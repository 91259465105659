/*

* Copyright © 2020, HCL Technologies Ltd. ALL RIGHTS RESERVED.

* <p>

* This software is the confidential information of HCL Technologies Ltd., and is licensed

* as restricted rights software. The use,reproduction, or disclosure of this software

* is subject to restrictions set forth in your license agreement with HCL.

*/

/**

* Cognitive Flow Management.

*

* @author Abhash Vats

*/
import React from "react";


import {Component} from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import {  Container } from 'react-bootstrap';
// import cognitiveBackground from "../../../public/images/cognitiveBackground.png";
import Grid from "@material-ui/core/Grid";
import { Col, Row } from 'react-bootstrap';
import './CsFlowManagement.css'
import SelectCustom from "../../../components/UIComponents/SelectDropdown/SelectComponent";
import NavbarCustom from '../../../components/UI/Navbar/Navbar';
import TextAreaWithLabelComponent from "./SecheduleMeeting/TextAreaWithLabelComponent";
import { withStyles, styled, alpha} from "@material-ui/core/styles";
import Switch from '@material-ui/core/Switch';
import { blue } from '@material-ui/core/colors';
import Form from 'react-bootstrap/Form';
import { encryptAndStore } from "../../../utility/utility";
// import Switch from 'react-bootstrap-switch';
    
class CsFlowManagement extends Component{

    constructor(props) {
        super(props);
        this.state = {
            enableCognitive:false,
            // selectedProject:null,
            searchT:"",
            popperShow:false,
            projectname:localStorage.getItem('projectname'),
            selectedProject:localStorage.getItem('projectname'),
            howSearchResult:'Carousel',
            hyperlinkTextForStandaloneScreen:'Please use this link to see your search results in a separate window.',
            defaultMessageInCaseNoReleventSearchRresults:'Sorry, we were not able to find any relevant result for your query.',
            defaultMessage1:'Do you want more alternatives?',
            defaultMessage2:'Do you want more alternatives?',
            defaultMessage3:'Do you want more alternatives?',
            userNotSatisfy:false,
            botNotSatisfy:false,
            userSelectNonOfAbove:false,
            searchTypeOption:[{value:'Carousel',displayValue:'Carousel'},{value:'Standalone Screen',displayValue:'Standalone Screen'}],
            virtualAssistantProjects:{"bots":[],},
        }
    }

    componentDidUpdate(prevprops){
        if(this.props.cognitiveData && this.props.cognitiveData.length>0 && this.props.cognitiveData != prevprops.cognitiveData){
            this.setState({
                enableCognitive:this.props.cognitiveData[0].enableCognitive,
                howSearchResult:this.props.cognitiveData[0].searchType,
                userNotSatisfy:this.props.cognitiveData[0].faq,
                botNotSatisfy:this.props.cognitiveData[0].unanswered,
                userSelectNonOfAbove:this.props.cognitiveData[0].nonoftheabove,
                defaultMessageInCaseNoReleventSearchRresults:this.props.cognitiveData[0].noDataMsg,
                hyperlinkTextForStandaloneScreen:this.props.cognitiveData[0].textForStandalone,
                defaultMessage1:this.props.cognitiveData[0].cogntiveDefaultMsg
            })
        }
        else if(this.props.cognitiveData != prevprops.cognitiveData){
            this.setState({
                enableCognitive:false,
                howSearchResult:'Carousel',
                userNotSatisfy:false,
                botNotSatisfy:false,
                userSelectNonOfAbove:false,
                defaultMessageInCaseNoReleventSearchRresults:'Sorry, we were not able to find any relevant result for your query.',
                hyperlinkTextForStandaloneScreen:'Please use this link to see your search results in a separate window.',
                defaultMessage1:'Do you want more alternatives?'
            })
        }
    }

    componentDidMount(){
        let url = this.props.config.BOTBUILDER_URL + `/cognitiveFlowConfiguration/project/`+ localStorage.getItem('projectname')
        this.props.onGetCognitiveFlow(url)
        let project = [];
        if (this.props.AssistantsList) {
            let obj = this.props.AssistantsList.find(ele => ele.virtualassistantName == localStorage.getItem("vaName"))
            if(obj){
                project =obj.project.filter(ele => ele !== '');
            }else{
                project = [];
            }
        }
        let vaProjectList = this.props.projects.filter(projec => project.includes(projec.projectName))
        vaProjectList && vaProjectList.map((data) => {
             this.state.virtualAssistantProjects.bots.push(data.projectName)
        })
    }

    enableCognitiveSearch = (e) =>{
        this.setState({
            enableCognitive:e.target.checked
          })
    }

    selectProject = (e) =>{
      this.setState({selectedProject:e.target.value,})
    //   localStorage.setItem("projectname",e.target.value)
      encryptAndStore("projectname",e.target.value)
    }

    setSearchTerm = (item) => {
        this.setState({searchT:item})
    }

    popperShowFn=()=>{
        this.setState({
          popperShow:true,
        })
    }

    projectclick =(event,projectname)=>{
        this.setState({searchT:"",popperShow:false})
        let savedProjectName=localStorage.getItem('projectname');
        if(savedProjectName!==projectname){
            // localStorage.setItem('projectname', projectname );
            encryptAndStore('projectname', projectname );
            this.setState({projectname:projectname});
            let url = this.props.config.BOTBUILDER_URL + `/cognitiveFlowConfiguration/project/`+ projectname
            this.props.onGetCognitiveFlow(url)
        }
        this.props.onchangeProjectAction(projectname);        
          //window.updateHCLChatBotURL(projectname);
    }

    howSearchResults = (e) =>{
      this.setState({howSearchResult:e.target.value,})
    }

    onChangeHandler2 = (e, name) =>{
      if(name == "hyperlinkTextForStandaloneScreen")
          this.setState({hyperlinkTextForStandaloneScreen:e.target.value})
      if(name == "defaultMessageInCaseNoReleventSearchRresults")
          this.setState({defaultMessageInCaseNoReleventSearchRresults:e.target.value})
      if(name == "defaultMessage1")
          this.setState({defaultMessage1:e.target.value})
      if(name == "defaultMessage2")
          this.setState({defaultMessage2:e.target.value})
      if(name == "defaultMessage3")
          this.setState({defaultMessage3:e.target.value})
    }

    userNotSatisfy = (e) => {
      this.setState({userNotSatisfy:e.target.checked})
    }

    botNotSatisfy = (e) => {
      this.setState({botNotSatisfy:e.target.checked})
    }

    userSelectNonOfAbove = (e) => {
      this.setState({userSelectNonOfAbove:e.target.checked})
    }

    saveCognitiveFlow = () => {
        var url = this.props.config.BOTBUILDER_URL + `/cognitiveFlowConfiguration`
        var data = {
            "enableCognitive": this.state.enableCognitive,
            "searchType": this.state.howSearchResult,
            "faq": this.state.userNotSatisfy,
            "unanswered": this.state.botNotSatisfy,
            "nonoftheabove": this.state.userSelectNonOfAbove,
            "noDataMsg": this.state.defaultMessageInCaseNoReleventSearchRresults,
            "textForStandalone": this.state.hyperlinkTextForStandaloneScreen,
            "cogntiveDefaultMsg": this.state.defaultMessage1,
            "project": localStorage.getItem('projectname')
        }
        this.props.onSaveCognitiveFlow(url,data)
    }

    cancelCognitiveFlow = () => {
        this.setState({
            enableCognitive:false,
            howSearchResult:'Carousel',
            userNotSatisfy:false,
            botNotSatisfy:false,
            userSelectNonOfAbove:false,
            defaultMessageInCaseNoReleventSearchRresults:'Sorry, we were not able to find any relevant result for your query.',
            hyperlinkTextForStandaloneScreen:'Please use this link to see your search results in a separate window.',
            defaultMessage1:'Do you want more alternatives?'
        })
    }

    render(){
        const label = { inputProps: { 'aria-label': 'Switch demo' } };
        const GreenSwitch = styled(Switch)(({ theme }) => ({
          '& .MuiSwitch-switchBase.Mui-checked': {
            color: blue[600],
            '&:hover': {
              backgroundColor: alpha(blue[600], theme.palette.action.hoverOpacity),
            },
          },
          '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: blue[600],
          },
        }));

        return(
            <Container style={{backgroundImage:`url(${"./images/cognitiveBackground.png"})`,backgroundSize:"100% 100%", backgroundRepeat: "no-repeat",paddingTop:"10px",paddingLeft:"10px"}}>
                <Grid container md={12} xs={12} xl={12}>
                    <Grid item md={6} xs={6} xl={6}>
                    <div style={{fontFamily:"Roboto-Bold",fontSize:"21px",color:"#000000"}}>Cognitive Flow Management</div>
                    <div className="pr-2" style={{display:'flex',justifyContent:'space-between',width:'100%',alignItems:'center'}}>
                                <span className="paraGraph">Provision to configure the cognitive search in virtual agent.</span>
                            </div>
                    </Grid>

                     <Grid item md={6} xs={6} xl={6} >
                            
                            <div style={{position:"relative",float:"right",marginRight:"45px"}}>
                                <NavbarCustom 
                                    className="cognitiveFlowManagementProjectName"
                                    // dataList={this.props.projectList}
                                    dataList={this.state.virtualAssistantProjects}
                                    addImportProjectIcon={false}
                                    setSearchTerm={(item)=>this.setSearchTerm(item)}
                                    popperShow={this.state.popperShow}
                                    popperShowFn={()=>this.popperShowFn()}
                                    searchTerm={this.state.searchT}
                                    projectclick={(event,item)=>this.projectclick(event,item)}
                                    projectname={this.state.projectname} 
                                    rightPopUp={false} >
                                </NavbarCustom>
                            </div>
                    </Grid>
                </Grid>
                <Grid container md={12} xs={12} xl={12} className="topDropDown">
                    <Grid item md={6} xs={6} xl={6} className="pr-5">
                        {/* <label style={{fontFamily:"Roboto-Bold",fontSize:"14px",color:"#696868",textTransform:"inherit"}}>Enable Cognitive Search</label>
                        <GreenSwitch onChange={(e) => this.enableCognitiveSearch(e)} {...label} checked={this.state.enableCognitive}/> */}
                        <span className='label'>Enable Cognitive Search</span>
                        <Form style={{display: "inline-flex", marginLeft: "13px"}}>
                            <Form.Check
                                type="switch"
                                id="enableCognitiveSearch"
                                checked={this.state.enableCognitive}
                                onChange={e => this.enableCognitiveSearch(e)}
                            />
                        </Form>
                    </Grid>                    
                </Grid>
                    <Grid item md={6} xs={6} xl={6} className="pr-5">
                        <SelectCustom
                            name="selectName"
                            className="MuiGrid-root select.selectClass.form-control"
                            label="Ways of displaying search results"
                            changed={(event) => this.howSearchResults(event)}
                            required={true}
                            value={this.state.howSearchResult}
                            options={this.state.searchTypeOption}
                            disabled={!this.state.enableCognitive}
                        />
                    </Grid>
                {this.state.howSearchResult == 'Standalone Screen' && <Grid item md={6} xs={6} xl={6} className="pr-5" style={{marginTop:"10px"}}>
                        <TextAreaWithLabelComponent
                                        type="text"
                                        name="name"
                                        label="Hyperlink text for standalone screen"
                                        value={this.state.hyperlinkTextForStandaloneScreen}
                                        readOnly={false}
                                        onChange={(e) => this.onChangeHandler2(e, 'hyperlinkTextForStandaloneScreen')}
                                        required={true}
                                        autocomplete="new-password"
                                        style={{height:"40px",padding:"8px"}}
                                        disabled={!this.state.enableCognitive}
                            />
                </Grid>}
                <Grid item md={6} xs={6} xl={6} className="pr-5" style={{marginTop:"10px"}}>
                        <TextAreaWithLabelComponent
                                    type="text"
                                    name="name"
                                    label="Message/Query to trigger cognitive search"
                                    value={this.state.defaultMessage1}
                                    readOnly={false}
                                    onChange={(e) => this.onChangeHandler2(e, 'defaultMessage1')}
                                    required={true}
                                    autocomplete="new-password"
                                    style={{height:"40px",padding:"8px"}}
                                    disabled={!this.state.enableCognitive}
                        />
                </Grid>
                <Row className="topDropDown">
                    {/* <Col md={5} xs={5} xl={5}><span><label style={{fontFamily:"Roboto-Bold",fontSize:"14px",color:"#696868",textTransform:"inherit", display:"inline"}}>Enable cognitive search for unsatisfactory FAQ response</label></span></Col>
                    <Col><span style={{position:"relative", left:"-35px"}}><GreenSwitch onChange={(e) => this.userNotSatisfy(e)} {...label} checked={this.state.userNotSatisfy} disabled={!this.state.enableCognitive}/></span></Col> */}
                     <span className='label' style={{marginLeft: "15px"}}>Enable cognitive search for unsatisfactory FAQ response</span>
                        <Form style={{display: "inline-flex", marginLeft: "15px"}}>
                            <Form.Check
                                type="switch"
                                id="enableCognitiveSearchForUnsatisfactoryFAQResponse"
                                checked={this.state.userNotSatisfy}
                                onChange={e => this.userNotSatisfy(e)}
                            />
                        </Form>
                </Row>

                <Row className="topDropDown">
                    {/* <Col md={5} xs={5} xl={5}><span><label style={{fontFamily:"Roboto-Bold",fontSize:"14px",color:"#696868",textTransform:"inherit", display:"inline"}}>Enable cognitive search for unanswered query</label></span></Col>
                    <Col><span style={{position:"relative", left:"-35px"}}><GreenSwitch onChange={(e) => this.botNotSatisfy(e)} {...label} checked={this.state.botNotSatisfy} disabled={!this.state.enableCognitive}/></span></Col> */}
                    <span className='label' style={{marginLeft: "15px"}}>Enable cognitive search for unanswered query</span>
                        <Form style={{display: "inline-flex", marginLeft: "13px"}}>
                            <Form.Check
                                type="switch"
                                id="enableCognitiveSearchForUnansweredQuery"
                                checked={this.state.botNotSatisfy}
                                onChange={e => this.botNotSatisfy(e)}
                            />
                        </Form>
                </Row>

                <Row className="topDropDown">
                    {/* <Col md={5} xs={5} xl={5}><span><label style={{fontFamily:"Roboto-Bold",fontSize:"14px",color:"#696868",textTransform:"inherit", display:"inline"}}>Enable cognitive search for unsatisfactory response during ambiguity among FAQ or Intents</label></span></Col>
                    <Col><span style={{position:"relative", left:"-35px"}}><GreenSwitch onChange={(e) => this.userSelectNonOfAbove(e)} {...label} checked={this.state.userSelectNonOfAbove} disabled={!this.state.enableCognitive}/></span></Col> */}
                    <span className='label' style={{marginLeft: "15px"}}>Enable cognitive search for unsatisfactory response during ambiguity among FAQ or Intents</span>
                        <Form style={{display: "inline-flex", marginLeft: "10px"}}>
                            <Form.Check
                                type="switch"
                                id="enableCognitiveSearchForUnsatisfactoryResponseDuringAmbiguityAmongFAQorIntents"
                                checked={this.state.userSelectNonOfAbove}
                                onChange={e => this.userSelectNonOfAbove(e)}
                            />
                        </Form>
                </Row>

                <Grid container md={12} xs={12} xl={12} className="topDropDown">
                    <Grid item md={6} xs={6} xl={6} className="pr-5">
                        <TextAreaWithLabelComponent
                                    type="text"
                                    name="name"
                                    label="Default message in case no relevant search results"
                                    value={this.state.defaultMessageInCaseNoReleventSearchRresults}
                                    readOnly={false}
                                    onChange={(e) => this.onChangeHandler2(e, 'defaultMessageInCaseNoReleventSearchRresults')}
                                    required={true}
                                    autocomplete="new-password"
                                    style={{height:"40px",padding:"8px"}}
                                    disabled={!this.state.enableCognitive}
                        />
                    </Grid>
                </Grid>
                <button className="cancelBtn" onClick={()=>this.cancelCognitiveFlow()} ><span style={{fontFamily: 'Roboto-Bold', fontSize:"14px"}}>Reset</span></button>
                <button className="saveBtn" onClick={()=>this.saveCognitiveFlow()}><span style={{fontFamily: 'Roboto-Bold', fontSize:"14px"}}>Save</span></button>              
            </Container> 
        )
    }
}      

const mapStateToProps = state => {
    return {
      projectList:state.user.projects,
      cognitiveData:state.cognitive.cognitiveData,
      projects: state.projectview.AllProjectsList,
      AssistantsList: state.projectview.AllAssistantsList,
    };
  };
  
  const mapDispatchToProps = (dispatch) => ({
    onchangeProjectAction: (projectname) => dispatch(actions.changeProjectAction(projectname)),
    onSaveCognitiveFlow: (url,data) => dispatch(actions.saveCognitiveFlow(url,data)),
    onGetCognitiveFlow: (url) => dispatch(actions.getCognitiveFlow(url))
  });

export default connect(mapStateToProps, mapDispatchToProps)(CsFlowManagement);