import React, { useState, useEffect } from "react";
import Drawer from "@material-ui/core/Drawer";
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import "./edit-rootcause.css";
import { Col, Form } from 'react-bootstrap';
import { useDispatch } from "react-redux";
import editRootCauseToSymptomsHandler from "../../../../../../../../store/actions/adminSettings/editRootCauseToSymptomsHandler";
import PrimaryButtonWithLoader from "../../../../../components/PrimaryButtonWithLoader/PrimaryButtonWithLoader";
import SecondaryButtonWithLoader from "../../../../../components/SecondaryButtonWithLoader/SecondaryButtonWithLoader";
import DrawerHeaderContent from "../../../../../components/DrawerHeaderContent/DrawerHeaderContent";
import InputWithLabelComponent from "../../../../../components/InputWithLabelComponent/InputWithLabelComponent";
import { deleteCalltoServer, UpdateCalltoServer, PostCalltoServer } from "../../../../../../../../store/utility";
import deleteRootCauseToSymptomsHandler from "../../../../../../../../store/actions/adminSettings/deleteRootCauseToSymptomsHandler";
import getProjectDetails from "../../../../../helpers/getProjectDetails";
import { SetSuccessMessage } from "../../../../../../../../store/actions";

const useStyles = makeStyles({
  list: {
    width: 500,
  }
});

const EditRootCause = ({
  showDrawer,
  toggleDrawer,
  symptomsData,
  selectedItem,
  id,
  closeDrawer,
  toastFun,
  config
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const projectName = getProjectDetails();
  const [saveLoading, setSaveLoading] = useState(false);
  const [isInvalid, setInValid] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [formData, setFormData] = useState({
    symptoms: '',
    leadingQuestion: '',
    relevancy: '',
    status: '',
    rootcause: ''
  });

  useEffect(() => {
    setFormData(
      {
        symptoms: get(symptomsData, 'symptoms', ''),
        leadingQuestion: get(symptomsData, 'leadingQuestion', ''),
        relevancy: get(symptomsData, 'relevancy', ''),
        status: get(symptomsData, 'status', ''),
        rootcause: get(selectedItem, 'rootcause', '')
      }
    )
  }, [symptomsData]);

  const changeHandler = (name) => (event) => { 
    if (name === 'status') {
      setFormData(prevData => ({
        ...prevData,
        [name]: event.target.checked ? 'reviewed' : 'yet to review'
      }));
    } else {
      if(!isEqual(event.target.value,"")) {
        setInValid(false);
        setFormData(prevData => ({
          ...prevData,
          [name]: event.target.value
        }));
      }
      setFormData(prevData => ({
        ...prevData,
        [name]: event.target.value
      }));
      }
  };


  const saveHandler = (e) => {
    if( formData.relevancy == 0){
      setInValid(true)
      setTimeout(()=>{
        setInValid(false)
      },1000)
      return 
    }
    if(!isEqual(formData.relevancy,"") && formData.relevancy <= 90){
    const projectName = getProjectDetails();
    const newForm = {
      "symptoms": formData.symptoms,
      "symptom_title": '',
      "relevancy": formData.relevancy,
      "rootcause": formData.rootcause,
      "rootcause_title": "",
      "leadingquestion": '',
      "project": projectName,
      "status": formData.status,
      "group" : "r2s"
    }
    const url = `${config.INSIGHTS_API_URL}/product-intelligent-triage/settings/statusAndScore`;
    setSaveLoading(true);
    UpdateCalltoServer(url, newForm)
      .then((response) => {
        setSaveLoading(false);
        if (get(response, "data.status.responseType", "") === "success") {
          dispatch(editRootCauseToSymptomsHandler({
            formData,
            id,
            selectedItem
          }));
          setSaveLoading(false);
          closeDrawer();
          dispatch(SetSuccessMessage('Root cause To Symptom Mapping Successfully Saved'));
        } else {
          setSaveLoading(false);
        }
      })
      .catch((err) => { 
        setSaveLoading(false);
        toastFun(err, "error");
       });
    } else{
      setInValid(true);
      setTimeout(()=>{
        setInValid(false)
      },1000)
    }
  };

  const deleteHandler = () => {
    setDeleteLoading(true);
    const url =`${config.INSIGHTS_API_URL}/product-intelligent-triage/settings/symptomsUnmap`;
    const deletedSymptom ={
      "symptoms": formData.symptoms,
      "rootcause": formData.rootcause,
      "project": projectName
    }
    deleteCalltoServer(url,deletedSymptom)
    .then((response) => {
      if (get(response, "data.status.responseType", "") === "success") {
        dispatch(deleteRootCauseToSymptomsHandler({
          formData,
          id,
          selectedItem
        }));
        setDeleteLoading(false);
        closeDrawer();
        dispatch(SetSuccessMessage('Root cause To Symptom Unmapped Successfully'));
      } else {
        setDeleteLoading(false);
      }
    })
    .catch((error) => {
      setDeleteLoading(false);
      toastFun(error, "error");
    })
  };


  return (
    <React.Fragment>
      {showDrawer ? (
        <Drawer
          anchor={"right"}
          open={showDrawer}
          onClose={toggleDrawer(false)}
        >
          <div
            className={clsx(classes.list, classes.fullList)}
            role="presentation"
          >
            <DrawerHeaderContent
              heading={"Symptoms Details"}
              toggleDrawer={toggleDrawer}
            />
            <div className="mt-3 d-flex flex-column rootCauses-Item rootCauses-Item-bg custom-bg ">
              <div className="mt-1">
              <Col md={12}>
                <InputWithLabelComponent
                  type="text"
                  className=""
                  name="symptoms"
                  label={"Symptom"}
                  value={get(formData, "symptoms", "")}
                  onChange={changeHandler("symptoms")}
                  readOnly
                  helpIcon ={false}
                />
                </Col>
              </div>
              <div className="mt-3">
                <Col md={12}>
                <InputWithLabelComponent
                  type="text"
                  className=""
                  name="leadingQuestion"
                  label={"Leading Question"}
                  value={get(formData, "leadingQuestion", "")}
                  onChange={changeHandler("leadinQuestion")}
                  readOnly
                  helpIcon={false}
                />
                </Col>
              </div>
              <div className="mt-3">
                <Col md={12}>
                <InputWithLabelComponent
                  type="number"                  
                  max={'90'}
                  min={'1'}
                  className=""
                  placeHolder={"Enter Relevancy"}
                  name="relevancy"
                  label={"Relevancy"}
                  value={get(formData, "relevancy", "") }
                  onChange={changeHandler("relevancy")}                 
                  isInvalid={isInvalid}
                  required={true}
                  error ={ isInvalid ? "Please Enter Relevancy(1-90)" : null}
                  helpIcon={false}
                />
                </Col>
              </div>
            </div>
            <div className="d-flex flex-column rootCauses-Item ">
                 <Col md={12}>
                  <div className="d-flex align-items-baseline">
                    <Form.Label className="mb-1 toggle-btn-align">
                      {"Is this reviewed"}
                    </Form.Label>
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label=""
                      checked={get(formData, "status", "") === "reviewed"}
                      onChange={changeHandler("status")}
                    />
                  </div>
                  </Col>
              </div>
            <div className="d-flex flex-row button-content">
              <SecondaryButtonWithLoader
                className="ml-5"
                size="lg"
                text="Unmap"
                onClick={deleteHandler}
                loading={deleteLoading}
              />
              <PrimaryButtonWithLoader
                className="ml-4"
                size="lg"
                onClick={saveHandler}
                loading={saveLoading}
                text="save"
              />
            </div>
          </div>
        </Drawer>
      ) : null}
    </React.Fragment>
  );
};

EditRootCause.propTypes = {
  deletebutton: PropTypes.string,
  savebutton: PropTypes.string,
};

EditRootCause.defaultProps = {
  deletebutton: "DELETE",
  savebutton: "SAVE"
};

export default EditRootCause;
