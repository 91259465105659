import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../utility';
const checkiTSExtensionEngineStatusFailure = (state, action) => {
    return updateObject( state, {
        error: action.error
    });
};

const checkiTSExtensionEngineStatusSuccess = (state, action) => {

    return updateObject( state, {
        checkits:action.response
    });
};

const checkUploadEngineStatusFailure = (state, action) => {
    return updateObject( state, {
        error: action.error
    });
};

const checkUploadEngineStatusSuccess = (state, action) => {

    return updateObject( state, {
        checkuploadeng:action.response
    });
};
const getdsStatusFailure = (state, action) => {
    return updateObject( state, {
        error: action.error
    });
};

const getdsStatusSuccess = (state, action) => {

    return updateObject( state, {
        checdsstatus:action.response
    });
};
const getDashboardStatusFailure = (state, action) => {
    return updateObject( state, {
        error: action.error
    });
};

const getDashboardStatusSuccess = (state, action) => {

    return updateObject( state, {
        checkdahboard:action.response
    });
};
const HealthReducer = ( state = {}, action ) => {
    switch ( action.type ) {
        
        case actionTypes.CHECK_ITS_EX_ENGN_FAILURE: return checkiTSExtensionEngineStatusFailure(state, action);
        case actionTypes.CHECK_ITS_EX_ENGN_SUCCESS: return checkiTSExtensionEngineStatusSuccess(state, action);
        
        case actionTypes.CHECK_UPLOAD_ENGN_FAILURE: return checkUploadEngineStatusFailure(state, action);
        case actionTypes.CHECK_UPLOAD_ENGN_SUCCESS: return checkUploadEngineStatusSuccess(state, action);

        case actionTypes.GET_STATUS_FAILURE: return getdsStatusFailure(state, action);
        case actionTypes.GET_STATUS_SUCCESS: return getdsStatusSuccess(state, action);

        case actionTypes.GET_DASHBOARD_STATUS_FAILURE: return getDashboardStatusFailure(state, action);
        case actionTypes.GET_DASHBOARD_STATUS_SUCCESS: return getDashboardStatusSuccess(state, action);


        default:
            return state;
    }
};

export default HealthReducer;