import axios from 'axios';
import * as actionTypes from './actionTypes';
import {  PostCalltoServer } from '../utility';
import {SetErrorMessage, SetSuccessMessage} from './message'
import { decrypt } from '../../utility/utility';

const orgName = localStorage.getItem("orgName");
const username = localStorage.getItem("userName");

export const totalUserStart = () => {
    return {
        type: actionTypes.TOTALUSER_START
    };
};

export const totalCognitiveUserStart = () => {
    return {
        type: actionTypes.TOTAL_COGNITIVE_USER_START
    };
};

export const totalUserSuccess = (status) => {
    return {
        type: actionTypes.TOTALUSER_SUCCESS,
        totalUsers:status
    };
};

export const totalCognitiveUserSuccess = (status) => {
    return {
        type: actionTypes.TOTAL_COGNITIVE_USER_SUCCESS,
        totalUsers:status
    };
};

export const totalCognitiveUserFail = (error) => {
    return {
        type: actionTypes.TOTAL_COGNITIVE_USER_FAIL,
        error: error
    };
};

export const totalUserFail = (error) => {
    return {
        type: actionTypes.TOTALUSER_FAIL,
        error: error
    };
};

export const repeatUserStart = () => {
    return {
        type: actionTypes.REPEATUSER_START
    };
};

export const repeatUserSuccess = (status) => {
    return {
        type: actionTypes.REPEATUSER_SUCCESS,
        repeatUsers:status
    };
};

export const repeatUserFail = (error) => {
    return {
        type: actionTypes.REPEATUSER_FAIL,
        error: error
    };
};


export const newUserStart = () => {
    return {
        type: actionTypes.NEWUSER_START
    };
};

export const newUserSuccess = (status) => {
    return {
        type: actionTypes.NEWUSER_SUCCESS,
        newUsers:status
    };
};

export const newUserFail = (error) => {
    return {
        type: actionTypes.NEWUSER_FAIL,
        error: error
    };
};

export const totalUserReportStart = () => {
    return {
        type: actionTypes.TOTALUSERREPORT_START
    };
};

export const totalUserReportSuccess = (status) => {
    return {
        type: actionTypes.TOTALUSERREPORT_SUCCESS,
        totalUsersReport:status
    };
};

export const totalUserReportFail = (error) => {
    return {
        type: actionTypes.TOTALUSERREPORT_FAIL,
        error: error
    };
};

// export const totalInitiatFlowStart = () => {
//     return {
//         type: actionTypes.TOTALINITIATFLOW_START
//     };
// };

// export const totalInitiatFlowSuccess = (status) => {
//     return {
//         type: actionTypes.TOTALINITIATFLOW_SUCCESS,
//         initiatFlow:status
//     };
// };

// export const totalInitiatFlowFail = (error) => {
//     return {
//         type: actionTypes.TOTALINITIATFLOW_FAIL,
//         error: error
//     };
// };
export const totalFeedbackStart = () => {
    return {
        type: actionTypes.TOTALFEEDBACK_START
    };
};

export const totalFeedbackSuccess = (status) => {
    return {
        type: actionTypes.TOTALFEEDBACK_SUCCESS,
        totalFeedback:status
    };
};

export const totalFeedbackFail = (error) => {
    return {
        type: actionTypes.TOTALFEEDBACK_FAIL,
        error: error
    };
};

export const totalCognitiveFeedbackStart = () => {
    return {
        type: actionTypes.TOTAL_COGNITIVE_FEEDBACK_START
    };
};

export const totalCognitiveFeedbackSuccess = (status) => {
    return {
        type: actionTypes.TOTAL_COGNITIVE_FEEDBACK_SUCCESS,
        totalCogFeedback:status
    };
};

export const totalCognitiveFeedbackFail = (error) => {
    return {
        type: actionTypes.TOTAL_COGNITIVE_FEEDBACK_FAIL,
        error: error
    };
};

// export const totalChatSessionStart = () => {
//     return {
//         type: actionTypes.TOTALCHATSESSION_START
//     };
// };

// export const totalChatSessionSuccess = (status) => {
//     return {
//         type: actionTypes.TOTALCHATSESSION_SUCCESS,
//         totalChatSession:status
//     };
// };

// export const totalChatSessionFail = (error) => {
//     return {
//         type: actionTypes.TOTALCHATSESSION_FAIL,
//         error: error
//     };
// };

export const totalChatSessionReportStart = () => {
    return {
        type: actionTypes.TOTALCHATSESSIONREPORT_START
    };
};

export const totalChatSessionReportSuccess = (status) => {
    return {
        type: actionTypes.TOTALCHATSESSIONREPORT_SUCCESS,
        totalChatSessionReport:status
    };
};

export const totalChatSessionReportFail = (error) => {
    return {
        type: actionTypes.TOTALCHATSESSIONREPORT_FAIL,
        error: error
    };
};

export const totalAgentTransformsStart = () => {
    return {
        type: actionTypes.TOTAL_AGENT_TRANSFORMS_START
    };
};

export const totalAgentTransformsSuccess = (status) => {
    return {
        type: actionTypes.TOTAL_AGENT_TRANSFORMS_SUCCESS,
        totalAgentTransforms:status
    };
};

export const totalAgentTransformsFail = (error) => {
    return {
        type: actionTypes.TOTAL_AGENT_TRANSFORMS_FAIL,
        error: error
    };
};

export const totalFlowReportSuccess = (status) => {
    return {
        type: actionTypes.TOTAL_FLOW_REPORT_SUCCESS,
        totalFlowReport:status
    };
};

export const totalFlowReportFail = (error) => {
    return {
        type: actionTypes.TOTAL_FLOW_REPORT_FAIL,
        error: error
    };
};

export const totalFlowReportStart = () => {
    return {
        type: actionTypes.TOTAL_FLOW_REPORT_START
    };
};

export const totalAgentTransferReportStart = () => {
    return {
        type: actionTypes.TOTAL_AGENT_TRANSFER_REPORT_START
    };
};


export const totalAgentTransferReportSuccess = (status) => {
    return {
        type: actionTypes.TOTAL_AGENT_TRANSFER_REPORT_SUCCESS,
        totalAgentTransferReport:status
    };
};

export const totalAgentTransferReportFail = (error) => {
    return {
        type: actionTypes.TOTAL_AGENT_TRANSFER_REPORT_FAIL,
        error: error
    };
};

export const totalRepeatUserReportStart = () => {
    return {
        type: actionTypes.TOTALREPEATUSERREPORT_START
    };
};

export const totalRepeatUserReportSuccess = (status) => {
    return {
        type: actionTypes.TOTALREPEATUSERREPORT_SUCCESS,
        RepeatUserReport:status
    };
};

export const totalRepeatUserReportFail = (error) => {
    return {
        type: actionTypes.TOTALREPEATUSERREPORT_FAIL,
        error: error
    };
};

export const totalNewUserReportStart = () => {
    return {
        type: actionTypes.TOTALNEWUSERREPORT_START
    };
};

export const totalNewUserReportSuccess = (status) => {
    return {
        type: actionTypes.TOTALNEWUSERREPORT_SUCCESS,
        NewUserReport:status
    };
};

export const totalNewUserReportFail = (error) => {
    return {
        type: actionTypes.TOTALNEWUSERREPORT_FAIL,
        error: error
    };
};
export const totalunansweredReportStart = () => {
    return {
        type: actionTypes.TOTALUNANSWEREDREPORT_START
    };
};

export const totalunansweredReportSuccess = (status) => {
    return {
        type: actionTypes.TOTALUNANSWEREDREPORT_SUCCESS,
        totalunansweredReport:status
    };
};

export const totalunansweredReportFail = (error) => {
    return {
        type: actionTypes.TOTALUNANSWEREDREPORT_FAIL,
        error: error
    };
};



export const totalUsers = (apiURL,data) => {
    return dispatch => {
        dispatch(totalUserStart());
        let url =apiURL+`/dashboard/totalusers`;
        PostCalltoServer(url,data)
        .then(response => {
           if( response.data){               
                dispatch(totalUserSuccess(response.data.data));
           }
           else
           {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(totalUserFail(response.data.msg));
           }
        })
        .catch(err => {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(totalUserFail(err.message));
            dispatch(totalUserSuccess(''));
        });
    };    
};

export const totalCognitiveUsers = (apiURL,data) => {
    return dispatch => {
        dispatch(totalCognitiveUserStart());
        let url =apiURL+`/dashboard/totalcognitiveusers`;
        PostCalltoServer(url,data)
        .then(response => {
           if( response.data){               
                dispatch(totalCognitiveUserSuccess(response.data.data));
           }
           else
           {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(totalCognitiveUserFail(response.data.msg));
           }

        })
        .catch(err => {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(totalCognitiveUserFail(err.message));
            dispatch(totalCognitiveUserSuccess(''));
        });
    };    
};

export const totalCognitiveUnanswers = (apiURL,data) => {
    return dispatch => {
        dispatch(totalCognitiveUnanswersStart());
        let url =apiURL+`/dashboard/totalcognitiveunansweredcount`;
        PostCalltoServer(url,data)
        .then(response => {
           if( response.data){               
                dispatch(totalCognitiveUnanswersSuccess(response.data.data));
           }
           else
           {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(totalCognitiveUnanswersFail(response.data.msg));
           }

        })
        .catch(err => {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(totalCognitiveUnanswersFail(err.message));
        });
    };    
};

export const totalCognitiveSearchQueries = (apiURL,data) => {
    return dispatch => {
        dispatch(totalCognitiveSearchQueryStart());
        let url =apiURL+`/dashboard/totalcognitivesearchqueries`;
        PostCalltoServer(url,data)
        .then(response => {
           if( response.data){  
            let newdata =(response.data.data && response.data.data.length!=0)? response.data.data[0] : {}
                       
                dispatch(totalCognitiveSearchQuerySuccess(newdata));
           }
           else
           {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(totalCognitiveSearchQueryFail(response.data.msg));
           }

        })
        .catch(err => {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(totalCognitiveSearchQueryFail(err.message));
        });
    };    
};

// export const totalUnanswersStart = () => {
//     return {
//         type: actionTypes.TOTALUNANSWERS_START
//     };
// };

// export const totalUnanswersSuccess = (status) => {
//     return {
//         type: actionTypes.TOTALUNANSWERS_SUCCESS,
//         totalUnanswers:status
//     };
// };

// export const totalUnanswersFail = (error) => {
//     return {
//         type: actionTypes.TOTALUNANSWERS_FAIL,
//         error: error
//     };
// };

export const totalCognitiveUnanswersStart = () => {
    return {
        type: actionTypes.TOTAL_COGNITIVE_UNANSWERS_START
    };
};

export const totalCognitiveUnanswersSuccess = (status) => {
    return {
        type: actionTypes.TOTAL_COGNITIVE_UNANSWERS_SUCCESS,
        totalUnanswers:status
    };
};

export const totalCognitiveUnanswersFail = (error) => {
    return {
        type: actionTypes.TOTAL_COGNITIVE_UNANSWERS_FAIL,
        error: error
    };
};

export const totalCognitiveSearchQueryStart = () => {
    return {
        type: actionTypes.TOTAL_COGNITIVE_SEARCHQUERY_START
    };
};

export const totalCognitiveSearchQuerySuccess = (status) => {
    return {
        type: actionTypes.TOTAL_COGNITIVE_SEARCHQUERY_SUCCESS,
        totalCognitiveSearchqueries:status
    };
};

export const totalCognitiveSearchQueryFail = (error) => {
    return {
        type: actionTypes.TOTAL_COGNITIVE_SEARCHQUERY_FAIL,
        error: error
    };
};

export const AvgUserReportStart = () => {
    return {
        type: actionTypes.AVGUSERSREPORT_START
    };
};

export const AvgUserReportSuccess = (status) => {
    return {
        type: actionTypes.AVGUSERSREPORT_SUCCESS,
        avgUserReport:status
    };
};

export const AvgUserReportFail = (error) => {
    return {
        type: actionTypes.AVGUSERSREPORT_FAIL,
        error: error
    };
};


export const newUsers = (apiURL,data) => {
    return dispatch => {
        dispatch(newUserStart());
        let url =apiURL+`/dashboard/getnewusers`;
        PostCalltoServer(url,data)
        .then(response => {
           if( response.data){               
                dispatch(newUserSuccess(response.data.data));
           }
           else
           {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(newUserFail(response.data.msg));
           }

        })
        .catch(err => {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(newUserFail(err.message));
        });
    };    
};

export const repeatUsers = (apiURL,data) => {
    return dispatch => {
        dispatch(repeatUserStart());
        let url =apiURL+`/dashboard/repeatedusers`;
        PostCalltoServer(url,data)
        .then(response => {
           if( response.data){               
                dispatch(repeatUserSuccess(response.data.data));
           }
           else
           {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(repeatUserFail(response.data.msg));
           }

        })
        .catch(err => {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(repeatUserFail(err.message));
        });
    };    
};

export const totalUsersReport = (apiURL,data) => {
    return dispatch => {
        dispatch(totalUserReportStart());
        let url =apiURL+`/dashboard/totaluserswithReport`;
        PostCalltoServer(url,data)
        .then(response => {
           if( response.data){               
                dispatch(totalUserReportSuccess(response.data.data));
           }
           else
           {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(totalUserReportFail(response.data.msg));
           }

        })
        .catch(err => {
            dispatch(totalUserReportSuccess(''));
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(totalUserReportFail(err.message));
        });
    };    
};


export const totalCognitivefeedback = (apiURL,data) => {
    return dispatch => {
        dispatch(totalCognitiveFeedbackStart());
        let url =apiURL+`/dashboard/totalcognitivefeedbackcount`;
        PostCalltoServer(url,data)
        .then(response => {
           if( response.data){  
            let newdata =(response.data.data && response.data.data.length!=0)? response.data.data[0] : {}             
                dispatch(totalCognitiveFeedbackSuccess(newdata));
           }
           else
           {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(totalCognitiveFeedbackFail(response.data.msg));
           }
        })
        .catch(err => {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(totalCognitiveFeedbackFail(err.message));
        });
    };    
};

export const totalfeedback = (apiURL,data) => {
    return dispatch => {
        dispatch(totalFeedbackStart());
        let url =apiURL+`/dashboard/totalfeedback`;
        PostCalltoServer(url,data)
        .then(response => {
           if( response.data){               
                dispatch(totalFeedbackSuccess(response.data.data));
           }
           else
           {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(totalFeedbackFail(response.data.msg));
           }

        })
        .catch(err => {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(totalFeedbackFail(err.message));
        });
    };    
};

export const totalFeedbackReportStart = () => {
    return {
        type: actionTypes.TOTALFEEDBACK_REPORT_START
    };
};

export const totalFeedbackReportSuccess = (status) => {
    return {
        type: actionTypes.TOTALFEEDBACK_REPORT_SUCCESS,
        feedbackReportData:status
    };
};

export const totalFeedbackReportFail = (error) => {
    return {
        type: actionTypes.TOTALFEEDBACK_REPORT_FAIL,
        error: error
    };
};

export const totalFeedbackUtterancesStart = () => {
    return {
        type: actionTypes.TOTALFEEDBACK_UTTARANCES_START
    };
};

export const totalFeedbackUtterancesSuccess = (status) => {
    return {
        type: actionTypes.TOTALFEEDBACK_UTTARANCES_SUCCESS,
        feedbackUtterancesData:status
    };
};

export const totalFeedbackUtterancesFail = (error) => {
    return {
        type: actionTypes.TOTALFEEDBACK_UTTARANCES_FAIL,
        error: error
    };
};

export const totalFeedbackCategoriesStart = () => {
    return {
        type: actionTypes.TOTALFEEDBACK_CATEGORIES_START
    };
};

export const totalFeedbackCategoriesSuccess = (status) => {
    return {
        type: actionTypes.TOTALFEEDBACK_CATEGORIES_SUCCESS,
        feedbackCategoriesData:status
    };
};

export const selectedCategory= (cat) => {
    return (dispatch)=>{
        dispatch(selectedCat(cat));
    };
};

export const selectedCat= (cat) => {
    return {
        type: actionTypes.SELECTED_CATEGORY,
        category:cat
    };
};

export const totalFeedbackCategoriesFail = (error) => {
    return {
        type: actionTypes.TOTALFEEDBACK_CATEGORIES_FAIL,
        error: error
    };
};


export const totalfeedbackReport = (apiURL,data) => {
    return dispatch => {
        dispatch(totalFeedbackReportStart());
        let url =apiURL+`/dashboard/totalfeedbackreport`;
        PostCalltoServer(url,data)
        .then(response => {
           if( response.data){               
                dispatch(totalFeedbackReportSuccess(response.data.data));
           }
           else
           {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(totalFeedbackReportFail(response.data.msg));
           }

        })
        .catch(err => {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(totalFeedbackReportFail(err.message));
        });
    };    
};

export const totalfeedbackUtterances = (apiURL,tfUdata) => {
    return dispatch => {
        dispatch(totalFeedbackUtterancesStart());
        dispatch(selectedCat(tfUdata.message))
        let url =apiURL+`/dashboard/getfeedbackUtterances`;
        PostCalltoServer(url,tfUdata)
        .then(response => {
           if( response.data){               
                dispatch(totalFeedbackUtterancesSuccess(response.data.data));
           }
           else
           {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(totalFeedbackUtterancesFail(response.data.msg));
           }
        })
        .catch(err => {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(totalFeedbackUtterancesFail(err.message));
        });
    };    
};


export const totalfeedbackCategories = (apiURL,tfUdata) => {
    return dispatch => {
        dispatch(totalFeedbackCategoriesStart());
        let url =apiURL+`/dashboard/totalfeedbackcategories`;
        PostCalltoServer(url,tfUdata)
        .then(response => {
           if( response.data){               
                dispatch(totalFeedbackCategoriesSuccess(response.data.data));
                let cats=response.data.data?JSON.parse(response.data.data):[];
                if(cats.length>0){
                    let url =apiURL+`/dashboard/getfeedbackUtterances`;
                    let topCat={
                        cat:'',
                        count:0
                    }
                    if(cats.length>0){
                        for (let key in cats[0]) {
                            if(cats[0][key]>topCat.count){
                                topCat.count=cats[0][key];
                                topCat.cat=key
                            }
                          }
                    }
                    tfUdata.message=topCat.cat;
                    PostCalltoServer(url,tfUdata)
                    .then(response => {
                       if( response.data){               
                            dispatch(totalFeedbackUtterancesSuccess(response.data.data));
                       }
                       else
                       {
                        dispatch(SetErrorMessage('Error occured.'));
                        dispatch(totalFeedbackUtterancesFail(response.data.msg));
                       }
                    })
                    .catch(err => {
                        dispatch(SetErrorMessage('Error occured.'));
                        dispatch(totalFeedbackUtterancesFail(err.message));
                    });
                }
           }
           else
           {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(totalFeedbackCategoriesFail(response.data.msg));
           }
        })
        .catch(err => {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(totalFeedbackCategoriesFail(err.message));
        });
    };    
};


export const totalChatSessionReport = (apiURL,data) => {
    return dispatch => {
        dispatch(totalChatSessionReportStart());
        let url =apiURL+`/dashboard/totalsessionreport`;
        PostCalltoServer(url,data)
        .then(response => {
           if( response.data){               
                dispatch(totalChatSessionReportSuccess(response.data.data));
           }
           else
           {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(totalChatSessionReportFail(response.data.msg));
           }

        })
        .catch(err => {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(totalChatSessionReportFail(err.message));
        });
    };    
};
export const totalAgentTransforms = (apiURL,data) => {
    return dispatch => {
        dispatch(totalAgentTransformsStart());
        let url =apiURL+`/dashboard/totalagenttransfer`;
        PostCalltoServer(url,data)
        .then(response => {
           if( response.data){               
                dispatch(totalAgentTransformsSuccess(response.data.data));
           }
           else
           {
            dispatch(SetErrorMessage('Error occured.'));
           }

        })
        .catch(err => {
            dispatch(SetErrorMessage('Error occured.'));
        });
    };    
};

export const totalRepeatUsersReport = (apiURL,data) => {
    return dispatch => {
        dispatch(totalRepeatUserReportStart());
        let url =apiURL+`/dashboard/totalrepeateduserswithReport`;
        PostCalltoServer(url,data)
        .then(response => {
           if( response.data){               
                dispatch(totalRepeatUserReportSuccess(response.data.data));
           }
           else
           {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(totalRepeatUserReportFail(response.data.msg));
           }

        })
        .catch(err => {
            dispatch(totalRepeatUserReportSuccess(''));
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(totalRepeatUserReportFail(err.message));
        });
    };    
};

export const totalNewUsersReport = (apiURL,data) => {
    return dispatch => {
        dispatch(totalNewUserReportStart());
        let url =apiURL+`/dashboard/totalnewuserswithReport`;
        PostCalltoServer(url,data)
        .then(response => {
           if( response.data){               
                dispatch(totalNewUserReportSuccess(response.data.data));
           }
           else
           {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(totalNewUserReportFail(response.data.msg));
           }

        })
        .catch(err => {
            dispatch(totalUserReportSuccess(''));
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(totalNewUserReportFail(err.message));
        });
    };    
};

export const totalunansweredReport = (apiURL,data) => {
    return dispatch => {
        dispatch(totalunansweredReportStart());
        let url =apiURL+`/dashboard/totalunansweredReport`;
        PostCalltoServer(url,data)
        .then(response => {
           if( response.data){               
                dispatch(totalunansweredReportSuccess(response.data.data));
           }
           else
           {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(totalunansweredReportFail(response.data.msg));
           }

        })
        .catch(err => {
            dispatch(totalunansweredReportSuccess(''));
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(totalunansweredReportFail(err.message));
        });
    };    
};

export const totalFlowReport= (apiURL,data) => {
    return dispatch => {
        dispatch(totalFlowReportStart());
        let url =apiURL+`/dashboard/totalflowreport`;
        PostCalltoServer(url,data)
        .then(response => {
           if( response.data){               
                dispatch(totalFlowReportSuccess(response.data.data));
           }
           else
           {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(totalFlowReportFail(response.data.msg));
           }

        })
        .catch(err => {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(totalFlowReportFail(err.message));
        });
    };    
};

export const totalAgentTransferReport= (apiURL,data) => {
    return dispatch => {
        dispatch(totalAgentTransferReportStart());
        let url =apiURL+`/dashboard/totalagentransferreport`;
        PostCalltoServer(url,data)
        .then(response => {
           if( response.data){               
                dispatch(totalAgentTransferReportSuccess(response.data.data));
           }
           else
           {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(totalAgentTransferReportFail(response.data.msg));
           }

        })
        .catch(err => {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(totalAgentTransformsFail(err.message));
        });
    };    
};

export const AvgUsersReport = (apiURL,data) => {
    return dispatch => {
        dispatch(AvgUserReportStart());
        let url =apiURL+`/dashboard/avguserReport`;
        data.orgName = localStorage.getItem("orgName");
        PostCalltoServer(url,data)
        .then(response => {
           if( response.data){               
                dispatch(AvgUserReportSuccess(response.data.data));
           }
           else
           {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(AvgUserReportFail(response.data.msg));
           }

        })
        .catch(err => {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(AvgUserReportFail(err.message));
        });
    };    
};

export const ReportPageData = (data) => {
    return { type: actionTypes.REPORT_PAGE_ACTION, reportDetail:data }
}

export const exportReportData = (apiURL,data,dldUrl) => {
    return dispatch => {
        let url =apiURL+`/dashboard/`+dldUrl;
        let token = localStorage.getItem("accessToken");
        axios({
            url: url,
            headers: { 'content-type': 'application/json', 'Authorization': `Bearer ${token}`,"orgName":orgName,"userName":username },
            method: 'POST',
            data,
            responseType: 'blob', // important
        }).then(response => {
            if(response.data){
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download',orgName+'-conversationalAI-'+data.project+"-Insights" +".zip");
            document.body.appendChild(link);
            link.click();         
                dispatch(SetSuccessMessage("Reports exported successfully"));
           }
           else
           {
            dispatch(SetErrorMessage('Failed to export Reports.'));
           }
        })
        .catch(() => {
            dispatch(SetErrorMessage('Failed to export Reports.'));
        });
    };    
};

export const exportMsgCategoryReport = (apiURL,data,dldUrl) => {
    return dispatch => {
        let url =apiURL+`/dashboard/`+dldUrl;
        let token = localStorage.getItem("accessToken");
        axios({
            url: url,
            headers: { 'content-type': 'application/json', 'Authorization': `Bearer ${token}`,"orgName":orgName,"userName":username },
            method: 'POST',
            data,
            responseType: 'blob',
        }).then(response => {
            if(response.data){
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download',`${orgName}-MsgCategories-(${data.startDate})-(${data.endDate})-${data.project}-Report.zip`);
            document.body.appendChild(link);
            link.click();         
                dispatch(SetSuccessMessage("Reports exported successfully"));
           }
           else
           {
            dispatch(SetErrorMessage('Failed to export Reports.'));
           }
        })
        .catch(() => {
            dispatch(SetErrorMessage('Failed to export Reports.'));
        });
    };    
};

export const exportUnAnsMessageReport = (apiURL,data,dldUrl) => {
    return dispatch => {
        let url =apiURL+`/dashboard/`+dldUrl;
        let token = localStorage.getItem("accessToken");
        axios({
            url: url,
            headers: { 'content-type': 'application/json', 'Authorization': `Bearer ${token}`,"orgName":orgName,"userName":username },
            method: 'POST',
            data,
            responseType: 'blob',
        }).then(response => {
            if(response.data){
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download',`${orgName}-UnansMsg-(${data.startDate})-(${data.endDate})-${data.project}-Report.zip`);
            document.body.appendChild(link);
            link.click();         
                dispatch(SetSuccessMessage("Reports exported successfully"));
           }
           else
           {
            dispatch(SetErrorMessage('Failed to export Reports.'));
           }
        })
        .catch(() => {
            dispatch(SetErrorMessage('Failed to export Reports.'));
        });
    };    
};

export const exportCognitiveReportData = (apiURL,data) => {
    return dispatch => {
        let url =apiURL+`/dashboard/cognitive-search-report`;
        let token = localStorage.getItem("accessToken");
        axios({
            url: url,
            headers: { 'content-type': 'application/json', 'Authorization': `Bearer ${token}`,"orgName":orgName,"userName":username },
            method: 'POST',
            data: data,
            responseType: 'blob', // important
        }).then(response => {
            if(response.data){
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download',orgName+'-cognitivesearch-'+data.project+"-Insights" +".zip");
            document.body.appendChild(link);
            link.click();         
                dispatch(SetSuccessMessage("Reports exported successfully"));
           }
           else
           {
            dispatch(SetErrorMessage('Failed to export Reports.'));
           }
        })
        .catch(() => {
            dispatch(SetErrorMessage('Failed to export Reports.'));
        });
    };    
};

export const totalMeetingsCountStart = () => {
    return {
        type: actionTypes.TOTALMEETINGSCOUNT_START
    };
};

export const totalMeetingsCountSuccess = (status) => {
    return {
        type: actionTypes.TOTALMEETINGSCOUNT_SUCCESS,
        totalmeetingcount:status
    };
};

export const totalMeetingsCountFail = (error) => {
    return {
        type: actionTypes.TOTALMEETINGSCOUNT_FAIL,
        error: error
    };
};

export const totalMeetingsReportStart = () => {
    return {
        type: actionTypes.TOTALMEETINGSREPORT_START
    };
};

export const totalMeetingsReportSuccess = (status) => {
    return {
        type: actionTypes.TOTALMEETINGSREPORT_SUCCESS,
        totalmeetingsreport:status
    };
};

export const totalMeetingsReportFail = (error) => {
    return {
        type: actionTypes.TOTALMEETINGSREPORT_FAIL,
        error: error
    };
};

export const totalInitiatedBotReportStart = () => {
    return {
        type: actionTypes.TOTALINITIATEDBOTREPORT_START
    };
};

export const totalInitiatedBotReportSuccess = (status) => {
    return {
        type: actionTypes.TOTALINITIATEDBOTREPORT_SUCCESS,
        totalintiatedbotreport:status
    };
};

export const totalInitiatedBotReportFail = (error) => {
    return {
        type: actionTypes.TOTALINITIATEDBOTREPORT_FAIL,
        error: error
    };
};

export const totalMeetingsCount = (apiURL,data) => {
    return dispatch => {
        dispatch(totalMeetingsCountStart());
        let url =apiURL+`/dashboard/totalMeetingscheduledcount`;
        data.orgName = localStorage.getItem("orgName");
        PostCalltoServer(url,data)
        .then(response => {
           if( response.data){               
                dispatch(totalMeetingsCountSuccess(response.data.data));
           }
           else
           {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(totalMeetingsCountFail(response.data.msg));
           }

        })
        .catch(err => {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(totalMeetingsCountFail(err.message));
        });
    };    
};

export const totalMeetingReport = (apiURL,data) => {
    return dispatch => {
        dispatch(totalMeetingsReportStart());
        let url =apiURL+`/dashboard/totalmeetingscheduledreport`;
        data.orgName = localStorage.getItem("orgName");
        PostCalltoServer(url,data)
        .then(response => {
           if( response.data){               
                dispatch(totalMeetingsReportSuccess(response.data.data));
           }
           else
           {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(totalMeetingsReportFail(response.data.msg));
           }

        })
        .catch(err => {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(totalMeetingsReportFail(err.message));
        });
    };    
};

export const totalInitiatedBotReport = (apiURL,data) => {
    return dispatch => {
        dispatch(totalInitiatedBotReportStart());
        let url =apiURL+`/dashboard/totalintiatedbotreport`;
        data.orgName = localStorage.getItem("orgName");
        PostCalltoServer(url,data)
        .then(response => {
           if( response.data){               
                dispatch(totalInitiatedBotReportSuccess(response.data.data));
           }
           else
           {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(totalInitiatedBotReportFail(response.data.msg));
           }

        })
        .catch(err => {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(totalInitiatedBotReportFail(err.message));
        });
    };    
};

export const totalAnsUnansCountStart = () => {
    return {
        type: actionTypes.TOTALANSCOUNT_START
    };
};

export const totalAnsUnansCountSuccess = (status) => {
    return {
        type: actionTypes.TOTALANSCOUNT_SUCCESS,
        totalAnsUnansCount:status
    };
};

export const totalAnsUnansCountFail = (error) => {
    return {
        type: actionTypes.TOTALANSCOUNT_FAIL,
        error: error
    };
};

export const totalAnswerCount = (apiURL,data) => {
    return dispatch => {
        dispatch(totalAnsUnansCountStart());
        let url =apiURL+`/dashboard/totalansweredandunansweredcount`;
        PostCalltoServer(url,data)
        .then(response => {
           if( response.data){               
                dispatch(totalAnsUnansCountSuccess(response.data.data));
           }
           else
           {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(totalAnsUnansCountFail(response.data.msg));
           }

        }).catch(err => {
            dispatch(totalAnsUnansCountSuccess(''));
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(totalAnsUnansCountFail(err.message));
        });
    };    
};

export const msgCategoriesStart = () => {
    return {
        type: actionTypes.MSGCATEGORY_START
    };
};

export const msgCategoriesSuccess = (status) => {
    return {
        type: actionTypes.MSGCATEGORY_SUCCESS,
        msgCategoryRes:status
    };
};

export const msgCategoriesFail = (error) => {
    return {
        type: actionTypes.MSGCATEGORY_FAIL,
        error: error
    };
};

export const MsgCategoriesRes = (apiURL,data) => {
    return dispatch => {
        dispatch(msgCategoriesStart());
        let url =apiURL+`/dashboard/getmessagecategories`;
        PostCalltoServer(url,data)
        .then(response => {
           if( response.data){               
                dispatch(msgCategoriesSuccess(response.data.data));
           }
           else
           {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(msgCategoriesFail(response.data.msg));
           }

        }).catch(err => {
            dispatch(msgCategoriesSuccess(''));
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(msgCategoriesFail(err.message));
        });
    };    
};




export const msgCategoriesAnsStart = () => {
    return {
        type: actionTypes.MSGCATEGORYANS_START
    };
};

export const msgCategoriesAnsSuccess = (status) => {
    return {
        type: actionTypes.MSGCATEGORYANS_SUCCESS,
        msgCategoryAnsRes:status
    };
};

export const msgCategoriesAnsFail = (error) => {
    return {
        type: actionTypes.MSGCATEGORYANS_FAIL,
        error: error
    };
};

export const MsgCategoriesAnsRes = (apiURL,data) => {
    return dispatch => {
        dispatch(msgCategoriesAnsStart());
        let url =apiURL+`/dashboard/getansweredandUnansweredcategories`;
        PostCalltoServer(url,data)
        .then(response => {
           if( response.data){               
                dispatch(msgCategoriesAnsSuccess(response.data.data));
           }
           else
           {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(msgCategoriesAnsFail(response.data.msg));
           }

        }).catch(err => {
            dispatch(msgCategoriesAnsSuccess(''));
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(msgCategoriesAnsFail(err.message));
        });
    };    
};

export const ConAnsUnsCountStart = () => {
    return {
        type: actionTypes.CONANSUNSCOUNT_START
    };
};

export const ConAnsUnsCountSuccess = (status) => {
    return {
        type: actionTypes.CONANSUNSCOUNT_SUCCESS,
        CoversationCount:status
    };
};

export const ConAnsUnsCountFail = (error) => {
    return {
        type: actionTypes.CONANSUNSCOUNT_FAIL,
        error: error
    };
};

export const ConAnsUnsCountRes = (apiURL,data) => {
    return dispatch => {
        dispatch(ConAnsUnsCountStart());
        let url =apiURL+`/dashboard/totalansweredandunansweredcount`;
        PostCalltoServer(url,data)
        .then(response => {
           if( response.data){               
                dispatch(ConAnsUnsCountSuccess(response.data.data));
           }
           else
           {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(ConAnsUnsCountFail(response.data.msg));
           }

        }).catch(err => {
            dispatch(ConAnsUnsCountSuccess(''));
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(ConAnsUnsCountFail(err.message));
        });
    };    
};


export const ConAnsUnsCountReportStart = () => {
    return {
        type: actionTypes.CONANSUNSCOUNTREPORT_START
    };
};

export const ConAnsUnsCountReportSuccess = (status) => {
    return {
        type: actionTypes.CONANSUNSCOUNTREPORT_SUCCESS,
        ConversationReport:status
    };
};

export const ConAnsUnsCountReportFail = (error) => {
    return {
        type: actionTypes.CONANSUNSCOUNTREPORT_FAIL,
        error: error
    };
};

export const ConAnsUnsCountReportRes = (apiURL,data) => {
    return dispatch => {
        dispatch(ConAnsUnsCountReportStart());
        let url =apiURL+`/dashboard/totalansweredandunansweredreport`;
        PostCalltoServer(url,data)
        .then(response => {
           if( response.data){               
                dispatch(ConAnsUnsCountReportSuccess(response.data.data));
           }
           else
           {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(ConAnsUnsCountReportFail(response.data.msg));
           }

        }).catch(err => {
            dispatch(ConAnsUnsCountReportSuccess(''));
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(ConAnsUnsCountReportFail(err.message));
        });
    };    
};
//http://localhost:9090/botbuilder/dashboard/totalUnansweredcategories

//http://localhost:9090/botbuilder/dashboard/getUnansweredUtterances


export const totalUnansCatStart = () => {
    return {
        type: actionTypes.UNANSWERED_CAT_START
    };
};

export const totalUnansCatSuccess = (status) => {
    return {
        type: actionTypes.UNANSWERED_CAT_SUCCESS,
        UnansweredCatReponse:status
    };
};

export const totalUnansCatFail = (error) => {
    return {
        type: actionTypes.UNANSWERED_CAT_FAIL,
        error: error
    };
};

export const totalUnansweredCat = (apiURL,data) => {
    return dispatch => {
        dispatch(totalUnansCatStart());
        let url =apiURL+`/dashboard/totalUnansweredcategories`;
        PostCalltoServer(url,data)
        .then(response => {
           if( response.data){               
                dispatch(totalUnansCatSuccess(response.data.data));
           }
           else
           {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(totalUnansCatFail(response.data.msg));
           }

        }).catch(err => {
            dispatch(totalUnansCatSuccess(''));
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(totalUnansCatFail(err.message));
        });
    };    
};


export const getUnansUtterancesStart = () => {
    return {
        type: actionTypes.UNANSWERED_UTTERENCE_START
    };
};

export const getUnansUtterancesSuccess = (status) => {
    return {
        type: actionTypes.UNANSWERED_UTTERENCE_SUCCESS,
        UnansweredUtterances:status
    };
};

export const getUnansUtterancesFail = (error) => {
    return {
        type: actionTypes.UNANSWERED_UTTERENCE_FAIL,
        error: error
    };
};

export const getUnansUtterances = (apiURL,data) => {
    return dispatch => {
        dispatch(getUnansUtterancesStart());
        let url =apiURL+`/dashboard/getUnansweredUtterances`;
        PostCalltoServer(url,data)
        .then(response => {
           if( response.data){               
                dispatch(getUnansUtterancesSuccess(response.data.data));
           }
           else
           {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(getUnansUtterancesFail(response.data.msg));
           }

        }).catch(err => {
            dispatch(getUnansUtterancesSuccess(''));
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(getUnansUtterancesFail(err.message));
        });
    };    
};


export const AgentTransferCatStart = () => {
    return {
        type: actionTypes.AGENTTRANSFER_CAT_START
    };
};

export const AgentTransferCatSuccess = (status) => {
    return {
        type: actionTypes.AGENTTRANSFER_CAT_SUCCESS,
        AgentTransferCat:status
    };
};

export const AgentTransferCatFail = (error) => {
    return {
        type: actionTypes.AGENTTRANSFER_CAT_FAIL,
        error: error
    };
};

export const TotalAgentTransferCat = (apiURL,data) => {
    return dispatch => {
        dispatch(AgentTransferCatStart());
        let url =apiURL+`/dashboard/totalagenttransfercategories`;
        PostCalltoServer(url,data)
        .then(response => {
           if( response.data){               
                dispatch(AgentTransferCatSuccess(response.data.data));
           }
           else
           {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(AgentTransferCatFail(response.data.msg));
           }

        }).catch(err => {
            dispatch(AgentTransferCatSuccess(''));
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(AgentTransferCatFail(err.message));
        });
    };    
};


export const AgentTransUtterancesStart = () => {
    return {
        type: actionTypes.AGENTTRANSFER_UTTERENCE_START
    };
};

export const AgentTransUtterancesSuccess = (status) => {
    return {
        type: actionTypes.AGENTTRANSFER_UTTERENCE_SUCCESS,
        AgentTransferUtterances:status
    };
};

export const AgentTransUtterancesFail = (error) => {
    return {
        type: actionTypes.AGENTTRANSFER_UTTERENCE_FAIL,
        error: error
    };
};

export const AgentTransUtterances = (apiURL,data) => {
    return dispatch => {
        dispatch(AgentTransUtterancesStart());
        let url =apiURL+`/dashboard/getAgenttransferUtterances`;
        PostCalltoServer(url,data)
        .then(response => {
           if( response.data){               
                dispatch(AgentTransUtterancesSuccess(response.data.data));
           }
           else
           {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(AgentTransUtterancesFail(response.data.msg));
           }

        }).catch(err => {
            dispatch(AgentTransUtterancesSuccess(''));
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(AgentTransUtterancesFail(err.message));
        });
    };    
};

export const TopAgentTransStart = () => {
    return {
        type: actionTypes.TOP_AGENTTRANSFER_START
    };
};

export const TopAgentTransSuccess = (status) => {
    return {
        type: actionTypes.TOP_AGENTTRANSFER_SUCCESS,
        AgentTransferUtterances:status
    };
};

export const TopAgentTransFail = (error) => {
    return {
        type: actionTypes.TOP_AGENTTRANSFER_FAIL,
        error: error
    };
};

export const TopAgentTransferData = (apiURL,data) => {
    return dispatch => {
        dispatch(TopAgentTransStart());
        let url =apiURL+`/dashboard/topagentscount`;
        PostCalltoServer(url,data)
        .then(response => {
           if( response.data){               
                dispatch(TopAgentTransSuccess(response.data.data));
           }
           else
           {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(TopAgentTransFail(response.data.msg));
           }

        }).catch(err => {
            dispatch(TopAgentTransSuccess(''));
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(TopAgentTransFail(err.message));
        });
    };    
};


export const TotalCogUserReportStart = () => {
    return {
        type: actionTypes.TOTAL_COGUSER_REPORT_START
    };
};

export const TotalCogUserReportSuccess = (status) => {
    return {
        type: actionTypes.TOTAL_COGUSER_REPORT_SUCCESS,
        TotalCognitiveUserReport:status
    };
};

export const TotalCogUserReportFail = (error) => {
    return {
        type: actionTypes.TOTAL_COGUSER_REPORT_FAIL,
        error: error
    };
};

export const totalCogUserReportData = (apiURL,data) => {
    return dispatch => {
        dispatch(TotalCogUserReportStart());
        let url =apiURL+`/dashboard/total-cognitiveusers-with-Report`;
        PostCalltoServer(url,data)
        .then(response => {
           if( response.data){               
                dispatch(TotalCogUserReportSuccess(response.data.data));
           }
           else
           {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(TotalCogUserReportFail(response.data.msg));
           }

        }).catch(err => {
            dispatch(TotalCogUserReportSuccess(''));
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(TotalCogUserReportFail(err.message));
        });
    };    
};

export const TotalTriageReportStart = ()=>{
    return{
        type:actionTypes.TOTAL_TRIAGE_REPORT_START
    }
};

export const TotalTriageReportSuccess = (status) =>{
    return{
        type:actionTypes.TOTAL_TRIAGE_REPORT_SUCCESS,
        TotalTriageReport:status
    }
};

export const TotalTriageReportFail = (error) => {
    return {
        type: actionTypes.TOTAL_TRAIGE_REPORT_FAIL,
        error: error
    };
};

export const totalTriageReportData = (apiURL,data) => {
    return dispatch => {
        dispatch(TotalTriageReportStart());
        let url =apiURL+`/dashboard/triage`;
        PostCalltoServer(url,data)
        .then(response => {
           if( response.data){               
                dispatch(TotalTriageReportSuccess(response.data.data));
           }
           else
           {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(TotalTriageReportFail(response.data.msg));
           }

        }).catch(err => {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(TotalTriageReportFail(err.message));
        });
    };    
};

export const conversationalDataStart = ()=>{
    return{
        type:actionTypes.CONVERSATIONAL_METRICS_START
    }
};

export const conversationalDataSuccess = (status) =>{
    return{
        type:actionTypes.CONVERSATIONAL_METRICS_SUCCESS,
        conMetricsData:status
    }
};

export const conversationalDataFail = (error) => {
    return {
        type: actionTypes.CONVERSATIONAL_METRICS_FAIL,
        error: error
    };
};

export const conversationalMetricsData = (apiURL,data) => {
    return dispatch => {
        dispatch(conversationalDataStart());
        let url =apiURL+`/dashboard/conversational-metrics`;
        PostCalltoServer(url,data)
        .then(response => {
           if( response.data){               
                dispatch(conversationalDataSuccess(response.data.data));
           }
           else
           {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(conversationalDataFail(response.data.msg));
           }

        }).catch(err => {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(conversationalDataFail(err.message));
        });
    };    
};

export const cognitiveDataStart = ()=>{
    return{
        type:actionTypes.COGNITIVE_METRICS_START
    }
};

export const cognitiveDataSuccess = (status) =>{
    return{
        type:actionTypes.COGNITIVE_METRICS_SUCCESS,
        cogMetricsData:status
    }
};

export const cognitiveDataFail = (error) => {
    return {
        type: actionTypes.COGNITIVE_METRICS_FAIL,
        error: error
    };
};

export const cognitiveMetricsData = (apiURL,data) => {
    return dispatch => {
        dispatch(cognitiveDataStart());
        let url =apiURL+`/dashboard/cognitive-metrics`;
        PostCalltoServer(url,data)
        .then(response => {
           if( response.data){               
                dispatch(cognitiveDataSuccess(response.data.data));
           }
           else
           {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(cognitiveDataFail(response.data.msg));
           }

        }).catch(err => {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(cognitiveDataFail(err.message));
        });
    };    
};

export const conversationFeedbackStart = ()=>{
    return{
        type:actionTypes.CONVERSATIONAL_FEEDBACK_START
    }
};

export const conversationFeedbackSuccess = (status) =>{
    return{
        type:actionTypes.CONVERSATIONAL_FEEDBACK_SUCCESS,
        conFeedbackMetricsData:status
    }
};

export const conversationFeedbackFail = (error) => {
    return {
        type: actionTypes.CONVERSATIONAL_FEEDBACK_FAIL,
        error: error
    };
};

export const conversationFeedbackMetricsData = (apiURL,data) => {
    console.log('api',data)
    return dispatch => {
        dispatch(conversationFeedbackStart());
        let url =apiURL+`/dashboard/conversational-feedback-metrics`;
        PostCalltoServer(url,data)
        .then(response => {
           if( response.data){               
                dispatch(conversationFeedbackSuccess(response.data.data));
           }
           else
           {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(conversationFeedbackFail(response.data.msg));
           }

        }).catch(err => {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(conversationFeedbackFail(err.message));
        });
    };    
};

export const conFeedbackNegativeUtteranceStart = ()=>{
    return{
        type:actionTypes.CONVERSATIONAL_NEGATIVE_UTTERANCE_START
    }
};

export const conFeedbackNegativeUtteranceSuccess = (status) =>{
    return{
        type:actionTypes.CONVERSATIONAL_NEGATIVE_UTTERANCE_SUCCESS,
        conNegativeUtteranceData:status
    }
};

export const conFeedbackNegativeUtteranceFail = (error) => {
    return {
        type: actionTypes.CONVERSATIONAL_NEGATIVE_UTTERANCE_FAIL,
        error: error
    };
};

export const conFeedbackNegativeUtterance = (apiURL,data) => {
    return dispatch => {
        dispatch(conFeedbackNegativeUtteranceStart());
        let url =apiURL+`/dashboard/conversational-feedback-negative-utterances`;
        PostCalltoServer(url,data)
        .then(response => {
           if( response.data){               
                dispatch(conFeedbackNegativeUtteranceSuccess(response.data.data));
           }
           else
           {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(conFeedbackNegativeUtteranceFail(response.data.msg));
           }

        }).catch(err => {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(conFeedbackNegativeUtteranceFail(err.message));
        });
    };    
};
export const conversationalHandledStart = ()=>{
    return{
        type:actionTypes.CONVERSATIONAL_HANDLED_START
    }
};

export const conversationalHandledSuccess = (status) =>{
    return{
        type:actionTypes.CONVERSATIONAL_HANDLED_SUCCESS,
        conHandledData:status
    }
};

export const conversationalHandledFail = (error) => {
    return {
        type: actionTypes.CONVERSATIONAL_HANDLED_FAIL,
        error: error
    };
};

export const conversationalHandled = (apiURL,data) => {
    return dispatch => {
        dispatch(conversationalHandledStart());
        let url =apiURL+`/dashboard/conversational-handles`;
        PostCalltoServer(url,data)
        .then(response => {
           if( response.data){               
                dispatch(conversationalHandledSuccess(response.data.data));
           }
           else
           {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(conversationalHandledFail(response.data.msg));
           }

        }).catch(err => {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(conversationalHandledFail(err.message));
        });
    };    
};

export const conversationMsgUtterancesStart = ()=>{
    return{
        type:actionTypes.CONVERSATIONAL_MSG_UTTERANCE_START
    }
};

export const conversationMsgUtterancesSuccess = (status) =>{
    return{
        type:actionTypes.CONVERSATIONAL_MSG_UTTERANCE_SUCCESS,
        conMsgUtterancesData:status
    }
};

export const conversationMsgUtterancesFail = (error) => {
    return {
        type: actionTypes.CONVERSATIONAL_MSG_UTTERANCE_FAIL,
        error: error
    };
};

export const conversationMsgUtterances = (apiURL,data) => {
    return dispatch => {
        dispatch(conversationMsgUtterancesStart());
        let url =apiURL+`/dashboard/conversational-message-utterances`;
        PostCalltoServer(url,data)
        .then(response => {
           if( response.data){               
                dispatch(conversationMsgUtterancesSuccess(response.data.data));
           }
           else
           {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(conversationMsgUtterancesFail(response.data.msg));
           }

        }).catch(err => {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(conversationMsgUtterancesFail(err.message));
        });
    };    
};
export const conUnansweredUtterancesStart = ()=>{
    return{
        type:actionTypes.CONVERSATIONAL_UNANS_UTTERANCE_START
    }
};

export const conUnansweredUtterancesSuccess = (status) =>{
    return{
        type:actionTypes.CONVERSATIONAL_UNANS_UTTERANCE_SUCCESS,
        conUnansUtterancesData:status
    }
};

export const conUnansweredUtterancesFail = (error) => {
    return {
        type: actionTypes.CONVERSATIONAL_UNANS_UTTERANCE_FAIL,
        error: error
    };
};

export const conversationUnansweredUtterances = (apiURL,data) => {
    return dispatch => {
        dispatch(conUnansweredUtterancesStart());
        let url =apiURL+`/dashboard/conversational-unanswered-utterances`;
        PostCalltoServer(url,data)
        .then(response => {
           if( response.data){               
                dispatch(conUnansweredUtterancesSuccess(response.data.data));
           }
           else
           {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(conUnansweredUtterancesFail(response.data.msg));
           }

        }).catch(err => {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(conUnansweredUtterancesFail(err.message));
        });
    };    
};