import get from 'lodash/get';
import { GetCalltoServer } from '../../../utility';
import getProjectDetails from '../../../../containers/SettingPage/SettingInnerPages/helpers/getProjectDetails';
import fetchProductTriageDataFailureAction from './fetchProductTriageDataFailureAction';
import fetchProductTriageDataStartAction from './fetchProductTriageDataStartAction';
import fetchProductTriageDataSuccessAction from './fetchProductTriageDataSuccesAction';

const fetchProductTriageDataAction = (payload,projectview) => (dispatch) => {
	const projectName = getProjectDetails();
    const project = (projectview)? projectview: projectName
    const url = payload.INSIGHTS_API_URL + `/product-intelligent-triage/insights/productdetails/${project}`;   
    
    dispatch(fetchProductTriageDataStartAction());
    GetCalltoServer(url)
        .then(response => {
            if (get(response, 'data.status.responseType', '') === "success") {
                dispatch(fetchProductTriageDataSuccessAction(get(response, 'data.data', {})));
            }
            else {
                dispatch(fetchProductTriageDataFailureAction(response.data.status.responseType))
            }
        }).catch(err => {
            dispatch(fetchProductTriageDataFailureAction(err.message))
        });
};

export default fetchProductTriageDataAction;