import * as React from "react";
import "./Search-component.css";
import SearchIcon from "@material-ui/icons/Search";

const SearchComponent = (props) => {
  const inputRef = React.useRef();
  React.useEffect(() => {
    inputRef.current.onblur = function () {
      inputRef.current.value = "";
    };
  }, []);
  return (
    //  <div class="input-group searchClass"B
    <div className={props.class}>
      <input
        className="form-control border-right-0"
        ref={inputRef}
        type="text"
        placeholder={"Search"}
        id="example-search-input"
        onChange={props.onSearch}
        value={props.value}
        onKeyPress={props.onKeyPress}
      />
      <span class="input-group-append">
        <div class="input-group-text bg-white">
          <SearchIcon />
        </div>
      </span>
    </div>
  );
};

export default SearchComponent;
