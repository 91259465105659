import * as React from 'react';
import './triage-search.css';
import SearchIcon from '@material-ui/icons/Search';

 const TriageSearchComponent = (props) => {
    return(
      <div className={props.class}>  
                 <input data-testid={`inputelement`} className="form-control border-right-0" type="text" placeholder={"Search"} 
                 id="example-search-input" onChange={props.onSearch} value={props.value} onKeyPress={props.onKeyPress}/>
                <span className="input-group-append">
                <div className="input-group-text bg-white">
                <SearchIcon />
                  </div>
                 </span>
            
        </div>
   )
}

export default TriageSearchComponent;