import React from 'react';
import Grid from "@material-ui/core/Grid";
import { Row } from 'react-bootstrap';
import moment from 'moment';
import MetricCard from './innerPages/MetricCard';
import { Doughnut } from 'react-chartjs-2';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import SpinnerPage from '../../components/UI/Loader/Loader';
import ReactSelectComponent from '../SettingPage/SettingInnerPages/components/ReactSelectComponent/ReactSelectComponent';
import ButtonComponent from '../../components/UIComponents/Button/ButtonComponent';
import './reportDashboard.css';
import PredefinedRanges from '../../components/UI/DatetimeRangePicker/PredefinedRanges';

let data = {
    datasets: [
        {
            label: "Product Triage",
            data: [5, 3, 2],
            backgroundColor: ['rgba(20, 0, 255, 0.5)', 'rgba(29, 34, 30, 0.8)', 'rgba(255, 46, 0, 0.5)'],
            borderColor: "transparent",
        }
    ],
}
class Chatbox extends React.Component {
    constructor(props) {
        super(props);
        }
    render() {
        return (
            <div className={`Pt-container ${this.props.data.borderClass}`}>
                <div>
                    <img src={this.props.data.image} />
                </div>
                <div>
                    <div className={`graph-count ${this.props.data.textColor}`}>{this.props.data.count}</div>
                    <div className={`graph-label ${this.props.data.textColor}`}>{this.props.data.label}</div>
                </div>
            </div>
        )
    }
}

class ProductTriageDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            statusData: {
                heading: 'Status',
                headerIcon: '',
                backgroundColor: '#043B4F',
                icon: './Icons/training-completed.png'
            },
            symptomsCount: {
                heading: 'Symptoms',
                headerIcon: '',
                backgroundColor: '#054B7D',
                icon: './Icons/symptoms.png'
            },
            rootCausesCount: {
                heading: 'Root Causes Generated',
                headerIcon: '',
                backgroundColor: '#007EA7',
                icon: './Icons/root-causes.png',
            },
            successRatioCount: {
                heading: 'Success Ratio',
                headerIcon: '',
                backgroundColor: '#646364',
                label1: '',
                label2: '',
                icon: './Icons/success-ratio.png',
                successful: 0,
                unSuccessful: 0
            },
            modalScore: {
                heading: 'Modal Score',
                headerIcon: '',
                backgroundColor: '#090768',
                icon: './Icons/modal-score.png',
            },
            averageTimeData: {
                heading: 'Average Time Consumed for Ticket Resolution',
                headerIcon: '',
                backgroundColor: '#3B4752',
                icon: './Icons/average-time.png',
            },
            projectname: this.props.projectList?.bots[0],
            startDate: moment().subtract(6, 'days'),
            endDate: moment(),
        }
    }
    chatboxContent = [{
        borderClass: 'Pt-chatbox',
        textColor: 'Pt-chatbox-text',
        image: './Icons/chatIcon.png',
        count: '30%',
        label: 'CHAT'
    },
    {
        borderClass: 'Pt-IVRbox',
        textColor: 'Pt-IVRbox-text',
        image: './Icons/IVR.png',
        count: '50%',
        label: 'IVR'
    },
    {
        borderClass: 'Pt-emailbox',
        textColor: 'Pt-emailbox-text',
        image: './Icons/emailIcon.png',
        count: '20%',
        label: 'EMAIL'
    }]

    UsersDetails = () => {
        let projName = this.state.projectname
        let apiURL = this.props.config.BOTBUILDER_URL;
        let data = {
            "startDate": moment(this.state.startDate).format('YYYY-MM-DD'),
            "endDate": moment(this.state.endDate).format('YYYY-MM-DD'),
            "project": projName,
            "orgName": localStorage.getItem('orgName'),
        }
            this.props.totalTriageReportData(apiURL, data)
    }

    handleDidmountCall = () => {
        if(this.props.projectList){
            this.setState({
                projectname:this.props.projectList?.bots[0]
            })
        }
    }
    callUsersDetails = (data) => {
        let apiURL = this.props.config.BOTBUILDER_URL;
        this.props.totalTriageReportData(apiURL, data)

    }

    componentDidMount() {
        this.props.TotalTriageReportStart()
        setTimeout(() => {
                this.handleDidmountCall();
                console.log('didmount',this.state.projectname)
                let apiData = {
                    "startDate": moment(this.state.startDate).format('YYYY-MM-DD'),
                    "endDate": moment(this.state.endDate).format('YYYY-MM-DD'),
                    "project": this.state.projectname,
                    "orgName": localStorage.getItem('orgName'),
                }
                this.callUsersDetails(apiData);
        }, 2000)
      
    }

    projectclick = (data) => {
        if (data.value) {
            this.setState({ projectname: data.value});
            this.props.onchangeProjectAction(data.value);
            setTimeout(() => {
                this.UsersDetails();
            }, 100);

            let nrwdata = {
                "startDate": moment(this.state.startDate).format('YYYY-MM-DD'),
                "endDate": moment(this.state.endDate).format('YYYY-MM-DD'),
                "project": data.value,
                "orgName": localStorage.getItem('orgName'),
            }
            this.props.ReportPageData(nrwdata)
        }
    }
    getDateValues = (dateValue) => {
        let projName = this.state.projectname
        this.setState({
            startDate: dateValue.startDate,
            endDate: dateValue.endDate
        })
        setTimeout(() => {
            this.UsersDetails()
        }, 100);
        let data = {
            "startDate": moment(dateValue.startDate).format('YYYY-MM-DD'),
            "endDate": moment(dateValue.endDate).format('YYYY-MM-DD'),
            "project": projName,
            "orgName": localStorage.getItem('orgName'),
        }
        this.props.ReportPageData(data)

    };

    render() {
        this.props.projectList.bots.sort()
        let projectsList = ''
        if(this.props.projectList){
            let newArray = [...new Set(this.props.projectList.bots)]
            projectsList = newArray.map((ele)=>{
                return { value:ele,label:ele }
            })
        }
        let totalTriageData = this.props.totalTriageData
        let modelStatus = totalTriageData && totalTriageData.modelStatus ? totalTriageData.modelStatus : '';
        let uniqueSymptoms = totalTriageData && totalTriageData.uniqueSymptoms ? totalTriageData.uniqueSymptoms : 0;
        let uniqueRootCause = totalTriageData && totalTriageData.uniqueRootCause ? totalTriageData.uniqueRootCause : 0;
        let avgModelDuration = totalTriageData && totalTriageData.avgModelDuration ? totalTriageData.avgModelDuration : 0;
        let successfulRatio = totalTriageData && totalTriageData.successRatio ? totalTriageData.successRatio : 0;
        let unSuccessfulRatio = totalTriageData && totalTriageData.failureRatio ? totalTriageData.failureRatio : 0;
        let topFaqs = totalTriageData && totalTriageData.topFaqs;
        let modalScoreCount = totalTriageData && totalTriageData.successRatio!== 0 ? 80 : 0;
        const { statusData,
            symptomsCount,
            rootCausesCount,
            modalScore,
            successRatioCount,
            averageTimeData } = this.state;
        return (<>
        { this.props.ptCountLoading === true ? <SpinnerPage /> : null }
        <div className="botReportsTiles pt-dashboard">
                <Row className='projDropdown pt-dropdown'>
                    <div className="col-md-6">
                        <h2 className="reportsTag">Product Triage Metrics Insights</h2>
                        <div className='reportsDesc'>
                            <span className="reportsPara">
                                Provision to view triage metrics
                            </span>
                            <ButtonComponent variant='outlined'
                                label='Download Insights' className='buttonClass dld-btn'
                                clicked={() => { this.exportData() }} />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <Row className='projDropdown'>
                            <div className="col-md-6 reportsProjectList">
                                <span className="reportHeading">Project</span>
                                {<ReactSelectComponent
                                    customClassName="SelectView"
                                    value={{ value: this.state.projectname, label: this.state.projectname }}
                                    options={projectsList}
                                    name="project"
                                    onChange={(event) => this.projectclick(event)}
                                >
                                </ReactSelectComponent>}
                            </div>
                        </Row>
                        <Row>
                            <div className="col-md-6">

                            </div>
                            <div className="col-md-6 reportsDateRangeOuter">
                                <PredefinedRanges onDateChange={this.getDateValues} startDate={this.state.startDate} endDate={this.state.endDate} />
                            </div>
                        </Row>
                    </div>
                </Row>
            
            <Grid container lg={12} className="con-cards pt-metrics">
                <Grid item md={4} >
                    <MetricCard
                        heading={statusData.heading}
                        content={modelStatus}
                        icon={statusData.icon}
                        backgroundColor={statusData.backgroundColor}
                    />
                </Grid>
                <Grid item md={4}>
                    <MetricCard
                        heading={symptomsCount.heading}
                        count={uniqueSymptoms}
                        icon={symptomsCount.icon}
                        backgroundColor={symptomsCount.backgroundColor}
                    />
                </Grid>
                <Grid item md={4} >
                    <MetricCard
                        heading={rootCausesCount.heading}
                        count={uniqueRootCause}
                        icon={rootCausesCount.icon}
                        backgroundColor={rootCausesCount.backgroundColor}
                    />
                </Grid>
                <Grid item md={8} >
                    <div className='report-graph-wrapper'>
                        <div className=" mb-2 graphOuterContainer pb-2">
                            <div className='d-flex justify-content-between title-row pt-3'>
                                <span className='chart-title'>Resolution Analysis By Communication Type</span>
                            </div>
                            <div className="GraphContainer px-4" >
                                <div style={{ width: '30%' }}>
                                    <Doughnut data={data} options={{
                                        responsive: true,
                                        cutout: "70%",
                                    }}
                                    />
                                </div>
                                <div className='graphDetails'>
                                    {this.chatboxContent.map((data) => (<Chatbox data={data} />))}
                                </div>
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item md={4} className='right-2 row'>
                    <Grid item md={12}>
                        <MetricCard
                            heading={successRatioCount.heading}
                            count={successfulRatio}
                            icon={successRatioCount.icon}
                            backgroundColor={successRatioCount.backgroundColor}
                            count2={unSuccessfulRatio}
                            label1="Successful"
                            label2="UnSuccessful"
                        />

                    </Grid>
                    <Grid item md={6}>
                        <MetricCard
                            heading={modalScore.heading}
                            count={modalScoreCount}
                            icon={modalScore.icon}
                            backgroundColor={modalScore.backgroundColor}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <MetricCard
                            heading={averageTimeData.heading}
                            count={avgModelDuration}
                            icon={averageTimeData.icon}
                            backgroundColor={averageTimeData.backgroundColor}
                        />
                    </Grid>
                </Grid>
                <div className='col-md-6'>
                    <div className="handledGraphContainer hbar-container">
                        <div className="graphOuterContainer">
                            <div className='title-row'>
                                <div className='chart-title'>Top Asked Queries/Symptoms</div>
                                <div className='pt-faq'>
                                    {topFaqs?.map((item) => {
                                        return (
                                            <div className='p-item'>
                                                <div className='topFaqs'>{item}</div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className="handledGraphContainer hbar-container">
                        <div className="graphOuterContainer">
                            <div className='title-row'>
                                <div className='chart-title'>Queries Asked Frequently which are giving less confidence score</div>
                            </div>
                        </div>
                    </div>
                </div>
            </Grid>
        </div>
        </>
        )
    }
}

const mapStateToProps = state => {
    return {
        totalTriageData: state.reportDashboard.totalTriageReport,
        ptCountLoading: state.reportDashboard.ptCountLoading,
        projectList: state.user.projects,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        totalTriageReportData: (apiUrl, data) => dispatch(actions.totalTriageReportData(apiUrl, data)),
        onchangeProjectAction: (projectname) => dispatch(actions.changeProjectAction(projectname)),
        ReportPageData: (data) => dispatch(actions.ReportPageData(data)),
        TotalTriageReportStart : ()=> dispatch(actions.TotalTriageReportStart())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductTriageDashboard);

