// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.badgeClass{
    background-color: rgba(73, 71, 71, 0.62);
    border-radius: 0.56rem !important;
    color: white;
    line-height: 1.6;
    min-height: 4.7vh;
    min-width: 7vw;
    width:auto;
    cursor: pointer;
    text-align: left;
}

.closeButton{
    margin:0 0.4rem;
    font-family: 'Roboto-light';
    font-weight: normal;
    font-size: 18px;
    position: relative;
    bottom: 1.5px;
    left:-5px;
}
.badgeSpan{
    font-size: 11px !important;
    font-family: 'Roboto-bold';
    font-weight: normal;
    position: relative;
    bottom: 4px;
    left:-5px;



}`, "",{"version":3,"sources":["webpack://./src/components/UIComponents/Badge/BadgeComponent.css"],"names":[],"mappings":"AAAA;IACI,wCAAwC;IACxC,iCAAiC;IACjC,YAAY;IACZ,gBAAgB;IAChB,iBAAiB;IACjB,cAAc;IACd,UAAU;IACV,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,2BAA2B;IAC3B,mBAAmB;IACnB,eAAe;IACf,kBAAkB;IAClB,aAAa;IACb,SAAS;AACb;AACA;IACI,0BAA0B;IAC1B,0BAA0B;IAC1B,mBAAmB;IACnB,kBAAkB;IAClB,WAAW;IACX,SAAS;;;;AAIb","sourcesContent":[".badgeClass{\n    background-color: rgba(73, 71, 71, 0.62);\n    border-radius: 0.56rem !important;\n    color: white;\n    line-height: 1.6;\n    min-height: 4.7vh;\n    min-width: 7vw;\n    width:auto;\n    cursor: pointer;\n    text-align: left;\n}\n\n.closeButton{\n    margin:0 0.4rem;\n    font-family: 'Roboto-light';\n    font-weight: normal;\n    font-size: 18px;\n    position: relative;\n    bottom: 1.5px;\n    left:-5px;\n}\n.badgeSpan{\n    font-size: 11px !important;\n    font-family: 'Roboto-bold';\n    font-weight: normal;\n    position: relative;\n    bottom: 4px;\n    left:-5px;\n\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
