import React from 'react'
import { connect } from "react-redux";
import axios from "../../../instance";
import * as actions from "../../../store/actions/index";
import withErrorHandler from "../../../hoc/withErrorHandler/withErrorHandler";
import classes from './Navbar.module.css'
import { Navbar, Nav } from 'react-bootstrap';
import { useState } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuItem from '@material-ui/core/MenuItem';
import { useHistory } from "react-router-dom";
import { Paper } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SearchComponent from "../../UIComponents/SearchComponent/Search";


const useStyles = makeStyles({
  myBtn: {
    width: '150px',
    backgroundColor: '#0080ff',
    '&:hover': {
      backgroundColor: '#0080ff',
    }
  },
  projBtn: {
    width: '120px',
  },
  menuList: {
    position: 'absolute',
    marginTop: '45px',
    marginLeft: '-10px',
  },
  ModalWindow: {
    zIndex: "-1",
    position: "fixed",
    inset: "0px",
    backgroundColor: "rgba(0, 0, 0, 0.6) !important",
  },
  paperModel: {
    backgroundColor: 'white',
    zIndex: 888,
    position: 'fixed',
    left: '1px',
    top: '0px',
    minHeight: '100vh',
    width: '360px',
  },
  paperModelRight: {
    backgroundColor: 'white',
    zIndex: 888,
    position: 'fixed',
    right: '1px',
    top: '0px',
    minHeight: '100vh',
    width: '360px',
  },
  projName: {
    marginLeft: '35px',
    marginTop: '25px',
    color: "#223DB8 !important",
    textTransform: "none",
    fontFamily: 'Roboto-Black',
  },
  projectDD: {
    position: "absolute",
    width: "auto",
    height: "40px",
    left: "55px",
    top: "20px",
  },
  projectDDAdmin: {
    position: "absolute",
    width: "100%",
    height: "40px",
    right: "10px",
    top: "9px",
    justifyContent: "initial"
  },
  crawlingProjectName: {
    position: "absolute",
    width: "100%",
    height: "40px",
    right: "47px",
    top: "9px",
    justifyContent: "initial"
  },
  cognitiveFlowManagementProjectName: {
    position: "absolute",
    width: "100%",
    height: "40px",
    right: "30px",
    top: "15px",
    justifyContent: "initial"
  },
  projList: {
    marginTop: '10px',
  },
  activeList: {
    fontFamily: "Roboto-Bold !important",
    textTransform: "uppercase !important",
    fontSize: '13px !important',
    fontWeight: '600 !important',
    '&:hover': {
      color: '#2A93DF !important',
      backgroundColor: 'none !important'
    },
  },
  activeProject: {
    color: '#2A93DF !important',
    textTransform: "uppercase !important",
    fontSize: '13px !important',
    fontWeight: '600 !important',
    fontFamily: "Roboto-Bold !important",
    '&:hover': {
      backgroundColor: 'none !important'
    },
  }
})

const NavbarCustom = (props) => {
  const classesMUI = useStyles();
  const [show, setShow] = useState(true);
  const [openUI, setOpen] = React.useState(false);
  const anchorRef = React.useRef < HTMLButtonElement > (null);
  let history = useHistory();
  const closePaperModel = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handlePaperShow = () => {
    props.popperShowFn()
    setOpen(true)
  }

  const gotoProjectList = () => {
    props.onKnowledgeGridExpandCollapse("collapse");
    props.OnProjectHeaderShow(false);
    if(localStorage.getItem("fromVA")){
      history.goBack();
      localStorage.removeItem("fromVA")
    }else{
          history.push("/");
    }

  }

  let projectname = '';
  projectname = props.projectname
  let projectList = '';
  let allProjectsList = '';

  props.dataList.bots&&props.dataList.bots.sort(SortArray);
  if (props.dataList) {
    if (props.projectSelection == true) {
      projectname = "All Projects";
      let newArray = props.dataList.bots
      newArray.push(projectname);
      let newFilteredArray = [...new Set(props.dataList.bots)]
      allProjectsList = props.dataList && newFilteredArray.length > 0 && newFilteredArray.filter((item) => {
        if (props.searchTerm == "") {
          return item;
        } else if (item.toLowerCase().includes(props.searchTerm.toLowerCase())) {
          return item;
        }
      }).map((item) => {
        if (item != null) {
          return (
            <>
              <MenuItem onClick={(event) => props.projectclick(event, item)}
                className={item == localStorage.getItem("projectname") ? classesMUI.activeProject : classesMUI.activeList} key={item}>
                {item}</MenuItem>
            </>
          )
        }
      });
    } else {
      projectList = props.dataList && props.dataList.bots.length > 0 && props.dataList.bots.filter((item) => {
        if (props.searchTerm == "") {
          return item !== "All Projects";
        } else if (item.toLowerCase().includes(props.searchTerm.toLowerCase())) {
          return item !== "All Projects";
        }
      }).map((item) => {
        if (item != null) {
          return (
            <>
              <MenuItem onClick={(event) => props.projectclick(event, item)}
                className={item == localStorage.getItem("projectname") ? classesMUI.activeProject : classesMUI.activeList} key={item}>
                {item}
              </MenuItem>
            </>
          )
        }
      });
    }
  }

  function SortArray(x, y) {
    var nameA = x.toUpperCase();
    var nameB = y.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }

  const ClearSearchTerm = () => {
    props.setSearchTerm("");
    setShow(true);
  }
  const onQuickFilterChanged = (event) => {
    props.setSearchTerm(event.target.value)
    if (event.target.value != "") {
      setShow(false)
    }
    else {
      setShow(true)
    }
  }

  return (
    <Navbar collapseOnSelect expand="lg" className={["col-lg-9", classes.homeHeaderColor].join(' ')} style={{ backgroundColor: "inherit" }}>
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav>
          {props.rightPopUp == true ? <div style={{ cursor: 'pointer', position: 'relative', top: '20px' }} onClick={gotoProjectList} className="custom-arrow-back">
            <ArrowBackIcon style={{ cursor: 'pointer', ZIndex: '22222' }} />
          </div> : ''}
          <span style={{paddingRight: "0px"}} className={props.rightPopUp == true ? classes.topProjectname : classes.topProjectname2}>{!props.isVA?'Project':'Virual Assistant'}</span>
          <div className={`${props.rightPopUp == true ? classes.myDiv : ''}`}>
            <div onClick={handlePaperShow} style={{ "cursor": 'pointer' }}>
              <Button disableRipple color="primary" className={`${props.rightPopUp == true ? classesMUI.projectDD : (props.className == "crawlingProjectName") ? classesMUI.crawlingProjectName : (props.className == "cognitiveFlowManagementProjectName")? classesMUI.cognitiveFlowManagementProjectName : classesMUI.projectDDAdmin}`} onClick={handlePaperShow}>
                {<span className={props.boldViewForProjectName ? classes.projectNameForDashboard : classes.projName}>{props.commanProjectNotShow? projectname: localStorage.getItem("projectname") ? localStorage.getItem("projectname") : projectname}</span>}
                {
                  <ArrowDropDownIcon style={{ filter: " invert(47%) sepia(91%) saturate(1192%) hue-rotate(181deg) brightness(91%) contrast(91%)", position: "relative", top: "4px", zIndex: '800' }} onClick={handlePaperShow} />}
              </Button>
            </div>
            {props.popperShow && openUI ?
              <Modal open={openUI} onClose={closePaperModel} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" className={classesMUI.ModalWindow}>
                <ClickAwayListener onClickAway={closePaperModel}>
                  <Paper elevation={3} variant="outlined" square className={`${props.rightPopUp == true ? classesMUI.paperModel : classesMUI.paperModelRight}`} open={openUI} anchorEl={anchorRef.current}>
                    <h3 className={classesMUI.projName}>Select Project</h3>
                    <SearchComponent
                      className={classes.searchbar}
                      class={"input-group projectSearch"}
                      show={show}
                      value={props.searchTerm}
                      onSearch={(event) => { onQuickFilterChanged(event) }}
                      Clear={ClearSearchTerm} />
                    <div className={classes.projectListDD}>
                      {props.projectSelection == true ? allProjectsList : projectList}
                    </div>
                  </Paper>
                </ClickAwayListener>
              </Modal> : ''}
          </div>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
};

const mapStateToProps = (state) => {
  return {
    showProjectHeader:state.projectview.showProjectHeader,
    IsKnowledgeGridCollapse: state.projectview.IsKnowledgeGridCollapse,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    OnProjectHeaderShow:(showProjectHeader)=>dispatch(actions.projectHeaderShow(showProjectHeader)),
    onKnowledgeGridExpandCollapse: (expand)  => dispatch(actions.KnowledgeGridExpandCollapse(expand)) ,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorHandler(NavbarCustom, axios));
// export default NavbarCustom;
