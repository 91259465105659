const rootCauseDetailsDeleteHandlerReducer = (state, payload) => {
    const rootCauseSymptomsData = state.rootCausesTabData.rootCausesData;
    const updatedData = rootCauseSymptomsData.filter((item, index) => {
        return  index !== payload.id
    });
        
    return {
        ...state,
        rootCausesTabData: {
            ...state.rootCausesTabData,
            rootCausesData: updatedData
        }
    }
}

export default rootCauseDetailsDeleteHandlerReducer;