export const FORM_INPUT = 'FORM_INPUT';
export const INPUT_ITEM = 'INPUT_ITEM';

export const TEXTFIELD = 'TEXTFIELD';
export const CHECKBOX = 'CHECKBOX';
export const MULTISELECT = 'MULTISELECT';
export const RADIOBUTTON = 'RADIOBUTTON';
export const DROPDOWN = 'DROPDOWN';
export const TEXTBLOCK = 'TEXTBLOCK';
export const TEXTAREA = 'TEXTAREA';
export const FILEUPLOAD = 'FILEUPLOAD';
export const HEADING = 'HEADING';
export const TEXT = 'TEXT';
export const IMAGE = 'IMAGE';
export const CONTACT_NUM = 'CONTACT_NUM';
export const CALENDER = 'CALENDER';
export const CONSENT = 'CONSENT';

export const inputTypes = [
    {
      type: TEXTFIELD,
      properties: {
        label: '',
        isRequired:false,
        isPassword:false,
        id:'',
        placeholder:'',
        value:'',
        regex:'.*',
        errorMessage:'',
        validationType:'all'
    }
    },
    {
      type: TEXTAREA,
      properties: {
        isMultiline: true,
        maxLength: 500,
        label: '',
        isRequired:false,
        id:'',
        placeholder:'',
        value:'',
        errorMessage:''
    }
    },
    {
      type: MULTISELECT,
      properties: { 
        isMultiSelect: true,       
        label: '',
        isRequired:false,
        id:'',
        value:'',
        errorMessage:'',
        isReference:false,
        referenceVariable:'',
        count:2,
        choices: [
          {
            title: '',
            value: ''
          },
          {
            title: '',
            value: ''
          }
        ]

    }
    },
    {
      type: RADIOBUTTON,
      properties: { 
        isMultiSelect: false,       
        label: '',
        isRequired:false,
        id:'',
        value:'',
        errorMessage:'',
        isReference:false,
        referenceVariable:'',
        count:2,
        choices: [
          {
            title: '',
            value: ''
          },
          {
            title: '',
            value: ''
          }
        ]

    }
    },
    {
      type: DROPDOWN,
      properties: { 
        isMultiSelect: false,       
        label: '',
        isRequired:false,
        id:'',
        value:'',
        errorMessage:'',
        isReference:false,
        referenceVariable:'',
        count:2,
        choices: [
          {
            title: '',
            value: ''
          },
          {
            title: '',
            value: ''
          }
        ]

    }
    },
    {
      type: HEADING,
      properties: {
        text: '',
        horizontalAlignment:'left',
        size:'medium',
        style:'heading'
      }
    },
    {
      type: TEXT,
      properties: {
        text: '',
        horizontalAlignment:'left'
      }
    },
    {
      type: FILEUPLOAD,
      properties: {
        id:'',
        label: '',
        isRequired:false,
        fileType:['All'],
        validationInfo:'',
        errorMessage:'',
        isMinimized:false,
        isMultipleFiles:false,
        fileSize:10
    }
    },
    {
      type: IMAGE,
      properties: {
        url:'',
        horizontalAlignment:'',
        size:'auto'
    }
    },
    {
      type: CONTACT_NUM,
      properties: {
        id_cc:'',
        id_ph:'',
        label: '',
        isRequired:false,
        value:'',
        errorMessage:'',
        placeholder_cc:'',
        placeholder_ph:''
    }
    },
    {
      type: CALENDER,
      properties: {
        label: '',
        isRequired:false,
        id:'',
        placeholder:'',
        value:'',
        errorMessage:'',
        max:'9999-12-31'
    }
    },
    {
      type: CONSENT,
      properties: {
        label: '',
        isRequired:false,
        id:'',
        url:'',
        text:'',
        agreeLabel:'I Agree',
        disagreeLabel:'I Don\'t Agree',
        valueType:'url',
        modifiedLabel:'',
        errorMessage:''
    }
    }
  ];
  