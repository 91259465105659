import * as actionTypes from './actionTypes';
import { PostCalltoServer } from '../utility';
export const checkiTSExtensionEngineStatusFailure=(error)=> { return { type: actionTypes.CHECK_ITS_EX_ENGN_FAILURE, error }; };
export const checkiTSExtensionEngineStatusSuccess=(response)=> { return { type: actionTypes.CHECK_ITS_EX_ENGN_SUCCESS, response }}
export const checkiTSExtensionEngineStatus=(apiURL) =>{
    return dispatch => {
        let url=apiURL+`/checkiTSExtensionEngineStatus`;
        PostCalltoServer(url,{})
        .then(response=>{
            if(response.data.status.responseType == "success"){
                dispatch(checkiTSExtensionEngineStatusSuccess(response.data.data))
                
            }       
            else{
                dispatch(checkiTSExtensionEngineStatusFailure(response.status))
            }       
        }).catch(err => {
            console.log(err);
        });
    }; 
};

export const checkUploadEngineStatusFailure=(error)=> { return { type: actionTypes.CHECK_UPLOAD_ENGN_FAILURE, error }; };
export const checkUploadEngineStatusSuccess=(response)=> { return { type: actionTypes.CHECK_UPLOAD_ENGN_SUCCESS, response }}
export const checkUploadEngineStatus=(apiURL) =>{
    return dispatch => {
        let url=apiURL+`/checkUploadEngineStatus`;
        PostCalltoServer(url,{})
        .then(response=>{
            if(response.data.status.responseType == "success"){
                dispatch(checkUploadEngineStatusSuccess(response.data.data))
            }       
            else{
                dispatch(checkUploadEngineStatusFailure(response.status))
            }       
        }).catch(err => {
            console.log(err);
        });
    }; 
};

export const getdsStatusFailure=(error)=> { return { type: actionTypes.GET_STATUS_FAILURE, error }; };
export const getdsStatusSuccess=(response)=> { return { type: actionTypes.GET_STATUS_SUCCESS, response }}
export const getdsStatus=(apiURL) =>{
    return dispatch => {
        let url=apiURL+`/checkDSStatus`;
        PostCalltoServer(url,{})
        .then(response=>{
            if(response.data.status.responseType == "success"){
                dispatch(getdsStatusSuccess(response.data.data))
            }       
            else{
                dispatch(getdsStatusFailure(response.status))
            }       
        }).catch(err => {
            console.log(err);
        });
    }; 
};

export const getDashboardStatusFailure=(error)=> { return { type: actionTypes.GET_DASHBOARD_STATUS_FAILURE, error }; };
export const getDashboardStatusSuccess=(response)=> { return { type: actionTypes.GET_DASHBOARD_STATUS_SUCCESS, response }}
export const getDashboardStatus=(apiURL) =>{
    return dispatch => {
        let url=apiURL+`/checkDashboardStatus`;
        PostCalltoServer(url,{})
        .then(response=>{
            if(response.data.status.responseType == "success"){
                dispatch(getDashboardStatusSuccess(response.data.data))
                
            }       
            else{
                dispatch(getDashboardStatusFailure(response.status))
            }       
        }).catch(err => {
            console.log(err);
        });
    }; 
};