import get from 'lodash/get';

const issueClarificationOptionalFieldChangeHandlerReducer = (state, payload) => {
if(payload.isDefault) {
    const clusterFields = get(payload, 'data.issueclassification.ClusterFields', []);
    const issueClarificationOptionalFields = {
        issueClarificationOptionalField1: get(clusterFields, '3', ''),
        issueClarificationOptionalField2: get(clusterFields, '4', ''),
        issueClarificationOptionalField3: get(clusterFields, '5', '')
    }; 
    return {
        ...state,
        ...issueClarificationOptionalFields
    }
}
    return {
        ...state,
        ...payload
    }
}

export default issueClarificationOptionalFieldChangeHandlerReducer;