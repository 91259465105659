import React from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab'; 
const TabsView = (props) => (
    <Tabs data-testid={`tabsid`} defaultActiveKey={props.selected} onSelect={ (e) => props.setSelected(e)}>
        {props.tabsdata.map(tab =>{
            return (
                <Tab  key={tab} eventKey={tab} title={tab} > 
                    {props.children} 
                </Tab>
                
            );
        })}
    </Tabs>
);
 
export default TabsView;

