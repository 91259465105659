import clsx from 'clsx';
import './heading5.css';

const Heading5 = ({
    text,
    className,
}) => {
return <h5 className={clsx(className, 'heading5')}>{text}</h5>
};

export default Heading5;