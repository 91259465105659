// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IssueClarificationPage .Settings-icon img{height: 18px;margin-bottom: 3px;}
.IssueClarificationPage .card-header{display: flex;
  align-items: center;
  justify-content: space-between;}

  .IssueClarificationPage .IssueClarificationActions{display: flex;
    margin-right: 25px;}
    .IssueClarificationPage .form-group{position: relative;}
    .IssueClarificationPage label.form-label{position: absolute;
      top: -13px;
      background: white;
      left: 18px;
      padding: 0 10px;}
      .ClusteringParameters{border: 1px solid #ced4da;
        padding: 15px 0px 10px 20px;
      
        border-radius: 7px;
        position: relative;margin-top:15px}
        .ClusteringParameters p{position: absolute;
          top: -16px;
          left: 15px;
          padding: 0 8px;
          background: white;}
          .ClusteringParametersContent{border: 1px solid #ced4da;
            padding: 15px 0px 10px 20px;
            margin-left: -5px;
            border-radius: 7px;
            position: relative;margin-top:15px;margin-right: 10px;}
            .Ticketing{top:19px}
            .saveBtn {
              margin-top: 10px;
            }            `, "",{"version":3,"sources":["webpack://./src/components/Insights/IssueClarification/IssueClarification.css"],"names":[],"mappings":"AAAA,2CAA2C,YAAY,CAAC,kBAAkB,CAAC;AAC3E,qCAAqC,aAAa;EAChD,mBAAmB;EACnB,8BAA8B,CAAC;;EAE/B,mDAAmD,aAAa;IAC9D,kBAAkB,CAAC;IACnB,oCAAoC,kBAAkB,CAAC;IACvD,yCAAyC,kBAAkB;MACzD,UAAU;MACV,iBAAiB;MACjB,UAAU;MACV,eAAe,CAAC;MAChB,sBAAsB,yBAAyB;QAC7C,2BAA2B;;QAE3B,kBAAkB;QAClB,kBAAkB,CAAC,eAAe;QAClC,wBAAwB,kBAAkB;UACxC,UAAU;UACV,UAAU;UACV,cAAc;UACd,iBAAiB,CAAC;UAClB,6BAA6B,yBAAyB;YACpD,2BAA2B;YAC3B,iBAAiB;YACjB,kBAAkB;YAClB,kBAAkB,CAAC,eAAe,CAAC,kBAAkB,CAAC;YACtD,WAAW,QAAQ;YACnB;cACE,gBAAgB;YAClB","sourcesContent":[".IssueClarificationPage .Settings-icon img{height: 18px;margin-bottom: 3px;}\n.IssueClarificationPage .card-header{display: flex;\n  align-items: center;\n  justify-content: space-between;}\n\n  .IssueClarificationPage .IssueClarificationActions{display: flex;\n    margin-right: 25px;}\n    .IssueClarificationPage .form-group{position: relative;}\n    .IssueClarificationPage label.form-label{position: absolute;\n      top: -13px;\n      background: white;\n      left: 18px;\n      padding: 0 10px;}\n      .ClusteringParameters{border: 1px solid #ced4da;\n        padding: 15px 0px 10px 20px;\n      \n        border-radius: 7px;\n        position: relative;margin-top:15px}\n        .ClusteringParameters p{position: absolute;\n          top: -16px;\n          left: 15px;\n          padding: 0 8px;\n          background: white;}\n          .ClusteringParametersContent{border: 1px solid #ced4da;\n            padding: 15px 0px 10px 20px;\n            margin-left: -5px;\n            border-radius: 7px;\n            position: relative;margin-top:15px;margin-right: 10px;}\n            .Ticketing{top:19px}\n            .saveBtn {\n              margin-top: 10px;\n            }            "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
