import * as actionTypes from './actionTypes';


export const getBackupDetails = () => {
    return {
        type: actionTypes.GET_ES_BACKUP_DETAILS
    };
};


export const setBackupDetails = (backupInfo) => {
    return {
        type: actionTypes.SET_ES_BACKUP_DETAILS,
        backupInfo: backupInfo
    };
};

export const getRestoreList = () => {
    return {
        type: actionTypes.GET_ES_RESTORE_LIST_DETAILS
    };
};

export const setRestoreList = (restoreOptionsList) => {
    return {
        type: actionTypes.SET_ES_RESTORE_LIST_DETAILS,
        restoreOptionsList: restoreOptionsList
    };
};





