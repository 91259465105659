import * as actionTypes from './actionTypes';
import { botPostCalltoServer, deleteCalltoServer, GetCalltoServer, PostCalltoServer } from '../utility';
import {SetErrorMessage, SetSuccessMessage} from './message'

export const flowStart = () => {
    return {
        type: actionTypes.FLOWLIST_START
    };
};

export const flowSuccess = (status) => {
    return {
        type: actionTypes.FLOWLIST_SUCCESS,
        flowData:status
    };
};

export const flowFail = (error) => {
    return {
        type: actionTypes.FLOWLIST_FAIL,
        error: error
    };
};


export const categoryStart = () => {
    return {
        type: actionTypes.CATEGORYLIST_START
    };
};

export const categorySuccess = (status) => {
    return {
        type: actionTypes.CATEGORYLIST_SUCCESS,
        categoryList:status
    };
};

export const categoryFail = (error) => {
    return {
        type: actionTypes.CATEGORYLIST_FAIL,
        error: error
    };
};

export const flowListStart = () => {
    return {
        type: actionTypes.CAT_FLOWLIST_START
    };
};

export const flowListSuccess = (status) => {
    return {
        type: actionTypes.CAT_FLOWLIST_SUCCESS,
        catflowList:status
    };
};

export const flowListFail = (error) => {
    return {
        type: actionTypes.CAT_FLOWLIST_FAIL,
        error: error
    };
};

export const createcategoryStart = () => {
    return {
        type: actionTypes.CREATE_CATEGORY_START
    };
};

export const createcategorySuccess = (status,catname) => {
    return {
        type: actionTypes.CREATE_CATEGORY_SUCCESS,
       // createcategory: 'created',
          newcatname: catname
    };
};

export const createcategoryFail = (error) => {
    return {
        type: actionTypes.CREATE_CATEGORY_FAIL,
        error: error
    };
};

export const createcategorybotStart = () => {
    return {
        type: actionTypes.CREATE_CATEGORY_BOT_START
    };
};

export const createcategorybotSuccess = (status,botname) => {
    return {
        type: actionTypes.CREATE_CATEGORY_BOT_SUCCESS,
        newbotname:botname,
      //  createcategorybot: 'created'
    };
};

export const createcategorybotFail = (error) => {
    return {
        type: actionTypes.CREATE_CATEGORY_BOT_FAIL,
        error: error
    };
};

export const delcategorybotStart = () => {
    return {
        type: actionTypes.DELETE_CATEGORY_BOT_START
    };
};

export const delcategorybotSuccess = (status,botname,category,orgName) => {
    return {
        type: actionTypes.DELETE_CATEGORY_BOT_SUCCESS,
        deletecategorybot: botname,
        orgName: orgName,
        cat:category
    };
};

export const delcategorybotFail = (error) => {
    return {
        type: actionTypes.DELETE_CATEGORY_BOT_FAIL,
        error: error
    };
};


export const flowList = (apiURL) => {
    return dispatch => {
        dispatch(flowStart());
        let url =apiURL+`/project/recentFlows`;
        let userName = localStorage.getItem("userName");
        let actualuser = '';
        if(userName !== null)
        {
            if(userName.indexOf("@") > -1){
            let userNameArr = userName.split('@');
             actualuser = userNameArr[0];
            }
            else{
                actualuser=userName
            }
        }
       
        let user = {"userName":actualuser,"orgName": localStorage.getItem("orgName")};
        botPostCalltoServer(url,user)
            .then(response => {  
                if( response.data.status.responseType =="success")
               {           
                let responseData=JSON.parse(response.data.data)
                dispatch(flowSuccess(responseData.hits.hits));
               }
               else
               {
                
                dispatch(flowFail(response.data.status.responseType));
               }

            })
            .catch(err => {
                dispatch(flowFail(err.message));
            });
    };
};

export const flowListBycategory = (apiURL,cat) => {
    let language=localStorage.getItem('language')
  
    return dispatch => {
        dispatch(flowListStart());
      
      let url =apiURL+`/category/${cat}/flow/${language}`
        GetCalltoServer(url)
        .then(response => {
            if( response.data.status.responseType =="success")
           {               
           dispatch(flowListSuccess(response.data.data));
           }
           else
           {
          
           dispatch(flowListFail(response.data.status.responseType));
           }

        })
        .catch(err => {
          dispatch(flowListFail(err.message));
        });
    };
};



export const initialLoadCatData = (apiURL) => {
    let language=localStorage.getItem('language')
    return dispatch => {
        dispatch(categoryStart());
        let url =apiURL+`/category/${language}`
      

        GetCalltoServer(url)
        .then(response => {
            if(response.data.status.responseType =="success")
           {               
            dispatch(categorySuccess(response.data.data));
            if(response.data.data && response.data.data.length > 0)
            {
                dispatch(flowListBycategory(apiURL,response.data.data[0]));                    
            }
           }
           else
           {
            dispatch(categoryFail(response.data.status));
           }

        })
        .catch(err => {
            dispatch(categoryFail(err.message));
        });
    };

    
};



export const createCategory = (apiURL,catname) => {
    return dispatch => {
        dispatch(createcategoryStart());
        let category={...catname,"orgName": localStorage.getItem("orgName")}
        let url =apiURL+`/category`;
       
        botPostCalltoServer(url,category)
        .then(response => {
            if(  response.data.status.responseType =="success")
           {    
           dispatch(createcategorySuccess(response.data.status.responseType,catname.name));
         
            dispatch(SetSuccessMessage('Category created successfully.'));
           }
           else
           {
            dispatch(createcategoryFail(response.data.status.responseType));
            dispatch(SetErrorMessage('Failed to create Category'));
           }
        })
        .catch(err => {
            dispatch(createcategoryFail(err.message));
            dispatch(SetErrorMessage('Failed to create Category'));
        });
    }; 
};



export const createCategoryBot = (apiURL,catname,botname) => {
    return dispatch => {
        dispatch(createcategorybotStart());
        let url =apiURL+`/createFlow`;
        let categorybot = {project: catname,botname:botname}
        PostCalltoServer(url,categorybot)
        .then(response => {
            if( response.data.status.responseType =="success")
           {               
           
             dispatch(createcategorybotSuccess(response.data));
             dispatch(SetSuccessMessage('Flow created successfully.'));
           }
           else
           {
            dispatch(createcategorybotFail(response.data.status.responseType));
            dispatch(SetErrorMessage('Failed to create Flow'));
           }

        })
        .catch(err => {
            dispatch(createcategorybotFail(err.message));
            dispatch(SetErrorMessage(err.message));
        });
    }; 
};



export const deleteCategoryBot = (apiURL,catname,botname,language) => {
    return dispatch => {
        dispatch(delcategorybotStart());
        let url =apiURL+`/category/${catname}/flow/${botname}/${language}`;
        deleteCalltoServer(url)
        .then(response => {
            if( response.data.status.responseType=="success")
           {             
            dispatch(delcategorybotSuccess(response.data.status.responseType,botname,"Bot"));
            dispatch(SetSuccessMessage('Category Bot deleted successfully.'));
           }
           else
           {
            dispatch(delcategorybotFail(response.data.status.responseType));
            dispatch(SetErrorMessage('Failed to delete Category Bot'));
           }

        })
        .catch(err => {
            dispatch(delcategorybotFail(err.message));
            dispatch(SetErrorMessage(err.message));
        });
    }; 
};

export const deleteCategory = (apiURL,catname,language) => {
    return dispatch => {
        dispatch(delcategorybotStart());
        let url =apiURL+`/category/${catname}/${language}`;
        deleteCalltoServer(url)
        .then(response => {
            if( response.data.status.responseType=="success")
           {             
            dispatch(delcategorybotSuccess(response.data.status.responseType,catname,"Category"));
            dispatch(SetSuccessMessage('Category deleted successfully.'));
           }
           else
           {
            dispatch(delcategorybotFail(response.data.status.responseType));
            dispatch(SetErrorMessage('Failed to delete Category'));
           }

        })
        .catch(err => {
            dispatch(delcategorybotFail(err.message));
            dispatch(SetErrorMessage(err.message));
        });
    }; 
};
export const savecategoryProfileStart = () => {
    return {
        type: actionTypes.SAVE_CATEGORY_PROFILE_START
    };
};
export const savecategoryProfileFailure=(error)=> { 
    return { type: actionTypes.SAVE_CATEGORY_PROFILE_FAILURE, error
	};
};
export const  savecategoryProfileSuccess=(botProfile)=> {
     return { type: actionTypes.SAVE_CATEGORY_PROFILE_SUCCESS, botProfile 
};
	 };

export const savecategoryProfile = (profile,apiURL) => {
    return dispatch => {
        dispatch(savecategoryProfileStart());
        let url =apiURL+`/updateCategoryBot`; 
		console.log(profile)
		console.log(url)
        PostCalltoServer(url,profile).then(response=>{
            if( response.data.status.responseType=="success")
            {
                dispatch(savecategoryProfileSuccess(response.data.data));
                dispatch(SetSuccessMessage('Category Profile Saved successfully.'));
                
        }
        else{
            dispatch( savecategoryProfileFailure(response.data.status.responseType))
        } 
    }).catch(err => {
        dispatch( savecategoryProfileFailure(err))
            });     
    };
};
export const getCategoryStructureFailure=(error)=> { 
    return { type: actionTypes.GET_CATEGORYSTRUCTURE_FAILURE, error 
    }; 
};
export const  getCategoryStructureSuccess=(bot)=> {
     return { type: actionTypes.GET_CATEGORYSTRUCTURE_SUCCESS, bot 
	 };
};
	 
export const getCategoryProfileStructure = (bot,apiURL) => {
    return dispatch => {
        let url =apiURL+`/getCategoryProfileStructureList`; 
        PostCalltoServer(url,bot).then(response=>{
            if( response.data.status.responseType=="success")
            {
                dispatch( getCategoryStructureSuccess(response.data.data))                
        }
        else{
            dispatch( getCategoryStructureFailure(response.data.status.responseType))
        } 
    }).catch(err => {
        dispatch( getCategoryStructureFailure(err))
            });     
    };
};

export const getCategoryFileFailure=(error)=> { 
    return { type: actionTypes.GET_CATEGORYFILE_FAILURE, error 
    }; 
};
export const  getCategoryFileSuccess=(file)=> {
     return { type: actionTypes.GET_CATEGORYFILE_SUCCESS, file 
	};
};

export const getCategoryFile = (file,apiURL) => {
    return dispatch => {
        let url = apiURL + `/category/${file.project}/flow/${file.botname}/script/${file.language}`;
        GetCalltoServer(url).then(response=>{
            if( response.data.status.responseType=="success")
            {
                dispatch( getCategoryFileSuccess(response.data.data))                
        }
        else{
            dispatch( getCategoryFileFailure(response.data.status.responseType))
        } 
    }).catch(err => {
        dispatch( getCategoryFileFailure(err))
            });     
    };
};
export const refreshLayout = (botname,projectname) => {
    return dispatch => {
        dispatch(
             {
                type: actionTypes.REFRESH_LAYOUT,
                botname: botname,
                projectname:projectname
            }

        );
        
    }; 

};


export const createFlowFStart = () => {
    return {
        type: actionTypes.CREATE_FLOWF_START
    };
};

export const createFlowFSuccess =(bot)=>{
   
    return { type: actionTypes.CREATE_FLOWF_SUCCESS, bot }
}
 
export const createFlowFFailure=(error)=> { 
    return { type: actionTypes.CREATE_FLOWF_FAILURE, error 
    }; 
};
export const CreateFlowF = (apiURL,bot,fromImportCluster) => {
    return dispatch => {
        let user = localStorage.getItem("sessiontoken");
        user = JSON.parse(user);
        let bots={...bot,"orgName":localStorage.getItem("orgName")}
        bot.username=user.useremailid;
        dispatch(createFlowFStart());
        let url=apiURL+`/category/flow`;
        botPostCalltoServer(url,bots)
        .then(response=>{
            if( response.data.status.responseType=="success")
            {
                dispatch(createFlowFSuccess("created"));
                if(!fromImportCluster)
                dispatch(SetSuccessMessage(response.data.data));
                
            }
         /*   else{
                let errs = []
                if(response.data.errors.length>0){
                (response.data.errors.forEach(err => {
                    if(err.source)
                    errs.push(err.source)
                }));
                }
            errs = errs.join(",")
            if(errs) dispatch(SetErrorMessage(errs + ' in create flow')); 
            else dispatch(SetErrorMessage("Flow creation failed"));

            dispatch(createFlowFFailure(response.data.status.responseType))
            }      */ 
              
    }).catch(err => {
        dispatch(createFlowFFailure(err.message));
        dispatch(SetErrorMessage(err.message));
    });
};
};



export const triggerTypeStart = () => {
    return {
        type: actionTypes.TRIGGER_TYPE_START
    };
};
export const triggerTypeFailure=(error)=> { 
    return { type: actionTypes.TRIGGER_TYPE_FAILURE, error 
    }; 
};
export const triggerTypeSuccess=(intent)=> {
     return { type: actionTypes.TRIGGER_TYPE_SUCCESS, intent }}

export const UpdateTriggerTypeCatF = (intents,apiURL) => {
    return dispatch => {
        dispatch(triggerTypeStart());
        let intent={...intents,"username": localStorage.getItem('userName'),"orgName":localStorage.getItem("orgName")}
        let url =apiURL+`/updateTriggerTypeCatF`; 
        PostCalltoServer(url,intent).then(response=>{
            if( response.data.status.responseType=="success")
            {
                dispatch(triggerTypeSuccess(response.data.data));
                
        }
        else{
            dispatch(triggerTypeFailure(response.status))
        } 
    }).catch(err => {
        dispatch(triggerTypeFailure(err))
            });     
    };
};
 
export const getFlowListSuccess =(flowListData)=>{   
    return { type: actionTypes.GET_FLOW_LIST_SUCCESS, flowListData:flowListData }
}

export const getFlowListFailure=(error)=> { 
    return { type: actionTypes.GET_FLOW_LIST_FAILURE, error 
    }; 
};
export const GetFlowList = (apiURL) => {
    return dispatch => { 
        let sessiontoken =localStorage.getItem("sessiontoken");
        let data ={}
        if(sessiontoken){
            sessiontoken = JSON.parse(sessiontoken);
            data = {"username" : sessiontoken.username.replace("@hcl.com",""),"domain":sessiontoken.domain,"authtoken":sessiontoken.authtoken,"orgName":localStorage.getItem("orgName")}
        }
       
        let url=apiURL+`/fList`;
        PostCalltoServer(url,data)
       
        .then(response=>{
            if( response.data.status.responseType=="success")
            {
                dispatch(getFlowListSuccess(response.data.data));
            }       
        else{
            
            dispatch(getFlowListFailure(response.data.status.responseType))

        }       
    }).catch(err => {
        dispatch(getFlowListFailure(err.message))
    });
};
};

export const createUpdateEntityStart=()=> { 
    return { type: actionTypes.CREATE_UPDATE_ENTITY_START
    }; 
};
export const createUpdateEntityFailure=(error)=> { 
    return { type: actionTypes.CREATE_UPDATE_ENTITY_FAILURE, error 
    }; 
};
export const  createUpdateEntitySuccess=(createEntity)=> {
     return { type: actionTypes.CREATE_UPDATE_ENTITY_SUCCESS, createEntity }}

export const onCreateUpdateEntityCatC = (file,apiURL,fromFlowModal) => {
    return dispatch => {
        dispatch(createUpdateEntityStart());
        let files={...file,"username": localStorage.getItem('userName'),"orgName":localStorage.getItem("orgName")}
        let url =apiURL+`/category/flow/entity`; 
        PostCalltoServer(url,files).then(response=>{
            if( response.data.status.responseType=="success")
            {
                dispatch( createUpdateEntitySuccess(response.data.data));
            if(!fromFlowModal)
            dispatch(SetSuccessMessage('Entities saved successfully.'));
        }
        else{
            dispatch( createUpdateEntityFailure(response.data.status.responseType))
            dispatch(SetErrorMessage('Error occured.'));
            
        } 
    }).catch(err => {
        dispatch( createUpdateEntityFailure(err))
        dispatch(SetErrorMessage('Error occured.'));
            });     
    };
};

export const getListOfCategoryStart = () => {
    return {
        type: actionTypes.GET_LIST_CATEGORY_START
    };
};

export const getListOfCategorySuccess = (data) => {
    return {
        type: actionTypes.GET_LIST_CATEGORY_SUCCESS,
        listofcategory:data
    };
};

export const GetListofCategories= (apiURL,data) => {
    return dispatch => {
        let url=apiURL+`/listofCategories`;
        dispatch(getListOfCategoryStart())
        PostCalltoServer(url,data)
        .then(response=>{
        if(response.data.status.responseType == "success"){
          
            dispatch(getListOfCategorySuccess(response.data.data))
        }       
        else{            
            dispatch(SetErrorMessage("Error occured in getting project"));
        }       
    }).catch(err => {
        dispatch(SetErrorMessage(err.message));
    });
};
};

export const getEntityCatStart = () => {
    return {
        type: actionTypes.GET_ENTITY_CAT_START
    };
};
export const getEntityCatFailure=(error)=> { 
    return { type: actionTypes.GET_ENTITY_CAT_FAILURE, error 
    }; 
};
export const  getEntityCatSuccess=(entity)=> {
     return { type: actionTypes.GET_ENTITY_CAT_SUCCESS, entity }}

export const GetEntityCat = (flow,apiURL) => {
    return dispatch => {
        dispatch(getEntityCatStart());
        let url =apiURL+`/category/${flow.project}/flow/${flow.botname}/entity/${localStorage.getItem("language")}`; 
        GetCalltoServer(url).then(response=>{
            if( response.data.status.responseType=="success")
            {
                dispatch( getEntityCatSuccess(response.data.data))
        }
        else{
            dispatch( getEntityCatFailure(response.data.status.responseType))
        } 
    }).catch(err => {
        dispatch( getEntityCatFailure(err))
            });     
    };
};

export const saveBotProfileStart = () => {
    return {
        type: actionTypes.SAVE_BOT_PROFILE_START
    };
};
export const saveBotProfileFailure=(error)=> { 
    return { type: actionTypes.SAVE_BOT_PROFILE_FAILURE, error 
    }; 
};
export const  saveBotProfileSuccess=(botProfile)=> {
     return { type: actionTypes.SAVE_BOT_PROFILE_SUCCESS, botProfile }}

export const saveBotProfileFlow = (profile,apiURL) => {
    return dispatch => {
        dispatch(saveBotProfileStart());
        let profiles={...profile,"orgName":localStorage.getItem("orgName")}
        let url =apiURL+`/category/flow/script`; 
        botPostCalltoServer(url,profiles).then(response=>{
            if( response.data.status.responseType=="success")
            {
                dispatch(saveBotProfileSuccess(response.data.data));
                dispatch(SetSuccessMessage('Bot Profile Saved successfully.'));
                
        }
        else{
            dispatch( saveBotProfileFailure(response.data.status.responseType))
        } 
    }).catch(err => {
        dispatch( saveBotProfileFailure(err))
            });     
    };


};