// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.roles-content-row {
    min-height: 45px;
    background-color: #fff;
    font-family: "Roboto-Regular";
    font-size: 13px;
    /* color: #555555; */
    padding-top: 1rem;
    margin-bottom: 0.5rem;
  }

  .roles-content {
    font-family: 'Roboto-Regular';
    font-size: 12px ;
    color: #000;
    font-weight: normal;
    /* text-transform: capitalize; */
  }

  .padding-adjustment {
    padding-left: 4px;
  }

  .cursor-action{
    cursor: pointer;
  }

  .optionsItemsDropdown{
    padding:5px 10px 5px 19px!important;
    font-size: 10px;
    color:#000 !important
    
  }
  .optionsItemsDropdown:hover{
    background-color: rgb(255, 255, 255) !important;
    color: #2A93DF !important;
  }`, "",{"version":3,"sources":["webpack://./src/components/UIComponents/RowCard/RowCard.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,sBAAsB;IACtB,6BAA6B;IAC7B,eAAe;IACf,oBAAoB;IACpB,iBAAiB;IACjB,qBAAqB;EACvB;;EAEA;IACE,6BAA6B;IAC7B,gBAAgB;IAChB,WAAW;IACX,mBAAmB;IACnB,gCAAgC;EAClC;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,mCAAmC;IACnC,eAAe;IACf;;EAEF;EACA;IACE,+CAA+C;IAC/C,yBAAyB;EAC3B","sourcesContent":[".roles-content-row {\n    min-height: 45px;\n    background-color: #fff;\n    font-family: \"Roboto-Regular\";\n    font-size: 13px;\n    /* color: #555555; */\n    padding-top: 1rem;\n    margin-bottom: 0.5rem;\n  }\n\n  .roles-content {\n    font-family: 'Roboto-Regular';\n    font-size: 12px ;\n    color: #000;\n    font-weight: normal;\n    /* text-transform: capitalize; */\n  }\n\n  .padding-adjustment {\n    padding-left: 4px;\n  }\n\n  .cursor-action{\n    cursor: pointer;\n  }\n\n  .optionsItemsDropdown{\n    padding:5px 10px 5px 19px!important;\n    font-size: 10px;\n    color:#000 !important\n    \n  }\n  .optionsItemsDropdown:hover{\n    background-color: rgb(255, 255, 255) !important;\n    color: #2A93DF !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
