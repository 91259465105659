// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` .componentContainer{background-color: #ffffff;
    border-radius: 12px;
    padding:15px;
    margin: 15px;
    
 } 

 .background-container {
   background-color: #f2f1f1;
   padding-top: 2%;
   padding-bottom: 2%;
 }

 .background-container .row-no-gutters {
   margin-right: 0 !important;
   margin-left: 0 !important;
 }

 .background-container .content-scroll  {
   /* max-height: 350px; */
   overflow-y: scroll;
   overflow-x: hidden;
   /* padding-bottom: 100px; */
 }
`, "",{"version":3,"sources":["webpack://./src/containers/Components/Components.css"],"names":[],"mappings":"CAAC,oBAAoB,yBAAyB;IAC1C,mBAAmB;IACnB,YAAY;IACZ,YAAY;;CAEf;;CAEA;GACE,yBAAyB;GACzB,eAAe;GACf,kBAAkB;CACpB;;CAEA;GACE,0BAA0B;GAC1B,yBAAyB;CAC3B;;CAEA;GACE,uBAAuB;GACvB,kBAAkB;GAClB,kBAAkB;GAClB,2BAA2B;CAC7B","sourcesContent":[" .componentContainer{background-color: #ffffff;\n    border-radius: 12px;\n    padding:15px;\n    margin: 15px;\n    \n } \n\n .background-container {\n   background-color: #f2f1f1;\n   padding-top: 2%;\n   padding-bottom: 2%;\n }\n\n .background-container .row-no-gutters {\n   margin-right: 0 !important;\n   margin-left: 0 !important;\n }\n\n .background-container .content-scroll  {\n   /* max-height: 350px; */\n   overflow-y: scroll;\n   overflow-x: hidden;\n   /* padding-bottom: 100px; */\n }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
