import React, { Component } from "react";
import "./Spinner.css";


class Spinner extends Component {
  render() {
    return (
      <div data-testid={ `spin`} className="spinner">
         <div className="loading">
         <img className="SpinnerClass"/>
         </div>
      </div>
    );
  }
}

export default Spinner;
