const rootCauseDetailsUpdateHandlerReducer = (state, payload) => {
    const rootCauseSymptomsData = state.rootCausesTabData.rootCausesData;
    const updatedData = rootCauseSymptomsData.map((item, index) => {
        if (index === payload.id) {
            return {
                ...item,
                ...payload.formData
            };
        }
        return item;
    });
    return {
        ...state,
        rootCausesTabData: {
            ...state.rootCausesTabData,
            rootCausesData: updatedData
        }
    }
}

export default rootCauseDetailsUpdateHandlerReducer;