import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import InputWithLabelComponent from "../../../../../../containers/SettingPage/SettingInnerPages/components/InputWithLabelComponent/InputWithLabelComponent";
import SelectCustom from "../../../../../../components/UIComponents/SelectDropdown/SelectComponent";
import TextAreaWithLabelComponent from "../../../../../../containers/SettingPage/SettingInnerPages/components/TextAreaWithLabelComponent/TextAreaWithLabelComponent";

const Consent = ({properties, onChangeHandler, invalidVarMsg}) => {

    const displayInfoList = [{value:'url',displayValue:'Configured URL'},{value:'text',displayValue:'Text'}];


    return (
        <div>
            <div className="mt-1 settings-div">
                <Col md={12} >
                    <Row className='settings-form-switch'>
                        <Col md={6}><span className='settings-label'>Required</span></Col>
                        <Col md={6} className='settings-switch'>
                        <Form>
                                <Form.Check
                                    type="switch"
                                    id="isRequired"
                                    checked={properties.isRequired}
                                    onChange={e => onChangeHandler('isRequired',e.target.checked)}
                                />
                            </Form>
                        </Col>
                    </Row>
                </Col>
            </div>
            <div className="mt-1 settings-div">
                <Col md={12}>
                <InputWithLabelComponent
                        type="text"
                        name={'settings-id'}
                        label="Name*"
                        showLabel={true}
                        value={properties.id}
                        readOnly={false}
                        onChange={e => onChangeHandler('id',e.target.value)}
                        required={true}
                        className="settings-input"
                        isInvalid={invalidVarMsg!==null}
                        error={invalidVarMsg}
                        toolTip={true}
                        toolTipParams={{title:'Name will also be used as variables',placement:'right'}}
                  />
                </Col>
            </div>
            <div className="mt-1 settings-div">
                <Col md={12}>
                <InputWithLabelComponent
                        type="text"
                        name={'settings-label'}
                        label="Label*"
                        showLabel={true}
                        value={properties.label}
                        readOnly={false}
                        onChange={e => onChangeHandler('label',e.target.value)}
                        required={true}
                        className="settings-input"
                  />
                </Col>
            </div>
            <div className="mt-1 settings-div">
                <Col md={12}>
                    <SelectCustom
                    name="displayInfo"
                    label="Display infomation"
                    className="settings-dropdown"
                    changed={e => onChangeHandler('valueType',e.target.value)}
                    required={false}
                    value={properties.valueType}
                    options={displayInfoList}/>
                </Col>
            </div> 
            {properties.valueType === 'url' && (
            <div className="mt-1 settings-div">
                <Col md={12}>
                <InputWithLabelComponent
                        type="text"
                        name={'settings-label'}
                        label="URL"
                        showLabel={true}
                        value={properties.url}
                        readOnly={false}
                        onChange={e => onChangeHandler('url',e.target.value)}
                        required={true}
                        className="settings-input"
                  />
                </Col>
            </div>
            )}
            {properties.valueType === 'text' && (
            <div className="mt-1 settings-div">
                <Col md={12}>
                <TextAreaWithLabelComponent 
                    label="Body"
                    name="displayText"
                    required ={false}
                    value={properties.text}
                    className='text-area settings-input'
                    placeholder='Display Text.'
                    onChange={(e) => onChangeHandler('text',e.target.value)}
                    />
                </Col>
            </div>
            )}
            <div className="mt-1 settings-div">
                <Col md={12}>
                <InputWithLabelComponent
                        type="text"
                        name={'settings-agreeLabel'}
                        label="Agree Button Label"
                        showLabel={true}
                        value={properties.agreeLabel}
                        readOnly={false}
                        onChange={e => onChangeHandler('agreeLabel',e.target.value)}
                        required={true}
                        className="settings-input"
                        maxLength="30"
                  />
                </Col>
            </div>  
            <div className="mt-1 settings-div">
                <Col md={12}>
                <InputWithLabelComponent
                        type="text"
                        name={'settings-disagreeLabel'}
                        label="Disagree Button Label"
                        showLabel={true}
                        value={properties.disagreeLabel}
                        readOnly={false}
                        onChange={e => onChangeHandler('disagreeLabel',e.target.value)}
                        required={true}
                        className="settings-input"
                        maxLength="30"
                  />
                </Col>
            </div>  
            <div className="mt-1 settings-div">
                <Col md={12}>
                <InputWithLabelComponent
                        type="text"
                        name={'settings-modifiedLabel'}
                        label="Modified Label"
                        showLabel={true}
                        value={properties.modifiedLabel}
                        readOnly={false}
                        onChange={e => onChangeHandler('modifiedLabel',e.target.value)}
                        required={true}
                        className="settings-input"
                        maxLength="75"
                  />
                </Col>
            </div>     
            <div className="mt-1 settings-div">
                <Col md={12}>
                    <InputWithLabelComponent
                        type="text"
                        name={'settings-default'}
                        label="Error Message"
                        showLabel={true}
                        value={properties.errorMessage}
                        readOnly={false}
                        onChange={e => onChangeHandler('errorMessage',e.target.value)}
                        required={true}
                        className="settings-input"
                    />
                </Col>
            </div>      
        </div>
    )
}

export default Consent;