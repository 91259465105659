import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

const deleteSymptomsToRootCauseReducer = (state, payload) => {
    const rootCauseSymptomsData = state.symptomRootCauseData;
    const updatedData = rootCauseSymptomsData.map(item => {
        if (item.symptoms === payload.selectedItem.symptoms) {
            const rootcausedata = get(item, 'rootcausedata', []);
            if (isEmpty(rootcausedata)) {
                return item;
            }
            const updatedRootCause = rootcausedata.filter((rootcause, index) => {
                return index !== payload.id;
            });
            return {
                ...item,
                rootcausedata: updatedRootCause
            }
        }
        return item;
    });
    return {
        ...state,
        symptomRootCauseData: updatedData
    }
}

export default deleteSymptomsToRootCauseReducer;