import React, { useRef, useState, useEffect } from 'react';
import ValidationMsg from '../../../components/ValidationMsg/ValidationMsg';
import './Dropzone.css';
import ButtonComponent from '../../../components/UIComponents/Button/ButtonComponent';
const SynonymsDropzoneComponent = (props) => {
    const fileInputRef = useRef();
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [validFiles, setValidFiles] = useState([]);
    const [unsupportedFiles, setUnsupportedFiles] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [validation, setvalidationMessage] = useState(null);
    useEffect(() => {
        let filteredArr = selectedFiles.reduce((acc, current) => {
            const x = acc.find(item => item.name === current.name);
            if (!x) {
                return acc.concat([current]);
            } else {
                return acc;
            }
        }, []);
        setValidFiles([...filteredArr]);
        if (props.showButton == false) {

            props.ImportFAQIntents([...filteredArr])

        }
        if (props.showButton == false) {
            props.ImportFAQIntents([...filteredArr])
        }
    }, [selectedFiles]);

    const preventDefault = (e) => {
        e.preventDefault();
    }

    const dragOver = (e) => {
        preventDefault(e);
    }

    const dragEnter = (e) => {
        preventDefault(e);
    }

    const dragLeave = (e) => {
        preventDefault(e);
    }

    const fileDrop = (e) => {
        preventDefault(e);
        const files = e.dataTransfer.files;
        if (files.length) {
            if (props.failure || props.showFiles) {

                setValidFiles([]);
                setSelectedFiles([])
            }
            handleFiles(files);
        }
    }

    const filesSelected = () => {

        if (fileInputRef.current.files.length) {
            if (props.failure || props.showFiles) {
                setValidFiles([])
                setSelectedFiles([])
            }
            handleFiles(fileInputRef.current.files);
        }
    }

    const fileInputClicked = () => {
        fileInputRef.current.click();
    }

    const handleFiles = (files) => {
        props.onchangeFile();
        for (let i = 0; i < files.length; i++) {
            if (validateFile(files[i])) {
                setSelectedFiles(prevArray => [...prevArray, files[i]]);
            } else {
                files[i]['invalid'] = true;
                setSelectedFiles(prevArray => [...prevArray, files[i]]);
                setErrorMessage('File type not permitted');
                setUnsupportedFiles(prevArray => [...prevArray, files[i]]);
            }
        }
        ValidationMessage("files", selectedFiles.length)

    }

    const ValidationMessage = (type, value) => {

        if (type == "files") {
            value < 0 ? setvalidationMessage(<ValidationMsg>File is required</ValidationMsg>) :
                setvalidationMessage(null);
        }
        if (type == "filesLength") {
            value.trim() === ''
                ? setvalidationMessage(<ValidationMsg>File is required</ValidationMsg>) :
                setvalidationMessage(null);
        }

    }
    const validateFile = (file) => {
        const validTypes = ['application/pdf', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            , 'text/plain', 'text/csv', '.csv', 'text/html', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
        if (validTypes.indexOf(file.type) === -1) {
            return false;
        }

        return true;
    }

    const fileSize = (size) => {
        if (size === 0) {
            return '0 Bytes';
        }
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(size) / Math.log(k));
        return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    }

    const fileType = (fileName) => {
        return fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || fileName;
    }

    const removeFile = (name) => {
        const index = validFiles.findIndex(e => e.name === name);
        const index2 = selectedFiles.findIndex(e => e.name === name);
        const index3 = unsupportedFiles.findIndex(e => e.name === name);
        validFiles.splice(index, 1);
        selectedFiles.splice(index2, 1);
        setValidFiles([...validFiles]);
        setSelectedFiles([...selectedFiles]);
        if (index3 !== -1) {
            unsupportedFiles.splice(index3, 1);
            setUnsupportedFiles([...unsupportedFiles]);
        }
        props.onchangeFile()
    }
    const ImportFAQIntents = () => {
        if (validFiles.length && !props.showFiles)
            props.ImportFAQIntents(validFiles)
        else {
            props.ImportFAQIntents(validFiles)
            ValidationMessage("filesLength", '')
        }

    }

    return (
        <>
            <div className="" style={{ height: "auto" }}>
                <div className="dropZone">
                    <div className="drop-container"
                        onDragOver={dragOver}
                        onDragEnter={dragEnter}
                        onDragLeave={dragLeave}
                        onDrop={fileDrop}
                        onClick={fileInputClicked}
                        style={{
                            height: props.showButton == false && props.length == 0 ?
                                '400px'
                                : ''
                        }}
                    >
                        <div className="drop-message">
                            Drag & Drop files here or click to select file(s)
                        </div>
                        <input
                            ref={fileInputRef}
                            className="file-input"
                            type="file"
                            onChange={filesSelected}
                            multiple
                            onClick={(event) => { event.target.value = null }}
                        />
                    </div>
                </div>
                <div>
                    {unsupportedFiles.length ? <div className='file-error-message' style={{ color: 'red' }}>Please remove all unsupported files.</div> : ''}
                    <div className="row col-md-12 fileContainers">
                        <div className="file-display-containers">
                            {
                                props.showFiles ? null :
                                    (validFiles.map((data, i) =>
                                        <div className="file-status-bars" key={i}>
                                            <div>
                                                <div className="file-type-logo"></div>
                                                <div className="file-types">{fileType(data.name)}</div>
                                                <div className={`file-names ${data.invalid ? 'file-error' : ''}`}>{data.name}
                                                    <span className="file-sizes">({fileSize(data.size)})</span></div> {data.invalid && <div className='file-error-message'>({errorMessage})</div>}
                                            </div>
                                            <div className="file-removes" onClick={() => removeFile(data.name)}>X</div>
                                        </div>
                                    ))
                            }
                        </div>
                    </div>
                </div>
            </div>

            {props.uploadloadingImg}

            <div className="dropZone">
                {validation}
            </div>
            <div>
                <img src="./images/info_icon.png" />
            </div>
            <div className="dropzoneInfo">
                <span>File formats supported for FAQ are Excel, CSV, PDF, Word and Txt.</span>
                <br />
                <span>For CSV/Excel files, column names should be question_shortDesc/question/questions | alternate_questions | answer/answers/response/responses.</span>
            </div>
            <div>
                <ButtonComponent
                    variant='contained'
                    label='Save'
                    disabled={(unsupportedFiles.length === 0 && validFiles.length &&
                        props.failure == false) ? "" : "disabled"}
                    className='buttonWidth mb-2 mt-3'
                    clicked={ImportFAQIntents} />
            </div>
        </>
    );
}

export default SynonymsDropzoneComponent;
