import React from 'react';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';
import { Row, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Input from '../../UI/Input/InputCustom';
import './IssueClarification.css';
import LabelValueComponent from '../components/LabelValueComponent/LabelValueComponent';
import { useDispatch, useSelector } from "react-redux";
import { SetWarningMessage } from "../../../store/actions/message";

const IssueClarificationPage = ({
    handleSwitchChange,
    enabled,
    size,
    clusterNumber,
    inputChangedHandler,
    optionFieldsChangedHandler,
    // optionField1Config,
    // optionField2Config,
    // optionField3Config,
    saveButtonClickHandler,
    defaultItemsNumber,
    items,
    summerizedLength,
    summarytextsize,
    optionField1,
    optionField2,
    optionField3,
    optionalField1DdOptions,
    optionalField2DdOptions,
    optionalField3DdOptions,
    issueClassificationDefaultField,
    ownProps
}) => {
    const dispatch = useDispatch();
            const similarStatus = useSelector(
            (state) => state.insights.similartickets.enabled
            );  
            const topresStatus = useSelector(
            (state) => state.insights.topresolutions.enabled
            ); 
        const check = (e)=>{
            if(ownProps.permissions==false) return true
            if(similarStatus!=false || topresStatus!=false ) {
                handleSwitchChange(e)
            }else{
                dispatch(SetWarningMessage("One Tab Should be Enable"));
            }
        }
        return (
            <div className="IssueClarificationPage" >
                <Accordion defaultActiveKey="0">
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="1">
						<span id="IssueClassification-icon" ><img src="./images/IssueClassification.svg" className="InsightsTabsIcons"/>Issue Classification </span>
                            
                                <div className="IssueClarificationActions">
                                <Form>
                                    <Form.Check
                                        type="switch"
                                        id="IssueClarificationCustomSwitch"
                                        onChange={e => check(e)}
                                        checked={enabled}
                                    />

                                </Form>
                                <span class="Settings-icon" ><img src="./images/SettingsGrey.svg" /></span>
                            </div>

                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body>
                                <div className="col-md-4 ClusterNumber">
                                    <Form.Group>
                                        <Form.Label>No. of Clusters</Form.Label>
                                        <Input
                                            key="clusterNumber"
                                            elementType="select"
                                            elementConfig={clusterNumber.elementConfig}
                                            value={size}
                                            changed={(event) => inputChangedHandler(event, 'size')}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-md-4 DefaultItemsNumber">
                                    <Form.Group>
                                        <Form.Label>No of default items in a cluster</Form.Label>
                                        <Input
                                            key="defaultItemsNumber"
                                            elementType="select"
                                            elementConfig={defaultItemsNumber.elementConfig}
                                            value={items}
                                            changed={(event) => inputChangedHandler(event, 'items')}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-md-4 SummerizedLength">
                                    <Form.Group>
                                        <Form.Label>Length of the auto summarized label</Form.Label>
                                        <Input
                                            key="summerizedLength"
                                            elementType="select"
                                            elementConfig={summerizedLength.elementConfig}
                                            value={summarytextsize}
                                            changed={(event) => inputChangedHandler(event, 'summarytextsize')}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="ClusteringParameters">
                                    <p>Parameters to consider the clustering  </p>
                                    <div className="col-md-12">
                                        <Row>
                                            <div className="col-md-4 TicketID ClusteringParametersContent">
                                                <LabelValueComponent
                                                    value='caseId'
                                                    readOnly={true}
                                                    labelName='Ticket ID' />
                                            </div>
                                            <div className="col-md-4 ClusteringParametersContent">
                                                <LabelValueComponent
                                                    value='title'
                                                    readOnly={true}
                                                    labelName='Title' />
                                            </div>
                                            <div className="col-md-3 ClusteringParametersContent">
                                                <LabelValueComponent
                                                    value={issueClassificationDefaultField}
                                                    readOnly={true}
                                                    labelName='Problem Description' />
                                            </div>
                                        </Row>
                                    </div>
                                    <div className="col-md-12 Ticketing">
                                        <Row>
                                            <div className="col-md-3">

                                                <Form.Group>
                                                    <Form.Label>Option Field 1</Form.Label>
                                                    <Input
                                                        key="optionField1"
                                                        elementType="select"
                                                        elementConfig={optionalField1DdOptions}
                                                        value={optionField1}
                                                        changed={(event) => optionFieldsChangedHandler(event, 'issueClarificationOptionalField1')}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-3">

                                                <Form.Group>
                                                    <Form.Label>Option Field 2</Form.Label>
                                                    <Input
                                                        key="optionField2"
                                                        elementType="select"
                                                        elementConfig={optionalField2DdOptions}
                                                        value={optionField2}
                                                        changed={(event) => optionFieldsChangedHandler(event, 'issueClarificationOptionalField2')}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-3 ">

                                                <Form.Group>
                                                    <Form.Label>Option Field 3</Form.Label>
                                                    <Input
                                                        key="optionField3"
                                                        elementType="select"
                                                        elementConfig={optionalField3DdOptions}
                                                        value={optionField3}
                                                        changed={(event) => optionFieldsChangedHandler(event, 'issueClarificationOptionalField3')}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </Row>
                                    </div>


                                </div>
                                <Button className='saveBtn' disabled={ownProps.permissions==true?false:true} onClick={saveButtonClickHandler}>Save</Button>{' '}
                            </Card.Body>

                        </Accordion.Collapse>
                    </Card>

                </Accordion>
            </div>
        );
    }

IssueClarificationPage.propTypes = {
    clusterNumber: PropTypes.object,
    defaultItemsNumber: PropTypes.object,
    summerizedLength: PropTypes.object,
    searchSize1: PropTypes.object,
    searchSize2: PropTypes.object,
    searchSize3: PropTypes.object,
};

IssueClarificationPage.defaultProps = {
    clusterNumber: {
        elementType: 'select',
        elementConfig: {
            options: [
                { value: 3, displayValue: '3' },
                { value: 5, displayValue: '5' },
                { value: 8, displayValue: '8' },
                { value: 10, displayValue: '10' }
            ]
        },
        value: '',
        validation: {
            required: true
        },
        valid: false,
        touched: false,
        className: 'selectLang'
    },
    defaultItemsNumber: {
        elementType: 'select',
        elementConfig: {
            options: [
                { value: 3, displayValue: '3' },
                { value: 5, displayValue: '5' },
                { value: 8, displayValue: '8' },
                { value: 10, displayValue: '10' }
            ]
        },
        value: '',
        validation: {
            required: true
        },
        valid: false,
        touched: false,
        className: 'selectLang'
    },
    summerizedLength: {
        elementType: 'select',
        elementConfig: {
            options: [
                { value: 30, displayValue: '30 Characters' },
                { value: 50, displayValue: '50 Characters' },
                { value: 80, displayValue: '80 Characters' },
                { value: 100, displayValue: '100 Characters' }
            ]
        },
        value: '',
        validation: {
            required: true
        },
        valid: false,
        touched: false,
        className: 'selectLang'
    },
    optionField1Config: {
        elementType: 'select',
        elementConfig: {
            options: [
                { value: '', displayValue: '--Select--' },
                { value: 'title', displayValue: 'title' },
                { value: 'assignee', displayValue: 'assignee' },
                { value: 'severity', displayValue: 'severity' },
                { value: 'workGroup', displayValue: 'workGroup' },
                { value: 'customerName', displayValue: 'customerName' },
                { value: 'problemArea', displayValue: 'problemArea' },
                { value: 'incidentCategory', displayValue: 'incidentCategory' },
                { value: 'problemDescription', displayValue: 'problemDescription' }
            ]
        },
        value: '',
        validation: {
            required: true
        },
        valid: false,
        touched: false,
        className: 'selectLang'
    },
    optionField2Config: {
        elementType: 'select',
        elementConfig: {
            options: [
                { value: '', displayValue: '--Select--' },
                { value: 'title', displayValue: 'title' },
                { value: 'assignee', displayValue: 'assignee' },
                { value: 'severity', displayValue: 'severity' },
                { value: 'workGroup', displayValue: 'workGroup' },
                { value: 'customerName', displayValue: 'customerName' },
                { value: 'problemArea', displayValue: 'problemArea' },
                { value: 'incidentCategory', displayValue: 'incidentCategory' },
                { value: 'problemDescription', displayValue: 'problemDescription' }
            ]
        },
        value: '',
        validation: {
            required: true
        },
        valid: false,
        touched: false,
        className: 'selectLang'
    },
    optionField3Config: {
        elementType: 'select',
        elementConfig: {
            options: [
                { value: '', displayValue: '--Select--' },
                { value: 'title', displayValue: 'title' },
                { value: 'assignee', displayValue: 'assignee' },
                { value: 'severity', displayValue: 'severity' },
                { value: 'workGroup', displayValue: 'workGroup' },
                { value: 'customerName', displayValue: 'customerName' },
                { value: 'problemArea', displayValue: 'problemArea' },
                { value: 'incidentCategory', displayValue: 'incidentCategory' },
                { value: 'problemDescription', displayValue: 'problemDescription' }
            ]
        },
        value: '',
        validation: {
            required: true
        },
        valid: false,
        touched: false,
        className: 'selectLang'
    }
}
export default IssueClarificationPage;