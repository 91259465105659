// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Modal_modalContent__2Ple8 {
    background-color: #f3f6ff;
    border-radius: 12px;
}

.Modal_connectDataModal__j9lhU .Modal_close__stDBq{
    position: absolute;
    right: -20px;
    top: 10px;
    background: grey;
    border-radius: 50px;
    width: 20px;
    height: 34px;
}
.Modal_connectDataModal__j9lhU button.Modal_close__stDBq span:first-child{
    position: absolute;
    /* padding-top: 46px; */
    top: 0px;
    text-align: center;
    left: 7px;

}
.Modal_headertitle__6xenT{
    color:  #0080ff; 
}
`, "",{"version":3,"sources":["webpack://./src/components/UI/Modal/Modal.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,SAAS;IACT,gBAAgB;IAChB,mBAAmB;IACnB,WAAW;IACX,YAAY;AAChB;AACA;IACI,kBAAkB;IAClB,uBAAuB;IACvB,QAAQ;IACR,kBAAkB;IAClB,SAAS;;AAEb;AACA;IACI,eAAe;AACnB","sourcesContent":[".modalContent {\n    background-color: #f3f6ff;\n    border-radius: 12px;\n}\n\n.connectDataModal .close{\n    position: absolute;\n    right: -20px;\n    top: 10px;\n    background: grey;\n    border-radius: 50px;\n    width: 20px;\n    height: 34px;\n}\n.connectDataModal button.close span:first-child{\n    position: absolute;\n    /* padding-top: 46px; */\n    top: 0px;\n    text-align: center;\n    left: 7px;\n\n}\n.headertitle{\n    color:  #0080ff; \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContent": `Modal_modalContent__2Ple8`,
	"connectDataModal": `Modal_connectDataModal__j9lhU`,
	"close": `Modal_close__stDBq`,
	"headertitle": `Modal_headertitle__6xenT`
};
export default ___CSS_LOADER_EXPORT___;
