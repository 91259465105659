import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../utility';


const initialState = {
    error: null,
    loading: false,
    agentList:null,
    activeAgent:null
};


const agentStart = ( state ) => {
    return updateObject( state, { error: null, loading: true } );
};

const agentSuccess = (state, action) => {
    return updateObject( state, { 
        agentList: action.agentList,
        error: null,
        loading: false,
     } );
};

const agentFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
};


const activeAgentSuccess = (state, action) => {
    return updateObject( state, { 
        activeAgent: action.activeAgent,
        error: null
     } );
};

const handleFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
    });
};

// const activeAgentFail = (state, action) => {
//     return updateObject( state, {
//         error: action.error,
//     });
// };

const saveagentStart = ( state ) => {
    return updateObject( state, { error: null, saveloading: true } );
};

const saveagentSuccess = (state, action) => {
    return updateObject( state, { 
        saveList: action.saveList,
        error: null,
        saveloading: false,
     } );
};

const saveagentFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        saveloading: false
    });
};

// const getTimezonesFail = (state, action) => {
//     return updateObject( state, {
//         error: action.error,
//     });
// };

const getTimezonesStart = ( state ) => {
    return updateObject( state, { error: null,} );
};

const getTimezonesSuccess = (state, action) => {
    return updateObject( state, { 
        timeZone: action.timeZone,
     } );
};

// const getContryFail = (state, action) => {
//     return updateObject( state, {
//         error: action.error,
//     });
// };

const getContryStart = ( state ) => {
    return updateObject( state, { error: null,} );
};

const getContrySuccess = (state, action) => {
    return updateObject( state, { 
        countryList: action.countryList,
     } );
};

const getAllScheduleMeetingDetailStart = ( state ) => {
    return updateObject( state, { error: null,} );
};

const getAllScheduleMeetingDetailSuccess = (state, action) => {
    return updateObject( state, { 
        scheduleMeetingDetail: action.scheduleMeetingDetail,
     } );
};

// const getAllScheduleMeetingDetailFail = (state, action) => {
//     return updateObject( state, {
//         error: action.error,
//     });
// };

const getAgentTransferDetailStart = ( state ) => {
    return updateObject( state, { error: null,} );
};

const getAgentTransferDetailSuccess = (state, action) => {
    return updateObject( state, { 
        agentTransferDetail: action.agentTransferDetail,
     } );
};

// const getAgentTransferDetailFail = (state, action) => {
//     return updateObject( state, {
//         error: action.error,
//     });
// };

const getAgentDetailsStart = ( state ) => {
    return updateObject( state, { error: null,} );
};

const getAgentDetailsSuccess = (state, action) => {
    return updateObject( state, { 
        agentDetail: action.agentDetail,
     } );
};

// const getAgentDetailsFail = (state, action) => {
//     return updateObject( state, {
//         error: action.error,
//     });
// };


const agentreducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.AGENTLIST_START: return agentStart(state);
        case actionTypes.AGENTLIST_SUCCESS: return agentSuccess(state, action);
        case actionTypes.AGENTLIST_FAIL: return agentFail(state, action);
        case actionTypes.ACTIVEAGENT_SUCCESS: return activeAgentSuccess(state, action);
        case actionTypes.ACTIVEAGENT_FAIL: return handleFail(state, action); 
        case actionTypes.SAVE_AGENTLIST_START: return saveagentStart(state);
        case actionTypes.SAVE_AGENTLIST_SUCCESS: return saveagentSuccess(state, action);
        case actionTypes.SAVE_AGENTLIST_FAIL: return saveagentFail(state, action);
        case actionTypes.GET_TIME_ZONE_START: return getTimezonesStart(state);
        case actionTypes.GET_TIME_ZONE_SUCCESS: return getTimezonesSuccess(state, action);
        case actionTypes.GET_TIME_ZONE_FAIL: return handleFail(state, action);
        case actionTypes.GET_CONTRY_START: return getContryStart(state);
        case actionTypes.GET_CONTRY_SUCCESS: return getContrySuccess(state, action);
        case actionTypes.GET_CONTRY_FAIL: return handleFail(state, action);
        case actionTypes.GET_ALL_SCHEDULE_MEETING_DETAIL_START: return getAllScheduleMeetingDetailStart(state);
        case actionTypes.GET_ALL_SCHEDULE_MEETING_DETAIL_SUCCESS: return getAllScheduleMeetingDetailSuccess(state, action);
        case actionTypes.GET_ALL_SCHEDULE_MEETING_DETAIL_FAIL: return handleFail(state, action);
        case actionTypes.GET_AGENT_TRANSFER_DETAIL_START: return getAgentTransferDetailStart(state);
        case actionTypes.GET_AGENT_TRANSFER_DETAIL_SUCCESS: return getAgentTransferDetailSuccess(state, action);
        case actionTypes.GET_AGENT_TRANSFER_DETAIL_FAIL: return handleFail(state, action);
        case actionTypes.GET_AGENT_DETAIL_START: return getAgentDetailsStart(state);
        case actionTypes.GET_AGENT_DETAIL_SUCCESS: return getAgentDetailsSuccess(state, action);
        case actionTypes.GET_AGENT_DETAIL_FAIL: return handleFail(state, action);
        default:
            return state;
    }
};

export default agentreducer;