import React from 'react';
import './Slider.css';

const active = '#64c3ef'
const inactive = '#dbdbdb'

class Slider extends React.Component {
  inputRef = React.createRef();
  
  componentDidMount() {
    const { value, minValue, maxValue } = this.props;
    const progress = (value - minValue) / (maxValue - minValue) * 100;
    const newBackgroundStyle = `linear-gradient(90deg, ${active} 0% ${progress}%,   ${inactive} ${progress}% 100%)`
    this.inputRef.current.style.background = newBackgroundStyle;
  }

  componentDidUpdate() {
    const { value, minValue, maxValue } = this.props;
    const progress = (value - minValue) / (maxValue - minValue) * 100;
    const newBackgroundStyle = `linear-gradient(90deg, ${active} 0% ${progress}%,   ${inactive} ${progress}% 100%)`
    this.inputRef.current.style.background = newBackgroundStyle;
  }

  handleChange = (min, max) => (event) => {
    const selectedValue = event.target.value
    const progress = (selectedValue - min) / (max - min) * 100;
    const newBackgroundStyle = `linear-gradient(90deg, ${active} 0% ${progress}%,   ${inactive} ${progress}% 100%)`
    this.inputRef.current.style.background = newBackgroundStyle;
    this.props.onChange(progress, selectedValue)
  }


  render() {
    const { minValue, maxValue, value } = this.props;

    return (
      <div data-testid={`slide`} className="Slider">
        <input
          ref={this.inputRef}
          id="sliderId"
          className="inputR"
          name="sliderName"
          type="range"
          min={minValue}
          max={maxValue}
          value={value}
          onChange={this.handleChange(minValue, maxValue)}
        />
        <div  className="ticks">
          <span  className="tick">50%</span>
          <span  className="tick">60%</span>
          <span  className="tick">70%</span>
          <span  className="tick">80%</span>
          <span  className="tick">90%</span>
          <span  className="tick">100%</span>
        </div>
      </div>

    );
  }
}
export default Slider;