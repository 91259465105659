// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KnowledgeArticlesPage .Settings-icon img{height: 18px;margin-bottom: 3px;}
.KnowledgeArticlesPage .card-header{display: flex;
  align-items: center;
  justify-content: space-between;}

  .KnowledgeArticlesPage .KnowledgeArticlesActions{display: flex;
    margin-right: 25px;}
    .KnowledgeArticlesPage .form-group{position: relative;}
    .KnowledgeArticlesPage label.form-label{position: absolute;
      top: -13px;
      background: white;
      left: 18px;
      padding: 0 10px;}
.InsightsPage .Settings-icon{padding-left: 15px;}`, "",{"version":3,"sources":["webpack://./src/components/Insights/KnowledgeArticles/KnowledgeArticles.css"],"names":[],"mappings":"AAAA,0CAA0C,YAAY,CAAC,kBAAkB,CAAC;AAC1E,oCAAoC,aAAa;EAC/C,mBAAmB;EACnB,8BAA8B,CAAC;;EAE/B,iDAAiD,aAAa;IAC5D,kBAAkB,CAAC;IACnB,mCAAmC,kBAAkB,CAAC;IACtD,wCAAwC,kBAAkB;MACxD,UAAU;MACV,iBAAiB;MACjB,UAAU;MACV,eAAe,CAAC;AACtB,6BAA6B,kBAAkB,CAAC","sourcesContent":[".KnowledgeArticlesPage .Settings-icon img{height: 18px;margin-bottom: 3px;}\n.KnowledgeArticlesPage .card-header{display: flex;\n  align-items: center;\n  justify-content: space-between;}\n\n  .KnowledgeArticlesPage .KnowledgeArticlesActions{display: flex;\n    margin-right: 25px;}\n    .KnowledgeArticlesPage .form-group{position: relative;}\n    .KnowledgeArticlesPage label.form-label{position: absolute;\n      top: -13px;\n      background: white;\n      left: 18px;\n      padding: 0 10px;}\n.InsightsPage .Settings-icon{padding-left: 15px;}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
