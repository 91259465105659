import * as React from 'react';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import './TreeComponent.css';

const TreeComponent = (props) => {
   return(
    
    <TreeView
      aria-label={props.ariaLabel}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      sx={{ height: 240, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
      expanded={props.expanded}
      className='treeView'
      onNodeToggle={props.onNodeChange}
    >
      <TreeItem nodeId={props.label} className='treeItemLabel' label={props.label}>
     {props.nodeList? props.nodeList.length > 0 && props.nodeList.map(item => {
         return (<TreeItem className={props.selectedLabel== item?'activeTree':'treeInnerLabel'}
          nodeId={item} key={item} label={item} onClick={() => props.selectedItem(item)} />);
                      }):null}
      </TreeItem>
    </TreeView>
  
   )
}

export default TreeComponent;
