import React, { useEffect, useState } from "react";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { Row, Col } from "react-bootstrap";
import Badge from 'react-bootstrap/Badge';
import SymptomsDetails from "./SymptomsDetails";
import "./SymptomAndRootCause.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EditSymptomsDetails from "./EditSymptomsDetails";



const SymptomsContent = ({ symptom, id, config, getSymptoms, closeDrawer, advanceFilters,permissions,setSearchTerm }) => {
  const [showDrawer, setShowDrawer] = useState(false);
  const toggleDrawer = (value) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setShowDrawer(value);
    setSearchTerm("");
  };

  const getAttributesData = (data, type) => {
    const selectedSourceFields = get(data, type, []);
    if (isEmpty(selectedSourceFields)) {
      return "--";
    }

    return selectedSourceFields.toString();
  };

  const getSymptomAttributes = (symptom) =>
    Object.entries(symptom).map(([key, value]) => {
      if (key === "leadingquestion" && key === "description") {
        return null;
      }
      if (Array.isArray(value)) {
        return (
          <>
          { (config.SYMPTOMS_ROOT_CAUSE_HIDDEN_FIELDS).includes(key) ?  null : (
          <Col xs={2}>
            <p className="issue-results-description-change" id={key}>{getAttributesData(symptom, key)}</p>
          </Col>) }
          </>
        );
      }
      return null;
    });
    const day = new Date();

    const yesterday = [(day.getFullYear()).toString(), ((day.getMonth() + 1)).toString().padStart(2, "0") , ((day.getDate())).toString().padStart(2, "0")]
  
  const BadgeCheck = (timeStamp) => { 
      let day = new Date();
      let yesterday = [(day.getFullYear()).toString(), ((day.getMonth() + 1)).toString().padStart(2, "0") , ((day.getDate())).toString().padStart(2, "0")]
      let syncedTimeStamp = timeStamp;
      // let syncedDate = syncedTimeStamp[0].split("-");
      return JSON.stringify(yesterday) == JSON.stringify(syncedTimeStamp)
  }

  // const [testDate, setTestDate] = useState("");

   
  return (
    <React.Fragment>
      <Row  xs={12}
        className="d-flex symtom-content row row-no-gutters"
        onClick={toggleDrawer(true)}
      >
        <Col className="d-flex symtom-container">
          <div className="row d-flex justify-content-between w-100 content-align-items">
            <Col>
             
                  <div className="row d-flex justify-content-between w-100 content-align-items" >
                    {
                      Object.keys(symptom).sort().reverse().map((key) => {
                        return (
                        <Col style={{display: `${key === "timestamp" ? "none" : "block"}`, textAlign: "center"}} md={2}>
                          
                          <p className="issue-results-description-change pr-0">{key === "timestamp" ? "" : Array.isArray(symptom[key])? symptom[key].join(", "): symptom[key]}</p>
                        </Col>
                        )
                      })
                    }
                  </div>
            </Col>

            {/* <Col xs={2}>

              <p className="issue-results-description-change">{symptom.symptoms ? symptom.symptoms : "no value"} { BadgeCheck(get(symptom, "timestamp", "2022-02-18 07:12:48.519"))  ? <Badge  bg="warning" text="dark" >New</Badge> :null }</p>
            </Col>
            <Col xs={2}>
              <p className="issue-results-description-change">{symptom.leadingquestion ? symptom.leadingquestion : "no value"}</p>

            </Col>
            {getSymptomAttributes(symptom)} */}
          </div>
        </Col>
        <Col xs={1} className="arrow-img">
          <img src="./images/Vector.svg" />
        </Col>
      </Row>
      {showDrawer && permissions? (
        <EditSymptomsDetails
          config={config}
          id={id}
          symptom={symptom}
          showDrawer={showDrawer}
          toggleDrawer={toggleDrawer}
          getSymptoms={getSymptoms}
          advanceFilters={advanceFilters}
          closeDrawer={() => {
            setShowDrawer(false);
          }}
          toastFun={(msg, type) => {
            toast(msg, {
              type: type,
              pauseOnHover: false,
            });
          }}
        />
      ) : null}
      <ToastContainer/>
    </React.Fragment>
  );
};
export default SymptomsContent;
