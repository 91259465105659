import Crypt from 'crypto-js';



// encryptAndStore retrieveAndDecrypt 

export const encrypt = (key, value) => {
    localStorage.setItem(key, Crypt.AES.encrypt(JSON.stringify(value), process.env.REACT_APP_KEY).toString()); 
};

export const decrypt = (key) => {
    const encryptedData = localStorage.getItem(key);
  
    if (!encryptedData) {
      return null;
    }
  
    try {
      const decryptedBytes = Crypt.AES.decrypt(encryptedData, process.env.REACT_APP_KEY);
      const decryptedText = decryptedBytes.toString(Crypt.enc.Utf8);
      return decryptedText;
    } catch (error) {
      // Handle decryption errors, such as incorrect key or malformed data
      console.error('Decryption error:', error);
      return null;
    }
  };

  const storageRef = localStorage.setItem.bind(localStorage);  
  export const encryptAndStore = (key , value)=>{
    storageRef(key, value); 
  }

  // localStorage.__proto__.encryptAndStore=function(key ,value){
  //   localStorage.storage(key, value);  
  // }
  
// const encryptAndStoreStr = localStorage.getItem('encryptAndStore');
// export const encryptAndStore = new Function('return ' + encryptAndStoreStr)();