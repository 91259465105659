import * as React from 'react';
import Drawer from '@material-ui/core/Drawer';
import './DrawerWithSubHeading.css';
import CloseIcon from "@material-ui/icons/Close";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { Row, Col } from "react-bootstrap";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles} from "@material-ui/core/styles";

const DrawerWithSubHeading = (props) => {

   const LightTooltip = withStyles((theme) => ({
      tooltip: {
        backgroundColor: theme.palette.common.white,
        color: "rgba(0, 0, 0, 0.87)",
        boxShadow: theme.shadows[1],
        fontSize: 12,
        fontFamily: "Roboto-Regular",
      },
      arrow: {
        color: theme.palette.common.white,
        "&:before": {
          border: "1px solid #E6E8ED",
          boxShadow: theme.shadows[1],
        },
      },
    }))(Tooltip);  

   return (
      <>
         <Drawer
            anchor={"right"}
            open={props.openDrawer}
            onClose={() => props.showDrawer(false)}
            className={props.className===undefined?'drawerClass':props.className}
         >
            <div className="pl-2">
               <Row className="d-flex pt-4 pl-2">
               <div className="col-md-1 mw-52">
                  {props.isArrowIcon ? <ArrowBack
                     className="d-flex product-img"
                     onClick={() => props.showDrawer(false)}
                     style={{ fontSize: 25 }}
                  /> : <CloseIcon
                     className="d-flex cross-img"
                     onClick={() => props.showDrawer(false)}
                     style={{ fontSize: 25 }}
                  />}
               </div>
               <Col className='header-col header-row'>
                  <Row>
                     <div className="d-flex header-mb" md={6}>
                        <h3 className="text-capitalize">{props.heading}</h3>
                     </div>
                     {props.tooltip && (
                        <div>
                        <LightTooltip
                        arrow
                        title="Usage Information"
                        placement="right"
                        onClick={props.onClick}
                      >
                        <img
                          src="./images/info_icon.png"

                          style={{ position: "absolute", top: "4px", cursor:"pointer" }}
                        />
                      </LightTooltip>
                      </div>
                     )}
                     {/* <div className='drawerSubhead'>{props.subHeading}</div> */}
                  </Row>
                  <Row>
                  <div className='drawerSubhead'>{props.subHeading}</div>
                  </Row>
               </Col>
               </Row>
            </div>
            
            
            <div className="d-flex flex-column Symtom-Item highlights">
               {props.children}
            </div>
         </Drawer>
      </>
   )
}

export default DrawerWithSubHeading;
