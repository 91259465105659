import React, { useState, useEffect } from 'react';
import { Row, Col, Container } from "react-bootstrap";
import Heading3 from '../../components/Typography/Heading3/Heading3';
import './UserGroups.css';
import SearchUsersAndRoles from "../helpers/SearchUsersAndRoles";

import PrimaryButtonWithLoader from "../../components/PrimaryButtonWithLoader/PrimaryButtonWithLoader";
import { GetCalltoOUServer } from "../helpers/utility";
import UserGroupsContent from './UserGroupsContent';
import UserGroupForm from './UserGroupForm';
import { useSelector } from "react-redux";
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InviteUser from '../Users/InviteUser';
import permissionsJson from "../../../../../permissions_Json";
import SearchComponent from '../../components/OuManagementSearch/OusearchComponent';
import UserGroupInvitation from '../Users/UserGroupInvitation';

const UserGroups = ({ config }) => {

  const userPermissions = useSelector(state => {
    return state.user.permissionList;
  });

    const [searchTerm, setSearchTerm] = useState('');
    const [showDrawer, setShowDrawer] = useState(false);
    const [showAddUserDrawer, setAddUserDrawer] = useState(false);
    const [filterGroupData, setfilterGroupData] = useState([]);
    console.log('filterGroupData', filterGroupData);
    const [groupData, setgroupData] = useState([]);
    const [count, setCount] = useState(0);
    const [userGroupName, setUserGroupName] = useState();
    const [group, setGroup] = useState({});
    

    const toggleDrawer = (value) => (event) => {
        if (
          event.type === "keydown" &&
          (event.key === "Tab" || event.key === "Shift")
        ) {
          return;
        }
        setShowDrawer(value);
        setAddUserDrawer(value);
        setSearchTerm("");
      };

      const getUserCount = (list) => {
        setCount(list.length);
      }

      const getUserGroups = () =>{
        const orgname = localStorage.getItem("orgName");
        const url = config.COMMON_API_URL + `/organization/usergroup?orgname=${orgname}`;
        GetCalltoOUServer(url)
          .then(response => {
              if (response.data != null) {
              setgroupData(response.data);
              getUserCount(response.data);
              setfilterGroupData(response.data);
            }
          }).catch(() => {
          });
      }

      useEffect(() => {
        getUserGroups();
      }, []);

    const searchHandler = (event) => {
        setSearchTerm(event.target.value);
          const filter = SearchUsersAndRoles(groupData, "group", event, setfilterGroupData);
          setfilterGroupData(filter);
      };

  const hasPermission = (code) =>
    userPermissions.find((data) => data.permission_code.includes(code)) ? true : false;

        const updateUserGroups = () =>{
          getUserGroups();
        }

        const updateFilterData = (object) =>{
          getUserGroups();
          var index = filterGroupData.findIndex(({ usergroup_name }) => usergroup_name === object.usergroup_name );
          if (index !== -1) {
            filterGroupData[index] = object;
            setfilterGroupData(filterGroupData);
          } 
        }

      const addNewUser = (userGroupName) => {
        setUserGroupName(userGroupName);
        setAddUserDrawer(true);
        setSearchTerm("");
      }

      const addNewGroup = (group) => {
        setGroup(group);
        setShowDrawer(true)
        setSearchTerm("");
      }

      const sortUsergroup = (type) => {
        const toBeSortedData = [...filterGroupData];
        let fa,fb = null;
        
        toBeSortedData.sort((a, b)=>{
          if(type==='usergroup_name'){
            fa = a[type].toLowerCase();
            fb = b[type].toLowerCase();
          } else if(type==='users') {
            fa=a[type]? a[type].length:0;
            fb=b[type]? b[type].length:0;
          }
          if (fa < fb) {
            return -1;
        }
        if (fa > fb) {
            return 1;
        }
        return 0;
      });
      setfilterGroupData(toBeSortedData);
      }

      let  userManagement_Read_Write = false;
      let userManagement_RW_Status = userPermissions && userPermissions.find(data => {
        if (data.permission_code.includes(permissionsJson.permissionCode.USERMANAGEMENT_RW) && data.sku_code.includes(permissionsJson.skuCode.STANDARD))
          return true
      })
      userManagement_Read_Write = userManagement_RW_Status ? true : false;
      const [groupName, setGroupName]=useState('')      
      const getGroupName=(str)=>{
        setGroupName(str)
      }

    return (
      <Container className="symptom-rootcause user-management" fluid>
        <Row>
          <Col xs={6} className="d-flex justify-content-between">
            <Heading3 className="pageTitleText" text={`User Groups`} />
          </Col>
          <Col xs={6} className="d-flex justify-content-end mt-3">
            {hasPermission("usermanagement_RW") && (
              <PrimaryButtonWithLoader
                className="mr-2 usergrp-btntitle"
                onClick={addNewGroup}
                size="lg"
                text="New USER GROUP"
                loading={false}
                disabled={userManagement_Read_Write ? false : true}
              />
            )}
          </Col>
        </Row>
        <Row className="d-flex justify-content-between">
            <Heading3 xs={6}
              className="pageTitleText pl-2"
              text={count + ` User Groups are available`}
            />
            <div xs={6} className="um-search-input mb-2">
              <SearchComponent
                class={"input-group searchClass"}
                placeholder="Search"
                value={searchTerm}
                onSearch={searchHandler}
                onBlur={searchHandler}
              />
            </div>
        </Row>
        <Row className="mt-4 row row-no-gutters">
          <Col
            xs={12}
            className="d-flex text-left align-items-center symtom-container"
          >
            <div className="row d-flex justify-content-between w-100 content-align-center symtom-heading-data">
              <Col xs={2}>
                <h3 className="issue-descp-title">
                  NAME
                  <a onClick={() => sortUsergroup("usergroup_name")}>
                    <img src="./Icons/ArrowDown.svg" />
                  </a>
                </h3>
              </Col>
              <Col xs={3}>
                <h3 className="issue-descp-title">DESCRIPTION</h3>
              </Col>
              <Col xs={2}>
                <h3 className="issue-descp-title">ASSOCIATED PROJECTS</h3>
              </Col>
              <Col xs={2}>
                <h3 className="issue-descp-title">ASSOCIATED ROLES</h3>
              </Col>
              <Col xs={1}>
                <h3 className="issue-descp-title">
                  USERS
                  <a onClick={() => sortUsergroup("users")}>
                    <img src="./Icons/ArrowDown.svg" />
                  </a>
                </h3>
              </Col>
              <Col xs={1}>
                <h3 className="issue-descp-title"></h3>
              </Col>
            </div>
          </Col>
        </Row>
        <div className="content-scroll">
          {filterGroupData.length
            ? filterGroupData.map((data) => {
                return (
                  <UserGroupsContent
                   getGroupName={getGroupName}
                    config={config}
                    group={data}
                    key={data.id}
                    updateUserGroups={updateUserGroups}
                    addNewUser={addNewUser}
                    addNewGroup={addNewGroup}
                    updateFilterData={updateFilterData}
                    userManagement_Read_Write={userManagement_Read_Write}
                    setSearch={setSearchTerm}
                  />
                );
              })
            : null}
        </div>
        {showDrawer ? (
          <UserGroupForm
            showDrawer={showDrawer}
            toggleDrawer={toggleDrawer}
            config={config}
            groupsData={filterGroupData}
            group={group}
            updateUserGroups={updateUserGroups}
            title="User Group Details"
            buttonText="Add Group"
            toastFun={(msg, type) => {
              toast(msg, {
                type: type,
                pauseOnHover: false,
                autoClose: 3000,
                hideProgressBar: true,
              });
            }}
            closeDrawer={() => {
              setShowDrawer(false);
            }}
          />
        ) : null}

        {showAddUserDrawer ? (
          <UserGroupInvitation  
            groupName={groupName}          
            showDrawer={showAddUserDrawer}
            toggleDrawer={toggleDrawer}
            updateUserGroups={updateUserGroups}
            config={config}
            userGroupName={userGroupName}
            toastFun={(msg, type) => {
              toast(msg, {
                type: type,
                pauseOnHover: false,
                autoClose: 3000,
                hideProgressBar: true,
              });
            }}
            closeDrawer={() => {
              setAddUserDrawer(false);
            }}
          />
        ) : null}
      </Container>
    );
}

export default UserGroups;