import { concat } from 'lodash';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    redirectData:{
        isRedirect:false,
        projectName:'',
        botName:''
    },
    AllAssistantsList:[],
    synonyms:null,
    projectdescription:'',
    selectedFlowName:'',
    intent: null,
    pattern: null,
    saveProfile: null,
    error: null,
    getStructure: null,
    getFileData: null,
    reloadDocument:false,
    loading: false,
    scriptLoading: false,
    entityLoading: false,
    botList: [],
    delknowledgeIntentInprogress: false,
    projectTranslation: true,
    translationinprogress: false,
    macrodata: null,
    macrolist: null,
    macroFileData: null,
    connectionsData: [],
    connectionsTabs: [],
    ticketSystemItems: [],
    ticketSystemObjItems: [],
    knowledgeBaseItems: [],
    knowledgeBaseObjItems: [],
    connectionsDataLoading: false,
    dataSourceIndexNames: [],
    localDataSourceIndexNames: [],
    dataSourceIndexNamesLoading: false,
    list:{'listTitle':'',
    'listType':'',
    },
    imgtitle:null,
    imageurl:null,
    node:[],
    sharePointData: {
        files: [],
        folders: [],
    },
    sharePointBaseUrl: '',
    sharePointDataLoading: false,
    condition :[],
    'ConditionA':{
        cndtType:'-1',
        ruleCndt:'-1',
        paramList:'-1',
        choiceList:'-1',
        paramType:'-1',
        cndParamType:[],
        compareValue:null,
    },
    'ConditionB':{
        cndtType:'-1',
        ruleCndt:'-1',
        paramList:'-1',
        choiceList:'-1',
        paramType:'-1',
        compareValue:null,
        cndParamType:[],
        emptyValueMsg:null
        }, 
    rule:false,
    ConditionAChat:null,
    ConditionBChat:null,
    chatReply:null,
    buttondata:{
        chatUserDefineInput: '',
        buttonInRowValue:'1'
    },
    listRows:[{option: "",}],
    videoContents:{
        title:"",
        url:"",
    },
    IsTabChanged:true,
    Carousel:[],
    CarouselIndex:0,
    CuxBotList:[],
    CuxBotId:'',
    CuxBotData:[],
    CuxTriggerList:[],
    projectsloading:false,
    CuxFormActiveField:0,
    cuxTemplate: [],
    cuxFlowTemplates:[],
    cuxHttpTemplates:[],
    cuxFormTemplates:[],
    cuxScriptTemplates:[],
    whatsAppConfigData:[],
    MSTeamsConfigData:[],
    copyChannelRefresh:false,
}

const actionStart = (state) => {
    return updateObject(state, { error: null, loading: true });
};
const actionSuccess = (state) => {
    return updateObject(state, { error: null, loading: false });
};
const actionFailure = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const setRedirectionData = (state, action) => {
    return updateObject(state, {
        redirectData: action.data,
    });
};

const triggerStart = (state) => {
    return updateObject(state, { error: null, loading: true });
};
const triggerFailure = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const triggerSuccess = (state, action) => {
    return updateObject(state, {
        intent: action.intent,
        loading: false
    });
};


const triggerStartDoc = (state) => {
    return updateObject(state, { reloadDocument: false });
};
const triggerFailureDoc = (state) => {
    return updateObject(state, {
        reloadDocument: false 
    });
};

const triggerSuccessDoc = (state) => {
    return updateObject(state, {
        reloadDocument: true 
    });
};

const IntentStart = (state) => {
    return updateObject(state, { error: null, loading: true });
};
const IntentFailure = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const IntentSuccess = (state, action) => {
    return updateObject(state, {
        pattern: action.pattern,
        loading: false
    });
};
const knowledgeIntentStart = (state) => {
    return updateObject(state, { error: null, loading: true });
};
const knowledgeIntentFailure = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const knowledgeIntentSuccess = (state, action) => {
    return updateObject(state, {
        knowledgePattern: action.pattern,
        loading: false
    });
};

const knowledgeWithFileIntentStart = (state) => {
    return updateObject(state, { error: null, uploadloading: true });
};
const knowledgeWithFileIntentFailure = (state, action) => {
    return updateObject(state, {
        // knowledgeUpload:"error",
        knowledgeUploadfailure: action.error,
        knowledgeUpload: '',
        uploadloading: false
    });
};

const knowledgeWithFileIntentSuccess = (state, action) => {
    return updateObject(state, {
        knowledgeUploadfailure: '',
        knowledgeUpload: action.pattern,
        uploadloading: false
    });
};
const knowledgeWithFileIntentCancel = (state) => {
    return updateObject(state, {
        knowledgeUpload: '',
    });
};

const GetknowledgeIntentStart = (state) => {
    return updateObject(state, { error: null, GetKnowledgeloading: true });
};
const GetknowledgeIntentFailure = (state, action) => {
    return updateObject(state, {
        error: action.error,
        GetKnowledgeloading: false
    });
};

const GetknowledgeIntentSuccess = (state, action) => {
    return updateObject(state, {
        knowledgeIntents: action.pattern,
        GetKnowledgeloading: false
    });
};

const getSynonymsStart = (state) => {
    return updateObject(state, { error: null, getSynonymsLoading: true });
};
const getSynonymsFailure = (state, action) => {
    return updateObject(state, {
        error: action.error,
        getSynonymsLoading: false
    });
};

const getSynonymsSuccess = (state, action) => {
    return updateObject(state, {
        synonyms: action.pattern,
        getSynonymsLoading: false
    });
};

//----------------------
const getSiteListStart = (state) => {
    return updateObject(state, { error: null});
};
const getSiteListFailure = (state, action) => {
    return updateObject(state, {
        error: action.error,
        sharePointDataLoading: false
    });
};

const getSiteListSuccess = (state, action) => {
    return updateObject(state, {
        siteList: action.pattern,
        sharePointDataLoading: false
    });
};
//----------------------

const getConnectionsDataStart = (state) => {
    return updateObject(state, { error: null, connectionsDataLoading: true });
};

const getSharePointDataStart = (state) => {
    return updateObject(state, { error: null, sharePointDataLoading: true });
};

const fetchDataSourceIndexNamesStart = (state) => {
    return updateObject(state, { error: null, dataSourceIndexNamesLoading: true });
};

const fetchDataSourceIndexNamesSuccess = (state, action) => {
    const dataSource = get(action, 'pattern', []);
    const transformedCollections = dataSource.map(item => ({
        value: item.index,
        displayValue: item.index,
        id: item.id
    }));
    return updateObject(state,
        {
            error: null,
            dataSourceIndexNames: transformedCollections,
            localDataSourceIndexNames: transformedCollections,
            dataSourceIndexNamesLoading: false
        });
};


const fetchDataSourceIndexNamesFailure = (state) => {
    return updateObject(state, {
        dataSourceIndexNamesLoading: false,
    });
};

const addDataSourceIndexNames = (state, action) => {
    return {
        ...state,
        localDataSourceIndexNames: uniq(concat(state.localDataSourceIndexNames, action.pattern))
    }
}

const getConnectionsTabs = (action) => {
    const connections = get(action, 'pattern.hits.hits', []);
    if (connections.length) {
        const formattedConnections = connections.map(item => item._id.split('__')[1]);
        return uniq(formattedConnections).sort().reverse()
    }
    return [];
}

const getTicketSystemItems = (action) => {
    const connections = get(action, 'pattern.hits.hits', []);
    let ticketSystemItems = [];
    if (connections.length) {
        connections.forEach(item => {
            if (item._id.includes('ticket_systems')) {
                ticketSystemItems.push(item._id.split('__')[2])
            }
        })
    }
    return uniq(ticketSystemItems);
};

const getTicketSystemObjItems = (action) => {
    const connections = get(action, 'pattern.hits.hits', []);
    let ticketSystemObjItems = [];
    if (connections.length) {
        connections.forEach(item => {
            if (item._id.includes('ticket_systems')) {
                ticketSystemObjItems.push({
                    displayName: get(item, '_source.datasourceDisplayName'),
                    id: get(item, '_source.datasourceId')
                })
            }
        })
    }
    return uniq(ticketSystemObjItems);
};

const getKnowledgeBaseItems = (action) => {
    const connections = get(action, 'pattern.hits.hits', []);
    let knowledgeBaseItems = [];
    if (connections.length) {
        connections.forEach(item => {
            if (item._id.includes('knowledge_base')) {
                knowledgeBaseItems.push(item._id.split('__')[2])
            }
        })
    }
    return uniq(knowledgeBaseItems);
};

const getKnowledgeBaseObjItems = (action) => {
    const connections = get(action, 'pattern.hits.hits', []);
    let knowledgeBaseItems = [];
    if (connections.length) {
        connections.forEach(item => {
            if (item._id.includes('knowledge_base')) {
                knowledgeBaseItems.push({
                    displayName: get(item, '_source.datasourceDisplayName'),
                    id: get(item, '_source.datasourceId')
                })
            }
        })
    }
    return uniq(knowledgeBaseItems);
};

const getConnectionsDataSuccess = (state, action) => {
    return updateObject(state,
        {
            error: null,
            connectionsData: get(action, 'pattern.hits.hits', []),
            connectionsDataLoading: false,
            connectionsTabs: getConnectionsTabs(action),
            ticketSystemItems: getTicketSystemItems(action),
            ticketSystemObjItems: getTicketSystemObjItems(action),
            knowledgeBaseObjItems: getKnowledgeBaseObjItems(action),
            knowledgeBaseItems: getKnowledgeBaseItems(action)
        });
};

const getSharePointDataSuccess = (state, action) => {
    const baseUrl = get(action, 'pattern.baseUrl', '');
    const filesData = get(action, 'pattern.data.Files', []);
    const updatedFilesData = filesData.map(item => {
        const splittedName = item.name.split('.');
        return {
            ...item,
            fileName: item.name,
            fileType: splittedName[1],
            checked: false
        }
    }); 
    return {
        ...state,
        sharePointDataLoading: false,
        sharePointData: {
            files: updatedFilesData,
            folders: get(action, 'pattern.data.Folders', []),
            baseUrl
        }
    }
};

const selectSharePointFile = (state, action) => {
    const updatedFilesData = state.sharePointData.files.map(item => {
            if(item.Name === action.payload.selectedFile.Name) {
                return {
                    ...item,
                    checked: action.payload.event.target.checked
                }
            }
            return item;
        });
        return {
            ...state,
            sharePointData: {
                ...state.sharePointData, 
                files: updatedFilesData
            } 
        }
};

const resetSharePointData = (state) => {
    return {
        ...state,
        sharePointData: {
            ...state.sharePointData,
            files: [],
            folders: []
        }
    }
}

const getConnectionsDataFailure = (state, action) => {
    return updateObject(state, { error: action.error, connectionsDataLoading: false });
};

const getSharePointDataFailure = (state, action) => {
    return updateObject(state, { error: action.error, sharePointDataLoading: false });
};

const knowledgeIntentImportStart = (state) => {
    return updateObject(state, { error: null, knowledgeImportloading: true });
};
const knowledgeIntentImportFailure = (state, action) => {
    return updateObject(state, {
        error: action.error,
        knowledgeImportloading: false
    });
};

const knowledgeIntentImportSuccess = (state, action) => {
    return updateObject(state, {
        importKnowledgeIntent: action.importKnowledgeIntent,
        knowledgeImportloading: false
    });
};

const connectDataImportStart = (state) => {
    return updateObject(state, { error: null, connectDataImportLoading: true });
};

const connectDataImportFailure = (state, action) => {
    return updateObject(state, {
        error: action.error,
        connectDataImportLoading: false
    });
};

const connectDataImportSuccess = (state, action) => {
    return updateObject(state, {
        importConnectData: action.connectData,
        connectDataImportLoading: false
    });
};

const UpdateKnowledgeIntentStart = (state) => {
    return updateObject(state, { error: null, updateKnowledgeIntentLoading: true });
};

const UpdateKnowledgeIntentFailure = (state, action) => {
    return updateObject(state, {
        error: action.error,
        updateKnowledgeIntentLoading: false
    });
};

const UpdateKnowledgeIntentSuccess = (state, action) => {
    return updateObject(state, {
        updateKnowledgeIntent: action.updateKnowledgeIntent,
        updateKnowledgeIntentLoading: false
    });
};

const getCLusterDataStart = (state) => {
    return updateObject(state, { error: null, clusterDataLoading: true });
};

const getCLusterDataFailure = (state, action) => {
    return updateObject(state, {
        error: action.error,
        clusterDataLoading: false
    });
};

const getCLusterDataSuccess = (state, action) => {
    return updateObject(state, {
        clusterData: action.cluster,
        clusterDataLoading: false
    });
};

const saveProfileStart = (state) => {
    return updateObject(state, { error: null, scriptLoading: true });
};
const saveProfileFailure = (state, action) => {
    return updateObject(state, {
        error: action.error,
        scriptLoading: false
    });
};

const saveProfileSuccess = (state, action) => {
    return updateObject(state, {
        saveProfile: action.botProfile,
        scriptLoading: false
    });
};

const getStructureFailure = (state, action) => {
    return updateObject(state, {
        error: action.error
    });
};

const getStructureSuccess = (state, action) => {
    return updateObject(state, {
        getStructure: action.bot
    });
};

const getBotFileFailure = (state, action) => {
    return updateObject(state, {
        error: action.error
    });
};

const getBotFileSuccess = (state, action) => {
    return updateObject(state, {
        getFileData: action.file
    });
};

const getEntityStart = (state) => {
    return updateObject(state, { error: null, entityLoading: true });
};
const getEntityFailure = (state, action) => {
    return updateObject(state, {
        error: action.error,
        entityLoading: false,
        entity:action.entity
    });
};

const getEntitySuccess = (state, action) => {
    return updateObject(state, {
        entity: action.entity,
        entityLoading: false
    });
};

const readFileFailure = (state, action) => {
    return updateObject(state, {
        error: action.error,
    });
};

const readFileSuccess = (state, action) => {
    return updateObject(state, {
        fileData: action.fileData,
    });
};

const createEntityStart = (state) => {
    return updateObject(state, { error: null, createLoading: true });
};
const createEntityFailure = (state, action) => {
    return updateObject(state, {
        error: action.error,
        createLoading: false
    });
};

const createEntitySuccess = (state, action) => {
    return updateObject(state, {
        createentity: action.createEntity,
        createLoading: false
    });
};

const cuxBotStart = (state) => {
    return updateObject(state, { error: null, loading: true });
};

const CuxLoader = (state, action) => {
    return updateObject(state, { loading: action.flag });
};

const createBotStart = (state) => {
    return updateObject(state, { error: null, loading: true });
};

const createBotSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        botCreated: action.bot,
        entity:'{}'
    });
};

const createBotFailure = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const getBotListSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        botList: action.botList,
        createBotloading: Math.random()
    });
};

const getBotListFailure = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
        createBotloading: false
    });
};

const getProjectsListStart = (state, action) => {
    return updateObject(state, {
        projectsloading: true,
        AllProjectsList: action.AllProjectsList,
    });
};


const getProjectsListSuccess = (state, action) => {
    return updateObject(state, {
        projectsloading: false,
        AllProjectsList: action.AllProjectsList,
    });
};

const getProjectsListFailure = (state, action) => {
    return updateObject(state, {
        error: action.error,
        projectsloading: false,
    });
};

const getAssistantsListStart = (state, action) => {
    return updateObject(state, {
        Assistantsloading: true,
    });
};


const getAssistantsListSuccess = (state, action) => {
    return updateObject(state, {
        Assistantsloading: false,
        AllAssistantsList: action.AllAssistants,
    });
};

const getAssistantsFailure = (state, action) => {
    return updateObject(state, {
        Assistantsloading: false,
        error: action.error,
        AllAssistantsList: [],
    });
};

const createCuxTriggerFailure = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
    });
};

const updateCuxTriggerFailure = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
    });
};

const createCuxTemplateSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        CuxBotId: action.flowid
    });
}

const CuxTemplateFailure = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
    });
}

const ClearCuxTemplates = (state) => {
    return updateObject(state,{
        cuxHttpTemplates: [],
        cuxFormTemplates: [],
        cuxScriptTemplates: [],
        cuxFlowTemplates: []
    })
}

const getCuxTemplatesSuccess = (state, action) => {
    if(action.nodeType === 'http') {
        return updateObject(state, {
            cuxHttpTemplates: action.nodeTemplate, 
            loading: false,
        });
    } else if(action.nodeType === 'forms') {
        return updateObject(state, {
            cuxFormTemplates: action.nodeTemplate,
            loading: false,
            });
    } else if(action.nodeType === 'script') {
        return updateObject(state, {
            cuxScriptTemplates: action.nodeTemplate,
            loading: false,
            });
    } else if(action.nodeType === 'flow') {
        return updateObject(state, {
            cuxFlowTemplates: action.nodeTemplate,
            loading: false,
            });
    }
}

const getCuxTriggerListSuccess = (state, action) => {
    return updateObject(state, {
        CuxTriggerList: action.cuxTriggerList,
        loading: false,
    });
};

const getCuxTriggerListFailure = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
    });
};

const getCuxBotListSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        CuxBotList: action.cuxBotList,
    });
};

const getCuxBotListFailure = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
    });
};

const getCuxBotDataSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        CuxBotData: action.cuxBotData,
    });
};

const getCuxBotDataFailure = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
    });
};

const updateCuxBotDataSuccess = (state) => {
    return updateObject(state, {
        loading: false,
    });
};

const updateCuxBotDataFailure = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
    });
};

const updateCuxFormField = (state, action) => {
    return updateObject(state, {
        CuxFormActiveField: action.activeField,
    });
};

const getChatBotSuccess = (state, action) => {
    return updateObject(state, {
        intentData: action.getBot
    });
};

const getChatBotFailure = (state, action) => {
    return updateObject(state, {
        error: action.error,
    });
};

const getDocSuccess = (state, action) => {
    return updateObject(state, {
        docData: action.getDoc,
        selectedFlowName:'',
    });
};

const getDocFailure = (state, action) => {
    return updateObject(state, {
        error: action.error,
        selectedFlowName:''
    });
};

const getProjectDescriptionSuccess = (state, action) => {
    return updateObject(state, {
        getprojDesp: action.getprojDesp
    });
};

const getProjectDescriptionFailure = (state, action) => {
    return updateObject(state, {
        error: action.error,
        getprojDesp:''
    });
};
const delFlowBotStart = (state) => {
    return updateObject(state, { error: null, delflowbotstart: true });
};

const delFlowBotSuccess = (state, action) => {
    let lang = action.botdata.language
    let pro = action.botdata.projectName
    let bot = action.botdata.botName
    let botList = state.botList
    delete botList[lang][pro][bot];
   
    return updateObject(state, {
        botList: botList,
        error: null,
        delflowbotstart: false,
    });
};

const createCuxBotSuccess = (state, action) => {
    let botid = action.flowid;
    return updateObject(state, {
        CuxBotId: botid,
        error: null,
        loading:false,
    });
};

const createCuxBotFailure = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading:false,
    });
};

const delCuxBotSuccess = (state, action) => {
    let botid = action.flowid;
    let cuxbotList = state.CuxBotList;
    let updatedCuxbotList = cuxbotList.filter(data => data.flowid !== botid);
    return updateObject(state, {
        CuxBotList: updatedCuxbotList.length>0 ?updatedCuxbotList:[],
        error: null,
        CuxBotId: updatedCuxbotList.length>0 ?updatedCuxbotList[0].flowid:'flowHeader',
        loading:false,
    });
};

const delCuxBotFailure = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading:false,
    });
};

const deleteKnowledgeIntentStart = (state) => {
    return updateObject(state, { error: null, delknowledgeIntentInprogress: true });
};

const deleteKnowledgeIntentEnd = (state) => {
    return updateObject(state, { error: null, delknowledgeIntentInprogress: false });
};

const delFlowBotFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        delflowbotstart: false
    });
};
const exportFlowBotStart = (state) => {
    return updateObject(state, { error: null, exportFlowBotStart: true });
};

const exportFlowBotSuccess = (state) => {
    return updateObject(state, {
        error: null,
        exportFlowBotStart: false,
    });
};

const exportFlowBotFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        exportFlowBotStart: false
    });
};
const uploadProjectStart = (state) => {
    return updateObject(state, {
        loading: true,
        uploadProjectFlag: 'S'
    });
};

const uploadProjectSuccess = (state) => {
    return updateObject(state, {
        loading: false,
        uploadProjectFlag: 'E'
    });
};

const uploadProjectFailure = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
        uploadProjectFlag: 'E'
    });
};


const importflowStart = (state) => {
    return updateObject(state, { error: null, importflowStart: true });
};

const importflowSuccess = (state, action) => {
    return updateObject(state, {
        error: null,
        importflowStart: false,
        selectedFlowName:action.botname
    });
};

const importflowFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        importflowStart: false
    });
};
const exportProjectStart = (state) => {
    return updateObject(state, { error: null, exportProjectStart: true });
};

const exportProjectSuccess = (state) => {
    return updateObject(state, {
        error: null,
        exportProjectStart: false,
    });
};

const exportProjectFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        exportProjectStart: false
    });
};

const initChatBotSuccess = (state, action) => {
    return updateObject(state, {
        initChatBotbots: action.initChatBot
    });
};

const initChatBotFailure = (state, action) => {
    return updateObject(state, {
        error: action.error,
    });
};

const intentListDataScoreSuccess = (state, action) => {
    return updateObject(state, {
        intentListDataScore: action.intentListDataScore
    });
};

const intentListDataScoreFailure = (state, action) => {
    return updateObject(state, {
        error: action.error,
    });
};

const createProjectAccuracySuccess = (state, action) => {
    return updateObject(state, {
        createProjectAccuracy: action.createProjectAccuracy
    });
};

const createProjectAccuracyFailure = (state, action) => {
    return updateObject(state, {
        error: action.error,
    });
};


const translationProjectStart = (state) => {
    return updateObject(state, { error: null, projectTranslation: true, translationinprogress: true });
};
const translationProjectEnd = (state) => {
    return updateObject(state, { error: null, projectTranslation: false, translationinprogress: false });
};

const getProjectData = (state, action) => {
    return updateObject(state, { error: null, projectdata: action.projectdata });
};

const getMacroData = (state, action) => {
    return updateObject(state, { error: null, macrodata: action.macrodata });
};

const getMacroListData = (state, action) => {
    return updateObject(state, { error: null, macrolist: action.macrolist });
};

const knowledgeGridExpandCollapse = (state, action) => {
    return updateObject(state, { error: null, IsKnowledgeGridCollapse: action.expand == "expand" ? true : false });
};



const handleTranslationBtn = (state, action) => {
    return updateObject(state, { error: null, projectTranslation: action.flag });
};


const saveMacroFileDataStart = (state) => {
    return updateObject(state, { error: null, scriptLoading: true });
};
const saveMacroFileDataFailure = (state, action) => {
    return updateObject(state, {
        error: action.error,
        scriptLoading: false
    });
};

const saveMacroFileDataSuccess = (state, action) => {
    return updateObject(state, {
        macroFileData: action.macroFileData,
        scriptLoading: false
    });
};

const updateTicketSystemData = (state, action) => {
    return {
        ...state,
        ticketSystemItems: uniq(concat(state.ticketSystemItems, action.pattern)),
        connectionsTabs: uniq(state.connectionsTabs.concat('ticket_systems').sort().reverse())
    }
};

const updateKnowledgeBaseItems = (state, action) => {
    console.log(action);
    return {
        ...state,
        knowledgeBaseItems: uniq(concat(state.knowledgeBaseItems, action.pattern)),
        connectionsTabs: uniq(state.connectionsTabs.concat('knowledge_base').sort().reverse())
    }
}
const chatSuccess = (state, action) => {
    return updateObject(state, {
        chat: action.chat
    });
};
const FlowReplySuccess = (state, action) => {
    return updateObject(state, {
        FlowReply: action.FlowReply
    });
};
const buttonSuccess = (state, action) => {
    return updateObject(state, {
        buttondata: action.button
    });
};
const buttonLabelSuccess = (state, action) => {
    return updateObject( state, {
        buttonLabelRows: action.buttoninput,
    });
};

const schedulerSuccess = (state, action) => {
    return updateObject(state, {
        schedule: action.schedule
    });
};


const userSchedulerSuccess = (state, action) => {
    return updateObject(state, {
        userSchedule: action.userSchedule
    });
};

const inputSchedulerSuccess = (state, action) => {
    return updateObject(state, {
        inputSchedule: action.inputSchedule
    });
};

const timeSchedulerSuccess = (state, action) => {
    return updateObject(state, {
        timeSchedule: action.timeSchedule
    });
};

const indexSuccess = (state, action) => {
  
    return updateObject( state, {
        CarouselIndex:action.index
    });
};

const subTitleSuccess = (state, action) => {
    return updateObject( state, {
        subtitle:action.subtitle
    });
};

const imageSuccess = (state, action) => {
    return updateObject( state, {
        image:action.image
    });
};


const imgtitleSuccess = (state, action) => {
    return updateObject( state, {
        imgtitle:action.title
    });
};

const imgurlSuccess = (state, action) => {
    return updateObject( state, {
        imageurl:action.image
    });
};

const updateFileFailure = (state, action) => {
    return updateObject(state, {
        error: action.error,
    });
};

const updateFileSuccess = (state, action) => {
    return updateObject(state, {
        fileDataResponse: action.fileDataResponse,
    });
};

//-----------------------Save Category -----------------------

const saveCategoryStart = ( state ) => {
    return updateObject( state, { error: null, createcatstart: true,createcatflag:'S' } );
};

const saveCategorySuccess = (state, action) => {
    return updateObject( state, { 
        categoryList : state.categoryList.concat(action.newcatname),
        error: null,
        createcatstart: false,
        createcatflag:'E'
     } );
};

const saveCategoryFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        createcatstart: false,
        createcatflag:'E'
    });
};



const getAllCategoryStart = ( state ) => {
    return updateObject( state, { error: null, createcatstart: true,createcatflag:'S' } );
};

const getAllCategorySuccess = (state, action) => {
    return updateObject( state, { 
        catList : action.catList
     } );
};

const getAllCategoryFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        createcatstart: false,
        createcatflag:'E'
    });
};
const listSuccess = (state, action) => {
    if(Object.keys(action.list).includes("listTitle")){
        state.list['listTitle']=action.list['listTitle']
    }
    else if(Object.keys(action.list).includes("listType")){
        state.list['listType']=action.list['listType']
    }
    else if(Object.keys(action.list).includes("listRows")){
        state.list['listRows']=action.list['listRows']
    }
    else{
        state.list= {'listTitle':'',
        'listType':'',
        }
    }
  
    return updateObject( state, {
        list: state.list,
    });
};
const nodeSuccess =(state, action)=>{
    let node=null
    if(action.node == ""){
        node=[]
    }
    else{
        node = state.node.concat(action.node)
    }
    return updateObject( state, {
        node: node,
    });
}
const emailSuccess = (state, action) => {
    return updateObject( state, {
        email:action.email
    });
};
const updateCategoryStart = ( state ) => {
    return updateObject( state, { error: null, createcatstart: true,createcatflag:'S' } );
};

const updateCategorySuccess = (state, action) => {
    return updateObject( state, { 
        data:action.data,
        error: null,
     } );
};

const updateCategoryFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        createcatstart: false,
        createcatflag:'E'
    });
};

const updateSharePointBaseUrl = (state, action) => {
    return {
        ...state,
        sharePointBaseUrl: action.payload
    }
}

const setrefresh = (state, action) => {
    return {
        ...state,
        refresh: action.payload
    }
}

const flowsSuccess = (state, action) => {
    return updateObject( state, {
        flows:action.flows
    });
};

const getCategoryyTreeDataStart = ( state ) => {
    return updateObject( state, { error: null, createcatstart: true,createcatflag:'S' } );
};

const getCategoryyTreeDataSuccess = (state, action) => {
    return updateObject( state, { 
        treeData : action.treeData
     } );
};

const getCategoryyTreeDataFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        createcatstart: false,
        createcatflag:'E'
    });
};

const deleteCategoryStart = ( state ) => {
    return updateObject( state, { error: null, createcatstart: true,createcatflag:'S' } );
};

const deleteCategoryFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        createcatstart: false,
        createcatflag:'E'
    });
};

const conditionSuccess = (state, action) => {
    if(action.node == 'Condition A'){
        return updateObject( state, {
            ConditionA: action.cndt,
        });
    }
    else if (action.node == 'Condition B'){
        return updateObject( state, {
            ConditionB: action.cndt,
        });
    }
   
};
const ruleSuccess = (state, action) => {
    return updateObject( state, {
        rule: action.rule,
    });
};

const macrosReadFileFailure = (state, action) => {
    return updateObject(state, {
        error: action.error,
    });
};

const macrosReadFileSuccess = (state, action) => {
    return updateObject(state, {
        getMacrosFileData: action.getMacrosFileData,
    });
};

const saveMacrosDataFailure = (state, action) => {
    return updateObject(state, {
        error: action.error,
    });
};

const saveMacrosDataSuccess = (state, action) => {
    return updateObject(state, {
        saveMacrosData: action.saveMacrosData,
    });
};

const configurationInputFailure = (state, action) => {
    return updateObject(state, {
        error: action.error,
    });
};

const configurationInputSuccess = (state, action) => {
    return updateObject(state, {
        configurationInputs: action.configurationInputs,
    });
};

const delProfileSuccess = (state, action) => {
  
    let lang = action.profilename.language
    let pro = action.profilename.projectName
    let bot = action.profilename.botName
    let profile = action.profilename.profile
    let botList = JSON.parse(state.botList);
    let profileList = botList[lang][pro][bot];
  
    if (profileList.length > 0) {
        for(let i=0 ; i< profileList.length; i++){
            if (profileList[i].profile == profile) {
                profileList.splice(i, 1);
            }
        }
    }
  
     botList = JSON.stringify(botList)
    return updateObject(state, {
        botList: botList,
        error: null,
      
    });
};


const saveChatSuccess = (state, action) => {
    if(action.chatNode == 'ConditionA Chat node'){
        return updateObject( state, {
            ConditionAChat: action.chatMsg,
        });
    }
 
    else if (action.chatNode == 'ConditionB Chat node'){
        return updateObject( state, {
            ConditionBChat: action.chatMsg,
        });
    }
   
    else if (action.chatNode == 'chatReply,reply node'){
        return updateObject( state, {
            chatReply: action.chatMsg,
        });
    }
   
   
};

const bConfigurationFailure = (state, action) => {
    return updateObject(state, {
        error: action.error,
    });
};

const bConfigurationSuccess = (state, action) => {
    return updateObject(state, {
        bConfiguration: action.bConfiguration,
    });
};
const videoSuccess= (state, action) => {
    return updateObject(state, {
        videoContents: action.video,
    });
};

const removeNodeSuccess =(state, action)=>{
    var indexDel = state.node.indexOf(action.removeNode);
    if(indexDel > -1) {
         state.node.splice(indexDel, 1);
    }
    return updateObject( state, {
        node: state.node,
    });    
}
const changeProjectAction = (state, action) => {
    return updateObject(state, { error: null, IsProjectChange: action.projectname  });
};

const changeTabAction = (state, action) => {
    return updateObject(state, { error: null, IsTabChanged: action.tab  });
};

const CarouselSuccess = (state, action) => {
    console.log(action.CarouselInput)
    return updateObject( state, {
        Carousel: action.CarouselInput.Carousel,
    });
};
const ProjectDescriptionSuccess = (state, action) => {
    return updateObject( state, { 
        projectdescription: action.projectdescription,
        error: null,
     } );
};

const ProjectDescriptionFailure = (state, action) => {
    return updateObject( state, {
        error: action.error,
    });
};

const projectHeaderShow = (state, action) => {
    return updateObject(state, { error: null, showProjectHeader: action.showProjectHeader });
};

const getIntentDescriptionSuccess = (state, action) => {
    return updateObject( state, { 
        intentDescription: action.intentDescription,
        error: null,
     } );
};

const getIntentDescriptionFailure = (state, action) => {
    return updateObject( state, {
        error: action.error,
    });
};
const saveFAQFromUrlStart = (state, action) => {
    return updateObject(state, {loading: action.Data});
};
const saveFAQFromUrlFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
    });
};
const saveFAQFromUrlSuccess = (state, action) => {
    return updateObject(state, {
        faqData: action.Data,
    });
};

const getContinousDataStart = (state, action) => {
    return updateObject(state, {loading: action.loading});
};
const getContinousDataFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
    });
};
const getContinousDataSuccess = (state, action) => {
    return updateObject(state, {
        continuousData: action.continuousData,
    });
};

const getWaitingForApprovalIntentStart = (state, action) => {
    return updateObject(state, {waitinForApprovalLoading: action.waitinForApprovalLoading});
};
const getWaitingForApprovalIntentFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
    });
};
const getWaitingForApprovalIntentSuccess = (state, action) => {
    return updateObject(state, {
        waitinForApprovalData: action.waitinForApprovalData,
    });
};

const getWhatsappConfigDataSuccess = (state, action) => {
    return updateObject(state, {
        whatsAppConfigData: action.data
    })
}

const copyChannelSuccess = (state) => {
    return updateObject(state, {
        copyChannelRefresh: !state.copyChannelRefresh,
        error: null, 
        loading: false
    })
}

const projectReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.PROJECT_REDIRECT_TO: return setRedirectionData(state, action);
        case actionTypes.TRIGGER_START: return triggerStart(state);
        case actionTypes.TRIGGER_SUCCESS: return triggerSuccess(state, action);
        case actionTypes.TRIGGER_FAILURE: return triggerFailure(state, action);

        case actionTypes.TRIGGER_START_DOC: return triggerStartDoc(state);
        case actionTypes.TRIGGER_SUCCESS_DOC: return triggerSuccessDoc(state);
        case actionTypes.TRIGGER_FAILURE_DOC: return triggerFailureDoc(state);


        case actionTypes.INTENT_START: return IntentStart(state);
        case actionTypes.INTENT_SUCCESS: return IntentSuccess(state, action);
        case actionTypes.INTENT_FAILURE: return IntentFailure(state, action);
        case actionTypes.SAVE_PROFILE_START: return saveProfileStart(state);
        case actionTypes.SAVE_PROFILE_SUCCESS: return saveProfileSuccess(state, action);
        case actionTypes.SAVE_PROFILE_FAILURE: return saveProfileFailure(state, action);
        case actionTypes.GET_STRUCTURE_SUCCESS: return getStructureSuccess(state, action);
        case actionTypes.GET_STRUCTURE_FAILURE: return getStructureFailure(state, action);
        case actionTypes.GET_BOTFILE_SUCCESS: return getBotFileSuccess(state, action);
        case actionTypes.GET_BOTFILE_FAILURE: return getBotFileFailure(state, action);
        case actionTypes.GET_ENTITY_START: return getEntityStart(state);
        case actionTypes.GET_ENTITY_SUCCESS: return getEntitySuccess(state, action);
        case actionTypes.GET_ENTITY_FAILURE: return getEntityFailure(state, action);
        case actionTypes.READ_FILE_SUCCESS: return readFileSuccess(state, action);
        case actionTypes.READ_FILE_FAILURE: return readFileFailure(state, action);
        case actionTypes.CREATE_ENTITY_START: return createEntityStart(state);
        case actionTypes.CREATE_ENTITY_SUCCESS: return createEntitySuccess(state, action);
        case actionTypes.CREATE_ENTITY_FAILURE: return createEntityFailure(state, action);
        case actionTypes.CREATE_BOT_SUCCESS: return createBotSuccess(state, action);
        case actionTypes.CREATE_BOT_FAILURE: return createBotFailure(state, action);
        case actionTypes.CREATE_BOT_START: return createBotStart(state);
        case actionTypes.GET_BOT_LIST_SUCCESS: return getBotListSuccess(state, action);
        case actionTypes.GET_BOT_LIST_FAILURE: return getBotListFailure(state, action);

        case actionTypes.GET_ASSISTANTS_LIST_SUCCESS: return getAssistantsListSuccess(state, action);
        case actionTypes.GET_ASSISTANTS_FAILURE: return getAssistantsFailure(state, action);
        case actionTypes.GET_ASSISTANTS_START: return getAssistantsListStart(state, action);
        case actionTypes.GET_PROJECT_LIST_SUCCESS: return getProjectsListSuccess(state, action);
        case actionTypes.GET_PROJECT_LIST_FAILURE: return getProjectsListFailure(state, action);
        case actionTypes.GET_PROJECTLIST_START: return getProjectsListStart(state, action);
        
        case actionTypes.GET_CHATBOT_SUCCESS: return getChatBotSuccess(state, action);
        case actionTypes.GET_CHATBOT_FAILURE: return getChatBotFailure(state, action);
        case actionTypes.GET_DOC_SUCCESS: return getDocSuccess(state, action);
        case actionTypes.GET_DOC_FAILURE: return getDocFailure(state, action);
        case actionTypes.GET_PROJECT_DESCRIPTION_SUCCESS: return getProjectDescriptionSuccess(state, action);
        case actionTypes.GET_PROJECT_DESCRIPTION_FAILURE: return getProjectDescriptionFailure(state, action);
        case actionTypes.KNOWLEDGE_INTENT_START: return knowledgeIntentStart(state);
        case actionTypes.KNOWLEDGE_INTENT_SUCCESS: return knowledgeIntentSuccess(state, action);
        case actionTypes.KNOWLEDGE_INTENT_FAILURE: return knowledgeIntentFailure(state, action);
        case actionTypes.KNOWLEDGE_WITH_FILE_INTENT_START: return knowledgeWithFileIntentStart(state);
        case actionTypes.KNOWLEDGE_WITH_FILE_INTENT_SUCCESS: return knowledgeWithFileIntentSuccess(state, action);
        case actionTypes.KNOWLEDGE_WITH_FILE_INTENT_CANCEL: return knowledgeWithFileIntentCancel(state);
        case actionTypes.KNOWLEDGE_WITH_FILE_INTENT_FAILURE: return knowledgeWithFileIntentFailure(state, action);
        case actionTypes.GET_KNOWLEDGE_INTENT_START: return GetknowledgeIntentStart(state);
        case actionTypes.GET_KNOWLEDGE_INTENT_SUCCESS: return GetknowledgeIntentSuccess(state, action);
        case actionTypes.GET_KNOWLEDGE_INTENT_FAILURE: return GetknowledgeIntentFailure(state, action);
        case actionTypes.GET_SYNONYMS_START: return getSynonymsStart(state);
        case actionTypes.GET_SYNONYMS_SUCCESS: return getSynonymsSuccess(state, action);
        case actionTypes.GET_SYNONYMS_FAILURE: return getSynonymsFailure(state, action);
        case actionTypes.GET_CONNECTIONS_DATA_START: return getConnectionsDataStart(state);
        case actionTypes.GET_CONNECTIONS_DATA_SUCCESS: return getConnectionsDataSuccess(state, action);
        case actionTypes.GET_CONNECTIONS_DATA_FAILURE: return getConnectionsDataFailure(state, action);
        case actionTypes.GET_SITE_LIST_START: return getSiteListStart(state);
        case actionTypes.GET_SITE_LIST_SUCCESS: return getSiteListSuccess(state, action);
        case actionTypes.GET_SITE_LIST_FAILURE: return getSiteListFailure(state, action);

        case actionTypes.GET_SHARE_POINT_DATA_START: return getSharePointDataStart(state);
        case actionTypes.GET_SHARE_POINT_DATA_SUCCESS: return getSharePointDataSuccess(state, action);
        case actionTypes.GET_SHARE_POINT_DATA_FAILURE: return getSharePointDataFailure(state, action);
        case actionTypes.SELECT_SHARE_POINT_FILE: return selectSharePointFile(state, action);
        case actionTypes.RESET_SHARE_POINT_DATA: return resetSharePointData(state);
        case actionTypes.DELETE_FLOW_BOT_START: return delFlowBotStart(state);
        case actionTypes.DELETE_FLOW_BOT_SUCCESS: return delFlowBotSuccess(state, action);
        case actionTypes.DELETE_FLOW_BOT_FAIL: return delFlowBotFail(state, action);
        case actionTypes.KNOWLEDGE_INTENT_IMPORT_START: return knowledgeIntentImportStart(state);
        case actionTypes.KNOWLEDGE_INTENT_IMPORT_SUCCESS: return knowledgeIntentImportSuccess(state, action);
        case actionTypes.KNOWLEDGE_INTENT_IMPORT_FAILURE: return knowledgeIntentImportFailure(state, action);
        case actionTypes.DELETE_KNOWLEDGE_INTENT_START: return deleteKnowledgeIntentStart(state);
        case actionTypes.DELETE_KNOWLEDGE_INTENT_END: return deleteKnowledgeIntentEnd(state);
        case actionTypes.EXPORT_FLOW_BOT_START: return exportFlowBotStart(state);
        case actionTypes.EXPORT_FLOW_BOT_SUCCESS: return exportFlowBotSuccess(state);
        case actionTypes.EXPORT_FLOW_BOT_FAIL: return exportFlowBotFail(state, action);
        case actionTypes.UPLOAD_PROJECT_START: return uploadProjectStart(state);
        case actionTypes.UPLOAD_PROJECT_SUCCESS: return uploadProjectSuccess(state);
        case actionTypes.UPLOAD_PROJECT_FAILURE: return uploadProjectFailure(state, action);
        case actionTypes.IMPORT_FLOW_START: return importflowStart(state);
        case actionTypes.IMPORT_FLOW_SUCCESS: return importflowSuccess(state, action);
        case actionTypes.IMPORT_FLOW_FAIL: return importflowFail(state, action);
        case actionTypes.EXPORT_PROJECT_START: return exportProjectStart(state);
        case actionTypes.EXPORT_PROJECT_SUCCESS: return exportProjectSuccess(state);
        case actionTypes.EXPORT_PROJECT_FAIL: return exportProjectFail(state, action);
        case actionTypes.INIT_CHAT_BOT_SUCCESS: return initChatBotSuccess(state, action);
        case actionTypes.INIT_CHAT_BOT_FAILURE: return initChatBotFailure(state, action);

        case actionTypes.CONNECT_DATA_IMPORT_SUCCESS: return connectDataImportSuccess(state, action);
        case actionTypes.CONNECT_DATA_IMPORT_FAILURE: return connectDataImportFailure(state, action);
        case actionTypes.CONNECT_DATA_IMPORT_START: return connectDataImportStart(state);
        case actionTypes.GET_CLUSTER_DATA_SUCCESS: return getCLusterDataSuccess(state, action);
        case actionTypes.GET_CLUSTER_DATA_FAILURE: return getCLusterDataFailure(state, action);
        case actionTypes.GET_CLUSTER_DATA_START: return getCLusterDataStart(state);
        case actionTypes.INTENT_LIST_DATA_SCORE_SUCCESS: return intentListDataScoreSuccess(state, action);
        case actionTypes.INTENT_LIST_DATA_SCORE_FAILURE: return intentListDataScoreFailure(state, action);
        case actionTypes.CREATE_PROJECT_ACCURACY_SUCCESS: return createProjectAccuracySuccess(state, action);
        case actionTypes.CREATE_PROJECT_ACCURACY_FAILURE: return createProjectAccuracyFailure(state, action);

        case actionTypes.TRANSLATION_PROJECT_START: return translationProjectStart(state);
        case actionTypes.TRANSLATION_PROJECT_END: return translationProjectEnd(state);
        case actionTypes.GET_PROJECT_DATA: return getProjectData(state, action);
        case actionTypes.HANDLE_TRANSLATION_BTN: return handleTranslationBtn(state, action);
        case actionTypes.GET_MACRO_DATA: return getMacroData(state, action);
        case actionTypes.GET_MACRO_LIST: return getMacroListData(state, action);
        case actionTypes.KNOWLEDGEGRIDEXPANDCOLLAPSE: return knowledgeGridExpandCollapse(state, action);
        case actionTypes.UPDATE_KNOWLEDGE_INTENT_SUCCESS: return UpdateKnowledgeIntentSuccess(state, action);
        case actionTypes.UPDATE_KNOWLEDGE_INTENT_FAILURE: return UpdateKnowledgeIntentFailure(state, action);
        case actionTypes.UPDATE_KNOWLEDGE_INTENT_START: return UpdateKnowledgeIntentStart(state);

        case actionTypes.SAVE_MACRO_FILE_START: return saveMacroFileDataStart(state);
        case actionTypes.SAVE_MACRO_FILE_SUCCESS: return saveMacroFileDataSuccess(state, action);
        case actionTypes.SAVE_MACRO_FILE_FAILURE: return saveMacroFileDataFailure(state, action);
        case actionTypes.UPDATE_TICKET_SYSTEMS_DATA: return updateTicketSystemData(state, action);
        case actionTypes.UPDATE_KNOWLEDGE_BASE_DATA: return updateKnowledgeBaseItems(state, action);

        case actionTypes.SCHEDULER_SUCCESS: return schedulerSuccess(state, action);
        case actionTypes.USER_SCHEDULER_SUCCESS: return userSchedulerSuccess(state, action);
        case actionTypes.BUTTON_SUCCESS: return buttonSuccess(state, action);
        case actionTypes.BUTTON_LABEL_SUCCESS: return buttonLabelSuccess(state, action);
       // case actionTypes.BUTTON_ACTION_SUCCESS: return buttonActionSuccess(state, action);
        case actionTypes.INPUT_SCHEDULER_SUCCESS: return inputSchedulerSuccess(state, action);
        case actionTypes.TIME_SCHEDULER_SUCCESS: return timeSchedulerSuccess(state, action);
        case actionTypes.FETCH_DATA_SOURCE_INDEX_NAMES_START: return fetchDataSourceIndexNamesStart(state);
        case actionTypes.FETCH_DATA_SOURCE_INDEX_NAMES_SUCCESS: return fetchDataSourceIndexNamesSuccess(state, action);
        case actionTypes.FETCH_DATA_SOURCE_INDEX_NAMES_FAILURE: return fetchDataSourceIndexNamesFailure(state);
        case actionTypes.ADD_DATA_SOURCE_INDEX_NAMES: return addDataSourceIndexNames(state, action);
        case actionTypes.CHAT_SUCCESS: return chatSuccess(state, action);
        case actionTypes.FLOWREPLY_SUCCESS: return FlowReplySuccess(state, action);

        case actionTypes.INDEX_SUCCESS: return indexSuccess(state, action);
        case actionTypes.SUBTITLE_SUCCESS: return subTitleSuccess(state, action);
        case actionTypes.IMAGE_SUCCESS: return imageSuccess(state, action);
        case actionTypes.UPDATE_FILE_SUCCESS: return updateFileSuccess(state, action);
        case actionTypes.UPDATE_FILE_FAILURE: return updateFileFailure(state, action);

        case actionTypes.IMG_TITLE_SUCCESS: return imgtitleSuccess(state, action);
        case actionTypes.IMG_URL_SUCCESS: return imgurlSuccess(state, action);

	//aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa
        case actionTypes.SAVE_CATEGORY_START: return saveCategoryStart(state);
        case actionTypes.SAVE_CATEGORY_SUCCESS: return saveCategorySuccess(state, action);
        case actionTypes.SAVE_CATEGORY_FAIL: return saveCategoryFail(state, action);

        case actionTypes.GET_ALL_CATEGORY_START: return getAllCategoryStart(state);
        case actionTypes.GET_ALL_CATEGORY_SUCCESS: return getAllCategorySuccess(state, action);
        case actionTypes.GET_ALL_CATEGORY_FAIL: return getAllCategoryFail(state, action);
        case actionTypes.EMAIL_SUCCESS: return emailSuccess(state, action);

        case actionTypes.LIST_SUCCESS: return listSuccess(state, action);
        case actionTypes.NODE_SUCCESS: return nodeSuccess(state, action);
        case actionTypes.UPDATE_SHARE_POINT_BASE_URL: return updateSharePointBaseUrl(state, action);
        case actionTypes.SET_REERESH_OTHERDOC: return setrefresh(state, action);
        case actionTypes.FLOWS_SUCCESS: return flowsSuccess(state, action);
	
	    case actionTypes.UPDATE_CATEGORY_START: return updateCategoryStart(state);
        case actionTypes.UPDATE_CATEGORY_SUCCESS: return updateCategorySuccess(state, action);
        case actionTypes.UPDATE_CATEGORY_FAIL: return updateCategoryFail(state, action);

        case actionTypes.GET_CATEGORY_TREEDATA_START: return getCategoryyTreeDataStart(state);
        case actionTypes.GET_CATEGORY_TREEDATA_SUCCESS: return getCategoryyTreeDataSuccess(state, action);
        case actionTypes.GET_CATEGORY_TREEDATA_FAIL: return getCategoryyTreeDataFail(state, action);

        case actionTypes.DELETE_CATEGORY_START: return deleteCategoryStart(state);
        case actionTypes.DELETE_CATEGORY_FAIL: return deleteCategoryFail(state, action);

        case actionTypes.CONDITION_SUCCESS: return conditionSuccess(state, action);
        case actionTypes.RULE_SUCCESS: return ruleSuccess(state, action);

        case actionTypes.MACROS_READ_FILE_SUCCESS: return macrosReadFileSuccess(state, action);
        case actionTypes.MACROS_READ_FILE_FAILURE: return macrosReadFileFailure(state, action);

        case actionTypes.SAVE_MACROS_DATA_SUCCESS: return saveMacrosDataSuccess(state, action);
        case actionTypes.SAVE_MACROS_DATA_FAILURE: return saveMacrosDataFailure(state, action);

        case actionTypes.CONFIGURATION_INPUT_SUCCESS: return configurationInputSuccess(state, action);
        case actionTypes.CONFIGURATION_INPUT_FAILURE: return configurationInputFailure(state, action);

        case actionTypes.DELETE_PROFILE_SUCCESS: return delProfileSuccess(state, action);

        case actionTypes.SAVECHAT_SUCCESS: return saveChatSuccess(state, action);
        
        case actionTypes.B_CONFIGURATION_SUCCESS: return bConfigurationSuccess(state, action);
        case actionTypes.B_CONFIGURATION_FAILURE: return bConfigurationFailure(state, action);
         case actionTypes.REMOVE_NODE_SUCCESS: return removeNodeSuccess(state, action);
         case actionTypes.VIDEO_SUCCESS:return videoSuccess(state,action);
         case actionTypes.changeProjectActionType: return changeProjectAction(state, action);
         case actionTypes.INEDITFLOWTAB:return changeTabAction(state,action);
         case actionTypes.CAROUSEL_SUCCESS:return CarouselSuccess(state,action)

        case actionTypes.CUXBOT_START: return cuxBotStart(state);
        case actionTypes.CUX_LOADER: return CuxLoader(state, action);

        case actionTypes.GET_CUXBOT_DATA_SUCCESS: return getCuxBotDataSuccess(state, action);
        case actionTypes.GET_CUXBOT_DATA_FAILURE: return getCuxBotDataFailure(state, action);

        case actionTypes.UPDATE_CUXBOT_DATA_SUCCESS: return updateCuxBotDataSuccess(state);
        case actionTypes.UPDATE_CUXBOT_DATA_FAILURE: return updateCuxBotDataFailure(state, action);

        case actionTypes.GET_CUXBOT_LIST_SUCCESS: return getCuxBotListSuccess(state, action);
        case actionTypes.GET_CUXBOT_LIST_FAILURE: return getCuxBotListFailure(state, action);

        case actionTypes.DELETE_CUXBOT_SUCCESS: return delCuxBotSuccess(state, action);
        case actionTypes.DELETE_CUXBOT_FAILURE: return delCuxBotFailure(state, action);

        case actionTypes.CREATE_CUXBOT_SUCCESS: return createCuxBotSuccess(state, action);
        case actionTypes.CREATE_CUXBOT_FAILURE: return createCuxBotFailure(state, action);

        case actionTypes.GET_CUXTRIGGER_LIST_SUCCESS: return getCuxTriggerListSuccess(state, action);
        case actionTypes.GET_CUXTRIGGER_LIST_FAILURE: return getCuxTriggerListFailure(state, action);

        case actionTypes.CREATE_CUXTRIGGER_FAILURE: return createCuxTriggerFailure(state, action);
        case actionTypes.UPDATE_CUXTRIGGER_FAILURE: return updateCuxTriggerFailure(state, action);

        case actionTypes.UPDATE_CUX_FORM_ACTIVENODE: return updateCuxFormField(state, action);

        case actionTypes.GET_CUXBOT_TEMPLATES_SUCCESS: return getCuxTemplatesSuccess(state, action);
        case actionTypes.CREATE_CUXTEMPLATE_SUCCESS: return createCuxTemplateSuccess(state, action);
        case actionTypes.CUXTEMPLATE_FAILURE: return CuxTemplateFailure(state,action);
        case actionTypes.CLEAR_CUXTEMPLATES_SUCCESS: return ClearCuxTemplates(state);

        case actionTypes.PROJECTDESCRIPTION_SUCCESS: return ProjectDescriptionSuccess(state, action);
        case actionTypes.PROJECTDESCRIPTION_FAILURE: return ProjectDescriptionFailure(state, action);

        case actionTypes.PROJECT_HEADER_SHOW: return projectHeaderShow(state, action);

        case actionTypes.GET_INTENT_DESCRIPTION_SUCCESS: return getIntentDescriptionSuccess(state, action);
        case actionTypes.GET_INTENT_DESCRIPTION_FAIL: return getIntentDescriptionFailure(state, action);

        case actionTypes.GET_FAQ_FROM_URL_START: return saveFAQFromUrlStart(state,action);
        case actionTypes.GET_FAQ_FROM_URL_SUCCESS: return saveFAQFromUrlSuccess(state, action);
        case actionTypes.GET_FAQ_FROM_URL_FAIL: return saveFAQFromUrlFail(state, action);

        case actionTypes.GET_CONTINUOUS_DATA_START: return getContinousDataStart(state,action);
        case actionTypes.GET_CONTINUOUS_DATA_SUCCESS: return getContinousDataSuccess(state, action);
        case actionTypes.GET_CONTINUOUS_DATA_FAIL: return getContinousDataFail(state, action);

        case actionTypes.GET_WAITING_FOR_APPROVAL_INTENT_START: return getWaitingForApprovalIntentStart(state,action);
        case actionTypes.GET_WAITING_FOR_APPROVAL_INTENT_SUCCESS: return getWaitingForApprovalIntentSuccess(state, action);
        case actionTypes.GET_WAITING_FOR_APPROVAL_INTENT_FAIL: return getWaitingForApprovalIntentFail(state, action);

        case actionTypes.ACTION_START: return actionStart(state);
        case actionTypes.ACTION_SUCCESS: return actionSuccess(state);
        case actionTypes.ACTION_FAILURE: return actionFailure(state, action);

        case actionTypes.GET_WHATSAPP_DATA_SUCCESS: return getWhatsappConfigDataSuccess(state,action);
        case actionTypes.COPY_CHANNEL_SUCCESS: return copyChannelSuccess(state);

        default:
            return state;
    }
};

export default projectReducer;
