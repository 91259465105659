import React, { useState, useReducer, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import Input from '../../../../components/UI/Input/InputCustom';
import { Form, Button, Spinner, Table, Row } from 'react-bootstrap';
import './connections-tab.css';
import { botPostCalltoServer } from '../../../../store/utility';
import { getConnectionsData, fetchDataSourceIndexNames, SetErrorMessage, SetSuccessMessage } from '../../../../store/actions';
import ReactSelectComponent from './ReactSelectComponent/ReactSelectComponent';
import AddCollectionModal from './AddCollectionModal';
import EditCollectionModal from './EditCollectionModal';
import ViewCollectionModal from './ViewCollectionModal';
import { isEqual, isNil } from 'lodash';
import { addDataSourceIndexNames } from '../../../../store/actions/projectview';

import ReactMultiSelectComponent from './ReactMultiSelect/ReactSelectComponent';

const initialState = {
    rows: [],
    mappedData: {
        target: '',
        sourceField: null,
        transform: null
    },
    saveDataLoading: false,
};

const addRows = (state, payload) => {
    const isPayloadExist = state.rows.find(row => isEqual(row, payload));
    if (isNil(isPayloadExist)) {
        return {
            ...state,
            rows: state.rows.concat(payload)
        }
    }
    return state;
}

function reducer(state, action) {
    switch (action.type) {
        case 'ADD_ITEM':
            return addRows(state, action.payload)
        case 'REMOVE_ITEM':
            return {
                ...state,
                rows: state.rows.filter((item, index) => index !== action.payload)
            };
        case 'changeMappedData':
            return {
                ...state,
                mappedData: {
                    ...state.mappedData,
                    ...action.payload
                }
            }
        case 'IS_SAVE_DATA_LOADING':
            return {
                ...state,
                saveDataLoading: action.payload
            }
        case 'UPDATE_ITEM':
            return {
                ...state,
                rows: action.payload
            }
        default:
            throw new Error();
    }
}

const addItem = (payload) => ({
    type: 'ADD_ITEM',
    payload,
});

const removeItem = (payload) => ({
    type: 'REMOVE_ITEM',
    payload,
});

const updateItem = (payload) => ({
    type: 'UPDATE_ITEM',
    payload,
});

const isSaveDataLoading = (payload) => ({
    type: 'IS_SAVE_DATA_LOADING',
    payload,
});

function DataMappingTab({ selectedDataMapping, selectedMenu, selectedSubMenu, config, tabId }) {
    const [selectedProjects, setSelectedProjects] = useState([]);
    const [transformsLoading, setTransformsLoading] = useState(false);
    const [sourceFieldLoading, setSourceFieldLoading] = useState(false);
    const [transformations, setTransformations] = useState([]);
    const [sourceFields, setSourceFields] = useState([]);
    const [filteredSourceFields, setFilteredSourceFields] = useState([]);
    const reduxDispatch = useDispatch();
    const connectionsData = useSelector(state => state.projectview.connectionsData)
    const localDataSourceIndexNames = useSelector(state => state.projectview.localDataSourceIndexNames);

    const [state, dispatch] = useReducer(reducer, initialState);
    const [dataMappingIndex, setDataMappingIndex] = useState('');
    const [projectList, setProjectList] = useState([]);
    const inputChangedHandler = (selectedItem, name) => {
        if (name === 'target') {
            dispatch(
                {
                    type: 'changeMappedData',
                    payload: {
                        [name]: selectedItem.target.value
                    }
                })
        } else {
            dispatch(
                {
                    type: 'changeMappedData',
                    payload: {
                        [name]: selectedItem
                    }
                });
        }
    };
    useEffect(() => {
        // setDataMappingIndex(getDataMappingIndexHelper(localDataSourceIndexNames, tabId));
        setDataMappingIndex(selectedDataMapping.index)
        console.log("getDataMappingIndexHelper(localDataSourceIndexNames, tabId) == ",selectedDataMapping.index)
    }, []);
    // useEffect(() => {
    //     setDataMappingIndex(getDataMappingIndexHelper(localDataSourceIndexNames, tabId));
    // }, [localDataSourceIndexNames]);

    useEffect(() => {
       const {rows} = state;
       const addedSourceFields = rows.map(row => get(row, 'sourceField.value', ''));
       setFilteredSourceFields(sourceFields.filter(sourceField => !addedSourceFields.includes(sourceField.value)));
    }, [state.rows, sourceFields]);
    useEffect(() => {
        const requestDataForProjectList = {
            "dataSourceType": selectedMenu,
            'dataSourceName': selectedSubMenu,           
            'tenantId': localStorage.getItem("orgName"),
            "projectName": "",
            "connector": "",
            "projects": null,
        }
        let url2 = config.CONNECTOR_URL + `/datasource-projects`;
        console.log("url = ",url2)
        botPostCalltoServer(url2, requestDataForProjectList)
            .then(response => {
                if (get(response, 'data.status.responseType', '') === "success") {
                    const projectList = get(JSON.parse(response.data.data).data, 'projects', []);
                    const updatedProject = projectList.map(item => ({
                        value: item.key,
                        label: item.name
                    }));
                
                    setProjectList(updatedProject);
                }
                else {
                    reduxDispatch(SetErrorMessage(get(response, 'data.errors.0.source', '')));
                }
            }).catch(err => {
                reduxDispatch(SetErrorMessage(get(err, 'response', '')));
            });

    }, []);
    useEffect(() => {
        const spec = get(selectedDataMapping, 'spec', []);
        const transformedSpec = spec.map(item => ({
            sourceField: item.fieldName,
            transform: item.transformation,
            target: item.targetFieldName
        }))
        dispatch(updateItem(transformedSpec));
        dispatch({
            type: 'changeMappedData',
            payload: {
                target: '',
                sourceField: null,
                transform: null
            }
        });

      
        

        const transformConfigObj = {
           
        };
        const sourceFieldsConfigObj = {            
            'dataSourceType': selectedMenu,
            'dataSourceName': selectedSubMenu,           
            'tenantId': localStorage.getItem("orgName"),
            'projectName': localStorage.getItem("projectname"),                        
            'connector': ""
        };
        let projList=null;
        
        let url = config.CONNECTOR_URL + `/transformations`;
        let url1 = config.CONNECTOR_URL + `/get-all-fields/${projList}`;
        setTransformsLoading(true);
        botPostCalltoServer(url, transformConfigObj)
            .then(response => {
                if (get(response, 'data.status.responseType', '') === "success") {
                    const transformations = get(JSON.parse(response.data.data).data, 'transformations', []);
                    const updatedTransformations = transformations.map(item => ({
                        value: item.transformation,
                        label: item.displayName
                    }));
                    const updatedData = updatedTransformations;
                    setTransformsLoading(false);
                    setTransformations(updatedData);
                    //reduxDispatch(SetSuccessMessage('Fetched transformations Data Successfully'));
                }
                else {
                    setTransformsLoading(false);
                    reduxDispatch(SetErrorMessage(get(response, 'data.errors.0.source', '')));
                }
            }).catch(err => {
                setTransformsLoading(false);
                reduxDispatch(SetErrorMessage(get(err, 'response', '')));
            });
        setSourceFieldLoading(true);
        botPostCalltoServer(url1, sourceFieldsConfigObj)
            .then(response => {
                if (get(response, 'data.status.responseType', '') === "success") {
                    const sourceFields = get(JSON.parse(response.data.data).data, 'fields', []);
                    const transformedSourceFields = sourceFields.map(item => ({
                        value: item.fieldName,
                        label: item.displayName
                    }));
                    const updatedData = transformedSourceFields;
                    setSourceFieldLoading(false);
                    setSourceFields(updatedData);
                    setFilteredSourceFields(updatedData);
                    reduxDispatch(SetErrorMessage(get(response, 'data.errors.0.source', '')));
                }
                else {
                    setSourceFieldLoading(false);
                    reduxDispatch(SetErrorMessage(get(response, 'data.errors.0.source', '')));
                }
            }).catch(err => {
                setSourceFieldLoading(false);
                reduxDispatch(SetErrorMessage(get(err, 'response', '')));
            });
        const payload = {
            config,
            selectedMenu,
            selectedSubMenu
        };
        reduxDispatch(fetchDataSourceIndexNames(payload));
        reduxDispatch(getConnectionsData(config));
        for(var i = 0; i<connectionsData.length; i++){
            if(connectionsData[i]._source.dataMapping.projectList && connectionsData[i]._source.dataMapping.projectList.length>0){
                setSelectedProjects(connectionsData[i]._source.dataMapping.projectList)
            }
        }
    }, [tabId])

    useEffect(() => {
        const spec = get(selectedDataMapping, 'spec', []);
        const transformedSpec = spec.map(item => ({
            sourceField: item.fieldName,
            transform: item.transformation,
            target: item.targetFieldName
        }))
        dispatch(updateItem(transformedSpec));
        dispatch({
            type: 'changeMappedData',
            payload: {
                target: '',
                sourceField: null,
                transform: null
            }
        });      
        

        const transformConfigObj = {
           
        };
        const sourceFieldsConfigObj = {            
            'dataSourceType': selectedMenu,
            'dataSourceName': selectedSubMenu,           
            'tenantId': localStorage.getItem("orgName"),
            'projectName': localStorage.getItem("projectname"),                        
            'connector': ""
        };
        //let projList=null;
        
        let url = config.CONNECTOR_URL + `/transformations`;
        let url1 = config.CONNECTOR_URL + `/get-all-fields/${selectedProjects}`;
        setTransformsLoading(true);
        botPostCalltoServer(url, transformConfigObj)
            .then(response => {
                if (get(response, 'data.status.responseType', '') === "success") {
                    const transformations = get(JSON.parse(response.data.data).data, 'transformations', []);
                    const updatedTransformations = transformations.map(item => ({
                        value: item.transformation,
                        label: item.displayName
                    }));
                    const updatedData = updatedTransformations;
                    setTransformsLoading(false);
                    setTransformations(updatedData);
                    //reduxDispatch(SetSuccessMessage('Fetched transformations Data Successfully'));
                }
                else {
                    setTransformsLoading(false);
                    reduxDispatch(SetErrorMessage(get(response, 'data.errors.0.source', '')));
                }
            }).catch(err => {
                setTransformsLoading(false);
                reduxDispatch(SetErrorMessage(get(err, 'response', '')));
            });
        setSourceFieldLoading(true);
        botPostCalltoServer(url1, sourceFieldsConfigObj)
            .then(response => {
                if (get(response, 'data.status.responseType', '') === "success") {
                    const sourceFields = get(JSON.parse(response.data.data).data, 'fields', []);
                    const transformedSourceFields = sourceFields.map(item => ({
                        value: item.fieldName,
                        label: item.displayName
                    }));
                    const updatedData = transformedSourceFields;
                    setSourceFieldLoading(false);
                    setSourceFields(updatedData);
                    setFilteredSourceFields(updatedData);

                    reduxDispatch(SetErrorMessage(get(response, 'data.errors.0.source', '')));
                }
                else {
                    setSourceFieldLoading(false);
                    reduxDispatch(SetErrorMessage(get(response, 'data.errors.0.source', '')));
                }
            }).catch(err => {
                setSourceFieldLoading(false);
                reduxDispatch(SetErrorMessage(get(err, 'response', '')));
            });
        const payload = {
            config,
            selectedMenu,
            selectedSubMenu
        };
        reduxDispatch(fetchDataSourceIndexNames(payload));
        reduxDispatch(getConnectionsData(config));
    }, [selectedProjects])
    
    const saveDataMapping = () => {
            const getSpec = rows.map(item => ({
                fieldName: get(item, 'sourceField', ''),
                transformation: get(item, 'transform', ''),
                targetFieldName: item.target
            }))
            if(getSpec && getSpec.length>0){
               
                let language = localStorage.getItem("language");
                let orgName=localStorage.getItem("orgName");
                let targetCollection = "optimus"
                if (language && language != "en")
                    targetCollection += "_"+language;
                if (orgName != null && orgName != "default" && orgName !="")
                    targetCollection += "_"+orgName;
                const configObj = {            
                    'dataSourceType': selectedMenu,
                    'dataSourceName': selectedSubMenu,
                    'tenantId': localStorage.getItem("orgName"),
                    'connector': JSON.stringify({
                        "dataMapping": {
                            "index": dataMappingIndex,
                            "targetCollection":targetCollection,
                            "projectList":selectedProjects,
                            "spec": getSpec
                        }
                    })
                };
                let url = config.CONNECTOR_URL + `/update-data-mappings`;
                dispatch(isSaveDataLoading(true));
                botPostCalltoServer(url, configObj)
                    .then(response => {
                        if (get(response, 'data.status.responseType', '') === "success") {
                            dispatch(isSaveDataLoading(false));
                            reduxDispatch(SetSuccessMessage('updated Data Mapping Successfully'));
                        }
                        else {
                            dispatch(isSaveDataLoading(false));
                            reduxDispatch(SetErrorMessage(get(response, 'data.errors.0.source', '')));
                        }
                    }).catch(err => {
                        dispatch(isSaveDataLoading(false));
                        reduxDispatch(SetErrorMessage(get(err, 'response', '')));
                    });

        }
        else{
            reduxDispatch(SetErrorMessage('Source field is mendatory'));
        }
    }

    const updateCollection = (collection) => {
        const transformedOptions = {
            value: collection,
            displayValue: collection
        }
        reduxDispatch(addDataSourceIndexNames(transformedOptions));
    }

    const indexInputChangedHandler = (event) => {
        setDataMappingIndex(event.target.value);
    };

    const addDataMapping = () => {
        if(isNil(mappedData.sourceField) || isNil(mappedData.transform)) {
            return false;
        }
        if(mappedData.target == "" && (mappedData.transform.label !== 'No Action')) {
            reduxDispatch(SetErrorMessage('While renaming/copy, target field is mandatory'));
            return false;
        }
        dispatch({
            type: 'changeMappedData',
            payload: {
                target: '',
                sourceField: null,
                transform: null
            }
        });
        dispatch(addItem(mappedData));
    };
    const multiChangeHandler = (newValue) =>{
        var temp = []
        for(var i=0;i<newValue.length;i++){ 
                temp.push(newValue[i].value)
        }
        setSelectedProjects(temp)
    }
    const onRemoveItemHandler = (item) =>{
        var temp = [...selectedProjects]
        var index = temp.indexOf(item)
        temp.splice(index,1)
        setSelectedProjects(temp)
    }

    const { mappedData, rows, saveDataLoading } = state;
    return (
        <div className='integration-tabs-container'>
            {transformsLoading || sourceFieldLoading ?
                <Row className="justify-content-md-center padding10">
                    <Spinner animation="border" variant="primary" />
                </Row> :
                <div className='box-container'>
                    <div className='row'>
                        <div className="col-md-4 my-10">
                            <Form.Label>Select Collection/ Index</Form.Label>
                            {/* {dataSourceIndexNamesLoading ?
                                <Spinner
                                    as="span"
                                    animation="border"
                                    variant="primary"
                                /> : */}
                                 <Input
                                        key="SelctDropDown"
                                        elementType="select"
                                        defaultOption
                                        elementConfig={{ options: localDataSourceIndexNames }}
                                        value={dataMappingIndex}
                                        changed={(event) => indexInputChangedHandler(event)}
                                />
                        </div>
                        <div className='col-md-2'>
                            <ViewCollectionModal
                                config={config}
                                selectedMenu={selectedMenu}
                                selectedSubMenu={selectedSubMenu}
                                dataMappingIndex={dataMappingIndex} />
                            <EditCollectionModal
                                config={config}
                                selectedMenu={selectedMenu}
                                selectedSubMenu={selectedSubMenu}
                                dataMappingIndex={dataMappingIndex}
                            />
                            <AddCollectionModal
                                config={config}
                                selectedMenu={selectedMenu}
                                selectedSubMenu={selectedSubMenu}
                                updateCollection={updateCollection} />
                        </div>
                        {projectList.length>0?
                            (<div className="col-md-5 SimilarityLevel">
                            <p>Project List</p>
                            <ReactMultiSelectComponent
                                value={selectedProjects}
                                options={projectList}
                                onChange={(newValue) => multiChangeHandler(newValue)}
                                onRemoveItem={onRemoveItemHandler}
                                placeholder={'Select Project'}
                            />
                        </div>):''
                        }
                        
                    </div>
                    <Table bordered>
                        <thead>
                            <tr className='data-happing-table-header'>
                                <th>Source Field</th>
                                <th>Transform</th>
                                <th>Target</th>
                                <th></th>
                            </tr>
                            <tr>
                                <th>
                                    <ReactSelectComponent
                                        options={filteredSourceFields}
                                        name='sourceField'
                                        value={mappedData.sourceField}
                                        onChange={(event) => inputChangedHandler(event, 'sourceField')}
                                    />
                                </th>
                                <th>
                                    <ReactSelectComponent
                                        options={transformations}
                                        value={mappedData.transform}
                                        name='transform'
                                        onChange={(event) => inputChangedHandler(event, 'transform')}
                                    />
                                </th>
                                <th>
                                    <Input
                                        key="selctTarget"
                                        elementType="text"
                                        value={mappedData.target}
                                        name='target'
                                        changed={(event) => inputChangedHandler(event, 'target')}
                                    />
                                </th>
                                <th>
                                    <img className='add-icon' src="./Icons/AddBlue.svg" onClick={addDataMapping} />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows.length ? rows.map((item, index) => {
                                return (
                                    <tr key={get(item, 'sourceField.label', '')}>
                                        <td>{get(item, 'sourceField.label', '')}</td>
                                        <td>{get(item, 'transform.label', '')}</td>
                                        <td>{get(item, 'target', '')}</td>
                                        <td><img className='add-icon' src="./Icons/DeleteRed.svg" onClick={() => dispatch(removeItem(index))} /></td>
                                    </tr>
                                )
                            }) : null
                            }
                        </tbody>
                    </Table>
                    <div className='row'>
                        <div className='col-md-5'></div>
                        <div className="col-md-4 my-10">
                            <Button variant="primary" className='large-border-radius'
                                onClick={saveDataMapping}
                                disabled={saveDataLoading || dataMappingIndex.length === 0}
                            >
                                {saveDataLoading ?
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    /> : null}
                            Save
                         </Button>
                        </div>
                    </div>
                </div>}
        </div>
    )
}

DataMappingTab.propTypes = {
    similarIssuesSelect: PropTypes.object
};

DataMappingTab.defaultProps = {
    selfTicketingSystemConfig: {
        elementType: 'select',
        elementConfig: {
            options: [
                { value: null, displayValue: '' },
                { value: 'servicenow', displayValue: 'Service Now' },
                { value: 'others', displayValue: 'Others' },
            ]
        },
        value: '',
        validation: {
            required: true
        },
        valid: false,
        touched: false,
        className: 'selectLang'
    }
};

export default DataMappingTab;
