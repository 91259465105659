import clsx from 'clsx';
import './heading3.css';

const Heading3 = ({
    text,
    users,
    className,
}) => {
return <h3 className={clsx(className, 'heading3')}><span className={className.includes("count-text-color") ? "text" : ""}>{text}</span>{users}</h3>
};

export default Heading3;