/*

* Copyright © 2020, HCL Technologies Ltd. ALL RIGHTS RESERVED.

* <p>

* This software is the confidential information of HCL Technologies Ltd., and is licensed

* as restricted rights software. The use,reproduction, or disclosure of this software

* is subject to restrictions set forth in your license agreement with HCL.

*/

 

/**

* Manage multimedia data.

*

* @author Abhash Vats

*/


import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { connect } from "react-redux";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import './manageMultimedia.css';
import get from 'lodash/get';
import * as actions from '../../store/actions/index';
import { GetCalltoServer, PostCalltoServer, botPostCalltoServer } from '../../store/utility';
import { SetErrorMessage, SetSuccessMessage } from '../../store/actions';
import { useDispatch, useSelector, } from 'react-redux';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { makeStyles,withStyles } from '@material-ui/core';
import Dropzone from "./dropzone/Dropzone";
import withErrorHandler from "../../hoc/withErrorHandler/withErrorHandler";
import {  unset } from 'lodash';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import axios from "../../instance";
import TextField from '@material-ui/core/TextField';

import SpinnerPage from '../../components/UI/Loader/Loader';
import moment from 'moment';
import SharePointPage from "./SharePointPage";
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';

import orderBy from 'lodash/orderBy';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import SearchComponent from "../../components/UIComponents/SearchComponent/Search";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Col, Row } from 'react-bootstrap';
import classes from './ProjectViewPage.module.css';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import DrawerComponent from '../../components/UIComponents/Drawer/DrawerComponent';
import InputWithLabelComponent from '../../containers/SettingPage/SettingInnerPages/components/InputWithLabelComponent/InputWithLabelComponent';
import ButtonComponent from "../../components/UIComponents/Button/ButtonComponent";
import BadgeComponent from '../../components/UIComponents/Badge/BadgeComponent';
import ImportFiles from './ImportFiles';
import Checkbox from '@material-ui/core/Checkbox';
import DeleteIcon from '@material-ui/icons/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import {decrypt} from '../../utility/utility';

const clss = withStyles((theme)=>({
    customStyle: {
        '& div': {
           left:'unset !important',
           right:'110px !important',
        }
    },
    style:{
        '& .MuiPaper-rounded':{
            borderRadius:"30px",
        }
    },
}));

var idfordelete = ''

const invertDirection = {
    asc:'desc',
    desc:'asc',
}
// const dispatch = useDispatch();
//const clss = useStyles()
class ManageMultimediaData extends Component {
    
    state={
        nonSearchableData:[],
        allRows:[],
        filterType:"local",
        prevFilterType:"local",
        fields:['File Name','Size','Modified Date & Time','DESCRIPTION', 'keywords','TRAINING'],
        sortDirection:'desc',
        searchValue:'',
        showFiles:true,
        show:true,
        menuShow:null,
        deletePopup:false,
        deleteMultiplePopup:false,
        columnToSort:'',
        editPopup:false,
        numSelected:0,
        rowCount:0,
        checked:[],
        editRow:{},
        badges:[],
        tag:"",
        descriptionVal:"",
        saveSetting:true,
        loading:false,
        errmsg:false,
        language :localStorage.getItem('language'),
        selectedLang:'',
    }

    componentDidUpdate(prevprops){
        if(this.props.loading != prevprops.loading){
                this.getLatestDataForNotSearchableFiles();                            
                this.setState({editPopup:false,deletePopup:false,deleteMultiplePopup:false, menuShow:null,loading:false}) 
        }
        if(this.props.edited != prevprops.edited){
            setTimeout(() => {
                this.getLatestDataForNotSearchableFiles();                            
                this.setState({editPopup:false, menuShow:null}) 
            }, 1000);
            
        }
        if(this.state.filterType != this.state.prevFilterType){
            this.getLatestDataForNotSearchableFiles()
            this.setState({prevFilterType:this.state.filterType})
        }
    //    this.state.language == 'af' ? this.setState({SelectedLang:'Afrikaans'}): 
    //    this.state.language == 'ar' ? this.setState({SelectedLang:'Arabic'}):
    //    this.state.language == 'de' ? this.setState({SelectedLang:'German'}):
    //    this.state.language == 'el' ? this.setState({SelectedLang:'Greek'}):
    //    this.state.language == 'es' ? this.setState({SelectedLang:'Spanish'}):
    //    this.state.language == 'fr' ? this.setState({SelectedLang:'French'}):
    //    this.state.language == 'it' ? this.setState({SelectedLang:'Italian'}):
    //    this.state.language == 'hu' ? this.setState({SelectedLang:'Hungarian'}):
    //    this.state.language == 'zh' ? this.setState({SelectedLang:'Chinese'}):
    //    this.state.language == 'ru' ? this.setState({SelectedLang:'Russian'}):
    //    this.state.language == 'el' ? this.setState({SelectedLang:'Greek'}):
    //    this.state.language == 'ja' ? this.setState({SelectedLang:'Japanese'}):
    //    this.state.language == 'pl' ? this.setState({SelectedLang:'Polish'}):
    //    this.state.language == 'hi' ? this.setState({SelectedLang:'Hindi'}):this.setState({SelectedLang:'English'})
        
    }

    bytesToSize(bytes) {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes == 0) return '0 Byte';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
     }

    utcToLocalTime(utcdateStr){
         try{
            var local_date= moment.utc(utcdateStr ).local().format('YYYY-MM-DD HH:mm:ss');
            const formatDate = moment(local_date).format('MMMM Do YYYY, h:mm:ss A z')
            return formatDate;
         }catch(err){console.log(err)}
     }

    ChangeFilterType = (event, newValue) =>{
        this.setState({prevFilterType:this.state.filterType, filterType:newValue})
    }

    getLatestDataForNotSearchableFiles = () => {
        
        const configObj = {
            "index_es": "cognitivecollection_intelliservices.onmicrosoft",
            "id_es": "01CZCBNYLUR33SPTSBBBAYHM7DNNFWM36C",
            "organisation": localStorage.getItem("orgName"),
            "project": localStorage.getItem("projectname"),
            "documentType":"local"
        }
        //SetSearchableFilesLoading(true);
       let url = this.props.config.CS_GOLANG + `/ctrainer/train/fullstats?project=${localStorage.getItem("projectname")}` +`&` + `source=` + this.state.filterType +`&` + `multimedia=only`;
    // let url = this.props.config.CS_GOLANG + `/ctrainer/train/fullstats?multimedia=only`;
       GetCalltoServer(url, configObj)
            .then(response => {
				
				if (get(response, 'data.status', '') === "success") {                    
                    let resp = response.data.data
                    let respArr=[];
                    let check=[];
                    for(var r in resp){
                        let respArray = resp[r];
                        let respObj={};
                        if(respArray == null || respArray.length == 0)
                            return ;
                        respObj["fileName"]=respArray[0].filename
                        respObj["lastUpdateDate"]=this.utcToLocalTime(respArray[0].lastmodified)
                        respObj["size"]=this.bytesToSize(respArray[0].size);
                        respObj["status"]=respArray[0].moreinfo.Status;
                        respObj["description"]=respArray[0].moreinfo.description;
                        respObj["tags"]=respArray[0].moreinfo.tags;
                        respObj["keywords"]=respArray[0].moreinfo.keywords;
                        respArr.push(respObj);
                        check.push(false);
                    }
                    // /respArr.sort((a, b) => {
                    //     let da = new Date(a.lastUpdateDate),
                    //         db = new Date(b.lastUpdateDate);
                    //     return db - da;
                    // });
                    this.setState({nonSearchableData:respArr,rowCount:respArr.length,checked:check})
                }
                
                else {
                    this.setState({nonSearchableData:[]})
                    //SetSearchableFilesLoading(false);
                }
            }).catch(() => {
                this.setState({nonSearchableData:[]})
                //SetSearchableFilesLoading(false);
            });
    };

    componentDidMount() {
        this.getLatestDataForNotSearchableFiles();
    }

    handleSort = (columnName) =>{
        this.setState({columnToSort:columnName})
        if(this.state.columnToSort == columnName)
            this.setState({sortDirection:invertDirection[this.state.sortDirection]})
        else
            this.setState({sortDirection:'asc'})
    }

    onQuickFilterChanged = (event) => {
        this.setState({searchValue:event.target.value})
            if(event.target.value!="" ){
              this.setState({show:false})
            }
            else{
                this.setState({show:true})
            }
      };

      ClearSearchValue = ()=>{
        this.setState({searchValue:""})
        //setShow(true);
      }
 
      handleClickMenu = (event) => {
        this.setState({menuShow:event.currentTarget
        })
    };

    onRowClicked = (item) =>{
        this.setState({editRow:item})
    }
  
     handleCloseMenu = () => {
        this.setState({menuShow:null})
    };

    deleteConfirmation = ()=>{
        this.setState({deletePopup:true,menuItems:null})
    }

    
    deleteMultipleFiles = ()=>{
        this.setState({deleteMultiplePopup:true})

    }


    handleCloseWindow = (str) =>{
        if(str == "deleteConfirmationCancel"){
            this.setState({deletePopup:false})
        }
        if(str == "deletemultipleConfirmationCancel"){
            this.setState({deleteMultiplePopup:false})
        }
	}

    editRow=()=>{
        this.setState({editPopup:true,
            // badges:[this.state.editRow.tags], 
            descriptionVal:this.state.editRow.description,errmsg:false})

            let tag = this.state.editRow.tags != null ? this.state.editRow.tags.trim():null
            if(tag){
            this.setState({badges:tag.split(',')})}
    }

    closeeditPopup = () =>{
        this.setState({editPopup:false, tag:""})
        // handleTag('');
    }
    onSelectAllClick = (event) =>{
        var check = [...this.state.checked]
        if(event.target.checked == true){
            check.fill(true)
            this.setState({numSelected:this.state.rowCount,checked:check,allRows:this.state.nonSearchableData})
        }
        else{
            this.setState({numSelected:0,checked:check})
            check.fill(false)
            //setAllRows([])
        }

        // var check = this.state.checked
        // if(event.target.checked == true){
        //     check.fill(true)
        //     this.setState({numSelected:this.state.rowCount,checked:check})
        // }
        // else{
        //     this.setState({numSelected:0,checked:check})
        //     check.fill(false)
        // }
    }

    onSelectClick = (event,item,index) =>{


        let i = this.state.numSelected
        var check = [...this.state.checked]
        var row = [...this.state.allRows]
        if(this.state.sortDirection === 'asc'){
            row.sort((a, b) => {
                if (a.fileName < b.fileName) return -1;
                if (a.fileName > b.fileName) return 1;
              });}
              else{
                row.sort((a, b) => {
                    if (a.fileName > b.fileName) return -1;
                    if (a.fileName < b.fileName) return 1;
                  })
                  ;
              }
        if(event.target.checked == true){
            i++
            check[index]=true
            row.push(item)
            this.setState({allRows:row})
            //setAllRows(row)
        }
        else{
            i--
            check[index]=false
           /* var filtered = this.state.allRows.filter(function(value){ 
                return value != item;
            });*/
            this.setState({allRows:row})
            //setAllRows(filtered)
        }
        // setNumSelected(i)
        // setChecked(check)
        this.setState({numSelected:i,checked:check})
        // var i = this.state.numSelected
        // var check = this.state.checked
        
        // if(event.target.checked == true){
        //     i++
        //     check[index]=true
        // }
        // else{
        //     i--
        //     check[index]=false
        // }
        // this.setState({numSelected:i,checked:check})
    }

    badgeClick = (i) => {
        this.state.badges.splice(i, 1)
        this.setState({badges:[...this.state.badges]})
        if(this.state.descriptionVal != '' && this.state.badges.length>0){
            this.setState({saveSetting:false,errmsg:false})
        }
        else{
            this.setState({saveSetting:true,errmsg:false})
        }
        //setBadges([...badges])
    } 

    handleTag = (tagValue) =>{
        this.setState({tag:tagValue})
    }

    descriptionValChange = (descriptionValue) =>{
        this.setState({descriptionVal:descriptionValue})
        if(descriptionValue.trim() != '' && this.state.badges.length>0){
            this.setState({saveSetting:false})
        }
        else{
            this.setState({saveSetting:true})
        }
    }
    handleAddTag=()=>{
            if (this.state.tag.trim()) {
                let check_upper=this.state.badges.map(item => item.toUpperCase()).filter((value, index, self) => self.indexOf(value) === index);
                let check_lower=this.state.badges.map(item => item.toLowerCase()).filter((value, index, self) => self.indexOf(value) === index);
                let check_state=this.state.badges.includes(this.state.tag);
                let check_camelcase=this.state.badges.map(item=>item.charAt(0).toUpperCase() + item.slice(1).toLowerCase());
                if(check_upper.includes(this.state.tag) || check_lower.includes(this.state.tag) || check_state || check_camelcase.includes(this.state.tag))
                {this.setState({errmsg:true})}
                else{this.setState({errmsg:false})}

                this.state.badges.push(this.state.tag)
                const uniqueArray= this.state.badges.map(item => item.toUpperCase()).filter((value, index, self) => self.indexOf(value) === index);
                const originalArray=uniqueArray.map(item=>this.state.badges[uniqueArray.indexOf(item)])
                this.setState({badges:[...new Set(originalArray)], tag:""})
    }
        if(this.state.descriptionVal != '' && this.state.badges.length>0){
            this.setState({saveSetting:false})
        }
        else{
            this.setState({saveSetting:true})
        }
    }

    updateSingleRow = () =>{ 
        let projectname = localStorage.getItem("projectname");
            if (!projectname)
                projectname = "common";
            let fileList = []
            let fileArray = []
            let formData = new FormData();
            
                let formFieldsObj = {}
                formFieldsObj["fileName"] = this.state.editRow.fileName;
                formFieldsObj["tags"] = this.state.badges.join();
                formFieldsObj["keywords"] = this.state.editRow.keywords;
                formFieldsObj["description"] = this.state.editRow.description;
                formFieldsObj["ids"] = this.state.editRow.ids;
                formFieldsObj["isValidate"] = true;
                formFieldsObj["language"] = localStorage.getItem("language");
                formFieldsObj["lastUpdateDate"] = moment(new Date()).format('DD-MM-YYYY hh:mm:ss A z');
				fileList.push(this.state.editRow.fileName)
                formData.append('file', this.state.editRow.fileName)
                
                let formObj = {}
                formObj[this.state.editRow.fileName] = formFieldsObj;
                fileArray.push(formObj);
                
            
            formData.append('project', projectname);
            formData.append('tags', this.state.badges.join());
            formData.append('keywords', this.state.editRow.keywords);
            formData.append('description', this.state.descriptionVal);
            formData.append('language',this.state.selectedLang);

            let token = localStorage.getItem("accessToken");
            // let token = decrypt("newAccessToken")

            let url=this.props.config.CS_GOLANG + `/ctrainer/train/upload?overwrite=retrain`
            this.props.onEditMultimediaRow(url,formData)
    }

    deleteSelctedRow = () => {
        
        const configObj = {
            'project': localStorage.getItem("projectname"),                
            'file':this.state.editRow.fileName ,
            'source': this.state.filterType.toLowerCase() ,
            'language':this.state.selectedLang              
        }
        var delArray = [];
        delArray.push(configObj);
        let url = this.props.config.CS_GOLANG + `/ctrainer/delete`;
        this.props.onDeleteMultimediaRow(url,configObj)
        this.setState({editPopup:false,deletePopup:false, menuShow:null,loading:false})
    }
    deleteFiles = () =>{
        var data = []
        for(var i=0;i<this.state.checked.length;i++){
            if(this.state.checked[i]==true){
                data.push({"project":localStorage.getItem('projectname'), "file":this.state.allRows[i].fileName,"source":this.state.filterType})
            }
        }
        let url = this.props.config.BOTBUILDER_URL + `/cognitive/deleteMutlipleFiles`;
        this.props.onDeleteAllMultimediaRow(url,data)
    }
    render() {
        
        return (
            
                    <React.Fragment>
                        <Col md={12}>
                            <Col md={3}>
                                <h3 className='otherDocMedia' style={{paddingLeft:"0px"}}>Multimedia</h3>
                            </Col>
                        </Col>
                        <Col md={12}>
                            <Col md={3} className="viewing_knowledge">
                            <span className='viewing-text'>Viewing</span>
                            <Autocomplete
                                value={this.state.filterType}
                                onChange={(event, newValue) => {
                                    this.ChangeFilterType(event, newValue)
                                }}
                                id="controllable-states-demo"
                                className={classes.autoCompleteTag}
                                options={['local', 'sharepoint', 'Webcrawler']}
                                renderInput={(params) => <TextField {...params}
                                    InputProps={{ ...params.InputProps, disableUnderline: true }} />}
                            />
                            </Col>
                            <Col md={3} style={{position:"absolute",right:"0px",marginTop:"-35px"}}>
                                    <SearchComponent className="searchIcon"
                                        class={"input-group searchClass"}
                                        Clear={()=>this.ClearSearchValue()}
                                        show={this.state.show}
                                        value={this.state.searchValue}
                                        onSearch={(event) => this.onQuickFilterChanged(event)}
                                    />
                            </Col>
                        </Col>

                    {/* {this.props.otherDocsPermission.R &&} */}
                    {this.props.otherDocsPermission.R && this.state.nonSearchableData.length == 0 ?
                                <React.Fragment>
                                    <div className="noDataContent"> 
                                        <div className="d-flex justify-content-center">
                                            <div className="noDataText">Other Documents and Multimedia files are not added</div>
                                        </div>
                                        {this.props.otherDocsPermission.WR &&<div className="d-flex justify-content-center">
                                                <div className="addDoc" onClick={this.props.openOtherDocumentDrawer}>Add Other Document/Multimedia files</div>
                                        </div>}                                  
                                    </div>
                                </React.Fragment>:
                        <React.Fragment>
                            <Col md={12} className={classes.otherDocumentsDropdownDiv} style={{marginLeft:'15px',width:"97%"}}>
                                <h4 className='pt-1'><span style={{color:'#2A93DF'}}>{this.state.nonSearchableData && this.state.nonSearchableData.length} {this.state.nonSearchableData&& this.state.nonSearchableData.length==1 ?'file':"files"} </span> available. 
                                </h4>
                                <span style={{color:"rgb(42, 147, 223)", cursor:"pointer"}}>
                                    <span style={{display:this.state.numSelected>0?'inline':'none'}}><DeleteIcon onClick={()=>this.deleteMultipleFiles()}></DeleteIcon></span>
                                    <RefreshIcon onClick={()=>this.getLatestDataForNotSearchableFiles()}></RefreshIcon>
                                </span>
                                {/* <span style={{color:"rgb(42, 147, 223)", cursor:"pointer"}}><RefreshIcon onClick={()=>this.getLatestDataForNotSearchableFiles()}></RefreshIcon></span> */}
                            </Col>
                                <Col md={12}>
                                    <div
                                        id="myGrid"
                                        className="ag-theme-alpine pt-1"
                                    >
                                        <TableContainer className="tableContainer">
                                            <Table aria-label="simple table" stickyHeader >
                                                <TableHead className='multi-header'>
                                                    <TableRow >
                                                        <TableCell padding="checkbox" style={{backgroundColor:"#7D7D7D",color:"#ffffff"}}>
                                                            <Checkbox
                                                                indeterminate={this.state.numSelected > 0 && this.state.numSelected < this.state.rowCount}
                                                                checked={this.state.rowCount > 0 && this.state.numSelected === this.state.rowCount}
                                                                onClick={(event)=>this.onSelectAllClick(event)}
                                                                style ={{
                                                                    color: "#ffffff",
                                                                  }}
                                                            />
                                                        </TableCell>
                                                        {this.state.fields != undefined && this.state.fields !== null ? this.state.fields.map((headerName) => {
                                                            return (
                                                                <>
                                                                    <TableCell className="tableHeader" style={{textTransform:"uppercase",backgroundColor:"#7D7D7D",color:"#ffffff"}} onClick={() => this.handleSort(headerName)}>
                                                                        {headerName} {headerName == "File Name" ? this.state.sortDirection === 'asc' ? <ArrowUpwardIcon className="iconStyle"/> : <ArrowDownwardIcon className="iconStyle"/>:''}
                                                                    </TableCell>

                                                                </>
                                                            )
                                                        }) : null}
                                                        <TableCell className="tableHeader" style={{textTransform:"uppercase",backgroundColor:"#7D7D7D"}}></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody >
                                                    {this.state.nonSearchableData != undefined && this.state.nonSearchableData !== null ?
                                                        orderBy(this.state.nonSearchableData, ['fileName'], this.state.sortDirection).filter((item)=>{
                                                            // let fileType1 = item.fileName.split('.')
                                                            // let fileType=fileType1[1]
                                                            return true
                                                        }).filter((value) =>{
                                                            return (value.fileName != null && value.fileName.toLowerCase().includes(this.state.searchValue.toLowerCase())) ||
                                                                (value.size != null && value.size.toLowerCase().includes(this.state.searchValue.toLowerCase())) ||
                                                                (value.description != null && value.description.toLowerCase().includes(this.state.searchValue.toLowerCase())) ||
                                                                (value.tags != null && value.tags.toLowerCase().includes(this.state.searchValue.toLowerCase())) ||
                                                                (value.lastUpdateDate != null && value.lastUpdateDate.toLowerCase().includes(this.state.searchValue.toLowerCase()))
                                                        }).map((item,index) => {
                                                            return (
                                                                <>
                                                                    <TableRow key={item.id} >
                                                                        <TableCell padding="checkbox">
                                                                            <Checkbox
                                                                                checked={this.state.checked[index]}
                                                                                onClick={(event)=>this.onSelectClick(event,item,index)}
                                                                                style ={{
                                                                                    color: "rgb(42, 147, 223)",
                                                                                  }}
                                                                            />
                                                                        </TableCell>
                                                                    {/* <TooltipCustom tooltext={item.fileName} direction="top"> */}
                                                                    <LightTooltip arrow title={item.fileName} placement="top-start">
                                                                            <TableCell className="tableCell">
                                                                               {item.fileName.length > 30 ? item.fileName.slice(0, 30) + '...' : item.fileName}
                                                                            </TableCell>
                                                                    </LightTooltip>
                                                                            {/* </TooltipCustom> */}
                                                                    <LightTooltip arrow title={item.size} placement="top-start">
                                                                        <TableCell className="tableCell">
                                                                            {item.size}
                                                                        </TableCell>
                                                                    </LightTooltip> 
                                                                    <LightTooltip arrow title={item.lastUpdateDate} placement="top-start">                                                                      
                                                                        <TableCell className="tableCell">
                                                                            {item.lastUpdateDate}
                                                                        </TableCell>
                                                                    </LightTooltip>
                                                                    <LightTooltip arrow title={item.description} placement="top-start">
                                                                            <TableCell className="tableCell">
                                                                                {item.description != null && item.description.slice(0, 100)}
                                                                            </TableCell>
                                                                    </LightTooltip>
                                                                    {/* <LightTooltip arrow title={item.tags} placement="top-start">
                                                                        <TableCell className="tableCell">
                                                                        {item.tags != null && item.tags.slice(0, 100)}
                                                                        </TableCell>
                                                                    </LightTooltip> */}
                                                                    <LightTooltip arrow title={item.tags} placement="top-start">
                                                                        <TableCell className="tableCell">
                                                                        {/* {item.keywords != null && item.keywords.slice(0, 100)} */}
                                                                        {item.tags != null && item.tags.slice(0, 100)}
                                                                        </TableCell>
                                                                    </LightTooltip>
                                                                    <LightTooltip arrow title={item.status} placement="top-start">
                                                                        <TableCell className="tableCell">
                                                                            {item.status}
                                                                        </TableCell>
                                                                    </LightTooltip>
                                                                        <TableCell onClick={() => this.onRowClicked(item)} className="tableCell">
                                                                            <img src='./Icons/OptionsIcon.svg'  style={{ position: 'relative', cursor: 'pointer', padding: '14px' }}
                                                                                aria-controls="simple-menu" aria-haspopup="true" onClick={(e)=>this.handleClickMenu(e)} />
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </>
                                                            )
                                                        }) : null}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <Menu
                                            id="multimediaDocMenu"
                                            anchorEl={this.state.menuShow}
                                            keepMounted
                                            open={Boolean(this.state.menuShow)}
                                            onClose={this.handleCloseMenu}
                                        >
                                            <MenuItem className={clss.menuItems} onClick={(event) => this.editRow(event)}>EDIT</MenuItem>
                                            <MenuItem  className="menuItems" onClick={(event) => this.deleteConfirmation(event)}>DELETE</MenuItem>
                                        </Menu>
                                        
                                    </div>
                                </Col>
                        </React.Fragment> }
                        <React.Fragment>
               <DrawerComponent heading="Documents Details"
                    showDrawer={()=>this.closeeditPopup()}
                    openDrawer={this.state.editPopup}>
                    <div className='drawer'>
                        <div className='drawer-body mt-2'>
                        <form>
                                <InputWithLabelComponent
                                    placeHolder=''
                                    className={'drawer-input'}
                                    type="text"
                                    name="name"
                                    label="File Name*"
                                    value={this.state.editRow.fileName}
                                    disabled={true}
                                />
                                <div className='mt-3'>
                                  <InputWithLabelComponent
                                      placeHolder=''
                                      className={'drawer-input'}
                                      type="text"
                                      name="name"
                                      label="Description*"
                                      value={this.state.descriptionVal}
                                      onChange={(e)=>this.descriptionValChange(e.target.value)}
                                      required={true}
                                      isInvalid={this.state.descriptionVal==''?true:false}
                                      error={"Description is required"}
                                  />
                               </div>
                               <div className='mt-3'>
                                    
                                    <div className='tags-input-div'>
                                        <div style={{width:"100%"}}>
                                            <InputWithLabelComponent
                                                placeHolder=''
                                                className={'drawer-input'}
                                                type="text"
                                                name="tags"
                                                label={"Keywords*"}
                                                value={this.state.tag}
                                                onChange={(e) => this.handleTag(e.target.value)}
                                                isInvalid={this.state.badges.length==0?true:false}
                                                error={"Please add tag."}
                                                maxLength={'50'}
                                            />
                                        </div>
                                        {this.state.errmsg === true ? <p style={{color:"red",fontSize:"12px"}}>Keyword Exists</p> :null}
                                        <ButtonComponent
                                            variant="contained"
                                            className="buttonWidth addTagBtn"
                                            label={"ADD"}
                                            clicked={() => this.handleAddTag()}
                                            btnType="blueButton"
                                            disabled={this.state.badges.includes(this.state.tag) || this.state.tag.trim().startsWith(" ") || this.state.tag.trim()=== ""}

                                        />            
                                    </div>
                                    
                                    <div className='my-3 w-400'>
                                        {
                                        this.state.badges.map((badge, i) => <BadgeComponent key={i} label={badge} clicked={() => this.badgeClick(i)} />)
                                        }
                                    </div>
                                       </div>
    
                                <div className="form-group submit-btn-parent mt-3">
                                    <ButtonComponent 
                                    clicked={this.updateSingleRow} 
                                    disabled={this.state.saveSetting}  
                                    label={"SAVE"}
                                    variant="contained"
                                    className="buttonWidth"
                                    />
                                        
                                </div>
                            </form>
                        </div>
                    </div>      </DrawerComponent>
           
            </React.Fragment>

            <Dialog open={this.state.deletePopup} onClose='' aria-labelledby="form-dialog-title" >
                    <form style={{width:"600px"}} className="deleteMultimediaForm">
                            <DialogTitle>Delete Confirmation <CloseIcon
                            className="d-flex closeDelMultimedia"
                            onClick={() => this.handleCloseWindow('deleteConfirmationCancel')
                            }
                            style={{ fontSize: 25 }}
                        /></DialogTitle>
                            <DialogContentText>Are you sure you want to delete the Multimedia document?</DialogContentText>       
                            <DialogActions>
                                <div className="form-group submit-btn-parent mt-3">
                                    <ButtonComponent 
                                        clicked={() => this.handleCloseWindow('deleteConfirmationCancel')} 
                                        label={"NO"}
                                        variant="outlined"
                                        className="buttonWidth"
                                        />  
                                    <ButtonComponent 
                                    clicked={(event)=>this.deleteSelctedRow(event)} 
                                    label={"YES"}
                                    variant="contained"
                                    className="buttonWidth ml-2"
                                    />
                                    
                                </div>
                            </DialogActions>
                    </form>
            </Dialog>
            <Dialog open={this.state.deleteMultiplePopup} onClose='' aria-labelledby="form-dialog-title" >
            <form style={{width:"600px"}} className="deleteMultimediaForm">
                    <DialogTitle>Delete Confirmation<CloseIcon
                     className="d-flex closeDelMultimedia"
                     onClick={() => this.handleCloseWindow('deletemultipleConfirmationCancel')
                    }
                     style={{ fontSize: 25 }}
                  /></DialogTitle>
                    <DialogContentText>Are you sure you want to delete the Multimedia documents?</DialogContentText>       
                    <DialogActions>
                         <div className="form-group submit-btn-parent mt-3">
                            <ButtonComponent 
                                clicked={() => this.handleCloseWindow('deletemultipleConfirmationCancel')} 
                                label={"NO"}
                                variant="outlined"
                                className="buttonWidth"
                                />  
                            <ButtonComponent 
                            clicked={()=>this.deleteFiles()} 
                            label={"YES"}
                            variant="contained"
                            className="buttonWidth ml-2"
                            />
                              
                        </div>
                    </DialogActions>
            </form>
            </Dialog>

                </React.Fragment>
            
          );
    }
}
const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 12,
      margin:-8 -2,
    },
  }))(Tooltip);
  const mapStateToProps = (state) => {
    return {
        loading:state.cognitive.loading,
        edited:state.cognitive.edited
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
        onDeleteMultimediaRow: (url,configObj) => dispatch(actions.deleteMultimediaRow(url,configObj)),
        onEditMultimediaRow:(url,formData) => dispatch(actions.editMultimediaRow(url,formData)) ,
        onDeleteAllMultimediaRow: (url,configObj) => dispatch(actions.deleteAllMultimediaRow(url,configObj)),
    };
  };
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(withErrorHandler(ManageMultimediaData, axios));
//export default ManageMultimediaData;