import React from 'react';
import Form from 'react-bootstrap/Form';
import './label-value.css';

const LabelValueComponent = ({
    labelName,
    value
}) => {
    return (
        <div className={`inputContainer whiteBg`}>
            <Form.Label>
                {labelName}
            </Form.Label>
            <Form.Control 
                type="text"
                name="defaultValue"
                defaultValue={value}
                readOnly
            />
        </div>
    )
};

export default LabelValueComponent;