// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CircleProgressBar_label__c83Z2 {
    transform: translateY(0.25em);
}

.CircleProgressBar_percentage__i2k0\\+ {
    font-size: 0.3em;
    line-height: 0.1;
    text-anchor: middle;
    transform: translateY(-0.25em);
}

.CircleProgressBar_boxSize__n8ja6{
height: 350px; 
width: 87%;
}`, "",{"version":3,"sources":["webpack://./src/components/CircleProgressBar/CircleProgress/CircleProgressBar.module.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;AACjC;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;AACA,aAAa;AACb,UAAU;AACV","sourcesContent":[".label {\n    transform: translateY(0.25em);\n}\n\n.percentage {\n    font-size: 0.3em;\n    line-height: 0.1;\n    text-anchor: middle;\n    transform: translateY(-0.25em);\n}\n\n.boxSize{\nheight: 350px; \nwidth: 87%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `CircleProgressBar_label__c83Z2`,
	"percentage": `CircleProgressBar_percentage__i2k0+`,
	"boxSize": `CircleProgressBar_boxSize__n8ja6`
};
export default ___CSS_LOADER_EXPORT___;
