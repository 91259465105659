import React, { useEffect, useState } from "react";
import get from "lodash/get";
import MasterCard from "./MasterCard";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import "./manage-master-card.css";
import { useHistory } from "react-router-dom";
import {
  GetCalltoServer,
  PostCalltoServer,
} from "../../../../../../../store/utility";
import { triageConfigurationupdateHandlerAction } from "../../../../../../../store/actions/adminSettings/adminSettingsActions";
import getRunModalBodyData from "./helpers/getRunModalBodyData";
import PrimaryButtonWithLoader from "../../../../components/PrimaryButtonWithLoader/PrimaryButtonWithLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import getProjectDetails from "../../../../helpers/getProjectDetails";
import getProductAttributesData from "./helpers/getProductAttributesData";
import permissionsJson from "../../../../../../../permissions_Json"

const ManageMasterCard = (props) => {
  const data = useSelector(
    (state) => state.adminSettings.triageConfigurationData.triageData
  );
  const ProjectView = useSelector(state => state.projectview.IsProjectChange);
  const permissions = useSelector(state => {
    return state.user.permissionList;
  });
  let history = useHistory();
  const dispatch = useDispatch();
  const [saveLoading, setSaveLoading] = useState(false);
  const [trainLoading, setTrainLoading] = useState(false);
  const [AttrErrMsg, setAttrErrMsg] = useState('');
  const [sourceData, setSourceData] = useState([]);
  const [sourceField, setSourceField] = useState('');
  let  ProductTriage_Read_Write = false;
  let ProductTriage_RW_Status = permissions.find(data => {
    if (data.permission_code.includes(permissionsJson.permissionCode.PRODUCT_TRIAGE_ADMIN_RW ) && data.sku_code.includes(permissionsJson.skuCode.PT) ||
     data.permission_code.includes( permissionsJson.permissionCode.PRODUCT_TRIAGE_TRAIN_RW))
      return true
  })
  ProductTriage_Read_Write = ProductTriage_RW_Status ? true : false;

  useEffect(() => {
    setAttrErrMsg("")
    
    const projectName = getProjectDetails();

    // let NewProjectName = ProjectView ?  ProjectView : projectName;    
    let NewProjectName = projectName ?  projectName : ProjectView;    

    const url = props.config.INSIGHTS_API_URL +
      `/product-intelligent-triage/settings/sourcemap/${NewProjectName}`;
    GetCalltoServer(url)
      .then((response) => {
        if (get(response, "data.status.responseType", "") === "success") {
          let data1 = get(response, "data.data", []);
          const data = data1.length?data1:getProductAttributesData()
          dispatch(triageConfigurationupdateHandlerAction(data));
        } else {
        }
      })
      .catch((err) => {});
     
  }, [ProjectView]);
  const saveHandler = () => {
    const projectName = getProjectDetails();
    // let NewProjectName = (ProjectView)?  ProjectView : projectName;
    let NewProjectName = (projectName)?  projectName : ProjectView;

      const url =
      props.config.INSIGHTS_API_URL +
      `/product-intelligent-triage/settings/sourcemap`;
    const productUpdateUrl =
      props.config.INSIGHTS_API_URL +
      `/product-intelligent-triage/settings/ProductdetailsData/${NewProjectName}`;
    const collectionUpdateUrl = 
       props.config.INSIGHTS_API_URL + 
       `/product-intelligent-triage/settings/sourcevaluemap`;
       
    let source_fields = 0
    data.forEach(function(item, index) {
      if(NewProjectName){
        item['project'] = NewProjectName
      }
      if(item['project']==null && (NewProjectName==null || NewProjectName ==undefined)){
        setAttrErrMsg("Please First Select Project")
      }else if (!('source_fields' in item) || item['source_fields']==null || item['source_fields'].length==0){
          setAttrErrMsg("Please Select each Attribute Value")
      }
      if(('source_fields' in item) && Array.isArray(item['source_fields']) && item['source_fields'].length!=0){
        source_fields++
      }
    })
    if(source_fields==3){
      setSaveLoading(true);
      setAttrErrMsg("")
      PostCalltoServer(url, data)
        .then((response) => {
          setSaveLoading(false);
          if (get(response, "data.status.responseType", "") === "success") {
            PostCalltoServer(collectionUpdateUrl, data.map((obj) => ({...obj,collection:props.sourceVal})))
            .then((response) => {
              setSaveLoading(false);
            })
            PostCalltoServer(productUpdateUrl, {}).then((response) => {
              setTimeout(function(){
                props.changeNextEvent();
              },500)
            });
            
          } else {
            setSaveLoading(false);
          }
        })
        .catch((err) => {
          setSaveLoading(false);
          toast(err, {
            type: "error",
            pauseOnHover: false,
          });
        });
    }
   
  };
  const runModalHandler = () => {
    const url =
      props.config.INSIGHTS_API_URL + "/product-intelligent-triage/bulkapi";
    const runModalBodyData = getRunModalBodyData(props.config);
    setTrainLoading(true);
    PostCalltoServer(url, runModalBodyData)
      .then((response) => {
        setTrainLoading(false);
        if (get(response, "data.status.responseType", "") === "success") {
          toast("Successfully Trained", {
            type: "success",
            pauseOnHover: false,
          });
        } else {
          setTrainLoading(false);
        }
      })
      .catch((err) => {
        setTrainLoading(false);
        toast(err, {
          type: "error",
          pauseOnHover: false,
        });
      });
  };
  var target = data.sort(function(a,b){

    if (a.source_name > b.source_name) return 1;
    return -1;
    });
    const index = [0, 2,1];

    target = index.map(i => target[i]);
    let selectedSource = props.selectedSource.split(" - ");
    let collectiondata =  {};
    collectiondata = props.selectedSource.slice(13).trim();
    let sourceVal = selectedSource[0]
    let sourceCole = selectedSource[1]
  return (
    <div>
      <div className="mt-1">
        {target.length
          ? target.map((item) => {
              return (
                <MasterCard
                  key={item.source_name}
                  selectedData={item}
                  config={props.config}
                  sourceData={sourceVal}
                  selectedSource={props.selectedSource}  
                  sourceVal={props.sourceVal}
                />
              );
            })
          : null}
        <div class="error error-msg">{AttrErrMsg}</div>
        <div className="d-flex mt-3 justify-content-end button-content mt-2">
          <PrimaryButtonWithLoader
          disabled={!ProductTriage_Read_Write}
            className="mr-3 save-proceed-btn"
            onClick={saveHandler}
            size="lg"
            text="SAVE & PROCEED"
            loading={saveLoading}
          />
          {/*<PrimaryButtonWithLoader
            className="mr-2"
            onClick={runModalHandler}
            size="lg"
            text="train"
            loading={trainLoading}
          />
          <PrimaryButtonWithLoader
            className="mr-2 trained-btn"
            onClick={() => history.push("/symptomsAndRootCause")}
            size="lg"
            text="View Trained Data"
          />*/}
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};
ManageMasterCard.propTypes = {
  SaveButton: PropTypes.string,
  ModalButton: PropTypes.string,
  DataButton: PropTypes.string,
};
ManageMasterCard.defaultProps = {
  SaveButton: "SAVE",
  ModalButton: "RUN A MODAL",
  DataButton: "DATA",
};
export default ManageMasterCard;
