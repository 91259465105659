import React, { useState, useEffect } from "react";
import Drawer from "@material-ui/core/Drawer";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import "./SymptomAndRootCause.css";
import { Col, Row, Form, Spinner } from "react-bootstrap";
import {
  GetCalltoServer,
  PostCalltoServer,
  deleteCalltoServer
} from "../../../../../../store/utility";
import get from "lodash/get";
import DrawerHeaderContent from "../../../components/DrawerHeaderContent/DrawerHeaderContent";
import { useDispatch, useSelector } from "react-redux";
import InputWithLabelComponent from "../../../components/InputWithLabelComponent/InputWithLabelComponent";
import isEmpty from "lodash/isEmpty";
import ProductAttributesComponent from "../ProductAttributesComponent/ProductAttributesComponent";
import fetchProductTriageDataAction from "../../../../../../store/actions/agentInsights/fetchProductTriageData/fetchProductTriageDataAction";
import PrimaryButtonWithLoader from "../../../components/PrimaryButtonWithLoader/PrimaryButtonWithLoader";
import getProjectDetails from "../../../helpers/getProjectDetails";
import { FormatColorReset } from "@material-ui/icons";
import isEqual from "lodash.isequal";
import AutoCompleteComponent from "../autoComplete/AutoComplete";
import SecondaryButtonWithLoader from "../../../components/SecondaryButtonWithLoader/SecondaryButtonWithLoader";
import symptomDetailsDeleteHandler from "../../../../../../store/actions/adminSettings/symptomDetailsDeleteHandler";
import { SetSuccessMessage } from "../../../../../../store/actions/message";


const useStyles = makeStyles({
  list: {
    width: "29rem",
    overflowY:"scrool",
  },
});

const EditSymptomsDetails = ({
  config,
  showDrawer,
  toggleDrawer,
  symtomsheading,
  savebutton,
  symtomtitle,
  associatedIssues,
  allOption,
  onRemoveItem,
  disabled,
  placeholder,
  label,
  data,
  triageQuestion,
  addSymptom,
  closeDrawer,
  advanceFilters,
  symptomsData,
  symptom,
  toastFun,
  getSymptoms,
  id,
  showValues,
  ...rest
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const projectName = getProjectDetails();
  const [productAttributesData, setProductAttributesData] = useState([]);
  const filterData = useSelector((state) => state.agentInsights.filterData);
  const productTriageDataLoading = useSelector(state => state.agentInsights.productTriageDataLoading);
  const [rootCauseOptions, setRootCauseOptions] = useState([]);
  const [seletctedRootcauseData, setSeletctedRootcauseData] = useState([]);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false)
  const [slectedValues, setSelectedValues] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [nonTrainData, setNonTrainData] = useState("");
  const [formData, setFormData] = useState({
    symptoms: "",
    leadingquestion: "",
    description: [],
    rootcauses: [],
    resolution: [],
    productattributes: []
  });
  const [associtaeDataShow, setAssocitaeDataShow] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  const [btnDisable, setBtnDisable] = useState(true);
  const [symptomError, setSymptomError] = useState(false);
  const [mandatory, setMandatory] = useState(false);
  const [associateIssueArr, setAssociateIssueArr] = useState([]);
  const associateIssueElements = (data) => {
    (data.description).map(item => {
        setAssociateIssueArr((s) => {
            return [
                ...s,
                {
                type: "text",
                value: item,
                },
            ];
            });
    })
}


  const addAssociateIssue = () => {
    setAssociateIssueArr((s) => {
      return [
        ...s,
        {
          type: "text",
          value: "",
        },
      ];
    });
  };

  const handleChange = (e) => {
    e.preventDefault();
    const index = e.target.id;
    setAssociateIssueArr((s) => {
      const newArr = s.slice();
      newArr[index].value = e.target.value;
      return newArr;
    });
  };

  const getOptions = (data) => {
    if (isEmpty(data)) {
      return [];
    }
    if(isEqual(data.attribute_data,null)){
      return [];
    }
    const attrData = get(data, "attribute_data", []);
    return attrData.map((option) => ({
      label: option,
      value: option,
    }));
  };

    const symptomValidation = (updatedFormData) => {
      if (isEqual(updatedFormData.symptoms, "")) return true;
      if (isEqual(updatedFormData.leadingquestion, "")) return true;
      if (isEqual(updatedFormData.rootcauses.length, 0)) return true;
      if ((updatedFormData.description == "" || updatedFormData.description == [""] )) return true;

      
      // if(isEqual(updatedFormData.seletctedRootcauseData.length, 0)) return true;
      // const attributeData =   updatedFormData.productattributes.map((item) => item.attribute_data);
      // let attributeString = attributeData.toString();
      // if (
      //   (updatedFormData.productattributes.map((item) => item.attribute_name ==="")) &&
      //     (attributeString === "")
      // )
      //   return true;
      // if (isEqual(updatedFormData.description.length, 0)) return true;
    //  if(updatedFormData.symptoms!= "" &&  updatedFormData.leadingquestion!= "" && seletctedRootcauseData.length!= 0){
    //     if (!isEqual(updatedFormData.rootcauses), ((seletctedRootcauseData.length == 0))) return true;
    //   }else{
    //     return true
    //   }
      }

  const saveHandler = (e) => {
    const descriptionArr = associateIssueArr.map((item) => item.value);
    const productattributes = getProductAttributes(productAttributesData);
    const rootCauseOptions = get(formData, "rootcauses", []);
    const newFormData = {
      symptoms: get(formData, "symptoms", ""),
      leadingquestion: get(formData, "leadingquestion", ""),
      resolution: get(formData, "resolution", []),
      description: descriptionArr,
      rootcauses: get(formData, "rootcauses", []),
      project: projectName,
    };
    const updatedFormData = {
      ...newFormData,
      productattributes,
    };
    if (!symptomValidation(updatedFormData)) {
        const deleteObject = {
            symptoms : symptom.symptoms,
            project  : projectName
         }
         setSaveLoading(true);
         const url =
         config.INSIGHTS_API_URL +
         `/product-intelligent-triage/settings/rootaggs`;
         deleteCalltoServer(url, deleteObject)
         .then((response) => {
           if (get(response, "data.status.responseType", "") === "success") {
            const url =
            config.INSIGHTS_API_URL +
            `/product-intelligent-triage/settings/addSymptoms`;
          PostCalltoServer(url, updatedFormData)
            .then((response) => {
              if (get(response, "data.status.responseType", "") === "success") {
                const symptomsData = {
                  ...productAttributesData,
                  symptoms: get(formData, "symptoms", ""),
                  leadingquestion: get(formData, "leadingquestion", ""),
                  description: get(formData, "description", []),
                };
                // addSymptom(updatedFormData);
                getSymptoms();
                setSaveLoading(false);
                closeDrawer();
                dispatch(SetSuccessMessage('Symptom updated successfully'));
              } else {
                setSaveLoading(false);
              }
              })
              .catch((err) => {
                  console.log("err", err)
              })
           }
        })
        .catch((err) => {
            console.log(err, "error Found");
        })
    }else setMandatory(symptomValidation(updatedFormData));
  };

  const getRootCauseOptions = (data) => {
    return data.map((item) => ({
      label: get(item, "rootcauseTitle", ""),
      value: get(item, "rootcause", ""),
    }));
  };

  useEffect(() => {},[dataLoading]);

  useEffect(() => {
    associateRootOption();
    const url =
      config.INSIGHTS_API_URL +
      `/product-intelligent-triage/settings/getSymptomsdetails/${symptom.symptoms}/project/${projectName}`;
    GetCalltoServer(url)
      .then((response) => {
        if (get(response, "data.status.responseType", "") === "success") {
          setDataLoading(true);
          const data = JSON.parse(get(response, "data.data", []));
          associateIssueElements(data[0]);
        setFormData({
          ...data[0],
        })
          getSelectedProductAttributes(data[0].productattributes);
          // setRootCauseOptions(getRootCauseOptions(data[0].rootcauses));
          setSeletctedRootcauseData([...formData.rootcauses]);
          setDataLoading(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  const associateRootOption = () => {
    setDataLoading(false);
    dispatch(fetchProductTriageDataAction(config));
    setProductAttributesData([]);
    const url =
      config.INSIGHTS_API_URL +
      `/product-intelligent-triage/settings/getRootCausedetailsSet/project/${projectName}`;
    PostCalltoServer(url, advanceFilters)
      .then((response) => {
        if (get(response, "data.status.responseType", "") === "success") {
          const data = JSON.parse(get(response, "data.data", []));
          setDataLoading(true);
          setRootCauseOptions(getRootCauseOptions(data));
        } else {
          setRootCauseOptions([]);
        }
      })
      .catch((err) => {
        setRootCauseOptions([]);
      });
  }

  const onChangeHandler = (event, type) => {
    setFormData((formData) => ({
      ...formData,
      [type]: event.target.value,
    }));
    setMandatory(false);
  };

  const onRemoveItemHandler = (name) => (selectedItem) => {
    if (name === "rootcauses") {
      const selectedRootCauses = get(formData, "rootcauses", []);
      const filteredRootCauses = selectedRootCauses.filter(
        (item) => item.rootcauseTitle !== selectedItem
      );
      setSeletctedRootcauseData(filteredRootCauses);
      setFormData((formData) => ({
        ...formData,
        ["rootcauses"]: selectedRootCauses.filter((item) =>  item.rootcauseTitle !== selectedItem),
      }));
    } else {
      const selectedProduct = get(productAttributesData, name, []);
      const filteredProducts = selectedProduct.filter(
        (item) => item !== selectedItem
      );
      setProductAttributesData((productAttributesData) => ({
        ...productAttributesData,
        [name]: filteredProducts,
      }));
    }
  };
  const removeAssociatedIssues = (index) => {
    const list = [...associateIssueArr];
    list.splice(index, 1);
    setAssociateIssueArr(list);
  };

  useEffect(() => {}, [seletctedRootcauseData]);
  useEffect(() => {}, [formData]);

  const onDropdownChangeHandler = (selectedItems, name) => {
    if (name === "rootcauses") {
      const rootcauseValues= selectedItems.map(obj=> ({rootcause:obj.label,rootcauseTitle:obj.label}))
      setSeletctedRootcauseData(rootcauseValues);
      setFormData((formData) => ({
        ...formData,
        [name]:selectedItems.map(obj=> ({rootcause:obj.label,rootcauseTitle:obj.label})),
      }));
    } else {
      setProductAttributesData((productAttributesData) => ({
        ...productAttributesData,
        [name]: selectedItems.map((item) => item.value),
      }));
    }
    setMandatory(false);
  };

  const getProductAttributes = (productData) =>
    Object.entries(productData).map(([key, value]) => {
      return {
        attribute_name: key,
        attribute_data: value,
      };
    });

    const getSelectedProductAttributes = (productData) =>
    productData.map((item, i) => {
      setProductAttributesData((productAttributesData) => ({
        ...productAttributesData,
        [item.attribute_name]: item.attribute_data,
      }));
    });

    const deleteHandlerConfirmation = () => {
      setDeleteConfirmation(!deleteConfirmation);
    }

    const deleteHandler = () => {
      setDeleteLoading(true);
      const url = `${config.INSIGHTS_API_URL}/product-intelligent-triage/settings/rootaggs`;
      const deletedSymtom = {
        symptoms: formData.symptoms,
        project: projectName
      };
      deleteCalltoServer(url, deletedSymtom)
        .then((response) => {
          if (get(response, "data.status.responseType", "") === "success") {
            dispatch(
              symptomDetailsDeleteHandler({
                formData,
                id,
              })
            );
            setDeleteLoading(false);
            // getSymptoms();
            closeDrawer();
            dispatch(SetSuccessMessage("Symptom Deleted Successfully"));

            // toastFun("symptom deleted Successfully", "success");
          } else {
            setDeleteLoading(false);
          }
        })
        .catch((error) => {
          setDeleteLoading(false);
          toastFun(error, "error");
        });
    };
    


  return (
    <React.Fragment>
      {showDrawer ? (
        <Drawer
          anchor={"right"}
          open={showDrawer}
          onClose={toggleDrawer(false)}
        >
          <div
            className={clsx(classes.list, classes.fullList)}
            role="presentation"
          >
            <DrawerHeaderContent
              heading={symtomsheading}
              toggleDrawer={toggleDrawer}
            />
            {dataLoading ? (
               <div className="d-flex flex-column Symtom-Item">
               <div className="mt-1 mb-2">
                       <Col md={11}>
                       <InputWithLabelComponent
                           type="text"
                           className=""
                           name="symptomTitle"
                           label={symtomtitle}
                           value={get(formData, "symptoms", "")}
                           readOnly={true}
                           helpIcon={false}
                       />
                       </Col>
                   </div>
                 <div className="mt-1 mb-2">
                   <Col md={11}>
                     <span className="adding-other-input pr-2">{associatedIssues}</span>
                     <img
                       className="addingInputBox pl-1"
                       onClick={addAssociateIssue}
                       src="./Icons/AddBlack.svg"
                     />
                    </Col>
                     {associtaeDataShow && associateIssueArr.slice(0,7).map((item, i) => {
                       return (
                         <div className="d-flex" key={i}>
                           <Col md={11}>
                           <input
                             className="mb-2 addAssociatebox"
                             placeholder={"Enter Text Here"}
                             onChange={handleChange}
                             value={item.value}
                             id={i}
                             type={item.type}
                             size="70"
                           />
                           </Col>
                           <Col md={1} className="pl-0 pt-2">
                           {isEqual(i, 0) ? null : (
                             <img
                               className="pl-1 addingInputBox"
                               name={item.value}
                               onClick={() => removeAssociatedIssues(i)}
                               src="./images/minus.svg"
                             />
                           )}
                            </Col>
                         </div>
                       );
                     })}

{!associtaeDataShow && associateIssueArr.map((item, i) => {
                       return (
                         <div className="d-flex" key={i}>
                           <Col md={11}>
                           <input
                             className="mb-2 addAssociatebox"
                             placeholder={"Enter Text Her"}
                             onChange={handleChange}
                             value={item.value}
                             id={i}
                             type={item.type}
                             size="70"
                           />
                           </Col>
                           <Col md={1} className="pl-0 pt-2">
                           {isEqual(i, 0) ? null : (
                             <img
                               className="pl-1 addingInputBox"
                               name={item.value}
                               onClick={() => removeAssociatedIssues(i)}
                               src="./images/minus.svg"
                             />
                           )}
                            </Col>
                         </div>
                       );
                     })}
                     <div className="pl-3">
                     {associateIssueArr.length > 7 && <button className="show-more-less-key p-2"
                      onClick={() => setAssocitaeDataShow(!associtaeDataShow)}>
                        {associtaeDataShow ? "Show More ..." : "... Show Less"}</button>}
                      </div>
                 </div>
                 <div className="mb-2">
                 <Col md={11}>
                     <span className="adding-other-input ">{triageQuestion}</span>
                     <InputWithLabelComponent
                       type="text"
                       className="mt-2"
                       name="symptomTitle"
                       showLabel={false}
                       value={formData.leadingquestion}
                       readOnly={false}
                       onChange={(e) => onChangeHandler(e, "leadingquestion")}
                     />
                   </Col>
                 </div>
                 <div className="mt-1">
                 {rootCauseOptions.length ? (
                  <Col md={12} className="pl-0 associate-symptom-alignment" >
                   <ProductAttributesComponent
                     options={rootCauseOptions}
                     name={"Associated Root Causes"}
                     label={"Associated Root Causes*"}
                     boldTitle={true}
                     search={false}
                     placeholder={"Select"}
                     className={"mt-1 mb-2"}
                     targetSourceDescription={"source_name"}
                     sourceHelpIcon={false}
                     attributeHelpIcon={false}
                     value={(formData.rootcauses).map(item => item.rootcauseTitle)}
                     onChange={(event) =>
                       onDropdownChangeHandler(event, "rootcauses")
                     }
                     onRemoveItem={onRemoveItemHandler("rootcauses")}
                   />
                   </Col>
                 ) : null}
                 </div>
                 {(filterData.length && filterData[0].attribute_data != null && !productTriageDataLoading)
                   ? filterData.map((item, i) => {
                       return (
                        <Col md={12} className="pl-0 associate-symptom-alignment" >
                         <ProductAttributesComponent
                           options={getOptions(item)}
                           name={item.attribute_name}
                           label={item.attribute_name}
                           boldTitle={true}
                           search={true}
                           placeholder={"Select"}
                           className={""}
                           targetSourceDescription={"source_name"}
                           sourceHelpIcon={false}
                           attributeHelpIcon={false}
                           value={get(
                             productAttributesData,
                             item.attribute_name,
                             ""
                           )}
                           onChange={(event) =>
                             onDropdownChangeHandler(event, item.attribute_name)
                           }
                           onRemoveItem={onRemoveItemHandler(item.attribute_name)}
                         />
                         </Col>
                       );
                     })
                   : null}
                   <div className="ml-4">
                   {mandatory ? (
                     <Form.Label className="text-box-error">
                       {"All fields are mandatory"}
                     </Form.Label>
                   ) : null}
                 </div>
               </div>
            ):(
              <Row className="vh-100 justify-content-center align-items-center">
                    <Spinner animation="border" variant="primary" />
                </Row> 
            ) }
           
              { !deleteConfirmation ? <div className="d-flex pt-3 flex-row button-content">
              <SecondaryButtonWithLoader
                className="ml-5"
                size="lg"
                text="delete"
                onClick={deleteHandlerConfirmation}
              />
              <PrimaryButtonWithLoader
                className="ml-4"
                onClick={saveHandler}
                size="lg"
                text="save"
                loading={saveLoading}
              />
            </div> : null }
            <div className="mb-3">
            { deleteConfirmation ? (<>
              <div className="ml-5">
              <Form.Label className="text-box-title adding-other-input">{"Are you sure you want to delete the symptom?"}</Form.Label>
              </div>
            <div className="d-flex flex-row button-content">
              <SecondaryButtonWithLoader
                className="ml-5"
                size="lg"
                text="No"
                onClick={deleteHandlerConfirmation}
              />
              <PrimaryButtonWithLoader
                className="ml-4"
                onClick={deleteHandler}
                size="lg"
                text="yes"
                loading={deleteLoading}
              />
            </div> </>) : null }
            </div>
          </div>
        </Drawer>
      ) : null}
    </React.Fragment>
  );
};

EditSymptomsDetails.propTypes = {
  symtomsheading: PropTypes.string,
  symtomtitle: PropTypes.string,
  associatedIssues: PropTypes.string,
  triageQuestion: PropTypes.string,
  savebutton: PropTypes.string,
  disabled: PropTypes.bool,
  showValues: PropTypes.bool,
};

EditSymptomsDetails.defaultProps = {
  symtomsheading: "Edit Symptoms",
  symtomtitle: "Symptom*",
  associatedIssues: "Associated Issues*",
  triageQuestion: "Triage Question*",
  savebutton: "SAVE",
  disabled: false,
  showValues: true
};

export default EditSymptomsDetails;
