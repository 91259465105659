import { Component } from 'react';
import { connect } from 'react-redux';
import './footer.css';
import SelectComponent from '../../../../components/UIComponents/SelectDropdown/SelectComponent';
import * as actions from "../../../../store/actions/index";
import InputWithLabelComponent from '../components/InputWithLabelComponent/InputWithLabelComponent';
import ColorPickerComponent from '../components/ColorPicker/ColorPickerComponent';
import ButtonComponent from '../../../../components/UIComponents/Button/ButtonComponent';
import { PostCalltoServer,botPostCalltoServerWithFormData } from '../../../../store/utility';
import DeleteModal from '../../../../components/UIComponents/DeleteModalComponent/DeleteModal';
import UserInput from './FooterComponent/UserInput';
import BackHome from './FooterComponent/BackHome';
import MenuOptions from './FooterComponent/MenuOptions';
import { decrypt } from '../../../../utility/utility';
import { defaultJSON } from './DefaultPTValues';
import SpinnerPage from '../../../../components/UI/Loader/Loader';

const initState = {
    resetPopUp: false,
    poweredByText: "Powered by HCL intelliService",
    poweredByTextCount: 45,
    poweredByTextColor: '#000000',
    userInput: defaultJSON.footer.userInput,
    backToHome: {
        isVisible: true,
        text:'Back To Home',
        textColor: '#2945FF',
        textFamily: 'Roboto',
        textStyle: 'Regular',
        textSize: '10px',
        flowQuery:''
    },
    menuOptions: {
        isMenuVisible: false,
        isBannerVisible: false,
        bgColor: '#FFFFFF',
        closeIconColor:'#FA2834',
        menuItems:[
            {text:'', routeType:'Flow', routeData:'', textColor:'#000000', textSize:'12px', subMenu:[]}
        ]
    },
    showSavePopup: false,
    saveSetting:false,
    resetSetting:false,
    dataLoading:false
}

class FooterPart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            components: [{ value: 'Back to Home', displayValue: 'Back to Home' },
            { value: 'Menu', displayValue: 'Menu Options' },
            { value: 'User Input', displayValue: 'User Input' },
            { value: 'Footer Text', displayValue: 'Footer Text' }
            ],
            flowLinks: [{ value: 'Flow', displayValue: 'Flow' },
            { value: 'URL', displayValue: 'URL' }
            ],
            projectLinks: [{ value: 'Intentlist', displayValue: 'Intentlist' },
            { value: 'unansweredbot', displayValue: 'unansweredbot' }
            ],
            selectedComponent: 'Menu',
            ...initState
        }
       
    }

    componentDidMount() {     
        this.loadHeaderJSon();
        this.props.updateFooterSelection(this.state.selectedComponent);
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.IsProjectChange !== this.props.IsProjectChange) {
            this.loadHeaderJSon()
            this.setState({
                ...initState
            });
        }
      }

    loadHeaderJSon = () => {
        const url = this.props.config.VIRTUAL_ASSISTANCE_URL+"/virtualAssistant/loadJSON";
        let data = {FolderName:localStorage.getItem('orgName')+'/Intelli'+localStorage.getItem('vaName')+'Bot'}
        this.setState({dataLoading:true})
        PostCalltoServer(url,data)
         .then((response) => {
            if(response=="error") return true;
            else{
                let virtualValues = JSON.parse(response.data)
                if(virtualValues.footer) {
                    let footer = virtualValues.footer
                    this.setState( {
                        userInput:footer.userInput,
                        poweredByTextColor:footer.poweredByTextColor,
                        poweredByText:footer.poweredByText,
                        backToHome:footer.backToHome,
                        menuOptions:footer.menuOptions
                    })
                    this.props.poweredByTextHandler({
                        text: this.state.poweredByText,
                        color: this.state.poweredByTextColor
                    })
                    this.props.userInputHandler({
                        ...this.state.userInput,
                    })
                    this.props.backToHomeHandler({
                        ...this.state.backToHome
                    })
                    this.props.menuOptionsHandler({
                        ...this.state.menuOptions
                    })
                }

            }
            this.setState({dataLoading:false})
         }).catch(() => {
            console.error('VA JSON load failed');
            this.setState({dataLoading:false})
         });
    }

    onSave = () => {
        this.setState({dataLoading:true})
        let data = {
            "userInput": this.state.userInput,
            "poweredByTextColor": this.state.poweredByTextColor,
            "poweredByText": this.state.poweredByText,
            "backToHome":this.state.backToHome,
            "menuOptions":this.state.menuOptions
        };

        let project = { "vaName":localStorage.getItem('vaName'),"project": (this.props.projectDetails).join(),'virtualLink':this.props.config.VIRTUAL_ASSISTANCE_URL,'deployLink':this.props.config.DEPLOYMENT_URL,'websocketurl': this.props.config.WEBSOCKET_URL,'botOrgsLink': this.props.config.BOT_ORGS ,'orgName':localStorage.getItem('orgName')}
        let otherdata = {"token":localStorage.getItem('accessToken'),"project":(this.props.projectDetails).join(),"language":localStorage.getItem('language'),"orgName":localStorage.getItem('orgName'),"vaName":localStorage.getItem('vaName')}

        const formData = new FormData();
        formData.append("data",JSON.stringify(data))
       
        PostCalltoServer(
            this.props.config.VIRTUAL_ASSISTANCE_URL+'/virtualAssistant/create-template', 
            project).then(response => {
                let responsee = response.data;
               
                formData.append("FolderName",responsee.FolderName)
                formData.append("other",JSON.stringify(otherdata))
              
                botPostCalltoServerWithFormData(this.props.config.VIRTUAL_ASSISTANCE_URL+'/virtualAssistant/footerLogs', formData)
                    .then(result => {
                        if(result.data.status=='Success'){
                            this.props.onSetSuccessMessage("Changes to the footer section have been updated.")
                           }
                           this.setState({"showSavePopup":false});
                           this.setState({saveSetting:false})
                           this.setState({dataLoading:false})
                    });
            });
    }

    onReset = () => {
        this.setState({
            ...initState,
            saveSetting:false,resetSetting:true
        })
        this.props.footerReset();
        this.props.onSetSuccessMessage("Footer section changes have been reset to default.")
    }

    setSelected = (tab) => {
        this.setState({ selected: tab });
    }

    onChangepoweredByText = (e) => {
        let value = e.target.value;
        this.setState({
            poweredByText: value,
            saveSetting:true,resetSetting:true
        })
        this.props.poweredByTextHandler({
            text: value,
            color: this.state.poweredByTextColor
        })
    }

    onChangepoweredByTextColor = (e) => {
        let value = e.target.value;
        this.setState({
            poweredByTextColor: value,
            saveSetting:true,resetSetting:true
        })
        this.props.poweredByTextHandler({
            text: this.state.poweredByText,
            color: value
        })
    }

    onSelectHandler = (event) => {
        this.setState({
            selectedComponent: event.target.value,
        })
        this.props.updateFooterSelection(event.target.value);
    }

    onUserInputChange = (type, value) => {
        this.setState({
            userInput: {
                ...this.state.userInput,
                [type]: value
            },
            saveSetting:true,resetSetting:true
        })
        this.props.userInputHandler({
            ...this.state.userInput,
            [type]: value
        })
    }

    onBackToHomeChange = (type, value) => {
        this.setState({
            backToHome: {
                ...this.state.backToHome,
                [type]:value
            },
            saveSetting:true,resetSetting:true
        })
        this.props.backToHomeHandler({
            ...this.state.backToHome,
            [type]:value
        })
    }

    onMenuOptionsChange = (type, value) => {
        this.setState({
            menuOptions: {
                ...this.state.menuOptions,
                [type]: value
            },
            saveSetting:true, resetSetting:true
        });
        this.props.menuOptionsHandler({
            ...this.state.menuOptions,
            [type]:value
        })
    }

    onColorChange = (color) => {
        this.setState({
            textColor: color,
            saveSetting:true,resetSetting:true
        })
    }

    hasRWPermission = () => {
        return this.props.permissions.RW?false:true;
    }

    render() {

        return (
            <div className="VirtualAssitantFooter">
                 {this.state.dataLoading ? (
          <div className="vh-100 row justify-content-center align-items-center"  style={{marginLeft:"50%"}}>
            <SpinnerPage animation="border" variant="primary" />
          </div>
        ):''}
                <div className='row mb-3'>
                    <div className='col-md-3'>
                        <SelectComponent
                            name="selectName"
                            label="Select the component"
                            changed={(event) => this.onSelectHandler(event)}
                            required={true}
                            value={this.state.selectedComponent}
                            options={this.state.components}
                        />
                    </div>
                </div>
                {this.state.selectedComponent == "Menu" && 
                    <MenuOptions data={this.state.menuOptions} onChangeHandler={this.onMenuOptionsChange}></MenuOptions>
                }
                {this.state.selectedComponent == "Footer Text" && <>
                    <div className='row mt-2'>
                        <div className='col-md-6 mb-3'>
                            <InputWithLabelComponent
                                placeHolder=""
                                type="text"
                                name="navigation-menu-text"
                                label="Footer Text Message"
                                value={this.state.poweredByText}
                                readOnly={false}
                                onChange={(e) => this.onChangepoweredByText(e)}
                                required={false}
                                // maxLength={45}
                            />
                            {/* <span className='mt-1 characters-count'>
                                {45 - this.state.poweredByText.length} Characters {this.state.poweredByText.length !== 0 && <span>Left</span>}</span> */}

                        </div>
                        <div className='col-md-6'>
                        </div>
                        <div className='col-md-2'>
                            <ColorPickerComponent
                                type="color"
                                name="response-text-color"
                                label="Footer Text Color"
                                labelContent="AB"
                                labelContentColor={this.state.poweredByTextColor}
                                value={"#FFFFFF"}
                                readOnly={true}
                                onChange={(e) => this.onChangepoweredByTextColor(e)}
                                required={false}
                            />
                        </div>
                    </div>
                </>}
                {this.state.selectedComponent == "Back to Home" && (
                    <BackHome data={this.state.backToHome} onChangeHandler={this.onBackToHomeChange}></BackHome>
                )}
                {this.state.selectedComponent == "User Input" && <>
                    <UserInput data={this.state.userInput} onChangeHandler={this.onUserInputChange}></UserInput>
                </>}
                <DeleteModal
                    show={this.state.resetPopUp}
                    deleteModal={() => this.setState({ resetPopUp: false })}
                    title="Reset Confirmation"
                    bodyTitle="Do you want to reset the footer section changes?"
                    buttonClick={this.onReset}
                />
                 <DeleteModal
                    show={this.state.showSavePopup}
                    deleteModal={() => this.setState({showSavePopup:false})}
                    title="Save Confirmation"
                    bodyTitle="Do you want to save the changes to the footer section?"
                    buttonClick={this.onSave}
                />
                <div className='row mt-4 pl-3 mb-4'>
                    <ButtonComponent
                        variant='outlined'
                        label='Reset'
                        className='buttonClass mr-3 reset-btn'
                        clicked={() => this.setState({ resetPopUp: true })}
                    />
                    <ButtonComponent
                        variant='contained'
                        label='Save'
                        className='buttonWidth'
                        clicked={()=>{ this.setState({"showSavePopup":true})}}
                        disabled={!this.state.resetSetting?true:this.hasRWPermission()}
                    />
                </div>
            </div>
        );

    }
}
const mapStateToProps = state => {
    return {
        botList: state.projectview.botList,
        IsProjectChange:state.projectview.IsProjectChange,
        permissions:state.user.moduleAccess.conversationalAdmin,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        OnGetBotList: (APIURL) => dispatch(actions.GetBotList(APIURL)),
        onSetSuccessMessage: (message) => dispatch(actions.SetSuccessMessage(message)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FooterPart);
