import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from "react-bootstrap";
import './DndSidebar.css';
import TabCustom from "../../../components/UI/HorizontalTab/HorizontalTab";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { GetCuxTemplates, DeleteCuxTemplate, ClearCuxTemplates, DeleteCuxBot } from '../../../store/actions';
import DeleteModal from "../../../components/UIComponents/DeleteModalComponent/DeleteModal";

const onDragStart = (event, nodeType, data) => {
  event.dataTransfer.setData('application/reactflow',JSON.stringify({nodeType:nodeType, data:data}));
  event.dataTransfer.effectAllowed = 'move';
};

const Sidebar = ({config}) => {

  const [selectedTab, setSelectedTab] = useState('Actions');
  const [selectedNode, setSelectedNode] = useState('http');
  const [deleteFlag, setDeleteFlag] = useState(false);
  const [selectedTemplateID, setSelectedTemplateID] = useState();
  const [selectedFlowId, setSelectedFlowId] = useState();

  const dispatch = useDispatch();

  const httpTemplates = useSelector(state => {
    return state.projectview.cuxHttpTemplates;
  });

  const formTemplates = useSelector(state => {
    return state.projectview.cuxFormTemplates;
  });

  const flowTemplates = useSelector(state => {
    return state.projectview.cuxFlowTemplates;
  });

  const scriptTemplates = useSelector(state => {
    return state.projectview.cuxScriptTemplates;
  });

  useEffect (()=>{
    switch(selectedNode) {
      case 'script': if(scriptTemplates?.length === 0 || scriptTemplates === null) {
        dispatch(GetCuxTemplates(config.COMMON_API_URL, selectedNode));
      } break;
      case 'http': if(httpTemplates?.length === 0 || httpTemplates === null) {
        dispatch(GetCuxTemplates(config.COMMON_API_URL, selectedNode));
      } break;
      case 'forms': if(formTemplates?.length === 0 || formTemplates === null) {
        dispatch(GetCuxTemplates(config.COMMON_API_URL, selectedNode));
      } break;
      case 'flow': if(flowTemplates?.length === 0 || flowTemplates === null) {
        dispatch(GetCuxTemplates(config.COMMON_API_URL, selectedNode));
      } break;
    }
    
  },[selectedNode]);

  useEffect(()=> {
    dispatch(ClearCuxTemplates());
  },[localStorage.getItem('projectname')]);

  const deleteTemplate = (item, flowid) => {
    setSelectedTemplateID(item);
    setDeleteFlag(true);
    setSelectedFlowId(flowid);
  }

  const confirmDelete = () => {
    dispatch(DeleteCuxTemplate(config.COMMON_API_URL, selectedTemplateID, selectedNode));
    if(selectedNode === 'flow') {
      dispatch(DeleteCuxBot(config.COMMON_API_URL, selectedFlowId, 'template'));
    }    
    setDeleteFlag(false);
  }

  return (
    <div id='sidebar-div'>
      <TabCustom
          tabs={["Actions", "Templates"]}
          selected={selectedTab}
          setSelected={(e)=> setSelectedTab(e)}
        >
          {selectedTab === 'Actions' && (
            <div  className='node-container'>
            <Row>
            <Col md={3} className='nodes-padding'>
              <div className="action-nodes boxSel-bg" onDragStart={(event) => onDragStart(event, 'boxSelector')} draggable>
                <img className="boxSel-img" src="./images/CDIcons/BoxSelector.svg"></img>
                <div className="boxSelector-span node-text">box selector</div>
              </div>
            </Col>
            <Col md={3} className='nodes-padding'>
              <div className="action-nodes text-bg" onDragStart={(event) => onDragStart(event, 'text')} draggable>
                <img className="action-img" src="./images/CDIcons/Text.svg"></img>
                <div className="start-span node-text">text</div>
              </div>
            </Col>
            <Col md={3} className='nodes-padding'>
              <div className="action-nodes textPr-bg" onDragStart={(event) => onDragStart(event, 'textPrompt')} draggable>
                <img className="textPrompt-img" src="./images/CDIcons/TextPrompt.svg"></img>
                <div className="textPrompt-span node-text">text prompt</div>
              </div>
            </Col>
            <Col md={3} className='nodes-padding'>
              <div className="action-nodes http-bg" onDragStart={(event) => onDragStart(event, 'http')} draggable>
                <img className="http-img" src="./images/CDIcons/Http.png"></img>
                <div className="http-span node-text">http</div>
              </div>
            </Col>
            <Col md={3} className='nodes-padding'>
              <div className="action-nodes express-bg" onDragStart={(event) => onDragStart(event, 'expression')} draggable>
                <img className="expression-img" src="./images/CDIcons/Expression.svg"></img>
                <div className="expression-span node-text">expr</div>
              </div>
            </Col>
            <Col md={3} className='nodes-padding'>
              <div className="action-nodes carousel-bg" onDragStart={(event) => onDragStart(event, 'carousel')} draggable>
                <img className="carousel-img" src="./images/CDIcons/Carousel.svg"></img>
                <div className="carousel-span node-text">carousel</div>
              </div>
            </Col>
            <Col md={3} className='nodes-padding'>
              <div className="action-nodes confirm-bg" onDragStart={(event) => onDragStart(event, 'confirmation')} draggable>
                <img className="confirm-img" src="./images/CDIcons/Confirmation.svg"></img>
                <div className="confirm-span node-text">confirm</div>
              </div>
            </Col>
            <Col md={3} className='nodes-padding'>
              <div className="action-nodes flow-bg" onDragStart={(event) => onDragStart(event, 'flow')} draggable>
                <img className="flow-img" src="./images/CDIcons/Flow.svg"></img>
                <div className="flow-span node-text">flow</div>
              </div>
            </Col>
            <Col md={3} className='nodes-padding'>
              <div className="action-nodes media-bg" onDragStart={(event) => onDragStart(event, 'media')} draggable>
                <img className="media-img" src="./images/CDIcons/Media.svg"></img>
                <div className="media-span node-text">media</div>
              </div>
            </Col>
            <Col md={3} className='nodes-padding'>
              <div className="action-nodes forms-bg" onDragStart={(event) => onDragStart(event, 'forms')} draggable>
                <img className="form-img" src="./images/CDIcons/InputCapture.svg"></img>
                <div className="form-span node-text">input capture</div>
              </div>
            </Col>
            <Col md={3} className='nodes-padding'>
              <div className="action-nodes agent-bg" onDragStart={(event) => onDragStart(event, 'agent')} draggable>
                <img className="agent-img" src="./images/CDIcons/AgentTransfer.svg"></img>
                <div className="agent-span node-text">agent transfer</div>
              </div>
            </Col>
            <Col md={3} className='nodes-padding'>
              <div className="action-nodes script-bg" onDragStart={(event) => onDragStart(event, 'script')} draggable>
                <img className="script-img" src="./images/CDIcons/Script.svg"></img>
                <div className="script-span node-text">script</div>
              </div>
            </Col>
            <Col md={3} className='nodes-padding'>
              <div className="action-nodes email-bg" onDragStart={(event) => onDragStart(event, 'email')} draggable>
                <img className="email-img" src="./images/CDIcons/Mail.svg"></img>
                <div className="email-span node-text">email</div>
              </div>
            </Col>
            <Col md={3} className='nodes-padding'>
              <div className="action-nodes triggerIntent-bg" onDragStart={(event) => onDragStart(event, 'triggerIntent')} draggable>
                <img className="triggerIntent-img" src="./images/CDIcons/TriggerIntent.svg"></img>
                <div className="triggerIntent-span node-text">trigger intent</div>
              </div>
            </Col>
            {/* <Col md={3} className='nodes-padding'>
              <div className="action-nodes showData-bg" onDragStart={(event) => onDragStart(event, 'showData')} draggable>
                <img className="showData-img" src="./images/CDIcons/ShowData.svg"></img>
                <div className="showData-span node-text">show data</div>
              </div>
            </Col> */}
            <Col md={3} className='nodes-padding'>
              <div className="action-nodes feedback-bg" onDragStart={(event) => onDragStart(event, 'feedback')} draggable>
                <img className="feedback-img" src="./images/CDIcons/Feedback.svg"></img>
                <div className="feedback-span node-text">feedback</div>
              </div>
            </Col>
            </Row>
          </div>
          )}
          {selectedTab === 'Templates' && (
            <div id='templates-div'>
              <Splide options={ {
              // rewind: true,
              perPage: 3,
              // fixedWidth: 70,
              padding: { left: 15, right: 20 },
              perMove: 3,
              drag: false,
              height: 90,
            } }>
              <SplideSlide>
                <div className='node-border' id={selectedNode==='http'?'http-border':'no-border'}>
              <div className="splide-nodes http-bg" onClick={()=>setSelectedNode('http')}>
                <img className="http-img" src="./images/CDIcons/Http.png"></img>
                <div className="http-span node-text">http</div>
              </div>
              </div>
              </SplideSlide>
              <SplideSlide>
              <div className='node-border' 
               id={selectedNode==='forms'?'forms-border':'no-border'}>
              <div className="splide-nodes forms-bg" onClick={()=>setSelectedNode('forms')}>
                <img className="form-img" src="./images/CDIcons/Forms.svg"></img>
                <div className="flow-span node-text">input capture</div>
              </div>
              </div>
              </SplideSlide>
              <SplideSlide>
              <div className='node-border' 
              id={selectedNode==='flow'?'flow-border':'no-border'}>
              <div className="splide-nodes flow-bg" onClick={()=>setSelectedNode('flow')}>
                <img className="flow-img" src="./images/CDIcons/Flow.svg"></img>
                <div className="flow-span node-text">flow</div>
              </div>
              </div>
              </SplideSlide>
              <SplideSlide>
              <div className='node-border' 
              id={selectedNode==='script'?'script-border':'no-border'}>
              <div className="splide-nodes script-bg" onClick={()=>setSelectedNode('script')}>
              <img className="script-img" src="./images/CDIcons/Script.svg"></img>
                <div className="scriptTemplate-span node-text">script</div>
              </div>
              </div>
              </SplideSlide>
            </Splide>
            <div className='templates-list'>
              {selectedNode === 'http' && httpTemplates && httpTemplates.map((item)=>
              <div className='listBox' id={'http-border'} key={item.name} onDragStart={(event) => onDragStart(event, 'http', item.data)} draggable>
                <div className='listLabel' title={item.name}>{item.name.length > 25 ? item.name.slice(0, 25) + "..." : item.name}</div>
                <div className='padding2'><img id='pointerCursor' src='./images/CDIcons/deleteIcon.svg' onClick={()=>deleteTemplate(item.ID)}/></div>
              </div>
              )}
              {selectedNode === 'forms' &&  formTemplates && formTemplates.map((item)=>
              <div className='listBox' id={'forms-border'} key={item.name} onDragStart={(event) => onDragStart(event, 'forms', item.data)} draggable>
                <div className='listLabel' title={item.name}>{item.name.length > 25 ? item.name.slice(0, 25) + "..." : item.name}</div>
                <div className='padding2'><img id='pointerCursor' src='./images/CDIcons/deleteIcon.svg' onClick={()=>deleteTemplate(item.ID)}/></div>
              </div>
              )}
              {selectedNode === 'flow' &&  flowTemplates && flowTemplates.map((item)=>
              <div className='listBox' id={'flow-border'} key={item.ID} onDragStart={(event) => onDragStart(event, 'flow', item.data)} draggable>
                <div className='listLabel' title={item.name}>{item.name.length > 25 ? item.name.slice(0, 25) + "..." : item.name}</div>
                <div className='padding2'><img id='pointerCursor' src='./images/CDIcons/deleteIcon.svg' onClick={()=>deleteTemplate(item.name, item.data.flowid)}/></div>
              </div>
              )}
              {selectedNode === 'script' &&  scriptTemplates && scriptTemplates.map((item)=>
              <div className='listBox' id={'script-border'} key={item.name} onDragStart={(event) => onDragStart(event, 'script', item.data)} draggable>
                <div className='listLabel' title={item.name}>{item.name.length > 25 ? item.name.slice(0, 25) + "..." : item.name}</div>
                <div className='padding2'><img id='pointerCursor' src='./images/CDIcons/deleteIcon.svg' onClick={()=>deleteTemplate(item.ID)}/></div>
              </div>
              )}
            </div>
            </div>
          )}
      </TabCustom>
      {/* <Row>
        <div className='sidebar-heading'>
          <h4>ACTIONS</h4>
        </div>        
      </Row> */}

        <DeleteModal
          show={deleteFlag}
          deleteModal={() => setDeleteFlag(false)}
          title="Delete Confirmation"
          bodyTitle="Do you want to delete the template?"
          buttonClick={() => confirmDelete()}
        />
     
      
    </div>
  );
};

export default Sidebar;