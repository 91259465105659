// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InsightsPage{padding: 15px;background: #fff;
    margin: 20px;}
.InsightsTabsIcons{
	margin-right:10px;width: 22px;
}
.InsightsPage .card-header{ background: #fff;    margin-bottom: 0!important;
    padding: 8px;}
.InsightsPage .card{    margin-top: 12px;}`, "",{"version":3,"sources":["webpack://./src/containers/SettingPage/InsightsPage.css"],"names":[],"mappings":"AAAA,cAAc,aAAa,CAAC,gBAAgB;IACxC,YAAY,CAAC;AACjB;CACC,iBAAiB,CAAC,WAAW;AAC9B;AACA,4BAA4B,gBAAgB,KAAK,0BAA0B;IACvE,YAAY,CAAC;AACjB,wBAAwB,gBAAgB,CAAC","sourcesContent":[".InsightsPage{padding: 15px;background: #fff;\n    margin: 20px;}\n.InsightsTabsIcons{\n\tmargin-right:10px;width: 22px;\n}\n.InsightsPage .card-header{ background: #fff;    margin-bottom: 0!important;\n    padding: 8px;}\n.InsightsPage .card{    margin-top: 12px;}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
