import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Row } from 'react-bootstrap';
import './conversationMgmt.css';
import ButtonCustom from '../../../../components/UIComponents/Button/ButtonComponent';


const Feedback = () => {

    const [faqCheck, setFaqCheck] = useState(true);
    const [flowCheck, setFlowCheck] = useState(false);

    const resetHandler = () => {
        setFaqCheck(true);
        setFlowCheck(false);
    }

    const saveHandler = () => {
        //update when save api available.
    }

    return (
        <div className='conversationTabContent'>
            <Row className='paddingT2'>
                <span className='label'>FAQ Response</span>
                <Form>
                    <Form.Check
                        type="switch"
                        id="faqFeedback"
                        checked={faqCheck}
                        onChange={e => setFaqCheck(e.target.checked)}
                    />
                </Form>

                <span className='label paddingL3'>Flow Completion</span>
                <Form>
                    <Form.Check
                        type="switch"
                        id="flowFeedback"
                        checked={flowCheck}
                        onChange={e => setFlowCheck(e.target.checked)}
                    />
                </Form>
            </Row>
            
            <Row className='paddingT3'>
                <ButtonCustom
                    variant='outlined'
                    label='Reset'
                    className='buttonClass mr-3 reset-btnFaq'
                    clicked={resetHandler}
                />
                <ButtonCustom
                    variant='contained'
                    label='Save'
                    className='buttonWidth'
                    clicked={saveHandler}

                />
            </Row>
        </div>
    )
}

export default Feedback;