import React, { useState, useEffect } from "react";
import Drawer from "@material-ui/core/Drawer";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import Heading3 from "../../../../../components/Typography/Heading3/Heading3";
import ReactSelectComponent from "../../../../../components/ReactSelectComponent/ReactSelectComponent";
import "./cluster-results.css";

const useStyles = makeStyles({
    list: {
      width: "28rem",
      overflowY: "scroll",
      maxHeight: "100vh",
      overflowX: "hidden",
    },
  });
  
  const ClusterResultsComponent = ({ 
      toggleDrawer, 
      item,
      config  }) => {
    const classes = useStyles();
    const [tickets ,setTickets] = useState([]);
    const [ticketsObj, setTicketsObj] = useState({});
    const [ticketInfo, setTicketInfo] = useState(false);

    const selectedIssueDetails = (issue) =>
      Object.entries(issue).map(([key, value]) => {
        if (Array.isArray(value)) {
          return null;
        }
        return (
          <>
          {(config.CLUSTER_HIDDEN_FIELDS).includes(key) ? null : (
              <div className="ml-2 mt-3">
                <div className="issue-details-head">{key.split('_').join(' ')}</div>
                <div className="issue-details-description mt-1">{value}</div>
              </div>
          )}
          </>
        );
      });
  
      useEffect(() => {
          setTickets(tickestData(item.documents));
      },[])
  
      const tickestData = (data) => {
          let arr =[]
          for (var i = 0; i < data.length; i++) {
              arr.push({
                  label: data[i].id,
                  value: data[i].id,
              });
          }
          return arr;
      }
  
      const onChange = (event) => {
          let data = (item.documents).find(item => item.issueid === event.label);
         setTicketsObj(data);
         setTicketInfo(true);
      }

      return (
        <React.Fragment>
          <Drawer anchor={"right"} open={true} onClose={toggleDrawer(false)}>
            <div
              className={clsx(classes.list)}
              role="presentation"
              className="issue-result-page"
            >
                <div className="d-flex pt-4 pl-2">
            <div className="col-md-1 arrow-img">
              <CloseIcon
                className="d-flex"
                onClick={toggleDrawer(false)}
                style={{ fontSize: 25 }}
              />
            </div>
            <div className="d-flex product-data pl-2" md={12}>
              <Heading3
                className="text-capitalize issue-details-header"
                text={"Cluster Details" }
              />
            </div>
          </div>
          {/* <div className="issue-details-description ml-1 pl-4">
            {ticketInfo ? ticketsObj.description.toLowerCase() : null }
          </div> */}
          <div className="d-flex flex-column issue-results-details" >
            <div className="mt-3 mb-3">
                <div className="issue-details-head">{'Label'}</div>
                <div className="issue-details-description mt-1">{item.label}</div>
            </div>
            <ReactSelectComponent
              options={tickets}
              name={"item.attribute_name"}
              label={"ISSUES"}
              placeholder={"Select"}
              // onChange={(event) => onChange(event)}
              onChange={""}
              className="issue-cluster-select"
              defaultValue={tickets[0]}
            />
           {ticketInfo ? selectedIssueDetails(ticketsObj) : selectedIssueDetails(item.documents[0]) }
          </div>
        </div>
      </Drawer>
    </React.Fragment>
  );
};

ClusterResultsComponent.propTypes = {};

ClusterResultsComponent.defaultProps = {};

export default ClusterResultsComponent;