import React, { useState, useEffect } from 'react';
import DrawerCustom from "../../UIComponents/Drawer/DrawerComponent";
import { Row, Col, Form } from "react-bootstrap";
import ReactSelectComponent from "../../../containers/SettingPage/SettingInnerPages/components/ReactSelectComponent/ReactSelectComponent";
import { CreateCuxTrigger, UpdateCuxTrigger, DeleteCuxTrigger } from '../../../store/actions';
import { useDispatch } from "react-redux";
import ButtonCustom from "../../UIComponents/Button/ButtonComponent";
import TimerTrigger from '../../../containers/ProjectViewPage/CoversationalUI/TriggerFiles/TimerTrigger';


const getTodayDate = () => {
    let today = new Date();
    return today.getFullYear()+'-'+
        parseInt(today.getMonth() + 1).toLocaleString("en-US",{minimumIntegerDigits:2,useGrouping:false})+
        '-'+today.getDate();
}

const getTriggerContent = (addTriggerDrawer, triggerData) => {
    return {
            timezone:!addTriggerDrawer?triggerData.triggercontent.timezone:'Asia/Kolkata',
            startdate:!addTriggerDrawer?triggerData.triggercontent.startdate:getTodayDate(),
            enddate:!addTriggerDrawer?triggerData.triggercontent.enddate:getTodayDate(),
            executiontime:!addTriggerDrawer?triggerData.triggercontent.executiontime:'00:00:00',
            endtime:!addTriggerDrawer?triggerData.triggercontent.endtime:'23:59:59',
            frequency:!addTriggerDrawer?triggerData.triggercontent.frequency:'Hourly',
            weekday:!addTriggerDrawer?triggerData.triggercontent.weekday:null,
            repeatcount:!addTriggerDrawer?triggerData.triggercontent.repeatcount:1,
            repeattime:!addTriggerDrawer?triggerData.triggercontent.repeattime:'00:00:00',
            monthweek:!addTriggerDrawer?triggerData.triggercontent.monthweek:1,
            monthday:!addTriggerDrawer?triggerData.triggercontent.monthday:0,
            monthOption:!addTriggerDrawer?triggerData.triggercontent.monthOption:'monthweek',
            monthweekday:!addTriggerDrawer?triggerData.triggercontent.monthweekday:'Sunday'
        }
}

const TriggerDrawer = ({show, showDrawer, flowid, intentList, 
    config, addTriggerDrawer, triggerData}) => {

    const dispatch = useDispatch();
    const triggerTypes = [{ value: "intent", label: "Intent based" },
                          { value: "timer", label: "Timer based" }];
    const [triggerType, setTriggerType] = useState();
    const [intentListValues, setIntentListValues] = useState([]);
    const [selectedIntent, setSelectedIntent] = useState(null);
    const [triggercontent, setTriggercontent] = useState(getTriggerContent(addTriggerDrawer, triggerData));

    useEffect(()=>{
        if(!addTriggerDrawer) {
            setTriggerType(triggerTypes.find((item)=>item.value === triggerData.triggertype));
            setSelectedIntent({ value: triggerData.triggercontent.intentname, 
                                label: triggerData.triggercontent.intentname });
        }
    },[]);

    useEffect (()=>{
        var language = localStorage.getItem("language");
        if(intentList !== undefined && intentList.length !== 0) {
            let list = intentList[language][localStorage.getItem('projectname')];
            let intents = Object.keys(list);
            if(intentListValues.length === 0) {
                intents.forEach(item=> {
                    if(item.toLocaleLowerCase() != "faq" && item.toLocaleLowerCase() != "intentlist") {
                        setIntentListValues((prev)=>[...prev,{value:item, label:item}]);
                    }                    
                });
            }                
        }
        
        
    },[triggerType && triggerType.value === 'intent']);

    const getTriggerData = () => {
        let body = null;
        if(triggerType.value === 'timer') {
            triggercontent.repeatcount = parseInt(triggercontent.repeatcount);
            triggercontent.monthday = parseInt(triggercontent.monthday);
            body = {
                triggertype:"timer",
                triggercontent
            }
        } else {
            body = {
                triggertype:"intent",
                triggercontent:{
                    intentname: selectedIntent.value
                }
            }
        }
        return body;
    }

    const addTrigger = (e) => {
        e.preventDefault();
        let data = getTriggerData();
        dispatch(CreateCuxTrigger(config.COMMON_API_URL,data,flowid));
        showDrawer(false);
    }

    const updateTrigger = (e) => {
        e.preventDefault();
        let data = getTriggerData();
        dispatch(UpdateCuxTrigger(config.COMMON_API_URL,data,flowid,triggerData.triggerid));
        showDrawer(false);
    }

    const deleteTrigger = (e) => {
        e.preventDefault();
        dispatch(DeleteCuxTrigger(config.COMMON_API_URL,flowid,triggerData.triggerid));
        showDrawer(false);
    }

    const getTimeValue = (value, time, type) => {
        if(time === 'hours')
            return value+triggercontent[type].substring(2);
        else 
            return triggercontent[type].substring(0,3)+value+triggercontent[type    ].substring(5);
    }
    
    const onChangeHandler = (value, type) => {
        switch(type) {
            case 'hours': value = getTimeValue(value,'hours','executiontime');
                          type = 'executiontime'; break;
            case 'mins': value = getTimeValue(value, 'mins','executiontime');
                          type = 'executiontime'; break;
            case 'endHr': value = getTimeValue(value,'hours','endtime');
                          type = 'endtime'; break;
            case 'endMins': value = getTimeValue(value, 'mins','endtime');
                          type = 'endtime'; break;
            case 'repeattimeHr': value = getTimeValue(value,'hours','repeattime');
                          type = 'repeattime'; break;
            case 'repeattimeMin': value = getTimeValue(value, 'mins','repeattime');
                          type = 'repeattime'; break;
            case 'monthday' : value = value > 31 ? triggercontent.monthday : value; break;
            case 'repeatcount' : value = value > 99 ? triggercontent.repeatcount : value; break;
            
        }
        setTriggercontent((formData) => ({
            ...formData,
            [type]: value
          }))
    }

    const isFormValid = () => {
        return triggerType && (triggerType.value === 'intent'? selectedIntent : true);
    }
                        
    return (
    <React.Fragment >
        <DrawerCustom showDrawer={showDrawer} openDrawer={show} 
            heading={(addTriggerDrawer?'Add ':'') + 'Trigger Details'}>
            <div className="mt-1">
                <Col md={12}>
                     <div className="common-input">
                        <Form.Label className="mb-1 text-box-title adding-other-input">Trigger type*</Form.Label>
                    </div>
                    <ReactSelectComponent
                        customClassName="SelectView"
                        value={triggerType}
                        options={triggerTypes}
                        name="option"
                        placeholder={'Select'}
                        onChange={(event) => setTriggerType(event)}
                    />
                </Col>
            </div>
            
            {triggerType && triggerType.value === 'intent' && (
               <>
                <div className="mt-3">
                <Col md={12}>
                    <div className="common-input">
                        <Form.Label className="mb-1 text-box-title adding-other-input">Intent name*</Form.Label>
                    </div>
                    <ReactSelectComponent
                        customClassName="SelectView"
                        value={selectedIntent}
                        options={intentListValues}
                        name="option"
                        placeholder={'Select'}
                        onChange={(event) => setSelectedIntent(event)}
                    />
                </Col>
                </div>
                </>
            )}

            {triggerType && triggerType.value === 'timer' && (
                <TimerTrigger triggercontent={triggercontent} onChangeHandler={onChangeHandler} 
                triggerData={triggerData} addTriggerDrawer={addTriggerDrawer}/>
            )}

            <br/>

            <div className="mt-1">
                <Col md={12}>
                    {addTriggerDrawer ? (
                        <ButtonCustom variant='contained' label='Submit'className='buttonWidth' clicked={addTrigger} disabled={!isFormValid()}/>
                    ) : (
                        <>
                           <span><ButtonCustom variant='outlined' label='Remove'className='buttonWidth remove-trigger-btn' 
                                    clicked={deleteTrigger}/></span>
                            <span><ButtonCustom variant='contained' label='Save'className='buttonWidth save-trigger-btn' 
                                    clicked={updateTrigger}/></span>
                        </> 
                    )}
                    
                </Col>
            </div>        

        </DrawerCustom>
    </React.Fragment>
    )
}

export default TriggerDrawer;