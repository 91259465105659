import React from "react";
import { Form } from "react-bootstrap";
import * as PropTypes from "prop-types";
import "./InputWithLabel.css";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 12,
    fontFamily: "Roboto-Regular",
  },
  arrow: {
    color: theme.palette.common.white,
    "&:before": {
      border: "1px solid #E6E8ED",
      boxShadow: theme.shadows[1],
    },
  },
}))(Tooltip);
const InputWithLabelComponent = ({
  min,
  max,
  label,
  pattern,
  name,
  value,
  className,
  placeHolder,
  readOnly,
  onChange,
  required,
  isInvalid,
  error,
  helpIcon,
  type,
  onKeyDown,
  showLabel,
  disabled,
  toolTipParams,
  toolTip,
  ...inputs
}) => {
  return (
    <div className="common-input">
      {showLabel ? (
        <Form.Label className="mb-1 text-box-title adding-other-input">{label}</Form.Label>
      ) : null}
      {label==="Default Value"?(
      <LightTooltip 
      arrow
      title="Default value should match the validation expression."
      placement="top-start"
    >
      <img 
        src="./images/info_icon.png"
        style={{ position: "relative", top: "1px", left: "6px" }}
      />
    </LightTooltip>
      ):null}
      {toolTip && (
      <LightTooltip 
      arrow
      title={toolTipParams.title}
      placement={toolTipParams.placement}
      >
        <img 
          src="./images/info_icon.png"
          style={{ position: "relative", left: "6px" }}
        />
      </LightTooltip>
      )}
      {helpIcon ? (
        <HelpOutlineIcon className="product-img" style={{ fontSize: 14 }} />
      ) : null}
      <Form.Control
        type={type}
        pattern={pattern}
        name={name}
        max={max}
        min={min}
        required={required}
        value={value}
        className={"default-css" + " " + className }
        placeholder={placeHolder}
        onKeyDown={onKeyDown}
        onChange={onChange}
        readOnly={readOnly}
        isInvalid={isInvalid}
        disabled={disabled}
        {...inputs}
      />
      {isInvalid ? (
        <span className="error-css">{error}</span>
      ) : null}
    </div>
  );
};
InputWithLabelComponent.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  placeHolder: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  isInvalid: PropTypes.bool,
  error: PropTypes.string,
  helpIcon: PropTypes.bool,
  showLabel: PropTypes.bool,
};

InputWithLabelComponent.defaultProps = {
  label: "Input Label",
  name: "input-class",
  value: "",
  className: "",
  placeHolder: "Enter Text",
  readOnly: false,
  required: false,
  isInvalid: false,
  type: "text",
  error: "",
  helpIcon: false,
  showLabel: true,
  toolTip:false,
};
export default InputWithLabelComponent;
