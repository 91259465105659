import React, { memo } from 'react';
import { Handle } from 'react-flow-renderer';
import './nodeStyles.css';

export default memo(({ data, isConnectable }) => {
    
  return (
    <>
        <div className='startNode'>{data.label}</div>
        <Handle
            type="source"
            position="bottom"
            style={{ background: '#555' }}
            isConnectable={isConnectable}
        />
      
    </>
  );
});