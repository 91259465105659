// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  .product-data h3{
    font-family: "Roboto-Black";
    font-size: 18px;
    line-height: 25px;
    color: #1B1B1B;
  }

.product-content-data{
    padding-left: 30px;
    font-family: "Roboto-Regular";
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #000;
}
.Product-Select-Item{
    /* background-color: #e3e8fb; */
    height: 110px;
    padding-top: 20px;
    margin-top: 10px;
    padding-left: 18px;
}

.Product-Select-Item h3, h4{
    font-family: "Roboto-Regular";
    font-size: 14px;
    line-height: 16px;
}
.Product-Select-Item h4{
    transform: rotate(0.14deg);
    color: #000;
    font-family: 'Roboto-Bold';
}
.Product-Select-Item h3{
    transform: matrix(1, 0, 0, 1, 0, 0);
    color: #353535;
}
.select-option{
    cursor: pointer;
}
.product-img{
    margin-left: 8px;
    cursor: pointer;
}
.manage-product-select-items .selected-product-atri-items{margin-top: 2rem; margin-left: 1rem;}`, "",{"version":3,"sources":["webpack://./src/containers/SettingPage/SettingInnerPages/Insight/AdminConfiguration/TraiageConfigurationTabs/ManageMasterList/ManageProduct/product-details-list.css"],"names":[],"mappings":";EACE;IACE,2BAA2B;IAC3B,eAAe;IACf,iBAAiB;IACjB,cAAc;EAChB;;AAEF;IACI,kBAAkB;IAClB,6BAA6B;IAC7B,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,WAAW;AACf;AACA;IACI,+BAA+B;IAC/B,aAAa;IACb,iBAAiB;IACjB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,6BAA6B;IAC7B,eAAe;IACf,iBAAiB;AACrB;AACA;IACI,0BAA0B;IAC1B,WAAW;IACX,0BAA0B;AAC9B;AACA;IACI,mCAAmC;IACnC,cAAc;AAClB;AACA;IACI,eAAe;AACnB;AACA;IACI,gBAAgB;IAChB,eAAe;AACnB;AACA,0DAA0D,gBAAgB,EAAE,iBAAiB,CAAC","sourcesContent":["\n  .product-data h3{\n    font-family: \"Roboto-Black\";\n    font-size: 18px;\n    line-height: 25px;\n    color: #1B1B1B;\n  }\n\n.product-content-data{\n    padding-left: 30px;\n    font-family: \"Roboto-Regular\";\n    font-weight: 400;\n    font-size: 12px;\n    line-height: 14px;\n    color: #000;\n}\n.Product-Select-Item{\n    /* background-color: #e3e8fb; */\n    height: 110px;\n    padding-top: 20px;\n    margin-top: 10px;\n    padding-left: 18px;\n}\n\n.Product-Select-Item h3, h4{\n    font-family: \"Roboto-Regular\";\n    font-size: 14px;\n    line-height: 16px;\n}\n.Product-Select-Item h4{\n    transform: rotate(0.14deg);\n    color: #000;\n    font-family: 'Roboto-Bold';\n}\n.Product-Select-Item h3{\n    transform: matrix(1, 0, 0, 1, 0, 0);\n    color: #353535;\n}\n.select-option{\n    cursor: pointer;\n}\n.product-img{\n    margin-left: 8px;\n    cursor: pointer;\n}\n.manage-product-select-items .selected-product-atri-items{margin-top: 2rem; margin-left: 1rem;}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
