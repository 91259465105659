import React, { useEffect, useState } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Col from 'react-bootstrap/Col';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import Tab from 'react-bootstrap/Tab';
import ConnectionsTab from './IntegrationTabs/ConnectionsTab';
import AlertsTab from './IntegrationTabs/AlertsTab';
import DataSyncTab from './IntegrationTabs/DataSyncTab';
import { getConnectionsData, SetErrorMessage} from '../../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { botPostCalltoServer } from '../../../store/utility';
import DataMappingTab from './IntegrationTabs/DataMappingTab';
import disableDataMappingAndDataSyncTabshelper from './IntegrationTabs/helpers/disableDataMappingAndDataSyncTabshelper/disableDataMappingAndDataSyncTabshelper';
import { useLocation, useHistory } from "react-router-dom";
import Heading3 from './components/Typography/Heading3/Heading3';

function IntegrationTabs({config }) {
    const history = useHistory();
    const location = useLocation();
    const selectedMenu = location.state.selectedMenu;
    const selectedSubMenu = location.state.selectedSubMenu;
    const [data, setData] = useState({});
    const [disableDataTabs, setDisableDataTabs] = useState(true);
    const dispatch = useDispatch();
    const [selectedTab, setSelectedTab] = useState('connection');
    const [showConfigLoader, setShowConfigLoader] = useState(false);
    const connectionsData = useSelector(state => state.projectview.connectionsData);
    const selectedConnectionsData = connectionsData.find(item => item._id.includes(selectedSubMenu));
    const selectedSourceConnectionData = get(selectedConnectionsData, '_source.connection', []);
    const selectedDataMapping = get(selectedConnectionsData, '_source.dataMapping', []);
    const selectedDataSync = get(selectedConnectionsData, '_source.dataSync', {});
    const loadAlertData = get(selectedConnectionsData, '_source.alerts', {});
    const tabId = `${localStorage.getItem("orgName")}__${selectedMenu}__${selectedSubMenu}`;
    const disableTabs = useSelector(state => disableDataMappingAndDataSyncTabshelper(state, selectedSubMenu, disableDataTabs));
    useEffect(() => {
        
        dispatch(getConnectionsData(config));
        //let url = config.API_URL + `/lowNoCodeConnectorService`;

        const configObj = {
            'dataSourceType': selectedMenu,
            'dataSourceName': selectedSubMenu,
            'tenantId': localStorage.getItem("orgName"),
            'projectName': localStorage.getItem("projectName"),
            'connector': ""
        }
        let url = config.CONNECTOR_URL + `/connector-config`;
        botPostCalltoServer(url, configObj)

        if (isEmpty(selectedSourceConnectionData)) {
            setShowConfigLoader(true);
            botPostCalltoServer(url, configObj)
                .then(response => {
                    if (get(response, 'data.status.responseType', '') === "success") {
                        setData(JSON.parse(response.data.data).data);
                        setShowConfigLoader(false);
                        //dispatch(SetSuccessMessage(`Fetched ${selectedSubMenu} Config Successfully`))
                    }
                    else {
                        setShowConfigLoader(false);
                        dispatch(SetErrorMessage(`Error in ${selectedSubMenu} fetching config`));
                    }
                }).catch(() => {
                    setShowConfigLoader(false);
                    dispatch(SetErrorMessage(`Error in ${selectedSubMenu} fetching config`));
                });
        }
    }, [selectedSubMenu, selectedMenu, selectedTab]);

    useEffect(() => {
        setSelectedTab('connection');
    }, [selectedSubMenu, selectedMenu])

    const onTabChange = (selected) => {
        setSelectedTab(selected)
    };

    const handleDisableDataTabs = (payload) => setDisableDataTabs(payload);
    return (
        <React.Fragment>
            <Col xs="12 ml-4">
          <div className="d-flex mt-3 main-heading">
            <h3>
              <img
                style={{marginTop:"27px"}}
                className=""
                src="./Icons/arrow-lft.png"
                onClick={() => history.goBack()}
              />
            </h3>
            <Heading3 text={`Integrations/` + selectedSubMenu } className="pageTitleText" />
          </div>
        </Col>
        <Tabs id="controlled-tab-example" activeKey={selectedTab} onSelect={onTabChange}>
            <Tab eventKey="connection" title="Connection">
                {selectedTab === 'connection' ?
                    <ConnectionsTab
                        selectedMenu={selectedMenu}
                        selectedSubMenu={selectedSubMenu}
                        config={config}
                        data={data}
                        handleDisableDataTabs={handleDisableDataTabs}
                        showConfigLoader={showConfigLoader}
                    /> : null
                }
            </Tab>
            {selectedMenu !== 'knowledge_base' ?
                <Tab eventKey="dataMapping" title="Data Mapping" disabled={disableTabs}>
                    {selectedTab === 'dataMapping' ?
                        <DataMappingTab
                            selectedMenu={selectedMenu}
                            selectedSubMenu={selectedSubMenu}
                            config={config}
                            data={data}
                            selectedDataMapping={selectedDataMapping}
                            tabId={tabId}
                        /> : null
                    }
                </Tab> : null}
            {selectedMenu !== 'knowledge_base' ?
                <Tab eventKey="dataSync" title="Data Sync" disabled={disableTabs}>
                    {selectedTab === 'dataSync' ?
                        <DataSyncTab
                            selectedMenu={selectedMenu}
                            selectedSubMenu={selectedSubMenu}
                            config={config}
                            data={data}
                            showConfigLoader={showConfigLoader}
                            selectedDataSync={selectedDataSync}
                            tabId={tabId}
                        /> : null
                    }
                </Tab> : null
            }
            {selectedMenu !== 'knowledge_base' ?
                <Tab eventKey="alerts" title="Alerts">
                    {selectedTab === 'alerts' ?
                        <AlertsTab
                            selectedMenu={selectedMenu}
                            selectedSubMenu={selectedSubMenu}
                            config={config}
                            data={data}
                            loadAlertData={loadAlertData}
                            showConfigLoader={showConfigLoader}
                            tabId={tabId}
                        /> : null
                    }
                </Tab> : null
            }
        </Tabs>
        </React.Fragment>
    )
}

export default IntegrationTabs;