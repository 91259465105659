import React, { useState } from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import ManageProduct from "./ManageProduct/ManageProduct";
import "./manage-master-card.css";
import { Card } from "react-bootstrap";
import { get } from "lodash";

const MasterCard = ({
  selectedData,
  config,
  productDetails,
  sourceContent,
  sourceData,
  selectedSource,
  sourceVal
}) => {
  const [showDrawer, setShowDrawer] = useState(false);
  const toggleDrawer = (value) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setShowDrawer(value);
  };

  const getAttributesData = (data) => {
    const selectedSourceFields = get(data, "source_fields", []);
    if (isEmpty(selectedSourceFields)) {
      return "--";
    }

    return selectedSourceFields.toString();
  };
  const getSourceName = (selectedData) => {
    const sourceName = get(selectedData, "source_name", "");
    if (sourceName === "productattributes") {
      return "Product";
    }
    if(sourceName === 'rootcause') {
      return 'Root Causes'
    }
    return sourceName;
  };

 

  return (
    <Card className="mb-1 manage-master-card">
      <Card.Body onClick={toggleDrawer(true)}>
        <div className="d-flex align-items-center">
          <div className="col-md-7 product-container">
            <div className="product-content">
              <h5 className="text-capitalize">{getSourceName(selectedData)}</h5>
            </div>
            <div className="product-content-details">
              {`View or choose applicable product attributes from the ticket data source to consider as ${getSourceName(
                selectedData
              )} being extracted `}
            </div>
          </div>
          <div className="col-md-2 source-container">
            <div className="source-content">
              <h6>{sourceContent}</h6>
            </div>
            <div className="source-content-details">{sourceData}</div>
          </div>
          <div className="col-md-2 attribute-container">
            <div className="attribute-content">
              <h6 className="text-capitalize">{'Attributes'}</h6>
            </div>
            <div className="attribute-content-details">
              {getAttributesData(selectedData)}
            </div>
          </div>
          <span className="col-md-1 image justify-content-end">
            <img src="./images/Vector.svg" />
          </span>
        </div>
      </Card.Body>
      {showDrawer ? (
        <ManageProduct
          data={selectedData}
          showDrawer={showDrawer}
          setShowDrawer={setShowDrawer}
          toggleDrawer={toggleDrawer}
          config={config}
          sourceData={sourceData}
          selectedSource={selectedSource}
          sourceVal={sourceVal}
        />
      ) : null}
    </Card>
  );
};
MasterCard.propTypes = {
  productDetails: PropTypes.string,
  sourceContent: PropTypes.string,
  sourceData: PropTypes.string,
  atributeData: PropTypes.string,
};
MasterCard.defaultProps = {
  productDetails:
    "Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum ",
  sourceContent: "Source",
  sourceData: "ServiceNow",
  atributeData: "Attributes",
};

export default MasterCard;
