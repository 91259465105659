import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createStore, applyMiddleware, compose, combineReducers } from 'redux'
import {Provider} from 'react-redux'
import reducer from './store/reducers/auth'
import thunk from 'redux-thunk';
import UserReducer from './store/reducers/user'
import MessageReducer from './store/reducers/message'
import HealthReducer from './store/reducers/health'
import homereducer from './store/reducers/home'
import projectReducer from './store/reducers/projectview'
import agentReducer from './store/reducers/agentTransfer'
import { insightsReducer } from './store/reducers/Insights/insightsReducer';
import backupRestoreReducer from './store/reducers/backupRestore';
import cognitiveReducer from './store/reducers/cognitive';
import { adminSettingsReducer } from './store/reducers/adminSettings/adminSettingsReducer';
import { agentInsightsReducer } from './store/reducers/agentInsights/agentInsightsReducer';
import reportDashboard from './store/reducers/reportDashboard';
import CrmLayout from './store/reducers/CrmLayout';
import config from './config';
import UnsupervisedML from './store/reducers/UnsupervisedML';
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
    auth: reducer,
    user:UserReducer,
    errormessage:MessageReducer,
    health:HealthReducer,
    home: homereducer,
    projectview:projectReducer,
    agentTransfer:agentReducer,
    insights: insightsReducer,
    cognitive:cognitiveReducer,
    backupRestore:backupRestoreReducer,
    agentInsights: agentInsightsReducer,
    adminSettings: adminSettingsReducer,
    reportDashboard: reportDashboard,
    CrmLayout:CrmLayout,
    UnsupervisedML:UnsupervisedML
  
});

const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
));



const app = (
  <React.StrictMode>
  <Provider store={store}>
      <BrowserRouter>
          <App config={config} />
      </BrowserRouter>
  </Provider>
  </React.StrictMode>
);

ReactDOM.render( app, document.getElementById( 'root' ) );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();