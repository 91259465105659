import * as actionTypes from './actionTypes';
import { GetCalltoServer, PostCalltoServer } from '../utility';
import {SetErrorMessage, SetSuccessMessage} from './message'



export const agentStart = () => {
    return {
        type: actionTypes.AGENTLIST_START
    };
};

export const agentSuccess = (status) => {
    return {
        type: actionTypes.AGENTLIST_SUCCESS,
        agentList:status
    };
};

export const agentFail = (error) => {
    return {
        type: actionTypes.AGENTLIST_FAIL,
        error: error
    };
};


export const agentList = (apiURL,projectName) => {
    return dispatch => {
        dispatch(agentStart());
        let url =apiURL+`/activeUser/project/`+projectName;
        GetCalltoServer(url)
        .then(response => {
           if( response.data){               
                dispatch(agentSuccess(response.data.status));
           }
           else
           {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(agentFail(response.data.msg));
           }

        })
        .catch(err => {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(agentFail(err.message));
        });
    };    
};

export const activeAgentSuccess = (status) => {
    return {
        type: actionTypes.ACTIVEAGENT_SUCCESS,
        activeAgent:status
    };
};

export const activeAgentFail = (error) => {
    return {
        type: actionTypes.ACTIVEAGENT_FAIL,
        error: error
    };
};


export const listActiveAgent = (apiURL,projectName) => {
    return dispatch => {
        let url =apiURL+`/activeUser/project/`+projectName;
        GetCalltoServer(url)
        .then((response) => {
           if( response.data ){ 
                dispatch(activeAgentSuccess(response.data));
           }else if( response.data==null && response.status.statusCode=="400"){
            dispatch(activeAgentSuccess(response.data));
                
           }
           else
           {
            dispatch(activeAgentSuccess(response.data));
          
            dispatch(activeAgentFail(response.data.msg));
           }

        })
        .catch(err => {
         
           dispatch(activeAgentSuccess(null));
            dispatch(activeAgentFail(err.message));
        });
    };    
};

export const saveagentStart = () => {
    return {
        type: actionTypes.SAVE_AGENTLIST_START
    };
};

export const saveagentSuccess = (status) => {
    return {
        type: actionTypes.SAVE_AGENTLIST_SUCCESS,
        saveUser:status
    };
};

export const saveagentFail = (error) => {
    return {
        type: actionTypes.SAVE_AGENTLIST_FAIL,
        error: error
    };
};

export const getTimezonesStart = () => {
    return {
        type: actionTypes.GET_TIME_ZONE_START
    };
};

export const getTimezonesSuccess = (timeZone) => {
    return {
        type: actionTypes.GET_TIME_ZONE_SUCCESS,
        timeZone:timeZone
    };
};

export const getTimezonesFail = (error) => {
    return {
        type: actionTypes.GET_TIME_ZONE_FAIL,
        error: error
    };
};

export const getContryStart = () => {
    return {
        type: actionTypes.GET_CONTRY_START
    };
};

export const getContrySuccess = (countryList) => {
    return {
        type: actionTypes.GET_CONTRY_SUCCESS,
        countryList:countryList
    };
};

export const getContryFail = (error) => {
    return {
        type: actionTypes.GET_CONTRY_FAIL,
        error: error
    };
};

export const getAllScheduleMeetingDetailStart = () => {
    return {
        type: actionTypes.GET_ALL_SCHEDULE_MEETING_DETAIL_START
    };
};

export const getAllScheduleMeetingDetailSuccess = (scheduleMeetingDetail) => {
    return {
        type: actionTypes.GET_ALL_SCHEDULE_MEETING_DETAIL_SUCCESS,
        scheduleMeetingDetail:scheduleMeetingDetail
    };
};

export const getAllScheduleMeetingDetailFail = (error) => {
    return {
        type: actionTypes.GET_ALL_SCHEDULE_MEETING_DETAIL_FAIL,
        error: error
    };
};


export const getAgentTransferDetailStart = () => {
    return {
        type: actionTypes.GET_AGENT_TRANSFER_DETAIL_START
    };
};

export const getAgentTransferDetailSuccess = (agentTransferDetail) => {
    return {
        type: actionTypes.GET_AGENT_TRANSFER_DETAIL_SUCCESS,
        agentTransferDetail:agentTransferDetail
    };
};

export const getAgentTransferDetailFail = (error) => {
    return {
        type: actionTypes.GET_AGENT_TRANSFER_DETAIL_FAIL,
        error: error
    };
};

export const saveActiveAgent = (user,apiURL) => {
    return dispatch => {
        dispatch(saveagentStart());
       
       let url =apiURL+`/activeUser`;
        PostCalltoServer(url,user)
        .then(response => {
            if( response.data.status!=="Failure in saveactiveuser")
           {               
            dispatch(saveagentSuccess(response.data.status));
            dispatch(SetSuccessMessage('Configuration changes saved successfully.'))
           }
           else
           {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(saveagentFail(response.data.msg));
           }

        })
        .catch(err => {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(agentFail(err.message));
        });
    };    
};

export const saveSchedulMeetingSettings = (APIURL, data ) => {
    return dispatch => {
        dispatch(saveagentStart());
       let url =APIURL+ `/saveschedulemeeting`;
       
       PostCalltoServer(url, data).then(response => {
        if (response.data.status.responseType == "success") {
            dispatch(SetSuccessMessage('Configuration changes saved successfully.'))
        }
                        
        
    }).catch(() => {  
      console.log("error occured in save Schedule Meeting Settings!!!")
    });
    };    
};

export const getTimezones = (APIURL) => {
    return dispatch => {
        dispatch(getTimezonesStart());
       let url =APIURL+ `/all-timezones`;
       GetCalltoServer(url).then(response => {
        if (response.data.status.responseType == "success") {
            dispatch(getTimezonesSuccess(response.data.data))
        }
    }).catch(err => {  
        dispatch(getTimezonesFail(err))
    });
    };    
};

export const getContry = (APIURL) => {
    return dispatch => {
        dispatch(getContryStart());
       let url =APIURL+ `/all-country-names`;
       GetCalltoServer(url).then(response => {
        if (response.data.status.responseType == "success") {
            dispatch(getContrySuccess(response.data.data))
        }
    }).catch(err => {  
        dispatch(getContryFail(err))
    });
    };    
};

export const getAllScheduleMeetingDetail = (url) => {
    return dispatch => {
        dispatch(getAllScheduleMeetingDetailStart());
        PostCalltoServer(url).then(response => {
        if (response.data.status.responseType == "success") {
            dispatch(getAllScheduleMeetingDetailSuccess(response.data.data))
        }
    }).catch(err => {  
        dispatch(getAllScheduleMeetingDetailFail(err))
    });
    };    
};

export const getAgentTransferDetail = (url) => {
    return dispatch => {
        dispatch(getAgentTransferDetailStart());
        GetCalltoServer(url).then(response => {
        if (response.data.status.responseType == "success") {
            dispatch(getAgentTransferDetailSuccess(response.data.data))
        }
    }).catch(err => {  
        dispatch(getAgentTransferDetailFail(err))
    });
    };    
};

export const getAgentDetailsStart = () => {
    return {
        type: actionTypes.GET_AGENT_DETAIL_START
    };
};

export const getAgentDetailsSuccess = (agentDetail) => {
    return {
        type: actionTypes.GET_AGENT_DETAIL_SUCCESS,
        agentDetail:agentDetail
    };
};

export const getAgentDetailsFail = (error) => {
    return {
        type: actionTypes.GET_AGENT_DETAIL_FAIL,
        error: error
    };
};
export const getAgentDetails = (url) => {
    return dispatch => {
        dispatch(getAgentDetailsStart());
        GetCalltoServer(url).then(response => {
            dispatch(getAgentDetailsSuccess(response.data))
        if (response.data.status.responseType == "success") {
            dispatch(getAgentDetailsSuccess(response.data.data))
        }
    }).catch(err => {  
        dispatch(getAgentDetailsFail(err))
    });
    };    
};







