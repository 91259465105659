// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VirtualAssitantCanvas{
    margin-left: 15px;
}

.canvas-header {
    font-family: 'Roboto-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.04em;
    color: #696868;
}

.radioOption {
    padding-left: 5px;
    vertical-align: text-bottom;
}`, "",{"version":3,"sources":["webpack://./src/containers/SettingPage/SettingInnerPages/VirtualAssistant/canvas.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,6BAA6B;IAC7B,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,sBAAsB;IACtB,cAAc;AAClB;;AAEA;IACI,iBAAiB;IACjB,2BAA2B;AAC/B","sourcesContent":[".VirtualAssitantCanvas{\n    margin-left: 15px;\n}\n\n.canvas-header {\n    font-family: 'Roboto-Regular';\n    font-style: normal;\n    font-weight: 700;\n    font-size: 16px;\n    line-height: 19px;\n    letter-spacing: 0.04em;\n    color: #696868;\n}\n\n.radioOption {\n    padding-left: 5px;\n    vertical-align: text-bottom;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
