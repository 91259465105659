import React from 'react';

import classes from './Button.module.css';

const button = (props) => (
    <button
        type={props.btnInput?props.btnInput:'submit'}
        disabled={props.disabled}
        className={['btn','btn-primary ', classes[props.btnType]].join(' ')}
        onClick={props.clicked}
        style={props.customStyle}
        >{props.children}        
        </button>
       
       
);

export default button;