import React, { useEffect, useState, useReducer } from 'react';
import PropTypes from 'prop-types';
import Input from '../../../../components/UI/Input/InputCustom';
import { useDispatch } from 'react-redux';
import get from 'lodash/get';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { botPostCalltoServer } from '../../../../store/utility';
import { Form, Button, Col, Spinner, Row } from 'react-bootstrap';
import './connections-tab.css';
import { SetErrorMessage, SetSuccessMessage, getConnectionsData } from '../../../../store/actions';
import ViewSyncLogModal from './ViewSyncLogModal';

const initialState = {
    syncData: {
        scheduleTime: '',
        schedule: false,
        scheduleType: 'minutes',
        logDuration: '',
        storageType: 'days'
    },
    saveDataLoading: false,
    syncDataLoading: false,
};

function reducer(state, action) {
    switch (action.type) {
        case 'changeSyncData':
            return {
                ...state,
                syncData: {
                    ...state.syncData,
                    ...action.payload
                }
            }
        case 'updateSyncdata':
            return {
                ...state,
                syncData: action.payload
            }
        case 'saveDataLoading':
            return {
                ...state,
                saveDataLoading: action.payload
            }
        case 'syncDataLoading':
            return {
                ...state,
                syncDataLoading: action.payload
            }
        default:
            throw new Error();
    }
}

function DataSyncTab({
    DataSyncSystemConfig,
    StoreLogConfig,
    config,
    selectedMenu,
    selectedSubMenu,
    selectedDataSync,
    tabId,
    showConfigLoader
}) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [showViewSyncLogModal, setShowViewSyncLogModal] = useState(false);
    const handleViewSyncModal = () => setShowViewSyncLogModal(true);
    const hideViewSyncLogModal = () => setShowViewSyncLogModal(false);
    const reduxDispatch = useDispatch();
    const [disableSyncSwitch, setDisableSyncSwitch] = useState(false);
    const [disableSyncButton, setDisableSyncButton] = useState(false);

    useEffect(() => {
        if (isEmpty(selectedDataSync)) {
            dispatch(
                {
                    type: 'updateSyncdata',
                    payload: {
                        scheduleTime: '',
                        schedule: false,
                        scheduleType: 'minutes',
                        logDuration: '',
                        storageType: 'days'
                    }
                });
        }
        else {
            dispatch(
                {
                    type: 'updateSyncdata',
                    payload: selectedDataSync
                });
        }
    }, [tabId]);

    useEffect(() => {
      
        const configObj = {            
            'dataSourceType': selectedMenu,
            'dataSourceName': selectedSubMenu,
            'tenantId': localStorage.getItem("orgName"),
            'connector': ""
        };
        let url = config.CONNECTOR_URL + `/firsttime-sync`;
        botPostCalltoServer(url, configObj)
            .then(response => {
                if (get(response, 'data.status.responseType', '') === "success") {
                    const data = get(response, 'data.data', false)
                    setDisableSyncSwitch(data);
                }
                else {
                    reduxDispatch(SetErrorMessage(get(response, 'data.errors', '')));
                }
            }).catch(err => {
                reduxDispatch(SetErrorMessage(get(err, 'response', '')));
            });
    }, [])

    const inputChangedHandler = (e, name) => {
        if (name === 'schedule') {
            dispatch(
                {
                    type: 'changeSyncData',
                    payload: {
                        [name]: e.target.checked
                    }
                });
                setDisableSyncButton(e.target.checked);
            const scheduleConfigObj = {
                'appUrl': config.CONNECTOR_URL,
                'type': selectedMenu,
                'source': selectedSubMenu,
                'target': 'elasticsearch',
                'schedule': e.target.checked,
                'projectName': localStorage.getItem("orgName"),
                'requestType': 'scheduleDataSync',
                'connector': {}
            };
            let url = config.API_URL + `/lowNoCodeConnectorService`;
            botPostCalltoServer(url, scheduleConfigObj)
                .then(response => {
                    if (get(response, 'data.status.responseType', '') === "success") {
                        //dispatch({ type: 'saveDataLoading', payload: false }); 
                        reduxDispatch(SetSuccessMessage(JSON.parse(response.data.data).message+' succcessfully'));
                    }
                    else {
                        //dispatch({ type: 'saveDataLoading', payload: false });
                        reduxDispatch(SetErrorMessage(get(response, 'data.errors.0.source', '')));
                    }
                }).catch(err => {
                    //dispatch({ type: 'saveDataLoading', payload: false });
                    reduxDispatch(SetErrorMessage(get(err, 'response', '')));
                });
        } else {
            dispatch(
                {
                    type: 'changeSyncData',
                    payload: {
                        [name]: e.target.value
                    }
                });
        }
    };
    const saveDataSync = () => {
        
        const configObj = {
            'dataSourceType': selectedMenu,
            'dataSourceName': selectedSubMenu,
            'tenantId': localStorage.getItem("orgName"),
            'connector': JSON.stringify({
                "dataSync": state.syncData
            })
        };
        let url = config.CONNECTOR_URL + `/update-data-sync`;
        dispatch({ type: 'saveDataLoading', payload: true });
        botPostCalltoServer(url, configObj)
            .then(response => {
                if (get(response, 'data.status.responseType', '') === "success") {
                    dispatch({ type: 'saveDataLoading', payload: false });
                    reduxDispatch(SetSuccessMessage('updated Data Sync Successfully'));
                }
                else {
                    dispatch({ type: 'saveDataLoading', payload: false });
                    reduxDispatch(SetErrorMessage(get(response, 'data.errors.0.source', '')));
                }
            }).catch(err => {
                dispatch({ type: 'saveDataLoading', payload: false });
                reduxDispatch(SetErrorMessage(get(err, 'response', '')));
            });
    };

    const syncDataHandler = () => {
      
        const configObj = {
            'dataSourceType': selectedMenu,
            'dataSourceName': selectedSubMenu,
            'tenantId': localStorage.getItem("orgName"),
            'connector': ""
        };
        let url = config.CONNECTOR_URL + `/sync-tickets`;
        dispatch({ type: 'syncDataLoading', payload: true });
        botPostCalltoServer(url, configObj)
            .then(response => {
                if (get(response, 'data.status.responseType', '') === "success") {
                    reduxDispatch(SetSuccessMessage("Data Sync initiated successfully"));
                    dispatch({ type: 'syncDataLoading', payload: false });
                    setTimeout(() => {
                        reduxDispatch(getConnectionsData(config));
                    }, 1000)
                }
                else {
                    dispatch({ type: 'syncDataLoading', payload: false });
                    reduxDispatch(SetErrorMessage(get(response, 'data.errors.0.source', '')));
                }
            }).catch(err => {
                dispatch({ type: 'syncDataLoading', payload: false });
                reduxDispatch(SetErrorMessage(get(err, 'response', '')));
            });
    };
    return (
        <React.Fragment>
            <div className='integration-tabs-container'>
                {showConfigLoader ?
                    <Row className="justify-content-md-center padding10">
                        <Spinner animation="border" variant="primary" />
                    </Row> :
                    <React.Fragment>
                        <div className='box-container'>
                            <div className='flex-space-between'>
                                <div>
                                    {isEmpty(state.syncData.lastLocalSyncDate) ? null :
                                        <label>
                                            <i>{`Last synced on ${moment(state.syncData.lastLocalSyncDate).format('DD/MM/YYYY hh:mm:ss A')} `}</i>
                                        </label>
                                    }
                                </div>
                                <div>
                                    <Form>
                                        <Form.Check
                                            type="switch"
                                            id="SimilarIssuesCustomSwitch"
                                            checked={state.syncData.schedule}
                                            label="Sync the Data"
                                            disabled={disableSyncSwitch}
                                            onChange={e => inputChangedHandler(e, 'schedule')}
                                        />
                                    </Form>
                                </div>
                            </div>
                            <Row className='mt-10'>
                                <Col md={'3'}>
                                    <div className={`inputContainer`}>
                                        <Form.Label>
                                            Schedule Data Sync every
                                    </Form.Label>
                                        <Form.Control
                                            type={'number'}
                                            value={state.syncData.scheduleTime}
                                            onChange={(e) => inputChangedHandler(e, 'scheduleTime')}
                                        />
                                    </div>
                                </Col>
                                <Col md='3'>
                                    <div className={`inputContainer-without-border`}>
                                        <Input
                                            key="SelctDropDown"
                                            elementType="select"
                                            elementConfig={DataSyncSystemConfig.elementConfig}
                                            value={state.syncData.scheduleType}
                                            changed={(event) => inputChangedHandler(event, 'scheduleType')}
                                        />
                                    </div>
                                </Col>
                                <Col md={'3'}>
                                    <div className={`inputContainer`}>
                                        <Form.Label>
                                            Store Log For
                                    </Form.Label>
                                        <Form.Control
                                            type={'number'}
                                            name={'logDuration'}
                                            value={state.syncData.logDuration}
                                            onChange={(e) => inputChangedHandler(e, 'logDuration')}
                                        />
                                    </div>
                                </Col>
                                <Col md='3'>
                                    <div className={`inputContainer-without-border`}>
                                        <Input
                                            key="SelctDropDown"
                                            elementType="select"
                                            elementConfig={StoreLogConfig.elementConfig}
                                            value={state.syncData.storageType}
                                        //changed={(event) => inputChangedHandler(event, 'log')}
                                        />
                                    </div>
                                </Col>
                                <Col md='3'>
                                </Col>
                            </Row>
                            <div className='flex-space-between mt-10'>
                                <div>
                                    <Button
                                        variant="primary"
                                        className='large-border-radius mr-15'
                                        onClick={syncDataHandler}
                                        disabled={state.syncDataLoading || disableSyncButton}>
                                        {state.syncDataLoading ?
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            /> : null}
                                        Sync Now
                                    </Button>
                                    <Button
                                        variant="outline-primary"
                                        className='large-border-radius'
                                        onClick={handleViewSyncModal}>
                                        View Sync Log
                            </Button>
                                    {showViewSyncLogModal ?
                                        <ViewSyncLogModal
                                            showViewSyncLogModal={showViewSyncLogModal}
                                            hideViewSyncLogModal={hideViewSyncLogModal}
                                            selectedMenu={selectedMenu}
                                            selectedSubMenu={selectedSubMenu}
                                            config={config}
                                        />
                                        : null}
                                </div>
                                <Button variant="primary" className='large-border-radius'
                                    onClick={saveDataSync}
                                    disabled={state.saveDataLoading}
                                >
                                    {state.saveDataLoading ?
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        /> : null}
                                    Save

                         </Button>
                            </div>
                        </div>
                    </React.Fragment>
                }
            </div>
        </React.Fragment>
    )
}

DataSyncTab.propTypes = {
    DataSyncSystemConfig: PropTypes.object,
    StoreLogConfig: PropTypes.object,
};

DataSyncTab.defaultProps = {
    DataSyncSystemConfig: {
        elementType: 'select',
        elementConfig: {
            options: [
                { value: 'minutes', displayValue: 'Minutes' },
                { value: 'hours', displayValue: 'Hours' },
            ]
        },
        value: '',
        validation: {
            required: true
        },
        valid: false,
        touched: false,
        className: 'selectLang'
    },
    StoreLogConfig: {
        elementType: 'select',
        elementConfig: {
            options: [
                { value: 'days', displayValue: 'days' },
            ]
        },
        value: '',
        validation: {
            required: true
        },
        valid: false,
        touched: false,
        className: 'selectLang'
    }
};

export default DataSyncTab;