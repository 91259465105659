import React, { useState } from 'react';
import { Breadcrumb, Table, Spinner, Row } from 'react-bootstrap';
import get from 'lodash/get';
import './share-point.css';
import { useSelector, useDispatch } from 'react-redux';
import { getSharePointData, SetErrorMessage, SetSuccessMessage, updateSharePointBaseUrl } from '../../store/actions';
import { setrefresh } from '../../store/actions/projectview';
import { botPostCalltoServer } from '../../store/utility';
import ButtonComponent from '../../components/UIComponents/Button/ButtonComponent';
import SearchComponent from '../../components/UIComponents/SearchComponent/Search';

function ImportFiles({
    config,
    
    isFaq,
    siteName,
    siteId,
    handleClose,
 
    categoryName
}) {
    const files = useSelector(state => state.projectview.sharePointData.files);
    const [saveDataLoading, setSaveDataLoading] = useState(false);
    //const [refresh, setrefresh] = useState(false);
    const [disabledButton, setDisabledButton] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [show, setShow] = useState(true)
    var [selectedFiles, setSelectedFiles]=useState([])
    const folders = useSelector(state => state.projectview.sharePointData.folders);
    const sharePointDataLoading = useSelector(state => state.projectview.sharePointDataLoading);
    const sharePointBaseUrl = useSelector(state => state.projectview.sharePointBaseUrl);
    const dispatch = useDispatch();
    const navigationItems = sharePointBaseUrl.split('/');
    const getBaseUrlValue = (folderData, isFolder) => {
        if (isFolder) {
            return sharePointBaseUrl.concat('/' + folderData);
        }
        return navigationItems.slice(0, folderData + 1).join('/');
    }
    const getLatestSharePointData = (folderData, isFolder) => {
        const baseUrlValue = getBaseUrlValue(folderData, isFolder)
        const payload = {
            config,
            baseUrl: baseUrlValue,
            siteId:siteId,
            siteName:siteName,
        };
        dispatch(getSharePointData(payload));
        dispatch(updateSharePointBaseUrl(baseUrlValue));
    };

    const selectFiles = (event, selectedFile) => {
        let files = selectedFiles;
        let inputs = document.getElementById(selectedFile.id);
        if (inputs.checked == true) {
            files.push(selectedFile)
        }
        else {
            var lists = selectedFiles.filter(x => {
                return x.id != selectedFile.id;
            })
            files = lists;
        }
        setSelectedFiles(files);
        setDisabledButton(files.length?false:true)
    };
    const getDataFilesData = () => {
        const dataFileFormats = ["pdf", "docx", "doc", "txt", "xlsx", "xls", "csv", "html", "htm"];
        const dataFiles = selectedFiles.filter(item => dataFileFormats.includes(item.fileType));
        return dataFiles.map(dataFile => {
            return {
                fileName: dataFile.fileName,
                id: dataFile.id,
                lastModifiedTime: dataFile.lastModifiedTime,
                relativeUrl: dataFile.relativeUrl
            }

        })
    };

    const getMediaFilesData = () => {
        const dataFileFormats = ["pdf", "docx", "doc", "txt", "xlsx", "xls", "csv", "html", "htm"];
        const dataFiles = selectedFiles.filter(item => !dataFileFormats.includes(item.fileType));
        return dataFiles.map(dataFile => {
            return {
                fileName: dataFile.fileName,
                id: dataFile.id,
                lastModifiedTime: dataFile.lastModifiedTime,
                relativeUrl: dataFile.relativeUrl
            }

        })
    };

    const getMediaFilesMetaData = () => {
        const dataFileFormats = ["pdf", "docx", "doc", "txt", "xlsx", "xls", "csv", "html", "htm"];
        const dataFiles = selectedFiles.filter(item => !dataFileFormats.includes(item.fileType));
        //var n1 = document.getElementById("tableData").rows.length;
        // var n1 = selectedFiles.length
        var n1 = files.length
        return dataFiles.map(dataFile => {
            var desc,tag
            for(var i=0; i<n1;i++){
                var fileId = document.getElementById("tableData").rows[i].cells.item(5).innerHTML
                if(fileId == dataFile.id){
                    desc = document.getElementById("tableData").rows[i].cells.item(3).innerHTML
                    tag = document.getElementById("tableData").rows[i].cells.item(4).innerHTML
                }
            }
            return {
                fileName: dataFile.fileName,
                id: dataFile.id,
                description: desc,
                tags: tag
            }

        })
    };
   const onQuickFilterChanged = (event) => {
    setSearchText(event.target.value)

    if(event.target.value!=""){
        setShow(false)
      }
      else{
        setShow(true)
      }
    }

    const close = ()=>{
        handleClose()
    }

    const saveFiles = () => {
        dispatch(setrefresh(false))
        close()
        console.log("data");
        // const configObj1 = {
        //     'appUrl': config.CONNECTOR_URL,
        //     'type': 'knowledge_base',
        //     'source': 'sharepoint',
        //     'target': 'elasticsearch',
        //     'tenant': localStorage.getItem("orgName"),
        //     'isFaq':isFaq,
        //     'projectName': localStorage.getItem("projectname"),
        //     'requestType': 'SaveFiles',
        //     'connector': {
        //         dataFileRelativeUrls: getDataFilesData(),
        //         mediaFileRelativeUrls: getMediaFilesData(),
        //         mediaFilesMetaData: getMediaFilesMetaData()
        //     },
        // };
        // var data = getMediaFilesData()
        // if(data && data.length==0){
        const configObj = {           
            'datasourceType': 'knowledge_base',
            'dataSourceName': 'sharepoint',
            'target': 'elasticsearch',
            'tenantId': localStorage.getItem("orgName"),
            'isFaq':isFaq,
            'faq':isFaq,
            "siteId": siteId,
            "siteName": siteName,
            'projectName': localStorage.getItem("projectname"),
            "categoryName": categoryName,           
            'connector': JSON.stringify({
                dataFileRelativeUrls: getDataFilesData(),
                mediaFileRelativeUrls: getMediaFilesData(),
                mediaFilesMetaData: getMediaFilesMetaData()
            }),
        };
        
        let url = config.CONNECTOR_URL + `/save-files`;
        setSaveDataLoading(true);
        botPostCalltoServer(url, configObj)
            .then(response => {
                if (get(response, 'data.status.responseType', '') === "success") {
                    dispatch(SetSuccessMessage('Document(s) uploaded successfully from sharepoint.'));
                    setSaveDataLoading(false);
                    setTimeout(() => {
                        // close()
                        dispatch(setrefresh(true))
                        
                        //getLatestDataForNotSearchableFiles()
                        // ManageFileData(config,isFaq)
                    },1000)
                }
                else {
                    dispatch(SetErrorMessage('Uploading of document(s) from sharepoint failed.'));
                    setSaveDataLoading(false);
                    //dispatch(setrefresh(false))
                }
            }).catch(() => {
                dispatch(SetErrorMessage('Uploading of document(s) from sharepoint failed.'));
                setSaveDataLoading(false);
                //dispatch(setrefresh(false))
            });
        // }
        // else{
        //     dispatch(SetErrorMessage('Multimedia file(s) not allowed.'));
        // }
    }
    const clearSearchText = ()=>{
        setSearchText('');
        setShow(true);
      }

      let filterSearchValue = files.length>0 && files.filter((item) => {
        return item.fileName.toLowerCase().includes(searchText.toLowerCase()) 
         })

    return (
        <React.Fragment>
            {sharePointDataLoading ?
                <Row className="justify-content-md-center padding10">
                    <Spinner animation="border" variant="primary" />
                </Row> :
                <React.Fragment>
                    {files.length || folders.length ?
                        <React.Fragment>
                            <div className='d-flex justify-content-between align-items-end'>
                                <div>
                                <h4 className="RobotoBold"><span className='files-txt'>{filterSearchValue.length} {filterSearchValue.length==1 ?"file":"files"}</span> available.</h4>
                                </div>
                                <div className='search-div'>
                                <SearchComponent 
                                class={"input-group searchClass"}
                                value={searchText} 
                                onSearch={(event) => {onQuickFilterChanged(event)}}
                                Clear={clearSearchText}
                                show={show}

                                />
                                </div>
                            </div>
                            <Breadcrumb style={{marginTop:"5px"}}>
                                {files.length || folders.length && navigationItems.length ?
                                    navigationItems.map((navigationItem, index) => {
                                        return (
                                            <Breadcrumb.Item href="#" onClick={() => getLatestSharePointData(index, false)}>{navigationItem}</Breadcrumb.Item>
                                        )
                                    })
                                    : null}
                            </Breadcrumb>
                            <Table striped bordered hover id="tableData">
                                <thead>
                                    <th>FILE NAME</th>
                                    <th>FORMAT</th>
                                    <th>DATE</th>
                                    {/* <th>keywords</th> */}
                                    <th>DESCRIPTION</th>
                                    <th>KEYWORDS</th>
                                    <th style={{ display: "none" }}>ID</th>
                                </thead>
                                <tbody>
                                    
                                    {files.length ? files.filter((item) => {
                                        return item.fileName.toLowerCase().includes(searchText.toLowerCase()) 
                                    }).map(file => {
                                        return (
                                            <tr>
                                                <td style={{ width: "35%" }}>
                                                    <div className='check-box'>
                                                        <input type='checkbox' id={file.id} onChange={(e) => selectFiles(e, file)} variant='primary' />
                                                    </div>
                                                    {file.fileName}
                                                </td>
                                                <td style={{ width: "10%" }}>{file.fileType}</td>
                                                {/* <td style={{width:"20%"}}>{file.lastModifiedTime}</td> */}
                                                <td contenteditable='true' style={{ maxWidth: "250px", width: "35%" }}>{file.lastModifiedTime}</td>
                                                {/* <td contenteditable='true' style={{width: "10%" }}></td> */}
                                                <td contenteditable='true' style={{ maxWidth: "250px", width: "35%" }}></td>
                                                <td contenteditable='true' style={{ maxWidth: "250px", width: "20%" }}></td>
                                                <td style={{ display: "none" }}>{file.id}</td>
                                            </tr>
                                        )
                                    }) : null}
                                    {folders.length ? folders.map(folder => {
                                        return (
                                            <tr>
                                                <td><a className='folder-link' onClick={() => getLatestSharePointData(folder.name, true)}>{folder.name}</a></td>
                                                <td>Folder</td>
                                                <td>{folder.lastModifiedTime}</td>
                                            </tr>
                                        )
                                    }) : null}
                                </tbody>
                            </Table>
                            <ButtonComponent
                                className='buttonWidth sharepoint-submit-btn'
                                label={'Save'}
                                clicked={saveFiles}
                                btnType="blueButton"
                                disabled={disabledButton}
                            />
                        </React.Fragment>
                        : <span class="multiDocmessage">No documents available to load from the base folder of the selected site.</span>}
                </React.Fragment>
            }
        </React.Fragment>
    )
}

ImportFiles.propTypes = {};

ImportFiles.defaultProps = {};

export default ImportFiles;