// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inputR {
    appearance: none;
    width: 100%;
    height: 5px;
    opacity: 1;
    outline: none;
    margin: 0 auto;
    }

.inputR::-webkit-slider-thumb {
appearance: none;
-webkit-appearance: none;
background: #0080ff;
height: 15px;
width: 15px;
border-radius: 50%;
}
.Slider{width: 96%;}
.ticks {
    display: flex;
    justify-content: space-between;
    
   
    padding: 10px 20px;
  }
  .tick {
    position: relative;
    display: flex;
    justify-content: center;
    width: 1px;
    background: gray;
    
    
    height: 10px;
    line-height: 50px;
    margin-bottom: 20px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/UI/Slider/Slider.css"],"names":[],"mappings":"AAAA;IAEI,gBAAgB;IAChB,WAAW;IACX,WAAW;IACX,UAAU;IACV,aAAa;IACb,cAAc;IACd;;AAEJ;AACA,gBAAgB;AAChB,wBAAwB;AACxB,mBAAmB;AACnB,YAAY;AACZ,WAAW;AACX,kBAAkB;AAClB;AACA,QAAQ,UAAU,CAAC;AACnB;IACI,aAAa;IACb,8BAA8B;;;IAG9B,kBAAkB;EACpB;EACA;IACE,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,UAAU;IACV,gBAAgB;;;IAGhB,YAAY;IACZ,iBAAiB;IACjB,mBAAmB;EACrB","sourcesContent":[".inputR {\n    -webkit-appearance: none;\n    appearance: none;\n    width: 100%;\n    height: 5px;\n    opacity: 1;\n    outline: none;\n    margin: 0 auto;\n    }\n\n.inputR::-webkit-slider-thumb {\nappearance: none;\n-webkit-appearance: none;\nbackground: #0080ff;\nheight: 15px;\nwidth: 15px;\nborder-radius: 50%;\n}\n.Slider{width: 96%;}\n.ticks {\n    display: flex;\n    justify-content: space-between;\n    \n   \n    padding: 10px 20px;\n  }\n  .tick {\n    position: relative;\n    display: flex;\n    justify-content: center;\n    width: 1px;\n    background: gray;\n    \n    \n    height: 10px;\n    line-height: 50px;\n    margin-bottom: 20px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
