import React, { Component } from 'react';
import { Row, Col, Container } from "react-bootstrap";
import CrmCustomerDataDrawer from './CrmCustomerDataDrawer';
import './CrmLayout.css';
import Card from 'react-bootstrap/Card';
import Drawer from "@material-ui/core/Drawer";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import axios from 'axios';
import * as actions from '../../../../../store/actions/index';
import { connect } from 'react-redux';
import ButtonComponent from '../../../../../components/UIComponents/Button/ButtonComponent';
import ReactMultiSelectComponent from '../../IntegrationTabs/ReactMultiSelect/ReactSelectComponent';
import {decrypt} from '../../../../../utility/utility';
class CrmLayout extends React.Component {
  state = {
    description: "Description",
    heading2: "Fields",
    FieldsData: [],
    fielddata: [],
    showDrawer: false,
    selectedValues: [],
    selectData: ["Name", "Mobile No", "Email ID"],
    customer_data: [],
    customer_details: ["Close Description"],
    product_details: ["product", "yom", "model"],
    options: [],
    prevSelectedField: [],
    selectedField: [],
    allFields: [],
    data: [],
    dropdownVal: '',
    selectedValue: ''
  }
  componentDidMount() {
    this.props.onGetFieldsData()
    if (this.props.FieldsData) {
      this.setState({
        customer_data: this.props.FieldsData.customer_data,
        customer_details: this.props.FieldsData.customer_details,
        product_details: this.props.FieldsData.product_details
      })
    }
    let orgname = localStorage.getItem('orgName')
    let url = `https://sandbox.api.saas.intelliservice.ai/dev/product-intelligent-triage/settings/fields/crmdata_intelliservices.onmicrosoft`;
    this.GetCalltoServer(url).then(response => {
      if (response.data.status.responseType == "success") {
        this.setState({
          data: response.data.data
        })
      }
    }).catch(err => {
      console.log("error", err);
    });
  }
  componentDidUpdate(prevProps, prevState) {
    console.log(this.state.showDrawer)
    if (prevProps.FieldsData != this.props.FieldsData) {
      this.setState({
        customer_data: this.props.FieldsData.customer_data,
        customer_details: this.props.FieldsData.customer_details,
        product_details: this.props.FieldsData.product_details
      })
    }
  }
  onSetShowDrawer = (value, name) => {
    this.setState({
      showDrawer: true,
      fielddata: value
    })
    if (name == 'customer_data') {
      this.setState({
        selectedValue: 'customer_data'
      })
    }
    if (name == 'customer_details') {
      this.setState({
        selectedValue: 'customer_details'
      })
    }
    if (name == 'product_details') {
      this.setState({
        selectedValue: 'product_details'
      })
    }
  }
  onSetShowDrawer2 = (data) => {
    this.setState({
      showDrawer: false,
      FieldsData: data,
    })
  }
  onSelecField = (event) => {
    var temp = [];
    for (var i = 0; i < event.length; i++) {
      temp.push(event[i].value)
    }
    this.setState({
      prevSelectedField: this.state.selectedField,
      selectedField: temp
    })
  }
  toggleDrawer = (value) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    this.setState({
      showDrawer: false,
    })
  };
  GetCalltoServer = (Url) => {
    let token = localStorage.getItem("accessToken");
    // let token = decrypt("newAccessToken");
    return axios.get(Url, {
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
  }
  submitHandler = () => {
    this.props.onsaveFields(this.state.selectedField, this.state.selectedValue);
    setTimeout(() => {
      this.props.onGetFieldsData()
    }, 1000);
    if (this.props.FieldsData) {
      this.setState({
        FieldsData: this.props.FieldsData
      })
    }
    this.setState({
      showDrawer: false,
      selectedField: []
    })
  }
  onRemoveFields = (item) => {
    var temp = [...this.state.selectedField]
    var index = temp.indexOf(item)
    temp.splice(index, 1)
    this.setState({ selectedField: temp })
    if (temp.length > 0) {
      this.props.onsaveFields(temp, this.state.selectedValue)
    }
  }
  render() {
    return (
      <>
        <Container >
          <Row>
            <h3 className='pageTitleText'>CRM Layout</h3>
          </Row>
          <Card className='mainCard'>
            <Card.Body onClick={() => this.onSetShowDrawer(this.state.customer_data, 'customer_data')} style={{ cursor: "pointer" }}>
              <Row>
                <Col>
                  <Card.Title className='cardTitle'>Customer Data for Agent Intelligent Triage</Card.Title>
                </Col>
                <Col>
                  <Card.Title className='cardTitle' style={{ position: "relative", left: "-45px" }}>{this.state.heading2}</Card.Title>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card.Text className='cardDescription'>
                    {this.state.description}
                  </Card.Text>
                </Col>
                <Col style={{ float: "left" }}>
                  {this.state.customer_data.map((item, index) => (
                    <Card.Text className='cardDescription'>
                      {(index ? ', ' : '') + item}
                    </Card.Text>
                  ))}
                </Col>
                <Col xs={1}><img src="./images/Vector.svg" /></Col>
              </Row>
            </Card.Body>
          </Card>
          <Card className='mainCard'>
            <Card.Body onClick={() => this.onSetShowDrawer(this.state.customer_details, 'customer_details')} style={{ cursor: "pointer" }}>
              <Row>
                <Col>
                  <Card.Title className='cardTitle'>Customer Products Table view</Card.Title>
                </Col>
                <Col>
                  <Card.Title className='cardTitle' style={{ position: "relative", left: "-45px" }}>{this.state.heading2}</Card.Title>

                </Col>
              </Row>
              <Row>
                <Col>
                  <Card.Text className='cardDescription'>
                    {this.state.description}
                  </Card.Text>
                </Col>
                <Col style={{ float: "left" }}>
                  {this.state.customer_details.map((item, index) => (
                    <Card.Text className='cardDescription'>
                      {(index ? ', ' : '') + item}
                    </Card.Text>
                  ))}
                </Col>
                <Col xs={1}><img src="./images/Vector.svg" /></Col>
              </Row>
            </Card.Body>
          </Card>
          <Card className='mainCard'>
            <Card.Body onClick={() => this.onSetShowDrawer(this.state.product_details, 'product_details')} style={{ cursor: "pointer" }}>
              <Row>
                <Col>
                  <Card.Title className='cardTitle'>Customer Details used for Intelligent Triage</Card.Title>
                </Col>
                <Col>
                  <Card.Title className='cardTitle' style={{ position: "relative", left: "-45px" }}>{this.state.heading2}</Card.Title>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card.Text className='cardDescription'>
                    {this.state.description}
                  </Card.Text>
                </Col>
                <Col style={{ float: "left" }}>
                  {this.state.product_details.map((item, index) => (
                    <Card.Text className='cardDescription'>
                      {(index ? ', ' : '') + item}
                    </Card.Text>
                  ))}
                </Col>
                <Col xs={1}><img src="./images/Vector.svg" /></Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
        <Drawer
          anchor={"right"}
          open={this.state.showDrawer}
          onClose={this.toggleDrawer(false)}
        >
          {this.state.showDrawer == true ?
            <div className="d-flex pt-4 pl-2 maindiv">
              <div className='d-flex product-data'>
                <CloseIcon
                  className="d-flex product-img "
                  onClick={this.toggleDrawer(false)}
                  style={{ fontSize: 25, marginLeft: "-3px" }}
                />
                <div className="d-flex pl-1" md={6}>
                  <h3>Configure Customer Data for Intelligent Triage</h3>

                </div>
              </div>
              <div className='d-flex pl-3'>
                <ReactMultiSelectComponent
                  label="Choose fields for Customer Data for Agent Intelligent Triage"
                  onChange={(event) => this.onSelecField(event)}
                  value={this.state.selectedField}
                  options={this.state.data.map(item => (
                    { value: item, label: item }))}
                  onRemoveItem={this.onRemoveFields}
                  placeholder={'Select field'}
                />
              </div>
              <div className='d-flex pl-1 ButtonComponent '>
                <ButtonComponent
                  variant='contained'
                  label='Save'
                  className='buttonWidth mb-2'
                  clicked={this.submitHandler}
                />
              </div>
            </div>
            : null}
        </Drawer>
      </>
    )
  }
}
const mapStateToProps = state => {
  console.log(state.CrmLayout, 'sddsgdgf')
  return {
    FieldsData: state.CrmLayout.FieldsData1
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onGetFieldsData: () => dispatch(actions.GetFieldsData()),
    onsaveFields: (selectedField, selectedValue) => dispatch(actions.saveFields(selectedField, selectedValue)),

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CrmLayout);
