import axios from 'axios';
import * as kc from './store/actions/keycloak.js';
import * as actions from './store/actions/index';
import { encrypt, encryptAndStore } from './utility/utility.js';

const instance = axios.create({
   // baseURL: 'http://localhost:80/'
});


instance.interceptors.response.use(
   (res) => {
     return res;
   },
   async (err) => {
     const originalConfig = err.config;
 
     if (err.response) {
       // Access Token was expired
       if (err.response.status === 401 && !originalConfig._retry) {
         originalConfig._retry = true;
 
         try {
         await kc.keycloak.updateToken(30)
         .then(function(refreshed) {
             if (refreshed) {
                //  localStorage.setItem('accessToken', kc.keycloak.token);
                //  localStorage.setItem('refreshToken', kc.keycloak.refreshToken); 
                encryptAndStore('accessToken', kc.keycloak.token);                
                encryptAndStore('refreshToken', kc.keycloak.refreshToken); 
             } else {
                 console.log('Token is still valid');
             }
             originalConfig.headers['Authorization'] = 'Bearer' + kc.keycloak.token;
             
         }).catch(function() {
          
             console.log('Failed to refresh the token, or the session has expired');
             console.log('calling dispatch logout');
             actions.logout();
         });
         
         return instance(originalConfig);
         } catch (_error) {
          console.log('calling dispatch logout');
          actions.logout();
         }
       }
     }
 
     return Promise.reject(err);
   }
 );


export default instance;