import React from 'react';
const TableHeader = ( props ) => {
let TableHaderData=null;
TableHaderData=<thead>
          <tr>{props.columnHeader.map((value, index) => {
      return <th key={index}>{value}</th>;
    })}</tr>
         </thead>  
         return (
             <>{TableHaderData}</>
          
    );
         };
export default TableHeader;