export const defaultJSON = {
    header: {
        headerText: "HCL Virtual Assistant",
        headerBgColor: "#FFFFFF",
        headerTextColor: "#000000",
        subheaderText: "Your Personal Assistant",
        subheaderTextColor: "#000000",
        closeIconBgColor: "#FA2834",
        closeIconColor: "#FFFFFF",
        minimizeIconBgColor: "#FABF28",
        minimizeIconColor: "#FFFFFF",
        maximizeIconEnable: false,
        maximizeIconBgColor: "#2CBF44",
        maximizeIconColor: "#FFFFFF",
        botImg:'',
        botImgEnabled:true
    },
    canvas: {
        userText: {
            responseBgColor: "#1D74F5",
            responseTextColor: "#FFFFFF",
            responseBorderColor: "#1D74F5"
        },
        botResponse: {
            canvasBgColor: "#FFFFFF",
            responseBgColor: "#F1EBEB",
            responseTextColor: "#000000",
            responseBorderColor: "#F1EBEB",
            buttonBgColor: "#FFFFFF",
            buttonTextColor: "#504D4D",
            buttonBorderColor: "#9E9E9E",
            buttonHoverBgColor: "#2A93DF",
            buttonHoverTextColor: "#FFFFFF",
            buttonHoverBorderColor: "#2A93DF",
            timeStampTextColor: "#292121",
            buttonBehaviour: "disappear"
        }
    },
    footer: {
        backToHome: {
            isVisible: true,
            text: "Back To Home",
            textColor: "#2945FF",
            textFamily: "Roboto",
            textStyle: "Regular",
            textSize: "10px",
            flowQuery: ""
        },
        menuOptions: {
            isMenuVisible: false,
            isBannerVisible: false,
            bgColor: "#FFFFFF",
            closeIconColor:"#FA2834",
            menuItems:[
                {text:"", routeType:"Flow", routeData:"", textColor:"", textSize:"12px", subMenu:[]}
            ]
        },
        userInput: {
            text: "Ask your question here..",
            inputBgColor: "#FFFFFF",
            inputTextColor: "#000000",
            inputBorderColor: "#000000",
            fontFamily: "Roboto",
            fontSize: "16px",
            isLangVisible: false,
            languageList:[{code:"en",label:"English"}],
            langBgColor:"#F1EBEB",
            iconColor:"#504D4D",
            sendIconColor:"#0CB027",
            sendIconDisabledColor:"#C8CDD0"
        },
        poweredByTextColor: "#000000",
        poweredByText: "Powered by HCL intelliService",
        displayMenu: false
    },
    personality: {
        autoPopup: {
            isEnable: true,
            timeout:5
        },
        greeting: [{langCode:'en',flowQuery:''}],
        agent: {
            widgetIcon:""
        },
        engagement: {
            isEnable: false,
            message: "",
            bgColor: "#FFFFFF",
            textColor:"#000000",
            borderColor:"#F1F1F1",
            fontFamily: "Roboto",
            fontSize: "15px",
            fontStyle: "Regular",
            closeBgColor: "#2B2B2B",
            closeIconColor: "#FFFFFF"
        }
    },
    agent: {
        IconTextColor: "#FFFFFF",
        IconbackgroundColor: "#006BB6",
        MsgOn: false,
        engagementMsg: "",
        engagementMsgBG: "#dfdfdf",
        engagementMsgTC: "#212021",
        engagementMsgBC: "#dfdfdf"
    }
}