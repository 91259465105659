import { PostCalltoServer } from '../../../utility';
import { SetErrorMessage, SetSuccessMessage } from '../../message';
import saveSimilarIssuesUnifiedLoginEsDataFailure from './saveInsightsUnifiedLoginEsDataFailure';
import saveSimilarIssuesUnifiedLoginEsDataStart from './saveInsightsUnifiedLoginEsDataStart';
import saveSimilarIssuesUnifiedLoginEsDataSuccess from './saveInsightsUnifiedLoginEsDataSuccess';

const getTabData = (state, tabName) => {
    if(tabName === 'similartickets') {
        return {
            similartickets: state.insights.similartickets
        }
    }
    if(tabName === 'kbdocs') {
        return {
            kbdocs: state.insights.kbdocs
        }
    }
    if(tabName === 'issueclassification') {
        return {
            issueclassification: state.insights.issueclassification
        }
    }
    if(tabName === 'topresolutions') {
        return {
            topresolutions: state.insights.topresolutions
        }
    }
    if(tabName === 'predictedissues') {
        return {
            predictedissues: state.insights.predictedissues
        }
    }
    if(tabName === 'questionaries') {
        return {
            questionaries: state.insights.questionaries
        }
    }
    if(tabName === 'knowledgeArticles') {
        return {
           knowledgeArticles : state.insights.knowledgeArticles
        }
    }
    return {};
} 

const saveInsightsUnifiedLoginEsDataAction = (payload) => (dispatch, getState) => {
    const state = getState();
    
    
    

    const updatedSimilarTicketsdata = getTabData(state, payload.tabName);
    
    let project = localStorage.getItem('projectname');
    const url = payload.apiUrl + `/product-intelligent-triage/settings/insightsconfiguration/project/`+project;
    dispatch(saveSimilarIssuesUnifiedLoginEsDataStart());
    PostCalltoServer(url, updatedSimilarTicketsdata)
        .then(response => {
			        
			try{
				if(response.data.status.responseType == "success"){
					dispatch(saveSimilarIssuesUnifiedLoginEsDataSuccess(response.data.data));
					dispatch(SetSuccessMessage('Insights settings updated successfully.'));
				}       
				else{
					dispatch(saveSimilarIssuesUnifiedLoginEsDataFailure(response.data.errors))
					dispatch(SetErrorMessage('Error in update insights settings !!!'));
				} 
			}catch(err){
				dispatch(SetErrorMessage('Error in update insights settings !!'));
			}			
			
        });
};

export default saveInsightsUnifiedLoginEsDataAction;