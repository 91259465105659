import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Input from '../../../../components/UI/Input/InputCustom';
import get from 'lodash/get';
import { useDispatch } from 'react-redux';
import { SetErrorMessage, SetSuccessMessage } from '../../../../store/actions';
import { botPostCalltoServer } from '../../../../store/utility';

function AddCollectionModal({ config, selectedMenu, selectedSubMenu, updateCollection }) {
    const [show, setShow] = useState(false);
    const [collection, setCollection] = useState('');
    const dispatch = useDispatch();


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const addCollection = () => {
        
        const configObj = {}
     
        let url = config.CONNECTOR_URL + `/create-index/${collection}`;
        botPostCalltoServer(url, configObj)
            .then(response => {
                if (get(response, 'data.status.responseType', '') === "success") {
                    if(response.data.data.indexOf("Index already exist") == -1){
                        updateCollection(collection)
                        dispatch(SetSuccessMessage('Added New Collection Successfully'))
                    }
                    else{
                        dispatch(SetErrorMessage(response.data.data));
                    }
                    
                }
                else {
                    dispatch(SetErrorMessage(get(response, 'data.errors.0.source', '')));
                }
            }).catch(err => {
                dispatch(SetErrorMessage(get(err, 'response', '')));
            });
        setShow(false);
    };
    return (
        <React.Fragment>
            <img className='collection-icon add-icon' title='Create Collection/Index' onClick={handleShow} src="./Icons/AddCollection.svg" />
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add New Collection</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Input
                        key="editCollection"
                        elementType="text"
                        placeholder='IAdd New Collection ID'
                        value={collection}
                        name='editCollection'
                        changed={(event) => setCollection(event.target.value)}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={addCollection}>
                        Add
            </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
            </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
}

export default AddCollectionModal;