import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import InputWithLabelComponent from "../../../../../../containers/SettingPage/SettingInnerPages/components/InputWithLabelComponent/InputWithLabelComponent";


const Calender = ({properties, onChangeHandler, invalidVarMsg}) => {

    return (
        <div>
            <div className="mt-1 settings-div">
                <Col md={12} >
                    <Row className='settings-form-switch'>
                        <Col md={6}><span className='settings-label'>Required</span></Col>
                        <Col md={6} className='settings-switch'>
                        <Form>
                                <Form.Check
                                    type="switch"
                                    id="isRequired"
                                    checked={properties.isRequired}
                                    onChange={e => onChangeHandler('isRequired',e.target.checked)}
                                />
                            </Form>
                        </Col>
                    </Row>
                </Col>
            </div>
            <div className="mt-1 settings-div">
                <Col md={12}>
                <InputWithLabelComponent
                        type="text"
                        name={'settings-id'}
                        label="Name*"
                        showLabel={true}
                        value={properties.id}
                        readOnly={false}
                        onChange={e => onChangeHandler('id',e.target.value)}
                        required={true}
                        className="settings-input"
                        isInvalid={invalidVarMsg!==null && invalidVarMsg!==undefined}
                        error={invalidVarMsg}
                        toolTip={true}
                        toolTipParams={{title:'Name will also be used as variables',placement:'right'}}
                  />
                </Col>
            </div>
            <div className="mt-1 settings-div">
                <Col md={12}>
                <InputWithLabelComponent
                        type="text"
                        name={'settings-label'}
                        label="Label*"
                        showLabel={true}
                        value={properties.label}
                        readOnly={false}
                        onChange={e => onChangeHandler('label',e.target.value)}
                        required={true}
                        className="settings-input"
                  />
                </Col>
            </div>
            <div className="mt-1 settings-div">
                <Col md={12}>
                    <InputWithLabelComponent
                        type="text"
                        name={'settings-placeholder'}
                        label="Placeholder"
                        showLabel={true}
                        value={properties.placeholder}
                        readOnly={false}
                        onChange={e => onChangeHandler('placeholder',e.target.value)}
                        required={true}
                        className="settings-input"
                    />
                </Col>
            </div>
            <div className="mt-1 settings-div">
                <Col md={12}>
                    <InputWithLabelComponent
                        type="text"
                        name={'settings-default'}
                        label="Default"
                        showLabel={true}
                        value={properties.value}
                        readOnly={false}
                        onChange={e => onChangeHandler('value',e.target.value)}
                        required={true}
                        className="settings-input"
                        placeHolder="yyyy-mm-dd"
                    />
                </Col>
            </div>
            <div className="mt-1 settings-div">
                <Col md={12}>
                    <InputWithLabelComponent
                        type="text"
                        name={'settings-default'}
                        label="Error Message"
                        showLabel={true}
                        value={properties.errorMessage}
                        readOnly={false}
                        onChange={e => onChangeHandler('errorMessage',e.target.value)}
                        required={true}
                        className="settings-input"
                    />
                </Col>
            </div>
            
        </div>
    )
}

export default Calender;