// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainCard  {
/* display: flex;
justify-content: space-between;
position: relative;
bottom: 10px; */
/* padding-bottom: 20px; */
margin-bottom: 20px;
border-radius: 5px;
}
.cardTitle {
font-family: Roboto-Regular;
font-size: 14px;
line-height: 16px;
font-weight: bold;
}
.cardDescription {
font-family: Roboto-Regular;
font-size: 12px;
line-height: 16px;
font-weight: 300;
float: left;
}`, "",{"version":3,"sources":["webpack://./src/containers/SettingPage/SettingInnerPages/AgentCockpit/CrmLayout/CrmLayout.css"],"names":[],"mappings":"AAAA;AACA;;;eAGe;AACf,0BAA0B;AAC1B,mBAAmB;AACnB,kBAAkB;AAClB;AACA;AACA,2BAA2B;AAC3B,eAAe;AACf,iBAAiB;AACjB,iBAAiB;AACjB;AACA;AACA,2BAA2B;AAC3B,eAAe;AACf,iBAAiB;AACjB,gBAAgB;AAChB,WAAW;AACX","sourcesContent":[".mainCard  {\n/* display: flex;\njustify-content: space-between;\nposition: relative;\nbottom: 10px; */\n/* padding-bottom: 20px; */\nmargin-bottom: 20px;\nborder-radius: 5px;\n}\n.cardTitle {\nfont-family: Roboto-Regular;\nfont-size: 14px;\nline-height: 16px;\nfont-weight: bold;\n}\n.cardDescription {\nfont-family: Roboto-Regular;\nfont-size: 12px;\nline-height: 16px;\nfont-weight: 300;\nfloat: left;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
