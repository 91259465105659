import React, { useRef } from 'react';
import { Row, Button } from 'react-bootstrap';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import * as actions from '../../../store/actions/index';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { connect } from 'react-redux';
import moment from 'moment';
import SelectCustom from "../../../components/UIComponents/SelectDropdown/SelectComponent";
import DatetimeRangePicker from '../../../components/UI/DatetimeRangePicker';
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import './UserDescription.css';
import ChartDataLabels from "chartjs-plugin-datalabels";
import HorizontalBarChart from '../../../components/UI/Charts/HorizontalBarChart';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ToolTipComponent from '../../../components/UIComponents/ToolTip/ToolTip';
import classes from '../../../components/Navigation/NavigationItems/NavigationItems.module.css';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Line } from 'react-chartjs-2';
import MetricCard from './MetricCard';
import { number } from 'prop-types';
import SpinnerPage from '../../../components/UI/Loader/Loader';
import Dropdown from 'react-bootstrap/Dropdown';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

ChartJS.defaults.borderColor = "#b8b6b6";
//const chartRef = useRef<'bar'>(null);
const allProj = 'All VA Projects';
class ConversationHandled extends React.Component {


  constructor(props) {
    super(props);
    this.chartReference = React.createRef(null);
    this.chartObject = null;
    let projectName = "All VA Projects"
    let vaProject = "All"
    let reportDetail = this.props.reportDetail
    let startDate = this.props.startDate ? this.props.startDate : moment().subtract(6, 'days');
    let endDate = this.props.endDate ? this.props.endDate : moment();
    if (reportDetail != null) {
      startDate = moment(reportDetail.startDate)
      endDate = moment(reportDetail.endDate)
      projectName = reportDetail.project
      vaProject = reportDetail.virtualAssist
    }
    this.state = {
      selectedCal: '',
      projectname: projectName,
      vaName: vaProject,
      searchT: "",
      popperShow: false,
      botname: '',
      startDate: startDate,
      endDate: endDate,
      metricsOptions: [
        { value: "User Metrics - Total Users", key: "totaluser" },
        { value: "User Metrics - New Users", key: "newuser" },
        { value: "User Metrics - Repeat Users", key: "repeatuser" },
        { value: "Conversation Metrics - Chat Sessions", key: "chat" },
        { value: "Conversation Metrics - Flow Initiated", key: "flows" },
        { value: "Conversation Metrics - Feedback", key: "feedback" },
        { value: "Conversation Metrics - Agent Handovers", key: "agent" },
        { value: "Conversation Metrics - Unanswered", key: "unanswered" },
        { value: "Conversation Metrics - Meetings Scheduled", key: "meetings" }
      ],
      // selectedMetrics: (location && location[1]) ? location[1] : '',
      SelectedFlowList: [],
      SelectedCumulative: 'cumulative',
      //chartRefff: currChart
      topcatHeading: 'Payments',
      topUnansHeading: '',
      ansMsg: 0,
      unansMsg: 0,
      totalMsg: 0,
      unUnsTitle: [],
      unUnsVals: [],
      totalUnansCount: 0
    }
  }
  calAPI(val) {
    let apiURL = this.props.config.BOTBUILDER_URL;
    let details = this.props.reportDetail;
    let projName = this.state.projectname ? this.state.projectname : allProj
    if (details == null) {
      details = {
        "startDate": moment(this.state.startDate).format('YYYY-MM-DD'),
        "endDate": moment(this.state.endDate).format('YYYY-MM-DD'),
        "project": projName == 'common' ? allProj : projName,
        "orgName": localStorage.getItem('orgName'),
        "virtualAssist": this.state.vaName
      }
    }

    this.props.conversationalMetricsData(apiURL, details)
    this.props.conversationalHandled(apiURL, details);
    this.props.conversationMsgUtterances(this.props.config.BOTBUILDER_URL, details);
    this.props.conversationUnansweredUtterances(apiURL, details)

  }
  componentDidMount() {
    // let location = this.props.location.search;
    let details = this.props.reportDetail;
    this.calAPI()


  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.reportDetail !== this.props.reportDetail) {

      // let location = this.props.location.search;
      this.calAPI()
    }
    if (this.props.conHandledData) {
      let msgCategoryRes = this.props.conHandledData?.messageCategories
      if (prevProps.conHandledData !=  this.props.conHandledData) {
        if(this.props.conHandledData?.messageCategories){
          let sortable = []
          for (let [key, value] of Object.entries(msgCategoryRes)) {
            sortable.push([key, value]);
          }
          sortable = sortable.sort((a, b) => b[1] - a[1]);
          let msgCatTitle = sortable.length != 0 ? sortable[0][0] : []
          this.setState({ topcatHeading: msgCatTitle })
        }  
      }
      let unAnsCategoryRes = this.props.conHandledData?.unansweredCategories
      if (prevProps.conHandledData != this.props.conHandledData) {
        if (this.props.conHandledData?.unansweredCategories) {
          let sortable = []
          for (let [key, value] of Object.entries(unAnsCategoryRes)) {
            sortable.push([key, value]);
          }
          sortable = sortable.sort((a, b) => b[1] - a[1]);
          let unansCatTitle = sortable.length != 0 ? sortable[0][0] : []
          this.setState({ topUnansHeading: unansCatTitle })
        }

      }
    }
  }

  setSearchTerm = (item) => {
    this.setState({ searchT: item })
  }


  popperShowFn = () => {
    this.setState({
      popperShow: true,
    })
  }


  backToReportPage = () => {
    this.props.history.push('/ConversationMetrics?back')
  }

  handleCalenderChange = (e, val) => {
    this.setState({ selectedCal: val })
  }
  onProjectDropDown = (event) => {
    this.setState({
      selectedFlow: event.target.value,
    })
    let projName = localStorage.getItem('projectname')
    let data = {
      "startDate": moment(this.props.reportDetail.startDate).utcOffset("+05:30").format('YYYY-MM-DD'),
      "endDate": moment(this.props.reportDetail.endDate).utcOffset("+05:30").format('YYYY-MM-DD'),
      "project": projName == 'common' ? allProj : projName,
      "orgName": localStorage.getItem('orgName'),
    }
    this.props.ReportPageData(data)

  }

  topUnansCatEvent = (evt, element, msg) => {
    if (element.length > 0) {

      let msgDetails = this.props.reportDetail;
      let projName = this.state.projectname
      if (msgDetails == null) {
        msgDetails = {
          "startDate": moment(this.state.startDate).format('YYYY-MM-DD'),
          "endDate": moment(this.state.endDate).format('YYYY-MM-DD'),
          "project": projName == 'common' ? allProj : projName,
          "orgName": localStorage.getItem('orgName'),
          "virtualAssist": this.state.vaName
        }
      }
      msgDetails.message = msg.trim()
      //this.props.conversationUnansweredUtterances(this.props.config.BOTBUILDER_URL,msgDetails)
      this.setState({
        topUnansHeading: msg
      })
    }
  }

  topMsgCatEvent = (evt, element, msg) => {
    if (element.length > 0) {
      let msgDetails = this.props.reportDetail;
      let projName = this.state.projectname
      if (msgDetails == null) {
        msgDetails = {
          "startDate": moment(this.state.startDate).format('YYYY-MM-DD'),
          "endDate": moment(this.state.endDate).format('YYYY-MM-DD'),
          "project": projName == 'common' ? allProj : projName,
          "orgName": localStorage.getItem('orgName'),
          "virtualAssist": this.state.vaName
        }
      }
      msgDetails.message = msg.trim()
      //this.props.conversationMsgUtterances(this.props.config.BOTBUILDER_URL, msgDetails)
      this.setState({
        topcatHeading: msg
      })
    }
  }

  downloadReport = (val)=> {
    let projName = this.state.projectname
    let details = this.props.reportDetail
    if(!details){
       details = {
        "startDate": moment(this.state.startDate).utcOffset("+05:30").format('YYYY-MM-DD'),
        "endDate": moment(this.state.endDate).utcOffset("+05:30").format('YYYY-MM-DD'),
        "project": projName == 'common' ? allProj : projName,
        "orgName": localStorage.getItem('orgName'),
        "virtualAssist":this.state.vaName
    }
    }
    if(val === 'msgCategory'){
      this.props.exportMsgCategoryReport(this.props.config.BOTBUILDER_URL, details, 'download-conversational-handles')
    }
    if(val === 'unansMessages'){
      this.props.exportUnAnsMessageReport(this.props.config.BOTBUILDER_URL, details, 'download-unanswered-messages')
    }

  }

  render() {

    let ansCount = 0, unansCount = 0;
    ansCount = this.props.conMetricsData?.answered
    unansCount = this.props.conMetricsData?.unAnswered

    let msgCatTitle = [], msgCatVals = [];
    let msgCategoryRes = this.props.conHandledData?.messageCategories
    if (msgCategoryRes) {
      let sortable = [];
      for (let [key, value] of Object.entries(msgCategoryRes)) {
        sortable.push([key, value]);
      }
      sortable.sort((a, b) => b[1] - a[1]);
      sortable = (sortable.length >= 10) ? sortable.slice(0, 10) : sortable.slice(0, sortable.length)
      sortable.forEach(element => {
        msgCatVals.push(element[1]);
        msgCatTitle.push(element[0]);
      });

    }
    let ansMsg = 0, unansMsg = 0, totalMsg = 0;
    if (this.props.conMsgUtterancesData) {
      let msgCategoryAnsRes = this.props.conMsgUtterancesData;
      let key, value;
      for ([key, value] of Object.entries(msgCategoryAnsRes))
        if (key === this.state.topcatHeading) {
          ansMsg = value.answered,
            unansMsg = value.unanswered,
            totalMsg = parseInt(ansMsg) + parseInt(unansMsg)
        }
    }

    let unansCatTitle = [], unansCatVals = [];
    let unAnsCategoryRes = this.props.conHandledData?.unansweredCategories
    if (unAnsCategoryRes) {
      let sortable = [];
      for (let [key, value] of Object.entries(unAnsCategoryRes)) {
        sortable.push([key, value]);
      }
      sortable.sort((a, b) => b[1] - a[1]);
      sortable = (sortable.length >= 10) ? sortable.slice(0, 10) : sortable.slice(0, sortable.length)
      sortable.forEach(element => {
        unansCatVals.push(element[1]);
        unansCatTitle.push(element[0]);
      });
    }
    let unUnsTitle = [], unUnsVals = [], totalUnansCount = 0;
    if (this.props.conUnansUtterancesData) {
      let UnansweredUtterances = this.props.conUnansUtterancesData;
      let key, value;
      for ([key, value] of Object.entries(UnansweredUtterances))
        if (key === this.state.topUnansHeading) {
          unUnsTitle = Object.keys(value),
            unUnsVals = Object.values(value),
            totalUnansCount = Object.values(value).reduce((a, b) => parseInt(a) + parseInt(b), 0)
        }
    }

    let options = {
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          displayColors: false,
          callbacks: {
            title: function () { }
          }
        },
        datalabels: {
          display: true,
          color: "black",
          align: 'center',
          formatter: function (value, context) {
            return value == 0 ? '' : value;
          }
        }
      },
      responsive: true,
      scales: {
        x: {
          grid: {
            display: false,
            drawOnChartArea: false,
          },
          ticks: {
            precision: 0
          },
          stacked: true
        },
        y: {
          grid: {
            display: true,
            drawOnChartArea: true,
            drawBorder: false,
            color: '#b8b6b6'
          },
          ticks: {
            precision: 0
          },
          stacked: true
        },
      },
    };
    let verMapLabels = [], ConUnans = [], ConAns = [], totanAnsUnans;

    let ConversationReport = this.props.conHandledData?.dateWiseAnsweredUnansweredCount;
    let lineChartData = {}
    let parseConv = (ConversationReport) ? ConversationReport : [];
    let weekly = false;
    let sorted_data;
    let showdata = '';

    if (parseConv) {
      for (let [key, value] of Object.entries(parseConv)) {
        if (key.toLowerCase() === "day" && Object.keys(value[0]).length >= 1 && value.length < 8) {
          sorted_data = value;
          showdata = "day"
          break

        } else if (key.toLowerCase() === "week" && Object.keys(value[0]).length >= 1 && value.length <= 9) {
          sorted_data = value;
          weekly = true
          showdata = 'week'

        } else if (key.toLowerCase() === 'month' && Object.keys(value[0]).length>=1 && !weekly) {
          sorted_data = value;
          showdata = 'month'

        }else if(key.toLowerCase() === 'year' && Object.keys(value[0]).length>=1 ){
          sorted_data = value;
          showdata = 'year'
        }
      }
    }
    if (sorted_data) {
      sorted_data.forEach((v) => {
        (showdata == 'year') ? verMapLabels.push(`${moment(Object.keys(v)[0]).format("YYYY")}`) : (showdata == 'month') ? verMapLabels.push(`${moment(Object.keys(v)[0]).format("MMM")}`) : verMapLabels.push(`${moment(Object.keys(v)[0]).format("DD")}-${moment(Object.keys(v)[0]).format("MMM")}`)
        if (Object.values(v)[0]) {
          let posdata = Object.values(v)[0][0]
          for (let [key, value] of Object.entries(posdata)) {
            let answered, unanswered;
            if (key === "answered") {
              ConAns.push(value)
              answered = value

            } else if (key === "unanswered") {
              ConUnans.push(value)
              unanswered = value

            }
            totanAnsUnans = ConAns.map(function (num, idx) {
              return num + ConUnans[idx];
            })
          }
        }
      });
    }


    let data = {
      labels: verMapLabels,
      datasets: [
        {
          label: 'Answered',
          data: ConAns,
          backgroundColor: '#e3cc7a',
        },
        {
          label: 'Unanswered',
          data: ConUnans,
          backgroundColor: '#f38787',
        }
      ],
    };




    const Linedata = {
      labels: verMapLabels,
      datasets: [
        {
          data: totanAnsUnans,
          backgroundColor: "rgba(75,192,192,0.2)",
          borderColor: "rgba(75,192,192,1)",
          borderDash: [2, 2]
        }
      ]
    };
    let LineOptions = {
      responsive: true,
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          mode: 'index',
          intersect: false,
          displayColors: false,
          callbacks: {
            title: function () { }
          }
        }
      },
      scales: {
        x: {
          title: {
            display: false,
            color: '#000',
            padding: { top: 0, left: 0, right: 0, bottom: 5 }
          },
          grid: {
            display: false,
            drawOnChartArea: false,
            drawBorder: false,
          },
        },
        y: {
          ticks: {
            beginAtZero: true,
            callback: function (label, index, labels) {
              if (Math.floor(label) === label) return label;
            },
          },
          grid: {
            drawBorder: false,
            color: '#b8b6b6',
            drawOnChartArea: true,
          }
        }
      },
    };

    // UnansweredUtterances


    let bgColorMsg = [], bgColorUnAns = [];
    if (this.state.topcatHeading) {
      if (msgCatTitle.length > 0) {
        msgCatTitle.forEach((element, i) => {
          if (msgCatTitle.indexOf(this.state.topcatHeading) == i) bgColorMsg.push('rgb(107,92,165)')
          else bgColorMsg.push('rgb(232,229,229)')
        });
      }
    }
    if (bgColorMsg.length == 0) { bgColorMsg.push('rgb(232,229,229)') }
    if (this.state.topUnansHeading) {
      if (unansCatTitle.length > 0) {
        unansCatTitle.forEach((element, i) => {
          if (unansCatTitle.indexOf(this.state.topUnansHeading) == i) bgColorUnAns.push('rgb(243,135,135)')
          else bgColorUnAns.push('rgb(232,229,229)')
        });
      }

    }
    if (bgColorUnAns.length == 0) { bgColorUnAns.push('rgb(232,229,229)') }
    return (

      <>
        {this.props.conHandledLoading === true || this.props.conMsgUtterancesLoading === true || this.props.conUnansUtteranceLoading === true ? <SpinnerPage /> : null}
        <div className='d-flex mb-3'>
          <div className='detail-card'>
            <MetricCard
              heading={"Messages Answered"}
              count={ansCount}
              icon={"./Icons/answered.svg"}
              backgroundColor={"rgb(107, 92, 165)"}
              isLeftBorder={true}
            />
          </div>
          <div className='detail-card negative-card'>
            <MetricCard
              heading={"Messages Unanswered"}
              count={unansCount}
              icon={"./Icons/unanswered1.svg"}
              backgroundColor={"rgb(107, 92, 165)"}
              isLeftBorder={true}
            />
          </div>

        </div>
        <div className='row'>
          <div className='col-md-6 conversation_handled'>

            <div className="handledGraphContainer">
              <h4 className='chartTitle'>Conversation</h4>
              <div className='ansUnansConver'>
                <span className='anslabel'>Answered</span>
                <span className='unanslabel'>Unanswered</span>
              </div>
              <Bar options={options} data={data} plugins={[ChartDataLabels]} />
            </div>
          </div>
          <div className='col-md-6 conversation_handled pl-1'>

            <div className="handledGraphContainer">
              <h4 className='chartTitle pb-4'>Conversation Trend</h4>
              <Line data={Linedata} options={LineOptions} />
            </div>
          </div>
          <div className='col-md-6 conversation_handled'>
            <div className="handledGraphContainer">
              <div className='graph-content'>
                <h4 className='chartTitle '>Top Messages Categories</h4>
                <ToolTipComponent
                  title="Download">
                  <FileDownloadOutlinedIcon style={{ cursor: "pointer" }} onClick={()=>{this.downloadReport('msgCategory')}} />
                </ToolTipComponent>
              </div>
              <HorizontalBarChart labels={msgCatTitle} dataHorBar={msgCatVals} bgColor={bgColorMsg} clickEvent={this.topMsgCatEvent} plugins={[ChartDataLabels]} selected={this.state.topcatHeading} seltextColor="#fff" />
            </div>
          </div>
          <div className='col-md-6 conversation_handled pl-1'>
            <div className='payments-div'>
              <h4 className='payments-header messagesAnsHeading'>{this.state.topcatHeading + ' (' + totalMsg + ')'}</h4>

              <div className='payments-data-wrapper messagesAns col-12 mt-5 no-gutters'>
                <div className='col-6 no-gutters'>
                  <div className='msgAns'>
                    <h3>Messages Answered</h3>
                    <h1 className='MsgAnsCount'>{ansMsg}</h1>
                  </div>
                  <div className='MsgUnans'>
                    <h3>Messages Unanswered</h3>
                    <h1 className='MsgUnansCount'>{unansMsg}</h1>
                  </div>
                </div>
                <div className='col-6 no-gutters'>
                  <div className='MsgUnansIcon'>
                    <img src='./Icons/msgPayment.svg' />
                  </div>
                </div>


              </div>
            </div>
          </div>
          <div className='col-md-6 conversation_handled'>

            <div className="handledGraphContainer">
              <div className='graph-content'>
                <h4 className='chartTitle'>Top Unanswered Messages</h4>
                <ToolTipComponent
                  title="Download">
                  <FileDownloadOutlinedIcon style={{ cursor: "pointer" }} onClick={()=>{this.downloadReport('unansMessages')}} />
                </ToolTipComponent>

              </div>
              {/* <canvas ref={this.chartReference} height="400px" width="400px" />*/}
              <HorizontalBarChart labels={unansCatTitle} dataHorBar={unansCatVals} bgColor={bgColorUnAns} clickEvent={this.topUnansCatEvent} plugins={[ChartDataLabels]} selected={this.state.topUnansHeading} seltextColor="#000" />
            </div>
          </div>
          <div className='col-md-6 conversation_handled pl-1'>
            <div className='payments-div'>
              <div className='payments-header'>{this.state.topUnansHeading} ({totalUnansCount})</div>
              <div className='payments-data-wrapper scroll_payment_div'>
                {unUnsTitle && unUnsTitle.map((element, key) =>
                  <div className="d-flex justify-content-between">
                    <div>{element}</div>
                    <div>{unUnsVals[key]}</div>
                  </div>
                )

                }
              </div>
            </div>
          </div>
          {/* <div className='col-md-6 conversation_handled'>
            <div className="handledGraphContainer">
              <div className='graph-content'>
                <div className='filters'>
                  <h4 className='chartTitle'>Top Performed Actions</h4>
                  <Dropdown className="navigationHelpMenu " style={{ "position": "relative", "left": "5px" }}>
                    <Dropdown.Toggle id="navigationHelpMenu" drop='end'>
                      <ToolTipComponent
                        title="Filters">
                        <img
                          src="./Icons/filter_icon.png"
                          alt="filter"
                          style={{ cursor: 'pointer' }}
                        />
                      </ToolTipComponent>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item className={[classes.navigationItemsDropdown, classes.highlight, classes.optionSpacing].join(" ")} >
                        All
                      </Dropdown.Item>
                      <Dropdown.Item className={[classes.navigationItemsDropdown, classes.highlight, classes.optionSpacing].join(" ")} >
                        Intents
                      </Dropdown.Item>
                      <Dropdown.Item className={[classes.navigationItemsDropdown, classes.highlight, classes.optionSpacing].join(" ")} >
                        FAQs
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div>
                  <ToolTipComponent
                    title="Download">
                    <FileDownloadOutlinedIcon style={{ cursor: "pointer" }} />
                  </ToolTipComponent>
                </div>
              </div>
              <HorizontalBarChart />
            </div>
          </div> */}
        </div>

      </>

    )
  }
}

const mapStateToProps = state => {
  return {
    projectList: state.user.projects,
    totalUsersReportData: state.reportDashboard.totalUsersReport,
    RepeatUserReport: state.reportDashboard.RepeatUserReport,
    NewUserReport: state.reportDashboard.NewUserReport,
    reportDetail: state.reportDashboard.reportDetail,
    AgentTransferReport: state.reportDashboard.totalAgentTransferReport,
    totalunansweredReportData: state.reportDashboard.totalunansweredReport,
    totalFlowReportData: state.reportDashboard.totalFlowReport,
    feedbackReportData: state.reportDashboard.feedbackReportData,
    totalChatSessionReport: state.reportDashboard.totalChatSessionReport,
    botList: state.projectview.botList,
    totalmeetingsreport: state.reportDashboard.totalmeetingsreport,
    totalintiatedbotreport: state.reportDashboard.totalintiatedbotreport,
    totalAnsUnansCount: state.reportDashboard.totalAnsUnansCount,
    msgCategoryRes: state.reportDashboard.msgCategoryRes,
    msgCategoryAnsRes: state.reportDashboard.msgCategoryAnsRes,
    ConversationReport: state.reportDashboard.ConversationReport,
    UnansweredCatReponse: state.reportDashboard.UnansweredCatReponse,
    UnansweredUtterances: state.reportDashboard.UnansweredUtterances,
    conLoading: state.reportDashboard.conLoading,
    conAnsUnsReportLoading: state.reportDashboard.conAnsUnsReportLoading,
    conmsgCategoryLoading: state.reportDashboard.conmsgCategoryLoading,
    conMsgAnsUnsLoading: state.reportDashboard.conMsgAnsUnsLoading,
    conTopUnansLoading: state.reportDashboard.conTopUnansLoading,
    conGetUnansLoading: state.reportDashboard.conGetUnansLoading,
    conMetricsData: state.reportDashboard.conMetricsData,
    conHandledData: state.reportDashboard.conHandledData,
    conMsgUtterancesData: state.reportDashboard.conMsgUtterancesData,
    conUnansUtterancesData: state.reportDashboard.conUnansUtterancesData,
    conHandledLoading: state.reportDashboard.conHandledLoading,
    conMsgUtterancesLoading: state.reportDashboard.conMsgUtterancesLoading,
    conUnansUtteranceLoading: state.reportDashboard.conUnansUtteranceLoading

  };
};
const mapDispatchToProps = dispatch => {
  return {
    totalUsersReport: (apiUrl, data) => dispatch(actions.totalUsersReport(apiUrl, data)),
    totalRepeatUsersReport: (apiUrl, data) => dispatch(actions.totalRepeatUsersReport(apiUrl, data)),
    totalNewUsersReport: (apiUrl, data) => dispatch(actions.totalNewUsersReport(apiUrl, data)),
    totalAgentReport: (apiUrl, data) => dispatch(actions.totalAgentTransferReport(apiUrl, data)),
    totalFlowReport: (apiUrl, data) => dispatch(actions.totalFlowReport(apiUrl, data)),
    onTotalfeedbackReport: (apiUrl, data) => dispatch(actions.totalfeedbackReport(apiUrl, data)),
    totalunansweredReport: (apiUrl, data) => dispatch(actions.totalunansweredReport(apiUrl, data)),
    onTotalChatSessionReport: (apiURL, data) => dispatch(actions.totalChatSessionReport(apiURL, data)),
    onchangeProjectAction: (projectname) => dispatch(actions.changeProjectAction(projectname)),
    ReportPageData: (data) => dispatch(actions.ReportPageData(data)),
    OnGetBotList: (APIURL) => dispatch(actions.GetBotList(APIURL)),
    totalMeetingReport: (apiUrl, data) => dispatch(actions.totalMeetingReport(apiUrl, data)),
    totalInitiatedBotReport: (apiUrl, data) => dispatch(actions.totalInitiatedBotReport(apiUrl, data)),
    totalAnswerCount: (apiUrl, data) => dispatch(actions.totalAnswerCount(apiUrl, data)),
    MsgCategoriesRes: (apiUrl, data) => dispatch(actions.MsgCategoriesRes(apiUrl, data)),
    MsgCategoriesAnsRes: (apiUrl, data) => dispatch(actions.MsgCategoriesAnsRes(apiUrl, data)),
    // ConAnsUnsCountRes: (apiUrl, data) => dispatch(actions.ConAnsUnsCountRes(apiUrl, data)),
    ConAnsUnsCountReportRes: (apiUrl, data) => dispatch(actions.ConAnsUnsCountReportRes(apiUrl, data)),
    totalUnansweredCat: (apiUrl, data) => dispatch(actions.totalUnansweredCat(apiUrl, data)),
    getUnansUtterances: (apiUrl, data) => dispatch(actions.getUnansUtterances(apiUrl, data)),
    msgCategoriesAnsStart: () => dispatch(actions.msgCategoriesAnsStart()),
    getUnansUtterancesStart: () => dispatch(actions.getUnansUtterancesStart()),
    conUnansweredUtterancesStart: () => dispatch(actions.conUnansweredUtterancesStart()),
    conversationMsgUtterancesStart: () => dispatch(actions.conversationMsgUtterancesStart()),
    conversationalHandled: (apiUrl, data) => dispatch(actions.conversationalHandled(apiUrl, data)),
    conversationMsgUtterances: (apiUrl, data) => dispatch(actions.conversationMsgUtterances(apiUrl, data)),
    conversationUnansweredUtterances: (apiUrl, data) => dispatch(actions.conversationUnansweredUtterances(apiUrl, data)),
    conversationalMetricsData: (APIURL, data) => dispatch(actions.conversationalMetricsData(APIURL, data)),
    exportMsgCategoryReport: (apiUrl, data, url) => dispatch(actions.exportMsgCategoryReport(apiUrl, data, url)),
    exportUnAnsMessageReport: (apiUrl, data, url) => dispatch(actions.exportUnAnsMessageReport(apiUrl, data, url))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ConversationHandled);

