import {
    SIMILAR_ISSUES_CHANGE_HANDLER,
    FETCH_INSIGHTS_DATA_FAILURE,
    FETCH_INSIGHTS_DATA_SUCCESS,
    FETCH_INSIGHTS_DATA_START,
    KB_DOCS_CHANGE_HANDLER,
    QUESTIONARIES_CHANGE_HANDLER,
    TOP_RESOLUTIONS_CHANGE_HANDLER,
    PREDICTED_ISSUES_CHANGE_HANDLER,
    ISSUE_CLASSIFICATION_CHANGE_HANDLER,
    SAVE_INSIGHTS_UNIFIED_LOGIN_ES_DATA_START,
    SAVE_INSIGHTS_UNIFIED_LOGIN_ES_DATA_SUCCESS,
    SAVE_INSIGHTS_UNIFIED_LOGIN_ES_DATA_FAILURE,
    SIMILAR_ISSUES_OPTIONAL_FIELD_CHANGE_HANDLER,
    ISSUE_CLARIFICATION_OPTIONAL_FIELD_CHANGE_HANDLER,
    OPTIONAL_FIELD_RESET_HANDLER,
    KNOWLEDGE_ARTICLES_CHANGE_HANDLER

} from '../../actions/insights/insightsActiontypes';
import fetchInsightsDataFailureReducer from './fetchInsightsData/fetchInsightsDataFailureReducer';
import fetchInsightsDataStartReducer from './fetchInsightsData/fetchInsightsDataStartReducer';
import fetchInsightsDataSuccessReducer from './fetchInsightsData/fetchInsightsDataSuccessReducer';
import kbDocsChangeHandlerReducer from './kbDocsChangeHandlerReducer';
import questionariesChangeHandlerReducer from './questionariesChangeHandlerReducer';
import topResolutionsChangeHandlerReducer from './topResolutionsChangeHandlerReducer';
import predictedIssuesChangeHandlerReducer from './predictedIssuesChangeHandlerReducer';
import issueClassificationChangeHandlerReducer from './issueClassificationChangeHandlerReducer';
import similarIssuesChangeHandlerReducer from './similarIssuesChangeHandlerReducer';
import saveInsightsUnifiedLoginESdataFailureReducer from './saveInsightsUnifiedLoginEs/saveInsightsUnifiedLoginESdataFailureReducer';
import saveInsightsUnifiedLoginESdataSuccessReducer from './saveInsightsUnifiedLoginEs/saveInsightsUnifiedLoginESdataSuccessReducer';
import saveInsightsUnifiedLoginESdataStartReducer from './saveInsightsUnifiedLoginEs/saveInsightsUnifiedLoginESdataStartReducer';
import similarIssuesOptinalFieldChangeHandlerReducer from './similarIssuesOptinalFieldChangeHandlerReducer';
import issueClarificationOptionalFieldChangeHandlerReducer from './issueClarificationOptionalFieldChangeHandlerReducer';
import similarIssuesOptinalFieldResethandlerReducer from './similarIssuesOptinalFieldResethandlerReducer';
import knowledgeArticlesChangeHandlerReducer from './knowledgeArticlesChangeHandlerReducer';

const INITIAL_STATE = {
    loading: false,
    error: null,
    updatedServiceStatusObject: {},
    knowledgeArticles:{
        enabled:true
    },
    similartickets: {
        size: 5,
        extensionFields: [
        ],
        similarity: 60,
        fieldToSerach: [
            "title",
            "description"
        ],
        enabled: true
    },
    kbdocs: {
        repositorypath: "iceprime",
        summarytextsize: 30,
        enabled: true
    },
    questionaries: {
        size: 5,
        enabled: true
    },
    topresolutions: {
        size: 5,
        similarity: 60,
        enabled: true
      },
      predictedissues: {
        size: 5,
        similarity: 60,
        summarytextsize: 30,
        enabled: true
      },
      issueclassification: {
        size: 5,
        items: 3,
        summarytextsize: 30,
        ClusterFields: [
          "title",
          "problemDescription",
          "caseId"
        ],
        enabled: true
      },
      similarIssuesOptionalField1: '',
      similarIssuesOptionalField2: '',
      issueClarificationOptionalField1: '',
      issueClarificationOptionalField2: '',
      issueClarificationOptionalField3: ''
};

const reducers = {
    [SIMILAR_ISSUES_CHANGE_HANDLER]: similarIssuesChangeHandlerReducer,
    [KB_DOCS_CHANGE_HANDLER]: kbDocsChangeHandlerReducer,
    [QUESTIONARIES_CHANGE_HANDLER]:questionariesChangeHandlerReducer,
    [TOP_RESOLUTIONS_CHANGE_HANDLER]: topResolutionsChangeHandlerReducer,
    [PREDICTED_ISSUES_CHANGE_HANDLER]: predictedIssuesChangeHandlerReducer,
    [ISSUE_CLASSIFICATION_CHANGE_HANDLER]:issueClassificationChangeHandlerReducer,
    [SAVE_INSIGHTS_UNIFIED_LOGIN_ES_DATA_START]: saveInsightsUnifiedLoginESdataStartReducer,
    [SAVE_INSIGHTS_UNIFIED_LOGIN_ES_DATA_SUCCESS]: saveInsightsUnifiedLoginESdataSuccessReducer,
    [SAVE_INSIGHTS_UNIFIED_LOGIN_ES_DATA_FAILURE]: saveInsightsUnifiedLoginESdataFailureReducer,
    [FETCH_INSIGHTS_DATA_FAILURE]: fetchInsightsDataFailureReducer,
    [FETCH_INSIGHTS_DATA_START]: fetchInsightsDataStartReducer,
    [FETCH_INSIGHTS_DATA_SUCCESS]: fetchInsightsDataSuccessReducer,
    [OPTIONAL_FIELD_RESET_HANDLER]: similarIssuesOptinalFieldResethandlerReducer,
    [SIMILAR_ISSUES_OPTIONAL_FIELD_CHANGE_HANDLER]: similarIssuesOptinalFieldChangeHandlerReducer,
    [ISSUE_CLARIFICATION_OPTIONAL_FIELD_CHANGE_HANDLER]: issueClarificationOptionalFieldChangeHandlerReducer,
    [KNOWLEDGE_ARTICLES_CHANGE_HANDLER] : knowledgeArticlesChangeHandlerReducer
    // (INITIAL_STATE,INITIAL_STATE.knowledgeArticles.enabled)
    ,
};

/**
 * This reducer is handling the insights component all reducers
 *
 * @param {Object} state - the Redux state of the reducer that this function is
 *  used for
 * @param {*} action - the action dispatched with type and payload props.
 * @returns {*} the next Redux state
 */
export const insightsReducer = (
    state = INITIAL_STATE,
    action
) => {
    const { type, payload } = action;
    const reducer = reducers[type];
    return (reducer) ?
        reducer(state, payload) :
        state;
};