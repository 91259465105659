import React, {  useState, useEffect } from "react";
import "./IntegrationsPage.css";
import {  useSelector, useDispatch } from "react-redux";
import { Row, Col } from "react-bootstrap";
import Heading3 from "./components/Typography/Heading3/Heading3";
import CardCustom from "../../../components/UIComponents/Card/Card";
import SelectCustom from "../../../components/UIComponents/SelectDropdown/SelectComponent";
import { useHistory } from "react-router-dom";
import SharepointSettings from "./IntegrationTabs/SharepointSettings";
import DrawerComponent from "../../../components/UIComponents/Drawer/DrawerComponent";
import permissionsJson from "../../../permissions_Json"
import CommChannelDrawer from "../../../components/UI/Drawer/CommChannelDrawer";
// import { getWhatsappConfigData } from "../../../store/actions/index"; //to be uncommented when watsapp apis enabled

function IntegrationsPage({ config,permissions }) {
  
  const history = useHistory();
  const dispatch = useDispatch();

  const [stateInfo, setSateInfo] = useState({ selectedMenu: "", selectedSubMenu: {} })
  // const [selectHandler, setSelectHandler] = useState("");
  const [disableDataTabs, setDisableDataTabs] = useState(true);
  const [data, setData] = useState({});
  
  const [showSharepoint, setshowSharepoint] = React.useState({
    right: false
  });
  const [selectHandler, setSelectHandler] = useState("allApps");
  const [selectedChannel, setSelectedChannel] = useState('');
  const [commChannelDrawer, setCommChannelDrawer] = useState(false);

  const connectionsTabs = useSelector(
    (state) => state.projectview.connectionsTabs
  ).filter((item) => item !== "");
  const knowledgeBaseItems = useSelector(
    (state) => state.projectview.knowledgeBaseObjItems
  );
  const ticketSystemItems = useSelector(
    (state) => state.projectview.ticketSystemObjItems
  );
  const commChannelItems = [];//['WHATSAPP', 'MSTEAMS']; //to be uncommented when watsapp apis enabled

  const getChannelLabel = (channel) => {
    return channel === 'WHATSAPP' ? 'WhatsApp' : 'MS Teams';
  }
  
  
  // useEffect(()=>{ //to be uncommented when watsapp apis enabled
  //   dispatch(getWhatsappConfigData(config.COMMON_API_URL));
  // },[])

  const handleDisableDataTabs = (payload) => setDisableDataTabs(payload);

  const [ticketsDump, setTicketsDump] = useState({
    cardTitle: "Ingest Data",
    cardLabel: "Ticketing System",
    cardNotes: "Upload the files which has ticket dump details i.e .csv, .xsl",
    cardVariant: "contained",
    buttonLabel: "Add",
    cardActive: true,
    showButton: true,
  });
  // const [ManualUpload, setManualUpload] = useState({
  //   cardTitle: "Manual Upload",
  //   cardLabel: "DATA",
  //   cardNotes: "upload the data for Triage & CRM",
  //   cardVariant: "contained",
  //   buttonLabel: "Add",
  //   cardActive: true,
  //   showButton: true,
  // });
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setshowSharepoint({ ...showSharepoint, right: open });
  };

  const selectedItemClickHandler = (selectedItem, tabName) => {
    if (selectedItem.displayName.toUpperCase() == "SHAREPOINT") {
      setshowSharepoint({ ...showSharepoint, ["right"]: true });
      setSateInfo({ selectedMenu: tabName, selectedSubMenu: selectedItem.id })
    } else {
      history.push({
        pathname: "/integrationTabs",
        state: { selectedMenu: tabName, selectedSubMenu: selectedItem.id },
      });
    }
  };

  const onSelectHandler = (event) => {
    setSelectHandler(event.target.value);
  };

  const showChannelDrawer = (channel) => {
    setSelectedChannel(channel);
    setCommChannelDrawer(true);
  }

  let  integration_Read_Write = false;
  let integration_RW_Status = permissions.find(data => {
    if (data.permission_code.includes(permissionsJson.permissionCode.INTEGRATIONM_RW) && data.sku_code.includes(permissionsJson.skuCode.STANDARD))
      return true
  })
  integration_Read_Write = integration_RW_Status ? true : false;

  return (
    <React.Fragment>
      <Row style={{ marginLeft: "-5px", marginRight: "0px" }}>
        <div className="d-flex ml-4 mt-4 mb-2">
          <Heading3 text="Integrations" className="pageTitleText" />
        </div>
      </Row>
      <Row style={{ marginLeft: "0px", marginRight: "0px" }}>
        <Col xs="3">
          <div className="all-apps-dropdown">
            <SelectCustom
              name="selectName"
              className="allApps"
              changed={(event) => onSelectHandler(event)}
              required={true}
              value={selectHandler}
              options={[
                { value: "allApps", displayValue: "All Apps" },
                { value: "ticket_systems", displayValue: "Ticketing System" },
                { value: "knowledge_base", displayValue: "Knowledge Base" },
                //to be uncommented when watsapp apis enabled
                // { value: "comm_channel", displayValue: "Communication channel"} 
              ]}
            />
          </div>
        </Col>
        <Col xs="3"></Col>
        <Col xs="3" className="searchSortBy">
          <div className="sortBy">
            <img src="./Icons/Search.svg"></img>
            <span>Sort by:</span>
          </div>
          <div className="most-used">
            <SelectCustom
              name="selectName"
              changed={(event) => onSelectHandler(event)}
              required={true}
              value={selectHandler}
              options={[
                { value: "MostUsed", displayValue: "Most Used" },
                { value: "Zendesk", displayValue: "Zendesk" },
                { value: "ServiceNow", displayValue: "Service Now" },
                { value: "jira", displayValue: "jira" },
                { value: "SharePoints", displayValue: "share Points" },
                { value: "Ingest Data", displayValue: "Ingest Data" },
                { value: "KnowledgeBase", displayValue: "Knowledge Base" },
                // {value : "ManualUpload",displayValue:"Manual Upload"},
              ]}
            />{" "}
          </div>
        </Col>
      </Row>
      <Row
        className="cardAlign integrationsPage"
        style={{ marginLeft: "0px", marginRight: "0px" }}
      >
        {connectionsTabs && connectionsTabs.length
          ? connectionsTabs.map((tab) => {
              return (
                <>
                  {tab === "ticket_systems"  
                    ? ticketSystemItems.length &&
                      ticketSystemItems.map((item) => {
                        const jsonObj = {
                          cardTitle: item.displayName,
                          cardLabel: tab,
                          cardNotes:
                            " Provide cross-channel support to customers and easy-to-use tools to your agents that scale as your business grows.",
                          cardVariant: "contained",
                          buttonLabel: "Settings",
                          cardActive: true,
                          showButton: true,
                        };
                        if( selectHandler=="ticket_systems" || selectHandler=="allApps"){
                          return (
                            <Col xs="4" key={item.displayName}>
                              <CardCustom
                                cardInput={jsonObj}
                                disabled={integration_Read_Write ? false : true}
                                buttonClick={() =>
                                  selectedItemClickHandler(item, tab)
                                }
                              />
                            </Col>
                          );
                        }
                        
                      })
                    : null}
                  {tab === "knowledge_base"  
                    ? knowledgeBaseItems.length &&
                      knowledgeBaseItems.map((item) => {
                        const jsonObj = {
                          cardTitle: item.displayName,
                          cardLabel: tab,
                          cardNotes:
                            " Provide cross-channel support to customers and easy-to-use tools to your agents that scale as your business grows.",
                          cardVariant: "contained",
                          buttonLabel: "Settings",
                          cardActive: true,
                          showButton: true,
                        };
                        if(selectHandler=="knowledge_base" || selectHandler=="allApps"){
                          return (
                            <Col xs="4">
                              <CardCustom
                                cardInput={jsonObj}
                                disabled={integration_Read_Write ? false : true}
                                buttonClick={() =>
                                  selectedItemClickHandler(item, tab)
                                }
                              />
                            </Col>
                          );
                        }
                        
                      })
                    : null}
                  
                </>
              );
            })
          : null}
        {(selectHandler=="comm_channel" || selectHandler=="allApps") && (
          commChannelItems.map((item)=> {
            const jsonObj = {
              cardTitle: getChannelLabel(item),
              cardLabel: 'Communication Channel',
              cardNotes:
                "Seamlessly deploy your virtual assistant on "+getChannelLabel(item)+" to enhance your customer experience.",
              cardVariant: "contained",
              buttonLabel: "Settings",
              cardActive: true,
              showButton: true,
            };
              return (
                <Col xs="4" key={item}>
                  <CardCustom
                    cardInput={jsonObj}
                    disabled={integration_Read_Write ? false : true}
                    buttonClick={() => {showChannelDrawer(item)}
                    }
                  />
                </Col>
              );
          })
        )}
        {commChannelDrawer && <CommChannelDrawer show={commChannelDrawer} setShow={setCommChannelDrawer} 
                               config= {config} channel={selectedChannel} /> 
        } 
        <React.Fragment>
          <DrawerComponent
            heading="SharePoint"
            showDrawer={toggleDrawer(false)}
            openDrawer={showSharepoint.right}
          >
            <label className="mb-1 text-box-title adding-other-input">
              Document Management and store system.once the account is linked,we can see all the files present in the SharePoint.
            </label>
            <SharepointSettings
              selectedMenu={stateInfo.selectedMenu}
              selectedSubMenu={stateInfo.selectedSubMenu}
              config={config}
              data={data}
              handleDisableDataTabs={handleDisableDataTabs}
              showConfigLoader={false}
            />
          </DrawerComponent>
        </React.Fragment>
        {selectHandler !== 'comm_channel' && (
          <Col xs="4">
          <CardCustom
            cardInput={ticketsDump}
            disabled={integration_Read_Write ? false : true}
            buttonClick={() => history.push("/tickets")}
          />
          </Col>
        )}
        
        {/* <Col xs="4">
          <CardCustom
            cardInput={ManualUpload}
            disabled={integration_Read_Write ? false : true}
            buttonClick={() => history.push("/ManualUpload")}
          />
        </Col> */}
      </Row>
    </React.Fragment>
  );
}

export default IntegrationsPage;
