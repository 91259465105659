import React from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import './HorizontalTab.css';

const tab = (props) => (
    <Tabs defaultActiveKey={props.selected} className={['tabClass', props.tabCustomClass].join(' ')}
     activeKey = {props.selected} data-testid="TabHead" onSelect={ (e) => props.setSelected(e)}>
        {props.tabs.map(tab =>{
            return (
                <Tab key={tab} eventKey={tab} title={tab}> 
                    {props.tabheader=="KnowledgeIntents"?(tab==props.selected?props.children:null):
                    props.children
                } 
                </Tab>
                
            );
        })}
    </Tabs>
);

export default tab;