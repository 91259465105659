const resetInsightsHomeDataReducer = (state) => {
    return {
        ...state,
        agentInsightsData: {},
        agentInsightsLoading: false,
        productTriageDataLoading: false,
        clustersData: [],
        clustersDataLoading: false,
        selectedInisghtsTab: 'recommendedSolutions',
        yesQuestions: [],
        recommendedSolutionData: [],
        recommendedSolutionDataLoading: false
    };
};

export default resetInsightsHomeDataReducer;