import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import { Tab, Nav, Col, Row } from 'react-bootstrap';
import '.././triage-configuration-tabs.css';
import './symptom-root-cause-tabs.css';
import SymptomData from './SymptomData';
import SearchSymptomAndRootcause from '../../SymtomsAndRootCauseTab/helpers/SearchSymptomAndRootcause';
import { isEmpty, isEqual } from 'lodash';
import SearchComponent from '../../../../components/SearchComponent/SearchComponent';


const SymptomRootCauseTabs = ({
    config,sendDataToRootCauseSym,lastSelectedSymptom,checked,permissions
}) => {
    const symptomRootCauseData = useSelector(state => state.adminSettings.symptomRootCauseData);
    const [symptomRootCause, setSymptomRootCause] = useState(symptomRootCauseData);
    let seletedTabCheck = lastSelectedSymptom ? lastSelectedSymptom : get(symptomRootCauseData, `0.symptoms`, '')
    const [selectedTab, setSelectedTab] = useState(seletedTabCheck);
    const [slectedTablength, setSlectedTablength] = useState(symptomRootCauseData.find(item => item.symptoms === selectedTab));
    
    const onTabChange = (selected) => {
        sendDataToRootCauseSym(selected);
        setSelectedTab(selected)
        setSlectedTablength(symptomRootCauseData.find(item => item.symptoms === selected))
    };
    const [searchTerm, setSearchTerm] = useState("");
    const searchHandler = (e) => {
        if(!isEqual(e.target.value.length ,0)){
        setSearchTerm(e.target.value);
        console.log(searchTerm,"searchterm");
        if (symptomRootCause.length > 0) {
          const filter = SearchSymptomAndRootcause(
            symptomRootCause,
            "symptoms",
            e,
            setSymptomRootCause
          );
          setSymptomRootCause(filter);
        }
    }else{
        setSearchTerm(e.target.value);
        setSymptomRootCause(symptomRootCauseData);
    }
    }

    useEffect(() => {
        let seletedTabCheck  = lastSelectedSymptom ? lastSelectedSymptom : get(symptomRootCauseData, `0.symptoms`, '')
        sendDataToRootCauseSym(seletedTabCheck);
        setSelectedTab(seletedTabCheck);
        // setSlectedTablength(symptomRootCauseData.find(item => item.symptoms === selectedTab));
    }, [symptomRootCauseData]);

    useEffect(() => {
        setSymptomRootCause(symptomRootCauseData);
    },[symptomRootCauseData])

    return (
        <Tab.Container
            id="issue-symptom-root-details-tabs"
            defaultActiveKey="first"
            activeKey={selectedTab}
            onSelect={onTabChange}
        >
            <Row className='w-100 no-gutters'>

                <Col sm={3} className='symptom-root-cause-mapping-tabs'>
                    <div className='mb-4'>
                    <SearchComponent
                                name={'recomended-solution'}
                                value={searchTerm}
                                onChange={searchHandler}
                                placeHolder={'Search'}
                            />
                    </div>
                      <h4 className="root-cause-side-box-heading">{`SYMPTOMS (${symptomRootCause.length})`}
                    </h4>
                    {/* <img src="./images/Search-img.svg"/> */}
                    <div className='left-container left-side-container'>
                    <Nav variant="pills" className="flex-column" >
                        {symptomRootCause.length ? symptomRootCause.map((item,index) => {
                            return (
                                <Nav.Item key={item.symptoms}>
                                    <Nav.Link eventKey={item.symptoms}>
                                        <p className="root-cause-side-box root-cause-side-box-border">
                                            {item.symptoms}
                                            <img className="root-cause-side-box-img" src="./images/Play.svg" />
                                        </p>
                                    </Nav.Link>
                                </Nav.Item>
                            )
                        }) : null}
                    </Nav>
                    </div>
                </Col>
                <Col sm={9}>
                    <div className="root-cause-content">
                        <Row className="root-cause-table-heading no-gutters">
                            <Col className="col-md-4 root-cause-table-heading-symptom text-break"><h4>{`ROOT CAUSES (${!isEmpty(slectedTablength) ? (slectedTablength.rootcausedata).length : (symptomRootCauseData[0].rootcausedata).length})`}</h4></Col>
                            <Col className="col-md-3 pl-3 text-break pr-2"><h4>Recommended Resolution</h4></Col>
                            <Col className="col-md-2  text-break"><h4>RELEVANCY(%)</h4></Col>
                            <Col className="col-md-2 text-break"><h4>STATUS</h4></Col>
                            {/* <Col className="col-md-1 text-break pl-2"><img src="./images/Download-path.svg" /></Col> */}
                        </Row>
                        <Tab.Content className='right-side-container'>
                            {symptomRootCauseData.length ? symptomRootCauseData.map(item => {
                                return (
                                    <Tab.Pane eventKey={item.symptoms}>
                                        <SymptomData
                                            selectedItem={item}
                                            config={config}
                                            checked={checked}
                                            permissions={permissions}
                                        />
                                    </Tab.Pane>
                                )
                            }) : null
                            }
                        </Tab.Content>
                    </div>
                </Col>
            </Row>
        </Tab.Container>
    );
};

export default SymptomRootCauseTabs;