export const FETCH_PRODUCT_TRIAGE_DATA_START = 'FETCH_PRODUCT_TRIAGE_DATA_START';
export const FETCH_PRODUCT_TRIAGE_DATA_SUCCESS = 'FETCH_PRODUCT_TRIAGE_DATA_SUCCESS';
export const FETCH_PRODUCT_TRIAGE_DATA_FAILURE = 'FETCH_PRODUCT_TRIAGE_DATA_FAILURE';

export const FETCH_PRODUCT_TRIAGE_INSIGHTS_START = 'FETCH_PRODUCT_TRIAGE_INSIGHTS_START';
export const FETCH_PRODUCT_TRIAGE_INSIGHTS_SUCCESS = 'FETCH_PRODUCT_TRIAGE_INSIGHTS_SUCCESS';
export const FETCH_PRODUCT_TRIAGE_INSIGHTS_FAILURE = 'FETCH_PRODUCT_TRIAGE_INSIGHTS_FAILURE';

export const FILTER_DATA_CHANGE_HANDLER = 'FILTER_DATA_CHANGE_HANDLER';
export const FILTER_DATA_ARR_CHANGE_HANDLER = 'FILTER_DATA_ARR_CHANGE_HANDLER';
export const SET_SELECTED_INSIGHTS_TAB = 'SET_SELECTED_INSIGHTS_TAB';
export const YES_QUESTION_UPDATE_HANDLER = 'YES_QUESTION_UPDATE_HANDLER';

export const ADVANCE_FILTER_CHANGE_HANDLER = 'ADVANCE_FILTER_CHANGE_HANDLER';
export const RECOMENDED_SOLUTIONS_CHECKBOX_HANDLER = 'RECOMENDED_SOLUTIONS_CHECKBOX_HANDLER';

export const FETCH_CLUSTERS_DATA_START = 'FETCH_CLUSTERS_DATA_START';
export const FETCH_CLUSTERS_DATA_SUCCESS = 'FETCH_CLUSTERS_DATA_SUCCESS';
export const FETCH_CLUSTERS_DATA_FAILURE = 'FETCH_CLUSTERS_DATA_FAILURE';

export const FETCH_RECOMMENDED_SOLUTION_DATA_START = 'FETCH_RECOMMENDED_SOLUTION_DATA_START';
export const FETCH_RECOMMENDED_SOLUTION_DATA_SUCCESS = 'FETCH_RECOMMENDED_SOLUTION_DATA_SUCCESS';
export const FETCH_RECOMMENDED_SOLUTION_DATA_FAILURE = 'FETCH_RECOMMENDED_SOLUTION_DATA_FAILURE';

export const RESET_INSIGHTS_HOME_DATA = 'RESET_INSIGHTS_HOME_DATA';
export const GET_SYMPTOMS_FILTERS_CHANGE_HANDLER = "GET_SYMPTOMS_FILTERS_CHANGE_HANDLER";

export const QUESTIONS_UPDATE_HANDLER = "QUESTIONS_UPDATE_HANDLER";