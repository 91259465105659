import React from 'react';
import AceEditor from 'react-ace';
import 'brace/mode/javascript';
import 'brace/theme/tomorrow_night_eighties';
import 'brace';
import 'brace/ext/searchbox';
import classes from './Editor.module.css';

const editor = (props) => (
    <AceEditor

        ref={props.refer}
        mode="javascript"
        theme="tomorrow_night_eighties"
        name={props.names}
        className={classes['ace-width']}
        onChange={props.changed}
        value={props.value}
        editorProps={{
            $blockScrolling: true
        }}
        />
);

export default editor;