import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import "./Users.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserDetailsForm from "./UserDetailsForm";
import Dropdown from "react-bootstrap/Dropdown";
import {
  GetCalltoOUServer,
  DeleteCalltoOUServer,
  PostCallToOUServer,
  UpdateCalltoOUServer,
 
} from "../helpers/utility";
import DeleteModal from "../../../../../components/UIComponents/DeleteModalComponent/DeleteModal";
import { useDispatch } from "react-redux";
import {
  SetSuccessMessage,
  SetErrorMessage,
  SetWarningMessage,
} from "../../../../../store/actions";
import { SET_ERROR_MESSAGE } from "../../../../../store/actions/actionTypes";

const UserContent = ({
  user,
  config,
  updateUserCallBack,
  updateUser,
  usergroup,
  updateFilterData,
  userManagement_Read_Write,
  setSearchTerm
}) => {

  const [showDrawer, setShowDrawer] = useState(false);
  const [invited, setInvited] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  
  const orgname = localStorage.getItem("orgName");

  const dispatch = useDispatch();

  const toggleDrawer = (value) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setShowDrawer(value);
    setSearchTerm("");
    // updateUser();
    // updateUserCallBack()
  };
  const deleteInviteModel = () => {
    setSearchTerm("");
    setDeleteConfirmation(true);
  };
  const handleClose = (modaltype) => {
    if (modaltype == "deleteInviteModal") {
      setDeleteConfirmation(false);
    } else {
      return null;
    }
  };

  function getLastActive(minutes) {
    if (minutes < 0) {
      return "-";
    } else if (minutes < 5) {
      return "Few minutes ago";
    } else if (minutes < 60) {
      return minutes + " minutes ago";
    } else if (minutes < 60 * 24) {
      return Math.trunc(minutes / 60) + " hour ago";
    } else {
      return Math.trunc(minutes / 1440) + " days ago";
    }
  }

  const displayToastMsg = (msg, method) => {
    setTimeout(() => {
      dispatch(method(msg))
    },450)
  }

  const confirmMsg = (msg, method) => {
    setTimeout(() => {
      dispatch(method(msg))
    },450)
  }
  const [formData, setFormData] = useState({
    username: user.username,
    orgname: orgname,
    invited:  true,
    usergroup: user.usergroup, 
    message: "I am inviting you to the Intelliservice platform.",
  });

  const resendInvite = () => {
   const url = config.INSIGHTS_API_URL + `/organization/user`;
   PostCallToOUServer(url, formData).then((response) => {
    if (response.status == 201) {
      confirmMsg(`Invitation resent successfully to ${
        user.first_name + " " + user.last_name
      }`, SetSuccessMessage);
   }
   else {
    displayToastMsg('Resending of invitation failed', SetErrorMessage);
  }
})
.catch(() => {
  displayToastMsg('Something went wrong!', SetErrorMessage);
});
}
async function getUpdatedUsergroups(username) {
  const url =
    config.COMMON_API_URL +
    `/organization/user?username=${username}&orgname=${orgname}`;

  try {
    const response = await GetCalltoOUServer(url);

    if (response.data != null) {
      let usergroupList = response.data[0]?.usergroup;
      let updatedList = usergroupList.filter((list) => list !== usergroup);
      return updatedList;
    }
  } catch (err) {}
}
const removeUser = () => {
  let username = formData.username.replaceAll(".", "~~");
  const url =
    config.COMMON_API_URL + `/organization/${orgname}/user/${username}`;

  DeleteCalltoOUServer(url).then((response) => {
    if (response.status == 204) {
      toggleDrawer(true);
      updateUserCallBack();
      confirmMsg('User deleted successfully', SetSuccessMessage); 
    } else {
      displayToastMsg('Something went wrong!', SetErrorMessage);
    }
  });
};
const removeUserFromUsergroup = async () => {
  let username = formData.username.replaceAll(".", "~~");
  const url =
    config.COMMON_API_URL + `/organization/${orgname}/user/${username}`;
  const list = await getUpdatedUsergroups(formData.username);
  const requestBody = { usergroup: list };
  if (list.length > 0) {
    UpdateCalltoOUServer(url, requestBody).then((response) => {
      if (response.status == 204) {
        updateUserCallBack();
        toggleDrawer(true);
        setDeleteConfirmation(false); 
      //   confirmMsg(`${username} is deleted  from ${usergroup} successfully`, SetSuccessMessage);
      } else {
        displayToastMsg('Something went wrong!', SetErrorMessage);
        
      }
    });
  } else {
    setDeleteConfirmation(false); 
    displayToastMsg('User needs to be part of atleast one group', SetWarningMessage);
  }
};
const removeAction = () => {
  if (usergroup === undefined) {
    removeUser();
  } else {
    removeUserFromUsergroup();
  }
};
  
  return (
    <React.Fragment>
      <Row
        className="d-flex users-content-row row-no-gutters"
        //  onClick={toggleDrawer(true)}
      >
        <Col xs={12} className="d-flex row symtom-container">
          <div className="row d-flex justify-content-between w-100 content-align-items row-no-gutters">
          <Col xs={2}>
              <div className="users-content name-capitilize" key={user.id}>
                {user.first_name  + " " + user.last_name}
              </div>
            </Col>
            <Col xs={2}>
              <div className="users-content padding-adjustment">
                {user.last_active < 0 ? "Invite Sent" : "Active"}
              </div>
            </Col>
            <Col xs={2}>
              <div className="users-content padding-adjustment">
                {getLastActive(user.last_active)}
              </div>
            </Col>
            <Col xs={2}>
              {usergroup === undefined && (
                /* <div className="users-content padding-adjustment">{parseData(user.usergroup)}</div> */
                <div className="users-content padding-adjustment">
                  {(user.usergroup).join(', ')}
                </div>
              )}
              </Col>
            <Col xs={2}>
              <div className="users-content"></div>
            </Col>
             {/* <Col xs={1}>
              <div className="users-content cursor-action" onClick={toggleDrawer(true)}>...</div>
            </Col>  */}
            <Col xs={1}>
              <div className="users-content cursor-action">
                <Dropdown
                  className="navigationHelpMenu d-flex"
                  style={{ position: "absolute", bottom: "2px" }}
                >
                  <Dropdown.Toggle id="navigationHelpMenu" drop="end">
                    <img
                      src="./Icons/OptionsIcon.svg"
                      title="Help"
                      style={{ marginLeft: "7px" }}
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="options-menu">
                  {user.last_active < 0 ?
                    <Dropdown.Item
                      className="optionsItemsDropdown"
                      onClick={() => resendInvite()}
                      disabled={userManagement_Read_Write ? false : true} 
                    >
                      Resend Invite
                    </Dropdown.Item>
                    : "" }
                    {/* <Dropdown.Item href="#/action-2" className='optionsItemsDropdown'>
                      DUPLICATE ROLE</Dropdown.Item>  */}
                    <Dropdown.Item
                      className="optionsItemsDropdown"
                      onClick={deleteInviteModel}
                      disabled={userManagement_Read_Write ? false : true} 
                    >
                      Remove
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="optionsItemsDropdown"
                      onClick={toggleDrawer(true)}
                      disabled={userManagement_Read_Write ? false : true} 
                    >
                      View/Edit Details
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Col>
            <DeleteModal
              show={deleteConfirmation}
              deleteModal={() => handleClose("deleteInviteModal")}
              title="Delete Confirmation"
              bodyTitle="Are you sure you want to remove the user?"
              buttonClick={() => removeAction()}
            />
          </div>
        </Col>
        {/* <Col xs={1} className="arrow-img">
          <img src="./images/Vector.svg" />
        </Col> */}
      </Row>
      {showDrawer ? (
        <UserDetailsForm
          showDrawer={showDrawer}
          toggleDrawer={toggleDrawer}
          data={user}
          updateFilterData={updateFilterData}
          config={config}
          updateUserCallBack={updateUserCallBack}
          usergroup={usergroup}
          toastFun={(msg, type) => {
            toast(msg, {
              type: type,
              pauseOnHover: false,
              autoClose: 3000,
              hideProgressBar: true,
            });
          }}
          closeDrawer={() => {
            setShowDrawer(false);
          }}
        />
      ) : null}

      <ToastContainer />
    </React.Fragment>
  );
};
export default UserContent;
