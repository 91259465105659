/*

* Copyright © 2020, HCL Technologies Ltd. ALL RIGHTS RESERVED.

* <p>

* This software is the confidential information of HCL Technologies Ltd., and is licensed

* as restricted rights software. The use,reproduction, or disclosure of this software

* is subject to restrictions set forth in your license agreement with HCL.

*/

/**

* Home component.

*

* @author Abhash Vats

*/
import React from "react";


import {Component} from 'react';
import { connect } from 'react-redux';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import TextAreaWithLabelComponent from "./TextAreaWithLabelComponent";
import InputWithLabelComponent from "../../../SettingPage/SettingInnerPages/components/InputWithLabelComponent/InputWithLabelComponent";
import {  Container } from 'react-bootstrap';
import ButtonCustom from "../../../../components/UIComponents/Button/ButtonComponent";
import "./ScheduleMeeting.css"
import SelectCustom from "../../../../components/UIComponents/SelectDropdown/SelectComponent";
import ReactMultiSelectComponent from './ReactSelectComponent';
import TabCustom from "../../../../components/UI/HorizontalTab/HorizontalTab";
import * as actions from '../../../../store/actions/index';
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles, styled, alpha} from "@material-ui/core/styles";
import Switch from '@material-ui/core/Switch';
import { blue } from '@material-ui/core/colors';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
// import {Input, Card} from 'antd';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Form } from "react-bootstrap";
import "./inputBox.css";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

class ScheduleMeeting extends Component{

    constructor(props) {
        super(props);
        this.state = {
            // selectedProject:[localStorage.getItem('projectname')],
            selectedProject:'',
            prevSelectedProject:'',
            selectedTimeZone:[],
            prevSelectedTimeZone:[],
            selectedTab:'Schedule Meeting Configuration',

            //------------------
            agentsID:'',
            searchdays:4,
            slotsPerDay:4,
            whenMeetingInitiateMsg: 'You seem to be interested in scheduling a meeting with an agent. Please give me a minute to search for the available time slots.',
            whenTimeSlotsAvailableMsg:'In the “Days” textbox: Here are some days available from "<agent name>"s calendar. Let us know what works best for you. <days> In the “Timeslot” textbox: Here are some  available timeslots <timezone> for <selected day>. Let us know what works best for you.',
            whenTimeSlotsNotAvailbleMsg:'Sorry all our agents seem to be busy. Currently, there are no timeslots available. Please try scheduling a meeting with our agents after some time.',
            whenTimeSlotSelectedMsg:'I have scheduled a meeting on <Month Date (Timeslot) Timezone> with <agent name>. You should receive an email invite shortly at <visitor email address>.',
            whenTimeSlotNotSelectedMsg: 'Sorry to hear that. Please try scheduling a meeting with our agents after some time.',
            whenTimeSlotsAvailableMsgForDays:'Here are some days available from <agent name>\'s calendar. Let us know what works best for you.',
            whenTimeSlotsAvailableMsgForTimeslot:'Here are some  available timeslots <timezone> for <selected day>. Let us know what works best for you.',
            meetingDuration:30,
            fromEmail:'intelliService@intelliservices.onmicrosoft.com',
            emailSubject:'HCL IntelliService: Follow up conversation',
            emailBody:'Hi, \n\n It was nice chatting with you. As requested, this is a follow up meeting with our sales executive <agent name>.\n\nDate and Time: <Month Date (Timeslot) Timezone>\nAgenda: <agenda>\n\nRegards, \nHCL Virtual Assistant',
            AgentsRouting:"Max Slots Availability",
            emailIdMsg: "You seem to be interested in scheduling a meeting with an agent. Please provide your email id before I can help you with the process.",
            agendaMsg: "Thanks a lot for the email id. What would you like to explore during the meeting?",
            validationMsg:null,
            //-----------
            
            agentStatus:'Available',
            saveSetting:false,
            emailSentToUser:false,
            emailSentToAgent:true,
            //meetingDurationOption:[{value:"15 min",displayValue:"15 min"},{value:"30 min",displayValue:"30 min"},{value:"45 min",displayValue:"45 min"},{value:"1 hr",displayValue:"1 hr"}],
            emailToggle:false,
            agendaToggle:false,
            noOfAgents:[''],
            agents:[],
            agentsMatch:[],
            agentIndex:0,
            agentVal:[],
            left:'',
            top:'',
            enableButton:false,
            virtualAssistantProjects:[],
        }
      }

    componentDidMount() {
        this.props.onGetTimezones(this.props.config.BOTBUILDER_URL);
        this.props.onGetAgentDetails(this.props.config.CS_GOLANG +`/organization/user?orgname=`+ localStorage.getItem("orgName"));
        setTimeout(() => {
          if(this.props.agentDetail){
            const temp=[]
            for(var i=0;i<this.props.agentDetail.length;i++){
              temp.push(this.props.agentDetail[i].username)
            }
            this.setState({agents:temp})
          }
        }, 2000);
        let project = [];
        if (this.props.AssistantsList) {
            let obj = this.props.AssistantsList.find(ele => ele.virtualassistantName == localStorage.getItem("vaName"))
            if(obj){
                project =obj.project.filter(ele => ele !== '');
            }else{
                project = [];
            }
        }
        let vaProjectList = this.props.projects.filter(projec => project.includes(projec.projectName))
        vaProjectList && vaProjectList.map((data) => {
          if(data.projectName !== 'common'){
             this.state.virtualAssistantProjects.push(data.projectName)
          }
        })
        if(this.state.virtualAssistantProjects.length>0)
          this.setState({selectedProject:this.state.virtualAssistantProjects[0]})
     }
     componentDidUpdate(prevprops){
            if(this.props.scheduleMeetingDetail && this.props.scheduleMeetingDetail.length !==0 && prevprops.scheduleMeetingDetail !== this.props.scheduleMeetingDetail){
              var temp=[]

              for(var i=0;i<this.props.scheduleMeetingDetail[0].agents.length;i++){
                temp.push(this.props.scheduleMeetingDetail[0].agents[i])
              }
              if(temp.length==0)
              temp.push('')
                this.setState({
                    noOfAgents:temp,
                    selectedTimeZone:this.props.scheduleMeetingDetail[0].timeZone?this.props.scheduleMeetingDetail[0].timeZone:[],
                    agentsID:this.props.scheduleMeetingDetail[0].agents,
                    searchdays:this.props.scheduleMeetingDetail[0].days,
                    slotsPerDay:this.props.scheduleMeetingDetail[0].slots,
                    whenMeetingInitiateMsg: this.props.scheduleMeetingDetail[0].whenMeetingInitiateMsg,
                    whenTimeSlotsAvailableMsg:this.props.scheduleMeetingDetail[0].whenTimeSlotsAvailableMsg,
                    whenTimeSlotsNotAvailbleMsg:this.props.scheduleMeetingDetail[0].whenTimeSlotsNotAvailbleMsg,
                    whenTimeSlotSelectedMsg:this.props.scheduleMeetingDetail[0].whenTimeSlotSelectedMsg,
                    whenTimeSlotNotSelectedMsg: this.props.scheduleMeetingDetail[0].whenTimeSlotNotSelectedMsg,
                    whenTimeSlotsAvailableMsgForDays: this.props.scheduleMeetingDetail[0].daysMsg,
                    whenTimeSlotsAvailableMsgForTimeslot: this.props.scheduleMeetingDetail[0].timeslotsMsg,
                    meetingDuration: this.props.scheduleMeetingDetail[0].meetingTime,
                    emailIdMsg: this.props.scheduleMeetingDetail[0].emailIdMsg,
                    agendaMsg: this.props.scheduleMeetingDetail[0].agendaMsg,
                    fromEmail:this.props.scheduleMeetingDetail[0].from,
                    emailSubject:this.props.scheduleMeetingDetail[0].subject,
                    emailBody:this.props.scheduleMeetingDetail[0].mailbody,
                    emailToggle: this.props.scheduleMeetingDetail[0].email,
                    agendaToggle: this.props.scheduleMeetingDetail[0].agenda,
                    validationMsg:null,
                })
                
            }
            
            if(this.props.scheduleMeetingDetail && this.props.scheduleMeetingDetail.length == 0 && prevprops.scheduleMeetingDetail !== this.props.scheduleMeetingDetail && this.state.selectedTimeZone.length>0){
                this.setState({
                    //------------------------------------------------------------------------
                    noOfAgents:[''],
                    agentsID:'',
                    searchdays:5,
                    slotsPerDay:3,
                    //whenMeetingInitiateMsg: 'You seem to be interested in scheduling a meeting with an agent. Please give me a minute to search for the available time slots.',
                    whenTimeSlotsAvailableMsg:'In the “Days” textbox: Here are some days available from "<agent name>"s calendar. Let us know what works best for you. <days> In the “Timeslot” textbox: Here are some  available timeslots <timezone> for <selected day>. Let us know what works best for you.',
                    whenTimeSlotsNotAvailbleMsg:'Sorry all our agents seem to be busy. Currently, there are no timeslots available. Please try scheduling a meeting with our agents after some time.',
                    whenTimeSlotSelectedMsg:'I have scheduled a meeting on <Month Date (Timeslot) Timezone> with <agent name>. You should receive an email invite shortly at <visitor email address>.',
                    whenTimeSlotNotSelectedMsg: 'Sorry to hear that. Please try scheduling a meeting with our agents after some time.',
                    whenTimeSlotsAvailableMsgForDays:'Here are some days available from <agent name>\'s calendar. Let us know what works best for you.',
                    whenTimeSlotsAvailableMsgForTimeslot:'Here are some  available timeslots <timezone> for <selected day>. Let us know what works best for you.',
                    emailIdMsg: "You seem to be interested in scheduling a meeting with an agent. Please provide your email id before I can help you with the process.",
                    agendaMsg: "Thanks a lot for the email id. What would you like to explore during the meeting?",
                    meetingDuration:30,
                    fromEmail:'intelliService@intelliservices.onmicrosoft.com',
                    emailSubject:'HCL IntelliService: Follow up conversation',
                    emailBody:'Hi, \n It was nice chatting with you. As requested, this is a follow up meeting with our sales executive <agent name>.\n\nDate and Time: <Month Date (Timeslot) Timezone>\nAgenda: <agenda>\nRegards, \nHCL Virtual Assistant',
                    AgentsRouting:"Max Slots Availability",
                    validationMsg:"Agent configuration is not available or agent configuration is different for the selected project(s) and timezone(s)",
                })
                if(this.state.emailToggle == false && this.state.agendaToggle == true){
                  this.setState({
                    agendaMsg:"You seem to be interested in scheduling a meeting with an agent. What would you like to explore during the meeting?"
                  })
                }
                else{
                  this.setState({
                    agendaMsg:"Thanks a lot for the email id. What would you like to explore during the meeting?"
                  })
                }
                if(this.state.emailToggle == false && this.state.agendaToggle == false){
                  this.setState({
                    whenMeetingInitiateMsg:"You seem to be interested in scheduling a meeting with an agent. Please give me a minute to search for the available time slots."
                  })
                }
                else{
                  this.setState({
                    whenMeetingInitiateMsg:"Great! Please give me a minute to search for the available time slots."
                  })
                }
            }
            if(this.props.scheduleMeetingDetail && this.props.scheduleMeetingDetail.length == 0 && prevprops.scheduleMeetingDetail !== this.props.scheduleMeetingDetail && this.state.selectedTimeZone.length==0){
              this.setState({
                noOfAgents:[''],
                agentsID:'',
                searchdays:5,
                slotsPerDay:3,
                //whenMeetingInitiateMsg: 'You seem to be interested in scheduling a meeting with an agent. Please give me a minute to search for the available time slots.',
                whenTimeSlotsAvailableMsg:'In the “Days” textbox: Here are some days available from "<agent name>"s calendar. Let us know what works best for you. <days> In the “Timeslot” textbox: Here are some  available timeslots <timezone> for <selected day>. Let us know what works best for you.',
                whenTimeSlotsNotAvailbleMsg:'Sorry all our agents seem to be busy. Currently, there are no timeslots available. Please try scheduling a meeting with our agents after some time.',
                whenTimeSlotSelectedMsg:'I have scheduled a meeting on <Month Date (Timeslot) Timezone> with <agent name>. You should receive an email invite shortly at <visitor email address>.',
                whenTimeSlotNotSelectedMsg: 'Sorry to hear that. Please try scheduling a meeting with our agents after some time.',
                whenTimeSlotsAvailableMsgForDays:'Here are some days available from <agent name>\'s calendar. Let us know what works best for you.',
                whenTimeSlotsAvailableMsgForTimeslot:'Here are some  available timeslots <timezone> for <selected day>. Let us know what works best for you.',
                emailIdMsg: "You seem to be interested in scheduling a meeting with an agent. Please provide your email id before I can help you with the process.",
                agendaMsg: "Thanks a lot for the email id. What would you like to explore during the meeting?",
                meetingDuration:30,
                fromEmail:'intelliService@intelliservices.onmicrosoft.com',
                emailSubject:'HCL IntelliService: Follow up conversation',
                emailBody:'Hi, \n It was nice chatting with you. As requested, this is a follow up meeting with our sales executive <agent name>.\n\nDate and Time: <Month Date (Timeslot) Timezone>\nAgenda: <agenda>\nRegards, \nHCL Virtual Assistant',
                AgentsRouting:"Max Slots Availability",
                validationMsg:null,
              })
              if(this.state.emailToggle == false && this.state.agendaToggle == true){
                this.setState({
                  agendaMsg:"You seem to be interested in scheduling a meeting with an agent. What would you like to explore during the meeting?"
                })
              }
              else{
                this.setState({
                  agendaMsg:"Thanks a lot for the email id. What would you like to explore during the meeting?"
                })
              }
              if(this.state.emailToggle == false && this.state.agendaToggle == false){
                this.setState({
                  whenMeetingInitiateMsg:"You seem to be interested in scheduling a meeting with an agent. Please give me a minute to search for the available time slots."
                })
              }
              else{
                this.setState({
                  whenMeetingInitiateMsg:"Great! Please give me a minute to search for the available time slots."
                })
              }
           
          }
          if(this.props.agentDetail && prevprops.agentDetail != this.props.agentDetail){
            const temp=[]
            for(var i=0;i<this.props.agentDetail.length;i++){
              temp.push(this.props.agentDetail[i].username)
            }
            this.setState({agents:temp})
          }
    }
     onProjectDropDown=(event)=>{
        if(this.state.selectedTimeZone.length>0){
          let timezone=(this.props.timeZone.length === this.state.selectedTimeZone.length)?['All']:this.state.selectedTimeZone
            let url = this.props.config.BOTBUILDER_URL + `/schedule-meeting-config/` + event.target.value + `/` + timezone;
            this.props.onGetAllScheduleMeetingDetail(url)
        }
        this.setState({
            prevSelectedProject:this.state.selectedProject,
            selectedProject:event.target.value,
        })
        //localStorage.setItem("projectname",event.target.value)
        
      }

    onChangeTimeZone = (newValue) =>{
        var temp = []
        for(var i=0;i<newValue.length;i++){ 
                temp.push(newValue[i].value)
        }
        if(this.state.selectedProject.length>0 && temp.length>0){
          let timezone=(this.props.timeZone.length === temp.length)?['All']:temp;
            let url = this.props.config.BOTBUILDER_URL + `/schedule-meeting-config/` + this.state.selectedProject + `/` + timezone
            this.props.onGetAllScheduleMeetingDetail(url)
        }
        else{
            this.setState({
              noOfAgents:[''],
              agentsID:'',
              searchdays:4,
              slotsPerDay:4,
              whenMeetingInitiateMsg: 'You seem to be interested in scheduling a meeting with an agent. Please give me a minute to search for the available time slots.',
              whenTimeSlotsAvailableMsg:'In the “Days” textbox: Here are some days available from "<agent name>"s calendar. Let us know what works best for you. <days> In the “Timeslot” textbox: Here are some  available timeslots <timezone> for <selected day>. Let us know what works best for you.',
              whenTimeSlotsNotAvailbleMsg:'Sorry all our agents seem to be busy. Currently, there are no timeslots available. Please try scheduling a meeting with our agents after some time.',
              whenTimeSlotSelectedMsg:'I have scheduled a meeting on <Month Date (Timeslot) Timezone> with <agent name>. You should receive an email invite shortly at <visitor email address>.',
              whenTimeSlotNotSelectedMsg: 'Sorry to hear that. Please try scheduling a meeting with our agents after some time.',
              whenTimeSlotsAvailableMsgForDays:'Here are some days available from <agent name>\'s calendar. Let us know what works best for you.',
              whenTimeSlotsAvailableMsgForTimeslot:'Here are some  available timeslots <timezone> for <selected day>. Let us know what works best for you.',
              emailIdMsg: "You seem to be interested in scheduling a meeting with an agent. Please provide your email id before I can help you with the process.",
              agendaMsg: "Thanks a lot for the email id. What would you like to explore during the meeting?",
              meetingDuration:30,
              fromEmail:'intelliService@intelliservices.onmicrosoft.com',
              emailSubject:'HCL IntelliService: Follow up conversation',
              emailBody:'Hi, \n It was nice chatting with you. As requested, this is a follow up meeting with our sales executive <agent name>.\n\nDate and Time: <Month Date (Timeslot) Timezone>\nAgenda: <agenda>\nRegards, \nHCL Virtual Assistant',
              AgentsRouting:"Max Slots Availability",
              validationMsg:null,
            })
            if(this.state.emailToggle == false && this.state.agendaToggle == true){
              this.setState({
                agendaMsg:"You seem to be interested in scheduling a meeting with an agent. What would you like to explore during the meeting?"
              })
            }
            else{
              this.setState({
                agendaMsg:"Thanks a lot for the email id. What would you like to explore during the meeting?"
              })
            }
            if(this.state.emailToggle == false && this.state.agendaToggle == false){
              this.setState({
                whenMeetingInitiateMsg:"You seem to be interested in scheduling a meeting with an agent. Please give me a minute to search for the available time slots."
              })
            }
            else{
              this.setState({
                whenMeetingInitiateMsg:"Great! Please give me a minute to search for the available time slots."
              })
            }
        }
        this.setState({
            prevSelectedTimeZone:this.state.selectedTimeZone,
            selectedTimeZone:temp
        })
    }

    onRemoveItemHandler = (item) =>{
        var temp = [...this.state.selectedProject]
        var index = temp.indexOf(item)
        temp.splice(index,1)
        this.setState({selectedProject:temp})
    }

    onRemoveTimeZone = (item) =>{
        var temp = [...this.state.selectedTimeZone]
        var index = temp.indexOf(item)
        temp.splice(index,1)
        this.setState({selectedTimeZone:temp})
        if(temp.length>0){
          this.setState({validationMsg:"Agent configuration is not available or agent configuration is different for the selected project(s) and timezone(s)",})
        }
        else{
          this.setState({validationMsg:null,})
        }
       
            this.setState({
              noOfAgents:[''],
              agentsID:'',
              searchdays:4,
              slotsPerDay:4,
              whenMeetingInitiateMsg: 'You seem to be interested in scheduling a meeting with an agent. Please give me a minute to search for the available time slots.',
              whenTimeSlotsAvailableMsg:'In the “Days” textbox: Here are some days available from "<agent name>"s calendar. Let us know what works best for you. <days> In the “Timeslot” textbox: Here are some  available timeslots <timezone> for <selected day>. Let us know what works best for you.',
              whenTimeSlotsNotAvailbleMsg:'Sorry all our agents seem to be busy. Currently, there are no timeslots available. Please try scheduling a meeting with our agents after some time.',
              whenTimeSlotSelectedMsg:'I have scheduled a meeting on <Month Date (Timeslot) Timezone> with <agent name>. You should receive an email invite shortly at <visitor email address>.',
              whenTimeSlotNotSelectedMsg: 'Sorry to hear that. Please try scheduling a meeting with our agents after some time.',
              whenTimeSlotsAvailableMsgForDays:'Here are some days available from <agent name>\'s calendar. Let us know what works best for you.',
              whenTimeSlotsAvailableMsgForTimeslot:'Here are some  available timeslots <timezone> for <selected day>. Let us know what works best for you.',
              emailIdMsg: "You seem to be interested in scheduling a meeting with an agent. Please provide your email id before I can help you with the process.",
              agendaMsg: "Thanks a lot for the email id. What would you like to explore during the meeting?",
              meetingDuration:30,
              fromEmail:'intelliService@intelliservices.onmicrosoft.com',
              emailSubject:'HCL IntelliService: Follow up conversation',
              emailBody:'Hi, \n It was nice chatting with you. As requested, this is a follow up meeting with our sales executive <agent name>.\n\nDate and Time: <Month Date (Timeslot) Timezone>\nAgenda: <agenda>\nRegards, \nHCL Virtual Assistant',
              AgentsRouting:"Max Slots Availability",
            })
            if(this.state.emailToggle == false && this.state.agendaToggle == true){
              this.setState({
                agendaMsg:"You seem to be interested in scheduling a meeting with an agent. What would you like to explore during the meeting?"
              })
            }
            else{
              this.setState({
                agendaMsg:"Thanks a lot for the email id. What would you like to explore during the meeting?"
              })
            }
            if(this.state.emailToggle == false && this.state.agendaToggle == false){
              this.setState({
                whenMeetingInitiateMsg:"You seem to be interested in scheduling a meeting with an agent. Please give me a minute to search for the available time slots."
              })
            }
            else{
              this.setState({
                whenMeetingInitiateMsg:"Great! Please give me a minute to search for the available time slots."
              })
            }
        //}
    }

    addAgents = (even) => {
      var temp = [...this.state.noOfAgents]
      temp.push('')
      this.setState({
        noOfAgents:temp,
        agentsMatch:[]
    })
    }

    removeAgents = (index) => {
      var temp = [...this.state.noOfAgents]
      temp.splice(index,1)
      this.setState({
        noOfAgents:temp,
        agentVal:[],
        agentsMatch:[],
        agentIndex:0
    })
    if(temp){
      for(var i=0;i<temp.length;i++){
        if(temp[i] != '')
        {
          this.setState({enableButton:true})
          break;
        }
        else{
          this.setState({enableButton:false})
        }
      }
    }
    else{
      this.setState({enableButton:false})
    }
    }

    focus_Out = (event) =>{
      this.setState({agentsMatch:[]})
    }
    searchAgents = (text,index) => {
      var l,t
      if(!text){
        var temp = [...this.state.noOfAgents]
        temp[index]=''
        this.setState({
          agentsMatch:[],
          noOfAgents:temp
      })
      }
      else{
      let matches = this.state.agents.filter((agent)=>{
        const regex = new RegExp(`${text}`,'gi');
        l = document.getElementById(index+"card").offsetLeft - document.getElementById(index+"card").scrollLeft;
        t = document.getElementById(index+"card").offsetTop - document.getElementById(index+"card").scrollTop + 40;
        return agent.match(regex)
      })
      var temp = [...this.state.noOfAgents]
      temp[index]=text
      this.setState({
        validationMsg:null,
        left:l,
        top:t,
        noOfAgents:temp,
        agentsMatch:matches,
        agentIndex:index,
    })
    }
    if(temp){
      for(var i=0;i<temp.length;i++){
        if(temp[i] != '')
        {
          this.setState({enableButton:true})
          break;
        }
        else{
          this.setState({enableButton:false})
        }
      }
    }
    else{
      this.setState({enableButton:false})
    }
    }

    setAgentVal = (agent) => {
      var temp = [...this.state.noOfAgents]
      temp[this.state.agentIndex]=agent
      this.setState({
        noOfAgents:temp,
        agentsMatch:[],
        
    })
    }

    onMeetingDurationDropDown=(event)=>{
        this.setState({
            meetingDuration:event.target.value
        })
      }
      onChangeFromEmail=(event)=>{
        this.setState({
            fromEmail:event.target.value
        })
      }
      onChangeSubject=(event)=>{
        this.setState({
            emailSubject:event.target.value
        })
      }
      onChangeBody=(event)=>{
        this.setState({
            emailBody:event.target.value
        })
      }

    onChangeHandler=(event)=>{
        this.setState({
          agentsID: event.target.value,
          saveSetting:true,
          validationMsg:null
        })
      }
    onChangesearchdays=(event) =>{
        this.setState({
            searchdays: event.target.value,
          })
    }

    onChangeslotsPerDay=(event) =>{
        this.setState({
            slotsPerDay: event.target.value,
          })
    }

    selectedTab=(nodeName)=>{
        this.setState({
          selectedTab:nodeName
        })
      }

      saveSettings = (e)=>{
        var agentArr = []
        for(var i=0;i<this.state.noOfAgents.length;i++){
          if(this.state.noOfAgents[i] != '')
            agentArr.push(this.state.noOfAgents[i])
        }
        var data ={
            "channel": "MS Outlook",
            "project":this.state.selectedProject,
            "timeZone": this.state.selectedTimeZone,
            "agents": agentArr,
            "days": this.state.searchdays,
            "slots": this.state.slotsPerDay,
            "routingMethod": "Max Slots Availability",
            "whenMeetingInitiateMsg":this.state.whenMeetingInitiateMsg,
            "whenTimeSlotsAvailableMsg": this.state.whenTimeSlotsAvailableMsg,
            "whenTimeSlotsNotAvailbleMsg": this.state.whenTimeSlotsNotAvailbleMsg,
            "whenTimeSlotSelectedMsg": this.state.whenTimeSlotSelectedMsg,
            "whenTimeSlotNotSelectedMsg": this.state.whenTimeSlotNotSelectedMsg,
            "daysMsg": this.state.whenTimeSlotsAvailableMsgForDays,
            "timeslotsMsg": this.state.whenTimeSlotsAvailableMsgForTimeslot,
            "meetingTime": this.state.meetingDuration,
            "fromMailId": this.state.fromEmail,
            "subject": this.state.emailSubject,
            "mailbody": this.state.emailBody,
            "emailIdMsg": this.state.emailIdMsg,
            "agendaMsg": this.state.agendaMsg,
            "agenda": this.state.agendaToggle,
            "email": this.state.emailToggle
        }
        let url = this.props.config.BOTBUILDER_URL;
        this.props.onSaveSettings(url,data);
        this.setState({validationMsg:null})
      }

      onChangeHandler2 = (e, name) =>{
            if(name == "whenMeetingInitiateMsg")
                this.setState({whenMeetingInitiateMsg:e.target.value})
            if(name == "whenTimeSlotsAvailableMsg")
                this.setState({whenTimeSlotsAvailableMsg:e.target.value})
            if(name == "whenTimeSlotsNotAvailbleMsg")
                this.setState({whenTimeSlotsNotAvailbleMsg:e.target.value})
            if(name == "whenTimeSlotSelectedMsg")
                this.setState({whenTimeSlotSelectedMsg:e.target.value})
            if(name == "whenTimeSlotNotSelectedMsg")
                this.setState({whenTimeSlotNotSelectedMsg:e.target.value})
            if(name == "whenTimeSlotsAvailableMsgForDays")
                this.setState({whenTimeSlotsAvailableMsgForDays:e.target.value})
            if(name == "whenTimeSlotsAvailableMsgForTimeslot")
                this.setState({whenTimeSlotsAvailableMsgForTimeslot:e.target.value})
            if(name == "askForEmail")
                this.setState({emailIdMsg:e.target.value})
            if(name == "askForAgenda")
                this.setState({agendaMsg:e.target.value})
      }

      emailSentToAgent = () =>{
        this.setState({
          emailSentToAgent:true,
          emailSentToUser:false
        })
      }
     
      emailSentToUser = () =>{
        this.setState({
          emailSentToAgent:false,
          emailSentToUser:true
        })
      }

      emailHandleChange = (e) =>{
        this.setState({
          emailToggle:e.target.checked
        })
        if(e.target.checked == false && this.state.agendaToggle == true){
          this.setState({
            agendaMsg:"You seem to be interested in scheduling a meeting with an agent. What would you like to explore during the meeting?"
          })
        }
        else{
          this.setState({
            agendaMsg:"Thanks a lot for the email id. What would you like to explore during the meeting?"
          })
        }
        if(e.target.checked == false && this.state.agendaToggle == false){
          this.setState({
            whenMeetingInitiateMsg:"You seem to be interested in scheduling a meeting with an agent. Please give me a minute to search for the available time slots."
          })
        }
        else{
          this.setState({
            whenMeetingInitiateMsg:"Great! Please give me a minute to search for the available time slots."
          })
        }
      }

      agendaHandleChange = (e) =>{
        this.setState({
          agendaToggle:e.target.checked
        })
        if(this.state.emailToggle == false && e.target.checked == true){
          this.setState({
            agendaMsg:"You seem to be interested in scheduling a meeting with an agent. What would you like to explore during the meeting?"
          })
        }
        else{
          this.setState({
            agendaMsg:"Thanks a lot for the email id. What would you like to explore during the meeting?"
          })
        }
        if(e.target.checked == false && this.state.emailToggle == false){
          this.setState({
            whenMeetingInitiateMsg:"You seem to be interested in scheduling a meeting with an agent. Please give me a minute to search for the available time slots."
          })
        }
        else{
          this.setState({
            whenMeetingInitiateMsg:"Great! Please give me a minute to search for the available time slots."
          })
        }
      }

      render(){
        const label = { inputProps: { 'aria-label': 'Switch demo' } };
        const GreenSwitch = styled(Switch)(({ theme }) => ({
          '& .MuiSwitch-switchBase.Mui-checked': {
            color: blue[600],
            '&:hover': {
              backgroundColor: alpha(blue[600], theme.palette.action.hoverOpacity),
            },
          },
          '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: blue[600],
          },
        }));
          return(
            <Container className="ScheduleMeetingContainer" onClick={(e) => this.focus_Out(e)}>
                <Grid container md={12} xs={12} xl={12}>
                    <Grid item md={6} xs={6} xl={6}>
                    <h3>Schedule Meeting</h3>
                
                    </Grid>
                    <Grid className="pr-2" style={{display:'flex',justifyContent:'space-between',width:'100%',alignItems:'center'}}>
                        <span className="paraGraph">Provision to configure the agents, the routing rules, the messages and the emails for the scheduling of meeting functionality.</span>
                        <ButtonCustom variant='contained' disabled={((this.state.noOfAgents.length>0 && this.state.noOfAgents[0] == '') || this.state.selectedTimeZone.length==0?true:this.props.permissions.RW?false:true)} label='Save Settings'className='buttonWidth'  clicked={(e) =>this.saveSettings(e)}/>
                    </Grid>
                </Grid>
                <Grid container md={12} xs={12} xl={12} className="topDropDown">
                    <Grid item md={4} xs={4} xl={4} className="pr-5">
                        <SelectCustom
                        name="selectName"
                        label="Channel"
                        //changed={(event) => this.onSelectHandler(event)}
                        required={true}
                        value={"MS Outlook"}
                        options={[{value:'MS Outlook',displayValue:'MS Outlook'},]}
                        />  
                    </Grid>
                     {/* <Grid item md={4} className="pr-5">
                            <ReactMultiSelectComponent
                                
                                options={this.props.projectList && this.props.projectList.bots.map(user =>(
                                    {value:user,label:user}
                                ))}
                                value={this.state.selectedProject}
                                onChange={(newValue) => this.onProjectDropDown(newValue)}
                                label="Project"
                                onRemoveItem={this.onRemoveItemHandler}
                                placeholder={'Select Project'}
                            />
                    </Grid> */}

                    <Grid item md={4} xs={4} xl={4} className="pr-5">
                    <SelectCustom
                     name="selectName"
                     label="Project"
                     changed={(event) => this.onProjectDropDown(event)}
                     required={true}
                     value={this.state.selectedProject}
                     options={this.state.virtualAssistantProjects && this.state.virtualAssistantProjects.map(user =>(
                      {value:user,displayValue:user}
                      // {value:user,displayValue:user}
                  ))
                  }/>
                  </Grid>

                    <Grid item md={4} xs={4} xl={4} className="pr-0 timezone-wrapper" >
                            <ReactMultiSelectComponent
                                value={this.state.selectedTimeZone}
                                options={this.props.timeZone && this.props.timeZone.map(timeZone =>(
                                    {value:timeZone,label:timeZone}
                                ))}
                                onChange={(newValue) => this.onChangeTimeZone(newValue)}
                                label="Timezone "
                                onRemoveItem={this.onRemoveTimeZone}
                                placeholder={'Select Timezone'}
                            />
                     </Grid>
                     <Grid Container md={12} xs={12} xl={12} className="tabsMainContent">
                        <Grid item>
                            <TabCustom
                            tabs={["Schedule Meeting Configuration", "Message Configuration", "Email Configuration"]}
                            selected={this.state.selectedTab}
                            setSelected={this.selectedTab}
                            >
                            </TabCustom>
                        </Grid>
                    </Grid>
                    {this.state.selectedTab ==='Schedule Meeting Configuration'?
                    <>
                        <Grid container md={12} xs={12} xl={12} className="MeetingConfiguredDiv">
                        
                            <Grid item md={4} xs={4} xl={4} className="pr-5" >
                            
                            {/* <Box className='textAreabox'> */}
                              
                            <div className='MeetingText'> Agents
                            <span style={{color:"black",cursor:"pointer"}} onClick = {(event) => this.addAgents(event)}><AddCircleIcon style={{fontSize:"20px"}}></AddCircleIcon></span>
                            {/* <LightTooltip
                                arrow
                                title="Provide the list of agents using comma separator"
                                placement="top"
                            >
                                <img
                                src="./images/info_icon.png"
                                style={{ position: "absolute", top: "6px", left: "110px" }}
                                />
                            </LightTooltip> */}
                            </div>
                            {/* <TextAreaWithLabelComponent 
                                        label="Agents configured"
                                        name="message"
                                        required ={true}
                                        value={this.state.agentsID}
                                        className='text-area'
                                        placeholder='Enter agent email ids '
                                        onChange={(e) =>this.onChangeHandler(e)}
                                        style={{height:"190px"}}
                                    /> */}
                            {/* </Box> */}
                            <Box className='textAreabox' style={{maxHeight:"200px", overflowY:"scroll"}}>
                              {this.state.noOfAgents && this.state.noOfAgents.map((item, index)=>{
                                return(
                                  <div>
                                    <input  id={index+"card"} className="textFocus default_css" onChange={(e) => this.searchAgents(e.target.value,index)} value={this.state.noOfAgents[index]} spellcheck="false" autocomplete="off"/>
                                    {this.state.noOfAgents.length>1?<span onClick = {() => this.removeAgents(index)} style={{cursor:"pointer"}}><RemoveCircleIcon style={{fontSize:"20px"}}></RemoveCircleIcon></span>:<span></span>}
                                  </div>
                                )
                              }
                              )}
                            </Box>
                            <div id="Card" style={{position:"absolute", width:"400px", maxHeight:"150px", overflowY:"scroll", overflowX:"hidden", left:this.state.left, top:this.state.top}}>
                                    {this.state.agentsMatch && this.state.agentsMatch.map((item)=>{
                                        return(
                                          <Card>
                                            <CardContent onClick = {()=>this.setAgentVal(item)} style={{padding:"5px",cursor:"pointer"}}>
                                            {item}
                                            </CardContent>
                                          </Card>
                                        )
                                      })
                                    }
                                    </div>
                            <p style={{color:"red", textTransform: "inherit",lineHeight: "20px"}}><strong style={{fontFamily: "Roboto-Bold",fontSize: "12px",}}>{this.state.validationMsg}</strong></p>
                            </Grid>

                        <Grid item md={4} xs={4} xl={4} className="pr-5">
                            <InputWithLabelComponent
                      
                                type="text"
                                name="name"
                                label="Days for which slots should be searched"
                                value={this.state.searchdays}
                                readOnly={false}
                                onChange={(e) => this.onChangesearchdays(e)}
                                required={true}
                                placeholder='Enter Text '
                                autocomplete="new-password"
                            />
                            <InputWithLabelComponent
                                type="text"
                                name="name"
                                label="Slots to be searched per day" 
                                value={this.state.slotsPerDay}
                                readOnly={false}
                                onChange={(e) => this.onChangeslotsPerDay(e)}
                                required={true}
                                placeholder='Enter Text '
                                autocomplete="new-password"
                            />
                            <SelectCustom
                                name="selectName"
                                label="Meeting duration"
                                changed={(event) => this.onMeetingDurationDropDown(event)}
                                required={true}
                                value={this.state.meetingDuration}
                                options={[{value:"15",displayValue:"15 min"},{value:"30",displayValue:"30 min"},{value:"45",displayValue:"45 min"},{value:"60",displayValue:"1 hr"}]}
                            />
                        </Grid>

                        <Grid item md={4} xs={4} xl={4} className="pr-2">
                        <div class="common-input ">
                        <label class="mb-1 text-box-title-text adding-other-input form-label">Routing method
                        </label>
                        </div>
                        <Box className='AgentsRoutingbox  Agents-Routing-Box'>
                            <ul className='AgentsRouting' style={{height:"190px"}}>
                            {/* <li className={((this.state.AgentsRouting=='Round Robin') ? "active": "")} onClick={()=>this.setState({"AgentsRouting":"Round Robin","saveSetting":true})}>Round Robin</li> */}
                            <li className={((this.state.AgentsRouting=='Max Slots Availability') ? "active": "")} onClick={()=>this.setState({"AgentsRouting":"Max Slots Availability","saveSetting":true})}>Max Slots Availability</li>
                            <li className={((this.state.AgentsRouting=='Best Agent') ? "active": "")} onClick={()=>this.setState({"AgentsRouting":"Best Agent","saveSetting":true})}>Best Agent</li>
                            </ul>
                            </Box>
                        </Grid>
                    </Grid>
                    </>:null}
                 </Grid>
                 {this.state.selectedTab==='Message Configuration'?
                <>
                    <h4 style={{marginTop:'20px'}}>Messages Shown to User</h4>
                    {/* <div className='MeetingText'> 
                            <LightTooltip
                                arrow
                                title="Messages Shown to User"
                                placement="top"
                            >
                                <img
                                src="./images/info_icon.png"
                                style={{ position: "absolute", display:"inline", top: "-22px", left: "165px" }}
                                />
                            </LightTooltip>
                    </div> */}
                    <div style={{marginTop:'20px',fontFamily: "Roboto-Bold",fontSize: "12px", color: "rgb(105, 104, 104)", textTransform: "inherit"}}>When user initiates scheduling of meeting</div>
                    <label style={{fontFamily:"Roboto-Bold",fontSize:"12px",color:"#696868",textTransform:"inherit"}}>Capture user email id</label><GreenSwitch onChange={(e) => this.emailHandleChange(e)} {...label} checked={this.state.emailToggle} />
                    <label style={{fontFamily:"Roboto-Bold",fontSize:"12px",color:"#696868",textTransform:"inherit"}}>Capture agenda</label><GreenSwitch onChange={(e) => this.agendaHandleChange(e)} {...label} checked={this.state.agendaToggle} />
                    <Grid container md={12} xs={12} xl={12}>
                    <Grid item md={4} xs={4} xl={4} style={{marginTop:'10px',paddingRight:'30px',}}>
                            <TextAreaWithLabelComponent
                                type="text"
                                name="name"
                                label="Message to capture user email id"
                                value={this.state.emailIdMsg}
                                readOnly={false}
                                onChange={(e) => this.onChangeHandler2(e, 'askForEmail')}
                                required={true}
                                autocomplete="new-password"
                                style={{height:"60px",padding:"8px"}}
                                disabled={!this.state.emailToggle}
                            />
                        </Grid>
                        <Grid item md={4} xs={4} xl={4} style={{marginTop:'10px',paddingRight:'30px',}}>
                            <TextAreaWithLabelComponent
                                type="text"
                                name="name"
                                label="Message to capture agenda"
                                value={this.state.agendaMsg}
                                readOnly={false}
                                onChange={(e) => this.onChangeHandler2(e, 'askForAgenda')}
                                required={true}
                                autocomplete="new-password"
                                style={{height:"60px",padding:"8px"}}
                                disabled={!this.state.agendaToggle}
                            />
                        </Grid>
                    </Grid>
                    <Grid container md={12} xs={12} xl={12}>
                        <Grid item md={8} xs={8} xl={8} style={{marginTop:'10px',paddingRight:'30px',}}>
                            <TextAreaWithLabelComponent
                                type="text"
                                name="name"
                                label="Message to initiate scheduling of meeting"
                                value={this.state.whenMeetingInitiateMsg}
                                readOnly={false}
                                onChange={(e) => this.onChangeHandler2(e, 'whenMeetingInitiateMsg')}
                                required={true}
                                autocomplete="new-password"
                                style={{height:"60px",padding:"8px"}}
                            />
                        </Grid>
                        
                    </Grid>

                    <div style={{marginTop:'20px',fontFamily: "Roboto-Bold",fontSize: "12px", color: "rgb(105, 104, 104)", textTransform: "inherit"}}>When timeslots are available</div>
                    <Grid container md={12}  xs={12} xl={12}>
                      <Grid item md={4} xs={4} xl={4} style={{marginTop:'8px',paddingRight:'30px',}}>
                          <TextAreaWithLabelComponent
                            type="text"
                            name="name"
                            label="Message to display the available days"
                            value={this.state.whenTimeSlotsAvailableMsgForDays}
                            readOnly={false}
                            onChange={(e) => this.onChangeHandler2(e, 'whenTimeSlotsAvailableMsgForDays')}
                            required={true}
                            autocomplete="new-password"
                            style={{height:"60px",padding:"8px"}}
                          />
                        </Grid>
                        <Grid item md={4} xs={4} xl={4} style={{marginTop:'8px',paddingRight:'30px',}}>
                          <TextAreaWithLabelComponent
                            type="text"
                            name="name"
                            label="Message to display the available timeslots"
                            value={this.state.whenTimeSlotsAvailableMsgForTimeslot}
                            readOnly={false}
                            onChange={(e) => this.onChangeHandler2(e, 'whenTimeSlotsAvailableMsgForTimeslot')}
                            required={true}
                            autocomplete="new-password"
                            style={{height:"60px",padding:"8px"}}
                          />
                        </Grid>
                    </Grid>

                    <Grid container md={12} xs={12} xl={12}>
                      <Grid item md={4} xs={4} xl={4} style={{marginTop:'8px',paddingRight:'30px',}}>
                          {/* <TextAreaWithLabelComponent
                            type="text"
                            name="name"
                            label="When timeslots are availabel"//{symtomtitle}
                            value={this.state.whenTimeSlotsAvailableMsg}
                            readOnly={false}
                            onChange={(e) => this.onChangeHandler2(e, 'whenTimeSlotsAvailableMsg')}
                            required={true}
                            autocomplete="new-password"
                            style={{height:"60px",padding:"8px"}}
                          /> */}
                          <TextAreaWithLabelComponent
                              type="text"
                              name="name"
                              label="When none of the timeslots is selected"
                              value={this.state.whenTimeSlotNotSelectedMsg}
                              readOnly={false}
                              onChange={(e) => this.onChangeHandler2(e, 'whenTimeSlotNotSelectedMsg')}
                              required={true}
                              autocomplete="new-password"
                              style={{height:"60px",padding:"8px"}}
                          /> 
                          </Grid>

                          <Grid item md={4} xs={4} xl={4} style={{marginTop:'8px',paddingRight:'30px',}}>
                            <TextAreaWithLabelComponent
                                type="text"
                                name="name"
                                label="When a specific timeslot is selected"
                                value={this.state.whenTimeSlotSelectedMsg}
                                readOnly={false}
                                onChange={(e) => this.onChangeHandler2(e, 'whenTimeSlotSelectedMsg')}
                                required={true}
                                autocomplete="new-password"
                                style={{height:"60px",padding:"8px"}}
                            />
                            </Grid>

                          <Grid item md={4} xs={4} xl={4} style={{marginTop:'8px',paddingRight:'30px',}}>
                          <TextAreaWithLabelComponent
                              type="text"
                              name="name"
                              label="When timeslots are not available"
                              value={this.state.whenTimeSlotsNotAvailbleMsg}
                              readOnly={false}
                              onChange={(e) => this.onChangeHandler2(e, 'whenTimeSlotsNotAvailbleMsg')}
                              required={true}
                              autocomplete="new-password"
                              style={{height:"60px",padding:"8px"}}
                          />
                          </Grid>
                          
                    </Grid>
                    
                </>
                :null}
                {this.state.selectedTab ==='Email Configuration'?
                  <>
                            <Grid container spacing={2} style={{marginTop:"5px",}}>
                                <Grid item xs={3} md={3} xl={3}>
                                  <Item style={{textAlign:"start",height:"110%"}}>
                                      <div style={{padding:"5px",color:this.state.emailSentToAgent?"#5A85F2":''}}><a onClick={()=>this.emailSentToAgent()}>Email sent to Agent</a></div>
                                      <div style={{padding:"5px", color:this.state.emailSentToUser?"#5A85F2":''}}><a onClick={()=>this.emailSentToUser()}>Email sent to User</a></div>
                                  </Item>
                                </Grid>
                                <Grid item xs={9} md={9} xl={9} style={{backgroundColor:"inherit", marginTop:"20px"}}>
                                  {this.state.emailSentToAgent &&<>
                                    <Grid xs={12} md={12} xl={12}>
                                        <Grid item xs={6} md={6} xl={6}>
                                          <h4 style={{display:"flex"}}>Email sent to Agent -  When meeting is scheduled
                                          <div className='AgentsText' style={{paddingLeft:"5px"}}> 
                                            <LightTooltip
                                                arrow
                                                title="The same email is also sent to user"
                                                placement="top"
                                              >
                                                <img src="./images/info_icon.png"/>
                                              </LightTooltip>
                                          </div>
                                          </h4>
                                        </Grid>
                                    </Grid>

                                    <Grid container  xs={12} md={12} xl={12} style={{backgroundColor:"inherit", marginTop:"-20px"}}>
                                          <Grid item xs={6} md={6} xl={6} style={{marginTop:'30px',paddingRight:'30px',}}>
                                            <InputWithLabelComponent
                                              type="text"
                                              name="name"
                                              label="From email id"
                                              value={this.state.fromEmail}
                                              disabled
                                              readOnly={false}
                                              onChange={(e) => this.onChangeFromEmail(e)}
                                              required={true}
                                              //disabled={true}
                                              autocomplete="new-password"
                                            />
                                              <InputWithLabelComponent
                                              type="text"
                                              name="name"
                                              label="Subject of email"
                                              value={this.state.emailSubject}
                                              readOnly={false}
                                              onChange={(e) => this.onChangeSubject(e)}
                                              required={true}
                                              autocomplete="new-password"
                                            />
                                          </Grid>
                                          <Grid item xs={6} md={6} xl={6} style={{marginTop:'30px',paddingRight:'30px',}}>
                                              <Box className=''>
                                                  <div className='AgentsText'> 
                                                    <LightTooltip
                                                        arrow
                                                        title="Write Email"
                                                        placement="top"
                                                      >
                                                        <img
                                                          src="./images/info_icon.png"
                                                          style={{ position: "absolute", top: "6px", left: "80px" }}
                                                        />
                                                      </LightTooltip>
                                                  </div>
                                                    <TextAreaWithLabelComponent 
                                                          label="Body of email"
                                                          name="message"
                                                          required ={true}
                                                          value={this.state.emailBody}
                                                          className='text-area'
                                                          placeholder=''
                                                          onChange={(e) =>this.onChangeBody(e)}
                                                          style={{height:"187px",padding: "4px"}}
                                                          autocomplete="new-password"
                                                    />
                                              </Box>
                                          </Grid>
                                    </Grid>
                                  </>}
                                </Grid>
                            </Grid>
                    </>:null}
            </Container>
          )
      }
}

const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 12,
      fontFamily: "Roboto-Regular",
    },
    arrow: {
      color: theme.palette.common.white,
      "&:before": {
        border: "1px solid #E6E8ED",
        boxShadow: theme.shadows[1],
      }
    },
  }))(Tooltip); 

const mapStateToProps = state => {
    return {
        loading: state.agentTransfer.loading,
        agentList:state.agentTransfer.agentList,
        projectList:state.user.projects,
        timeZone: state.agentTransfer.timeZone,
        scheduleMeetingDetail : state.agentTransfer.scheduleMeetingDetail,
        permissions:state.user.moduleAccess.conversationalAdmin,
        agentDetail:state.agentTransfer.agentDetail,
        projects: state.projectview.AllProjectsList,
        AssistantsList: state.projectview.AllAssistantsList,
    };
  };
  
  const mapDispatchToProps = (dispatch) => ({
    onListAgent: (APIURL,project ) => dispatch( actions.listActiveAgent(APIURL,project ) ),
    onSaveSettings: (APIURL, data) => dispatch( actions.saveSchedulMeetingSettings(APIURL, data ) ),
    onGetTimezones: (APIURL) => dispatch( actions.getTimezones(APIURL) ),
    onGetAllScheduleMeetingDetail:(url, project) => dispatch(actions.getAllScheduleMeetingDetail(url, project)),
    onGetAgentDetails:(url) => dispatch(actions.getAgentDetails(url))
  });

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleMeeting);