import React from 'react';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';
import Slider from '../../../components/UI/Slider/Slider';
import { Button } from 'react-bootstrap';
import Input from '../../UI/Input/InputCustom';
import PropTypes from 'prop-types';
import './Resolution.css';
import { useDispatch, useSelector } from "react-redux";
import { SetWarningMessage } from "../../../store/actions/message";

const ResolutionsPage = ({
    handleSwitchChange,
    size,
    resolutionsCount,
    inputChangedHandler,
    handleSlideChange,
    similarity,
    saveButtonClickHandler,
    enabled,
    ownProps
}) => {
    const dispatch = useDispatch();
         const similarStatus = useSelector(
            (state) => state.insights.similartickets.enabled
        );  
        const issueCStatus = useSelector(
            (state) => state.insights.issueclassification.enabled
        );
        const check = (e)=>{
            if(ownProps.permissions==false) return true

            if(issueCStatus!=false || similarStatus!=false ) {
                handleSwitchChange(e)
            }else{
                dispatch(SetWarningMessage("One Tab Should be Enable"));
            }
        }
    return (
        <div className="ResolutionsPage" >
            <Accordion defaultActiveKey="0">
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="1">
						<span id="TopResolutions-icon" ><img src="./images/TopResolutions.svg" className="InsightsTabsIcons" />Top Resolutions </span>
                       
                                <div className="ResolutionActions">
                            <Form>
                                <Form.Check
                                    type="switch"
                                    id="ResolutionCustomSwitch"
                                    onChange={e => check(e)}
                                    checked={ownProps.permissions && enabled?true:false}
                                />
                            </Form>
                            <span class="Settings-icon" ><img src="./images/SettingsGrey.svg" /></span>
                        </div>

                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body>
                            <div className="col-md-4 ResolutionsCount">
                                <Form.Group>
                                    <Form.Label>Total No. of Resolutions</Form.Label>
                                    <Input
                                        key="resolutionsCount"
                                        elementType="select"
                                        elementConfig={resolutionsCount.elementConfig}
                                        value={size}
                                        changed={(event) => inputChangedHandler(event, 'size')}
                                    />
                                </Form.Group>
                            </div>
                            <div className="SimilarityLevel">
                                <p>Similarity confidence level </p>
                               <Slider
                                    minValue={50}
									maxValue={100}
                                    value={similarity}
                                    onChange={handleSlideChange}
                                /> 
                            </div>
                            <Button className='saveBtn' disabled={ownProps.permissions==true?false:true} onClick={saveButtonClickHandler}>Save</Button>{' '}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>

            </Accordion>
        </div>
    );
}

ResolutionsPage.propTypes = {
    resolutionsCount: PropTypes.object
};

ResolutionsPage.defaultProps = {
    resolutionsCount: {
        elementType: 'select',
        elementConfig: {
            options: [
                { value: 3, displayValue: '3' },
                { value: 5, displayValue: '5' },
                { value: 8, displayValue: '8' },
                { value: 10, displayValue: '10' }
            ]
        },
        value: '',
        validation: {
            required: true
        },
        valid: false,
        touched: false,
        className: 'selectLang'
    }
}
export default ResolutionsPage;