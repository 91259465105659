import React, { useEffect, useState } from 'react';



import Users from './UserManagement/Users/Users';
import Roles from './UserManagement/Roles/Roles';
import UserGroups from './UserManagement/UserGroups/UserGroups';
import { GetCalltoOUServer } from "./UserManagement/helpers/utility";

const UserManagementTabs = ({ selectedMenu, config }) => {
    const [standardPermissions, setStandardPermissions] = useState();
    
    useEffect(()=>{
        getPermissions();
      },[]);
    
      const getPermissions = async () => {
        const url = config.INSIGHTS_API_URL + `/organization/permission`;
       
        await GetCalltoOUServer(url)
          .then(response => {
              if (response.data != null) {
                setStandardPermissions(response.data);
            }
            else {
                console.log('System Permissions data is null');
            }
          }).catch(() => {
              console.log('Error getting system permissions');
          
          });
      }

    return (
            <>
                {selectedMenu === 'users' ?
                    <Users
                        config={config}
                    /> : null
                }
                {selectedMenu === 'roles' ?
                    <Roles
                        standardPermissions={standardPermissions}
                        config={config}
                    /> : null
                }
                {selectedMenu === 'usergroups' ?
                    <UserGroups
                        config={config}
                    /> : null
                }
                </>
    )
}

export default UserManagementTabs;