const predictedIssuesChangeHandlerReducer = (state, payload) => {
    return {
        ...state,
        predictedissues: {
            ...state.predictedissues,
            ...payload
        }
    }
}

export default predictedIssuesChangeHandlerReducer;