import React, { Component} from 'react'
import { connect } from 'react-redux';
import axios from '../../instance'
import { Col, Row,Spinner } from 'react-bootstrap';
import classes from './ProjectViewPage.module.css'
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import * as actions from '../../store/actions/index'
import ValidationMsg from '../../components/ValidationMsg/ValidationMsg';
import Dropzone from "./dropzone/Dropzone";
import SpinnerPage from '../../components/UI/Loader/Loader';
import ImportFiles from './ImportFiles';
import SelectComponent from '../../components/UIComponents/SelectDropdown/SelectComponent';

class CognitiveSearch extends Component {
    botEditor = React.createRef();
    state = {
        CognitiveControls: {
            KnowledgeSource: 'SharePoint',
            url: '/Shared Documents',
        },
        emptyURLMsg: null,
        showFiles: false,
        selectedProject: '',
        optionListProject: [{ siteId: "Select", siteName: 'Select' }],
        siteId:null,
        siteName:null
    };

    componentDidMount() {
        this.props.getConnectionsData(this.props.config);
        let data = {
            dataSourceType: "knowledge_base",
            tenantId: localStorage.getItem("orgName"),
            projectName: localStorage.getItem("projectname"),
            applicationUrl: "",
            connector: "",
            language: "",
            dataSourceName: "sharepoint"
    
        }
        this.props.getSiteList(this.props.config,data);
        // setTimeout(() => {
        //     this.setState({
        //         optionListProject: this.state.optionListProject
        //     })
        // }, 20000)
    }

    componentDidUpdate(prevprops) {
        if (this.props.uploadloading !== prevprops.uploadloading && !this.props.uploadloading) {
            if (this.props.knowledgeUploadfailure == '') {
                this.setState({ showFiles: true });
            }
            else if (this.props.knowledgeUpload == '') {
                this.setState({ showFiles: false, failure: true });
            }
        }
        if(this.props.siteList && this.props.siteList.data.sites !== null && typeof this.props.siteList !== 'undefined' && this.props.siteList !== prevprops.siteList){
            //this.state.optionListProject = [{ siteId: "Select", siteName: 'Select' }, ...this.props.siteList.data.sites]
            this.setState({optionListProject: this.props.siteList.data.sites})
        }
        
        // if(this.props.siteList && this.props.siteList.data.sites !== null && typeof this.props.siteList !== 'undefined'){
        //     //this.state.optionListProject = [{ siteId: "Select", siteName: 'Select' }, ...this.props.siteList.data.sites]
        //     this.setState({optionListProject: this.props.siteList.data.sites})
        // } 
        
    }
    ImportFAQIntents = (files) => {
        let projectname = localStorage.getItem("projectname");
        if (!projectname)
            projectname = "common";
        let fileList = []
        let formData = new FormData();

        files.map((item) => {
            fileList.push(item.name)
            formData.append('myfile', item)
        })
        formData.append('projectName', projectname);
        formData.append('intent', this.state.CognitiveControls.KnowledgeSource);
        if (this.state.CognitiveControls.KnowledgeSource) {
            let data = { "intent": this.state.CognitiveControls.KnowledgeSource, "filename": fileList }
            this.props.onSaveKnowledgeIntentWithFile(this.props.config.FileUpload_URL, formData, data)
        }

    }

    setValidationMessage = (type, value) => {
        if (type === "url") {
            value.trim() === ''
                ? this.setState({ emptyURLMsg: (<ValidationMsg>URL is required</ValidationMsg>) }) :
                this.setState({ emptyURLMsg: null });
        }
    }

    inputChangedDoc = (event, controlName) => {
        this.props.resetSharePointData();
        const updatedControls = {
            ...this.state.CognitiveControls,
            [controlName]: event.target.value,
        }
        this.setState({ CognitiveControls: updatedControls, });
        this.setValidationMessage("url", event.target.value);
    }
    cancelKnowledgeHandler = () => {
        this.setState({
            CognitiveControls: {
                KnowledgeSource: '',
                url: '',
            },
        });
        this.props.resetSharePointData();
    }

    ImportKnowledgeDoc = (event) => {
        event.preventDefault();
        if (!this.state.CognitiveControls.url.length) {
            this.setValidationMessage("url", event.target.value);
        } else {
            this.props.updateSharePointBaseUrl(this.state.CognitiveControls.url);
            const payload = {
                siteId:this.state.siteId,
                siteName:this.state.siteName,
                config: this.props.config,
                baseUrl: this.state.CognitiveControls.url
            };
            this.props.getSharePointData(payload);
        }
    };

    getKnowledgeDoc = () => {
            this.props.updateSharePointBaseUrl(this.state.CognitiveControls.url);
            const payload = {
                siteId:this.state.siteId,
                siteName:this.state.siteName,
                config: this.props.config,
                baseUrl: "/Shared Documents"
            };
            this.props.getSharePointData(payload);
    };


    updateBaseUrl = (baseUrl) => {
        const updatedControls = {
            ...this.state.CognitiveControls,
            url: baseUrl,
        };
        this.setState({ CognitiveControls: updatedControls, });
    }
    onchangeFile = () => {
        this.setState({ showFiles: false, failure: false })
    }
    inputCategoryHandler = (event) => {
        if(this.state.optionListProject.length>0){
            for(var i=0;i<this.state.optionListProject.length;i++)
            {
                if(this.state.optionListProject[i].siteId==event.target.value){
                    this.setState({
                        siteName:this.state.optionListProject[i].siteName
                    })
                }
            }
        }
        this.setState({
            selectedProject: event.target.value,
            siteId:event.target.value
        },() => {
            this.getKnowledgeDoc();
        })
        
    }
    close = () =>{
        this.props.handleClose()
    }
    render() {
        let uploadloadingImg = null;
        if (this.props.uploadloading) {
            uploadloadingImg = (<SpinnerPage />)
        }
        else {
            uploadloadingImg = null;
        }
        let options =[]
        this.state.optionListProject.length>0 && this.state.optionListProject.map((item) => {
            options.push({
                value: item.siteId , displayValue: item.siteName , disabled: item.siteId=='Select'
            })
            return(
                <option value={item.siteId}>{item.siteName}</option>
                )
                },this);  
        let loadingEffect = <>
           <Row className="justify-content-md-center padding10">
                    <Spinner animation="border" variant="primary" />
                </Row>
        </>  
        let cognitiveform = <>
            {/* <Col md={4}>
                <div className="inputContainer">
                    <label className={classes.ProjectTabContentLabel}>Select Datasource <b></b></label>
                    <select style={{ border: 'none' }} 
                    value={this.state.CognitiveControls.KnowledgeSource}
                     name="KnowledgeSource" 
                     onChange={(e) => this.inputChangedDoc(e, 'KnowledgeSource')}>
                        <option value='0'>Select Source</option>
                        {!this.props.isFaq &&
                            <option value='Local'>Local</option>
                        }
                        {this.props.knowledgeBaseItems.length &&
                            <option value='SharePoint'>SharePoint</option>
                        }
                    </select>
                </div>
            </Col> */}
            <Row>
                    <Col md={6} className="MultimediaSite">
                        <SelectComponent
                            name="project"
                            label="Site"
                            changed={(e) => this.inputCategoryHandler(e)}
                            required={true}
                            value={this.state.selectedProject}
                            options={options} />
                    </Col>
                    {/* <Col md={4} >
                        <div className="inputContainer">
                            <label className={classes.ProjectTabContentLabel}>Select Site<b></b></label>
                            <select style={{ border: 'none' }} value={this.state.selectedProject} name="project" onChange={(e) => this.inputCategoryHandler(e)}>
                                <option value='0'>Select Site</option>
                                {optionProjectList}
                            </select>
                        </div>
                    </Col> */}
                    <Col md={6}>
                        {this.state.CognitiveControls.KnowledgeSource == "SharePoint" ? <>
                            {/* <div className="inputContainer">
                                <label className={classes.ProjectTabContentLabel}>Base Folder<b></b></label>
                                <input type="text" value={this.state.CognitiveControls.url} 
                                name="url" onChange={(e) => this.inputChangedDoc(e, 'url')} />
                            </div>{this.state.emptyURLMsg} */}
                            <SelectComponent
                                name="base"
                                label="Base Folder"
                                changed={(e) => this.inputChangedDoc(e, 'url')}
                                required={true}
                                value={this.state.CognitiveControls.url}
                                options={[{
                                    value: '/Shared Documents' , displayValue: '/Shared Documents'
                                }]} />
                            </> : ''}
                    </Col>
            {this.state.CognitiveControls.KnowledgeSource == "Local" ?
                <Col md={12}>
                    <Dropzone ImportFAQIntents={this.ImportFAQIntents} showFiles={this.state.showFiles}
                        failure={this.state.failure} onchangeFile={this.onchangeFile}
                        knowledgeUpload={this.props.knowledgeUploadfailure}
                        cancelKnowledgeHandler={this.cancelKnowledgeHandler} validate="false" />
                </Col> : ''}
                </Row>
        </>


        return (<>
            <Col md={12} className={classes.KnowledgeDropdownDiv} >
               
                    {options.length > 0 ? cognitiveform : loadingEffect }
                    <Row className="">
                    {this.state.CognitiveControls.KnowledgeSource === "SharePoint" && this.state.selectedProject ? <>
                        {/* <Col md={12}>
                            <div className={classes.BtnAlignment}>
                                <ButtonCustom disabled={this.state.CognitiveControls.url === ''} btnType="blueButton" clicked={this.ImportKnowledgeDoc}>Load</ButtonCustom>

                                <ButtonCustom btnType="whiteButton" clicked={this.cancelKnowledgeHandler}>Cancel</ButtonCustom>
                            </div></Col> */}
                           
                        <Col className={"mt-4"}>
                            <ImportFiles
                                config={this.props.config}
                                url={this.state.CognitiveControls.url}
                                updateBaseUrl={this.updateBaseUrl}
                                navigateToNextTab={this.props.navigateToNextTab}
                                isFaq={this.props.isFaq}
                                siteId ={this.state.siteId}
                                siteName={this.state.siteName}
                                handleClose={this.close}
                                categoryName={this.props.categoryName}
                            />
                        </Col>
                    </> : ''}
                </Row>
            </Col>
            {uploadloadingImg}

        </>)
    }
}
const mapStateToProps = state => {
    return {
        knowledgeUpload: state.projectview.knowledgeUpload,
        uploadloading: state.projectview.uploadloading,
        knowledgeUploadfailure: state.projectview.knowledgeUploadfailure,
        knowledgeBaseItems: state.projectview.knowledgeBaseItems,
        siteList:state.projectview.siteList
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSaveKnowledgeIntentWithFile: (APIURL, formData, data) => dispatch(actions.SaveKnowledgeIntentWithFile(APIURL, formData, data)),
        getSharePointData: (payload) => dispatch(actions.getSharePointData(payload)),
        updateSharePointBaseUrl: payload => dispatch(actions.updateSharePointBaseUrl(payload)),
        resetSharePointData: () => dispatch(actions.resetSharePointData()),
        getConnectionsData: (config) => dispatch(actions.getConnectionsData(config)),
        getSiteList: (config,data) => dispatch(actions.getSiteList(config,data)),
    }

};

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(CognitiveSearch, axios));



