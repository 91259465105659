import React, { useEffect, useState } from "react";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import Drawer from "@material-ui/core/Drawer";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import CloseIcon from "@material-ui/icons/Close";
import Col from "react-bootstrap/Col";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import "./product-details-list.css";
import { useDispatch } from "react-redux";
import Heading3 from "../../../../../components/Typography/Heading3/Heading3";
import { triageConfigurationChangeHandlerAction } from "../../../../../../../../store/actions/adminSettings/adminSettingsActions";
import { GetCalltoServer, PostCalltoServer } from "../../../../../../../../store/utility";
import productAttributesRemoveItemHandler from "../../../../../../../../store/actions/adminSettings/productAttributesRemoveItemHandler";
import ProductAttributesComponent from "../../../ProductAttributesComponent/ProductAttributesComponent";
import PrimaryButtonWithLoader from "../../../../../components/PrimaryButtonWithLoader/PrimaryButtonWithLoader";
import { uniqueId } from "lodash";
import {  useSelector } from "react-redux";

const useStyles = makeStyles({
  list: {
    width: 500,
  },
});

const getOptions = (data) => {
  if (isEmpty(data)) {
    return [];
  }
  return data.map((option) => ({
    label: option,
    value: option,
  }));
};

const ManageProduct = ({ showDrawer, setShowDrawer, toggleDrawer, data, config, sourceData, selectedSource,sourceVal}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectData, setSelectData] = useState([]);
  const [btnDisable, setBtnDisable] = useState(true);
  const [selectedValues, setSelectedValues] = useState(get(data, "source_fields", []));
  const [sourceSelect, setSourceSelect] = useState(localStorage.getItem("sourceSelect") || "ServiceNow - demodata");

  const onRemoveItemHandler = (selectedItem) => {
    dispatch(
      productAttributesRemoveItemHandler({
        type: get(data, "source_name", ""),
        selectedItem,
      })
    );
    setBtnDisable(false);
  };
  
  useEffect(() => {
     let sourceEndPoint = localStorage.getItem("source").slice(13).trim();
    let selectedSourceCollection = selectedSource.includes("-")
      ? selectedSource.split("-")
      : "demodata";
    if (selectedSource != "ServiceNow - demodata") {
      const url =
        config.INSIGHTS_API_URL +
        `/utilityservice/settings/fields/` + sourceEndPoint ;
        // sourceVal;

      // `/product-intelligent-triage/settings/fields/singlerowdev`;

      GetCalltoServer(url)
        .then((response) => {
          if (get(response, "data.status.responseType", "") === "success") {
            const data = get(response, "data.data", []);
            setSelectData(getOptions(data));
          } else {
            setSelectData([]);
          }
        })
        .catch((err) => {
          setSelectData([]);
        });
    } else {
      const url =
        config.INSIGHTS_API_URL +
      `/utilityservice/settings/fields/singlerowdev`;
      GetCalltoServer(url)
        .then((response) => {
          if (get(response, "data.status.responseType", "") === "success") {
            const data = get(response, "data.data", []);
            setSelectData(getOptions(data));
          } else {
            setSelectData([]);
          }
        })
        .catch((err) => {
          setSelectData([]);
        });
    }
  }, []);

  const getSourceName = (data) => {
    const sourceName = get(data, "source_name", "");
    if (sourceName === "productattributes") {
      return "Product";
    }
    if (sourceName === "rootcause") {
      return "Root Causes";
    }
    return sourceName;
  };

  const onApply = () => {
    dispatch(
      triageConfigurationChangeHandlerAction({
        selectedValue: isEmpty(selectedValues)
          ? []
          : selectedValues,
        target: "source_fields",
        type: get(data, "source_name", ""),
      })
    );
    setShowDrawer(false);
  }

  const onChangeHandler = (newValue) => {
    setSelectedValues(newValue.map((item) => item.value));
    setBtnDisable(false);
  }

  const onRemoveHandler = (selectedItem) => {
    setSelectedValues(selectedValues.filter((item) => item !== selectedItem));
    setBtnDisable(false);
  }

  return (
    <React.Fragment>
      {showDrawer ? (
        <Drawer
          anchor={"right"}
          open={showDrawer}
          onClose={toggleDrawer(false)}
        >
          <div
            className={clsx(classes.list, classes.fullList)}
            role="presentation"
            onKeyDown={toggleDrawer(false)}
          >
            <div className="d-flex pt-4 pl-2">
              <div className="col-md-1">
                <CloseIcon
                  className="d-flex product-img"
                  onClick={toggleDrawer(false)}
                  style={{ fontSize: 25 }}
                />
              </div>
              <div className="d-flex product-data pl-3" md={6}>
                <Heading3
                  className="text-capitalize"
                  text={getSourceName(data)}
                />
              
              </div>
            </div>
            <div className="d-flex">
              <Col className="col-md-8 d-flex product-content-data">
                {`Attributes for training models on ${getSourceName(data)}`}
              </Col>
            </div>
            <div className="mt-1">
            <Col md={12} className="pl-0 associate-symptom-alignment manage-product-select-items" >
            <ProductAttributesComponent
              // value={get(data, "source_fields", [])}
              value={selectedValues}
              key={uniqueId()}
              options={selectData}
              targetSourceDescription={"source_name"}
              placeholder={"Select"}
              onChange={(newValue) => {
                onChangeHandler(newValue);
              }}
              onRemoveItem={onRemoveHandler}
              sourceData={sourceData}
            />
            </Col>
            </div>
            <div className="d-flex pl-3">
              <Col xs="4">
                <PrimaryButtonWithLoader
                  className="mt-4"
                  size="lg"
                  onClick={()=>onApply()}
                  text="Apply"
                  disabled={btnDisable}
                />
              </Col>
            </div>
          </div>
        </Drawer>
      ) : null}
    </React.Fragment>
  );
};

ManageProduct.propTypes = {
  similarIssuesSelect: PropTypes.object,
  sizeSearchListConfig: PropTypes.object,
  productHeading: PropTypes.string,
  productData: PropTypes.string,
  sourceName: PropTypes.string,
  serviceName: PropTypes.string,
  SelectOption: PropTypes.string,
};

ManageProduct.defaultProps = {
  extensionFieldsOptions: [
    { value: "title", label: "title" },
    { value: "description", label: "description" },
    { value: "SR_NUM", label: "SR_NUM" },
    { value: "PRODUCT", label: "PRODUCT" },
    { value: "RESOLUTION_CD", label: "RESOLUTION_CD" },
    { value: "SYMPTON_CODE", label: "SYMPTON_CODE" },
    { value: "productfamilyname", label: "productfamilyname" },
    { value: "SERIAL_NUM", label: "SERIAL_NUM" },
  ],
  productHeading: "Product Details",
  productData:
    "Ipsum lorem Ipsum lorem Ipsum lorem Ipsum lorem Ipsum lorem Ipsum lorem Ipsum lorem Ipsum lorem Ipsum lorem",
  sourceName: "Source",
  serviceName: "ServiceNow",
  SelectOption: "Select Field",
};

export default ManageProduct;
