import React, { Component } from 'react';
import './Drawer.css';
import DrawerComponent from '../../UIComponents/Drawer/DrawerComponent';

class AddEntityDrawer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            right: true
        }
    }
    toggleDrawer = (open) => (event) => {
        if (!open) {
            this.props.onHide('entity');
        }
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            this.props.onHide('entity');
            return;
        }
        this.setState({ right: open });
    };

    render() {
        return (
            <React.Fragment >
                <DrawerComponent heading="Add Entity Details"
                    showDrawer={this.toggleDrawer(false)}
                    openDrawer={this.props.show}>
                    <div className='drawer add-entity'>
                        <div className='drawer-body mt-2'>
                            <form>
                                {this.props.children}
                            </form>
                        </div>
                    </div>
                </DrawerComponent>
            </React.Fragment>
        );
    }
}

export default AddEntityDrawer;
