export const MONTHWEEK = ['First', 'Second', 'Third', 'Fourth', 'Last'];
export const MONTHWEEKLIST = [{value:1,label:'First'},{value:2,label:'Second'},{value:3,label:'Third'},
{value:4,label:'Fourth'},{value:-1,label:'Last'}]

export const WEEK = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];

export const TIMEZONE = [{label:'(UTC-12:00) International Date Line West',value:'Etc/GMT+12'},
{label:'(UTC-11:00) Coordinated Universal Time-11',value:'Etc/GMT+11'},
{label:'(UTC-10:00) Aleutian Islands',value:'America/Adak'},
{label:'(UTC-10:00) Hawaii',value:'Pacific/Honolulu'},
{label:'(UTC-09:30) Marquesas Islands',value:'Pacific/Marquesas'},
{label:'(UTC-09:00) Alaska',value:'America/Anchorage'},
{label:'(UTC-09:00) Coordinated Universal Time-09',value:'Etc/GMT+9'},
{label:'(UTC-08:00) Baja Califronia',value:'America/Tijuana'},
{label:'(UTC-08:00) Coordinated Universal Time-08',value:'Etc/GMT+8'},
{label:'(UTC-08:00) Pacific Time(US & Canada)',value:'America/Tijuana'},
{label:'(UTC-07:00) Arizona',value:'America/Phoenix'},
{label:'(UTC-07:00) Chihuahua, La Paz, Mazatlan',value:'America/Chihuahua'},
{label:'(UTC-07:00) Mountain Time(US & Canada)',value:'America/Ojinaga'},
{label:'(UTC-05:00) Chetumal',value:'America/Cancun'},
{label:'(UTC-06:00) Central America',value:'America/Chicago'},
{label:'(UTC-06:00) Central Time(US & Canada)',value:'America/Regina'},
{label:'(UTC-06:00) Easter Island',value:'Pacific/Easter'},
{label:'(UTC-06:00) Guadalajara, Mexico City, Monterrey',value:'America/Mexico_City'},
{label:'(UTC-06:00) Saskatchewan',value:'America/Regina'},
{label:'(UTC-05:00) Bogota, Lima, Quito, Rio Branco',value:'America/Bogota'},
{label:'(UTC-05:00) Eastern Time(US & Canada)',value:'America/Detroit'},
{label:'(UTC-05:00) Haiti',value:'America/Port-au-Prince'},
{label:'(UTC-05:00) Havana',value:'America/Havana'},
{label:'(UTC-05:00) Indiana(East)',value:'America/Indiana/Indianapolis'},
{label:'(UTC-05:00) Turks and Caicos',value:'America/Toronto'},
{label:'(UTC-04:00) Caracas',value:'America/Caracas'},
{label:'(UTC-03:00) Punta Arenas',value:'America/Punta_Arenas'},
{label:'(UTC-04:00) Asuncion',value:'America/Asuncion'},
{label:'(UTC-04:00) Atlantic Time(Canada)',value:'America/Halifax'},
{label:'(UTC-04:00) Cuiaba',value:'America/Cuiaba'},
{label:'(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',value:'America/La_Paz'},
{label:'(UTC-04:00) Santiago',value:'America/Santiago'},
{label:'(UTC-03:30) Newfoundland',value:'America/St_Johns'},
{label:'(UTC-03:00) Araguaina',value:'America/Araguaina'},
{label:'(UTC-03:00) Brasilia',value:'America/Fortaleza'},
{label:'(UTC-03:00) Cayenne, Fortaleza',value:'America/Cayenne'},
{label:'(UTC-03:00) City of Buenos Aires',value:'America/Argentina/Buenos_Aires'},
{label:'(UTC-03:00) Greenland',value:'America/Nuuk'},
{label:'(UTC-03:00) Montevideo',value:'America/Montevideo'},
{label:'(UTC-03:00) Saint Pierre and Miquelon',value:'America/Miquelon'},
{label:'(UTC-03:00) Salvador',value:'America/Maceio'},
{label:'(UTC-02:00) Coordinated Universal Time-02',value:'Etc/GMT+2'},
{label:'(UTC-02:00) Mid-Atlantic',value:'America/Noronha'},
{label:'(UTC-01:00) Azores',value:'Atlantic/Azores'},
{label:'(UTC-01:00) Cabo Verde Is.',value:'Atlantic/Cape_Verde'},
{label:'(UTC) Coordinated Universal Time',value:'UTC'},
{label:'(UTC+00:00) Dublin, Edinburgh, Lisbon, London',value:'Europe/Dublin'},
{label:'(UTC+00:00) Monrovia, Reykjavik',value:'Africa/Monrovia'},
{label:'(UTC+01:00) Casablanca',value:'Africa/Casablanca'},
{label:'(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',value:'Europe/Berlin'},
{label:'(UTC+01:00) Belgrade, Brastislava, Budapest, Ljubljana, Prague',value:'Europe/Budapest'},
{label:'(UTC+01:00) Brussels, Copenhagen, Madrid, Paris',value:'Europe/Madrid'},
{label:'(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb',value:'Europe/Sarajevo'},
{label:'(UTC+01:00) West Central Africa',value:'Africa/Lagos'},
{label:'(UTC+02:00) Tripoli',value:'Africa/Tripoli'},
{label:'(UTC+02:00) Windhoek',value:'Africa/Windhoek'},
{label:'(UTC+02:00) Amman',value:'Asia/Amman'},
{label:'(UTC+02:00) Athens, Bucharest',value:'Europe/Athens'},
{label:'(UTC+02:00) Beirut',value:'Asia/Beirut'},
{label:'(UTC+02:00) Cairo',value:'Africa/Cairo'},
{label:'(UTC+02:00) Chisinau',value:'Europe/Chisinau'},
{label:'(UTC+02:00) Damascus',value:'Asia/Damascus'},
{label:'(UTC+02:00) Gaza, Hebron',value:'Asia/Hebron'},
{label:'(UTC+02:00) Harare, Pretoria',value:'Africa/Harare'},
{label:'(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',value:'Europe/Helsinki'},
{label:'(UTC+02:00) Jerusalem',value:'Asia/Jerusalem'},
{label:'(UTC+02:00) Kaliningrad',value:'Europe/Kaliningrad'},
{label:'(UTC+02:00) Khartoum',value:'Africa/Khartoum'},
{label:'(UTC+03:00) Istanbul',value:'Europe/Istanbul'},
{label:'(UTC+03:00) Minsk',value:'Europe/Minsk'},
{label:'(UTC+03:00) Baghdad',value:'Asia/Baghdad'},
{label:'(UTC+03:00) Kuwait, Riyadh',value:'Asia/Kuwait'},
{label:'(UTC+03:00) Moscow, St.Petersburg',value:'Europe/Moscow'},
{label:'(UTC+03:00) Nairobi',value:'Africa/Nairobi'},
{label:'(UTC+04:00) Astrakhan, Ulyanovsk',value:'Europe/Ulyanovsk'},
{label:'(UTC+04:00) Izhevsk, Samara',value:'Europe/Samara'},
{label:'(UTC+04:00) Saratov',value:'Europe/Saratov'},
{label:'(UTC+04:00) Volgograd',value:'Europe/Volgograd'},
{label:'(UTC+03:30) Tehran',value:'Asia/Tehran'},
{label:'(UTC+04:00) Abu Dhabi, Muscat',value:'Asia/Muscat'},
{label:'(UTC+04:00) Baku',value:'Asia/Baku'},
{label:'(UTC+04:00) Port Louis',value:'Indian/Mauritius'},
{label:'(UTC+04:00) Tbilisi',value:'Asia/Tbilisi'},
{label:'(UTC+04:00) Yerevan',value:'Asia/Yerevan'},
{label:'(UTC+04:30) Kabul',value:'Asia/Kabul'},
{label:'(UTC+05:00) Ashgabat, Toshkent',value:'Asia/Ashgabat'},
{label:'(UTC+05:00) Ekaterinburg',value:'Asia/Yekaterinburg'},
{label:'(UTC+05:00) Islamabad, Karachi',value:'Asia/Karachi'},
{label:'(UTC+05:00) Qyzylorda',value:'Asia/Qyzylorda'},
{label:'(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi',value:'Asia/Kolkata'},
{label:'(UTC+05:30) Sri Jayawardenapura',value:'Asia/Colombo'},
{label:'(UTC+05:45) Kathmandu',value:'Asia/Kathmandu'},
{label:'(UTC+06:00) Nur-Sultan',value:'Asia/Almaty'},
{label:'(UTC+06:00) Dhaka',value:'Asia/Dhaka'},
{label:'(UTC+06:00) Omsk',value:'Asia/Omsk'},
{label:'(UTC+07:00) Barnaul, Gorno-Altaysk',value:'Asia/Barnaul'},
{label:'(UTC+07:00) Novosibirsk',value:'Asia/Novosibirsk'},
{label:'(UTC+07:00) Tomsk',value:'Asia/Tomsk'},
{label:'(UTC+06:30) Yangon(Rangoon)',value:'Asia/Rangoon'},
{label:'(UTC+07:00) Bangkok, Hanoi, Jakarta',value:'Asia/Bangkok'},
{label:'(UTC+07:00) Hovd',value:'Asia/Hovd'},
{label:'(UTC+07:00) Krasnoyarsk',value:'Asia/Krasnoyarsk'},
{label:'(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi',value:'Asia/Hong_Kong'},
{label:'(UTC+08:00) Irkutsk',value:'Asia/Irkutsk'},
{label:'(UTC+08:00) Kuala Lumpur, Singapore',value:'Asia/Singapore'},
{label:'(UTC+08:00) Perth',value:'Australia/Perth'},
{label:'(UTC+08:00) Taipei',value:'Asia/Taipei'},
{label:'(UTC+08:00) Ulaanbaatar',value:'Asia/Ulaanbaatar'},
{label:'(UTC+09:00) Chita',value:'Asia/Chita'},
{label:'(UTC+09:00) Pyongyang',value:'Asia/Pyongyang'},
{label:'(UTC+08:45) Eucla',value:'Australia/Eucla'},
{label:'(UTC+09:00) Osaka, Sapporo, Tokyo',value:'Asia/Tokyo'},
{label:'(UTC+09:00) Seoul',value:'Asia/Seoul'},
{label:'(UTC+09:00) Yakutsk',value:'Asia/Yakutsk'},
{label:'(UTC+09:30) Adelaide',value:'Australia/Adelaide'},
{label:'(UTC+09:30) Darwin',value:'Australia/Darwin'},
{label:'(UTC+10:00) Brisbane',value:'Australia/Brisbane'},
{label:'(UTC+10:00) Canberra, Melbourne, Sydney',value:'Australia/Sydney'},
{label:'(UTC+10:00) Guam, Morseby',value:'Pacific/Guam'},
{label:'(UTC+10:00) Hobart',value:'Australia/Hobart'},
{label:'(UTC+10:00) Vladivostok',value:'Asia/Vladivostok'},
{label:'(UTC+11:00) Bougainville Island',value:'Pacific/Bougainville'},
{label:'(UTC+11:00) Magadan',value:'Asia/Magadan'},
{label:'(UTC+11:00) Sakhalin',value:'Asia/Sakhalin'},
{label:'(UTC+10:30) Lord Howe Island',value:'Australia/Lord_Howe'},
{label:'(UTC+11:00) Chokurdakh',value:'Asia/Srednekolymsk'},
{label:'(UTC+11:00) Norfolk Island',value:'Pacific/Norfolk'},
{label:'(UTC+11:00) Solomon Is., New Caledonia',value:'Pacific/Noumea'},
{label:'(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky',value:'Asia/Anadyr'},
{label:'(UTC+12:00) Auckland, Wellington',value:'Pacific/Auckland'},
{label:'(UTC+12:00) Coordinated Universal Time+12',value:'Etc/GMT-12'},
{label:'(UTC+12:00) Fiji',value:'Pacific/Fiji'},
{label:'(UTC+12:00) Petropavlovsk-Kamchatskysk - Old',value:'Asia/Kamchatka'},
{label:'(UTC+12:45) Chatham Islands',value:'Pacific/Chatham'},
{label:'(UTC+13:00) Coordinated Universal Time+13',value:'Etc/GMT-13'},
{label:'(UTC+13:00) Nuku\'alofa',value:'Pacific/Tongatapu'},
{label:'(UTC+13:00) Samoa',value:'Pacific/Tongatapu'},
{label:'(UTC+14:00) Kiritimati Island',value:'Pacific/Kiritimati'}]