import React, { Component } from "react";
import classes from "../ProjectViewPage/ProjectViewPage.module.css";
import './va-details.css'
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import {
    Container,
    Col,
    Row,
} from "react-bootstrap";
import withErrorHandler from "../../hoc/withErrorHandler/withErrorHandler";
import axios from "../../instance";
import Projects from "./Projects";
import VirtualAssistant from "../SettingPage/SettingInnerPages/VirtualAssistant/VirtualAssistant";
import AgentTransfer from "../SettingPage/SettingInnerPages/AgentTransfer";
import ScheduleMeeting from "../SettingPage/SettingInnerPages/SecheduleMeeting/ScheduleMeeting";
import CsFlowManagement from "../SettingPage/SettingInnerPages/CsFlowManagement";
import ContinuousLearningAnalysis from "../SettingPage/SettingInnerPages/ContinuousLearningAnalysis";
import ConversationManagement from "../SettingPage/SettingInnerPages/ConversationMgmt/ConversationManagement";
import UpdateVaDescriptionDrawer from "../../components/UI/Drawer/UpdateVaDescriptionDrawer";
import ButtonComponent from "../../components/UIComponents/Button/ButtonComponent";
import DeleteModal from '../../components/UIComponents/DeleteModalComponent/DeleteModal';
import { PostCalltoServer, deleteCalltoServer } from '../../store/utility';


class VirtualAssistantDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDescDrawer: false,
            selectedCat: "Projects",
            categories: ["Projects",
                "Personalized Theme",
                "Agent Transfer",
                "Schedule Meeting",
                "Cognitive Flow Management",
                "Conversation Management",
                //"Continous Learning Analysis" //commented due to release 12.1.7
            ],
            showSavePopup: false
        }
    }

    handleClose = () => {
        this.setState({
            showDescDrawer: false,
        })
    }

    handleEditDesc = () => {
        this.setState({
            showDescDrawer: true,
        })
    }

    handleCategory = (ele) => {
        this.setState({
            selectedCat: ele
        })
    }

    deleteVAHandle = () => {
        this.props.OnDeleteAssistant(
            this.props.config.BOTBUILDER_URL,
            this.props.config.COMMON_API_URL
        );
        this.setState({"showSavePopup":false})
        let token = {"FolderName": localStorage.getItem("orgName")+'/Intelli' + localStorage.getItem('vaName')}
        PostCalltoServer(this.props.config.VIRTUAL_ASSISTANCE_URL + '/virtualAssistant/deleteVATheme', token)
            .then(response => {
                
                // if (response.data.status == true) {
                //     console.log("project folder deleted")
                // }

            });
        this.props.history.push("/settings")
    }

    render() {
        let category = this.state.selectedCat
        let desc = "";
        let project = [];
        let createdUser='';
        if (this.props.AssistantsList) {
            let obj = this.props.AssistantsList.find(ele => ele.virtualassistantName == localStorage.getItem("vaName"))
            if(obj){
                desc = obj.virutalassistantDescription ;
                project =obj.project.filter(ele => ele !== '');
                createdUser= obj.createdUser
            }else{
                desc = '';
                project = [];
            }
        }

        return (
            <>
                <UpdateVaDescriptionDrawer
                    show={this.state.showDescDrawer}
                    onHide={this.handleClose}
                    config={this.props.config}
                    projectName={localStorage.getItem("vaName")}
                    getprojDesp={{ description: desc }}
                    projects={project}
                    createdUser={createdUser}
                />
                <div className="m-2 ml-5 mt-3 pl-3 d-flex justify-content-between pb-3">
                    <div>
                        <span className="va-desc d-inline-block">
                            {desc} <span onClick={() => { this.handleEditDesc() }} className="d-inline-block ml-1 descEdit">
                            <img src="./Icons/blueicon.png"
                                className={classes.editIcon} height="13px" />
                        </span>
                        </span>
                       
                    </div>
                    <ButtonComponent variant='outlined'
                        label='Delete' className='buttonClass mr-3 va-dlt-btn'
                        clicked={()=>{ this.setState({"showSavePopup":true})}} />
                          <DeleteModal
                    show={this.state.showSavePopup}
                    deleteModal={() => this.setState({showSavePopup:false})}
                    title="Delete Confirmation"
                    bodyTitle="Are you sure you want to delete the Virtual Assistant?"
                    buttonClick={this.deleteVAHandle}
                />

                </div>
                <Container className={classes.ProjectViewContentPanel + " no-gutters"}>
                    <Row>
                        <Col lg={2} className="left-section">
                            <ul>
                                {this.state.categories.map(ele => {
                                    return (<>
                                        <li
                                            onClick={() => this.handleCategory(ele)}
                                            className={`va-li-item ${this.state.selectedCat == ele && 'va-li-item-active'}`}>
                                            {ele}
                                        </li>
                                    </>)
                                })}
                            </ul>
                        </Col>
                        <Col lg="10" className="right-section">
                            {category == "Projects" && <Projects {...this.props} projects={project} description={desc} createdUser={createdUser} />}
                            {category == "Personalized Theme" && <VirtualAssistant config={this.props.config} projectDetails={project} />}
                            {category == "Agent Transfer" && <AgentTransfer config={this.props.config} />}
                            {category == "Schedule Meeting" && <ScheduleMeeting config={this.props.config} />}
                            {category == "Cognitive Flow Management" && <CsFlowManagement config={this.props.config} />}
                            {category == "Conversation Management" && <ConversationManagement config={this.props.config} />}
                            {/*//commented due to release 12.1.7*/}
                            {/*category == "Continous Learning Analysis" && <ContinuousLearningAnalysis config={this.props.config} />*/}
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        AssistantsList: state.projectview.AllAssistantsList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        OnDeleteAssistant: (apiUrl, data, msg) =>
            dispatch(actions.deleteAssistant(apiUrl, data, msg)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withErrorHandler(VirtualAssistantDetail, axios));

