import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

const deleteRootCauseToSymptomsReducer = (state, payload) => {
    const symptomRootCauseData = state.symptomRootCauseData;
    const updatedData = symptomRootCauseData.map(item => {
        if (item.rootcause === payload.selectedItem.rootcause) {
            const symptomsdata = get(item, 'rootcausedata', []);
            if (isEmpty(symptomsdata)) {
                return item;
            }
            const updatedSymptomsdata = symptomsdata.filter((symptoms, index) => {
                return index !== payload.id;
            });
            return {
                ...item,
                rootcausedata: updatedSymptomsdata
            }
        }
        return item;
    });
    return {
        ...state,
        symptomRootCauseData: updatedData
    }
}

export default deleteRootCauseToSymptomsReducer;