import React, { Component } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import LoginPage from './containers/Auth/LoginPage';
import LoginPageCmm from './containers/Auth/LoginPageCmm';
import SettingPage from './containers/SettingPage/SettingPage';
import ProjectViewPage from './containers/ProjectViewPage/ProjectViewPage';
import * as actions from './store/actions/index';
import { connect } from 'react-redux';
import Layout from './hoc/Layout/Layout'
import PCognitive from './cognitiveSearch/PCognitive';
import InsightsHome from './containers/SettingPage/SettingInnerPages/Insights/InsightsHome';
import AdminHome from './containers/SettingPage/SettingInnerPages/Insight/AdminConfiguration/AdminHome';
import SymptomAndRootCauseTab from './containers/SettingPage/SettingInnerPages/Insight/AdminConfiguration/SymtomsAndRootCauseTab/SymptomAndRootCauseTab';
import AgentCockpit from './containers/SettingPage/SettingInnerPages/AgentCockpit/AgentCockpit';
import UserGroupDetails from './containers/SettingPage/SettingInnerPages/UserManagement/UserGroups/UserGroupDetails';
import UserGroups from './containers/SettingPage/SettingInnerPages/UserManagement/UserGroups/UserGroups';
import * as kc from './store/actions/keycloak.js';
import * as authActions from './store/actions/auth';
import * as userActions from './store/actions/user';
import ComponentsPage from './containers/Components/Components';
import ToastMessage from './components/UIComponents/ToastMessage/ToastMessage'
import Tickets from './containers/SettingPage/SettingInnerPages/IntegrationTab/Tickets';
import ProjectsList from './containers/ProjectsPage/ProjectsList';
import IntegrationTabs from './containers/SettingPage/SettingInnerPages/IntegrationTabs';
// import ReportDashboard from './containers/ReportDashboard/ReportDashboard';
import UserDescription from './containers/ReportDashboard/innerPages/UserDescription';
import ManualUpload from './containers/SettingPage/SettingInnerPages/IntegrationTab/ManualUpload';
import {GetCalltoServer,PostCalltoServer } from "./store/utility";
import OneTimeLogin from './containers/ProjectsPage/OneTimeLogin'
import VirtualAssistantDetail from './containers/VirtualAssistantDetails/VirtualAssistantDetail';
import axios from 'axios';
import get from "lodash/get";
import {encrypt, encryptAndStore} from './utility/utility';
import EncodePass from './containers/SettingPage/encodePass';
import ConversationalDashboard from './containers/ReportDashboard/ConversationalDashboard';
import CognitiveDashboard from './containers/ReportDashboard/CognitiveDashboard';
import ProductTriageDashboard from './containers/ReportDashboard/ProductTriageDashboard';

let userRequest=''
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showingAlert: false,
      messages: {},
      rentry: localStorage.getItem("login"),
      showCognitiveStandAlone: true,
      flag: 0,

      license_type: "",
      days_left: "",
      token: "",
    };
  }
  info = {};
  oneTime(user, name, userRequest) {
    const url = this.props.config.COMMON_API_URL + `/organization/subscription`;
    this.info.name = name;
    this.info.user = user;
    this.info.userRequest = userRequest;
    const urlElastic = this.props.config.COMMON_API_URL + `/organization/es/collections`;
    PostCalltoServer(urlElastic, {})
      .then((response) => {
        if (response.status == "200") {
          GetCalltoServer(url)
            .then((res) => {
              const { license_type, days_left, tenant_admin } = res.data;
              if (license_type === 'local' && tenant_admin && days_left === 0) {
                // localStorage.setItem("expired", "Your subcription expired , Renew Now")
                encryptAndStore("expired", "Your subcription expired , Renew Now")
                this.props.OnlogOut()
              } else if (!res.data.username) {
                // localStorage.setItem('invalidUser', "You do not have the necessary permissions to use this app. Please contact your administrator for assistance.")
                encryptAndStore('invalidUser', "You do not have the necessary permissions to use this app. Please contact your administrator for assistance.")
                this.props.onAuthFail("You do not have the necessary permissions to use this app. Please contact your administrator for assistance.");
                this.props.OnlogOut();
                return;
              }
              if (res.data.license_type !== "FNO") {
                this.props.onAuthSuccess(JSON.stringify(user), name, userRequest);
                this.props.onUpdateUserLoginStatus(this.props.config.COMMON_API_URL);
              } else if (license_type === "FNO" && days_left > 0) {
                this.props.onAuthSuccess(JSON.stringify(user), name, userRequest);
                this.props.onUpdateUserLoginStatus(this.props.config.COMMON_API_URL);
              }
              else {
                const { license_type, days_left } = res.data;
                this.setState({ license_type: license_type, days_left: days_left });
              }

            })
            .catch((err) => console.log("getErr", err));
        }
      })
      .catch((error) => {
        if (error.message == "Request failed with status code 403" || error.message == "Request failed with status code 401") {
          GetCalltoServer(url)
            .then((res) => {
              const { license_type, days_left, tenant_admin } = res.data;
              if (license_type === 'local' && tenant_admin && days_left === 0) {
                // localStorage.setItem("expired", "Your subcription expired , Renew Now")
                encryptAndStore("expired", "Your subcription expired , Renew Now")
                this.props.OnlogOut()
              } else if (!res.data.username) {
                // localStorage.setItem('invalidUser', "You do not have the necessary permissions to use this app. Please contact your administrator for assistance.")
                encryptAndStore('invalidUser', "You do not have the necessary permissions to use this app. Please contact your administrator for assistance.")
                this.props.onAuthFail("You do not have the necessary permissions to use this app. Please contact your administrator for assistance.");
                this.props.OnlogOut();
                return;
              }
              if (res.data.license_type !== "FNO") {
                this.props.onAuthSuccess(JSON.stringify(user), name, userRequest);
                this.props.onUpdateUserLoginStatus(this.props.config.COMMON_API_URL);
              } else if (license_type === "FNO" && days_left > 0) {
                this.props.onAuthSuccess(JSON.stringify(user), name, userRequest);
                this.props.onUpdateUserLoginStatus(this.props.config.COMMON_API_URL);
              }
              else {
                const { license_type, days_left } = res.data;
                this.setState({ license_type: license_type, days_left: days_left });
              }

            })

            .catch((err) => console.log("getErr", err))
        } else{
          // localStorage.setItem('invalidUser', "Organization system variables not configured")
          encryptAndStore('invalidUser', "Organization system variables not configured")
          this.props.OnlogOut();
        }
      })
  }

  componentDidMount() {
    if (this.state.rentry === "1") {
      this.keycloakAuthentication();
      this.props.onGetCountryTimeZone("https://ipapi.co/json/");
    }
  }

  keycloakAuthentication() {
    let orgname = localStorage.getItem("orgName");
    let username = localStorage.getItem("userName");

    if (kc.keycloak === null) {
      kc.initializeKC(orgname, this.props.config.KEYCLOAK_URL);
    }
    kc.keycloak
      .init({ onLoad: "check-sso" })
      .then((initauth) => {
        if (!initauth) {
          kc.keycloak
            .login({ loginHint: username })
            .then((auth) => {
              console.log("keycloak.token in login().then", auth);
            })
            .catch((err) => {
              console.log("Catch of keycloak login in app.js", err);
            });
        } else {
          let name = kc.keycloak.idTokenParsed.preferred_username;
          let user = null;
          user = {
            username: name,
            useremailid: name,
            authtoken: kc.keycloak.token,
            domain: orgname,
          };
          
          // localStorage.setItem("newAccessToken", encrypt(kc.keycloak.token) );          
          // localStorage.setItem("accessToken", kc.keycloak.token);
          // localStorage.setItem("refreshToken", kc.keycloak.refreshToken);  
          // localStorage.setItem("sessiontoken", JSON.stringify(user));
          // localStorage.setItem("userName", name);          
          // localStorage.setItem("language", "en");
          // localStorage.setItem("orgName", orgname);
          // localStorage.setItem("projectname", "common");
          // localStorage.setItem("popup", true);
          
          
          
          encryptAndStore("accessToken", kc.keycloak.token);
          encryptAndStore("refreshToken", kc.keycloak.refreshToken);
          encryptAndStore("sessiontoken", JSON.stringify(user)); 
          encryptAndStore("userName", name);                  
          encryptAndStore("language", "en");
          encryptAndStore("orgName", orgname);
          encryptAndStore("projectname", "common");
          encryptAndStore("popup", true);
          
          this.oneTime(user, name, userRequest);
         /* if(orgname=='lmes'){
            let token = { "token":  kc.keycloak.token }
            axios.post(this.props.config.VIRTUAL_ASSISTANCE_URL + '/virtualAssistant/updateToken', {'token':kc.keycloak.token},{
              headers: {
               'Content-Type': 'application/json',
               'Accept': 'application/json',
               'Authorization':`Bearer ${kc.keycloak.token}`,
               "orgName":orgname,
               "userName":name
          }
            });
          }*/

           /* axios.post(this.props.config.VIRTUAL_ASSISTANCE_URL + '/virtualAssistant/saveLatestToken', {'token':kc.keycloak.token,'orgname':orgname},{
              headers: {
               'Content-Type': 'application/json',
               'Accept': 'application/json',
               //'Authorization':`Bearer ${token}`,
               //"orgName":orgName,
               //"userName":username
          }
            }).catch((err) => console.log("saveLatestToken", err));*/
        }
      })
      .catch((err) => {
        console.error("Authenticated Failed!!", err);
      });
  }
  closeModal = (id) => {
    let msgObjs = this.state.messages;
    delete msgObjs[id];

    this.setState({
      showingAlert: false,
      messages: {
        ...msgObjs,
      },
    });
  };
  componentWillReceiveProps(nextProps) {
    let newMessages = {};
    if (nextProps.isError) {
      this.setState({ showingAlert: true });
      let id = setTimeout(() => {
        let msgObjs = this.state.messages;
        delete msgObjs[id];
        this.setState({
          showingAlert: false,
          messages: {
            ...msgObjs,
          },
        });
        this.props.onResetMessages();
      }, 4000);
      newMessages = {
        [id]: {
          message: nextProps.isError,
          type: "error",
        },
        ...this.state.messages,
      };
    } else if (nextProps.isSuccess) {
      this.setState({ showingAlert: true });
      let id = setTimeout(() => {
        let msgObjs = this.state.messages;
        delete msgObjs[id];
        this.setState({
          showingAlert: false,
          messages: {
            ...msgObjs,
          },
        });
        this.props.onResetMessages();
      }, 4000);
      newMessages = {
        [id]: {
          message: nextProps.isSuccess,
          type: "success",
        },
        ...this.state.messages,
      };
    } else if (nextProps.isWarning) {
      this.setState({ showingAlert: true });
      let id = setTimeout(() => {
        let msgObjs = this.state.messages;
        delete msgObjs[id];
        this.setState({
          showingAlert: false,
          messages: {
            ...msgObjs,
          },
        });
        this.props.onResetMessages();
      }, 4000);
      newMessages = {
        [id]: {
          message: nextProps.isWarning,
          type: "warning",
        },
        ...this.state.messages,
      };
    }
    if (Object.keys(newMessages).length > 0)
      this.setState({
        messages: {
          ...newMessages,
        },
      });
  }

  componentDidUpdate(prevProps, prevState) {
    console.log(this.props.isError);

    if (
      this.props.isAuthenticated &&
      this.props.countryDetails &&
      this.props.countryDetails.country_code &&
      this.props.countryDetails.country_name &&
      this.state.flag == 0
    ) {
      let url = this.props.config.BOTBUILDER_URL + `/timezone`;
      let data = {
        emailId: kc.keycloak.idTokenParsed.preferred_username,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        countryCode: this.props.countryDetails.country_code,
        countryName: this.props.countryDetails.country_name,
      };
      this.props.onSaveTimeZone(url, data);
      this.setState({ flag: 1 });
    }
  }

  render() {
    if (this.state.license_type === "FNO" && this.state.days_left == 0) {
      return (
        <OneTimeLogin
          config={this.props.config}
          setstate={this.setState}
          info={this.info}
          props={this.props}
        /> 
      );
    }
    let routes = null;
    if (this.state.rentry === null && !this.props.isAuthenticated ) {
      routes = (
        <Switch>
          <Route
            path="/login"
            render={(props) => (
              <LoginPage {...props} config={this.props.config} />
            )}
          />
          <Route
            path="/onetime"
            exact
            render={(props) => (
              <OneTimeLogin {...props} config={this.props.config} />
            )}
          />
          <Route
            path="/logincmm"
            render={(props) => (
              <LoginPageCmm {...props} config={this.props.config} />
            )}
          />

          <Route
            path="/"
            exact
            render={(props) => (
              <LoginPage {...props} config={this.props.config} />
            )}
          />

          <Route render={() => <Redirect to={{ pathname: "/" }} />} />
        </Switch>
      );
    }

    if (this.props.isAuthenticated && this.state.showCognitiveStandAlone) {
      routes = (
        <Switch>
          <Route
            path="/projectviewpage"
            exact
            render={(props) => {
              if (this.props.location.search) {
                const query = new URLSearchParams(this.props.location.search);
                for (let param of query.entries()) {
                  if (param[0] == "projname") {
                    let projectName = param[1];
                    // localStorage.setItem("projectname", projectName);
                    encryptAndStore("projectname", projectName);
                  }
                }
              }
              return <ProjectViewPage {...props} config={this.props.config} />;
            }}
          />
          <Route path="/virtual-assistant" exact render={(props) => {
            if (this.props.location.search) {
              const query = new URLSearchParams(this.props.location.search);
              for (let param of query.entries()) {
                if (param[0] == "va-name") {
                  let vaName = param[1];
                  // localStorage.setItem("vaName", vaName);
                  encryptAndStore("vaName", vaName);
                }
              }
            }
            return (
              <VirtualAssistantDetail  {...props} config={this.props.config} />
            )
          }} />
          <Route
            path="/tickets"
            exact
            render={(props) => (
              <Tickets {...props} config={this.props.config} />
            )}
          />
          <Route
            path="/ManualUpload"
            exact
            render={(props) => (
              <ManualUpload {...props} config={this.props.config} />
            )}
          />
          <Route
            path="/integrationTabs"
            exact
            render={(props) => (
              <IntegrationTabs {...props} config={this.props.config} />
            )}
          />

          <Route
            path="/insights"
            exact
            render={(props) => (
              <AgentCockpit {...props} config={this.props.config} />
            )}
          />

          <Route
            path="/Cognitive"
            exact
            render={(props) => (
              <PCognitive
                {...props}
                config={this.props.config}
                standAlone={false}
              />
            )}
          />

          <Route
            path="/settings"
            exact
            render={(props) => (
              <SettingPage {...props} config={this.props.config} />
            )}
          />
          <Route
            path="/encodePass"
            exact
            render={(props) => (
              <EncodePass {...props} config={this.props.config} />
            )}
          />
          
          <Route
            path="/"
            exact
            render={() => (
              <Redirect to={{ pathname: "/projectview" }} />
            )}
          />

          <Route
            path="/insightsHome"
            exact
            render={(props) => (
              <InsightsHome {...props} config={this.props.config} />
            )}
          />
          <Route
            path="/triageConfiguration"
            exact
            render={(props) => (
              <AdminHome {...props} config={this.props.config} />
            )}
          />

          <Route
            path="/symptomsAndRootCause"
            exact
            render={(props) => (
              <SymptomAndRootCauseTab {...props} config={this.props.config} />
            )}
          />
          <Route
            path="/userGroupDetails/:name"
            exact
            render={(props) => (
              <UserGroupDetails {...props} config={this.props.config} />
            )}
          />
          <Route
            path="/usergroup"
            exact
            render={(props) => (
              <UserGroups {...props} config={this.props.config} />
            )}
          />

          <Route
            path="/components"
            exact
            render={(props) => (
              <ComponentsPage {...props} config={this.props.config} />
            )}
          />

          <Route
            path="/projectview"
            exact
            render={(props) => (
              <ProjectsList {...props} config={this.props.config} />
            )}
          />

          {/* <Route
            path="/reportdashboard"
            exact
            render={(props) => (
              <ReportDashboard {...props} config={this.props.config} />
            )}
          /> */}
          <Route
            path="/ConversationMetrics"
            exact
            render={(props) => (
              <ConversationalDashboard {...props} config={this.props.config} />
            )}
          />
          <Route
            path="/CognitiveMetrics"
            exact
            render={(props) => (
              <CognitiveDashboard {...props} config={this.props.config} />
            )}
          />
          <Route
            path="/ProductTriageMetrics"
            exact
            render={(props) => (
              <ProductTriageDashboard {...props} config={this.props.config} />
            )}
          /> 
          <Route
            path="/reportdescription"
            exact
            render={(props) => (
              <UserDescription {...props} config={this.props.config} />
            )}
          />
          <Route render={() => <Redirect to={{ pathname: "/" }} />} />
        </Switch>
      );
    }

    let errorMessage = null;
    let successMessage = null;
    if (this.props.isError) {
      errorMessage = (
        <ToastMessage
          closeModal={this.closeModal}
          showingAlert={this.state.showingAlert}
          message={this.props.isError}
          customClass="error"
        />
      );
    }

    if (this.props.isWarning) {
      errorMessage = null;
      successMessage = (
        <ToastMessage
          closeModal={this.closeModal}
          showingAlert={this.state.showingAlert}
          message={this.props.isWarning}
          customClass="warning"
        />
      );
    }

    if (this.props.isSuccess) {
      errorMessage = null;
      successMessage = (
        <ToastMessage
          closeModal={this.closeModal}
          showingAlert={this.state.showingAlert}
          message={this.props.isSuccess}
          customClass="success"
        />
      );
    }
    const { pathname } = this.props.location;

    if (pathname == "/PCognitive") {
      this.state.showCognitiveStandAlone = false;
    }
   /* if (pathname == "/PCognitive" && !localStorage.getItem("sessiontoken")) {
      userRequest = "/PCognitive";
      this.state.showCognitiveStandAlone = false;
    }*/
    if (!localStorage.getItem("sessiontoken")) {
      <LoginPage config={this.props.config} />;
    }
    if (
      !this.state.showCognitiveStandAlone 
    ) {
      userRequest = "";
      return (
        <Route
          path="/PCognitive"
          exact
          render={(props) => (
            <PCognitive
              {...props}
              config={this.props.config}
              standAlone={true}
            />
          )}
        />
      );
    } else if (this.props.isAuthenticated) {
      return (
        <Layout config={this.props.config}>
          {Object.keys(this.state.messages).map((msgId) => {
            return (
              <ToastMessage
                closeModal={() => {
                  this.closeModal(msgId);
                }}
                key={msgId}
                showingAlert={true}
                message={this.state.messages[msgId].message}
                customClass={this.state.messages[msgId].type}
              />
            );
          })}
          {routes}
        </Layout>
      );
    } else {
      return (
        <>
          {errorMessage}
          {routes}
        </>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    isError: state.errormessage.error,
    isAuthenticated: state.auth.sessiontoken !== null,
    isSuccess: state.errormessage.success,
    isWarning:state.errormessage.warning,
    countryDetails:state.auth.countryDetails
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
    OnlogOut: () => dispatch(actions.logout()),
    onResetMessages: () => dispatch(actions.resetMessages()),
    onAuthSuccess: (sessiontoken, username,userRequest) => dispatch(authActions.authSuccess(sessiontoken, username,userRequest)),
    onSetDefaultLanguage:(apiUrl,langname,langkey) => dispatch( userActions.SetDefaultLanguage(apiUrl,langname,langkey)),
    onUpdateUserLoginStatus: (apiUrl) => dispatch(authActions.updateUserLoginStatus(apiUrl)),
    onSetErrorMessage: (error) => dispatch(actions.SetErrorMessage(error)),
    onAuthFail: (error) => dispatch(authActions.authFail(error)),
    onSaveTimeZone: (url,data) => dispatch(authActions.saveTimeZone(url,data)),
    onGetCountryTimeZone:(url) => dispatch(authActions.getCountryTimeZone(url))
  };
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
