

 
    
//   constructor(props){
//       super(props);
     
//     }
  
   
//       render () {
        
//           return  <div className="">
            
//           </div>
//       }
//   }
  

/*

* Copyright © 2020, HCL Technologies Ltd. ALL RIGHTS RESERVED.

* <p>

* This software is the confidential information of HCL Technologies Ltd., and is licensed

* as restricted rights software. The use,reproduction, or disclosure of this software

* is subject to restrictions set forth in your license agreement with HCL.

*/

 

/**

* Home component.

*

* @author sujatha

*/
import {Component} from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import withErrorHandler from '../../../hoc/withErrorHandler/withErrorHandler';
import axios from '../../../instance'


import Spinner from '../../../components/UI/Spinner/Spinner';
import '../AgentTransfer.css';
import {  ListGroupItem,  } from 'react-bootstrap';
import {  Container } from 'react-bootstrap';

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import ButtonCustom from "../../../components/UIComponents/Button/ButtonComponent";
import SelectCustom from "../../../components/UIComponents/SelectDropdown/SelectComponent";
import TabCustom from "../../../components/UI/HorizontalTab/HorizontalTab";
import TextAreaWithLabelComponent from "../../SettingPage/SettingInnerPages/components/TextAreaWithLabelComponent/TextAreaWithLabelComponent";
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import InputWithLabelComponent from "../../SettingPage/SettingInnerPages/components/InputWithLabelComponent/InputWithLabelComponent";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles,styled,alpha} from "@material-ui/core/styles";
import Switch from '@material-ui/core/Switch';
import { blue } from '@material-ui/core/colors';
import Checkbox from '@material-ui/core/Checkbox';
import StopIcon from '@material-ui/icons/Stop';
import StopOutlinedIcon from '@material-ui/icons/StopOutlined';
import ReactMultiSelectComponent from './SecheduleMeeting/ReactSelectComponent';
import { GetCalltoOUServer } from './UserManagement/helpers/utility';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Form } from "react-bootstrap";
import "./inputBox.css";


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
class AgentTransfer extends Component {
    state = {
        agentId : [{ agentId: "" }],
        FilterUserData:[],
        conusers:'',
        msg:'',
        agents:{},
        status:'',
        MovedList:[],
        description:'',
        desctouched:false,
        dragged:[],
        deleteTouched:false,
        saveUser:true,
        selectName:'1',
        selectedTab:'AGENT TRANSFER CONFIGURATION',
        // agentsID:'',
        agentStatus:'Available',
        AgentsRouting:'Round Robin',
        selectedProject:localStorage.getItem('projectname'),
        retiredAgents:[1,2,3,4,5,6,7,8,9,10],
        selectedRetiredAgent:1,
        saveSetting:false,
        selectedContry:[],
        prevSelectedCountry:[],
        emailSentToUser:false,
        emailSentToAgent:true,
        whenAgentPing:'Welcome! HCL virtual assistant will help you connect to the incoming visitors',
        whenAgentReceiveChatTransferRequest:'<strong>HCL Virtual Assistant</strong> would like to initiate a chat session with you.', 
        whenAgentAcceptsChatTransferRequest: 'Thank you. You are now in a live chat session.',
        whenAgentRejectsChatTransferRequest:'Thank you. Your live chat session did not get established.',
        whenAgentEndsLiveChat:'Thank you. Your live chat session has ended.',
        whenOneIsConfigAsAgent:'Welcome! You have been configured as an agent. Please say hi to help Virtual Assistant connect you to incoming users.',
        whenOneIsNoLongerConfigAsAgent:'You are no longer configured as an agent. HCL Virtual Assistant can no longer connect you to the incoming visitors',
        whenUserInitChatTransfer:'You seem to be interested in interacting with an agent. Please give me a minute while I check the availability of our agents.',
        whenAgentAcceptsChatTransferRequestForUser:'You are now connected to <agent name>',
        whenAgentsAreBusy: 'Sorry all our agents seem to be busy. Please try connecting to our agents after some time.',
        whenAgentEndsLiveChatForUser:'I hope you had a good experience interacting with our agent <agent name>. How may I help you further?',       
        fromMailId : 'intelliService@intelliservices.onmicrosoft.com',
        toMailId: '',
        mailSubject: 'HCL IntelliService: Follow up on the request to interact with sales',
        mailBody: 'Hi all,<br> During one of the sessions, a visitor expressed interest in interacting with a sales executive. But, it was not possible to connect the visitor to one of our sales representatives. So, please follow up with the visitor as soon as possible.<conversation_log><br> Regards,<br>HCL Virtual Assistant',
        sendConversationLog: false,
        typeConversationLog: 'Question and Response',
        numberOfConversationLog:5,
        msgSendConversationLog: false,
        msgTypeConversationLog: 'Question and Response',
        msgNumberOfConversationLog:5,
        sendVisitorDetails: false,
        firstNameCheckBox: false,
        lastNameCheckBox: false,
        companyCheckBox: false,
        emailIdCheckBox: false,
        contactNumberCheckBox: false,
        agentsMatch:[],
        agentIndex:0,
        agentVal:[],
        left:'',
        top:'',
        validationMsg:-1,
        captureUserEmail:true,
        msgToCaptureUserEmail:'Sorry all our agents seem to be busy. Please provide your email address so that one of our agents can reach out to you soon to help resolve your query',
        msgToEndAgentTransferRequest:'Thanks a lot. One of our agents will reach out to you soon to help resolve your query.',
         };
      MoveItem=(item)=>{
        if(!(this.state.deleteTouched)){
            if(this.props.activeAgent && Object.entries(this.props.activeAgent.agentlist).length!== 0){
                Object.keys(this.props.activeAgent.agentlist).forEach((item) => {
                    const found = this.state.dragged.some(el => el === item);
                    if (!found)  this.state.dragged.push(item)
                    return null;
                  })
              }
            }
        let MovedList1=this.state.dragged;
        let agents=this.state.agents;
          const index = MovedList1.indexOf(item);
          if(MovedList1.length === 0 || index < 0){
          MovedList1.push(item);
        agents[item] ={
            "status":"active"
            };
        this.setState({deleteTouched:true,dragged:MovedList1});
      }
    }
       deleteItem=(item)=>{
        if(!(this.state.deleteTouched)){
        if(Object.entries(this.props.activeAgent.agentlist).length!== 0){

            Object.keys(this.props.activeAgent.agentlist).forEach((item) => {
                const found = this.state.dragged.some(el => el === item);
                if (!found)
                this.state.dragged.push(item)
                return null;
                           })
                        }
        }
        this.setState({deleteTouched:true});
          let MovedList1=this.state.dragged;
          const index = MovedList1.indexOf(item);    
            if(index > -1){
                MovedList1.splice(index, 1);
                this.setState({dragged:MovedList1});
            }
      }
    cancelActiveUser(){
        this.setState({saveUser:false});
        setTimeout(() => { this.setState({saveUser:true})},1000)
      }
      saveActiveUser(e){
        console.log(e)
        let{saveUser}=this.state;
   
        if(saveUser){
   
        
        let user={"channel":"MSTeams",
        "geography":this.state.selectedContry,
        "numberOfRetires":Number(this.state.selectedRetiredAgent),
        "routingMethod":"Round Robbin",
        "agents":this.state.agentId,
        "status":this.state.agentStatus,
        "language":this.props.defaultlanginfo.langkey,
        "projectName":this.state.selectedProject,
        "organization": localStorage.getItem("orgName"),
        "whenAgentPing": this.state.whenAgentPing,
        "whenAgentReceiveChatTransferRequest": this.state.whenAgentReceiveChatTransferRequest,
        "whenAgentAcceptsChatTransferRequest": this.state.whenAgentAcceptsChatTransferRequest,
        "whenAgentRejectsChatTransferRequest": this.state.whenAgentRejectsChatTransferRequest,
        "whenAgentEndsLiveChat": this.state.whenAgentEndsLiveChat,
        "whenOneIsConfigAsAgent": this.state.whenOneIsConfigAsAgent,
        "whenOneIsNoLongerConfigAsAgent":this.state.whenOneIsNoLongerConfigAsAgent,
        "whenUserInitChatTransfer": this.state.whenUserInitChatTransfer,
        "whenAgentAcceptsChatTransferRequestForUser": this.state.whenAgentAcceptsChatTransferRequestForUser,
        "whenAgentsAreBusy": this.state.whenAgentsAreBusy,
        "whenAgentEndsLiveChatForUser": this.state.whenAgentEndsLiveChatForUser,
        "sendConversationLog": this.state.msgSendConversationLog,
        "typeConversationLog": this.state.msgTypeConversationLog,
        "numberOfConversationLog":Number(this.state.msgNumberOfConversationLog),
        "agentEmail":{
        "fromMailId": this.state.fromMailId,
        "toMailId": this.state.toMailId,
        "mailSubject": this.state.mailSubject,
        "mailBody": this.state.mailBody,
        "sendConversationLog": this.state.sendConversationLog,
        "typeConversationLog": this.state.typeConversationLog,
        "numberOfConversationLog":Number(this.state.numberOfConversationLog),
        "sendVisitorDetails": this.state.sendVisitorDetails,
        "firstName": this.state.firstNameCheckBox,
        "lastName": this.state.lastNameCheckBox,
        "company": this.state.companyCheckBox,
        "emailId": this.state.emailIdCheckBox,
        "contactNumber": this.state.contactNumberCheckBox,
        "captureUserEmail":this.state.captureUserEmail,
        "msgToCaptureUserEmail":this.state.msgToCaptureUserEmail,
        "msgToEndAgentTransferRequest":this.state.msgToEndAgentTransferRequest,
        }

        }
        this.props.onsaveAgent(user,this.props.config.BOTBUILDER_URL);
        this.setState({saveSetting:false})
        }
      }
      handleChange=(e)=>{
        const { name, value, } = e.target
        this.setState({
                [name]: value,
        })   
      }
      handleInput=(e)=>{
        const { value, } = e.target
        this.setState({
              description:value,
              desctouched:true
        })
      }
      componentDidMount() {
        let vaName = localStorage.getItem("vaName")
        if(this.props.AssistantsList != null){
          this.props.AssistantsList.map((data, i) => {
        if(data.virtualassistantName == vaName){
          this.setState({
            projects:data.project
          })
         }
          })
        }
        const orgname = localStorage.getItem("orgName");
       const url = this.props.config.COMMON_API_URL + `/organization/user?orgname=${orgname}`;
    GetCalltoOUServer(url)
      .then((response) => {
      if (response.data != null) {
       const userId = response.data.map((user) =>{
        return user.username
       })
        this.setState({
        FilterUserData:userId
        })        
      } 
      })
      .catch(() => {
      });
        this.getAgentDetails(localStorage.getItem("projectname"))
        this.props.onGetContry(this.props.config.BOTBUILDER_URL)
       
       
     }

     componentDidUpdate(prevprops){
      if(this.props.agentTransferDetail && this.props.agentTransferDetail.length !==0 && prevprops.agentTransferDetail !== this.props.agentTransferDetail){
          this.setState({
            agentId:this.props.agentTransferDetail[0].agents,
            selectedRetiredAgent: this.props.agentTransferDetail[0].numberOfRetires,
            agentStatus:this.props.agentTransferDetail[0].status,
            whenAgentPing:this.props.agentTransferDetail[0].whenAgentPing,
            whenAgentReceiveChatTransferRequest: this.props.agentTransferDetail[0].whenAgentReceiveChatTransferRequest,
            whenAgentAcceptsChatTransferRequest: this.props.agentTransferDetail[0].whenAgentAcceptsChatTransferRequest,
            whenAgentRejectsChatTransferRequest: this.props.agentTransferDetail[0].whenAgentRejectsChatTransferRequest,
            whenAgentEndsLiveChat: this.props.agentTransferDetail[0].whenAgentEndsLiveChat,
            whenOneIsConfigAsAgent: this.props.agentTransferDetail[0].whenOneIsConfigAsAgent,
            whenOneIsNoLongerConfigAsAgent:this.props.agentTransferDetail[0].whenOneIsNoLongerConfigAsAgent,
            whenUserInitChatTransfer: this.props.agentTransferDetail[0].whenUserInitChatTransfer,
            whenAgentAcceptsChatTransferRequestForUser: this.props.agentTransferDetail[0].whenAgentAcceptsChatTransferRequestForUser,
            whenAgentsAreBusy: this.props.agentTransferDetail[0].whenAgentsAreBusy,
            whenAgentEndsLiveChatForUser: this.props.agentTransferDetail[0].whenAgentEndsLiveChatForUser,
            fromMailId :this.props.agentTransferDetail[0].agentEmail.fromMailId,
            toMailId: this.props.agentTransferDetail[0].agentEmail.toMailId,
            mailSubject: this.props.agentTransferDetail[0].agentEmail.mailSubject,
            mailBody: this.props.agentTransferDetail[0].agentEmail.mailBody,
            sendConversationLog:this.props.agentTransferDetail[0].agentEmail.sendConversationLog,
            typeConversationLog: this.props.agentTransferDetail[0].agentEmail.typeConversationLog,
            numberOfConversationLog:this.props.agentTransferDetail[0].agentEmail.numberOfConversationLog,

            msgSendConversationLog:this.props.agentTransferDetail[0].sendConversationLog,
            msgTypeConversationLog: this.props.agentTransferDetail[0].typeConversationLog,
            msgNumberOfConversationLog:this.props.agentTransferDetail[0].numberOfConversationLog,
            
            sendVisitorDetails: this.props.agentTransferDetail[0].agentEmail.sendVisitorDetails,
            firstNameCheckBox:this.props.agentTransferDetail[0].agentEmail.firstName,
            lastNameCheckBox:this.props.agentTransferDetail[0].agentEmail.lastName,
            companyCheckBox: this.props.agentTransferDetail[0].agentEmail.company,
            emailIdCheckBox:this.props.agentTransferDetail[0].agentEmail.emailId,
            contactNumberCheckBox:this.props.agentTransferDetail[0].agentEmail.contactNumber,
            captureUserEmail:this.props.agentTransferDetail[0].agentEmail.captureUserEmail,
            msgToCaptureUserEmail:this.props.agentTransferDetail[0].agentEmail.msgToCaptureUserEmail,
            msgToEndAgentTransferRequest:this.props.agentTransferDetail[0].agentEmail.msgToEndAgentTransferRequest,
            validationMsg:-1
          })
      }
      if(this.props.agentTransferDetail && this.props.agentTransferDetail.length == 0 && prevprops.agentTransferDetail !== this.props.agentTransferDetail){
          this.setState({
              
            agentId:[{agentId:""}],
            agentStatus:'',
            whenAgentPing:'Welcome! HCL virtual assistant will help you connect to the incoming visitors',
            selectedRetiredAgent:'',
            whenAgentReceiveChatTransferRequest: 'HCL Virtual Assistant would like to initiate a chat session with you.',
            whenAgentAcceptsChatTransferRequest: 'Thank you. You are now in a live chat session.',
            whenAgentRejectsChatTransferRequest:'Thank you. Your live chat session did not get established.',
            whenAgentEndsLiveChat: 'Thank you. Your live chat session has ended.',
            whenOneIsConfigAsAgent: 'Welcome! You have been configured as an agent. Please say hi to help Virtual Assistant connect you to incoming users.',
            whenOneIsNoLongerConfigAsAgent:'You are no longer configured as an agent. HCL Virtual Assistant can no longer connect you to the incoming visitors',
            whenUserInitChatTransfer: 'You seem to be interested in interacting with an agent. Please give me a minute while I check the availability of our agents.',
            whenAgentAcceptsChatTransferRequestForUser: 'You are now connected to <agent name>',
            whenAgentsAreBusy: 'Sorry all our agents seem to be busy. Please try connecting to our agents after some time.',
            whenAgentEndsLiveChatForUser: 'I hope you had a good experience interacting with our agent <agent name>. How may I help you further?',
            fromMailId : 'intelliService@intelliservices.onmicrosoft.com',
            toMailId: '',
            mailSubject: 'HCL IntelliService: Follow up on the request to interact with sales',
            mailBody: 'Hi all,<br> During one of the sessions, a visitor expressed interest in interacting with a sales executive. But, it was not possible to connect the visitor to one of our sales representatives. So, please follow up with the visitor as soon as possible.<conversation_log><br> Regards,<br>HCL Virtual Assistant',
            sendConversationLog: false,
            typeConversationLog: 'Question and Response',
            numberOfConversationLog:5,
            msgSendConversationLog: false,
            msgTypeConversationLog: 'Question and Response',
            msgNumberOfConversationLog:5,
            sendVisitorDetails: false,
            firstNameCheckBox: false,
            lastNameCheckBox: false,
            companyCheckBox: false,
            emailIdCheckBox: false,
            contactNumberCheckBox: false,
            captureUserEmail:true,
            msgToCaptureUserEmail:'Sorry all our agents seem to be busy. Please provide your email address so that one of our agents can reach out to you soon to help resolve your query',
            validationMsg:-1
    
          })
      }
}

     getAgentDetails =(projectname)=>{
      this.props.onListAgent(this.props.config.BOTBUILDER_URL,projectname);
      setTimeout(() => {
      if(this.props.activeAgent && this.props.activeAgent.data && this.props.activeAgent.data.length>=1 ){
        let activeAgent=[];
        activeAgent=this.props.activeAgent.data[this.props.activeAgent.data.length - 1]
          
            this.setState({
              selectedRetiredAgent:activeAgent.numberOfRetires,
              agentId:activeAgent.agents,
              agentStatus:activeAgent.status,
            }) 
        
      }else{
        this.setState({
          selectedRetiredAgent:1,
          agentId:[{agentId:""}],
          agentStatus:'Available',
          AgentsRouting:'Round Robin',
        })
      }
    }, 1000);
     }
     onSelectHandler=(event)=>{
        this.setState({
          agentStatus:event.target.value,
          typeConversationLog:event.target.value,
          saveSetting:true
        })
      }

      onMsgSelectHandler=(event)=>{
        this.setState({
          // agentStatus:event.target.value,
          msgTypeConversationLog:event.target.value,
          saveSetting:true
        })
      }

      onSelectContry=(event) =>{
        var temp = []
        for(var i=0;i<event.length;i++){ 
                temp.push(event[i].value)
        }
        if(this.state.selectedProject.length>0 && temp.length>0){          
          let selectedContry=(this.props.countryList.length === temp.length)?['All']:temp;
          let url = this.props.config.BOTBUILDER_URL + `/activeUser/project/` + this.state.selectedProject + `/geography/` + selectedContry
          this.props.onGetAgentTransferDetail(url)
      }
        this.setState({
          // selectedContry:event.target.value,
          prevSelectedCountry:this.state.selectedContry,
          selectedContry:temp
          
        })
      }
      onRemoveCountryList = (item) =>{
        var temp = [...this.state.selectedContry]
        var index = temp.indexOf(item)
        temp.splice(index,1)
        this.setState({selectedContry:temp})
        if(temp.length>0){
          let url = this.props.config.BOTBUILDER_URL + `/activeUser/project/` + this.state.selectedProject + `/geography/` + temp
          this.props.onGetAgentTransferDetail(url)
        }
      }
      OnselectRetiredAgent=(event)=>{
        this.setState({
          selectedRetiredAgent:event.target.value,
          saveSetting:true
        })
      }

      onProjectDropDown=(event)=>{
          let selectedContry=(this.state.selectedContry.length === 0)?['All']:this.state.selectedContry;
          let url = this.props.config.BOTBUILDER_URL + `/activeUser/project/` + event.target.value + `/geography/` + selectedContry
          this.props.onGetAgentTransferDetail(url)
      this.setState({
          selectedProject:event.target.value,
          // numberOfConversationLog:event.target.value,
      })
        
      }
    
      selectedTab=(nodeName)=>{
        console.log(nodeName)
        this.setState({
          selectedTab:nodeName
        })
      }

      onChangeHandler=(event,name)=>{
        this.setState({saveSetting:true})

      //   if(name == "agentsID"){
      //     this.setState({
      //     agentsID: event.target.value,
      //     saveSetting:true
      //   })
      // }
    
 
    if(name == "fromMailId"){
      this.setState({
        fromMailId: event.target.value,
        saveSetting:true

      })
    }
    else if(name == "mailSubject"){
      this.setState({
        mailSubject:event.target.value,
        saveSetting:true

      })
    }
    else if(name == "toMailId"){
      this.setState({
        toMailId:event.target.value,
        saveSetting:true

      })
    }
    else if(name == "mailBody"){
      this.setState({
        mailBody:event.target.value,
        saveSetting:true

      })
    }
    }

      onChangeHandler2=(event)=>{
        this.setState({
          whenAgentAcceptsChatTransferRequest:event.target.value,
          saveSetting:true

        })
      }

      onChangeHandler1=(event,name)=>{
        this.setState({saveSetting:true})

        if(name == "whenAgentPing"){
          this.setState({
            whenAgentPing: event.target.value,
            saveSetting:true
          })
        }
        else if(name == "whenAgentReceiveChatTransferRequest"){
          this.setState({
            whenAgentReceiveChatTransferRequest:event.target.value,
            saveSetting:true
          })
        }
        else if(name == "whenAgentAcceptsChatTransferRequest"){
          this.setState({
            whenAgentAcceptsChatTransferRequest:event.target.value,
            saveSetting:true
          })
        }
        else if(name == "whenAgentRejectsChatTransferRequest"){
          this.setState({
            whenAgentRejectsChatTransferRequest:event.target.value,
            saveSetting:true
          })
        }
        else if(name == "whenAgentEndsLiveChat"){
          this.setState({
            whenAgentEndsLiveChat:event.target.value,
            saveSetting:true
          })
        }
        else if(name == "whenOneIsConfigAsAgent"){
          this.setState({
            whenOneIsConfigAsAgent:event.target.value,
            saveSetting:true
          })
        }
        else if(name == "whenOneIsNoLongerConfigAsAgent"){
          this.setState({
            whenOneIsNoLongerConfigAsAgent:event.target.value,
            saveSetting:true
          })
        }
        else if(name == "whenUserInitChatTransfer"){
          this.setState({
            whenUserInitChatTransfer:event.target.value,
            saveSetting:true
          })
        }
        else if(name == "whenAgentAcceptsChatTransferRequestForUser"){
          this.setState({
            whenAgentAcceptsChatTransferRequestForUser:event.target.value,
            saveSetting:true
          })
        }
        else if(name == "whenAgentsAreBusy"){
          this.setState({
            whenAgentsAreBusy:event.target.value,
            saveSetting:true
          })
        }
        else if(name == "whenAgentEndsLiveChatForUser"){
          this.setState({
            whenAgentEndsLiveChatForUser:event.target.value,
            saveSetting:true
          })
        }
      }

      emailSentToAgent = () =>{
        this.setState({
          emailSentToAgent:true,
          emailSentToUser:false
        })
      }
     
      emailSentToUser = () =>{
        this.setState({
          emailSentToAgent:false,
          emailSentToUser:true
        })
      }

      companyCheckBox = (event) =>{
        this.setState({
          companyCheckBox:event.target.checked,
          saveSetting:true
        })
      }

      lastNameCheckBox = (event) =>{
        this.setState({
          lastNameCheckBox:event.target.checked,
          saveSetting:true
        })
      }

      firstNameCheckBox = (event) =>{
        this.setState({
          firstNameCheckBox:event.target.checked,
          saveSetting:true

        })
      }
      emailIdCheckBox = (event) =>{
        this.setState({
          emailIdCheckBox:event.target.checked,
          saveSetting:true

        })
      }
      contactNumberCheckBox= (event) =>{
        this.setState({
          contactNumberCheckBox:event.target.checked,
          saveSetting:true

        })
      }
      sendConversationLog = (event) =>{
        this.setState({
          sendConversationLog:event.target.checked,
          saveSetting:true

        })
      }

      msgSendConversationLog = (event) =>{
        this.setState({
          msgSendConversationLog:event.target.checked,
          saveSetting:true

        })
      }
      sentVisitorDetails = (event) =>{
        this.setState({
          sendVisitorDetails:event.target.checked,
          saveSetting:true

        })
      }

      msgNumberOfConversationLog = (event) =>{        
          this.setState({
            msgNumberOfConversationLog: event.target.value,
            saveSetting:true
          })                 
      }

      numberOfConversationLog = (event) =>{        
        this.setState({
          numberOfConversationLog: event.target.value,
          saveSetting:true

        })
               
    }
    focus_Out = (event) =>{
      this.setState({agentsMatch:[]})
    }
    // handle input change
   handleInputChange = (e, index) => {
    var l,t
    var { name, value } = e.target;
    if(!value){
      var list = [...this.state.agentId]
      list[index][name]= '';
      this.setState({
        agentsMatch:[],
        agentId:list
    })
    }
    else{
      let matches = this.state.FilterUserData.filter((agent)=>{
        const regex = new RegExp(`${value}`,'gi');
        l = document.getElementById(index+"card").offsetLeft - document.getElementById(index+"card").scrollLeft;
        t = document.getElementById(index+"card").offsetTop - document.getElementById(index+"card").scrollTop + 40;
        return agent.match(regex)
      })
      var list = [...this.state.agentId]
      list[index][name]=value
      this.setState({
        validationMsg:-1,
        left:l,
        top:t,
        agentId:list,
        agentsMatch:matches,
        agentIndex:index
    })
    }
    if(list){
      for(var i=0;i<list.length;i++){
        if(list[i] != ''){
          this.setState({saveSetting:true})
          break;
        }
        else{
          this.setState({saveSetting:false})
        }
      }
    }
    else{
      this.setState({saveSetting:false})
    }
    // if(this.state.FilterUserData.includes(e.target.value.trim())){
    //   this.setState({
    //     validationMsg:-1,
    //     saveSetting:true
    //   })
    // } else {
    //   this.setState({
    //     validationMsg:(index),
    //     saveSetting:false

    //   })
    // }
    };

   handleRemoveClick = index => {
    const list = [...this.state.agentId];
    list.splice(index, 1);
    this.setState({
      agentId: list,
      agentsMatch:[],
      agentVal:[],
      agentIndex:0
    })
    if(list){
      for(var i=0;i<list.length;i++){
        if(list[i] != '')
        {
          this.setState({saveSetting:true})
          break;
        }
        else{
          this.setState({saveSetting:false})
        }
      }
    }
    else{
      this.setState({saveSetting:false})
    }
  };
 
  handleAddClick = () => {
    this.setState({
      agentId: [...this.state.agentId, { agentId: ""}],
      agentsMatch:[]
    })
  };
  setAgentVal = (e,agent,name) => {    
    var list = [...this.state.agentId]
    list[this.state.agentIndex][name]=agent
    this.setState({
      agentId:list,
      agentsMatch:[],
      
  })
  if(this.state.FilterUserData.includes(agent.trim())){
    this.setState({
      validationMsg:-1,
      saveSetting:true
    })
  } else {
    this.setState({
      validationMsg:(this.state.agentIndex),
      saveSetting:false

    })
  }
  }
  emailHandleChange = (e) =>{
    this.setState({
      captureUserEmail:e.target.checked,
      saveSetting:true
    })
    if(e.target.checked==true)
    {
      this.setState({
        msgToCaptureUserEmail:"Sorry all our agents seem to be busy. Please provide your email address so that one of our agents can reach out to you soon to help resolve your query."
      })
    }
  }
  onChangeHandler3 = (e, name) =>{ 
    if(name == "askForEmail")
        this.setState({msgToCaptureUserEmail:e.target.value,saveSetting:true})
    if(name=="end agent transfer request")
        this.setState({msgToEndAgentTransferRequest:e.target.value,saveSetting:true})

  }


    render () {
       const label = { inputProps: { 'aria-label': 'Switch demo' } };
       const GreenSwitch = styled(Switch)(({ theme }) => ({
        '& .MuiSwitch-switchBase.Mui-checked': {
          color: blue[600],
          '&:hover': {
            backgroundColor: alpha(blue[600], theme.palette.action.hoverOpacity),
          },
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
          backgroundColor: blue[600],
        },
      }));
       
            
       let agentList=[]
       agentList=this.props.agentList && Object.entries(this.props.agentList).length!== 0 && Object.values(this.props.agentList).map((item,key) => {
        return(
            <ListGroupItem onClick={()=>this.MoveItem(item)} key={key}> {item}</ListGroupItem>
        )   
      });
      

      let descbox = null
      if(this.state.desctouched )
      {
          descbox = this.state.description
      }

      else{
       // descbox =  this.state.msg ?this.state.msg:activeAgent.desc
      }

    
     
    

      let saveLoadingImg = null;
      if (this.props.saveLoading) {
          saveLoadingImg =(<Spinner/>)
      }
      else
      {
          saveLoadingImg = null;
      }
      
        return (
          <Container className="AgentTransferContainer" onClick={(e) => this.focus_Out(e)}>

                <Grid container md={12}>
                    <Grid item md={6}>
                    <h3>Agent Transfer</h3>
                
                    </Grid>
                    <Grid className="pr-3" style={{display:'flex',justifyContent:'space-between',width:'100%',alignItems:'center'}}>
                        <span className="paraGraph">Provision to configure the agents, the routing rules, the messages and the emails for the agent transfer functionality.</span>
                        <ButtonCustom variant='contained' disabled={(this.state.saveSetting==false||this.state.agentId==[{agentId:""}]?true:this.props.permissions.RW?false:true)} label='Save Settings'className='buttonWidth'  clicked={(e) =>this.saveActiveUser(e)}/>
                    </Grid>
                </Grid>
                 <Grid container md={12} className="topDropDown">
                    <Grid item md={4} className="pr-5">
                    <SelectCustom
                     name="selectName"
                     label="Channel"
                    //changed={(event) => this.onSelectHandler(event)}
                     required={true}
                     value={"MS Teams"}
                     options={[{value:'MS Teams',displayValue:'MS Teams'},]}
                     />  
                    </Grid>
                     <Grid item md={4} className="pr-5">
                    <SelectCustom
                     name="selectName"
                     label="Project"
                     changed={(event) => this.onProjectDropDown(event)}
                     required={true}
                     value={this.state.selectedProject}
                     options={this.state.projects && this.state.projects.map(user =>(
                      {value:user,displayValue:user}
                  ))
                  }/>
  
                    </Grid>
                    <Grid item md={4} className="pr-2 pl-3">
                    <ReactMultiSelectComponent
                    //  name="selectName"
                     label="Geography"
                     onChange={(event) => this.onSelectContry(event)}
                    //  required={true}
                     value={this.state.selectedContry}
                    options={this.props.countryList && this.props.countryList.map(contry =>(
                      {value:contry,label:contry}))}
                      onRemoveItem={this.onRemoveCountryList}
                      placeholder={'Select Country'}

                    /> 
                     </Grid>
                     
                 </Grid>

                 <Grid Container md={12} className="tabsMainContent">
                     <Grid item>
                        <TabCustom
                        tabs={["AGENT TRANSFER CONFIGURATION", "Message Configuration", "Email Configuration"]}
                        selected={this.state.selectedTab}
                        setSelected={this.selectedTab}
                        >
                        </TabCustom>
                     </Grid>
                </Grid>

                {this.state.selectedTab === 'AGENT TRANSFER CONFIGURATION'?
<>
                <Grid container md={12} className="AgentConfiguredDiv">
                
                    <Grid item md={4} className="pr-2" >
                      <div className='AgentsText'> Agents configured
                          <span style={{color:"black",cursor:"pointer"}} name="agentId" onClick = {(event) => this.handleAddClick(event)}><AddCircleIcon style={{fontSize:"20px",marginLeft:"5px"}}></AddCircleIcon></span>
                      </div>  
                      <Box className='textAreabox' style={{maxHeight:"200px", overflowY:"scroll"}}>
                              {this.state.agentId && this.state.agentId.map((item, index)=>{
                                return(
                                  <div>
                                    <input  id={index+"card"} name="agentId" className="textFocus default_css" placeholder="Enter agent email ids"  onChange={(e) => this.handleInputChange(e,index)} value={this.state.agentId[index].agentId} spellcheck="false" autocomplete="off"/>
                                    {this.state.agentId.length !== 1 && 
                                    <span onClick = {() => this.handleRemoveClick(index)} style={{cursor:"pointer"}}><RemoveCircleIcon style={{fontSize:"20px"}}></RemoveCircleIcon></span>}
                                      {/*<p style={{color:"red", textTransform: "inherit",lineHeight: "20px"}}><strong style={{fontFamily: "Roboto-Bold",fontSize: "12px",}}>{index === this.state.validationMsg ? "Agent emailId not exists" :''}</strong></p>*/}

                                    <div id="Card" style={{position:"absolute", width:"400px", maxHeight:"150px", overflowY:"scroll", overflowX:"hidden", left:this.state.left, top:this.state.top}}>
                                    {
                                      this.state.agentsMatch && this.state.agentsMatch.map((item)=>{
                                        return(
                                          <Card>
                                          <CardContent name = 'agentId' onClick = {(e)=>this.setAgentVal(e,item,'agentId')} style={{padding:"5px",cursor:"pointer"}}>  
                                           {item}
                                            </CardContent>
                                          </Card>
                                        )
                                      })
                                    }
                                    </div>
                                  </div>
                                )
                              } 
                              )}
                            </Box>

                    </Grid>

                 <Grid item md={4} className="pr-5">
                 {/* <SelectCustom
                     name="selectName"
                     label="Retries when agent rejects chat transfer request"
                    changed={(event) => this.OnselectRetiredAgent(event)}
                     required={true}
                     value={this.state.selectedRetiredAgent}
                     options={this.state.retiredAgents&& this.state.retiredAgents.map(nbr=>(
                      {value:Number(nbr),displayValue:nbr}
                     ))}/>  */}
                    <div className='mt-3'>
                        <SelectCustom
                        name="selectName"
                        label="Send chat transfer request when agent status is"
                        changed={(event) => this.onSelectHandler(event)}
                        required={true}
                        value={this.state.agentStatus}
                        options={[{value:'Available',displayValue:'Available'},
                        // {value:'Not-Available',displayValue:'Not-Available'},
                        ]}/> 
                    </div>
                    
                 </Grid>

                 <Grid item md={4} className="pr-3">
                 <div class="common-input">
                   <label class="mb-0 text-box-title adding-other-input form-label">Routing method
                   </label>
                </div>
                 <Box className='AgentsRoutingbox' value={this.state.AgentsRouting}>
                   {/* <TextAreaWithLabelComponent 
                                label=""
                                name="message"
                                required ={false}
                            // value={this.state.message}
                                className='text-area'
                                placeholder='Round Robin'
                            //  onChange={(e) =>this.onChangeHandler(e, 'message')}
                     />*/}
                     <ul className='AgentsRouting'>
                     <li className={((this.state.AgentsRouting=='Round Robin') ? "active": "")} 
                      onClick={()=>this.setState({"AgentsRouting":"Round Robin","saveSetting":true})}
                       >Round Robin</li>
                       {/* <li className={((this.state.AgentsRouting=='Best Agent') ? "active": "")} onClick={()=>this.setState({"AgentsRouting":"Best Agent","saveSetting":true})}>Best Agent</li> */}
                     </ul>
                    </Box>
                 </Grid>
             </Grid>
             </>:null}

{this.state.selectedTab==='Message Configuration'?
<>
                <h3 style={{marginTop:'20px'}}>Messages Shown to Agent</h3>
                <Grid container md={12}>
                    
                <Grid item md={4} style={{marginTop:'10px',paddingRight:'30px',}}>
                    <InputWithLabelComponent
                    type="text"
                    name="name"
                    label="When agent pings on the channel"
                    value={this.state.whenAgentPing}
                    readOnly={false}
                    onChange={(e) => this.onChangeHandler1(e, 'whenAgentPing')}
                    required={true}
                    autocomplete="new-password"
                  />
                  <InputWithLabelComponent
                    type="text"
                    name="name"
                    label="When agent receives the chat transfer request"
                    value={this.state.whenAgentReceiveChatTransferRequest}
                    readOnly={false}
                    onChange={(e) => this.onChangeHandler1(e, 'whenAgentReceiveChatTransferRequest')}
                    required={true}
                    autocomplete="new-password"
                  />
                    {/* <InputWithLabelComponent
                    type="text"
                    name="name"
                    label="When one is no longer configured as an agent"//{symtomtitle}
                    value={this.state.whenOneIsNoLongerConfigAsAgent}
                    readOnly={false}
                    onChange={(e) => this.onChangeHandler1(e, 'whenOneIsNoLongerConfigAsAgent')}
                    required={true}
                    autocomplete="new-password"
                  /> */}
                </Grid>
                <Grid item md={4} style={{marginTop:'10px',paddingRight:'30px',}}>
                  <InputWithLabelComponent
                    type="text"
                    name="name"
                    label="When agent rejects the chat transfer request"
                    value={this.state.whenAgentRejectsChatTransferRequest}
                    readOnly={false}
                    onChange={(e) => this.onChangeHandler1(e, 'whenAgentRejectsChatTransferRequest')}
                    required={true}
                    autocomplete="new-password"
                  />
                   <InputWithLabelComponent
                    type="text"
                    name="name"
                    label="When agent ends live chat"
                    value={this.state.whenAgentEndsLiveChat}
                    readOnly={false}
                    onChange={(e) => this.onChangeHandler1(e, 'whenAgentEndsLiveChat')}
                    required={true}
                    autocomplete="new-password"
                  />
                </Grid>
                <Grid item md={4} style={{marginTop:'10px',}}>
                    <InputWithLabelComponent
                    type="text"
                    name="name"
                    label="When agent accepts the chat transfer request"
                    value={this.state.whenAgentAcceptsChatTransferRequest}
                    readOnly={false}
                    onChange={(e) => this.onChangeHandler2(e)}
                    required={true}
                    autocomplete="new-password"
                  />
                   <InputWithLabelComponent
                    type="text"
                    name="name"
                    label="When one is configured as an agent"//{symtomtitle}
                    value={this.state.whenOneIsConfigAsAgent}
                    readOnly={false}
                    onChange={(e) => this.onChangeHandler1(e, 'whenOneIsConfigAsAgent')}
                    required={true}
                    autocomplete="new-password"
                  />
                </Grid>
                <label style={{fontFamily:"Roboto-Bold",fontSize:"12px",color:"#696868",textTransform:"inherit",marginTop:"10px"}}>Send conversation log</label><GreenSwitch {...label} checked={this.state.msgSendConversationLog} onChange={(event)=>this.msgSendConversationLog(event)}/>
                {this.state.msgSendConversationLog == true ? 
                    <Grid container xs={12} md={12} xl={12}>
                          <Grid item md={4} className="pr-5">
                          <SelectCustom
                          name="selectName"
                          label="Type of conversation log"
                          changed={(event) => this.onMsgSelectHandler(event)}
                          required={true}
                          value={this.state.msgTypeConversationLog}
                          options={[{value:'Only Question',displayValue:'Only Question'},
                          {value:'Question and Response',displayValue:'Question and Response'}
                          ]}
                          />  
                          </Grid>
                          <Grid item md={4} className="pr-5">
                          <SelectCustom
                          name="selectName"
                          label="Number of conversations to show"
                          changed={(event) => this.msgNumberOfConversationLog(event)}
                          required={true}
                          value={this.state.msgNumberOfConversationLog}
                          options={[{value:99,displayValue:'All'},{value:1,displayValue:'Last 1'},{value:5,displayValue:'Last 5'},{value:10,displayValue:'Last 10'},{value:15,displayValue:'Last 15'},{value:20,displayValue:'Last 20'}
                          ]}
                        />  
                    </Grid>
                    </Grid> : null}
                </Grid>

                <h3 style={{marginTop:'30px'}}>Messages Shown to User</h3>
                <Grid container md={12}>
                    
                <Grid item md={4} style={{marginTop:'10px',paddingRight:'30px',}}>
                   
                   <InputWithLabelComponent
                    type="text"
                    name="name"
                    label="When user initiates chat transfer"
                    value={this.state.whenUserInitChatTransfer}
                    readOnly={false}
                    onChange={(e) => this.onChangeHandler1(e, 'whenUserInitChatTransfer')}
                    required={true}
                    autocomplete="new-password"
                  />
                    <InputWithLabelComponent
                    type="text"
                    name="name"
                    label="When agent ends live chat"
                    value={this.state.whenAgentEndsLiveChatForUser}
                    readOnly={false}
                    onChange={(e) => this.onChangeHandler1(e, 'whenAgentEndsLiveChatForUser')}
                    required={true}
                    autocomplete="new-password"
                  />
                </Grid>
                <Grid item md={4} style={{marginTop:'10px',paddingRight:'30px',}}>
                    <InputWithLabelComponent
                    type="text"
                    name="name"
                    label="When agent accepts the chat transfer request"
                    value={this.state.whenAgentAcceptsChatTransferRequestForUser}
                    readOnly={false}
                    onChange={(e) => this.onChangeHandler1(e, 'whenAgentAcceptsChatTransferRequestForUser')}
                    required={true}
                    autocomplete="new-password"
                  />
                  
                </Grid>
                <Grid item md={4} style={{marginTop:'10px',}}>
                    <InputWithLabelComponent
                    type="text"
                    name="name"
                    label="When agents are busy"
                    value={this.state.whenAgentsAreBusy}
                    readOnly={false}
                    onChange={(e) => this.onChangeHandler1(e, 'whenAgentsAreBusy')}
                    required={true}
                    autocomplete="new-password"
                  />                   
                </Grid>
               
          </Grid>
                </>
                :null}
          {this.state.selectedTab === 'Email Configuration'?
            <>
                <Grid container spacing={2} style={{marginTop:"5px",}}>
                    <Grid item xs={3} md={3} xl={3}>
                      <Item style={{textAlign:"start",height:"100%"}}>
                          <div style={{padding:"5px",color:this.state.emailSentToAgent?"#5A85F2":''}}><a onClick={()=>this.emailSentToAgent()}>Email sent to Agent</a></div>
                          {/* <div style={{padding:"5px", color:this.state.emailSentToUser?"#5A85F2":''}}><a onClick={()=>this.emailSentToUser()}>Email sent to User</a></div> */}
                      </Item>
                    </Grid>
                    <Grid item xs={9} md={9} xl={9} style={{backgroundColor:"inherit"}}>
                      {this.state.emailSentToAgent &&<>
                        <Grid xs={12} md={12} xl={12}>
                            <Grid item xs={6} md={6} xl={6}>
                              <h4 style={{display:"flex"}}>Email sent to Agent
                              {/* <div className='AgentsText' style={{paddingLeft:"5px"}}> 
                                <LightTooltip
                                    arrow
                                    title="Email sent to Agent"
                                    placement="top"
                                  >
                                    <img src="./images/info_icon.png"/>
                                  </LightTooltip>
                              </div> */}
                              </h4>
                            </Grid>
                            <Grid className="pr-2" style={{display:'flex',justifyContent:'space-between',width:'100%',alignItems:'center'}}>
                                <span className="paraGraph" style={{fontSize:"16px"}}>When all agents are busy</span>
                            </Grid>
                        </Grid>

                        
                        {/* <div xs={12} md={12} xl={12} >

                          <div item xs={5} md={5} xl={5} style={{float:"left"}}>
                              aaaa
                          </div>

                          <div xs={5} md={5} xl={5} style={{float:"right"}}>
                              bbbb
                          </div>

                        </div> */}
                        <label style={{fontFamily:"Roboto-Bold",fontSize:"12px",color:"#696868",textTransform:"inherit"}}>Capture user email id</label><GreenSwitch onChange={(e) => this.emailHandleChange(e)} {...label} checked={this.state.captureUserEmail} />
                    <Grid container md={12}>
                    <Grid item md={6} xs={6} xl={6} style={{marginTop:'10px',paddingRight:'30px',}}>
                            <TextAreaWithLabelComponent
                                type="text"
                                name="name"
                                label="Message to capture user email id"
                                value={this.state.msgToCaptureUserEmail}
                                readOnly={false}
                                onChange={(e) => this.onChangeHandler3(e, 'askForEmail')}
                                required={true}
                                autocomplete="new-password"
                                style={{height:"60px",padding:"8px"}}
                                disabled={!this.state.captureUserEmail}
                            />
                        </Grid>
                        <Grid item md={6} xs={6} xl={6} style={{marginTop:'10px',paddingRight:'30px',}}>
                            <TextAreaWithLabelComponent
                                type="text"
                                name="name"
                                label="Message to end agent transfer request"
                                value={this.state.msgToEndAgentTransferRequest}
                                readOnly={false}
                                onChange={(e) => this.onChangeHandler3(e, 'end agent transfer request')}
                                required={true}
                                autocomplete="new-password"
                                style={{height:"60px",padding:"8px"}}
                                
                                
                            />
                        </Grid>
                        </Grid>

                    <Grid container md={12}>
                    
                      <Grid item xs={6} md={6} xl={6} style={{marginTop:'30px',paddingRight:'30px',}}>
                        
                        <InputWithLabelComponent
                          type="text"
                          name="name"
                          label="From email id"
                          disabled value={this.state.fromMailId}
                          readOnly={false}
                          // onChange={(e) => this.onChangeHandler(e, 'fromMailId')}
                          required={true}
                        />
                          <InputWithLabelComponent
                          type="text"
                          name="name"
                          label="Subject of email"
                          value={this.state.mailSubject}
                          readOnly={false}
                          onChange={(e) => this.onChangeHandler(e, 'mailSubject')}
                          required={true}
                        />
                        <InputWithLabelComponent
                          type="text"
                          name="name"
                          label="To email id"
                          value={this.state.toMailId}
                          readOnly={false}
                          onChange={(e) => this.onChangeHandler(e, 'toMailId')}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={6} md={6} xl={6} style={{marginTop:'30px',paddingRight:'30px',}}>
                      <Box className=''>
                     <div className='AgentsText'> 
                     <LightTooltip
                        arrow
                        title="Write Email"
                        placement="top"
                      >
                        <img
                          src="./images/info_icon.png"
                          style={{ position: "absolute", top: "6px", left: "80px" }}
                        />
                      </LightTooltip>
                     </div>
                    <TextAreaWithLabelComponent 
                                label="Body of email"
                                name="message"
                                required ={true}
                                value={this.state.mailBody}
                                className='text-area'
                                placeholder=''
                                onChange={(e) =>this.onChangeHandler(e,'mailBody')}
                                style={{height:"187px",padding: "4px"}}
                            />
                     </Box>
                        
                      </Grid>
                      
                    </Grid>
                    <label style={{fontFamily:"Roboto-Bold",fontSize:"12px",color:"#696868",textTransform:"inherit"}}>Send conversation log</label><GreenSwitch {...label} checked={this.state.sendConversationLog} onChange={(event)=>this.sendConversationLog(event)}/>
                        {this.state.sendConversationLog == true ? 
                    <Grid container xs={12} md={12} xl={12}>
                          <Grid item md={6} className="pr-5">
                          <SelectCustom
                          name="selectName"
                          label="Type of conversation log"
                          changed={(event) => this.onSelectHandler(event,'Type of conversation log')}
                          required={true}
                          value={this.state.typeConversationLog}
                          options={[{value:'Only Question',displayValue:'Only Question'},
                          {value:'Question and Response',displayValue:'Question and Response'}
                          ]}
                          />  
                          </Grid>
                          <Grid item md={6} className="pr-5">
                          <SelectCustom
                          name="selectName"
                          label="Number of conversations to show"
                          changed={(event) => this.numberOfConversationLog(event,'Number of conversations to show')}
                          required={true}
                          value={this.state.numberOfConversationLog}
                          options={[{value:99,displayValue:'All'},{value:1,displayValue:'Last 1'},{value:5,displayValue:'Last 5'},{value:10,displayValue:'Last 10'},{value:15,displayValue:'Last 15'},{value:20,displayValue:'Last 20'}
                          ]}
                        />
  
                    </Grid>

                    </Grid> : null}
                    {/* <label style={{fontFamily:"Roboto-Bold",fontSize:"12px",color:"#696868",textTransform:"capitalize"}}>Sent Visitor details</label><GreenSwitch {...label} checked={this.state.sendVisitorDetails} onChange={(event)=>this.sentVisitorDetails(event)}/> */}
                    {this.state.sendVisitorDetails == true ? 
                      <div>
                        <Checkbox checked={this.state.firstNameCheckBox} icon={<StopOutlinedIcon style={{fontSize: 40}}/>} checkedIcon={<StopIcon style={{ fontSize: 40, color:"#5A85F2" }}/>} onChange={(event)=>this.firstNameCheckBox(event)}/>
                        <label>First Name</label>
                        <Checkbox checked={this.state.lastNameCheckBox} icon={<StopOutlinedIcon style={{fontSize: 40}}/>} checkedIcon={<StopIcon style={{ fontSize: 40, color:"#5A85F2" }}/>} onChange={(event)=>this.lastNameCheckBox(event)}/>
                        <label>Last Name</label>
                        <Checkbox checked={this.state.companyCheckBox} icon={<StopOutlinedIcon style={{fontSize: 40}}/>} checkedIcon={<StopIcon style={{ fontSize: 40, color:"#5A85F2" }}/>} onChange={(event)=>this.companyCheckBox(event)}/>
                        <label>Company</label>
                        <Checkbox   checked={this.state.emailIdCheckBox} icon={<StopOutlinedIcon style={{fontSize: 40}}/>} checkedIcon={<StopIcon style={{ fontSize: 40, color:"#5A85F2" }}/>} onChange={(event)=>this.emailIdCheckBox(event)}/>
                        <label>Email id</label>
                        <Checkbox  checked={this.state.contactNumberCheckBox} icon={<StopOutlinedIcon style={{fontSize: 40}}/>} checkedIcon={<StopIcon style={{ fontSize: 40, color:"#5A85F2" }}/>} onChange={(event)=>this.contactNumberCheckBox(event)}/>
                        <label>Contact Number</label>
                      </div> : null}
                      </>
                      }
                       
                      {/* <input type="checkbox" id="" name="" value=''/> */}
                      
                    </Grid>
                </Grid>
            </>:''
          }
        </Container> 
              
        );
        
    }
    }

     
         
      const LightTooltip = withStyles((theme) => ({
        tooltip: {
          backgroundColor: theme.palette.common.white,
          color: "rgba(0, 0, 0, 0.87)",
          boxShadow: theme.shadows[1],
          fontSize: 12,
          fontFamily: "Roboto-Regular",
        },
        arrow: {
          color: theme.palette.common.white,
          "&:before": {
            border: "1px solid #E6E8ED",
            boxShadow: theme.shadows[1],
          },
        },
      }))(Tooltip);  

    const mapStateToProps = state => {
        return {
            loading: state.agentTransfer.loading,
            agentList:state.agentTransfer.agentList,
            saveloading: state.agentTransfer.saveloading,
            activeAgent:state.agentTransfer.activeAgent,
            projectList:state.user.projects,
            defaultlanginfo:state.user.defaultlanginfo,
            countryList:state.agentTransfer.countryList,
            agentTransferDetail:state.agentTransfer.agentTransferDetail,
            permissions:state.user.moduleAccess.conversationalAdmin,
            AssistantsList: state.projectview.AllAssistantsList,
        };
    };
    
    const mapDispatchToProps = dispatch => {
        return {      
              //  onGetAgent: (APIURL ,project) => dispatch( actions.agentList(APIURLproj,ect ) ),
                onListAgent: (APIURL,project ) => dispatch( actions.listActiveAgent(APIURL,project ) ),
                onsaveAgent: (user,APIURL ) => dispatch( actions.saveActiveAgent(user,APIURL ) ),
                onGetContry: (APIURL ) => dispatch( actions.getContry(APIURL ) ),
                onGetAgentTransferDetail: (APIURL) => dispatch( actions.getAgentTransferDetail(APIURL ) )
    
        };
    };
    
    export default connect( mapStateToProps, mapDispatchToProps )(withErrorHandler( AgentTransfer, axios ));
    
