import React, { useEffect, useState } from 'react';
import { Col, Row, Form } from "react-bootstrap";
import './Drawer.css';
import ButtonCustom from "../../../../components/UIComponents/Button/ButtonComponent";
import InputWithLabelComponent from "../../../SettingPage/SettingInnerPages/components/InputWithLabelComponent/InputWithLabelComponent";

const BoxSelectorDetails = ({node, variable, invalidVarMsg, handleExternalSubmit}) => {

  function getOptions() {
    if(node.data?.referenceVariable) return []; //because options are different if referenced
    else return node.data.optionValues;
  }
  const [label, setLabel] = useState(node.data.label);
  const [count, setCount] = useState(node.data.count);
  const [optionValues, setOptionValues] = useState(node.data.optionValues?getOptions():[]);
  const [referenceVariable, setReferenceVariable] = useState(node.data.referenceVariable);
  const [refVariableDetails, setRefVariableDetails] = useState(node.data.referenceVariable?node.data.optionValues[0]:{option:'',displayText:'',url:''});

  const [radioBtn, setRadioBtn] = useState();
  const [branched, setBranched] = useState(node.data?.branched?node.data.branched:'false');
  const [errorMsg, setErrorMsg] = useState(null);
  const [disableTextbox, setDisableTextbox] = useState(node.data?.disableTextbox!==undefined?node.data.disableTextbox:true);
  const [addToLog, setAddToLog] = useState(node.data.addToLog!==undefined? node.data.addToLog:true);

  useEffect(()=> {
      if(!/^[0-9]+$/i.test(count) && count !== undefined) {
        setErrorMsg('Please enter a number');
      } else {
        setErrorMsg(null);
      }
  },[count]);
  
  useEffect(()=> {
        if(referenceVariable !== undefined && referenceVariable !== null) {
          setRadioBtn('passByVar')
        } else 
          setRadioBtn('manual')
  },[]);

  const handleBranchingOption = (e) => {
      setBranched(e.target.value);
  }

  const handleOptionChange = (e) => {
      setRadioBtn(e.target.value);
      setReferenceVariable(undefined);     
  }

  const isFormValid = () => {
      let validStatus = variable && !invalidVarMsg;
      if(radioBtn === 'manual') {
          validStatus = validStatus && count && optionValues;
        } else {
          validStatus = validStatus && referenceVariable && refVariableDetails.option;
        }
        return validStatus && label;
  }
    

  const saveData = () => {
      let finalCount, optionsData = null;
      
      if(radioBtn === 'manual') {
          optionsData = optionValues;
          finalCount = parseInt(count);
      } else {
          optionsData = [refVariableDetails];
          finalCount = 1;
      }

      let data = {
          label: label,
          branched: branched,
          count:finalCount,
          referenceVariable:referenceVariable,
          disableTextbox:disableTextbox,
          optionValues:optionsData,
          addToLog: addToLog
        };
      handleExternalSubmit('boxSelector', data);
  }

  function handleSetCount (value) {
    
    if(value != '') {
      let currentCount = optionValues.length;
      
      value = parseInt(value);
      if (value > currentCount)
        addOptions(value - currentCount);
    }
    setCount(value);
      
  }

  function addOptions (total) {
    let tempArray = [...optionValues];
    for (let i = 0; i < total; i++) {
        tempArray.push({option:'', displayText:'',url:''});
    }
    setOptionValues(tempArray);
  }

  const updateOption = (index, key, value) => {
    let tempArray = [...optionValues];
    tempArray[index] = {...tempArray[index], [key]:value};
    setOptionValues(tempArray);
  }

  function reduceCount(index) {
    setCount(optionValues.length-1);
    setOptionValues(optionValues.filter((_, i) => i !== index));   
  }

  function renderOptions(index, item) {
    return (
        <div className='backgroundBox'>
            <div className="mt-1">
            <Col md={12}>
            <Row className="FormLabel-row">
                <Col md={9} className="FormLabel-col addBtn-label">
                <Form.Label className="mb-1 text-box-title adding-other-input">{'Option '.concat(index+1)+'*' }</Form.Label>
                </Col>    
                <Col md={1} className='countBtn-col plusBtn-align'>
                <div onClick={()=>reduceCount(index)}>
                    <img className='removeBtn-img' src="./Icons/removeCircleBlack.svg"/>
                </div>    
                </Col>        
            </Row>
            </Col>
            </div>
            <div className="mt-1">
            <Col md={12}>
                <InputWithLabelComponent
                    type="text"
                    name="optionText"
                    showLabel={false}
                    value={optionValues[index].option}
                    readOnly={false}
                    onChange={(e) => updateOption(index,'option',e.target.value)}
                    required={true}
                />
            </Col>
            </div>
            <div className="mt-1">
            <Col md={12} className='col-flex'>
                <div><Form.Label className="mb-1 text-box-title adding-other-input">Actions to perform</Form.Label></div>
            </Col>
            <Col md={12}>
            <Row>
            <Col md={6}>
                <InputWithLabelComponent
                    type="text"
                    name="displayText"
                    showLabel={false}
                    value={optionValues[index].displayText}
                    readOnly={false}
                    onChange={(e) => updateOption(index,'displayText',e.target.value)}
                    required={true}
                    placeHolder='Display Text'
                    // disabled={!listData[index].childPresent}
                />
            </Col>
            <Col md={6}>
                <InputWithLabelComponent
                    type="text"
                    name="childKey"
                    showLabel={false}
                    value={optionValues[index].url}
                    readOnly={false}
                    onChange={(e) => updateOption(index,'url',e.target.value)}
                    required={true}
                    placeHolder='URL'
                    // disabled={!listData[index].childPresent}
                />
            </Col>
            </Row></Col>
            </div>
        </div>
    )
  }

  function setRefVariableOption (key, value) {
    setRefVariableDetails((data)=> ({...data, [key]:value}));
  }

  return (
      <>
      <div className="mt-1">
          <Col md={12}>
              <InputWithLabelComponent
                  type="text"
                  name="label"
                  label = {'Text Message*'}
                  value={label}
                  readOnly={false}
                  onChange={(e) => setLabel(e.target.value)}
                  required={true}
              />
          </Col>
      </div>

      <div className="mt-1">
      <Col md={12}>
        <div>
          <input type="radio" id="manual" name="inputMethod" value="manual" checked={radioBtn === 'manual'} onChange={handleOptionChange}/>
          <label >Manual</label>
          <input type="radio" id="passByVar" name="inputMethod" value="passByVar" checked={radioBtn === 'passByVar'} onChange={handleOptionChange}/>
          <label >Reference variable</label><br/>
        </div>
      </Col>
      </div>

      {radioBtn === 'manual' && (
        <>
        <div className="mt-1">
        <Col md={12}>
            <InputWithLabelComponent
                type="text"
                name="selectorCount"
                label= "Number of Options Required*"
                value={count}
                readOnly={false}
                onChange={(e) => handleSetCount(e.target.value)}
                required={true}
                placeHolder="Enter a number"
                isInvalid={errorMsg!==null}
                error={errorMsg}
        />
        </Col>
        </div>
        <div id='optionsDiv'>
        {optionValues.map((item, index)=> renderOptions(index,item))}
        </div>
        <div className="mt-1">
        <Col md={12}>
            <label>Is Branching required?</label> <br/>
            <div>
              <input type="radio" id="true" name="branched" value="true" checked={branched==='true'} onChange={handleBranchingOption}/>
              <label >Yes</label>
              <input type="radio" id="false" name="branched" value="false" checked={branched==='false'} onChange={handleBranchingOption}/>
              <label >No</label><br/>
            </div>
        </Col>
        </div>
        </>
      )}

      {radioBtn === 'passByVar' && (
        <>
        <div className="mt-1">
        <Col md={12}>
        <InputWithLabelComponent
                type="text"
                name="label"
                label="Reference Variable*"
                value={referenceVariable}
                readOnly={false}
                onChange={(e) => setReferenceVariable(e.target.value)}
                required={true}
          />
        </Col>
        </div>
        <div className="mt-1">
        <Col md={12}>
        <InputWithLabelComponent
                type="text"
                name="label"
                label="Option key*"
                value={refVariableDetails.option}
                readOnly={false}
                onChange={(e) => setRefVariableOption('option',e.target.value)}
                required={false}
          />
        </Col>
        </div>
        <div className="mt-1">
          <Col md={12} className='col-flex'>
              <div><Form.Label className="mb-1 text-box-title adding-other-input">Actions to perform</Form.Label></div>
          </Col>
          <Col md={12} >
          <Row>
          <Col md={6}>
              <InputWithLabelComponent
                  type="text"
                  name="displayText"
                  showLabel={false}
                  value={refVariableDetails.displayText}
                  readOnly={false}
                  onChange={(e) => setRefVariableOption('displayText',e.target.value)}
                  required={true}
                  placeHolder='Display Text'
                  // disabled={!listData[index].childPresent}
              />
          </Col>
          <Col md={6}>
              <InputWithLabelComponent
                  type="text"
                  name="childKey"
                  showLabel={false}
                  value={refVariableDetails.url}
                  readOnly={false}
                  onChange={(e) => setRefVariableOption('url',e.target.value)}
                  required={true}
                  placeHolder='URL'
                  // disabled={!listData[index].childPresent}
              />
          </Col>
          </Row></Col></div>
        </>
      )}

      <div className="mt-1">
          <Col md={12}>
          <Form>
              <Form.Check 
              type="checkbox"
              id={`disable-textbox`+variable}
              label='Disable Textbox'
              checked = {disableTextbox}
              onChange={e=>setDisableTextbox(e.target.checked)}
              />
          </Form>
          </Col>
      </div>

      <div className="mt-1"  id="checkInCdNode">
        <Col md={12}>
        <Form>
            <Form.Check 
            type="checkbox"
            id={`addToLog`+variable}
            label='Capture as conversation log'
            checked = {addToLog}
            onChange={e=>setAddToLog(e.target.checked)}
            />
        </Form>
        </Col>
      </div>

      <br/>
      <div className="mt-1">
      <Col md={12}>
          <div className="mt-1">
              <ButtonCustom variant='contained' label='Save'className='buttonWidth' 
              disabled={!isFormValid()} clicked={saveData}/>
          </div>
      </Col>
      </div>
      </>
  )
}

export default BoxSelectorDetails;