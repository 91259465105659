import React from "react";
import classes from "./Entities.module.css";
import "./Entities.module.css";
import { Col, Row , Button} from "react-bootstrap";
import InputWithLabelComponent from "../../../containers/SettingPage/SettingInnerPages/components/InputWithLabelComponent/InputWithLabelComponent";
import SelectCustom from "../../UIComponents/SelectDropdown/SelectComponent";


const EntitiesComponent = (props) => {
  return (
    <>
      <InputWithLabelComponent
        type="text"
        name="entityName"
        label="Entities Name*"
        value={props.entityName}
        readOnly={false}
        onChange={props.changed}
        required={true}
        isInvalid={!props.entityName}
        error="Entity name is required"
      />
      <SelectCustom
        name="validation"
        label="Select Validation Type*"
        changed={props.changed}
        required={true}
        value={
          props.validationText === "custom"
            ? props.validationText
            : props.validation
        }
        options={props.validationOptionValues}
      />
      <div className={classes.errvalid}>
      {!props.validation&&!props.validationText?props.emptyValidationNameMsg:null}
      </div>
      {props.validationText === "custom" ? (
        <>
          <InputWithLabelComponent
            type="text"
            name="validationText"
            label="Validation Name*"
            placeHolder="Enter Reg expression type.."
            value={props.validationName}
            readOnly={false}
            onChange={props.changed}
            required={true}
            isInvalid={props.emptyValidationTypeMsg}
            error="Validation name is required"
          />
          <InputWithLabelComponent
            type="text"
            name="ValidationValue"
            label="Validation*"
            placeHolder="Enter Reg expression.."
            value={props.validation}
            readOnly={false}
            onChange={props.changed}
            required={true}
            isInvalid={props.emptyValidationValueMsg}
            error="Validation expression is required"
          />
        </>
      ) : (
        <InputWithLabelComponent
          type="text"
          name="validationName"
          label="Validation*"
          placeHolder="Enter Reg expression.."
          value={props.validation}
          readOnly={false}
          onChange={props.changed}
          required={true}
          isInvalid={false}
          error=""
        />
      )}

      <InputWithLabelComponent
        type="text"
        name="invalidEntity"
        label="Validation  Message*"
        placeHolder="Enter message to be displayed when entity is invalid.."
        value={props.invalidEntity}
        readOnly={false}
        onChange={props.changed}
        required={true}
        isInvalid={props.emptyValidationMsg}
        error="Validation message is required"
      />
      <InputWithLabelComponent
        type="text"
        name="question"
        label="Question*"
        placeHolder="Enter Question to display when entity is missing.."
        value={props.question}
        readOnly={false}
        onChange={props.changed}
        required={true}
        isInvalid={!props.question?props.emptyQuestionMsg:null}
        error="Question is required"
      />
      <Row>
        <Col md={6}>
          <InputWithLabelComponent
            type="text"
            name="defaultValue"
            label="Default Value"
            placeHolder=""
            value={props.defaultValue}
            readOnly={false}
            onChange={props.changed}
            isInvalid={props.emptyDefaultMsg}
            error={props.defaultValue!=""?"Default value does not match the validation expression":null}/>
        </Col>
        <Col md={6}>
          <SelectCustom
            name="optionType"
            label="Option Type"
            changed={props.changed}
            required={true}
            value={props.optionType}
            options={[
              { value: "-1", displayValue: "Select" },
              { value: "addoption", displayValue: "Add Option" },
              /*{
                value: "confirmation",
                displayValue: "Confirmation",
                disabled: true,
              },*/
              { value: "password", displayValue: "Password" },
              /*{ value: "form", displayValue: "HTML Form", disabled: true },*/
            ]}
          />
        </Col>
      </Row>

      <Row>
        {props.addOption === true ? 
          <>
            <div className={[classes.addOptions, "input-group"].join(" ")}>
              <input
                type="text"
                className={["form-control", classes.addOptionValue].join(" ")}
                name="addOptionValue"
                autoComplete="off"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    props.buttonClicked(e);
                  }
                }}
                onChange={props.changed}
                value={props.addOptionValue}
                placeholder="type here"
              ></input>
              {/* <span className="input-group-btn"> */}
                <Button 
                disabled={props.addOptionValue==""||props.validateOptionMsg!=null||props.onEditAddBtnDisable==false ?true:false}
                

                style={{fontSize:"14px", border:"none",fontWeight:"bold",color:"white",margin:'0px'}}
                // class="btn blue btn-primary font-weight-bold "
                  type="button"
                  onClick={(e) => props.buttonClicked(e)}
                >
                  ADD
                </Button>
              {/* </span> */}
            </div>
            <span className={classes.optionerr}>
         {props.addOptionList.length==0&&!props.validateOptionMsg&&props.emptyaddoptionMsg&&props.addOptionValue==""?"Please provide the options":null}
            </span>
                
           <div className={classes.topSpace}>{props.validateOptionMsg}</div>
           </>:null}
            
        </Row>
        {props.addOptionList && <div className='mt-3 w-400'>
            {props.addOptionList}
        </div>}
        
        <Row>
        <Col md={6}>
          {props.optionType == "password" ||
          props.optionType == "form" ? null : (
            <SelectCustom
              name="display"
              label="Display Option"
              changed={props.changed}
              required={false}
              value={props.display}
              options={[
                { value: "-1", displayValue: "Select" },
                { value: "checkbox", displayValue: "Checkbox" },
                { value: "dropdown", displayValue: "Dropdown" },
                /*{ value: "list", displayValue: "ListBox", disabled: true },*/
                { value: "radio", displayValue: "Radio Button" },
                { value: "button", displayValue: "Button" },
              ]}
            />
          )}
        <span className={classes.displayoptionerr}>
       {props.addOption==true && props.optionType!=='-1'&&props.display=='-1'&&props. emptydisplayMsg?"Please select display option":null}
        </span>  
        </Col>
        <Col md={6}>
          <SelectCustom
            name="entityType"
            label="Type of Entity"
            changed={props.Entitychanged}
            required={false}
            value={props.entityType}
            options={[
              { value: "-1", displayValue: "Select" },
              { value: "false", displayValue: "Required" },
              { value: "true", displayValue: "Optional" },
            ]}
          />
        </Col>
      </Row>
      <SelectCustom
        name="remember"
        label="Remember this Entity"
        changed={props.Rememberchanged}
        required={false}
        value={props.remember}
        options={[
          { value: "-1", displayValue: "Select" },
          { value: "false", displayValue: "No" },
          { value: "true", displayValue: "Yes" },
        ]}
      />
    </>
  );
};

export default EntitiesComponent;
