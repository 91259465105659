import React, { useState, useEffect } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import ClusterResultsComponent from './ClusterResultsComponent/ClusterResultsComponent';
import ClustersCardComponent from './ClustersCardComponent';
import fetchClustersDataAction from '../../../../../../../store/actions/agentInsights/fetchClustersData/fetchClustersDataAction';
import { get } from 'lodash';


const IssueClustersTab = ({
    config, updateCounts
}) => {

    const [showDetailsComponent, setShowDetailsComponent] = useState(false);
    const [selectedIssueId, setSelectedIssueId] = useState(null);
    const onShowDetailsTab = (id) => {
        setShowDetailsComponent(true);
        setShowDrawer(true);
        setSelectedIssueId(id);
    };
    const [searchText, setSearchText] = useState('');
    const [data, setData] = useState([]);
    const [showDrawer, setShowDrawer] = useState(false);
    const toggleDrawer = (value) => (event) => {
      if (
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }
      setShowDrawer(value);
    };

    const clustersDataLoading = useSelector(state => state.agentInsights.clustersDataLoading);
    const dispatch = useDispatch();
    const clustersData = useSelector(state => state.agentInsights.clustersData);

    useEffect(() => {
        dispatch(fetchClustersDataAction(config.INSIGHTS_API_URL));
    }, []);

    useEffect(() => {
        setData(clustersData);
        updateCounts("issueClusters", clustersData)
    }, [clustersData]);

    const searchData = (event) => {
        setSearchText(event.target.value);
        const filteredRows = clustersData.filter((row) => {
            if (event.target.value.trim() === '') {
                setData(clustersData);
                updateCounts("issueClusters", clustersData)
            }
            return row.label.toLowerCase().includes(event.target.value.toLowerCase())
        });
        setData(filteredRows);
        updateCounts("issueClusters", filteredRows)
    }

    return (
        <React.Fragment>
            {data.length>0?
             <>
            <Row className='mt-3'>
                <Col xs={{ span: 3, offset: 9 }}  className='d-flex'>
                    <div className="search-input">
                    {/* <SearchComponent
                                name={'recomended-solution'}
                                searchValue={searchText}
                                onSearch={searchData}
                                placeHolder={'Search'}
                            /> */}
                    </div>
                </Col>
            </Row>
            {/* <Row className="d-flex justify-content-end mr-4 pt-3">
                <img src="images/Search.svg" />
            </Row> */}
            <Row className="d-flex pl-2 mt-4">
                    <Col xs='6'>
                    <p className="issue-descp-title ">TITLE</p>
                </Col>
                <Col xs='3' className="pl-0">
                <p className="issue-descp-title">ISSUES</p>
                </Col>
               
                </Row>
            {clustersDataLoading ? <Row className="justify-content-center align-items-center">
                <Spinner animation="border" variant="primary" />
            </Row> :
                <React.Fragment>
                    <Row className='insights-tab-scrool'>
                        { data.length ?
                                data.map((item, index) => {
                                    return (
                                        <ClustersCardComponent
                                            key = {item.label}
                                            active={selectedIssueId === index}
                                            label={item.label}
                                            ticketsCount={item.documents.length}
                                            showDetailsTab={() => onShowDetailsTab(index)}
                                            config={config}
                                        />

                                    )
                                }) : null
                        }
                    </Row>
                    <div className='insights-tab-scrool mt-2'>
                    {showDrawer && data.length ?
                            <ClusterResultsComponent
                                data={data}
                                onDetailsCloseHandler={() => setShowDrawer(false)}
                                toggleDrawer={toggleDrawer}
                                item={get(data, `${selectedIssueId}`, '')}
                                key={get(data, `${selectedIssueId}`, '')}
                                config={config}
                            />
                            : null
                        }
                    </div>
                </React.Fragment>}
            </>
            :
            <div className="row nodataAgentcockpit justify-content-center align-items-center">
                No Data Available 
          </div>
        }
        </React.Fragment>
    )
};

export default IssueClustersTab;