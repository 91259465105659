import React, { useEffect, useState } from "react";
import {  useSelector, useDispatch } from "react-redux";
import { Row, Col, Container } from "react-bootstrap";
import "../Insight/AdminConfiguration/AdminHome.css";
import Heading3 from "../components/Typography/Heading3/Heading3";
import NavbarCustom from "../../../../components/UI/Navbar/Navbar";
import { connect } from "react-redux";
import * as actions from "../../../../store/actions/index";
import SourceFields from "../Insight/AdminConfiguration/SourceFields";
import { encryptAndStore } from "../../../../utility/utility";
import Grid from "@material-ui/core/Grid";
import './UseCase.css'
import ButtonComponent from "../../../../components/UIComponents/Button/ButtonComponent";
import { Button, Form } from 'react-bootstrap';
import CreateUseCareDrawer from "./CreateUseCareDrawer";
import CreateUseCase from "./CreateUseCase";
import DrawerComponent from "../../../../components/UIComponents/Drawer/DrawerComponent";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles, withStyles } from '@material-ui/core';
import ConfigureModel from "./ConfigureModel";
import { GetUseCaseDetails } from "../../../../store/actions/index";
import moment from 'moment';
import { GetUseCaseDetailsByName } from "../../../../store/actions/index";
import SpinnerPage from "../../../../components/UI/Loader/Loader";
const useStyles = makeStyles((theme) => ({
  menuItems: {
    right: '10px !important',
    fontSize: 14,
    fontWeight: 'bold',
    fontFamily: 'Roboto-Regular',
    color: 'black',
    '&.active, &:hover, &.active:hover': {
      background: 'none',
      color: '#2A93DF',
    }
  },
  menu: {
    width: '180px !important',
    marginRight: '10px !important',
  }
}));

const UseCase = ({
  config,
  onChangeAdminHome,
  projectList,
  onchangeProjectAction,
}) => {
  const [popperShow, setPopperShow] = useState(false);
  const [searchT, SetSearchT] = useState("");
  const [projectname, SetProjectname] = useState("Project List");
  const [sourceListData, setSourceListData] = useState("");
  const [sourceSelect, setSourceSelect] = useState("ServiceNow - demodata");
  const [openDrawer, setOpenDrawer] = useState(false)
  const [menuShow, setMenuShow] = useState(null);
  const [menuOpen, setMenuOpen] = useState(null);
  const [nxtVal, setNxtVal] = useState('');
  const [configureModel, setConfigureModel] = useState(false);
  const [loading, setLoading] = useState(true);
  const [datafromchild, setDatafromchild] = useState("true");
  const [viewModelOutput, setViewModelOutput] = useState(false)
  const [ingestData, setIngestData] = useState(false)
  const [dataToDisplay, SetDataToDisplay] = useState([{
    useCaseName: 'Chrun Prediction',
    Description: 'Something about this use case',
    ModeUsed: 'Customer Feedback',
    CreatedDate: 'Wed Aug 16 2023',
    Status: 'Trained',
    Actions: '...'
  },
  {
    useCaseName: 'Chrun Prediction',
    Description: 'Something about this use case',
    ModeUsed: 'Customer Feedback',
    CreatedDate: 'Wed Aug 16 2023',
    Status: 'Trained',
    Actions: '...'
  },
  {
    useCaseName: 'Chrun Prediction',
    Description: 'Something about this use case',
    ModeUsed: 'Customer Feedback',
    CreatedDate: 'Wed Aug 16 2023',
    Status: 'Not Trained',
    Actions: '...'
  }
  ])
  const usecasedata = useSelector((state) => state.UnsupervisedML.Usecase);
  const clss = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    if (sourceListData.length > 0) {
      const source = sourceListData[0]
      setSourceSelect(source);
    }
    else {
      setSourceSelect("ServiceNow - demodata");
    }
  }, [sourceListData]);
  useEffect((props) => {
    dispatch(GetUseCaseDetails('http://10.14.108.21:32470'));
    setTimeout(() => {
      setLoading(false);
      }, 2000);
  }, []);
  const handleDataFromChild = (data) => {
    setDatafromchild(data)
    setOpenDrawer(false)
    setTimeout(() => {
        dispatch(GetUseCaseDetails('http://10.14.108.21:32470'));
        setLoading(true);
    }, 1000);
    setTimeout(() => {
          setLoading(false);
          }, 2000);
  }
  const onSourceSelectChange = (selected) => {
    setSourceSelect(selected);
  };

  const handleTrainData = (langValue) => {
    onChangeAdminHome(langValue);
  };

  const setSearchTerm = (item) => {
    SetSearchT(item);
  };
  const sourceVal = sourceListData.length > 0 ?
    sourceListData[sourceListData.length - 1].slice(13).trim() : sourceListData[0];

  const projectclick = (event, projectname) => {
    encryptAndStore("sourceSelect", sourceVal);
    SetSearchT("");
    setPopperShow(false);
    let savedProjectName = localStorage.getItem("projectname");
    if (savedProjectName !== projectname) {
      // localStorage.setItem("projectname", projectname);
      encryptAndStore("projectname", projectname);
      SetProjectname(projectname);
    }
    onchangeProjectAction(projectname);
    //window.updateHCLChatBotURL(projectname);
    dispatch(GetUseCaseDetails('http://10.14.108.21:32470'));
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      }, 2000);

  };

  const popperShowFn = () => {
    setPopperShow(true);
  };

  const onHide = () => {
    setOpenDrawer(false)
    dispatch(GetUseCaseDetails(config.COMMON_API_URL));
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      }, 2000);
  }

  const toggleDrawer = (open) => (event) => {
    if (!open) {
      onHide();
      
    }
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      onHide();
    }
  };

  const createUseCaseDrawer = () => {
    setOpenDrawer(true)
  }

  const handleCloseMenu = () => {
    setMenuShow(null);
    setMenuOpen(null)
  };

  const handleOpenMenu = (event, index) => {
    console.log(usecasedata[index].useCaseName, 'siva')
    let status = usecasedata[index].status
    if (status == 'Not Trained') {
      setMenuOpen(event.currentTarget)
    }
    else {
      setMenuShow(event.currentTarget)
    }
    let temp=usecasedata[index].useCaseName
    setNxtVal(temp)
  }

  const handleConfigureModel = (event,index) => {
    setMenuOpen(null)
    setConfigureModel(true)
  }
  const handleviewModelOutput = () =>{
    setViewModelOutput(true)
    setMenuOpen(false)
    setIngestData(false)
  }
  const handleingestData = () =>{
    setIngestData(true)
    setMenuOpen(false)
    setViewModelOutput(false)
  }

  return (
    <>
    {configureModel == true || viewModelOutput == true || ingestData == true ? <ConfigureModel data={nxtVal} config={config}  viewModelOutput={viewModelOutput} ingestData={ingestData}/> :
      <Container className="trigae-configuration-page triageTabStyle">
        <Row md={12} >
        {loading ===true ? <SpinnerPage/> : null}
          <Col md={9} className="mt-4 mb-3 triage-config-header">
            <div className="d-flex">
              <Heading3
                text="Use Cases"
                className="pageTitleText"
              />
            </div>
          </Col>
          <Col md={2} className=" projectDropdown mt-4  ml-5 Project-align-styles mb-3" >
            <NavbarCustom
              dataList={projectList}
              addImportProjectIcon={false}
              setSearchTerm={(item) => setSearchTerm(item)}
              popperShowFn={() => popperShowFn()}
              popperShow={popperShow}
              searchTerm={searchT}
              projectclick={(event, item) => projectclick(event, item)}
              projectname={projectname}
              rightPopUp={false}
              className={"admin-page-changes"}
            ></NavbarCustom>
          </Col>
        </Row>
        <Grid item md={12} className="button pt-2 pb-2">
          <Row>
            <Col md={9}></Col>
            <Col md={3} className=" createButton pt-3 pr-4">
              <ButtonComponent
                style={{ width: '200px' }}
                label='CREATE USE CASE'
                variant='contained'
                clicked={() => { createUseCaseDrawer() }}
                className='buttonWidth add-lang-button'
                disabled={false}
              />
              {/* <Button
                variant="primary"
                // className='large-border-radius'
                style={{ width: '180px' }}
                onClick={createUseCaseDrawer}
              >
                CREATE USE CASE
              </Button> */}
            </Col>
          </Row>
        </Grid>
        <Grid item lg={12} className='pl-2 pr-2 pt-5 ML-3 container_css'>
          {/* <Row md={12}style={{ fontSize: "14px", fontWeight: "bold" }} className='ml-2 mr-2 mb-3'>
                                        <Col md={2}className='ml-4'>USE CASE NAME</Col>
                                        <Col>DESCRIPTION</Col>
                                        <Col md={2}>MODEL USED</Col>
                                        <Col>CREATED ON</Col>
                                        <Col>STATUS</Col>
                                        <Col>ACTIONS</Col>
                                    </Row>
                                        <Col>
                                            <span class="radio">
                                                <input id="radio-1" name="radio" type="radio" />
                                                <label for="radio-1" class="radio-label"></label>
                                            </span><span className='tabData'>Chrun Prediction</span></Col>
                                        <Col>Something about this use case</Col>
                                        <Col>Customer Feedback</Col>
                                        <Col>Wed Aug 16 2023</Col>
                                        <Col>Trained</Col>
                                        <Col>...</Col>
                                        <Col className='image'>
                                            <div>
                                                <img src="./images/Vector.svg" style={{width:"15px",height:'15px'}} />
                                            </div>
                                        </Col>                              
                                         </Row>
                                    <Row style={{ fontSize: "14px", backgroundColor: "#E1E1E1", height: '55px', alignItems: "center", borderRadius: "3px", color: "#555555" }} className='ml-2 mr-2 mb-2'>
                                        <Col>
                                            <span class="radio">
                                                <input id="radio-1" name="radio" type="radio" />
                                                <label for="radio-1" class="radio-label"></label>
                                            </span><span className='tabData'>Chrun Prediction</span></Col>
                                        <Col>Something about this use case</Col>
                                        <Col>Customer Feedback</Col>
                                        <Col>Wed Aug 16 2023</Col>
                                        <Col>Trained</Col>
                                        <Col>...</Col>
                                         <Col className='image'>
                                            <div>
                                                <img src="./images/Vector.svg" style={{width:"15px",height:'15px'}} />
                                            </div>
                                        </Col>
                                    </Row>
                                     <Table
                                    striped bordered hover responsive="md"
                                     style={{ border: 'none' }}>
                                        <thead style={{ fontSize: "14px" }}>
                                            <tr>
                                                <th>Use Case Name</th>
                                                <th>Description</th>
                                            </tr>
                                        </thead>
                                        <tbody style={{ fontSize: "14px" }}>
                                            <tr>
                                                <td>Chrun Prediction</td>
                                                <td>Something about this use case</td>
                                            </tr>
                                        </tbody>
                                    </Table>  */}
          <table className="custom-tablee" style={{ overflow:"scroll"}}>
            <thead style={{ fontFamily: "Roboto-Bold",fontSize:"16px"}}>
              <tr>
                <th>USE CASE NAME</th>
                <th className="spacing-cell"></th>
                <th>DESCRIPTION</th>
                <th className="spacing-cell"></th>
                <th>MODEL USED</th>
                <th>CREATED ON</th>
                <th>STATUS</th>
                <th>ACTIONS</th>
              </tr>
            </thead>
            <tbody style={{ fontFamily: "Roboto-Regular" }}>
              {usecasedata&&usecasedata.map((item, index) =>
              (
                <React.Fragment key={index}>
                  <tr className="row-box" style={{ height: '50px', marginBottom: '5px' }}>
                    <td style={{ color: 'black', fontSize: '13px', boxShadow: ' 5px #d3cfcf'}} className="row-data">{item.useCaseName}</td><td className="spacing-cell"></td>
                    <td style={{ color: 'black', fontSize: '13px', boxShadow: ' 5px #d3cfcf' }} className="row-data">{item.description}</td><td className="spacing-cell"></td>
                    <td style={{ color: 'black', fontSize: '13px', boxShadow: '5px #d3cfcf' }} className="row-data">{item.model}</td>
                    <td style={{ color: 'black', fontSize: '13px', boxShadow: '5px #d3cfcf' }} className="row-data">{moment(item.createdDate).toString().slice(0,15)}</td>
                    <td style={{ color: 'black', fontSize: '13px', boxShadow: '5px #d3cfcf' }} className="row-data">{item.status}</td>
                    <td style={{ color: 'black', fontSize: '13px', boxShadow: '5px #d3cfcf' }} className="row-data" onClick={(e) => handleOpenMenu(e, index)}>...</td>
                  </tr>
                  <tr key={`${index}-container`}>
                    <td colSpan="6">
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </Grid>
        {/* <Row>
        <Col xs="12">
          <CreateUseCase
            config={config}
            OnSelectTrainedData={handleTrainData}
            selectedSource={sourceSelect}
            sourceListData={sourceListData}
            sourceVal={sourceVal}
          />
        </Col>
      </Row> */}
      </Container> }

      <Menu
        id="multimediaDocMenu"
        anchorEl={menuOpen}
        keepMounted
        open={Boolean(menuOpen)}
                // open={Boolean(menuShow)}
        onClose={handleCloseMenu}
        className={clss.menu}
      >
        <MenuItem className={clss.menuItems}
          onClick={handleConfigureModel}
        >CONFIGURE MODEL</MenuItem>
        {/* <MenuItem disabled={otherDocsPermission.R && otherDocsPermission.WR ? false : true} className={clss.menuItems} onClick={(event) => deleteConfirmation()}>DELETE</MenuItem> */}
      </Menu>
      <Menu
        id="multimediaDocMenu"
        anchorEl={menuOpen}
        keepMounted
       open={Boolean(menuShow)}
      //  open={Boolean(menuOpen)}
        onClose={handleCloseMenu}
        className={clss.menu}
      >
        <MenuItem className={clss.menuItems}
        // onClick={editRow}
        >RETRAIN</MenuItem>
        <MenuItem className={clss.menuItems}
          onClick={handleviewModelOutput}
          >VIEW MODEL OUTPUT</MenuItem>
        <MenuItem className={clss.menuItems}
        onClick={handleingestData}
        >INGEST PREDICTION DATA</MenuItem>
        {/* <MenuItem disabled={otherDocsPermission.R && otherDocsPermission.WR ? false : true} className={clss.menuItems} onClick={(event) => deleteConfirmation()}>DELETE</MenuItem> */}
      </Menu>
      {/* <Menu
        id="multimediaDocMenu"
        anchorEl={menuShow}
        keepMounted
        // open={Boolean(menuShow)}
        open={Boolean(menuOpen)}
        onClose={handleCloseMenu}
        className={clss.menu}
      >
        <MenuItem className={clss.menuItems}
        // onClick={editRow}
        >RETRAIN</MenuItem>
        <MenuItem className={clss.menuItems}
          onClick={handleviewModelOutput}
          >VIEW MODEL OUTPUT</MenuItem>
        <MenuItem className={clss.menuItems}
        onClick={handleingestData}
        >INGEST PREDICTION DATA</MenuItem>
        {/* <MenuItem disabled={otherDocsPermission.R && otherDocsPermission.WR ? false : true} className={clss.menuItems} onClick={(event) => deleteConfirmation()}>DELETE</MenuItem> */}
      {/* </Menu> */} 
      <DrawerComponent
        heading="Create Use Case"
        showDrawer={toggleDrawer(false)}
        openDrawer={openDrawer}
      >
        {openDrawer === true ?
          <div>
            <CreateUseCareDrawer toggleDrawer={toggleDrawer()} onDataPassed={handleDataFromChild} />
          </div> : null}
      </DrawerComponent>
    </>
  );
};


const mapStateToProps = (state) => {
  console.log(state.UnsupervisedML.Usecase)
  return {
    projectList: state.user.projects,
    usecasedata : state.UnsupervisedML.Usecase,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onchangeProjectAction: (projectname) =>dispatch(actions.changeProjectAction(projectname)),
    onGetUseCaseDetails: (COMMON_API_URL) => dispatch(actions.GetUseCaseDetails(COMMON_API_URL)),
    onGetUseCaseDetailsByName: (COMMON_API_URL,data) => dispatch(actions.GetUseCaseDetailsByName(COMMON_API_URL,data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UseCase);