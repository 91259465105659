import React, { memo } from 'react';
import NodeBox from './NodeBox';
import { Handle } from 'react-flow-renderer';

export default memo(({ data, isConnectable }) => {
    
  return (
    <div className='node-box'>
      <Handle
        type="target"
        position="top"
        style={{ background: '#555' }}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />
      <NodeBox type='Text Prompt' data={data}></NodeBox>
      {/* <Handle //to be uncommented once backend is ready
          type="source"
          position="bottom"
          id="true"
          style={{ left: 224/3, background: '#555' }}
          isConnectable={isConnectable}
      />
      <Handle
        type="source"
        position="bottom"
        id="false"
        style={{ left: 224/3 * 2, background: '#555' }}
        isConnectable={isConnectable}
      /> */}
      <Handle
        type="source"
        position="bottom"
        style={{ background: '#555' }}
        isConnectable={isConnectable}
      />
      
    </div>
  );
});