import * as actionTypes from './actionTypes';
import { PostCalltoServer } from '../utility';
import {SetErrorMessage, SetSuccessMessage} from './message'

export const sctScheduleESdataFailure=(error)=> { 
    return { type: actionTypes.SCT_SAVE_ES_SCHEDULE_DATA_FAILURE, error 
	}; 
};
export const sctScheduleESdataSuccess=(schedule)=> { 
    return { type: actionTypes.SCT_SAVE_ES_SCHEDULE_DATA_SUCCESS, schedule
     };
}; 

export const triggerStart = () => {
    return {
        type: actionTypes.TRIGGER_START
    };
};
export const triggerFailure=(error)=> { 
    return { type: actionTypes.TRIGGER_FAILURE, error 
    }; 
};
export const triggerSuccess=(intent)=> {
     return { type: actionTypes.TRIGGER_SUCCESS, intent }}

export const saveScheduleDataIntoES = (inputObject,apiURL) => {
    return dispatch => {
		let url = apiURL+`/saveToESByUnqId`;
		inputObject["collection"]="sct_schedule_collection"
		inputObject["documentId"]=inputObject["jsonData"]["requestType"]
        PostCalltoServer(url,inputObject)
        .then(response=>{
			
			console.log("===33==saida==="+JSON.stringify(response));            
			try{
				if(response.data.status.responseType == "success"){
					dispatch(sctScheduleESdataSuccess(response.data.data));
					dispatch(SetSuccessMessage('Self Ticketing System updated successfully.'));
				}       
				else{
					dispatch(sctScheduleESdataFailure(response.data.errors))
					dispatch(SetErrorMessage('Error in update Self Ticketing System !!'));
				}
			}catch(err)	{
				dispatch(SetErrorMessage('Error in update Self Ticketing System !!!'));
			}		
		});
	};
};

export const scheduleBot = (scheduleBotObj,apiURL) => {
    return dispatch => {
		dispatch(triggerStart());
		let scheduleBotObjs={...scheduleBotObj,"orgName":localStorage.getItem("orgName")}
        let url = apiURL+`/updateTriggerType`; 
        PostCalltoServer(url,scheduleBotObjs).then(response=>{
			if( response.status == 'Failure'){
				dispatch(triggerFailure(response.errors))
			} else{				 
				dispatch(triggerSuccess(response.data));
				dispatch(SetSuccessMessage('selfContained ticket updated successfully.'));
			} 
			
			try{
				if(response.data.status.responseType == "success"){
					dispatch(sctScheduleESdataSuccess(response.data.data));
					dispatch(SetSuccessMessage('Self Ticketing System updated successfully.'));
				}       
				else{
					dispatch(sctScheduleESdataFailure(response.data.errors))
					dispatch(SetErrorMessage('Error in update Self Ticketing System !!'));
				}
			}catch(err)	{
				dispatch(SetErrorMessage('Error in update Self Ticketing System !!!'));
			}
			
		}).catch(err => {
			dispatch(triggerFailure(err))
       });     
    };
};

export const fetchSeltTicketsData = (configObj,apiURL) => {
    return dispatch => {
		let url = apiURL+`/fetchDataFromESbyId`;		
        PostCalltoServer(url,configObj)
        .then(response=>{			
			console.log("===33==saida==="+JSON.stringify(response));            
			try{
				if(response.data.status.responseType == "success"){
					dispatch(sctScheduleESdataSuccess(response.data.data));
					dispatch(SetSuccessMessage('fetch Self Ticketing System successfully.'));
				}       
				else{
					dispatch(sctScheduleESdataFailure(response.data.errors))
					dispatch(SetErrorMessage('Error in fetch Self Ticketing System !!'));
				}
			}catch(err)	{
				dispatch(SetErrorMessage('Error in fetch Self Ticketing System !!!'));
			}		
		});
	};
};
