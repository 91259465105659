import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import './va-container.css';
import * as actions from "../../../../store/actions/index";
import withErrorHandler from "../../../../hoc/withErrorHandler/withErrorHandler";
import axios from "../../../../instance";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import permissions from "../../../../permissions_Json";
import SpinnerPage from "../../../../components/UI/Loader/Loader";
import ButtonComponent from "../../../../components/UIComponents/Button/ButtonComponent";
import AddVirtualAssistantDrawer from "../../../../components/UI/Drawer/AddVirualAssistantDrawer";
import SearchComponent from "../../../../components/UIComponents/SearchComponent/Search";
const useStyles = makeStyles((theme) => ({

    card: {
        maxWidth: "100%",
        margin: "10px 10px",
        cursor: "pointer",
    },
    paraBox: {
        minHeight: "80px",
    },
}));

function VirtualAssistantContainer(props) {
    useEffect(() => {
        props.OnGetAllAssistantsData(props.config.BOTBUILDER_URL)
    }, [])    
    let access = false;
    let obj = props.permissions.find(data => {
        if (data.permission_code.includes(permissions.permissionCode.CONVERSATIONAL_AI_STANDARD_R
            || permissions.permissionCode.CONVERSATIONAL_AI_STANDARD_RW
            || permissions.permissionCode.COGNITIVE_SEARCH_KU_RW))
            return true
    })
    access = obj ? true : false;

    const classes = useStyles();
    let ProjectNameLoop;


    const [addDrawerShow, setAddDrawerShow] = useState(false);
    const [Searchvalue, setSearchValue] = useState("");
    const [show, setShow] = useState(true);
    const [virtualAssistantList,setVirtualAssistantList] = useState([]);
    const IsprojectExists = (virtualName) => {
        let VirtualAssistant = []
        virtualAssistantList && virtualAssistantList.map((data)=>{
            return VirtualAssistant.push(data.virtualassistantName);
        })
        let assistantList = VirtualAssistant;
        if(assistantList !== undefined && assistantList.length > 0){
            var assistantExist = assistantList.findIndex((element)=>{
                return element.toLowerCase() === virtualName.toLowerCase()
            })
            if (assistantExist > -1) {
                return true;
            }else return false;
        }else return false;    
    };

    const openAddDrawer = () => {
        setAddDrawerShow(true);
    }

    const handleClose = () => {
        setAddDrawerShow(false);
    };
    const onQuickFilterProjects = (event) => {
        setSearchValue(event.target.value);
      }
      const clearSearchValue = () => {
        setSearchValue('');
        setShow(true);
      }
      useEffect(() => {   
        if (props.AssistantsList !== null && props.AssistantsList !== undefined && show == true) {
          let newProjectList = [];
          props.AssistantsList && props.AssistantsList.map((item) => newProjectList.push(item));
          setVirtualAssistantList(newProjectList);
        }
        if (Searchvalue.length > 0 && Searchvalue !== "") {
          setShow(false);
          let output = props.AssistantsList && props.AssistantsList.filter((item) => {
            return (
              item.virtualassistantName.toLowerCase().includes(Searchvalue.toLowerCase())
            )
          })
          setVirtualAssistantList(output);
        } else {
          setShow(true)
        }
      }, [props.AssistantsList, Searchvalue, show]);

    const ProjectCardClick = (projectname) => {
            const queryParams = [];
            queryParams.push("va-name=" + projectname);
            const queryString = queryParams.join("&");
            props.history.push({
                pathname: "/virtual-assistant",
                search: "?" + queryString,
            });
    };


    let loadingImg = null;
    if (props.Assistantsloading) {
        loadingImg = <SpinnerPage />;
    } else {
        loadingImg = null;
    }
    function SortArray(x, y) {
        var nameA = x.virtualassistantName.toUpperCase();
        var nameB = y.virtualassistantName.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      }
    let alphabeticalOrder = virtualAssistantList && virtualAssistantList.sort(SortArray)
    if (true) {
        ProjectNameLoop =
        alphabeticalOrder && alphabeticalOrder.map((data, i) => {
                return (
                    <>
                        <Grid item sm={6} md={4} key={i}>
                            <Card
                                p={1}
                                className="customCard va-card"
                                key={i}
                                onClick={() => ProjectCardClick(data.virtualassistantName)}
                            >
                                <CardContent>
                                    <span className="ProjectTitle">{data.virtualassistantName}</span>
                                    <div className="d-flex justify-content-between pb-3">
                                        <span className="author-text">Created by {data.createdUser&&data.createdUser.split('@')[0]} </span>
                                        <span className="author-text">Last Modified {new Date(data.modifiedDate ? data.modifiedDate : data.startDate).toDateString()}</span>
                                    </div>
                                    <Box pb={1} className={classes.paraBox}>
                                        <span className="cardParagraph">
                                            {data.virutalassistantDescription}
                                        </span>
                                    </Box>
                                    <Box className={classes.DocBox}>
                                        <span className="CardFooter">
                                            {data.project.length}{data.project.length == 1 ? ' Project' : ' Projects'}
                                        </span>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </>
                );
            });
    }
    return (
        <>
            <Grid className="va-container">
                {loadingImg}
                <div className='d-flex justify-content-between p-4 header'>
                    <div>
                        <h3 className="ProjectsDropDown pb-2">Virtual Assistant</h3>
                        <span className='para-graph mainParagraph'>
                            Provision to create and configure virtual assistants for various channels.
                        </span>
                    </div>
                    <SearchComponent
                        class={"input-group homeSearch VASearchbox"}
                        id="example-search-input"
                        value={Searchvalue}
                        show={show}
                        onSearch={(event) => onQuickFilterProjects(event)}
                        Clear={clearSearchValue}
                    />
                    <ButtonComponent
                        label='NEW VIRTUAL ASSISTANT'
                        variant='contained'
                        clicked={() => { openAddDrawer() }}
                        className='buttonWidth add-lang-button mr-2'
                        disabled={false}
                    />
                </div>
                <Grid container lg={12} className="va-cards-wrapper">
                    {props.AssistantsList && props.AssistantsList.length > 0 ? '' : <>{!loadingImg && <div className='w-100 no-data-container'>
                        <div className="d-flex justify-content-center">
                            <div className={'no-data-text'}>Virtual Assistants are not added </div>
                        </div>
                        <div className="d-flex justify-content-center">
                            <div className={'no-data-add-faq'} onClick={() => openAddDrawer()}>Add Virtual Assistant </div>
                        </div>

                    </div>}</>}
                    {ProjectNameLoop}
                </Grid>
            </Grid>
            <AddVirtualAssistantDrawer
                show={addDrawerShow}
                onHide={handleClose}
                config={props.config}
                isThisExists={IsprojectExists}
                history={props.history}
            />
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        permissions: state.user.permissionList,
        AssistantsList: state.projectview.AllAssistantsList,
        Assistantsloading: state.projectview.Assistantsloading,
        createProjectFlag: state.user.createProjectFlag,
        uploadProjectFlag: state.user.uploadProjectFlag,
        englishProject: state.user.englishProject,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        OnGetAllAssistantsData: (APIURL) =>
            dispatch(actions.GetAllAssistantsData(APIURL)),
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withErrorHandler(VirtualAssistantContainer, axios));
