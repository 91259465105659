import React from 'react';
import { Modal } from 'react-bootstrap';
import CloseIcon from "@material-ui/icons/Close";
import './ToastMessage.css';

const ToastMessage = (props) => {

  return (
    <>
       <Modal size='lg'  show={props.showingAlert} className='toastModal' key={props.key}>  
        <Modal.Body className={props.customClass}>
              <p><span className="toastClose">
                <CloseIcon
                     onClick={props.closeModal}
                     style={{ fontSize: 25 }}>
                </CloseIcon>
                  </span>
                  <span className="toastContent">{props.message}</span></p>
       </Modal.Body>
       </Modal>
    </>

  );


}

export default ToastMessage;