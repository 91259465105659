// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pageTitleText3 {
    font-family: "Roboto-Black" !important;
    font-size: 18px!important;
    Color: #000 !important;
    line-height: 60px;
    /* margin-top: 1%; */
    margin-left: -40px;
    padding:60px;
    display: inline-block;
  
  }
  .ingest-data-card {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 0px;
    padding: 20px;
    margin: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    height: 180px;
    width: 380px;
  }
.ingest-data-card h2 {
  font-size: 12px;
}
.inner-box {
  background-color: white;
  border: 0.9px solid black;
 padding:9px;
  width:100px;
  height:25px;
  border-radius: 5px;
  display: flex;
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  text-align: center;

}
.ticketing-text {
  font-size: 10px;
  white-space: nowrap;
  padding:12px;
  font-weight: bold; 
}
.ticketing-para{
  font-size: 10px;
  white-space: nowrap;
  padding:5px;
  font-weight: bold; 
}



`, "",{"version":3,"sources":["webpack://./src/containers/SettingPage/SettingInnerPages/UnsupervisedML/IngestPredictionData.css"],"names":[],"mappings":"AAAA;IACI,sCAAsC;IACtC,yBAAyB;IACzB,sBAAsB;IACtB,iBAAiB;IACjB,oBAAoB;IACpB,kBAAkB;IAClB,YAAY;IACZ,qBAAqB;;EAEvB;EACA;IACE,uBAAuB;IACvB,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,YAAY;IACZ,wCAAwC;IACxC,aAAa;IACb,YAAY;EACd;AACF;EACE,eAAe;AACjB;AACA;EACE,uBAAuB;EACvB,yBAAyB;CAC1B,WAAW;EACV,WAAW;EACX,WAAW;EACX,kBAAkB;EAClB,aAAa;EACb,mBAAmB,EAAE,sBAAsB;EAC3C,uBAAuB,EAAE,wBAAwB;EACjD,kBAAkB;;AAEpB;AACA;EACE,eAAe;EACf,mBAAmB;EACnB,YAAY;EACZ,iBAAiB;AACnB;AACA;EACE,eAAe;EACf,mBAAmB;EACnB,WAAW;EACX,iBAAiB;AACnB","sourcesContent":[".pageTitleText3 {\n    font-family: \"Roboto-Black\" !important;\n    font-size: 18px!important;\n    Color: #000 !important;\n    line-height: 60px;\n    /* margin-top: 1%; */\n    margin-left: -40px;\n    padding:60px;\n    display: inline-block;\n  \n  }\n  .ingest-data-card {\n    background-color: white;\n    border: 1px solid #ddd;\n    border-radius: 0px;\n    padding: 20px;\n    margin: 20px;\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n    height: 180px;\n    width: 380px;\n  }\n.ingest-data-card h2 {\n  font-size: 12px;\n}\n.inner-box {\n  background-color: white;\n  border: 0.9px solid black;\n padding:9px;\n  width:100px;\n  height:25px;\n  border-radius: 5px;\n  display: flex;\n  align-items: center; /* Center vertically */\n  justify-content: center; /* Center horizontally */\n  text-align: center;\n\n}\n.ticketing-text {\n  font-size: 10px;\n  white-space: nowrap;\n  padding:12px;\n  font-weight: bold; \n}\n.ticketing-para{\n  font-size: 10px;\n  white-space: nowrap;\n  padding:5px;\n  font-weight: bold; \n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
