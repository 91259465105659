import React, { useState } from 'react';
import  classes from './ProjectPageHeader.module.css';
import { Col,Navbar,Nav } from 'react-bootstrap';
import DropdownCustom from '../../UIComponents/Dropdowns/DropdownComponent';
import DropdownButton from 'react-bootstrap/DropdownButton';
import '../../UIComponents/Dropdowns/DropdownComponent.css';
import { Tooltip, withStyles } from '@material-ui/core';
import AddProjectDescriptionDrawer from '../../UI/Drawer/AddProjectDescriptionDrawer';
import permissions from "../../../permissions_Json";

const ProjectHeaderPage=(props)=>{
  const [show,setShow]=useState(false)
  const[projectName,setProjectName]=useState('')

  let projectManagement_R = false
  let projectManagement_RW = false

  let project_R_Status = props.permissions.find(data => {
    if (data.permission_code.includes(permissions.permissionCode.PROJECTM_R) && data.sku_code.includes(permissions.skuCode.STANDARD))
      return true
  })
  projectManagement_R = project_R_Status ? true : false;

  let project_RW_Status = props.permissions.find(data => {
    if (data.permission_code.includes(permissions.permissionCode.PROJECTM_RW) && data.sku_code.includes(permissions.skuCode.STANDARD))
      return true
  })
  projectManagement_RW = project_RW_Status ? true : false;


  const selectedNode = (nodeName) => {
    if (nodeName == "NEW FLOW" && !props.isCuxOrg) {
      props.ShowFlowModal();
    } else if (nodeName == "NEW INTENT") {
      props.ShowFlowModal();
    }else if ((nodeName == "IMPORT FLOW" && !props.isCuxOrg) || nodeName == "IMPORT INTENT") {
      props.setImportFlowModal();
    }else if(nodeName == "FAQs"){
      props.openFaqDocumentDrawer();
    }else if(nodeName == "DOCUMENTs"){
      props.openOtherDocumentDrawer();
    } else if(nodeName == "NEW FLOW" && props.isCuxOrg){
      props.showBotModal();
    } else if(nodeName == "IMPORT FLOW" && props.isCuxOrg){
      props.setImportCuxBotModal();
    } else if(nodeName == "SYNONYMS & ACRONYMS"){
      props.openSynonymsDrawer();
    }
  }

  const selectedSettingNode = (nodeName) => {
    if (nodeName == "Init Chatbot") {
      props.initChatBotModal();
    } else if (nodeName == "Export") {
      props.exportProject();
    }else if(nodeName == "Delete"){
      props.deleteProjectModel();
    }
    else if(nodeName == "Translate"){
      props.showTranslateModel();
    }
  }
 
  let settingNodes=[]
  let addNodes=[]
  if(localStorage.getItem('language') !=='en'){
    if(localStorage.getItem('projectname')=="common"){
      settingNodes=[{name:'Init Chatbot','disabled':false}]
      if(projectManagement_R){
        settingNodes.push({name:'Export','disabled':projectManagement_RW ? false: true})
      }
    }
    else{
    settingNodes=[];
    if(props.permissionsAIStandard.RW){
      settingNodes.push({name:'Init Chatbot','disabled':props.permissionsAIStandard.RW ? false : true})
    }
    settingNodes.push({name:'Translate','disabled':true})
    settingNodes.push({name:'Export','disabled':projectManagement_RW ? false: true})
    if(projectManagement_RW){
      settingNodes.push({name:'Delete','disabled':projectManagement_RW ? false : true})
    }
    }
  }
  else{
    if(localStorage.getItem('projectname')=="common"){
      settingNodes=[];
      if(props.permissionsAIStandard.RW){
        settingNodes.push({name:'Init Chatbot','disabled':props.permissionsAIStandard.RW ? false : true})
      }
      if(projectManagement_R){
        settingNodes.push({name:'Export','disabled':projectManagement_RW ? false: true})
      }
    }
    else{
    settingNodes=[];
    if(props.permissionsAIStandard.RW){
      settingNodes.push({name:'Init Chatbot','disabled':props.permissionsAIStandard.RW ? false : true})
    }
    if(props.permissionsAIAdmin.RW){
      settingNodes.push({name:'Translate','disabled':props.permissionsAIAdmin.RW ? false : true})
    }
    if(projectManagement_R){
      settingNodes.push({name:'Export','disabled':projectManagement_RW ? false: true})
      settingNodes.push({name:'Delete','disabled':projectManagement_RW ? false : true})
    }
    }
  }

if(localStorage.getItem('language') !=='en'){
    addNodes=[{name:props.isCuxOrg?'NEW INTENT':'NEW FLOW','disabled':false},
    {name:props.isCuxOrg?'IMPORT INTENT':'IMPORT FLOW','disabled':false}];
    if(props.permissionsAIStandard.RW){
      addNodes.push({name:'FAQs','disabled':props.permissionsAIStandard.RW ? false : true})
    }
    if(props.otherDocsPermission.WR){
      addNodes.push({name:'DOCUMENTs','disabled':props.otherDocsPermission.WR ? false : true})
    }
    if(props.isCuxOrg) {
      addNodes.push({name:'NEW FLOW','disabled':false},{name:'IMPORT FLOW','disabled':false});
    }
}
else{
  addNodes=[];
  if(props.permissionsAIStandard.RW){
    // addNodes.push({name:props.isCuxOrg?'NEW INTENT':'NEW FLOW','disabled':props.permissionsAIStandard.RW ? false : true});
    addNodes.push({name:props.isCuxOrg?'NEW INTENT':'NEW FLOW','disabled':false});
    // addNodes.push({name:props.isCuxOrg?'IMPORT INTENT':'IMPORT FLOW','disabled':props.permissionsAIStandard.RW ? false : true});
    addNodes.push({name:props.isCuxOrg?'IMPORT INTENT':'IMPORT FLOW','disabled':false});
    addNodes.push({name:'FAQs','disabled':props.permissionsAIStandard.RW ? false : true});
    if(props.isCuxOrg) {
      // addNodes.push({name:'NEW FLOW','disabled':props.permissionsAIStandard.RW ? false : true},
      addNodes.push({name:'NEW FLOW','disabled':false},
      //{name:'IMPORT FLOW','disabled':props.permissionsAIStandard.RW ? false : true});
      {name:'IMPORT FLOW','disabled': false});
    }
  }
  if(props.otherDocsPermission.WR){
    addNodes.push({name:'DOCUMENTs','disabled':props.otherDocsPermission.WR ? false : true})
  }
}

addNodes.push({name:'SYNONYMS & ACRONYMS','disabled':(props.permissionsAIStandard.RW || props.otherDocsPermission.WR) ? false : true});
const showDescription=()=>{
  if(localStorage.getItem("projectname")=="common"){
  setShow(false)
  }
  else{
    setShow(true)
    setProjectName(localStorage.getItem('projectname'))
  }
}
const handleClose=(description)=>{
  if(description=="adddescription"){
  setShow(false)
  }
}
return(
  props.showProjectHeader == true ?
 <Navbar collapseOnSelect expand="lg" className={["col-lg-12",classes.homeHeaderColor].join(' ')}>
  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
   <Navbar.Collapse id="responsive-navbar-nav">
     <Nav className={"col-md-9"}>               
      <div className={classes.MainContent}>
          <div className={classes.paraGraphText}>
              <Col style={{maxWidth:'60%'}}>
                 <p className={classes.paraContent} >{props.getprojDesp!==null&&props.getprojDesp!==undefined?props.getprojDesp.description:''}
                 <span onClick={showDescription}>
                  {localStorage.getItem("projectname") != "common" ?
                    props.permissionsProjectM.RW ? <img src="./Icons/blueicon.png" className={classes.editIcon} /> : null
                    : null}
                  </span>
                 </p>
               </Col>
            </div>
            <div className={classes.projectButtons}>
          
             <Col md={3} sm={6} className={classes.buttonRows}>
             
            {settingNodes.length > 0 && <DropdownCustom label='Actions'  customClass="dropdownCustomClass"
            variant='secondary' dropdownItem={settingNodes}
             selectedItem={selectedSettingNode}/>}
             <DropdownCustom label='Add'  customClass="dropdownClass"
            variant='primary' dropdownItem={addNodes}
             selectedItem={selectedNode}/>
           </Col>

           <Col md={2} className={props.availableLangList.length > 1 ? classes.availableLanguage: classes.langList}>
             <div className={classes.languageDiv}>
                <LightTooltip
                  arrow
                  title="Change the default language settings to view and edit the current project in any of the listed languages"
                  placement="top-start"
                >
                  <img src="./images/info_icon.png"/>
                </LightTooltip>

              {props.availableLangList.length > 1 ?
              <>
              <span className={classes.spanContent}>
                Available in</span>
                <DropdownButton className="langDropdown" title={props.currentLangauge}>
                {props.availableLangList.map(item=>{
                  if(item==props.currentLangauge)
                  return(
                    <>
                    <li className='langMenu'>{item} <span className='langDefault'>Default</span></li>
                     </>
                )
                else
                  return(
                    <li>{item}</li>
                  )
                })}
              </DropdownButton>
              </>:<> <span className={classes.spanContent}>
                Available in</span> <span className={classes.languageContent}>{props.currentLangauge}</span></>}
              </div>
                </Col>
           </div>
      </div>
      <AddProjectDescriptionDrawer
      show={show}
      onHide={handleClose}
      config={props.config}
      projectName={projectName}
      getprojDesp={props.getprojDesp}
      />
    </Nav>
        </Navbar.Collapse>
    </Navbar>
    :''  
    )
};

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 12,
    fontFamily: "Roboto-Regular",
  },
  arrow: {
    color: theme.palette.common.white,
    "&:before": {
      border: "1px solid #E6E8ED",
      boxShadow: theme.shadows[1],
    },
  },
}))(Tooltip);

export default ProjectHeaderPage;
