import get from 'lodash/get';
import uniq from 'lodash/uniq';
import compact from 'lodash/compact';

const similarIssuesChangeHandlerReducer = (state, payload) => {
    if (payload.isOptionField) {
        const fieldToSearch = [
            "title",
            "problemDescription"
        ];
        const optionalField1 = payload.type === "similarIssuesOptionalField1" ? payload.value :get(state, 'similarIssuesOptionalField1', '');
        const optionalField2 = payload.type === "similarIssuesOptionalField2" ? payload.value :get(state, 'similarIssuesOptionalField2', '');
        const optionalFieldsArr = [optionalField1, optionalField2];
        const updatedFieldTosearch = {
            fieldToSerach: uniq(compact(fieldToSearch.concat(optionalFieldsArr)))
        };
        return {
            ...state,
            similartickets: {
                ...state.similartickets,
                ...updatedFieldTosearch
            }
        }
    }
    return {
        ...state,
        similartickets: {
            ...state.similartickets,
            ...payload
        }
    }
}

export default similarIssuesChangeHandlerReducer;