import get from 'lodash/get';

const similarIssuesOptinalFieldChangeHandlerReducer = (state, payload) => {
if(payload.isDefault) {
    const fieldToSearch = get(payload, 'data.similartickets.fieldToSerach', []);
    const similarIssuesOptionalFields = {
        similarIssuesOptionalField1: get(fieldToSearch, '2', ''),
        similarIssuesOptionalField2: get(fieldToSearch, '3', '')
    }; 
    return {
        ...state,
        ...similarIssuesOptionalFields
    }
}
    return {
        ...state,
        ...payload
    }
}

export default similarIssuesOptinalFieldChangeHandlerReducer;