const knowledgeArticlesChangeHandlerReducer = (state, payload) => {
    return {
        ...state,
        knowledgeArticles: {
            ...state.knowledgeArticles,
            ...payload
        }
    }
}

export default knowledgeArticlesChangeHandlerReducer;