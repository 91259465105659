import * as actionTypes from './actionTypes';
import get from 'lodash/get';
import { GetCalltoServer, PostCalltoServer, botPostCalltoServer, UpdateCalltoServer, deleteCalltoServer } from '../utility';
import { SetErrorMessage, SetSuccessMessage, setSucMessage,SetWarningMessage } from './message'
import { flowList } from './home'
import axios from '../../instance';
import * as XLSX from 'xlsx'
import { decrypt, encryptAndStore } from '../../utility/utility';
import   DOMPurify from 'dompurify'

const orgName = localStorage.getItem("orgName");
const username = localStorage.getItem("userName");
let translateIntervalMain;
export const actionStart = () => { //for generic use
    return {
        type: actionTypes.ACTION_START
    }
}
export const actionSuccess = () => { //for generic use
    return {
        type: actionTypes.ACTION_SUCCESS
    }
}
export const actionFailure = (error) => { //for generic use
    return {
        type: actionTypes.ACTION_FAILURE, error
    };
};
export const triggerStart = () => {
    return {
        type: actionTypes.TRIGGER_START
    };
};
export const triggerFailure = (error) => {
    return {
        type: actionTypes.TRIGGER_FAILURE, error
    };
};
export const triggerSuccess = (intent) => {
    return { type: actionTypes.TRIGGER_SUCCESS, intent }
}

export const triggerStartDoc = () => {
    return {
        type: actionTypes.TRIGGER_START_DOC
    };
};
export const triggerFailureDoc = (error) => {
    return {
        type: actionTypes.TRIGGER_FAILURE_DOC, error
    };
};
export const triggerSuccessDoc = (intent) => {
    return { type: actionTypes.TRIGGER_SUCCESS_DOC, intent }
}

export const updateTrigger = (intents, apiURL, isTriggerSuccess) => {
    return dispatch => {
        dispatch(triggerStart());
        let intent = { ...intents, "username": localStorage.getItem('userName'), "orgName": localStorage.getItem("orgName") }
        let url = apiURL + `/project/flow/triggerType`;
        botPostCalltoServer(url, intent).then(response => {
            if (response.data.status.responseType == "success") {
                dispatch(triggerSuccess(response.data.data));
                if (isTriggerSuccess) {
                    dispatch(SetSuccessMessage('TriggerType updated successfully.'));
                }
            }
            else {
                dispatch(triggerFailure(response.status))
            }
        }).catch(err => {
            dispatch(triggerFailure(err))
        });
    };
};


export const KnowledgeGridExpandCollapse = (expand) => {
    return { type: actionTypes.KNOWLEDGEGRIDEXPANDCOLLAPSE, expand }
}
export const IntentStart = () => {
    return {
        type: actionTypes.INTENT_START
    };
};
export const IntentFailure = (error) => {
    return {
        type: actionTypes.INTENT_FAILURE, error
    };
};
export const IntentSuccess = (pattern) => {
    return { type: actionTypes.INTENT_SUCCESS, pattern }
}

export const createIntent = (patterns, apiURL, fromImportCluster) => {
    return dispatch => {
        dispatch(IntentStart());
        let pattern = { ...patterns, "orgName": localStorage.getItem("orgName") }
        let url = apiURL + `/project/flow/intent`
        botPostCalltoServer(url, pattern).then(response => {
            if (response.data.status.responseType == "success") {
                dispatch(IntentSuccess(response.data.data));
                if (!fromImportCluster){
                    if(localStorage.getItem('language')){
                    setTimeout(() => {
                        dispatch(SetSuccessMessage('Intent saved successfully.'));
                    }, 1000);
                }
                // else{
                //     setTimeout(() => {
                //         dispatch(SetSuccessMessage('Intent saved successfully.'));
                //     }, 1000);
                // }
                }                  
            }
            else {
                dispatch(IntentFailure(response.status))
            }
        }).catch(err => {
            dispatch(IntentFailure(err))
        });
    };
};



export const deleteIntent = (patterns, apiURL, fromImportCluster) => {
    return dispatch => {
        dispatch(IntentStart());
        let pattern = { ...patterns, "orgName": localStorage.getItem("orgName") }
        let url = apiURL + `/project/flow/intent`
        botPostCalltoServer(url, pattern).then(response => {
            if (response.data.status.responseType == "success") {
                dispatch(IntentSuccess(response.data.data));
                if (!fromImportCluster)
                    setTimeout(() => {
                        dispatch(SetSuccessMessage(' Data Deleted successfully'));
                    }, 1000);
            }
            else {
                dispatch(IntentFailure(response.status))
            }
        }).catch(err => {
            dispatch(IntentFailure(err))
        });
    };
};

export const createCatIntent = (patterns, apiURL, fromImportCluster) => {
    return dispatch => {
        dispatch(IntentStart());
        let pattern = { ...patterns, "orgName": localStorage.getItem("orgName") }
        let url = apiURL + `/category/flow/intent`
        botPostCalltoServer(url, pattern).then(response => {
            if (response.data.status.responseType == "success") {
                dispatch(IntentSuccess(response.data.data));
                if (!fromImportCluster)
                    setTimeout(() => {
                        dispatch(SetSuccessMessage('Intent saved successfully.'));
                    }, 1000);
            }
            else {
                dispatch(IntentFailure(response.status))
            }
        }).catch(err => {
            dispatch(IntentFailure(err))
        });
    };
}

/* Action for Chat page delete*/
export const deleteChatIntent = (patterns, apiURL, fromImportCluster) => {
    return (dispatch) => {
        dispatch(IntentStart());
        let pattern = { ...patterns, orgName: localStorage.getItem("orgName") };
        let url = apiURL + `/createUpdateDeleteIntentFile`;
        PostCalltoServer(url, pattern)
            .then((response) => {
                if (response.data.status.responseType == "success") {
                    dispatch(IntentSuccess(response.data.data));
                    if (!fromImportCluster)
                        dispatch(SetSuccessMessage("Chat Intent deleted successfully"));
                } else {
                    dispatch(IntentFailure(response.status));
                }
            })
            .catch((err) => {
                dispatch(IntentFailure(err));
            });
    };
};
export const KnowledgeIntentStart = () => {
    return {
        type: actionTypes.KNOWLEDGE_INTENT_START
    };
};
export const KnowledgeIntentFailure = (error) => {
    return {
        type: actionTypes.KNOWLEDGE_INTENT_FAILURE, error
    };
};
export const KnowledgeIntentSuccess = (pattern) => {
    return { type: actionTypes.KNOWLEDGE_INTENT_SUCCESS, pattern }
}

export const saveSynonymWord = (reqData, apiURL) => {
    return dispatch => {
        let synonymData = { ...reqData, "orgName": localStorage.getItem("orgName") }
        dispatch(KnowledgeIntentStart());
        let api =  `/cognitive/synonyms`;
        let url = apiURL + api;
        botPostCalltoServer(url, synonymData).then(response => {
            if (response.data.status.responseType == "success") {
                dispatch(KnowledgeIntentSuccess(response.data.data));
                    setTimeout(()=>{
                        dispatch(SetSuccessMessage('Synonym added successfully.'));
                    },2000)
                let projectName = localStorage.getItem("projectname");
                let language = localStorage.getItem("language");
                if (!language) {
                    language = "en";
                }
                var requestObject = { "projectName": projectName, "language": language };
                dispatch(getSynonymsData(requestObject, apiURL))
            }
            else {
                // dispatch(KnowledgeIntentFailure(response.status))
                dispatch(SetErrorMessage(response.data.status.responseType));
            }
        }).catch(err => {
            // dispatch(KnowledgeIntentFailure(err))
        });
    };
};

export const SaveKnowledgeIntent = (knowledgeData, apiURL, IsCreate) => {
    return dispatch => {
        let knowledgeDatas = { ...knowledgeData, "orgName": localStorage.getItem("orgName") }
        dispatch(KnowledgeIntentStart());
        let api = IsCreate ? `/smartfaq/qna` : `/updateDoc`;
        let url = apiURL + api;
        botPostCalltoServer(url, knowledgeDatas).then(response => {
            if (response.data.status.responseType == "success") {
                dispatch(KnowledgeIntentSuccess(response.data.data));
                if(localStorage.getItem('language') == 'en'){
                    setTimeout(()=>{
                        dispatch(SetSuccessMessage('FAQ added successfully.'));
                    },2000)
                }
                else{
                    dispatch(SetSuccessMessage('FAQ added successfully.'));
                }
                let projectName = localStorage.getItem("projectname");
                let language = localStorage.getItem("language");
                if (!language) {
                    language = "en";
                }
                var requestObject = { "projectName": projectName, "language": language };
                dispatch(GetKnowledgeIntent(requestObject, apiURL))
            }
            else {
                dispatch(KnowledgeIntentFailure(response.status))
                dispatch(SetErrorMessage(response.data.status.responseType));
            }
        }).catch(err => {
            dispatch(KnowledgeIntentFailure(err))
        });
    };
};

export const UpdateAllKnowledgeIntentStart = () => {
    return {
        type: actionTypes.UPDATE_KNOWLEDGE_INTENT_START
    };
};
export const UpdateAllKnowledgeIntentFailure = (error) => {
    return {
        type: actionTypes.UPDATE_KNOWLEDGE_INTENT_FAILURE, error
    };
};
export const UpdateAllKnowledgeIntentSuccess = (updateKnowledgeIntent) => {
    return { type: actionTypes.UPDATE_KNOWLEDGE_INTENT_SUCCESS, updateKnowledgeIntent }
}

export const UpdateAllKnowledgeIntent = (knowledgeData, apiURL) => {
    return dispatch => {
        dispatch(UpdateAllKnowledgeIntentStart());
        let api = `/bulkUpdateDoc`;
        let url = apiURL + '/review'+api;
        PostCalltoServer(url, knowledgeData).then(response => {
            if (response.data.status.responseType == "success") {
                dispatch(UpdateAllKnowledgeIntentSuccess(response.data.data));
                if(localStorage.getItem('language')){
                dispatch(SetSuccessMessage('FAQ edited successfully.'));
                }
            }
            else {
                dispatch(UpdateAllKnowledgeIntentFailure(response.status))
                dispatch(SetErrorMessage(response.data.status.responseType));
            }
        }).catch(err => {
            dispatch(UpdateAllKnowledgeIntentFailure(err))
        });
    }
};
export const GetKnowledgeIntentStart = () => {
    return {
        type: actionTypes.GET_KNOWLEDGE_INTENT_START
    };
};
export const GetKnowledgeIntentFailure = (error) => {
    return {
        type: actionTypes.GET_KNOWLEDGE_INTENT_FAILURE, error
    };
};
export const GetKnowledgeIntentSuccess = (pattern) => {
    return { type: actionTypes.GET_KNOWLEDGE_INTENT_SUCCESS, pattern }
}

export const getSynonymsStart = () => {
    return {
        type: actionTypes.GET_SYNONYMS_START
    };
};
export const getSynonymsFailure = (error) => {
    return {
        type: actionTypes.GET_SYNONYMS_FAILURE, error
    };
};
export const getSynonymsSuccess = (pattern) => {
    return { type: actionTypes.GET_SYNONYMS_SUCCESS, pattern }
}

export const getConnectionsDataStart = () => {
    return {
        type: actionTypes.GET_CONNECTIONS_DATA_START
    };
};
export const getConnectionsDataFailure = (error) => {
    return {
        type: actionTypes.GET_CONNECTIONS_DATA_FAILURE, error
    };
};
export const getConnectionsDataSuccess = (pattern) => {
    return { type: actionTypes.GET_CONNECTIONS_DATA_SUCCESS, pattern }
};

export const getSiteListStart = () => {
    return {
        type: actionTypes.GET_SITE_LIST_START
    };
};
export const getSiteListFailure = (error) => {
    return {
        type: actionTypes.GET_SITE_LIST_FAILURE, error
    };
};
export const getSiteListSuccess = (pattern) => {
    return { type: actionTypes.GET_SITE_LIST_SUCCESS, pattern }
};

export const getSharePointDataStart = () => {
    return {
        type: actionTypes.GET_SHARE_POINT_DATA_START
    };
};

export const selectSharePointFile = (payload) => {
    return {
        type: actionTypes.SELECT_SHARE_POINT_FILE,
        payload,
    }
};

export const resetSharePointData = (payload) => {
    return {
        type: actionTypes.RESET_SHARE_POINT_DATA,
        payload,
    }
};

export const updateSharePointBaseUrl = (payload) => {
    return {
        type: actionTypes.UPDATE_SHARE_POINT_BASE_URL,
        payload
    }
}

export const setrefresh = (payload) => {
    var temp
    if(payload == true)
        temp = false
    else
        temp = true

    return {
        type: actionTypes.SET_REERESH_OTHERDOC,
        payload
    }
}

export const getSharePointDataFailure = (error) => {
    return {
        type: actionTypes.GET_SHARE_POINT_DATA_FAILURE, error
    };
};
export const getSharePointDataSuccess = (pattern) => {
    return { type: actionTypes.GET_SHARE_POINT_DATA_SUCCESS, pattern }
};

export const fetchDataSourceIndexNamesSuccess = (pattern) => {
    return { type: actionTypes.FETCH_DATA_SOURCE_INDEX_NAMES_SUCCESS, pattern }
};

export const fetchDataSourceIndexNamesStart = (pattern) => {
    return { type: actionTypes.FETCH_DATA_SOURCE_INDEX_NAMES_START, pattern }
};

export const fetchDataSourceIndexNamesFailure = (pattern) => {
    return { type: actionTypes.FETCH_DATA_SOURCE_INDEX_NAMES_FAILURE, pattern }
};

export const addDataSourceIndexNames = (pattern) => {
    return { type: actionTypes.ADD_DATA_SOURCE_INDEX_NAMES, pattern }
};

export const updateTicketSystemData = (pattern) => {
    return { type: actionTypes.UPDATE_TICKET_SYSTEMS_DATA, pattern }
}

export const updateKnowledgeBaseData = (pattern) => {
    return { type: actionTypes.UPDATE_KNOWLEDGE_BASE_DATA, pattern }
}

export const GetKnowledgeIntent = (reqData, apiURL) => {
    return dispatch => {
        dispatch(GetKnowledgeIntentStart());
        let url = apiURL + `/smartfaq/qna/${reqData.projectName}/${reqData.language}`
        GetCalltoServer(url).then(response => {
            if (response.data.status.responseType == "success") {
                dispatch(GetKnowledgeIntentSuccess(response.data.data));
            }
            else {
                dispatch(GetKnowledgeIntentFailure(response.status))
            }
        }).catch(err => {
            dispatch(KnowledgeIntentFailure(err))
        });
    };
};

export const getSynonymsData = (reqData, apiURL) => {
    return dispatch => {
        dispatch(getSynonymsStart());
        let url = apiURL + `/cognitive/synonyms/${reqData.projectName}/${reqData.language}`
        GetCalltoServer(url).then(response => {
            if (response.data.status.responseType == "success") {
                dispatch(getSynonymsSuccess(response.data.data));
            }
            else {
                dispatch(getSynonymsFailure(response.status))
            }
        }).catch(err => {
           dispatch(getSynonymsFailure(err))
        });
    };
};

export const getConnectionsData = (config) => {
    return dispatch => {
        dispatch(getConnectionsDataStart());
        const configObj = {}
        let url = config.CONNECTOR_URL + `/datasources/${localStorage.getItem("orgName")}`;
        dispatch(getConnectionsDataStart());
        botPostCalltoServer(url, configObj)
            .then(response => {
                try {
                    if (response.data.status.responseType === "success") {
                        dispatch(getConnectionsDataSuccess(JSON.parse(response.data.data)));
                    }
                    else if (response.data.status.statusCode === '400') {
                        dispatch(getConnectionsDataFailure(get(response, 'status', '')));
                    }
                } catch (err) {
                    dispatch(getConnectionsDataFailure(get(response, 'status', '')));
                }
            });
    }
};

export const getSiteList = (config,data) => {
    return dispatch => {
        dispatch(getSiteListStart());
        let url = config.CONNECTOR_URL + `/sharepoint-sitelist`;
        dispatch(getSiteListStart());
        botPostCalltoServer(url, data)
            .then(response => {
                try {
                    if (response.data.status.responseType === "success") {
                        dispatch(getSiteListSuccess(JSON.parse(response.data.data)));
                    }
                    else if (response.data.status.statusCode === '400') {
                        dispatch(getSiteListFailure(get(response, 'status', '')));
                    }
                    else if(response.data.data == null){
                        dispatch(getSiteListFailure(get(response, 'status', '')));
                    }
                    else{
                        dispatch(getSiteListFailure(get(response, 'status', '')));
                    }
                } catch (err) {
                    dispatch(getSiteListFailure(get(response, 'status', '')));
                }
            });
    }
};

export const getSharePointData = (payload) => {
    return dispatch => {
        dispatch(getSharePointDataStart());
        const configObj = {
            "datasourceType": "knowledge_base",
            "dataSourceName": "sharepoint",           
            "projectName": "",
            "siteId": payload.siteId,
            "siteName": payload.siteName,
            "tenantId": localStorage.getItem("orgName"),
            "connector": "",
            "language": "",
            "url": payload.baseUrl,
            "faq": true
          };
        let url = payload.config.CONNECTOR_URL + `/get-all-files`;
        botPostCalltoServer(url, configObj)
            .then(response => {
                try {
                    if (response.data.status.responseType === "success") {
                        const resp = JSON.parse(response.data.data);
                        dispatch(getSharePointDataSuccess({ data: resp.data, baseUrl: payload.baseUrl }));
                    }
                    else {
                        dispatch(getSharePointDataFailure(response.status));
                        dispatch(SetErrorMessage(get(response, 'data.errors.0.source', '')));
                    }
                } catch (err) {
                    dispatch(getSharePointDataFailure(err.status));
                    dispatch(SetErrorMessage(get(err, 'response', '')));
                }
            });
    };
};

export const fetchDataSourceIndexNames = (payload) => {
    return dispatch => {
        dispatch(getConnectionsDataStart());
        const fetchIndexConfig = {}
        let url = payload.config.CONNECTOR_URL + `/datasource-indexnames/${localStorage.getItem("orgName")}`;
        dispatch(fetchDataSourceIndexNamesStart());
        botPostCalltoServer(url, fetchIndexConfig)
            .then(response => {
                try {
                    if (response.data.status.responseType === "success") {
                        dispatch(fetchDataSourceIndexNamesSuccess(JSON.parse(response.data.data)));
                    }
                    else {
                        dispatch(fetchDataSourceIndexNamesFailure(response.data.status.statusCode));
                    }
                } catch (err) {
                    dispatch(fetchDataSourceIndexNamesFailure(err.status));
                }
            });
    };
};

export const SuccessImportCluster = (msg) => {
    return dispatch => {
        dispatch(setSucMessage(msg));
    }
}
export const GetClusterDataStart = () => {
    return {
        type: actionTypes.GET_CLUSTER_DATA_START
    };
};
export const GetClusterDataFailure = (error) => {
    return {
        type: actionTypes.GET_CLUSTER_DATA_FAILURE, error
    };
};
export const GetClusterDataSuccess = (cluster) => {
    return { type: actionTypes.GET_CLUSTER_DATA_SUCCESS, cluster }
}

export const GetMacroData = (data) => {
    return { type: actionTypes.GET_MACRO_DATA, macrodata: data }
}

export const GetMacroListData = (data) => {
    return { type: actionTypes.GET_MACRO_LIST, macrolist: data }
}

export const GetClusterData = (apiURL, reqData) => {
    return dispatch => {
        dispatch(GetClusterDataStart());
        let url = apiURL + `/getClusterdata`;
        PostCalltoServer(url, reqData).then(response => {
            if (response.data.status.responseType == "success") {
                dispatch(GetClusterDataSuccess(response.data.data));

            }
            else {
                dispatch(GetClusterDataFailure(response.status))

            }
        }).catch(err => {
            dispatch(GetClusterDataFailure(err))
        });
    };
};

export const KnowledgeIntentWithFileStart = () => {
    return {
        type: actionTypes.KNOWLEDGE_WITH_FILE_INTENT_START
    };
};
export const KnowledgeIntentWithFileFailure = (error) => {
    return {
        type: actionTypes.KNOWLEDGE_WITH_FILE_INTENT_FAILURE, error
    };
};
export const KnowledgeIntentWithFileSuccess = (pattern) => {
    return { type: actionTypes.KNOWLEDGE_WITH_FILE_INTENT_SUCCESS, pattern }
}
export const KnowledgeIntentWithFileCancel = () => {
    return { type: actionTypes.KNOWLEDGE_WITH_FILE_INTENT_CANCEL }
}

export const resetUpload = () => {
    return dispatch=>{
        dispatch(KnowledgeIntentWithFileCancel())
    }
}

export const SaveKnowledgeIntentWithFile = (apiURL, formData, data) => {
    let token = localStorage.getItem("accessToken")
    return dispatch => {
        dispatch(KnowledgeIntentWithFileStart());
        fetch(apiURL + `/smartfaq/upload`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${token}`,"orgName":orgName,"userName":username
            },
            body: formData,
        }).then(response => {
            return response.text();
        }).then(datas => {
            let result = JSON.parse(datas);
            let results = JSON.parse(result.data);
            if (result.status.responseType == "success") {
                let knowledgeUpload = { category: data.categoryName, "data": results.data, "ids": results.ids, "existingFiles": results.existingFiles }
                dispatch(KnowledgeIntentWithFileSuccess(knowledgeUpload));
                if (!result.data.includes('Do you wish to override ?')) {
                    if(localStorage.getItem('language') == 'en'){
                        setTimeout(()=>{
                            dispatch(SetSuccessMessage("FAQs uploaded successfully."));
                        },2000)
                    }
                    else{
                        dispatch(SetSuccessMessage("FAQs uploaded successfully."));
                    }
                }
                let projectName = localStorage.getItem("projectname");
                let language = localStorage.getItem("language");
                if (!language) {
                    language = "en";
                }
                var requestObject = { "projectName": projectName, "language": language };
                //dispatch(GetKnowledgeIntent(requestObject, apiURL))
                setTimeout(()=>{
                    dispatch(GetKnowledgeIntent(requestObject, apiURL))
                },2000)
            }
            else{
                dispatch(KnowledgeIntentWithFileFailure(result.errors));
                dispatch(SetErrorMessage(result.errors));
            }
        })
            .catch((error) => {
                 dispatch(KnowledgeIntentWithFileFailure(error))
                if(error.message.includes("Failed to fetch")){
                    dispatch(SetErrorMessage( "Unable to Fetch some of the chosen file(s)"));
                   
                    
                    
                } else {
                    dispatch(KnowledgeIntentWithFileFailure(error))
                }
            })
    };
};


export const TranslationProjectStart = () => {
    return {
        type: actionTypes.TRANSLATION_PROJECT_START
    };
};
export const TranslationProjectEnd = () => {
    return {
        type: actionTypes.TRANSLATION_PROJECT_END
    };
};

export const HandleTranslationBtn = (flag) => {
    return {
        type: actionTypes.HANDLE_TRANSLATION_BTN,
        flag: flag
    };
};

export const getProjectDataSuccess = (projectdata) => {
    return {
        type: actionTypes.GET_PROJECT_DATA,
        projectdata: projectdata
    };
};
export const saveProfileStart = () => {
    return {
        type: actionTypes.SAVE_PROFILE_START
    };
};
export const saveProfileFailure = (error) => {
    return {
        type: actionTypes.SAVE_PROFILE_FAILURE, error
    };
};
export const saveProfileSuccess = (botProfile) => {
    return { type: actionTypes.SAVE_PROFILE_SUCCESS, botProfile }
}

export const saveBotProfile = (profile, apiURL) => {
    return dispatch => {
        dispatch(saveProfileStart());
        let profiles = { ...profile, "orgName": localStorage.getItem("orgName") }

        let url = apiURL + `/project/flow/script`;
        botPostCalltoServer(url, profiles).then(response => {
            if (response.data.status.responseType == "success") {
                dispatch(saveProfileSuccess(response.data.data));
                if(localStorage.getItem('language')){
                setTimeout(() => {
                    dispatch(SetSuccessMessage('Flow saved successfully.'));
                }, 1000);
            }
            // else{
            //     setTimeout(() => {
            //         dispatch(SetSuccessMessage('Flow saved successfully.'));
            //     }, 1000);
            // }
            }
            else {
                dispatch(saveProfileFailure(response.data.status.responseType))
            }
        }).catch(err => {
            dispatch(saveProfileFailure(err))
        });
    };
};
//delete script
export const deleteEditPage = (profile, apiURL) => {
    return dispatch => {
        dispatch(saveProfileStart());
        let profiles = { ...profile, "orgName": localStorage.getItem("orgName") }
        console.log("total profile" + profile);

        let url = apiURL + `/project/flow/script`;
        PostCalltoServer(url, profiles).then(response => {
            if (response.data.status.responseType == "success") {
                dispatch(saveProfileSuccess(response.data.data));
                setTimeout(() => {
                    dispatch(SetSuccessMessage('The data is deleted Successfully'));
                }, 1000);
            }
            else {
                dispatch(saveProfileFailure(response.data.status.responseType))
            }
        }).catch(err => {
            dispatch(saveProfileFailure(err))
        });
    };
};

export const getStructureFailure = (error) => {
    return {
        type: actionTypes.GET_STRUCTURE_FAILURE, error
    };
};
export const getStructureSuccess = (bot) => {
    return { type: actionTypes.GET_STRUCTURE_SUCCESS, bot }
}

export const getProfileStructure = (bot, apiURL) => {
    return dispatch => {
        let bots = { ...bot, "orgName": localStorage.getItem("orgName") }
        let url = apiURL + `/getBotProfileStructureList`;
        PostCalltoServer(url, bots).then(response => {
            if (response.data.status.responseType == "success") {
                dispatch(getStructureSuccess(response.data.data))
            }
            else {
                dispatch(getStructureFailure(response.data.status.responseType))
            }
        }).catch(err => {
            dispatch(getStructureFailure(err))
        });
    };
};

export const getBotFileFailure = (error) => {
    return {
        type: actionTypes.GET_BOTFILE_FAILURE, error
    };
};
export const getBotFileSuccess = (file) => {
    return { type: actionTypes.GET_BOTFILE_SUCCESS, file }
}

export const getBotFile = (file, apiURL) => {
    return dispatch => {
        let files = { ...file, "orgName": localStorage.getItem("orgName") }
        let url = apiURL + `/project/${files.projectname}/flow/${files.botName}/script/${files.language}`;
        GetCalltoServer(url).then(response => {
            if (response.data.status.responseType == "success") {
                dispatch(getBotFileSuccess(response.data.data))
            }
            else {
                dispatch(getBotFileFailure(response.data.status.responseType))
            }
        }).catch(err => {
            dispatch(getBotFileFailure(err))
        });
    };
};

export const getEntityStart = () => {
    return {
        type: actionTypes.GET_ENTITY_START
    };
};
export const getEntityFailure = (error, entity) => {
    return {
        type: actionTypes.GET_ENTITY_FAILURE, error, entity
    };
};
export const getEntitySuccess = (entity) => {
    return { type: actionTypes.GET_ENTITY_SUCCESS, entity }
}

export const getEntity = (flow, apiURL) => {
    return dispatch => {
        dispatch(getEntityStart());
        let flows = { ...flow, "orgName": localStorage.getItem("orgName") }
        let url = apiURL + `/project/${flows.projectname}/flow/${flows.botName}/entity/${flows.language}`;
        GetCalltoServer(url).then(response => {
            if (response.data.status.responseType == "success") {
                dispatch(getEntitySuccess(response.data.data))
            }
            else {
                dispatch(getEntityFailure(response.data.status.responseType, response.data.data))
            }
        }).catch(err => {
            dispatch(getEntityFailure(err, null))
        });
    };
};

export const readFileFailure = (error) => {
    return {
        type: actionTypes.READ_FILE_FAILURE, error
    };
};
export const readFileSuccess = (fileData) => {
    return { type: actionTypes.READ_FILE_SUCCESS, fileData }
}

export const readFile = (file, apiURL) => {
    return dispatch => {
        let url = apiURL + `/project/readFile`;
        botPostCalltoServer(url, file).then(response => {
            if (response.data.status.responseType == "success") {
                dispatch(readFileSuccess(response.data.data))
            }
            else {
                dispatch(readFileFailure(response.data.status.responseType))
            }
        }).catch(err => {
            dispatch(readFileFailure(err))
        });
    };
};
export const createEntityStart = () => {
    return {
        type: actionTypes.CREATE_ENTITY_START
    };
};
export const createEntityFailure = (error) => {
    return {
        type: actionTypes.CREATE_ENTITY_FAILURE, error
    };
};
export const createEntitySuccess = (createEntity) => {
    return { type: actionTypes.CREATE_ENTITY_SUCCESS, createEntity }
}

export const onCreateUpdateEntity = (file, apiURL, fromFlowModal, isDeleteAction,EntityOccurance) => {
    return dispatch => {
        dispatch(createEntityStart());
        let files = { ...file, "username": localStorage.getItem('userName'), "orgName": localStorage.getItem("orgName") }
        let url = apiURL + `/project/flow/entity`;
        botPostCalltoServer(url, files).then(response => {
            if (response.data.status.responseType == "success") {
                dispatch(createEntitySuccess(response.data.data));
                if (!fromFlowModal){
                    if (!isDeleteAction&&EntityOccurance==false){
                        dispatch(SetSuccessMessage('Entities created successfully.'));
                    }else if(EntityOccurance==true){
                        dispatch(SetSuccessMessage('Entities saved successfully.')); 
                    }
                    else
                    dispatch(SetSuccessMessage('Entity deleted successfully.'));
                }
            }
            else {
                dispatch(createEntityFailure(response.data.status.responseType))
                dispatch(SetErrorMessage('Error occured.'));

            }
        }).catch(err => {
            dispatch(createEntityFailure(err))
            dispatch(SetErrorMessage('Error occured.'));
        });
    };
};
export const getChatBotFailure = (error) => {
    return {
        type: actionTypes.GET_CHATBOT_FAILURE, error
    };
};
export const getChatBotSuccess = (getBot) => {
    return { type: actionTypes.GET_CHATBOT_SUCCESS, getBot }
}

export const getChatBot = (project, apiURL) => {
    return dispatch => {
        let projects = { ...project, "orgName": localStorage.getItem("orgName") }
        let url = apiURL + `/getChatBotIntent`;
        PostCalltoServer(url, projects).then(response => {
            if (response.data.status.responseType == "success") {
                dispatch(getChatBotSuccess(response.data.data))

            }
            else {
                dispatch(getChatBotFailure(response.data.status.responseType))
                dispatch(SetErrorMessage('Error occured.'));
            }
        }).catch(err => {
            dispatch(getChatBotFailure(err))
            dispatch(SetErrorMessage('Error occured.'));
        });
    };
};

export const cuxBotStart = () => {
    return {
        type: actionTypes.CUXBOT_START
    };
};

export const CuxLoader = (data) => {
    return {
        type: actionTypes.CUX_LOADER, flag: data
    };
};

export const createbotStart = () => {
    return {
        type: actionTypes.CREATE_BOT_START
    };
};

export const createBotSuccess = (bot) => {

    return { type: actionTypes.CREATE_BOT_SUCCESS, bot }
}

export const setProjectReDirect = (val) => {
    return { type: actionTypes.PROJECT_REDIRECT_TO, data: val }
}

export const setRedirection = (val) => {
    return dispatch => {
        dispatch(setProjectReDirect(val))
    }
}


export const createBotFailure = (error) => {
    return {
        type: actionTypes.CREATE_BOT_FAILURE, error
    };
};

export const CreateBot = (apiURL, bot, fromImportCluster, isProfileSuccess, isCuxOrg) => {
    return dispatch => {
        let bots = { ...bot, "orgName": localStorage.getItem("orgName") }
        let user = localStorage.getItem("sessiontoken");
        user = JSON.parse(user);
        bot.username = user.useremailid;
        dispatch(createbotStart());
        let url = apiURL + `/project/flow`;
        botPostCalltoServer(url, bots)
            .then(response => {
                if (response.data && response.data.status.responseType == "success") {
                    if (isProfileSuccess) {
                        dispatch(SetSuccessMessage('Profile is created successfully.'));
                    }
                    else {
                        dispatch(createBotSuccess("created"));
                        if (!fromImportCluster)
                        setTimeout(()=>{
                            dispatch(SetSuccessMessage(isCuxOrg?'Intent created successfully.':'Flow created successfully.'));
                        },2000) 
                        setTimeout(() => {
                           dispatch(GetBotList(apiURL));
                        }, 1500);
                    }
                }
                else {
                    let errs = []
                    if (response.data && response.data.errors.length > 0) {
                        (response.data.errors.forEach(err => {
                            if (err.source)
                                errs.push(err.source)
                        }));
                    }
                    errs = errs.join(",")
                    if (errs) dispatch(SetErrorMessage(errs + ' in create flow'));
                    else dispatch(SetErrorMessage(isCuxOrg?"Intent creation failed":"Flow creation failed"));

                    dispatch(createBotFailure(response.data.status.responseType))
                }

            }).catch(err => {
                dispatch(createBotFailure(err.message));
                dispatch(SetErrorMessage(err.message));
            });
    };
};

export const useInProject = (apiURL, bot) => {
    return dispatch => {
        let bots = { ...bot, "orgName": localStorage.getItem("orgName") }
        let user = localStorage.getItem("sessiontoken");
        user = JSON.parse(user);
        bot.username = user.useremailid;
        let url = apiURL + `/category/flow/project`;
        botPostCalltoServer(url, bots)
            .then(response => {
                if (response.response) {
                    dispatch(SetWarningMessage(`${bot.botName} flow already exists in ${bot.project} project.`));
                }
                else if (response.data.status.responseType == "success") {
                    dispatch(SetSuccessMessage(`${bot.botName} flow added to ${bot.project} project successfully.`));
                    // localStorage.setItem("projectname", bot.project);
                    encryptAndStore("projectname", bot.project);
                    setTimeout(() => {
                        dispatch(setProjectReDirect({ projectName: bot.project, botName: bot.botName, isRedirect: true }))
                    }, 2000)
                }
                else {
                    dispatch(SetErrorMessage("Category flow creation failed"));
                }
            }).catch(err => {
                dispatch(createBotFailure(err.message));
                dispatch(SetErrorMessage(err.message));
            });
    };
};

export const getBotListSuccess = (botlist) => {
    return { type: actionTypes.GET_BOT_LIST_SUCCESS, botList: botlist }
}

export const getBotListFailure = (error) => {
    return {
        type: actionTypes.GET_BOT_LIST_FAILURE, error
    };
};

export const GetBotList = (apiURL) => {
    return dispatch => {
        let url = apiURL + `/project/flow`;
        GetCalltoServer(url)
            .then(response => {
                if (response.data.status.responseType == "success") {
                    dispatch(getBotListSuccess(response.data.data));
                }
                else {
                    dispatch(getBotListFailure(response.data.status.responseType))
                }
            }).catch(err => {
                dispatch(getBotListFailure(err.message))
            });
    };
};


export const getProjectsListSuccess = (AllProjectsList) => {
    return { type: actionTypes.GET_PROJECT_LIST_SUCCESS, AllProjectsList: AllProjectsList }
}

export const getAssistantsListSuccess = (AllAssistants) => {
    return { type: actionTypes.GET_ASSISTANTS_LIST_SUCCESS, AllAssistants: AllAssistants }
}

export const getAssistantsListFailure = (error) => {
    return {
        type: actionTypes.GET_ASSISTANTS_FAILURE, error
    };
};

export const getProjectsListFailure = (error) => {
    return {
        type: actionTypes.GET_PROJECT_LIST_FAILURE, error
    };
};

export const getProjectsListStart = () => {
    return {
        type: actionTypes.GET_PROJECTLIST_START
    };
};

export const getAssistantsListStart = () => {
    return {
        type: actionTypes.GET_ASSISTANTS_START
    };
};

export const GetAllProjectsData = (apiURL) => {
    return dispatch => {
        let language = localStorage.getItem("language");
        let url = apiURL + `/project/info/${language}`;
        dispatch(getProjectsListStart())
        GetCalltoServer(url)
            .then(response => {
                if (response.data.status.responseType == "success") {
                    dispatch(getProjectsListSuccess(response.data.data));
                }
                else {
                    dispatch(getProjectsListFailure(response.data.status.responseType))
                }
            }).catch(err => {
                dispatch(getBotListFailure(err.message))
            });
    };
};

export const GetAllAssistantsData = (apiURL) => {
    return dispatch => {
        let url = apiURL + `/getallvirtualassistants`;
         dispatch(getAssistantsListStart())
        GetCalltoServer(url)
            .then(response => {
                if (response.data.status.responseType == "success") {
                    dispatch(getAssistantsListSuccess(JSON.parse(response.data.data)));
                }
                else {
                    dispatch(getAssistantsListFailure(response.data.status.responseType))
                }
            }).catch(err => {
                // dispatch(getBotListFailure(err.message))
            });
    };
};




export const getDocFailure = (error) => {
    return {
        type: actionTypes.GET_DOC_FAILURE, error
    };
};
export const getDocSuccess = (getDoc) => {
    return { type: actionTypes.GET_DOC_SUCCESS, getDoc }
}

export const getDoc = (project, apiURL) => {
    return dispatch => {
        let files = { ...project, "orgName": localStorage.getItem("orgName") }
        let url = apiURL + `/project/${files.projectname}/flow/${files.botName}/intent/${files.language}`;
        GetCalltoServer(url).then(response => {
            if (response.data.status.responseType == "success") {
                dispatch(getDocSuccess(response.data.data))
            }
            else {
                dispatch(getDocFailure(response.data.status.responseType))
                dispatch(SetErrorMessage('Error occured.'));
            }
        }).catch(err => {
            dispatch(getDocFailure(err))
            dispatch(SetErrorMessage('Error occured.'));
        });
    };
};

export const getProjectDescriptionFailure = (error) => {
    return {
        type: actionTypes.GET_PROJECT_DESCRIPTION_FAILURE, error
    };
};
export const getProjectDescriptionSuccess = (getprojDesp) => {
    return { type: actionTypes.GET_PROJECT_DESCRIPTION_SUCCESS, getprojDesp }
}

export const getProjectDescription = (project, apiURL) => {
    return dispatch => {
        let files = { ...project, "orgName": localStorage.getItem("orgName") }
        let url = apiURL + `/project/${files.projectname}/${files.language}`;
        GetCalltoServer(url).then(response => {
            if (response.data.status.responseType == "success") {
                dispatch(getProjectDescriptionSuccess(response.data.data))
            }
            else {
                dispatch(getProjectDescriptionFailure(response.data.status.responseType))
                dispatch(SetErrorMessage('Description not available.'));
            }
        }).catch(err => {
            dispatch(getProjectDescriptionFailure(err))
            dispatch(SetErrorMessage('Description not available.'));
        });
    };
};






export const getCatIntent = (project, apiURL) => {
    return dispatch => {
        let files = { ...project, "orgName": localStorage.getItem("orgName") }
        let url = apiURL + `/category/${files.projectname}/flow/${files.botName}/intent/${files.language}`;
        GetCalltoServer(url).then(response => {
            if(response.status==204){
                dispatch(getDocSuccess(response.data))
            }else if(response.data.status.responseType == "success") {
                dispatch(getDocSuccess(response.data.data))
            }else {
                dispatch(getDocFailure(response.data.status.responseType))
                dispatch(SetErrorMessage('Error occured.'));
            }
        }).catch(err => {
            dispatch(getDocFailure(err))
            dispatch(SetErrorMessage('Error occured.'));
        });
    };
};

export const delFlowBotStart = () => {
    return {
        type: actionTypes.DELETE_FLOW_BOT_START
    };
};

export const delFlowBotSuccess = (status, botname) => {
    return {
        type: actionTypes.DELETE_FLOW_BOT_SUCCESS,
        botdata: botname
    };
};

export const delFlowBotFail = (error) => {
    return {
        type: actionTypes.DELETE_FLOW_BOT_FAIL,
        error: error
    };
};

export const delKnowledgeIntentStart = () => {
    return {
        type: actionTypes.DELETE_KNOWLEDGE_INTENT_START
    };
};

export const delKnowledgeIntentEnd = () => {
    return {
        type: actionTypes.DELETE_KNOWLEDGE_INTENT_END
    };
};

export const deleteFlowBot = (apiURL, data, isCuxOrg) => {
    return dispatch => {
        dispatch(delFlowBotStart());
        let list = { "project": data.projectName, "botName": data.botName, "language": data.language }
        let url = apiURL + `/project/${data.projectName}/flow/${data.botName}/${data.language}`;
        deleteCalltoServer(url, list)
            .then(response => {
                if (response.data.status.responseType == "success") {
                    dispatch(delFlowBotSuccess(response.data.status, data));
                    dispatch(SetSuccessMessage(isCuxOrg?"Intent deleted successfully.":"Flow deleted successfully."))
                }
                else {
                    dispatch(delFlowBotFail('Error occured.'));
                    dispatch(SetErrorMessage(isCuxOrg?'Failed to Delete Intent':'Failed to Delete Flow'));
                }
            })
            .catch(err => {
                dispatch(delFlowBotFail(err.message));
                dispatch(SetErrorMessage(err.message));
            });
    };
};

export const deleteFlow = (apiURL, projectName, flowName) => {
    return dispatch => {
        
        let data = { "botName": flowName, "projectName": projectName, "language": localStorage.getItem('language'), "project": projectName, "orgName": localStorage.getItem("orgName") }
        let url = apiURL + `/project/${data.projectName}/flow/${data.botName}/${data.language}`;
       
        deleteCalltoServer(url, data).then(response => {
            if (response.data.status.responseType == "success") {
                dispatch(SetSuccessMessage(flowName + ' has been deleted successfully.'));
                setTimeout(() => {
                    dispatch(flowList(apiURL));
                }, 1000);
            }
            else {
                dispatch(SetErrorMessage('Error occured.'));
            }
        }).catch(() => {
            dispatch(SetErrorMessage('Error occured.'));
        });
    };
};
export const exportFlowBotStart = () => {
    return {
        type: actionTypes.EXPORT_FLOW_BOT_START
    };
};

export const exportFlowBotSuccess = (status, botname) => {
    return {
        type: actionTypes.EXPORT_FLOW_BOT_SUCCESS,
        botdata: botname
    };
};

export const exportFlowBotFail = (error) => {
    return {
        type: actionTypes.EXPORT_FLOW_BOT_FAIL,
        error: error
    };
};
export const exportFlowBot = (apiURL, data) => {
    return dispatch => {
        dispatch(exportFlowBotStart());
        let url = apiURL + `/project/flow/export`;
        let token = localStorage.getItem("accessToken");
        axios({
            url: url,
            headers: { 'content-type': 'application/json', 'Authorization': `Bearer ${token}`,"orgName":orgName,"userName":username },
            method: 'POST',
            data: data,
            responseType: 'blob', // important
        }).then(response => {
            if(response.data){
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', data.botName + ".zip");
            document.body.appendChild(link);
            link.click();
            setTimeout(() => {
                dispatch(SetSuccessMessage("Intent exported successfully."));
            }, 3000); 
            }
        })
            .catch(err => {
                dispatch(exportFlowBotFail(err.message));
                dispatch(SetErrorMessage(err.message));
            });
    };
};

export const exportCuxBot = (apiURL, data) => {
    return dispatch => {
        dispatch(exportFlowBotStart());
        let url = apiURL + `/project/flowIntent/export`;
        let token = localStorage.getItem("accessToken");
        axios({
            url: url,
            headers: { 'content-type': 'application/json', 'Authorization': `Bearer ${token}`,"orgName":orgName,"userName":username },
            method: 'POST',
            data: data,
            responseType: 'blob', // important
        }).then(response => {
            if(response.data){
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', data.botName + ".zip");
            document.body.appendChild(link);
            link.click();
            dispatch(SetSuccessMessage("Flow exported successfully."))
            }

        })
            .catch(err => {
                dispatch(exportFlowBotFail(err.message));
                dispatch(SetErrorMessage(err.message));
            });
    };
};

export const importCuxBot = (apiURL, data , flowName) => {
    let token = localStorage.getItem("accessToken");
    return dispatch => {
        dispatch(importflowStart());
        let url = apiURL + `/botbuilder/project/flowIntent/import`;
        fetch(url, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${token}`,"orgName":orgName,"userName":username
            },
            body: data,
        })
        .then((response) => {
            if (response.status === 200) {
                setTimeout(() => {
                    dispatch(GetCuxBotList(apiURL, data.get('project')));
                }, 1500);
                dispatch(SetSuccessMessage('Flow imported successfully.'));
            }
            else if(response.status === 400) {
                setTimeout(() => {
                    dispatch(GetCuxBotList(apiURL, data.get('project')));
                }, 1500);
                dispatch(SetSuccessMessage('Flow import failed due to invalid zip file.'));
            }
            // else if (response.data) {
            //     dispatch(KnowledgeIntentWithFileFailure(response.data));
            //     dispatch(SetErrorMessage('Flow import failed due to invalid zip file.'));
            // }
            else if(response.errors.includes('Flow import failed as flow already exists')){
                dispatch(SetErrorMessage('Flow import failed as duplicate Intent already exists.'));
            }
            else{
                dispatch(SetErrorMessage("Failed to import flow"));
            }
        })
        .catch((error) => {
            // Handle the error
             dispatch(importflowFail(error));
           
            if(error.message.includes("Failed to fetch")){
                dispatch(SetErrorMessage( 'Unable to Fetch some of the chosen file(s)'));
            }
            else{
                dispatch(importflowFail(error));
            }
        });
    };
};


export const importflow = (apiURL, input, dataUrl,flowName) => {
    let token = localStorage.getItem("accessToken");
    let name = "";
    name = flowName;
    return dispatch => {
        dispatch(importflowStart());
        let url = apiURL + `/project/flow/import`;
        fetch(url, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${token}`,"orgName":orgName,"userName":username
            },
            body: input,
        })
            .then((response) => {
                return response.text();
            })
            .then(datas => {
                let result = JSON.parse(datas);
                if (result.status.responseType == "success") {
                    dispatch(importflowSuccess('flow uploaded', flowName));
                    setTimeout(() => {
                        dispatch(SetSuccessMessage('Intent imported successfully.'));
                    }, 2000);
                    setTimeout(() => {
                        dispatch(GetBotList(dataUrl));
                    }, 1500);
                }
                else if (result.data) {
                    dispatch(KnowledgeIntentWithFileFailure(result.data));
                    dispatch(SetErrorMessage('Intent import failed due to invalid zip file.'));
                }else if(result.errors.includes('Flow import failed as flow already exists')){
                    dispatch(SetErrorMessage('Intent import failed as duplicate Intent already exists.'));
                }
                else{
                    dispatch(SetErrorMessage("Failed to import intent"));
                }
            })
            .catch((error) => {
                // dispatch(SetErrorMessage(get(error, 'response', '')));
                if(error.message.includes("Failed to fetch")) {
                    dispatch(SetErrorMessage('Unable to Fetch some of the chosen file(s)'));
                    
                }
                else {
                    dispatch(SetErrorMessage(get(error, 'response', '')));
                }
            })
    };
};

export const importflowStart = () => {
    return {
        type: actionTypes.IMPORT_FLOW_START
    };
};

export const importflowSuccess = (status, botname) => {

    return { type: actionTypes.IMPORT_FLOW_SUCCESS, botname }
}

export const importflowFail = (error) => {
    return {
        type: actionTypes.IMPORT_FLOW_FAIL, error
    };
};
export const exportProjectStart = () => {
    return {
        type: actionTypes.EXPORT_PROJECT_START
    };
};

export const exportProjectSuccess = (status, botname) => {
    return {
        type: actionTypes.EXPORT_PROJECT_SUCCESS,
        botdata: botname
    };
};

export const exportProjectFail = (error) => {
    return {
        type: actionTypes.EXPORT_PROJECT_FAIL,
        error: error
    };
};
export const exportProject = (apiURL, data) => {
    return dispatch => {
        dispatch(exportProjectStart());
        let url = apiURL + `/project/export`;
        let token =localStorage.getItem("accessToken");
        axios({
            url: url,
            headers: { 'content-type': 'application/json', 'Authorization': `Bearer ${token}`,"orgName":orgName,"userName":username },
            method: 'POST',
            data: data,
            responseType: 'blob', // important
        }).then(response => {
            if(response.data){
            const urlW = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = urlW;
            link.setAttribute('download', data.name + ".zip");
            document.body.appendChild(link);
            link.click();
            dispatch(SetSuccessMessage("Project exported successfully."))
            }
        })
            .catch(err => {
                dispatch(exportProjectFail(err.message));
                dispatch(SetErrorMessage(err.message));
            });
    };
};
export const KnowledgeIntentImportStart = () => {
    return {
        type: actionTypes.KNOWLEDGE_INTENT_IMPORT_START
    };
};
export const KnowledgeIntentImportFailure = (error) => {
    return {
        type: actionTypes.KNOWLEDGE_INTENT_IMPORT_FAILURE, error
    };
};
export const KnowledgeIntentImportSuccess = (importKnowledgeIntent) => {
    return { type: actionTypes.KNOWLEDGE_INTENT_IMPORT_SUCCESS, importKnowledgeIntent }
}

export const ConnectDataImportFailure = (error) => {
    return {
        type: actionTypes.CONNECT_DATA_IMPORT_FAILURE, error
    };
};
export const ConnectDataImportSuccess = (connectData) => {
    return { type: actionTypes.CONNECT_DATA_IMPORT_SUCCESS, connectData }
}

export const ConnectDataImportStart = () => {
    return {
        type: actionTypes.CONNECT_DATA_IMPORT_START
    };
};
export const KnowledgeIntentImport = (apiURL, data, importType) => {
    return dispatch => {
        if (importType == "KnowledgeIntent")
            dispatch(KnowledgeIntentImportStart());
        else if (importType == "ConnectData")
            dispatch(ConnectDataImportStart());
        let url = apiURL + `/bulkEsData`;
        PostCalltoServer(url, data, false).then(response => {
            if (response.data.status.responseType == "success") {
                dispatch(SetSuccessMessage("Import successfully"));

                setTimeout(() => {
                    if (importType == "KnowledgeIntent")
                        dispatch(KnowledgeIntentImportSuccess(response.data.data));
                    else if (importType == "ConnectData")
                        dispatch(ConnectDataImportSuccess(response.data.data));
                }, 1000);
            }
            else {
                if (importType == "KnowledgeIntent")
                    dispatch(KnowledgeIntentImportFailure(response.status));
                else if (importType == "ConnectData")
                    dispatch(ConnectDataImportFailure(response.data.data));
                dispatch(SetErrorMessage(response.data.status.responseType));
            }
        }).catch(() => {
            dispatch(SetErrorMessage('Error occured.'));
        });

    }
};

export const HandleImportError = (msg) => {
    return dispatch => {
        dispatch(SetErrorMessage(msg));
    }
}

export const HandleSuccessMsg = (msg) => {
    return dispatch => {
        dispatch(SetWarningMessage(msg));
    }
}

export const ExportKnowledgeIntent = (apiURL, projectName, lang) => {
    return dispatch => {
       
        let url = apiURL + `/smartfaq/qna/${projectName}/${lang}`
        GetCalltoServer(url)
            .then(response => {
                if (response.data.status.responseType == "success") {
                    let jsonData = response.data.data;
                    let arrResults = jsonData.length > 0 ? jsonData : [];
                    if (arrResults && arrResults.length > 0) {
                        dispatch(SetSuccessMessage("FAQs exported successfully.")) 
                        JSONToCSV(arrResults, projectName);
                    }
                    else {
                        dispatch(SetErrorMessage('No Records found.'));
                    }
                }
                else {
                    dispatch(SetErrorMessage('Error occured.'));
                }
            }).catch(() => {
                dispatch(SetErrorMessage('Error occured.'));
            });
    };
};

export const JSONToCSV = (arrData, ReportTitle) => {
    var fileName = "Report_";
    fileName += ReportTitle.replace(/ /g, "_");
    var data = []
    for (var i = 0; i < arrData.length; i++) {
        var datakey = {};
        for (var index in arrData[i]) {
            if (index != "createdate" && index != "isfaq" && index != "") {
                var indexval = arrData[i][index];
                if (index == "project") {
                    datakey["project"] = indexval;
                }
                else if (index == "description") {
                    datakey["question_shortDesc"] = indexval;
                }
                else if (index == "categoryName") {
                    datakey["categoryName"] = indexval;
                }
                else if (index == "training") {
                    if (indexval.learn) {
                        let dataRow = "";
                        var learnData = indexval.learn;
                        const rows = learnData.split("\n");
                        rows.shift();
                        rows.forEach(function (row) {
                            if (row && row.trim() !== "" && row.trim() !== "undefined")
                                dataRow += row + "\n";
                        })
                        datakey["alternate_questions"] = dataRow
                    }
                }
                else if (index == "response") {
                    datakey["response"] = indexval;
                }
                else if (index == "File_Name") {
                    datakey["File_Name"] = indexval;
                }
            }
        }
        data.push(datakey)

    }

    /* make the worksheet */
    let ws = XLSX.utils.json_to_sheet(data);
    /* write workbook (use type 'binary') */
    let csv = XLSX.utils.sheet_to_csv(ws);
    let csvData = "\ufeff" + csv;
    /* generate a download */

    let blob = new Blob([csvData], { type: 'text/csv' });
    var csvUrl = window.webkitURL.createObjectURL(blob);

    var link = document.createElement("a");
    link.setAttribute("href", csvUrl);
    link.setAttribute("download", fileName + ".csv");
    document.body.appendChild(link); // Required for FF
    link.click(); // This will download the data file named "fileName.csv".
    document.body.removeChild(link);
}

export const DeleteKnowledgeIntent = (apiURL, projectName, language, flowname, org) => {
    return dispatch => {
        let query = { "query": { "match": { "isfaq": "faq" } } };
        let optimusCollection = '';
        let datasourcename = 'optimus';
        if (datasourcename == "optimus" && orgName != "default") {
            optimusCollection = datasourcename + "_" + org;
        }
        else if (language != "en") {
            optimusCollection = datasourcename + "_" + language;
        } else {
            optimusCollection = datasourcename;
        }
        let requestObject = { "url": optimusCollection + "/_delete_by_query", "data": JSON.stringify(query) };
        let url = apiURL + `/bulkActionES`;

        const options = {
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: JSON.stringify(requestObject),
            url,
        };

        axios(options).then(response => {
            dispatch(delKnowledgeIntentStart());
            if (response.data.status.responseType == "success") {
                dispatch(SetSuccessMessage("Deleted Succesfully!"))
                dispatch(delKnowledgeIntentEnd());
            }
            else {
                dispatch(SetErrorMessage('Error occured.'));
                dispatch(delKnowledgeIntentEnd());
            }
        }).catch(()=> {
            dispatch(SetErrorMessage('Error occured.'));
            dispatch(delKnowledgeIntentEnd());
        });


    };
};

export const DeleteMultiKnowledgeIntent = (apiURL, data,bool) => {
    return dispatch => {
        dispatch(delKnowledgeIntentStart());
		if (bool == true) {
          let url = apiURL + `/smartfaq/qna/${data.project}/category/${data.category}/${localStorage.getItem("language")}`;
          deleteCalltoServer(url)
		  .then(response => {
                if (response.data.status.responseType == "success") {
                    dispatch(SetSuccessMessage("FAQs deleted successfully."));
                    dispatch(delKnowledgeIntentEnd());
                }
                else {
                    dispatch(SetErrorMessage("Error occured."));
                    dispatch(delKnowledgeIntentEnd());

                }
            }).catch(err => {
                dispatch(SetErrorMessage(err.message))
                dispatch(delKnowledgeIntentEnd());
            });
		} else {
			let url = apiURL + '/review/multiDeleteKnowledgeIntentFiles';
            PostCalltoServer(url, data)
			.then(response => {
                if (response.data.status.responseType == "success") {
					 dispatch(initChatBotSuccess(response.data.data));
                    let smartFaqUrl = apiURL + '/review/multiDeleteKnowledgeIntentFilesInSmartFaq';
                     setTimeout(() => {
                         PostCalltoServer(smartFaqUrl, data)
                             .then(response => {
                                 if (response.data.status.responseType == "success") {
                                     dispatch(initChatBotSuccess(response.data.data));
                                     dispatch(SetSuccessMessage(response.data.data));
                                     dispatch(delKnowledgeIntentEnd());
                                 }
                                 else {
                                     dispatch(SetErrorMessage("Error occured."));
                                     dispatch(delKnowledgeIntentEnd());

                                 }
                             }).catch(err => {
                                 dispatch(SetErrorMessage(err.message))
                                 dispatch(delKnowledgeIntentEnd());
                             });
                     }, 5000);
                    dispatch(SetSuccessMessage("FAQ deleted successfully."));
                    dispatch(delKnowledgeIntentEnd());
                }
                else {
                    dispatch(SetErrorMessage("Error occured."));
                    dispatch(delKnowledgeIntentEnd());

                }
            }).catch(err => {
                dispatch(SetErrorMessage(err.message))
                dispatch(delKnowledgeIntentEnd());
            });
		}
    };
};

export const initChatBotSuccess = (initChatBot) => {
    return { type: actionTypes.INIT_CHAT_BOT_SUCCESS, initChatBot }
}

export const initChatBotFailure = (error) => {
    return {
        type: actionTypes.INIT_CHAT_BOT_FAILURE, error
    };
};

export const initChatBot = (apiURL, data, intent) => {
    return dispatch => {
        let url = apiURL + `/smartFAQ/initChatbot`;
        console.log("===projectview.js=="+data);
        botPostCalltoServer(url, data)
            .then(response => {
                if (response.data.status.responseType == "Success") {
                    dispatch(initChatBotSuccess(response.data.data));
                    dispatch(SetSuccessMessage('Chat bot has been initiated successfully.'));
                }
                else {
                    dispatch(initChatBotFailure(response.data.status.responseType))
                }
            }).catch(err => {
                dispatch(initChatBotFailure(err.message))
            });

         let botUrl = apiURL + `/botbuilder/project/trainEntitiesWithPositions`;
         data["intent"] = intent;
         botPostCalltoServer(botUrl, data)
            .then(response => {
                if (response.data.status.responseType == "Success") {
                    dispatch(initChatBotSuccess(response.data.data));
                    //dispatch(SetSuccessMessage('Chat bot has been initiated successfully.'));
                }
                else {
                    dispatch(initChatBotFailure(response.data.status.responseType))
                }
            }).catch(err => {
                dispatch(initChatBotFailure(err.message))
            });
    };
};
export const downloadProject = (apiURL) => {
    return dispatch => {
        let projectname = localStorage.getItem("projectname");
        let orgName = localStorage.getItem("orgName");
        let urlname = '';
        if (orgName == "default") {
            urlname = "/botAdminstrator/download/default/en/" + projectname + ".zip";
        }
        else {
            urlname = "/botAdminstrator/download/" + orgName + "/en/" + projectname + ".zip";
        }
        let token = localStorage.getItem("accessToken");
        axios({
            url: apiURL + urlname,
            headers: { 'content-type': 'application/json', 'Authorization': `Bearer ${token}` ,"orgName":orgName,"userName":username},
            method: 'POST',
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', projectname + ".zip");
            document.body.appendChild(link);
            link.click();
        }).catch(() => {
            dispatch(SetErrorMessage('Error occured.'));
        });
    }
};
export const downloadExportBot = (apiURL, item) => {
    let token = localStorage.getItem("accessToken");
    return dispatch => {
        axios({
            url: apiURL + "/botAdminstrator/downloadBot/" + item + ".zip",
            headers: { 'content-type': 'application/json', 'Authorization': `Bearer ${token}` ,"orgName":orgName,"userName":username},
            method: 'POST',
            responseType: 'blob', // important
        }).then((response) => {
            console.log(response)
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', item + ".zip");
            document.body.appendChild(link);
            link.click();
        }).catch(() => {
            dispatch(SetErrorMessage('Error occured.'));
        });
    }
}


export const intentListDataScoreSuccess = (intentListDataScore) => {
    return { type: actionTypes.INTENT_LIST_DATA_SCORE_SUCCESS, intentListDataScore }
}

export const intentListDataScoreFailure = (error) => {
    return {
        type: actionTypes.INTENT_LIST_DATA_SCORE_FAILURE, error
    };
};

export const IntentListDataScore = (apiURL, data) => {
    return dispatch => {
        let url = apiURL + `/intentListDataScore`;
        PostCalltoServer(url, data)
            .then(response => {
                if (response.data.status.responseType == "success") {
                    dispatch(intentListDataScoreSuccess(response.data.data));
                }
                else {
                    dispatch(intentListDataScoreFailure(response.data.status.responseType))
                }
            }).catch(err => {
                dispatch(intentListDataScoreFailure(err.message))
            });
    };
};

export const createProjectAccuracySuccess = (createProjectAccuracy) => {
    return { type: actionTypes.CREATE_PROJECT_ACCURACY_SUCCESS, createProjectAccuracy }
}

export const createProjectAccuracyFailure = (error) => {
    return {
        type: actionTypes.CREATE_PROJECT_ACCURACY_FAILURE, error
    };
};

export const CreateProjectAccuracy = (apiURL, data) => {
    return dispatch => {
        let url = apiURL + `/createProjectAccuracy`;
        PostCalltoServer(url, data)
            .then(response => {
                if (response.data.status.responseType == "success") {
                    dispatch(createProjectAccuracySuccess(response.data.data));
                }
                else {
                    dispatch(createProjectAccuracyFailure(response.data.status.responseType))
                }
            }).catch(err => {
                dispatch(createProjectAccuracyFailure(err.message))
            });
    };
};

export const HandleProjectAccuracyError = (msg) => {
    return dispatch => {
        dispatch(SetErrorMessage(msg));
    }
}


export const DownloadTemplate = (apiURL) => {
    return () => {
        let exporturl = apiURL + "/botAdminstrator/faq_template/template.csv";
        let sanitizedLink= DOMPurify.sanitize(exporturl);
        //window.open(exporturl);
        let newTab = window.open();
	newTab.opener = null;
	newTab.location=sanitizedLink;
    }
}
export const getLanguageValueByKey = (languageKey, langarray) => {
    for (var i = 0; i < langarray.length; i++) {
        if (langarray[i].langkey === languageKey) {
            return langarray[i].languagename;
        }
    }
    return false;
}

export const task = (i, project, temparray, langarray,) => {
    return dispatch => {
        setTimeout(function () {
            dispatch(SetSuccessMessage(project + " Project already exist in " + getLanguageValueByKey(temparray, langarray) + " language"));
        }, 2000 * i);
    }
}
export const TranslateProject = (apiURL, data) => {
    return dispatch => {
            let url = apiURL + `/project/languagetranslate`;
            dispatch(TranslationProjectStart());
            botPostCalltoServer(url, data)
                .then(response => {
                    if (response.data.status.responseType == "success") {
                        dispatch(TranslationProjectEnd());
                            dispatch(SetSuccessMessage('Project translated successfully. Change default language setting to view the newly translated project'));
                    }
                    else {
                        dispatch(TranslationProjectEnd());
                        dispatch(SetErrorMessage('Project translation failed'))

                    }
                }).catch(err => {
                    dispatch(SetErrorMessage(err.message))
                });
        };
};

export const GetTranslationFlag = (apiURL, data, langarray) => {
    return dispatch => {
        let url = apiURL + `/getTranslateFlag`;
        PostCalltoServer(url, data)
            .then(response => {
                if (response.data.status.responseType == "success") {
                    let actualjsonobj = JSON.parse(response.data.data);
                    if (actualjsonobj.transflag == "false") {
                        let countslist = actualjsonobj.countlist;
                        let selectedtranslatedlanglist = actualjsonobj.translatedlang;
                        if (Object.keys(countslist).length === 0 && countslist.constructor === Object) {
                            for (var chk = 0; chk < selectedtranslatedlanglist.length; chk++) {
                                dispatch(SetErrorMessage("facing issues while translating into " + getLanguageValueByKey(selectedtranslatedlanglist[chk], langarray) + " language"));
                            }
                        }
                        else if (Object.keys(countslist).length > 0 && countslist.constructor === Object && countslist !== null && selectedtranslatedlanglist !== ""
                            && selectedtranslatedlanglist !== null && actualjsonobj.projectmessage !== null
                            && actualjsonobj.projectmessage !== "") {
                            for (var key in countslist) {
                                var mykey = JSON.parse(countslist[key]);
                                if (mykey !== null && mykey.botcount !== undefined && mykey.botstransledcount !== undefined &&
                                    mykey.esIniticount !== undefined && mykey.estransltedcount !== undefined &&
                                    mykey.projectmessage !== "" && mykey.projectmessage !== null &&
                                    mykey.projectmessage !== "project limit exceeded") {
                                    var failedbots = mykey.botcount - mykey.botstransledcount;
                                    var failedintents = mykey.esIniticount - mykey.estransltedcount;
                                    dispatch(SetErrorMessage("failed bots " + failedbots + " and failed intents " + failedintents + " in " + getLanguageValueByKey(key, langarray) + " language"));
                                }
                                else if (actualjsonobj.projectmessage != "" && actualjsonobj.projectmessage != null) {
                                    for (var k = 0; k < selectedtranslatedlanglist.length; k++) {
                                        if (actualjsonobj.projectmessage[k] != "success") {
                                            dispatch(SetErrorMessage("project limit exceeded in " + getLanguageValueByKey(selectedtranslatedlanglist[k], langarray) + " language"));
                                        }
                                    }
                                }
                                else {
                                    if (selectedtranslatedlanglist.length > 0) {
                                        for (var langcheck = 0; langcheck < selectedtranslatedlanglist.length; langcheck++) {
                                            dispatch(SetErrorMessage("facing issues while translating into " + getLanguageValueByKey(selectedtranslatedlanglist[langcheck], langarray) + " language"));
                                        }
                                    }
                                }
                            }
                        }
                        else if (actualjsonobj.projectmessage != undefined && actualjsonobj.projectmessage != null && actualjsonobj.projectmessage.length > 0) {
                            if (selectedtranslatedlanglist.length > 0) {
                                for (var ck = 0; ck < selectedtranslatedlanglist.length; ck++) {
                                    dispatch(SetErrorMessage("project limit exceeded in " + getLanguageValueByKey(selectedtranslatedlanglist[ck], langarray) + " language"));
                                }
                            }
                        }
                        else {
                            if (selectedtranslatedlanglist.length > 0) {
                                for (var check = 0; check < selectedtranslatedlanglist.length; check++) {
                                    dispatch(SetErrorMessage("facing issues while translating into " + getLanguageValueByKey(selectedtranslatedlanglist[check], langarray) + " language"));
                                }
                            }
                        }
                        dispatch(TranslationProjectEnd());
                        clearInterval(translateIntervalMain);
                    }
                    else if (actualjsonobj.transflag == "true") {
                        dispatch(SetSuccessMessage('Translation already in progress.'));
                    }
                }
                else {
                    dispatch(TranslationProjectEnd());
                    clearInterval(translateIntervalMain);
                    dispatch(SetErrorMessage('Sorry, something went wrong while translating: Please try again later.'))

                }
            }).catch(err => {
                dispatch(TranslationProjectEnd());
                clearInterval(translateIntervalMain);
                dispatch(SetErrorMessage(err.message))
            });
    };

}

export const GetProjectData = (apiURL, orgName) => {
    return dispatch => {
        let url = apiURL + `/projectData`;
        let data = JSON.parse(localStorage.getItem("sessiontoken"));
        if (data !== null) {
            data["orgName"] = orgName;
        }
        PostCalltoServer(url, data)
            .then(response => {
                if (response.data.status.responseType == "success") {
                    dispatch(getProjectDataSuccess(JSON.parse(response.data.data)));
                }
                else {
                    dispatch(SetErrorMessage('Sorry, something went wrong while getting project data: Please try again later.'))
                }
            }).catch(() => {
            });
    };
};


export const HandleTranslateBtn = (flag) => {
    return dispatch => {
        dispatch(HandleTranslationBtn(flag))
    };

}

export const readMacro = (apiURL, macrodata) => {
    return dispatch => {
        let url = apiURL + `/readMacro`;
        let data = JSON.parse(localStorage.getItem("sessiontoken"));
        if (data !== null) {
            data["project"] = macrodata.projectName;
            data["language"] = macrodata.language;
            data["org"] = macrodata.orgName;
        }
        PostCalltoServer(url, data)
            .then(response => {
                if (response.data.status.responseType == "success") {
                    dispatch(GetMacroData(response.data.data));
                }
                else {
                    dispatch(SetErrorMessage('Sorry, something went wrong during project deletion: Please try again later.'))
                }
            }).catch(() => {
            });
    };
};

export const GetMacroList = (apiURL, macrodata) => {
    return dispatch => {
        let url = apiURL + `/readMacroList`;
        let data = JSON.parse(localStorage.getItem("sessiontoken"));
        if (data !== null) {
            data["project"] = macrodata.projectName;
            data["language"] = macrodata.language;
            data["org"] = macrodata.orgName;
            data["collection"] = macrodata.collection;
        }
        PostCalltoServer(url, data)
            .then(response => {
                if (response.data.status.responseType == "success") {
                    dispatch(GetMacroListData(response.data.data));
                }
            }).catch(() => {
            });
    };
};


export const saveMacroFileDataStart = () => {
    return {
        type: actionTypes.SAVE_MACRO_FILE_START
    };
};
export const saveMacroFileDataFailure = (error) => {
    return {
        type: actionTypes.SAVE_MACRO_FILE_FAILURE, error
    };
};
export const saveMacroFileDataSuccess = (macroFileData) => {
    return { type: actionTypes.SAVE_MACRO_FILE_SUCCESS, macroFileData }
}

export const SaveMacroFileData = (apiURL, macroData) => {
    return dispatch => {
        dispatch(saveMacroFileDataStart());
        let data = { ...macroData, "orgName": localStorage.getItem("orgName") }
        let url = apiURL + `/saveMacroFileData`;
        PostCalltoServer(url, data).then(response => {
            if (response.data.status.responseType == "success") {
                dispatch(saveMacroFileDataSuccess(response.data.data));
                dispatch(SetSuccessMessage('Macro File Data Saved successfully.'));
            }
            else {
                dispatch(saveMacroFileDataFailure(response.data.status.responseType))
            }
        }).catch(err => {
            dispatch(saveMacroFileDataFailure(err))
        });
    };
};
export const DeleteProject = (apiURL, projdata, boolValue) => {
    return dispatch => {
        let url = apiURL + `/project/${localStorage.getItem("projectname")}/${localStorage.getItem("language")}`;
        let data = JSON.parse(localStorage.getItem("sessiontoken"));
        if (data !== null) {
            data["collectionName"] = projdata.collectionName;
            data["projectName"] = projdata.projectName;
            data["language"] = projdata.language;
            data["orgName"] = projdata.orgName;
        }
        deleteCalltoServer(url, data)
            .then(response => {
                if (response.data.status.responseType == "success") {

                    if (boolValue === true) {
                        dispatch(SetSuccessMessage('Project deleted successfully.'));
                        // localStorage.setItem('DeleteProjName', projdata.projectName);
                        // localStorage.setItem('projectname','common');
                        encryptAndStore('DeleteProjName', projdata.projectName);
                        encryptAndStore('projectname','common');
                    }

                }
                else {
                    dispatch(SetErrorMessage('Sorry, something went wrong during project deletion: Please try again later.'))
                }
            }).catch(err => {
                dispatch(SetErrorMessage(err.message))
            });
    };
};


export const schedulerSuccess = (schedule) => {
    return { type: actionTypes.SCHEDULER_SUCCESS, schedule }
}

export const updateScheduler = (schedule) => {
    return dispatch => {
        dispatch(schedulerSuccess(schedule));
    };
};

export const userSchedulerSuccess = (userSchedule) => {
    return { type: actionTypes.USER_SCHEDULER_SUCCESS, userSchedule }
}

export const updateUserScheduler = (userSchedule) => {
    return dispatch => {
        dispatch(userSchedulerSuccess(userSchedule));
    };
};
export const chatSuccess = (chat) => {
    return { type: actionTypes.CHAT_SUCCESS, chat }
}

export const updateChat = (chatValue) => {
    return dispatch => {
        dispatch(chatSuccess(chatValue));
    };
};
export const FlowReplySuccess = (FlowReply) => {
    return { type: actionTypes.FLOWREPLY_SUCCESS, FlowReply }
}

export const updateFlowReply = (FlowReplyValue) => {
    return dispatch => {
        dispatch(FlowReplySuccess(FlowReplyValue));
    };
};
export const buttonSuccess = (button) => {
    return { type: actionTypes.BUTTON_SUCCESS, button }
}

export const updateButton = (buttonValue) => {

    return dispatch => {
        dispatch(buttonSuccess(buttonValue));
    };
};
export const buttonLabelSuccess = (buttoninput) => {
    return { type: actionTypes.BUTTON_LABEL_SUCCESS, buttoninput }
}

export const updateButtonLabel = (buttonchatdata) => {
   return dispatch => {
       dispatch(buttonLabelSuccess(buttonchatdata));
   };
};


export const inputSchedulerSuccess = (inputSchedule) => {
    return { type: actionTypes.INPUT_SCHEDULER_SUCCESS, inputSchedule }
}

export const updateInputScheduler = (inputSchedule) => {
    return dispatch => {
        dispatch(inputSchedulerSuccess(inputSchedule));
    };
};

export const timeSchedulerSuccess = (timeSchedule) => {
    return { type: actionTypes.TIME_SCHEDULER_SUCCESS, timeSchedule }
}

export const updateTimeScheduler = (timeSchedule) => {
    return dispatch => {
        dispatch(timeSchedulerSuccess(timeSchedule));
    };
};

export const indexSuccess = (index) => {
    return { type: actionTypes.INDEX_SUCCESS, index }
}

export const updateIndex = (title) => {
  
    return dispatch => {
        dispatch(indexSuccess(title));
    };
};

export const imgtitleSuccess = (title) => {
    return { type: actionTypes.IMG_TITLE_SUCCESS, title }
}

export const updateimgTitle = (title) => {
    return dispatch => {
        dispatch(imgtitleSuccess(title));
    };
};

export const subTitleSuccess = (subtitle) => {
    return { type: actionTypes.SUBTITLE_SUCCESS, subtitle }
}

export const updateSubTitle = (subtitle) => {
    return dispatch => {
        dispatch(subTitleSuccess(subtitle));
    };
};

export const imageSuccess = (image) => {
    return { type: actionTypes.IMAGE_SUCCESS, image }
}


export const updateImage = (image) => {
    return dispatch => {
        dispatch(imageSuccess(image));
    };
};

export const imageurlSuccess = (image) => {
    return { type: actionTypes.IMG_URL_SUCCESS, image }
}


export const updateImageurl = (image) => {
    return dispatch => {
        dispatch(imageurlSuccess(image));
    };
};

export const updateFileFailure = (error) => {
    return {
        type: actionTypes.UPDATE_FILE_FAILURE, error
    };
};
export const updateFileSuccess = (fileDataResponse) => {
    return { type: actionTypes.UPDATE_FILE_SUCCESS, fileDataResponse }
}

export const updateFile = (file, apiURL) => {
    return dispatch => {
        let url = apiURL + `/createOrUpdateFile`;
        PostCalltoServer(url, file).then(response => {
            if (response.data.status.responseType == "success") {
                dispatch(updateFileSuccess(response.data.data))
            }
            else {
                dispatch(updateFileFailure(response.data.status.responseType))
            }
        }).catch(err => {
            dispatch(updateFileFailure(err))
        });
    };
};

export const saveCategoryStart = () => {
    return {
        type: actionTypes.SAVE_CATEGORY_START
    };
};

export const saveCategorySuccess = (status, catname) => {
    return {
        type: actionTypes.SAVE_CATEGORY_SUCCESS,
        newcatname: catname
    };
};

export const saveCategoryFail = (error) => {
    return {
        type: actionTypes.SAVE_CATEGORY_FAIL,
        error: error
    };
};

export const saveCategory = (apiURL, catname) => {
    return dispatch => {
        dispatch(saveCategoryStart());
        let category = {
            "category": catname,
            "project": localStorage.getItem("projectname"),
            "language": localStorage.getItem("language"),
        }
        let url = apiURL + `/smartfaq/category`;
        botPostCalltoServer(url, category)
            .then(response => {
                if (response.data.status.responseType == "success") {
                    dispatch(SetSuccessMessage('Category created successfully.'));
                    setTimeout(() => {
                        dispatch(getAllCategory(apiURL));
                    }, 3000);
                }
                else {
                    dispatch(saveCategoryFail(response.data.status.responseType));
                    dispatch(SetErrorMessage(response.data.errors[0].source));
                }
            })
            .catch(err => {
                dispatch(saveCategoryFail(err.message));
                dispatch(SetErrorMessage('Failed to create Category'));
            });
    };
};




export const getAllCategoryStart = () => {
    return {
        type: actionTypes.GET_ALL_CATEGORY_START
    };
};

export const getAllCategorySuccess = (catList) => {
    return {
        type: actionTypes.GET_ALL_CATEGORY_SUCCESS,
        catList: catList
    };
};

export const getAllCategoryFail = (error) => {
    return {
        type: actionTypes.GET_ALL_CATEGORY_FAIL,
        error: error
    };
};

export const getAllCategory = (apiURL) => {

    return dispatch => {
        dispatch(getAllCategoryStart());
        let project = localStorage.getItem("projectname");
        let url = apiURL + `/smartfaq/category/${project}/${localStorage.getItem("language")}`;
        GetCalltoServer(url)
            .then(response => {
                if (response.data.status.responseType == "success") {
                    dispatch(getAllCategorySuccess(response.data.data));
                }
                else {
                    dispatch(getAllCategoryFail(response.data.status.responseType));
                }
            })
            .catch(err => {
                dispatch(getAllCategoryFail(err.message));
                dispatch(SetErrorMessage('Failed to get Category'));
            });
    };
};

export const emailSuccess = (email) => {
    return { type: actionTypes.EMAIL_SUCCESS, email }
}


export const updateEmail = (email) => {
    return dispatch => {
        dispatch(emailSuccess(email));
    };
};

export const listSuccess = (list) => {
    return { type: actionTypes.LIST_SUCCESS, list }
}

export const updateList = (list) => {
    return dispatch => {
        dispatch(listSuccess(list));
    };
};
export const nodeSuccess = (node) => {
    return { type: actionTypes.NODE_SUCCESS, node }
}

export const addNode = (node) => {
    return dispatch => {
        dispatch(nodeSuccess(node));
    };
};

export const updateCategoryStart = () => {
    return {
        type: actionTypes.UPDATE_CATEGORY_START
    };
};

export const updateCategorySuccess = (status, data) => {
    return {
        type: actionTypes.UPDATE_CATEGORY_SUCCESS,
        data: data
    };
};

export const flowsSuccess = (flows) => {
    return { type: actionTypes.FLOWS_SUCCESS, flows }
}


export const updateFlows = (flows) => {
    return dispatch => {
        dispatch(flowsSuccess(flows));
    };
};

export const updateCategoryFail = (error) => {
    return {
        type: actionTypes.UPDATE_CATEGORY_FAIL,
        error: error
    };
};

export const updateCategory = (apiURL, data) => {

    return dispatch => {

        dispatch(updateCategoryStart());

        let url = apiURL + `/updateCategory`;
        PostCalltoServer(url, data)
            .then(response => {

                if (response.data.status.responseType == "success") {
                    dispatch(SetSuccessMessage('Category updated successfully.'));
                    dispatch(updateCategorySuccess(response.data.data));
                    setTimeout(() => {
                        dispatch(getAllCategory(apiURL));
                    }, 3000);
                }
                else {
                    dispatch(updateCategoryFail(response.data.status.responseType));
                    dispatch(SetErrorMessage('Failed to update Category'));
                }
            })
            .catch(err => {
                dispatch(updateCategoryFail(err.message));
                dispatch(SetErrorMessage('Failed to update Category in catch'));
            });
    };
};

export const getCategoryyTreeDataStart = () => {
    return {
        type: actionTypes.GET_CATEGORY_TREEDATA_START
    };
};

export const getCategoryyTreeDataSuccess = (treeData) => {
    return {
        type: actionTypes.GET_CATEGORY_TREEDATA_SUCCESS,
        treeData: treeData
    };
};

export const getCategoryyTreeDataFail = (error) => {
    return {
        type: actionTypes.GET_CATEGORY_TREEDATA_FAIL,
        error: error
    };
};

export const getCategoryyTreeData = (apiURL, data) => {

    return dispatch => {
        dispatch(getCategoryyTreeDataStart());

        let url = apiURL + `/getCategoryyTreeData`;

        PostCalltoServer(url, data)
            .then(response => {
                if (response.data.status.responseType == "success") {
                    dispatch(getCategoryyTreeDataSuccess(response.data.data));
                }
                else {
                    dispatch(getCategoryyTreeDataFail(response.data.status.responseType));
                }
            })
            .catch(err => {
                dispatch(getCategoryyTreeDataFail(err.message));
            });
    };
};

export const deleteCategoryFail = (error) => {
    return {
        type: actionTypes.DELETE_CATEGORY_FAIL,
        error: error
    };
};

export const deleteCategoryStart = () => {
    return {
        type: actionTypes.DELETE_CATEGORY_START
    };
};


export const delCategory = (apiURL, data) => {
    return dispatch => {
        dispatch(deleteCategoryStart());
		var categories = [];
		data.forEach(function (row){
			if(row.title)
				categories.push(row.title)
		})
        let url = apiURL + `/smartfaq/${localStorage.getItem("projectname")}/category/${[categories]}/${localStorage.getItem("language")}`;
        deleteCalltoServer(url)
            .then(response => {
                if (response.data.status.responseType == "success") {
                    dispatch(SetSuccessMessage('Category deleted successfully.'));
                    setTimeout(() => {
                        dispatch(getAllCategory(apiURL));
                    }, 3000);
                }
                else if (response.data.status.responseType == "Failure") {
                    dispatch(deleteCategoryFail(response.data.status.responseType));
                    dispatch(SetErrorMessage(response.data.errors[0].source));
                }
                else {
                    dispatch(deleteCategoryFail(response.data.status.responseType));
                    dispatch(SetErrorMessage('Failed to delete Category'));
                }
            })
            .catch(err => {
                dispatch(deleteCategoryFail(err.message));
                dispatch(SetErrorMessage('Failed to delete Category'));
            });
    };
};

export const conditionSuccess = (cndt, node) => {
    return { type: actionTypes.CONDITION_SUCCESS, cndt, node }
}

export const updateCondition = (cndt, node) => {
    return dispatch => {
        dispatch(conditionSuccess(cndt, node));
    };
};

export const ruleSuccess = (rule) => {
    return { type: actionTypes.RULE_SUCCESS, rule }
}

export const saveRule = (rule, node) => {
    return dispatch => {
        dispatch(ruleSuccess(rule));
        if (rule)
            dispatch(SetSuccessMessage(node + ' node rules saved successfully'));
    };
};


export const macrosReadFileFailure = (error) => {
    return {
        type: actionTypes.MACROS_READ_FILE_FAILURE, error
    };
};
export const macrosReadFileSuccess = (getMacrosFileData) => {
    return { type: actionTypes.MACROS_READ_FILE_SUCCESS, getMacrosFileData }
}

export const macrosReadFile = (file, apiURL) => {
    return dispatch => {
        let url = apiURL + `/readFile`;
        PostCalltoServer(url, file).then(response => {
            if (response.data.status.responseType == "success") {
                dispatch(macrosReadFileSuccess(response.data.data))
            }
            else {
                dispatch(macrosReadFileFailure(response.data.status.responseType))
            }
        }).catch(() => {
        });
    };
};

export const saveMacrosDataFailure = (error) => {
    return {
        type: actionTypes.SAVE_MACROS_DATA_FAILURE, error
    };
};
export const saveMacrosDataSuccess = (saveMacrosData) => {
    return { type: actionTypes.SAVE_MACROS_DATA_SUCCESS, saveMacrosData }
}

export const saveMacrosData = (apiURL, file) => {
    return dispatch => {
        let url = apiURL + `/macroTest`;
        PostCalltoServer(url, file).then(response => {
            if (response.data.status.responseType == "success") {
                dispatch(saveMacrosDataSuccess(response.data.data))
            }
            else {
                dispatch(saveMacrosDataFailure(response.data.status.responseType))
                dispatch(SetErrorMessage('Cannot connect to the URL'));
            }
        }).catch(err => {
            dispatch(saveMacrosDataFailure(err))
        });
    };
};

export const deleteAltQuesForFAQ = (apiURL, data) => {
    return dispatch => {
        dispatch(deleteCategoryStart());
        let url = apiURL + `/review/deleteAltQuesForFAQ`;
        PostCalltoServer(url, data)
            .then(response => {
                if (response.data.status.responseType == "success") {
                    dispatch(SetSuccessMessage('Alternate Question deleted successfully.'));
                }
                else {
                    dispatch(SetErrorMessage('Failed to delete Alternate Question'));
                }
            })
            .catch(err => {
                dispatch(deleteCategoryFail(err.message));
                dispatch(SetErrorMessage('Failed to delete Alternate Question'));
            });
    };
};

export const deleteSynonyms = (apiURL,data) => {
    return dispatch => {
        dispatch(deleteCategoryStart());
        let url = apiURL + `/cognitive/synonyms`;
        deleteCalltoServer(url,data)
            .then(response => {
                if (response.data.status.responseType == "success") {
                    dispatch(SetSuccessMessage('Synonym deleted successfully.'));
                }
                else {
                    dispatch(SetErrorMessage('Failed to delete Synonym'));
                }
            })
            .catch(err => {
                dispatch(SetErrorMessage('Failed to delete Synonym'));
            });
    };
};


export const configurationInputFailure = (error) => {
    return {
        type: actionTypes.CONFIGURATION_INPUT_FAILURE, error
    };
};
export const configurationInputSuccess = (configurationInputs) => {
    return { type: actionTypes.CONFIGURATION_INPUT_SUCCESS, configurationInputs }
}

export const ConfigurationInputs = (file, apiURL) => {
    return dispatch => {
        let url = apiURL + `/project/flow/profile`;
        botPostCalltoServer(url, file).then(response => {
            if (response.data.status.responseType == "success") {
                dispatch(SetSuccessMessage('Profile Created successfully.'));
                dispatch(configurationInputSuccess(response.data.data))
                setTimeout(() => {
                    dispatch(GetBotList(apiURL));
                }, 1500);
            }
            else {
                dispatch(configurationInputFailure(response.data.status.responseType))
            }
        }).catch(err => {
            dispatch(configurationInputFailure(err))
        });
    };
};



export const delProfileStart = () => {
    return {
        type: actionTypes.DELETE_PROFILE_START
    };
};

export const delProfileSuccess = ( profilename) => {
    return {
        type: actionTypes.DELETE_PROFILE_SUCCESS,
        profilename: profilename
    };
};

export const delProfileFail = (error) => {
    return {
        type: actionTypes.DELETE_PROFILE_FAIL,
        error: error
    };
};


export const deleteProfile = (apiURL, data) => {
    return dispatch => {
        dispatch(delProfileStart());
        let list = { ...data, "orgName": localStorage.getItem("orgName") }
        let url = apiURL + `/deleteBot`;
        PostCalltoServer(url, list)
            .then(response => {
                if (response.data.status.responseType == "success") {
                    dispatch(delProfileSuccess(data));
                    dispatch(SetSuccessMessage("Profile Deleted Succesfully!"))
                }
                else {
                    dispatch(delProfileFail('Error occured.'));
                    dispatch(SetErrorMessage('Failed to Delete Profile'));
                }
            })
            .catch(err => {
                dispatch(delProfileFail(err.message));
                dispatch(SetErrorMessage(err.message));
            });
    };
};

export const saveChatSuccess = (chatMsg, chatNode) => {
    return { type: actionTypes.SAVECHAT_SUCCESS, chatMsg, chatNode }
}
export const saveChatData = (chatMsg, node) => {
    return dispatch => {
        dispatch(saveChatSuccess(chatMsg, node));
    };
};

export const bConfigurationFailure = (error) => {
    return {
        type: actionTypes.B_CONFIGURATION_FAILURE, error
    };
};
export const bConfigurationSuccess = (bConfiguration) => {
    return { type: actionTypes.B_CONFIGURATION_SUCCESS, bConfiguration }
}

export const BConfiguration = (file, apiURL) => {
    return dispatch => {
        let url = apiURL + `/bConfiguration`;
        PostCalltoServer(url, file).then(response => {
            if (response.data.status.responseType == "success") {
                dispatch(bConfigurationSuccess(response.data.data))
                dispatch(SetSuccessMessage('Bot config has been saved successfully.'));
            }
            else {
                dispatch(bConfigurationFailure(response.data.status.responseType))
                dispatch(SetErrorMessage('failed to save bot config.'));
            }
        }).catch(err => {
            dispatch(bConfigurationFailure(err))
            dispatch(SetErrorMessage('failed to save bot config.'));
        });
    };
};
export const removeNodeSuccess = (removeNode) => {
    return { type: actionTypes.REMOVE_NODE_SUCCESS, removeNode }
}

export const removeNode = (node) => {
    return dispatch => {
        dispatch(removeNodeSuccess(node));
    };
};

export const videoSuccess = (video) => {
    return { type: actionTypes.VIDEO_SUCCESS, video}
}

export const updateVideo = (video) => {
    return dispatch => {
        dispatch(videoSuccess(video));
    };
};


export const DeleteTrigger = (intents, apiURL) => {
    return dispatch => {
        dispatch(triggerStart());
        let intent = { ...intents, "username": localStorage.getItem('userName'), "orgName": localStorage.getItem("orgName") }
        let url = apiURL + `/project/flow/triggerType`;
        PostCalltoServer(url, intent).then(response => {
            if (response.data.status.responseType == "success") {
                dispatch(SetSuccessMessage('TriggerType deleted successfully.'));
            }
            else {
                dispatch(triggerFailure(response.status))
            }
        }).catch(err => {
            dispatch(triggerFailure(err))
        });
    };
};
export const changeProjectAction = (projectname) => {
    return { type: actionTypes.changeProjectActionType, projectname }
}
export const changeTabAction = (tab) => {
    return { type: actionTypes.INEDITFLOWTAB, tab }
}
export const CarouselSuccess = (CarouselInput) => {
    return { type: actionTypes.CAROUSEL_SUCCESS, CarouselInput }
}

export const updateCarousel = (data) => {
   return dispatch => {
       dispatch(CarouselSuccess(data));
   };
};


export const createCuxBotSuccess = (botid) => {
    return {
        type: actionTypes.CREATE_CUXBOT_SUCCESS,
        flowid: botid
    };
};

export const createCuxBotFailure = (error) => {
    return {
        type: actionTypes.CREATE_CUXBOT_FAILURE,
        error: error
    };
};

export const CreateCuxBot = (apiURL, bot) => {
    let url = apiURL + '/flowframework/flow?lang='+localStorage.getItem('language');
    return dispatch => {
        dispatch(cuxBotStart());
        PostCalltoServer(url, bot)
        .then((response)=> {
            if(response.status === 200) {
                setTimeout(() => {
                    dispatch(GetCuxBotList(apiURL, bot.projectname));
                }, 1500);
                dispatch(createCuxBotSuccess(response.data));
                dispatch(SetSuccessMessage('Flow created successfully.'));
            } else {
                dispatch(createCuxBotFailure('Failed to add flow item'));
                dispatch(SetErrorMessage('Failed to add flow item'));
            }
        })
        .catch(err => {
            dispatch(createCuxBotFailure(err.message));
            dispatch(SetErrorMessage(err.message));
        }); 
    }
}

export const GetCuxBotData = (apiURL, botid) => {
    let url = apiURL + '/flowframework/flow/' + botid +'?lang='+localStorage.getItem('language');
    return dispatch => {
        dispatch(cuxBotStart());
        GetCalltoServer(url)
        .then((response) => {
            if(response.status === 200) {
                if(response.data.flowcontent?.length > 0) {
                    dispatch(getCuxBotDataSuccess(response.data.flowcontent));
                }
                else {
                    // dispatch(getCuxBotDataSuccess(defaultData));
                    dispatch(getCuxBotDataFailure(''));
                }
                
            } else {
                dispatch(getCuxBotDataFailure('Failed to fetch flow details'));
                dispatch(SetErrorMessage('Failed to fetch flow details'));
            }
                
        }) .catch ((err) => {
            dispatch(getCuxBotDataFailure(err));
            dispatch(SetErrorMessage('Failed to fetch flow details'));
            }
        )
    }
}

export const UpdateCuxBotName = (apiURL, flowid, flowname, projectname) => {
    let url = apiURL + '/flowframework/flow/update/name/' + flowid +'?lang='+localStorage.getItem('language');
    let data = {flowname:flowname};
    return dispatch => {
        dispatch(cuxBotStart());
        UpdateCalltoServer(url, data)
        .then((response)=> {
            if(response.status === 200) {
                dispatch(updateCuxBotDataSuccess());  
                setTimeout(() => {
                    dispatch(GetCuxBotList(apiURL, projectname));
                }, 1500);            
                // dispatch(SetSuccessMessage('Flow details updated successfully.'));
            } else {
                dispatch(updateCuxBotDataFailure('Failed to update flow details'));
                // dispatch(SetErrorMessage('Failed to update flow details'));
            }
        })
        .catch(err => {
            dispatch(updateCuxBotDataFailure(err.message));
            dispatch(SetErrorMessage(err.message));
        }); 
    }
}

export const UpdateCuxBotData = (apiURL, flowid, data) => {
    let url = apiURL + '/flowframework/flow/' + flowid +'?lang='+localStorage.getItem('language');
    return dispatch => {
        dispatch(cuxBotStart());
        UpdateCalltoServer(url, data)
        .then((response)=> {
            if(response.status === 200) {  
                dispatch(updateCuxBotDataSuccess());              
                dispatch(SetSuccessMessage('Flow details updated successfully.'));
            } else {
                dispatch(updateCuxBotDataFailure('Failed to update flow details'));
                dispatch(SetErrorMessage('Failed to update flow details'));
            }
        })
        .catch(err => {
            dispatch(updateCuxBotDataFailure(err.message));
            dispatch(SetErrorMessage(err.message));
        }); 
    }
} 

export const GetCuxBotList = (apiURL, project) => {
    let url = apiURL + '/flowframework/flow?projectname=' + project +'&lang='+localStorage.getItem('language');
    return dispatch => {
        dispatch(cuxBotStart());
        GetCalltoServer(url)
        .then((response) => {
            if(response.status === 200) {
                dispatch(getCuxBotListSuccess(response.data));
            } else {
                dispatch(getCuxBotListFailure('Failed to fetch flow list'));
                dispatch(SetErrorMessage('Failed to fetch flow list'));
            }
                
        }) .catch ((err) => {
            dispatch(getCuxBotListFailure(err));
            dispatch(SetErrorMessage('Failed to fetch flow list'));
            }
            
        )
    }
}

export const GetCuxTemplates = (apiURL, nodeType) => {
    let url = apiURL + '/flowframework/flow/search/template/'+localStorage.getItem('projectname')+'/'+nodeType +'?lang='+localStorage.getItem('language');

    return dispatch => {
        GetCalltoServer(url)
        .then((response) => {
            if(response.status === 200) {
                dispatch(onGetCuxTemplatesSuccess(response.data, nodeType));
            } else {
                dispatch(cuxTemplatesFailure('Failed to fetch template list'));
                // dispatch(SetErrorMessage('Failed to fetch template list'));
            }
                
        }) .catch ((err) => {
            dispatch(cuxTemplatesFailure(err));
            // dispatch(SetErrorMessage('Failed to fetch template list'));
            }
        )
    }
    
}

export const ClearCuxTemplates = () => {
    return dispatch => {
        dispatch({type: actionTypes.CLEAR_CUXTEMPLATES_SUCCESS});
    }
}

export const onGetCuxTemplatesSuccess = (data, type) => {
    return {
        type: actionTypes.GET_CUXBOT_TEMPLATES_SUCCESS,
        nodeTemplate: data, 
        nodeType: type
    };
};

export const onCreateCuxTemplateSuccess = (id) => {
    return {
        type: actionTypes.CREATE_CUXTEMPLATE_SUCCESS,
        flowid: id,
    }
}

export const cuxTemplatesFailure = (error) => {
    return {
        type: actionTypes.CUXTEMPLATE_FAILURE,
        error: error
    };
};

export const getMessage = (type) => {
    if (type === 'flow') {
        return 'Flow saved as template successfully';
    } else {
        return 'Template saved successfully';
    }
}

export const CreateCuxTemplate = (apiURL, type, data) => {
    let url = apiURL + '/flowframework/flow/update/template?lang='+localStorage.getItem('language');
    return dispatch => {
        dispatch(cuxBotStart());
        PostCalltoServer(url, data)
        .then((response)=> {
            if(response.status === 201 || response.status === 200) {
                dispatch(response.status === 201 ? SetSuccessMessage(getMessage(type)):SetSuccessMessage('Template updated successfully'));
                dispatch(onCreateCuxTemplateSuccess(data.data.flowid));
                setTimeout(() => {
                    dispatch(GetCuxTemplates(apiURL, type));
                }, 2500);                
            } else {
                dispatch(cuxTemplatesFailure('Failed to save template'));
                dispatch(SetErrorMessage('Failed to save template'));
            }
        })
        .catch(err => {
            dispatch(cuxTemplatesFailure(err.message));
            dispatch(SetErrorMessage(err.message));
        }); 
        
    }
}

export const CreateCuxFlowTemplate = (apiURL, flowid, flowname) => {
    return dispatch => {
        dispatch(cuxBotStart());
        // dispatch(GetCuxBotData(apiURL, flowid));
        let templateData = {
            type: 'flow',
            project: localStorage.getItem('projectname'),
            name: flowname,
            data: {flowid:flowid, flowname:flowname}
        };
        dispatch(CreateCuxTemplate(apiURL, 'flow', templateData));
    }
}

export const DeleteCuxTemplate = (apiURL, id, type) => {
    let url = null;
    if(type !== 'flow') {
        url = apiURL + '/flowframework/flow/template/' + id +'?lang='+localStorage.getItem('language');
    } else {
        url = apiURL + '/flowframework/flow/template/delete/' + localStorage.getItem('projectname') + '/' + id +'?lang='+localStorage.getItem('language');
    }

    return dispatch => {
        dispatch(cuxBotStart());
        deleteCalltoServer(url)
        .then((response) => {
            if(response.status === 200) {
                dispatch(SetSuccessMessage('Template deleted successfully'));
                setTimeout(() => {
                    dispatch(GetCuxTemplates(apiURL, type));
                }, 2500);
            } else {
                dispatch(cuxTemplatesFailure('Failed to delete template'));
                dispatch(SetErrorMessage('Failed to delete template'));
            }

        })
        .catch(err => {
            dispatch(cuxTemplatesFailure(err.message));
            dispatch(SetErrorMessage(err.message));
        });                    
    }
}

export const delCuxBotSuccess = (botid) => {
    return {
        type: actionTypes.DELETE_CUXBOT_SUCCESS,
        flowid: botid
    };
};

export const delCuxBotFail = (error) => {
    return {
        type: actionTypes.DELETE_CUXBOT_FAILURE,
        error: error
    };
};
export const DeleteCuxBot = (apiURL, botid, calledFrom) => {
    let url = apiURL + '/flowframework/flow/' + botid +'?lang='+localStorage.getItem('language');
    return dispatch => {
        dispatch(cuxBotStart());
        deleteCalltoServer(url)
        .then((response) => {
            if(response.status === 200) {
                if(calledFrom !== 'template') {
                    dispatch(SetSuccessMessage('Flow deleted successfully.'));
                }
                dispatch(delCuxBotSuccess(botid));
                dispatch(GetCuxBotList(apiURL,localStorage.getItem("projectname")))
            } else {
                dispatch(delCuxBotFail('Error occured.'));
                dispatch(SetErrorMessage('Failed to delete flow'));
            }

        })
        .catch(err => {
            dispatch(delCuxBotFail(err.message));
            dispatch(SetErrorMessage(err.message));
        });              
    }
}

export const getCuxBotDataSuccess = (data) => {
    return { type: actionTypes.GET_CUXBOT_DATA_SUCCESS, cuxBotData: data }
}

export const getCuxBotDataFailure = (error) => {
    return {
        type: actionTypes.GET_CUXBOT_DATA_FAILURE, error
    };
};

export const updateCuxBotDataSuccess = () => {
    return { 
        type: actionTypes.UPDATE_CUXBOT_DATA_SUCCESS
    }
}

export const updateCuxBotDataFailure = (error) => {
    return {
        type: actionTypes.UPDATE_CUXBOT_DATA_FAILURE, error
    };
};

export const getCuxBotListSuccess = (data) => {
    return { type: actionTypes.GET_CUXBOT_LIST_SUCCESS, cuxBotList: data }
}

export const getCuxBotListFailure = (error) => {
    return {
        type: actionTypes.GET_CUXBOT_LIST_FAILURE, error
    };
};

export const CreateCuxTrigger = (apiURL, data, flowid) => {
    let url = apiURL + '/flowframework/flow/' + flowid +'/trigger?lang='+localStorage.getItem('language');
    return dispatch => {
        dispatch(triggerStart());
        PostCalltoServer(url, data)
        .then((response)=> {
            if(response.status === 200) {
                
                dispatch(SetSuccessMessage('Trigger created successfully.'));
                
                setTimeout(() => {
                    dispatch(GetCuxTriggerList(apiURL, flowid));
                }, 1500);                
            } else {
                dispatch(createCuxTriggerFailure('Failed to add trigger'));
                dispatch(SetErrorMessage('Failed to add trigger'));
            }
        })
        .catch(err => {
            dispatch(createCuxTriggerFailure(err.message));
            dispatch(SetErrorMessage(err.message));
        }); 
    }} 

export const UpdateCuxTrigger = (apiURL, data, flowid, triggerId) => {
    let url = apiURL + '/flowframework/flow/' + flowid + '/trigger/' + triggerId +'?lang='+localStorage.getItem('language');
    return dispatch => {
        dispatch(triggerStart());
        UpdateCalltoServer(url, data)
        .then((response)=> {
            if(response.status === 200) {                
                dispatch(SetSuccessMessage('Trigger updated successfully.'));
                
                setTimeout(() => {
                    dispatch(GetCuxTriggerList(apiURL, flowid));
                }, 1500);                
            } else {
                dispatch(updateCuxTriggerFailure('Failed to update trigger'));
                dispatch(SetErrorMessage('Failed to update trigger'));
            }
        })
        .catch(err => {
            dispatch(updateCuxTriggerFailure(err.message));
            dispatch(SetErrorMessage(err.message));
        }); 
    }
} 

export const DeleteCuxTrigger = (apiURL, flowid, triggerId) => {
    let url = apiURL + '/flowframework/flow/' + flowid + '/trigger/' + triggerId +'?lang='+localStorage.getItem('language');
    return dispatch => {
        dispatch(triggerStart());
        deleteCalltoServer(url)
        .then((response)=> {
            if(response.status === 200) {                
                dispatch(SetSuccessMessage('Trigger deleted successfully.'));
                
                setTimeout(() => {
                    dispatch(GetCuxTriggerList(apiURL, flowid));
                }, 1500);                
            } else {
                dispatch(updateCuxTriggerFailure('Failed to delete trigger'));
                dispatch(SetErrorMessage('Failed to delete trigger'));
            }
        })
        .catch(err => {
            dispatch(updateCuxTriggerFailure(err.message));
            dispatch(SetErrorMessage(err.message));
        }); 
    }
} 

export const GetCuxTriggerList = (apiURL, flowid) => {
    let url = apiURL + '/flowframework/flow/' + flowid +'/trigger?lang='+localStorage.getItem('language');
    return dispatch => {
        dispatch(triggerStart());
        GetCalltoServer(url)
        .then((response) => {
            if(response.status === 200) {
                dispatch(getCuxTriggerListSuccess(response.data));
            } else {
                dispatch(getCuxTriggerListFailure('Failed to fetch flow list'));
                dispatch(SetErrorMessage('Failed to fetch flow list'));
            }
                
        }) .catch ((err) => {
            dispatch(getCuxTriggerListFailure(err));
            dispatch(SetErrorMessage('Failed to fetch flow list'));
            }
            
        )
    }
}

export const getCuxTriggerListSuccess = (data) => {
    return { type: actionTypes.GET_CUXTRIGGER_LIST_SUCCESS, cuxTriggerList: data }
}

export const getCuxTriggerListFailure = (error) => {
    return {
        type: actionTypes.GET_CUXTRIGGER_LIST_FAILURE, error
    };
};

export const createCuxTriggerFailure = (error) => {
    return {
        type: actionTypes.CREATE_CUXTRIGGER_FAILURE,
        error: error
    };
};

export const updateCuxTriggerFailure = (error) => {
    return {
        type: actionTypes.UPDATE_CUXTRIGGER_FAILURE,
        error: error
    };
};

export const UpdateCuxActiveFieldSuccess = (data) => {
    return { type: actionTypes.UPDATE_CUX_FORM_ACTIVENODE, activeField: data }
}

export const UpdateCuxFormActiveField = (data) => {
    return dispatch => {
        dispatch(UpdateCuxActiveFieldSuccess(data));
    }
    
} 
export const updateProjectDescription = (apiURL,payload) => {
    let url = apiURL + '/project/updateProjectDescription'
    return dispatch => {
        PostCalltoServer(url,payload)
        .then((response) => {
            if(response.status === 201) {
                dispatch(SetSuccessMessage("Project description updated successfully."))
                dispatch(ProjectDescriptionSuccess(response.data));
            } else {
                dispatch(ProjectDescriptionFailure(response.errors));
                dispatch(SetErrorMessage('Failed to update description'))
            }

        })
        .catch(err => {
            dispatch(ProjectDescriptionFailure(err));
            dispatch(SetErrorMessage('Failed to update description'));
        });              
    }
}

export const ProjectDescriptionSuccess = (data) => {
    return { type: actionTypes.PROJECTDESCRIPTION_SUCCESS, projectdescription: data }
}

export const ProjectDescriptionFailure = (error) => {
    return {
        type: actionTypes.PROJECTDESCRIPTION_FAILURE, error
    };
};
export const saveFAQFromUrlStart = (status) => {
    return {
        type: actionTypes.GET_FAQ_FROM_URL_START,
        Data:status
    };
};
export const saveFAQFromUrlSuccess = (status) => {
    return {
        type: actionTypes.GET_FAQ_FROM_URL_SUCCESS,
    };
};
export const saveFAQFromUrlFail = (error) => {
    return {
        type: actionTypes.GET_FAQ_FROM_URL_FAIL,
    };
};

export const saveFAQFromUrl = (apiURL,payload,Url) => {
    return dispatch => {
        dispatch(saveFAQFromUrlStart(true));
        PostCalltoServer(apiURL,payload)
        .then((response) => {
            if(response.data.data == "success") {
                dispatch(saveFAQFromUrlStart(false));
                dispatch(SetSuccessMessage("FAQ URL upload successfully."))
                let projectName = localStorage.getItem("projectname");
                    let language = localStorage.getItem("language");
                    if (!language) {language = "en"; }
                    var requestObject = { "projectName": projectName, "language": language };
                    dispatch(GetKnowledgeIntent(requestObject, Url));
                    dispatch(GetBotList(Url));
                    dispatch(getAllCategory(Url));
            } else {
                dispatch(saveFAQFromUrlStart(false));
                dispatch(SetErrorMessage('Please try again with a valid URL'))
            }

        })
        .catch(err => {
            dispatch(saveFAQFromUrlStart(false));
            dispatch(SetErrorMessage('Please try again with a valid URL'));
        });              
    }
}

export const projectHeaderShow = (showProjectHeader) => {
    return { type: actionTypes.PROJECT_HEADER_SHOW, showProjectHeader }
}

export const getIntentDescriptionFailure = (error) => {
    return {
        type: actionTypes.GET_INTENT_DESCRIPTION_FAIL,
        error: error
    };
};

export const getIntentDescriptionSuccess = (data) => {
    return { type: actionTypes.GET_INTENT_DESCRIPTION_SUCCESS, intentDescription: data }
}
 
export const getIntentDescription = (apiUrl) => {
    return dispatch => {
        GetCalltoServer(apiUrl)
        .then((response) => {
            if(response.data.status.responseType == "success") {
                dispatch(getIntentDescriptionSuccess(response.data.data));
            } else {
                dispatch(getIntentDescriptionFailure(response.errors));
            }

        })
        .catch(err => {
            dispatch(ProjectDescriptionFailure(err));
        });              
    }
}

export const GenerateAIPhrase = () => {
    return dispatch => {
        dispatch(IntentStart());
        setTimeout(() => {
            dispatch(updateCuxBotDataSuccess()); //using existing method to update generic status, ie to set loading as false.
        }, 2000);
    }
    
}

export const getContinousDataStart = (load) => {
    return {
        type: actionTypes.GET_CONTINUOUS_DATA_START,
        loading:load
    };
};

export const getContinousDataFail = (errors) => {
    return {
        type: actionTypes.GET_CONTINUOUS_DATA_FAIL,
        error:errors
    };
};

export const getContinousDataSuccess = (data) => {
    return {
        type: actionTypes.GET_CONTINUOUS_DATA_SUCCESS,
        continuousData: data
    };
};

export const getContinuousData = (apiURL,project) => {
    return dispatch => {
        let url = apiURL + `/continuous-learning/va/` + project;
        dispatch(getContinousDataStart(true))
        GetCalltoServer(url)
            .then(response => {
                if (response.data.status.responseType == "success") {
                    dispatch(getContinousDataSuccess(response.data.data));
                    dispatch(getContinousDataStart(false))
                }
                else {
                    dispatch(getContinousDataFail(response.data.status.responseType))
                    dispatch(getContinousDataStart(false))
                }
            }).catch(err => {
                dispatch(getContinousDataFail(err.message))
            });
    };
};

export const saveContinousDataSuccess = (data) => {
    return {
        type: actionTypes.SAVE_CONTINUOUS_DATA_SUCCESS,
        //continuousData: data
    };
};

export const saveContinuousData = (apiURL, payload) => {
    return dispatch => {
        let url = apiURL + `/continuous-learning/multiple`;
        PostCalltoServer(url, payload)
            .then(response => {
                if (response.data.status.responseType == "success") {
                    // dispatch(getContinuousData(apiURL));
                    dispatch(setSucMessage("continuous Learning document updated successfully"))
                    // dispatch(getContinousDataSuccess(response.data.data));
                    // dispatch(getContinousDataStart(false))
                }
                else {
                    // dispatch(getContinousDataFail(response.data.status.responseType))
                    // dispatch(getContinousDataStart(false))
                }
            }).catch(err => {
                //dispatch(getBotListFailure(err.message))
            });
    };
};

export const getWaitingForApprovalIntentStart = (load) => {
    return {
        type: actionTypes.GET_WAITING_FOR_APPROVAL_INTENT_START,
        waitinForApprovalLoading:load
    };
};

export const getWaitingForApprovalIntentFail = (errors) => {
    return {
        type: actionTypes.GET_WAITING_FOR_APPROVAL_INTENT_FAIL,
        error:errors
    };
};

export const getWaitingForApprovalIntentSuccess = (data) => {
    return { type: actionTypes.GET_WAITING_FOR_APPROVAL_INTENT_SUCCESS, waitinForApprovalData: data }
}
 
export const getWaitingForApprovalIntent = (apiUrl) => {
    return dispatch => {
        getWaitingForApprovalIntentStart(true)
        GetCalltoServer(apiUrl)
        .then((response) => {
            if(response.data.status.responseType == "success") {
                dispatch(getWaitingForApprovalIntentSuccess(response.data.data));
                getWaitingForApprovalIntentStart(false)
            } else {
                dispatch(getWaitingForApprovalIntentFail(response.errors));
                getWaitingForApprovalIntentStart(false)
            }

        })
        .catch(err => {
            dispatch(getWaitingForApprovalIntentFail(err));
            getWaitingForApprovalIntentStart(false)
        });              
    }
}

export const getWhatsappConfigDataSuccess = (data) => {
    return { 
        type: actionTypes.GET_WHATSAPP_DATA_SUCCESS,
        data: data
    }
}

export const getWhatsappConfigData = (apiURL) => {
    let url = apiURL + '/whatsapp-webhook/'+orgName+'/virtual-agents';
    return dispatch => {
        GetCalltoServer(url)
        .then((response) => {
            if(response.status === 200) {
                dispatch(getWhatsappConfigDataSuccess(response.data));
            } else {
                dispatch(actionFailure('Failed to fetch Whatsapp integration data'));
                dispatch(SetErrorMessage('Failed to fetch Whatsapp integration data'));
            }
                
        })
        .catch ((err) => {
            dispatch(actionFailure(err));
            dispatch(SetErrorMessage('Failed to fetch Whatsapp integration data'));
        })
    }
}

export const saveWhatsappConfigData = (apiURL, data) => {

    let url = apiURL + '/whatsapp-webhook/'+orgName+'/virtual-agents';
    return dispatch => {
        dispatch(actionStart());
        UpdateCalltoServer(url, data)
            .then((response)=> {
                if(response.status === 200) {
                    dispatch(actionSuccess());  
                    setTimeout(() => {
                        dispatch(getWhatsappConfigData(apiURL));
                    }, 1500);            
                    dispatch(SetSuccessMessage('WhatsApp Integration data saved successfully.'));
                } else {
                    dispatch(actionFailure('Failed to update WhatsApp Integration data'));
                    dispatch(SetErrorMessage('Failed to update WhatsApp Integration data'));
                }
            })
            .catch(err => {
                dispatch(actionFailure(err.message));
                dispatch(SetErrorMessage(err.message));
        }); 
    }
}
export const saveApproveFlow = (url, payload) => {
    return dispatch => {
        PostCalltoServer(url, payload)
            .then(response => {
                if (response.data.status.responseType == "success") {
                    dispatch(SetSuccessMessage('Flow created successfully.'));
                }
                else {
                    dispatch(SetErrorMessage('Flow not created successfully.'));
                }
            }).catch(err => {
                // dispatch(SetErrorMessage(resp.data.errors));
                dispatch(SetErrorMessage('Flow already exists.'));
            });
    };
};

export const saveApproveIntent = (url, payload) => {
    return dispatch => {
        PostCalltoServer(url, payload)
            .then(response => {
                if (response.data.status.responseType == "success" ) {
                    dispatch(SetSuccessMessage('Intent created successfully.'));
                }
                else {
                    dispatch(SetErrorMessage('Intent not created successfully.'));
                }
            }).catch(err => {
                dispatch(SetErrorMessage('Intent already exists.'));
                //dispatch(SetErrorMessage(err.message));
            });
    };
};

export const copyChannelFlow = (apiURL, data, flowid) => {
    let url = apiURL + '/flowframework/projects/'+ localStorage.getItem('projectname') + '/flows/' + flowid + '/copy'
    return dispatch => {
        dispatch(actionStart());
        PostCalltoServer(url, data)
        .then((response) => {
            if(response.status === 200) {
                setTimeout(() => {
                    dispatch(copyChannelSuccess());
                }, 1000);                
                dispatch(SetSuccessMessage('Flow details copied successfully'));
            } else {
                dispatch(SetErrorMessage('Flow details copy failed'));
            }
        }).catch(err => {
            dispatch(SetErrorMessage(err.message));
        })
    }
}

export const copyChannelSuccess = () => {
    return { 
        type: actionTypes.COPY_CHANNEL_SUCCESS
    }
}

export const approveFAQ = (url, payload) => {
    return dispatch => {
        PostCalltoServer(url, payload)
        .then((response) => {
            if(response.data.status.responseType === 'success') {               
                dispatch(SetSuccessMessage(response.data.data));
            } else {
                dispatch(SetErrorMessage('FAQ not added successfully'));
            }
        }).catch(err => {
            dispatch(SetErrorMessage('FAQ not added successfully'));
            // dispatch(SetErrorMessage(err.message));
        })
    }
}