import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../utility';


const initialState = {
    sessiontoken: null,
    userName: null,
    error: null,
    loading: false,
    isLoggedin:false,
    authRedirectPath: '/'
};

const authStart = ( state ) => {
    return updateObject( state, { error: null, loading: true } );
};

const authSuccess = (state, action) => {
    return updateObject( state, { 
        sessiontoken: action.sessiontoken,
        userName: action.userName,
        error: null,
        loading: false,
        authRedirectPath:!action.userRequestedURL?'projectview':action.userRequestedURL
     } );
};

const authFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
};

const createUserSuccess = (state, action) => {
    return updateObject( state, { 
        userCreated: action.user,
        error: null,
        loading: false
     } );
};

const createUserFailure = (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
};

const authLogout = (state) => {
    return updateObject(state, { sessiontoken: null, userName: null });
};

const setAuthRedirectPath = (state, action) => {
    return updateObject(state, { authRedirectPath: action.path })
}

const updateUserLoginSuccess = ( state ) => {
    return updateObject( state, { error: null, loading: true, isLoggedin:true } );
};

const updateUserLoginFailure = ( state, action ) => {
    return updateObject( state, {
        error: action.error,
        loading: false,
        isLoggedin:false
    });
};

const getCountryTimeZoneSuccess = (state, action) => {
    return updateObject( state, { 
        countryDetails:action.countryDetails
     } );
};

const getCountryTimeZoneFailure = (state, action) => {
    return updateObject( state, {
        error: action.error,
    });
};

const getCmmStatusSuccess = (state, action) => {
    return updateObject( state, { 
        cmmLoginStatus: action.cmmLoginStatus,
        error: null,
        loading: false
     } );
};

const getCmmStatusFailure = (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
};


const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.AUTH_START: return authStart(state);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_FAIL: return authFail(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state);
        case actionTypes.SET_AUTH_REDIRECT_PATH: return setAuthRedirectPath(state,action);
        case actionTypes.CREATE_USER_SUCCESS: return createUserSuccess(state, action);
        case actionTypes.CREATE_USER_FAIL: return createUserFailure(state, action);
        case actionTypes.UPDATE_USER_LOGIN_SUCCESS: return updateUserLoginSuccess(state);
        case actionTypes.UPDATE_USER_LOGIN_FAILURE: return updateUserLoginFailure(state, action);
        case actionTypes.GET_COUNTRY_DETAILS_SUCCESS: return getCountryTimeZoneSuccess(state, action);
        case actionTypes.GET_COUNTRY_DETAILS_FAILURE: return getCountryTimeZoneFailure(state, action);
        case actionTypes.GET_CMM_LOGIN_SUCCESS: return getCmmStatusSuccess(state, action);
        case actionTypes.GET_CMM_LOGIN_FAILURE: return getCmmStatusFailure(state, action);
        default:
            return state;
    }
};

export default reducer;