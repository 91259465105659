import React, { useState, useCallback, useEffect } from 'react';
import { Row, Col, Form } from "react-bootstrap";
import './Drawer.css';
import ButtonCustom from "../../../../components/UIComponents/Button/ButtonComponent";
import InputWithLabelComponent from "../../../SettingPage/SettingInnerPages/components/InputWithLabelComponent/InputWithLabelComponent";
import TextAreaWithLabelComponent from "../../../SettingPage/SettingInnerPages/components/TextAreaWithLabelComponent/TextAreaWithLabelComponent";
import { useDropzone } from "react-dropzone";
import BadgeCustom from "../../../../components/UIComponents/Badge/BadgeComponent";

const EmailNodeDetails = ({config, data, handleExternalSubmit, variable, invalidVarMsg}) => {

    const [emailData, setEmailData] = useState({
        from: data?.from?data.from:config.FROM_EID+'@'+config.FROM_EDOMAIN,
        to: data?.to?data.to:'',
        subject: data?.subject?data.subject:'',
        body: data?.body?data.body:'',
        attachments: data?.attachments?data.attachments:[],
        IsReference: data?.IsReference?data.IsReference:false,
      });
    const [radioBtn, setRadioBtn] = useState();
    const [files, setFiles] = useState(data?.attachments?data.attachments:[]); 
    let defaultRefFile = {filename:'', filedata:''};

    useEffect(()=> {
        data?.IsReference ? setRadioBtn('passByVar') : setRadioBtn('manual');
    },[]);

    const addRefAttachments = () => {
        setFiles((prev)=>[...prev,defaultRefFile]);
    }

    const handleOptionChange = (e) => {
        let option = e.target.value;
        setRadioBtn(option);
        if(option !== 'manual') {
            setFiles([defaultRefFile]);
        } else setFiles([]);
    }

    const onDrop = useCallback(acceptedFiles => {
    // this callback will be called after files get dropped, we will get the acceptedFiles. If you want, you can even access the rejected files too
      
        acceptedFiles.forEach(file => {
            // Initialize FileReader browser API
            const reader = new FileReader();
            // onload callback gets called after the reader reads the file data
            reader.onload = function(e) {
              // add the image into the state. Since FileReader reading process is asynchronous, its better to get the latest snapshot state (i.e., prevState) and update it. 
              setFiles(prevState => [
                ...prevState,
                {filename: file.name, filedata: e.target.result }
              ]);
            };

            
        // Read the file as Data URL (since we accept only images)
        reader.readAsDataURL(file);
        return file;
    });
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: true
    });

    function onChangeHandler (type, value) {
        setEmailData((item) => ({
            ...item,
            [type]: value,
          }));
    }

    const isFormValid = () => {
        return variable && !invalidVarMsg && emailData.to && emailData.subject && emailData.body;
    }

    const saveData = () => {
        let data = emailData;
        data.IsReference = radioBtn === 'manual' ? false : true;
        data.attachments = files;
        handleExternalSubmit('email', data);
    }

    const attachmentsUpdate = (index, key, value) => {
        let tempArray = [...files];
        tempArray[index] = {...tempArray[index], [key]:value};
        setFiles(tempArray);
    }

    const reduceCount = (index) => {
        setFiles(files.filter((_,i)=> i !== index));
    }

    function renderReferenceAttachments(item, index) {
        return (
            <Row key={'attachment'+index}>
                <Col md={5} className='pd-0'>
                <InputWithLabelComponent
                        type="text"
                        name={item.filename}
                        label=""
                        showLabel={false}
                        value={item.filename}
                        readOnly={false}
                        onChange={(e) => attachmentsUpdate(index,'filename',e.target.value)}
                        required={false}
                        placeholder='Attachment name'
                    />
                </Col>
                <Col md={5} className='pd-0 ml-16'>
                <InputWithLabelComponent
                        type="text"
                        name={item.filedata}
                        label=""
                        showLabel={false}
                        value={item.filedata}
                        readOnly={false}
                        onChange={(e) => attachmentsUpdate(index,'filedata',e.target.value)}
                        required={false}
                        placeholder='Attachment data'
                    />
                </Col>
                
                <Col md={1} className='header-box-button minusBtn-align ml-16'>
                    <div onClick={()=>reduceCount(index)}>
                      <img className='httpbody-more-btn' src="./Icons/removeCircleBlack.svg"/>    
                    </div>     
                </Col>
            </Row>
        )
        
    }

    return (
        <div>
            <div className="mt-1">
                <Col md={12}>
                    <InputWithLabelComponent
                        type="text"
                        name="fromAddress"
                        label = "From email id"
                        value={emailData.from}
                        // value={config.FROM_EMAIL}
                        readOnly={true}
                        onChange={(e) => onChangeHandler('from',e.target.value)}
                        required={true}
                  />
                </Col>
            </div>
            <div className="mt-1">
                <Col md={12}>
                    <InputWithLabelComponent
                        type="text"
                        name="toAddress"
                        label = "To email id*"
                        value={emailData.to}
                        readOnly={false}
                        onChange={(e) => onChangeHandler('to',e.target.value)}
                        required={true}
                  />
                </Col>
            </div>
            <div className="mt-1">
                <Col md={12}>
                    <InputWithLabelComponent
                        type="text"
                        name="subject"
                        label = "Subject of email*"
                        value={emailData.subject}
                        readOnly={false}
                        onChange={(e) => onChangeHandler('subject',e.target.value)}
                        required={true}
                  />
                </Col>
            </div>
            <div className="mt-1">
                <Col md={12}>
                    <TextAreaWithLabelComponent 
                        label="Body of email*"
                        name="emailbody"
                        required ={false}
                        value={emailData.body}
                        className='text-area'
                        placeholder='EMail body.'
                        onChange={(e) => onChangeHandler('body',e.target.value)}
                        />
                </Col>
            </div>
            
            <div className="mt-1">
                <Col md={12}>
                <Form.Label className="mb-1 text-box-title adding-other-input">Attachment for email</Form.Label>
                
            <div>
                <input type="radio" id="manual" name="inputMethod" value="manual" checked={radioBtn === 'manual'} onChange={handleOptionChange}/>
                <label >Manual</label>
                <input type="radio" id="passByVar" name="inputMethod" value="passByVar" checked={radioBtn === 'passByVar'} onChange={handleOptionChange}/>
                <label >Reference variable</label><br/>
            </div>
            
            {radioBtn === 'manual' ? (
                <>
                <div className='dnd-upload' {...getRootProps()}>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                        <div className="dropzone-content">Release to drop the files here</div>
                        ) : (
                        <div className="dropzone-content">Drag & Drop files here or click to select file(s)</div>
                    )} 
                </div>
                <div className='mt-3'>{files.map((data, index)=>{
                    return <BadgeCustom label={data.filename} clicked={()=>reduceCount(index)}/>})}</div>
                </>
            ) : (
                <>
                <Row className="http-form-row">
                    <Col md={3} className="http-label">
                        <Form.Label className="mb-1 text-box-title adding-other-input">Attachments</Form.Label>
                    </Col>
                    <Col md={1} className='header-box-button plusBtn-align'>
                        <div onClick={addRefAttachments}>
                            <img className='addBtn-img attachment-btn' src="./Icons/AddBlackFilled.svg"/>
                        </div>    
                    </Col>
                </Row>
                <div className="mt-1">
                <Col md={12}>
                    {files.map((item, index) => renderReferenceAttachments(item, index))}
                </Col>
                </div>
                </>
            )}
            
                
                </Col>
            </div>
            <br/>
            <div className="mt-1">
            <Col md={12}>
                <div className="mt-1">
                    <ButtonCustom variant='contained' label='Save'className='buttonWidth' disabled={!isFormValid()} clicked={saveData}/>
                </div>
            </Col>
            </div>
        </div>
    )

}

export default EmailNodeDetails