// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Input_Input__8hdy0 {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
}

.Input_Label__hxAgH {
    font-weight: bold;
    display: block;
    margin-bottom: 8px;
}

.Input_InputElement__lS-uv {
    outline: none;
    border: 1px solid #ccc;
    background-color: white;
    font: inherit;
    padding: 6px 10px;
    display: block;
    width: 100%;
    box-sizing: border-box;
}

.Input_InputElement__lS-uv:focus {
    outline: none;
    background-color: #ccc;
}

.Input_Invalid__02WcZ {
    border: 1px solid red;
    background-color: #FDA49A;
}


.Input_selectLang__uRwZE{
    margin-bottom:0px !important;
}

ul.Input_skillListing__B8WGW  {
    list-style: none;
    display: inline-flex;
    padding-left: 5px;
    flex-flow: wrap;
    margin: 5px 0;
}
.Input_skillListing__B8WGW li {
    padding: 4px 10px;
    margin: 5px;
    border: 1px solid #707070;
    border-radius: 30px;
}
.Input_importIcon__ZRtF\\+
{
    height: 20px;
    cursor:pointer;
}
.Input_KnowledgeDropdownUploadBtn__YqMTN{
    display: none;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/Input/Input.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;IACjB,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,aAAa;IACb,iBAAiB;IACjB,cAAc;IACd,WAAW;IACX,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,qBAAqB;IACrB,yBAAyB;AAC7B;;;AAGA;IACI,4BAA4B;AAChC;;AAEA;IACI,gBAAgB;IAChB,oBAAoB;IACpB,iBAAiB;IACjB,eAAe;IACf,aAAa;AACjB;AACA;IACI,iBAAiB;IACjB,WAAW;IACX,yBAAyB;IACzB,mBAAmB;AACvB;AACA;;IAEI,YAAY;IACZ,cAAc;AAClB;AACA;IACI,aAAa;AACjB","sourcesContent":[".Input {\n    width: 100%;\n    padding: 10px;\n    box-sizing: border-box;\n}\n\n.Label {\n    font-weight: bold;\n    display: block;\n    margin-bottom: 8px;\n}\n\n.InputElement {\n    outline: none;\n    border: 1px solid #ccc;\n    background-color: white;\n    font: inherit;\n    padding: 6px 10px;\n    display: block;\n    width: 100%;\n    box-sizing: border-box;\n}\n\n.InputElement:focus {\n    outline: none;\n    background-color: #ccc;\n}\n\n.Invalid {\n    border: 1px solid red;\n    background-color: #FDA49A;\n}\n\n\n.selectLang{\n    margin-bottom:0px !important;\n}\n\nul.skillListing  {\n    list-style: none;\n    display: inline-flex;\n    padding-left: 5px;\n    flex-flow: wrap;\n    margin: 5px 0;\n}\n.skillListing li {\n    padding: 4px 10px;\n    margin: 5px;\n    border: 1px solid #707070;\n    border-radius: 30px;\n}\n.importIcon\n{\n    height: 20px;\n    cursor:pointer;\n}\n.KnowledgeDropdownUploadBtn{\n    display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Input": `Input_Input__8hdy0`,
	"Label": `Input_Label__hxAgH`,
	"InputElement": `Input_InputElement__lS-uv`,
	"Invalid": `Input_Invalid__02WcZ`,
	"selectLang": `Input_selectLang__uRwZE`,
	"skillListing": `Input_skillListing__B8WGW`,
	"importIcon": `Input_importIcon__ZRtF+`,
	"KnowledgeDropdownUploadBtn": `Input_KnowledgeDropdownUploadBtn__YqMTN`
};
export default ___CSS_LOADER_EXPORT___;
