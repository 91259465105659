import isEqual from "lodash.isequal";

const changeAdvanceFilterDataReducer = (state, payload) => {
   if(isEqual(payload,[])){
    return {
        ...state,
        appliedFilters : []
    }
   }else{
    return {
        ...state,
        appliedFilters : state.appliedFilters.includes(payload) ? [...state.appliedFilters.filter((item) => item !== payload)] :[...state.appliedFilters ,payload]
    };
   }
   
};

export default changeAdvanceFilterDataReducer;