import { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import * as actions from '../../../../store/actions/index';
import SelectCustom from "../../../../components/UIComponents/SelectDropdown/SelectComponent";
import HorizontalTab from "../../../../components/UI/HorizontalTab/HorizontalTab";
import './header.css';
import HeaderPart from './HeaderPart';
import CanvasPart from './CanvasPart';
import FooterPart from './FooterPart';
import PersonalityPart from './PersonalityPart';
import ChatWindow from '../components/ChatWindow/ChatWindow';
import { PostCalltoServer } from '../../../../store/utility';
import { defaultJSON } from './DefaultPTValues';
import { encryptAndStore } from '../../../../utility/utility';
import SpinnerPage from '../../../../components/UI/Loader/Loader';

let initState = {
    selected: 'Processes',
    columnHeader: ["#", "Name", "Status"],
    tabsdata: ["Processes", "View Logs"],
    CustomizeSec: "Header",
    searchTerm: '',
    projectName: '',
    popperShow: false,
    header: defaultJSON.header,
    headerBotImg:null,
    personality:defaultJSON.personality,
    footer: {
        poweredByText: {
            text: 'Powered by HCL intelliService',
            color: '#000000'
        },
        userInput: defaultJSON.footer.userInput,
        backToHome: {
            isVisible: true,
            text:'Back To Home',
            textColor: '#2945FF',
            textFamily: 'Roboto',
            textStyle: 'Regular',
            textSize: '10px',
            flowQuery:''
        },
        menuOptions: {
            isMenuVisible: false,
            isBannerVisible: false,
            bgColor: '#FFFFFF',
            closeIconColor:'#FA2834',
            menuItems:[
                {text:'', routeType:'Flow', routeData:'', textColor:'', textSize:'12px', subMenu:[]}
            ]
        },
    },
    canvas: {
        userText: {
            responseBgColor: '#1D74F5',
            responseTextColor: '#FFFFFF',
            responseBorderColor: '#1D74F5'
        },
        botResponse: {
            canvasBgColor: '#FFFFFF',
            responseBgColor: '#F1EBEB',
            responseTextColor: '#000000',
            responseBorderColor: '#F1EBEB',
            buttonBgColor: '#FFFFFF',
            buttonTextColor: '#504D4D',
            buttonBorderColor: '#9E9E9E',
            buttonHoverBgColor: '#2A93DF',
            buttonHoverTextColor: '#FFFFFF',
            buttonHoverBorderColor: '#2A93DF',
            timeStampTextColor: '#292121',
            buttonBehaviour: 'disappear'
        }
    },
    msg: '',
    tokenStatus: false,
    selectedSubSection:'',
    dataLoading:false
}
class VirtualAssistant extends Component {
    state = {
        ...initState
    }

    componentDidMount() {
        this.setState({
            ...initState
        })
        this.loadHeaderJSon()
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.IsProjectChange !== this.props.IsProjectChange) {
            this.setState({
                ...initState
            })
            this.loadHeaderJSon()
        }
    }

    onChangeCanvas = (data) => {
        this.setState({
            canvas: {
                ...data
            }
        })
    }

    onFooterReset = () => {
        this.setState({
            footer: {
                ...initState.footer
            }
        })
    }

    onChangeHeader = (data) => {
        this.setState({
            header: data
        })
    }

    onChangeHeaderImg = (data) => {
        this.setState({
            headerBotImg: data
        })
    }

    onChangeAgent = (data) => {
        this.setState({
            agent: {
                ...data
            }
        })
    }

    onChangePersonality = (data) => {
        this.setState({
            personality: {
                ...data
            }
        })
    }

    backToHomeHandler = (data) => {
        this.setState({
            footer: {
                ...this.state.footer,
                backToHome: data
            }
        })
    }

    menuOptionsHandler = (data) => {
        this.setState({
            footer: {
                ...this.state.footer,
                menuOptions: data
            }
        })
    }

    updateSubSelection = data => {
        this.setState({
            selectedSubSection: data
        })
    }

    poweredByTextHandler = (data) => {
        this.setState({
            footer: {
                ...this.state.footer,
                poweredByText: data
            }
        })
    }


    userInputHandler = (data) => {
        this.setState({
            footer: {
                ...this.state.footer,
                userInput: data
            }
        })
    }

    setSelected = (tab) => {
        this.setState({ selected: tab });
    }

    onSelectHandler = (event) => {

        this.setState({
            CustomizeSec: event.target.value,
            footer: {
                menu: {
                    ...this.state.footer.menu
                },
                ...this.state.footer
            }
        });
        this.loadHeaderJSon();
    }
    setSearchTerm = (item) => {
        this.setState({
            searchTerm: item
        })
    }
    popperShowFn = () => {
        this.setState({
            popperShow: true
        })
    }
    projectclick = (event, projectname) => {
        this.setState({
            searchTerm: '',
            popperShow: false
        })
        let savedProjectName = localStorage.getItem('projectname');
        if (savedProjectName !== projectname) {
            // localStorage.setItem('projectname', projectname);
            encryptAndStore('projectname', projectname);
            this.setState({
                projectName: projectname
            })
        }
        this.props.onchangeProjectAction(projectname);
        this.props.OnGetBotList(this.props.config.BOTBUILDER_URL);

    }

    getdatefromtoken = (token) => {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        return decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    }
    loadHeaderJSon = () => {
        this.setState({dataLoading:true})
        const url = this.props.config.VIRTUAL_ASSISTANCE_URL + "/virtualAssistant/loadJSON";
        let data = { FolderName: localStorage.getItem('orgName')+'/Intelli' + localStorage.getItem('vaName') + 'Bot' }
        PostCalltoServer(url, data)
            .then((response) => {
                if (response == "error") return true;
                else {
                    let virtualValues = JSON.parse(response.data)
                    if (virtualValues.header) {
                        this.setState({
                            header: virtualValues.header
                        })
                    }
                    
                    if (virtualValues.footer) {
                        let footer = virtualValues.footer
                        this.setState({
                            footer: {
                                userInput: footer.userInput,
                                poweredByText: {
                                    text: footer.poweredByText,
                                    color: footer.poweredByTextColor
                                },
                                backToHome: footer.backToHome,
                                menuOptions: footer.menuOptions
                            }
                        })
                    }
                    if (virtualValues.canvas) {
                        let canvas = virtualValues.canvas
                        this.setState({
                            canvas: {
                                userText: canvas.userText,
                                botResponse: canvas.botResponse
                            }
                        })
                    }
                    if(virtualValues.personality) {
                        this.setState({
                            personality:virtualValues.personality
                        })
                    }
                }
                this.setState({dataLoading:false})
            }).catch(() => {
                console.error('VA JSON load failed');
                this.setState({dataLoading:false})
            });
    }
    

    render() {
        return (<div className="VirtualAssistantContainer">
             {this.state.dataLoading ? (
          <Row className="vh-100 justify-content-center align-items-center"  style={{marginLeft:"50%"}}>
            <SpinnerPage animation="border" variant="primary" />
          </Row>
        ):''}
            <div className='d-flex  head-div vol-12'>
                <div className='col-6'>
                    <h3>Personalized Theme</h3>
                    <span className='para-graph'>Provision to customize the UI of the virtual agent for multiple channels.</span>
                </div>
            </div>
            <div className='tabs-container'>
                <HorizontalTab
                    tabs={["WEB", "MS TEAMS", "WHATSAPP"]}
                    selected={"WEB"}
                    setSelected={() => {console.log('Only WEB option available now') }}
                />
                <div className='bottom-line' />
            </div>
            <ChatWindow
                footer={{
                    ...this.state.footer
                }}
                canvas={{ ...this.state.canvas }}
                header={{ ...this.state.header }}
                headerBotImg={this.state.headerBotImg}
                personality={{ ...this.state.personality }}
                selectedSubSection={this.state.selectedSubSection}
                selectedSection={this.state.CustomizeSec}
            />
            <Container >
                <Row>
                    <Col md='12' className='mb-3'>
                        <Col md='3' className='main-dropdown-section'>
                            <SelectCustom
                                name="selectName"
                                label="Select the section to customize"
                                changed={(event) => this.onSelectHandler(event)}
                                required={true}
                                value={this.state.CustomizeSec}
                                options={[
                                    { value: 'Personality', displayValue: 'Personality' },
                                    { value: 'Header', displayValue: 'Header' },
                                    { value: 'Canvas', displayValue: 'Canvas' },
                                    { value: 'Footer', displayValue: 'Footer' }]}
                            />
                        </Col>
                    </Col>
                    <Col md='12'>
                         {this.state.CustomizeSec == 'Personality' && <PersonalityPart config={this.props.config}
                            data={this.state.personality} onChangePersonality={this.onChangePersonality} 
                            projectDetails={this.props.projectDetails}  updateSubSelection = {this.updateSubSelection}/>}
                        {this.state.CustomizeSec == 'Header' && (
                             <HeaderPart config={this.props.config} projectName={this.state.projectName}
                             onChangeHeader={this.onChangeHeader} projectDetails={this.props.projectDetails} 
                             onChangeHeaderImg={this.onChangeHeaderImg}/>
                        )}
                        {this.state.CustomizeSec == 'Canvas' ? <CanvasPart config={this.props.config}
                            onChangeCanvas={this.onChangeCanvas} projectDetails={this.props.projectDetails}
                        /> : ''}
                        {this.state.CustomizeSec == 'Footer' ? <FooterPart
                            config={this.props.config}
                            poweredByTextHandler={this.poweredByTextHandler}
                            userInputHandler={this.userInputHandler}
                            menuOptionsHandler={this.menuOptionsHandler}
                            backToHomeHandler={this.backToHomeHandler}
                            footerReset={this.onFooterReset}
                            projectDetails={this.props.projectDetails}
                            updateFooterSelection = {this.updateSubSelection}
                        /> : ''}
                    </Col>
                </Row>
            </Container>
        </div>
        );

    }
}
const mapStateToProps = state => {
    return {
        projectList: state.user.projects,
        IsProjectChange: state.projectview.IsProjectChange,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onchangeProjectAction: (projectname) => dispatch(actions.changeProjectAction(projectname)),
        OnGetBotList: (APIURL) => dispatch(actions.GetBotList(APIURL)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VirtualAssistant);
