// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-container{
    background: #FFFFFF;
    box-shadow: 0px 1px 5px 3px #E0E4E7;
    padding: 5px;
    font-family: 'Roboto-Black' !important;
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 40px;
    color: #000000;
    border-radius: 0;
    padding-left: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/components/UIComponents/Card/CardComponent.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,mCAAmC;IACnC,YAAY;IACZ,sCAAsC;IACtC,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":[".card-container{\n    background: #FFFFFF;\n    box-shadow: 0px 1px 5px 3px #E0E4E7;\n    padding: 5px;\n    font-family: 'Roboto-Black' !important;\n    font-style: normal;\n    font-weight: 900;\n    font-size: 14px;\n    line-height: 40px;\n    color: #000000;\n    border-radius: 0;\n    padding-left: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
