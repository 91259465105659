/*

* Copyright © 2023, HCL Technologies Ltd. ALL RIGHTS RESERVED.

* <p>

* This software is the confidential information of HCL Technologies Ltd., and is licensed

* as restricted rights software. The use,reproduction, or disclosure of this software

* is subject to restrictions set forth in your license agreement with HCL.

*/

 

/**

* Model Output.

*

* @author Abhash Vats

*/

import React,{Component} from "react";
import { encryptAndStore } from "../../../../utility/utility";
import  "../AgentCockpit/CrmLayout/CrmLayout.css";
import { makeStyles,withStyles } from '@material-ui/core';
import ButtonComponent from "../../../../components/UIComponents/Button/ButtonComponent";


import Tooltip from '@material-ui/core/Tooltip';
import { connect } from 'react-redux';
import withErrorHandler from "../../../../hoc/withErrorHandler/withErrorHandler";
import axios from "../../../../instance";
import "./modelOutput.css";
import Heading3 from "../components/Typography/Heading3/Heading3";
import { Row, Col } from "react-bootstrap";
import NavbarCustom from './Navbar';
import DrawerComponent from "../../../../components/UIComponents/Drawer/DrawerComponent";
import SearchComponent from "../../../../components/UIComponents/SearchComponent/Search";
import InputWithLabelComponent from "../components/InputWithLabelComponent/InputWithLabelComponent";
import "../Insight/AdminConfiguration/AdminHome.css";
import "./dataReview.css";
import * as actions from '../../../../store/actions/index';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { SetSuccessMessage } from "../../../../store/actions/index";








 
const drawerStyles = {
  width: '100%', // Adjust the width as needed
  left: '50%', // Adjust the left position as needed
};
  

class ModelOutput extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      tableData:[],
      keywords:"",
      isDrawerOpen:false,
      selectedClusterName:"",
      selectedClusterKeywords: [],
      searchValue: "",// Store the cluster name
      show:true,
      filteredData: [],
      userInput: '',
      sentiment: "",
      selectedProject:null,
      selectedSourceValue:null,
      sourcename:"SampleCollection",
      sourceValues:{bots:['SampleCollection1','SampleCollection2','SampleCollection3']},
      searchT:"",
      searchT2:"",
      projectname:"Project List",
      popperShow:false,
      popperShow2:false,
      noOfRecords: 0,
    };
  }
  componentDidMount(){
    var url = "http://10.14.108.21:32470/botbuilder" + "/modeloutput/" + localStorage.getItem('projectname') + "/customerclust"
    this.props.onGetModelOutPutData(url)
   
  }
  componentDidUpdate(prevprops){
    if(this.props.modelOutputData && this.props.modelOutputData !== this.state.tableData){
      this.setState({tableData:this.props.modelOutputData})
    }

  }
  
  downloadModelData = () =>{
    var payload={
      "clusters": this.state.selectedClusterName,
      "clusterLabel": this.state.userInput,
      "project": localStorage.getItem('projectname'),
      
    }
    var url =  this.props.config.BOTBUILDER_URL + "/modeloutput/" + "customerclust" + "/downloadCluserData"
    this.props.onDownloadModelOutputData(url,payload)
  }
  handleSearchChange = (event) => {
    this.setState({ searchValue: event.target.value });
  };
  ClearSearchValue = () => {
    this.setState({ searchValue: "", show: true, filteredData: [] });
  };
  handleSentimentChange = (event) => {
    this.setState({ sentiment: event.target.value });
  };
  onQuickFilterChanged = (event) => {
    const searchValue = event.target.value;
    this.setState({ searchValue }, () => {
      const filteredData = this.state.tableData.filter((item) => {
        const lowerSearchValue = searchValue.toLowerCase();
        return (
          (item.Cluster && item.Cluster.toLowerCase().includes(lowerSearchValue)) ||
          (item.sentiment && item.sentiment.toLowerCase().includes(lowerSearchValue)) ||
          (item.Keywords && item.Keywords.some(keyword => keyword.toLowerCase().includes(lowerSearchValue))) ||
          (item.DisplayClusterName && item.DisplayClusterName.toLowerCase().includes(lowerSearchValue)) ||
          (item.NoOfRecords != null && item.NoOfRecords.toString().toLowerCase().includes(lowerSearchValue))
        );
      });
      this.setState({ filteredData });
      if (searchValue !== "") {
        this.setState({ show: false });
      } else {
        this.setState({ show: true });
      }
    });
  };
  handleButtonClick(item)
  {
        this.setState({isDrawerOpen: true,
          selectedClusterName:item.clusters,
          userInput:item.clusterLabel,
          selectedClusterKeywords:item.keywords,
          noOfRecords:item.numberOfRecords,
          sentiment:item.sentiment
        })
      }
  handleCloseDrawer() 
  {
    this.setState({
      isDrawerOpen: false,
      selectedClusterName: "",
    });
  }
  handleInputChange = (event) => {
    this.setState({ userInput: event.target.value });
  }; 
setSearchTerm = (item) => {
    this.setState({searchT:item})
  }
 popperShowFn=()=>{
    this.setState({
      popperShow:true,
    })
  }
  setSearchTerm2 = (item) => {
    this.setState({searchT2:item})
  }
  popperShowFn2=()=>{
    this.setState({
      popperShow2:true,
    })
  }
  sourceValueclick =(event,sourcename)=>{
    this.setState({searchT2:"",popperShow2:false})
    this.setState({sourcename:sourcename});
    console.log("2222222")       
    }
    selectSourceVal = (event) => {
      this.setState({
          selectedSourceValue: event.target.value
      })
    }
  projectclick =(event,projectname)=>{
    this.setState({searchT:"",popperShow:false})
    let savedProjectName=localStorage.getItem('projectname');
    if(savedProjectName!==projectname){
    encryptAndStore('projectname', projectname );
    this.setState({projectname:projectname});
    }
    this.props.onchangeProjectAction(projectname); 
    var url = this.props.config.BOTBUILDER_URL + "/modeloutput/" + localStorage.getItem('projectname') + "/customerclust"
    this.props.onGetModelOutPutData(url)         
    }
  
    handleSave = () => {
      const { selectedClusterName, userInput,sentiment } = this.state;
      const selectedClusterKeywords = this.state.selectedClusterKeywords || []; // Ensure selectedClusterKeywords is an array
      const keywordsText = selectedClusterKeywords.join(', '); // Convert keywords to a comma-separated string
      const payload = {
        clusters: selectedClusterName,
        clusterLabel: userInput,
        project: localStorage.getItem('projectname'),
        keywords: selectedClusterKeywords,
         sentiment: sentiment,
      };
      const url = this.props.config.BOTBUILDER_URL + "/modeloutput/" + "model_test_data";
      
    
        this.props.onSaveModelOutPutData(url,payload);
      };
    render()
{
        return(
          <React.Fragment>
      <Row style={{justifyContent: "space-between"}}></Row>
            <Row style={{ marginTop: "0",margin:'0px' ,justifyContent: "space-between" }}>
              <Col xs='12'>
                <div className="d-flex align-items-center" style={{marginTop:'-60px'}}>
                  <Heading3 text= {
                    <span>
                      Model Output: <span style={{color:'red',marginLeft:'5px',marginBottom:'5px'}}></span>
                      
                      </span>
                    } className="pageTitleText2"/>
                  </div>
                <div style={{marginTop:'-120px',display:'flex',height:'80px',marginBottom:'40px'}}>
                  <div style={{position:'absolute',right:'0px',width:'35%'}}>
                    <div style={{paddingRight:'30px'}}>
                      <SearchComponent className="searchIcon" class={"input-group searchClass"} Clear={()=> this.ClearSearchValue()} show={this.state.show}
                      value={this.state.searchValue} onSearch={(event)=> this.onQuickFilterChanged(event)}/>
                    </div>
                  </div>
                </div>
                  </Col>
            </Row>
          {/* <div className="trainigDataHeadDiv_model">  */}
          <TableContainer className="tableContainer_model" id="model_id" style={{ marginTop: '-60px' }}>
                    <Table aria-label="simple table" stickyHeader>
                    {/* <div style={{ overflowX: "auto" }}> */}
                      <TableHead>
                      <TableRow style={{ border:'none',backgroundColor:'white',marhinleft:'10px' ,padding: '3px'}}>
                                  <TableCell style={{width: '10%',padding: '5px',textAlign:'center'}}>Cluster</TableCell>
                                  <TableCell style={{width: '10%',padding: '5px',textAlign:'center'}} >Sentiment</TableCell>
                                  <TableCell style={{width: '5%',padding: '5px',textAlign:'center'}} >Keywords</TableCell>
                                  <TableCell style={{width: '10%',padding: '5px',textAlign:'center'}} >Display Cluster Name</TableCell>
                                  <TableCell style={{width: '10%',padding: '5px',textAlign:'center'}} >No. of Records</TableCell>
                                  <TableCell style={{width: '10%',padding: '5px',textAlign:'center'}} >Actions</TableCell>
                                  </TableRow>   
                     </TableHead>
                              <TableBody >
                              {this.state.tableData.length>0 && this.state.tableData.filter((value)=> {
                                  return(
                                                          (value.clusters!= null && value.clusters.toLowerCase().includes(this.state.searchValue.toLowerCase()))||
                                                          (value.sentiment!= null && value.sentiment.toLowerCase().includes(this.state.searchValue.toLowerCase()))||
                                                          (value.keywords != null && typeof value.keywords === 'string' && value.keywords.toLowerCase().includes(this.state.searchValue.toLowerCase()))||
                                                          (value.numberOfRecords != null && typeof value.numberOfRecords === 'string' && value.numberOfRecords.toLowerCase().includes(this.state.searchValue.toLowerCase()))||
                                                          (value.clusterLabel!=null && value.clusterLabel.toLowerCase().includes(this.state.searchValue.toLowerCase()))
                                                        )
                                    })
                                    .map((item, index) => (
                                      <React.Fragment key={index}>
                                        <TableRow className="row-box" style={{ height: '40px', marginBottom: '0px' }}>
                                          <TableCell style={{ color: 'grey', fontSize: '14px',textAlign:'center',paddingLeft: '10px', paddingRight: '10px',width: '12%' }}>Cluster {item.clusters}</TableCell>
                                          <TableCell style={{ color: 'grey', fontSize: '14px',textAlign:'center',paddingLeft: '10px', paddingRight: '10px',width: '15%' }}>{item.sentiment}</TableCell>
                                          <TableCell style={{ color: 'grey', fontSize: '14px',textAlign: 'center',paddingLeft: '10px', paddingRight: '20px',width: '18%' }}>
                                            {item.keywords.map((keyword, keywordIndex) => (
                                              <span key={keywordIndex}>
                                                {keyword}
                                                {keywordIndex < item.keywords.length - 1 ? ', ' : ''}
                                              </span>
                                            ))}
                                          </TableCell>
                                          <TableCell style={{ color: 'grey', fontSize: '14px',paddingLeft: '20px', textAlign:'center',paddingRight: '20px',width: '18%' }}>{item.clusterLabel}</TableCell>
                                          <TableCell style={{ color: 'grey', fontSize: '14px',paddingLeft: '20px', textAlign:'center',paddingRight: '20px',width: '15%' }}>{item.numberOfRecords}</TableCell>
                                          <TableCell>
                                            <img
                                              src="./images/Vector.svg"
                                              alt="Clickable Image"
                                              onClick={() => this.handleButtonClick(item)}
                                              className="custombutton"
                                            />
                                          </TableCell>
                                        </TableRow>
                                         <TableCell colSpan={6}>
                                          {/* This cell spans all columns (colSpan = 6) */}
                                         </TableCell> 
                                      </React.Fragment>
                                    ))}
                              </TableBody>
                              {/* </div> */}
                              {/* </div>    */}
                    </Table>
                  
                </TableContainer>
             {/* </div> */}
                  {/* <div style={{ display: "flex",justifyContent: "flex-end", marginRight:'40px' }}>
                     <div style={{padding:'10px',float:"right",right:"20px",position:"relative",}}>
                    <button className="cancelButton" style={{ width: "100px", height: "30px",borderWidth: "1.8px",marginLeft:'5px'}}>
                      CANCEL
                    </button>
                    <button className="cancelButton" style={{ width: "100px", height: "30px",borderWidth: "1.8px",marginLeft:'5px'}}>
                      BACK
                    </button>
                    <button className="customBtn" style={{ width: '100px', height: '30px',marginLeft:'5px'}} 
                    // onClick={this.nextdata()}
                    >
                        NEXT
                    </button>
                  </div>
                  </div> */}
          <DrawerComponent
                  heading={`Cluster ${this.state.selectedClusterName}`}
                  showDrawer={(isOpen)=>this.setState({isDrawerOpen:isOpen})}
                  openDrawer={this.state.isDrawerOpen}
                  >
                    <InputWithLabelComponent
                    type="text"
                    name="name"
                    label="System Generated Name"
                    value= {`Cluster ${this.state.selectedClusterName}`}
                    style={{ marginBottom: "10px", background: "grey" }}
                    disabled
                    />
                    <InputWithLabelComponent
                    type="text"
                    name="name"
                    label="Display Cluster Name"
                    value={this.state.userInput}
                    onChange={this.handleInputChange}
                    style={{ marginBottom: "10px" }}
                    />
                    <div>
                    <label 
                      htmlFor="associatedKeywords" style={{fontWeight:'bold',color:'grey',marginBottom: "2px",}}>Associated Keywords ({this.state.selectedClusterKeywords.length})</label>
                        <div className="samplecontainer">
                        {this.state.selectedClusterKeywords.map((keyword, index) => (
                          <div className="item"key={index}>{keyword}</div>
                        ))}
                     </div>
                           <div style={{marginTop:'-10px'}}>
                       <label
                                    htmlFor="associatedRecords"
                                    style={{
                                        fontWeight: "bold",
                                        color: "grey",
                                    }}
                                >
                                    Associated Records
                      </label>
                                <div style={{ color: "grey" }}>
                                    {this.state.noOfRecords}
                                </div>
                            </div>
                      <div style={{ color: "grey" }}>
                                    {this.state.selectedsentiment}
                                </div>
                       <div style={{padding:"2px",float:"right",right:"20px",position:"relative",marginTop: '-60px',}}>
                      <button className="cancelBtn" style={{ width: "130px", height: "30px", borderWidth: "1.8px" }} onClick={()=>this.downloadModelData()}>
                      DOWNLOAD
                    </button>
                    </div>
                    <span className="downloadtext">Upon selecting the "Download" button,the corresponding records will be retrieved and downloaded in the Excel file format.</span>
                     </div>
                     <div style={{ padding: "10px", display: "flex",justifyContent: "space-between", alignItems: "center"}}>
                    <button className="cancelBtn" style={{ width: "150px", height: "30px",marginRight: "50px",borderWidth: "1.8px"}}>
                      CANCEL
                    </button>
                    <button className="customBtn" style={{ width: '160px', height: '30px',marginTop:'16px'}} onClick={()=>this.handleSave()}>
                        SAVE
                    </button>
                </div>
          </DrawerComponent>
        </React.Fragment>  
        );
}
}
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 12,
    margin: '0 0 0 8px',
    overflowWrap: 'break-word',
  },
}))(Tooltip);
const mapStateToProps = (state) => {
    return {
      projectlist: state.user.projects,
      dataLoading: state.UnsupervisedML.modelOutputDataLoading,
      modelOutputData: state.UnsupervisedML.modelOutputData,
    };
  };
  const mapDispatchToProps = (dispatch) => {
    return {
      onchangeProjectAction: (projectname) => dispatch(actions.changeProjectAction(projectname)),
      onGetModelOutPutData: (url) => dispatch(actions.getModelOutPutData(url)),
      onSaveModelOutPutData:(url,payload)=> dispatch(actions.saveModelOutputData(url,payload)),
      onDownloadModelOutputData: (url,payload) => dispatch(actions.downloadModelOutputData(url,payload))
    };
  };
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(withErrorHandler(ModelOutput, axios))