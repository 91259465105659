import React, { useEffect, useState, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Form, Button, Col, Spinner, Row } from 'react-bootstrap';
import './connections-tab.css';
import { botPostCalltoServer } from '../../../../store/utility';
import { SetErrorMessage, SetSuccessMessage, getConnectionsData } from '../../../../store/actions';

const initialState = {
    alertData: {
        retries: 5,
        complete:'',
        error: '',
        logsummary: ''
    },
    saveDataLoading: false,

};

function reducer(state, action) {
    switch (action.type) {	
        case 'changeAlertData':
            return {
                ...state,
                alertData: {
                    ...state.alertData,
                    ...action.payload
                }
            }	
        case 'updateAlertData':
            return {
                ...state,
                alertData: action.payload
            }
        case 'saveDataLoading':
            return {
                ...state,
                saveDataLoading: action.payload
            }
        
        default:
            throw new Error();
    }
}

function AlertsTab({   
    config,
    selectedMenu,
    selectedSubMenu,
    loadAlertData,
    tabId,
    showConfigLoader
}) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const reduxDispatch = useDispatch();
    const [validated, setValidated] = useState(false);

     useEffect(() => {
        if (isEmpty(loadAlertData)) {
            dispatch(
                {
                    type: 'updateAlertData',
                    payload: {
                        retries: 5,
                        complete:'',
                        error: '',
                        logsummary: ''
                    }
                });
        }
        else {
            dispatch(
                {
                    type: 'updateAlertData',
                    payload: loadAlertData
                });
        }
    }, [tabId]);
	const inputChangedHandler = (e, name) => {
        
            dispatch(
                {
                    type: 'changeAlertData',
                    payload: {
                        [name]: e.target.value
                    }
                });
       
    };
   
    const saveAlerts = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === false) {
        setValidated(true);
        }
        else {
            setValidated(true);
            
            const configObj = {
                'appUrl': config.CONNECTOR_URL,
                'dataSourceType': selectedMenu,
                'dataSourceName': selectedSubMenu,
                'tenantId': localStorage.getItem("orgName"),
                'connector':JSON.stringify( {
                    "alerts": state.alertData
                })
            };
            let url = config.CONNECTOR_URL + `/update-alerts`;
            dispatch({ type: 'saveDataLoading', payload: true });
            botPostCalltoServer(url, configObj)
                .then(response => {
                    if (get(response, 'data.status.responseType', '') === "success") {
                        dispatch({ type: 'saveDataLoading', payload: false });
                        reduxDispatch(getConnectionsData(config));
                        reduxDispatch(SetSuccessMessage('updated alerts Successfully'));
                    }
                    else {
                        dispatch({ type: 'saveDataLoading', payload: false });
                        reduxDispatch(SetErrorMessage(get(response, 'data.errors.0.source', '')));
                    }
                }).catch(err => {
                    dispatch({ type: 'saveDataLoading', payload: false });
                    reduxDispatch(SetErrorMessage(get(err, 'response', '')));
                });
        }
        
    };
   
    return (
        <React.Fragment>
             <div className='alert-tab-container'>
                {showConfigLoader ?
                    <Row className="justify-content-md-center padding10">
                        <Spinner animation="border" variant="primary" />
                    </Row> :
                    <React.Fragment>
                        <Form noValidate validated={validated} onSubmit={saveAlerts}>
                        <div className='box-container'>                           
                            <Row>
                                <Col md={'4'}>
                                    <div className={`inputContainer`}>
                                        <Form.Label>
                                            Number of retries to connect before alert
                                        </Form.Label>
                                        <Form.Control
                                            type={'number'}
                                            value={state.alertData.retries}
                                            onChange={(e) => inputChangedHandler(e, 'retries')}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <hr class='horizontalLine'/>
                            <Row>
                                <Col md={'4'}>
                                    <div className={`inputContainer`}>
                                        <Form.Label>
                                            Email id's for data sync complete
                                        </Form.Label>
                                        <Form.Control
                                            type={'email'}
                                            multiple 
                                            pattern="^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4},*[\W]*)+$"
                                            value={state.alertData.complete}
                                            onChange={(e) => inputChangedHandler(e, 'complete')}
                                        />
                                    </div>
                                </Col>
                                <Col md={'4'}>
                                    <div className={`inputContainer`}>
                                        <Form.Label>
                                        Email id's for data sync error
                                        </Form.Label>
                                        <Form.Control
                                            type={'email'}
                                            multiple 
                                            pattern="^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4},*[\W]*)+$"
                                            value={state.alertData.error}
                                            onChange={(e) => inputChangedHandler(e, 'error')}
                                        />
                                    </div>
                                </Col>
                                <Col md={'4'}>
                                    <Form.Group controlId='SendDailySyncLogSummary'>
                                    <div className={`inputContainer`}>
                                        <Form.Label>
                                            Email id's for daily sync log summary
                                        </Form.Label>
                                        <Form.Control
                                            type={'email'}
                                            multiple 
                                            pattern="^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4},*[\W]*)+$"
                                            value={state.alertData.logsummary}
                                            onChange={(e) => inputChangedHandler(e, 'logsummary')}
                                        />
                                    </div>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <div className='flex-space-between mt-10'>
							<div class="col-md-5"></div>
							<div class="col-md-7">
                                <Button variant="primary" className='large-border-radius mr-15'
                                    type="submit"
                                    disabled={state.saveDataLoading} >
                                    {state.saveDataLoading ?
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        /> : null}
                                    Save

								</Button>
							</div>	
                          </div>
                        </div>
                        </Form>
                    </React.Fragment>
                }
            </div>
        </React.Fragment>
    )
}


export default AlertsTab;