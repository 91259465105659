import get from 'lodash/get';
import uniq from 'lodash/uniq';
import compact from 'lodash/compact';

const issueClassificationChangeHandlerReducer = (state, payload) => {
    if (payload.isOptionField) {
        const clusterFields = [            
            "caseId",
            "problemDescription",
            "title"
        ];
        
        const optionalField1 = payload.type === "issueClarificationOptionalField1" ? payload.value :get(state, 'issueClarificationOptionalField1', '');
        const optionalField2 = payload.type === "issueClarificationOptionalField2" ? payload.value :get(state, 'issueClarificationOptionalField2', '');
        const optionalField3 = payload.type === "issueClarificationOptionalField3" ? payload.value :get(state, 'issueClarificationOptionalField3', '');
        const optionalFieldsArr = [optionalField1, optionalField2, optionalField3];
        const updatedClusterFields = {
            ClusterFields: uniq(compact(clusterFields.concat(optionalFieldsArr)))
        };
        return {
            ...state,
            issueclassification: {
                ...state.issueclassification,
                ...updatedClusterFields
            }
        }
    }
    return {
        ...state,
        issueclassification: {
            ...state.issueclassification,
            ...payload
        }
    }
}

export default issueClassificationChangeHandlerReducer;