import React, {  useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Drawer from "@material-ui/core/Drawer";
import { Col, Form, Row, Spinner } from "react-bootstrap";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import './Filters.css';
import fetchProductTriageDataAction from "../../../../../../store/actions/agentInsights/fetchProductTriageData/fetchProductTriageDataAction";
import advanceFilterChangeHandlerAction from "../../../../../../store/actions/agentInsights/advanceFilterChangeHandlerAction";
import DrawerHeaderContent from "../../../components/DrawerHeaderContent/DrawerHeaderContent";


const useStyles = makeStyles({
  list: {
    maxWidth: "29.688rem",
    maxHeight: "100vh",
    overflowX: "hidden",
    overflowY: "scroll",
  },
});

const Filters = ({ toggleDrawer, config, resultsUpdate }) => {
  const selectedFilters = useSelector(state => state.agentInsights.appliedFilters);
  const data = useSelector(state => state.agentInsights.filterData);
  const productTriageDataLoading = useSelector(state => state.agentInsights.productTriageDataLoading);
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(fetchProductTriageDataAction(config));
  }, []);

  const checkHandler = (e, index, parentAttribute) => {
      dispatch(advanceFilterChangeHandlerAction(`${parentAttribute}-${e.target.name}`));
      resultsUpdate(parentAttribute, e.target.name);
  }

  const clearAll = (attributeName) => {
      const matches = selectedFilters.filter(s => s.includes(attributeName));
        matches.map((value) => {
          const field = value.split('-');
          resultsUpdate(attributeName, field[1]);
          dispatch(advanceFilterChangeHandlerAction(value));
      })
  }

  const FilterValuesByAttributes = (attribute, parentAttribute) =>
    Object.entries(attribute).map(([key, value]) => {
      if (Array.isArray(value)) {
        return null
      }
      const isChecked = selectedFilters.includes(`${parentAttribute}-${value}`);
      return (
        <Col xs={4}>
        <div className="mt-2">
          <div key={value} >
            <Form.Check
              inline
              type={'checkbox'}
              id={`default-${value}`}
              label={value}
              name={value}
              onChange={(e) => checkHandler(e, key, parentAttribute)}
              checked = {isChecked}
               />
          </div>
        </div>
        </Col>
      );
    });

  return (
    <React.Fragment>
      <Drawer anchor={"right"} open={true} onClose={toggleDrawer(false)}>
        <div
          className={clsx(classes.list)}
          role="presentation"
          className="custom-filters-page"
        >
          <DrawerHeaderContent heading= {"Filters"} toggleDrawer={toggleDrawer} />
          {data.length && !productTriageDataLoading ?
          <div className="issue-details-sub-heading ml-2 pl-4">
            {data.map((item) => {
              return (
                <div className='pt-3 pb-3 mr-4 product-attribute-tags'>
                  {item.attribute_data != null ? 
                  <div>
                    {item.attribute_name}
                    <a className="d-flex justify-content-end clear-filters" onClick={() => clearAll(item.attribute_name)} >clear all</a>
                    <Row className="mt-2">
                    {FilterValuesByAttributes(item.attribute_data, item.attribute_name)}
                    </Row>
                    </div>
                    : <Row className="filters-data-null">
                    {"There are no filters available"}
                </Row> } 
                </div>
                )
            })}
          </div> : <Row className="pt-4 filters-data-null">
                    {"There are no filters available"}
                </Row> }
        </div>
      </Drawer>
    </React.Fragment>
  );
};

Filters.propTypes = {};

Filters.defaultProps = {};

export default Filters;
