import React, { useRef, useState, useEffect } from 'react';
import ValidationMsg from '../../../components/ValidationMsg/ValidationMsg';
import './Dropzone.css';
 import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import {Button} from 'react-bootstrap';
import ButtonComponent from '../../../components/UIComponents/Button/ButtonComponent';
const Dropzone = (props) => {
    const fileInputRef = useRef();
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [validFiles, setValidFiles] = useState([]);
    const [unsupportedFiles, setUnsupportedFiles] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [validation, setvalidationMessage] = useState(null);
    const [infoModel, modelInfo] = useState(false)
    useEffect(() => {
        let filteredArr = selectedFiles.reduce((acc, current) => {
            const x = acc.find(item => item.name === current.name);
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
        }, []);
        setValidFiles([...filteredArr]);
        // if([...filteredArr].length>0 && !props.catSelected){
        //     props.setValidationMessage("catSelected",props.catSelected)
        // }
        // else
        // {
        //     props.setValidationMessage("catSelected",'')
        // }

        if(props.showButton ==false){

            props.ImportFAQIntents([...filteredArr])

        }
        if(props.showButton ==false){
            props.ImportFAQIntents([...filteredArr])
        }
    }, [selectedFiles]);

    const preventDefault = (e) => {
        e.preventDefault();
    }

    const dragOver = (e) => {
        preventDefault(e);
    }

    const dragEnter = (e) => {
        preventDefault(e);
    }

    const dragLeave = (e) => {
        preventDefault(e);
    }

    const fileDrop = (e) => {
        preventDefault(e);
        const files = e.dataTransfer.files;
        if (files.length) {
            if(props.failure || props.showFiles)
            {

                setValidFiles([]);
                setSelectedFiles([])
            }
            handleFiles(files);
        }
    }

    const filesSelected = () => {
        
        if (fileInputRef.current.files.length) {
            if(props.failure || props.showFiles)
        {
            setValidFiles([])
            setSelectedFiles([])
        }
            handleFiles(fileInputRef.current.files);
        }
    }

    const fileInputClicked = () => {
        fileInputRef.current.click();
    }

    const handleFiles = (files) => {
        props.onchangeFile();
        for(let i = 0; i < files.length; i++) {
            if (validateFile(files[i])) {
                setSelectedFiles(prevArray => [...prevArray, files[i]]);
            } else {
                files[i]['invalid'] = true;
                setSelectedFiles(prevArray => [...prevArray, files[i]]);
                setErrorMessage('File type not permitted');
                setUnsupportedFiles(prevArray => [...prevArray, files[i]]);
            }
        }
        // for(let i = 0; i < files.length; i++) {
           
        //         setSelectedFiles(prevArray => [...prevArray, files[i]]);
        // }
        ValidationMessage("files",selectedFiles.length)
        
    }

    const ValidationMessage =(type,value) =>{
        
       if(type =="files"){
             value < 0? setvalidationMessage( <ValidationMsg>File is required</ValidationMsg>) : 
             setvalidationMessage(null);
         }
         if(type =="filesLength"){
            value.trim() === '' 
            ?   setvalidationMessage( <ValidationMsg>File is required</ValidationMsg>) : 
            setvalidationMessage(null);
            } 
        
     }
    const validateFile = (file) => {
        const validTypes = ['application/pdf','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
       ,'text/plain','text/csv','.csv','text/html','application/vnd.ms-excel','application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
        if (validTypes.indexOf(file.type) === -1) {
            return false;
        }

        return true;
    }

    const fileSize = (size) => {
        if (size === 0) {
          return '0 Bytes';
        }
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(size) / Math.log(k));
        return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    }

    const fileType = (fileName) => {
        return fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || fileName;
    }

    const removeFile = (name) => {
        const index = validFiles.findIndex(e => e.name === name);
        const index2 = selectedFiles.findIndex(e => e.name === name);
        const index3 = unsupportedFiles.findIndex(e => e.name === name);
        validFiles.splice(index, 1);
        selectedFiles.splice(index2, 1);
        setValidFiles([...validFiles]);
        setSelectedFiles([...selectedFiles]);
        if (index3 !== -1) {
            unsupportedFiles.splice(index3, 1);
            setUnsupportedFiles([...unsupportedFiles]);
        }
        //if(validFiles.length == 0)
        props.onchangeFile()
    }
    const ImportFAQIntents=()=>{
        if(validFiles.length && !props.showFiles)
        props.ImportFAQIntents(validFiles)
        else{
            props.ImportFAQIntents(validFiles)
            ValidationMessage("filesLength",'')
        }
        
    }
   
    // const info=()=>{
    //     modelInfo(true)
    // }
    const handleClose=()=>{
        modelInfo(false)
    }
    return (
        <>
            <div className="" style={{height:"auto"}}>
                <div className="dropZone">
                {/* {props.showButton !=false ?
                    <TooltipCustom tooltext="Required file formatting" direction="bottom">
                        <span style={{float:"right", right: "0px",top: "-45px",position:"absolute"}}><a onClick={info} style={{float:"right", position:"relative"}}><InfoIcon style={{ color:"#4aa1f3", paddingTop:"7px",paddingRight:"10px",fontSize:"45"}} /></a></span>
                    </TooltipCustom>
                    :''} */}
                
                <div className="drop-container"
                    onDragOver={dragOver}
                    onDragEnter={dragEnter}
                    onDragLeave={dragLeave}
                    onDrop={fileDrop}
                    onClick={fileInputClicked}
                    style={{height:props.showButton ==false && props.length==0?
                        '400px'
                        :''
                    }}
                >
                    <div className="drop-message">
                        Drag & Drop files here or click to select file(s)
                    </div>
                    <input
                        ref={fileInputRef}
                        className="file-input"
                        type="file"
                        onChange={filesSelected}
                        multiple
                        onClick={(event)=> {event.target.value = null}}
                    />
                   
                </div>
               </div>
               <div>
               {/* <div   className='file-error-message alignLeft' style={props.failure ?{display:'block'}:{display:'none'}}>
                {props.knowledgeUpload}
                <h4 style={{color:'red',fontSize:"inherit"}}>Please remove all unsupported files.</h4>
                
                </div>  */}
               {unsupportedFiles.length ? <div className='file-error-message' style={{color:'red'}}>Please remove all unsupported files.</div> : ''}
               <div className="row col-md-12 fileContainers">
                <div className="file-display-containers">
                    {
                       props.showFiles ?null: 
                        (validFiles.map((data, i) => 
                            <div className="file-status-bars" key={i}>
                                <div>
                                    <div className="file-type-logo"></div>
                                    <div className="file-types">{fileType(data.name)}</div>
                                    <div className={`file-names ${data.invalid ? 'file-error' : ''}`}>{data.name}
                                    <span className="file-sizes">({fileSize(data.size)})</span></div> {data.invalid && <div className='file-error-message'>({errorMessage})</div>}
                                </div>
                                <div className="file-removes" onClick={() => removeFile(data.name)}>X</div>
                            </div>
                        ))
                    }
                </div>
                </div>
                </div>
            </div>
           
                {props.uploadloadingImg}
            
            <div  className="dropZone">
            {validation}
            </div>
            <div>
            <img src="./images/info_icon.png"/>
            </div>
            <div className="dropzoneInfo">
            <span>File formats supported for FAQ are Excel, CSV, PDF, Word and Txt.</span>
            <br/>
            <span>For CSV/Excel files, column names should be question_shortDesc/question/questions | alternate_questions | answer/answers/response/responses.</span>
            </div>
            <div>
                <ButtonComponent
                    variant='contained'
                    label='Save'
                    disabled={(unsupportedFiles.length === 0 && validFiles.length &&
                        props.failure == false) && props.catSelected !== null ? "" : "disabled"}
                    className='buttonWidth mb-2 mt-3'
                    clicked={ImportFAQIntents} />
            {/* {props.showButton !=false ?
            <div className="BtnAlignment">
                <ButtonCustom btnType="blueButton" 
                disabled={(unsupportedFiles.length === 0 && validFiles.length && 
                props.failure==false) && props.catSelected !== null ? "":"disabled"}
                 clicked={ImportFAQIntents}>Create</ButtonCustom>
                <ButtonCustom btnType="whiteButton" clicked={props.cancelKnowledgeHandler}>Cancel</ButtonCustom>
            </div>:''} */}
        </div>

        <Dialog open={infoModel} onClose={handleClose} width="100px" aria-labelledby="form-dialog-title">
        <form style={{background:"#e3e8fb"}}>
          <DialogTitle id="form-dialog-title">File(s) Information</DialogTitle>
          <div style={{padding:'10px'}}>
          <DialogContent style={{border:'3px solid #b6cfe9', padding:'2px'}}>
              <div style={{padding:'5px', background:"white"}}>
                <div><strong>Excel/CSV &nbsp; - &nbsp;</strong> The file(s) have to be in the below format. Abiding by this will allow the Excel file to upload.</div>
                <p><br></br>question_shortDesc/question/questions | alternate_questions | answer/answers/response/responses<br></br>
                --------------------------------------------------------------------------------------</p>
                <p>All other File formats- Please ensure the files are in Question/Answer format.</p>
              </div>
          </DialogContent>
          </div>
          <DialogActions>
		        
            <Button onClick={handleClose} color="primary">
              Close
            </Button> 
          </DialogActions>
        </form>
      </Dialog>
        </>
    );
}

export default Dropzone;
