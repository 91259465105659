import React from 'react';
import Select from 'react-select';

import clsx from 'clsx';

import * as PropTypes from 'prop-types';
import "./react-select.css";
import {  isNil } from 'lodash';


const ReactSelectComponent = ({
    options,
    value,
    onChange,
    
    disabled,
    placeholder,
   
    name,
    
    ...rest
}) => (
        <div data-testid={`react`} className='react-select'>
            <Select
                isMulti={false}
                value={value}
                placeholder={placeholder}
                options={options}
                className = {clsx('single-select-container', isNil(value) ? 'isRequired' : '')}
                classNamePrefix="multi-select"
                isDisabled={disabled}
                closeMenuOnSelect={true}
                name={name}
                tabSelectsValue={false}
                onChange={onChange}
                {...rest}
            />
        </div>
    );

ReactSelectComponent.propTypes = {
    options: PropTypes.array,
    id: PropTypes.string,
    disabled: PropTypes.bool
};

ReactSelectComponent.defaultProps = {
    options: [],
    id: '',
    disabled: false,
};

export default ReactSelectComponent;
