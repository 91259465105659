const questionariesChangeHandlerReducer = (state, payload) => {
    return {
        ...state,
        questionaries: {
            ...state.questionaries,
            ...payload
        }
    }
}

export default questionariesChangeHandlerReducer;