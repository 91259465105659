import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { OptinalFieldResetHandlerAction, similarIssuesChangeHandlerAction, similarIssuesOptinalFieldChangeHandlerAction } from '../../../store/actions/insights/insightsActions';
import SimilarIssuesPage from '../SimilarIssues/SimilarIssues';
import saveInsightsUnifiedLoginEsDataAction from '../../../store/actions/insights/saveInsightsUnifiedLoginEs/saveInsightsUnifiedLoginEsDataAction';
import extensionFieldsRemoveItemHandler from '../../../store/actions/insights/extensionFieldsRemoveItemHandler';
import getActiveInsights from '../helpers/getActiveInsights';
import getOptionalFieldOptions from '../helpers/getOptionalFieldOptions';



const getOptionalFieldDdOptions = (state, value, props) => {
    const optionalFieldValue = value === 1 ? state.insights.similarIssuesOptionalField2 : state.insights.similarIssuesOptionalField1;
    const options = getOptionalFieldOptions(props);
    if(isEmpty(optionalFieldValue)) {
        return {
            options: options
        };
    }
    const updatedOptions = options.filter(item => item.value !== optionalFieldValue);
    return {
        options: updatedOptions
    }
};


const getTicketFieldsOptions = (state, props) => {
    
    const fields =  props.fields;
    return fields.map(item => ({
        value: item,
        label: item
    }))
};

const getSimilarIssuesDefaultField = (props) => {
    const activeInsights = getActiveInsights(props.config);
    return activeInsights === "its" ? "problemDescription" : "description" 
};

export const mapStateToProps = (state, ownProps) => ({
    enabled: state.insights.similartickets.enabled,
    extensionFields: state.insights.similartickets.extensionFields,
    fieldToSerach: state.insights.similartickets.fieldToSerach,
    size: state.insights.similartickets.size,
    similarity: state.insights.similartickets.similarity,
    optionField1: state.insights.similarIssuesOptionalField1,
    optionField2: state.insights.similarIssuesOptionalField2,
    optionalField1DdOptions: getOptionalFieldDdOptions(state, 1, ownProps),
    optionalField2DdOptions: getOptionalFieldDdOptions(state, 2, ownProps),
    ticketFieldsOptions: getTicketFieldsOptions(state, ownProps),
    similarIssuesDefaultField: getSimilarIssuesDefaultField(ownProps)
});

export const mapDispatchToProps = (dispatch,ownProps) => ({
    
    handleSwitchChange: (event) => {
        dispatch(similarIssuesChangeHandlerAction({
            enabled: event.target.checked
        }))
        const apiUrl = ownProps.config.INSIGHTS_API_URL;
        dispatch(saveInsightsUnifiedLoginEsDataAction({apiUrl, tabName: 'similartickets',ownProps}));
    },
    multiChangeHandler: (options, name) => {
        dispatch(
            similarIssuesChangeHandlerAction({
                [name]: isEmpty(options) ?
                    [] :
                    options.map(item => item.value)
            }))
    },
    onRemoveItemHandler: (item) => {
        dispatch(
            extensionFieldsRemoveItemHandler(item))
    },
    inputChangedHandler: (event, name) => {
        dispatch(
            similarIssuesChangeHandlerAction(
                {
                    [name]: Number(event.target.value)
                }
            )
        );
    },
    inputFiledToSearchChangedHandler: (event, name) => {
        dispatch(
            similarIssuesChangeHandlerAction(
                {
                    [name]: event.target.value
                }
            )
        );
    },
    handleSlideChange: (slideProgress, value) => {
        dispatch(
            similarIssuesChangeHandlerAction(
                {
                    similarity: Number(value)
                }
            )
        )
    },
    optionFieldsChangedHandler: (event, name) => {
        dispatch(
            similarIssuesChangeHandlerAction(
                {
                    value: event.target.value,
                    isOptionField: true,
                    type: name
                }
            )
        );
        dispatch(
            similarIssuesOptinalFieldChangeHandlerAction({
                [name]: event.target.value,
                isDefault: false,
            })
        );
        dispatch(OptinalFieldResetHandlerAction({
            value: event.target.value,
            id: name,
        }));
    },
    saveButtonClickHandler: () => {
        
		const apiUrl = ownProps.config.INSIGHTS_API_URL;
        dispatch(saveInsightsUnifiedLoginEsDataAction({apiUrl, tabName: 'similartickets',ownProps}));
    },
    ownProps
});

export const SimilarIssuesContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(SimilarIssuesPage);