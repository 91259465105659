import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import InputWithLabelComponent from "../../../../../../containers/SettingPage/SettingInnerPages/components/InputWithLabelComponent/InputWithLabelComponent";
import SelectCustom from "../../../../../../components/UIComponents/SelectDropdown/SelectComponent";

const TextField = ({properties, onChangeHandler, invalidVarMsg}) => {

    const validationTypesList = [{value:'all',displayValue:'All'},
                            {value:'alpha',displayValue:'Alphabets'},
                            {value:'num',displayValue:'Numeric'},
                            {value:'alpha_num',displayValue:'Alpha Numeric'},
                            {value:'email',displayValue:'Email'},{value:'custom',displayValue:'Custom'}];

    function onTypeChange (type) {
        onChangeHandler('validationType',type);
        onChangeHandler('regex',getRegex(type));
    }

    function getRegex (type) {
        switch(type) {
            case 'alpha' : return '^[a-zA-Z ]+$'
            case 'num' : return '^[0-9]*$'
            case 'alpha_num' : return '^[a-zA-Z0-9 ]+$'
            case 'all' : return '.*'
            case 'email' : return '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+[.][A-Za-z0-9-]{2,4}$'
            case 'custom' : return ''
            // case 'select' : return '*'
        }
    }

    return (
        <div>
            <div className="mt-1 settings-div">
                <Col md={12} >
                    <Row className='settings-form-switch'>
                        <Col md={6}><span className='settings-label'>Required</span></Col>
                        <Col md={6} className='settings-switch'>
                        <Form>
                                <Form.Check
                                    type="switch"
                                    id="isRequired"
                                    checked={properties.isRequired}
                                    onChange={e => onChangeHandler('isRequired',e.target.checked)}
                                />
                            </Form>
                        </Col>
                    </Row>
                </Col>
            </div>
            <div className="mt-1 settings-div">
                <Col md={12}>
                    <Row className='settings-form-switch'>
                        <Col md={6}><span className='settings-label'>Password</span></Col>
                        <Col md={6} className='settings-switch'>
                        <Form>
                                <Form.Check
                                    type="switch"
                                    id="isPassword"
                                    checked={properties.isPassword}
                                    onChange={e => onChangeHandler('isPassword',e.target.checked)}
                                />
                            </Form>
                        </Col>
                    </Row>
                </Col>
            </div>
            <div className="mt-1 settings-div">
                <Col md={12}>
                <InputWithLabelComponent
                        type="text"
                        name={'settings-id'}
                        label="Name*"
                        showLabel={true}
                        value={properties.id}
                        readOnly={false}
                        onChange={e => onChangeHandler('id',e.target.value)}
                        required={true}
                        className="settings-input"
                        isInvalid={invalidVarMsg!==null}
                        error={invalidVarMsg}
                        toolTip={true}
                        toolTipParams={{title:'Name will also be used as variables',placement:'right'}}
                  />
                </Col>
            </div>
            <div className="mt-1 settings-div">
                <Col md={12}>
                <InputWithLabelComponent
                        type="text"
                        name={'settings-label'}
                        label="Label*"
                        showLabel={true}
                        value={properties.label}
                        readOnly={false}
                        onChange={e => onChangeHandler('label',e.target.value)}
                        required={true}
                        className="settings-input"
                  />
                </Col>
            </div>
            <div className="mt-1 settings-div">
                <Col md={12}>
                    <SelectCustom
                        name="validation"
                        label="Select Validation Type"
                        className="settings-dropdown"
                        changed={e=>onTypeChange(e.target.value)}
                        required={true}
                        value={properties.validationType}
                        options={validationTypesList}
                    />
                </Col>
            </div>
            <div className="mt-1 settings-div">
                <Col md={12}>
                <InputWithLabelComponent
                        type="text"
                        name={'settings-regex'}
                        label="Validation"
                        showLabel={true}
                        value={properties.regex}
                        readOnly={false}
                        onChange={e => onChangeHandler('regex',e.target.value)}
                        required={true}
                        className="settings-input"
                        placeHolder={'Enter Regular Expression'}
                        isInvalid={invalidVarMsg!==null}
                        error={invalidVarMsg}
                  />
                </Col>
            </div>
            <div className="mt-1 settings-div">
                <Col md={12}>
                    <InputWithLabelComponent
                        type="text"
                        name={'settings-placeholder'}
                        label="Placeholder"
                        showLabel={true}
                        value={properties.placeholder}
                        readOnly={false}
                        onChange={e => onChangeHandler('placeholder',e.target.value)}
                        required={true}
                        className="settings-input"
                    />
                </Col>
            </div>
            <div className="mt-1 settings-div">
                <Col md={12}>
                    <InputWithLabelComponent
                        type="text"
                        name={'settings-default'}
                        label="Default Values"
                        showLabel={true}
                        value={properties.value}
                        readOnly={false}
                        onChange={e => onChangeHandler('value',e.target.value)}
                        required={true}
                        className="settings-input"
                    />
                </Col>
            </div>
            <div className="mt-1 settings-div">
                <Col md={12}>
                    <InputWithLabelComponent
                        type="text"
                        name={'settings-default'}
                        label="Error Message"
                        showLabel={true}
                        value={properties.errorMessage}
                        readOnly={false}
                        onChange={e => onChangeHandler('errorMessage',e.target.value)}
                        required={true}
                        className="settings-input"
                    />
                </Col>
            </div>
            
        </div>
    )
}

export default TextField;