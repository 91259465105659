import React, { Component } from 'react';
import './Drawer.css';
import DrawerComponent from '../../UIComponents/Drawer/DrawerComponent';
import InputWithLabelComponent from "../../../containers/SettingPage/SettingInnerPages/components/InputWithLabelComponent/InputWithLabelComponent";


const DrawerTemplate = ({show, title, closeDrawer, children}) => {

    return (
        <DrawerComponent heading={title}
            showDrawer={closeDrawer}
            openDrawer={show}>
            <div className='drawer add-entity'>
                <div className='drawer-body mt-2'>
                    <form>
                    {children}
                    </form>
                </div>
            </div>
        </DrawerComponent>
    )
}

// class AddBotDrawer extends Component {
    
//     toggleDrawer = (open) => (event) => {
//         if (!open) {
//             this.props.onHide('entity');
//         }
//         if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
//             this.props.onHide('entity');
//         }
//     };

//     render() {
//         return (
//             <DrawerComponent heading="Add Flow Details"
//                 showDrawer={this.toggleDrawer(false)}
//                 openDrawer={this.props.show}>
//                 <div className='drawer add-entity'>
//                     <div className='drawer-body mt-2'>
//                         <form>
//                             {this.props.children}
//                         </form>
//                     </div>
//                 </div>
//             </DrawerComponent>
//         );
//     }
// }

export default DrawerTemplate;
