// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VirtualAssitantPersonality .addBtn-img {
    width: 22px;
    cursor: pointer;
    margin-left: 15px;
}

.plusImgCol {
    align-self: center;
    margin-top: 20px;
}

.deleteBtn-img {
    width: 28px;
    cursor: pointer;
}

.engagementCloseIcon {
    top:-25px;
}`, "",{"version":3,"sources":["webpack://./src/containers/SettingPage/SettingInnerPages/VirtualAssistant/personalityPart.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,SAAS;AACb","sourcesContent":[".VirtualAssitantPersonality .addBtn-img {\n    width: 22px;\n    cursor: pointer;\n    margin-left: 15px;\n}\n\n.plusImgCol {\n    align-self: center;\n    margin-top: 20px;\n}\n\n.deleteBtn-img {\n    width: 28px;\n    cursor: pointer;\n}\n\n.engagementCloseIcon {\n    top:-25px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
