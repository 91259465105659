import * as React from 'react';
import Card from '@material-ui/core/Card';
import './CardComponent.css';

const CardComponent = (props) => {
   return(
      <Card className={`card-container ${props.className}`} variant="outlined">
      {props.children}
    </Card>
  
   )
}

export default CardComponent;
