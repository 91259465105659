// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ValidationMsg_helpblock__vRuqs{
    color:red;
    margin-top: -5px;
    font-size: 12px;
    font-family:  'Roboto-Regular';
}`, "",{"version":3,"sources":["webpack://./src/components/ValidationMsg/ValidationMsg.module.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,gBAAgB;IAChB,eAAe;IACf,8BAA8B;AAClC","sourcesContent":[".helpblock{\n    color:red;\n    margin-top: -5px;\n    font-size: 12px;\n    font-family:  'Roboto-Regular';\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpblock": `ValidationMsg_helpblock__vRuqs`
};
export default ___CSS_LOADER_EXPORT___;
