/*

* Copyright © 2020, HCL Technologies Ltd. ALL RIGHTS RESERVED.

* <p>

* This software is the confidential information of HCL Technologies Ltd., and is licensed

* as restricted rights software. The use,reproduction, or disclosure of this software

* is subject to restrictions set forth in your license agreement with HCL.

*/

 

/**

* Home component.

*

* @author Abhay

*/
import {Component} from 'react';
import { connect } from 'react-redux';
import classes from '../SettingPage.module.css';
import './LanguageSettings.css'
import { Dropdown} from 'react-bootstrap';
import * as actions from '../../../store/actions/index'
import SpinnerPage from '../../../components/UI/Loader/Loader';
import ButtonComponent from '../../../components/UIComponents/Button/ButtonComponent';
import CardComponent from "../../../components/UIComponents/Card/CardComponent";
import SearchComponent from '../../../components/UIComponents/SearchComponent/Search';
import AddLanguageDrawer from '../../../components/UI/Drawer/AddLanguageDrawer';
import EditLanguageDrawer from '../../../components/UI/Drawer/EditLanguageDrawer';
import DefaultButton from '../../../components/UIComponents/Button/DefaultButton';
import InputWithLabelComponent from './components/InputWithLabelComponent/InputWithLabelComponent';
import DeleteModal from "../../../components/UIComponents/DeleteModalComponent/DeleteModal";
class LanguageSettingPage extends Component {
    state ={
        languagelist:[],
        alllanguagelist:[],
        messagetextbox:'',
        synonmystextbox:'',
        stopwordtextbox:'',
        menuclicked:'',
        selectedlanguage:'',
        deletelanguageShow:false,
        selectedlangkey:null,
        langfilter:'',
        show:true,
        showAddLanguage:false

    }
    componentDidMount() {
        this.setLanguageArray(this.props.languageList);
        this.setState(
            { 
                messagetextbox: this.props.messageSetting && this.props.messageSetting !=undefined ? JSON.parse(this.props.messageSetting):'',
                stopwordtextbox:this.props.synonmyslist,
                synonmystextbox:this.props.synonmyslist
            }
           )
    }

    componentDidUpdate(prevprops){
        
        if(this.props.importlangstart !== prevprops.importlangstart && prevprops.importlangstart == true) 
        {
           this.props.OnGetLanguage(this.props.config.BOTBUILDER_URL);
        }
        
        if(this.props.langdeletestart !== prevprops.langdeletestart && prevprops.langdeletestart == true)
        {
            this.props.OnGetLanguage(this.props.config.BOTBUILDER_URL);
            this.setState(
            {
                'deletelanguageShow':false,
                 selectedlangkey:null
            })
        }

        if(this.props.languageList !== prevprops.languageList)
        { 
            this.setLanguageArray(this.props.languageList);
        }
        if(this.props.messageSetting !== prevprops.messageSetting)
        {
            this.setState(
                {
                    messagetextbox: JSON.parse(this.props.messageSetting)
                }
            )
        }
        if(this.props.synonmyslist !== prevprops.synonmyslist)
        { 
            this.state.menuclicked == 'synonyms' ?
            this.setState({
                    synonmystextbox: this.props.synonmyslist
                }) 
            : 
            this.setState({
                    stopwordtextbox: this.props.synonmyslist
                }) 
        }
    }

    openAddLanguageDrawer = () => {
        this.setState({
            showAddLanguage: true
        })
    }

    addLanguageSubmission = (data) => {
        this.props.addLanguage(this.props.config.BOTBUILDER_PROXY, { "languageName": data },this.props.config.BOTBUILDER_URL)
        this.setState({
            showAddLanguage: false
        })
    }

    closeAddLanguageDrawer = () => {
        this.setState({
            showAddLanguage: false
        })
    }

    setLanguageArray(language) {  
        let language_array = language  && language.split(',');
        const uniquelanguage = [...new Set(language_array)]
        let templanglist = [];
        for(var i=0; i<uniquelanguage.length; i++){
            let lngvalue=uniquelanguage[i].split('_')[0];
            templanglist.push({"langkey":uniquelanguage[i].split('_')[1],"languagename":lngvalue.charAt(0).toUpperCase() +lngvalue.slice(1)});
        }
        this.setState({
                languagelist: templanglist,
                alllanguagelist:templanglist
            })
    }
    createUI=()=>{
        switch(this.state.menuclicked) {
            case 'editmessage':
             return (<div><h4>Default Messages</h4>{this.createMessageUI()}</div>);
            case 'synonyms':
               return this.createSynonymsUI();
            case 'stopword':
               return this.createStopWordUI();
            default:
             return null  
          }
    }

    createStopWordUI=()=>{
        return (
            <>
            <div className="form-group">
                <textarea className="form-control" rows="18" placeholder="Enter stop words" value={this.state.stopwordtextbox} onChange={(event)=>this.handleStopWordChange(event)}></textarea>
            </div>
            </>
        )
    }

    createSynonymsUI=()=>{
        return (
            <>
            <div className="form-group">
                <label>Similar Words</label>
                <textarea className="form-control" rows="18" placeholder="Enter synonyms using comma separator and place each group of synonyms in separate lines."  value={this.state.synonmystextbox} onChange={(event)=>this.handleSynonymsChange(event)}></textarea>
            </div>
            </>
        )
    }

    createMessageUI = () => {
        return Object.keys(this.state.messagetextbox).map((key) =>
        (<div className="form-group" key={key}>
            <InputWithLabelComponent
                placeHolder=''
                className={'drawer-input'}
                type="text"
                name="name"
                label={ key }
                value={this.state.messagetextbox[key]}
                onChange={(event) => this.handleMessageChange(event, key)}
            />
        </div>
        ));
    }
    
    handleMessageChange=(event,key)=>{
        let messagetextbox = {...this.state.messagetextbox};
        messagetextbox[key] = event.target.value;
        this.setState({ messagetextbox });

    }

    handleStopWordChange=(event)=>{
        this.setState({ stopwordtextbox: event.target.value });
    }

    handleSynonymsChange=(event)=>{
        this.setState({ synonmystextbox: event.target.value });
    }

    getMessage =(lang)=>{
    let orgName=localStorage.getItem("orgName");
    if(!orgName)
       orgName="default";
      const apiURL=this.props.config.BOTBUILDER_URL;
      const projectName=localStorage.getItem('projectname');
      let data =   {"project":projectName,"language":lang,"orgName":orgName} ;
      this.props.onGetMessagesettings(apiURL,data);
      this.setState({
            menuclicked:'editmessage',
            selectedlanguage:lang
        });
    }

    getSynonmys=(lang)=>{
    let orgName=localStorage.getItem("orgName");
    if(!orgName)
      orgName="default";
    const apiURL=this.props.config.API_URL;
    const projectName=localStorage.getItem('projectname');
    let data =   {"project":projectName,"language":lang,"orgName":orgName,"type":"synonym"} ;
    this.props.onGetSynonmys(apiURL,data);
    this.setState({
            menuclicked:'synonyms',
            selectedlanguage:lang
        });
    }

    getStopWord=(lang)=>{
        this.setState({
            stopwordtextbox : ''
        })
        let orgName=localStorage.getItem("orgName");
        if(!orgName)
           orgName="default";
        const apiURL=this.props.config.API_URL;
        const projectName=localStorage.getItem('projectname');
        let data =   {"project":projectName,"language":lang,"orgName":orgName,"type":"stopword"} ;
        this.props.onGetSynonmys(apiURL,data);
        this.setState({
                menuclicked:'stopword',
                selectedlanguage:lang
            });
    }

    setDefaultLanguage=(langname,langkey)=>{
        let orgName=localStorage.getItem("orgName");
        if(!orgName)
           orgName="default";
        const apiURL=this.props.config.API_URL;
        localStorage.setItem('language',langkey)
        this.props.onSetDefaultLanguage(apiURL,langname,langkey);
       this.setState({
            menuclicked:''
        });
        window.updateHCLChatBotURL(" ");
    }

    exportLang=(lang)=>{
        const apiURL=this.props.config.BOTBUILDER_URL;
        this.props.onLanguageExport(apiURL,lang);
        this.setState({
                menuclicked:'exportlang'
            });
    }

    handleImportLanguage=(e)=>{
        e.preventDefault()
        let orgName=localStorage.getItem("orgName");
        let langarray  = this.state.languagelist;
        if(!orgName)
           orgName="default";
        const apiURL=this.props.config.BOTBUILDER_URL;
        let langfilebinarydata = e.target.files[0]
        let fileName =null
        if(langfilebinarydata)
        fileName=langfilebinarydata.name
        let langList={
            language:langarray,
            filename:fileName
        }
        let formData = new FormData();
        formData.append('file', langfilebinarydata);
        this.props.onLanguageImport(apiURL,formData,langList);
    }

    deleteLang=(e)=>{
        e.preventDefault();
        let langkey = this.state.selectedlangkey;
        let orgName=localStorage.getItem("orgName");
        if(!orgName)
           orgName="default";
        const apiURL=this.props.config.BOTBUILDER_URL;
        let data =   {"deletingLanguage":langkey,"language":langkey} ;
         this.setState( {
            menuclicked:''
        });
        
        //Reset default language to english if selected language is defaultlanguage
        if(this.props.defaultlanginfo && this.props.defaultlanginfo.langkey == langkey)
        {
			localStorage.setItem('language','en');
            this.props.onSetDefaultLanguage(apiURL,"English","en");
        }
        this.props.onLanguageDelete(this.props.config.BOTBUILDER_PROXY,data);
    }

    showDelPopup=(langkey)=>{
        this.setState(
            {
                'deletelanguageShow':true,
                 selectedlangkey:langkey
            }
        )
    }

    handleClose(projectType) {
        if(projectType == "deletelanguage"){
            this.setState(
                {
                'deletelanguageShow':false,
                 selectedlangkey:null
               }
            )
        }
    }
    

    submitHandler = ( event ) => {
        event.preventDefault();
        let orgName=localStorage.getItem("orgName");
        if(!orgName)
           orgName="default";
        const apiURL=this.props.config.BOTBUILDER_URL;
        const projectName=localStorage.getItem('projectname');
        let data ={}
        if(this.state.menuclicked ==='editmessage')
        {
         data =   {"data":this.state.messagetextbox,"project":projectName,"language":this.state.selectedlanguage,"orgName":orgName} ;
         this.props.onUpdateMessageFile(apiURL,data);
        }
        else if(this.state.menuclicked ==='synonyms')
        { 
            
            data =   {"type":"synonym","text":this.state.synonmystextbox,"project":projectName,"language":this.state.selectedlanguage,"orgName":orgName} ;
            this.props.onUpdateSynonymsOrStopWord(apiURL,data);

        }
        else if(this.state.menuclicked ==='stopword')
        {
           data =   {"type":"stopword","text":this.state.stopwordtextbox,"project":projectName,"language":this.state.selectedlanguage,"orgName":orgName} ;
           this.props.onUpdateSynonymsOrStopWord(apiURL,data);
        }
        this.setState({
            menuclicked:''
        })
    }
     
    clearDataHandler = () => {
        let language = this.state.selectedlanguage;
        if (this.state.menuclicked === 'editmessage') {
            this.getMessage(language)
            this.setState(
                {
                    messagetextbox: JSON.parse(this.props.messageSetting),
                    menuclicked: ''
                }
            )
        }
        else if (this.state.menuclicked === 'synonyms') {
            this.getSynonmys(language)
            this.setState(
                {
                    synonmystextbox: this.props.synonmyslist,
                    menuclicked: ''
                });
        }
        else if (this.state.menuclicked === 'stopword') {
            this.getStopWord(language)
            this.setState({
                    stopwordtextbox: this.props.synonmyslist,
                    menuclicked: ''
                })
        }
    }

    onQuickFilterChanged = (event)=>{
       // set textbox value
        this.setState({
                langfilter:event.target.value
            })
        if(event.target.value!="" ){
            this.setState({show:false})
          }
          else{
            this.setState({show:true})
          }
        
        // convert value in lowercase
         const lowercasedFilter = event.target.value.toLowerCase();
        
         // filtered data
         const filteredData = this.state.alllanguagelist.filter(item => {
            return Object.keys(item).some(key =>
              item[key].toLowerCase().includes(lowercasedFilter)
            );
          });
         // Filtered lang list
         this.setState(
            {
                languagelist:  filteredData
            }
           )
     } 

     ClearKnowledgeIntentSearch = ()=>{
        this.setState({langfilter:"",show:true});
        let language_array = this.props.languageList  && this.props.languageList.split(',');
        const uniquelanguage = [...new Set(language_array)]
        let templanglist = [];
        for(var i=0; i<uniquelanguage.length; i++){
            let lngvalue=uniquelanguage[i].split('_')[0];
            templanglist.push({"langkey":uniquelanguage[i].split('_')[1],"languagename":lngvalue.charAt(0).toUpperCase() +lngvalue.slice(1)});
        }
        
        this.setState(
            {
                languagelist: templanglist,
                alllanguagelist:templanglist
            }
           )
    }

    render () {
        let langSaveLoader=null;
        let langsettingloader =null;
        
        let langarray  = this.state.languagelist;
        let languagekey = localStorage.getItem("language");
        if(!languagekey){
          languagekey="en";
          }
       
        if(this.props.importlangstart)
        {
            langsettingloader = (<SpinnerPage/>)
        }
       
        if(this.props.langLoading)
        {
             langSaveLoader=  (<SpinnerPage/>)
        }
        return(
            <div className={classes.contentTableOuter}>
                <AddLanguageDrawer
                     languagelist={this.state.languagelist}
                    show={this.state.showAddLanguage}
                    onSubmission={this.addLanguageSubmission}
                    onHide={this.closeAddLanguageDrawer}
                />
             {langsettingloader}
             {langSaveLoader}
                <div className='d-flex'>
                    <h3 className='mr-3 ml-2 mt-1'>Language Settings</h3>
                    <ButtonComponent
                        label='ADD NEW LANGUAGE'
                        variant='contained'
                        clicked={this.openAddLanguageDrawer}
                        className='buttonWidth add-lang-button'
                        disabled={!this.props.permissions.RW}
                    />
                </div>
                <div className='ContentWithSearch'>
                <span className='paraGraphContent'>Provision to add the languages to be supported by the application and to set any of those languages as default.</span>
                </div>
                <div className="row mb-2">
                    <div className="col">
                    </div>
                    <div className="col">
                    </div>
                    <div className="col">
                    </div>
                    <div className="col mr-2 mb-2">
                        <SearchComponent
                            class={"input-group searchClass"}
                            placeholder="Search"
                            value={this.state.langfilter}
                            onSearch={(event) => this.onQuickFilterChanged(event)}
                            Clear={this.ClearKnowledgeIntentSearch} 
                            show ={this.state.show}
                        />
                    </div>
                </div>
                <div className='m-2'>
                    <div className="row">
                        {langarray && langarray !== undefined &&
                            langarray.length > 0 && langarray.map((lang, index) =>
                            (
                                <div className='col-4 mb-4' key={lang.langkey}>
                                    <CardComponent >
                                        <span> {lang.languagename} </span>
                                        {languagekey === lang.langkey && <DefaultButton
                                            variant='outlined'
                                            label='DEFAULT LANGUAGE'
                                            labelStyles={{marginBottom:"5px",cursor:"default"}}
                                            className='buttonClass default-button'
                                        />}

                                        <Dropdown key={index} className={[classes.dropdown, classes.languagedropdown].join(' ')}>
                                            <Dropdown.Toggle className={classes['dropdown-toggle']} >
                                                <img src='./Icons/ActionMenu.svg' height={10} />
                                            </Dropdown.Toggle>
                                            {lang.langkey === languagekey ?
                                                <Dropdown.Menu className='lang-menu'>
                                                    <Dropdown.Item
                                                     className={this.props.permissions.RW ? `${classes.sideDropdown} sideDropdown` : `${classes.sideDropdown} sideDropdown click-access`} 
                                                    onClick={() => this.getMessage(lang.langkey)}>EDIT</Dropdown.Item>
                                                </Dropdown.Menu>
                                                :
                                                <Dropdown.Menu className='lang-menu' >
                                                    <Dropdown.Item className={`${classes.sideDropdown} sideDropdown`} onClick={() => this.setDefaultLanguage(lang.languagename, lang.langkey)}>SET AS DEFAULT</Dropdown.Item>
                                                    <Dropdown.Item className={this.props.permissions.RW ? `${classes.sideDropdown} sideDropdown` : `${classes.sideDropdown} sideDropdown click-access`}
                                                        onClick={() => this.getMessage(lang.langkey)}>EDIT</Dropdown.Item>
                                                    {lang.langkey !== "en" && <Dropdown.Item className={this.props.permissions.RW ? `${classes.sideDropdown} sideDropdown` : `${classes.sideDropdown} sideDropdown click-access`}
                                                        onClick={() => this.showDelPopup(lang.langkey)}>DELETE</Dropdown.Item>}
                                                </Dropdown.Menu>
                                            }
                                        </Dropdown>
                                    </CardComponent>
                                </div>
                            )
                            )}
                    </div>
                </div>             
                <EditLanguageDrawer
                    heading={this.state.menuclicked == "editmessage" ? 'Language Details' : "Similar Words Details"}
                    show={this.state.menuclicked}
                    onHide={this.clearDataHandler}
                >
                    {
                        this.createUI()
                    }
                    <ButtonComponent
                        variant='contained'
                        label='SAVE'
                        clicked={this.submitHandler}
                        className='buttonWidth'
                    />
                </EditLanguageDrawer>
          <DeleteModal
          show={this.state.deletelanguageShow}
          deleteModal={() => this.handleClose("deletelanguage")}
          title="Delete Confirmation"
          bodyTitle="Deleting a language will delete all the projects associated with it. Are you sure you want to delete the language?"
          buttonClick={(e) => this.deleteLang(e)}
        />
     </div>
        
        );
     }
 }
    const mapStateToProps = state => {
        return {
            setting:state.user.settings,
            permissions:state.user.moduleAccess.conversationalAdmin,
            messageSetting:state.user.messageSetting,
            messagesettingstart:state.user.messagesettingstart,
            synonmyslist:state.user.synonmyslist,
            projectlistbylang:state.user.projectlistbylang,
            defaultlanginfo:state.user.defaultlanginfo,
            langdeletestart:state.user.langdeletestart,
            importlangstart:state.user.importlangstart,
            languageList:state.user.languageList,
            langLoading:state.user.langLoading
        };
    };
    const mapDispatchToProps = dispatch => {
        return {
           onGetMessagesettings: (apiUrl,data) => dispatch( actions.GetMessagesettings(apiUrl,data)),
           onGetSynonmys: (apiUrl,data) => dispatch( actions.GetSynonmys(apiUrl,data)),
           onLanguageExport: (apiUrl,lang) => dispatch( actions.LanguageExport(apiUrl,lang)),
           onGetProjectByLanguage: (apiUrl,data) => dispatch( actions.GetProjectByLanguage(apiUrl,data)),
           onUpdateMessageFile:(apiUrl,data) => dispatch( actions.UpdateMessageFile(apiUrl,data)),
           onSetDefaultLanguage:(apiUrl,langname,langkey) => dispatch( actions.SetDefaultLanguage(apiUrl,langname,langkey)),
           onUpdateSynonymsOrStopWord:(apiUrl,data) => dispatch( actions.UpdateSynonymsOrStopWord(apiUrl,data)),
           onLanguageDelete:(apiUrl,data) => dispatch( actions.LanguageDelete(apiUrl,data)),
           OnGetSettings:(apiUrl) => dispatch( actions.GetSettings(apiUrl)),
           onLanguageImport: (apiUrl,data,list) => dispatch( actions.LanguageImport(apiUrl,data,list)),
           addLanguage:(apiUrl,data,oldURL) => dispatch( actions.addLanguage(apiUrl,data,oldURL)),
           OnGetLanguage: (apiUrl) => dispatch(actions.GetLanguage(apiUrl)),
        };
    };

    export default connect( mapStateToProps, mapDispatchToProps )(LanguageSettingPage);
