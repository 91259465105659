// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.heading5 {
    font-family: 'Roboto-Black';
    font-size: 12px;
    Color: #000000;
}`, "",{"version":3,"sources":["webpack://./src/containers/SettingPage/SettingInnerPages/components/Typography/Heading5/heading5.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,eAAe;IACf,cAAc;AAClB","sourcesContent":[".heading5 {\n    font-family: 'Roboto-Black';\n    font-size: 12px;\n    Color: #000000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
