import * as actionTypes from './actionTypes';
import { botPostCalltoServer, GetCalltoServer, deleteCalltoServer } from '../utility';
import { SetErrorMessage, SetSuccessMessage, setWarMessage} from './message';
import { decrypt } from '../../utility/utility';

const orgName = localStorage.getItem("orgName");
const username = localStorage.getItem("userName");

export const getCognitiveStart = () => {
    return {
        type: actionTypes.GET_COGNITIVE_START
    };
};
export const getCognitiveFailure = (error) => {
    return {
        type: actionTypes.GET_COGNITIVE_FAILURE, error
    };
};
export const getCognitiveSuccess = (input) => {
    return { type: actionTypes.GET_COGNITIVE_SUCCESS,input }
}
//export const updateValue=(inVal)=>{
    //return (dispatch)=>{
       // dispatch(getCognitiveSuccess(inVal))
    //};   
//};

export const cognitiveData = (flow, apiURL) => {
    return dispatch => {
        dispatch(getCognitiveStart());
        let queryText = flow["query"];
        let configObj={};
        configObj["project"]=localStorage.getItem("projectname");
        configObj["query"]=queryText;
        configObj["language"]=localStorage.getItem("language");
        //let url = apiURL + `/cognitiveSearch`;
        let url = apiURL + `/cognitive/cognitivesearch`;
        botPostCalltoServer(url, configObj).then(response => {
            if (response.data.status.responseType == "success") {
                //let cognitive = JSON.parse(response.data.data)
                let cognitive = response.data.data;
                if(cognitive == null || cognitive.length <=0){
                    cognitive=[{"response":"Please rephrase your question"}];
                }
                dispatch(SetSuccessMessage("success"));
                dispatch(getCognitiveSuccess(cognitive))

            }else{ 
                dispatch(SetErrorMessage(JSON.parse(response.data.errors).error))
                dispatch(getCognitiveFailure(null))
            }
            
        }).catch(err => {            
            dispatch(getCognitiveFailure(err))
        });
    };
};

export const getUrlMiningStart = (loading) => {
    return {
        type: actionTypes.GET_URLMINING_START,loading
    };
};
export const getUrlMiningFailure = (input,error,loading) => {
    return {
        type: actionTypes.GET_URLMINING_FAILURE, input,error,loading
    };
};
export const getUrlMiningSuccess = (input,loading) => {
    return { type: actionTypes.GET_URLMINING_SUCCESS,input,loading }
}

export const urlMining = (apiURL, data) => {
    return dispatch => {
        dispatch(getUrlMiningStart(true));  
            let url = apiURL + `/urlmining`;
            botPostCalltoServer(url, data).then(response => {
                if(response.data.status.statusCode=="206"){
                    let error = response.data.errors;
                    var subString = '.....';
                    var length = 180;
                    if(error.length > length){
                        error = error.slice(0, length) + subString;
                    }
                    dispatch(getUrlMiningFailure(null,false));
                    dispatch(SetErrorMessage(error));
                }
                 else if(response.data.data.length>0){
                    dispatch(getUrlMiningSuccess(response.data.data,false)); 
                    dispatch(SetSuccessMessage("URL mining completed successfully"))
                    // this.setState({
                    //     minedUrl:response.data.response[1],
                    //     circleColorFlag:true,
                    //     viewCrawledUrls:true
                    // })
                    // SetSuccessMessage("URLs Mining Done...")
                }
                else{
                    dispatch(getUrlMiningFailure(response.data.data,null,false));
                    dispatch(SetErrorMessage("No URLs found"))
                    // this.setState({
                    //     minedUrl:[]
                    // })
                    // SetErrorMessage("Something wrong in URLs Mining...");
                }
            }).catch(err => {
                dispatch(getUrlMiningFailure(null,false));
                console.log(err);
            });
    };
};
export const DownloadDataStart = (loading) =>{
    return {
        type: actionTypes.DOWNLOAD_DATA_START,loading
}
}


export const getDownloadCrawlDataStart = (loading) => {
    return {
        type: actionTypes.GET_DOWNLOAD_CRAWL_DATA_START,loading
    };
};
export const getDownloadCrawlDataFailure = (error,loading) => {
    return {
        type: actionTypes.GET_DOWNLOAD_CRAWL_DATA_FAILURE, error,loading
    };
};
export const getDownloadCrawlDataSuccess = (input,loading) => {
    return { type: actionTypes.GET_DOWNLOAD_CRAWL_DATA_SUCCESS,input,loading };
}

export const downloadCrawledData = (apiURL, data) => {
    return dispatch => {
        dispatch(getDownloadCrawlDataStart(true))
        let url = apiURL+ `/webcrawlAsync`;
        if(data.urls.length <=50 && data.urls.length >= 1){
        botPostCalltoServer(url, data).then(response => {
            if ( response.data.data.downloadData != "") {
            dispatch(getDownloadCrawlDataSuccess(response.data.data.downloadData,false))
            dispatch(SetSuccessMessage("Webcrawling completed successfully"))
            }
            if (response.data.status.statusCode =="206") {
                dispatch(getDownloadCrawlDataFailure(null,false))
               dispatch(setWarMessage("it is taking more time that expected please go to download data page to see the download result after some time"))
            }
            // if (response.data.status == "success") {
            //     dispatch(getDownloadCrawlDataSuccess(response.data,false))
            //    dispatch(SetSuccessMessage("Web crawling is successfull"))
            // }
            // else if(response.data.status == "partialsuccess") {
            //     dispatch(getDownloadCrawlDataSuccess(response.data,false))
            //     dispatch(SetSuccessMessage("Web crawling is partial successful"))
            // }
            // else if(response.data.status == "partialfailure") {
            //     dispatch(getDownloadCrawlDataFailure(response.data,false))
            //     dispatch(SetErrorMessage("Web crawling is partially fail"))
            // }
            // else if(response.data.status == "failure") {
            //     dispatch(getDownloadCrawlDataFailure(null,false))
            //     dispatch(SetErrorMessage("Web crawling failed"))
            // }
        })
        
        .catch(() => {
            dispatch(getDownloadCrawlDataFailure(null,false))
            dispatch(SetErrorMessage("Error occured during Downloading Crawled Data"))
        });
    }
    else{
        dispatch(getDownloadCrawlDataStart(false))
        dispatch(SetErrorMessage("please select urls below 50"))
    }
    };
};


export const deleteMultimediaRowStart = (loading) => {
    return {
        type: actionTypes.DELETE_MULTIMEDIA_ROW_START,loading
    };
};
export const deleteMultimediaRowFailure = (loading) => {
    return {
        type: actionTypes.DELETE_MULTIMEDIA_ROW_FAIL, loading
    };
};
export const deleteMultimediaRowSuccess = (loading) => {
    return { type: actionTypes.DELETE_MULTIMEDIA_ROW_SUCCESS,loading };
}

export const deleteMultimediaRow = (url,configObj) => {
    return dispatch => {
        dispatch(deleteMultimediaRowStart(false))
        //let url = apiURL+ `/webcrawlAsync`;
        botPostCalltoServer(url, configObj).then(response => {
            if(response.data.status == "success"){
                dispatch(deleteMultimediaRowStart(true))
                dispatch(SetSuccessMessage("File deleted successfully"))
                
            }
            else{
                dispatch(deleteMultimediaRowFailure(false))
                dispatch(SetErrorMessage("File not deleted successfully"))
            }
        }).catch(() => {
            dispatch(deleteMultimediaRowFailure(false))
            dispatch(SetErrorMessage("Error in deleting multimedia file"))
        });
    };
};


export const deleteAllMultimediaRowStart = (loading) => {
    return {
        type: actionTypes.DELETE_MULTIMEDIA_ROW_START,loading
    };
};
export const deleteAllMultimediaRowFailure = (loading) => {
    return {
        type: actionTypes.DELETE_MULTIMEDIA_ROW_FAIL, loading
    };
};
export const deleteAllMultimediaRowSuccess = (loading) => {
    return { type: actionTypes.DELETE_MULTIMEDIA_ROW_SUCCESS,loading };
}

export const deleteAllMultimediaRow = (url,configObj) => {
    return dispatch => {
        dispatch(deleteMultimediaRowStart(false))
        //let url = apiURL+ `/webcrawlAsync`;
        deleteCalltoServer(url, configObj).then(response => {
            if(response.data.status.responseType == "success"){
                dispatch(deleteMultimediaRowStart(true))
                dispatch(SetSuccessMessage(response.data.data))
                
            }
            else{
                dispatch(deleteMultimediaRowFailure(false))
                dispatch(SetErrorMessage(response.data.data))
            }
        }).catch(() => {
            dispatch(deleteMultimediaRowFailure(false))
            dispatch(SetErrorMessage("Error in file(s) deletion"))
        });
    };
};


export const editMultimediaRowStart = (edited) => {
    return {
        type: actionTypes.EDIT_MULTIMEDIA_ROW_START,edited
    };
};
export const editMultimediaRowFailure = (edited) => {
    return {
        type: actionTypes.EDIT_MULTIMEDIA_ROW_FAILURE, edited
    };
};
export const editMultimediaRowSuccess = (edited) => {
    return { type: actionTypes.EDIT_MULTIMEDIA_ROW_SUCCESS,edited };
}

export const editMultimediaRow = (url,configObj) => {
    return dispatch => {
        dispatch(editMultimediaRowStart(false))
        fetch(url, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,"orgName":orgName,"userName":username
            },
            body: configObj,
        }).then(response => {
            return response.text();
        }).then(datas => {
            
            let result = JSON.parse(datas);
            if (result.status == "success") {   
                dispatch(editMultimediaRowSuccess(true))                     
                dispatch(SetSuccessMessage("File updated successfully"))                
            }else{
                dispatch(editMultimediaRowFailure(false))
                dispatch(SetErrorMessage("File not updated successfully"))
            }
        })
            .catch((error) => {
                dispatch(editMultimediaRowFailure(false))
                dispatch(SetErrorMessage("Error in updating multimedia file"))
            })
    };
};

// export const saveCognitiveFlowStart = () => {
//     return {
//         type: actionTypes.SAVE_COGNITIVE_FLOW_START
//     };
// };
// export const saveCognitiveFlowFailure = () => {
//     return {
//         type: actionTypes.SAVE_COGNITIVE_FLOW_FAILURE
//     };
// };
// export const saveCognitiveFlowSuccess = () => {
//     return { type: actionTypes.SAVE_COGNITIVE_FLOW_SUCCESS };
// }

export const saveCognitiveFlow = (url,data) => {
    return dispatch => {
        //dispatch(saveCognitiveFlowStart())
        botPostCalltoServer(url, data).then(response => {
            if(response.data.status.responseType == "success"){
                //dispatch(saveCognitiveFlowSuccess())
                dispatch(SetSuccessMessage("Cognitive flow management data saved successfully"))
            }
            else{
                //dispatch(saveCognitiveFlowFailure())
                dispatch(SetErrorMessage("Cognitive flow management data not saved successfully"))
            }
        }).catch(() => {
            //dispatch(saveCognitiveFlowFailure())
            dispatch(SetErrorMessage("Error in Cognitive flow management data save"))
        });
    };
};


export const getCognitiveFlowStart = () => {
    return {
        type: actionTypes.GET_COGNITIVE_FLOW_START
    };
};
export const getCognitiveFlowFailure = () => {
    return {
        type: actionTypes.GET_COGNITIVE_FLOW_FAILURE
    };
};
export const getCognitiveFlowSuccess = (cognitiveData) => {
    return { type: actionTypes.GET_COGNITIVE_FLOW_SUCCESS, cognitiveData};
}

export const getCognitiveFlow = (url,data) => {
    return dispatch => {
        dispatch(getCognitiveFlowStart())
        GetCalltoServer(url, data).then(response => {
            if(response.data.status.responseType == "success"){
                dispatch(getCognitiveFlowSuccess(response.data.data))
                //dispatch(SetSuccessMessage("Cognitive flow management data saved successfully"))
            }
            else{
                dispatch(getCognitiveFlowFailure())
                //dispatch(SetErrorMessage("Cognitive flow management data not saved successfully"))
            }
        }).catch(() => {
            dispatch(getCognitiveFlowFailure())
            //dispatch(SetErrorMessage("Error in Cognitive flow management data save"))
        });
    };
};