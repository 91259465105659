import React, { Component } from 'react'
import classes from './SettingPage.module.css';
import { Col } from 'react-bootstrap';
import SelfContainedTickets from './SettingInnerPages/SelfContainedTickets';
import LanguageSettingPage from './SettingInnerPages/LanguageSettingPage';
import InsightsPage from './SettingInnerPages/InsightsPage';
import BackupRestore from './SettingInnerPages/BackupRestore/BackupRestore';
import IntegrationsPage from './SettingInnerPages/IntegrationsPage';
import KnowledgeSearch from '../../components/KnowledgeSearch/KnowledgeSearch'
import AdminHome from './SettingInnerPages/Insight/AdminConfiguration/AdminHome'
import UserManagementTabs from './SettingInnerPages/UserManagementTabs';
import RelationshipMapping from '../SettingPage/SettingInnerPages/Insight/AdminConfiguration/TraiageConfigurationTabs/RelationshipMapping';
import SymptomAndRootCauseTab from '../SettingPage/SettingInnerPages/Insight/AdminConfiguration/SymtomsAndRootCauseTab/SymptomAndRootCauseTab';
import { connect } from 'react-redux';
import TreeCustom from "../../components/UIComponents/TreeComponent/TreeComponent";
import getProjectDetails from "./SettingInnerPages/helpers/getProjectDetails";
import ToastMessage from '../../components/UIComponents/ToastMessage/ToastMessage';
import SetupCrawler from './SettingInnerPages/KnowledgeMining/SetupCrawler';
import DownloadData from './SettingInnerPages/KnowledgeMining/DownloadData';
import { getConnectionsData } from '../../store/actions';
import * as actions from "../../store/actions/index";
import { get, isEqual } from 'lodash';
import permissions from '../../permissions_Json';
import CrmLayout from "./SettingInnerPages/AgentCockpit/CrmLayout/CrmLayout";
import VirtualAssistantContainer from './SettingInnerPages/VirtualAssistant/VirtualAssistantContainer';
import ModelOutput from './SettingInnerPages/UnsupervisedML/modelOutput';
import DataReview from './SettingInnerPages/UnsupervisedML/dataReview';
import UseCase from './SettingInnerPages/UnsupervisedML/UseCase';
import IngestPredictionData from './SettingInnerPages/UnsupervisedML/IngestPredictionData';


class SettingPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      sideContent:
      {
        selectedTab: isEqual(get(props.history, 'location.state'),undefined) || 
        isEqual(get(props.history, 'location.state'),null) ? 'VirtualAssistant' : get(props.history, 'location.state','')
      },
      ticketSystems: [],
      knowledgeBase: [],
      showIntegrationsTab: false,
      selectedMenu: '',
      selectedSubMenu: '',
      hasUserManagementAccess:false,
      showUserMTabClass:'hideTriage',
      showUserMTab:false,
      showTriageTabClass:'hideTriage',
      showTriageTab:false,
      hasTriagePageAccess:false,
      selectedLabel:isEqual(get(props.history, 'location.state'),undefined) || isEqual(get(props.history, 'location.state'),null) ? 'Virtual Assistant' : get(props.history, 'location.state',''),//'Language Settings',
      expanded:['Virtual Agent'],
      projectname:getProjectDetails(),
      projectViewError:null,
      showingAlert:false,
    }
  }
  closeModal=()=>{
    this.setState({ showingAlert: false });
  }
  CheckIfProjectSelected=()=>{
    let projectViewError=null;
    if(this.state.projectname==null){        
      setTimeout(() => {
         this.setState({
           showingAlert: false     
         },()=>{
          projectViewError=null;
          this.setState({projectViewError:projectViewError});
         });
        
       }, 3000); 
      this.setState({ showingAlert: true }, () => {                              
        projectViewError=(  
          <ToastMessage closeModal={this.closeModal} 
              showingAlert={this.state.showingAlert} 
              message="Please select project" customClass='warning'/>   
        );
        this.setState({projectViewError:projectViewError});
      });      
    }
  }

  showTab(content) {
    this.setState({
      sideContent: {
        selectedTab: content
      }
    });
    if(content == 'triageConfiguration'){
      this.props.history.push({
        pathname: '/settings',
            search: '?' + 'ticketsInsights'
        });
    }else if(content == 'Integrations'){
      this.props.history.push({
        pathname: '/settings',
            state: "Integrations"
        });
    }else if(content == 'VirtualAssistant'){
      this.props.history.push({
        pathname: '/settings',
            state: "VirtualAssistant"
        });
    }
    else{
      this.props.history.push({
        pathname: '/settings',
            search: ''
        });
    }
  }

  addConnections = (selectedTicketSystems, selectedKnowledgeBase) => {
    this.setState({ 
      ticketSystems: selectedTicketSystems
    });
    this.setState({ 
      knowledgeBase: selectedKnowledgeBase
    });
  }

  showIntegrationPage = (tabDetails) => {
    this.setState({
      sideContent:
      {
        selectedTab: 'Integrations'
      },
      selectedMenu: tabDetails.selectedMenu,
      selectedSubMenu: tabDetails.selectedSubMenu
    })
  }

  showUserManagementPage = (tabDetails) => {
    this.setState({
      sideContent:
      {
        selectedTab: 'showUser'
      },
      selectedMenu: tabDetails.selectedMenu
    })
  }

  TriageTabs(res) {
    if(res=='show') {
      this.setState({showTriageTab:true,showTriageTabClass:'showTriage'})
    }else{ 
      this.setState({showTriageTab:false,showTriageTabClass:'hideTriage'})
    }
  }

  userManagementTabs(res) {
    if(res=='show') {
      this.setState({showUserMTab:true,showUserMTabClass:'showTriage'})
    }else{ 
      this.setState({showUserMTab:false,showUserMTabClass:'hideTriage'})
    }
  }

  UMTabSelected(menu) {
    this.setState({selectedMenu:menu});
  }

  componentDidMount() {
    this.setState({hasUserManagementAccess:this.hasPermission('usermanagement_R')||this.hasPermission('usermanagement_RW')})
    this.setState({hasTriagePageAccess:this.hasPermission('triageconfig_RW')})
    this.props.OnProjectHeaderShow(false)
  }

  hasPermission = (code) => {
       if(this.props.permissions.find((data)=>data.permission_code.includes(code)))
        return true;
       else return false;
    }
    onChangeAdminHome=()=>{
      this.showTab('SymptomAndRootCauseTab')
    }
    
    onNodeChange = (e, nodes) => {
   this.setState({
    expanded:nodes
  })
  };

    selectedNode=(nodeName)=>{
      if(nodeName=="Language"){
       this.showTab('showLanguage')
      }
      if(nodeName=="Agent Transfer"){
       this.showTab('agentTransfer')
      }
       if(nodeName=="Personalized Theme"){
       this.showTab('virtualAssistant')
      }
      if(nodeName=="Virtual Assistant"){
        this.showTab('VirtualAssistant')
       }
      if(nodeName=="Integrations"){
        this.showTab('Integrations')
       }
      if(nodeName=="Layout"){
       this.showTab('TicketInsights')
      }
      if(nodeName=="CRM Layout"){
        this.showTab('crm layout')
       }
      if(nodeName=="view"){
        this.showTab('IntegrationView')
       }
     if(nodeName=="Setup Triage Attributes & Train Model"){
       this.showTab('triageConfiguration')
       this.CheckIfProjectSelected()
      }
      if(nodeName=="Triage Data"){
         this.showTab('SymptomAndRootCauseTab')
         this.CheckIfProjectSelected()
      }
      if(nodeName=="Symptoms & Root Causes mapping"){
        this.showTab('RelationshipMapping')
        this.CheckIfProjectSelected()
    
      }
       if(nodeName=="Cognitive Search"){
       this.showTab('KnowledgeSearch')
      }
      if(nodeName=="Schedule Meeting"){
        this.showTab('ScheduleMeeting')
       }
       if(nodeName=="Cognitive Flow Management"){
        this.showTab('CsFlowManagement')
       }
       if(nodeName=="Continuous Learning Analysis"){
        this.showTab('ContinuousLearningAnalysis')
       }
       if(nodeName=="Model Output"){
        this.showTab('ModelOutput')
       }
       if(nodeName=="Ingest Prediction Data"){
        this.showTab('IngestPredictionData')
       }
       if(nodeName=="Data Review"){
        this.showTab('DataReview')
       }
       if(nodeName=="Use Cases"){
        this.showTab('UseCase')
       }
       if(nodeName=="Setup Crawler"){
        this.showTab('SetupCrawler')
       }
       if(nodeName=="Downloaded Data"){
        this.showTab('DownloadData')
       }
      if(nodeName=="Embedded Tickets"){
       this.showTab('selfContain')
      }
      if(nodeName=="User Groups"){
       this.showTab('showUser');
       this.UMTabSelected('usergroups')
      }
      if(nodeName=="Roles"){
       this.showTab('showUser');
       this.UMTabSelected('roles')
      }
      if(nodeName=="Users"){
       this.showTab('showUser');
       this.UMTabSelected('users')
      }
      if(nodeName=="Application Info"){
       this.showTab('applicationInfo')
      }
       if(nodeName=="BackUp & Restore"){
       this.showTab('BackupRestore')
      }
      if(nodeName=="Conversation Management"){
        this.showTab('conversationManagement')
       }
      this.setState({
        selectedLabel:nodeName
      })
    }
  
  render() {
    let permissionsCount=0;
    let  accessForLVA = false;
    let obj = this.props.permissions.find(data => {
      if (data.permission_code.includes(permissions.permissionCode.CONVERSATIONAL_AI_ADMIN_R))
        return true
    })
    accessForLVA = obj ? true : false;
    if(accessForLVA){
      permissionsCount++;
    }
    //-------------unsupervised Learning--------------
    let  readAccessForUnsupervisedML = false;
    let adminReadAccessStatusForUnsupervisedML = this.props.permissions.find(data => {
      if (data.permission_code.includes(permissions.permissionCode.COGNITIVE_SEARCH_ADMIN_R) && data.sku_code.includes(permissions.skuCode.CS))
        return true
    })
    readAccessForUnsupervisedML = adminReadAccessStatusForUnsupervisedML ? true : false;
    if(readAccessForUnsupervisedML){
      permissionsCount++;
    }
    //--------------unsupervised Learning-----------------
    let  readAccessForKnowlwdgeMining = false;
    let adminReadAccessStatus = this.props.permissions.find(data => {
      if (data.permission_code.includes(permissions.permissionCode.COGNITIVE_SEARCH_ADMIN_R) && data.sku_code.includes(permissions.skuCode.CS))
        return true
    })
    readAccessForKnowlwdgeMining = adminReadAccessStatus ? true : false;
    if(readAccessForKnowlwdgeMining){
      permissionsCount++;
    }
    let  readAccessForTicketsInsights = false;
    let TIStatus = this.props.permissions.find(data => {
      if (data.permission_code.includes(permissions.permissionCode.TICKETS_INSIGHTS_R) && data.sku_code.includes(permissions.skuCode.STANDARD))
        return true
    })
    readAccessForTicketsInsights = TIStatus ? true : false;

    let  userManagement_Read = false;
    let userManagement_Read_Status = this.props.permissions.find(data => {
      if (data.permission_code.includes(permissions.permissionCode.USERMANAGEMENT_R) && data.sku_code.includes(permissions.skuCode.STANDARD))
        return true
    })
    userManagement_Read = userManagement_Read_Status ? true : false;

    let  integration_Read = false;
    let integration_Read_Status = this.props.permissions.find(data => {
      if (data.permission_code.includes(permissions.permissionCode.INTEGRATIONM_R) && data.sku_code.includes(permissions.skuCode.STANDARD))
        return true
    })
    integration_Read = integration_Read_Status ? true : false;

    let  productTriageAdmin_Read = false;
    let productTriageAdmin_Read_Status = this.props.permissions.find(data => {
      if (data.permission_code.includes(permissions.permissionCode.PRODUCT_TRIAGE_ADMIN_R) && data.sku_code.includes(permissions.skuCode.PT))
        return true
    })
    productTriageAdmin_Read = productTriageAdmin_Read_Status ? true : false;
    if(productTriageAdmin_Read){
      permissionsCount++;
    }

   
    const { sideContent,selectedMenu } = this.state;
    if(sideContent.selectedTab == 'showLanguage' && !accessForLVA && permissionsCount){
        if(productTriageAdmin_Read){
          this.setState({
            expanded:['Agent Cockpit']
          })
          this.selectedNode("Layout");
        }else if(readAccessForKnowlwdgeMining){
          this.setState({
            expanded:['Knowledge Mining']
          })
          this.selectedNode("Setup Crawler");
        }else if(readAccessForUnsupervisedML){
          this.setState({
            expanded:['Unsupervised ML']
          })
          this.selectedNode("Model Output");
        }
    }
    
    return (<>
    {this.state.projectViewError}
    <div className={classes.settingsPage}>
      <Col md={2} className={classes.leftSidePanel}>
        <h4>Settings</h4>
          {accessForLVA && <>
            <div className={accessForLVA ? classes.integrationSidebar : classes.disableIntegrationSidebar}
              onClick={() => { this.showTab('VirtualAssistant'); }}
              style={{ color: sideContent.selectedTab == 'VirtualAssistant' ? "#5A85F2" : "" }}><a>Virtual Assistant</a></div>
            <div className={accessForLVA ? classes.integrationSidebar : classes.disableIntegrationSidebar}
              onClick={() => { this.showTab('showLanguage'); }}
              style={{ color: sideContent.selectedTab == 'showLanguage' ? "#5A85F2" : "" }}><a>Language</a></div>
          </>}
      {productTriageAdmin_Read && <>
      <div className={productTriageAdmin_Read ? "" :classes.disablelabel}>
        <TreeCustom ariaLabel="Agent Cockpit" label="Agent Cockpit" 
                onNodeChange={this.onNodeChange}
                expanded={this.state.expanded}
        nodeList={["Layout","CRM Layout"]} 
        selectedItem={this.selectedNode} selectedLabel={this.state.selectedLabel}/>
        </div>
        </>}
        <div className={this.props.ptModelTraining || productTriageAdmin_Read ? "" :classes.disablelabel}>
        <TreeCustom ariaLabel="Triage" label="Triage" 
        nodeList={["Setup Triage Attributes & Train Model","Triage Data","Symptoms & Root Causes mapping"]}  
        selectedItem={this.selectedNode} selectedLabel={this.state.selectedLabel}/>
        </div>
         {/*//commented due to release 12.1.7*/}
        {/*readAccessForUnsupervisedML &&
        <div className={readAccessForUnsupervisedML ? "" :classes.disablelabel}>
        <TreeCustom ariaLabel="ML Solutions" label="ML Solutions"
        nodeList={["Use Cases","Model Output","Data Review","Ingest Prediction Data"]}
        onNodeChange={this.onNodeChange}
        selectedItem={this.selectedNode} selectedLabel={this.state.selectedLabel} expanded={this.state.expanded}/>
      </div>*/}
        {readAccessForKnowlwdgeMining &&
        <div className={readAccessForKnowlwdgeMining ? "" :classes.disablelabel}>
        <TreeCustom ariaLabel="Knowledge Mining/Sharing" label="Knowledge Mining"
        nodeList={["Setup Crawler","Downloaded Data"]}
        onNodeChange={this.onNodeChange}
        selectedItem={this.selectedNode} selectedLabel={this.state.selectedLabel} expanded={this.state.expanded}/>
        </div>}
        <div className={readAccessForTicketsInsights ? "" :classes.disablelabel}>
        <TreeCustom ariaLabel="Ticket Insights" label="Ticket Insights" 
        nodeList={["Embedded Tickets"]}
        selectedItem={this.selectedNode} selectedLabel={this.state.selectedLabel}/>
        </div>
       <div className={integration_Read ? classes.integrationSidebar : classes.disableIntegrationSidebar} 
       onClick={() => {this.showTab('Integrations'); this.props.getConnectionsData(this.props.config)}} style={{color:sideContent.selectedTab == 'Integrations' ? "#5A85F2": ""}}><a>Integrations</a></div>
      <div className={userManagement_Read ? "" :classes.disablelabel}>
        <TreeCustom ariaLabel="User Management" label="User Management"
        nodeList={["User Groups","Roles","Users"]}
        selectedItem={this.selectedNode} selectedLabel={this.state.selectedLabel}/>
      </div>
      </Col>

      {(sideContent.selectedTab == 'showLanguage' && accessForLVA) && <Col md={10} className={classes.contentPanel}><LanguageSettingPage config={this.props.config}></LanguageSettingPage></Col>}
      {sideContent.selectedTab == 'Integrations' &&  <Col md={10} className={classes.contentPanel}><IntegrationsPage  config={this.props.config} permissions={this.props.permissions} /></Col>}
      {sideContent.selectedTab == 'showUser' && <Col md={10} className={classes.contentPanel}><UserManagementTabs config={this.props.config} selectedMenu={selectedMenu} /></Col>}
      {sideContent.selectedTab == 'selfContain' && <Col md={10} className={classes.contentPanel}><SelfContainedTickets config={this.props.config} permissions={this.props.permissions}/></Col>}
      {sideContent.selectedTab == 'TicketInsights' && <Col md={10} className={classes.contentPanel}><InsightsPage config={this.props.config} /></Col>}
      {sideContent.selectedTab == 'crm layout' && <Col md={10} className={classes.contentPanel}><CrmLayout config={this.props.config} /></Col>}
      {sideContent.selectedTab == 'KnowledgeSearch' && <Col md={10} className={classes.contentPanel}><KnowledgeSearch config={this.props.config} /></Col>}
      {sideContent.selectedTab == 'ModelOutput' && <Col md={10} className={classes.contentPanel}><ModelOutput config={this.props.config}/></Col>}
      {sideContent.selectedTab == 'IngestPredictionData' && <Col md={10} className={classes.contentPanel}><IngestPredictionData config={this.props.config}/></Col>}
      {sideContent.selectedTab == 'DataReview' && <Col md={10} className={classes.contentPanel}><DataReview config={this.props.config} selectedNode={this.selectedNode}/></Col>}
      {sideContent.selectedTab == 'UseCase' && <Col md={10} className={classes.contentPanel}><UseCase config={this.props.config} selectedNode={this.selectedNode}/></Col>}
      {sideContent.selectedTab == 'SetupCrawler' && <Col md={10} className={classes.contentPanel}><SetupCrawler config={this.props.config} selectedNode={this.selectedNode}/></Col>}
      {sideContent.selectedTab == 'DownloadData' && <Col md={10} className={classes.contentPanel}><DownloadData config={this.props.config} /></Col>}
      {sideContent.selectedTab == 'BackupRestore' && <Col md={10} className={classes.contentPanel}><BackupRestore config={this.props.config} /></Col>}
      {sideContent.selectedTab == 'triageConfiguration' && <Col md={10} className={classes.contentPanelTriage}><AdminHome  config={this.props.config} onChangeAdminHome={this.onChangeAdminHome} /></Col>}
      {sideContent.selectedTab == 'RelationshipMapping' && <Col md={10} className={classes.contentPanelMaping}><RelationshipMapping  config={this.props.config} /></Col>}
      {sideContent.selectedTab == 'SymptomAndRootCauseTab' && <Col md={10} className={classes.contentPanelSymptomRoot}><SymptomAndRootCauseTab  config={this.props.config} /></Col>}
      {(sideContent.selectedTab == 'VirtualAssistant'&& accessForLVA) && <Col md={10} className={classes.contentPanel}><VirtualAssistantContainer  config={this.props.config} history={this.props.history}/></Col>}
      </div></>);
    }
}

const mapStateToProps = state => {
  return {
    permissions:state.user.permissionList,
    ptModelTraining:state.user.moduleAccess.ptModelTraining
  };
};

const mapDispatchToProps = (dispatch) => ({
  getConnectionsData: (url) => {dispatch(getConnectionsData(url))},
  OnProjectHeaderShow:(showProjectHeader)=>dispatch(actions.projectHeaderShow(showProjectHeader)),
});




export default connect(mapStateToProps, mapDispatchToProps)(SettingPage);