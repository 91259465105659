// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.horizontal-tabs .nav-tabs .nav-link.active {
    font-family: 'Roboto-Regular';
    font-size: 14px;
    line-height: 19px;
    font-variant: small-caps;
    color: #2A93DF;
    border-bottom: 2px solid #2A93DF;
    background-color: transparent;
}
.horizontal-tabs .nav-tabs .nav-link {
    font-family: 'Roboto-Regular';
    font-size: 14px;
    line-height: 19px;
    font-variant: small-caps;
    color: #B0AFAF
}`, "",{"version":3,"sources":["webpack://./src/containers/SettingPage/SettingInnerPages/components/TabsComponent/tabs-component.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,eAAe;IACf,iBAAiB;IACjB,wBAAwB;IACxB,cAAc;IACd,gCAAgC;IAChC,6BAA6B;AACjC;AACA;IACI,6BAA6B;IAC7B,eAAe;IACf,iBAAiB;IACjB,wBAAwB;IACxB;AACJ","sourcesContent":[".horizontal-tabs .nav-tabs .nav-link.active {\n    font-family: 'Roboto-Regular';\n    font-size: 14px;\n    line-height: 19px;\n    font-variant: small-caps;\n    color: #2A93DF;\n    border-bottom: 2px solid #2A93DF;\n    background-color: transparent;\n}\n.horizontal-tabs .nav-tabs .nav-link {\n    font-family: 'Roboto-Regular';\n    font-size: 14px;\n    line-height: 19px;\n    font-variant: small-caps;\n    color: #B0AFAF\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
