import React, { Component } from "react";
import { connect } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import "./BackupRestore.css";
import RestoreTab from "./RestoreTab";
import BackupTab from "./BackupTab";
import TabsView from "../../../../components/UI/Tabs/Tabs";
import { isEqual } from 'lodash';

function mapStateToProps() {
  return {};
}

class BackupRestore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: "Backup",
      tabsdata: ["Backup"],
      // tabsdata: ["Backup", "Restore"],
      apiURL: this.props.config.INSIGHTS_API_URL
    };
  }

   setSelected = (tab) => {
    this.setState({ selected: tab });
  };


  render() {
    
    return (
      <div className="backup-restore">
        <TabsView
          tabsdata={this.state.tabsdata}
          selected={this.state.selected}
          setSelected={this.setSelected}
        >
          { isEqual(this.state.selected, "Backup") ? (
            <BackupTab 
            apiURL={this.state.apiURL}
            toastFn={(msg, type) => {
              toast(msg, {
                type: type,
                pauseOnHover: false,
                autoClose: 3000,
                hideProgressBar: true,
              });
            }}
             />
          ) : (
            <RestoreTab apiURL={this.state.apiURL} />
          )}
        </TabsView>
        <ToastContainer/>
        
      </div>
    );
  }
}

export default connect(mapStateToProps)(BackupRestore);
