import get from 'lodash/get';

const fetchProductTriageInsightsSuccessReducer = (state, payload) => {
    const data = JSON.parse(get(payload, 'response.data.data', {}));
   
    if (payload.isFirstTime) {
        return {
            ...state,
            agentInsightsData: data,
            //yesQuestions: questions,
            agentInsightsLoading: false
        };
    }
    return {
        ...state,
        agentInsightsData: data,
        agentInsightsLoading: false
    };
};

export default fetchProductTriageInsightsSuccessReducer;