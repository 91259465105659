import React from 'react';
import { Col } from 'react-bootstrap';
import InputWithLabelComponent from "../../../../../../containers/SettingPage/SettingInnerPages/components/InputWithLabelComponent/InputWithLabelComponent";
import SelectCustom from "../../../../../../components/UIComponents/SelectDropdown/SelectComponent";

const Heading = ({properties, onChangeHandler}) => {

    const alignments = [{value:'left',displayValue:'Left Align'},{value:'center',displayValue:'Center'},
                      {value:'right',displayValue:'Right Align'}];
    const sizes = [{value:'small',displayValue:'Small'},{value:'medium',displayValue:'Medium'},
                    {value:'large',displayValue:'Large'}];


    return (
        <div>
            <div className="mt-1 settings-div">
                <Col md={12}>
                <InputWithLabelComponent
                        type="text"
                        name={'settings-label'}
                        label="Heading*"
                        showLabel={true}
                        value={properties.text}
                        readOnly={false}
                        onChange={e => onChangeHandler('text',e.target.value)}
                        required={true}
                        className="settings-input"
                  />
                </Col>
            </div>
            <div className="mt-1 settings-div">
                <Col md={12}>
                    <SelectCustom
                    name="alignment"
                    label="Heading Alignment"
                    className="settings-dropdown"
                    changed={e => onChangeHandler('horizontalAlignment',e.target.value)}
                    required={false}
                    value={properties.horizontalAlignment}
                    options={alignments}/>
                </Col>
            </div>
            <div className="mt-1 settings-div">
                <Col md={12}>
                    <SelectCustom
                    name="style"
                    label="Heading size"
                    className="settings-dropdown"
                    changed={e => onChangeHandler('size',e.target.value)}
                    required={false}
                    value={properties.size}
                    options={sizes}/>
                </Col>
            </div>            
        </div>
    )
}

export default Heading;