import React from 'react';
import { Form, Modal } from 'react-bootstrap';
import classes from './Modal.module.css';

const modal =(props) =>{
		
		let inline = null
		if(props.modalclass =='projectedit')
		{
			inline = {'width':'110%'}
		}
    return(
        <>
         <Modal size={props.size} className={props.modalclass} show={props.show} onHide={props.onHide}>  
         <div className={classes.modalContent} style={inline}>
         <Modal.Header className={props.modalHeaderClass} closeButton>
           <Modal.Title  className={classes.headertitle}>{props.title}</Modal.Title>
         </Modal.Header>
           <Modal.Body className={props.ModalBodyClass}>
           <Form onSubmit={props.submit}>
           {props.children}
           </Form>
          </Modal.Body>
          </div>
          </Modal>
        </>

    );


}

export default modal;