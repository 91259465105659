import {
    ISSUE_CLASSIFICATION_CHANGE_HANDLER,
    KB_DOCS_CHANGE_HANDLER,
    PREDICTED_ISSUES_CHANGE_HANDLER,
    QUESTIONARIES_CHANGE_HANDLER,
    SIMILAR_ISSUES_CHANGE_HANDLER,
    TOP_RESOLUTIONS_CHANGE_HANDLER,
    SIMILAR_ISSUES_OPTIONAL_FIELD_CHANGE_HANDLER,
    ISSUE_CLARIFICATION_OPTIONAL_FIELD_CHANGE_HANDLER,
    OPTIONAL_FIELD_RESET_HANDLER,
    KNOWLEDGE_ARTICLES_CHANGE_HANDLER
} from "./insightsActiontypes";

export const similarIssuesChangeHandlerAction = (payload) => ({
    type: SIMILAR_ISSUES_CHANGE_HANDLER,
    payload
});

export const similarIssuesOptinalFieldChangeHandlerAction = (payload) => ({
    type: SIMILAR_ISSUES_OPTIONAL_FIELD_CHANGE_HANDLER,
    payload
});

export const OptinalFieldResetHandlerAction = (payload) => ({
    type: OPTIONAL_FIELD_RESET_HANDLER,
    payload
});

export const issueClarificationOptinalFieldChangeHandlerAction = (payload) => ({
    type: ISSUE_CLARIFICATION_OPTIONAL_FIELD_CHANGE_HANDLER,
    payload
});

export const kbDocsChangeHandlerAction = (payload) => ({
    type: KB_DOCS_CHANGE_HANDLER,
    payload
});

export const issueClassificationChangeHandlerAction = (payload) => ({
    type: ISSUE_CLASSIFICATION_CHANGE_HANDLER,
    payload
});

export const topResolutionsChangeHandlerAction = (payload) => ({
    type: TOP_RESOLUTIONS_CHANGE_HANDLER,
    payload
});

export const predictedIssuesChangeHandlerAction = (payload) => ({
    type: PREDICTED_ISSUES_CHANGE_HANDLER,
    payload
});

export const questionariesChangeHandlerAction = (payload) => ({
    type: QUESTIONARIES_CHANGE_HANDLER,
    payload
});
export const knowledgeArticlesChangeHandlerAction =(payload) =>({
    type: KNOWLEDGE_ARTICLES_CHANGE_HANDLER,
    payload

})