// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-header .close {
    position: absolute;
    left: 15px;
    top: 25px;
   
    
  }

  .rounded-modal {
    border-radius: 10px !important; /* Adjust the radius as needed */
    border: 1px solid red;
  }
  .modal-content{
    border-radius: 15px;
  }
  .model-body{
    border: none !important;
  }

   .model-footer{
    border: none !important;
    
  }
  .btn{
    font-size: 12px;
  }
.model-header{
    border: none !important;
  }`, "",{"version":3,"sources":["webpack://./src/containers/ProjectViewPage/ManageFileForms.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,UAAU;IACV,SAAS;;;EAGX;;EAEA;IACE,8BAA8B,EAAE,gCAAgC;IAChE,qBAAqB;EACvB;EACA;IACE,mBAAmB;EACrB;EACA;IACE,uBAAuB;EACzB;;GAEC;IACC,uBAAuB;;EAEzB;EACA;IACE,eAAe;EACjB;AACF;IACI,uBAAuB;EACzB","sourcesContent":[".modal-header .close {\n    position: absolute;\n    left: 15px;\n    top: 25px;\n   \n    \n  }\n\n  .rounded-modal {\n    border-radius: 10px !important; /* Adjust the radius as needed */\n    border: 1px solid red;\n  }\n  .modal-content{\n    border-radius: 15px;\n  }\n  .model-body{\n    border: none !important;\n  }\n\n   .model-footer{\n    border: none !important;\n    \n  }\n  .btn{\n    font-size: 12px;\n  }\n.model-header{\n    border: none !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
