const productAttributesRemoveItemHandlerReducer = (state, payload) => {
    const triageData = state.triageConfigurationData.triageData;
    const updatedData = triageData.map(item => {
        if (item.source_name === payload.type) {
            return {
                ...item,
                source_fields: item.source_fields.filter(selectedSource => selectedSource !== payload.selectedItem)
            };
        }
        return item;
    });
    return {
        ...state,
        triageConfigurationData: {
            ...state.triageConfigurationData,
            triageData: updatedData
        }
    }
}

export default productAttributesRemoveItemHandlerReducer;