/*

* Copyright © 2023, HCL Technologies Ltd. ALL RIGHTS RESERVED.

* <p>

* This software is the confidential information of HCL Technologies Ltd., and is licensed

* as restricted rights software. The use,reproduction, or disclosure of this software

* is subject to restrictions set forth in your license agreement with HCL.

*/

 

/**

* Ingest Prediction Data.

*

* @author Abhash Vats

*/
import React,{Component} from "react";
import "./IngestPredictionData.css";
import { encryptAndStore } from "../../../../utility/utility";
import NavbarCustom from './Navbar';
import "./modelOutput.css";
import InputWithLabelComponent from "../components/InputWithLabelComponent/InputWithLabelComponent";

class IngestPredictionData extends Component{
  constructor(props) {
    super(props);
    this.state = {
      searchValue: "",
      show:true,
      sourcename:"SampleCollection",
      sourceValues:{bots:['SampleCollection1','SampleCollection2','SampleCollection3']},
      searchT:"",
      searchT2:"",
      projectname:"Project List",
      popperShow:false,
      popperShow2:false,
    };
  }
  setSearchTerm = (item) => {
    this.setState({searchT:item})
  }
  popperShowFn=()=>{
    this.setState({
      popperShow:true,
    })
  }
  setSearchTerm2 = (item) => {
    this.setState({searchT2:item})
  }
  popperShowFn2=()=>{
    this.setState({
      popperShow2:true,
    })
  }
  sourceValueclick =(event,sourcename)=>{
    this.setState({searchT2:"",popperShow2:false})
    this.setState({sourcename:sourcename});
    console.log("2222222")       
    }
    selectSourceVal = (event) => {
      this.setState({
          selectedSourceValue: event.target.value
      })
    }
    projectclick =(event,projectname)=>{
      this.setState({searchT:"",popperShow:false})
      let savedProjectName=localStorage.getItem('projectname');
      if(savedProjectName!==projectname){
      encryptAndStore('projectname', projectname );
      this.setState({projectname:projectname});
      }
      this.props.onchangeProjectAction(projectname); 
      var url = this.props.config.BOTBUILDER_URL + "/modeloutput/" + localStorage.getItem('projectname') + "/customerclust"
      this.props.onGetModelOutPutData(url)         
      }
    render()
{
  return (
    <React.Fragment>
        <div className="headerParent">
        <div className="headerSivling1">
          <span className="headerText" style={{marginLeft:"30px"}}>Configure Model</span>
        </div>
        <div className="headerSivling2">
          <div style={{paddingRight:"100px"}}>
            <NavbarCustom
            className="projectNameForDashboard"
            dataList={this.props.projectlist}
            addImportProjectIcon={false}
            setSearchTerm={(item)=> this.setSearchTerm(item)}
            popperShow={this.state.popperShow}
            popperShowFn={()=> this.popperShowFn()}
            searchTerm={this.state.searchT}
            projectclick={(event,item)=> this.projectclick(event,item)}
            projectname={this.state.projectname}
            rightPopUp={false}
            sourceValue={false}
            commanProjectNotShow={false}>
            </NavbarCustom>
          </div>
          <div className="vl"></div>
          <div style={{marginRight:"121px"}}>
            <NavbarCustom
                className="projectNameForDashboard"
                dataList={this.state.sourceValues}
                addImportProjectIcon={false}
                setSearchTerm={(item)=>this.setSearchTerm2(item)}
                popperShow={this.state.popperShow2}
                popperShowFn={()=>this.popperShowFn2()}
                searchTerm={this.state.searchT2}
                projectclick={(event,item)=> this.sourceValueclick(event,item)}
                projectname={this.state.sourcename}
                rightPopUp={false}
                sourceValue={true}
                boldViewForProjectName={true}
                commanProjectNotShow={true}
            >
            </NavbarCustom>
          </div>
        </div>
      </div>
      <div className="ingest-data-card">
          <div className="card-content">
            <h3>Ingest Data</h3>
            <div className="inner-box">
            <span className="ticketing-text">TICKETING SYSTEM</span>
            </div>
            <p className="ticketing-para">Upload the files which has ticket dump details i.e .csv,.xsl</p>
            <button className="customBtn" style={{ width: '100px', height: '30px',marginTop:'16px',fontsize:'10px'}} onClick={()=>this.handleSave()}>
                        ADD
                    </button>
          </div>
        </div>
        
    </React.Fragment>
  )      

}
}
const mapStateToProps = (state) => {
  return {
    projectlist: state.user.projects
  };
};
export default IngestPredictionData;