import React, { useEffect, useState } from "react";
import { Row, Col, Modal, Container } from "react-bootstrap";
import "./UserGroups.css";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";

import Heading3 from "../../components/Typography/Heading3/Heading3";

import PrimaryButtonWithLoader from "../../components/PrimaryButtonWithLoader/PrimaryButtonWithLoader";
import ReactSelectComponent from "../../components/ReactSelectComponent/ReactSelectComponent";
import {
  GetCalltoOUServer,
  DeleteCalltoOUServer,
  UpdateCalltoOUServer,
} from "../helpers/utility";
import { GetCalltoServer } from "../../../../../store/utility";
import SearchUsersAndRoles from "../helpers/SearchUsersAndRoles";
import isEmpty from "lodash/isEmpty";
import UserContent from "../Users/UserContent";
import InviteUser from "../Users/InviteUser";
import SelectedItemsComponent from "../components/SelectedItemsComponent";
import Dropdown from "react-bootstrap/Dropdown";
import DeleteModal from "../../../../../components/UIComponents/DeleteModalComponent/DeleteModal";
import {
  SetErrorMessage,
  SetSuccessMessage,
  SetWarningMessage,
} from "../../../../../store/actions";
import { isEqual } from "lodash";
import SearchComponent from "../../components/OuManagementSearch/OusearchComponent";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const UserGroupsContent = ({
  getGroupName,
  group,
  config,
  updateUserGroups,
  addNewUser,
  addNewGroup,
  updateFilterData,
  userManagement_Read_Write,
  setSearch,
}) => {
  const [showDrawer, setShowDrawer] = useState(false);
  const [projectList, setProjectList] = useState();
  const dispatch = useDispatch();

  const toggleDrawer = (value) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setShowDrawer(value);
  };

  useEffect(() => {
   // existingUsers(group.id)
    parseData(group.projects, "projects");
    parseData(group.roles, "roles");
    getProjectDetails();
    getRoleDetails();
  }, []);

  const parseData = (dataList, name) => {
    let list = "";

    dataList.slice(0, 3);
    dataList.map((item) => {
      list += item + ", ";
    });
    if (name === "projects") {
      setProjectList(list.slice(0, list.length - 2));
      // if(dataCount > 1) {
      //   let displayString = projectList + '..';
      //   setProjectList(prevList=>(...prevList,displayString));
      // }
    }
  };

  const [show, setShow] = useState(false);
  const handleShow = () =>{
     setShow(true);
  }
  const handleClose = () => setShow(false);

  const setUserCount = (data) => {
    data.users !== null ? setCount(data.users.length) : setCount(0);
  };
  

  const searchHandler = (event) => {
    setSearchTerm(event.target.value);
    const filter = SearchUsersAndRoles(
      userData,
      "users",
      event,
      setFilterUserData
    );
    setFilterUserData(filter);
  };

  const orgname = localStorage.getItem("orgName");
  const [groupName, setGroupName] = useState();
  const [groupData, setgroupData] = useState([]);
  const [projects, setProjects] = useState([]);
  const [roles, setRoles] = useState([]);

  const [count, setCount] = useState(0);
  console.log("setCount", count);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isDisabled, setDisabled] = useState(true);
  const [formData, setFormData] = useState({
    orgname: orgname,
    usergroup_name: "",
    projects: [],
    roles: [],
    description: "",
  });
  const [filterUserData, setFilterUserData] = useState([]);
  console.log("filterUserData2",filterUserData);
  const [userData, setUserData] = useState([]);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [modalFocus, setModalFocus] = useState(true);
  const [addUserFlag, setAddUserFlag] = useState(false);

  const deleteInviteModel = () => {
    setDeleteConfirmation(true);
    setSearch("");
  };
  const closeWindow = (modaltype) => {
    if (modaltype == "deleteInviteModal") {
      setDeleteConfirmation(false);
      setSearch("");
    } else {
      return null;
    }
  };

  const parseUserGroupDetails = (data) => {
    console.log("dataaaaa", data.users)
    setgroupData(data);
    setSelectedRoles(data.roles);
    setSelectedProjects(data.projects);
    setUserData(data.users);
    setFilterUserData(data.users);
    setUserCount(data);
    setGroupName(data.usergroup_name);
    setSearch("");
    setFormData((formData) => ({
      ...formData,
      usergroup_name: data.usergroup_name,
    }));
    if (data.usergroup_name === "Tenant Admin") {
      setDisabled(true);
    }
  };

  useEffect(() => {
    setFormData((formData) => ({
      ...formData,
      roles: selectedRoles,
    }));
  }, [selectedRoles]);

  useEffect(() => {
    setFormData((formData) => ({
      ...formData,
      projects: selectedProjects,
    }));
  }, [selectedProjects]);

  const updateGroup = () => {
    const url =
      config.COMMON_API_URL + `/organization/${orgname}/usergroup/${groupName}`;
    UpdateCalltoOUServer(url, formData).then((response) => {
      if (response.status === 204) {
        toggleDrawer(true);
        confirmMsg("User group updated successfully", SetSuccessMessage);
        updateFilterData(formData);
        setDisabled(true);
        setSearch("");
      } else {
        confirmMsg("Something went wrong!", SetErrorMessage);
      }
    });
  };
  const updatetextSearch = () => {
    const url =
      config.COMMON_API_URL + `/organization/${orgname}/usergroup/${groupName}`;
    UpdateCalltoOUServer(url, formData).then((response) => {
      if (response.status === 204) {
        toggleDrawer(true);
        updateFilterData(formData);
        setDisabled(true);
        setSearch("");
      } else {
      }
    });
  };

  const confirmMsg = (msg, method) => {
    setTimeout(() => {
      dispatch(method(msg));
    }, 450);
  };

  const removeGroup = (name) => {
    const url =
      config.COMMON_API_URL + `/organization/${orgname}/usergroup/${name}`;
    setDeleteConfirmation(false);
    DeleteCalltoOUServer(url).then((response) => {
      setSearch("");
      if (response.status == 204) {
        confirmMsg("Usergroup deleted successfully", SetSuccessMessage);
        updateUserGroups();
        handleClose();
      } else {
        confirmMsg("Something went wrong!", SetErrorMessage);
      }
    });
  };

  const removeGroupId = (name) => {
    const url =
      config.COMMON_API_URL + `/organization/${orgname}/usergroup/${name}`;
    setDeleteConfirmation(false);
    DeleteCalltoOUServer(url).then((response) => {
      if (response.status == 204) {
        confirmMsg("Usergroup deleted successfully", SetSuccessMessage);
        updateUserGroups();
        closeWindow();
        setSearch("");
      } else {
        confirmMsg("Something went wrong!", SetErrorMessage);
      }
    });
  };

  const getProjectDetails = () => {
    const url = config.COMMON_API_URL + `/botbuilder/project/en`;

    GetCalltoServer(url)
      .then((response) => {
        if (response.data != null) {
          setProjects(response.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getRoleDetails = () => {
    const orgname = localStorage.getItem("orgName");
    const url = config.COMMON_API_URL + `/organization/role?orgname=${orgname}`;

    GetCalltoOUServer(url)
      .then((response) => {
        if (response.data != null) {
          setRoles(parseRoles(response.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getOptions = (data) => {
    if (isEmpty(data)) {
      return [];
    }

    const attrData = data;
    return attrData.map((option) => ({
      label: option,
      value: option,
    }));
  };

  const parseRoles = (data) => {
    let rolenames = [];
    rolenames = data.map((role) => role.rolename);
    return rolenames;
  };

  const updateProjectData = (e) => {
    if (!isEqual(group.usergroup_name, "tenant admin group")) {
      if (!selectedProjects.includes(e.value)) {
        setSelectedProjects((prevData) => [...prevData, e.value]);
        setDisabled(false);
      }
    } else {
      dispatch(
        SetWarningMessage("Permissions Required to Edit Tenant Admin Group")
      );
    }
  };

  const removeProject = (item) => {
    if (selectedProjects.length > 1) {
      setSelectedProjects(selectedProjects.filter((list) => list !== item));
      setDisabled(false);
    }
  };

  const updateRoleData = (e) => {
    if (!isEqual(group.usergroup_name, "tenant admin group")) {
      if (!selectedRoles.includes(e.value)) {
        setSelectedRoles((prevData) => [...prevData, e.value]);
        setDisabled(false);
      }
    } else {
      dispatch(
        SetWarningMessage("Permissions Required to Edit Tenant Admin Group")
      );
    }
  };

  const removeRole = (item) => {
    if (selectedRoles.length > 1) {
      setSelectedRoles(selectedRoles.filter((list) => list !== item));
      setDisabled(false);
    }
  };

  const sortUsers = (type) => {
    const toBeSortedData = [...filterUserData];
    toBeSortedData.sort((a, b) => {
      let fa = type === "first_name" ? a[type].toLowerCase() : a[type];
      let fb = type === "first_name" ? b[type].toLowerCase() : b[type];
      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
    setFilterUserData(toBeSortedData);
  };

  const getUserGroups = (name) => {
    const orgname = localStorage.getItem("orgName");
    const url =config.COMMON_API_URL +`/organization/usergroup?orgname=${orgname}&usergroup=${name}`;

    GetCalltoOUServer(url)
      .then((response) => {  
        if (response.data != null) { 
         setFilterUserData( [ response.data[0].users] );          
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (addUserFlag) {
      getUserGroups(groupName);
      setAddUserFlag(false);
    }
  }, [group]);



  const updateUser = () => {   
    //  update call
    updateGroup();
    getUserGroups(groupName);
  };

  return (
    <React.Fragment>
      <Row className="d-flex usergroup-content-row row-no-gutters">
        <Col xs={12} className="d-flex row">
          <div className="row d-flex justify-content-between w-100 content-align-items row-no-gutters">
            <Col xs={2}>
              <div className="usergroup-content name-capitilize" key={group.id}>
                {group.usergroup_name}
              </div>
            </Col>
            <Col xs={3}>
              <div className="usergroup-content padding-adjustment">
                {group.description}
              </div>
            </Col>
            <Col xs={2}>
              <div className="usergroup-content padding-adjustment">
                {group.usergroup_name === "Tenant Admin Group"
                  ? "All"
                  : projectList}
              </div>
            </Col>
            <Col xs={2}>
              <div className="usergroup-content padding-adjustment">
                {group.roles.join(", ")}
              </div>
            </Col>
            <Col xs={1}>
              <div className="usergroup-content padding-adjustment">
                {group.users ? group.users.length : "0"}
              </div>
            </Col>
            <Col xs={1}>
              <div className="usergroup-content cursor-action">
                <Dropdown
                  className="navigationHelpMenuUserGroup"
                  style={{ position: "absolute", bottom: "2px" }}
                >
                  <Dropdown.Toggle id="navigationHelpMenuUserGroup" drop="end">
                    <img
                      src="./Icons/OptionsIcon.svg"
                      title="Help"
                      style={{ marginLeft: "7px" }}
                    />
                  </Dropdown.Toggle>
                  {/* {group.usergroup_name==='tenant admin group'?
                <Dropdown.Menu>
                    <Dropdown.Item className='optionsItemsDropdown' onClick={()=>{handleShow(); parseUserGroupDetails(group)}}>
                    VIEW</Dropdown.Item>
                </Dropdown.Menu> : */}                             
                  <Dropdown.Menu className="user-group-menu">
                    <Dropdown.Item
                      className="optionsItemsDropdown"
                      disabled={userManagement_Read_Write ? false : true}
                      onClick={() => {
                        handleShow();
                        parseUserGroupDetails(group);                       
                       getGroupName(group.usergroup_name)
                      }}
                    >
                      View/Edit Details
                    </Dropdown.Item>
                    {!isEqual(group.usergroup_name, "tenant admin group") ? (
                      <Dropdown.Item
                        className="optionsItemsDropdown"
                        disabled={userManagement_Read_Write ? false : true}
                        onClick={() => {
                          addNewGroup(group);
                        }}
                      >
                        Duplicate User Group
                      </Dropdown.Item>
                    ) : null}
                    <Dropdown.Item
                      className="optionsItemsDropdown"
                      disabled={userManagement_Read_Write ? false : true}
                      onClick={() => {
                        addNewUser(group.usergroup_name);
                        handleClose();
                      }}
                    >
                      Invite User to Group
                    </Dropdown.Item>
                    {!isEqual(group.usergroup_name, "tenant admin group") ? (
                      <Dropdown.Item
                        className="optionsItemsDropdown"
                        disabled={userManagement_Read_Write ? false : true}
                        onClick={() => deleteInviteModel()}
                      >
                        Delete
                      </Dropdown.Item>
                    ) : null}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Col>
            <DeleteModal
              show={deleteConfirmation}
              deleteModal={() => closeWindow("deleteInviteModal")}
              title="Delete Confirmation"
              bodyTitle="Are you sure you want to delete the user group?"
              buttonClick={() => removeGroupId(group.usergroup_name)}
            />
          </div>
        </Col>
      </Row>

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="modal-fullscreen"
        enforceFocus={modalFocus}
        animation={false}
      >
        <Container className="symptom-rootcause" fluid>
          <Row>
            <Col xs={12} className="d-flex justify-content-between">
            <div
                  xs={6}
                  className="d-flex justify-content-between align-items-center mb-1"
                >
              <ArrowBackIcon
                    className="d-flex cursor-action arrow-icon-fontSize"
                    onClick={handleClose}
                  />
                <Heading3
                    className="pageTitleText pt-3]"
                    text="User Group Details" />
              </div>
              <div className="d-flex justify-content-between mt-4 pr-1 mb-1 mr-3">
                {group.usergroup_name === "tenant admin group" ? (
                  ""
                ) : (
                  <PrimaryButtonWithLoader
                    className="mr-2 usergrp-btntitle-edit-page"
                    onClick={() => removeGroup(formData.usergroup_name)}
                    size="sm"
                    text="DELETE USER GROUP"
                    loading={false}
                  />
                )}
                <PrimaryButtonWithLoader
                  className="mr-2 usergrp-btntitle-edit-page"
                  onClick={() => {
                    setAddUserFlag(true);
                    addNewUser(groupName);
                    setModalFocus(false);
                  }}
                  size="md"
                  text="ADD USERS"
                  loading={false}
                />
                <PrimaryButtonWithLoader
                  className="mr-2 usergrp-btntitle"
                  onClick={updateGroup}
                  size="lg"
                  text="SAVE"
                  loading={false}
                  disabled={isDisabled}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={3} className="user-group-form-left">
              <Row className="usergrp-highlight">
                <Col xs={12}>
                  <Row>
                    <div className="d-flex flex-column Symtom-Item">
                      <label className="label-title">User Group Name</label>
                      <label className="label-text">
                        {groupData.usergroup_name}
                      </label>
                    </div>
                  </Row>
                  <Row>
                    <div className="d-flex flex-column Symtom-Item">
                      <label className="label-title">Description</label>
                      <label className="label-description">
                        {groupData.description}
                      </label>
                    </div>
                  </Row>
                </Col>
                <Col xs={10} className="user-group-form-left-panel">
                  <div className="mt-1">
                    <Row>
                      <Col md={12}>
                        <ReactSelectComponent
                          options={getOptions(projects)}
                          name="userGroups"
                          label="Associated Projects"
                          placeholder={"Select"}
                          value=""
                          onChange={updateProjectData}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="mt-1">
                    <Row>
                      <Col md={12} className="dropdown-selections">
                        <SelectedItemsComponent
                          selectedItems={selectedProjects}
                          removeFun={removeProject}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col xs={10} className="user-group-form-left-panel">
                  <div className="mt-1">
                    <Row>
                      <Col md={12}>
                        <ReactSelectComponent
                          options={getOptions(roles)}
                          name="userGroups"
                          label="Associated Roles"
                          placeholder={"Select"}
                          value=""
                          onChange={updateRoleData}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="mt-1">
                    <Row>
                      <Col md={12} className="role-selection">
                        <SelectedItemsComponent
                          selectedItems={selectedRoles}
                          removeFun={removeRole}
                        />

                        {/*  */}
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col xs={1}></Col>
              </Row>
            </Col>
            <Col xs={9}>
              <Row  className="d-flex justify-content-between">
                {/* <Users config={config}/> */}
                  <Heading3
                    className="pageTitleText"
                    text={`This user group has ` + group?.users?.length + ` Users!`}
                    
                  />
                  <div xs={6} className="d-flex justify-content-end um-search-input">
                    <SearchComponent
                      class={"input-group searchClass mr-3"}
                      placeholder="Search"
                      value={searchTerm}
                      onSearch={searchHandler}
                      onBlur={searchHandler}
                    />
                  </div>
              </Row>
              <Row className="mt-4 row row-no-gutters">
                <Col
                  xs={12}
                  className="d-flex text-left align-items-center symtom-container"
                >
                  <div className="row d-flex justify-content-between w-100 content-align-center symtom-heading-data">
                    <Col xs={2}>
                      <h3 className="issue-descp-title">
                        Name{" "}
                        <a onClick={() => sortUsers("first_name")}>
                          <img src="../Icons/ArrowDown.svg" />
                        </a>
                      </h3>
                    </Col>
                    <Col xs={2}>
                      <h3 className="issue-descp-title">Status</h3>
                    </Col>
                    <Col xs={2}>
                      <h3 className="issue-descp-title">
                        Last Active{" "}
                        <a onClick={() => sortUsers("last_active")}>
                          <img src="./Icons/ArrowDown.svg" />
                        </a>
                      </h3>
                    </Col>
                    <Col xs={2}>
                      <h3 className="issue-descp-title"></h3>
                    </Col>
                    <Col xs={2}>
                      <h3 className="issue-descp-title"></h3>
                    </Col>
                    <Col xs={1}>
                      <h3 className="issue-descp-title"></h3>
                    </Col>
                  </div>
                </Col>
              </Row>
              <div className="user-content-scroll">              
                {group.users && group.users.length ? (
                  group.users.map((user) => {
                    return (
                      <UserContent
                        key={user.id}
                        config={config}
                        user={user}
                        updateUserCallBack={updateUser}
                        userManagement_Read_Write={userManagement_Read_Write}
                        usergroup={groupName}
                        setSearchTerm={setSearchTerm}
                      />
                    );
                  })
                ) : (
                  <Row
                    className="d-flex row-no-gutters"
                    //  onClick={toggleDrawer(true)}
                  >
                    <Col xs={12} className="d-flex row symtom-container">
                      <div className="row d-flex justify-content-between w-100 content-align-items row-no-gutters">
                        <h4 className="empty-row">
                          No users added to this group
                        </h4>
                      </div>
                    </Col>
                  </Row>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </Modal>

      {showDrawer ? (
        <InviteUser
          showDrawer={showDrawer}
          toggleDrawer={toggleDrawer}
          updateUser={updateUser}
          config={config}
          toastFun={(msg, type) => {
            toast(msg, {
              type: type,
              pauseOnHover: false,
              autoClose: 3000,
              hideProgressBar: true,
            });
          }}
          closeDrawer={() => {
            setShowDrawer(false);
          }}
        />
      ) : null}
    </React.Fragment>
  );
};

export default UserGroupsContent;
