import React from 'react';
import { Row, Col,  Form } from 'react-bootstrap';

import './filter-details.css';

import { get } from 'lodash';
import PropTypes from 'prop-types';

import 'react-multi-carousel/lib/styles.css';



const getSelectedFilterData = (selectedFilterData) => Object.entries(selectedFilterData).map(([key, value]) => {
    if (typeof value === 'object') {
        return (
            <React.Fragment>
                <div className='product-details' key={value}>
                    <Form.Label className='capitalize pr-1'>{key} -</Form.Label>
                    <Form.Label className='value pl-1'>{get(value, 'label', '')}</Form.Label>
                </div>
                <div className='product-details mx-2' key={value}>|</div>
            </React.Fragment>
        );
    }
    return null;
});

const FilterDetails = ({
    selectedQuestions
}) => {
    



    return (
        <React.Fragment>
            <Row style={{backgroundColor:"white", minHeight:'51vh'}}>
                <Col xs='12' className='align-items-center'>
                    <Row>
                    <div className="questionaire">
                        <h3 className='questionaire-cust-heading ml-4 pt-3'>Triage Responses</h3>
                    </div>
                    </Row>
                   <Row>
                       <Col xs={12} className='scroll-height'   >
                   <div className='pt-2 d-flex custom-line scroll-height-inner'
                     
                   >
                       <Col xs={10}>
                       {selectedQuestions.length ?
                                selectedQuestions.map((question, i) => {
                                    let cal = ( i === 0 ) ? 'pt-2' : 'pt-4';
                                    return(
                                        <p className={`trigae-custom-response ` + (cal)} >{question.description} </p>
                                    )
                                }) : <img src="./images/noquestion.png" alt="noquestion" /> }
                       </Col>
                       <Col xs={2} style={{padding:'0'}} >
                       {selectedQuestions.length ?
                                selectedQuestions.map((question, i) => {
                                    let cal = ( i === 0 ) ? 'pt-2' : 'pt-0';
                                    let wordLen = question.description.length;
                                    return(
                                        <>
                                        { (i === 0) ? null : <div class={(wordLen > 37) ? "vl1" :'vl' }></div>}
                                        <img className={cal} src={`images/${question.type}.svg`} />
                                        </>  ) } ) : null }
                       </Col>
                    </div>
                    </Col>
                   </Row>
                </Col>
            </Row>
        </React.Fragment>
    )
};

FilterDetails.propTypes = {
    description: PropTypes.string,
    skipClickHandler: PropTypes.func,
    yesClickHandler: PropTypes.func,
    noClickHandler: PropTypes.func
};

FilterDetails.defaultProps = {
    description: 'Ask Customer >',
    skipClickHandler: () => { },
    yesClickHandler: () => { },
    noClickHandler: () => { }
}

export default FilterDetails;