import React, { useState, useEffect } from "react";
import Drawer from "@material-ui/core/Drawer";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import "./SymptomAndRootCause.css";
import { Col, Form, Row } from "react-bootstrap";
import {
  GetCalltoServer,
  PostCalltoServer,
  deleteCalltoServer
} from "../../../../../../store/utility";
import get from "lodash/get";
import DrawerHeaderContent from "../../../components/DrawerHeaderContent/DrawerHeaderContent";
import { useDispatch, useSelector } from "react-redux";
import InputWithLabelComponent from "../../../components/InputWithLabelComponent/InputWithLabelComponent";
import isEmpty from "lodash/isEmpty";
import ProductAttributesComponent from "../ProductAttributesComponent/ProductAttributesComponent";
import fetchProductTriageDataAction from "../../../../../../store/actions/agentInsights/fetchProductTriageData/fetchProductTriageDataAction";
import PrimaryButtonWithLoader from "../../../components/PrimaryButtonWithLoader/PrimaryButtonWithLoader";
import getProjectDetails from "../../../helpers/getProjectDetails";
import isEqual from "lodash.isequal";
import SecondaryButtonWithLoader from "../../../components/SecondaryButtonWithLoader/SecondaryButtonWithLoader";
import rootCauseDetailsDeleteHandler from "../../../../../../store/actions/adminSettings/rootCauseDetailsDeleteHandler";
import { SetSuccessMessage } from "../../../../../../store/actions";

const useStyles = makeStyles({
  list: {
    width: "29rem"
  },
});

const EditRootcauseDetails = ({
  config,
  showDrawer,
  toggleDrawer,
  rootcausesheading,
  savebutton,
  rootcause,
  rootcausestitle,
  issueDiscription,
  allOption,
  onRemoveItem,
  disabled,
  placeholder,
  label,
  data,
  addRootcause,
  closeDrawer,
  associatedResolutionText,
  associatedSymptoms,
  advanceFilters,
  rootCause,
  getRootCauses,
  toastFun,
  id,
  ...rest
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const projectName = getProjectDetails();
  const [productAttributesData, setProductAttributesData] = useState([]);
  const filterData = useSelector((state) => state.agentInsights.filterData);
  const productTriageDataLoading = useSelector(state => state.agentInsights.productTriageDataLoading);
  const [symptomsOptions, setSymptomsOptions] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [seletctedSymptomsData, setSeletctedSymptomsData] = useState([]);
  const [rootcauseError, setRootcauseError] = useState(false);
  const [mandatory, setMandatory] = useState(false);
  const [formData, setFormData] = useState({
    rootcause: "",
    rootcauseTitle: "",
    resolution: [],
    symptoms: [],
    description: []
  });
  const [saveLoading, setSaveLoading] = useState(false);
  const [symptomDataLoading, setSymptomDataLoading] = useState(false);
  const [btnDisable, setBtnDisable] = useState(true);
  const [dataLoading, setDataLoading] = useState(false);
  const [associatedResolution, setAssociatedResolution] = useState([]);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false)
 
  const associateIssueElements = (data) => {
    (data.resolution).map(item => {
      setAssociatedResolution((s) => {
            return [
                ...s,
                {
                type: "text",
                value: item,
                },
            ];
            });
    })
}



  useEffect(() => {},[dataLoading]);
  useEffect(() => {}, [formData]);

  const addAssociatedResolution = () => {
    setAssociatedResolution((s) => {
      return [
        ...s,
        {
          type: "text",
          value: "",
        },
      ];
    });
  };

  
  const handleChange = (e) => {
    e.preventDefault();

    const index = e.target.id;
    setAssociatedResolution((s) => {
      const newArr = s.slice();
      newArr[index].value = e.target.value;

      return newArr;
    });
  };
  
  const getOptions = (data) => {
    if (isEmpty(data)) {
      return [];
    }
    if(isEqual(data.attribute_data,null)){
      return [];
    }
    const attrData = get(data, "attribute_data", []);
    return attrData.map((option) => ({
      label: option,
      value: option,
    }));
  };

  const saveHandler = (e) => {
    const resolutionArr = associatedResolution.map((item) => item.value);
    const productattributes = getProductAttributes(productAttributesData);
    const symptomsOptions = get(formData, "symptoms", []);
    const projectName = getProjectDetails();
    const newFormData = {
      rootcause: get(formData, "rootcause", ""),
      rootcauseTitle: get(formData, "rootcauseTitle",""),
      description: get(formData, "description",[]),
      resolution: resolutionArr,
      symptoms: get(formData, "symptoms",[]),
      project: projectName,
    };
    const updatedFormData = {
      ...newFormData,
      productattributes,
    };
    const deletedRootcause ={
      "rootcause": formData.rootcause,
      "project": projectName,
    }
   const url =
   config.INSIGHTS_API_URL +
   `/product-intelligent-triage/settings/rootaggs`;
   deleteCalltoServer(url, deletedRootcause)
   .then((response) => {
     if (get(response, "data.status.responseType", "") === "success") {
    if (!Validation(updatedFormData)) {
    const url =
      config.INSIGHTS_API_URL +
      `/product-intelligent-triage/settings/addRootcause`;
    setSaveLoading(true);
    PostCalltoServer(url, updatedFormData)
      .then((response) => {
        if (get(response, "data.status.responseType", "") === "success") {
          setSaveLoading(false);
          closeDrawer();
          const rootcauseData = {
            ...productAttributesData,
            rootcause: get(formData, "rootcause", ""),
            rootcauseTitle: get(formData,"rootcausetitle", ""),
            resolution: get(formData, "resolution",[]),
          };
          //addRootcause(rootcauseData);
          getRootCauses();
          // dispatch(SetSuccessMessage('Root cause to symptom unmapped successfully'));
          dispatch(SetSuccessMessage('Root cause updated successfully'));
        } else {
          setSaveLoading(false);
        }
      })
      .catch((err) => {
        setSaveLoading(false);
      });
    } else setMandatory(Validation(updatedFormData)); }
  }).catch(err => {
    console.log(err);
  })
  };

  const getRootCauseOptions = (data) => {
    return data.map((item) => ({
      label: get(item, "symptoms", ""),
      value: get(item, "symptoms", ""),
    }));
  };

  const getSelectedProductAttributes = (productData) =>
  productData.map((item, i) => {
    setProductAttributesData((productAttributesData) => ({
      ...productAttributesData,
      [item.attribute_name]: item.attribute_data,
    }));
  });

  useEffect(() => {
    associateSymptomsOption();
    const url =
      config.INSIGHTS_API_URL +
      `/product-intelligent-triage/settings/getRootCausedetails/${rootCause.rootcause}/project/${projectName}`;
    GetCalltoServer(url)
      .then((response) => {
        if (get(response, "data.status.responseType", "") === "success") {
          setDataLoading(true);
          const data = JSON.parse(get(response, "data.data", []));
        setFormData({
          ...data[0],
        });
          getSelectedProductAttributes(data[0].productattributes);
          associateIssueElements(data[0]);
          setSeletctedSymptomsData([...formData.symptoms]);
          setDataLoading(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const associateSymptomsOption = ()  => {
    dispatch(fetchProductTriageDataAction(config));
    setProductAttributesData([]);
    const url =
     config.INSIGHTS_API_URL +
     `/product-intelligent-triage/settings/getSymptomsdetailsSet/project/${projectName}`;
      setSymptomDataLoading(true);
    PostCalltoServer(url, advanceFilters)
      .then((response) => {
        if (get(response, "data.status.responseType", "") === "success") {
          setSymptomDataLoading(false);
          const data = JSON.parse(get(response, "data.data", []));
          setSymptomsOptions(getRootCauseOptions(data));
        } else {
          setSymptomDataLoading(false);
          setSymptomsOptions([]);
        }
      })
      .catch((err) => {
        setSymptomDataLoading(false);
        setSymptomsOptions([]);
      });
  }

  const onChangeHandler = (event, type) => {
    setFormData((formData) => ({
      ...formData,
      [type]: event.target.value,
    }));
    setMandatory(false);
  };

  useEffect(() => { }, [seletctedSymptomsData]);

  const onRemoveItemHandler = (name) => (selectedItem) => {
    if (name === "symptoms") {
      const selectedRootCauses = get(formData, "symptoms", []);
      const filteredRootCauses = seletctedSymptomsData.filter(
        (item) => item.symptoms !== selectedItem
      );
      setSeletctedSymptomsData(filteredRootCauses);
      setFormData((formData) => ({
        ...formData,
        [name]: selectedRootCauses.filter((item) =>  item.symptoms !== selectedItem),
      }));
    } else {
      const selectedProduct = get(productAttributesData, name, []);
      const filteredProducts = selectedProduct.filter(
        (item) => item !== selectedItem
      );
      setProductAttributesData((productAttributesData) => ({
        ...productAttributesData,
        [name]: filteredProducts,
      }));
      setMandatory(false);
    }
  };
  const removeAssociatedResolution = (index) => {
    const list = [...associatedResolution];
    list.splice(index, 1);
    setAssociatedResolution(list);
  };

  const onDropdownChangeHandler = (selectedItems, name) => {
    if (name === "symptoms") {
        const symptomValues= selectedItems.map(obj=> ({symptoms:obj.label,leadingquestion: ""}))
        setSeletctedSymptomsData(symptomValues);
        setFormData((formData) => ({
          ...formData,
          [name]: selectedItems.map(obj=> ({symptoms:obj.label,leadingquestion: ""}))
        }));
    } else {
      setProductAttributesData((productAttributesData) => ({
        ...productAttributesData,
        [name]: selectedItems.map((item) => item.value),
      }));
    }
  };
  const getProductAttributes = (productData) =>
    Object.entries(productData).map(([key, value]) => {
      return {
        attribute_name: key,
        attribute_data: value,
      };
    });


    const Validation = (updatedFormData) => {
      // const attributeData =   updatedFormData.productattributes.map((item) => item.attribute_data);
      // let attributeString = attributeData.toString();
     
      if (isEqual(updatedFormData.rootcause, "")) return true;
      if (isEqual(updatedFormData.rootcauseTitle, "")) return true;
      if (isEqual(updatedFormData.symptoms.length, 0)) return true;

      // if (
      //   (updatedFormData.productattributes.map((item) => item.attribute_name ==="")) &&
      //     (attributeString === "")
      // )
      //   return true;
      // if(updatedFormData.rootcause!= "" &&  updatedFormData.rootcauseTitle!= "" && seletctedSymptomsData.length!= 0){
      //   if (!isEqual(updatedFormData.symptoms), ((seletctedSymptomsData.length == 0))) return true;
      // }else{
      //   return true;
      // }

    };


    const deleteHandler = () => {
      setDeleteLoading(true);
      const url =`${config.INSIGHTS_API_URL}/product-intelligent-triage/settings/rootaggs`;
      const deletedRootcause ={
        "rootcause": formData.rootcause,
        "project": projectName,
      }
      deleteCalltoServer(url,deletedRootcause)
      .then((response) => {
        if (get(response, "data.status.responseType", "") === "success") {
          dispatch(rootCauseDetailsDeleteHandler({
            formData,
            id
          }));
          setDeleteLoading(false);
          // getRootCauses();
          closeDrawer();
          // toastFun('Root cause deleted Successfully', "success");
            dispatch(SetSuccessMessage("Root cause Deleted Successfully"));
        } else {
          setDeleteLoading(false);
        }
      })
      .catch((error) => {
        setDeleteLoading(false);
        toastFun(error, "error");
      })
    }

    const deleteHandlerConfirmation = () => {
      setDeleteConfirmation(!deleteConfirmation);
    }
    
  return (
    <React.Fragment>
      {showDrawer ? (
        <Drawer
          anchor={"right"}
          open={showDrawer}
          onClose={toggleDrawer(false)}
        >
          <div
            className={clsx(classes.list, classes.fullList)}
            role="presentation"
          >
            <DrawerHeaderContent
              heading={rootcausesheading}
              toggleDrawer={toggleDrawer}
            />
            <div className="d-flex flex-column Symtom-Item">
              <div className="mt-1 mb-2">
                <Col md={11}>
                <InputWithLabelComponent
                    type="text"
                    className=""
                    name="rootcause"
                    label={rootcause}
                    value={get(formData, "rootcause", "")}
                    readOnly={true}
                    helpIcon={false}
                  />
                </Col>
              </div>
              <div className="mt-1 mb-2">
                <Col md={11}>
                  <InputWithLabelComponent
                    type="text"
                    className="mt-1"
                    name="rootcauseTitle"
                    label={rootcausestitle}
                    value={formData.rootcauseTitle}
                    readOnly={false}
                    onChange={(e) => onChangeHandler(e, "rootcauseTitle")}
                  />
                </Col>
              </div>
              {/* this code is committed due to requirement in future this code may use, soo please dont remove this code */}
              {/* <div className="mt-1">
                <Col md={11}>
                  <span className="adding-other-input">
                    {associatedResolutionText}
                  </span>
                  <img
                    className="addingInputBox pl-1"
                    onClick={addAssociatedResolution}
                    src="./Icons/AddBlack.svg"
                  />
                  </Col>
                  {associatedResolution.map((item, i) => {
                    return (
                      <div className="d-flex">
                         <Col md={11}>
                        <input
                          className="mb-2 addAssociatebox"
                          placeholder={"Enter Text Here"}
                          onChange={handleChange}
                          value={item.value}
                          id={i}
                          type={item.type}
                          size="57"
                        />
                        </Col>
                        <Col md={1} className="pl-0 pt-2">
                        {isEqual(i, 0) ? null : (
                          <img
                            className="pl-1 addingInputBox"
                            name={item.value}
                            onClick={() => removeAssociatedResolution(i)}
                            src="./images/minus.svg"
                          />
                        )}
                      </Col>
                      </div>
                    );
                  })}
              </div> */}
              <div className="mt-1">
              <Col md={12} className="pl-0 associate-rootcause-alignment" >
              {symptomsOptions.length ? (
                <ProductAttributesComponent
                  options={symptomsOptions}
                  name={"Associated Symptoms"}
                  label={"Associated Symptoms*"}
                  boldTitle={true}
                  search={true}
                  placeholder={"Select"}
                  className={""}
                  targetSourceDescription={"source_name"}
                  sourceHelpIcon={false}
                  attributeHelpIcon={false}
                  value={(formData.symptoms).map(item => item.symptoms)}
                  onChange={(event) =>
                    onDropdownChangeHandler(event, "symptoms")
                  }
                  onRemoveItem={onRemoveItemHandler("symptoms")}
                />
              ) : null}
               </Col>
              </div>
              {(filterData.length && filterData[0].attribute_data != null && !productTriageDataLoading)
                ? filterData.map((item) => {
                    return (
                      <Col md={12} className="pl-0 associate-rootcause-alignment" >
                      <ProductAttributesComponent
                        options={getOptions(item)}
                        name={item.attribute_name}
                        label={item.attribute_name}
                        boldTitle={true}
                        search={true}
                        placeholder={"Select"}
                        className={""}
                        targetSourceDescription={"source_name"}
                        sourceHelpIcon={false}
                        attributeHelpIcon={false}
                        value={get(
                          productAttributesData,
                          item.attribute_name,
                          ""
                        )}
                        onChange={(event) =>
                          onDropdownChangeHandler(event, item.attribute_name)
                        }
                        onRemoveItem={onRemoveItemHandler(item.attribute_name)}
                      />
                      </Col>
                    );
                  })
                : null}
                  <div className="ml-3 ">
                {mandatory ? (
                  <Form.Label className="text-box-error">
                    {"All fields are mandatory"}
                  </Form.Label>
                ) : null}
              </div>
            </div>
            { !deleteConfirmation ? <div className="d-flex pt-3 flex-row button-content">
              <SecondaryButtonWithLoader
                className="ml-5"
                size="lg"
                text="delete"
                onClick={deleteHandlerConfirmation}
              />
              <PrimaryButtonWithLoader
                className="ml-4"
                onClick={saveHandler}
                size="lg"
                text="save"
                loading={saveLoading}
              />
            </div> : null }
            <div className="mb-3">
            { deleteConfirmation ? (<>
              <div className="ml-5">
              <Form.Label className="text-box-title adding-other-input">{"Are you sure you want to delete the root cause?"}</Form.Label>
              </div>
            <div className="d-flex flex-row button-content">
              <SecondaryButtonWithLoader
                className="ml-5"
                size="lg"
                text="No"
                onClick={deleteHandlerConfirmation}
              />
              <PrimaryButtonWithLoader
                className="ml-4"
                onClick={deleteHandler}
                size="lg"
                text="yes"
                loading={deleteLoading}
              />
            </div> </>) : null }
            </div>
          </div>
        </Drawer>
      ) : null}
    </React.Fragment>
  );
};

EditRootcauseDetails.propTypes = {
  rootcausesheading: PropTypes.string,
  rootcause: PropTypes.string,
  savebutton: PropTypes.string,
  disabled: PropTypes.bool,
  associatedResolutionText: PropTypes.string,
  associatedSymptoms: PropTypes.string,
  rootcausestitle: PropTypes.string,
};

EditRootcauseDetails.defaultProps = {
  rootcausesheading: "Edit Root Cause",
  rootcause: "Root Cause*",
  associatedResolutionText: "Associated Resolutions*",
  savebutton: "SAVE",
  disabled: false,
  associatedSymptoms: "Associated Symptoms*",
  rootcausestitle: "Root Cause Title*"
};

export default EditRootcauseDetails;
