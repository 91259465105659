import React from 'react'
import './DropdownInput.css'


const DropDownInput = (props) => {

    return (
        <div className="btn-group btn-lg btn-block custom-dropdown">
            <button type="button" className="btn btn-lg btn-block dropdown-toggle"
                data-toggle="dropdown"
                data-display="static"
                aria-expanded="true">
                {props.selectedValue}
            </button>
            <div className="dropdown-menu" >
                {props.items.map((item,
                    i) => <button  key={i} data-testid={`drop-${i}`}
                        onClick={(e) => {
                            e.preventDefault();
                            props.handleSelection(item.itemKey)
                        }}
                        className="dropdown-item"
                        href="#">
                        {item.itemValue}
                    </button>)}

            </div>
        </div>
    )
};

export default DropDownInput;
