import React from 'react';
import classes from './Entities.module.css';
import  './Entities.module.css';
import {Col,Row} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';


const configuration = (props) => (
    <Row>
        <Col md={12}>
        </Col>
        <Col md={12}>
        <div className="inputContainer">
            <Form.Label>input name*</Form.Label>     
            <Form.Control type="text" name="urlName"  autoComplete="off" required={true} value={props.urlName} onChange={props.changed} placeholder="URL"/>
        </div>
        </Col>

        <Col md={12} className={classes.topSpace}>
        <div className="inputContainer">
            <Form.Label >Select Validation Type</Form.Label>
            <Form.Control as="select" name="validation" onChange={props.changed} required={true} value={props.validationText === 'custom'?props.validationText:props.validation}>  
                <option value="">Select your Option</option>   
               {props.optionValues}
               <option value="custom">Custom</option>
            </Form.Control>
        </div>
        </Col>
        {props.validationText === 'custom'? 
        <>
        <Col md={12}>
        <div className="inputContainer">
            <Form.Label>Validation Name*</Form.Label>     
            <Form.Control type="text" placeholder="Enter Reg expression type.." required={true} autoComplete="off"  name="validationText" onChange={props.changed}  value={props.validationName}/>
        </div>
        {props.emptyValidationTypeMsg}
        </Col>
        <Col md={12}>
        <div className="inputContainer">
            <Form.Label>Validation*</Form.Label>     
            <Form.Control type="text" placeholder="Enter Reg expression.." required={true} autoComplete="off"   onChange={props.changed}  name="ValidationValue" value={props.validation}/>
        </div>
        {props.emptyValidationValueMsg}
        </Col></>:<Col md={12}>
        <div className="inputContainer">
            <Form.Label>Validation Expression Type</Form.Label>     
            <Form.Control type="text" placeholder="Enter validation expression type.."  name="validationName" onChange={props.changed} readOnly={true} value={props.validation} />
        </div>
        </Col>}
        <Col md={12}>
        <div className="inputContainer">
            <Form.Label>Validation Expression Type</Form.Label>     
            <Form.Control type="text" name="invalidEntity"  autoComplete="off" required={true} value={props.invalidEntity} onChange={props.changed} placeholder="Enter validation expression type.."/>
        </div>
        </Col>
        {/* <Col md={12}>
        <div className="inputContainer">
            <Form.Label>Option Type</Form.Label>
            <Form.Control as="select" name="optionType"  value={props.optionType} onChange={props.changed}>     
                <option value="-1">Select your Option</option>
                <option value="addoption">Add Option</option>
                <option value="password">Password</option>
                <option value="AddTextInput">Add Text Input</option>
            </Form.Control>
        </div>
        </Col>
        <Col md={12}>
		{props.addOption === true ? 
        	<><div class="input-group" >
             <input type="text" class="form-control" name="addOptionValue" autocomplete="off" onChange={props.changed} value={props.addOptionValue} placeholder="type here...." ></input>
			 <span class="input-group-btn">
                   <button class="btn blue btn-primary" type="button" onClick={props.buttonClicked}>Add</button>
               </span>
			</div>
           <div className={classes.topSpace}>{props.validateOptionMsg}</div>
           <ul className={classes.optionListing}>
           {props.addOptionList}
            </ul></>:null}		
        </Col>
        {(props.optionType =="password")?
        <Col md={12}>
        <div className="inputContainer">
            <Form.Label>Value*</Form.Label>     
            <Form.Control type="password"  name="defaultValue"  autocomplete="off" required="true"  value={props.defaultValue} onChange={props.changed}/>
        </div>
        {props.emptyDefaultMsg}
        </Col>:<Col md={12}>
        <div className="inputContainer">
            <Form.Label>Value*</Form.Label>     
            <Form.Control type="text"  name="defaultValue"  autocomplete="off" required="true"  value={props.defaultValue} onChange={props.changed}/>
        </div>
        {props.emptyDefaultMsg}
        </Col>}

        <Col md={12}>
        {(props.optionType =="password" || props.optionType =="AddTextInput")?null:
        <div className="inputContainer">
            <Form.Label>Display Option</Form.Label>     
            <Form.Control as="select" name="display"  value={props.display} onChange={props.changed}>
                <option value="-1">Select Your Options</option>
                <option value="dropdown">Dropdown</option>
                <option value="radio">RadioButton</option>
                <option value="checkbox">Checkbox</option>
                <option value="button">TextBox</option>
            </Form.Control>
        </div>}
        </Col> */}
    </Row>
);

export default configuration;