import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../utility';

const initialState = {
    error: null,
    loading: false,
    Usecase:null,
    SourceAttributes:null,
    CollectionVal:null,
    UsecaseDetails:null,
    DataAttributes:null,
};
const GetUseCaseDetailsStart = (state) => {
    return updateObject(state, { error: null, loading: true });
};
const GetUseCaseDetailsSuccess = (state, action) => {
    //console.log(action.FieldsData, 'reducer');
    return updateObject(state, {
        Usecase: action.Data,
        error: null,
        loading: false,
    });
};
const GetUseCaseDetailsFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const GetUseCaseDetailsByNameStart = (state) => {
    return updateObject(state, { error: null, loading: true });
};
const GetUseCaseDetailsByNameSuccess = (state, action) => {
    //console.log(action.FieldsData, 'reducer');
    return updateObject(state, {
        UsecaseDetails : action.Data,
        error: null, 
        loading: false,
    });
};
const GetUseCaseDetailsByNameFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const GetSourceAttributesStart = (state) => {
    return updateObject(state, { error: null, loading: true });
};
const GetSourceAttributesSuccess = (state, action) => {
    //console.log(action.FieldsData, 'reducer');
    return updateObject(state, {
        SourceAttributes: action.Data,
        error: null,
        loading: false,
    });
};
const GetSourceAttributesFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const FetchSourceAttributesStart = (state) => {
    return updateObject(state, { error: null, loading: true });
};
const FetchSourceAttributesSuccess = (state, action) => {
    //console.log(action.FieldsData, 'reducer');
    return updateObject(state, {
        DataAttributes: action.Data,
        error: null,
        loading: false,
    });
};
const FetchSourceAttributesFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const CollectionValueStart = (state) => {
    return updateObject(state, { error: null, loading: true });
};
const CollectionValueSuccess = (state, action) => {
    //console.log(action.FieldsData, 'reducer');
    return updateObject(state, {
        CollectionVal: action.Data,
        error: null,
        loading: false,
    });
};
const CollectionValueFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const updateClusterDataStart = (state) => {
    return updateObject(state, { error: null, loading: true });
};
const updateClusterDataSuccess = (state, action) => {
    //console.log(action.FieldsData, 'reducer');
    return updateObject(state, {
        CollectionVal: action.Data,
        error: null,
        loading: false,
    });
};
const updateClusterDataFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};
const getModelOutPutDataStart = (state, action) => {
    return updateObject(state, { error: null, modelOutputDataLoading: action.modelOutputDataLoading});
};
const getModelOutPutDataSuccess = (state, action) => {
    return updateObject(state, {
        modelOutputData: action.modelOutputData,
        error: null,
    });
};
const getModelOutPutDataFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
    });
};

const getDataReviewDataStart = (state, action) => {
    return updateObject(state, { error: null, dataReviewDataLoading: action.dataReviewDataLoading});
};
const getDataReviewDataSuccess = (state, action) => {
    return updateObject(state, {
        dataReviewData: action.dataReviewData,
        error: null,
    });
};
const getDataReviewDataFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
    });
};
const getSentimentAndCategoryAndStatusSuccess = (state, action) => {
    return updateObject(state, {
        allEditableList: action.allEditableList,
        error: null,
    });
};

const UnsupervisedML = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_USECASE_DETAILS_START: return GetUseCaseDetailsStart(state, action);
        case actionTypes.GET_USECASE_DETAILS_SUCCESS: return GetUseCaseDetailsSuccess(state, action);
        case actionTypes.GET_USECASE_DETAILS_FAIL: return GetUseCaseDetailsFail(state, action);
        case actionTypes.GET_SOURCE_ATTRIBUTES_START: return GetSourceAttributesStart(state, action);
        case actionTypes.GET_SOURCE_ATTRIBUTES_SUCCESS: return GetSourceAttributesSuccess(state, action);
        case actionTypes.GET_SOURCE_ATTRIBUTES_FAIL: return GetSourceAttributesFail(state, action);
        case actionTypes.FETCH_SOURCE_ATTRIBUTES_START: return FetchSourceAttributesStart(state, action);
        case actionTypes.FETCH_SOURCE_ATTRIBUTES_SUCCESS: return FetchSourceAttributesSuccess(state, action);
        case actionTypes.FETCH_SOURCE_ATTRIBUTES_FAIL: return FetchSourceAttributesFail(state, action);
        case actionTypes.CREATE_COLLECTION_VALUE_START: return CollectionValueStart(state, action);
        case actionTypes.CREATE_COLLECTION_VALUE_SUCCESS: return CollectionValueSuccess(state, action);
        case actionTypes.CREATE_COLLECTION_VALUE_FAIL: return CollectionValueFail(state, action);
        case actionTypes.GET_MODELOUTPUT_DATA_START: return getModelOutPutDataStart(state, action);
        case actionTypes.GET_MODELOUTPUT_DATA_SUCCESS: return getModelOutPutDataSuccess(state, action);
        case actionTypes.GET_MODELOUTPUT_DATA_FAIL: return getModelOutPutDataFail(state, action);
        case actionTypes.GET_USECASE_DETAILS_BYNAME_START: return GetUseCaseDetailsByNameStart(state, action);
        case actionTypes.GET_USECASE_DETAILS_BYNAME_SUCCESS: return GetUseCaseDetailsByNameSuccess(state, action);
        case actionTypes.GET_USECASE_DETAILS_BYNAME_FAIL: return GetUseCaseDetailsByNameFail(state, action);
        case actionTypes.GET_DATA_REVIEW_DATA_START: return getDataReviewDataStart(state, action);
        case actionTypes.GET_DATA_REVIEW_DATA_SUCCESS: return getDataReviewDataSuccess(state, action);
        case actionTypes.GET_DATA_REVIEW_DATA_FAIL: return getDataReviewDataFail(state, action);
        case actionTypes.GET_SENTIMENT_CATEGORY_STATUS_LIST_SUCCESS: return getSentimentAndCategoryAndStatusSuccess(state, action);
        default:
            return state;
    }
};
export default UnsupervisedML;