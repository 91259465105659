import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Heading3 from "../../components/Typography/Heading3/Heading3";
import "./UserRoles.css";
import SearchUsersAndRoles from "../helpers/SearchUsersAndRoles";
import PrimaryButtonWithLoader from "../../components/PrimaryButtonWithLoader/PrimaryButtonWithLoader";
import { GetCalltoOUServer } from "../helpers/utility";
import AddRole from "./AddRole";
import RoleContent from "./RoleContent";
import { useSelector } from "react-redux";
import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import permissionsJson from "../../../../../permissions_Json";
import SearchComponent from "../../components/OuManagementSearch/OusearchComponent";
const Roles = ({ config, standardPermissions }) => {
  const userPermissions = useSelector((state) => {
    return state.user.permissionList;
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [showDrawer, setShowDrawer] = useState(false);
  const [filterRoleData, setFilterRoleData] = useState([]);
  const [roleData, setRoleData] = useState([]);
  const [count, setCount] = useState(0);
  
  const toggleDrawer = (value) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setShowDrawer(value);
    setSearchTerm("");
  };
  const getUserCount = (list) => {
    setCount(list.length);
  };

  const getRoles = () => {
    const orgname = localStorage.getItem("orgName");
    const url = config.COMMON_API_URL + `/organization/role?orgname=${orgname}`;
    GetCalltoOUServer(url)
      .then((response) => {
        if (response.data != null) {
          setRoleData(response.data);
          setFilterRoleData(response.data);
          getUserCount(response.data);
        } 
      })
      .catch((err) => {
        console.log(err)
      });
  };

  useEffect(() => {
    getRoles();
  }, []);

  const showDuplicateDrawer = () => {
    setShowDrawer(true);
  };

  const searchHandler = (event) => {
    setSearchTerm(event.target.value);
    const filter = SearchUsersAndRoles(
      roleData,
      "roles",
      event,
      setFilterRoleData
    );
    setFilterRoleData(filter);
  };

  const hasPermission = (code) => {
    if (userPermissions.find((data) => data.permission_code.includes(code)))
      return true;
    else return false;
  };

  const updateRoleList = () => {
    getRoles();
  };

  const sortRoles = (type) => {
    const toBeSortedData = [...filterRoleData];
    toBeSortedData.sort((a, b) => {
      let fa = a[type].toLowerCase();
      let fb = b[type].toLowerCase();
      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
    setFilterRoleData(toBeSortedData);
  };

  let  userManagement_Read_Write = false;
  let userManagement_RW_Status = userPermissions && userPermissions.find(data => {
    if (data.permission_code.includes(permissionsJson.permissionCode.USERMANAGEMENT_RW) && data.sku_code.includes(permissionsJson.skuCode.STANDARD))
      return true
  })
  userManagement_Read_Write = userManagement_RW_Status ? true : false;
  
  return (
    <Container className="symptom-rootcause" fluid>
      <Row>
        <Col xs={6} className="d-flex justify-content-between mb-3">
          <Heading3 className="pageTitleText" text={`Roles`} />
        </Col>
        <Col xs={6} className="d-flex justify-content-end mb-3 pt-3">
          {hasPermission("usermanagement_RW") && (
            <PrimaryButtonWithLoader
              className="mr-2"
              onClick={toggleDrawer(true)} 
              size="lg"
              text="NEW ROLE"
              loading={false}
              disabled ={userManagement_Read_Write ? false : true}
            />
          )}
        </Col>
      </Row>
      <Row className="d-flex justify-content-between">
             <Heading3 xs={6}
               className="pageTitleText count-text-color pl-2"
               text={count}
               users={" Roles are available!"}
            />
        <div xs={6} className="um-search-input">
               <SearchComponent
                  class={"input-group searchClass"}
                  placeholder="Search"
                  value={searchTerm}
                  onSearch={searchHandler}
                  onBlur={searchHandler}
                />
          </div>
      </Row>
      <Row className="mt-4 row row-no-gutters">
        <Col
          xs={12}
          className="d-flex text-left align-items-center symtom-container"
        >
          <div className="row d-flex justify-content-between w-100 content-align-center symtom-heading-data">
            <Col xs={2}>
              <h3 className="issue-descp-title">
                Role Name{" "}
                <a onClick={() => sortRoles("rolename")}>
                  <img src="./Icons/ArrowDown.svg" />
                </a>
              </h3>
            </Col>
            <Col xs={6}>
              <h3 className="issue-descp-title">Description</h3>
            </Col>
            <Col xs={2}>
              <h3 className="issue-descp-title"></h3>
            </Col>
            <Col xs={1}>
              <h3 className="issue-descp-title"></h3>
            </Col>
          </div>
        </Col>
      </Row>
      <div className="content-scroll">
        {filterRoleData.length
          ? filterRoleData.map((role) => {
              return (
                <RoleContent
                  config={config}
                  role={role}
                  key={role.id}
                  getRoles={getRoles}
                  systemPermissions={standardPermissions}
                  updateRoles={updateRoleList}
                  showDuplicateDrawer={showDuplicateDrawer}
                  userManagement_Read_Write = {userManagement_Read_Write}
                  setSearchTerm={setSearchTerm}
                />
              );
            })
          : null}
      </div>
      {showDrawer && (
        <AddRole
          showDrawer={showDrawer}
          toggleDrawer={toggleDrawer}
          config={config}
          updateRoles={updateRoleList}
          systemPermissions={standardPermissions}
          roleheading="Role Details"
          savebutton="SAVE"
          toastFun={(msg, type) => {
            toast(msg, {
              type: type,
              pauseOnHover: false,
              autoClose: 3000,
              hideProgressBar: true,
            });
          }}
          closeDrawer={() => {
            setShowDrawer(false);
          }}
        />
      )}
    </Container>
  );
};

export default Roles;
