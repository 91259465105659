// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TicketFields{
    border: 1px solid #ced4da;
    padding: 15px 0px 10px 20px;
    border-radius: 7px;
    position: relative;
  
  }
  .TicketFields p {
    position: absolute;
    top: -16px;
    left: 15px;
    padding: 0 8px;
    background: white;
  }
  .inputContainer.whiteBg .form-control[readonly] {
    background-color: white !important;
    padding-left: 30px;
    color: #dddddd;
  }
  
  
  .inputContainer.whiteBg label.form-label {
    color: #dddddd;
  }
 .inputContainer.whiteBg {
    border: 1px solid #dddddd;
  }
  .OptionField select.form-control{
    padding-left: 23px;
  }`, "",{"version":3,"sources":["webpack://./src/components/Insights/components/LabelValueComponent/label-value.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,2BAA2B;IAC3B,kBAAkB;IAClB,kBAAkB;;EAEpB;EACA;IACE,kBAAkB;IAClB,UAAU;IACV,UAAU;IACV,cAAc;IACd,iBAAiB;EACnB;EACA;IACE,kCAAkC;IAClC,kBAAkB;IAClB,cAAc;EAChB;;;EAGA;IACE,cAAc;EAChB;CACD;IACG,yBAAyB;EAC3B;EACA;IACE,kBAAkB;EACpB","sourcesContent":[".TicketFields{\n    border: 1px solid #ced4da;\n    padding: 15px 0px 10px 20px;\n    border-radius: 7px;\n    position: relative;\n  \n  }\n  .TicketFields p {\n    position: absolute;\n    top: -16px;\n    left: 15px;\n    padding: 0 8px;\n    background: white;\n  }\n  .inputContainer.whiteBg .form-control[readonly] {\n    background-color: white !important;\n    padding-left: 30px;\n    color: #dddddd;\n  }\n  \n  \n  .inputContainer.whiteBg label.form-label {\n    color: #dddddd;\n  }\n .inputContainer.whiteBg {\n    border: 1px solid #dddddd;\n  }\n  .OptionField select.form-control{\n    padding-left: 23px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
