import React, { useEffect, useState } from 'react';
import { Row, Col, Form } from "react-bootstrap";
import './Drawer.css';
import ButtonCustom from "../../../../components/UIComponents/Button/ButtonComponent";
import InputWithLabelComponent from "../../../SettingPage/SettingInnerPages/components/InputWithLabelComponent/InputWithLabelComponent";
import TextAreaWithLabelComponent from "../../../SettingPage/SettingInnerPages/components/TextAreaWithLabelComponent/TextAreaWithLabelComponent";
import SelectCustom from "../../../../components/UIComponents/SelectDropdown/SelectComponent";

const HttpNodeDetails = ({node, variable, invalidVarMsg, handleExternalSubmit}) => {

    const httpMethods = [{value:'GET',displayValue:'GET'},{value:'POST',displayValue:'POST'},
    {value:'PUT',displayValue:'PUT'},{value:'DELETE',displayValue:'DELETE'}];
    const defaultKeyPair = [{key:'', value:''}];
    const [httpAction, setHttpAction] = useState(node.data?.httpAction?node.data.httpAction:'GET');
    const [url, setUrl] = useState(node.data.url);
    const [httpBodyJson, setHttpBodyJson] = useState(node.data.httpBodyJson);
    const [httpBodyEncoded, setHttpBodyEncoded] = useState(node.data?.httpBodyEncoded?node.data.httpBodyEncoded:defaultKeyPair);
    const [httpFormData, setHttpFormData] = useState(node.data?.httpFormData?node.data.httpFormData:[{key:'',filename:'',filedata:''}]);
    const [headers, setHeaders] = useState(node.data?.headers?node.data.headers:[{key:'Content-Type',value:'application/json', secret:false}]);
    const [params, setParams] = useState(node.data?.params?node.data.params:[{param:'', value:''}]);
    const [radioBtn, setRadioBtn] = useState();
    const [saveTemplate, setSaveTemplate] = useState(false);
    const [isMultipleFiles, setIsMultipleFiles] = useState(node.data?.isMultipleFiles?node.data.isMultipleFiles:false);
    const [referenceVariable, setReferenceVariable] = useState(node.data.referenceVariable);

    useEffect(()=> {
        if(node.type === 'http') {
          let type = node.data?.headers?node.data.headers[0].value:'none';
          switch(type) {
            case 'application/x-www-form-urlencoded' : 
                setRadioBtn('urlencoded');
                break;
            case 'application/json' : 
                if(httpBodyJson === undefined || httpBodyJson === '') {
                  setRadioBtn('none');
                }
                else 
                  setRadioBtn('json');
                break;
            case 'multipart/form-data':
                setRadioBtn('formData');
                break;
            default: setRadioBtn('none');
          }
        }
    },[]);

    const handleOptionChange = (e) => {
        setRadioBtn(e.target.value);
        if(node.type === 'http') {
          if(e.target.value === 'urlencoded') {
            headerUpdate(0,'value','application/x-www-form-urlencoded');
          } else if(e.target.value === 'none') {
            headerUpdate(0,'value','application/json');
            setHttpBodyJson('');
            setHttpBodyEncoded(defaultKeyPair)
          } else if(e.target.value === 'formData') {
            headerUpdate(0,'value','multipart/form-data');
          } else {
            headerUpdate(0,'value','application/json');
          }
        }         
    }

    const updateHeaderCount = () => {
        setHeaders((prev)=>[...prev,{key:'', value:'', secret:false}]);
    }
    
    const updateParamsCount = () => {
        setParams((prev)=>[...prev,{param:'', value:''}]);
    }
    
    const updateHttpBodyCount = (type) => {
        if(type === 'urlencoded') {
            setHttpBodyEncoded((prev)=>[...prev,{key:'', value:''}]);
        }            
        else {
            setHttpFormData((prev)=>[...prev,{key:'', value:''}]);
        }
    }  

    function reduceCount(index,type) {
        switch(type) {
          case 'headers':setHeaders(headers.filter((_, i)=>i !== index)); break; 
          case 'params':setParams(params.filter((_, i)=>i !== index)); break; 
          case 'urlencoded':setHttpBodyEncoded(httpBodyEncoded.filter((_, i)=>i !== index)); break;
          case 'formData':setHttpFormData(httpFormData.filter((_, i)=>i !== index)); break;
        }
    }
    
    function headerUpdate(index, key, value) {
        let tempArray = [...headers];
        tempArray[index] = {...tempArray[index], [key]:value};
        setHeaders(tempArray);
    }

    function paramUpdate(index, key, value) {
        let tempArray = [...params];
        tempArray[index] = {...tempArray[index], [key]:value};
        setParams(tempArray);
    }
    
    function httpBodyUpdate(index, key, value) {
        if(radioBtn === 'urlencoded') {
            let tempArray = [...httpBodyEncoded];
            tempArray[index] = {...tempArray[index], [key]:value};
            setHttpBodyEncoded(tempArray);
        } else {
            let tempArray = [...httpFormData];
            tempArray[index] = {...tempArray[index], [key]:value};
            setHttpFormData(tempArray);
        }
        
    }

    function renderHeaderInputs(index, item) {
        return (
            <Row>
                <Col md={5}>
                    <InputWithLabelComponent
                            type="text"
                            name={item.key}
                            label=""
                            showLabel={false}
                            value={item.key}
                            readOnly={false}
                            onChange={(e) => headerUpdate(index,'key',e.target.value)}
                            required={false}
                            placeholder='Key'
                      />
                </Col>
                <Col md={5}>
                    <InputWithLabelComponent
                            type={item.secret?"password":"text"}
                            name={item.value}
                            label=""
                            showLabel={false}
                            value={item.value}
                            readOnly={false}
                            onChange={(e) => headerUpdate(index,'value',e.target.value)}
                            required={false}
                            placeholder='Value'
                      />
                </Col>
                <Col md={1} className='header-box-button'>
                    <input type="checkbox"   value={item.secret} onChange={e=>headerUpdate(index,'secret',e.target.checked)}/>
                </Col>
                {item.key !== 'Content-Type' && (
                  <Col md={1} className='header-box-button minusBtn-align'>
                  <div onClick={()=>reduceCount(index,'headers')}>
                    <img className='header-more-btn' src="./Icons/removeCircleBlack.svg"/>
                  </div>    
                  </Col>
                )}
                
            </Row>
        )
        
    }
    
    function renderParamInputs(index, item) {
        return (
            <Row className='http-row'>
                <Col md={5}>
                    <InputWithLabelComponent
                            type="text"
                            name={item.param}
                            label=""
                            showLabel={false}
                            value={item.param}
                            readOnly={false}
                            onChange={(e) => paramUpdate(index,'param',e.target.value)}
                            required={false}
                            placeholder='Parameter'
                      />
                </Col>
                <Col md={5}>
                    <InputWithLabelComponent
                            type="text"
                            name={item.value}
                            label=""
                            showLabel={false}
                            value={item.value}
                            readOnly={false}
                            onChange={(e) => paramUpdate(index,'value',e.target.value)}
                            required={false}
                            placeholder='Value'
                      />
                </Col>
                <Col md={2} className='header-box-button minusBtn-align'>
                    <div onClick={()=>reduceCount(index,'params')}>
                      <img className='params-more-btn' src="./Icons/removeCircleBlack.svg"/>  
                    </div>    
                </Col>
            </Row>
        )
    }
    
    function renderHttpBodyInputs(index, item) {
        return (
            <Row key={'httpbody'+index}>
                <Col md={radioBtn === 'urlencoded'?5:4}>
                    <InputWithLabelComponent
                            type="text"
                            name={item.key}
                            label=""
                            showLabel={false}
                            value={item.key}
                            readOnly={false}
                            onChange={(e) => httpBodyUpdate(index,'key',e.target.value)}
                            required={false}
                            placeholder='Key'
                      />
                </Col>
                {radioBtn === 'urlencoded' && (
                    <Col md={5}>
                    <InputWithLabelComponent
                            type="text"
                            name={item.value}
                            label=""
                            showLabel={false}
                            value={item.value}
                            readOnly={false}
                            onChange={(e) => httpBodyUpdate(index,'value',e.target.value)}
                            required={false}
                            placeholder='Value'
                      />
                    </Col>
                )}
                {radioBtn === 'formData' && (
                    <>
                    <Col md={3} className='pd-0'>
                    <InputWithLabelComponent
                            type="text"
                            name={item.filename}
                            label=""
                            showLabel={false}
                            value={item.filename}
                            readOnly={false}
                            onChange={(e) => httpBodyUpdate(index,'filename',e.target.value)}
                            required={false}
                            placeholder='File name'
                      />
                    </Col>
                    <Col md={3} className='pd-0 ml-16'>
                    <InputWithLabelComponent
                            type="text"
                            name={item.filedata}
                            label=""
                            showLabel={false}
                            value={item.filedata}
                            readOnly={false}
                            onChange={(e) => httpBodyUpdate(index,'filedata',e.target.value)}
                            required={false}
                            placeholder='File data'
                      />
                    </Col>
                    </>
                )}
                
                <Col md={1} className={`header-box-button minusBtn-align ${radioBtn==='formData'?'ml-16':''}`}>
                    <div onClick={()=>reduceCount(index,radioBtn)}>
                      <img className='httpbody-more-btn' src="./Icons/removeCircleBlack.svg"/>    
                    </div>     
                </Col>
            </Row>
        )
        
    }

    const isFormValid = () => {
        let validStatus = variable && url && !invalidVarMsg;
        if(radioBtn === 'formData' && isMultipleFiles) {
            validStatus = validStatus && referenceVariable;
        }
        return  validStatus;
    }

    const saveData = () => {
        let httpData = {
            httpAction:httpAction,
            httpBodyJson:radioBtn === 'json'?httpBodyJson:undefined,
            headers:headers,
            params:params[0].param!==''?params:undefined,
            url:url,
            httpBodyEncoded:radioBtn === 'urlencoded'?httpBodyEncoded:undefined,
            httpFormData:radioBtn === 'formData'?httpFormData:undefined,
            isMultipleFiles:radioBtn === 'formData'?isMultipleFiles:undefined,
            referenceVariable:radioBtn === 'formData' && isMultipleFiles?referenceVariable:undefined
        };
        let data = {
            httpData: httpData,
            template: saveTemplate
        }
        handleExternalSubmit('http', data);
    }

    return (
        <>
            <div className="mt-1">
            <Col md={12}>
                <InputWithLabelComponent
                    type="text"
                    name="url"
                    label="URL*"
                    value={url}
                    readOnly={false}
                    onChange={(e) => setUrl(e.target.value)}
                    required={true}
                    placeHolder='Enter URL'
                />
            </Col>
            </div>

            <div className="mt-1">
            <Col md={12}>
                <SelectCustom
                name="selectName"
                label="Method*"
                changed={e => setHttpAction(e.target.value)}
                required={true}
                value={httpAction}
                options={httpMethods}/>
            </Col>
            </div>

            <div className="mt-1">
            <Col md={12}>
            <Row className="http-form-row">
                <Col md={3} className="http-label">
                <Form.Label className="mb-1 text-box-title adding-other-input">Header details</Form.Label>
                </Col>
                <Col md={1} className='header-box-button plusBtn-align'>
                <div onClick={updateHeaderCount}>
                    <img className='addBtn-img' src="./Icons/AddBlackFilled.svg"/>
                </div>    
                </Col>
            </Row>
            </Col>

            <Col md={12}>
                {headers.map((item, index)=> renderHeaderInputs(index,item))}
            </Col>
            </div>

            <div className="mt-1">
            <Col md={12}>
            <Row className="http-form-row">
                <Col md={3} className="http-label">
                <Form.Label className="mb-1 text-box-title adding-other-input">Param details</Form.Label>
                </Col>
                <Col md={1} className='header-box-button plusBtn-align'>
                <div onClick={updateParamsCount}>
                    <img className='addBtn-img' src="./Icons/AddBlackFilled.svg"/>
                </div>    
                </Col>
            </Row>
            </Col>
            <Col md={12}>
                {params.map((item, index)=> renderParamInputs(index,item))}
            </Col>
            </div>

            <div className="mt-1">
            <Col md={12}>
            <Form.Label className="mb-1 text-box-title adding-other-input">Body details</Form.Label>
            <Row className='http-body-options'>
                <Col md={2}>
                <input type="radio" id="none" name="bodyType" value="none" checked={radioBtn === 'none'} onChange={handleOptionChange}/>
                <span >None</span>
                </Col>
                <Col md={2}>
                <input type="radio" id="json" name="bodyType" value="json" checked={radioBtn === 'json'}  onChange={handleOptionChange}/>
                <span >JSON</span>
                </Col>
                <Col md={3}>
                <input type="radio" id="none" name="bodyType" value="formData" checked={radioBtn === 'formData'} onChange={handleOptionChange}/>
                <span >form-data</span>
                </Col>
                <Col md={5}>
                <input type="radio" id="urlencoded" name="bodyType" value="urlencoded" checked={radioBtn === 'urlencoded'} onChange={handleOptionChange}/>
                <span >x-www-form-urlencoded </span>
                </Col>
                </Row>
            </Col>
            </div>
                
            {radioBtn === 'json' && (
                <div className="mt-1">
                <Col md={12}>
                    <TextAreaWithLabelComponent 
                        label="Body"
                        name="httpbody"
                        required ={false}
                        value={httpBodyJson}
                        className='text-area'
                        placeholder='Json data.'
                        onChange={(e) => setHttpBodyJson(e.target.value)}
                        />
                </Col>
                </div>
            )}

            {radioBtn === 'formData' && (
                <>
                {/* <div className="mt-1r"> // to be uncommented when backend is ready
                <Col md={12}>
                    <Row className='settings-form-switch'>
                        <Col md={6}><span className='text-box-title'>Upload multiple files</span></Col>
                        <Col md={6} className='settings-switch'>
                            <Form.Check
                                type="switch"
                                id="isMultipleFiles"
                                checked={isMultipleFiles}
                                onChange={e => setIsMultipleFiles(e.target.checked)}
                            />
                        </Col>
                    </Row>
                </Col>
                </div> */}
                {isMultipleFiles && (
                    <div className="mt-1">
                    <Col md={12}>
                        <InputWithLabelComponent
                            type="text"
                            name="url"
                            label="Reference Variable*"
                            value={referenceVariable}
                            readOnly={false}
                            onChange={(e) => setReferenceVariable(e.target.value)}
                            required={true}
                            placeHolder='Enter Text'
                        />
                    </Col>
                    </div>
                )}
                </>
            )}

            {(radioBtn === 'urlencoded' || radioBtn === 'formData') && (
                <div className="mt-1">
                <Col md={12}>
                <Row className="http-form-row">
                    <Col md={3} className="http-label">
                    <Form.Label className="mb-1 text-box-title adding-other-input">Key-Value pair</Form.Label>
                    </Col>
                    {!isMultipleFiles && (
                        <Col md={1} className='header-box-button plusBtn-align'>
                        <div onClick={e=>updateHttpBodyCount(radioBtn)}>
                            <img className='addBtn-img' src="./Icons/AddBlackFilled.svg"/>
                        </div>    
                        </Col>
                    )}
                    
                </Row>
                </Col>
                <Col md={12}>
                {radioBtn === 'urlencoded' && httpBodyEncoded.map((item, index)=> renderHttpBodyInputs(index,item))}
                {radioBtn === 'formData' && httpFormData.map((item, index)=> renderHttpBodyInputs(index,item))}
                </Col>
                </div>
            )}

            <br/>
            <div className="mt-1">
              <Col md={12}>
                <Form>
                    <Form.Check 
                    type="checkbox"
                    id={`template`+variable}
                    label='Save as template'
                    checked = {saveTemplate}
                    onChange={e=>setSaveTemplate(e.target.checked)}
                  />
                </Form>
              </Col>
            </div>
            <div className="mt-1">
            <Col md={12}>
                <div className="mt-1">
                    <ButtonCustom variant='contained' label='Save'className='buttonWidth' disabled={!isFormValid()} clicked={saveData}/>
                </div>
            </Col>
            </div>
                
        </>
    )


}

export default HttpNodeDetails;