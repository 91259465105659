import get from 'lodash/get';

const fetchRecommendedSolutionFailureReducer = (state, payload) => {
    return {
        ...state,
        recommendedSolutionDataLoading: false,
        error: get(payload, 'error', false)
    };
};

export default fetchRecommendedSolutionFailureReducer;