import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import './primary-button-loader.css';

const PrimaryButtonWithLoader = (
    {
        className,
        onClick,
        disabled,
        loading,
        text,
        ...props
    }
) => {
    return (
        <div className='primary-button-loader'>
            <Button
                variant="primary"
                className={clsx(className, '')}
                onClick={onClick}
                disabled={disabled}
                {...props}
            >
                {loading ?
                    <Spinner
                        as="span"
                        animation="border"
                        className='mr-2'
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    /> : null}
                {text}
            </Button>
        </div>
    )
};

PrimaryButtonWithLoader.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    text: PropTypes.string
};

PrimaryButtonWithLoader.defaultProps = {
    className: '',
    onClick: () => { },
    disabled: false,
    loading: false,
    text: ''
}

export default PrimaryButtonWithLoader;