import axios from '../instance';
import {decrypt} from '../utility/utility';
const orgName = localStorage.getItem("orgName");
const username = localStorage.getItem("userName");
export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const PostCalltoServer=(Url,data,IsStringify=true)=>{  
    return  axios.post(Url, IsStringify? JSON.stringify(data):data,{
        headers: {
         'Content-Type': 'application/json',
         'Accept': 'application/json',
         'Authorization':`Bearer ${localStorage.getItem("accessToken")}`,
         "orgName":orgName,
         "userName":username
         
    }
       // mode: 'no-cors'
      });
  }

export const botPostCalltoServerWithFormData = (Url, data, IsStringify = false) => {  
  const requestOptions = {
    method: 'POST',
    headers: { "Content-Type": "multipart/form-data", 'Authorization': `Bearer ${localStorage.getItem("accessToken")}`,"orgName":orgName,"userName":username },
    data: data,
    Url,
    mode: 'no-cors'
  };
  return axios.post(Url, IsStringify ? JSON.stringify(data) : data, requestOptions);
}



  export const botPostCalltoServer=(Url,data,IsStringify=true)=>{
    const requestOptions = {
      //method: 'POST',
      method: 'POST',
      headers: { 'content-type': 'application/json','Authorization':`Bearer ${localStorage.getItem("accessToken")}`,"orgName":orgName,"userName":username},
      data: data,
      Url,
      mode: 'no-cors'
    };
   
    return  axios.post(Url, IsStringify? JSON.stringify(data):data,requestOptions,{
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          
        }
        //mode: 'no-cors'
      });
  }

  export const GetCalltoServer=(Url)=>{
   return  axios.get(Url,{
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'Authorization':`Bearer ${localStorage.getItem("accessToken")}`,
        "orgName":orgName,
        "userName":username
      }
      //mode: 'no-cors'
    });
  }
  export const UpdateCalltoServer=(Url,data,IsStringify=true)=>{
    return  axios.put(Url, IsStringify? JSON.stringify(data):data,{
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':`Bearer ${localStorage.getItem("accessToken")}`,
        "orgName":orgName,
        "userName":username
      }

      });
  }

  export const deleteCalltoServer=(Url,payload,IsStringify=true)=>{    
    return  axios({
      method: 'DELETE',
      url: Url,  // like this
      data: IsStringify? JSON.stringify(payload):payload,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':`Bearer ${localStorage.getItem("accessToken")}`,
        "orgName":orgName,
        "userName":username
  
      
      },
    })
  }

  export const patchCallToServer = (url, data, IsStringify=true) => {
    return axios({
      method: 'PATCH',
      url: url,  // like this
      data: IsStringify? JSON.stringify(data):data,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem("accessToken")}`,
        "orgName":orgName,
        "userName":username
      },
    })
  }



