import React, { useState, useEffect } from "react";
import Drawer from "@material-ui/core/Drawer";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import "./UserGroups.css";
import { Row, Col,Form } from "react-bootstrap";
import {
    GetCalltoServer,
  } from "../../../../../store/utility";
import DrawerHeaderContent from "../../components/DrawerHeaderContent/DrawerHeaderContent";
import { useDispatch, useSelector } from "react-redux";
import InputWithLabelComponent from "../../components/InputWithLabelComponent/InputWithLabelComponent";
import isEmpty from "lodash/isEmpty";
import PrimaryButtonWithLoader from "../../components/PrimaryButtonWithLoader/PrimaryButtonWithLoader";
import TextAreaWithLabelComponent from '../../components/TextAreaWithLabelComponent/TextAreaWithLabelComponent';
import ReactSelectComponent from '../../components/ReactSelectComponent/ReactSelectComponent';

import { GetCalltoOUServer, PostCallToOUServer} from "../helpers/utility";
import { SetErrorMessage, SetSuccessMessage } from '../../../../../store/actions';

import { isEqual } from "lodash";

const useStyles = makeStyles({
  list: {
    width: 600,
  },
});

const UserGroupForm = ({
  config,
  showDrawer,
  toggleDrawer,
  updateUserGroups,
  title,
  buttonText,
  
  
  
 
  closeDrawer,
  
  group,
  
}) => {

  const userPermissions = useSelector(state => {
    return state.user.permissionList;
  });

  const dispatch = useDispatch();  

  const [projects, setProjects] = useState([]);
  const [roles, setRoles] = useState([]);
  const [mandatory, setMandatory] = useState(false);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]); 
  const [isDisabled, setDisabled] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const orgname = localStorage.getItem("orgName");
  const [formData, setFormData] = useState({
    orgname: orgname,
    usergroup_name:!isEqual(group.usergroup_name, undefined) ? group.usergroup_name + "_copy" :'',
    description:!isEqual(group.description, undefined) ? group.description : '',
    projects: [],
    roles: []
  });

  const classes = useStyles();  
  

  const parseRoles = (data) => {
      let rolenames = [];
      rolenames = data.map(role=>role.rolename);
        return rolenames;
  }

  useEffect(() => {
    getProjectDetails();
    getRoleDetails();
    if(group) {
      setSelectedRoles(!isEqual(group.roles, undefined) ? group.roles : []);
      setSelectedProjects(!isEqual(group.projects, undefined) ? group.projects : []);
      if(group.usergroup_name === 'Tenant Admin')
        setDisabled(true);
    } 
    

  },[]);

  const getProjectDetails = () => {
    const url = config.COMMON_API_URL + `/botbuilder/project/en`;
       
        GetCalltoServer(url)
          .then(response => {
              if (response.data != null) {
             
              setProjects(response.data.data);
            }
           
          }).catch((err) => {
           console.log(err)
          });
  }

  const getRoleDetails = () => {
    const orgname = localStorage.getItem("orgName");
        const url = config.COMMON_API_URL + `/organization/role?orgname=${orgname}`;
        
        GetCalltoOUServer(url)
          .then(response => {
              if (response.data != null) {
              
              setRoles(parseRoles(response.data));
          
            }
            
          }).catch((err) => {
            console.log(err)
          });
  }

  const getOptions = (data) => {
    if (isEmpty(data)) {
      return [];
    }
    const attrData = data;
    return attrData.map((option) => ({
      label: option,
      value: option,
    }));
  };


  const onChangeHandler = (event, type) => {
    setFormData((formData) => ({
      ...formData,
      [type]: event.target.value
    }))
    setMandatory(false);
  };


    const updateProjectData = (e) => {
      setErrorMsg('');
      if(!selectedProjects.includes(e.value))
        setSelectedProjects(prevData => [...prevData,e.value]);
        setMandatory(false);
    }

    const removeProject = (e,item) => {
      e.preventDefault();
        setSelectedProjects(selectedProjects.filter(list => list !== item))
        setMandatory(false);
    }

    const updateRoleData = (e) => {
      setErrorMsg('');
      if(!selectedRoles.includes(e.value))
       setSelectedRoles(prevData => [...prevData,e.value]);
       setMandatory(false);

  }

  const removeRole = (e,item) => {
    e.preventDefault();
      setSelectedRoles(selectedRoles.filter(list => list !== item))
  }

  useEffect(()=>{
    setFormData((formData) => ({
      ...formData,
      roles:selectedRoles
    }))
  },[selectedRoles]);

  useEffect(()=>{
    setFormData((formData) => ({
      ...formData,
      projects:selectedProjects
    }))
  },[selectedProjects]);

  const groupValidation = (formData) => {
    if(updateUserGroups.length === 0){
    if (isEqual( formData.projects, [])) return true;
    if (isEqual( formData.roles, [])) return true;
    }
    else return
  }
    
  const addGroup = (e) => {
    e.preventDefault();
    if (!groupValidation(formData)) {
    const url = config.COMMON_API_URL + `/organization/usergroup`;
    PostCallToOUServer(url, formData)
    .then(response => {
      if(response.status == 201) {
        setTimeout(() => {
          dispatch(SetSuccessMessage('User Group added successfully'));
        },750)
        updateUserGroups();
        closeDrawer();
      } else if(response?.response?.status===400) {
        setErrorMsg('Please choose Roles and Projects.');
      } else if(response.response.status == 409) {
        setErrorMsg('Group name already exists.');
        dispatch(SetErrorMessage('Group name already exists!'));
      } else {
        dispatch(SetErrorMessage('Something went wrong!'));
      }
    });
  } 
  else setMandatory(groupValidation(formData));
}
  const hasPermission = (code) => {
       if(userPermissions.find((data)=>data.permission_code.includes(code)))
        return true;
       else return false;
    }

  
  
  return (
    <React.Fragment>
      {showDrawer ? (
        <Drawer
          anchor={"right"}
          open={showDrawer}
          onClose={toggleDrawer(false)}
        >
          {/* <form onSubmit={addGroup}> */}
          <div
            className={clsx(classes.list, classes.fullList)}
            role="presentation"
          >
            <DrawerHeaderContent
              heading={title}
              toggleDrawer={toggleDrawer}
            /> <br/>
            <div className="d-flex flex-column Symtom-Item highlights">
              <div className="mt-1">
                <Row>
                <Col md={12}>
                <InputWithLabelComponent
                    type="text"
                    className=""
                    label="Group Name*"
                    value={formData.usergroup_name}
                    readOnly={isDisabled}
                    required={true}
                    onChange={(e) => onChangeHandler(e, 'usergroup_name')}
                  />
                </Col>
                </Row>
              </div>
              <div className="mt-1">
                <Row>
                <Col md={12}>
                <TextAreaWithLabelComponent 
                    label="Description*"
                    name="description"
                    required ={true}
                    value={formData.description}
                    placeholder={'Enter description'}
                    onChange={(e) =>onChangeHandler(e, 'description')}
                    />  
                </Col>
                </Row>
              </div>
             <br/>
             <div className="mt-1">
              <Row>
                <Col md={12}>
                  <ReactSelectComponent
                      options={getOptions(roles)}
                      name="userGroups"
                      label="Associated Roles*"
                      placeholder={isDisabled?'--':'Search'}
                      value=""
                      onChange={updateRoleData}
                      disabled={isDisabled}
                      isRequired={true}
                  />
                </Col>
                </Row>
              </div>
              <div className="mt-1">
              <Row>
                 <Col xs={12}>
          <div className='d-flex flex-start align-items-center associate-multi-select'>
                {selectedRoles && selectedRoles.length ? selectedRoles.map((item) => <div className="mr-3 mt-2 pr-2 d-flex flex-start align-items-center selected-option">
                    <div onClick={e=>removeRole(e,item)} className='remove-icon mr-2 ml-2'>
                        <div className="selected-close-icon">x</div>
                    </div>
                    <div className='selected-option-label'>{item}</div>
                </div>) : ''}
            </div>
            </Col>
                {/* <Col md={9}>
                    {selectedRoles.length ? selectedRoles.map((item) => {
                        return (
                              <a className="dyna-list mb-2" key={item} onClick={e=>removeRole(e,item)} ><span className="li-close">X</span>{item}</a> 
                              
                        )
                    }): null}
                   
                </Col> */}
                </Row>
              </div><br/>
              <div className="mt-1">
              <Row>
                <Col md={12}>
                  <ReactSelectComponent
                      options={getOptions(projects)}
                      name="userGroups"
                      label="Associated Projects*"
                      placeholder={isDisabled?'--':'Search'}
                      value=""
                      onChange={updateProjectData}
                      disabled={isDisabled}
                      isRequired={true}
                  />                  
                </Col>
                </Row>
              </div>
              <div className="mt-1">
              <Row>

              <Col xs={12}>
          <div className='d-flex flex-start align-items-center associate-multi-select'>
                {selectedProjects && selectedProjects.length ? selectedProjects.map((item) => <div className="mr-3 mt-2 pr-2 d-flex flex-start align-items-center selected-option">
                    <div onClick={e=>removeProject(e,item)} className='remove-icon mr-2 ml-2'>
                        <div className="selected-close-icon">x</div>
                    </div>
                    <div className='selected-option-label'>{item}</div>
                </div>) : ''}
            </div>
            </Col>
                {/* <Col md={12}>
                    {selectedProjects.length ? selectedProjects.map((item) => {
                        return (                        
                              <a className="dyna-list" key={item} onClick={e=>removeProject(e,item)} ><span className="li-close">X</span>{item==='*'?'All':item}</a> 
                             )
                    }): null}
                   
                </Col> */}
                </Row>
              </div>
              <br/>
            </div><br/>
            <div className="ml-4">
                {mandatory ? (
                  <Form.Label className="text-box-error">
                    {"All fields are mandatory"}
                  </Form.Label>
                ) : null}
              </div>
            {hasPermission('usermanagement_RW') && (
            <div className="d-flex flex-column Symtom-Item">              
            <div className="mt-1">
               <PrimaryButtonWithLoader
                // className="ml-5 mb-2"
                size="lg"
                onClick={addGroup}
                type='submit'
                // disabled = {isEmptyObject(formData)}
                text={buttonText}
                loading={false}
              />
            </div>
            <span className='error-msg'>{errorMsg}</span>
            </div>
            )}            
          </div>
          {/* </form> */}
        </Drawer>
      ) : null}
    </React.Fragment>
  );
};


export default UserGroupForm;
