import React from "react";

import './userManagementComponent.css';

const SelectedItemsComponent = ({selectedItems, removeFun}) => {

    return (
        <>
        {selectedItems.length>0 && selectedItems.map((item,index) => {
            return (
                <div key={index}>
               <div  className='d-flex align-items-center associate-multi-select2 mb-1'>
        <div className="mr-3 pr-2 d-flex flex-start align-items-center selected-option">
            <div onClick={() => removeFun(item)} data-testid={`id`} className='remove-icon mr-2 ml-2'>
                <div className="selected-close-icon">x</div>
            </div>
            <div  className='selected-option-label'>{item}</div>
        </div>
        </div>
        </div>
        
                    )
    
        })}
        </>
    )
}

export default SelectedItemsComponent;
