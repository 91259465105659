import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from '../../../instance';
import './Drawer.css';
import DrawerComponent from '../../UIComponents/Drawer/DrawerComponent';
import ButtonComponent from '../../UIComponents/Button/ButtonComponent'
import FileComponent from '../../UIComponents/FileInput/FileInput';

class ImportProjectDrawer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            right: true
        }
    }

    toggleDrawer = (open) => (event) => {
        if (!open) {
            this.props.onHide();
        }
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            this.props.onHide();
            return;
        }
        this.setState({ right: open });
    };

    render() {

        return (
            <React.Fragment >
                <DrawerComponent
                    heading="Import Project"
                    showDrawer={this.toggleDrawer(false)}
                    openDrawer={this.props.show}>
                    <div className='drawer pt-3 pb-3'>
                        <div className='drawer-body mb-3'>
                            <form>
                                <div className='mt-3 mb-4'>
                                    <FileComponent
                                        value={this.props.value}
                                        error={this.props.error}
                                        fileType='application/x-zip-compressed'
                                        name='projectfile'
                                        onFileChange={this.props.onChange} />
                                </div>
                                <ButtonComponent
                                    variant='contained'
                                    label='IMPORT'
                                    clicked={this.props.submit}
                                    className='buttonWidth'
                                    disabled={!(!this.props.error && this.props.value)}
                                />
                            </form>
                        </div>
                    </div>
                </DrawerComponent>
            </React.Fragment>
        );
    }
}


export default connect()(withRouter(ImportProjectDrawer, axios));
