// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-auto-complete {
  width: 100%;
  margin-top: 0.25rem;
  font-family: 'Roboto-Regular';
  font-size: 14px;
  color: #696868;
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  border-color: black;
  }
  .no-suggestions {
    color: #999;
    padding: 0.5rem;
  }
  .suggestions {
    border: 1px solid #999;
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 143px;
    overflow-y: auto;
    padding-left: 0;
  }
  .suggestions li {
    padding: 0.5rem;
  }
  .suggestion-active,
  .suggestions li:hover {
    background-color: #2196f387;
    color: #fff;
    cursor: pointer;
  }
  .suggestions li:not(:last-of-type) {
    border-bottom: 1px solid #999;
  }
  .custom-auto-complete:focus{
    outline: none !important;
    border:1px solid #5ac8fa;
  }`, "",{"version":3,"sources":["webpack://./src/containers/SettingPage/SettingInnerPages/Insight/AdminConfiguration/autoComplete/AutoComplete.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,mBAAmB;EACnB,6BAA6B;EAC7B,eAAe;EACf,cAAc;EACd,yBAAyB;EACzB,gBAAgB;EAChB,gBAAgB;EAChB,4BAA4B;EAC5B,yBAAyB;EACzB,sBAAsB;EACtB,qEAAqE;EACrE,mBAAmB;EACnB;EACA;IACE,WAAW;IACX,eAAe;EACjB;EACA;IACE,sBAAsB;IACtB,mBAAmB;IACnB,gBAAgB;IAChB,aAAa;IACb,iBAAiB;IACjB,gBAAgB;IAChB,eAAe;EACjB;EACA;IACE,eAAe;EACjB;EACA;;IAEE,2BAA2B;IAC3B,WAAW;IACX,eAAe;EACjB;EACA;IACE,6BAA6B;EAC/B;EACA;IACE,wBAAwB;IACxB,wBAAwB;EAC1B","sourcesContent":[".custom-auto-complete {\n  width: 100%;\n  margin-top: 0.25rem;\n  font-family: 'Roboto-Regular';\n  font-size: 14px;\n  color: #696868;\n  padding: 0.375rem 0.75rem;\n  font-weight: 400;\n  line-height: 1.5;\n  background-clip: padding-box;\n  border: 1px solid #ced4da;\n  border-radius: 0.25rem;\n  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;\n  border-color: black;\n  }\n  .no-suggestions {\n    color: #999;\n    padding: 0.5rem;\n  }\n  .suggestions {\n    border: 1px solid #999;\n    border-top-width: 0;\n    list-style: none;\n    margin-top: 0;\n    max-height: 143px;\n    overflow-y: auto;\n    padding-left: 0;\n  }\n  .suggestions li {\n    padding: 0.5rem;\n  }\n  .suggestion-active,\n  .suggestions li:hover {\n    background-color: #2196f387;\n    color: #fff;\n    cursor: pointer;\n  }\n  .suggestions li:not(:last-of-type) {\n    border-bottom: 1px solid #999;\n  }\n  .custom-auto-complete:focus{\n    outline: none !important;\n    border:1px solid #5ac8fa;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
