import React, { useEffect, useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import Input from '../../../../components/UI/Input/InputCustom';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDataSourceIndexNames, SetErrorMessage, SetSuccessMessage } from '../../../../store/actions';
import { PostCalltoServer } from '../../../../store/utility';

function EditCollectionModal({ config, selectedMenu, selectedSubMenu, dataMappingIndex }) {
    const [show, setShow] = useState(false);
    const [collection, setCollection] = useState(dataMappingIndex);
    const dispatch = useDispatch();
    const dataSourceIndexNamesLoading = useSelector(state => state.projectview.dataSourceIndexNamesLoading);

    useEffect(() => {
        setCollection(dataMappingIndex)
    }, [dataMappingIndex])
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const addItems = () => {
       

        const configObj = {
            'dataSourceType': selectedMenu,
            'dataSourceName': selectedSubMenu,
            "tenantId": localStorage.getItem("orgName"),
            "projectName": localStorage.getItem("projectname"),
            "connector": ""
        }

        let url = config.CONNECTOR_URL + `/` + dataMappingIndex + `/` + collection;
        PostCalltoServer(url, configObj)
            .then(response => {
                if (get(response, 'data.status.responseType', '') === "success") {
                    const payload = {
                        config,
                        selectedMenu,
                        selectedSubMenu,
                    };
                    dispatch(SetSuccessMessage('Edited Collection Successfully'));
                    setTimeout(() => {
                       dispatch(fetchDataSourceIndexNames(payload));
                    }, 100)
                }
                else {
                    dispatch(SetErrorMessage(get(response, 'data.errors.0.source', '')));
                }
            }).catch(err => {
                dispatch(SetErrorMessage(get(err, 'response', '')));
            });
        if (!dataSourceIndexNamesLoading) {
            setShow(false);
        }
    };
    return (
        <React.Fragment>
            <img className='collection-icon add-icon' title='Rename Collection/Index' onClick={handleShow} src="./Icons/EditCollection.svg" />
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Collection</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Input
                        key="editCollection"
                        elementType="text"
                        placeholder='ID'
                        value={collection}
                        name='editCollection'
                        changed={(event) => setCollection(event.target.value)}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={addItems}>
                        Add
                        {dataSourceIndexNamesLoading ?
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            /> : null}
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
            </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
}

export default EditCollectionModal;