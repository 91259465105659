import TRIAGE_CONFIGURATION_MOCK_DATA from "../../../containers/SettingPage/SettingInnerPages/Insight/AdminConfiguration/TraiageConfigurationTabs/constants/TRIAGE_CONFIGURATION_MOCK_DATA";
import {
    GET_SYMPTOMS_DATA, GET_ROOTCAUSES_DATA,
    PRODUCT_ATTRIBUTES_REMOVE_ITEM_HANDLER,
    SYMPTOM_DETAILS_UPDATE_HANDLER,
    SYMPTOM_DETAILS_DELETE_HANDLER,
    ROOTCAUSE_DETAILS_UPDATE_HANDLER,
    ROOTCAUSE_DETAILS_DELETE_HANDLER,
    TRIAGE_CONFIGURATION_CHANGE_HANDLER_ACTION,
    TRIAGE_CONFIGURATION_UPDATE_HANDLER_ACTION,
    SYMPTOM_ROOT_CAUSE_DATA_CHANGE,
    EDIT_SYMPTOMS_TO_ROOT_CAUSE,
    EDIT_ROOT_CAUSE_TO_SYMPTOMS,
    DELETE_ROOT_CAUSE_TO_SYMPTOMS,
    DELETE_SYMPTOMS_TO_ROOT_CAUSE
} from "../../actions/adminSettings/adminSettingsActionTypes";
import getSymptomsDataReducer from "./getSymptomsDataReducer";
import getRootCausesDataReducer from "./getRootCausesDataReducer";
import productAttributesRemoveItemHandlerReducer from "./productAttributesRemoveItemHandlerReducer";
import symptomDetailsUpdateHandlerReducer from "./symptomDetailsUpdateHandlerReducer";
import symptomDetailsDeleteHandlerReducer from "./symptomDetailsDeleteHandlerReducer";
import rootCauseDetailsUpdateHandlerReducer from "./rootCauseDetailsUpdateHandlerReducer";
import rootCauseDetailsDeleteHandlerReducer from "./rootCauseDetailsDeleteHandlerReducer";
import triageConfigurationChangeHandlerReducer from "./triageConfigurationChangeHandlerReducer";
import triageConfigurationUpdateHandlerReducer from "./triageConfigurationUpdateHandlerReducer";
import symptomRootCauseDataChangeHandlerReducer from "./symptomRootCauseDataChangeHandlerReducer";
import editRootCauseToSymptomsReducer from "./editRootCauseToSymptomsReducer";
import editSymptomsToRootCauseReducer from "./editSymptomsToRootCauseReducer";
import deleteRootCauseToSymptomsReducer from "./deleteRootCauseToSymptomsReducer";
import deleteSymptomsToRootCauseReducer from "./deleteSymptomsToRootCauseReducer";

const INITIAL_STATE = {
    triageConfigurationData: {
        triageData: TRIAGE_CONFIGURATION_MOCK_DATA
    },
    symptomsTabData: {
        symptomData: []
    },
    rootCausesTabData: {
        rootCausesData: []
    },
    symptomRootCauseData: [],
};

const reducers = {
    [TRIAGE_CONFIGURATION_CHANGE_HANDLER_ACTION]: triageConfigurationChangeHandlerReducer,
    [PRODUCT_ATTRIBUTES_REMOVE_ITEM_HANDLER]: productAttributesRemoveItemHandlerReducer,
    [SYMPTOM_DETAILS_UPDATE_HANDLER]: symptomDetailsUpdateHandlerReducer,
    [SYMPTOM_DETAILS_DELETE_HANDLER]: symptomDetailsDeleteHandlerReducer,
    [ROOTCAUSE_DETAILS_UPDATE_HANDLER]: rootCauseDetailsUpdateHandlerReducer,
    [ROOTCAUSE_DETAILS_DELETE_HANDLER]: rootCauseDetailsDeleteHandlerReducer,
    [TRIAGE_CONFIGURATION_UPDATE_HANDLER_ACTION]: triageConfigurationUpdateHandlerReducer,
    [GET_SYMPTOMS_DATA]: getSymptomsDataReducer,
    [GET_ROOTCAUSES_DATA]: getRootCausesDataReducer,
    [SYMPTOM_ROOT_CAUSE_DATA_CHANGE]: symptomRootCauseDataChangeHandlerReducer,
    [EDIT_SYMPTOMS_TO_ROOT_CAUSE]: editSymptomsToRootCauseReducer,
    [EDIT_ROOT_CAUSE_TO_SYMPTOMS]: editRootCauseToSymptomsReducer,
    [DELETE_ROOT_CAUSE_TO_SYMPTOMS]: deleteRootCauseToSymptomsReducer,
    [DELETE_SYMPTOMS_TO_ROOT_CAUSE]: deleteSymptomsToRootCauseReducer,


};

/**
 * This reducer is handling the admin settings component all reducers
 *
 * @param {Object} state - the Redux state of the reducer that this function is
 *  used for
 * @param {*} action - the action dispatched with type and payload props.
 * @returns {*} the next Redux state
 */
export const adminSettingsReducer = (
    state = INITIAL_STATE,
    action
) => {
    const { type, payload } = action;
    const reducer = reducers[type];

    return (reducer) ?
        reducer(state, payload) :
        state;
};