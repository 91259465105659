const getSymptomsFiltersChangeHandlerReducer = (state, payload) => {
    return {
        ...state,
           getSymptomsFilterData: {
                ...state.getSymptomsFilterData,
                ...payload
            }
    };
};

export default getSymptomsFiltersChangeHandlerReducer;