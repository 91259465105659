import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import './Card.css';

const CardUI = (props) => {
   return(
      <Card className='customCard' variant="outlined">
      <CardContent>
        <div>
         <span className='cardTitle'>{props.cardInput.cardTitle}</span>  
         {props.cardInput.cardActive?
         <span className='cardStatus'>{props.cardInput.cardStatus}</span>:null}
        </div> 
            {props.cardInput.showButton?
            <Button className='cardButton' variant="outlined">{props.cardInput.cardLabel}</Button>:null} 
      </CardContent>
      <CardContent className='cardNotes'>
         <span>
         {props.cardInput.cardNotes}
         </span> 
      </CardContent>
      <CardContent>
      <Button data-testid={`btnid`} variant={props.cardInput.cardVariant} color="primary" disabled={props.disabled} className='cardAction' onClick={()=>props.buttonClick()}>
         {props.cardInput.buttonLabel}
         </Button>
         
      </CardContent>
    </Card>
  
   )
}

export default CardUI;
