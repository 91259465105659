import React from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import * as actions from '../../../store/actions/index';
import './UserDescription.css';
import { connect } from 'react-redux';
import moment from 'moment';
import PredefinedRanges from '../../../components/UI/DatetimeRangePicker/PredefinedRanges';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ConversationalSentimentDetail from './ConversationalSentimentDetail';
import ConversationHandled from './ConversationHandled';
import ReactSelectComponent from '../../SettingPage/SettingInnerPages/components/ReactSelectComponent/ReactSelectComponent';
import AgentHandover from './AgentHandover';
import SelectComponent from '../../../components/UIComponents/SelectDropdown/SelectComponent';


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
ChartJS.defaults.borderColor = "#655B96";

const download = {
  aiSentiment: 'download-convai-sentiment-report-data',
  aiHandled: 'download-convai-handled-report-data',
  aiAgent: 'download-convai-agent-report-data'
}
const allProj = 'All VA Projects';
class UserDescription extends React.Component {
  constructor(props) {
    super(props);
    let pro = localStorage.getItem('projectname')
    let projectName = "All VA Projects";
    let vaproject = "All";
    let reportDetail = this.props.reportDetail;
    let location = this.props.location.search;
    location = location.split("?")
    let startDate = moment().subtract(6, 'days');
    let endDate = moment();
    if (reportDetail != null) {
      startDate = moment(reportDetail.startDate)
      endDate = moment(reportDetail.endDate)
      projectName = reportDetail.project
      vaproject = reportDetail.virtualAssist
    }
    this.state = {
      selectedCal: '',
      projectname: projectName,
      vaName: vaproject,
      searchT: "",
      popperShow: false,
      botname: '',
      startDate: startDate,
      endDate: endDate,
      selectedMetrics: (location && location[1]) ? location[1] : '',
      SelectedFlowList: [],
      SelectedCumulative: 'cumulative',
      reportTypes: [{ value: 'Conversational Metrics', displayValue: 'Conversational Metrics' },
      { value: 'Cognitive Metrics', displayValue: 'Cognitive Metrics' },
      { value: 'Product Triage Metrics', displayValue: 'Product Triage Metrics' },
      ],
      reportType: 'Conversational Metrics',
    }
  }

  calAPI(val) {
    let apiURL = this.props.config.BOTBUILDER_URL;
    let details = this.props.reportDetail;
    // if (val == 'sentiment') {
    //   if (details)
    //     this.props.conversationFeedbackMetricsData(this.props.config.BOTBUILDER_URL, details);
    // }
    if (val == 'unanswered') {
      this.props.totalunansweredReport(this.props.config.BOTBUILDER_URL, details);
    }

  }
  componentDidMount() {
    let location = this.props.location.search;
    // localStorage.setItem('projectname', this.state.projectname);
    location = location.split("?")
    if (location && location[1]) {
      this.calAPI(location[1])
    }
    let apiURL = this.props.config.BOTBUILDER_URL;
    this.props.OnGetAllAssistantsData(apiURL)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.reportDetail !== this.props.reportDetail) {
      let location = this.props.location.search;
      // localStorage.setItem('projectname', this.state.projectname);
      location = location.split("?")
      if (location && location[1]) {
        this.calAPI(location[1])
      }
    }
  }

  exportData = () => {
    let projName = this.state.projectname
    let data = {
      "startDate": moment(this.state.startDate).utcOffset("+05:30").format('YYYY-MM-DD'),
      "endDate": moment(this.state.endDate).utcOffset("+05:30").format('YYYY-MM-DD'),
      "project": projName == 'common' ? allProj : projName,
      "orgName": localStorage.getItem('orgName'),
    }
    let cat = '';
    let url = '';
    let location = this.props.location.search;
    location = location.split("?")
    if (location && location[1]) {
      cat = location[1]
    }
    if (cat) {
      if (cat == "sentiment") {
        url = download.aiSentiment
      } else if (cat == "handled") {
        url = download.aiHandled
      } else if (cat == "agenthandover") {
        url = download.aiAgent
      }
      this.props.onExportReportData(this.props.config.BOTBUILDER_URL, data, url)
    }

  }

  projectclick = (dataP) => {
    this.setState({ searchT: "", popperShow: false })
    // let savedProjectName = localStorage.getItem('projectname');
    //if (savedProjectName !== projectname) {
    //  localStorage.setItem('projectname', projectname.value);
    //  this.setState({ projectname: projectname.value });
    //}
    this.setState({ projectname: dataP.value });
    this.props.onchangeProjectAction(dataP.value);
    let data = {
      "startDate": moment(this.state.startDate).format('YYYY-MM-DD'),
      "endDate": moment(this.state.endDate).format('YYYY-MM-DD'),
      "project": dataP.value,
      "orgName": localStorage.getItem('orgName'),
      "virtualAssist": this.state.vaName
    }
    this.props.ReportPageData(data)

  }
  VAListClick = (data) => {
    if (data.value) {
        this.setState({ vaName: data.value })
    }
    this.setState({ projectname: allProj },()=>{
      let nrwrdata = {
        "startDate": moment(this.state.startDate).format('YYYY-MM-DD'),
        "endDate": moment(this.state.endDate).format('YYYY-MM-DD'),
        "project": this.state.projectname,
        "orgName": localStorage.getItem('orgName'),
        "virtualAssist": data.value
    }
    this.props.ReportPageData(nrwrdata)
    })
    
}

  backToReportPage = () => {
    this.props.history.push('/ConversationMetrics?back')
  }


  getDateValues = (dateValue) => {
    let projName = this.state.projectname
    this.setState({
      startDate: dateValue.startDate,
      endDate: dateValue.endDate,
    });
    let data = {
      "startDate": moment(dateValue.startDate).format('YYYY-MM-DD'),
      "endDate": moment(dateValue.endDate).format('YYYY-MM-DD'),
      "project": projName == 'common' ? allProj : projName,
      "orgName": localStorage.getItem('orgName'),
      "virtualAssist": this.state.vaName
    }
    this.props.ReportPageData(data)
    this.calAPI(this.state.selectedMetrics)
  }

  
  render() {
        let VAList = '';
        let project = [];
        let alphabeticalOrder = this.props.projectList.bots.sort();
        let projList = '';
        let virtualassistantName = this.props.AssistantsList.map((item)=>{
            return(
              item.virtualassistantName
            )
        })
      
        if (this.props.AssistantsList) {
            let newVA = ["All",...new Set(virtualassistantName)]
            VAList = newVA.map(ele => {
                return { value: ele, label: ele }
            })
            let obj = this.props.AssistantsList.find(ele => ele.virtualassistantName == this.state.vaName)
            if (obj) {
                project = obj.project.filter(ele => ele !== '');
            } else {
                project = [];
            }
            let vaProjectList = alphabeticalOrder.filter(projects => project.includes(projects))
            if (vaProjectList) {
                let newArray = ["All VA Projects", ...new Set(vaProjectList)]
                projList = newArray.map(ele => {
                    return { value: ele, label: ele }
                })
            }
        }
        let allProjectsList = ''
        if (this.props.projectList) {
            let nwra = ["All Projects", ...new Set(this.props.projectList.bots)]
            allProjectsList = nwra.map((item) => {
                return { value: item, label: item }
            })
        }
        let vaProjects = this.props.AssistantsList.map((item)=>{
          return item.project
      })
      let combineList = vaProjects.flat(3);
      let VAProjectsList = '';
      if(vaProjects){
          let nwrwArr = ["All VA Projects",...new Set(combineList)]
          VAProjectsList = nwrwArr.map((ele)=>{
              return {value:ele, label:ele}
          })
      }
      
    let reportDescTop = ''
    let location = this.props.location.search;

    location = location.split("?")
    if (location && location[1]) {

      if (location[1] == 'sentiment' || location[1] == 'handled') {
        reportDescTop = 'Metrics and Insights to improve conversation accuracy & performance'
      }
      if (location[1] == 'agenthandover') {
        reportDescTop = 'Metrics and Insights to improve Agent Handover performance'
      }

      if (location[1] == 'unanswered') {
        reportDescTop = 'Provision to view the number of times the virtual agent'
      }
    }
    let { props } = this;
    props.projectList.bots.sort();
    let projectList = [];
    if (props.projectList) {
      let newArray = ["All Projects", ...new Set(props.projectList.bots)]
      projectList = newArray.map(ele => {
        return { value: ele, label: ele }
      })
    }

    return (
      <div className="userReportDescPage">
        <div className="report-detail">
          <div className="mb-2 row mx-3 pt-2">
            <div className="col-7 arrow-title">
              <div className='reportBackIcon'>
                <ArrowBackIcon onClick={this.backToReportPage} />
              </div>
              <div className='ml-5'>
                <h2>{location[1] == 'sentiment' ? 'Conversations Feedback' : location[1] == 'handled' ? 'Conversations Handled' : 'Agent Handover'}</h2>
                <span className=""></span>
                <div>
                  <span className="reportsPara">
                    {reportDescTop}
                  </span>
                </div>
              </div>
            </div>
            <div className='col-5'>
              <div className='row'>
                 <div className="col-md-6 reportsProjectList">
                    <span className="reportHeading">Virtual Assistant</span>
                    {<ReactSelectComponent
                        customClassName="SelectView"
                        value={{ value: this.state.vaName, label: this.state.vaName }}
                        options={ VAList }
                        name="VirtualAssistant"
                        onChange={(event) => this.VAListClick(event)}
                    >
                    </ReactSelectComponent>}
                </div>
                <div className="col-6 reportsProjectList">
                  <span className="reportHeading">Project</span>
                  <ReactSelectComponent
                    customClassName="SelectView"
                    value={{ value: this.state.projectname, label: this.state.projectname }}
                    options={ this.state.vaName === "None" ? allProjectsList : this.state.vaName === "All" ? VAProjectsList : projList }
                    name="project"
                    onChange={(event) => this.projectclick(event)}
                  />
                </div>

              </div>
              <div className='row'>
                <div className="col-md-6">

                </div>
                <div className="col-md-6 reportsDateRangeOuter" style={{ "height": "60px" }}>
                  <PredefinedRanges onDateChange={this.getDateValues} startDate={this.state.startDate} endDate={this.state.endDate} />
                </div>
              </div>
            </div>

            {
              location[1] == 'sentiment' ? <ConversationalSentimentDetail startDate={this.state.startDate} endDate={this.state.endDate} config={{ ...this.props.config }} /> : location[1] == 'handled' ? <ConversationHandled startDate={this.state.startDate} endDate={this.state.endDate} config={{ ...this.props.config }} /> : <AgentHandover startDate={this.state.startDate} endDate={this.state.endDate} config={{ ...this.props.config }} />
            }
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    projectList: state.user.projects,
    reportDetail: state.reportDashboard.reportDetail,
    totalunansweredReportData: state.reportDashboard.totalunansweredReport,
    botList: state.projectview.botList,
    AssistantsList: state.projectview.AllAssistantsList,

  };
};
const mapDispatchToProps = dispatch => {
  return {
    onTotalfeedbackReport: (apiUrl, data) => dispatch(actions.totalfeedbackReport(apiUrl, data)),
    totalunansweredReport: (apiUrl, data) => dispatch(actions.totalunansweredReport(apiUrl, data)),
    onchangeProjectAction: (projectname) => dispatch(actions.changeProjectAction(projectname)),
    ReportPageData: (data) => dispatch(actions.ReportPageData(data)),
    OnGetBotList: (APIURL) => dispatch(actions.GetBotList(APIURL)),
    onExportReportData: (apiUrl, data, url) => dispatch(actions.exportReportData(apiUrl, data, url)),
    OnGetAllAssistantsData: (APIURL) => dispatch(actions.GetAllAssistantsData(APIURL)),
    conversationFeedbackMetricsData: (apiUrl, data) => dispatch(actions.conversationFeedbackMetricsData(apiUrl, data))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UserDescription);

