import { Component } from 'react';
import { connect } from 'react-redux';
import './canvas.css';
import SelectComponent from '../../../../components/UIComponents/SelectDropdown/SelectComponent';
import ColorPickerComponent from '../components/ColorPicker/ColorPickerComponent';
import ButtonComponent from '../../../../components/UIComponents/Button/ButtonComponent';
import { PostCalltoServer } from '../../../../store/utility';
import DeleteModal from '../../../../components/UIComponents/DeleteModalComponent/DeleteModal';
import * as actions from "../../../../store/actions/index";
import { decrypt } from '../../../../utility/utility';
import SpinnerPage from '../../../../components/UI/Loader/Loader';


const initState = {
    resetPopUp: false,
    userText: {
        responseBgColor: '#1D74F5',
        responseTextColor: '#FFFFFF',
        responseBorderColor: '#1D74F5'
    },
    botResponse: {
        canvasBgColor: '#FFFFFF',
        responseBgColor: '#F1EBEB',
        responseTextColor: '#000000',
        responseBorderColor: '#F1EBEB',
        buttonBgColor: '#FFFFFF',
        buttonTextColor: '#504D4D',
        buttonBorderColor: '#9E9E9E',
        buttonHoverBgColor: '#2A93DF',
        buttonHoverTextColor: '#FFFFFF',
        buttonHoverBorderColor: '#2A93DF',
        timeStampTextColor: '#292121',
        buttonBehaviour: 'disappear'
    },
    showSavePopup: false,
    saveSetting:false,
    resetSetting:false,
    dataLoading:false
}

class CanvasPart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            components: [{ value: 'Bot Response', displayValue: 'Bot Response' },
            { value: 'User Text Response', displayValue: 'User Response' },],
            selectedComponent: 'Bot Response',
            ...initState
        }
    }
    componentDidMount() {
        this.loadHeaderJSon()
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.IsProjectChange !== this.props.IsProjectChange) {
            this.loadHeaderJSon()
            this.setState({
                ...initState
            })
        }
      }

    loadHeaderJSon = () => {
        const url = this.props.config.VIRTUAL_ASSISTANCE_URL+"/virtualAssistant/loadJSON";
        let data = {FolderName:localStorage.getItem('orgName')+'/Intelli'+localStorage.getItem('vaName')+'Bot'}
        this.setState({dataLoading:true})
        PostCalltoServer(url,data)
         .then((response) => {
            if(response=="error") return true;
            else{
                let virtualValues = JSON.parse(response.data)
                if(virtualValues.canvas) {
                    let canvas = virtualValues.canvas
                    this.setState( {userText: canvas.userText, 
                        botResponse:canvas.botResponse
                    })  
                    this.props.onChangeCanvas({
                        userText: {
                            ...this.state.userText,
                        },
                        botResponse: {
                            ...this.state.botResponse,
                        }
                    });
                }

            }
            this.setState({dataLoading:false})
         }).catch(() => {
            console.error('VA JSON load failed');
            this.setState({dataLoading:false})
         });
    }


    onSave = () => {
        let userText = this.state.userText;
        let botResponse = this.state.botResponse;
        let project = {"vaName":localStorage.getItem('vaName'), "project": (this.props.projectDetails).join(),'virtualLink':this.props.config.VIRTUAL_ASSISTANCE_URL,'deployLink':this.props.config.DEPLOYMENT_URL,'websocketurl': this.props.config.WEBSOCKET_URL,'botOrgsLink': this.props.config.BOT_ORGS ,'orgName':localStorage.getItem('orgName')}
        let otherdata = {"token":localStorage.getItem('accessToken'),"project":(this.props.projectDetails).join(),"language":localStorage.getItem('language'),"orgName":localStorage.getItem('orgName'),"vaName":localStorage.getItem('vaName')}
        this.setState({dataLoading:true})
        let data = {
            "userText": {
                "responseBgColor": userText.responseBgColor,
                "responseTextColor": userText.responseTextColor,
                "responseBorderColor": userText.responseBorderColor
            },
            "botResponse": {
                "canvasBgColor":botResponse.canvasBgColor,
                "responseBgColor": botResponse.responseBgColor,
                "responseTextColor": botResponse.responseTextColor,
                "responseBorderColor": botResponse.responseBorderColor,
                "buttonBgColor": botResponse.buttonBgColor,
                "buttonTextColor": botResponse.buttonTextColor,
                "buttonBorderColor": botResponse.buttonBorderColor,
                "buttonHoverBgColor": botResponse.buttonHoverBgColor,
                "buttonHoverTextColor": botResponse.buttonHoverTextColor,
                "buttonHoverBorderColor": botResponse.buttonHoverBorderColor,
                "timeStampTextColor": botResponse.timeStampTextColor,
                "buttonBehaviour": botResponse.buttonBehaviour
            }

        }
        PostCalltoServer(
            this.props.config.VIRTUAL_ASSISTANCE_URL+'/virtualAssistant/create-template',
            project).then(response => {
                let responsee = response.data;
                let dattaa = { data: data, FolderName: responsee.FolderName,other:otherdata }
                PostCalltoServer(this.props.config.VIRTUAL_ASSISTANCE_URL+'/virtualAssistant/canvasLogs', dattaa)
                    .then(result => {
                        if(result.data.status=='Success'){
                            this.props.onSetSuccessMessage("Changes to the canvas section have been updated.")
                           }
                           this.setState({"showSavePopup":false,saveSetting:false,resetSetting:false});
                           
                    });
                    this.setState({dataLoading:false})
            });
    }

    onReset = () => {
        this.setState({
            ...initState,
            saveSetting:false,
            resetSetting:true,
        })
        this.props.onChangeCanvas({
            userText: {
                ...initState.userText,
            },
            botResponse: {
                ...initState.botResponse,
            }
        });
        this.props.onSetSuccessMessage("Canvas section changes have been reset to default.")
    }

    setSelected = (tab) => {
        this.setState({ selected: tab });
    }


    onSelectHandler = (event) => {
        this.setState({
            selectedComponent: event.target.value,
            saveSetting:true,
            resetSetting:true,
        })
    }


    onUserTextColorChange = (key, color) => {
        this.setState({
            userText: {
                ...this.state.userText,
                [key]: color
            },
            saveSetting:true,
            resetSetting:true,
        })
        this.props.onChangeCanvas({
            userText: {
                ...this.state.userText,
                [key]: color
            },
            botResponse: {
                ...this.state.botResponse,
            }
        });
    }
    onBotResponseColorChange = (key, color) => {
        this.setState({
            botResponse: {
                ...this.state.botResponse,
                [key]: color
            },
            saveSetting:true,
            resetSetting:true,
        })
        this.props.onChangeCanvas({
            userText: {
                ...this.state.userText,
            },
            botResponse: {
                ...this.state.botResponse,
                [key]: color
            }
        });
    }

    hasRWPermission = () => {
        return this.props.permissions.RW?false:true;
    }

    render() {
        return (
            <div className="VirtualAssitantCanvas">
                 {this.state.dataLoading ? (
          <div className="row vh-100 justify-content-center align-items-center"  style={{marginLeft:"50%"}}>
            <SpinnerPage animation="border" variant="primary" />
          </div>
        ):''}
                <div className='row mb-4'>
                    <div className='col-md-3'>
                        <SelectComponent
                            name="selectName"
                            label="Select the component"
                            changed={(event) => this.onSelectHandler(event)}
                            required={true}
                            value={this.state.selectedComponent}
                            options={this.state.components}
                        />
                    </div>
                </div>
                {this.state.selectedComponent == "Bot Response" ? <>
                    <div className='row mt-2 pt-1'>
                    <div className='col-md-2'>
                            <ColorPickerComponent
                                type="color"
                                name="canvas-background-color"
                                label="Canvas Background Color"
                                showLabel={true}
                                value={this.state.botResponse.canvasBgColor}
                                readOnly={true}
                                onChange={(e) => this.onBotResponseColorChange("canvasBgColor", e.target.value)}
                                required={false}
                            />
                        </div>
                    </div>
                    <div className='row mt-2 pt-1'>
                        <div className='col-md-2'>
                            <label className='canvas-header'>Chat Bubble Styling</label>
                        </div>
                    </div>
                    <div className='row mt-2 pt-1'>
                        <div className='col-md-2'>
                            <label className="mb-0 text-box-title adding-other-input form-label">
                                Background Color
                            </label>
                        </div>
                        <div className='col-md-2'>
                            <label className="mb-0 text-box-title adding-other-input form-label">
                                Text Color
                            </label>
                        </div>
                        <div className='col-md-2'>
                            <label className="mb-0 text-box-title adding-other-input form-label">
                                Border Color
                            </label>
                        </div>
                    </div>
                    <div className='row mt-0 align-items-end'>
                        <div className='col-md-2'>
                            <ColorPickerComponent
                                type="color"
                                name="response-background-color"
                                label="Response Background Color"
                                showLabel={false}
                                value={this.state.botResponse.responseBgColor}
                                readOnly={true}
                                onChange={(e) => this.onBotResponseColorChange("responseBgColor", e.target.value)}
                                required={false}
                            />
                        </div>
                        <div className='col-md-2'>
                            <ColorPickerComponent
                                type="color"
                                name="response-text-color"
                                label="Response Text Color"
                                showLabel={false}
                                labelContent="AB"
                                labelContentColor={this.state.botResponse.responseTextColor}
                                value={this.state.botResponse.responseBgColor}
                                readOnly={true}
                                onChange={(e) => this.onBotResponseColorChange("responseTextColor", e.target.value)}
                                required={false}
                            />
                        </div>
                        <div className='col-md-2'>
                            <ColorPickerComponent
                                type="color"
                                name="response-border-color"
                                label="Response Border Color"
                                showLabel={false}
                                value={this.state.botResponse.responseBorderColor}
                                readOnly={true}
                                onChange={(e) => this.onBotResponseColorChange("responseBorderColor", e.target.value)}
                                required={false}
                            />
                        </div>
                    </div>
                    <div className='row mt-2 pt-1'>
                        <div className='col-md-2'>
                            <label className='canvas-header'>Button Styling</label>
                        </div>
                    </div>
                    <div className='row mt-2 pt-1'>
                        <div className='col'>
                            <label className='text-box-title'>Button behavior upon selection</label>
                        </div>
                    </div>
                    <div className='row mt-1'>
                        <div className='col-md-12'>
                            <input type="radio" id="disappear" name="btnBehaviour" checked={this.state.botResponse.buttonBehaviour === 'disappear'} value="disappear"  onChange={e=>this.onBotResponseColorChange('buttonBehaviour',e.target.value)}/>
                            <span className='radioOption'>Buttons should disappear</span>
                        </div>
                        <div className='col-md-12'>
                            <input type="radio" id="disable" name="btnBehaviour" checked={this.state.botResponse.buttonBehaviour === 'disable'} value="disable"  onChange={e=>this.onBotResponseColorChange('buttonBehaviour',e.target.value)}/>
                            <span className='radioOption'>Buttons should be disabled</span>
                        </div>
                        <div className='col-md-12'>
                            <input type="radio" id="enable" name="btnBehaviour" checked={this.state.botResponse.buttonBehaviour === 'enable'} value="enable"  onChange={e=>this.onBotResponseColorChange('buttonBehaviour',e.target.value)}/>
                            <span className='radioOption'>Buttons should be clickable</span>
                        </div>
                    </div>
                    <div className='row mt-2 align-items-end'>
                        <div className='col-md-2'>
                            <ColorPickerComponent
                                type="color"
                                name="button-background-color"
                                label="Background Color"
                                value={this.state.botResponse.buttonBgColor}
                                readOnly={true}
                                onChange={(e) => this.onBotResponseColorChange("buttonBgColor", e.target.value)}
                                required={false}
                            />
                        </div>
                        <div className='col-md-2'>
                            <ColorPickerComponent
                                type="color"
                                name="button-text-color"
                                label="Text Color"
                                labelContent="AB"
                                labelContentColor={this.state.botResponse.buttonTextColor}
                                value={this.state.botResponse.buttonBgColor}
                                readOnly={true}
                                onChange={(e) => this.onBotResponseColorChange("buttonTextColor", e.target.value)}
                                required={false}
                            />
                        </div>
                        <div className='col-md-2'>
                            <ColorPickerComponent
                                type="color"
                                name="button-border-color"
                                label="Border Color"
                                value={this.state.botResponse.buttonBorderColor}
                                readOnly={true}
                                onChange={(e) => this.onBotResponseColorChange("buttonBorderColor", e.target.value)}
                                required={false}
                            />
                        </div>
                    </div>
                    <div className='row mt-2 pt-1'>
                        <div className='col-md-2'>
                            <label className="mb-0 text-box-title adding-other-input form-label">
                                Hover Background Color
                            </label>
                        </div>
                        <div className='col-md-2'>
                            <label className="mb-0 text-box-title adding-other-input form-label">
                                Hover Text Color
                            </label>
                        </div>
                        <div className='col-md-2'>
                            <label className="mb-0 text-box-title adding-other-input form-label">
                                Hover Border Color
                            </label>
                        </div>
                    </div>
                    <div className='row mt-0 align-items-end'>
                        <div className='col-md-2'>
                            <ColorPickerComponent
                                type="color"
                                name="button-hover-background-color"
                                label="Button Hover Background Color"
                                showLabel={false}
                                value={this.state.botResponse.buttonHoverBgColor}
                                readOnly={true}
                                onChange={(e) => this.onBotResponseColorChange("buttonHoverBgColor", e.target.value)}
                                required={false}
                            />
                        </div>
                        <div className='col-md-2'>
                            <ColorPickerComponent
                                type="color"
                                name="button-hover-text-color"
                                label="Button Hover Text Color"
                                showLabel={false}
                                labelContent="AB"
                                labelContentColor={this.state.botResponse.buttonHoverTextColor}
                                value={this.state.botResponse.buttonHoverBgColor}
                                readOnly={true}
                                onChange={(e) => this.onBotResponseColorChange("buttonHoverTextColor", e.target.value)}
                                required={false}
                            />
                        </div>
                        <div className='col-md-2'>
                            <ColorPickerComponent
                                type="color"
                                name="buttonHoverBorderColor"
                                label="Button Hover Border Color"
                                showLabel={false}
                                value={this.state.botResponse.buttonHoverBorderColor}
                                readOnly={true}
                                onChange={(e) => this.onBotResponseColorChange("buttonHoverBorderColor", e.target.value)}
                                required={false}
                            />
                        </div>
                    </div>
                    <div className='row mt-2'>
                        <div className='col-md-2'>
                            <ColorPickerComponent
                                type="color"
                                name="timestampTextColor"
                                label="Timestamp Text Color"
                                labelContent="AB"
                                labelContentColor={this.state.botResponse.timeStampTextColor}
                                value={this.state.botResponse.canvasBgColor}
                                readOnly={true}
                                onChange={(e) => this.onBotResponseColorChange("timeStampTextColor", e.target.value)}
                                required={false}
                            />
                        </div>

                    </div>
                </> : <>
                    <div className='row mt-2 pt-1'>
                        <div className='col-md-2'>
                            <label className='canvas-header'>Chat Bubble Styling</label>
                        </div>
                    </div>
                        <div className='row mt-2 pt-1'>
                            <div className='col-md-2'>
                                <label className="mb-0 text-box-title adding-other-input form-label">
                                    Background Color
                            </label>
                            </div>
                            <div className='col-md-2'>
                                <label className="mb-0 text-box-title adding-other-input form-label">
                                    Text Color
                            </label>
                            </div>
                            <div className='col-md-2'>
                                <label className="mb-0 text-box-title adding-other-input form-label">
                                    Border Color
                            </label>
                            </div>
                        </div>
                        <div className='row mt-0 align-items-end'>
                            <div className='col-md-2'>
                                <ColorPickerComponent
                                    type="color"
                                    name="navigation-menu-text"
                                    label="Response Background Color"
                                    showLabel={false}
                                    value={this.state.userText.responseBgColor}
                                    readOnly={true}
                                    onChange={(e) => this.onUserTextColorChange("responseBgColor", e.target.value)}
                                    required={false}
                                />
                            </div>
                            <div className='col-md-2'>
                                <ColorPickerComponent
                                    type="color"
                                    name="navigation-menu-text"
                                    label="Response Text Color"
                                    showLabel={false}
                                    labelContent="AB"
                                    labelContentColor={this.state.userText.responseTextColor}
                                    value={this.state.userText.responseBgColor}
                                    readOnly={true}
                                    onChange={(e) => this.onUserTextColorChange("responseTextColor", e.target.value)}
                                    required={false}
                                />
                            </div>
                            <div className='col-md-2'>
                                <ColorPickerComponent
                                    type="color"
                                    name="navigation-menu-text"
                                    label="Response Border Color"
                                    showLabel={false}
                                    value={this.state.userText.responseBorderColor}
                                    readOnly={true}
                                    onChange={(e) => this.onUserTextColorChange("responseBorderColor", e.target.value)}
                                    required={false}
                                />
                            </div>
                        </div></>}
                <DeleteModal
                    show={this.state.resetPopUp}
                    deleteModal={() => this.setState({ resetPopUp: false })}
                    title="Reset Confirmation"
                    bodyTitle="Do you want to reset the canvas section changes?"
                    buttonClick={this.onReset}
                />

                <DeleteModal
                    show={this.state.showSavePopup}
                    deleteModal={() => this.setState({showSavePopup:false})}
                    title="Save Confirmation"
                    bodyTitle="Do you want to save the changes to the canvas section?"
                    buttonClick={this.onSave}
                />

                <div className='row mt-4 pl-3 mb-4'>
                    <ButtonComponent
                        variant='outlined'
                        label='Reset'
                        className='buttonClass mr-3 reset-btn'
                        clicked={() => this.setState({ resetPopUp: true })}

                    />
                    <ButtonComponent
                        variant='contained'
                        label='Save'
                        className='buttonWidth'
                        clicked={()=>{ this.setState({"showSavePopup":true})}}
                        disabled={!this.state.resetSetting?true:this.hasRWPermission()}

                    />
                </div>
            </div>

        );

    }
}
const mapStateToProps = state => {
    return {
        IsProjectChange:state.projectview.IsProjectChange,
        permissions:state.user.moduleAccess.conversationalAdmin,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onSetSuccessMessage: (message) => dispatch(actions.SetSuccessMessage(message)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CanvasPart);
