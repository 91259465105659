const symptomDetailsDeleteHandlerReducer = (state, payload) => {
    const symptomData = state.symptomsTabData.symptomData;
    const updatedData = symptomData.filter((item, index) => {
          return  index !== payload.id
    });
    return {
        ...state,
        symptomsTabData: {
            ...state.symptomsTabData,
            symptomData: updatedData
        }
    }
}

export default symptomDetailsDeleteHandlerReducer;