var config = {
 API_URL: 'https://localhost:88',
COMMON_API_URL: 'https://hcl-intelliservice.eastus.cloudapp.azure.com',
AUTH_URL:'https://hcl-intelliservice.eastus.cloudapp.azure.com/user/authentication',
FileUpload_URL: 'https://hcl-intelliservice.eastus.cloudapp.azure.com/botbuilder',
BOTBUILDER_URL: 'https://hcl-intelliservice.eastus.cloudapp.azure.com/botbuilder',
BOTBUILDER_PROXY: 'https://hcl-intelliservice.eastus.cloudapp.azure.com/botbuilder-proxy',
CONNECTOR_URL: 'https://hcl-intelliservice.eastus.cloudapp.azure.com/connectors',
CS_GOLANG:"https://hcl-intelliservice.eastus.cloudapp.azure.com",
KIBANA_URL:"kibana-kb-https:5601",
DEPLOYMENT_URL:'https://hcl-intelliservice.eastus.cloudapp.azure.com/',
INSIGHTS_API_URL: 'https://hcl-intelliservice.eastus.cloudapp.azure.com',
WEBSOCKET_URL:'wss://hcl-intelliservice.eastus.cloudapp.azure.com/chatengine',
SOURCE_URL: 'demodata', //not used
KEYCLOAK_URL:'https://hcl-intelliservice.eastus.cloudapp.azure.com/auth/',
VIRTUAL_ASSISTANCE_URL:'https://hcl-intelliservice.eastus.cloudapp.azure.com',
SUPPORT_URL: 'https://help.hcltechsw.com/Intelliservice/en/12.1.7/index.html',
VERSION_INFO_URL: 'https://help.hcltechsw.com/Intelliservice/en/12.1.7/ReleaseNotes/Whats_New.html',
BOT_ORGS: [], //list of org which has access for coversationalUI designer   
FROM_EID: 'itfuser',
FROM_EDOMAIN:'hcl.com',
insights: {
    active: "its",       
    index: "insights_collection",
    type: "insights_collection",
    its: { iTSAPPUrl: "https://localhost:96", iTSESUrl: "https://localhost:8200",			
        projectName: "InsightsBySaida",
        viewName: "InsightsBySaida",
        similarTicketsOptions: {
            defaultFields: ["title","problemDescription"],
            fields: ["impactedUsers","workGroup","resolutionType","ticketOriginatorPhone","resolutionCategory","escalationDate","title","resolution","emailOptOut","ticketOriginatorName","caseId","primaryImpactedProduct","ticketOriginatorEmailId","incidentCategory","downtimeHours","respondDate","restoredDate","resolvedDate","severity","outage","reproducibility","duebyDate","problemDescription","customerName","ticketOrigin","emailSubscriptions","createdDate","incidentSource","plannedReleaseDate","closedDate","problemArea","ticketStatus","jiraId","assignee","defectId","softwareRunningVersion","csatnotified" ],
        }
    },
    xerox: { iTSAPPUrl: "https://localhost:96", iTSESUrl:"https://localhost:8200", projectName: "Xerox",
        viewName: "XeroxInsights",
        similarTicketsOptions: {
            defaultFields: ["title","description"],
            fields: ["SR_NUM","SERIAL_NUM","SYMPTON_CODE","RESOLUTION_CD","SR_CREATED","CLOSE_DT","SR_STATUS","PRODUCT","ESCALATION_DATETIME","title","description","productfamilyname"]
        }
    }
},
SIMILAR_ISSUES_HIDDEN_FIELDS : [ "description" ,"id", "rc_predicted", "resolution", "relevancy","symptoms_predicted"],
CLUSTER_HIDDEN_FIELDS : [ "id", "rc_predicted", "label", "symptoms_predicted", "project", "score"],
SYMPTOMS_ROOT_CAUSE_HIDDEN_FIELDS : [ "description" , "resolution"],
userName:'admin',
LatestToken: 'eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJpc3k5ZUFELUxZM05vWGFXRFZzeEtERFpOeGJuS0Y0Q3Z4Wmp4YnVCLVFvIn0.eyJleHAiOjE2ODA3MjY1MzcsImlhdCI6MTY3ODIyMDU1MiwiYXV0aF90aW1lIjoxNjc4MjIwNTQ3LCJqdGkiOiI2ZGQ3YjY0Mi02ODVkLTQ1MjktYTQxYi1lYjc5MWFhOGMxMjYiLCJpc3MiOiJodHRwczovL2RldnNlY3VyZS5hcGkuc2Fhcy5pbnRlbGxpc2VydmljZS5haS9hdXRoL3JlYWxtcy9pbnRlbGxpc2VydmljZXMub25taWNyb3NvZnQiLCJzdWIiOiJiM2U0MTVkMy03YTM0LTRmYzMtYjU4ZS1mNzdmYzdkZTMzZjIiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJhcGkiLCJub25jZSI6IjQyOWM1YWQ0LWNkNzgtNDg0OS04YWM0LTRjYmVlZGM0YzQxNiIsInNlc3Npb25fc3RhdGUiOiI0ODMzODQ3Yy01ZGFjLTQwM2EtYmUzZS0zNTAzM2M2N2FiY2MiLCJhY3IiOiIxIiwiYWxsb3dlZC1vcmlnaW5zIjpbImh0dHBzOi8vZGV2c2FuZGJveC53ZWIuc2Fhcy5pbnRlbGxpc2VydmljZS5haSIsImh0dHBzOi8vZGV2bWFuYWdlbWVudHVpLndlYi5zYWFzLmludGVsbGlzZXJ2aWNlLmFpIiwiaHR0cDovL2xvY2FsaG9zdDozMDAwIl0sInNjb3BlIjoib3BlbmlkIHByb2ZpbGUgZW1haWwiLCJzaWQiOiI0ODMzODQ3Yy01ZGFjLTQwM2EtYmUzZS0zNTAzM2M2N2FiY2MiLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsInByZWZlcnJlZF91c2VybmFtZSI6ImFuamFsaWtAaW50ZWxsaXNlcnZpY2VzLm9ubWljcm9zb2Z0LmNvbSIsImVtYWlsIjoiYW5qYWxpa0BpbnRlbGxpc2VydmljZXMub25taWNyb3NvZnQuY29tIn0.hx632kFPqNdSPzsSPE0ATLn2TiQeAGbMDYKI8pkLU11_8nmyWBfv4OyyUt1Sh5he-ns9iGhmtSvRANUZYtZLhPjBOPBJQzQmkCnJRTno45ZejG4b5UNUst2sgq41JzqiWq11zDLnPs3kaxi25ffp84TX_e5W2KiJQu7a3hb-8nCoxdCkcn9SHMD_V1Ge0jtl9AddMeu_Bce-FVVtxHhPhJHuAXEbW0ntAHJTApFJ0c6xBMA6oymIPoMMYwsvMfWJOwlulfBo1XbhDVngtYM5MFvsxso8-3Lu1-00zP7EQMHPCV--Ho18iaHoizLR0uADE2dUAfoPXa1y_NJVYjDI1Q'
};
export default config;
