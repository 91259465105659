import React, { useState } from 'react';
import { Row, Col, Form } from "react-bootstrap";
import './Drawer.css';
import ButtonCustom from "../../../../components/UIComponents/Button/ButtonComponent";
import InputWithLabelComponent from "../../../SettingPage/SettingInnerPages/components/InputWithLabelComponent/InputWithLabelComponent";

const AgentTransferDetails = ({data, handleExternalSubmit, variable, invalidVarMsg}) => {

    const defaultData = {label:'',value:''};
    const [agentData, setAgentData] = useState(data?.agentTransferData?.length>0?data.agentTransferData:[defaultData]);
    const [label, setLabel] = useState(data?.label);

    const isFormValid = () => {
        return variable && !invalidVarMsg;
    }

    const saveData = () => {
        
        let emptyData = false;
        if(agentData[0].value === '' && agentData[0].label === '') {
            emptyData = true;
        }
        let nodeData = {
            label,
            agentData: emptyData?[]:agentData
        }
        handleExternalSubmit('agent', nodeData);
    }

    const addNewEntry = () => {
        setAgentData((prev)=>[...prev,defaultData]);
    }

    function reduceCount(index) {
        setAgentData(agentData.filter((_, i) => i !== index));
    }

    const updateAgentDetails = (index, key, value) => {
        let tempArray = [...agentData];
        tempArray[index] = {...tempArray[index], [key]:value};
        setAgentData(tempArray);
    }

    function renderAgentInputs(index, item) { 
        return (
            <Row >
                <Col md={11}>
                    <Row>
                    <Col md={6}>
                    <InputWithLabelComponent
                            type="text"
                            name={item.label}
                            label=""
                            showLabel={false}
                            value={item.label}
                            readOnly={false}
                            onChange={(e) => updateAgentDetails(index,'label',e.target.value)}
                            required={false}
                            placeholder='Label'
                        />
                    </Col>
                    <Col md={6}>
                    <InputWithLabelComponent
                            type="text"
                            name={item.value}
                            label=""
                            showLabel={false}
                            value={item.value}
                            readOnly={false}
                            onChange={(e) => updateAgentDetails(index,'value',e.target.value)}
                            required={false}
                            placeholder='Value'
                        />
                    </Col>
                    </Row>
                </Col>
                <Col md={1} className='countBtn-col minusBtn-align'>
                    {index !== 0 && (
                        <div onClick={()=>reduceCount(index)}>
                        <img className='btn-more-btn' src="./Icons/removeCircleBlack.svg"/>  
                        </div> 
                    )}
                       
                </Col>
            </Row>
        )
        }

    return (
        <div>
            <div className="mt-1">
                <Col md={12}>
                    <InputWithLabelComponent
                        type="text"
                        name="label"
                        label = "Heading for transferring details to agent"
                        value={label}
                        readOnly={false}
                        onChange={(e) => setLabel(e.target.value)}
                        required={true}
                  />
                </Col>
            </div>
            <div className="mt-1">
            <Col md={12}>
            <Row className="FormLabel-row">
                <Col md={9} className="FormLabel-col addBtn-label">
                <Form.Label className="mb-1 text-box-title adding-other-input">Details to be transferred to Agent</Form.Label>
                </Col>
                <Col md={1} className='countBtn-col plusBtn-align'>
                <div onClick={addNewEntry}>
                    <img className='addBtn-img' src="./Icons/AddBlackFilled.svg"/>
                </div>    
                </Col>
            </Row>
            </Col>

            <Col md={12}>
                {agentData.map((item, index)=> renderAgentInputs(index,item))}
            </Col>
            </div>
           
            
            <br/>
            <div className="mt-1">
            <Col md={12}>
                <div className="mt-1">
                    <ButtonCustom variant='contained' label='Save'className='buttonWidth' disabled={!isFormValid()} clicked={saveData}/>
                </div>
            </Col>
            </div>
        </div>
    )

}

export default AgentTransferDetails