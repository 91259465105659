import React from 'react';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';




import './KnowledgeArticles.css';
import { useDispatch, useSelector } from "react-redux";
import { SetWarningMessage } from "../../../store/actions/message";

const KnowledgeArticlesPage = ({
    handleSwitchChange,
    
    enabled,
    ownProps
}) => {
    const dispatch = useDispatch();
         const similarStatus = useSelector(
            (state) => state.insights.similartickets.enabled
        );  
        const issueCStatus = useSelector(
            (state) => state.insights.issueclassification.enabled
        );
        const check = (e)=>{
            if(ownProps.permissions==false) return true

            if(issueCStatus!=false || similarStatus!=false ) {
                handleSwitchChange(e)
            }else{
                dispatch(SetWarningMessage("One Tab Should be Enable"));
            }
        }

    return (
        <div className="KnowledgeArticlesPage" >
            <Accordion defaultActiveKey="0">
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="1">
					<span id="KnowledgeArticlesPage-icon" ><img src="./images/KBInfo.svg" className="InsightsTabsIcons" />Knowledge Articles </span>
                       
                                <div className="KnowledgeArticlesActions">
                            <Form>
                                <Form.Check
                                    type="switch"
                                    id="KnowledgeArticlesPageCustomSwitch"
                                    checked={enabled}
                                    onChange={e => handleSwitchChange(e)}
                                />
                            </Form>
                            <span class="Settings-icon" ><img src="./images/SettingsGrey.svg" /></span>
                        </div>

                    </Accordion.Toggle>
                    {/* <Accordion.Collapse eventKey="1">
                        <Card.Body>
                            <Button variant="primary" disabled={ownProps.permissions==true?false:true} onClick={saveButtonClickHandler}>Save</Button>{' '}
                        </Card.Body>
                    </Accordion.Collapse> */}
                </Card>

            </Accordion>
        </div>
    );
}
export default KnowledgeArticlesPage;