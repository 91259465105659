// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-wrapper{
    margin-top: 2px;
    margin-top: 0.25rem;
    font-family: 'Roboto-Regular';
    font-size: 14px;
    color: #696868;
    resize: none;
    height: 40.56px;
    width: 68.45px;
    border-radius: 0.25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.VirtualAssistantContainer .dropdown-wrapper select {
    background-position-x: 97%;
    background-position-y: 8px;
    background-size: 30%;
    margin-top: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.routing-div .selectClass{
    background-position-x: 97%;
    background-position-y: 7px;
    background-size: 15%;
}`, "",{"version":3,"sources":["webpack://./src/containers/SettingPage/SettingInnerPages/VirtualAssistant/RoutingComponent/routing-wrapper.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,mBAAmB;IACnB,6BAA6B;IAC7B,eAAe;IACf,cAAc;IACd,YAAY;IACZ,eAAe;IACf,cAAc;IACd,sBAAsB;IACtB,0BAA0B;IAC1B,6BAA6B;AACjC;;AAEA;IACI,0BAA0B;IAC1B,0BAA0B;IAC1B,oBAAoB;IACpB,aAAa;IACb,0BAA0B;IAC1B,6BAA6B;AACjC;;AAEA;IACI,0BAA0B;IAC1B,0BAA0B;IAC1B,oBAAoB;AACxB","sourcesContent":[".dropdown-wrapper{\n    margin-top: 2px;\n    margin-top: 0.25rem;\n    font-family: 'Roboto-Regular';\n    font-size: 14px;\n    color: #696868;\n    resize: none;\n    height: 40.56px;\n    width: 68.45px;\n    border-radius: 0.25rem;\n    border-top-right-radius: 0;\n    border-bottom-right-radius: 0;\n}\n\n.VirtualAssistantContainer .dropdown-wrapper select {\n    background-position-x: 97%;\n    background-position-y: 8px;\n    background-size: 30%;\n    margin-top: 0;\n    border-top-right-radius: 0;\n    border-bottom-right-radius: 0;\n}\n\n.routing-div .selectClass{\n    background-position-x: 97%;\n    background-position-y: 7px;\n    background-size: 15%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
