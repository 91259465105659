import { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row, Form } from 'react-bootstrap';
import './header.css';
import InputWithLabelComponent from '../components/InputWithLabelComponent/InputWithLabelComponent';
import ButtonCustom from "../../../../components/UIComponents/Button/ButtonComponent";
import ColorPickerComponent from '../components/ColorPicker/ColorPickerComponent';
import * as actions from "../../../../store/actions/index";
import { Switch } from '@material-ui/core';
import { PostCalltoServer,botPostCalltoServerWithFormData } from '../../../../store/utility';
import DeleteModal from "../../../../components/UIComponents/DeleteModalComponent/DeleteModal";
import * as lists from './Constants';
import SelectComponent from '../../../../components/UIComponents/SelectDropdown/SelectComponent';
import FontFamilyComponent from './FontFamilyComponent/FontFamilyComponent';
import { defaultJSON } from './DefaultPTValues';
import { decrypt } from '../../../../utility/utility';
import SpinnerPage from '../../../../components/UI/Loader/Loader';

let headerTextTouched = false;
let subheaderTextTouched = false;

class HeaderPart extends Component {
    state = {
        headerContent: defaultJSON.header,
        headerNameCount: 8,
        subheaderNameCount: 8,
        resetPopUp:false,
        showSavePopup: false,
        saveSetting:false,
        resetSetting:false,
        botImg:'',
        botImgURL:'',
        invalidImageFile: false,
        dataLoading:false
    }


    loadHeaderJSon = () => {
        const url = this.props.config.VIRTUAL_ASSISTANCE_URL+"/virtualAssistant/loadJSON";
       // this.setState({dataLoading:true})
        let data = {FolderName:localStorage.getItem('orgName')+'/Intelli'+localStorage.getItem('vaName')+'Bot'}
        PostCalltoServer(url,data)
         .then((response) => {
            if(response=="error") return true;
            else{
                let virtualValues = JSON.parse(response.data)
                
                if(virtualValues.header) {
                    this.setState({
                        headerContent:virtualValues.header,
                        botImg:virtualValues.header.botImg
                    })
                    this.props.onChangeHeader(virtualValues.header);
                   // this.setState({dataLoading:false})
                }

            }
         }).catch(() => {
            console.error('VA JSON load failed');
            //this.setState({dataLoading:false})
         });
    }

    onChangeHandler = (event) => {
        let { name, checked, value, type } = event.target;
        const newValue = type === "checkbox" ? checked : value;
        this.setState({saveSetting:true,resetSetting:true})

        if (name === 'headerText') {
            if (newValue.length !== 0 && newValue !== '' && newValue !== undefined && newValue !== null) {
                headerTextTouched = true;
            } else {
                headerTextTouched = false;
            }
            this.setState({
                headerNameCount: 25 - newValue.length
            })
        } else if (name === 'subheaderText') {
            if (newValue.length !== 0 && newValue !== '' && newValue !== undefined && newValue !== null) {
                subheaderTextTouched = true;
            } else {
                subheaderTextTouched = false;
            }
            this.setState({
                subheaderNameCount: 25 - newValue.length
            })
        } else if (type === 'file' && newValue !== "") {

            var fileType = newValue.split(/\.(?=[^\.]+$)/)[1]
            fileType = fileType.toLowerCase();
            this.setState({botImg:event.target.files[0]})
            if (fileType === "svg" || fileType === "png" || fileType === "jpeg" || fileType === "jpg") {
                this.setState({
                    invalidImageFile: false,
                    botImgURL:URL.createObjectURL(event.target.files[0]),
                    botImg:event.target.files[0]
                })

                this.props.onChangeHeaderImg(URL.createObjectURL(event.target.files[0]))
            } else {
                this.setState({
                    invalidImageFile: true,
                })
            }
        }


        this.setState({
            headerContent: {
                ...this.state.headerContent,
                [name]: newValue
            }
        })

        this.props.onChangeHeader({
            ...this.state.headerContent,
            [name]: newValue
        });
        
    }

    ResetData = () => {
        this.setState({
            headerContent: defaultJSON.header,
            headerNameCount: 8,
            resetPopUp:false,
            saveSetting:false,
            resetSetting:true,
            invalidImageFile:false,
            botImg:'',
            botImgURL:''
        });

        this.props.onChangeHeader({
            ...defaultJSON.header
        });
        this.props.onChangeHeaderImg('')

        headerTextTouched = false;
        this.props.onSetSuccessMessage("Header section changes have been reset to default.")

    }

    resetPopUpConformition=()=>{
        this.setState({
            resetPopUp:true,
        })
    }

    saveHeaderData = async (e) => {
        e.preventDefault();
        this.setState({dataLoading:true})
        let project = { "vaName":localStorage.getItem('vaName'),"project": (this.props.projectDetails).join(),'virtualLink':this.props.config.VIRTUAL_ASSISTANCE_URL,'deployLink':this.props.config.DEPLOYMENT_URL,'websocketurl': this.props.config.WEBSOCKET_URL,'orgName':localStorage.getItem('orgName')}
        let otherdata = {"token":localStorage.getItem('accessToken'),"project":(this.props.projectDetails).join(),"language":localStorage.getItem('language'),"orgName":localStorage.getItem('orgName'),'botOrgsLink': this.props.config.BOT_ORGS,"vaName":localStorage.getItem('vaName')}

        PostCalltoServer(
            this.props.config.VIRTUAL_ASSISTANCE_URL+'/virtualAssistant/create-template',
            project).then(response=>{
               const formData = new FormData();

               if(this.state.botImg) {
                formData.append('botImg', this.state.botImg);
            }
                
               let responsee = response.data;
               if(response && response.data){
                formData.append("data",JSON.stringify(this.state.headerContent));
                formData.append("FolderName",responsee.FolderName)
                formData.append("other",JSON.stringify(otherdata))
               
               botPostCalltoServerWithFormData( this.props.config.VIRTUAL_ASSISTANCE_URL+'/virtualAssistant/headerLogs',  formData)
                .then(result=>{
                if(result.data.status=='Success'){
                 this.props.onSetSuccessMessage("Changes to the header section have been updated.")
                }
                this.setState({"showSavePopup":false});
                this.setState({saveSetting:false,resetSetting:false})
             }).catch((e)=>console.log('Error:',e));  
               }
               this.setState({dataLoading:false})   
        });  
       
    }

    hasRWPermission = () => {
        return this.props.permissions.RW?false:true;
    }


    componentDidMount() {
        headerTextTouched = true;
        this.loadHeaderJSon()
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.IsProjectChange !== this.props.IsProjectChange) {
            this.loadHeaderJSon()
           this.ResetData()
        }
    }

    deleteHeaderImg = (e) => {
    e.preventDefault()
    
    this.props.onChangeHeaderImg('')
    this.setState({headerContent:{...this.state.headerContent,
        botImg:''
    },botImg:'',botImgURL:'',saveSetting:true,resetSetting:true})
    this.props.onChangeHeader({
            ...this.state.headerContent,
            botImg:''
        });
    }


    render() {
        let headerTextString = headerTextTouched ? 'Left' : "";
        let selectedImg = this.state.botImgURL
        let DisplayImg = ''
        let DeleteIcon = <img src="./Icons/DeleteBlack.svg" className="deleteHeaderImg" onClick={(e) => this.deleteHeaderImg(e)} />
        if (selectedImg) {
            DisplayImg = <img src={selectedImg} style={{height: "55px", width: "55px",  position: "absolute",
                top: "18px", left: "38%"}}/>;
        } else if(this.state.headerContent.botImg) {
            DisplayImg = <img src={'./VATheme/Templatefiles/'+this.state.headerContent.botImg} style={{height: "55px", width: "55px",  position: "absolute",  top: "18px", left: "38%"}}/>;
        } else{
            DisplayImg = <span>Click to select image file</span>;
            DeleteIcon = ''
        }

        return (
            <Container fluid className="VirtualAssitantHeader">
                {this.state.dataLoading ? (
          <Row className="vh-100 justify-content-center align-items-center"  style={{marginLeft:"50%"}}>
            <SpinnerPage animation="border" variant="primary" />
          </Row>
        ):''}
                <Row>
                    <Col md='3'>
                    <ColorPickerComponent
                                type="color"
                                name="headerBgColor"
                                label="Header Background Color"
                                value={this.state.headerContent.headerBgColor}
                                readOnly={true}
                                onChange={(e) => this.onChangeHandler(e)}
                                required={false}
                            />
                    </Col>
                </Row>
                <Row>
                <Col md='3'>
                    <InputWithLabelComponent
                        type="text"
                        name="headerText"
                        label="Header Text"
                        value={this.state.headerContent.headerText}
                        placeHolder=""
                        readOnly={false}
                        onChange={(e) => this.onChangeHandler(e)}
                        required={true}
                        // maxLength='25'
                    />
                    {/* <span className='mt-1 characters-count'>
                        {this.state.headerNameCount} Characters <span>{headerTextString}</span>
                    </span> */}
                </Col>
                <Col md='2'>
                    <ColorPickerComponent
                        type="color"
                        name="headerTextColor"
                        label="Text Color"
                        labelContent="AB"
                        value={this.state.headerContent.headerBgColor}
                        labelContentColor={this.state.headerContent.headerTextColor}
                        readOnly={true}
                        onChange={(e) => this.onChangeHandler(e)}
                        required={false}
                    />
                </Col>
                </Row>
                <Row>
                <Col md='3'>
                    <FontFamilyComponent
                        type="color"
                        name="navigation-menu-text"
                        label="Text Family"
                        // onChange={e => onChangeHandler('textFamily',e)}
                        // value={data.textFamily}
                        labelContent="AB"
                        readOnly={false}
                        required={true}
                        disabled={true}
                    />
                </Col>
                <Col md='2'>
                    <SelectComponent
                        name="home-style"
                        label="Text Style"
                        className="selectCompCol2"
                        // changed={(e) => onChangeHandler('textStyle',e.value)}
                        required={true}
                        // value={data.textStyle}
                        options={lists.fontStyles}
                        disabled={true}
                    />
                </Col>
                <Col md='2'>
                    <SelectComponent
                        name="home-size"
                        label="Text Size"
                        className="selectCompCol2"
                        // changed={(e) => onChangeHandler('textSize',e.value)}
                        required={true}
                        value={'16px'}
                        options={lists.fontSizes}
                        disabled={true}
                    />
                </Col>
                </Row>
                <Row>
                <Col md='3'>
                    <InputWithLabelComponent
                        type="text"
                        name="subheaderText"
                        label="Sub Header Text"
                        value={this.state.headerContent.subheaderText}
                        placeHolder=""
                        readOnly={false}
                        onChange={(e) => this.onChangeHandler(e)}
                        required={true}
                        // maxLength='25'
                    />
                    {/* <span className='mt-1 characters-count'>
                        {this.state.subheaderNameCount} Characters <span>{subheaderTextTouched ? 'Left' : ""}</span>
                    </span> */}
                </Col>
                <Col md='2'>
                    <ColorPickerComponent
                        type="color"
                        name="subheaderTextColor"
                        label="Text Color"
                        labelContent="AB"
                        value={this.state.headerContent.headerBgColor}
                        labelContentColor={this.state.headerContent.subheaderTextColor}
                        readOnly={true}
                        onChange={(e) => this.onChangeHandler(e)}
                        required={false}
                    />
                </Col>
                </Row>
                <Row>
                <Col md='3'>
                    <FontFamilyComponent
                        type="color"
                        name="navigation-menu-text"
                        label="Text Family"
                        // onChange={e => onChangeHandler('textFamily',e)}
                        // value={data.textFamily}
                        labelContent="AB"
                        readOnly={false}
                        required={true}
                        disabled={true}
                    />
                </Col>
                <Col md='2'>
                    <SelectComponent
                        name="home-style"
                        label="Text Style"
                        className="selectCompCol2"
                        // changed={(e) => onChangeHandler('textStyle',e.value)}
                        required={true}
                        // value={data.textStyle}
                        options={lists.fontStyles}
                        disabled={true}
                    />
                </Col>
                <Col md='2'>
                    <SelectComponent
                        name="home-size"
                        label="Text Size"
                        className="selectCompCol2"
                        // changed={(e) => onChangeHandler('textSize',e.value)}
                        required={true}
                        value={'12px'}
                        options={lists.fontSizes}
                        disabled={true}
                    />
                </Col>
                </Row>
                <Row id='iconsTitleRow'>
                    <Col md='1' className='text-box-title'>Icons</Col>
                    <Col md='1'></Col>
                    <Col md='2' className='text-box-title subTitlePT'>Background colour</Col>
                    <Col md='2' className='text-box-title subTitlePT'>Icon Colour</Col>
                </Row>
                <Row className='iconOptionRow'>
                    <Col md='1' className='icon-title al-center'>Close</Col>
                    <Col md='1'></Col>
                    <Col md='4' className='iconOptionsDiv'>
                        <Row className='ht-100'>
                        <Col className='colWidth'>
                            <svg width="24" height="24" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="8.5" cy="8.5" r="8.5" fill={this.state.headerContent.closeIconBgColor}/>
                            <path d="M5.14686 5.14686C5.1933 5.10031 5.24846 5.06337 5.30919 5.03817C5.36992 5.01297 5.43503 5 5.50078 5C5.56654 5 5.63164 5.01297 5.69238 5.03817C5.75311 5.06337 5.80827 5.10031 5.85471 5.14686L8.50013 7.79329L11.1456 5.14686C11.192 5.10038 11.2472 5.06352 11.3079 5.03836C11.3687 5.01321 11.4337 5.00026 11.4995 5.00026C11.5652 5.00026 11.6303 5.01321 11.691 5.03836C11.7517 5.06352 11.8069 5.10038 11.8534 5.14686C11.8999 5.19334 11.9367 5.24852 11.9619 5.30924C11.9871 5.36997 12 5.43505 12 5.50078C12 5.56651 11.9871 5.6316 11.9619 5.69233C11.9367 5.75305 11.8999 5.80823 11.8534 5.85471L9.20698 8.50013L11.8534 11.1456C11.8999 11.192 11.9367 11.2472 11.9619 11.3079C11.9871 11.3687 12 11.4337 12 11.4995C12 11.5652 11.9871 11.6303 11.9619 11.691C11.9367 11.7517 11.8999 11.8069 11.8534 11.8534C11.8069 11.8999 11.7517 11.9367 11.691 11.9619C11.6303 11.9871 11.5652 12 11.4995 12C11.4337 12 11.3687 11.9871 11.3079 11.9619C11.2472 11.9367 11.192 11.8999 11.1456 11.8534L8.50013 9.20698L5.85471 11.8534C5.80823 11.8999 5.75305 11.9367 5.69233 11.9619C5.6316 11.9871 5.56651 12 5.50078 12C5.43505 12 5.36997 11.9871 5.30924 11.9619C5.24852 11.9367 5.19334 11.8999 5.14686 11.8534C5.10038 11.8069 5.06352 11.7517 5.03836 11.691C5.01321 11.6303 5.00026 11.5652 5.00026 11.4995C5.00026 11.4337 5.01321 11.3687 5.03836 11.3079C5.06352 11.2472 5.10038 11.192 5.14686 11.1456L7.79329 8.50013L5.14686 5.85471C5.10031 5.80827 5.06337 5.75311 5.03817 5.69238C5.01297 5.63164 5 5.56654 5 5.50078C5 5.43503 5.01297 5.36992 5.03817 5.30919C5.06337 5.24846 5.10031 5.1933 5.14686 5.14686Z" fill={this.state.headerContent.closeIconColor}/>
                            </svg>
                        </Col>
                        <Col className='al-center'><div>
                            <span className='colorSpan'>{this.state.headerContent.closeIconBgColor}</span>
                            <span className='colorBoxPT' style={{backgroundColor:this.state.headerContent.closeIconBgColor}}>
                                <input type="color"
                                className="form-control iconColorPT"
                                name='closeIconBgColor'
                                value={this.state.headerContent.closeIconBgColor}
                                onChange={(e) => this.onChangeHandler(e)}
                                />
                            </span>
                        </div></Col>
                        <Col className='al-center'><div>
                            <span className='colorSpan'>{this.state.headerContent.closeIconColor}</span>
                            <span className='colorBoxPT' style={{backgroundColor:this.state.headerContent.closeIconColor}}>
                                <input type="color"
                                className="form-control"
                                name='closeIconColor'
                                value={this.state.headerContent.closeIconColor}
                                onChange={(e) => this.onChangeHandler(e)}
                                />
                            </span>
                        </div></Col>
                        </Row>
                    </Col>
                </Row>
                <Row className='iconOptionRow'>
                    <Col md='1' className='icon-title al-center'>Minimize</Col>
                    <Col md='1'></Col>
                    <Col md='4' className='iconOptionsDiv'>
                        <Row className='ht-100'>
                        <Col className='colWidth'>
                        <svg width="24" height="24" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="8.5" cy="8.5" r="8.5" fill={this.state.headerContent.minimizeIconBgColor}/>
                        <path d="M5 9C5 8.73478 5.05268 8.48043 5.14645 8.29289C5.24021 8.10536 5.36739 8 5.5 8H12.5C12.6326 8 12.7598 8.10536 12.8536 8.29289C12.9473 8.48043 13 8.73478 13 9C13 9.26522 12.9473 9.51957 12.8536 9.70711C12.7598 9.89464 12.6326 10 12.5 10H5.5C5.36739 10 5.24021 9.89464 5.14645 9.70711C5.05268 9.51957 5 9.26522 5 9Z" fill={this.state.headerContent.minimizeIconColor}/>
                        </svg>
                        </Col>
                        <Col className='al-center'><div>
                            <span className='colorSpan'>{this.state.headerContent.minimizeIconBgColor}</span>
                            <span className='colorBoxPT' style={{backgroundColor:this.state.headerContent.minimizeIconBgColor}}>
                                <input type="color"
                                className="form-control iconColorPT"
                                name='minimizeIconBgColor'
                                value={this.state.headerContent.minimizeIconBgColor}
                                onChange={(e) => this.onChangeHandler(e)}
                                />
                            </span>
                        </div></Col>
                        <Col className='al-center'><div>
                            <span className='colorSpan'>{this.state.headerContent.minimizeIconColor}</span>
                            <span className='colorBoxPT' style={{backgroundColor:this.state.headerContent.minimizeIconColor}}>
                                <input type="color"
                                className="form-control"
                                name='minimizeIconColor'
                                value={this.state.headerContent.minimizeIconColor}
                                onChange={(e) => this.onChangeHandler(e)}
                                />
                            </span>
                        </div></Col>
                        </Row>
                    </Col>
                </Row>
                <Row className='iconOptionRow'>
                    <Col md='1' className='icon-title al-center'>Maximize</Col>
                    <Col md='1'>
                    <Switch
                        className='switchMain'
                        checked={this.state.headerContent.maximizeIconEnable}
                        onChange={(e) => this.onChangeHandler(e)}
                        color="primary"
                        name="maximizeIconEnable"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        disableRipple
                    />
                    </Col>
                    <Col md='4' className={['iconOptionsDiv',this.state.headerContent.maximizeIconEnable?'':'disabledOptionRow'].join(' ')} >
                        <Row className='ht-100'>
                        <Col className='colWidth'>
                        <svg width="24" height="24" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="8.5" cy="8.5" r="8.5" fill={this.state.headerContent.maximizeIconEnable?this.state.headerContent.maximizeIconBgColor:'#8C898936'}/>
                        <path d="M3.81505 4.35953L10.004 4.96139L4.76351 10.5068L3.81505 4.35953Z" fill={this.state.headerContent.maximizeIconEnable?this.state.headerContent.maximizeIconColor:'white'}/>
                        <path d="M12.827 13.0619L6.66287 12.5057L11.8851 6.94306L12.827 13.0619Z" fill={this.state.headerContent.maximizeIconEnable?this.state.headerContent.maximizeIconColor:'white'}/>
                        </svg>
                        </Col>
                        <Col className='al-center'><div>
                            <span className='colorSpan'>{this.state.headerContent.maximizeIconBgColor}</span>
                            <span className='colorBoxPT' style={{backgroundColor:this.state.headerContent.maximizeIconBgColor}}>
                                <input type="color"
                                className="form-control iconColorPT"
                                name='maximizeIconBgColor'
                                value={this.state.headerContent.maximizeIconBgColor}
                                onChange={(e) => this.onChangeHandler(e)}
                                disabled = {!this.state.headerContent.maximizeIconEnable}
                                />
                            </span>
                        </div></Col>
                        <Col className='al-center'><div>
                            <span className='colorSpan'>{this.state.headerContent.maximizeIconColor}</span>
                            <span className='colorBoxPT' style={{backgroundColor:this.state.headerContent.maximizeIconColor}}>
                                <input type="color"
                                className="form-control"
                                name='maximizeIconColor'
                                value={this.state.headerContent.maximizeIconColor}
                                onChange={(e) => this.onChangeHandler(e)}
                                disabled = {!this.state.headerContent.maximizeIconEnable}
                                />
                            </span>
                        </div></Col>
                        </Row>
                    </Col>
                </Row>
                <Row> 
                <Col className="mt-2 headerInput pl-0">
                        <Form.Label className="mb-1 text-box-title adding-other-input labelText">Virtual Agent Image</Form.Label>
                        <section className="imgContainer">
                            <label className="fileIinput">
                            {DisplayImg}
                                <input
                                    name="botImg"
                                    type="file"
                                    onChange={(e) => this.onChangeHandler(e)}
                                    accept='image/svg+xml,image/png,image/jpeg'
                                    ref="upload"
                                    id="upload"
                                />
                               {DeleteIcon}
                            </label>
                        </section>

                        <div className='switchDiv'>
                            <Form.Label className="mb-1 text-box-title adding-other-input">Display Image In Header</Form.Label>
                            <br />
                            <Switch
                                className='switchMain'
                                checked={this.state.headerContent.botImgEnabled}
                                onChange={(e) => this.onChangeHandler(e)}
                                color="primary"
                                name="botImgEnabled"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                disableRipple
                            />
                        </div>
                        <div className='contentView'>
                            <img src="./images/info_icon.png" />
                            <span>File format supported is .svg, .png and .jpeg. Same image will be used for header and for responses.</span>
                            <div className='selectedFile'>{this.state.invalidImageFile === true ? "Uploading of image failed as the file format is not supported." : ''}</div>
                        </div>
                    </Col>
                </Row>
                <Row>               
                <Col md='12 ml-0 mt-2 btn-row' className='buttonsDiv'>
                    <ButtonCustom
                        variant='outlined'
                        label='Reset'
                        className='buttonClass mr-3 reset-btn'
                        clicked={this.resetPopUpConformition}
                    />
                    <ButtonCustom
                        variant='contained'
                        label='Save'
                        className='buttonWidth'
                        clicked={()=>{ this.setState({"showSavePopup":true})}}
                        disabled={!this.state.resetSetting?true:this.hasRWPermission()}

                    />
                </Col>
                </Row>

            <DeleteModal
                show={this.state.resetPopUp}
                deleteModal={() => this.setState({resetPopUp:false})}
                title="Reset Confirmation"
                bodyTitle="Do you want to reset the header section changes?"
                buttonClick={this.ResetData}
            />

            <DeleteModal
                show={this.state.showSavePopup}
                deleteModal={() => this.setState({showSavePopup:false})}
                title="Save Confirmation"
                bodyTitle="Do you want to save the changes to the header section?"
                buttonClick={this.saveHeaderData}
            />

            </Container>

        );

    }
}
const mapStateToProps = state => {
    return {
        IsProjectChange:state.projectview.IsProjectChange,
        permissions:state.user.moduleAccess.conversationalAdmin,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onSetSuccessMessage: (message) => dispatch(actions.SetSuccessMessage(message)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderPart);
