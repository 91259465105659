// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.default-css {
    margin-top: 0.25rem;
    font-family: 'Roboto-Regular';
    font-size: 14px;
    color: #696868;
    resize: none;
    /* border-color: black; */
    border: 1px solid #484545;
    height: 40.56px;
}
.error-css {
    color: #FF1B1B;
    margin-top: 0.25rem;
    font-family: 'Roboto-Regular';
    font-size: 12px;
}

.form-control.is-invalid {
    border-color: #FF1B1B  ;
    background-image: none !important;
}
.default-css.form-control:focus{
    border: 1px solid #2A93DF;
}
.text-box-title{
    font-family: 'Roboto-Bold';
    font-size: 12px;
    /* color: #000; */
    color: #696868;
    text-transform: inherit;
}`, "",{"version":3,"sources":["webpack://./src/containers/SettingPage/SettingInnerPages/components/InputWithLabelComponent/InputWithLabel.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,6BAA6B;IAC7B,eAAe;IACf,cAAc;IACd,YAAY;IACZ,yBAAyB;IACzB,yBAAyB;IACzB,eAAe;AACnB;AACA;IACI,cAAc;IACd,mBAAmB;IACnB,6BAA6B;IAC7B,eAAe;AACnB;;AAEA;IACI,uBAAuB;IACvB,iCAAiC;AACrC;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,0BAA0B;IAC1B,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,uBAAuB;AAC3B","sourcesContent":[".default-css {\n    margin-top: 0.25rem;\n    font-family: 'Roboto-Regular';\n    font-size: 14px;\n    color: #696868;\n    resize: none;\n    /* border-color: black; */\n    border: 1px solid #484545;\n    height: 40.56px;\n}\n.error-css {\n    color: #FF1B1B;\n    margin-top: 0.25rem;\n    font-family: 'Roboto-Regular';\n    font-size: 12px;\n}\n\n.form-control.is-invalid {\n    border-color: #FF1B1B  ;\n    background-image: none !important;\n}\n.default-css.form-control:focus{\n    border: 1px solid #2A93DF;\n}\n.text-box-title{\n    font-family: 'Roboto-Bold';\n    font-size: 12px;\n    /* color: #000; */\n    color: #696868;\n    text-transform: inherit;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
