import React, { useEffect, useState } from 'react';
import { Col, Form } from "react-bootstrap";
import './Drawer.css';
import ButtonCustom from "../../../../components/UIComponents/Button/ButtonComponent";
import { useSelector } from "react-redux";
import ReactSelectComponent from "../../../SettingPage/SettingInnerPages/components/ReactSelectComponent/ReactSelectComponent";
import InputWithLabelComponent from "../../../SettingPage/SettingInnerPages/components/InputWithLabelComponent/InputWithLabelComponent";


const FlowNodeDetails = ({node, flowid, invalidVarMsg, variable, handleExternalSubmit}) => {

    const getFlowName = () => {
      return node.data.IsReferance?node.data.flowid:{value:node.data.flowid,label:node.data.label};
    }

    const [radioBtn, setRadioBtn] = useState();
    const [flowName, setFlowName] = useState(node.data?.flowid?getFlowName():'Select');
    const flows = useSelector(state => {
        return state.projectview.CuxBotList;
    });
    const [flowList, setFlowList] = useState();
    const [referenceVariable, setReferenceVariable] = useState(node.data.referenceVariable);

    useEffect(()=>{
        if(referenceVariable !== undefined && referenceVariable !== null) {
            setRadioBtn('passByVar')
          } else {
            setRadioBtn('manual')
          }            
          let list = [];
          flows.map((item)=>{
            if(item.flowid !== flowid){
            list.push({value:item.flowid,label:item.flowname})
            }
          })
          setFlowList(list);
    },[]);

    const handleOptionChange = (e) => {
        setRadioBtn(e.target.value);
        setReferenceVariable(null);
    }

    const isFormValid = () => {
        return variable && (referenceVariable?referenceVariable:flowName.value) && !invalidVarMsg;
    }

    const saveData = () => {
        let flowData = {
            flowid: radioBtn === 'manual'?flowName.value:referenceVariable,
            IsReferance:radioBtn === 'manual'?false:true,
            referenceVariable:radioBtn === 'manual'?undefined:referenceVariable,
            flowName:flowName
        }
        handleExternalSubmit('flow', flowData);
    }

    return (
        <>
              <div className="mt-1">
              <Col md={12}>
                <div>
                  <input type="radio" id="manual" name="inputMethod" value="manual" checked={radioBtn === 'manual'} onChange={handleOptionChange}/>
                  <label >Manual</label>
                  <input type="radio" id="passByVar" name="inputMethod" value="passByVar" checked={radioBtn === 'passByVar'} onChange={handleOptionChange}/>
                  <label >Reference variable</label><br/>
                </div>
              </Col>
              </div>
              {radioBtn === 'manual' && (
                <div className="mt-1">
                <Col md={12}>
                  <div className="common-input">
                    <Form.Label className="mb-1 text-box-title adding-other-input">Flow Name*</Form.Label>
                  </div>
                  <ReactSelectComponent
                        customClassName="SelectView"
                        value={flowName}
                        options={flowList}
                        name="flow"
                        placeholder={'Select'}
                        onChange={(event) => setFlowName(event)}
                  />
                </Col>
                </div>
              )}
              {radioBtn === 'passByVar' && (
                <div className="mt-1">
                <Col md={12}>
                    <InputWithLabelComponent
                        type="text"
                        name="refVar"
                        label= "Reference variable*"
                        value={referenceVariable}
                        readOnly={false}
                        onChange={(e) => setReferenceVariable(e.target.value)}
                        required={true}
                        // isInvalid={errorMsg!==null}
                        // error={errorMsg}
                />
                </Col>
                </div>
              )}
            <br/>
            <div className="mt-1">
            <Col md={12}>
                <div className="mt-1">
                    <ButtonCustom variant='contained' label='Save'className='buttonWidth' disabled={!isFormValid()} clicked={saveData}/>
                </div>
            </Col>
            </div>
              
              </>
    )
}

export default FlowNodeDetails;