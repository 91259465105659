import { isEqual } from "lodash";

const SearchUsersAndRoles = (data, type, event, method) => { 

    const filteredRows = data.filter((row) => {
        // if(event.target.value.trim() === '') {
        //   method(data);
        // }
        if(isEqual(type,"users")){
            return row.first_name.toLowerCase().includes(event.target.value.toLowerCase()) //||
         //   row.last_name.toLowerCase().includes(event.target.value.toLowerCase()) 
            // row.role.toLowerCase().includes(event.target.value.toLowerCase()) ||
            // row.groups.toLowerCase().includes(event.target.value.toLowerCase()) || 
          //  row.status.toLowerCase().includes(event.target.value.toLowerCase())
            // row.skills.toLowerCase().includes(event.target.value.toLowerCase())
        }
        if(isEqual(type,"roles")){
            return row.rolename.toLowerCase().includes(event.target.value.toLowerCase())
        }
        if(isEqual(type,"group")){
            return row.usergroup_name.toLowerCase().includes(event.target.value.toLowerCase()) //||
           // row.roles.toLowerCase().includes(event.target.value.toLowerCase()) || 
            //row.projects.toLowerCase().includes(event.target.value.toLowerCase())
        }
    });

    return filteredRows;

}



export default SearchUsersAndRoles;