import React, { memo, useRef } from 'react';
import './../formStyles.css';
import { useDrag, useDrop } from 'react-dnd';
import * as inputTypes from './../InputTypes';
import { useDispatch, useSelector } from "react-redux";
import { UpdateCuxFormActiveField } from '../../../../../../store/actions';
import { Form } from "react-bootstrap";
import { getLabel, getOptionTitle } from './utility';

export default memo(({ data, index, moveInputFields }) => {

    const ref = useRef(null);
    const dispatch = useDispatch();
    const currentActiveField = useSelector(state => {
        return state.projectview.CuxFormActiveField;
      });


    const [, drop] = useDrop({
        accept: inputTypes.INPUT_ITEM,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        hover(item, monitor) {
            if(!ref.current) {
                return
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            if(dragIndex === hoverIndex) {
                return
            }

            const hoverBoundingRect = ref.current?.getBoundingClientRect()
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top)/2
            const clientOffset = monitor.getClientOffset()
            const hoverClientY = clientOffset.y - hoverBoundingRect.top

            if(dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }

            if(dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }

            moveInputFields(dragIndex, hoverIndex);

            item.index = hoverIndex
        }
    })

    const [, drag] = useDrag(()=> ({
        type: inputTypes.INPUT_ITEM,
        item: {...data, index},
    }));

    drag(drop(ref));

    const updateActiveField = () => {
        dispatch(UpdateCuxFormActiveField(data.index));
    }

    return (
        <div className={[
            'form-input-div',
            data.index===currentActiveField?'active-box':'',
          ].join(" ")}
           ref={ref} onClick={()=>updateActiveField()}>
            <div className='div-padding'>
                <div>{getLabel(data.properties.label)}</div>
                <Form>
                {data.properties.choices.map((choice,key)=>(
                    <Form.Check 
                    type="radio"
                    id={`default-radio`}
                    label={getOptionTitle(choice.title, key)}
                    checked = {choice.value===''?false:data.properties.value.includes(choice.value)}
                    readOnly={true}
                  />
                ))}
                </Form>
             </div>
        </div>
    )
});
