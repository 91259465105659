import React from 'react';
import {NavLink,Link} from 'react-router-dom';
import { connect } from "react-redux";
import classes from './NavigationItems.module.css';
import Dropdown from 'react-bootstrap/Dropdown'
import $ from 'jquery';
import permissions from '../../../permissions_Json';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';
import { withStyles } from "@material-ui/core/styles";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 12,
    fontFamily: "Roboto-Regular",
  },
  arrow: {
    color: theme.palette.common.white,
    "&:before": {
      border: "1px solid #E6E8ED",
      boxShadow: theme.shadows[1],
    },
  },
}))(Tooltip);


const navigationItems = ( props ) => {
  let filterUserName="";
  let splitName = null;

  let userName  = localStorage.getItem("userName").split('@');

  if(userName[0]){
    if(userName[0].includes('.')){
     splitName = userName[0].split('.')
    filterUserName = splitName[0][0].toUpperCase() + splitName[1][0].toUpperCase()
   }else if(userName[0].includes('_')){
    splitName=userName[0].split('_')
    filterUserName = splitName[0][0].toUpperCase() + splitName[1][0].toUpperCase()
   }else{
    filterUserName=userName[0][0].toUpperCase()+userName[0][1].toUpperCase()
   }
}

  

  let dashboardAccess = false;
  let cognitiveSearchAccess = false;
  let projectViewReadAccess = false;
  let agentCockpitReadAccess = false;
  let obj = props.permissions.find(data => {
    if (data.permission_code.includes(permissions.permissionCode.CONVERSATIONAL_AI_DASHBOARD_RW))
      return true
    else if (data.permission_code.includes(permissions.permissionCode.COGNITIVE_SEARCH_DASHBOARD_RW))
      return true
    else if (data.permission_code.includes(permissions.permissionCode.PRODUCT_TRIAGE_DASHBOARD_RW))
      return true
    else
      return false
  })
  dashboardAccess = obj ? true : false;

  let cognitiveResult = props.permissions.find(data => {
    if (data.permission_code.includes(permissions.permissionCode.COGNITIVE_SEARCH_STANDARD_RW) && data.sku_code.includes(permissions.skuCode.CS))
      return true
  })
  cognitiveSearchAccess = cognitiveResult ? true : false

  let accessStatus = props.permissions.find(data => {
    if (data.permission_code.includes(permissions.permissionCode.PROJECTM_R) && data.sku_code.includes(permissions.skuCode.STANDARD))
      return true
  })
  projectViewReadAccess = accessStatus ? true : false

  let ACaccessStatus = props.permissions.find(data => {
    if (data.permission_code.includes(permissions.permissionCode.PRODUCT_TRIAGE_STANDARD_R) && data.sku_code.includes(permissions.skuCode.PT))
      return true
  })

  agentCockpitReadAccess = ACaccessStatus ? true : false

  return (

    <div id={props.path.pathname === '/projectview' || props.path.pathname === '/settings' ? classes["sidebar-containerPv"] : classes["sidebar-container"]} className={{ display: props.DisplaySide ? "sidebar-expanded" : "sidebar-collapsed" }} >
      <img style={{ display: props.DisplaySide ? 'inline-block' : 'none' }} className={classes.loginlogo} src='./images/HomeLogo.svg' />
      <img style={{ display: props.DisplaySide ? 'none' : 'inline-block' }} className={localStorage.getItem('cmmStatus') == 'true' ? classes.CMMlogo1 : classes.loginlogo1} src={localStorage.getItem('cmmStatus') == 'true' ? "./Icons/cmmLogo.png" : './images/LogoSymbol.svg'} title="intelliService" />
      <ul className={classes["list-group"] + ' sidebar_navigation list-group'}>

        <NavLink onClick={(event) => props.CheckIfProjectSelected(event)} to={props.projectname === "Project List" ? "" : "/projectview"} exact className={'bg-dark list-group-item list-group-item-action align-items-start'}><div className="d-flex w-100 justify-content-start ml-1">
          <span><img className={classes.projectViewIconMargin} src='./Icons/projects-icon.png' title="Projects" /> </span>
          <span style={{ display: props.DisplaySide ? 'inline-block' : 'none' }} className={classes.projectViewIconAlignment}>Project View</span>
        </div></NavLink>
        {agentCockpitReadAccess && <NavLink to="/insights" onClick={(event) => props.CheckIfProjectSelected(event)} exact className={'bg-dark list-group-item list-group-item-action align-items-start'}><div className="d-flex w-100 justify-content-start ml-1">
          <span><img className={classes.insightsIcon} src='./Icons/agentcockpitIcon.png' title="Agent Cockpit" /> </span>
          <span style={{ display: props.DisplaySide ? 'inline-block' : 'none' }} className={classes.insightsAlignment}>Agent Cockpit</span>
        </div></NavLink>}
        {cognitiveSearchAccess && <NavLink to="/Cognitive" onClick={(event) => props.CheckIfProjectSelected(event)} exact className={'bg-dark list-group-item list-group-item-action align-items-start'}><div className="d-flex w-100 justify-content-start ml-1">
          <span><img className={classes.insightsIconMargin} src='./Icons/cognitive-search-icon.png' title="Cognitive Search" /> </span>
          <span style={{ display: props.DisplaySide ? 'inline-block' : 'none' }} className={classes.insightsAlignment}>Cognitive Search</span>
        </div></NavLink>}
        {dashboardAccess && 
        <Dropdown className="navigationHelpMenu dashboard" style={{ "position": "relative" ,"left":"5px"}}>
          <Dropdown.Toggle id="navigationHelpMenu" drop='end'>
            <img  src='./Icons/Reports.svg' title="Insights" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item>
              <NavLink to="/ConversationMetrics" onClick={() => $('.navigationHelpMenu div[aria-labelledby="navigationHelpMenu"]').removeClass('show')} className={[classes.navigationItemsDropdown, classes.highlight].join(" ")}
                exact>
                Conversation Metrics
              </NavLink>
            </Dropdown.Item>
            <Dropdown.Item>
              <NavLink to="/CognitiveMetrics" onClick={() => $('.navigationHelpMenu div[aria-labelledby="navigationHelpMenu"]').removeClass('show')} className={[classes.navigationItemsDropdown, classes.highlight,classes.navlinks].join(" ")}
                exact>
                Cognitive Metrics
              </NavLink>
            </Dropdown.Item>
            <Dropdown.Item>
            <NavLink to="/ProductTriageMetrics" onClick={() => $('.navigationHelpMenu div[aria-labelledby="navigationHelpMenu"]').removeClass('show')} className={[classes.navigationItemsDropdown, classes.highlight].join(" ")}
              exact>
              Product Triage Metrics
            </NavLink>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>}        
        {/* <Dropdown className="navigationHelpMenu" style={{ "position": "absolute",'bottom':'35px'}}>
        <Dropdown.Toggle  id="navigationHelpMenu">
            <img src='./Icons/UserImage.svg' title={localStorage.getItem("userName")} style={{'width':'30px','cursor':'default'}}/>
        </Dropdown.Toggle>
        </Dropdown> */}
        <div  style={{ "position": "absolute",'bottom':'50px',left:'9px'}}>
            <LightTooltip  title={localStorage.getItem("userName")} aria-label="title" placement="right">
               <Fab size="small" className={classes.fabClass} >
                 <span className={classes.twoWordText} >{filterUserName}</span>
              </Fab>
            </LightTooltip>
            </div>

     
    <Dropdown className="navigationHelpMenu" style={{ "position": "absolute",'bottom':'2px'}}>
        <Dropdown.Toggle  id="navigationHelpMenu"  drop='end'>
            <img src='./Icons/UserHelp.png' style={{ "marginLeft": "7px"}}/>
        </Dropdown.Toggle>

        <Dropdown.Menu>
        <NavLink  to="/settings" onClick={()=>$('.navigationHelpMenu div[aria-labelledby="navigationHelpMenu"]').removeClass('show')} className={[classes.navigationItemsDropdown, classes.highlight].join(" ")}
          exact>
            Settings
        </NavLink>
            <Dropdown.Item href={props.config.SUPPORT_URL} target="_blank" className={classes.navigationItemsDropdown}
            >Support</Dropdown.Item>

          <Link to={{pathname:props.config.VERSION_INFO_URL }} target="_blank" className={[classes.navigationItemsDropdown, classes.highlight].join(" ")} >Version Info</Link>
           
            {/* <Dropdown.Item href="#/action-2" className={classes.navigationItemsDropdown}>
               Privacy Policy</Dropdown.Item> */}
               <hr/>
            <Dropdown.Item href={localStorage.getItem('cmmStatus')=='true'?"/logincmm":"/login"} onClick={props.clicked}
             className={classes.navigationItemsLogin}>
            <img src='./Icons/UserLogout.png' style={{'marginLeft':'15px'}}/>Logout</Dropdown.Item>
        </Dropdown.Menu>
    </Dropdown>
     </ul>       
    </div>
);
}

const mapStateToProps = (state) => {
    return {
      permissions:state.user.permissionList,
    };
  };
  
  const mapDispatchToProps = () => {
    return {
     
    };
  };
export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(navigationItems);
