import React, { Component } from 'react';
import './Drawer.css';
import * as actions from '../../../store/actions/index'
import DrawerComponent from '../../UIComponents/Drawer/DrawerComponent';
import ButtonComponent from '../../UIComponents/Button/ButtonComponent';
import { connect } from 'react-redux';
import withErrorHandler from '../../../hoc/withErrorHandler/withErrorHandler';
import axios from 'axios';
import { PostCalltoServer } from '../../../store/utility';
import ReactSelectComponent from '../../../containers/SettingPage/SettingInnerPages/components/ReactSelectComponent/ReactSelectComponent';

class AddProjectsToVA extends Component {
    constructor(props) {
        super(props);
        this.state = {
            right: true,
            options: [
                {
                    value: '',
                    displayValue: '',
                    description: ""
                },
            ],
            SelectedProject: '',
            SelectedProjectName: '',
            description: ''
        }
    }

    toggleDrawer = (open) => (event) => {
        if (!open) {
            this.props.onHide('addProject');
        }
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            this.props.onHide('addProject');
            return;
        }
        this.setState({
            right: open,
            SelectedProject: '',
            SelectedProjectName: '',
            description: ''
        });
    };

    projectChange = (data) => {
        if (data.value !== '') {
            this.setState({
                SelectedProject: data.value,
                SelectedProjectName: this.props.projectList[data.value].projectName,
                description: this.props.projectList[data.value].description
            })
        } else {
            this.setState({
                SelectedProject: '',
                SelectedProjectName: '',
                description: ''
            })
        }
    }

    addSubmitHandler = () => {
        let projectList = [...this.props.projects, this.state.SelectedProjectName]
        let obj = {
            virtualassistantName: localStorage.getItem("vaName"),
            virutalassistantDescription: this.props.description,
            createdUser: this.props.createdUser,
            modifiedUser: localStorage.getItem("userName").split('@')[0],
            project: projectList
        }
        this.props.onSaveAssistant(this.props.config.BOTBUILDER_URL, obj, "Project added to virtual assistant successfully");
        this.setState({
            SelectedProject: '',
            SelectedProjectName: '',
            description: ''
        })
        this.props.onHide('addProject');
        let checkFolder = { "FolderName": 'Intelli' + localStorage.getItem('vaName') + 'Bot',"orgName": localStorage.getItem('orgName') }
        PostCalltoServer(this.props.config.VIRTUAL_ASSISTANCE_URL + '/virtualAssistant/checkFolderExists', checkFolder).then(response => {
            if (response.data.status == true) {
                let token = { "project": projectList.join(), "FolderName": 'Intelli' + localStorage.getItem('vaName') + 'Bot',"orgName": localStorage.getItem('orgName') }
                PostCalltoServer(this.props.config.VIRTUAL_ASSISTANCE_URL + '/virtualAssistant/updateProjectName', token)
            }
        });
    }

    render() {

        let projectList = [];
        if (this.props.projectList) {
            let newArray = [...new Set(this.props.projectList)]
                projectList = newArray.map((ele, i) => {
            return {
                    value: i,
                    label: ele.projectName
                }
            })
            projectList.unshift({ value: '', label: "Select" })
        }

        return (
            <React.Fragment >
                <DrawerComponent heading="Project Details"
                    showDrawer={this.toggleDrawer(false)}
                    openDrawer={this.props.show}>
                    <div className='drawer add-entity'>
                        <div className='drawer-body mt-2'>
                            <span className="reportHeading">Choose a Project to be used in Virtual Assistant</span>
                            <ReactSelectComponent
                                customClassName="SelectView"
                                value={{ value: this.state.SelectedProjectName, label: this.state.SelectedProjectName }}
                                options={projectList}
                                name="project"
                                onChange={(event) => this.projectChange(event)}
                            >
                            </ReactSelectComponent>
                            <div>
                                {projectList.length <= 1 ? (
                                    <span className="error-css">
                                        All the available projects are already added to this Virtual Assistant.
                                    </span>
                                ) : null}
                </div>
                            <div className='desc-div mt-2'>
                                <label>Description</label>
                                <p>
                                    {this.state.description}
                                </p>

                            </div>
                            <div className="my-3">
                                <ButtonComponent
                                    disabled={'' == this.state.SelectedProjectName}
                                    variant='contained'
                                    label='Add Project to Virtual Assistant'
                                    className='buttonWidth my-2'
                                    clicked={() => this.addSubmitHandler()} />
                            </div>
                        </div>
                    </div>
                </DrawerComponent>
            </React.Fragment>
        );
    }
}
const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSaveAssistant: (APIURL, vaData, msg) => dispatch(actions.CreateAssistant(APIURL, vaData, msg)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(AddProjectsToVA, axios));
