import React, { useEffect, useState, useCallback } from "react";
import { Col,  Row, Table, Spinner } from "react-bootstrap";
import Heading3 from "../components/Typography/Heading3/Heading3";
import { useDispatch, useSelector } from "react-redux";
import ButtonCustom from "../../../../components/UIComponents/Button/ButtonComponent";
import { useHistory } from "react-router-dom";
import TextAreaWithLabelComponent from "../components/TextAreaWithLabelComponent/TextAreaWithLabelComponent";
import ProductAttributesComponent from "../Insight/AdminConfiguration/ProductAttributesComponent/ProductAttributesComponent";
import { isEmpty, isEqual, get } from "lodash";
import { useDropzone } from "react-dropzone";
import "./Tickets.css";
import { SetErrorMessage, SetSuccessMessage, SetWarningMessage } from "../../../../store/actions";
import { GetCalltoServer, PostCalltoServer } from "../../../../store/utility";
import axios from "../../../../instance";
import SelectCustom from '../../../../components/UIComponents/SelectDropdown/SelectComponent'
import AddCollection from "./AddCollection";
import SpinnerPage from "../../../../components/UI/Loader/Loader";
import {decrypt} from '../../../../utility/utility';
const ManualUpload = (config) => {
const dispatch = useDispatch();
const history = useHistory();
const projects = useSelector((state) => state.user.projects);
const [projectValues, setProjectValues] = useState([]);
const [selectedCollectionValues, setSelectedCollectionValues] = useState([]);
const [btnUpload, setBtnUpload] = useState(true);
const [records, setRecords] = useState([]);
const [fileName, setFileName] = useState("");
const [description, setDescription] = useState("");
const [projectList, setProjectList] = useState(projects);
const [collectionNames, setCollectionNames] = useState([]);
const [fileNames, setFileNames] = useState([]);
const [selectedCollection, setSelectedCollection] = useState("");
const [dataLoading, setDataLoading] = useState(false);
const [showDrawer, setShowDrawer] = useState(false);
const [dropedItem, setDropedItem] = useState({});
const [dataUsedFor, setDataUsedFor] = useState("CRM");
const [collectionNameEnabled,setCollectionNameEnabled] = useState(false);
const [dataUsedOptions,setDataUsedOptions] = useState(["Triage","CRM"]);
var fd = new FormData();
let token = localStorage.getItem("accessToken");
// let token = decrypt("newAccessToken");

useEffect(() => {
    getHistory();
}, []);

const addedRecord = (newRecord) => {
    let updatedRecords = records.concat(newRecord);
    setRecords(updatedRecords)
}

const toggleDrawer = (value) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setShowDrawer(value);
  };

  const getHistory = () => {
    GetCalltoServer(config.config.INSIGHTS_API_URL +"/product-intelligent-triage/crmticketdump/get-crm-info")
      .then((response) => {
        if (response.data.status.responseType == "success") {
          setRecords(response.data.data);
        }
      })
      .catch(() => {});
    setProjectList(projects.bots);
  };

  const onDropdownChangeHandler = (selectedItems, name) => {
    if (isEqual(name, "collectionNames")) {
      setSelectedCollection(selectedItems.map((item) => item.value));
      setSelectedCollectionValues(selectedItems.map((item) => item.value));
    }
    else if (isEqual(name,"DataUsedFor")){
        setDataUsedFor(selectedItems.target.value)
            if(selectedItems.target.value == "Triage"){
                setCollectionNameEnabled(true)
            }
            else{
                setCollectionNameEnabled(false)
            }
        
    }
     else {
      setSelectedCollectionValues([]);
      setCollectionNames([]);
      setProjectValues(selectedItems.map((item) => item.value));
      // let selectedPrj = selectedItems.map((item) => item.value);
      // getCollectionByProject(selectedPrj.length > 0 ? selectedPrj[selectedPrj.length-1]: null);
      getCollectionByProject(selectedItems.map((item) => item.value));
    }
  };

  const onRemoveItemHandler = (name) => (selectedItem) => {
    if (isEqual(name, "collectionNames")) {
      const filteredCollections = selectedCollectionValues.filter(
        (item) => item !== selectedItem
      );
      setSelectedCollectionValues(filteredCollections);
    } else {
      const filteredProducts = projectValues.filter(
        (item) => item !== selectedItem
      );
      if(isEqual(filteredProducts.length,0)){
         setCollectionNames([]);
         setSelectedCollection("");
      }
       
      setProjectValues(filteredProducts);
    }
  };

  const getCollectionByProject = (project) => {
    if (!isEqual(project.length, 0)) {
      PostCalltoServer(
        config.config.INSIGHTS_API_URL +
          `/product-intelligent-triage/crmticketdump/get-crm-info-by-project/project`,
        { project }
      )
        .then((response) => {
          if (response.data.status.responseType == "success") {
            setCollectionNames(get(response, "data.data[0].collection", []));
          }
        })
        .catch(() => {});
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    if (!isEmpty(acceptedFiles)) {
      setBtnUpload(false);
      let droppedFiles = [];
      acceptedFiles.forEach((item) => {
          setFileName(item.name);
          droppedFiles.push(item.name);
          setDropedItem(item);
          fd.append("file", item);

      });
      setFileNames(droppedFiles);
    } else {
      dispatch(SetErrorMessage("File Format Not Supported."));
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: ".csv,.xlsx",
    onDrop,
    multiple: false,
  });
  

  const uploadBtn = () => {
    const configHeader = { headers: { Authorization: `Bearer ${token}`} };
    fd.append("file", dropedItem);
    if(projectValues.length > 0){
    setDataLoading(true);
    setTimeout(() => {
    axios
          .post(config.config.INSIGHTS_API_URL+ `/product-intelligent-triage/crmticketdump/upload-ticket/project/:project`, fd, configHeader)
          .then((res) => {
            if (get(res, "data.status.responseType", "") === "success") {
                
                let responseJson =  { "project": projectValues, "source": "CRM", "description": description, "files":fileNames }
          PostCalltoServer(config.config.INSIGHTS_API_URL + `/product-intelligent-triage/crmticketdump/save-crm-info`, responseJson)
            .then((response) => {
              if (get(response, "data.status.responseType", "") === "success") {
                let today = new Date().toUTCString();
                let addedObj = {
                  collection :selectedCollection,
                  files:fileNames,
                  description:description,
                  project:projectValues.toString(),
                  createdAt:today,
                  source:dataUsedFor,
                }
                addedRecord(addedObj);
                dispatch(SetSuccessMessage("Ticket Uploaded successfully"));
                setSelectedCollection("");
                setProjectValues([]);
                setCollectionNames([]);
                setSelectedCollectionValues([]);
                setFileName("");
                setDescription("");
                setDataLoading(false);
                setBtnUpload(true);
              }
            }).catch((err) => {
              console.log(err, err);
            })
            }
          })
          .catch((error) => {
            console.log(error);
          });
        },30000)
      }else{
        dispatch(SetWarningMessage( projectValues.length > 0 ? "Select CollectionName" : "Select Project"));
      }
  };


  const onChangeHandler = (e) => {
       setDescription(e.target.value)
       
  };

  const getOptions = (list) => {
    let options = [];
    list.forEach((option) =>
      options.push({
        label: option,
        value: option,
      })
    );
    return options;
  };



const updateCollectionName = (val) => {
    let createdCollection = [];
    createdCollection.push(val);
    setCollectionNames(createdCollection);
    setSelectedCollectionValues(createdCollection);
    setSelectedCollection(val);
    setShowDrawer(false);
    dispatch(SetSuccessMessage(val + "  - Collection Added successfully"));
}

  return (
    <>
      <Row
        className="tickets-dump"
        style={{
          marginLeft: "0px",
          marginRight: "0px",
          backgroundColor: "white",
        }}
      >
        <Col xs="12" className="row-no-gutters">
          <div className="d-flex mt-3 main-heading">
            <h3>
              <img
                className=""
                src="./Icons/arrow-lft.png"
                onClick={() => history.goBack()}
              />
            </h3>
            <Heading3 text="Manual Upload" className="pageTitleText" />
          </div>
          <div className="mb-2">
            <label>
              Upload the Data For Triage & CRM
            </label>
          </div>
        </Col>
        {dataLoading ? (
          <Row className="vh-100 justify-content-center align-items-center"  style={{marginLeft:"50%"}}>
            <SpinnerPage animation="border" variant="primary" />
          </Row>
        ) : (
        <Col xs="12" className="d-flex">
          <Col xs="4">
            <div
              style={{
                minHeight: "300px",
                backgroundColor: "rgb(225, 221, 221)",
                padding: "1rem",
              }}
            >
            <Row xs="1" style={{width:"50%",marginLeft:"0px",marginBottom:"10px"}}>
            {/* <ProductAttributesComponent
                    options={getOptions((projects.bots).length > 0 ? dataUsedOptions : [])}
                    name={"DataUsedFor"}
                    label={"Data Used"}
                    boldTitle={true}
                    search={false}
                    placeholder={"Select"}
                    className={"mt-1 mb-2"}
                    targetSourceDescription={"source_name"}
                    sourceHelpIcon={false}
                    attributeHelpIcon={false}
                    showValues={true}
                    value={dataUsedFor}
                    onChange={(event) =>onDropdownChangeHandler(event, "DataUsedFor")}
                  /> */}
                  <SelectCustom
                     name="DataUsedFor"
                     label="Data Used For"
                     changed={(event) =>onDropdownChangeHandler(event, "DataUsedFor")}
                     required={true}
                     value={dataUsedFor}
                     options={[
                        // {value:'Triage',displayValue:'Triage'},
                        {value:'CRM',displayValue:'CRM'}
                     ]}
                     />
                </Row>
                <div
                {...getRootProps()}
                className="mb-3"
                style={{ minHeight: "25vh", border: "dashed 2px #484545" }}
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p>Drop the files here ...</p>
                ) : (
                  <div>
                    <p style={{ padding: "4rem", color: "#0000008f" }}>
                      {isEqual(fileName, "") ?  "Drag 'n' drop some files here, or click to select files" : fileName}
                    </p>
                  
                  </div>
                )}
              </div>
              <TextAreaWithLabelComponent
                label="Description"
                name="Description"
                required={false}
                value={description}
                className="text-area"
                placeholder="Enter Text Area Content."
                onChange={(e) => onChangeHandler(e)}
              />
              <Col xs="12" className="d-flex mt-3 p-0">
                <Col xs="5" className="p-0 pr-2">
                  <ProductAttributesComponent
                    options={getOptions((projects.bots).length > 0 ?(projects.bots) : [])}
                    //options={ProjectsOptions}
                    name={"Projects_Associated"}
                    label={"Projects Associated"}
                    boldTitle={true}
                    placeholder={"Select"}
                    className={"mt-1 mb-2"}
                    targetSourceDescription={"source_name"}
                    sourceHelpIcon={false}
                    attributeHelpIcon={false}
                    value={projectValues}
                    search={true}
                    onChange={(event) =>
                      onDropdownChangeHandler(event, "projectValues")
                    }
                    onRemoveItem={onRemoveItemHandler("projectValues")}
                  />
                </Col>
                {collectionNameEnabled == true ? <Col xs="5" className="p-0 pl-3">
                  <ProductAttributesComponent
                    options={getOptions(collectionNames.length > 0 ? collectionNames : [])}
                    name={"collection_Nmae"}
                    label={"collection Name"}
                    boldTitle={true}
                    search={false}
                    placeholder={"Select"}
                    className={"mt-1 mb-2"}
                    targetSourceDescription={"source_name"}
                    sourceHelpIcon={false}
                    attributeHelpIcon={false}
                    //showValues={true}
                    value={selectedCollectionValues}
                    onChange={(event) =>
                      onDropdownChangeHandler(event, "collectionNames")
                    }
                    onRemoveItem={onRemoveItemHandler("collectionNames")}
                  />
                </Col>:null}
                {/* <Col xs="2 mt-5 pl-4">
                  <div className="d-flex">
                    <img className="mr-2" src="./images/MapAdd.svg" onClick={toggleDrawer(true)}/>
                    <img className="" src="./images/DeleteBlack.svg" />
                  </div>
                </Col> */}
              </Col>
              <Col xs="12 mt-4">
                <div align="center">
                  <ButtonCustom
                    variant="contained"
                    label="Upload"
                    className="buttonWidth"
                    disabled={btnUpload}
                    clicked={uploadBtn}
                  />
                </div>
              </Col>
            </div>
          </Col>
          <Col xs="8" className="row-no-gutters">
            <div style={{ backgroundColor: "#fff" }}>
              <Col xs="12">
                <h3>Recent Activity</h3>
              </Col>
              <div className="ticket-dump-table-scroll">
              <Table responsive className="mt-2" style={{width:"100%"}}>
                <thead >
                  <tr style={{ backgroundColor: "rgb(225, 221, 221)"}}>
                    <th key={0}>FILE NAME</th>
                    <th key={1}>DESCRIPTION</th>
                    <th key={2}>PROJECTS ASSOCIATED</th>
                    <th key={3}>COLLECTION NAME </th>
                    <th key={4}>DATA USED FOR</th>
                    <th key={4}>UPDATED DATE</th>
                    <th key={5}></th>
                  </tr>
                </thead>
                <tbody>
                  {records.map((item) => (
                    <tr>fgfdg
                      <td key={item.files}>{item.files}</td>
                      <td key={item.description}>{item.description}</td>
                      <td key={item.project.toString()}>{item.project.toString()}</td>
                      <td key={item.collection}>NA</td>
                      <td key={item.dataUsedFor}>{item.source}</td>
                      <td key={item.createdAt}>{item.createdAt.slice(5, 16)}</td>
                      {/* <td key={5}>...</td> */}
                    </tr>
                  ))}
                </tbody>
              </Table>
              </div>
            </div>
          </Col>
        </Col>) }
      </Row>
      {showDrawer ? (
          <AddCollection
            showDrawer={showDrawer}
            toggleDrawer={toggleDrawer}
            updateCollectionName = {updateCollectionName}
            config={config}
            selectedCollection={selectedCollection}
            collectionNames={collectionNames}
            closeDrawer={() => {
              setShowDrawer(false);
            }}
          />
        ) : null}
    </>
  );
};

export default ManualUpload;
