import React, { useState, useEffect } from "react";
import Drawer from "@material-ui/core/Drawer";
import get from "lodash/get";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import "./SymptomAndRootCause.css";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import symptomDetailsUpdateHandler from "../../../../../../store/actions/adminSettings/symptomDetailsUpdateHandler";
import { isEmpty, isEqual } from "lodash";
import {
  deleteCalltoServer,
  PostCalltoServer,
  UpdateCalltoServer,
} from "../../../../../../store/utility";
import PrimaryButtonWithLoader from "../../../components/PrimaryButtonWithLoader/PrimaryButtonWithLoader";
import SecondaryButtonWithLoader from "../../../components/SecondaryButtonWithLoader/SecondaryButtonWithLoader";
import DrawerHeaderContent from "../../../components/DrawerHeaderContent/DrawerHeaderContent";
import InputWithLabelComponent from "../../../components/InputWithLabelComponent/InputWithLabelComponent";
import symptomDetailsDeleteHandler from "../../../../../../store/actions/adminSettings/symptomDetailsDeleteHandler";
import getProjectDetails from "../../../helpers/getProjectDetails";

const useStyles = makeStyles({
  list: {
    width: 500,
  },
});

const SymptomsDetails = ({
  symptom,
  showDrawer,
  toggleDrawer,
  symtomsheading,
  leadingquestions,
  closeDrawer,
  symtomtitle,
  toastFun,
  id,
  config,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const projectName = getProjectDetails();
  const [saveLoading, setSaveLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [isInvalid, setInValid] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false)
  const [formData, setFormData] = useState({
    symptoms: "",
    systemGeneratedSymptom: "",
    leadingquestion: "",
    product: "",
    yom: "",
    model: "",
  });

  useEffect(() => {
    setFormData({
      symptoms: get(symptom, "symptoms", ""),
      leadingquestion: get(symptom, "leadingquestion", ""),
      product: get(symptom, "product", ""),
      yom: get(symptom, "yom", ""),
      model: get(symptom, "model", ""),
    });
  }, [symptom]);

  const changeHandler = (name) => (event) => {
    if (!isEqual(event.target.value, "")) setInValid(false);
    setFormData((prevData) => ({
      ...prevData,
      [name]: event.target.value,
    }));
  };

  const deleteHandler = () => {
    setDeleteLoading(true);
    const url = `${config.INSIGHTS_API_URL}/product-intelligent-triage/settings/symptomsrootcause`;
    const deletedSymtom = {
      symptoms: formData.symptoms,
      project: projectName
    };
    deleteCalltoServer(url, deletedSymtom)
      .then((response) => {
        if (get(response, "data.status.responseType", "") === "success") {
          dispatch(
            symptomDetailsDeleteHandler({
              formData,
              id,
            })
          );
          setDeleteLoading(false);
          closeDrawer();
          toastFun("symptom deleted Successfully", "success");
        } else {
          setDeleteLoading(false);
        }
      })
      .catch((error) => {
        setDeleteLoading(false);
        toastFun(error, "error");
      });
  };

  const saveHandler = (e) => {
    if (!isEqual(formData.leadingquestion, "")) {
      const newForm = {
        symptoms: formData.symptoms,
        symptom_title: formData.symptom_title,
        relevency: "",
        rootcause: "",
        rootcause_title: "",
        leadingquestion: formData.leadingquestion,
        status: "",
        project: projectName,
      };
      const url = `${config.INSIGHTS_API_URL}/product-intelligent-triage/settings/symptomsrootcause`;
      setSaveLoading(true);
      UpdateCalltoServer(url, newForm)
        .then((response) => {
          setSaveLoading(false);
          if (get(response, "data.status.responseType", "") === "success") {
            dispatch(
              symptomDetailsUpdateHandler({
                formData,
                id,
              })
            );
            setSaveLoading(false);
            closeDrawer();
            toastFun("Symptoms saved Successfully", "success");
          } else {
            setSaveLoading(false);
          }
        })
        .catch((error) => {
          setSaveLoading(false);
          toastFun(error, "error");
        });
    } else {
      setInValid(true);
    }
  };

  const getAttributesData = (data, type) => {
    const selectedSourceFields = get(data, type, []);
    if (isEmpty(selectedSourceFields)) {
      return "--";
    }

    return selectedSourceFields.toString();
  };

  const getSymptomAttributes = (symptom) =>
    Object.entries(symptom).map(([key, value]) => {
      if (key === "leadingquestion") {
        return null;
      }
      if (Array.isArray(value)) {
        return (
          <div className="mt-3">
            <Col md={12}>
              <InputWithLabelComponent
                type="text"
                className="disable-border-colr"
                name={key}
                label={key}
                value={getAttributesData(symptom, key)}
                readOnly={true}
                helpIcon={false}
              />
            </Col>
          </div>
        );
      }
      return null;
    });

  const deleteHandlerConfirmation = () => {
    setDeleteConfirmation(!deleteConfirmation);
  }

  return (
    <React.Fragment>
      {showDrawer ? (
        <Drawer
          anchor={"right"}
          open={showDrawer}
          onClose={toggleDrawer(false)}
          className=""
        >
          <div
            className={clsx(classes.list, classes.fullList)}
            role="presentation"
          >
            <DrawerHeaderContent
              heading={symtomsheading}
              toggleDrawer={toggleDrawer}
            />
            <div className="d-flex flex-column Symtom-Item mb-4 mt-4 ">
              <div className="mt-1">
                <Col md={12}>
                  <InputWithLabelComponent
                    type="text"
                    className="disable-border-colr"
                    name="symptomTitle"
                    label={symtomtitle}
                    value={get(formData, "symptoms", "")}
                    readOnly={true}
                    helpIcon={false}
                  />
                </Col>
              </div>
              <div className="mt-3">
                <Col md={12}>
                  <InputWithLabelComponent
                    type="text"
                    className=""
                    name="leadingQuestion"
                    label={leadingquestions}
                    value={get(formData, "leadingquestion", "")}
                    required={true}
                    onChange={changeHandler("leadingquestion")}
                    isInvalid={isInvalid}
                    error={isInvalid ? "Please Enter Leading Question" : null}
                    helpIcon={false}
                  />
                </Col>
              </div>
              {getSymptomAttributes(symptom)}
            </div>
            { !deleteConfirmation ? <div className="d-flex pt-3 flex-row button-content">
              <SecondaryButtonWithLoader
                className="ml-5"
                size="lg"
                text="delete"
                onClick={deleteHandlerConfirmation}
              />
              <PrimaryButtonWithLoader
                className="ml-4"
                onClick={saveHandler}
                size="lg"
                text="save"
                loading={saveLoading}
              />
            </div> : null }
            { deleteConfirmation ? (<>
              <Row className="ml-5">
              <Form.Label className="text-box-title adding-other-input">{" Do you really want to delete Symptom"}</Form.Label>
              </Row>
            <div className="d-flex pt-3 flex-row button-content">
  
              <SecondaryButtonWithLoader
                className="ml-5"
                size="lg"
                text="No"
                onClick={deleteHandlerConfirmation}
              />
              <PrimaryButtonWithLoader
                className="ml-4"
                onClick={deleteHandler}
                size="lg"
                text="yes"
                loading={deleteLoading}
              />
            </div> </>) : null }
          </div>
        </Drawer>
      ) : null}
    </React.Fragment>
  );
};

SymptomsDetails.propTypes = {
  symtomsheading: PropTypes.string,
  symtomtitle: PropTypes.string,
  systemgeneratedsymtoms: PropTypes.string,
  leadingquestions: PropTypes.string,
  productattribute1: PropTypes.string,
  productattribute2: PropTypes.string,
};

SymptomsDetails.defaultProps = {
  symtomsheading: "Symptoms Details",
  symtomtitle: "Symptom",
  systemgeneratedsymtoms: "System Generated Symptoms",
  leadingquestions: "Leading Question",
  productattribute1: "Product Attribute 1",
  productattribute2: "Product Attribute 2",
};

export default SymptomsDetails;
