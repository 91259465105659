/*

* Copyright © 2023, HCL Technologies Ltd. ALL RIGHTS RESERVED.

* <p>

* This software is the confidential information of HCL Technologies Ltd., and is licensed

* as restricted rights software. The use,reproduction, or disclosure of this software

* is subject to restrictions set forth in your license agreement with HCL.

*/

 

/**

* Data Review.

*

* @author Abhash Vats

*/

import React,{Component} from "react";
import { connect } from 'react-redux';
import withErrorHandler from "../../../../hoc/withErrorHandler/withErrorHandler";
import axios from "../../../../instance";
import SearchComponent from "../../../../components/UIComponents/SearchComponent/Search";
import NavbarCustom from './Navbar';
import TabsComponent from "../../../SettingPage/SettingInnerPages/components/TabsComponent/TabsComponent";
import { encryptAndStore } from "../../../../utility/utility";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import orderBy from 'lodash/orderBy';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { makeStyles,withStyles } from '@material-ui/core';
import { styled, alpha } from '@mui/material/styles';
import FilterListIcon from '@mui/icons-material/FilterList';
import "./dataReview.css";
import * as actions from '../../../../store/actions/index';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import DrawerComponent from '../../../../components/UIComponents/Drawer/DrawerComponent';
import ReactMultiSelectComponent from '../SecheduleMeeting/ReactSelectComponent';
import SelectCustom from "../../../../components/UIComponents/SelectDropdown/SelectComponent";

const clss = withStyles((theme)=>({
  customStyle: {
      '& .MuiTableCell-root': {
         padding:"0px"
      }
  },
}));

const StyledCheckbox = styled(Checkbox)`
  svg {
    color: rgba(0, 0, 0, 0.54);
    font-size: 15px;
  }
`;

const invertDirection = {
    asc:'desc',
    desc:'asc',
}

class DataReview extends Component {
  state={
    selectedProject:null,
    projectname:"Project List",
    searchT:"",
    popperShow:false,
    selectedSourceValue:null,
    sourcename:"SourceList",
    searchT2:"",
    popperShow2:false,
    sourceValues:{bots:['sourceValue1','sourceVvalue2','sourceValue3']},
    selectedVale:null,
    selectedTab:'TRAINING DATA',
    tabItems:[
      {
        label: 'TRAINING DATA',
        value: 'TRAINING DATA'
      },
      {
        label: 'PREDICTION DATA',
        value: 'PREDICTION DATA'
      }
    ],
    dataList:{bots:[{displayValue:"abc",value:"a"},{displayValue:"def",value:"d"},{displayValue:"ghi",value:"g"}]},
    // tableColm:['Customer Feedback','Category','Sentiment','Status','Option1','Option2','Option3','Pridiction Score'],
    tableColm:['clusters','cluster_label','sentiment','status','keywords','prediction_score'],
    selectedColm:['clusters','cluster_label','sentiment','status','keywords','prediction_score'],
    tableData:[],
    //   {customerFeedback:'Lorem Ipsum is simply dummy text of the printing and typesetting industry',category:'General',sentiment:'Positive',status:'Not Reviewed',pridictionScore:'5',checked:false,edit:false,editRow:false},
    //   {customerFeedback:'It has survived not only five centuries',category:'Abc',sentiment:'Negative',status:'Reviewed',pridictionScore:'5',checked:false,edit:false,editRow:false},
    //   {customerFeedback:'It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages',category:'Special',sentiment:'Positive',status:'Not Reviewed',pridictionScore:'5',checked:false,edit:false,editRow:false},
    //   {customerFeedback:'It is a long established fact that a reader will be distracted by the readable content',category:'General',sentiment:'Negative',status:'Reviewed',pridictionScore:'5',checked:false,edit:false,editRow:false},
    //   {customerFeedback:'Contrary to popular belief, Lorem Ipsum is not simply random text',category:'Special',sentiment:'Positive',status:'Reviewed',pridictionScore:'5',checked:false,edit:false,editRow:false},
    //   {customerFeedback:'On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment',category:'Special',sentiment:'Negative',status:'Not Reviewed',pridictionScore:'5',checked:false,edit:false,editRow:false},
    //   {customerFeedback:'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus',category:'General',sentiment:'Negative',status:'Not Reviewed',pridictionScore:'5',checked:false,edit:false,editRow:false},
    //   {customerFeedback:'he rejects pleasures to secure other greater pleasures, or else he endures pains to avoid worse pains',category:'General',sentiment:'Positive',status:'Reviewed',pridictionScore:'5',checked:false,edit:false,editRow:false},
    //   {customerFeedback:'aaLorem Ipsum is simply dummy text of the printing and typesetting industry',category:'General',sentiment:'Positive',status:'Not Reviewed',pridictionScore:'5',checked:false,edit:false,editRow:false},
    //   {customerFeedback:'abIt has survived not only five centuries',category:'Abc',sentiment:'Negative',status:'Reviewed',pridictionScore:'5',checked:false,edit:false,editRow:false},
    //   {customerFeedback:'baIt was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages',category:'Special',sentiment:'Positive',status:'Not Reviewed',pridictionScore:'5',checked:false,edit:false,editRow:false},
    //   {customerFeedback:'AbIt is a long established fact that a reader will be distracted by the readable content',category:'General',sentiment:'Negative',status:'Reviewed',pridictionScore:'5',checked:false,edit:false,editRow:false},
    //   {customerFeedback:'ABContrary to popular belief, Lorem Ipsum is not simply random text',category:'Special',sentiment:'Positive',status:'Reviewed',pridictionScore:'5',checked:false,edit:false,editRow:false},
    //   {customerFeedback:'bbOn the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment',category:'Special',sentiment:'Negative',status:'Not Reviewed',pridictionScore:'5',checked:false,edit:false,editRow:false},
    //   {customerFeedback:'BBNam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus',category:'General',sentiment:'Negative',status:'Not Reviewed',pridictionScore:'5',checked:false,edit:false,editRow:false},
    // ],
    selected_column:[],
    //attributesdata:[],
    count:0,
    fullTableData:[],
    sortedColm:'cluster_label',
    numSelected:0,
    rowCount:0,
    sortDirection:'desc',
    allRows:[],
    columnToSort:'',
    searchValue:'',
    show:true,
    menuShow1:null,
    all1:true,
    //categoryCheck:[],
    // special:false,
    // general:false,
    menuShow2:null,
    all2:true,
    // positive:false,
    // negative:false,
    menuShow3:null,
    all3:true,
    // reviewed:false,
    // notReviewed:false,
    //editRow:false,
    editedCategory:null,
    editedSentiment:null,
    openFilterDrawer:false,
    categoryInDrawer:'All',
    sentimentInDrawer:'All',
    statusInDrawer:'All',
    openAllFilterDrawer:false,
    allCategoryInDrawer:'',
    allSentimentInDrawer:'',
    categoriesList:[],
    sentimentList:[],
    statusList:[],
  }

  componentDidMount() {
    this.setState({
      selectedColm:this.state.tableColm,
      //fullTableData:this.state.tableColm,
      // all1:true,all2:true,all3:true,
      count:this.state.tableData.length
    })
    let url = this.props.config.COMMON_API_URL + '/utilityservice/settings/fields/customerclust';
    this.props.onGetSourceAttributes(url);
    url = this.props.config.BOTBUILDER_URL + '/unsupervised-data-review/records/collection/customerclust' + '/project/' + localStorage.getItem('projectname');
    this.props.onGetDataReviewData(url);
    url = this.props.config.BOTBUILDER_URL + '/unsupervised-data-review/collection/customerclust' + '/project/' + localStorage.getItem('projectname');
    this.props.onGetSentimentAndCategoryAndStatus(url);
  }

  prepareTableData(){
    var temp = this.props.dataReviewData
    var temp2 =[]
    for(var i=0;i<temp.length;i++){
      temp[i].checked=false
      temp[i].edit=false
      temp[i].editRow=false
      temp2.push(temp[i])
      if(i==100)break
    }
    var col1 = Object.keys(temp[0])
    var col2 = []
    col1.find(element => element.includes('clusters')) && col2.push('clusters')
    col1.find(element => element.includes('cluster_label')) && col2.push('cluster_label')
    col1.find(element => element.includes('sentiment')) && col2.push('sentiment')
    col1.find(element => element.includes('status')) && col2.push('status')
    col1.find(element => element.includes('keywords')) && col2.push('keywords')
    col1.find(element => element.includes('number')) && col2.push('number')
    col1.find(element => element.includes('u_customer_response')) && col2.push('u_customer_response')
    col1.find(element => element.includes('u_customer_satisfaction')) && col2.push('u_customer_satisfaction')
    col1.find(element => element.includes('u_primary_reason_for_rating')) && col2.push('u_primary_reason_for_rating')
    col1.find(element => element.includes('project')) && col2.push('project')
    col1.find(element => element.includes('Label_Predicted')) && col2.push('Label_Predicted')
    col1.find(element => element.includes('marketing_division')) && col2.push('marketing_division')
    col1.find(element => element.includes('product_name')) && col2.push('product_name')
    col1.find(element => element.includes('nps_group_name')) && col2.push('nps_group_name')
    col1.find(element => element.includes('account_name')) && col2.push('account_name')
    col1.find(element => element.includes('u_crm_product_family')) && col2.push('u_crm_product_family')
    col1.find(element => element.includes('u_geography_name')) && col2.push('u_geography_name')
    col1.find(element => element.includes('customer_comment')) && col2.push('customer_comment')
    col1.find(element => element.includes('u_product_pillar')) && col2.push('u_product_pillar')
    col1.find(element => element.includes('u_secondary_reason_for_rating')) && col2.push('u_secondary_reason_for_rating')
    col1.find(element => element.includes('prediction_score')) && col2.push('prediction_score')
    this.setState({tableData:temp2, fullTableData:temp2,tableColm:col2})
  }

  prepareFilterData(){
    var temp1 =[]
    for(var i=0;i<this.props.allEditableList.categories.length;i++){
      var t ={"category":this.props.allEditableList.categories[i],"check":false}
      temp1.push(t)
    }
    this.setState({categoriesList:temp1})
    var temp2 =[]
    for(var i=0;i<this.props.allEditableList.sentiments.length;i++){
      var t ={"sentiment":this.props.allEditableList.sentiments[i],"check":false}
      temp2.push(t)
    }
    this.setState({sentimentList:temp2})
    var temp3 =[]
    for(var i=0;i<this.props.allEditableList.statusSet.length;i++){
      var t ={"status":this.props.allEditableList.statusSet[i],"check":false}
      temp3.push(t)
    }
    this.setState({statusList:temp3})
  }

  componentDidUpdate(prevProps){
    if(this.props.dataReviewData && this.props.dataReviewData != prevProps.dataReviewData && this.props.dataReviewData.length>0){
      this.prepareTableData()
    }
    if(this.props.allEditableList && this.props.allEditableList != prevProps.allEditableList){
      this.setState({categoriesList:this.props.allEditableList.categories,sentimentList:this.props.allEditableList.sentiments,statusList:this.props.allEditableList.statusSet})
      this.setState({allCategoryInDrawer:this.props.allEditableList.categories[0],allSentimentInDrawer:this.props.allEditableList.sentiments[0]})
      this.prepareFilterData()
    }
  }

  setSearchTerm = (item) => {
    this.setState({searchT:item})
  }

  popperShowFn=()=>{
    this.setState({
      popperShow:true,
    })
  }

  projectclick =(event,projectname)=>{
    this.setState({searchT:"",popperShow:false})
    let savedProjectName=localStorage.getItem('projectname');
    if(savedProjectName!==projectname){
    encryptAndStore('projectname', projectname );
    this.setState({projectname:projectname});
    }
    this.props.onchangeProjectAction(projectname);       
  }
     
  selectProjectVal = (event) => {
    this.setState({
      selectedProject: event.target.value
    })
  }

  setSearchTerm2 = (item) => {
    this.setState({searchT2:item})
  }

  popperShowFn2=()=>{
    this.setState({
      popperShow2:true,
    })
  }

  sourceValueclick =(event,sourcename)=>{
    this.setState({searchT2:"",popperShow2:false})
    this.setState({sourcename:sourcename});       
    }

    selectSourceVal = (event) => {
      this.setState({
          selectedSourceValue: event.target.value
      })
    }


  addCheckboxValue = () =>{
    if(this.state.tableData.length>0){
        var temp = [...this.state.tableData]
        for( var i=0;i<this.state.tableData.length;i++){
            temp[i].checked = false
        }
        this.setState({tableData:temp})
    }
  }

  ClearSearchValue = ()=>{
    this.setState({searchValue:""})
  }

  onQuickFilterChanged = (event) => {
      this.setState({searchValue:event.target.value})
          if(event.target.value!="" ){
            this.setState({show:false})
          }
          else{
              this.setState({show:true})
          }
  };

  onSelectAllClick = (event) =>{
    var check = [...this.state.tableData]
    if(event.target.checked == true){
        for(var i=0;i<check.length;i++){
            check[i].checked=true
        }
        this.setState({numSelected:check.length,rowCount:check.length,allRows:this.state.tableData})
    }
    else{         
        for(var i=0;i<check.length;i++){
            check[i].checked=false
        }
        this.setState({numSelected:0,tableData:check})
    }
  }

onSelectClick = (event,item,index) =>{
    let i = this.state.numSelected
    var check = [...this.state.tableData]
    var row = item
    if(event.target.checked == true){
        i++
        check.map((item,i)=>{
            if(row == item){
                row.checked = true
                check[i]=row
            }
        })
    }
    else{
        i--
        check.map((item,i)=>{
            if(row == item){
                row.checked = false
                check[i]=row
            }
        })
    }
    this.setState({numSelected:i,rowCount:check.length,tableData:check})
  }
  
  handleSort = (columnName) =>{
    if(columnName == 'cluster_label'){
        this.setState({sortedColm:'cluster_label'})
    }
    else if(columnName == 'sentiment'){
        this.setState({sortedColm:'sentiment'})
    }
    else if(columnName == 'status'){
        this.setState({sortedColm:'status'})
    }
    else if(columnName == 'prediction_score'){
      this.setState({sortedColm:'prediction_score'})
    }
    else if(columnName == 'u_customer_response'){
      this.setState({sortedColm:'u_customer_response'})
    }
    else if(columnName == 'u_customer_satisfaction'){
      this.setState({sortedColm:'u_customer_satisfaction'})
    }
    else if(columnName == 'u_primary_reason_for_rating'){
      this.setState({sortedColm:'u_primary_reason_for_rating'})
    }
    else if(columnName == 'nps_group_name'){
      this.setState({sortedColm:'nps_group_name'})
    }
    else if(columnName == 'number'){
      this.setState({sortedColm:'number'})
    }
    else if(columnName == 'project'){
      this.setState({sortedColm:'project'})
    }
    else if(columnName == 'Label_Predicted'){
      this.setState({sortedColm:'Label_Predicted'})
    }
    else if(columnName == 'marketing_division'){
      this.setState({sortedColm:'marketing_division'})
    }
    else if(columnName == 'product_name'){
      this.setState({sortedColm:'product_name'})
    }
    else if(columnName == 'account_name'){
      this.setState({sortedColm:'account_name'})
    }
    else if(columnName == 'clusters'){
      this.setState({sortedColm:'clusters'})
    }
    else if(columnName == 'u_crm_product_family'){
      this.setState({sortedColm:'u_crm_product_family'})
    }
    else if(columnName == 'u_geography_name'){
      this.setState({sortedColm:'u_geography_name'})
    }
    else if(columnName == 'customer_comment'){
      this.setState({sortedColm:'customer_comment'})
    }
    else if(columnName == 'u_product_pillar'){
      this.setState({sortedColm:'u_product_pillar'})
    }
    else if(columnName == 'u_secondary_reason_for_rating'){
      this.setState({sortedColm:'u_secondary_reason_for_rating'})
    }
    this.setState({columnToSort:columnName})
    if(this.state.columnToSort == columnName)
        this.setState({sortDirection:invertDirection[this.state.sortDirection]})
    else
        this.setState({sortDirection:'asc'})
  }

  handleClickMenu = (event, headerName) => {
    if(headerName == "cluster_label")
      this.setState({menuShow1:event.currentTarget})
    else if(headerName == "sentiment")
      this.setState({menuShow2:event.currentTarget})
    else if(headerName == "status")
      this.setState({menuShow3:event.currentTarget})
    
  };

  handleCloseMenu1 = () => {
    this.setState({menuShow1:null,menuShow2:null,menuShow3:null})
  };

  categoryFilter = (event,index) =>{
    var temp = [...this.state.categoriesList]
    temp[index].check=Boolean(event.target.checked)
    this.setState({categoriesList:temp,all1:false})
  }

  sentimentFilter = (event,index) =>{
    var temp = [...this.state.sentimentList]
    temp[index].check=Boolean(event.target.checked)
    this.setState({sentimentList:temp,all2:false})
  }

  statusFilter = (event,index) =>{
    var temp = [...this.state.statusList]
    temp[index].check=Boolean(event.target.checked)
    this.setState({statusList:temp,all3:false})
  }

  categoryFilterOnAll = (event) => {
    this.setState({all1:Boolean(event.target.checked)}, function stateUpdateComplete() {
      var temp = [...this.state.categoriesList]
      for(var i=0;i<temp.length;i++){
        temp[i].check = false
      }
      this.setState({categoriesList:temp})
    }.bind(this))
  }

  sentimentFilterOnAll = (event) => {
    this.setState({all2:Boolean(event.target.checked)}, function stateUpdateComplete() {
      var temp = [...this.state.sentimentList]
      for(var i=0;i<temp.length;i++){
        temp[i].check = false
      }
      this.setState({sentimentList:temp})
    }.bind(this))
  }

  statusFilterOnAll = (event) => {
    this.setState({all3:Boolean(event.target.checked)}, function stateUpdateComplete() {
      var temp = [...this.state.statusList]
      for(var i=0;i<temp.length;i++){
        temp[i].check = false
      }
      this.setState({statusList:temp})
    }.bind(this))
  }

  openFilterDrawer = () =>{
    this.setState({openFilterDrawer:true});
  }

  closeFilterDrawer = () =>{
    this.setState({openFilterDrawer:false,openAllFilterDrawer:false});
  }

  openAllFilterDrawer = () =>{
    this.setState({openAllFilterDrawer:true});
  }

  tableFilter = () =>{
    this.prepareTableData()
    var temp = []
    var categoriesList = []
    var sentimentList = []
    var statusList = []
    var temp1 = []
    var temp2 = []
    var temp3 = []
    
    if(this.state.all1 == false){
      for(var i=0;i<this.state.categoriesList.length;i++){
        if(this.state.categoriesList[i].check == true)
          categoriesList.push(this.state.categoriesList[i].category)
      }
      
      for(var i=0;i<this.state.fullTableData.length;i++){
  
        for(var a = 0; a < categoriesList.length; a++){
          if(this.state.fullTableData[i].cluster_label == categoriesList[a]){
            temp1.push(this.state.fullTableData[i])
          }
        }
      }
    }
    else{
      temp1=[...this.state.fullTableData]
    }
    

    if(this.state.all2 == false){
      for(var i=0;i<this.state.sentimentList.length;i++){
        if(this.state.sentimentList[i].check == true)
          sentimentList.push(this.state.sentimentList[i].sentiment)
      }
      if(temp1.length>0){
  
        for(var i = 0; i < sentimentList.length; i++){
          for(var j =0; j<temp1.length;j++){
            if(temp1[j].sentiment == sentimentList[i]){
              temp2.push(temp1[j])
            }
          }
        }
      }
    }
    else{
      temp2=temp1
    }
    

    if(this.state.all3 == false){
      for(var i=0;i<this.state.statusList.length;i++){
        if(this.state.statusList.check == true)
          statusList.push(this.state.statusList.status)
      }
  
      if(temp2.length>0){
  
        for(var i = 0; i < statusList.length; i++){
          for(var j =0; j<temp2.length;j++){
            if(temp2[j].status == statusList[i]){
              temp3.push(temp2[j])
            }
          }
        }
      }
    }
    else{
      temp3 = temp2
    }
    
    this.setState({tableData:temp3})

    // if(this.state.all1 == true){
    //   if(this.state.fullTableData.length>0){
    //     temp = this.state.fullTableData
    //   }
    // }
    // if(this.state.general == true){
    //   if(this.state.fullTableData.length>0){
    //     for(var i=0;i<this.state.fullTableData.length;i++){
    //       if(this.state.fullTableData[i].category == "General"){
    //         temp.push(this.state.fullTableData[i])
    //       }
    //     }
    //   }
    // }
    // if(this.state.special == true){
    //   if(this.state.fullTableData.length>0){
    //     for(var i=0;i<this.state.fullTableData.length;i++){
    //       if(this.state.fullTableData[i].category == "Special"){
    //         temp.push(this.state.fullTableData[i])
    //       }
    //     }
    //   }
    // }
    // if(this.state.special != true && this.state.general != true && this.state.all1 != true){
    //   if(this.state.fullTableData.length>0){
    //     for(var i=0;i<this.state.fullTableData.length;i++){
    //       if(this.state.fullTableData[i].category != "Special" && this.state.fullTableData[i].category != "General"){
    //         temp.push(this.state.fullTableData[i])
    //       }
    //     }
    //   }
    // }
    // var temp2 = []
    // if(this.state.all2 == true){
    //   if(temp.length>0){
    //     temp2 = temp
    //   }
    // }
    // if(this.state.positive == true){
    //   if(temp.length>0){
    //     for(var i=0;i<temp.length;i++){
    //       if(temp[i].sentiment == "Positive"){
    //         temp2.push(temp[i])
    //       }
    //     }
    //   }
    // }
    // if(this.state.negative == true){
    //   if(temp.length>0){
    //     for(var i=0;i<temp.length;i++){
    //       if(temp[i].sentiment == "Negative"){
    //         temp2.push(temp[i])
    //       }
    //     }
    //   }
    // }
    // if(this.state.positive != true && this.state.negative != true && this.state.all2 != true){
    //   if(temp.length>0){
    //     for(var i=0;i<temp.length;i++){
    //       if(temp[i].sentiment != "Positive" && temp[i].sentiment != "Negative"){
    //         temp2.push(temp[i])
    //       }
    //     }
    //   }
    // }
    // var temp3 = []
    // if(this.state.all3 == true){
    //   if(temp2.length>0){
    //     temp3 = temp2
    //   }
    // }
    // if(this.state.reviewed == true){
    //   if(temp2.length>0){
    //     for(var i=0;i<temp2.length;i++){
    //       if(temp2[i].status == "Reviewed"){
    //         temp3.push(temp2[i])
    //       }
    //     }
    //   }
    // }
    // if(this.state.notReviewed == true){
    //   if(temp2.length>0){
    //     for(var i=0;i<temp2.length;i++){
    //       if(temp2[i].status == "Not Reviewed"){
    //         temp3.push(temp2[i])
    //       }
    //     }
    //   }
    // }
    // if(this.state.reviewed != true && this.state.notReviewed != true && this.state.all3 != true){
    //   if(temp2.length>0){
    //     for(var i=0;i<temp2.length;i++){
    //       if(temp2[i].status != "Reviewed" && temp2[i].status != "Not Reviewed"){
    //         temp3.push(temp2[i])
    //       }
    //     }
    //   }
    // }
    // this.setState({tableData:temp3})
  }

  clickedModeEditIcon = (item) =>{
    if(item.edit == false){
      var temp = [...this.state.tableData]
      for(var i =0; i<temp.length;i++){
        if(temp[i] == item)
          temp[i].edit = true
        else
        temp[i].edit = false
      }
      this.setState({tableData:temp,editedCategory:item.cluster_label,editedSentiment:item.sentiment})
    }
  }

  editRow = (item,index) =>{
    if(item.editRow == false){
      var temp = [...this.state.tableData]
      for(var i =0; i<temp.length;i++){
        if(temp[i] == item)
          temp[i].editRow = true
        else
        temp[i].editRow = false
      }
      this.setState({tableData:temp})
    }
  }

  handleChange = (event) => {
    this.setState({editedCategory:event.target.value})
  };

  handleChange2 = (event) =>{
    this.setState({editedSentiment:event.target.value})
  }

  callAllUpdateApi = () =>{
    var temp = [...this.state.tableData]
    var dataPayload = []
    for(var i=0; i<temp.length; i++){
      if(temp[i].checked == true){
        var t = {
          "id": temp[i].id,
          "cluster_label": this.state.allCategoryInDrawer,
          "sentiment": this.state.allSentimentInDrawer
        }
        dataPayload.push(t)
      } 
    }
    var url = this.props.config.BOTBUILDER_URL + '/unsupervised-data-review';
    var payload = {
                    "project": localStorage.getItem('projectname'),
                    "collection": "customerclust_bk",
                    "data": dataPayload
                }
    this.props.onUpdateDataReview(url,payload);
    setTimeout(() => {
      url = this.props.config.BOTBUILDER_URL + '/unsupervised-data-review/records/collection/customerclust' + '/project/' + localStorage.getItem('projectname');
      this.props.onGetDataReviewData(url);
    }, 3000);
    this.closeFilterDrawer()
  }

  callUpdateApi = (item) =>{
    var url = this.props.config.BOTBUILDER_URL + '/unsupervised-data-review';
    var payload = {
                    "project": localStorage.getItem('projectname'),
                    "collection": "customerclust_bk",
                    "data": [
                        {
                            "id": item.id,
                            "cluster_label": this.state.editedCategory,
                            "sentiment": this.state.editedSentiment
                        }
                    ]
                }
    this.props.onUpdateDataReview(url,payload);
    setTimeout(() => {
      url = this.props.config.BOTBUILDER_URL + '/unsupervised-data-review/records/collection/customerclust' + '/project/' + localStorage.getItem('projectname');
      this.props.onGetDataReviewData(url);
    }, 2000);
  } 

  cancelUpdateApi = (item) =>{
    var temp = [...this.state.tableData]
    for(var i =0; i<temp.length;i++){
      if(temp[i] == item){
        temp[i].edit = false
        temp[i].editRow = false
      }
    }
    this.setState({tableData:temp})
  }
  
  callDeleteApi = (item) =>{
    var url = this.props.config.BOTBUILDER_URL + '/unsupervised-data-review';
    var payload = {
      "project": localStorage.getItem('projectname'),
      "collection": "customerclust_bk",
      "data": [
                item.id
              ]
          }
    this.props.onDeleteDataReview(url,payload)
    setTimeout(() => {
      url = this.props.config.BOTBUILDER_URL + '/unsupervised-data-review/records/collection/customerclust' + '/project/' + localStorage.getItem('projectname');
      this.props.onGetDataReviewData(url);
    }, 2000);
  }

  onChangeColmHeader = (newValue) =>{
    var temp = []
    for(var i=0;i<newValue.length;i++){ 
            temp.push(newValue[i].value)
    }
    this.setState({
      selected_column:this.state.selectedColm,
      selectedColm:temp})
  }

  onRemoveColmHeader = (item) =>{
    var temp = [...this.state.selectedColm]
    var index = temp.indexOf(item)
    temp.splice(index,1)
    this.setState({selectedColm:temp})
  }

  changeCategoryInDrawer = (e) =>{
    this.setState({categoryInDrawer:e.target.value})
  }

  changeSentimentInDrawer = (e) =>{
    this.setState({sentimentInDrawer:e.target.value})
  }

  changeStatusInDrawer = (e) =>{
    this.setState({statusInDrawer:e.target.value})
  }

  changeAllCategoryInDrawer = (e) =>{
    var temp = this.state.tableData
    for(var i=0;i<this.state.tableData.length;i++){
      if(temp[i].checked == true){
        temp[i].category = e.target.value
      }
    }
    this.setState({allCategoryInDrawer:e.target.value,tableData:temp})
  }

  changeAllSentimentInDrawer = (e) =>{
    var temp = this.state.tableData
    for(var i=0;i<this.state.tableData.length;i++){
      if(temp[i].checked == true){
        temp[i].sentiment = e.target.value
      }
    }
    this.setState({allSentimentInDrawer:e.target.value,tableData:temp})
  }

  render()
  {   
      const ITEM_HEIGHT = 48;
      // let header = 
      //   <div className="headerParent">
      //     <div className="headerSivling1">
      //       <span className="headerText" style={{marginLeft:"20px"}}>Data Review</span>
      //     </div>
      //     <div className="headerSivling2">
      //       <div style={{paddingRight:"100px"}}>
      //         <NavbarCustom 
      //                 className="projectNameForDashboard"
      //                 dataList={this.props.projectlist}
      //                 addImportProjectIcon={false}
      //                 setSearchTerm={(item)=>this.setSearchTerm(item)}
      //                 popperShow={this.state.popperShow}
      //                 popperShowFn={()=>this.popperShowFn()}
      //                 searchTerm={this.state.searchT}
      //                 projectclick={(event,item)=>this.projectclick(event,item)}
      //                 projectname={this.state.projectname} 
      //                 rightPopUp={false} 
      //                 sourceValue={false}
      //                 commanProjectNotShow={false}>
      //           </NavbarCustom>
      //       </div>
      //       <div className="vl"></div>
      //       <div >
      //         <NavbarCustom 
      //                 className="projectNameForDashboard"
      //                 dataList={this.state.sourceValues}
      //                 addImportProjectIcon={false}
      //                 setSearchTerm={(item)=>this.setSearchTerm2(item)}
      //                 popperShow={this.state.popperShow2}
      //                 popperShowFn={()=>this.popperShowFn2()}
      //                 searchTerm={this.state.searchT2}
      //                 projectclick={(event,item)=>this.sourceValueclick(event,item)}
      //                 projectname={this.state.sourcename} 
      //                 rightPopUp={false} 
      //                 sourceValue={true}
      //                 boldViewForProjectName={true}
      //                 commanProjectNotShow={true}>
      //           </NavbarCustom>
      //       </div>
      //     </div>
      //   </div>
        let tabHeader = 
        <div style={{marginTop:"10px",display:"flex",height:"80px",marginBottom:"10px"}}>
            <span className="countText"><span style={{color:"#016BB5", paddingLeft:"15px"}}>{this.state.fullTableData.length}&nbsp;</span>Records are available for review!</span>
            <div style={{position:"absolute",right:"0px",width:"35%"}}>
                <div style={{padding:"10px",float:"right",right:"30px",position:"relative"}}>
                    <button className={this.state.numSelected > 0 ? "submitEditBtn":"disableEditBtn"} style={{cursor:this.state.numSelected > 0 ?"pointer":"not-allowed"}} onClick={this.state.numSelected > 0 ?()=>this.openAllFilterDrawer():''}>
                      <span className="btnTxt">EDIT</span>
                    </button>
                    <button className="submitEditBtn">
                      <span className="btnTxt">SUBMIT</span>
                    </button>
                    <span className='filterIconUnsupervisecML'><img onClick={()=>this.openFilterDrawer()} src='./images/Filter-icon.png' style={{cursor:"pointer"}}></img></span>
                </div>
                <div style={{paddingRight:"30px"}}>
                    <SearchComponent className="searchIcon" class={"input-group searchClass"} Clear={()=>this.ClearSearchValue()} show={this.state.show} 
                      value={this.state.searchValue} onSearch={(event) => this.onQuickFilterChanged(event)}/>
                </div>
            </div>
        </div>
        let trainigData = this.state.selectedTab =='TRAINING DATA' &&
            <div className="trainigDataHeadDiv"> 
                {tabHeader}
                <TableContainer className="tableContainer">
                  <Table aria-label="simple table" stickyHeader>
                    <TableHead className=''>
                      <TableRow>
                        <TableCell margin="checkbox" className='tableHeaderCheckbox' style={{padding:"0px",backgroundColor:"#f8f9fa",color:"black"}}>
                          <Checkbox color="default" className="checkbox" key="headCheckbox"
                            indeterminate={this.state.numSelected > 0 && this.state.numSelected < this.state.rowCount}
                            checked={this.state.rowCount > 0 && this.state.numSelected === this.state.rowCount}
                            onClick={(event)=>this.onSelectAllClick(event)}
                          />
                        </TableCell>
                        {this.state.tableColm != undefined && this.state.tableColm !== null ? this.state.tableColm.map((headerName) => {
                          return (
                            this.state.selectedColm.find(element => element.includes(headerName)) && 
                              <TableCell className='dataReviewTableHeader' key={headerName} style={{backgroundColor:"#f8f9fa",color:"black"}}>
                                {(headerName == 'cluster_label'||headerName=='sentiment'||headerName=='status')&&<FilterListIcon id="categoryFilter" className="filterListIcon" onClick={(e)=>this.handleClickMenu(e,headerName)}/>}
                                &nbsp;{headerName} {headerName != "" ? this.state.sortDirection === 'asc' ? <ArrowUpwardIcon className="arrow" onClick={() => this.handleSort(headerName)}/> : <ArrowDownwardIcon className="arrow" onClick={() => this.handleSort(headerName)}/>:''}
                              </TableCell>                
                          )
                        }) : null}
                      </TableRow>
                    </TableHead>
                    
                    {this.state.tableData && this.state.tableData.length>0 && 
                    <TableBody>
                      {this.state.tableData != undefined && this.state.tableData !== null ?
                        ('cluster_label' == this.state.sortedColm ? orderBy(this.state.tableData, [(user) =>user.cluster_label.toLowerCase().trim()], this.state.sortDirection) :
                        'sentiment' == this.state.sortedColm ? orderBy(this.state.tableData, [(user) => user.sentiment.toLowerCase()], this.state.sortDirection):
                        'status' == this.state.sortedColm ? orderBy(this.state.tableData, [(user) => user.status.toLowerCase()], this.state.sortDirection):
                        'prediction_score' == this.state.sortedColm ? orderBy(this.state.tableData, [(user) => user.prediction_score], this.state.sortDirection):
                        'u_customer_response' == this.state.sortedColm ? orderBy(this.state.tableData, [(user) => user.u_customer_response.toLowerCase()], this.state.sortDirection):
                        'u_customer_satisfaction' == this.state.sortedColm ? orderBy(this.state.tableData, [(user) => user.u_customer_satisfaction], this.state.sortDirection):
                        'u_primary_reason_for_rating' == this.state.sortedColm ? orderBy(this.state.tableData, [(user) => user.u_primary_reason_for_rating.toLowerCase()], this.state.sortDirection):
                        'nps_group_name' == this.state.sortedColm ? orderBy(this.state.tableData, [(user) => user.nps_group_name.toLowerCase()], this.state.sortDirection):
                        'number' == this.state.sortedColm ? orderBy(this.state.tableData, [(user) => user.number], this.state.sortDirection):
                        'project' == this.state.sortedColm ? orderBy(this.state.tableData, [(user) => user.project.toLowerCase()], this.state.sortDirection):
                        'Label_Predicted' == this.state.sortedColm ? orderBy(this.state.tableData, [(user) => user.Label_Predicted.toLowerCase()], this.state.sortDirection):
                        'marketing_division' == this.state.sortedColm ? orderBy(this.state.tableData, [(user) => user.marketing_division.toLowerCase()], this.state.sortDirection):
                        'product_name' == this.state.sortedColm ? orderBy(this.state.tableData, [(user) => user.product_name.toLowerCase()], this.state.sortDirection):
                        'account_name' == this.state.sortedColm ? orderBy(this.state.tableData, [(user) => user.account_name.toLowerCase()], this.state.sortDirection):
                        'clusters' == this.state.sortedColm ? orderBy(this.state.tableData, [(user) => user.clusters], this.state.sortDirection):
                        'u_crm_product_family' == this.state.sortedColm ? orderBy(this.state.tableData, [(user) => user.u_crm_product_family.toLowerCase()], this.state.sortDirection):
                        'u_geography_name' == this.state.sortedColm ? orderBy(this.state.tableData, [(user) => user.u_geography_name.toLowerCase()], this.state.sortDirection):
                        'customer_comment' == this.state.sortedColm ? orderBy(this.state.tableData, [(user) => user.customer_comment.toLowerCase()], this.state.sortDirection):
                        'u_product_pillar' == this.state.sortedColm ? orderBy(this.state.tableData, [(user) => user.u_product_pillar.toLowerCase()], this.state.sortDirection):
                        'u_secondary_reason_for_rating' == this.state.sortedColm ? orderBy(this.state.tableData, [(user) => user.u_secondary_reason_for_rating.toLowerCase()], this.state.sortDirection):
                        ''
                        ).filter((item)=>{
                            return true
                        }).filter((value) =>{
                            return (value.cluster_label != null && value.cluster_label.toLowerCase().includes(this.state.searchValue.toLowerCase())) ||
                                (value.sentiment != null && value.sentiment.toLowerCase().includes(this.state.searchValue.toLowerCase())) ||
                                (value.status != null && value.status.toLowerCase().includes(this.state.searchValue.toLowerCase()))||
                                (value.prediction_score != null && value.prediction_score==this.state.searchValue) ||
                                (value.u_customer_response != null && value.u_customer_response.includes(this.state.searchValue.toLowerCase())) ||
                                (value.u_customer_satisfaction != null && value.u_customer_satisfaction==this.state.searchValue) ||
                                (value.u_primary_reason_for_rating != null && value.u_primary_reason_for_rating.includes(this.state.searchValue.toLowerCase())) ||
                                (value.nps_group_name != null && value.nps_group_name.includes(this.state.searchValue.toLowerCase())) ||
                                (value.number != null && value.number.includes(this.state.searchValue)) ||
                                (value.project != null && value.project.includes(this.state.searchValue.toLowerCase())) ||
                                (value.Label_Predicted != null && value.Label_Predicted.includes(this.state.searchValue.toLowerCase())) ||
                                (value.marketing_division != null && value.marketing_division.includes(this.state.searchValue.toLowerCase())) ||
                                (value.product_name != null && value.product_name.includes(this.state.searchValue.toLowerCase())) ||
                                (value.account_name != null && value.account_name.includes(this.state.searchValue.toLowerCase())) ||
                                (value.clusters != null && value.clusters==this.state.searchValue) ||
                                (value.u_crm_product_family != null && value.u_crm_product_family.includes(this.state.searchValue.toLowerCase())) ||
                                (value.u_geography_name != null && value.u_geography_name.includes(this.state.searchValue.toLowerCase())) ||
                                (value.customer_comment != null && value.customer_comment.includes(this.state.searchValue.toLowerCase())) ||
                                (value.u_product_pillar != null && value.u_product_pillar.includes(this.state.searchValue.toLowerCase())) ||
                                (value.u_secondary_reason_for_rating != null && value.u_secondary_reason_for_rating.includes(this.state.searchValue.toLowerCase())) ||
                                (value.keywords != null && value.keywords.includes(this.state.searchValue.toLowerCase())) 
                        }).map((item,index) => {
                            return (
                              <>
                                <TableRow key={item.id} style={{}}>
                                    <TableCell margin="checkbox" style={{backgroundColor:item.checked == true || item.edit || item.editRow?"inherit":"white",borderBottomColor:item.checked == true || item.edit || item.editRow?"#2A93DF":"",padding:"0px",paddingLeft:"10px",paddingRight:"10px"}}>
                                        <Checkbox checked={Boolean(item.checked)} onClick={(event)=>this.onSelectClick(event,item,index)} color="default" className="checkbox"/>
                                    </TableCell>
                                    {/* {this.state.selectedColm.find(element => element.includes("Customer Feedback")) && 
                                      <LightTooltip arrow title={item.customerFeedback} placement="top-start">
                                              <TableCell className="dataReviewTableCell" style={{padding:"0px",cursor:"pointer",backgroundColor:item.checked == true || item.edit || item.editRow?"inherit":"white",borderBottomColor:item.checked == true || item.edit || item.editRow?"#2A93DF":""}} onClick={()=>this.editRow(item)}>
                                                <span className="cellText">{item.customerFeedback.length > 60 ? item.customerFeedback.slice(0, 60) + '...' : item.customerFeedback}</span>
                                              </TableCell>
                                      </LightTooltip>
                                    } */}
                                    {this.state.selectedColm.find(element => element.includes("clusters")) && 
                                      <LightTooltip arrow title={item.clusters} placement="top-start">                                                                      
                                          <TableCell className="dataReviewTableCell" style={{padding:"0px",cursor:"pointer",backgroundColor:item.checked == true || item.edit || item.editRow?"inherit":"white",borderBottomColor:item.checked == true || item.edit || item.editRow?"#2A93DF":""}} onClick={()=>this.editRow(item)}>
                                          <div>{item.clusters}</div>                                     
                                          </TableCell>
                                      </LightTooltip>
                                    }

                                    {this.state.selectedColm.find(element => element.includes("cluster_label")) && 
                                      <LightTooltip arrow title={item.cluster_label} placement="top-start">
                                              <TableCell className="dataReviewTableCell" style={{padding:"0px",cursor:"pointer",backgroundColor:item.checked == true || (item.edit || item.editRow)?"inherit":"white",borderBottomColor:item.checked == true || item.edit || item.editRow?"#2A93DF":""}} onClick={()=>this.editRow(item)}>
                                              {item.edit && item.editRow?
                                                <FormControl style={{paddingLeft:"0px",}}>
                                                  <Select
                                                    value={this.state.editedCategory}
                                                    onChange={(e)=>this.handleChange(e)}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                    style={{border:"1px solid black",height:"30px",width:"96px",fontSize:"12px",fontWeight:"400",fontFamily:"Roboto-Regular"}}
                                                    sx={{
                                                      '& .css-19g3yfa-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root': {
                                                        paddingRight: 2,
                                                        paddingLeft: 1,
                                                        paddingTop: 1,
                                                        paddingBottom: 1,
                                                      }
                                                    }}
                                                  >{
                                                    this.state.categoriesList.length>0 && this.state.categoriesList.map((category)=>{
                                                      return(
                                                        <MenuItem value={category}>{category}</MenuItem>
                                                      )
                                                    })
                                                  }
                                                    {/* <MenuItem value="General">General</MenuItem>
                                                    <MenuItem value="Special">Special</MenuItem> */}
                                                  </Select>
                                                </FormControl>
                                              :<span className="cellText" style={{paddingLeft:"15px"}}>{item.cluster_label}</span>}
                                          </TableCell>
                                      </LightTooltip> 
                                    }
                                    
                                    {this.state.selectedColm.find(element => element.includes("sentiment")) && 
                                      <LightTooltip arrow title={item.sentiment} placement="top-start">
                                            <TableCell className="dataReviewTableCell" style={{padding:"0px",cursor:"pointer",backgroundColor:item.checked == true || item.edit || item.editRow?"inherit":"white",borderBottomColor:item.checked == true || item.edit || item.editRow?"#2A93DF":""}} onClick={()=>this.editRow(item)}>
                                            {item.edit && item.editRow?
                                                <FormControl style={{paddingLeft:"0px",}}>
                                                    <Select
                                                      value={this.state.editedSentiment}
                                                      onChange={(e)=>this.handleChange2(e)}
                                                      displayEmpty
                                                      inputProps={{ 'aria-label': 'Without label' }}
                                                      style={{border:"1px solid black",height:"30px",width:"96px",fontSize:"12px",fontWeight:"400",fontFamily:"Roboto-Regular"}}
                                                      sx={{
                                                        '& .css-19g3yfa-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root': {
                                                          paddingRight: 1,
                                                          paddingLeft: 1,
                                                          paddingTop: 1,
                                                          paddingBottom: 1,
                                                        }
                                                      }}
                                                    >{
                                                      this.state.sentimentList.length>0 && this.state.sentimentList.map((sentiment)=>{
                                                        return(
                                                          <MenuItem value={sentiment}>{sentiment}</MenuItem>
                                                        )
                                                      })
                                                    }
                                                      {/* <MenuItem value="Positive" style={{paddingRight:"0px"}}>Positive</MenuItem>
                                                      <MenuItem value="Negative">Negative</MenuItem> */}
                                                    </Select>
                                                  </FormControl>
                                                :<span className="cellText" style={{paddingLeft:"15px"}}>{item.sentiment}</span>}
                                          </TableCell>
                                      </LightTooltip>
                                    }
                                    {this.state.selectedColm.find(element => element.includes("status")) && 
                                      <LightTooltip arrow title={item.status} placement="top-start">                                                                      
                                          <TableCell className="dataReviewTableCell " style={{padding:"0px",cursor:"pointer",backgroundColor:item.checked == true || item.edit || item.editRow?"inherit":"white",borderBottomColor:item.checked == true || item.edit || item.editRow?"#2A93DF":""}} onClick={()=>this.editRow(item)}>
                                            <span className="cellText" style={{paddingLeft:"15px"}}>{item.status}</span>
                                          </TableCell>
                                      </LightTooltip>
                                    }
                                    {this.state.selectedColm.find(element => element.includes("keywords")) && 
                                      <LightTooltip arrow title={item.keywords} placement="top-start">                                                                      
                                          <TableCell className="dataReviewTableCell" style={{width:"20%",padding:"0px",cursor:"pointer",backgroundColor:item.checked == true || item.edit || item.editRow?"inherit":"white",borderBottomColor:item.checked == true || item.edit || item.editRow?"#2A93DF":""}} onClick={()=>this.editRow(item)}>
                                            {
                                              item.keywords.map((keyword,index)=>{
                                                return(
                                                  <span>{keyword} 
                                                  {index < item.keywords.length - 1 ? ', ' : ''}</span>
                                                )
                                              })
                                            }                                    
                                          </TableCell>
                                      </LightTooltip>
                                    }
                                    
                                    {this.state.selectedColm.find(element => element.includes("number")) && 
                                      <LightTooltip arrow title={item.number} placement="top-start">                                                                      
                                          <TableCell className="dataReviewTableCell" style={{padding:"0px",cursor:"pointer",backgroundColor:item.checked == true || item.edit || item.editRow?"inherit":"white",borderBottomColor:item.checked == true || item.edit || item.editRow?"#2A93DF":""}} onClick={()=>this.editRow(item)}>
                                          <div>{item.number}</div>                                     
                                          </TableCell>
                                      </LightTooltip>
                                    }
                                    {this.state.selectedColm.find(element => element.includes("u_customer_response")) && 
                                      <LightTooltip arrow title={item.u_customer_response} placement="top-start">                                                                      
                                          <TableCell className="dataReviewTableCell" style={{padding:"0px",cursor:"pointer",backgroundColor:item.checked == true || item.edit || item.editRow?"inherit":"white",borderBottomColor:item.checked == true || item.edit || item.editRow?"#2A93DF":""}} onClick={()=>this.editRow(item)}>
                                          <div>{item.u_customer_response}</div>                                     
                                          </TableCell>
                                      </LightTooltip>
                                    }
                                    {this.state.selectedColm.find(element => element.includes("u_customer_satisfaction")) && 
                                      <LightTooltip arrow title={item.u_customer_satisfaction} placement="top-start">                                                                      
                                          <TableCell className="dataReviewTableCell" style={{padding:"0px",cursor:"pointer",backgroundColor:item.checked == true || item.edit || item.editRow?"inherit":"white",borderBottomColor:item.checked == true || item.edit || item.editRow?"#2A93DF":""}} onClick={()=>this.editRow(item)}>
                                          <div>{item.u_customer_satisfaction}</div>                                     
                                          </TableCell>
                                      </LightTooltip>
                                    }
                                    {this.state.selectedColm.find(element => element.includes("u_primary_reason_for_rating")) && 
                                      <LightTooltip arrow title={item.u_primary_reason_for_rating} placement="top-start">                                                                      
                                          <TableCell className="dataReviewTableCell" style={{padding:"0px",cursor:"pointer",backgroundColor:item.checked == true || item.edit || item.editRow?"inherit":"white",borderBottomColor:item.checked == true || item.edit || item.editRow?"#2A93DF":""}} onClick={()=>this.editRow(item)}>
                                          <div>{item.u_primary_reason_for_rating}</div>                                     
                                          </TableCell>
                                      </LightTooltip>
                                    }
                                    {this.state.selectedColm.find(element => element.includes("project")) && 
                                      <LightTooltip arrow title={item.project} placement="top-start">                                                                      
                                          <TableCell className="dataReviewTableCell" style={{padding:"0px",cursor:"pointer",backgroundColor:item.checked == true || item.edit || item.editRow?"inherit":"white",borderBottomColor:item.checked == true || item.edit || item.editRow?"#2A93DF":""}} onClick={()=>this.editRow(item)}>
                                          <div>{item.project}</div>                                     
                                          </TableCell>
                                      </LightTooltip>
                                    }
                                    {this.state.selectedColm.find(element => element.includes("Label_Predicted")) && 
                                      <LightTooltip arrow title={item.Label_Predicted} placement="top-start">                                                                      
                                          <TableCell className="dataReviewTableCell" style={{padding:"0px",cursor:"pointer",backgroundColor:item.checked == true || item.edit || item.editRow?"inherit":"white",borderBottomColor:item.checked == true || item.edit || item.editRow?"#2A93DF":""}} onClick={()=>this.editRow(item)}>
                                          <div>{item.Label_Predicted}</div>                                     
                                          </TableCell>
                                      </LightTooltip>
                                    }
                                    {this.state.selectedColm.find(element => element.includes("marketing_division")) && 
                                      <LightTooltip arrow title={item.marketing_division} placement="top-start">                                                                      
                                          <TableCell className="dataReviewTableCell" style={{padding:"0px",cursor:"pointer",backgroundColor:item.checked == true || item.edit || item.editRow?"inherit":"white",borderBottomColor:item.checked == true || item.edit || item.editRow?"#2A93DF":""}} onClick={()=>this.editRow(item)}>
                                          <div>{item.marketing_division}</div>                                     
                                          </TableCell>
                                      </LightTooltip>
                                    }
                                    {this.state.selectedColm.find(element => element.includes("product_name")) && 
                                      <LightTooltip arrow title={item.product_name} placement="top-start">                                                                      
                                          <TableCell className="dataReviewTableCell" style={{padding:"0px",cursor:"pointer",backgroundColor:item.checked == true || item.edit || item.editRow?"inherit":"white",borderBottomColor:item.checked == true || item.edit || item.editRow?"#2A93DF":""}} onClick={()=>this.editRow(item)}>
                                          <div>{item.product_name}</div>                                     
                                          </TableCell>
                                      </LightTooltip>
                                    }
                                    {this.state.selectedColm.find(element => element.includes("nps_group_name")) && 
                                      <LightTooltip arrow title={item.nps_group_name} placement="top-start">                                                                      
                                          <TableCell className="dataReviewTableCell" style={{padding:"0px",cursor:"pointer",backgroundColor:item.checked == true || item.edit || item.editRow?"inherit":"white",borderBottomColor:item.checked == true || item.edit || item.editRow?"#2A93DF":""}} onClick={()=>this.editRow(item)}>
                                          <div>{item.nps_group_name}</div>                                     
                                          </TableCell>
                                      </LightTooltip>
                                    }
                                    {this.state.selectedColm.find(element => element.includes("account_name")) && 
                                      <LightTooltip arrow title={item.account_name} placement="top-start">                                                                      
                                          <TableCell className="dataReviewTableCell" style={{padding:"0px",cursor:"pointer",backgroundColor:item.checked == true || item.edit || item.editRow?"inherit":"white",borderBottomColor:item.checked == true || item.edit || item.editRow?"#2A93DF":""}} onClick={()=>this.editRow(item)}>
                                          <div>{item.account_name}</div>                                     
                                          </TableCell>
                                      </LightTooltip>
                                    }
                                    {this.state.selectedColm.find(element => element.includes("u_crm_product_family")) && 
                                      <LightTooltip arrow title={item.u_crm_product_family} placement="top-start">                                                                      
                                          <TableCell className="dataReviewTableCell" style={{padding:"0px",cursor:"pointer",backgroundColor:item.checked == true || item.edit || item.editRow?"inherit":"white",borderBottomColor:item.checked == true || item.edit || item.editRow?"#2A93DF":""}} onClick={()=>this.editRow(item)}>
                                          <div>{item.u_crm_product_family}</div>                                     
                                          </TableCell>
                                      </LightTooltip>
                                    }
                                    {this.state.selectedColm.find(element => element.includes("u_geography_name")) && 
                                      <LightTooltip arrow title={item.u_geography_name} placement="top-start">                                                                      
                                          <TableCell className="dataReviewTableCell" style={{padding:"0px",cursor:"pointer",backgroundColor:item.checked == true || item.edit || item.editRow?"inherit":"white",borderBottomColor:item.checked == true || item.edit || item.editRow?"#2A93DF":""}} onClick={()=>this.editRow(item)}>
                                          <div>{item.u_geography_name}</div>                                     
                                          </TableCell>
                                      </LightTooltip>
                                    }
                                    {this.state.selectedColm.find(element => element.includes("customer_comment")) && 
                                      <LightTooltip arrow title={item.customer_comment} placement="top-start">                                                                      
                                          <TableCell className="dataReviewTableCell" style={{padding:"0px",cursor:"pointer",backgroundColor:item.checked == true || item.edit || item.editRow?"inherit":"white",borderBottomColor:item.checked == true || item.edit || item.editRow?"#2A93DF":""}} onClick={()=>this.editRow(item)}>
                                          <div>{item.customer_comment}</div>                                     
                                          </TableCell>
                                      </LightTooltip>
                                    }
                                    {this.state.selectedColm.find(element => element.includes("u_product_pillar")) && 
                                      <LightTooltip arrow title={item.u_product_pillar} placement="top-start">                                                                      
                                          <TableCell className="dataReviewTableCell" style={{padding:"0px",cursor:"pointer",backgroundColor:item.checked == true || item.edit || item.editRow?"inherit":"white",borderBottomColor:item.checked == true || item.edit || item.editRow?"#2A93DF":""}} onClick={()=>this.editRow(item)}>
                                          <div>{item.u_product_pillar}</div>                                     
                                          </TableCell>
                                      </LightTooltip>
                                    }
                                    {this.state.selectedColm.find(element => element.includes("u_secondary_reason_for_rating")) && 
                                      <LightTooltip arrow title={item.u_secondary_reason_for_rating} placement="top-start">                                                                      
                                          <TableCell className="dataReviewTableCell" style={{padding:"0px",cursor:"pointer",backgroundColor:item.checked == true || item.edit || item.editRow?"inherit":"white",borderBottomColor:item.checked == true || item.edit || item.editRow?"#2A93DF":""}} onClick={()=>this.editRow(item)}>
                                          <div>{item.u_secondary_reason_for_rating}</div>                                     
                                          </TableCell>
                                      </LightTooltip>
                                    }
                                    {this.state.selectedColm.find(element => element.includes("prediction_score")) && 
                                      <LightTooltip arrow title={item.prediction_score} placement="top-start">                                                                      
                                          <TableCell className="dataReviewTableCell" style={{padding:"0px",cursor:"pointer",backgroundColor:item.checked == true || item.edit || item.editRow?"inherit":"white",borderBottomColor:item.checked == true || item.edit || item.editRow?"#2A93DF":""}} onClick={()=>this.editRow(item)}>
                                          <div>{!item.edit && item.editRow &&<div  style={{paddingLeft:"0px",}}>
                                                <span onClick='' style={{paddingRight:"10px",cursor:"pointer"}}><RemoveRedEyeOutlinedIcon sx={{ fontSize:17 }}/></span>
                                                <span onClick={()=>this.clickedModeEditIcon(item)} style={{paddingRight:"10px",cursor:"pointer"}}><ModeEditIcon sx={{ fontSize: 17 }}/></span>
                                                <span onClick={()=>this.callDeleteApi(item)} style={{paddingRight:"10px",cursor:"pointer"}}><DeleteOutlinedIcon sx={{ fontSize: 17 }}/></span>
                                              </div>}
                                              {!item.edit && !item.editRow &&<span className="cellText" style={{paddingLeft:"0px"}}>{item.prediction_score}</span>}
                                              {item.edit && item.editRow &&<div>
                                                <span className="cellText" style={{paddingLeft:"0px"}}>{item.prediction_score}</span>
                                                <span onClick={()=>this.callUpdateApi(item)} style={{position:"absolute", right:"60px",cursor:"pointer"}}><CheckCircleOutlineOutlinedIcon sx={{fontSize: 17 ,color:"#c1cd32"}}/></span>
                                                <span onClick={()=>this.cancelUpdateApi(item)} style={{position:"absolute", right:"30px",cursor:"pointer"}}><HighlightOffOutlinedIcon sx={{fontSize: 17,color:"red"}}/></span>
                                                </div>}
                                          </div>                                     
                                          </TableCell>
                                      </LightTooltip>
                                    }
                                </TableRow>
            
                              </>
                            )
                        }) : null}
                    </TableBody>}
                  </Table>
                </TableContainer>
            </div>
      return(
        <React.Fragment>
              {/* {header} */}
              {/* <TabsComponent tabItems={this.state.tabItems}
                            selected={this.state.selectedTab}
                            setSelected={this.setSelected} className='t-69'>
                            {trainigData}
              </TabsComponent> */}
              {trainigData}
                {/* -------------------------------------Category Filter Menu------------------------------------------------ */}
              <div>
                <Menu id="multimediaDocMenu" anchorEl={this.state.menuShow1} keepMounted open={Boolean(this.state.menuShow1)} 
                  PaperProps={{style: {width: '21ch',marginLeft:'-25px',marginTop:'25px'},}}  
                  onClose={this.handleCloseMenu1}
                >
                    <MenuItem className="menuItems">
                        <div>
                            <StyledCheckbox checked={this.state.all1} onChange={(event)=>this.categoryFilterOnAll(event)}  color="default" style={{padding:"0px"}}/>
                            <label className='menuText'>&nbsp;&nbsp;All</label>
                        </div>
                    </MenuItem>
                    <hr style={{marginTop:"0px",marginBottom:"5px",border:"0px",borderTop:"1px solid rgba(0,0,0,.1)"}}></hr>
                    {
                      this.state.categoriesList.length>0 && this.state.categoriesList.map((item,index)=>{
                        return(
                                <MenuItem className="menuItems">
                                    <div>
                                        <StyledCheckbox checked={item.check} onChange={(e)=>this.categoryFilter(e,index)} color="default" style={{padding:"0px"}}/>
                                        <label className='menuText'>&nbsp;&nbsp;{item.category}</label>
                                    </div>
                                </MenuItem>
                        )
                      })
                    } 
                    <div style={{ padding: "5px", display: "flex",justifyContent: "space-between", alignItems: "center"}}>
                        <button style={{width:"75px",height:"25px",borderWidth:"1.8px",border:"1px solid #007bff",color:"#007bff",backgroundColor:"#fff",borderRadius:"2px"}}>
                          CANCEL
                        </button>
                        <button style={{width:'75px',height:'25px',borderWidth: "1.8px",border:"1px solid #007bff",color:"#fff",backgroundColor:"#007bff",borderRadius:"2px"}} onClick={()=>this.tableFilter()}>
                          APPLY
                        </button>
                    </div>                    
                </Menu>
           
                {/* -------------------------------------Sentiment Filter Menu------------------------------------------------ */}
                <Menu id="multimediaDocMenu" anchorEl={this.state.menuShow2} keepMounted open={Boolean(this.state.menuShow2)} 
                  PaperProps={{style: {width: '18ch',marginLeft:'-25px',marginTop:'25px',},}}  
                  onClose={this.handleCloseMenu1}
                >
                    <MenuItem className="menuItems">
                        <div>
                            <StyledCheckbox checked={this.state.all2} onChange={(event)=>this.sentimentFilterOnAll(event)}  color="default" style={{padding:"0px"}}/>
                            <label className='menuText'>&nbsp;&nbsp;All</label>
                        </div>
                    </MenuItem>
                    <hr style={{marginTop:"0px",marginBottom:"5px",border:"0px",borderTop:"1px solid rgba(0,0,0,.1)"}}></hr>
                    {
                      this.state.sentimentList.length>0 && this.state.sentimentList.map((item,index)=>{
                        return(
                                <MenuItem className="menuItems">
                                    <div>
                                        <StyledCheckbox checked={item.check} onChange={(e)=>this.sentimentFilter(e,index)} color="default" style={{padding:"0px"}}/>
                                        <label className='menuText'>&nbsp;&nbsp;{item.sentiment}</label>
                                    </div>
                                </MenuItem>
                        )
                      })
                    }
                    <div style={{ padding: "5px", display: "flex",justifyContent: "space-between", alignItems: "center"}}>
                        <div style={{marginRight:"5px"}}>
                          <button style={{width:"75px",height:"25px",borderWidth:"1.8px",border:"1px solid #007bff",color:"#007bff",backgroundColor:"#fff",borderRadius:"2px"}}>
                            CANCEL
                          </button>
                        </div>
                        <button style={{width:'75px',height:'25px',borderWidth: "1.8px",border:"1px solid #007bff",color:"#fff",backgroundColor:"#007bff",borderRadius:"2px"}} onClick={()=>this.tableFilter()}>
                          APPLY
                        </button>
                    </div>
                </Menu>
                {/* -------------------------------------Status Filter Menu------------------------------------------------ */}
                <Menu id="multimediaDocMenu" anchorEl={this.state.menuShow3} keepMounted open={Boolean(this.state.menuShow3)} 
                  PaperProps={{style: {width: '18ch',marginLeft:'-25px',marginTop:'25px',},}}  
                  onClose={this.handleCloseMenu1}
                >
                    <MenuItem className="menuItems">
                        <div>
                            <StyledCheckbox checked={this.state.all3} onChange={(event)=>this.statusFilterOnAll(event)}  color="default" style={{padding:"0px"}}/>
                            <label className='menuText'>&nbsp;&nbsp;All</label>
                        </div>
                    </MenuItem>
                    <hr style={{marginTop:"0px",marginBottom:"5px",border:"0px",borderTop:"1px solid rgba(0,0,0,.1)"}}></hr>
                    {
                      this.state.statusList.length>0 && this.state.statusList.map((item,index)=>{
                        return(
                                <MenuItem className="menuItems">
                                    <div>
                                        <StyledCheckbox checked={item.check} onChange={(e)=>this.statusFilter(e,index)} color="default" style={{padding:"0px"}}/>
                                        <label className='menuText'>&nbsp;&nbsp;{item.status}</label>
                                    </div>
                                </MenuItem>
                        )
                      })
                    }
                    <div style={{ padding: "5px", display: "flex",justifyContent: "space-between", alignItems: "center"}}>
                        <div style={{marginRight:"5px"}}>
                          <button style={{width:"75px",height:"25px",borderWidth:"1.8px",border:"1px solid #007bff",color:"#007bff",backgroundColor:"#fff",borderRadius:"2px"}}>
                            CANCEL
                          </button>
                        </div>
                        <button style={{width:'75px',height:'25px',borderWidth: "1.8px",border:"1px solid #007bff",color:"#fff",backgroundColor:"#007bff",borderRadius:"2px"}} onClick={()=>this.tableFilter()}>
                          APPLY
                        </button>
                    </div>
                </Menu>
                
              </div>
              <div>
                <DrawerComponent heading="Filter" showDrawer={()=>this.closeFilterDrawer()} openDrawer={this.state.openFilterDrawer}>
                  <div>
                    <div style={{padding:"20px",paddingRight:"0px"}}>
                      <ReactMultiSelectComponent
                          value={this.state.selectedColm}
                          options={this.state.tableColm && this.state.tableColm.map(colm =>(
                              {value:colm,label:colm}
                          ))}
                          onChange={(newValue) => this.onChangeColmHeader(newValue)}
                          label="Display Column "
                          onRemoveItem={this.onRemoveColmHeader}
                          placeholder={'Select'}
                      />
                    </div>
                    <div style={{padding:"10px"}}>
                      <SelectCustom
                          name="Category"
                          label="Category"
                          changed={(event) => this.changeCategoryInDrawer(event)}
                          required={true}
                          value={this.state.categoryInDrawer}
                          options={[{value:"All",displayValue:"All Category"},{value:"General",displayValue:"General"},{value:"Special",displayValue:"Special"}]}
                      />
                    </div>
                    <div style={{padding:"10px"}}>
                      <SelectCustom
                          name="Sentiment"
                          label="Sentiment"
                          changed={(event) => this.changeSentimentInDrawer(event)}
                          required={true}
                          value={this.state.sentimentInDrawer}
                          options={[{value:"All",displayValue:"All Sentiment"},{value:"Positive",displayValue:"Positive"},{value:"Negative",displayValue:"Negative"}]}
                      />
                    </div>
                    <div style={{padding:"10px"}}>
                      <SelectCustom
                          name="Status"
                          label="Status"
                          changed={(event) => this.changeStatusInDrawer(event)}
                          required={true}
                          value={this.state.statusInDrawer}
                          options={[{value:"All",displayValue:"All"},{value:"Reviewed",displayValue:"Reviewed"},{value:"Not Reviewed",displayValue:"Not Reviewed"}]}
                      />
                    </div>
                    <div style={{paddingLeft:"10px",paddingTop:"20px"}}>
                      <label for="quantity" className="predictionScoreLabel">Prediction Score Threshold</label>
                      <input type="number" name="quantity" min="1" max="10" className="predictionScore"/>
                    </div>
                  </div>           
                </DrawerComponent>
              </div>
              <div>
                <DrawerComponent heading="Filter" showDrawer={()=>this.closeFilterDrawer()} openDrawer={this.state.openAllFilterDrawer}>
                  <div>
                    <div style={{padding:"10px"}}>
                      <SelectCustom
                          name="Category"
                          label="Category"
                          changed={(event) => this.changeAllCategoryInDrawer(event)}
                          required={true}
                          value={this.state.allCategoryInDrawer}
                          options={
                            this.state.categoriesList.length>0 && this.state.categoriesList.map((cat)=>{
                              return(
                                {value:cat.category,displayValue:cat.category}
                              )
                            })
                          }
                      />
                    </div>
                    <div style={{padding:"10px"}}>
                      <SelectCustom
                          name="Sentiment"
                          label="Sentiment"
                          changed={(event) => this.changeAllSentimentInDrawer(event)}
                          required={true}
                          value={this.state.allSentimentInDrawer}
                          options={
                            this.state.sentimentList.length>0 && this.state.sentimentList.map((item)=>{
                              return(
                                {value:item.sentiment,displayValue:item.sentiment}
                              )
                            })
                          }
                      />
                    </div>
                    <div style={{ padding: "10px", display: "flex",justifyContent: "space-between", alignItems: "center"}}>
                        <button className="cancelBtn" style={{ width: "150px", height: "30px",marginRight: "50px",borderWidth: "1.8px"}}>
                          CANCEL
                        </button>
                        <button className="customBtn" style={{ width: '160px', height: '30px',marginTop:'16px',marginRight:"0px"}} onClick={()=>this.callAllUpdateApi()}>
                            SAVE
                        </button>
                    </div>
                  </div>           
                </DrawerComponent>
              </div>
        </React.Fragment>  
      )
  }
}

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 12,
    margin:-8 -2,
  },
}))(Tooltip);

const mapStateToProps = (state) => {
    return {
      projectlist: state.user.projects,
      SourceAttributesData: state.UnsupervisedML.SourceAttributes,
      dataReviewData: state.UnsupervisedML.dataReviewData,
      allEditableList: state.UnsupervisedML.allEditableList,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      onchangeProjectAction: (projectname) => dispatch(actions.changeProjectAction(projectname)),
      onGetSourceAttributes: (url) => dispatch(actions.GetSourceAttributes(url)),  
      onGetDataReviewData: (url) => dispatch(actions.getDataReviewData(url)),
      onGetSentimentAndCategoryAndStatus: (url) => dispatch(actions.getSentimentAndCategoryAndStatus(url)),
      onUpdateDataReview: (url,payload) => dispatch(actions.updateDataReview(url,payload)),
      onDeleteDataReview: (url,payload) => dispatch(actions.deleteDataReview(url,payload)),
    };
  };
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(withErrorHandler(DataReview, axios));