
import * as actionTypes from '../actions/actionTypes';


const intailState = {
  recentBackupDetails : JSON.parse(localStorage.getItem( 'recentBackupDetails' )) || [],
  restoreOptionsList: []
}

const setESBackupDetails = (state, action) => {
    return { ...state, recentBackupDetails: action.backupInfo }
}

const setESRestoreList = (state, action) => {
    return { ...state, restoreOptionsList: action.restoreOptionsList }
}

const BackupRestoreReducer =(state=intailState, action) =>{
    switch(action.Type)
    {
        case actionTypes.SET_ES_BACKUP_DETAILS: 
          return setESBackupDetails(state, action);
        case actionTypes.SET_ES_RESTORE_LIST_DETAILS:
          return setESRestoreList(state, action)
        default :
          return state;
    }
   
}





export default BackupRestoreReducer;