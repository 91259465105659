import React from "react";
import { Row, Col } from "react-bootstrap";
import Dropdown from 'react-bootstrap/Dropdown';
import './RowCard.css';

const RowCard = ({data, buttonClick, toggleDrawer}) => {

    return (
        <Row className="d-flex roles-content-row row-no-gutters">
            <Col xs={12} className="d-flex row ">
            <div className="row d-flex justify-content-between w-100 content-align-items row-no-gutters">
            <Col xs={4}>
              <div className="roles-content padding-adjustment name-capitilize" data-testid={`card-${data.id}`} key={data.id}>{data.name}</div>
            </Col>
            <Col xs={7}>
              <div className="roles-content padding-adjustment">{data.description}</div>
            </Col>
            <Col xs={1}>
              <div className="roles-content cursor-action" >
              <Dropdown className="navigationHelpMenu" style={{ "position": "absolute",'bottom':'2px'}}>
                <Dropdown.Toggle  id="navigationHelpMenu"  drop='end'>
                    <img src='./Icons/OptionsIcon.svg' title="Help" style={{ "marginLeft": "7px"}}/>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item className='optionsItemsDropdown' data-testid="cardEditBtn" onClick={()=>toggleDrawer(true)}
                    >EDIT</Dropdown.Item>
                    <Dropdown.Item className='optionsItemsDropdown' onClick={()=>buttonClick()}>
                      DELETE</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              </div>
            </Col>
            </div>
            </Col>
                    </Row>
    )
}

export default RowCard;