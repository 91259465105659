import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../utility';


const initialState = {
    error: null,
    loading: false,
    totalUsers:null,
    totalCognitiveUsers:null,
    selectedCategory:'',
    totalCognitiveSearchqueries:null,
    newUsers:null,
    repeatUsers:null,
    totalUsersReport:null,
    totalCognitiveUnanswers:null,
    totalChatSession:null,
    totalAgentTransforms:null,
    totalFeedback:null,
    totalCognitiveFeedback:null,
    NewUserReport:null,
    RepeatUserReport:null,
    totalAgentTransferReport:null,
    totalFlowReport:null,
    feedbackReportData:null,
    feedbackUtterancesData:null,
    feedbackCategoriesData:[],
    reportDetail:null,
    avgUserReport:null,
    totalmeetingcount:null,
    totalmeetingsreport:null,
    totalintiatedbotreport:null,
    TotalCognitiveUserReport:null,
    msgCategoryRes:[],
    UnansweredCatReponse:[],
    totalTriageReport:null,
    conLoading:false,
    conUsers:false,
    conFeedbackLoading:false,
    conAgentLoading:false,
    conFeedbackReportLoading:false,
    conFeedbackCategoryLoading:false,
    conUtteranceLoading:false,
    conAnsUnsReportLoading:false,
    conmsgCategoryLoading:false,
    conMsgAnsUnsLoading:false,
    conTopUnansLoading:false,
    conGetUnansLoading:false,
    conUserReportLoading:false,
    cogSearchQueryLoading:false,
    cogFeedbackCountLoading:false,
    cogUsersCountLoading:false,
    cogUsersReportLoading:false,
    ptCountLoading:false,
    conMetricsLoading:false,
    conMetricsData:null,
    cogMetricsLoading:false,
    cogMetricsData:null,
    // conFeedbackLoading:false,
    conFeedbackMetricsData:null,
    conNegativeUtteranceLoading:false,
    conNegativeUtteranceData:null,
    conHandledLoading:false,
    conHandledData:null,
    conMsgUtterancesLoading:false,
    conMsgUtterancesData:null,
    conUnansUtterancesData:null,
    conUnansUtteranceLoading:false
};


const totalUserStart = ( state ) => {
    return updateObject( state, { error: null, conUsers: true } );
};

const totalUserSuccess = (state, action) => {
    return updateObject( state, { 
        totalUsers: action.totalUsers,
        error: null,
        conUsers: false,
     } );
};

const totalUserFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        conUsers: false
    });
};

const totalCognitiveSearchQueryFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        cogSearchQueryLoading: false
    });
};
const totalCognitiveUserFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        cogUsersCountLoading: false
    });
};


const totalCognitiveUserStart = ( state ) => {
    return updateObject( state, { error: null, cogUsersCountLoading: true } );
};

const totalCognitiveUserSuccess = (state, action) => {
    return updateObject( state, { 
        totalCognitiveUsers: action.totalUsers,
        error: null,
        cogUsersCountLoading: false,
     } );
};

const totalCognitiveSearchQueryStart = ( state ) => {
    return updateObject( state, { error: null, cogSearchQueryLoading: true } );
};

const totalCognitiveSearchQuerySuccess = (state, action) => {
    return updateObject( state, { 
        totalCognitiveSearchqueries: action.totalCognitiveSearchqueries,
        error: null,
        cogSearchQueryLoading: false,
     } );
};



const newUsersStart = ( state ) => {
    return updateObject( state, { error: null, loading: true } );
};

const newUsersSuccess = (state, action) => {
    return updateObject( state, { 
        newUsers: action.newUsers,
        error: null,
        loading: false,
     } );
};

const newUsersFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
};
const repeatUsersStart = ( state ) => {
    return updateObject( state, { error: null, loading: true } );
};

const repeatUsersSuccess = (state, action) => {
    return updateObject( state, { 
        repeatUsers: action.repeatUsers,
        error: null,
        loading: false,
     } );
};

const repeatUsersFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
};

const totalUserReportStart = ( state ) => {
    return updateObject( state, { error: null, conUserReportLoading: true } );
};

const totalUserReportSuccess = (state, action) => {
    return updateObject( state, { 
        totalUsersReport: action.totalUsersReport,
        error: null,
        conUserReportLoading: false,
     } );
};

const totalUserReportFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        conUserReportLoading: false
    });
};

const totalUnanswersStart = ( state ) => {
    return updateObject( state, { error: null, loading: true } );
};

const totalUnanswersSuccess = (state, action) => {
    return updateObject( state, { 
        totalUnanswers: action.totalUnanswers,
        error: null,
        loading: false,
     } );
};

const totalUnanswersFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
};

const totalCognitiveUnanswersStart = ( state ) => {
    return updateObject( state, { error: null, loading: true } );
};

const totalCognitiveUnanswersSuccess = (state, action) => {
    return updateObject( state, { 
        totalCognitiveUnanswers: action.totalUnanswers,
        error: null,
        loading: false,
     } );
};

const totalCognitiveUnanswersFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
};

const totalFeedbackStart = ( state ) => {
    return updateObject( state, { error: null, conFeedbackLoading: true } );
};

const totalFeedbackSuccess = (state, action) => {
    return updateObject( state, { 
        totalFeedback: action.totalFeedback,
        error: null,
        conFeedbackLoading: false,
     } );
};

const totalFeedbackFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        conFeedbackLoading: false
    });
};


const totalCognitiveFeedbackStart = ( state ) => {
    return updateObject( state, { error: null, cogFeedbackCountLoading: true } );
};

const totalCognitiveFeedbackSuccess = (state, action) => {
    return updateObject( state, { 
        totalCognitiveFeedback: action.totalCogFeedback,
        error: null,
        cogFeedbackCountLoading: false,
     } );
};

const totalCognitiveFeedbackFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        cogFeedbackCountLoading: false
    });
};

const totalFeedbackReportStart = ( state ) => {
    return updateObject( state, { error: null, conFeedbackReportLoading: true } );
};

const totalFeedbackReportSuccess = (state, action) => {
    return updateObject( state, { 
        feedbackReportData: action.feedbackReportData,
        error: null,
        conFeedbackReportLoading: false,
     } );
};

const totalFeedbackUtterancesFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        conUtteranceLoading: false
    });
};

const totalFeedbackUtterancesStart = ( state ) => {
    return updateObject( state, { error: null, conUtteranceLoading: true } );
};

const totalFeedbackUtterancesSuccess = (state, action) => {
    return updateObject( state, { 
        feedbackUtterancesData: JSON.parse(action.feedbackUtterancesData),
        error: null,
        conUtteranceLoading: false,
     } );
};

const totalFeedbackCategoriesFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        conFeedbackCategoryLoading: false
    });
};

const totalFeedbackCategoriesStart = ( state ) => {
    return updateObject( state, { error: null, conFeedbackCategoryLoading: true } );
};

const totalFeedbackCategoriesSuccess = (state, action) => {
    let cats=action.feedbackCategoriesData?JSON.parse(action.feedbackCategoriesData):[];
    let topCat={
        cat:'',
        count:0
    }
    if(cats.length>0){
        for (let key in cats[0]) {
            if(cats[0][key]>topCat.count){
                topCat.count=cats[0][key];
                topCat.cat=key
            }
          }
    }
    return updateObject( state, { 
        feedbackCategoriesData: cats,
        selectedCategory:topCat.cat,
        error: null,
        conFeedbackCategoryLoading: false,
     } );
};

const selectedCategory = (state, action) => {
    return updateObject( state, { 
        selectedCategory:action.category,
     } );
};

const totalFeedbackReportFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        conFeedbackReportLoading: false
    });
};

const totalInitiatFlowStart = ( state ) => {
    return updateObject( state, { error: null, loading: true } );
};



const totalInitiatFlowSuccess = (state, action) => {
    return updateObject( state, { 
        initiatFlow: action.initiatFlow,
        error: null,
        loading: false,
     } );
};

const totalInitiatFlowFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
};

const totalChatSessionStart = ( state ) => {
    return updateObject( state, { error: null, loading: true } );
};

const totalChatSessionSuccess= (state, action) => {
    return updateObject( state, { 
        totalChatSession: action.totalChatSession,
        error: null,
        loading: false, 
     } );
};

const totalChatSessionFail= (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
};
const totalChatSessionReportStart = ( state ) => {
    return updateObject( state, { error: null, loading: true } );
};

const totalChatSessionReportSuccess= (state, action) => {
    return updateObject( state, { 
        totalChatSessionReport: action.totalChatSessionReport,
        error: null,
        loading: false, 
     } );
};

const totalChatSessionReportFail= (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
};

const totalAgentTransformsStart = ( state ) => {
    return updateObject( state, { error: null, conAgentLoading: true } );
};

const totalAgentTransformsSuccess= (state, action) => {
    return updateObject( state, { 
        totalAgentTransforms: action.totalAgentTransforms,
        error: null,
        conAgentLoading: false, 
     } );
};

const totalAgentTransformsFail= (state, action) => {
    return updateObject( state, {
        error: action.error,
        conAgentLoading: false
    });
};

const totalAgentTransferReportStart = ( state ) => {
    return updateObject( state, { error: null, loading: true } );
};

const totalAgentTransferReportSuccess= (state, action) => {
    return updateObject( state, { 
        totalAgentTransferReport: action.totalAgentTransferReport,
        error: null,
        loading: false, 
     } );
};

const totalAgentTransferReportFail= (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
};

const totalFlowReportStart = ( state ) => {
    return updateObject( state, { error: null, loading: true } );
};

const totalFlowReportSuccess= (state, action) => {
    return updateObject( state, { 
        totalFlowReport: action.totalFlowReport,
        error: null,
        loading: false, 
     } );
};

const totalFlowReportFail= (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
};


const totalNewUserReportStart = ( state ) => {
    return updateObject( state, { error: null, loading: true } );
};

const totalNewUserReportSuccess= (state, action) => {
    return updateObject( state, { 
        NewUserReport: action.NewUserReport,
        error: null,
        loading: false, 
     } );
};

const totalNewUserReportFail= (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
};
const totalunansweredReportStart = ( state ) => {
    return updateObject( state, { error: null, loading: true } );
};

const totalunansweredReportSuccess = (state, action) => {
    return updateObject( state, { 
        totalunansweredReport: action.totalunansweredReport,
        error: null,
        loading: false,
     } );
};

const totalunansweredReportFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
};

const totalRepeatUserReportStart = ( state ) => {
    return updateObject( state, { error: null, loading: true } );
};

const totalRepeatUserReportSuccess= (state, action) => {
    console.log("action",action)
    return updateObject( state, { 
        RepeatUserReport: action.RepeatUserReport,
        error: null,
        loading: false, 
     } );
};

const totalRepeatUserReportFail= (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
};

const ReportPageAction = (state, action) => {
    return updateObject(state, { error: null, reportDetail: action.reportDetail  });
};

const AvgUserReportStart = ( state ) => {
    return updateObject( state, { error: null, loading: true } );
};

const AvgUserReportSuccess = (state, action) => {
    return updateObject( state, { 
        avgUserReport: action.avgUserReport,
        error: null,
        loading: false,
     } );
};

const AvgUserReportFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
};

const totalMeetingsCountStart = ( state ) => {
    return updateObject( state, { error: null, loading: true } );
};

const totalMeetingsCountSuccess = (state, action) => {
    return updateObject( state, { 
        totalmeetingcount: action.totalmeetingcount,
        error: null,
        loading: false,
     } );
};

const totalMeetingsCountFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
};

const totalMeetingsReportStart = ( state ) => {
    return updateObject( state, { error: null, loading: true } );
};

const totalMeetingsReportSuccess = (state, action) => {
    return updateObject( state, { 
        totalmeetingsreport: action.totalmeetingsreport,
        error: null,
        loading: false,
     } );
};

const totalMeetingsReportFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
};
const totalInitiatedBotReportStart = ( state ) => {
    return updateObject( state, { error: null, loading: true } );
};

const totalInitiatedBotReportSuccess = (state, action) => {
    return updateObject( state, { 
        totalintiatedbotreport: action.totalintiatedbotreport,
        error: null,
        loading: false,
     } );
};

const totalInitiatedBotReportFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
};


const totalAnsUnansCountStart = ( state ) => {
    return updateObject( state, { error: null, conLoading: true } );
};

const totalAnsUnansCountSuccess = (state, action) => {
    return updateObject( state, { 
        totalAnsUnansCount: action.totalAnsUnansCount,
        error: null,
        conLoading: false,
     } );
};

const totalAnsUnansCountFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        conLoading: false
    });
};

const msgCategoriesStart = ( state ) => {
    return updateObject( state, { error: null, conmsgCategoryLoading: true } );
};

const msgCategoriesSuccess = (state, action) => {
    return updateObject( state, { 
        msgCategoryRes: action.msgCategoryRes,
        error: null,
        conmsgCategoryLoading: false,
     } );
};

const msgCategoriesFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        conmsgCategoryLoading: false
    });
};


const msgCategoriesAnsStart = ( state ) => {
    return updateObject( state, { error: null, conMsgAnsUnsLoading: true } );
};

const msgCategoriesAnsSuccess = (state, action) => {
    return updateObject( state, { 
        msgCategoryAnsRes: action.msgCategoryAnsRes,
        error: null,
        conMsgAnsUnsLoading: false,
     } );
};

const msgCategoriesAnsFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        conMsgAnsUnsLoading: false
    });
};

const ConAnsUnsCountStart = ( state ) => {
    return updateObject( state, { error: null, loading: true } );
};

const ConAnsUnsCountSuccess = (state, action) => {
    return updateObject( state, { 
        CoversationCount: action.CoversationCount,
        error: null,
        loading: false,
     } );
};

const ConAnsUnsCountFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
};


const ConAnsUnsCountReportStart = ( state ) => {
    return updateObject( state, { error: null, conAnsUnsReportLoading: true } );
};

const ConAnsUnsCountReportSuccess = (state, action) => {
    return updateObject( state, { 
        ConversationReport: action.ConversationReport,
        error: null,
        conAnsUnsReportLoading: false,
     } );
};

const ConAnsUnsCountReportFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        conAnsUnsReportLoading: false
    });
};

const totalUnansCatStart = ( state ) => {
    return updateObject( state, { error: null, conTopUnansLoading: true } );
};

const totalUnansCatSuccess = (state, action) => {
    return updateObject( state, { 
        UnansweredCatReponse: action.UnansweredCatReponse,
        error: null,
        conTopUnansLoading: false,
     } );
};

const totalUnansCatFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        conTopUnansLoading: false
    });
};

const getUnansUtterancesStart = ( state ) => {
    return updateObject( state, { error: null, conGetUnansLoading: true } );
};

const getUnansUtterancesSuccess = (state, action) => {
    return updateObject( state, { 
        UnansweredUtterances: action.UnansweredUtterances,
        error: null,
        conGetUnansLoading: false,
     } );
};

const getUnansUtterancesFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        conGetUnansLoading: false
    });
};


const AgentTransUtterancesStart = ( state ) => {
    return updateObject( state, { error: null, loading: true } );
};

const AgentTransUtterancesSuccess = (state, action) => {
    return updateObject( state, { 
        AgentTransferUtterances: action.AgentTransferUtterances,
        error: null,
        loading: false,
     } );
};

const AgentTransUtterancesFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
};


const AgentTransferCatStart = ( state ) => {
    return updateObject( state, { error: null, loading: true } );
};

const AgentTransferCatSuccess = (state, action) => {
    return updateObject( state, { 
        AgentTransferCat: action.AgentTransferCat,
        error: null,
        loading: false,
     } );
};

const AgentTransferCatFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
};


const TopAgentTransferStart = ( state ) => {
    return updateObject( state, { error: null, loading: true } );
};

const TopAgentTransferSuccess = (state, action) => {
    return updateObject( state, { 
        TopAgentTransfer: action.TopAgentTransfer,
        error: null,
        loading: false,
     } );
};

const TopAgentTransferFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
};

const TotalCogUserReportStart = ( state ) => {
    return updateObject( state, { error: null, cogUsersReportLoading: true } );
};

const TotalCogUserReportSuccess = (state, action) => {
    return updateObject( state, { 
        TotalCognitiveUserReport: action.TotalCognitiveUserReport,
        error: null,
        cogUsersReportLoading: false,
     } );
};

const TotalCogUserReportFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        cogUsersReportLoading: false
    });
};

const TotalTriageReportStart = (state) =>{
    return updateObject( state, { error: null, ptCountLoading: true } );
};

const TotalTriageReportSuccess = (state, action) =>{
    return updateObject( state, { 
        totalTriageReport: action.TotalTriageReport,
        error: null,
        ptCountLoading: false,
     } );
};
const TotalTriageReportFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        ptCountLoading: false
    });
};

const conversationalDataStart = (state) =>{
    return updateObject( state, { error: null, conMetricsLoading: true } );
};

const conversationalDataSuccess = (state, action) =>{
    return updateObject( state, { 
        conMetricsData: action.conMetricsData,
        error: null,
        conMetricsLoading: false,
     } );
};
const conversationalDataFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        conMetricsLoading: false
    });
};

const cognitiveDataStart = (state) =>{
    return updateObject( state, { error: null, cogMetricsLoading: true } );
};

const cognitiveDataSuccess = (state, action) =>{
    return updateObject( state, { 
        cogMetricsData: action.cogMetricsData,
        error: null,
        cogMetricsLoading: false,
     } );
};
const cognitiveDataFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        cogMetricsLoading: false
    });
};

const conversationFeedbackStart = (state) =>{
    return updateObject( state, { error: null, conFeedbackLoading: true } );
};

const conversationFeedbackSuccess = (state, action) =>{
    return updateObject( state, { 
        conFeedbackMetricsData: action.conFeedbackMetricsData,
        error: null,
        conFeedbackLoading: false,
     } );
};
const conversationFeedbackFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        conFeedbackLoading: false
    });
};

const conFeedbackNegativeUtteranceStart = (state) =>{
    return updateObject( state, { error: null, conNegativeUtteranceLoading: true } );
};

const conFeedbackNegativeUtteranceSuccess = (state, action) =>{
    return updateObject( state, { 
        conNegativeUtteranceData: action.conNegativeUtteranceData,
        error: null,
        conNegativeUtteranceLoading: false,
     } );
};
const conFeedbackNegativeUtteranceFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        conNegativeUtteranceLoading: false
    });
};
const conversationalHandledStart = (state) =>{
    return updateObject( state, { error: null, conHandledLoading: true } );
};

const conversationalHandledSuccess = (state, action) =>{
    return updateObject( state, { 
        conHandledData: action.conHandledData,
        error: null,
        conHandledLoading: false,
     } )
};
const conversationalHandledFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        conHandledLoading: false
    });
};
const conversationMsgUtterancesStart = (state) =>{
    return updateObject( state, { error: null, conMsgUtterancesLoading: true } );
};

const conversationMsgUtterancesSuccess = (state, action) =>{
    return updateObject( state, { 
        conMsgUtterancesData: action.conMsgUtterancesData,
        error: null,
        conMsgUtterancesLoading: false,
     } )
};
const conversationMsgUtterancesFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        conMsgUtterancesLoading: false
    });
}

const conUnansweredUtterancesStart = (state) =>{
    return updateObject( state, { error: null, conUnansUtteranceLoading: true } );
};

const conUnansweredUtterancesSuccess = (state, action) =>{
    return updateObject( state, { 
        conUnansUtterancesData: action.conUnansUtterancesData,
        error: null,
        conUnansUtteranceLoading: false,
     } )
};
const conUnansweredUtterancesFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        conUnansUtteranceLoading: false
    });
}
const reportDashboard = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.TOTALUSER_START: return totalUserStart(state);
        case actionTypes.TOTALUSER_SUCCESS: return totalUserSuccess(state, action);
        case actionTypes.TOTALUSER_FAIL: return totalUserFail(state, action);
        case actionTypes.TOTAL_COGNITIVE_USER_START: return totalCognitiveUserStart(state);
        case actionTypes.TOTAL_COGNITIVE_USER_SUCCESS: return totalCognitiveUserSuccess(state, action);
        case actionTypes.TOTAL_COGNITIVE_USER_FAIL: return totalCognitiveUserFail(state, action);
        case actionTypes.TOTAL_COGNITIVE_SEARCHQUERY_START: return totalCognitiveSearchQueryStart(state);
        case actionTypes.TOTAL_COGNITIVE_SEARCHQUERY_SUCCESS: return totalCognitiveSearchQuerySuccess(state, action);
        case actionTypes.TOTAL_COGNITIVE_SEARCHQUERY_FAIL: return totalCognitiveSearchQueryFail(state, action);
        case actionTypes.NEWUSER_START: return newUsersStart(state);
        case actionTypes.NEWUSER_SUCCESS: return newUsersSuccess(state, action);
        case actionTypes.NEWUSER_FAIL: return newUsersFail(state, action); 
        case actionTypes.REPEATUSER_START: return repeatUsersStart(state);
        case actionTypes.REPEATUSER_SUCCESS: return repeatUsersSuccess(state, action);
        case actionTypes.REPEATUSER_FAIL: return repeatUsersFail(state, action);  
        case actionTypes.TOTALUSERREPORT_START: return totalUserReportStart(state);
        case actionTypes.TOTALUSERREPORT_SUCCESS: return totalUserReportSuccess(state, action);
        case actionTypes.TOTALUSERREPORT_FAIL: return totalUserReportFail(state, action);    
        case actionTypes.TOTALUNANSWERS_SUCCESS: return totalUnanswersSuccess(state, action);   
        case actionTypes.TOTALUNANSWERS_START: return totalUnanswersStart(state);   
        case actionTypes.TOTALUNANSWERS_FAIL: return totalUnanswersFail(state, action);  
        case actionTypes.TOTAL_COGNITIVE_UNANSWERS_SUCCESS: return totalCognitiveUnanswersSuccess(state, action);   
        case actionTypes.TOTAL_COGNITIVE_UNANSWERS_START: return totalCognitiveUnanswersStart(state);   
        case actionTypes.TOTAL_COGNITIVE_UNANSWERS_FAIL: return totalCognitiveUnanswersFail(state, action);  
        case actionTypes.TOTALFEEDBACK_SUCCESS: return totalFeedbackSuccess(state, action);   
        case actionTypes.TOTALFEEDBACK_START: return totalFeedbackStart(state);   
        case actionTypes.TOTALFEEDBACK_FAIL: return totalFeedbackFail(state, action); 
        case actionTypes.TOTAL_COGNITIVE_FEEDBACK_SUCCESS: return totalCognitiveFeedbackSuccess(state, action);   
        case actionTypes.TOTAL_COGNITIVE_FEEDBACK_START: return totalCognitiveFeedbackStart(state);   
        case actionTypes.TOTAL_COGNITIVE_FEEDBACK_FAIL: return totalCognitiveFeedbackFail(state, action); 
        case actionTypes.TOTALFEEDBACK_REPORT_SUCCESS: return totalFeedbackReportSuccess(state, action);   
        case actionTypes.TOTALFEEDBACK_REPORT_START: return totalFeedbackReportStart(state);  
        case actionTypes.TOTALFEEDBACK_REPORT_FAIL: return totalFeedbackReportFail(state, action); 
        case actionTypes.TOTALFEEDBACK_UTTARANCES_SUCCESS: return totalFeedbackUtterancesSuccess(state, action);   
        case actionTypes.TOTALFEEDBACK_UTTARANCES_START: return totalFeedbackUtterancesStart(state);  
        case actionTypes.TOTALFEEDBACK_UTTARANCES_FAIL: return totalFeedbackUtterancesFail(state, action); 
        case actionTypes.TOTALFEEDBACK_CATEGORIES_SUCCESS: return totalFeedbackCategoriesSuccess(state, action);   
        case actionTypes.SELECTED_CATEGORY: return selectedCategory(state, action);   
        case actionTypes.TOTALFEEDBACK_CATEGORIES_START: return totalFeedbackCategoriesStart(state);  
        case actionTypes.TOTALFEEDBACK_CATEGORIES_FAIL: return totalFeedbackCategoriesFail(state, action); 
        case actionTypes.TOTALINITIATFLOW_START: return totalInitiatFlowStart(state);   
        case actionTypes.TOTALINITIATFLOW_SUCCESS: return totalInitiatFlowSuccess(state, action);   
        case actionTypes.TOTALINITIATFLOW_FAIL: return totalInitiatFlowFail(state, action);   
        case actionTypes.TOTALCHATSESSION_START: return totalChatSessionStart(state);
        case actionTypes.TOTALCHATSESSION_SUCCESS: return totalChatSessionSuccess(state, action);
        case actionTypes.TOTALCHATSESSION_FAIL: return totalChatSessionFail(state, action); 
        case actionTypes.TOTALCHATSESSIONREPORT_START: return totalChatSessionReportStart(state);
        case actionTypes.TOTALCHATSESSIONREPORT_SUCCESS: return totalChatSessionReportSuccess(state, action);
        case actionTypes.TOTALCHATSESSIONREPORT_FAIL: return totalChatSessionReportFail(state, action); 
        case actionTypes.TOTAL_AGENT_TRANSFORMS_START: return totalAgentTransformsStart(state);
        case actionTypes.TOTAL_AGENT_TRANSFORMS_SUCCESS: return totalAgentTransformsSuccess(state, action);
        case actionTypes.TOTAL_AGENT_TRANSFORMS_FAIL: return totalAgentTransformsFail(state, action);  

        case actionTypes.TOTAL_AGENT_TRANSFER_REPORT_START: return totalAgentTransferReportStart(state);
        case actionTypes.TOTAL_AGENT_TRANSFER_REPORT_SUCCESS: return totalAgentTransferReportSuccess(state, action);
        case actionTypes.TOTAL_AGENT_TRANSFER_REPORT_FAIL: return totalAgentTransferReportFail(state, action);  

        case actionTypes.TOTAL_FLOW_REPORT_START: return totalFlowReportStart(state);
        case actionTypes.TOTAL_FLOW_REPORT_SUCCESS: return totalFlowReportSuccess(state, action);
        case actionTypes.TOTAL_FLOW_REPORT_FAIL: return totalFlowReportFail(state, action);  

        case actionTypes.TOTALREPEATUSERREPORT_START: return totalRepeatUserReportStart(state);
        case actionTypes.TOTALREPEATUSERREPORT_SUCCESS: return totalRepeatUserReportSuccess(state, action);
        case actionTypes.TOTALREPEATUSERREPORT_FAIL: return totalRepeatUserReportFail(state, action);  
        case actionTypes.TOTALNEWUSERREPORT_START: return totalNewUserReportStart(state);
        case actionTypes.TOTALNEWUSERREPORT_SUCCESS: return totalNewUserReportSuccess(state, action);
        case actionTypes.TOTALNEWUSERREPORT_FAIL: return totalNewUserReportFail(state, action);  
        case actionTypes.TOTALUNANSWEREDREPORT_START: return totalunansweredReportStart(state);
        case actionTypes.TOTALUNANSWEREDREPORT_SUCCESS: return totalunansweredReportSuccess(state, action);
        case actionTypes.TOTALUNANSWEREDREPORT_FAIL: return totalunansweredReportFail(state, action); 
        case actionTypes.AVGUSERSREPORT_START: return AvgUserReportStart(state);
        case actionTypes.AVGUSERSREPORT_SUCCESS: return AvgUserReportSuccess(state, action);
        case actionTypes.AVGUSERSREPORT_FAIL: return AvgUserReportFail(state, action);
        case actionTypes.REPORT_PAGE_ACTION: return ReportPageAction(state, action);
        case actionTypes.TOTALMEETINGSCOUNT_START: return totalMeetingsCountStart(state);
        case actionTypes.TOTALMEETINGSCOUNT_SUCCESS: return totalMeetingsCountSuccess(state, action);
        case actionTypes.TOTALMEETINGSCOUNT_FAIL: return totalMeetingsCountFail(state, action);
        case actionTypes.TOTALMEETINGSREPORT_START: return totalMeetingsReportStart(state);
        case actionTypes.TOTALMEETINGSREPORT_SUCCESS: return totalMeetingsReportSuccess(state, action);
        case actionTypes.TOTALMEETINGSREPORT_FAIL: return totalMeetingsReportFail(state, action);
        case actionTypes.TOTALINITIATEDBOTREPORT_START: return totalInitiatedBotReportStart(state);
        case actionTypes.TOTALINITIATEDBOTREPORT_SUCCESS: return totalInitiatedBotReportSuccess(state, action);
        case actionTypes.TOTALINITIATEDBOTREPORT_FAIL: return totalInitiatedBotReportFail(state, action);
        case actionTypes.TOTALANSCOUNT_START: return totalAnsUnansCountStart(state);
        case actionTypes.TOTALANSCOUNT_SUCCESS: return totalAnsUnansCountSuccess(state, action);
        case actionTypes.TOTALANSCOUNT_FAIL: return totalAnsUnansCountFail(state, action); 

        case actionTypes.MSGCATEGORY_START: return msgCategoriesStart(state);
        case actionTypes.MSGCATEGORY_SUCCESS: return msgCategoriesSuccess(state, action);
        case actionTypes.MSGCATEGORY_FAIL: return msgCategoriesFail(state, action);

        case actionTypes.MSGCATEGORYANS_START: return msgCategoriesAnsStart(state);
        case actionTypes.MSGCATEGORYANS_SUCCESS: return msgCategoriesAnsSuccess(state, action);
        case actionTypes.MSGCATEGORYANS_FAIL: return msgCategoriesAnsFail(state, action);

        case actionTypes.CONANSUNSCOUNT_START: return ConAnsUnsCountStart(state);
        case actionTypes.CONANSUNSCOUNT_SUCCESS: return ConAnsUnsCountSuccess(state, action);
        case actionTypes.CONANSUNSCOUNT_FAIL: return ConAnsUnsCountFail(state, action);

        case actionTypes.CONANSUNSCOUNTREPORT_START: return ConAnsUnsCountReportStart(state);
        case actionTypes.CONANSUNSCOUNTREPORT_SUCCESS: return ConAnsUnsCountReportSuccess(state, action);
        case actionTypes.CONANSUNSCOUNTREPORT_FAIL: return ConAnsUnsCountReportFail(state, action);

        case actionTypes.UNANSWERED_CAT_START: return totalUnansCatStart(state);
        case actionTypes.UNANSWERED_CAT_SUCCESS: return totalUnansCatSuccess(state, action);
        case actionTypes.UNANSWERED_CAT_FAIL: return totalUnansCatFail(state, action);

        case actionTypes.UNANSWERED_UTTERENCE_START: return getUnansUtterancesStart(state);
        case actionTypes.UNANSWERED_UTTERENCE_SUCCESS: return getUnansUtterancesSuccess(state, action);
        case actionTypes.UNANSWERED_UTTERENCE_FAIL: return getUnansUtterancesFail(state, action);

        case actionTypes.AGENTTRANSFER_UTTERENCE_START: return AgentTransUtterancesStart(state);
        case actionTypes.AGENTTRANSFER_UTTERENCE_SUCCESS: return AgentTransUtterancesSuccess(state, action);
        case actionTypes.AGENTTRANSFER_UTTERENCE_FAIL: return AgentTransUtterancesFail(state, action);

        case actionTypes.AGENTTRANSFER_CAT_START: return AgentTransferCatStart(state);
        case actionTypes.AGENTTRANSFER_CAT_SUCCESS: return AgentTransferCatSuccess(state, action);
        case actionTypes.AGENTTRANSFER_CAT_FAIL: return AgentTransferCatFail(state, action);

        case actionTypes.TOP_AGENTTRANSFER_START: return TopAgentTransferStart(state);
        case actionTypes.TOP_AGENTTRANSFER_SUCCESS: return TopAgentTransferSuccess(state, action);
        case actionTypes.TOP_AGENTTRANSFER_FAIL: return TopAgentTransferFail(state, action);

        case actionTypes.TOTAL_COGUSER_REPORT_START: return TotalCogUserReportStart(state);
        case actionTypes.TOTAL_COGUSER_REPORT_SUCCESS: return TotalCogUserReportSuccess(state, action);
        case actionTypes.TOTAL_COGUSER_REPORT_FAIL: return TotalCogUserReportFail(state, action);
        case actionTypes.TOTAL_TRIAGE_REPORT_START: return TotalTriageReportStart(state);
        case actionTypes.TOTAL_TRIAGE_REPORT_SUCCESS: return TotalTriageReportSuccess(state,action);
        case actionTypes.TOTAL_TRAIGE_REPORT_FAIL: return TotalTriageReportFail(state,action);

        case actionTypes.CONVERSATIONAL_METRICS_START: return conversationalDataStart(state);
        case actionTypes.CONVERSATIONAL_METRICS_SUCCESS: return conversationalDataSuccess(state,action);
        case actionTypes.CONVERSATIONAL_METRICS_FAIL: return conversationalDataFail(state,action);

        case actionTypes.COGNITIVE_METRICS_START: return cognitiveDataStart(state);
        case actionTypes.COGNITIVE_METRICS_SUCCESS: return cognitiveDataSuccess(state,action);
        case actionTypes.COGNITIVE_METRICS_FAIL: return cognitiveDataFail(state,action);

        case actionTypes.CONVERSATIONAL_FEEDBACK_START: return conversationFeedbackStart(state);
        case actionTypes.CONVERSATIONAL_FEEDBACK_SUCCESS: return conversationFeedbackSuccess(state,action);
        case actionTypes.CONVERSATIONAL_FEEDBACK_FAIL: return conversationFeedbackFail(state,action);

        case actionTypes.CONVERSATIONAL_NEGATIVE_UTTERANCE_START: return conFeedbackNegativeUtteranceStart(state);
        case actionTypes.CONVERSATIONAL_NEGATIVE_UTTERANCE_SUCCESS: return conFeedbackNegativeUtteranceSuccess(state,action);
        case actionTypes.CONVERSATIONAL_NEGATIVE_UTTERANCE_FAIL: return conFeedbackNegativeUtteranceFail(state,action);

        case actionTypes.CONVERSATIONAL_HANDLED_START: return conversationalHandledStart(state);
        case actionTypes.CONVERSATIONAL_HANDLED_SUCCESS: return conversationalHandledSuccess(state,action);
        case actionTypes.CONVERSATIONAL_HANDLED_FAIL: return conversationalHandledFail(state,action);
        
        case actionTypes.CONVERSATIONAL_MSG_UTTERANCE_START: return conversationMsgUtterancesStart(state);
        case actionTypes.CONVERSATIONAL_MSG_UTTERANCE_SUCCESS: return conversationMsgUtterancesSuccess(state,action);
        case actionTypes.CONVERSATIONAL_MSG_UTTERANCE_FAIL: return conversationMsgUtterancesFail(state,action);

        case actionTypes.CONVERSATIONAL_UNANS_UTTERANCE_START: return conUnansweredUtterancesStart(state);
        case actionTypes.CONVERSATIONAL_UNANS_UTTERANCE_SUCCESS: return conUnansweredUtterancesSuccess(state,action);
        case actionTypes.CONVERSATIONAL_UNANS_UTTERANCE_FAIL: return conUnansweredUtterancesFail(state,action);
        default:
            return state;
    }
};

export default reportDashboard;