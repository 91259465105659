import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import InputWithLabelComponent from "../../../../../../containers/SettingPage/SettingInnerPages/components/InputWithLabelComponent/InputWithLabelComponent";
import SelectCustom from "../../../../../../components/UIComponents/SelectDropdown/SelectComponent";
import BadgeCustom from "../../../../../../components/UIComponents/Badge/BadgeComponent";

const FileUpload = ({properties, onChangeHandler, invalidVarMsg, invalidSizeMsg}) => {

    const FileTypeList = [{value:'select',displayValue:'Select'},
                            {value:'All',displayValue:'All'},
                            {value:'pdf',displayValue:'pdf'},
                            {value:'jpg',displayValue:'jpg'},
                            {value:'png',displayValue:'png'},
                            {value:'doc',displayValue:'doc'}];

    const removeFileType = (type) => {
        onChangeHandler('fileType',properties.fileType.filter((item)=>item !== type));
    }

    const updateFileType = (value) => {
        let tempArray = [...properties.fileType];
        tempArray.push(value);
        onChangeHandler('fileType',tempArray);
    }
 
    return (
        <div>
            <div className="mt-1 settings-div">
                <Col md={12} >
                    <Row className='settings-form-switch'>
                        <Col md={6}><span className='settings-label'>Required</span></Col>
                        <Col md={6} className='settings-switch'>
                        <Form>
                                <Form.Check
                                    type="switch"
                                    id="isRequired"
                                    checked={properties.isRequired}
                                    onChange={e => onChangeHandler('isRequired',e.target.checked)}
                                />
                            </Form>
                        </Col>
                    </Row>
                </Col>
            </div>
            <div className="mt-1 settings-div">
                <Col md={12}>
                <InputWithLabelComponent
                        type="text"
                        name={'settings-id'}
                        label="Name*"
                        showLabel={true}
                        value={properties.id}
                        readOnly={false}
                        onChange={e => onChangeHandler('id',e.target.value)}
                        required={true}
                        className="settings-input"
                        isInvalid={invalidVarMsg!==null}
                        error={invalidVarMsg}
                        toolTip={true}
                        toolTipParams={{title:'Name will also be used as variables',placement:'right'}}
                  />
                </Col>
            </div>
            <div className="mt-1 settings-div">
                <Col md={12}>
                <InputWithLabelComponent
                        type="text"
                        name={'settings-label'}
                        label="Default Label*"
                        showLabel={true}
                        value={properties.label}
                        readOnly={false}
                        onChange={e => onChangeHandler('label',e.target.value)}
                        required={true}
                        className="settings-input"
                  />
                </Col>
            </div>
            <div className="mt-1 settings-div">
                <Col md={12}>
                    <SelectCustom
                        name="validation"
                        label="Select File Type"
                        className="settings-dropdown"
                        changed={e=>updateFileType(e.target.value)}
                        required={true}
                        value={'select'}
                        options={FileTypeList}
                    />
                    <div className='settings-div'>
                        {properties.fileType.map((item)=>
                        <BadgeCustom className='badgeWidth' key={item} label={item} clicked={()=>removeFileType(item)}/> )}
                    </div>
                    
                
                </Col>
                
            </div>
            <div className="mt-1 settings-div">
                <Col md={12}>
                <InputWithLabelComponent
                        type="number"
                        name={'settings-filesize'}
                        label="Maximum File Upload Size (in MB)"
                        showLabel={true}
                        value={properties.fileSize}
                        readOnly={false}
                        onChange={e => onChangeHandler('fileSize',e.target.value)}
                        required={true}
                        className="settings-input"
                        pattern={'[0-9]{2}'}
                        max={10}
                        min={1}
                        placeHolder='Enter number'
                        isInvalid={invalidSizeMsg!==null}
                        error={invalidSizeMsg}
                  />
                </Col>
            </div>
            <div className="mt-1 settings-div">
                <Col md={12}>
                <InputWithLabelComponent
                        type="text"
                        name={'settings-regex'}
                        label="Validation Information"
                        showLabel={true}
                        value={properties.validationInfo}
                        readOnly={false}
                        onChange={e => onChangeHandler('validationInfo',e.target.value)}
                        required={true}
                        className="settings-input"
                  />
                </Col>
            </div>
            <div className="mt-1 settings-div">
                <Col md={12}>
                    <InputWithLabelComponent
                        type="text"
                        name={'settings-default'}
                        label="Error Message"
                        showLabel={true}
                        value={properties.errorMessage}
                        readOnly={false}
                        onChange={e => onChangeHandler('errorMessage',e.target.value)}
                        required={true}
                        className="settings-input"
                    />
                </Col>
            </div>
            <div className="mt-1 settings-div">
                <Col md={12} >
                    <Row className='settings-form-switch'>
                        <Col md={9}><span className='settings-label'>Minimized version</span></Col>
                        <Col md={3} className='settings-switch'>
                        <Form>
                                <Form.Check
                                    type="switch"
                                    id="isMinimized"
                                    checked={properties.isMinimized}
                                    onChange={e => onChangeHandler('isMinimized',e.target.checked)}
                                    disabled={true}
                                />
                            </Form>
                        </Col>
                    </Row>
                </Col>
            </div>
            <div className="mt-1 settings-div">
                <Col md={12} >
                    <Row className='settings-form-switch'>
                        <Col md={9}><span className='settings-label'>Allow multiple file uploads</span></Col>
                        <Col md={3} className='settings-switch'>
                        <Form>
                                <Form.Check
                                    type="switch"
                                    id="isMultipleFiles"
                                    checked={properties.isMultipleFiles}
                                    onChange={e => onChangeHandler('isMultipleFiles',e.target.checked)}
                                />
                            </Form>
                        </Col>
                    </Row>
                </Col>
            </div>
            
        </div>
    )
}

export default FileUpload;