import React, { useState, useEffect } from "react";
import Drawer from "@material-ui/core/Drawer";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes, { object } from "prop-types";
import { Row, Col, Button, Form } from "react-bootstrap";

import DrawerHeaderContent from "../components/DrawerHeaderContent/DrawerHeaderContent";


import PrimaryButtonWithLoader from "../components/PrimaryButtonWithLoader/PrimaryButtonWithLoader";
import { PostCalltoServer,GetCalltoServer } from "../../../../store/utility";
import "./CompleteTriage.css";
import { get, isEqual } from "lodash";
import TextAreaWithLabelComponent from "../components/TextAreaWithLabelComponent/TextAreaWithLabelComponent";
import getProjectDetails from "../helpers/getProjectDetails";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  list: {
    width: "32rem",
  },
});
const CompleteTriage = ({
  showDrawer,
  toggleDrawer,
  triageSummary,
  issueDetials,
  uniqueIDnums,

  triageQuestion,
  Rootcause,
  

  selectedQuestions,
  selectedFilterData,
  appliedCheckboxes,
  
  config,
  toastFun,
  greetings,
  sucessmsg,
  custmoreResponse,
  startNewTriageHandler,
  timeStart,
  timeEnd,
}) => {
  const classes = useStyles();
  const projectName = getProjectDetails();
  const [questions, setQuestions] = useState([]);
  const [filtersData, setFiltersData] = useState({});
  const [saveLoading, setSaveLoading] = useState(false);
  const [confimMsg, setConfirmMsg] = useState(false);
  const [comments, setComments] = useState("");
  const [suggestedResolutions, setSuggestedResolutions] = useState("");
  const [ticketId, setTicketId] = useState("");
  const [gettingImgUrl, setGettingImgUrl] = useState([]);
  const [gettingVideoLink, setGettingVideoLink] = useState([]);
  const productAtt = useSelector((state) => state.agentInsights.filterData);
  const getResolution = useSelector((state) => state.agentInsights.recommendedSolutionData);
  const user = localStorage.getItem("userName");
  
  useEffect(() => {
    getQuestions(selectedQuestions);
  }, [selectedQuestions]);

  useEffect(() => {
    getSelectedFilter(selectedFilterData);
  }, [selectedFilterData]);

  useEffect(() => {
    
  }, [filtersData]);
  useEffect(() => {
    const url =
      config.INSIGHTS_API_URL +
      `/product-intelligent-triage/carear/get-image/${uniqueIDnums}`;
    GetCalltoServer(url)
      .then((response) => {
        if (get(response, "data.status.responseType", "") === "success") {
          setGettingImgUrl(response.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  useEffect(() => {
    const url =
      config.INSIGHTS_API_URL +
      `/product-intelligent-triage/carear/get-video-link/${uniqueIDnums}`;
    GetCalltoServer(url)
      .then((response) => {
          setGettingVideoLink(response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const selectedFilterDetails = (issue) =>
    Object.entries(issue).map(([key, value]) => {
      if (isEqual(typeof value, "object") && !isEqual(value, null)) {
        let vall = ""
        if(value && value.length!=0){
          vall = value.join(",")
        }
        return (
          <Col xs={6} className="">
            <h5 className="triage-sub-heading">{key.toUpperCase()}</h5>
            <p className="triage-details-data">{vall}</p>
          </Col>
        );
      }
      if (!isEqual(value, "")) {
        return (
          <Col xs={12} className="">
            <h5 className="triage-sub-heading">
              {isEqual(key, "description") ? issueDetials : key.toUpperCase()}
            </h5>
            <p className="triage-details-data">{value} </p>
          </Col>
        );
      }
    });

  const completeTriageHandler = () => {
    const url =
      config.INSIGHTS_API_URL +
      `/product-intelligent-triage/settings/producttriage/triagesummary`;
    const updatedFormData = {
      ...filtersData,
      triagequetions: questions,
      rootcause: appliedCheckboxes,
      suggestedResolutions:suggestedResolutions,
      comments:comments,
      project:projectName,
      recommendedsolutions: getResolution,
      endDate: timeEnd,
      startDate: timeStart,
      user: user,

    };
    setSaveLoading(true);
    PostCalltoServer(url, updatedFormData)
      .then((response) => {
        if (get(response, "status", "") === 200) {
          let ticketInfo = (response.data.data)
          setTicketId(get(ticketInfo, "_id", ""))
          setSaveLoading(false);
          setConfirmMsg(true);
        } else {
          setSaveLoading(false);
        }
      })
      .catch((err) => {
        toastFun(err, "error");
        setSaveLoading(false);
      });
      
  };
  const getQuestions = (selectedQuestions) => {
    selectedQuestions.map((item) => {
      setQuestions((prevState) =>
        prevState.concat(`${item.description}-${item.type}`)
      );
    });
  };

  const getSelectedFilter = (selectedFilterData) => {
    Object.entries(selectedFilterData).map(([key, value]) => {
        setFiltersData((currentQuantity) =>({
          ...currentQuantity,
          [key]: value,
        }));
    });
  };

  const changeHandler = (event, type) => {
      if(isEqual(type,"suggestedResolutions"))
         setSuggestedResolutions(event);
      else
         setComments(event);
  }

  return (
    <React.Fragment>
      {showDrawer ? (
        <Drawer
          anchor={"right"}
          open={showDrawer}
          onClose={toggleDrawer(false)}
        >
          <div
            className={clsx(classes.list, classes.fullList)}
            role="presentation"
          >
            { !confimMsg ?
            <DrawerHeaderContent
              heading={triageSummary}
              toggleDrawer={toggleDrawer}
              className="p-4"
            />:null}
            { !confimMsg ? (<div class="complete-triage-form">
            <div className="triage-container p-4">
               <div className="">
                <div className="pl-2 triage-response-product-data">
                {productAtt?.map(obj=> <Form.Label className="productatt-data">{ obj.attribute_name}</Form.Label>) }
                </div>
              </div> 
              <Col md={12} className="p-2">
                
                <h5 className="triage-sub-heading">{triageQuestion}</h5>
                {selectedQuestions.length > 0
                  ? selectedQuestions.map((item) => {
                      return (
                        <p className="triage-details-data" style={{marginBottom:"0.15rem"}}>
                          {item.description} -  <img style={{width:"20px",height:"20px"}} src={`images/${item.type}.svg`} />
                        </p>
                      );
                    })
                  : null}
              </Col>
              <Col md={11} className="p-2">
              {appliedCheckboxes.length > 0 ? <h5 className="triage-sub-heading">{Rootcause}</h5> : null }
                {appliedCheckboxes.length > 0 ? (
                  appliedCheckboxes.map((item) => {
                    return <p className="triage-details-data">{item} </p>;
                  })
                ) : (
                  <TextAreaWithLabelComponent
                    label={Rootcause}
                    name="suggestedResolutions"
                    required={true}
                    value={suggestedResolutions}
                    className={""}
                    placeholder={"Enter Resolutions ..."}
                    onChange={(e) => changeHandler(e.target.value, "suggestedResolutions")}
                  />
                )}
              </Col>
              <Col md={11} className="p-2">
                <TextAreaWithLabelComponent
                  label={"COMMENTS"}
                  name="comments"
                  required={true}
                  value={comments}
                  className={""}
                  placeholder={"Enter Comments ..."}
                  onChange={(e) => changeHandler(e.target.value, "comments")}
                />
              </Col>
              <Col md={11} className="p-2">
              <h5 className="triage-sub-heading">{"Images and Videos"}</h5>
              <br />
                {gettingImgUrl && gettingImgUrl.length && gettingImgUrl.map(obj => (
                     <img className="careAr-img" src={`data:image/png;base64,${obj.imageValue} `}/>
               ))}
               {gettingVideoLink && gettingVideoLink.length && gettingVideoLink.map(item => (
                <a href={item} target="_blank"><img className="careAr-img" src = {"./images/thumbnail.JPG"} /></a>
                
               ))}
              </Col>
            </div>
            <div className="">
              <Col md={12} className="">
                {/* <p className="triage-fotter">
                  We don’t have any ticket associated with this issue, Do you
                  want to create a ticket ?
                </p> */}
                <Row>
                  <Col xs={12}>
                    <div className=" d-flex justify-content-start end-triage-btn-container">
                      {/* <SecondaryButtonWithLoader
                        size="sm"
                        className="w-56 h-32"
                        onClick={toggleDrawer(false)}
                        text="no"
                      /> */}
                    <Button
                        variant="primary"
                        size="md"
                        style={{ backgroundColor: '#FF381D' }}
                        onClick={completeTriageHandler}
                        text="CONFIRM DETAILS & END TRIAGE"
                        loading={saveLoading}
                        active
                        className="end-triage-btn"
                      >
                        {"CONFIRM DETAILS & END TRIAGE"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </div>
            </div>)
             : (<div class="complete-triage-confirmation-screen">
            <div className="p-4">
              <Col xs={12} className="d-flex align-items-center">
                <img
                  className="pb-1"
                  alt="Green tick mark"
                  src="./images/Greeting.png"
                />
                <h1 className="pl-2 complete-triage-greeting">{greetings}</h1>
              </Col>
              <Col className="pl-4 pt-2">
                <h4 className="triage-greeting-text">{sucessmsg}</h4>
                <h4 className="triage-greeting-text">{"Ticket no : "}{ticketId}</h4>
              </Col>
              <Col className="p-4">
                <h2 className="triage-rateing">{"Rate Us"}</h2>
                <h4 className="triage-greeting-text">{custmoreResponse}</h4>
              </Col>
              <Col>
                <img
                  className="pr-3"
                  alt="symbol for ratting"
                  src="./images/Ratting2.png"
                />
                <img
                  className="pr-3"
                  alt="symbol for ratting"
                  src="./images/Rating3.png"
                />
                <img alt="symbol for ratting" src="./images/Ratting4.png" />
              </Col>
              <Col className="mt-3">
              <PrimaryButtonWithLoader
                        size="lg"
                        className="ml-2 h-32 ml-1"
                        //onClick={startNewTriageHandler}
                        onClick={() => {
                          startNewTriageHandler('reset');
                        }} 
                        text="Start New Triage"
                      />
              </Col>
            </div>
            </div>) }
          </div>
        </Drawer>
      ) : null}
    </React.Fragment>
  );
};
CompleteTriage.propTypes = {
  triageSummary: PropTypes.string,
  issueDetials: PropTypes.string,
  product: PropTypes.string,
  modal: PropTypes.string,
  triageQuestion: PropTypes.string,
  Rootcause: PropTypes.string,
  resolution1: PropTypes.string,
  resolution2: PropTypes.string,
  greetings: PropTypes.string,
  sucessmsg: PropTypes.string,
  custmoreResponse: PropTypes.string,
};

CompleteTriage.defaultProps = {
  triageSummary: "Triage Summary",
  issueDetials: "ISSUE DETAILS",
  product: "PRODUCT",
  modal: "MODEL",
  triageQuestion: "TRIAGE RESPONSES",
  Rootcause: "RESOLUTIONS",
  resolution1: "RESOLUTION 1",
  resolution2: "RESOLUTION 2",
  greetings: "Congratulations !",
  sucessmsg: " Your ticket has been successfully created",
  custmoreResponse: "We love to hear from you ! How is your experience?",
};

export default CompleteTriage;
