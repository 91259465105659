import React, { useState, useEffect } from "react";
import Drawer from "@material-ui/core/Drawer";
import get from 'lodash/get';
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import "./SymptomAndRootCause.css";
import { Col, Form, Row } from 'react-bootstrap';
import { useDispatch } from "react-redux";
import rootCauseDetailsUpdateHandler from "../../../../../../store/actions/adminSettings/rootCauseDetailsUpdateHandler";
import { isEmpty, isEqual } from "lodash";
import { deleteCalltoServer, UpdateCalltoServer } from "../../../../../../store/utility";
import PrimaryButtonWithLoader from "../../../components/PrimaryButtonWithLoader/PrimaryButtonWithLoader";
import SecondaryButtonWithLoader from "../../../components/SecondaryButtonWithLoader/SecondaryButtonWithLoader";
import DrawerHeaderContent from "../../../components/DrawerHeaderContent/DrawerHeaderContent";
import InputWithLabelComponent from "../../../components/InputWithLabelComponent/InputWithLabelComponent";
import rootCauseDetailsDeleteHandler from "../../../../../../store/actions/adminSettings/rootCauseDetailsDeleteHandler";
import { SetErrorMessage, SetSuccessMessage } from "../../../../../../store/actions";
import getProjectDetails from "../../../helpers/getProjectDetails";


const useStyles = makeStyles({
  list: {
    width: 500,
  }
});

const RootCausesDetails = ({
  rootCause,
  config,
  showDrawer,
  toggleDrawer,
  rootcausesheading,
  rootcausetitle,
  rootcause,
  closeDrawer,
  toastFun,
  id
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const projectName = getProjectDetails();
  const [saveLoading, setSaveLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [isInvalid, setInValid] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false)
  const [formData, setFormData] = useState({
    rootcause:'',
    leadingQuestion: '',
    productattribute1: '',
    productattribute2: '',
    rootcause_title: ""
  });

  useEffect(() => {
    setFormData(
      {
        rootcause: get(rootCause, 'rootcause', ''),
        rootcause_title: get(rootCause, 'rootcause_title', ''),
        productattribute1: get(rootCause, 'productattribute1', ''),
        productattribute2: get(rootCause, 'productattribute2', ''),
      }
    )
  }, [rootCause]);

  const changeHandler = (name) => (event) => {
    if(!isEqual(event.target.value,""))
       setInValid(false);
    setFormData(prevData => ({
      ...prevData,
      [name]: event.target.value
    }))
  };

  const deleteHandler = () => {
    setDeleteLoading(true);
    const url =`${config.INSIGHTS_API_URL}/product-intelligent-triage/settings/rootcause`;
    const deletedRootcause ={
      "rootcause": formData.rootcause,
      "project": projectName,
    }
    deleteCalltoServer(url,deletedRootcause)
    .then((response) => {
      if (get(response, "data.status.responseType", "") === "success") {
        dispatch(rootCauseDetailsDeleteHandler({
          formData,
          id
        }));
        setDeleteLoading(false);
        closeDrawer();
        toastFun('Root cause deleted Successfully', "success");
      } else {
        setDeleteLoading(false);
      }
    })
    .catch((error) => {
      toastFun(error, "error");
      setDeleteLoading(false);
    })
  }

  const saveHandler = (e) => {
    if(!isEqual(formData.rootcause_title,"")){
    const newForm = {
        "symptoms": "",
        "symptom_title": "",
        "relevency": "",
        "rootcause": formData.rootcause,
        "rootcause_title": formData.rootcause_title,
        "leadingquestion" : "",
        "project": projectName,
        "status": ""
      }
    const url =`${config.INSIGHTS_API_URL}/product-intelligent-triage/settings/rootcause`;
    setSaveLoading(true);
    UpdateCalltoServer(url,newForm)
      .then((response) => {
        setSaveLoading(false);
        if (get(response, "data.status.responseType", "") === "success") {
          dispatch(rootCauseDetailsUpdateHandler({
            formData,
            id
          }));
          setSaveLoading(false);
          closeDrawer();
          toastFun('Root cause saved Successfully', "success");
        } else {
          setSaveLoading(false);
        }
      })
      .catch((error) => { 
        setSaveLoading(false);
        toastFun(error, "error");
       });
    }else{
      setInValid(true);
    }
  };


  const getAttributesData = (data, type) => {
    const selectedSourceFields = get(data, type, []);
    if (isEmpty(selectedSourceFields)) {
      return '--'
    }

    return selectedSourceFields.toString();
  };

  const getRootCauseAttributes = (rootCause) => Object.entries(rootCause).map(([key, value]) => {
    if (Array.isArray(value)) {
        return (
          <div className="mt-3">
            <Col md={12}>
              <InputWithLabelComponent
                type="text"
                className="disable-border-colr"
                name={key}
                label={key}
                value={getAttributesData(rootCause, key)}
                readOnly={true}
                helpIcon={false}
              />
            </Col>
          </div>
        );
    }
    return null;
});


const deleteHandlerConfirmation = () => {
  setDeleteConfirmation(!deleteConfirmation);
}

  return (
    <React.Fragment>
      {showDrawer ?
        <Drawer
          anchor={"right"}
          open={showDrawer}
          onClose={toggleDrawer(false)}
        >
          <div
            className={clsx(classes.list, classes.fullList)}
            role="presentation"
          >
            <DrawerHeaderContent heading= {rootcausesheading} toggleDrawer={toggleDrawer} />
            <div className="d-flex flex-column rootCauses-Item mb-4 mt-4">
              <div className="mt-1 mb-2">
                <Col md={12}>
                  <InputWithLabelComponent
                    type="text"
                    className="disable-border-colr"
                    name="rootcause"
                    label={rootcause}
                    value={get(formData, "rootcause", "")}
                    readOnly={true}
                    helpIcon={false}
                  />
                </Col>
              </div>
              <div className="mt-3">
                <Col md={12}>
                  <InputWithLabelComponent
                    type="text"
                    className=""
                    name="rootcausetitle"
                    label={rootcausetitle}
                    value={get(formData, "rootcause_title", "")}
                    required={true}
                    onChange={changeHandler("rootcause_title")}
                    isInvalid={isInvalid}
                    error ={ isInvalid ? "Please Enter RootCause Title" : null}
                    helpIcon={false}
                  />
                </Col>
              </div>
              {getRootCauseAttributes(rootCause)}
             </div>
             { !deleteConfirmation ? <div className="d-flex pt-3 flex-row button-content">
              <SecondaryButtonWithLoader
                className="ml-5"
                size="lg"
                text="delete"
                onClick={deleteHandlerConfirmation}
              />
              <PrimaryButtonWithLoader
                className="ml-4"
                onClick={saveHandler}
                size="lg"
                text="save"
                loading={saveLoading}
              />
            </div> : null }
            { deleteConfirmation ? (<>
              <Row className="ml-5">
              <Form.Label className="text-box-title adding-other-input">{" Do you really want to delete Symptom"}</Form.Label>
              </Row>
            <div className="d-flex pt-3 flex-row button-content">
  
              <SecondaryButtonWithLoader
                className="ml-5"
                size="lg"
                text="No"
                onClick={deleteHandlerConfirmation}
              />
              <PrimaryButtonWithLoader
                className="ml-4"
                onClick={deleteHandler}
                size="lg"
                text="yes"
                loading={deleteLoading}
              />
            </div> </>) : null }
          </div>
        </Drawer> : null}
    </React.Fragment>
  );
};

RootCausesDetails.propTypes = {
  rootcausesheading: PropTypes.string,
  rootcause: PropTypes.string,
  rootcausetitle: PropTypes.string,
  productattribute1: PropTypes.string,
  productattribute2: PropTypes.string
};

RootCausesDetails.defaultProps = {
  rootcausesheading: "Root Cause Details",
  rootcause: "Root Cause",
  rootcausetitle: "Root Cause Title",
  productattribute1: "Product Attribute 1",
  productattribute2: 'Product Attribute 2'
};

export default RootCausesDetails;
