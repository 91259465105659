import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

const disableDataMappingAndDataSyncTabshelper = (state, selectedSubMenu, disableDataTabs) => {
    const connectionsData = get(state, 'projectview.connectionsData', []);
    if(isEmpty(connectionsData)) {
        return true;
    }
    if(!disableDataTabs) {
        return false;
    }
    const selectedConnectionsData = connectionsData.find(item => item._id.includes(selectedSubMenu));
    const selectedSourceConnectionData = get(selectedConnectionsData, '_source.connection', []);
    const selectedLastVerifiedDate = get(selectedSourceConnectionData, 'lastVerifiedDate', '');
    return isEmpty(selectedLastVerifiedDate) || isEmpty(selectedSourceConnectionData) ?
        true :
        false;
};

export default disableDataMappingAndDataSyncTabshelper;