import React, { Component, useEffect, useState } from 'react';
import '../../Insight/AdminConfiguration/AdminHome.css'
import CloseIcon from "@material-ui/icons/Close";
import Drawer from "@material-ui/core/Drawer";
import ReactMultiSelectComponent from '../../SecheduleMeeting/ReactSelectComponent';
import './CrmCustomerDataDrawer.css';
import ButtonComponent from '../../../../../components/UIComponents/Button/ButtonComponent';


const CrmCustomerDataDrawer = ( props) => {
    const [options , setOptions] = useState(["Name","Mobile No","Email Id"]);
    const [prevSelectedField,setPrevSelectedFiled] = useState([]);
    const [selectedField , setSelectedFiled] = useState([]);
    
   const  onSelecField = (event) =>{
        var temp = []
        for(var i=0;i<event.length;i++){ 
                temp.push(event[i].value)
        }
          setPrevSelectedFiled(selectedField);
          setSelectedFiled(temp);
      }
  const toggleDrawer = (value) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    props.onSetShowDrawer2()
  };
    return(
        <React.Fragment >
        <Drawer
          anchor={"right"}
          open={props.showDrawer}
          onClose={toggleDrawer(false)}
        >
            {props.showDrawer == true ? 
            <div className="d-flex pt-4 pl-2 maindiv">
                <div className='d-flex product-data'>
              <CloseIcon
                className="d-flex product-img "
                onClick={toggleDrawer(false)}
                style={{ fontSize: 25,marginLeft:"-3px"}}
              />
            <div className="d-flex pl-1" md={6}>
              <h3>Configure Customer Data for Intelligent Triage</h3>
            
            </div>
                </div>
            
            <div className ='d-flex pl-3'>
          <ReactMultiSelectComponent
          //  name="selectName"
           label="Choose fields for Customer Data for Agent Intelligent Triage"
           onChange={(event) => onSelecField(event)}
           value={selectedField}
           options={options.map(item =>(
            {value:item,label:item}))}
            // onRemoveItem={this.onRemoveCountryList}
            placeholder={'Select field'}

          /> 
           </div>
           <div className ='d-flex pl-1 ButtonComponent '>
           <ButtonComponent
                  variant='contained'
                  label='Save'
                  className='buttonWidth mb-2'
                //   clicked={this.submitKnowledgeHandler} 
                  />
           </div>
          </div> 
         
           : null }
          
        </Drawer>
      </React.Fragment>
    )
   
}

export default CrmCustomerDataDrawer;
 