import React, { useState , useEffect} from 'react';
import { Row, Col } from 'react-bootstrap';

import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import './insights-results.css';
import SimilarIssuesTab from './SimilarIssuesTab';

import IssueClustersTab from './IssueClusters/IssueClustersTab';
import RecommendedSolutionsTab from './RecommendedSolutionsTab';
import setSelectedInsightsTabAction from '../../../../../../store/actions/agentInsights/setSelectedInsightsTabAction';
import { useDispatch, useSelector } from 'react-redux';
import TabsComponent from '../../../components/TabsComponent/TabsComponent';
import getInsightsTabItems from '../../helpers/getInsightsTabItems/getInsightsTabItems';
import PCognitive from '../../../../../../cognitiveSearch/PCognitive';

const InsightsResults = ({
    results,
    config,
}) => {
    const dispatch = useDispatch();
    const [selectedTab, setSelectedTab] = useState('recommendedSolutions');

    const onTabChange = (selected) => {
        dispatch(setSelectedInsightsTabAction(selected));
        setSelectedTab(selected);
    };
   
    const similartickets = useSelector(state => state.insights.similartickets.enabled);
    const topresolutions = useSelector(state => state.insights.topresolutions.enabled);
    const issueclassification = useSelector(state => state.insights.issueclassification.enabled);
    const knowledgeArticles = useSelector(state => state.insights.knowledgeArticles.enabled);
    const tabsStatus = {"similartickets":similartickets,"topresolutions":topresolutions,"issueclassification":issueclassification,"knowledgeArticles":knowledgeArticles}

    const clusterData = useSelector(state => state.agentInsights.clustersData);
    const agentInsightsData = useSelector(state => state.agentInsights.agentInsightsData);
    const recommendedSolutionData = useSelector(state => state.agentInsights.recommendedSolutionData);
    const similarIssuesData = get(agentInsightsData, 'responsedata', []); 


    // useEffect(() => {
    //     if(topresolutions) setSelectedTab('similarIssues')
    //     if(similartickets) setSelectedTab('issueClusters')
    //     if(issueclassification) setSelectedTab('knowledgeArticles')
    //     if(knowledgeArticles) setSelectedTab('recommendedSolutions')
    // }, []);
    
    useEffect(() => {
        if(knowledgeArticles) setSelectedTab('knowledgeArticles') //knowledgeArticles
        if(issueclassification) setSelectedTab('issueClusters') //issueClusters
        if(similartickets) setSelectedTab('similarIssues') //similarIssues
        if(topresolutions) setSelectedTab('recommendedSolutions') //recommendedSolutions
    }, []);
    

    const [recommendedSolutionCount, setRecommendedSolutionCount] = useState(recommendedSolutionData);
    const [similarIssuesCount, setSimilarIssuesCount] = useState(similarIssuesData);
    const [clusterCount, setClusterCount] = useState(clusterData);

    useEffect(() => {
        setRecommendedSolutionCount(recommendedSolutionData)
        setSimilarIssuesCount(similarIssuesData)
        setClusterCount(clusterData)
    }, [clusterData,recommendedSolutionData,agentInsightsData]);
    // },[]);
    
    const updateCounts = (type, value) => {
        if(isEqual(type,"recommendedSolutions"))
           setRecommendedSolutionCount(value)
        if(isEqual(type,"similarIssues"))
           setSimilarIssuesCount(value)
        if(isEqual(type,"issueClusters"))
           setClusterCount(value)
    }
  //  const tabItems = getInsightsTabItems(similarIssuesCount, clusterCount, recommendedSolutionCount); 
  const tabItems = getInsightsTabItems(similarIssuesCount, clusterCount, recommendedSolutionCount,tabsStatus);
    return (
        <React.Fragment>
            <Row>
                <Col xs={12} className='insight-tabs-container'>
                    <TabsComponent className='insight-tabs' tabItems={tabItems}
                        selected={selectedTab}
                        setSelected={onTabChange}>
                        {selectedTab === 'recommendedSolutions' ?
                            <RecommendedSolutionsTab config={config}  updateCounts={updateCounts} />
                            : null
                        }
                        {selectedTab === 'similarIssues' ?
                            <SimilarIssuesTab config={config} updateCounts={updateCounts} />
                            : null
                        }
                        {selectedTab === 'issueClusters' ?
                            <IssueClustersTab config={config}  updateCounts={updateCounts} />
                            : null
                        }
                       
                        {selectedTab === 'knowledgeArticles' ?
                            <PCognitive config={config} knowledgeSearch={13}/>
                            : null
                        }
                    </TabsComponent>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default InsightsResults;
