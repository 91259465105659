import React from 'react';
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Bar } from 'react-chartjs-2';
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  );
  
  ChartJS.defaults.borderColor = "#655B96";
  class HorizontalBarChart extends React.Component {


    constructor(props) {
      super(props);  
    }

  
    render() {

      let labels = this.props.labels
      const dataHorBar = {
        labels: labels,
        datasets: [
          {
           backgroundColor: this.props.bgColor,
           // hoverBackgroundColor: 'rgb(107,92,165,0.7)',
           // hoverBackgroundColor: 'rgb(107,92,165,0.7)',
            data: this.props.dataHorBar,
            
          }
        ]
      };
      const that = this;
     let HorOptions = {
      onClick: function(evt, element) {
        const { index } = element[0];
        let msg = dataHorBar.labels[index];
        that.props.clickEvent(evt,element,msg)
        },
        indexAxis: 'y', 
        responsive: true,
        plugins: {
          legend: {
            display: false
          },
          datalabels: {
            display: true,
            color: "black",
            anchor: 'end',
            align: 'end',
              font:{
                weight:700
              }
          }
        },
        scales: {
          x: {
            grid: {
              display: false,
              drawOnChartArea: false,
              drawBorder: false,
            },
            ticks: {display: false}
          },
          y: {
            grid: {
              display: false,
              drawOnChartArea: false,
              drawBorder: false
            },
            ticks: {mirror: true,
              color: (c)=>{return (c.tick.label==that.props.selected)?this.props.seltextColor:'#000'} , 
              z: 1}
          },
  
        },
        layout: {
          padding: {
              right: 30
          }
        }
      };
  
  
      return (
        <>
            <Bar options={HorOptions} data={dataHorBar} plugins={[ChartDataLabels]}/>
        </>
      )
    }
  }
  export default HorizontalBarChart;  