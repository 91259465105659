import React, { useState, useEffect } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { useDrop } from 'react-dnd';
import TextField from './InputFields/TextField';
import './formStyles.css';
import * as inputTypes from './InputTypes';
import ButtonCustom from "../../../../../components/UIComponents/Button/ButtonComponent";
import Settings from './Settings';
import { useSelector, useDispatch } from "react-redux";
import MultiSelect from './InputFields/MultiSelect';
import debounce from "lodash/debounce";
import RadioButton from './InputFields/RadioButton';
import Dropdown from './InputFields/Dropdown';
import Heading from './InputFields/Heading';
import TextArea from './InputFields/TextArea';
import InputWithLabelComponent from "../../../../../containers/SettingPage/SettingInnerPages/components/InputWithLabelComponent/InputWithLabelComponent";
import FileUpload from './InputFields/FileUpload';
import Text from './InputFields/Text';
import Image from './InputFields/Image';
import ContactNum from './InputFields/ContactNum';
import Calender from './InputFields/Calender';
import Consent from './InputFields/Consent';
import { UpdateCuxFormActiveField } from '../../../../../store/actions';

const FormPlayground = ({node, data, handleSubmit, setVar, variable, invalidMsg, disableFlag, setDisableFlag}) => {

    const currentActiveField = useSelector(state => {
        return state.projectview.CuxFormActiveField;
    });

    const getIndex = () => {
        let index = fields.findIndex((item) => item.index === currentActiveField); 
        return index  === -1 ? 0 : index; 
    }

    const dispatch = useDispatch();

    const [fields, setFields] = useState(data.formInputs);
    const [buttonText, setButtonText] = useState(data.formSubmitBtn);
    const [saveTemplate, setSaveTemplate] = useState(false);
    const [buttonMsg, setButtonMsg] = useState(data.formSubmitMsg);
    const [skipBtnText, setSkipBtnText] = useState(node?.formSkipBtnText?node.formSkipBtnText:'');
    const [skipBtnMsg, setSkipBtnMsg] = useState(node?.formSkipBtnMsg?node.formSkipBtnMsg:'');
    const [isInputCapture, setIsInputCapture] = useState(false);
    let defaultBtn = {btnText:'',displayText:''};
    const [buttonDataList, setButtonDataList] = useState(node?.buttonDataList?node.buttonDataList:[]);
    const [validItemList, setValidItemList] = useState([]); //updated when each individual form component is saved
    const [addToLog, setAddToLog] = useState(node.addToLog!==undefined? node.addToLog:true);

    useEffect(()=> {
        if(data.formInputs[0])
            dispatch(UpdateCuxFormActiveField(data.formInputs[0].index));

        /*if the node is an existing one which is opened, then the form was a 
        valid form, hence the save btn should be enabled by default, hence updating the validItemList */
        if(fields.length > 0) { 
            let tempList = [];
            fields.map((item)=> tempList.push({index:item.index, isValid:true}))
            setValidItemList(tempList);
        }
    },[]);

    function isInputType(type) {
        switch(type) {
            case inputTypes.TEXTFIELD:
            case inputTypes.CHECKBOX:
            case inputTypes.MULTISELECT:
            case inputTypes.RADIOBUTTON:
            case inputTypes.DROPDOWN:
            case inputTypes.TEXTAREA:
            case inputTypes.FILEUPLOAD:
            case inputTypes.CONTACT_NUM:
            case inputTypes.CONSENT:
            case inputTypes.CALENDER: return true;
            case inputTypes.TEXTBLOCK:
            case inputTypes.HEADING:
            case inputTypes.TEXT:
            case inputTypes.IMAGE: return false;
        }
    }
  
    useEffect(()=>{
        for(let i = 0; i < fields.length; i++){
            if(isInputType(fields[i].type)){
                setIsInputCapture(true);
                break;
            }
            setIsInputCapture(false);
        }
        if(fields.length === 0) setIsInputCapture(false);
    },[fields]);

    const getNewIndex = (fieldsArray) => {
        let maxIndex = fieldsArray.reduce(function(max, field) {
            return field.index > max.index? field : max;
          });
        return maxIndex.index + 1;
    }
    
    const [, drop] = useDrop(()=> ({
        accept: inputTypes.FORM_INPUT,
        drop: (item) => {
            setFields((prev)=>[...prev, {...item, index:prev.length>0?getNewIndex(prev):0}]);
        }
    }));

    const onChangeHandler = (index) => (e) => {
        setFields((curr)=>
        curr.map((field)=> {
            const target = e.target;
            const label = target.value;
            return index === field.index ? { ...field, label} : field;
        })
        )
    }

    const deleteHandler = (index) => {
        setFields(fields.filter((item) => item.index !== index));
        setValidItemList(validItemList.filter((item)=>item.index !== index));
    }

    const saveForm = () => {
        setVar(variable);
        setDisableFlag(disableFlag);
        let formData = {
            fields: fields,
            buttonText: buttonText===''?undefined:buttonText,
            buttonMsg: buttonMsg===''?undefined:buttonMsg        
        }
        let extraBtns = {
            skipBtnText: skipBtnText===''?undefined:skipBtnText,
            skipBtnMsg: skipBtnMsg===''?undefined:skipBtnMsg,
            buttonDataList: buttonDataList.length === 0 ? undefined:buttonDataList
        }
        let submitData = {
            formData: formData,
            extraBtns: extraBtns,
            template: saveTemplate,
            addToLog: addToLog
        }
        handleSubmit('forms',submitData);
    }

    const updateSaveBtnStatus = (index) => {
        let tempItemList = [...validItemList];
        if(tempItemList[index])
                tempItemList[index].isValid = true;
        else {
            tempItemList.push({index:index, isValid: true})
        }
        setValidItemList(tempItemList)
    }

    const handleSettingSave = (items) => {
        let index = getIndex();
        let tempArray = [...fields];
        tempArray[index].properties = items;
        setFields(tempArray);
        updateSaveBtnStatus(index);
    }

    const moveInputFields = debounce((dragIndex, hoverIndex) => {
        const dragItem = fields[dragIndex];
        if(dragItem) {
            setFields((prevState => {
                const copiedState = [...prevState];
                const prevItem = copiedState.splice(hoverIndex,1,dragItem);
                copiedState.splice(dragIndex,1,prevItem[0]);
                return copiedState;
            }))
        }
    },50)

    const isFormValid = () => {
        let validStatus = variable && !invalidMsg && fields.length > 0 && fields.length === validItemList.length;
        if(isInputCapture)
            validStatus = validStatus && buttonText;

        return validStatus;
    }

    const updateButtonCount = () => {
        setButtonDataList((prev)=>[...prev,defaultBtn]);
    }

    function reduceCount(index) {
        setButtonDataList(buttonDataList.filter((_, i) => i !== index)); 
    }

    const updateButtonDetails = (index, key, value) => {
        let tempArray = [...buttonDataList];
        tempArray[index] = {...tempArray[index], [key]:value};
        setButtonDataList(tempArray);
    }

    function renderButtonInputs(index, item) { 
        return (
            <div id='optionsDiv' className='backgroundBox' key={'buttonInput'+index}>
            <div className="mt-1">
            <Col md={12}>
            <Row className='pt-1 row-ht-formBtn'>
                <Col md={2} className='button-LW'>
                <Form.Label className="mb-1 text-box-title adding-other-input">{'Button '.concat(index+1) }</Form.Label>
                </Col>
                <Col md={1} className='countBtn-col minusBtn-align'>
                    <div onClick={()=>reduceCount(index)}>
                        <img className='btn-more-btn removeBtn-img' src="./Icons/removeCircleBlack.svg"/>  
                    </div>    
                </Col>
            </Row> 
            <Row>
                <Col md={12}>
                    <Row>
                    <Col md={6}>
                        <InputWithLabelComponent
                            type="text"
                            name={item.btnText}
                            showLabel={false}
                            value={item.btnText}
                            readOnly={false}
                            onChange={(e) => updateButtonDetails(index,'btnText',e.target.value)}
                            required={true}
                            placeHolder='Button Text'
                        />
                        
                    </Col>
                    <Col md={6}>
                        <InputWithLabelComponent
                            type="text"
                            name={item.displayText}
                            showLabel={false}
                            value={item.displayText}
                            readOnly={false}
                            onChange={(e) => updateButtonDetails(index,'displayText',e.target.value)}
                            required={true}
                            placeHolder='Display Text'
                        />
                    </Col>
                    </Row>
                </Col>
            </Row>
            </Col>
            </div>
            </div>
        )
    }

    function displayComponent (field, index) {
        switch(field.type) {
            case inputTypes.TEXTFIELD: return (
                <TextField key={index} data={field} index={index} onChangeHandler={onChangeHandler} 
                moveInputFields={moveInputFields}/>
            )
            case inputTypes.TEXTAREA: return (
                <TextArea key={index} data={field} index={index} onChangeHandler={onChangeHandler} 
                moveInputFields={moveInputFields}/>
            )  
            case inputTypes.RADIOBUTTON: return (
                <RadioButton key={index} data={field} index={index} onChangeHandler={onChangeHandler} 
                moveInputFields={moveInputFields}/>
            )  
            case inputTypes.MULTISELECT: return (
                <MultiSelect key={index} data={field} index={index} onChangeHandler={onChangeHandler} 
                moveInputFields={moveInputFields}/>
            )
            case inputTypes.DROPDOWN: return (
                <Dropdown key={index} data={field} index={index} onChangeHandler={onChangeHandler} 
                moveInputFields={moveInputFields}/>
            ) 
            case inputTypes.HEADING: return (
                <Heading key={index} data={field} index={index} onChangeHandler={onChangeHandler} 
                moveInputFields={moveInputFields}/>
            )  
            case inputTypes.TEXT: return (
                <Text key={index} data={field} index={index} onChangeHandler={onChangeHandler} 
                moveInputFields={moveInputFields}/>
            )    
            case inputTypes.FILEUPLOAD: return (
                <FileUpload key={index} data={field} index={index} onChangeHandler={onChangeHandler} 
                moveInputFields={moveInputFields}/>
            )    
            case inputTypes.IMAGE: return (
                <Image key={index} data={field} index={index} onChangeHandler={onChangeHandler} 
                moveInputFields={moveInputFields}/>
            )    
            case inputTypes.CONTACT_NUM: return (
                <ContactNum key={index} data={field} index={index} onChangeHandler={onChangeHandler} 
                moveInputFields={moveInputFields}/>
            )  
            case inputTypes.CALENDER: return (
                <Calender key={index} data={field} index={index} onChangeHandler={onChangeHandler} 
                moveInputFields={moveInputFields}/>
            )
            case inputTypes.CONSENT: return (
                <Consent key={index} data={field} index={index} onChangeHandler={onChangeHandler} 
                moveInputFields={moveInputFields}/>
            ) 
        }
    }

    return (
        <>
        <div className='dnd-outer-box'>
        <div className='dnd-inner-box' ref={drop} >
            <Row>
                {fields.length === 0 && (
                    <Col md={12} id='form-emptyText'>
                        Drag & drop nodes here
                    </Col>
                ) }
                {fields.length > 0 && (
                    <Col md={6}>
                    {fields.map((field, index)=> {
                        return displayComponent(field, index);
                    }                    
                    )}
                    </Col>
                )}
            {fields.length > 0 && fields[0].properties && (
                <Col md={6}>
                    <div className='form-setting-box'>
                        <Settings fields={fields}
                        handleSettingSave={handleSettingSave} onDelete={deleteHandler}></Settings>
                    </div>
                
                </Col>
            )}
            
            </Row>
        </div>
        </div>
            
        <Row>
            <Col md={6}>
            <div className="mt-1">
            <InputWithLabelComponent
                type="text"
                name="btnText"
                label="Form Submit Button"
                value={buttonText}
                readOnly={false}
                onChange={(e) => setButtonText(e.target.value)}
                required={false}
                placeHolder={`Button Text${isInputCapture?'*':''}`}
            />
            </div>
            </Col>
            <Col md={6}>
            <div className="mt-1">
            <InputWithLabelComponent
                type="text"
                name="btnText"
                label=""
                value={buttonMsg}
                readOnly={false}
                onChange={(e) => setButtonMsg(e.target.value)}
                required={false}
                placeHolder="Display Text"
            />
            </div>
            </Col>
        </Row>
        <Row>
            <Col md={6}>
            <div className="mt-1">
            <InputWithLabelComponent
                type="text"
                name="btnText"
                label="Form Skip Button"
                value={skipBtnText}
                readOnly={false}
                onChange={(e) => setSkipBtnText(e.target.value)}
                required={false}
                placeHolder="Button Text"
            />
            </div>
            </Col>
            <Col md={6}>
            <div className="mt-1">
            <InputWithLabelComponent
                type="text"
                name="btnText"
                label=""
                value={skipBtnMsg}
                readOnly={false}
                onChange={(e) => setSkipBtnMsg(e.target.value)}
                required={false}
                placeHolder="Display Text"
            />
            </div>
            </Col>
        </Row>
        
        <div className="mt-1">
                <Row className="FormLabel-row FormLabel-col">
                    <Col md={4} className="add-btn-label">
                    <Form.Label className="mb-1 text-box-title adding-other-input">Add buttons</Form.Label>
                    </Col>
                    <Col md={1} className='countBtn-col plusBtn-align'>
                    <div onClick={updateButtonCount}>
                        <img className='addBtn-img' src="./Icons/AddBlackFilled.svg"/>
                    </div>    
                    </Col>
                </Row>
        </div>
        {buttonDataList.map((item, index)=>{ return renderButtonInputs(index,item)})}

        <br/>
        <div className="mt-1" id="checkInCdNode">
            <Form>
                <Form.Check 
                type="checkbox"
                id={`template`+variable}
                label='Save as template'
                checked = {saveTemplate}
                onChange={e=>setSaveTemplate(e.target.checked)}
                />
            </Form>
        </div>
        <div className="mt-1" id="checkInCdNode">
            <Form>
                <Form.Check 
                type="checkbox"
                id={`addToLog`+variable}
                label='Capture as conversation log'
                checked = {addToLog}
                onChange={e=>setAddToLog(e.target.checked)}
                />
            </Form>
        </div>
        <br/>
        <div className="mt-1">
            <ButtonCustom variant='contained' label='Save'className='buttonWidth' disabled={!isFormValid()} clicked={saveForm}/>
        </div>
</>
    )
}

export default FormPlayground;