import { connect } from 'react-redux';
import { topResolutionsChangeHandlerAction } from '../../../store/actions/insights/insightsActions';
import saveInsightsUnifiedLoginEsDataAction from '../../../store/actions/insights/saveInsightsUnifiedLoginEs/saveInsightsUnifiedLoginEsDataAction';
import ResolutionsPage from '../TopResolutions/Resolutions';

export const mapStateToProps = (state) => ({
    enabled: state.insights.topresolutions.enabled,
    size: state.insights.topresolutions.size,
    similarity: state.insights.topresolutions.similarity,
});

export const mapDispatchToProps = (dispatch,ownProps) => ({
    handleSwitchChange: (event) => {
        dispatch(topResolutionsChangeHandlerAction({
            enabled: event.target.checked
        }))
        const apiUrl = ownProps.config.INSIGHTS_API_URL;
        dispatch(saveInsightsUnifiedLoginEsDataAction({apiUrl, tabName: 'topresolutions',ownProps}));
    },
    inputChangedHandler: (event, name) => {
        dispatch(
            topResolutionsChangeHandlerAction(
                {
                    [name]: Number(event.target.value)
                }
            )
        );
    },
    handleSlideChange: (slideProgress, value) => {
        dispatch(
            topResolutionsChangeHandlerAction(
                {
                    similarity: Number(value)
                }
            )
        )
    },
    saveButtonClickHandler: () => {
        
		const apiUrl = ownProps.config.INSIGHTS_API_URL;
        dispatch(saveInsightsUnifiedLoginEsDataAction({apiUrl, tabName: 'topresolutions',ownProps}));
    },
    ownProps
});

export const TopResolutionContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ResolutionsPage);