const triageConfigurationUpdateHandlerReducer = (state, payload) => {
    return {
        ...state,
        triageConfigurationData: {
            ...state.triageConfigurationData,
            triageData: payload
        }
    }
}

export default triageConfigurationUpdateHandlerReducer;