import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Heading3 from "../../components/Typography/Heading3/Heading3";

import "./Users.css";
import PrimaryButtonWithLoader from "../../components/PrimaryButtonWithLoader/PrimaryButtonWithLoader";
import InviteUser from "./InviteUser";
import { GetCalltoOUServer } from "../helpers/utility";

import SearchUsersAndRoles from "../helpers/SearchUsersAndRoles";
import UserContent from "./UserContent";
import {  useSelector } from "react-redux";
import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Filters from "../../Insight/AdminConfiguration/Filters/Filters";

import isEqual from "lodash.isequal";
import { isEmpty } from "lodash";
import permissionsJson from "../../../../../permissions_Json";
import SearchComponent from "../../components/OuManagementSearch/OusearchComponent";

const Users = ({ config }) => {
  const userPermissions = useSelector((state) => {
    return state.user.permissionList;
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [showDrawer, setShowDrawer] = useState(false);
  const [filterUserData, setFilterUserData] = useState([]);
  const [userData, setUserData] = useState([]);
 
  const [count, setCount] = useState(0);
  const [showFilterDrawer, setShowFilterDrawer] = useState(false);
  const [advanceFilters, setAdvanceFilters] = useState({});

  const toggleDrawer = (value) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setShowDrawer(value);
    setSearchTerm("");
  };
  const toggleShowFilterDrawer = (value) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setShowFilterDrawer(value);
  };
  
  function getUserCount(list) {
   
    setCount(list.length);
  }

  const getUsers = () => {
    const orgname = localStorage.getItem("orgName");
    const url = config.COMMON_API_URL + `/organization/user?orgname=${orgname}`;
    
    GetCalltoOUServer(url)
      .then((response) => {
        if (response.data != null) {
        
          setUserData(response.data);
          getUserCount(response.data);
          setFilterUserData(response.data);
        } 
      })
      .catch((err) => {
        console.log(err)
      });
  };

  useEffect(() => {
    getUsers();
  }, []);

  const resultsUpdate = (attribute, data) => {
    if (attribute in advanceFilters) {
      if (
        isEqual(
          advanceFilters[attribute][advanceFilters[attribute].length - 1],
          data
        )
      ) {
        delete advanceFilters[attribute];
        if (isEmpty(advanceFilters)) setAdvanceFilters({});
        else setAdvanceFilters({ ...advanceFilters });
      } else {
        setAdvanceFilters({
          ...advanceFilters,
          [attribute]: advanceFilters[attribute].includes(data)
            ? [...advanceFilters[attribute].filter((item) => item !== data)]
            : [...advanceFilters[attribute], data],
        });
      }
    } else setAdvanceFilters({ ...advanceFilters, [attribute]: [data] });
  };

  const searchHandler = (event) => {
    setSearchTerm(event.target.value);
    const filter = SearchUsersAndRoles(
      userData,
      "users",
      event,
      setFilterUserData
    );
    setFilterUserData(filter);
  };

  

  const hasPermission = (code) => {
    if (userPermissions.find((data) => data.permission_code.includes(code)))
      return true;
    else return false;
  };

  const updateUser = () => {
    getUsers();
  };

  const sortUsers = (type) => {
    const toBeSortedData = [...filterUserData];
    toBeSortedData.sort((a, b) => {
   
      let fa = type === "first_name" ? a[type].toLowerCase() : a[type];
      let fb = type === "first_name" ? b[type].toLowerCase() : b[type];
      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
    setFilterUserData(toBeSortedData);
  };
  

  const updateFilterData = (object) =>{
    getUsers();
    var index = filterUserData.findIndex(({ id }) => id === object.id );
    if (index !== -1) {
      filterUserData[index] = object;
      setFilterUserData(filterUserData);
    } 
  }

  let  userManagement_Read_Write = false;
  let userManagement_RW_Status = userPermissions && userPermissions.find(data => {
    if (data.permission_code.includes(permissionsJson.permissionCode.USERMANAGEMENT_RW) && data.sku_code.includes(permissionsJson.skuCode.STANDARD))
      return true
  })
  userManagement_Read_Write = userManagement_RW_Status ? true : false;

  return (
    <Container className="symptom-rootcause" fluid>
      <Row>
        <Col xs={6} className="d-flex justify-content-between mb-3">
          <Heading3 className="pageTitleText" text="Users"  />
        </Col>
        <Col xs={6} className="d-flex justify-content-end mb-3 pt-3 ">
          {hasPermission("usermanagement_RW") && (
            <PrimaryButtonWithLoader
              className="mr-2"
              onClick={toggleDrawer(true)}
              size="lg"
              text="Add User"
              loading={false}
              disabled ={userManagement_Read_Write ? false : true}
            />
          )}
        </Col>
      </Row>
      <Row className="d-flex justify-content-between">
        <Heading3 xs={6}
              className="pageTitleText count-text-color pl-2"
              text={count}
              users={" Users are available!"}
            />
          <div xs={6} className="um-search-input">
               <SearchComponent
                  class={"input-group searchClass"}
                  placeholder="Search"
                  value={searchTerm}
                  onSearch={searchHandler}
                  onBlur={searchHandler}
                />
          </div>
        {/* These lines are commented now, in future we may use this */}
          {/* <div>
            <img
              title="Filter"
              className="user-filter pt-2"
              src="./images/Filter-img.svg"
              onClick={filterHandler}
            />
          </div> */}
      </Row>
      {/* <Col
        xs={6}
        className="d-flex justify-content-start symptoms-imgs pt-1 pl-3 pb-1"
      >
        <div>
          {appliedFilters.length ? (
            <p className="root-cause-right-side-head">Filters Applied</p>
          ) : null}
        </div>
      </Col>
      <Row className="d-flex justify-content-between">
        <Col
          xs={7}
          className="d-flex justify-content-start symptoms-imgs pl-4 pb-4"
        >
          <div className="d-flex flex-start align-items-center">
            {appliedFilters.length
              ? appliedFilters.slice(0, 7).map((item, index) => (
                  <div className="mr-2 mt-1 d-flex flex-start align-items-center selected-filter-tag ">
                    {index > 5 ? null : (
                      <div
                        onClick={() => onRemoveItem(item)}
                        className="selected-fiter-tag-remove-icon mr-2 ml-2"
                      >
                        <div className="selected-fiter-tag-close-icon">x</div>
                      </div>
                    )}
                    {index > 5 ? (
                      <div
                        className="ml-3 selected-filter-tag-label"
                        onClick={filterHandler}
                      >
                        {"show more.."}
                      </div>
                    ) : (
                      <div className="selected-filter-tag-label">{item}</div>
                    )}
                  </div>
                ))
              : ""}
          </div>
        </Col>
      </Row> */}
      <Row className="mt-4 row row-no-gutters">
        <Col
          xs={12}
          className="d-flex text-left align-items-center symtom-container"
        >
          <div className="row d-flex justify-content-between w-100 content-align-center symtom-heading-data">
            <Col xs={2}>
              <h3 className="issue-descp-title">
                Name{" "}
                <a onClick={() => sortUsers("first_name")}>
                  <img src="./Icons/ArrowDown.svg" />
                </a>
              </h3>
            </Col>
            <Col xs={2}>
              <h3 className="issue-descp-title">Status</h3>
            </Col>
            <Col xs={2}>
              <h3 className="issue-descp-title">
                Last Active{" "}
                <a onClick={() => sortUsers("last_active")}>
                  <img src="./Icons/ArrowDown.svg" />
                </a>
              </h3>
            </Col>
            <Col xs={2}>
              <h3 className="issue-descp-title">User groups</h3>
            </Col>
            <Col xs={2}>
                    <h3 className="issue-descp-title"></h3>
                  </Col>
            <Col xs={1}>
              <h3 className="issue-descp-title"></h3>
            </Col>
          </div>
        </Col>
      </Row>
      <div className="user-content-scroll">
        {filterUserData.length
          ? filterUserData.map((user) => {
              return (
                <UserContent
                  config={config}
                  key={user.id}
                  user={user}
                  updateFilterData={updateFilterData}
                  updateUser={updateUser}
                  updateUserCallBack={updateUser}
                  calledFrom="users"
                  userManagement_Read_Write ={userManagement_Read_Write}
                  setSearchTerm={setSearchTerm}
                />
              );
            })
          : null}
      </div>
      {showDrawer ? (
        <InviteUser
          showDrawer={showDrawer}
          toggleDrawer={toggleDrawer}
          updateUser={updateUser}
          config={config}
          // addSymptom={(symptomData) => addSymptom(symptomData)}
          toastFun={(msg, type) => {
            toast(msg, {
              type: type,
              pauseOnHover: false,
              autoClose: 3000,
              hideProgressBar: true,
            });
          }}
          closeDrawer={() => {
            setShowDrawer(false);
          }}
        />
      ) : null}
      {showFilterDrawer ? (
        <Filters
          showDrawer={showFilterDrawer}
          toggleDrawer={toggleShowFilterDrawer}
          closeDrawer={() => {
            showFilterDrawer(false);
          }}
          config={config}
          resultsUpdate={resultsUpdate}
        />
      ) : null}
    </Container>
  );
};

export default Users;
