// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modalClass .modal-dialog {
    top:30%
}
.modalClass .headerText{
    font-family:'Roboto-Black';
    font-size: 18px;
    line-height: 40px;
    color: #000000;
}
.modalClass .modal-header{
    padding: 1.5rem 2rem 1rem 2.5rem;
    border-bottom: 0px;
}
.modalClass .closeIcon{
    font-size: 1.88rem;
    cursor: pointer;
}
.modalClass .modal-body{
    padding: 0.5rem 2rem 2.2rem 2.5rem;
}
.modalClass .buttonGap{
    margin-left: 1.56rem;
}
.modalClass .buttonSpace{
    margin-bottom: 1rem;
}
.modalClass .buttonSpace span{
    font-family: 'Roboto-regular';
    font-size: 14px;
    line-height: 20px;
    color: #0B0C0C;

}
.modal-content {
    border-radius: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/UIComponents/DeleteModalComponent/DeleteModal.css"],"names":[],"mappings":"AAAA;IACI;AACJ;AACA;IACI,0BAA0B;IAC1B,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;AACA;IACI,gCAAgC;IAChC,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,eAAe;AACnB;AACA;IACI,kCAAkC;AACtC;AACA;IACI,oBAAoB;AACxB;AACA;IACI,mBAAmB;AACvB;AACA;IACI,6BAA6B;IAC7B,eAAe;IACf,iBAAiB;IACjB,cAAc;;AAElB;AACA;IACI,gBAAgB;AACpB","sourcesContent":[".modalClass .modal-dialog {\n    top:30%\n}\n.modalClass .headerText{\n    font-family:'Roboto-Black';\n    font-size: 18px;\n    line-height: 40px;\n    color: #000000;\n}\n.modalClass .modal-header{\n    padding: 1.5rem 2rem 1rem 2.5rem;\n    border-bottom: 0px;\n}\n.modalClass .closeIcon{\n    font-size: 1.88rem;\n    cursor: pointer;\n}\n.modalClass .modal-body{\n    padding: 0.5rem 2rem 2.2rem 2.5rem;\n}\n.modalClass .buttonGap{\n    margin-left: 1.56rem;\n}\n.modalClass .buttonSpace{\n    margin-bottom: 1rem;\n}\n.modalClass .buttonSpace span{\n    font-family: 'Roboto-regular';\n    font-size: 14px;\n    line-height: 20px;\n    color: #0B0C0C;\n\n}\n.modal-content {\n    border-radius: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
