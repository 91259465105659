import * as React from 'react';
import Drawer from '@material-ui/core/Drawer';
import './DrawerComponent.css';
import CloseIcon from "@material-ui/icons/Close";
import ArrowBack from "@material-ui/icons/ArrowBack";

const DrawerComponent = (props) => {
   return (
      <>
         <Drawer
            anchor={"right"}
            open={props.openDrawer}
            onClose={() => props.showDrawer(false)}
            className={props.className===undefined?'drawerClass':props.className}
            data-testid = "drawerdiv"
         >
            <div className="d-flex pt-4 pl-2">
               <div className="col-md-1">
                  {props.isArrowIcon ? <ArrowBack
                     className="d-flex product-img"
                     onClick={() => props.showDrawer(false)}
                     style={{ fontSize: 25 }}
                     data-testid = "arrowBackNode"
                  /> : <CloseIcon
                     className="d-flex product-img"
                     onClick={() => props.showDrawer(false)}
                     style={{ fontSize: 25 }}
                     data-testid = "drawerNode"
                  />}
               </div>
               <div className="d-flex product-data pl-3 pb-3" md={6}>
                  <h3 className="text-capitalize" data-testid = "headerNode">{props.heading}</h3>
               </div>
            </div>
            <div className="d-flex flex-column Symtom-Item highlights">
               {props.children}
            </div>
         </Drawer>
      </>
   )
}

export default DrawerComponent;
