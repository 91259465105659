import * as React from 'react';
import Dropdown from 'react-bootstrap/Dropdown'
import './DropdownComponent.css';
import DropdownButton from 'react-bootstrap/DropdownButton'
const DropdownComponent = (props) => {
   return(
      <>
   

    <DropdownButton title={props.label} className={props.customClass} id="dropdown-menu-align-end" variant={props.variant}>
    {props.dropdownItem? props.dropdownItem.length > 0 && props.dropdownItem.map((item,id) => {
         return (
          <Dropdown.Item data-testid={`item-${id}`} disabled={item.disabled} className={props.selectedLabel== item?'activeTree':'treeInnerLabel'}
          eventKey={item.name} key={item.name} onClick={() => props.selectedItem(item.name)}>{item.name}</Dropdown.Item>);
                       }):null}
    </DropdownButton>
    </>
  
   )
}

export default DropdownComponent;
