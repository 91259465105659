import React from 'react';
import { Form } from "react-bootstrap";
import * as PropTypes from 'prop-types';

const TextAreaWithLabelComponent = ({
    label,
    nameValue,
    value,
    className,
    placeHolder,
    onChange,
    isInvalid,
    error,
    ...props
}) => {
    return (
      <div>
          {label=="Response*"?(
            <div className="common-input">
              <Form.Label className="mb-1 text-box-title adding-other-input">{label}</Form.Label>
            </div>
          ):
        <Form.Label className="mb-1 text-box-title">
          {label}
        </Form.Label>}
        
        
        <Form.Control
          as="textarea"
          name={nameValue}
          required
          value={value}
          className={"default-css" + " " + className }
          placeholder={placeHolder}
          onChange={onChange}
          isInvalid={isInvalid}
          {...props}
        />
        {isInvalid ? (
        <span className="error-css">{error}</span>
      ) : null}
      </div>
    );
};

TextAreaWithLabelComponent.prototype = {
  label:PropTypes.string,
  name:PropTypes.string,
  className:PropTypes.string,
  defaultValue:PropTypes.string,
  placeHolder:PropTypes.string,
  readOnly:PropTypes.bool,
  required:PropTypes.bool,
  error: PropTypes.string,

}

TextAreaWithLabelComponent.defaultProps ={
  label:"TextArea Label",
  name:"TextArea-class",
  defaultValue:"",
  className:"",
  placeHolder:"Enter Text Here",
  readOnly:false,
  required:false,
  error: "",
}
export default TextAreaWithLabelComponent;