// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.secondary-button-loader .btn-secondary:not([disabled]) {
    border: 1px solid #2A93DF;
    box-sizing: border-box;
    border-radius: 4px;
    color: #2A93DF;
    background: transparent;
}

.secondary-button-loader .btn-secondary {
    width: 147px;
    height: 32px;
    font-family: 'Roboto-Bold';
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    letter-spacing: 0.04em;
    padding: .375rem .75rem;
    /* min-width: 10.76vw; */
}

.secondary-button-loader .btn-secondary:not([disabled]):hover {
    background: rgba(0, 107, 182, 0.11);
}

.secondary-button-loader .btn-secondary:disabled {
    background-color: #B8BEC3;
    color: #FFFFFF;
    border: none;
}`, "",{"version":3,"sources":["webpack://./src/containers/SettingPage/SettingInnerPages/components/SecondaryButtonWithLoader/secondary-button-loader.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,sBAAsB;IACtB,kBAAkB;IAClB,cAAc;IACd,uBAAuB;AAC3B;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,0BAA0B;IAC1B,eAAe;IACf,iBAAiB;IACjB,yBAAyB;IACzB,sBAAsB;IACtB,uBAAuB;IACvB,wBAAwB;AAC5B;;AAEA;IACI,mCAAmC;AACvC;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,YAAY;AAChB","sourcesContent":[".secondary-button-loader .btn-secondary:not([disabled]) {\n    border: 1px solid #2A93DF;\n    box-sizing: border-box;\n    border-radius: 4px;\n    color: #2A93DF;\n    background: transparent;\n}\n\n.secondary-button-loader .btn-secondary {\n    width: 147px;\n    height: 32px;\n    font-family: 'Roboto-Bold';\n    font-size: 14px;\n    line-height: 16px;\n    text-transform: uppercase;\n    letter-spacing: 0.04em;\n    padding: .375rem .75rem;\n    /* min-width: 10.76vw; */\n}\n\n.secondary-button-loader .btn-secondary:not([disabled]):hover {\n    background: rgba(0, 107, 182, 0.11);\n}\n\n.secondary-button-loader .btn-secondary:disabled {\n    background-color: #B8BEC3;\n    color: #FFFFFF;\n    border: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
