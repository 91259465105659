import React, { useState } from 'react';
import { Row, Col, Form } from "react-bootstrap";
import AceEditor from 'react-ace';
import './Drawer.css';
import ButtonCustom from "../../../../components/UIComponents/Button/ButtonComponent";

const ScriptNodeDetails = ({data, handleExternalSubmit, variable, enableScriptHTML, invalidVarMsg}) => {
    
    const [scriptData, setScriptData] = useState(data);
    const [saveTemplate, setSaveTemplate] = useState(false);
    const [displayHTML, setDisplayHTML] = useState(enableScriptHTML);

    function onChangeScript (script) {
        setScriptData(script);
    }

    const isFormValid = () => {
        return variable && scriptData && !invalidVarMsg;
    }

    const saveScript = () => {
        let formData = {
            scriptData: scriptData,
            template: saveTemplate,
            displayHTML: displayHTML
        }
        handleExternalSubmit('script', formData);
    }
    
    return (
        <>
        <div className="mt-1 top-padding">
        <Col md={12}>
            <div className="common-input">
            <Form.Label className="mb-1 text-box-title adding-other-input">Script*</Form.Label>
            </div>
            
            <AceEditor
                mode="javascript"
                theme="tomorrow_night_eighties"
                // name={props.names}
                className='ace-width'
                onChange={onChangeScript}
                value={scriptData}
                editorProps={{
                    $blockScrolling: true
                }}
            />
        </Col>
        </div>
        <br/>
        <Row className='optionsRow-script'>
            <Col md={3}>
            <Form>
                <Form.Check 
                type="checkbox"
                id={`template`+variable}
                label='Save as template'
                checked = {saveTemplate}
                onChange={e=>setSaveTemplate(e.target.checked)}
                />
            </Form>
            </Col>
            <Col md={4}>
            <Form>
                <Form.Check 
                type="checkbox"
                id={`html`+variable}
                label='Display with HTML formatting'
                checked = {displayHTML}
                onChange={e=>setDisplayHTML(e.target.checked)}
                />
            </Form>
            </Col>
        </Row>
        <div className="mt-1">
        <Col md={12}>
            <div className="mt-1">
                <ButtonCustom variant='contained' label='Save'className='buttonWidth' disabled={!isFormValid()} clicked={saveScript}/>
            </div>
        </Col>
        </div>
        </>
    )
    
}

export default ScriptNodeDetails;