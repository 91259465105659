import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './conversationMgmt.css';
import NavbarCustom from '../../../../components/UI/Navbar/Navbar';
import HorizontalTab from "../../../../components/UI/HorizontalTab/HorizontalTab";
import { changeProjectAction, GetBotList } from '../../../../store/actions';
import ShowMore from './ShowMore';
import Feedback from './Feedback';
import { encryptAndStore } from '../../../../utility/utility';

const ConversationManagement = ({config}) => {

    const [searchTerm, setSearchTerm] = useState('');
    const [popperShow, setPopperShow] = useState(false);
    const [projectName, setProjectName] = useState('');
    const [selectedTab, setSelectedTab] = useState("SHOW MORE SETTINGS");

    const dispatch = useDispatch();
    const projectList = useSelector(state => {
        return state.user.projects;
    })

    const projectclick = (event, projectname) => {
        setSearchTerm('');
        setPopperShow(false);
        let savedProjectName = localStorage.getItem('projectname');
        if (savedProjectName !== projectname) {
            // localStorage.setItem('projectname', projectname);
            encryptAndStore('projectname', projectname);
            setProjectName(projectname);
        }
        dispatch(changeProjectAction(projectname));
        dispatch(GetBotList(config.BOTBUILDER_URL));
        
    }

    return (
        <div className="ConversationMgmtContainer">
            <div className='d-flex justify-content-between head-div'>
                <div>
                    <h3>Conversation Management</h3>
                    <span className='para-graph'>Provision to configure the conversation responses and feedback capturing</span>
                </div>
                <div className="conversation-mgmt-navbar-div">
                    <NavbarCustom
                        dataList={projectList}
                        addImportProjectIcon={false}
                        setSearchTerm={(item) => setSearchTerm(item)}
                        popperShowFn={() => setPopperShow(true)}
                        popperShow={popperShow}
                        searchTerm={searchTerm}
                        projectclick={(event, item) => projectclick(event, item)}
                        projectname={projectName}
                        rightPopUp={false} >
                    </NavbarCustom>
                </div>
            </div>
            <div className='tabs-container'>
                <HorizontalTab
                    tabs={["FEEDBACK CAPTURING", "SHOW MORE SETTINGS"]}
                    selected={selectedTab}
                    setSelected={(item) => setSelectedTab(item)}
                />
                <div className='bottom-line' />
            </div>
            {selectedTab === 'SHOW MORE SETTINGS' && (
                <ShowMore></ShowMore>
            )}
            {selectedTab === 'FEEDBACK CAPTURING' && (
                <Feedback></Feedback>
            )}
        </div>
    )
}

export default ConversationManagement;