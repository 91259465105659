import { useState } from "react/cjs/react.development";
import TextField from '@material-ui/core/TextField';

function ShowAndHidePassword(props){
    const [passwordType, setPasswordType] = useState("password");
    const [passwordInput, setPasswordInput] = useState("");
    const handlePasswordChange =(evnt)=>{
        setPasswordInput(evnt.target.value);
        props.handleClick(evnt.target.value);
    }
    const togglePassword =()=>{
      if(passwordType==="password")
      {
       setPasswordType("text")
       return;
      }
      setPasswordType("password")
    }
    return(
        <>
                 <label>Password</label>
                 <TextField type={passwordType} name="password" value={passwordInput}  onChange={handlePasswordChange}  ></TextField>  
                <div className="visiblityBtn">
                    { passwordType==="password"? <img onClick={togglePassword} src='./Icons/visibility_eye.svg'/> :<img onClick={togglePassword} src='./Icons/visibility_off_eye.svg'/> }
                </div>
      </>
      
    )
}
export default ShowAndHidePassword;