import * as React from 'react';
import { Tooltip, withStyles } from '@material-ui/core';

const ToolTipComponent = (props) => {
   return(
      <LightTooltip
      arrow
      title={props.title}
      placement="top-start">
         {props.children}
      </LightTooltip>
  
   )
}
const LightTooltip = withStyles((theme) => ({
   tooltip: {
     backgroundColor: theme.palette.common.white,
     color: "rgba(0, 0, 0, 0.87)",
     boxShadow: theme.shadows[1],
     fontSize: 12,
     fontFamily: "Roboto-Regular",
   },
   arrow: {
     color: theme.palette.common.white,
     "&:before": {
       border: "1px solid #E6E8ED",
       boxShadow: theme.shadows[1],
     },
   },
 }))(Tooltip);
export default ToolTipComponent;
