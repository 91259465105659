import * as React from 'react';
import './FileInput.css';


const FileComponent = (props) => {
   return (<>
      <div class='fileClass'>
         <input type="file" name={props.name}
            accept={props.fileType} value={props.value} onChange={props.onFileChange} />
      </div>
      {props.error && (
         <div className="error-css">{props.error}</div>
      )}
   </>)
}

export default FileComponent;
