import React, { useState, useEffect } from "react";
import { Row, Col, Container} from "react-bootstrap";

import "./UserGroups.css";
import { useHistory, useParams } from 'react-router-dom';
import Heading3 from '../../components/Typography/Heading3/Heading3';
import PrimaryButtonWithLoader from "../../components/PrimaryButtonWithLoader/PrimaryButtonWithLoader";
import ReactSelectComponent from '../../components/ReactSelectComponent/ReactSelectComponent';

import { GetCalltoOUServer, DeleteCalltoOUServer, UpdateCalltoOUServer } from "../helpers/utility";
import { GetCalltoServer } from "../../../../../store/utility";
import isEmpty from "lodash/isEmpty";
import InputWithLabelComponent from "../../components/InputWithLabelComponent/InputWithLabelComponent";
import SearchUsersAndRoles from "../helpers/SearchUsersAndRoles";
import UserContent from '../Users/UserContent';
import InviteUser from '../Users/InviteUser';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UserGroupDetails = ({ config }) => {

  let history = useHistory();
 
  const groupName = useParams().name;
  const orgname = localStorage.getItem("orgName");
  

  const [groupData, setgroupData] = useState([]);
  const [projects, setProjects] = useState([]);
  const [roles, setRoles] = useState([]);
  
  const [count, setCount] = useState(0);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]); 
 
  const [searchTerm, setSearchTerm] = useState('');
  const [formData, setFormData] = useState({
    orgname: orgname,
    usergroup_name: groupName,
    projects: [],
    roles: [],
    description:'',
  });
  const [filterUserData, setFilterUserData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [showDrawer, setShowDrawer] = useState(false);


  useEffect(()=>{
    getUserGroupDetails();
    getProjectDetails();
    getRoleDetails();
  },[]);

  const getUserGroupDetails = () =>{    
    const orgname = localStorage.getItem("orgName");
    const url = config.COMMON_API_URL + `/organization/usergroup?orgname=${orgname}&usergroup=${groupName}`;
    
    GetCalltoOUServer(url)
      .then(response => {
          if (response.data !== null) {
          
          setgroupData(response.data);
          setSelectedRoles(response.data[0].roles);
      setSelectedProjects(response.data[0].projects);
      setUserData(response.data[0].users);
      setFilterUserData(response.data[0].users);
      setUserCount(response.data[0]);

      }
        
        
      }).catch((err) => {
       console.log(err)
      });
  }

  const setUserCount = (data) => {
    setCount(data.users.length);
  }

  const searchHandler = (event) => {
    setSearchTerm(event.target.value);
    if (filterUserData.length > 0) {
      const filter = SearchUsersAndRoles(userData, "users", event, setFilterUserData);
      setFilterUserData(filter);
    }
  };

  useEffect(()=>{
    setFormData((formData) => ({
      ...formData,
      roles:selectedRoles
    }))
  },[selectedRoles]);

  useEffect(()=>{
    setFormData((formData) => ({
      ...formData,
      projects:selectedProjects
    }))
  },[selectedProjects]);

  const getProjectDetails = () => {
    const url = config.COMMON_API_URL + `/botbuilder/project/en`;
       
        GetCalltoServer(url)
          .then(response => {
              if (response.data != null) {
             
              setProjects(response.data.data);
            }
            
          }).catch((err) => {
            console.log(err)
          });
  }

  const getRoleDetails = () => {
    const orgname = localStorage.getItem("orgName");
        const url = config.COMMON_API_URL + `/organization/role?orgname=${orgname}`;
        
        GetCalltoOUServer(url)
          .then(response => {
              if (response.data != null) {
            

              setRoles(parseRoles(response.data));
          
            }
            
          }).catch((err) => {
         console.log(err)
          });
  }

  const parseRoles = (data) => {
    let rolenames = [];
    rolenames = data.map(role=>role.rolename);
      return rolenames;
}

  const getOptions = (data) => {
    if (isEmpty(data)) {
      return [];
    }
  
    const attrData = data;
    return attrData.map((option) => ({
      label: option,
      value: option,
    }));
  };

  const updateProjectData = (e) => {
    if(!selectedProjects.includes(e.value))
    setSelectedProjects(prevData => [...prevData,e.value]);
}

const removeProject = (e,item) => {
  e.preventDefault();
    setSelectedProjects(selectedProjects.filter(list => list !== item))
}

const updateRoleData = (e) => {
  if(!selectedRoles.includes(e.value))
  setSelectedRoles(prevData => [...prevData,e.value]);
}

const removeRole = (e,item) => {
e.preventDefault();
  setSelectedRoles(selectedRoles.filter(list => list !== item))
}

const sortUsers = () => {
 
  filterUserData.sort((a, b)=>{
    let fa = a.first_name.toLowerCase();
    let fb = b.first_name.toLowerCase();
    if (fa < fb) {
      return -1;
  }
  if (fa > fb) {
      return 1;
  }
  return 0;
});
}

const updateUser = () => {
  //  update call
     
  }

  const toggleDrawer = (value) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setShowDrawer(value);
  };

  const updateGroup = () => {
    const url = config.COMMON_API_URL + `/organization/${orgname}/usergroup/${groupName}`;
    UpdateCalltoOUServer(url,formData)
    .then(response => {
      if(response.status===204) {
        toggleDrawer(true);
        
        toastFn('User group updated successfully', "success");
      } else {
        toastFn('Something went wrong!', "error");
      }
    });
  }

  const removeGroup = () => {
    const url = config.COMMON_API_URL + `/organization/${orgname}/usergroup/${formData.usergroup_name}`;
    DeleteCalltoOUServer(url)
    .then(response => {
      if(response.status == 204) {
        toastFn('Usergroup deleted successfully', "success");
        history.push('/settings');
        
      } else {
        toastFn('Something went wrong!', "error");
      }
    });
  }

  // const onChangeHandler = (event, type) => {
  //   setFormData((formData) => ({
  //     ...formData,
  //     [type]: event.target.value
  //   }))
  // };

  function toastFn(msg, type) {
    toast(msg, {
      type: type,
      pauseOnHover: false,
      autoClose: 3000,
      hideProgressBar: true,
    });
  }

  
  
  

  return (
    <Container className="symptom-rootcause" fluid>
      <Row className=''>
        <Col xs={12} className="d-flex justify-content-between mb-3">
        <div className="d-flex justify-content-between mt-4 mb-3">
        <img className="d-flex pr-2 cursor-action"
            src="../Icons/Close-img.svg"
            alt="close"
            onClick={() => history.push('/settings')} />
          <Heading3 className="pageTitleText"  text='User Group Details' />
          </div>
          <div className="d-flex justify-content-between mt-4 mb-3">
          <PrimaryButtonWithLoader
              className="mr-2 usergrp-btntitle"
              onClick={removeGroup}
              size="lg"
              text="DELETE GROUP"
              loading={false}
            />
            <PrimaryButtonWithLoader
              className="mr-2 usergrp-btntitle"
              onClick={toggleDrawer(true)}
              size="lg"
              text="ADD USER"
              loading={false}
            />
            <PrimaryButtonWithLoader
              className="mr-2 usergrp-btntitle"
              onClick={updateGroup}
              size="lg"
              text="SAVE"
              loading={false}
            />
          </div>
        </Col>
      </Row>
      <Row className="highlights">
        <Col xs={5}>
        <Row>
            <div className="d-flex flex-column Symtom-Item">        
            <div >
            <label className="label-title">Name</label>
            </div>
            <div>
            <label className="label-text">{groupData[0]?.usergroup_name}</label>
            </div>
            </div>
        </Row>
        <Row>
        <div className="d-flex flex-column Symtom-Item">
        <div >
            <label className="label-title">Description</label>
            </div>
            <div>
            <label className="label-description">{groupData[0]?.description}</label>
            </div>
            </div>
        </Row>
        </Col>
        <Col ms={3}>
            <div className="mt-1">
              <Row>
                <Col md={12}>
                  <ReactSelectComponent
                     options={getOptions(projects)}
                      name="userGroups"
                      label="Associated Projects"
                      placeholder={'Select'}
                      value=""
                     onChange={updateProjectData}
                  />
                  
                </Col>
                </Row>
            </div>
            <div className="mt-1">
            <Row>
            <Col md={12}>
                {selectedProjects.length ? selectedProjects.map((item) => {
                    return (
                            <a className="dyna-list-grp" onClick={e=>removeProject(e,item)} ><span className="li-close">X</span>{item==='*'?'All':item}</a> 
                           )
                }): null}                
            </Col>
            </Row>
            </div>
        </Col>
        <Col ms={3}>
        <div className="mt-1">
              <Row>
                <Col md={12}>
                  <ReactSelectComponent
                     options={getOptions(roles)}
                      name="userGroups"
                      label="Associated Roles"
                      placeholder={'Select'}
                      value=""
                     onChange={updateRoleData}
                  />
                  
                </Col>
                </Row>
            </div>
            <div className="mt-1">
            <Row>
            <Col md={12}>
                {selectedRoles.length ? selectedRoles.map((item) => {
                    return (
                            <a className="dyna-list-grp" onClick={e=>removeRole(e,item)} ><span className="li-close">X</span>{item==='*'?'All':item}</a> 
                           )
                }): null}
                
            </Col>
            </Row>
            </div>
        </Col>
        <Col ms={1}></Col>
            
        
      </Row>
      <Row>
          {/* <Users config={config}/> */}
          <Col xs={6} className="d-flex justify-content-between mt-4 mb-3">
           <Heading3 className="pageTitleText"  text={`This user group has 2`+count+` Users`} />
          </Col>
          <Col xs={6} className="d-flex justify-content-end mt-4 mb-3 pt-3">
                <div className="search-grp" >
                  <InputWithLabelComponent
                    type="text"
                    name={'search-symtom'}
                    showLabel={false}
                    value={searchTerm}
                    onChange={searchHandler}
                    placeHolder={'Search'}
                    className={"search-field"}
                  />
                </div>
              </Col>
      </Row>
      <Row className='mt-4 row row-no-gutters' >
              <Col xs={12} className="d-flex text-left align-items-center symtom-container">
                <div className='row d-flex justify-content-between w-100 content-align-center symtom-heading-data'>
                  <Col xs={2}>
                    <h3 className="issue-descp-title">Name <a onClick={() => sortUsers()}><img src="../Icons/ArrowDown.svg" /></a></h3>
                  </Col>
                  {/* <Col xs={2}>
                    <h3 className="issue-descp-title">Role</h3>
                  </Col> */}
                  <Col xs={2}>
                    <h3 className="issue-descp-title">Last Active</h3>
                  </Col>
                  <Col xs={2}>
                    <h3 className="issue-descp-title">Status</h3>
                  </Col>
                  <Col xs={2}>
                    <h3 className="issue-descp-title"></h3>
                  </Col>
                  <Col xs={1}>
                    <h3 className="issue-descp-title"></h3>
                  </Col>
                  </div>
                  </Col>
                  </Row>
                  <div className="user-content-scroll">
                            
                   {filterUserData && filterUserData.length ? filterUserData.map((user) => {
                return (
                  <UserContent config={config} user={user} updateUserCallBack={updateUser} calledFrom='usergroup'/>
                )
              }) : 
              <Row
        className="d-flex row-no-gutters"
        //  onClick={toggleDrawer(true)}
        >
          <Col xs={12} className="d-flex row symtom-container">
                <div className="row d-flex justify-content-between w-100 content-align-items row-no-gutters">
                <h4 className="empty-row">No users added to this group</h4>
                </div>
                </Col>
          
          </Row>
              }

                  </div>

                  {showDrawer ?
              (<InviteUser 
                showDrawer={showDrawer}
                toggleDrawer={toggleDrawer}
                updateUser={updateUser}
                calledFrom='userGroupDetails'
                usergroup={groupName}
                config={config}
               toastFun={(msg, type) => {
                toast(msg, {
                  type: type,
                  pauseOnHover: false,
                  autoClose: 3000,
                  hideProgressBar: true,
                });
              }}
                closeDrawer={() => {
                  setShowDrawer(false);
                }} />) :
              null}
    </Container>
   
  );
};

export default UserGroupDetails;
