import React, { useEffect, useState } from 'react';
import './Drawer.css';
import { Col, Form } from "react-bootstrap";
import InputWithLabelComponent from "../../../containers/SettingPage/SettingInnerPages/components/InputWithLabelComponent/InputWithLabelComponent";
import DrawerWithSubHeading from "../../UIComponents/DrawerWithSubHeading/DrawerWithSubHeading";
import ButtonCustom from "../../UIComponents/Button/ButtonComponent";
import { useDispatch, useSelector } from "react-redux";
import { SetSuccessMessage, CreateCuxTemplate } from '../../../store/actions';
import FormsNode from "../../../containers/ProjectViewPage/CoversationalUI/Drawer/FormsNode";
import CarouselNodeDetails from "../../../containers/ProjectViewPage/CoversationalUI/Drawer/CarouselNodeDetails";
import ScriptNodeDetails from '../../../containers/ProjectViewPage/CoversationalUI/Drawer/ScriptNodeDetails';
import EmailNodeDetails from '../../../containers/ProjectViewPage/CoversationalUI/Drawer/EmailNodeDetails';
import AgentTransferDetails from '../../../containers/ProjectViewPage/CoversationalUI/Drawer/AgentTransferDetails';
import ShowDataDetails from '../../../containers/ProjectViewPage/CoversationalUI/Drawer/ShowDataDetails';
import FlowNodeDetails from '../../../containers/ProjectViewPage/CoversationalUI/Drawer/FlowNodeDetails';
import HttpNodeDetails from '../../../containers/ProjectViewPage/CoversationalUI/Drawer/HttpNodeDetails';
import BoxSelectorDetails from '../../../containers/ProjectViewPage/CoversationalUI/Drawer/BoxSelectorDetails';
import MediaNodeDetails from '../../../containers/ProjectViewPage/CoversationalUI/Drawer/MediaNodeDetails';
import TextPromptDetails from '../../../containers/ProjectViewPage/CoversationalUI/Drawer/TextPromptDetails';

const AddNodeDetailsDrawer = ({config, show, showDrawer, node, updateNode, variableList, flowid}) => {

  const [label, setLabel] = useState(node.data.label);
  const [variable,setVariable] = useState(node.data.variable);

  const [branched, setBranched] = useState(node.data?.branched?node.data.branched:'false');
  
  const [branchNames, setBranchNames] = useState(node.data.branchOptions);
  const [branchCount, setBranchCount] = useState(node.data?.branchOptions?.length);

  const [httpData, setHttpData] = useState();
  const [carouselInputs, setCarouselInputs] = useState();
  const [boxSelectorData, setBoxSelectorData] = useState();
  const [flowData, setFlowData] = useState();

  const [formInputs, setFormInputs] = useState(node.data?.formInputs?node.data.formInputs:[]);
  const [formSubmitBtn, setFormSubmitBtn] = useState(node.data?.formSubmitBtn?node.data.formSubmitBtn:'');
  const [formSubmitMsg, setFormSubmitMsg] = useState(node.data?.formSubmitMsg?node.data.formSubmitMsg:'');
  const [formExtraBtnList, setFormExtraBtnList] = useState();
  const [submitFlag, setSubmitFlag] = useState(false);

  const [scriptData, setScriptData] = useState(node.data?.scriptData);

  const [emailData, setEmailData] = useState(node.data?.emailData);

  const [mediaData, setMediaData] = useState(node.data?.mediaData);

  const [intentListValues, setIntentListValues] = useState([]);
  const [intentQuery, setIntentQuery] = useState(node.data?.intentQuery);

  const [agentTransferData, setAgentTransferData] = useState(node.data?.agentTransferData);

  const [showDataDetails, setShowDataDetails] = useState(node.data?.showDataDetails);

  const [textPromptData, setTextPromptData] = useState(node.data?.textPromptData);

  const [disableTextbox, setDisableTextbox] = useState(node.data?.disableTextbox!==undefined?node.data.disableTextbox:true);
  const [invalidVarMsg, setInvalidVarMsg] = useState(null);
  const [templateFlag, setTemplateFlag] = useState();
  const [displayHTML, setDisplayHTML] = useState(node.data?.displayHTML?node.data.displayHTML:false);
  const [addToLog, setAddToLog] = useState(node.data?.addToLog!==undefined?node.data.addToLog:true); //flag to decide whether to add to conv log

  const dispatch = useDispatch();

  const intentList = useSelector(state => {
    return state.projectview.botList;
  });

  useEffect(()=>{
    if(intentList !== undefined && intentList.length !== 0) {
      let list = intentList['en'][localStorage.getItem('projectname')];
      let intents = Object.keys(list);
      if(intentListValues.length === 0)
          intents.forEach((item)=> {
              let object = {value:item, label:item};
              setIntentListValues((prev)=>[...prev,object]);
               return true;
          });
  }
  }, [node.type === 'triggerIntent'])

  const getFinalLabel = () => {
    switch(node.type) {
      case 'http': setLabel(httpData.url); return httpData.url;
      case 'media': setLabel(mediaData.url); return mediaData.url;
      case 'boxSelector': return boxSelectorData.label;
      case 'flow': if(flowData.referenceVariable === undefined)
                      return flowData.flowName.label;
                    else 
                      return flowData.referenceVariable;
      case 'triggerIntent': return intentQuery;
      default: return label;
    }
  }
  
  const handleSubmit = () => {
    let branchOptions, maxLength = null;
    
    if(branchNames !== null && branchNames !== undefined)
      branchOptions = handleArrayInput(branchNames);
    
    let data = {label: getFinalLabel(), variable, branched:node.type==='boxSelector'?boxSelectorData.branched:branched, nodeOptions:node.data.nodeOptions};

    switch (node.type) {
      case 'text' : Object.assign(data,{addToLog:addToLog}); break;
      case 'textPrompt' : Object.assign(data,{
        textPromptData:textPromptData,
        addToLog:addToLog}); break;
      case 'boxSelector': Object.assign(data,{
        count:boxSelectorData.count,
        referenceVariable:boxSelectorData.referenceVariable,
        disableTextbox:boxSelectorData.disableTextbox,
        optionValues:boxSelectorData.optionValues,
        addToLog:boxSelectorData.addToLog
        }); break;
      case 'confirmation': Object.assign(data,{
        disableTextbox:disableTextbox,
        addToLog:addToLog
        }); break;
      case 'expression': Object.assign(data,{
        branchOptions:branchOptions,
        }); break;
      case 'carousel': maxLength = isNaN(parseInt(carouselInputs.maxBodyLength))?0:parseInt(carouselInputs.maxBodyLength);
        Object.assign(data,{
          carousel: carouselInputs.carouselData,
          showMoreBtn:carouselInputs.showMoreBtn,
          maxBodyLength:maxLength,
          carouselReferenceCheck:carouselInputs.carouselReferenceCheck,
          referenceVariable:carouselInputs.referenceVariable,
          buttonDataList: carouselInputs?.buttonDataList,
          disableTextbox:carouselInputs.disableTextbox,
          addToLog:carouselInputs.addToLog
        });
        break;
      case 'http': Object.assign(data, httpData);
        break;
      case 'flow': Object.assign(data, {
        flowid: flowData.flowid,
        IsReferance: flowData.IsReferance,
        referenceVariable: flowData.referenceVariable
        }); break;
      case 'media': Object.assign(data, {...mediaData,addToLog});
        break;
      case 'forms': Object.assign(data,{
          formInputs:formInputs,
          formSubmitBtn: formSubmitBtn,
          formSubmitMsg: formSubmitMsg,
          formSkipBtnText: formExtraBtnList?.skipBtnText,
          formSkipBtnMsg: formExtraBtnList?.skipBtnMsg,
          buttonDataList: formExtraBtnList?.buttonDataList,
          isCardView:true,
          disableTextbox:disableTextbox,
          addToLog:addToLog
        }); break;
      case 'script': Object.assign(data,{
          scriptData:scriptData,
          displayHTML: displayHTML
        }); break;
      case 'email': Object.assign(data,{
          emailData:emailData
        }); break;
      case 'triggerIntent': Object.assign(data,{
          intentQuery: intentQuery
        }); break;
      case 'agent':
        Object.assign(data,{
          agentTransferData: agentTransferData
        }); break;
      case 'showData': Object.assign(data,{
          showDataDetails: showDataDetails
        }); break;
      
    }
    if(templateFlag) { 
      saveTemplate(node.type, data); 
    } 
    updateNode(data,node);
    showDrawer(false);
    // console.log('data:',data);
    dispatch(SetSuccessMessage('Node details saved successfully.'));
  }

  const saveTemplate = (type, data) => {
    Object.assign(data,{type:type});
    let requestBody = {
      type: type,
      project: localStorage.getItem('projectname'),
      name: data.variable,
      data: data
    }
    dispatch(CreateCuxTemplate(config.COMMON_API_URL, type, requestBody));
  }

  const handleExternalSubmit = (type, data) => {
    switch(type) {
      case 'script' : setScriptData(data.scriptData); setTemplateFlag(data.template); setDisplayHTML(data.displayHTML); break;
      case 'email' : setEmailData(data); break;
      case 'agent' : setAgentTransferData(data.agentData); setLabel(data.label); break;
      case 'showData' : setShowDataDetails(data); break;
      case 'flow' : setFlowData(data); break;
      case 'http' : setHttpData(data.httpData); setTemplateFlag(data.template); break;
      case 'boxSelector' : setBoxSelectorData(data); break;
      case 'carousel' : setCarouselInputs(data); break;
      case 'forms' : setFormInputs(data.formData.fields); 
                     setFormSubmitBtn(data.formData.buttonText);
                     setFormSubmitMsg(data.formData.buttonMsg);
                     setFormExtraBtnList(data.extraBtns);
                     setTemplateFlag(data.template); 
                     setBranched('true');
                     setAddToLog(data.addToLog); break;
      case 'media' : setMediaData(data.mediaData); setAddToLog(data.addToLog); break;
      case 'textPrompt' : setTextPromptData(data.promptData); setLabel(data.label); 
                     setAddToLog(data.addToLog); break;
    }
    
    setSubmitFlag(true);
  }

  useEffect(()=> {
    if(submitFlag) {
      handleSubmit();
      setSubmitFlag(false);
    }      
  },[formInputs, carouselInputs, scriptData, submitFlag]);

  const handleArrayInput =  (data) => {
    let arrayItems = data;
    if(typeof data === 'string') {
      arrayItems = data.split(',');
    }      
    return arrayItems;
  }

  const handleBranchingOption = (e) => {
    setBranched(e.target.value);
  }


  function inputChangeHandler(value,type) {
    if (type === 'variable') {
      setVariable(value);
      checkValidation(value,type)
    }
  }

  function isDuplicate(value) {
    if (variableList !== undefined && variableList.length > 0) {
      let varExists = variableList.findIndex((element) => {
        return element.toLowerCase() === value.toLowerCase();
      });
      if (varExists > -1) {
        return true;
      } 
      return false;
    } else 
      return false;
  }

  function checkValidation(value,type) {    
    if (type === 'variable') {
      if (value.trim() === "") {
        setInvalidVarMsg('Name is required.');
      } else if (!/^[A-Z0-9]+$/i.test(value)) {
        setInvalidVarMsg('Name should be alphanumeric.');
      } else if(isDuplicate(value)) {
        setInvalidVarMsg('Name already exists.');
      } else {
        setInvalidVarMsg(null);
      }
    }
  }

  const isVarExisting = (value) => {
    return variableList.filter((item)=> item === value).length;
  }

  useEffect(()=> {
    if(isVarExisting(variable)>1) {
      setInvalidVarMsg('Name already exists.');
    }
  },[variable]);

  const getLabel = (type) => {
    switch (type) {
      case 'text' : return 'Text Message';
      case 'textPrompt' : return 'Text Prompt Message';
      case 'expression' : return 'Expression';
      case 'confirmation' : return 'Confirmation message';
      case 'agent' : return 'Heading for transferring details to agent';
    }
  }

  const getSubHeading = () => {
    switch(node.type) {
      case 'boxSelector' : return 'To provide the user with single-select options in the form of buttons anytime during a conversation';
      case 'text' : return 'To present information to the user anytime during a conversation';
      case 'textPrompt' : return 'To capture information of any format (text/numeric/email) from the user anytime during a conversation';
      case 'http' : return 'To integrate with external systems through API calls anytime during a conversation or an automated workflow';
      case 'expression' : return 'To execute logical/mathematical functions or perform conditional statements or manipulate strings or work with dates to change the course of a conversation or of an automated workflow';
      case 'carousel' : return 'To present information to the user as well as to provide the user with options in the form of card sliders, along with images and details, to select from anytime during a conversation';
      case 'confirmation' : return 'To get confirmation from the user anytime during a conversation';
      case 'flow' : return 'To trigger a different flow during the execution of a specific flow';
      case 'media' : return 'To present image/video to the user anytime during a conversation';
      case 'forms' : return 'To capture various information from the user at once anytime during a conversation by creating a form using multiple components';
      case 'agent' : return 'To transfer an ongoing chat to an available live agent anytime during a conversation, along with appropriate user and session information';
      case 'script' : return 'To write simple script and execute certain actions during the unlikely scenarios of being unable to design some parts of the workflows/conversations using the other action nodes';
      case 'email' : return 'To send appropriate emails to right stakeholders anytime during a conversation or an automated workflow';
      case 'showData' : return 'To present information to the user in a tabular or list format anytime during a conversation';
      case 'feedback' : return 'To capture user feedback anytime during a conversation';
      case 'triggerIntent' : return 'To explicitly know the intent being triggered depending on the user query during a conversation or an automated workflow';
    }
  }

const windowopen = window.open;

const onClickInfo = () => {
  let commonUrl = "https://help.hcltechsw.com/Intelliservice/en/8.5/webhelp/User_Guide/Nodes/";

  switch(node.type) {
    case 'boxSelector' : windowopen(commonUrl +"Box_Selector.htm" ,"_blank") ; break;
    case 'text' : windowopen(commonUrl +"Text.htm" ,"_blank") ; break;
    case 'textPrompt' : windowopen(commonUrl +"Text_Prompt.htm" ,"_blank"); break;
    case 'http' : windowopen(commonUrl +"HTTP.htm" ,"_blank"); break;
    case 'expression' : windowopen(commonUrl +"EXPR.htm" ,"_blank"); break;
    case 'carousel' :windowopen(commonUrl +"Carousel.htm" ,"_blank"); break;
    case 'confirmation' : windowopen(commonUrl +"Nodes_and_Templates.htm"); break;
    case 'flow' : windowopen(commonUrl +"Nodes_and_Templates.htm"); break;
    case 'media' : windowopen(commonUrl +"Nodes_and_Templates.htm"); break;
    case 'forms' : windowopen(commonUrl +"Forms.htm" ,"_blank"); break;
    case 'agent' : windowopen(commonUrl +"Nodes_and_Templates.htm"); break;
    case 'script' :windowopen(commonUrl +"Script.htm" ,"_blank"); break;
    case 'email' : windowopen(commonUrl +"Email.htm" ,"_blank"); break;
    case 'showData' : windowopen(commonUrl +"Nodes_and_Templates.htm"); break;
    case 'feedback' : windowopen(commonUrl +"Nodes_and_Templates.htm"); break;
    case 'triggerIntent' : windowopen(commonUrl +"Nodes_and_Templates.htm"); break;
  }
}
  const isFormValid = () => {
    let validStatus = variable && !invalidVarMsg;
    switch(node.type) {
      case 'text' : 
      case 'textPrompt' : 
      case 'confirmation' : return validStatus && label;
      case 'expression' : if(branched === 'true') {
                            validStatus = validStatus && branchCount && branchNames;
                          }
                          return validStatus && label;
      case 'feedback': 
      case 'agent' : return validStatus;
      case 'script' : return validStatus;
      case 'triggerIntent' : return validStatus && intentQuery;
    }
  }

  const getHeading = () => {
    switch(node.type) {
      case 'script' : return 'Script Details';
      case 'triggerIntent' : return 'Trigger Intent Details';
      case 'media' : return 'Media Details';
      case 'email' : return 'Email Details';
      case 'showData' : return 'Show Data';
      case 'carousel' : return 'Carousel Details';
      case 'text' : return 'Text Details';
      case 'textPrompt' : return 'Text Prompt Details';
      case 'boxSelector' : return 'Box Selector Details';
      case 'http' : return 'HTTP Details';
      case 'confirmation' : return 'Confirmation Details';
      case 'flow' : return 'Flow Details';
      case 'agent' : return 'Agent Transfer Details';
      case 'expression' : return 'Expression Details';
      case 'feedback' : return 'Feedback Details';
      default : return 'Add Node Details';
    }
  }

  const triggerIntentForm = () => {
    return (
      <div className="mt-3">
              <Col md={12}>
                <InputWithLabelComponent
                        type="text"
                        name="label"
                        label = "Intent triggering query*"
                        value={intentQuery}
                        readOnly={false}
                        onChange={(e) => setIntentQuery(e.target.value)}
                        required={true}
                  />
              </Col>
              </div>
    )
  }

  const expressionForm = () => {
    return (
      <>
      <div className="mt-1">
      <Col md={12}>
        <label>Is Branching required?</label> <br/>
        <div>
          <input type="radio" id="true" name="branched" value="true" checked={branched==='true'} onChange={handleBranchingOption}/>
          <label >Yes</label>
          <input type="radio" id="false" name="branched" value="false" checked={branched==='false'} onChange={handleBranchingOption}/>
          <label >No</label><br/>
        </div>
      {branched === 'true' && (
          <>
          <div className="mt-1">
          <Col md={12}>
              <InputWithLabelComponent
                  type="text"
                  name="branches"
                  label= "How many branches requried?*"
                  value={branchCount}
                  readOnly={false}
                  onChange={(e) => setBranchCount(e.target.value)}
                  required={true}
          />
          </Col>
          </div>
          <div className="mt-1">
          <Col md={12}>
              <InputWithLabelComponent
                  type="text"
                  name="brancheNames"
                  label= "Enter branch names with comma separator*"
                  value={branchNames}
                  readOnly={false}
                  onChange={(e) => setBranchNames(e.target.value)}
                  required={true}
          />
          </Col>
          </div>
          </>
      )}
      </Col>
      </div>
      </>
    )
  }

  const confirmationForm = () => {
    return (
      <div className="mt-1">
              <Col md={12}>
                <Form>
                    <Form.Check 
                    type="checkbox"
                    id={`disable-textbox`+variable}
                    label='Disable Textbox'
                    checked = {disableTextbox}
                    onChange={e=>setDisableTextbox(e.target.checked)}
                  />
                </Form>
              </Col>
            </div>
    )
  }

  const getFormContent = () => {
    switch (node.type) {
      case 'textPrompt' : return ( <TextPromptDetails data={{label,textPromptData,addToLog}} 
        handleExternalSubmit={handleExternalSubmit} variable={variable} invalidVarMsg={invalidVarMsg}/>);
      case 'http' : return (<HttpNodeDetails node={node} variable={variable} 
        invalidVarMsg={invalidVarMsg} handleExternalSubmit={handleExternalSubmit} />);
      case 'carousel' : return (<CarouselNodeDetails node={node} handleSubmit ={handleExternalSubmit} 
        variable={variable} invalidVarMsg={invalidVarMsg}/>);
      case 'boxSelector' : return (<BoxSelectorDetails node={node} variable={variable} 
        invalidVarMsg={invalidVarMsg} handleExternalSubmit={handleExternalSubmit} />);
      case 'flow' : return (<FlowNodeDetails node={node} flowid={flowid} invalidVarMsg={invalidVarMsg} 
        variable={variable} handleExternalSubmit={handleExternalSubmit} />);
      case 'media' : return (<MediaNodeDetails node={node} variable={variable} invalidVarMsg={invalidVarMsg} 
        handleExternalSubmit={handleExternalSubmit}/>);
      case 'script' : return (<ScriptNodeDetails data={scriptData} handleExternalSubmit={handleExternalSubmit} 
        enableScriptHTML={displayHTML} variable={variable} invalidVarMsg={invalidVarMsg}/>);
      case 'email' : return (<EmailNodeDetails config={config} data={emailData} 
        handleExternalSubmit={handleExternalSubmit} variable={variable} invalidVarMsg={invalidVarMsg}/>);
      case 'agent' : return (<AgentTransferDetails data={{label, agentTransferData}} 
        handleExternalSubmit={handleExternalSubmit} variable={variable} invalidVarMsg={invalidVarMsg}/>);
      case 'showData' : return (<ShowDataDetails data={showDataDetails} 
        handleExternalSubmit={handleExternalSubmit} variable={variable} invalidVarMsg={invalidVarMsg}/>);   
      case 'triggerIntent' : return triggerIntentForm();
      case 'expression' : return expressionForm();
      case 'confirmation' : return confirmationForm();
    }
  }

    return (
        <React.Fragment >
          {node.type === 'forms' ? (
              <FormsNode node={node.data} data={{formInputs,formSubmitBtn, formSubmitMsg}} handleFormNodeDate={handleExternalSubmit} 
              variable={variable} showDrawer={showDrawer} show={show} setVar={setVariable} invalidVarMsg={invalidVarMsg}
              setInvalidMsg={setInvalidVarMsg} checkValidation={checkValidation} 
              disableTextbox={disableTextbox} setDisableTextbox={setDisableTextbox}></FormsNode>
          ):(
            <DrawerWithSubHeading showDrawer={showDrawer} openDrawer={show} 
            heading={getHeading()} tooltip={true} onClick ={onClickInfo}
             className={node.type==='script'?'drawer-width':undefined}
            subHeading={getSubHeading()}> 
              <div className="mt-1">
              <Col md={12}>
                  <InputWithLabelComponent
                      type="text"
                      name="varname"
                      label="Name*"
                      className={node.type === 'script' ? 'input-width':''}
                      value={variable}
                      readOnly={false}
                      onChange={(e) => inputChangeHandler(e.target.value,'variable')}
                      required={true}
                      isInvalid={invalidVarMsg!==null}
                      error={invalidVarMsg}
                      toolTip={true}
                      toolTipParams={{title:'Name will also be used as variables',placement:'right'}}
                />
              </Col>
          </div>            

            { (node.type ==='text' || node.type === 'confirmation' || node.type === 'expression') && (
                <div className="mt-1">
                <Col md={12}>
                    <InputWithLabelComponent
                        type="text"
                        name="label"
                        label = {getLabel(node.type)+'*'}
                        value={label}
                        readOnly={false}
                        onChange={(e) => setLabel(e.target.value)}
                        required={true}
                  />
                </Col>
                </div>
            )}

            {getFormContent()}

            {(node.type ==='text' || node.type === 'confirmation')  && (
                <div className="mt-1"  id="checkInCdNode">
                  <Col md={12}>
                    <Form>
                        <Form.Check 
                        type="checkbox"
                        id={`addToLog`+variable}
                        label='Capture as conversation log'
                        checked = {addToLog}
                        onChange={e=>setAddToLog(e.target.checked)}
                      />
                    </Form>
                  </Col>
                </div>
              )}

            <br/>

            {(node.type === 'text' || node.type === 'expression' || node.type === 'confirmation' 
            || node.type === 'triggerIntent' || node.type === 'feedback') && (
               <div className="mt-1">
               <Col md={12}>
                   <ButtonCustom variant='contained' label='Save'className='buttonWidth' disabled={!isFormValid()} clicked={handleSubmit}/>
               </Col>
              </div>
            )}
           <br/>
            
          </DrawerWithSubHeading>
          )}
        </React.Fragment>
    );
    
}


export default AddNodeDetailsDrawer;
