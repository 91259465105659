import { 
    FETCH_PRODUCT_TRIAGE_DATA_FAILURE,
    FETCH_PRODUCT_TRIAGE_DATA_START,
    FETCH_PRODUCT_TRIAGE_DATA_SUCCESS,
    FETCH_PRODUCT_TRIAGE_INSIGHTS_START,
    FETCH_PRODUCT_TRIAGE_INSIGHTS_SUCCESS,
    FETCH_PRODUCT_TRIAGE_INSIGHTS_FAILURE,
    FILTER_DATA_CHANGE_HANDLER,
    FILTER_DATA_ARR_CHANGE_HANDLER,
    ADVANCE_FILTER_CHANGE_HANDLER,
    RECOMENDED_SOLUTIONS_CHECKBOX_HANDLER,
    FETCH_CLUSTERS_DATA_START,
    FETCH_CLUSTERS_DATA_SUCCESS,
    FETCH_CLUSTERS_DATA_FAILURE,
    SET_SELECTED_INSIGHTS_TAB,
    YES_QUESTION_UPDATE_HANDLER,
    FETCH_RECOMMENDED_SOLUTION_DATA_START,
    FETCH_RECOMMENDED_SOLUTION_DATA_SUCCESS,
    FETCH_RECOMMENDED_SOLUTION_DATA_FAILURE,
    RESET_INSIGHTS_HOME_DATA,
    GET_SYMPTOMS_FILTERS_CHANGE_HANDLER,
    QUESTIONS_UPDATE_HANDLER,
 } from "../../actions/agentInsights/agentInsightsActiontypes";
import changeFilterDataReducer from "./changeFilterDataReducer";
import changeFilterDataArrReducer from "./changeFilterDataArrReducer";
import fetchClustersDataStartReducer from "./fetchClustersData/fetchClustersDataStartReducer";
import fetchClustersDataFailureReducer from "./fetchClustersData/fetchClustersDataFailureReducer";
import fetchClustersDataSuccessReducer from "./fetchClustersData/fetchClustersDataSuccessReducer";
import fetchProductTriageDataFailureReducer from "./fetchProductTriageData/fetchProductTriageDataFailureReducer";
import fetchProductTriageDataSuccessReducer from "./fetchProductTriageData/fetchProductTriageDataSuccessReducer";
import fetchProductTriageDataStartReducer from "./fetchProductTriageData/fetchProductTriageStartReducer";
import fetchProductTriageInsightsFailureReducer from "./fetchProductTriageInsights/fetchProductTriageInsightsFailureReducer";
import fetchProductTriageInsightsStartReducer from "./fetchProductTriageInsights/fetchProductTriageInsightsStartReducer";
import fetchProductTriageInsightsSuccessReducer from "./fetchProductTriageInsights/fetchProductTriageInsightsSuccessReducer";
import setSelectedInsightsTabReducer from "./setSelectedInsightsTabReducer";
import yesQuestionsUpdateReducer from "./yesQuestionsUpdateReducer";
import fetchRecommendedSolutionStartReducer from "./fetchRecommendedSolution/fetchRecommendedSolutionStartReducer";
import fetchRecommendedSolutionSuccessReducer from "./fetchRecommendedSolution/fetchRecommendedSolutionSuccessReducer";
import fetchRecommendedSolutionFailureReducer from "./fetchRecommendedSolution/fetchRecommendedSolutionFailureReducer";
import resetInsightsHomeDataReducer from "./resetInsightsHomeDataReducer";
import changeAdvanceFilterDataReducer from "./changeAdvanceFilterDataReducer";
import changeRecomendedSolutionsDataReducer from "./changeRecomendedSolutionsDataReducer";
import getSymptomsFiltersChangeHandlerReducer from "./getSymptomsFiltersChangeHandlerReducer";
import questionsUpdateHandlerReducer from "./fetchProductTriageInsights/questionsUpdateHandlerReducer";

const INITIAL_STATE = {
    filterData: {},
    agentInsightsData: {},
    agentInsightsLoading: false,
    productTriageDataLoading: false,
    selectedFilterData: {},
    selectedFilterDataArr: {},
    appliedFilters:[],
    appliedCheckboxes:[],
    clustersData: [],
    clustersDataLoading: false,
    selectedInisghtsTab: 'recommendedSolutions',
    yesQuestions: [],
    recommendedSolutionData: [],
    recommendedSolutionDataLoading: false,
    getSymptomsFilterData: {}
};

const reducers = {
    [FETCH_PRODUCT_TRIAGE_DATA_START]: fetchProductTriageDataStartReducer,
    [FETCH_PRODUCT_TRIAGE_DATA_SUCCESS]: fetchProductTriageDataSuccessReducer,
    [FETCH_PRODUCT_TRIAGE_DATA_FAILURE]: fetchProductTriageDataFailureReducer,
    [FETCH_PRODUCT_TRIAGE_INSIGHTS_START]: fetchProductTriageInsightsStartReducer,
    [FETCH_PRODUCT_TRIAGE_INSIGHTS_FAILURE]: fetchProductTriageInsightsFailureReducer,
    [FETCH_PRODUCT_TRIAGE_INSIGHTS_SUCCESS]: fetchProductTriageInsightsSuccessReducer,
    [FILTER_DATA_CHANGE_HANDLER]: changeFilterDataReducer,
    [FILTER_DATA_ARR_CHANGE_HANDLER]: changeFilterDataArrReducer,
    [ADVANCE_FILTER_CHANGE_HANDLER] : changeAdvanceFilterDataReducer,
    [RECOMENDED_SOLUTIONS_CHECKBOX_HANDLER] : changeRecomendedSolutionsDataReducer,
    [FETCH_CLUSTERS_DATA_START]: fetchClustersDataStartReducer,
    [FETCH_CLUSTERS_DATA_FAILURE]: fetchClustersDataFailureReducer,
    [FETCH_CLUSTERS_DATA_SUCCESS]: fetchClustersDataSuccessReducer,
    [SET_SELECTED_INSIGHTS_TAB]: setSelectedInsightsTabReducer,
    [YES_QUESTION_UPDATE_HANDLER]: yesQuestionsUpdateReducer,
    [FETCH_RECOMMENDED_SOLUTION_DATA_START]: fetchRecommendedSolutionStartReducer,
    [FETCH_RECOMMENDED_SOLUTION_DATA_SUCCESS]: fetchRecommendedSolutionSuccessReducer,
    [FETCH_RECOMMENDED_SOLUTION_DATA_FAILURE]: fetchRecommendedSolutionFailureReducer,
    [RESET_INSIGHTS_HOME_DATA]: resetInsightsHomeDataReducer,
    [GET_SYMPTOMS_FILTERS_CHANGE_HANDLER]: getSymptomsFiltersChangeHandlerReducer,
    [QUESTIONS_UPDATE_HANDLER] :questionsUpdateHandlerReducer,
};

/**
 * This reducer is handling the agent insights component all reducers
 *
 * @param {Object} state - the Redux state of the reducer that this function is
 *  used for
 * @param {*} action - the action dispatched with type and payload props.
 * @returns {*} the next Redux state
 */
export const agentInsightsReducer = (
    state = INITIAL_STATE,
    action
) => {
    const { type, payload } = action;
    const reducer = reducers[type];

    return (reducer) ?
        reducer(state, payload) :
        state;
};