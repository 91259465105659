// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-data h3{
    font-family: "Roboto-Regular";
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 25px;
    color: #000000;
  }
.product-content-data{
    padding-left: 30px;
    font-family: "Roboto-Regular";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    color: #000;
}
.Product-Select-Item{
    /* background-color: #F4F0F0;; */
    height: 110px;
    padding-top: 20px;
    margin-top: 10px;
    padding-left: 18px;
}
.Product-Select-Item h3, h4{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
}
.Product-Select-Item h4{
    transform: rotate(0.14deg);
    color: #646363;
}
.Product-Select-Item h3{
    transform: matrix(1, 0, 0, 1, 0, 0);
    color: #353535;
}
.select-option{
    cursor: pointer;
}
.product-img{
    margin-left: 8px;
}
.toggle-btn-align {
    padding-right: 0.75rem;
    font-family: 'Roboto-Bold';
    font-size: 12px;
    color: #000;
}`, "",{"version":3,"sources":["webpack://./src/containers/SettingPage/SettingInnerPages/Insight/AdminConfiguration/TraiageConfigurationTabs/SymptomRootCauseMapping/EditSymptoms/edit-symptoms.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;IACf,iBAAiB;IACjB,cAAc;EAChB;AACF;IACI,kBAAkB;IAClB,6BAA6B;IAC7B,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,WAAW;AACf;AACA;IACI,gCAAgC;IAChC,aAAa;IACb,iBAAiB;IACjB,gBAAgB;IAChB,kBAAkB;AACtB;AACA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;IACf,iBAAiB;AACrB;AACA;IACI,0BAA0B;IAC1B,cAAc;AAClB;AACA;IACI,mCAAmC;IACnC,cAAc;AAClB;AACA;IACI,eAAe;AACnB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,sBAAsB;IACtB,0BAA0B;IAC1B,eAAe;IACf,WAAW;AACf","sourcesContent":[".product-data h3{\n    font-family: \"Roboto-Regular\";\n    font-style: normal;\n    font-weight: bold;\n    font-size: 21px;\n    line-height: 25px;\n    color: #000000;\n  }\n.product-content-data{\n    padding-left: 30px;\n    font-family: \"Roboto-Regular\";\n    font-style: normal;\n    font-weight: 300;\n    font-size: 12px;\n    line-height: 14px;\n    color: #000;\n}\n.Product-Select-Item{\n    /* background-color: #F4F0F0;; */\n    height: 110px;\n    padding-top: 20px;\n    margin-top: 10px;\n    padding-left: 18px;\n}\n.Product-Select-Item h3, h4{\n    font-family: Roboto;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 14px;\n    line-height: 16px;\n}\n.Product-Select-Item h4{\n    transform: rotate(0.14deg);\n    color: #646363;\n}\n.Product-Select-Item h3{\n    transform: matrix(1, 0, 0, 1, 0, 0);\n    color: #353535;\n}\n.select-option{\n    cursor: pointer;\n}\n.product-img{\n    margin-left: 8px;\n}\n.toggle-btn-align {\n    padding-right: 0.75rem;\n    font-family: 'Roboto-Bold';\n    font-size: 12px;\n    color: #000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
