import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Row, Spinner } from 'react-bootstrap';

import { SetErrorMessage, SetSuccessMessage } from '../../../../store/actions';
import { PostCalltoServer } from '../../../../store/utility';
import './connections-tab.css';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import RefreshIcon from '@material-ui/icons/Refresh';
import TooltipCustom from '../../../../components/UI/Tooltip/Tooltip';

function ViewSyncLogModal({
    config,
    
    selectedSubMenu,
    hideViewSyncLogModal,
    showViewSyncLogModal
}) {
    var [gridApi,setGridApi] = useState('');
    const [data, setData] = useState([]);
    const [dataLoading, setDataLoading] = useState(false);
    const [searchText,setSearchText] = useState('')
    const [columnDefs, setColumnDefs] = useState([
         {
            headerName: "Data Source Name",
            field: "dataSourceName",
            editable: false,
            tooltipField: "dataSourceName",
            cellEditor: 'agLargeTextCellEditor',
            cellEditorParams: {
              rows: 4,
              cols: 35
            }
          },
          {
            headerName: "Status",
            field: "status",
            editable: false,
            tooltipField: "errorDetails",
            cellEditor: 'agLargeTextCellEditor',
            cellEditorParams: {
              rows: 5,
              cols: 36
            }
          },
          {
            headerName: "syncStartTime",
            field: "syncStartTime",
            tooltipField: "failedFiles",
            editable: false,
            cellEditor: 'agLargeTextCellEditor',
            cellEditorParams: {
              rows: 5,
              cols: 35
            }
          },
          {
            headerName: "syncEndTime",
            field: "syncEndTime",
            editable: false,
            tooltipField: "failedTickets",
            cellEditor: 'agLargeTextCellEditor',
            cellEditorParams: {
              rows: 4,
              cols: 35
            }
          },
          {
            headerName: "Total Tickets",
            field: "totalTickets",
            editable: false,
            tooltipField: "failedTickets",
            cellEditor: 'agLargeTextCellEditor',
            cellEditorParams: {
              rows: 4,
              cols: 35
            },
            hide: true,
            suppressToolPanel: true
          },
          {
            headerName: "No Of Failed Tickets",
            field: "noOfFailedTickets",
            editable: false,
            tooltipField: "failedTickets",
            cellEditor: 'agLargeTextCellEditor',
            cellEditorParams: {
              rows: 4,
              cols: 35
            },
            hide: true,
            suppressToolPanel: true
          },
          {
            headerName: "Failed Tickets",
            field: "failedTickets",
            editable: false,
            tooltipField: "failedTickets",
            cellEditor: 'agLargeTextCellEditor',
            cellEditorParams: {
              rows: 4,
              cols: 35
            },
            hide: true,
            suppressToolPanel: true
          },
          {
            headerName: "Message",
            field: "message",
            editable: false,
            tooltipField: "failedTickets",
            cellEditor: 'agLargeTextCellEditor',
            cellEditorParams: {
              rows: 4,
              cols: 35
            }
          },
          {
            headerName: "Error Details",
            field: "errorDetails",
            editable: false,
            tooltipField: "failedTickets",
            cellEditor: 'agLargeTextCellEditor',
            cellEditorParams: {
              rows: 4,
              cols: 35
            },
            hide: true,
            suppressToolPanel: true
          },
    ]);
    const [defaultColDef, setDefaultColDef] = useState( {
        flex: 1,
        minWidth: 100,
        resizable: true,
        headerCheckboxSelectionFilteredOnly: true,
        autoHeight: true,
        stopEditingWhenGridLosesFocus: true,
  
      })
    const dispatch = useDispatch();

    useEffect(() => {
      viewSyncLog()
    }, []);
    const viewSyncLog = () =>{
      const configObj = {
        "dataSourceType": "ticket_systems",
        "tenantId": localStorage.getItem("orgName"),
        "projectName": localStorage.getItem("orgName"),
        "applicationUrl": config.CONNECTOR_URL,
        "connector": "",
        "language": "en",
        "dataSourceName": selectedSubMenu
    };
    let url = config.CONNECTOR_URL + `/view-sync-log`;
    setDataLoading(true);
    PostCalltoServer(url, configObj)
        .then(response => {
            if (response.data.status.responseType === "success") {
                const respData = response.data.data;
                setData(response.data.data);
                setData(respData);
                setDataLoading(false);
                dispatch(SetSuccessMessage('Fetched connectors Data Successfully'));
            }
            else {
                dispatch(SetErrorMessage('Error in Fetching Connectors.vvvvvvvvvv'));
                setDataLoading(false);
            }
        }).catch(() => {
            dispatch(SetErrorMessage('Error in Fetching Connectors.'));
            setDataLoading(false);
        });
    }
    const onGridReady = params => {
      setGridApi(params.api)
    };
    const onQuickFilterChanged = (event) => {
      setSearchText(event.target.value);
      var text=''
      text = event.target.value
      if(event.target.value.length>0)
      gridApi.setQuickFilter(text);
      else
      gridApi.setQuickFilter(" ");
    };
    return (
        <React.Fragment>
            {showViewSyncLogModal ?
                <div className='view-sync-log-modal'>
                <Modal
                    show={showViewSyncLogModal} 
                    onHide={hideViewSyncLogModal}
                    className='scrollable-modal'
                    size="xl"
                    >
                    <Modal.Header closeButton>
                        <Modal.Title>Connector Sync Log</Modal.Title>
                        <span style={{position:"absolute",right:"240px",top:"23px",backgroundColor:"#b6cfe9"}}>
                          <a onClick={() => viewSyncLog()}><TooltipCustom tooltext="Reload Sync Log" direction="top"><RefreshIcon /></TooltipCustom></a>
                        </span>
                        <input type="search" value = {searchText} onChange={(event) => onQuickFilterChanged(event)} placeholder = "Search"
                          style={{height:"30px",borderRadius:"3px",borderColor:"#f8f9fa",borderWidth:"1px",borderRightColor:"#ada4aa",
                          borderBottomColor:"#ada4aa", marginRight:"5px",marginTop:"5px",position:"absolute",right:"35px"}}>
                        </input>
                    </Modal.Header>
                    {dataLoading ?
                        <Row className="justify-content-md-center padding10">
                            <Spinner animation="border" variant="primary" />
                        </Row> :
                        <Modal.Body>  
                            <div className="ag-theme-alpine">
                                <AgGridReact
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    onGridReady={onGridReady}
                                    rowData={data}
                                />
                            </div>
                        </Modal.Body>
                    }
                </Modal></div> : null}
        </React.Fragment>
    );
}

export default ViewSyncLogModal;