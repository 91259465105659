import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { encryptAndStore } from '../../../../utility/utility';
import Nav from 'react-bootstrap/Nav';
import *as actions from '../../../../store/actions/index'
import InputWithLabelComponent from '../components/InputWithLabelComponent/InputWithLabelComponent';
import TextAreaWithLabelComponent from '../components/TextAreaWithLabelComponent/TextAreaWithLabelComponent';
import ButtonComponent from '../../../../components/UIComponents/Button/ButtonComponent';
import './CreateUseCase.css';
import ReactSelectComponent from '../components/ReactSelectComponent/ReactSelectComponent';
import {  useSelector, useDispatch } from "react-redux";
import { createUseCase } from '../../../../store/actions/index';



const CreateUseCaseDrawer = (props) => {
const [useCaseName , setUseCaseName] = useState('');
const [description , setDescription] = useState('');
const [selectModel , setSelectModel] = useState('Select Model')
const[validation,setValidation]=useState('');
const ModelOptions = [{ value: "Model", label: "Model"}];
const dispatch = useDispatch();

const modelDropDown= (e) => {
        setSelectModel (e.value);
}
const onChangeHandler = (e) =>{
    setDescription(e.target.value)
}
const handleInputs = (e) =>{
    setUseCaseName(e.target.value)
}
const onCreateUsecase = () =>{
    setValidation('yes')
    if(useCaseName.length>0 && description.length>0){
    let data= {
            "useCaseName": useCaseName,
            "description": description,
            "project": localStorage.getItem('projectname'),
            "orgName": localStorage.getItem('orgName'),
            "model": selectModel
        
            }
        dispatch(createUseCase('http://10.14.108.21:32470',data));}
        const usecasecreated = "false";
        props.onDataPassed(usecasecreated);
}
    
    return (
        <React.Fragment >
            <div className="col-md-8  pb-3">
                <InputWithLabelComponent
                    style={{ marginBottom: "5px" , fontFamily:'Roboto'}}
                    placeHolder=''
                    className='drawer-input'
                    type="text"
                    name="name"
                    label="Use Case Name"
                    value={useCaseName}
                    onChange={(e) => handleInputs(e,'languageName')}
                    // isInvalid={this.state.projectValidationMsg ? true : false}
                    // error={this.state.projectValidationMsg}
                    maxLength='25'
                    required={true}

                />
                <div>{validation=="yes" && useCaseName.length==0 ?<span style={{color:"red",fontFamily:"roboto-regular",fontSize:"11px"}}>Enter valid UseCase Name</span> : null}</div>
            </div>
            <div className="col-md-8  pb-3">
                <TextAreaWithLabelComponent
                    style={{fontFamily:'Roboto'}}
                    label="Description"
                    name="message"
                    required={true}
                    value={description}
                    className='text-area'
                // placeholder='Enter Text Area Content.'
                onChange={(e) =>onChangeHandler(e, 'message')}
                />
                <div>{validation=="yes" && description.length==0 ?<span style={{color:"red",fontFamily:"roboto-regular",fontSize:"11px"}}>Enter valid Description</span> : null}</div>
            </div>
            <div className="col-md-8  pb-5">
            <span className="reportHeading" style={{ color: "grey",fontFamily:'Roboto-Bold' }}>Select Model</span>
            <ReactSelectComponent
                    id="select"
                    customClassName="Select Model multi_select"
                    value={{value: selectModel, label: selectModel}}
                    options={ModelOptions}
                    placeholder='Select the model'
                    // value={{ value: selectedLang, label: selectedLang }}
                    // options={languagelist && languagelist.map(user => ({ value: user.languagename, label: user.languagename }))}
                    name="project"
                    onChange={(event) => modelDropDown(event)}
                    >
                </ReactSelectComponent>
                </div>
            <div className='CreateButton'>
                <span className='nextButton pt-5 mb-1 pr-5'>

                    <ButtonComponent 
                    style={{fontFamily:'Roboto-Bold'}}
                    variant='outlined'
                        label='CANCEL' className='buttonClass dld-btn'
                    // clicked={() => { this.exportData() }} 
                    />
                </span>
                <span className='createbutton pt-4 mt-2'>
                    <ButtonComponent
                        style={{fontFamily:'Roboto-Bold'}}
                        label='CREATE'
                        variant='contained'
                        clicked={() => {onCreateUsecase() }}
                        className='buttonWidth add-lang-button'
                        disabled={false}
                    />
                </span>
            </div>
        </React.Fragment>
    )
}
const mapStateToProps = state => {
    return {
        languageList: state.user.languageList,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        OnGetLanguage: (apiUrl) => dispatch(actions.GetLanguage(apiUrl)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateUseCaseDrawer);


