import React, { memo } from 'react';
import { Handle } from 'react-flow-renderer';
import NodeBox from './NodeBox';

export default memo(({ data, isConnectable }) => {

  return (
    <>
      <Handle
        type="target"
        position="top"
        style={{ background: '#555' }}
        isConnectable={isConnectable}
      />
      <NodeBox type='Confirm' data={data}></NodeBox>
      <Handle
            type="source"
            position="bottom"
            id="Yes"
            style={{ left: 224/3, background: '#555' }}
            isConnectable={isConnectable}
      />
      <Handle
          type="source"
          position="bottom"
          id="No"
          style={{ left: 224/3 * 2, background: '#555' }}
          isConnectable={isConnectable}
      />
      <Handle
          type="source"
          position="bottom"
          id="Unknown"
          style={{ left: 0, background: '#555' }}
          isConnectable={isConnectable}
      />
      
    </>
  );
});