import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Row } from 'react-bootstrap';
import './conversationMgmt.css';
import InputWithLabelComponent from '../components/InputWithLabelComponent/InputWithLabelComponent';
import ButtonCustom from '../../../../components/UIComponents/Button/ButtonComponent';


const ShowMore = () => {

    const [faqCheck, setFaqCheck] = useState(true);
    const [maxLength, setMaxLength] = useState(120);

    const resetHandler = () => {
        setFaqCheck(true);
        setMaxLength(120);
    }

    const saveHandler = () => {
        //update when save api available.
    }

    return (
        <div className='conversationTabContent'>
            <Row className='paddingT2'>
                <span className='label'>Enable Show More for FAQ Responses</span>
                <Form>
                    <Form.Check
                        type="switch"
                        id="showMoreFaq"
                        checked={faqCheck}
                        onChange={e => setFaqCheck(e.target.checked)}
                    />
                </Form>
            </Row>
            {faqCheck && (
                <Row className='paddingT2'>
                <span className='mt-1 label withInputField'>Maximum character length of content to be displayed</span>
                <InputWithLabelComponent
                        type="text"
                        name='maxLength'
                        showLabel={false}
                        value={maxLength}
                        readOnly={false}
                        onChange={e => setMaxLength(e.target.value)}
                        required={true}
                        className="shortLength"
                    />
                </Row>
            )}
            
            <Row className='paddingT3'>
                <ButtonCustom
                    variant='outlined'
                    label='Reset'
                    className='buttonClass mr-3 reset-btnFaq'
                    clicked={resetHandler}
                />
                <ButtonCustom
                    variant='contained'
                    label='Save'
                    className='buttonWidth'
                    clicked={saveHandler}

                />
            </Row>
        </div>
    )
}

export default ShowMore;