import React, { useState, useEffect } from 'react';
import classesName from './Cognitive.module.css'
import { connect } from 'react-redux';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import pdf from './image/pdf.png';
import mp3 from './image/mp3.png';
import mp4 from './image/mp4.png';
import jpeg from './image/jpeg.png';
import png from './image/png.png';
import text from './image/text.png';
import xls from './image/xls.png';
import doc from './image/doc.png';
import ppt from './image/ppt.png';
import csv from './image/csv.png';
import html from './image/html.png';
import linkicon from './image/linkicon.png'
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import StarRateIcon from '@mui/icons-material/StarRate';
import './Cognitive.css'
import Tooltip from '@material-ui/core/Tooltip';
import {withStyles } from '@material-ui/core';
import Typography from "@material-ui/core/Typography";
import DOMPurify from "dompurify";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor:"black",
    color: "white",
    boxShadow: theme.shadows[1],
    fontSize: 12,
    margin:-8 -2,
  },
}))(Tooltip);
const styles = theme => ({
  description:{
fontSize:"12px"
},
descp:{
  color:"white"
}
});

const Lists = (props) => { 
  const [likeclolorFlag, setLikeclolorFlag] = useState("false")
  const [dislikeclolorFlag, setDisLikeclolorFlag] = useState("false")
  const [showMore, setShowMore] = useState(false)
  const [showmore,setShowmore] = useState(false)
  var score = parseInt(props.score*100)

  useEffect (() => {
    // if(props.index>=0)
    //   document.getElementsByClassName(props.index)[0].innerHTML += props.para
  }, [])

  const clickMe =(e,index,name)=>{
    let para;
    let showmore = false;
    if(name=='showmore'){
      showmore = true;
       para = props.common[index].para;
    }
    props.drawerShow(props.title,props.fileType,props.encryptedContent,props.user,props.common.length,para,showmore,props.common)
  }
  const feedback =(feedback)=>{
    console.log("feedback",feedback)
    if(feedback=="true"){
      setLikeclolorFlag("true")
      setDisLikeclolorFlag("false")
    }
    else{
      setDisLikeclolorFlag("true")
      setLikeclolorFlag("false")
    }
    //console.log("user = ",props.user)
    props.feedback(feedback,props.user)
  }

  const showMorePara = (index) =>{
    showMore == true?setShowMore(false):setShowMore(true)
    props.onChangeBorder(index)
    
  }
  const showmorepara = (index) =>{
    showmore == true?setShowmore(false):setShowmore(true)
    
  }

  const { classes } = props;

  return (
    <>
      {(props.response == "Please rephrase your question") ? <p className={classesName.response} >{props.response}</p> : <>
        <div onClick={!props.sourceURL && props.type ? clickMe : ''}
          style={{
            cursor: !props.sourceURL && props.type ? "pointer" : "auto", pointerEvents: !props.sourceURL && props.type ? '' : 'none',
            overflowY: "auto", overflowX: "hidden", size :"95vh",paddingRight: props.showFile ? "5px" : ''
          }}>
          {props.user.sh_ans != null && showmore == false ?
            <span style={{size:"95vh"}} className={classesName.underline}><div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.user.sh_ans) }} /></span> :
            (props.user.html !=null && props.user.html != "") ?
            <span style={{size:"95vh"}} className={classesName.underline}><div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.user.html) }} /></span>  :
            <span style={{size:"95vh"}} className={classesName.underline}><div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.user.para) }} /></span>
          }
        </div>
        {/* <div style={{paddingRight:"5px"}}>
          <a onclick={setShowmore()}style={{float:"right", marginBottom:"15px",fontSize:"10px",color:'blue'}}>SHOW MORE</a>
          <a style={{ display: showmore == false ? "" : "none", cursor: "pointer", float: "right", marginBottom: "15px", fontSize: "10px", color: 'blue' }} onClick={() => showmorepara(props.index)}>SHOW MORE</a>
          <a style={{ display: showmore == true ? "" : "none", cursor: "pointer", float: "right", marginBottom: "15px", fontSize: "10px", color: 'blue' }} onClick={() => showmorepara(props.index)}>SHOW LESS</a>
        </div> */}
        <div style={{ display:"flex",float:"left",marginTop: "30px", marginBottom: "15px" }}>
          {(props.type == "mp4") ? <img src={mp4} className={classesName.imageIcon}  style={{marginRight:"3px"}}/> : ''}
          {(props.type == "pdf") ? <img src={pdf} className={classesName.imageIcon} style={{marginRight:"3px"}}/> : ''}
          {(props.type == "mp3") ? <img src={mp3} className={classesName.imageIcon} style={{marginRight:"3px"}}/> : ''}
          {(props.type == "jpeg") ? <img src={jpeg} className={classesName.imageIcon}style={{marginRight:"3px"}} /> : ''}
          {(props.type == "jpg") ? <img src={jpeg} className={classesName.imageIcon} style={{marginRight:"3px"}}/> : ''}
          {(props.type == "png") ? <img src={png} className={classesName.imageIcon} style={{marginRight:"3px"}}/> : ''}
          {(props.type == "text") ? <img src={text} className={classesName.imageIcon}style={{marginRight:"3px"}} /> : ''}
          {(props.type == "txt") ? <img src={linkicon} className={classesName.imageIcon} style={{marginRight:"3px"}}/> : ''}
          {(props.type == "xls") ? <img src={xls} className={classesName.imageIcon} style={{marginRight:"3px"}}/> : ''}
          {(props.type == "xlsx") ? <img src={xls} className={classesName.imageIcon} style={{marginRight:"3px"}}/> : ''}
          {(props.type == "doc") ? <img src={doc} className={classesName.imageIcon} style={{marginRight:"3px"}}/> : ''}
          {(props.type == "docx") ? <img src={doc} className={classesName.imageIcon} style={{marginRight:"3px"}}/> : ''}
          {(props.type == "ppt") ? <img src={ppt} className={classesName.imageIcon} style={{marginRight:"3px"}}/> : ''}
          {(props.type == "pptx") ? <img src={ppt} className={classesName.imageIcon}style={{marginRight:"3px"}} /> : ''}
          {(props.type == "csv") ? <img src={csv} className={classesName.imageIcon} style={{marginRight:"3px"}}/> : ''}
          {(props.type == "html") ? <img src={html} className={classesName.imageIcon} style={{marginRight:"3px"}}/> : ''}
          {props.sourceURL && props.type == 'pdf' ? <a className={classesName.underline} onClick={props.sourceURL ? clickMe : ''} style={{ fontSize: "15px", fontWeight: "bold", color: "#3362CC", fontFamily: "Roboto-Regular", height: "40px",paddingRight:"5px"}}>{props.title}</a> : null}
          {props.sourceURL && props.type !== 'pdf' ? <a href={props.sourceURL} target="_blank" >
            <span className={classesName.underline} style={{ fontSize: "15px", fontWeight: "bold", color: "#3362CC", fontFamily: "Roboto-Regular", height: "40px" }}>{props.title}</span>
          </a> : null}
          {!props.sourceURL && props.type ?
            <a className={classesName.underline} onClick={clickMe} style={{ pointerEvents: !props.sourceURL && props.type ? '' : 'none' , paddingRight:"5px",paddingTop:"3px"}}>
              <span style={{ fontSize: "12px", fontWeight: "bold", color: !props.sourceURL && !props.type ? 'black' : "#3362CC", cursor: !props.sourceURL && props.type ? "pointer" : "auto" }}>
                &nbsp;{props.title}</span>
            </a> : null
          }
          {
            (props.common.length !== 0) &&
            <span >
              <div style={{ height: "20px", width: "0px", border: "1px solid gray", display: "inline", marginLeft: "5px", backgroundColor: "gray" }}></div>
              <span className={classesName.underline} style={{ fontSize: "12px", fontWeight: "900", color: "#3362CC", marginLeft: "10px", cursor: "pointer", fontFamily: "Roboto-Regular", height: "40px" }} onClick={() => showMorePara(props.index)}>{props.common.length} more matches&nbsp;</span><strong>found</strong>
              <span style={{ display: showMore == false ? "" : "none", cursor: "pointer" }} onClick={() => showMorePara(props.index)}><ArrowRightIcon></ArrowRightIcon></span>
              <span style={{ display: showMore == true ? "" : "none", cursor: "pointer" }} onClick={() => showMorePara(props.index)}><ArrowDropDownIcon></ArrowDropDownIcon></span>
            </span>

          }
          <div style={{display:"flex",border:"1px solid #CBE2F2",borderRadius: "15px",height:"25px",width:"100px",textAlign:"center",alignItems:"center",marginTop:"-2px",backgroundColor:"#F2F2F2"}}>
          <StarRateIcon style={{ color:" #CBC8C8",fontSize: "16px",paddingBottom:"2px",paddingLeft:"3px" }} />
          <LightTooltip arrow 
          title={
            <>
              <Typography variant="h6" className={classes.descp} >What is this?</Typography>
              <Typography className={classes.description}>We have calculated score based upon the relevancy of the document to your query. The scores have been calculated out of 100. Please provide feedback also to improve the future search results</Typography>
            </>
          }
           placement="right">
          <span style={{fontWeight:"bold",paddingLeft:"3px", paddingBottom:"0px",fontFamily:"Roboto-Regular",color:"#25405E",fontSize:"11px"}}>SCORE &nbsp;<span style={{color:"#2A93DF",fontSize:"12px",paddingRight:"4px",fontWeight:"bold",fontFamily:"Roboto-Regular"}}>{score}</span></span>
          </LightTooltip>
          <StarRateIcon style={{ color:" #CBC8C8",fontSize: "16px",paddingBottom:"2px", }} />
          </div>
          {/* <div style={{ float: "right", position: "relative", top: "-20px", height: "40px", marginRight: "-15px", marginLeft: "10px" }}>
            <ProgressBar
              radius={13}
              progress={score}
              strokeWidth={2}
              strokeColor="#022D8E"
              strokeLinecap="square"
              trackStrokeWidth={2}
            >
              <div className={classesName.indicator} style={{ width: "60px" }}>
                <div style={{ position: "relative", left: "2px", fontSize: "12px", color: "#022D8E" }}>{score}</div>
              </div>
            </ProgressBar>
          </div> */}
          {/* <text
            style={{
              float: "right", width: "39px", height: "19px", textAlign: "center", position: "relative", backgroundColor: "#022D8E",
              color: "white", marginRight: "-20px", marginTop: "3px", paddingTop: "1px"
            }}>score
          </text> */}
          {/* <div className='menudiv' style={{display:"flex"}}>
          <span style={{fontSize:"12px",paddingTop:"5px",paddingRight:"5px"}}>Did this Help?</span>
          <div style={{
            float: "left", marginRight: "10px", height: "30px", width: "83px", position: "relative", backgroundColor: "white", borderRadius: "15px", border: "1px solid #BAB5B5",
            marginTop: "-2px", paddingTop: "2px", display: "flex", justifyContent: "center"
           }}>

            <div style={{
              height: "25px", width: "25px", backgroundColor: "white", color: likeclolorFlag == true ? "green" : "#bababa", float: "right", padding: "3px", marginRight: "5px",
              borderRadius: "15px"
            }}>
              <a onClick={() => feedback(true)}><ThumbUpIcon style={{ fontSize: "18px" }} /></a>
            </div>
            <div style={{
              height: "25px", width: "25px", backgroundColor: "white", color: dislikeclolorFlag == true ? "red" : "#bababa", float: "right", padding: "3px",
              borderRadius: "15px"
            }}>
              <a onClick={() => feedback(false)}><ThumbDownIcon style={{ fontSize: "18px" }} /></a>
            </div>
          </div>
          </div> */}
          
        </div>
        <div className='menudiv' style={{display:"flex"}}>
        {props.user.sh_ans != null && props.user.sh_ans != '' ?
        <div style={{paddingRight:"5px",color:"#3362CC"}}>
          {/* <a onclick={setShowmore()}style={{float:"right", marginBottom:"15px",fontSize:"10px",color:'blue'}}>SHOW MORE</a> */}
          <a className='showmore' style={{ display: showmore == false ? "" : "none", cursor: "pointer", float: "right", marginBottom: "15px", fontSize: "11px", color: '#3362CC' ,fontFamily:"Roboto-Regular"}} onClick={() => showmorepara(props.index)}>SHOW MORE</a>
          <a className='showmore'style={{ display: showmore == true ? "" : "none", cursor: "pointer", float: "right", marginBottom: "15px", fontSize: "11px", color: '#3362CC',fontFamily:"Roboto-Regular" }} onClick={() => showmorepara(props.index)}>SHOW LESS</a>
        </div>:null}
          <div className='feedbackDiv'>
          <span style={{fontSize:"12px",paddingRight:"5px",color:"#25405E"}}>Did this Help?</span>
          <div style={{
            float: "left",height: "30px", width: "83px", position: "relative", backgroundColor: "#F2F2F2", borderRadius: "15px", 
            // border: "1px solid #BAB5B5",
            marginTop: "-8px", paddingTop: "2px", display: "flex", justifyContent: "center"
           }}>

            <div style={{
              height: "25px", width: "25px", backgroundColor: "#F2F2F2", color: likeclolorFlag == "true" ? "green" : "#bababa", float: "right", padding: "3px", marginRight: "5px",
              borderTopLeftRadius : "14px",borderBottomLeftRadius:"14px",borderRight:"1px solid #D5D4D4"
            }}>
              <a onClick={() => feedback("true")}><ThumbUpIcon style={{ fontSize: "18px"}} /></a>
            </div>
            <div style={{
              height: "25px", width: "25px", backgroundColor: "#F2F2F2", color: dislikeclolorFlag == "true" ? "red" : "#bababa", float: "right", padding: "3px",
              borderTopRightRadius:"14px",borderBottomRightRadius:"14px"
            }}>
              <a onClick={() => feedback("false")}><ThumbDownIcon style={{ fontSize: "18px" }} /></a>
            </div>
          </div>
          </div>
          </div>
        {
          showMore && props.common && props.common.map((item,index)=>{
              return(
                <div style={{overflowY:"hidden",width:"100%", marginBottom: "12px"}}><hr style={{border:"1 solid #b4b4b4"}}></hr>
                <a  className={classesName.underline} onClick={(e) =>clickMe(e,index,'showmore')} style={{color:"black"}}><div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.para) }} style={{maxHeight:"50px"}}/></a>
                </div>
              )          
          })
        }
        </>}
     </>
  )
};   
const mapStateToProps = state => {
	return {
    inValue:state.cognitive.input,
  }
};
export default withStyles(styles)(connect(mapStateToProps)( Lists));
