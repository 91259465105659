import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import classes from "./ProjectViewSidebar.module.css";
import "../../UI/RsDropDown/RsDropDown.css";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";
import "./ProjectSidebar.css";
import permissions from "../../../permissions_Json";
import { useDispatch } from "react-redux";
import { encryptAndStore } from "../../../utility/utility";

let flowNodes = [];

//botList
const Projectviewsidebar = (props) => {
  const [newbots, setNewbotlist] = useState([]);
  const [profileValues, setprofileValues] = useState([]);
  const [expandedTree, setExpandedTree] = useState(["Intents"]);
  const [selectedLabel, setSelectedLabel] = useState("Intents");
  const [expanded, setExpanded] = React.useState(["7"]);
  const [selected, setSelected] = React.useState([]);
  // const [selectedBot, setSelectedBot] = useState("Intents");
  const [selectedBot, setSelectedBot] = useState(props.selectedCUXBotId); //to highlight selected bot
  const [expandedBot, setExpandedBot] = useState([""]); //holds list of Bots to show in treeItem list
  const [showBotList, setShowBotList] = useState(true);

  const dispatch = useDispatch();
  let projectsideBarAccess = false;
  let projectReadStatus = props.permissions.find(data => {
    if (data.permission_code.includes(permissions.permissionCode.CONVERSATIONAL_AI_STANDARD_R) && data.sku_code.includes(permissions.skuCode.AI))
      return true
  })

  projectsideBarAccess = projectReadStatus ? true : false;

  useEffect(() => {
    let orgname = localStorage.getItem("orgName");
    if (props.botOrgList.includes(orgname)) {
      setShowBotList(false);
    }
    props.currentBotEvent(
      true,
      "Intentlist",
      "Intentlist__Intentlistprofile",
      true
    );
  }, []);

  useEffect(()=>{
    if (props.otherDocsPermission.WR && !props.permissionsAIStandard.RW) {
      setSelectedLabel("Others");  
      setExpandedTree(['1'])
    } 
  },[props.otherDocsPermission.WR,props.permissionsAIStandard.RW])

  useEffect(()=> {
    if(props.isCUXBotSelected) {
      setSelectedLabel('Flows');
    }
  },[props.isCUXBotSelected]);

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
    setExpandedTree(["Documents"]);
    setExpandedBot([]);
  };

  const onNodeChange = (e, nodes) => {
    setExpandedTree(nodes);
    setExpanded([]);
    setExpandedBot([]);

  };

  const selectedNode = (nodeName) => {
    if (nodeName.target.innerHTML == "FAQs") {
      props.selectKnowledgeIntent(true, "Knowledge");
      setSelectedLabel(nodeName.target.innerHTML);
    } else if (
      nodeName.target.innerHTML == "Others"
    ) {
      props.selectKnowledgeIntent(true, "OtherDocs");
      setSelectedLabel(nodeName.target.innerHTML);
    } else if (
      nodeName.target.innerHTML == "Multimedia"
    ) {
      props.selectKnowledgeIntent(true, "Multimedia");
      setSelectedLabel(nodeName.target.innerHTML);
    }else if (nodeName.target.innerHTML.includes("Synonyms")) {
      props.selectKnowledgeIntent(true, "Synonyms & Acronyms");
      setSelectedLabel(nodeName.target.innerHTML);
    }else if (nodeName.target.innerHTML.includes("Intents")) {
      props.selectKnowledgeIntent(true, "Intents");
      setSelectedLabel(nodeName.target.innerHTML);
    } else if (nodeName.target.innerHTML.includes("Flows")) {
      props.showCuxFlowMenu();
      setSelectedLabel(nodeName.target.innerHTML);
    } else {
      return true;
    }
  };

  const selectedNodeReDirect = (nodeName) => {
    if (nodeName == 'FAQs') {
      props.selectKnowledgeIntent(true, 'Knowledge')
      setSelectedLabel(nodeName)
    } else if (nodeName == 'Others') {
      props.selectKnowledgeIntent(true, 'OtherDocs')
      setSelectedLabel(nodeName)
    }else if (nodeName == 'Synonyms & Acronyms') {
      props.selectKnowledgeIntent(true, 'Synonyms & Acronyms')
      setSelectedLabel(nodeName)
    }else if (nodeName == "Intents") {
      props.selectKnowledgeIntent(true, 'Intents');
      setSelectedLabel(nodeName);
    }
    else {
      return true;
    }
  }

  useEffect(() => {
    if (bots.length > 0) {
      let updatedbots = [];
      for (let i = 0; i < bots.length; i++) {
        updatedbots.push({ name: bots[i], expanded: false, tboxshow: false });
      }
      if (updatedbots.length > 0) {
        setNewbotlist(updatedbots);
      }
    }

    if (profileValue1.length > 0) {
      let updatedprofilevals = [];
      for (let i = 0; i < profileValue1.length; i++) {
        updatedprofilevals.push({ name: profileValue1[i], expanded: false });
      }
      setprofileValues(updatedprofilevals);
    }
  }, [
    props.botList,
    localStorage.getItem("projectname"),
    localStorage.getItem("botList"),
  ]);

  // useEffect(() => {
  //   if (props.isFlowSelectedFlow == true) {
  //     setExpanded(["1"]);
  //     setExpandedTree([""]);
  //     setSelectedLabel("");
  //   }
  // }, [props.isFlowSelectedFlow == true]);

  // useEffect(() => {
  //   if (props.isFaqNavigation == true) {
  //     setExpanded([]);
  //     setExpandedTree(['1', '']);
  //     selectedNodeReDirect('FAQs')
  //   }
  // }, [props.isFaqNavigation == true])

  // useEffect(() => {
  //   if (props.isOtherDocsNavigation == true) {
  //     setExpanded([]);
  //     setExpandedTree(["1", '']);
  //     selectedNodeReDirect('Others')
  //   }
  // }, [props.isOtherDocsNavigation == true])

  // useEffect(() => {
  //   if (props.isSynonymsNavigation == true) {
  //     setExpanded([]);
  //     setExpandedTree(["1", '']);
  //     selectedNodeReDirect('Synonyms & Acronyms')
  //   }
  // }, [props.isSynonymsNavigation == true])

  // useEffect(() => {
  //   let projectList = null;
  //   let profileKey = null;
  //   let selBot = [];
  //   let profileValue1 = [];

  //   if (props.botList && props.botList != undefined && projectsideBarAccess) {
  //     if (props.projectList && props.projectList != undefined) {
  //       let grpprojectlang = props.projectList.bots;
  //       console.log("props.projectList.bots == ",props.projectList.bots)
  //       console.log("props.botList === ",props.botList)
  //       let botlist = null;
  //       if (props.botList) {
  //         botlist = props.botList;
  //       }
  //       let language = localStorage.getItem("language");
  //       if (!language || language == "en") language = "en";
  //       let botlanguage = null;
  //       if (grpprojectlang && grpprojectlang.length > 0) {
  //         botlanguage = botlist[language];
  //       }
  //       if (botlanguage) {
  //         if (botlanguage.hasOwnProperty(projectname)) {
  //           projectList = botlanguage[projectname];
            
  //           if (projectname !== "common") {
  //             bots = Object.keys(projectList);
  //             const index = bots.indexOf("Intentlist");
  //             if (index > -1) {
  //               bots.splice(index, 1);
  //             }
  //             console.log("projectList == ",bots)
  //             bots.sort(
  //               new Intl.Collator("en", {
  //                 numeric: true,
  //                 sensitivity: "accent",
  //               }).compare
  //             );
  //             bots.forEach((data) => {
  //               if (
  //                 // data.includes("Intentlist") ||
  //                data.includes("unanswered")) {
  //                 return selBot.push(data);
  //               }
  //               return true
  //             });
  //             props.currentBotEvent(
  //               true,
  //               selBot[0],
  //               projectList[selBot[0]][0]["profile"],
  //               true
  //             );
  //             profileKey = Object.values(projectList);
  //             profileKey.map((item) => {
  //               item.filter((data) => {
  //                 if (Object.keys(data).includes("profile")) {
  //                   return profileValue1.push(data.profile);
  //                 }
  //               });
  //             });
  //           } else {
  //             bots = Object.keys(projectList);
  //             bots.sort(
  //               new Intl.Collator("en", {
  //                 numeric: true,
  //                 sensitivity: "accent",
  //               }).compare
  //             );
  //             bots.forEach((data) => {
  //               if (
  //                 data.includes("casual") ||
  //                 data.includes("scheduler") ||
  //                 data.includes("salutation") ||
  //                 data.includes("currentweather") ||
  //                 data.includes("greeting") ||
  //                 data.includes("thankyou") ||
  //                 // data.includes("Intentlist") ||
  //                 data.includes("unansweredbot")
  //               ) {
  //                 return selBot.push(data);
  //               }
  //               return true;
  //             });
  //             props.currentBotEvent(
  //               true,
  //               selBot[0],
  //               projectList[selBot[0]][0]["profile"],
  //               true
  //             );
  //             profileKey = Object.values(projectList);
  //             profileKey.map((item) => {
  //               item.filter((data) => {
  //                 if (Object.keys(data).includes("profile")) {
  //                   return profileValue1.push(data.profile);
  //                 }
  //               });
  //             });
  //           }
  //         }
  //         // setExpandedTree([""]);
  //         // setSelectedLabel("");
  //         // setExpanded(["1"]);
  //       }
  //     }
  //   }
  // }, [
  //   localStorage.getItem("projectname"),
  //   props.projectList,
  //   props.botdeleted == true,
  // ]);

  let projectname = localStorage.getItem("projectname");
  if (!projectname) projectname = "Common";
  let language = localStorage.getItem("language");
  if (!language || language == "en") language = "en";
  let templanglist = [];
  let orgName = localStorage.getItem("orgName");
  if (props.setting !== undefined && props.setting !== null) {
    let orgData = props.setting.settingProperties.orgMapping.replace(/\\/g, "");
    let orgList = JSON.parse(orgData);
    orgData = Object.keys(JSON.parse(orgData));
    if (
      orgList[orgName] &&
      orgList[orgName] !== undefined &&
      orgList[orgName].length > 0
    ) {
      orgList[orgName].map((option) => {
        return templanglist;
      });
    }
  }

  const selectedItem = (nodeName, botName) => {
    if (nodeName == "Delete") {
      props.deleteBot({
        botName: botName,
        profile: botName + "__",
        projectName: projectname,
        language: language ? language : "en",
      });
    } else if (nodeName == "Export") {
      props.exportBot({
        botName: botName,
        language: language ? language : "en",
        training: "df",
        project: projectname,
        profilename: botName + "__",
      });
    }
  };

  let langList = [];
  if (
    props.setting &&
    props.setting !== undefined &&
    props.setting.settingProperties
  ) {
    let language_array = props.setting.settingProperties.language.split(",");
    for (let i = 0; i < language_array.length; i++) {
      let value = language_array[i].split("_")[1];
      langList.push(value);
    }
  }

  let botListLi = null;
  let bots = [];
  let projectList = null;

  let profileKey = null;
  let profileValue1 = [];

  if (props.botList && props.botList != undefined && projectsideBarAccess) {
    if (props.projectList && props.projectList != undefined) {
      let grpprojectlang = props.projectList.bots;
      let botlist = null;
      if (props.botList) {
        botlist = props.botList;
      }
      let botlanguage = null;
      if (grpprojectlang && grpprojectlang.length > 0) {
        botlanguage = botlist[language];
      }

      if (botlanguage) {
        if (botlanguage.hasOwnProperty(projectname)) {
          projectList = botlanguage[projectname];
          bots = Object.keys(projectList);
          const index = bots.indexOf("Intentlist");
              if (index > -1) {
                bots.splice(index, 1);
              }
          bots.sort(
            new Intl.Collator("en", { numeric: true, sensitivity: "accent" })
              .compare
          );
          profileKey = Object.values(projectList);
          profileKey.map((item) => {
            item.filter((data) => {
              if (Object.keys(data).includes("profile")) {
                return profileValue1.push(data.profile);
              }
            });
          });
          if (!props.currentBotSelected && bots.length > 0) {
            let botName = bots[0];
            if (!props.isKnowledgeContentSelected)
              setTimeout(() => {
                props.selectKnowledgeIntent(
                  false,
                  bots[0],
                  projectList[botName][0]["profile"]
                );
              }, 500);
          }

          if (newbots.length == 0) {
            let updatedbots = [];
            console.log("bots === ",bots)
            for (let i = 0; i < bots.length; i++) {
              updatedbots.push({
                name: bots[i],
                expanded: false,
                tboxshow: false,
              });
            }
            if (updatedbots.length > 0) {
              setNewbotlist(updatedbots);
            }
          }
          if (profileValues.length == 0) {
            let updatedprofilevals = [];
            for (let i = 0; i < profileValue1.length; i++) {
              updatedprofilevals.push({
                name: profileValue1[i],
                expanded: false,
              });
            }
            if (updatedprofilevals.length > 0) {
              setprofileValues(updatedprofilevals);
            }
          }
        } else {
          bots = [];
          projectList = [];
        }
      }
      // localStorage.setItem("botList", bots);
      encryptAndStore("botList", bots);

      botListLi =
        newbots &&
        newbots.length > 0 &&
        newbots.map((item, i) => {
          let flowValue =
            item.name.length > 20 ? item.name.slice(0, 20) + "..." : item.name;
          if (
            localStorage.getItem("projectname") == "common" &&
            (item.name.includes("casual") ||
              item.name.includes("currentweather") ||
              item.name.includes("greeting") ||
              item.name.includes("scheduler") ||
              item.name.includes("thankyou") ||
              item.name.includes("salutation"))
          ) {
            flowNodes = [
              { name: "Export", disabled: true },
              { name: "Delete", disabled: true },
            ];
          } else {
            if (item.name.includes("unanswered")
              // item.name.includes("unanswered") ||
              // item.name.includes("Intentlist")
            ) {
              flowNodes = [
                { name: "Export", disabled: true },
                { name: "Delete", disabled: true },
              ];
            } else {
              flowNodes = [
                { name: "Export", disabled: false },
                { name: "Delete", disabled: false },
              ];
            }
          }
          return item.name === "faq" ? (
            ""
          ) : (
            <li
              key={i}
              className={
                props.currentBotSelected == item.name
                  ? "activeTree"
                  : "treeInnerLabel"
              }
              onClick={() => {
                props.currentBotEvent(
                  true,
                  item.name,
                  projectList[item.name][0]["profile"],
                  true
                ); setSelectedBot();} //to empty CD flow selection
              }
            >
              <span className={classes.flowspan} title={item.name}>
                {flowValue}
              </span>
              <Dropdown className={classes.flowListsDropdown}>
                <Dropdown.Toggle className={classes["dropdown-toggle"]}>
                  <img
                    src="./Icons/ActionMenu.svg"
                    className={
                      props.currentBotSelected == item.name
                        ? classes.dotsMenu
                        : classes.dotsMenu2
                    }
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu className={classes.dropMenu}>
                  <div className={classes.dropMenuInner}>
                    {flowNodes
                      ? flowNodes.length > 0 &&
                      flowNodes.map((items) => {
                        return (
                          <Dropdown.Item
                            disabled={items.disabled}
                            className={`sideDropdown ${!props.permissionsAIStandard.RW && 'click-access'}`}
                            eventKey={items.name}
                            key={items.name}
                            onClick={() => selectedItem(items.name, item.name)}
                          >
                            {items.name}
                          </Dropdown.Item>
                        );
                      })
                      : null}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          );
        });
    }
  }

  // useEffect(() => {
  //   if (projectsideBarAccess) {
  //     setExpandedTree([""]);
  //     setSelectedLabel("");
  //     setExpanded(["1"]);
  //   } else {
  //     setExpanded([]);
  //     setExpandedTree(["1", '']);
  //     selectedNodeReDirect('Others')
  //   }

  // }, [props.otherDocsPermission.R])

  return (
    <>
      {projectsideBarAccess && <>
        <div>
          <TreeView
            className="treeViewPV"
            onNodeToggle={onNodeChange}
            onNodeSelect={selectedNode}
            selected={selectedLabel}
            expanded={expandedTree}
          >
            <TreeItem nodeId="7" label="Intents" className={
              selectedLabel == "Intents" && props.currentBotSelected == ""
              ? "activeTreeFaq"
              : "treeInnerLabelForFaq"
            }/>
          </TreeView>
        </div>
        <div>
          <TreeView
            className="treeViewPV"
            onNodeToggle={onNodeChange}
            onNodeSelect={selectedNode}
          >
            <TreeItem
              nodeId="1"
              label="FAQs"
              className={
                selectedLabel == "FAQs" && props.currentBotSelected == ""
                  ? "activeTreeFaq"
                  : "treeInnerLabelForFaq"
              }
            />
          </TreeView>
        </div>
      </>}
      {props.otherDocsPermission.WR && <div>
        <TreeView
          className="treeViewPV"
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          expanded={expandedTree}
          selected={selectedLabel}
          onNodeToggle={onNodeChange}
          onNodeSelect={selectedNode}
          defaultExpanded={expandedTree}
        >
          <TreeItem nodeId="1" label="Documents" className="treeItemLabelPV">
            <TreeItem
              nodeId="2"
              label="Multimedia"
              className={
                selectedLabel == "Multimedia" && props.currentBotSelected == ""
                  ? "activeTree"
                  : "treeInnerLabel"
              }
            />
            <TreeItem
              nodeId="3"
              label="Others"
              className={
                selectedLabel == "Others" &&
                  props.currentBotSelected == "" 
                  ? "activeTree"
                  : "treeInnerLabel"
              }
            />
          </TreeItem>
        </TreeView>
      </div>}
      {projectsideBarAccess && showBotList && (
        <div>
        <TreeView
          className="treeViewPV"
          onNodeSelect={selectedNode}
          selected={selectedLabel}
        >
          <TreeItem nodeId="1" label="Flows" className={
            selectedLabel === "Flows" &&  props.isCUXBotSelected
            ? "activeTreeFaq"
            : "treeInnerLabelForFaq"
          }/>
        </TreeView>
        </div>
      )}
        <TreeView
            className="treeViewPV"
            onNodeToggle={onNodeChange}
            onNodeSelect={selectedNode}
          >
            <TreeItem
              nodeId="4"
              label="Synonyms & Acronyms"
              className={
                selectedLabel == "Synonyms & Acronyms" && props.currentBotSelected == ""
                  ? "activeTreeFaq"
                  : "treeInnerLabelForFaq"
              }
            />
          </TreeView>
     
    </>
  );
};

export default Projectviewsidebar;
