import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import "./UserRoles.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DuplicateRoleForm from "./DuplicateRoleForm";
import { useDispatch } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import { DeleteCalltoOUServer } from "../helpers/utility";
import DeleteModal from "../../../../../components/UIComponents/DeleteModalComponent/DeleteModal";
import {SetErrorMessage,SetSuccessMessage} from "../../../../../store/actions"
import EditRole from "./EditRole";


const RoleContent = ({ role, config, updateRoles, systemPermissions,userManagement_Read_Write, setSearchTerm}) => {

  const dispatch = useDispatch();
  const [showDuplicateDrawer, setShowDuplicateDrawer] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [DuplicateConformation, setDuplicateConformation] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedRole, setSelectedRole] = useState({});

  const handleShow = (role) =>{
    setShow(true);
    setSearchTerm("");
    setSelectedRole(role);
  }
  const [permissionsObject, setPermissionsObject] = useState();
  const toggleDrawer = (value) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setShow(value)
  };
  const toggleDuplicateRoleDrawer = (value) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setShowDuplicateDrawer(value);
  };
  const confirmMsg = (msg, method) => {
    setTimeout(() => {
      dispatch(method(msg));
    }, 450);
  };
  useEffect(() => {
    getSelectedPermissions();
    createDataObject(systemPermissions);
  }, []);
  const updatePermissionObject = (dataObj) => {
    selectedPermissions&&selectedPermissions.map((code) => {
      dataObj.map((item) => {
        item.permissions.map((data) => {
          if (data.code === code) {
            data.checked = true;
            item.selectedCount += 1;
          }
        });
      });
    });
  };
  const createDataObject = (permissionList) => {
    let dataObject = [];
    let catList = [];
    permissionList&&permissionList.map((data) => {
      if (catList.includes(data.category)) {
        dataObject.forEach((obj) => {
          if (obj.category === data.category) {
            obj.permissions.push({
              code: data.permission_code,
              name: data.permission,
              checked: false,
            });
            obj.count += 1;
          }
        });
      } else {
        let object = {
          category: data.category,
          permissions: [
            {
              code: data.permission_code,
              name: data.permission,
              checked: false,
            },
          ],
          count: 1,
          selectedCount: 0,
        };
        catList.push(data.category);
        dataObject.push(object);
      }
    });

    setPermissionsObject(dataObject);
    updatePermissionObject(dataObject);
  };
  
  const deleteInviteModel = () => {
    setDeleteConfirmation(true);
    setShow(false)
  };
  const duplicateRole = () => {
    setDuplicateConformation(true);
  };
 
  const [selectedPermissions, setSelectedPermissions] = useState([]);

  const getSelectedPermissions = () => {
    role.permissions.map((item) => {
      setSelectedPermissions((prevItems) => [
        ...prevItems,
        item.permission_code,
      ]);
    });
  };


  useEffect(() => {
    updatePermissionObject(permissionsObject);
  }, [permissionsObject]);

  const closeWindow = (modaltype) => {
    if (modaltype == "deleteInviteModal") {
      setDeleteConfirmation(false);
    } else {
      return null;
    }
  };
  const closeModalPopup = (modaltype) => {
    if (modaltype == "deleteDuplicateRole") {
      setDuplicateConformation(false);
    } else {
      return null;
    }
  };
 

  const orgname = localStorage.getItem("orgName");

  const toastFun = (msg, type) => {
    toast(msg, {
      type: type,
      pauseOnHover: false,
      autoClose: 3000,
      hideProgressBar: true,
    });
  };
  const handleDuplicateRole = () => {
    setDuplicateConformation(false);
    setShowDuplicateDrawer(true);
  };

  const removeRole = (rolename) => {
    const url =
      config.COMMON_API_URL + `/organization/${orgname}/role/${rolename}`;
    setDeleteConfirmation(false);
    try {
      DeleteCalltoOUServer(url).then((response) => {
        if (response.status === 204) {
          updateRoles();
          setShowDuplicateDrawer(false);
          confirmMsg("Role deleted successfully", SetSuccessMessage);
        } else {
          confirmMsg("Something went wrong!", SetErrorMessage);
        }
      });
    } catch (err) {
      console.log("error while trying to delete role. ", err);
    }
  };
 
  return (
    <React.Fragment>
      <Row
        className="d-flex roles-content-row row-no-gutters"
      >
        <Col xs={12} className="d-flex row symtom-container">
          <div className="row d-flex justify-content-between w-100 content-align-items row-no-gutters">
            <Col xs={2}>
              <div
                className="roles-content padding-adjustment name-capitilize"
                key={role.id}
              >
                {role.rolename}
              </div>
            </Col>
            <Col xs={6}>
              <div className="roles-content padding-adjustment">
                {role.description}
              </div>
            </Col>
            <Col xs={2}>
              <div className="roles-content"></div>
            </Col>
            <Col xs={1}>
              <div className="roles-content cursor-action">
                <Dropdown
                  className="navigationHelpMenu"
                  style={{ position: "absolute", bottom: "2px",paddingRight:"2rem" }}
                >
                  <Dropdown.Toggle id="navigationHelpMenu" drop="end">
                    <img
                      src="./Icons/OptionsIcon.svg"
                      title="Help"
                      style={{ marginLeft: "7px" }}
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {role.rolename != "tenant admin" ? (
                      <Dropdown.Item
                        className="optionsItemsDropdown"
                        onClick={() => {
                          handleShow(role);
                          {
                            toggleDrawer(true);
                          }
                        }}
                        disabled={userManagement_Read_Write ? false : true}
                      >
                        VIEW/EDIT DETAILS
                      </Dropdown.Item>
                    ) : null}
                    <Dropdown.Item
                      className="optionsItemsDropdown"
                      onClick={duplicateRole}
                      disabled={userManagement_Read_Write ? false : true}
                    >
                      DUPLICATE ROLE
                    </Dropdown.Item>
                    {role.rolename != "tenant admin" ? (
                      <Dropdown.Item
                        className="optionsItemsDropdown"
                        onClick={() => deleteInviteModel()}
                        disabled={userManagement_Read_Write ? false : true}
                      >
                        DELETE
                      </Dropdown.Item>
                    ) : null}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Col>
            <DeleteModal
              show={deleteConfirmation}
              deleteModal={() => closeWindow("deleteInviteModal")}
              title="Delete Confirmation"
              bodyTitle="Are you sure you want to delete the Role?"
              buttonClick={() => removeRole(role.rolename)}
            />
          </div>
        </Col>
      </Row>

      {show ? (
        <EditRole
          showDrawer={show}
          toggleDrawer={toggleDrawer}
          data={role}
          role={selectedRole}
          config={config}
          updateRoles={updateRoles}
          removeRole={() => deleteInviteModel()}
          systemPermissions={systemPermissions}
          roleheading="Role details"
          savebutton="Save"
          toastFun={(msg, type) => toastFun(msg, type)}
          closeDrawer={() => {
            setShow(false);
          }}
        />
      ) : null}
      {showDuplicateDrawer ? (
        <DuplicateRoleForm
          showDrawer={showDuplicateDrawer}
          toggleDrawer={toggleDuplicateRoleDrawer}
          data={role}
          config={config}
          updateRoles={updateRoles}
          removeRole={(rolename) => removeRole(rolename)}
          systemPermissions={systemPermissions}
          roleheading="Role details"
          buttonText="Duplicate Role"
          savebutton="Save"
          toastFun={(msg, type) => toastFun(msg, type)}
          closeDrawer={() => {
            setShowDuplicateDrawer(false);
          }}
        />
      ) : null}
      <DeleteModal
        show={DuplicateConformation}
        deleteModal={() => closeModalPopup("deleteDuplicateRole")}
        title="Duplicate Role Confirmation"
        bodyTitle="Are you sure you want to duplicate the role ?"
        buttonClick={handleDuplicateRole}
      />

      <ToastContainer />
    </React.Fragment>
  );
};
export default RoleContent;
