import React, { useState, useEffect } from "react";
import Drawer from "@material-ui/core/Drawer";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import "./SymptomAndRootCause.css";
import { Col, Form } from "react-bootstrap";
import {
 
  PostCalltoServer,
} from "../../../../../../store/utility";
import get from "lodash/get";
import DrawerHeaderContent from "../../../components/DrawerHeaderContent/DrawerHeaderContent";
import { useDispatch, useSelector } from "react-redux";
import InputWithLabelComponent from "../../../components/InputWithLabelComponent/InputWithLabelComponent";
import isEmpty from "lodash/isEmpty";
import ProductAttributesComponent from "../ProductAttributesComponent/ProductAttributesComponent";
import fetchProductTriageDataAction from "../../../../../../store/actions/agentInsights/fetchProductTriageData/fetchProductTriageDataAction";
import PrimaryButtonWithLoader from "../../../components/PrimaryButtonWithLoader/PrimaryButtonWithLoader";
import getProjectDetails from "../../../helpers/getProjectDetails";
import isEqual from "lodash.isequal";
import AutoCompleteComponent from "../autoComplete/AutoComplete";
import { getRootCausesDataAction } from "../../../../../../store/actions/adminSettings/adminSettingsActions";
import { SetSuccessMessage } from "../../../../../../store/actions";

const useStyles = makeStyles({
  list: {
    width: "29rem"
  },
});

const AddRootCauseDetails = ({
  config,
  showDrawer,
  toggleDrawer,
  rootcausesheading,
  savebutton,
  rootcause,
  rootcausestitle,
  issueDiscription,
  allOption,
  onRemoveItem,
  disabled,
  placeholder,
  label,
  data,
  addRootcause,
  closeDrawer,
  associatedResolutionText,
  associatedSymptoms,
  advanceFilters,
  rootCause,
  getRootCauses,
  selectedSymptom,
  ...rest
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const projectName = getProjectDetails();
  const [productAttributesData, setProductAttributesData] = useState([]);
  const filterData = useSelector((state) => state.agentInsights.filterData);
  const productTriageDataLoading = useSelector(state => state.agentInsights.productTriageDataLoading);
  const [symptomsOptions, setSymptomsOptions] = useState([]);
  const [seletctedSymptomsData, setSeletctedSymptomsData] = useState([]);
  const [rootcauseError, setRootcauseError] = useState(false);
  const [mandatory, setMandatory] = useState(false);
  const [formData, setFormData] = useState({
    rootcause: "",
    rootcauseTitle: "",
    resolution: [],
    symptoms: [],
    description: []
  });
  const [nonTrainData, setNonTrainData] = useState("");
  const [saveLoading, setSaveLoading] = useState(false);
  const [symptomDataLoading, setSymptomDataLoading] = useState(false);
 
  const[symptomsDataVal,setSymptomsDataVal] = useState([]);
  
  const associatedResolutionBox = [
    {
      type: "text",
      id: 1,
      value: "",
    },
  ];

  const [associatedResolution, setAssociatedResolution] = useState(
    associatedResolutionBox
  );
  // const addAssociatedResolution = () => {
  //   setAssociatedResolution((s) => {
  //     return [
  //       ...s,
  //       {
  //         type: "text",
  //         value: "",
  //       },
  //     ];
  //   });
  // };
  // const handleChange = (e) => {
  //   e.preventDefault();

  //   const index = e.target.id;
  //   setAssociatedResolution((s) => {
  //     const newArr = s.slice();
  //     newArr[index].value = e.target.value;

  //     return newArr;
  //   });
  // };
  

  const getOptions = (data) => {
    if (isEmpty(data)) {
      return [];
    }
    if(isEqual(data.attribute_data,null)){
      return [];
    }
    const attrData = get(data, "attribute_data", []);
    return attrData.map((option) => ({
      label: option,
      value: option,
    }));
  };

  const saveHandler = () => {
    const resolutionArr = associatedResolution.map((item) => item.value);
    const productattributes = getProductAttributes(productAttributesData);
    const symptomsOptions = get(formData, "symptoms", []);
    const projectName = getProjectDetails();
    let symptomsData = seletctedSymptomsData
    if(selectedSymptom){
      symptomsData = symptomsDataVal
    }
    if(seletctedSymptomsData.length === 0){
      symptomsData = [{leadingquestion:"",symptoms:nonTrainData}]
    }
    const newFormData = {
      rootcause: get(formData, "rootcause", ""),
      rootcauseTitle: get(formData, "rootcausetitle",""),
      description: get(formData, "description",[]),
      resolution: resolutionArr,
      symptoms: symptomsData,
      project: projectName,
    };
    const updatedFormData = {
      ...newFormData,
      productattributes,
    };
    if (!Validation(updatedFormData)) {
    const url =
      config.INSIGHTS_API_URL +
      `/product-intelligent-triage/settings/addRootcause`;
    setSaveLoading(true);
    PostCalltoServer(url, updatedFormData)
      .then((response) => {
        if (get(response, "data.status.responseType", "") === "success") {
          setSaveLoading(false);
          closeDrawer();
          const rootcauseData = {
            ...productAttributesData,
            rootcause: get(formData, "rootcause", ""),
            rootcauseTitle: get(formData,"rootcausetitle", "")
          };
          dispatch(getRootCausesDataAction(rootCause.concat(rootcauseData)));
          addRootcause(rootcauseData);
          dispatch(SetSuccessMessage('New Root casuse Added Successfully'));
        } else {
          setSaveLoading(false);
        }
      })
      .catch(() => {
        setSaveLoading(false);
      });
    } else setMandatory(Validation(updatedFormData));
  };

  const getRootCauseOptions = (data) => {
    if(selectedSymptom){
      setSymptomsDataVal([{
        symptoms:selectedSymptom,leadingquestion: ""
      }])
    }
    return data.map((item) => ({
      label: get(item, "symptoms", ""),
      value: get(item, "symptoms", ""),
    }));
  };

  useEffect(() => {
    dispatch(fetchProductTriageDataAction(config));
    setProductAttributesData([]);
    const url =
     config.INSIGHTS_API_URL +
     `/product-intelligent-triage/settings/getSymptomsdetailsSet/project/${projectName}`;
      setSymptomDataLoading(true);
    PostCalltoServer(url, advanceFilters)
      .then((response) => {
        if (get(response, "data.status.responseType", "") === "success") {
          setSymptomDataLoading(false);
          const data = JSON.parse(get(response, "data.data", []));
          setSymptomsOptions(getRootCauseOptions(data));
        } else {
          setSymptomDataLoading(false);
          setSymptomsOptions([]);
        }
      })
      .catch(() => {
        setSymptomDataLoading(false);
        setSymptomsOptions([]);
      });
  }, []);

  const onChangeHandler = (event, type) => {
    setFormData((formData) => ({
      ...formData,
      [type]: event.target.value,
    }));
    setMandatory(false);
  };

  useEffect(() => { }, [seletctedSymptomsData]);

  const onRemoveItemHandler = (name) => (selectedItem) => {
    if (name === "symptoms") {
      const selectedRootCauses = get(formData, "symptoms", []);
      const filteredRootCauses = seletctedSymptomsData.filter(
        (item) => item.symptoms !== selectedItem
      );
      setSeletctedSymptomsData(filteredRootCauses);
      setFormData((formData) => ({
        ...formData,
        [name]: selectedRootCauses.filter(
          (item) => item !== selectedItem
        )
      }));
    } else {
      const selectedProduct = get(productAttributesData, name, []);
      const filteredProducts = selectedProduct.filter(
        (item) => item !== selectedItem
      );
      setProductAttributesData((productAttributesData) => ({
        ...productAttributesData,
        [name]: filteredProducts,
      }));
      setMandatory(false);
    }
  };
  // const removeAssociatedResolution = (index) => {
  //   const list = [...associatedResolution];
  //   list.splice(index, 1);
  //   setAssociatedResolution(list);
  // };

  const onDropdownChangeHandler = (selectedItems, name) => {
    if (name === "symptoms") {
      const symptomValues= selectedItems.map(obj=> ({symptoms:obj.label,leadingquestion: ""}))
      setSeletctedSymptomsData(symptomValues);
      setFormData((formData) => ({
        ...formData,
        [name]: selectedItems.map((item) => item.value),
      }));
    } else {
      setProductAttributesData((productAttributesData) => ({
        ...productAttributesData,
        [name]: selectedItems.map((item) => item.value),
      }));
    }
  };
  const getProductAttributes = (productData) =>
    Object.entries(productData).map(([key, value]) => {
      return {
        attribute_name: key,
        attribute_data: value,
      };
    });

const rootcauseUpdate = (value) => {
      setFormData((formData) => ({
        ...formData,
        ["rootcause"]: value,
      }));
      if((rootCause.map((item) => item.rootcause).includes(value)))
        setRootcauseError(true);
      else
        setRootcauseError(false);
      setMandatory(false);
  };

    const Validation = (updatedFormData) => {
      if(updatedFormData.rootcause!= "" &&  updatedFormData.rootcauseTitle!= ""  && nonTrainData!= ""){
        if (!isEqual(updatedFormData.symptoms), ((nonTrainData == ""))) return true;
      }
      else if(updatedFormData.rootcause!= "" &&  updatedFormData.rootcauseTitle!= "" && seletctedSymptomsData.length!= 0){
        if (!isEqual(updatedFormData.symptoms), ((seletctedSymptomsData.length == 0))) return true;
      }else{
        return true;
      }

      // if ((symptomsOptions.length && filterData.length) === 0) {
      //   if (isEqual(updatedFormData.rootcause, "")) return true;
      //   if (isEqual(updatedFormData.rootcauseTitle, "")) return true;
      // } else {
      //   if (isEqual(updatedFormData.rootcause, "")) return true;
      //   if (isEqual(updatedFormData.rootcauseTitle, "")) return true;
      //   if (isEqual(updatedFormData.symptoms.length, 0)) return true;
      //   if((filterData.length === 0)){
      //   if (
      //     !isEqual(
      //       updatedFormData.productattributes.map((item) => item.attributename)
      //         .length,
      //       filterData.map((item) => item.attribute_name).length
      //     )
      //   )
      //     return true;
      //     }
      //   if (isEqual(updatedFormData.resolution.length, 0)) return true;
      //   if((filterData.length === 0)) {
      //     if (
      //       isEqual(
      //         updatedFormData.productattributes.map(
      //           (item) => item.attributename
      //         ).length,
      //         filterData.map((item) => item.attribute_name).length
      //       )
      //     )
      //       return false;
      //   }
      // }
    };


  return (
    <React.Fragment>
      {showDrawer ? (
        <Drawer
          anchor={"right"}
          open={showDrawer}
          onClose={toggleDrawer(false)}
        >
          <div
            className={clsx(classes.list, classes.fullList)}
            role="presentation"
          >
            <DrawerHeaderContent
              heading={rootcausesheading}
              toggleDrawer={toggleDrawer}
            />
            <div className="d-flex flex-column Symtom-Item">
              <div className="mt-1 mb-2">
                <Col md={12}>
                  <Form.Label className="mb-1 text-box-title adding-other-input">
                    {"Root cause*"}
                  </Form.Label>
                  <AutoCompleteComponent
                    suggestions={rootCause.map((item) => item.rootcause)}
                    symptomUpdate={rootcauseUpdate}
                    symptomError={rootcauseError}
                    placeholder={"Add Root Cause"}
                    type="Root cause"
                  />
                </Col>
              </div>
              <div className="mt-1 mb-2">
                <Col md={12}>
                  <InputWithLabelComponent
                    type="text"
                    className="mt-1"
                    name="rootcauseTitle"
                    label={rootcausestitle}
                    value={formData.rootcausetitle}
                    readOnly={false}
                    onChange={(e) => onChangeHandler(e, "rootcausetitle")}
                  />
                </Col>
              </div>
              {/* this code is committed due to requirement in future this code may use, soo please dont remove this code */}
              {/* <div className="mt-1">
                <Col md={12}>
                  <span className="adding-other-input">
                    {associatedResolutionText}
                  </span>
                  <img
                    className="addingInputBox pl-1"
                    onClick={addAssociatedResolution}
                    src="./Icons/AddBlack.svg"
                  />
                  {associatedResolution.map((item, i) => {
                    return (
                      <div className="d-flex">
                        <input
                          className="mb-2 addAssociatebox"
                          placeholder={"Enter Text Here"}
                          onChange={handleChange}
                          value={item.value}
                          id={i}
                          type={item.type}
                          size="57"
                        />
                        {isEqual(i, 0) ? null : (
                          <img
                            className="pl-1 addingInputBox"
                            name={item.value}
                            onClick={() => removeAssociatedResolution(i)}
                            src="./images/minus.svg"
                          />
                        )}
                      </div>
                    );
                  })}
                </Col>
              </div> */}
              <div className="mt-1">
               {symptomsOptions.length ? (
                <ProductAttributesComponent
                  options={symptomsOptions}
                  name={"Associated Symptoms"}
                  label={"Associated Symptoms*"}
                  boldTitle={true}
                  search={true}
                  placeholder={"Select"}
                  className={""}
                  targetSourceDescription={"source_name"}
                  sourceHelpIcon={false}
                  attributeHelpIcon={false}
                  value={get(formData, "symptoms", [])}
                  onChange={(event) =>
                    onDropdownChangeHandler(event, "symptoms")
                  }
                  onRemoveItem={onRemoveItemHandler("symptoms")}
                />
              ) : 
              <div className="mt-1 mb-2">
              <Col md={12}>
                <InputWithLabelComponent
                  type="text"
                  className="mt-1"
                  name="rootcauseTitle"
                  label={associatedSymptoms}
                  value={nonTrainData}
                  readOnly={false}
                  onChange={(e) => setNonTrainData(e.target.value)}
                />
              </Col>
            </div>
              }
              </div>
              {(filterData.length && filterData[0].attribute_data != null && !productTriageDataLoading)
                ? filterData.map((item) => {
                    return (
                      <ProductAttributesComponent
                        options={getOptions(item)}
                        name={item.attribute_name}
                        label={item.attribute_name}
                        boldTitle={true}
                        search={true}
                        placeholder={"Select"}
                        className={""}
                        targetSourceDescription={"source_name"}
                        sourceHelpIcon={false}
                        attributeHelpIcon={false}
                        value={get(
                          productAttributesData,
                          item.attribute_name,
                          ""
                        )}
                        onChange={(event) =>
                          onDropdownChangeHandler(event, item.attribute_name)
                        }
                        onRemoveItem={onRemoveItemHandler(item.attribute_name)}
                      />
                    );
                  })
                : null}
                  <div className="ml-3 ">
                {mandatory ? (
                  <Form.Label className="text-box-error">
                    {"All fields are mandatory"}
                  </Form.Label>
                ) : null}
              </div>
            </div>
            <div className="d-flex flex-row">
              <PrimaryButtonWithLoader
                className="ml-5 mb-2"
                size="lg"
                onClick={saveHandler}
                text={savebutton}
                loading={saveLoading}
              />
            </div>
          </div>
        </Drawer>
      ) : null}
    </React.Fragment>
  );
};

AddRootCauseDetails.propTypes = {
  rootcausesheading: PropTypes.string,
  rootcause: PropTypes.string,
  savebutton: PropTypes.string,
  disabled: PropTypes.bool,
  associatedResolutionText: PropTypes.string,
  associatedSymptoms: PropTypes.string,
  rootcausestitle: PropTypes.string,
};

AddRootCauseDetails.defaultProps = {
  rootcausesheading: "Add Root Cause",
  rootcause: "Root Cause*",
  associatedResolutionText: "Associated Resolutions*",
  savebutton: "SAVE",
  disabled: false,
  associatedSymptoms: "Associated Symptoms*",
  rootcausestitle: "Recommended Resolution*"
};

export default AddRootCauseDetails;

