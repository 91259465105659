import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from 'chart.js'

Chart.register(...registerables)

export default function StackedBarChart(props) {

    return (
        <Bar
            pointStyle="star"
            data={props.data}
            options={props.options}
            plugins={props.plugins}
        />
    );
}
