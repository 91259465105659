import React from "react";
import { Form } from "react-bootstrap";
import * as PropTypes from "prop-types";
import "./color-picker.css";


const ColorPickerComponent = ({
    label,
    labelContent,
    labelContentColor,
    labelContentClassname,
    name,
    value,
    className,
    placeHolder,
    readOnly,
    onChange,
    required,
    isInvalid,
    error,
    helpIcon,
    type,
    onKeyDown,
    showLabel,
    disabled,
    ...inputs
}) => {
    let bgClr = '';
    if (value == "#006BB6")
        bgClr = 'bg-clr-class';
    return (
        <div className="common-input">
            {showLabel ? (
                <Form.Label className="mb-1 text-box-title adding-other-input">{label}</Form.Label>
            ) : null}
            <div>
                <div className="input-group">
                    <label className={`color-wrapper ${bgClr}`} style={{ backgroundColor: value }}>
                        <input type="color"
                            className="form-control"
                            name={name}
                            value={labelContentColor ? labelContentColor : value}
                            onChange={onChange}
                        />
                        <span className={"labelContentData "+labelContentClassname} style={{ color: labelContentColor }}>{labelContent}</span></label>
                    <Form.Control
                        type={"text"}
                        name={"color"}
                        required={required}
                        value={labelContentColor ? labelContentColor : value}
                        className={"default-css color-text-input" + " " + className}
                        placeholder={placeHolder}
                        onKeyDown={onKeyDown}
                        readOnly={readOnly}
                        isInvalid={isInvalid}
                        disabled={disabled}
                        {...inputs}
                    />                        </div>
            </div>
            {isInvalid ? (
                <span className="error-css">{error}</span>
            ) : null}
        </div>
    );
};
ColorPickerComponent.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    className: PropTypes.string,
    value: PropTypes.string,
    type: PropTypes.string,
    placeHolder: PropTypes.string,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    isInvalid: PropTypes.bool,
    error: PropTypes.string,
    helpIcon: PropTypes.bool,
    showLabel: PropTypes.bool,
};

ColorPickerComponent.defaultProps = {
    label: "Input Label",
    name: "input-class",
    value: "",
    className: "",
    placeHolder: "",
    readOnly: false,
    required: false,
    isInvalid: false,
    type: "color",
    error: "",
    helpIcon: false,
    showLabel: true,
};
export default ColorPickerComponent;
