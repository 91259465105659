import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import getProjectDetails from '../../../../containers/SettingPage/SettingInnerPages/helpers/getProjectDetails';
import { PostCalltoServer } from '../../../utility';
import fetchClustersDataAction from '../fetchClustersData/fetchClustersDataAction';
import fetchRecommendedSolutionDataAction from '../fetchRecommendedSolutionData/fetchRecommendedSolutionDataAction';
import fetchProductTriageInsightsFailureAction from './fetchProductTriageInsightsFailureAction';
import fetchProductTriageInsightsStartAction from './fetchProductTriageInsightsStartAction';
import fetchProductTriageInsightsSuccessAction from './fetchProductTriageInsightsSuccesAction';

const getFilterDataObj = (selectedFilterData) => {
    const filterData = Object.entries(selectedFilterData).map(([key, value]) => {
        if (key === 'description') {
            return;
        }
        return {
            [key]: value,
        }
    });
    return Object.assign({}, ...filterData);
}

const getConfigObj = (state) => {
    const yesQuestions = state.agentInsights.yesQuestions;
    const selectedFilterData = state.agentInsights.selectedFilterDataArr;
    const filterDataObj = getFilterDataObj(selectedFilterData);
    
    Object.keys(filterDataObj).forEach(function(k) {
        if(filterDataObj[k].length == 0) delete filterDataObj[k]
        if( filterDataObj[k] == "") delete filterDataObj[k]
    });

    if (isEmpty(yesQuestions)) {
        return filterDataObj;
    }
    return {
        ...filterDataObj,
        "yes": yesQuestions.map(item => {
            if (isEmpty(item)) {
                return [];
            }
            return item.symptoms;
        })
    }
}
const fetchProductTriageInsightsAction = (payload) => (dispatch, getState) => {
    const state = getState();
    const selectedFilterData = state.agentInsights.selectedFilterDataArr;
    const description = get(selectedFilterData, 'description', '');
    const projectName = getProjectDetails();
    const url = payload.url + `/product-intelligent-triage/insights/similarissues/${description}/${projectName}`;
    const configObj = getConfigObj(state)
    dispatch(fetchProductTriageInsightsStartAction());
    PostCalltoServer(url, configObj)
        .then(response => {
            if (get(response, 'data.status.responseType', '') === "success") {
                dispatch(fetchProductTriageInsightsSuccessAction({
                    response,
                    isFirstTime: payload.isFirstTime,
                    // questions: payload.questions
                }));
                if(payload.isFirstTime) {
                    dispatch(fetchClustersDataAction(payload.config.INSIGHTS_API_URL));
                    dispatch(fetchRecommendedSolutionDataAction(payload.config.INSIGHTS_API_URL));
                }
            }
            else {
                dispatch(fetchProductTriageInsightsFailureAction(response.data.status.responseType))
            }
        }).catch(err => {
            dispatch(fetchProductTriageInsightsFailureAction(err.message))
        });
};

export default fetchProductTriageInsightsAction;