import React from 'react';
import { Col,Row ,Container,Form} from 'react-bootstrap';
import TreeCustom from "../../components/UIComponents/TreeComponent/TreeComponent";
import ButtonCustom from "../../components/UIComponents/Button/ButtonComponent";
import DropdownCustom from "../../components/UIComponents/Dropdowns/DropdownComponent";
import DrawerCustom from "../../components/UIComponents/Drawer/DrawerComponent";
import ToolTipCustom from "../../components/UIComponents/ToolTip/ToolTip";
import InfoIcon from '@material-ui/icons/Info';
import InputWithLabelComponent from "../SettingPage/SettingInnerPages/components/InputWithLabelComponent/InputWithLabelComponent";
import TextAreaWithLabelComponent from "../SettingPage/SettingInnerPages/components/TextAreaWithLabelComponent/TextAreaWithLabelComponent";
import ReactSelectComponent from "../SettingPage/SettingInnerPages/components/ReactSelectComponent/ReactSelectComponent";
import PrimaryButtonWithLoader from "../SettingPage/SettingInnerPages/components/PrimaryButtonWithLoader/PrimaryButtonWithLoader";
import SecondaryButtonWithLoader from "../SettingPage/SettingInnerPages/components/SecondaryButtonWithLoader/SecondaryButtonWithLoader";
import TabCustom from "../../components/UI/HorizontalTab/HorizontalTab";
import SelectCustom from "../../components/UIComponents/SelectDropdown/SelectComponent";
import BadgeCustom from "../../components/UIComponents/Badge/BadgeComponent";
import LabelComponent from "../../components/UIComponents/LabelComponent/Label";
import SearchComponent from "../../components/UIComponents/SearchComponent/Search";
import FileComponent from "../../components/UIComponents/FileInput/FileInput";
import AccordionCustom from "../../components/UIComponents/Accordion/Accordion";
import ModalPopUp from "../../components/UIComponents/DeleteModalComponent/DeleteModal";
import CardCustom from "../../components/UIComponents/Card/Card";
import './Components.css'
import DropDownInput from '../../components/UI/FormComponents/DropDownInput';
import RowCard from '../../components/UIComponents/RowCard/RowCard';
import ColorPickerComponent from '../SettingPage/SettingInnerPages/components/ColorPicker/ColorPickerComponent';

class ComponentsPage extends React.Component {

  state = {	
    selectedLabel:'Language',
    openDrawer:false,
    name:'',
    textColor:"red",
    viewingOptions: [
      { value: "en", label: "English" },
      { value: "hi", label: "Hindi" },
    ],
    viewOption:'',
    selectedTab:'Train',
    selectName:'',
    expanded:['Virtual Agent'],
    accordionData:{category:"Tenant",
    permissions:[{checked: true,code: "knowledgesearch_R",name: "View tenant usage dashboards"},
     {code: 'knowledgesearch_S', name: 'Add/edit tenant admins', checked: true}]},
     activeItem:'',
     showModal:false,
     cardInput:{
        cardTitle:'Service Now',
        cardStatus:'Active',
        cardLabel:'Ticketing System',
        cardNotes:' Provide cross-channel support to customers and easy-to-use tools to your agents that scale as your business grows.',
        cardVariant:'outlined',
        buttonLabel:'Settings',
        cardActive:true,
        showButton:true
     },
     cardInput1:{
      cardTitle:'Service Now',
      cardStatus:'Active',
      cardLabel:'Ticketing System',
      cardNotes:' Provide cross-channel support to customers and easy-to-use tools to your agents that scale as your business grows.',
      cardVariant:'contained',
      buttonLabel:'Settings',
      cardActive:true,
      showButton:true
   },
    rowData:[{id:'1' ,name:'Tenant Admin',description:'Has access to all permissions'},
            {id:'2' ,name:'User Admin',description:'Handles user management task'}],
  };


  componentDidMount() {

  }
  selectedNode=(nodeName)=>{
    console.log(nodeName)
    this.setState({
      selectedLabel:nodeName
    })
  }
  buttonClick=()=>{
    console.log('clicked')
  }
  showDrawer=(status)=>{
    this.setState({
      openDrawer:status
    })
  }
  onChangeHandler=(event,type)=>{
    this.setState({
      [type]: event.target.value,
    })
  }
  viewChangeHandler = (event) => {
    console.log(event)
    this.setState({
      viewOption: event,
    })
  }
  selectedTab=(nodeName)=>{
    console.log(nodeName)
    this.setState({
      selectedTab:nodeName
    })
  }
  onSelectHandler=(event)=>{
    console.log(event.target.value)
    this.setState({
      selectName:event.target.value
    })
  }
  badgeClick=(item)=>{
    console.log(item)
  }
  onNodeChange = (e, nodes) => {
   const expandingNodes = nodes.filter(x => !this.state.expanded.includes(x));
   this.setState({
    expanded:nodes
  })
  console.log("expandingNodes",expandingNodes)
  };

  SearchContent=(event)=>{
    console.log(event.target.value)
  }
  changeFile=(event)=>{
    console.log(event.target.value)
  }
  ChangePermission=(event)=>{
    console.log(event.target.checked)
  }
  onColorChange=(color)=>{
    this.setState({
        textColor:color
    })
}
  toggleActive=(item)=>{
    console.log("item",item)
    if (this.state.activeItem === item) {
      this.setState({
        activeItem:null
      })
    } else {
      this.setState({
        activeItem:item
      })
    }
  }
  deleteModal=(status)=>{
    this.setState({
      showModal:status
    })
  }

  toggleDrawer = (value) => {
    console.log('opens the drawer if any',value);
  }
 
  render() {
    return (
     
            <div className="componentContainer">
              <h2>Components:</h2>
        <Container>
        
        <h6>h6 Element</h6>
          <h5>h5 Element</h5>
          <h4>h4 Element</h4>
          <h3>h3 Element</h3>
          <h2>h2 Element</h2>
          <h1>h1 Element</h1>
          <p>Paragraph Element</p>
        </Container>
         
        <React.Fragment>
        <Row>
          <Col md={2}>
          <h3>Tree Component <span>
          <ToolTipCustom title={"{'ariaLabel':'String','label':'String','nodeList':'Array','expanded':'Array','onNodeChange':'function','selectedItem':'function','selectedLabel':'boolean'}"}>
            <InfoIcon/>
            </ToolTipCustom>
          </span></h3>

          <TreeCustom ariaLabel="Virtual Agent" label={'Virtual Agent'}
            nodeList={["Language ","Personalized Theme","Agent Transfer"]}
           onNodeChange={this.onNodeChange}  expanded={this.state.expanded}
            selectedItem={this.selectedNode} selectedLabel={this.state.selectedLabel}/>

            <TreeCustom ariaLabel="Virtual Agent" label={'Agent Cockpit'}
            nodeList={["Layout"]}
            expanded={this.state.expanded} onNodeChange={this.onNodeChange}
            selectedItem={this.selectedNode} selectedLabel={this.state.selectedLabel}/>

          <TreeCustom ariaLabel="Triage" label="Triage" nodeList={["Setup Triage Attributes & Train Model","Triage Data","Symptoms & Root Causes mapping"]}  expanded={this.state.expanded} onNodeChange={this.onNodeChange} selectedItem={this.selectedNode} selectedLabel={this.state.selectedLabel}/>
          </Col>
          <Col md={2}>
          <h3>Outlined Button<span>
          <ToolTipCustom title={"{'variant':'String','label':'String','className':string,'clicked':'function'}"}>
            <InfoIcon/>
            </ToolTipCustom>
          </span></h3>
            <ButtonCustom variant='outlined' label='Delete' className='buttonClass' clicked={this.buttonClick}/>
          </Col>
          <Col md={2}>
          <h3>Filled Button<span>
          <ToolTipCustom title={"{'variant':'String','label':'String','clicked':'function'}"}>
            <InfoIcon/>
            </ToolTipCustom>
          </span></h3>
            <ButtonCustom variant='contained' label='Save'className='buttonWidth'/>
          </Col>
          <Col md={2}>
            <h3>Dropdown Button<span>
          <ToolTipCustom title={"{'label':'String','dropdownItem':'array','selectedItem':'function'}"}>
            <InfoIcon/>
            </ToolTipCustom>
          </span></h3>
            <DropdownCustom label='Add' customClass="dropdownClass"
            variant='primary' dropdownItem={[{name:'New Flow'},{name:'Import Flow'},{name:'FAQ Document'}]}
            selectedItem={this.selectedNode}/>
          </Col>
         
          <Col md={2}>
          <h3>Drawer Button</h3>
            <ButtonCustom variant='contained' label='Open Drawer' className='buttonWidth' clicked={()=>this.showDrawer(true)}/>
          </Col>
      
        </Row>
        <br/>
        <Row>
        <Col md={3}>
          <h3>Input TextBox Component<span>
          <ToolTipCustom 
          title={"{type:text,label:String,name:String,value:String,readOnly:boolean,onChange:function,required:boolean}"}>
            <InfoIcon/>
          </ToolTipCustom>
          </span></h3>
         
          <InputWithLabelComponent
                    type="text"
                    name="name"
                    label="Name Field"
                    value={this.state.name}
                    readOnly={false}
                    onChange={(e) => this.onChangeHandler(e, 'name')}
                    required={true}
                  />
            </Col>
            
            <Col md={3}>
            <h3>TextBox with Error<span>
            <ToolTipCustom 
            title={"{type:text,label:String,name:String,value:String,readOnly:boolean,onChange:function,required:boolean,inValid:true,error:String}"}>
              <InfoIcon/>
            </ToolTipCustom>
            </span></h3>
            <InputWithLabelComponent
                      type="text"
                      name="name"
                      label="Name Field"
                      value={this.state.name}
                      readOnly={false}
                      onChange={(e) => this.onChangeHandler(e, 'name')}
                      required={true}
                      isInvalid={true}
                      error="Error"
                    />
            
              </Col>
              <Col md={3}>
              <h3>TextArea<span>
              <ToolTipCustom 
              title={"{label:String,name:String,value:String,onChange:function,required:boolean}"}>
                <InfoIcon/>
              </ToolTipCustom>
              </span></h3>
              <TextAreaWithLabelComponent 
                        label="Text Area"
                        name="message"
                        required ={false}
                        value={this.state.message}
                        className='text-area'
                        placeholder='Enter Text Area Content.'
                        onChange={(e) =>this.onChangeHandler(e, 'message')}/>
              </Col>
                <Col md={3}>
                  <h3>Select with Search<span>
                <ToolTipCustom 
                title={"{options:array,name:String,value:String,onChange:function}"}>
                  <InfoIcon/>
                </ToolTipCustom>
                </span></h3>
                <div className="common-input">
                <Form.Label className="mb-1 text-box-title adding-other-input">Language</Form.Label>
                </div>
                  <ReactSelectComponent
                    customClassName="SelectView"
                    value={this.state.viewOption}
                    options={this.state.viewingOptions}
                    name="option"
                    placeholder={'Select'}
                    onChange={(event) => this.viewChangeHandler(event)}
                  />
                </Col>
          </Row>
          <br/>
       
          <Row>
              <Col md={3}>
              <h3>Select<span>
                  <ToolTipCustom 
                  title={"{name:String,value:String,changed:function,options:array}"}>
                    <InfoIcon/>
                  </ToolTipCustom>
                  </span></h3>
                  <SelectCustom
                  name="selectName"
                  label="Entity"
                  changed={(event) => this.onSelectHandler(event)}
                  required={true}
                  value={this.state.selectName}
                  options={[{value:'',displayValue:'Select'},{value:'1',displayValue:'1'},
                  {value:'2',displayValue:'2'},{value:'3',displayValue:'3'}]}/>     
                </Col>
              <Col md={2}>
                <h3>Badge<span>
                <ToolTipCustom 
                title={"{label:String,clicked:function}"}>
                  <InfoIcon/>
                </ToolTipCustom>
                </span></h3>
                <BadgeCustom label='Test Project' clicked={this.badgeClick}/>     
              </Col>

              <Col md={2}>
                <h3>Label<span>
                <ToolTipCustom 
                title={"{value:String}"}>
                  <InfoIcon/>
                </ToolTipCustom>
                  </span></h3>
                  <LabelComponent value='Project Name'/>
              </Col>
              <Col md={3}>
                <h3>Search<span>
                <ToolTipCustom 
                title={"{value:String}"}>
                  <InfoIcon/>
                </ToolTipCustom>
                  </span></h3>
                  <SearchComponent value={this.state.searchValue} onSearch={this.SearchContent}/>
              </Col>
              <Col md={3}>
                <h3>File Input<span>
                <ToolTipCustom 
                title={"{value:String,fileType:'String',name:'String',onFileChange:function}"}>
                  <InfoIcon/>
                </ToolTipCustom>
                  </span></h3>
                  <FileComponent value={this.state.projectName} 
                  fileType='application/x-zip-compressed' name='projectName' onFileChange={this.changeFile}
                  error="Fle is required" />
              </Col>
          </Row>
          <br/>
          
        <Row>
            <Col md={3}>
              <h3>Primary Button with Loader<span>
                <ToolTipCustom 
                title={"{loading:boolean,text:String,onClick:function}"}>
                  <InfoIcon/>
                </ToolTipCustom>
                </span></h3>
                <PrimaryButtonWithLoader
                        className="ml-5 mb-2"
                        size="lg"
                        onClick={this.selectedNode}
                        text="Save Flow"
                        loading={true}
                        // disabled={}
                      />
              </Col>
              <Col md={3}>
              <h3>Secondary Button with Loader<span>
                <ToolTipCustom 
                title={"{loading:boolean,text:String,onClick:function}"}>
                  <InfoIcon/>
                </ToolTipCustom>
                </span></h3>
                <SecondaryButtonWithLoader
                            size="sm"
                            className="w-56 h-32"
                            onClick={this.selectedNode}
                            text="no"
                            loading={true}
                          />
              </Col>
              <Col md={6}>
              <h3>Tab<span>
                <ToolTipCustom 
                title={"{tabs:array,selected:String,setSelected:function}"}>
                  <InfoIcon/>
                </ToolTipCustom>
                </span></h3>
                <TabCustom
                  tabs={["Train", "Script"]}
                  selected={this.state.selectedTab}
                  setSelected={this.selectedTab}
                >
              </TabCustom>
              </Col>
          </Row>
          <br/>
          <Row>
          <Col md={3}>
              <h3>DropDown With Triangle shape Arrow<span>
                <ToolTipCustom 
                title={`selectedValue={"English"}
                handleSelection={()=>{}}
                items={ [{itemKey:1,itemValue:"English"},
                {itemKey:2,itemValue:"Telugu"},
                {itemKey:3,itemValue:"Tamil"}
              ]}`}>
                  <InfoIcon/>
                </ToolTipCustom>
                </span></h3>
                <div className="form-group">
                    <label for="lang" className='text-box-title'>Default Language</label>
                    <DropDownInput
                      selectedValue={"English"}
                      handleSelection={()=>{}}
                      items={ [{itemKey:1,itemValue:"English"},
                      {itemKey:2,itemValue:"Telugu"},
                      {itemKey:3,itemValue:"Tamil"}
                    ]}
                    />
                  </div>
               
              </Col>
          <Col md={5}>
          <h3>Accordion Component<span>
                <ToolTipCustom 
                title={"{data:object,imgSrc:String,ChangePermission:function,toggleActive:function,activeItem:boolean,status:String}"}>
                  <InfoIcon/>
                </ToolTipCustom>
                </span></h3>
            
                <AccordionCustom data={this.state.accordionData} imgSrc="./Icons/FullAccess.svg"
                 status='Full Access' ChangePermission={this.ChangePermission}
                 activeItem={this.state.activeItem} toggleActive={this.toggleActive}/>
            
          </Col>
          <Col md={2}>
          <h3>DeletePopup<span>
                <ToolTipCustom 
                title={"{show:boolean,onHide:function,title:String,size:string,deleteModal:function,buttonClick:function,bodyTitle:String}"}>
                  <InfoIcon/>
                </ToolTipCustom>
                </span></h3>
            
                <ButtonCustom variant='outlined' label='Delete Flow' className='buttonClass' clicked={()=>this.deleteModal(true)}/>
            
          </Col>
          <Col md={2}>
          <h3>Color Picker<span>
                <ToolTipCustom 
                title={"{type:color,onChange:function,name:String,label:String}"}>
                  <InfoIcon/>
                </ToolTipCustom>
                </span></h3>
                <ColorPickerComponent
                                type="color"
                                name="buttonHoverBorderColor"
                                label="Button Hover Border Color"
                                value={this.state.textColor}
                                onChange={(e) => this.onColorChange(e.target.value)}
                                required={false}
                            />            
          </Col>
          </Row>
          <br/>
          <Row>
          <Col md={4}>
          <h3>Card <span>
            <ToolTipCustom 
            title={"{cardInput:Object,buttonClick:function}"}>
              <InfoIcon/>
            </ToolTipCustom>
            </span></h3>
            <CardCustom cardInput={this.state.cardInput} buttonClick={this.buttonClick}/> 
            
          </Col> 

          <Col md={8}>
            {/*********************************
            Row styles - Used in UserManagement
           ***********************************/}
          <h3>Row card <span>
            <ToolTipCustom 
            title={"{rowData:Object,buttonClick:function}"}>
              <InfoIcon/>
            </ToolTipCustom>
            </span></h3>
            <Container className="background-container" fluid>
            <div className="content-scroll">
            {this.state.rowData.map((data,i)=>(
              <RowCard key={i} data={data} buttonClick={this.buttonClick} toggleDrawer={this.toggleDrawer}/> 
            ))}            
            </div>
            </Container>
          </Col> 

          </Row>
              <Row>
              <Col md={4}>
          <h3>Card <span>
            <ToolTipCustom 
            title={"{cardInput:Object,buttonClick:function}"}>
              <InfoIcon/>
            </ToolTipCustom>
            </span></h3>
            <CardCustom cardInput={this.state.cardInput1} buttonClick={this.buttonClick}/> 
            
          </Col> 
              </Row>
        <DrawerCustom showDrawer={this.showDrawer} openDrawer={this.state.openDrawer} 
        heading="Add Project Details">
          <div className="mt-1">
                <Col md={12}>
                <InputWithLabelComponent
                    type="text"
                    name="name"
                    label="Name Field"
                    value={this.state.name}
                    readOnly={false}
                    onChange={(e) => this.onChangeHandler(e, 'name')}
                    required={true}
                  />
                </Col>
              </div><br/>
              <div className="mt-1">
                <Col md={12}>
                <TextAreaWithLabelComponent 
                      label="Text Area"
                      name="message"
                      required ={false}
                      value={this.state.message}
                       className='text-area'
                      placeholder='Enter Text Area Content.'
                      onChange={(e) =>this.onChangeHandler(e, 'message')}/>
                </Col>
              </div><br/>
              <div className="mt-1">
                <Col md={12}>
                <div className="common-input">
        <Form.Label className="mb-1 text-box-title adding-other-input">Language</Form.Label>
        </div>
        <ReactSelectComponent
                customClassName="SelectView"
                value={this.state.viewOption}
                options={this.state.viewingOptions}
                name="option"
                placeholder={'Select'}
                onChange={(event) => this.viewChangeHandler(event)}
              />
                </Col>
                </div>
                <br/>
          </DrawerCustom>
          <br/>

          <ModalPopUp
          show={this.state.showModal}
          onHide={this.deleteModal}
          title="Delete Confirmation"
          size='md'
          deleteModal={this.deleteModal}
          buttonClick={this.buttonClick}
          bodyTitle='Are you sure to delete?'
        >
         
        </ModalPopUp>
       
          </React.Fragment>  
            </div>
     
    );
  }
}

export default ComponentsPage;
