import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { Switch } from '@material-ui/core';
import InputWithLabelComponent from '../../components/InputWithLabelComponent/InputWithLabelComponent';
import ColorPickerComponent from '../../components/ColorPicker/ColorPickerComponent';
import FontFamilyComponent from '../FontFamilyComponent/FontFamilyComponent';
import SelectComponent from '../../../../../components/UIComponents/SelectDropdown/SelectComponent';
import * as lists from '../Constants';
import '../personalityPart.css';

const Engagement = ({data, onChangeHandler}) => {

    let closeIconSvg = <svg width="21" height="21" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8.5" cy="8.5" r="8.5" fill={data.engagement.closeBgColor}/>
    <path d="M5.14686 5.14686C5.1933 5.10031 5.24846 5.06337 5.30919 5.03817C5.36992 5.01297 
    5.43503 5 5.50078 5C5.56654 5 5.63164 5.01297 5.69238 5.03817C5.75311 5.06337 5.80827 5.10031 5.85471 
    5.14686L8.50013 7.79329L11.1456 5.14686C11.192 5.10038 11.2472 5.06352 11.3079 5.03836C11.3687 5.01321 
    11.4337 5.00026 11.4995 5.00026C11.5652 5.00026 11.6303 5.01321 11.691 5.03836C11.7517 5.06352 11.8069 
    5.10038 11.8534 5.14686C11.8999 5.19334 11.9367 5.24852 11.9619 5.30924C11.9871 5.36997 12 5.43505 12 
    5.50078C12 5.56651 11.9871 5.6316 11.9619 5.69233C11.9367 5.75305 11.8999 5.80823 11.8534 5.85471L9.20698 
    8.50013L11.8534 11.1456C11.8999 11.192 11.9367 11.2472 11.9619 11.3079C11.9871 11.3687 12 11.4337 12 
    11.4995C12 11.5652 11.9871 11.6303 11.9619 11.691C11.9367 11.7517 11.8999 11.8069 11.8534 11.8534C11.8069 
    11.8999 11.7517 11.9367 11.691 11.9619C11.6303 11.9871 11.5652 12 11.4995 12C11.4337 12 11.3687 11.9871 
    11.3079 11.9619C11.2472 11.9367 11.192 11.8999 11.1456 11.8534L8.50013 9.20698L5.85471 11.8534C5.80823 
    11.8999 5.75305 11.9367 5.69233 11.9619C5.6316 11.9871 5.56651 12 5.50078 12C5.43505 12 5.36997 11.9871 
    5.30924 11.9619C5.24852 11.9367 5.19334 11.8999 5.14686 11.8534C5.10038 11.8069 5.06352 11.7517 5.03836 
    11.691C5.01321 11.6303 5.00026 11.5652 5.00026 11.4995C5.00026 11.4337 5.01321 11.3687 5.03836 
    11.3079C5.06352 11.2472 5.10038 11.192 5.14686 11.1456L7.79329 8.50013L5.14686 5.85471C5.10031 5.80827 
    5.06337 5.75311 5.03817 5.69238C5.01297 5.63164 5 5.56654 5 5.50078C5 5.43503 5.01297 5.36992 5.03817 
    5.30919C5.06337 5.24846 5.10031 5.1933 5.14686 5.14686Z" fill={data.engagement.closeIconColor}/>
    </svg>;

    return (
        <>
        <Row className='mt-2'>
            <Col md='3' className='al-ctr'>
                <Form.Label className="mb-1 text-box-title switch-label">Display message on page load</Form.Label>
            </Col>
            <Col md='1'>
                <Switch
                    className='switchMain'
                    checked={data.engagement.isEnable}
                    onChange={(e) => onChangeHandler('isEnable',e.target.checked)}
                    color="primary"
                    name="engagementEnable"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    disableRipple
                />
            </Col>
        </Row>
        {data.engagement.isEnable && (
            <>
            <Row>
            <Col md='7'>
                <InputWithLabelComponent
                    type="text"
                    name="engagementMsg"
                    label="Engagement Message"
                    value={data.engagement.message}
                    placeHolder=""
                    readOnly={false}
                    onChange={(e) => onChangeHandler('message',e.target.value)}
                    required={true}
                />
            </Col>
        </Row>
        <Row className="mt-2">
            <Col md='2'>
                <ColorPickerComponent
                    type="color"
                    name="engagementBgColor"
                    label="Background Color"
                    showLabel={true}
                    value={data.engagement.bgColor}
                    readOnly={true}
                    onChange={(e) => onChangeHandler("bgColor", e.target.value)}
                    required={false}
                />
            </Col>
            <Col md='2'>
                <ColorPickerComponent
                    type="color"
                    name="engagementTextColor"
                    label="Text Color"
                    labelContent="AB"
                    value={data.engagement.bgColor}
                    labelContentColor={data.engagement.textColor}
                    readOnly={true}
                    onChange={(e) => onChangeHandler("textColor", e.target.value)}
                    required={false}
                />
            </Col>
            <Col md='2'>
                <ColorPickerComponent
                    type="color"
                    name="engagementBorderColor"
                    label="Border Color"
                    showLabel={true}
                    value={data.engagement.borderColor}
                    readOnly={true}
                    onChange={(e) => onChangeHandler("borderColor", e.target.value)}
                    required={false}
                />
            </Col>
        </Row>
        <Row>
            <Col md='2'>
                <FontFamilyComponent
                    type="color"
                    name="engagementFontFamily"
                    label="Text Family"
                    onChange={e => onChangeHandler('fontFamily',e)}
                    value={data.engagement.fontFamily}
                    labelContent="AB"
                    readOnly={false}
                    required={true}
                    disabled={true}
                />
            </Col>
            <Col md='2'>
                <SelectComponent
                    name="engagementFontStyle"
                    label="Text Style"
                    className="selectCompCol2"
                    changed={(e) => onChangeHandler('fontStyle',e.value)}
                    required={true}
                    value={data.engagement.fontStyle}
                    options={lists.fontStyles}
                    disabled={true}
                />
            </Col>
            <Col md='2'>
                <SelectComponent
                    name="engagementFontSize"
                    label="Text Size"
                    className="selectCompCol2"
                    changed={(e) => onChangeHandler('fontSize',e.value)}
                    required={true}
                    value={data.engagement.fontSize}
                    options={lists.fontSizes}
                    disabled={true}
                />
            </Col>
        </Row>
        <Row>
            <Col md='2'>
                <ColorPickerComponent
                    type="color"
                    name="menu-background-color"
                    label="Close Background Color"
                    value={data.engagement.closeBgColor}
                    readOnly={true}
                    onChange={(e) => onChangeHandler('closeBgColor', e.target.value)}
                    required={false}
                />
            </Col>
            <Col md='2'>
                <ColorPickerComponent
                    type="color"
                    name="iconColor"
                    label="Close Icon Color"
                    labelContent={closeIconSvg}
                    labelContentClassname="engagementCloseIcon"
                    labelType='image'
                    value="#FFFFFF"
                    labelContentColor={data.engagement.closeIconColor}
                    readOnly={true}
                    onChange={(e) => onChangeHandler('closeIconColor', e.target.value)}
                    required={false}
                />
            </Col>
        </Row>
        </>
        )}
        
        </>
    )

}

export default Engagement;