import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import { compose } from 'redux';
const withErrorHandler = ( WrappedComponent, axios ) => {
    return class extends Component {
        state = {
            errormsg: null
        }

        componentWillMount () {
            this.reqInterceptor = axios.interceptors.request.use( req => {
                this.props.onSetErrorMessage(null);
                this.props.onSetSuccessMessage(null);
             
                return req;
            } );
            this.resInterceptor = axios.interceptors.response.use( res => {
                if( res.data != undefined && res.data.status =="failure")
                {
                    this.props.onSetErrorMessage(res.data.msg);
                }
                return res;

            }, error => {

               this.props.onSetErrorMessage(error);
             
               return error
            } );
        }

        componentWillUnmount () {
            axios.interceptors.request.eject( this.reqInterceptor );
            axios.interceptors.response.eject( this.resInterceptor );
        }

        errorConfirmedHandler = () => {
            this.setState( { errormsg: null } );
        }

        render () {
            return (
                <>
                 <WrappedComponent {...this.props} />
                </>
            );
        }

        
    }
}

const mapStateToProps = () => {
    return {
       
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSetErrorMessage: (error) => dispatch( actions.SetErrorMessage(error) ),
        onSetSuccessMessage:(success) => dispatch( actions.SetSuccessMessage(success))
    };
};

const composeF = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withErrorHandler
)
//export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler);
//export default connect(mapDispatchToProps )(withErrorHandler);
export default composeF;