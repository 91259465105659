import React, { useState } from 'react';
import { Col, Container } from "react-bootstrap";
import './FormDetails/formStyles.css';
import FormOptions from './FormDetails/FormOptions';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import FormPlayground from './FormDetails/FormPlayground';
import * as inputs from './FormDetails/InputTypes';
import DrawerWithSubHeading from "../../../../components/UIComponents/DrawerWithSubHeading/DrawerWithSubHeading";
import InputWithLabelComponent from "../../../../containers/SettingPage/SettingInnerPages/components/InputWithLabelComponent/InputWithLabelComponent";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const FormsNode = ({node, data, handleFormNodeDate, variable, setVar, showDrawer, show, invalidVarMsg, 
                  checkValidation, setInvalidMsg, disableTextbox, setDisableTextbox}) => {  

    const [variableName, setVariableName] = useState(variable?variable:'');

    function onChangeHandler (value) {
      setVariableName(value);
      checkValidation(value,'variable');
    }
    const newWindow=window.open.bind(window);
    const onClickInfo = () => {
      let commonUrl = "https://help.hcltechsw.com/Intelliservice/en/8.5/webhelp/User_Guide/Nodes/";
      newWindow(commonUrl +"Forms.htm" ,"_blank");
    }

    const responsive = {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 12
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 10
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 6
      }
      // mobile: {
      //   breakpoint: { max: 464, min: 0 },
      //   items: 1
      // }
    };

    return (
      <>
      <DrawerWithSubHeading showDrawer={showDrawer}  tooltip={true} openDrawer={show} 
            heading="Input Capture Details" className='drawer-width' onClick ={onClickInfo}
            subHeading='To capture various information from the user at once anytime during a conversation by creating a form using multiple components'>

          <div className="mt-1">
              <Col md={12}>
                  <InputWithLabelComponent
                      type="text"
                      name="varname"
                      label="Name*"
                      className={'input-width'}
                      value={variableName}
                      readOnly={false}
                      onChange={(e) => onChangeHandler(e.target.value)}
                      required={true}
                      isInvalid={invalidVarMsg!==null}
                      error={invalidVarMsg}
                      toolTip={true}
                      toolTipParams={{title:'Name will also be used as variables',placement:'right'}}
                />
              </Col>
          </div> 
        
        <Container className='form-container'>
          <DndProvider backend={HTML5Backend} >
            <Carousel responsive={responsive}>
              {inputs.inputTypes.map((item, index)=> (
                  <FormOptions data={item} key={index}></FormOptions>
              ))}
              </Carousel>

            <FormPlayground node={node} data={data} handleSubmit={handleFormNodeDate} setVar={setVar} 
                variable={variableName} invalidMsg={invalidVarMsg} disableFlag={disableTextbox}
                setDisableFlag={setDisableTextbox}></FormPlayground>
          </DndProvider>
        </Container>
      </DrawerWithSubHeading>
        
      </>
    )
}

export default FormsNode;
