import React, { memo } from 'react';
import { Row, Col, Dropdown } from "react-bootstrap";
import './nodeStyles.css'

export default memo(({ type, data, boxList }) => {

  const getLabel = (option) => {
    switch (option) {
      case 'Text' : return 'Text Message';
      case 'Text Prompt' : return 'Text Message';
      case 'Expression' : return 'Expression details';
      case 'Confirm' : return 'Confirmation message';
      case 'Box Selector' : return 'Text Message';
      case 'HTTP' : return 'HTTP Details';
      case 'Flow' : return 'Flow Details';
      case 'Media' : return 'Media Details';
      case 'Forms' : return 'Input Capture Details';
      case 'Agent Transfer' : return 'Agent Transfer';
      case 'Script' : return 'Script details';
      case 'Email' : return 'Email details';
      case 'TriggerIntent' : return 'Intent details';
      case 'ShowData' : return 'Data details';
      case 'Feedback' : return 'Feedback details';
    }
  }

  const getIcon = (option) => {
    switch (option) {
      case 'Text' : return 'Text-icon.png'; 
      case 'Text Prompt' : return 'Text-prompt-icon.png'; 
      case 'Expression' : return 'Express-icon.png'; 
      case 'Confirm' : return 'Confirm-icon.png'; 
      case 'Box Selector' : return 'Box-selector-icon.png'; 
      case 'HTTP' : return 'Http-icon.png'; 
      case 'Flow' : return 'Flow-icon.png'; 
      case 'Media' : return 'Media-icon.png'; 
      case 'Forms' : return 'InputCapture-icon.svg'; 
      case 'Carousel' : return 'Carousel-icon.png'; 
      case 'Agent Transfer' : return 'Agent-icon.png'; 
      case 'Script' : return 'Script-icon.png'; 
      case 'Email' : return 'Email-icon.png'; 
      case 'TriggerIntent' : return 'TriggerIntent-icon.png'; 
      case 'ShowData' : return 'ShowData-icon.png'; 
      case 'Feedback': return 'Feedback-icon.svg'; 
    }
  }

  return (
    <div>
      <Row>
      <Col md={2} className="box-image">
        <img src={"./images/CDIcons/icons/"+getIcon(type)}></img>
      </Col>

      <Col md={10}>
      <div className='node-header'>
        {/* <span className='node-header-text'>{type} :</span> */}
        <span className='node-header-text'>{data.variable?data.variable:'Name'}</span>
        <Dropdown className='node-dropdown'>
          <Dropdown.Toggle className='cux-dropdown-toggle'>
            <img src="./images/CDIcons/optionsDots.svg"/>
          </Dropdown.Toggle>
          <Dropdown.Menu className='node-dropdown-menu'> 
            <Dropdown.Item className="cux-sideDropdown"
              eventKey="Edit" key="Edit"
              onClick={() => data.nodeOptions('edit')}
            >Edit</Dropdown.Item>
            <Dropdown.Item className="cux-sideDropdown"
              eventKey="Delete" key="Delete"
              onClick={() => data.nodeOptions('delete')}
            >Delete</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>  
      </div>
      
      
      <div class="node-data">
        {data.label?data.label.substring(0,100):getLabel(type)}

        {type==='Confirm' && (
          <div className='confirm-btn-row'>
            <button key='Yes' className='confirm-yes' >Yes</button>
            <button key='No' className='confirm-no' >No</button>
          </div>
        )}

        {type === 'Box Selector' && (
          <>
          <div>{boxList}</div>
          </>
        )}

        {type === 'Carousel' &&  ( data.carousel &&
          data.carousel.length > 0 ? (
            <div>
            <div>{data?.carousel[0]?.title.substring(0,20)}</div>
            <div>{data?.carousel[0]?.body.substring(0,100)}</div>
          </div>
          ) : 'Carousel details'
        )}

      </div>
      </Col>
      </Row>
    </div>

  );
});