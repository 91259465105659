import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { issueClarificationOptinalFieldChangeHandlerAction, issueClassificationChangeHandlerAction } from '../../../store/actions/insights/insightsActions';
import IssueClarificationPage from '../IssueClarification/IssueClarification';
import saveInsightsUnifiedLoginEsDataAction from '../../../store/actions/insights/saveInsightsUnifiedLoginEs/saveInsightsUnifiedLoginEsDataAction';
import getOptionalFieldOptions from '../helpers/getOptionalFieldOptions';
import getActiveInsights from '../helpers/getActiveInsights';

const getFilteredOptions = (options, value) => {
    if(isEmpty(value)) {
        return options;
    }

    return options.filter(item => item.value !== value);
};

const getOptionalField1DdOptions = (state, props) => {
    const issueClarificationOptionalField2 = state.insights.issueClarificationOptionalField2;
    const issueClarificationOptionalField3 = state.insights.issueClarificationOptionalField3;
    const options = getOptionalFieldOptions(props);
    if(isEmpty(issueClarificationOptionalField2) && isEmpty(issueClarificationOptionalField3)) {
        return {
            options: options
        };
    }
    const filtered2ndOptions = getFilteredOptions(options, issueClarificationOptionalField2);
    const updatedOptions = getFilteredOptions(filtered2ndOptions, issueClarificationOptionalField3);
    return {
        options: updatedOptions
    }
};

const getOptionalField2DdOptions = (state, props) => {
    const issueClarificationOptionalField1 = state.insights.issueClarificationOptionalField1;
    const issueClarificationOptionalField3 = state.insights.issueClarificationOptionalField3;
    const options = getOptionalFieldOptions(props);
    if(isEmpty(issueClarificationOptionalField1) && isEmpty(issueClarificationOptionalField3)) {
        return {
            options: options
        };
    }

    const filtered1stOptions = getFilteredOptions(options, issueClarificationOptionalField1);
    const updatedOptions = getFilteredOptions(filtered1stOptions, issueClarificationOptionalField3);
    return {
        options: updatedOptions
    }
};

const getOptionalField3DdOptions = (state, props) => {
    const issueClarificationOptionalField1 = state.insights.issueClarificationOptionalField1;
    const issueClarificationOptionalField2 = state.insights.issueClarificationOptionalField2;
    const options = getOptionalFieldOptions(props);
    if(isEmpty(issueClarificationOptionalField1) && isEmpty(issueClarificationOptionalField2)) {
        return {
            options: options
        };
    }

    
    const filtered1stOptions = getFilteredOptions(options, issueClarificationOptionalField1);
    const updatedOptions = getFilteredOptions(filtered1stOptions, issueClarificationOptionalField2);
    return {
        options: updatedOptions
    }
};

const getIssueClassificationDefaultField = (props) => {
    const activeInsights = getActiveInsights(props.config);
    return activeInsights === "its" ? "problemDescription" : "description" 
};

export const mapStateToProps = (state, ownProps) => ({
    enabled: state.insights.issueclassification.enabled,
    ClusterFields: state.insights.issueclassification.ClusterFields,
    summarytextsize: state.insights.issueclassification.summarytextsize,
    size: state.insights.issueclassification.size,
    items: state.insights.issueclassification.items,
    optionField1: state.insights.issueClarificationOptionalField1,
    optionField2: state.insights.issueClarificationOptionalField2,
    optionField3: state.insights.issueClarificationOptionalField3,
    optionalField1DdOptions: getOptionalField1DdOptions(state, ownProps),
    optionalField2DdOptions: getOptionalField2DdOptions(state, ownProps),
    optionalField3DdOptions: getOptionalField3DdOptions(state, ownProps),
    issueClassificationDefaultField: getIssueClassificationDefaultField(ownProps)
});

export const mapDispatchToProps = (dispatch,ownProps) => ({
    handleSwitchChange: (event) => {
        dispatch(issueClassificationChangeHandlerAction({
            enabled: event.target.checked
        }))
        const apiUrl = ownProps.config.INSIGHTS_API_URL;
        dispatch(saveInsightsUnifiedLoginEsDataAction({apiUrl, tabName: 'issueclassification',ownProps}));
    },
    multiChangeHandler: (options, name) => {
        dispatch(
            issueClassificationChangeHandlerAction({
                [name]: isEmpty(options) ?
                    [] :
                    options.map(item => item.value)
            }))
    },
    inputChangedHandler: (event, name) => {
        dispatch(
            issueClassificationChangeHandlerAction(
                {
                    [name]: Number(event.target.value)
                }
            )
        );
    },
    inputFiledToSearchChangedHandler: (event, name) => {
        dispatch(
            issueClassificationChangeHandlerAction(
                {
                    [name]: event.target.value
                }
            )
        );
    },
    handleSlideChange: (slideProgress, value) => {
        dispatch(
            issueClassificationChangeHandlerAction(
                {
                    similarity: Number(value)
                }
            )
        )
    },
    optionFieldsChangedHandler: (event, name) => {
        console.log(event.target.value, 'value');
        dispatch(
            issueClassificationChangeHandlerAction(
                {
                    value: event.target.value,
                    type:name,
                    isOptionField: true
                }
            )
        );
        dispatch(
            issueClarificationOptinalFieldChangeHandlerAction({
                [name]: event.target.value,
                isDefault: false
            })
        );
    },
    saveButtonClickHandler: () => {
       
		const apiUrl = ownProps.config.INSIGHTS_API_URL;
        dispatch(saveInsightsUnifiedLoginEsDataAction({apiUrl, tabName: 'issueclassification',ownProps}));
    },
    ownProps
});

export const IssueClarificationContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(IssueClarificationPage);