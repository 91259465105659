import React, { memo, useEffect, useMemo } from 'react';
import { Handle, useUpdateNodeInternals } from 'react-flow-renderer';
import NodeBox from './NodeBox';

export default memo(({id, data, isConnectable }) => {

  let sourceArray = [data.formSubmitBtn];
  if(data.formSkipBtnText) sourceArray.push(data.formSkipBtnText);
  if(data.buttonDataList) {
    sourceArray.push(...data.buttonDataList.map((item)=>item.btnText))
  }

  const nodeId = id;
  let segment = 220/sourceArray.length;
  let gap = segment/2;

  const positionHandle = (index) => {
      return segment * index + gap ;
  }

  useEffect(() => {
    updateNodeInternals(nodeId);
  }, [sourceArray])

  const updateNodeInternals = useUpdateNodeInternals();

  const sourceHandles = useMemo(
    () =>
        sourceArray.map((item, index) => {
            const handleId = item;
            return (
                <Handle
                    key={index}
                    type="source"
                    position="bottom"
                    id={handleId}
                    style={{ left: positionHandle(parseInt(index)) }}
                    isConnectable={isConnectable}
                />
            );
        }),
    [sourceArray]
);
    
  return (
    <>
      <Handle
        type="target"
        position="top"
        style={{ background: '#555' }}
        isConnectable={isConnectable}
      />
      <NodeBox type='Forms' data={data}></NodeBox>
     {sourceHandles}
      {/* <Handle
        type="source"
        position="bottom"
        style={{ background: '#555' }}
        isConnectable={isConnectable}
      /> */}
      
    </>
  );
});