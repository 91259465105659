import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../utility';


const initialState = {
    flowData: null,
    error: null,
    loading: false,
    catloading:false,
    categoryList:[],
    catflowList:[],
	getStructure: null,
	getFileData:null,
    catflowloading:false,
    createcatstart:false,
    createcatbotstart:false,
    createcatflag: null,
    createcatbotflag:null
};

const flowStart = ( state ) => {
    return updateObject( state, { error: null, loading: true } );
};

const flowSuccess = (state, action) => {
    return updateObject( state, { 
        flowData: action.flowData,
        error: null,
        loading: false,
     } );
};

const flowFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
};

const categoryStart = ( state ) => {
    return updateObject( state, { error: null, catloading: true } );
};

const categorySuccess = (state, action) => {
    return updateObject( state, { 
        categoryList: action.categoryList,
        error: null,
        catloading: false,
     } );
};

const categoryFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        catloading: false
    });
};


const catflowListStart = ( state ) => {
    return updateObject( state, { error: null, catflowloading: true } );
};


const catflowListSuccess = (state, action) => {
    let temparray = [];
    action.catflowList.map(item => {
         temparray.push(item.botname);
     })

    state.catflowList = [];
    return updateObject( state, { 
        catflowList: temparray,
     //  action.catflowList,
        error: null,
        catflowloading: false,
     } );
};

const catflowListFail = (state, action) => {
    return updateObject( state, {
        catflowList:[],
        error: action.error,
        catflowloading: false
    });
};




const createCategoryStart = ( state ) => {
    return updateObject( state, { error: null, createcatstart: true,createcatflag:'S' } );
};

const createCategorySuccess = (state, action) => {
    return updateObject( state, { 
        categoryList : state.categoryList.concat(action.newcatname),
       // createcategory: action.createcategory,
        error: null,
        createcatstart: false,
        createcatflag:'E'
     } );
};

const createCategoryFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        createcatstart: false,
        createcatflag:'E'
    });
};

const createCategoryBotStart = ( state ) => {
    return updateObject( state, { error: null, createcatbotstart: true,createcatbotflag:'S' } );
};

const createCategoryBotSuccess = (state, action) => {
    return updateObject( state, { 
        catflowList :state.catflowList.concat(action.newbotname),
        error: null,
        createcatbotstart: false,
        createcatbotflag:'E'
     } );
};

const createCategoryBotFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        createcatbotstart: false,
        createcatbotflag:'E'
    });
};

const delCategoryBotStart = ( state ) => {
    return updateObject( state, { error: null, delcatbotstart: true } );
};

const delCategoryBotSuccess = (state, action) => {
    if(action.cat=="Bot"){
    let filtered =  state.catflowList.filter(function(value){ 
        return value !== action.deletecategorybot;
        });
   
        return updateObject( state, { 
        catflowList: filtered,
        error: null,
        delcatbotstart: false,
     } );
    }
    else if(action.cat=="Category"){
        let filtered =  state.categoryList.filter(function(value){ 
            return value !== action.deletecategorybot;
            });
       
            return updateObject( state, { 
            categoryList: filtered,
            error: null,
            delcatbotstart: false,
         } );
        }
};

const delCategoryBotFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        delcatbotstart: false
    });
};

const refreshLayout = (state, action) => {
    return updateObject( state, {
        editbot: action.botname,
        editprojectname:action.projectname
    });
};

const savecategoryProfileStart = ( state ) => {
    return updateObject( state, { error: null, scriptLoading: true } );
};
const savecategoryProfileFailure = (state, action) => {
    return updateObject( state, {
        error: action.error,
        scriptLoading: false
    });
};

const savecategoryProfileSuccess = (state, action) => {
    return updateObject( state, {
        saveProfile:action.botProfile,
        scriptLoading: false
    });
};

const getCategoryStructureFailure = ( state, action ) => {
	return updateObject( state, {
		error: action.error
	});
};
const getCategoryStructureSuccess = (state, action) => {
    return updateObject( state, {
        getStructure:action.bot
    });
};
const getCategoryFileFailure = ( state, action ) => {
	return updateObject( state, {
		error: action.error
	});
};
const getCategoryFileSuccess = (state, action) => {
    return updateObject( state, {
        getFileData:action.file
    });
};


const createFlowFStart = (state) => {
    return updateObject( state, { error: null, loading: true } );
};

const createFlowFFailure = (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
};

const createFlowFSuccess = (state, action) => {
    return updateObject( state, {
        loading: false,
        botCreated:action.bot
    });
};

const triggerTypeStart = ( state ) => {
    return updateObject( state, { error: null, loading: true } );
};
const triggerTypeFailure = (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
};

const triggerTypeSuccess = (state, action) => {
    return updateObject( state, {
        intent:action.intent,
        loading: false
    });
};

const getFlowListSuccess = (state, action) => {
    return updateObject( state, {
        loading: false,
        flowListData:action.flowListData,
       // createBotloading:Math.random()
    });
};

const getFlowListFailure = (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false,
       // createBotloading:false
    });
};

const createUpdateEntityStart = ( state ) => {
    return updateObject( state, { error: null, createLoading: true } );
};
const createUpdateEntityFailure = (state, action) => {
    return updateObject( state, {
        error: action.error,
        createLoading: false
    });
};

const createUpdateEntitySuccess = (state, action) => {
    return updateObject( state, {
        createentity:action.createEntity,
        createLoading: false
    });
};

const getListOfCategoryStart = (state) => {
    return updateObject( state, { error: null, getlistofcategorystart: true } );
};

const getListOfCategorySuccess = (state, action) => {
    return updateObject( state, {
        listofcategory:action.listofcategory
    });
};

const getEntityCatStart = ( state ) => {
    return updateObject( state, { error: null, entityLoading: true} );
};
const getEntityCatFailure = (state, action) => {
    return updateObject( state, {
        error: action.error,
        entityLoading: false
    });
};

const getEntityCatSuccess = (state, action) => {
    return updateObject( state, {
        entity:action.entity,
        entityLoading: false
    });
};


const saveBotProfileStart = ( state ) => {
    return updateObject( state, { error: null, scriptLoading: true } );
};
const saveBotProfileFailure = (state, action) => {
    return updateObject( state, {
        error: action.error,
        scriptLoading: false
    });
};

const saveBotProfileSuccess = (state, action) => {
    return updateObject( state, {
        saveProfile:action.botProfile,
        scriptLoading: false
    });
};


const homereducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.FLOWLIST_START: return flowStart(state);
        case actionTypes.FLOWLIST_SUCCESS: return flowSuccess(state, action);
        case actionTypes.FLOWLIST_FAIL: return flowFail(state, action);
        case actionTypes.CATEGORYLIST_START: return categoryStart(state);
        case actionTypes.CATEGORYLIST_SUCCESS: return categorySuccess(state, action);
        case actionTypes.CATEGORYLIST_FAIL: return categoryFail(state, action);
        case actionTypes.CAT_FLOWLIST_START: return catflowListStart(state);
        case actionTypes.CAT_FLOWLIST_SUCCESS: return catflowListSuccess(state, action);
        case actionTypes.CAT_FLOWLIST_FAIL: return catflowListFail(state, action);
        case actionTypes.CREATE_CATEGORY_START: return createCategoryStart(state);
        case actionTypes.CREATE_CATEGORY_SUCCESS: return createCategorySuccess(state, action);
        case actionTypes.CREATE_CATEGORY_FAIL: return createCategoryFail(state, action);
        case actionTypes.CREATE_CATEGORY_BOT_START: return createCategoryBotStart(state);
        case actionTypes.CREATE_CATEGORY_BOT_SUCCESS: return createCategoryBotSuccess(state, action);
        case actionTypes.CREATE_CATEGORY_BOT_FAIL: return createCategoryBotFail(state, action);
        case actionTypes.DELETE_CATEGORY_BOT_START: return delCategoryBotStart(state);
        case actionTypes.DELETE_CATEGORY_BOT_SUCCESS: return delCategoryBotSuccess(state, action);
        case actionTypes.DELETE_CATEGORY_BOT_FAIL: return delCategoryBotFail(state, action);
        case actionTypes.REFRESH_LAYOUT: return refreshLayout(state, action);
		case actionTypes.SAVE_CATEGORY_PROFILE_START: return savecategoryProfileStart(state);
        case actionTypes.SAVE_CATEGORY_PROFILE_FAILURE: return savecategoryProfileFailure(state, action);
		case actionTypes.SAVE_CATEGORY_PROFILE_SUCCESS: return savecategoryProfileSuccess(state, action);
		case actionTypes.GET_CATEGORYSTRUCTURE_FAILURE: return getCategoryStructureFailure(state, action);
		case actionTypes.GET_CATEGORYSTRUCTURE_SUCCESS: return getCategoryStructureSuccess(state, action);
		case actionTypes.GET_CATEGORYFILE_FAILURE: return getCategoryFileFailure(state, action);
        case actionTypes.GET_CATEGORYFILE_SUCCESS: return getCategoryFileSuccess(state, action);
        
        case actionTypes.CREATE_FLOWF_START: return createFlowFStart(state);
        case actionTypes.CREATE_FLOWF_SUCCESS: return createFlowFSuccess(state, action);
        case actionTypes.CREATE_FLOWF_FAILURE: return createFlowFFailure(state, action);


        case actionTypes.TRIGGER_TYPE_START: return triggerTypeStart(state);
        case actionTypes.TRIGGER_TYPE_SUCCESS: return triggerTypeSuccess(state, action);
        case actionTypes.TRIGGER_TYPE_FAILURE: return triggerTypeFailure(state, action);

        case actionTypes.GET_FLOW_LIST_SUCCESS: return getFlowListSuccess(state, action);
        case actionTypes.GET_FLOW_LIST_FAILURE: return getFlowListFailure(state, action);

        case actionTypes.CREATE_UPDATE_ENTITY_START: return createUpdateEntityStart(state);
        case actionTypes.CREATE_UPDATE_ENTITY_SUCCESS: return createUpdateEntitySuccess(state, action);
        case actionTypes.CREATE_UPDATE_ENTITY_FAILURE: return createUpdateEntityFailure(state, action);

        case actionTypes.GET_LIST_CATEGORY_START: return getListOfCategoryStart(state);
        case actionTypes.GET_LIST_CATEGORY_SUCCESS: return getListOfCategorySuccess(state, action);

        case actionTypes.GET_ENTITY_CAT_START: return getEntityCatStart(state);
        case actionTypes.GET_ENTITY_CAT_SUCCESS: return getEntityCatSuccess(state, action);
        case actionTypes.GET_ENTITY_CAT_FAILURE: return getEntityCatFailure(state, action);

        case actionTypes.SAVE_PROFILE_START: return saveBotProfileStart(state);
        case actionTypes.SAVE_PROFILE_SUCCESS: return saveBotProfileSuccess(state, action);
        case actionTypes.SAVE_PROFILE_FAILURE: return saveBotProfileFailure(state, action);
       
        default:
            return state;
    }
};

export default homereducer;