import React, { useState, useEffect } from "react";
import Drawer from "@material-ui/core/Drawer";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import "./Users.css";
import { Col, Form } from "react-bootstrap";
import DrawerHeaderContent from "../../components/DrawerHeaderContent/DrawerHeaderContent";
import { useDispatch } from "react-redux";
import InputWithLabelComponent from "../../components/InputWithLabelComponent/InputWithLabelComponent";
import isEmpty from "lodash/isEmpty";
import PrimaryButtonWithLoader from "../../components/PrimaryButtonWithLoader/PrimaryButtonWithLoader";
import TextAreaWithLabelComponent from "../../components/TextAreaWithLabelComponent/TextAreaWithLabelComponent";
import ReactSelectComponent from "../../components/ReactSelectComponent/ReactSelectComponent";
import {
  GetCalltoOUServer,
  PostCallToOUServer,
  UpdateCalltoOUServer,
} from "../helpers/utility";
import {
  SetSuccessMessage,
  SetErrorMessage,
} from "../../../../../store/actions";
import AutoCompleteComponent from "../../Insight/AdminConfiguration/autoComplete/AutoComplete";

const useStyles = makeStyles({
  list: {
    width: 600,
  },
});

const InviteUser = ({
  config,
  showDrawer,
  toggleDrawer,
  updateUser,
 
  // disabled,
  
  userGroupName,
  updateUserGroups,
  // groupUpdatedToastMsg,
  
 

  closeDrawer,
  
}) => {
  const orgname = localStorage.getItem("orgName");
  
  
  const [userGroups, setUserGroups] = useState([]);
  const [emailId, setEmailId] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  
  const [name, setName] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [disabled, setDisabled] = useState(false);
  const [invalidGroupMsg, setInvalidGroupMsg] = useState();
 
  const [inviteMsg, setInviteMsg] = useState();
  const [formData, setFormData] = useState({
    username: "",
    first_name: "",
    email: "",
    last_name: "",
    orgname: orgname,
    usergroup: [],
    message: "I am inviting you to the Intelliservice platform.",
  });
  const dispatch = useDispatch();

  

  const classes = useStyles();
  
  

  useEffect(() => {
    
    if (userGroupName !== null && userGroupName !== undefined) {
      setSelectedGroups((prevData) => [...prevData, userGroupName]);
      setDisabled(true);
    } else getUserGroups();
  }, []);



  const parseData = (data) => {
    let usergroup = [];
    usergroup = data.map((group) => group.usergroup_name);
    return usergroup;
  };


  const confirmMsg = (msg, method) => {
    setTimeout(() => {
      dispatch(method(msg))
    },450)
  }

  const getUserGroups = () => {
    
    const url =
      config.COMMON_API_URL + `/organization/usergroup?orgname=${orgname}`;
    
    GetCalltoOUServer(url)
      .then((response) => {
        if (response.data != null) {
          

          setUserGroups(parseData(response.data));
        } 
      })
      .catch((err) => {
        console.log(err)
      });
  };

  const getInviteEmials = () => {
    const orgname = localStorage.getItem("orgName");
    const url = config.COMMON_API_URL + `/organization/user/azure?orgname=${orgname}`;
    GetCalltoOUServer(url)
    .then((response) => {
      if (response.data != null) {
           
           setEmailId(response.data);
      } 
    })
    .catch((err) => {
      console.log(err)
    });
};
  useEffect(() => {
    getInviteEmials();
  }, []);

  const getOptions = (data) => {
    if (isEmpty(data)) {
      return [];
    }
    const attrData = data;
    return attrData.map((option) => ({
      label: option,
      value: option,
    }));
  };

  useEffect(() => {
    setFormData((formData) => ({
      ...formData,
      usergroup: selectedGroups,
    }));
  }, [selectedGroups]);

  
  const checkIfExistingUser = async (username) => {
    const url =
      config.COMMON_API_URL +
      `/organization/user?username=${username}&orgname=${orgname}`;
    try {
      const response = await GetCalltoOUServer(url);
      if (response.status === 200) {
        return true;
      } else {
        return false;
      }
      // })
    } catch (error) {
      console.log("error while checking exitsing user: ", error);
      return false;
    }
  };

  async function getUpdatedUsergroups(username) {
    const url =
      config.COMMON_API_URL +
      `/organization/user?username=${username}&orgname=${orgname}`;

    try {
      const response = await GetCalltoOUServer(url);

      if (response.data != null) {
        let usergroupList = response.data[0]?.usergroup;
        usergroupList.push(userGroupName);
        return usergroupList;
      }
    } catch (err) {} 
  }

  const inviteUser = async (e) => {
    e.preventDefault();
    let url = null;
    
    let domain = localStorage.getItem("userName").split("@")[1];
    if (formData.username.split("@")[1] !== domain) {
      setErrorMsg("Email id does not match organization domain.");
    } else if (formData.usergroup.length < 1) {
      setInvalidGroupMsg("Please select user group");
    } else {
   
      let status = await checkIfExistingUser(formData.username);
      
      if (!status) {
        url = config.INSIGHTS_API_URL + `/organization/user`;
        PostCallToOUServer(url, formData).then((response) => {
          if (response.status == 201) {
            closeDrawer();
            toggleDrawer(true);
            if (userGroupName !== undefined) {
              updateUserGroups();
              confirmMsg('Invitation sent successfully', SetSuccessMessage);
            } else {
              updateUser();
              confirmMsg('Invitation sent successfully', SetSuccessMessage);
            }
          } else if (response.response.status == 409) {
            setErrorMsg("User already exists.");
          } else {
            confirmMsg('Something went wrong!', SetErrorMessage);
          }
        });
      } else if (userGroupName !== undefined) {
        let msg = "I am adding you to the usergroup " + userGroupName;
        setInviteMsg("I am adding you to the usergroup " + userGroupName);
        const list = await getUpdatedUsergroups(formData.username);
        const requestBody = {
          usergroup: list,
          message: msg,
        };
        console.log("requestBody: ", requestBody);
        let username = formData.username.replaceAll(".", "~~");
        console.log("username:" , username);
        url =
          config.COMMON_API_URL + `/organization/${orgname}/user/${username}`;
        UpdateCalltoOUServer(url, requestBody).then((response) => {
          if (response.status == 204) {
            closeDrawer();
            updateUserGroups();
            confirmMsg('User group updated successfully', SetSuccessMessage);
            toggleDrawer(true);
          } else if (response.response.status == 409) {
            setErrorMsg("User already exists.");
        
          } else {
            
            confirmMsg('Something went wrong!', SetErrorMessage);
          }
        });
      } else {
        setErrorMsg("User already exists.");
     
        dispatch(SetErrorMessage("User already exists!"));
      }
    }
  };
//END inviteUser


  

 
  const onChangeHandler = (event, type) => {
    if (type === "first_name") {
      let nameArray = [];
      nameArray = event.target.value.split(" ");
      setName(event.target.value);
      setFormData((formData) => ({
        ...formData,
        first_name: nameArray[0],
        last_name: nameArray[1],
      }));
    }
    else {
      setFormData((formData) => ({
        ...formData,
        [type]: event.target.value,
      }));
    }
  };

  const updateUserGroup = (e) => {
    if (!selectedGroups.includes(e.value))
      setSelectedGroups((prevData) => [...prevData, e.value]);
  };

  const removeUserGroup = (e, item) => {
    e.preventDefault();
    if (userGroupName === undefined)
      setSelectedGroups(selectedGroups.filter((list) => list !== item));
  };
  const selectedEmialId = (value) => {
    emailId && emailId.map((item) => item)
    if((emailId.map((item) => item)).includes(value)) 
    setFormData((formData) => ({
      ...formData,
      username: value,
    }));
    else{
      setFormData((formData) => ({
        ...formData,
        username: value,
      }));
    }
  }

 


  return (
    <React.Fragment>
      {showDrawer ? (
        <Drawer
          anchor={"right"}
          open={showDrawer}
          onClose={toggleDrawer(false)}
        >
          <form onSubmit={inviteUser}>
            <div
              className={clsx(classes.list, classes.fullList)}
              role="presentation"
            >
              <DrawerHeaderContent
                heading="Invite User" 
                toggleDrawer={toggleDrawer}
              />{" "}
              <br />
              <div className="d-flex flex-column Symtom-Item invite-user highlights">
                <div className="mt-1">
                  <Col md={12}>
                    <InputWithLabelComponent
                      type="text"
                      className=""
                      placeHolder="Enter username"
                      name="invite name"
                      label="User Name" 
                      value={name}
                      readOnly={false}
                      onChange={(e) => onChangeHandler(e, "first_name")}
                      required={true}
                    />
                  </Col>
                </div>
                <br />
                <div className="mt-1">
                  <Col md={12}>
                  <Form.Label className="mb-1 text-box-title  adding-other-input">
                    {"Email Id"}
                  </Form.Label>
                    <AutoCompleteComponent
                    suggestions={emailId.map((item) => item)}
                    symptomUpdate={selectedEmialId}
                    // symptomError={symptomError}
                    placeholder={"Enter email id"}
                    type="Symptom"
                  />
                  </Col>
                </div>
                <div className="username-error">{errorMsg}</div>
                <br />

                 {/* as per 8924 this feild is removed */}
                {/* <div className="mt-1">
                  <Col md={12}>
                    <InputWithLabelComponent
                      type="text"
                      className=""
                      name="email"
                      label="Alternative Email Id"
                      placeholder="Enter alternative email id"
                      value={formData.email}
                      readOnly={false}
                      onChange={(e) => onChangeHandler(e, "email")}
                      required={false}
                    />
                  </Col>
                </div> */}
                
                <div className="mt-1">
                  <Col md={12}>
                    <TextAreaWithLabelComponent
                      label="Message"
                      name="message"
                      required={false}
                      value={formData.message} 
                      className="text-area"
                      placeholder={inviteMsg}
                      onChange={(e) => onChangeHandler(e, "message")}
                      //onChange={(e) => updateFilterData(e.target.value, 'description')}
                    />
                  </Col>
                </div>{" "}
                <br />
                <div className="mt-1">
                  <div className="mt-1">
                    <Col md={12}>
                      <ReactSelectComponent
                        options={getOptions(userGroups)}
                        name="userGroups"
                        label="User Group"
                        placeholder={"Search"}
                        value="" 
                        onChange={updateUserGroup}
                        isRequired={true}
                        disabled={disabled}
                        className="cursor-action"
                      />
                    </Col>
                  </div>
                  <div className="mt-1">
                  <Col xs={12}>
           <div className='d-flex flex-start align-items-center associate-multi-select'>
                {selectedGroups && selectedGroups.length ? selectedGroups.map((item) => <div className="mr-3 mt-2 pr-2 d-flex flex-start align-items-center selected-option">
                    <div onClick={(e) => removeUserGroup(e, item)} className='remove-icon mr-2 ml-2'>
                        <div className="selected-close-icon">x</div>
                    </div>
                    <div className='selected-option-label'>{item}</div>
                </div>) : ( <div className="error-msg">{invalidGroupMsg}</div> )}
            </div>
            </Col>
                   
                  </div>
                </div>
              </div>
              <div className="d-flex flex-row">
                <PrimaryButtonWithLoader
                  className="ml-5 mb-2"
                  size="lg"
                  type="submit"
                  // onClick={inviteUser}
                  text="Send Invite" 
                  loading={false}
                  // disabled={}
                />
              </div>
            </div>
          </form>
        </Drawer>
      ) : null}
    </React.Fragment>
  );
};

export default InviteUser;
