import * as actionTypes from './actionTypes';
import { PostCalltoServer, GetCalltoServer } from '../utility';
import { SetErrorMessage } from './message'

export const saveFieldsStart = () => {
    return {
        type: actionTypes.SAVEFIELDS_START
    };
};
export const saveFieldsSuccess = (status) => {
    return {
        type: actionTypes.SAVEFIELDS_SUCCESS,
        saveFields: status
    };
};
export const saveFieldsFail = (error) => {
    return {
        type: actionTypes.SAVEFIELDS_FAIL,
        error: error
    };
};
export const saveFields = (data, selectedValue) => {
    return dispatch => {
        let Data = {};
        if (selectedValue == 'customer_data') {
            Data = {
                customer_data: data
            }
        }
        if (selectedValue == 'customer_details') {
            Data = {
                customer_details: data
            }
        }
        if (selectedValue == 'product_details') {
            Data = {
                product_details: data
            }
        }
        dispatch(saveFieldsStart());
        let url = `https://sandbox.api.saas.intelliservice.ai/dev/product-intelligent-triage/crmlayout/save-crm-fields`;
        PostCalltoServer(url, Data)
            .then(response => {
                if (response.data) {
                    dispatch(saveFieldsSuccess(response.data));
                }
                else {
                    dispatch(SetErrorMessage('Error occured.'));
                    dispatch(saveFieldsFail(response.data.errors));
                }
            })
            .catch(err => {
                dispatch(SetErrorMessage('Error occured.'));
                dispatch(saveFieldsFail(err.message));
                dispatch(saveFieldsSuccess(''));
            });
    };
};
export const GetFieldsDataStart = () => {
    return {
        type: actionTypes.GET_FIELDS_DATA_START
    };
};
export const GetFieldsDataSuccess = (status) => {
    return {
        type: actionTypes.GET_FIELDS_DATA_SUCCESS,
        FieldsData: status
    };
};
export const GetFieldsDataFail = (error) => {
    return {
        type: actionTypes.GET_FIELDS_DATA_FAIL,
        error: error
    };
};
export const GetFieldsData = (data) => {
    return dispatch => {
        dispatch(GetFieldsDataStart());
        let url = `https://sandbox.api.saas.intelliservice.ai/dev/product-intelligent-triage/crmlayout/get-saved-crm-fields`;
        GetCalltoServer(url).then(response => {
            if (response.data.status.responseType == "success") {
                dispatch(GetFieldsDataSuccess(response.data.data));
            }
            else {
                dispatch(SetErrorMessage('Error occured.'));
                dispatch(GetFieldsDataFail(response.data.errors));
            }
        })
            .catch(err => {
                dispatch(SetErrorMessage('Error occured.'));
                dispatch(GetFieldsDataFail(err.message));
                dispatch(GetFieldsDataSuccess(''));
            });
    };
};
