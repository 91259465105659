// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.issue-results-description {
    font-family: 'Roboto-Regular';
    font-size: 12px ;
    color: #000;
    font-weight: normal;
    text-transform: capitalize;
}
.issue-results-box.card {
    cursor: pointer;
    border: none;
    border-radius: 0;
}

.insight-tabs-container .card-body {
    padding: 0.75rem;
 }

.issue-results-box.selected {
    border: 1px solid #2A93DF;
}

.small-caps {
    text-transform: lowercase;
}

.similar-issue-arrow-img{
    padding-right: 3rem;
}

.issue-results-card-box-data{
    padding: 0.75rem;
    align-items: center;
    vertical-align: middle;
}
.issue-results-box:hover {
    border: 1px solid #2A93DF;
}
`, "",{"version":3,"sources":["webpack://./src/containers/SettingPage/SettingInnerPages/Insights/components/IssueResultsCardComponent/issue-results-card.css"],"names":[],"mappings":";AACA;IACI,6BAA6B;IAC7B,gBAAgB;IAChB,WAAW;IACX,mBAAmB;IACnB,0BAA0B;AAC9B;AACA;IACI,eAAe;IACf,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;CACnB;;AAED;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,sBAAsB;AAC1B;AACA;IACI,yBAAyB;AAC7B","sourcesContent":["\n.issue-results-description {\n    font-family: 'Roboto-Regular';\n    font-size: 12px ;\n    color: #000;\n    font-weight: normal;\n    text-transform: capitalize;\n}\n.issue-results-box.card {\n    cursor: pointer;\n    border: none;\n    border-radius: 0;\n}\n\n.insight-tabs-container .card-body {\n    padding: 0.75rem;\n }\n\n.issue-results-box.selected {\n    border: 1px solid #2A93DF;\n}\n\n.small-caps {\n    text-transform: lowercase;\n}\n\n.similar-issue-arrow-img{\n    padding-right: 3rem;\n}\n\n.issue-results-card-box-data{\n    padding: 0.75rem;\n    align-items: center;\n    vertical-align: middle;\n}\n.issue-results-box:hover {\n    border: 1px solid #2A93DF;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
