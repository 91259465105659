import axios from '../../../../../instance';
import { decrypt } from '../../../../../utility/utility';
const orgName = localStorage.getItem("orgName");
const username = localStorage.getItem("userName");
export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};


export const PostCallToOUServer = (url,data,IsStringify=true) => {
  let token = localStorage.getItem("accessToken");
 
    return  axios.post(url, IsStringify? JSON.stringify(data):data,{
      headers: {
       'Content-Type': 'application/json',
       'Accept': 'application/json',
       'Authorization':`Bearer ${token}`,
       "orgName":orgName,
         "userName":username
       
  }
    });
}

export const GetCalltoOUServer=(url)=>{
    let token = localStorage.getItem("accessToken");
  
      return  axios.get(url,{
          headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
            'Authorization':`Bearer ${token}`,
            "orgName":orgName,
         "userName":username
          // 'orgname': orgname
          }
        });

  }

export const DeleteCalltoOUServer=(url)=>{

  let token = localStorage.getItem("accessToken");
  return  axios({
    method: 'DELETE',
    url: url, 
   // data: IsStringify? JSON.stringify(payload):payload,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization':`Bearer ${token}`,
      "orgName":orgName,
         "userName":username

    
    },
  })
}

export const UpdateCalltoOUServer=(url,data,IsStringify=true)=>{
  let token = decrypt("newAccessToken");
  return  axios({
    method: 'PATCH',
    url: url, 
    data: IsStringify? JSON.stringify(data):data,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization':`Bearer ${token}`,
      "orgName":orgName,
         "userName":username
    },
  })
}
