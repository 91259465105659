import { connect } from 'react-redux';
import { knowledgeArticlesChangeHandlerAction } from '../../../store/actions/insights/insightsActions';
import saveInsightsUnifiedLoginEsDataAction from '../../../store/actions/insights/saveInsightsUnifiedLoginEs/saveInsightsUnifiedLoginEsDataAction';

import KnowledgeArticlesPage from '../KnowledgeArticles/KnowledgeArticles';

export const mapStateToProps = (state) => ({
    enabled: state.insights.knowledgeArticles.enabled,
});

export const mapDispatchToProps = (dispatch,ownProps) => ({
    handleSwitchChange: (event) => {
        dispatch(knowledgeArticlesChangeHandlerAction({
            enabled: event.target.checked
        }))
        const apiUrl = ownProps.config.INSIGHTS_API_URL;
        dispatch(saveInsightsUnifiedLoginEsDataAction({apiUrl, tabName: 'knowledgeArticles',ownProps}));
    },
    saveButtonClickHandler: () => {
        
		const apiUrl = ownProps.config.INSIGHTS_API_URL;
        dispatch(saveInsightsUnifiedLoginEsDataAction({apiUrl, tabName: 'knowledgeArticles',ownProps}));
    },
    ownProps
});

export const KnowledgeArticlesContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(KnowledgeArticlesPage);