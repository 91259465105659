import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import get from 'lodash/get';
import EditRootCause from './EditRootCause/EditRootCause';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const RootCauseData = ({ selectedItem, config, formData, checked ,permissions}) => {
    const sortedSymptomsData = get(selectedItem, 'rootcausedata', []);
    const symptomsdata = sortedSymptomsData.sort((a, b) => Number(b.relevancy) - Number(a.relevancy));
    const [showDrawer, setShowDrawer] = useState(false);
    const [selectedSymptom, setSelectedSymptom] = useState({});
    const [selectedIndex, setSelectedIndex] = useState(0);
    const toggleDrawer = (value, index) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }
        setShowDrawer(value);
        setSelectedSymptom(get(symptomsdata, index, {}));
        setSelectedIndex(index);
    };
    
    return (
        <React.Fragment>
           {checked ? 
             <React.Fragment>
             {symptomsdata.length ? symptomsdata.map((symptom, index) => {
                 return (<React.Fragment>
                      {symptom.status === 'reviewed' ?
                     <Row className="root-cause-table-body no-gutters" onClick={toggleDrawer(true, index)} >
                         <Col className="col-md-4 root-cause-table-data text-break">{symptom.symptoms}</Col>
                         <Col className="col-md-3 text-break">{symptom.leadingQuestion}</Col>
                         <Col className="col-md-2 pl-4 root-cause-table-body-data text-break">{symptom.relevancy}</Col>
                         <Col className="col-md-2 text-break">{symptom.status === 'reviewed'}
                             <button className="root-cause-table-body-review-button text-break">{symptom.status}</button>
                        </Col>
                         <Col className="col-md-1"><img src="./images/Vector.svg" /></Col>
                     </Row>
                      : null}
                 </React.Fragment>)
             }
             ) : null
             }
            </React.Fragment>
            : 
            <React.Fragment>
            {symptomsdata.length ? symptomsdata.map((symptom, index) => {
                return (<React.Fragment>
                    <Row className="root-cause-table-body no-gutters" onClick={toggleDrawer(true, index)} >
                        <Col className="col-md-4 root-cause-table-data text-break">{symptom.symptoms}</Col>
                        <Col className="col-md-3 text-break">{symptom.leadingQuestion}</Col>
                        <Col className="col-md-2 pl-4 root-cause-table-body-data text-break">{symptom.relevancy}</Col>
                        <Col className="col-md-2 text-break">{symptom.status === 'reviewed' ?
                            <button className="root-cause-table-body-review-button text-break">{symptom.status}</button> :
                            <button className="root-cause-table-body-yetReview-button text-break">{symptom.status}</button>
                        }</Col>
                        <Col className="col-md-1"><img src="./images/Vector.svg" /></Col>
                    </Row>
                </React.Fragment>)
            }
            ) : null
            }
            </React.Fragment>
            }
            {showDrawer&& permissions ?
                <EditRootCause
                    showDrawer={showDrawer}
                    symptomsData={selectedSymptom}
                    toggleDrawer={toggleDrawer}
                    id={selectedIndex}
                    selectedItem={selectedItem}
                    closeDrawer={() => {
                        setShowDrawer(false);
                      }}
                    config={config}
                    toastFun={(msg, type) => {
                        toast(msg, {
                          type: type,
                          pauseOnHover: false,
                        });
                      }}
                /> :
            null}
            <ToastContainer />
        </React.Fragment>
    )
};

export default RootCauseData;