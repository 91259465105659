import React from 'react'
import  './SubcriptionCheck.css'

export default function SubcriptionCheck({validity, setValidity, text}) {
  return (
    <div className='SubcriptionCheck'>        
        <div style={{marginRight:'auto'}}></div>        
        <div className='light'> <img src='./images/subcription.png' style={{width:'30px', height:'30px' }} /> <span>{text}</span>   </div>
        <span style={{textAlign:'right', marginTop:10 ,marginLeft:'auto',  cursor:'pointer'}}  onClick={()=> {
           setValidity({...validity , showmsg: localStorage.setItem('popup' , "") })           
        }  } >X</span>
    </div>
  )
}
