import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";
import axios from "../../../instance";
import "./Drawer.css";
import InputWithLabelComponent from "../../../containers/SettingPage/SettingInnerPages/components/InputWithLabelComponent/InputWithLabelComponent";
import TextAreaWithLabelComponent from "../../../containers/SettingPage/SettingInnerPages/components/TextAreaWithLabelComponent/TextAreaWithLabelComponent";
import DrawerComponent from "../../UIComponents/Drawer/DrawerComponent";
import ButtonComponent from "../../UIComponents/Button/ButtonComponent";

let initState = {
right: false,
descEdit:false,
  description: "",
  language: "english_en",
  validation: {
    description: true,
  },
  projDescriptionCount: 220,
};
let ProjDesptouched = false;

class AddProjectDescriptionDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initState,
      right: true,
    };
  }

  handleInputs = (key) => (event) => {
    
    let val=event.target.value
    let len=val.length
    if(val.startsWith(" ")){
      return;
    }
      else if (
       val.length !== 0 &&
       val !== "" &&
       val !== undefined &&
       val !== null
      ) {
        ProjDesptouched = true;
      } else {
        ProjDesptouched = false;
      }
      this.setState({
        ...this.state,
        [key]:val,
        validation: {
          ...this.state.validation,
          [key]: [key] ? true : false,
        },
        projDescriptionCount: 220 - len,
        descEdit:true
      });
  };

  validationOnSubmition = () => {
    const {  description } = this.state;
    this.setState({
      ...this.state,
      validation: {
        description: description ? true : false,
      },
    });
    return  description ? true : false;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.onHide("adddescription")
    let payload={
        name: localStorage.getItem('projectname'),
        description: this.state.description,
        language: localStorage.getItem('language'),
      }
 
      this.props.onUpdateProjectDescription(
      this.props.config.BOTBUILDER_URL,payload
      );
      let docvalue = {
        projectname: localStorage.getItem("projectname"),
        language: localStorage.getItem("language"),
      };
      setTimeout(() => {
        this.props.OngetProjectDescription(
          docvalue,
          this.props.config.BOTBUILDER_URL,
        );
        
      }, 1500);
    this.setState({
      ...this.state,
      descEdit:false
    })
};

  toggleDrawer = (open) => (event) => {
    if (!open) {
      this.props.onHide("adddescription");
    }
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      this.props.onHide("adddescription");
      return;
    }
    let count=this.props.getprojDesp.description
    this.setState({ 
      right: open,
      description:this.props.getprojDesp.description ,
      descEdit:false,
      projDescriptionCount:220-count.length
    });
  };

  componentWillUnmount() {
    ProjDesptouched = false;
  }
  componentDidMount(){
    if(this.props.getprojDesp){
      this.setState({
        description:this.props.getprojDesp.description
      })
    }
  }

  componentDidUpdate(prevProps){
    ProjDesptouched=true
    if(this.props.getprojDesp){
      let count=this.props.getprojDesp.description?this.props.getprojDesp.description:'';
    if(prevProps.getprojDesp!==this.props.getprojDesp){
      this.setState({
        description:this.props.getprojDesp.description,
        projDescriptionCount:220-count.length,
      })
    }
  }
  }
    
  
  render() {

    let ProjDespCharString = ProjDesptouched ? "Left" : "";
    return (
      <React.Fragment>
        <DrawerComponent
          heading="Project Details"
          showDrawer={this.toggleDrawer(false)}
          openDrawer={this.props.show}
        >
          <div className="drawer">
            <div className="drawer-body mt-2">
              <form>
                <InputWithLabelComponent
                  placeHolder=""
                  className={"drawer-input"}
                  type="text"
                  name="name"
                  label="Project Name"
                  value={this.props.projectName}
                  maxLength="15"
                  disabled={true}
                />

                <div className="mt-3">
                  <TextAreaWithLabelComponent
                    label="Description*"
                    name="description"
                    required={false}
                    value={this.state.description}
                    className="text-area"
                    placeholder=""
                    isInvalid={
                      !this.state.validation.description ? true : false
                    }
                    onChange={this.handleInputs("description")}
                    maxLength="220"
                  />
                  <span
                    style={{
                      float: "right",
                      color: "#A49D9D",
                      fontStyle: "Roboto-bold",
                      fontSize: "12px",
                      fontWeight: "700",
                      position:'relative',
                      top:'4px',
                    }}
                  >
                    {this.state.projDescriptionCount} Characters{" "}
                    {ProjDespCharString}
                  </span>
                </div>
                <div className="form-group submit-btn-parent mt-4">
                  <ButtonComponent
                    variant="contained"
                    label="SAVE"
                    clicked={this.handleSubmit}
                    className="buttonWidth"
                    disabled={
                      !(
                        this.state.descEdit&&
                        this.state.description.trim()!=""
                      )
                    }
                  />
                </div>
              </form>
            </div>
          </div>
        </DrawerComponent>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
      onUpdateProjectDescription:(apiURL,payload)=>
      dispatch(actions.updateProjectDescription(apiURL,payload)),
    onSetSuccessMessage: (message) => dispatch(actions.SetSuccessMessage(message)),
    OngetProjectDescription: (apiUrl, data) =>
    dispatch(actions.getProjectDescription(apiUrl, data)),

  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AddProjectDescriptionDrawer, axios));
