// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ResolutionsPage .Settings-icon img{height: 18px;margin-bottom: 3px;}
.ResolutionsPage .card-header{display: flex;
  align-items: center;
  justify-content: space-between;}

  .ResolutionsPage .ResolutionActions{display: flex;
    margin-right: 25px;}
    .ResolutionsPage .form-group{position: relative;}
    .ResolutionsPage label.form-label{position: absolute;
      top: -13px;
      background: white;
      left: 18px;
      padding: 0 10px;}
      .ResolutionsCount{right: 16px;}
.saveBtn {
  margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/Insights/TopResolutions/Resolution.css"],"names":[],"mappings":"AAAA,oCAAoC,YAAY,CAAC,kBAAkB,CAAC;AACpE,8BAA8B,aAAa;EACzC,mBAAmB;EACnB,8BAA8B,CAAC;;EAE/B,oCAAoC,aAAa;IAC/C,kBAAkB,CAAC;IACnB,6BAA6B,kBAAkB,CAAC;IAChD,kCAAkC,kBAAkB;MAClD,UAAU;MACV,iBAAiB;MACjB,UAAU;MACV,eAAe,CAAC;MAChB,kBAAkB,WAAW,CAAC;AACpC;EACE,gBAAgB;AAClB","sourcesContent":[".ResolutionsPage .Settings-icon img{height: 18px;margin-bottom: 3px;}\n.ResolutionsPage .card-header{display: flex;\n  align-items: center;\n  justify-content: space-between;}\n\n  .ResolutionsPage .ResolutionActions{display: flex;\n    margin-right: 25px;}\n    .ResolutionsPage .form-group{position: relative;}\n    .ResolutionsPage label.form-label{position: absolute;\n      top: -13px;\n      background: white;\n      left: 18px;\n      padding: 0 10px;}\n      .ResolutionsCount{right: 16px;}\n.saveBtn {\n  margin-top: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
