import React, { useState, useEffect } from "react";
import { Row, Col, Container, Form, Navbar, Nav } from "react-bootstrap";
import SymptomsTab from "./SymptomTab";
import RootCauseTab from "./RootCausesTab";
import { useDispatch, useSelector } from "react-redux";
import "./SymptomAndRootCause.css";
import { useHistory } from 'react-router-dom';
import TabsComponent from "../../../components/TabsComponent/TabsComponent";
import advanceFilterChangeHandlerAction from "../../../../../../store/actions/agentInsights/advanceFilterChangeHandlerAction";
import NavbarCustom from '../../../../../../components/UI/Navbar/Navbar'
import { connect } from 'react-redux';
import * as actions from '../../../../../../store/actions/index'
import SourceFields from "../SourceFields";
import permissionsJson from "../../../../../../permissions_Json"
import { encryptAndStore } from "../../../../../../utility/utility";



const SymptomAndRootCauseTab = ({ config,projectList,onchangeProjectAction,onChangeAdminHome }) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState("symptoms");
  const [ popperShow,setPopperShow]= useState(false);
  const [searchT, SetSearchT] = useState("");
  const [projectname, SetProjectname] = useState("Project List");
  const [popperSourceShow, setPopperSourceShow] = useState(false);
  const [openUI, setOpen] = React.useState(false);
  const [sourceSelect, setSourceSelect] = useState("ServiceNow - demodata");
  const permissions = useSelector(state => {
    return state.user.permissionList;
  });
  let  ProductTriage_Read_Write = false;
  let ProductTriage_RW_Status = permissions.find(data => {
    if (data.permission_code.includes(permissionsJson.permissionCode.PRODUCT_TRIAGE_ADMIN_RW ) && data.sku_code.includes(permissionsJson.skuCode.PT) ||
    data.permission_code.includes( permissionsJson.permissionCode.PRODUCT_TRIAGE_TRAIN_RW))
     return true
  })
  ProductTriage_Read_Write = ProductTriage_RW_Status ? true : false; 
  const onTabChange = (selected) => {
    setSelectedTab(selected);
       // dispatch(advanceFilterChangeHandlerAction([]));
  };
  const tabItems = [
    {
      label: 'SYMPTOMS',
      value: 'symptoms'
    },
    {
      label: 'ROOT CAUSES',
      value: 'rootCause'
    }
  ];
  const setSearchTerm = (item) => {
    SetSearchT(item)
  }
  const projectclick =(event,projectname)=>{
    dispatch(advanceFilterChangeHandlerAction([]));
    SetSearchT("");
    setPopperShow(false)
      let savedProjectName=localStorage.getItem('projectname');
      if(savedProjectName!==projectname){
      // localStorage.setItem('projectname', projectname );
      encryptAndStore('projectname', projectname );
      SetProjectname(projectname);
      }
      onchangeProjectAction(projectname);        
        //window.updateHCLChatBotURL(projectname);
  }
  const onSourceSelectChange = (selected) => {
    setSourceSelect(selected);
  
  };

  const popperShowFn=()=>{
    setPopperShow(true)
  }
  return (
    <Container className="symptom-rootcause triageTabStyle" fluid>
      <Row xs={12} className='d-flex '>
        <Col xs={9} style={{marginRight: "1.9rem"}} className="d-flex justify-content-between symptoms-imgs mt-4 mb-3">
          <h3 className="pageTitleText">Triage Data </h3> {/*  &nbsp;<Form.Label className="mb-1 text-box-title adding-other-input">Last Syncd @10 Feb 10:10</Form.Label> */}
        </Col>
        {/* <SourceFields 
        config = {config}
        projectList = {projectList}
        onchangeProjectAction = {onchangeProjectAction}
        /> */}
        <Col xs={2} className="mt-4 Project-align-styles mb-3" >
          <NavbarCustom 
              dataList={projectList}
              addImportProjectIcon={false}
              setSearchTerm={(item)=>setSearchTerm(item)}
              popperShowFn={()=>popperShowFn()}
              popperShow={popperShow}
              searchTerm={searchT}
              projectclick={(event,item)=>projectclick(event,item)}
              projectname={projectname} 
              rightPopUp={false} >
          </NavbarCustom>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <TabsComponent tabItems={tabItems}
            selected={selectedTab}
            setSelected={onTabChange} className='t-69'>
            {selectedTab === 'symptoms' ?
              <SymptomsTab config={config} permissions={ProductTriage_Read_Write}/>
              : null
            }
            {selectedTab === 'rootCause' ?
              <RootCauseTab config={config} permissions={ProductTriage_Read_Write}/>
              : null
            }
          </TabsComponent>
        </Col>
      </Row>
    </Container>
  );
};
const mapStateToProps = state => {
  return {
      projectList:state.user.projects,
  };
};
const mapDispatchToProps = dispatch => {
  return {
      onchangeProjectAction: (projectname) => dispatch(actions.changeProjectAction(projectname)),
  };
};

export default connect( mapStateToProps, mapDispatchToProps )(SymptomAndRootCauseTab);
