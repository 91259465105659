import React, { memo, useMemo, useEffect } from 'react';
import { Handle, useUpdateNodeInternals } from 'react-flow-renderer';
import NodeBox from './NodeBox';

export default memo(({ id, data, isConnectable }) => {
    
  let sourceArray = data.optionValues ? data.optionValues : [];
  const nodeId = id;
  let segment = 220/sourceArray.length;
  let gap = segment/2;

  const positionHandle = (index) => {
      return segment * index + gap ;
  }

  const boxList = useMemo(
    () => {
      let newItem = null;
    let arr = [];
    for (let option of sourceArray) {
      newItem = <button key={option.option} className='box-options' >{option.option}</button>;
      arr.push(newItem);
    }
    return arr;
    }
  )


  useEffect(() => {
    updateNodeInternals(nodeId);
  }, [sourceArray, data.branched])

  const updateNodeInternals = useUpdateNodeInternals();

  const sourceHandles = useMemo(
      () =>
          sourceArray.map((item, index) => {
              const handleId = item.option;
              return (
                  <Handle
                      key={index}
                      type="source"
                      position="bottom"
                      id={handleId}
                      style={{ left: positionHandle(parseInt(index)) }}
                      isConnectable={isConnectable}
                  />
              );
          }),
      [sourceArray]
  );

  const defaultHandle = useMemo(
    () =>
      <Handle
          key='1'
          type="source"
          position="bottom"
          isConnectable={isConnectable}
      />
  );

  return (
    <>
      <Handle
        type="target"
        position="top"
        style={{ background: '#555' }}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />
      <NodeBox type='Box Selector' data={data} boxList={boxList}></NodeBox>
      {data.branched === 'true' ? sourceHandles : defaultHandle}
          
    </>
  );
});