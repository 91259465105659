

const fetchRecommendedSolutionSuccessReducer = (state, payload) => {
    return {
        ...state,
        // recommendedSolutionData: JSON.parse(get(payload, 'data.data', {})),
        recommendedSolutionData: payload,
        recommendedSolutionDataLoading: false,
    };
};

export default fetchRecommendedSolutionSuccessReducer;