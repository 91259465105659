import React from "react";
import { Form } from "react-bootstrap";
import * as PropTypes from "prop-types";
import SelectComponent from "../../../../../components/UIComponents/SelectDropdown/SelectComponent";
import "./font-family-wrapper.css";
import * as lists from '../Constants';


const FontFamilyComponent = ({
    label,
    labelContent,
    onChange,
    labelContentColor,
    name,
    value,
    flowLink,
    projectLink,
    flowLinkChange,
    projectLinkChange,
    projectLinks,
    flowLinks,
    isInvalid,
    error,
    disabled,
    showLabel,
    required,
    className,
    placeHolder,
    readOnly,
    url
}) => {
    
    return (
        <div className="common-input">
            {showLabel ? (
                <Form.Label className="mb-0 text-box-title adding-other-input">{label}</Form.Label>
            ) : null}
            <div>
                <div className="input-group routing-div">
                <label className="flow-link-wrapper" >
                    <span>{labelContent}</span>
                </label>
                     <SelectComponent name="selectName"
                        label=""
                        className="link2"
                        changed={(event) => onChange(event.value)}
                        required={true}
                        value={value}
                        options={lists.fontFamilies} 
                        disabled={disabled}
                        />

                </div>
            </div>
            {isInvalid ? (
                <span data-testid={`route-Err`} className="error-css">{error}</span>
            ) : null}
        </div>
    );
};
FontFamilyComponent.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    className: PropTypes.string,
    type: PropTypes.string,
    placeHolder: PropTypes.string,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    isInvalid: PropTypes.bool,
    error: PropTypes.string,
    helpIcon: PropTypes.bool,
    showLabel: PropTypes.bool,
};

FontFamilyComponent.defaultProps = {
    label: "Input Label",
    name: "input-class",
    className: "",
    placeHolder: "",
    readOnly: false,
    required: false,
    isInvalid: false,
    type: "text",
    error: "",
    helpIcon: false,
    showLabel: true,
};
export default FontFamilyComponent;
