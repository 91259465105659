/*

* Copyright © 2020, HCL Technologies Ltd. ALL RIGHTS RESERVED.

* <p>

* This software is the confidential information of HCL Technologies Ltd., and is licensed

* as restricted rights software. The use,reproduction, or disclosure of this software

* is subject to restrictions set forth in your license agreement with HCL.

*/



/**

* Third party stand alone Cognitive component.

*

* @author Abhash Vats

*/
import React, { useState, useEffect } from "react";
import Lists from './Lists';
import { Row,Col} from 'react-bootstrap';
import axios from '../instance';
import classesName from './Cognitive.module.css'
import SpinnerPage from '../components/UI/Loader/Loader';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import cognitiveBackground from "./image/cognitiveBackground.png"
import intelliService from "./image/intelliService.png";
import Drawer from '@material-ui/core/Drawer';
import { useSelector } from "react-redux";
import './Cognitive.css'
import {useLocation} from "react-router-dom";
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import SpeechRecognition,{useSpeechRecognition} from 'react-speech-recognition';
import {decrypt, encryptAndStore} from '../utility/utility';
const useStyles = makeStyles({
  root: {
         width: '100%',
       },
  style:{
        '& .MuiPaper-root':{
          width: '100%',
          overflow:"auto"
        }
    }, 
  iconStyle:{
    '& .path':{
      strokeWidth:"1px",
    }
  },
})
function PCognitive(props) { 
  let fileencryptedContent;
  const classes = useStyles();
  const [cognitivePage, setCognitivePage]  = useState(null)
  const [inValue, setInValue]  = useState(null)
  const [cognitiveLoading, setCognitiveLoading] = useState(false)
  const [search, setSearch] = useState('');
  const [listView,setListView] = useState(false);
  const [shPara, setShpara] = useState({"para":[]});
  var [showDrawer, setShowDrawer] = useState(false);
  const [filepath,setfilepath] = useState('')
  const [filetitle,setFileTitle] = useState('')
  const [thumbs, setThumbs] = useState("false")
  const [project,setproject] = useState([])
  const [csResult, setCsResult] = useState(null)
  const [arraySize, setArraySize] = useState([{ startIndex: 0, endindex: 5 }]);
  const data = './CognitiveDocs/';
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [loading, setLoading] = useState(false)
  const [selectedProject,setSelectedProject] = useState(localStorage.getItem("projectname"))
  const [Latesttoken,setLatesttoken]= useState('')
  const [showmore,setShowmore] = useState(false)
  const [language,setLanguage] = useState([localStorage.getItem('language')]);
  const[selectedLang,setSelectedLang]=useState('');
  const selectedFilterData = useSelector((state) => state.agentInsights.selectedFilterDataArr);
  const knowledgeSearch= selectedFilterData.description
  let urlLink=''; let urlQuery='';let orgnameURL=''
  const orgName = localStorage.getItem("orgName");
  const userName = localStorage.getItem("userName");
  const location = useLocation();
  const[getUsername,setGetUsername]=useState('');
  const[getOrgName,setGetOrgName]=useState('');

  let multiProject = [];
  if(location.search!=''){
    console.log(new URLSearchParams(location.search).get('project'))
    let project = new URLSearchParams(location.search).get('project')
    let query = new URLSearchParams(location.search).get('query')
    orgnameURL = new URLSearchParams(location.search).get('orgname')
    urlLink = project?project:''
    urlQuery = query ?query:''
    
    if (urlLink.indexOf(',') != -1) {
      multiProject = urlLink.split(',');
      urlLink = multiProject[0]
    }
  }
 console.log("multiProject=======",multiProject)
  useEffect (() => {
    if(props.knowledgeSearch == '13'){
      KnowledgeSearch(knowledgeSearch);
    }
    if(urlLink){
      let project = urlLink
      setSelectedProject(project)
      // localStorage.setItem("projectname",project);
      encryptAndStore("projectname",project);
    }
    if(urlQuery){
      let decodelink = decodeURIComponent(urlQuery)
      setSearch(decodelink)
      addSearch(decodelink)
    //  setTimeout(() => {
     //   handleSearch(decodelink)
     // }, 1000);
      
    }
    let user = localStorage.getItem("sessiontoken");
        user = JSON.parse(user);
        if(user !== null)
        {     
              let userdetail = {};
              let url ='';      
                url=props.config.BOTBUILDER_PROXY + `/project/${localStorage.getItem("language")}`;
                  userdetail={"useremailid":user.useremailid};   
                  GetCalltoServer(url).then(response=>{
                      if(response.data.status.responseType == "success"){
                              //setproject(response.data.data) 
                              setproject(response.data.data)
                          }
                  }).catch(err => {
                      console.log("error",err);
                  });     
        }
        let getorgname='', orgnamee='';
        if (orgnameURL) {
          getorgname = orgnameURL
        } else if (localStorage.getItem("orgName")) {
          getorgname = localStorage.getItem("orgName")
        } else {
          getorgname = "intelliservice"
        }
        if(getorgname) {
          orgnamee = getorgname
          setGetOrgName(orgnamee)
        }
    
    axios.post(props.config.VIRTUAL_ASSISTANCE_URL + '/virtualAssistant/getLatestToken',{orgname:orgnamee}, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        "orgName": localStorage.getItem("orgName"),
        "userName": localStorage.getItem("userName")
      }
    }).then((response) => {
      let token = '', username='';
      let gettokens = response.data.data;
      
      if(getorgname) token = gettokens['token']
      if(getorgname) {
       // username = getorgname['username']
        username = gettokens['username']
        setGetUsername(username)
      }

      //if(tokens[orgnameURL])
      setLatesttoken(token)
      let url = '';
      url = props.config.BOTBUILDER_PROXY + `/project/en`;
      GetCalltoServer(url, token,username,orgnamee).then(response => {
        if (response.data.status.responseType == "success") {
          setproject(response.data.data)
        }
      }).catch(err => {
        console.log("error", err);
      });
    });


  }, []);

  useEffect(() => {
    if (urlQuery) {
      let decodelink = decodeURIComponent(urlQuery)
      setTimeout(() => {
        handleSearch(decodelink)
      }, 1000);

    }
    console.log('Latesttoken', Latesttoken);
  }, [Latesttoken]);

//console.log("selectedProject=========",selectedProject)
//console.log(localStorage.getItem("projectname"))
  const GetCalltoServer=(Url,tokennew,usernamee,orgnamee)=>{
    console.log("usernameee")
    console.log(usernamee)
    let token = localStorage.getItem("accessToken")
    // let token = decrypt("newAccessToken")
    if(token==null){
      token = tokennew
     // userName = usernamee
    }
   return  axios.get(Url,{
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'Authorization':`Bearer ${token}`,
        "orgName":orgName==null?orgnamee:orgName,
        "userName":userName==null?usernamee:userName
        // "orgName":localStorage.getItem("orgName"),
        // "userName":localStorage.getItem("userName")
      }
    });
  }
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  }=useSpeechRecognition({});

  const startlistening=() => {
    SpeechRecognition.startListening()
    setSearch("")
    resetTranscript()
    //handleSearch((transcript).split('.').join(""));
  };

  const stoplisten=() => {
    SpeechRecognition.stopListening({continuous:true})
    setSearch((transcript).split('.').join(""))
    if(transcript.length != 0){
      handleSearch((transcript).split('.').join(""));
    }
  };

  // const call =() =>
  // setSearch((transcript).split('.').join(""))
  // handleSearch((transcript).split('.').join(""));
  // if(!browserSupportsSpeechRecognition){return <span>doesnot support</span>}

   const botPostCalltoServer=(Url,data,IsStringify=true)=>{
    let token = localStorage.getItem("accessToken")
    // let token = decrypt("newAccessToken");
    token = Latesttoken
        const requestOptions = {
      method: 'POST',
      headers: { 'content-type': 'application/json',
      'Authorization':`Bearer ${token}`, 
      "orgName":orgName==null?getOrgName:orgName,
      "userName":userName==null?getUsername:userName
 
    },
      data: data,
      Url,
      mode: 'no-cors'
    };
    /*setTimeout(() => {
    return  
    },10);*/

      return  axios.post(Url, IsStringify? JSON.stringify(data):data,requestOptions,{
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            "orgName":orgName,
            "userName":userName
 
          }
        });
  }
  useEffect(() => { 
    language == 'af' ? setSelectedLang('Afrikaans') : 
    language == 'ar' ? setSelectedLang('Arabic'):
    language == 'de' ? setSelectedLang('German'):
    language == 'el' ? setSelectedLang('Greek'):
    language == 'es' ? setSelectedLang('Spanish'):
    language == 'fr' ? setSelectedLang('French'):
    language == 'it' ? setSelectedLang('Italian'):
    language == 'hu' ? setSelectedLang('Hungarian'):
    language == 'zh' ? setSelectedLang('Chinese'):
    language == 'ru' ? setSelectedLang('Russian'):
    language == 'el' ? setSelectedLang('Greek'):
    language == 'ja' ? setSelectedLang('Japanese'):
    language == 'pl' ? setSelectedLang('Polish'):
    language == 'hi' ? setSelectedLang('Hindi'):setSelectedLang('English')
}, [language]);

  const handleSearch=(search)=>{
    setStartTime(new Date().toUTCString())
    setCognitiveLoading(true)
    setListView(true)
        var project = (selectedProject == 'All Projects' || selectedProject == null || selectedProject == 'common' )?"All":selectedProject
        // project = (selectedProject == 'common' || selectedProject == null ) ? "All" : selectedProject
        let configObj={};
        configObj["project"]=project;
        configObj["query"]=search;
        configObj["language"]=selectedLang;
        let url = props.config.BOTBUILDER_URL + `/cognitive/cognitivesearch`;
        let newArray = [...arraySize];
        newArray[0]["endindex"] = 5;
        setArraySize(newArray);
        //setTimeout(() => {
         // console.log("kkkifhrufejrk   frkjkewllllllllllllllllllllllllll")
          
          botPostCalltoServer(url, configObj).then(response => {
            if (response.data.status.responseType == "success") {
             
                let cognitive = response.data.data;
                setCognitivePage(response.data.data)
                if(cognitive == null || typeof cognitive === "undefined" || cognitive.length==0){
                    cognitive=[{"response":"Please rephrase your question"}];
                }
                else{
                  for(var i=0;i<cognitive.length;i++){
                    var array = [];
                    for(var j=i+1;j<cognitive.length;j++){
                      if(cognitive[i].title == cognitive[j].title){
                          array.push(cognitive[j])
                          cognitive.splice(j,1);
                          j--;
                      }
                    }
                    cognitive[i].common=array 
                  }
                }
                setInValue(cognitive)
                setCognitiveLoading(false) 
                setEndTime(new Date().toUTCString())
            }
            else { 
              let msg = JSON.parse(response.data.errors).error;
              if(msg.indexOf("Please rephrase your") !==-1 ){
                let cognitiveResp=[{"response":"Please rephrase your question"}];
                setInValue(cognitiveResp)
              }
              setCognitiveLoading(false)              
            }
        }).catch(() => { 
          setCognitiveLoading(false) 
        });
       // }, 4000);
        
  };

  const handleShow = (view, relavant,paras,files,title) => {
       setShpara({"para":shPara})
  };

 
  const addSearch=(values)=>{
    setShowDrawer(false)
    setThumbs("false")
    setSearch(values);
    setListView(false)
  }
  const KnowledgeSearch=(values)=>{
    setShowDrawer(false)
    setThumbs("false")
    setSearch(values);
    setListView(false)
    handleSearch(values);
  }
  const paraShow=(paraGraph)=>{
    let all=[]
    let para=null;
    if(inValue){
     inValue.map(user =>(
       all.push(user.para))
         )  
     }  
    para=all.join() 
  }

  const drawerShow = (title,type,fileencryptedContent,result,length,para,showmore,common) => {
    setCsResult(result)
    setShowDrawer(true)
    if(type != null)
      type= type.toLowerCase();
    
    setFileTitle(title)
    setThumbs("false")
    if(type == "pdf" || type == 'jpg' || type == 'bmp' || type == 'png' || type == "ppt" || type == "pptx" || type == 'tiff' || type == 'txt' || type == 'text' || type == "docx" || type == 'doc'|| type == 'xls'|| type == 'xlsx'|| type == 'mp4' || type == 'mp3' || type == 'csv' || type == 'html'){
      if(result.sourceURL == null || result.sourceURL == "")
        viewFile(title,type,para,showmore,common)
      else
        downloadFileWithName(result.sourceURL,title,type)
    }
    else
     downloadFileWithName(result.sourceURL,title,type)
  }  
const handleResponse = () =>{
  fileencryptedContent=null;
}
  const viewFile = async (title,type,para,showmore,common)=>{
  let pgno;
  
  cognitivePage.map((val)=>{
    if(val.title == title){
      pgno = val.pgno;
    }
  })
if(showmore == true){
  common.map((data)=>{
    if(para.includes(data.para)||data.para.includes(para)){
      pgno = data.pgno;
    }
  })
}
  var random = Math.floor((Math.random() * 1000000) );
  setLoading(true)
    let configObj={};
    configObj["project"]=selectedProject;
    configObj["file"]=title+"."+type;
    let projName=selectedProject;
    let url = props.config.CS_GOLANG + `/ctrainer/download`;
    await botPostCalltoServer(url, configObj).then(response => {            
         fileencryptedContent =  response.data;         
        var fileformat="";
        if(type != null)
          type= type.toLowerCase();
        if(type == 'pdf') {
          let blob = base64ToBlob( fileencryptedContent, 'application/pdf' );
          let url = URL.createObjectURL( blob );
          url= url+`#page=`+ pgno
          fileformat = url;
          // if (url) url = URL.revokeObjectURL(url), undefined;
          // blob = null;
        }
        else if(type == 'jpg' || type == 'bmp' || type == 'png' || type == 'tiff' || type == 'mp4' || type == 'mp3')
          downloadFileWithNamee("data:image/"+type+";base64,"+fileencryptedContent,projName,title,random,type)
        else if(type == 'doc')
          downloadFileWithNamee("data:application/msword;base64,"+fileencryptedContent,projName,title,random)
        else if(type == 'docx')
          downloadFileWithNamee("data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,"+fileencryptedContent,projName,title,random)
        else if (type == 'htm')
          downloadFileWithNamee("data:text/htm;base64," + fileencryptedContent,projName,title,random)
        else if (type == 'html')
          downloadFileWithNamee("data:text/html;base64," + fileencryptedContent,projName,title,random)
        else if (type == 'ppt')
          downloadFileWithNamee("data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64," + fileencryptedContent,projName,title,random)
        else if (type == 'pptx')
          downloadFileWithNamee("data:application/vnd.openxmlformats-officedocument.presentationml.presentation;base64," + fileencryptedContent,projName,title,random)
        else if (type == 'txt')
          downloadFileWithNamee("data:text/plain;base64," + fileencryptedContent,projName,title,random)
        else if (type == 'xls')
          downloadFileWithNamee("data:application/vnd.ms-excel;base64," + fileencryptedContent,projName,title,random)
        else if (type == 'xlsx')
          downloadFileWithNamee("data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + fileencryptedContent,projName,title,random)
          else if (type == 'csv')
          downloadFileWithNamee("data:text/csv;base64,"+ fileencryptedContent,projName,title,random)
        setfilepath(fileformat)  
        setLoading(false)

   
    }).catch(err => { 
      console.log(err)
      setLoading(false)
    });
 }
 
 const base64ToBlob = (base64, type = "application/octet-stream") => {
  const binStr = atob( base64 );
  const len = binStr.length;
  const arr = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    arr[ i ] = binStr.charCodeAt( i );
  }
  return new Blob( [ arr ], { type: type } );
}
const showmorepara = (index) =>{
  showmore == true?setShowmore(false):setShowmore(true)
}

const downloadFileWithName = (uri,title,type) => {
  let configObj={};
    configObj["projectName"]=selectedProject;
    configObj["url"]=uri;    
    let url = props.config.BOTBUILDER_URL + `/downloadPdf`;
    botPostCalltoServer(url, configObj).then(response => {            
        var fileencryptedContent = response.data.data;         
        var fileformat="";
        if(type=='pdf'){
        const blob = base64ToBlob( fileencryptedContent, 'application/pdf' );
        const url = URL.createObjectURL( blob );
        fileformat = url;
        }
        setfilepath(fileformat)  
   
      }).catch(err => { 
        console.log(err)
      });
    }
    const downloadFileWithNamee = (uri,projName,title,random,type) => {
      
      let name;
      if(type == 'jpg' || type == 'bmp' || type == 'png' || type == 'tiff' || type == 'mp4' || type == 'mp3'){
        name = projName+"_"+title+"."+type;
      }
      else{
      name = projName+"_"+title+"_"+random;
      }
      let link = document.createElement("a");
      link.download = name;
      link.href = uri;
      link.click();
    }

  const closeDrawer = () =>{
    setShowDrawer(false)
    setThumbs("false")
  }

  const feedback1 = (feedback,result) => {
    setThumbs("true")  
    let userDetails = localStorage.getItem("sessiontoken");

    userDetails=userDetails==null?'GuestUser' : JSON.parse(userDetails);
        var project = (selectedProject == 'All Projects' || selectedProject == null)?"All":selectedProject
        let data = { "query":search,"para":result.para, "score": parseInt(result.score*100) ,"satisfied":feedback,"views":1,
        "user":userDetails==null?'GuestUser' :userDetails.useremailid,"project": project,
        "startTime":startTime,"endTime":endTime,"fileName":result.title==null?"":result.title+"."+result.fileType,"sourceURL":result.sourceURL == null?"":result.sourceURL}
        let url = props.config.BOTBUILDER_URL + `/cognitive/savecsresults`;
        botPostCalltoServer(url, data).then(response => {
            if (response.data.status.responseType == "success"){ 
                console.log("Feedback info saved successfully!") 
            }           
            else {
            console.log("Feedback info saved unsuccessful!")
            }
        }).catch(() => {  
          console.log("error occured in Feedback!!!")
        });
  }

  const feedback = (feedback) => {
    setThumbs("true")     
    let userDetails = localStorage.getItem("sessiontoken");
    userDetails = userDetails==null?'GuestUser' :JSON.parse(userDetails);
        var project = (selectedProject == 'All Projects' || selectedProject == null)?"All":selectedProject
        let data = { "query":search,"para":csResult.para, "score": parseInt(csResult.score*100) ,"satisfied":feedback,"views":1,
        "user":userDetails==null?'GuestUser' :userDetails.useremailid,"project": project,
        "startTime":startTime,"endTime":endTime,"fileName":csResult.title==null?"":csResult.title+"."+csResult.fileType,"sourceURL":csResult.sourceURL == null?"":csResult.sourceURL}
        let url = props.config.BOTBUILDER_URL + `/cognitive/savecsresults`;
        botPostCalltoServer(url, data).then(response => {
            if (response.data.status.responseType == "success") {
                console.log("Feedback info saved successfully!")
            }            
            else {
            console.log("Feedback info saved unsuccessful!")
            }
        }).catch(() => {  
          console.log("error occured in Feedback!!!")
        });
  }
  const selectVal = (event) => {
    setSelectedProject(event.target.value);
    // localStorage.setItem("projectname",event.target.value)
    encryptAndStore("projectname",event.target.value)
  }
  
  const loadMoreHandle = (e)=>{
    let bottom = e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop<50
    if(bottom){
      let newArray = [...arraySize];
      newArray[0]["endindex"] += 5;
      setArraySize(newArray);
    }
  }

  const onChangeBorder =(index) =>{
    var x = document.getElementById(index).style.border
    if(x=="2px solid rgb(51, 98, 204)"){
      document.getElementById(index).style.border = "none"
    }
    else{
      document.getElementById(index).style.border = "2px solid #3362CC"
    }
  }
  const SortArray = (x, y) => {
    var nameA = x.toUpperCase();
    var nameB = y.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }
  console.log("seletedproject=========="+selectedProject)
  return (
    <>
      <div className={classesName.homeContent} style={{backgroundImage: search && (inValue) && listView && !cognitiveLoading?'':`url(${cognitiveBackground})`,
        backgroundColor:search && (inValue) && listView && !cognitiveLoading?"#E5E5E5":'',
        backgroundRepeat: "no-repeat", width: "100%",height:"100vh",backgroundSize:"100% 100%",overflow:"hidden"}}>
        <div className={classesName.homeRecentContent} style = {{marginBottom:"10px"}}> 
          {cognitiveLoading? (<SpinnerPage/>):null}
          <Row>
            <Col md={12}>
              {props.standAlone?<><div style={{color:"#3362CC",paddingTop:"10px",fontSize: "24px", lineHeight:"40px", fontWeight: "900",marginBottom:"10px",paddingLeft:"10px"}}>Cognitive Search</div>
              <div style={{position:"absolute",marginTop:"-4px",paddingLeft:"10px",fontFamily:"Roboto-Regular"}}>Powered by </div>
              <span style = {{backgroundImage: `url(${intelliService})`,backgroundRepeat: "no-repeat", height:"18px", width: "150px", display: "inline-flex",marginLeft:"95px"}}></span></>
              :(props.knowledgeSearch == '13') ? null :<><div style={{paddingTop:"10px",fontSize: "24px",fontWeight: "900",lineHeight:"40px",paddingLeft:"10px",fontFamily:"Roboto-Black",display:"grid"}}>Cognitive Search
              <span style={{fontSize:"12px",fontFamily:"Roboto-Light",fontWeight:"300",lineHeight:"16px",marginBottom:"10px"}}>Enables to perform a context based search across multiple documents configured with a project.</span>
              </div></>
            }
                <div className={[classesName.contentTableOuter]}>
                  <Row>
                    <Col md={10} style={{paddingLeft:"0px",paddingRight:"0px"}}> 
                      <div className="input-group" style={{width:"70%",marginLeft:"15px",float:"left",marginTop:"10px",paddingLeft:"10px"}}>
                      { props.knowledgeSearch == '13' ? null :
                      <div 
                      style={{marginTop:"-4px"}}
                      >
                      <select style={{
                        color:"#000000",
                      height:"49px",
                      borderTopWidth: "1px",
                      borderBottomWidth: "1px",
                      borderLeftWidth: "1px",
                      borderTopLeftRadius: "30px",
                       borderBottomLeftRadius: "30px",
                       borderTopColor: "Black",
                       borderBottomColor: "Black",
                       borderLeftColor: "Black",
                       borderRightColor: "Black",
                       borderRightWidth: "1px",
                       textAlignLast: "start",
                       fontSize: "18px",
                       fontWeight: "900",
                       fontFamily: 'Roboto-Regular',
                       lineHeight:'40px',
                       backgroundSize:"16%",
                       backgroundPositionX:"98%",
                       backgroundPositionY:"10px",
                       paddingLeft:"25px"
                      }}
                      id="projectDropdown"
                      name="selectName " required className={(((urlQuery && urlLink) ||urlLink )&&(multiProject.length==0))?"selectClass SelectRemoveDropDown form-control":"selectClass undefined form-control"}
                        disabled={(((urlQuery && urlLink) ||urlLink )&&(multiProject.length==0))?true:false}
                         value={(selectedProject)?selectedProject:'All Projects'} 
                         onChange={(e)=>selectVal(e)}
                         >
                          {multiProject.length==0 && <option value='All Projects'>All Projects</option>}
                          {multiProject.length==0 && project && project.sort(SortArray).map(user =>( 
                          user!='common' && <option value={user}>{user}</option>
                          ))}
                          {multiProject.length!=0  && multiProject.map(o =>( 
                            <option value={o}>{o}</option>
                          ))}
                      </select>
                      </div>
                        }
                      {props.knowledgeSearch == '13' ? 
                        <input id="inputSearch"  value = {knowledgeSearch} type="search" className={[classes.topBorder1, 'form-control'].join(' ')}   placeholder="Search"
                          style={{borderTopColor: "Black",
                            borderRightColor: "Black",
                            borderBottomColor: "Black",
                            borderLeftColor: "Black",
                            height: "48px",
                            borderTopWidth: "1px",
                            borderRightWidth: "1px",
                            borderBottomWidth: "1px",
                            borderTopRightRadius: "30px",
                            borderBottomRightRadius: "30px",
                            borderTopLeftRadius: "30px",
                            borderBottomLeftRadius: "30px",
                            fontSize: "16px",
                            fontWeight:"400",
                            fontStyle:"normal",
                            fontFamily: 'Roboto-Regular',
                            color: "Black"}}
                          // onChange={(e) => KnowledgeSearch(knowledgeSearch)}
                        ></input> : 
                        <input id="inputSearch" type="search" className={[classes.topBorder1, 'form-control'].join(' ')}   placeholder="Search"
                          style={{borderTopColor: "Black",
                            borderRightColor: "Black",
                            borderBottomColor: "Black",
                            borderLeftColor: "none",
                            height: "48px",
                            borderTopWidth: "1px",
                            borderRightWidth: "1px",
                            borderBottomWidth: "1px",
                            borderTopRightRadius: "30px",
                            borderBottomRightRadius: "30px",
                            fontSize: "16px",
                            fontWeight:"400",
                            fontStyle:"normal",
                            fontFamily: 'Roboto-Regular',
                            color: "Black"}}
                            value={search}
                          onChange={e => addSearch(e.target.value)}  onKeyPress={(event) => { var key = event.keyCode || event.which;
                        if (key === 13) {
                          if(search.length!=0)
                          {
                              handleSearch(search)}
                          }}}
                        >
                        </input>
        }
                      <span style={{marginLeft:"-15px"}} onClick={() => showmorepara()}><MicIcon style={{ display: showmore == true ? "" : "none", cursor: "pointer", float: "right", marginBottom: "15px", fontSize: "11px", color: '#000000' ,fontFamily:"Roboto-Regular",width:"100px",height:"33px",marginTop:"4px"}} onClick={stoplisten} /> </span>
                      <span style={{marginLeft:"-15px"}} onClick={() => showmorepara()}>< MicOffIcon  style={{ display: showmore == false? "" : "none", cursor: "pointer", float: "right", marginBottom: "15px", fontSize: "11px", color: '#000000' ,fontFamily:"Roboto-Regular",width:"100px",height:"33px",marginTop:"4px"}} onClick={startlistening} /></span>
                      </div>
                    </Col>  
                  </Row>
                  {
                  
                  search && inValue &&(inValue[0].response != "Please rephrase your question")? listView && !cognitiveLoading && <div 
                    style={{marginTop:"10px",fontSize: "18px",color: "#3362CC",overflowY:"auto", maxHeight:"50px",marginRight:"30px",paddingLeft:"10px",fontFamily:"Roboto-Regular",lineHeight:"40px",
                    fontWeight:"900",marginBottom: "15px"}}>
                  <span style={{color:"#3362CC"}}>"{search}"</span><span style={{color:"Black"}}>&nbsp;found in {inValue.length} {(inValue.length > 1)? 'Documents' : 'Document' } </span></div>:''
                  }
                  
                  <div  className={classesName.borders} onScroll={(e)=>loadMoreHandle(e)}
                    style={{
                              width:"100%",
                              textAlign:"justify",
                              position:"relative",
                              maxHeight:"400px",
                              overflowY:"auto",
                              overflowX:"hidden",
                              paddingRight:(search && (inValue) && inValue[0].response == "Please rephrase your question")?"20px":"5px",
                              marginTop:"10px",
                              right:"10px",
                              paddingLeft:"20px"
                              
                      }}>
                    {search && (inValue) && (inValue[0].response == "Please rephrase your question")?
                       <Lists response={inValue[0].response}/>
                       :
                            search ? (inValue) && listView && !cognitiveLoading && inValue.slice(arraySize[0].startIndex, arraySize[0].endindex).map((user,index) =>(
                            <Card className={classes.root} variant="outlined" id={index}
                              style={{height:(showDrawer == true)?"auto":'auto', backgroundColor:"rgb(255, 255, 255)", border:"none",
                              marginBottom:"10px",borderWidth:(filepath == data + user.title + "." + user.fileType) && (showDrawer == true)?"3px":''}} >
                              <div style={{contentVisibility:"hidden"}}></div>
                              <CardContent style={{paddingBottom:"0px"}}>
                                <Typography variant="body2" component="div" style={{fontSize:"12px"}}>
                                  <Lists key={user.sh_ans} {...user}   sh_ansClick={(paraGraph)=>paraShow(paraGraph)} fileType={user.fileType}
                                    headerclick={(item,key,value,content,title)=>handleShow(item,key,value,content,title)} onSearch={search} 
                                    filename={user.title+"."+user.fileType} fileContent={user.shortPara} path={data + user.title + "." + user.fileType}
                                    showFile={showDrawer} score={user.score } title={user.title} encryptedContent={user.encryptedContent} drawerShow={drawerShow} 
                                    feedback ={feedback1} user={user} type = {user.fileType} sourceURL = {user.sourceURL} index={index} common={user.common} onChangeBorder = {onChangeBorder}
                                    />
                                </Typography>
                              </CardContent>
                            </Card>
                            )):null
                    }  
                  </div>
                  
                </div>
            </Col>
          </Row>
          
          <div>
        {['left'].map((anchor) => (
          <React.Fragment key={anchor}>
            <Drawer anchor={anchor} open={showDrawer} onClose={handleResponse} docked={true} containerClassName="drawer-side-drawer" className={classes.style}>
            {loading == true ? <SpinnerPage/> :null}
                <span style={{float:"left"}}><a onClick={closeDrawer}><CloseIcon style={{fontSize:"45px", position:"absolute", paddingRight:"10px", color:"#3362CC",
                  marginLeft:"10px", paddingTop:"3px"}}/></a>
                </span>
                <div style={{paddingTop:"5px",paddingBottom:"5px", marginLeft:"5px",fontSize:"20px",fontWeight:"900",position:"relative",left:"50px",
                  color:"#3362CC",fontFamily:"Roboto-Regular",lineHeight:"40px"}}><span style={{fontSize:"24px"}}>{filetitle}</span>
                    <div style={{display:"inline-block",backgroundColor:"#eef2f6",height:"45px",width:"25%",position:"relative",
                      borderRadius:"20px",float:"right",marginTop:"5px",marginRight:"60px",textAlign:(thumbs == "true")?"center":''}}>
                      {thumbs != "true" ? 
                          <div>
                              <text style={{marginLeft:"15px",fontSize:"13px",fontFamily:"Roboto-Regular", lineHeight:"15px",fontStyle:"normal",fontWeight:"400", color:"#143F5F"}}>Did this document help you?</text>
                              <div style={{height:"32px", width:"30px", backgroundColor:"inherit", color:"#a1a1a1",float:"right",marginRight:"15px",paddingLeft:"5px",paddingRight:"2px",
                                  display:"flex",borderRadius:"25px",marginTop:"6px"}}>
                                  <a onClick={() => feedback("false")}><ThumbDownIcon className={classes.iconStyle} strokeWidth="2px" fontSize="20px" style={{fontSize:"20px", marginBottom:"10px"}}/></a>
                              </div>
                              <div style={{height:"32px",  width:"30px", backgroundColor:"inherit", color:"#a1a1a1",float:"right",marginRight:"5px",paddingLeft:"5px",paddingRight:"2px",
                                    display:"flex",borderRadius:"25px",marginTop:"6px"}}>
                                    <a onClick={() => feedback("true")}><ThumbUpIcon className={classes.iconStyle} style={{fontSize:"20px", marginBottom:"10px"}}/></a>
                              </div>
                          </div>:<text style={{fontSize:"15px",fontFamily:"Roboto-Regular"}}>Thanks for feedback!!!</text>
                      }
                    </div>
                  
                </div>
                <iframe src={filepath} width="auto" height="99%" style={{marginLeft:"15px",marginRight:"15px",border:"none",backgroundColor:"#[COLOR=#FF0000]fff[/COLOR]"}}/>
            </Drawer>
          </React.Fragment>
        ))}
      </div>
        </div>
      </div>
    </>
  );          
}
export default PCognitive; 
