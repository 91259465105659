import * as actionTypes from './actionTypes';
import { PostCalltoServer,GetCalltoServer,botPostCalltoServer, deleteCalltoServer, patchCallToServer } from '../utility';
import {SetErrorMessage, SetSuccessMessage} from './message'
import axios from '../../instance';
import { GetAllAssistantsData } from './projectview';
import { decrypt } from '../../utility/utility';

const orgName = localStorage.getItem("orgName");
const username = localStorage.getItem("userName");
export const getUserByIdFailure=(error)=> { 
    return { type: actionTypes.GET_USER_BY_ID_FAILURE, error 
    }; 
};

export const getUserByIdSuccess=(user)=> { return { type: actionTypes.GET_USER_BY_ID_SUCCESS, user }}

export const getUserProjectsSuccess=(projects)=> { return { type: actionTypes.GET_USER_PROJECTS_SUCCESS, projects }}

export const getUserSkillFailure=(error)=> { 
    return { type: actionTypes.GET_USER_BY_ID_FAILURE, error 
    }; 
};
export const getUserSkillSuccess=(skill)=> { return { type: actionTypes.GET_USER_SKILL_SUCCESS, skillList:skill }}

export const deleteUserSkillFailure=(error)=> { 
    return { type: actionTypes.DELETE_USER_SKILL_FAILURE, error:error }; 
 };
export const deleteUserSkillSuccess=(data,skill)=> { 
    return { type: actionTypes.DELETE_USER_SKILL_SUCCESS, deleteSkill: skill };
};  
export const deleteUserSkillStart = () => {
    return { type: actionTypes.DELETE_USER_SKILL_START };
};
export const createUserSkillFailure=(error)=> { 
        return { type: actionTypes.CREATE_USER_SKILL_FAILURE, error 
    }; 
 };

export const createUserSkillStart=()=> { 
    return { type: actionTypes.CREATE_USER_SKILL_START };
};
 export const createUserSkillSuccess=(status,skill)=> { 
    return { type: actionTypes.CREATE_USER_SKILL_SUCCESS, newskill: skill
        };
};

export const getUsersFailure=(error)=> { 
    return { type: actionTypes.GET_USERS_FAILURE, error 
    }; 
};
export const getUsersSuccess=(users)=> { return { type: actionTypes.GET_USERS_SUCCESS, users }}

export const updateUserStart=()=> { 
    return { type: actionTypes.UPDATE_USER_START 
    }; 
};
export const updateUserFailure=(error)=> { 
    return { type: actionTypes.UPDATE_USER_FAILURE, error 
    }; 
};
export const updateUserSuccess=(updateUser)=> { return { type: actionTypes.UPDATE_USER_SUCCESS, updateUser }}

export const deleteUserFailure=(error)=> { 
    return { type: actionTypes.DELETE_USER_FAILURE, error 
    }; 
};
export const deleteUserSuccess=(deleteUser)=> { return { type: actionTypes.DELETE_USER_SUCCESS, deleteUser }}

export const getUserRoleFailure=(error)=> { 
    return { type: actionTypes.GET_USER_ROLE_FAILURE, error 
    }; 
};
export const getUserRoleSuccess=(userrole)=> { return { type: actionTypes.GET_USER_ROLE_SUCCESS, roleList:userrole }}

export const getUserPermissionSuccess=(permissions)=>{ return { type: actionTypes.GET_USER_PERMISSION_SUCCESS, permissionList:permissions }}

export const getUserPermissionFailure=(error)=> { 
    return { type: actionTypes.GET_USER_PERMISSION_FAILURE, error 
    }; 
};

export const getSettingsFailure=(error)=> { 
    return { type: actionTypes.GET_SETTINGS_FAILURE, error 
    }; 
};
export const getSettingsSuccess=(settings,orgList)=> { return { 
    type: actionTypes.GET_SETTINGS_SUCCESS, settings ,orglist:orgList}}

export const createProjectSuccess =(project)=>{
   
    return { type: actionTypes.CREATE_PROJECT_SUCCESS, project }
}

export const createAssistantSuccess =(assist)=>{
   
    return { type: actionTypes.CREATE_ASSISTANT_SUCCESS, assist }
}
 
export const createProjectFailure=(error)=> { 
    return { type: actionTypes.CREATE_PROJECT_FAILURE, error 
    }; 
};

export const createAssistantFailure=(error)=> { 
    return { type: actionTypes.CREATE_ASSISTANT_FAILURE, error 
    }; 
};

export const uploadProjectSuccess =(project)=>{
   
    return { type: actionTypes.UPLOAD_PROJECT_SUCCESS, project }
}
 
export const uploadProjectFailure=(error)=> { 
    return { type: actionTypes.UPLOAD_PROJECT_FAILURE, error 
    }; 
};

export const addRoleFailure=(error)=> { 
    return { type: actionTypes.CREATE_ROLE_FAILURE, error 
    }; 
};
export const addRoleStart=()=> { 
    return { type: actionTypes.CREATE_ROLE_START };
};
export const addRoleSuccess=(status,createRole)=> { return { type: actionTypes.CREATE_ROLE_SUCCESS, newrole:createRole }}

export const createOrganizationStart=()=> { 
return { type: actionTypes.CREATE_ORG_START };
};
export const createOrganizationSuccess=(org)=> { 
return { type: actionTypes.CREATE_ORG_SUCCESS, neworg: org
    };
};
export const createOrganizationFailure=(error)=> { 
    return { type: actionTypes.CREATE_ORG_FAILURE, error 
}; 
};

export const getUserOrgFailure=(error)=> { 
    return { type: actionTypes.GET_ORG_FAILURE, error 
    }; 
};
export const getUserOrgSuccess=(mapData)=> { 
    return { type: actionTypes.GET_ORG_SUCCESS,mappedData:mapData
     }}

export const createGroupStart=()=> { 
    return { type: actionTypes.CREATE_GROUP_START };
};
export const createGroupSuccess=(grp)=> { 
    return { type: actionTypes.CREATE_GROUP_SUCCESS, newgrp: grp
    };
};
export const createGroupFailure=(error)=> { 
    return { type: actionTypes.CREATE_GROUP_FAILURE, error 
}; 
};
export const getMessageSettingonStart=()=> { 
        return { type: actionTypes.GET_MESSAGE_SETTING_START
     }}

export const getGroupFailure=(error)=> { 
    return { type: actionTypes.GET_GROUP_FAILURE, error 
    }; 
};
export const getGroupSuccess=(grpData)=> { 
    return { type: actionTypes.GET_GROUP_SUCCESS,groupedData:grpData
     }}
export const getMessageSettingonSuccess=(data)=> { 
        return { type: actionTypes.GET_MESSAGE_SETTING_SUCCESS,messageSetting:data
     }}

export const getSynonmysStart=()=> { 
        return { type: actionTypes.GET_SYNONMYS_START
     }}

export const getSynonmysSuccess=(data)=> { 
        return { type: actionTypes.GET_SYNONMYS_SUCCESS,synonmyslist:data
     }}

     
export const getprojectbylangStart = () => {
    return {
        type: actionTypes.GET_PROJECT_BY_LANG_START
    };
};

export const getprojectbylangSuccess = (data) => {
    return {
        type: actionTypes.GET_PROJECT_BY_LANG_SUCCESS,
        projectlistbylang:data
    };
};

export const setDefaultLanguages = (data) => {
    return {
        type: actionTypes.SET_DEFAULT_LANGUAGE,
        defaultlanginfo:data
    };
};

export const langDeleteStart = () => {
    return {
        type: actionTypes.LANG_DELETE_START
    };
};

export const langDeleteSuccess = () => {
    return {
        type: actionTypes.LANG_DELETE_SUCCESS
    };
};


export const langDeleteFailure = () => {
    return {
        type: actionTypes.LANG_DELETE_FAILURE
    };
};

export const importLanguageStart = () => {
    return {
        type: actionTypes.IMPORT_LANG_START
    };
};

export const importLanguageSuccess = () => {
    return {
        type: actionTypes.IMPORT_LANG_SUCCESS
    };
};


export const importLanguageFailure = () => {
    return {
        type: actionTypes.IMPORT_LANG_FAILURE
    };
};

export const EditGrpStart=()=> { 
    return { type: actionTypes.EDIT_GROUP_START };
};
export const EditGrpSuccess=(grp)=> { 
    return { type: actionTypes.EDIT_GROUP_SUCCESS,grp
    };
};
export const EditGrpFailure=(error)=> { 
    return { type: actionTypes.EDIT_GROUP_FAILURE, error 
}; 
};

export const createUserStart=()=> { 
    return { type: actionTypes.CREATE_NEWUSER_START };
};
export const createUserSuccess=(user)=> { 
    return { type: actionTypes.CREATE_NEWUSER_SUCCESS,user
    };
};
export const createUserFailure=(error)=> { 
    return { type: actionTypes.CREATE_NEWUSER_FAILURE, error 
}; 
};


export const GetUserbyID = (apiURL,botBuilder_URL) => {
    return dispatch => {
        
        let user = localStorage.getItem("sessiontoken");
        user = JSON.parse(user);
        if(user !== null)
        {
        
        let userdetail = {};
        let url =''; 
          if(!botBuilder_URL)
               botBuilder_URL=apiURL     
          url=botBuilder_URL+`/project/${localStorage.getItem("language")}`;
            userdetail={"useremailid":user.useremailid};   
            GetCalltoServer(url,userdetail).then(response=>{
                if(response.data.status.responseType == "success"){
                        let bots= {}
                        bots["bots"] = response.data.data;
                       dispatch(getUserProjectsSuccess(bots));
                        
                    }
                else{
                    dispatch(getUserByIdFailure(response.status))
                } 
            }).catch(err => {
                dispatch(getUserByIdFailure(err))
            });     
    }
       
    };
};

export const GetUserSkills = (apiURL) => {
    return dispatch => {
        let url=apiURL+`/getSkillSetList`;
        PostCalltoServer(url,{})
        .then(response=>{
        if(response.data.status.responseType == "success"){
            let Skills=[];
            (response.data.data && response.data.data.hits.hits.map(skill => {
                let sk={};
                sk.skillsetname=skill._source.skillsetname;
                sk.description=skill._source.description;
                Skills.push(sk);
            }));
            dispatch(getUserSkillSuccess(Skills))
        }       
        else{            
            dispatch(getUserSkillFailure(response.data.status.responseType))
        }       
    }).catch(err => {
        dispatch(getUserSkillFailure(err.message));
    });
};
};

export const DeleteUserSkills = (skillsetname,apiURL) => {
    return dispatch => {
        dispatch(deleteUserSkillStart());
        let url=apiURL+`/deleteSkillSet`;
        let skill={"skillsetname":skillsetname};
        PostCalltoServer(url,skill)
        .then(response=>{
        if(response.data.status.responseType == "success"){
            dispatch(deleteUserSkillSuccess(response.data.data,skillsetname));
            dispatch(SetSuccessMessage('Successfully Deleted.'));
        }       
        else{
            dispatch(deleteUserSkillFailure('Failed to delete Skill.'))
            dispatch(SetErrorMessage('Failed to delete Skill.'));
            
        }       
    }).catch(err => {
        dispatch(deleteUserSkillFailure(err.message));
        dispatch(SetErrorMessage(err.message));
    });
};
};

export const CreateUserSkills = (skill,apiURL) => {
    return dispatch => {
        dispatch(createUserSkillStart())
        let url=apiURL+`/createSkillSet`;
        PostCalltoServer(url,skill)
        .then(response=>{
        if(response.data.status.responseType == "success"){
            dispatch(SetSuccessMessage('Skill created successfully.'));
            dispatch(createUserSkillSuccess(response.data,skill))
        }       
        else{
            dispatch(createUserSkillFailure('Failed to Create Skill.'));
            dispatch(SetErrorMessage('Failed to Create Skill.'));
        }       
    }).catch(err => {
        dispatch(createUserSkillFailure(err.message));
        dispatch(SetErrorMessage(err.message));
    });
};
};

export const GetUsers = (apiURL) => {
    return dispatch => {
        let url=apiURL+`/getUsers`;
        PostCalltoServer(url,{})
        .then(response=>{
        if(response.data.status.responseType == "success"){
            dispatch(getUsersSuccess(response.data))
        }       
        else{
            dispatch(SetErrorMessage("Fail to get Users"));
            dispatch(getUsersFailure(response.status))
        }       
    });
};
};

export const DeleteUser = (useremailid,apiURL) => {
    return dispatch => {
        let url=apiURL+`/deleteuserByid`;
        PostCalltoServer(url,{"useremailid":useremailid})
        .then(response=>{
        if(response.data.status.responseType == "success"){
            dispatch(deleteUserSuccess(response.data.status))
            dispatch(SetSuccessMessage("User Deleted Successfully!"));
        }       
        else{
            dispatch(deleteUserFailure(response.status))
            dispatch(SetErrorMessage("Failed to Delete!"));
        }       
    });
};
};

export const submitUser = (username,password,role,organization,apiURL) => {
    return dispatch => {
        if(username!=''&&password!='' && role!='' && organization!=''){
        let url=apiURL+`/addUser`;
        let useremailid=username+'@'+organization;
        dispatch(createUserStart())
        PostCalltoServer(url,{"username":username,'useremailid':useremailid,'password':password,'role':role,'org':organization,'bots':{
            'en' : [
              'common'
            ]
          },'skillset':['java'],'languages':['en']})
        .then(response=>{
        if(response.status== "200"){
            dispatch(createUserSuccess("Created User Successfully!"))
            dispatch(SetSuccessMessage("Created User Successfully!"));
            setTimeout(() => {
                dispatch(GetUsers(apiURL));
            }, 2000);            
        }       
        else{
            dispatch(createUserFailure("Failed to add user!"))
            dispatch(SetErrorMessage("Failed to add user!"));
        }       
    
    
        }
    );
        }else{
            dispatch(createUserFailure("Failed to add user!"))
            dispatch(SetErrorMessage("Username,Password,Role,Organization is Mandatory!"));
        }
};
};

//user management - added by Aiswarya AJ
export const GetUserPermissions = (apiURL,projectname) => {
    const orgname = localStorage.getItem("orgName");
    const user = localStorage.getItem("userName");
    const params = projectname?`username=${user}&orgname=${orgname}&project=${projectname}`:`username=${user}&orgname=${orgname}`;
    return dispatch => {
        let url=apiURL+`/organization/user/permission?${params}`;
        GetCalltoServer(url)
        .then(response => {
            if(response.status== "200"){
                if (response.data != null) {
                    dispatch(getUserPermissionSuccess(response.data))
                }
            } else {
                if(response.message.indexOf('403') > -1)
                    dispatch(getUserPermissionFailure('User is not authorised to use Intelliservice. Please contact your admin.'));
                else 
                    console.log('response other than 200 ',response);
            }
        })
    }
}

export const GetUserRoleList = (apiURL) => {
    return dispatch => {
        let url=apiURL+`/getRoleList`;
        PostCalltoServer(url,{})
        .then(response=>{
        if(response.data.status.responseType == "success"){
            let roles=[];
           (response.data && response.data.data && response.data.data.hits.hits.map(role => {
                let sk={};
                sk.rolename=role._source.rolename;
                sk.permissions=role._source.permissions;
                roles.push(sk);
            }));
            dispatch(getUserRoleSuccess(roles))
        }       
        else{
            dispatch(getUserRoleFailure(response.data.status))
            dispatch(SetErrorMessage("Failed to Fetch List of Roles!"));
        }       
    });
};
};


export const GetSettings = (apiURL) => {
    return dispatch => {
        let url = apiURL + `/readSettingProperties`;
        PostCalltoServer(url, {})
            .then(response => {
                if (response.data && response.data.status.responseType == 'success') {
                    let orgData = []
                    if (response.data.data.settingProperties && response.data.data.settingProperties.orgMapping) {
                        orgData = response.data.data.settingProperties.orgMapping.replace(/\\/g, "");
                        orgData = Object.keys(JSON.parse(orgData))
                        dispatch(getSettingsSuccess(response.data.data, orgData))
                    }
                    else if (response.data.data.settingProperties) {
                        dispatch(getSettingsSuccess(response.data.data, null))
                    }
                }
                else {

                    dispatch(getSettingsFailure(response.status))
                }
            });
    };
};
export const userStart = () => {
    return {
        type: actionTypes.USER_START
    };
};

export const createProjectStart = () => {
    return {
        type: actionTypes.CREATE_PROJECT_START
    };
};

export const createAssistantStart = () => {
    return {
        type: actionTypes.CREATE_ASSISTANT_START
    };
};

export const uploadProjectStart = () => {
    return {
        type: actionTypes.UPLOAD_PROJECT_START
    };
};

export const UpdateUser = (apiURL,user) => {
    return dispatch => {
        dispatch(updateUserStart())
        let url=apiURL+`/Updateuser`;
        PostCalltoServer(url,user)
        .then(response=>{
        if(response.data.status.responseType == 'success'){
            dispatch(updateUserSuccess(response.data.status))
            setTimeout(() => {
                dispatch(GetUsers(apiURL));
            }, 2000); 
            dispatch(SetSuccessMessage("Updated Successfully!"));
        }       
        else{
            dispatch(updateUserFailure(response.data.status));
            dispatch(SetErrorMessage("Failed to Update!"));
        }       
    });
};
};

export const CreateProject = (apiURL,commonApiURL,botURL,input) => {
    return dispatch => {
        dispatch(createProjectStart());
        let inputs={...input,"orgName": localStorage.getItem("orgName")}
       let url=botURL+`/project`;
       botPostCalltoServer(url,inputs)
        .then(response=>{
        if(response.data.status.responseType == 'success'){
            if(localStorage.getItem('language') == 'en'){
            setTimeout(()=>{
                dispatch(SetSuccessMessage('Project created successfully.'));
            },3500)
            dispatch(createProjectSuccess(response.data));
            //to get user permissions for newly selected project
            GetUserPermissions(commonApiURL,input.name);
              setTimeout(() => {
                dispatch(GetUserbyID(apiURL,botURL));
                dispatch(GetEnglishProjects(botURL));
               }, 1500); 
            }

            else{
                dispatch(createProjectSuccess(response.data));
                dispatch(SetSuccessMessage('Project created successfully in English. Change default language setting to English to view the newly created project and use Translate functionality to create the project in the preferred language.'));
                setTimeout(() => {
                    dispatch(GetUserbyID(apiURL,botURL));
                    dispatch(GetEnglishProjects(botURL));
                   }, 1500); 
            }
   
        }   
        else{
            dispatch(SetErrorMessage("Project creation failed."))
            dispatch(createProjectFailure("Project creation failed."))
            
        }       
    }).catch(err => {
        dispatch(SetErrorMessage("Project creation failed."))
        dispatch(createProjectFailure(err.message))
    });
};
};

export const CreateAssistant = (apiURL, input, msg) => {
    return dispatch => {
         dispatch(createAssistantStart());
        let inputs = { ...input, "orgName": localStorage.getItem("orgName") }
        let url = apiURL + `/createvirtualassistant`;
        botPostCalltoServer(url, inputs)
            .then(response => {
                dispatch(createAssistantSuccess(response.data));
                dispatch(GetAllAssistantsData(apiURL));
                dispatch(SetSuccessMessage(msg?msg:"Virtual Assistant updated successfully"));
                // dispatch(updateVAFromWhatsappConfig(apiURL, input)) //to be uncommented when watsapp apis enabled
                if (response.data.status.responseType == 'success') {
                        dispatch(createAssistantSuccess(response.data));
                       dispatch(GetAllAssistantsData());
                }
                else {
                    //dispatch(SetErrorMessage("Virtual Assistant creation failed."))
                    // dispatch(createAssistantFailure("Virtual Assistant creation failed."))

                }
            }).catch(err => {
                dispatch(createAssistantFailure("Virtual Assistant creation failed."))
                dispatch(SetErrorMessage("Virtual Assistant creation failed."))
            });
    };
};

export const deleteAssistant = (apiURL,baseURL) => {
    return dispatch => {
         dispatch(createAssistantStart());
        let url = apiURL + `/deleteallvirtualassistant/`+localStorage.getItem('vaName');
        deleteCalltoServer(url)
            .then(() => {
                dispatch(GetAllAssistantsData(apiURL));
                dispatch(SetSuccessMessage("Virtual Assistant deleted successfully."));
                //to be uncommented when watsapp apis enabled
                // dispatch(deleteVAFromWhatsappConfig(baseURL, localStorage.getItem('vaName')));
            }).catch(err => {
                dispatch(createAssistantFailure("Virtual Assistant deleted failed."))
                dispatch(SetErrorMessage("Virtual Assistant deleted failed."))
            });
    };
};

export const Projectfileupload = (apiURL,input,dataUrl,projectName,filename) => {
    
    let languageObject ={"fr":"French","es":"Spanish","ru":"Russian","de":"German","zh": "Chinese","it":"italian","ja":"japanese","sr": "serbian","el":"greek","nl":"dutch","pl":"polish","hu":"Hungarian","tr":"Turkish"};
    let lang;
    let language=localStorage.getItem('language');
    for (const [key, value] of Object.entries(languageObject)) {
          if(language == key){
                lang = value;
          }
        
        }
    return dispatch => {
         dispatch(uploadProjectStart());
       
        let token = localStorage.getItem("accessToken")
        fetch(apiURL+`/project/import/${language}`, {
            method: 'POST', 
            headers: { 'Authorization':`Bearer ${token}`,"orgName":orgName,"userName":username},
            body: input,
          })
        .then((response) => {
            return response.text();
        }).then(datas => {
           
            let result=JSON.parse(datas);
          
            if(result.status.responseType == "success"){
                if(localStorage.getItem('language')=='en'){
                    localStorage.setItem('projectname',projectName)
                dispatch(uploadProjectSuccess('Project uploaded'));
                dispatch(SetSuccessMessage('Project imported successfully.'))
                setTimeout(() => {
                    dispatch(GetUserbyID(apiURL,dataUrl));
                    dispatch(GetEnglishProjects(dataUrl));
                   }, 0); 
                 }
                else{
                    dispatch(uploadProjectSuccess('Project uploaded'));
                    dispatch(SetSuccessMessage('Project imported successfully in '+lang+'. Change default language setting to English to view the newly created project and use Translate functionality to create the project in the preferred language.'));
                    setTimeout(() => {
                        dispatch(GetUserbyID(apiURL,dataUrl));
                        dispatch(GetEnglishProjects(dataUrl));
                       }, 2000); 
                }
            }
            else{
       
                dispatch(SetErrorMessage('Project import failed due to invalid zip file.'));
                 dispatch(uploadProjectFailure('Upload failed.'));
            }
           
           
          
        })
        .catch((error) => {
          // Handle the error
          dispatch(uploadProjectFailure(error));
        if(error.message.includes("Failed to fetch")){
            dispatch(SetErrorMessage('Unable to Fetch some of the chosen file(s)'));
        }
        else{
            dispatch(uploadProjectFailure(error));
        }
        });       
    };
};

export const AddRole = (role,apiURL) => {
    return dispatch => {
        dispatch(addRoleStart())
        let url=apiURL+`/CreateRole`;
        PostCalltoServer(url,role)
        .then(response=>{
        if( response.data.status.responseType=="success"){
            dispatch(SetSuccessMessage('Role created successfully.'));
            dispatch(addRoleSuccess(response.data, role))
        }       
        else{
            let errs = []
            if(response.data.errors.length>0){
                (response.data.errors.forEach(err => {
                    if(err.source){
                        errs.push(err.source)
                    }
                }));
            }
            errs = errs.join(",")
            if(errs) dispatch(SetErrorMessage(errs + ' in Roles'));
            else dispatch(SetErrorMessage("Fail to add Roles"));
            dispatch(addRoleFailure(response.status))
        }      
       }).catch(err => {
        dispatch(addRoleFailure(err.message));
        dispatch(SetErrorMessage(err.message));
    });
    };
};

export const CreateOrganization= (org,apiURL) => {
    return dispatch => {
        dispatch(createOrganizationStart())
        let url=apiURL+`/createOrg`;
        PostCalltoServer(url,org)
        .then(response=>{
        if(response.data.status.responseType == "success"){
            dispatch(SetSuccessMessage('Organization created successfully.'));
            dispatch(createOrganizationSuccess(response.data));
            setTimeout(() => {
                dispatch(GetSettings(apiURL));
            },2000);
            
        }       
        else{
            dispatch(createOrganizationFailure('Failed to Create Organization.'));
            dispatch(SetErrorMessage('Failed to Create Organization.'));
        }       
    }).catch(err => {
        dispatch(createOrganizationFailure(err.message));
        dispatch(SetErrorMessage(err.message));
    });
};
};


export const GetmappedData= (apiURL) => {
    return dispatch => {
      let url=apiURL+`/project/${localStorage.getItem("language")}`;
       GetCalltoServer(url)
        .then(response=>{
        if(response.data.status.responseType == "success"){
            dispatch(getUserOrgSuccess(response.data.data))
        }       
        else{            
            dispatch(getUserOrgFailure(response.data.status.responseType))
        }       
    }).catch(err => {
        dispatch(getUserOrgFailure(err.message));
        dispatch(SetErrorMessage(err.message));
    });
};
};

export const GetMessagesettings= (apiURL,data) => {
    return dispatch => {
        let url=apiURL+`/project/messageFile/${data.language}`;
        dispatch(getMessageSettingonStart())
        GetCalltoServer(url)
        .then(response=>{
       if(response.data.status.responseType == "success"){
            dispatch(getMessageSettingonSuccess(response.data.data))
        }       
        else{            
            dispatch(SetErrorMessage("Error occured in getting message"));
        }     
    }).catch(err => {
        dispatch(SetErrorMessage(err.message));
    });
};
};

export const CreateGroup= (grp,apiURL) => {
    return dispatch => {
        dispatch(createGroupStart())
        let url=apiURL+`/createGroup`;
        PostCalltoServer(url,grp)
        .then(response=>{
        if(response.data.status.responseType == "success"){
            dispatch(createGroupSuccess(response.data.status.responseType))
            dispatch(SetSuccessMessage('Group created successfully.'));
            setTimeout(() => {
                dispatch(GetAllGroup(apiURL));
            },1000);
           
        }       
        else{
            dispatch(createGroupFailure('Failure.'));
            dispatch(SetErrorMessage('Failed to Create Group.'));
        }       
    }).catch(err => {
        dispatch(createGroupFailure(err.message));
        dispatch(SetErrorMessage(err.message));
    });
};
};
export const GetSynonmys= (apiURL,data) => {
    return dispatch => {
        let url=apiURL+`/pluginreadFile`;
        dispatch(getSynonmysStart())
        PostCalltoServer(url,data)
        .then(response=>{
        if(response.data.status.responseType == "success"){
          
            dispatch(getSynonmysSuccess(response.data.data))
        }     
        else if(response.data.status.responseType == "Failure")  
        {
            if(response.data.errors[0].source.includes("index is not exist") != -1)
                dispatch(SetErrorMessage("No Data available!!"));
        }
        else{            
            dispatch(SetErrorMessage("Error occured in getting synonmys"));
        }       
    }).catch(err => {
        dispatch(SetErrorMessage(err.message));
    });
};
};

export const GetAllGroup= (apiURL) => {
    return dispatch => {
        let url=apiURL+`/getAllGroup`;
        PostCalltoServer(url,{})
        .then(response=>{
        if(response.data.status.responseType == "success"){
            dispatch(getGroupSuccess(response.data.data))
        }       
        else{            
            dispatch(getGroupFailure(response.data.status.responseType))
        }       
    }).catch(err => {
        dispatch(getGroupFailure(err.message));
        dispatch(SetErrorMessage(err.message));
    });
};
};

export const EditGroup= (group,apiURL) => {
    return dispatch => {
        dispatch(EditGrpStart())
        let url=apiURL+`/UpdateGroup`;
        PostCalltoServer(url,group)
        .then(response=>{
        if(response.data.status.responseType == "success"){
            dispatch(SetSuccessMessage('Updated successfully.'));
            setTimeout(() => {
                dispatch(GetAllGroup(apiURL));
            },2000);
            dispatch(EditGrpSuccess(response.data))
        }       
        else{
            dispatch(EditGrpFailure('Failed to Update Group.'));
            dispatch(SetErrorMessage('Failed to Update Group.'));
        }       
    }).catch(err => {
        dispatch(EditGrpFailure(err.message));
        dispatch(SetErrorMessage(err.message));
    });
};
};

export const LanguageExport= (apiURL,lang) => {
    return dispatch => {
        let url=apiURL+`/exportLanguage`;
        let token = localStorage.getItem("accessToken");
        axios({
            url: url,
            headers: { 'content-type': 'application/json', 'Authorization': `Bearer ${token}` ,"orgName":orgName,"userName":username},
            method: 'POST',
            data: {"language":lang},
            responseType: 'blob', // important
        }).then(response => {
            if(response.data){
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `itsautobot${lang}.zip`);
            document.body.appendChild(link);
            link.click();
            dispatch(SetSuccessMessage("Language Exported Succesfully!"))
            }
            }).catch(err => {
                dispatch(SetErrorMessage(err.message));
            });
    };
};


export const deleteFile =(apiURL,filename)=>{
    let data ={"fileName":filename}
    let url=apiURL+`/deletefile`;
    PostCalltoServer(url,data)
        .then(()=>{  
    }).catch(() => {
    });

}

export const GetProjectByLanguage= (apiURL,data) => {
    return dispatch => {
        let url=apiURL+`/projectListByLang`;
        dispatch(getprojectbylangStart())
        PostCalltoServer(url,data)
        .then(response=>{
        if(response.data.status.responseType == "success"){
          
            dispatch(getprojectbylangSuccess(response.data.data))
        }       
        else{            
            dispatch(SetErrorMessage("Error occured in getting project"));
        }       
    }).catch((err) => {
        console.log('error',err)
    });

};
};

export const UpdateMessageFile= (apiURL,data) => {
    return dispatch => {
        let url=apiURL+`/project/messageFile`;
        botPostCalltoServer(url,data)
        .then(response=>{
        if(response.data.status.responseType == "success"){
            dispatch(SetSuccessMessage('Message updated successfully.'));
        }       
        else{            
            dispatch(SetErrorMessage("Error occured in message update"));
        }       
    }).catch(err => {
        dispatch(SetErrorMessage(err.message));
    });
};
};

export const SetDefaultLanguage = (apiURL,langname,langkey) => {
    return dispatch => {
       
         setTimeout(() => {
            dispatch(setDefaultLanguages({langname:langname,langkey:langkey}))
              }, 5000);
            dispatch(SetSuccessMessage(`${langname} set as default.`));

      
  };
};

export const UpdateSynonymsOrStopWord= (apiURL,data) => {
    return dispatch => {
        let url=apiURL+`/plugincreateOrUpdateFile`;
        PostCalltoServer(url,data)
        .then(response=>{
        if(response.data.status.responseType == "success"){
            if(data.type == "stopword")
            {
            dispatch(SetSuccessMessage('Stopword updated successfully.'));
            }
            else if(data.type == "synonym")
            {
            dispatch(SetSuccessMessage('Synonyms updated successfully.'));
            }
        }       
        else{            
            dispatch(SetErrorMessage("Error occured in message update"));
        }       
    }).catch(err => {
        dispatch(SetErrorMessage(err.message));
    });
};
};


export const LanguageDelete= (apiURL,data) => {
    return dispatch => {
        dispatch(langDeleteStart());
        let url=apiURL+`/deletelanguage`;
        botPostCalltoServer(url, data)
            .then(response => {
                if (response.status === 200) {
                    setTimeout(()=>{
                        dispatch(SetSuccessMessage('Language deleted successfully.'));
                    },1000)
                    setTimeout(() => {
                        dispatch(langDeleteSuccess());
                    }, 500);
                }
                else {
                    dispatch(langDeleteFailure());
                    dispatch(SetErrorMessage("Error occured in language delete"));
                }
            }).catch(err => {
                dispatch(langDeleteFailure());
                dispatch(SetErrorMessage(err.message));
            });
    };
};

export const LanguageImport= (apiURL,data,langarray) => {
    let langFileName=langarray.filename;
    let checkLanguage=false;
    if(langFileName){
    langFileName=langFileName.split('.zip');
    if(langFileName[0].includes('itsautobot')){
    langFileName=langFileName[0].split('itsautobot')
    langFileName=langFileName[1]
    if(langFileName.includes('(')){
        langFileName=langFileName.split('(');
        langFileName=langFileName[0].trim()
    }
    }
    else{
      
            return dispatch => {
                dispatch(SetErrorMessage("Please enter valid file"));
                }
       
    }
    }
    if(langarray.language){
        let languageList=langarray.language
        languageList && languageList !== undefined && languageList.length > 0 && 
        languageList.map((lang) =>{
            if(lang.langkey ==langFileName)
                checkLanguage=true
            })
    }
    if(checkLanguage){
        return dispatch => {
        dispatch(SetErrorMessage("Language already exists"));
        }
    }
    else{
    return dispatch => {
     dispatch(importLanguageStart());
     let token = localStorage.getItem("accessToken")
  
       const requestOptions = {
           method: 'POST',
           headers: { 'Authorization':`Bearer ${token}`,"orgName":orgName,"userName":username},
           body: data,
           
       };

       fetch(apiURL+`/importLanguage`, requestOptions)
       .then((response) => {
        return response.text();
    }).then(datas => {
        let result=JSON.parse(datas);
          
        if(result.status.responseType == 'Success'){
            setTimeout(()=>{
                dispatch(SetSuccessMessage('Language uploaded successfully.'));
            },1000)
           setTimeout(() => {
            dispatch(importLanguageSuccess());
             }, 500);
         } else {
          dispatch(SetErrorMessage("Error occured in language upload"));
          dispatch(importLanguageFailure());
       
         }
       })
       .then(() => {
         // do whatever you want with the JSON response
       }).catch((error) => {
         // Handle the error
         dispatch(SetErrorMessage(error.message));
         dispatch(importLanguageFailure());
    
       });       
   };
}
};


export const getPropertiesSuccess=(property)=> { 
    return { type: actionTypes.GET_PROPERTY_SUCCESS,property
    };
};
export const getPropertiesFailure=(error)=> { 
    return { type: actionTypes.GET_PROPERTY_FAILURE, error 
}; 
};

export const getProperty = (apiURL) => {
    return dispatch => {
        let url = apiURL + `/project/uniqueProperties`;
        GetCalltoServer(url, {})
            .then(response => {
                if (response.data && response.data.status.responseType == 'success') {
                        dispatch(getPropertiesSuccess(response.data.data))
                }
                else {
                    dispatch(getPropertiesFailure(response.data.status))
                }
            }).catch(() => {
                dispatch(getPropertiesFailure());
            })
    };
};

export const updatePropertyStart=()=> { 
    return { type: actionTypes.UPDATE_PROPERTY_START 
    }; 
};
export const updatePropertyFailure=(error)=> { 
    return { type: actionTypes.UPDATE_PROPERTY_FAILURE, error 
    }; 
};
export const updatePropertySuccess=(updateUser)=> {
     return { type: actionTypes.UPDATE_PROPERTY_SUCCESS, updateUser 
    }}

export const updateProperty = (apiURL,data) => {
    return dispatch => {
        dispatch(updatePropertyStart())
        let url=apiURL+`/updatePropertiesOfSetting`;
        PostCalltoServer(url,data)
        .then(response=>{
        if(response.data.status.responseType == 'success'){
            dispatch(updatePropertySuccess(response.data.status))
            setTimeout(() => {
                dispatch(getProperty(apiURL));
            }, 2000); 
            dispatch(SetSuccessMessage("Updated Successfully!"));
        }       
        else{
            dispatch(updatePropertyFailure(response.data.status));
            dispatch(SetErrorMessage("Failed to Update!"));
        }       
    });
};
};

export const getBuildPropsFailure=(error)=> { 
    return { type: actionTypes.GET_BUILD_FAILURE, error 
    }; 
};
export const getBuildPropsSuccess=(buildProps)=> { return { 
    type: actionTypes.GET_BUILD_SUCCESS, buildProps }}

export const GetBuildProps = (apiURL) => {
    return dispatch => {
        let url = apiURL + `/project/readBuildProperties`;
        GetCalltoServer(url)
            .then(response => {
                if (response.data && response.data.status.responseType == 'success') {
                    let responseData = response.data.data ?JSON.parse(response.data.data):{}
                        dispatch(getBuildPropsSuccess(responseData))
                    
                }
                else {

                    dispatch(getBuildPropsFailure(response.status))
                }
            });
    };
};

export const getLangaugeFailure=(error)=> { 
    return { type: actionTypes.GET_LANGUAGE_FAILURE, error 
    }; 
};
export const getLangaugeSuccess=(languageList)=> { return { 
    type: actionTypes.GET_LANGUAGE_SUCCESS, languageList }}

export const GetLanguage = (apiURL) => {
    return dispatch => {
        let url=apiURL+`/languageList`;
        GetCalltoServer(url)
        .then(response=>{
        if(response.data && response.data.status.responseType == "success"){
            dispatch(getLangaugeSuccess(response.data.data))
        }       
        else{
            dispatch(SetErrorMessage("Failed to get Languages"));
            dispatch(getLangaugeFailure(response.status))
        }       
    });
};
};

export const addLanguageStart=()=> { 
    return { type: actionTypes.ADD_LANGUAGE_START 
    }; 
};

export const addLanguageSuccess=()=> { 
    return { type: actionTypes.ADD_LANGUAGE_SUCCESS
    }; 
};

export const addLanguageFailure=()=> { 
    return { type: actionTypes.ADD_LANGUAGE_FAILURE 
    }; 
};

export const addLanguage = (apiURL,data,oldURL) => {
    return dispatch => {
        let url=apiURL+`/project/language`;
         dispatch(addLanguageStart());
        PostCalltoServer(url,data)
        .then(response=>{
        if(response.data && response.data.status.responseType == 'success'){
            setTimeout(() => {
                dispatch(GetLanguage(oldURL));
            }, 2000); 
            dispatch(SetSuccessMessage("New Language added successfully."));
            dispatch(addLanguageSuccess());
        }   
        else{
            dispatch(SetErrorMessage('New Language cannot be added.'));
            dispatch(addLanguageFailure());
            }       
    });
}
};

export const getEnglishProjectsFailure=(error)=> { 
    return { type: actionTypes.GET_ENGLISH_PROJECTS_FAILURE, error 
    }; 
};
export const getEnglishProjectsSuccess=(englishProject)=> { return { type: actionTypes.GET_ENGLISH_PROJECTS_SUCCESS, englishProject }}

export const GetEnglishProjects = (botBuilder_URL) => {
    return dispatch => {
  
        let url =''; 
        
          url=botBuilder_URL+`/project/en`;   
            GetCalltoServer(url).then(response=>{
                if(response.data.status.responseType == "success"){
                       dispatch(getEnglishProjectsSuccess(response.data.data));
                        
                    }
                else{
                    dispatch(getEnglishProjectsFailure(response.status))
                } 
            }).catch(err => {
                dispatch(getEnglishProjectsFailure(err))
            });     
       
    };
};

export const getProjectLangFailure=(error)=> { 
    return { type: actionTypes.GET_PROJECT_LANGS_FAILURE, error 
    }; 
};
export const getProjectLangSuccess=(projectLang)=> { return { type: actionTypes.GET_PROJECT_LANGS_SUCCESS, projectLang }}

export const GetProjectLang = (botBuilder_URL,project) => {
    return dispatch => {
        
        let user = localStorage.getItem("sessiontoken");
        user = JSON.parse(user);
        if(user !== null)
        {   
        let userdetail = {};
        let url =''; 
        
          url=botBuilder_URL+`/project/availableLanguages/${project}`;
            GetCalltoServer(url,userdetail).then(response=>{
                if(response.data.status.responseType == "success"){
                       dispatch(getProjectLangSuccess(response.data.data));
                        
                    }
                else{
                    dispatch(getProjectLangFailure(response.status))
                } 
            }).catch(err => {
                dispatch(getProjectLangFailure(err))
            });     
    }
       
    };
};

export const deleteVAFromWhatsappConfig = (baseURL, vaName) => {
    let url = baseURL + '/whatsapp-webhook/'+orgName+'/virtual-agents/'+vaName;
    return dispatch => {
        deleteCalltoServer(url)
        .then(() => {
            console.info('Virtual Assistant successfully removed from Whatsapp Configuration');
        }).catch(err => {
            console.info(`${err} \n Virtual Assistant failed to be removed from Whatsapp Configuration, 
            either because the virtual assistant is not mapped to any configuration or it failed to be removed from database.`);
        });
    }
    
}

const updateVAFromWhatsappConfig = (apiURL, data) => {

    let url = apiURL.substring(0,apiURL.lastIndexOf('/')) + '/whatsapp-webhook/'+orgName+'/virtual-agents/'+data.virtualassistantName;
    let requestData = { project_list: data.project.join(',') };
    return dispatch => {
        patchCallToServer(url,requestData)
        .then((response)=>{
            if(response.status === 204)
                console.info(`Virtual Assitant's projects successfully updated in Whatsapp Configuration`);
            else
                console.info(`Virtual Assistant is not mapped to any Whatsapp Records, hence update is not required.`)
        }).catch(err => {
            console.info(`${err} \n Virtual Assistant's project details failed to be updated in Whatsapp Configuration`)
        })
    }
   
}