import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const tooltip = (props) => (
    <OverlayTrigger
     placement={props.direction}
     delay={{ show: 250, hide: 400 }}
     overlay={<Tooltip id="button-tooltip" >
     {props.tooltext}
     
   </Tooltip>}
     >
         {props.children}
    </OverlayTrigger>
);

export default tooltip;