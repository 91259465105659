import React, { useState } from 'react';
import { Row, Col, Form } from "react-bootstrap";
import './Drawer.css';
import ButtonCustom from "../../../../components/UIComponents/Button/ButtonComponent";
import InputWithLabelComponent from "../../../SettingPage/SettingInnerPages/components/InputWithLabelComponent/InputWithLabelComponent";
import SelectCustom from "../../../../components/UIComponents/SelectDropdown/SelectComponent";

const TextPromptDetails = ({config, data, handleExternalSubmit, variable, invalidVarMsg}) => {

    const [label, setLabel] = useState(data.label!==undefined?data.label:'');
    const defaultPromptData = {isPassword:false,validationType:'all',regex:'.*'};
    const [promptData, setPromptData] = useState(data?.textPromptData?data.textPromptData:defaultPromptData);
    const [addToLog, setAddToLog] = useState(data.addToLog!==undefined? data.addToLog:true);

    const validationTypesList = [{value:'all',displayValue:'All'},
                            {value:'alpha',displayValue:'Alphabets'},
                            {value:'num',displayValue:'Numeric'},
                            {value:'alpha_num',displayValue:'Alpha Numeric'},
                            {value:'email',displayValue:'Email'},{value:'custom',displayValue:'Custom'}];

    function onTypeChange (type) {
        onChangeHandler('validationType',type);
        onChangeHandler('regex',getRegex(type));
    }

    function getRegex (type) {
        switch(type) {
            case 'alpha' : return '^[a-zA-Z ]+$'
            case 'num' : return '^[0-9]*$'
            case 'alpha_num' : return '^[a-zA-Z0-9 ]+$'
            case 'all' : return '.*'
            case 'email' : return '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+[.][A-Za-z0-9-]{2,4}$'
            case 'custom' : return ''
        }
    }

    function onChangeHandler (type, value) {
        setPromptData((item) => ({
            ...item,
            [type]: value,
          }));
    }

    const isFormValid = () => {
        return variable && !invalidVarMsg && label;
    }

    const saveData = () => {
        handleExternalSubmit('textPrompt', {label, promptData, addToLog});
    }

    return (
        <div>
            <div className="mt-1">
                <Col md={12}>
                    <InputWithLabelComponent
                        type="text"
                        name="message"
                        label = "Text Prompt Message*"
                        value={label}
                        readOnly={false}
                        onChange={(e) => setLabel(e.target.value)}
                        required={true}
                  />
                </Col>
            </div>
            <div className="mt-1">
                <Col md={12}>
                    <Row className='settings-form-switch'>
                        <Col md={6}><span className='text-box-title'>Password</span></Col>
                        <Col md={6} className='settings-switch'>
                            <Form.Check
                                type="switch"
                                id="isPassword"
                                checked={promptData.isPassword}
                                onChange={e => onChangeHandler('isPassword',e.target.checked)}
                                disabled={true}
                            />
                        </Col>
                    </Row>
                </Col>
            </div>
            <div className="mt-1">
                <Col md={12}>
                    <SelectCustom
                        name="validation"
                        label="Select Validation Type"
                        changed={e=>onTypeChange(e.target.value)}
                        required={true}
                        value={promptData.validationType}
                        options={validationTypesList}
                        disabled={true}
                    />
                </Col>
            </div>
            <div className="mt-1">
                <Col md={12}>
                <InputWithLabelComponent
                        type="text"
                        name={'settings-regex'}
                        label="Validation Expression"
                        showLabel={true}
                        value={promptData.regex}
                        readOnly={false}
                        onChange={e => onChangeHandler('regex',e.target.value)}
                        required={true}
                        placeHolder={'Enter Regular Expression'}
                        isInvalid={invalidVarMsg!==null}
                        error={invalidVarMsg}
                        disabled={true}
                  />
                </Col>
            </div>
            <div className="mt-1"  id="checkInCdNode">
                <Col md={12}>
                <Form>
                    <Form.Check 
                    type="checkbox"
                    id={`addToLog`+variable}
                    label='Capture as conversation log'
                    checked = {addToLog}
                    onChange={e=>setAddToLog(e.target.checked)}
                    />
                </Form>
                </Col>
            </div>
            <br/>
            <div className="mt-1">
            <Col md={12}>
                <div className="mt-1">
                    <ButtonCustom variant='contained' label='Save'className='buttonWidth' disabled={!isFormValid()} clicked={saveData}/>
                </div>
            </Col>
            </div>
        </div>
    )

}

export default TextPromptDetails