import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import { Tab, Nav, Col, Row } from 'react-bootstrap';
import '.././triage-configuration-tabs.css';
import './symptom-root-cause-tabs.css';
import RootCauseData from './RootCauseData';
import Heading3 from '../../../../components/Typography/Heading3/Heading3';
import SearchComponent from '../../../../components/SearchComponent/SearchComponent';
import SearchSymptomAndRootcause from '../../SymtomsAndRootCauseTab/helpers/SearchSymptomAndRootcause';
import { isEqual } from 'lodash';


const RootCauseSymptomTabs = ({
    config,sendDataToSymRootCause,lastSelectedRootCause,formData, checked,permissions
}) => {
    const symptomRootCauseData = useSelector(state => state.adminSettings.symptomRootCauseData);
    const [symptomRootCause, setSymptomRootCause] = useState(symptomRootCauseData);
    let seletedTabCheck = lastSelectedRootCause ? lastSelectedRootCause : get(symptomRootCauseData, `0.rootcause`, '')
    const [selectedTab, setSelectedTab] = useState(seletedTabCheck);
    const [slectedTablength, setSlectedTablength] = useState(symptomRootCauseData.find(item => item.rootcause === selectedTab));
    const onTabChange = (selected) => {
        sendDataToSymRootCause(selected);
        setSelectedTab(selected)
        setSlectedTablength(symptomRootCauseData.find(item => item.rootcause === selected))
    };
    const [searchTerm, setSearchTerm] = useState("");

    const searchHandler = (e) => {
        if(!isEqual(e.target.value.length ,0)){
        setSearchTerm(e.target.value);
        console.log(searchTerm,"searchterm");
        if (symptomRootCause.length > 0) {
          const filter = SearchSymptomAndRootcause(
            symptomRootCause,
            "rootcause",
            e,
            setSymptomRootCause
          );
          setSymptomRootCause(filter);
        }
    }else{
        setSearchTerm(e.target.value);
        setSymptomRootCause(symptomRootCauseData);
    }
    }

    useEffect(() => {        
        let seletedTabCheck1 = lastSelectedRootCause ? lastSelectedRootCause : get(symptomRootCauseData, `0.rootcause`, '')
        sendDataToSymRootCause(seletedTabCheck1);
        setSelectedTab(seletedTabCheck1);
    }, [symptomRootCauseData]);

    return (
        <Tab.Container
            id="root-cause-symptom-tabs"
           // defaultActiveKey="first"
            activeKey={selectedTab}
            onSelect={onTabChange}
        >
            <Row className='w-100 no-gutters'>
            
                <Col sm={3} className='symptom-root-cause-mapping-tabs'>
                    <div className='mb-4'>
                    <SearchComponent
                                name={'recomended-solution'}
                                value={searchTerm}
                                onChange={searchHandler}
                                placeHolder={'Search'}
                            />
                    </div>
                    <h4 className="root-cause-side-box-heading">{`ROOT CAUSES (${symptomRootCause.length})`}
                    {/* <img src="./images/Search-img.svg"/> */}
                    </h4>
                    <div className='left-container left-side-container'>
                    <Nav variant="pills" className="flex-column" >
                        {symptomRootCause.length ? symptomRootCause.map((item, index) => {
                            return (
                                <Nav.Item key={item.rootcause}>
                                    <Nav.Link eventKey={item.rootcause}>
                                        <p className="root-cause-side-box root-cause-side-box-border">
                                            {item.rootcause}
                                            <img className="root-cause-side-box-img" src="./images/Play.svg" />
                                        </p>
                                    </Nav.Link>
                                </Nav.Item>
                            )
                        }) : null }
                    </Nav>
                    </div>
                </Col>
                <Col sm={9}>
                    <div className="root-cause-content">
                        {/* <Row className="pl-4">
                           <h6 className="pageTitleText-mapping">
                               <span className="active-rootcasuse">{`" ${selectedTab} "`}</span>
                               <span className="active-rootcause-count">{`${(slectedTablength.rootcausedata).length} Symptoms Found!`}</span>
                            </h6>
                        </Row> */}
                        <Row className="root-cause-table-heading no-gutters ml-3">
                            <Col className="col-md-4 root-cause-table-heading-symptom text-break"><h4>{`SYMPTOMS (${(slectedTablength.rootcausedata).length})`}</h4></Col>
                            {/* <Col className="col-md-4 root-cause-table-heading-symptom text-break"><h4>{`SYMPTOMS`}</h4></Col> */}
                            <Col className="col-md-3 text-break"><h4>LEADING QUESTION</h4></Col>
                            <Col className="col-md-2 text-break"><h4>RELEVANCY(%)</h4></Col>
                            <Col className="col-md-2 text-break"><h4>STATUS</h4></Col>
                            {/* <Col className="col-md-1 text-break pl-2"><img src="./images/Download-path.svg" /></Col> */}
                        </Row>
                        <Tab.Content className='right-side-container'>
                            {symptomRootCauseData.length ? symptomRootCauseData.map(item => {
                                return (
                                    <Tab.Pane eventKey={item.rootcause}>
                                       <RootCauseData selectedItem={item} config={config} formData={formData} checked={checked} permissions={permissions}/>
                                    </Tab.Pane>
                                )
                            }) : null
                            }
                        </Tab.Content>
                    </div>
                </Col>
            </Row>
        </Tab.Container>
    );
};

export default RootCauseSymptomTabs;