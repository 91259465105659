import isEqual from "lodash.isequal";

const changeRecomendedSolutionsDataReducer = (state, payload) => {
   if(isEqual(payload,[])){
    return {
        ...state,
        appliedCheckboxes : []
    }
   }else{
    return {
        ...state,
        appliedCheckboxes : state.appliedCheckboxes.includes(payload) ? [...state.appliedCheckboxes.filter((item) => item !== payload)] :[...state.appliedCheckboxes ,payload]
    };
   }
   
};

export default changeRecomendedSolutionsDataReducer;