import React from 'react';
import Select, { components } from "react-select";
import clsx from 'clsx';
import * as PropTypes from 'prop-types';
import "./react-select.css";
import { isNil } from 'lodash';
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

const Placeholder = props => {
  return <components.Placeholder {...props} />;
};

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowDropDownIcon style={{ top: '8px' }} />
    </components.DropdownIndicator>
  );
};

const customStyles = {
  dropdownIndicator: base => ({
    ...base,
    color: "#000000" // Custom colour
  })
};

const ReactSelectComponent = ({
  options,
  value,
  onChange,
  label,
  disabled,
  placeholder,
  name,
  customClassName,
  ...rest
}) => (
  <div className='react-select'>
    <div className='title mb-1'>{label}</div>
    <Select
      components={{ Placeholder, DropdownIndicator }}
      isMulti={false}
      value={value}
      styles={customStyles}
      placeholder={placeholder}
      options={options}
      className={clsx('single-select-container', isNil(value) ? '' : null, customClassName)}
      classNamePrefix="multi-select"
      isDisabled={disabled}
      closeMenuOnSelect={true}
      name={name}
      tabSelectsValue={false}
      onChange={onChange}
      {...rest}
    />
  </div>
);

ReactSelectComponent.propTypes = {
  options: PropTypes.array,
  id: PropTypes.string,
  disabled: PropTypes.bool
};

ReactSelectComponent.defaultProps = {
  options: [],
  id: '',
  disabled: false,
};

export default ReactSelectComponent;
