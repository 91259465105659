import React from 'react';
import { SimilarIssuesContainer } from '../../../components/Insights/containers/SimilarIssuesContainer';
import { TopResolutionContainer } from '../../../components/Insights/containers/TopResolutionContainer';

import { IssueClarificationContainer } from '../../../components/Insights/containers/IssueClarificationContainer';
import '../InsightsPage.css';
import fetchInsightsDataAction from '../../../store/actions/insights/fetchInsightsData/fetchInsightsDataAction';
import { connect } from 'react-redux';
import getInsightsData from '../../../components/Insights/helpers/getInsightsData';
import NavbarCustom from '../../../components/UI/Navbar/Navbar'
import * as actions from '../../../store/actions/index'
import { Row, Col, Container } from 'react-bootstrap';
import Heading3 from './components/Typography/Heading3/Heading3';
import getProjectDetails from "./helpers/getProjectDetails";
import { GetCalltoServer } from '../../../store/utility';
import { get } from 'lodash';
import permissions from "../../../permissions_Json"
import {KnowledgeArticlesContainer} from '../../../components/Insights/containers/KnowledgeArticlesContainer';
import { encryptAndStore } from '../../../utility/utility';

let ProductTriage_Read_Write = false;
let configObj = {}
class InsightsPage extends React.Component {
  constructor() {
    super();
    this.state ={
      projectname:"Project List",
      searchT:"",
      popperShow:false,
      fields:[]
    }
  }

  componentDidMount() {
    //const apiUrl = "http://localhost:88";
    const apiUrl = this.props.config.INSIGHTS_API_URL;
    let collectionName = "insights_collection";
    let collectionType = "insights_collection";
    let documentId = "insightsId";	
    let insightsData = getInsightsData(this.props);	
    collectionName = this.props.config.insights.index;
    documentId = insightsData.projectName+"__"+insightsData.viewName;
    let iTSESUrl = insightsData.iTSESUrl;
    configObj = {
        configObj: {
        "collection":collectionName,
        "type":collectionType,
        "documentId":documentId,
        iTSESUrl: iTSESUrl
      },
      apiUrl
    };
    let projectName = ''
    if(this.props.IsProjectChange)  projectName = this.props.IsProjectChange
    else  projectName = getProjectDetails()
    this.props.fetchInsightsData(configObj,projectName);
    GetCalltoServer(apiUrl + `/product-intelligent-triage/settings/ticket/fields/producttriage`)
    .then((response) => {
      if (get(response, "data.status.responseType", "") === "success") {
      this.setState({fields:get(response, "data.data" ,[])});
      }else{
        console.log("error");
      }
      
    }).catch((err) => {
        console.log("err",err)
    })
    
  }
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.IsProjectChange !== this.props.IsProjectChange) {
      this.props.fetchInsightsData(configObj,nextProps.IsProjectChange);
    }
  }
  setSearchTerm = (item) => {
    this.setState({searchT:item})
  }
  projectclick =(event,projectname)=>{
      this.setState({searchT:"",popperShow:false})
      let savedProjectName=localStorage.getItem('projectname');
      if(savedProjectName!==projectname){
      // localStorage.setItem('projectname', projectname );
      encryptAndStore('projectname', projectname );
      this.setState({projectname:projectname});
      }
      this.props.onchangeProjectAction(projectname);        
        //window.updateHCLChatBotURL(projectname);
  }

  popperShowFn=()=>{
    this.setState({
      popperShow:true,
    })
  }

  

  render() {
    let ProductTriage_RW_Status = this.props.permissions.find(data => {
      if (data.permission_code.includes(permissions.permissionCode.PRODUCT_TRIAGE_ADMIN_RW) && data.sku_code.includes(permissions.skuCode.PT))
        return true;
    })
    ProductTriage_Read_Write = ProductTriage_RW_Status ? true : false
  
  
    return (
      <Container className="triageTabStyle">
      <Row>
        <Col xs='6'>
          <div className='d-flex mt-4 mb-3'>
            <Heading3 text='Agent Cockpit Layout' className="pageTitleText"/>
          </div>
        </Col>
        <Col xs='6' className="mt-4 mb-3">
        <div style={{position:"relative",float:"right",marginRight:"40px"}}>
          <NavbarCustom 
              className="crawlingProjectName"
              dataList={this.props.projectList}
              addImportProjectIcon={false}
              setSearchTerm={(item)=>this.setSearchTerm(item)}
              popperShow={this.state.popperShow}
              popperShowFn={()=>this.popperShowFn()}
              searchTerm={this.state.searchT}
              projectclick={(event,item)=>this.projectclick(event,item)}
              projectname={this.state.projectname} 
              rightPopUp={false} >
          </NavbarCustom>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs='12'>
          <div className="InsightsPage">
            <SimilarIssuesContainer  config={this.props.config} fields={this.state.fields} permissions={ProductTriage_Read_Write}/>
            {/* <KbInfoContainer  config={this.props.config} permissions={ProductTriage_Read_Write}/> */}
            <IssueClarificationContainer  config={this.props.config} permissions={ProductTriage_Read_Write}/>
            <TopResolutionContainer  config={this.props.config} permissions={ProductTriage_Read_Write}/>
            <KnowledgeArticlesContainer config={this.props.config} permissions={ProductTriage_Read_Write}/>
            {/*<PredictedIssuesContainer  config={this.props.config}/>
            <QuestionnairContainer  config={this.props.config}/>*/}
          </div>
        </Col>
      </Row>
      </Container>
    )
  }
}
const mapStateToProps = state => {
  return {
    IsProjectChange:state.projectview.IsProjectChange,
    projectList:state.user.projects,
    similarticketsStatus:state.insights.similartickets.enabled,
    permissions:state.user.permissionList,

};
}
const mapDispatchToProps = (dispatch) => ({
  fetchInsightsData: (payload,project) => {
    dispatch(fetchInsightsDataAction(payload,project))
  },
  onchangeProjectAction: (projectname) => dispatch(actions.changeProjectAction(projectname)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InsightsPage)