import React from 'react';
import moment from 'moment';
import $ from 'jquery';
import {
  Button,
} from 'react-bootstrap';
import DatetimeRangePicker from '.';

class PredefinedRanges extends React.Component {

  constructor(props) {
    super(props);
    let today = moment().format('YYYY-MM-DD');
    let days7 = moment().subtract(6, 'days').format('YYYY-MM-DD');
    let days30 = moment().subtract(29, 'days').format('YYYY-MM-DD');
    let startDate = moment(this.props.startDate);
    let endDate = moment(this.props.endDate);
    this.state = {
      showCustom: false,
      startDate: startDate,
      endDate: endDate,
      selectedDate: (today==endDate.format('YYYY-MM-DD') && days7==startDate.format('YYYY-MM-DD'))? 'day7' : (today==endDate.format('YYYY-MM-DD') && days30==startDate.format('YYYY-MM-DD')) ? 'day30' : 'custom',
      ranges: {
       // 'Today': [moment(), moment()],
       // 'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      //  'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      //  'Last 30 Days': [moment().subtract(29, 'days'), moment()],
       // 'This Month': [moment().startOf('month'), moment().endOf('month')],
       // 'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
       // 'Last 3 months': [moment().subtract(3, 'months'), moment()],
       // 'Last 6 months': [moment().subtract(6, 'months'), moment()],
       // 'This Year': [moment().startOf('year'), moment()],
      },
    };
  }



  SubmitEvent = (event, picker) => {
    this.setState({
      startDate: picker.startDate,
      endDate: picker.endDate,
    });
    this.props.onDateChange({
      startDate: moment(picker.startDate).format('YYYY-MM-DD'),
      endDate: moment(picker.endDate).format('YYYY-MM-DD'),
    })
  }

  SubmitEventCustom = (startDate,endDate) => {
    this.setState({
      startDate: startDate,
      endDate: endDate,
      showCustom:false
    });
    this.props.onDateChange({
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
    })
  }
  handleEvent  = (event, picker) => {
    if(event.type=="showCalendar"){
      $('.ranges ul li'). removeClass('active');
      $(".ranges ul li[data-range-key='Custom Range']").addClass('active');    
      // console.log($(".ranges ul li[data-range-key='Custom Range']").hasClass('active'))
      $(".daterangepicker.show-calendar .drp-buttons").show()
    }else{
      // console.log($(".ranges ul li[data-range-key='Custom Range']").hasClass('active'))
       $(".daterangepicker .drp-buttons").hide()
    }
  }
  


  render() {
    let start = this.state.startDate.format('DD MMM YYYY');
    let end = this.state.endDate.format('DD MMM YYYY');
    let label = start + ' - ' + end;
    if (start === end) {
      label = start;
    }

    let buttonStyle = { width: '92%',background:'#fff',border:'1px solid #000',color:'#000',marginRight: '0px', paddingTop:'3px', paddingBottom:'3px' };
   
    return (
      <div className="form-group">
        <ul className="dateRange-tabs">
          <li className={this.state.selectedDate=='day7'?'active':''} onClick={()=>{
             this.setState({ selectedDate:'day7'})
            this.SubmitEventCustom(moment().subtract(6, 'days'),moment())
          }}>7 Days</li>
          <li className={this.state.selectedDate=='day30'?'active':''} onClick={()=>{
            this.setState({ selectedDate:'day30'})
            this.SubmitEventCustom(moment().subtract(29, 'days'),moment())
            }}>30 Days</li>
          <li className={this.state.selectedDate=='custom'?'active':''} data-testid={`custom`} onClick={()=>{
            this.setState({
              showCustom:true,
              selectedDate:'custom'
            })}}>Custom</li>
        </ul>
        {this.state.selectedDate=='custom' &&
          <DatetimeRangePicker 
            startDate={this.state.startDate}
            endDate={this.state.endDate}
          //  ranges={null}
          //  onEvent={this.handleEvent}
           onApply={this.SubmitEvent}
            maxDate={moment()} >
             <Button className={"selected-date-range-btn " + (this.props.class) ? this.props.class : ''} style={buttonStyle}>
                <div className="reportcalenderOuter">
                    <span className="reportcalenderLabel">
                        {label}
                    </span>&nbsp;
                    <img className="reportcalendericon" src='./Icons/calender-icon.png' />
                </div>
            </Button>
          </DatetimeRangePicker>
        }
      </div>
    );
  }

}

export default PredefinedRanges;
