const changeFilterDataArrReducer = (state, payload) => {
    return {
        ...state,
            selectedFilterDataArr: {
                ...state.selectedFilterDataArr,
               ...payload
            }
    };
};

export default changeFilterDataArrReducer;