// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.maindiv{
  flex-flow: column;
  display: flex;
  padding-top: 4px;
  padding-left: 2px;
}
.product-data{
  flex-flow: row;
}
.closeicon{
margin-left: -3px;
}
.ButtonComponent{
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/containers/SettingPage/SettingInnerPages/AgentCockpit/CrmLayout/CrmCustomerDataDrawer.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,aAAa;EACb,gBAAgB;EAChB,iBAAiB;AACnB;AACA;EACE,cAAc;AAChB;AACA;AACA,iBAAiB;AACjB;AACA;EACE,gBAAgB;AAClB","sourcesContent":[".maindiv{\n  flex-flow: column;\n  display: flex;\n  padding-top: 4px;\n  padding-left: 2px;\n}\n.product-data{\n  flex-flow: row;\n}\n.closeicon{\nmargin-left: -3px;\n}\n.ButtonComponent{\n  margin-top: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
