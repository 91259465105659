import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import getProjectDetails from '../../../../containers/SettingPage/SettingInnerPages/helpers/getProjectDetails';
import { PostCalltoServer } from '../../../utility';
import fetchClustersDataFailureAction from './fetchClustersDataFailureAction';
import fetchClustersDataStartAction from './fetchClustersDataStartAction';
import fetchClustersDataSuccessAction from './fetchClustersDataSuccessAction';


const getFilterDataObj = (selectedFilterData) => {
    const filterData = Object.entries(selectedFilterData).map(([key, value]) => {
        
        if (key === 'description') {
            return;
        }
        return {
            [key]: value,
        }
    });
    return Object.assign({}, ...filterData);
}

const getConfigObj = (state) => {
    const yesQuestions = state.agentInsights.yesQuestions;
    const selectedFilterData = state.agentInsights.selectedFilterDataArr;
    const filterDataObj = getFilterDataObj(selectedFilterData);
    
    Object.keys(filterDataObj).forEach(function(k) {
        if(filterDataObj[k].length == 0) delete filterDataObj[k]
        if( filterDataObj[k] == "") delete filterDataObj[k]
    });
    if (isEmpty(yesQuestions)) {
        return filterDataObj;
    }
    return {
        ...filterDataObj,
        "yes": yesQuestions.map(item => {
            if (isEmpty(item)) {
                return [];
            }
            return item.symptoms;
        })
    }
}

const fetchClustersDataAction = (payload) => (dispatch, getState) => {
    const state = getState();
    const selectedFilterData = state.agentInsights.selectedFilterDataArr;
    console.log("6576777777777777777777777777777")
    console.log(selectedFilterData)
    const description =  get(selectedFilterData, 'description', '');
    const projectName = getProjectDetails();
    const url = payload + `/product-intelligent-triage/insights/issueclusters/${description}/${projectName}`;   
    const configObj = getConfigObj(state)
    dispatch(fetchClustersDataStartAction());
    PostCalltoServer(url, configObj)
        .then(response => {
            if (get(response, 'data.status.responseType', '') === "success") {
                dispatch(fetchClustersDataSuccessAction(response));
            }
            else {
                dispatch(fetchClustersDataFailureAction(response.data.status.responseType))
            }
        }).catch(err => {
            dispatch(fetchClustersDataFailureAction(err.message))
        });
};

export default fetchClustersDataAction;