import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { Spinner, Row } from 'react-bootstrap';
import { botPostCalltoServer } from '../../../../store/utility';
import { SetErrorMessage, SetSuccessMessage } from '../../../../store/actions';
import InputWithLabelComponent from '../components/InputWithLabelComponent/InputWithLabelComponent';
import PrimaryButtonWithLoader from '../components/PrimaryButtonWithLoader/PrimaryButtonWithLoader';

function SharepointSettings({ data, showConfigLoader, selectedMenu, selectedSubMenu, config, handleDisableDataTabs }) {
    const dispatch = useDispatch();
    const [showVerifyLoader, setShowVerifyLoader] = useState(false);
    const [sourceConnection, setLocalSourceConnection] = useState([]);
    const [apiPayload, setPayload] = useState({});
    const connectionsData = useSelector(state => state.projectview.connectionsData);
    const selectedConnectionsData = connectionsData.find(item => item._id.includes(selectedSubMenu));
    const selectedSourceConnectionData = get(selectedConnectionsData, '_source.connection', []);
    const passVal='text';

    useEffect(() => {
        if (isEmpty(selectedSourceConnectionData)) {
            setLocalSourceConnection(get(data, 'sourceConnection', []));
            setPayload(data);
        } else {
            setLocalSourceConnection(get(selectedSourceConnectionData, 'sourceConnection', []));
            setPayload(selectedSourceConnectionData);
        }
    }, [data, connectionsData]);

    const handleTextBoxChange = (event) => {
        const updatedLocalConnections = sourceConnection.map(item => {
            if (item.type === event.target.name) {
                return {
                    ...item,
                    value: event.target.value
                };
            }
            return item;
        });
        setLocalSourceConnection(updatedLocalConnections);
    }

    const verifyConnectionDetails = () => {
        const connectorData = {
            ...apiPayload,
            lastVerifiedDate: moment().utc().format('MM/DD/YYYY hh:mm:ss A z'),
            sourceConnection
        };

        const publishConfigObj = {
            'dataSourceType': selectedMenu,
            'dataSourceName': selectedSubMenu,
            'tenantId': localStorage.getItem("orgName"),
            'projectName': localStorage.getItem("projectName"),
            'applicationUrl': config.CONNECTOR_URL,
            'connector': JSON.stringify(connectorData)
        };

        let url = config.CONNECTOR_URL + `/publish-connector`;
        setShowVerifyLoader(true);
        botPostCalltoServer(url, publishConfigObj)
            .then(response => {
                if (get(response, 'data.status.responseType', '') === "success") {
                    handleDisableDataTabs(false);
                    if (response.data.data.toLowerCase().includes("invalid url") || response.data.data.toLowerCase().includes("authentication")) {
                        dispatch(SetErrorMessage(response.data.data));
                    } else {
                        dispatch(SetSuccessMessage('Successfully established the connection with Sharepoint.'));
                        // dispatch(SetSuccessMessage(response.data.data));
                    }
                    setShowVerifyLoader(false);
                }
                else {
                    dispatch(SetErrorMessage(get(response, 'data.errors.0.source', '')));
                    setShowVerifyLoader(false);
                }
            }).catch(err => {
                dispatch(SetErrorMessage(get(err, 'response', '')));
                setShowVerifyLoader(false);
            });
    };
    const hiddenConnections = ['_TENANT_', '_SOURCE_TYPE_', '_DATASOURCE_'];
    return (
        <React.Fragment>
            <div>
                {showConfigLoader ?
                    <Row className="justify-content-md-center padding10">
                        <Spinner animation="border" variant="primary" />
                    </Row> :
                    <React.Fragment>
                        <div>
                            {sourceConnection.length ? sourceConnection.map(connection => {
                                {
                                    return hiddenConnections.includes(connection.type) ?
                                        null :
                                        (
                                            <div>
                                                <InputWithLabelComponent
                                                    type={connection.type === '_PASSWORD_' ? 'password' : passVal}
                                                    label={connection.displayName}
                                                    name={connection.type}
                                                    value={connection.value}
                                                    onChange={handleTextBoxChange}
                                                    readOnly={false}
                                                />
                                            </div>
                                        )
                                }
                            }) : null}
                            <PrimaryButtonWithLoader
                                className="mb-2 mt-4"
                                size="md"
                                onClick={verifyConnectionDetails}
                                text="Save"
                                loading={showVerifyLoader}
                                disabled={showVerifyLoader || sourceConnection.length ? sourceConnection.find(connection => !connection.value) : true}
                            />
                        </div>
                    </React.Fragment>
                }
            </div>
        </React.Fragment>
    )
}

export default SharepointSettings;