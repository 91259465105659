// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.issue-clusters-head {
    font-family: 'Roboto-Bold';
    font-size: 12px;
    line-height: 15px;
    color: #3D3A3A;
    text-transform: capitalize;
}

.issue-clusters-description {
    font-family: 'Roboto-Regular';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    color: #555555;
}

.issue-clusters-tickets {
    font-family: 'Roboto-Bold';
    font-size: 12px;
    line-height: 14px;
    color: #3d3a3a;
}

.issue-clusters-tickets-count {
    font-family: 'Roboto-Regular';
    font-style: normal;
    font-weight: 300;
    font-size: 11px;
    line-height: 13px;
    /* identical to box height */
    color: #474545;
}

.issue-clusters-box {
    cursor: pointer;
    box-sizing: border-box;
}

.issue-clusters-box.selected {
    border: 1px solid #000;
}
.mh-150 {
    min-height: 150px;
}`, "",{"version":3,"sources":["webpack://./src/containers/SettingPage/SettingInnerPages/Insights/components/InsightsResults/IssueClusters/clusters-card.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,0BAA0B;AAC9B;;AAEA;IACI,6BAA6B;IAC7B,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,0BAA0B;IAC1B,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,6BAA6B;IAC7B,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,4BAA4B;IAC5B,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,sBAAsB;AAC1B;;AAEA;IACI,sBAAsB;AAC1B;AACA;IACI,iBAAiB;AACrB","sourcesContent":[".issue-clusters-head {\n    font-family: 'Roboto-Bold';\n    font-size: 12px;\n    line-height: 15px;\n    color: #3D3A3A;\n    text-transform: capitalize;\n}\n\n.issue-clusters-description {\n    font-family: 'Roboto-Regular';\n    font-style: normal;\n    font-weight: 300;\n    font-size: 12px;\n    line-height: 14px;\n    color: #555555;\n}\n\n.issue-clusters-tickets {\n    font-family: 'Roboto-Bold';\n    font-size: 12px;\n    line-height: 14px;\n    color: #3d3a3a;\n}\n\n.issue-clusters-tickets-count {\n    font-family: 'Roboto-Regular';\n    font-style: normal;\n    font-weight: 300;\n    font-size: 11px;\n    line-height: 13px;\n    /* identical to box height */\n    color: #474545;\n}\n\n.issue-clusters-box {\n    cursor: pointer;\n    box-sizing: border-box;\n}\n\n.issue-clusters-box.selected {\n    border: 1px solid #000;\n}\n.mh-150 {\n    min-height: 150px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
