import React from "react";
import { Form } from "react-bootstrap";
import * as PropTypes from "prop-types";
import SelectComponent from "../../../../../components/UIComponents/SelectDropdown/SelectComponent";
import "./routing-wrapper.css";


const RoutingComponent = ({
    label,
    routeType,
    routeData,
    routeTypeChange,
    routeDataChange,
    routeTypes,
  
    isInvalid,
    error,
    showLabel,
    required,
    className,
    placeHolder,
    readOnly,
    url
}) => {
    return (
        <div className="common-input">
            {showLabel ? (
                <Form.Label className="mb-1 text-box-title adding-other-input">{label}</Form.Label>
            ) : null}
            <div>
                <div className="input-group routing-div">
                    <label className="dropdown-wrapper">
                        <SelectComponent name="selectName"
                            label=""
                            className="link1"
                            changed={(event) => routeTypeChange(event)}
                            required={true}
                            value={routeType}
                            options={routeTypes}></SelectComponent>
                    </label>
                         <Form.Control
                             type={"text"}
                             name={"routeData"}
                             onChange={(event) => routeDataChange(event)}
                             required={required}
                             value={routeData}
                             className={"default-css color-text-input" + " " + className}
                             placeholder={placeHolder}
                             readOnly={readOnly}
                             isInvalid={isInvalid}
                         />

                </div>
            </div>
            {isInvalid ? (
                <span data-testid={`route-Err`} className="error-css">{error}</span>
            ) : null}
        </div>
    );
};
RoutingComponent.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    className: PropTypes.string,
    type: PropTypes.string,
    placeHolder: PropTypes.string,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    isInvalid: PropTypes.bool,
    error: PropTypes.string,
    helpIcon: PropTypes.bool,
    showLabel: PropTypes.bool,
};

RoutingComponent.defaultProps = {
    label: "Input Label",
    name: "input-class",
    className: "",
    placeHolder: "",
    readOnly: false,
    required: false,
    isInvalid: false,
    type: "text",
    error: "",
    helpIcon: false,
    showLabel: true,
};
export default RoutingComponent;
