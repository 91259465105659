/*

* Copyright © 2023, HCL Technologies Ltd. ALL RIGHTS RESERVED.

* <p>

* This software is the confidential information of HCL Technologies Ltd., and is licensed

* as restricted rights software. The use,reproduction, or disclosure of this software

* is subject to restrictions set forth in your license agreement with HCL.

*/

 

/**

* Intent Analysis.

*

* @author Abhash Vats

*/

import React, { Component } from 'react';
import axios from "../../../instance";
import withErrorHandler from "../../../hoc/withErrorHandler/withErrorHandler";
import { connect } from "react-redux";
import TabsComponent from "../../SettingPage/SettingInnerPages/components/TabsComponent/TabsComponent";
import { Col, Row, Image, Form} from 'react-bootstrap';
import "./IntentAnalysis.css";
import SearchComponent from "../../../components/UIComponents/SearchComponent/Search";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import styled from "styled-components";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import orderBy from 'lodash/orderBy';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { makeStyles,withStyles } from '@material-ui/core';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import * as actions from "../../../store/actions/index";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import NavbarCustom from './Navbar';
import Grid from "@material-ui/core/Grid";
import InputWithLabelComponent from "../../SettingPage/SettingInnerPages/components/InputWithLabelComponent/InputWithLabelComponent";
import ButtonComponent from "../../../components/UIComponents/Button/ButtonComponent";
import DeleteIcon from '@material-ui/icons/Delete';
import ButtonCustom from "../../../components/UIComponents/Button/ButtonComponent";
import Button from '@material-ui/core/Button';
import DrawerComponent from '../../../components/UIComponents/Drawer/DrawerComponent';
import TextAreaWithLabelComponent from "../../SettingPage/SettingInnerPages/SecheduleMeeting/TextAreaWithLabelComponent";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { forEach } from 'lodash';
import TablePagination from '@material-ui/core/TablePagination';

const StyledCheckbox = styled(Checkbox)`
  svg {
    color: rgb(42, 147, 223);
    font-size: 15px;
  }
`;
const StyledCheckbox2 = styled(Checkbox)`
  svg {
    color: rgb(42, 147, 223);
    font-size: 18px;
  }
  .MuiCheckbox-root:{
    padding:0px;
    marginLeft:9px;
  }
`;

const invertDirection = {
  asc:'desc',
  desc:'asc',
}

class IntentAnalysis extends Component {

    state={
      selectedTab:'ACTIVE INTENTS',
      searchValue:'',
      menuShow1:null,
      menuShow:null,
      show:true,
      all1:false,
      new:false,
      tabItems:[
        {
          label: 'ACTIVE INTENTS',
          value: 'ACTIVE INTENTS'
        },
        {/* //commented due to release 12.1.7
          label: 'INTENTS WAITING FOR APPROVAL',
          value: 'UNANSWERED QUERIES'
      */}
      ],
      tableColm:['Intent Name','Created Date',''],
      sortedColn:'dates',
      tableData:[],
      tableColm2:['Intent Name','Comments','Recommended By','Created Date',''],
      tableData2:[],
      sortedColn2:'date',
      intentNameInDrawer:null,
      itemInDrawer:null,
      selectedUtterances: [],
      selectedList:[],
      count:0,
      sortDirection:'desc',
      rowsPerPage:5,
      page:0,
      columnToSort:'',
      editIntent:false,
      rowItem:null,
      // intentList:{bots:["Lorem","ipsum","ipsum","Lorem","ipsum","ipsum","Lorem","ipsum","ipsum"],},
      intentList:[],
      searchT:"",
      popperShow:false,
      intentname:'',
      intentDescription:'',
      phrases:[''],
      openApprovalDrawer:false,
      changeRadioBtn:"newIntentRadio",
      allIntentDescription:"",
    }
    setTableData=()=>{
        var intentName = []
        // this.setState({tableData:[]})
        this.state.tableData.length=0
        var botlist = this.props.botList
        var  grpprojectlang = this.props.projectList.bots;
        var language = localStorage.getItem("language");
        var projectname = localStorage.getItem("projectname")
        if (!language || language == "en") language = "en";
        var botlanguage = null;
        if (grpprojectlang && grpprojectlang.length > 0) {
          botlanguage = botlist[language];
        }
        if (botlanguage) {
          if (botlanguage.hasOwnProperty(projectname)) {
            var projectList = botlanguage[projectname];
            var temp = []
            // if (projectname !== "common") {
              Object.keys(projectList).forEach(function eachKey(key) {
                if((key != "faq" && key != "FAQ") && (key != "Intentlist")){
                  temp.push({
                    "intentName" : key,
                    "comments" : '',
                    "dates" : projectList[key][1].created_date
                  })   
                  intentName.push(key)
                }
                });
            this.setState({intentList:intentName,tableData:temp,count:temp.length})
            //this.state.tableData.push(temp)
          }
        }
    }
    componentDidUpdate(prevprops){
      this.props.onKnowledgeGridExpandCollapse("collapse")
      this.props.OnProjectHeaderShow(true)
      if(this.props.waitinForApprovalData && prevprops.waitinForApprovalData !== this.props.waitinForApprovalData){
        let obj = this.props.waitinForApprovalData
        let value 
        obj && Object.keys(obj).forEach(function(key){
          if(key.includes("Intent")){
            value = obj[key];
            console.log(key + ':' + JSON.stringify(value));
          }
          
        });
        this.setState({tableData2:value}) 
        var tab=[
          {
            label: 'ACTIVE INTENTS',
            value: 'ACTIVE INTENTS'
          },
          {/*//commented due to release 12.1.7
            label: 'INTENTS WAITING FOR APPROVAL('+value.length+")",
            value: 'UNANSWERED QUERIES'
            */
          }
        ]
        this.setState({tabItems:tab}) 
      }
      // if(this.props.botList != undefined && this.props.botList != prevprops.botList){
      //   var project = localStorage.getItem("projectname");
      //   var language = localStorage.getItem("language");
      //   var apiUrl = this.props.config.BOTBUILDER_URL + "/project/intent-description?" + "project=" + project + "&language=" + language
      //   Promise.all([this.props.OnGetIntentDescription(apiUrl)]).then(
      //     ()=>{
      //       this.state.tableData.length=0;
      //       this.setState({editIntent:false,intentname:'',intentDescription:'',allIntentDescription:this.props.intentDescription});
      //       this.setTableData();
      //     }
      //   )
      // }
    
      if(this.props.botList != undefined && this.props.botList != prevprops.botList){
        var project = localStorage.getItem("projectname");
        var language = localStorage.getItem("language");
        var apiUrl = this.props.config.BOTBUILDER_URL + "/project/intent-description?" + "project=" + project + "&language=" + language
        Promise.all([this.props.OnGetIntentDescription(apiUrl)]).then(
          ()=>{
            this.state.tableData.length=0;
            this.setState({editIntent:false,intentname:'',intentDescription:'',allIntentDescription:this.props.intentDescription});
            this.setTableData();
          }
        )
      }
    }

    componentDidMount(){
      var project = localStorage.getItem("projectname");
      var language = localStorage.getItem("language");
      var apiUrl = this.props.config.BOTBUILDER_URL + "/project/intent-description?" + "project=" + project + "&language=" + language
      Promise.all([this.props.OnGetIntentDescription(apiUrl)]).then(
        ()=>{
          this.state.tableData.length=0;
          this.setState({editIntent:false,intentname:'',intentDescription:'',allIntentDescription:this.props.intentDescription});
          this.setTableData();
        }
      )
      var url = this.props.config.BOTBUILDER_URL + "/continuous-learning/project/" + project
      Promise.all([this.props.onGetWaitingForApprovalIntent(url)]).then(
        ()=>{
            let obj = this.props.waitinForApprovalData
            let value =[]
            obj && Object.keys(obj).forEach(function(key){
              if(key.includes("Intent")){
                value = obj[key];
                console.log(key + ':' + JSON.stringify(value));
              }
              
            });
            this.setState({tableData2:value})
            var tab=[
              {
                label: 'ACTIVE INTENTS',
                value: 'ACTIVE INTENTS'
              },
              {/*//commented due to release 12.1.7
                label: 'INTENTS WAITING FOR APPROVAL('+value && value.length+")",
                value: 'UNANSWERED QUERIES'
                */
              }
            ]
            this.setState({tabItems:tab})
          }
      )
    }

    setSelected = (tab) => {
      this.setState({selectedTab:tab});
      if(tab == 'ACTIVE INTENTS'){
        this.setState({count:this.state.tableData.length})
      }
      else{
        this.setState({count:this.state.tableData2.length})
      }
    }
    ClearSearchValue = ()=>{
      this.setState({searchValue:"",show:true})
      // setShow(true);
    }

    onQuickFilterChanged = (event) => {
        this.setState({searchValue:event.target.value})
            if(event.target.value!="" ){
              this.setState({show:false})
            }
            else{
                this.setState({show:true})
            }
    };

    handleClickMenu1 = (event) => {
        this.setState({menuShow1:event.currentTarget
        })
    };

    handleClickMenu = (event) => {
      this.setState({menuShow:event.currentTarget
      })
  };
  handleCloseMenu1 = () => {
      this.setState({menuShow1:null})
  };
  handleCloseMenu = () => {
    this.setState({menuShow:null})
};

  handleSort = (columnName) =>{
    this.setState({columnToSort:columnName})
    if(this.state.columnToSort == columnName)
        this.setState({sortDirection:invertDirection[this.state.sortDirection]})
    else
        this.setState({sortDirection:'asc'})
    if(columnName == 'Created Date'){
      this.setState({sortedColn:'dates'})
    }
    else{
      this.setState({sortedColn:'intentName'})
    }
  }
  handleSort2 = (columnName) =>{
    this.setState({columnToSort:columnName})
    if(this.state.columnToSort == columnName)
        this.setState({sortDirection:invertDirection[this.state.sortDirection]})
    else
        this.setState({sortDirection:'asc'})
    if(columnName == 'Created Date'){
      this.setState({sortedColn2:'date'})
    }
    else{
      this.setState({sortedColn2:'topic'})
    }
  }

  deleteBot = () =>{
    var language = localStorage.getItem("language");
    var projectname = localStorage.getItem("projectname")
    this.props.deleteBot({
      botName: this.state.intentname,
      profile: this.state.intentname + "__",
      projectName: projectname,
      language: language ? language : "en",
    });
    this.handleCloseMenu()
  }
  exportBot = () =>{
    var language = localStorage.getItem("language");
    var projectname = localStorage.getItem("projectname")
    this.props.exportBot({
      botName: this.state.intentname,
      language: language ? language : "en",
      training: "df",
      project: projectname,
      profilename: this.state.intentname + "__",
    });
    this.handleCloseMenu()
  }

  editRow = () =>{
    var intentName = []
    this.setState({tableData:[]})
    var botlist = this.props.botList
    var  grpprojectlang = this.props.projectList.bots;
    var language = localStorage.getItem("language");
    var projectname = localStorage.getItem("projectname")
    if (!language || language == "en") language = "en";
    var botlanguage = null;
    if (grpprojectlang && grpprojectlang.length > 0) {
      botlanguage = botlist[language];
    }
    var projectList = botlanguage[projectname];
    this.setState({editIntent:true})
    //this.props.onKnowledgeGridExpandCollapse("expand")
    this.props.OnProjectHeaderShow(false)
    var botvalue = ""
    botvalue = projectList[this.state.intentname][0]["profile"]
    botvalue = botvalue.toString()
      this.props.currentBotEvent(
        true,
        this.state.intentname,
        botvalue,
        true,
        this.state.intentList,
        this.state.intentname,
        this.state.popperShow,
        this.state.intentname,
      );
      this.handleCloseMenu()
    // setSelectedBot();
  }
  // backtoIntentList = () =>{
  //   this.props.onKnowledgeGridExpandCollapse("collapse")
  //   this.props.OnProjectHeaderShow(true)
  //   this.setState({editIntent:false,intentname:'',intentDescription:''})
  // }
  onRowClicked = (item) =>{
    this.setState({rowItem:item,intentname:item.intentName,intentDescription:item.comments})
}
  onEdit = (item) =>{
    this.setState({intentname:item.intentName},()=>{this.editRow()})
  }

setSearchTerm = (item) => {
  this.setState({searchT:item})
}

popperShowFn=()=>{
  this.setState({
    popperShow:true,
  })
}
intentclick =(event,intent)=>{
  this.setState({searchT:"",popperShow:false,intentname:intent})
}
handlePhrase = (e,index)=>{
  var temp = [...this.state.phrases]
  temp[index] = e.target.value
  this.setState({phrases:temp})
}
deletePhrase = (e,index)=>{
  var temp = [...this.state.phrases]
  temp.splice(index,1)
  this.setState({phrases:temp})
}
handleAddRow = () =>{
  var temp = [...this.state.phrases]
  temp.push("")
  this.setState({phrases:temp})
}

openApprovalDrawer = (item) =>{

  this.setState({intentNameInDrawer:item.topic,itemInDrawer:item,openApprovalDrawer:true});
}
closeApprovalDrawer = () =>{
  this.setState({openApprovalDrawer:false});
}
onChangesearchdays = (e) =>{
  this.setState({intentNameInDrawer:e.target.value})
}
changeRadioBtn = (e)=>{
  this.setState({changeRadioBtn:e})
}
handleChangePage = (event, newPage) => {
  console.log("event",event)
this.setState({page:newPage})
}

handleChangeRowsPerPage = (event) => {
this.setState({rowsPerPage:+event.target.value,page:0})
}
handleCheckboxChange = (index) => {
  var isSelected = false;
  const selectedUtterances = this.state.itemInDrawer.utterances;
this.state.selectedList.push(selectedUtterances[index])
  if(selectedUtterances.includes(selectedUtterances[index]) > -1){
    isSelected = true;
  }
var finaliList=this.state.selectedList;
  this.setState({finaliList });
};




approveIntent = () => {
  var flowData = {
      "botName":this.state.intentNameInDrawer,
      // "description":this.state.itemInDrawer.comment,
      "description":this.state.intentNameInDrawer,
      "project":localStorage.getItem("projectname"),
      "language":"en",
      "response":"",
      "intentTraining":null,
      "entityData":{
        "entityList":[],"Action":"","FinalValidationOptional":""},"orgName":localStorage.getItem("orgName")
      }
  var apiUrl = this.props.config.BOTBUILDER_URL + "/project/flow"
  this.props.onApproveFlow(apiUrl,flowData)
  
  var intentData = {
      "project":localStorage.getItem("projectname"),
      "language":"en",
      "botName":this.state.intentNameInDrawer,
      "intentTraining":{"learn":this.state.selectedList ,"relearn":[" "]},"description":this.state.intentNameInDrawer,"orgName":localStorage.getItem("orgName")
      }
  apiUrl = this.props.config.BOTBUILDER_URL + "/project/flow/intent"
  this.props.onApproveIntent(apiUrl,intentData)
  this.closeApprovalDrawer()
}
    render()
    {
     
        let tabContent=null;
        let common =this.state.editIntent == false && <React.Fragment>
            <Row className='commonPadding' style={{width:"100%",paddingRight:"0px"}}>
                <Col md={8} sm={8} lg={8} xlg={8}>
                    <div><span className='headerText'><span style={{color:"#016BB5"}}>{this.state.count} Recommended Intents </span> are available! </span> </div>
                </Col>
                <Col md={4} sm={4} lg={4} xlg={4} className='recent'>
                  <div>
                    {/* <span className='filterIcon'><img onClick={(e)=>this.handleClickMenu1(e)} src='./images/Filter-icon.png' style={{cursor:"pointer"}}></img></span> */}
                      <SearchComponent className="searchIcon" class={"input-group searchClass"} Clear={()=>this.ClearSearchValue()} show={this.state.show} 
                      value={this.state.searchValue} onSearch={(event) => this.onQuickFilterChanged(event)}/>
                  </div>
                </Col>
            </Row>
            </React.Fragment>
            const filteredData = this.state.tableData.filter((value)=>{
              return (
                (value.intentName != null &&
                  value.intentName.toLowerCase().includes(this.state.searchValue.toLowerCase())) ||
                (value.comments != null &&
                  value.comments.toLowerCase().includes(this.state.searchValue.toLowerCase())) ||
                (value.dates != null &&
                  value.dates.toLowerCase().includes(this.state.searchValue.toLowerCase()))
              );
            })
            let tableDataa2 = filteredData.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage);
           
            
        if(this.state.editIntent == false && this.state.selectedTab=="ACTIVE INTENTS")
        {
          
          
            tabContent = <React.Fragment>
              {
                    this.state.tableData && this.state.tableData.length>0 && 
                    <div style={{paddingLeft:"25px",paddingRight:"10px"}}>
                        <TableContainer className="table1Cont">
                            <Table aria-label="simple table" stickyHeader >
                                <TableHead className='multi-header'>
                                    <TableRow >
                                        {this.state.tableColm != undefined && this.state.tableColm !== null ? this.state.tableColm.map((headerName) => {
                                            return (
                                                <>
                                                    <TableCell className="table1H" style={{padding:"10px",backgroundColor:"#7D7D7D",color:"#ffffff"}} onClick={() => this.handleSort(headerName)}>
                                                        {headerName} {headerName != "" ? this.state.sortDirection === 'asc' ? <ArrowUpwardIcon className="iconStyle"/> : <ArrowDownwardIcon className="iconStyle"/>:''}
                                                    </TableCell>
                                                </>
                                            )
                                        }) : null}
                                        {/* <TableCell className="table1H" ></TableCell> */}
                                    </TableRow>
                                </TableHead>
                               
                                <TableBody >
                                
               

                                    {this.state.tableData != undefined && this.state.tableData !== null ?
                                        orderBy(tableDataa2, this.state.sortedColn, this.state.sortDirection).filter((item)=>{
                                            return true
                                           
                                        }).filter((value) =>{
                                         
                                            return (value.intentName != null && value.intentName.toLowerCase().includes(this.state.searchValue.toLowerCase())) ||
                                                (value.comments != null && value.comments.toLowerCase().includes(this.state.searchValue.toLowerCase())) ||
                                                (value.dates != null && value.dates.toLowerCase().includes(this.state.searchValue.toLowerCase()))
                                        }).map((item,index) => {
                                            return (
                                                <>
                                                    <TableRow key={item.id}  style={{backgroundColor:item.intentName == "unansweredbot"?"#e1e7e7":"inherit", cursor:item.intentName == "unansweredbot"?"not-allowed":"default"}}>
                                                        <LightTooltip arrow title={item.intentName != "unansweredbot"?this.props.intentDescription != null ? this.props.intentDescription[item.intentName]:item.intentName:''} placement="top-start">
                                                          <TableCell className='table1C' onClick={()=>{item.intentName != "unansweredbot" && this.onEdit(item)}}>
                                                            {item.intentName}
                                                          </TableCell>
                                                        </LightTooltip>
                                                        {/* <LightTooltip arrow title={item.comments} placement="top-start">
                                                          <TableCell className='table1C'>
                                                              {item.comments.length > 90 ? item.comments.slice(0, 90) + '...' : item.comments}
                                                          </TableCell>
                                                        </LightTooltip>  */}
                                                        {/* <LightTooltip arrow title={item.intentName != "unansweredbot"?item.dates:''} placement="top-start">                                                                       */}
                                                            <TableCell className='table1C' onClick={()=>{ item.intentName != "unansweredbot" && this.onEdit(item)}}>
                                                               {item.dates}
                                                            </TableCell>
                                                        {/* </LightTooltip> */}
                                                        
                                                        <TableCell onClick={ item.intentName != "unansweredbot"?() => this.onRowClicked(item):''} className='table1C'>
                                                            <img src='./Icons/OptionsIcon.svg' style={{cursor:item.intentName != "unansweredbot"?"pointer":"none"}}
                                                                aria-controls="simple-menu" aria-haspopup="true" onClick={item.intentName != "unansweredbot"?(e)=>this.handleClickMenu(e):''} />
                                                        </TableCell>
                                                    </TableRow>
                                                </>
                                            )
                                        }) : null}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Menu
                            anchorEl={this.state.menuShow}
                            keepMounted
                            open={Boolean(this.state.menuShow)}
                            onClose={this.handleCloseMenu}
                        >
                            <MenuItem className="IntentMenuItems" onClick={(event) => this.editRow(event)}>VIEW/EDIT</MenuItem>
                            {/* <MenuItem className="IntentMenuItems" onClick={() => {this.props.currentBotEvent(true,item.name,projectList[item.name][0]["profile"],true);
                            setSelectedBot();}}>VIEW/EDIT</MenuItem> */}
                            <MenuItem  className="IntentMenuItems" onClick={(event) => this.deleteBot(event)}>DELETE</MenuItem>
                            <MenuItem  className="IntentMenuItems" onClick={(event) => this.exportBot(event)}>EXPORT</MenuItem>
                        </Menu>
                        <TablePagination
                                rowsPerPageOptions={[5,10,15]}
                                component="div"
                                count={this.state.tableData.length}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.page}
                                onPageChange={(e,page)=>this.handleChangePage(e,page)}
                                onRowsPerPageChange={(e)=>this.handleChangeRowsPerPage(e)}
                                style={{ marginRight: '20px' }}
                        />
                    </div>
                }
            </React.Fragment>
        }
        else{
          let tableDataa22 = this.state.tableData2.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage);
          if(this.state.editIntent == false){
            tabContent = <React.Fragment>
              {
                    this.state.tableData2 && this.state.tableData2.length>0 && 
                    <div style={{paddingLeft:"25px",paddingRight:"10px"}}>
                        <TableContainer className="tableCont">
                            <Table aria-label="simple table" stickyHeader >
                                <TableHead className='multi-header'>
                                    <TableRow >
                                        {this.state.tableColm2 != undefined && this.state.tableColm2 !== null ? this.state.tableColm2.map((headerName) => {
                                            return (
                                                <>
                                                    <TableCell className="table1H" style={{backgroundColor:"#7D7D7D",color:"#ffffff"}} onClick={() => this.handleSort2(headerName)}>
                                                        {headerName} {headerName != "" ? this.state.sortDirection === 'asc' ? <ArrowUpwardIcon className="iconStyle"/> : <ArrowDownwardIcon className="iconStyle"/>:''}
                                                    </TableCell>
                                                </>
                                            )
                                        }) : null}
                                        <TableCell className="table1H" style={{backgroundColor:"#7D7D7D",color:"#ffffff"}}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody >
                                    {this.state.tableData2 != undefined && this.state.tableData2 !== null ?
                                        orderBy(tableDataa22, ['topic'], this.state.sortDirection).filter((item)=>{
                                            return true
                                        }).filter((value) =>{
                                            return (value.topic != null && value.topic.toLowerCase().includes(this.state.searchValue.toLowerCase())) ||
                                                (value.comments != null && value.comments.toLowerCase().includes(this.state.searchValue.toLowerCase())) ||
                                                (value.dates != null && value.dates.toLowerCase().includes(this.state.searchValue.toLowerCase()))
                                        }).map((item,index) => {
                                            return (
                                                <>
                                                    <TableRow key={item.id} >
                                                        <LightTooltip arrow title={item.topic} placement="top-start">
                                                          <TableCell className='table1C'>
                                                            {item.topic}
                                                          </TableCell>
                                                        </LightTooltip>
                                                        <LightTooltip arrow title={item.comment} placement="top-start">
                                                          <TableCell className='table1C'>
                                                              {item.comment && item.comment.length > 50 ? item.comment.slice(0, 50) + '...' : item.comment}
                                                          </TableCell>
                                                        </LightTooltip> 
                                                        <LightTooltip arrow title={localStorage.getItem('userName')} placement="top-start">                                                                      
                                                            <TableCell className='table1C'>
                                                                {localStorage.getItem('userName')}
                                                            </TableCell>
                                                        </LightTooltip>

                                                        <LightTooltip arrow title={item.date} placement="top-start">                                                                      
                                                            <TableCell className='table1C'>
                                                                {item.date}
                                                            </TableCell>
                                                        </LightTooltip>
                                                        
                                                        <TableCell className='table1C'>
                                                            <span onClick={()=>this.openApprovalDrawer(item)} style={{cursor:"pointer"}}><KeyboardArrowRightIcon></KeyboardArrowRightIcon></span>
                                                        </TableCell>
                                                    </TableRow>
                                                </>
                                            )
                                        }) : null}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                                rowsPerPageOptions={[5,10,15]}
                                component="div"
                                count={this.state.tableData2.length}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.page}
                                onPageChange={(e,page)=>this.handleChangePage(e,page)}
                                onRowsPerPageChange={(e)=>this.handleChangeRowsPerPage(e)}
                                style={{ marginRight: '20px' }}
                        />
                        <DrawerComponent heading="Intent Details" showDrawer={()=>this.closeApprovalDrawer()} openDrawer={this.state.openApprovalDrawer}>
                            <InputWithLabelComponent
                                type="text"
                                name="name"
                                label="Intent Name*"
                                value={this.state.intentNameInDrawer}
                                readOnly={false}
                                onChange={(e) => this.onChangesearchdays(e)}
                                required={true}
                                placeholder='Enter Text '
                                autocomplete="new-password"
                            />
                            
                            
                            <div style={{fontFamily:"Roboto-Bold",fontSize:"12px", color: "#696868", paddingTop: "10px"}}>Recommended by</div>
                            <div style={{fontFamily: 'Roboto-Regular',fontSize:"14px", color:"#696868", }}>{localStorage.getItem('userName')}</div>
                            <div style={{fontFamily:"Roboto-Bold",fontSize:"12px", color: "#696868", paddingTop: "10px"}}>Comments</div>
                            <div style={{fontFamily: 'Roboto-Regular',fontSize:"14px", color:"#696868", paddingBottom:"10px"}}>
                              {this.state.itemInDrawer && this.state.itemInDrawer.comment ? this.state.itemInDrawer.comment:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."}
                            </div>
                            <FormControl>
                                <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group" defaultValue="newIntentRadio">
                                  <FormControlLabel value="newIntentRadio" onChange={()=>this.changeRadioBtn("newIntentRadio")} control={<Radio size="small" sx={{'& .MuiSvgIcon-root': {fontSize: 14,},}}/>} label={<span style={{ fontFamily:"Roboto-Bold",fontSize:"12px", color: "#696868", }}>Add as New Intent</span>}/>
                                  <FormControlLabel value="utteranceRadio" onChange={()=>this.changeRadioBtn("utteranceRadio")} control={<Radio size="small" sx={{'& .MuiSvgIcon-root': {fontSize: 14,},}}/>} label={<span style={{ fontFamily:"Roboto-Bold",fontSize:"12px", color: "#696868", }}>Add as Utterance</span>}/>
                                </RadioGroup>
                            </FormControl>
                            {
                              this.state.changeRadioBtn == "newIntentRadio" && <div>
                                  <FormControl>
                                    <FormGroup>{
                                        this.state.itemInDrawer && this.state.itemInDrawer.utterances && this.state.itemInDrawer.utterances.length>0 && this.state.itemInDrawer.utterances.map((item,index) => {
                                          return(
                                              <FormControlLabel key={index} control={<StyledCheckbox2 color="primary"  onChange={() => this.handleCheckboxChange(index)}/>} 
                                              label={<span style={{ fontFamily:"Roboto-Regular",fontSize:"14px", color: "#696868", }}>{item}</span>} />
                                          )
                                        })
                                      }
                                      {/* <FormControlLabel control={<StyledCheckbox2 onChange='' color="primary" />} label={<span style={{ fontFamily:"Roboto-Regular",fontSize:"14px", color: "#696868", }}>Lorem Ipsum Lorem Ipsum Lorem Ipsum</span>} />
                                      <FormControlLabel control={<StyledCheckbox2 onChange='' color="primary" />} label={<span style={{ fontFamily:"Roboto-Regular",fontSize:"14px", color: "#696868", }}>Lorem Ipsum Lorem Ipsum Lorem Ipsum</span>} />
                                      <FormControlLabel control={<StyledCheckbox2 onChange='' color="primary" />} label={<span style={{ fontFamily:"Roboto-Regular",fontSize:"14px", color: "#696868", }}>Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum</span>} />
                                      <FormControlLabel control={<StyledCheckbox2 onChange='' color="primary" />} label={<span style={{ fontFamily:"Roboto-Regular",fontSize:"14px", color: "#696868", }}>Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum</span>} /> */}
                                    </FormGroup>
                                  </FormControl>
                              </div>
                            }
                            {
                              this.state.changeRadioBtn == "utteranceRadio" && <div>
                                <div style={{fontFamily:"Roboto-Bold",fontSize:"12px", color: "#696868", paddingTop: "10px"}}>Confidence for active intents list</div>
                                <div style={{fontFamily: 'Roboto-Regular',fontSize:"14px", color:"#696868", }}>40%</div>
                                <div style={{fontFamily:"Roboto-Bold",fontSize:"12px", color: "#696868", paddingTop: "10px"}}>Existing intents list</div>
                                <FormControl>
                                    <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group" defaultValue="option1">
                                      <FormControlLabel value="option1" onChange='' control={<Radio size="small" sx={{'& .MuiSvgIcon-root': {fontSize: 14,},}}/>} label={<span style={{ fontFamily:"Roboto-Bold",fontSize:"12px", color: "#696868", }}>Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum</span>}/>
                                      <FormControlLabel value="option2" onChange='' control={<Radio size="small" sx={{'& .MuiSvgIcon-root': {fontSize: 14,},}}/>} label={<span style={{ fontFamily:"Roboto-Bold",fontSize:"12px", color: "#696868", }}>Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum</span>}/>
                                    </RadioGroup>
                                </FormControl>
                              </div>
                            }
                            <div style={{marginTop:"20px"}}>
                                <button style={{cursor: "not-allowed", color:"#2A93DF",backgroundColor:"#FFF",border:"1px solid #2A93DF", width:"150px", height:"35px", borderRadius:"5px"}}>
                                    <span className='applyBtn'>REJECT</span>
                                </button>
                                <button onClick={()=>this.approveIntent()} style={{cursor:"pointer", color:"#FFF",backgroundColor:"#2A93DF", border:"none", width:"150px", height:"35px", marginLeft:"10px",borderRadius:"5px"}}>
                                    <span className='applyBtn'>APPROVE</span>
                                </button>
                                {/* <button style={{backgroundColor:"#FFFFFF",color:"#2A93DF",border:"1px solid #2A93DF",width:"150px", height:"35px", borderRadius:"5px"}}>
                                    <span className='applyBtn'>REJECT</span>
                                </button>
                                <button style={{backgroundColor:"#2A93DF",color:"#FFFFFF",border:"none",width:"150px", height:"35px", marginLeft:"10px",borderRadius:"5px"}}>
                                    <span className='applyBtn'>APPROVE</span>
                                </button> */}
                            </div>
                        </DrawerComponent>
                        
                    </div>
                }
            </React.Fragment>
          }
        }
        
        return(
          this.state.editIntent == false ? <React.Fragment >
            <Row style={{width:"100%", overflow:"hidden"}}>
                    <Col md={12} sm={12} lg={12} xlg={12}>
                        <div className='header'>Intents</div>
                    </Col>
                </Row>

                <Row className='tabPadding' style={{width:"100%", overflow:"hidden"}}>
                    <Col xs={12}>
                        <TabsComponent tabItems={this.state.tabItems}
                            selected={this.state.selectedTab}
                            setSelected={this.setSelected} className='t-69'>
                            {common}
                            {tabContent}
                        </TabsComponent>
                    </Col>
                </Row>
                <Menu id="multimediaDocMenu" anchorEl={this.state.menuShow1} keepMounted open={Boolean(this.state.menuShow1)} onClose={this.handleCloseMenu1}>
                    <MenuItem className="IntentMenuItems" >
                        <div>
                            <StyledCheckbox checked={this.state.all1} onChange={(event)=>{this.setState({all1:Boolean(event.target.checked),new:false})}} color="primary" style={{padding:"0px"}}/>
                            <label className='menuText'>All</label>
                        </div>
                    </MenuItem>
                    <MenuItem className="IntentMenuItems" >
                        <div>
                            <StyledCheckbox checked={this.state.new} onChange={(event)=>{this.setState({new:Boolean(event.target.checked),all1:false})}} color="primary" style={{padding:"0px"}}/>
                            <label className='menuText'>New</label>
                        </div>
                    </MenuItem>                  
                    <button style={{backgroundColor:"#2A93DF",color:"#FFFFFF",border:"none",width:"100px",marginLeft:"10px",borderRadius:"5px"}}>
                        <span className='applyBtn'>APPLY</span>
                    </button>
                </Menu>
          </React.Fragment> :<React.Fragment >
              
          </React.Fragment>  
        )
    }
}

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 12,
    margin:-8 -2,
  },
}))(Tooltip);

const mapStateToProps = (state) => {
    return {
      showProjectHeader:state.projectview.showProjectHeader,
      botList: state.projectview.botList,
      intentDescription: state.projectview.intentDescription,
      waitinForApprovalData: state.projectview.waitinForApprovalData,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      onKnowledgeGridExpandCollapse: (expand)  => dispatch(actions.KnowledgeGridExpandCollapse(expand)) ,
      OnProjectHeaderShow:(showProjectHeader)=>dispatch(actions.projectHeaderShow(showProjectHeader)),
      OnGetIntentDescription:(apiUrl)=>dispatch(actions.getIntentDescription(apiUrl)),
      onGetWaitingForApprovalIntent:(url)=>dispatch(actions.getWaitingForApprovalIntent(url)),
      onApproveFlow:(url,payload)=>dispatch(actions.saveApproveFlow(url,payload)),
      onApproveIntent:(url,payload)=>dispatch(actions.saveApproveIntent(url,payload)),
    };
  };
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(withErrorHandler(IntentAnalysis, axios));