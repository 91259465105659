import {
    TRIAGE_CONFIGURATION_CHANGE_HANDLER_ACTION,
    TRIAGE_CONFIGURATION_UPDATE_HANDLER_ACTION,
    GET_SYMPTOMS_DATA,
    GET_ROOTCAUSES_DATA
} from "./adminSettingsActionTypes";

export const triageConfigurationChangeHandlerAction = (payload) => ({
    type: TRIAGE_CONFIGURATION_CHANGE_HANDLER_ACTION,
    payload
});

export const triageConfigurationupdateHandlerAction = (payload) => ({
    type: TRIAGE_CONFIGURATION_UPDATE_HANDLER_ACTION,
    payload
});

export const getSymptomsDataAction = (payload) => ({
    type: GET_SYMPTOMS_DATA,
    payload
});

export const getRootCausesDataAction = (payload) => ({
    type: GET_ROOTCAUSES_DATA,
    payload
});