import React, { useEffect, useState } from 'react';
import { Row, Col, Form } from "react-bootstrap";
import InputWithLabelComponent from "../../../SettingPage/SettingInnerPages/components/InputWithLabelComponent/InputWithLabelComponent";
import TextAreaWithLabelComponent from "../../../SettingPage/SettingInnerPages/components/TextAreaWithLabelComponent/TextAreaWithLabelComponent";
import ButtonCustom from "../../../../components/UIComponents/Button/ButtonComponent";

const CarouselNodeDetails = ({node, handleSubmit, variable, invalidVarMsg}) => {

    let emptyCarouselBody = [{title:'',body:'',selectionValue:'',imageUrl:''}];
    let defaultBtn = {btnText:'',displayText:'',url:''};
    const [carouselData, setCarouselData] = useState(node.data?.carousel?node.data.carousel:emptyCarouselBody);
    const [showMoreBtn, setShowMoreBtn] = useState(node.data?.showMoreBtn);
    const [maxBodyLength, setMaxBodyLength] = useState(node.data.maxBodyLength===null?0:node.data.maxBodyLength);
    const [carouselReferenceCheck, setCarouselReferenceCheck] = useState(node.data?.carouselReferenceCheck?node.data.carouselReferenceCheck :false);
    const [buttonDataList, setButtonDataList] = useState(node.data?.buttonDataList?node.data.buttonDataList:[]);
    const [radioBtn, setRadioBtn] = useState();
    const [referenceVariable, setReferenceVariable] = useState(node.data.referenceVariable);
    const [disableTextbox, setDisableTextbox] = useState(node.data?.disableTextbox!==undefined?node.data.disableTextbox:true);
    const [addToLog, setAddToLog] = useState(node.data.addToLog!==undefined? node.data.addToLog:true);

    useEffect(()=> {
        if(referenceVariable !== undefined && referenceVariable !== null) 
            setRadioBtn('passByVar')
        else 
            setRadioBtn('manual')
    
    },[]);

    useEffect(()=> {
    if(radioBtn === 'passByVar') {
        setCarouselReferenceCheck(true);
    } else {
        setCarouselReferenceCheck(false);
    }
    },[radioBtn]);

    const updateCarouselCount = () => {
       setCarouselData((prev)=>[...prev,{title:'',body:'',selectionValue:'',imageUrl:''}]);
    }
    
    const updateButtonCount = () => {
        setButtonDataList((prev)=>[...prev,defaultBtn]);
    }

    const handleOptionChange = (e) => {
        setRadioBtn(e.target.value);
        setReferenceVariable(null);
      }

    function reduceCount(index,type) {
        switch(type) {
            case 'buttonList': setButtonDataList(buttonDataList.filter((_, i) => i !== index)); break;
            case 'carouselData': setCarouselData(carouselData.filter((_, i) => i !== index)); break;
        }
    }

    const updateButtonDetails = (index, key, value) => {
        let tempArray = [...buttonDataList];
        tempArray[index] = {...tempArray[index], [key]:value};
        setButtonDataList(tempArray);
    }

    function carouselUpdate(index, key, value) {
        let tempArray = [...carouselData];
        tempArray[index] = {...tempArray[index], [key]:value};
        setCarouselData(tempArray);    
    }

    function renderCarouselInputs(index, item) {
        return (
          <div key={'carouselInput'+index}>
              <Row>
                <Col md={12}>
                    <InputWithLabelComponent
                            type="text"
                            name={item.title}
                            label="Title"
                            value={item.title}
                            readOnly={false}
                            onChange={(e) => carouselUpdate(index,'title',e.target.value)}
                            required={false}
                            placeholder='Title'
                      />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                <TextAreaWithLabelComponent 
                            label="Body"
                            name="carouselbody"
                            required ={false}
                            value={item.body}
                            className='text-area'
                            placeholder='Carousel body.'
                            onChange={(e) => carouselUpdate(index,'body',e.target.value)}
                            />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                <InputWithLabelComponent
                            type="text"
                            name={item.selectionValue}
                            label="Selection Value*"
                            value={item.selectionValue}
                            readOnly={false}
                            onChange={(e) => carouselUpdate(index,'selectionValue',e.target.value)}
                            required={false}
                            placeholder='Selection Value'
                      />
                </Col>
              </Row>
              <Row>
                <Col md={radioBtn==='manual'?11:12}>
                    <InputWithLabelComponent
                            type="text"
                            name={item.imageUrl}
                            label="Image URL"
                            value={item.imageUrl}
                            readOnly={false}
                            onChange={(e) => carouselUpdate(index,'imageUrl',e.target.value)}
                            required={false}
                            placeholder='URL value'
                      />
                </Col>
                {radioBtn==='manual' && (
                  <Col md={1} className='minus-card-button'>
                    <div onClick={()=>reduceCount(index,'carouselData')}>
                      <img className='card-more-btn' src="./Icons/removeCircleBlack.svg"/>    
                    </div>   
                  </Col>
                )}            
              </Row>
          </div>
        )
    }
    
    function renderButtonInputs(index, item) { 
    return (
        <div id='optionsDiv' className='backgroundBox' key={'buttonInput'+index}>
        <div className="mt-1">
        <Col md={12}>
        <Row>
            <Col md={2}>
            <Form.Label className="mb-1 text-box-title adding-other-input">{'Button '.concat(index+1) }</Form.Label>
            </Col>
            <Col md={1} className='countBtn-col minusBtn-align'>
                <div onClick={()=>reduceCount(index,'buttonList')}>
                    <img className='btn-more-btn removeBtn-img' src="./Icons/removeCircleBlack.svg"/>  
                </div>    
            </Col>
        </Row> 
        <Row>
            <Col md={12}>
            <div className="mt-1">
                <InputWithLabelComponent
                    type="text"
                    name={item.btnText}
                    showLabel={false}
                    value={item.btnText}
                    readOnly={false}
                    onChange={(e) => updateButtonDetails(index,'btnText',e.target.value)}
                    required={true}
                />
            </div>
            </Col>
            <Col md={12}>
            <div className="mt-1">
                <div><Form.Label className="mb-1 text-box-title adding-other-input">Actions to perform</Form.Label></div>
                <Row>
                <Col md={6}>
                    <InputWithLabelComponent
                        type="text"
                        name={item.displayText}
                        showLabel={false}
                        value={item.displayText}
                        readOnly={false}
                        onChange={(e) => updateButtonDetails(index,'displayText',e.target.value)}
                        required={true}
                        placeHolder='Display Text'
                    />
                </Col>
                <Col md={6}>
                    <InputWithLabelComponent
                        type="text"
                        name={item.url}
                        showLabel={false}
                        value={item.url}
                        readOnly={false}
                        onChange={(e) => updateButtonDetails(index,'url',e.target.value)}
                        required={true}
                        placeHolder='URL'
                    />
                </Col>
                </Row>
            </div>
            </Col>
        </Row>
        </Col>
        </div>
        </div>
    )
    }

    const saveData = () => {
        let data = {
            carouselData: carouselData,
            showMoreBtn: showMoreBtn===''?undefined:showMoreBtn,
            maxBodyLength:maxBodyLength,
            carouselReferenceCheck: carouselReferenceCheck,
            referenceVariable: referenceVariable,
            buttonDataList: buttonDataList.length === 0 ? undefined:buttonDataList,
            disableTextbox: disableTextbox,
            addToLog: addToLog
        }
        handleSubmit('carousel',data);
    }

    const isFormValid = () => {
        let validStatus = null;
        if(radioBtn === 'manual') {
            let valuePresent = '*';
            carouselData.forEach((data) => {
                valuePresent = valuePresent && data.selectionValue;
            });
            validStatus = valuePresent;
        } else {
            validStatus = referenceVariable && carouselData[0].selectionValue;
        } 
        return validStatus && variable && !invalidVarMsg;
    }

    return (
        <>
            <div className="mt-1">
            <Col md={12}>
            <div>
                <input type="radio" id="manual" name="inputMethod" value="manual" checked={radioBtn === 'manual'} onChange={handleOptionChange}/>
                <label >Manual</label>
                <input type="radio" id="passByVar" name="inputMethod" value="passByVar" checked={radioBtn === 'passByVar'} onChange={handleOptionChange}/>
                <label >Reference</label><br/>
            </div>
            </Col>
            </div>

            {radioBtn === 'manual' && (
            <div className="mt-1">
                <Col md={12}>
                <Row className="FormLabel-row">
                    <Col md={3} className="FormLabel-col card-label">
                    <Form.Label className="mb-1 text-box-title adding-other-input">Card details</Form.Label>
                    </Col>
                    <Col md={1} className='countBtn-col plusBtn-align'>
                    <div onClick={updateCarouselCount}>
                        <img className='addBtn-img' src="./Icons/AddBlackFilled.svg"/>
                    </div>    
                    </Col>
                </Row>
            </Col>
            <Col md={12}>
                {carouselData.map((item, index)=> renderCarouselInputs(index,item))}
            </Col>
            </div>
            )}

            {radioBtn === 'passByVar' && (
            <>
            <div className="mt-1">
            <Col md={12}>
            <InputWithLabelComponent
                    type="text"
                    name="label"
                    label="Reference Variable*"
                    value={referenceVariable}
                    readOnly={false}
                    onChange={(e) => setReferenceVariable(e.target.value)}
                    required={true}
                />
            </Col>
            </div>
            <div className="mt-1">
            <Col md={12}>
                {carouselData.map((item, index)=> renderCarouselInputs(index,item))}
            </Col>
            </div>
            </>
            )}

            <div className="mt-1">
              <Col md={12}>
                <Form>
                    <Form.Check 
                    type="checkbox"
                    id={`disable-textbox`+variable}
                    label='Disable Textbox'
                    checked = {disableTextbox}
                    onChange={e=>setDisableTextbox(e.target.checked)}
                  />
                </Form>
              </Col>
            </div>

            <div className="mt-1"  id="checkInCdNode">
                <Col md={12}>
                <Form>
                    <Form.Check 
                    type="checkbox"
                    id={`addToLog`+variable}
                    label='Capture as conversation log'
                    checked = {addToLog}
                    onChange={e=>setAddToLog(e.target.checked)}
                    />
                </Form>
                </Col>
            </div>

            <div className="mt-1">
            <Col md={12}>
            <InputWithLabelComponent
                    type="text"
                    name="label"
                    label="Maximum character length of content to be displayed"
                    value={maxBodyLength}
                    readOnly={false}
                    onChange={(e) => setMaxBodyLength(e.target.value)}
                    required={false}
                />
            </Col>
            </div>

            <div className="mt-1">
            <Col md={12}>
            <InputWithLabelComponent
                    type="text"
                    name="label"
                    label="Show more Button Text"
                    value={showMoreBtn}
                    readOnly={false}
                    onChange={(e) => setShowMoreBtn(e.target.value)}
                    required={true}
                />
            </Col>
            </div>

            <div className="mt-1">
            <Col md={12}>
            <Row className="FormLabel-row">
                <Col md={4} className="FormLabel-col add-btn-label">
                <Form.Label className="mb-1 text-box-title adding-other-input">Add buttons</Form.Label>
                </Col>
                <Col md={1} className='countBtn-col plusBtn-align'>
                <div onClick={updateButtonCount}>
                    <img className='addBtn-img' src="./Icons/AddBlackFilled.svg"/>
                </div>    
                </Col>
            </Row>
            </Col>
            </div>
            {buttonDataList.map((item, index)=>{ return renderButtonInputs(index,item)})}
            
            <br/>
            <div className="mt-1">
            <Col md={12}>
                <ButtonCustom variant='contained' label='Save'className='buttonWidth' disabled={!isFormValid()} clicked={saveData}/>
            </Col>
            </div>

        </>
    )
}

export default CarouselNodeDetails;