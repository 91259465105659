// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drawerClass .MuiDrawer-paperAnchorRight{
    max-width:43.92vw;
    min-width:43.92vw;
}

.drawerClass .Symtom-Item {
    /* padding: 0.25rem 3rem; */
    padding: 4px 48px;
}

.drawer-width .Symtom-Item {
    padding: 4px 48px;
}

.categgory-container{
    position: relative;
}

.categgory-container .add-icon{
    position: absolute;
    right: -30px;
    top: 35px;
}

.question-div div{
    margin-top: 5px;
    width: 45%;
}
.error-css {
    height: 20px;
}

.header-mb {
    margin-bottom: -8px;
    padding-right: 1%;
}

.header-col {
    padding-left: 0px;
}

.header-row {
    padding-left: 24px;
    margin-right: 48px;
    margin-bottom: 16px;
}

.drawerSubhead {
    font-family: 'Roboto-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
    margin-top: 4px;
    margin-right: 5%;
    /* position: relative;
    right: 4px; */
}

.cross-img {
    margin-left: 3px;
}

.mw-52 {
    max-width: 52px;
}
`, "",{"version":3,"sources":["webpack://./src/components/UIComponents/DrawerWithSubHeading/DrawerWithSubHeading.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,2BAA2B;IAC3B,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,SAAS;AACb;;AAEA;IACI,eAAe;IACf,UAAU;AACd;AACA;IACI,YAAY;AAChB;;AAEA;IACI,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,6BAA6B;IAC7B,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB;iBACa;AACjB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".drawerClass .MuiDrawer-paperAnchorRight{\n    max-width:43.92vw;\n    min-width:43.92vw;\n}\n\n.drawerClass .Symtom-Item {\n    /* padding: 0.25rem 3rem; */\n    padding: 4px 48px;\n}\n\n.drawer-width .Symtom-Item {\n    padding: 4px 48px;\n}\n\n.categgory-container{\n    position: relative;\n}\n\n.categgory-container .add-icon{\n    position: absolute;\n    right: -30px;\n    top: 35px;\n}\n\n.question-div div{\n    margin-top: 5px;\n    width: 45%;\n}\n.error-css {\n    height: 20px;\n}\n\n.header-mb {\n    margin-bottom: -8px;\n    padding-right: 1%;\n}\n\n.header-col {\n    padding-left: 0px;\n}\n\n.header-row {\n    padding-left: 24px;\n    margin-right: 48px;\n    margin-bottom: 16px;\n}\n\n.drawerSubhead {\n    font-family: 'Roboto-Regular';\n    font-style: normal;\n    font-weight: 400;\n    font-size: 12px;\n    line-height: 14px;\n    color: #000000;\n    margin-top: 4px;\n    margin-right: 5%;\n    /* position: relative;\n    right: 4px; */\n}\n\n.cross-img {\n    margin-left: 3px;\n}\n\n.mw-52 {\n    max-width: 52px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
