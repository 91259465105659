import get from 'lodash/get';
import { GetCalltoServer } from '../../../utility';
import { issueClarificationOptinalFieldChangeHandlerAction, similarIssuesOptinalFieldChangeHandlerAction } from '../insightsActions';
import fetchInsightsDataFailureAction from './fetchInsightsDataFailureAction';
import fetchInsightsDataStartAction from './fetchInsightsDataStartAction';
import fetchInsightsDataSuccesAction from './fetchInsightsDataSuccesAction';

const fetchInsightsDataAction = (payload,proName) => (dispatch) => {
    const url =
    payload.apiUrl + "/product-intelligent-triage/settings/insightsconfiguration/project/"+proName;   
    let data = {};
    dispatch(fetchInsightsDataStartAction());
    GetCalltoServer(url)
        .then(response => {
            if (get(response, 'data.status.responseType', '') === "success") {
                console.log(data)
                console.log(response.data)
               if(response.data.data != null){
                   data = JSON.parse(response.data.data)
               }
               if(!('similartickets' in data)) data.similartickets = {}
               if(!('kbdocs' in data)) data.kbdocs = {}
               if(!('questionaries' in data)) data.questionaries = {}
               if(!('topresolutions' in data)) data.topresolutions = {}
               if(!('predictedissues' in data)) data.predictedissues = {}
               if(!('issueclassification' in data)) data.issueclassification = {}
               if(!('knowledgeArticles' in data )) data.knowledgeArticles={}
                dispatch(fetchInsightsDataSuccesAction(data));
                dispatch(similarIssuesOptinalFieldChangeHandlerAction(
                    {
                        isDefault: true,
                        data: data
                    }
                ));
                dispatch(issueClarificationOptinalFieldChangeHandlerAction(
                    {
                        isDefault: true,
                        data: data
                    }
                ))
            }
            else {
                dispatch(fetchInsightsDataFailureAction(response.data.status.responseType))
            }
        }).catch(err => {
            dispatch(fetchInsightsDataFailureAction(err.message))
        });
};

export default fetchInsightsDataAction;