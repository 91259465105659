import React, { useState, useEffect } from 'react';
import { Col,Row ,Container, Image, Dropdown} from 'react-bootstrap';
import { GetCuxTriggerList, DeleteCuxTrigger } from '../../../store/actions';
import { useDispatch, useSelector } from "react-redux";
import './conversationalUI.css';
import TriggerDrawer from '../../../components/UI/Drawer/TriggerDrawer';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import DeleteModal from "../../../components/UIComponents/DeleteModalComponent/DeleteModal";

const useStyles = makeStyles((theme) => ({
    root: {
      ".MuiCard-root":{
        overflow:"visible"
      },
      minHeight: "100vh",
      backgroundColor: "#F2F1F1 !important",
      [theme.breakpoints.up("sm")]: {
        backgroundColor: "#F2F1F1",
      },
      [theme.breakpoints.down("md")]: {
        backgroundColor: "#F2F1F1",
      },
      [theme.breakpoints.up("1700")]: {
        position: "relative",
        left: "18px",
        width: "97.5vw",
      },
    },
    mainCardContainer: {
      padding: "0px 0px",
      margin: "0px 20px",
    },
    card: {
      maxWidth: "100%",
      margin: "10px 10px",
      cursor: "pointer",
    },
    paraBox: {
      height: "60px",
      width:'19vw',
    },
    DocBox:{
      paddingTop:'8px',
    }
  }));

const CDTrigger = ({config, intentList, flowid}) => {

    const classes = useStyles();
    const dispatch = useDispatch(); 
    const [drawer, setDrawer] = useState();
    const [addDrawer, setAddDrawer] = useState();
    const [triggerData, setTriggerData] = useState();
    const [selectedId, setSelectedId] = useState();
    const [deleteModal, setDeleteModal] = useState(false);

    const triggerList = useSelector(state => {
    return state.projectview.CuxTriggerList;
    });

    useEffect(()=>{
        getTriggers();            
    },[flowid]);

    const getTriggers = () => {
        dispatch(GetCuxTriggerList(config.COMMON_API_URL, flowid));
    }

    const deleteData = (id) => {
      setSelectedId(id);
      setDeleteModal(true);      
    }

    const deleteTrigger = (e) => {
      e.preventDefault();
      dispatch(DeleteCuxTrigger(config.COMMON_API_URL,flowid,selectedId));
      setDeleteModal(false);
    }
 
    const addTrigger = (e) => {
        e.preventDefault();
        setAddDrawer(true);
        setDrawer(true);
    }

    const editTrigger = (id) => {
        setAddDrawer(false);
        setDrawer(true);
        setTriggerData(triggerList.find(e=>e.triggerid===id));
    }

    const getEntityNodeCount = () => {
        return intentData.triggercontent.entityvariablemapping ? intentData.triggercontent.entityvariablemapping.length : 0;
    }

    const setTriggerCard = (data) => {
      if(data.triggertype==='intent') {
        return createIntentCard(data);
      } else if(data.triggertype==='timer') {
        return createTimerCard(data);
      }
    }

    let intentData = null;
    if(triggerList !== null) {
        intentData = triggerList.find(e=>e.triggertype==='intent');   
    }

    const createIntentCard = (intentCardData) => {
      return (
        <Card
            p={1}
            className="trigger-card"
          >
            <CardContent>
              <Row className='card-title-row'>
              <span className="ProjectTitle">Intent based - {intentCardData.triggercontent.intentname}</span>
                <span>
                <Dropdown className='trigger-dropdown'>
                  <Dropdown.Toggle className='cux-dropdown-toggle'>
                    <img src="./images/CDIcons/triggerDots.svg"/>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className='trigger-dropdown-menu'> 
                    <Dropdown.Item className="cux-sideDropdown"
                      eventKey="Edit" key="Edit"
                      onClick={() => editTrigger(intentCardData.triggerid)}
                    >Edit</Dropdown.Item>
                    <Dropdown.Item className="cux-sideDropdown"
                      eventKey="Delete" key="Delete"
                      onClick={() => deleteData(intentCardData.triggerid)}
                    >Delete</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> 
                </span> 

              </Row>
                
            {/* //    <Box pt={1} className={classes.authorContent}>
            //           <Typography  color="textSecondary">
            //                <span className='subTitles'>Created by Lorem</span> 
            //           </Typography>
            //           <Typography  color="textSecondary">
            //                <span className='subTitles'>Last modified 2hrs ago</span>
            //           </Typography>
            //           </Box>  */}
              <Box pb={1} className={classes.paraBox}>
                <span className="trigger-cardParagraph">
                  Intent based trigger, which connects flows to specific intents and its entities.
                </span>
              </Box>
              <Box className={classes.DocBox}>
                <span className="CardFooter">
                  {getEntityNodeCount()} Entity Node Mapping
                </span>
              </Box>
            </CardContent>
          </Card>
      )
    }
            
    function getTime (time) {
      let arr = time.split(':');
      let suffix = ' AM';
      
      if(arr[0]>12) {
        time = time.replace(arr[0],arr[0]-12);
      }
      if(arr[0] >= 12) suffix = ' PM'; 
      
      return time.substring(0,5)+suffix;
    }
        
    const createTimerCard = (timerCardData) => {
      let data = timerCardData.triggercontent;
      return(
        <Card
          p={1}
          className="trigger-card"
        >
        <CardContent>
        <Row className='card-title-row'>
              <span className="ProjectTitle">Timer based</span>
                <span>
                <Dropdown className='trigger-dropdown'>
                  <Dropdown.Toggle className='cux-dropdown-toggle'>
                    <img src="./images/CDIcons/triggerDots.svg"/>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className='trigger-dropdown-menu'> 
                    <Dropdown.Item className="cux-sideDropdown"
                      eventKey="Edit" key="Edit"
                      onClick={() => editTrigger(timerCardData.triggerid)}
                    >Edit</Dropdown.Item>
                    <Dropdown.Item className="cux-sideDropdown"
                      eventKey="Delete" key="Delete"
                      onClick={() => deleteData(timerCardData.triggerid)}
                    >Delete</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> 
                </span> 

              </Row>
            {/* <span className="ProjectTitle">Time based</span> */}
        {/* //    <Box pt={1} className={classes.authorContent}>
        //           <Typography  color="textSecondary">
        //                <span className='subTitles'>Created by Lorem</span> 
        //           </Typography>
        //           <Typography  color="textSecondary">
        //                <span className='subTitles'>Last modified 2hrs ago</span>
        //           </Typography>
        //           </Box>  */}
            <Box pb={1} className={classes.paraBox}>
            <span className="trigger-cardParagraph">
                Runs triggers periodically as per the configuration.
            </span>
            </Box>
            <Box className={classes.DocBox}>
            <span className="CardFooter timer-cardFooter">
                {/* Start date &nbsp;:&nbsp; {1}{" "} Documents */}
                <div className='footer-row'>
                  <div className='footer-data'>{'Start date: '+data.startdate }</div>
                  <div className='footer-data trigger-frequency'>{data.frequency+' @ '+getTime(data.executiontime)}</div>
                </div>
                <div>{'End date: '+data.enddate}</div>
            </span>
            </Box>
        </CardContent>
        </Card>

    )};

    const getTrigggerCount = () => {
      return triggerList?triggerList.length:0;
    }

    return (
        <div className='trigger-list-container'>
            {triggerList !== null ? (
                <>
                <Row>
                <h3 className=""  >
                    {getTrigggerCount()} <span>Triggers are available!</span>
                    <span className='add-trigger-span'> <Image
                        src="./Icons/AddBlackFilled.svg"
                        onClick={addTrigger}
                        className='add-trigger-btn'
                    /></span>
                    </h3>
                </Row> 

                <Row>
                  {triggerList.map((data,i)=>(
                    <Col md={4} key={i}>{setTriggerCard(data)}</Col>
                  ))}
                  {/* <Col md={4}></Col> */}
                    
                </Row>
                </>
            ) : (
                <>
                    <div className='empty-trigger-list'>
                        <h1><span className='no-trigger-text'>No Triggers are added</span></h1>
                        <h2><span className='add-trigger-text' onClick={addTrigger}>Add Trigger</span></h2>
                    </div>
                </>
            )}
            
          {drawer? <TriggerDrawer show={drawer} showDrawer={setDrawer} flowid={flowid} 
                      intentList={intentList} config={config} addTriggerDrawer={addDrawer}
                      triggerData={triggerData}></TriggerDrawer>
          : null}

          <DeleteModal
            show={deleteModal}
            deleteModal={() =>setDeleteModal(false)}
            title="Delete Confirmation"
            bodyTitle="Are you sure you want to delete the trigger?"
            buttonClick={(e) => deleteTrigger(e)}
          />
        </div>
    );
}

export default CDTrigger;