import React from 'react';
import { Form } from "react-bootstrap";
import * as PropTypes from 'prop-types';
import "./ScheduleMeeting.css";

const TextAreaWithLabelComponent = ({
    label,
    nameValue,
    value,
    className,
    placeHolder,
    onChange,
    isInvalid,
    readOnly,
    ...props
}) => {
    return (
      <div>
        <Form.Label className="mb-1 text-box-title">
          {label}
        </Form.Label>
        <Form.Control
          as="textarea"
          name={nameValue}
          required
          value={value}
          
          className={"default-css" + " " + className }
          placeholder={placeHolder}
          onChange={onChange}
          readOnly={readOnly}
          isInvalid={isInvalid}
          {...props}
        />
      </div>
    );
};

TextAreaWithLabelComponent.prototype = {
  label:PropTypes.string,
  name:PropTypes.string,
  className:PropTypes.string,
  defaultValue:PropTypes.string,
  placeHolder:PropTypes.string,
  readOnly:PropTypes.bool,
  required:PropTypes.bool
}

TextAreaWithLabelComponent.defaultProps ={
  label:"TextArea Label",
  name:"TextArea-class",
  defaultValue:"",
  className:"",
  placeHolder:"Enter Text Here",
  readOnly:false,
  required:false,
}
export default TextAreaWithLabelComponent;