const symptomDetailsUpdateHandlerReducer = (state, payload) => {
    const symptomData = state.symptomsTabData.symptomData;
    const updatedData = symptomData.map((item, index) => {
        if (index === payload.id) {
            return {
                ...item,
                ...payload.formData
            };
        }
        return item;
    });
    return {
        ...state,
        symptomsTabData: {
            ...state.symptomsTabData,
            symptomData: updatedData
        }
    }
}

export default symptomDetailsUpdateHandlerReducer;