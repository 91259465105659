import React from 'react';
import { Modal } from 'react-bootstrap';
import CloseIcon from "@material-ui/icons/Close";
import ButtonCustom from '../Button/ButtonComponent';
import './DeleteModal.css';

const DeleteModal = (props) => {

  return (
    <>
      <Modal size={props.size} className='modalClass' show={props.show}
        onHide={() => props.deleteModal(false)}>
        <div className={props.modalContent}>
          <Modal.Header>
            <Modal.Title className='headerText'>{props.title}</Modal.Title>
            <CloseIcon
              className="closeIcon"
              onClick={() => props.deleteModal(false)}
            />
          </Modal.Header>
          <Modal.Body className={props.ModalBodyClass}>
            <div className='buttonSpace'>
              <span>
                {props.bodyTitle}
              </span>
            </div>
            <div>
              <ButtonCustom variant='outlined' label='No' className='noButton'
                clicked={() => props.deleteModal(false)} />
              <span className='buttonGap'></span>
              <ButtonCustom variant='contained' label='Yes' className='yesButton'
                clicked={(e) => props.buttonClick(e)} />
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>

  );


}

export default DeleteModal;