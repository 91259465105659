import React, {useState} from 'react';
import InputWithLabelComponent from '../../components/InputWithLabelComponent/InputWithLabelComponent';
import ColorPickerComponent from '../../components/ColorPicker/ColorPickerComponent';
import SelectComponent from '../../../../../components/UIComponents/SelectDropdown/SelectComponent';
import { Form, Col, Row, Dropdown } from 'react-bootstrap';
import { Switch } from '@material-ui/core';
import ImageGenerator from './ImageGenerator';
import RoutingComponent from '../RoutingComponent/RoutingComponent';
import * as lists from '../Constants';


const MenuOptions = ({data, onChangeHandler}) => {

    //will be used in upcoming requirements
    const [invalidImageFile, setInvalidImageFile] = useState(false);
    const [displayImg, setDisplayImg] = useState(<span>Click to select image file</span>);
    let DeleteIcon = '';

    const routeTypes  =[{ value: 'Flow', displayValue: 'Flow' },
            { value: 'URL', displayValue: 'URL' }];

    const deleteMenuItem = (index) => {
        let menuItems = data.menuItems;
        onChangeHandler('menuItems',menuItems.filter((_,i)=>i !== index));
    }

    const deleteSubMenuItem = (menuIndex, index) => {
        let tempMenu = [...data.menuItems];
        let subMenuItems = tempMenu[menuIndex].subMenu;
        let updatedSubMenu = subMenuItems.filter((_,i)=>i !== index);
        tempMenu[menuIndex] = {...tempMenu[menuIndex], ['subMenu']:updatedSubMenu};
        onChangeHandler('menuItems',tempMenu);
    }

    const onAddCategory = (type, index) => {
        let defaultMenu = {text:'', routeType:'Flow', routeData:'', textColor:'#000000', textSize:'12px', subMenu:[]};
        let defaultSubMenu = {text:'', routeType:'Flow', routeData:'', textColor:'#000000', textSize:'10px'};
        let menuItems = data.menuItems;
        if(type === 'category') {
            menuItems.push(defaultMenu);
        } else {
            menuItems[index].subMenu.push(defaultSubMenu);
        }
        onChangeHandler('menuItems',menuItems);
    }

    const onMenuUpdate = (key,value,category,index, menuIndex) => {
        let tempArray = [...data.menuItems];
        if(category === 'Menu') {
            tempArray[index] = {...tempArray[index], [key]:value};
        } else {
            let tempSubArray =  [...tempArray[menuIndex].subMenu];
            tempSubArray[index] = {...tempSubArray[index], [key]:value};
            tempArray[menuIndex] = {...tempArray[menuIndex], ['subMenu']:tempSubArray};
            
        }
        onChangeHandler('menuItems',tempArray);
        
    }

    function renderOptions(index,item,type, menuIndex) {
        return (
            <>
            <Col md='2' className='menu-col'>
                <InputWithLabelComponent
                    type="text"
                    placeHolder=""
                    name={type+"text"+index}
                    label={type+' '+(index+1)+' Text'}
                    value={item.text}
                    readOnly={false}
                    onChange={(e) => onMenuUpdate('text',e.target.value,type,index, menuIndex)}
                    required={false}
                    // maxLength={25}
                />
            </Col>
            <Col md='2' className='menu-col'>
                <RoutingComponent
                    type="color"
                    name={type+"routingLink"+index}
                    label="Routing link"
                    routeType={item.routeType}
                    routeData={item.routeData}
                    routeTypeChange={(e) => onMenuUpdate('routeType', e.target.value,type,index, menuIndex)}
                    routeDataChange={(e) => onMenuUpdate('routeData',e.target.value,type,index, menuIndex)}
                    routeTypes={routeTypes}
                    readOnly={false}
                    required={true}
                />
            </Col>
            <Col md='2' className='menu-col'>
                <ColorPickerComponent
                    type="color"
                    name={type+"textColor"+index}
                    label="Text Color"
                    value={item.textColor}
                    readOnly={true}
                    onChange={(e) => onMenuUpdate('textColor',e.target.value,type,index, menuIndex)}
                    required={false}
                />
            </Col>
            <Col md='1' className='menu-col'>
                <SelectComponent
                    name={type+"textSize"+index}
                    label="Size"
                    className="selectCompCol2 mt-7"
                    changed={(e) => onMenuUpdate('textSize',e.target.value,type,index, menuIndex)}
                    required={true}
                    value={item.textSize}
                    options={lists.fontSizes}
                    disabled={true}
                />
            </Col>
            </>
        )
    }

    function renderSubMenuItems(index, subMenu, menuIndex) {
        return (
            <Row className='pl-9' key={'submenu-'+index}>
                {renderOptions(index, subMenu, 'SubMenu', menuIndex)}
                <Col md='1' className='menu-col menuActions'>
                <div className='mr-15 pointer' onClick={()=>deleteSubMenuItem(menuIndex, index)}>
                    <img src='./Icons/DeleteBlack.svg'/>
                </div>
                </Col>
            </Row>
        )
    }

    function renderMenuItems(index, menu) {

        return (
            <div className={menu.subMenu.length>0?'activeBorder':'noBorder'}>
            <Row className='pl-9' key={'menu-'+index}>
                {renderOptions(index, menu, 'Menu')}
            <Col md='1' className='menu-col menuActions'>
                <div className='mr-15 pointer' onClick={()=>deleteMenuItem(index)}>
                    <img src='./Icons/DeleteBlack.svg'/>
                </div>
                <Dropdown className='addOptions'>
                    <Dropdown.Toggle className='cux-dropdown-toggle'>
                        <img src="./Icons/plus-icon.svg"/>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className='node-dropdown-menu'> 
                        <Dropdown.Item className="cux-sideDropdown"
                        eventKey="category" key="category"
                        onClick={() => onAddCategory('category')}
                        >Add a category</Dropdown.Item>
                        <Dropdown.Item className="cux-sideDropdown"
                        eventKey="subCategory" key="subCategory"
                        onClick={() => onAddCategory('subCategory', index)}
                        >Add a sub-category</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown> 
                {/* <div className='pointer' onClick={()=>addMenuCategory()}>
                    <img src='./Icons/plus-icon.svg'/>
                </div> */}
                
            </Col>
            </Row>
            {data.menuItems[index].subMenu.map((item, subIndex) => renderSubMenuItems(subIndex, item, index))}
            </div>
        )
    }

    return (
        <div>
        <Row className='mt-2'>
            <Col md='1' className='al-ctr'>
            <Form.Label className="mb-1 text-box-title switch-label">Menu Bar</Form.Label>
            </Col>
            <Col md='1'>
            <Switch
                className='switchMain'
                checked={data.isMenuVisible}
                onChange={(e) => onChangeHandler('isMenuVisible', e.target.checked)}
                color="primary"
                name="displayCheck"
                inputProps={{ 'aria-label': 'primary checkbox' }}
                disableRipple
            />
            </Col>
            <Col md='2' className='al-ctr'>
                <Form.Label className="mb-1 text-box-title switch-label">Display menu banner</Form.Label>
            </Col>
            <Col md='1'>
            <Switch
                className='switchMain'
                checked={false}
                // checked={data.isBannerVisible}
                // onChange={(e) => onChangeHandler('isBannerVisible',e.target.checked)}
                color="primary"
                name="displayCheck"
                inputProps={{ 'aria-label': 'primary checkbox' }}
                disableRipple
                // disabled={true}
            />
            </Col>
        </Row>
        {data.isBannerVisible && (
        <div id='menuOptions'>
            <Row className='mt-2'>
                <Col md={7} className="headerInput pl-0">
                    <Form.Label className="mb-1 text-box-title adding-other-input labelText">Banner Image</Form.Label>
                    <section className="imgContainer">
                        <label className="fileIinput">
                        {displayImg}
                            <input
                                name="bannerImg"
                                type="file"
                                // onChange={(e) => this.onChangeHandler(e)}
                                accept='image/svg+xml,image/png'
                                // ref="upload"
                                id="upload"
                                // onClick={(event)=> {  event.target.value = null  }}
                            />
                                {DeleteIcon}
                        </label>
                    </section>
                    <div className='contentView'>
                        <img src="./images/info_icon.png" />
                        <span>File format supported is .svg, Same image will be used in Bot response</span>
                        <div className='selectedFile'>{invalidImageFile === true ? "Uploading of image failed as the file format is not supported." : ''}</div>
                    </div>
                </Col>
            </Row>
        </div>
        )}
        {data.isMenuVisible && (
        <div>
            <Row>
            <Col md='2'>
                <ColorPickerComponent
                    type="color"
                    name="menu-background-color"
                    label="Background Color"
                    value={data.bgColor}
                    readOnly={true}
                    onChange={(e) => onChangeHandler('bgColor', e.target.value)}
                    required={false}
                />
            </Col>
            <Col md='2'>
                <ColorPickerComponent
                    type="color"
                    name="iconColor"
                    label="Icon Color"
                    labelContent={<ImageGenerator path='./images/chatMenuClose.svg'/>}
                    labelType='image'
                    value={data.bgColor}
                    labelContentColor={data.closeIconColor}
                    readOnly={true}
                    onChange={(e) => onChangeHandler('closeIconColor', e.target.value)}
                    required={false}
                />
            </Col>
            </Row>
            {data.menuItems.map((item, index) => renderMenuItems(index, item))}
            
        </div>
        )}

        </div>
    )

}

export default MenuOptions;