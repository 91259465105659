import React, {  useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import "../Insight/AdminConfiguration/AdminHome.css";
import Heading3 from "../components/Typography/Heading3/Heading3";
// import NavbarCustom from "../../../../components/UI/Navbar/Navbar";
import NavbarCustom from "./Navbar";
import { connect } from "react-redux";
import * as actions from "../../../../store/actions/index";
import SourceFields from "../Insight/AdminConfiguration/SourceFields";
import { encryptAndStore } from "../../../../utility/utility";
import CreateUseCase from './CreateUseCase'
import UseCase from "./UseCase";
import { CollectionValue } from "../../../../store/actions/index";
import {  useSelector, useDispatch } from "react-redux";
import { GetUseCaseDetailsByName } from "../../../../store/actions/index";

const ConfigureModel = (props,{
  config,
  onChangeAdminHome,
  projectList,
  onchangeProjectAction,
}) => {
  const [popperShow, setPopperShow] = useState(false);
  const [searchT, SetSearchT] = useState("");
  const [projectname, SetProjectname] = useState("Project List");
  const[sourceListData,setSourceListData] = useState("");
  const [sourceSelect, setSourceSelect] = useState("ServiceNow - demodata");
  const [backButton, setBackButton] = useState(false);
  const [popperShow2, setPopperShow2] = useState(false);
  const [searchT2, setSearchT2] = useState('');
  const [sourcename, setSourcename] = useState('select')
  const [sourcevalue,setSourcevalue]=useState({bots:['SampleCollection1','SampleCollection2','SampleCollection3']})
  const [dataToPass,setDataToPass]=useState(" ")
  const [nxtvall,setNxtVall]=useState('')
  // const [sourcevalue,setSourcevalue]=useState({bots:[CollectionValueList[0].collection]})
  const [selectModel , setSelectModel] = useState('Unsupervised ML')
  const dispatch = useDispatch();
  const CollectionValueList= useSelector((state) => state.UnsupervisedML.CollectionVal);
 
useEffect(() => {
 let data= {
  "project":[localStorage.getItem('projectname')],
  "modelType": selectModel,
  }
   dispatch(CollectionValue('http://10.14.108.21:32470',data));
},[]);

useEffect(()=> {
  if(props.Loading == false){
  setTimeout(() => {
  {CollectionValueList && CollectionValueList[0].collection == undefined && CollectionValueList[0].collection ==null?
    setSourcevalue({bots:['SampleCollection1','SampleCollection2','SampleCollection3']}) :
    setSourcevalue({bots:(CollectionValueList[0].collection)})
    setDataToPass(CollectionValueList[0].collection)
    }
  }, 3000);
}

},[CollectionValueList,props.Loading]);
// setTimeout(() => {
//   {CollectionValueList[0].collection == undefined && CollectionValueList[0].collection ==null?
//   setSourcevalue({bots:['SampleCollection1','SampleCollection2','SampleCollection3']}) :
//   setSourcevalue({bots:(CollectionValueList[0].collection)})
//   }
//   setDataToPass(CollectionValueList[0].collection)
// }, 1000);
  const onSourceSelectChange = (selected) => {
    setSourceSelect(selected);
  };
 
  const handleTrainData = (langValue) => {
    onChangeAdminHome(langValue);
  };

 
  const setSearchTerm = (item) => {
    SetSearchT(item);
  };
  const setSearchTerm2 = (item) => {
    setSearchT2(item);
  };

  const sourceVal = sourceListData.length>0 ?
   sourceListData[sourceListData.length-1].slice(13).trim() : sourceListData[0];

   const sourceValueclick =(event,sourcename)=>{
    setSearchT2("");
    setPopperShow2(false);
    //encryptAndStore('projectname', sourcename )
    setSourcename(sourcename)
    // let savedProjectName=localStorage.getItem('projectname');
    // if(savedProjectName!==projectname){
    // encryptAndStore('projectname', projectname );
    // this.setState({projectname:projectname});
    // }
    // this.props.onchangeProjectAction(projectname); 
    }
  const projectclick = (event, projectname) => {
    encryptAndStore("sourceSelect",sourceVal);
    SetSearchT("");
    setPopperShow(false);
    let savedProjectName = localStorage.getItem("projectname");
    if (savedProjectName !== projectname) {
      // localStorage.setItem("projectname", projectname);
      encryptAndStore("projectname", projectname);
      SetProjectname(projectname);
      // let data= {
      //   "project":[localStorage.getItem('projectname')],
      //   "modelType": selectModel,
      //   }
      //    dispatch(CollectionValue('http://10.14.108.21:32470',data));
    }
    props.onchangeProjectAction(projectname);
    //window.updateHCLChatBotURL(projectname);
  };
  const popperShowFn = () => {
    setPopperShow(true);
  };
  const popperShowFn2 = () => {
    setPopperShow2(true);
  };
  const handleCallBack = (childData) =>{
    setBackButton(childData)
  }
  return (
    <>
    {backButton == true ? <UseCase/> :
    <Container lg={12}className="trigae-configuration-page triageTabStyle">
      <Row xs={12} >
        <Col xs={4} className="mt-4 mb-3 triage-config-header">
          <div className="d-flex">
            <Heading3
              text="Configure Model"
              className="pageTitleText"
            />
          </div>
        </Col>
        <Col xs={3} style={{marginRight:"-2.5rem"}} className="mt-4 Project-align-styles mb-3" >
          <NavbarCustom
            dataList={props.projectList}
            addImportProjectIcon={false}
            setSearchTerm={(item) => setSearchTerm(item)}
            popperShowFn={() => popperShowFn()}
            popperShow={popperShow}
            searchTerm={searchT}
            projectclick={(event, item) => projectclick(event, item)}
            projectname={projectname}
            rightPopUp={false}
            className="projectNameForusecase"
            // className={"admin-page-changes"}
          ></NavbarCustom>
        </Col>
        <img className="pt-4 project-source-divider-img" src="./Icons/triage-line.png" />
        <Col xs={2}  style={{marginLeft:"0.5rem"}} className="mt-4 mb-3 pr-1 mr-3 SourceNavbar">
        <NavbarCustom
                className="projectNameForsourcefield"
                dataList={sourcevalue}
                addImportProjectIcon={false}
                setSearchTerm={(item)=>setSearchTerm2(item)}
                popperShow={popperShow2}
                popperShowFn={()=>popperShowFn2()}
                searchTerm={searchT2}
                projectclick={(event,item)=> sourceValueclick(event,item)}
                projectname={sourcename}
                rightPopUp={false}
                sourceValue={true}
                boldViewForProjectName={true}
                commanProjectNotShow={true}
            >
            </NavbarCustom>
        </Col>
        <img className="pt-4 pl-5 pr-1 project-source-divider-img" src="./Icons/triage-line.png" />
        <Col xs={2} className='pt-4 mt-0 pl-0'>
           <div style={{fontSize:'10px', color:'grey' ,fontFamily:'Roboto-Regular'}}>USE CASE</div>
           <div style={{fontSize:'16px',fontFamily:'Roboto-Bold',marginTop:"8px"}}>{props.data}</div>
          </Col>
      </Row>
      <Row>
        <Col xs="12" style={{border:'none'}}>
          <CreateUseCase
            viewModelOutput={props.viewModelOutput}
            ingestData={props.ingestData}
            usecasevalue={props.data}
            config={props.config}
            OnSelectTrainedData={handleTrainData}
            selectedSource={sourceSelect}
            sourceListData={sourceListData}
            sourceVal={sourceVal}
            handleCallBack={handleCallBack}
            data={dataToPass}
            sourcedata={dataToPass}
          />
        </Col>
      </Row>
    </Container>}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    projectList: state.user.projects,
    CollectionValueList:state.UnsupervisedML.CollectionVal,
    Loading : state.UnsupervisedML.loading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onchangeProjectAction: (projectname) =>dispatch(actions.changeProjectAction(projectname)),
    onCollectionValue: (COMMON_API_URL) => dispatch(actions.CollectionValue(COMMON_API_URL)),
    onGetUseCaseDetailsByName :(COMMON_API_URL) => dispatch(actions.GetUseCaseDetailsByName(COMMON_API_URL))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigureModel);
