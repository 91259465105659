import React, { Component, useEffect, useState } from "react";
import { isEqual } from "lodash";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { Row, Col, Container } from 'react-bootstrap';
import "../AdminHome.css";
import SymptomRootCauseMapping from "./SymptomRootCauseMapping/SymptomRootCauseMapping";
import Heading3 from '../../../components/Typography/Heading3/Heading3';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import NavbarCustom from '../../../../../../components/UI/Navbar/Navbar'
import { connect } from 'react-redux';
import * as actions from '../../../../../../store/actions/index'
import permissionsJson from "../../../../../../permissions_Json"
import advanceFilterChangeHandlerAction from "../../../../../../store/actions/agentInsights/advanceFilterChangeHandlerAction";
import { encryptAndStore } from "../../../../../../utility/utility";

const RelationshipMapping = ({ config,projectList,onchangeProjectAction }) => { 

  let history = useHistory();
  const dispatch = useDispatch();
  const [searchT, SetSearchT] = useState("");
  const [ popperShow,setPopperShow]= useState(false);
  const [projectname, SetProjectname] = useState("Project List");
  const setSearchTerm = (item) => {
    SetSearchT(item)
  }
    // useEffect(()=>{
    //   dispatch(advanceFilterChangeHandlerAction([]));
    // },[]) 
  const permissions = useSelector(state => {
    return state.user.permissionList;
  });
  let  ProductTriage_Read_Write = false;
  let ProductTriage_RW_Status = permissions.find(data => {
    if (data.permission_code.includes(permissionsJson.permissionCode.PRODUCT_TRIAGE_ADMIN_RW ) && data.sku_code.includes(permissionsJson.skuCode.PT) ||
    data.permission_code.includes( permissionsJson.permissionCode.PRODUCT_TRIAGE_TRAIN_RW))
     return true
  })
  ProductTriage_Read_Write = ProductTriage_RW_Status ? true : false; 
  const projectclick =(event,projectname)=>{
      dispatch(advanceFilterChangeHandlerAction([]));
    SetSearchT("");
    setPopperShow(false)
      let savedProjectName=localStorage.getItem('projectname');
      if(savedProjectName!==projectname){
      // localStorage.setItem('projectname', projectname );
      encryptAndStore('projectname', projectname );
      SetProjectname(projectname);
      }
      onchangeProjectAction(projectname);        
        //window.updateHCLChatBotURL(projectname);
  }

  const popperShowFn=()=>{
    setPopperShow(true)
  }

  return (
    <Container className="trigae-configuration-page triageTabStyle">
      <Row className="d-flex">
        <Col xs='9' style={{marginRight: "1.9rem"}}>
        {/* className= "mt-3 mb-2" for below line commented to match figma screen */}
          <div className='d-flex'> 
            <Heading3 text='Symptoms & Root Causes mapping' className="pageTitleText"/>
          </div>
        </Col>
        <Col xs='2' className="mt-2 Project-align-styles mb-3">
          <NavbarCustom 
              dataList={projectList}
              addImportProjectIcon={false}
              setSearchTerm={(item)=>setSearchTerm(item)}
              popperShowFn={()=>popperShowFn()}
              popperShow={popperShow}
              searchTerm={searchT}
              projectclick={(event,item)=>projectclick(event,item)}
              projectname={projectname}  
              rightPopUp={false}>
          </NavbarCustom>
        </Col>
      </Row>
      <Row>
        <Col xs='12'>
          
        <SymptomRootCauseMapping config={config} permissions={ProductTriage_Read_Write} />
        </Col>
      </Row>
      </Container>
  );

}
const mapStateToProps = state => {
  return {
      projectList:state.user.projects,
  };
};

const mapDispatchToProps = dispatch => {
  return {
      onchangeProjectAction: (projectname) => dispatch(actions.changeProjectAction(projectname)),
  };
};

export default connect( mapStateToProps, mapDispatchToProps )(RelationshipMapping);
