import  React,{ useRef } from 'react';
import classes from './Input.module.css';
import ButtonCustom from '../Button/Button';
import TooltipCustom from '../../UI/Tooltip/Tooltip';

const Inputcustom = ( props ) => {
  const  fileInput = useRef();
  const SetFocus =useRef();

  if(props && props.defaultFocus)
  {
  setTimeout(() => {
    if(SetFocus && SetFocus.current)
    {
        SetFocus.current.focus();
    }
}, 1000);
  }

    function uploadClick(elemId) {
        var elem = document.getElementById(elemId);
        if(elem && document.createEvent) {
           var evt = document.createEvent("MouseEvents");
           evt.initEvent("click", true, false);
           
            elem.dispatchEvent(evt);
           
          
        }
     }
     
    let inputElement = null;
    const inputClasses = ['form-control'];
    if(props.removedefaultCSSClass != undefined)
    {
        inputClasses.length = 0;
    }

    if (props.invalid && props.shouldValidate && props.touched) {
        inputClasses.push(classes.Invalid);
    }

    if(props.customclassName )
    {
        inputClasses.push(classes[props.customclassName]);
    }

    switch ( props.elementType ) {
        case ( 'input' ):
            inputElement = <input
              ref={SetFocus}
                className={inputClasses.join(' ')}
                {...props.elementConfig}
                value={props.value}
                readOnly={props.readOnly}
                onChange={props.changed} 
				autoFocus ={props.autofocus?true:false}/>;
            break;
            case ( 'file' ):
                inputElement = (<>
                {props.ButtonType=="Button" &&
                <ButtonCustom btnInput="button" customStyle={props.customStyle} btnType="whiteButton" disabled={props.disabled ? props.disabled: false} clicked={()=>uploadClick(props.id)} >Upload File</ButtonCustom>
                }
                 {props.ButtonType=="Image" &&
                 <TooltipCustom tooltext={props.tooltext} direction={props.direction}>
                <img src={props.ImgSrc} className={inputClasses.join(' ')} onClick={()=>uploadClick(props.id)} ></img>
                </TooltipCustom>
                }
                <input id={props.id} ref={fileInput}
                className="fileButton"
                 style={{display:"none"}}
                 accept={props.acceptfileType ? props.acceptfileType : '*'}
                     
                     {...props.elementConfig}
                    //  value={props.value}
                     readOnly={props.readOnly}
                     onChange={props.changed}
                     onClick={props.clicked}
                    type="file" /></>);
                break;
        case ( 'checkbox' ):
             inputElement = <input
                className={inputClasses.join(' ')}
                {...props.elementConfig}
                value={props.value}
                onChange={props.changed} />;
                break;
        case ( 'textarea' ):
            inputElement = <textarea
                className={inputClasses.join(' ')}
                {...props.elementConfig}
                value={props.value}
                onChange={props.changed} />;
            break;
        case ( 'select' ):
            const defaultOption = {
                value: '',
                displayValue: '--Select'
            };
            const updatedElementConfig = props.defaultOption ? 
            {
                ...props.elementConfig,
                options: [defaultOption, ...props.elementConfig.options, ]
            } : props.elementConfig;
            inputElement = (
                <select
                    className={inputClasses.join(' ')}
                    value={props.value}
                    onChange={props.changed}>
                    {updatedElementConfig.options && updatedElementConfig.options.length ? updatedElementConfig.options.map(option => (
                        <option key={option.value} value={option.value}>
                            {option.displayValue}
                        </option>
                    )) : null}
                </select>
            );
            break;
        case ( 'dropdown' ):
                inputElement = (
                    <ul className= {inputClasses.join(' ')}>

                    {props.elementConfig.options.map(option => (
                        <li onClick={()=>props.clicked(option.value)} key={option.value}>
                          {option.displayValue} <a className={props.elementConfig.className} ><img src={props.elementConfig.imgsrc}/></a>
                        </li>
                    ))}
                    </ul>
                   
                );
                
                break;
                case ('customdropdown'):
                                       
                    let li=[];
                    {Object.keys(props.elementConfig.options).forEach(function(key) {
                        var value = props.elementConfig.options[key];
                        value.map(option => (
                            li.push( <li key={key}>
                              {option} <a className={props.elementConfig.className} ><img src={props.elementConfig.imgsrc}/></a>
                            </li>)
                        ))
                        // ...
                    })}
                    inputElement = (
                        <ul className= {inputClasses.join(' ')}>
    
                        {li}
                        </ul>
                       
                    );
                    
                    
                    break;
        default:
            inputElement = <input
                className={inputClasses.join(' ')}
                {...props.elementConfig}
                value={props.value}
                onChange={props.changed} />;
    }

    return (
        <>
            {inputElement}
        </>
    );

};

Inputcustom.defaultProps = {
    defaultOption: false
}

export default Inputcustom;