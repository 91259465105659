import React from "react";
import Select from "react-select";
import { Col } from "react-bootstrap";
import isEmpty from "lodash/isEmpty";
import Form from "react-bootstrap/Form";
import * as PropTypes from "prop-types";
import "./product-attributes.css";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { isNil, uniqueId } from "lodash";
import { useState } from "react";

const ProductAttributesComponent = ({
  options,
  value,
  onChange,
  label,
  allOption,
  disabled,
  placeholder,
  onRemoveItem,
  targetSourceDescription,
  sourceHelpIcon,
  attributeHelpIcon,
  className,
  boldTitle,
  search,
  showValues,
  closeOption,
  sourceData,
  ...rest
}) => {
  const [filterHandler, setFilterHandler] = useState(true);

  return (
    <React.Fragment>
      <div
        className={
          sourceHelpIcon ? "Product-Select-Item" : "add-Symptom-multi-selcet"
        }
      >
        {sourceHelpIcon ? (
          <div className="col-md-5">
            <p className="mb-2 product-source-service-head">{"Source"}</p>
            <p className="mb-4 product-source-service-body">
              {sourceData ? sourceData : "ServiceNow"}
            </p>
          </div>
        ) : null}
        <div
          className={sourceHelpIcon ? "col-md-7" : "col-md-12"}
          key={uniqueId()}
        >
          {attributeHelpIcon ? (
            <p className="d-flex flex-start mb-2 product-source-service-head">
              {"Source Attributes"}
            </p>
          ) : null}
          <div
            className={
              sourceHelpIcon
                ? "react-select multi-select-container col-md-12 "
                : ""
            }
          >
            {label ? (
              <div className={boldTitle ? "bold-title" : "select-label"}>
                <Form.Label>{label}</Form.Label>
              </div>
            ) : null}
            <Select
              value={
                isEmpty(value)
                  ? []
                  : options.filter((item) => value.includes(item.value))
              }
              isMulti
              isSearchable={search}
              placeholder={placeholder}
              name="multi Select"
              options={[allOption, ...options]}
              className={
                sourceHelpIcon
                  ? "multi-select-container"
                  : "multi-select-dropdown"
              }
              classNamePrefix="multi-select"
              isDisabled={disabled}
              hideSelectedOptions={false}
              closeMenuOnSelect={closeOption}
              tabSelectsValue={false}
              controlShouldRenderValue={false}
              onChange={(selected) => {
                if (isNil(selected)) {
                  return onChange([]);
                }
                if (
                  selected.length > 0 &&
                  selected[selected.length - 1].value === allOption.value
                ) {
                  return onChange(options);
                }
                return onChange(selected);
              }}
              {...rest}
            />
          </div>
        </div>
      </div>
      <Col xs={12} className="selected-product-atri-items">
        <div className="d-flex flex-start align-items-center associate-multi-select">
          {filterHandler &&
            value &&
            value.slice(0, 7).map((item, index) => (
              <div className="mr-3 mt-2 pr-2 d-flex flex-start align-items-center selected-option">
                <div
                  onClick={() => onRemoveItem(item)}
                  className="remove-icon mr-2 ml-2"
                >
                  <div className="selected-close-icon">x</div>
                </div>
                <div className="selected-option-label">{item}</div>
              </div>
            ))}
        </div>
      </Col>
      <Col xs={12} className="selected-product-atri-items">
        <div className="d-flex flex-start align-items-center associate-multi-select">
          {!filterHandler &&
            value &&
            value.map((item, index) => (
              <div className="mr-3 mt-2 pr-2 d-flex flex-start align-items-center selected-option">
                <div
                  onClick={() => onRemoveItem(item)}
                  className="remove-icon mr-2 ml-2"
                >
                  <div className="selected-close-icon">x</div>
                </div>
                <div className="selected-option-label">{item}</div>
              </div>
            ))}
        </div>
      </Col>
      <div className="pl-3 pt-2">
        {value.length > 7 && (
          <button
            className="show-more-less-key p-2"
            onClick={() => setFilterHandler(!filterHandler)}
          >
            {filterHandler ? "Show More ..." : "... Show Less"}
          </button>
        )}
      </div>
    </React.Fragment>
  );
};

ProductAttributesComponent.propTypes = {
  options: PropTypes.array,
  id: PropTypes.string,
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  sourceHelpIcon: PropTypes.bool,
  attributeHelpIcon: PropTypes.bool,
  boldTitle: PropTypes.bool,
  search: PropTypes.bool,
  closeOption: PropTypes.bool,
  showValues: PropTypes.bool,
};

ProductAttributesComponent.defaultProps = {
  options: [],
  id: "",
  allOption: {
    label: "Select all",
    value: "*",
  },
  disabled: false,
  sourceHelpIcon: true,
  attributeHelpIcon: true,
  boldTitle: false,
  search: false,
  closeOption: true,
  showValues: true,
};

export default ProductAttributesComponent;
