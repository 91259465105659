import { Card, Col, Row } from 'react-bootstrap';

import './clusters-card.css';

const ClustersCardComponent = ({
    label,
   
    showDetailsTab,
    active,
    ticketsCount
}) => {
    return (
        <Col xs={12}>
        <Card className={`issue-results-box mb-2 ${active ? 'selected' : ''}`} onClick={showDetailsTab}>
        <Card.Body className='d-flexalign-items-center'>
            <Row>
                <Col xs={6}>
                    <div className='issue-results-description'>
                       {label.toLowerCase()}
                    </div>
                </Col>
                <Col xs={5}>
                    <div className='issue-results-description'>{ticketsCount}</div>
                </Col>
                 <Col xs={1} className="d-flex  ">
                    <div><img src="./images/Vector.svg" alt="Arrow-img" /></div>
                </Col>
            </Row>
        </Card.Body>
    </Card>
    </Col>

    )
};

export default ClustersCardComponent;