// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SubcriptionCheck{
 position: fixed;
 right:0;
 top: 0;
 width: 100%;
 background-color: #fdecb1;
 display: flex;
 justify-content: space-evenly;
 margin-bottom: 120px;
 padding: 0 25px;
 font-weight: bold;


}
/* .SubcriptionCheck div  {
    flex-grow: 1;

} */
.SubcriptionCheck .light{
    display: flex;
    justify-content: center;
    text-align: center;
}
.SubcriptionCheck .light span{
 padding-top: 10px;
 /* margin-right: 40px; */
}`, "",{"version":3,"sources":["webpack://./src/components/validity/SubcriptionCheck.css"],"names":[],"mappings":"AAAA;CACC,eAAe;CACf,OAAO;CACP,MAAM;CACN,WAAW;CACX,yBAAyB;CACzB,aAAa;CACb,6BAA6B;CAC7B,oBAAoB;CACpB,eAAe;CACf,iBAAiB;;;AAGlB;AACA;;;GAGG;AACH;IACI,aAAa;IACb,uBAAuB;IACvB,kBAAkB;AACtB;AACA;CACC,iBAAiB;CACjB,wBAAwB;AACzB","sourcesContent":[".SubcriptionCheck{\n position: fixed;\n right:0;\n top: 0;\n width: 100%;\n background-color: #fdecb1;\n display: flex;\n justify-content: space-evenly;\n margin-bottom: 120px;\n padding: 0 25px;\n font-weight: bold;\n\n\n}\n/* .SubcriptionCheck div  {\n    flex-grow: 1;\n\n} */\n.SubcriptionCheck .light{\n    display: flex;\n    justify-content: center;\n    text-align: center;\n}\n.SubcriptionCheck .light span{\n padding-top: 10px;\n /* margin-right: 40px; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
