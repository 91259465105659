import React, { Component } from 'react';
import './Drawer.css';
import InputWithLabelComponent from '../../../containers/SettingPage/SettingInnerPages/components/InputWithLabelComponent/InputWithLabelComponent';
import DrawerComponent from '../../UIComponents/Drawer/DrawerComponent';
import ButtonComponent from '../../UIComponents/Button/ButtonComponent';


let initState = {
    right: false,
    languageName: '',
    language: "english_en",
    projectValidationMsg: '',
    validation: {
        projectName: true,
        isProjectExists: true,
    },
    projectnameCount: 25,
};
let ProjNametouched = false;
class AddLanguageDrawer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initState,
            right: true
        }
    }

    handleInputs = () => (event) => {
        let value = event.target.value
        let msg = '';
        let filtereedArray = this.props.languagelist && this.props.languagelist.some((item) => item.languagename.toLowerCase() == value.toLowerCase())
        if (filtereedArray) {
            msg = 'Language already exists';
        }
        if (value.length !== 0 && value !== '' && value !== undefined && value !== null) {
            ProjNametouched = true;
        } else {
            ProjNametouched = false;
        }
        if (!value) {
            msg = 'Please add language name.';
        } else if (!(/^[a-zA-Z]+$/).test(value)) {
            msg = 'Language name should consist of alphabets.';
        } else if (value.length > 25) {
            msg = 'Language name should not be more than 25 chars';
        }
        this.setState({
            ...this.state,
            languageName: event.target.value,
            projectValidationMsg: msg,
            validation: {
                ...this.state.validation,
                languageName: !msg ? true : false
            },
            projectnameCount: 25 - value.length
        })


    }


    validationOnSubmition = () => {
        let msg = '';
        const { languageName, projectValidationMsg } = this.state
        if (!languageName) {
            msg = 'Please add language name.';
        } else {
            msg = projectValidationMsg;
        }

        this.setState({
            ...this.state,
            projectValidationMsg: msg,
            validation: {
                languageName: !msg ? true : false,
            }
        })
        return !msg ? true : false
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.onSubmission(this.state.languageName)
        this.handleClose();
    }

    removeInputData = () => {
        this.setState({
            projectnameCount: 25,
            languageName: '',
            projectValidationMsg: ''
        })
        ProjNametouched = false;
    }


    handleClose = () => {
        this.props.onHide()
        this.removeInputData()
    }

    render() {
        let ProjNameCharString = ProjNametouched ? 'Left' : ""
        return (
            <React.Fragment >
                <DrawerComponent
                    heading="Add Language Details"
                    showDrawer={this.handleClose}
                    openDrawer={this.props.show}
                >
                    <div className='drawer'>
                        <div className='drawer-body mt-2'>
                            <form>
                                <InputWithLabelComponent
                                    style={{ marginBottom: "5px" }}
                                    placeHolder=''
                                    className={'drawer-input'}
                                    type="text"
                                    name="name"
                                    label="Language Name*"
                                    value={this.state.languageName}
                                    onChange={this.handleInputs('languageName')}
                                    isInvalid={this.state.projectValidationMsg ? true : false}
                                    error={this.state.projectValidationMsg}
                                    maxLength='25'
                                />
                                <span style={{ float: 'right', color: '#C6BDBD', fontStyle: 'Roboto', fontSize: '12px', fontWeight: '700' }}>{this.state.projectnameCount} Characters <span>{ProjNameCharString}</span></span>
                                <div className="form-group submit-btn-parent mt-4">
                                    <ButtonComponent
                                        variant='contained'
                                        label='SAVE'
                                        clicked={this.handleSubmit}
                                        className='buttonWidth'
                                        disabled={!(this.state.languageName && !this.state.projectValidationMsg)}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </DrawerComponent>
            </React.Fragment>
        );
    }
}


export default AddLanguageDrawer;
