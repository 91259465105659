import React from 'react';
import InputWithLabelComponent from '../../components/InputWithLabelComponent/InputWithLabelComponent';
import ColorPickerComponent from '../../components/ColorPicker/ColorPickerComponent';
import SelectComponent from '../../../../../components/UIComponents/SelectDropdown/SelectComponent';
import FontFamilyComponent from '../FontFamilyComponent/FontFamilyComponent';
import { Col, Row, Form } from 'react-bootstrap';
import { Switch } from '@material-ui/core';
import * as lists from '../Constants';

const BackHome = ({data, onChangeHandler}) => {
  
    return (
    <div>
        <Row className='mt-2'>
            <Col md='2' className='al-ctr'>
            <Form.Label className="mb-1 text-box-title switch-label">Display “Back to Home”</Form.Label>
            </Col>
            <Col md='1'>
            <Switch
                className='switchMain'
                checked={data.isVisible}
                onChange={(e) => onChangeHandler('isVisible',e.target.checked)}
                color="primary"
                name="displayCheck"
                inputProps={{ 'aria-label': 'primary checkbox' }}
                disableRipple
            />
            </Col>
        </Row>
        {data.isVisible && (
        <div>
        <Row className='mt-2'>
            <Col md='2'>
                <InputWithLabelComponent
                    type="text"
                    placeHolder=""
                    name="navigation-home-text"
                    label="Navigation Label"
                    value={data.text}
                    readOnly={false}
                    onChange={(e) => onChangeHandler('text',e.target.value)}
                    required={false}
                    maxLength={25}
                />
            </Col>
            <Col md='2'>
                <ColorPickerComponent
                    type="color"
                    name="home-text-color"
                    label="Text Color"
                    value={data.textColor}
                    readOnly={true}
                    onChange={(e) => onChangeHandler("textColor", e.target.value)}
                    required={false}
                />
            </Col>
        </Row>
        <Row className='mt-2'>
            <Col md='3'>
            <FontFamilyComponent
                type="color"
                name="navigation-menu-text"
                label="Text Family"
                onChange={e => onChangeHandler('textFamily',e)}
                value={data.textFamily}
                labelContent="AB"
                readOnly={false}
                required={true}
                disabled={true}
            />
            </Col>
            <Col md='2'>
                <SelectComponent
                    name="home-style"
                    label="Text Style"
                    className="selectCompCol2"
                    changed={(e) => onChangeHandler('textStyle',e.value)}
                    required={true}
                    value={data.textStyle}
                    options={lists.fontStyles}
                    disabled={true}
                />
            </Col>
            <Col md='2'>
                <SelectComponent
                    name="home-size"
                    label="Text Size"
                    className="selectCompCol2"
                    changed={(e) => onChangeHandler('textSize',e.value)}
                    required={true}
                    value={data.textSize}
                    options={lists.fontSizes}
                    disabled={true}
                />
            </Col>
        </Row>
        <Row className='mt-2'>
            <Col md='3'>
                <div className="common-input">
                    <Form.Label className="mb-1 text-box-title adding-other-input">Routing link</Form.Label>
                    <div>
                    <div className="input-group routing-div">
                        <label className="dropdown-wrapper dropless-wrapper"><span className="">Flow</span>
                        </label>
                        <Form.Control
                            type={"text"}
                            name={"selectName"}
                            onChange={(e) => onChangeHandler('flowQuery',e.target.value)}
                            required={false}
                            value={data.flowQuery}
                            className={"default-css color-text-input"}
                            placeholder={'Query to trigger'}
                            readOnly={false}
                        />
                    </div>
                    </div>
                </div>
            </Col>
        </Row>
        </div>
        )}
    </div>
    )
}

export default BackHome;