import React from "react";
import Drawer from "@material-ui/core/Drawer";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import "./issue-results-details.css";
import Heading3 from "../../../components/Typography/Heading3/Heading3";
import { isEqual } from "lodash";


const useStyles = makeStyles({
  list: {
    width: "29.688rem",
    maxHeight: "100vh",
    overflowX: "hidden",
    overflowY: "scroll",
  },
});

const IssueResultsDetails = ({ toggleDrawer, item, isRecommendedTab,  configureKeys }) => {
 
  const classes = useStyles();
  const selectedIssueDetails = (issue) =>
    Object.entries(issue).map(([key, value]) => {
      if (Array.isArray(value)) {
        return (<div className="ml-2 mt-3">
          <div className="issue-details-head">{key.split('_').join(' ')}</div>
           <div className="issue-details-description mt-1">{value.toString()}</div>
        </div>)
      }
      if(!isEqual(configureKeys.length,0)){
      return (
        <>
        { configureKeys.includes(key) ?  (
            <div className="ml-2 mt-3">
              <div className="issue-details-head">{key.split('_').join(' ')}</div>
              <div className="issue-details-description mt-1">{value}</div>
            </div>
          ) : null}
        </>
      )}
      // else {
      //   return (
      //     <>
      //       { (config.SIMILAR_ISSUES_HIDDEN_FIELDS).includes(key) ? null : (
      //         <div className="ml-2 mt-3">
      //           <div className="issue-details-head">{key.split('_').join(' ')}</div>
      //           <div className="issue-details-description mt-1">{value}</div>
      //         </div>
      //       )}
      //     </>
      //   );
      // }
    });
  

  return (
    <React.Fragment>
      <Drawer anchor={"right"} open={true} onClose={toggleDrawer(false)}>
        <div
          className={clsx(classes.list) }
          role="presentation"
          className="issue-result-page"
        >
          <div className="d-flex pt-4 pl-2">
            <div className="col-md-1">
              <CloseIcon
                className="d-flex"
                onClick={toggleDrawer(false)}
                style={{ fontSize: 25 }}
              />
            </div>
            <div className="d-flex product-data pl-2" md={12}>
              {isRecommendedTab ? (
                <Heading3
                  className="text-capitalize issue-details-header"
                  text={item.rootcause_title.toLowerCase().slice(0, 40) }
                />
              ) : (
                <Heading3
                  className="text-capitalize issue-details-header"
                  text= "Issue Details"
                  //text={typeof item.description !== 'undefined' ? item.description.toLowerCase().slice(0, 40) : "" }
                />
              )}
            </div>
          </div>
          <div className="issue-details-sub-heading ml-2 pl-4">
            {isRecommendedTab
              ? item.rootcause_title.toLowerCase()
              : null }
          </div>
          <div className="d-flex flex-column issue-results-details">
            {selectedIssueDetails(item)}
          </div>
        </div>
      </Drawer>
    </React.Fragment>
  );
};

IssueResultsDetails.propTypes = {};

IssueResultsDetails.defaultProps = {};

export default IssueResultsDetails;
