import React, {  useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import "./AdminHome.css";
import Heading3 from "../../components/Typography/Heading3/Heading3";

import StepperConfigureTrain from "./TraiageConfigurationTabs/ManageMasterList/StepperConfigureTrain";
import NavbarCustom from "../../../../../components/UI/Navbar/Navbar";
import { connect } from "react-redux";
import * as actions from "../../../../../store/actions/index";
import SourceFields from "./SourceFields";
import { encryptAndStore } from "../../../../../utility/utility";

const AdminHome = ({
  config,
  onChangeAdminHome,
  projectList,
  onchangeProjectAction,
}) => {
  const [popperShow, setPopperShow] = useState(false);
  const [searchT, SetSearchT] = useState("");
  const [projectname, SetProjectname] = useState("Project List");
  const[sourceListData,setSourceListData] = useState("");
  const [sourceSelect, setSourceSelect] = useState("ServiceNow - demodata");

useEffect(() => {
 if(sourceListData.length > 0){
  const source = sourceListData[0]
  setSourceSelect(source);
 }
 else{
  setSourceSelect("ServiceNow - demodata");
 }
},[sourceListData]);

  const onSourceSelectChange = (selected) => {
    setSourceSelect(selected);
    encryptAndStore("source",selected);
  };
 
  const handleTrainData = (langValue) => {
    onChangeAdminHome(langValue);
  };

 
  const setSearchTerm = (item) => {
    SetSearchT(item);
  };

  const sourceVal = sourceListData.length>0 ?
   sourceListData[sourceListData.length-1].slice(13).trim() : sourceListData[0];

    const projectclick = (event, projectname) => {
    encryptAndStore("sourceSelect",sourceVal);
    encryptAndStore("source",sourceListData[sourceListData.length-1]);
    SetSearchT("");
    setPopperShow(false);
    let savedProjectName = localStorage.getItem("projectname");
    if (savedProjectName !== projectname) {
      // localStorage.setItem("projectname", projectname);
      encryptAndStore("projectname", projectname);
      SetProjectname(projectname);
    }
    onchangeProjectAction(projectname);
    //window.updateHCLChatBotURL(projectname);
  };
  const popperShowFn = () => {
    setPopperShow(true);
  };
  return (
    <Container className="trigae-configuration-page triageTabStyle">
      <Row xs={12} >
        <Col xs={7} className="mt-4 mb-3 triage-config-header">
          <div className="d-flex">
            <Heading3
              text="Setup Triage Attributes & Train Model"
              className="pageTitleText"
            />
          </div>
        </Col>
        <Col xs={2} className="mt-4 mr-4  Project-align-styles mb-3" >
          <NavbarCustom
            dataList={projectList}
            addImportProjectIcon={false}
            setSearchTerm={(item) => setSearchTerm(item)}
            popperShowFn={() => popperShowFn()}
            popperShow={popperShow}
            searchTerm={searchT}
            projectclick={(event, item) => projectclick(event, item)}
            projectname={projectname}
            rightPopUp={false}
            className={"admin-page-changes"}
          ></NavbarCustom>
        </Col>
        <img className="pt-4  project-source-divider-img" src="./Icons/triage-line.png" />
        <Col xs={2}  style={{marginLeft:"1.7rem"}} className="mt-4 mb-3 SourceNavbar">
          <SourceFields 
          config = {config}
          onChangeAdminHome = {onChangeAdminHome}
          projectList = {projectList}
          onchangeProjectAction ={onchangeProjectAction}
          selectedSource={sourceSelect}
          onSourceSelectChange={onSourceSelectChange}
          setSourceListData={setSourceListData}
         />
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <StepperConfigureTrain
            config={config}
            OnSelectTrainedData={handleTrainData}
            selectedSource={sourceSelect}
            sourceListData={sourceListData}
            sourceVal={sourceVal}
          />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    projectList: state.user.projects,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onchangeProjectAction: (projectname) =>
      dispatch(actions.changeProjectAction(projectname)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminHome);
