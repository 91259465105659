import React from 'react';
import { Tab, Nav } from 'react-bootstrap';
import './tabs-component.css';

const TabsComponent = ({
    tabItems,
    setSelected,
    children,
    selected,
    className
}) => (
        <div className='horizontal-tabs'>
            <Tab.Container
                defaultActiveKey={selected}
                onSelect={(e) => setSelected(e)}
                activeKey={selected}
            >
                <Nav variant="tabs" className={className}>
                    {tabItems.length ? tabItems.map(tab => {
                        return (
                            <Nav.Item key={tab.value}>
                                <Nav.Link eventKey={tab.value}>{tab.label}</Nav.Link>
                            </Nav.Item>
                        );
                    }) : null}
                </Nav>
                <Tab.Content>
                    <Tab.Pane eventKey={selected}>{children}</Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </div>
    );

export default TabsComponent;

