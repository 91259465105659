import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from '../../../instance';
import './Drawer.css';
import DrawerComponent from '../../UIComponents/Drawer/DrawerComponent';

class OtherDocumentDrawer extends Component {
    constructor(props) {
        super(props);
        
    }
    toggleDrawer = (open) => (event) => {
        if (!open) {
            this.props.onHide("otherDocument");
        }
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            this.props.onHide("otherDocument");
            return;
        }
    };

    render() {
        return (
            <React.Fragment >
                <DrawerComponent heading={this.props.title}
                    showDrawer={this.toggleDrawer(false)}
                    openDrawer={this.props.show}>
                    <div className='drawer add-entity'>
                        <div className='drawer-body mt-2'>
                            <form>
                               {this.props.children}
                            </form>
                        </div>
                    </div>
                </DrawerComponent>
            </React.Fragment>
        );
    }
}


export default connect()(withRouter(OtherDocumentDrawer, axios));
