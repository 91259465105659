import React from 'react'
import { Col,  Row } from 'react-bootstrap';
import Input from '../UI/Input/InputCustom';
const UserProfileForm=(props)=>{
   //Edituserprofile  
   let profilecontrols  = props.profilecontrols;
   const edituserProfileformElementsArray = [];
   for ( let key in profilecontrols) {
       let  labelName = '';
       let individualSkill=null;
       let individualLanguage=null;
       let assignProject = null;
       if(key =='username'){
           labelName = (<label>Name </label>);
       }
       else if(key == 'userrole' ){
           labelName = (<label>Role </label>);
       }
       else if (key == 'userOrgList' )
       {
           labelName = (<label>Organization</label>);
       }
       
       else if (key == 'userskillList' ){
           labelName = (
           <label>Skill Set</label>
           );
           individualSkill=( <Input
             customclassName={profilecontrols[key].individualSkillList.className}
             elementType={profilecontrols[key].individualSkillList.elementType}
             elementConfig={profilecontrols[key].individualSkillList.elementConfig}
             value={profilecontrols[key].individualSkillList.value}
             invalid={!profilecontrols[key].individualSkillList.valid}
             shouldValidate={profilecontrols[key].individualSkillList.validation}
             touched={profilecontrols[key].individualSkillList.touched}
             removedefaultCSSClass = {profilecontrols[key].individualSkillList.removedefaultCSSClass}
             readOnly={profilecontrols[key].individualSkillList.readOnly !== undefined ? profilecontrols[key].individualSkillList.readOnly : null}
              clicked={( value1 ) => props.editUserDeleteLi( value1, "individualSkill")}
              /> )
       }
       else if (key == 'userknownlanguage' )
       {
           labelName = (<label>Known Languages</label>);
           individualLanguage=( <Input
             // key={formElement.id}
             customclassName={profilecontrols[key].individualLanguageList.className}
             elementType={profilecontrols[key].individualLanguageList.elementType}
             elementConfig={profilecontrols[key].individualLanguageList.elementConfig}
             value={profilecontrols[key].individualLanguageList.value}
             invalid={!profilecontrols[key].individualLanguageList.valid}
             shouldValidate={profilecontrols[key].individualLanguageList.validation}
             touched={profilecontrols[key].individualLanguageList.touched}
             removedefaultCSSClass = {profilecontrols[key].individualLanguageList.removedefaultCSSClass}
             readOnly={profilecontrols[key].individualLanguageList.readOnly !== undefined ? profilecontrols[key].individualSkillList.readOnly : null}
              clicked={( value1 ) => props.editUserDeleteLi( value1,  "individualLanguage" )}
              /> )
       }
       else if (key == 'assignProject' )
       {
           labelName = (<label>Assigned Projects</label>);
           if(props.ProjectsDisabled == 'false'){
            assignProject=( <Input
                // key={formElement.id}
                customclassName={profilecontrols[key].AssignedProjectList.className}
                elementType={profilecontrols[key].AssignedProjectList.elementType}
                elementConfig={profilecontrols[key].AssignedProjectList.elementConfig}
                value={profilecontrols[key].AssignedProjectList.value}
                invalid={!profilecontrols[key].AssignedProjectList.valid}
                shouldValidate={profilecontrols[key].AssignedProjectList.validation}
                touched={profilecontrols[key].AssignedProjectList.touched}
                removedefaultCSSClass = {profilecontrols[key].AssignedProjectList.removedefaultCSSClass}
                readOnly={profilecontrols[key].AssignedProjectList.readOnly !== undefined ? profilecontrols[key].AssignedProjectList.readOnly : null}
                 clicked={( value1 ) => props.editUserDeleteLi( value1,  "assignProject" )}
                 /> )
           }
       }
       
       edituserProfileformElementsArray.push( {
           id: key,
           config: profilecontrols[key],
           labelName: labelName,
           individualSkill:individualSkill,
           individualLanguage:individualLanguage,
           assignProject:assignProject
       } );
       
   }
   let  edituserProfileform = edituserProfileformElementsArray.map( formElement => (
    <Row key={formElement.id} >
    <Col md={12}>
    <div className="inputContainer">
      {formElement.labelName}
     <Input
       key={formElement.id}
       customclassName={formElement.config.className}
       elementType={formElement.config.elementType}
       elementConfig={formElement.config.elementConfig}
       value={formElement.config.value}
       invalid={!formElement.config.valid}
       shouldValidate={formElement.config.validation}
       touched={formElement.config.touched}
       removedefaultCSSClass = {formElement.config.removedefaultCSSClass}
       readOnly={formElement.config.readOnly !== undefined ? formElement.config.readOnly : null}
       changed={( event ) => props.changeEventHandler( event, formElement.id )} /> 

      { formElement.config.elementConfig.placeholder ==="Password" ? '' : null }
      {formElement.individualSkill}
      {formElement.individualLanguage}
      {formElement.assignProject}
     </div>
     { formElement.id ===  "userknownlanguage" ? props.emptyLanguageMsg : null }
     { formElement.id ==="assignProject" ? props.emptyProjectMsg: null}
      </Col>
   </Row>   
  ) );

    return(
        edituserProfileform
    )
};

export default UserProfileForm;

