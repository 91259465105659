import React, { Component } from 'react';
import './Drawer.css';
import DrawerComponent from '../../UIComponents/Drawer/DrawerComponent';

class AddProjectFlowDrawer extends Component {

    toggleDrawer = (open) => (event) => {
        if (!open) {
            this.props.onHide('entity');
        }
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            this.props.onHide('entity');
        }
    };

    render() {
        return (
            <DrawerComponent heading={"Add " + this.props.title}
                showDrawer={this.toggleDrawer(false)}
                openDrawer={this.props.show}>
                <div className='drawer add-entity'>
                    <div className='drawer-body mt-2'>
                        <form>
                            {this.props.children}
                        </form>
                    </div>
                </div>
            </DrawerComponent>
        );
    }
}


export default AddProjectFlowDrawer;
