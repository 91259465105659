export const fontSizes = [{ value: '10px', displayValue: '10px' },
    { value: '12px', displayValue: '12px' },
    { value: '14px', displayValue: '14px' },
    { value: '16px', displayValue: '16px' },
    { value: '18px', displayValue: '18px' },
    { value: '20px', displayValue: '20px' },
    { value: '22px', displayValue: '22px' },
    { value: '24px', displayValue: '24px' }];
    
export const fontStyles = [{ value: 'Bold', displayValue: 'Bold' },
    { value: 'Italic', displayValue: 'Italic' },
    { value: 'Regular', displayValue: 'Regular' },
    { value: 'Black', displayValue: 'Black' }];

export const fontFamilies = [{ value: 'Roboto', displayValue: 'Roboto' },
    { value: 'Times Roman', displayValue: 'Times Roman' },
    { value: 'Noto Sans', displayValue: 'Noto Sans' },
    { value: 'Arial', displayValue: 'Arial' },
    { value: 'Helvetica', displayValue: 'Helvetica' },
    { value: 'Custom', displayValue: 'Custom' }];