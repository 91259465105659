import { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Form } from 'react-bootstrap';
import './AgentImage.css';
import * as actions from "../../../../store/actions/index";
import { Switch } from '@material-ui/core';

const initState = {
    resetPopUp: false,
    showSavePopup: false,
    saveSetting:false,
    resetSetting:false,
    Closeicon:'',
    widgetIconURL:'', 
    IconbackgroundColor: '#006BB6',
    IconTextColor: "#FFFFFF",
    menuOn: false,
    engagementMsgBG: '#dfdfdf',
    engagementMsgTC: '#212021',
    engagementMsgBC: '#dfdfdf',
    engagementMsg:''
}

class AgentImage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initState
        }
    }

    onColorChange = (e) => {

        this.setState({
            [e.target.name]: e.target.value,
        })
        this.setState({saveSetting:true,resetSetting:true})

    }
    
    onChangeHandler = (event) => {
        let { name,  value, type } = event.target;
        this.setState({saveSetting:true,resetSetting:true}) 
        if (type === 'file' ) {
            var fileType = value.split(/\.(?=[^\.]+$)/)[1]
            fileType = fileType.toLowerCase();
            if (fileType === "svg" || fileType === "png" || fileType === "jpeg" || fileType === 'jpg') {
                if(name=='widgetIcon'){
                    this.setState({ widgetIconURL: URL.createObjectURL(event.target.files[0]),widgetIconData: event.target.files[0] })
                    this.props.onPersonalityImgChange('widgetIcon',{ widgetIcon: URL.createObjectURL(event.target.files[0]),widgetIconData: event.target.files[0] })
                    this.props.updateReset(false);
                }
              
                this.setState({
                    invalidImageFile: false,
                });

            } else {
                this.setState({
                    invalidImageFile: true,
                })
            }
        }


    }

    componentDidUpdate(prevprops) {
        if(prevprops.resetFlag !== this.props.resetFlag && this.props.resetFlag) {
            this.deleteWidgetImg('RESET')
        }
    }
    
    deleteWidgetImg = (type,e) => {
        if(type === 'DELETE')
            e.preventDefault()
       
        this.props.onPersonalityImgChange('deleteWidgetIcon','')
        this.setState({widgetIconURL:'',widgetIconData:''})
        this.setState({saveSetting:true,resetSetting:true})
    }
   

    render() {
        let widgetIconURL = this.state.widgetIconURL
        let DisplayImg = ''
      
        let DeleteIcon = <img src="./Icons/DeleteBlack.svg" className="deleteHeaderImg" onClick={(e) => this.deleteWidgetImg('DELETE',e)} />

        if (widgetIconURL) {
            DisplayImg = <img src={widgetIconURL} style={{height: "55px", width: "55px",  position: "absolute",
                top: "18px", left: "38%"}}/>;
        } else if(this.props.data.agent.widgetIcon) {
            DisplayImg = <img src={'./VATheme/Templatefiles/'+this.props.data.agent.widgetIcon} style={{height: "55px", width: "55px",  position: "absolute",  top: "18px", left: "38%"}}/>;
        }else{
            DisplayImg = <span>Click to select image file</span>;
            DeleteIcon = ''
        }
      
        return (
            <>
            <Row className="VirtualAgentImage">
                <Col md={7} className="headerInput pl-0">
                    <Form.Label className="mb-1 text-box-title adding-other-input labelText">Bot Widget Image</Form.Label>
                    <section className="imgContainer">
                        <label className="fileIinput">
                        {DisplayImg}
                            <input
                                name="widgetIcon"
                                type="file"
                                onChange={(e) => this.onChangeHandler(e)}
                                accept='image/svg+xml,image/png,image/jpeg'
                                ref="upload"
                                id="upload"
                                onClick={(event)=> {  event.target.value = null  }}
                            />
                                {DeleteIcon}
                        </label>
                    </section>
                    <div className='contentView'>
                        <img src="./images/info_icon.png" />
                        <span>File format supported is .svg, .png and.jpeg</span>
                        <div className='selectedFile'>{this.state.invalidImageFile === true ? "Uploading of image failed as the file format is not supported." : ''}</div>
                    </div>
                </Col>   
            </Row>
            <Row className='mt-3'>
            <Col md='2' className='al-ctr'>
                <Form.Label className="mb-1 text-box-title switch-label">Draggable Chatbot</Form.Label>
            </Col>
            <Col md='1'>
                <Switch
                    className='switchMain'
                    checked={this.props.data.agent.isDraggable}
                    onChange={(e) =>this.props.onPersonalityImgChange('isDraggable',e.target.checked)}
                    color="primary"
                    name="isDraggable"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    disableRipple
                />
            </Col>
            </Row> 
            </>

        );

    }
}
const mapStateToProps = state => {
    return {
        IsProjectChange:state.projectview.IsProjectChange,
        permissions:state.user.moduleAccess.conversationalAdmin,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onSetSuccessMessage: (message) => dispatch(actions.SetSuccessMessage(message)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AgentImage);
