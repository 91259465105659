import * as React from 'react';
 import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Col, Row } from "react-bootstrap";
import './Accordion.css';


const AccordionComponent = (props) => {
   return(
      <Accordion defaultActiveKey="0" className='accordionComp'>
      <Card>
        <Card.Header className={props.activeItem === props.data.category ?'active-panel': ''} data-testid={`panel`}>
          <Accordion.Toggle as={Button} variant="link" eventKey={props.data.category} data-testid={`accordion`}
          onClick={() => props.toggleActive(props.data.category)} className='accordionClass'>
             <div >
               <Row>
               <Col md={7}>
                  <div className="accordionPanel">
                  <img src={(props.activeItem == props.data.category) ? "./Icons/Minus.svg": "./Icons/Plus.svg"} ></img>
                  <span className="categoryTitle">{props.data.category}</span></div>
               </Col>
               <Col md={5}>
                  <div className="categoryStatus"><img src={props.imgSrc} className=""></img>
                  <span>{props.status}</span></div>
               </Col>
               </Row>
            </div>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey={props.data.category}>
          <Card.Body>
          <ul>
         {props.data.permissions.map((item) => {
            
         return (
               <li key={item.code} className="cardBodyList"><input type="checkbox" checked={item.checked} name={item.code} value={item.code} 
               onChange={((e)=>props.ChangePermission(e,props.data.category))}/>
               <span className="cardBodyListname">{item.name}</span></li>
               ) 
         })}
         </ul>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
   )
}

export default AccordionComponent;
