// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contentView{
    position: relative;
    left: 30px;
    top: -75px;
}

.contentView span{
    font-size: 11px;
    font-family: 'Roboto-Regular';
    color:#000;
    left: 5px;
    position: relative;

}
.VirtualAgentImage .contentView{
    top: -6px;
}
.iconTextcolorClass .labelContentData {
    left: 14px;
}
.engagementSection label.text-box-title.adding-other-input {
    height: 30px;
}`, "",{"version":3,"sources":["webpack://./src/containers/SettingPage/SettingInnerPages/VirtualAssistant/AgentImage.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,UAAU;IACV,UAAU;AACd;;AAEA;IACI,eAAe;IACf,6BAA6B;IAC7B,UAAU;IACV,SAAS;IACT,kBAAkB;;AAEtB;AACA;IACI,SAAS;AACb;AACA;IACI,UAAU;AACd;AACA;IACI,YAAY;AAChB","sourcesContent":[".contentView{\n    position: relative;\n    left: 30px;\n    top: -75px;\n}\n\n.contentView span{\n    font-size: 11px;\n    font-family: 'Roboto-Regular';\n    color:#000;\n    left: 5px;\n    position: relative;\n\n}\n.VirtualAgentImage .contentView{\n    top: -6px;\n}\n.iconTextcolorClass .labelContentData {\n    left: 14px;\n}\n.engagementSection label.text-box-title.adding-other-input {\n    height: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
