import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import ButtonComponent from "../../components/UIComponents/Button/ButtonComponent";
import axios from 'axios';
import { Col,Row } from 'react-bootstrap';
import './encodePass.css'
import {
  GetCalltoOUServer,
  PostCallToOUServer,
  UpdateCalltoOUServer,
} from "./SettingInnerPages/UserManagement/helpers/utility";
import ShowAndHidePassword from "./ShowAndHidePassword";
import Form from "react-bootstrap/Form";

class EncodePass extends Component {

  constructor(props) {
    super(props);
    this.state = {
      encodepassText:'',
      newEncodepass:'',
        username:'',
        password:'',
        orgname:localStorage.getItem('orgName'),
      encodeBtn:true,
      userlist:[],
      //value
      ValidDate: new Date()

    }
  }

  componentDidMount() {
    const orgname = localStorage.getItem("orgName");
    const url = this.props.config.COMMON_API_URL + `/organization/user?orgname=${orgname}`;

  PostCallToOUServer(this.props.config.VIRTUAL_ASSISTANCE_URL + '/virtualAssistant/getEncodePassword',{orgname:this.state.orgname}).then((response) => {        
        if(response.data.status=="true"){
          this.setState({
            username:response.data.data.username,
            ValidDate:response.data.data.ValidDate
          })
        }
      })
      .catch((err) => {
        console.log(err);
      });
  GetCalltoOUServer(url)
      .then((response) => {       
        if (response.data != null) {                
          const allEmailIds= response.data.map(obj=> obj.username);
          console.log(allEmailIds)
          this.setState({
            userlist:allEmailIds
          })
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  submitBtn=(e) =>{
    e.preventDefault();
    let encodeDetails = {
      username:this.state.username,
      password:this.state.password,
      orgname:localStorage.getItem('orgName'),
      ValidDate:this.state.ValidDate
    }
    PostCallToOUServer(this.props.config.VIRTUAL_ASSISTANCE_URL + '/virtualAssistant/encodePassword',encodeDetails).then((res) => {        
      this.setState({
        encodepassText:res.data.msg,
        newEncodepass:'',
        username:'',
        password:'',
        orgname:'',
        encodeBtn:true
      })
      setTimeout(() => {
        this.setState({
          encodepassText:''
        })
      }, 5000);
    })
    .catch((err) => {
      console.log(err);
    });
    
    } 
      onChangePass = (value) => {
        let textValid = value ? true : false;        // basic text validation
        let submitValid = this.state.username && this.state.orgname && textValid 
          this.setState({
          password:value,
          encodeBtn : !submitValid
        })
      }

       handleChange = (e) => {
        let textValid = e.target.value ? true : false;        // basic text validation
        let submitValid = this.state.password && this.state.orgname && textValid 
        this.setState({
          username:e.target.value,
          encodeBtn : !submitValid
          })
      };

    
    render() {
    return (
    <>
    <Row className='encodepassStyling'>
    <Col md={12}><h3>Encode Password</h3></Col>
    
    <Col md={12}>
    <Row className='encodePass'>
      <Col md={12} className='encodePassCol'>
        <div>
          <label>Username</label>
        <Select
          value={this.state.username?this.state.username:''}
          onChange={this.handleChange}> 
        {this.state.userlist.length>0 && this.state.userlist.map((u)=>
          <MenuItem value={u}>{u}</MenuItem>)
        }
        </Select>
        </div>
      </Col>

    <Col md={12} className='encodePassCol'>
      <div>
          <ShowAndHidePassword handleClick={this.onChangePass}/>
      </div>
    </Col>
      <Col md={12} className='encodePassCol'>
      <div>
        <label>Org Name</label>
        <TextField type="text" name="orgname" value={this.state.orgname} disabled   ></TextField>  
       </div>
      </Col>
      <Col md={12} className='encodePassCol'>
      <div>
        <label>Valid Till</label>
        <Form.Control className='validDate' type="date" 
        name="duedate"
        value={this.state.ValidDate}
        onChange={(e) => this.setState({ValidDate:e.target.value})}
        min={new Date().toISOString().split("T")[0]}/>
       </div>
      </Col>
    </Row>
    </Col>
    
    <Col md={12} className='btnOuter'>
    <ButtonComponent
                  variant='contained'
                  className='buttonWidth'
                  label={'Submit'}
                  clicked={this.submitBtn}
                  btnType="blueButton" 
                  disabled={this.state.encodeBtn}/>
    </Col>
    <Col  md={12}><h4>{this.state.encodepassText}</h4></Col>
    </Row>
    </>);
    }
}

export default EncodePass;