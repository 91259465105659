import React, { Component } from 'react';
import './Drawer.css';
import * as actions from '../../../store/actions/index'
import DrawerComponent from '../../UIComponents/Drawer/DrawerComponent';
import SelectComponent from '../../UIComponents/SelectDropdown/SelectComponent';
import SynonymsDropzoneComponent from '../../UIComponents/DropzoneComponent/SynonymsDropzoneComponent';
import SpinnerPage from '../Loader/Loader';
import InputWithLabelComponent from '../../../containers/SettingPage/SettingInnerPages/components/InputWithLabelComponent/InputWithLabelComponent';
import TextAreaWithLabelComponent from '../../../containers/SettingPage/SettingInnerPages/components/TextAreaWithLabelComponent/TextAreaWithLabelComponent';
import ButtonComponent from '../../UIComponents/Button/ButtonComponent';
import { connect } from 'react-redux';
import withErrorHandler from '../../../hoc/withErrorHandler/withErrorHandler';
import axios from 'axios';

class AddSynonymsAndAcronyms extends Component {
    constructor(props) {
        super(props);
        this.state = {
            right: true,
            word: "",
            synonym: "",
            KnowledgeDropdown: {
                elementType: 'select',
                elementConfig: {
                    options: [
                        // { value: 'Upload FAQ document', displayValue: 'Upload FAQ from local' },
                        { value: 'Individual questions', displayValue: 'Add Synonyms & Acronyms' },
                    ]
                },
                value: 'Add Synonyms & Acronyms',
                className: 'selectLang'
            }
        }
    }
    ImportFAQIntents = (files) => {
        this.state.files = files
        let projectname = localStorage.getItem("projectname");
        if (!projectname)
            projectname = "common";
        let language = localStorage.getItem("language");
        if (!language) {
            language = "en";
        }
        let fileList = []
        let formData = new FormData();
        let isValidate = false
        files.map((item) => {
            fileList.push(item.name)
            formData.append('files', item)
        })
        formData.append('projectName', projectname);
        formData.append('validate', isValidate)
        formData.append('language', language);
    }
    cancelKnowledgeHandler = () => {

    }
    onchangeFile = () => {
        this.setState({ showFiles: false, failure: false })
    }
    setValidationMessage = (type, value) => {


    }

    toggleDrawer = (open) => (event) => {
        if (!open) {
            this.props.onHide('synonyms');
        }
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            this.props.onHide('synonyms');
            return;
        }
        this.setState({ right: open });
    };

    inputChangedKnowledgeHandler = (event) => {
        this.setState({
            KnowledgeDropdown: {
                ... this.state.KnowledgeDropdown,
                value: event.target.value
            },
        });
        if (event.target.value == "Upload FAQ document") {
            this.setState({
                showFiles: false,
            })
        }
    }

    inputChangedSynonymHandler = (event, control) => {
        this.setState({
            [control]: event.target.value
        });

    }

    qaSubmitHandler = () => {
        let obj = {
            project: localStorage.getItem("projectname"),
            word: this.state.word,
            synonym: this.state.synonym,
            language: "en"
        }
        this.props.onSaveSynonym(obj, this.props.config.BOTBUILDER_URL);
        this.setState({
            word: '',
            synonym: ''
        })
        this.props.onHide('synonyms');
    }

    render() {
        let uploadloadingImg = null;
        if (this.props.uploadloading) {
            uploadloadingImg = (<SpinnerPage />)
        }
        return (
            <React.Fragment >
                <DrawerComponent heading="Synonyms & Acronyms Details"
                    showDrawer={this.toggleDrawer(false)}
                    openDrawer={this.props.show}>
                    <div className='drawer add-entity'>
                        <div className='drawer-body mt-2'>
                            <SelectComponent
                                name="selectKnowledgeDropdown"
                                label="Add word & Synonym"
                                changed={
                                    (event) => this.inputChangedKnowledgeHandler(event)
                                } required={true}
                                value={this.state.KnowledgeDropdown.value}
                                options={this.state.KnowledgeDropdown.elementConfig.options}
                            />
                            {this.state.KnowledgeDropdown.value === "Upload FAQ document" ?
                                <SynonymsDropzoneComponent
                                    ImportFAQIntents={this.ImportFAQIntents}
                                    showFiles={this.state.showFiles}
                                    failure={this.state.failure}
                                    cancelKnowledgeHandler={this.cancelKnowledgeHandler}
                                    onchangeFile={this.onchangeFile}
                                    setValidationMessage={this.setValidationMessage}
                                    uploadloadingImg={uploadloadingImg}
                                    // knowledgeUpload={this.props.knowledgeUploadfailure}
                                    knowledgeUpload={() => { }}
                                    validate="true" /> :
                                <div className="my-3">
                                    <InputWithLabelComponent
                                        type="text"
                                        name="word"
                                        label="Word*"
                                        placeHolder=""
                                        value={this.state.word}
                                        onChange={(event) => this.inputChangedSynonymHandler(event, "word")}
                                        required={true}
                                        // isInvalid={formElement.error}
                                        // error={formElement.error}
                                        className={"w-100 mb-3"}
                                    />
                                    <TextAreaWithLabelComponent
                                        name="synonym"
                                        label="Synonym/Acronym*"
                                        placeHolder=""
                                        value={this.state.synonym}
                                        onChange={(event) => this.inputChangedSynonymHandler(event, "synonym")}
                                        required={true}
                                        // isInvalid={formElement.error}
                                        // error={formElement.error}
                                        className={"w-100 mb-3"}
                                    />
                                    <ButtonComponent
                                        disabled={!(this.state.synonym && this.state.word)}
                                        variant='contained'
                                        label='Save'
                                        className='buttonWidth my-2'
                                        clicked={(event) => this.qaSubmitHandler()} />
                                </div>
                            }
                        </div>
                    </div>
                </DrawerComponent>
            </React.Fragment>
        );
    }
}
const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSaveSynonym: (knowledgeData, APIURL) => dispatch(actions.saveSynonymWord(knowledgeData, APIURL)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(AddSynonymsAndAcronyms, axios));
