import React from 'react';
import Select from 'react-select';
import isEmpty from 'lodash/isEmpty';
import Form from 'react-bootstrap/Form';
import * as PropTypes from 'prop-types';
import "./react-select.css";
import { isNil } from 'lodash';

const ReactSelectComponent = ({
    options,
    value,
    onChange,
    label,
    allOption,
    disabled,
    placeholder,
    onRemoveItem,
    ...rest
}) => (
        <div className='react-select'>
            {label ?
                <div className="select-label">
                    <Form.Label>{label}</Form.Label>
                </div> : null
            }
            <Select
                value={isEmpty(value) ?
                    [] :
                    options.filter(item => value.includes(item.value))}
                isMulti
                placeholder={placeholder}
                name="Forecast Display"
                options={[allOption, ...options]}
                className='multi-select-container'
                classNamePrefix="multi-select"
                isDisabled={disabled}
                hideSelectedOptions={false}
                closeMenuOnSelect={true}
                tabSelectsValue={false}
                controlShouldRenderValue={false}
                onChange={selected => {
                    if (isNil(selected)) {
                        return onChange([]);
                    }
                    if (selected.length > 0 &&
                        selected[selected.length - 1].value === allOption.value
                    ) {
                        return onChange(options);
                    }
                    return onChange(selected);
                }}
                {...rest}
            />
            <div className='options'>
            {value && value.length ? value.map((item, index) => <div className="selected-item">
                <div>{`${index+1}, ${item}`}</div>
                <div onClick={() => onRemoveItem(item)} className='remove-icon'>
                    <div className="rounded-circle select-close-icon">x</div>
                </div>
                </div>) : ''}
            </div>
        </div>
    );

ReactSelectComponent.propTypes = {
    options: PropTypes.array,
    id: PropTypes.string,
    allOption: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
    }),
    disabled: PropTypes.bool
};

ReactSelectComponent.defaultProps = {
    options: [],
    id: '',
    allOption: {
        label: "Select all",
        value: "*"
    },
    disabled: false,
};

export default ReactSelectComponent;
