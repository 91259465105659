import React from 'react';
import { connect } from 'react-redux';
import './Table.css';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 750,
    },
    tableContainer: {
        maxHeight: 332,
        width: '104%',
        borderLeft: 'none !important',
        [theme.breakpoints.up('lg')]: {
            maxHeight: 480,
        },
        [theme.breakpoints.between('1300', '1600')]: {
            maxHeight: 332,
        },
    },
    tableHeader: {
        fontWeight: 700,
        backgroundColor: '#F2F1F1',
        width: '-1px',
        color: '#000000',
        fontFamily: 'Roboto-Regular',
        fontStyle: 'normal',
        fontSize: 13,
        fontVariant: 'small-caps',
        textTransform: 'uppercase',
    },
    tableBodyCell: {
        padding: '8px 16px',
        fontFamily: 'Roboto-Regular',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 12,
        color: '#000000',

    },
    menuItems: {
        fontSize: 14,
        fontWeight: 'bold',
        fontFamily: 'Roboto-Regular',
        color: 'black',
        '&.active, &:hover, &.active:hover': {
            background: 'none',
            color: '#2A93DF'
        }
    }
}));

function SynonymTable(props) {
    const classes = useStyles();
    return (
        <>
            {props.smartFaqSearchResultArray != undefined && props.smartFaqSearchResultArray.length !== 0 ?
                <TableContainer className={classes.tableContainer}>
                    <Table className={classes.table} aria-label="simple table" stickyHeader >
                        <TableHead className='myownclass'>
                            <TableRow className={classes.tableHeaderRow}>
                                {props.columnDefs != undefined && props.columnDefs !== null ? props.columnDefs.map((headerName) => {
                                    return (
                                        <>
                                            <TableCell className={classes.tableHeader}>{headerName.headerName} </TableCell>
                                        </>
                                    )
                                }) : null}
                                <TableCell className={classes.tableHeader}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody >
                            {props.smartFaqSearchResultArray != undefined && props.smartFaqSearchResultArray !== null ?
                                props.smartFaqSearchResultArray.map((item) => {
                                    return (
                                        <>
                                            <TableRow key={item.recordId} className='myownclass' >
                                                <TableCell 
                                                onClick={() => props.AIStandard.RW && props.onGridRowClicked(item)} 
                                                className={classes.tableBodyCell}>
                                                    {item.word.length > 45 ? item.word.slice(0, 45) + '...' : item.word}
                                                </TableCell>
                                                <TableCell onClick={() => props.AIStandard.RW && props.onGridRowClicked(item)} className={classes.tableBodyCell}>
                                                    {item.synonym.length > 45 ? item.synonym.slice(0, 45) + '...' : item.synonym}
                                                </TableCell>
                                                <TableCell className={classes.tableBodyCell}>
                                                    <img src='./Icons/OptionsIcon.svg' aria-controls="simple-menu" aria-haspopup="true"
                                                        onClick={props.handleClick(item)} style={{ padding: '14px', cursor: 'pointer', position: 'relative', right: '30px' }} />
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    )
                                }) : null}
                        </TableBody>
                    </Table>
                </TableContainer> : ''}
            <Menu
                id={props.menuID ? props.menuID : "simple-menu"}
                anchorEl={props.anchorElement}
                keepMounted
                open={Boolean(props.anchorElement)}
                onClose={props.handleMenuClose}
            >
                {/* <MenuItem
                    className={props.AIStandard.RW ? `${classes.menuItems} ` : `${classes.menuItems} click-access`}
                    onClick={() => props.editData(props.incomingData)}>EDIT</MenuItem> */}
                <MenuItem
                    className={(props.AIStandard.RW || props.csUpload )? `${classes.menuItems}` : `${classes.menuItems} click-access`}
                    onClick={() => props.deleteData(props.incomingData)}>DELETE</MenuItem>
            </Menu>
        </>
    )
}


const mapStateToProps = (state) => {
    return {
        AIStandard: state.user.moduleAccess.conversationalStandard,
        csUpload: state.user.moduleAccess.cognitiveOtherDocsUpload.WR,
    };
};

const mapDispatchToProps = () => {
    return {

    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SynonymTable);
