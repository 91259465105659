import React,{ useState, useEffect }  from 'react';
import { Button,Form } from 'react-bootstrap';

import Card from 'react-bootstrap/Card';
import Input from '../UI/Input/InputCustom';
import { PostCalltoServer } from '../../store/utility';
import { SetSuccessMessage } from '../../store/actions';
import { useDispatch } from 'react-redux';
import get from 'lodash/get';

const KnowledgeSearch = ({config}) => {
    const dispatch = useDispatch();
    const knowledge = {

        summaryLength: {
            elementType: 'select',
            elementConfig: {
                options: [
                    { value: 15, displayValue: '15 words' },
                    { value: 20, displayValue: '20 words' },
                    { value: 25, displayValue: '25 words' },
                    { value: 30, displayValue: '30 words' }
                ]
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            className: 'selectLang'
        },
        knowledgeCount: {
            elementType: 'select',
            elementConfig: {
                options: [
                    { value: 3, displayValue: '3' },
                    { value: 5, displayValue: '5' },
                    { value: 8, displayValue: '8' },
                    { value: 10, displayValue: '10' }
                    
                ]
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            className: 'selectLang'
        }
    }
    
    const [sizeChangedVal,SetsizeChangedVal] = useState(3);
    const [lengthChangedVal,SetlengthChangedVal] = useState('');
    useEffect(() => {
        getCognitiveSearchConfig()
      }, []);
    
    const getCognitiveSearchConfig = () => {
        let data = {}
        let url = config.API_URL + `/getCognitiveSearchConfig`;
        PostCalltoServer(url, data)
            .then(response => {
				
				if (get(response, 'data.status.responseType', '') === "success") {
                    const data = response.data.data;
					console.log("79 data",data);
                    SetsizeChangedVal(data.searchSize)
                    SetlengthChangedVal(data.maxLength)
                }
            }).catch(err => {
                console.log("error",err)
            });
    };
    const sizechange=(event)=>{
        SetsizeChangedVal(event.target.value)
    }

    const lengthchange = (event) => {
        SetlengthChangedVal(event.target.value)
    }
    
    const saveCognitiveSearchConfig = () => {
        
        let data = {
            "searchSize": sizeChangedVal,
            "threshold": 89,
            "maxLength": lengthChangedVal,
            "project": localStorage.getItem("projectname"),
            "org": ""
        }
        let url = config.API_URL + `/saveCognitiveSearchConfig`;
        
        PostCalltoServer(url, data)
            .then(response => {
				
				if (get(response, 'data.status.responseType', '') === "success") {
                    dispatch(SetSuccessMessage('Data save Successfully'));
                }
            }).catch(err => {
                console.log("error",err)
            });
    };

    return (
        <div style={{marginTop:"16px"}}>
                <Card>
                        <Card.Body>
                            <div className="col-md-4 PredictedIssuesCount">
                                <Form.Group>
                                    <Form.Label>Size of the Search List</Form.Label>
                                    {console.log("size = ",sizeChangedVal)}
                                    <Input
                                       key="knowledgeCount"
                                       elementType="select"
                                       elementConfig={knowledge.knowledgeCount.elementConfig}
                                       changed={(event) => sizechange(event)}
                                        value={sizeChangedVal}
                                    />
                                </Form.Group>
                            </div>

                            {/* <div className="SimilarityLevel">
                                <p>Search relevence - Confidence Threshold</p>
                                <Slider
                                    minValue={50}
                                    maxValue={100}
                                    //value={50}
                                    
                                />
                            </div> */}
                           
                            <div className="col-md-4 SummaryLength" >
                                <Form.Group>
                                    <Form.Label>Max Response length</Form.Label>
                                    <Input
                                        key="summaryLength"
                                        elementType="select"
                                        elementConfig={knowledge.summaryLength.elementConfig}
                                        changed={(event) => lengthchange(event)}
                                        value={lengthChangedVal}
                                    />
                                </Form.Group>
                            </div>
                            
                        </Card.Body>
                </Card>

                <div style={{display:'flex',justifyContent:'center',paddingTop:"16px"}}>
                        <Button
                            variant="primary"
                            className='large-border-radius'
                            style={{width:'100px'}}
                            onClick={saveCognitiveSearchConfig}>
                                Save
                        </Button>                   
                </div>       
        </div>
    );
}

export default KnowledgeSearch;