import React from 'react';
import * as actions from '../../../store/actions/index';
import './UserDescription.css';
import { connect } from 'react-redux';
import moment from 'moment';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import StackedBarChart from './StackedBarChart';
import ChartDataLabels from "chartjs-plugin-datalabels";
import MetricCard from './MetricCard';
import UtterancesComponent from './UtterancesComponent';
import HorizontalBarChart from '../../../components/UI/Charts/HorizontalBarChart';
import SpinnerPage from '../../../components/UI/Loader/Loader';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

ChartJS.defaults.borderColor = "#b8b6b6";
const allProj = 'All VA Projects';
class UserDescription extends React.Component {

    constructor(props) {
        super(props);
        let projectName = "All VA Projects"
        let vaProject = "All"
        let reportDetail = this.props.reportDetail
        let startDate = this.props.startDate ? this.props.startDate : moment().subtract(6, 'days');
        let endDate = this.props.endDate ? this.props.endDate : moment();
        if (reportDetail != null) {
            startDate = moment(reportDetail.startDate)
            endDate = moment(reportDetail.endDate)
            projectName = reportDetail.project
            vaProject = reportDetail.virtualAssist
        }
        this.state = {
            selectedCal: '',
            selectedCatValue: 0,
            selectedCategory: '',
            projectname: projectName,
            vaName: vaProject,
            searchT: "",
            popperShow: false,
            botname: '',
            startDate: startDate,
            endDate: endDate,
            selectedMetrics: '',
            SelectedFlowList: [],
            SelectedCumulative: 'cumulative',
        }
    }

    handleCategoryChange = (cat, val) => {
        this.setState({
            selectedCategory: cat,
            selectedCatValue: val
        })
    }

    calUtterances = (msg) => {
        let details = this.props.reportDetail;
        let projName = this.state.projectname
        if (!details) {
            details = {
                "startDate": moment(this.state.startDate).format('YYYY-MM-DD'),
                "endDate": moment(this.state.endDate).format('YYYY-MM-DD'),
                "project": projName == 'common' ? allProj : projName,
                "orgName": localStorage.getItem('orgName'),
                "virtualAssist": this.state.vaName
            }
        }
        // if (details && msg) {

        //     details.message = msg;
        //     //this.props.onTotalfeedbackUtterances(this.props.config.BOTBUILDER_URL, details);
        //     this.props.conFeedbackNegativeUtterance(this.props.config.BOTBUILDER_URL, details)
        // }
    }

    calAPI() {
        let details = this.props.reportDetail;
        let projName = this.state.projectname
        if (details == null) {
            details = {
                "startDate": moment(this.state.startDate).format('YYYY-MM-DD'),
                "endDate": moment(this.state.endDate).format('YYYY-MM-DD'),
                "project": projName == 'common' ? allProj : projName,
                "orgName": localStorage.getItem('orgName'),
                "virtualAssist": this.state.vaName
            }
        }
        this.props.conversationalMetricsData(this.props.config.BOTBUILDER_URL, details)
        this.props.conversationFeedbackMetricsData(this.props.config.BOTBUILDER_URL, details);
        this.props.conFeedbackNegativeUtterance(this.props.config.BOTBUILDER_URL, details);
          
    }
    componentDidMount() {
        this.calAPI()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.reportDetail !== this.props.reportDetail) {
            this.calAPI()
        }
        let msgNegativeCategory = this.props.conFeedbackMetricsData?.negativeCategories
        if( prevProps.conFeedbackMetricsData != this.props.conFeedbackMetricsData){
            if (this.props.conFeedbackMetricsData?.negativeCategories) {
                let sortable = []
                for (let [key, value] of Object.entries(msgNegativeCategory)) {
                    sortable.push([key, value]);
                }
                sortable = sortable.sort((a, b) => b[1] - a[1]);
                let msgCatTitle = sortable.length != 0 ? sortable[0][0] : []
                this.setState({ selectedCategory: msgCatTitle })
            }
        }
    }

    setSearchTerm = (item) => {
        this.setState({ searchT: item })
    }

    popperShowFn = () => {
        this.setState({
            popperShow: true,
        })
    }


    backToReportPage = () => {
        this.props.history.push('/ConversationMetrics?back')
    }


    onProjectDropDown = (event) => {
        this.setState({
            selectedFlow: event.target.value,
        })
        let projName = localStorage.getItem('projectname')
        let data = {
            "startDate": moment(this.props.reportDetail.startDate).utcOffset("+05:30").format('YYYY-MM-DD'),
            "endDate": moment(this.props.reportDetail.endDate).utcOffset("+05:30").format('YYYY-MM-DD'),
            "project": projName == 'common' ? allProj : projName,
            "orgName": localStorage.getItem('orgName'),
        }
        this.props.ReportPageData(data)
    }

    handleEvent = (event, picker) => {
        this.setState({
            startDate: picker.startDate,
            endDate: picker.endDate,
        });
        let projName = this.state.projectname
        let data = {
            "startDate": moment(this.state.startDate).utcOffset("+05:30").format('YYYY-MM-DD'),
            "endDate": moment(this.state.endDate).utcOffset("+05:30").format('YYYY-MM-DD'),
            "project": projName == 'common' ? allProj : projName,
            "orgName": localStorage.getItem('orgName'),
        }
        this.props.ReportPageData(data)
        this.calAPI(this.state.selectedMetrics)
    }

    getLineChartdata = () => {
        let showdata = '';
        let sorted_data;
        let details = [];
        let feedbackLabel = [];
        let positiveFeedback = [];
        let negativeFeedback = [];
        let totaluser = this.props.conFeedbackMetricsData?.dateWiseFeedbackCount
        if (totaluser) details = totaluser ? totaluser : []

        let weekly = false;

        if (details) {
            for (let [key, value] of Object.entries(details)) {
                if (key.toLowerCase() === "day" && Object.keys(value[0]).length>=1 && value.length < 8) {
                    sorted_data = value;
                    showdata = "day"
                    break

                } else if (key.toLowerCase() === "week" && Object.keys(value[0]).length>=1 && value.length <= 9) {
                    sorted_data = value;
                    weekly = true
                    showdata = 'week'

                } else if (key.toLowerCase() === 'month' && value.length > 0 && !weekly) {
                    sorted_data = value;
                    showdata = 'month'

                }else if(key.toLowerCase() === 'year' && Object.keys(value[0]).length>=1 ){
                    sorted_data = value;
                    showdata = 'year'
                }
            }
        }

        if (sorted_data) {
            sorted_data.forEach((v) => {
                (showdata == 'year') ? feedbackLabel.push(`${moment(Object.keys(v)[0]).format("YYYY")}`) :(showdata == 'month') ? feedbackLabel.push(`${moment(Object.keys(v)[0]).format("MMM")}`) : feedbackLabel.push(`${moment(Object.keys(v)[0]).format("DD")}-${moment(Object.keys(v)[0]).format("MMM")}`)
                if (Object.values(v)[0]) {
                    let posdata = Object.values(v)[0][0]
                    for (let [key, value] of Object.entries(posdata)) {
                        if (key === "Yes") {
                            positiveFeedback.push(value)
                        } else if (key === "No") {
                            negativeFeedback.push(value)
                        }
                    }
                }
            });
        }
        let data = {
            labels: feedbackLabel,
            datasets: [
                {
                    label: 'Positive',
                    data: positiveFeedback,
                    fill: false,
                    backgroundColor: "#E3CC7A",
                    borderColor: "#E3CC7A",
                    pointBorderWidth: 4,
                    pointBorderColor: "#FFFFFF",
                    pointBackgroundColor: '#E3CC7A',
                    pointRadius: 6,
                    borderDash: [2, 2],
                },
                {
                    label: 'Negative',
                    data: negativeFeedback,
                    fill: false,
                    backgroundColor: "#F38787",
                    borderColor: "#F38787",
                    pointBorderWidth: 4,
                    pointBorderColor: "#FFFFFF",
                    pointBackgroundColor: '#F38787',
                    pointRadius: 6,
                    borderDash: [2, 2],
                }
            ],
        };
        const options = {
            //maintainAspectRatio: false, // Don't maintain w/h ratio
            responsive: true,
            scales: {
                x: {
                    title: {
                        display: true,
                        color: '#000',
                        font: {
                            family: 'Roboto-Bold',
                            size: 12,
                            style: 'normal',
                            lineHeight: 1.2,
                        },
                        padding: { top: 10, left: 5, right: 5, bottom: 0 }
                    },
                    grid: {
                        color: '#B8B6B6',
                        display: false,
                        drawOnChartArea: false,
                    },
                    ticks: {
                        precision: 0
                    }
                },
                y: {
                    title: {
                        display: true,
                        color: '#000',
                        font: {
                            family: 'Roboto-Bold',
                            size: 12,
                            style: 'normal',
                            lineHeight: 1.2
                        },
                        padding: { top: 0, left: 0, right: 0, bottom: 5 }
                    },
                    grid: {
                        color: '#B8B6B6',
                        display: true,
                        drawOnChartArea: true,
                        drawBorder: false
                    },
                    ticks: {
                        precision: 0
                    }
                },
            },
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    mode: 'index',
                    intersect: false,
                    displayColors: false,
                    callbacks: {
                        title: function () { }
                    }
                }
            }
        }
        return {
            lineData: data,
            lineOptions: options
        }
    }

    getStackedChartdata = () => {
        let showdata = '';
        let sorted_data;
        let details = [];
        let feedbackLabel = [];
        let positiveFeedback = [];
        let negativeFeedback = [];
        let totaluser = this.props.conFeedbackMetricsData?.dateWiseFeedbackCount
        if (totaluser) details = totaluser ? totaluser : []

        let weekly = false;

        if (details) {
            for (let [key, value] of Object.entries(details)) {
                if (key.toLowerCase() === "day" && Object.keys(value[0]).length>=1 && value.length < 8) {
                    sorted_data = value;
                    showdata = "day"
                    break

                } else if (key.toLowerCase() === "week" && Object.keys(value[0]).length>=1 && value.length <= 9) {
                    sorted_data = value;
                    weekly = true
                    showdata = 'week'

                } else if (key.toLowerCase() === 'month' && Object.keys(value[0]).length>=1 && !weekly) {
                    sorted_data = value;
                    showdata = 'month'

                }else if(key.toLowerCase() === 'year' && Object.keys(value[0]).length>=1 ){
                    sorted_data = value;
                    showdata = 'year'
                }
            }
        }

        if (sorted_data) {
            sorted_data.forEach((v) => {
                (showdata == 'year') ? feedbackLabel.push(`${moment(Object.keys(v)[0]).format("YYYY")}`) : (showdata == 'month') ? feedbackLabel.push(`${moment(Object.keys(v)[0]).format("MMM")}`) : feedbackLabel.push(`${moment(Object.keys(v)[0]).format("DD")}-${moment(Object.keys(v)[0]).format("MMM")}`)
                if (Object.values(v)[0]) {
                    let posdata = Object.values(v)[0][0]
                    for (let [key, value] of Object.entries(posdata)) {
                        if (key === "Yes") {
                            positiveFeedback.push(value)
                        } else if (key === "No") {
                            negativeFeedback.push(value)
                        }
                    }
                }
            });
        }

        let options = {
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    mode: 'index',
                    intersect: false,
                    displayColors: false,
                    callbacks: {
                        title: function () { }
                    }
                },
                title: {
                    display: false,
                },
                datalabels: {
                    display: true,
                    color: "black",
                    align: 'center',
                    formatter: function (value, context) {
                        return value == 0 ? '' : value;
                    }
                }
            },
            responsive: true,
            scales: {
                x: {
                    title: {
                        display: true,
                        color: '#000',
                        font: {
                            family: 'Roboto-Bold',
                            size: 12,
                            style: 'normal',
                            lineHeight: 1.2,
                        },
                        padding: { top: 10, left: 5, right: 5, bottom: 0 }
                    },
                    grid: {
                        display: false,
                        drawOnChartArea: false,
                    },
                    ticks: {
                        precision: 0
                    },
                    stacked: true
                },
                y: {
                    title: {
                        display: true,
                        color: '#000',
                        font: {
                            family: 'Roboto-Bold',
                            size: 12,
                            style: 'normal',
                            lineHeight: 1.2
                        },
                        padding: { top: 0, left: 0, right: 0, bottom: 5 }
                    },
                    grid: {
                        color: '#B8B6B6',
                        display: true,
                        drawOnChartArea: true,
                        drawBorder: false
                    },
                    ticks: {
                        precision: 0
                    },
                    stacked: true
                },
            },
        };

        let data = {
            labels: feedbackLabel,
            datasets: [
                {
                    label: 'Positive',
                    data: positiveFeedback ? positiveFeedback : 0,
                    barPercentage: 0.5,
                    backgroundColor: "#e3cc7a",
                },
                {
                    label: 'Negative',
                    data: negativeFeedback ? negativeFeedback : 0,
                    barPercentage: 0.5,
                    backgroundColor: "#f38787",
                }
            ],
        };
        return {
            stackedData: data,
            stackedOptions: options
        }
    }

    topNegativeCatEvent = (evt, element, msg) => {
        if (element.length > 0) {
            let msgDetails = this.props.reportDetail;
            let projName = this.state.projectname
            if (msgDetails == null) {
                msgDetails = {
                    "startDate": moment(this.state.startDate).format('YYYY-MM-DD'),
                    "endDate": moment(this.state.endDate).format('YYYY-MM-DD'),
                    "project": projName == 'common' ? allProj : projName,
                    "orgName": localStorage.getItem('orgName'),
                    "virtualAssist": this.state.vaName
                }
            }
            msgDetails.message = msg.trim()
            //this.props.conFeedbackNegativeUtterance(this.props.config.BOTBUILDER_URL, msgDetails)
            this.setState({
                selectedCategory: msg
            })
        }
    }

    render() {
        let hKeys = [], hValues = [];
        if (this.props.conFeedbackMetricsData?.negativeCategories) {
            let apiCatData = this.props.conFeedbackMetricsData?.negativeCategories;
            hKeys = [...Object.keys(apiCatData)]
            let catObject = [];
            hKeys.forEach(ele => {
                catObject.push({
                    key: ele,
                    value: apiCatData[ele]
                })
            })
            catObject = [...catObject];
            catObject = catObject.sort((obj1, obj2) => obj2.value - obj1.value)
            hKeys = []
            catObject.forEach(sortedEle => {
                hKeys.push(sortedEle.key)
                hValues.push(sortedEle.value)
            })
        }
           
            let utUnsTitle=[], utUnsVal =[], totalUnansCount=0; 
            if (this.props.conNegativeUtteranceData) {
                let negativeUtterance = this.props.conNegativeUtteranceData; 
                for (let [key, value] of Object.entries(negativeUtterance)){
                    if (key === this.state.selectedCategory) {
                            utUnsTitle = Object.keys(value),
                            utUnsVal = Object.values(value),
                            totalUnansCount = Object.values(value).reduce((a, b) => parseInt(a) + parseInt(b), 0)
                    }
            }
        }
        let details = {}
        let feedbackReport = this.props.feedbackReportData
        details = feedbackReport != null ? JSON.parse(feedbackReport) : []

        let positiveFeedbackCount = 0;
        let negitiveFeedbackCount = 0;

        if (this.props.conMetricsData !== null && this.props.conMetricsData !== undefined) {
            positiveFeedbackCount = this.props.conMetricsData?.positive;
            negitiveFeedbackCount = this.props.conMetricsData?.negative;
        }
        let { lineData, lineOptions } = this.getLineChartdata();
        let { stackedData, stackedOptions } = this.getStackedChartdata();

        let bgColorUCat = [];
        hKeys.forEach((element, i) => {
            if (this.state.selectedCategory == element)
                bgColorUCat.push('rgb(243,135,135,0.7)')
            else
                bgColorUCat.push('rgb(232,229,229,0.7)')
        });

        return (
            <>
                {this.props.conFeedbackLoading === true || this.props.conNegativeUtteranceLoading === true ? <SpinnerPage /> : null}
                <div className='d-flex mb-3'>
                    <div className='detail-card'>
                        <MetricCard
                            heading={"Positive"}
                            count={positiveFeedbackCount ? positiveFeedbackCount : 0}
                            icon={"./Icons/con-senti.svg"}
                            backgroundColor={"#63465B"}
                            isLeftBorder={true}
                        />
                    </div>
                    <div className='detail-card negative-card'>
                        <MetricCard
                            heading={"Negative"}
                            count={negitiveFeedbackCount ? negitiveFeedbackCount : 0}
                            icon={"./Icons/negative-feedback.svg"}
                            backgroundColor={"#63465B"}
                            isLeftBorder={true}
                        />
                    </div>

                </div>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className="GraphContainer pr-4 mb-20">
                            <div className=" mb-2 graphOuterContainer pb-2">
                                <div className='title-row'>
                                    <div className='chart-title pb-1'>Conversational Feedback</div>
                                    <div className='d-flex label-row'>
                                        <span className='positive-pill'></span><span>Positive</span>
                                        <span className='negative-pill'></span><span>Negative</span>
                                    </div>
                                </div>
                            </div>
                            <div className='senti-line-graph-container'>
                                <StackedBarChart data={stackedData} options={stackedOptions} plugins={[ChartDataLabels]} />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className="GraphContainer mb-20">
                            <div className=" mb-2 graphOuterContainer pb-2">
                                <div className='title-row '>
                                    <div className='chart-title pb-1'>Feedback Trend</div>
                                    <div className='d-flex label-row'>
                                        <span className='positive-pill'></span><span>Positive</span>
                                        <span className='negative-pill'></span><span>Negative</span>
                                    </div>
                                </div>
                            </div>
                            <div className='senti-line-graph-container'>
                                <Line data={lineData} options={lineOptions} />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className="handledGraphContainer hbar-container">
                            <div className="graphOuterContainer">
                                <div className='title-row'>
                                    <div className='chart-title'>Top Negative Categories</div>
                                </div>
                            </div>
                           { <HorizontalBarChart labels={hKeys.slice(0, 12)} dataHorBar={hValues.slice(0, 12)} bgColor={bgColorUCat} clickEvent={this.topNegativeCatEvent} plugins={[ChartDataLabels]} selected={this.state.selectedCategory} />}
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='payments-div'>
                            <div className='payments-header'>{this.state.selectedCategory} ({totalUnansCount})</div>
                            <div className='payments-data-wrapper scroll_payment_div'>
                                { utUnsTitle && utUnsTitle.map((element, key) =>
                                    <div className="d-flex justify-content-between">
                                        <div>{element}</div>
                                        <div>{utUnsVal[key]}</div>
                                    </div>
                                )

                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        projectList: state.user.projects,
        // selectedCategory: state.reportDashboard.selectedCategory,
        feedbackCategoriesData: state.reportDashboard.feedbackCategoriesData,
        feedbackUtterancesData: state.reportDashboard.feedbackUtterancesData,
        totalfeedbackData: state.reportDashboard.totalFeedback,
        totalUsersReportData: state.reportDashboard.totalUsersReport,
        RepeatUserReport: state.reportDashboard.RepeatUserReport,
        NewUserReport: state.reportDashboard.NewUserReport,
        reportDetail: state.reportDashboard.reportDetail,
        AgentTransferReport: state.reportDashboard.totalAgentTransferReport,
        totalFlowReportData: state.reportDashboard.totalFlowReport,
        feedbackReportData: state.reportDashboard.feedbackReportData,
        totalChatSessionReport: state.reportDashboard.totalChatSessionReport,
        botList: state.projectview.botList,
        totalmeetingsreport: state.reportDashboard.totalmeetingsreport,
        totalintiatedbotreport: state.reportDashboard.totalintiatedbotreport,
        conFeedbackLoading: state.reportDashboard.conFeedbackLoading,
        conFeedbackReportLoading: state.reportDashboard.conFeedbackReportLoading,
        conFeedbackCategoryLoading: state.reportDashboard.conFeedbackCategoryLoading,
        conUtteranceLoading: state.reportDashboard.conUtteranceLoading,
        conFeedbackMetricsData: state.reportDashboard.conFeedbackMetricsData,
        conNegativeUtteranceData: state.reportDashboard.conNegativeUtteranceData,
        conMetricsData: state.reportDashboard.conMetricsData,
        conNegativeUtteranceLoading:state.reportDashboard.conNegativeUtteranceLoading       
    };
};
const mapDispatchToProps = dispatch => {
    return {
        // onSelectedCategory: (data) => dispatch(actions.selectedCategory(data)),
        onTotalfeedbackReport: (apiUrl, data) => dispatch(actions.totalfeedbackReport(apiUrl, data)),
        onTotalfeedbackCategories: (apiUrl, data) => dispatch(actions.totalfeedbackCategories(apiUrl, data)),
        onTotalfeedbackUtterances: (apiUrl, data) => dispatch(actions.totalfeedbackUtterances(apiUrl, data)),
        totalfeedback: (apiUrl, data) => dispatch(actions.totalfeedback(apiUrl, data)),
        ReportPageData: (data) => dispatch(actions.ReportPageData(data)),
        conversationFeedbackMetricsData: (apiUrl, data) => dispatch(actions.conversationFeedbackMetricsData(apiUrl, data)),
        conFeedbackNegativeUtterance: (apiUrl, data) => dispatch(actions.conFeedbackNegativeUtterance(apiUrl, data)),
        conFeedbackNegativeUtteranceStart:()=> dispatch(actions.conFeedbackNegativeUtteranceStart()),
        conversationalMetricsData: (APIURL,data) => dispatch(actions.conversationalMetricsData(APIURL,data))

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(UserDescription);

