const kbDocsChangeHandlerReducer = (state, payload) => {
    return {
        ...state,
        kbdocs: {
            ...state.kbdocs,
            ...payload
        }
    }
}

export default kbDocsChangeHandlerReducer;