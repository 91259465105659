import React from 'react';
import { Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import moment from 'moment';
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import MetricCard from './innerPages/MetricCard';
import { Line } from 'react-chartjs-2';
import SpinnerPage from '../../components/UI/Loader/Loader';
import ButtonComponent from '../../components/UIComponents/Button/ButtonComponent';
import './reportDashboard.css';
import DashboardFilters from './DashboardFilters';

const allProj = 'All Projects';
const allVaProj = 'All VA Projects'
let VaProjectName = '';
class CognitiveDashboard extends React.Component {
    constructor(props) {
        super(props);
        let reportDetail = this.props.reportDetail
        let location = this.props.location.search;
        let startDate = moment().subtract(6, 'days');
        let endDate = moment();
        let projectName = "All Projects";
        let vaProject = "None";
        if (location == "?back" && reportDetail != null) {
            startDate = moment(reportDetail.startDate)
            endDate = moment(reportDetail.endDate)
            projectName = reportDetail.project
            vaProject = reportDetail.virtualAssist
        }
        this.state = {
            usersCount: {
                heading: 'Users Count',
                headerIcon: '',
                backgroundColor: '#4D1CA6',
                count: 0,
                icon: './Icons/total-users.svg'
            },
            searchCount: {
                heading: 'Search Queries Count',
                headerIcon: '',
                backgroundColor: '#A62E99',
                count: 0,
                icon: './Icons/cog-dash-icon-2.svg',
            },
            cogSentiment: {
                heading: 'Feedback Sentiment',
                headerIcon: './Icons/up-arrow.svg',
                backgroundColor: '#4764E6',
                icon: './Icons/cog-dash-icon.svg',
                positiveCount: 0,
                negitiveCount: 0
            },
            cogHandled: {
                heading: 'Search Queries Handled',
                headerIcon: './Icons/up-arrow.svg',
                backgroundColor: '#4D1CA6',
                label1: '',
                label2: '',
                icon: './Icons/cog-dash-icon-3.svg',
                answeredCount: 0,
                unAnsweredCount: 0
            },
            botMetrics: [
                { heading: "Search Queries Asked", numbers: "0", key: "chat", icon: "Search-queries-icon" },
                { heading: "Unanswered", numbers: "0", key: "unanswered", icon: "unanswered" },
                { heading: "Feedback", numbers: "0", key: "feedback", icon: "feedback" },
            ],
            projectname: projectName,
            startDate: startDate,
            endDate: endDate,
            vaName: vaProject
        }
    }
    callUsersDetails = (data) => {
        let apiURL = this.props.config.BOTBUILDER_URL;
        this.props.cognitiveMetricsData(apiURL,data)

    }
    handleDidmountCall = () => {
        let location = this.props.location.search;
        if (location != "?back") {
            this.props.ReportPageData(null)
        }

    }
    componentDidMount() {
        setTimeout(() => {
            this.handleDidmountCall();
        }, 2000)
        let apiData = {
            "startDate": moment(this.state.startDate).format('YYYY-MM-DD'),
            "endDate": moment(this.state.endDate).format('YYYY-MM-DD'),
            "project": this.state.projectname,
            "orgName": localStorage.getItem('orgName'),
            "virtualAssist":this.state.vaName
        }
        let apiURL = this.props.config.BOTBUILDER_URL;
        this.props.OnGetAllAssistantsData(apiURL)
        let data = apiData;
        this.callUsersDetails(data);
    }
    UsersDetails = () => {
        let projName;
        projName = this.state.projectname ? this.state.projectname : allProj
        let apiURL = this.props.config.BOTBUILDER_URL;
        let data = {
            "startDate": moment(this.state.startDate).format('YYYY-MM-DD'),
            "endDate": moment(this.state.endDate).format('YYYY-MM-DD'),
            "project": projName,
            "orgName": localStorage.getItem('orgName'),
            "virtualAssist":this.state.vaName
        }
        this.props.cognitiveMetricsData(apiURL,data)
    }
    VAListClick = (data) => {
        if (data.value) {
            this.setState({ vaName: data.value })
        }
        this.setState({ projectname: data.value === "None" ? allProj : allVaProj })

        setTimeout(() => {
            this.UsersDetails();
        }, 100);
    }
    projectclick = (data) => {
        if (data.value) {
            this.setState({ searchT: "" })
            this.setState({ projectname: data.value });
            this.props.onchangeProjectAction(data.value);
            setTimeout(() => {
                this.UsersDetails();
            }, 100);

            let nrwdata = {
                "startDate": moment(this.state.startDate).format('YYYY-MM-DD'),
                "endDate": moment(this.state.endDate).format('YYYY-MM-DD'),
                "project": data.value,
                "orgName": localStorage.getItem('orgName'),
            }
            this.props.ReportPageData(nrwdata)
        }
    }

    getDateValues = (dateValue) => {
        let projName = this.state.projectname
        this.setState({
            startDate: dateValue.startDate,
            endDate: dateValue.endDate
        })
        setTimeout(() => {
            this.UsersDetails()
        }, 100);

        let data = {
            "startDate": moment(dateValue.startDate).format('YYYY-MM-DD'),
            "endDate": moment(dateValue.endDate).format('YYYY-MM-DD'),
            "project": projName == 'common' ? allProj : projName,
            "orgName": localStorage.getItem('orgName'),
        }
        this.props.ReportPageData(data)

    };
    exportData = () => {
        let projName = this.state.projectname
        let data = {
            "startDate": moment(this.state.startDate).utcOffset("+05:30").format('YYYY-MM-DD'),
            "endDate": moment(this.state.endDate).utcOffset("+05:30").format('YYYY-MM-DD'),
            "project": projName == 'common' ? allProj : projName,
            "orgName": localStorage.getItem('orgName'),
            "virtualAssist":this.state.vaName
        }

        this.props.onExportCognitiveReportData(this.props.config.BOTBUILDER_URL, data)

    }

    getGraphData = () => {

        let data = {}
        let showdata = '';
        let sorted_data;
        let details = []
        let totaluser = this.props.cogMetricsData?.dateWiseUsersCount
        if(totaluser) details = totaluser  ? totaluser : []

        let chart_data = [];
        let chart_labels = [];
        let weekly = false;

        if(details){
            for (let [key, value] of Object.entries(details)) {
                if(key.toLowerCase() === "day" && Object.keys(value[0]).length>=1 && value.length<8){
                    sorted_data = value;
                    showdata = "day" 
                    break 
                    
                }else if (key.toLowerCase() === "week" && Object.keys(value[0]).length>=1 && value.length <= 9){
                    sorted_data = value;
                    weekly = true
                    showdata = 'week'

                } else if(key.toLowerCase() === 'month' && Object.keys(value[0]).length>=1 && !weekly){
                    sorted_data = value;
                    showdata = 'month'

                }else if(key.toLowerCase() === 'year' && Object.keys(value[0]).length>=1 ){
                    sorted_data = value;
                    showdata = 'year'
                }
            }
        }

        if(sorted_data){
            sorted_data?.forEach((v) => {
                chart_labels.push(Object.keys(v)[0])
                chart_data.push(Object.values(v)[0])
            });
        }
        chart_labels = chart_labels.map((v) => {
            return (showdata == 'year') ? `${moment(v).format("YYYY")}` : (showdata == 'month') ? `${moment(v).format("MMM")}` : `${moment(v).format("DD")}-${moment(v).format("MMM")}`
        })
        data = {
            labels: chart_labels,
            datasets: [
                {
                    label: "Users",
                    data: chart_data,
                    fill: false,
                    backgroundColor: "#2A93DF",
                    borderColor: "#2A93DF",
                    pointBorderWidth: 4,
                    pointBorderColor: "#FFFFFF",
                    pointBackgroundColor: '#2A93DF',
                    pointRadius: 6,
                    borderDash: [2, 2],
                }
            ],
        };
        const options = {
            maintainAspectRatio: false, // Don't maintain w/h ratio
            scales: {
                x: {
                    grid: {
                        display: false,
                        drawOnChartArea: false,
                    },
                },
                y: {
                    grid: {
                        color: '#B8B6B6',
                        display: true,
                        drawOnChartArea: true,
                        drawBorder: false
                    },
                    ticks: {
                        precision: 0
                    }
                },

            },
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    mode: 'index',
                    intersect: false,
                    displayColors: false,
                    callbacks: {
                        title: function () { }
                    }
                }
            }
        }

        return {
            data,
            options
        }
    }
    render() {
        let { data, options } = this.getGraphData()
        let VAList = '';
        let project = [];
        let alphabeticalOrder = this.props.projectList.bots.sort();
        let projectList = '';
        let virtualassistantName = this.props.AssistantsList.map((item)=>{
            return(
              item.virtualassistantName
            )
        })
        if (this.props.AssistantsList) {
            let newVA = ["None","All",...new Set(virtualassistantName)]
            VAList = newVA.map(ele => {
                return { value: ele, label: ele }
            })
            let obj = this.props.AssistantsList.find(ele => ele.virtualassistantName == this.state.vaName)
            if (obj) {
                project = obj.project.filter(ele => ele !== '');
            } else {
                project = [];
            }
            let vaProjectList = alphabeticalOrder.filter(projects => project.includes(projects))
            if (vaProjectList) {
                let newArray = ["All VA Projects",...new Set(vaProjectList)]
                projectList = newArray.map(ele => {
                    return { value: ele, label: ele }
                })
            }
        }
        let allProjectsList = ''
        if (this.props.projectList) {
            let nwra = ["All Projects", ...new Set(this.props.projectList.bots)]
            allProjectsList = nwra.map((item) => {
                return { value: item, label: item }
            })
        }
        let vaProjects = this.props.AssistantsList.map((item)=>{
            return item.project
        })
        let combineList = vaProjects.flat(3);
        let VAProjectsList = '';
        if(vaProjects){
            let nwrwArr = ["All VA Projects",...new Set(combineList)]
            VAProjectsList = nwrwArr.map((ele)=>{
                return {value:ele, label:ele}
            })
        }

        let cogMetricsData = this.props.cogMetricsData
        let totalUsersData = cogMetricsData && cogMetricsData.users ? cogMetricsData.users : 0;
        let searchQueriesData = cogMetricsData && cogMetricsData.searchQryCount ? cogMetricsData.searchQryCount : 0;
        let totalFeedback = 0, positiveCount = 0, negativeCount = 0, answeredCount = 0, unansweredCount = 0;
        answeredCount = cogMetricsData && cogMetricsData.answered ? cogMetricsData.answered : 0;
        unansweredCount = cogMetricsData && cogMetricsData.unanswered ? cogMetricsData.unanswered : 0;
        if (cogMetricsData != null ) {
            totalFeedback = Number(cogMetricsData.positive) + Number(cogMetricsData.negative)
            positiveCount = cogMetricsData.positive
            negativeCount = cogMetricsData.negative
        }

        const { usersCount,
            searchCount,
            cogSentiment,
            cogHandled } = this.state;

        return (<>
            { this.props.cogMetricsLoading === true ? <SpinnerPage /> : null}
            <div className="botReportsTiles">
                <Row>
                    <div className="col-md-6">
                        <h2 className="reportsTag">Cognitive Metrics Insights</h2>
                        <div className='reportsDesc'>
                            <span className="reportsPara">
                                Provision to view cognitive metrics
                            </span>
                            <ButtonComponent variant='outlined'
                                label='Download Insights' className='buttonClass dld-btn'
                                clicked={() => { this.exportData() }} />
                        </div>
                    </div>

                    <DashboardFilters
                        AssistantsList={this.props.AssistantsList}
                        vaName={this.state.vaName}
                        VAList={VAList}
                        VAListClick={(event) => { this.VAListClick(event) }}
                        projectname={this.state.projectname}
                        projectList={projectList}
                        allProjectsList={allProjectsList}
                        projectclick={(event) => { this.projectclick(event) }}
                        getDateValues={this.getDateValues}
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        location={this.props.location}
                        VAProjectsList={VAProjectsList} />
                </Row>

                <Grid container lg={12} className="con-cards">
                    <Grid item md={8} >
                        <Grid container md={12} className="con-cards">
                            <Grid item md={6} >
                                <MetricCard
                                    heading={usersCount.heading}
                                    count={totalUsersData}
                                    icon={usersCount.icon}
                                    backgroundColor={usersCount.backgroundColor}
                                />
                            </Grid>
                            <Grid item md={6} >
                                <MetricCard
                                    heading={searchCount.heading}
                                    count={searchQueriesData}
                                    icon={searchCount.icon}
                                    backgroundColor={searchCount.backgroundColor}
                                />
                            </Grid>
                        </Grid>
                        <Grid item>
                            <div className='report-graph-wrapper'>
                                <div className=" mb-2 graphOuterContainer pb-2">
                                    <div className='d-flex justify-content-between title-row pt-3'>
                                        <span className='chart-title'>Users</span>
                                    </div>
                                    <div className="GraphContainer px-4" >
                                        <Line data={data} options={options} />
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item md={4} className="cognitive-right" >
                        <Grid item md={12}>
                            <MetricCard
                                heading={cogSentiment.heading}
                                headerIcon={cogSentiment.headerIcon}
                                count={positiveCount}
                                count2={negativeCount}
                                label1="Positive"
                                label2="Negative"
                                icon={cogSentiment.icon}
                                backgroundColor={cogSentiment.backgroundColor}
                            />
                        </Grid>
                        <Grid item md={12}>
                            <MetricCard
                                heading={cogHandled.heading}
                                headerIcon={cogHandled.headerIcon}
                                count={answeredCount}
                                count2={unansweredCount}
                                label1="Answered"
                                label2="Unanswered"
                                icon={cogHandled.icon}
                                backgroundColor={cogHandled.backgroundColor}
                            />
                        </Grid>
                    </Grid>

                </Grid>
            </div>
        </>

        )
    }
}

const mapStateToProps = state => {
    return {
        totalUsersData: state.reportDashboard.totalCognitiveUsers,
        totalCognitiveSearchqueries: state.reportDashboard.totalCognitiveSearchqueries,
        totalfeedbackData: state.reportDashboard.totalCognitiveFeedback,
        totalUnanswersCount: state.reportDashboard.totalCognitiveUnanswers,
        reportDetail: state.reportDashboard.reportDetail,
        TotalCognitiveUserReport: state.reportDashboard.TotalCognitiveUserReport,
        cogSearchQueryLoading: state.reportDashboard.cogSearchQueryLoading,
        cogFeedbackCountLoading: state.reportDashboard.cogFeedbackCountLoading,
        cogUsersCountLoading: state.reportDashboard.cogUsersCountLoading,
        cogUsersReportLoading: state.reportDashboard.cogUsersReportLoading,
        projectList: state.user.projects,
        AssistantsList: state.projectview.AllAssistantsList,
        cogMetricsData: state.reportDashboard.cogMetricsData,
        cogMetricsLoading: state.reportDashboard.cogMetricsLoading
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        totalCognitivefeedback: (apiUrl, data) => dispatch(actions.totalCognitivefeedback(apiUrl, data)),
        totalCognitiveSearchQueries: (apiUrl, data) => dispatch(actions.totalCognitiveSearchQueries(apiUrl, data)),
        totalCognitiveUsers: (apiUrl, data) => dispatch(actions.totalCognitiveUsers(apiUrl, data)),
        totalCogUserReportData: (apiUrl, data) => dispatch(actions.totalCogUserReportData(apiUrl, data)),
        ReportPageData: (data) => dispatch(actions.ReportPageData(data)),
        onchangeProjectAction: (projectname) => dispatch(actions.changeProjectAction(projectname)),
        OnGetAllAssistantsData: (APIURL) => dispatch(actions.GetAllAssistantsData(APIURL)),
        onExportCognitiveReportData: (apiUrl, data) => dispatch(actions.exportCognitiveReportData(apiUrl, data)),
        cognitiveMetricsData: (apiURL,data) => dispatch(actions.cognitiveMetricsData(apiURL,data)) 
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CognitiveDashboard);

