import React, { useState, useEffect } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import get from "lodash/get";
import SymptomsContent from "./SymptomsContent";
import "./SymptomAndRootCause.css";
import { useDispatch, useSelector } from "react-redux";
import { PostCalltoServer } from "../../../../../../store/utility";
import { getSymptomsDataAction } from "../../../../../../store/actions/adminSettings/adminSettingsActions";
import AddSymptomsDetails from "./AddSymptomsDetails";
import InputWithLabelComponent from "../../../components/InputWithLabelComponent/InputWithLabelComponent";
import SearchSymptomAndRootcause from "./helpers/SearchSymptomAndRootcause";
import getProjectDetails from "../../../helpers/getProjectDetails";
import Filters from "../Filters/Filters";
import isEqual from "lodash.isequal";
import { isEmpty } from "lodash";
import advanceFilterChangeHandlerAction from "../../../../../../store/actions/agentInsights/advanceFilterChangeHandlerAction";
import SearchComponent from "../../../components/SearchComponent/SearchComponent";
import SpinnerPage from "../../../../../../components/UI/Loader/Loader";
import TriageSearchComponent from "../../../components/TriageSearch/TriageSearchComponent";
import { Tooltip, withStyles } from '@material-ui/core';

const SymptomsTab = (props) => {
  const symptomsData = useSelector((state) => {
    return state.adminSettings.symptomsTabData.symptomData;
  });
  const appliedFilters = useSelector(
    (state) => state.agentInsights.appliedFilters
  );
  const ProjectView = useSelector(state => state.projectview.IsProjectChange);

  const projectName = getProjectDetails();
  const [showDrawer, setShowDrawer] = useState(false);
  const [showFilterDrawer, setShowFilterDrawer] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [filterSymptomData, setFilterSymtomData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [advanceFilters, setAdvanceFilters] = useState({});
  const symptomData = filterSymptomData.filter(obj => JSON.stringify(Object.keys(obj)) === JSON.stringify(Object.keys(filterSymptomData[0])));
  const [columVal,setColumVal] = useState([]);
  
  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 12,
      fontFamily: "Roboto-Regular",
    },
    arrow: {
      color: theme.palette.common.white,
      "&:before": {
        border: "1px solid #E6E8ED",
        boxShadow: theme.shadows[1],
      },
    },
  }))(Tooltip);
  const toggleDrawer = (value) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setShowDrawer(value);
    setSearchTerm("");
  };
  const toggleShowFilterDrawer = (value) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setShowFilterDrawer(value);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    getSymptoms();
  }, [advanceFilters,ProjectView]);
   
  const getSymptoms = () => {
    const url =
      props.config.INSIGHTS_API_URL +
      `/product-intelligent-triage/settings/getSymptomsdetailsSet/project/${projectName}`;
    setDataLoading(true);
    PostCalltoServer(url, advanceFilters)
      .then((response) => {
        setColumVal(response.data.columnsSet);
        if (get(response, "data.status.responseType", "") === "success") {
          setDataLoading(false);
          const data = JSON.parse(get(response, "data.data", []));
          setFilterSymtomData([...data]);
          dispatch(getSymptomsDataAction([...data].reverse()));
        } else {
          setDataLoading(false);
        }
      })
      .catch((err) => {
        setDataLoading(false);
      });
  }
 
  const resultsUpdate = (attribute, data) => {
    if (attribute in advanceFilters) {
      if (
        isEqual(
          advanceFilters[attribute][advanceFilters[attribute].length - 1],
          data
        )
      ) {
        delete advanceFilters[attribute];
        if (isEmpty(advanceFilters)) setAdvanceFilters({});
        else setAdvanceFilters({ ...advanceFilters });
      } else {
        setAdvanceFilters({
          ...advanceFilters,
          [attribute]: advanceFilters[attribute].includes(data)
            ? [...advanceFilters[attribute].filter((item, i) => item !== data)]
            : [...advanceFilters[attribute], data],
        });
      }
    } else setAdvanceFilters({ ...advanceFilters, [attribute]: [data] });
  };

  useEffect(() => {
    setFilterSymtomData(symptomsData);
  }, [symptomsData]);

  const getSymptomHeading = (selectedSymptom) =>
    Object.entries(selectedSymptom).map(([key, value]) => {
      if (key === "leadingquestion" && key === "description") {
        return null;
      }
      if (Array.isArray(value)) {
        return (
          <>
          { (props.config.SYMPTOMS_ROOT_CAUSE_HIDDEN_FIELDS).includes(key) ? null : (
          <Col xs={2} className="symptom-keys">
            <h4 className="issue-descp-title-change">{key}</h4>
          </Col>)}
          </>
        );
      }
      return null;
    });

  const searchHandler = (event) => {
    setSearchTerm(event.target.value);
    if (symptomsData.length > 0) {
      const filter = SearchSymptomAndRootcause(
        symptomsData,
        "symptoms",
        event,
        setFilterSymtomData
      );
      setFilterSymtomData(filter);
    }    
  };

  const addSymptom = (newRecord) => {
    let updatedRecords = [newRecord, ...filterSymptomData];
    setFilterSymtomData(updatedRecords);
    // setFilterSymtomData((filterSymptomData) =>
    //   filterSymptomData.concat(newRecord).reverse()
    // );
  };

  const filterHandler = () => {
    setShowFilterDrawer(true);
    setSearchTerm("");
  };

  const onRemoveItem = (item) => {
    const removedFilter = item.split("-");
    resultsUpdate(removedFilter[0], removedFilter[1]);
    dispatch(advanceFilterChangeHandlerAction(item));
  };
  const makeDataConsistent = (names, data) => {
    const defaultValue = "--";
    data.forEach((obj) => {
      names.forEach((name) => {
        if (!obj.hasOwnProperty(name)) {
          obj[name] = defaultValue;
        }
      });
    });
    // return data.map(({...names}) => ({...names}) );
    return data
  }

  const consistentData = makeDataConsistent(columVal, filterSymptomData);   

  return (
    <React.Fragment>
      <React.Fragment>
        <Row>
          <Col className="d-flex justify-content-end  symptoms-imgs pt-3 pb-2">
            <LightTooltip
              arrow
              title={"Adding New Symptoms"}
              placement="top-start"
            >
              <img
                className={
                  props.permissions == true ? "mr-3" : "mr-3 peventNone"
                }
                src="./Icons/Triage-add.svg"
                alt="Add Symptoms"
                onClick={toggleDrawer(true)}
              />
            </LightTooltip>
            <LightTooltip
              arrow
              title={"Filters"}
              placement="top-start"
            >
              <img
                className="mr-3"
                src="./Icons/filter_icon.png"
                alt="filter"
                onClick={filterHandler}
              />
            </LightTooltip>
          </Col>
        </Row>
        <Col
          xs={6}
          className="d-flex justify-content-start symptoms-imgs pt-1 pl-3 pb-1"
        >
          <div>
            {appliedFilters.length ? (
              <p className="root-cause-right-side-head">Filters Applied</p>
            ) : null}
          </div>
        </Col>
        <Row className="d-flex justify-content-between">
          <Col
            xs={7}
            className="d-flex justify-content-start symptoms-imgs pl-4"
          >
            <div className="d-flex flex-start align-items-center">
              {appliedFilters.length
                ? appliedFilters.slice(0, 6).map((item, index) => (
                    <div className="mr-2 mt-1 d-flex flex-start align-items-center selected-filter-tag ">
                      {index > 4 ? null : (
                        <div
                          onClick={() => onRemoveItem(item)}
                          className="selected-fiter-tag-remove-icon mr-2 ml-2"
                        >
                          <div className="selected-fiter-tag-close-icon">x</div>
                        </div>
                      )}
                      {index > 4 ? (
                        <div
                          className="ml-3 selected-filter-tag-label"
                          onClick={filterHandler}
                        >
                          {"show more.."}
                        </div>
                      ) : (
                        <div className="selected-filter-tag-label">{item}</div>
                      )}
                    </div>
                  ))
                : ""}
            </div>
          </Col>
          <Col xs={3} className="d-flex justify-content-end search-component ">
            <div className="triage-search-input">
              <TriageSearchComponent
                name={"recomended-solution"}
                value={searchTerm}
                onSearch={searchHandler}
                placeHolder={"Search"}
                class={"input-group searchClass"}
              />
            </div>
          </Col>
        </Row>
        {dataLoading ? (
          <Row className="vh-100 justify-content-center align-items-center">
            {/* <Spinner animation="border" variant="primary" /> */}
            <SpinnerPage />
          </Row>
        ) : (
          <Row xs={12} className="mt-4 row row-no-gutters">
            <Col className="d-flex  symtom-container">
              <div className="row d-flex justify-content-between w-100 content-align-center symtom-heading-data">
                {columVal.length> 0 ?
                 columVal.sort().reverse().map((item) => {
                  return <Col xs={2}>
                    <h4 style={{paddingLeft: `${item === "symptoms" ? "0.3rem" : "0"}`}} className="issue-descp-title-change">{item === "leadingquestion" ? "leadingquestion" : item &&
                     item === "symptoms" ? `symptoms (${filterSymptomData.length})`: item } </h4>
                  </Col>
                })
                : <div className="row d-flex justify-content-between w-100 content-align-center symtom-heading-data">
                  <Col xs={2}>
                  <h4 className="issue-descp-title-change">
                    Symptom ({filterSymptomData.length})
                  </h4>
                </Col>
                <Col xs={2}>
                  <h4 className="issue-descp-title-change">Leading Question</h4>
                </Col>
                </div>
                }
                {/* <Col xs={2}>
                  <h4 className="issue-descp-title-change">
                    Symptom ({filterSymptomData.length})
                  </h4>
                </Col>
                <Col xs={2}>
                  <h4 className="issue-descp-title-change">Leading Question</h4>
                </Col>
                {getSymptomHeading(get(symptomsData, "0", []))} */}
              </div>
            </Col>
            <Col className="arrow-img" xs={1}></Col>
          </Row>
        )}
        <div className="content-scrool">
          {filterSymptomData.length
            ? filterSymptomData.map((symptom, index) => {
                return (
                  <SymptomsContent
                    config={props.config}
                    symptom={symptom}
                    id={index}
                    key={index}
                    getSymptoms={getSymptoms}
                    advanceFilters={advanceFilters}
                    permissions={props.permissions}
                    columVal = {columVal}
                    consistentData = {consistentData}
                    setSearchTerm = {setSearchTerm}
 
                  />
                );
              })
            : null}
        </div>
        {showDrawer ? (
          <AddSymptomsDetails
            showDrawer={showDrawer}
            toggleDrawer={toggleDrawer}
            config={props.config}
            symptomsData={symptomsData}
            getSymptoms={getSymptoms}
            addSymptom={(symptomData) => addSymptom(symptomData)}
            advanceFilters={advanceFilters}
            closeDrawer={() => {
              setShowDrawer(false);
            }}
          />
        ) : null}
      </React.Fragment>
      {showFilterDrawer ? (
        <Filters
          showDrawer={showFilterDrawer}
          toggleDrawer={toggleShowFilterDrawer}
          closeDrawer={() => {
            showFilterDrawer(false);
          }}
          config={props.config}
          resultsUpdate={resultsUpdate}
        />
      ) : null}
    </React.Fragment>
  );
};
export default SymptomsTab;
