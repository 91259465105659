const TRIAGE_CONFIGURATION_MOCK_DATA = [
    {
        "source_name": "productattributes",
        "source_fields": []
    },
    {
        "source_name": "symptoms",
        "source_fields": []
    },
    {
        "source_name": "rootcause",
        "source_fields": []
    }
];

export default TRIAGE_CONFIGURATION_MOCK_DATA;
