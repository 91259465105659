import * as React from 'react';
import Button from '@material-ui/core/Button';
import './DefaultButton.css';

const DefaultButton = (props) => {
    return (
        <span className='default-button'>
            <Button variant={props.variant} color="primary" disabled={props.disabled} size={props.size}
                onClick={props.clicked} className={props.className} style={props.labelStyles}>
                {props.label}
            </Button>
        </span>
    )
}

export default DefaultButton;
