import React from 'react';
import './formStyles.css';
import { useDrag } from 'react-dnd';
import * as inputTypes from './InputTypes';

const FormOptions = ({data}) => {

    const [{isDragging}, drag] = useDrag(()=> ({
        type: inputTypes.FORM_INPUT,
        item: data,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
            handlerId: monitor.getHandlerId()
        })
    }));
    
    const opacity = isDragging ? 0.4 : 1;

    return (
        <>
        {data.type === inputTypes.TEXTFIELD && (
            <div className="form-nodes" ref={drag} style={{opacity}}>
                <img id='text-icon-img' src='./images/CDIcons/form/TextField.svg'></img>
            <div className="form-node-text text-label">text field</div>
        </div>
        )}
        {data.type === inputTypes.MULTISELECT && (
            <div className="form-nodes" ref={drag} style={{opacity}}>
                <img id='multiselect-icon-img' src='./images/CDIcons/form/MultiSelect.svg'></img>
            <div className="form-node-text multiselect-label">multi check</div>
        </div>
        )}
        {data.type === inputTypes.RADIOBUTTON && (
            <div className="form-nodes" ref={drag} style={{opacity}}>
                <img id='radiobtn-icon-img' src='./images/CDIcons/form/RadioButton.svg'></img>
            <div className="form-node-text radiobtn-label">radio button</div>
        </div>
        )}
        {data.type === inputTypes.DROPDOWN && (
            <div className="form-nodes" ref={drag} style={{opacity}}>
                <img id='radiobtn-icon-img' src='./images/CDIcons/form/Dropdown.svg'></img>
            <div className="form-node-text radiobtn-label">drop down</div>
        </div>
        )}
        {data.type === inputTypes.HEADING && (
            <div className="form-nodes" ref={drag} style={{opacity}}>
                <img id='label-icon-img' src='./images/CDIcons/form/Heading.svg'></img>
            <div className="form-node-text label-label">heading</div>
        </div>
        )}
        {data.type === inputTypes.TEXT && (
            <div className="form-nodes" ref={drag} style={{opacity}}>
                <img id='label-icon-img' src='./images/CDIcons/form/Text.svg'></img>
            <div className="form-node-text label-label">text</div>
        </div>
        )}
        {data.type === inputTypes.TEXTAREA && (
            <div className="form-nodes" ref={drag} style={{opacity}}>
                <img id='textArea-icon-img' src='./images/CDIcons/form/TextArea.svg'></img>
            <div className="form-node-text textArea-label">text area</div>
        </div>
        )}
        {data.type === inputTypes.FILEUPLOAD && (
            <div className="form-nodes" ref={drag} style={{opacity}}>
                <img id='textArea-icon-img' src='./images/CDIcons/form/FileUpload.svg'></img>
            <div className="form-node-text fileUpload-label">file upload</div>
        </div>
        )}
        {data.type === inputTypes.IMAGE && (
            <div className="form-nodes" ref={drag} style={{opacity}}>
                <img id='imageUrl-icon-img' src='./images/CDIcons/form/Image.svg'></img>
            <div className="form-node-text imageUrl-label">image</div>
        </div>
        )}
        {data.type === inputTypes.CONTACT_NUM && (
            <div className="form-nodes" ref={drag} style={{opacity}}>
                <img id='contactNum-icon-img' src='./images/CDIcons/form/ContactNum.svg'></img>
            <div className="form-node-text contactNum-label">contact number</div>
        </div>
        )}
        {data.type === inputTypes.CALENDER && (
            <div className="form-nodes" ref={drag} style={{opacity}}>
                <img id='calender-icon-img' src='./images/CDIcons/form/Calendar.svg'></img>
            <div className="form-node-text calender-label">date picker</div>
        </div>
        )}
        {/* {data.type === inputTypes.CONSENT && (
            <div className="form-nodes" ref={drag} style={{opacity}}>
                <img id='consent-icon-img' src='./images/CDIcons/form/Consent.svg'></img>
            <div className="form-node-text consent-label">consent capture</div>
        </div>
        )} */}

        </>
    )
}

export default FormOptions;