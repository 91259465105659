/*

* Copyright © 2023, HCL Technologies Ltd. ALL RIGHTS RESERVED.

* <p>

* This software is the confidential information of HCL Technologies Ltd., and is licensed

* as restricted rights software. The use,reproduction, or disclosure of this software

* is subject to restrictions set forth in your license agreement with HCL.

*/

 

/**

* Continuous Learning Analysis.

*

* @author Abhash Vats

*/

import React, { Component } from 'react';
import axios from "../../../instance";
import withErrorHandler from "../../../hoc/withErrorHandler/withErrorHandler";
import * as actions from "../../../store/actions/index";
import { connect } from "react-redux";
import TabsComponent from "./components/TabsComponent/TabsComponent";
import { Col, Row } from 'react-bootstrap';
import "./ContinuousLearningAnalysis.css";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DrawerComponent from '../../../components/UIComponents/Drawer/DrawerComponent';
import SearchComponent from "../../../components/UIComponents/SearchComponent/Search";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import orderBy from 'lodash/orderBy';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { makeStyles,withStyles } from '@material-ui/core';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import * as classes from '../../ProjectViewPage/ProjectViewPage.module.css';
import SelectCustom from "../../../components/UIComponents/SelectDropdown/SelectComponent";
import TextAreaWithLabelComponent from "./SecheduleMeeting/TextAreaWithLabelComponent";
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';
import PredefinedRanges from '../../../components/UI/DatetimeRangePicker/PredefinedRanges';
import { filter } from 'lodash';
import { styled, alpha } from '@mui/material/styles';
import TablePagination from '@material-ui/core/TablePagination';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { encryptAndStore } from "../../../utility/utility";
import NavbarCustom from '../../../components/UI/Navbar/Navbar';

const StyledCheckbox = styled(Checkbox)`
  svg {
    color: rgb(42, 147, 223);
    font-size: 15px;
  }
`;

const invertDirection = {
    asc:'desc',
    desc:'asc',
}

class ContinuousLearningAnalysis extends Component {

    state={
        dropdownOpen: false,
        selectedTab:'NEGATIVE FEEDBACK',
        tabItems:[
            {
              label: 'NEGATIVE FEEDBACK',
              value: 'NEGATIVE FEEDBACK'
            },
            {
              label: 'UNANSWERED QUERIES',
              value: 'UNANSWERED QUERIES'
            }
          ],
          rowsPerPage:5,
          page:0,
        openRecentDrawer:false,
        openRecommendationDrawer:false,
        searchValue:'',
        show:true,
        menuShow1:null,
        menuShow2:null,
        menuShow3:null,
        filterAllMenu:false,
        filterReviewedMenu:false,
        filterNotReviewedMenu:false,
        filterType:"All",
        all1:false,
        all2:false,
        Reviewed:false,
        notReviewed:false,
        new:false,
        recentActivityData:[
            {date:"1 jan 2000",noOfRecommendations:100},
            {date:"2 jan 2001",noOfRecommendations:101},
            {date:"3 jan 2002",noOfRecommendations:102},
            {date:"4 jan 2003",noOfRecommendations:103},
            {date:"5 jan 2004",noOfRecommendations:104},
            {date:"6 jan 2005",noOfRecommendations:105},
            {date:"7 jan 2006",noOfRecommendations:106},
            {date:"1 jan 2000",noOfRecommendations:100},
            {date:"2 jan 2001",noOfRecommendations:101},
            {date:"3 jan 2002",noOfRecommendations:102},
            {date:"4 jan 2003",noOfRecommendations:103},
            {date:"5 jan 2004",noOfRecommendations:104},
            {date:"6 jan 2005",noOfRecommendations:105},
            {date:"7 jan 2006",noOfRecommendations:106},
          ],
        tableColm:['RECOMMENDED INTENT','PROJECT','STATUS',''],
        tableData:[],
        sortedColm:'topic',
        numSelected:0,
        rowCount:0,
        sortDirection:'desc',
        allRows:[],
        columnToSort:'',
        startDate: moment().subtract(6, 'days'),
        endDate: moment(),
        intentNameInDrawer:'',
        id:null,
        count:0,
        typeDrawer:'Creat as Faq',
        commentsDrawer:'',
        Utterances:[],
        fullTableData:[],
        anchorE2:null,
        options: ['Creat as Faq','Add as Intent'],
        open:false,
        dataForSaveApi:[],
        disableKnowledgeBtn:true,
        virtualAssistantProjects:{"bots":[],},
        searchT:"",
        popperShow:false,
        selectedProject:null,
    }

    selectProject = (e) =>{
        this.setState({selectedProject:e.target.value,})
        encryptAndStore("projectname",e.target.value)
      }

    setSearchTerm = (item) => {
        this.setState({searchT:item})
    }

    popperShowFn=()=>{
        this.setState({
          popperShow:true,
        })
    }

    projectclick =(event,projectname)=>{
        this.setState({searchT:"",popperShow:false})
        let savedProjectName=localStorage.getItem('projectname');
        if(savedProjectName!==projectname){
            // localStorage.setItem('projectname', projectname );
            encryptAndStore('projectname', projectname );
            this.setState({selectedProject:projectname});
            // let url = this.props.config.BOTBUILDER_URL + `/cognitiveFlowConfiguration/project/`+ projectname
            // this.props.onGetCognitiveFlow(url)
        }
        this.props.onchangeProjectAction(projectname);        
          //window.updateHCLChatBotURL(projectname);
    }

    handleClick = (event) => {
        this.setState({open:true,anchorE2:event.currentTarget})
    };

    handleClose = () => {
        this.setState({open:false,anchorE2:null})
    };

    addToKnowledgeBase = () =>{
        this.props.onSaveContinuousData(this.props.config.BOTBUILDER_URL, this.state.dataForSaveApi)
        setTimeout(() => {
            this.props.onGetContinuousData(this.props.config.BOTBUILDER_URL, localStorage.getItem("vaName"))
        }, 2000);
        this.setState({disableKnowledgeBtn:true})
    }

    handleSelectCheckBox = (option) => {
        this.setState({open:false,anchorE2:null})
        var tableRows = [...this.state.tableData]
        var data = []
        if(option == 'Creat as Faq'){
            for(var i=0;i<tableRows.length;i++){
                if(tableRows[i].checked == true){
                    var d = {
                        "status":"Created as Faq",
                        "topic":tableRows[i].topic,
                        "utterances":tableRows[i].utterances,
                        "comment":tableRows[i].comment,
                        "id":tableRows[i].id
                    }
                    data.push(d)
                }
            }
            this.setState({dataForSaveApi:data, disableKnowledgeBtn:false})
        }
        else if(option == 'Add as Intent'){
            for(var i=0;i<tableRows.length;i++){
                if(tableRows[i].checked == true){
                    var d = {
                        "status":"Added as Intent",
                        "topic":tableRows[i].topic,
                        "utterances":tableRows[i].utterances,
                        "comment":tableRows[i].comment,
                        "id":tableRows[i].id
                    }
                    data.push(d)
                }
            }
            this.setState({dataForSaveApi:data, disableKnowledgeBtn:false})
        }
        else{
            this.setState({dataForSaveApi:[], disableKnowledgeBtn:true})
        }
    };

    setSelected = (tab) => {
        this.setState({selectedTab:tab});
        if(tab == 'NEGATIVE FEEDBACK'){
            this.setState({fullTableData:this.props.continuousData.negative, tableData:this.props.continuousData.negative,count:this.props.continuousData.negative.length}, () => {
                setTimeout(() => {
                    this.addCheckboxValue()
                }, 2000);               
            })
            this.setState({all1:true,filterType:"All"})
        }    
        else{
            this.setState({fullTableData:this.props.continuousData.unanswered, tableData:this.props.continuousData.unanswered,count:this.props.continuousData.unanswered.length}, () => {
                setTimeout(() => {
                    this.addCheckboxValue()
                }, 2000);
            })
            this.setState({all1:true,filterType:"All",Reviewed:false,notReviewed:false})
        }        
    }

    componentDidMount() {
        this.props.onGetContinuousData(this.props.config.BOTBUILDER_URL, localStorage.getItem("vaName"))
        // let url = this.props.config.BOTBUILDER_URL + `/cognitiveFlowConfiguration/project/`+ localStorage.getItem('projectname')
        // this.props.onGetCognitiveFlow(url)
        let project = [];
        if (this.props.AssistantsList) {
            let obj = this.props.AssistantsList.find(ele => ele.virtualassistantName == localStorage.getItem("vaName"))
            if(obj){
                project =obj.project.filter(ele => ele !== '');
            }else{
                project = [];
            }
        }
        let vaProjectList = this.props.projects.filter(projec => project.includes(projec.projectName))
        vaProjectList && vaProjectList.map((data) => {
             this.state.virtualAssistantProjects.bots.push(data.projectName)
        })
    }

    componentDidUpdate(prevprops){
        if(this.props.continuousData && prevprops.continuousData != this.props.continuousData){
            console.log("this.state.setSelected ==",this.state.setSelected)
            if(this.state.selectedTab == 'NEGATIVE FEEDBACK'){
                this.setState({fullTableData:this.props.continuousData.negative, tableData:this.props.continuousData.negative, all1:true,filterType:"All",count:this.props.continuousData.negative.length})
                this.setState({rowCount:this.props.continuousData.negative.length,numSelected:0})
            }
            else if(this.state.selectedTab == 'UNANSWERED QUERIES'){
                this.setState({fullTableData:this.props.continuousData.unanswered, tableData:this.props.continuousData.unanswered,count:this.props.continuousData.unanswered.length})
                this.setState({rowCount:this.props.continuousData.unanswered.length,numSelected:0})
            }
            setTimeout(() => {
                this.addCheckboxValue()
            }, 2000); 
        }
    }
    
    addCheckboxValue = () =>{
        if(this.state.tableData.length>0){
            var temp = [...this.state.tableData]
            for( var i=0;i<this.state.tableData.length;i++){
                temp[i].checked = false
            }
            this.setState({tableData:temp})
        }
    }

    saveDrawerData = () =>{
        var data = [
            {"status":this.state.typeDrawer,
            "topic":this.state.intentNameInDrawer,
            "utterances":this.state.Utterances,
            "comment":this.state.commentsDrawer,
            "id":this.state.id}
        ]
        this.props.onSaveContinuousData(this.props.config.BOTBUILDER_URL,data)
        setTimeout(() => {
            this.props.onGetContinuousData(this.props.config.BOTBUILDER_URL, localStorage.getItem("vaName"))
        }, 2000); 
        this.setState({openRecommendationDrawer:false});
    }

    openRecentDrawer = () =>{
        this.setState({openRecentDrawer:true});
    }

    closedRecentDrawer = () =>{
        this.setState({openRecentDrawer:false});
    }

    openRecommendationDrawer = (item) =>{
        this.setState({openRecommendationDrawer:true,selectedProject:item.project});
        this.setState({
            intentNameInDrawer:item.topic,
            commentsDrawer:item.comment,
            Utterances:item.utterances,
            id:item.id,
        })
    }

    closeRecommendationDrawer = () =>{
        this.setState({openRecommendationDrawer:false});
    }

    onSelectHandlerDrawer = (e)=>{
        this.setState({typeDrawer:e.target.value})
    }

    intentNameInDrawer = (e) =>{
        this.setState({intentNameInDrawer:e.target.value})
    }

    commentsInDrawer = (e) =>{
        this.setState({commentsDrawer:e.target.value})
    }

    utteranceInDrawer = (e,index) =>{
        var utterance = [...this.state.Utterances]
        utterance[index] = e.target.value
        this.setState({Utterances:utterance})
    }

    deleteUtterances = (e, index) =>{
        var utterance = [...this.state.Utterances]
        utterance.splice(index,1)
        this.setState({Utterances:utterance})
    }

    ClearSearchValue = ()=>{
        this.setState({searchValue:""})
    }

    onQuickFilterChanged = (event) => {
        this.setState({searchValue:event.target.value})
            if(event.target.value!="" ){
              this.setState({show:false})
            }
            else{
                this.setState({show:true})
            }
    };

    handleChangePage = (event, newPage) => {
        console.log("event",event)
    this.setState({page:newPage})
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({rowsPerPage:+event.target.value,page:0})
    }

    handleFilterApply = () =>{
        var data=[...this.state.fullTableData]
        var temp=[]
        if(this.state.filterAllMenu){
            this.setState({tableData:data,menuShow1:null,filterType:"All"})
        }
        else{
                if(this.state.notReviewed && this.state.Reviewed){
                    this.setState({tableData:data,menuShow1:null,filterType:"Reviewed & Not Reviewed"})
                }
                else if(this.state.notReviewed){
                    for(var i=0;i<data.length;i++){
                        if(data[i].status=="not reviewed"){
                            temp.push(data[i])
                        }
                    }
                    this.setState({tableData:temp,menuShow1:null,filterType:"Not Reviewed"})
                }
                else if(this.state.Reviewed){
                    for(var i=0;i<data.length;i++){
                        if(data[i].status!="not reviewed"){
                            temp.push(data[i])
                        }
                    }
                    this.setState({tableData:temp,menuShow1:null,filterType:"Reviewed"})
                }
        }
    }

    handleClickMenu1 = (event) => {
        this.setState({menuShow1:event.currentTarget})
        if(this.state.filterReviewedMenu==false && this.state.filterNotReviewedMenu ==false){
            this.setState({filterAllMenu:true})
        }
    };

    handleCloseMenu1 = () => {
        this.setState({menuShow1:null})
    };

    handleAllMenu = (event)=>{
        this.setState({filterAllMenu:event.target.checked,Reviewed:false,notReviewed:false,filterReviewedMenu:false,filterNotReviewedMenu:false})
    }

    handleClickMenu2 = (event) => {
        this.setState({filterReviewedMenu:event.target.checked,all1:false,filterAllMenu:false})
    };

    handleCloseMenu2 = () => {
        this.setState({menuShow2:null})
    };

    handleClickMenu3 = (event) => {
        this.setState({filterNotReviewedMenu:event.target.checked,all1:false,filterAllMenu:false})
    };

    handleCloseMenu3 = () => {
        this.setState({menuShow3:null})
    };


    onSelectAllClick = (event) =>{
        var check = [...this.state.tableData]
        if(event.target.checked == true){
            for(var i=0;i<check.length;i++){
                check[i].checked=true
            }
            this.setState({numSelected:this.state.rowCount,allRows:this.state.tableData})
        }
        else{         
            for(var i=0;i<check.length;i++){
                check[i].checked=false
            }
            this.setState({numSelected:0,tableData:check})
        }
    }

    onSelectClick = (event,item,index) =>{
        let i = this.state.numSelected
        var check = [...this.state.tableData]
        var row = item
        console.log("index == ",index,check[index].checked)
        if(event.target.checked == true){
            i++
            check.map((item,i)=>{
                if(row == item){
                    row.checked = true
                    check[i]=row
                }
            })
        }
        else{
            i--
            check.map((item,i)=>{
                if(row == item){
                    row.checked = false
                    check[i]=row
                }
            })
        }
        this.setState({numSelected:i,tableData:check})
    }

    handleSort = (columnName) =>{
        if(columnName == 'RECOMMENDED INTENT'){
            this.setState({sortedColm:'topic'})
        }
        else if(columnName == 'PROJECT'){
            this.setState({sortedColm:'project'})
        }
        else if(columnName == 'STATUS'){
            this.setState({sortedColm:'status'})
        }
        console.log("columnName == ",columnName)
        this.setState({columnToSort:columnName})
        if(this.state.columnToSort == columnName)
            this.setState({sortDirection:invertDirection[this.state.sortDirection]})
        else
            this.setState({sortDirection:'asc'})
        console.log("qqqq = = ",this.state.sortedColm)
    }

    getDateValues = (dateValue) => {
        this.setState({
            startDate: dateValue.startDate,
            endDate: dateValue.endDate
        })
    };

    render(){
        
        const ITEM_HEIGHT = 48;
        let tabContent=null;
        let common =<React.Fragment>
            <Row className='commonPadding' style={{width:"100%"}}>
                <Col md={8} sm={8} lg={8} xlg={8}>
                    <div><span className='headerText'>
                            <span style={{color:"#016BB5"}}>
                                {this.state.count} Recommended Intents 
                            </span> are available 
                        </span>&nbsp;&nbsp;&nbsp;&nbsp;
                        <span className='filterApply'>Filters Applied </span>
                        <span className='statusBtn'><span className='btnText'>Status&nbsp;-&nbsp;{this.state.filterType}</span></span>  
                    </div>
                </Col>
                <Col md={4} sm={4} lg={4} xlg={4} className='recent'>
                    <div><span className='clockIcon'><AccessTimeIcon onClick={(event) => this.openRecentDrawer(event)}></AccessTimeIcon></span>RECENT ACTIVITY</div>
                </Col>
            </Row>
            <Row className='searchBox' style={{width:"100%"}}>
                <div><span className='filterIconCLA'><img onClick={(e)=>this.handleClickMenu1(e)} src='./images/Filter-icon.png' style={{cursor:"pointer"}}></img></span>
                    <SearchComponent className="searchIcon" class={"input-group searchClass"} Clear={()=>this.ClearSearchValue()} show={this.state.show} 
                    value={this.state.searchValue} onSearch={(event) => this.onQuickFilterChanged(event)}/>
                </div>
            </Row> 
            <Row style={{width:"100%",paddingLeft:"25px",paddingRight:"10px",marginTop:"-15px"}}>
                <button className='knowledgeBaseBtn' style={{backgroundColor:this.state.disableKnowledgeBtn?"#C1C8CD":"#2A93DF"}} onClick={()=>this.addToKnowledgeBase()}><span className='knowledgeBasetxt'>ADD TO KNOWLEDGE BASE</span></button>
            </Row>
        </React.Fragment>

        let tableDataa = this.state.tableData.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage);
        return(
            <React.Fragment>
                <Row style={{width:"100%"}}>
                    <Col md={12} sm={12} lg={12} xlg={12}>
                        <div className='header'>Continuous Learning Analysis</div>
                    </Col>
                </Row>

                <Row className='tabPadding' style={{width:"100%"}}>
                    <Col xs={12}>
                        <TabsComponent tabItems={this.state.tabItems}
                            selected={this.state.selectedTab}
                            setSelected={(e)=>this.setSelected(e)} className='t-69'>
                            {common}
                            {/* {tabContent} */}
                        </TabsComponent>
                    </Col>
                </Row>
                <Menu id="multimediaDocMenu" anchorEl={this.state.menuShow1} keepMounted open={Boolean(this.state.menuShow1)} onClose={this.handleCloseMenu1}>
                    <MenuItem className="menuItems" >
                        <div>
                            <StyledCheckbox checked={this.state.all1} onChange={(event)=>{this.setState({all1:Boolean(event.target.checked)})}} onClick={(e)=>this.handleAllMenu(e)} color="primary" style={{padding:"0px"}}/>
                            <label className='menuText'>All</label>
                        </div>
                    </MenuItem>
                    <MenuItem className="menuItems" >
                        <div>
                            <StyledCheckbox checked={this.state.Reviewed} onChange={(event)=>{this.setState({Reviewed:Boolean(event.target.checked)})}} onClick={(e)=>this.handleClickMenu2(e)} color="primary" style={{padding:"0px"}}/>
                            <label className='menuText'>Reviewed</label>
                        </div>
                    </MenuItem>
                    <MenuItem className="menuItems" >
                        <div>
                            <StyledCheckbox checked={this.state.notReviewed} onChange={(event)=>{this.setState({notReviewed:Boolean(event.target.checked)})}} onClick={(e)=>this.handleClickMenu3(e)} color="primary" style={{padding:"0px"}}/>
                            <label className='menuText'>Not Reviewed</label>
                        </div>
                    </MenuItem>                   
                    <button style={{backgroundColor:"#2A93DF",color:"#FFFFFF",border:"none",width:"100px",marginLeft:"10px",borderRadius:"5px",cursor:"pointer"}} onClick={()=>this.handleFilterApply()}>
                        <span className='applyBtn'>APPLY</span>
                    </button>
                </Menu>
                {/* <Menu id="multimediaDocMenu" anchorEl={this.state.menuShow2} keepMounted open={Boolean(this.state.menuShow2)} onClose={this.handleCloseMenu2}>
                    <MenuItem className="menuItems" >
                        <div>
                            <StyledCheckbox checked={this.state.all2} onChange={(event)=>{this.setState({all2:Boolean(event.target.checked)})}} color="primary" style={{padding:"0px"}}/>
                            <label className='menuText'>All</label>
                        </div>
                    </MenuItem>
                    <MenuItem className="menuItems" >
                        <div>
                            <StyledCheckbox checked={this.state.new} onChange={(event)=>{this.setState({new:Boolean(event.target.checked)})}} color="primary" style={{padding:"0px"}}/>
                            <label className='menuText'>New</label>
                        </div>
                    </MenuItem>
                    <button style={{backgroundColor:"#2A93DF",color:"#FFFFFF",border:"none",width:"100px",marginLeft:"10px",borderRadius:"5px"}}>
                        <span className='applyBtn'>APPLY</span>
                    </button>
                </Menu> */}
                <DrawerComponent heading="Recent Activity" showDrawer={()=>this.closedRecentDrawer()} openDrawer={this.state.openRecentDrawer}>
                    <div className="btn-group">
                        <button><span className='grpBtnText'>Day</span></button>
                        <button><span className='grpBtnText'>Week</span></button>
                        <button><span className='grpBtnText'>Month</span></button>
                    </div>
                    <span className='datePicker'><PredefinedRanges onDateChange={this.getDateValues} startDate={this.state.startDate} endDate={this.state.endDate}/></span>
                    <div className='drawerTable'>
                    <TableContainer style={{maxHeight:"450px",overflowY:"auto"}}>
                        <Table aria-label="simple table" stickyHeader >
                            <TableHead className='multi-header'>
                                <TableRow>
                                    <TableCell className="drawerTableH" >DATE</TableCell>
                                    <TableCell className="drawerTableH" >NO OF RECOMMENDATIONS Reviewed</TableCell>
                                    <TableCell className="drawerTableH" ></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                
                                 {this.state.recentActivityData && this.state.recentActivityData.length>0 && this.state.recentActivityData.map((item,index) => {
                                    return(
                                        <TableRow>
                                            <TableCell className="drawerTableC" >{item.date}</TableCell>
                                            <TableCell className="drawerTableC" >{item.noOfRecommendations}</TableCell>
                                            <TableCell className="drawerTableC" ><span><KeyboardArrowRightIcon></KeyboardArrowRightIcon></span></TableCell>
                                        </TableRow>
                                    )
                                })} 
                            </TableBody>
                        </Table>
                    </TableContainer>
                    
                    </div>
                </DrawerComponent>
                {
                    this.state.tableData && this.state.tableData.length>0 && 
                    
                    <div style={{paddingLeft:"25px",paddingRight:"10px"}}>
                        <TableContainer className="tableCont">
                            <Table aria-label="simple table" stickyHeader >
                                <TableHead className='multi-header'>
                                    <TableRow >
                                    
                                        <TableCell padding="checkbox" style={{backgroundColor:"#7D7D7D"}}>
                                            <Checkbox
                                                indeterminate={this.state.numSelected > 0 && this.state.numSelected < this.state.rowCount}
                                                checked={this.state.rowCount > 0 && this.state.numSelected === this.state.rowCount}
                                                onClick={(event)=>this.onSelectAllClick(event)}
                                                style ={{
                                                    color: "#ffffff",
                                                    width:"10px"
                                                    }}
                                                key="headCheckbox"
                                            />
                                         
                                            <IconButton
                                                aria-label="more"
                                                id="long-button"
                                                onClick={(e)=>this.handleClick(e)}
                                                style={{padding:"0px"}}
                                            >
                                                <ArrowDropDownIcon style={{color:'white'}}/>
                                            </IconButton>
                                            <Menu
                                                id="long-menu"
                                                anchorEl={this.state.anchorE2} keepMounted open={Boolean(this.state.anchorE2)}
                                                onClose={this.handleClose} 
                                                PaperProps={{
                                                    style: {
                                                        maxHeight: ITEM_HEIGHT * 4.5,
                                                        width: '20ch',
                                                        marginLeft:'15px',
                                                        marginTop:'30px',
                                                    },
                                                }}   
                                            >
                                                    {this.state.options.map((option) => (
                                                    <MenuItem key={option}  onClick={()=>this.handleSelectCheckBox(option)}>
                                                        <span style={{textTransform:"none"}}>{option}</span>
                                                    </MenuItem>
                                                    ))}
                                            </Menu>
                                        </TableCell>
                                        {this.state.tableColm != undefined && this.state.tableColm !== null ? this.state.tableColm.map((headerName) => {
                                            return (
                                                <>
                                                    <TableCell className="tableH" style={{textTransform:"uppercase",backgroundColor:"#7D7D7D"}} onClick={() => this.handleSort(headerName)}>
                                                        {headerName} {headerName != "" ? this.state.sortDirection === 'asc' ? <ArrowUpwardIcon className="iconStyle"/> : <ArrowDownwardIcon className="iconStyle"/>:''}
                                                    </TableCell>

                                                </>
                                            )
                                        }) : null}
                                        <TableCell className="tableH" ></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody >
                                    
                                    {this.state.tableData != undefined && this.state.tableData !== null ?
                                        ('topic' == this.state.sortedColm ? orderBy(tableDataa, [(user) =>user.topic.toLowerCase().trim()], this.state.sortDirection) :
                                        ('project' == this.state.sortedColm ? orderBy(tableDataa, [(user) => user.project.toLowerCase()], this.state.sortDirection):
                                        ('status' == this.state.sortedColm ? orderBy(tableDataa, [(user) => user.status.toLowerCase()], this.state.sortDirection):''))
                                        ).filter((item)=>{
                                            return true
                                        }).filter((value) =>{
                                            return (value.topic != null && value.topic.toLowerCase().includes(this.state.searchValue.toLowerCase())) ||
                                                (value.project != null && value.project.toLowerCase().includes(this.state.searchValue.toLowerCase())) ||
                                                (value.status != null && value.status.toLowerCase().includes(this.state.searchValue.toLowerCase()))
                                        }).map((item,index) => {
                                            return (
                                                <>
                                            
                                                    <TableRow key={item.id} >
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                checked={Boolean(item.checked)}
                                                                onClick={(event)=>this.onSelectClick(event,item,index)}
                                                                style ={{
                                                                    color: "rgb(42, 147, 223)",
                                                                    width:"10px"
                                                                    }}
                                                                //key={index}
                                                            />
                                                        </TableCell>
                                                        <LightTooltip arrow title={item.topic} placement="top-start">
                                                                <TableCell className="tableC">
                                                                {item.topic.length > 90 ? item.topic.slice(0, 90) + '...' : item.topic}
                                                                </TableCell>
                                                        </LightTooltip>
                                                        <LightTooltip arrow title={item.project} placement="top-start">
                                                            <TableCell className="tableC">
                                                                {item.project}
                                                            </TableCell>
                                                        </LightTooltip> 
                                                        <LightTooltip arrow title={item.status} placement="top-start">                                                                      
                                                            <TableCell className="tableC">
                                                                {item.status}
                                                            </TableCell>
                                                        </LightTooltip>
                                                        
                                                        <TableCell  className="tableC">
                                                            <span style={{cursor:"pointer"}}><KeyboardArrowRightIcon onClick={()=>this.openRecommendationDrawer(item)}></KeyboardArrowRightIcon></span>
                                                        </TableCell>
                                                    </TableRow>
                            
                                                </>
                                            )
                                        }) : null}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <DrawerComponent heading="Recommendation Details" showDrawer={()=>this.closeRecommendationDrawer()} openDrawer={this.state.openRecommendationDrawer}>
                                <div style={{position:"absolute", top:"15px", right:"60px"}}>
                                    <NavbarCustom 
                                        className="cognitiveFlowManagementProjectName"
                                        // dataList={this.props.projectList}
                                        dataList={this.state.virtualAssistantProjects}
                                        addImportProjectIcon={false}
                                        setSearchTerm={(item)=>this.setSearchTerm(item)}
                                        popperShow={this.state.popperShow}
                                        popperShowFn={()=>this.popperShowFn()}
                                        searchTerm={this.state.searchT}
                                        projectclick={(event,item)=>this.projectclick(event,item)}
                                        projectname={this.state.selectedProject} 
                                        rightPopUp={false}
                                        commanProjectNotShow={true}
                                    >
                                    </NavbarCustom>
                                </div>
                                <SelectCustom
                                    name="selectName"
                                    label="Type"
                                    changed={(event) => this.onSelectHandlerDrawer(event)}
                                    required={true}
                                    value={this.state.typeDrawer}
                                    options={[{value:'Created as Faq',displayValue:'Create as Faq'}, {value:'Added as Intent',displayValue:'Add as Intent'}]}
                                /> 
                                <TextAreaWithLabelComponent
                                    type="text"
                                    name="name"
                                    label="Intent Name"
                                    value={this.state.intentNameInDrawer}
                                    readOnly={false}
                                    onChange={(e)=>this.intentNameInDrawer(e)}
                                    required={true}
                                    autocomplete="new-password"
                                    style={{height:"100px",padding:"8px"}}
                                    disabled=''
                                />
                                <div style={{fontFamily:"Roboto-Black", fontSize:"14px", marginTop:"10px"}}>Associated Utterances</div>
                                {this.state.Utterances && this.state.Utterances.length>0 && this.state.Utterances.map((item,index)=>{
                                    var i = index+1
                                    var utteranceLabel = "Utterance "+i
                                    return(
                                        <div style={{display:"flex"}}>
                                            <div style={{width:"100%"}}>
                                                <TextAreaWithLabelComponent
                                                    type="text"
                                                    name="name"
                                                    label={utteranceLabel}
                                                    value={item}
                                                    readOnly={false}
                                                    onChange={(e)=>this.utteranceInDrawer(e,index)}
                                                    required={true}
                                                    autocomplete="new-password"
                                                    style={{height:"40px",padding:"8px"}}
                                                    disabled=''
                                                />
                                            </div>
                                            <span style={{marginTop:"35px",position:"absolute",right:"15px"}}><DeleteIcon onClick={(e)=>this.deleteUtterances(e,index)}></DeleteIcon></span>
                                        </div>
                                    )
                                })}             
                                <TextAreaWithLabelComponent
                                    type="text"
                                    name="name"
                                    label="Comments"
                                    value={this.state.commentsDrawer}
                                    readOnly={false}
                                    onChange={(e)=>this.commentsInDrawer(e)}
                                    required={true}
                                    autocomplete="new-password"
                                    style={{height:"100px",padding:"8px"}}
                                    disabled=''
                                />     
                                <button onClick={(()=>this.saveDrawerData())} style={{backgroundColor:"#2A93DF",color:"#FFFFFF",border:"none",width:"150px",marginTop:"10px", marginBottom:"10px",borderRadius:"5px",cursor:"pointer"}}>
                                    <span className='applyBtn'>SAVE</span>
                                </button>              
                        </DrawerComponent>
                        
                        <TablePagination
                                rowsPerPageOptions={[5,10,15]}
                                component="div"
                                count={this.state.tableData.length}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.page}
                                onPageChange={(e,page)=>this.handleChangePage(e,page)}
                                onRowsPerPageChange={(e)=>this.handleChangeRowsPerPage(e)}
                        />
                    </div>
                }
            </React.Fragment>
        )
    }
}

const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 12,
      margin:-8 -2,
    },
  }))(Tooltip);

const mapStateToProps = (state) => {
    return {
        loading:state.projectview.loading,
        continuousData: state.projectview.continuousData,
        projects: state.projectview.AllProjectsList,
        AssistantsList: state.projectview.AllAssistantsList,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
        onGetContinuousData: (apiUrl,project) => dispatch(actions.getContinuousData(apiUrl,project)),
        onSaveContinuousData: (apiUrl,payload,id) => dispatch(actions.saveContinuousData(apiUrl, payload,id)),
        onchangeProjectAction: (projectname) => dispatch(actions.changeProjectAction(projectname)),
    };
  };
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(withErrorHandler(ContinuousLearningAnalysis, axios));
