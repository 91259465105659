import React, { useState, useEffect } from "react";
import { Container, Navbar, Nav } from "react-bootstrap";
import classes from "../../../../../components/UI/Navbar/Navbar.module.css";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Paper } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import MenuItem from "@material-ui/core/MenuItem";
import {
  GetCalltoServer,
  PostCalltoServer,
} from "../../../../../store/utility";
import { useSelector, useDispatch } from "react-redux";
import { triageConfigurationupdateHandlerAction } from "../../../../../store/actions/adminSettings/adminSettingsActions";
import { get, isEmpty } from "lodash";
import getProductAttributesData from "./TraiageConfigurationTabs/ManageMasterList/helpers/getProductAttributesData";
import getProjectDetails from "../../helpers/getProjectDetails";
import { encryptAndStore } from "../../../../../utility/utility";

const SourceFields = ({ config, onSourceSelectChange, sourceSelectVal,setSourceListData }) => {
  const dispatch = useDispatch();
  const [popperSourceShow, setPopperSourceShow] = useState(false);
  const [openUI, setOpen] = React.useState(false);
  const [sourceSelect, setSourceSelect] = useState("");
  const anchorRef = React.useRef < HTMLButtonElement > null;
  const [sourceList, SetSourceList] = useState([""]);
  const [searchSource, SetSearchSource] = useState("");
  const [sourceField, setSourceField] = useState("");
  const ProjectView = useSelector((state) => state.projectview.IsProjectChange);
  const [selectData, setSelectData] = useState([]);
  const handlePaperShow = () => {
    setPopperSourceShow(true);
    setOpen(true);
  };

  const closePaperModel = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const updatedselectedSourceVal = localStorage.getItem("sourceSelect");

  const sourceclick = (event, source) => {
    SetSearchSource("");
    setPopperSourceShow(false);
    setSourceSelect(source);
    onSourceSelectChange(source);
    // localStorage.setItem("sourceSelect", source);
    encryptAndStore("sourceSelect", source);
    const projectName = getProjectDetails();
    let NewProjectName = ProjectView ? ProjectView : projectName;
    const url =
      config.INSIGHTS_API_URL +
      `/product-intelligent-triage/settings/sourcevaluemap/${NewProjectName}/${source
        .slice(13)
        .trim()}`;
    GetCalltoServer(url)
      .then((response) => {
        if (get(response, "data.status.responseType", "") === "success") {
          let data1 = get(response, "data.data", []);
          const data = data1.length ? data1 : getProductAttributesData();
          dispatch(triageConfigurationupdateHandlerAction(data));
        }
      })
      .catch((err) => { });
  };

  const ManualSourceclick = () => {
    setPopperSourceShow(false);

  };
  const getOptions = (data) => {
    if (isEmpty(data)) {
      return [];
    }
    return data.map((option) => ({
      label: option,
      value: option,
    }));
  };
  useEffect(() => {
    const url =
      config.INSIGHTS_API_URL +
      `/product-intelligent-triage/settings/indices`;
    GetCalltoServer(url)
      .then((response) => {
        if (get(response, "data.status.responseType", "") === "success") {
          const data = get(response, "data.data", []);
          setSelectData(getOptions(data));
        } else {
          setSelectData([]);
        }
      })
      .catch((err) => {
        setSelectData([]);
      });
  }, [])




  useEffect(() => {
    const projectName = localStorage.getItem("projectname");
    let sourceArr = [];
    PostCalltoServer(
      config.INSIGHTS_API_URL +
      `/utilityservice/ticketdump/get-info-by-project/project`,
      { project: [projectName],
        "modelType": "Triage"
       }
    )
      .then((response) => {
        let data = response.data.data;
        data.forEach((item) => {
          if (!item.source) return;
          let collection = item.collection;
          let collecLen = collection.length;
          let sourcefield = item.source;
          setSourceField(sourcefield);
          if (collecLen > 0) {
            for (let i = 0; i < collecLen; i++) {
              sourceArr.push(sourcefield + " - " + collection[i]);
            }
          }
        });
        SetSourceList(sourceArr);
        const projectName = getProjectDetails();
        let NewProjectName = ProjectView ? ProjectView : projectName;
        const sourceConst = sourceArr[sourceArr.length - 1]
        let url =
          config.INSIGHTS_API_URL +
          `/product-intelligent-triage/settings/sourcevaluemap/${NewProjectName}/${localStorage.getItem("sourceSelect").slice(13).trim() || sourceConst.slice(13).trim()
          }`;
        GetCalltoServer(url)
          .then((response) => {
            if (get(response, "data.status.responseType", "") === "success") {
              let data1 = get(response, "data.data", []);
              const data = data1.length ? data1 : getProductAttributesData();
              dispatch(triageConfigurationupdateHandlerAction(data));
            }
          })
          .catch((err) => { });
          setSourceListData(sourceArr);
      })
      .catch(() => { });
  }, [ProjectView, sourceSelect]);

  useEffect(() =>{
    const projectName = localStorage.getItem("projectname");
    let sourceArr = [];
    PostCalltoServer(
      config.INSIGHTS_API_URL +
      `/utilityservice/ticketdump/get-info-by-project/project`,
      { project: [projectName],
        "modelType": "Triage"
       }
    ).then(response => {
      let data = response.data.data;
        data.forEach((item) => {
          if (!item.source) return;
          let collection = item.collection;
          let collecLen = collection.length;
          let sourcefield = item.source;
          setSourceField(sourcefield);
          if (collecLen > 0) {
            for (let i = 0; i < collecLen; i++) {
              sourceArr.push(sourcefield + " - " + collection[i]);
            }
          }
        });
        encryptAndStore("source",sourceArr[sourceArr.length-1]);
    })
  },[ProjectView])

  return (
    <Container>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="dark"
        variant="dark"
        className={["col-lg-12", classes.homeHeaderColor].join(" ")}
      >
        <Navbar.Brand>
          <Nav className="SourceNavbar">
            <span className={classes.topProjectname2}>SOURCE VALUE</span>
            <div>
              <div
                onClick={handlePaperShow}
                style={{ cursor: "pointer", justifyContent: "initial" }}
              >
                <Button
                  color="primary"
                  className="projectDDAdmin"
                  onClick={handlePaperShow}
                >
                  {sourceList && sourceList.length > 1 ? (
                    <span className={"source-dropdown"}>{updatedselectedSourceVal === null ? sourceList[0] : `${"test_ticket - " +updatedselectedSourceVal}`}</span>
                  ) :
                    sourceList && sourceList.length === 1 ? (
                      <span className={"source-dropdown"}>{sourceList}</span>
                    )
                      :
                      <span className={"source-dropdown"}>{"Sample Collection"}</span>}
                  {
                    <ArrowDropDownIcon
                      style={{
                        filter: "brightness(4) invert(1)",
                        position: "relative",
                        top: "4px",
                        zIndex: "800",
                      }}
                      onClick={handlePaperShow}
                    />
                  }
                </Button>
              </div>
              {popperSourceShow && openUI ? (
                <Modal
                  open={openUI}
                  onClose={closePaperModel}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                  className="ModalWindow"
                >
                  <ClickAwayListener onClickAway={closePaperModel}>
                    <Paper
                      elevation={3}
                      variant="outlined"
                      square
                      className="paperModel"
                      open={openUI}
                      anchorEl={anchorRef.current}
                    >
                      <h3 className="projName">SOURCE VALUE</h3>
                      <div>
                        {sourceList && sourceList.length > 0 ? (
                          sourceList.map((item) => {
                            return (
                              <MenuItem
                                onClick={(event) => sourceclick(event, item)}
                                className={
                                  item == sourceSelect
                                    ? "activeProject"
                                    : "activeList"
                                }
                                key={item}
                              >
                                {item}
                              </MenuItem>
                            );
                          })
                        ) : (selectData.map((item => {
                          return (
                            <MenuItem
                              onClick={(event) => ManualSourceclick()}
                              className={
                                item == sourceSelect
                                  ? "activeProject"
                                  : "activeList"
                              }
                              key={item}
                            >
                              {item.value}
                            </MenuItem>
                          );
                        })
                        ))
                        }
                      </div>
                    </Paper>
                  </ClickAwayListener>
                </Modal>
              ) : (
                ""
              )}
            </div>
          </Nav>
        </Navbar.Brand>
      </Navbar>
    </Container>
  );
};

export default SourceFields;
