import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Form } from "react-bootstrap";
import DrawerWithSubHeading from "../../UIComponents/DrawerWithSubHeading/DrawerWithSubHeading";
import InputWithLabelComponent from "../../../containers/SettingPage/SettingInnerPages/components/InputWithLabelComponent/InputWithLabelComponent";
import ReactSelectComponent from "../../../containers/SettingPage/SettingInnerPages/components/ReactSelectComponent/ReactSelectComponent";
import TextAreaWithLabelComponent from "../../../containers/SettingPage/SettingInnerPages/components/TextAreaWithLabelComponent/TextAreaWithLabelComponent";
import ButtonCustom from "../../UIComponents/Button/ButtonComponent";
import { saveWhatsappConfigData } from "../../../store/actions/index";

const CommChannelDrawer = ({show, setShow, config, channel}) => {

    const dispatch = useDispatch();

    const whatsappEmptyObj = {name:'', whatsapp_business_id:'', whatsapp_phone_id:'', 
                            access_token:'',project_list:''}
    const teamsEmptyObj = {name:'', app_id:''}

    const [integrationData, setIntegrationData] = useState([channel === 'WHATSAPP'?whatsappEmptyObj:teamsEmptyObj]);
    const [VADropdownList,setVADropDownList] = useState([]);
    const [channelLabel, setChannelLabel] = useState('');

    const VALists = useSelector((state) => state.projectview.AllAssistantsList);
    const existingWhatsappData = useSelector((state) => state.projectview.whatsAppConfigData);
    const existingTeamsData = useSelector((state) => state.projectview.MSTeamsConfigData);
    
    useEffect(()=>{
        let list = [];
        VALists.map((item)=> list.push({value:item.virtualassistantName, 
            label:item.virtualassistantName, projects:item.project.join(',')}));
            list.push({value:'',label:'None',projects:''})
        setVADropDownList(list);
    },[VALists])

    useEffect(()=>{
        if(channel === 'WHATSAPP') {
            if(existingWhatsappData.length > 0)  setIntegrationData(existingWhatsappData);
            setChannelLabel('WhatsApp');
        } else {
            if(existingTeamsData.length > 0) setIntegrationData(existingTeamsData);
            setChannelLabel('MS Teams');
        }
    },[channel])

    const addMapping = () => {
        setIntegrationData((prev)=>[
            ...prev,
            channel === 'WHATSAPP'?whatsappEmptyObj:teamsEmptyObj
        ])
    }

    const deleteMapping = (index) => {
        setIntegrationData(integrationData.filter((_,i)=>i !== index))
    }

    const onChangeHandler = (key, item, index) => {
        let tempArray = [...integrationData];
        if(key === 'VAData') {
            if(channel === 'WHATSAPP') {
                tempArray[index] = {...tempArray[index],'name':item.value, 
                                'project_list':item.projects};
            } else {
                tempArray[index] = {...tempArray[index],'name':item.value};
            }
            
        } else {
            tempArray[index] = {...tempArray[index],[key]:item}
        }
        setIntegrationData(tempArray);    
    }

    const handleSubmit = () => {
        // console.log('integrationData:',integrationData);
        if(channel === 'WHATSAPP')
            dispatch(saveWhatsappConfigData(config.COMMON_API_URL, integrationData, channel));
        setShow(false);
    }

    const renderData = (item,index) => {
        if(channel === 'WHATSAPP') {
            return renderWhatsappData(item, index)
        }
        return renderTeamsData(item,index);
    }

    const renderTeamsData = (data, index) => {
        return (
        <div className='backgroundBox mb-2' key={'msTeams'+index}>
            <Col md={12}>
            <Row className="FormLabel-row">
                <Col md={9} className="FormLabel-col addBtn-label">
                    <Form.Label className="mb-1 text-box-title adding-other-input">
                        {'Mapping '.concat(index+1) }</Form.Label>
                </Col>  
                <Col md={1} className='countBtn-col plusBtn-align'>
                <div onClick={()=>deleteMapping(index)}>
                    <img className='removeBtn-img' src="./Icons/removeCircleBlack.svg"/>
                </div>    
                </Col>
            </Row>
            </Col>
            <div className="mt-1">
            <Col md={12}>
            <Row>
            <Col md={6}>
                <div className="common-input">
                    <Form.Label className="mb-1 text-box-title adding-other-input">Vitrual Assistant</Form.Label>
                </div>
                <ReactSelectComponent
                    customClassName="SelectView"
                    value={data.name === ''?{value:'None',label:'None'}:{value:data.name, label:data.name}}
                    options={VADropdownList}
                    name="selectedVA"
                    placeholder={'None'}
                    onChange={(item) => onChangeHandler('VAData', item, index)}
                />
            </Col>
            <Col md={6}>
                <InputWithLabelComponent
                    type="text"
                    name="teams_appId"
                    label="APP ID*"
                    value={data.app_id}
                    readOnly={false}
                    onChange={(e) => onChangeHandler('app_id', e.target.value, index)}
                    required={true}
                    placeHolder='Enter ID'
                />
            </Col>
            </Row></Col>
            </div>
        </div>
        )
    }

    const renderWhatsappData = (data,index) => {
        return (
            <div className='backgroundBox mb-2' key={'watsapp'+index}>
            <Col md={12}>
            <Row className="FormLabel-row">
                <Col md={9} className="FormLabel-col addBtn-label">
                    <Form.Label className="mb-1 text-box-title adding-other-input">
                        {'Mapping '.concat(index+1) }</Form.Label>
                </Col>  
                <Col md={1} className='countBtn-col plusBtn-align'>
                <div onClick={()=>deleteMapping(index)}>
                    <img className='removeBtn-img' src="./Icons/removeCircleBlack.svg"/>
                </div>    
                </Col>
            </Row>
            </Col>
            <div className="mt-1">
            <Col md={12}>
                <div className="common-input">
                    <Form.Label className="mb-1 text-box-title adding-other-input">Vitrual Assistant</Form.Label>
                </div>
                <ReactSelectComponent
                    customClassName="SelectView"
                    value={data.name === ''?{value:'None',label:'None'}:{value:data.name, label:data.name}}
                    options={VADropdownList}
                    name="selectedVA"
                    placeholder={'None'}
                    onChange={(item) => onChangeHandler('VAData', item, index)}
                />
            </Col>
            </div>
            <div className="mt-1">
            <Col md={12}>
            <Row>
            <Col md={6}>
                <InputWithLabelComponent
                    type="text"
                    name="watsapp_businessId"
                    label="Business ID*"
                    value={data.whatsapp_business_id}
                    readOnly={false}
                    onChange={(e) => onChangeHandler('whatsapp_business_id', e.target.value, index)}
                    required={true}
                    placeHolder='Enter ID'
                />
            </Col>
            <Col md={6}>
                <InputWithLabelComponent
                    type="text"
                    name="watsapp_phId"
                    label="Phone ID*"
                    value={data.whatsapp_phone_id}
                    readOnly={false}
                    onChange={(e) => onChangeHandler('whatsapp_phone_id', e.target.value, index)}
                    required={true}
                    placeHolder='Enter ID'
                />
            </Col>
            </Row></Col>
            </div>
            <div className="mt-1">
            <Col md={12}>
                <TextAreaWithLabelComponent 
                    label="Access Token*"
                    name="watsapp_token"
                    required ={true}
                    value={data.access_token}
                    defaultValue=""
                    className='text-area'
                    placeholder='Enter Access Token.'
                    onChange={(e) => onChangeHandler('access_token', e.target.value, index)}
                />
            </Col>
            </div>
            </div>
        )
    }

    const isFormValid = () => {
        switch(channel) {
            case 'WHATSAPP' : return integrationData.every((item)=>item.whatsapp_business_id && 
                                    item.whatsapp_phone_id && item.access_token);
            case 'MSTEAMS' : return integrationData.every((item)=>item.app_id)
        }
        
    }

    return (
        <>
         <DrawerWithSubHeading
            heading={channelLabel + " Integration Details"}
            subHeading={`Map the right virtual assistant with the right ${channel==='WHATSAPP'?'WhatsApp business':channelLabel} account`}
            showDrawer={setShow}
            openDrawer={show}
          >
            <div className="mt-1 mb-2">
                <Col md={12}>
                <Row className="FormLabel-row">
                    <Col md={6} className="">
                        <Form.Label className="mb-1 text-box-title adding-other-input">
                            Mapping of VA with {channelLabel} account
                        </Form.Label>
                    </Col>
                    <Col md={1} className='countBtn-col plusBtn-align align-auto'>
                        <div onClick={addMapping}>
                            <img className='addChannelMappingBtn-img' src="./Icons/AddBlackFilled.svg"/>
                        </div>    
                    </Col>
                </Row>
            </Col>
            </div>
            
            {integrationData.map((item, index)=>renderData(item,index))}

            <div className="mt-1">
               <Col md={12}>
                   <ButtonCustom variant='contained' label='Save'className='buttonWidth' clicked={handleSubmit}
                    disabled={!isFormValid()} />
               </Col>
            </div>
          </DrawerWithSubHeading>
        </>
    )
}

export default CommChannelDrawer;