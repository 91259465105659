import React, { useState } from "react";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { Row, Col} from "react-bootstrap";
import Badge from 'react-bootstrap/Badge';
import ArrowForwardIosSharpIcon from "@material-ui/icons/ArrowForwardIosSharp";
import RootCausesDetails from "./RootCausesDetails";
import "./SymptomAndRootCause.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EditRootcauseDetails from "./EditRootcauseDetails";


const RootCausesContent = ({ rootCause, id, config, advanceFilters, getRootCauses,permissions,setSearchTerm }) => {
  const [showDrawer, setShowDrawer] = useState(false);
  const toggleDrawer = (value) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setShowDrawer(value);
    setSearchTerm("");
  };
  const getAttributesData = (data, type) => {
    const selectedSourceFields = get(data, type, []);
    if (isEmpty(selectedSourceFields)) {
      return "--";
    }

    return selectedSourceFields.toString();
  };

  const getRootCausesAttributes = (rootCause) =>
    Object.entries(rootCause).map(([key, value]) => {
      if (Array.isArray(value)) {
        return (
          <>
          { (config.SYMPTOMS_ROOT_CAUSE_HIDDEN_FIELDS).includes(key) ?  null : (
          <Col xs={2}>
            <p className="issue-results-description-change">{getAttributesData(rootCause, key)}</p>
          </Col>) }
          </>
        );
      }
      return null;
    });

    const day = new Date();

    const yesterday = [(day.getFullYear()).toString(), ((day.getMonth() + 1)).toString().padStart(2, "0") , ((day.getDate())).toString().padStart(2, "0")]
  

    const BadgeCheck = (timeStamp) => { 
      let day = new Date();
      let yesterday = [(day.getFullYear()).toString(), ((day.getMonth() + 1)).toString().padStart(2, "0") , ((day.getDate())).toString().padStart(2, "0")]
      let syncedTimeStamp = (timeStamp);
      // let syncedDate = syncedTimeStamp[0].split("-");
      return JSON.stringify(yesterday) ==JSON.stringify(syncedTimeStamp)
  }

  return (
    <React.Fragment>
      <Row  xs={12} 
          className="d-flex rootcauses-content row-no-gutters"
          onClick={toggleDrawer(true)}
      >
        <Col className="d-flex rootcauses-container">
          <div className="row d-flex justify-content-between w-100 content-align-items">
          <Col>
             <div className="row d-flex justify-content-between w-100 content-align-items" >
               {
                 Object.keys(rootCause).sort().reverse().map((key) => {
                   return (
                   <Col style={{display: `${key === "timestamp" ? "none" : "block"}`, textAlign: "center", paddingRight: `${key === "rootcauseTitle" ? "1rem" : "0"}`}} md={2}>
                     
                     <p className="issue-results-description-change pr-0">{key === "timestamp" ? "" : Array.isArray(rootCause[key])? rootCause[key].join(", "): rootCause[key]}</p>
                   </Col>
                   )
                 })
               }
             </div>
       </Col>
            {/* <Col xs={2}>
              <p className="issue-results-description-change">{get(rootCause, "rootcause", "--") } { BadgeCheck(get(rootCause, "timestamp", "2022-02-18 07:12:48.519")) ? <Badge  bg="warning" text="dark" >New</Badge> :null }</p>
            </Col>
            <Col xs={2}>
              <p className="issue-results-description-change">{get(rootCause, "rootcauseTitle", "--")}</p>
            </Col>
            {getRootCausesAttributes(rootCause)} */}
          </div>
        </Col>
        <Col xs={1} className="arrow-img">
          <img src="./images/Vector.svg" />
        </Col>
      </Row>
      {showDrawer && permissions ? (
        <EditRootcauseDetails
          config={config}
          id={id}
          rootCause={rootCause}
          showDrawer={showDrawer}
          toggleDrawer={toggleDrawer}
          getRootCauses={getRootCauses}
          closeDrawer={() => {setShowDrawer(false)}}
          advanceFilters ={advanceFilters}
          toastFun={(msg, type) => {
            toast(msg, {
              type: type,
              pauseOnHover: false,
            });
          }}
        />
      ) : null}
       <ToastContainer/>
    </React.Fragment>
  );
};
export default RootCausesContent;
