import { useState, useEffect } from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import ReactSelectComponent from "../../../SettingPage/SettingInnerPages/components/ReactSelectComponent/ReactSelectComponent";
import * as constants from './Constants';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from "@material-ui/core/styles";
import '../conversationalUI.css';

const useStyles = makeStyles(() => ({
    textfield: {
        borderWidth: "thin",
        borderRadius: "4px",
        paddingLeft: "3%",
        paddingRight: "2%",
        border: "1px solid #484545",
        transform: "matrix(1, 0, 0.01, 1, 0, 0)",
        marginTop: "2%",
        height: "38px",
        paddingTop: "2%",
        width: "80%",
    },
    textfield_enddate: {
        borderWidth: "thin",
        borderRadius: "4px",
        paddingLeft: "2%",
        paddingRight: "2%",
        border: "1px solid #484545",
        transform: "matrix(1, 0, 0.01, 1, 0, 0)",
        marginTop: "2%",
        height: "38px",
        paddingTop: "1.1%",
        width: "39%",
    }

  }));

const TimerTrigger = ({triggercontent, onChangeHandler, triggerData, addTriggerDrawer}) => {

    const classes = useStyles();
    const frequencyList = [{ value: "Hourly", label: "Hourly" },
        { value: "Daily", label: "Daily" },
        { value: "Weekly", label: "Weekly" },
        { value: "Monthly", label: "Monthly" }];
    const days = ['S','M','T','W','T','F','S'];
    const [weekList, setWeekList] = useState([]);
    const [weekday, setWeekday] = useState([]);
    const [hourList, setHourList] = useState();
    const [minutesList, setMinutesList] = useState();

    useEffect(()=>{
        let week = [];
        days.forEach((item)=>week.push({day:item,selected:false}));
        if(!addTriggerDrawer && typeof(triggercontent.weekday) === 'string') { //to support old existing timers
            week[constants.WEEK.indexOf(triggerData?.triggercontent?.weekday)].selected=true;
            onChangeHandler(triggerData?.triggercontent?.weekday,'monthweekday');
        }else if(!addTriggerDrawer && triggercontent?.weekday.length>0) {
            triggercontent.weekday.map((item)=>
                week[constants.WEEK.indexOf(item)].selected=true
            )
        } else {
            week[0].selected=true;
        }
        setWeekList(week);

        let weekdays = [];
        constants.WEEK.forEach((item)=>weekdays.push({value:item,label:item}));
        setWeekday(weekdays);
        let hour = [];
        let i = 0;
        while(i < 24) {
            let digit = i.toLocaleString("en-US",{minimumIntegerDigits:2,useGrouping:false});
            hour.push({value:digit,label:digit});
            i++;
        }
        setHourList(hour);
        let j = 0;
        let minutes = [];
        while(j < 60) {
            let digit = j.toLocaleString("en-US",{minimumIntegerDigits:2,useGrouping:false});
            minutes.push({value:digit,label:digit});
            j++;
        }
        setMinutesList(minutes);                 
    },[])

    useEffect(()=>{
        let weekData = []
        weekList !== undefined && weekList.map((data,i)=>{
            if(data.selected) {
                weekData.push(constants.WEEK[i]);
            }  
        });
        if(typeof(triggercontent.weekday) === 'string') { //to support old existing timers
            onChangeHandler(weekData[0],'weekday');
        } else {
            onChangeHandler(weekData,'weekday');
        }            
        
    },[weekList]);

    const getTimezone = (value) => {
        return constants.TIMEZONE.find((item)=>item.value===value);
    }

    const getMonthWeek = (value) => {
        return constants.MONTHWEEKLIST.find((week)=>week.value===value);
    }

    function unSelectOtherDays (dayList, index) {
        dayList.map((day, i)=>{
            if(i!==index)
                day.selected = false;
        })
        return dayList;
    }

    function toggleDay(index, value) {
        let tempArray = [...weekList];
        // tempArray = unSelectOtherDays(tempArray, index); //to facilitate single selection
        tempArray[index] = {...tempArray[index], ['selected']:!value};
        setWeekList(tempArray);
    }

    function getRepeatLabel() {
        switch(triggercontent.frequency) {
            case 'Daily': return 'Day';
            case 'Weekly': return 'Week';
            case 'Monthly': return 'Month';
        }
    }

    return (
        <>                
        <div className="mt-3">
        <Col md={12}>
            <div className="common-input">
                <Form.Label className="mb-1 text-box-title adding-other-input">Frequency*</Form.Label>
            </div>
            <ReactSelectComponent
                customClassName="SelectView"
                value={{value:triggercontent.frequency,label:triggercontent.frequency}}
                options={frequencyList}
                name="option"
                placeholder={'Select'}
                onChange={(event) => onChangeHandler(event.value,'frequency')}
            />
        </Col>
        </div>
        {triggercontent.frequency !== null && (
        <>
        <div className="mt-3">
            <Row className='time-row'>
            <Col md={6}>
                <div className="common-input">
                    <Form.Label className="mb-1 text-box-title adding-other-input">Start Date*</Form.Label>
                </div>
                <TextField
                    id="date"
                    type="date"
                    className={classes.textfield}
                    defaultValue={triggercontent.startdate}
                    onChange={(e) => onChangeHandler(e.target.value,'startdate')}
                    InputLabelProps={{
                    shrink: true,
                    }}
                />
            </Col>
            {triggercontent.frequency !== 'Hourly' && (
            <>
            <Col md={3}>
                <div className="common-input">
                    <Form.Label className="mb-1 text-box-title adding-other-input">Hours</Form.Label>
                </div>
                <ReactSelectComponent
                    customClassName="SelectView time-list-box"
                    value={{value:triggercontent.executiontime.substring(0,2),label:triggercontent.executiontime.substring(0,2)}}
                    options={hourList}
                    name="hours"
                    placeholder={'Select'}
                    onChange={(event) => onChangeHandler(event.value,'hours')}
                />
            </Col>
            <Col md={3}>
                <div className="common-input">
                    <Form.Label className="mb-1 text-box-title adding-other-input">Mins</Form.Label>
                </div>
                <ReactSelectComponent
                    customClassName="SelectView time-list-box"
                    value={{value:triggercontent.executiontime.substring(3,5),label:triggercontent.executiontime.substring(3,5)}}
                    options={minutesList}
                    name="minutes"
                    placeholder={'Select'}
                    onChange={(event) => onChangeHandler(event.value,'mins')}
                />
            </Col>
            </>
            )} 
            </Row>
        </div>

        {triggercontent.frequency === 'Hourly' && (
        <>
        <div className="mt-3">
        <Row className='time-row'>
        <Col md={6}>
            <div className="common-input">
                <Form.Label className="mb-1 text-box-title adding-other-input">Start Time*</Form.Label>
            </div>
            <Row>
            <Col md={6}>
                <div className="common-input">
                    <Form.Label className="mb-1 text-box-title adding-other-input">Hours</Form.Label>
                </div>
                <ReactSelectComponent
                    customClassName="SelectView time-select"
                    value={{value:triggercontent.executiontime.substring(0,2),label:triggercontent.executiontime.substring(0,2)}}
                    options={hourList}
                    name="hours"
                    placeholder={'Select'}
                    onChange={(event) => onChangeHandler(event.value,'hours')}
                />
            </Col>
            <Col md={6}>
                <div className="common-input">
                    <Form.Label className="mb-1 text-box-title adding-other-input">Mins</Form.Label>
                </div>
                <ReactSelectComponent
                    customClassName="SelectView time-select"
                    value={{value:triggercontent.executiontime.substring(3,5),label:triggercontent.executiontime.substring(3,5)}}
                    options={minutesList}
                    name="minutes"
                    placeholder={'Select'}
                    onChange={(event) => onChangeHandler(event.value,'mins')}
                />
            </Col>
            </Row>
        </Col>
        <Col md={6}>
            <div className="common-input">
                <Form.Label className="mb-1 text-box-title adding-other-input">End Time*</Form.Label>
            </div>
            <Row>
            <Col md={6}>
                <div className="common-input">
                    <Form.Label className="mb-1 text-box-title adding-other-input">Hours</Form.Label>
                </div>
                <ReactSelectComponent
                    customClassName="SelectView time-select"
                    value={{value:triggercontent.endtime.substring(0,2),label:triggercontent.endtime.substring(0,2)}}
                    options={hourList}
                    name="hours"
                    placeholder={'Select'}
                    onChange={(event) => onChangeHandler(event.value,'endHr')}
                />
            </Col>
            <Col md={6}>
                <div className="common-input">
                    <Form.Label className="mb-1 text-box-title adding-other-input">Mins</Form.Label>
                </div>
                <ReactSelectComponent
                    customClassName="SelectView time-select"
                    value={{value:triggercontent.endtime.substring(3,5),label:triggercontent.endtime.substring(3,5)}}
                    options={minutesList}
                    name="minutes"
                    placeholder={'Select'}
                    onChange={(event) => onChangeHandler(event.value,'endMins')}
                />
            </Col>
            </Row>
        </Col>
        </Row>
        </div>
        <div className='mt-3 form-sentence'>
        <Col md={12}>
            <Row className='time-row al-center'>
                <div>Repeat every</div>
                <div className='time-select-div'>
                    <ReactSelectComponent
                        customClassName="SelectView"
                        value={{value:triggercontent.repeattime.substring(0,2),label:triggercontent.repeattime.substring(0,2)}}
                        options={hourList}
                        name="repeattimeHr"
                        placeholder={'Select'}
                        onChange={(event) => onChangeHandler(event.value,'repeattimeHr')}
                    />
                </div>
                <div>Hours</div>
                <div className='time-select-div'>
                    <ReactSelectComponent
                        customClassName="SelectView"
                        value={{value:triggercontent.repeattime.substring(3,5),label:triggercontent.repeattime.substring(3,5)}}
                        options={minutesList}
                        name="repeattimeMin"
                        placeholder={'Select'}
                        onChange={(event) => onChangeHandler(event.value,'repeattimeMin')}
                    />
                </div>
                <div>Mins</div>
            </Row>
        </Col>
        </div>
        </>
        )}

        {triggercontent.frequency === 'Weekly' && (
        <div>
            <div className="mt-3 weeks-row">
                {weekList.map((day,i)=> (
                    <span className={day.selected?'week-day-selected':'week-day'} 
                    onClick={()=>toggleDay(i,day.selected)} key={i}>{day.day}</span>
                ))}
            </div>
        </div>

        )}

        {triggercontent.frequency === 'Monthly' && (
            <>
            <div className="mt-4">
            <Col md={12}>
                <input type="radio" id="day" name="monthweek" value="monthweek"
                 checked={triggercontent.monthOption==='monthweek'} onChange={()=>onChangeHandler('monthweek','monthOption')}/> 
                 <div className='month-day'>On the 
                    <ReactSelectComponent
                        customClassName="SelectView month-day-selections first-col"
                        value={getMonthWeek(triggercontent.monthweek)}
                        options={constants.MONTHWEEKLIST}
                        name="monthweek"
                        placeholder={'Select'}
                        onChange={(event) => onChangeHandler(event.value,'monthweek')}
                        disabled={triggercontent.monthOption!=='monthweek'}
                    />
                    <ReactSelectComponent
                        customClassName="SelectView month-day-selections second-col"
                        value={{value:triggercontent.monthweekday,label:triggercontent.monthweekday}}
                        options={weekday}
                        name="monthweekday"
                        placeholder={'Select'}
                        onChange={(event) => onChangeHandler(event.value,'monthweekday')}
                        disabled={triggercontent.monthOption!=='monthweek'}
                    />
                </div>
            </Col>
            </div>
            <div className="mt-4">
            <Col md={12}>
                <input type="radio" id="day" name="monthday" value="monthday" className='flexStyle'
                     checked={triggercontent.monthOption==='monthday'} onChange={()=>onChangeHandler('monthday','monthOption')} />
                <div className='month-day al-center'>On Day 
                <span>
                    <input type='number' name='count' className={['dayNo-input no-arrows',triggercontent.monthOption!=='monthday'?'disabledStyle':''].join(' ')} value={triggercontent.monthday}
                    onChange={e=>onChangeHandler(e.target.value,'monthday')} readOnly={triggercontent.monthOption!=='monthday'} />
                </span>
                </div>
            </Col>
            </div>
            </>
        )}

        {triggercontent.frequency !== 'Hourly' && (
            <div className='mt-3 form-sentence'>
            <Col md={12}>
                Repeat every 
                <span>
                    <input type='number' name='count' className='count-input no-arrows' value={triggercontent.repeatcount}
                    onChange={e=>onChangeHandler(e.target.value,'repeatcount')} pattern="[0-9]{2}"/>
                </span>
                    {getRepeatLabel()}
            </Col>
            </div>
        )}

        <div className="mt-3">
        <Col md={12}>
            <div className="common-input">
                <Form.Label className="mb-1 text-box-title adding-other-input">End Date*</Form.Label>
            </div>
            <TextField
                id="enddate"
                type="date"
                className={classes.textfield_enddate}
                defaultValue={triggercontent.enddate} //"2017-05-24"
                onChange={(e) => onChangeHandler(e.target.value,'enddate')}
                InputLabelProps={{
                shrink: true,
                }}
            />
        </Col>
        </div>       

        <div className='mt-3'>
            <Col md={12}>
            <div className="common-input">
                <Form.Label className="mb-1 text-box-title adding-other-input">Timezone</Form.Label>
            </div>
            <ReactSelectComponent
                customClassName="SelectView"
                value={getTimezone(triggercontent.timezone)}
                options={constants.TIMEZONE}
                name="timezone"
                placeholder={'Select'}
                onChange={(event) => onChangeHandler(event.value,'timezone')}
            />
            </Col>
        </div>
        </>
        )}
        </>
    )
}

export default TimerTrigger;