import React, { memo } from 'react';
import { Handle } from 'react-flow-renderer';
import NodeBox from './NodeBox';

export default memo(({ data, isConnectable }) => {
    
  return (
    <>
      <Handle
        type="target"
        position="top"
        style={{ background: '#555' }}
        isConnectable={isConnectable}
      />
      <NodeBox type='Media' data={data}></NodeBox>
     
      <Handle
        type="source"
        position="bottom"
        style={{ background: '#555' }}
        isConnectable={isConnectable}
      />
      
    </>
  );
});