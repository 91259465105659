const TriageDataStatus = (config) => {
  return [
    {
        "message":"Data preparation is in Progress",
        "key":"dataPreInPro"
    },
    {
        "message":"Data preparation is Completed",
        "key":"dataPreComp"
    },
    {
        "message":"Symptom model is in Progress",
        "key":"SymModelInPro"
    },
    {
        "message":"Symptom model is in Progress",
        "key":"SymModelComp"
    },
    {
        "message":"Resolution model is in Progress",
        "key":"ResModelInPro"
    },
    {
        "message":"Resolution model is Completed",
        "key":"ResModelComp"
    },
    {
        "message":"Symptom predictions is in Progress",
        "key":"SymPreInPro"
    },
    {
        "message":"Symptom predictions is Completed",
        "key":"SymPreComp"
    },
    {
        "message":"Resolutions predictions is in Progress",
        "key":"ResPreInPro"
    },
    {
        "message":"Resolutions predictions is Completed",
        "key":"ResPreComp"
    },{
        "message":"Symptom Resolutions mapping model is in Progress",
        "key":"SymResInPro"
    },
    {
        "message":"Symptom Resolutions mapping model is Completeds",
        "key":"SymResComp"
    }
  ];
};

export default TriageDataStatus;
