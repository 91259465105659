import get from 'lodash/get';

const fetchClustersDataSuccessReducer = (state, payload) => {
    return {
        ...state,
        clustersData: JSON.parse(get(payload, 'data.data', {})),
        clustersDataLoading: false,
    };
};

export default fetchClustersDataSuccessReducer;