import React from 'react';
import { connect } from 'react-redux';
import './Table.css';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import { useState } from 'react';



import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';


// const LightTooltip = withStyles((theme) => ({
//   tooltip: {
//     backgroundColor: theme.palette.common.white,
//     color: 'rgba(0, 0, 0, 0.87)',
//     boxShadow: theme.shadows[1],
//     fontSize: 12,
//     margin:-8 -2,
//   },
// }))(Tooltip);


const useStyles = makeStyles((theme)=>({
    table: {
      minWidth: 750,
    },
    tableContainer:{
        maxHeight:332,
        width: '104%',
        borderLeft:'none !important',
        [theme.breakpoints.up('lg')]: {
          maxHeight:480,
        },
        [theme.breakpoints.between('1300','1600')]: {
          maxHeight:332,
        },
    },
    tableHeader:{
        fontWeight:700,
        backgroundColor:'#7D7D7D',
        width:'-1px',
        color:'#ffffff',
        fontFamily: 'Roboto-Regular',
        fontStyle: 'normal',
        fontSize: 13,
        fontVariant: 'small-caps',
        textTransform: 'uppercase',
        padding:'15px 10px',
    },
    tableBodyCell:{
        padding:'5px 10px',
        fontFamily: 'Roboto-Regular',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 12,
        color:'#000000',

    },
    menuItems:{
      fontSize:14,
      fontWeight:'bold',
      fontFamily:'Roboto-Regular',
      color:'black',
      '&.active, &:hover, &.active:hover':{
        background:'none',
        color:'#2A93DF'
      }
  },
  
    // tableRow: {
    //   "&:hover": {
    //     backgroundColor: "#5A85F2 !important"
    //   }
    // }
  }));
 
  
    

  
  

function TableMI(props) {
  
  const [page, setPage] = useState(0);
  const [rowsPerPage,setRowsPerPage] = useState(5);
  const classes = useStyles();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0); // Reset page to 0 when changing rows per page
  };

    
  
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  
  

   
  return (
    
      <>
      {props.smartFaqSearchResultArray!=undefined && props.smartFaqSearchResultArray.length!==0 ?
    <TableContainer  className={classes.tableContainer} style={{maxHeight:props.tab == "ACTIVE FAQS" ?"300px":"700px"}}>
        <Table className={classes.table} aria-label="simple table" stickyHeader >
        <TableHead className='myownclass'>
          <TableRow className={classes.tableHeaderRow}>
      
              {props.columnDefs!=undefined && props.columnDefs!==null?props.columnDefs.map((headerName)=>{
                  return (
                      <>   
                         <TableCell className={classes.tableHeader} style={{width:headerName.headerName == 'Created Date'?"120px":"inherit"}}>{headerName.headerName} </TableCell>
                      </>
                      )}):null}
                      <TableCell className={classes.tableHeader}></TableCell>
    
          </TableRow>
        </TableHead>
        <TableBody >
            {props.smartFaqSearchResultArray!=undefined && props.smartFaqSearchResultArray!==null ? 
            props.smartFaqSearchResultArray.slice(startIndex,endIndex).map((item)=>{
                return(
                    <>
                    <TableRow key={item.recordId}  className='myownclass' >
                        <TableCell onClick={()=>props.AIStandard.RW&&props.onGridRowClicked(item)} className={classes.tableBodyCell}>
                           {item.description && item.description.length>45?item.description.slice(0, 35) + '...':item.description}
                        </TableCell>
                        <TableCell onClick={()=>props.AIStandard.RW&&props.onGridRowClicked(item)} className={classes.tableBodyCell}>
                            {item.response && item.response.length>45?item.response.slice(0, 35) + '...':item.response}
                        </TableCell>
                      {props.columnDefs && props.columnDefs.length > 2 && <TableCell onClick={() => props.AIStandard.RW && props.onGridRowClicked(item)} className={classes.tableBodyCell}>
                        {item.learn.length > 45 ? item.learn.slice(0, 35) + '...' : item.learn}
                      </TableCell>}
                      <TableCell onClick={()=>props.AIStandard.RW&&props.onGridRowClicked(item)} className={classes.tableBodyCell}>
                           {item.date}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                        {props.tab == "ACTIVE FAQS" ? <img src='./Icons/OptionsIcon.svg'  aria-controls="simple-menu" aria-haspopup="true"
                          onClick={props.handleClick(item)} style={{padding:'14px',cursor:'pointer',position:'relative',right:'10px'}} />:
                         <KeyboardArrowRightIcon color="action" onClick={props.faqDetails}
                         style={{cursor:'pointer',position:'relative',right:'10px'}}> </KeyboardArrowRightIcon>}
                          </TableCell>
                    </TableRow>
                    
                    </>
                )              
            }) :null}
        </TableBody>
        </Table>
        
        <TablePagination
        rowsPerPageOptions={[5,10,15]}
        component="div"
        count={props.smartFaqSearchResultArray.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(e,page)=>handleChangePage(e,page)}
        onRowsPerPageChange={(e)=>handleChangeRowsPerPage(e)}
        style={{ marginRight: '40px' }}
           />
          
        
    </TableContainer>:''}
    
    
      <Menu
        id={props.menuID ? props.menuID : "simple-menu"}
        anchorEl={props.anchorElement}
        keepMounted
        open={Boolean(props.anchorElement)}
        onClose={props.handleMenuClose}
      >
        <MenuItem
          className={props.AIStandard.RW ? `${classes.menuItems} ` : `${classes.menuItems} click-access`}
          onClick={() => props.editData(props.incomingData)}>EDIT</MenuItem>
        <MenuItem
          className={props.AIStandard.RW ? `${classes.menuItems}` : `${classes.menuItems} click-access`}
          onClick={() => props.deleteData(props.incomingData)}>DELETE</MenuItem>
          {/* <MenuItem
          className={props.AIStandard.RW ? `${classes.menuItems} ` : `${classes.menuItems} click-access`}
          onClick={() => props.faqDetails(props.incomingData)}>FAQ DETAILS</MenuItem> */}
      </Menu>
      
    </>
  )
}


const mapStateToProps = (state) => {
  return {
    AIStandard:state.user.moduleAccess.conversationalStandard,
  };
};

const mapDispatchToProps = () => {
  return {
   
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TableMI);
