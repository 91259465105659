import React, {  useEffect, useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Col,  Form } from "react-bootstrap";
import DrawerHeaderContent from "../components/DrawerHeaderContent/DrawerHeaderContent";
import SecondaryButtonWithLoader from "../components/SecondaryButtonWithLoader/SecondaryButtonWithLoader";
import PrimaryButtonWithLoader from "../components/PrimaryButtonWithLoader/PrimaryButtonWithLoader";
import InputWithLabelComponent from "../components/InputWithLabelComponent/InputWithLabelComponent";
import { isEqual,get } from "lodash";
import axios from "../../../../instance";
import { useDispatch } from "react-redux";
import { SetWarningMessage } from "../../../../store/actions";
import {decrypt} from '../../../../utility/utility'


const useStyles = makeStyles({
  list: {
    width: "29rem",
  },
});


const AddCollection = ({ showDrawer, toggleDrawer, updateCollectionName,config,selectedCollection,collectionNames,records }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("")
  const [btnUpload, setBtnUpload] = useState(true);

  const saveHandler = () => {
     updateCollectionName(inputValue)
  }
  const totalCollectionData = records.map((item) => item.collection)
  const ticketdump = () => {
    // const configHeader = { headers: { Authorization: `Bearer ${token}`} };
    let token = localStorage.getItem("accessToken");
    // let token = decrypt("newAccessToken");
    const configHeader = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    if (totalCollectionData.includes(inputValue)) {
      axios
        .post(
          config.config.INSIGHTS_API_URL +
            `/product-intelligent-triage/cleanup/check-for-index/${inputValue}`,
          {},
          configHeader
        )
        .then((response) => {
          if (get(response, "data.status.responseType", "") === "success") {
          } else {
            dispatch(
              SetWarningMessage(
                "Collection name already exists! Please provide a different name for the collection. New data will be concatenated to the existing data otherwise."
              )
            );
          }
        })
        .catch((errors) => {
          console.log("errorrrrrrrrrrr", errors);
          dispatch(
            SetWarningMessage(
              "Collection name already exists! Please provide a different name for the collection. New data will be concatenated to the existing data otherwise."
            )
          );
        });
    }
  };

  const onChangeHandler = (e) => {
     if(!isEqual(e.target.value,"")){
      setInputValue(e.target.value);
     }else
        setInputValue(e.target.value);
        if((e.target.value).trim() === "" ){
        setBtnUpload(true);
        }
        else {
          setBtnUpload(false);
        }
  }

  return (
    <React.Fragment>
      <Drawer anchor={"right"} open={showDrawer} onClose={toggleDrawer(false)}>
        <div
          className={clsx(classes.list, classes.fullList)}
          role="presentation"
        >
          <DrawerHeaderContent
            heading={"Add Collection Details"}
            toggleDrawer={toggleDrawer}
          />
          <div
            className="d-flex flex-column Symtom-Item"
            style={{ paddingTop: "0.25rem", paddingLeft: "1.55rem" }}
          >
            <div className="mt-1 mb-2">
              <Col md={12}>
                
              <form  onSubmit={() => {
                       saveHandler();
                       ticketdump();
                     }}>
                <Form.Label className="mb-1 text-box-title  adding-other-input" for="collection_name">
                  {"Collection Name"}
                </Form.Label>
                <InputWithLabelComponent
                  type="text"
                  pattern="[a-zA-Z][a-zA-Z0-9-_.]{1,20}"
                  title="Collection name should only contain alphabets and numbers. e.g. Collection123"
                  className="mt-2"
                  name="collectionName"
                  showLabel={false}
                  value={inputValue}
                  readOnly={false}
                  onChange={(e) => onChangeHandler(e)}
                />
                <div className="d-flex pt-3 flex-row button-content">
                  <SecondaryButtonWithLoader
                    className=""
                    size="lg"
                    text="Cancel"
                    onClick={toggleDrawer(false)}
                  />
                  <PrimaryButtonWithLoader
                    className="ml-4"
                    type={"submit"}
                    size="lg"
                    text="save"
                    disabled={btnUpload}
                  />
                </div>
                </form>
              </Col>
            </div>
          </div>
        </div>
      </Drawer>
    </React.Fragment>
  );
};

export default AddCollection;
