import React, { Component } from "react";
import { connect } from 'react-redux';
import axios from '../../instance'
import { Col, Image, Button, Form } from 'react-bootstrap';
import classes from './ProjectViewPage.module.css'
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import Input from '../../components/UI/Input/InputCustom';
import ButtonCustom from '../../components/UI/Button/Button';
import * as actions from '../../store/actions/index'
import * as XLSX from 'xlsx'
import ValidationMsg from '../../components/ValidationMsg/ValidationMsg';
import Dropzone from "./dropzone/Dropzone";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import SpinnerPage from '../../components/UI/Loader/Loader';
import DrawerComponent from "../../components/UIComponents/Drawer/DrawerComponent";
import InputWithLabelComponent from "../SettingPage/SettingInnerPages/components/InputWithLabelComponent/InputWithLabelComponent";
import SelectComponent from "../../components/UIComponents/SelectDropdown/SelectComponent";
import ButtonComponent from "../../components/UIComponents/Button/ButtonComponent";
import SharePointPage from "./SharePointPage";
import TextAreaWithLabelComponent from "../../containers/SettingPage/SettingInnerPages/components/TextAreaWithLabelComponent/TextAreaWithLabelComponent";


const Category = [];
let categoryTouched = false;
class SynonymsDrawerContainer extends Component {
  botEditor = React.createRef();
  altQues
  altque = 0
  gridAltQue = null
  gridCol4
  state = {
    dailogError: '',
    dailogErrorStatus: false,
    existingFilesNames: null,
    existingFilesErrorMsg: null,
    macrosUserValue: null,
    dynamicSateValue: [],
    flowValue: false,
    urlMacrosInput: {
      value: "",
      theUrl: null,
      proxy: null,
      auth: null,
      port: null,
      data: null,
      contentType: null,
      h: null,
      s: null,
      defaultValue: null,
      log: null,
      displaydata: null,
      options: null,
      dv: null,
      client: null,
      isForm: null,
      title: null,
      ms: null,
    },

    columnDefs: [
      {
        headerName: "Question",
        field: "description",
        editable: true,
        tooltipField: "description",
        cellEditor: 'agLargeTextCellEditor',
        cellEditorParams: {
          rows: 4,
          cols: 35
        }
      },
      {
        headerName: "Answer",
        field: "response",
        editable: true,
        tooltipField: "response",
        cellEditor: 'agLargeTextCellEditor',
        cellEditorParams: {
          rows: 5,
          cols: 36
        }
      },
      {
        headerName: "Alternate Questions",
        field: "learn",
        tooltipField: "learn",
        editable: true,
        cellEditor: 'agLargeTextCellEditor',
        cellEditorParams: {
          rows: 5,
          cols: 35
        }
      },
    ],

    defaultColDef: {
      flex: 1,
      minWidth: 100,
      resizable: true,
      headerCheckboxSelectionFilteredOnly: true,
      autoHeight: true,
      stopEditingWhenGridLosesFocus: true,

    },
    rowSelection: 'multiple',
    KnowledgeTabSelected: "Create",
    KnowledgeMacroExpand: true,
    KnowledgeMacroSelected: "Custom Macros",
    selectedSubKnowledgeTab: "Manual",
    SeletedKnowledgeIntents: [],
    currentBotSelected: "",
    ShowDeleteIcons: false,
    IsKnowledgeRowSelected: false,
    gridModel1: false,
    gridCol1: null,
    gridCol2: null,
    gridCol3: null,
    gridAltQue: [],
    gridAltQueFlag: false,
    gridAltQueCount: 0,
    rowdata: null,
    updateKnowledgeData: null,
    knowledgeImportFile: null,
    KnowledgeFilevalidation: null,
    knowledgeFile: null,
    KnowledgeIntentImportFile: false,
    KnowledgeIntentImportFileName: null,
    FAQImportFile: false,
    FAQImportFileName: null,
    IsDeleteAll: false,
    scriptSelected: false,
    ShowRightPanel: false,
    currentFileSelected: null,
    knowledgeIntentSearch: "",
    CategoryOldVal: null,
    delCategory: null,
    deleteknowledgeIntentShow: false,
    custommacroShow: false,
    prvProj: null,
    displayInfoShow: false,
    displayInfoShow1: false,
    displayUseContent: false,
    useMacroValue: null,
    __httpGet: {},
    __httpPost: {},
    prompt: {},
    display: {},
    wait: {},
    __htmlView: {},
    agentHover: {},
    scripttouched: false,
    currentAceValue: "",
    scriptValue: null,
    KnowledgeIntentcontrols: {
      id: {
        value: '',
      },
      flowDesc: {
        elementType: 'input',
        elementConfig: {
          type: 'text',
          placeholder: ''
        },
        value: '',
        className: '',
        readOnly: false,
      }
      ,
      response: {
        elementType: 'input',
        elementConfig: {
          type: 'text',
          placeholder: ''
        },
        value: '',
        className: '',
        readOnly: false,
      }
    },
    KnowledgeIntentcontrolsDropdown2: {
      flowDesc: {
        elementType: 'input',
        elementConfig: {
          type: 'text',
          placeholder: ''
        },
        value: '',
        className: '',
        readOnly: true,
      },

      file: {
        elementType: 'file',
        elementConfig: {
          type: 'text',
          placeholder: ''
        },
        value: '',
        className: ''
      },
      flowName: {
        elementType: 'input',
        elementConfig: {
          type: 'text',
          placeholder: ''
        },
        value: '',
        validation: {
          required: true
        },
        valid: false,
        touched: false,
        className: '',
      },
      id: {
        value: ''
      }
    },

    KnowledgeIntentcontrolsDropdown4: {
      KnowledgeSource: '',
      url: '',
    },
    knoledgeRows: [{}],
    knoledgeReLearnRows: [{}],
    KnowledgeDropdown: {
      elementType: 'select',
      elementConfig: {
        options: [
          { value: 'Upload FAQ document', displayValue: 'Upload FAQ from local' },
          { value: 'Individual questions', displayValue: 'Add Q&A' },
          { value: 'Share Point', displayValue: 'Share Point' },
        ]
      },
      value: 'Upload FAQ document',
      className: 'selectLang'
    },
    OriginalKnowledgeGridData: null,
    UpdatedKnowledgeGridData: null,
    emptyFlowNameMsg: null,
    emptyResponseMsg: null,
    emptyfaqNameMsg: null,
    selectedCategory: null,
    emptycatSelectedMsg: null,
    catSelected: 'common',
    catKey: 'common',
    existingcatSelected: null,
    fileExistFlag: null,
    openModal: false,
    dialogValue: {
      title: '',
    },
    deleteCatModal: false,
    fileUploadCatModel: false,
    files: null,
    categoryColumnDefs: [{
      headerName: "List of Categories",
      field: "title",
      editable: true,
      tooltipField: "title",
      headerClass: 'header-green',
    },],
    categoryDefaultColDef: {
      flex: 1,
      minWidth: 100,
      resizable: true,
      headerCheckboxSelection: isFirstColumn,
      checkboxSelection: isFirstColumn,
      headerCheckboxSelectionFilteredOnly: true,
      autoHeight: true,
      stopEditingWhenGridLosesFocus: true,

    },
    SpecialCharacterMsg: null,
    Cflag: null,
    categoryRowSelection: 'multiple',
    iscategoryRowSelected: false,
    UpdatedAllGridData: null,
    ShowCatDeleteIcons: false,
    SeletedCategoryList: null,
    IsCatDeleteAll: false,
    UpdatedCategoryGridData: null,
    catListdata: [],
    optionListValues: [],
    categoryNameCount: 15,

  }
  componentDidMount() {
    this.GetKnowledgeIntentData();
    this.ClearKnowledgeIntentState();
  }
  componentDidUpdate(prevprops) {
    if (this.state.gridModel1 == false) {
      this.state.gridCol1 = null
      this.state.gridCol2 = null
      this.state.gridCol3 = null
      this.gridCol4 = null
      this.state.gridAltQue = []
      this.state.gridAltQueCount = 0
    }
    if (this.state.gridCol3) {
      this.altque = 0
    }
    if (this.state.KnowledgeTabSelected == "Review") {
      this.state.catSelected = 'common';
      this.state.emptycatSelectedMsg = null;
    }
    if (this.state.prvProj != localStorage.getItem("projectname")) {
      this.state.selectedCategory = null;
      this.state.catSelected = 'common';
      this.state.prvProj = localStorage.getItem("projectname");
    }
    if (this.props.updateKnowledgeIntentLoading && this.props.updateKnowledgeIntentLoading !== prevprops.updateKnowledgeIntentLoading) {
      this.ClearKnowledgeIntentState();
      this.setState({ knoledgeRows: [{}] });
      this.setState({
        knoledgeReLearnRows: [{}],
        UpdatedKnowledgeGridData: null
      });
    }

    if (this.props.pageload !== prevprops.prevprops && this.props.pageload === true) {
      this.ClearKnowledgeIntentState();
      this.setState({ knoledgeRows: [{}] });
      this.setState({ knoledgeReLearnRows: [{}] });
      this.setState({ KnowledgeTabSelected: "Create" });
      this.setState({ KnowledgeMacroExpand: true });
      this.setState({ SeletedKnowledgeIntents: [] })
      this.GetKnowledgeIntentData();

    }
    if (this.props.importKnowledgeIntent && this.props.importKnowledgeIntent !== prevprops.importKnowledgeIntent) {
      this.ClearKnowledgeIntentState();
      this.setState({ SeletedKnowledgeIntents: [] })
      this.GetKnowledgeIntentData();

    }
    if (this.props.knowledgePattern && this.props.knowledgePattern !== prevprops.knowledgePattern) {
      this.ClearKnowledgeIntentState();
      setTimeout(() => {
        this.setState({ SeletedKnowledgeIntents: [] })
        this.GetKnowledgeIntentData();
      }, 2000);
    }

    if (this.state.selectedCategory != null && this.props.delknowledgeIntentInprogress !== prevprops.delknowledgeIntentInprogress && prevprops.delknowledgeIntentInprogress == true) {
      this.handleClose('deleteKnowledgeIntent');
      this.ClearKnowledgeIntentState();
      setTimeout(() => {
        this.setState({ IsKnowledgeRowSelected: false });
        this.GetKnowledgeIntentData();
      }, 1000);
    }

    if (this.props.uploadloading !== prevprops.uploadloading && !this.props.uploadloading) {
      if (this.props.knowledgeUploadfailure == '') {
        this.setState({ showFiles: true, catSelected: 'common' });
        this.props.handleClose("Synonyms & Acronyms", true);
      }
      else if (this.props.knowledgeUpload == '') {
        this.setState({ showFiles: false, failure: true, catSelected: this.state.catSelected });
      }
    }
    if (this.props.catList && this.props.catList !== prevprops.catList) {
      let categorySearchResultArray = []
      let filterCommonExists = this.props.catList.some((item) => item.category == 'common');

      if (filterCommonExists) {
        let filterCommon = this.props.catList.filter((item) => item.category == 'common');
        let elemCommon = new Object({ value: filterCommon[0].category, displayValue: filterCommon[0].category })
        categorySearchResultArray.push(elemCommon)
      } else {
        let elemSelect = new Object({ value: null, displayValue: 'select' })
        categorySearchResultArray.push(elemSelect)
      }
      let options = []
      options.push("All Categories")
      for (var { category: c } of this.props.catList) {
        var elem = new Object({ value: c, displayValue: c });
        categorySearchResultArray.push(elem);
        options.push(c)

      }
      const ids = categorySearchResultArray.map(o => o.value)
      const arrayWithNoDuplicates = categorySearchResultArray.filter(({ value }, index) => !ids.includes(value, index + 1))
      let optionsWithNoDuplicates = Array.from(new Set(options))
      this.setState({
        catListdata: arrayWithNoDuplicates,
        optionListValues: optionsWithNoDuplicates
      });

    }
    if (this.state.fileExistFlag && this.props.knowledgeUpload && typeof this.props.knowledgeUpload.data !== 'undefined' && this.props.knowledgeUpload.data.includes('Do you wish to override ?')) {
      if (this.state.catSelected) {
        this.state.existingcatSelected = this.state.catSelected
        this.state.existingFilesNames = this.props.knowledgeUpload.existingFiles
        this.state.existingFilesErrorMsg = this.props.knowledgeUpload.data
        this.state.fileUploadCatModal = true
      }
    }

  }
  close = () => {
    this.props.handleClose("Synonyms & Acronyms")
    this.handleClose("addCategory")

  }
  handleClose = (modaltype) => {
    this.state.SpecialCharacterMsg = null;
    this.state.Cflag = null;
    if (modaltype == "deleteKnowledgeIntent") {
      this.setState({ deleteknowledgeIntentShow: false })
    }
    else if (modaltype == "custommacro") {
      this.setState({ custommacroShow: false })
    }
    else if (modaltype == "displayInfo") {
      this.setState({ flowValue: false })
      this.setState({ displayInfoShow: false })
    }
    else if (modaltype == "displayInfo1") {
      this.setState({ displayInfoShow1: false })
    }
    else if (modaltype == "useContent") {
      this.setState({ displayUseContent: false })
    }
    else if (modaltype == "gridModelClose") {
      this.altque = 0
      this.setState({ gridModel1: false, gridAltQue: [], gridAltQueFlag: false })
    }

    else if (modaltype == "addCategory") {
      this.setState({
        openModal: false,
        dialogValue: {
          title: '',
        },
        ShowCatDeleteIcons: false,
        dailogErrorStatus: false,
        dailogError: '',
        categoryNameCount: 15,
      })
      categoryTouched = false
    }
    else if (modaltype == "deleteCategory") {
      this.setState({ deleteCatModal: false, })
    }
    else if (modaltype == "fileUploadAlreadyExit") {
      this.setState({ fileUploadCatModal: false, })
      this.state.catSelected = 'common'
      this.state.existingcatSelected = null
      this.state.files = null
      this.state.fileExistFlag = null
    }
  }
  GetKnowledgeIntentData = () => {
    let projectName = localStorage.getItem("projectname");
    let language = localStorage.getItem("language");
    if (!language) {
      language = "en";
    }
    var requestObject = { "projectName": projectName, "language": language };
    this.props.onGetKnowledgeIntent(requestObject, this.props.config.BOTBUILDER_URL);
    this.props.OnGetBotList(this.props.config.BOTBUILDER_URL);
    this.props.onGetCategoryList(this.props.config.BOTBUILDER_URL);

  }
  handleAddKnowledgeRow = () => {
    const item = {
      name: "",
    };
    this.setState({
      knoledgeRows: [...this.state.knoledgeRows, item]
    });
  };
  handleKnowledgePatterns = idx => e => {
    const { value } = e.target;
    const rows = [...this.state.knoledgeRows];
    rows[idx] = {
      "name": value
    };
    this.setState({
      knoledgeRows: rows
    });
  };

  handleRemoveKnowledgeSpecificRow = (idx) => () => {
    const rows = [...this.state.knoledgeRows]
    rows.splice(idx, 1)
    this.setState({ knoledgeRows: rows })
  }


  ShowCategoryModal = () => {
    this.setState({ openModal: true });
    this.props.onGetCategoryList(this.props.config.BOTBUILDER_URL);
    this.state.SpecialCharacterMsg = null;
    this.state.Cflag = null;
  }
  ShowDeleteCategoryModal = () => {
    this.setState({ deleteCatModal: true })
  }

  setDialogValue = (event) => {
    let value = event.target.value
    if (
      value.length !== 0 &&
      value !== "" &&
      value !== undefined &&
      value !== null
    ) {
      categoryTouched = true;
    } else {
      categoryTouched = false;
    }
    let msg = ''
    if (!value) {
      msg = 'Please add category name.';
      this.setState({
        dailogErrorStatus: true,
      })
      // } else if ((/^[0-9]+$/i.test(value))) {
    } else if (!(/^[a-zA-Z0-9]+$/).test(value)) {
      msg = 'category name should consist of alphabets.';
      this.setState({
        dailogErrorStatus: true,
      })
    } else if (value.length > 25) {
      msg = 'category name should not be more than 25 chars';
      this.setState({
        dailogErrorStatus: true,
      })
    } else {
      this.setState({
        dailogErrorStatus: false,
      })
      msg = ''
    }
    this.setState({
      isEmptyCatName: !event.target.value,
      dailogError: msg,
      dialogValue: {
        title: event.target.value,
      },
      categoryNameCount: 15 - value.length,
    })

  }


  onCategoryGridRowClicked = (event) => {
    let data = event.data;
    if (data && data != undefined) {
      this.setState({
        iscategoryRowSelected: true
      })
    }
  }
  onCategorycellEditingStart = (event) => {
    this.state.CategoryOldVal = event.data.title;

  }
  onCategorycellEditingStopped = (event) => {
    let data = event.data;

    let UpdatedKnowledgeGridData = this.state.UpdatedCategoryGridData;
    if (UpdatedKnowledgeGridData && UpdatedKnowledgeGridData.length > 0 && UpdatedKnowledgeGridData.find(x => x.id == data.id)) {
      const index = UpdatedKnowledgeGridData.findIndex(x => x.id == data.id);
      UpdatedKnowledgeGridData[index] = data;
      this.setState({ UpdatedCategoryGridData: UpdatedKnowledgeGridData });
    }
    else {
      let UpdatedKnowledgeGridData = this.state.UpdatedCategoryGridData;
      if (!(UpdatedKnowledgeGridData && UpdatedKnowledgeGridData.length > 0))
        UpdatedKnowledgeGridData = [];
      UpdatedKnowledgeGridData.push(data);
      this.setState({ UpdatedCategoryGridData: UpdatedKnowledgeGridData });
    }
  }
  OnCategoryGridRowSelection = () => {
    let totalrecords = 0;
    let selectedrows = this.gridApi.getSelectedRows();
    if (Category) {
      let data = Category
      totalrecords = data && data.length > 0 ? data.length : 0;
    }
    if (selectedrows.length > 0) {
      this.state.delCategory = selectedrows
      this.setState({ ShowCatDeleteIcons: true });
    }
    else {
      this.setState({ ShowCatDeleteIcons: false, SeletedCategoryList: null });
    }

    // check if delete all records 
    if (selectedrows.length > 0 && totalrecords == selectedrows.length) {
      this.setState({ IsCatDeleteAll: true })
    }
    else {
      this.setState({ IsCatDeleteAll: false })
    }
  }



  ClearKnowledgeIntentState = () => {
    this.setState({ KnowledgeFilevalidation: null });

    this.setState({
      knowledgeFile: null,
      knowledgeImportFile: null,
      knoledgeRows: [{}],
      knoledgeReLearnRows: [{}],

    });


    this.setState({
      KnowledgeIntentcontrolsDropdown2: {
        ...this.state.KnowledgeIntentcontrolsDropdown2,
        flowName: {
          ...this.state.KnowledgeIntentcontrolsDropdown2.flowName,
          value: ''
        },
        flowDesc: {
          ...this.state.KnowledgeIntentcontrolsDropdown2.flowDesc,
          value: ''
        }
      },
      KnowledgeDropdown: {
        ...this.state.KnowledgeDropdown,
      }
    })

    this.setState({
      KnowledgeIntentcontrolsDropdown4: {
        ...this.state.KnowledgeIntentcontrolsDropdown4,
        KnowledgeSource: '',
        url: ''
      },
      KnowledgeDropdown: {
        ...this.state.KnowledgeDropdown,
      }
    })


    const updatedControls = {
      ...this.state.KnowledgeIntentcontrols,

      flowDesc: {
        ...this.state.KnowledgeIntentcontrols.flowDesc,
        value: '',
      },
      response: {
        ...this.state.KnowledgeIntentcontrols.response,
        value: '',
      },

    };

    this.setState({ KnowledgeIntentcontrols: updatedControls, });


  }



  updateKnowledgeIntent = (event) => {
    //abhas
    event.preventDefault();
    let learn = this.state.gridCol1 + "\n";
    for (let x in this.state.gridAltQue) {
      learn += this.state.gridAltQue[x] + "\n";
    }
    this.altque = 0
    this.setState({ gridAltQueFlag: false })
    this.setState({ gridAltQue: [] })
    console.log("learn --", learn);
    let language = localStorage.getItem("language");
    if (!language) {
      language = "en";
    }
    this.state.updateKnowledgeData = [{
      "projectName": localStorage.getItem("projectname"), "language": language,
      "data": JSON.stringify({
        "id": this.state.rowdata.id, "description": this.state.gridCol1, "response": this.state.gridCol2, "training": { "learn": learn, "relearn": this.state.rowdata.relearn },
        "isfaq": "faq", "intent": this.state.rowdata.intent, "createdate": this.getCurrentDate()
      }),
      "collection": "optimus",
      "project": localStorage.getItem("projectname"),
      "documentId": this.state.rowdata.recordId,
      "orgName": localStorage.getItem("orgName")
    }]
    this.setState({ gridModel1: false })
    this.props.onUpdateAllKnowledgeIntent(this.state.updateKnowledgeData, this.props.config.BOTBUILDER_URL, false);

    setTimeout(() => {
      this.setState({ selectedCategory: this.state.selectedCategory })
      this.GetKnowledgeIntentData();
    }, 2000);
  }
  updateAllKnowledgeIntent = () => {
    //old
    let unProperData = [];
    let bulkUpdateArr = [];
    const self = this;
    const UpdatedKnowledgeGridData = this.state.UpdatedKnowledgeGridData;
    // Some change happen in grid data
    if (UpdatedKnowledgeGridData && UpdatedKnowledgeGridData.length > 0) {
      const projectname = localStorage.getItem("projectname");
      let language = localStorage.getItem("language");
      if (!language) {
        language = "en";
      }
      UpdatedKnowledgeGridData.forEach(function (knowledge) {
        if (knowledge) {
          if (knowledge.description && knowledge.description.trim() && knowledge.response && knowledge.response.trim()) {
            let knowledgeData = {
              "projectName": projectname, "language": language,
              "data": JSON.stringify({
                "id": knowledge.id, "description": knowledge.description, "response": knowledge.response, "training": { "learn": knowledge.learn, "relearn": knowledge.relearn },
                "isfaq": "faq", "intent": knowledge.intent, "createdate": self.getCurrentDate()
              }),
              "collection": "optimus",
              "project": projectname,
              "documentId": knowledge.id,
              "orgName": localStorage.getItem("orgName")
            }
            bulkUpdateArr.push(knowledgeData);
          }
          else {
            unProperData.push(knowledge);
          }

        }
      });

      if (unProperData && unProperData.length > 0) {
        // Generate error message and refresh grid with old data
        this.props.OnHandleImportError("Question and Answer is mandatory, unable to update Knowledge intents");
        setTimeout(() => {
          this.GetKnowledgeIntentData();
        }, 1000);
        this.setState({ UpdatedKnowledgeGridData: null });

      }
      else {
        // Multiple update api call
        //update call              
        this.props.onUpdateAllKnowledgeIntent(bulkUpdateArr, this.props.config.API_URL, false);
      }
    }
  }
  oncellEditingStopped = (event) => {
    let data = event.data;

    let UpdatedKnowledgeGridData = this.state.UpdatedKnowledgeGridData;
    if (UpdatedKnowledgeGridData && UpdatedKnowledgeGridData.length > 0 && UpdatedKnowledgeGridData.find(x => x.id == data.id)) {
      const index = UpdatedKnowledgeGridData.findIndex(x => x.id == data.id);
      UpdatedKnowledgeGridData[index] = data;
      this.setState({ UpdatedKnowledgeGridData: UpdatedKnowledgeGridData });
    }
    else {
      let UpdatedKnowledgeGridData = this.state.UpdatedKnowledgeGridData;
      if (!(UpdatedKnowledgeGridData && UpdatedKnowledgeGridData.length > 0))
        UpdatedKnowledgeGridData = [];
      UpdatedKnowledgeGridData.push(data);
      this.setState({ UpdatedKnowledgeGridData: UpdatedKnowledgeGridData });
    }
  }

  downloadTemplate = () => {
    const apiURL = this.props.config.API_URL;
    this.props.onDownloadTemplate(apiURL);
  }

  onGridReady = params => {
    this.gridApi = params.api;
  };

  handleImportKnoledgeIntents = (e) => {
    this.setState({
      KnowledgeIntentImportFileName: e.target.value,
      KnowledgeIntentImportFile: e.target.files[0]
    }, function () {
      if (e.target.value) {
        const fileName = e.target.value.split(`\\`)[e.target.value.split(`\\`).length - 1];
        this.setState({
          KnowledgeIntentcontrolsDropdown2: {
            ...this.state.KnowledgeIntentcontrolsDropdown2,
            flowName: {
              ...this.state.KnowledgeIntentcontrolsDropdown2.flowName,
              value: fileName
            }
          }
        })
      }
    });

  }


  IsValidColumnsforKnowledgeIntentImport = (fileColumns) => {
    let fileColumnsArr = [];
    for (let key in fileColumns) {
      fileColumnsArr.push(key.trim().toLocaleLowerCase());
    }

    if (fileColumnsArr.find(x => x == "uniqueid") &&
      fileColumnsArr.find(x => x == "description") &&
      fileColumnsArr.find(x => x == "response") &&
      fileColumnsArr.find(x => x == "learn") &&
      fileColumnsArr.find(x => x == "intent")) {
      return true;
    }
    else
      return false;
  }


  ESBulkImport = (resp, boolval, importType) => {
    resp = this.ValidateScript(resp)
    let input = '{"bulkQuery":' + JSON.stringify(resp) + '}'
    if (importType == "ConnectData") {
      this.props.OnKnowledgeIntentImport(this.props.config.API_URL, input, "ConnectData");
      this.setState({
        ImportConnectDataFileName: null,
        ImportConnectDataFile: null
      });
    }

  }

  ValidateScript = (input) => {
    var reg = /<script\b[^>]*>([\s\S]*?)<\/script>/gm;
    if (reg.test(input) == true) {
      return input.replaceAll("<script>", "").replaceAll("</script>", "");
    }
    else {
      return input;
    }
  }
  to_json = (workbook) => {
    var result = {};
    workbook.SheetNames.forEach(function (sheetName) {
      var roa = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
      if (roa.length) result[sheetName] = roa;
    });
    return JSON.stringify(result, 2, 2);
  };


  getCurrentDate = () => {
    var currentdate = new Date();
    var dd = currentdate.getDate()
    var mm = (currentdate.getMonth() + 1)
    var yyyy = currentdate.getFullYear();
    var hh = currentdate.getHours()
    var ms = currentdate.getMinutes()
    var sc = currentdate.getSeconds();

    if (dd < 10)
      dd = '0' + dd;

    if (mm < 10)
      mm = '0' + mm;

    if (hh < 10)
      hh = '0' + hh;

    if (ms < 10)
      ms = '0' + ms;

    if (sc < 10)
      sc = '0' + sc;

    var currentdate = yyyy + '/' + mm + '/' + dd + ' ' + hh + ':' + ms + ':' + sc;
    return currentdate;
  }

  ImportKnowledgeIntents = () => {
    if (this.state.KnowledgeIntentImportFile && this.state.KnowledgeIntentImportFile != null) {
      const csv = this.state.KnowledgeIntentImportFileName;
      const filename = (this.state.KnowledgeIntentImportFile.name).split(".")[0];
      const regex = /^([a-zA-Z0-9\s_()\\.\-:])+(.csv|.txt|.xlsx)$/
      const project = localStorage.getItem("projectname");
      let self = this;
      if (regex.test(csv.toLowerCase())) {
        if (typeof (FileReader) != "undefined") {
          let reader = new FileReader();
          reader.onload = function (e) {
            let data = e.target.result;
            let csvjson = self.to_json(XLSX.read(data, { type: 'binary' }));
            let parseData = JSON.parse(csvjson);
            let rows = (parseData.hasOwnProperty("Sheet1")) ? parseData["Sheet1"] : parseData[filename]
            if (rows.length > 0) {
              let resp = "";
              let first_Row_Cells = rows[0]; //Taking Headings  
              if (Object.keys(first_Row_Cells).length == 5 && self.IsValidColumnsforKnowledgeIntentImport(first_Row_Cells)) {

                for (let i = 0; i <= rows.length - 1; i++) {
                  let cells = rows[i]//splitCSVtoCells(rows[i], "@@@");
                  let indexobj = {};
                  let obj = {};
                  let id = ""
                  let unqstatus = 0;

                  let isfaq = 0;

                  for (let key in cells) {
                    key = key.trim()

                    if (key.toLowerCase() == "uniqueid") {
                      if (cells[key] != "" && cells[key] != undefined && cells[key] != null && unqstatus == 0) {
                        let uniqueid = cells[key].replace(/"/g, "")
                        id = uniqueid
                        unqstatus = 1;
                      }
                    }
                    if (key.toLowerCase() == "intent") {
                      if (cells[key] != "" && cells[key] != undefined && cells[key] != null) {
                        let newString = cells[key].replace(/[^A-Z0-9_]+/ig, "");
                        if (unqstatus == 0) {
                          id = newString;
                        }
                        obj[key] = newString;
                      }
                      obj["createdate"] = self.getCurrentDate();
                      obj["isfaq"] = "faq";

                    }
                    else {
                      if (key.toLowerCase() == "isfaq") { obj["isfaq"] = "faq"; isfaq = 1; obj["createdate"] = self.getCurrentDate(); }
                      else if (key.toLowerCase() != "uniqueid") {
                        if (cells[key].toLowerCase != "" && cells[key] != undefined && cells[key] != null) {
                          if (key.toLowerCase() == "learn" || key.toLowerCase() == "relearn") {
                            if (!obj.hasOwnProperty("training")) {
                              obj["training"] = {}
                            }
                            obj["training"][key] = typeof (cells[key]) == "string" ? cells[key].replace(/"/g, "'") : cells[key]

                          }
                          else {
                            obj[key] = cells[key]
                          }

                        }
                        else {
                          obj[key] = null
                        }
                      }
                    }

                  }
                  if (id != "") {
                    indexobj["index"] = { "_index": "optimus", "_type": project, "_id": id }

                    resp += JSON.stringify(indexobj)
                    resp += "\n"
                    resp += JSON.stringify(obj) + "\n"
                  }
                }
                self.ESBulkImport(resp, false, "KnowledgeIntent");
              }
              else {
                self.props.OnHandleImportError("Select a valid File, as per sample download file.");
                return;
              }
            }
          }
          reader.readAsBinaryString(this.state.KnowledgeIntentImportFile);
        }

      } else {
        this.props.OnHandleImportError("Select a valid CSV & XLSX File.");
      }
    }

  }

  uploadExistingFile(isValidate) {
    let projectname = localStorage.getItem("projectname");
    if (!projectname)
      projectname = "common";
    let fileList = []
    let formData = new FormData();
    let ids = []
    this.state.files.map((item) => {
      fileList.push(item.name)
      formData.append('files', item)
    })
    formData.append('projectName', projectname);
    formData.append('categoryName', this.props.knowledgeUpload.category);
    formData.append('validate', isValidate)
    formData.append('language', localStorage.getItem("language"))


    let data = { "projectName": projectname, "intent": this.state.catSelected, "filename": fileList, "ids": ids, "isValidate": isValidate }
    this.props.onSaveKnowledgeIntentWithFile(this.props.config.BOTBUILDER_URL, formData, data)
    this.state.fileUploadCatModal = false
    this.state.existingcatSelected = null
    this.state.fileExistFlag = null
    this.state.files = null
  }

  removeExistingFilesandUpload() {

    var existingFilesNames = this.state.existingFilesNames;
    this.state.files = this.state.files.filter(function (el) {
      return !existingFilesNames.includes(el.name);
    });

    console.log("this.state.files-----1323--", this.state.files);
    if (this.state.files.length > 0) {
      this.uploadExistingFile(false)
    }
    this.props.resetUploadFile();
    this.setState({
      fileUploadCatModal: false,
      catSelected: 'common',
      existingcatSelected: null,
      files: null,
      fileExistFlag: null,
      existingFilesNames: null
    })
  }
  ImportFAQIntents = (files) => {
    this.state.files = files
    let projectname = localStorage.getItem("projectname");
    if (!projectname)
      projectname = "common";
    let language = localStorage.getItem("language");
    if (!language) {
      language = "en";
    }
    let fileList = []
    let formData = new FormData();
    let ids = []
    let isValidate = false
    files.map((item) => {
      fileList.push(item.name)
      formData.append('files', item)
    })
    formData.append('projectName', projectname);
    formData.append('categoryName', this.state.catSelected);
    formData.append('validate', isValidate)
    formData.append('language', language);

    this.setValidationMessage("catSelected", this.state.catSelected);
    if (this.state.catKey) {
      let data = {
        "projectName": projectname,
        "categoryName": this.state.catSelected,
        "files": fileList,
        "ids": ids,
        "validate": isValidate
      }
      this.state.fileExistFlag = true
      this.props.onSaveKnowledgeIntentWithFile(this.props.config.BOTBUILDER_URL, formData, data)
      this.props.handleClose("Synonyms & Acronyms", true);
    }

  }
  onchangeFile = () => {
    this.setState({ showFiles: false, failure: false })
  }
  ValidateScript = (input) => {
    var reg = /<script\b[^>]*>([\s\S]*?)<\/script>/gm;
    if (reg.test(input) == true) {
      return input.replaceAll("<script>", "").replaceAll("</script>", "");
    }
    else {
      return input;
    }
  }


  ESBulkImport = (resp, boolval, importType) => {
    resp = this.ValidateScript(resp)
    let input = '{"bulkQuery":' + JSON.stringify(resp) + '}'
    if (importType == "KnowledgeIntent") {
      this.props.OnKnowledgeIntentImport(this.props.config.API_URL, input, "KnowledgeIntent");
      this.setState({
        KnowledgeIntentImportFile: null,
        KnowledgeIntentImportFileName: null
      });
    }
    else if (importType == "FAQIntent") {
      this.props.OnKnowledgeIntentImport(this.props.config.API_URL, input, "FAQIntent");
      this.setState({
        FAQImportFile: null,
        FAQImportFileName: null
      });
    }

  }

  cancelKnowledgeHandler = () => {
    this.ClearKnowledgeIntentState();
  }
  checkValidity(value, rules) {
    let isValid = true;
    if (!rules) {
      return true;
    }

    if (rules.required) {
      isValid = value.trim() !== '' && isValid;
    }
    return isValid;
  }


  RemoveKnowledgeReLearnSpecificRow = (idx) => {
    const rows = [...this.state.knoledgeReLearnRows]
    rows.splice(idx, 1)
    this.setState({ knoledgeReLearnRows: rows });
  }

  inputChangedCategoryeHandler = (event) => {
    let catKey = event.target.value;
    if (event.target.value == "Select")
      catKey = null
    this.setState({
      catSelected: event.target.value,
      emptycatSelectedMsg: catKey ? "" : this.state.emptycatSelectedMsg,
      catKey: catKey
    });

  }

  inputChangedKnowledgeHandler = (event, controlName) => {
    if (controlName === "selectKnowledgeDropdown") {
      this.setState({
        KnowledgeDropdown: {
          ... this.state.KnowledgeDropdown,
          value: event.target.value
        },
        emptyFlowNameMsg: null,
        emptyResponseMsg: null,
        emptycatSelectedMsg: null
      });
      if (event.target.value == "Upload FAQ document") {
        this.setState({
          showFiles: false,
          catSelected: 'common'
        })
      }
    }
    else {
      var f = 0
      if (this.props.knowledgeIntents && controlName === "flowDesc") {
        for (var i = 0; i < this.props.knowledgeIntents.length; i++) {
          if (this.props.knowledgeIntents[i].description.toLowerCase() == event.target.value.toLowerCase()) {
            f = 1;
            i = this.props.knowledgeIntents.length
          }
        }
      }
      const updatedControls = {
        ...this.state.KnowledgeIntentcontrols,
        [controlName]: {
          ...this.state.KnowledgeIntentcontrols[controlName],
          value: event.target.value,
          defaultFocus: false,
          valid: this.checkValidity(event.target.value, this.state.KnowledgeIntentcontrols[controlName].validation)
        }
      };

      this.setState({ KnowledgeIntentcontrols: updatedControls, });
      if (f == 0)
        this.setValidationMessage(controlName, event.target.value);
      else
        this.setValidationMessage("existsQ", '');
      f = 0
    }
  }


  inputChangedFileHandler = (event, controlName) => {
    const updatedControls = {
      ...this.state.KnowledgeIntentcontrolsDropdown2,
      [controlName]: {
        ...this.state.KnowledgeIntentcontrolsDropdown2,
        value: event.target.value,
        defaultFocus: false,
        valid: this.checkValidity(event.target.value, this.state.KnowledgeIntentcontrolsDropdown2[controlName].validation)
      }
    }
    this.setState({ KnowledgeIntentcontrolsDropdown2: updatedControls, });
  }

  saveMacroFileData = (event) => {
    event.preventDefault();
    let projectname = localStorage.getItem("projectname");
    if (!projectname) {
      projectname = "common";
    }
    let language = localStorage.getItem("language");
    if (!language) {
      language = "en";
    }
    let data = {
      "path": this.state.currentBotSelected + '//' + this.state.currentFileSelected,
      "data": this.botEditor.editor.getValue(),
      "project": projectname,
      "language": language
    }
    let data1 = { projectName: projectname, language: localStorage.getItem("language"), orgName: "default", collection: "projectdata_collection", }
    this.props.onsaveMacroFileData(this.props.config.API_URL, data);
    this.props.onGetMacroList(this.props.config.API_URL, data1);
    this.handleClose('custommacro');
  }


  setValidationMessage = (type, value) => {
    if (type === "intentName") {
      if (value.trim() === '') {

        this.setState({ emptyIntentNameMsg: (<ValidationMsg>Intent Name is required</ValidationMsg>) });
      }
      else if (!(/^[A-Z0-9./_]+$/i.test(value))) {
        this.setState({ emptyIntentNameMsg: (<ValidationMsg>Intent name should be alphanumeric</ValidationMsg>) })
      }
      else {
        this.setState({ emptyIntentNameMsg: null });
      }
    }
    else if (type === "flowDesc") {
      value.trim() === ''
        ? this.setState({ emptyFlowNameMsg: 'Question is required' }) :
        this.setState({ emptyFlowNameMsg: null });
    }
    else if (type === "existsQ") {
      this.setState({ emptyFlowNameMsg: 'Question already exists' })
    }
    else if (type === "faqName") {
      value.trim() === ''
        ? this.setState({ emptyfaqNameMsg: (<ValidationMsg>FAQ Name is required</ValidationMsg>) }) :
        this.setState({ emptyfaqNameMsg: null });
    } else if (type === "catSelected") {

      (value === null || value === 'Select') ? this.setState({ emptycatSelectedMsg: 'Category Name is required' }) :
        this.setState({ emptycatSelectedMsg: null });
    }
    else if (type === "response") {
      value.trim() === ''
        ? this.setState({ emptyResponseMsg: 'Response is required' }) :
        this.setState({ emptyResponseMsg: null });

    }
    else if (type == "SC") {
      this.setState({ SpecialCharacterMsg: (<ValidationMsg>Special Characters are not Allowed</ValidationMsg>) })

    }
    else if (type == "BS") {
      this.setState({ SpecialCharacterMsg: (<ValidationMsg>Blank Spaces are not Allowed</ValidationMsg>) })
    }
    else if (type == "space") {
      this.setState({ SpecialCharacterMsg: (<ValidationMsg>No Space Allowed In Category Name</ValidationMsg>) })
    }

  }

  saveCategory = (event, catname) => {

    event.preventDefault()
    catname = catname.trim()
    if (!catname) {
      this.setState({
        isEmptyCatName: true
      })
    }
    let f = 1
    this.state.SpecialCharacterMsg = null;
    this.state.Cflag = null;
    var regex = /^[A-Za-z0-9 ]+$/
    if (catname.length == 0 && this.state.CategoryOldVal !== null) {
      let unProperData = [];
      let bulkUpdateArr = [];

      const UpdatedCategoryGridData = this.state.UpdatedCategoryGridData;

      let categoryData;
      let oldVal = this.state.CategoryOldVal
      this.state.CategoryOldVal = null
      UpdatedCategoryGridData.forEach(function (category) {
        (category.title && !category.title.trim() == "") ? f = 1 : f = 0
        if (category) {
          if (category.title && category.title.trim()) {
            categoryData = {
              "id": category.id,
              "categoryOldVal": oldVal,
              "categoryNewVal": category.title.trim(),
              "projectName": localStorage.getItem("projectname")
            }

            bulkUpdateArr.push(categoryData);
          }
          else {
            unProperData.push(category);
          }
        }
      });

      if (f == 1 && /\s/.test(categoryData.categoryNewVal)) {
        this.setValidationMessage("space");
        this.setState({ openModal: false });
      }
      else if (f == 1 && regex.test(categoryData.categoryNewVal)) {

        this.props.onupdateCategory(this.props.config.API_URL, categoryData);
        this.setState({ ShowCatDeleteIcons: false })
        this.setState({ openModal: false })
        this.state.UpdatedCategoryGridData = null
      }
      else if (f == 1 && !regex.test(categoryData.categoryNewVal)) {


        this.setState({ openModal: false });
        this.setValidationMessage("SC");
      }
      else {

        this.setState({ openModal: false });
        this.setValidationMessage("BS");
      }

    }
    else if (catname.length !== 0) {
      if (/\s/.test(catname)) {
        this.setValidationMessage("space");
      }
      else if (regex.test(catname)) {

        this.props.onsaveCategory(this.props.config.BOTBUILDER_URL, catname);
        this.state.dialogValue.title = "";
        this.setState({
          ShowCatDeleteIcons: false,
          openModal: false
        })
        this.state.Cflag = "save";
        this.handleClose('addCategory')
      }
      else {
        this.setValidationMessage("SC");
      }
    }
    else {
      this.setValidationMessage("BS");
    }
  }

  submitCategory = (event) => {
    event.preventDefault()
  }
  deleteCategory = (event) => {
    event.preventDefault()
  }

  handleDeleteCategory = (event) => {
    event.preventDefault()
    this.props.ondelCategory(this.props.config.BOTBUILDER_URL, this.state.delCategory);
    this.setState({ deleteCatModal: false })

  }
  closePopup = () => {
    this.props.handleClose("Synonyms & Acronyms")
    this.setState({
      emptycatSelectedMsg: null,
      catSelected: 'common',
      catKey: 'common',
      emptyFlowNameMsg: null,
      emptyResponseMsg: null,
      KnowledgeDropdown: {
        ...this.state.KnowledgeDropdown,
        value: 'Upload FAQ document'
      }
    })
  }

  submitKnowledgeHandler = (event) => {
    event.preventDefault();
    const { KnowledgeIntentcontrols, knoledgeRows, knoledgeReLearnRows, catKey } = this.state;
    let projectname = localStorage.getItem("projectname");
    if (!projectname) {
      projectname = "common";
    }
    let language = localStorage.getItem("language");
    if (!language) {
      language = "en";
    }
    var learn = ""
    let reLearn = "";
    if (knoledgeRows && knoledgeRows != []) {

      knoledgeRows.forEach(function (row) {
        if (row && row.name)
          learn = learn + row.name + "\n"
      })
    }

    if (knoledgeReLearnRows && knoledgeReLearnRows != []) {
      knoledgeReLearnRows.forEach(function (row) {
        if (row && row.name)
          reLearn = reLearn + row.name + "\n"
      })

    }
    this.setValidationMessage("catSelected", catKey)
    this.setValidationMessage("flowDesc", KnowledgeIntentcontrols.flowDesc.value);
    this.setValidationMessage("response", KnowledgeIntentcontrols.response.value);
    if (KnowledgeIntentcontrols.flowDesc.value && KnowledgeIntentcontrols.response.value && catKey) {
      let flowDesc = KnowledgeIntentcontrols.flowDesc.value;
      let response = KnowledgeIntentcontrols.response.value;
      let id = KnowledgeIntentcontrols.id.value;
      if (this.state.knowledgeImportFile) {
        if (this.state.KnowledgeTabSelected === "Create") {
          let formData = new FormData();

          formData.append('files', this.state.knowledgeImportFile);
          formData.append('intentdescription', response);
          formData.append('projectName', projectname);
          formData.append('categoryName', '');
          formData.append('language', language);
          formData.append('uploadType', '');
          formData.append('importdir', '');
          this.props.onSaveKnowledgeIntentWithFile(this.props.config.BOTBUILDER_URL, formData)
          this.props.handleClose("Synonyms & Acronyms", true);
        }
      }
      else {

        if (this.state.KnowledgeTabSelected === "Create") {
          let knowledgeData = {
            "description": flowDesc,
            "categoryName": this.state.catSelected,
            "training": { "learn": flowDesc + "\n" + learn },
            "response": response,
            "isfaq": "faq",
            "intent": CreateUUID(),
            "project": projectname,
            "createdate": this.props.getCurrentDate(),
            "language": language,
          }
          this.setValidationMessage("catSelected", this.state.catSelected);
          if (this.state.catSelected) {
            this.props.onSaveKnowledgeIntent(knowledgeData, this.props.config.BOTBUILDER_URL, true);
          }
          this.props.handleClose("Synonyms & Acronyms", true);
        }
        else {
          //update call
          let knowledgeData = {
            "projectName": projectname, "language": language,
            "data": JSON.stringify({
              "id": id, "description": flowDesc, "response": response, "training": { "learn": learn, "relearn": reLearn },
              "isfaq": "faq", "intent": CreateUUID(), "createdate": this.props.getCurrentDate()
            }),
            "collection": "optimus",
            "project": projectname,
            "documentId": id
          }
          this.setValidationMessage("catSelected", this.state.catSelected);
          if (this.state.catSelected) {
            this.props.onSaveKnowledgeIntent(knowledgeData, this.props.config.API_URL, false);
          }
          this.props.handleClose("Synonyms & Acronyms", true);
        }
      }
    }
    if (this.state.catSelected) {
      this.setState({
        KnowledgeIntentcontrols: {
          id: {
            value: '',
          },
          flowDesc: {
            elementType: 'input',
            elementConfig: {
              type: 'text',
              placeholder: ''
            },
            value: '',
            className: '',
            readOnly: false,
          }
          ,
          response: {
            elementType: 'input',
            elementConfig: {
              type: 'text',
              placeholder: ''
            },
            value: '',
            className: '',
            readOnly: false,
          }
        },
        knoledgeRows: [{}],
        knoledgeReLearnRows: [{}]
      })
    }
  }

  componentWillUnmount() {
    categoryTouched = false;
  }


  render() {
    let CatNameCharString = categoryTouched ? "Left" : "";

    let loadingImg = null;
    if (this.props.loading) {
      loadingImg = (<SpinnerPage />)
    }

    let uploadloadingImg = null;
    if (this.props.uploadloading) {
      uploadloadingImg = (<SpinnerPage />)
    }


    if (this.props.knowledgeIntents) {
      let data = []
      if (this.state.selectedCategory == 'All Categories') {
        data = this.props.knowledgeIntents;
      }
      else if (this.state.selectedCategory != 'All Categories') {
        data = this.props.knowledgeIntents.filter(item => item.categoryName == this.state.selectedCategory);
      }
      data = data && data !== undefined ? data : null;
      if (data && data.length > 0) {
        for (var i = 0; i < data.length; i++) {
          //Learn data push
          if (data[i].training && data[i].training.learn) {
            if (data[i].training.learn) {
              let dataRow = "";
              var learnData = data[i].training.learn;
              var desc = data[i].description;
              const rows = learnData.split("\n");
              if (rows[0] == desc) {
                rows.shift();
              }
              rows.forEach(function (row) {
                if (row && row.trim() !== "" && row.trim() !== "undefined")
                  dataRow += row + "\n";
              })
              data[i].learn = dataRow;
            }
          }
          else {
            data[i].learn = "";
          }

          // Re Learn rows
          if (data[i] && data[i].training && data[i].training.relearn) {
            data[i].relearn = data[i].training.relearn
          }
          else {
            data[i].relearn = "";
          }
          data[i].id = data[i]._id;
        }

      }
    }

    const knowledgeIntentformElementsArray = [];
    for (let key in this.state.KnowledgeIntentcontrols) {
      let labelName = '';
      let error = '';
      if (key === 'flowName') {
        labelName = "FAQ Name*";
      } else if (key === 'flowDesc') {
        labelName = "Question*";
        error = this.state.emptyFlowNameMsg;
      } else if (key === 'response') {
        labelName = "Response*";
        error = this.state.emptyResponseMsg;
      }

      knowledgeIntentformElementsArray.push({
        id: key,
        config: this.state.KnowledgeIntentcontrols[key],
        labelName: labelName,
        error: error
      });
    }

    let knowledgeIntentform = knowledgeIntentformElementsArray.map(formElement => (
      <>
        {formElement.id === "id" ? <>
        </> : formElement.id == "flowDesc" ?
          <InputWithLabelComponent
            type="text"
            name={formElement.id}
            label={formElement.labelName}
            placeHolder=""
            value={formElement.config.value}
            readOnly={formElement.config.readOnly}
            onChange={(event) => this.inputChangedKnowledgeHandler(event, formElement.id)}
            required={true}
            isInvalid={formElement.error}
            error={formElement.error}
            className={classes.questext} /> :
          <TextAreaWithLabelComponent
            name={formElement.id}
            label={formElement.labelName}
            placeHolder=""
            value={formElement.config.value}
            readOnly={formElement.config.readOnly}
            onChange={(event) => this.inputChangedKnowledgeHandler(event, formElement.id)}
            required={true}
            isInvalid={formElement.error}
            error={formElement.error}
            className={classes.textarea}
          />
        }
      </>
    ));


    const knowledgeIntentDrp2formElementsArray = [];
    for (let key in this.state.KnowledgeIntentcontrolsDropdown2) {
      let labelName = ''
      if (key === 'flowName') {
        labelName = (<label className={classes.ProjectTabContentLabel}>Description <b></b></label>);
      }
      else if (key === 'flowDesc') {
        labelName = (<label className={classes.ProjectTabContentLabel}>FAQ Name </label>);
      }
      else if (key === 'file') {
        labelName = (<label className={classes.ProjectTabContentLabel}> </label>);
      }


      knowledgeIntentDrp2formElementsArray.push({
        id: key,
        config: this.state.KnowledgeIntentcontrolsDropdown2[key],
        labelName: labelName
      });
    }

    let knowledgeIntentDrp2form = knowledgeIntentDrp2formElementsArray.map(formElement => (
      <>
        {formElement.id === "id" ? <Col md={6}>
          {this.state.KnowledgeDropdown.value === "Upload Q&A list(excel)" &&
            <div className={classes.DownloadKnolwdgeImg}><label onClick={this.downloadTemplate} style={{ color: "#0080ff" }}>Download template to fill the content</label>
              <img className="projectImage" onClick={this.downloadTemplate} src='./Icons/DownloadTemplate.svg' title="Download Template" />
            </div>
          }
        </Col> :
          <Col md={6} key={formElement.id}>
            <div className={formElement.id !== "file" && "inputContainer"}>
              {formElement.labelName}
              <Input
                id={formElement.id}
                key={formElement.id}
                customclassName={formElement.config.className}
                elementType={formElement.config.elementType}
                elementConfig={formElement.config.elementConfig}
                value={formElement.id === "flowDesc" ? "Template" : formElement.config.value}
                invalid={!formElement.config.valid}
                shouldValidate={formElement.config.validation}
                customStyle={{ float: "right" }}
                touched={formElement.config.touched}
                readOnly={formElement.config.readOnly}
                acceptfileType={formElement.id === "file" ? ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" : ""}
                name={formElement.id === "file" ? "Import Intents" : ""}
                direction={formElement.id === "file" ? "top" : ""}
                tooltext={formElement.id === "file" ? "Import Knowledge Intent csv,xlsx,xls file format are allowed" : ""}
                ButtonType="Button"
                changed={formElement.id === "file" ?
                  (event) => this.handleImportKnoledgeIntents(event, formElement.id)
                  :
                  (event) => this.inputChangedFileHandler(event, formElement.id)
                }
              />
            </div>

          </Col>
        }
      </>
    ));

    let addKnowledgePatternform = this.state.knoledgeRows.map((item, idx) => (
      <div id={classes.patterns} key={idx} className="mb-2">
        <div style={{ border: '1px solid #484545', borderRadius: '5px', position: 'relative', backgroundColor: "#E5E5E5" }}>
          <input type="text"
            className={[classes.topBorder, 'form-control', classes.addKnowledgeInputs].join(' ')}
            name="name" value={this.state.knoledgeRows[idx].name == undefined ? ""
              : this.state.knoledgeRows[idx].name}
            onChange={this.handleKnowledgePatterns(idx)} />
          <span className={classes.deleteBtn}><Image onClick={this.handleRemoveKnowledgeSpecificRow(idx)} src="./Icons/DeleteBlack.svg" /></span>

        </div>
      </div>
    ));

    let addKnowledgeReLearnPatternform = this.state.knoledgeReLearnRows.map((item, idx) => (
      <div key={idx}>
        <InputWithLabelComponent
          type="text"
          name="name"
          placeHolder=""
          value={this.state.knoledgeReLearnRows[idx].name == undefined ? "" : this.state.knoledgeReLearnRows[idx].name}
          readOnly={true}
          error={this.state.emptyFlowNameMsg}
        />
        <span className={classes.deleteBtn}><Image onClick={this.RemoveKnowledgeReLearnSpecificRow(idx)} src="./Icons/DeleteBlack.svg" /></span>
      </div>
    ));

    let KnowledgeSubtabChildContent = null;
    if (this.state.selectedSubKnowledgeTab === "Manual") {
      KnowledgeSubtabChildContent = <>
        <div className="mb-3">
          <div>
            {addKnowledgePatternform}
          </div>
          <Button className={[classes.topSpace, classes["btn-bg-clr"], classes.buttonclr].join(' ')} onClick={this.handleAddKnowledgeRow} block>
            <Image src='./Icons/AddFlow.svg' /> ADD PHRASE
          </Button>
        </div></>
    }
    else if (this.state.selectedSubKnowledgeTab === "Auto Training") {
      KnowledgeSubtabChildContent = <>
        <div>
          <div>
            {addKnowledgeReLearnPatternform}
          </div>
        </div>
      </>
    }

    let knowledgeTabContent = null;
    knowledgeTabContent = <>

      {this.state.KnowledgeTabSelected == "Create" && <>
        <SelectComponent
          name="selectKnowledgeDropdown"
          label="Source"
          changed={
            (event) => this.inputChangedKnowledgeHandler(event, "selectKnowledgeDropdown")
          } required={true}
          value={this.state.KnowledgeDropdown.value}
          options={this.state.KnowledgeDropdown.elementConfig.options}
        />
        <div className="categgory-container">
          <SelectComponent
            name="selectCategoryDropdown"
            label="Category"
            changed={(event) => this.inputChangedCategoryeHandler(event)}
            required={true}
            value={this.state.catSelected}
            options={this.state.catListdata}
          />
          {this.state.emptycatSelectedMsg && <div className="default-css error-css desc-err">
            Please select category.
          </div>}
          <Image src="./Icons/plus-icon.svg" onClick={() => this.ShowCategoryModal()} className={"add-icon"} />
        </div>
      </>
      }

      <div >
        {(this.state.KnowledgeTabSelected === "Create") &&
          <div>
            {(this.state.KnowledgeDropdown.value === "Individual questions") && <>
              <div className="d-flex  flex-column w-100  response-div">
                {knowledgeIntentform}
              </div>
              <div className="mt-2">
                <Form.Label className="mb-1 text-box-title adding-other-input">Alternate Questions</Form.Label>
              </div>
              <div>
                {KnowledgeSubtabChildContent}
              </div>
              <div >
                {loadingImg}
              </div>
              <div>
                <ButtonComponent
                  variant='contained'
                  label='Save'
                  className='buttonWidth mb-2'
                  disabled={!(this.state.catSelected && this.state.KnowledgeIntentcontrols.flowDesc.value && this.state.KnowledgeIntentcontrols.response.value)}
                  clicked={this.submitKnowledgeHandler} />
              </div>
            </>
            }
            {this.state.KnowledgeDropdown.value === "Upload Q&A list(excel)" && <>
              <div >
                {knowledgeIntentDrp2form}
                <div>
                  <div className={classes.BtnAlignment1}>
                    <ButtonComponent
                      variant='contained'
                      label='Save'
                      className='buttonWidth mb-2'
                      clicked={this.ImportKnowledgeIntents} />
                    <ButtonCustom btnType="blueButton"
                      clicked={this.ImportKnowledgeIntents}>Create</ButtonCustom>
                  </div>
                </div>
              </div>

            </>}
            {this.state.KnowledgeDropdown.value === "Upload FAQ document" && <>
              <Dropzone ImportFAQIntents={this.ImportFAQIntents} showFiles={this.state.showFiles}
                failure={this.state.failure} catSelected={this.state.catSelected}
                cancelKnowledgeHandler={this.cancelKnowledgeHandler} onchangeFile={this.onchangeFile} setValidationMessage={this.setValidationMessage}
                uploadloadingImg={uploadloadingImg} knowledgeUpload={this.props.knowledgeUploadfailure} validate="true" />
            </>}
            {this.state.KnowledgeDropdown.value === "*" && <>
              <Dropzone />
            </>}

            {this.state.KnowledgeDropdown.value === "Share Point" && <>
              <SharePointPage
                config={this.props.config}
                isFaq={true}
                categoryName={this.state.catSelected}
                handleClose={this.close}
              />
            </>}
          </div>
        }
      </div>
    </>

    return (<>
      <DrawerComponent heading={this.props.title}
        showDrawer={(event) => this.closePopup(event)}
        openDrawer={this.props.showFaqDocumentDrawer}>
        <div className='drawer add-entity'>
          <div className='drawer-body mt-2'>
            {knowledgeTabContent}
          </div>
        </div>
      </DrawerComponent>
      <DrawerComponent
        heading={'Add New Category'}
        isArrowIcon={true}
        showDrawer={() => this.handleClose("addCategory")}
        openDrawer={this.state.openModal}>
        <div className='drawer add-entity'>
          <div className='drawer-body mt-2'>
            <div className="">
              <InputWithLabelComponent
                type="text"
                name="catname"
                label="Category Name*"
                value={this.state.dialogValue.title}
                readOnly={false}
                onChange={(event) => this.setDialogValue(event)}
                required={true}
                isInvalid={this.state.isEmptyCatName || this.state.dailogErrorStatus}
                error={this.state.dailogError}
                maxLength="15"
              />
              <span
                style={{
                  float: "right",
                  color: "#A49D9D",
                  fontStyle: "Roboto-bold",
                  fontSize: "12px",
                  fontWeight: "700",
                  position: 'relative',
                  top: '4px',
                }}
              >
                {this.state.categoryNameCount} Characters{" "}
                <span>{CatNameCharString}</span>
              </span>
            </div>
            <div className="my-3">
              <ButtonComponent
                disabled={!(this.state.dialogValue.title && !this.state.dailogErrorStatus)}
                variant='contained'
                label='Save'
                className='buttonWidth mb-2'
                clicked={(event) => this.saveCategory(event, this.state.dialogValue.title)} />
            </div>
          </div>
        </div>
      </DrawerComponent>
      <Dialog open={this.state.fileUploadCatModal} onClose={() => this.handleClose('fileUploadAlreadyExit')} aria-labelledby="form-dialog-title">
        <form>
          <DialogContent>
            {this.state.existingFilesErrorMsg}
          </DialogContent>
          <DialogContentText>
          </DialogContentText>
          <DialogActions>
            <Button onClick={() => this.uploadExistingFile(true)} color="primary">
              OK
            </Button>
            <Button onClick={() => this.removeExistingFilesandUpload()} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <div >
        {uploadloadingImg}
      </div>
      <Dialog open={this.state.deleteCatModal} onClose={() => this.handleClose('delteeCategory')} aria-labelledby="form-dialog-title">
        <form style={{ width: "600px" }}>
          <DialogTitle id="form-dialog-title">Delete Confirmation</DialogTitle>
          <hr></hr>
          <DialogContentText>
            <b>&nbsp;&nbsp;&nbsp;&nbsp; Are you sure to delete selected category?</b>
          </DialogContentText>
          <DialogActions style={{ justifyContent: "center", marginBottom: "10px" }}>
            <Button onClick={(event) => this.handleDeleteCategory(event)} color="primary" style={{ borderRadius: "20px", width: "100px", height: "40px" }}>
              Delete
            </Button>
            <Button onClick={() => this.handleClose('deleteCategory')} color="primary" style={{ borderRadius: "20px", width: "100px", height: "40px" }}>
              Cancel
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>)
  }
}

const mapStateToProps = state => {
  return {
    loading: state.projectview.loading,
    knowledgeImportloading: state.projectview.knowledgeImportloading,
    GetKnowledgeloading: state.projectview.GetKnowledgeloading,
    knowledgePattern: state.projectview.knowledgePattern,
    delknowledgeIntentInprogress: state.projectview.delknowledgeIntentInprogress,
    importKnowledgeIntent: state.projectview.importKnowledgeIntent,
    knowledgeIntents: state.projectview.knowledgeIntents,
    macrodata: state.projectview.macrodata,
    macrolist: state.projectview.macrolist,
    IsKnowledgeGridCollapse: state.projectview.IsKnowledgeGridCollapse,
    updateKnowledgeIntent: state.projectview.updateKnowledgeIntent,
    updateKnowledgeIntentLoading: state.projectview.updateKnowledgeIntentLoading,
    knowledgeUpload: state.projectview.knowledgeUpload,
    uploadloading: state.projectview.uploadloading,
    knowledgeUploadfailure: state.projectview.knowledgeUploadfailure,
    catList: state.projectview.catList,
    getMacrosFileData: state.projectview.getMacrosFileData,
    saveMacrosData: state.projectview.saveMacrosData,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetUploadFile: () => dispatch(actions.resetUpload()),
    onSaveKnowledgeIntent: (knowledgeData, APIURL, IsCreate) => dispatch(actions.SaveKnowledgeIntent(knowledgeData, APIURL, IsCreate)),
    onSaveKnowledgeIntentWithFile: (APIURL, formData, data) => dispatch(actions.SaveKnowledgeIntentWithFile(APIURL, formData, data)),
    onGetKnowledgeIntent: (reqData, APIURL) => dispatch(actions.GetKnowledgeIntent(reqData, APIURL)),
    OnGetBotList: (APIURL) => dispatch(actions.GetBotList(APIURL)),
    onDownloadTemplate: (apiUrl) => dispatch(actions.DownloadTemplate(apiUrl)),
    onDeleteKnowledgeIntent: (APIURL, projectName, language, flowname, orgName) => dispatch(actions.DeleteKnowledgeIntent(APIURL, projectName, language, flowname, orgName)),
    onDeleteMultiKnowledgeIntent: (APIURL, data, bool) => dispatch(actions.DeleteMultiKnowledgeIntent(APIURL, data, bool)),
    OnKnowledgeIntentImport: (APIURL, data, importType) => dispatch(actions.KnowledgeIntentImport(APIURL, data, importType)),
    onExportKnowledgeIntent: (APIURL, projectName, language, orgName) => dispatch(actions.ExportKnowledgeIntent(APIURL, projectName, language, orgName)),
    OnHandleImportError: (msg) => dispatch(actions.HandleImportError(msg)),
    onsaveBotProfile: (profile, APIURL) => dispatch(actions.saveBotProfile(profile, APIURL)),
    onsaveMacroFileData: (apiURL, data) => dispatch(actions.SaveMacroFileData(apiURL, data)),
    onreadMacro: (APIURL, data) => dispatch(actions.readMacro(APIURL, data)),
    onGetMacroList: (APIURL, data) => dispatch(actions.GetMacroList(APIURL, data)),
    onKnowledgeGridExpandCollapse: (expandCollapse) => dispatch(actions.KnowledgeGridExpandCollapse(expandCollapse)),
    onUpdateAllKnowledgeIntent: (bulkUpdateArr, APIURL) => dispatch(actions.UpdateAllKnowledgeIntent(bulkUpdateArr, APIURL)),
    onsaveCategory: (APIURL, data) => dispatch(actions.saveCategory(APIURL, data)),
    onGetCategoryList: (APIURL) => dispatch(actions.getAllCategory(APIURL)),
    onupdateCategory: (APIURL, data) => dispatch(actions.updateCategory(APIURL, data)),
    ondelCategory: (APIURL, data) => dispatch(actions.delCategory(APIURL, data)),
    onMacrosReadFile: (file, APIURL) => dispatch(actions.macrosReadFile(file, APIURL)),
    OnSaveMacrosData: (APIURL, data) => dispatch(actions.saveMacrosData(APIURL, data)),
    ondeleteAltQuesForFAQ: (APIURL, data) => dispatch(actions.deleteAltQuesForFAQ(APIURL, data)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(SynonymsDrawerContainer, axios));
function isFirstColumn(params) {
  var displayedColumns = params.columnApi.getAllDisplayedColumns();
  return displayedColumns[0] === params.column;
}
function CreateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}


