// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.treeView .MuiTreeItem-root{
    
    cursor: pointer;
    font-family: 'Roboto-Regular' !important;
    font-size: 12px !important;
    line-height: 14px;
    border-top:none !important;
   
}
.treeView.MuiTreeView-root{
    border-bottom: 1px solid rgba(226, 218, 218, 0.73) !important;
}
.treeView .MuiTreeItem-iconContainer{
    position: absolute !important;
    right: 10px !important;
}

.treeView .MuiTreeItem-group{
    margin-left:0px !important
}
.treeView .MuiTreeItem-content{
    padding:2px !important
}
.treeView .MuiTreeItem-label{
    padding-left: 0px !important;
    font-family: 'Roboto-Regular' !important;
    font-size: 12px !important;
    line-height: 14px;
    
}
.treeView .treeItemLabel{
    color: #000;
    padding: 10px 13px !important;
}
.treeView .treeInnerLabel{
    color: #000 !important;
     padding: 5px 10px !important;
}
.treeView .activeTree{
    color: #5A85F2 !important;
    padding: 5px 10px !important;
}

.treeView .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label {
    background: transparent !important;
}
.treeView .MuiTreeItem-label:hover {
    background-color: transparent !important;
}`, "",{"version":3,"sources":["webpack://./src/components/UIComponents/TreeComponent/TreeComponent.css"],"names":[],"mappings":"AAAA;;IAEI,eAAe;IACf,wCAAwC;IACxC,0BAA0B;IAC1B,iBAAiB;IACjB,0BAA0B;;AAE9B;AACA;IACI,6DAA6D;AACjE;AACA;IACI,6BAA6B;IAC7B,sBAAsB;AAC1B;;AAEA;IACI;AACJ;AACA;IACI;AACJ;AACA;IACI,4BAA4B;IAC5B,wCAAwC;IACxC,0BAA0B;IAC1B,iBAAiB;;AAErB;AACA;IACI,WAAW;IACX,6BAA6B;AACjC;AACA;IACI,sBAAsB;KACrB,4BAA4B;AACjC;AACA;IACI,yBAAyB;IACzB,4BAA4B;AAChC;;AAEA;IACI,kCAAkC;AACtC;AACA;IACI,wCAAwC;AAC5C","sourcesContent":[".treeView .MuiTreeItem-root{\n    \n    cursor: pointer;\n    font-family: 'Roboto-Regular' !important;\n    font-size: 12px !important;\n    line-height: 14px;\n    border-top:none !important;\n   \n}\n.treeView.MuiTreeView-root{\n    border-bottom: 1px solid rgba(226, 218, 218, 0.73) !important;\n}\n.treeView .MuiTreeItem-iconContainer{\n    position: absolute !important;\n    right: 10px !important;\n}\n\n.treeView .MuiTreeItem-group{\n    margin-left:0px !important\n}\n.treeView .MuiTreeItem-content{\n    padding:2px !important\n}\n.treeView .MuiTreeItem-label{\n    padding-left: 0px !important;\n    font-family: 'Roboto-Regular' !important;\n    font-size: 12px !important;\n    line-height: 14px;\n    \n}\n.treeView .treeItemLabel{\n    color: #000;\n    padding: 10px 13px !important;\n}\n.treeView .treeInnerLabel{\n    color: #000 !important;\n     padding: 5px 10px !important;\n}\n.treeView .activeTree{\n    color: #5A85F2 !important;\n    padding: 5px 10px !important;\n}\n\n.treeView .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label {\n    background: transparent !important;\n}\n.treeView .MuiTreeItem-label:hover {\n    background-color: transparent !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
