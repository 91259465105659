import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import ButtonCustom from "../../../../../components/UIComponents/Button/ButtonComponent";
import Form from 'react-bootstrap/Form';
import { useSelector, useDispatch } from "react-redux";
import * as inputTypes from './InputTypes';
import { UpdateCuxFormActiveField } from '../../../../../store/actions';
import InputWithLabelComponent from "../../../../../containers/SettingPage/SettingInnerPages/components/InputWithLabelComponent/InputWithLabelComponent";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from './Settings/TextField';
import FileUpload from './Settings/FileUpload';
import Heading from './Settings/Heading';
import Text from './Settings/Text';
import Image from './Settings/Image';
import ContactNum from './Settings/ContactNum';
import Calender from './Settings/Calender';
import Consent from './Settings/Consent';

const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 12,
      fontFamily: "Roboto-Regular",
    },
    arrow: {
      color: theme.palette.common.white,
      "&:before": {
        border: "1px solid #E6E8ED",
        boxShadow: theme.shadows[1],
      },
    },
  }))(Tooltip);

const Settings = ({fields, handleSettingSave, onDelete}) => {
 
    const currentActiveField = useSelector(state => {
        return state.projectview.CuxFormActiveField;
      });

    const dispatch = useDispatch();

    const getIndex = () => {
        let index = fields.findIndex((item) => item.index === currentActiveField);
        return index  === -1 ? 0 : index;
    }

    const [field, setField] = useState(fields[getIndex()]); 
    const [properties, setProperties] = useState(fields[getIndex()].properties);

    const [variableList, setVariableList] = useState();
    const [invalidVarMsg, setInvalidVarMsg] = useState(null);
    const [invalidSizeMsg, setInvalidSizeMsg] = useState(null);
    
    useEffect (() => {
        getVariables();
    },[fields]);

    function getVariables () {
        let list = [];
        if(fields !== undefined) {
            fields.forEach((node)=> {
                if(node.properties.id !== undefined) {
                  list.push(node.properties.id);
                }        
              });
        }        
        setVariableList(list);
    }

    function isDuplicate(value) {
        if (variableList !== undefined && variableList.length > 0) {
          let varExists = variableList.findIndex((element) => {
            return element.toLowerCase() === value.toLowerCase();
          });
          if (varExists > -1) {
            return true;
          } 
          return false;
        } else 
          return false;
      }

    useEffect(()=>{
        setProperties(fields[getIndex()].properties);
        setField(fields[getIndex()]);
    },[currentActiveField, fields])

    useEffect(()=> {
        if(properties.isReference) {
            onChangeHandler('choices',[properties.choices[0]]);
            onChangeHandler('count',1);
        } else {
            onChangeHandler('referenceVariable','');
        }
    },[properties.isReference])
    
    const onChangeHandler = (type,value) => {
        if(type === 'id') {
            if (!/^[A-Z0-9]+$/i.test(value)) {
                setInvalidVarMsg('Name should be alphanumeric.');
            } else if(isDuplicate(value)) {
                setInvalidVarMsg('Name already exists.');
            } else {
                setInvalidVarMsg(null);
            }
        } else if (type === 'id_cc' || type === 'id_ph') {
            if (!/^[A-Z0-9]+$/i.test(value.slice(0,-3))) {
                setInvalidVarMsg('Name should be alphanumeric.');
            } else if(isDuplicate(value.slice(0,-3))) {
                setInvalidVarMsg('Name already exists.');
            } else {
                setInvalidVarMsg(null);
            }
        } else if(type === 'count' && value != '') {   
            let currentCount = properties.choices.length;
            value = parseInt(value);
            if (value > currentCount)
                addChoices(value - currentCount);
        } else if(type === 'fileSize') {
            if(!(0 < value  && value < 11)) {
                setInvalidSizeMsg('Value must be less than or equal to 10');
            } else {
                setInvalidSizeMsg(null);
            }
        }
        
        setProperties(prevState=>({
            ...prevState,
            [type]:value
        }));
        
    }

    const handleSubmit = () => {
        handleSettingSave(properties);
    }

    const handleDelete = () => { 
        fields = fields.filter((item) => item.index !== currentActiveField);
        onDelete(currentActiveField);
        if(fields.length > 0)
            dispatch(UpdateCuxFormActiveField(fields[0].index));
        else 
            dispatch(UpdateCuxFormActiveField(0));
    }

    const updateChoices = (key, index, value) => {
        let tempArray = [...properties.choices];
        if(key === 'checked') {
            if(field.type === inputTypes.MULTISELECT) {
                if(value) {
                    let updatedValue = properties.value + ','+tempArray[index].value;
                    onChangeHandler('value',updatedValue);                
                } else {
                    let currentValues = properties.value.split(',');
                    let updatedValues = currentValues.filter((item)=>item!==tempArray[index].value);
                    onChangeHandler('value',updatedValues.join());
                }
            }
            else {
                if(value) {
                    onChangeHandler('value',tempArray[index].value);
                } else {
                    onChangeHandler('value','');
                }
            }
           
        } else {
            tempArray[index] = {...tempArray[index], [key]:value};
            onChangeHandler('choices',tempArray);
        }
        
    }

    function addChoices (count) {
        let tempArray = [...properties.choices];
        for (let i = 0; i < count; i++) {
            tempArray.push({title:'', value:''});
        }
        onChangeHandler('choices',tempArray);
    }

    function deleteChoice (index) {
        let updatedChoices = properties.choices.filter((_, i) => i !== index);
        onChangeHandler('count',properties.choices.length - 1);
        onChangeHandler('choices',updatedChoices);
        
    }

    function renderChoices (index, choice) {
        return (
            <>
            <Row>
                <Col md={6} id='choiceKey-input'>
                    <InputWithLabelComponent
                        type="text"
                        name={'settings-choiceKey'}
                        label={ properties.isReference ? 'Options':'Option '.concat(index+1)}
                        showLabel={true}
                        value={choice.title}
                        readOnly={false}
                        onChange={e => updateChoices('title',index, e.target.value)}
                        required={true}
                        className="settings-input"
                        placeHolder={properties.isReference ? 'Key':'Name'}
                    />
                    </Col>
                <Col md={5} id='choiceValue-input'>
                    <InputWithLabelComponent
                        type="text"
                        name={'settings-choiceValue'}
                        label={' '}
                        showLabel={true}
                        value={choice.value}
                        readOnly={false}
                        onChange={e => updateChoices('value',index, e.target.value)}
                        required={true}
                        className="settings-input"
                        placeHolder='Value'
                    />
                </Col>
                {index > 0 && (
                    <Col md={1} id='choiceDelete' onClick={()=>deleteChoice(index)}>
                        <img src='./images/CDIcons/deleteIcon.svg'></img>
                    </Col>
                )}                
            </Row>
            { !properties.isReference && (
                <Row id='defaultCheck-row'>
                <span id='defaultCheck-label'>Do you want this to be selected?</span>
                <Form>
                        <Form.Check
                            type="switch"
                            id={`isChecked-${choice.title}`}
                            checked={choice.value===''?false:properties.value.includes(choice.value)}
                            onChange={e => updateChoices('checked',index,e.target.checked)}
                        />
                    </Form>
                </Row>
            )}
            
            
            </>
        )
    }

    const getOptionsTitle = () => {
        switch(field.type) {
            case inputTypes.MULTISELECT : return 'check boxes';
            case inputTypes.RADIOBUTTON : return 'radio buttons';
            case inputTypes.DROPDOWN : return 'options';
        }
    }

    const isFormValid = () => {
        if(field.type === inputTypes.HEADING || field.type === inputTypes.TEXT)
            return properties.text;
        else if(field.type === inputTypes.TEXTBLOCK)
            return properties.text;
        else if(field.type === inputTypes.IMAGE)
            return properties.url;
        else if(field.type === inputTypes.CONTACT_NUM)
            return properties.id_ph.slice(0,-3) && properties.label;
        else if (field.type === inputTypes.FILEUPLOAD) {
             return properties.id && properties.label && !invalidSizeMsg && !invalidVarMsg;
        }
        else 
            return properties.id && properties.label && !invalidVarMsg;
    }

    const handleOptionChange = (e) => {
        if(e.target.value === 'true')
            onChangeHandler('isReference',e.target.checked)  
        else {
            onChangeHandler('isReference',false)
            onChangeHandler('referenceVariable','') 
        }
               
    }

    return (
        <div className='settings-box'>
            <div className="mt-1 form-settings-header">
                <Col md={12}>
                    Settings
                </Col>
            </div>
            {field.type === inputTypes.TEXTFIELD && (
                <TextField properties={properties} onChangeHandler={onChangeHandler} invalidVarMsg={invalidVarMsg} />
            )}
            {field.type === inputTypes.FILEUPLOAD && (
                <FileUpload properties={properties} onChangeHandler={onChangeHandler} 
                invalidVarMsg={invalidVarMsg} invalidSizeMsg={invalidSizeMsg} />
            )}
            {field.type === inputTypes.HEADING && (
                <Heading properties={properties} onChangeHandler={onChangeHandler} />
            )}
            {field.type === inputTypes.TEXT && (
                <Text properties={properties} onChangeHandler={onChangeHandler} />
            )}
            {field.type === inputTypes.IMAGE && (
                <Image properties={properties} onChangeHandler={onChangeHandler} />
            )}
            {field.type === inputTypes.CONTACT_NUM && (
                <ContactNum properties={properties} onChangeHandler={onChangeHandler} invalidVarMsg={invalidVarMsg}/>
            )}
            {field.type === inputTypes.CALENDER && (
                <Calender properties={properties} onChangeHandler={onChangeHandler} />
            )}
            {field.type === inputTypes.CONSENT && (
                <Consent properties={properties} onChangeHandler={onChangeHandler} invalidVarMsg={invalidVarMsg}/>
            )}
            {field.type !== inputTypes.TEXTFIELD && field.type !== inputTypes.FILEUPLOAD && 
            field.type !== inputTypes.HEADING && field.type !== inputTypes.TEXT && 
            field.type !== inputTypes.IMAGE && field.type !== inputTypes.CONTACT_NUM && 
            field.type !== inputTypes.CALENDER && field.type !== inputTypes.CONSENT && (
                <>
            
            <div className="mt-1 settings-div">
                <Col md={12} >
                    <Row className='settings-form-switch'>
                        <Col md={6}><span className='settings-label'>Required</span></Col>
                        <Col md={6} className='settings-switch'>
                        <Form>
                                <Form.Check
                                    type="switch"
                                    id="isRequired"
                                    checked={properties.isRequired}
                                    onChange={e => onChangeHandler('isRequired',e.target.checked)}
                                />
                            </Form>
                        </Col>
                    </Row>
                </Col>
            </div>
            
            <div className="mt-1 settings-div">
                <Col md={12}>
                <InputWithLabelComponent
                        type="text"
                        name={'settings-id'}
                        label="Name*"
                        showLabel={true}
                        value={properties.id}
                        readOnly={false}
                        onChange={e => onChangeHandler('id',e.target.value)}
                        required={true}
                        className="settings-input"
                        isInvalid={invalidVarMsg!==null}
                        error={invalidVarMsg}
                        toolTip={true}
                        toolTipParams={{title:'Name will also be used as variables',placement:'right'}}
                  />
                </Col>
            </div>
            
            <div className="mt-1 settings-div">
                <Col md={12}>
                <InputWithLabelComponent
                        type="text"
                        name={'settings-label'}
                        label="Label*"
                        showLabel={true}
                        value={field.type === inputTypes.TEXTBLOCK?properties.text:properties.label}
                        readOnly={false}
                        onChange={e => onChangeHandler(field.type === inputTypes.TEXTBLOCK?'text':'label',e.target.value)}
                        required={true}
                        className="settings-input"
                  />
                </Col>
            </div>
            {(field.type === inputTypes.MULTISELECT || field.type === inputTypes.RADIOBUTTON 
            || field.type === inputTypes.DROPDOWN ) && (
                <>
            <div className="mt-1 settings-div">
            <Col md={12}>
                <div>
                <input type="radio" id="manual" name="inputMethod" value={false} checked={!properties.isReference} onChange={handleOptionChange}/>
                <label >Manual</label>
                <input type="radio" id="reference" name="inputMethod" value={true} checked={properties.isReference} onChange={handleOptionChange}/>
                <label >Reference variable</label><br/>
                </div>
            </Col>
            </div>
            <div className="mt-1 settings-div">
            <Col md={12}>
                { properties.isReference ? 
                    <InputWithLabelComponent
                        type="text"
                        name="settings-referenceVar"
                        label="Reference Variable*"
                        showLabel={true}
                        value={properties.referenceVariable}
                        readOnly={false}
                        onChange={(e) => onChangeHandler('referenceVariable',e.target.value)}
                        required={true}
                        className="settings-input"
                    />
                :
                    <InputWithLabelComponent
                        type="text"
                        name={'settings-placeholder'}
                        label={'How many '+getOptionsTitle()+' you want ?'}
                        showLabel={true}
                        value={properties.count}
                        readOnly={false}
                        onChange={e => onChangeHandler('count',e.target.value)}
                        required={true}
                        className="checkbox-count"
                        placeholder='0'
                    />
            
            }
            </Col>
            </div>
            <div className="mt-1 settings-div">
            <Col md={12}>
                <div id='options-label'>{getOptionsTitle()}
                <LightTooltip 
                    arrow
                    title="'Name' of the option is shown to the user and 'Value' of the option 
                    gets assigned to the form element when selected by the user."
                    placement="top-start"
                    >
                    <img 
                        src="./images/info_icon.png"
                        style={{ position: "relative", top: "-1px", left: "6px" }}
                    />
                </LightTooltip>
                </div>
                { properties.isReference ? renderChoices(0,properties.choices[0]) :
                properties.choices.map((item, index)=> renderChoices(index,item))}
            </Col>
            </div>
            </>
            )}
            {(field.type === inputTypes.TEXTAREA) && (
                <div className="mt-1 settings-div">
                <Col md={12}>
                    <InputWithLabelComponent
                        type="text"
                        name={'settings-placeholder'}
                        label="Placeholder"
                        showLabel={true}
                        value={properties.placeholder}
                        readOnly={false}
                        onChange={e => onChangeHandler('placeholder',e.target.value)}
                        required={true}
                        className="settings-input"
                    />
                </Col>
                </div>
            )}
            {field.type !== inputTypes.DROPDOWN && field.type !== inputTypes.MULTISELECT 
            && field.type !== inputTypes.RADIOBUTTON && (
                <div className="mt-1 settings-div">
                <Col md={12}>
                    <InputWithLabelComponent
                        type="text"
                        name={'settings-default'}
                        label="Default Values"
                        showLabel={true}
                        value={properties.value}
                        readOnly={false}
                        onChange={e => onChangeHandler('value',e.target.value)}
                        required={true}
                        className="settings-input"
                    />
                </Col>
                </div>
            )}
            
            <div className="mt-1 settings-div">
                <Col md={12}>
                    <InputWithLabelComponent
                        type="text"
                        name={'settings-default'}
                        label="Error Message"
                        showLabel={true}
                        value={properties.errorMessage}
                        readOnly={false}
                        onChange={e => onChangeHandler('errorMessage',e.target.value)}
                        required={true}
                        className="settings-input"
                    />
                </Col>
            </div>
            </>
            )}
            <div className="mt-1 settings-btn-row">
                <Col md={12}>
                    <Row>
                    <Col md={5}>
                        <ButtonCustom variant='outlined' label='Delete'className='buttonWidth settings-btn' clicked={handleDelete} />
                    </Col>
                    <Col md={6}>
                        <ButtonCustom variant='contained' label='Save'className='buttonWidth settings-btn' disabled={!isFormValid()} clicked={handleSubmit}/>
                    </Col>
                    </Row>
                    
                </Col>
            </div>
            
        </div>
    )
}

export default Settings;