import React, { useState, useEffect } from "react";
import Drawer from "@material-ui/core/Drawer";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import "./Users.css";
import { Col, Form } from "react-bootstrap";
import DrawerHeaderContent from "../../components/DrawerHeaderContent/DrawerHeaderContent";
import { useDispatch } from "react-redux";
import InputWithLabelComponent from "../../components/InputWithLabelComponent/InputWithLabelComponent";
import isEmpty from "lodash/isEmpty";
import PrimaryButtonWithLoader from "../../components/PrimaryButtonWithLoader/PrimaryButtonWithLoader";
import TextAreaWithLabelComponent from "../../components/TextAreaWithLabelComponent/TextAreaWithLabelComponent";
import ReactSelectComponent from "../../components/ReactSelectComponent/ReactSelectComponent";
import {
  GetCalltoOUServer,
  PostCallToOUServer,
  UpdateCalltoOUServer,
} from "../helpers/utility";
import {
  SetSuccessMessage,
  SetErrorMessage,
} from "../../../../../store/actions";
import AutoCompleteComponent from "../../Insight/AdminConfiguration/autoComplete/AutoComplete";
import ProductAttributesComponent from "../../Insight/AdminConfiguration/ProductAttributesComponent/ProductAttributesComponent"
import get from "lodash/get";
// C:\gitLab\botreactapp\src\containers\SettingPage\SettingInnerPages\Insight\AdminConfiguration\ProductAttributesComponent\ProductAttributesComponent.js
// C:\gitLab\botreactapp\src\containers\SettingPage\SettingInnerPages\UserManagement\Users\UserGroupInvitation.js

const useStyles = makeStyles({
  list: {
    width: 600,
  },
});

const InviteUser = ({
  groupName,
  config, 
  users,
  showDrawer,
  toggleDrawer,
  updateUser, 
  // disabled,  
  userGroupName,
  updateUserGroups,
  // groupUpdatedToastMsg,
  closeDrawer,  
}) => {
  const orgname = localStorage.getItem("orgName"); 
  const [userGroups, setUserGroups] = useState([]);
  const [emailId, setEmailId] = useState([]);
 
  const [selectedGroups, setSelectedGroups] = useState([]);
  
  const [name, setName] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [disabled, setDisabled] = useState(false);
  const [invalidGroupMsg, setInvalidGroupMsg] = useState();

  const [mandatory, setMandatory] = useState(false);
  const [productAttributesData, setProductAttributesData] = useState([]);
 
  const [seletctedRootcauseData, setSeletctedRootcauseData] = useState([]);
  const [userData, setUserData] = useState([]);
  console.log('userData',userData);
  const [inviteMsg, setInviteMsg] = useState();
  // const [formData, setFormData] = useState({
  //   username: "",
  //   first_name: "",
  //   email: "",
  //   last_name: "",
  //   orgname: orgname,
  //   usergroup: [],
  //   message: "I am inviting you to the Intelliservice platform.",
  // });
  const [mandatoryField, setMandatoryField] = useState(false);
  
 const [formData, setFormData] = useState({   
    username: "",
    emailids: [],       
    orgname: orgname,
    usergroup: [],
    message: "I am inviting you to the Intelliservice platform.",
  });

  const formValidation = (formdata) => {
    if(formdata.emailids.length == 0) {
      return true;
    }
  };

 // const rootCauseOptions = get(formData, "rootcauses", []);

  const dispatch = useDispatch();
  const classes = useStyles();
  
  

  useEffect(() => {    
    if (userGroupName !== null && userGroupName !== undefined) {
      setSelectedGroups((prevData) => [...prevData, userGroupName]);
      setDisabled(true);
    } else getUserGroups();
  }, []);


  const parseData = (data) => {
    let usergroup = [];
    usergroup = data.map((group) => group.usergroup_name);
    return usergroup;
  };


  const confirmMsg = (msg, method) => {
    setTimeout(() => {
      dispatch(method(msg))
    },450)
  }


  


 

  const onDropdownChangeHandler = (selectedItems, name) => {
    console.log("selectedItems", selectedItems);
    if (name === "rootcauses") {
      setSeletctedRootcauseData((seletctedRootcauseData) => [
        ...seletctedRootcauseData,
        {
          selectedItems,
        },
      ]);
      setFormData((formData) => ({
        ...formData,
        emailids: [...selectedItems.map(obj=> obj.value)],
        // emailids:[...formData.emailids, ...selected...]
      }));
    }
    setMandatory(false);
  };

  const onRemoveItemHandler = (name ,e) => (selectedItem) => {   
         console.log('str', formData.emailids);
       const emails= formData.emailids.filter(str=>   str !==e)
       setFormData( {...formData, emailids:emails})
    // if (name === "rootcauses") {
    //   const selectedRootCauses = get(formData, "emailids", []);
    //   const filteredRootCauses = seletctedRootcauseData.filter(
    //     (item) => item.rootcauseTitle !== selectedItem
    //   );
    //   setSeletctedRootcauseData(filteredRootCauses);
    //   setFormData((formData) => ({
    //     ...formData,
    //     [name]: selectedRootCauses.filter((item) => item !== selectedItem),
    //   }));
    // } else {
    //   const selectedProduct = get(productAttributesData, name, []);
    //   const filteredProducts = selectedProduct.filter(
    //     (item) => item !== selectedItem
    //   );
    //   setProductAttributesData((productAttributesData) => ({
    //     ...productAttributesData,
    //     [name]: filteredProducts,
    //   }));
    // }
  };

//geExistingtUsers

const [existingUsers, setExistingUsers ]=useState([])
console.log('existingUsers',existingUsers);
  const geExistingtUsers = (name) => {
    //  alert('existingUsers')
    const orgname = localStorage.getItem("orgName");
    const url =config.COMMON_API_URL +`/organization/usergroup?orgname=${orgname}&usergroup=${name}`;

    GetCalltoOUServer(url)
      .then((response) => {      
        if (response.data != null) {           
          setExistingUsers( [...response.data[0].users].map(obj=> obj.username) );          
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {    
      geExistingtUsers(groupName);   
  }, []);
// end geExistingtUsers


    //  GETTING ALL USER EMAILS AND STORING IN USERDATA STATE
    const getUsers = () => {
      //  alert('getUsers')
      const orgname = localStorage.getItem("orgName");
      const url = config.COMMON_API_URL + `/organization/user?orgname=${orgname}`;
  
      GetCalltoOUServer(url)
        .then((response) => {       
          if (response.data != null) {                
            const allEmailIds= response.data.map(obj=> obj.username);
             const nonExistingEmailIds=allEmailIds.filter(str=> !existingUsers.includes(str))             
            setUserData(nonExistingEmailIds.map(option=> ({label:option, value:option})));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };  
    useEffect(() => {    
     getUsers()
    }, [existingUsers]);


  

  const getUserGroups = () => {

    const url =
      config.COMMON_API_URL + `/organization/usergroup?orgname=${orgname}`;
    
    GetCalltoOUServer(url)
      .then((response) => {
        if (response.data != null) {  
             console.log('getUserGroups', userGroups);
          setUserGroups(parseData(response.data));
        } 
      })
      .catch((err) => {
        console.log(err)
      });
  };

//   const getInviteEmials = () => {
//     const orgname = localStorage.getItem("orgName");
//     const url = config.COMMON_API_URL + `/organization/user/azure?orgname=${orgname}`;
//     GetCalltoOUServer(url)
//     .then((response) => {
//       if (response.data != null) {
           
//            setEmailId(response.data);
//       } 
//     })
//     .catch((err) => {
//       console.log(err)
//     });
// };
//   useEffect(() => {
//     getInviteEmials();
//   }, []);

  const getOptions = (data) => {
    if (isEmpty(data)) {
      return [];
    }
    const attrData = data;
    return attrData.map((option) => ({
      label: option,
      value: option,
    }));
  };

  useEffect(() => {
    setFormData((formData) => ({
      ...formData,
      usergroup: selectedGroups,
    }));
  }, [selectedGroups]);

  
  const checkIfExistingUser = async (username) => {
    const url =  config.COMMON_API_URL +`/organization/user?username=${username}&orgname=${orgname}`;
    try {
      const response = await GetCalltoOUServer(url);
      if (response.status === 200) {       
        return true;
      } else {
        return false;
      }
      // })
    } catch (error) {
      console.log("error while checking exitsing user: ", error);
      return false;
    }
  };


  
  async function getUpdatedUsergroups(username) {
    const url = config.COMMON_API_URL + `/organization/user?username=${username}&orgname=${orgname}`;
    try {
      const response = await GetCalltoOUServer(url);

      if (response.data != null) {
        let usergroupList = response.data[0]?.usergroup;
        usergroupList.push(userGroupName);
        return usergroupList;
      }
    } catch (err) {} 
  }
  console.log('formData.emailids',formData.emailids);
  const testUrl =config.COMMON_API_URL + `/organization/${orgname}/usergroup/${userGroupName}`;
  const temp = {
    usergroup_name: userGroupName,
    description: formData.message,
    language: "",
    projects: [],
    roles: [],
    user_list: [...formData.emailids,...existingUsers],
  };
 
  const inviteUser = async (e) => {  
    e.preventDefault();
    let url = null;    
  
  
    if (!formValidation(formData)) {
     try {
       const response = await UpdateCalltoOUServer(testUrl, temp);
       console.log('responseresponse',response);
       closeDrawer();
       updateUserGroups();
       confirmMsg("User group updated successfully", SetSuccessMessage);
       toggleDrawer(true);
     } catch (error) {
       confirmMsg("Something went wrong!", SetErrorMessage);
       setErrorMsg("User already exists.");
       dispatch(SetErrorMessage("User already exists!"));
     }
    } else setMandatory(formValidation(formData));
  }

  // const inviteUser = async (e) => {  
  //   e.preventDefault();
  //   let url = null;
  //      //  if (userGroupName !== undefined) {
  //       // let msg = "I am adding you to the usergroup " + userGroupName;
  //       // setInviteMsg("I am adding you to the usergroup " + userGroupName);
  //       // const list = await getUpdatedUsergroups(formData.username);
  //     const requestBody = {
  //       usergroup_name:userGroupName,
  //       description:formData.message,
  //       language:"",
  //       projects:[],
  //       roles:[],
  //       user_list:formData.emailids
    
  //     }
      
  //       // let username = formData.username.replaceAll(".", "~~");
      
  //       url =config.COMMON_API_URL + `/organization/${orgname}/user/${username}` ;

       
  //         try{
  //         const response = await  UpdateCalltoOUServer(url, requestBody);
  //         closeDrawer();
  //         updateUserGroups();
  //         confirmMsg('User group updated successfully', SetSuccessMessage);
  //         toggleDrawer(true);
           
  //         }
  //         catch(err => console.log(err));

      

     
       
  //       // confirmMsg('Something went wrong!', SetErrorMessage);
  //       // setErrorMsg("User already exists.");     
  //       // dispatch(SetErrorMessage("User already exists!"));
       
      
  // };


//END inviteUser


  

 
  const onChangeHandler = (event, type) => {   
      setFormData((formData) => ({
        ...formData,
        [type]: event.target.value,
      }));
    
  };

  const updateUserGroup = (e) => {
    if (!selectedGroups.includes(e.value))
      setSelectedGroups((prevData) => [...prevData, e.value]);
  };

  const removeUserGroup = (e, item) => {    
    e.preventDefault();
    if (userGroupName === undefined)
      setSelectedGroups(selectedGroups.filter((list) => list !== item));
  };
  const selectedEmialId = (value) => {
    emailId && emailId.map((item) => item)
    if((emailId.map((item) => item)).includes(value)) 
    setFormData((formData) => ({
      ...formData,
      username: value,
    }));
    else{
      setFormData((formData) => ({
        ...formData,
        username: value,
      }));
    }
  }




  return (
    <React.Fragment>
      {showDrawer ? (
        <Drawer
          anchor={"right"}
          open={showDrawer}
          onClose={toggleDrawer(false)}
        >
          <form onSubmit={inviteUser}>
            <div
              className={clsx(classes.list, classes.fullList)}
              role="presentation"
            >
              <DrawerHeaderContent
                heading="Add User"
                toggleDrawer={toggleDrawer}
              />{" "}
              
              <div className="d-flex flex-column Symtom-Item invite-user highlights">
                {/* <div className="mt-1">
                  <Col md={12}>
                    <InputWithLabelComponent
                      type="text"
                      className=""
                      placeHolder="Enter username"
                      name="invite name"
                      label="User Name" 
                      value={name}
                      readOnly={false}
                      onChange={(e) => onChangeHandler(e, "first_name")}
                      required={true}
                    />
                  </Col>
                </div> */}
             
                <div className=""  style={{marginLeft:-18, width:523}}>
                  <Col md={12}>
                    {/* {userData.length} */}
                    <Form.Label className="mb-1 text-box-title  adding-other-input">
                      {/* {"User Email Id"} */}
                    </Form.Label>
                    {/* <AutoCompleteComponent
                    suggestions={emailId.map((item) => item)}
                    symptomUpdate={selectedEmialId}
                    // symptomError={symptomError}
                    placeholder={"Enter Email id"}
                    type="Symptom"
                  /> */}
                    <ProductAttributesComponent
                      options={userData}
                      name={"User Email id"}
                      label={"User Email id"}
                      boldTitle={true}
                      search={true}
                      placeholder={"Select"}
                      className={"mt-1 mb-2"}
                      targetSourceDescription={"source_name"}
                      sourceHelpIcon={false}
                      attributeHelpIcon={false}
                      isRequired={true}
                      value={formData.emailids}
                      onChange={(event) =>
                        onDropdownChangeHandler(event, "rootcauses")
                      }
                      onRemoveItem={ e=>   onRemoveItemHandler("rootcauses",e)()}
                    />
                    {/* onDropdownChangeHandler(event, "rootcauses")  */}
                  </Col>
                </div>
                <div className="username-error">{errorMsg}</div>
                <br />

                {/* as per 8924 this feild is removed */}
                {/* <div className="mt-1">
                  <Col md={12}>
                    <InputWithLabelComponent
                      type="text"
                      className=""
                      name="email"
                      label="Alternative Email Id"
                      placeholder="Enter alternative email id"
                      value={formData.email}
                      readOnly={false}
                      onChange={(e) => onChangeHandler(e, "email")}
                      required={false}
                    />
                  </Col>
                </div> */}

                <div className="mt-1">
                  <Col md={12}>
                    <TextAreaWithLabelComponent
                      label="Message"
                      name="message"
                      value={formData.message}
                      readOnly={false}
                      className="text-area"
                      placeholder={inviteMsg}
                      onChange={(e) => onChangeHandler(e, "message")}
                      //onChange={(e) => updateFilterData(e.target.value, 'description')}
                    />
                  </Col>
                </div>
                <br />
                <div className="mt-1">
                  <span style={{paddingLeft:12,fontSize:12,fontFamily:'Roboto-Bold'}}>User Group</span> 
                  <div className="mt-1">
                 
                  </div>
                  <div className="mt-1">
                    <Col xs={12}>
                      <div className="d-flex flex-start align-items-center associate-multi-select">
                         <span style={{background:'gray', borderRadius:5, padding:'2px 5px'}}>{ userGroupName}</span> 
                        {
                        
                        // selectedGroups && selectedGroups.length ? (
                        //   selectedGroups.map((item) => (
                        //     <div className="mr-3 mt-2 pr-2 d-flex flex-start align-items-center selected-option">
                        //       <div
                        //         onClick={(e) => removeUserGroup(e, item)}
                        //         className="remove-icon mr-2 ml-2"
                        //       >
                        //         <div className="selected-close-icon">x</div>
                        //       </div>
                        //       <div className="selected-option-label">
                        //         {item}
                        //       </div>
                        //     </div>
                        //   ))
                        // ) : (
                        //   <div className="error-msg">{invalidGroupMsg}</div>
                        // )
                        
                        }
                      </div>
                    </Col>
                  </div>
                </div>
              </div>
              <div className="ml-4">
                {mandatory ? (
                  <Form.Label className="pl-4 pt-3 text-box-error">
                    {"All fields are mandatory"}
                  </Form.Label>
                ) : null}
              </div>
              <div className="d-flex flex-row">
                <PrimaryButtonWithLoader
                  className="ml-5 mb-2 mt-2"
                  size="lg"
                  type="submit"
                  // onClick={inviteUser}
                  text="Submit"
                  loading={false}
                  // disabled={}
                />
              </div>
            </div>
          </form>
        </Drawer>
      ) : null}
    </React.Fragment>
  );
 };
export default InviteUser;
