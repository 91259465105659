import React, { memo, useMemo, useEffect } from 'react';
import { Handle, useUpdateNodeInternals } from 'react-flow-renderer';
import NodeBox from './NodeBox';

export default memo(({ id, data, isConnectable }) => {  

  let sourceArray = data.buttonDataList?[...data.buttonDataList]:[];

  // To be removed when showMoreBtn branch becomes inbuilt in backend
  let defaultBranch = false;
  if(data.showMoreBtn!==undefined && data.showMoreBtn!='' && data.showMoreBtn!=null) {
    
    if(!data.buttonDataList) {
      defaultBranch = true;
    } else 
      sourceArray.push(data.showMoreBtn);
  }  

  const showMoreDefault = useMemo(
    () => (
      <>
      
      <Handle
          key='1'
          type="source"
          position="bottom"
          isConnectable={isConnectable}
          style={{ left: 65 }}
      />
      <Handle
          key='0'
          type="source"
          position="bottom"
          id={data.showMoreBtn}
          isConnectable={isConnectable}
          style={{ left: 155 }}
      />
      </>
    )
    
  );
  
  // till here

  let segment = 220/sourceArray.length;
  let gap = segment/2;

  const positionHandle = (index) => {
      return segment * index + gap ;
  }

  useEffect(() => {
    updateNodeInternals(id);
  }, [sourceArray,data.showMoreBtn])

  const updateNodeInternals = useUpdateNodeInternals();

  const sourceHandles = useMemo(
    () =>
        sourceArray.map((option, index) => {
            const handleId = option?.btnText!==undefined?option.btnText:option;
            return (
                <Handle
                    key={index}
                    type="source"
                    position="bottom"
                    id={handleId}
                    style={{ left: positionHandle(parseInt(index)) }}
                />
            );
        }),
    [sourceArray]
);

const defaultHandle = useMemo(
  () =>
    <Handle
        key='1'
        type="source"
        position="bottom"
        isConnectable={isConnectable}
    />
);

const getDefaultBranch = () => {
  return defaultBranch?showMoreDefault:defaultHandle
}

  return (
    <>
      <Handle
        type="target"
        position="top"
        style={{ background: '#555' }}
        isConnectable={isConnectable}
      />
      <NodeBox type='Carousel' data={data}></NodeBox>
      {sourceArray.length>0?sourceHandles:getDefaultBranch()}
      
    </>
  );
});