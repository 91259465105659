import isEmpty from 'lodash/isEmpty';

const fetchProductTriageDataSuccessReducer = (state, payload) => {
    let filterObj = {};
    let filterObjArr = {};
    payload.forEach(item => filterObj[item.attribute_name] = '');
    payload.forEach(item => filterObjArr[item.attribute_name] = []);
    return {
        ...state,
        filterData: payload,
        productTriageDataLoading: false,
        selectedFilterData: isEmpty(state.selectedFilterData) ? filterObj : state.selectedFilterData,
        selectedFilterDataArr: isEmpty(state.selectedFilterDataArr) ? filterObjArr : state.selectedFilterDataArr
    };
};

export default fetchProductTriageDataSuccessReducer;