/*

* Copyright © 2020, HCL Technologies Ltd. ALL RIGHTS RESERVED.

* <p>

* This software is the confidential information of HCL Technologies Ltd., and is licensed

* as restricted rights software. The use,reproduction, or disclosure of this software

* is subject to restrictions set forth in your license agreement with HCL.

*/

/**

* ProjectView component.

*

* @author Abhay Kumar Mishra,Hemavathi Kandamani,Amritpal Singh

*/
import React, { Component } from "react";
import * as XLSX from "xlsx";
import classes from "./ProjectViewPage.module.css";
import * as actions from "../../store/actions/index";
import { connect } from "react-redux";
import {
  Container,
  Col,
  Row,
  Image,
  Dropdown as Dropdown1,
} from "react-bootstrap";
import TabCustom from "../../components/UI/HorizontalTab/HorizontalTab";
import AddSynonymsAndAncronymsDrawer from "../../components/UI/Drawer/AddSynonymsAndAncronymsDrawer";
import EditorCustom from "../../components/UI/Editor/Editor";
import Input from "../../components/UI/Input/InputCustom";
import ButtonCustom from "../../components/UI/Button/Button";
import withErrorHandler from "../../hoc/withErrorHandler/withErrorHandler";
import axios from "../../instance";
import ValidationMsg from "../../components/ValidationMsg/ValidationMsg";
import ModalPopUp from "../../components/UI/Modal/Modal";
import ProjectHeaderPage from "../../components/UI/ProjectPageHeader/ProjectPageHeader";
import EntityCustom from "../../components/UI/Entities/Entities";
import ConfigurationCustom from "../../components/UI/Entities/Configuration";
import ProjectViewSideBar from "../../components/ProjectViewPage/ProjectViewSidebar/ProjectViewSidebar";
import ContentEditable from "react-contenteditable";
import CircleProgressBar from "../../components/CircleProgressBar/CircleProgress/CircleProgressBarBase";
import ManageFileData from "./ManageFileData";
import ManageMultimediaData from "./ManageMultimediaData";
import IntentAnalysis from "./Intent Analysis/IntentAnalysis";
import NavbarCustom from './Intent Analysis/Navbar';
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import $ from "jquery";
import DialogContent from "@material-ui/core/DialogContent";
import ButtonMUI from "../../components/UIComponents/Button/ButtonComponent";
import Form from "react-bootstrap/Form";
import DeleteModal from "../../components/UIComponents/DeleteModalComponent/DeleteModal";
import ImportFlowDrawer from "../../components/UI/Drawer/ImportFlowDrawer";
import AddEntityDrawer from "../../components/UI/Drawer/AddEntityDrawer";
import EntitiesComponent from "../../components/UI/Entities/EntitiesComponent";
import AddProjectFlowDrawer from "../../components/UI/Drawer/AddProjectFlowDrawer";
import AIGenDrawer from "../../components/UI/Drawer/AIGenDrawer";
import InputWithLabelComponent from "../SettingPage/SettingInnerPages/components/InputWithLabelComponent/InputWithLabelComponent";
import SelectComponent from "../../components/UIComponents/SelectDropdown/SelectComponent";
import TextAreaWithLabelComponent from "../SettingPage/SettingInnerPages/components/TextAreaWithLabelComponent/TextAreaWithLabelComponent";
import ButtonComponent from "../../components/UIComponents/Button/ButtonComponent";
import DndFlow from "./CoversationalUI/DndFlow";
import MacroDrawer from "../../components/UI/Drawer/MacroDrawer";
import FAQDrawerContainer from "./FAQDrawerContainer";
import Spinners from "../../components/UI/Loader/Loader";
import OtherDocumentDrawer from "../../components/UI/Drawer/OtherDocumentDrawer";
import ManageFileForms from "./ManageFileForms";
import KnowledgeTable from "../../containers/ProjectViewPage/KnowledgeTable";
import AddBotDrawer from "../../components/UI/Drawer/AddBotDrawer";
import DrawerComponent from "../../components/UIComponents/Drawer/DrawerComponent";
import BadgeComponent from "../../components/UIComponents/Badge/BadgeComponent";
import CDTrigger from './CoversationalUI/CDTrigger';
import SynonymsDataContainer from "./SynonymsDataContainer";
import SynonymsDrawerContainer from "./SynonymsDrawerContainer";
import { encryptAndStore } from "../../utility/utility";
import { PostCalltoServer } from "../../store/utility";
import CDFlowList from "./CoversationalUI/CDFlowList";
import CustomNavBar from "../../components/UI/Navbar/CustomNavBar";
let currentProject = null;
let botdeleted = false;
let botselected = "";
let clusertHtml = null;
let scorecount = 0;
let scoresum = 0;
let projectSum = 0;
let intentCount = 0;
let entitiesTraindata;
let filteredArrayList ;
let selectedNav = '';
class ProjectViewPage extends Component {
  botEditor = React.createRef();
  entityInput = React.createRef();
  state = {
    descp: false,
    disableButton:false,
    disableBtn:true,
    EntityOccurance: false,
    savedEditFlow: true,
    getData: false,
    showingAlert: false,
    mainTriggerEmptyAlert: null,
    showSavePopup: false,
    intentdelete: false,
    confirmIntentSave: false,
    entityshow: false,
    showFaqDocumentDrawer: false,
    showOtherDocumentDrawer: false,
    isFaqNavigation:false,
    isOtherDocsNavigation:false,
    entitytarget: null,
    flowValue: false,
    displayInfoShow1: false,
    dynamicSateValue: [],
    displayInfoShow: false,
    macrosUserValue: null,
    deleteScriptData: false,
    scriptData: false,
    projectAccuracy: null,
    formInputData: null,
    manual: "Manual",
    schedule: "",
    showTextBox: false,
    emptyOnEmailMsg: null,
    emptySchedulelMsg: null,
    emptyChatBotlMsg: null,
    emptyOtherBotsMsg: null,
    emptyTriggerTypeMsg: null,
    deleteProjectModalShow: false,
    deleteEntityModalShow: false,
    emptyCreateProfileMsg: null,
    deleteProfileModalShow: false,
    projectDescription:null,
    createProfileInput: {
      value: "",
    },
    configureUrlInput: {
      value: "",
    },
    urlMacrosInput: {
      value: "",
      theUrl: null,
      proxy: null,
      auth: null,
      port: null,
      data: null,
      contentType: null,
      h: null,
      s: null,
      defaultValue: null,
      log: null,
      displaydata: null,
      options: null,
      dv: null,
      client: null,
      isForm: null,
      title: null,
      ms: null,
    },

    OnEmailControls: {
      onEmailInput: {
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: "",
        },
        value: "",
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
    },

    ScheduleUserDefineControls: {
      scheduleUserDefineInput: {
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: "",
        },
        value: "",
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
    },
    ScheduleRadioControls: {
      scheduleRadioInput: {
        elementType: "input",
        elementConfig: {
          type: "radio",
          placeholder: "",
        },
        value: "",
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
        className: "",
      },
    },
    ChatBotControls: {
      chatBotInputs: {
        elementType: "select",
        elementConfig: {
          options: [{ value: "0", displayValue: "Select your option" }],
        },
        value: "0",
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
        className: "selectBox",
      },
    },
    OtherBotsControls: {
      otherBotsInputs: {
        elementType: "select",
        elementConfig: {
          options: [{ value: "0", displayValue: "Select your option" }],
        },
        value: "0",
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
        className: "selectBox",
      },
    },
    TriggerTypeControls: {
      triggerTypeInputs: {
        elementType: "select",
        elementConfig: {
          options: [
            { value: "0", displayValue: "Select Type" },
            { value: "Manual", displayValue: "Manual" },
            { value: "On Email", displayValue: "On Email" },
            { value: "Schedule", displayValue: "Schedule" },
            { value: "other Bots", displayValue: "other Bots" },
            { value: "ChatBot", displayValue: "ChatBot" },
          ],
        },
        value: "0",
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
        className: "selectLang",
      },
    },
    localbot: null,
    AddIntentcontrols: {
      intentName: {
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: "",
        },
        value: "",
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
        className: "",
        readOnly: true,
      },
      intentDesc: {
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: "",
        },
        value: "",
        validation: {
          required: false,
          //minLength: 6
        },
        valid: false,
        touched: false,
        className: "",
        readOnly: false,
      },
    },
    selected: "",
    selectedTab: "Manual",
    emptyIntentNameMsg: null,
    rows: [{}],
    displayError:false,
    reLearnRows: [{}],
    currentBotSelected: "",
    currentFileSelected: null,
    scriptSelected: false,
    ShowRightPanel: false,
    currentAceValue: "",
    setEntityShow: false,
    showSynonyms:false,
    deleteIntentShow: false,
    emptyQuestionMsg: null,
    emptyDefaultMsg: null,
    emptyValidationValueMsg: null,
    emptyValidationTypeMsg: null,
    emptyValidationMsg:null,
    emptyValidationNameMsg:null,
    emptydisplayMsg:false,
    emptyaddoptionMsg:false,
    validateOptionMsg: null,
    savebtnDisable:false,
    configuration: {
      entityName: "",
      urlName: "",
      validation: "",
      validationName: "",
      invalidEntity: "",
      question: "",
      entityType: "false",
      remember: "false",
      defaultValue: "",
      optionType: "-1",
      display: "-1",
      addOption: false,
      invalidDefault:true,
      validationText: "",
      addOptionValue: "",
      addOptionList: [],
      optList: [],
    },
    onEditEntityDisableSave:false,
    onEditAddBtnDisable:false,
    entity1: {
      entityName: "Input_01",
      urlName: "",
      validation: "",
      validationName: "",
      invalidEntity: "",
      question: "",
      entityType: "false",
      remember: "false",
      defaultValue: "",
      optionType: "-1",
      display: "-1",
      addOption: false,
      validationText: "",
      addOptionValue: "",
      addOptionList: [],
      optList: [],
      emptydisplayMsg:false,
    },
    entity2: {
      entityName: "Input_02",
      validation: "",
      validationName: "",
      invalidEntity: "",
      question: "",
      entityType: "false",
      remember: "false",
      defaultValue: "-1",
      optionType: "-1",
      display: "",
      addOption: false,
      validationText: "",
      addOptionValue: "",
      addOptionList: [],
      optList: [],
      emptydisplayMsg:false,
    },
    entity3: {
      entityName: "Input_03",
      validation: "",
      validationName: "",
      invalidEntity: "",
      question: "",
      entityType: "false",
      remember: "false",
      defaultValue: "",
      optionType: "-1",
      display: "-1",
      addOption: false,
      validationText: "",
      addOptionValue: "",
      addOptionList: [],
      optList: [],
      emptydisplayMsg:false,
    },
    entity4: {
      entityName: "Input_04",
      validation: "",
      validationName: "",
      invalidEntity: "",
      question: "",
      entityType: "false",
      remember: "false",
      defaultValue: "",
      optionType: "-1",
      display: "-1",
      addOption: false,
      validationText: "",
      addOptionValue: "",
      addOptionList: [],
      optList: [],
      emptydisplayMsg:false,
    },
    entity5: {
      entityName: "Input_05",
      validation: "",
      validationName: "",
      invalidEntity: "",
      question: "",
      entityType: "false",
      remember: "false",
      defaultValue: "",
      optionType: "-1",
      display: "-1",
      addOption: false,
      validationText: "",
      addOptionValue: "",
      addOptionList: [],
      optList: [],
      emptydisplayMsg:false,
    },
    indexValue: "1",
    submitEntity1: false,
    submitEntity2: false,
    submitEntity3: false,
    submitEntity4: false,
    submitEntity5: false,
    projectname: "",
    isFlowModalShow: false,
    isTriggerTypeModalShow: false,
    isConfigureModalShow: false,
    isProjectAccuracyModelShow: false,
    activeEntity: false,
    addIndex: false,
    scripttouched: false,
    scriptValue: null,

    AddFlowControls: {
      botname: {
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: "",
        },
        value: "",
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
        className: "",
        readOnly: true,
        defaultFocus: true,
      },
      botdescription: {
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: "",
        },
        value: "",
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
        className: "",
        readOnly: true,
      },
      botEntityInputs: {
        elementType: "select",
        elementConfig: {
          options: [
            { value: "-1", displayValue: "Select your inputs" },
            { value: "0", displayValue: "0" },
            { value: "1", displayValue: "1" },
            { value: "2", displayValue: "2" },
            { value: "3", displayValue: "3" },
            { value: "4", displayValue: "4" },
            { value: "5", displayValue: "5" },
          ],
        },
        value: "-1",
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
        className: "selectBox",
      },
    },
    entityColors: {
      one: "rgb(44, 153, 153)",
      two: "rgb(131, 100, 105)",
      three: "rgb(205, 155, 14)",
      four: "rgb(107, 116, 102)",
      five: "rgb(53, 79, 108)",
    },
    emptybotnameMsg: null,
    isKnowledgeContentSelected: true,
    selectedSubKnowledgeTab: "Manual",
    emptyFlowNameMsg: null,
    IsConnectModalShow: false,
    ImportConnectDataFile: null,
    ImportConnectDataFileName: null,
    clusterData: null,
    IshowMoreData: false,
    clusterArray: null,
    SelectedClusters: [],
    importClusterLoading: false,
    IsClusterImported: false,
    isProjectTranslation: false,
    translationdata: null,
    refreshKnowledgeIntent: false,
    importCLusterCount: {
      Total: null,
      completed: null,
    },
    KnowledgeMacroExpand: true,
    KnowledgeMacroSelected: "Custom Macros",
    custommacroShow: false,
    EditFlowExpand: true,
    selectedKnowledge: "Intents",
    displayUseContent: false,
    useMacroValue: null,
    __httpGet: {
      theUrl: "",
      proxy: "",
      port: "",
      auth: "",
    },
    __httpPost: {
      theUrl: "",
      data: "",
      contentType: "",
      proxy: "",
      port: "",
      auth: "",
    },
    prompt: {
      h: "",
      s: "",
      defaultValue: "",
      log: "",
    },
    display: {
      h: "",
      s: "",
      log: "",
    },
    wait: {
      ms: "",
    },
    __htmlView: {
      displayType: "",
      options: "",
      dv: "",
      client: "",
      isForm: "",
      title: "",
    },
    agentHover: {
      theUrl: "",
      proxy: "",
      port: "",
      auth: "",
    },
    ConditionA: {
      cndtType: "-1",
      ruleCndt: "-1",
      paramList: "-1",
      choiceList: "-1",
      paramType: "-1",
      cndParamType: [],
      compareValue: null,
      emptyValueMsg: null,
    },

    ConditionB: {
      cndtType: "-1",
      ruleCndt: "-1",
      paramList: "-1",
      choiceList: "-1",
      paramType: "-1",
      cndParamType: [],
      compareValue: null,
      emptyValueMsg: null,
    },

    isCUXBotSelected: false,
    selectedCUXBot: "",
    selectedCUXBotId: "",
    isFlowSelected: true,
    isFlowSelectedFlow: false,
    isBotModalShow: false,
    setImportCuxBotShow: false,
    cuxBotName: "",
    cuxBotTabSelected: "Designer",
    isCuxOrg: true,
    deleteCuxBotModalShow: false,
    deleteCuxBotModalData: "",
    isTriggerModalShow: false,

    AIGenShow: false,

    showTranslate: false,
    selectedLanguage: -1,
    showTranslateError: null,
    intentsList:[],
    searchT:"",
    popperShow:false,
    intentname:'',
    deleteBotModalData:null,
  };
  to_json = (workbook) => {
    var result = {};
    workbook.SheetNames.forEach(function (sheetName) {
      var roa = XLSX.utils.sheet_to_row_object_array(
        workbook.Sheets[sheetName]
      );
      if (roa.length) result[sheetName] = roa;
    });
    return JSON.stringify(result, 2, 2);
  };
   
    

  
  IsValidColumnsforKnowledgeIntentImport = (fileColumns) => {
    let fileColumnsArr = [];
    for (let key in fileColumns) {
      fileColumnsArr.push(key.trim().toLocaleLowerCase());
    }

    if (
      fileColumnsArr.find((x) => x == "uniqueid") &&
      fileColumnsArr.find((x) => x == "description") &&
      fileColumnsArr.find((x) => x == "response") &&
      fileColumnsArr.find((x) => x == "learn") &&
      fileColumnsArr.find((x) => x == "intent")
    ) {
      return true;
    } else return false;
  };

  ESBulkImport = (resp, boolval, importType) => {
    resp = this.ValidateScript(resp);
    let input = '{"bulkQuery":' + JSON.stringify(resp) + "}";
    if (importType == "ConnectData") {
      this.props.OnKnowledgeIntentImport(
        this.props.config.API_URL,
        input,
        "ConnectData"
      );
      this.setState({
        ImportConnectDataFileName: null,
        ImportConnectDataFile: null,
      });
    }
  };

  ValidateScript = (input) => {
    var reg = /<script\b[^>]*>([\s\S]*?)<\/script>/gm;
    if (reg.test(input) == true) {
      return input.replaceAll("<script>", "").replaceAll("</script>", "");
    } else {
      return input;
    }
  };

  updatePhrase = (index) => {
    let tempRow = [...this.state.rows];
      tempRow[index] = {...tempRow[index],type:'manual'};
      this.setState({
        rows: tempRow
      });
  }

  generatePhrase = () => {
    let item = {name:"Lorem ipsum dolor sit amet.", type:"AI"};
    this.setState({
      rows: [...this.state.rows, item],
      disableBtn:false
    });
  }

  handleAiGen = () => {

    this.setState({
      AIGenShow: true
    });
  }

  handleAddRow = () => {
    const item = {
      name: "",
      type: "manual"
    };
    if (
      this.state.currentBotSelected == "Intentlist" ||
      this.state.currentBotSelected == "unansweredbot" ||
      !this.props.AIStandard.RW
    ) {
      return true;
    } else {
      this.setState({
        rows: [...this.state.rows, item],
      });
    }
  };
  handleRemoveSpecificRow = (idx) => () => {
    if(!this.props.AIStandard.RW){
      return;
    }
    const rows = [...this.state.rows];
    if (rows.length > 0) {
      rows.splice(idx, 1);
      this.setState({ rows,disableBtn:false, });
    } else {
      this.setState({
        deleteIntentShow: true,disableBtn:true
        
      });
    }
  };

  deleteTriggerRows = (e) => {
    e.preventDefault();
    const rows = [...this.state.rows];
    rows.splice(0, 1);
    this.setState({ rows });
    this.setState({
      deleteIntentShow: false,
    });
  };

  handleRemoveReLearnSpecificRow = (idx) => () => {
    const rows = [...this.state.reLearnRows];
    rows.splice(idx, 1);
    this.setState({ reLearnRows: rows });
  };

  handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      event.target.blur();
      this.handleAddRow();
    }
  };

  handlePatterns = (idx) => (e) => {
    let value = e.target.value.replaceAll("&nbsp;", " ");
    if(/^\s/.test(value)|| !value.trim().length){
    this.setState({disableBtn:true})
    }

    else{
      this.setState({disableBtn:false})
    }
    let val = value.match('<span style="color:[^>]*">([^>]*)</span>');
    let vals = false;
    const rows = [...this.state.rows];
    if (val != null && val.length > 0) {
      let v = value.replace(/<span style="color:[^>]*">([^>]*)<\/span>/g, "$1");
      value = v;
    } else {
      let colors = this.state.entityColors;
      for (const key in colors) {
        if (
          value.includes(
            '<span style="background-color: ' + colors[key] + ';">'
          )
        ) {
          vals = true;
        }
      }
    }
    // if (vals == false) {
    //   let v = value.replace(/<[^>]+>/g, "");
    //   value = v;
    // }

    rows[idx] = {
      name: value,
    };
    this.setState({
      rows,
    });
  };

  onClickContentEditable = (event, idx) => {
    if (!window.getSelection().isCollapsed && this.state.currentBotSelected !== "unansweredbot" && this.state.currentBotSelected !== "Intentlist" && this.props.AIStandard.RW) {
      this.setState({
        anchorEl: event.currentTarget,
      });
    }
  };

  handleMenuClose = () => {
    this.setState({
      anchorEl: null,
    });
  };
  setSelected = (tab, updateCondition) => {
    let projectname = localStorage.getItem("projectname");
    let language = localStorage.getItem("language");
    let orgName = localStorage.getItem("orgName");
    if (!projectname) projectname = "common";

    if (!language) {
      language = "en";
    }
    if (tab === "Script") {
      this.setState({
        scriptSelected: true,
        ShowRightPanel: false,
        currentFileSelected: null,
        KnowledgeMacroExpand: true,
      });
      let data = {
        botname: this.state.currentBotSelected,
        project: projectname,
        language: language,
      };
      let data1 = {
        projectName: projectname,
        language: language,
        orgName: orgName,
        collection: "projectdata_collection",
      };
      this.openJSFile("bot.js");
      this.props.onsaveChatData(null, "chatReply,reply node");

    } else if (tab === "Train") {
      this.setState({ scriptSelected: false, ShowRightPanel: true });
    } else {
      this.setState({
        scriptSelected: false,
        ShowRightPanel: false,
        scriptData: false,
      });
      let data = {
        botname: this.state.currentBotSelected,
        project: projectname,
        language: language,
      };
      this.openJSFile("bot.js");
      this.props.onaddNode("");
    }
    this.props.onchangeTabAction(true);
    let docvalue = {
      projectname: projectname,
      language: language,
      botName: this.state.currentBotSelected,
    };
    if(this.state.submitImport!=true) this.props.OnGetDoc(docvalue, this.props.config.BOTBUILDER_URL);
    this.setState({ selected: tab ,submitImport:false});
    this.setState({ currentAceValue: "", scripttouched: false });
    if (updateCondition) {
      this.updateNodeState();
    }
  };

  checkTab = (tab, updateCondition) => {
    if (this.props.IsTabChanged) {
      this.setSelected(tab, updateCondition);
    } else {
      this.setState({
        showSavePopup: true,
      });
    }
  };

  checkCuxBotTab = (tab) => {
    this.setState({
      cuxBotTabSelected: tab,
    });
  };
  backToFlowList = () => {
    this.props.OnProjectHeaderShow(true);
    setTimeout(() => {
    this.setState({
      isCUXBotSelected: true,
      isFlowSelected: false,
    })
  },100);
  }
  backtoIntentList = () =>{
    this.props.onKnowledgeGridExpandCollapse("collapse")
    this.props.OnProjectHeaderShow(true)
    // this.checkSelectKnowledgeIntent(true, "Intents");
    // this.setState({
    //   isFlowSelected: false,
    //   ShowRightPanel: false,
    //   KnowledgeMacroExpand: false,
    //   isFlowSelectedFlow: false,
    // });
    setTimeout(() => {
      this.setState({
        isKnowledgeContentSelected: true,
        selectedKnowledge: "Intents",
        ShowRightPanel: false,
        currentBotSelected: "",
        refreshKnowledgeIntent: false,
        isCUXBotSelected: false,
        isFaqNavigation:false,
        isOtherDocsNavigation:false,
      });
    }, 1000);
    
    // this.setState({selectedKnowledge:"Intents",isKnowledgeContentSelected:true})
    // this.setState({
    //   isFlowSelected: true,
    //   isCUXBotSelected: false,
    // });
    // this.setState({editIntent:false,intentname:'',intentDescription:''})
  }

  setSearchTerm = (item) => {
    this.setState({searchT:item})
  }

  popperShowFn=()=>{
    this.setState({
      popperShow:true,
    })
  }
  intentclick =(event,intent)=>{
    this.setState({searchT:"",popperShow:false,intentname:intent})

  }
  flowSelection = (isbotselected, item, botvalue, newadded,intentList1,searchT,popperShow,intentname) => {
    this.setState({intentsList:intentList1,popperShow:popperShow,intentname:intentname})
    botdeleted = false;
    if (this.props.IsTabChanged) {
      this.currentBotEvent(isbotselected, item, botvalue, newadded);
      this.setState({
        isFlowSelected: true,
        KnowledgeMacroExpand: false,
        isCUXBotSelected: false
      });
    } else {
      this.setState({
        showSavePopup: true,
      });
    }
  };

  openFaqDocumentDrawer = () => {
    this.setState({ showFaqDocumentDrawer: true });
  };

  openSynonymsDrawer = () => {
    this.setState({ showSynonyms: true });
  };


  openOtherDocumentDrawer = () => {
    this.setState({ showOtherDocumentDrawer: true });
  };

  updateNodeState = () => {
    this.props.onUpdateCondition(this.state.ConditionA, "Condition A");
    this.props.onUpdateCondition(this.state.ConditionB, "Condition B");
    this.props.onsaveChatData(null, "ConditionB Chat node");
    this.props.onsaveChatData(null, "ConditionA Chat node");
    this.props.onsaveChatData(null, "chatReply,reply node");
  };
  updateVideoNodeState = () => {
    this.props.onUpdateVideo({
      title: "",
      url: "",
    });
  };
  setIntentdelete = () => {
    this.setState({ intentdelete: false });
  };

  setSelectedTab = (tab) => {
    this.setState({ selectedTab: tab });
  };
  setSelectedSubKnowledgeTab = (tab) => {
    this.setState({ selectedSubKnowledgeTab: tab });
  };
  setSelectedMacro = (tab) => {
    this.setState({ KnowledgeMacroSelected: tab });
  };
  inputChangedHandler = (event, controlName) => {
    if (
      this.state.currentBotSelected == "Intentlist" ||
      this.state.currentBotSelected == "unansweredbot" || !this.props.AIStandard.RW
    ) {
      return true;
    } else {
      const updatedControls = {
        ...this.state.AddIntentcontrols,
        [controlName]: {
          ...this.state.AddIntentcontrols[controlName],
          value: event.target.value,
          valid: this.checkValidity(
            event.target.value,
            this.state.AddIntentcontrols[controlName].validation
          ),
        },
      };
      this.setState({ AddIntentcontrols: updatedControls ,disableBtn:false});
      this.setValidationMessage(controlName, event.target.value);
    }
  };
  inputTriggerTypeChangedHandler = (event, id) => {
    if (id === "triggerTypeInputs") {
      this.setState({
        TriggerTypeControls: {
          ...this.state.TriggerTypeControls,
          triggerTypeInputs: {
            ...this.state.TriggerTypeControls.triggerTypeInputs,
            value: event.target.value,
          },
        },
      });
      var input = event.target.value;
      if (input == "0") {
        this.setState({ formInputData: "Manual" });
      }
      if (input == "Manual") {
        this.setState({ formInputData: "Manual" });
      } else if (input == "On Email") {
        this.setState({ formInputData: "OnEmail" });
      } else if (input == "Schedule") {
        this.setState({ formInputData: "Schedule" });
      } else if (input == "other Bots") {
        let projectname = localStorage.getItem("projectname");
        let language = localStorage.getItem("language");
        if (!language || language == "en") language = "en";
        let bots = null;
        let projectList = null;
        let botlist = {};
        let botsprofile = [];
        if (this.props.botList) {
          botlist = this.props.botList;
          let botEnglish = botlist[language];

          if (botEnglish) {
            if (botEnglish.hasOwnProperty(projectname)) {
              projectList = botlist[language][projectname];

              bots = Object.values(projectList);
              bots.map((item) => {
                botsprofile.push(item[0].profile);
              });
            }
          }
        }
        let options = [{ value: "0", displayValue: "select your option" }];
        botsprofile.forEach((option) =>
          options.push({ value: option, displayValue: option })
        );
        this.setState({
          OtherBotsControls: {
            ...this.state.OtherBotsControls,
            otherBotsInputs: {
              ...this.state.OtherBotsControls.otherBotsInputs,
              elementConfig: {
                ...this.state.OtherBotsControls.otherBotsInputs.elementConfig,
                options: options,
              },
            },
          },
        });
        this.setState({ formInputData: "otherBots" });
      } else if (input == "ChatBot") {
        let projectname = localStorage.getItem("projectname");
        let language = localStorage.getItem("language");
        if (!language || language == "en") language = "en";
        let bots = null;
        let projectList = null;
        let botlist = {};
        let botsprofile = [];
        let chatBotsValues = [];
        let splitedValue = [];
        if (this.props.botList) {
          botlist = this.props.botList;
          let botEnglish = botlist[language];

          if (botEnglish) {
            if (botEnglish.hasOwnProperty(projectname)) {
              projectList = botlist[language][projectname];
              bots = Object.values(projectList);
              bots.map((item) => {
                botsprofile.push(item[0].triggerType);
              });
            }
          }
        }
        for (var index = 0; index < botsprofile.length - 1; index++) {
          let chatBotArray = botsprofile[index].split("&&");
          splitedValue[index] = chatBotArray[1];
        }
        let options = [{ value: "0", displayValue: "select your option" }];
        splitedValue.forEach((option) =>
          options.push({ value: option, displayValue: option })
        );
        this.setState({
          ChatBotControls: {
            ...this.state.ChatBotControls,
            chatBotInputs: {
              ...this.state.ChatBotControls.chatBotInputs,
              elementConfig: {
                ...this.state.ChatBotControls.chatBotInputs.elementConfig,
                options: options,
              },
            },
          },
        });
        this.setState({ formInputData: "ChatBot" });
      }
    }
    this.setValidationMessage("triggerTypeInputs", event.target.value);
  };

  dynamicChangedHandler = (event) => {
    this.setState({ dynamicSateValue: event.target.value });
  };

  userValueChangedHandler = (event, names) => {
    this.setState({
      urlMacrosInput: {
        ...this.state.urlMacrosInput,
        [names]: event.target.value,
      },
    });
  };

  inputCreateProfileHandler = (event) => {
    this.setState({
      createProfileInput: {
        ...this.state.createProfileInput,
        value: event.target.value,
      },
    });
    this.setValidationMessage("CreateProfile", event.target.value);
  };

  inputConfigureUrlHandler = (event) => {
    this.setState({
      configureUrlInput: {
        ...this.state.configureUrlInput,
        value: event.target.value,
      },
    });
  };

  inputRadioChangedHandler = (event) => {
    if (event.target.value === "UserDefined") {
      this.setState({ showTextBox: true });
      this.setState({
        ScheduleRadioControls: {
          ...this.state.ScheduleRadioControls,
          scheduleRadioInput: {
            value: "",
          },
        },
      });
    } else {
      this.setState({ showTextBox: false });
    }
    if (event.target.value != "UserDefined") {
      this.setState({
        ScheduleRadioControls: {
          ...this.state.ScheduleRadioControls,
          scheduleRadioInput: {
            ...this.state.ScheduleRadioControls.scheduleRadioInput,
            value: event.target.value,
          },
        },
      });
    }
    this.setValidationMessage("Schedule", event.target.value);
  };

  inputEmailChangedHandler = (event) => {
    this.setState({
      OnEmailControls: {
        ...this.state.OnEmailControls,
        onEmailInput: {
          ...this.state.OnEmailControls.onEmailInput,
          value: event.target.value,
        },
      },
    });
    this.setValidationMessage("On Email", event.target.value);
  };
  inputScheduleUserDefineChangedHandler = (event) => {
    this.setState({
      ScheduleUserDefineControls: {
        ...this.state.ScheduleUserDefineControls,
        scheduleUserDefineInput: {
          ...this.state.ScheduleUserDefineControls.scheduleUserDefineInput,
          value: event.target.value,
        },
      },
    });
    this.setValidationMessage("UserDefine", event.target.value);
  };

  inputChatBotChangedHandler = (event, id) => {
    if (id === "chatBotInputs") {
      this.setState({
        ChatBotControls: {
          ...this.state.ChatBotControls,
          chatBotInputs: {
            ...this.state.ChatBotControls.chatBotInputs,
            value: event.target.value,
          },
        },
      });
    }
    this.setValidationMessage("ChatBot", event.target.value);
  };

  inputOtherBotsChangedHandler = (event, id) => {
    if (id === "otherBotsInputs") {
      this.setState({
        OtherBotsControls: {
          ...this.state.OtherBotsControls,
          otherBotsInputs: {
            ...this.state.OtherBotsControls.otherBotsInputs,
            value: event.target.value,
          },
        },
      });
    }
    this.setValidationMessage("other Bots", event.target.value);
  };

  inputFlowChangedHandler = (event, id) => {
    if (id === "botEntityInputs") {
      this.setState({
        AddFlowControls: {
          ...this.state.AddFlowControls,
          botEntityInputs: {
            ...this.state.AddFlowControls.botEntityInputs,
            value: event.target.value,
          },
        },
      });
    } else if (id === "botname" || id === "botdescription") {
      if (id === "botname") {
        let botExistAlready = false;
        let projectname = localStorage.getItem("projectname");
        let lang = localStorage.getItem("language");
        if (!projectname) projectname = "common";
        let objectKeys = Object.keys(this.props.botList);

          if (this.props.botList[lang][projectname] !== undefined) {
            var botobjkeys = Object.keys(
              this.props.botList[lang][projectname]
            );
            const updatedControls = {
              ...this.state.AddFlowControls,
              [id]: {
                ...this.state.AddFlowControls[id],
                value: event.target.value,
                defaultFocus: false,
              },
            };
           let duplicateFlowExists = botobjkeys.some((item)=>item.toLowerCase()===event.target.value.toLowerCase())
            if(duplicateFlowExists){
              this.setState({
                emptybotnameMsg: this.state.isCuxOrg?"Intent name already exists.":"Flow already exist.",
                disableButton:false,
                AddFlowControls: updatedControls
              });
              botExistAlready = true;
            }
           }

          if (!botExistAlready) {
            this.setState({ emptybotnameMsg: null });
            const updatedControls = {
              ...this.state.AddFlowControls,
              [id]: {
                ...this.state.AddFlowControls[id],
                value: event.target.value,
                defaultFocus: false,
              },
            };

            this.setState({ AddFlowControls: updatedControls });
            this.setValidationMessage(this.state.isCuxOrg?'cuxIntentName':id, event.target.value);
          } else {
            this.setState({
              emptybotnameMsg: this.state.isCuxOrg?"Intent name already exists.":"Flow already exists.",
            });
          }
          
      } else {
        const updatedControls = {
          ...this.state.AddFlowControls,
          [id]: {
            ...this.state.AddFlowControls[id],
            value: event.target.value,
            defaultFocus: false,
          },
        };

        this.setState({ AddFlowControls: updatedControls });
        this.setValidationMessage(id, event.target.value);
      }
    }
  };

  inputCuxBotChangedHandler = (botname) => {
    this.setState({ cuxBotName: botname });
    this.setValidationMessage('botname', botname);
    if(this.props.cuxBotList && this.props.cuxBotList.find((item)=>this.duplicateFlowCheck(item,botname))) {
      this.setState({
        emptybotnameMsg: "The name already exists as flow or template name",
      });
    }
  };

  checkValidity(value, rules) {
    let isValid = true;
    if (!rules) {
      return true;
    }

    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }
    return isValid;
  }
  setValidationMessage = (type, value) => {
    if (type === "intentName") {
      if (value.trim() === "") {
        this.setState({
          emptyIntentNameMsg: (
            <ValidationMsg>Intent Name is required</ValidationMsg>
          ),
        });
      } else if (!/^[A-Z0-9./_]+$/i.test(value)) {
        this.setState({
          emptyIntentNameMsg: (
            <ValidationMsg>Intent name should be alphanumeric</ValidationMsg>
          ),
        });
      } else {
        this.setState({ emptyIntentNameMsg: null });
      }
    } else if (type === "botname") {
      if (value.trim() === "") {
        this.setState({
          emptybotnameMsg: "Flow name is required.",
          disableButton:false,
        });
      } else if (!/^[A-Z0-9]+$/i.test(value)) {
        this.setState({
          emptybotnameMsg: "Flow name should be alphanumeric.",
          disableButton:false,
        });
      }
      else {
        this.setState({ emptybotnameMsg: null , disableButton:true});

      }
    } else if (type === 'cuxIntentName') {
      if (value.trim() === "") {
        this.setState({
          emptybotnameMsg: "Intent name is required.",
          disableButton:false
        });
      } else if (!/^[A-Z0-9]+$/i.test(value)) {
        this.setState({
          emptybotnameMsg: "Intent name should be alphanumeric.",
          disableButton:false,
        });
      } else {
        this.setState({ emptybotnameMsg: null, disableButton:true });
      }
    } else if (type === "question") {
      value.trim() === ""
        ? this.setState({
            emptyQuestionMsg: (
              <ValidationMsg>Question is required</ValidationMsg>
            ),
          })
        : this.setState({ emptyQuestionMsg: null });
    }else if (type === "invalidEntity") {
      value.trim() === ""
        ? this.setState({
            emptyValidationMsg: (
              <ValidationMsg>Validation message is required</ValidationMsg>
            ),
          })
        : this.setState({ emptyValidationMsg: null });
    }else if (type === "validation") {
      value.trim()===""
        ? this.setState({
            emptyValidationNameMsg:( 
            <ValidationMsg>Validation type is required</ValidationMsg>
            ),
          })
        : this.setState({ emptyValidationNameMsg: null });
    }else if (type === "validationText") {
      value.trim() === ""
        ? this.setState({
            emptyValidationTypeMsg: (
              <ValidationMsg>Validation name is required</ValidationMsg>
            ),
          })
        : this.setState({ 
          emptyValidationTypeMsg: null,
        });
    } else if (type === "ValidationValue") {
      value.trim() === ""
        ? this.setState({
            emptyValidationValueMsg: (
              <ValidationMsg>Validation expression is required</ValidationMsg>
            ),
          })
        : this.setState({ emptyValidationValueMsg: null });
    } else if (type === "triggerTypeInputs") {
      value.trim() === "0"
        ? this.setState({
            emptyTriggerTypeMsg: (
              <ValidationMsg>please select trigger type</ValidationMsg>
            ),
          })
        : this.setState({ emptyTriggerTypeMsg: null });
    } else if (type === "On Email") {
      value.trim() === ""
        ? this.setState({
            emptyOnEmailMsg: (
              <ValidationMsg>Enter the folder name</ValidationMsg>
            ),
          })
        : this.setState({ emptyOnEmailMsg: null });
    } else if (type === "Schedule") {
      value.trim() === ""
        ? this.setState({
            emptySchedulelMsg: (
              <ValidationMsg>select the schedule name</ValidationMsg>
            ),
          })
        : this.setState({ emptySchedulelMsg: null });
    } else if (type === "UserDefine") {
      value.trim() === ""
        ? this.setState({
            emptySchedulelMsg: <ValidationMsg>Enter cron value</ValidationMsg>,
          })
        : this.setState({ emptySchedulelMsg: null });
    } else if (type === "ChatBot") {
      value.trim() === "0"
        ? this.setState({
            emptyChatBotlMsg: (
              <ValidationMsg>select the chatbot name</ValidationMsg>
            ),
          })
        : this.setState({ emptyChatBotlMsg: null });
    } else if (type === "other Bots") {
      value.trim() === "0"
        ? this.setState({
            emptyOtherBotsMsg: (
              <ValidationMsg>select the otherbots name</ValidationMsg>
            ),
          })
        : this.setState({ emptyOtherBotsMsg: null });
    }else if (type === "flowDesc") {
      value.trim() === ""
        ? this.setState({
            emptyFlowNameMsg: (
              <ValidationMsg>Question is required</ValidationMsg>
            ),
          })
        : this.setState({ emptyFlowNameMsg: null });
    }else if (type === "createProfile") {
      if (value.trim() === "") {
        this.setState({
          emptyCreateProfileMsg: (
            <ValidationMsg>Profile name is required</ValidationMsg>
          ),
        });
      } else if (!/^[A-Z0-9./_]+$/i.test(value)) {
        this.setState({
          emptyCreateProfileMsg: (
            <ValidationMsg>Profile name should be alphanumeric</ValidationMsg>
          ),
        });
      } else {
        this.setState({ emptyCreateProfileMsg: null });
      }
    } else if (type === "translateLanguage") {
      value === -1
        ? this.setState({
            showTranslateError: (
              <ValidationMsg>Target Project Language is required</ValidationMsg>
            ),
          })
        : this.setState({ showTranslateError: null });
    }
  };
  getCurrentDate = () => {
    var currentdate = new Date();
    var dd = currentdate.getDate();
    var mm = currentdate.getMonth() + 1;
    var yyyy = currentdate.getFullYear();
    var hh = currentdate.getHours();
    var ms = currentdate.getMinutes();
    var sc = currentdate.getSeconds();

    if (dd < 10) dd = "0" + dd;

    if (mm < 10) mm = "0" + mm;

    if (hh < 10) hh = "0" + hh;

    if (ms < 10) ms = "0" + ms;

    if (sc < 10) sc = "0" + sc;

    var currentdate =
      yyyy + "/" + mm + "/" + dd + " " + hh + ":" + ms + ":" + sc;
    return currentdate;
  };

  submitHandler = (event) => {
    event.preventDefault();
    const { AddIntentcontrols, rows, currentBotSelected, reLearnRows } =
      this.state;
    let projectname = localStorage.getItem("projectname");
    let language = localStorage.getItem("language");
    let lang=''
    let collection = "optimus";
    if (!projectname) projectname = "common";

    if (!language || language == "en") {
      language = "en";
      collection = "optimus";
    } else {
      lang = language;
      collection = "optimus_" + lang;
    }

    var learn = "";
    let reLearn = "";
    let bgcolor = this.state.entityColors;
    {
      rows.forEach((item, idx) => {
        if (
          rows[idx].name.includes(
            '<span style="background-color: ' + bgcolor["one"] + ';">'
          ) ||
          rows[idx].name.includes(
            '<span style="background-color: ' + bgcolor["two"] + ';">'
          ) ||
          rows[idx].name.includes(
            '<span style="background-color: ' + bgcolor["three"] + ';">'
          ) ||
          rows[idx].name.includes(
            '<span style="background-color: ' + bgcolor["four"] + ';">'
          ) ||
          rows[idx].name.includes(
            '<span style="background-color: ' + bgcolor["five"] + ';">'
          )
        ) {
          learn = learn + rows[idx].name + "<br>";
        } else if (
          rows[idx].name.includes("<END>") ||
          rows[idx].name.includes("<START:one>") ||
          rows[idx].name.includes("<START:two>") ||
          rows[idx].name.includes("<START:three>") ||
          rows[idx].name.includes("<START:four>") ||
          rows[idx].name.includes("<START:five>")
        ) {
          learn = learn + rows[idx].name + "<br>";
        } else {
          learn = learn + rows[idx].name.replace(/<[^>]+>/g, "") + "<br>";
        }
        return true;
      });
    }
    learn = learn
      .replaceAll("<span", "<START")
      .replaceAll("<font", "<START")
      .replaceAll(
        'style="background-color: ' + bgcolor["one"] + ';">',
        ":one> "
      )
      .replaceAll(
        'style="background-color: ' + bgcolor["two"] + ';">',
        ":two> "
      )
      .replaceAll(
        'style="background-color: ' + bgcolor["three"] + ';">',
        ":three> "
      )
      .replaceAll(
        'style="background-color: ' + bgcolor["four"] + ';">',
        ":four> "
      )
      .replaceAll(
        'style="background-color: ' + bgcolor["five"] + ';">',
        ":five> "
      )
      .replaceAll("</span>", " <END> ")
      .replaceAll("</font>", " <END> ")
      .replaceAll("<p>", "")
      .replaceAll("</p>", "<br>")
      .replaceAll("<START ", "<START")
      .replaceAll("&nbsp;", " ");
    learn = learn.replaceAll(/<a[^>]*>x<\/a>/g, "");
    let learnData = [];
    learnData = learn.split("<br>");
    learnData = learnData.filter((item) => item);

    {
      reLearnRows.forEach(
        (item, idx) => (reLearn = reLearn + reLearnRows[idx].name + "<br>")
      );
    }
    reLearn = reLearn
      .replaceAll("<span", "<START")
      .replaceAll("<font", "<START")
      .replaceAll(
        'style="background-color: ' + bgcolor["one"] + ';">',
        ":one> "
      )
      .replaceAll(
        'style="background-color: ' + bgcolor["two"] + ';">',
        ":two> "
      )
      .replaceAll(
        'style="background-color: ' + bgcolor["three"] + ';">',
        ":three> "
      )
      .replaceAll(
        'style="background-color: ' + bgcolor["four"] + ';">',
        ":four> "
      )
      .replaceAll(
        'style="background-color: ' + bgcolor["five"] + ';">',
        ":five> "
      )
      .replaceAll("</span>", " <END> ")
      .replaceAll("</font>", " <END> ")
      .replaceAll("<p>", "")
      .replaceAll("</p>", "<br>")
      .replaceAll("<START ", "<START")
      .replaceAll("&nbsp;", " ");
    reLearn = reLearn.replaceAll(/<a[^>]*>x<\/a>/g, "");
    let reLearnData = [];
    reLearn = reLearn.replace("undefined", "");
    reLearnData = reLearn.split("<br>");
    reLearnData = reLearnData.filter((item) => item);
    this.setValidationMessage("intentName", AddIntentcontrols.intentName.value);
    if (
      AddIntentcontrols.intentName.value &&
      /^[A-Z0-9./_]+$/i.test(AddIntentcontrols.intentName.value)
    ) {
      let intentName = AddIntentcontrols.intentName.value;
      let intentDesp = AddIntentcontrols.intentDesc.value;
      let intentList = {
        botName: currentBotSelected,
        profileName: intentDesp,
        value: "ChatBot&&" + intentName,
        project: projectname,
        language: language,
        triggerType: "chatbot",
      };
      let pattern = {
        project: projectname,
        language: language,
        botName: intentName,
        intentTraining: { learn: learnData, relearn: [" "] },
        description: intentDesp,
      };
      if (learn == "") {
        var learn = [];
        let pattern1 = {
          project: projectname,
          language: language,
          botName: intentName,
          intentTraining: { learn: learn, relearn: [" "] },
          description: intentDesp,
        };
        this.props.onUpdateIntent(pattern1, this.props.config.BOTBUILDER_URL);
      }
      this.props.onUpdateIntent(pattern, this.props.config.BOTBUILDER_URL);
    }
  };

  currentBotEvent = (isbotselected, item, botvalue, newadded) => {
    if (isbotselected == false) {
      if (botselected != "") item = botselected;
    }
    if(this.state.submitImport!=true){
      this.setState({ isKnowledgeContentSelected: false, selectedKnowledge: "",disableBtn:true });
    }
    
    let botContent = botvalue.split("__");
    botContent = botContent[1];
    if (botContent === "Intentlistprofile") {
      botContent = "Initiate correct flow for the user query";
    } else if (botContent === "unansweredprofile") {
      botContent = "Appropriate response for unanswered query";
    }
    this.setState({
      AddIntentcontrols: {
        ...this.state.AddIntentcontrols,
        intentName: {
          ...this.state.AddIntentcontrols.intentName,
          value: item,
        },
        intentDesc: {
          ...this.state.AddIntentcontrols.intentDesc,
          value: botContent,
        },
      },
      emptyIntentNameMsg: null,
    });
    this.setState({ selected: "Train", selectedTab: "Manual" });
    let projectname = localStorage.getItem("projectname");
    let language = localStorage.getItem("language");
    let lang='';
    let collection = "optimus";
    if (!projectname) projectname = "common";

    if (!language || language == "en") {
      language = "en";
      collection = "optimus";
    } else {
      lang = language;
      collection = "optimus_" + lang;
    }
    this.props.ongetEntity(
      { language: language, projectname: projectname, botName: item },
      this.props.config.BOTBUILDER_URL
    );
    let docvalue = {
      projectname: projectname,
      language: language,
      botName: item,
    };
    this.props.OnGetDoc(docvalue, this.props.config.BOTBUILDER_URL);
    this.setState({ currentBotSelected: item, ShowRightPanel: true,isFaqNavigation:false,isOtherDocsNavigation:false, });
    if (newadded) {
      this.updateNodeState();
      this.updateVideoNodeState();
    }
    this.props.onaddNode("");
  };

  clearIntent() {
    this.setState({
      AddIntentcontrols: {
        ...this.state.AddIntentcontrols,
        intentName: {
          ...this.state.AddIntentcontrols.intentName,
          value: "",
        },
        intentDesc: {
          ...this.state.AddIntentcontrols.intentDesc,
          value: "",
        },
      },
      emptyIntentNameMsg: null,
      rows: [{ name: "" }],
    });
  }
  saveBotProfile = (event) => {
    let projectname = localStorage.getItem("projectname");
    let language = localStorage.getItem("language");
    let intentList = {
      botnatme: this.state.currentBotSelected,
      project: projectname,
      language: language,
    };
    if (!language) {
      language = "en";
    }
    if (!projectname) projectname = "common";
    let data = {
      botName: this.state.currentBotSelected,
      data: this.botEditor.editor.getValue(),
      project: projectname,
      language: language,
    };
    this.props.onsaveBotProfile(data, this.props.config.BOTBUILDER_URL);
  };
  saveMacroFileData = (event) => {
    event.preventDefault();
    let projectname = localStorage.getItem("projectname");
    if (!projectname) projectname = "common";
    let language = localStorage.getItem("language");
    if (!language || language == "en") language = "en";
    let data = {
      path:
        this.state.currentBotSelected + "//" + this.state.currentFileSelected,
      data: this.botEditor.editor.getValue(),
      project: projectname,
      language: "en",
    };
    let data1 = {
      projectName: projectname,
      language: localStorage.getItem("language"),
      orgName: "default",
      collection: "projectdata_collection",
    };
    this.props.onsaveMacroFileData(this.props.config.API_URL, data);
    this.handleClose("custommacro");
  };

  clearStateVariables = () => {
    this.props.OnSaveMacrosData(this.props.config.API_URL, "");
    if (this.state.macrosUserValue === "__httpGet") {
      this.setState({
        __httpGet: {
          theUrl: "",
          proxy: "",
          port: "",
          auth: "",
        },
      });
    } else if (this.state.macrosUserValue === "__httpPost") {
      this.setState({
        __httpPost: {
          theUrl: "",
          data: "",
          contentType: "",
          proxy: "",
          port: "",
          auth: "",
        },
      });
    } else {
      return;
    }
  };

  runMacroData = (event) => {
    event.preventDefault();
    let data;
    let url = null;
    let proxy = null;
    let port = null;
    let auth = null;
    let dataPost = null;
    let contentType = null;
    let h = null;
    let s = null;
    let defaultValue = null;
    let log = null;
    let displayType = null;
    let options = null;
    let client = null;
    let isForm = null;
    let dv = null;
    let title = null;
    let ms = null;
    if (this.state.macrosUserValue === "__httpGet") {
      if (this.state.__httpGet.theUrl) {
        url = this.state.__httpGet.theUrl;
      }
      if (this.state.__httpGet.proxy) {
        proxy = this.state.__httpGet.proxy;
      }
      if (this.state.__httpGet.port) {
        port = this.state.__httpGet.port;
      }
      if (this.state.__httpGet.auth) {
        auth = this.state.__httpGet.auth;
      }
      let code;
      if (
        url !== null &&
        url !== "" &&
        proxy !== null &&
        proxy !== "" &&
        port !== null &&
        port !== "" &&
        auth !== null &&
        auth !== ""
      ) {
        code = `${this.state.macrosUserValue}MacrosTest('${url}','${proxy}','${port}','${auth}')`;
      } else {
        return null;
      }
      data = { code: code };
      this.setState({ flowValue: true });
      this.props.OnSaveMacrosData(this.props.config.API_URL, data);
    } else if (this.state.macrosUserValue === "__httpPost") {
      if (this.state.__httpPost.theUrl) {
        url = this.state.__httpPost.theUrl;
      }
      if (this.state.__httpPost.data) {
        dataPost = this.state.__httpPost.data;
      }
      if (this.state.__httpPost.contentType) {
        contentType = this.state.__httpPost.contentType;
      }
      if (this.state.__httpPost.proxy) {
        proxy = this.state.__httpPost.proxy;
      }
      if (this.state.__httpPost.port) {
        port = this.state.__httpPost.port;
      }
      if (this.state.__httpPost.auth) {
        auth = this.state.__httpPost.auth;
      }
      let code;
      if (
        url !== null &&
        url !== "" &&
        dataPost !== null &&
        dataPost !== "" &&
        contentType !== null &&
        contentType !== "" &&
        proxy !== null &&
        proxy !== "" &&
        port !== null &&
        port !== "" &&
        auth !== null &&
        auth !== ""
      ) {
        code = `${this.state.macrosUserValue}MacrosTest('${url}','${dataPost}','${contentType}','${proxy}','${port}','${auth}')`;
      } else {
        return null;
      }
      data = { code: code };
      this.setState({ flowValue: true });
      this.props.OnSaveMacrosData(this.props.config.API_URL, data);
    } else if (this.state.macrosUserValue === "prompt") {
      if (this.state.prompt.h) {
        h = this.state.prompt.h;
      }
      if (this.state.prompt.s) {
        s = this.state.prompt.s;
      }
      if (this.state.prompt.defaultValue) {
        defaultValue = this.state.prompt.defaultValue;
      }
      if (this.state.prompt.log) {
        log = '"' + this.state.prompt.log + '"';
      }
      let code = `${this.state.macrosUserValue}(${h},${s},${defaultValue},${log})`;
      data = { code: code };
    } else if (this.state.macrosUserValue === "display") {
      if (this.state.display.h) {
        h = this.state.display.h;
      }
      if (this.state.display.s) {
        s = this.state.display.s;
      }
      if (this.state.display.log) {
        log = this.state.display.log;
      }
      let code = `${this.state.macrosUserValue}(${h},${s},${log})`;
      data = { code: code };
    } else if (this.state.macrosUserValue === "__htmlView") {
      if (this.state.__htmlView.displayType) {
        displayType = this.state.__htmlView.displayType;
      }
      if (this.state.__htmlView.options) {
        options = this.state.__htmlView.options;
      }
      if (this.state.__htmlView.dv) {
        dv = this.state.__htmlView.dv;
      }
      if (this.state.__htmlView.client) {
        client = this.state.__htmlView.client;
      }
      if (this.state.__htmlView.isForm) {
        isForm = this.state.__htmlView.isForm;
      }
      if (this.state.__htmlView.title) {
        title = this.state.__htmlView.title;
      }
      let code = `${this.state.macrosUserValue}(${displayType},${options},${dv},${client},${isForm},${title})`;
      data = { code: code };
    } else if (this.state.macrosUserValue === "wait") {
      if (this.state.wait.ms) {
        ms = this.state.wait.ms;
      }
      let code = `${this.state.macrosUserValue}(${ms})`;
      data = { code: code };
    }
  };

  handledropdownMacros = (event, headernames) => {
    event.preventDefault();
    let names = "select_" + headernames;
    const { value } = event.target;
    if (this.state.macrosUserValue == "__httpGet") {
      this.setState({
        __httpGet: {
          ...this.state.__httpGet,
          [names]: value,
        },
      });
    } else if (this.state.macrosUserValue == "__httpPost") {
      this.setState({
        __httpPost: {
          ...this.state.__httpPost,
          [names]: value,
        },
      });
    } else if (this.state.macrosUserValue == "prompt") {
      this.setState({
        prompt: {
          ...this.state.prompt,
          [names]: value,
        },
      });
    } else if (this.state.macrosUserValue == "display") {
      this.setState({
        display: {
          ...this.state.display,
          [names]: value,
        },
      });
    } else if (this.state.macrosUserValue == "__htmlView") {
      this.setState({
        __htmlView: {
          ...this.state.__htmlView,
          [names]: value,
        },
      });
    } else if (this.state.macrosUserValue == "wait") {
      this.setState({
        wait: {
          ...this.state.wait,
          [names]: value,
        },
      });
    } else if (this.state.macrosUserValue == "agent hover") {
      this.setState({
        agentHover: {
          ...this.state.agentHover,
          [names]: value,
        },
      });
    }
  };

  handleUseMacros = (event, headernames) => {
    event.preventDefault();
    const { name, value } = event.target;
    if (this.state.macrosUserValue == "__httpGet")
      this.setState({
        __httpGet: {
          ...this.state.__httpGet,
          [headernames]: value,
        },
      });
    else if (this.state.macrosUserValue == "__httpPost")
      this.setState({
        __httpPost: {
          ...this.state.__httpPost,
          [headernames]: value,
        },
      });
    else if (this.state.macrosUserValue == "prompt")
      this.setState({
        prompt: {
          ...this.state.prompt,
          [headernames]: value,
        },
      });
    else if (this.state.macrosUserValue == "display")
      this.setState({
        display: {
          ...this.state.display,
          [headernames]: value,
        },
      });
    else if (this.state.macrosUserValue == "__htmlView")
      this.setState({
        __htmlView: {
          ...this.state.__htmlView,
          [headernames]: value,
        },
      });
    else if (this.state.macrosUserValue == "wait")
      this.setState({
        wait: {
          ...this.state.wait,
          [headernames]: value,
        },
      });
    else if (this.state.macrosUserValue == "agent hover")
      this.setState({
        agentHover: {
          ...this.state.agentHover,
          [headernames]: value,
        },
      });
  };

  saveUseData = (event) => {
    event.preventDefault();
    let parseData = "";
    if (
      this.props.getMacrosFileData != undefined &&
      this.props.getMacrosFileData != null
    ) {
      parseData = JSON.parse(this.props.getMacrosFileData);
    }
    let prevtext = this.botEditor.editor.getValue();
    let useValue = null;
    let paramValuesAppend = "";
    $("#paramTable tr").each(function () {
      let paramValue = $(this).find("td").eq(1).children().val();
      if (paramValue == "") paramValue = '""';
      if (paramValuesAppend == "") paramValuesAppend += paramValue;
      else if (paramValuesAppend != "") paramValuesAppend += "," + paramValue;
    });
    useValue = `${this.state.useMacroValue}(${paramValuesAppend});`;
    this.setState({
      currentAceValue:
        prevtext + this.botEditor.editor.setValue("\n" + useValue, 1),
    });
    this.handleClose("useContent");
  };
  onChangeAce(newValue) {
    if (
      this.state.currentBotSelected == "Intentlist" || !this.props.AIStandard.RW
    ) {
      this.setState({ scripttouched: false });
    } else {
      this.setState({ currentAceValue: newValue, scripttouched: true });
    }
  }
  openJSFile = (text, fromKnowledgeIntent) => {
    let projectname = localStorage.getItem("projectname");
    let language = localStorage.getItem("language");

    if (!language) {
      language = "en";
    }
    if (!projectname) projectname = "common";
    this.setState({ currentFileSelected: text });
    let data = {
      botName: fromKnowledgeIntent ? "faq" : this.state.currentBotSelected,
      projectname: projectname,
      language: language,
      // file: text,
    };
    this.props.ongetBotFile(data, this.props.config.BOTBUILDER_URL);
  };

  handleClose = (modaltype, redirection) => {
    if (modaltype === "entity") {
      this.setState({ setEntityShow: false, EntityOccurance: false,emptyaddoptionMsg:false,
        emptydisplayMsg:true,validateOptionMsg:null,displayError:false,onEditEntityDisableSave:false,onEditAddBtnDisable:false});
    } else if (modaltype === "otherDocument") {
      if (redirection)
        this.setState({
          showOtherDocumentDrawer: false,
          isKnowledgeContentSelected: true,
          isCUXBotSelected: false,
          ShowRightPanel: false,
          isFlowSelected: false,
          selectedKnowledge: "OtherDocs",
          currentBotSelected: "",
          getData: true,
          isOtherDocsNavigation:true,
        });
      else this.setState({ showOtherDocumentDrawer: false });
      $(".dropdownClass  .dropdown-item").removeClass("active");
    } else if (modaltype === "faqDocument") {
      if (redirection)
        this.setState({
          showFaqDocumentDrawer: false,
          ShowRightPanel: false,
          isKnowledgeContentSelected: true,
          isCUXBotSelected: false,
          isFlowSelected: false,
          selectedKnowledge: "Knowledge",
          currentBotSelected: "",
          isFaqNavigation:true,
          isOtherDocsNavigation:false,
        });
       else this.setState({ showFaqDocumentDrawer: false });

      $(".dropdownClass  .dropdown-item").removeClass("active");
    } else if (modaltype === "Synonyms & Acronyms") {
      if (redirection)
        this.setState({
          showSynonyms: false,
          ShowRightPanel: false,
          isKnowledgeContentSelected: true,
          isCUXBotSelected: false,
          isFlowSelected: false,
          selectedKnowledge: "Synonyms & Acronyms",
          currentBotSelected: "",
          isFaqNavigation:false,
          isSynonymsNavigation:true,
          isOtherDocsNavigation:false,
        });
       else this.setState({ showSynonyms: false });
      $(".dropdownClass  .dropdown-item").removeClass("active");
    }else if (modaltype === "FlowModal") {
      this.setState({ isFlowModalShow: false, emptybotnameMsg:'', disableButton:false, });
      $(".dropdownClass  .dropdown-item").removeClass("active");
    } else if (modaltype == "deleteBotModal") {
      this.setState({ deleteBotModalShow: false });
    } else if (modaltype == "deleteEntityModal") {
      this.setState({ deleteEntityModalShow: false });
    } else if (modaltype == "ImportModal") {
      this.setState({ setImportFlowShow: false, setImportCuxBotShow: false, filevalidation: null });
      this.clearUploadProjectStateValue();
      $(".dropdownClass  .dropdown-item").removeClass("active");
    } else if (modaltype == "triggerTypeModal") {
      this.clearTriggerTypeStateValue();
      this.setState({ isTriggerTypeModalShow: false });
    } else if (modaltype == "createProfileModal") {
      this.clearProfileStateValue();
      this.setState({ isCreateProfileModalShow: false });
    } else if (modaltype == "configureModal") {
      this.closeEntities();
      this.setState({ isConfigureModalShow: false });
    } else if (modaltype == "displayInfo") {
      this.setState({ displayInfoShow: false });
      this.clearStateVariables();
    } else if (modaltype == "useContent") {
      this.setState({ displayUseContent: false });
    } else if (modaltype == "ConnectModalShow") {
      this.setState({
        IsConnectModalShow: false,
        SelectedClusters: [],
        importCLusterCount: {
          ...this.state.importCLusterCount,
          completed: null,
          Total: null,
        },
      });
    } else if (modaltype == "projectAccuracyModal") {
      this.setState({ isProjectAccuracyModelShow: false });
    } else if (modaltype == "projectTranslation") {
      this.setState({ isProjectTranslation: false, translationdata: null });
    } else if (modaltype == "custommacro") {
      this.setState({ custommacroShow: false });
    } else if (modaltype == "deleteProjectModal") {
      this.setState({ deleteProjectModalShow: false });
      $(".dropdownCustomClass  .dropdown-item").removeClass("active");
    } else if (modaltype == "deleteProfileModal") {
      this.setState({ deleteProfileModalShow: false });
    } else if (modaltype == "deleteScriptDataModal") {
      this.setState({ deleteScriptData: false });
    } else if (modaltype == "displayInfo1") {
      this.setState({ displayInfoShow1: false });
    } else if (modaltype == "deleteIntentModal") {
      this.setState({ deleteIntentShow: false, deleteScriptData: false });
    } else if (modaltype == "deleteIntentSaveModal") {
      this.setState({
        confirmIntentSave: false,
      });
    } else if (modaltype == "showSavePopup") {
      this.setState({
        showSavePopup: false,
      });
    } else if (modaltype === "CuxBotModal") {
      this.setState({ isBotModalShow: false });
      $(".dropdownClass  .dropdown-item").removeClass("active");
      this.setState({
        cuxBotName : '',
        emptybotnameMsg: '',
      });
    } else if (modaltype === "showTranslate") {
      this.setState({ showTranslate: false });
    } else if (modaltype == "deleteCuxBotModal") {
      this.setState({ deleteCuxBotModalShow: false });
    } else if (modaltype == "CuxTriggerModal") {
      this.setState({ isTriggerModalShow: false });
    } else if (modaltype == "AIGen") {
      this.setState({ AIGenShow: false });
    }
  };
  showDrawer = (status) => {
    this.setState({
      showTranslate: status,
      selectedLanguage: -1,
      showTranslateError: null,
    });
    $(".dropdownCustomClass  .dropdown-item").removeClass("active");
  };
  clearScriptStateValue = () => {
    let projectname = localStorage.getItem("projectname");
    let language = localStorage.getItem("language");
    let data = {
      botname: this.state.currentBotSelected,
      project: projectname,
      language: language,
    };
    this.openJSFile("bot.js");
    let botProfileFile = this.props.botProfileFileData;
    this.setState({ currentAceValue: botProfileFile, scripttouched: false });
  };
  clearTrainStateValue = () => {
    let intentObj = "";
    let projectname = localStorage.getItem("projectname");
    let language = localStorage.getItem("language");
    let lang='';
    let collection = "optimus";
    if (!projectname) projectname = "common";

    if (!language || language == "en") {
      language = "en";
      collection = "optimus";
    } else {
      lang = language;
      collection = "optimus_" + lang;
    }
    let bot = this.state.currentBotSelected + "__";
    let docvalue = {
      projectname: projectname,
      language: language,
      botName: this.state.currentBotSelected,
    };
    this.props.OnGetDoc(docvalue, this.props.config.BOTBUILDER_URL);
    this.populateManualData();
  };

  populateManualData = () => {
    let getDocArr;
    let learn = [];
    if (this.props.docData) {
      getDocArr = this.props.docData;
      if (getDocArr.training.learn) {
        learn = getDocArr.training.learn.split("\n");
        let patterns = [];
        learn.map((item, idx) => {
          return item !== "" && item !== "undefined"
            ? patterns.push({ name: item })
            : patterns;
        });
        if (patterns.length === 0) {
          patterns = [{ name: "" }];
        }
        this.setState({
          rows: patterns,
          AddIntentcontrols: {
            ...this.state.AddIntentcontrols,
            intentDesc: {
              ...this.state.AddIntentcontrols.intentDesc,
              value: getDocArr.description,
            },
          },
        });
      }
    } else {
      this.setState({
        rows: [{ name: "" }],
      });
    }
  };

  showEntities(item, name) {
    if (
      this.state.currentBotSelected == "unansweredbot" || this.state.currentBotSelected == "Intentlist" || !this.props.AIStandard.RW
    ) {
    
    this.setState({
      setEntityShow: false,
    });
  }
  else{

  
    this.props.onreadFile(
      { fileName: "validationTypes.json", project: "", language: "en" },
      this.props.config.BOTBUILDER_URL
    );
    if (item === "") {
      this.setState({
        EntityOccurance: false,
        setEntityShow: true,
        activeEntity: false,
        selectedBotList: "",
        selectedEntity: "",
        emptyDefaultMsg: null,
        emptyQuestionMsg: null,
        validateOptionMsg: null,
        emptyValidationValueMsg: null,
        emptyValidationTypeMsg: null,
        emptyValidationMsg:null,
        emptyValidationNameMsg:null,
        emptydisplayMsg:false,
        addIndex: false,
        onEditEntityDisableSave:false,
      });
      this.clearAddFlowEntitiesValue();
      this.setState({
        anchorEl: null,
      });
      return true;
    }
    let sample = Object.values(item.Entities);
    this.setState({
      selectedBotList: item.Entities,
      selectedEntity: name,
    });
    let list = "";
    list = sample.filter(function (item) {
      return item.Type === name;
    });
    list = list[0];
    let opt = [];
    let optList = [];
    let addOption = false;
    if (list.questionoptiontype == "addoption") {
      addOption = true;
      if (list.options != "undefined" && list.options != undefined) {
        optList = list.options;
        list.options.map((x) => {
          opt.push(x["option"]);
        });
      }
    }
    this.setState({
      setEntityShow: true,
      activeEntity: true,
      EntityOccurance: true,
      indexValue: "1",
      entity1: {
        invalidEntity: list.ValidationFailResponse,
        entityName: list.Type,
        question: list.Question,
        defaultValue: list.defaultValue,
        validation: Object.values(list.Validation)[0],
        validationName: Object.keys(list.Validation)[0],
        entityType: list.isEntityOptional,
        remember: list.rememberEntity,
        optionType: list.questionoptiontype,
        display: list.displaytype,
        addOption: addOption,
        optList: optList,
        addOptionList: opt,
      },
    });
  }
}
  handleShow = () => {
    this.setState({ setImportProjectShow: true });
  };
  saveaddOption = (e, entity) => {
    e.preventDefault();
    const { entity1, entity2, entity3, entity4, entity5 } = this.state;

    if (entity == "1") {
      if (entity1.validation) {
        let textValues = entity1.addOptionValue;
        let listArray = entity1.addOptionList;
        let optLists = [];
          if (listArray.indexOf(textValues?.toLowerCase()) == -1)
            listArray.push(textValues);
          if (listArray.length > 0) {
            listArray.forEach((item) => {
              return optLists.push({ option: item });
            });
          }   
          this.setState({
            entity1: {
              ...entity1,
              addOptionList: listArray,
              addOptionValue: "",
              optList: optLists,
            },
          });
          
        }
        else{
            this.setState({
            entity1: {
              ...entity1,
              addOptionValue: "",
            },
          });
        }

    } else if (entity == "2") {
      if (entity2.validation) {
        let textValues = entity2.addOptionValue;
        let listArray = entity2.addOptionList;
        let optLists = [];
          if (listArray.indexOf(textValues.toLowerCase()) == -1)
            listArray.push(textValues);
          if (listArray.length > 0) {
            listArray.forEach((item) => {
              return optLists.push({ option: item });
            });
          }
          this.setState({
            entity2: {
              ...entity2,
              addOptionList: listArray,
              addOptionValue: "",
              optList: optLists,
            },
          });
        } else {
          this.setState({
            entity2: {
              ...entity2,
              addOptionValue: "",
            },
          });
        }
    } else if (entity == "3") {
      if (entity3.validation) {
        let textValues = entity3.addOptionValue;
        let listArray = entity3.addOptionList;
        let optLists = [];
          if (listArray.indexOf(textValues.toLowerCase()) == -1)
            listArray.push(textValues);
          if (listArray.length > 0) {
            listArray.forEach((item) => {
              return optLists.push({ option: item });
            });
          }
          this.setState({
            entity3: {
              ...entity3,
              addOptionList: listArray,
              addOptionValue: "",
              optList: optLists,
            },
          });
        } else {
          this.setState({
            entity3: {
              ...entity3,
              addOptionValue: "",
            },
          });
        }
      
    } else if (entity == "4") {
      if (entity4.validation) {
        let textValues = entity4.addOptionValue;
        let listArray = entity4.addOptionList;
        let optLists = [];
          if (listArray.indexOf(textValues.toLowerCase()) == -1)
            listArray.push(textValues);
          if (listArray.length > 0) {
            listArray.forEach((item) => {
             return optLists.push({ option: item });
            });
          }
          this.setState({
            entity4: {
              ...entity4,
              addOptionList: listArray,
              addOptionValue: "",
              optList: optLists,
            },
          });
        } else {
          this.setState({
            entity4: {
              ...entity4,
              addOptionValue: "",
            },
          });
        }
      
    } else {
      if (entity5.validation) {
        let textValues = entity5.addOptionValue;
        let listArray = entity5.addOptionList;
        let optLists = [];
          if (listArray.indexOf(textValues.toLowerCase()) == -1)
            listArray.push(textValues);
          if (listArray.length > 0) {
            listArray.forEach((item) => {
              return optLists.push({ option: item });
            });
          }
          this.setState({
            entity5: {
              ...entity5,
              addOptionList: listArray,
              addOptionValue: "",
              optList: optLists,
            },
          });
        } else {
          this.setState({
            entity5: {
              ...entity5,
              addOptionValue: "",
            },
          });
        }
    }
  };
  deleteAddOptions = (item, entity) => {
    const { entity1, entity2, entity3, entity4, entity5 } = this.state;
    if (entity == "1") {
      let listArray = entity1.addOptionList;
      let index = listArray.indexOf(item);
      let optLists = [];
      if (index > -1) {
        listArray.splice(index, 1);
        listArray.forEach((item) => {
         return optLists.push({ option: item });
        });
      }
      this.setState({
        entity1: {
          ...entity1,
          addOptionList: listArray,
          addOptionValue: "",
          optList: optLists,
        },
      });
    } else if (entity == "2") {
      let listArray = entity2.addOptionList;
      let index = listArray.indexOf(item);
      let optLists = [];
      if (index > -1) {
        listArray.splice(index, 1);
      }
      if (listArray.length > 0) {
        listArray.forEach((item) => {
         return optLists.push({ option: item });
        });
      }
      this.setState({
        entity2: {
          ...entity2,
          addOptionList: listArray,
          addOptionValue: "",
          optList: optLists,
        },
      });
    } else if (entity == "3") {
      let listArray = entity3.addOptionList;
      let index = listArray.indexOf(item);
      let optLists = [];
      if (index > -1) {
        listArray.splice(index, 1);
      }
      if (listArray.length > 0) {
        listArray.forEach((item) => {
         return optLists.push({ option: item });
        });
      }
      this.setState({
        entity3: {
          ...entity3,
          addOptionList: listArray,
          addOptionValue: "",
          optList: optLists,
        },
      });
    } else if (entity == "4") {
      let listArray = entity4.addOptionList;
      let index = listArray.indexOf(item);
      let optLists = [];
      if (index > -1) {
        listArray.splice(index, 1);
      }
      if (listArray.length > 0) {
        listArray.forEach((item) => {
         return optLists.push({ option: item });
        });
      }
      this.setState({
        entity4: {
          ...entity4,
          addOptionList: listArray,
          addOptionValue: "",
          optList: optLists,
        },
      });
    } else {
      let listArray = entity5.addOptionList;
      let index = listArray.indexOf(item);
      let optLists = [];
      if (index > -1) {
        listArray.splice(index, 1);
      }
      if (listArray.length > 0) {
        listArray.forEach((item) => {
        return  optLists.push({ option: item });
        });
      }
      this.setState({
        entity5: {
          ...entity5,
          addOptionList: listArray,
          addOptionValue: "",
          optList: optLists,
        },
      });
    }
    this.setState({
      emptyaddoptionMsg:true,
    })
  };
  handleEntities(e, entity) {
    e.preventDefault();
    const { name, value, options, selectedIndex } = e.target;
    const { entity1, entity2, entity3, entity4, entity5 } = this.state;
    let projectname = localStorage.getItem("projectname");
    if (!projectname) projectname = "common";
    this.setState({
      onEditEntityDisableSave:true,
    })
    if (entity == "1") {
      this.setState({
        entity1: {
          ...entity1,
          addOption: false,
        },
      });
      if (name == "validation") {
        if (value == "") {
          this.setState({
            entity1: {
              ...entity1,
              validationName: "",
              [name]: value,
              addOption: false,
              defaultValue:'',
              addOptionValue:'',
              addOptionList:[]          
              },
          });
        } else {
          if (value == "custom") {
            this.setState({
              entity1: {
                ...entity1,
                validationName: "",
                [name]: "",
                validationText: value,
                defaultValue:'',
                addOptionValue:'',
                addOptionList:[]              
              },
            });
          } else {
            this.setState({
              entity1: {
                ...entity1,
                validationName: options[selectedIndex].innerHTML,
                [name]: value,
                validationText: "",
                defaultValue:'',
                addOptionValue:'',
                addOptionList:[]              
              },
            });
          }
          return true;
        }
      }
      if(name=="display"||name=="optionType"){
        if(value=='-1'){
          this.setState({
            ...entity1,
            emptydisplayMsg:true
          })
        }
        else{
          this.setState({
            ...entity1,
            emptydisplayMsg:false
          })
        }
      }
      if(name=="optionType"){
        if(value=='-1'){
        this.setState({
          displayError:false
        })
        }
      }
      if(name=="addOptionValue"){
        if(value.length!=0){
          this.setState({
            displayError:false
          })
        }
      }
      if (name == "optionType") {
        if (value == "addoption") {
          this.setState({
            entity1: {
              ...entity1,
              addOption: true,
              display:'-1',
              [name]: value,
              addOptionValue:"",
            },
            emptyaddoptionMsg:false,
            validateOptionMsg:null,
          });
        }
         else if (value == "form") {
          this.setState({
            entity1: {
              ...entity1,
              [name]: value,
              addOption: false,
              display: value,
              optList: [{ option: "" }],
            },
          });
        } else if (value == "password") {
          this.setState({
            entity1: {
              ...entity1,
              [name]: value,
              addOption: false,
              display: value,
              optList: [],
            },
          });
        } else if (value == "confirmation") {
          this.setState({
            entity1: {
              ...entity1,
              [name]: value,
              addOption: false,
              optList: [{ option: "yes" }, { option: "no" }],
            },
          });
        } else {
          this.setState({
            entity1: {
              ...entity1,
              [name]: value,
              addOption: false,
              display: value,
            },
          });
        }
      } else {
        if (entity1.validationText == "custom") {
          if (name == "validationText") {
            this.setState({
              entity1: {
                ...entity1,
                validationName: value,
              },
            });
          } else if (name == "ValidationValue") {
            this.setState({
              entity1: {
                ...entity1,
                validation: value,
              },
            });
          } else {
            this.setState({
              entity1: {
                ...entity1,
                [name]: value,
              },
            });
          }
        } else if(name=="addOptionValue"){
        let regExp = new RegExp(entity1.validation);
        let res = regExp.test(value);
        let val=/\s+/g.test(value)
        if (res&&!val) {
          this.setState({
            entity1:{
              ...entity1,
              [name]:value,
            },
          onEditAddBtnDisable:true,
          validateOptionMsg:null,
        })
        }
        else if(value.length==0){
          this.setState({
            entity1:{
              ...entity1,
              [name]:"",
            },
          validateOptionMsg:null,
        })
        }
        else{
          this.setState({
            validateOptionMsg: ( <ValidationMsg>Option does not match the validation expression</ValidationMsg>),
          entity1: {
            ...entity1,
            [name]:value,
          },
        });
      }
      }
        else if (name == "defaultValue") {
          let regExp = new RegExp(entity1.validation);
          let res = regExp.test(value);
          let val=/\s+/g.test(value);
          this.setState({
            entity1: {
              ...entity1,
              [name]: value,
            },
          });
          if(value.length==0){
            this.setState({
            displayError:false,
            filteredArrayList:false,
            emptyDefaultMsg:null,
            savebtnDisable:false
          })
        }
        if(value.length!=0){
          if (!res||val) {
            this.setState({
              emptyDefaultMsg: (<ValidationMsg>Default value does not match the validation expression</ValidationMsg>),
              savebtnDisable:true
            })
          }
          else {
            this.setState({
              emptyDefaultMsg: null,
              savebtnDisable:false
            });
          }
        }
      } else {
          this.setState({
            entity1: {
              ...entity1,
              [name]: value,
            },
          });
        }
      }
      if (this.state.entity1.question && this.state.entity1.defaultValue) {
        let isValidationTypeExist = false;
        let isValidationRegexExist = false;
        let isValidRegex = true;
        if (entity1.validationText == "custom") {
          if (entity1.validationName && entity1.validation) {
            let fileData = [];
            if (this.props.fileData) {
              fileData = JSON.parse(this.props.fileData);
              fileData = fileData.preValidationList;

              try {
                new RegExp(entity1.validation);
              } catch (e) {
                isValidRegex = false;
              }

              for (var i = 0; i < fileData.length; i++) {
                if (Object.keys(fileData[i])[0] == entity1.validationName) {
                  isValidationTypeExist = true;
                  break;
                } else if (
                  fileData[i][Object.keys(fileData[i])[0]] == entity1.validation
                ) {
                  isValidationRegexExist = true;
                  break;
                }
              }
              if (isValidationTypeExist) {
                this.setState({
                  emptyValidationTypeMsg: (
                    <ValidationMsg>Validation Type already exist</ValidationMsg>
                  ),
                });
              } else if (isValidationRegexExist) {
                this.setState({
                  emptyValidationValueMsg: (
                    <ValidationMsg>
                      Validation value already exist
                    </ValidationMsg>
                  ),
                });
              } else if (!isValidRegex) {
                this.setState({
                  emptyValidationValueMsg: (
                    <ValidationMsg>Please enter valid regex</ValidationMsg>
                  ),
                });
              } else {
                fileData.push({ [entity1.validationName]: entity1.validation });
                let validatioTypesObjString = JSON.stringify({
                  preValidationList: fileData,
                });
                let data = {
                  fileName: "validationTypes.json",
                  content: escape(validatioTypesObjString),
                  project: projectname,
                  language: "en",
                };
                this.setState({ submitEntity1: true });
              }
            }
          }
        } else this.setState({ submitEntity1: true });
      } else {
        this.setState({ submitEntity1: false });
      }
    } else if (entity == "2") {
      if (name == "validation") {
        if (value == "") {
          this.setState({
            entity2: {
              ...entity2,
              validationName: "",
              [name]: value,
              defaultValue:'',
              addOptionValue:'',
              addOptionList:[]            
            },
          });
        } else {
          if (value == "custom") {
            this.setState({
              entity2: {
                ...entity2,
                validationName: "",
                [name]: "",
                defaultValue:'',
                addOptionValue:'',
                addOptionList:[],
                validationText: value,
              },
            });
          } else {
            this.setState({
              entity2: {
                ...entity2,
                validationName: options[selectedIndex].innerHTML,
                [name]: value,
                defaultValue:'',
                addOptionValue:'',
                addOptionList:[],
                validationText: "",
              },
            });
          }
          return true;
        }
      }
      if(name=="display"||name=="optionType"){
        if(value=='-1'){
          this.setState({
            ...entity2,
            emptydisplayMsg:true
          })
        }
        else{
          this.setState({
            ...entity2,
            emptydisplayMsg:false
          })
        }
      }
      if(name=="optionType"){
        if(value=='-1'){
        this.setState({
          displayError:false
        })
        }
      }
      if(name=="addOptionValue"){
        if(value.length!=0){
          this.setState({
            displayError:false
          })
        }
      }
      if (name == "optionType") {
        if (value == "addoption") {
          this.setState({
            entity2: {
              ...entity2,
              addOption: true,
               display:'-1',
              [name]: value,
              addOptionValue:"",
            },
            emptyaddoptionMsg:false,
            validateOptionMsg:null,
          });
        } else if (value == "form") {
          this.setState({
            entity2: {
              ...entity2,
              [name]: value,
              addOption: false,
              display: value,
              optList: [{ option: "" }],
            },
          });
        } else if (value == "password") {
          this.setState({
            entity2: {
              ...entity2,
              [name]: value,
              addOption: false,
              display: value,
              optList: [],
            },
          });
        } else if (value == "confirmation") {
          this.setState({
            entity2: {
              ...entity2,
              [name]: value,
              addOption: false,
              optList: [{ option: "yes" }, { option: "no" }],
            },
          });
        } else {
          this.setState({
            entity2: {
              ...entity2,
              [name]: value,
              addOption: false,
            },
          });
        }
      } else {
        if (entity2.validationText == "custom") {
          if (name == "validationText") {
            this.setState({
              entity2: {
                ...entity2,
                validationName: value,
              },
            });
          } else if (name == "ValidationValue") {
            this.setState({
              entity2: {
                ...entity2,
                validation: value,
              },
            });
          } else {
            this.setState({
              entity2: {
                ...entity2,
                [name]: value,
              },
            });
          }
        }else if(name=="addOptionValue"){

          let regExp = new RegExp(entity2.validation);
          let res = regExp.test(value);
          let val=/\s+/g.test(value)
          if (res&&!val) {
            this.setState({
              entity2:{
                ...entity2,
                [name]:value,
              },
            onEditAddBtnDisable:true,
            validateOptionMsg:null
          })
          }
          else if(value.length==0){
            this.setState({
              entity2:{
                ...entity2,
                [name]:"",
              },
            validateOptionMsg:null
          })
          }
          else{
            this.setState({
              validateOptionMsg: ( <ValidationMsg>Option does not match the validation expression</ValidationMsg>),
            entity2: {
              ...entity2,
              [name]:value,
            },
          });
        }
        }
         else if (name == "defaultValue") {
          let regExp = new RegExp(entity2.validation);
          let res = regExp.test(value);
          let val=/\s+/g.test(value)
          this.setState({
            entity2: {
              ...entity2,
              [name]: value,
            },
          });
          if(value.length==0){
            this.setState({
            displayError:false,
            filteredArrayList:false,
            emptyDefaultMsg:null,
            savebtnDisable:false
          })
        } if(value.length!=0){
          if (!res||val) {
            this.setState({
                  emptyDefaultMsg: ( <ValidationMsg>Default value does not match the validation expression</ValidationMsg>),
                  savebtnDisable:true
                 })
                }
                else{
                  this.setState({
                    emptyDefaultMsg:null,
                    savebtnDisable:false
                  });
                }
               }
              }
              else{
                this.setState({
                    entity2: {
                        ...entity2,
                        [name]: value
                    }
                });
              }
             }
             if(entity2.question && entity2.defaultValue){
              let isValidationTypeExist = false;
              let isValidationRegexExist = false;
              let isValidRegex = true;
              if(entity2.validationText =="custom"){
                if(entity2.validationName && entity2.validation){
                  let fileData = [];
                  if(this.props.fileData){
                      fileData=  JSON.parse(this.props.fileData)
                      fileData=fileData.preValidationList;
                    
                      try {
                            new RegExp(entity2.validation);
                      } catch(e) {
                         isValidRegex = false;
                      }
                     
                      for(var i=0; i<fileData.length; i++){
                        if(Object.keys(fileData[i])[0]==entity2.validationName){
                           isValidationTypeExist=true;
                           break;
                        }else if(fileData[i][Object.keys(fileData[i])[0]]==entity2.validation){
                           isValidationRegexExist=true;
                           break;
                        }
                      }
                      if(isValidationTypeExist){
                        this.setState( { emptyValidationTypeMsg: ( <ValidationMsg>Validation Type already exist</ValidationMsg>) } ) 
                     }else if(isValidationRegexExist){
                      this.setState( { emptyValidationValueMsg: ( <ValidationMsg>Validation value already exist</ValidationMsg>) } )
                     }else if(!isValidRegex){
                      this.setState( { emptyValidationValueMsg: ( <ValidationMsg>Please enter valid regex</ValidationMsg>) } )
                     }else{
                      fileData.push({[entity2.validationName]:entity2.validation})
                      let validatioTypesObjString = JSON.stringify({"preValidationList":fileData});
                      let data={fileName: "validationTypes.json","content":escape(validatioTypesObjString),"project":projectname,"language":"en"}
                      this.setState({submitEntity2:true})
                     }
                  }
                
                }
              }
              else
                this.setState({submitEntity2:true})
             }
            else{
                this.setState({submitEntity2:false})
            }
          }
         else if(entity =="3"){
          if(name=="validation")
          {   
              if(value==''){
                  this.setState({
                      entity3: {
                          ...entity3,
                          "validationName":'',
                          [name]: value,
                          defaultValue:'',
                          addOptionValue:'',
                          addOptionList:[]                    
                         }
                  }); 
              }
              else{
              if(value=="custom"){
                this.setState({
                  entity3: {
                      ...entity3,
                      "validationName":'',
                      [name]: '',
                      "validationText":value,
                      defaultValue:'',
                      addOptionValue:'',
                      addOptionList:[]               
                       }
              });
              }
              else{
              this.setState({
                  entity3: {
                      ...entity3,
                      "validationName":options[selectedIndex].innerHTML,
                      [name]: value,
                      "validationText":'',
                      defaultValue:'',
                      addOptionValue:'',
                      addOptionList:[]                
                      }
              });
              }
              return true
          }
        }
        if(name=="display"||name=="optionType"){
          if(value=='-1'){
            this.setState({
              ...entity3,
              emptydisplayMsg:true
            })
          }
          else{
            this.setState({
              ...entity3,
              emptydisplayMsg:false
            })
          }
        }
        if(name=="optionType"){
          if(value=='-1'){
          this.setState({
            displayError:false
          })
          }
        }
        if(name=="addOptionValue"){
          if(value.length!=0){
            this.setState({
              displayError:false
            })
          }
        }
			if(name == "optionType"){
               if(value == "addoption"){
                    this.setState({
                        entity3: {
                            ...entity3,
                           addOption:true,
                           display:'-1',
                            [name]:value,
                            addOptionValue:"",
                            

                        },
                        emptyaddoptionMsg:false,
                        validateOptionMsg:null,
                    });
                }
                else if(value=="form"){
                  this.setState({
                    entity3: {
                        ...entity3,
                        [name]:value,
                        addOption:false,
                        display:value,
                        optList:[{"option":""}]
                    }
                })
                }
                else if(value=="password"){
                  this.setState({
                    entity3: {
                        ...entity3,
                        [name]:value,
                        addOption:false,
                        display:value,
                        optList:[]
                    }
                })
                }
                else if(value=="confirmation"){
                  this.setState({
                    entity3: {
                        ...entity3,
                        [name]:value,
                        addOption:false,
                        optList:[{"option":"yes"},{"option":"no"}]
                    }
                })
                }
                else {
                    this.setState({
                        entity3: {
                            ...entity3,
                            [name]:value,
                            addOption:false
                        }
                    })
                }
            }
            else{
              if(entity3.validationText =="custom"){
                if(name =="validationText"){
                  this.setState({
                    entity3: {
                        ...entity3,
                        "validationName": value
                    }
                });
                }
               else if(name =="ValidationValue"){
                  this.setState({
                    entity3: {
                        ...entity3,
                        "validation": value
                    }
                });
                }
                else{
                  this.setState({
                      entity3: {
                          ...entity3,
                          [name]: value
                      }
                  });
                }
              }else if(name=="addOptionValue"){

                let regExp = new RegExp(entity3.validation);
                let res = regExp.test(value);
                let val=/\s+/g.test(value)
                if (res&&!val) {
                  this.setState({
                    entity3:{
                      ...entity3,
                      [name]:value,
                    },
                  onEditAddBtnDisable:true,
                  validateOptionMsg:null
                })
                }
                else if(value.length==0){
                  this.setState({
                    entity3:{
                      ...entity3,
                      [name]:"",
                    },
                  validateOptionMsg:null
                })
                }
                else{
                  this.setState({
                    validateOptionMsg: ( <ValidationMsg>Option does not match the validation expression</ValidationMsg>),
                  entity3: {
                    ...entity3,
                    [name]:value,
                  },
                });
              }
              }
             else if(name =="defaultValue"){
                let regExp = new RegExp(entity3.validation);
                let res = regExp.test(value);
                let val=/\s+/g.test(value)
                this.setState({
                  entity3: {
                      ...entity3,
                      [name]: value
                  }
                });
                if(value.length==0){
                  this.setState({
                  displayError:false,
                  filteredArrayList:false,
                  emptyDefaultMsg:null,
                  savebtnDisable:false
                })
              }
              if(value.length!=0){
                if(!res||val) {
                 this.setState( { 
                  emptyDefaultMsg: ( <ValidationMsg>Default value does not match the validation expression</ValidationMsg>),
                  savebtnDisable:true
                 })
                }
                else{
                  this.setState({
                    emptyDefaultMsg:null,
                    savebtnDisable:false
                  });
                }
               }
              }
              else{
                this.setState({
                    entity3: {
                        ...entity3,
                        [name]: value
                    }
                });
              }
             }
            if(entity3.question && entity3.defaultValue){
              let isValidationTypeExist = false;
              let isValidationRegexExist = false;
              let isValidRegex = true;
              if(entity3.validationText =="custom"){
                if(entity3.validationName && entity3.validation){
                  let fileData = [];
                  if(this.props.fileData){
                      fileData=  JSON.parse(this.props.fileData)
                      fileData=fileData.preValidationList;
                    
                      try {
                            new RegExp(entity3.validation);
                      } catch(e) {
                         isValidRegex = false;
                      }
                     
                      for(var i=0; i<fileData.length; i++){
                        if(Object.keys(fileData[i])[0]==entity3.validationName){
                           isValidationTypeExist=true;
                           break;
                        }else if(fileData[i][Object.keys(fileData[i])[0]]==entity3.validation){
                           isValidationRegexExist=true;
                           break;
                        }
                      }
                      if(isValidationTypeExist){
                        this.setState( { emptyValidationTypeMsg: ( <ValidationMsg>Validation Type already exist</ValidationMsg>) } ) 
                     }else if(isValidationRegexExist){
                      this.setState( { emptyValidationValueMsg: ( <ValidationMsg>Validation value already exist</ValidationMsg>) } )
                     }else if(!isValidRegex){
                      this.setState( { emptyValidationValueMsg: ( <ValidationMsg>Please enter valid regex</ValidationMsg>) } )
                     }else{
                      fileData.push({[entity3.validationName]:entity3.validation})
                      let validatioTypesObjString = JSON.stringify({"preValidationList":fileData});
                      let data={fileName: "validationTypes.json","content":escape(validatioTypesObjString),"project":projectname,"language":"en"}
                      this.setState({submitEntity3:true})
                     }
                  }
                
                }
              }
              else
                this.setState({submitEntity3:true})
            }
           else{
               this.setState({submitEntity3:false})
           }
          }
          else if(entity =="4"){
            if(name=="validation")
            {   
                if(value==''){
                    this.setState({
                        entity4: {
                            ...entity4,
                            "validationName":'',
                            [name]: value,
                            defaultValue:'',
                            addOptionValue:'',
                            addOptionList:[]                            
                        }
                    }); 
                }
                else{
                if(value=="custom"){
                  this.setState({
                    entity4: {
                        ...entity4,
                        "validationName":'',
                        [name]: '',
                        "validationText":value,
                        defaultValue:'',
                        addOptionValue:'',
                        addOptionList:[]
                        }
                });
                }
                else{
                this.setState({
                    entity4: {
                        ...entity4,
                        "validationName":options[selectedIndex].innerHTML,
                        [name]: value,
                        "validationText":'',
                        defaultValue:'',
                        addOptionValue:'',
                        addOptionList:[]
                       }
                });
                }
                return true
            }
          }
          if(name=="display"||name=="optionType"){
            if(value=='-1'){
              this.setState({
                ...entity4,
                emptydisplayMsg:true
              })
            }
            else{
              this.setState({
                ...entity4,
                emptydisplayMsg:false
              })
            }
          }
          if(name=="optionType"){
            if(value=='-1'){
            this.setState({
              displayError:false
            })
            }
          }
          if(name=="addOptionValue"){
            if(value.length!=0){
              this.setState({
                displayError:false
              })
            }
          }
			if(name == "optionType"){
               if(value == "addoption"){
                    this.setState({
                        entity4: {
                            ...entity4,
                           addOption:true,
                           display:'-1',
                            [name]:value,
                            addOptionValue:""

                        },
                        emptyaddoptionMsg:false,
                        validateOptionMsg:null,
                    });
                }
                else if(value=="form"){
                  this.setState({
                    entity4: {
                        ...entity4,
                        [name]:value,
                        addOption:false,
                        display:value,
                        optList:[{"option":""}]
                    }
                })
                }
                else if(value=="password"){
                  this.setState({
                    entity4: {
                        ...entity4,
                        [name]:value,
                        addOption:false,
                        display:value,
                        optList:[]
                    }
                })
                }
                else if(value=="confirmation"){
                  this.setState({
                    entity4: {
                        ...entity4,
                        [name]:value,
                        addOption:false,
                        optList:[{"option":"yes"},{"option":"no"}]
                    }
                })
                }
                else {
                    this.setState({
                        entity4: {
                            ...entity4,
                            [name]:value,
                            addOption:false
                        }
                    })
                }
            }
            else{
              if(entity4.validationText =="custom"){
                if(name =="validationText"){
                  this.setState({
                    entity4: {
                        ...entity4,
                        "validationName": value
                    }
                });
                }
               else if(name =="ValidationValue"){
                  this.setState({
                    entity4: {
                        ...entity4,
                        "validation": value
                    }
                });
                }
                else{
                  this.setState({
                      entity4: {
                          ...entity4,
                          [name]: value
                      }
                  });
                }
              }else if(name=="addOptionValue"){

                let regExp = new RegExp(entity4.validation);
                let res = regExp.test(value);
                let val=/\s+/g.test(value)
                if (res&&!val) {
                  this.setState({
                    entity4:{
                      ...entity4,
                      [name]:value,
                    },
                  onEditAddBtnDisable:true,
                  validateOptionMsg:null
                })
                }
                else if(value.length==0){
                  this.setState({
                    entity4:{
                      ...entity4,
                      [name]:"",
                    },
                  validateOptionMsg:null
                })
                }
                else{
                  this.setState({
                    validateOptionMsg: ( <ValidationMsg>Option does not match the validation expression</ValidationMsg>),
                  entity4: {
                    ...entity4,
                    [name]:value,
                  },
                });
              }
              }
             else if(name =="defaultValue"){
                let regExp = new RegExp(entity4.validation);
                let res = regExp.test(value);
                let val=/\s+/g.test(value)
                this.setState({
                  entity4: {
                      ...entity4,
                      [name]: value
                  }
                });
                if(value.length==0){
                  this.setState({
                  displayError:false,
                  filteredArrayList:false,
                  emptyDefaultMsg:null,
                  savebtnDisable:false
                })
              }
              if(value.length!=0){
                if(!res||val) {
                 this.setState( { 
                  emptyDefaultMsg: ( <ValidationMsg>Default value does not match the validation expression</ValidationMsg>),
                  savebtnDisable:true
                 })
                }
                else{
                  this.setState({
                    emptyDefaultMsg:null,
                    savebtnDisable:false
                  });
                }
               }
              }
              else{
                this.setState({
                    entity4: {
                        ...entity4,
                        [name]: value
                    }
                });
              }
             }
         if(entity4.question && entity4.defaultValue){
          let isValidationTypeExist = false;
          let isValidationRegexExist = false;
          let isValidRegex = true;
          if(entity4.validationText =="custom"){
            if(entity4.validationName && entity4.validation){
              let fileData = [];
              if(this.props.fileData){
                  fileData=  JSON.parse(this.props.fileData)
                  fileData=fileData.preValidationList;
                
                  try {
                        new RegExp(entity4.validation);
                  } catch(e) {
                     isValidRegex = false;
                  }
                 
                  for(var i=0; i<fileData.length; i++){
                    if(Object.keys(fileData[i])[0]==entity4.validationName){
                       isValidationTypeExist=true;
                       break;
                    }else if(fileData[i][Object.keys(fileData[i])[0]]==entity4.validation){
                       isValidationRegexExist=true;
                       break;
                    }
                  }
                  if(isValidationTypeExist){
                    this.setState( { emptyValidationTypeMsg: ( <ValidationMsg>Validation Type already exist</ValidationMsg>) } ) 
                 }else if(isValidationRegexExist){
                  this.setState( { emptyValidationValueMsg: ( <ValidationMsg>Validation value already exist</ValidationMsg>) } )
                 }else if(!isValidRegex){
                  this.setState( { emptyValidationValueMsg: ( <ValidationMsg>Please enter valid regex</ValidationMsg>) } )
                 }else{
                  fileData.push({[entity4.validationName]:entity4.validation})
                  let validatioTypesObjString = JSON.stringify({"preValidationList":fileData});
                  let data={"filePath":"Bots/libs/validationTypes.json","content":escape(validatioTypesObjString),"project":projectname,"language":"en"}
                  this.setState({submitEntity4:true})
                 }
              }
            }
          }
          else
            this.setState({submitEntity4:true})
         }
         else{
             this.setState({submitEntity4:false})
         }
          }
          else{
            if(name=="validation")
            {   
                if(value==''){
                    this.setState({
                        entity5: {
                            ...entity5,
                            "validationName":'',
                            [name]: value,
                            defaultValue:'',
                            addOptionValue:'',
                            addOptionList:[]                            
                        }
                    }); 
                }
                else{
                if(value=="custom"){
                  this.setState({
                    entity5: {
                        ...entity5,
                        "validationName":'',
                        [name]: '',
                        "validationText":value,
                        defaultValue:'',
                        addOptionValue:'',
                        addOptionList:[]                        
                    }
                });
                }
                else{
                this.setState({
                    entity5: {
                        ...entity5,
                        "validationName":options[selectedIndex].innerHTML,
                        [name]: value,
                        "validationText":'',
                        defaultValue:'',
                        addOptionValue:'',
                        addOptionList:[]
                        }
                });
                }
                return true
            }
          }
          if(name=="display"||name=="optionType"){
            if(value=='-1'){
              this.setState({
                ...entity5,
                emptydisplayMsg:true
              })
            }
            else{
              this.setState({
                ...entity5,
                emptydisplayMsg:false
              })
            }
          }
          if(name=="optionType"){
            if(value=='-1'){
            this.setState({
              displayError:false
            })
            }
          }
          if(name=="addOptionValue"){
            if(value.length!=0){
              this.setState({
                displayError:false
              })
            }
          }
			if(name == "optionType"){
               if(value == "addoption"){
                    this.setState({
                        entity5: {
                            ...entity5,
                           addOption:true,
                           display:'-1',
                            [name]:value,
                            addOptionValue:"",

                        },
                        emptyaddoptionMsg:false,
                        validateOptionMsg:null,
                    });
                }
                else if(value=="form"){
                  this.setState({
                    entity5: {
                        ...entity5,
                        [name]:value,
                        addOption:false,
                        display:value,
                        optList:[{"option":""}]
                    }
                })
                }
                else if(value=="password"){
                  this.setState({
                    entity5: {
                        ...entity5,
                        [name]:value,
                        addOption:false,
                        display:value,
                        optList:[]
                    }
                })
                }
                else if(value=="confirmation"){
                  this.setState({
                    entity5: {
                        ...entity5,
                        [name]:value,
                        addOption:false,
                        optList:[{"option":"yes"},{"option":"no"}]
                    }
                })
                }
                else {
                    this.setState({
                        entity5: {
                            ...entity5,
                            [name]:value,
                            addOption:false
                        }
                    })
                }
            }
            else{
              if(entity5.validationText =="custom"){
                if(name =="validationText"){
                  this.setState({
                    entity5: {
                        ...entity5,
                        "validationName": value
                    }
                });
                }
               else if(name =="ValidationValue"){
                  this.setState({
                    entity5: {
                        ...entity5,
                        "validation": value
                    }
                });
                }
                else{
                  this.setState({
                      entity5: {
                          ...entity5,
                          [name]: value
                      }
                  });
                }
              }else if(name=="addOptionValue"){

                let regExp = new RegExp(entity5.validation);
                let res = regExp.test(value);
                let val=/\s+/g.test(value)
                if (res&&!val) {
                  this.setState({
                    entity5:{
                      ...entity5,
                      [name]:value,
                    },
                  onEditAddBtnDisable:true,
                  validateOptionMsg:null
                })
                }
                else if(value.length==0){
                  this.setState({
                    entity5:{
                      ...entity5,
                      [name]:"",
                    },
                  validateOptionMsg:null
                })
                }
                else{
                  this.setState({
                    validateOptionMsg: ( <ValidationMsg>Option does not match the validation expression</ValidationMsg>),
                  entity5: {
                    ...entity5,
                    [name]:value,
                  },
                });
              }
              }
             else if(name =="defaultValue"){
                let regExp = new RegExp(entity5.validation);
                let res = regExp.test(value);
                let val=/\s+/g.test(value)
                this.setState({
                  entity5: {
                      ...entity5,
                      [name]: value
                  }
                });
                if(value.length==0){
                  this.setState({
                  displayError:false,
                  filteredArrayList:false,
                  emptyDefaultMsg:null,
                  savebtnDisable:false
                })
              }
              if(value.length!=0){
                if(!res||val) {
                 this.setState( { 
                  emptyDefaultMsg: ( <ValidationMsg>Default value does not match the validation expression</ValidationMsg>),
                  savebtnDisable:true
                 })
                }
                else{
                  this.setState({
                    emptyDefaultMsg:null,
                    savebtnDisable:false
                  });
                }
               }
              }
              else{
                this.setState({
                    entity5: {
                        ...entity5,
                        [name]: value
                    }
                });
              }
             }
            if(entity5.question && entity5.defaultValue){
              let isValidationTypeExist = false;
              let isValidationRegexExist = false;
              let isValidRegex = true;
              if(entity5.validationText =="custom"){
                if(entity5.validationName && entity5.validation){
                  let fileData = [];
                  if(this.props.fileData){
                      fileData=  JSON.parse(this.props.fileData)
                      fileData=fileData.preValidationList;
                    
                      try {
                            new RegExp(entity5.validation);
                      } catch(e) {
                         isValidRegex = false;
                      }
                     
                      for(var i=0; i<fileData.length; i++){
                        if(Object.keys(fileData[i])[0]==entity5.validationName){
                           isValidationTypeExist=true;
                           break;
                        }else if(fileData[i][Object.keys(fileData[i])[0]]==entity5.validation){
                           isValidationRegexExist=true;
                           break;
                        }
                      }
                      if(isValidationTypeExist){
                        this.setState( { emptyValidationTypeMsg: ( <ValidationMsg>Validation Type already exist</ValidationMsg>) } ) 
                     }else if(isValidationRegexExist){
                      this.setState( { emptyValidationValueMsg: ( <ValidationMsg>Validation value already exist</ValidationMsg>) } )
                     }else if(!isValidRegex){
                      this.setState( { emptyValidationValueMsg: ( <ValidationMsg>Please enter valid regex</ValidationMsg>) } )
                     }else{
                      fileData.push({[entity5.validationName]:entity5.validation})
                      let validatioTypesObjString = JSON.stringify({"preValidationList":fileData});
                      let data={fileName: "validationTypes.json","content":escape(validatioTypesObjString),"project":projectname,"language":"en"}
                      this.setState({submitEntity5:true})
                     }
                  }
                
                }
              }
              else
                this.setState({submitEntity5:true})
            }
            else{
                this.setState({submitEntity5:false})
            }
          }
          this.setValidationMessage(name,value);
    }

  saveConfigureAddOption = (e, entity) => {
    const { entity1 } = this.state;
    if (entity == "1") {
      if (entity1.validation) {
        let textValues = entity1.addOptionValue;
        let listArray = entity1.addOptionList;
        let optLists = [];

        let regExp = new RegExp(entity1.validation);
        let res = regExp.test(entity1.addOptionValue);
        if (res) {
          if (listArray.indexOf(textValues.toLowerCase()) == -1)
            listArray.push(textValues);
          if (listArray.length > 0) {
            listArray.forEach((item) => {
            return optLists.push({ option: item });
            });
          }
          this.setState({
            entity1: {
              ...entity1,
              addOptionList: listArray,
              addOptionValue: "",
              optList: optLists,
            },
            validateOptionMsg: null,
          });
        } else {
          this.setState({
            validateOptionMsg: (
              <ValidationMsg>Enter valid Option</ValidationMsg>
            ),
            entity1: {
              ...entity1,
              addOptionValue: "",
            },
          });
        }
      }
    }
  };

  deleteConfigureAddOptions = (item, entity) => {
    const { entity1 } = this.state;
    if (entity == "1") {
      let listArray = entity1.addOptionList;
      let index = listArray.indexOf(item);
      let optLists = [];
      if (index > -1) {
        listArray.splice(index, 1);
        listArray.forEach((item) => {
        return optLists.push({ option: item });
        });
      }
      this.setState({
        entity1: {
          ...entity1,
          addOptionList: listArray,
          addOptionValue: "",
          optList: optLists,
        },
      });
    }
  };

  handleConfigure(e, entity) {
    const { name, value, options, selectedIndex } = e.target;
    const { entity1 } = this.state;
    let projectname = localStorage.getItem("projectname");
    if (!projectname) projectname = "common";
    if (entity == "1") {
      this.setState({
        entity1: {
          ...entity1,
          addOption: false,
        },
      });
      if (name == "validation") {
        if (value == "") {
          this.setState({
            entity1: {
              ...entity1,
              validationName: "",
              [name]: value,
              addOption: false,
            },
          });
        } else {
          if (value == "custom") {
            this.setState({
              entity1: {
                ...entity1,
                validationName: "",
                [name]: "",
                validationText: value,
              },
            });
          } else {
            this.setState({
              entity1: {
                ...entity1,
                validationName: options[selectedIndex].innerHTML,
                [name]: value,
                validationText: "",
              },
            });
          }
          return true;
        }
      }
      if (name == "optionType") {
        if (value == "addoption") {
          this.setState({
            entity1: {
              ...entity1,
              addOption: true,
              [name]: value,
            },
          });
        } else if (value == "form") {
          this.setState({
            entity1: {
              ...entity1,
              [name]: value,
              addOption: false,
              display: value,
              optList: [{ option: "" }],
            },
          });
        } else if (value == "password") {
          this.setState({
            entity1: {
              ...entity1,
              [name]: value,
              addOption: false,
              display: value,
              optList: [],
            },
          });
        } else if (value == "confirmation") {
          this.setState({
            entity1: {
              ...entity1,
              [name]: value,
              addOption: false,
              optList: [{ option: "yes" }, { option: "no" }],
            },
          });
        } else {
          this.setState({
            entity1: {
              ...entity1,
              [name]: value,
              addOption: false,
              display: value,
            },
          });
        }
      } else {
        if (entity1.validationText == "custom") {
          if (name == "validationText") {
            this.setState({
              entity1: {
                ...entity1,
                validationName: value,
              },
            });
          } else if (name == "ValidationValue") {
            this.setState({
              entity1: {
                ...entity1,
                validation: value,
              },
            });
          } else {
            this.setState({
              entity1: {
                ...entity1,
                [name]: value,
              },
            });
          }
        } else {
          this.setState({
            entity1: {
              ...entity1,
              [name]: value,
            },
          });
        }
      }
      if (entity1.validationText == "custom") {
        this.setValidationMessage("validationText", entity1.validationName);
        this.setValidationMessage("ValidationValue", entity1.validation);
      }
      if (this.state.entity1.question && this.state.entity1.defaultValue) {
        let isValidationTypeExist = false;
        let isValidationRegexExist = false;
        let isValidRegex = true;
        if (entity1.validationText == "custom") {
          if (entity1.validationName && entity1.validation) {
            let fileData = [];
            if (this.props.fileData) {
              fileData = JSON.parse(this.props.fileData);
              fileData = fileData.preValidationList;

              try {
                new RegExp(entity1.validation);
              } catch (e) {
                isValidRegex = false;
              }

              for (var i = 0; i < fileData.length; i++) {
                if (Object.keys(fileData[i])[0] == entity1.validationName) {
                  isValidationTypeExist = true;
                  break;
                } else if (
                  fileData[i][Object.keys(fileData[i])[0]] == entity1.validation
                ) {
                  isValidationRegexExist = true;
                  break;
                }
              }
              if (isValidationTypeExist) {
                this.setState({
                  emptyValidationTypeMsg: (
                    <ValidationMsg>Validation Type already exist</ValidationMsg>
                  ),
                });
              } else if (isValidationRegexExist) {
                this.setState({
                  emptyValidationValueMsg: (
                    <ValidationMsg>
                      Validation value already exist
                    </ValidationMsg>
                  ),
                });
              } else if (!isValidRegex) {
                this.setState({
                  emptyValidationValueMsg: (
                    <ValidationMsg>Please enter valid regex</ValidationMsg>
                  ),
                });
              } else {
                fileData.push({ [entity1.validationName]: entity1.validation });
                let validatioTypesObjString = JSON.stringify({
                  preValidationList: fileData,
                });
                let data = {
                  filePath: "Bots/libs/validationTypes.json",
                  content: escape(validatioTypesObjString),
                  project: projectname,
                  language: "en",
                };
                this.setState({ submitEntity1: true });
              }
            }
          }
        } else this.setState({ submitEntity1: true });
      } else {
        this.setState({ submitEntity1: false });
      }
    }
    this.setValidationMessage(name, value);
  }

  handleRemember(e, entity) {
    const { value } = e.target;
    const { entity1, entity2, entity3, entity4, entity5 } = this.state;
    if (entity == "1") {
      this.setState({
        entity1: {
          ...entity1,
          remember: value,
        },
      });
    } else if (entity == "2") {
      this.setState({
        entity2: {
          ...entity2,
          remember: value,
        },
      });
    } else if (entity == "3") {
      this.setState({
        entity3: {
          ...entity3,
          remember: value,
        },
      });
    } else if (entity == "4") {
      this.setState({
        entity4: {
          ...entity4,
          remember: value,
        },
      });
    } else {
      this.setState({
        entity5: {
          ...entity5,
          remember: value,
        },
      });
    }
  }

  handleToggle(e, entity) {
    const { value } = e.target;
    const { entity1, entity2, entity3, entity4, entity5 } = this.state;
    if (entity == "1") {
      this.setState({
        entity1: {
          ...entity1,
          entityType: value,
        },
      });
    } else if (entity == "2") {
      this.setState({
        entity2: {
          ...entity2,
          entityType: value,
        },
      });
    } else if (entity == "3") {
      this.setState({
        entity3: {
          ...entity3,
          entityType: value,
        },
      });
    } else if (entity == "4") {
      this.setState({
        entity4: {
          ...entity4,
          entityType: value,
        },
      });
    } else {
      this.setState({
        entity5: {
          ...entity5,
          entityType: value,
        },
      });
    }
  }

  IsFlowAlreadyExists = (clusterObj) => {
    let retVal = "";
    let botlist = localStorage.getItem("botList");
    if (botlist) {
      botlist = botlist.split(",");
      const alreadyExistBot = botlist.find(
        (x) => x.toLowerCase() == clusterObj.Title.toLowerCase()
      );
      if (alreadyExistBot) retVal = alreadyExistBot;
    }
    return retVal;
  };

  IsFlowExists = (clusterObj, IsMultiple) => {
    let retVal = "";

    if (clusterObj && clusterObj.length > 0) {
      for (let i = 0; i < clusterObj.length; i++) {
        const cluster = this.state.clusterArray.find(
          (x) => x.Id == clusterObj[i]
        );
        const retFlow = this.IsFlowAlreadyExists(cluster);
        if (retFlow) {
          retVal += retFlow + ",";
        }
      }
    }
    return retVal ? retVal.substring(0, retVal.length - 1) : retVal;
  };

  async importClustersData() {
    const selectedClusters = this.state.SelectedClusters;
    const retVal = this.IsFlowExists(selectedClusters, true);
    if (!retVal) {
      if (selectedClusters.length > 0) {
        await this.setState({
          importClusterLoading: true,
          IsClusterImported: true,
          importCLusterCount: {
            ...this.state.importCLusterCount,
            Total: selectedClusters.length,
            completed: 0,
          },
        });
        for (let i = 0; i < selectedClusters.length; i++) {
          const clusterObj = this.state.clusterArray.find(
            (x) => x.Id == selectedClusters[i]
          );
          await this.importClusterDataOneByOne(clusterObj);
        }
     
      }
    } else {
      await this.props.OnHandleImportError(
        "Flow(" + retVal + ") already exists"
      );
    }
  }

  async importClusterDataOneByOne(clusterObj) {
    botselected = clusterObj.Title;
    const projectname = localStorage.getItem("projectname");
    const language = "en";
    // Code for single create flow and create intent
    let bot = {
      botname: clusterObj.Title,
      profile: clusterObj.Title,
      project: projectname,
      language: language,
      username: "",
    };

    await this.props.OnCreateBot(this.props.config.API_URL, bot, true, false, this.state.isCuxOrg);
    let intentList = {
      botName: clusterObj.Title,
      profileName: clusterObj.Title,
      value: "ChatBot&&" + clusterObj.Title,
      project: projectname,
      language: language,
      triggerType: "chatbot",
    };

    let learn = "";
    if (clusterObj.Docs && clusterObj.Docs.length > 0) {
      clusterObj.Docs.map((item, idx) => (learn = learn + item + "\n"));
    }
    let learnData = [];
    learnData = learn.split("\n");
    learnData = learnData.filter((item) => item);
    let pattern = {
      project: projectname,
      language: language,
      botName: clusterObj.Title,
      intentTraining: { learn: learnData, relearn: [" "] },
      description: clusterObj.Title,
    };

    await this.props.onUpdateIntent(
      pattern,
      this.props.config.BOTBUILDER_URL,
      true
    );
  }
  async importClusterData(event, id) {
    const retVal = this.IsFlowAlreadyExists(
      this.state.clusterArray.find((x) => x.Id == id)
    );
    if (!retVal) {
      this.setState({
        importClusterLoading: true,
        IsClusterImported: true,
        importCLusterCount: {
          ...this.state.importCLusterCount,
          Total: 1,
          completed: 0,
        },
      });

      const clusterObj = this.state.clusterArray.find((x) => x.Id == id);
      await this.importClusterDataOneByOne(clusterObj);
    } else {
      await this.props.OnHandleImportError(
        "Flow(" + retVal + ") already exists"
      );
    }
  }
  selectDiv = (event, id) => {
    const originalID = id.split("_")[1];
    const classNam = classes.selectedDiv;
    const div = document.getElementById(id);
    if (div.classList.contains(classNam)) {
      const SelectedClusters = this.state.SelectedClusters;
      if (SelectedClusters && SelectedClusters.indexOf(originalID) > -1) {
        let selArray = [...this.state.SelectedClusters];
        selArray.splice(selArray.indexOf(originalID), 1);
        this.setState({ SelectedClusters: selArray });
      }
      div.classList.remove(classNam);
    } else {
      let selArray = [...this.state.SelectedClusters, originalID];
      this.setState({ SelectedClusters: selArray });

      div.classList.add(classNam);
    }
  };
  ShowMoreContent = (event, id, contentId) => {
    if (event.target.textContent.trim() == "Show more ∨") {
      event.target.textContent = "Show less ∧";
      const removeClass = classes.TabContent;
      const classNam = classes.showMoreData;
      const tabClassContent = classes.showMoreDatatabcontent;
      const div = document.getElementById(id);
      div.classList.add(classNam);
      const divTabContent = document.getElementById(contentId);
      divTabContent.classList.add(tabClassContent);
      divTabContent.classList.remove(removeClass);
    } else {
      event.target.textContent = "Show more ∨";
      const removeclassNam = classes.showMoreData;
      const removeContentClass = classes.showMoreDatatabcontent;
      const addContentClass = classes.TabContent;
      const div = document.getElementById(id);
      div.classList.remove(removeclassNam);
      const divTabContent = document.getElementById(contentId);
      divTabContent.classList.add(addContentClass);
      divTabContent.classList.remove(removeContentClass);
    }
  };
  addInputIndex(e) {
    let indexValue = JSON.parse(this.state.indexValue);
    if (this.state.setEntityShow) {
      this.setState({ addIndex: true });
      indexValue = indexValue + 1;
      if (indexValue >= 5) {
        indexValue = 5;
      }
    }
    if (
      !this.state.setEntityShow &&
      indexValue < this.state.AddFlowControls.botEntityInputs.value
    ) {
      indexValue = indexValue + 1;
    }

    this.setState({ indexValue: indexValue });
  }

  deleteEntitySubmission = (entityKey) => {
    let projectname = localStorage.getItem("projectname");
    let language = localStorage.getItem("language");

    let entitylistObj = [];
    entitylistObj = JSON.parse(this.props.entityList);
    delete entitylistObj.Entities[entityKey];
    let finalObj = [];
    for (const [key, value] of Object.entries(entitylistObj.Entities)) {
      //console.log(key, value);
      let aObj = {};
      let bObj = {};
      let cObj = {};
      aObj["name"] = key;
      bObj = value;
      cObj = Object.assign(aObj,bObj);
      finalObj.push(cObj); 
    }
    let BotKeys = Object.keys(entitylistObj.Entities);
    let BotValues = Object.values(entitylistObj.Entities);

    let newBotEntity = [];
    BotKeys.forEach((v, i) => {
      newBotEntity.push(BotValues[i]);
    });

    let isDeleteAction = true;
    this.props.createUpdateEntity(
      {
        botName: this.state.currentBotSelected,
        entityData: {
          FinalValidationOptional: "",
          Action: "",
          entityList: finalObj,
        },
        project: projectname,
        language: language,
        entitykey : entityKey
		
		
      },
      this.props.config.BOTBUILDER_URL,
      false,
      isDeleteAction,
      false
    );

    this.setState({
      deleteEntityModalShow: false,
      deleteEntityModalData: "",
    });

    setTimeout(() => {
      this.props.ongetEntity(
        {
          language: localStorage.getItem("language"),
          projectname: localStorage.getItem("projectname"),
          botName: this.state.currentBotSelected,
        },
        this.props.config.BOTBUILDER_URL
      );
      let projectname = localStorage.getItem("projectname");
      let language = localStorage.getItem("language");
      let botName = this.state.currentBotSelected;
      let docvalue = {
      projectname: projectname,
      language: language,
      botName: botName,
      };
      this.props.OnGetDoc(docvalue, this.props.config.BOTBUILDER_URL);
      
    }, 4000);
  };

  saveEntity(e, index, active, currentSelBot) {
    e.preventDefault();
    const { entity1, entity2, entity3, entity4, entity5 } = this.state;
    let projectname = localStorage.getItem("projectname");
    let language = localStorage.getItem("language");
    if (!projectname) projectname = "common";
    if (!language) {
      language = "en";
    }
    if(entity1.addOption==true||entity2.addOption==true||entity3.addOption==true||
      entity4.addOption==true||entity5.addOption==true){
    if(filteredArrayList==true){
      this.setState({
        displayError:false
      })

    }else{
      this.setState({
        displayError:true,  
      })
    return;
    }
  }
    let fromFlowModal = false;
    let currentBot = currentSelBot;
    if (!currentBot || currentBot === undefined || currentBot === null) {
      currentBot = this.state.currentBotSelected;
      fromFlowModal = false;
    } else {
      fromFlowModal = true;
    }
    if (index == "1") {
      this.setValidationMessage("question", this.state.entity1.question);
      this.setValidationMessage(
        "defaultValue",
        this.state.entity1.defaultValue
      );
      if (entity1.validationText == "custom") {
        this.setValidationMessage("validationText", entity1.validationName);
        this.setValidationMessage("ValidationValue", entity1.validation);
      }
      if (
        this.state.entity1.question 
      ) {
        let isValidationTypeExist = false;
        let isValidationRegexExist = false;
        let isValidRegex = true;
        if (entity1.validationText == "custom") {
          if (entity1.validationName && entity1.validation) {
            let fileData = [];
            if (this.props.fileData) {
              fileData = JSON.parse(this.props.fileData);
              fileData = fileData.preValidationList;

              try {
                new RegExp(entity1.validation);
              } catch (e) {
                isValidRegex = false;
              }

              for (var i = 0; i < fileData.length; i++) {
                if (Object.keys(fileData[i])[0] == entity1.validationName) {
                  isValidationTypeExist = true;
                  break;
                } else if (
                  fileData[i][Object.keys(fileData[i])[0]] == entity1.validation
                ) {
                  isValidationRegexExist = true;
                  break;
                }
              }
              if (isValidationTypeExist) {
                this.setState({
                  emptyValidationTypeMsg: (
                    <ValidationMsg>Validation Type already exist</ValidationMsg>
                  ),
                });
              } else if (isValidationRegexExist) {
                this.setState({
                  emptyValidationValueMsg: (
                    <ValidationMsg>
                      Validation value already exist
                    </ValidationMsg>
                  ),
                });
              } else if (!isValidRegex) {
                this.setState({
                  emptyValidationValueMsg: (
                    <ValidationMsg>Please enter valid regex</ValidationMsg>
                  ),
                });
              } else {
                fileData.push({ [entity1.validationName]: entity1.validation });
                let validatioTypesObjString = JSON.stringify({
                  preValidationList: fileData,
                });
                let data = {
                  fileName: "validationTypes.json",
                  content: escape(validatioTypesObjString),
                  project: projectname,
                  language: "en",
                };
                this.props.onUpdateFile(data, this.props.config.API_URL);
                this.props.onreadFile(
                  {
                    fileName: "validationTypes.json",
                    project: projectname,
                    language: "en",
                  },
                  this.props.config.BOTBUILDER_URL
                );
                this.setState({ submitEntity1: true });
              }
            }
          }
        } else this.setState({ submitEntity1: true });
        if (active) {
          let activeBotList = this.state.selectedBotList;
          let BotKeys = Object.keys(activeBotList);
          let BotValues = Object.values(activeBotList).map((p) =>
            p.Type === this.state.selectedEntity
              ? {
                  ...p,
                  validationType: entity1.validationName,
                  Type: entity1.entityName,
                  param: "P1",
                  ValidationFailResponse: entity1.invalidEntity,
                  Question: entity1.question,
                  defaultValue: entity1.defaultValue,
                  questionoptiontype: entity1.optionType,
                  displaytype: entity1.display,
                  isEntityOptional: entity1.entityType,
                  rememberEntity: entity1.remember,
                  Validation: { [entity1.validationName]: entity1.validation },
                  options:
                    entity1.optionType != "password" &&
                    entity1.optionType != "-1"
                      ? entity1.optList
                      : [],
                }
              : p
          );
          let newBotEntity = [];
          BotKeys.forEach((v, i) => {
            newBotEntity.push(BotValues[i]);
          });
          if (
            !isValidationTypeExist &&
            isValidRegex &&
            !isValidationRegexExist
          ) {
            this.props.createUpdateEntity(
              {
                botName: currentBot,
                entityData: {
                  FinalValidationOptional: "",
                  Action: "",
                  entityList: newBotEntity,
                },
                project: projectname,
                language: language,
              },
              this.props.config.BOTBUILDER_URL,
              fromFlowModal,
              false,
              this.state.EntityOccurance
            );

            setTimeout(() => {
              this.closeEntities();
            }, 2000);
          }
        } else {
          if (
            !isValidationTypeExist &&
            isValidRegex &&
            !isValidationRegexExist
          ) {
            let listData = {
              validationType: entity1.validationName,
              Type: entity1.entityName,
              param: "P1",
              ValidationFailResponse: entity1.invalidEntity,
              Question: entity1.question,
              defaultValue: entity1.defaultValue,
              questionoptiontype: entity1.optionType,
              displaytype: entity1.display,
              isEntityOptional: entity1.entityType,
              rememberEntity: entity1.remember,
              isSecured: false,
              Validation: { [entity1.validationName]: entity1.validation },
            };
            if (
              entity1.optionType != "password" &&
              entity1.optionType != "-1"
            ) {
              listData["options"] = entity1.optList;
            }
            this.props.createUpdateEntity(
              {
                botName: currentBot,
                entityData: {
                  FinalValidationOptional: "",
                  Action: "",
                  entityList: [listData],
                },
                project: projectname,
                language: language,
              },
              this.props.config.BOTBUILDER_URL,
              fromFlowModal,
              false,
              this.state.EntityOccurance
            );
            setTimeout(() => {
              this.closeEntities();
            }, 2000);
          }
        }
      }
    } else if (index == "2") {
      this.setValidationMessage("question", this.state.entity2.question);
      this.setValidationMessage(
        "defaultValue",
        this.state.entity2.defaultValue
      );
      if (entity2.validationText == "custom") {
        this.setValidationMessage("validationText", entity2.validationName);
        this.setValidationMessage("ValidationValue", entity2.validation);
      }

      if (
        this.state.entity2.question 
      ) {
        let isValidationTypeExist = false;
        let isValidationRegexExist = false;
        let isValidRegex = true;
        if (entity2.validationText == "custom") {
          if (entity2.validationName && entity2.validation) {
            let fileData = [];
            if (this.props.fileData) {
              fileData = JSON.parse(this.props.fileData);
              fileData = fileData.preValidationList;

              try {
                new RegExp(entity2.validation);
              } catch (e) {
                isValidRegex = false;
              }

              for (var i = 0; i < fileData.length; i++) {
                if (Object.keys(fileData[i])[0] == entity2.validationName) {
                  isValidationTypeExist = true;
                  break;
                } else if (
                  fileData[i][Object.keys(fileData[i])[0]] == entity2.validation
                ) {
                  isValidationRegexExist = true;
                  break;
                }
              }
              if (isValidationTypeExist) {
                this.setState({
                  emptyValidationTypeMsg: (
                    <ValidationMsg>Validation Type already exist</ValidationMsg>
                  ),
                });
              } else if (isValidationRegexExist) {
                this.setState({
                  emptyValidationValueMsg: (
                    <ValidationMsg>
                      Validation value already exist
                    </ValidationMsg>
                  ),
                });
              } else if (!isValidRegex) {
                this.setState({
                  emptyValidationValueMsg: (
                    <ValidationMsg>Please enter valid regex</ValidationMsg>
                  ),
                });
              } else {
                fileData.push({ [entity2.validationName]: entity2.validation });
                let validatioTypesObjString = JSON.stringify({
                  preValidationList: fileData,
                });
                let data = {
                  fileName: "validationTypes.json",
                  content: escape(validatioTypesObjString),
                  project: projectname,
                  language: "en",
                };
                this.props.onUpdateFile(data, this.props.config.API_URL);
                this.props.onreadFile(
                  {
                    fileName: "validationTypes.json",
                    project: projectname,
                    language: "en",
                  },
                  this.props.config.BOTBUILDER_URL
                );
                this.setState({ submitEntity2: true });
              }
            }
          }
        } else this.setState({ submitEntity2: true });
        let entitylistObj = {};

        if (
          this.props.entityList &&
          this.props.entityList != null &&
          this.props.entityList != "{}" &&
          !(this.props.entityList.indexOf("Failure") > -1)
        ) {
          entitylistObj = JSON.parse(this.props.entityList);

          if (Object.keys(entitylistObj.Entities).length === 1) {
            let addKeys = {
              two: {
                validationType: entity2.validationName,
                Type: entity2.entityName,
                param: "P2",
                ValidationFailResponse: entity2.invalidEntity,
                Question: entity2.question,
                defaultValue: entity2.defaultValue,
                questionoptiontype: entity2.optionType,
                displaytype: entity2.display,
                isEntityOptional: entity2.entityType,
                rememberEntity: entity2.remember,
                isSecured: false,
                Validation: { [entity2.validationName]: entity2.validation },
              },
            };
            if (
              entity2.optionType != "password" &&
              entity2.optionType != "-1"
            ) {
              addKeys.two["options"] = entity2.optList;
            }

            if (!this.state.addIndex) {
              if (
                !isValidationTypeExist &&
                isValidRegex &&
                !isValidationRegexExist
              ) {
                this.props.createUpdateEntity(
                  {
                    botName: currentBot,
                    entityData: {
                      FinalValidationOptional:
                        entitylistObj.FinalValidationOptional,
                      Action: entitylistObj.Action,
                      entityList: [entitylistObj.Entities.one, addKeys.two],
                    },
                    project: projectname,
                    language: language,
                  },
                  this.props.config.BOTBUILDER_URL,
                  fromFlowModal,
                  false,
                  this.state.EntityOccurance
                );
                setTimeout(() => {
                  this.closeEntities();
                }, 2000);
              }
            }
          } else {
            if (
              !isValidationTypeExist &&
              isValidRegex &&
              !isValidationRegexExist
            ) {
              let listData = {
                validationType: entity1.validationName,
                Type: entity1.entityName,
                param: "P1",
                ValidationFailResponse: entity1.invalidEntity,
                Question: entity1.question,
                defaultValue: entity1.defaultValue,
                questionoptiontype: entity1.optionType,
                displaytype: entity1.display,
                isEntityOptional: entity1.entityType,
                rememberEntity: entity1.remember,
                isSecured: false,
                Validation: { [entity1.validationName]: entity1.validation },
              };
              if (
                entity1.optionType != "password" &&
                entity1.optionType != "-1"
              ) {
                listData["options"] = entity1.optList;
              }

              let listData2 = {
                validationType: entity2.validationName,
                Type: entity2.entityName,
                param: "P2",
                ValidationFailResponse: entity2.invalidEntity,
                Question: entity2.question,
                defaultValue: entity2.defaultValue,
                questionoptiontype: entity2.optionType,
                displaytype: entity2.display,
                isEntityOptional: entity2.entityType,
                rememberEntity: entity2.remember,
                isSecured: false,
                Validation: { [entity2.validationName]: entity2.validation },
              };
              if (
                entity2.optionType != "password" &&
                entity2.optionType != "-1"
              ) {
                listData2["options"] = entity2.optList;
              }
              this.props.createUpdateEntity(
                {
                  botName: currentBot,
                  entityData: {
                    FinalValidationOptional: "",
                    Action: "",
                    entityList: [listData, listData2],
                  },
                  project: projectname,
                  language: language,
                },
                this.props.config.BOTBUILDER_URL,
                fromFlowModal,
                false,
                this.state.EntityOccurance
              );
              setTimeout(() => {
                this.closeEntities();
              }, 2000);
            }
          }
        } else {
          if (
            !isValidationTypeExist &&
            isValidRegex &&
            !isValidationRegexExist
          ) {
            let listData = {
              validationType: entity1.validationName,
              Type: entity1.entityName,
              param: "P1",
              ValidationFailResponse: entity1.invalidEntity,
              Question: entity1.question,
              defaultValue: entity1.defaultValue,
              questionoptiontype: entity1.optionType,
              displaytype: entity1.display,
              isEntityOptional: entity1.entityType,
              rememberEntity: entity1.remember,
              isSecured: false,
              Validation: { [entity1.validationName]: entity1.validation },
            };
            if (
              entity1.optionType != "password" &&
              entity1.optionType != "-1"
            ) {
              listData["options"] = entity1.optList;
            }

            let listData2 = {
              validationType: entity2.validationName,
              Type: entity2.entityName,
              param: "P2",
              ValidationFailResponse: entity2.invalidEntity,
              Question: entity2.question,
              defaultValue: entity2.defaultValue,
              questionoptiontype: entity2.optionType,
              displaytype: entity2.display,
              isEntityOptional: entity2.entityType,
              rememberEntity: entity2.remember,
              isSecured: false,
              Validation: { [entity2.validationName]: entity2.validation },
            };
            if (
              entity2.optionType != "password" &&
              entity2.optionType != "-1"
            ) {
              listData2["options"] = entity2.optList;
            }
            this.props.createUpdateEntity(
              {
                botName: currentBot,
                entityData: {
                  FinalValidationOptional: "",
                  Action: "",
                  entityList: [listData, listData2],
                },
                project: projectname,
                language: language,
              },
              this.props.config.BOTBUILDER_URL,
              fromFlowModal,
              false,
              this.state.EntityOccurance
            );
            setTimeout(() => {
              this.closeEntities();
            }, 2000);
          }
        }
      }
    } else if (index == "3") {
      this.setValidationMessage("question", this.state.entity3.question);
      this.setValidationMessage(
        "defaultValue",
        this.state.entity3.defaultValue
      );
      if (entity3.validationText == "custom") {
        this.setValidationMessage("validationText", entity3.validationName);
        this.setValidationMessage("ValidationValue", entity3.validation);
      }
      if (
        this.state.entity3.question
      ) {
        let isValidationTypeExist = false;
        let isValidationRegexExist = false;
        let isValidRegex = true;
        if (entity3.validationText == "custom") {
          if (entity3.validationName && entity3.validation) {
            let fileData = [];
            if (this.props.fileData) {
              fileData = JSON.parse(this.props.fileData);
              fileData = fileData.preValidationList;

              try {
                new RegExp(entity3.validation);
              } catch (e) {
                isValidRegex = false;
              }

              for (var i = 0; i < fileData.length; i++) {
                if (Object.keys(fileData[i])[0] == entity3.validationName) {
                  isValidationTypeExist = true;
                  break;
                } else if (
                  fileData[i][Object.keys(fileData[i])[0]] == entity3.validation
                ) {
                  isValidationRegexExist = true;
                  break;
                }
              }
              if (isValidationTypeExist) {
                this.setState({
                  emptyValidationTypeMsg: (
                    <ValidationMsg>Validation Type already exist</ValidationMsg>
                  ),
                });
              } else if (isValidationRegexExist) {
                this.setState({
                  emptyValidationValueMsg: (
                    <ValidationMsg>
                      Validation value already exist
                    </ValidationMsg>
                  ),
                });
              } else if (!isValidRegex) {
                this.setState({
                  emptyValidationValueMsg: (
                    <ValidationMsg>Please enter valid regex</ValidationMsg>
                  ),
                });
              } else {
                fileData.push({ [entity3.validationName]: entity3.validation });
                let validatioTypesObjString = JSON.stringify({
                  preValidationList: fileData,
                });
                let data = {
                  fileName: "validationTypes.json",
                  content: escape(validatioTypesObjString),
                  project: projectname,
                  language: "en",
                };
                this.props.onUpdateFile(data, this.props.config.API_URL);
                this.props.onreadFile(
                  {
                    fileName: "validationTypes.json",
                    project: projectname,
                    language: "en",
                  },
                  this.props.config.BOTBUILDER_URL
                );
                this.setState({ submitEntity3: true });
              }
            }
          }
        } else this.setState({ submitEntity3: true });
        if (
          this.props.entityList &&
          this.props.entityList != null &&
          this.props.entityList != "{}" &&
          !(this.props.entityList.indexOf("Failure") > -1)
        ) {
          let entitylistObj = JSON.parse(this.props.entityList);
          if (Object.keys(entitylistObj.Entities).length !== 0) {
            let activeBotList = entitylistObj;
            let addKeys = {};
            let entityData = [];
            if (!this.state.addIndex) {
              addKeys = {
                three: {
                  validationType: entity3.validationName,
                  Type: entity3.entityName,
                  param: "P3",
                  ValidationFailResponse: entity3.invalidEntity,
                  Question: entity3.question,
                  defaultValue: entity3.defaultValue,
                  questionoptiontype: entity3.optionType,
                  displaytype: entity3.display,
                  isEntityOptional: entity3.entityType,
                  rememberEntity: entity3.remember,
                  isSecured: false,
                  Validation: { [entity3.validationName]: entity3.validation },
                },
              };
              if (
                entity3.optionType != "password" &&
                entity3.optionType != "-1"
              ) {
                addKeys.three["options"] = entity3.optList;
              }
              entityData = [
                entitylistObj.Entities.one,
                entitylistObj.Entities.two,
                addKeys.three,
              ];
            } else {
              addKeys = {
                two: {
                  validationType: entity2.validationName,
                  Type: entity2.entityName,
                  param: "P2",
                  ValidationFailResponse: entity2.invalidEntity,
                  Question: entity2.question,
                  defaultValue: entity2.defaultValue,
                  questionoptiontype: entity2.optionType,
                  displaytype: entity2.display,
                  isEntityOptional: entity2.entityType,
                  rememberEntity: entity2.remember,
                  isSecured: false,
                  Validation: { [entity2.validationName]: entity2.validation },
                },
                three: {
                  validationType: entity3.validationName,
                  Type: entity3.entityName,
                  param: "P3",
                  ValidationFailResponse: entity3.invalidEntity,
                  Question: entity3.question,
                  defaultValue: entity3.defaultValue,
                  questionoptiontype: entity3.optionType,
                  displaytype: entity3.display,
                  isEntityOptional: entity3.entityType,
                  rememberEntity: entity3.remember,
                  isSecured: false,
                  Validation: { [entity3.validationName]: entity3.validation },
                },
              };
              if (
                entity2.optionType != "password" &&
                entity2.optionType != "-1"
              ) {
                addKeys.two["options"] = entity2.optList;
              }
              if (
                entity3.optionType != "password" &&
                entity3.optionType != "-1"
              ) {
                addKeys.three["options"] = entity3.optList;
              }
              entityData = [
                entitylistObj.Entities.one,
                addKeys.two,
                addKeys.three,
              ];
            }
            if (
              !isValidationTypeExist &&
              isValidRegex &&
              !isValidationRegexExist
            ) {
              this.props.createUpdateEntity(
                {
                  botName: currentBot,
                  entityData: {
                    FinalValidationOptional:
                      entitylistObj.FinalValidationOptional,
                    Action: entitylistObj.Action,
                    entityList: entityData,
                  },
                  project: projectname,
                  language: language,
                },
                this.props.config.BOTBUILDER_URL,
                fromFlowModal,
                false,
                this.state.EntityOccurance
              );

              setTimeout(() => {
                this.closeEntities();
              }, 2000);
            }
          } else {
            if (
              !isValidationTypeExist &&
              isValidRegex &&
              !isValidationRegexExist
            ) {
              let listData = {
                validationType: entity1.validationName,
                Type: entity1.entityName,
                param: "P1",
                ValidationFailResponse: entity1.invalidEntity,
                Question: entity1.question,
                defaultValue: entity1.defaultValue,
                questionoptiontype: entity1.optionType,
                displaytype: entity1.display,
                isEntityOptional: entity1.entityType,
                rememberEntity: entity1.remember,
                isSecured: false,
                Validation: { [entity1.validationName]: entity1.validation },
              };
              if (
                entity1.optionType != "password" &&
                entity1.optionType != "-1"
              ) {
                listData["options"] = entity1.optList;
              }

              let listData2 = {
                validationType: entity2.validationName,
                Type: entity2.entityName,
                param: "P2",
                ValidationFailResponse: entity2.invalidEntity,
                Question: entity2.question,
                defaultValue: entity2.defaultValue,
                questionoptiontype: entity2.optionType,
                displaytype: entity2.display,
                isEntityOptional: entity2.entityType,
                rememberEntity: entity2.remember,
                isSecured: false,
                Validation: { [entity2.validationName]: entity2.validation },
              };
              if (
                entity2.optionType != "password" &&
                entity2.optionType != "-1"
              ) {
                listData2["options"] = entity2.optList;
              }
              let listData3 = {
                validationType: entity3.validationName,
                Type: entity3.entityName,
                param: "P3",
                ValidationFailResponse: entity3.invalidEntity,
                Question: entity3.question,
                defaultValue: entity3.defaultValue,
                questionoptiontype: entity3.optionType,
                displaytype: entity3.display,
                isEntityOptional: entity3.entityType,
                rememberEntity: entity3.remember,
                isSecured: false,
                Validation: { [entity3.validationName]: entity3.validation },
              };
              if (
                entity3.optionType != "password" &&
                entity3.optionType != "-1"
              ) {
                listData3["options"] = entity3.optList;
              }
              this.props.createUpdateEntity(
                {
                  botName: currentBot,
                  entityData: {
                    FinalValidationOptional: "",
                    Action: "",
                    entityList: [listData, listData2, listData3],
                  },

                  project: projectname,
                  language: language,
                },
                this.props.config.BOTBUILDER_URL,
                fromFlowModal,
                false,
                this.state.EntityOccurance
              );
              setTimeout(() => {
                this.closeEntities();
              }, 2000);
            }
          }
        } else {
          if (
            !isValidationTypeExist &&
            isValidRegex &&
            !isValidationRegexExist
          ) {
            let listData = {
              validationType: entity1.validationName,
              Type: entity1.entityName,
              param: "P1",
              ValidationFailResponse: entity1.invalidEntity,
              Question: entity1.question,
              defaultValue: entity1.defaultValue,
              questionoptiontype: entity1.optionType,
              displaytype: entity1.display,
              isEntityOptional: entity1.entityType,
              rememberEntity: entity1.remember,
              isSecured: false,
              Validation: { [entity1.validationName]: entity1.validation },
            };
            if (
              entity1.optionType != "password" &&
              entity1.optionType != "-1"
            ) {
              listData["options"] = entity1.optList;
            }

            let listData2 = {
              validationType: entity2.validationName,
              Type: entity2.entityName,
              param: "P2",
              ValidationFailResponse: entity2.invalidEntity,
              Question: entity2.question,
              defaultValue: entity2.defaultValue,
              questionoptiontype: entity2.optionType,
              displaytype: entity2.display,
              isEntityOptional: entity2.entityType,
              rememberEntity: entity2.remember,
              isSecured: false,
              Validation: { [entity2.validationName]: entity2.validation },
            };
            if (
              entity2.optionType != "password" &&
              entity2.optionType != "-1"
            ) {
              listData2["options"] = entity2.optList;
            }
            let listData3 = {
              validationType: entity3.validationName,
              Type: entity3.entityName,
              param: "P3",
              ValidationFailResponse: entity3.invalidEntity,
              Question: entity3.question,
              defaultValue: entity3.defaultValue,
              questionoptiontype: entity3.optionType,
              displaytype: entity3.display,
              isEntityOptional: entity3.entityType,
              rememberEntity: entity3.remember,
              isSecured: false,
              Validation: { [entity3.validationName]: entity3.validation },
            };
            if (
              entity3.optionType != "password" &&
              entity3.optionType != "-1"
            ) {
              listData3["options"] = entity3.optList;
            }
            this.props.createUpdateEntity(
              {
                botName: currentBot,
                entityData: {
                  FinalValidationOptional: "",
                  Action: "",
                  entityList: [listData, listData2, listData3],
                },

                project: projectname,
                language: language,
              },
              this.props.config.BOTBUILDER_URL,
              fromFlowModal,
              false,
              this.state.EntityOccurance
            );
            setTimeout(() => {
              this.closeEntities();
            }, 2000);
          }
        }
      }
    } else if (index == "4") {
      this.setValidationMessage("question", this.state.entity4.question);
      this.setValidationMessage(
        "defaultValue",
        this.state.entity4.defaultValue
      );
      if (entity4.validationText == "custom") {
        this.setValidationMessage("validationText", entity4.validationName);
        this.setValidationMessage("ValidationValue", entity4.validation);
      }
      if (
        this.state.entity4.question 
      ) {
        let isValidationTypeExist = false;
        let isValidationRegexExist = false;
        let isValidRegex = true;
        if (entity4.validationText == "custom") {
          if (entity4.validationName && entity4.validation) {
            let fileData = [];
            if (this.props.fileData) {
              fileData = JSON.parse(this.props.fileData);
              fileData = fileData.preValidationList;

              try {
                new RegExp(entity4.validation);
              } catch (e) {
                isValidRegex = false;
              }

              for (var i = 0; i < fileData.length; i++) {
                if (Object.keys(fileData[i])[0] == entity4.validationName) {
                  isValidationTypeExist = true;
                  break;
                } else if (
                  fileData[i][Object.keys(fileData[i])[0]] == entity4.validation
                ) {
                  isValidationRegexExist = true;
                  break;
                }
              }
              if (isValidationTypeExist) {
                this.setState({
                  emptyValidationTypeMsg: (
                    <ValidationMsg>Validation Type already exist</ValidationMsg>
                  ),
                });
              } else if (isValidationRegexExist) {
                this.setState({
                  emptyValidationValueMsg: (
                    <ValidationMsg>
                      Validation value already exist
                    </ValidationMsg>
                  ),
                });
              } else if (!isValidRegex) {
                this.setState({
                  emptyValidationValueMsg: (
                    <ValidationMsg>Please enter valid regex</ValidationMsg>
                  ),
                });
              } else {
                fileData.push({ [entity4.validationName]: entity4.validation });
                let validatioTypesObjString = JSON.stringify({
                  preValidationList: fileData,
                });
                let data = {
                  fileName: "validationTypes.json",
                  content: escape(validatioTypesObjString),
                  project: projectname,
                  language: "en",
                };
                this.props.onUpdateFile(data, this.props.config.API_URL);
                this.props.onreadFile(
                  {
                    fileName: "validationTypes.json",
                    project: projectname,
                    language: "en",
                  },
                  this.props.config.BOTBUILDER_URL
                );
                this.setState({ submitEntity4: true });
              }
            }
          }
        } else this.setState({ submitEntity4: true });
        if (
          this.props.entityList &&
          this.props.entityList != null &&
          this.props.entityList != "{}" &&
          !(this.props.entityList.indexOf("Failure") > -1)
        ) {
          let entitylistObj = JSON.parse(this.props.entityList);
          if (Object.keys(entitylistObj.Entities).length !== 0) {
            let activeBotList = entitylistObj;
            let addKeys = {};
            let entityData = [];
            if (!this.state.addIndex) {
              addKeys = {
                four: {
                  validationType: entity4.validationName,
                  Type: entity4.entityName,
                  param: "P4",
                  ValidationFailResponse: entity4.invalidEntity,
                  Question: entity4.question,
                  defaultValue: entity4.defaultValue,
                  questionoptiontype: entity4.optionType,
                  options: entity4.optList,
                  displaytype: entity4.display,
                  isEntityOptional: entity4.entityType,
                  rememberEntity: entity4.remember,
                  isSecured: false,
                  Validation: { [entity4.validationName]: entity4.validation },
                },
              };

              if (
                entity4.optionType != "password" &&
                entity4.optionType != "-1"
              ) {
                addKeys.four["options"] = entity4.optList;
              }
              entityData = [
                entitylistObj.Entities.one,
                entitylistObj.Entities.two,
                entitylistObj.Entities.three,
                addKeys.four,
              ];
            } else if (
              this.state.addIndex &&
              Object.keys(entitylistObj.Entities).length == 1
            ) {
              addKeys = {
                two: {
                  validationType: entity2.validationName,
                  Type: entity2.entityName,
                  param: "P2",
                  ValidationFailResponse: entity2.invalidEntity,
                  Question: entity2.question,
                  defaultValue: entity2.defaultValue,
                  questionoptiontype: entity2.optionType,
                  options: entity2.optList,
                  displaytype: entity2.display,
                  isEntityOptional: entity2.entityType,
                  rememberEntity: entity2.remember,
                  isSecured: false,
                  Validation: { [entity2.validationName]: entity2.validation },
                },
                three: {
                  validationType: entity3.validationName,
                  Type: entity3.entityName,
                  param: "P3",
                  ValidationFailResponse: entity3.invalidEntity,
                  Question: entity3.question,
                  defaultValue: entity3.defaultValue,
                  questionoptiontype: entity3.optionType,
                  options: entity3.optList,
                  displaytype: entity3.display,
                  isEntityOptional: entity3.entityType,
                  rememberEntity: entity3.remember,
                  isSecured: false,
                  Validation: { [entity3.validationName]: entity3.validation },
                },
                four: {
                  validationType: entity4.validationName,
                  Type: entity4.entityName,
                  param: "P4",
                  ValidationFailResponse: entity4.invalidEntity,
                  Question: entity4.question,
                  defaultValue: entity4.defaultValue,
                  questionoptiontype: entity4.optionType,
                  options: entity4.optList,
                  displaytype: entity4.display,
                  isEntityOptional: entity4.entityType,
                  rememberEntity: entity4.remember,
                  isSecured: false,
                  Validation: { [entity4.validationName]: entity4.validation },
                },
              };
              if (
                entity2.optionType != "password" &&
                entity2.optionType != "-1"
              ) {
                addKeys.two["options"] = entity2.optList;
              }
              if (
                entity3.optionType != "password" &&
                entity3.optionType != "-1"
              ) {
                addKeys.three["options"] = entity3.optList;
              }
              if (
                entity4.optionType != "password" &&
                entity4.optionType != "-1"
              ) {
                addKeys.four["options"] = entity4.optList;
              }
              entityData = [
                entitylistObj.Entities.one,
                addKeys.two,
                addKeys.three,
                addKeys.four,
              ];
            } else if (
              this.state.addIndex &&
              Object.keys(entitylistObj.Entities).length == 2
            ) {
              addKeys = {
                three: {
                  validationType: entity3.validationName,
                  Type: entity3.entityName,
                  param: "P3",
                  ValidationFailResponse: entity3.invalidEntity,
                  Question: entity3.question,
                  defaultValue: entity3.defaultValue,
                  questionoptiontype: entity3.optionType,
                  options: entity3.optList,
                  displaytype: entity3.display,
                  isEntityOptional: entity3.entityType,
                  rememberEntity: entity3.remember,
                  isSecured: false,
                  Validation: { [entity3.validationName]: entity3.validation },
                },
                four: {
                  validationType: entity4.validationName,
                  Type: entity4.entityName,
                  param: "P4",
                  ValidationFailResponse: entity4.invalidEntity,
                  Question: entity4.question,
                  defaultValue: entity4.defaultValue,
                  questionoptiontype: entity4.optionType,
                  options: entity4.optList,
                  displaytype: entity4.display,
                  isEntityOptional: entity4.entityType,
                  rememberEntity: entity4.remember,
                  isSecured: false,
                  Validation: { [entity4.validationName]: entity4.validation },
                },
              };

              if (
                entity3.optionType != "password" &&
                entity3.optionType != "-1"
              ) {
                addKeys.three["options"] = entity3.optList;
              }
              if (
                entity4.optionType != "password" &&
                entity4.optionType != "-1"
              ) {
                addKeys.four["options"] = entity4.optList;
              }
              entityData = [
                entitylistObj.Entities.one,
                entitylistObj.Entities.two,
                addKeys.three,
                addKeys.four,
              ];
            }

            if (
              !isValidationTypeExist &&
              isValidRegex &&
              !isValidationRegexExist
            ) {
              this.props.createUpdateEntity(
                {
                  botName: currentBot,
                  entityData: {
                    FinalValidationOptional:
                      entitylistObj.FinalValidationOptional,
                    Action: entitylistObj.Action,
                    entityList: entityData,
                  },
                  project: projectname,
                  language: language,
                },
                this.props.config.BOTBUILDER_URL,
                fromFlowModal,
                false,
                this.state.EntityOccurance
              );
              setTimeout(() => {
                this.closeEntities();
              }, 2000);
            }
          } else {
            if (
              !isValidationTypeExist &&
              isValidRegex &&
              !isValidationRegexExist
            ) {
              let listData = {
                validationType: entity1.validationName,
                Type: entity1.entityName,
                param: "P1",
                ValidationFailResponse: entity1.invalidEntity,
                Question: entity1.question,
                defaultValue: entity1.defaultValue,
                questionoptiontype: entity1.optionType,
                displaytype: entity1.display,
                isEntityOptional: entity1.entityType,
                rememberEntity: entity1.remember,
                isSecured: false,
                Validation: { [entity1.validationName]: entity1.validation },
              };
              if (
                entity1.optionType != "password" &&
                entity1.optionType != "-1"
              ) {
                listData["options"] = entity1.optList;
              }

              let listData2 = {
                validationType: entity2.validationName,
                Type: entity2.entityName,
                param: "P2",
                ValidationFailResponse: entity2.invalidEntity,
                Question: entity2.question,
                defaultValue: entity2.defaultValue,
                questionoptiontype: entity2.optionType,
                displaytype: entity2.display,
                isEntityOptional: entity2.entityType,
                rememberEntity: entity2.remember,
                isSecured: false,
                Validation: { [entity2.validationName]: entity2.validation },
              };
              if (
                entity2.optionType != "password" &&
                entity2.optionType != "-1"
              ) {
                listData2["options"] = entity2.optList;
              }
              let listData3 = {
                validationType: entity3.validationName,
                Type: entity3.entityName,
                param: "P3",
                ValidationFailResponse: entity3.invalidEntity,
                Question: entity3.question,
                defaultValue: entity3.defaultValue,
                questionoptiontype: entity3.optionType,
                displaytype: entity3.display,
                isEntityOptional: entity3.entityType,
                rememberEntity: entity3.remember,
                isSecured: false,
                Validation: { [entity3.validationName]: entity3.validation },
              };
              if (
                entity3.optionType != "password" &&
                entity3.optionType != "-1"
              ) {
                listData3["options"] = entity3.optList;
              }
              let listData4 = {
                validationType: entity4.validationName,
                Type: entity4.entityName,
                param: "P4",
                ValidationFailResponse: entity4.invalidEntity,
                Question: entity4.question,
                defaultValue: entity4.defaultValue,
                questionoptiontype: entity4.optionType,
                displaytype: entity4.display,
                isEntityOptional: entity4.entityType,
                rememberEntity: entity4.remember,
                isSecured: false,
                Validation: { [entity4.validationName]: entity4.validation },
              };
              if (
                entity4.optionType != "password" &&
                entity4.optionType != "-1"
              ) {
                listData4["options"] = entity4.optList;
              }
              this.props.createUpdateEntity(
                {
                  botName: currentBot,
                  entityData: {
                    FinalValidationOptional: "",
                    Action: "",
                    entityList: [listData, listData2, listData3, listData4],
                  },
                  project: projectname,
                  language: language,
                },
                this.props.config.BOTBUILDER_URL,
                fromFlowModal,
                false,
                this.state.EntityOccurance
              );
              setTimeout(() => {
                this.closeEntities();
              }, 2000);
            }
          }
        } else {
          if (
            !isValidationTypeExist &&
            isValidRegex &&
            !isValidationRegexExist
          ) {
            let listData = {
              validationType: entity1.validationName,
              Type: entity1.entityName,
              param: "P1",
              ValidationFailResponse: entity1.invalidEntity,
              Question: entity1.question,
              defaultValue: entity1.defaultValue,
              questionoptiontype: entity1.optionType,
              displaytype: entity1.display,
              isEntityOptional: entity1.entityType,
              rememberEntity: entity1.remember,
              isSecured: false,
              Validation: { [entity1.validationName]: entity1.validation },
            };
            if (
              entity1.optionType != "password" &&
              entity1.optionType != "-1"
            ) {
              listData["options"] = entity1.optList;
            }

            let listData2 = {
              validationType: entity2.validationName,
              Type: entity2.entityName,
              param: "P2",
              ValidationFailResponse: entity2.invalidEntity,
              Question: entity2.question,
              defaultValue: entity2.defaultValue,
              questionoptiontype: entity2.optionType,
              displaytype: entity2.display,
              isEntityOptional: entity2.entityType,
              rememberEntity: entity2.remember,
              isSecured: false,
              Validation: { [entity2.validationName]: entity2.validation },
            };
            if (
              entity2.optionType != "password" &&
              entity2.optionType != "-1"
            ) {
              listData2["options"] = entity2.optList;
            }
            let listData3 = {
              validationType: entity3.validationName,
              Type: entity3.entityName,
              param: "P3",
              ValidationFailResponse: entity3.invalidEntity,
              Question: entity3.question,
              defaultValue: entity3.defaultValue,
              questionoptiontype: entity3.optionType,
              displaytype: entity3.display,
              isEntityOptional: entity3.entityType,
              rememberEntity: entity3.remember,
              isSecured: false,
              Validation: { [entity3.validationName]: entity3.validation },
            };
            if (
              entity3.optionType != "password" &&
              entity3.optionType != "-1"
            ) {
              listData3["options"] = entity3.optList;
            }
            let listData4 = {
              validationType: entity4.validationName,
              Type: entity4.entityName,
              param: "P4",
              ValidationFailResponse: entity4.invalidEntity,
              Question: entity4.question,
              defaultValue: entity4.defaultValue,
              questionoptiontype: entity4.optionType,
              displaytype: entity4.display,
              isEntityOptional: entity4.entityType,
              rememberEntity: entity4.remember,
              isSecured: false,
              Validation: { [entity4.validationName]: entity4.validation },
            };
            if (
              entity4.optionType != "password" &&
              entity4.optionType != "-1"
            ) {
              listData4["options"] = entity4.optList;
            }
            this.props.createUpdateEntity(
              {
                botName: currentBot,
                entityData: {
                  FinalValidationOptional: "",
                  Action: "",
                  entityList: [listData, listData2, listData3, listData4],
                },
                project: projectname,
                language: language,
              },
              this.props.config.BOTBUILDER_URL,
              fromFlowModal,
              false,
              this.state.EntityOccurance
            );
            setTimeout(() => {
              this.closeEntities();
            }, 2000);
          }
        }
      }
    } else if (index == "5") {
      this.setValidationMessage("question", this.state.entity5.question);
      this.setValidationMessage(
        "defaultValue",
        this.state.entity5.defaultValue
      );
      if (entity5.validationText == "custom") {
        this.setValidationMessage("validationText", entity5.validationName);
        this.setValidationMessage("ValidationValue", entity5.validation);
      }
      if (
        this.state.entity5.question 
      ) {
        let isValidationTypeExist = false;
        let isValidationRegexExist = false;
        let isValidRegex = true;
        if (entity5.validationText == "custom") {
          if (entity5.validationName && entity5.validation) {
            let fileData = [];
            if (this.props.fileData) {
              fileData = JSON.parse(this.props.fileData);
              fileData = fileData.preValidationList;

              try {
                new RegExp(entity5.validation);
              } catch (e) {
                isValidRegex = false;
              }

              for (var i = 0; i < fileData.length; i++) {
                if (Object.keys(fileData[i])[0] == entity5.validationName) {
                  isValidationTypeExist = true;
                  break;
                } else if (
                  fileData[i][Object.keys(fileData[i])[0]] == entity5.validation
                ) {
                  isValidationRegexExist = true;
                  break;
                }
              }
              if (isValidationTypeExist) {
                this.setState({
                  emptyValidationTypeMsg: (
                    <ValidationMsg>Validation Type already exist</ValidationMsg>
                  ),
                });
              } else if (isValidationRegexExist) {
                this.setState({
                  emptyValidationValueMsg: (
                    <ValidationMsg>
                      Validation value already exist
                    </ValidationMsg>
                  ),
                });
              } else if (!isValidRegex) {
                this.setState({
                  emptyValidationValueMsg: (
                    <ValidationMsg>Please enter valid regex</ValidationMsg>
                  ),
                });
              } else {
                fileData.push({ [entity5.validationName]: entity5.validation });
                let validatioTypesObjString = JSON.stringify({
                  preValidationList: fileData,
                });
                let data = {
                  fileName: "validationTypes.json",
                  content: escape(validatioTypesObjString),
                  project: projectname,
                  language: "en",
                };
                this.props.onUpdateFile(data, this.props.config.API_URL);
                this.props.onreadFile(
                  {
                    fileName: "validationTypes.json",
                    project: projectname,
                    language: "en",
                  },
                  this.props.config.BOTBUILDER_URL
                );
                this.setState({ submitEntity5: true });
              }
            }
          }
        } else this.setState({ submitEntity5: true });
        if (
          this.props.entityList &&
          this.props.entityList != null &&
          this.props.entityList != "{}" &&
          !(this.props.entityList.indexOf("Failure") > -1)
        ) {
          let entitylistObj = JSON.parse(this.props.entityList);
          if (Object.keys(entitylistObj.Entities).length !== 0) {
            let activeBotList = entitylistObj;
            let addKeys = {};
            let entityData = [];
            if (!this.state.addIndex) {
              addKeys = {
                five: {
                  validationType: entity5.validationName,
                  Type: entity5.entityName,
                  param: "P5",
                  ValidationFailResponse: entity5.invalidEntity,
                  Question: entity5.question,
                  defaultValue: entity5.defaultValue,
                  questionoptiontype: entity5.optionType,
                  options: entity5.optList,
                  displaytype: entity5.display,
                  isEntityOptional: entity5.entityType,
                  rememberEntity: entity5.remember,
                  isSecured: false,
                  Validation: { [entity5.validationName]: entity5.validation },
                },
              };

              if (
                entity5.optionType != "password" &&
                entity5.optionType != "-1"
              ) {
                addKeys.five["options"] = entity5.optList;
              }
              entityData = [
                entitylistObj.Entities.one,
                entitylistObj.Entities.two,
                entitylistObj.Entities.three,
                entitylistObj.Entities.four,
                addKeys.five,
              ];
            } else if (
              this.state.addIndex &&
              Object.keys(entitylistObj.Entities).length == 1
            ) {
              addKeys = {
                two: {
                  validationType: entity2.validationName,
                  Type: entity2.entityName,
                  param: "P2",
                  ValidationFailResponse: entity2.invalidEntity,
                  Question: entity2.question,
                  defaultValue: entity2.defaultValue,
                  questionoptiontype: entity2.optionType,
                  options: entity2.optList,
                  displaytype: entity2.display,
                  isEntityOptional: entity2.entityType,
                  rememberEntity: entity2.remember,
                  isSecured: false,
                  Validation: { [entity2.validationName]: entity2.validation },
                },
                three: {
                  validationType: entity3.validationName,
                  Type: entity3.entityName,
                  param: "P3",
                  ValidationFailResponse: entity3.invalidEntity,
                  Question: entity3.question,
                  defaultValue: entity3.defaultValue,
                  questionoptiontype: entity3.optionType,
                  options: entity3.optList,
                  displaytype: entity3.display,
                  isEntityOptional: entity3.entityType,
                  rememberEntity: entity3.remember,
                  isSecured: false,
                  Validation: { [entity3.validationName]: entity3.validation },
                },
                four: {
                  validationType: entity4.validationName,
                  Type: entity4.entityName,
                  param: "P4",
                  ValidationFailResponse: entity4.invalidEntity,
                  Question: entity4.question,
                  defaultValue: entity4.defaultValue,
                  questionoptiontype: entity4.optionType,
                  options: entity4.optList,
                  displaytype: entity4.display,
                  isEntityOptional: entity4.entityType,
                  rememberEntity: entity4.remember,
                  isSecured: false,
                  Validation: { [entity4.validationName]: entity4.validation },
                },
                five: {
                  validationType: entity5.validationName,
                  Type: entity5.entityName,
                  param: "P5",
                  ValidationFailResponse: entity5.invalidEntity,
                  Question: entity5.question,
                  defaultValue: entity5.defaultValue,
                  questionoptiontype: entity5.optionType,
                  options: entity5.optList,
                  displaytype: entity5.display,
                  isEntityOptional: entity5.entityType,
                  rememberEntity: entity5.remember,
                  isSecured: false,
                  Validation: { [entity5.validationName]: entity5.validation },
                },
              };
              if (
                entity2.optionType != "password" &&
                entity2.optionType != "-1"
              ) {
                addKeys.two["options"] = entity2.optList;
              }
              if (
                entity3.optionType != "password" &&
                entity3.optionType != "-1"
              ) {
                addKeys.three["options"] = entity3.optList;
              }
              if (
                entity4.optionType != "password" &&
                entity4.optionType != "-1"
              ) {
                addKeys.four["options"] = entity4.optList;
              }
              if (
                entity5.optionType != "password" &&
                entity5.optionType != "-1"
              ) {
                addKeys.five["options"] = entity5.optList;
              }
              entityData = [
                entitylistObj.Entities.one,
                addKeys.two,
                addKeys.three,
                addKeys.four,
                addKeys.five,
              ];
            } else if (
              this.state.addIndex &&
              Object.keys(entitylistObj.Entities).length == 2
            ) {
              addKeys = {
                three: {
                  validationType: entity3.validationName,
                  Type: entity3.entityName,
                  param: "P3",
                  ValidationFailResponse: entity3.invalidEntity,
                  Question: entity3.question,
                  defaultValue: entity3.defaultValue,
                  questionoptiontype: entity3.optionType,
                  options: entity3.optList,
                  displaytype: entity3.display,
                  isEntityOptional: entity3.entityType,
                  rememberEntity: entity3.remember,
                  isSecured: false,
                  Validation: { [entity3.validationName]: entity3.validation },
                },
                four: {
                  validationType: entity4.validationName,
                  Type: entity4.entityName,
                  param: "P4",
                  ValidationFailResponse: entity4.invalidEntity,
                  Question: entity4.question,
                  defaultValue: entity4.defaultValue,
                  questionoptiontype: entity4.optionType,
                  options: entity4.optList,
                  displaytype: entity4.display,
                  isEntityOptional: entity4.entityType,
                  rememberEntity: entity4.remember,
                  isSecured: false,
                  Validation: { [entity4.validationName]: entity4.validation },
                },
                five: {
                  validationType: entity5.validationName,
                  Type: entity5.entityName,
                  param: "P5",
                  ValidationFailResponse: entity5.invalidEntity,
                  Question: entity5.question,
                  defaultValue: entity5.defaultValue,
                  questionoptiontype: entity5.optionType,
                  options: entity5.optList,
                  displaytype: entity5.display,
                  isEntityOptional: entity5.entityType,
                  rememberEntity: entity5.remember,
                  isSecured: false,
                  Validation: { [entity5.validationName]: entity5.validation },
                },
              };

              if (
                entity3.optionType != "password" &&
                entity3.optionType != "-1"
              ) {
                addKeys.three["options"] = entity3.optList;
              }
              if (
                entity4.optionType != "password" &&
                entity4.optionType != "-1"
              ) {
                addKeys.four["options"] = entity4.optList;
              }
              if (
                entity5.optionType != "password" &&
                entity5.optionType != "-1"
              ) {
                addKeys.five["options"] = entity5.optList;
              }
              entityData = [
                entitylistObj.Entities.one,
                entitylistObj.Entities.two,
                addKeys.three,
                addKeys.four,
                addKeys.five,
              ];
            } else if (
              this.state.addIndex &&
              Object.keys(entitylistObj.Entities).length == 3
            ) {
              addKeys = {
                four: {
                  validationType: entity4.validationName,
                  Type: entity4.entityName,
                  param: "P4",
                  ValidationFailResponse: entity4.invalidEntity,
                  Question: entity4.question,
                  defaultValue: entity4.defaultValue,
                  questionoptiontype: entity4.optionType,
                  options: entity4.optList,
                  displaytype: entity4.display,
                  isEntityOptional: entity4.entityType,
                  rememberEntity: entity4.remember,
                  isSecured: false,
                  Validation: { [entity4.validationName]: entity4.validation },
                },
                five: {
                  validationType: entity5.validationName,
                  // validationregexpopt: entity5.validation,
                  Type: entity5.entityName,
                  param: "P5",
                  ValidationFailResponse: entity5.invalidEntity,
                  Question: entity5.question,
                  defaultValue: entity5.defaultValue,
                  questionoptiontype: entity5.optionType,
                  options: entity5.optList,
                  displaytype: entity5.display,
                  isEntityOptional: entity5.entityType,
                  rememberEntity: entity5.remember,
                  isSecured: false,
                  Validation: { [entity5.validationName]: entity5.validation },
                },
              };

              if (
                entity4.optionType != "password" &&
                entity4.optionType != "-1"
              ) {
                addKeys.four["options"] = entity4.optList;
              }
              if (
                entity5.optionType != "password" &&
                entity5.optionType != "-1"
              ) {
                addKeys.five["options"] = entity5.optList;
              }
              entityData = [
                entitylistObj.Entities.one,
                entitylistObj.Entities.two,
                entitylistObj.Entities.three,
                addKeys.four,
                addKeys.five,
              ];
            }
            if (
              !isValidationTypeExist &&
              isValidRegex &&
              !isValidationRegexExist
            )
              this.props.createUpdateEntity(
                {
                  botName: currentBot,
                  entityData: {
                    FinalValidationOptional: "",
                    Action: "",
                    entityList: entityData,
                  },
                  project: projectname,
                  language: language,
                },
                this.props.config.BOTBUILDER_URL,
                fromFlowModal,
                false,
                this.state.EntityOccurance
              );
          } else {
            if (
              !isValidationTypeExist &&
              isValidRegex &&
              !isValidationRegexExist
            ) {
              let listData = {
                validationType: entity1.validationName,
                Type: entity1.entityName,
                param: "P1",
                ValidationFailResponse: entity1.invalidEntity,
                Question: entity1.question,
                defaultValue: entity1.defaultValue,
                questionoptiontype: entity1.optionType,
                displaytype: entity1.display,
                isEntityOptional: entity1.entityType,
                rememberEntity: entity1.remember,
                isSecured: false,
                Validation: { [entity1.validationName]: entity1.validation },
              };
              if (
                entity1.optionType != "password" &&
                entity1.optionType != "-1"
              ) {
                listData["options"] = entity1.optList;
              }

              let listData2 = {
                validationType: entity2.validationName,
                Type: entity2.entityName,
                param: "P2",
                ValidationFailResponse: entity2.invalidEntity,
                Question: entity2.question,
                defaultValue: entity2.defaultValue,
                questionoptiontype: entity2.optionType,
                displaytype: entity2.display,
                isEntityOptional: entity2.entityType,
                rememberEntity: entity2.remember,
                isSecured: false,
                Validation: { [entity2.validationName]: entity2.validation },
              };
              if (
                entity2.optionType != "password" &&
                entity2.optionType != "-1"
              ) {
                listData2["options"] = entity2.optList;
              }
              let listData3 = {
                validationType: entity3.validationName,
                Type: entity3.entityName,
                param: "P3",
                ValidationFailResponse: entity3.invalidEntity,
                Question: entity3.question,
                defaultValue: entity3.defaultValue,
                questionoptiontype: entity3.optionType,
                displaytype: entity3.display,
                isEntityOptional: entity3.entityType,
                rememberEntity: entity3.remember,
                isSecured: false,
                Validation: { [entity3.validationName]: entity3.validation },
              };
              if (
                entity3.optionType != "password" &&
                entity3.optionType != "-1"
              ) {
                listData3["options"] = entity3.optList;
              }
              let listData4 = {
                validationType: entity4.validationName,
                Type: entity4.entityName,
                param: "P4",
                ValidationFailResponse: entity4.invalidEntity,
                Question: entity4.question,
                defaultValue: entity4.defaultValue,
                questionoptiontype: entity4.optionType,
                displaytype: entity4.display,
                isEntityOptional: entity4.entityType,
                rememberEntity: entity4.remember,
                isSecured: false,
                Validation: { [entity4.validationName]: entity4.validation },
              };
              if (
                entity4.optionType != "password" &&
                entity4.optionType != "-1"
              ) {
                listData4["options"] = entity4.optList;
              }
              let listData5 = {
                validationType: entity5.validationName,
                Type: entity5.entityName,
                param: "P5",
                ValidationFailResponse: entity5.invalidEntity,
                Question: entity5.question,
                defaultValue: entity5.defaultValue,
                questionoptiontype: entity5.optionType,
                displaytype: entity5.display,
                isEntityOptional: entity5.entityType,
                rememberEntity: entity5.remember,
                isSecured: false,
                Validation: {
                  [entity5.validationName]: entity5.validation,
                },
              };
              if (
                entity5.optionType != "password" &&
                entity5.optionType != "-1"
              ) {
                listData5["options"] = entity5.optList;
              }
              this.props.createUpdateEntity(
                {
                  botName: currentBot,
                  entityData: {
                    FinalValidationOptional: "",
                    Action: "",
                    entityList: [
                      listData,
                      listData2,
                      listData3,
                      listData4,
                      listData5,
                    ],
                  },
                  project: projectname,
                  language: language,
                },
                this.props.config.BOTBUILDER_URL,
                fromFlowModal,
                false,
                this.state.EntityOccurance
              );
            }
          }
        } else {
          if (
            !isValidationTypeExist &&
            isValidRegex &&
            !isValidationRegexExist
          ) {
            let listData = {
              validationType: entity1.validationName,
              Type: entity1.entityName,
              param: "P1",
              ValidationFailResponse: entity1.invalidEntity,
              Question: entity1.question,
              defaultValue: entity1.defaultValue,
              questionoptiontype: entity1.optionType,
              displaytype: entity1.display,
              isEntityOptional: entity1.entityType,
              rememberEntity: entity1.remember,
              isSecured: false,
              Validation: { [entity1.validationName]: entity1.validation },
            };
            if (
              entity1.optionType != "password" &&
              entity1.optionType != "-1"
            ) {
              listData["options"] = entity1.optList;
            }

            let listData2 = {
              validationType: entity2.validationName,
              Type: entity2.entityName,
              param: "P2",
              ValidationFailResponse: entity2.invalidEntity,
              Question: entity2.question,
              defaultValue: entity2.defaultValue,
              questionoptiontype: entity2.optionType,
              displaytype: entity2.display,
              isEntityOptional: entity2.entityType,
              rememberEntity: entity2.remember,
              isSecured: false,
              Validation: { [entity2.validationName]: entity2.validation },
            };
            if (
              entity2.optionType != "password" &&
              entity2.optionType != "-1"
            ) {
              listData2["options"] = entity2.optList;
            }
            let listData3 = {
              validationType: entity3.validationName,
              Type: entity3.entityName,
              param: "P3",
              ValidationFailResponse: entity3.invalidEntity,
              Question: entity3.question,
              defaultValue: entity3.defaultValue,
              questionoptiontype: entity3.optionType,
              displaytype: entity3.display,
              isEntityOptional: entity3.entityType,
              rememberEntity: entity3.remember,
              isSecured: false,
              Validation: { [entity3.validationName]: entity3.validation },
            };
            if (
              entity3.optionType != "password" &&
              entity3.optionType != "-1"
            ) {
              listData3["options"] = entity3.optList;
            }
            let listData4 = {
              validationType: entity4.validationName,
              Type: entity4.entityName,
              param: "P4",
              ValidationFailResponse: entity4.invalidEntity,
              Question: entity4.question,
              defaultValue: entity4.defaultValue,
              questionoptiontype: entity4.optionType,
              displaytype: entity4.display,
              isEntityOptional: entity4.entityType,
              rememberEntity: entity4.remember,
              isSecured: false,
              Validation: { [entity4.validationName]: entity4.validation },
            };
            if (
              entity4.optionType != "password" &&
              entity4.optionType != "-1"
            ) {
              listData4["options"] = entity4.optList;
            }
            let listData5 = {
              validationType: entity5.validationName,
              Type: entity5.entityName,
              param: "P5",
              ValidationFailResponse: entity5.invalidEntity,
              Question: entity5.question,
              defaultValue: entity5.defaultValue,
              questionoptiontype: entity5.optionType,
              displaytype: entity5.display,
              isEntityOptional: entity5.entityType,
              rememberEntity: entity5.remember,
              isSecured: false,
              Validation: {
                [entity5.validationName]: entity5.validation,
              },
            };
            if (
              entity5.optionType != "password" &&
              entity5.optionType != "-1"
            ) {
              listData5["options"] = entity5.optList;
            }
            this.props.createUpdateEntity(
              {
                botName: currentBot,
                entityData: {
                  FinalValidationOptional: "",
                  Action: "",
                  entityList: [
                    listData,
                    listData2,
                    listData3,
                    listData4,
                    listData5,
                  ],
                },
                project: projectname,
                language: language,
              },
              this.props.config.BOTBUILDER_URL,
              fromFlowModal,
              false,
              this.state.EntityOccurance
            );
          }
        }
      }
      setTimeout(() => {
        this.closeEntities();
      }, 2000);
    }
    setTimeout(() => {
      this.props.ongetEntity(
        { language: language, projectname: projectname, botName: currentBot },
        this.props.config.BOTBUILDER_URL
      );
    }, 4000);
    this.handleClose("entity");
  }

  saveConfiguration(e, index, active, currentSelBot) {
    e.preventDefault();
    const { entity1 } = this.state;
    let projectname = localStorage.getItem("projectname");
    let language = localStorage.getItem("language");
    let orgName = localStorage.getItem("orgName");
    if (!projectname) projectname = "common";
    if (!language) {
      language = "en";
    }
    let currentBot = currentSelBot;
    if (!currentBot || currentBot === undefined || currentBot === null) {
      currentBot = this.state.currentBotSelected;
    }
    let urlName = entity1.urlName;
    let value = entity1.defaultValue;
    this.props.OnBConfiguration(
      {
        botname: currentBot,
        profile: currentBot + "__" + this.state.profileval,
        config: { urlName: value },
        input: {},
        orgName: orgName,
        project: projectname,
        language: language,
      },
      this.props.config.API_URL
    );
    this.handleClose("configureModal");
    setTimeout(() => {
      this.closeEntities();
    }, 2000);
  }

  closeEntities() {
    this.setState({
      setEntityShow: false,
      indexValue: "1",
      submitEntity1: false,
      selectedBotList: "",
      selectedEntity: "",
      emptyQuestionMsg: null,
      emptyDefaultMsg: null,
      validateOptionMsg: null,
      emptyValidationValueMsg: null,
      emptyValidationTypeMsg: null,
      emptyValidationMsg:null,
      emptyValidationNameMsg:null,
      entity1: {
        entityName: "Input_01",
        validation: "",
        validationName: "",
        invalidEntity: "",
        validationText: "",
        question: "",
        entityType: "false",
        remember: "false",
        defaultValue: "",
        optionType: "-1",
        display: "-1",
        addOption: false,
        addOptionValue: "",
        addOptionList: [],
        optList: [],
      },
      activeEntity: false,
      emptydisplayMsg:true,
    });
  }
  runBot = (profilename) => {
    let selectedLanguage = "en";
    window.HCLBot_WebSocket(
      '{"botname":"' +
        profilename +
        '","project":"' +
        currentProject +
        '","triggertype":"Manual","input":"{}","language":"' +
        selectedLanguage +
        '"}'
    );
  };
  GetConnectData = () => {
    const selectedLanguage = "en";
    const selectedProject = localStorage.getItem("projectname");
    const query = {
      search_request: {
        _source: ["Description", "Title", "Category", "categoryorg"],
        query: {
          query_string: {
            query: "*",
          },
        },
        size: 10000,
      },
      include_hits: "true",
      query_hint: "true",
      field_mapping: {
        title: ["_source.categoryorg"],
      },
    };
    let requestObject = {
      url:
        "demotest/" +
        selectedLanguage +
        "_" +
        selectedProject +
        "/_search_with_clusters",
      data: JSON.stringify(query),
    };

    this.props.OnGetClusterData(this.props.config.API_URL, requestObject);
  };

  componentDidUpdate(prevprops,prevState) {
    if (this.props.otherDocsPermission.WR && !this.props.AIStandard.RW){
      selectedNav = 'OtherDocs';
    }else{
     selectedNav = 'Intents'
    }
    // this.props.onKnowledgeGridExpandCollapse("collapse")
    // this.props.OnProjectHeaderShow(true)
    
    // !this.state.isCUXBotSelected &&
    //           this.state.isKnowledgeContentSelected &&

    // if(prevprops.showProjectHeader !== undefined && this.props.showProjectHeader !== prevprops.showProjectHeader && this.props.showProjectHeader == true){
    //   this.setState({selectedKnowledge:"Intents",isKnowledgeContentSelected:true})
    //   this.setState({isFlowSelected: true,isCUXBotSelected: false})
    // }
    if(this.state.AddIntentcontrols != prevState.AddIntentcontrols) {
      if(this.state.AddIntentcontrols.intentDesc.value.trim() === "") {
        this.setState({
          descp:true
        })
      }
      else {
        this.setState({
          descp:false
        })
      }
    }
    if (
      this.props.selectedFlowName &&
      this.props.selectedFlowName !== this.state.currentBotSelected
    ) {
      this.setState({
        currentBotSelected: this.props.selectedFlowName,
        isFlowSelectedFlow: true,
        isFlowSelected: true,
        KnowledgeMacroExpand: false,
      });

      this.setSelected("Train");
      this.currentBotEvent(
        true,
        this.props.selectedFlowName,
        this.props.selectedFlowName + "__" + this.props.selectedFlowName,
        false
      );
    }
    if (
      this.props.intentListDataScore &&
      this.props.intentListDataScore !== prevprops.intentListDataScore
    ) {
      this.projectAccuracyScoreSucess(this.props.intentListDataScore);
    }

    if (
      this.props.createProjectAccuracy &&
      this.props.createProjectAccuracy !== prevprops.createProjectAccuracy
    ) {
      this.setState({ projectAccuracy: this.props.createProjectAccuracy });
    }

    if (
      this.props.clusterData &&
      this.props.clusterData !== prevprops.clusterData
    ) {
      const data = JSON.parse(this.props.clusterData);
      if (data && data.hasOwnProperty("clusters") && data.clusters.length > 0) {
        this.setState({ clusterData: data });
      }
    }

    if (
      this.props.importConnectData &&
      this.props.importConnectData !== prevprops.importConnectData
    ) {
      this.GetConnectData();
    }
    if (
      this.props.createBotloading &&
      this.props.createBotloading !== prevprops.createBotloading
    ) {
      if (typeof this.props.createBotloading === "number") {
        const importClusterLoading = this.state.importClusterLoading;
        if (importClusterLoading) {
          const totalClusterstoImport = this.state.importCLusterCount.Total;
          let completedClustersImport = this.state.importCLusterCount.completed;

          if (totalClusterstoImport) {
            if (totalClusterstoImport > 1) {
              completedClustersImport = completedClustersImport + 1;
              // multiple cluster import
              if (totalClusterstoImport !== completedClustersImport) {
                this.setState({
                  importCLusterCount: {
                    ...this.state.importCLusterCount,
                    completed: this.state.importCLusterCount.completed + 1,
                  },
                });
              } else {
                this.setState({
                  importClusterLoading: false,
                  IsClusterImported: false,
                  importCLusterCount: {
                    ...this.state.importCLusterCount,
                    completed: this.state.importCLusterCount.completed + 1,
                  },
                });
                this.props.onSuccessImportCluster(
                  "Flow(s) created successfully"
                );
              }
            } else {
              this.setState({
                importClusterLoading: false,
                IsClusterImported: false,
                importCLusterCount: {
                  ...this.state.importCLusterCount,
                  completed: this.state.importCLusterCount.completed + 1,
                },
              });
              this.props.onSuccessImportCluster("Flow created successfully");
              setTimeout(() => {
                this.setState({
                  isKnowledgeContentSelected: true,
                  selectedKnowledge: selectedNav,
                  ShowRightPanel: false,
                  currentBotSelected: "",
                  refreshKnowledgeIntent: false,
                  isCUXBotSelected: false,
                  isFaqNavigation:false,
                  isOtherDocsNavigation:false,
                });
              }, 2000);
              
              // this.setState({
              //   isKnowledgeContentSelected: true,
              //   selectedKnowledge: "Intents",
              // });
            }
          }
        }
      }
    }
    // if (
    //   this.props.botCreated &&
    //   this.props.botCreated !== prevprops.botCreated
    // ) {
    //   this.setState({
    //     isKnowledgeContentSelected: true,
    //     selectedKnowledge: "Intents",
    //   });
    // }

    if (this.props.location !== prevprops.location) {
      botselected = "";
      this.onRouteChanged();
      clusertHtml = null;
      this.setState({ clusterData: null });
      this.setState({ refreshKnowledgeIntent: true });
      this.setState({ isKnowledgeContentSelected: true });
    } else if (this.state.refreshKnowledgeIntent === true) {
      this.setState({ refreshKnowledgeIntent: false });
    }
    if (
      prevprops.importflowStart !== this.props.importflowStart &&
      prevprops.importflowStart !== true
    )
      this.handleClose("ImportModal");
    if (this.props.intentData !== prevprops.intentData) {
      let intentObj = "";
      let projectname = localStorage.getItem("projectname");
      let language = localStorage.getItem("language");
      let lang='';
      let collection = "optimus";
      if (!projectname) projectname = "common";

      if (!language || language == "en") {
        language = "en";
        collection = "optimus";
      } else {
        lang = language;
        collection = "optimus_" + lang;
      }
      let bot = this.state.currentBotSelected + "__";
      intentObj = this.props.intentData;
      if (intentObj && intentObj[language]) {
        intentObj = intentObj[language][projectname];
        if (intentObj && intentObj !== undefined) {
          intentObj = Object.keys(intentObj).find((key) =>
            intentObj[key].includes(bot)
          );
          if (intentObj) {
            let docvalue = {
              projectname: projectname,
              language: language,
              botName: intentObj,
            };
            this.props.OnGetDoc(docvalue, this.props.config.BOTBUILDER_URL);
          }
        }
      }
    }

    if (this.props.docData !== prevprops.docData) {
      let intentObj = "";
      let projectname = localStorage.getItem("projectname");
      let language = localStorage.getItem("language");
      let bot = this.state.currentBotSelected;
      if (this.props.docData) {
        let intentContent = this.props.docData;
        if (intentContent.intent == bot) {
          let learn = [];
          let reLearn = [];
          if (intentContent.training.learn) {
            learn = intentContent.training.learn.split("\n");
            let patterns = [];
            learn.map((item, idx) => {
              if (item !== "" && item !== "undefined") {
                if (item.includes("<br>")) {
                  const items = item.split("<br>");
                  items.map((name) => {
                    if (name) {
                      patterns.push({ name });
                    }
                  });
                  return patterns;
                } else {
                  patterns.push({ name: item });
                }
              }
            });
            if (patterns.length === 0) {
              patterns = [{ name: "" }];
            }
            this.setState({
              rows: patterns,
              AddIntentcontrols: {
                ...this.state.AddIntentcontrols,
                intentDesc: {
                  ...this.state.AddIntentcontrols.intentDesc,
                  value: intentContent.description,
                },
              },
            });

            // Auto training tab
            if (intentContent.training.relearn) {
              reLearn = intentContent.training.relearn.split("\n");
              let reLearnpatterns = [];
              reLearn.map((item, idx) => {
                return item !== "" && item !== "undefined"
                  ? reLearnpatterns.push({ name: item })
                  : reLearnpatterns;
              });
              if (reLearnpatterns.length === 0) {
                reLearnpatterns = [{ name: "                " }];
              }
              this.setState({
                reLearnRows: reLearnpatterns,
              });
            } else {
              this.setState({
                reLearnRows: [{ name: "          " }],
              });
            }
          } else {
            this.setState({
              rows: [{ name: "" }],
            });
          }
        } else {
          this.setState({
            rows: [{ name: "" }],
          });
        }
      } else {
        this.setState({
          rows: [{ name: "" }],
        });
      }
    }

    if (this.props.botList && this.props.botList !== prevprops.botList) {
    }

    if (this.props.cuxBotId && this.props.cuxBotId !== prevprops.cuxBotId) {
      this.setState({
        isCUXBotSelected: true,
        selectedCUXBotId: this.props.cuxBotId,
      });
    }
    if(this.props.getprojDesp&&this.props.getprojDesp!=prevprops.getprojDesp){
      this.setState({
        projectDescription:this.props.getprojDesp
      })
    }
  }
  onRouteChanged() {
    this.setState({
      selected: "Train",
      scriptSelected: false,
      ShowRightPanel: true,
      currentBotSelected: this.state.currentBotSelected,
    });
    if (this.state.selectedKnowledge === "Synonyms & Acronyms") {
      this.setState({
        scriptSelected: false,
        selectedKnowledge: "Synonyms & Acronyms",
        ShowRightPanel: false,
      });
    }
    if (this.state.selectedKnowledge === "Knowledge") {
      this.setState({
        scriptSelected: false,
        selectedKnowledge: "Knowledge",
        ShowRightPanel: false,
      });
    }
    if (this.state.selectedKnowledge === "OtherDocs") {
      this.setState({
        scriptSelected: false,
        selectedKnowledge: "OtherDocs",
        ShowRightPanel: false,
      });
    }
    if (this.state.selectedKnowledge === "Multimedia") {
      this.setState({
        scriptSelected: false,
        selectedKnowledge: "Multimedia",
        ShowRightPanel: false,
      });
    }
    if (this.state.selectedKnowledge === "Intents") {
      this.setState({
        scriptSelected: false,
        selectedKnowledge: "Intents",
        ShowRightPanel: false,
      });
    }
  }
  componentDidMount() {
    this.props.OnProjectHeaderShow(true)
    // localStorage.setItem("DeleteProjName", null);
    encryptAndStore("DeleteProjName", null);
    this.props.onGetmappedData(this.props.config.BOTBUILDER_URL);
    window.scrollTo(0, 0);
    let projectName = "";
    let botName = "";
    if (this.props.location.search) {
      const query = new URLSearchParams(this.props.location.search);
      for (let param of query.entries()) {
        if (param[0] == "projname") {
          projectName = param[1];
          // localStorage.setItem("projectname", projectName);
          encryptAndStore("projectname", projectName);

          if (param[1] == "botName") {
            this.setState({ currentBotSelected: param[1] });
          }
        } else if (param[0] == "botname") {
          botName = param[1];
          this.setState({
            isKnowledgeContentSelected: true,
            selectedKnowledge: "Intents",
          });
        }
      }
      currentProject = projectName;

      if (projectName !== "" && botName !== "") {
        botselected = botName;
      } else {
        botselected = "";
      }
    }
    let projectname = localStorage.getItem("projectname");
    let orgName = localStorage.getItem("orgName");
    this.props.onreadFile(
      {
        fileName: "validationTypes.json",
        project: "",
        language: "en",
      },
      this.props.config.BOTBUILDER_URL
    );
   
    this.props.OnGetBotList(this.props.config.BOTBUILDER_URL);
    this.props.onGetUserById(
      this.props.config.API_URL,
      this.props.config.BOTBUILDER_PROXY
    );
    this.props.onGetCuxBotList(this.props.config.COMMON_API_URL,projectname);
    //to check if org belongs to script-based org list
    if (this.props.config.BOT_ORGS.includes(orgName)) {
      this.setState({
        isCuxOrg: false,
      });
    }
    if(this.props.getprojDesp){
      this.setState({
        projectDescription:this.props.getprojDesp
      })
    }
    // this.setState({selectedKnowledge:'Intents'})
    setTimeout(() => {
        this.setState({
            isKnowledgeContentSelected: true,
            selectedKnowledge: selectedNav,
            ShowRightPanel: false,
            currentBotSelected: "",
            refreshKnowledgeIntent: false,
            isCUXBotSelected: false,
            isFaqNavigation:false,
            isOtherDocsNavigation:false,
        });
    }, 2000);
    
  }
  setFlowModalShowHide = () => {
    this.clearAddFlowStateValue();
    this.clearAddFlowEntitiesValue();
    if (this.props.IsTabChanged) {
      this.state.isFlowModalShow
        ? this.setState({ isFlowModalShow: false })
        : this.setState({ isFlowModalShow: true, activeEntity: false });
      this.clearAddFlowStateValue();
      this.clearAddFlowEntitiesValue();
    } else {
      this.setState({
        showSavePopup: true,
      });
    }
  };
  showBotModal = () => {
    this.setState({ isBotModalShow: true });
  };

  setImportFlowModal = () => {
    this.setState({ setImportFlowShow: true });
  };

  setImportCuxBotModal = () => {
    this.setState({ setImportCuxBotShow: true });
  };

  ShowTriggerTypeModal = () => {
    this.setState({ isTriggerTypeModalShow: true });
  };

  ShowCreateProfileModal = () => {
    this.setState({ isCreateProfileModalShow: true });
  };

  ShowConfigureModal = (profileVal) => {
    this.setState({ isConfigureModalShow: true, profileval: profileVal });
  };

  createProjectAccuracyModal = () => {
    let projectname = localStorage.getItem("projectname");
    let organizationName = localStorage.getItem("orgName");
    let language = localStorage.getItem("language");
    if (!language || language == "en") language = "en";
    let bots = null;
    let projectList = null;
    let botlist = JSON.parse(this.props.botList);
    let botEnglish = botlist[language];
    let botsprofile = [];
    let splitedValue = [];
    if (botEnglish) {
      if (botEnglish.hasOwnProperty(projectname)) {
        projectList = botlist[language][projectname];
        bots = Object.values(projectList);
        bots.map((item) => {
          botsprofile.push(item[0].triggerType);
        });
      }
    }
    for (var index = 0; index < botsprofile.length - 1; index++) {
      let chatBotArray = botsprofile[index].split("&&");
      splitedValue[index] = chatBotArray[1];
    }
    let itentListValue = splitedValue.join(",");
    let data = {
      orgName: organizationName,
      project: projectname,
      language: language,
      intentList: itentListValue,
      input: {},
    };
    this.props.OnIntentListDataScore(this.props.config.API_URL, data);
  };

  projectAccuracyScoreSucess(data) {
    scorecount = 0;
    scoresum = 0;
    projectSum = 0;
    intentCount = 0;
    var isEmptyIntent = false;
    var emptyIntentCount = 0;
    let projectname = localStorage.getItem("projectname");
    for (var k1 in data) {
      if (data[k1] != "[]") {
        this.eachRecursive(JSON.parse(data[k1]));
        projectSum =
          projectSum + parseFloat((scoresum / scorecount).toFixed(2));
        intentCount = intentCount + 1;
        scoresum = 0;
        scorecount = 0;
      } else {
        isEmptyIntent = true;
        emptyIntentCount = emptyIntentCount + 1;
      }
    }
    if (projectSum > 0) {
      var projAccuracy = (projectSum / intentCount).toFixed(2);
      this.createProjectAccuracyInES(projAccuracy);
    }
    if (isEmptyIntent) {
      this.props.OnHandleProjectAccuracyError(
        projectname + " project does not have accuracy:please add Intent"
      );
    }
  }
  createProjectAccuracyInES(projAccuracy) {
    let projectname = localStorage.getItem("projectname");
    let language = localStorage.getItem("language");
    if (!language || language == "en") language = "en";
    let currentDate = this.getCurrentDate();
    let projectAccuracyData = {
      collection: "accuracycollection",
      project: projectname,
      language: language,
      LogDate: currentDate,
      Accuracy: projAccuracy,
    };
    this.props.OnCreateProjectAccuracy(
      this.props.config.API_URL,
      projectAccuracyData
    );
    this.setState({ isProjectAccuracyModelShow: true });
  }
  eachRecursive(parsedData) {
    for (var k in parsedData) {
      if (typeof parsedData[k] == "object" && parsedData[k] !== null)
        this.eachRecursive(parsedData[k]);
      else if (k == "score") {
        scorecount = scorecount + 1;
        scoresum = scoresum + parseInt(parsedData.score);
      }
    }
  }

  initChatBotModal = () => {
    let data = {
      project: localStorage.getItem("projectname"),
      language: localStorage.getItem("language"),
      orgName: localStorage.getItem("orgName"),
    };
	var intent = this.state.currentBotSelected;
    this.submitInitChatBot(data,intent);
  };

  showTranslateModel = () => {
    this.setState({
      showTranslate: true,
    });
  };

  submitInitChatBot = (data,intent) => {
    this.props.OnInitChatBot(this.props.config.INSIGHTS_API_URL, data, intent);
    $(".dropdownCustomClass  .dropdown-item").removeClass("active");
  };

  duplicateFlowCheck = (flowItem,newFlowItem) => {
    let flowname = flowItem.flowname;
    if(flowname.indexOf('Template_') === 0) {
      flowname = flowname.substring(9);
    }
    return flowname.toLowerCase() === newFlowItem.toLowerCase();
  }

   handlesubmitAddCuxBot (event) {
    event.preventDefault();
      let projectname = localStorage.getItem("projectname");
      if (!projectname) projectname = "common";
      let body = {
        flowname: this.state.cuxBotName,
        projectname: projectname,
        flowcontent: [],
      };
      
      this.props.onCreateCuxBot(this.props.config.COMMON_API_URL, body);
      this.handleClose("CuxBotModal");
      this.setState({
        selectedCUXBot: this.state.cuxBotName,
        currentBotSelected: this.state.cuxBotName,
        cuxBotName:''
      });      
    
  }

  async handlesubmitAddFlow(event, index, active) {
    event.preventDefault();

    if (this.state.emptybotnameMsg) return true;
    else {
      this.setValidationMessage(
        "botname",
        this.state.AddFlowControls.botname.value
      );

      let projectname = localStorage.getItem("projectname");
      let language = localStorage.getItem("language");
      let lang='';
      let collection = "optimus";
      if (!projectname) projectname = "common";

      if (!language) {
        language = "en";
        collection = "optimus";
      } else {
        lang = language;
        collection = "optimus_" + lang;
      }
      let returnType = true;
      if (
        this.state.AddFlowControls.botname.value &&
        /^[A-Z0-9./_]+$/i.test(this.state.AddFlowControls.botname.value)
      ) {
        if (this.state.AddFlowControls.botEntityInputs.value > 0) {
          returnType = this.checkValidity(index);
          if (returnType) {
            let bot = {
              botName: this.state.AddFlowControls.botname.value,
              description: this.state.AddFlowControls.botdescription.value,
              project: projectname,
              language: language,
              response: "",
              intentTraining: { learn: [], relearn: [] },
            };
            await this.props.OnCreateBot(this.props.config.BOTBUILDER_URL, bot, false, false, this.state.isCuxOrg);
            let intentList = {
              botName: this.state.AddFlowControls.botname.value,
              profileName: this.state.AddFlowControls.botdescription.value,
              value: "ChatBot&&" + this.state.AddFlowControls.botname.value,
              project: projectname,
              language: language,
              triggerType: "chatbot",
            };
            await this.props.OnCreateBot(this.props.config.BOTBUILDER_URL, bot, false, false, this.state.isCuxOrg);
            this.handleClose("FlowModal");
            await new Promise((resolve) =>
              setTimeout(() => {
                this.saveEntity(
                  event,
                  index,
                  active,
                  this.state.AddFlowControls.botname.value
                );
                return resolve(0);
              }, 5000)
            );
            this.setState({
              currentBotSelected: this.state.AddFlowControls.botname.value,
            });
            if (this.state.AddFlowControls.botEntityInputs.value > 0) {
              await this.props.ongetEntity(
                {
                  language: language,
                  projectname: projectname,
                  botName: this.state.AddFlowControls.botname.value,
                },
                this.props.config.BOTBUILDER_URL
              );
            }
            this.setState({
                ShowRightPanel: true,
                isKnowledgeContentSelected: true,
                selectedKnowledge: "Intents",
                // ShowRightPanel: false,
                //currentBotSelected: "",
                refreshKnowledgeIntent: false,
                isCUXBotSelected: false,
                isFaqNavigation:false,
                isOtherDocsNavigation:false,
            });

            returnType = true;
          }
        } else {
          let bot = {
            botName: this.state.AddFlowControls.botname.value,
            description: this.state.AddFlowControls.botdescription.value,
            project: projectname,
            language: language,
            response: "",
            intentTraining: null,
            entityData: {
              entityList: [],
              Action: "",
              FinalValidationOptional: "",
            },
          };

          let intentList = {
            botName: this.state.AddFlowControls.botname.value,
            profileName: this.state.AddFlowControls.botdescription.value,
            value: "ChatBot&&" + this.state.AddFlowControls.botname.value,
            project: projectname,
            language: language,
            triggerType: "chatbot",
          };
          await this.props.OnCreateBot(this.props.config.BOTBUILDER_URL, bot, false, false, this.state.isCuxOrg);
          this.setState({
            currentBotSelected: this.state.AddFlowControls.botname.value,
          });
          if (this.state.AddFlowControls.botEntityInputs.value > 0) {
            this.props.ongetEntity(
              {
                language: language,
                projectname: projectname,
                botName: this.state.AddFlowControls.botname.value,
              },
              this.props.config.BOTBUILDER_URL
            );
          }
          this.setState({
            ShowRightPanel: true,
            isKnowledgeContentSelected: true,
            selectedKnowledge: "Intents",
            // ShowRightPanel: false,
            //currentBotSelected: "",
            refreshKnowledgeIntent: false,
            isCUXBotSelected: false,
            isFaqNavigation:false,
            isOtherDocsNavigation:false,
          });
        }
        if (returnType) {
          this.clearAddFlowEntitiesValue();
        
          this.setState({
            AddIntentcontrols: {
              ...this.state.AddIntentcontrols,
              intentName: {
                ...this.state.AddIntentcontrols.intentName,
                value: this.state.AddFlowControls.botname.value,
              },
              intentDesc: {
                ...this.state.AddIntentcontrols.intentDesc,
                value: this.state.AddFlowControls.botdescription.value,
              },
            },
          });
          this.clearAddFlowStateValue();
          this.handleClose("FlowModal");
          this.setState({ rows: [{ name: "" }] });
        }
        this.setState({ isFlowSelected: true, isFlowSelectedFlow: true, isCUXBotSelected: false });
        this.setSelected("Train");
        this.updateNodeState();
        this.updateVideoNodeState();
      }
    }
  }
  deleteScript = (event) => {
    event.preventDefault();
    let projectname = localStorage.getItem("projectname");
    let language = localStorage.getItem("language");
    let collection = "optimus";
    let docvalue = {
      project: projectname,
      language: language,
      collection: collection,
    };
    if (!language) {
      language = "en";
    }
    if (!projectname) projectname = "common";
    let data = {
      data: 'display(hmap,"")',
      project: projectname,
      language: language,
      botName: this.state.currentBotSelected,
    };
    let newLearn = [];
    var learn = "";
    if (this.props.docData) {
      let getDocArr = this.props.docData;
      let intentName = getDocArr.intent;
      let intentDesp = getDocArr.description;
      if (intentName == this.state.currentBotSelected) {
        if (getDocArr.training.learn) {
          newLearn = getDocArr.training.learn.split("\n");
          {
            newLearn.map((item, idx) => (learn = learn + item + "<br>"));
          }
        }
      }
    }

    learn = learn.replaceAll(/<a[^>]*>x<\/a>/g, "");
    var learnData = [];
    let deleteIntentFlag = false;
    learnData = learn.split("<br>");
    learnData = learnData.filter((item) => item);
    learnData.shift();
    let reLearn = [" "];
    let pattern = {
      project: projectname,
      language: language,
      botName: this.state.currentBotSelected,
      intentTraining: { learn: learnData, relearn: reLearn },
      description: this.state.currentBotSelected,
    };
    this.props.onDeleteIntent(pattern, this.props.config.BOTBUILDER_URL);
    this.props.onDeleteEditPage(data, this.props.config.BOTBUILDER_URL);
    this.props.OnGetDoc(docvalue, this.props.config.BOTBUILDER_URL);
    this.props.onUpdateChat("");
    this.updateNodeState();
    this.updateVideoNodeState();
    this.setState({ scriptData: true });
    this.setState({ deleteScriptData: false });
    this.setState({ replydata: "" });
    this.setState({ intentdelete: true });
    this.props.onaddNode("");
  };
  deleteMainTrigger = (event) => {
    event.preventDefault();
    let projectname = localStorage.getItem("projectname");
    let language = localStorage.getItem("language");
    let collection = "optimus";

    if (!language) {
      language = "en";
    }
    if (!projectname) projectname = "common";

    let pattern = {
      project: projectname,
      language: language,
      botName: this.state.currentBotSelected,
      intentTraining: { learn: [], relearn: [" "] },
      description: this.state.currentBotSelected,
    };
    let data = {
      data: 'display(hmap,"")',
      project: projectname,
      language: language,
      botName: this.state.currentBotSelected,
    };
    let docvalue = {
      project: projectname,
      language: language,
      collection: collection,
    };

    this.props.onUpdateIntent(pattern, this.props.config.BOTBUILDER_URL);
    this.props.onDeleteEditPage(data, this.props.config.BOTBUILDER_URL);
    this.props.OnGetDoc(docvalue, this.props.config.BOTBUILDER_URL);
    this.props.onUpdateChat("");
    this.updateNodeState();
    this.updateVideoNodeState();
    this.setState({ scriptData: true });
    this.setState({ deleteScriptData: false });
    this.setState({ replydata: "" });
    this.setState({ intentdelete: true });
    this.props.onaddNode("");
  };
  saveBotDataIntent = (event) => {
    this.setState({
      replydata: event,
    });
  };

  updatechatIntentSaveYes = (event) => {
    let chatIntent = "";
    if (!this.props.chatintent) {
      chatIntent = this.getIntentText();
    } else {
      chatIntent = this.props.chatintent;
    }

    event.preventDefault();
    const { currentBotSelected } = this.state;
    let projectname = localStorage.getItem("projectname");
    let language = localStorage.getItem("language");
    let lang='';
    let collection = "optimus";
    if (!projectname) projectname = "common";

    if (!language || language == "en") {
      language = "en";
      collection = "optimus";
    } else {
      lang = language;
      collection = "optimus_" + lang;
    }
    let newLearn = [];
    var learn = "";
    let reLearn = "undefined<br><br>";
    let bgcolor = this.state.entityColors;
    let intentName = "";
    let intentDesp = "";
    let learnexist = false;
    let learnvalue = null;
    if (this.props.docData) {
      let getDocArr = this.props.docData;
      intentName = getDocArr.intent;
      intentDesp = getDocArr.description;
      if (intentName == currentBotSelected) {
        if (getDocArr.training.learn) {
          learnexist = true;
          learnvalue = getDocArr.training.learn;
          newLearn = getDocArr.training.learn.split("\n");

          {
            newLearn.map((item, idx) => (learn = learn + "<br>" + item));
          }
        }
      }
    }
    if (chatIntent !== "undefined" && chatIntent)
      learn = chatIntent + learn + "<br>";

    learn = learn
      .replaceAll("<span", "<START")
      .replaceAll("<font", "<START")
      .replaceAll(
        'style="background-color: ' + bgcolor["one"] + ';">',
        ":one> "
      )
      .replaceAll(
        'style="background-color: ' + bgcolor["two"] + ';">',
        ":two> "
      )
      .replaceAll(
        'style="background-color: ' + bgcolor["three"] + ';">',
        ":three> "
      )
      .replaceAll(
        'style="background-color: ' + bgcolor["four"] + ';">',
        ":four> "
      )
      .replaceAll(
        'style="background-color: ' + bgcolor["five"] + ';">',
        ":five> "
      )
      .replaceAll("</span>", " <END> ")
      .replaceAll("</font>", " <END> ")
      .replaceAll("<p>", "")
      .replaceAll("</p>", "<br>")
      .replaceAll("<START ", "<START")
      .replaceAll("&nbsp;", " ");
    learn = learn.replaceAll(/<a[^>]*>x<\/a>/g, "");
    var learnData = [];
    learnData = learn.split("<br>");
    learnData = learnData.filter((item) => item);
    reLearn = reLearn
      .replaceAll("<span", "<START")
      .replaceAll("<font", "<START")
      .replaceAll(
        'style="background-color: ' + bgcolor["one"] + ';">',
        ":one> "
      )
      .replaceAll(
        'style="background-color: ' + bgcolor["two"] + ';">',
        ":two> "
      )
      .replaceAll(
        'style="background-color: ' + bgcolor["three"] + ';">',
        ":three> "
      )
      .replaceAll(
        'style="background-color: ' + bgcolor["four"] + ';">',
        ":four> "
      )
      .replaceAll(
        'style="background-color: ' + bgcolor["five"] + ';">',
        ":five> "
      )
      .replaceAll("</span>", " <END> ")
      .replaceAll("</font>", " <END> ")
      .replaceAll("<p>", "")
      .replaceAll("</p>", "<br>")
      .replaceAll("<START ", "<START")
      .replaceAll("&nbsp;", " ");
    reLearn = reLearn.replaceAll(/<a[^>]*>x<\/a>/g, "");
    var reLearnData = [];
    reLearn = reLearn.replace("undefined", "");
    reLearnData = reLearn.split("<br>");
    reLearnData = reLearnData.filter((item) => item);
    intentName = chatIntent;
    if (chatIntent || this.props.chatReply) {
      let intentList = {
        botName: currentBotSelected,
        profileName: intentDesp,
        triggerType: "chatbot",
        value: "ChatBot&&" + currentBotSelected,
        project: projectname,
        language: language,
      };
      let pattern = {
        project: projectname,
        language: language,
        botName: currentBotSelected,
        intentTraining: { learn: learnData, relearn: [" "] },
        description: intentDesp,
      };

      if (!language) {
        language = "en";
      }
      if (!projectname) projectname = "common";
      this.handleClose("deleteIntentSaveModal");
      this.props.onUpdateIntent(pattern, this.props.config.BOTBUILDER_URL);
      if (chatIntent) {
        this.props.onUpdateChat(chatIntent);
      }

      this.setState({ scriptData: false });
      this.setState({ replydata: "" });
      this.currentBotEvent(
        true,
        currentBotSelected,
        intentName + "__" + intentDesp,
        false
      );
      this.setSelected("Edit Flow", false);
    }
    this.saveReplyNode();
  };

  updatechatIntentSaveNo = (event) => {
    let chatIntent = "";
    if (!this.props.chatintent) {
      chatIntent = this.getIntentText();
    } else {
      chatIntent = this.props.chatintent;
    }

    event.preventDefault();
    const { currentBotSelected } = this.state;
    let projectname = localStorage.getItem("projectname");
    let language = localStorage.getItem("language");
    let lang='';
    let collection = "optimus";
    if (!projectname) projectname = "common";

    if (!language || language == "en") {
      language = "en";
      collection = "optimus";
    } else {
      lang = language;
      collection = "optimus_" + lang;
    }
    let newLearn = [];
    var learn = "";
    let reLearn = "undefined<br><br>";
    let bgcolor = this.state.entityColors;
    let intentName = "";
    let intentDesp = "";
    let learnexist = false;
    let learnvalue = null;
    if (this.props.docData) {
      let getDocArr = this.props.docData;
      intentName = getDocArr.intent;
      intentDesp = getDocArr.description;
      if (intentName == currentBotSelected) {
        if (getDocArr.training.learn) {
          learnexist = true;
          learnvalue = getDocArr.training.learn;
          newLearn = getDocArr.training.learn.split("\n");

          {
            newLearn.map((item, idx) => (learn = learn + "<br>" + item));
          }
        }
      }
    }

    learn = learn
      .replaceAll("<span", "<START")
      .replaceAll("<font", "<START")
      .replaceAll(
        'style="background-color: ' + bgcolor["one"] + ';">',
        ":one> "
      )
      .replaceAll(
        'style="background-color: ' + bgcolor["two"] + ';">',
        ":two> "
      )
      .replaceAll(
        'style="background-color: ' + bgcolor["three"] + ';">',
        ":three> "
      )
      .replaceAll(
        'style="background-color: ' + bgcolor["four"] + ';">',
        ":four> "
      )
      .replaceAll(
        'style="background-color: ' + bgcolor["five"] + ';">',
        ":five> "
      )
      .replaceAll("</span>", " <END> ")
      .replaceAll("</font>", " <END> ")
      .replaceAll("<p>", "")
      .replaceAll("</p>", "<br>")
      .replaceAll("<START ", "<START")
      .replaceAll("&nbsp;", " ");
    learn = learn.replaceAll(/<a[^>]*>x<\/a>/g, "");
    var learnData = [];
    learnData = learn.split("<br>");
    learnData = learnData.filter((item) => item);

    learnData[0] = chatIntent;

    reLearn = reLearn
      .replaceAll("<span", "<START")
      .replaceAll("<font", "<START")
      .replaceAll(
        'style="background-color: ' + bgcolor["one"] + ';">',
        ":one> "
      )
      .replaceAll(
        'style="background-color: ' + bgcolor["two"] + ';">',
        ":two> "
      )
      .replaceAll(
        'style="background-color: ' + bgcolor["three"] + ';">',
        ":three> "
      )
      .replaceAll(
        'style="background-color: ' + bgcolor["four"] + ';">',
        ":four> "
      )
      .replaceAll(
        'style="background-color: ' + bgcolor["five"] + ';">',
        ":five> "
      )
      .replaceAll("</span>", " <END> ")
      .replaceAll("</font>", " <END> ")
      .replaceAll("<p>", "")
      .replaceAll("</p>", "<br>")
      .replaceAll("<START ", "<START")
      .replaceAll("&nbsp;", " ");
    reLearn = reLearn.replaceAll(/<a[^>]*>x<\/a>/g, "");
    var reLearnData = [];
    reLearn = reLearn.replace("undefined", "");
    reLearnData = reLearn.split("<br>");
    reLearnData = reLearnData.filter((item) => item);

    intentName = chatIntent;
    if (chatIntent || this.props.chatReply) {
      let intentList = {
        botName: currentBotSelected,
        profileName: intentDesp,
        triggerType: "chatbot",
        value: "ChatBot&&" + currentBotSelected,
        project: projectname,
        language: language,
      };
      let pattern = {
        project: projectname,
        language: language,
        botName: currentBotSelected,
        intentTraining: { learn: learnData, relearn: [" "] },
        description: intentDesp,
      };

      if (!language) {
        language = "en";
      }
      if (!projectname) projectname = "common";
      this.handleClose("deleteIntentSaveModal");
      this.props.onUpdateIntent(pattern, this.props.config.BOTBUILDER_URL);
      if (chatIntent) {
        this.props.onUpdateChat(chatIntent);
      }

      this.setState({ scriptData: false });
      this.setState({ replydata: "" });
      this.currentBotEvent(
        true,
        currentBotSelected,
        intentName + "__" + intentDesp,
        false
      );
      this.setSelected("Edit Flow", false);
    }

    this.saveReplyNode();
  };
  DiscardSavedchanges = () => {
    let projectname = localStorage.getItem("projectname");
    let language = localStorage.getItem("language");
    if (!projectname) projectname = "common";

    if (!language) {
      language = "en";
    }
    let docvalue = {
      projectname: projectname,
      language: language,
      botName: this.state.currentBotSelected,
    };
    this.props.OnGetDoc(docvalue, this.props.config.BOTBUILDER_URL);

    this.openJSFile("bot.js");
    this.props.onchangeTabAction(true);
    this.handleClose("showSavePopup");
  };

  updateTrigger = (event) => {
    let chatIntent = "";
    if (!this.props.chatintent) {
      chatIntent = this.getIntentText();
    } else {
      chatIntent = this.props.chatintent;
    }

    event.preventDefault();
    const { currentBotSelected } = this.state;
    let projectname = localStorage.getItem("projectname");
    let language = localStorage.getItem("language");
    let lang='';
    let collection = "optimus";
    if (!projectname) projectname = "common";

    if (!language || language == "en") {
      language = "en";
      collection = "optimus";
    } else {
      lang = language;
      collection = "optimus_" + lang;
    }
    let newLearn = [];
    var learn = "";
    let reLearn = "undefined<br><br>";
    let bgcolor = this.state.entityColors;
    let intentName = "";
    let intentDesp = "";
    let learnexist = false;
    let learnvalue = null;
    if (this.props.docData) {
      let getDocArr = this.props.docData;
      intentName = getDocArr.intent;
      intentDesp = getDocArr.description;
      if (intentName == currentBotSelected) {
        if (getDocArr.training.learn) {
          learnexist = true;
          learnvalue = getDocArr.training.learn;
          newLearn = getDocArr.training.learn.split("\n");

          {
            newLearn.map((item, idx) => (learn = learn + item + "<br>"));
          }
        }
      }
    }

    if (chatIntent != "undefined") learn = learn + chatIntent + "<br>";
    learn = learn
      .replaceAll("<span", "<START")
      .replaceAll("<font", "<START")
      .replaceAll(
        'style="background-color: ' + bgcolor["one"] + ';">',
        ":one> "
      )
      .replaceAll(
        'style="background-color: ' + bgcolor["two"] + ';">',
        ":two> "
      )
      .replaceAll(
        'style="background-color: ' + bgcolor["three"] + ';">',
        ":three> "
      )
      .replaceAll(
        'style="background-color: ' + bgcolor["four"] + ';">',
        ":four> "
      )
      .replaceAll(
        'style="background-color: ' + bgcolor["five"] + ';">',
        ":five> "
      )
      .replaceAll("</span>", " <END> ")
      .replaceAll("</font>", " <END> ")
      .replaceAll("<p>", "")
      .replaceAll("</p>", "<br>")
      .replaceAll("<START ", "<START")
      .replaceAll("&nbsp;", " ");
    learn = learn.replaceAll(/<a[^>]*>x<\/a>/g, "");
    var learnData = [];
    learnData = learn.split("<br>");
    learnData = learnData.filter((item) => item);
    reLearn = reLearn
      .replaceAll("<span", "<START")
      .replaceAll("<font", "<START")
      .replaceAll(
        'style="background-color: ' + bgcolor["one"] + ';">',
        ":one> "
      )
      .replaceAll(
        'style="background-color: ' + bgcolor["two"] + ';">',
        ":two> "
      )
      .replaceAll(
        'style="background-color: ' + bgcolor["three"] + ';">',
        ":three> "
      )
      .replaceAll(
        'style="background-color: ' + bgcolor["four"] + ';">',
        ":four> "
      )
      .replaceAll(
        'style="background-color: ' + bgcolor["five"] + ';">',
        ":five> "
      )
      .replaceAll("</span>", " <END> ")
      .replaceAll("</font>", " <END> ")
      .replaceAll("<p>", "")
      .replaceAll("</p>", "<br>")
      .replaceAll("<START ", "<START")
      .replaceAll("&nbsp;", " ");
    reLearn = reLearn.replaceAll(/<a[^>]*>x<\/a>/g, "");
    var reLearnData = [];
    reLearn = reLearn.replace("undefined", "");
    reLearnData = reLearn.split("<br>");
    reLearnData = reLearnData.filter((item) => item);
    if (learnexist && chatIntent) {
      if (intentName == currentBotSelected) {
        let learnIndex = newLearn.findIndex(
          (item) => chatIntent.toLowerCase() === item.toLowerCase()
        );
        if (learnIndex == -1) {
          this.setState({ confirmIntentSave: true });
          return;
        } else {
          this.saveReplyNode();
          return;
        }
      }
    }

    intentName = chatIntent;
    if ((!learnexist && chatIntent) || this.props.chatReply) {
      let intentList = {
        botName: currentBotSelected,
        profileName: intentDesp,
        triggerType: "chatbot",
        value: "ChatBot&&" + currentBotSelected,
        project: projectname,
        language: language,
      };
      let pattern = {
        project: projectname,
        language: language,
        botName: currentBotSelected,
        intentTraining: { learn: learnData, relearn: [" "] },
        description: intentDesp,
      };

      if (!language) {
        language = "en";
      }
      if (!projectname) projectname = "common";
      this.props.onUpdateIntent(pattern, this.props.config.BOTBUILDER_URL);

      if (chatIntent) {
        this.props.onUpdateChat(chatIntent);
      }

      this.setState({ scriptData: false });
      this.currentBotEvent(
        true,
        currentBotSelected,
        intentName + "__" + intentDesp,
        false
      );
      this.setSelected("Edit Flow", false);
    }
    if (
      this.props.FlowIntent !== undefined &&
      this.props.FlowIntent !== "" &&
      this.props.FlowIntent !== null
    ) {
      let data = {
        botName: currentBotSelected,
        data: this.props.FlowIntent,
        project: projectname,
        language: language,
      };
      this.setSelected("Edit Flow", false);
      this.props.onsaveBotProfile(data, this.props.config.BOTBUILDER_URL);
    }

    if (
      this.props.chatintent !== null &&
      this.props.chatintent !== "" &&
      this.props.chatintent !== undefined
    ) {
      this.saveReplyNode();
    } else {
      return true;
    }
  };

  saveReplyNode = () => {
    const { currentBotSelected } = this.state;
    let projectname = localStorage.getItem("projectname");
    let language = localStorage.getItem("language");
    let lang='';
    let collection = "optimus";
    if (!projectname) projectname = "common";

    if (!language || language == "en") {
      language = "en";
      collection = "optimus";
    } else {
      lang = language;
      collection = "optimus_" + lang;
    }

    let intentDesp = "";
    if (this.props.docData) {
      let getDocArr = this.props.docData;
      intentDesp = getDocArr.description;
    }
    if (
      this.props.chatintent !== null &&
      this.props.chatintent !== "" &&
      this.props.chatintent !== undefined
    ) {
      if (
        this.props.listData &&
        this.props.listData.listTitle &&
        this.props.listData.listTitle != "" &&
        this.props.listData.listType &&
        this.props.listData.listType != "-1"
      ) {
        this.saveList();
        return true;
      } else if (
        this.props.buttondatavalue &&
        this.props.buttonRows &&
        this.props.buttondatavalue.chatUserDefineInput !== "" &&
        this.props.buttonRows.buttonRows.length > 0
      ) {
        this.saveButton();
        return true;
      }

      if (this.props.videoContent && this.props.videoContent.url) {
        this.saveVideo();
        return true;
      }
      if (this.props.imgtitle && this.props.imageurl) {
        this.saveImage();
        return true;
      }

      if (
        this.props.conditionNodeA.cndtType != "-1" &&
        this.props.conditionNodeB.cndtType != "-1"
      ) {
        this.saveCondition();
        return true;
      }
      if (this.props.chatReply) {
        let intentList = {
          botName: currentBotSelected,
          profileName: intentDesp,
          value: "ChatBot&&" + currentBotSelected,
          project: projectname,
          language: language,
          triggerType: "chatbot",
        };
        let data = {
          botName: currentBotSelected,
          data: 'display(hmap,"' + this.props.chatReply + '")',
          project: projectname,
          language: language,
        };
        this.setSelected("Edit Flow", false);
        this.props.onsaveBotProfile(data, this.props.config.BOTBUILDER_URL);
      }
      this.props.onchangeTabAction(true);
      this.setState({
        showSavePopup: false,
      });
    } else {
      let showMainError = null;
      this.setState({ showingAlert: true }, () => {
        showMainError = (
          <div
            className={[
              "alert",
              classes.alertError,
              "alert-danger",
              "row",
              this.state.showingAlert ? "alert-shown" : classes.alerthidden,
            ].join(" ")}
          >
            <div className="col-12">Trigger should not be empty</div>
          </div>
        );
        this.setState({ mainTriggerEmptyAlert: showMainError });
        setTimeout(() => {
          this.setState(
            {
              showingAlert: false,
            },
            () => {
              showMainError = null;
              this.setState({ mainTriggerEmptyAlert: showMainError });
            }
          );
        }, 2000);
      });
    }
  };

  saveImage = () => {
    const { currentBotSelected } = this.state;
    let projectname = localStorage.getItem("projectname");
    let language = localStorage.getItem("language");
    if (this.props.imageurl || this.props.imgtitle) {
      let imgtitle = this.props.imgtitle;
      let imgurl = this.props.imageurl;
      let codestart =
        "try{" +
        "\n" +
        "var uuid = guid();" +
        "\n" +
        'var options=[{"option":"<form style=' +
        "width:180px" +
        "><label style=" +
        "font-size:15px;margin-left:10px" +
        "'>" +
        imgtitle +
        "</label><br><br><div>";
      let codemiddle =
        "<img src='" +
        imgurl +
        "' style='width:100%' class='w3-hover-opacity' /></div></form>" +
        '"}]';
      let codeend =
        'view=__htmlView("form",options,uuid,null);' +
        "\n" +
        "var imageCard=prompt(hmap,view);" +
        "\n" +
        "display(hmap,imageCard)" +
        "\n" +
        "}" +
        "\n" +
        "catch(e)" +
        "\n" +
        "{" +
        "\n" +
        "};";
      let code = codestart + codemiddle + "\n" + codeend + "\n";
      let scriptData = {
        data: code,
        project: projectname,
        language: language,
        botName: currentBotSelected,
      };
      this.props.onsaveBotProfile(scriptData, this.props.config.BOTBUILDER_URL);
      this.setSelected("Edit Flow", true);
    }
  };
  saveList = () => {
    const { currentBotSelected } = this.state;
    let projectname = localStorage.getItem("projectname");
    let language = localStorage.getItem("language");
    if (this.props.listContent) {
      let listcontent = [];
      if (this.props.listContent.listRows) {
        this.props.listContent.listRows.map((item, idx) => {
          listcontent.push(this.props.listContent.listRows[idx].option);
        });
      }
      let listTitle = this.props.listContent.listTitle;
      var mid = "";
      for (var i = 0; i < listcontent.length; i++) {
        listcontent[i] = listcontent[i].replace(/^\s*/, "").replace(/\s*$/, "");
        var mm = listcontent[i];
        if (this.props.listContent.listType == "radio") {
          mid =
            mid +
            " <input type='radio'  name='radio1' onchange='checkList(this)' value='" +
            mm +
            "'><label style='font-size:15px;margin-left:10px'>" +
            mm +
            "</label><br>";
        } else if (this.props.listContent.listType == "checkbox") {
          mid =
            mid +
            " <input type='checkbox'  name='" +
            mm +
            "' onchange='checkList(this)' value='" +
            mm +
            "'><label style='font-size:15px;margin-left:10px'>" +
            mm +
            "</label><br>";
        }
      }
      let codestart =
        "try{" +
        "\n" +
        "var uuid = guid();" +
        "\n" +
        'var options=[{"option":"<form style=' +
        "width:180px" +
        "><label style=" +
        "font-size:15px;margin-left:10px" +
        "'>" +
        listTitle +
        "</label><br><br><div><table border=0 width=" +
        "'95%'><tr><td>";
      let codemiddle =
        "<br></td></tr></table><center><input type='button' class='btn btn-primary' style = 'color: #fff;background-color: #337ab7 !important;line-height: 1.38 !important' id='testB' onclick='submitList(this)' disabled value='Submit' /></center></div></form>" +
        '"}]';
      let codeend =
        'view=__htmlView("form",options,uuid,null);' +
        "\n" +
        "var ListCard=prompt(hmap,view);" +
        "\n" +
        "display(hmap,ListCard)" +
        "\n" +
        "}" +
        "\n" +
        "catch(e)" +
        "\n" +
        "{" +
        "\n" +
        "};";
      let code = codestart + mid + codemiddle + "\n" + codeend + "\n";
      let scriptData = {
        data: code,
        project: projectname,
        language: language,
        botName: currentBotSelected,
      };
      this.props.onsaveBotProfile(scriptData, this.props.config.BOTBUILDER_URL);
      this.setSelected("Edit Flow", true);
   
    }
  };

  saveButton = () => {
    const { currentBotSelected } = this.state;
    let projectname = localStorage.getItem("projectname");
    let language = localStorage.getItem("language");
    let userSaysVal;
    var mid = "";
    let urlvalue;
    let optValue;
    let output;
    let buttonParam = [];
    if (this.props.buttonRows) {
      let buttoncontent = [];
      let buttonTitle = this.props.buttondatavalue
        ? this.props.buttondatavalue.chatUserDefineInput
        : "";
      if (this.props.buttondatavalue == undefined) {
        buttonTitle = "";
      } else {
        buttonTitle = this.props.buttondatavalue.chatUserDefineInput;
      }
      if (this.props.buttonRows) {
        this.props.buttonRows.buttonRows.map((item, idx) => {
          let flag = false;
          buttoncontent = this.props.buttonRows.buttonRows[idx].option;
          buttoncontent = buttoncontent.replace(/^\s*/, "");
          buttonParam.push({
            ["buttonRows"]: this.props.buttonRows.buttonRows[idx],
          });
          var mm = buttoncontent;

          if (
            this.props.buttonRows.buttonRows[idx].selectedDropdown ==
              "userDropdown" &&
            this.props.buttonRows.buttonRows[idx].userSays !== ""
          ) {
            userSaysVal = this.props.buttonRows.buttonRows[idx].userSays;
            output = userSaysVal;
          } else if (
            this.props.buttonRows.buttonRows[idx].selectedDropdown ==
              "urlDropdown" &&
            this.props.buttonRows.buttonRows[idx].urlvalue !== ""
          ) {
            flag = true;
            urlvalue = this.props.buttonRows.buttonRows[idx].urlvalue;
            output = urlvalue;
          } else {
            optValue = this.props.buttonRows.buttonRows[idx].option;
            output = optValue;
          }
          let colMdValue = 12;
          if (this.props.buttondatavalue.buttonInRowValue == "2")
            colMdValue = 6;
          else if (this.props.buttondatavalue.buttonInRowValue == "3")
            colMdValue = 4;
          else if (this.props.buttondatavalue.buttonInRowValue == "4")
            colMdValue = 3;
          if (colMdValue == 6) {
            if (flag === true) {
              if (this.props.buttonRows.buttonRows[idx].checkBoxTick == true) {
                mid =
                  mid +
                  "<div class=col-md-" +
                  colMdValue +
                  "><a class='btn btn-primary buttonReplyChat1' href=" +
                  output +
                  " target='_blank'>" +
                  mm +
                  "</a></div>";
              } else {
                mid =
                  mid +
                  "<div class=col-md-" +
                  colMdValue +
                  "><a class='btn btn-primary buttonReplyChat1' href=" +
                  output +
                  ">" +
                  mm +
                  "</a></div>";
              }
            } else {
              mid =
                mid +
                "<div class=col-md-" +
                colMdValue +
                "><button type='button' name='button' class='btn btn-primary buttonReplyChat1' onclick=getButtonValue(this,'" +
                encodeURIComponent(output) +
                "')>" +
                mm +
                "</button></div>";
            }
          } else if (colMdValue == 4) {
            if (flag === true) {
              if (this.props.buttonRows.buttonRows[idx].checkBoxTick == true) {
                mid =
                  mid +
                  "<div class=col-md-" +
                  colMdValue +
                  "><a class='btn btn-primary buttonReplyChat2' href=" +
                  output +
                  " target='_blank'>" +
                  mm +
                  "</a></div>";
              } else {
                mid =
                  mid +
                  "<div class=col-md-" +
                  colMdValue +
                  "><a class='btn btn-primary buttonReplyChat2' href=" +
                  output +
                  ">" +
                  mm +
                  "</a></div>";
              }
            } else {
              mid =
                mid +
                "<div class=col-md-" +
                colMdValue +
                "><button type='button' name='button' class='btn btn-primary buttonReplyChat2' onclick=getButtonValue(this,'" +
                encodeURIComponent(output) +
                "')>" +
                mm +
                "</button></div>";
            }
          } else if (colMdValue == 3) {
            if (flag === true) {
              if (this.props.buttonRows.buttonRows[idx].checkBoxTick == true) {
                mid =
                  mid +
                  "<div class=col-md-" +
                  colMdValue +
                  "><a class='btn btn-primary buttonReplyChat3' href=" +
                  output +
                  " target='_blank'>" +
                  mm +
                  "</a></div>";
              } else {
                mid =
                  mid +
                  "<div class=col-md-" +
                  colMdValue +
                  "><a class='btn btn-primary buttonReplyChat3' href=" +
                  output +
                  ">" +
                  mm +
                  "</a></div>";
              }
            } else {
              mid =
                mid +
                "<div class=col-md-" +
                colMdValue +
                "><button type='button' name='button' class='btn btn-primary buttonReplyChat3' onclick=getButtonValue(this,'" +
                encodeURIComponent(output) +
                "')>" +
                mm +
                "</button></div>";
            }
          } else {
            if (flag === true) {
              if (this.props.buttonRows.buttonRows[idx].checkBoxTick == true) {
                mid =
                  mid +
                  "<div class=col-md-" +
                  colMdValue +
                  "><a class='btn btn-primary buttonReplyChat4' href=" +
                  output +
                  " target='_blank'>" +
                  mm +
                  "</a></div>";
              } else {
                mid =
                  mid +
                  "<div class=col-md-" +
                  colMdValue +
                  "><a class='btn btn-primary buttonReplyChat4' href=" +
                  output +
                  ">" +
                  mm +
                  "</a></div>";
              }
            } else {
              mid =
                mid +
                "<div class=col-md-" +
                colMdValue +
                "><button type='button' name='button' class='btn btn-primary buttonReplyChat4' onclick=getButtonValue(this,'" +
                encodeURIComponent(output) +
                "')>" +
                mm +
                "</button></div>";
            }
          }
        });
      }
      let codestart =
        "try{" +
        "\n" +
        "var uuid = guid();" +
        "\n" +
        'var options=[{"option":"<form><label style=' +
        "font-size:15px;margin-left:10px" +
        "'>" +
        buttonTitle +
        "</label><br><br><div class=row>";
      let codemiddle = "<br></div></form>" + '"}]';
      let codeend =
        'view=__htmlView("form",options,uuid,null);' +
        "\n" +
        "var buttonCard=prompt(hmap,view);" +
        "\n" +
        "var buttonParam=" +
        JSON.stringify(buttonParam) +
        ";" +
        "\n" +
        "display(hmap,buttonCard)" +
        "\n" +
        "}" +
        "\n" +
        "catch(e)" +
        "\n" +
        "{" +
        "\n" +
        "};";
      let code = codestart + mid + codemiddle + "\n" + codeend + "\n";
      let scriptData = {
        data: code,
        project: projectname,
        language: language,
        botName: currentBotSelected,
      };

      this.props.onsaveBotProfile(scriptData, this.props.config.BOTBUILDER_URL);
      this.setSelected("Edit Flow", true);
    }
  };
  saveCondition = () => {
    const { currentBotSelected } = this.state;
    let projectname = localStorage.getItem("projectname");
    let language = localStorage.getItem("language");
    if (this.props.conditionNodeA && this.props.conditionNodeB) {
      let cndtNameA = null,
        cndtNameB = null;
      let choiceListA = null,
        choiceListB = null;
      let compareValueA = null,
        compareValueB = null;
      let paramNameA = null,
        paramNameB = null;
      let paramTypeA = null,
        paramTypeB = null;
      let cndParamTypeA = null,
        cndParamTypeB = null;
      let ruleCndtA = null,
        ruleCndtB = null;
      let cndtTypeA = null,
        cndtTypeB = null;

      choiceListA = this.props.conditionNodeA.choiceList;
      compareValueA = this.props.conditionNodeA.compareValue;
      cndtNameA = "ConditionA";
      paramNameA = this.props.conditionNodeA.paramList;
      paramTypeA = this.props.conditionNodeA.paramType;
      cndParamTypeA = this.props.conditionNodeA.cndParamType;
      ruleCndtA = this.props.conditionNodeA.ruleCndt;
      cndtTypeA = this.props.conditionNodeA.cndtType;

      choiceListB = this.props.conditionNodeB.choiceList;
      compareValueB = this.props.conditionNodeB.compareValue;
      cndtNameB = "ConditionB";
      paramNameB = this.props.conditionNodeB.paramList;
      paramTypeB = this.props.conditionNodeB.paramType;
      cndParamTypeB = this.props.conditionNodeB.cndParamType;
      ruleCndtB = this.props.conditionNodeB.ruleCndt;
      cndtTypeB = this.props.conditionNodeB.cndtType;

      let listcontent = [];
      if (cndParamTypeB) {
        cndParamTypeB.map((item, idx) => {
          listcontent.push(item);
        });
      }
      let ConditionAView = {
        cndtType: cndtTypeA,
        ruleCndt: ruleCndtA,
        paramList: paramNameA,
        choiceList: choiceListA,
        paramType: paramTypeA,
        compareValue: compareValueA,
        cndParamType: cndParamTypeA,
        emptyValueMsg: null,
      };
      let ConditionBView = {
        cndtType: cndtTypeB,
        ruleCndt: ruleCndtB,
        paramList: paramNameB,
        choiceList: choiceListB,
        paramType: paramTypeB,
        compareValue: compareValueB,
        cndParamType: cndParamTypeB,
        emptyValueMsg: null,
      };
      let ConditionAChat = null,
        ConditionBChat = null;
      if (this.props.ConditionAChat) ConditionAChat = this.props.ConditionAChat;
      if (this.props.ConditionBChat) ConditionBChat = this.props.ConditionBChat;

      let sign = null;
      let paramList = ConditionAView.paramList;
      let valueTocompare = ConditionAView.compareValue;
      var ConditionA = "var ConditionA=" + JSON.stringify(ConditionAView);
      var ConditionB = "var ConditionB=" + JSON.stringify(ConditionBView);
      if (ConditionAView.choiceList == "greater") sign = ">";
      else if (ConditionAView.choiceList == "equal") sign = "==";
      else if (ConditionAView.choiceList == "greaterAndEqual") sign = ">=";
      else if (ConditionAView.choiceList == "lessAndEqual") sign = "<=";
      else sign = "<";
      let cdn = `if(hmap.Entity.${paramList} == undefined){
  var resolved=hmap.query.split(" ")
  var IsNotNumericString = resolved.filter(function(i){
  return !isNaN(i);
  })
   compareValue=IsNotNumericString[0];
  }
  else{
       var resolved=hmap.Entity.${paramList}
      var IsNotNumericString = !isNaN(resolved)
      if(IsNotNumericString)
       compareValue=resolved;
      }`;
      let reply = `if(compareValue${sign}${valueTocompare})
      display(hmap,"${ConditionAChat}",log,conversationData)
  else
    display(hmap,"${ConditionBChat}",log,conversationData)`;

      let cndtTitle = "Select the Type of param";

      let codestart =
        "try{" +
        "\n" +
        "var uuid = guid();" +
        "\n" +
        ConditionA +
        "\n" +
        ConditionB +
        "\n" +
        "var compareValue=null;" +
        "\n";
      let codemiddle = cdn + "\n" + reply + "\n" + "}" + "\n";
      let codeend = "catch(e)" + "\n" + "{" + "\n" + "};";
      let code = codestart + codemiddle + "\n" + codeend + "\n";

      let scriptData = {
        data: code,
        project: projectname,
        language: language,
        botName: currentBotSelected,
      };

      this.props.onsaveBotProfile(scriptData, this.props.config.BOTBUILDER_URL);
      this.setSelected("Edit Flow", false);
      this.props.onsaveRule(false);
    } else return true;
  };

  saveVideo = () => {
    const { currentBotSelected } = this.state;
    let projectname = localStorage.getItem("projectname");
    let language = localStorage.getItem("language");
    if (this.props.videoContent) {
      let videoTitle = "",
        videoUrl = "";
      videoTitle = this.props.videoContent.title;
      videoUrl = this.props.videoContent.url;
      if (videoUrl && videoUrl.includes("/watch?v="))
        videoUrl = videoUrl.replace("/watch?v=", "/embed/");
      let codestart =
        "try{" +
        "\n" +
        "var uuid = guid();" +
        "\n" +
        'var options=[{"option":"<form><label style=' +
        "font-size:15px;margin-left:10px" +
        "'>" +
        videoTitle +
        "</label><br><br><div>";
      let codemiddle =
        "<iframe width='200' height='120' src='" +
        videoUrl +
        "'></iframe></div></form>" +
        '"}]';
      let codeend =
        'view=__htmlView("form",options,uuid,null);' +
        "\n" +
        "var videoCard=prompt(hmap,view);" +
        "\n" +
        "display(hmap,videoCard)" +
        "\n" +
        "}" +
        "\n" +
        "catch(e)" +
        "\n" +
        "{" +
        "\n" +
        "};";
      let code = codestart + codemiddle + "\n" + codeend + "\n";
      let scriptData = {
        data: code,
        project: projectname,
        language: language,
        botName: currentBotSelected,
      };
      this.props.onsaveBotProfile(scriptData, this.props.config.BOTBUILDER_URL);
      this.setSelected("Edit Flow", true);
    }
  };

  submitCreateProfile(event, index, active) {
    event.preventDefault();

    if (this.state.createProfileInput.value.trim() === "") {
      this.setState({
        emptyCreateProfileMsg: (
          <ValidationMsg>Profile name is required</ValidationMsg>
        ),
      });
      return;
    } else if (!/^[A-Z0-9./_]+$/i.test(this.state.createProfileInput.value)) {
      this.setState({
        emptyCreateProfileMsg: (
          <ValidationMsg>Profile name should be alphanumeric</ValidationMsg>
        ),
      });
      return;
    }

    let projectname = localStorage.getItem("projectname");
    let language = localStorage.getItem("language");
    let orgName = localStorage.getItem("orgName");
    let bot = {
      botName: this.state.currentBotSelected,
      description: this.state.createProfileInput.value,
      project: projectname,
      language: language,
      response: "",
      intentTraining: null,
      entityData: {
        entityList: [],
        Action: "",
        FinalValidationOptional: "",
      },
    };
    let profileExist = false;
    let botlist = null;
    let botlanguage = null;
    let projectList = null;
    let profileKey = null;
    let profileValue1 = [];
    botlist =
      this.props.botList != undefined && this.props.botList
        ? this.props.botList
        : null;
    botlanguage = botlist[language];
    if (botlanguage) {
      if (botlanguage.hasOwnProperty(projectname)) {
        projectList = botlanguage[projectname];

        profileKey = Object.values(projectList);
        profileKey.map((item, index) => {
          item.filter((data, id) => {
            if (Object.keys(data).includes("profile")) {
              return profileValue1.push(data.profile);
            }
          });
        });
      }
    }

    profileValue1 &&
      profileValue1.map((key, index) => {
        let profiledata = key.split("__");
        if (
          profiledata[0] == this.state.currentBotSelected &&
          profiledata[1] == this.state.createProfileInput.value
        ) {
         return profileExist = true;
        }
      });

    if (!profileExist) {
      this.setValidationMessage(
        "createProfile",
        this.state.createProfileInput.value
      );
      let input = {
        botName: this.state.currentBotSelected,
        project: projectname,
        language: language,
      };
      if (
        this.state.createProfileInput.value != "" &&
        this.state.createProfileInput.value != undefined &&
        /^[A-Z0-9./_]+$/i.test(this.state.createProfileInput.value)
      ) {
        input.profile = this.state.createProfileInput.value;
        this.props.OnCreateBot(
          this.props.config.BOTBUILDER_URL,
          bot,
          false,
          true,
          this.state.isCuxOrg
        );
        this.props.OnConfigurationInputs(
          input,
          this.props.config.BOTBUILDER_URL
        );
        this.props.OnGetBotList(this.props.config.BOTBUILDER_URL);
        this.clearProfileStateValue();
        this.handleClose("createProfileModal");

        let entitylistObj = JSON.parse(this.props.entityList);
        let activeBotList = entitylistObj;
        let BotKeys = activeBotList ? Object.keys(activeBotList) : [];
        let BotValues = activeBotList ? Object.values(activeBotList) : [];
        let currentBot = this.state.currentBotSelected;

        let newBotEntity = {};
        BotKeys.forEach((v, i) => {
          newBotEntity[v] = BotValues[i];
        });
        if (BotKeys.length > 0) {
          this.props.createUpdateEntity(
            {
              entityname: currentBot,
              entitydata: newBotEntity,
              project: projectname,
              language: language,
            },
            this.props.config.BOTBUILDER_URL,
            true,
            false,
            this.state.EntityOccurance
          );

          setTimeout(() => {
            this.closeEntities();
          }, 2000);
        }
      }
    } else {
      this.setState({
        emptyCreateProfileMsg: (
          <ValidationMsg>Profile already exist</ValidationMsg>
        ),
      });
      return;
    }
  }
  clearProfileStateValue = () => {
    this.setState({
      createProfileInput: {
        ...this.state.createProfileInput,
        value: "",
      },
      emptyCreateProfileMsg: null,
    });
  };

  handlesubmitTriggerType(event, index, active) {
    event.preventDefault();
    let userinput = {};
    const {
      currentBotSelected,
      AddIntentcontrols,
      TriggerTypeControls,
      manual,
      OnEmailControls,
      ScheduleRadioControls,
      ScheduleUserDefineControls,
      ChatBotControls,
      OtherBotsControls,
    } = this.state;
    let projectname = localStorage.getItem("projectname");
    if (!projectname) projectname = "common";
    let language = localStorage.getItem("language");
    if (!language || language == "en") language = "en";
    let triggerType = TriggerTypeControls.triggerTypeInputs.value;
    let manualValue = manual;
    let emailValue = OnEmailControls.onEmailInput.value;
    let scheduleValue = ScheduleRadioControls.scheduleRadioInput.value;
    let scheduleUserDefineValue =
      ScheduleUserDefineControls.scheduleUserDefineInput.value;
    let chatbotValue = ChatBotControls.chatBotInputs.value;
    let otherbotsValue = OtherBotsControls.otherBotsInputs.value;
    let intentDesp = AddIntentcontrols.intentDesc.value;
    this.setValidationMessage("triggerTypeInputs", triggerType);
    if (manualValue == triggerType) {
      userinput = {
        botName: currentBotSelected,
        profileName: intentDesp,
        value: "Manual&&" + manualValue,
        project: projectname,
        language: language,
        triggerType: "manual",
      };
      this.saveTriggerType(userinput);
    } else if (triggerType === "On Email") {
      if (emailValue != "" && emailValue != null) {
        userinput = {
          botName: currentBotSelected,
          profileName: intentDesp,
          value: "Mail&&" + emailValue,
          project: projectname,
          language: language,
          triggerType: "email",
        };
        this.saveTriggerType(userinput);
      }
      this.setValidationMessage("On Email", emailValue);
    } else if (triggerType === "Schedule") {
      if (scheduleUserDefineValue != "" && scheduleUserDefineValue != null) {
        userinput = {
          botName: currentBotSelected,
          profileName: intentDesp,
          value: "Scd&&" + scheduleUserDefineValue,
          project: projectname,
          language: language,
          triggerType: "schedule",
        };
        this.saveTriggerType(userinput);
      }
      if (scheduleValue != "" && scheduleValue != null) {
        userinput = {
          botName: currentBotSelected,
          profileName: intentDesp,
          value: "Scd&&" + scheduleValue,
          project: projectname,
          language: language,
          triggerType: "schedule",
        };
        this.saveTriggerType(userinput);
      }
      this.setValidationMessage("Schedule", scheduleValue);
    } else if (triggerType === "other Bots") {
      if (otherbotsValue != "0" && otherbotsValue != null) {
        userinput = {
          botName: currentBotSelected,
          profileName: intentDesp,
          value: "bot&&" + otherbotsValue,
          project: projectname,
          language: language,
          triggerType: "bot",
        };
        this.saveTriggerType(userinput);
      }
      this.setValidationMessage("other Bots", otherbotsValue);
    } else if (triggerType === "ChatBot") {
      if (chatbotValue != "0" && chatbotValue != null) {
        userinput = {
          botName: currentBotSelected,
          profileName: intentDesp,
          value: "ChatBot&&" + chatbotValue,
          project: projectname,
          language: language,
          triggerType: "chatbot",
        };
        this.saveTriggerType(userinput);
      }
      this.setValidationMessage("ChatBot", chatbotValue);
    }
  }
  saveTriggerType = (userinput) => {
    this.props.onUpdateTrigger(
      userinput,
      this.props.config.BOTBUILDER_URL,
      true
    );
    this.clearTriggerTypeStateValue();
    this.handleClose("triggerTypeModal");
  };
  checkEntityValidate = (index) => {
    let returnType = false;
    if (index == "1") {
      this.setValidationMessage("question", this.state.entity1.question);
      this.setValidationMessage(
        "defaultValue",
        this.state.entity1.defaultValue
      );
      if (this.state.entity1.question && this.state.entity1.defaultValue) {
        returnType = true;
      }
    } else if (index == "2") {
      this.setValidationMessage("question", this.state.entity2.question);
      this.setValidationMessage(
        "defaultValue",
        this.state.entity2.defaultValue
      );
      if (this.state.entity2.question && this.state.entity2.defaultValue) {
        returnType = true;
      }
    } else if (index == "3") {
      this.setValidationMessage("question", this.state.entity3.question);
      this.setValidationMessage(
        "defaultValue",
        this.state.entity3.defaultValue
      );
      if (this.state.entity3.question && this.state.entity3.defaultValue) {
        returnType = true;
      }
    } else if (index == "4") {
      this.setValidationMessage("question", this.state.entity4.question);
      this.setValidationMessage(
        "defaultValue",
        this.state.entity4.defaultValue
      );
      if (this.state.entity4.question && this.state.entity4.defaultValue) {
        returnType = true;
      }
    } else if (index == "5") {
      this.setValidationMessage("question", this.state.entity5.question);
      this.setValidationMessage(
        "defaultValue",
        this.state.entity5.defaultValue
      );
      if (this.state.entity5.question && this.state.entity5.defaultValue) {
        returnType = true;
      }
    }

    return returnType;
  };

  clearTriggerTypeStateValue = () => {
    this.setState({
      TriggerTypeControls: {
        ...this.state.TriggerTypeControls,
        triggerTypeInputs: {
          ...this.state.TriggerTypeControls.triggerTypeInputs,
          elementConfig: {
            ...this.state.TriggerTypeControls.triggerTypeInputs.elementConfig,
          },
          value: "0",
        },
      },
      OnEmailControls: {
        ...this.state.OnEmailControls,
        onEmailInput: {
          value: "",
        },
      },
      ScheduleRadioControls: {
        ...this.state.ScheduleRadioControls,
        scheduleRadioInput: {
          value: "",
        },
      },
      ScheduleUserDefineControls: {
        ...this.state.ScheduleUserDefineControls,
        scheduleUserDefineInput: {
          value: "",
        },
      },
      ChatBotControls: {
        ...this.state.ChatBotControls,
        chatBotInputs: {
          ...this.state.ChatBotControls.chatBotInputs,
          elementConfig: {
            ...this.state.ChatBotControls.chatBotInputs.elementConfig,
          },
          value: "0",
        },
      },
      OtherBotsControls: {
        ...this.state.OtherBotsControls,
        otherBotsInputs: {
          ...this.state.OtherBotsControls.otherBotsInputs,
          elementConfig: {
            ...this.state.OtherBotsControls.otherBotsInputs.elementConfig,
          },
          value: "0",
        },
      },
      formInputData: null,
      showTextBox: false,
      emptyTriggerTypeMsg: null,
      emptyOnEmailMsg: null,
      emptySchedulelMsg: null,
      emptyChatBotlMsg: null,
      emptyOtherBotsMsg: null,
    });
  };
  clearAddFlowEntitiesValue = () => {
    this.setState({
      entity1: {
        entityName: "Input_01",
        validation: "",
        validationName: "",
        invalidEntity: "",
        question: "",
        entityType: "false",
        remember: "false",
        defaultValue: "",
        optionType: "-1",
        display: "-1",
        addOption: false,
        addOptionValue: "",
        addOptionList: [],
        validationText: "",
        optList: [],
      },
      entity2: {
        entityName: "Input_02",
        validation: "",
        validationName: "",
        invalidEntity: "",
        question: "",
        entityType: "false",
        remember: "false",
        defaultValue: "",
        optionType: "-1",
        display: "-1",
        addOption: false,
        addOptionValue: "",
        addOptionList: [],
        validationText: "",
        optList: [],
      },
      entity3: {
        entityName: "Input_03",
        validation: "",
        validationName: "",
        invalidEntity: "",
        question: "",
        entityType: "false",
        remember: "false",
        defaultValue: "",
        optionType: "-1",
        display: "-1",
        addOption: false,
        addOptionValue: "",
        addOptionList: [],
        validationText: "",
        optList: [],
      },
      entity4: {
        entityName: "Input_04",
        validation: "",
        validationName: "",
        invalidEntity: "",
        question: "",
        entityType: "false",
        remember: "false",
        defaultValue: "",
        optionType: "-1",
        display: "-1",
        addOption: false,
        addOptionValue: "",
        addOptionList: [],
        validationText: "",
        optList: [],
      },
      entity5: {
        entityName: "Input_05",
        validation: "",
        validationName: "",
        invalidEntity: "",
        question: "",
        entityType: "false",
        remember: "false",
        defaultValue: "",
        optionType: "-1",
        display: "-1",
        addOption: false,
        addOptionValue: "",
        addOptionList: [],
        validationText: "",
        optList: [],
      },
      indexValue: "1",
      submitEntity1: false,
      submitEntity2: false,
      submitEntity3: false,
      submitEntity4: false,
      submitEntity5: false,
    });
  };
  clearAddFlowStateValue = () => {
    this.setState({
      AddFlowControls: {
        ...this.state.AddFlowControls,
        botname: {
          ...this.state.botname,
          ...this.state.AddFlowControls.botname,
          value: "",
          defaultFocus: true,
        },
        botdescription: {
          ...this.state.botdescription,
          value: "",
        },
        botEntityInputs: {
          ...this.state.AddFlowControls.botEntityInputs,
          value: "-1",
        },
      },
      emptybotnameMsg: null,
    });
  };
  
  NextEntity = (e, index) => {
    
    e.preventDefault();
    let inputCount = this.state.AddFlowControls.botEntityInputs.value;

    if (index == "1") {
      this.setValidationMessage("question", this.state.entity1.question);
      if (this.state.entity1.question) {
        this.setState({
          submitEntity1: true,
        });
      } else return false;
    } else if (index == "2") {
      this.setValidationMessage("question", this.state.entity2.question);
      if (this.state.entity2.question ) {
        this.setState({
          submitEntity2: true,
        });
      } else return false;
    } else if (index == "3") {
      this.setValidationMessage("question", this.state.entity3.question);
      if (this.state.entity3.question) {
        this.setState({
          submitEntity3: true,
        });
      } else return false;
    } else if (index == "4") {
      this.setValidationMessage("question", this.state.entity4.question);
      if (this.state.entity4.question) {
        this.setState({
          submitEntity4: true,
        });
      } else return false;
    } else {
      this.setValidationMessage("question", this.state.entity5.question);
      if (this.state.entity5.question) {
        this.setState({
          submitEntity5: true,
        });
      } else return false;
    }
    if (index < inputCount) {
      let indexval = JSON.parse(index);
      indexval = indexval + 1;
      this.setState({ indexValue: indexval });
    }
    
  };

  selectKnowledgeIntent = (isbotselected, botName, profile) => {
    if (!isbotselected && botselected != "") {
      this.currentBotEvent(isbotselected, botselected, profile);
    } else {
      this.setState({
        isKnowledgeContentSelected: true,
        selectedKnowledge: botName,
        ShowRightPanel: false,
        currentBotSelected: "",
        refreshKnowledgeIntent: false,
        isCUXBotSelected: false,
        isFaqNavigation:false,
        isOtherDocsNavigation:false,
      });
    }
  };

  selectFlowFromMenu = (flowname) => {
    this.setState({
      isFlowSelected: true,
      isCUXBotSelected: false,
    });
  };

  selectBotFromDropdown = (id, name) => {
    this.selectBotFromMenu(id,name);
    this.setState({popperShow:false});
  }
  selectBotFromMenu = (botid, botname) => {
    // if(botid !== '') {
      this.setState({
        isCUXBotSelected: true,
        selectedCUXBot: botname,
        isFlowSelected: false,
        currentBotSelected: botname,
        selectedCUXBotId: botid,
      });
    // } else {
    //   this.setState({
    //     isCUXBotSelected: true,
    //     isFlowSelected: false,
    //     selectedCUXBotId: 'flowHeader',
    //     currentBotSelected: 'flowHeader',
    //   });
    // }
    
  };

  showCuxFlowMenu = () => {
    this.setState({
      isCUXBotSelected: true,
      isFlowSelected: false,
    })
  }

  checkSelectKnowledgeIntent = (isbotselected, botName, profile) => {
    if (this.props.IsTabChanged) {
      this.selectKnowledgeIntent(isbotselected, botName, profile);
      this.setState({
        isFlowSelected: false,
        ShowRightPanel: false,
        KnowledgeMacroExpand: false,
        isFlowSelectedFlow: false,
      });
    } else {
      this.setState({
        showSavePopup: true,
      });
    }
  };

  deleteProfileModel = (data) => {
    this.setState({ deleteProfileModalShow: true });
    this.setState({ deleteProfileModalData: data });
  };

  deleteProfile(e) {
    e.preventDefault();
    this.setState({ deleteProfileModalShow: true });
    let data = this.state.deleteProfileModalData;
    this.props.OnDeleteProfile(this.props.config.BOTBUILDER_URL, data);
    this.setState({ deleteProfileModalShow: false });
    this.setState({ deleteProfileModalData: "" });
  }

  deleteBotModal = (data) => {
    this.setState({ deleteBotModalShow: true });
    this.setState({ deleteBotModalData: data });
    // this.props.OnGetBotList(this.props.config.BOTBUILDER_URL);
    // setTimeout(() => {
    //   this.setState({
    //     isKnowledgeContentSelected: true,
    //     selectedKnowledge: "Intents",
    //     ShowRightPanel: false,
    //     currentBotSelected: "",
    //     refreshKnowledgeIntent: false,
    //     isCUXBotSelected: false,
    //     isFaqNavigation:false,
    //     isOtherDocsNavigation:false,
    //   });
    // }, 1000);
  };

  deleteCuxBotModal = (data) => {
    this.setState({ deleteCuxBotModalShow: true });
    this.setState({ deleteCuxBotModalData: data });
  };

  deleteEntityModal = (data) => {
    this.setState({
      deleteEntityModalShow: true,
      deleteEntityModalData: data,
    });
  };

  async deleteCuxBot(e) {
    e.preventDefault();

    await this.props.onDeleteCuxBot(
      this.props.config.COMMON_API_URL,
      this.state.deleteCuxBotModalData
    );
    if(this.state.currentBotSelected.includes('Template_')) {
        this.props.onDeleteCuxTemplate(this.props.config.COMMON_API_URL, this.state.currentBotSelected, 'flow');
    }
    if (this.props.cuxBotList.length !== 1) {
      let cuxBotItem =
        this.props.cuxBotList[0].flowid !== this.state.deleteCuxBotModalData
          ? this.props.cuxBotList[0]
          : this.props.cuxBotList[1];
      this.setState({
        isCUXBotSelected: true,
        selectedCUXBot: cuxBotItem.flowname,
        selectedCUXBotId: cuxBotItem.flowid,
        deleteCuxBotModalData: "",
        deleteCuxBotModalShow: false,
        currentBotSelected: cuxBotItem.flowname,
      });
    } else {
      this.setState({
        deleteCuxBotModalData: "",
        deleteCuxBotModalShow: false,
        isCUXBotSelected: true,
        // isFlowSelected: false,
        selectedCUXBotId: 'flowHeader',
        currentBotSelected: 'flowHeader',
      });
      // botdeleted = true;
    }
  }

  deleteBot(e) {
    let projectname = localStorage.getItem("projectname");
    let language = localStorage.getItem("language");
    var botNm;
    if(this.state.AddFlowControls.botname.value != '')
    botNm = this.state.AddFlowControls.botname.value
    else
    botNm = this.state.deleteBotModalData.botName
    e.preventDefault();
    //this.setState({ deleteBotModalShow: true });
    let data = this.state.deleteBotModalData;
    this.props.ongetEntity(
      {
        language: language,
        projectname: projectname,
        botName: botNm,
      },
      this.props.config.BOTBUILDER_URL
    );
    this.props.OnDeleteBot(this.props.config.BOTBUILDER_URL, data, this.state.isCuxOrg)
    this.clearIntent();
        
        this.props.OnGetBotList(this.props.config.BOTBUILDER_URL);
        this.setState({ deleteBotModalShow: false });
        this.setState({ deleteBotModalData: null });
        botdeleted = true;
    
    setTimeout(() => {
      this.props.OnGetBotList(this.props.config.BOTBUILDER_URL);
      this.setState({
        isKnowledgeContentSelected: true,
        selectedKnowledge: "Intents",
        ShowRightPanel: false,
        currentBotSelected: "",
        refreshKnowledgeIntent: false,
        isCUXBotSelected: false,
        isFaqNavigation:false,
        isOtherDocsNavigation:false,
      });
    }, 2000);
  }
  exportBotModal = (data) => {
    this.props.OnExportBot(this.props.config.BOTBUILDER_URL, data);
  };
  exportCuxBotHandler = (data) => {
    this.props.OnExportCuxBot(this.props.config.BOTBUILDER_URL, data);
  };
  exportProjectModal = () => {
    let data = {
      language: localStorage.getItem("language")
        ? localStorage.getItem("language")
        : "en",
      name: localStorage.getItem("projectname"),
    };
    this.props.OnExportProject(this.props.config.BOTBUILDER_URL, data);
    $(".dropdownCustomClass  .dropdown-item").removeClass("active");
  };
  validateFile = (file) => {
    const validTypes = ["application/x-zip-compressed","application/zip"];
    if (validTypes.indexOf(file) === -1) {
      this.setState({ filevalidation: "File Type is not permitted" });
      return false;
    }
    this.setState({ filevalidation: null });
    return true;
  };
  handleFlowFile = (e) => {
    if (e.target.value && e.target.files[0].name) {
      let cuxFlowName = e.target.files[0].name.split(".")[0];
      if (this.isThisExistingCuxFlow(cuxFlowName)) {
        this.setState({
          filevalidation: "Flow already exists.",
          importFile: e.target.files[0],
        });
        return;
      }
    }
    if (!this.validateFile(e.target.files[0].type)) {
      this.setState({
        importflow: null,
        importFile: null
      });
      return;
    }
    this.setState({
      importflow: e.target.value,
      importFile: e.target.files[0]
    });
  }

  isThisExistingCuxFlow = (flowName) => {
    let duplicate = null;
    let botList = this.props.cuxBotList;
    if (!botList || botList.length === 0)
      return false;
    
    duplicate = botList.find(item => item.flowname === flowName);
    return duplicate?true:false
  }

  submitCuxFlowImport = (e) => {
    e.preventDefault();
    let projectname = localStorage.getItem("projectname");
    let language = localStorage.getItem('language');
    let flowName = this.state.importFile.name;
    let formData = new FormData();
    formData.append("file", this.state.importFile);
    formData.append("project", projectname);
    formData.append("language", language ? language : "en");
    
    this.props.OnImportCuxBot(
      this.props.config.COMMON_API_URL,
      formData,
       flowName
    );
    this.clearUploadProjectStateValue();
    this.setState({ filevalidation: null });
    this.setState({ setImportCuxBotShow: false });
  }

  handleFile = (e) => {
    if (e.target.value && e.target.files[0].name) {
      let flowName = e.target.files[0].name.split(".")[0];
      if (this.isThisExistingFlow(flowName)) {
        this.setState({
          filevalidation: "Intent already exists.",
          importflow: e.target.value,
          importFile: e.target.files[0],
        });
        return;
      }
    }
    this.setState({
      importflow: e.target.value,
      importFile: e.target.files[0],
    });
    if (e.target.files[0] && this.validateFile(e.target.files[0].type)) {
      let botExistAlready = false;
      let tempPathFile = null;
      let fileAdded = null;
      let projectname = localStorage.getItem("projectname");
      if (!projectname) projectname = "Common";
      let file = e.target.files[0];
      if (file) {
        fileAdded = file.name.split(".");
        tempPathFile = fileAdded[0];
      }
      if (fileAdded[0].includes("(")) {
        tempPathFile = fileAdded[0].split("(");
        tempPathFile = tempPathFile[0].trim();
      }
      var merged = [].concat.apply([], Object.values(this.props.botList));
      let objectKeys = Object.keys(this.props.botList);

      // for (var key in objectKeys) {
      //   if (this.props.botList[objectKeys[key]][projectname] !== undefined) {
      //     var botobjkeys = Object.keys(
      //       this.props.botList[objectKeys[key]][projectname]
      //     );
      //     for (var botkey in botobjkeys) {
      //       if (botobjkeys[botkey] === tempPathFile) {
      //         this.setState({
      //           filevalidation: "Flow already exists.",
      //         });
      //         botExistAlready = true;
      //         break;
      //       }
      //     }
      //   }

      //   if (!botExistAlready) {
      //     this.setState({ filevalidation: null });
      //     this.setState({
      //       importflow: e.target.value,
      //       importFile: e.target.files[0],
      //     });
      //   } else {
      //     this.setState({ filevalidation: "Flow already exists." });
      //   }
      // }
    } else {
      if (e.target.files[0])
        this.setState({
          importflow: null,
          importFile: null,
          filevalidation: "File Type is not permitted",
        });
      else
        this.setState({
          importflow: null,
          importFile: null,
        });
    }
  };

  isThisExistingFlow = (flow) => {
    let isExists = false;
    let botlist = "";
    let projectname = localStorage.getItem("projectname");
    let language = localStorage.getItem("language");
    if (!language || language == "en") language = "en";
    let flowList = null;
    if (this.props.botList != "" && this.props.botList != undefined) {
      botlist = this.props.botList;
    }
    let botEnglish = botlist[language];
    if (botEnglish) {
      if (botEnglish.hasOwnProperty(projectname)) {
        flowList = Object.keys(botEnglish[projectname]);
        isExists = flowList.find((ele) => ele == flow);
      }
    }
    return isExists;
  };

  submitImport = (e) => {
    e.preventDefault();
    let projectname = localStorage.getItem("projectname");
    this.setState({ submitImport: true });
    let flowName;
    if (this.state.importFile && this.state.importFile.name) {
      flowName = this.state.importFile.name;
      if (this.isThisExistingFlow(flowName)) {
        this.setState({
          filevalidation: "Flow already exists.",
        });
        return;
      }
    }
    if (this.state.importFile) {
      let formData = new FormData();
      formData.append("file", this.state.importFile);
      formData.append("project", projectname);
      formData.append("language", localStorage.getItem('language'));
      this.props.OnImportFlow(
        this.props.config.FileUpload_URL,
        formData,
        this.props.config.BOTBUILDER_URL,
        flowName
      );
      this.clearUploadProjectStateValue();
      this.setState({ filevalidation: null });
      this.setState({ setImportFlowShow: false });
    } else {
      this.setState({
        filevalidation: "File is required.",
      });
    }
  };
  removeColor = () => (e) => {
    let span = e.target.parentElement
    let classList = span.parentElement.classList;
    classList = classList[1].split("_");
    let idxx = classList[1];
    let parentTag = e.target.parentElement.parentElement
    e.target.parentNode.outerHTML = e.target.parentNode.outerText.slice(0, -1);
    const rows = [...this.state.rows];
    rows[idxx] = {
      name: parentTag.innerHTML,
    };
    this.setState({
      rows,
    });
  };
  addEntityTag(event, bgcolor) {
    if (
      window.getSelection().toString() != "" &&
      window.getSelection().baseNode.parentNode.parentNode.parentNode.parentNode
        .id != "addPatternform"
    )
      return true;
    let span = document.createElement("span");
    span.style.backgroundColor = bgcolor;
    let anchor = document.createElement("a");
    anchor.innerText = "x";
    anchor.onclick = this.removeColor();
    anchor.className = classes.removeTag;
    if (window.getSelection().toString() != "") {
      var sel = window.getSelection();
      var range = sel.getRangeAt(0);
      if (!sel.isCollapsed) {
        if (/\s+$/.test(sel.toString())) {
          if (sel.focusOffset > sel.anchorOffset) {
            range.setEnd(sel.focusNode, sel.focusOffset - 1);
          } else {
            range.setEnd(sel.anchorNode, sel.anchorOffset - 1);
          }
        }
      }
      if (sel.rangeCount && sel.toString() != "") {
        range.surroundContents(span);
        sel.addRange(range);
        span.appendChild(anchor);
        range.setEndAfter(span);
        sel.removeAllRanges();
      }
      if (span.parentElement == null) return false;
      let content = span.parentElement.innerHTML;
      let classList = span.parentElement.classList;
      classList = classList[1].split("_");
      let idxx = classList[1];
      const rows = [...this.state.rows];
      rows[idxx] = {
        name: content,
      };
      this.setState({
        rows,
      });
      this.setState({
        anchorEl: null,
      });
    }
  }

  clearUploadProjectStateValue = () => {
    this.setState({ importflow: "", importFile: "" });
  };
  excel_data = (workbook) => {
    let result = {};
    workbook.SheetNames.forEach(function (sheetName) {
      let roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
        header: 1,
      });
      if (roa.length) result[sheetName] = roa;
    });
    return JSON.stringify(result, 2, 2);
  };
  OpenConnectDataModal = () => {
    this.GetConnectData();
    this.setState({ IsConnectModalShow: true });
  };
  importConnectDataFileEvent = (event) => {
    this.setState(
      {
        ImportConnectDataFileName: event.target.value,
        ImportConnectDataFile: event.target.files[0],
      },
      function () {
        this.importConnectData();
      }
    );
  };
  importConnectData = () => {
    const csv = this.state.ImportConnectDataFileName;
    const filename = this.state.ImportConnectDataFile.name.split(".")[0];
    const regex = /^([a-zA-Z0-9\s_()\\.\-:])+(.csv|.txt|.xlsx)$/;
    const project = localStorage.getItem("projectname");
    const lang = "en";
    const self = this;
    if (regex.test(csv.toLowerCase())) {
      if (typeof FileReader != "undefined") {
        let reader = new FileReader();
        reader.onload = function (e) {
          let data = e.target.result;
          let csvjson = self.excel_data(XLSX.read(data, { type: "binary" }));
          let parseData = JSON.parse(csvjson);
          let rows = parseData.hasOwnProperty("Sheet1")
            ? parseData["Sheet1"]
            : parseData[filename];
          if (rows.length > 0) {
            let resp = "";
            let topcalls = [];
            let cnt = 0;
            let first_Row_Cells = rows[0]; 
            let jsonArray = new Array();
            for (var i = 1; i <= rows.length - 1; i++) {
              let cells = {}; 
              if (rows[i].length > 0) {
                for (let q = 0; q < first_Row_Cells.length; q++) {
                  if (first_Row_Cells[q] == "Category") {
                    topcalls[cnt] = rows[i][q];
                    cnt++;
                  }

                  if (rows[i][q] != "" && rows[i][q] != null)
                    cells[first_Row_Cells[q].trim()] =
                      typeof rows[i][q] == "string"
                        ? rows[i][q].trim()
                        : rows[i][q];
                  else cells[first_Row_Cells[q].trim()] = "";
                }
              }

              let obj = {};
              let indexobj = {};
              let size = Object.keys(cells).length;
              obj = cells;

              if (Object.keys(obj).length > 0) {
                indexobj["index"] = {
                  _index: "demotest",
                  _type: lang + "_" + project,
                };
                resp += JSON.stringify(indexobj);
                resp += "\n";
                obj["categoryorg"] = obj["Category"].replace(
                  /[^a-zA-Z0-9]/g,
                  ""
                );
                resp += JSON.stringify(obj) + "\n";
              }
            }
            self.ESBulkImport(resp, true, "ConnectData");
          }
        };
        reader.readAsBinaryString(this.state.ImportConnectDataFile);
      } else {
      }
    } else {
      this.props.OnHandleImportError("Select a valid CSV & XLSX File.");
    }
  };

  initClusterRender = (data) => {
    let clusters = null;
    let clu_col = "";
    let c_clu_col = [];
    let titleLen = 130;
    const headingTitleLen = 150;
    let cdata = data;
    let cName = "demotest";
    let clusterArray = [];

    for (var i = 0; i < data.clusters.length; i++) {
      let clusterObj = {};
      let clusterDocs = [];
      clusterObj.Id = data.clusters[i].id;
      clusterObj.Title = data.clusters[i].label;
      let heading_text = data.clusters[i].label;
      let subStrHeadingTitle = this.trunc(
        headingTitleLen,
        heading_text,
        "string"
      );
      let cluster__Data = [];
      let show_more_include = null;
      let show_less_include = null;
      const originalId = data.clusters[i].id;
      const clusterDivId = "divCluster_" + data.clusters[i].id;
      const clusterDivTabContentId = "divTabContent_" + data.clusters[i].id;
      const clusterDivBodyId = "divClusterBody_" + data.clusters[i].id;
      show_more_include = (
        <div className={"show-more"} style={{ textAlign: "center" }}>
          <span style={{ float: "right" }}>
            <span
              onClick={(event) =>
                this.ShowMoreContent(
                  event,
                  clusterDivId,
                  clusterDivTabContentId
                )
              }
              style={{ outline: "none", cursor: "pointer", color: "#0080ff" }}
            >
              Show more ∨{" "}
            </span>
          </span>{" "}
        </div>
      );
      show_less_include = null;
      c_clu_col.push(
        <>
          <div
            id={clusterDivId}
            style={{ padding: "15px", float: "left", zIndex: "100" }}
            className={[
              "col-lg-4",
              "col-md-4",
              "col-sm-6",
              "col-xs-12",
              "card_container",
            ].join(" ")}
          >
            <div
              id={clusterDivBodyId}
              style={{
                bordeColor: "transparent",
                boxShadow: "0px 3px 7px #00000029",
              }}
            >
              <div class={"card-header"}>
                <div title={heading_text}>
                  {" "}
                  <span style={{ color: "#494c55" }} title={subStrHeadingTitle}>
                    {" "}
                    {subStrHeadingTitle}
                  </span>
                  <b>
                    <span
                      onClick={(event) =>
                        this.importClusterData(event, originalId)
                      }
                      title="Create Flow"
                      style={{ float: "right", cursor: "pointer" }}
                    >
                      {">>"}
                    </span>
                  </b>{" "}
                </div>
              </div>
              <div
                style={{
                  minHeight: "130px",
                  overflowY: "auto",
                  padding: ".25rem",
                }}
                className={"card-body tabs-below"}
              >
                <div
                  id={clusterDivTabContentId}
                  onClick={(event) => this.selectDiv(event, clusterDivBodyId)}
                  style={{ cursor: "pointer" }}
                  className={classes.TabContent}
                >
                  <div>{cluster__Data}</div>
                </div>
                {data.clusters[i].documents.length > 4
                  ? show_more_include
                  : show_less_include}
              </div>
            </div>
          </div>
        </>
      );

      let category = heading_text;
      for (var j = 0; j < data.clusters[i].documents.length; j++) {
        let title_txt = this.getClusterTitle(
          cdata,
          data.clusters[i].documents[j],
          "Title"
        );
        clusterDocs.push(title_txt);
        let desc_txt = this.getClusterTitle(
          cdata,
          data.clusters[i].documents[j],
          "Title"
        );
        let category = this.getClusterTitle(
          cdata,
          data.clusters[i].documents[j],
          "Category"
        );
        let subStrTitle = desc_txt;
        const crNumbtitleLen = 100;
        let crNumberTxt = this.trunc(titleLen, title_txt, "string");
        let subcrNumberTxt = this.trunc(crNumbtitleLen, crNumberTxt, "string");
        cluster__Data.push(
          <label
            className={"clusterListDetailWrap"}
            style={{ display: "flex", cursor: "pointer" }}
            value={data.clusters[i].documents[j]}
          >
            <span className={classes.spanElaspsis} title={desc_txt}>
              {" "}
              {subStrTitle}{" "}
            </span>
          </label>
        );
      }
      clusterObj.Docs = clusterDocs;
      clusterArray.push(clusterObj);
    
    }
    clusters = <div className={classes.clusterslist}> {c_clu_col}</div>;
    this.setState({ clusterArray: clusterArray });
    return clusters;
  };
  getClusterTitle = (data, cluster_id, type) => {
    for (var i = 0; i < data.hits.hits.length; i++) {
      if (cluster_id == data.hits.hits[i]._id)
        return this.strip(data.hits.hits[i]._source[type]);
    }
  };
  trunc = (n, element, typeofText) => {
    String.prototype.trim = function () {
      return this.replace(/^\s\s*/, "").replace(/\s\s*$/, "");
    };
    if (typeofText == "string") var str = element;
    else if (typeofText == "element") var str = element.innerHTML;

    if (this.visualLength(str) > n) {
      do {
        str = str.substr(0, str.length - 1).trim();
      } while (this.visualLength(str) > n);
      if (typeofText == "string") {
        return str + "...";
      } else if (typeofText == "element") {
        element.title = element.innerHTML;
        element.insertAdjacentHTML("beforeend",str + "&hellip;");
      }
    } else {
      return str;
    }
  };
  strip = (html) => {
    var tmp = document.createElement("DIV");
    tmp.insertAdjacentHTML("beforeend",html);
    return tmp.textContent || tmp.innerText;
  };
  visualHeight = (str) => {
    var pruler = document.createElement("div");
    pruler.style.overflow = "hidden";
    pruler.style.lineHeight = "1em";
    pruler.style.height = "61px";
    var ruler = document.createElement("div");
    ruler.style.visibility = "hidden";
    pruler.appendChild(ruler);
    document.body.appendChild(pruler);
    ruler.innerText = str;
    var c = ruler.children;

    for (let i = 0; i < c.length; i++) {
      c[i].style.display = "block";
      c[i].style.lineHeight = "1";
    }
    var l = ruler.offsetHeight;
    var p = pruler.offsetHeight;
    document.body.removeChild(pruler);
    if (l > p) return true;
    else return false;
  };
  visualLength = (str) => {
    var ruler = document.createElement("span");
    ruler.style.visibility = "hidden";
    ruler.style["white-space"] = "nowrap";
    document.body.appendChild(ruler);
    ruler.innerText = str;
    var l = ruler.offsetWidth;
    document.body.removeChild(ruler);
    return l;
  };
  translateProject = (langarray) => {
    let projectname = localStorage.getItem("projectname");
    let orgName = localStorage.getItem("orgName");
    let language = localStorage.getItem("language");
    if (!language || language == "en") {
      language = "en";
    }
    let langkeys = [];
    langarray.map((item, idx) => {
      langkeys.push(item.langkey);
    });

    let data = {
      multilanguages: langkeys,
      collection: "optimus",
      size: "10000",
      orgName: orgName,
      project: projectname,
      language: language,
    };
    this.setState({
      isProjectTranslation: true,
      translationdata: { data: data, langarray: langarray },
    });
  };
  handleProjectTranslation = (event) => {
    event.preventDefault();
    this.props.onTranslateProject(
      this.props.config.API_URL,
      this.state.translationdata.data,
      this.state.translationdata.langarray,
      this.props.projectdata
    );
    this.handleClose("projectTranslation");
    this.props.onHandleTranslateBtn([]);
  };
  handleTranslateBtn = (langarray) => {
    let disableTranslationBtn = true;
    if (langarray.length > 0 && this.props.translationinprogress == false) {
      disableTranslationBtn = false;
    }
    this.props.onHandleTranslateBtn(disableTranslationBtn);
  };

  ShowCustomMacroModal = (item) => {
    if(!this.props.AIStandard.RW){
      return;
    }
    let projectname = localStorage.getItem("projectname");
    let orgName = localStorage.getItem("orgName");
    let language = localStorage.getItem("language");
    if (!language) {
      language = "en";
    }
    let data = {
      projectName: projectname,
      language: language,
      orgName: orgName,
    };
    this.setState({ custommacroShow: true });
    this.props.onreadMacro(this.props.config.API_URL, data);
    setTimeout(() => {
      if (item) this.botEditor.editor.find(item + "(");
    }, 2000);

  };

  ShowDisplayInfoModal = (item) => {
    let projectname = localStorage.getItem("projectname");
    let orgName = localStorage.getItem("orgName");
    let language = localStorage.getItem("language");
    if (!language) {
      language = "en";
    }
    let data = {
      projectName: projectname,
      language: language,
      orgName: orgName,
    };
    this.setState({ macrosUserValue: item });
    this.setState({ displayInfoShow: true });
  };
  InfoDisplayModal = (item) => {
    let projectname = localStorage.getItem("projectname");
    let orgName = localStorage.getItem("orgName");
    let language = localStorage.getItem("language");
    if (!language) {
      language = "en";
    }
    let data = {
      projectName: projectname,
      language: language,
      orgName: orgName,
    };
    this.setState({ macrosUserValue: item });
    this.setState({ displayInfoShow1: true });
  };
  ShowUseModal = (item) => {
    this.setState({ useMacroValue: item });
    this.setState({ displayUseContent: true });
  };
  ExpandKnowledgeGrid = () => {
    this.props.onKnowledgeGridExpandCollapse(
      this.props.IsKnowledgeGridCollapse ? "collapse" : "expand"
    );
  };
  handleShowMacroDiv(flag) {
    this.setState({ KnowledgeMacroExpand: flag });
  }

  deleteProjectModel = () => {
    this.setState({ deleteProjectModalShow: true });
  };
  handleShowEditFlow(flag) {
    this.setState({ EditFlowExpand: flag });
  }

  getIntentText = () => {
    if (this.props.docData) {
      let getDocArr = this.props.docData;
      if (this.state.currentBotSelected == getDocArr.intent) {
        if (getDocArr.training.learn) {
          let learn = getDocArr.training.learn.split("\n");
          let traindat = learn[0];
          return traindat;
        }
      }
    }
  };

  deleteScriptData = () => {
    let newTraindat = "";
    let replySplittedData = "";
    if (this.props.docData) {
      let getDocArr = this.props.docData;

      if (this.state.currentBotSelected == getDocArr.intent) {
        if (getDocArr.training.learn) {
          let learn = getDocArr.training.learn.split("\n");
          let traindat = learn[0];
          newTraindat = traindat;
        }
      }
    }

    if (this.props.botProfileFileData) {
      let replayData = this.props.botProfileFileData;
      let splittedData = replayData.split(",");
      replySplittedData = splittedData[1].replace(/[^\w\s]/g, "");
    }

    if (newTraindat !== "" || replySplittedData !== "") {
      this.setState({ deleteScriptData: true });
    } else {
      return null;
    }
  };
  deleteProjectList() {
    let projectname = localStorage.getItem("projectname");
    let language1 = localStorage.getItem("language");
    let orgName = localStorage.getItem("orgName");
    let collectionName = "optimus";
    let boolValue = true;
    let data = {
      collectionName: collectionName,
      projectName: projectname,
      orgName: orgName,
      project: projectname,
      language: language1,
    };
    // this.props.OnDeleteProject(
    //   this.props.config.BOTBUILDER_PROXY,
    //   data,
    //   boolValue
    // );
    


    Promise.all([this.props.OnDeleteProject(
      this.props.config.BOTBUILDER_PROXY,
      data,
      boolValue
    )]).then(
      ()=>{
		  setTimeout(() => {
			this.props.OnGetAllProjectsData(this.props.config.BOTBUILDER_URL);
            this.props.history.push({
              pathname: "/projectview",
          })
        }, 2000);
      }
    )
  //   setTimeout(() => {
  //   this.props.OnGetAllProjectsData(this.props.config.BOTBUILDER_URL);
  // },3000);
    this.handleClose("deleteProjectModal");
    const queryParams = [];
    queryParams.push("projname=" + localStorage.getItem("projectname"));
    const commonapi_URL = this.props.config.COMMON_API_URL;
    this.props.OnGetUserPermissions(commonapi_URL, projectname);

    setTimeout(() => {
      this.props.onGetUserById(
        this.props.config.API_URL,
        this.props.config.BOTBUILDER_URL
      );
      this.props.onGetEnglishProjects(this.props.config.BOTBUILDER_URL);
    }, 10000);
    
    this.setState({
      currentBotSelected: null,
    });
    let url =this.props.config.INSIGHTS_API_URL+'/product-intelligent-triage/cleanup/delete-by-project';
    let obj = {
      "projects": [localStorage.getItem("projectname")],
      "ticketdump": true,
      "SRCMap": true,
      "triageMap": true,
      "descriptionMap": true,
      "questionsMap": true,
      "sourcesMap": true
  }
    PostCalltoServer(url,obj).then(response => {
        console.log(response)
    }).catch(err => {
        console.log(err)
    });
    
  }

  LanguageChangedHandler = (event) => {
    this.setState({
      selectedLanguage: event.target.value,
    });
    this.setValidationMessage("translateLanguage", event.target.value);
  };
  handleSubmitTranslate = (event) => {
    let data = {
      project: localStorage.getItem("projectname"),
      translateLanguage: this.state.selectedLanguage,
      language: localStorage.getItem("language"),
    };

    this.setValidationMessage("translateLanguage", this.state.selectedLanguage);
    if (this.state.selectedLanguage !== -1) {
      this.props.onTranslateProject(this.props.config.BOTBUILDER_PROXY, data);

      this.setState({
        showTranslate: false,
        showTranslateError: null,
      });
      setTimeout(() => {
        this.props.OnGetProjectLang(
          this.props.config.BOTBUILDER_URL,
          localStorage.getItem("projectname")
        );
      }, 7000);
    }
  };
  render() {
    let languagesData = this.props.languageList;
    let language_array = languagesData && languagesData.split(",");
    const uniquelanguage = [...new Set(language_array)];
    let availablelanguage = [];
    if (this.props.availableprojectLangs) {
      availablelanguage = this.props.availableprojectLangs;
    }

    let templanglist = [{ value: -1, displayValue: "Select the Language" }];
    let currentLangauge = "english";
    let availableLangList = [];
    availablelanguage.map((option) => {
      uniquelanguage.forEach((item) => {
        let lngvalue = item.split("_")[0];
        if (item.split("_")[1] == localStorage.getItem("language")) {
          currentLangauge =
            lngvalue.charAt(0).toUpperCase() + lngvalue.slice(1);
        }
        if (availablelanguage.includes(item.split("_")[1]) == false) {
          templanglist.push({
            value: item.split("_")[1],
            displayValue: lngvalue.charAt(0).toUpperCase() + lngvalue.slice(1),
          });
        }
        return true;
      });
    });
    templanglist = [
      ...new Map(templanglist.map((item) => [item["value"], item])).values(),
    ];

    availablelanguage.map((option) => {
      uniquelanguage.forEach((item) => {
        let lngvalue = item.split("_")[0];
        if (availablelanguage.includes(item.split("_")[1])) {
          availableLangList.push(
            lngvalue.charAt(0).toUpperCase() + lngvalue.slice(1)
          );
        }
        return true;
      });
    });
    availableLangList = [...new Set(availableLangList)];

    let parseSavedData = "";
    if (
      this.props.saveMacrosData != undefined &&
      this.props.saveMacrosData != null
    ) {
      parseSavedData = this.props.saveMacrosData.trim();
    }
    let flowValue =
      parseSavedData.length > 15
        ? parseSavedData.slice(0, 40) + "..."
        : parseSavedData;

    let parseData = "";
    if (
      this.props.getMacrosFileData != undefined &&
      this.props.getMacrosFileData != null
    ) {
      parseData = JSON.parse(this.props.getMacrosFileData);
    }
    let useMacros =
      parseData &&
      parseData.macros.map((item, id) => {
        return item.name == this.state.useMacroValue ? (
          <>
            <h5 style={{ color: "black" }}>Inputs</h5>
            <MDBTable className={classes.useTableData} id="paramTable">
              <MDBTableBody>
                {item.params.map((param, i) => {
                  return (
                    <tr key={i}>
                      <td>{param.name} </td>
                      <td>
                        <input
                          type="text"
                          name="paramValue"
                          autoComplete="off"
                          className="form-control"
                        />
                      </td>
                    </tr>
                  );
                })}
              </MDBTableBody>
            </MDBTable>
          </>
        ) : null;
      });
    let displaydata =
      parseData &&
      parseData.macros.map((item, id) => {
        return item.name == this.state.macrosUserValue ? (
          <>
            <div>
              <ul>
                <li>
                  {item.params.map((param, i) => {
                    return (
                      <div class="input-group mt-7 mb-7">
                        <input
                          type="text"
                          value={param.name}
                          class="form-control mr-1"
                          placeholder=""
                        />
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control"
                            onChange={(event) =>
                              this.handleUseMacros(event, param.name)
                            }
                          />
                        </div>
                      </div>
                    );
                  })}
                </li>
              </ul>
            </div>
          </>
        ) : null;
      });
    let displaydata1 =
      parseData &&
      parseData.macros.map((item, id) => {
        return item.name == this.state.macrosUserValue ? (
          <>
            <p>One Line Description of Macro</p>
            <input type="text" className="infodesc" placeholder="" />
            <br></br>
            <br></br>
            <DialogContent>
              <p className="infopara">Detail Description</p>
              <textarea
                value={item.desc}
                className="infodesc1"
                placeholder=""
              />
            </DialogContent>
          </>
        ) : null;
      });
    let projectAccuracy = (
      <>
        <CircleProgressBar
          trailStrokeColor="gray"
          strokeColor="#448aff"
          percentage={this.state.projectAccuracy}
        />
      </>
    );

    let createProfile = (
      <>
        <Row>
          <Col md={12}>
            <div className="inputContainer">
              <Form.Label>Profile Name*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter profile name..."
                value={this.state.createProfileInput.value}
                onSubmit
                onChange={(event) => this.inputCreateProfileHandler(event)}
                onB
              />
            </div>
          </Col>
        </Row>
        {this.state.emptyCreateProfileMsg
          ? this.state.emptyCreateProfileMsg
          : null}
      </>
    );

    let formdata = "";
    if (this.state.formInputData == "Manual") {
    } else if (this.state.formInputData == "OnEmail") {
      const id = "onEmailInput";
      formdata = (
        <Row>
          <Col md={12}>
            <div className="inputContainer">
              <label className={classes.ProjectTabContentLabel}>
                Folder Name:{" "}
              </label>
              <Input
                key={id}
                customclassName="form-control"
                elementType="text"
                value={this.state.OnEmailControls.onEmailInput.value}
                changed={(event) => this.inputEmailChangedHandler(event)}
              />
            </div>
            {id === "onEmailInput" ? this.state.emptyOnEmailMsg : null}
          </Col>
        </Row>
      );
    } else if (this.state.formInputData == "Schedule") {
      const id = "scheduleRadioInput";
      formdata = (
        <>
          <div>
            <input
              type="radio"
              value="Hourly"
              name="selectScheduler"
              onChange={(event) => this.inputRadioChangedHandler(event)}
            />{" "}
            Hourly
            <br />
            <input
              type="radio"
              value="Weekly"
              name="selectScheduler"
              onChange={(event) => this.inputRadioChangedHandler(event)}
            />{" "}
            Weekly
            <br />
            <input
              type="radio"
              value="Monthly"
              name="selectScheduler"
              onChange={(event) => this.inputRadioChangedHandler(event)}
            />{" "}
            Monthly
            <br />
            <input
              type="radio"
              value="Daily"
              name="selectScheduler"
              onChange={(event) => this.inputRadioChangedHandler(event)}
            />{" "}
            Daily
            <br />
            <input
              type="radio"
              value="UserDefined"
              name="selectScheduler"
              onChange={(event) => this.inputRadioChangedHandler(event)}
            />{" "}
            UserDefined <br />
            {this.state.showTextBox ? (
              <input
                type="text"
                placeholder="Enter cron value"
                onChange={(event) =>
                  this.inputScheduleUserDefineChangedHandler(event)
                }
              />
            ) : null}
            <br />
          </div>
          {this.state.emptySchedulelMsg}
        </>
      );
    } else if (this.state.formInputData == "otherBots") {
      const addOtherBotsElementsArray = [];
      for (let key in this.state.OtherBotsControls) {
        addOtherBotsElementsArray.push({
          id: key,
          config: this.state.OtherBotsControls[key],
          labelName: "Select bots:",
        });
      }
      formdata = addOtherBotsElementsArray.map((formElement) => (
        <Row key={formElement.id}>
          <Col md={12}>
            <div className="inputContainer">
              {formElement.labelName}
              <Input
                key={formElement.id}
                customclassName={formElement.config.className}
                elementType={formElement.config.elementType}
                elementConfig={formElement.config.elementConfig}
                value={formElement.config.value}
                invalid={!formElement.config.valid}
                shouldValidate={formElement.config.validation}
                touched={formElement.config.touched}
                changed={(event) =>
                  this.inputOtherBotsChangedHandler(event, formElement.id)
                }
              />
            </div>
            {formElement.id === "otherBotsInputs"
              ? this.state.emptyOtherBotsMsg
              : null}
          </Col>
        </Row>
      ));
    } else if (this.state.formInputData == "ChatBot") {
      const addChatBotsElementsArray = [];
      for (let key in this.state.ChatBotControls) {
        addChatBotsElementsArray.push({
          id: key,
          config: this.state.ChatBotControls[key],
          labelName: "Intent List:",
        });
      }
      formdata = addChatBotsElementsArray.map((formElement) => (
        <Row key={formElement.id}>
          <Col md={12}>
            <div className="inputContainer">
              {formElement.labelName}
              <Input
                key={formElement.id}
                customclassName={formElement.config.className}
                elementType={formElement.config.elementType}
                elementConfig={formElement.config.elementConfig}
                value={formElement.config.value}
                invalid={!formElement.config.valid}
                shouldValidate={formElement.config.validation}
                touched={formElement.config.touched}
                changed={(event) =>
                  this.inputChatBotChangedHandler(event, formElement.id)
                }
              />
            </div>
            {formElement.id === "chatBotInputs"
              ? this.state.emptyChatBotlMsg
              : null}
          </Col>
        </Row>
      ));
    }

    let clusterCards = null;
    if (this.state.clusterData && this.state.clusterData !== null) {
      clusterCards = clusertHtml
        ? clusertHtml
        : this.initClusterRender(this.state.clusterData);
      clusertHtml = clusterCards;
    }

    let botProfileFile = "";
    if (this.props.botProfileFileData) {
      botProfileFile = this.props.botProfileFileData;
    }

    let triggerExtractValue = "";
    let flowsExtractValue = "";
    let botlist = "";
    let projectname = localStorage.getItem("projectname");
    let language = localStorage.getItem("language");
    if (!language || language == "en") language = "en";
    let bots = null;
    let projectList = null;
    let selectBot = this.state.currentBotSelected;
    if (this.props.botList != "" && this.props.botList != undefined) {
      botlist = this.props.botList;
    }
    let botEnglish = botlist[language];
    if (botEnglish) {
      if (botEnglish.hasOwnProperty(projectname)) {
        projectList = botlist[language][projectname];
        let botsData = projectList[selectBot];
        if (botsData) {
          let triggerValue = botsData[0]["triggerType"];
          let splittedValue = triggerValue.split("&&");
          triggerExtractValue = splittedValue[1];
          let splittedFlowsValue = splittedValue[2];
          flowsExtractValue = splittedFlowsValue;
        }
      }
    }

    let flowList = "";
    let triggerFlowsValue = "";
    if (this.props.projectdata) {
      let projectname = localStorage.getItem("projectname");
      let language = localStorage.getItem("language");
      if (!language || language == "en") language = "en";
      let selectBot = this.state.currentBotSelected;
      let projectList = null;
      flowList = this.props.projectdata;
      let botEnglish = flowList[language];
      if (botEnglish) {
        if (botEnglish.hasOwnProperty(projectname)) {
          projectList = flowList[language][projectname];
          let botsData = projectList[selectBot];
          if (botsData) {
            let triggerValue = botsData[0]["triggerType"];
            let splittedValue = triggerValue.split("&&");
            triggerFlowsValue = splittedValue[1];
          }
        }
      }
    }

    let entitylistObj = {};

    if (
      this.props.entityList &&
      this.props.entityList != null &&
      this.props.entityList != "{}" &&
      !(this.props.entityList.indexOf("Failure") > -1)
    ) {
      entitylistObj = JSON.parse(this.props.entityList);
    }

    let fileData = [];
    if (this.props.fileData) {
      fileData = JSON.parse(this.props.fileData);
      fileData = fileData.preValidationList;
    }

    let optionValues = fileData.map(function (item, value) {
      return (
        <option
          key={value}
          value={Object.values(item)[0]}
          name={Object.keys(item)[0]}
        >
          {Object.keys(item)[0]}
        </option>
      );
    });
    let validationOptionValues = [{ value: "", displayValue: "Select" }];
    fileData.map((item) =>
      validationOptionValues.push({
        value: Object.values(item)[0],
        displayValue: Object.keys(item)[0],
      })
    );
    validationOptionValues.push({ value: "custom", displayValue: "Custom" });
    let BotProfileStructureArr = [];
    if (this.props.getStructure) {
      BotProfileStructureArr = JSON.parse(this.props.getStructure);
    }

    let entityIndex = "";
    if (
      Object.keys(entitylistObj).length != 0 &&
      Object.keys(entitylistObj.Entities).length > 0 &&
      !this.state.activeEntity &&
      this.state.setEntityShow
    ) {
      if (!this.state.addIndex) {
        entityIndex = Object.keys(entitylistObj.Entities).length + 1;
      } else {
        entityIndex = JSON.parse(this.state.indexValue);
      }
    } else {
      entityIndex = JSON.parse(this.state.indexValue);
    }
    if (this.state.activeEntity && this.state.setEntityShow) {
      entityIndex = JSON.parse(this.state.indexValue);
    }
    let entityName = {};
    let submitEntity = "";
    if (entityIndex == "1" || entityIndex == 1) {
      entityName = this.state.entity1;
      submitEntity = this.state.submitEntity1;
    } else if (entityIndex == "2" || entityIndex == 2) {
      entityName = this.state.entity2;
      submitEntity = this.state.submitEntity2;
    } else if (entityIndex == "3") {
      entityName = this.state.entity3;
      submitEntity = this.state.submitEntity3;
    } else if (entityIndex == "4") {
      entityName = this.state.entity4;
      submitEntity = this.state.submitEntity4;
    } else if (entityIndex == "5") {
      entityName = this.state.entity5;
      submitEntity = this.state.submitEntity5;
    }

    let entityList = [];
    let listValue = 1;

    if (
      Object.keys(entitylistObj).length != 0 &&
      Object.keys(entitylistObj.Entities).length < 5 &&
      !this.state.activeEntity &&
      this.state.setEntityShow
    ) {
      if (!this.state.addIndex) {
        this.state.indexValue = Object.keys(entitylistObj.Entities).length + 1;
      }

      listValue = Object.keys(entitylistObj.Entities).length + 1;
    }
    if (this.state.activeEntity && this.state.setEntityShow) {
      listValue = 1;
    }
    let indexValue = this.state.indexValue;
    for (let i = listValue; i <= this.state.indexValue; i++) {
      let entity = {};
      if (i == "1") {
        entity = this.state.entity1;
      } else if (i == "2") {
        entity = this.state.entity2;
      } else if (i == "3") {
        entity = this.state.entity3;
      } else if (i == "4") {
        entity = this.state.entity4;
      } else if (i == "5") {
        entity = this.state.entity5;
      }
      entityList.push(
        <a
          key={i}
          className={[
            classes.topSpace,
            classes.entityButton,
            classes.addBtn,
          ].join(" ")}
        >
          <span>{i}</span>
          <input
            disabled={this.state.activeEntity ? "disabled" : ""}
            type="text"
            name="entityName"
            ref={(ip) => (this.myInp = ip)}
            value={entity.entityName}
            onChange={
              !this.state.activeEntity
                ? (e) => this.handleEntities(e, i)
                : false
            }
            placeholder="Entity"
          />
          {!this.state.activeEntity && (
            <Image
              onClick={() => {
                this.myInp.focus();
              }}
              src="./Icons/EditBlue.svg"
            />
          )}
        </a>
      );
    }
    let addOptionList = entityName.addOptionList.map(
      function (item, value) {
        return (
          <BadgeComponent
            key={value}
            label={item}
            clicked={() => this.deleteAddOptions(item, indexValue)}
          />
        );
      }.bind(this)
    );

    filteredArrayList = entityName.addOptionList.some((item)=>item.toLowerCase().includes(entityName.defaultValue.toLowerCase()))


    let addEntity = (
      <>
        {!this.state.activeEntity && this.state.indexValue < 5 && (
          <Image
            onClick={(e) => this.addInputIndex(e)}
            className={submitEntity ? " " : classes.disableEvent}
            src="./Icons/AddFlow.svg"
          />
        )}
      </>
    );

    let loadingImportClusterImg = null;
    if (this.state.importClusterLoading) {
      loadingImportClusterImg = <Spinners />;
    } else {
      loadingImportClusterImg = null;
    }
    let loadingImg = null;
    if (this.props.loading) {
      loadingImg = <Spinners />;
    } else {
      loadingImg = null;
    }

    let translationProgressLoading = null;
    if (this.props.translationinprogress) {
      translationProgressLoading = <Spinners />;
    } else {
      translationProgressLoading = null;
    }
    let loadingConnectDataImport = null;
    if (this.props.connectDataImportLoading) {
      loadingConnectDataImport = <Spinners />;
    } else {
      loadingConnectDataImport = null;
    }

    let loadingClusterData = null;
    if (this.props.clusterDataLoading) {
      loadingClusterData = <Spinners customClass={classes.loaderClusterData} />;
    } else {
      loadingClusterData = null;
    }

    let scriptLoadingImg = null;
    if (this.props.scriptLoading) {
      scriptLoadingImg = <Spinners />;
    } else {
      scriptLoadingImg = null;
    }
    let entityLoadingImg = null;
    if (this.props.entityLoading) {
      entityLoadingImg = <Spinners />;
    } else {
      entityLoadingImg = null;
    }

    let scriptContent = null;
    if (this.state.scripttouched) {
      scriptContent = this.state.currentAceValue;
    } else {
      scriptContent = this.state.scriptValue
        ? this.state.scriptValue
        : botProfileFile;
    }

    const intentformElementsArray = [];
    for (let key in this.state.AddIntentcontrols) {
      let labelName = "";
      if (key === "intentName") {
        labelName = (
          <label className={classes.ProjectTabContentLabel}>
            Intent Name <b>*</b>
          </label>
        );
      } else if (key === "intentDesc") {
        labelName = (
          <>
            <label className={classes.ProjectTabContentLabel}>
              Intent Description{"*"}
            </label>
            <LightTooltip
              arrow
              title="Description should be short and crisp, Description is shown to the user when user query is ambiguous."
              placement="top-start"
            >
              <img
                src="./images/info_icon.png"
                style={{ position: "relative", top: "13px", left: "6px" }}
              />
            </LightTooltip>
          </>
        );
      }

      intentformElementsArray.push({
        id: key,
        config: this.state.AddIntentcontrols[key],
        labelName: labelName,
      });
    }

    const patternformElementsArray = [];
    for (let key in this.state.AddPatterncontrols) {
      patternformElementsArray.push({
        id: key,
        config: this.state.AddPatterncontrols[key],
        labelName: "",
      });
    }

    let addIntentform = intentformElementsArray.map((formElement) => (
      <Col md={6} key={formElement.id}>
        <InputWithLabelComponent
          type="text"
          placeholder=""
          name={formElement.id}
          label={formElement.labelName}
          value={formElement.config.value}
          readOnly={formElement.id === "intentName"?true:false}
          onChange={(event) => this.inputChangedHandler(event, formElement.id)}
          required={true}
          isInvalid={
            formElement.id === "intentName"
              ? this.state.emptyIntentNameMsg
              : null
          }
          error={
            formElement.id === "intentName"
              ? this.state.emptyIntentNameMsg
              : null
          }
        />
      </Col>
      
    ));

    entitiesTraindata =
      Object.entries(entitylistObj).length != 0 &&
      Object.keys(entitylistObj.Entities).map((name, key) => {
        let bgcolor = this.state.entityColors[name];

        return (
          <MenuItem
            key={key}
            value={entitylistObj.Entities[name]["Type"]}
            onClick={(event) => this.addEntityTag(event, bgcolor)}
          >
            <div
              className={classes.squareBox}
              style={{ backgroundColor: bgcolor }}
            ></div>
            {entitylistObj.Entities[name]["Type"]}
           </MenuItem>
        );
      });

    let addPatternform = this.state.rows.map((item, idx) => {
      let data = "";
      let bgcolor = this.state.entityColors;
      let anchor = `<a class=${classes.removeTag} onclick="var e = this; return e.parentNode.outerHTML = e.parentNode.outerText.slice(0, -1)+' ';">x</a>`;
      if (this.state.rows[idx].name) {
        data = this.state.rows[idx].name
          .replaceAll("<START", "<span ")
          .replaceAll(
            ":one> ",
            'style="background-color: ' + bgcolor["one"] + ';">'
          )
          .replaceAll(
            ":two> ",
            'style="background-color: ' + bgcolor["two"] + ';">'
          )
          .replaceAll(
            ":three> ",
            'style="background-color: ' + bgcolor["three"] + ';">'
          )
          .replaceAll(
            ":four> ",
            'style="background-color: ' + bgcolor["four"] + ';">'
          )
          .replaceAll(
            ":five> ",
            'style="background-color: ' + bgcolor["five"] + ';">'
          )
          .replaceAll(" <END> ", anchor + "</span> ");
      }
      return (
        <div id={"patterns" + idx} class={classes.patterns} key={idx}>
          <div className="input-group">
          <span className={classes.phraseIcon}>
              <Image
                src={item?.type === 'AI'?'./Icons/AIPhrase.svg':'./Icons/ManualPhrase.svg'}
                className={item?.type === 'AI'? classes.aiImg : ''}
              />
            </span>
            <ContentEditable
              className={[classes.divRef + " projectPat_" + idx,
                          item?.type === 'AI'?classes.aiPhrase:""].join(" ")}
              onMouseUp={(event) => this.onClickContentEditable(event, idx)}
              html={data}
              disabled={this.state.currentBotSelected=='Intentlist'||this.state.currentBotSelected=='unansweredbot'?true:this.props.AIStandard.RW?false:true}
              onChange={this.handlePatterns(idx)}
              onKeyDown={this.handleKeyDown}
              onClick={()=>this.updatePhrase(idx)}
            />

            <Menu
              id="lock-menu"
             anchorEl={this.state.anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom" }}
              transformOrigin={{ vertical: "top" }}
              open={Boolean(this.state.anchorEl)}
              onClose={this.handleMenuClose}
              PaperProps={{
                style: {
                  width: "40ch",
                },
              }}
            >
              <MenuItem>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div> Select entity type..</div>
                  <a
                    style={{ alignSelf: "end" }}
                    className={classes.underlineTextButton}
                    onClick={() => this.showEntities("", "")}
                  >
                    <Image src="./Icons/AddBlue.svg" /> Create New
                  </a>
                </div>
              </MenuItem>
              <Divider sx={{ my: 0.5 }} />
              {entitiesTraindata}
            </Menu>
            <span className={classes.deletePatIcon}>
              <Image
                onClick={this.handleRemoveSpecificRow(idx)}
                src="./Icons/DeleteBlack.svg"
              />
            </span>
          </div>
        </div>
      );
    });

    let addReLearnPatternform = this.state.reLearnRows.map((item, idx) => {
      let data = "";
      let bgcolor = this.state.entityColors;
      let anchor = `<a class=${classes.removeTag} onclick="var e = this; return e.parentNode.outerHTML = e.parentNode.outerText.slice(0, -1)+' ';">x</a>`;
      if (this.state.reLearnRows[idx].name) {
        data = this.state.reLearnRows[idx].name
          .replaceAll("<START", "<span ")
          .replaceAll(
            ":one> ",
            'style="background-color: ' + bgcolor["one"] + ';">'
          )
          .replaceAll(
            ":two> ",
            'style="background-color: ' + bgcolor["two"] + ';">'
          )
          .replaceAll(
            ":three> ",
            'style="background-color: ' + bgcolor["three"] + ';">'
          )
          .replaceAll(
            ":four> ",
            'style="background-color: ' + bgcolor["four"] + ';">'
          )
          .replaceAll(
            ":five> ",
            'style="background-color: ' + bgcolor["five"] + ';">'
          )
          .replaceAll(" <END> ", anchor + "</span> ");
      }

      return (
        <div id={classes.patterns} key={idx}>
          <div className="input-group">
            <ContentEditable
              className={classes.divRefdisabled}
              html={data}
              disabled={true}
              contenteditable-disable={true}
            />
            {data.trim() && (
              <span className={classes.deleteIconAlignment}>
                <Image
                  onClick={this.handleRemoveReLearnSpecificRow(idx)}
                  src="./Icons/DeleteBlack.svg"
                />
              </span>
            )}
          </div>
        </div>
      );
    });

    let tabChildContent = null;
    if (this.state.selectedTab === "Manual") {
      tabChildContent = (
        <>
          <div className={[classes.topSpace, classes.paddingSpace].join(" ")}>
            <div id="addPatternform" className={classes.addPatternform}>
              {addPatternform}
            </div>
          </div>
          <div className={classes.BtnAlignment}>
            <ButtonMUI
              variant="contained"
              label="Save"
              className="buttonWidth"
              clicked={this.submitHandler}
              disabled={this.state.disableBtn==true ?true:this.state.descp? true : this.props.AIStandard.RW?false:true }
            />
          </div>
        </>
      ); 
    } else if (this.state.selectedTab === "Auto Training") {
      tabChildContent = (
        <>
          <div className={[classes.topSpace, classes.paddingSpace].join(" ")}>
            <div id="addPatternform" className={classes.addPatternform}>
              {addReLearnPatternform}
            </div>
          </div>
          <div className={classes.BtnAlignment}>
            <ButtonCustom btnType="blueButton" clicked={this.submitHandler}>
              Save
            </ButtonCustom>
          </div>
        </>
      );
    }

    let tabContent = null;
    if (this.state.selected === "Train") {
      tabContent = (
        <>
          <Row className={classes.paddingSpace}>{addIntentform}</Row>
          <Row className={classes.paddingSpace}>
          <Col>
          <div className={classes.phraseRowLeft}>
            <h3 className={classes.phrasesName}>Phrases</h3>
            <Image src="./Icons/plus-icon.svg" onClick={this.handleAddRow} className={classes.addflowIcon}/>
          </div>
          </Col>
          <Col>
          <div className={classes.phraseRowRight}>
            <h3 className={classes.phrasesName}>Generate phrases with</h3>
            <Image src="./Icons/AIBtn.svg" onClick={this.handleAiGen} className={classes.addflowIcon}/>
          </div>
          </Col>
          </Row>
          {tabChildContent}
          <div className={classes.BtnAlignment}>{loadingImg}</div>
        </>
      );
    } else if (this.state.selected === "Edit Flow") {
      tabContent = (
        <>
          <Row className={classes.BtnAlignmentOfEditFlow}>
            <Col md={12}>
              <Col md={7}>
                <span className={classes.titleChanges}>
                  {this.state.currentBotSelected}
                </span>
              </Col>
              <Col md={3} className={classes.saveDelEditFlow}>
                <span className={classes.deleteCustom}>
                  <img
                    className={classes.deleteCustomimg}
                    src="./Icons/DeleteBlack.svg"
                    onClick={this.deleteScriptData}
                  />
                </span>
                <ButtonCustom
                  btnType="blueButton"
                  clicked={this.updateTrigger}
                >
                  Save
                </ButtonCustom>
              </Col>
            </Col>
          </Row>
        
        </>
      );
    } else if (this.state.selected === "Script") {
      tabContent = (
        <>
          <EditorCustom
            names="botEditor"
            refer={(instance) => {
              this.botEditor = instance;
            }}
            changed={(e) => this.onChangeAce(e)}
            value={scriptContent}
          />
          <div className={classes.BtnAlignmentOfScriptTab}>
            {scriptLoadingImg}
          </div>
          <div className={classes.BtnAlignmentOfScriptTab}>
            <ButtonMUI
              variant="contained"
              label="Save"
              disabled={this.state.currentBotSelected=='Intentlist' || this.state.scripttouched ==false?true:this.props.AIStandard.RW?false:true}
              className="buttonWidth"
              clicked={this.saveBotProfile}
            />
          </div>
        </>
      );
    }

    let entityValue =
      Object.entries(entitylistObj).length != 0 &&
      Object.keys(entitylistObj.Entities).map((name, key) => {
        let bgcolor = this.state.entityColors[name];
        return (
          <li
            className={classes.badge_listing}
            key={key}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <button
              className={[
                classes.rightButton,
                classes["entityColor" + key],
              ].join(" ")}
            >
              <span className={classes.entityName} style={{ color: bgcolor }}>
                {entitylistObj.Entities[name]["Type"]}
              </span>
            </button>
            <Dropdown1 className={classes.flowListsDropdown}>
              <Dropdown1.Toggle>
                <img
                  className={classes.entityMenu}
                  src="./Icons/ActionMenu.svg"
                />
              </Dropdown1.Toggle>
              <Dropdown1.Menu className={`${classes.dropMenu} dots-menu`}>
                <Dropdown1.Item
                  className={this.props.AIStandard.RW ? `${classes.sideDropdown} ` : `${classes.sideDropdown} click-access`}
                  onClick={() =>
                    this.showEntities(
                      entitylistObj,
                      entitylistObj.Entities[name]["Type"]
                    )
                  }
                >
                  EDIT
                </Dropdown1.Item>
                <Dropdown1.Item
                  className={this.props.AIStandard.RW ? `${classes.sideDropdown}` : `${classes.sideDropdown} click-access`}
                  onClick={() => {
                    this.deleteEntityModal(name);
                  }}
                >
                  DELETE
                </Dropdown1.Item>
              </Dropdown1.Menu>
            </Dropdown1>
          </li>
        );
      });

    const addFlowElementsArray = [];
    for (let key in this.state.AddFlowControls) {
      let labelName = "";
      if (key === "botname") {
        labelName = (
          <label>
            Flow Name <b>*</b>
          </label>
        );
      } else if (key === "botdescription") {
        labelName = <label>Description</label>;
      } else if (key === "botEntityInputs") {
        labelName = <label>Entities</label>;
      }
      addFlowElementsArray.push({
        id: key,
        config: this.state.AddFlowControls[key],
        labelName: labelName,
      });
    }

    let addFlowform = addFlowElementsArray.map((formElement) => (
      <Row key={formElement.id}>
        <Col md={12}>
          <div className="inputContainer">
            {formElement.labelName}
            <Input
              key={formElement.id}
              customclassName={formElement.config.className}
              elementType={formElement.config.elementType}
              elementConfig={formElement.config.elementConfig}
              value={formElement.config.value}
              invalid={!formElement.config.valid}
              shouldValidate={formElement.config.validation}
              touched={formElement.config.touched}
              changed={(event) =>
                this.inputFlowChangedHandler(event, formElement.id)
              }
              defaultFocus={formElement.config.defaultFocus}
            />
          </div>
          {formElement.id === "botname" ? this.state.emptybotnameMsg : null}
        </Col>
      </Row>
    ));
    const triggerTypeArray = [];
    for (let key in this.state.TriggerTypeControls) {
      let labelName = "";
      if (key === "triggerTypeInputs") {
        labelName = <label>Trigger Type</label>;
      }
      triggerTypeArray.push({
        id: key,
        config: this.state.TriggerTypeControls[key],
        labelName: labelName,
      });
    }

    let triggerTypeform = triggerTypeArray.map((formElement) => (
      <Row key={formElement.id}>
        <Col md={12}>
          <div className="inputContainer">
            {formElement.labelName}
            <Input
              key={formElement.id}
              customclassName={formElement.config.className}
              elementType={formElement.config.elementType}
              elementConfig={formElement.config.elementConfig}
              value={formElement.config.value}
              invalid={!formElement.config.valid}
              shouldValidate={formElement.config.validation}
              touched={formElement.config.touched}
              changed={(event) =>
                this.inputTriggerTypeChangedHandler(event, formElement.id)
              }
            />
          </div>
          {formElement.id === "triggerTypeInputs"
            ? this.state.emptyTriggerTypeMsg
            : null}
        </Col>
      </Row>
    ));
    let projectSideView = (
      <ProjectViewSideBar
        permissionsAIStandard={this.props.AIStandard}
        ShowFlowModal={this.setFlowModalShowHide}
        botList={this.props.botList}
        botdeleted={botdeleted}
        currentBotEvent={this.flowSelection}
        isFlowSelectedFlow={this.state.isFlowSelectedFlow}
        isSynonymsNavigation={this.state.isSynonymsNavigation}
        isFaqNavigation={this.state.isFaqNavigation}
        isOtherDocsNavigation={this.state. isOtherDocsNavigation}
        editbot={botselected}
        currentBotSelected={this.state.currentBotSelected}
        scriptSelected={this.state.scriptSelected}
        BotProfileStructureArr={BotProfileStructureArr}
        currentFileSelected={this.state.currentFileSelected}
        openJSFile={this.openJSFile}
        selectKnowledgeIntent={this.checkSelectKnowledgeIntent}
        isKnowledgeContentSelected={this.state.isKnowledgeContentSelected}
        deleteBot={this.deleteBotModal}
        exportBot={this.exportBotModal}
        ShowImportModal={this.setImportFlowModal}
        exportProject={this.exportProjectModal}
        runBot={this.runBot}
        ShowTriggerTypeModal={this.ShowTriggerTypeModal}
        ShowCreateProfileModal={this.ShowCreateProfileModal}
        ShowConfigureModal={this.ShowConfigureModal}
        initChatBotModal={this.initChatBotModal}
        OpenConnectDataModal={this.OpenConnectDataModal}
        createProjectAccuracyModal={this.createProjectAccuracyModal}
        setting={this.props.setting}
        translateProject={(langarray) => this.translateProject(langarray)}
        projectTranslation={this.props.projectTranslation}
        handleTranslateBtn={(langarray) => this.handleTranslateBtn(langarray)}
        projectList={this.props.projectlist}
        deleteProjectModel={this.deleteProjectModel}
        deleteProfile={this.deleteProfileModel}
        selectedKnowledge={this.state.selectedKnowledge}
        mappedData={this.props.mappedData}
        configurationInputs={this.props.configurationInputs}
        selectBotFromMenu={this.selectBotFromMenu}
        showCuxFlowMenu={this.showCuxFlowMenu}
        selectFlowFromMenu={this.selectFlowFromMenu}
        botOrgList={this.props.config.BOT_ORGS}
        cuxList={this.props.cuxBotList}
        config={this.props.config}
        deleteCuxBot={this.deleteCuxBotModal}
        selectedCUXBotId={this.props.cuxBotId}
        isCUXBotSelected={this.state.isCUXBotSelected}
        permissions={this.props.permissions}
        otherDocsPermission={this.props.otherDocsPermission}
        exportCuxBot={this.exportCuxBotHandler}
      ></ProjectViewSideBar>
    );

    let entityBox = "";
    if (
      Object.keys(entitylistObj).length == 0 ||
      Object.keys(entitylistObj.Entities).length < 5
    ) {
      entityBox = (
        <Image src="./Icons/plus-icon.svg" onClick={() => this.showEntities("", "")} className={classes.AddEntityButton} />
      );
    }
    let rightSidePanel = null;
    rightSidePanel = (
      <>
        {this.state.ShowRightPanel && (
          <>
            <Col lg={2} className={classes.rightSidePanel}>
              <div className={classes.entity_panel}>
                <h4 className={classes.entity_header}>Entities</h4>
                {entityBox}
              </div>
              <div lg={2} className={classes.rightSidePanel1}>
                <ul className={classes.projectSideBar}>
                  {entityLoadingImg}
                  {entityValue}
                </ul>
              </div>
            </Col>
          </>
        )}
      </>
    );

    return (
      <>

        {(this.props.AIStandard.RW || this.props.AIAdmin.RW) && <FAQDrawerContainer
          title="FAQs Details"
          showFaqDocumentDrawer={this.state.showFaqDocumentDrawer}
          scriptContent={scriptContent}
          scriptLoadingImg={scriptLoadingImg}
          ongetProfileStructure={(data, url) =>
            this.props.ongetProfileStructure(data, url)
          }
          openJSFile={(data, flag) => this.openJSFile(data, flag)}
          config={this.props.config}
          handleClose={(data, redirection) =>
            this.handleClose(data, redirection)
          }
          setValidationMessage={(type, value) =>
            this.setValidationMessage(type, value)
          }
          getCurrentDate={() => this.getCurrentDate()}
          pageload={this.state.refreshKnowledgeIntent}
        ></FAQDrawerContainer>}
        
        <OtherDocumentDrawer
          title="Document Details"
          show={this.state.showOtherDocumentDrawer}
          onHide={() => this.handleClose("otherDocument")}
          handleClose={() => this.handleClose("otherDocument", true)}
        >
          <ManageFileForms
            onHide={() => this.handleClose("otherDocument", true)}
            config={this.props.config}
            isFaq={false}
            handleClose={() => this.handleClose("otherDocument", true)}
          />
        </OtherDocumentDrawer>

        {this.props.showProjectHeader ? <div>
          <ProjectHeaderPage
            permissionsAIStandard={this.props.AIStandard}
            permissionsProjectM={this.props.projectM}
            permissionsAIAdmin={this.props.AIAdmin}
            openFaqDocumentDrawer={this.openFaqDocumentDrawer}
            openSynonymsDrawer={this.openSynonymsDrawer}
            openOtherDocumentDrawer={this.openOtherDocumentDrawer}
            ShowFlowModal={this.setFlowModalShowHide}
            setImportFlowModal={this.setImportFlowModal}
            getprojDesp={this.state.projectDescription}
            deleteProjectModel={this.deleteProjectModel}
            exportProject={this.exportProjectModal}
            showBotModal={this.showBotModal}
            initChatBotModal={this.initChatBotModal}
            showTranslateModel={this.showTranslateModel}
            currentLangauge={currentLangauge}
            availableLangList={availableLangList}
            isCuxOrg={this.state.isCuxOrg}
            permissions={this.props.permissions}
            otherDocsPermission={this.props.otherDocsPermission}
            config={this.props.config}
            setImportCuxBotModal={this.setImportCuxBotModal}
            showProjectHeader={this.props.showProjectHeader}
          />
        </div>:!this.state.isCUXBotSelected?<Row style={{width:"101vw",backgroundColor:"#F2F1F1",padding:"40px",paddingTop:"0px"}}>
                {/* <KeyboardBackspaceIcon onClick={()=>this.backtoIntentList()}></KeyboardBackspaceIcon> */}
                <NavbarCustom 
                    className="crawlingProjectName"
                    dataList={this.state.intentsList}
                    botList={this.props.botList}
                    projectList={this.props.projectlist}
                    addImportProjectIcon={false}
                    setSearchTerm={(item)=>this.setSearchTerm(item)}
                    popperShow={this.state.popperShow}
                    popperShowFn={()=>this.popperShowFn()}
                    searchTerm={this.state.searchT}
                    projectclick={this.flowSelection}
                    projectname={this.state.intentname} 
                    rightPopUp={true} 
                    backtoIntentList={()=>this.backtoIntentList()}
                    projectSelection={true}>
              </NavbarCustom>
              <span className="IntentHeader"></span>
              </Row>:
              <CustomNavBar selectedItem={this.state.selectedCUXBot} modalTitle={'Select Flows'} 
              rightPopUp={true} 
              popperShowFn={()=>this.popperShowFn()} 
              popperShow={this.state.popperShow}
              dataList={this.props.cuxBotList} 
              onListItemClick={this.selectBotFromDropdown}
              backArrowAction={this.backToFlowList}
              returnValues={['flowid','flowname']}
              uniqueKey={'flowid'}
              displayValue={'flowname'}
              title={'Flows'}
              />
              }
        <Container className={classes.ProjectViewContentPanel + " no-gutters"}>
          <Row>
            { this.props.showProjectHeader && (
              <Col lg={2} className={classes.leftProjectViewPanel}>
                {projectSideView}
              </Col>
            )}
            {this.state.isCUXBotSelected && this.props.showProjectHeader && (
              <Col lg="10">
                <CDFlowList config={this.props.config} showBotModal={this.showBotModal} selectFlowFromList={this.selectBotFromMenu} 
                deleteCuxBot={this.deleteCuxBotModal} exportCuxBot={this.exportCuxBotHandler}/>
              </Col>
            )}
            {this.state.isCUXBotSelected && !this.props.showProjectHeader && (
               <>
               
                <Col lg="12">
                  <TabCustom
                    tabs={["Designer", "Trigger"]}
                    selected={this.state.cuxBotTabSelected}
                    setSelected={this.checkCuxBotTab}
                    tabCustomClass={classes.cuxtabCustomClass}
                  >
                      <Container
                        className={[
                          classes.cuxDndArea,
                          classes.ProjectTabContent,
                        ].join(" ")}
                      > 
                         {this.state.cuxBotTabSelected === 'Designer' &&
                         <DndFlow config={this.props.config} botid={this.state.selectedCUXBotId} 
                            botname={this.state.selectedCUXBot} />
                         }
                         {this.state.cuxBotTabSelected === 'Trigger' &&
                         <CDTrigger config={this.props.config} flowid={this.state.selectedCUXBotId} 
                            botname={this.state.selectedCUXBot} intentList={this.props.botList}
                            entityList={this.props.entityList}/>
                        }
                        <div>{loadingImg}</div>
                      </Container>
                    </TabCustom>
                </Col>
              </>
            )}
            {!this.state.isCUXBotSelected &&
              this.state.isKnowledgeContentSelected && (
                <>
                  {this.state.selectedKnowledge == "Knowledge" && (
                    <KnowledgeTable
                    openFaqDocumentDrawer={this.openFaqDocumentDrawer}
                    scriptContent={scriptContent}
                      scriptLoadingImg={scriptLoadingImg}
                      ongetProfileStructure={(data, url) =>
                        this.props.ongetProfileStructure(data, url)
                      }
                      openJSFile={(data, flag) => this.openJSFile(data, flag)}
                      config={this.props.config}
                      handleClose={(data) => this.handleClose(data)}
                      setValidationMessage={(type, value) =>
                        this.setValidationMessage(type, value)
                      }
                      getCurrentDate={() => this.getCurrentDate()}
                      pageload={this.state.refreshKnowledgeIntent}
                    ></KnowledgeTable>
                  )}
                  {this.state.selectedKnowledge == "OtherDocs" && (
                    <div className="Knowledge" style={{ width: "83%" }}>
                      <ManageFileData
                        config={this.props.config}
                        isFaq={false}
                        openOtherDocumentDrawer={this.openOtherDocumentDrawer}
                        otherDocsPermission={this.props.otherDocsPermission}
                        ></ManageFileData>
                    </div>
                  )}
                  {this.state.selectedKnowledge == "Multimedia" && (
                    <div className="Knowledge" style={{ width: "83%" }}>
                      <ManageMultimediaData
                        config={this.props.config}
                        isFaq={false}
                        openOtherDocumentDrawer={this.openOtherDocumentDrawer}
                        otherDocsPermission={this.props.otherDocsPermission}
                        ></ManageMultimediaData>
                    </div>
                  )}
                  {this.state.selectedKnowledge == "Intents" && (
                    <div className="Knowledge" style={{ width: "83%" }}>
                      <IntentAnalysis
                        config={this.props.config}
                        isFaq={false}
                        botList={this.props.botList}
                        projectList={this.props.projectlist}
                        botdeleted={botdeleted}
                        currentBotEvent={(isbotselected, item, botvalue, newadded,intentList1,searchT,popperShow,intentname)=>this.flowSelection(isbotselected, item, botvalue, newadded,intentList1,searchT,popperShow,intentname)}
                        deleteBot={this.deleteBotModal}
                        exportBot={this.exportBotModal}
                        cuxList={this.props.cuxBotList}
                        openOtherDocumentDrawer={this.openOtherDocumentDrawer}
                        otherDocsPermission={this.props.otherDocsPermission}
                        ></IntentAnalysis>
                    </div>
                  )}
                {this.state.selectedKnowledge == "Synonyms & Acronyms" && (
                  <SynonymsDataContainer
                    openSynonymsDrawer={this.openSynonymsDrawer}
                    scriptContent={scriptContent}
                    scriptLoadingImg={scriptLoadingImg}
                    ongetProfileStructure={(data, url) =>
                      this.props.ongetProfileStructure(data, url)
                    }
                    openJSFile={(data, flag) => this.openJSFile(data, flag)}
                    config={this.props.config}
                    handleClose={(data) => this.handleClose(data)}
                    setValidationMessage={(type, value) =>
                      this.setValidationMessage(type, value)
                    }
                    getCurrentDate={() => this.getCurrentDate()}
                    pageload={this.state.refreshKnowledgeIntent}
                  />
                )}
                </>
              )}
            {/* :  */}
            {!this.state.isCUXBotSelected && this.state.isFlowSelected && (
              <>
                {!this.props.showProjectHeader && this.state.selected == "Script" && (
                  // <Col lg={this.state.KnowledgeMacroExpand ? 10 : 10}>
                  <Col lg={10}>
                    {/* <div className={classes.tabcontentTopSpace}>
                      <h2 className={classes.botactive}>
                        {this.state.currentBotSelected} flow
                      </h2>
                    </div> */}
                    <TabCustom
                      tabs={
                        this.state.isCuxOrg ? ["Train"] : ["Train", "Script"]
                      }
                      selected={this.state.selected}
                      setSelected={this.checkTab}
                      tabCustomClass={classes.tabCustomClass}
                    >
                      <Container
                        className={[
                          classes.topSpace,
                          classes.ProjectTabContent,
                        ].join(" ")}
                      >
                        {tabContent}
                      </Container>
                    </TabCustom>
                  </Col>
                )}
                {!this.props.showProjectHeader && this.state.selected == "Edit Flow" && (
                  // <Col lg={this.state.EditFlowExpand ? 10 : 10}>
                  <Col lg={10}>
                    <TabCustom
                      tabs={["Train", "Edit Flow", "Script"]}
                      selected={this.state.selected}
                      setSelected={this.checkTab}
                    >
                      <Container
                        className={[
                          classes.topSpace,
                          classes.ProjectTabContent,
                        ].join(" ")}
                      >
                        {tabContent}
                      </Container>
                    </TabCustom>
                  </Col>
                )}
                {!this.props.showProjectHeader && this.state.selected == "Train" && (
                  // <Col lg={this.state.ShowRightPanel ? 10 : 10}>
                  <Col lg={10}>
                    {/* <div className={classes.tabcontentTopSpace}>
                      <h2 className={classes.botactive}>
                        {this.state.currentBotSelected} flow
                      </h2>
                    </div> */}
                    <TabCustom
                      tabs={
                        this.state.isCuxOrg ? ["Train"] : ["Train", "Script"]
                      }
                      tabCustomClass={classes.tabCustomClass}
                      selected={this.state.selected}
                      setSelected={this.checkTab}
                    >
                      <Container
                        className={[
                          classes.topSpace,
                          classes.ProjectTabContent,
                        ].join(" ")}
                      >
                        {tabContent}
                      </Container>
                    </TabCustom>
                  </Col>
                )}
              </>
            )}
            {!this.state.isCUXBotSelected && !this.props.showProjectHeader && rightSidePanel}
            {this.state.selected === "Script" &&
              this.state.isFlowSelected &&
              this.state.KnowledgeMacroExpand === false && (
                <Col
                  md={1}
                  className={classes.knowledgeIntentrightSidePanel}
                  style={{
                    maxWidth: "1%",
                    marginLeft: "5.7%",
                  }}
                >
                  <div className={classes.knowledgeIntenticons}>
                    <ul>
                      <li>
                        <span
                          className={classes.macroshowhideBtn}
                          onClick={() => this.handleShowMacroDiv(true)}
                        >
                          <Image src="./Icons/ExpandGrey.svg" />
                        </span>
                      </li>
                    </ul>
                  </div>
                </Col>
              )}

            {this.state.selected === "Script" &&
              this.state.KnowledgeMacroExpand && (
                <Col lg={2} className={classes.knowledgeIntentrightSidePanel}>
                  {this.state.KnowledgeMacroSelected === "Custom Macros" ? (
                    <>
                      <div className={classes.macrosTopDiv}>
                        <h4 className={classes.macrosName}>Macros</h4>
                        <Image
                          src="./Icons/AddFlow.svg"
                          onClick={() => this.ShowCustomMacroModal("")}
                        />
                      </div>
                      <Container
                        className={[
                          classes.topSpace,
                          classes.ProjectTabContentKnowledge,
                        ].join(" ")}
                      >
                        <ul className={classes.projectSideBarMacros}>
                          {this.props.macrolist &&
                            this.props.macrolist.map((item, idx) => (
                              <li>
                                <a
                                  onClick={() =>
                                    this.ShowCustomMacroModal(item)
                                  }
                                >
                                  {item}
                                </a>
                              </li>
                            ))}
                          {this.props.macrolist &&
                            this.props.macrolist.length === 0 && (
                              <li>No macro available</li>
                            )}
                        </ul>
                      </Container>
                    </>
                  ) : null}
                </Col>
              )}
          </Row>
        </Container>
        {translationProgressLoading}
        <AddSynonymsAndAncronymsDrawer
          show={this.state.showSynonyms}
          config={this.props.config}
          onHide={() => this.handleClose("Synonyms & Acronyms")}
        >
        </AddSynonymsAndAncronymsDrawer>
        <AddEntityDrawer
          modalclass="EntityModal"
          show={this.state.setEntityShow}
          onHide={() => this.handleClose("entity")}
          title="Entities Details"
          submit={(e) =>
            this.saveEntity(e, indexValue, this.state.activeEntity)
          }
        >
          <EntitiesComponent
            optionValues={optionValues}
            entityList={entityList}
            addEntity={addEntity}
            Rememberchanged={(e) => this.handleRemember(e, indexValue)}
            Entitychanged={(e) => this.handleToggle(e, indexValue)}
            changed={(e) => this.handleEntities(e, indexValue)}
            buttonClicked={(e) => this.saveaddOption(e, indexValue)}
            validation={entityName.validation}
            validationName={entityName.validationName}
            invalidEntity={entityName.invalidEntity}
            question={entityName.question}
            defaultValue={entityName.defaultValue}
            optionType={entityName.optionType}
            display={entityName.display}
            addOption={entityName.addOption}
            entityType={entityName.entityType}
            remember={entityName.remember}
            entityName={entityName.entityName}
            emptyQuestionMsg={this.state.emptyQuestionMsg}
            emptyDefaultMsg={this.state.emptyDefaultMsg}
            emptyValidationTypeMsg={this.state.emptyValidationTypeMsg}
            emptyValidationValueMsg={this.state.emptyValidationValueMsg}
            emptyValidationMsg={this.state.emptyValidationMsg}
            emptyValidationNameMsg={this.state.emptyValidationNameMsg}
            emptydisplayMsg={this.state.emptydisplayMsg}
            emptyaddoptionMsg={this.state.emptyaddoptionMsg}
            validationText={entityName.validationText}
            addOptionList={addOptionList}
            addOptionValue={entityName.addOptionValue}
            validateOptionMsg={this.state.validateOptionMsg}
            validationOptionValues={validationOptionValues}
            AddOptionListArrAy = {filteredArrayList}
            onEditEntityDisableSave = {this.state.onEditEntityDisableSave}
            onEditAddBtnDisable = {this.state.onEditAddBtnDisable}
          ></EntitiesComponent>
          <br/>
          <div>{this.state.displayError==true?<ValidationMsg>Default value should match with one of the options provided.</ValidationMsg>:null}</div>
          <ButtonComponent
            disabled={
              entityName.addOption ? !(
                entityName.question &&
                entityName.invalidEntity &&
                entityName.entityName &&
                entityName.validationName &&
                entityName.addOptionList.length > 0 &&
                entityName.display !=='-1'&&
                this.state.onEditEntityDisableSave
              ) : !(
                entityName.question &&
                entityName.invalidEntity &&
                entityName.entityName &&
                entityName.validationName &&
                this.state.onEditEntityDisableSave&&
                !this.state.savebtnDisable
              )
            }
            variant="contained"
            className="buttonWidth"
            label={"Save"}
            clicked={(e) =>
              this.saveEntity(e, indexValue, this.state.activeEntity)
            }
          />
        </AddEntityDrawer>
        <ModalPopUp
          modalclass="EntityModal"
          // show={this.state.setEntityShow}
          onHide={() => this.handleClose("entity")}
          title="Add Entities"
          submit={(e) =>
            this.saveEntity(e, indexValue, this.state.activeEntity)
          }
        >
          <EntityCustom
            optionValues={optionValues}
            entityList={entityList}
            addEntity={addEntity}
            Rememberchanged={(e) => this.handleRemember(e, indexValue)}
            Entitychanged={(e) => this.handleToggle(e, indexValue)}
            changed={(e) => this.handleEntities(e, indexValue)}
            buttonClicked={(e) => this.saveaddOption(e, indexValue)}
            validation={entityName.validation}
            validationName={entityName.validationName}
            invalidEntity={entityName.invalidEntity}
            question={entityName.question}
            defaultValue={entityName.defaultValue}
            optionType={entityName.optionType}
            display={entityName.display}
            addOption={entityName.addOption}
            entityType={entityName.entityType}
            remember={entityName.remember}
            emptyQuestionMsg={this.state.emptyQuestionMsg}
            emptyDefaultMsg={this.state.emptyDefaultMsg}
            emptyValidationTypeMsg={this.state.emptyValidationTypeMsg}
            emptyValidationValueMsg={this.state.emptyValidationValueMsg}
            validationText={entityName.validationText}
            addOptionList={addOptionList}
            addOptionValue={entityName.addOptionValue}
            validateOptionMsg={this.state.validateOptionMsg}
          ></EntityCustom>
          <Row className={classes.ButtonAlign}>
            <ButtonCustom btnType="blueButton">Save</ButtonCustom>
            <ButtonCustom
              btnInput="button"
              btnType="whiteButton"
              clicked={() => this.handleClose("entity")}
            >
              Cancel
            </ButtonCustom>
          </Row>
        </ModalPopUp>
        <AddBotDrawer
          modalclass="AddflowModal"
          show={this.state.isBotModalShow}
          onHide={() => this.handleClose("CuxBotModal")}
          title="Flow Details"
          submit={(e) => this.handlesubmitAddCuxBot(e)}
        >
          <div className="mb-3">
            <InputWithLabelComponent
              type="text"
              placeholder=""
              name="botname"
              label="Flow Name*"
              value={this.state.cuxBotName}
              readOnly={false}
              onChange={(event) =>
                this.inputCuxBotChangedHandler(event.target.value)
              }
              required={true}
              isInvalid={this.state.emptybotnameMsg}
              error={this.state.emptybotnameMsg}
            />
          </div>
          <ButtonComponent
            variant="contained"
            className="buttonWidth"
            label={"Save"}
            disabled={this.state.cuxBotName === '' || this.state.emptybotnameMsg}
            clicked={(e) => this.handlesubmitAddCuxBot(e)}
            btnType="blueButton"
          />
          <br />
        </AddBotDrawer>
        <AddProjectFlowDrawer
          modalclass="AddflowModal"
          show={this.state.isFlowModalShow}
          onHide={() => this.handleClose("FlowModal")}
          title={this.state.isCuxOrg?"Intent Details":"Flow Details"}
          submit={(e) =>
            this.handlesubmitAddFlow(e, indexValue, this.state.activeEntity)
          }
        >
          <div>
            <InputWithLabelComponent
              type="text"
              placeholder=""
              name="botname"
              label={this.state.isCuxOrg?"Intent Name*":"Flow Name*"}
              value={this.state.AddFlowControls.botname.value}
              readOnly={false}
              onChange={(event) =>
                this.inputFlowChangedHandler(event,"botname")
              }
              required={true}
              isInvalid={this.state.emptybotnameMsg}
              error={this.state.emptybotnameMsg}
            />
          </div>
          <LightTooltip
            arrow
            title="Description should be short and crisp, Description is shown to the user when user query is ambiguous."
            placement="top-start"
          >
            <img
              src="./images/info_icon.png"
              style={{ position: "relative", top: "26px", left: "71px" }}
            />
          </LightTooltip>
         
          <TextAreaWithLabelComponent
            label="Description*"
            name="botdescription"
            required={false}
            value={this.state.AddFlowControls.botdescription.value}
            className="text-area"
            placeholder=""
            onChange={(event) =>
              this.inputFlowChangedHandler(event, "botdescription")
            }
          />
        

          <SelectComponent
            name="botEntityInputs"
            label="Entities"
            changed={(event) =>
              this.inputFlowChangedHandler(event, "botEntityInputs")
            }
            required={true}
            value={this.state.AddFlowControls.botEntityInputs.value}
            options={
              this.state.AddFlowControls.botEntityInputs.elementConfig.options
            }
          />
          {this.state.AddFlowControls.botEntityInputs.value > 0 && (
            <>
              <EntitiesComponent
                optionValues={optionValues}
                entityList={entityList}
                addEntity={addEntity}
                Rememberchanged={(e) => this.handleRemember(e, indexValue)}
                Entitychanged={(e) => this.handleToggle(e, indexValue)}
                changed={(e) => this.handleEntities(e, indexValue)}
                buttonClicked={(e) => this.saveaddOption(e, indexValue)}
                validation={entityName.validation}
                validationName={entityName.validationName}
                invalidEntity={entityName.invalidEntity}
                question={entityName.question}
                defaultValue={entityName.defaultValue}
                optionType={entityName.optionType}
                display={entityName.display}
                addOption={entityName.addOption}
                entityType={entityName.entityType}
                remember={entityName.remember}
                entityName={entityName.entityName}
                emptyQuestionMsg={this.state.emptyQuestionMsg}
                emptyDefaultMsg={this.state.emptyDefaultMsg}
                emptyValidationTypeMsg={this.state.emptyValidationTypeMsg}
                emptyValidationValueMsg={this.state.emptyValidationValueMsg}
                emptyValidationMsg={this.state.emptyValidationMsg}
                emptyValidationNameMsg={this.state.emptyValidationNameMsg}
                emptydisplayMsg={this.state.emptydisplayMsg}
                emptyaddoptionMsg={this.state.emptyaddoptionMsg}
                validationText={entityName.validationText}
                addOptionList={addOptionList}
                AddOptionListArrAy = {filteredArrayList}
                addOptionValue={entityName.addOptionValue}
                validateOptionMsg={this.state.validateOptionMsg}
                validationOptionValues={validationOptionValues}
              ></EntitiesComponent>
            </>
          )}
          
          <div className="form-group submit-btn-parent mt-4">
            {this.state.AddFlowControls.botEntityInputs.value > 1 &&
              indexValue < this.state.AddFlowControls.botEntityInputs.value && (
                <ButtonComponent
                disabled={
                  entityName.addOption ? !(
                    this.state.AddFlowControls.botname.value &&
                    this.state.AddFlowControls.botdescription.value &&
                    entityName.question &&
                    entityName.invalidEntity &&
                    entityName.entityName &&
                    entityName.validationName &&
                    entityName.addOptionList.length > 0 &&
                    entityName.display !=='-1'                
                      ) : !(
                    this.state.AddFlowControls.botname.value &&
                    this.state.AddFlowControls.botdescription.value &&
                    entityName.question &&
                    entityName.invalidEntity &&
                    entityName.entityName &&
                    entityName.validationName
                  ) 
                }
                  variant="contained"
                  className="buttonWidth"
                  label={"Next"}
                  clicked={(e) => this.NextEntity(e, indexValue)}
                />
              )}
            {(this.state.AddFlowControls.botEntityInputs.value == 0 ||
              this.state.AddFlowControls.botEntityInputs.value == -1 ||
              indexValue ==
                this.state.AddFlowControls.botEntityInputs.value) && (
              <ButtonComponent
                variant="contained"
                className="buttonWidth"
                label={"Save"}
                disabled={
                  this.state.AddFlowControls.botEntityInputs.value < 1 &&
                  this.state.AddFlowControls.botdescription.value.trim()!="" 
                 
                   ? !(
                    this.state.disableButton
                    ) : entityName.addOption ? !(
                    this.state.AddFlowControls.botname.value &&
                    
                    entityName.invalidEntity &&
                    entityName.entityName &&
                    entityName.question.trim()!="" &&
                    this.state.AddFlowControls.botdescription.value.trim()!=""&&
                    entityName.validationName &&
                    entityName.addOptionList.length > 0 &&
                    entityName.display !=='-1'               
                       ) : !(
                    this.state.AddFlowControls.botname.value &&
                    this.state.AddFlowControls.botdescription.value.trim()!=""&&
                    entityName.question.trim()!="" &&
                    entityName.invalidEntity &&
                    entityName.entityName &&
                    entityName.validationName
                  )
                }
                  clicked={(e) =>
                  this.handlesubmitAddFlow(
                    e,
                    indexValue,
                    this.state.activeEntity
                  )
                }
                btnType="blueButton"
              />
            )}

            {this.state.AddFlowControls.botEntityInputs.value > 0 &&
              indexValue < this.state.AddFlowControls.botEntityInputs.value && (
                <div>
                  <label>
                    Add{" "}
                    {this.state.AddFlowControls.botEntityInputs.value -
                      indexValue}{" "}
                    Entities to save the flow{" "}
                  </label>
                </div>
              )}
            {this.state.successMsg}
          </div>
        </AddProjectFlowDrawer>

        <ModalPopUp
          modalclass="AddflowModal"
          // show={this.state.isFlowModalShow}
          onHide={() => this.handleClose("FlowModal")}
          title="Add Flow"
          submit={(e) =>
            this.handlesubmitAddFlow(e, indexValue, this.state.activeEntity)
          }
        >
          {addFlowform}
          {loadingImg}
          {this.state.AddFlowControls.botEntityInputs.value > 0 && (
            <>
              <EntityCustom
                optionValues={optionValues}
                entityList={entityList}
                addEntity={addEntity}
                Rememberchanged={(e) => this.handleRemember(e, indexValue)}
                Entitychanged={(e) => this.handleToggle(e, indexValue)}
                changed={(e) => this.handleEntities(e, indexValue)}
                buttonClicked={(e) => this.saveaddOption(e, indexValue)}
                validation={entityName.validation}
                validationName={entityName.validationName}
                invalidEntity={entityName.invalidEntity}
                question={entityName.question}
                defaultValue={entityName.defaultValue}
                optionType={entityName.optionType}
                display={entityName.display}
                addOption={entityName.addOption}
                entityType={entityName.entityType}
                remember={entityName.remember}
                validationText={entityName.validationText}
                emptyQuestionMsg={this.state.emptyQuestionMsg}
                emptyDefaultMsg={this.state.emptyDefaultMsg}
                emptyValidationTypeMsg={this.state.emptyValidationTypeMsg}
                emptyValidationValueMsg={this.state.emptyValidationValueMsg}
                emptyValidationMsg={this.state.emptyValidationMsg}
                addOptionList={addOptionList}
                addOptionValue={entityName.addOptionValue}
                validateOptionMsg={this.state.validateOptionMsg}
              ></EntityCustom>
            </>
          )}
          <Row className={classes.ButtonAlign}>
            <div className="md-10">
              {this.state.AddFlowControls.botEntityInputs.value > 1 &&
                indexValue <
                  this.state.AddFlowControls.botEntityInputs.value && (
                  <ButtonComponent
                    variant="contained"
                    label="Next"
                    className="buttonWidth mb-2"
                    clicked={(e) => this.NextEntity(e, indexValue)}
                  />
                )}
              {(this.state.AddFlowControls.botEntityInputs.value == 0 ||
                this.state.AddFlowControls.botEntityInputs.value == -1 ||
                indexValue ==
                  this.state.AddFlowControls.botEntityInputs.value) && (
                <ButtonCustom btnType="blueButton">Save</ButtonCustom>
              )}
              <ButtonCustom
                btnInput="button"
                btnType="whiteButton"
                clicked={() => this.handleClose("FlowModal")}
              >
                Cancel
              </ButtonCustom>
            </div>
            <div className="col-10">
              {this.state.AddFlowControls.botEntityInputs.value > 0 &&
                indexValue <
                  this.state.AddFlowControls.botEntityInputs.value && (
                  <>
                    <label>
                      Add{" "}
                      {this.state.AddFlowControls.botEntityInputs.value -
                        indexValue}{" "}
                      Entities to save the flow{" "}
                    </label>
                  </>
                )}
            </div>

            {this.state.successMsg}
          </Row>
        </ModalPopUp>
        <ImportFlowDrawer
          show={this.state.setImportFlowShow}
          title={this.state.isCuxOrg?"Import Intent":"Import Flow"}
          onHide={this.handleClose}
          onChange={(e) => this.handleFile(e)}
          submit={(e) => this.submitImport(e)}
          config={this.props.config}
          importFile={this.state.importFile}
          error={this.state.filevalidation}
        />
        {/* Import CD Flow */}
        <ImportFlowDrawer
          show={this.state.setImportCuxBotShow}
          title={"Import Flow"}
          onHide={this.handleClose}
          onChange={(e) => this.handleFlowFile(e)} 
          submit={(e) => this.submitCuxFlowImport(e)}
          config={this.props.config}
          importFile={this.state.importFile}
          error={this.state.filevalidation}
        />
        {/* AI Generator Form */}
        <AIGenDrawer show={this.state.AIGenShow}
        onHide={() => this.handleClose("AIGen")} generatePhrase={this.generatePhrase}/>
        {/****************** Delete Entity Modal ****************/}
        <DeleteModal
          show={this.state.deleteEntityModalShow}
          deleteModal={() => this.handleClose("deleteEntityModal")}
          title="Delete Confirmation"
          bodyTitle="Are you sure you want to delete the entity?"
          buttonClick={() =>
            this.deleteEntitySubmission(this.state.deleteEntityModalData)
          }
        />

        {/****************** Delete Bot Modal ****************/}
        <DeleteModal
          show={this.state.deleteBotModalShow}
          deleteModal={() => this.handleClose("deleteBotModal")}
          title="Delete Confirmation"
          bodyTitle={"Are you sure you want to delete the ".concat(this.state.isCuxOrg?"intent?":"flow?")}
          buttonClick={(e) => this.deleteBot(e)}
        />
        {/****************** Delete Project ****************/}
        <DeleteModal
          show={this.state.deleteProjectModalShow}
          deleteModal={() => this.handleClose("deleteProjectModal")}
          title="Delete Confirmation"
          bodyTitle="Are you sure you want to delete the project?"
          buttonClick={(e) => this.deleteProjectList(e)}
        />
        {/****************** Delete CuxBot Modal ****************/}
        <DeleteModal
          show={this.state.deleteCuxBotModalShow}
          deleteModal={() => this.handleClose("deleteCuxBotModal")}
          title="Delete Confirmation"
          bodyTitle="Are you sure you want to delete the flow?"
          buttonClick={(e) => this.deleteCuxBot(e)}
        />

        <ModalPopUp
          modalclass="delProfile"
          show={this.state.deleteProfileModalShow}
          onHide={() => this.handleClose("deleteProfileModal")}
          title="Delete Confirmation"
        >
          Are you sure to delete Profile?
          <Col md={12}>
            <ButtonCustom
              btnType="blueButton"
              clicked={(e) => this.deleteProfile(e)}
            >
              Delete
            </ButtonCustom>
            <ButtonCustom
              btnType="whiteButton"
              btnInput="button"
              clicked={() => this.handleClose("deleteProfileModal")}
            >
              Cancel
            </ButtonCustom>
          </Col>
        </ModalPopUp>

        <ModalPopUp
          modalclass="triggerTypeModal"
          show={this.state.isTriggerTypeModalShow}
          onHide={() => this.handleClose("triggerTypeModal")}
          title="Select Trigger Type"
          submit={(e) =>
            this.handlesubmitTriggerType(e, indexValue, this.state.activeEntity)
          }
        >
          {triggerTypeform}
          {formdata}
          <Col md={12}>
            <ButtonCustom btnType="blueButton">Save</ButtonCustom>
            <ButtonCustom
              btnType="whiteButton"
              btnInput="button"
              clicked={() => this.handleClose("triggerTypeModal")}
            >
              Cancel
            </ButtonCustom>
          </Col>
        </ModalPopUp>

        <ModalPopUp
          modalclass="createProfileModal"
          show={this.state.isCreateProfileModalShow}
          onHide={() => this.handleClose("createProfileModal")}
          title="Add Profile"
          submit={(e) =>
            this.submitCreateProfile(e, indexValue, this.state.activeEntity)
          }
        >
          {createProfile}
          <Col md={12}>
            <ButtonCustom btnType="blueButton">submit</ButtonCustom>
            <ButtonCustom
              btnType="whiteButton"
              btnInput="button"
              clicked={() => this.handleClose("createProfileModal")}
            >
              close
            </ButtonCustom>
          </Col>
        </ModalPopUp>

        <ModalPopUp
          modalclass="configureModal"
          show={this.state.isConfigureModalShow}
          onHide={() => this.handleClose("configureModal")}
          title="Add Configuration"
          submit={(e) =>
            this.saveConfiguration(e, indexValue, this.state.activeEntity)
          }
        >
          <ConfigurationCustom
            optionValues={optionValues}
            changed={(e) => this.handleConfigure(e, indexValue)}
            buttonClicked={(e) => this.saveConfigureAddOption(e, indexValue)}
            validation={entityName.validation}
            validationName={entityName.validationName}
            invalidEntity={entityName.invalidEntity}
            urlName={entityName.urlName}
            question={entityName.question}
            optionType={entityName.optionType}
            display={entityName.display}
            addOption={entityName.addOption}
            emptyQuestionMsg={this.state.emptyQuestionMsg}
            emptyValidationTypeMsg={this.state.emptyValidationTypeMsg}
            emptyValidationValueMsg={this.state.emptyValidationValueMsg}
            emptyValidationMsg={this.state.emptyValidationMsg}
            emptyValidationNameMsg={this.state.emptyValidationNameMsg}
            validationText={entityName.validationText}
            addOptionList={addOptionList}
            addOptionValue={entityName.addOptionValue}
            validateOptionMsg={this.state.validateOptionMsg}
          ></ConfigurationCustom>

          <Col md={12} className={classes.ButtonAlign4}>
            <ButtonCustom btnType="blueButton">submit</ButtonCustom>
            <ButtonCustom
              btnType="whiteButton"
              btnInput="button"
              clicked={() => this.handleClose("configureModal")}
            >
              close
            </ButtonCustom>
          </Col>
        </ModalPopUp>

        {/****************** Connect data modal ****************/}
        <ModalPopUp
          modalHeaderClass={classes.connectDataModal}
          size={"xl"}
          ModalBodyClass={classes.connectDataModalBody}
          show={this.state.IsConnectModalShow}
          onHide={() => this.handleClose("ConnectModalShow")}
        >
          <Row style={{ height: "100%" }}>
            <hr></hr>
            <Col
              md={2}
              style={{
                borderRight: "1px solid #cbd0e4",
                backgroundColor: "#e3e8fb",
                padding: "10px",
              }}
            >
              <Row
                style={{
                  marginTop: "2px",
                  marginLeft: "12px",
                  fontSize: "11px",
                }}
              >
                <h4>Connect Data</h4>
                Connect data from your CRM tool
              </Row>
              <Row
                style={{
                  marginTop: "10px",
                  minHeight: "444px",
                  marginBottom: "-50px",
                  overflow: "auto",
                  display: "block",
                }}
              >
                <ul
                  style={{ marginLeft: "15%", marginRight: "13%" }}
                  className={classes.flowLists}
                >
                  <li>
                    <span>Remedy</span>
                  </li>
                  <li>
                    <span>Service Now</span>
                  </li>
                  <li>
                    <span>Salesforce</span>
                  </li>
                  <li>
                    <span>Jira Help Desk</span>
                  </li>
                  <li>
                    <span>Zendesk</span>
                  </li>
                </ul>
              </Row>
              <Row
                style={{ borderTop: "1px solid #cbd0e4", marginLeft: "-10px" }}
              >
                <Row className={classes.ImportClusterButton}>
                  {loadingConnectDataImport}
                  <Input
                    id="fileConnectData"
                    elementType="file"
                    ButtonType="Button"
                    changed={(event) => this.importConnectDataFileEvent(event)}
                  />
                </Row>
              </Row>
            </Col>
            <Col
              md={10}
              style={{ backgroundColor: "#ffffff" }}
              className={classes.ConnectDataCards}
            >
              <Row
                style={{
                  borderBottom: "1px solid #cbd0e4",
                  paddingBottom: "5px",
                }}
              >
                <Col md={9} className={classes.TopCallVolumeDrivers}>
                  {" "}
                  Top call volume drivers
                </Col>
                <Col md={3}>
                  <Row style={{ float: "right" }}>
                    <ButtonCustom
                      btnType={
                        this.state.SelectedClusters.length > 0
                          ? "blueButton"
                          : "whiteButton1"
                      }
                      btnInput="button"
                      clicked={() => this.importClustersData()}
                      disabled={
                        this.state.SelectedClusters.length > 0 ? "" : "disabled"
                      }
                      customStyle={{
                        backgroundColor:
                          this.state.SelectedClusters.length > 0
                            ? ""
                            : "#c5c5c5",
                        marginLeft: "40px",
                      }}
                    >
                      Create Flows
                    </ButtonCustom>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Row>
                  <Row style={{ marginLeft: "30px" }}>
                    {loadingImportClusterImg}
                  </Row>
                  {loadingClusterData}
                </Row>
                {clusterCards}
                <Row>
                  {!loadingClusterData && !clusterCards && (
                    <Col className={classes.ConnectDataMessage}>
                      No Record Found.
                    </Col>
                  )}
                </Row>
              </Row>
            </Col>
          </Row>
        </ModalPopUp>

        <ModalPopUp
          modalclass={classes.translateConfirmation}
          style={{ "margin-left": "20%" }}
          show={this.state.isProjectTranslation}
          onHide={() => this.handleClose("projectTranslation")}
          title="Project Confirmation Agreement"
          submit={(e) => this.handleProjectTranslation(e)}
        >
          <Col md={12}>
            <span>
              We will be migrating the selected project to the targeted
              language. This migration requires some of the selected project
              data to be translated and this translation would be done based on
              the best effort basis. You might have to review the migration
              before make use of it. Please proceed by clicking Ok to accept and
              proceed with the migration.
            </span>
          </Col>
          <Col md={12}>
            <ButtonCustom btnType="blueButton">OK</ButtonCustom>
            <ButtonCustom
              btnType="whiteButton"
              btnInput="button"
              clicked={() => this.handleClose("projectTranslation")}
            >
              Cancel
            </ButtonCustom>
          </Col>
        </ModalPopUp>

        <ModalPopUp
          size="lg"
          modalclass="projectAccuracyModal"
          show={this.state.isProjectAccuracyModelShow}
          onHide={() => this.handleClose("projectAccuracyModal")}
          title={<h4>Project Accuracy</h4>}
        >
          {projectAccuracy}

          <Col className={classes.ProjectAccuracyMessage}>
            <h5>your project is {this.state.projectAccuracy}% accurate</h5>
          </Col>
        </ModalPopUp>

        {/****************** Macro Knowledge Intent ****************/}
        <MacroDrawer
          show={this.state.custommacroShow}
          onHide={() => this.handleClose("custommacro")}
          title="Custom Macros"
        >
          <EditorCustom
            names="botEditor"
            refer={(instance) => {
              this.botEditor = instance;
            }}
            value={this.props.macrodata}
          />
          <br />
          <ButtonMUI
            variant="contained"
            label="Save"
            className="buttonWidth mb-2"
            clicked={this.saveMacroFileData}
          />
        </MacroDrawer>

        <DrawerComponent
          openDrawer={this.state.showTranslate}
          showDrawer={this.showDrawer}
          heading="Translate"
        >
          <div className="drawer">
            <div className="drawer-body mt-2">
              <form>
                <InputWithLabelComponent
                  placeHolder=""
                  className={"drawer-input"}
                  type="text"
                  name="name"
                  label="Current Project Language"
                  value={currentLangauge}
                  readOnly={true}
                  disabled={true}
                />
                <br />
                <SelectComponent
                  name="targetedLanguage"
                  label="Targeted Project Language"
                  changed={(event) => this.LanguageChangedHandler(event)}
                  required={true}
                  value={this.state.selectedLanguage}
                  options={templanglist}
                />
                <br />

                {this.state.showTranslateError}
                <div>
                  {templanglist.length <= 1 ? (
                    <span className="error-css">
                     Current project is already available in all the application supported languages.
                    </span>
                  ) : null}
                </div>
                <div className="form-group submit-btn-parent">
                  <ButtonCustom
                    btnInput="button"
                    btnType="blueButton"
                    disabled={templanglist.length > 1 ? false : true}
                    clicked={(event) => {
                      this.handleSubmitTranslate(event);
                    }}
                  >
                    TRANSLATE PROJECT
                  </ButtonCustom>
                </div>
              </form>
            </div>
          </div>
        </DrawerComponent>

        <ModalPopUp
          size="lg"
          modalclass="custommacro"
          onHide={() => this.handleClose("custommacro")}
          title="Custom Macros"
        >
          <EditorCustom
            names="botEditor"
            refer={(instance) => {
              this.botEditor = instance;
            }}
            value={this.props.macrodata}
          />
          <Row className={classes.BtnAlignment2}>
            <ButtonCustom btnType="blueButton" clicked={this.saveMacroFileData}>
              Save
            </ButtonCustom>
            <ButtonCustom
              btnInput="button"
              btnType="blueButton"
              clicked={() => this.handleClose("custommacro")}
            >
              Cancel
            </ButtonCustom>
          </Row>
        </ModalPopUp>

        <ModalPopUp
          modalclass="displayInfo"
          show={this.state.displayUseContent}
          onHide={() => this.handleClose("useContent")}
          title={this.state.useMacroValue}
        >
          {useMacros}
          <Row className={classes.ButtonAlign}>
            <ButtonCustom btnType="blueButton" clicked={this.saveUseData}>
              Use
            </ButtonCustom>
            <ButtonCustom
              btnInput="button"
              btnType="blueButton"
              clicked={() => this.handleClose("useContent")}
            >
              Cancel
            </ButtonCustom>
          </Row>
        </ModalPopUp>
        <ModalPopUp
          modalclass="displayInfo"
          show={this.state.displayInfoShow}
          onHide={() => this.handleClose("displayInfo")}
          title={this.state.macrosUserValue}
        >
          {displaydata}
          <h4>Output</h4>
          <LightTooltip
            arrow
            title={this.state.flowValue ? parseSavedData : null}
            placement="bottom"
          >
            <p>
              Output for the API call :{" "}
              {this.state.flowValue ? flowValue : null}
            </p>
          </LightTooltip>

          <Row className={[classes.ButtonAlign, classes.RunButton]}>
            <ButtonCustom btnType="blueButton" clicked={this.runMacroData}>
              Run
            </ButtonCustom>
          </Row>
        </ModalPopUp>
        <ModalPopUp
          modalclass="displayInfo1"
          show={this.state.displayInfoShow1}
          onHide={() => this.handleClose("displayInfo1")}
          title={this.state.macrosUserValue}
        >
          {displaydata1}
        </ModalPopUp>

        {/****************** Delete button for intent and script data ****************/}
        <ModalPopUp
          modalclass="delProjectList"
          show={this.state.deleteScriptData}
          onHide={() => this.handleClose("deleteScriptDataModal")}
          title="Delete Confirmation"
        >
          Are you sure to delete ?
          <Col md={12}>
            <ButtonCustom
              btnType="blueButton"
              clicked={(e) => this.deleteScript(e)}
            >
              Delete
            </ButtonCustom>
            <ButtonCustom
              btnType="whiteButton"
              btnInput="button"
              clicked={() => this.handleClose("deleteScriptDataModal")}
            >
              Cancel
            </ButtonCustom>
          </Col>
        </ModalPopUp>

        {/****************** Delete Bot Modal ****************/}
        <ModalPopUp
          modalclass="delUserModal"
          show={this.state.deleteIntentShow}
          onHide={() => this.handleClose("deleteIntentModal")}
          title="Delete Confirmation"
        >
          Deleting the question will delete the chat trigger node. Do you want
          to proceed?
          <Col md={12}>
            <ButtonCustom
              btnType="blueButton"
              clicked={(e) => this.deleteTriggerRows(e)}
            >
              Yes
            </ButtonCustom>
            <ButtonCustom
              btnType="whiteButton"
              btnInput="button"
              clicked={() => this.handleClose("deleteIntentModal")}
            >
              No
            </ButtonCustom>
          </Col>
        </ModalPopUp>

        {/****************** Confirmation for Intent save Modal ****************/}

        <ModalPopUp
          modalclass="delUserModal"
          show={this.state.confirmIntentSave}
          onHide={() => this.handleClose("deleteIntentSaveModal")}
          title="Confirmation for Save Intent"
        >
          Do you want to retain the earlier main trigger question as an
          alternate question?
          <Col md={12}>
            <ButtonCustom
              btnType="blueButton"
              clicked={(e) => this.updatechatIntentSaveYes(e)}
            >
              Yes
            </ButtonCustom>
            <ButtonCustom
              btnType="whiteButton"
              btnInput="button"
              clicked={(e) => this.updatechatIntentSaveNo(e)}
            >
              No
            </ButtonCustom>
          </Col>
        </ModalPopUp>

        {/****************** Confirmation for  save Editflow Tab ****************/}

        <ModalPopUp
          modalclass="delUserModal"
          show={this.state.showSavePopup}
          onHide={() => this.handleClose("showSavePopup")}
          title="Confirmation for Saving Details"
        >
          Do you want to save the changes done in the Edit Flow tab?
          <Col md={12}>
            <ButtonCustom
              btnType="blueButton"
              clicked={(e) => this.updateTrigger(e)}
            >
              Yes
            </ButtonCustom>
            <ButtonCustom
              btnType="whiteButton"
              btnInput="button"
              clicked={() => this.DiscardSavedchanges()}
            >
              No
            </ButtonCustom>
          </Col>
        </ModalPopUp>
        {this.state.mainTriggerEmptyAlert}
      </>
    );
  }
}
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 12,
    fontFamily: "Roboto-Regular",
  },
  arrow: {
    color: theme.palette.common.white,
    "&:before": {
      border: "1px solid #E6E8ED",
      boxShadow: theme.shadows[1],
    },
  },
}))(Tooltip);

const mapStateToProps = (state) => {
  return {
    FlowIntent: state.projectview.flows,
    AIStandard:state.user.moduleAccess.conversationalStandard,
    projectM:state.user.moduleAccess.projectManagement,
    AIAdmin:state.user.moduleAccess.conversationalAdmin,
    selectedFlowName: state.projectview.selectedFlowName,
    loading: state.projectview.loading,
    botProfileFileData: state.projectview.getFileData,
    entityList: state.projectview.entity,
    fileData: state.projectview.fileData,
    botCreated: state.projectview.botCreated,
    botList: state.projectview.botList,
    scriptLoading: state.projectview.scriptLoading,
    entityLoading: state.projectview.entityLoading,
    intentData: state.projectview.intentData,
    getStructure: state.projectview.getStructure,
    docData: state.projectview.docData,
    importKnowledgeIntent: state.projectview.importKnowledgeIntent,
    delknowledgeIntentInprogress:
      state.projectview.delknowledgeIntentInprogress,
    knowledgeImportloading: state.projectview.knowledgeImportloading,
    GetKnowledgeloading: state.projectview.GetKnowledgeloading,
    importflowStart: state.projectview.importflowStart,
    initChatBot: state.projectview.initChatBot,
    importConnectData: state.projectview.importConnectData,
    connectDataImportLoading: state.projectview.connectDataImportLoading,
    clusterData: state.projectview.clusterData,
    clusterDataLoading: state.projectview.clusterDataLoading,
    intentListDataScore: state.projectview.intentListDataScore,
    createProjectAccuracy: state.projectview.createProjectAccuracy,
    setting: state.user.settings,
    projectTranslation: state.projectview.projectTranslation,
    translationinprogress: state.projectview.translationinprogress,
    projectdata: state.projectview.projectdata,
    projectlist: state.user.projects,
    IsKnowledgeGridCollapse: state.projectview.IsKnowledgeGridCollapse,
    createBotloading: state.projectview.createBotloading,
    macroFileData: state.projectview.macroFileData,
    macrodata: state.projectview.macrodata,
    macrolist: state.projectview.macrolist,
    chatintent: state.projectview.chat,
    title: state.projectview.title,
    subtitle: state.projectview.subtitle,
    image: state.projectview.image,
    listContent: state.projectview.list,
    nodeName: state.projectview.node,
    imgtitle: state.projectview.imgtitle,
    imageurl: state.projectview.imageurl,
    buttondatavalue: state.projectview.buttondata,
    conditionView: state.projectview.condition,
    conditionNodeA: state.projectview.ConditionA,
    conditionNodeB: state.projectview.ConditionB,
    rule: state.projectview.rule,
    getMacrosFileData: state.projectview.getMacrosFileData,
    mappedData: state.user.mappedData,
    saveMacrosData: state.projectview.saveMacrosData,
    configurationInputs: state.projectview.configurationInputs,
    ConditionAChat: state.projectview.ConditionAChat,
    ConditionBChat: state.projectview.ConditionBChat,
    buttonRows: state.projectview.buttonLabelRows,
    listData: state.projectview.list,
    videoContent: state.projectview.videoContents,
    chatReply: state.projectview.chatReply,
    IsTabChanged: state.projectview.IsTabChanged,
    getprojDesp: state.projectview.getprojDesp,
    cuxBotList: state.projectview.CuxBotList,
    cuxBotId: state.projectview.CuxBotId,
    languageList: state.user.languageList,
    availableprojectLangs: state.user.projectLang,
    permissions:state.user.permissionList,
    otherDocsPermission:state.user.moduleAccess.cognitiveOtherDocsUpload,
    showProjectHeader:state.projectview.showProjectHeader,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateTrigger: (intentList, APIURL, isTriggerSuccess) =>
      dispatch(actions.updateTrigger(intentList, APIURL, isTriggerSuccess)),
    onUpdateIntent: (pattern, APIURL, fromImportCluster) =>
      dispatch(actions.createIntent(pattern, APIURL, fromImportCluster)),
    onDeleteIntent: (pattern, APIURL, fromImportCluster) =>
      dispatch(actions.deleteIntent(pattern, APIURL, fromImportCluster)),

    ongetBotFile: (file, APIURL) => dispatch(actions.getBotFile(file, APIURL)),
    ongetProfileStructure: (bot, APIURL) =>
      dispatch(actions.getProfileStructure(bot, APIURL)),
    onsaveBotProfile: (profile, APIURL) =>
      dispatch(actions.saveBotProfile(profile, APIURL)),
    onDeleteEditPage: (profile, APIURL) =>
      dispatch(actions.deleteEditPage(profile, APIURL)),

    onsaveMacroFileData: (apiURL, macroData) =>
      dispatch(actions.SaveMacroFileData(apiURL, macroData)),
    ongetEntity: (flow, APIURL) => dispatch(actions.getEntity(flow, APIURL)),
    onreadFile: (file, APIURL) => dispatch(actions.readFile(file, APIURL)),
    OnCreateBot: (APIURL, bot, fromImportCluster, isProfileSuccess, isCuxOrg) =>
      dispatch(
        actions.CreateBot(APIURL, bot, fromImportCluster, isProfileSuccess, isCuxOrg)
      ),
    OnGetBotList: (APIURL) => dispatch(actions.GetBotList(APIURL)),
    createUpdateEntity: (
      entity,
      APIURL,
      fromFlowModal,
      isDeleteAction,
      EntityOccurance
    ) =>
      dispatch(
        actions.onCreateUpdateEntity(
          entity,
          APIURL,
          fromFlowModal,
          isDeleteAction,
          EntityOccurance
        )
      ),
    OnGetChatBot: (entity, APIURL) =>
      dispatch(actions.getChatBot(entity, APIURL)),
    OnGetDoc: (doc, APIURL) => dispatch(actions.getDoc(doc, APIURL)),

    onGetKnowledgeIntent: (reqData, APIURL) =>
      dispatch(actions.GetKnowledgeIntent(reqData, APIURL)),
    OnDeleteBot: (APIURL, data, isCuxOrg) =>
      dispatch(actions.deleteFlowBot(APIURL, data, isCuxOrg)),
    OnKnowledgeIntentImport: (APIURL, data, importType) =>
      dispatch(actions.KnowledgeIntentImport(APIURL, data, importType)),
    OnHandleImportError: (msg) => dispatch(actions.HandleImportError(msg)),
    OnExportBot: (APIURL, data) =>
      dispatch(actions.exportFlowBot(APIURL, data)),
    OnExportCuxBot: (APIURL, data) =>
      dispatch(actions.exportCuxBot(APIURL, data)),
    OnProjectfileupload: (apiUrl, formdata) =>
      dispatch(actions.Projectfileupload(apiUrl, formdata)),
    OnImportFlow: (APIURL, data, dataUrl, flowName) =>
      dispatch(actions.importflow(APIURL, data, dataUrl, flowName)),
    OnImportCuxBot: (APIURL, data,flowName) =>
      dispatch(actions.importCuxBot(APIURL, data, flowName)),
    OnExportProject: (APIURL, data) =>
      dispatch(actions.exportProject(APIURL, data)),
    OnInitChatBot: (APIURL, data, intent) =>
      dispatch(actions.initChatBot(APIURL, data, intent)),
    ondownloadProject: (APIURL, data) =>
      dispatch(actions.downloadProject(APIURL, data)),
    OndownloadExportBot: (APIURL, data) =>
      dispatch(actions.downloadExportBot(APIURL, data)),
    OnGetClusterData: (APIURL, reqData) =>
      dispatch(actions.GetClusterData(APIURL, reqData)),
    onSuccessImportCluster: (msg) =>
      dispatch(actions.SuccessImportCluster(msg)),

    OnIntentListDataScore: (APIURL, data) =>
      dispatch(actions.IntentListDataScore(APIURL, data)),
    OnCreateProjectAccuracy: (APIURL, data) =>
      dispatch(actions.CreateProjectAccuracy(APIURL, data)),
    OnHandleProjectAccuracyError: (msg) =>
      dispatch(actions.HandleProjectAccuracyError(msg)),

    onTranslateProject: (APIURL, data) =>
      dispatch(actions.TranslateProject(APIURL, data)),
    OnGetProjectData: (APIURL, data) =>
      dispatch(actions.GetProjectData(APIURL, data)),
    onHandleTranslateBtn: (disableFlag) =>
      dispatch(actions.HandleTranslateBtn(disableFlag)),
    onSaveMacroFileData: (APIURL, data) =>
      dispatch(actions.SaveMacroFileData(APIURL, data)),
    onreadMacro: (APIURL, data) => dispatch(actions.readMacro(APIURL, data)),
    onGetMacroList: (APIURL, data) =>
      dispatch(actions.GetMacroList(APIURL, data)),
    OnDeleteProject: (APIURL, data, boolValue) =>
      dispatch(actions.DeleteProject(APIURL, data, boolValue)),
    onGetUserById: (APIURL, botUrl) =>
      dispatch(actions.GetUserbyID(APIURL, botUrl)),
    OnGetProjectByLanguage: (APIURL, data1) =>
      dispatch(actions.GetProjectByLanguage(APIURL, data1)),
    onUpdateChat: (chatuserinput) =>
      dispatch(actions.updateChat(chatuserinput)),
    onUpdateFile: (data, APIURL) => dispatch(actions.updateFile(data, APIURL)),
    onaddNode: (node) => dispatch(actions.addNode(node)),
    onsaveRule: (userinput) => dispatch(actions.saveRule(userinput)),
    onMacrosReadFile: (file, APIURL) =>
      dispatch(actions.macrosReadFile(file, APIURL)),
    onGetmappedData: (apiUrl) => dispatch(actions.GetmappedData(apiUrl)),
    OnSaveMacrosData: (APIURL, data) =>
      dispatch(actions.saveMacrosData(APIURL, data)),
    OnConfigurationInputs: (data, APIURL) =>
      dispatch(actions.ConfigurationInputs(data, APIURL)),
    OnDeleteProfile: (APIURL, data) =>
      dispatch(actions.deleteProfile(APIURL, data)),
    onUpdateCondition: (userinput, data) =>
      dispatch(actions.updateCondition(userinput, data)),
    OnBConfiguration: (data, APIURL) =>
      dispatch(actions.BConfiguration(data, APIURL)),
    showflowList: (APIURL) => dispatch(actions.flowList(APIURL)),
    onsaveChatData: (profile, APIURL) =>
      dispatch(actions.saveChatData(profile, APIURL)),
    onUpdateVideo: (data) => dispatch(actions.updateVideo(data)),
    onchangeTabAction: (tab) => dispatch(actions.changeTabAction(tab)),
    OnGetUserPermissions: (apiUrl, projectname) =>
      dispatch(actions.GetUserPermissions(apiUrl, projectname)),
    OngetProjectDescription: (apiUrl, data) =>
      dispatch(actions.getProjectDescription(apiUrl, data)),
    onCreateCuxBot: (APIURL, bot) =>
      dispatch(actions.CreateCuxBot(APIURL, bot)),
    onGetCuxBotList: (APIURL, projectName) =>
      dispatch(actions.GetCuxBotList(APIURL, projectName)),
    onDeleteCuxBot: (APIURL, botid) =>
      dispatch(actions.DeleteCuxBot(APIURL, botid)),
    onDeleteCuxTemplate: (APIURL, botid, type) => 
      dispatch(actions.DeleteCuxTemplate(APIURL, botid, type)),
    onGetEnglishProjects: (botBuilder_URL) =>
      dispatch(actions.GetEnglishProjects(botBuilder_URL)),
    OnGetAllProjectsData: (APIURL) =>
      dispatch(actions.GetAllProjectsData(APIURL)),
    OnGetProjectLang: (apiUrl, projectName) =>
      dispatch(actions.GetProjectLang(apiUrl, projectName)),
    OnProjectHeaderShow:(showProjectHeader)=>dispatch(actions.projectHeaderShow(showProjectHeader)),
    onKnowledgeGridExpandCollapse: (expand)  => dispatch(actions.KnowledgeGridExpandCollapse(expand)) ,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorHandler(ProjectViewPage, axios));
