const yesQuestionsUpdateReducer = (state, payload) => {
    const filterYesQuestions = (actionPayload) => {
        const yesQuestions = state.yesQuestions;
        const selectedQuestion = actionPayload.selectedQuestion;
        const type = actionPayload.type;
        if(type === 'no') {
            return yesQuestions.filter(item => item.symptoms !== selectedQuestion);
        }
        return yesQuestions;
    }
    return {
        ...state,
        yesQuestions: filterYesQuestions(payload)
    };
};

export default yesQuestionsUpdateReducer;