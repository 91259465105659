import React, { memo } from 'react';
import { Handle } from 'react-flow-renderer';
import NodeBox from './NodeBox';

export default memo(({ data, isConnectable }) => {
    
  return (
    <>
      <Handle
        type="target"
        position="top"
        style={{ background: '#555' }}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />
      <NodeBox type='HTTP' data={data}></NodeBox>
      <Handle
          type="source"
          position="bottom"
          id="success"
          style={{ left: 224/3, background: '#555' }}
          isConnectable={isConnectable}
      />
      <Handle
        type="source"
        position="bottom"
        id="error"
        style={{ left: 224/3 * 2, background: '#555' }}
        isConnectable={isConnectable}
      />
      
    </>
  );
});