import React, { useEffect } from "react";

function UtterancesComponent(props) {
let utValues=Object.values(props.utterances);
    useEffect(() => {
        props.calUtterances(props.UTdata.header)
        props.setHeader(props.UTdata.header, props.UTdata.count)
    }, [])
    return (<div className='payments-div' >
        <div className='payments-header'>{props.header}({utValues.length>0?utValues.reduce((acc,ele)=>acc+ele):0})</div>
        <div className='payments-data-wrapper scroll_payment_div'>
            {Object.keys(props.utterances).map(key => {
                return (<div className="d-flex justify-content-between">
                    <div>{key}</div>
                    <div>{props.utterances[key]}</div>
                </div>)
            })}
        </div>
    </div>)
}

export default UtterancesComponent;
