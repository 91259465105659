import React from 'react';
import { Row } from 'react-bootstrap';
import ReactSelectComponent from '../SettingPage/SettingInnerPages/components/ReactSelectComponent/ReactSelectComponent';
import PredefinedRanges from '../../components/UI/DatetimeRangePicker/PredefinedRanges';

function DashboardFilters(props) {
    return (
        <div className="col-md-6">
            <Row>
                <div className="col-md-6 reportsProjectList">
                    <span className="reportHeading">{props.location.pathname ==='/CognitiveMetrics' ? "Virtual Assistant/Project Group" : "Virtual Assistant"}</span>
                    {<ReactSelectComponent
                        customClassName="SelectView"
                        value={{ value: props.vaName, label: props.vaName }}
                        options={ props.VAList }
                        name="VirtualAssistant"
                        onChange={props.VAListClick}
                    >
                    </ReactSelectComponent>}
                </div>
                <div className="col-md-6 reportsProjectList">
                    <span className="reportHeading">Project</span>
                    {<ReactSelectComponent
                        customClassName="SelectView"
                        value={{ value: props.projectname, label: props.projectname }}
                        options={ props.vaName === "None" ? props.allProjectsList : props.vaName === "All" ? props.VAProjectsList : props.projectList}
                        name="project"
                        onChange={props.projectclick}
                    >
                    </ReactSelectComponent>}
                </div>
            </Row>
            <Row>
            <div className="col-md-6">
            </div>
            <div className="col-md-6 reportsDateRangeOuter">
                <PredefinedRanges onDateChange={props.getDateValues} startDate={props.startDate} endDate={props.endDate} />
            </div>
        </Row>
    </div>
       
    )
}

export default DashboardFilters;