import * as React from 'react';
import './Label.css';
import { Form } from "react-bootstrap";

const LabelComponent = (props) => {
   return(
      <Form.Label className="mb-1 labelClass">{props.value}</Form.Label>
   )
}

export default LabelComponent;
