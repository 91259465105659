// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.heading3 {
    font-family: 'Roboto-Light';
    font-size: 14px;
    Color: #000000;
    
}
.text{
    color: #016BB5;
}`, "",{"version":3,"sources":["webpack://./src/containers/SettingPage/SettingInnerPages/components/Typography/Heading3/heading3.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,eAAe;IACf,cAAc;;AAElB;AACA;IACI,cAAc;AAClB","sourcesContent":[".heading3 {\n    font-family: 'Roboto-Light';\n    font-size: 14px;\n    Color: #000000;\n    \n}\n.text{\n    color: #016BB5;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
