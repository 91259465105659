import React, { useState } from 'react';
import propTypes from 'prop-types';

import CognitiveSearch from './CognitiveSearch';


function SharePointPage({
    config,
    scriptContent,
    scriptLoadingImg,
    ongetProfileStructure,
    openJSFile,
    handleClose,
    setValidationMessage,
    getCurrentDate,
    pageload,
    isFaq,
    categoryName
}) {
    const [selectedTab, setSelectedTab] = useState('importFiles');

    // const onTabChange = (selected) => {
    //     setSelectedTab(selected)
    // };
    const navigateToNextTab = () => {
        setSelectedTab('manageFileData');
    }
    const close = ()=>{
        handleClose()
    }
    return (<>
        <CognitiveSearch
            config={config}
            pageload={pageload}
            getCurrentDate={getCurrentDate}
            setValidationMessage={setValidationMessage}
            handleClose={close}
            openJSFile={openJSFile}
            ongetProfileStructure={ongetProfileStructure}
            scriptLoadingImg={scriptLoadingImg}
            scriptContent={scriptContent}
            navigateToNextTab={navigateToNextTab}
            isFaq={isFaq}
            categoryName={categoryName}
        />
        {/* <Tabs id="controlled-tab-example" activeKey={selectedTab} onSelect={onTabChange}> */}
        {/* <Tab eventKey="importFiles" title="Import Files" style={{marginLeft:"15px",marginRight:"15px"}}>
                {selectedTab === 'importFiles' ?
                    <CognitiveSearch
                        config={config}
                        pageload={pageload}
                        getCurrentDate={getCurrentDate}
                        setValidationMessage={setValidationMessage}
                        handleClose={close}
                        openJSFile={openJSFile}
                        ongetProfileStructure={ongetProfileStructure}
                        scriptLoadingImg={scriptLoadingImg}
                        scriptContent={scriptContent}
                        navigateToNextTab={navigateToNextTab}
                        isFaq={isFaq}
                    /> : null
                }
            </Tab> */}
        {/* <Tab eventKey="manageFileData" title="Manage File Metadata">
                {selectedTab === 'manageFileData' ?
                    <ManageFileData
                        config={config}
                        navigateToNextTab={navigateToNextTab}
						isFaq={isFaq}
                    />
                    : null}
            </Tab> */}
        {/* </Tabs> */}
    </>
       
    )
}

SharePointPage.propTypes = {
    isFaq: propTypes.bool
};

SharePointPage.defaultProps = {
    isFaq: false
};

export default SharePointPage;
