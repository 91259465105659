import React, { Component } from "react";
import { connect } from "react-redux";
import ButtonCustom from "../../../../components/UI/Button/Button";
import CheckboxComponent from "./checkbox";

import * as actions from "../../../../store/actions/index";
import axios from "../../../../instance";
import { isEqual } from "lodash";
import Spinner from "../BackupRestore/Spinner/Spinner";

function mapStateToProps(state) {
  return {
    restoreOptionsList: state.backupRestore.restoreOptionsList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getRestoreList: () => dispatch(actions.getRestoreList()),
    SetSuccessMessage: (msg) => dispatch(actions.SetSuccessMessage(msg)),
    SetErrorMessage: (msg) => dispatch(actions.SetErrorMessage(msg)),
  };
}
class RestoreTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiUrl: this.props.apiURL,
      restoreOptionsList: [],
      showSpinner: false,
      selectedValue: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.restoreAllData = this.restoreAllData.bind(this);
    this.restoreSelected = this.restoreSelected.bind(this);
    this.statusEsRestore = this.statusEsRestore.bind(this);
  }

  componentDidMount() {
    axios
      .post(this.state.apiUrl + "/listesdump", {})
      .then((response) => {
        if (isEqual(response.status, 200) && isEqual(response.data.status.responseType, "success")) {
          const result = response.data.data.split(",");
          
          this.setState({
            restoreOptionsList: result,
            selectedValue: result[0],
          });
        } else {
          this.props.SetErrorMessage("listesdump error here!");
        }
      })
      .catch((error) => {
        this.props.SetErrorMessage(error);
      });
  }

  handleChange = (e) => {
    this.setState({
      selectedValue: e.target.value,
    });
  };

  statusEsRestore = (url, data) => {
    axios
      .post(url + "/statusesrestore", data)
      .then((response) => {
        if (isEqual(response.status, 200) && isEqual(response.data.status.responseType, "success") && isEqual(response.data.data, "started")) {
          setTimeout(() => {
            this.statusEsRestore(url, data);
          }, 6000);
        } 
        else {
          if (isEqual(response.data.data, "Failed")) {
            this.props.SetErrorMessage("esbkuprestorestatus Failed!");
          } else if (isEqual(response.data.data, "Completed")) {
            this.props.SetSuccessMessage("esbkuprestore Successfully completed!" );
          }
          this.setState({ showSpinner: false });
        }
      })
      .catch((error) => {
        this.props.SetErrorMessage("esbkuprestorestatus service error !", error);
      });
  };

  restoreAllData = () => {
    this.setState({ showSpinner: true });
    axios.post(this.state.apiUrl + "/esbkuprestore", {bkupfold: this.state.selectedValue })
      .then((response) => {
        if (isEqual(response.status, 200) && isEqual(response.data.status.responseType, "success")) {
          this.statusEsRestore(this.state.apiUrl, {});
        } else if (isEqual(response.status, 400) &&isEqual(response.data.status.responseType, "Failure")) {
          this.props.SetErrorMessage("esbkuprestore Service Failure!");
        } else {
          this.props.SetErrorMessage("esbkuprestore unhandled error!");
        }
      })
      .catch((error) => {
        this.setState({ showSpinner: false });
        this.props.SetErrorMessage(error);
      });
  };

  restoreSelected = () => {
    //restore functionality here
  };

  render() {
    const checkboxes = [
      { name: "Projects", key: "1", label: "Projects" },
      { name: "Intents", key: "2", label: "Intents" },
      { name: "SmartFAQ", key: "3", label: "SmartFAQ" },
      { name: "Connectors", key: "4", label: "Connectors" },
      { name: "Tenenats Settings", key: "5", label: "Tenenats Settings" },
      {
        name: "Agent converstion history",
        key: "6",
        label: "Agent converstion history",
      },
    ];
    const checkboxesOne = [
      { name: "Ticket Data", key: "1", label: "Ticket Data" },
      { name: "Knowledge Base Data", key: "2", label: "Knowledge Base Data" },
    ];

    let eventList =
      this.state.restoreOptionsList.length > 0
        ? this.state.restoreOptionsList.map((item) => {
            return <option key={item}>{item}</option>;
          })
        :  <option>No listesdump Found</option>;

    return (
      <div className="margin-setup select-backup">
        {this.state.showSpinner ? <Spinner /> : null}
        <h6>Select Backup</h6>
        <div className="row">
          <div className="col-md-4">
            <div className="form-group">
              <select size="1" className="form-control custom-select" onChange={this.handleChange}>
                {eventList}
              </select>
            </div>
          </div>
          <div className="col-md-8">
            <div className="form-group">
              <ButtonCustom
                btnInput="button"
                disabled={true}
                clicked={this.restoreSelected}>
                Restore Selected
              </ButtonCustom>
              <ButtonCustom
                btnInput="button"
                disabled={isEqual(this.state.selectedValue, "")}
                customStyle={{ marginLeft: "4%" }}
                clicked={this.restoreAllData}>
                Restore all data
              </ButtonCustom>
            </div>
          </div>
        </div>
        <hr className="margin-setup"></hr>
        <div className="margin-setup ">
          <h6>Selecteddata Restore</h6>
          <div className="row">
            <div className="col-md-6" style={{ display: "inline-block" }}>
              <CheckboxComponent checkboxes={checkboxes} />
            </div>
            <div className="col-md-6">
              <CheckboxComponent checkboxes={checkboxesOne} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RestoreTab);
