const topResolutionsChangeHandlerReducer = (state, payload) => {
    return {
        ...state,
        topresolutions: {
            ...state.topresolutions,
            ...payload
        }
    }
}

export default topResolutionsChangeHandlerReducer;