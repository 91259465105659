import React from 'react';
import CircleProgress from "../CircleProgress/CircleProgressBar.module.css";
const INITIAL_OFFSET = -70;
const circleConfig = {
  viewBox: '2 5 45 50',
  x: '30',
  y: '30',
  sw:'2',
  radio: '15.5'
};

const CircleProgressBarBase = ({
  trailStrokeColor,
  strokeColor,
  percentage
  }) => {
    return (
        <figure className={CircleProgress.boxSize}>
            <svg viewBox={circleConfig.viewBox}>
                <circle
                className="ring"
                cx={circleConfig.x}
                cy={circleConfig.y}
                strokeWidth={circleConfig.sw}
                r={circleConfig.radio}
                fill="transparent"
                stroke={trailStrokeColor}
                />
                <circle
                className="path"
                cx={circleConfig.x}
                cy={circleConfig.y}
                strokeWidth={circleConfig.sw}
                r={circleConfig.radio}
                fill="transparent"
                stroke={strokeColor}
                strokeDasharray={`${percentage} ${100 - percentage}`}
                strokeDashoffset={INITIAL_OFFSET}
                />
                <g className="circle-label">
                <text x="68%" y="66%" className={CircleProgress.percentage}>
                    {percentage}%
                </text>
                </g>
            </svg>
        </figure>
    );
};

export default CircleProgressBarBase;