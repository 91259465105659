import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../utility';
const initialState = {
    error: null,
    cognitiveLoading: false,
}


const getCognitiveStart = (state) => {
    return updateObject(state, { error: null, cognitiveLoading: true });
};


const getCognitiveSuccess = (state, action) => {
    
    return updateObject(state, {
        input: action.input,
        cognitiveLoading: false,
       
    });
};

const getCognitiveFailure = (state) => {
    return updateObject(state, {
       // loading:action.loading,
        cognitiveLoading: false
    });
};

const getUrlMiningStart = (state, action) => {
    return updateObject(state, { 
        loading:action.loading
    });
};


const getUrlMiningSuccess = (state, action) => {
    
    return updateObject(state, {
        minedUrl: action.input,
        loading:action.loading,
       
    });
};

const getUrlMiningFailure = (state, action) => {
    return updateObject(state, {
        minedUrl: action.input,
        loading:action.loading,
    });
};

const getDownloadCrawlDataStart = (state, action) => {
    return updateObject(state, { 
        loading:action.loading
    });
};


const getDownloadCrawlDataSuccess = (state, action) => {
    
    return updateObject(state, {
        downloadCrawlData: action.input,
        loading:action.loading,
       
    });
};

const getDownloadCrawlDataFailure = (state, action) => {
    return updateObject(state, {
        loading:action.loading,
    });
};
const DownloadDataStart = (state, action) => {
    return updateObject(state, {
        loading:action.loading,
    });
};

const deleteMultimediaRowSuccess = (state, action) => {
    
    return updateObject(state, {
        loading:action.loading,
       
    });
};

const deleteMultimediaRowFailure = (state, action) => {
    return updateObject(state, {
        loading:action.loading,
    });
};
const deleteMultimediaRowStart = (state, action) => {
    return updateObject(state, {
        loading:action.loading,
    });
};

const editMultimediaRowSuccess = (state, action) => {
    
    return updateObject(state, {
        edited:action.edited,
       
    });
};

const editMultimediaRowFailure = (state, action) => {
    return updateObject(state, {
        edited:action.edited,
    });
};
const editMultimediaRowStart = (state, action) => {
    return updateObject(state, {
        edited:action.edited,
    });
};

const getCognitiveFlowStart = (state, action) => {
    return updateObject(state, {
        cognitiveData:null,
    });
};

const getCognitiveFlowFailure = (state, action) => {
    return updateObject(state, {
        cognitiveData:null,
    });
};

const getCognitiveFlowSuccess = (state, action) => {
    return updateObject(state, {
        cognitiveData:action.cognitiveData,
    });
};

const cognitiveReducer = (state = initialState, action) => {
    switch (action.type) {

case actionTypes.GET_COGNITIVE_START: return getCognitiveStart(state);
case actionTypes.GET_COGNITIVE_SUCCESS: return getCognitiveSuccess(state, action);
 case actionTypes.GET_COGNITIVE_FAILURE: return getCognitiveFailure(state);
 case actionTypes.GET_URLMINING_START: return getUrlMiningStart(state, action);
case actionTypes.GET_URLMINING_SUCCESS: return getUrlMiningSuccess(state, action);
 case actionTypes.GET_URLMINING_FAILURE: return getUrlMiningFailure(state, action);
 case actionTypes.GET_DOWNLOAD_CRAWL_DATA_START: return getDownloadCrawlDataStart(state, action);
 case actionTypes.GET_DOWNLOAD_CRAWL_DATA_SUCCESS: return getDownloadCrawlDataSuccess(state, action);
  case actionTypes.GET_DOWNLOAD_CRAWL_DATA_FAILURE: return getDownloadCrawlDataFailure(state, action);
  case actionTypes.DOWNLOAD_DATA_START:return DownloadDataStart(state,action);
  case actionTypes.DELETE_MULTIMEDIA_ROW_START: return deleteMultimediaRowStart(state, action);
 case actionTypes.DELETE_MULTIMEDIA_ROW_SUCCESS: return deleteMultimediaRowSuccess(state, action);
  case actionTypes.DELETE_MULTIMEDIA_ROW_FAIL: return deleteMultimediaRowFailure(state, action);
  case actionTypes.EDIT_MULTIMEDIA_ROW_START: return editMultimediaRowStart(state, action);
 case actionTypes.EDIT_MULTIMEDIA_ROW_SUCCESS: return editMultimediaRowSuccess(state, action);
  case actionTypes.EDIT_MULTIMEDIA_ROW_FAILURE: return editMultimediaRowFailure(state, action);
  case actionTypes.GET_COGNITIVE_FLOW_START: return getCognitiveFlowStart(state, action);
  case actionTypes.GET_COGNITIVE_FLOW_FAILURE: return getCognitiveFlowFailure(state, action);
  case actionTypes.GET_COGNITIVE_FLOW_SUCCESS: return getCognitiveFlowSuccess(state, action);
  
default:
            return state;
    }
};

export default cognitiveReducer;