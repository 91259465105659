import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../utility';


const initialState = {
    error: null,
    success:null,
    warning:null
};

const setError = (state, action) => {
    return updateObject( state, {
        error: action.error
    });
};

const setWarning = (state, action) => {
    return updateObject( state, {
        warning: action.warning
    });
};

const setSuccess = (state, action) => {
    return updateObject( state, {
        success: action.success
    });
};


const messagereducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.SET_ERROR_MESSAGE: return setError(state, action);
        case actionTypes.SET_SUCCESS_MESSAGE: return setSuccess(state, action);
        case actionTypes.SET_WARNING_MESSAGE: return setWarning(state, action);
       
        default:
            return state;
    }
};

export default messagereducer;