import React from 'react';
import {useSelector} from 'react-redux';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ButtonCustom from '../../../components/UIComponents/Button/ButtonComponent'

import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import Input from '../../../components/UI/Input/InputCustom';
import NavbarCustom from '../../../components/UI/Navbar/Navbar';
import { PostCalltoServer, GetCalltoServer} from '../../../store/utility';
import '../SelfContainedTickets.css';
import {  styled, alpha} from "@material-ui/core/styles";
import { blue } from '@material-ui/core/colors';
import Switch from '@material-ui/core/Switch';
import permissionsJson from '../../../permissions_Json';
import { encryptAndStore } from '../../../utility/utility';



const SelfContainedTickets = ({
    
    config,
    setErrorMessage,
    setSuccessMessage,
    scheduleTypeConfig,
    
    onchangeProjectAction,
    permissions,
}) => {
    
    const [dataValue, setDataValue] = React.useState('servicenow');
    const [ticketingSystem, setTicketingSystem] = React.useState({options:[
        { value: 'servicenow', displayValue: 'Service Now' },
        { value: 'others', displayValue: 'Others' },
    ]});
    const [requestTypeOptions, setRequestTypeOptions] =React.useState({options:[]})
    const [requestTypeValue, setRequestTypeValue] =React.useState('')
    const [inputField, setInputField] = React.useState('')
    const [scheduleType, setScheduleType] = React.useState('minutes');
    const [sync,setSync] = React.useState("custom")
  
    const projectlist = useSelector(state => state.user.projects);
   
    
    const [radioForCustomFrequency, setRadioForCustomFrequency] = React.useState(true);
    const [radioForAutomatically, setRadioForAutomatically] = React.useState(false);
    const [projectname, setProjectname] = React.useState(localStorage.getItem("projectname"));
    const [searchT, setSearchT] = React.useState('');
    const [popperShow, setPopperShow] = React.useState(false);
    const [textBoxstate, setTextBoxState] = React.useState({
        ticketsNumber1: 3,
        ticketsNumber2: 3,
        ticketsNumber3: 3,
        scheduleTime: 5
    });
    const [toggle, setToggle] = React.useState(true)
    
    let  readWriteAccessForTicketsInsights = false;
    let TIStatus = permissions.find(data => {
      if (data.permission_code.includes(permissionsJson.permissionCode.TICKETS_INSIGHTS_RW) && data.sku_code.includes(permissionsJson.skuCode.STANDARD))
        return true
    })
    readWriteAccessForTicketsInsights = TIStatus ? true : false;

    const handleTextBoxChange = (event) => {
        setTextBoxState({ ...textBoxstate, [event.target.name]: event.target.value });
    };

   
    const { ticketsNumber1, ticketsNumber2, ticketsNumber3, scheduleTime } = textBoxstate;
   

    const setSearchTerm = (item) => {
        setSearchT(item)
    }

    const popperShowFn=()=>{
        setPopperShow(true);
    }
    const handleToggle = (e) =>{
        setToggle(e.target.checked)
    }

    const changeDataValue = (event) =>{
        setDataValue(event.target.value)
        setRequestTypeValue('')
        setInputField('')
        setRequestTypeOptions({options:[]})
        let savedProjectName=localStorage.getItem('projectname');
        let url = config.BOTBUILDER_URL + `/self-contained-tickets/project/` + savedProjectName + `/ticketingsystem/` +event.target.value;
        GetCalltoServer(url)
            .then(response => {
                try {
                    if (response.data.status.responseType === "success") {
                       if(response.data.data.length>0){
                            setTextBoxState({ ...textBoxstate, "ticketsNumber1": response.data.data[0].similarTickets, "ticketsNumber2":response.data.data[0].similarResolutions,"ticketsNumber3":response.data.data[0].knowledgeDocs,"scheduleTime":response.data.data[0].scheduleTime});
                            setSync(response.data.data[0].sync)
                            setToggle(response.data.data[0].schedule)
                            setRequestTypeValue(response.data.data[0].fieldName)
                            setInputField(response.data.data[0].fieldName)
                            if(response.data.data[0].sync == "automatic"){
                                setRadioForAutomatically(true)
                                setRadioForCustomFrequency(false)
                             }
                             else{
                                setRadioForAutomatically(false)
                                setRadioForCustomFrequency(true)
                             }
                            if(response.data.data[0].scheduleType == "minutes"){
                                setScheduleType('minutes')
                            }
                            else{
                                setScheduleType('hours')
                            }
                       }
                       else{
                                setSync("custom")
                                setRadioForAutomatically(false)
                                setRadioForCustomFrequency(true)
                                setToggle(true)
                                setTextBoxState({ ...textBoxstate,
                                ticketsNumber1: 3,
                                ticketsNumber2: 3,
                                ticketsNumber3: 3,
                                scheduleTime: 5,
                                })
                            }
                    }
                    else {
                        // setErrorMessage('Error in fetch Self Ticketing System !!');
                    }
                } catch (err) {
                    // setErrorMessage('Error in fetch Self Ticketing System !!!');
                }
            });
    }
    const changeRadioForAutomatically = () =>{
        if(radioForAutomatically == false){
            setRadioForAutomatically(true)
            setRadioForCustomFrequency(false)
            setSync("automatic")
        }
        else{
            setRadioForAutomatically(false)
            setRadioForCustomFrequency(true)
            setSync("custom")
        }
    }

    const changeRadioForCustomFrequency = () => {
        if(radioForCustomFrequency == false){
            setRadioForAutomatically(false)
            setRadioForCustomFrequency(true)
            setSync("custom")
        }
        else{
            setRadioForAutomatically(true)
            setRadioForCustomFrequency(false)
            setSync("automatic")
        }
    }

    const projectclick =(event,projectname)=>{
        setPopperShow(false);
        setSearchT("")
        let savedProjectName=localStorage.getItem('projectname');
        if(savedProjectName!==projectname){
        // localStorage.setItem('projectname', projectname );
        encryptAndStore('projectname', projectname );
            setProjectname(projectname);
        }
        onchangeProjectAction(projectname);        

          let url = config.BOTBUILDER_URL + `/self-contained-tickets/project/` + projectname + `/ticketingsystem/` +dataValue;
          GetCalltoServer(url)
              .then(response => {
                  try {
                    if (response.data.status.responseType === "success") {
                        if(response.data.data.length>0){
                             setTextBoxState({ ...textBoxstate, "ticketsNumber1": response.data.data[0].similarTickets, "ticketsNumber2":response.data.data[0].similarResolutions,"ticketsNumber3":response.data.data[0].knowledgeDocs,"scheduleTime":response.data.data[0].scheduleTime});
                             setRequestTypeValue(response.data.data[0].fieldName)
                             setInputField(response.data.data[0].fieldName)
                             setSync(response.data.data[0].sync)
                             setToggle(response.data.data[0].schedule)
                             if(response.data.data[0].sync == "automatic"){
                                setRadioForAutomatically(true)
                                setRadioForCustomFrequency(false)
                             }
                             else{
                                setRadioForAutomatically(false)
                                setRadioForCustomFrequency(true)
                             }
                             if(response.data.data[0].scheduleType == "minutes"){
                                 setScheduleType('minutes')
                             }
                             else{
                                 setScheduleType('hours')
                             }
                        }
                        else{
                                setSync("custom")
                                setRadioForAutomatically(false)
                                setRadioForCustomFrequency(true)
                                setToggle(true)
                                 setTextBoxState({ ...textBoxstate,
                                 ticketsNumber1: 3,
                                 ticketsNumber2: 3,
                                 ticketsNumber3: 3,
                                 scheduleTime: 5,
                                 })
                             }
                     }
                     else {
                         // setErrorMessage('Error in fetch Self Ticketing System !!');
                     }
                  } catch (err) {
                      // setErrorMessage('Error in fetch Self Ticketing System !!!');
                  }
              });
    }

    const handleSubmit = (e) => {
            e.preventDefault();
            const data = {
                "ticketingSystem": dataValue,
                "similarTickets": ticketsNumber1,
                "similarResolutions": ticketsNumber2,
                "knowledgeDocs": ticketsNumber3,
                "fieldName": requestTypeValue,
                "scheduleType": scheduleType,
                "scheduleTime": scheduleTime,
                "project": projectname,
                "sync": sync,
                "schedule":toggle,
                "inputField":inputField
            }
            let url = config.BOTBUILDER_URL + `/self-contained-tickets`;
            PostCalltoServer(url, data)
            .then(response => {
                try {
                    if (response.data.status.responseType === "success") {
                       setSuccessMessage(response.data.data);
                    }
                    else {
                        setErrorMessage('Data not saved successfully');
                    }
                } catch (err) {
                    setErrorMessage('Error in save setting');
                }
            });
            
	}

    React.useEffect(() => {
        let url = config.CONNECTOR_URL + `/get-all-fields`;
        var data = {
            "dataSourceType": "ticket_systems",
            "tenantId": localStorage.getItem("orgName"),
            "projectName": "",
            "connector": "",
            "dataSourceName": dataValue
          }
        PostCalltoServer(url, data)
            .then(response => {
                try {
                    if (response.data.status.responseType === "success") {
                       
                       var option = []
                       var resp = JSON.parse(response.data.data).data.fields
                       for(var i=0;i<resp.length;i++){
                                      option.push({ value: resp[i].fieldName, displayValue: resp[i].displayName })       
                       }
                    let data = {}
                    data.options = option
                    setInputField(JSON.parse(response.data.data).message)
                    setRequestTypeOptions(data)
                    }
                    else {
                        // setErrorMessage('Error in fetch Self Ticketing System !!');
                    }
                } catch (err) {
                    // setErrorMessage('Error in fetch Self Ticketing System !!!');
                }
            });
    },[dataValue])

    React.useEffect(() => {
        let url = config.CONNECTOR_URL + `/datasource-indexes/` + localStorage.getItem("orgName");
        PostCalltoServer(url)
            .then(response => {
                try {
                    if (response.data.status.responseType === "success") {
                       var option = []
                       var response = JSON.parse(response.data.data)
                    //    response && response.map(item => {
                    //     option.push({ value: item.dataSource, displayValue: item.dataSource })
                    //    })
                       for(var i=0;i<response.length;i++){
                                      option.push({ value: response[i].dataSource, displayValue: response[i].dataSource })       
                       }
                    //    selfTicketingSystemConfig.elementConfig.options = option
                    let data = {...dataValue}
                    data.options = option
                    setTicketingSystem(data)
                       //setDataValue(option[0].displayValue)
                    }
                    else {
                        // setErrorMessage('Error in fetch Self Ticketing System !!');
                    }
                } catch (err) {
                    // setErrorMessage('Error in fetch Self Ticketing System !!!');
                }
            });

        let savedProjectName=localStorage.getItem('projectname');
        url = config.BOTBUILDER_URL + `/self-contained-tickets/project/` + savedProjectName + `/ticketingsystem/` +dataValue;
        GetCalltoServer(url)
            .then(response => {
                try {
                    if (response.data.status.responseType === "success") {
                        if(response.data.data.length>0){
                             setTextBoxState({ ...textBoxstate, "ticketsNumber1": response.data.data[0].similarTickets, "ticketsNumber2":response.data.data[0].similarResolutions,"ticketsNumber3":response.data.data[0].knowledgeDocs,"scheduleTime":response.data.data[0].scheduleTime});
                             setRequestTypeValue(response.data.data[0].fieldName)
                             setInputField(response.data.data[0].fieldName)
                             setSync(response.data.data[0].sync)
                             setToggle(response.data.data[0].schedule)
                             if(response.data.data[0].sync == "automatic"){
                                setRadioForAutomatically(true)
                                setRadioForCustomFrequency(false)
                             }
                             else{
                                setRadioForAutomatically(false)
                                setRadioForCustomFrequency(true)
                             }
                             if(response.data.data[0].scheduleType == "minutes"){
                                 setScheduleType('minutes')
                             }
                             else{
                                 setScheduleType('hours')
                             }
                        }
                        else{
                                setSync("custom")
                                setRadioForAutomatically(false)
                                setRadioForCustomFrequency(true)
                                setToggle(true)
                                 setTextBoxState({ ...textBoxstate,
                                 ticketsNumber1: 3,
                                 ticketsNumber2: 3,
                                 ticketsNumber3: 3,
                                 scheduleTime: 5,
                                 })
                             }
                     }
                     else {
                         // setErrorMessage('Error in fetch Self Ticketing System !!');
                     }
                } catch (err) {
                    // setErrorMessage('Error in fetch Self Ticketing System !!!');
                }
            });
    }, []);

    const label = { inputProps: { 'aria-label': 'Switch demo' } };
        const GreenSwitch = styled(Switch)(({ theme }) => ({
          '& .MuiSwitch-switchBase.Mui-checked': {
            color: blue[600],
            '&:hover': {
              backgroundColor: alpha(blue[600], theme.palette.action.hoverOpacity),
            },
          },
          '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: blue[600],
          },
        }));

    return (
        <React.Fragment>
            <div className="selfTickets_content" style={{overflow:"hidden"}}>
                <Row sm="12" md="12" lg="12" xl="12" xxl="12">
                <Col sm='6'  md="6" lg="6" xl="6" xxl="6">
                        <h3>Embedded Tickets</h3>
                </Col>
                <Col sm='6'  md="6" lg="6" xl="6" xxl="6">
                        <div style={{position:"relative",float:"right"}}>
                                    <NavbarCustom 
                                        dataList={projectlist}
                                        addImportProjectIcon={false}
                                        setSearchTerm={(item)=>setSearchTerm(item)}
                                        popperShow={popperShow}
                                        popperShowFn={popperShowFn}
                                        searchTerm={searchT}
                                        projectclick={(event,item)=>projectclick(event,item)}
                                        projectname={projectname} 
                                        rightPopUp={false} 
                                        //</div>style={{backgroundColor:"inherit"}}
                                    >
                                    </NavbarCustom>
                        </div>
                </Col>
                </Row>
                <div className="col-md-4 mt-10 inputContainer1">
                    <Form.Label className='form-title'>Self Ticketing System</Form.Label>
                    <Input
                        key="SelctDropDown"
                        elementType="select"
                        elementConfig={ticketingSystem}
                        value={dataValue}
                        changed={(event) => changeDataValue(event)}
                    />
                </div>
                <div className="col-md-12 mt-10 inputContainer1">
                    
                    <Form.Row>
                        <Col xs={4}>
                            <Form.Label className='form-title'>No of Similar Ticket</Form.Label>
                            <Form.Control
                                type="number"
                                className="ticketsNumber"
                                value={ticketsNumber1}
                                onChange={handleTextBoxChange}
                                name="ticketsNumber1" />
                        </Col>
                        <Col xs={4}>
                            <Form.Label className='form-title'>No of relevant resolutions</Form.Label>
                            <Form.Control
                                type="number"
                                className="ticketsNumber"
                                value={ticketsNumber2}
                                onChange={handleTextBoxChange}
                                name="ticketsNumber2" />
                        </Col>
                        <Col xs={4}>
                            <Form.Label className='form-title'>No of relevant Knowledge articles</Form.Label>
                            <Form.Control
                                    type="number"
                                    className="ticketsNumber"
                                    value={ticketsNumber3}
                                    onChange={handleTextBoxChange}
                                    name="ticketsNumber3" />
                            </Col>
                    </Form.Row>
                </div>
                <div className="col-md-4 mt-10 inputContainer1">
                    <Form.Label className='form-title'>Field to input recommendations</Form.Label>
                    <Input
                        key="SelctDropDown"
                        elementType="select"
                       elementConfig={requestTypeOptions}
                        value={inputField}
                        changed={(event) => setInputField(event.target.value)}
                    />
                </div>
                <div className="col-md-4 mt-10 inputContainer1">
                    <Form.Label className='form-title'>Field to attach recommendations</Form.Label>
                    <Input
                        key="SelctDropDown"
                        elementType="select"
                        elementConfig={requestTypeOptions}
                        value={requestTypeValue}
                        changed={(event) => setRequestTypeValue(event.target.value)}
                    />
                </div>

                <div className="col-md-4 mt-10 inputContainer1">
                    <Form.Label className='form-title'>Sync</Form.Label><GreenSwitch size="small" onChange={(e) =>handleToggle(e)} {...label} checked={toggle} />
                    <Form.Row>
                        <Col xs={12}>
                            <Form.Check
                                inline
                                label="Automatically"
                                type="radio"
                                checked={radioForAutomatically}
                                onChange={changeRadioForAutomatically}
                            />
                             <Form.Check
                                inline
                                label="Custom Frequency"
                                type="radio"
                                checked={radioForCustomFrequency}
                                onChange={changeRadioForCustomFrequency}
                            />
                        </Col>
                    </Form.Row>
                    
                </div>

                {/* <hr className="borderLine" ></hr> */}
                <div className="col-md-4 mt-10 inputContainer1" style={{pointerEvents: radioForAutomatically?"none":'',opacity: radioForAutomatically?"0.4":''}}>
                    <Row>
                    <Col md={6}>
                            <Form.Group>
                            <Form.Label className='form-title'>Sync Frequency in</Form.Label>
                                <Input
                                    key="SelctDropDown"
                                    elementType="select"
                                    elementConfig={scheduleTypeConfig.elementConfig}
                                    value={scheduleType}
                                    changed={(event) => setScheduleType(event.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                        <Form.Label className='form-title'>No of Minutes</Form.Label>
                            <Form.Control
                                className="ticketsNumber"
                                value={scheduleTime}
                                type="number"
                                onChange={handleTextBoxChange}
                                name="scheduleTime" />
                        </Col>
                    </Row>
                </div>
                {/* <div className="col-md-5">
                    <Form.Label className='form-title'>Alert on update failures</Form.Label>
                    <Form.Control className="ticketsNumber" placeholder="Enter e-mail" />
                </div> */}
                {/* <button className="selfContaindTicketBtn" onClick={handleSubmit} disabled={true}>
                            <span style={{color:"white",fontSize:"12px",fontFamily: 'Roboto-Bold'}}>SAVE SETTING</span>
                </button> */}
                <ButtonCustom 
                 label='SAVE SETTING'
                 variant='contained'
                 clicked={handleSubmit}
                 className='buttonWidth add-lang-button'
                 disabled={readWriteAccessForTicketsInsights?false:true}/>
            </div>
        </React.Fragment>
    );
};

SelfContainedTickets.propTypes = {
    similarIssuesSelect: PropTypes.object
};

SelfContainedTickets.defaultProps = {
    selfTicketingSystemConfig: {
        elementType: 'select',
        elementConfig: {
            options: [
                { value: 'servicenow', displayValue: 'Service Now' },
                { value: 'others', displayValue: 'Others' },
            ]
        },
        value: '',
        validation: {
            required: true
        },
        valid: false,
        touched: false,
        className: 'selectLang'
    },
    scheduleTypeConfig: {
        elementType: 'select',
        elementConfig: {
            options: [
                { value: 'minutes', displayValue: 'Minutes' },
                { value: 'hours', displayValue: 'Hours' },
            ]
        },
        value: '',
        validation: {
            required: true
        },
        valid: false,
        touched: false,
        className: 'selectLang'
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onchangeProjectAction: (projectname) => dispatch(actions.changeProjectAction(projectname)),
        setSuccessMessage: (message) => dispatch(actions.SetSuccessMessage(message)),
        setErrorMessage: (error) => dispatch(actions.SetErrorMessage(error)),
        saveScheduleDataIntoES: (scheduleObj, apiURL) => dispatch(actions.saveScheduleDataIntoES(scheduleObj, apiURL)),
        scheduleBot: (scheduleBotObj, apiURL) => dispatch(actions.scheduleBot(scheduleBotObj, apiURL)),
        fetchSeltTicketsData: (configObj, apiURL) => dispatch(actions.fetchSeltTicketsData(configObj, apiURL))
    };
};

export default connect(null, mapDispatchToProps)(SelfContainedTickets);