import * as React from 'react';
import Badge from 'react-bootstrap/Badge'
import './BadgeComponent.css';

const BadgeComponent = (props) => {
   return(
    <>
     <Badge className={`badgeClass mr-3 mb-3 ${props.className}`} pill bg="secondary" onClick={()=>props.clicked(props.label)}>
     <span className="closeButton">X</span>
   <span className='badgeSpan'>{props.label}</span>
  </Badge>
  </>
   )
}

export default BadgeComponent;