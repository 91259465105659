import React, { Component } from 'react';
import { connect } from 'react-redux';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import axios from '../../instance';
import * as actions from '../../store/actions/index'
import { Col, Row } from 'react-bootstrap';
import classes from './ProjectViewPage.module.css';
import DeleteModal from "../../components/UIComponents/DeleteModalComponent/DeleteModal";
import TextField from '@material-ui/core/TextField';
import SearchComponent from "../../components/UIComponents/SearchComponent/Search";
import Autocomplete from '@material-ui/lab/Autocomplete';
import SpinnerPage from '../../components/UI/Loader/Loader';
import TableMI from '../ProjectViewPage/Table/TableMI';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import EditorCustom from '../../components/UI/Editor/Editor';
import TabCustom from '../../components/UI/HorizontalTab/HorizontalTab';
import ButtonComponent from "../../components/UIComponents/Button/ButtonComponent";
import './knowledgeTable.css'
import DrawerComponent from '../../components/UIComponents/Drawer/DrawerComponent';
import InputWithLabelComponent from '../../containers/SettingPage/SettingInnerPages/components/InputWithLabelComponent/InputWithLabelComponent';
import permissions from "../../permissions_Json"
import TextAreaWithLabelComponent from '../SettingPage/SettingInnerPages/components/TextAreaWithLabelComponent/TextAreaWithLabelComponent'
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import styled from "styled-components";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import orderBy from 'lodash/orderBy';
import { makeStyles,withStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
const StyledCheckbox2 = styled(Checkbox)`
  svg {
    color: rgb(42, 147, 223);
    font-size: 18px;
  }
  .MuiCheckbox-root:{
    padding:0px;
    marginLeft:9px;
  }
`;

const invertDirection = {
  asc:'desc',
  desc:'asc',
}
let CognitiveAccessToExportandDelete = false;
class KnowledgeTable extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }
    /* FOR DRAWER FUNCTIONNALUTY ONLY*/ 
    altQues
    altque=0
    gridAltQue=null
    gridCol4
    state={
        rightAnchorEl:null,
        rightOpenpopper:false,
        rightPlacement:null,
        iconShowView:false,
        anchorEl:null,
        gridModel1:false,
        gridAltQue:[],
        gridAltQueFlag:false,
        gridAltQueCount:0,
        IsDeleteAll: false,
        SeletedKnowledgeIntents: [],
        selectedCategory: null,
        optionListValues: [],
        gridCol1:null,
        gridCol2:null,
        gridCol3:null,
        updateKnowledgeData:null,
        rowdata:null,
        SpecialCharacterMsg: null,
        /* FOR DRAWER FUNCTIONNALUTY ONLY*/ 
        KnowledgeTabSelected: "ACTIVE FAQS",
        // KnowledgeMacroExpand: true,
        //tabs:['ACTIVE FAQS', 'FAQS WAITING FOR APPROVAL(0)'],//commented due to release 12.1.7
        tabs:['ACTIVE FAQS'],
        tableData:[],
        tableColm:['Question','Comments','Recommended By','Created Date',''],
        sortedColn:'date',
        sortDirection:'desc',
        sortedColm:'topic',
        emptycatSelectedMsg: null,
        catSelected: null,
        UpdatedKnowledgeGridData: null,
        deleteknowledgeIntentShow: false,
        ShowDeleteIndividualfromTable:false,
        IsKnowledgeRowSelected: false,
        catListdata: [],
        fileExistFlag: null,
        existingcatSelected: null,
        existingFilesNames : null,
        existingFilesErrorMsg : null,

        KnowledgeFilevalidation: null,
        knowledgeFile: null,
        knowledgeImportFile: null,
        knoledgeRows: [{}],
        knoledgeReLearnRows: [{}],
        KnowledgeIntentcontrolsDropdown2: {
            flowDesc: {
              elementType: 'input',
              elementConfig: {
                type: 'text',
                placeholder: ''
              },
              value: '',
              className: '',
              readOnly: true,
            },
      
            file: {
              elementType: 'file',
              elementConfig: {
                type: 'text',
                placeholder: ''
              },
              value: '',
              className: ''
            },
            flowName: {
              elementType: 'input',
              elementConfig: {
                type: 'text',
                placeholder: ''
              },
              value: '',
              validation: {
                required: true
              },
              valid: false,
              touched: false,
              className: '',
            },
            id: {
              value: '',
      
            }
          },
        KnowledgeDropdown: {
            elementType: 'select',
            elementConfig: {
              options: [
                //{value:'0',displayValue:'Select Input Mode'},
                { value: 'Upload FAQ document', displayValue: 'Upload FAQ from local' },
                { value: 'Individual questions', displayValue: 'Add Q&A' },
                //{value:'Upload Q&A list(excel)',displayValue:'Upload Q&A list(excel)'},
                //{ value: 'Link folder with multiple documents', displayValue: 'Upload FAQ from external source' },
                //   {value:'*',displayValue:'dropzone'},
              ]
            },
            value: 'Upload FAQ document',
            className: 'selectLang'
          },
        KnowledgeIntentcontrolsDropdown4: {
            KnowledgeSource: '',
            url: '',
          },
          KnowledgeIntentcontrols: {
            id: {
              value: '',
      
            },
            flowDesc: {
              elementType: 'input',
              elementConfig: {
                type: 'text',
                placeholder: ''
              },
              value: '',
              className: '',
              readOnly: false,
            }
            ,
            response: {
              elementType: 'input',
              elementConfig: {
                type: 'text',
                placeholder: ''
              },
              value: '',
              className: '',
              readOnly: false,
            },
      
      
          },

          knowledgeIntentSearch:"",
          show:true,
          columnDefs: [
            {
              headerName: "Question",
              field: "description",
              editable: true,
              tooltipField: "description",
              cellEditor: 'agLargeTextCellEditor',
              cellEditorParams: {
                rows: 4,
                cols: 35
              }
            },
            {
              headerName: "Answer",
              field: "response",
              editable: true,
              tooltipField: "response",
              cellEditor: 'agLargeTextCellEditor',
              cellEditorParams: {
                rows: 5,
                cols: 36
              }
            },
            {
              headerName: "Alternate Questions",
              field: "learn",
              tooltipField: "learn",
              editable: true,
              cellEditor: 'agLargeTextCellEditor',
              cellEditorParams: {
                rows: 5,
                cols: 35
              }
            },
            {
              headerName: "Created Date",
              field: "date",
              editable: true,
              tooltipField: "date",
              cellEditor: 'agLargeTextCellEditor',
              cellEditorParams: {
                rows: 4,
                cols: 35
              }
            },
          ],
          columnDefs2: [
            {
              headerName: "Question",
              field: "description",
              editable: true,
              tooltipField: "description",
              cellEditor: 'agLargeTextCellEditor',
              cellEditorParams: {
                rows: 4,
                cols: 35
              }
            },
            {
              headerName: "Comments",
              field: "response",
              editable: true,
              tooltipField: "response",
              cellEditor: 'agLargeTextCellEditor',
              cellEditorParams: {
                rows: 5,
                cols: 36
              }
            },
            {
              headerName: "Recommended By",
              field: "learn",
              tooltipField: "learn",
              editable: true,
              cellEditor: 'agLargeTextCellEditor',
              cellEditorParams: {
                rows: 5,
                cols: 35
              }
            },
            {
              headerName: "Created Date",
              field: "date",
              editable: true,
              tooltipField: "date",
              cellEditor: 'agLargeTextCellEditor',
              cellEditorParams: {
                rows: 4,
                cols: 35
              }
            },
          ],
          selectedSubKnowledgeTab: "Manual",
          emptyFlowNameMsg: null,
          emptyResponseMsg: null,
          currentBotSelected: "",
          scriptSelected: false,
          ShowRightPanel: false,
          currentFileSelected: null,
          currentAceValue: "",
          scripttouched: false,
          incomingData:null,
          validation: {
            gridCol1: true,
            gridCol2: true,
          },
          saveSetting:true,
          openApprovalDrawer:false,
          rowData:[],
          quesInDrawer:'',
          ansInDrawer:'',
          selectedAltQues:[],
    }
    openApprovalDrawer = (item) =>{
      this.setState({openApprovalDrawer:true, rowData:item, quesInDrawer:item.topic, ansInDrawer:item.answer});
    }
    closeApprovalDrawer = () =>{
      this.setState({openApprovalDrawer:false});
    }
    onChangeQuestion = (e) =>{
      this.setState({quesInDrawer:e.target.value});
    }
    onChangeAnswer = (e) =>{
      this.setState({ansInDrawer:e.target.value});
    }
    changeRadioBtn = (e)=>{
      this.setState({changeRadioBtn:e})
    }
    selectedAltQues = (e,item,index) =>{
      var temp = this.state.selectedAltQues
      if(e.target.checked == true){
        var f=0
        for(var i=0;i<temp.length;i++){
          if(temp[i] == item)
            f=1
        }
        if(f==0)
          temp.push(item)
      }
      if(e.target.checked == false){
        for(var i=0;i<temp.length;i++){
          if(temp[i] == item)
            temp.splice(i,1)
        }
      }
      this.setState({selectedAltQues:temp})
      var str = temp.join('\n')
      this.setState({queSepratedWithSlashN:str})
      console.log("eeeeeee  ===  "+str)
    }
    approveFAQ = () =>{
      var apiUrl = this.props.config.BOTBUILDER_URL + '/smartfaq/qna'
      var data = {
        "description": this.state.quesInDrawer,
        "categoryName": "common",
        "training": {
          "learn": this.state.queSepratedWithSlashN
        },
        "response": this.state.ansInDrawer,
        "isfaq": "faq",
        "intent": this.state.rowData.id,
        "project": localStorage.getItem("projectname"),
        "createdate": this.state.rowData.date,
        "language": localStorage.getItem("language"),
        "orgName": localStorage.getItem("orgName")
      }
      this.props.onApproveFAQ(apiUrl,data)
      this.setState({openApprovalDrawer:false})
    }
    /* FOR DRAWER FUNCTIONNALUTY ONLY*/ 
    GetKnowledgeIntentData = () => {
        let projectName = localStorage.getItem("projectname");
        let language = localStorage.getItem("language");
        if (!language) {
          language = "en";
        }
        var requestObject = { "projectName": projectName, "language": language };
        this.props.onGetKnowledgeIntent(requestObject, this.props.config.BOTBUILDER_URL);
        this.props.OnGetBotList(this.props.config.BOTBUILDER_URL);
        this.props.onGetCategoryList(this.props.config.BOTBUILDER_URL);
      
      }

      handleClick =(data)=> (event)=>{
        this.setState({
          anchorEl:event.currentTarget,
          incomingData:data
        })
      }
      handleMenuClose = ()=>{
        this.setState({
          anchorEl:null
        })
      }

    handleClose = (modaltype) => {
        this.state.SpecialCharacterMsg = null;
        this.state.Cflag = null;
        if (modaltype == "deleteKnowledgeIntent") {
          this.setState({ deleteknowledgeIntentShow: false })
        }if (modaltype == "deleteKnowledgeIntentSingle") {
          this.setState({ ShowDeleteIndividualfromTable: false })
        }
        else if (modaltype == "custommacro") {
          this.setState({ custommacroShow: false })
        }
        else if (modaltype == "displayInfo") {
          this.setState({flowValue:false})
          this.setState({ displayInfoShow: false })
        }
        else if (modaltype == "displayInfo1") {
          this.setState({ displayInfoShow1: false })
        }
        else if(modaltype=="useContent")
        {
           this.setState({ displayUseContent: false }) 
        } 
        else if(modaltype=="gridModelClose")
         {
          this.altque=0
            this.setState({ gridModel1: false,gridAltQue:[],gridAltQueFlag:false}) 
         }
    
        else if (modaltype == "addCategory") {
          this.setState({
            openModal: false,
            dialogValue: {
              title: '',
            },
            ShowCatDeleteIcons: false
          })
        }
        else if (modaltype == "deleteCategory") {
          this.setState({ deleteCatModal: false, })
        }
        else if (modaltype == "fileUploadAlreadyExit") {
          this.setState({ fileUploadCatModal: false, })
          this.state.catSelected = null
          this.state.existingcatSelected = null
          this.state.files = null
          this.state.fileExistFlag = null
        }
      }

    getCurrentDate = () => {
        var currentdate = new Date();
        var dd = currentdate.getDate()
        var mm = (currentdate.getMonth() + 1)
        var yyyy = currentdate.getFullYear();
        var hh = currentdate.getHours()
        var ms = currentdate.getMinutes()
        var sc = currentdate.getSeconds();
    
        if (dd < 10)
          dd = '0' + dd;
    
        if (mm < 10)
          mm = '0' + mm;
    
        if (hh < 10)
          hh = '0' + hh;
    
        if (ms < 10)
          ms = '0' + ms;
    
        if (sc < 10)
          sc = '0' + sc;
    
        var currentdate = yyyy + '/' + mm + '/' + dd + ' ' + hh + ':' + ms + ':' + sc;
        return currentdate;
      }




     DeleteIntent = (event) => {
        event.preventDefault();
        let projectname = localStorage.getItem("projectname");
        let orgName = localStorage.getItem("orgName");
       
        let datasourcename = "optimus";
        let optimusCollection = '';
        if (datasourcename == "optimus" && orgName != "default") {
          optimusCollection = datasourcename + "_" + orgName;
        }
        else {
          optimusCollection = datasourcename;
        }
      
        // Delete all records 
     
        if (this.state.IsDeleteAll) {
          let data = {
            "project": projectname,
            "language": localStorage.getItem("language"),
            "intentFile": this.state.SeletedKnowledgeIntents,
            "type": "faq",
            "action": "multidelete",
            "collection": optimusCollection,
            "category":this.state.selectedCategory,
            "orgName": localStorage.getItem("orgName")
          }
          this.setState({SeletedKnowledgeIntents:[]})
          this.setState({IsDeleteAll:false})
          this.props.onDeleteMultiKnowledgeIntent(this.props.config.BOTBUILDER_URL, data,true)
          this.setState({selectedCategory:this.state.optionListValues[0]})
          setTimeout(() => {
            this.setState({gridModel1:false})
          this.GetKnowledgeIntentData();
          }, 2000);
        }
        else if (this.state.SeletedKnowledgeIntents.length > 0) {
          let data = {
            "project": projectname,
            "language":localStorage.getItem("language"),
            "intentFile": this.state.SeletedKnowledgeIntents,
            "type": "faq",
            "action": "multidelete",
            "collection": optimusCollection,
                "category":this.state.selectedCategory,
            "orgName": localStorage.getItem("orgName")
          }
          
          this.setState({SeletedKnowledgeIntents:[],ShowDeleteIndividualfromTable:false,})
          this.props.onDeleteMultiKnowledgeIntent(this.props.config.BOTBUILDER_URL, data, false)
          this.setState({selectedCategory:this.state.selectedCategory})
          setTimeout(() => {
            this.setState({gridModel1:false})
          this.GetKnowledgeIntentData();
          }, 2000);
        }
      }

  updateKnowledgeIntent=(event)=>{
		//abhas
    event.preventDefault();
    this.setState({
        ...this.state,
        validation: {
          gridCol1: this.state.gridCol1 ? true : false,
          gridCol2: this.state.gridCol2 ? true : false,
        }
    })
    let language = localStorage.getItem("language");
    if (!language) {
      language = "en";
    }
    if(this.state.gridCol1 && this.state.gridCol2) { 
      let learn = this.state.gridCol1+"\n";
        for(let x in this.state.gridAltQue){
            learn += this.state.gridAltQue[x]+"\n";
        }
      this.altque=0
        this.setState({gridAltQueFlag:false})
          this.setState({gridAltQue:[]})
     
      console.log("learn --",learn);
        this.state.updateKnowledgeData = [{
          "projectName": localStorage.getItem("projectname"), "language": language,
          "data": JSON.stringify({
            "id": this.state.rowdata.id, "description": this.state.gridCol1, "response": this.state.gridCol2, "training": { "learn": learn, "relearn": this.state.rowdata.relearn },
            "isfaq": "faq", "intent": this.state.rowdata.intent, "createdate": this.getCurrentDate()
          }),
          "collection": "optimus",
          "project": localStorage.getItem("projectname"),
          "documentId": this.state.rowdata.recordId,
          "orgName": localStorage.getItem("orgName")
        }]
        this.setState({gridModel1:false})
        this.props.onUpdateAllKnowledgeIntent(this.state.updateKnowledgeData, this.props.config.BOTBUILDER_URL, false);
        
        setTimeout(() => {
          this.setState({selectedCategory:this.state.selectedCategory})
        this.GetKnowledgeIntentData();
        }, 2000);
      }
    }

    deletegridAltQues = (index) => {
        const rows = [...this.state.gridAltQue]
        //console.log("row = ",rows)
        console.log("row length = ",this.state.gridAltQueCount)
        console.log("index = ",index)
        
          if (this.state.gridAltQueCount<= index) {
          console.log("if")
          rows.splice(index, 1)
          this.altque = 0
          this.state.gridAltQueFlag = false
          this.setState({ gridAltQue: rows })
        }
        else {
          console.log("else")
          let data = {
            "project": localStorage.getItem("projectname"),
            "id": this.state.rowdata.recordId,
            "altQuestion": rows[index],
            "collectionName": "optimus",
            "language": localStorage.getItem("language")
          }
          this.props.ondeleteAltQuesForFAQ(this.props.config.BOTBUILDER_URL, data);
          
          rows.splice(index, 1)
            this.altque = 0
            this.state.gridAltQueFlag = false
            //this.setState({ gridAltQue: rows })
            this.state.gridAltQue=rows
            this.state.gridAltQueCount=this.state.gridAltQueCount-1
            console.log("gridAltQue ",this.state.gridAltQue)
            console.log("rows",rows)
            setTimeout(() => {
              this.GetKnowledgeIntentData()
            }, 1000);
            
        }
        this.setState({saveSetting: false})
       
    
        }

     onChangeCol1 = (e) => {
       // this.setState({gridCol1: e.target.value})
        this.altque=0
        this.setState({
          ...this.state,
          gridCol1: e.target.value,
          validation: {
               ...this.state.validation,
              gridCol1: this.state.gridCol1 ? true : false
          },
          saveSetting: false
      })
    }
     onChangeCol2 = (e) => {
        //this.setState({gridCol2: e.target.value})
        this.altque=0
        this.setState({
          ...this.state,
          gridCol2: e.target.value,
          validation: {
               ...this.state.validation,
              gridCol2: this.state.gridCol2 ? true : false
          },
          saveSetting: false
      })
    }
     handlegridAltQue = (e) => {
        e.preventDefault()
        this.state.gridAltQue.push("")
        this.altque=0
        this.setState({gridAltQue:this.state.gridAltQue})
      }
     onChangeCol3 = (e) => {
        this.altque=0
        this.gridAltQue=e.target.name
         this.setState({gridAltQueFlag:true})
         this.gridCol4= e.target.value
         this.setState({saveSetting:false})
      }

      
     ClearKnowledgeIntentState = () => {
    this.setState({ KnowledgeFilevalidation: null });
    this.setState({
      knowledgeFile: null,
      knowledgeImportFile: null,
      knoledgeRows: [{}],
      knoledgeReLearnRows: [{}],
    });
    this.setState({
      KnowledgeIntentcontrolsDropdown2: {
        ...this.state.KnowledgeIntentcontrolsDropdown2,
        flowName: {
          ...this.state.KnowledgeIntentcontrolsDropdown2.flowName,
          value: ''
        },
        flowDesc: {
          ...this.state.KnowledgeIntentcontrolsDropdown2.flowDesc,
          value: ''
        }
      },
      KnowledgeDropdown: {
        ...this.state.KnowledgeDropdown,
       // value: 'Upload FAQ document'
      }
    })
    this.setState({
      KnowledgeIntentcontrolsDropdown4: {
        ...this.state.KnowledgeIntentcontrolsDropdown4,
        KnowledgeSource: '',
        url: ''
      },
      KnowledgeDropdown: {
        ...this.state.KnowledgeDropdown,
        //value: 'Upload FAQ document'
      }
    })
    const updatedControls = {
      ...this.state.KnowledgeIntentcontrols,
      /*flowName: {
          ...this.state.KnowledgeIntentcontrols.flowName,
          value: '',
          defaultFocus:true               
      },*/
      flowDesc: {
        ...this.state.KnowledgeIntentcontrols.flowDesc,
        value: '',
      },
      response: {
        ...this.state.KnowledgeIntentcontrols.response,
        value: '',
      },
      // file: {
      //     ...this.state.KnowledgeIntentcontrols.file,
      //     value: '',               
      // },
    };
    this.setState({ KnowledgeIntentcontrols: updatedControls, });        
     }
     handleSort = (columnName) =>{
      if(columnName == 'Question'){
        this.setState({sortedColm:'topic'})
      }
      else if(columnName == 'Recommended By'){
          this.setState({sortedColm:'RecommendedBy'})
      }
      else if(columnName == 'Created Date'){
          this.setState({sortedColm:'date'})
      }
      this.setState({columnToSort:columnName})
      if(this.state.columnToSort == columnName)
          this.setState({sortDirection:invertDirection[this.state.sortDirection]})
      else
          this.setState({sortDirection:'asc'})
    }
    componentDidMount() {
        this.setState({changeRadioBtn:"newIntentRadio"})
        this.setState({ ShowDeleteIcons: false, SeletedKnowledgeIntents: null, selectedCategory: 'All Categories' })
        this.GetKnowledgeIntentData();
        this.ClearKnowledgeIntentState();
        let obj = this.props.waitinForApprovalData
          let value 
          obj && Object.keys(obj).forEach(function(key){
            if(key.includes("Created")){
              value = obj[key];
              console.log(key + ':' + JSON.stringify(value));
            }         
          });
          this.setState({tableData:value}) 
          console.log("table value == ",value)
          //var tab=['ACTIVE FAQS','FAQS WAITING FOR APPROVAL('+value.length+')']//commented due to release 12.1.7
          var tab=['ACTIVE FAQS']
          this.setState({tabs:tab}) 
      }

      componentDidUpdate(prevprops) {
       
        if(this.props.waitinForApprovalData && prevprops.waitinForApprovalData !== this.props.waitinForApprovalData){
          let obj = this.props.waitinForApprovalData
          let value 
          obj && Object.keys(obj).forEach(function(key){
            if(key.includes("Created")){
              value = obj[key];
              console.log(key + ':' + JSON.stringify(value));
            }           
          });
          this.setState({tableData:value})
          console.log("table value == ",value)
          //var tab=['ACTIVE FAQS','FAQS WAITING FOR APPROVAL('+value.length+')'] //commented due to release 12.1.7
          var tab=['ACTIVE FAQS']
          this.setState({tabs:tab}) 
        }

        if (this.state.gridModel1 == false) {
          this.state.gridCol1 = null
          this.state.gridCol2 = null
          this.state.gridCol3 = null
          this.gridCol4 = null
          this.state.gridAltQue = []
          this.state.gridAltQueCount=0
        }
        if (this.state.gridCol3) {
          this.altque = 0
        }
        if (!this.gridCol4 && this.state.gridCol3) {
          //this.state.gridAltQueCount=this.state.gridAltQue.length
          //this.state.gridAltQueCount = 0
          // this.state.gridAltQue=this.state.gridCol3.split(/\r?\n/)
          // this.state.gridAltQue.pop()
        }
        if (this.state.KnowledgeTabSelected == "ACTIVE FAQS") {
          this.state.catSelected = null;
          this.state.emptycatSelectedMsg = null;
        }
        if (this.state.prvProj != localStorage.getItem("projectname")) {
          this.state.selectedCategory = 'All Categories';
          this.state.catSelected = null;
          this.state.prvProj = localStorage.getItem("projectname");
        }
        if (this.props.updateKnowledgeIntentLoading && this.props.updateKnowledgeIntentLoading !== prevprops.updateKnowledgeIntentLoading) {
          this.ClearKnowledgeIntentState();
          this.setState({ knoledgeRows: [{}] });
          this.setState({
            knoledgeReLearnRows: [{}],
            UpdatedKnowledgeGridData: null
          });
          setTimeout(() => {
            //this.GetKnowledgeIntentData();
          }, 2000);
    
        }
    
        if (this.props.pageload !== prevprops.prevprops && this.props.pageload === true) {
          this.ClearKnowledgeIntentState();
          this.setState({ knoledgeRows: [{}] });
          this.setState({ knoledgeReLearnRows: [{}] });
          this.setState({ KnowledgeTabSelected: "ACTIVE FAQS" });
         
          this.setState({SeletedKnowledgeIntents:[]})
          this.GetKnowledgeIntentData();
          
        }
        
        if (this.props.importKnowledgeIntent && this.props.importKnowledgeIntent !== prevprops.importKnowledgeIntent) {
          this.ClearKnowledgeIntentState();
          this.setState({SeletedKnowledgeIntents:[]})
          this.GetKnowledgeIntentData();
    
        }
        if (this.props.knowledgePattern && this.props.knowledgePattern !== prevprops.knowledgePattern) {
          this.ClearKnowledgeIntentState();
          setTimeout(() => {
            this.setState({SeletedKnowledgeIntents:[]})
            this.GetKnowledgeIntentData();
    
          }, 2000);
    
    
        }
    
        if (this.state.selectedCategory!=null && this.props.delknowledgeIntentInprogress !== prevprops.delknowledgeIntentInprogress && prevprops.delknowledgeIntentInprogress == true) {
          this.handleClose('deleteKnowledgeIntent');
          this.ClearKnowledgeIntentState();
          setTimeout(() => {
            this.setState({ IsKnowledgeRowSelected: false });
            this.GetKnowledgeIntentData();
          }, 1000);
    
        }
    
        if (this.props.uploadloading !== prevprops.uploadloading && !this.props.uploadloading) {
          if (this.props.knowledgeUploadfailure == '') {
            this.setState({ showFiles: true, catSelected: null });
          }
          else if (this.props.knowledgeUpload == '') {
            this.setState({ showFiles: false, failure: true, catSelected: this.state.catSelected });
          }
        }
        if (this.props.catList && this.props.catList !== prevprops.catList) {
          let categorySearchResultArray = []
          let options = []
            options.push("All Categories")
        
            for (var {category: c } of this.props.catList) {
            var elem = new Object();
            elem['title'] = c;
            categorySearchResultArray.push(elem);
            options.push(c)
    
          }
          this.setState({
            catListdata: categorySearchResultArray,
            optionListValues: options
          });
    
        }
        if (this.state.fileExistFlag && this.props.knowledgeUpload && typeof this.props.knowledgeUpload.data !== 'undefined' && this.props.knowledgeUpload.data.includes('Do you wish to override ?')) {
          if (this.state.catSelected) {
            this.state.existingcatSelected = this.state.catSelected
            this.state.existingFilesNames = this.props.knowledgeUpload.existingFiles
            this.state.existingFilesErrorMsg = this.props.knowledgeUpload.data
            this.state.fileUploadCatModal = true
          }
        }
      }

/* FOR DRAWER FUNCTIONNALUTY ONLY*/ 

    inputCategoryHandler = (event, catName) => {
    this.setState({
      selectedCategory: catName
    })
    this.setState({ knowledgeIntentSearch: '' });
    if(catName =="All Categories"){
        let projectName=localStorage.getItem("projectname");
	   
      
		let  language=localStorage.getItem("language");
		 if(!language){
        language="en";
     }
     var requestObject = { "projectName": projectName, "language": language };
    this.props.onGetKnowledgeIntent(requestObject,this.props.config.BOTBUILDER_URL);
    }
	else if(catName != null && catName != "" ){
		let projectName=localStorage.getItem("projectname");
	    let orgName=localStorage.getItem('orgName');
        let selectedProject = '';
		let datasourcename = 'optimus';
        if(datasourcename == "optimus" && orgName!="default"){
        selectedProject = "optimus_"+orgName+"/_doc";
        }
        else {
            selectedProject = "optimus/_doc";
        }
	   
        
    
		let  language=localStorage.getItem("language");
		 if(!language){
        language="en";
     }
     var requestObject = { "projectName": projectName, "language": language };
      this.props.onGetKnowledgeIntent(requestObject, this.props.config.BOTBUILDER_URL);
    } else {
      let projectName = localStorage.getItem("projectname");
      let language = localStorage.getItem("language");
      if (!language) {
        language = "en";
      }
      var requestObject = { "projectName": projectName, "language": language };
      this.props.onGetKnowledgeIntent(requestObject, this.props.config.BOTBUILDER_URL);
    }
    }

    onQuickFilterChanged = (event) => {
        this.setState({ knowledgeIntentSearch: event.target.value });

        if(event.target.value!="" ){
          this.setState({show:false})

        }
        else{
          this.setState({show:true})
        }
      };

      // showIconView=()=>{
      //   console.log("inside sjoe")
      //   this.setState({iconShowView:false})
      // }


      ExportIntent = () => {
        let projectname = localStorage.getItem("projectname");
        let orgName = localStorage.getItem('orgName');
         let languageName = localStorage.getItem('language');
    
        this.props.onExportKnowledgeIntent(this.props.config.BOTBUILDER_URL, projectname,languageName, orgName)
        this.setState({anchorEl:null, rightOpenpopper:false,})
    }

      ShowDeleteIntentModal = () => {
        this.setState({gridCol1:null,gridCol2:null,gridCol3:null,gridAltQue:[]})
        this.gridCol4=null
       
       this.setState({IsDeleteAll:true})
        let data = this.props.knowledgeIntents;
       
        this.setState({SeletedKnowledgeIntents: data.map(i => i._source&&i._source.intent + '.txt'),deleteknowledgeIntentShow : true})
        this.setState({anchorEl:null, rightOpenpopper:false,})
      }

      ShowDeleteIndividualItem=(data11)=>{
        this.state.rowdata=data11;
        this.setState({
            ShowDeleteIndividualfromTable:true,
            SeletedKnowledgeIntents: [this.state.rowdata.intent + '.txt'],
            IsDeleteAll:false,
          });
          this.setState({anchorEl:null})
      }
      onGridRowClicked=(data)=>{
        this.setState({saveSetting:true});
        this.state.rowdata=data;
        if(this.state.rowdata && this.state.rowdata!=undefined)
        {
          this.altque=0
            
            this.setState({gridModel1:true,
              gridCol1:this.state.rowdata.description,
              gridCol2:this.state.rowdata.response,
              gridCol3:this.state.rowdata.learn,
              SeletedKnowledgeIntents: [this.state.rowdata.intent + '.txt']
            })
            
            this.setState({IsKnowledgeRowSelected:true, selectedSubKnowledgeTab:"Manual",emptyFlowNameMsg:null,emptyResponseMsg:null});
            this.setState({
                KnowledgeDropdown:{
                    ...this.state.KnowledgeDropdown,
                    value:"Individual questions"
                }
            })
            
              this.setState({gridAltQue:[]})
                this.state.gridAltQue=this.state.rowdata.learn.split(/\r?\n/)
                this.state.gridAltQue.pop()
		this.state.gridAltQueCount=this.state.gridAltQue.length
                this.setState({gridAltQue:this.state.gridAltQue})
                
            // }
        
        this.setState({anchorEl:null})
        } 
    }
    displayData=() => {
      this.setState({openApprovalDrawer:true});
    }

    setSelected = (tab) => {
      this.setState({ KnowledgeTabSelected: tab });
      if (tab === " ACTIVE FAQS") {
        this.setState({ ShowDeleteIcons: false, SeletedKnowledgeIntents: null, selectedCategory: this.state.optionListValues[0] })
        this.state.selectedCategory=this.state.optionListValues[0]
        this.GetKnowledgeIntentData();
      }else{
        this.setState({ ShowDeleteIcons: false, SeletedKnowledgeIntents: null, selectedCategory: this.state.optionListValues[0] })
        this.state.selectedCategory=this.state.optionListValues[0]
        this.GetKnowledgeIntentData();
      }
      //   else if (tab == "Script") {
          
      //     this.setState({ currentBotSelected: "faq" });
      //     this.setState({ "scriptSelected": true, "ShowRightPanel": false, "currentFileSelected": null })
          
          
      //     this.props.openJSFile("bot.js", true)
      //     this.setState({ currentAceValue: "", scripttouched: false })
      // }
    }

    onChangeAce(newValue) {
       this.setState({currentAceValue:newValue,scripttouched:true})   
    } 

    getformatedDate = (existingDate)=> {
      const date = new Date(existingDate);
          // Extract day, month, and year components from the Date object
          const day = date.getDate().toString().padStart(2, "0"); // Add leading zero if needed
          const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
          const year = date.getFullYear();
          const formattedDate = `${day}-${month}-${year}`;
          return formattedDate;
    }

  
   saveBotProfile = () => {
    let projectname = localStorage.getItem("projectname");
    if (!projectname)
      projectname = "common";
    let data = {
      "path": this.state.currentBotSelected + '//' + 'bot.js',
     // "data": encodeURIComponent(this.botEditor.editor.getValue()),
      "data": this.botEditor.editor.getValue(),
      "project": projectname,
      "language": "en",
      "botName":this.state.currentBotSelected
    }
    this.props.onsaveBotProfile(data, this.props.config.BOTBUILDER_URL)
  }

  handleClickSetting=()=>{
    this.setState({
      rightOpenpopper:true,
    })
  }
  handleSettingsMenuClose=()=>{
    this.setState({
      rightOpenpopper:false,
    })
  }
  ClearKnowledgeIntentSearch = ()=>{
    this.setState({knowledgeIntentSearch:"",show:true});
  }

  render() {

  let cognitiveRWtoExportandDelete = this.props.permissions.find(data => {
    if (data.permission_code.includes(permissions.permissionCode.COGNITIVE_SEARCH_KU_RW) && data.sku_code.includes(permissions.skuCode.CS))
      return true
  })
  CognitiveAccessToExportandDelete = cognitiveRWtoExportandDelete ? true : false


    let loadingImportKnowledgeIntent = null;
    if (this.props.knowledgeImportloading) { 
    loadingImportKnowledgeIntent = (<SpinnerPage />); 
    }else { 
    loadingImportKnowledgeIntent = null; 
    }

    let loadingGetKnowledgeIntent = null;
    if (this.props.GetKnowledgeloading){
    loadingGetKnowledgeIntent = (<SpinnerPage />);
    }else { 
    loadingGetKnowledgeIntent = null; 
    }

    let loadingUpdateIntentImg = null;
    if (this.props.updateKnowledgeIntentLoading) {
      loadingUpdateIntentImg = (<SpinnerPage />)
    }else {
      loadingUpdateIntentImg = null;
    }

    let loadingKnowledgeIntentImg = null;
    if (this.props.delknowledgeIntentInprogress) {
      loadingKnowledgeIntentImg = (<SpinnerPage />)
    }
    else {
      loadingKnowledgeIntentImg = null;
    }

    let smartFaqSearchResultArray = [];
  
    if (this.props.knowledgeIntents) {
      let data = []
      if (this.state.selectedCategory == 'All Categories') {
        data = this.props.knowledgeIntents;
      }
      else if (this.state.selectedCategory != 'All Categories') {
        data = this.props.knowledgeIntents.filter(item => item.categoryName == this.state.selectedCategory);
      }
      
      data = data && data !== undefined  ? data : null;
      if (data && data.length > 0) {
        for (var i = 0; i < data.length; i++) {
          //Learn data push
          if (data[i].training && data[i].training.learn) {
            if (data[i].training.learn) {
              let dataRow = "";
              var learnData = data[i].training.learn;
              var desc = data[i].description;
              const rows = learnData.split("\n");
              if(rows[0] != null && desc != null && (rows[0].trim() == desc.trim())){
                rows.shift();
              }
              rows.forEach(function (row) {
                if (row != null && (row.trim() !== "") && (row.trim() !== "undefined"))
                  dataRow += row + "\n";
              })
              data[i].learn = dataRow;
            }
            //data[i]._source.learn=data[i]._source.training.learn.toString()
          }
          else {
            data[i].learn = "";
          }
          // Re Learn rows
          if (data[i] && data[i].training && data[i].training.relearn) {
            data[i].relearn = data[i].training.relearn
          }
          else {
            data[i].relearn = "";
          }
          data[i].id = data[i]._id;
          if(data[i].createdate != null){
            const existingDate = data[i].createdate;
            data[i].date =  this.getformatedDate(existingDate);
          }
          smartFaqSearchResultArray.push(data[i]);
        }
      }
    }
    if (this.props.GetKnowledgeloading) {
      smartFaqSearchResultArray = [];
    
    }
    let scriptContent = null;
    if (this.state.scripttouched) {
      scriptContent = this.state.currentAceValue;
    } else {
      scriptContent = this.state.scriptValue
        ? this.state.scriptValue
        : this.props.scriptContent;
    }

    
    let filteredSearchResult = smartFaqSearchResultArray && smartFaqSearchResultArray.filter((item)=>{
      return item.description != null && item.description.toLowerCase().includes(this.state.knowledgeIntentSearch&&this.state.knowledgeIntentSearch.toLowerCase())||
      item.response != null && item.response.toLowerCase().includes(this.state.knowledgeIntentSearch&&this.state.knowledgeIntentSearch.toLowerCase())||
      item.learn != null && item.learn.toLowerCase().includes(this.state.knowledgeIntentSearch&&this.state.knowledgeIntentSearch.toLowerCase())||
      item.date != null && item.date.toLowerCase().includes(this.state.knowledgeIntentSearch.toLowerCase())
     })

    let knowledgeTabContent = null;
    knowledgeTabContent = <>
    {this.state.KnowledgeTabSelected==='ACTIVE FAQS' &&
      <Row className="no-gutters ">
        <Col md={12} className={classes.KnowledgeDropdownDiv1}>
              <Col md={3} className="viewing_knowledge">
                <span className='viewing-text'>Viewing</span>
                <Autocomplete
                  value={this.state.selectedCategory}
                  onChange={(event, newValue) => {
                    this.inputCategoryHandler(event, newValue)
                  }}
                  id="faq-cats-id"
                  selectOnFocus={false}
                  className={classes.autoCompleteTagKT}
                  options={this.state.optionListValues}
                  renderInput={(params) => <TextField {...params} 
                    InputProps={{ ...params.InputProps, disableUnderline: true,readOnly:true}} />}
                />
              </Col>
              <Col md={3}>
                <div className=''>
                  <SearchComponent 
                  class={"input-group searchClass"} 
                  Clear={this.ClearKnowledgeIntentSearch} 
                  show ={this.state.show} 
                  value={this.state.knowledgeIntentSearch} 
                  onSearch={(event) =>{this.onQuickFilterChanged(event)} } />
                 
                </div>
              </Col>
            </Col>
          {smartFaqSearchResultArray.length > 0 && <>
            <Col md={12} className={classes.categoryTitleFaq}>
              <h4><span style={{color:'#2A93DF'}}>{filteredSearchResult.length} {filteredSearchResult.length==1?"Question":"Questions"} </span> available.
                <span><img ref={this.myRef} src='./Icons/settingsFaq.png' onClick={() => this.handleClickSetting()}
                  style={{ position: 'relative', left: '4px', cursor: 'pointer' }} />
                </span></h4>
            </Col>
          </>}
          <Col className={classes.knowledgeContentLeftSection} md={12}>
            <Row className="no-gutters">
              {smartFaqSearchResultArray && smartFaqSearchResultArray.length > 0 ? '' : <>{!this.props.GetKnowledgeloading && <div className='w-100 no-data-container'>
                <div className="d-flex justify-content-center">
                  <div className={'no-data-text'}>FAQ’s are not added </div>
                </div>
                {this.props.docsUploadAccess && <div className="d-flex justify-content-center">
                  <div className={'no-data-add-faq'} onClick={() => this.props.openFaqDocumentDrawer()}>Add FAQ Document</div>
                </div>}

              </div>}</>}
              <div >
                {loadingImportKnowledgeIntent}
              </div>
                <div className="faq-wrapper">
                  <div id="myGrid" className="ag-theme-alpine pt-1" >
                    <div className={classes.KnowledgeGridLoader} >
                      {loadingGetKnowledgeIntent}
                      {loadingUpdateIntentImg}
                    </div>
                    
                  
                    <TableMI 
                    columnDefs={this.state.columnDefs}
                    onGridReady={this.onGridReady}
                    smartFaqSearchResultArray={this.state.knowledgeIntentSearch.length>0?smartFaqSearchResultArray.filter((item)=>{
                     return item.description.toLowerCase().includes(this.state.knowledgeIntentSearch.toLowerCase())||
                      item.response.toLowerCase().includes(this.state.knowledgeIntentSearch.toLowerCase())||
                      item.learn.toLowerCase().includes(this.state.knowledgeIntentSearch.toLowerCase())||
                      item.date.toLowerCase().includes(this.state.knowledgeIntentSearch.toLowerCase())
                    }):smartFaqSearchResultArray}
                    onGridRowClicked={(data)=>this.onGridRowClicked(data)}
                    // exportData={() => this.ExportIntent()}
                    deleteData={(data) => this.ShowDeleteIndividualItem(data)}
                    handleClick={(event)=> this.handleClick(event)}
                    incomingData={this.state.incomingData}
                    handleMenuClose = {this.handleMenuClose}
                    editData={(data)=>this.onGridRowClicked(data)}
                    // faqDetails = {(data)=> this.displayData(data)}
                    anchorElement={this.state.anchorEl}
                    menuID="faqEditDropdown"
                    tab={this.state.KnowledgeTabSelected }
                    // showIconView={()=>this.showIconView()}
                    />
                    
                   
                    {/* <AgGridCustom
                      columnDefs={this.state.columnDefs}
                      defaultColDef={this.state.defaultColDef}
                      suppressRowClickSelection={true}
                      rowSelection={this.state.rowSelection}
                      onGridReady={this.onGridReady}
                      onRowClicked={this.onGridRowClicked}
                      GridData={smartFaqSearchResultArray}
                      pagination={true}
                      gridrowselection={this.OnGridRowSelection}
                      paginationAutoPageSize={true}
                      editType={this.state.editType}
                      components={this.state.components}
                      onCellEditingStopped={this.oncellEditingStopped}
                    //rowHeight={this.state.rowHeight}
                    >
                    </AgGridCustom> */}
                  </div>
                  
              </div>
            </Row>
          </Col>
      </Row>} 
      {this.state.KnowledgeTabSelected === "FAQS WAITING FOR APPROVAL(" +(this.state.tableData ? this.state.tableData.length : 0) + ")" &&
        <React.Fragment>
              <div className={classes.KnowledgeDropdownDiv1} style={{float:"left",width:"250px"}}>
                <h4 style={{position:"relative", top:"10px"}}><span style={{color:'#2A93DF', marginLeft:"15px"}}>{this.state.tableData.length} {this.state.tableData.length==1?"Question":"Questions"} </span> available.</h4>
              </div>
              <div className={classes.KnowledgeDropdownDiv1} style={{float:"right",width:"250px"}}>
                      <SearchComponent 
                      class={"input-group searchClass"} 
                      Clear={this.ClearKnowledgeIntentSearch} 
                      show ={this.state.show} 
                      value={this.state.knowledgeIntentSearch} 
                      onSearch={(event) =>{this.onQuickFilterChanged(event)} } />
              </div> 
              <div style={{paddingLeft:"15px", paddingRight:"45px"}}>
              <TableContainer className="tableCont">
                            <Table aria-label="simple table" stickyHeader >
                                <TableHead className='multi-header'>
                                    <TableRow >
                                        {this.state.tableColm != undefined && this.state.tableColm !== null ? this.state.tableColm.map((headerName) => {
                                            return (
                                                <>
                                                    <TableCell className="table1H" onClick={() => this.handleSort(headerName)} style={{backgroundColor:"#7D7D7D",color:"#ffffff"}}>
                                                        {headerName} {headerName != "" ? this.state.sortDirection === 'asc' ? <ArrowUpwardIcon className="iconStyle"/> : <ArrowDownwardIcon className="iconStyle"/>:''}
                                                    </TableCell>
                                                </>
                                            )
                                        }) : null}
                                        <TableCell className="table1H" style={{backgroundColor:"#7D7D7D"}}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody >
                                    {this.state.tableData != undefined && this.state.tableData !== null ?
                                        // orderBy(this.state.tableData, ['topic'], this.state.sortDirection
                                        ('topic' == this.state.sortedColm ? orderBy(this.state.tableData, [(user) =>user.topic.toLowerCase().trim()], this.state.sortDirection) :
                                        ('recommendedBy' == this.state.sortedColm ? orderBy(this.state.tableData, [(user) => user.recommendedBy.toLowerCase()], this.state.sortDirection):
                                        ('date' == this.state.sortedColm ? orderBy(this.state.tableData, [(user) => user.date.toLowerCase()], this.state.sortDirection):
                                        orderBy(this.state.tableData, [(user) =>user.topic.toLowerCase().trim()], this.state.sortDirection)))
                                        ).filter((item)=>{
                                            return true
                                        }).filter((value) =>{
                                            return (value.topic != null && value.topic.toLowerCase().includes(this.state.knowledgeIntentSearch.toLowerCase())) ||
                                                (value.comments != null && value.comments.toLowerCase().includes(this.state.knowledgeIntentSearch.toLowerCase())) ||
                                                (value.dates != null && value.dates.toLowerCase().includes(this.state.knowledgeIntentSearch.toLowerCase()))
                                        }).map((item,index) => {
                                            return (
                                                <>
                                                    <TableRow key={item.id} >
                                                        <LightTooltip arrow title={item.topic} placement="top-start">
                                                          <TableCell className='table1C'>
                                                            {item.topic}
                                                          </TableCell>
                                                        </LightTooltip>
                                                        <LightTooltip arrow title={item.comment} placement="top-start">
                                                          <TableCell className='table1C'>
                                                              {item.comment && item.comment.length > 50 ? item.comment.slice(0, 50) + '...' : item.comment}
                                                          </TableCell>
                                                        </LightTooltip> 
                                                        <LightTooltip arrow title={localStorage.getItem('userName')} placement="top-start">                                                                      
                                                            <TableCell className='table1C'>
                                                                {localStorage.getItem('userName')}
                                                            </TableCell>
                                                        </LightTooltip>

                                                        <LightTooltip arrow title={item.date} placement="top-start">                                                                      
                                                            <TableCell className='table1C'>
                                                                {item.date}
                                                            </TableCell>
                                                        </LightTooltip>
                                                        
                                                        <TableCell className='table1C'>
                                                            <span onClick={()=>this.openApprovalDrawer(item)} style={{cursor:"pointer"}}><KeyboardArrowRightIcon></KeyboardArrowRightIcon></span>
                                                        </TableCell>
                                                    </TableRow>
                                                </>
                                            )
                                        }) : null}
                                </TableBody>
                            </Table>
                        </TableContainer>
              </div>
        </React.Fragment>
      }
    {this.state.KnowledgeTabSelected === "Script" && 
    <Row>
    <EditorCustom
      names="botEditor"
      refer={instance => { this.botEditor = instance; }}
      changed={(e) => this.onChangeAce(e)}
      value={scriptContent}
    />
    <div className={classes.BtnAlignment}>
      {this.props.scriptLoadingImg}
    </div>
    <div className={classes.BtnAlignmentofFaqScript}>
      {/* <ButtonCustom btnType="blueButton" clicked={this.saveBotProfile}>Save</ButtonCustom> */}
      <ButtonComponent
                  variant='contained'
                  className='buttonWidth'
                  label={'Save'}
                  clicked={this.saveBotProfile}
                  btnType="blueButton" />
    </div>
    </Row>
  }
  </>
    return (
        <>
      <Col>
        <div className={[classes.topSpace, classes.ProjectTabContentKnowledge].join(' ')}>
          {/* <h3 style={{position:'relative',top:'10px',left:'15px'}}>FAQ</h3>
          <h3 style={{position:'relative',top:'10px',left:'15px',cursor:'pointer'}} onClick={this.setSelected}>Script</h3> */}
          <TabCustom tabs={this.state.tabs} tabheader={"KnowledgeIntents"} selected={this.state.KnowledgeTabSelected} setSelected={this.setSelected}
          tabCustomClass={classes.tabCustomClassFaq}>
            <div className={[classes.topSpace, classes.ProjectTabContentKnowledge].join(' ')}>{knowledgeTabContent}</div>
          </TabCustom>
          
         </div>
      </Col>

        <div>
          {['right'].map((anchor) => (
            <React.Fragment key={anchor}>
              <DrawerComponent heading="FAQ Details" showDrawer={()=>this.closeApprovalDrawer()} openDrawer={this.state.openApprovalDrawer}>
                            <InputWithLabelComponent
                                type="text"
                                name="name"
                                label="Question"
                                value={this.state.quesInDrawer}
                                readOnly={false}
                                onChange={(e) => this.onChangeQuestion(e)}
                                required={true}
                                placeholder='Enter Text '
                                autocomplete="new-password"
                            />
                            <div style={{fontFamily:"Roboto-Bold",fontSize:"12px", color: "#696868", paddingTop: "10px"}}>Recommended by</div>
                            <div style={{fontFamily: 'Roboto-Regular',fontSize:"14px", color:"#696868", }}>pavan</div>
                            <div style={{fontFamily:"Roboto-Bold",fontSize:"12px", color: "#696868", paddingTop: "10px"}}>Comments</div>
                            <div style={{fontFamily: 'Roboto-Regular',fontSize:"14px", color:"#696868", paddingBottom:"10px"}}>{this.state.rowData.comment}</div>
                            {/* <div style={{fontFamily:"Roboto-Bold",fontSize:"12px", color: "#696868", paddingTop: "10px"}}>Answer</div>
                            <div style={{fontFamily: 'Roboto-Regular',fontSize:"14px", color:"#696868", paddingBottom:"10px"}}> {this.state.rowData.answer} </div> */}
                            
                             <TextAreaWithLabelComponent 
                                  label="Answer"
                                  name="answer"
                                  required ={true}
                                  value={this.state.ansInDrawer}
                                  className='text-area'
                                  placeholder='Enter Text'
                                  onChange={(e) =>this.onChangeAnswer(e)}
                                  style={{height:"80px",padding: "4px"}}
                                  autocomplete="new-password"
                              />
                            <FormControl>
                                <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group" defaultValue="newIntentRadio">
                                  <FormControlLabel value="newIntentRadio" onChange={()=>this.changeRadioBtn("newIntentRadio")} control={<Radio size="small" sx={{'& .MuiSvgIcon-root': {fontSize: 14,},}}/>} label={<span style={{ fontFamily:"Roboto-Bold",fontSize:"12px", color: "#696868", }}>Add as New FAQ</span>}/>
                                  <FormControlLabel value="utteranceRadio" onChange={()=>this.changeRadioBtn("utteranceRadio")} control={<Radio size="small" sx={{'& .MuiSvgIcon-root': {fontSize: 14,},}}/>} label={<span style={{ fontFamily:"Roboto-Bold",fontSize:"12px", color: "#696868", }}>Add as Alternate Question</span>}/>
                                </RadioGroup>
                            </FormControl>
                            {
                              this.state.changeRadioBtn == "newIntentRadio" && <div>
                                <div style={{fontFamily:"Roboto-Bold",fontSize:"12px", color: "#696868", paddingTop: "10px"}}>Alternate Questions</div>
                                  <FormControl>
                                    <FormGroup>
                                    {
                                        this.state.rowData && this.state.rowData.utterances && this.state.rowData.utterances.length>0 && this.state.rowData.utterances.map((item,index) => {
                                          return(
                                              <FormControlLabel control={<StyledCheckbox2 onChange={(e)=>this.selectedAltQues(e,item,index)} color="primary" />} 
                                              label={<span style={{ fontFamily:"Roboto-Regular",fontSize:"14px", color: "#696868", }}>{item}</span>} />
                                          )
                                        })
                                      }
                                      {/* <FormControlLabel control={<StyledCheckbox2 onChange='' color="primary" />} label={<span style={{ fontFamily:"Roboto-Regular",fontSize:"14px", color: "#696868", }}>Lorem Ipsum Lorem Ipsum Lorem Ipsum</span>} />
                                      <FormControlLabel control={<StyledCheckbox2 onChange='' color="primary" />} label={<span style={{ fontFamily:"Roboto-Regular",fontSize:"14px", color: "#696868", }}>Lorem Ipsum Lorem Ipsum Lorem Ipsum</span>} />
                                      <FormControlLabel control={<StyledCheckbox2 onChange='' color="primary" />} label={<span style={{ fontFamily:"Roboto-Regular",fontSize:"14px", color: "#696868", }}>Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum</span>} />
                                      <FormControlLabel control={<StyledCheckbox2 onChange='' color="primary" />} label={<span style={{ fontFamily:"Roboto-Regular",fontSize:"14px", color: "#696868", }}>Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum</span>} /> */}
                                    </FormGroup>
                                  </FormControl>
                              </div>
                            }
                            {
                              this.state.changeRadioBtn == "utteranceRadio" && <div>
                                <div style={{fontFamily:"Roboto-Bold",fontSize:"12px", color: "#696868", paddingTop: "10px"}}>Confidence for active intents list</div>
                                <div style={{fontFamily: 'Roboto-Regular',fontSize:"14px", color:"#696868", }}>40%</div>
                                <div style={{fontFamily:"Roboto-Bold",fontSize:"12px", color: "#696868", paddingTop: "10px"}}>Existing intents list</div>
                                <FormControl>
                                    <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group" defaultValue="option1">
                                      <FormControlLabel value="option1" onChange='' control={<Radio size="small" sx={{'& .MuiSvgIcon-root': {fontSize: 14,},}}/>} label={<span style={{ fontFamily:"Roboto-Bold",fontSize:"12px", color: "#696868", }}>Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum</span>}/>
                                      <FormControlLabel value="option2" onChange='' control={<Radio size="small" sx={{'& .MuiSvgIcon-root': {fontSize: 14,},}}/>} label={<span style={{ fontFamily:"Roboto-Bold",fontSize:"12px", color: "#696868", }}>Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum</span>}/>
                                    </RadioGroup>
                                </FormControl>
                              </div>
                            }
                            <div style={{marginTop:"20px"}}>
                                <button style={{backgroundColor:"#FFFFFF",color:"#2A93DF",border:"1px solid #2A93DF",width:"150px", height:"35px", borderRadius:"5px"}}>
                                    <span className='applyBtn'>REJECT</span>
                                </button>
                                <button onClick={this.approveFAQ} style={{backgroundColor:"#2A93DF",color:"#FFFFFF",border:"none",width:"150px", height:"35px", marginLeft:"10px",borderRadius:"5px"}}>
                                    <span className='applyBtn'>APPROVE</span>
                                </button>
                            </div>
                        </DrawerComponent>
               <DrawerComponent heading="FAQ Details"
                    showDrawer={()=>this.handleClose('gridModelClose')}
                    openDrawer={this.state.gridModel1}>
                    <div className='drawer'>
                        <div className='drawer-body mt-2'>
                            <form>
                                <InputWithLabelComponent
                                    placeHolder=''
                                    className={'drawer-input'}
                                    type="text"
                                    name="name"
                                    label="Question*"
                                    value={this.state.gridCol1}
                                    onChange={this.onChangeCol1}
                                    required={true}
                                    isInvalid={this.state.gridCol1==''?true:false}
                                    error={"Question is required"}
                                />
                                <div className='mt-3'>
                                  <TextAreaWithLabelComponent
                                      placeHolder=''
                                      className="textArea"
                                      name="name"
                                      label="Answer*"
                                      value={this.state.gridCol2}
                                      onChange={this.onChangeCol2}
                                      required={true}
                                      isInvalid={this.state.gridCol2==''?true:false}
                                      error={"Answer is required"}
                                  />
                               </div>
                               <div className='mt-3'>
                                  <label className='alterQuesHeading' style={{color:" #000000 "}}>Alternate Questions</label>
                                  <img className='alterQuesFAQ' src="./Icons/plus-icon.svg" onClick={(event)=>this.handlegridAltQue(event)} />
                               </div>
                               <div className='mt-3'>
                               {(this.state.gridAltQue.length>0)?
                  this.altQues=this.state.gridAltQue.map((que,id) => {
                      if(this.state.gridAltQue.length>=this.altque){
                        this.altque++
                      if(!this.state.gridAltQueFlag){
                          this.gridCol4=que
                      }
                      else{
                          if(this.gridAltQue==this.altque){
                            this.altque--
                              this.state.gridAltQue[this.altque]=this.gridCol4
                              this.altque++
                          }  
                      }
                return(
                    <div className='AlterQuestions mt-2'>
                    <label>Question {this.altque}</label><br></br>
                    <div>
                        <TextField type="text" name={this.altque} value={(this.state.gridAltQueFlag==0 || this.gridAltQue==this.altque)?this.gridCol4:que} fullWidth="true" variant="outlined" maxRows={4} 
                            onChange={this.onChangeCol3} multiline="true" size="small" color="primary" >                                
                        </TextField>
                        <img src='./Icons/DeleteBlack.svg' onClick={() => this.deletegridAltQues(id)} ></img>
                        
                    </div>
                    </div>
                ) 
                
                }
               
        }):<p>There are no Alternate Questions.</p>
    }
                               </div>
                                <div className="form-group submit-btn-parent mt-3">
                                    <ButtonComponent 
                                    clicked={(e) => this.updateKnowledgeIntent(e)} 
                                    disabled={this.state.gridCol1 == '' || this.state.gridCol2=='' || this.state.saveSetting==true}  
                                    label={"SAVE"}
                                    variant="contained"
                                    className="buttonWidth"
                                    />
                                        
                                </div>
                            </form>
                        </div>
                    </div>      </DrawerComponent>
           
            </React.Fragment>
          ))}
        </div>

      {/* <ModalPopUp modalclass="delUserModal" show={this.state.deleteknowledgeIntentShow} onHide={() => this.handleClose('deleteKnowledgeIntent')} title="Delete Confirmation" >
        Are you sure to delete All the FAQ's?
        <Row>
          {loadingKnowledgeIntentImg}
        </Row>
        <Col md={12} className={classes.ButtonAlign}>
          <ButtonCustom btnType="blueButton" clicked={(e) => this.DeleteIntent(e)}>Delete</ButtonCustom>
          <ButtonCustom btnType="whiteButton" btnInput="button" clicked={() => this.handleClose('deleteKnowledgeIntent')}>Cancel</ButtonCustom>
        </Col>
      </ModalPopUp> */}
<div>
     
            <Menu
              id="categoriesMenu"
              anchorEl={this.myRef.current}
              keepMounted
              open={this.state.rightOpenpopper}
              placement='right'
              onClose={this.handleSettingsMenuClose}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
   
           <MenuItem className={this.props.AIStandard.RW ? 'menu-item' : `menu-item click-access`} onClick={() => this.ShowDeleteIntentModal()}>DELETE</MenuItem>
           <MenuItem className={this.props.AIStandard.RW ? 'menu-item' : `menu-item click-access`}
            onClick={() => this.ExportIntent()}>EXPORT</MenuItem>
            </Menu>
    
       
  
     
    </div>
       <DeleteModal
          show={this.state.deleteknowledgeIntentShow}
          deleteModal={() => this.handleClose("deleteKnowledgeIntent")}
          title="Delete Confirmation"
          bodyTitle="Are you sure you want to delete the FAQs?"
          buttonClick={(e) => this.DeleteIntent(e)}
        />
        
        <DeleteModal
          show={this.state.ShowDeleteIndividualfromTable}
          deleteModal={() => this.handleClose("deleteKnowledgeIntentSingle")}
          title="Delete Confirmation"
          bodyTitle="Are you sure you want to delete the FAQ?"
          buttonClick={(e) => this.DeleteIntent(e)}
        />
        </>  
    )
  }
}

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 12,
    margin:-8 -2,
  },
}))(Tooltip);

const mapStateToProps = state => {
    return {
      AIStandard:state.user.moduleAccess.conversationalStandard,
      docsUploadAccess:state.user.moduleAccess.cognitiveOtherDocsUpload.WR,
      updateKnowledgeIntentLoading: state.projectview.updateKnowledgeIntentLoading,
      importKnowledgeIntent: state.projectview.importKnowledgeIntent,
      knowledgePattern: state.projectview.knowledgePattern,
      delknowledgeIntentInprogress: state.projectview.delknowledgeIntentInprogress,
      uploadloading: state.projectview.uploadloading,
      knowledgeUploadfailure: state.projectview.knowledgeUploadfailure,
      knowledgeUpload: state.projectview.knowledgeUpload,
      catList: state.projectview.catList,
      knowledgeIntents: state.projectview.knowledgeIntents,
      GetKnowledgeloading: state.projectview.GetKnowledgeloading,
      permissions:state.user.permissionList,
      waitinForApprovalData: state.projectview.waitinForApprovalData,
    };
  };

const mapDispatchToProps = dispatch => {
    return {
     
      onDeleteMultiKnowledgeIntent: (APIURL, data, bool) => dispatch(actions.DeleteMultiKnowledgeIntent(APIURL, data, bool)),
      onGetKnowledgeIntent: (reqData, APIURL) => dispatch(actions.GetKnowledgeIntent(reqData, APIURL)),
      OnGetBotList: (APIURL) => dispatch(actions.GetBotList(APIURL)),
      onGetCategoryList: (APIURL) => dispatch(actions.getAllCategory(APIURL)),
      onUpdateAllKnowledgeIntent: (bulkUpdateArr, APIURL) => dispatch(actions.UpdateAllKnowledgeIntent(bulkUpdateArr, APIURL)),
      ondeleteAltQuesForFAQ:(APIURL,data)=>dispatch(actions.deleteAltQuesForFAQ(APIURL,data)),
      onExportKnowledgeIntent: (APIURL, projectName, language, orgName) => dispatch(actions.ExportKnowledgeIntent(APIURL, projectName, language, orgName)),
      onsaveBotProfile: (profile, APIURL) => dispatch(actions.saveBotProfile(profile, APIURL)),
      onApproveFAQ: (url,payload) => dispatch(actions.approveFAQ(url,payload)),
    }
  
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(KnowledgeTable, axios));
