import * as actionTypes from './actionTypes';

export const setMessage = (error) => {
    let errmessage = error
    if(error != null  && error.message !== undefined)
    {
        errmessage = error.message 
        if(errmessage.includes("40")){
            return {
                type: actionTypes.SET_ERROR_MESSAGE,
                error: ''
            };
        }
    }
    return {
        type: actionTypes.SET_ERROR_MESSAGE,
        error: errmessage
    };
};

export const SetErrorMessage = (error) => {
    return dispatch => {
            dispatch(setMessage(error));
       
    };
};

export const setSucMessage = (success) => {
    let successmessage = success
    if(success != null  && success.message !== undefined)
    {
        successmessage = success.message 
    }
    return {
        type: actionTypes.SET_SUCCESS_MESSAGE,
        success: successmessage
    };
};

export const setWarMessage = (warning) => {
    let warningmessage = warning
    return {
        type: actionTypes.SET_WARNING_MESSAGE,
        warning: warningmessage
    };
};

export const SetWarningMessage = (warning) => {
    return dispatch => {
            dispatch(setWarMessage(warning));
       
    };
};

export const SetSuccessMessage = (success) => {
    return dispatch => {
            dispatch(setSucMessage(success));
       
    };
};


export const resetMessages =()=>{
    return dispatch => {
        dispatch(setSucMessage(null));
        dispatch(setMessage(null));
        dispatch(setWarMessage(null));

};
}