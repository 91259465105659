import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import get from 'lodash/get';
import EditSymptoms from './EditSymptoms/EditSymptoms';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SymptomData = ({ selectedItem, config,checked,permissions }) => {
    const sortedRootCauseData = get(selectedItem, 'rootcausedata', []);
    const rootCauseData = sortedRootCauseData.sort((a, b) => Number(b.relevancy) - Number(a.relevancy));
    const [showDrawer, setShowDrawer] = useState(false);
    const [selectedRootCause, setSelectedRootCause] = useState({});
    const [selectedIndex, setSelectedIndex] = useState(0);
    const toggleDrawer = (value, index) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }
        setShowDrawer(value);
        setSelectedRootCause(get(rootCauseData, index, {}));
        setSelectedIndex(index);
    };
    
    return (
        <React.Fragment>
            {checked ? 
             <React.Fragment>
             {rootCauseData.length ? rootCauseData.map((rootCause, index) => {
                 return (<React.Fragment>
                      {rootCause.status === 'reviewed' ?
                     <Row className="root-cause-table-body no-gutters" onClick={toggleDrawer(true, index)} >
                         <Col className="col-md-4 root-cause-table-data text-break">{rootCause.rootcause}</Col>
                         <Col className="col-md-3 text-break">{rootCause.rootcausetitle}</Col>
                         <Col className="col-md-2 pl-4 root-cause-table-body-data text-break">{rootCause.relevancy}</Col>
                         <Col className="col-md-2 text-break">{rootCause.status === 'reviewed'}
                             <button className="root-cause-table-body-review-button text-break">{rootCause.status}</button>
                        </Col>
                         <Col className="col-md-1"><img src="./images/Vector.svg" /></Col>
                     </Row>
                      : null}
                 </React.Fragment>)
             }
             ) : null
             }
            </React.Fragment>
            : 
            <React.Fragment>
            {rootCauseData.length ? rootCauseData.map((rootCause, index) => {
                return (<React.Fragment>
                    <Row className="root-cause-table-body no-gutters" onClick={toggleDrawer(true, index)} >
                        <Col className="col-md-4 root-cause-table-data text-break">{rootCause.rootcause}</Col>
                        <Col className="col-md-3 text-break">{rootCause.rootcausetitle}</Col>
                        <Col className="col-md-2 pl-4 root-cause-table-body-data text-break">{rootCause.relevancy}</Col>
                        <Col className="col-md-2 text-break">{rootCause.status === 'reviewed' ?
                            <button className="root-cause-table-body-review-button text-break">{rootCause.status}</button> :
                            <button className="root-cause-table-body-yetReview-button text-break">{rootCause.status}</button>
                        }</Col>
                        <Col className="col-md-1"><img src="./images/Vector.svg" /></Col>
                    </Row>
                </React.Fragment>)
            }
            ) : null
            }
            </React.Fragment>
            }
          {permissions &&  <EditSymptoms
                showDrawer={showDrawer}
                rootCauseData={selectedRootCause}
                toggleDrawer={toggleDrawer}
                id={selectedIndex}
                selectedItem={selectedItem}
                closeDrawer={() => {
                    setShowDrawer(false);
                  }}
                config={config}
                toastFun={(msg, type) => {
                    toast(msg, {
                      type: type,
                      pauseOnHover: false,
                    });
                  }}
            />}
            <ToastContainer />
        </React.Fragment>
    )
};

export default SymptomData;