import * as actionTypes from './actionTypes';
import axios from '../../instance';
import { GetUserbyID } from './user';
import {SetErrorMessage} from '../actions/message'
import {SetDefaultLanguage} from '../actions/user'
import { isJwtExpired } from 'jwt-check-expiration';
import * as kc from './keycloak.js';
import { decrypt, encrypt, encryptAndStore } from '../../utility/utility';
const orgName = localStorage.getItem("orgName");
const username = localStorage.getItem("userName"); 
 
export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const authSuccess = (sessiontoken, userName,userRequest) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        sessiontoken: sessiontoken,
        userName: userName,
        userRequestedURL:userRequest
    };
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    };
};

export const createUserSuccess = (user) => {
    return {
        type: actionTypes.CREATE_USER_SUCCESS,
        user: user
    };
};

export const createUserFail = (error) => {
    return {
        type: actionTypes.CREATE_USER_FAIL,
        error: error
    };
};

export const checkAuthTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime * 1000);
    };
};

export const logout = () => {

      kc.keycloak.logout().then((success) => {
                    console.log("--> log: logout success ", success );
            }).catch((error) => {
                    console.log("--> log: logout error ", error );
            });

    localStorage.removeItem('sessiontoken');
    localStorage.removeItem('useremailid');
    localStorage.removeItem('userName');
    localStorage.removeItem('orgName');
    localStorage.removeItem('projectname');
    localStorage.removeItem('roleName');
    localStorage.removeItem('language');
    localStorage.removeItem('DeleteProjName') 
    localStorage.removeItem('newAccessToken'); 
    localStorage.removeItem('vaName'); 
    localStorage.removeItem('login'); 
    localStorage.removeItem('cmmStatus');
    localStorage.removeItem('popup');   
    window.secretKey="";
    // localStorage.clear();  
    return {
        type: actionTypes.AUTH_LOGOUT
    };
};

export const updateUserLoginStatus = (url) => {
    return dispatch => {
        const orgname = localStorage.getItem("orgName");
        let user = localStorage.getItem("userName");
        let username = user.replaceAll('.','~~');
        let token = localStorage.getItem("accessToken")
        const updatedUrl =url+`/organization/${orgname}/user/${username}/loginstatus`;
      
        axios({
            method: 'PATCH',
            url: updatedUrl,  // like this
            // data: JSON.stringify(data),
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization':`Bearer ${token}`    ,"orgName":orgName,"userName":username
            
            },
          }).then((response)=>{ 
                if(response.status=="204"){
                    dispatch(updateUserLoginSuccess()); 
                }else{
                    dispatch(SetErrorMessage("You cannot access with this user ID. Please connect with your admin for access."));
                    logout();
                }
          }).catch(err => {
            logout();
            console.log('update user login status error response: ',err);
            dispatch(SetErrorMessage(err.error));
            dispatch(updateUserLoginFailure(err.error));
          });
        };
}

const getDomain = (email) => {
    let emailArr = email.split('@');
    let domain = '';
    if(emailArr[1].indexOf('.') > -1)
        domain = emailArr[1].substring(0,emailArr[1].lastIndexOf('.'));
    else 
        domain = emailArr[1];

    return domain;

}

export const auth = (name, isSignup, kcURL,cmmstatus=false) => {

    return dispatch => {
        dispatch(authStart());
        // localStorage.setItem('login',1);
        encryptAndStore('login',1);

        
        let domain =  getDomain(name);
        window.secretKey=process.env.REACT_APP_KEY;
    

    // localStorage.setItem('userName', name);
    // localStorage.setItem('orgName', domain);
    encryptAndStore('userName', name);
    encryptAndStore('orgName', domain);

    if(cmmstatus) encryptAndStore('cmmStatus', true);
    
    // if(kc.keycloak === null)
        kc.initializeKC(domain, kcURL);

        kc.keycloak.init({ onLoad: 'check-sso'}).then((auth) => {
            if (!auth) {
                console.log('auth in kc.init auth.js: ',auth);          
                kc.keycloak.login( {loginHint:name})
                .then((auth)=>{
                  console.log("keycloak.token in login().then",auth); 
                })
                .catch(() => {
                    console.log("Catch of keycloak login in auth.js"); 
                 });        
            } else {
                    console.log("Authenticated using keycloak");            
                    console.log('idTokenParsed', kc.keycloak.idTokenParsed);
                    let name = kc.keycloak.idTokenParsed.preferred_username;
                    let user = null;
                    user = {"username":name,"useremailid":name,"authtoken": kc.keycloak.token,"domain":domain};
                    // localStorage.setItem('accessToken', kc.keycloak.token);                
                    // localStorage.setItem('refreshToken', kc.keycloak.refreshToken); 
                    // localStorage.setItem('sessiontoken', JSON.stringify(user) );
                    // localStorage.setItem('userName', name);
                    // localStorage.setItem('language', 'en');
                    // localStorage.setItem('orgName', domain);
                      encryptAndStore('accessToken', kc.keycloak.token); 
                      encryptAndStore("refreshToken", kc.keycloak.refreshToken);                     
                      encryptAndStore('sessiontoken', JSON.stringify(user) );
                      encryptAndStore('userName', name);
                      encryptAndStore('language', 'en');
                      encryptAndStore('orgName', domain);
                  dispatch(SetDefaultLanguage('','English','en'));
                  dispatch(authSuccess(JSON.stringify(user), name));
                  window.secretKey=process.env.REACT_APP_KEY;
            }                    
            
            }).catch((err) => {
                console.error("Authenticated Failed in auth.js!!", err);
                dispatch(authFail("Try using different User ID. We don't have an account with this User ID."));
                localStorage.removeItem('login'); 
                localStorage.removeItem('orgName'); 
                localStorage.clear(); 
           });

        };
    }; 

export const createUser = (name,email,domain,apiURL) => {
    return dispatch => {
        dispatch(authStart());

        const CreateUser = {
            "role":'user',
             "username":name,
             "useremailid":email,
              "password":"",  
              "org":"default",            
              "skillset":["Java"],
              "languages":["en"],                 
             "bots":{"en":["common"]},
                  "domain":domain};

        let url =apiURL+`/addUser`; 
        
        axios.post(url, CreateUser)
            .then(response => {
                              
               if( response.data.status.responseType==="success")
               {              
                dispatch(createUserSuccess(JSON.stringify("user created"))); 
                setTimeout(() => {
                    dispatch(GetUserbyID(apiURL));
                }, 2000);              

               }
               else
               {
                dispatch(createUserFail(response.data.status.responseType,));
               }

            })
            .catch(err => {               
                dispatch(createUserFail(err));
            });
    };
};

export const setAuthRedirectPath = (path) => {
    return {
        type: actionTypes.SET_AUTH_REDIRECT_PATH,
        path: path
    };
};

export const authCheckState = () => {
    return dispatch => {
        const sessiontoken = localStorage.getItem('sessiontoken');
        const accessToken = localStorage.getItem('accessToken');
        if (!sessiontoken) {
            dispatch(logout());
        } 
        else if (!accessToken || accessToken == undefined || accessToken == 'undefined') {
            dispatch(logout());
        } else {
            if (isJwtExpired(accessToken)) {
                dispatch(logout());
             } else {
                const userName = localStorage.getItem('userName');
                dispatch(authSuccess(sessiontoken, userName));
            }   
        }
    };
};


export const updateUserLoginSuccess = () => {
    return {
        type: actionTypes.UPDATE_USER_LOGIN_SUCCESS
    };
};

export const updateUserLoginFailure = (error) => {
    return {
        type: actionTypes.UPDATE_USER_LOGIN_FAILURE,
        error: error
    };
};

export const saveTimeZone = (url,    data) => {
    return () => {
        axios.post(url,data).then(()=>{ 
            console.log("timezone save successfully") 
          }).catch(() => {
            console.log("timezone not save successfully")
          });
        };
}

export const getCountryTimeZoneSuccess = (countryDetails) => {
    return {
        type: actionTypes.GET_COUNTRY_DETAILS_SUCCESS,
        countryDetails:countryDetails
    };
};

export const getCountryTimeZoneFailure = (error) => {
    return {
        type: actionTypes.GET_COUNTRY_DETAILS_FAILURE,
        error: error
    };
};
export const getCountryTimeZone = (url) => {
    return dispatch => {
        axios.get(url).then((response)=>{
            dispatch(getCountryTimeZoneSuccess(response.data)) 
            console.log("timezone save successfully")
          }).catch(() => {
            console.log("timezone not save successfully")
          });
        };
}
export const getCmmStatusSuccess = (cmmLoginStatus) => {
    return {
        type: actionTypes.GET_CMM_LOGIN_SUCCESS,
        cmmLoginStatus:cmmLoginStatus
    };
};

export const getCmmStatusFailure = (error) => {
    return {
        type: actionTypes.GET_CMM_LOGIN_FAILURE,
        error: error
    };
};
export const getCMMLoginStatus = (url) => {
    return dispatch => {
        axios.get(url).then((response)=>{
            dispatch(getCmmStatusSuccess(response.data)) 
            console.log("cmm status save successfully")
          }).catch(() => {
            console.log("cmm status not  save successfully")
          });
        };
}

