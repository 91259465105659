import Input from '../../components/UI/Input/InputCustom';
import Button from '../../components/UI/Button/Button';
import { Col, Container,  Row } from 'react-bootstrap';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import classes from './LoginPage.module.css';
import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import axios from '../../instance';
import BackgroundImage from './login_bg.png'
import SpinnerPage from '../../components/UI/Loader/Loader';

const styles = {
    backgroundImage: `url(${BackgroundImage})`
}

class LoginPage extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    controls: {
      name: {
        elementType: "input",
        elementConfig: {
          type: "email",
          placeholder: "Email ID",
        },
        value: "",
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
        imgsrc: "./images/User_Login.svg",
        className: "userIconClass",
      }, //,
      // password: {
      //     elementType: 'input',
      //     elementConfig: {
      //         type: 'password',
      //         placeholder: 'Password'
      //     },
      //     value: '',
      //     validation: {
      //         required: true
      //         //minLength: 6
      //     },
      //     valid: false,
      //     touched: false,
      //     imgsrc:"./images/Password_Login.svg",
      //     className:"userPassClass"
      // }
    },
    isSignup: true,
    emptyPasswordMsg: null,
    emptyUserIdMsg: null,
    expired:'',
    invalidUser:''
  };

 componentDidMount(){
  this.setState({
    expired:localStorage.getItem('expired'),
    invalidUser:localStorage.getItem('invalidUser')
  })
  setTimeout(() => {
    this.setState({
      expired:localStorage.setItem('expired',""),
    })   
  }, 5000);
 }


 componentDidUpdate(){
  if(localStorage.getItem('invalidUser')){
    localStorage.removeItem('invalidUser') ;
  }
}

  checkValidity(value, rules) {
    let isValid = true;
    if (!rules) {
      return true;
    }

    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }
    return isValid;
  }
  setValidationMessage = (type, value) => {
    if (type == "name") {
      value.trim() === ""
        ? this.setState({
            emptyUserIdMsg: (
              <div className={classes.helpblock}>Username is required</div>
            ),
          })
        : this.setState({ emptyUserIdMsg: null });
    }
    if (type == "password") {
      value.trim() === ""
        ? this.setState({
            emptyPasswordMsg: (
              <div className={classes.helpblock}>Password is required</div>
            ),
          })
        : this.setState({ emptyPasswordMsg: null });
    }
  };

  submitHandler = (event) => {
    event.preventDefault();
    this.setValidationMessage("name", this.state.controls.name.value);
    if (this.state.controls.name.value)
      this.props.onAuth(
        this.state.controls.name.value,
        this.state.isSignup,
        this.props.config.KEYCLOAK_URL
      );
  };

  inputChangedHandler = (event, controlName) => {
    const updatedControls = {
      ...this.state.controls,
      [controlName]: {
        ...this.state.controls[controlName],
        value: event.target.value,
        valid: this.checkValidity(
          event.target.value,
          this.state.controls[controlName].validation
        ),
        // touched: true
      },
    };

    this.setState({ 
      controls: updatedControls,
      invalidUser:''
     });
     
    this.setValidationMessage(controlName, event.target.value);
    this.props.onAuthFail("");
  };

  render() {
    const formElementsArray = [];
    for (let key in this.state.controls) {
      formElementsArray.push({
        id: key,
        config: this.state.controls[key],
      });
    }

    let form = formElementsArray.map((formElement) => (
      <>
        <div key={formElement.id}>
          <div className={classes.testClass}>
            <Input
              key={formElement.id}
              elementType={formElement.config.elementType}
              elementConfig={formElement.config.elementConfig}
              value={formElement.config.value}
              invalid={!formElement.config.valid}
              shouldValidate={formElement.config.validation}
              touched={formElement.config.touched}
              changed={(event) =>
                this.inputChangedHandler(event, formElement.id)
              }
            />
            {formElement.config.elementConfig.placeholder === "Password" ? (
              <img
                src={formElement.config.imgsrc}
                className={classes.userPassClass}
              ></img>
            ) : null}
            {formElement.config.elementConfig.placeholder === "Password"
              ? this.state.emptyPasswordMsg
              : this.state.emptyUserIdMsg}
          </div>
        </div>
      </>
    ));

    let loadingImg = null;

    if (this.props.loading) {
      loadingImg = <SpinnerPage></SpinnerPage>;
    } else {
      loadingImg = null;
    }

    let authRedirect = null;
    if (this.props.isAuthenticated && this.props.isLoggedin) {
      authRedirect = <Redirect to={this.props.authRedirectPath} />;
    }

    return (
      <div className={classes.loginbody}>
        {authRedirect}
        <Row className={classes.loginrow}>
          <div className={classes.loginImage}>
            <img className={classes.loginLogo} src="./Icons/Login_logo.png" />
          </div>
          <Col md={7} className={classes.loginformleft} style={styles}>
            <Row>
              <Col md={12}>
                <div className={classes.logintext}>
                  {/* <p> Reduce call volumes, improve customer<br/> satisfaction, and enhance service team <br/> productivity</p> */}
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={5} className={classes.loginformcontent}>
            <form
              name="form"
              className={classes.loginform}
              onSubmit={this.submitHandler}
            >
              <span className={classes.loginformheading}>
                Sign in to your account
              </span>
              <br />
              <br />
              {form}
              {/* <Row>
                    <Col md={6}>
                            <input type='checkbox' name="chkKeepMeLoggedIn" /><span for="chkKeepMeLoggedIn" style={{textDecoration:"underline",marginLeft:"5px",color: "#4F4F56"}}>Keep me logged in</span> 
                    </Col>
                    <Col md={6}>
                            <a style={{textDecoration:"underline"}} href="#">Forgot Password?</a>
                    </Col>
                    </Row> */}
              <br />
              <Button
                btnType="loginBtn"
                disabled={!this.state.controls.name.value || this.props.error}
              >
                Next
              </Button>
              <br></br>
              <p style={{color:'red', fontWeight:'bold'}}>{this.state.expired}</p>
              <p style={{color:'red', fontWeight:'bold', width:'350px'}}>{this.state.invalidUser}</p>
              <Container className="form-group">{loadingImg}</Container>
              <div className="errorforhomepage">
                <span className={classes.loginerr}>{this.props.error}</span>
              </div>

              {/* <hr/> */}
              {/* <Container className="form-group" style={{color: "#4F4F56"}}> Need an account? <a style={{textDecoration:"underline"}} href="#">Sign Up</a></Container> */}
            </form>
            
          </Col>
          
          {/* <Col md={{ span: 4, offset: 3 }}>
                    <form name="form" className={classes.loginform} onSubmit={this.submitHandler}>
                   
                       <Image className="login-logo" src='./images/login_logo.svg' alt="login-logo"></Image>

                        {form}
                        <Row>
                        <Col md={6}>
                                <input type='checkbox' /> Keep me logged in
                        </Col>
                        <Col md={6}>
                                <a href="#">Forgot Password?</a>
                        </Col>
                        </Row>
                        <Container className="form-group">
                            <Button btnType="loginBtn">Login</Button>
                          {loadingImg}
                        </Container>
                        <hr/>
                        <Container className="form-group"> Need an account? <a href="#">Sign Up?</a></Container>
                       
                    </form>

                    </Col> */}
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        isAuthenticated: state.auth.sessiontoken !== null,
        authRedirectPath: state.auth.authRedirectPath,
        isLoggedin:state.auth.isLoggedin
    };
};

const mapDispatchToProps = dispatch => {
    return {
        // onAuth: ( name, password, isSignup,APIURL ) => dispatch( actions.auth( name, password, isSignup,APIURL ) ),
        onAuth: ( name, isSignup,APIURL ) => dispatch( actions.auth( name, isSignup,APIURL ) ),
        onAuthFail: ( name) => dispatch( actions.authFail(name)),
    };
};

export default connect( mapStateToProps, mapDispatchToProps )(withErrorHandler( LoginPage, axios ));