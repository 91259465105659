import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { connect } from "react-redux";
import axios from "../../instance";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import get from 'lodash/get';
import { GetCalltoServer, PostCalltoServer, botPostCalltoServer, deleteCalltoServer } from '../../store/utility';
import { SetErrorMessage, SetSuccessMessage } from '../../store/actions';
import withErrorHandler from "../../hoc/withErrorHandler/withErrorHandler";
import { useDispatch, useSelector, } from 'react-redux';
import { makeStyles,withStyles } from '@material-ui/core';
import Dropzone from "./dropzone/Dropzone";
import * as actions from '../../store/actions/index';
import {  unset } from 'lodash';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

import TextField from '@material-ui/core/TextField';

import SpinnerPage from '../../components/UI/Loader/Loader';
import moment from 'moment';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';

import orderBy from 'lodash/orderBy';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import SearchComponent from "../../components/UIComponents/SearchComponent/Search";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Col, Row } from 'react-bootstrap';
import classes from './ProjectViewPage.module.css';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import DrawerComponent from '../../components/UIComponents/Drawer/DrawerComponent';
import InputWithLabelComponent from '../../containers/SettingPage/SettingInnerPages/components/InputWithLabelComponent/InputWithLabelComponent';
import ButtonComponent from "../../components/UIComponents/Button/ButtonComponent";
import BadgeComponent from '../../components/UIComponents/Badge/BadgeComponent';
import Checkbox from '@material-ui/core/Checkbox';
import DeleteIcon from '@material-ui/icons/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import {decrypt} from '../../utility/utility';

const useStyles = makeStyles((theme)=>({
    customStyle: {
        '& div': {
           left:'unset !important',
           right:'110px !important',
        }
    },
    style:{
        '& .MuiPaper-rounded':{
            borderRadius:"30px",
        }
    },
    tableContainer:{
        width: '100%',
        maxHeight:370,
        overflowY:'auto',
        [theme.breakpoints.up('lg')]: {
            maxHeight:530,
            overflowY:'auto',
          },
          [theme.breakpoints.between('1300','1600')]: {
            maxHeight:320,
            overflowY:'auto',
          },
    },
    tableHeader:{
        fontWeight:700,
        backgroundColor:'#F2F1F1',
        color:'#000000',
        fontFamily: 'Roboto-Regular',
        fontStyle: 'normal',
        fontSize: 13,
        padding:'10px 82px 8px 8px',
        textTransform: 'uppercase',
    },
    tableCell:{
        padding:8,
        fontFamily: 'Roboto-Regular',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 12,
        color:'#000000',
        },
    iconStyle:{
       width:15,
       position:'relative',
       top:-1,
    },
    addDoc:{
        "fontWeight": "900",
        "fontSize": "22px",
        "lineHeight": "40px",
        "display": "flex",
        "alignItems": "center",
        "color": "#2A93DF",
        textDecoration:"underline",
        "cursor":"pointer"
    },
    noDataContent:{
        position:"relative",
        top:"100px"
    },
    noDataText:{
        "textAlign": "center",
        fontWeight: 900,
        fontSize: "35px",
        lineHeight: "48px",
        color: "#D9D8D8",
        width: "592px",
        height: "96px",
    },
    menuItems:{
        fontSize:14,
        fontWeight:'bold',
        fontFamily:'Roboto-Regular',
        color:'black',
        '&.active, &:hover, &.active:hover':{
          background:'none',
          color:'#2A93DF'
        }
    }
}));

const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 12,
      margin:-8 -2,
    },
  }))(Tooltip);


const invertDirection = {
    asc:'desc',
    desc:'asc',
}

function ManageFileData({
    config,
	isFaq,
    openOtherDocumentDrawer,
    onEditMultimediaRow,
    otherDocsPermission
}) {
    const dispatch = useDispatch();
    const reloadDocument = useSelector(state => state.projectview.reloadDocument);
    const loadnonSearchableData = useSelector(state => state.projectview.refresh);
    const selectedProject = useSelector(state=> state.projectview.IsProjectChange);
   const [fields,setFields] = useState(['File Name','Size','Modified Date & Time', 'keywords','TRAINING'])
    const [columnToSort,setColumnToSort] = useState("");
    const [sortDirection,setSortDirection] = useState('desc');
    const [searchValue,setSearchValue] = useState('');
    const[show,setShow] = useState(true);
	const [fileUploadConfirmation,setFileUploadConfirmation] = useState(false);
	const [flag,setflag] = useState(false);
  
    const [ids,setIds] = useState('');
	const [fileExistingMsg, SetFileExistingMsg] = useState('');
    const [existingFiles, setExistingFiles] = useState('');
    const [editPopup,setEditPopup] = useState(false)
    var [descriptionVal,setDescriptionVal]= useState(null)
    var [tagVal,setTagVal]= useState(null)
    var [fileName,setfileName]= useState(null)
    var [rowData,SetRowData]=useState(null)
    const [nonSearchableData, setNonSearchableData] = useState([]);
    const [updateDataLoading, setUpdateDataLoading] = useState(false);
    const [searchableFilesLoading, SetSearchableFilesLoading] = useState(false);
    const [showFiles, SetShowFiles] = useState(true);
    const [failure, Setfailure] = useState(false);
    const [option, SelectOption] = useState(null);
    const [selectedFiles, SetSelectedFiles] = useState([]);
    var [multimediaFiles, SetMultimediaFiles] = useState([]);
    var [otherFiles, SetOtherFiles] = useState([]);
    var [selectedTab, setSelecteTab] = useState("Multimedia Documents(0)")
    var [tabsData, SetTabData] = useState(["Multimedia Documents(0)","Other Documents(0)"])
    const [saveDataLoading,SetSaveDataLoading] = useState(false)
    const [filterType, SetFilterType] = useState('local')
    //------------------Simple Menu On Clicking Add icon-----------
    const [anchorEl, setAnchorEl] = React.useState(null);
	const [deletePopup, setDeletePopup] = useState(false);
    const [deleteMultiplePopup,setDeleteMultiplePopup] = useState(false);
    const [menuShow, setMenuShow] = React.useState(null);
    const [badges, setBadges] = useState([]);
    const [tag, handleTag] = useState("");
    const [saveSetting, setSaveSetting] = useState(true);
    const [numSelect, setNumSelected] = useState(0);
    const [rowcount, setRowCount] = useState(0);
    const [checkd, setChecked] = useState([]);
    const [allRow, setAllRows] = useState([]);
    const [language,setLanguage] = useState([localStorage.getItem('language')]);
    const[selectedLang,setSelectedLang]=useState('');

    const handleAddTag=()=>{
        if (tag.trim()) {
            badges.push(tag)
            setBadges([...badges])
            handleTag('')
            setSaveSetting(false)
        }
    }
    const badgeClick = (i) => {
        badges.splice(i, 1)
        setBadges([...badges])
    }    
    const handleClickMenu = (event) => {
        setMenuShow(event.currentTarget);
    };
  
    const handleCloseMenu = () => {
        setMenuShow(null);
    };

   
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSort = (columnName) =>{
        setColumnToSort(columnName);
        setSortDirection(columnToSort == columnName?invertDirection[sortDirection]:'asc')
    }

    const onQuickFilterChanged = (event) => {
        setSearchValue(event.target.value);
    
            if(event.target.value!="" ){
              setShow(false)
    
            }
            else{
              setShow(true)
            }
      };


	const handleCloseWindow = (str) =>{
		if(str == "fileUploadAlreadyExit"){
            var oFiles = []
            var mFiles = []
            if(existingFiles.length>0){
                for(var i=0;i<existingFiles.length;i++){
                var fileType=existingFiles[i].split(/\.(?=[^\.]+$)/)[1]
                
                if(fileType == "pdf" || fileType == "docx" || fileType == "doc" || fileType == "txt" || fileType == "xlsx" || fileType == "xls" || fileType == "csv" || fileType == "html" || fileType == "xls" || fileType == "htm"){
                    oFiles.push(existingFiles[i])
                }
                else{
                    mFiles.push(existingFiles[i])
                }
                }
            }
            multimediaFiles = multimediaFiles.filter( function( el ) {
                return !mFiles.includes( el.name );
              } );
              otherFiles = otherFiles.filter( function( el ) {
                return !oFiles.includes( el.name );
              } );
            saveFiles(false)
            setFileUploadConfirmation(false)				
		}
        if(str == "deleteConfirmationCancel"){
            setDeletePopup(false)
        }

        if(str == "deletemultipleConfirmationCancel"){
            setDeleteMultiplePopup(false)
        }


	}
    //-------------------------------------------------------------

	const deleteConfirmation = ()=>{
            setDeletePopup(true)
            setMenuShow(null); 
	}

    const editRow=()=>{
             setEditPopup(true)
             setMenuShow(null);
         }
    
    

    const deleteMultipleFiles = ()=>{
        setDeleteMultiplePopup(true)

    }

    const getLatestDataForNotSearchableFiles = () => {
        
        const configObj = {
            "index_es": "cognitivecollection_intelliservices.onmicrosoft",
            "id_es": "01CZCBNYLUR33SPTSBBBAYHM7DNNFWM36C",
            "organisation": localStorage.getItem("orgName"),
            "project": localStorage.getItem("projectname"),
            "documentType":filterType
        }
        SetSearchableFilesLoading(true);


       let url = config.CS_GOLANG + `/ctrainer/train/fullstats?project=${localStorage.getItem("projectname")}` +`&` + `source=` + filterType +`&` + `multimedia=exclude`;
       GetCalltoServer(url, configObj)
            .then(response => {
				
				if (get(response, 'data.status', '') === "success") {                    
                    let resp = response.data.data
                    let respArr=[];
                    let check=[];
                    for(var r in resp){
                        let respArray = resp[r];
                        let respObj={};
                        if(respArray == null || respArray.length == 0)
                            return ;
                        respObj["fileName"]=respArray[0].filename
                        respObj["lastUpdateDate"]=utcToLocalTime(respArray[0].lastmodified)
                        respObj["size"]=bytesToSize(respArray[0].size);
                        respObj["status"]=respArray[0].moreinfo.Status;
                        respObj["description"]='';
                        respObj["tags"]=respArray[0].moreinfo.tags;
                        respObj["keywords"]=respArray[0].moreinfo.keywords;
                        respArr.push(respObj);
                        check.push(false);
                    }

                 
                    setNonSearchableData(respArr);
                    SetSearchableFilesLoading(false);
                    setRowCount(respArr.length);
                    setChecked(check)
                    setNumSelected(0)
                }
                
                else {
                    setNonSearchableData([]);
                    SetSearchableFilesLoading(false);
                }
            }).catch(() => {
                SetSearchableFilesLoading(false);
                setNonSearchableData([]);
            });
    };

    function bytesToSize(bytes) {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes == 0) return '0 Byte';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
     }

     function utcToLocalTime(utcdateStr){
         try{
            var local_date= moment.utc(utcdateStr ).local().format('YYYY-MM-DD HH:mm:ss');
            const formatDate = moment(local_date).format('MMMM Do YYYY, h:mm:ss A z')
            return formatDate;
         }catch(err){console.log(err)}
     }

    useEffect(() => {
        setNumSelected(0)
        setChecked([])
        setAllRows([])
        setRowCount(0)  
       language == 'af' ? setSelectedLang('Afrikaans') : 
        language == 'ar' ? setSelectedLang('Arabic'):
        language == 'de' ? setSelectedLang('German'):
        language == 'el' ? setSelectedLang('Greek'):
        language == 'es' ? setSelectedLang('Spanish'):
        language == 'fr' ? setSelectedLang('French'):
        language == 'it' ? setSelectedLang('Italian'):
        language == 'hu' ? setSelectedLang('Hungarian'):
        language == 'zh' ? setSelectedLang('Chinese'):
        language == 'ru' ? setSelectedLang('Russian'):
        language == 'el' ? setSelectedLang('Greek'):
        language == 'ja' ? setSelectedLang('Japanese'):
        language == 'pl' ? setSelectedLang('Polish'):
        language == 'hi' ? setSelectedLang('Hindi'):setSelectedLang('English')
}, [reloadDocument, filterType, loadnonSearchableData,language]);
    
    useEffect(()=>{
        getLatestDataForNotSearchableFiles();
    },[selectedProject,reloadDocument, filterType, loadnonSearchableData])

    const saveFiles = (boolean)=>{
        var f=0
        if(multimediaFiles.length>0){
            multimediaFiles.map((item) => {
                if(item.description){}else {setflag(true);f=1}
                if(item.tags){}else {setflag(true); f=1}
            })
        }
        if(f==0){
            if(boolean == true)setFileUploadConfirmation(false)
            var rowData = [];
            		
            rowData=[...multimediaFiles, ...otherFiles]
            let projectname = localStorage.getItem("projectname");
            if (!projectname)
                projectname = "common";
            let fileList = []
            let fileArray = []
            let formData = new FormData();
            rowData.forEach((item) => {
                let formFieldsObj={}
                formFieldsObj["fileName"]=item.name;
                formFieldsObj["description"]=item.description?item.description:'';
                formFieldsObj["tags"]=item.tags?item.tags:'';
                formFieldsObj["keywords"]=item.tags?item.tags:'';
                formFieldsObj["ids"]=ids;
                formFieldsObj["isValidate"]=boolean;
				formFieldsObj["language"]="en";
                formFieldsObj["lastUpdateDate"]=moment(new Date()).format('DD-MM-YYYY hh:mm:ss A z');
				fileList.push(item.name)
                formData.append('files', item)
                formData.append('description', item.description?item.description:'')
                formData.append('tags', item.tags?item.tags:'')
                formData.append('ids', ids)
                formData.append('isValidate', boolean)
                let formObj={}
                formObj[item.name]=formFieldsObj;
                fileArray.push(formObj);
                return true;
            })
            formData.append('projectName', projectname);
            formData.append('categoryName', "Local");
            formData.append('fileArray',JSON.stringify(fileArray) );
			formData.append('language', "en");
            let token = localStorage.getItem("accessToken");
            // let token = decrypt("newAccessToken")

                        SetSaveDataLoading(true)
                         
                           fetch(config.FileUpload_URL + `/cognitive/upload`,{
                            method: 'POST', 
                            headers: {
                                "Authorization":`Bearer ${token}`
                              }, 
                            body: formData,
                          }).then(response=> {
                        return response.text();
                        }).then(datas => {
                            let result=JSON.parse(datas);
                            let results = JSON.parse(result.data)
                            if(result.status.responseType =="success"){                               
            					if(results.ids.length > 0){
            						setIds(results.ids);
            						SetFileExistingMsg(results.data);
                                    setExistingFiles(results.existingFiles.split(','))
            						setFileUploadConfirmation(true)
            					}
            					else{
                                dispatch(SetSuccessMessage(results.data));
                                SetSaveDataLoading(false)
                                SetShowFiles(true)
                                SelectOption(null)
                                setAnchorEl(null)
                                SetSelectedFiles([])
                                SetMultimediaFiles([])
                                SetOtherFiles([])
            					setIds(null);
            					setFileUploadConfirmation(false);
                                setflag(false)
                            
            					}
                            } else if(result.status.responseType =="failure"){ 
                                dispatch(SetErrorMessage(result.errors));
                                SetSaveDataLoading(false)
                            }
                            else{
                                dispatch(SetErrorMessage(results.data));
                                SetSaveDataLoading(false)
                            }
                        })
                        .catch((error) => {
                        dispatch(SetErrorMessage(get(error, 'response', '')));
                        SetSaveDataLoading(false)
                        })
        }
    }
    const ImportFAQIntents = (files) => {
        SetSelectedFiles(files)
        if(files.length>0){
            for(var i=0;i<files.length;i++){
            var fileType=files[i].name.split(/\.(?=[^\.]+$)/)[1]
            
            if(fileType == "pdf" || fileType == "docx" || fileType == "doc" || fileType == "txt" || fileType == "xlsx" || fileType == "xls" || fileType == "csv" || fileType == "html" || fileType == "xls" || fileType == "htm"){
                otherFiles.push(files[i])
                setSelecteTab( "Other Documents("+otherFiles.length+")")
            }
            else{
                multimediaFiles.push(files[i])
                setSelecteTab("Multimedia Documents("+multimediaFiles.length+")")
            }
            }
        }
        if(multimediaFiles.length==0)
        {
            setSelecteTab("Other Documents("+otherFiles.length+")")
        }
        if(otherFiles.length==0){
            setSelecteTab( "Multimedia Documents("+multimediaFiles.length+")")
        }
       
        
    }
    const onchangeFile = () => {
        SetShowFiles(true)
        Setfailure(false)
        SetTabData([])
        setSelecteTab("")
    }
    const showDragDrop = () => {
        SelectOption("Local")
        setAnchorEl(null);
    }
    const showSharePoint = () => {
        SelectOption("sharePoint")
        setAnchorEl(null);
    }
 
    const close=()=>{
        SetShowFiles(true)
        SelectOption(null)
        setAnchorEl(null)
        SetSelectedFiles([])
        SetMultimediaFiles([])
        SetOtherFiles([])
        setflag(false)
    }

    const closeeditPopup = () =>{
        setEditPopup(false)
        handleTag('');
    }
    const descriptionValChange =(e)=>{
        setSaveSetting(false)
        setDescriptionVal(e.target.value)
    }
    const tagValChange =(e)=>{
        setTagVal(e.target.value)
    }

    const ChangeFilterType = (event, newValue) =>{
        SetFilterType(newValue)
    }

    const deleteSelctedRow=()=>{
        setTimeout(() => {
      
        const configObj = {
                'project': localStorage.getItem("projectname"),                
                'file':rowData.fileName ,
                'source': filterType.toLowerCase(),   
                'language':selectedLang           
            }
		var delArray = [];
		delArray.push(configObj);
        let url = config.CS_GOLANG + `/ctrainer/delete`;
            setUpdateDataLoading(true);
            botPostCalltoServer(url,configObj)
                .then(response => {
                    if (get(response, 'data.status', '') === "success") {
                        
                        setEditPopup(false)                        
                        setDeletePopup(false)
                        SetRowData(null)
                        setUpdateDataLoading(false);
                        dispatch(SetSuccessMessage('Document deleted successfully.')); 
                        setTimeout(() => {
                            getLatestDataForNotSearchableFiles();                            
                        }, 1000);
                        
                        setUpdateDataLoading(false);
                    }
                    else {
                        
                        dispatch(SetErrorMessage(get(response, 'data.error.reason', '')));
                        setUpdateDataLoading(false);
                    }
                }).catch(err => {
                    dispatch(SetErrorMessage(get(err, 'response', '')));
                    setUpdateDataLoading(false);
                });
            },1000)
            setDeletePopup(false)
    }
    const onRowClicked =(data)=>{
        setSaveSetting(true)
     SetRowData(data)
    setDescriptionVal(data.description)
   let tag = data.tags != null ? data.tags.trim():null
   if(tag)  setBadges(tag.split(","))
    setfileName(data.fileName)
}
    const updateSingleRow = () =>{ 

        let projectname = localStorage.getItem("projectname");
        if (!projectname)
            projectname = "common";
        let fileList = []
        let fileArray = []
        let formData = new FormData();
        
            let formFieldsObj = {}
            formFieldsObj["fileName"] = rowData.fileName;
            formFieldsObj["tags"] = badges.join();
            formFieldsObj["keywords"] = badges.join();
            formFieldsObj["ids"] = rowData.id;
            formFieldsObj["isValidate"] = true;
            formFieldsObj["language"] = localStorage.getItem("language");
            formFieldsObj["lastUpdateDate"] = moment(new Date()).format('DD-MM-YYYY hh:mm:ss A z');
            fileList.push(rowData.fileName)
            formData.append('file', rowData.fileName)
            
            let formObj = {}
            formObj[rowData.fileName] = formFieldsObj;
            fileArray.push(formObj);
        
        formData.append('project', projectname);
        formData.append('tags', badges.join());
        formData.append('keywords', rowData.keywords);
        formData.append('language',selectedLang);
        const orgName = localStorage.getItem("orgName");
        const username = localStorage.getItem("userName");
        let token = localStorage.getItem("accessToken");
        setLanguage(localStorage.getItem('language'))
        console.log(language,'language')
        // let token = decrypt("newAccessToken");
        let url=config.CS_GOLANG + `/ctrainer/train/upload?overwrite=retrain`
        onEditMultimediaRow(url,formData);
        setEditPopup(false);
        setTimeout(() => {
            getLatestDataForNotSearchableFiles();    
        }, 1000);
    }
    const clss = useStyles();
    const ClearSearchValue = ()=>{
        setSearchValue("");
        setShow(true);
    }

    const deleteFiles = () =>{
        var data = []
        var j=0
        for(var i=0;i<checkd.length;i++){
            if(checkd[i]==true){
                data.push({"project":localStorage.getItem('projectname'), "file":allRow[j].fileName,"source":filterType})
                j++;
            }
        }
        let url = config.BOTBUILDER_URL + `/cognitive/deleteMutlipleFiles`;
        setUpdateDataLoading(true);
        deleteCalltoServer(url, data)
            .then(response => {
                if (response.data.status.responseType == "success") {
                    setUpdateDataLoading(false);
                    dispatch(SetSuccessMessage(response.data.data));
                    getLatestDataForNotSearchableFiles()
                }
                else {
                    setUpdateDataLoading(false);
                    dispatch(SetErrorMessage(response.data.data));
                }
            }).catch(err => {
                setUpdateDataLoading(false);
                dispatch(SetErrorMessage('Error in file(s) deletion'));
            });
            setDeleteMultiplePopup(false);
            setAllRows([])
    }
  

    return (
        <React.Fragment>
            {updateDataLoading ?<SpinnerPage /> : null}
           
			<Dialog open={fileUploadConfirmation} aria-labelledby="form-dialog-title">
			<form>
			  <DialogContent>
			  {fileExistingMsg}
			  </DialogContent>
			  <DialogContentText>
			  </DialogContentText>
			  <DialogActions>
				<Button onClick={() => saveFiles(true)} color="primary">
				  OK
				</Button>
				<Button onClick={() => handleCloseWindow('fileUploadAlreadyExit')} color="primary">
				  Cancel
				</Button>
			  </DialogActions>
			</form>
		  </Dialog>
            <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    onClick={handleClose}
                    left={unset}
                    right={110}
                    className={clss.customStyle}
                    anchorPosition={{  
                                        right:"110px"
                                    }}
                    >
                    <MenuItem onClick={showDragDrop}>Local</MenuItem>
                    <MenuItem onClick={showSharePoint}>Share Point</MenuItem>
            </Menu>
            {option == "Local" ?
                <div>
                    <span style={{float:"right"}}><a onClick={close}><CloseIcon style={{fontSize:"40px", color:"dimgray"}}/></a></span>
                    <br></br>
                    <Dropzone ImportFAQIntents={ImportFAQIntents} showFiles={showFiles}
                        failure={failure} onchangeFile={onchangeFile}
                        showButton={false} 
                        length={selectedFiles.length}
                    />
                </div>:''
            }
          
            <Col md={12}>
             <h3 className='otherDocMedia'>Others</h3>
             </Col>
                <Col md={12} className={classes.otherDocumentsDropdownDiv}>
                    <Col md={3} className="viewing_knowledge">
                        <span className='viewing-text'>Viewing</span>
                        <Autocomplete
                            value={filterType}
                            onChange={(event, newValue) => {
                                ChangeFilterType(event, newValue)
                            }}
                            id="controllable-states-demo"
                            className={classes.autoCompleteTag}
                            options={['local', 'sharepoint', 'Webcrawler']}
                            renderInput={(params) => <TextField {...params}
                                InputProps={{ ...params.InputProps, disableUnderline: true }} />}
                        />
                    </Col>
                    <Col md={3}>
                        <SearchComponent className="searchIcon"
                            class={"input-group searchClass"}

                            Clear={ClearSearchValue}
                            show={show}
                            value={searchValue}
                            onSearch={(event) => onQuickFilterChanged(event)} />
                    </Col>
                </Col>
            {searchableFilesLoading ?
                <Row className="justify-content-md-center padding10">
                    <SpinnerPage animation="border" variant="primary" />
                </Row> :
                <React.Fragment>
                    { otherDocsPermission.R && nonSearchableData.length ?
                        <React.Fragment>
                            <Col md={12} className={classes.otherDocumentsDropdownDiv} style={{marginLeft:'15px', width:"97%"}}>
                                <h4 className='pt-1'><span style={{color:'#2A93DF'}}>{nonSearchableData && nonSearchableData.length} {nonSearchableData&& nonSearchableData.length==1 ?'file':"files"} </span> available. 
                                </h4>
                                <span style={{color:"rgb(42, 147, 223)", cursor:"pointer"}}>
                                    <span style={{display:numSelect>0?'inline':'none'}}><DeleteIcon onClick={()=>deleteMultipleFiles()}></DeleteIcon></span>
                                    <RefreshIcon onClick={()=>getLatestDataForNotSearchableFiles()}></RefreshIcon>
                                </span>
                            </Col>
                            {option == null ?
                                <Col md={12}>
                                    <div
                                        id="myGrid"
                                        className="ag-theme-alpine pt-1"
                                    >
                                        <TableContainer className={clss.tableContainer}>
                                            <Table aria-label="simple table" stickyHeader >
                                                <TableHead>
                                                    <TableRow >
                                                        <TableCell style={{backgroundColor:"#7D7D7D"}} padding="checkbox">
                                                            <Checkbox
                                                                indeterminate={numSelect > 0 && numSelect < rowcount}
                                                                checked={rowcount > 0 && numSelect === rowcount}
                                                                onClick={(event)=>{
                                                                        var check = [...checkd]
                                                                        if(event.target.checked == true){
                                                                            check.fill(true)
                                                                            setNumSelected(rowcount)
                                                                            setChecked(check)
                                                                            setAllRows(nonSearchableData)
                                                                            
                                                                        }
                                                                        else{
                                                                            check.fill(false)
                                                                            setNumSelected(0)
                                                                            setChecked(check)
                                                                            setAllRows([])
                                                                        }
                                                                }}
                                                                style ={{
                                                                    color: "#ffffff",
                                                                  }}
                                                            />
                                                        </TableCell>
                                                        {fields != undefined && fields !== null ? fields.map((headerName) => {
                                                            return (
                                                                <>
                                                                    <TableCell className={clss.tableHeader} onClick={() => handleSort(headerName)} style={{backgroundColor:"#7D7D7D",color:"#ffffff"}}>
                                                                        {headerName} {headerName == "File Name" ? sortDirection === 'asc' ? <ArrowUpwardIcon className={clss.iconStyle} /> : <ArrowDownwardIcon className={clss.iconStyle} />:''}
                                                                    </TableCell>

                                                                </>
                                                            )
                                                        }) : null}
                                                        <TableCell className={clss.tableHeader} style={{backgroundColor:"#7D7D7D"}}></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody >
                                                    {nonSearchableData != undefined && nonSearchableData !== null ?
                                                        orderBy(nonSearchableData, ['fileName'], sortDirection).filter((item)=>{
                                                            let fileType1 = item.fileName.split('.')
                                                            let fileType=fileType1[1]
                                                            return true
                                                         
                                                        }).filter((value) => {
                                                            return value.fileName.toLowerCase().includes(searchValue.toLowerCase())  
                                                        }).map((item,index) => {
                                                            return (
                                                                <>
                                                                    <TableRow key={item.id} >
                                                                        <TableCell padding="checkbox">
                                                                            <Checkbox
                                                                                checked={numSelect == rowcount? true:numSelect == 0? false:checkd[index]}
                                                                                onClick={(event)=>{
                                                                                    let i = numSelect
                                                                                    var check = [...checkd]
                                                                                    var row = [...allRow]
                                                                                    if(sortDirection === 'asc'){
                                                                                        row.sort((a, b) => {
                                                                                          if (a.fileName < b.fileName) return -1;
                                                                                          if (a.fileName > b.fileName) return 1;
                                                                                        });}
                                                                                    else{
                                                                                      row.sort((a, b) => {
                                                                                      if (a.fileName > b.fileName) return -1;
                                                                                      if (a.fileName < b.fileName) return 1;
                                                                                    });
                                                                                    }
                                                                                    if(event.target.checked == true){
                                                                                        i++
                                                                                        check[index]=true
                                                                                        row.push(item)
                                                                                        setAllRows(row)
                                                                                    }
                                                                                    else{
                                                                                        i--
                                                                                        check[index]=false
                                                                                        /*var filtered = allRow.filter(function(value){ 
                                                                                            return value != item;
                                                                                        });*/
                                                                                        setAllRows(row)
                                                                                    }
                                                                                    setNumSelected(i)
                                                                                    setChecked(check)
                                                                                }}
                                                                                style ={{
                                                                                    color: "rgb(42, 147, 223)",
                                                                                  }}
                                                                            />
                                                                        </TableCell>
                                                                    <LightTooltip arrow title={item.fileName} placement="top-start">
                                                                            <TableCell className={clss.tableCell} >
                                                                               {item.fileName.length > 30 ? item.fileName.slice(0, 30) + '...' : item.fileName}
                                                                            </TableCell>
                                                                    </LightTooltip>
                                                                        <TableCell className={clss.tableCell} >
                                                                            {item.size}
                                                                        </TableCell>
                                                                      
                                                        
                                                                        <TableCell className={clss.tableCell}>
                                                                            {item.lastUpdateDate}
                                                                        </TableCell>
                                                                        <LightTooltip arrow title={item.keywords} placement="top-start">
                                                                            <TableCell className="tableCell">
                                                                            { item.tags != null && item.tags.slice(0, 100)}
                                                                            </TableCell>
                                                                        </LightTooltip>
                                                                    
                                                                        <TableCell className={clss.tableCell}>
                                                                            {item.status}
                                                                        </TableCell>
                                                                        <TableCell onClick={() => onRowClicked(item)} className={clss.tableCell}>
                                                                            <img src='./Icons/OptionsIcon.svg'  style={{ position: 'relative', cursor: 'pointer', padding: '14px' }}
                                                                                aria-controls="simple-menu" aria-haspopup="true" onClick={handleClickMenu} />
                                         
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </>
                                                            )
                                                        }) : null}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <Menu
                                            id="multimediaDocMenu"
                                            anchorEl={menuShow}
                                            keepMounted
                                            open={Boolean(menuShow)}
                                            onClose={handleCloseMenu}
                                        >
                                            <MenuItem className={clss.menuItems} onClick={editRow}>EDIT</MenuItem> 
                                            <MenuItem disabled={otherDocsPermission.R && otherDocsPermission.WR ? false : true} className={clss.menuItems} onClick={(event) => deleteConfirmation()}>DELETE</MenuItem>
                                            
                                        </Menu>
                                    </div>

                                </Col> : ''}
                        </React.Fragment> :

                        selectedFiles.length == 0 && option == null ? <div className={clss.noDataContent}> 
                        <div className="d-flex justify-content-center">
                            <div className={clss.noDataText}>Other Documents and Multimedia files are not added</div>
                        </div>
                            {otherDocsPermission.WR && <div className="d-flex justify-content-center">
                                <div className={clss.addDoc} onClick={openOtherDocumentDrawer}>Add Other Document/Multimedia files</div>
                            </div>}
                            
                        </div>: ''
                    }

                </React.Fragment>
            }
            <Dialog open={false} onClose='' aria-labelledby="form-dialog-title" className={clss.style}>
            <form style={{width:"600px",backgroundColor:"rgb(232 234 242)"}}>
                <div style={{color:"#007bff",fontSize:"20px",padding:"30px",backgroundColor:"rgb(232 234 242)"}}>{fileName} 
                <span style={{ float:"right"}}><a onClick={closeeditPopup}><CloseIcon style={{color:"rgb(41 40 40)",fontSize:"25px"}}/></a></span>
                <div style={{paddingRight:"30px",paddingTop:"20px",backgroundColor:"rgb(232 234 242)"}}>
                    <TextField className={""} id="outlined-required" label="Description" type="text" value={descriptionVal} fullWidth="true" variant="outlined" maxRows={4}
                    onChange={descriptionValChange} multiline="true" size="small" color="primary" style={{backgroundColor:"white",marginBottom:"25px"}}/>
                    <TextField className={""} label="Tags" type="text" value={tagVal} fullWidth="true" variant="outlined" maxRows={4}
                    onChange={tagValChange} multiline="true" size="small" color="primary" style={{backgroundColor:"white"}}/>
                </div>
                </div>
                    <DialogActions style={{backgroundColor:"rgb(232 234 242)",justifyContent:"center",marginBottom:"10px"}}>
                        <Button onClick={updateSingleRow} color="primary" style={{borderRadius:"20px",width:"100px",height:"40px"}}>
                            Save
                        </Button>
                        <Button onClick={(event)=>deleteSelctedRow()} color="primary"  style={{borderRadius:"20px",width:"100px",height:"40px"}}>
                        Delete
                        </Button>
                    </DialogActions>
            </form>
            </Dialog>

            <React.Fragment>
               <DrawerComponent heading="Others"
                    showDrawer={closeeditPopup}
                    openDrawer={editPopup}>
                    <div className='drawer'>
                        <div className='drawer-body mt-2'>
                        <form>
                                <InputWithLabelComponent
                                    placeHolder=''
                                    className={'drawer-input'}
                                    type="text"
                                    name="name"
                                    label="File Name*"
                                    value={fileName}
                                    disabled={true}
                                />
                                <div className='mt-3'>
                                  
                               </div>
                               <div className='mt-3'>
                                  
                                    <div className='tags-input-div'>
                                        <InputWithLabelComponent
                                            placeHolder=''
                                            className={'drawer-input'}
                                            type="text"
                                            name="tags"
                                            label={"Keywords*"}
                                            value={tag}
                                            onChange={(e) => handleTag(e.target.value)}
                                            isInvalid={badges.length==0?true:false}
                                            error={"Please add tag."}
                                            maxLength={'50'}
                                        />
                                        <ButtonComponent
                                            variant="contained"
                                            className="buttonWidth addTagBtn"
                                            label={"ADD"}
                                            clicked={() => handleAddTag()}
                                            btnType="blueButton"
                                            disabled={badges.includes(tag) || tag.trim().startsWith(" ") || tag.trim()=== ""}
                                        />            
                                        </div>
                                    
                                    <div className='my-3 w-400'>
                                        {badges.map((badge, i) => <BadgeComponent key={i} label={badge} clicked={() => badgeClick(i)} />)}
                                    </div>
                                       </div>
    
                                <div className="form-group submit-btn-parent mt-3">
                                    <ButtonComponent 
                                    clicked={updateSingleRow} 
                                    disabled={badges.length === 0 || saveSetting}  
                                    label={"SAVE"}
                                    variant="contained"
                                    className="buttonWidth"
                                    />
                                        
                                </div>
                            </form>
                        </div>
                    </div>      </DrawerComponent>
           
            </React.Fragment>
			 
            <Dialog open={deletePopup} onClose='' aria-labelledby="form-dialog-title" >
            <form style={{width:"600px"}} className="deleteMultimediaForm">
                    <DialogTitle>Delete Confirmation<CloseIcon
                     className="d-flex closeDelMultimedia"
                     onClick={() => handleCloseWindow('deleteConfirmationCancel')
                    }
                     style={{ fontSize: 25 }}
                  /></DialogTitle>
                    <DialogContentText>Are you sure you want to delete the document?</DialogContentText>       
                    <DialogActions>
                         <div className="form-group submit-btn-parent mt-3">
                            <ButtonComponent 
                                clicked={() => handleCloseWindow('deleteConfirmationCancel')} 
                                label={"NO"}
                                variant="outlined"
                                className="buttonWidth"
                                />  
                            <ButtonComponent 
                            clicked={(event)=>deleteSelctedRow()} 
                            label={"YES"}
                            variant="contained"
                            className="buttonWidth ml-2"
                            />
                              
                        </div>
</DialogActions>
            </form>
            </Dialog>







            <Dialog open={deleteMultiplePopup} onClose='' aria-labelledby="form-dialog-title" >
            <form style={{width:"600px"}} className="deleteMultimediaForm">
                    <DialogTitle>Delete Confirmation <CloseIcon
                     className="d-flex closeDelMultimedia"
                     onClick={() => handleCloseWindow('deletemultipleConfirmationCancel')
                    }
                     style={{ fontSize: 25 }}
                  /></DialogTitle>
                    <DialogContentText>Are you sure you want to delete the documents?</DialogContentText>       
                    <DialogActions>
                         <div className="form-group submit-btn-parent mt-3">
                            <ButtonComponent 
                                clicked={() => handleCloseWindow('deletemultipleConfirmationCancel')} 
                                label={"NO"}
                                variant="outlined"
                                className="buttonWidth"
                                />  
                            <ButtonComponent 
                            clicked={()=>deleteFiles()} 
                            label={"YES"}
                            variant="contained"
                            className="buttonWidth ml-2"
                            />
                              
                        </div>
</DialogActions>
            </form>
            </Dialog>
        </React.Fragment>                   
    )
}

ManageFileData.propTypes = {};

ManageFileData.defaultProps = {};

const mapStateToProps = (state) => {
    return {
        loading:state.cognitive.loading,
        edited:state.cognitive.edited
    };
  };
  const mapDispatchToProps = (dispatch) => {
    return {
        onEditMultimediaRow:(url,formData) => dispatch(actions.editMultimediaRow(url,formData)) ,
    };
  };
//export default ManageFileData;
export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(withErrorHandler(ManageFileData, axios));

