import React, { useRef, useState, useEffect } from 'react';
import './Dropzone.css';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Button } from 'react-bootstrap';
import ValidationMsg from '../../ValidationMsg/ValidationMsg';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { Modal, ProgressBar } from 'react-bootstrap';

const DropzoneComponent = (props) => {
    const fileInputRef = useRef();
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [validFiles, setValidFiles] = useState([]);
    const [unsupportedFiles, setUnsupportedFiles] = useState([]);
    const [errorMessage] = useState('');
    const [validation, setvalidationMessage] = useState(null);
    const [infoModel, modelInfo] = useState(false);
    const [englishFiles, setEnglishFiles] = useState([]);
    const [nonEnglishFiles, setNonEnglishFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
  
  
    useEffect(() => {
        ValidationMessage("files", selectedFiles.length)
    }, [props.isInvalid]);

    useEffect(() => {
        props.deleteNonEngFiles(setNonEnglishFiles)
    }, [nonEnglishFiles]);
        
    useEffect(() => {
        let filteredArr = selectedFiles.reduce((acc, current) => {
            const x = acc.find(item => item.name === current.name);
            if (!x) {
                return acc.concat([current]);
            } else {
                return acc;
            }
        }, []);
        setValidFiles([...filteredArr]);


        if (props.showButton == false) {

            props.ImportFAQIntents([...englishFiles])

        }
    }, [selectedFiles,englishFiles]);

        useEffect(()=>{
            setValidFiles([])
         },[props.fileType])

    const preventDefault = (e) => {
        e.preventDefault();
    }

    const dragOver = (e) => {
        preventDefault(e);
    }

    const dragEnter = (e) => {
        preventDefault(e);
    }

    const dragLeave = (e) => {
        preventDefault(e);
    }

    const fileDrop = (e) => {
        preventDefault(e);
        const files = e.dataTransfer.files;
        let filteredArr=[]
        if (files.length) {
            if (props.failure || props.showFiles) {

                setValidFiles([]);
                setSelectedFiles([])
            }
            for (var i = 0; i < files.length; i++) {
                var fileType = files[i].name.split(/\.(?=[^\.]+$)/)[1]
                fileType = fileType.toLowerCase()
                if (fileType == "pdf" || fileType == "docx" || fileType == "doc" || fileType == "txt" || fileType == "xlsx" || fileType == "xls" || fileType == "csv" || fileType == "html" || fileType == "xls" || fileType == "htm" || fileType == "ppt" || fileType == "pptx") {
                    if (props.fileType == "Other Document") {
                        filteredArr.push(files[i])
                    } 
                }
                else {
                    if (props.fileType == "Multimedia") {
                        filteredArr.push(files[i])
                    } 
                }
            }
            handleFiles(filteredArr);
        }
    }

    const filesSelected = () => {
            let files =fileInputRef.current.files
            let filteredArr=[];
        if (fileInputRef.current.files.length) {
            if (props.failure || props.showFiles) {
                setValidFiles([])
                setSelectedFiles([])
            }
            for (var i = 0; i < files.length; i++) {
                var fileType = files[i].name.split(/\.(?=[^\.]+$)/)[1]
                fileType = fileType.toLowerCase()
                if (props.fileType == "Other Document") {
                    if(fileType == "pdf" || fileType == "docx" || fileType == "doc" || fileType == "txt" || fileType == "xlsx" || fileType == "xls" || fileType == "csv" || fileType == "html" || fileType == "xls" || fileType == "htm" || fileType == "ppt" || fileType == "pptx"){
                        filteredArr.push(files[i])
                       props.onUndoInvalid(false)
                        
                    }else{
                        props.onUndoInvalid(true)
                    }
                }
                if(props.fileType == "Multimedia"){
                    if(fileType == "pdf" || fileType == "docx" || fileType == "doc" || fileType == "txt" || fileType == "xlsx" || fileType == "xls" || fileType == "csv" || fileType == "html" || fileType == "xls" || fileType == "htm" || fileType == "ppt" || fileType == "pptx"){
                       props.onUndoInvalid(true)
                    }else{
                        filteredArr.push(files[i])
                        props.onUndoInvalid(false)
                      
                    }
                }


                
                
            }
            handleFiles(filteredArr);
        }
    }

   

    const fileInputClicked = () => {
        fileInputRef.current.click();
    }

    const url=props.config.FileUpload_URL+"/cognitive/validateDocumentLanguage";
    let language = localStorage.getItem("language");
  
    const handleFiles = (files) => {
        setIsLoading(true);
        props.onchangeFile(validFiles);
        var arr = validFiles
        for (let i = 0; i < files.length; i++) {

            arr.push(files[i])
        }
        setSelectedFiles(arr);
        ValidationMessage("files", selectedFiles.length)

        const formData = new FormData();
        formData.append("language", language);
        var test = [];
        test = Array.from(new Set(validFiles));
        test.forEach((file) => {
          formData.append("files", file);
        });




        let token = localStorage.getItem("accessToken");
        let updatedEnglishFiles = [];
        let updatedNonEnglishFiles = [];


        fetch(url, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }).then((res) => {
              if (!res.ok) {
                throw new Error(`HTTP error! Status: ${res.status}`);
              }
                return res.json()
          } ).then((res) => {  
              
              res.data.forEach((fileInfo) => {
                if (fileInfo.isValid === "true") {
                  const matchedFile = files.find(
                    (file) => file.name === fileInfo.fileName
                  );
                  if (matchedFile) {
                    updatedEnglishFiles.push(matchedFile);
                   
                  }
                } else {
                  const matchedFile = files.find(
                    (file) => file.name === fileInfo.fileName
                  );
                  if (matchedFile) {
                    updatedNonEnglishFiles.push(matchedFile);
                  }
                }
              });
              if (updatedEnglishFiles.length) {
                updatedEnglishFiles = Array.from(new Set(updatedEnglishFiles));
              }
            
                 const uniqueEng= Array.from(new Set( [...updatedEnglishFiles,...englishFiles]))  
                 const uniqueNon= Array.from(new Set( [...updatedNonEnglishFiles,...nonEnglishFiles]))  

                 const uniqueEngFiles =uniqueEng.filter((file, index, arr) => {
                  return arr.findIndex((f) => f.name === file.name) === index;
                 });

                 const uniqueNonEng =uniqueNon.filter((file, index, arr) => {
                    return arr.findIndex((f) => f.name === file.name) === index;
                 });
                
              
             
              setEnglishFiles(uniqueEngFiles)  
              setNonEnglishFiles(uniqueNonEng);
              setIsLoading(false);
              
            })
            .catch((err) => {
              setIsLoading(false);
              setShowModal(false);
              console.log(err);
            });

    }

    const ValidationMessage = (type, value) => {

        if (type == "files") {
            value < 0 ? setvalidationMessage(<ValidationMsg>File is required</ValidationMsg>) :
                setvalidationMessage(null);
        }
        if (type == "filesLength") {
            value.trim() === ''
                ? setvalidationMessage(<ValidationMsg>File is required</ValidationMsg>) :
                setvalidationMessage(null);
        }

    }

    const fileSize = (size) => {
        if (size === 0) {
            return '0 Bytes';
        }
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(size) / Math.log(k));
        return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    }

    const fileType = (fileName) => {
        return fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || fileName;
    }
    const removeEnglishFiles=(name)=>{
        const updatFiles= englishFiles.filter(file=> file.name !==name);
        setEnglishFiles(updatFiles)
    }
    const removeFile = (name) => {
        removeEnglishFiles(name)
        const index = validFiles.findIndex(e => e.name === name);
        const index2 = selectedFiles.findIndex(e => e.name === name);
        const index3 = unsupportedFiles.findIndex(e => e.name === name);
        validFiles.splice(index, 1);
        selectedFiles.splice(index2, 1);
        setValidFiles([...validFiles]);
        setSelectedFiles([...selectedFiles]);
        if (index3 !== -1) {
            unsupportedFiles.splice(index3, 1);
            setUnsupportedFiles([...unsupportedFiles]);
        }
        props.onchangeFile(validFiles)
    }

    const handleClose = () => {
        modelInfo(false)
    }

   
    
    return (
        <>
            <div style={{ height: "auto" }}>
                <div className="dropZone">
                    <div className="drop-container"
                        onDragOver={dragOver}
                        onDragEnter={dragEnter}
                        onDragLeave={dragLeave}
                        onDrop={fileDrop}
                        onClick={fileInputClicked}
                    >
                        <div className="drop-message">
                            Drag & Drop files here or click to select file(s)<br/>                           
                        </div>
                        <input
                            ref={fileInputRef}
                            className="file-input"
                            type="file"
                            onChange={filesSelected}
                            multiple
                            onClick={(event) => { event.target.value = null }}
                        />

                    </div>
                </div>
                 {props.isInvalid && <div className="default-css error-css desc-err">
                    Please add file.
                </div>}
                  {props.invalidFile==true && <div className="default-css error-css desc-err">
                    Please select from the below file type.
                </div>}
                <div className='drop-zone-file-container mt-1'>
                    <div className='file-error-message alignLeft' style={props.failure ? { display: 'block' } : { display: 'none' }}>
                        {props.knowledgeUpload}
                        <h4 style={{ color: 'red', fontSize: "inherit" }}>Please remove all unsupported files.</h4>

                    </div>
                    {unsupportedFiles.length ? <h4 style={{ color: 'red' }}>Please remove all unsupported files.</h4> : ''}
                    <div className="row fileContainer">
                        <div className="file-display-container">
                        {isLoading && (<ProgressBar animated now={100} label={`Validating the files...`}     />)} 
                            {
                                props.showFiles &&
                                (englishFiles.map((data, i) =>
                                    <div xs={12} className="file-status-bar" key={i}>
                                        <div xs={10}>
                                            <div xs={1} className="file-type-logo"></div>
                                            <div xs={2} className="file-type">{fileType(data.name)}</div>
                                            <div xs={4} className={`file-name file-discription ${data.invalid ? 'file-error' : ''}`}  style={{overflow:'hidden'}}>{data.name}
                                            <span className="file-size">({fileSize(data.size)})</span> </div>
                                            {data.invalid && <span className='file-error-message'>({errorMessage})</span>}
                                        </div>
                                        <div xs={1} className='pl-4 file-discription'><TaskAltIcon className='pr-1'> </TaskAltIcon> Ready to upload</div>
                                        <div xs={1} className="file-remove" onClick={() => removeFile(data.name)}>Remove</div>
                                    </div>
                                ))
                            }
                            {nonEnglishFiles.length>0 ? <p style={{color:'gray',fontFamily:'Roboto-Black' , textAlign:'center',paddingTop:'15px'}}>Invalid documents due to project language mismatch</p>:""}
                            
                            {
                                nonEnglishFiles.length>0? ( nonEnglishFiles.map(file=><div  className='errorFiles'>{file.name}</div>)):''
                            }
                           
                        </div>
                    </div>
                </div>
            </div>

            {props.uploadloadingImg}

            <div className="dropZone">
                {validation}
            </div>
            <div >
            </div>

            <Dialog open={infoModel} onClose={handleClose} width="100px" aria-labelledby="form-dialog-title">
                <form style={{ background: "#e3e8fb" }}>
                    <DialogTitle id="form-dialog-title">File(s) Information</DialogTitle>
                    <div style={{ padding: '10px' }}>
                        <DialogContent style={{ border: '3px solid #b6cfe9', padding: '2px' }}>
                            <div style={{ padding: '5px', background: "white" }}>
                                <div><strong>Excel/CSV &nbsp; - &nbsp;</strong> The file(s) have to be in the below format. Abiding by this will allow the Excel file to upload.</div>
                                <p><br></br>question_shortDesc/question/questions | alternate_questions | answer/answers/response/responses<br></br>
                                    --------------------------------------------------------------------------------------</p>
                                <p>All other File formats- Please ensure the files are in Question/Answer format.</p>
                            </div>
                        </DialogContent>
                    </div>
                    <DialogActions>

                        <Button onClick={handleClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
}

export default DropzoneComponent;
