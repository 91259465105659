// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.primary-button-loader .btn-primary:not([disabled]) {
    background-color: #2A93DF;
    color: #FFFFFF;
}

.primary-button-loader .btn-primary {
    width: 160px;
    height: 32px;
    font-family: 'Roboto-Bold';
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    letter-spacing: 0.04em;
    /* padding: .375rem .75rem; */
}

.primary-button-loader .btn-primary:not([disabled]):hover {
    /* background-color: #006BB6; */
    background: #2A93DF;
}

.primary-button-loader .btn-primary:disabled {
    background-color: #B8BEC3;
    color: #FFFFFF;
    border: none;
}

.primary-button-loader .trained-btn {
    font-size: 12px; 
    }
`, "",{"version":3,"sources":["webpack://./src/containers/SettingPage/SettingInnerPages/components/PrimaryButtonWithLoader/primary-button-loader.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,0BAA0B;IAC1B,eAAe;IACf,iBAAiB;IACjB,yBAAyB;IACzB,sBAAsB;IACtB,6BAA6B;AACjC;;AAEA;IACI,+BAA+B;IAC/B,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,YAAY;AAChB;;AAEA;IACI,eAAe;IACf","sourcesContent":[".primary-button-loader .btn-primary:not([disabled]) {\n    background-color: #2A93DF;\n    color: #FFFFFF;\n}\n\n.primary-button-loader .btn-primary {\n    width: 160px;\n    height: 32px;\n    font-family: 'Roboto-Bold';\n    font-size: 14px;\n    line-height: 16px;\n    text-transform: uppercase;\n    letter-spacing: 0.04em;\n    /* padding: .375rem .75rem; */\n}\n\n.primary-button-loader .btn-primary:not([disabled]):hover {\n    /* background-color: #006BB6; */\n    background: #2A93DF;\n}\n\n.primary-button-loader .btn-primary:disabled {\n    background-color: #B8BEC3;\n    color: #FFFFFF;\n    border: none;\n}\n\n.primary-button-loader .trained-btn {\n    font-size: 12px; \n    }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
