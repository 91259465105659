import React, { memo, useMemo, useEffect } from 'react';
import { Handle, useUpdateNodeInternals } from 'react-flow-renderer';
import NodeBox from './NodeBox';

export default memo(({ id, data, isConnectable }) => {
    
  let sourceArray = data.branchOptions ? data.branchOptions : [];
  const nodeId = id;
  let segment = 220/sourceArray.length;
  let gap = segment/2;

  const positionHandle = (index) => {
    return segment * index + gap ;
  }

  useEffect(() => {
    updateNodeInternals(nodeId);
  }, [sourceArray, data.branched])

  const updateNodeInternals = useUpdateNodeInternals();

  const sourceHandles = useMemo(
    () =>
        sourceArray.map((option, index) => {
            const handleId = option;
            return (
                <Handle
                    key={index}
                    type="source"
                    position="bottom"
                    id={handleId}
                    style={{ left: positionHandle(parseInt(index)) }}
                />
            );
        }),
    [sourceArray]
  );

  const defaultHandle = useMemo(
    () =>
        <Handle
            key='1'
            type="source"
            position="bottom"
            isConnectable={isConnectable}
        />
  );

  return (
    <>
      <Handle
        type="target"
        position="top"
        style={{ background: '#555' }}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />
      <NodeBox type='Expression' data={data}></NodeBox>
      {data.branched === 'true' ? sourceHandles : defaultHandle}
      
    </>
  );
});