import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import "./drawerCluster.css";
import Grid from "@material-ui/core/Grid";
import Checkbox from '@material-ui/core/Checkbox';
import InputWithLabelComponent from "../components/InputWithLabelComponent/InputWithLabelComponent";
import Radio from '@mui/material/Radio';
import ReactMultiSelectComponent from '../SecheduleMeeting/ReactSelectComponent'
import PrimaryButtonWithLoader from "../components/PrimaryButtonWithLoader/PrimaryButtonWithLoader";
import {  useSelector, useDispatch } from "react-redux";
import {GetSourceAttributes} from '../../../../store/actions/index';
import { connect } from 'react-redux';
import *as actions from '../../../../store/actions/index'
import { handleUpdate} from "../../../../store/actions/index";


const ClusterDrawer= (props,config) => {
    const [attributes, setAttributes] = useState([' ']);
    const [values,setValue]=useState([]);
    const[val,setVal]=useState([]);
    const[displayval,setDisplayVal]=useState([]);
    const dispatch = useDispatch();
    const [drawerData , setDrawerData] = useState([]);
    const [noOfCluster , setNoOfCluster] = useState(null)
    const [source, setSource] = useState('');
    const [customDefined, setCustomDefined] = useState(null);
    const [systemDefined, setSystemDefined] = useState(null);
    const [sentiment, setSentiment] = useState(null);
    const [useCaseName, setUseCaseName] = useState('');
    const [model, setModel] = useState('');
    const [status, setStatus] = useState('');
    const [description, setDescription] = useState('');





    const  onSelectAttributes=(event) =>{
        var temp=[]
        for(var i=0;i<event.length;i++){ 
                temp.push(event[i].value)
        }
        setVal([...values]);
        setValue([...temp]);
      }
    const onRemoveAttributes = (item) =>{
        var index = values.indexOf(item)
        values.splice(index,1)
        setValue([...values])
      }
      useEffect(() => {
        if(props.useCaseDrawerData != null || props.useCaseDrawerData != undefined){
            setNoOfCluster(props.useCaseDrawerData.noOfCluster)
            setSource(props.useCaseDrawerData.source)
            setCustomDefined(props.useCaseDrawerData.customDefined)
            setSystemDefined(props.useCaseDrawerData.systemDefined)
            setSentiment(props.useCaseDrawerData.sentiment)
            setUseCaseName(props.useCaseDrawerData.useCaseName)
            setModel(props.useCaseDrawerData.model)
            setDescription(props.useCaseDrawerData.description)
            setStatus(props.useCaseDrawerData.status)
            setValue(props.useCaseDrawerData.attributes)
            }
            // if(SourceAttributes_Data !=null)
            // {
            //     setAttributes(SourceAttributes_Data)
            // }
            // else{
            //     setAttributes(['Item 1', 'Item 2', 'Item 3'])
            // }
      }, [props.useCaseDrawerData]);
      useEffect(() => {
        setTimeout(() => {
            if(SourceAttributes_Data !=null)
            {
                setAttributes(SourceAttributes_Data)
            }
        },1000);
      },);
      

  const SourceAttributes_Data = useSelector((state) => state.UnsupervisedML.DataAttributes);
  const handleSystemDefined = (e) =>{
  setSystemDefined(e.target.checked)
  setCustomDefined(false)
  }
  const handleCustomDefined = (e) =>{
  setCustomDefined(e.target.checked)
  setSystemDefined(false)
  }
  const handleChangeInput = (e) =>{
    setNoOfCluster(e.target.value)
  }
  const onSelectAllClick = (event) =>{
    setSentiment(event.target.checked)
  }
  const onApply = ()=>{
    let data = {
        "useCaseName": useCaseName,
        "description": description,
        "project": localStorage.getItem('projectname'),
        "orgName": localStorage.getItem('orgName'),
        "model": model,
        "status": status,
        "attributes": values,
        "source": source,
        "systemDefined": systemDefined,
        "customDefined": customDefined,
        "sentiment": sentiment,
        "noOfCluster": noOfCluster,
      }
    dispatch(handleUpdate('http://10.14.108.21:32470',data))
    const dataa = "false";
    props.onDataPassed(dataa);
    }
   
    return(
        <React.Fragment>
        <div className="d-flex">
            <Col className="col-md-12 d-flex productContent">
                <span>Attributes for training model on products</span>
            </Col>
        </div>
        <div className="col-md-5">
            <p className="mb-2 source-cls">Source</p>
            <p className="mb-4 source-body">{source}</p>
        </div>
        <Grid item lg={6} className="pl-1" >
            <ReactMultiSelectComponent
                        label="Source Attributes"
                        onChange={(event) =>onSelectAttributes(event)}
                        //  required={true}
                        value={values}
                        options={attributes && attributes.map(a =>(
                            {value:a,label:a}))}
                        onRemoveItem={onRemoveAttributes}
                        placeholder={'Select'}
                        /> 
        </Grid>
            <div className="col-md-12 divborder">
                <p className="mb-2 cluster-cls">Number of Cluster</p>
                <div style={{display:"flex"}}>
                    <label className="btnradio">
                        <input
                            type="radio"
                            value="System defined cluster"
                            checked={systemDefined == true && customDefined == false}
                            onChange={(e)=>handleSystemDefined(e)}
                        />
                        <div className="btntxt">System Defined Cluster</div>
                    </label>

                    <label className="radio-container">
                        <input
                            type="radio"
                            value="Custom Defined Cluster"
                            checked={customDefined == true && systemDefined == false}
                            onChange={(e)=>handleCustomDefined(e)}
                        />
                        <div className="text-container">Custom Defined Cluster</div>
                            <InputWithLabelComponent
                                placeHolder=''
                                className={'drawer-input txtcolumn'}
                                type="text"
                                name="keyword"
                                label={""}
                                value={noOfCluster} 
                                onChange={(e) =>handleChangeInput(e)}
                                maxLength={'50'}
                           />
                    </label>
                </div>
            </div>
        <div className="d-flex checkbox-align">
            <Checkbox 
                color="default" 
                className="checkbox" 
                key="headCheckbox"
                // indeterminate={this.state.numSelected > 0 && this.state.numSelected < this.state.rowCount}
                checked={sentiment}
                onClick={(event)=>onSelectAllClick(event)}
            />
                <span className="alignchkbox">Add Sentiments in the output</span>

        </div>
        <div className="d-flex applybtn">
                  <Col xs="4">
                    <PrimaryButtonWithLoader
                      className="mt-4"
                      size="lg"
                      onClick={()=>onApply()}
                      text="Apply"
                    //   disabled={btnDisable}
                    />
                  </Col>
                </div>
        </React.Fragment>

    )
}
// export default ClusterDrawer;
const mapStateToProps = state => {
    return {
        SourceAttributes_Data: state.UnsupervisedML.DataAttributes,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        OnGetLanguage: (apiUrl) => dispatch(actions.GetLanguage(apiUrl)),
        onFetchSourceAttributes : (COMMON_API_URL,data) => dispatch(actions.FetchSourceAttributes (COMMON_API_URL,data)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ClusterDrawer);