import getProjectDetails from "../../../../../helpers/getProjectDetails";

const getProductAttributesData = () => {
  const projectName = getProjectDetails();
  return [
      {"source_name": "productattributes", project: projectName},
      {"source_name": "rootcause", project: projectName},
      {"source_name": "symptoms", project: projectName}
    ];
};

export default getProductAttributesData;
