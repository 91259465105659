import get from 'lodash/get';

const fetchProductTriageInsightsFailureReducer = (state, payload) => {
    return {
        ...state,
        agentInsightsLoading: false,
        error: get(payload, 'error', false)
    };
};

export default fetchProductTriageInsightsFailureReducer;