
const getRootCausesDataReducer = (state, payload) => {
    return {
        ...state,
        rootCausesTabData: {
            ...state.rootCausesTabData,
            rootCausesData: payload
        }
    }
}

export default getRootCausesDataReducer;