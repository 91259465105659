import getProjectDetails from "../../../../../helpers/getProjectDetails";

const getRunModalBodyData = (config) => {
  const projectName = getProjectDetails();
  return [
    // {
    //   method: "post",
    //   sequence: 1,
    //   url: "/product-intelligent-triage/settings/reindex",
    //   requestdata: {
    //     sourceIndex: config.SOURCE_URL,
    //     desitnationIndex: "producttriage",
    //     project:projectName,
    //   },
    // },
	{
      method: "post",
      sequence: 1,
      url: `/product-intelligent-triage/settings/ProductdetailsData/${projectName}`,
      requestdata: {
        collection: "producttriage",
      },
    },
    {
      method: "post",
      sequence: 2,
      url: "/product-intelligent-triage/api/symptoms/model_build",
      requestdata: {
        ngram_range_min: 2,
        ngram_range_max: 5,
        embedding: "paraphrase-MiniLM-L6-v2",
        no_topics: "auto",
        collection: "producttriage",
        project:projectName,
      },
    },
    {
      method: "post",
      sequence: 3,
      url: "/product-intelligent-triage/api/root_cause/model_build",
      requestdata: {
        ngram_range_min: 2,
        ngram_range_max: 5,
        embedding: "paraphrase-MiniLM-L6-v2",
        no_topics: "auto",
        collection: "producttriage",
        project:projectName,
      },
    },
    {
      method: "post",
      sequence: 4,
      url: "/product-intelligent-triage/api/symptoms/prediction",
      requestdata: {
        collection: "producttriage",
        project:projectName,
      },
    },
    {
      method: "post",
      sequence: 5,
      url: "/product-intelligent-triage/api/root_cause/prediction",
      requestdata: {
        collection: "producttriage",
        project:projectName,
      },
    },
    {
      method: "get",
      sequence: 6,
      url: `/product-intelligent-triage/settings/symptomsrootcausemap/producttriage/project/${projectName}`,
      requestdata: {
        collection: "producttriage",
      },
    },
    {
      method: "post",
      sequence: 7,
      url: `/product-intelligent-triage/settings/generate/questions/project/${projectName}`,
      requestdata: {},
    },
    {
      method: "post",
      sequence: 8,
      url: `/product-intelligent-triage/settings/questions/project/${projectName}`,
      requestdata: {},
    },
  ];
};

export default getRunModalBodyData;
