const similarIssuesOptinalFieldResethandlerReducer = (state, payload) => {
    const similarIssuesOptionalField1Value = state.similarIssuesOptionalField1;
    const similarIssuesOptionalField2Value = state.similarIssuesOptionalField2;
    if (payload.id === 'similarIssuesOptionalField1' && similarIssuesOptionalField2Value === payload.value) {
        return {
            ...state,
            similarIssuesOptionalField2: ''
        }
    }
    if (payload.id === 'similarIssuesOptionalField2' && similarIssuesOptionalField1Value === payload.value) {
        return {
            ...state,
            similarIssuesOptionalField1: ''
        }
    }
    return state;
}

export default similarIssuesOptinalFieldResethandlerReducer;