import React from 'react';
import Select, { components } from 'react-select';
import isEmpty from 'lodash/isEmpty';
import Form from 'react-bootstrap/Form';
import * as PropTypes from 'prop-types';
import "./react-select.css";
import { isNil } from 'lodash';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const Placeholder = props => {
    return <components.Placeholder {...props} />;
};

const CaretDownIcon = () => {
    return <ArrowDropDownIcon style={{width: '32px',height:"32px",position:"absolute",top:"3px",right:"0px"}}/>;
};

const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon />
      </components.DropdownIndicator>
    );
};

const ReactMultiSelectComponent = ({
    options,
    value,
    onChange,
    label,
    allOption,
    disabled,
    placeholder,
    onRemoveItem,
    ...rest
}) => (
        <div >
            {label ?
                <div className="common-input">
                <Form.Label data-testid={`label1`} className="mb-1 text-box-title adding-other-input" 
                style={{
                    // paddingBottom: "6px",
                     marginLeft:"-12px"}}
                >
                  {label}
                </Form.Label>
              </div>
                // <div className="select-label">
                //     <Form.Label>{label}</Form.Label>
                // </div> 
                : null
            }
            <Select
                value={isEmpty(value) ?
                    [] :
                    options.filter(item => value.includes(item.value))}
                //  value = {value}
                isMulti
                components={{ Placeholder, DropdownIndicator }}
                placeholder={placeholder}
                name="Forecast Display"
                options={[allOption, ...options]}
                className='multi-select-container'
                classNamePrefix="multi-select"
                isDisabled={disabled}
                hideSelectedOptions={false}
                closeMenuOnSelect={true}
                tabSelectsValue={false}
                controlShouldRenderValue={false}
                onChange={selected => {
                    if (isNil(selected)) {
                        return onChange([]);
                    }
                    if (selected.length > 0 &&
                        selected[selected.length - 1].value === allOption.value
                    ) {
                        return onChange(options);
                    }
                    return onChange(selected);
                }}
                {...rest}
            />
            <div className='options' >
           
            {value.length ? value.map((item, index) => <div key={index} className="selected-item">
                <div onClick={() => onRemoveItem(item)} className='remove-icon'>
                    <div className="select-close-icon">x</div>
                </div>
                <div>{`${index+1}. ${item}`}</div>
                </div>) : ''}
            </div>
        </div>
    );

ReactMultiSelectComponent.propTypes = {
    options: PropTypes.array,
    id: PropTypes.string,
    allOption: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
    }),
    disabled: PropTypes.bool
};

ReactMultiSelectComponent.defaultProps = {
    options: [],
    id: '',
    allOption: {
        label: "Select all",
        value: "*"
    },
    disabled: false,
};

export default ReactMultiSelectComponent;
