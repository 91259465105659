// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.backup-restore .margin-setup {
   margin-top: 2%;
}

.backup-restore h6 {
    color:black
}
.backup-restore label {
    font-size: 16px;
}
.backup-restore .backup-now {
    margin-top: 3%;
}

.backup-restore select {
    font-family: 'FontAwesome', 'Second Font name'
}



`, "",{"version":3,"sources":["webpack://./src/containers/SettingPage/SettingInnerPages/BackupRestore/BackupRestore.css"],"names":[],"mappings":"AAAA;GACG,cAAc;AACjB;;AAEA;IACI;AACJ;AACA;IACI,eAAe;AACnB;AACA;IACI,cAAc;AAClB;;AAEA;IACI;AACJ","sourcesContent":[".backup-restore .margin-setup {\n   margin-top: 2%;\n}\n\n.backup-restore h6 {\n    color:black\n}\n.backup-restore label {\n    font-size: 16px;\n}\n.backup-restore .backup-now {\n    margin-top: 3%;\n}\n\n.backup-restore select {\n    font-family: 'FontAwesome', 'Second Font name'\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
