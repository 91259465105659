import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import get from 'lodash/get';
import InsightsResults from './components/InsightsResults/InsightsResults';
import './insights.css';
import {  useSelector } from 'react-redux';
import FilterDetails from './components/FilterDetails/FilterDetails';
import './insights-home.css';
import Heading3 from '../components/Typography/Heading3/Heading3';

const InsightsHome = ({
    config,
}) => {
    const data = useSelector(state => state.agentInsights.agentInsightsData);
    return (
        <Container fluid className="insights-home">
            <React.Fragment>
                <div>
                    <FilterDetails config={config} />
                    <Row>
                        <Col xs={12} className='d-flex align-items-center'>
                            <div className='ml-2'>
                                <Image src="images/checklist-img.png" alt='alt' fluid />
                            </div>
                            <Heading3
                                text='Based on triage response, found below results!'
                                className='insights-result-heading' />
                        </Col>
                    </Row>
                </div>
                <InsightsResults config={config} results={get(data, 'responsedata', [])} />
            </React.Fragment>
        </Container>
    )
};

export default InsightsHome;