// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.insights-result-heading {
    font-family: 'Roboto-Light';
    font-size: 14px;
    line-height: 21px;
    color: #000000;
}`, "",{"version":3,"sources":["webpack://./src/containers/SettingPage/SettingInnerPages/Insights/insights-home.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB","sourcesContent":[".insights-result-heading {\n    font-family: 'Roboto-Light';\n    font-size: 14px;\n    line-height: 21px;\n    color: #000000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
