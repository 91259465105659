import Keycloak from "keycloak-js";

export var keycloak = null;

export const initializeKC = (orgname, kcUrl) => {
  keycloak = Keycloak({
    "realm":orgname,
    "url": kcUrl,
    "clientId": "api", 
  });
}

