/*

* Copyright © 2020, HCL Technologies Ltd. ALL RIGHTS RESERVED.

* <p>

* This software is the confidential information of HCL Technologies Ltd., and is licensed

* as restricted rights software. The use,reproduction, or disclosure of this software

* is subject to restrictions set forth in your license agreement with HCL.

*/

 

/**

* Setup web crawler.

*

* @author Abhash Vats

*/
import React,{Component} from "react";
import { connect } from 'react-redux';
import withErrorHandler from "../../../../hoc/withErrorHandler/withErrorHandler";
import axios from "../../../../instance";
import { Container, Col, Row } from 'react-bootstrap';
import './SetupCrawler.css';
import CheckIcon from '@material-ui/icons/Check';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import NavbarCustom from '../../../../components/UI/Navbar/Navbar';
import SpinnerPage from '../../../../components/UI/Loader/Loader';
import Heading3 from '../components/Typography/Heading3/Heading3';
import * as actions from '../../../../store/actions/index';
import Drawer from '@material-ui/core/Drawer';
import { withStyles } from '@material-ui/core';
import permissions from "../../../../permissions_Json";
import { encryptAndStore } from "../../../../utility/utility";

const styles =({
    style:{
             overflowX:"hidden"
    },
});

let  R_W_accessKnowlwdgeMining = false;

class SetupCrawler extends Component {
    
    state = {
        selectedProject:null,
        projectList:[],
        circleColorFlag:false,
        formRow:[{url:"",level:"1",excludeKeyWord:"",includeKeyWord:"",urlFlag:"Yes"},],
        drawerFormRow:[],
        drawerFormSelectedRow:[],
        projectname:"Project List",
        searchT:"",
        popperShow:false,
        label:[1,2,3,4,5,6,7,8,9,10],
        selectedLabel:null,
        documentFlag:["Yes","No"],
        selectedCommanFlag:"Yes",
        selsectedDocumentFlag:null,
        viewCrawledUrls:false,
        showDrawer:false,
        minedUrl:[],
        downloadCrawlData:[],
        dataForDownload:[],
        urlLoading:false,
        viewDownloadCrawlData:false,
        defaultCheck:true,
        urlValidationMsg:[],
        //check:false,
        //checked: ['/app', '/app/Http', '/app/Http/Controllers', '/app/Http/Controllers/WelcomeController.js', '/app/Http/routes.js', '/app/Providers', '/app/Providers/EventServiceProvider.js', '/config', '/config/app.js', '/config/database.js', '/public', '/public/assets/', '/public/assets/style.css', '/public/index.html', '/.env', '/.gitignore', '/README.sm'],
        expanded: [],
    }

    setSearchTerm = (item) => {
        this.setState({searchT:item})
    }

    popperShowFn=()=>{
        this.setState({
          popperShow:true,
        })
    }

    projectclick =(event,projectname)=>{
        this.setState({searchT:"",popperShow:false})
        let savedProjectName=localStorage.getItem('projectname');
        if(savedProjectName!==projectname){
        // localStorage.setItem('projectname', projectname );
        encryptAndStore('projectname', projectname );
        this.setState({projectname:projectname});
        }
        this.props.onchangeProjectAction(projectname);        
          //window.updateHCLChatBotURL(projectname);
    }

    selectProjectVal = (event) => {
        this.setState({
            selectedProject: event.target.value
        })
    }

    componentDidUpdate(prevprops){
        // this.state.urlLoading = this.props.loading
        if(this.props.minedUrl && this.props.minedUrl !== prevprops.minedUrl){
            this.setState({
                minedUrl:this.props.minedUrl,
                dataForDownload:this.props.minedUrl,
                circleColorFlag:true,
                viewCrawledUrls:true,
                urlLoading:false
            })
        }
        if(this.props.downloadCrawlData && this.props.downloadCrawlData !== prevprops.downloadCrawlData){
            this.setState({
                downloadCrawlData:this.props.downloadCrawlData,
                viewDownloadCrawlData:true,
                urlLoading:false
            })
        }
        if(this.props.loading !== prevprops.loading){
            this.setState({
                urlLoading:this.props.loading
            })
        }
    }

    clickMe(){
        this.setState({
            circleColorFlag:true,
            viewCrawledUrls:true
        })
    }

    clickMe1(){
        this.setState({
            circleColorFlag:false,
            viewCrawledUrls:false
        })
    }

    selectLabelVal = (event,index) => {
        let row = [...this.state.formRow]
        row[index].level=event.target.value
        this.setState({formRow:row})
    }
    
    drawerCheckBox = (event,index) => {
        let row = [...this.state.drawerFormRow]
        row[index].check=event.target.checked
        this.setState({drawerFormRow:row})
        console.log(event.target.checked)
    }

    drawrSelectLabelVal = (event,index) => {
        let row = [...this.state.drawerFormRow]
        row[index].level=event.target.value
        this.setState({drawerFormRow:row})
    }

    selectDocumentFlag = (event) => {
        this.setState({selectedCommanFlag:event.target.value})
    }

    drawerSelectDocumentFlag = (event,index) => {
        let row = [...this.state.drawerFormRow]
        row[index].urlFlag=event.target.value
        this.setState({drawerFormRow:row})
    }

    addRow = () => {
        let row = this.state.formRow
        row.push({url:"",level:"1",excludeKeyWord:"",includeKeyWord:"",urlFlag:"Yes"})
        this.setState({formRow:row})
    }

    startCrawling = (formRow) => {
        var payload = []
        var msg = []
        var f = 1
        for(var i=0;i<formRow.length;i++){
            if(formRow[i].url==''){
                //this.state.urlValidationMsg[i] = true
                msg[i] = true
                f=0
            }
            else{
                    // this.state.urlValidationMsg[i] = false
                    msg[i] = false
                    var includeKeyWords = formRow[i].includeKeyWord.split(/[, ]+/).filter(element => element)
                    includeKeyWords = includeKeyWords.map(el => '/' + el)
                    var excludeKeyWords = formRow[i].excludeKeyWord.split(/[, ]+/).filter(element => element)
                    var data = {
                        "url": formRow[i].url,
                        "level": formRow[i].level,
                        "onlyInclude" : includeKeyWords,
                        "excludePattern" : excludeKeyWords,
                        "projectName": localStorage.getItem("projectname")
                    }
                    payload.push(data)
            }
            this.setState({urlValidationMsg:msg})
        }
        if(payload.length>0 && f==1){
            let url = this.props.config.BOTBUILDER_URL
            this.props.onUrlMining(url,payload)
            this.setState({urlLoading:false})
        }
    }
    
    drawerStartCrawling = () =>{
            var rows = []
            for(var i=0;i<this.state.drawerFormRow.length;i++){
                if(this.state.drawerFormRow[i].check == true){
                    rows.push(this.state.formRow[i])
                }
            }
            this.startCrawling(rows)
            this.setState({showDrawer:false,drawerFormRow:[],drawerFormSelectedRow:[]})
            //this.setState({drawerFormSelectedRow:rows})
    }

    defaultCheck = (event) => {
        this.setState({defaultCheck:event.target.checked})
        var checkboxes = document.getElementsByName('rowCheckbox');
        for (var checkbox of checkboxes) {
            checkbox.checked = event.target.checked;
        }
        if(event.target.checked == true){
            this.setState({dataForDownload:this.props.minedUrl})
        }
        else{
            this.setState({dataForDownload:[]})
        }
    }

    dataForDownload = (event,row) => {
        var checkbox = document.getElementById("allCheckbox");
        checkbox.indeterminate = true;
        this.setState({defaultCheck:''})
        // var checkboxes = document.getElementsByName('rowCheckbox');
        // for (var checkbox of checkboxes) {
        //     checkbox.removeAttribute('checked')
        // }
        let data=[...this.state.dataForDownload]
        var i = data.findIndex(item => item == row);
        if(event.target.checked == true){
            data.push(row);
        }
        else{
            if (data.includes(row)) {
               data.splice(i, 1)
            }
        }
        this.setState({dataForDownload:data})
    }

    downloadData = () => {
        let url = this.props.config.BOTBUILDER_URL;
        let urls = []
            this.state.dataForDownload.forEach((item)=>{
               return urls.push(item.url)
            })
            var data = {
                        "urls" : urls,
                        "crawldocs": this.state.selectedCommanFlag,
                        "projectName":localStorage.getItem("projectname")
                    }
            this.props.onDownloadCrawledData(url,data)
        this.setState({urlLoading:false})
        this.setState({dataForDownload:[]})
    }

    removeRow = (index) => {
        if(R_W_accessKnowlwdgeMining){
        let row = [...this.state.formRow]
        let msg = [...this.state.urlValidationMsg]
        row.splice(index,1)
        msg.splice(index,1)
        this.setState({formRow:row, urlValidationMsg:msg})
        }
    }

    urlInput = (event,index) =>{
        let row = [...this.state.formRow]
        row[index].url=event.target.value
        this.setState({formRow:row})
    }

    excludeKeyWordInput = (event,index) =>{
        let row = [...this.state.formRow]
        row[index].excludeKeyWord=event.target.value
        this.setState({formRow:row})
    }

    drawerExcludeKeyWordInput = (event,index) =>{
        let row = [...this.state.drawerFormRow]
        row[index].excludeKeyWord=event.target.value
        this.setState({drawerFormRow:row})
    }

    includeKeyWordInput = (event,index) =>{
        let row = [...this.state.formRow]
        row[index].includeKeyWord=event.target.value
        this.setState({formRow:row})
    }

    drawerIncludeKeyWordInput = (event,index) =>{
        let row = [...this.state.drawerFormRow]
        row[index].includeKeyWord=event.target.value
        this.setState({drawerFormRow:row})
    }

    showDrawer = () =>{
        var rows = [...this.state.formRow]
        for(var i=0;i<rows.length;i++){
            rows[i].check = true
        }
        this.setState({drawerFormRow:rows})
        this.setState({showDrawer:true})
    }

    handleClose=(str)=>{
        if(str == 'closeDrawer')
            this.setState({showDrawer:false,drawerFormRow:[],drawerFormSelectedRow:[]})
    }
    selectedNode = () =>{
        this.props.selectedNode("Downloaded Data")
    }
    render(){
        const { classes } = this.props;

        let  accessStatus = this.props.permissions.find(data => {
          if (data.permission_code.includes(permissions.permissionCode.COGNITIVE_SEARCH_ADMIN_RW) && data.sku_code.includes(permissions.skuCode.CS))
            return true
        })
        R_W_accessKnowlwdgeMining = accessStatus ? true : false;

        return(
           <>{ this.state.viewDownloadCrawlData && this.selectedNode()}
            <Container fluid className="CrawlerTabStyle">
                <Row sm="12" md="12" lg="12" xl="12" xxl="12"  className="headerRow">
                    <Col sm='6'  md="6" lg="6" xl="6" xxl="6">
                    <div style={{position:"relative",float:"left"}}>
                        <Heading3 text='Knowledge Mining / Setup Crawler' className="pageTitleText"/>
                    </div>
                    </Col>
                    <Col sm='6' md="6" lg="6" xl="6" xxl="4">
                        <div style={{position:"relative",float:"right",marginRight:"40px"}}>
                            <NavbarCustom 
                                className="crawlingProjectName"
                                dataList={this.props.projectlist}
                                addImportProjectIcon={false}
                                setSearchTerm={(item)=>this.setSearchTerm(item)}
                                popperShow={this.state.popperShow}
                                popperShowFn={()=>this.popperShowFn()}
                                searchTerm={this.state.searchT}
                                projectclick={(event,item)=>this.projectclick(event,item)}
                                projectname={this.state.projectname} 
                                rightPopUp={false} >
                            </NavbarCustom>
                        </div>
                    </Col>
                </Row>
                <Row sm="12" md="12" lg="12" xl="12" xxl="12">
                    <div className="dumble">
                        <div className="outerCircle" style={{
                                        border:this.state.circleColorFlag==false?"solid":"none",
                                        borderWidth:this.state.circleColorFlag==true?"none":"1px",
                                        borderColor:this.state.circleColorFlag==true?"none":"#2A93DF",
                                        backgroundColor:this.state.circleColorFlag==true?"inherit":"white"}}>

                            <div className="innerCircle" onClick={()=>this.clickMe1()} style={{left:this.state.circleColorFlag==true?"11px":"4px"}}>
                                        <span className="CheckIcon" style={{display:this.state.circleColorFlag==false?"none":''}}>
                                            <CheckIcon ></CheckIcon>
                                        </span>
                            </div>
                            <span className="circleText" style={{left:"-20px"}}>Configure URLs
                            </span>
                        </div>
                        
                        <div className="line"></div>
                        <div className="outerCircle" style={{
                                        border:this.state.circleColorFlag==true?"solid":"none",
                                        borderWidth:this.state.circleColorFlag==false?"none":"1px",
                                        borderColor:this.state.circleColorFlag==false?"none":"#2A93DF",
                                        backgroundColor:this.state.circleColorFlag==false?"inherit":"white"}}>

                            <div className="innerCircle" onClick={()=>this.clickMe()} style={{left:this.state.circleColorFlag==true?"4px":"0px",}}>
                            </div>
                            <span className="circleText" style={{left:"70px"}}>View Crawled URLs
                            </span>
                        </div>
                    </div>
                </Row>
                {this.state.viewCrawledUrls == false && <Row sm="12" md="12" lg="12" xl="12" xxl="12">
                    <Col md="12" lg="12" xl="12" xxl="12" className="buttonVerticleAlign" style={{marginRight:"20px"}}>
                        <div style={{float:"right",position:"relative",top:"20px",marginBottom:"40px"}}>
                            <button className="addUrlBtn" disabled = {(R_W_accessKnowlwdgeMining ? false:true) || (this.state.formRow[0].url).length === 0} onClick={()=>this.addRow()}>
                                <span style={{color:"#2A93DF",fontSize:"12px",fontWeight:"bold"}}><AddIcon style={{fontSize:"20px",paddingBottom:"2px"}}></AddIcon>ADD URL</span>
                            </button>
                            <button className="startCrawleBtn" disabled = {(R_W_accessKnowlwdgeMining ? false:true) || (this.state.formRow[0].url).length === 0} onClick={()=>this.startCrawling(this.state.formRow)}>
                                <span style={{color:"white",fontSize:"12px",fontWeight:"bold"}}>START CRAWLING</span>
                            </button>
                        </div>
                    </Col>
                </Row>}
                {this.state.viewCrawledUrls == false && this.state.formRow && this.state.formRow.map((row,index)=>{
                    return(
                        <>
                            <Row sm="12" md="12" lg="12" xl="12" xxl="12" className="crawlerRow" key={index}>
                                <Col sm='3' md="3" lg="3" xl="3" xxl="3" className="crawlerRowCol">
                                    <div>
                                        <label className="crawlerColLabel">Base URL*</label>
                                        <input type="text" value={row.url} placeholder="Please put a base URL for Knowledge Mining" onChange={(event) => this.urlInput(event,index)} className="crawlerCol"></input>
                                        {this.state.urlValidationMsg[index] == true && <span style={{color:"red"}}>Base URL is mandatory</span>}
                                    </div>
                                </Col>
                                <Col sm='1' md="1" lg="1" xl="1" xxl="1" className="crawlerRowCol">
                                    <div>
                                        <label className="crawlerColLabel">Levels</label>
                                        <select value={row.level} onChange={(e)=>this.selectLabelVal(e,index)} className="crawlerCol">
                                        {this.state.label && this.state.label.map(i =>(
                                            <option value={i}>{i}</option>
                                        ))
                                        }
                                        </select>
                                    </div>
                                </Col>
                                <Col sm='3' md="3" lg="3" xl="3" xxl="3" className="crawlerRowCol">
                                    <div>
                                        <label className="crawlerColLabel">Exclude URLs Containing Keywords</label>
                                        <input type="text" value={row.excludeKeyWord} placeholder="Please put comma between keywords" onChange={(event) => this.excludeKeyWordInput(event,index)} className="crawlerCol"></input>
                                    </div>
                                </Col>
                                <Col sm='3' md="3" lg="3" xl="3" xxl="3" className="crawlerRowCol">
                                    <div>
                                        <label className="crawlerColLabel">Include URLs Containing Keywords</label>
                                        <input type="text" value={row.includeKeyWord} placeholder="Please put comma between keywords" onChange={(event) => this.includeKeyWordInput(event,index)} className="crawlerCol"></input>
                                    </div>
                                </Col>
                                <Col sm='2' md="2" lg="2" xl="2" xxl="2" className="crawlerRowCol">
                                    <div>
                                        <label className="crawlerColLabel">Crawl documents</label>
                                        <select  value={this.state.selectedCommanFlag} onChange={(e)=>this.selectDocumentFlag(e)}
                                        style={{width:"70px",height:"35px",borderRadius:"5px",border:"1px solid black",fontSize:"13px"}}>
                                        {this.state.documentFlag && this.state.documentFlag.map(i =>(
                                            <option value={i}>{i}</option>
                                        ))
                                        }
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                            {(this.state.formRow).length == 1 ? null : <span className="plushIcon" onClick={() => this.removeRow(index)}><img src='./Icons/removeCircleBlack.svg' style={{cursor:"pointer"}}></img></span> }
                        </>
                        )
                        })}

                        <div className="crawledUrl">
                            {this.state.viewCrawledUrls == true && <Row sm="12" md="12" lg="12" xl="12" xxl="12" style={{position:"relative",top:"50px"}}>
                                <Col  sm="12" md="12" lg="12" xl="12" xxl="12" className="buttonVerticleAlign">
                                    <div style={{float:"left",marginBottom:"10px",left:"30px",position:"relative"}}>
                                        <h5 style={{fontFamily:'Roboto-Black',fontSize:"12px"}}> {this.state.minedUrl.length > 0 ?this.state.minedUrl.length:'No'} URLs Found</h5>
                                    </div>
                                    {this.state.minedUrl.length>0 &&
                                    <div className="exportDownloadBtn">
                                        {/* <button className="exportBtn" onClick={(e)=>this.addRow(e)}>
                                            <span style={{color:"#2A93DF",fontSize:"12px",fontWeight:"bold"}}>EXPORT CSV</span></button> */}
                                        <button className="downloadBtn" onClick={()=>this.downloadData()}>
                                        <span style={{color:"white",fontSize:"12px",fontWeight:"bold"}}>DOWNLOAD DATA</span></button>
                                    </div>}
                                </Col>
                            </Row>}
                            {this.state.viewCrawledUrls == true && this.state.minedUrl.length>0 && <Row sm="12" className="urlLevel">
                                <Col sm='10' md="10" lg="10" xl="10" xxl="10">
                                <input type="checkbox" defaultChecked="true" id="allCheckbox" style={{marginRight:"10px"}} onChange={(event)=>this.defaultCheck(event)}></input>
                                        <span  className="tableHeadrText"> URL</span>
                                </Col>
                                <Col sm='2' md="2" lg="2" xl="2" xxl="2" style={{padding:"0px",marginBottom:"5px"}}>
                                        <div>
                                        <span className="tableHeadrText">LEVEL</span>
                                        <span style={{float:"right"}}>
                                        <img onClick={()=>this.showDrawer()} src='./images/Filter-icon.png' style={{cursor:"pointer"}}></img>
                                        </span>
                                        </div>
                                        
                                </Col>
                            </Row>}

                            <div style={{height:"420px",overflowY: this.state.viewCrawledUrls == true && this.state.minedUrl.length>0?"scroll":"hidden",marginTop:"50px",marginLeft:"40px"}}>
                                {this.state.urlLoading == true ? <SpinnerPage/> :null}
                                {this.state.viewCrawledUrls == true && this.state.minedUrl.length>0 && this.state.minedUrl.map((item)=>{
                                    return <>
                                            <Row sm='12' md="12" lg="12" xl="12" xxl="12" className="row align-items-center urlLevelData">
                                                <Col sm='10' md="10" lg="10" xl="10" xxl="10">
                                                        <input type="checkbox" name = "rowCheckbox" defaultChecked={this.state.defaultCheck} style={{marginRight:"10px"}} onChange={(event)=>this.dataForDownload(event,item)}></input>
                                                        <a href ={item.url} target="_blank" className="downloadLink">
                                                        {item.url}
                                                        </a>
                                                </Col>
                                                <Col sm='2' md="2" lg="2" xl="2" xxl="2">
                                                        <div style={{fontFamily:"sans-serif", fontSize:"11px",fontWeight:"bold"}}>
                                                        {item.level}
                                                        </div>
                                                </Col>
                                                
                                            </Row>
                                    </>
                                    })
                                }
                            </div>
                            
                            {['right'].map((anchor) => (
                                <React.Fragment key={anchor}>
                                    <Drawer anchor={anchor} open={this.state.showDrawer} onClose={() => this.handleClose('closeDrawer')} docked={true} containerClassName="drawer-side-drawer"
                                          classes={{ paper: classes.style }}
                                         >
                                        <Row sm="12" md="12" lg="12" xl="12" xxl="12" className="drawerHeader">
                                            <div style={{display:"flex"}}>
                                                <a style={{paddingTop:"5px"}} onClick={() => this.handleClose('closeDrawer')}><CloseIcon style={{fontSize:"30px"}}/></a>
                                                <Heading3 text='Refine Crawled Urls' className="pageTitleText"/>
                                            </div>
                                        </Row>
                                        {
                                            this.state.drawerFormRow.map((row,index)=>{
                                                return(
                                                    row.url.length>0 && <div style={{marginLeft:"60px"}}>
                                                        <Row sm="12"  md="12" lg="12" xl="12" xxl="12">
                                                            <div>
                                                            <input type="checkbox" defaultChecked value={row.check} onChange={(event)=>this.drawerCheckBox(event,index)}
                                                                style={{marginRight:"10px",width:"18px",height:"18px"}}></input>
                                                            <span style={{fontFamily:"sans-serif", fontSize:"12px",fontWeight:"bold"}}>
                                                                {row.url}
                                                            </span>
                                                            <br/>
                                                            {this.state.drawerFormRow[index].check == true ? null : <span style={{color:"red",fontSize:"12px" }}> No URL has been selected for making any changes</span>}  
                                                            </div>
                                                        </Row>
                                                        <Row sm="12" md="12" lg="12" xl="12" xxl="12" className="crawlerRow">
                                                            <Col sm='1' md="1" lg="1" xl="1" xxl="1" className="crawlerRowCol" style={{marginRight:"10px"}}>
                                                                <div>
                                                                    <label className="crawlerColLabel">Levels</label>
                                                                    <select value={row.level} onChange={(e)=>this.drawrSelectLabelVal(e,index)} className="crawlerCol">
                                                                    {this.state.label && this.state.label.map(i =>(
                                                                        <option value={i}>{i}</option>
                                                                    ))
                                                                    }
                                                                    </select>
                                                                </div>
                                                            </Col>
                                                            <Col sm='4' md="4" lg="4" xl="4" xxl="4" className="crawlerRowCol" style={{marginRight:"10px"}}>
                                                                <div>
                                                                    <label className="crawlerColLabel">Exclude URLs Containing Keywords</label>
                                                                    <input type="text" value={row.excludeKeyWord} onChange={(event) => this.drawerExcludeKeyWordInput(event,index)} className="crawlerCol"></input>
                                                                </div>
                                                            </Col>
                                                            <Col sm='4' md="4" lg="4" xl="4" xxl="4" className="crawlerRowCol" style={{marginRight:"10px"}}>
                                                                <div>
                                                                    <label className="crawlerColLabel">Include URLs Containing Keywords</label>
                                                                    <input type="text" value={row.includeKeyWord} onChange={(event) => this.drawerIncludeKeyWordInput(event,index)} className="crawlerCol"></input>
                                                                </div>
                                                            </Col>
                                                            <Col sm='1' md="1" lg="1" xl="1" xxl="1" className="crawlerRowCol">
                                                                <div>
                                                                    <label className="crawlerColLabel">Crawl documents</label>
                                                                    <select  value={row.urlFlag} onChange={(event)=>this.drawerSelectDocumentFlag(event,index)}
                                                                    style={{width:"60px",height:"35px",borderRadius:"5px",border:"1px solid black"}}>
                                                                    {this.state.documentFlag && this.state.documentFlag.map(i =>(
                                                                        <option value={i}>{i}</option>
                                                                    ))
                                                                    }
                                                                    </select>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                )
                                            })
                                        }
                                        <Row sm="12" md="12" lg="12" xl="12" xxl="12" style={{marginLeft:"50px",marginBottom:"10px"}}>
                                            <button onClick={()=>this.drawerStartCrawling()} style={{backgroundColor: "#2A93DF",border:"none",width: "130px",borderRadius: "5px",height: "35px"}}>
                                            <span style={{color:"white",fontSize:"12px",fontWeight:"bold"}}>APPLY</span></button>
                                        </Row>
                                    </Drawer>
                                </React.Fragment>
                            ))}
                        </div>
                        
            </Container>
        
        </>)
    }
}

const mapStateToProps = state => {
    return {
        IsProjectChange:state.projectview.IsProjectChange,
        projectlist: state.user.projects,
        minedUrl:state.cognitive.minedUrl,
        loading:state.cognitive.loading,
        downloadCrawlData: state.cognitive.downloadCrawlData,
        permissions:state.user.permissionList,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onchangeProjectAction: (projectname) => dispatch(actions.changeProjectAction(projectname)),
        onUrlMining:(url,data) => dispatch(actions.urlMining(url,data)),
        onDownloadCrawledData: (url,data) => dispatch(actions.downloadCrawledData(url,data)),
    }
  
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withErrorHandler(withStyles(styles)(SetupCrawler), axios));