import React, { useState, useEffect } from 'react';
import { Modal, Table } from 'react-bootstrap';
import get from 'lodash/get';
import { useDispatch } from 'react-redux';
import { SetErrorMessage } from '../../../../store/actions';
import { botPostCalltoServer } from '../../../../store/utility';
import VisibilityIcon from '@material-ui/icons/Visibility';

function ViewCollectionModal({ config, selectedMenu, selectedSubMenu, dataMappingIndex }) {
    const [show, setShow] = useState(false);
    const [data, setData] = useState(false);
    const dispatch = useDispatch();

    const handleClose = () => setShow(false);
    useEffect(() => {
        const configObj = {
            'dataSourceType': selectedMenu,
            'dataSourceName': selectedSubMenu,
            'sourceIndex': dataMappingIndex,
            'connector': {}
        }           
        let url = config.CONNECTOR_URL + `/view-fields/${dataMappingIndex}`;
        botPostCalltoServer(url, configObj)
            .then(response => {
                if (get(response, 'data.status.responseType', '') === "success") {
                    setData(JSON.parse(response.data.data));
                }
                else {
                    setData([]);
                }
            }).catch(() => {
                setData([]);
            });
        
     }, [selectedMenu, selectedSubMenu, dataMappingIndex]);

     useEffect(() => {
        const configObj = {
            'dataSourceType': selectedMenu,
            'dataSourceName': selectedSubMenu,
            'sourceIndex': dataMappingIndex,
            'connector': {}
        }           
        let url = config.CONNECTOR_URL + `/view-fields/${dataMappingIndex}`;
        botPostCalltoServer(url, configObj)
            .then(response => {
                if (get(response, 'data.status.responseType', '') === "success") {
                    setData(JSON.parse(response.data.data));
                }
                else {
                    setData([]);
                }
            }).catch(() => {
                setData([]);
            });
        
     }, []);

    const viewModal = () => {
        setShow(true);
          

            const configObj = {
                'dataSourceType': selectedMenu,
                'dataSourceName': selectedSubMenu,
                'sourceIndex': dataMappingIndex,
                'connector': {}
            }           
            let url = config.CONNECTOR_URL + `/view-fields/${dataMappingIndex}`;
            botPostCalltoServer(url, configObj)
                .then(response => {
                    if (get(response, 'data.status.responseType', '') === "success") {
                        //dispatch(SetSuccessMessage('Viewed Collection Successfully'));
                        setData(JSON.parse(response.data.data));
                    }
                    else {
                        dispatch(SetErrorMessage(get(response, 'data.errors.0.source', '')));
                        setData([]);
                    }
                }).catch(err => {
                    dispatch(SetErrorMessage(get(err, 'response', '')));
                    setData([]);
                });
    };

    return (
        <React.Fragment>
            <a className='collection-icon add-icon' title='View Fields' onClick={data && data.length?viewModal:''} style={{color:data && data.length?'#007bff':'gray', cursor:data && data.length?'pointer':'not-allowed'}}><VisibilityIcon/></a>
            {/* <img className='collection-icon add-icon' title='View Fields' id='img' onClick={viewModal} src="./Icons/ViewBlue.svg" /> */}
            {show ? 
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>View Collection</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table striped bordered hover>
                        <tbody>
                            {data.length ? data.map(item => { return (
                                <tr><td>{item}</td></tr>
                            )}) : <tr><td>No Data Available !</td></tr>}
                        </tbody>
                    </Table>
                </Modal.Body>
            </Modal>: null}
        </React.Fragment>
    );
}

export default ViewCollectionModal;