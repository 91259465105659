// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-filters-page {
    width: 36.88rem;
    max-height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
}

.custom-filters-page .product-attribute-tags {
    font-family: 'Roboto-Black';
    font-size: 12px;
    line-height: 14px;
    color: #1B1B1B;
    text-transform: uppercase;
    border-bottom: solid 1px #d3d3d361;
}

.custom-filters-page .filter-header {
    font-family: "Roboto-Black";
    font-size: 20px;
    line-height: 25px;
    color: #1B1B1B;
    width: 26rem;
}

.custom-filters-page  .top-border {
    border-top: solid 1px lightgray;
}

.custom-filters-page .clear-filters {
   color: rgb(118 113 113 / 53%);
   font-size: 12px;
   text-transform: capitalize;
   margin-right: 1.5rem;
   text-decoration: underline;
}

.custom-filters-page .product-data h3 {
    font-family: "Roboto-Black";
    font-size: 20px;
    line-height: 25px;
    color: #1B1B1B;
    padding-left: 10px;
}
.filters-data-null{
    display: flex;
    align-items: center;
    font-family: 'Roboto-Black';
    font-size: 12px;
    line-height: 14px;
    color: #1B1B1B;
    /* text-transform: uppercase; */
    padding-left: 3.1rem;
}
`, "",{"version":3,"sources":["webpack://./src/containers/SettingPage/SettingInnerPages/Insight/AdminConfiguration/Filters/Filters.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,2BAA2B;IAC3B,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,yBAAyB;IACzB,kCAAkC;AACtC;;AAEA;IACI,2BAA2B;IAC3B,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,YAAY;AAChB;;AAEA;IACI,+BAA+B;AACnC;;AAEA;GACG,6BAA6B;GAC7B,eAAe;GACf,0BAA0B;GAC1B,oBAAoB;GACpB,0BAA0B;AAC7B;;AAEA;IACI,2BAA2B;IAC3B,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,kBAAkB;AACtB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,2BAA2B;IAC3B,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,+BAA+B;IAC/B,oBAAoB;AACxB","sourcesContent":[".custom-filters-page {\n    width: 36.88rem;\n    max-height: 100vh;\n    overflow-x: hidden;\n    overflow-y: scroll;\n}\n\n.custom-filters-page .product-attribute-tags {\n    font-family: 'Roboto-Black';\n    font-size: 12px;\n    line-height: 14px;\n    color: #1B1B1B;\n    text-transform: uppercase;\n    border-bottom: solid 1px #d3d3d361;\n}\n\n.custom-filters-page .filter-header {\n    font-family: \"Roboto-Black\";\n    font-size: 20px;\n    line-height: 25px;\n    color: #1B1B1B;\n    width: 26rem;\n}\n\n.custom-filters-page  .top-border {\n    border-top: solid 1px lightgray;\n}\n\n.custom-filters-page .clear-filters {\n   color: rgb(118 113 113 / 53%);\n   font-size: 12px;\n   text-transform: capitalize;\n   margin-right: 1.5rem;\n   text-decoration: underline;\n}\n\n.custom-filters-page .product-data h3 {\n    font-family: \"Roboto-Black\";\n    font-size: 20px;\n    line-height: 25px;\n    color: #1B1B1B;\n    padding-left: 10px;\n}\n.filters-data-null{\n    display: flex;\n    align-items: center;\n    font-family: 'Roboto-Black';\n    font-size: 12px;\n    line-height: 14px;\n    color: #1B1B1B;\n    /* text-transform: uppercase; */\n    padding-left: 3.1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
