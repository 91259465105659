import * as React from "react";
import Form from "react-bootstrap/Form";
import "./SelectComponent.css";

const SelectComponent = (props) => {
  return (
    <>
      <div className="common-input">
        {props.label && <Form.Label className={props.className=='mr-5 cognitiveSettings' ? "mb-0 text-box-title adding-other-input cognitiveSetting" :"mb-0 text-box-title adding-other-input"}>
          {props.label}
        </Form.Label>}
      </div>
      <Form.Control
        as="select"
        className={`selectClass ${props.className}`}
        name={props.name}
        onChange={props.changed}
        required={props.required}
        value={props.value}
        disabled={props.disabled}
      >
        {props.options && props.options.length > 0
          ? props.options.map((option) => (
              <option
                key={option.value}
                value={option.value}
                disabled={option.disabled}
              >
                {option.displayValue}
              </option>
            ))
          : null}
      </Form.Control>
    </>
  );
};

export default SelectComponent;
