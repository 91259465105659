import { similarIssuesChangeHandlerAction } from './insightsActions';

const extensionFieldsRemoveItemHandler = (payload) => (dispatch, getState) => {
    const state = getState();
    const extensionFields = state.insights.similartickets.extensionFields;
    dispatch(similarIssuesChangeHandlerAction({
        'extensionFields': extensionFields.filter(item => item !== payload),
    }))
};

export default extensionFieldsRemoveItemHandler;