import * as React from 'react';
import Button from '@material-ui/core/Button';
import './ButtonComponent.css';

const ButtonComponent = (props) => {
   return(
    
    <Button variant={props.variant} color="primary" disabled={props.disabled} size={props.size}
    onClick={props.clicked} className={props.className}>
      {props.label}
  </Button>
  
   )
}

export default ButtonComponent;
