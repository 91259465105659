const getSymptomsDataReducer = (state, payload) => {
    return {
        ...state,
        symptomsTabData: {
            ...state.symptomsTabData,
            symptomData: payload
        }
    }
}

export default getSymptomsDataReducer;