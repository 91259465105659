import React, { useEffect, useState } from 'react';
import { Row, Col, Form } from "react-bootstrap";
import './Drawer.css';
import ButtonCustom from "../../../../components/UIComponents/Button/ButtonComponent";
import InputWithLabelComponent from "../../../SettingPage/SettingInnerPages/components/InputWithLabelComponent/InputWithLabelComponent";
import SelectCustom from "../../../../components/UIComponents/SelectDropdown/SelectComponent";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

const ShowDataDetails = ({data, handleExternalSubmit, variable, invalidVarMsg}) => {

    const LightTooltip = withStyles((theme) => ({
        tooltip: {
          backgroundColor: theme.palette.common.white,
          color: "rgba(0, 0, 0, 0.87)",
          boxShadow: theme.shadows[1],
          fontSize: 12,
          fontFamily: "Roboto-Regular",
        },
        arrow: {
          color: theme.palette.common.white,
          "&:before": {
            border: "1px solid #E6E8ED",
            boxShadow: theme.shadows[1],
          },
        },
      }))(Tooltip);  

    const [radioBtn, setRadioBtn] = useState();
    const typeList = [{value:'table',displayValue:'Table'},{value:'list',displayValue:'List'}];
    const [viewType, setViewType] = useState(data?.viewType?data.viewType:'table');
    const [referenceVariable, setReferenceVariable] = useState(data?.referenceVariable);
    const defaultTableData = {column:'',value:''};
    const [tableData, setTableData] = useState(viewType==='table' && data?.data? data.data:[defaultTableData]);
    const defaultListData = {item:'',childPresent:false, child:''};
    const [listData, setListData] = useState(viewType === 'list' && data?.data? data.data:[defaultListData]);

    useEffect(()=> {
        if(referenceVariable !== undefined && referenceVariable !== null) 
            setRadioBtn('passByVar')
        else 
            setRadioBtn('manual')
    
    },[]);

    const isFormValid = () => {
        if(referenceVariable !== undefined) {
            if(viewType === 'table') {
                return variable && !invalidVarMsg && referenceVariable && tableData[0].column && tableData[0].value;
            } else {
                return variable && !invalidVarMsg && referenceVariable && listData[0].item
            }
        } else {
            if(viewType === 'table') {
                return variable && !invalidVarMsg && tableData[0].column && tableData[0].value;
            } else {
                return variable && !invalidVarMsg && listData[0].item
            }
        }
    }

    const saveData = () => {
        if(viewType === 'table') {
            handleExternalSubmit('showData', {viewType, data:tableData, referenceVariable});
        } else {
            if(referenceVariable === undefined)
                handleExternalSubmit('showData', {viewType, data:listData, referenceVariable});
            else 
                handleExternalSubmit('showData', {viewType, data:[listData[0]], referenceVariable});
        }
    }

    const addNewEntry = () => {
        setTableData((prev)=>[...prev,defaultTableData]);
    }

    const addListEntry = () => {
        setListData((prev)=>[...prev,defaultListData]);
    }

    function reduceCount(index, type) {
        if(type === 'table')
            setTableData(tableData.filter((_, i) => i !== index));
        else 
            setListData(listData.filter((_, i) => i !== index));
    }

    const updateTableDetails = (index, key, value) => {
        let tempArray = [...tableData];
        tempArray[index] = {...tempArray[index], [key]:value};
        setTableData(tempArray);
    }

    const updateListDetails = (index, key, value) => {
        let tempArray = [...listData];
        tempArray[index] = {...tempArray[index], [key]:value};
        if(key === 'childPresent' && !value) {
            tempArray[index] = {...tempArray[index], ['child']:''};
        }
        setListData(tempArray);
        
    }

    const handleOptionChange = (e) => {
        setRadioBtn(e.target.value);
        setReferenceVariable(undefined);
    }

    function renderTableInputs(index, item) { 
        return (
            <Row >
                <Col md={11}>
                    <Row>
                    <Col md={6}>
                    <InputWithLabelComponent
                            type="text"
                            name={item.column}
                            label=""
                            showLabel={false}
                            value={item.column}
                            readOnly={false}
                            onChange={(e) => updateTableDetails(index,'column',e.target.value)}
                            required={false}
                            placeholder='Column Title'
                        />
                    </Col>
                    <Col md={6}>
                    <InputWithLabelComponent
                            type="text"
                            name={item.value}
                            label=""
                            showLabel={false}
                            value={item.value}
                            readOnly={false}
                            onChange={(e) => updateTableDetails(index,'value',e.target.value)}
                            required={false}
                            placeholder='Value'
                        />
                    </Col>
                    </Row>
                </Col>
                <Col md={1} className='countBtn-col minusBtn-align'>
                    {index !== 0 && (
                        <div onClick={()=>reduceCount(index,'table')}>
                        <img className='btn-more-btn' src="./Icons/removeCircleBlack.svg"/>  
                        </div> 
                    )}
                       
                </Col>
            </Row>
        )
        }

    function renderListInputs(index, item) {
        return (
            <div className='backgroundBox'>
                <div className="mt-1">
                <Col md={12}>
                <Row className="FormLabel-row">
                    <Col md={9} className="FormLabel-col addBtn-label">
                    <Form.Label className="mb-1 text-box-title adding-other-input">{'List Item '.concat(index+1) }</Form.Label>
                    </Col>
                    {index>0 && (
                        <Col md={1} className='countBtn-col plusBtn-align'>
                        <div onClick={()=>reduceCount(index,'list')}>
                            <img className='removeBtn-img' src="./Icons/removeCircleBlack.svg"/>
                        </div>    
                        </Col>
                    )}
                    
                </Row>
                </Col>
                </div>
                <div className="mt-1">
                <Col md={11}>
                    <InputWithLabelComponent
                        type="text"
                        name="optionKey"
                        showLabel={false}
                        value={listData[index].item}
                        readOnly={false}
                        onChange={(e) => updateListDetails(index,'item',e.target.value)}
                        required={true}
                        placeHolder='Option key'
                    />
                </Col>
                </div>
                <div className="mt-1">
                <Col md={12} className='col-flex'>
                    <div><Form.Label className="mb-1 text-box-title adding-other-input">Child Nodes</Form.Label></div>
                    <Form className='switch-alignment'>
                        <Form.Check
                            type="switch"
                            id={"childNode"+index}
                            checked={listData[index].childPresent}
                            onChange={e => updateListDetails(index,'childPresent',e.target.checked)}
                        />
                    </Form>
                </Col>
                </div>
                
                <div className="mt-1">
                <Col md={11}>
                    <InputWithLabelComponent
                        type="text"
                        name="childKey"
                        showLabel={false}
                        value={listData[index].child}
                        readOnly={false}
                        onChange={(e) => updateListDetails(index,'child',e.target.value)}
                        required={true}
                        placeHolder='Child Items (Item 1, Item 2)'
                        disabled={!listData[index].childPresent}
                    />
                    <LightTooltip
                        arrow
                        title="Child Items can be listed with comma separator"
                        placement="top"
                      >
                        <img
                          src="./images/info_icon.png"
                          style={{ position: "absolute", top: "30%", left: "100%" }}
                        />
                      </LightTooltip>
                </Col>
                </div>
            </div>
        )
    }

    return (
        <div>
            <div className="mt-1">
              <Col md={12}>
                <div>
                  <input type="radio" id="manual" name="inputMethod" value="manual" 
                    checked={radioBtn === 'manual'} onChange={handleOptionChange}/>
                  <label >Manual</label>
                  <input type="radio" id="passByVar" name="inputMethod" value="passByVar" 
                    checked={radioBtn === 'passByVar'} onChange={handleOptionChange}/>
                  <label >Reference</label><br/>
                </div>
              </Col>
            </div>

            {radioBtn === 'passByVar' && (
                <div className="mt-1">
                <Col md={12}>
                    <InputWithLabelComponent
                        type="text"
                        name="referenceVar"
                        label = "Reference Variable*"
                        value={referenceVariable}
                        readOnly={false}
                        onChange={(e) => setReferenceVariable(e.target.value)}
                        required={true}
                    />
                </Col>
                </div>
            )}
            

            <div className="mt-1">
                <Col md={12}>
                    <SelectCustom
                    name="listType"
                    label="View Type"
                    changed={e => setViewType(e.target.value)}
                    required={true}
                    value={viewType}
                    options={typeList}/>
                </Col>
            </div>

            {viewType === 'table' && (
            <div>
                <div className="mt-1">
                <Col md={12}>
                <Row className="FormLabel-row">
                    <Col md={9} className="FormLabel-col addBtn-label">
                    <Form.Label className="mb-1 text-box-title adding-other-input">Table Details*</Form.Label>
                    </Col>
                    <Col md={1} className='countBtn-col plusBtn-align'>
                    <div onClick={addNewEntry}>
                        <img className='addBtn-img' src="./Icons/AddBlackFilled.svg"/>
                    </div>    
                    </Col>
                </Row>
                </Col>

                <div className="mt-1">
                <Col md={12}>
                    { tableData.map((item,index) => renderTableInputs(index,item)) }
                </Col>
                </div>
                
                </div>
            </div>
            )}

            {viewType === 'list' && (
                <div className="mt-1">
                <Col md={12}>
                <Row className="FormLabel-row">
                    <Col md={9} className="FormLabel-col addBtn-label">
                    <Form.Label className="mb-1 text-box-title adding-other-input">List Items*</Form.Label>
                    </Col>
                    {radioBtn === 'manual' && (
                        <Col md={1} className='countBtn-col plusBtn-align'>
                        <div onClick={addListEntry}>
                            <img className='addBtn-img' src="./Icons/AddBlackFilled.svg"/>
                        </div>    
                        </Col>
                    )}                    
                </Row>
                </Col>
                </div>
            )}

            {viewType === 'list' && radioBtn === 'passByVar' && (
                <div>
                    <div className="mt-1">
                    <Col md={12}>
                        <InputWithLabelComponent
                            type="text"
                            name="optionKey"
                            showLabel={false}
                            value={listData[0].item}
                            readOnly={false}
                            onChange={(e) => updateListDetails(0,'item',e.target.value)}
                            required={true}
                            placeHolder='Option key'
                        />
                    </Col>
                    </div>
                    <div className="mt-1">
                    <Col md={12} className='col-flex'>
                        <div><Form.Label className="mb-1 text-box-title adding-other-input">Child Nodes</Form.Label></div>
                        <Form className='switch-alignment'>
                            <Form.Check
                                type="switch"
                                id="childNode"
                                checked={listData[0].childPresent}
                                onChange={e => updateListDetails(0,'childPresent',e.target.checked)}
                            />
                        </Form>
                    </Col>
                    </div>
                   
                    <div className="mt-1">
                    <Col md={12}>
                        <InputWithLabelComponent
                            type="text"
                            name="childKey"
                            showLabel={false}
                            value={listData[0].child}
                            readOnly={false}
                            onChange={(e) => updateListDetails(0,'child',e.target.value)}
                            required={true}
                            placeHolder='Child key'
                            disabled={!listData[0].childPresent}
                        />
                    </Col>
                    </div>
                </div>
            )}

            {viewType === 'list' && radioBtn === 'manual' && (
            <div>
                { listData.map((item,index) => renderListInputs(index,item)) }
            </div>
            )}
           
           
            
            <br/>
            <div className="mt-1">
            <Col md={12}>
                <div className="mt-1">
                    <ButtonCustom variant='contained' label='Save'className='buttonWidth' disabled={!isFormValid()} clicked={saveData}/>
                </div>
            </Col>
            </div>
        </div>
    )

}

export default ShowDataDetails