// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toastModal{
z-index: 99999;
}

.toastModal .modal-dialog{
    max-width: 100%;
    /* margin: 0.75rem 1.25rem 0.75rem 4rem; */
    margin:0
}
.toastClose{
    cursor:pointer;
    float: right;
    margin: 0px 15px;
}

.toastModal p{
    color:#fff;
    height: 22px;
    margin-top: 0.2rem;
    margin-bottom: 0.5rem;
    margin-left: 2.5rem;
}

.toastModal .modal-body{
    padding:0.2rem;

}
.toastModal .error{
    background: #F43E16;
}

.toastModal .success{
    background: #0DB01D;
}

.toastModal .warning{
    background: #FFBC00;
}

.toastModal .toastContent{ 
    position: absolute;
    top: 9px;
}`, "",{"version":3,"sources":["webpack://./src/components/UIComponents/ToastMessage/ToastMessage.css"],"names":[],"mappings":"AAAA;AACA,cAAc;AACd;;AAEA;IACI,eAAe;IACf,0CAA0C;IAC1C;AACJ;AACA;IACI,cAAc;IACd,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,kBAAkB;IAClB,qBAAqB;IACrB,mBAAmB;AACvB;;AAEA;IACI,cAAc;;AAElB;AACA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;AACZ","sourcesContent":[".toastModal{\nz-index: 99999;\n}\n\n.toastModal .modal-dialog{\n    max-width: 100%;\n    /* margin: 0.75rem 1.25rem 0.75rem 4rem; */\n    margin:0\n}\n.toastClose{\n    cursor:pointer;\n    float: right;\n    margin: 0px 15px;\n}\n\n.toastModal p{\n    color:#fff;\n    height: 22px;\n    margin-top: 0.2rem;\n    margin-bottom: 0.5rem;\n    margin-left: 2.5rem;\n}\n\n.toastModal .modal-body{\n    padding:0.2rem;\n\n}\n.toastModal .error{\n    background: #F43E16;\n}\n\n.toastModal .success{\n    background: #0DB01D;\n}\n\n.toastModal .warning{\n    background: #FFBC00;\n}\n\n.toastModal .toastContent{ \n    position: absolute;\n    top: 9px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
