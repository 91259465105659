import React, { Component } from "react";
import clsx from "clsx";
import classes from "./Layout.module.css";
import * as actions from "../../store/actions/index";
import { connect } from "react-redux";
import NavbarCustom from "../../components/UI/Navbar/Navbar";
import ModalPopUp from "../../components/UI/Modal/Modal";
import ButtonCustom from "../../components/UI/Button/Button";
import Input from "../../components/UI/Input/InputCustom";
import { Row, Col } from "react-bootstrap";
import withErrorHandler from "../withErrorHandler/withErrorHandler";
import axios from "../../instance";
import ValidationMsg from "../../components/ValidationMsg/ValidationMsg";
import NavigationItems from "../../components/Navigation/NavigationItems/NavigationItems";
import { withRouter, Redirect } from "react-router-dom";
import UserProfileForm from "../../components/UserProfileForm/UserProfileForm";
import $ from "jquery";
import AddProjectDrawer from "../../components/UI/Drawer/AddProjectDrawer";
import ImportProjectDrawer from "../../components/UI/Drawer/ImportProjectDrawer";
import SpinnerCustom from "../../components/UI/Loader/Loader";
import ToastMessage from '../../components/UIComponents/ToastMessage/ToastMessage'
import NavbarVA from "../../components/UI/Navbar/NavBarVA";
import { encryptAndStore } from "../../utility/utility";

class Layout extends Component {
  state = {
    popperShow: false,
    Addprojectcontrols: {
      projectname: {
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: "",
        },
        value: "",
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
        className: "",
        defaultFocus: true,
      },
      projectdescription: {
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: "",
        },
        value: "",
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
        className: "",
      },
      projectlanguageList: {
        elementType: "select",
        elementConfig: {
          options: [{ value: "test", displayValue: "test" }],
        },
        value: "",
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
        className: "selectLang",
      },
    },
    Edituserprofilecontrols: {
      username: {
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: "",
        },
        value: "",
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
        className: "",
        readOnly: true,
      },
      userrole: {
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: "",
        },
        value: "",
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
        className: "",
        readOnly: true,
      },
      userOrgList: {
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: "",
        },
        value: "",
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
        className: "",
        readOnly: true,
      },

      userskillList: {
        elementType: "select",
        elementConfig: {
          options: [{ value: "0", displayValue: "Search Skills" }],
        },

        individualSkillList: {
          elementType: "dropdown",
          elementConfig: {
            options: [],
            className: "deleteList",
            imgsrc: "./Icons/Close.svg",
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          className: "skillListing",
          removedefaultCSSClass: true,
        },
        value: "",
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
        className: "skillListing",
      },

      userknownlanguage: {
        elementType: "select",
        elementConfig: {
          options: [{ value: "0", displayValue: "Search Languages" }],
        },
        individualLanguageList: {
          elementType: "dropdown",
          elementConfig: {
            options: [],
            className: "deleteList",
            imgsrc: "./Icons/Close.svg",
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          className: "skillListing",
          removedefaultCSSClass: true,
        },
        value: "",
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
        className: "skillListing",
      },
      assignProject: {
        elementType: "customdropdown",
        elementConfig: {
          options: {},
          className: "deleteList",
          imgsrc: "./Icons/Close.svg",
        },
        value: "",
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
        className: "skillListing",
        removedefaultCSSClass: true,
        projectfile: "",
        importFile: "",
      },
    },
    show: false,
    setImportProjectShow: false,
    setAddProjectShow: false,
    setEditUserShow: false,
    emptyprojectnameMsg: null,
    emptyusernameMsg: null,
    emptyuserroleMsg: null,
    displayside: false,
    projectname: "Project List",
    showingAlert: false,
    projectViewError: null,
    validProjectFileExtensions: [".exe", ".zip", ".jar"],
    projectList: null,
    searchTerm: "",
    buildProperties: {
      build: null,
      minorVersion: null,
      majorVersion: null,
    },
  };

  handleSidebar = (flag) => {
    this.setState({
      displayside: flag,
    });
  };

  validateFile = (file) => {
    const validTypes = ["application/x-zip-compressed"];
    if (validTypes.indexOf(file) === -1) {
      return false;
    }

    return true;
  };

  handleFile = (e) => {
    if (!/^[A-Z0-9./_]+$/i.test(e.target.files[0].name)) {
      this.setState({ filevalidation: "Project name should be alphanumeric" });
    } else if (this.validateFile(e.target.files[0].type)) {
      let tempPathFile = null;
      let fileAdded = null;
      let file = e.target.files[0];
      if (file) {
        fileAdded = file.name.split(".");
        tempPathFile = fileAdded[0];
      }
      if (fileAdded[0].includes("(")) {
        tempPathFile = fileAdded[0].split("(");
        tempPathFile = tempPathFile[0].trim();
      }
      if (!this.IsprojectExists(tempPathFile)) {
        let DeleteProjName = localStorage.getItem("DeleteProjName");
        if (tempPathFile == DeleteProjName)
          // localStorage.setItem("DeleteProjName", null);
          encryptAndStore("DeleteProjName", null);
        this.setState({ filevalidation: null });
        this.setState({
          projectfile: e.target.value,
          importFile: e.target.files[0],
        });
      } else {
        this.setState({ filevalidation: "Project already exist." });
      }
    } else {
      this.setState({
        projectfile: null,
        importFile: null,
        filevalidation: "File Type is not permitted ",
      });
    }
  };

  submitImport = (e) => {
    e.preventDefault();
    this.setState({ submitImport: true });
    if (this.state.importFile) {
      this.setState({ filevalidation: null });
      let formData = new FormData();
      formData.append("file", this.state.importFile);
      formData.append("language", "en");
      var filename = this.state.projectfile.replace(/^.*[\\\/]/, "");
      let file = filename.split(".");
      this.props.OnProjectfileupload(
        this.props.config.BOTBUILDER_PROXY,
        formData,
        this.props.config.BOTBUILDER_PROXY,
        file[0]
      );
      this.clearUploadProjectStateValue();
    } else {
      if (!this.state.filevalidation)
        this.setState({ filevalidation: "Project name is required" });
    }
  };

  clearUploadProjectStateValue = () => {
    this.setState({ projectfile: "", importFile: "" });
  };

  handleClose = (projectType) => {
    if (projectType == "importProject") {
      this.setState({ setImportProjectShow: false, filevalidation: null });
      this.clearUploadProjectStateValue();
    } else if (projectType == "addProject") {
      this.setState({ setAddProjectShow: false });
      this.clearAddProjectStateValue();
    } else if (projectType == "editUser") {
      this.setState({ setEditUserShow: false });
    }
  };

  handleShow = (event) => {
    this.checkTab(event);
    if (this.props.IsTabChanged)
      this.setState({ setImportProjectShow: true, popperShow: false });
  };
  handleAddShow = (event) => {
    this.checkTab(event);
    if (this.props.IsTabChanged)
      this.setState({ setAddProjectShow: true, popperShow: false });
  };
  handleEditUserShow = () => {
    this.props.onGetUserById(
      this.props.config.API_URL,
      this.props.config.BOTBUILDER_PROXY
    );
    this.setState({ setEditUserShow: true });
  };
  IsprojectExists = (projectName) => {
    let projects =
      this.props.englishProject && this.props.englishProject.length != 0
        ? this.props.englishProject
        : [];
    if (projects !== undefined && projects.length > 0) {
      var projExists = projects.findIndex((element) => {
        return element.toLowerCase() === projectName.toLowerCase();
      });

      if (projExists > -1) {
        return true;
      } else return false;
    } else return false;
  };
  setValidationMessage = (type, value) => {
    if (type == "projectname") {
      if (value.trim() === "")
        this.setState({
          emptyprojectnameMsg: (
            <ValidationMsg>Project name is required</ValidationMsg>
          ),
        });
      else if (!/^[A-Z0-9./_]+$/i.test(value)) {
        this.setState({
          emptyprojectnameMsg: (
            <ValidationMsg>should be alphanumeric</ValidationMsg>
          ),
        });
      } else if (value.length > 15) {
        this.setState({
          emptyprojectnameMsg: (
            <ValidationMsg>should not be more than 15 chars</ValidationMsg>
          ),
        });
      } else if (this.IsprojectExists(value)) {
        this.setState({
          emptyprojectnameMsg: (
            <ValidationMsg>Project already exists</ValidationMsg>
          ),
        });
      } else this.setState({ emptyprojectnameMsg: null });
    } else if (type == "username") {
      value.trim() === ""
        ? this.setState({
            emptyusernameMsg: (
              <ValidationMsg>User name is required</ValidationMsg>
            ),
          })
        : this.setState({ emptyusernameMsg: null });
    } else if (type == "userrole") {
      value.trim() === ""
        ? this.setState({
            emptyuserroleMsg: (
              <ValidationMsg>User role is required</ValidationMsg>
            ),
          })
        : this.setState({ emptyuserroleMsg: null });
    }
  };
  inputChangedHandler = (event, id) => {
    if (id == "projectlanguageList") {
      this.setState({
        Addprojectcontrols: {
          ...this.state.Addprojectcontrols,
          projectlanguageList: {
            ...this.state.Addprojectcontrols.projectlanguageList,
            value: event.target.value,
          },
        },
      });
    } else if (id == "projectname" || id == "projectdescription") {
      const updatedControls = {
        ...this.state.Addprojectcontrols,
        [id]: {
          ...this.state.Addprojectcontrols[id],
          value: event.target.value,
          defaultFocus: false,
        },
      };
      if (id == "projectname") {
        this.setValidationMessage("projectname", event.target.value);
      }
      this.setState({ Addprojectcontrols: updatedControls });
    }
  };
  editUserDeleteLi = (value, id) => {
    if (id == "individualSkill") {
      let val = value;
      let individualSkill = [];
      this.state.Edituserprofilecontrols.userskillList.individualSkillList.elementConfig.options.forEach(
        (skill) => {
        return individualSkill.push(skill.value);
        }
      );
      let index = individualSkill.indexOf(val);
      if (index > -1) {
        let indskills =
          this.state.Edituserprofilecontrols.userskillList.individualSkillList
            .elementConfig.options;
        indskills.splice(index, 1);

        const updatedControls = {
          ...this.state.Edituserprofilecontrols,
          userskillList: {
            ...this.state.Edituserprofilecontrols.userskillList,
            individualSkillList: {
              ...this.state.Edituserprofilecontrols.userskillList
                .individualSkillList,
              elementConfig: {
                ...this.state.Edituserprofilecontrols.userskillList
                  .individualSkillList.elementConfig,
                options: indskills,
              },
            },
          },
        };
        this.setState({ Edituserprofilecontrols: updatedControls });
      }
    } else if (id == "individualLanguage") {
      let val = value;
      let individualLanguage = [];
      this.state.Edituserprofilecontrols.userknownlanguage.individualLanguageList.elementConfig.options.forEach(
        (lang) => {
        return individualLanguage.push(lang.value);
        }
      );
      let index = individualLanguage.indexOf(val);
      if (index > -1) {
        let indLang =
          this.state.Edituserprofilecontrols.userknownlanguage
            .individualLanguageList.elementConfig.options;
        indLang.splice(index, 1);

        const updatedControls = {
          ...this.state.Edituserprofilecontrols,
          userknownlanguage: {
            ...this.state.Edituserprofilecontrols.userknownlanguage,
            userknownlanguage: {
              ...this.state.Edituserprofilecontrols.userknownlanguage
                .individualLanguageList,
              elementConfig: {
                ...this.state.Edituserprofilecontrols.userknownlanguage
                  .individualLanguageList.elementConfig,
                options: indLang,
              },
            },
          },
        };

        this.setState({ Edituserprofilecontrols: updatedControls });
      }
    }
  };
  editUserChangedHandler = (event, id) => {
    if (id == "username" || id == "userrole" || id == "userOrgList") {
      const updatedControls = {
        ...this.state.Edituserprofilecontrols,
        [id]: {
          ...this.state.Edituserprofilecontrols[id],
          value: event.target.value,
        },
      };

      this.setState({ Edituserprofilecontrols: updatedControls });
    } else if (id == "individualSkill") {
    } else if (id == "userskillList") {
      let val = event.target.value;
      let individualSkill = [];
      this.state.Edituserprofilecontrols.userskillList.individualSkillList.elementConfig.options.forEach(
        (skill) => {
        return  individualSkill.push(skill.value.toLowerCase());
        }
      );

      if (individualSkill.indexOf(val.toLowerCase()) == -1) {
        const updatedControls = {
          ...this.state.Edituserprofilecontrols,
          userskillList: {
            ...this.state.Edituserprofilecontrols.userskillList,
            individualSkillList: {
              ...this.state.Edituserprofilecontrols.userskillList
                .individualSkillList,
              elementConfig: {
                ...this.state.Edituserprofilecontrols.userskillList
                  .individualSkillList.elementConfig,
                options: [
                  ...this.state.Edituserprofilecontrols.userskillList
                    .individualSkillList.elementConfig.options,
                  { value: val, displayValue: val },
                ],
              },
            },
          },
        };

        this.setState({ Edituserprofilecontrols: updatedControls });
      }
    } else if (id == "userknownlanguage") {
      let val = event.target.value;
      let individualLanguage = [];
      this.state.Edituserprofilecontrols.userknownlanguage.individualLanguageList.elementConfig.options.forEach(
        (language) => {
        return individualLanguage.push(language.value);
        }
      );

      if (individualLanguage.indexOf(val) == -1) {
        const updatedControls = {
          ...this.state.Edituserprofilecontrols,
          userknownlanguage: {
            ...this.state.Edituserprofilecontrols.userknownlanguage,
            individualLanguageList: {
              ...this.state.Edituserprofilecontrols.userknownlanguage
                .individualLanguageList,
              elementConfig: {
                ...this.state.Edituserprofilecontrols.userknownlanguage
                  .individualLanguageList.elementConfig,
                options: [
                  ...this.state.Edituserprofilecontrols.userknownlanguage
                    .individualLanguageList.elementConfig.options,
                  { value: val, displayValue: val },
                ],
              },
            },
          },
        };

        this.setState({ Edituserprofilecontrols: updatedControls });
      }
    }
  };

  handlesubmitAdd = (event) => {
    event.preventDefault();

    this.setValidationMessage(
      "projectname",
      this.state.Addprojectcontrols.projectname.value
    );
    if (
      this.state.Addprojectcontrols.projectname.value &&
      !this.IsprojectExists(this.state.Addprojectcontrols.projectname.value) &&
      /^[A-Z0-9./_]+$/i.test(this.state.Addprojectcontrols.projectname.value) &&
      this.state.Addprojectcontrols.projectname.value.length <= 15 &&
      this.state.Addprojectcontrols.projectlanguageList.value
    ) {
      // localStorage.setItem("projectname",this.state.Addprojectcontrols.projectname.value);
      encryptAndStore("projectname",this.state.Addprojectcontrols.projectname.value);
      this.props.OnCreateProject(
        this.props.config.API_URL,
        this.props.config.COMMON_API_URL,
        this.props.config.BOTBUILDER_PROXY,
        {
          name: this.state.Addprojectcontrols.projectname.value,
          description: this.state.Addprojectcontrols.projectdescription.value,
          language: this.state.Addprojectcontrols.projectlanguageList.value,
        }
      );
      localStorage.removeItem("DeleteProjName");
      this.state.Addprojectcontrols.projectname.value = null;
      let projectname = localStorage.getItem("projectname");
      let language = localStorage.getItem("language");
      let docvalue = {
        projectname: projectname,
        language: language,
      };
      this.props.OngetProjectDescription(
        docvalue,
        this.props.config.BOTBUILDER_URL
      );
      this.clearAddProjectStateValue();
    }
  };

  clearAddProjectStateValue = () => {
    this.setState({
      Addprojectcontrols: {
        ...this.state.Addprojectcontrols,
        projectname: {
          ...this.state.Addprojectcontrols.projectname,
          value: "",
          defaultFocus: true,
        },
        projectdescription: {
          value: "",
        },
      },
      emptyprojectnameMsg: null,
    });
  };

  handlesubmitEditUser = (event) => {
    event.preventDefault();
    if (
      this.state.Edituserprofilecontrols.username.value &&
      this.state.Edituserprofilecontrols.userrole.value &&
      this.state.Edituserprofilecontrols.userOrgList.value &&
      this.state.Edituserprofilecontrols.userskillList.individualSkillList
        .elementConfig.options &&
      this.state.Edituserprofilecontrols.userknownlanguage
        .individualLanguageList.elementConfig.options &&
      this.state.Edituserprofilecontrols.assignProject.elementConfig.options
    ) {
      let user = {};
      let individualLanguage = [];
      let individualSkill = [];
      this.state.Edituserprofilecontrols.userknownlanguage.individualLanguageList.elementConfig.options.forEach(
        (language) => {
        return  individualLanguage.push(language.value);
        }
      );
      this.state.Edituserprofilecontrols.userskillList.individualSkillList.elementConfig.options.forEach(
        (skill) => {
        return  individualSkill.push(skill.value);
        }
      );
      user.username = this.state.Edituserprofilecontrols.username.value;
      user.role = this.state.Edituserprofilecontrols.userrole.value;
      user.org = this.state.Edituserprofilecontrols.userOrgList.value;
      user.useremailid = this.props.user.user.useremailid;
      user.skillset = individualSkill;
      user.languages = individualLanguage;
      user.bots = this.props.user.user.bots;
      this.props.OnUpdateUser(this.props.config.API_URL, user);
      this.handleClose("editUser");
    }
  };

  popperShowFn = () => {
    this.setState({
      popperShow: true,
    });
  };

  projectclick = (event, projectname) => {
    this.checkTab(event);
    if (this.props.IsTabChanged) {
      this.setState({ searchTerm: "", popperShow: false });
      const queryParams = [];
      queryParams.push("projname=" + projectname);
      let savedProjectName = localStorage.getItem("projectname");
      if (savedProjectName !== projectname) {
        // localStorage.setItem("projectname", projectname);
        encryptAndStore("projectname", projectname);
        this.setState({ projectname: projectname });
      }
      //getting project related user permissions
      const commonapi_URL = this.props.config.COMMON_API_URL;
      this.props.OnGetUserPermissions(commonapi_URL, projectname);
      this.props.OnGetProjectLang(
        this.props.config.BOTBUILDER_URL,
        projectname
      );
      const queryString = queryParams.join("&");
      if (
        this.props.history.location.pathname != "/insights" &&
        this.props.history.location.pathname != "/settings" &&
        this.props.history.location.search != "?ticketsInsights" &&
        this.props.history.location.pathname != "/Cognitive"
      ) {
        this.props.history.push({
          pathname: "/projectviewpage",
          search: "?" + queryString,
        });
      }
      this.props.onchangeProjectAction(projectname);
      window.updateHCLChatBotURL(projectname);
    }
    let projectname1 = localStorage.getItem("projectname");
    let language = localStorage.getItem("language");
    let docvalue = {
      projectname: projectname1,
      language: language,
    };
    this.props.OngetProjectDescription(
      docvalue,
      this.props.config.BOTBUILDER_URL
    );

    //  to be uncommented
    let orgname = localStorage.getItem("orgName");
    if (!this.props.config.BOT_ORGS.includes(orgname)) {
      this.props.onGetCuxBotList(this.props.config.COMMON_API_URL, projectname);
    }
  };

  assistClick = (event, projectname) => {
    this.setState({ searchTerm: "", popperShow: false });
    // localStorage.setItem("vaName", projectname);
    encryptAndStore("vaName", projectname);
    const queryParams = [];
    queryParams.push("projname=" + projectname);
    const queryString = queryParams.join("&");
    this.props.history.push({
        pathname: "/virtual-assistant",
        search: "?" + queryString,
    });
    
  };

  checkTab = (event) => {
    let projectViewError = null;
    if (!this.props.IsTabChanged) {
      setTimeout(() => {
        this.setState(
          {
            showingAlert: false,
          },
          () => {
            projectViewError = null;
            this.setState({ projectViewError: projectViewError });
          }
        );
        actions.resetMessages();
      }, 3000);
      this.setState({ showingAlert: true }, () => {
        projectViewError = (
          <div
            className={[
              "alert",
              classes.alertError,
              "alert-danger",
              "row",
              this.state.showingAlert ? "alert-shown" : classes.alerthidden,
            ].join(" ")}
          >
            <div className={["col-2", classes.imgCont].join(" ")}>
              <img align="left" src="./Icons/Warning.svg" />
            </div>
            <div className="col-10">
              <h6>Warning..</h6>
              Please Save Edit Flow Details.
            </div>
          </div>
        );
        this.setState({ projectViewError: projectViewError });
      });

      event.preventDefault();
      return;
    }
  };
  setSearchTerm = (item) => {
    this.setState({ searchTerm: item });
  };
  componentDidMount() {
    var head = document.getElementsByTagName("head")[0];
    var js = document.createElement("script");
    js.type = "text/javascript";
    var orgname = localStorage.getItem("orgName");
    if (!this.props.config.BOT_ORGS.includes(orgname)) { //if not script based org
      js.src = "./IntelliCuxBot/HCLchatbot.js";
    } else {
      js.src = "./IntelliLeadsBot/HCLchatbot.js";
    }
    head.appendChild(js);

    const apiURL = this.props.config.API_URL;
    const botBuilder_URL = this.props.config.BOTBUILDER_URL;
    const commonapi_URL = this.props.config.COMMON_API_URL;
    this.props.OnGetLanguage(botBuilder_URL);
    this.props.OnGetBuildProps(botBuilder_URL);
    this.props.onGetUserById(apiURL, this.props.config.BOTBUILDER_PROXY);
    if(localStorage.getItem("vaName")){
      this.props.OnGetAllAssistantsData(botBuilder_URL);
    }
    this.props.OnGetUserPermissions(commonapi_URL);
    this.props.getCMMLoginStatus(commonapi_URL+'/organization/cmm/status');

    if (
      localStorage.getItem("projectname") !== null &&
      localStorage.getItem("projectname") !== undefined
    ) {
      this.setState({ projectname: localStorage.getItem("projectname") });
      let projectname1 = localStorage.getItem("projectname");
      let language = localStorage.getItem("language");
      let docvalue = {
        projectname: projectname1,
        language: language,
      };
      this.props.OnGetProjectLang(
        this.props.config.BOTBUILDER_URL,
        projectname1
      );
      if (this.props.history.location.pathname == "/projectviewpage") {
        this.props.OngetProjectDescription(
          docvalue,
          this.props.config.BOTBUILDER_URL
        );
      }
     
    }

    
    
    this.props.onchangeTabAction(true);
    this.props.onGetEnglishProjects(this.props.config.BOTBUILDER_PROXY);
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.IsKnowledgeGridCollapse &&
      this.props.IsKnowledgeGridCollapse !== nextProps.IsKnowledgeGridCollapse
    ) {
      this.handleSidebar(nextProps.IsKnowledgeGridCollapse ? false : true);
    }
    if (nextProps.editbot !== this.props.editbot) {
      // localStorage.setItem("projectname", nextProps.editprojectname);
      encryptAndStore("projectname", nextProps.editprojectname);
      this.setState({ projectname: nextProps.editprojectname });
    }
    // Close Popup after add/update/delete cycle complete
    if (
      nextProps.createProjectFlag !== this.props.createProjectFlag &&
      nextProps.createProjectFlag === "E"
    ) {
      this.handleClose("addProject");

      if (localStorage.getItem("language") == "en") {
        setTimeout(() => {
          this.setState({ projectname: localStorage.getItem("projectname") });
          this.projectclick("event", localStorage.getItem("projectname"));
         
         
        }, 300);
      }
    }

    if (
      nextProps.uploadProjectFlag !== this.props.uploadProjectFlag &&
      nextProps.uploadProjectFlag === "E"
    ) {
      this.handleClose("importProject");
      if (localStorage.getItem("language") == "en") {
        if (!nextProps.createProjectFail) {
          setTimeout(() => {
            this.setState({ projectname: localStorage.getItem("projectname") });
            this.projectclick("event", localStorage.getItem("projectname"));
          }, 600);
        }
      }
    }

    if (Object.keys(this.props.user).length > 0) {
      if (
        this.props.user.user !== undefined &&
        this.props.user.user.bots &&
        Object.keys(this.props.user.user.bots).length > 0
      ) {
        let botlist = nextProps.user.user.bots;
        if (nextProps.user.user.bots != undefined) {
          await this.setState({
            Edituserprofilecontrols: {
              ...this.state.Edituserprofilecontrols,
              assignProject: {
                ...this.state.Edituserprofilecontrols.assignProject,

                elementConfig: {
                  ...this.state.Edituserprofilecontrols.assignProject
                    .elementConfig,
                  options: botlist,
                },
              },
            },
          });
        }
      }
    }

    if (
      this.props.skills &&
      this.props.skills !== undefined &&
      this.props.skills.length > 0
    ) {
      let options = [{ value: "0", displayValue: "Search Skills" }];

      this.props.skills.map((option) =>
        options.push({
          value: option.skillsetname,
          displayValue: option.skillsetname,
        })
      );
      await this.setState({
        Edituserprofilecontrols: {
          ...this.state.Edituserprofilecontrols,
          userskillList: {
            ...this.state.Edituserprofilecontrols.userskillList,
            elementConfig: {
              ...this.state.Edituserprofilecontrols.userskillList.elementConfig,
              options: options,
            },
          },
        },
      });
    }

    if (
      this.props.user.user &&
      this.props.user.user.skillset !== undefined &&
      this.props.user.user.skillset.length > 0
    ) {
      let options1 = [];
      this.props.user.user.skillset.map((option) =>
        options1.push({ value: option, displayValue: option })
      );
      await this.setState({
        Edituserprofilecontrols: {
          ...this.state.Edituserprofilecontrols,
          userskillList: {
            ...this.state.Edituserprofilecontrols.userskillList,
            individualSkillList: {
              ...this.state.Edituserprofilecontrols.userskillList
                .individualSkillList,
              elementConfig: {
                ...this.state.Edituserprofilecontrols.userskillList
                  .individualSkillList.elementConfig,
                options: options1,
              },
            },
          },
        },
      });
      //}
    }

    if (this.props.user.user && this.props.user.user !== undefined) {
      await this.setState({
        Edituserprofilecontrols: {
          ...this.state.Edituserprofilecontrols,
          username: {
            ...this.state.Edituserprofilecontrols.username,
            value: this.props.user.user.username,
          },
          userrole: {
            ...this.state.Edituserprofilecontrols.userrole,
            value: this.props.user.user.role,
          },
          userOrgList: {
            ...this.state.Edituserprofilecontrols.userOrgList,
            value: this.props.user.user.org,
          },
        },
      });
      //}
    }

    if (
      this.props.languages &&
      this.props.languages !== undefined &&
      this.props.languages.length > 0
    ) {
      let options = [{ value: "0", displayValue: "Search Languages" }];

      this.props.languages.map((option) =>
        options.push({ value: option.key, displayValue: option.value })
      );
      await this.setState({
        Edituserprofilecontrols: {
          ...this.state.Edituserprofilecontrols,
          userknownlanguage: {
            ...this.state.Edituserprofilecontrols.userknownlanguage,
            elementConfig: {
              ...this.state.Edituserprofilecontrols.userknownlanguage
                .elementConfig,
              options: options,
            },
          },
        },
      });
    }

    if (
      this.props.user.user &&
      this.props.user.user.languages !== undefined &&
      this.props.user.user.languages.length > 0
    ) {
      let options1 = [];

      this.props.user.user.languages.map((option) =>
        options1.push({ value: option, displayValue: option })
      );
      await this.setState({
        Edituserprofilecontrols: {
          ...this.state.Edituserprofilecontrols,
          userknownlanguage: {
            ...this.state.Edituserprofilecontrols.userknownlanguage,
            individualLanguageList: {
              ...this.state.Edituserprofilecontrols.userknownlanguage
                .individualLanguageList,
              elementConfig: {
                ...this.state.Edituserprofilecontrols.userknownlanguage
                  .individualLanguageList.elementConfig,
                options: options1,
              },
            },
          },
        },
      });
    }


    if (this.props.languageList && this.props.languageList !== undefined) {
      var options = [];
      let language_array = this.props.languageList.split(",");
      const uniquelanguage = [...new Set(language_array)];
    
      // show default language as selected
      if (
        this.props.defaultlanginfo &&
        this.props.defaultlanginfo != undefined &&
        this.props.defaultlanginfo.langkey
      ) {
        let lname =
          this.props.defaultlanginfo.langname.charAt(0).toUpperCase() +
          this.props.defaultlanginfo.langname.slice(1);
        options.push({
          value: this.props.defaultlanginfo.langkey,
          displayValue: lname,
        });
      }
   
      if (uniquelanguage !== undefined && uniquelanguage.length > 0) {
        uniquelanguage.forEach((option) => {
          let lngvalue = option.split("_")[0];
          let lngkey = option.split("_")[1];
          lngvalue = lngvalue.charAt(0).toUpperCase() + lngvalue.slice(1);
          // skip default language
          if (
            this.props.defaultlanginfo &&
            this.props.defaultlanginfo != undefined &&
            this.props.defaultlanginfo.langkey == lngkey
          ) {
            return;
          }
          return options.push({ value: lngkey, displayValue: lngvalue });
        });

        this.setState({
          Addprojectcontrols: {
            ...this.state.Addprojectcontrols,
            projectlanguageList: {
              ...this.state.Addprojectcontrols.projectlanguageList,

              elementConfig: {
                ...this.state.Addprojectcontrols.projectlanguageList
                  .elementConfig,
                options: options,
              },
              value: options[0].value,
            },
          },
        });
      }
    }

    if (
      nextProps.loading != undefined &&
      !nextProps.loading &&
      this.props.loading != nextProps.loading
    ) {
      this.handleClose("addProject");
      this.props.onGetEnglishProjects(this.props.config.BOTBUILDER_PROXY);
    }

    if (
      this.props.buildProps &&
      this.props.buildProps !== undefined &&
      this.props.buildProps.bulidProperties
    ) {
      let parsedbuildValue = this.props.buildProps;
      await this.setState({
        buildProperties: parsedbuildValue.bulidProperties,
      });
    }
  }
  componentDidUpdate(prevprops) {
    if (
      this.props.IsKnowledgeGridCollapse &&
      this.props.IsKnowledgeGridCollapse !== prevprops.IsKnowledgeGridCollapse
    ) {
      this.handleSidebar(this.props.IsKnowledgeGridCollapse ? false : true);
    }

    if (
      this.props.projectlistbylang &&
      this.props.projectlistbylang !== prevprops.projectlistbylang
    ) {
      // Got all mapping data

      this.setState({ projectList: this.props.projectlistbylang });
    }
    if (this.props.defaultlanginfo !== prevprops.defaultlanginfo) {

      this.props.onGetUserById(
        this.props.config.API_URL,
        this.props.config.BOTBUILDER_PROXY
      );
    }
  }
  ClearProjectSelection = (event) => {
    $(
      '.navigationHelpMenu div[aria-labelledby="navigationHelpMenu"]'
    ).removeClass("show");
    this.checkTab(event);

    if (this.props.IsTabChanged) {
      localStorage.removeItem("projectname");
      this.setState({ projectname: "Project List" });
      this.setState({ searchTerm: "" });
    }
  };
  CheckIfProjectSelected = (event,value) => {
    if(value=='/reportdashboard') return
    $(
      '.navigationHelpMenu div[aria-labelledby="navigationHelpMenu"]'
    ).removeClass("show");
    let projectViewError = null;
    if (
      (this.state.projectname &&
        this.state.projectname === "Project List" &&
        (localStorage.getItem("projectname") === null ||
          localStorage.getItem("projectname") === "Project List")) ||
      localStorage.getItem("projectname") === "Project List"
    ) {
      setTimeout(() => {
        this.setState(
          {
            showingAlert: false,
          },
          () => {
            projectViewError = null;
            this.setState({ projectViewError: projectViewError });
          }
        );
        actions.resetMessages();
      }, 3000);
     
      if (localStorage.getItem("language") == "en") {
        if (
          localStorage.getItem("projectname") != "undefined" ||
          localStorage.getItem("projectname")
        ) {
          // localStorage.setItem("projectname", "common");
          encryptAndStore("projectname", "common");
        }
      } else {
        // localStorage.setItem("projectname", "common");
        encryptAndStore("projectname", "common");
      }
    } else {
        if(localStorage.getItem("projectname") == "All Projects"){
          // localStorage.setItem("projectname", "common");
          encryptAndStore("projectname", "common");
          this.props.OnGetProjectLang(
            this.props.config.BOTBUILDER_URL,localStorage.getItem("projectname")
          );
        }else{
          this.props.OnGetProjectLang(
            this.props.config.BOTBUILDER_URL,localStorage.getItem("projectname")
          );
        }
     
      this.checkTab(event);
    }
  };
  closeDropdown = (event) => {
    $(
      '.navigationHelpMenu div[aria-labelledby="navigationHelpMenu"]'
    ).removeClass("show");
    this.checkTab(event);
    localStorage.removeItem("projectname");
    this.setState({ projectname: "Project List" });
    this.setState({ searchTerm: "" });
  };
  CheckIfAdmin = (event) => {
    this.checkTab(event);
    let userRole = this.state.Edituserprofilecontrols.userrole.value;
    let userName = localStorage.getItem("userName");
    if (userName && userName !== "admin") {
      if (userRole && userRole !== "admin") event.preventDefault();
    }
    this.setState({ searchTerm: "" });
  };

  render() {
    let loginStatus = this.props.cmmLoginStatus;
    if(loginStatus && loginStatus.cmm==false && localStorage.getItem('cmmStatus')=='true'){
      setTimeout(() => {
        this.props.OnlogOut(); 
      }, 5000);
      
    }
    
    if(loginStatus && loginStatus.cmm==true && localStorage.getItem('cmmStatus')!='true'){
      setTimeout(() => {
        this.props.OnlogOut(); 
      }, 5000);
    }
    let userRole = this.state.Edituserprofilecontrols.userrole.value;
    let userName = localStorage.getItem("userName");
    let navbar = null;
    let showNavBar = true;
    let showSideBar = true;
    let showbuttons = true;
    let path = this.props.location.pathname;
    let navigationitems = null;
   
    if (
      path == "/settings" ||
      path == "/components" ||
      path == "/insights" ||
      path == "/tickets" ||
      path == "/integrationTabs" ||
      path == "/Cognitive"||
      path == "/reportdashboard"||
      path == "/reportdescription"||
      path == "/ManualUpload"
    ) {
      showNavBar = false;
    }
    if (path == "/components") {
      showSideBar = false;
    }

    if ((path == "/projectview" || path == "/")  && this.props.isAuthenticated) {
      showNavBar = false;
    }

    let authRedirect = null;
    if (this.props.isAuthenticated && this.props.isLoggedin && path=='/logincmm') {
        authRedirect = <Redirect to="/projectview"/>
    } 

   

    if (this.props.showProjectHeader && this.props.isAuthenticated && showNavBar) {
        navbar = (
          <NavbarCustom
            data={this.props.user}
            popperShow={this.state.popperShow}
            popperShowFn={() => this.popperShowFn()}
            dataList={this.props.projectList}
            clicked={this.props.OnlogOut}
            importProject={this.handleShow}
            addProject={this.handleAddShow}
            editUser={this.handleEditUserShow}
            projectclick={(event, item) => this.projectclick(event, item)}
            projectname={this.state.projectname}
            defaultlang={this.props.defaultlanginfo}
            setSearchTerm={(item) => this.setSearchTerm(item)}
            searchTerm={this.state.searchTerm}
            addImportProjectIcon={true}
            rightPopUp={true}
            showbuttons={showbuttons}
            getprojDesp={this.props.getprojDesp}
          ></NavbarCustom>
        );

      }

      // if (this.props.showProjectHeader){
      navbar = path != "/virtual-assistant" ? (
        this.props.showProjectHeader?<NavbarCustom
          isVA={path == "/virtual-assistant"}
          data={this.props.user}
          popperShow={this.state.popperShow}
          popperShowFn={() => this.popperShowFn()}
          dataList={this.props.projectList}
          clicked={this.props.OnlogOut}
          importProject={this.handleShow}
          addProject={this.handleAddShow}
          editUser={this.handleEditUserShow}
          projectclick={(event, item) => this.projectclick(event, item)}
          projectname={this.state.projectname}
          defaultlang={this.props.defaultlanginfo}
          setSearchTerm={(item) => this.setSearchTerm(item)}
          searchTerm={this.state.searchTerm}
          addImportProjectIcon={true}
          rightPopUp={true}
          showbuttons={showbuttons}
          getprojDesp={this.props.getprojDesp}
        ></NavbarCustom>:''
      ) : <NavbarVA
        isVA={path == "/virtual-assistant"}
        data={this.props.user}
        popperShow={this.state.popperShow}
        popperShowFn={() => this.popperShowFn()}
        dataList={this.props.AssistantsList}
        clicked={this.props.OnlogOut}
        importProject={this.handleShow}
        addProject={this.handleAddShow}
        editUser={this.handleEditUserShow}
        projectclick={(event, item) => this.assistClick(event, item)}
        projectname={this.state.projectname}
        defaultlang={this.props.defaultlanginfo}
        setSearchTerm={(item) => this.setSearchTerm(item)}
        searchTerm={this.state.searchTerm}
        addImportProjectIcon={true}
        rightPopUp={true}
        showbuttons={showbuttons}
        getprojDesp={this.props.getprojDesp}
      ></NavbarVA>;
      // }

        navigationitems = (
          <NavigationItems
            isAuthenticated={this.props.isAuthenticated}
            DisplaySide={this.state.displayside}
            handleSidebar={(flag) => this.handleSidebar(flag)}
            path={this.props.location}
            ClearProjectSelection={this.ClearProjectSelection}
            CheckIfProjectSelected={this.CheckIfProjectSelected}
            closeDropdown={this.closeDropdown}
            projectname={this.state.projectname}
            CheckIfAdmin={this.CheckIfAdmin}
            editUser={this.handleEditUserShow}
            clicked={this.props.OnlogOut}
            username={userName}
            userrole={userRole}
            config={this.props.config}
          />
        );
      
  
    if (this.props.isAuthenticated && !showNavBar) {
      navbar = null;
      navigationitems = (
        <NavigationItems
          isAuthenticated={this.props.isAuthenticated}
          DisplaySide={this.state.displayside}
          handleSidebar={(flag) => this.handleSidebar(flag)}
          path={this.props.location}
          ClearProjectSelection={this.ClearProjectSelection}
          CheckIfProjectSelected={this.CheckIfProjectSelected}
          projectname={this.state.projectname}
          CheckIfAdmin={this.CheckIfAdmin}
          closeDropdown={this.closeDropdown}
          editUser={this.handleEditUserShow}
          clicked={this.props.OnlogOut}
          username={userName}
          userrole={userRole}
          config={this.props.config}
        />
      );
    }
    if (this.props.isAuthenticated && !showNavBar && !showSideBar) {
      navbar = null;
      navigationitems = null;
    }

    if (path == "/projectviewpage" && this.props.isAuthenticated) {
      navigationitems = null;
    }
    if (path == "/virtual-assistant" && this.props.isAuthenticated) {
      navigationitems = null;
    }
    if (path == "/reportdescription" && this.props.isAuthenticated) {
      navigationitems = null;
    }
    if(!this.props.isLoggedin){
      navbar = null;
      navigationitems = null;
    }

    if (Object.keys(this.props.user).length > 0) {
      
      if (
        this.props.user.user !== undefined &&
        this.props.user.user.bots !== undefined &&
        Object.keys(this.props.user.user.bots).length > 0
      ) {

        let botlist = this.props.user.user.bots;
        var merged = [].concat.apply([], Object.values(botlist));
        let projects = [...new Set(merged)];
      }
    }

    let loadingImg = null;

    if (this.props.loading) {
      loadingImg = <SpinnerCustom></SpinnerCustom>;
    } else {
      loadingImg = null;
    }

    let projectCreateImg = null;
    if (this.props.createProjectFlag == "S") {
      projectCreateImg = <SpinnerCustom />;
    } else projectCreateImg = null;

    let uploadprojectImg = null;
    if (this.props.uploadProjectFlag == "S") {
      uploadprojectImg = <SpinnerCustom />;
    } else uploadprojectImg = null;
    const addProjectformElementsArray = [];
    for (let key in this.state.Addprojectcontrols) {
      let labelName = "";
      let autofocus = false;
      if (key == "projectname") {
        labelName = (
          <label>
            Project Name <b>*</b>
          </label>
        );
        autofocus = true;
      } else if (key == "projectdescription") {
        labelName = <label>Description </label>;
      } else if (key == "projectlanguageList") {
        labelName = (
          <label>
            Default Language <b>*</b>
          </label>
        );
      }
      addProjectformElementsArray.push({
        id: key,
        config: this.state.Addprojectcontrols[key],
        labelName: labelName,
        autofocus: autofocus,
      });
    }

    let addProjectform = addProjectformElementsArray.map((formElement) => (
      <Row key={formElement.id}>
        <Col md={12}>
          <div className="inputContainer">
            {formElement.labelName}
            <Input
              key={formElement.id}
              customclassName={formElement.config.className}
              elementType={formElement.config.elementType}
              elementConfig={formElement.config.elementConfig}
              value={formElement.config.value}
              invalid={!formElement.config.valid}
              shouldValidate={formElement.config.validation}
              touched={formElement.config.touched}
              changed={(event) =>
                this.inputChangedHandler(event, formElement.id)
              }
              defaultFocus={formElement.config.defaultFocus}
            />
          </div>
          {formElement.id === "projectname"
            ? this.state.emptyprojectnameMsg
            : null}
        </Col>
      </Row>
    ));

    if(loginStatus && loginStatus.cmm==false && localStorage.getItem('cmmStatus')=='true'){
      return(
        <>
         <ToastMessage closeModal={true} 
              showingAlert={true} 
              message={localStorage.getItem('userName')+" is not entitled to login with devsandbox.intelliservice.ai/logincmm"} customClass='error'/>
        </>
      )
    }else if(loginStatus && loginStatus.cmm==true && localStorage.getItem('cmmStatus')!='true'){
      return(
        <>
        <ToastMessage closeModal={true} 
              showingAlert={true} 
              message={localStorage.getItem('userName')+" is not entitled to login with devsandbox.intelliservice.ai"} customClass='error'/>
        </>
      )
    }else{
      return (
        <>
          {this.state.projectViewError}
          {authRedirect}
          <div className={classes.outerContainer}>
            <Row className="no-gutters">
              <AddProjectDrawer
                show={this.state.setAddProjectShow}
                onHide={this.handleClose}
                config={this.props.config}
                isThisExists={this.IsprojectExists}
              />
              <div id="sidebar-container">
                {(path !== "/tickets") ? navigationitems : null}{" "}
              </div>
              <Col className={clsx(classes.rightContainer, "no-gutters")}>
                <Row className="no-gutters topBar">
                  <Col>{navbar}</Col>
                </Row>
                <Row className="no-gutters topBar">
                  <Col>
                    <main className="main_container">{this.props.children}</main>
                  </Col>
                </Row>
              
              </Col>
            </Row>
            <ImportProjectDrawer
              show={this.state.setImportProjectShow}
              error={this.state.filevalidation}
              onHide={() => this.handleClose("importProject")}
              value={this.state.projectfile}
              onChange={this.handleFile}
              submit={(e) => this.submitImport(e)}
            />
            {projectCreateImg}
            {uploadprojectImg}
          
  
            <ModalPopUp
              modalclass="projectModal"
              // show={this.state.setAddProjectShow}
              onHide={() => this.handleClose("addProject")}
              title="Add Project"
              submit={(e) => this.handlesubmitAdd(e)}
            >
              {addProjectform}
              {loadingImg}
              <Row className={classes.ButtonAlign}>
                <ButtonCustom btnType="blueButton">Add</ButtonCustom>
                <ButtonCustom
                  btnInput="button"
                  btnType="whiteButton"
                  clicked={() => this.handleClose("addProject")}
                >
                  Cancel
                </ButtonCustom>
              </Row>
  
              <Row>{this.state.successMsg}</Row>
            </ModalPopUp>
  
            <ModalPopUp
              modalclass="projectModal"
              show={this.state.setEditUserShow}
              onHide={() => this.handleClose("editUser")}
              title="User Profile"
              submit={(e) => this.handlesubmitEditUser(e)}
            >
              {
                /*edituserProfileform*/
                <UserProfileForm
                  profilecontrols={this.state.Edituserprofilecontrols}
                  changeEventHandler={this.editUserChangedHandler}
                  ProjectsDisabled="true"
                  editUserDeleteLi={this.editUserDeleteLi}
                />
              }
  
              <Row className={classes.ButtonAlign}>
                <ButtonCustom btnType="blueButton">Save</ButtonCustom>
                <ButtonCustom
                  btnInput="button"
                  btnType="whiteButton"
                  clicked={() => this.handleClose("editUser")}
                >
                  Cancel
                </ButtonCustom>
              </Row>
  
              <Row>{this.state.successMsg}</Row>
            </ModalPopUp>
          </div>
        </>
      );
    }

   
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.sessiontoken !== null,
    user: state.user,
    projectList: state.user.projects,
    AssistantsList: state.projectview.AllAssistantsList,
    skills: state.user.skillList,
    getprojDesp: state.projectview.getprojDesp,
    isLoggedin:state.auth.isLoggedin,
    languages: [{ key: "en", value: "English" }],
    setting: state.user.settings,
    loading: state.user.loading,
    UserCreated: state.auth.userCreated,
    createProjectFlag: state.user.createProjectFlag,
    uploadProjectFlag: state.user.uploadProjectFlag,
    createProjectFail: state.user.createProjectFail,
    defaultlanginfo: state.user.defaultlanginfo,
    editprojectname: state.home.editprojectname,
    editbot: state.home.editbot,
    projectlistbylang: state.user.projectlistbylang,
    IsKnowledgeGridCollapse: state.projectview.IsKnowledgeGridCollapse,
    mappedDataList: state.user.mappedData,
    buildProps: state.user.buildProps,
    IsTabChanged: state.projectview.IsTabChanged,
    languageList: state.user.languageList,
    englishProject: state.user.englishProject,
    cmmLoginStatus: state.auth.cmmLoginStatus,
    showProjectHeader:state.projectview.showProjectHeader,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    OnGetAllAssistantsData: (APIURL) =>
    dispatch(actions.GetAllAssistantsData(APIURL)),
    onGetUserById: (apiUrl, botBuilder_URL) =>
      dispatch(actions.GetUserbyID(apiUrl, botBuilder_URL)),
    onGetUserSkills: (apiUrl) => dispatch(actions.GetUserSkills(apiUrl)),
    OnGetSettings: (apiUrl) => dispatch(actions.GetSettings(apiUrl)),
    OnUpdateUser: (apiUrl, user) => dispatch(actions.UpdateUser(apiUrl, user)),
    OnCreateProject: (apiUrl, commonApiUrl, botUrl, data) =>
      dispatch(actions.CreateProject(apiUrl, commonApiUrl, botUrl, data)),
    OnlogOut: () => dispatch(actions.logout()),
    OnProjectfileupload: (apiUrl, formdata, dataUrl, projectName) =>
      dispatch(
        actions.Projectfileupload(apiUrl, formdata, dataUrl, projectName)
      ),
    OnGetProjectByLanguage: (apiUrl, data) =>
      dispatch(actions.GetProjectByLanguage(apiUrl, data)),
    OnGetBuildProps: (apiUrl) => dispatch(actions.GetBuildProps(apiUrl)),
    onchangeProjectAction: (projectname) =>
      dispatch(actions.changeProjectAction(projectname)),
    OnGetUserPermissions: (apiUrl, projectname) =>
      dispatch(actions.GetUserPermissions(apiUrl, projectname)),
    onchangeTabAction: (tab) => dispatch(actions.changeTabAction(tab)),
    OnGetLanguage: (apiUrl) => dispatch(actions.GetLanguage(apiUrl)),
    OngetProjectDescription: (apiUrl, data) =>
      dispatch(actions.getProjectDescription(apiUrl, data)),
    onGetCuxBotList: (apiUrl, projectName) =>
      dispatch(actions.GetCuxBotList(apiUrl, projectName)),
    onGetEnglishProjects: (botBuilder_URL) =>
      dispatch(actions.GetEnglishProjects(botBuilder_URL)),
    OnGetProjectLang: (apiUrl, projectName) =>
      dispatch(actions.GetProjectLang(apiUrl, projectName)),
      getCMMLoginStatus: (apiUrl) =>
      dispatch(actions.getCMMLoginStatus(apiUrl)),
      OnProjectHeaderShow:(showProjectHeader)=>dispatch(actions.projectHeaderShow(showProjectHeader)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withErrorHandler(Layout, axios)));
