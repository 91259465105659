// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabClass.nav-tabs{
    border-bottom: 1px solid #E1DEDE;
}
.tabClass.nav-tabs .nav-link {
    color: #B0AFAF;
    border:none;
    font-size: 16px;
    font-family: 'Roboto-Regular';
    font-variant: small-caps;
    text-transform: uppercase;
}
.tabClass.nav-tabs .nav-link.active {
    background-color: transparent !important;
    border-bottom: 4px solid#016BB5 !important;
    color: #016BB5 !important;
    border: 0px;
}
.tabClass .nav-link{
    padding:0.2rem 2rem
}`, "",{"version":3,"sources":["webpack://./src/components/UI/HorizontalTab/HorizontalTab.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;AACpC;AACA;IACI,cAAc;IACd,WAAW;IACX,eAAe;IACf,6BAA6B;IAC7B,wBAAwB;IACxB,yBAAyB;AAC7B;AACA;IACI,wCAAwC;IACxC,0CAA0C;IAC1C,yBAAyB;IACzB,WAAW;AACf;AACA;IACI;AACJ","sourcesContent":[".tabClass.nav-tabs{\n    border-bottom: 1px solid #E1DEDE;\n}\n.tabClass.nav-tabs .nav-link {\n    color: #B0AFAF;\n    border:none;\n    font-size: 16px;\n    font-family: 'Roboto-Regular';\n    font-variant: small-caps;\n    text-transform: uppercase;\n}\n.tabClass.nav-tabs .nav-link.active {\n    background-color: transparent !important;\n    border-bottom: 4px solid#016BB5 !important;\n    color: #016BB5 !important;\n    border: 0px;\n}\n.tabClass .nav-link{\n    padding:0.2rem 2rem\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
