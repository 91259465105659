import React, { useState, useEffect } from 'react';
import InputWithLabelComponent from '../../components/InputWithLabelComponent/InputWithLabelComponent';
import ColorPickerComponent from '../../components/ColorPicker/ColorPickerComponent';
import SelectComponent from '../../../../../components/UIComponents/SelectDropdown/SelectComponent';
import { Row, Form } from 'react-bootstrap';
import { Switch } from '@material-ui/core';
import { useSelector } from 'react-redux';
import BadgeCustom from "../../../../../components/UIComponents/Badge/BadgeComponent";

const UserInput = ({data, onChangeHandler}) => {

    const [langList, setLangList] = useState();

    const languageList = useSelector( state => state.user.languageList);

    useEffect(()=> {

        let language_array = languageList  && languageList.split(',');
        let templanglist = [];
        for(let langItem of language_array){
            let lngvalue=langItem.split('_')[0];
            templanglist.push({
                "value":langItem,
                "displayValue":lngvalue.charAt(0).toUpperCase() +lngvalue.slice(1)
            });
        }
        setLangList(templanglist);
    },[]);

    const isDuplicate = (lang) => {
        return data.languageList.filter(item => item.label.toLocaleLowerCase() === lang).length > 0 ? true: false;
    }

    const onUserInputChange = (type, value) => {
        if(type === 'languageList') {
            let list = [...data.languageList];
            let lang = value.split('_')[0];
            if(isDuplicate(lang)) {
                return;
            }
            let item = {code:value.split('_')[1], label:lang.charAt(0).toUpperCase() + lang.slice(1)}
            list.push(item);
            value = list;
        }
        onChangeHandler(type,value);
    }

    const deleteLang = (index) => {
        let list = [...data.languageList];
        list = list.filter((_, i)=>i !== index);
        onChangeHandler('languageList',list);
    }

    

    return (
    <div>
        <div className='row mt-2'>
            <div className='col-md-6 mb-3'>
                <InputWithLabelComponent
                    type="text"
                    placeHolder=""
                    name="navigation-menu-text"
                    label="User Input Prompt Message"
                    value={data.text}
                    readOnly={false}
                    onChange={(e) => onChangeHandler('text',e.target.value)}
                    required={false}
                    // maxLength={25}
                />
                {/* <span className='mt-1 characters-count'>
                    {25 - data.text.length} Characters {data.text.length !== 0 && <span>Left</span>}</span> */}
            </div>
            <div className='col-md-6'>
            </div>
            <div className='col-md-2'>
                <ColorPickerComponent
                    type="color"
                    name="response-background-color"
                    label="Input Background Color"
                    value={data.inputBgColor}
                    readOnly={false}
                    onChange={(e) => onChangeHandler("inputBgColor", e.target.value)}
                    required={false}
                />
            </div>
            <div className='col-md-2'>
                <ColorPickerComponent
                    type="color"
                    name="response-text-color"
                    label="Input Text Color"
                    labelContentColor={data.inputTextColor}
                    value={data.inputBgColor}
                    labelContent="AB"
                    readOnly={false}
                    onChange={(e) => onChangeHandler("inputTextColor", e.target.value)}
                    required={false}
                />
            </div>
            <div className='col-md-2'>
                <ColorPickerComponent
                    type="color"
                    name="response-border-color"
                    label="Input Border Color"
                    value={data.inputBorderColor}
                    readOnly={false}
                    onChange={(e) => onChangeHandler("inputBorderColor", e.target.value)}
                    required={false}
                />
            </div>
            <div className='col-md-6'>
            </div>
            <div className='col-md-2'>
                <ColorPickerComponent
                    type="color"
                    name="sendIcon-color"
                    label="Send Icon Disabled Color"
                    value={data.sendIconDisabledColor}
                    readOnly={false}
                    onChange={(e) => onChangeHandler("sendIconDisabledColor", e.target.value)}
                    required={false}
                />
            </div>
            <div className='col-md-2'>
                <ColorPickerComponent
                    type="color"
                    name="sendIcon-color"
                    label="Send Icon Enabled Color"
                    value={data.sendIconColor}
                    readOnly={false}
                    onChange={(e) => onChangeHandler("sendIconColor", e.target.value)}
                    required={false}
                />
            </div>
            {/* <div className='col-md-3'>
            <FontFamilyComponent
                type="color"
                name="navigation-menu-text"
                label="Family"
                // flowLink={rowData.flowLink}
                // projectLink={rowData.projectLink}
                labelContent="AB"
                // flowLinkChange={(e) => this.onFlowRouteLinkChange(i, e.target.value)}
                // projectLinkChange={(e) => this.onProjectRouteLinkChange(i, e)}
                // flowLinks={this.state.flowLinks}
                // projectLinks={fontFamilies}
                readOnly={false}
                required={true}
            />
            </div> */}
            {/* <div className='col-md-1'>
                <SelectComponent
                    name="home-size"
                    label="Size"
                    value={userInput.fontSize}
                    changed={(e) => onUserInputChange('fontSize',e.target.value)}
                    required={true}
                    options={lists.fontSizes}
                />
            </div> */}
        </div>
        
        <div className='row mt-2 ht-5'>
            <div className='col-md-1 al-ctr'>
                <Form.Label className="mb-1 text-box-title switch-label">Language</Form.Label>
            </div>
            <div className='col-md-1 al-ctr'>
                <Switch
                    className='switchMain'
                    checked={data.isLangVisible}
                    onChange={(e) => onUserInputChange('isLangVisible',e.target.checked)}
                    color="primary"
                    name="langChecked"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    disableRipple
                />
            </div>
        </div>
        {data.isLangVisible && (
            <Row className='ml-auto'>
                <div className='col-md-3'>
                    <Row>
                    <SelectComponent
                        name="selectLang"
                        label="Language"
                        changed={(e) => onUserInputChange('languageList',e.target.value)}
                        required={true}
                        value={'Select'}
                        options={langList}
                        className='mt-p45'
                    />
                    </Row>
                    <Row className='pt-2'>
                        {data.languageList.map((lang,index) => (
                            <BadgeCustom className='badgeWidth' key={lang.code} label={lang.label} clicked={e=>deleteLang(index)}></BadgeCustom>
                        ))}
                        
                    </Row>
                </div>
                <div className='col-md-2'>
                    <ColorPickerComponent
                        type="color"
                        name="lang-background-color"
                        label="Background Color"
                        value={data.langBgColor}
                        readOnly={false}
                        onChange={(e) => onChangeHandler("langBgColor", e.target.value)}
                        required={false}
                    />
                </div>
                <div className='col-md-2'>
                    <ColorPickerComponent
                        type="color"
                        name="lang-icon-color"
                        label="Icon Color"
                        labelContentColor={data.iconColor}
                        value={data.langBgColor}
                        labelContent="EN"
                        readOnly={false}
                        onChange={(e) => onChangeHandler("iconColor", e.target.value)}
                        required={false}
                    />
                </div>
            </Row>
            )}
        {/* <div className='row mt-2'>
            <div className='col-md-1 al-ctr'>
            <Form.Label className="mb-1 text-box-title switch-label">Speech</Form.Label>
            </div>
            <div className='col-md-1'>
            <Switch
                className='switchMain'
                checked={speechFlag}
                onChange={(e) => setSpeechFlag(e.target.checked)}
                color="primary"
                name="speechChecked"
                inputProps={{ 'aria-label': 'primary checkbox' }}
                disableRipple
            />
            </div>
        </div> */}
    </div>
    )
}

export default UserInput;