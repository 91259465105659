import React, { useState, useEffect } from 'react';
import IssueResultsCardComponent from "../IssueResultsCardComponent/IssueResultsCardComponent";

import { Row, Spinner, Col } from 'react-bootstrap';

import { useDispatch, useSelector } from 'react-redux';
import fetchRecommendedSolutionDataAction from '../../../../../../store/actions/agentInsights/fetchRecommendedSolutionData/fetchRecommendedSolutionDataAction';




import { uniqueId } from "lodash";
import recomendedSolutionsCheckboxAction from '../../../../../../store/actions/agentInsights/recomendedSolutionsCheckboxAction';



const RecommendedSolutionsTab = ({

    config,updateCounts,
}) => {
    const appliedCheckboxes = useSelector(
        (state) => state.agentInsights.appliedCheckboxes
      );
    const [showDetailsComponent, setShowDetailsComponent] = useState(false);
    const [selectedIssueId, setSelectedIssueId] = useState(null);
    const [searchText, setSearchText] = useState('');
    const onShowDetailsTab = (index,issueid) => {
        setShowDetailsComponent(true);
        setShowDrawer(true);
        dispatch(recomendedSolutionsCheckboxAction(`${issueid}`));
        setSelectedIssueId(index);
    };
    const [data, setData] = useState([]);
    const [showDrawer, setShowDrawer] = useState(false);
  
    const recommendedSolutionDataLoading = useSelector(state => state.agentInsights.recommendedSolutionDataLoading);
    const dispatch = useDispatch();
    const recommendedSolutionData = useSelector(state => state.agentInsights.recommendedSolutionData);

    useEffect(() => {
        dispatch(fetchRecommendedSolutionDataAction(config.INSIGHTS_API_URL));
    }, []);
   
    useEffect(() => {
        setData(recommendedSolutionData);
        updateCounts("recommendedSolutions", recommendedSolutionData)
    }, [recommendedSolutionData]);

    // const searchData = (event) => {
    //     setSearchText(event.target.value);
    //     const filteredRows = recommendedSolutionData.filter((row) => {
    //         if (event.target.value.trim() === '') {
    //             setData(recommendedSolutionData);
    //             updateCounts("recommendedSolutions", recommendedSolutionData)
    //         }
    //         return row.rootcause_title.toLowerCase().includes(event.target.value.toLowerCase());
    //     });
    //     setData(filteredRows);
    //     updateCounts("recommendedSolutions", filteredRows)
    // }

    return (
        <React.Fragment>
            {data.length>0?
            (<div>
            <Row className='mt-3'>
                <Col xs={{ span: 3, offset: 9 }}  className='d-flex'>
                    <div className="search-input">
                    {/* <SearchComponent
                                name={'recomended-solution'}
                                searchValue={searchText}
                                onSearch={searchData}
                                placeHolder={'Search'}
                            /> */}
                    </div>
                </Col>
            </Row>
            <Row className="d-flex pl-2 mt-4 justify-space-between">
                <Col xs='4'>
                    <p className="issue-descp-title issue-sub-title">RESOLUTION</p>
                </Col>
                <Col xs='5' className="pl-0">
                    <p className="issue-descp-title issue-sub-title">CONFIDENCE</p>
                </Col>
                
            </Row>
            <div className='insights-tab-scrool'>
                <React.Fragment>

                    {recommendedSolutionDataLoading ?
                        <Row className="justify-content-center align-items-center">
                            <Spinner animation="border" variant="primary" />
                        </Row>
                        :
                        <React.Fragment>
                            {data.length ?
                                data.map((item, index) => {
                                    return (
                                        <IssueResultsCardComponent
                                            active={selectedIssueId === index}
                                            issueid={item.rootcause_title}
                                            relevancy={item.relevancy}
                                            showDetailsTab={() => onShowDetailsTab(index, item.rootcause_title)}
                                            isRecommendedTab={true}
                                            config={config}
                                            key={uniqueId()}
                                            appliedCheckboxes={appliedCheckboxes}
                                        />
                                        //<AccordionComponent  config={config} isRecommendedTab={true} item={item} />

                                    )
                                }) : null
                            }
                            {/* {showDrawer && data.length ?
                                <IssueResultsDetails
                                    data={data}
                                    onDetailsCloseHandler={() => setShowDrawer(false)}
                                    selectedIssueId={selectedIssueId}
                                    toggleDrawer={toggleDrawer}
                                    item={get(data, `${selectedIssueId}`, '')}
                                    isRecommendedTab={true}
                                    config={config}
                                />
                                : null
                            } */}
                        </React.Fragment>
                    }
                </React.Fragment>
            </div>
            </div>)
          :
          <div className="row nodataAgentcockpit justify-content-center align-items-center">
                No Data Available 
          </div>
           }
        </React.Fragment>
    )
};

export default RecommendedSolutionsTab;