import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { Form, Button, Col, Spinner, Row } from 'react-bootstrap';
import './connections-tab.css';
import { botPostCalltoServer } from '../../../../store/utility';
import { SetErrorMessage, SetSuccessMessage } from '../../../../store/actions';

function ConnectionsTab({ data, showConfigLoader, selectedMenu, selectedSubMenu, config, handleDisableDataTabs }) {
    const dispatch = useDispatch();
    const [showVerifyLoader, setShowVerifyLoader] = useState(false);
    const [sourceConnection, setLocalSourceConnection] = useState([]);
    const [apiPayload, setPayload] = useState({});
    const connectionsData = useSelector(state => state.projectview.connectionsData);
    const selectedConnectionsData = connectionsData.find(item => item._id.includes(selectedSubMenu));
    const selectedSourceConnectionData = get(selectedConnectionsData, '_source.connection', []);
    const selectedLastVerifiedDate = get(selectedSourceConnectionData, 'lastVerifiedDate', '');
    const passVal='text';

  useEffect(() => {
        if (isEmpty(selectedSourceConnectionData)) {
            setLocalSourceConnection(get(data, 'sourceConnection', []));
            setPayload(data);
        } else {
            setLocalSourceConnection(get(selectedSourceConnectionData, 'sourceConnection', []));
            setPayload(selectedSourceConnectionData);
        }
    }, [data, connectionsData]);

    const handleTextBoxChange = (event) => {
        const updatedLocalConnections = sourceConnection.map(item => {
            if (item.type === event.target.name) {
                return {
                    ...item,
                    value: event.target.value
                };
            }
            return item;
        });
        setLocalSourceConnection(updatedLocalConnections);
    }

    const verifyConnectionDetails = () => {
        const connectorData = {
            ...apiPayload,
            lastVerifiedDate: moment().utc().format('MM/DD/YYYY hh:mm:ss A z'),
            sourceConnection
        };
       
     

        const publishConfigObj = {
            'dataSourceType': selectedMenu,
            'dataSourceName': selectedSubMenu,
            'tenantId': localStorage.getItem("orgName"),
            'projectName': localStorage.getItem("projectName"),
            'applicationUrl': config.CONNECTOR_URL,
            'connector': JSON.stringify(connectorData)
        };


        // const updateConfigObj = {
        //     'appUrl': config.CONNECTOR_URL,
        //     'type': selectedMenu,
        //     'source': selectedSubMenu,
        //     'target': 'elasticsearch',
        //     'projectName': localStorage.getItem("orgName"),
        //     'applicationUrl': config.API_URL,
        //     'requestType': 'updateConnectionDS',
        //     'connector': { "connection": updateConnectorData },
        // };
        let url = config.CONNECTOR_URL + `/publish-connector`;
        setShowVerifyLoader(true);
        botPostCalltoServer(url, publishConfigObj)
            .then(response => {
                if (get(response, 'data.status.responseType', '') === "success") {
                    console.log(get(response, 'data.data', ''));
                    handleDisableDataTabs(false);
                    if(response.data.data.toLowerCase().includes("invalid url") || response.data.data.toLowerCase().includes("authentication")){
                        dispatch(SetErrorMessage(response.data.data));
                    }else{
                        dispatch(SetSuccessMessage(get(response, 'data.data', '')));
                        dispatch(SetSuccessMessage(response.data.data));
                    }
                    
                    setShowVerifyLoader(false);
                    /*
                    PostCalltoServer(url, updateConfigObj)
                        .then(response => {
                            if (get(response, 'data.status.responseType', '') === "success") {
                                handleDisableDataTabs(false);
                                dispatch(SetSuccessMessage('Connections Published Successfully!!!'));
                                setShowVerifyLoader(false);
                            }
                            else {
                                dispatch(SetErrorMessage(get(response, 'data.errors.0.source', '')));
                                setShowVerifyLoader(false);
                            }
                        }).catch(err => {
                            dispatch(SetErrorMessage(get(err, 'response', '')));
                            setShowVerifyLoader(false);
                        });
                        */

                }
                else {
                    dispatch(SetErrorMessage(get(response, 'data.errors.0.source', '')));
                    setShowVerifyLoader(false);
                }
            }).catch(err => {
                dispatch(SetErrorMessage(get(err, 'response', '')));
                setShowVerifyLoader(false);
            });
    };
    const hiddenConnections = ['_TENANT_', '_SOURCE_TYPE_', '_DATASOURCE_'];
    return (
        <React.Fragment>
            <div className='integration-tabs-container'>
                {showConfigLoader ? 
                    <Row className="justify-content-md-center padding10">
                        <Spinner animation="border" variant="primary" />
                    </Row> :
                    <React.Fragment>
                        {/* <h6 className='connection-heading'>API Name: Account API</h6> */}
                        <div className='box-container'>
                            <div className='row'>
                                {sourceConnection.length ? sourceConnection.map(connection => {
                                    {
                                        return hiddenConnections.includes(connection.type) ?
                                            null :
                                            (
                                                <Col key={connection.displayName} md={connection.type === '_SOURCE_BASE_URL_' ? '12' : '6'}>
                                                    <div className={`inputContainer`}>
                                                        <Form.Label>
                                                            {connection.displayName}
                                                        </Form.Label>
                                                        <Form.Control
                                                            type={connection.type === '_PASSWORD_' ? 'password' : passVal}
                                                            name={connection.type}
                                                            value={connection.value}
                                                            onChange={handleTextBoxChange}
                                                        />
                                                    </div>
                                                </Col>
                                            )
                                    }
                                }) : null}
                            </div>
                            <div className='row marginTop10'>
                                    <div className='col-md-5'>
                                    {isEmpty(selectedLastVerifiedDate) ? null :
                                        <label>
                                            <i>{`Last verified on ${moment.utc(selectedLastVerifiedDate).local().format('DD/MM/YYYY hh:mm:ss A')}`}</i>
                                        </label>
                                    }
                                    </div>
                                <div className="col-md-5">
                                    <Button variant="primary" className='large-border-radius'
                                        onClick={verifyConnectionDetails}
                                        disabled={showVerifyLoader}
                                    >
                                        {showVerifyLoader ?
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            /> : null}
                            Publish
                         </Button>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                }
            </div>
        </React.Fragment>
    )
}

export default ConnectionsTab;