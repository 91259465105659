import React ,{useState,useEffect} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import classes from './OneTimeLogin.module.css';
import { Col,  Row} from 'react-bootstrap';
// import { useNavigate } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { PostCalltoServer,GetCalltoServer } from "../../store/utility";
import InputWithLabelComponent from "../../containers/SettingPage/SettingInnerPages/components/InputWithLabelComponent/InputWithLabelComponent";
import SecondaryButtonWithLoader from "../SettingPage/SettingInnerPages/components/SecondaryButtonWithLoader/SecondaryButtonWithLoader";
import PrimaryButtonWithLoader from "../SettingPage/SettingInnerPages/components/PrimaryButtonWithLoader/PrimaryButtonWithLoader";
import { useHistory } from "react-router-dom";
import { NavigateBefore } from "@material-ui/icons";
import { setrefresh } from "../../store/actions/projectview";
import LoginPage  from "../Auth/LoginPage";
import ProjectsList from "./ProjectsList";
import SpinnerPage from "../../components/UI/Loader/Loader"



const OnetimeLogin = ({config,info,props, setOnetime,setstate}) => { 
   console.log('info' , info, props);
   let history=useHistory()
   const {user, name , userRequest}=info;
  const [navigate , setNavigate]=useState(false)
  const [inputData, setInputData]=useState({
    deviceName:'',
    userName:'admin',
    password:'',
    license_server:"https://hclsoftware-uat.compliance.flexnetoperations.com",
  });

const [err, setErr] = useState(null);
const handleInputs = (e) => {
  setInputData({ ...inputData, [e.target.name]: e.target.value });
};   


const url=config.COMMON_API_URL+`/organization/license`;
const orgName=localStorage.getItem('orgName')
const { deviceName, userName, password, license_server }= inputData;

console.log("error" , err)


const handleSubmit =(e)=>{ 
  setNavigate(true)
  e.preventDefault(); 
  if( deviceName.trim()==='' || userName.trim()==='' || password.trim()==='' || license_server.trim()===''){
    setNavigate(false)
      setErr('Fill all feilds please')
      setTimeout(() => {
        setErr(null)
      }, 1000);
      return
  }


     let configObj = {
       orgname: orgName,
       license_server: license_server ,
       device_id: deviceName,
       user: userName,
       password: password,
       license_type: "FNO",
     };

    PostCalltoServer(url, configObj)
      .then((response) => {         
        props.onAuthSuccess(JSON.stringify(user), name, userRequest);
        props.onUpdateUserLoginStatus(props.config.COMMON_API_URL);      
        console.log("succesRes" , response);      
          setTimeout(() => {
            setNavigate(true);
            history.go("/projectview");
          }, 10);

      })
      .catch((err) => {
        setNavigate(false)
        console.log("error1" , err.response.data.message);
        setErr(err.response.data.message);
        setTimeout(() => {
          setErr(null);
        }, 2000);        
      }); 

  // setOnetime({license_type:"", days_left:''})
setInputData({
  deviceName: "",
  userName: "admin",
  license_server:"https://hclsoftware-uat.compliance.flexnetoperations.com",
  password: "",
});

}


  return (
    <div style={{zIndex:100000 , position:'fixed' , left:0, right:0, border:0, top:0  }}>
      {/* <Navbar isLogin={true}/> */}
      {navigate && <SpinnerPage/>}
      <div className={classes.loginbody}>
        <Row className={classes.loginrow}>
          <div className={classes.loginImage}>
            <img className={classes.loginLogo} src="./Icons/Login_logo.png" />
            <br />
          <div className={classes.loginformheading}>
                {"License Details!"}
              </div>
          </div>
          <Col md={5} className={classes.loginformcontent}>
            
          <form className={classes.loginform}>
            <div className="pt-2">
            <InputWithLabelComponent
                type="text"
                className=""
                name="license_server"
                label="FlexNet Server Url"
                value={license_server}
                readOnly={false}
                onChange={handleInputs}
                placeHolder={"Please Enter Device Name"}
                required={true}
               />
            </div>
            <div className="pt-2">
              <InputWithLabelComponent
                type="text"
                className=""
                name="deviceName"
                label="Device Name"
                value={deviceName}
                readOnly={false}
                onChange={handleInputs}
                placeHolder={"Please Enter Device Name"}
                required={true}
              />
              </div>
              <div className="pt-2">
              <InputWithLabelComponent
                type="text"
                className=""
                name="userName"
                label="Username"
                value={userName}
                readOnly={false}
                onChange={handleInputs}
                placeHolder={"Please Enter Username"}
                required={true}
               
              />
              </div>
              <div className="pt-2">
              <InputWithLabelComponent
                type="password"
                className=""
                name="password"
                label="Password"
                value={password}
                readOnly={false}
                onChange={handleInputs}
                placeHolder={"Please Enter Password"}
                required={true}
               
              />
              </div>
              <br />
              <div>
                {/* <SecondaryButtonWithLoader
                  className="mr-4"
                  size="lg"
                  text="cancel"
                  onClick={""}
                /> */}
                <PrimaryButtonWithLoader style={{width:'100%'}}
                  className=""
                  onClick={handleSubmit}
                  size="lg"
                  text="next"
                  loading={""}
                />
                <p style={{color:'red',height:15}}>{err} </p>
              </div>
             </form>
          </Col>
          <Col md={7} style={{backgroundColor:"white"}}>
          <img src="./Icons/onetime-login.png" alt="agen-cockpit"/>

          </Col>
        </Row>
      </div>
    </div>
  );
};

export default OnetimeLogin;
