import React, { useState,useEffect} from 'react';
import { Button} from 'react-bootstrap';
import './ManageFile.css'
import BadgeComponent from '../../components/UIComponents/Badge/BadgeComponent';
import DropzoneComponent from '../../components/UIComponents/DropzoneComponent/DropzoneComponent';
import SelectComponent from '../../components/UIComponents/SelectDropdown/SelectComponent';
import TextAreaWithLabelComponent from '../../containers/SettingPage/SettingInnerPages/components/TextAreaWithLabelComponent/TextAreaWithLabelComponent';
import InputWithLabelComponent from '../SettingPage/SettingInnerPages/components/InputWithLabelComponent/InputWithLabelComponent';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import get from 'lodash/get';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { GetCalltoServer } from '../../store/utility';
import { SetErrorMessage, SetSuccessMessage, } from '../../store/actions';
import {triggerStartDoc, triggerFailureDoc, triggerSuccessDoc} from '../../store/actions/projectview'
import { useDispatch } from 'react-redux';
import moment from 'moment';
import PrimaryButtonWithLoader from "../SettingPage/SettingInnerPages/components/PrimaryButtonWithLoader/PrimaryButtonWithLoader";
import SharePointPage from './SharePointPage';
import ButtonComponent from '../../components/UIComponents/Button/ButtonComponent';
import SpinnerPage from '../../components/UI/Loader/Loader';
import {decrypt}  from '../../utility/utility'
import { Modal, ProgressBar } from 'react-bootstrap';
import './ManageFileForms.css'
import { alignProperty } from '@mui/material/styles/cssUtils';
import axios from 'axios';
function ManageFileForms({
    onHide,
    config,
    
    handleClose
}) {
    const [badges, setBadges] = useState([]);
    const [badgesKeyword, setBadgesKeyword] = useState([]);
    const [sourceType, setSourceType] = useState('Local');
    const [tag, handleTag] = useState("");
    const [keyword, handleKeyword] = useState("");
    const [description, handleDescription] = useState("");
    const [invalidDescription, handleDescriptionValidation] = useState(true);
    const [isFileUploaded, setFileValidation] = useState(true);
    const [isTagAdded, setTagValidation] = useState(true);
    // const [isKeywordAdded, setKeywordValidation] = useState(true);
    const [isKeywordAdded, setKeywordValidation] = useState(true);
    const [selectedValue, setSelection] = useState("Other Document");
    const dispatch = useDispatch();
    const [fileUploadConfirmation, setFileUploadConfirmation] = useState(false);
	  const [fileExistingUploadConfirmation, setFileExistingUploadConfirmation] = useState(false);
    const [flag, setflag] = useState(false);
    const [ids, setIds] = useState('');
    const [fileExistingMsg, SetFileExistingMsg] = useState('');
    const [existingFiles, setExistingFiles] = useState('');
    const [nonSearchableData, setNonSearchableData] = useState([]);
    const [searchableFilesLoading, SetSearchableFilesLoading] = useState(false);
    const [showFiles, SetShowFiles] = useState(true);
    const [failure, Setfailure] = useState(false);
    const [option, SelectOption] = useState(null);
    const [selectedFiles, SetSelectedFiles] = useState([]);
    var [multimediaFiles, SetMultimediaFiles] = useState([]);
    var [otherFiles, SetOtherFiles] = useState([]);
    var [selectedTab, setSelecteTab] = useState("Multimedia Documents(0)")
    const [saveDataLoading, SetSaveDataLoading] = useState(false)
	  const [deletePopup, setDeletePopup] = useState(false);
    //------------------Simple Menu On Clicking Add icon-----------
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [invalidFile,setInvalidFile] = useState(false);
    const [language,setLanguage] = useState([localStorage.getItem('language')]);
    const[selectedLang,setSelectedLang]=useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [deleteFiles,setDeleteFiles]=useState({remove:""})
    // let deleteNonEnglishFiles;
    const deleteNonEngFiles=(f)=>{
      deleteFiles.remove=f;     
    }
  
    const handleAddTag=()=>{
            if (tag.trim()) {
                badges.push(tag)
                setBadges([...badges])
                setTagValidation(true)
                handleTag('')
        }
    }

    const handleAddKeyword=()=>{
        if (keyword.trim()) {
            badgesKeyword.push(keyword)
            setBadgesKeyword([...badgesKeyword])
            setKeywordValidation(true)
            handleKeyword('')
    }
}

    const handleSelection = (e) => {
        setSelection(e.target.value)
        close();
        setInvalidFile(false)
    }
	
	const handleCloseWindow = (str) => {
    if (str == "fileUploadAlreadyExit") {
      var oFiles = [];
      var mFiles = [];
      if (existingFiles.length > 0) {
        for (var i = 0; i < existingFiles.length; i++) {
          var fileType = existingFiles[i].split(/\.(?=[^\.]+$)/)[1];
          fileType = fileType.toLowerCase();
          if (
            fileType == "pdf" ||
            fileType == "docx" ||
            fileType == "doc" ||
            fileType == "txt" ||
            fileType == "xlsx" ||
            fileType == "xls" ||
            fileType == "csv" ||
            fileType == "html" ||
            fileType == "xls" ||
            fileType == "htm" ||
            fileType == "ppt" ||
            fileType == "pptx"
          ) {
            oFiles.push(existingFiles[i]);
          } else {
            mFiles.push(existingFiles[i]);
          }
        }
      }
      multimediaFiles = multimediaFiles.filter(function (el) {
        return !mFiles.includes(el.name);
      });
      otherFiles = otherFiles.filter(function (el) {
        return !oFiles.includes(el.name);
      });

      setFileExistingUploadConfirmation(false);
      onHide("otherDocument", true);
    }
    if (str == "deleteConfirmationCancel") {
      setDeletePopup(false);
    }
  };
	
    const badgeClick = (i) => {
        badges.splice(i, 1)
        setBadges([...badges])
    }

    const badgeKeywordClick = (i) => {
        badgesKeyword.splice(i, 1)
        setBadgesKeyword([...badgesKeyword])
    }


    const getLatestDataForNotSearchableFilesBK = () => {

        const configObj = {
                }
        SetSearchableFilesLoading(true);
        let url = config.CS_GOLANG + `/ctrainer/train/stats?project=${localStorage.getItem("projectname")}`;
        
        GetCalltoServer(url, configObj)
            .then(response => {

                if (get(response, 'data.status', '') === "success") {
                    const data = response.data.data;
                    data.sort((a, b) => {
                        let da = new Date(a.lastUpdateDate),
                            db = new Date(b.lastUpdateDate);
                        return db - da;
                    });
                    setNonSearchableData(data);
                    SetSearchableFilesLoading(false);
                }

                else {
                    setNonSearchableData([]);
                    SetSearchableFilesLoading(false);
                }
            }).catch(() => {
                SetSearchableFilesLoading(false);
                setNonSearchableData([]);
            });
       
    };

  

    const saveFiles = (boolean) => {
     
      // deleteNonEnglishFiles([]);
     deleteFiles.remove([])
      setUploadProgress(0)
      setIsLoading(true);
      setShowModal(true);    
      if (selectedValue == "Multimedia" && multimediaFiles.length == 0) {
        setTagValidation(false);
        setKeywordValidation(false);
      }
      
      let language = localStorage.getItem("language");
      if (!language) {
        language = "en";
      }
      var f = 0;
      if (multimediaFiles.length > 0) {
        if (!description && !badges.length) {
          setflag(true);
          f = 1;
        }
      }
     
      if (f == 0) {        
        if (boolean == true) setFileUploadConfirmation(false);
        if (boolean == true) setFileExistingUploadConfirmation(false);
        var rowData = [];
        var fname = "";
        rowData = [...multimediaFiles, ...otherFiles];
        let projectname = localStorage.getItem("projectname");
        if (!projectname) projectname = "common";
        let fileList = [];
        let fileArray = [];
        let formData = new FormData();
        rowData.forEach((item) => {
          let formFieldsObj = {};
          formFieldsObj["fileName"] = item.name;
          fname = item.name;
          // formFieldsObj["tags"] = badges.join();
          // formFieldsObj["keywords"] = badgesKeyword.join();
          formFieldsObj["tags"] = badgesKeyword.join();
          formFieldsObj["keywords"] = "";
          formFieldsObj["description"] = description;
          formFieldsObj["ids"] = ids;
          formFieldsObj["isValidate"] = boolean;
          formFieldsObj["language"] = language;
          formFieldsObj["lastUpdateDate"] = moment(new Date()).format(
            "DD-MM-YYYY hh:mm:ss A z"
          );
          fileList.push(item.name);
          formData.append("file", item);

          let formObj = {};
          formObj[item.name] = formFieldsObj;
          fileArray.push(formObj);
          return true;
        });
        formData.append("project", projectname);
        formData.append("tags", badgesKeyword.join());
        formData.append("keywords", keyword);
        formData.append("description", description);
        formData.append("language", selectedLang);

        let token = localStorage.getItem("accessToken");
        // let token = decrypt("newAccessToken")
        if (showFiles) {
          SetSaveDataLoading(true);
          // fetch(config.FileUpload_URL + `/cognitive/upload`, {
          let url = config.CS_GOLANG + `/ctrainer/train/upload`;
          if (boolean)
            url = config.CS_GOLANG + `/ctrainer/train/upload?overwrite=true`;
          // dispatch(triggerStartDoc());

          //  Progressbar
            
              // axios
              //   .post(url, formData, {
              //     headers: {
              //       Authorization: `Bearer ${token}`,
              //       orgName: localStorage.getItem("orgName"),
              //       userName: localStorage.getItem("userName"),
              //     },
              //     onUploadProgress: (progressEvent) => {
              //       const progress = Math.round(
              //         (progressEvent.loaded / progressEvent.total) * 100
              //       );

              //       setUploadProgress(progress);
              //     },
              //   })
              //   .then((res) => {
              //     console.log("axios", res);
              //   })
              //   .catch((error) => {}); 
            
             
        

          // End Progressbar

          fetch(url, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              orgName: localStorage.getItem("orgName"),
              userName: localStorage.getItem("userName"),
            },
            body: formData,
          })
            .then((response) => {
              return response.text();
            })
            .then((datas) => {
              setIsLoading(false);
              setShowModal(false);

              let result = JSON.parse(datas);           

              if (
                result.status == "success" &&
                result.message.indexOf("File Already Exist") == -1
              ) {
                SetSaveDataLoading(false);
                onHide("otherDocument", true);

                setTimeout(() => {
                  dispatch(triggerSuccessDoc());
                }, 1500);

                setTimeout(() => {
                  dispatch(
                    SetSuccessMessage("Document(s) uploaded successfully.")
                  );
                }, 2000);
              } else if (
                result.status == "success" &&
                result.message.indexOf("File Already Exist") != -1
              ) {
                // SetFileExistingMsg(JSON.stringify(result.data)+ "File's already exists!");

                SetFileExistingMsg(
                  "File(s) already exists! Do you want to update?"
                );
                setFileUploadConfirmation(true);
                setFileExistingUploadConfirmation(true);
                SetSaveDataLoading(false);
                setTimeout(() => {
                  dispatch(triggerSuccessDoc());
                }, 2000);
              } else {
                SetSaveDataLoading(false);
                dispatch(triggerFailureDoc());
              }
              //     else {
              //         dispatch(SetSuccessMessage(results.data));
              //         SetSaveDataLoading(false)
              //         SetShowFiles(true)
              //         SelectOption(null)
              //         setAnchorEl(null)
              //         SetSelectedFiles([])
              //         SetMultimediaFiles([])
              //         SetOtherFiles([])
              // 		handleDescription('')
              // 		handleTag('')
              // 		setBadges([])
              //         setIds(null);
              //         setFileUploadConfirmation(false);
              // 		setFileExistingUploadConfirmation(false)
              //         setflag(false)
              //         setTimeout(() => {
              //             getLatestDataForNotSearchableFiles();
              //         }, 3000);
              // 		onHide("otherDocument",true)
              //     }
              // } else if (result.status.responseType == "failure") {
              //     dispatch(SetErrorMessage(result.errors));
              //     SetSaveDataLoading(false)
              // }
              // else {
              //     dispatch(SetErrorMessage(results.data));
              //     SetSaveDataLoading(false)
              // }
              //onHide("otherDocument",true)
            })
            .catch((error) => {
              // dispatch(SetErrorMessage(get(error, 'response', '')));
              setIsLoading(false);
              setShowModal(false);
              if (error.message.includes("Failed to fetch")) {
                dispatch(
                  SetErrorMessage("Unable to Fetch some of the chosen file(s)")
                );

                //   false;
                onHide("otherDocument", true);
              } else {
                dispatch(SetErrorMessage(get(error, "response", "")));
              }
              SetSaveDataLoading(false);
            });
        } else {
          handleDescriptionValidation(false);
          setFileValidation(false);
        }
      }
    };

    const ImportFAQIntents = (files) => {
       
        if (files.length == 0) {
            SetSelectedFiles([])
            SetMultimediaFiles([])
            SetOtherFiles([])
        }
        var ofile = []
        var mfile = []
        if (files.length > 0) {
            for (var i = 0; i < files.length; i++) {
                var fileType = files[i].name.split(/\.(?=[^\.]+$)/)[1]
                fileType = fileType.toLowerCase()
                if (fileType == "pdf" || fileType == "docx" || fileType == "doc" || fileType == "txt" || fileType == "xlsx" || fileType == "xls" || fileType == "csv" || fileType == "html" || fileType == "xls" || fileType == "htm" || fileType == "ppt" || fileType == "pptx" ) {
                    if (selectedValue == "Other Document") {
                        setFileValidation(true);
                        SetSelectedFiles(files)
                        ofile.push(files[i])
                        setSelecteTab("Other Documents(" + otherFiles.length + ")")
                    } else {
                        setFileValidation(false);
                    }

                }
                else {
                    if (fileType != "pdf" || fileType != "docx" || fileType != "doc" || fileType != "txt" || fileType != "xlsx" || fileType != "xls" || fileType != "csv" || fileType != "html" || fileType != "xls" || fileType != "htm" || fileType != "ppt" || fileType != "pptx" ) {

                    if (selectedValue == "Multimedia") {
                        setFileValidation(true);
                        SetSelectedFiles(files)
                        mfile.push(files[i])
                        setSelecteTab("Multimedia Documents(" + multimediaFiles.length + ")")
                    } else {
                        setFileValidation(false);
                    }
                }

                }
            }
        }
        SetOtherFiles(ofile)
        SetMultimediaFiles(mfile)
        if (multimediaFiles.length == 0) {
            setSelecteTab("Other Documents(" + otherFiles.length + ")")
        }
        if (otherFiles.length == 0) {
            setSelecteTab("Multimedia Documents(" + multimediaFiles.length + ")")
        }
    }
    const onchangeFile = (files) => {
        SetShowFiles(true)
        Setfailure(false)
        ImportFAQIntents(files)
    }
    
    const onChangeSoruce = (e) => {
        setSourceType(e.target.value)
    }
    

   
    const close = () => {
        if (sourceType == 'Sharepoint')
          // onHide("otherDocument", true)
        SetShowFiles(false)
        SelectOption(null)
        setAnchorEl(null)
        SetSelectedFiles([])
        SetMultimediaFiles([])
        SetOtherFiles([])
        setBadges([])
        handleTag('');
        handleKeyword('');
        handleDescription('')
        setflag(false)
    }

    const undoInvalid=(boolean)=>{
        setInvalidFile(boolean)
    }
    useEffect(() => {  
       language == 'af' ? setSelectedLang('Afrikaans') : 
        language == 'ar' ? setSelectedLang('Arabic'):
        language == 'de' ? setSelectedLang('German'):
        language == 'el' ? setSelectedLang('Greek'):
        language == 'es' ? setSelectedLang('Spanish'):
        language == 'fr' ? setSelectedLang('French'):
        language == 'it' ? setSelectedLang('Italian'):
        language == 'hu' ? setSelectedLang('Hungarian'):
        language == 'zh' ? setSelectedLang('Chinese'):
        language == 'ru' ? setSelectedLang('Russian'):
        language == 'el' ? setSelectedLang('Greek'):
        language == 'ja' ? setSelectedLang('Japanese'):
        language == 'pl' ? setSelectedLang('Polish'):
        language == 'hi' ? setSelectedLang('Hindi'):setSelectedLang('English')
}, [language]);
const handleCloseModal = () => {
  setShowModal(false);	
};

    return (
      <React.Fragment>
        {/* {saveDataLoading == true ? <SpinnerPage /> : null} */}
        {
          
        //   showModal && (
        //   <Modal
        //     show={showModal}
        //     // onHide={handleCloseModal}
        //     style={{ zIndex: 10000 }}
        //   >
        //     <Modal.Header closeButton>
        //       <Modal.Title style={{ paddingLeft: "50px" }}>
        //         {" "}
        //         Uploading your files
        //       </Modal.Title>
        //     </Modal.Header>
        //     <Modal.Body>
        //       {isLoading && (
        //         <ProgressBar
        //           now={uploadProgress}
        //           label={`${uploadProgress}%`}
        //         />
        //       )}
        //     </Modal.Body>
        //     <Modal.Footer>
        //       <Button
        //         variant="primary"
        //         onClick={() => {
        //           handleCloseModal();
        //         }}
        //       >
        //         RUN IN BACKGROUND
        //       </Button>
        //     </Modal.Footer>
        //   </Modal>
        // )
        
        }

        <SelectComponent
          name="documentType"
          label="Document Type"
          changed={(e) => handleSelection(e)}
          required={true}
          value={selectedValue}
          options={[
            { value: "Other Document", displayValue: "Others" },
            { value: "Multimedia", displayValue: "Multimedia" },
          ]}
        />
        <div className="mt-2 radio-header mb-0">
          <label className="mb-0">
            Select the source from where you want to select files
          </label>
        </div>
        <div
          class="form-check form-check-inline radio-text"
          onChange={onChangeSoruce}
        >
          <div className="mr-2">
            <input
              class="form-check-input"
              type="radio"
              name="local"
              id="local"
              value={"Local"}
              checked={sourceType == "Local"}
            />
            <div class="form-check-label d-inline" for="flexRadioDefault1">
              Local
            </div>
          </div>
          <div>
            <input
              class="form-check-input"
              type="radio"
              name="sharepoint"
              id="sharepoint"
              value={"Sharepoint"}
              checked={sourceType == "Sharepoint"}
            />
            <div class="form-check-label d-inline" for="flexRadioDefault2">
              SharePoint
            </div>
          </div>
        </div>
        {sourceType == "Local" && (
          <div>
            <DropzoneComponent
              ImportFAQIntents={ImportFAQIntents}
              deleteNonEngFiles={deleteNonEngFiles}
              showFiles={showFiles}
              failure={failure}
              config={config}
              onchangeFile={onchangeFile}
              showButton={false}
              fileType={selectedValue}
              length={selectedFiles.length}
              isInvalid={!isFileUploaded}
              invalidFile={invalidFile}
              onUndoInvalid={(boolean) => undoInvalid(boolean)}
            />
            <div>
              <img src="./images/info_icon.png" />
            </div>
            <div className="dropzoneInfo2">
              <span>
                Multimedia document type supports audio, video and image file
                types.
              </span>
              <br />
              <span>
                {" "}
                Other document type supports excel, csv, pdf, ppt, doc, txt and
                html file types.
              </span>
              <br />
              <br />
            </div>
            {selectedValue != "Multimedia" && (
              <div>
                <div className="tags-input-div">
                  <div style={{ width: "100%" }}>
                    <InputWithLabelComponent
                      placeHolder=""
                      className={"drawer-input"}
                      type="text"
                      name="keyword"
                      label={"Keywords"}
                      value={keyword}
                      onChange={(e) => handleKeyword(e.target.value)}
                      isInvalid={!isKeywordAdded}
                      error={"Please add keyword."}
                      maxLength={"50"}
                    />
                  </div>
                  <ButtonComponent
                    variant="contained"
                    className="buttonWidth addTagBtn"
                    label={"ADD"}
                    clicked={() => handleAddKeyword()}
                    btnType="blueButton"
                    //style={{marginTop:"3px"}}
                  />
                </div>

                <div className="my-3 w-400">
                  {badgesKeyword.map((badgesKeyword, i) => (
                    <BadgeComponent
                      key={i}
                      label={badgesKeyword}
                      clicked={() => badgeKeywordClick(i)}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
        {sourceType == "Sharepoint" && (
          <SharePointPage
            config={config}
            isFaq={false}
            handleClose={handleClose}
          />
        )}
        {selectedValue == "Multimedia" && sourceType !== "Sharepoint" && (
          <>
            <div className="my-2">
              <TextAreaWithLabelComponent
                label="Description*"
                name="description"
                required={false}
                value={description}
                className="text-area"
                placeholder=""
                onChange={(e) => {
                  handleDescription(e.target.value);
                  handleDescriptionValidation(e.target.value);
                }}
              />
              {!description && !invalidDescription && (
                <div className="default-css error-css desc-err">
                  Please add description.
                </div>
              )}
            </div>

            <div className="tags-input-div">
              <div style={{ width: "100%" }}>
                <InputWithLabelComponent
                  placeHolder=""
                  className={"drawer-input"}
                  type="text"
                  name="keyword"
                  label={"Keywords*"}
                  value={keyword}
                  onChange={(e) => handleKeyword(e.target.value)}
                  isInvalid={!isKeywordAdded}
                  error={"Please add keyword."}
                  maxLength={"50"}
                />
              </div>
              <ButtonComponent
                variant="contained"
                className="buttonWidth addTagBtn"
                label={"ADD"}
                clicked={() => handleAddKeyword()}
                btnType="blueButton"
                disabled={badgesKeyword.includes(keyword)}
              />
            </div>

            <div className="my-3 w-400">
              {badgesKeyword.map((badgesKeyword, i) => (
                <BadgeComponent
                  key={i}
                  label={badgesKeyword}
                  clicked={() => badgeKeywordClick(i)}
                />
              ))}
            </div>

            {/* <div className='tags-input-div'>
                    <div style={{width:"92%"}}>
                        <InputWithLabelComponent
                            placeHolder=''
                            className={'drawer-input'}
                            type="text"
                            name="tags"
                            label={"Tags*"}
                            value={tag}
                            onChange={(e) => handleTag(e.target.value)}
                            isInvalid={!isTagAdded}
                            error={"Please add tag."}
                        />
                    </div>
                    <ButtonComponent
                        variant="contained"
                        className="buttonWidth add-tag-btn"
                        label={"ADD"}
                        clicked={() => handleAddTag()}
                        btnType="blueButton"
                    />            
                    </div> */}

            {/* <div className='my-3 w-400'>
                    {badges.map((badge, i) => <BadgeComponent key={i} label={badge} clicked={() => badgeClick(i)} />)}
                </div> */}
          </>
        )}
        {sourceType !== "Sharepoint" && (
          <div className="mb-2">
            <PrimaryButtonWithLoader
              className="mb-2"
              size="md"
              onClick={() => saveFiles(false)}
              text="Save"
              loading={saveDataLoading}
              disabled={
                selectedValue == "Multimedia"
                  ? multimediaFiles.length == 0 ||
                    badgesKeyword.length == 0 ||
                    !description ||
                    description.startsWith(" ")
                  : otherFiles.length == 0
              }
            />
          </div>
        )}

        <Dialog
          open={fileExistingUploadConfirmation}
          aria-labelledby="form-dialog-title"
          style={{ paddingRight: "300px" }}
        >
          <form>
            <DialogContent>{fileExistingMsg}</DialogContent>
            <DialogContentText></DialogContentText>
            <DialogActions>
              <Button
                onClick={() => {
                  setUploadProgress(0)
                  saveFiles(true);
                }}
                color="primary"
              >
                OK
              </Button>
              <Button
                onClick={() => handleCloseWindow("fileUploadAlreadyExit")}
                color="primary"
              >
                Cancel
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </React.Fragment>
    );
}

ManageFileForms.propTypes = {};

ManageFileForms.defaultProps = {};

export default ManageFileForms;
