import React from "react";
import OverlayLoader from 'react-loading-overlay';
import './Loader.css';
const SpinnerPage = () => {
  return (
    <>
     
      <OverlayLoader 
               active={false} 
               styles={{
                 overlay: (base) => ({
                   ...base,
                   background: "black",
                   color:"black",
                   opacity:".4",
                   text:'.'
                 }),
               }}   
              >
           <div id="background"></div>
      <div id="logocontainer">
        <div id="pelogo"><img src={localStorage.getItem('cmmStatus')=='true'?"./Icons/cmmLogo.png":"./Icons/loader.png"}/>hellooooo</div>
        <div className="loader" data-testid={'loader1'} style={{ left: "1.5vh", top: 0, height: "1.5vh", width: 0, animation: "slide1 1s linear forwards infinite" }}></div>
        <div className="loader" style={{ right: 0, top: "1.5vh", width: "1.5vh", height: 0, animation: "slide2 1s linear forwards infinite", animationDelay: "0.5s" }}></div>
        <div className="loader" style={{ "right": "1.5vh", bottom: 0, height: "1.5vh", width: 0, animation: "slide3 1s linear forwards infinite" }}></div>
        <div className="loader" style={{
          left: 0,
          bottom: "1.5vh", width: "1.5vh", height: 0, animation: "slide4 1s linear forwards infinite", animationDelay: "0.5s"
        }}></div>
      </div>
 </OverlayLoader>
    </>
  );
}

export default SpinnerPage;