import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import DrawerComponent from '../../UIComponents/Drawer/DrawerComponent';
import TextAreaWithLabelComponent from "../../../containers/SettingPage/SettingInnerPages/components/TextAreaWithLabelComponent/TextAreaWithLabelComponent";
import InputWithLabelComponent from "../../../containers/SettingPage/SettingInnerPages/components/InputWithLabelComponent/InputWithLabelComponent";
import ButtonCustom from "../../UIComponents/Button/ButtonComponent";
import { GenerateAIPhrase } from "../../../store/actions";
import { useDispatch } from "react-redux";

import './Drawer.css';

const AIGenDrawer = ({show, onHide, generatePhrase}) => {

    const [aiQuery, setAiQuery] = useState();
    const [count, setCount] = useState(30);

    const dispatch = useDispatch();

    const handleSubmit = () => {
        setAiQuery();
        setCount(30);
        onHide();
        dispatch(GenerateAIPhrase());
        generatePhrase();
    }

    return (
        <>
        <DrawerComponent heading="AI Generate" 
        showDrawer={onHide}
        openDrawer={show}>
            <TextAreaWithLabelComponent label="Description"
                name="AI-Description"
                required ={false}
                value={aiQuery}
                className='aiQueryArea'
                placeholder='Detailed description to generate AI more phrases'
                onChange={(e) => setAiQuery(e.target.value)}
            />
            <Row className='paddingT2'>
                <Col className='flexStyle'>
                <span className='text-box-title labelMargins'>Number of Suggestions</span>
                <InputWithLabelComponent
                        type="text"
                        name='phraseCount'
                        showLabel={false}
                        value={count}
                        readOnly={false}
                        onChange={e => setCount(e.target.value)}
                        required={true}
                        className="countField"
                    />
                    </Col>
            </Row>

            <div className="mt-5">
                <ButtonCustom variant='contained' label='Generate'className='buttonWidth' disabled={!aiQuery} clicked={handleSubmit}/>
            </div>
           

        </DrawerComponent>
        </>
    )
}

export default AIGenDrawer;