import React, {  useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Heading3 from "../components/Typography/Heading3/Heading3";
import "./UnsupervisedLearning.css"
import { Card } from "react-bootstrap";
import PrimaryButtonWithLoader from "../components/PrimaryButtonWithLoader/PrimaryButtonWithLoader";
import ButtonComponent from "../../../../components/UIComponents/Button/ButtonComponent";
import DrawerComponent from "../../../../components/UIComponents/Drawer/DrawerComponent";
import ClusterDrawer from "./ClusterDrawer";
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import *as actions from '../../../../store/actions/index';
class UnsupervisedLearning extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      // showDrawer:false,
      openDrawer:false,
      backButton:false
    }
  }
  componentDidMount() {

    this.props.onGetSourceAttributes('http://10.14.108.21:32470')

}
  showDrawerr = () => {
    this.setState({
      // showDrawer: true,
      openDrawer:true,
    })
  }
  onHide = () => {
    this.setState({openDrawer:false})
  }

  toggleDrawer = (open) => (event) => {
    if (!open) {
      this.onHide();
    }
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      this.onHide();
    }
  };
  handleBack = () =>{
// this.props.history.push('/settings/useCase')
console.log('s')
this.setState({backButton:true})
this.props.handlecallback(true)
// this.history.goBack()
  }
  
    render(){
    return(
      <Row>
      <Col xs="12">
    <Card className="mb-1 unsuper-learn-card">
      <Card.Body onClick={this.showDrawerr}>
        <div className="d-flex align-items-center">
        <div className="col-md-3 source-container">
            <div className="source-content">
              <h5>Use Case</h5>
            </div>
            <div className="source-content-details">
              Churn Prediction
            </div>
          </div>
          <div className="col-md-3 source-container">
            <div className="source-content">
              <h5>Source</h5>
            </div>
            <div className="source-content-details">ServiceNow</div>
          </div>
          <div className="col-md-3 source-container">
            <div className="source-content">
              <h5>Attributes</h5>
            </div>
            <div className="source-content-details">
              --
            </div>
          </div>
          <div className="col-md-2 source-container">
            <div className="source-content">
              <h5>No. of cluster</h5>
            </div>
            <div className="source-content-details">
              --
            </div>
          </div>
          <span className="col-md-1 image justify-content-end">
            <img src="./images/Vector.svg" />
          </span>
        </div>
      </Card.Body>
      <DrawerComponent
          heading="Cluster Input"
          showDrawer={this.toggleDrawer(false)}
          openDrawer={this.state.openDrawer}
        >
          {this.state.openDrawer === true ?
            <div>
            <ClusterDrawer toggleDrawer={this.toggleDrawer()}/>
            </div> : null}
        </DrawerComponent>
    </Card>
    {/* <div className="d-flex mt-6 justify-content-end button-content mt-5 btnalign">
    <div><ButtonComponent
        variant="outlined"
        label="back"
        size="lg"
        className="mr-4 back"
        clicked={() => {this.handleBack() }}
    />
    </div>
    <div>
    <PrimaryButtonWithLoader
     className="mr-3 save-proceed-btn"
    size="lg"
    text="SAVE & PROCEED"
    /></div>
    </div> */}
      </Col>
    </Row>
    );
}
}
const mapStateToProps = state => {
  return {
      SourceAttributesData: state.UnsupervisedML.SourceAttributes,
  };
};

const mapDispatchToProps = dispatch => {
  return {
      onGetSourceAttributes : (apiUrl) => dispatch(actions.GetSourceAttributes (apiUrl)),
  };

};
export default connect(mapStateToProps, mapDispatchToProps)(UnsupervisedLearning);