import React, { useEffect, useState } from "react";
import isNil from "lodash/isNil";
import get from "lodash/get";
import { isEqual } from "lodash";
import {
  Row,
  Col,
  Container,

  Button,

  
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import "./AgentCockpit.css";
import { compact } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import fetchProductTriageDataAction from "../../../../store/actions/agentInsights/fetchProductTriageData/fetchProductTriageDataAction";
import fetchProductTriageInsightsAction from "../../../../store/actions/agentInsights/fetchProductTriageInsights/fetchProductTriageInsightsAction";
import filterDataArrChangeHandlerAction from "../../../../store/actions/agentInsights/filterDataArrChangeHandlerAction";
import TextAreaWithLabelComponent from "../components/TextAreaWithLabelComponent/TextAreaWithLabelComponent";
import FilterDetails from "../Insights/components/FilterDetails/FilterDetails";
import InsightsResults from "../Insights/components/InsightsResults/InsightsResults";
import SecondaryButtonWithLoader from "../components/SecondaryButtonWithLoader/SecondaryButtonWithLoader";
import PrimaryButtonWithLoader from "../components/PrimaryButtonWithLoader/PrimaryButtonWithLoader";
import yesQuestionsUpdateHandler from "../../../../store/actions/agentInsights/yesQuestionsUpdateHandler";
import customerResponseHandler from "../../../../store/actions/agentInsights/customerResponseHandler";
import resetInsightsHomeDataAction from "../../../../store/actions/agentInsights/resetInsightsHomeDataAction";
import getProjectDetails from "../helpers/getProjectDetails";
import CompleteTriage from "./CompleteTriage";
import permissionsJson from "../../../../permissions_Json"

import {
  PostCalltoServer,
  GetCalltoServer,
} from "../../../../store/utility";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import getSymptomsFiltersChangeHandlerAction from "../../../../store/actions/agentInsights/getSymptomsFiltersChangeHandlerAction";
import questionsUpdateHandler from "../../../../store/actions/agentInsights/questionsUpdateHandler";
import ProductAttributesComponent from "../Insight/AdminConfiguration/ProductAttributesComponent/ProductAttributesComponent";
import NavbarCustom from '../../../../components/UI/Navbar/Navbar';
import * as actions from '../../../../store/actions/index'

import { connect } from 'react-redux';
import recomendedSolutionsCheckboxAction from "../../../../store/actions/agentInsights/recomendedSolutionsCheckboxAction";
import { v4 as uuid } from 'uuid'; 
import { NavigateBefore } from "@material-ui/icons";
import { encryptAndStore } from "../../../../utility/utility";

const AgentCockpit = ({ config, projectList, onchangeProjectAction }) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const data = useSelector((state) => state.agentInsights.filterData);

  const getSymptomsFilterData = useSelector(
    (state) => state.agentInsights.getSymptomsFilterData
  );
  
  const appliedCheckboxes = useSelector(
    (state) => state.agentInsights.appliedCheckboxes
  );

  const agentData = useSelector(
    (state) => state.agentInsights.agentInsightsData
  );

  const permissions = useSelector(state => {
    return state.user.permissionList;
  });
  const projectName = getProjectDetails();
  const [show, setShow] = useState(false);
  const [submiBtn, setSubmitBtn] = useState(false);
  // const symdata = useSelector((state) => state.agentInsights.agentInsightsData);
  //const questions = get(symdata, "symptoms", []);
  const [questions, setQuestions] = useState([]);
  const [counter, setCounter] = useState(0);
  const selectedFilterData = useSelector(
    (state) => state.agentInsights.selectedFilterDataArr
  );
  
  const unique_id = uuid();
  const smallunique = unique_id.slice(0,4)
  const [uniqueIDnums, setUniqueIDnums] =useState(smallunique);
  const [gettingDataUrl,setGettingDataUrl] = useState("");

useEffect(() => {
  const url =
    config.INSIGHTS_API_URL +
    `/product-intelligent-triage/carear/get-carear-url/${uniqueIDnums} `;
  GetCalltoServer(url)
    .then((response) => {
      if (get(response, "data.status.responseType", "") === "success") {
        setGettingDataUrl(response.data.data);
      }
    })
    .catch((err) => {
      console.log(err);
    });
}, []);
  const [selectedDataArr, setSelectedDataArr] = useState({ description: '' });

  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [showDrawer, setShowDrawer] = useState(false);
  const topresolutions = useSelector(state => state.insights.topresolutions.enabled);

  const [searchT, SetSearchT] = useState("");
  const [projectname, SetProjectname] = useState("Project List");
  const [popperShow, setPopperShow] = useState(false);
  const [timeStart, setTimeStart] = useState("");
  const [timeEnd, setTimeEnd] = useState("");


  let  AgentCockpit_Read_Write = false;
  let AgentCockpit_RW_Status = permissions.find(data => {
    if (data.permission_code.includes(permissionsJson.permissionCode.PRODUCT_TRIAGE_STANDARD_RW) && data.sku_code.includes(permissionsJson.skuCode.PT))
      return true
  })
  AgentCockpit_Read_Write = AgentCockpit_RW_Status ? true : false;

  const toggleDrawer = (value) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setShowDrawer(value);
  };
  const updateSelectedQuestionIndex = (type) => {
    setCounter((count) => count + 1);
    setSelectedQuestionIndex((prevState) => prevState + 1);
    setSelectedQuestions((prevState) =>
      prevState.concat({
        description: get(
          get(questions, `${selectedQuestionIndex}`, 0),
          "leadingquestion",
          ""
        ),
        type,
      })
    );
    dispatch(
      yesQuestionsUpdateHandler({
        type,
        selectedQuestion: get(
          get(questions, `${selectedQuestionIndex}`, 0),
          "symptoms",
          ""
        ),
      })
    );
    dispatch(
      customerResponseHandler({
        url: config.INSIGHTS_API_URL,
        isFirstTime: true,
        questions: questions
      })
    );
  };
  const resetSAndNavigateToInsights = () => {
    dispatch(resetInsightsHomeDataAction());
    dispatch(recomendedSolutionsCheckboxAction([]));
    setShow(false);
    setSubmitBtn(true);
    setSelectedQuestions([]);
    setSelectedQuestionIndex(0);
    setCounter(0);
    history.push("/insights");
  };
  const productTriageDataLoading = useSelector(
    (state) => state.agentInsights.productTriageDataLoading
  );

  useEffect(() => { }, [questions]);

  const getQuestions = () => {
    const url =
      config.INSIGHTS_API_URL +
      `/product-intelligent-triage/settings/getSymptom/project/${projectName}/issue/${get(
        selectedFilterData,
        "description",
        ""
      )}`;
    let filterDataObj = selectedDataArr;
    Object.keys(filterDataObj).forEach(function (k) {
      if (k != 'description' && filterDataObj[k].length == 0) delete filterDataObj[k]
      if (filterDataObj[k] == "") delete filterDataObj[k]
      if (k == "description") delete filterDataObj[k]
    });
    PostCalltoServer(url, filterDataObj)
      .then((response) => {
        if (get(response, "data.status.responseType", "") === "success") {
          const data = (get(response, "data.data", []));
          if (data == null || data == "[]") setQuestions([]);
          else setQuestions(data);
          dispatch(questionsUpdateHandler({ questions: data }));
          dispatch(
            fetchProductTriageInsightsAction({
              url: config.INSIGHTS_API_URL,
              isFirstTime: true,
              questions: data,
              config,
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const submitInsightsHandler = () => {
    const today =  new Date();
    const timeStartVal = today.toISOString();
    if (!isEqual(get(
      selectedFilterData,
      "description",
      ""
    ), "")) {
      setSelectedQuestions([]);
      dispatch(questionsUpdateHandler({ questions: [] }));
      setSelectedQuestionIndex(0);
      setCounter(0);
      getQuestions();
      setShow(true);
      setSubmitBtn(true);
      setTimeStart(timeStartVal)
    } else {
      toast("Enter Issue Details", {
        type: "warning",
        pauseOnHover: false,
      });
    }
  };


  const ProjectView = useSelector((state) => state.projectview.IsProjectChange);
  let NewProjectName = ProjectView ? ProjectView : projectName;
  useEffect(() => {
    dispatch(fetchProductTriageDataAction(config, NewProjectName));
    resetSAndNavigateToInsights();
    if (data.length && productTriageDataLoading) {
      data.forEach(function (val) {
        dispatch(
          filterDataArrChangeHandlerAction({
            [val.attribute_name]: [],
          })
        );
      });
    }
    dispatch(
      filterDataArrChangeHandlerAction({
        ["description"]: "",
      })
    );
  }, [ProjectView]);

  const getOptions = (insightsData) => {
    if (isNil(insightsData)) {
      return [];
    }
    if (isEqual(insightsData.attribute_data, null)) {
      return [];
    }
    const attrData = get(insightsData, "attribute_data", []);
    return attrData.map((item) => {
      return {
        label: item,
        value: item,
      };
    });
  };
    const updateFilterData = (event, type,item) => {
      setShow(false);
      dispatch(recomendedSolutionsCheckboxAction([]));
      if(event.length==0){
        setSelectedDataArr((selectedDataArr) => ({
          ...selectedDataArr,
          [type]: [],
        }));
      }
       if(type!='description'){
        let filterArr = []
         event.forEach(function(i){
           if(Object.keys(selectedDataArr).length==0){
             setSelectedDataArr((prevState) => ({
              ...prevState,
              [type]: [ i.value]
            }));
           }else if(!(type in selectedDataArr) && event.length!= item.attribute_data.length){
             setSelectedDataArr((selectedDataArr) => ({
               ...selectedDataArr,
               [type]: [ i.value],
             }));
           }else  if(item.attribute_data.length==event.length){
            filterArr.push(i.value)
            setSelectedDataArr((selectedDataArr) => ({
             ...selectedDataArr,
             [type]: filterArr,
           }));
          }
           else if( selectedDataArr[type].indexOf(i.value)==-1){
             setSelectedDataArr((selectedDataArr) => ({
               ...selectedDataArr,
               [type]: [ ...selectedDataArr[type],i.value],
             }));
           }else{
             filterArr.push(i.value)
             setSelectedDataArr((selectedDataArr) => ({
              ...selectedDataArr,
              [type]: filterArr,
            }));
           }
         })
       }else{
        setSelectedDataArr((selectedDataArr) => ({
          ...selectedDataArr,
          [type]: event,
        }));
       }
       if(!isEqual(type,"description") && event.length!=0) setSubmitBtn(true);
       if (/^\s*$/.test(event)) {
         // It has only whitespace
         dispatch(filterDataArrChangeHandlerAction({[type]: [] }) );
         if(!isEqual(type,"description"))
         dispatch(getSymptomsFiltersChangeHandlerAction({ [type]: [] }));
         setSubmitBtn(true);
       } else {
         if(!isEqual(type,"description")) {
           dispatch(filterDataArrChangeHandlerAction(selectedDataArr) );
           dispatch(getSymptomsFiltersChangeHandlerAction(selectedDataArr));
         }
         else {dispatch(filterDataArrChangeHandlerAction({ [type]: event }) );
         dispatch(getSymptomsFiltersChangeHandlerAction({ [type]: event }));
        }
         
         setSubmitBtn(true);
       }
       console.log(submiBtn)
       if(type=='description' && type!=''){
       setSubmitBtn(false);
       }
     };
     useEffect(() => {
       dispatch(filterDataArrChangeHandlerAction(selectedDataArr) );
    }, [selectedDataArr]);
  
  const onRemoveItemHandler = (name) => (selectedItem) => {
    const selectedProduct = get(selectedDataArr, name, []);
    const filteredProducts = selectedProduct.filter(
      (item) => item !== selectedItem
    );
    setSelectedDataArr((selectedDataArr) => ({
      ...selectedDataArr,
      [name]: filteredProducts,
    }));
  };
  useEffect(() => {
    disableSearchBtn();
  }, [selectedFilterData]);

  const disableSearchBtn = () => {
    const filterValues = compact(Object.values(selectedFilterData));
    return filterValues.length === 0;
  };

  const completeTriageHandler = () => {
    const today =  new Date();
    let timeEndVal = today.toISOString()
    setShowDrawer(true);
    setTimeEnd(timeEndVal);
  };
  const setSearchTerm = (item) => {
    SetSearchT(item)
  }
  const projectclick = (event, projectname) => {
    SetSearchT("");
    setPopperShow(false)
    let savedProjectName = localStorage.getItem('projectname');
    if (savedProjectName !== projectname) {
      // localStorage.setItem('projectname', projectname);
      encryptAndStore('projectname', projectname);
      SetProjectname(projectname);
    }
    onchangeProjectAction(projectname);
    //window.updateHCLChatBotURL(projectname);
  }
  const popperShowFn = () => {
    setPopperShow(true)
  }

  const startNewTriageHandler = (index) => { // the callback. Use a better name
    // setDrive(index);
    if (index == 'reset') {
      resetSAndNavigateToInsights();
      setSelectedDataArr({ 'description': '' })
      setShowDrawer(false);
    }
  };

  return (
    <Container fluid className="insights agent-cockpit " >
       <Row className="custom-insights d-flex pb-2" >
          <Col xs={2} style={{ padding: "0px" }} className="Agent-cockpit-sidebar">
            <div className="justify-content-start mb-1 p-1 agent-cockpit-area">
              <label className="pl-2 form-label-cust" style={{marginBottom:"0"}}>{"Agent Cockpit"}</label>
              <h4 className="justify-content-start pl-2 agent-cockpit-heading">
                {"Intelligent Triage"}
              </h4>
              {/* </Row> */}
            </div>
            <div className="custom-navbar-agantcockpit mb-1">
              <NavbarCustom
                dataList={projectList}
                addImportProjectIcon={false}
                setSearchTerm={(item) => setSearchTerm(item)}
                popperShowFn={() => popperShowFn()}
                popperShow={popperShow}
                searchTerm={searchT}
                projectclick={(event, item) => projectclick(event, item)}
                projectname={projectname}
                rightPopUp={true} >
              </NavbarCustom>
            </div>

            <div className="agent-cockpit-area p-2">
              <div className="d-flex mt-1">
                <span className="pl-1 w-90">
                  <h5 className="d-flex justify-content-start questionary-heading">
                    Thomas Glass <span>
                      <img
                  className="pl-2 float-right"
                  src="./images/edit-pencil.svg"
                  style={{width:'21px'}}
                  // onClick={resetSAndNavigateToInsights}
                /></span>
                  </h5>
                  <span>
                      <a href={gettingDataUrl} target="_blank">
                <img  className="video-icon-triage" src="./Icons/video-icon-black.svg" alt="Video Icon" />
                </a>
              <p className="icon-subheading">{"ASSIST"}</p>
                  </span>
                </span>
              </div>
              {(data.length && !productTriageDataLoading) ? 
              <div>
                {data.map((item) => {
                  let placeholder = 'Select'
                  let countt = get(selectedDataArr, item.attribute_name, "");
                  if (countt.length > 2) placeholder = countt[0] + "," + countt[1] + "...";
                  else if (countt.length != 0 && countt.length <= 2) placeholder = countt.join(",");
                  return (
                    <div className="productAttributesComp mt-1">
                      <ProductAttributesComponent
                        options={getOptions(item)}
                        name={item.attribute_name}
                        label={(item.attribute_name).includes("_") ? (item.attribute_name).split("_").join(" ") : (item.attribute_name)}
                        boldTitle={true}
                        search={true}
                        placeholder={placeholder}
                        className={""}
                        targetSourceDescription={"source_name"}
                        sourceHelpIcon={false}
                        attributeHelpIcon={false}
                        closeOption={false}
                        value={get(selectedDataArr, item.attribute_name, "")}
                        onChange={(event) =>
                          updateFilterData(event, item.attribute_name, item)
                        }
                        onRemoveItem={onRemoveItemHandler(item.attribute_name)}
                        showValues={false}
                      />
                    </div>
                  );
                })}
              </div>
                 : null }
            </div>
            <div className="agent-cockpit-area pl-2 pr-2 pt-1 pb-1">
              <div className="d-flex justify-content-center">
                <Col xs={12} className="pl-1 pr-1 pt-0 pb-0">
                  <TextAreaWithLabelComponent
                    label="Issue Details"
                    name="defaultValue"
                    required={true}
                    value={get(selectedFilterData, "description", "")}
                    className="description-text-box text-box"
                    placeholder={"Enter Issue Details..."}
                    onChange={(e) =>
                      updateFilterData(e.target.value, "description")
                    }
                  />
                </Col>
              </div>
              <div className="d-flex justify-content-center">
                <Col xs={12} className="p-1">
                  {show ?
                    <Button
                      variant="primary"
                      size="md"
                      style={{ backgroundColor: '#FF381D' }}
                      onClick={completeTriageHandler}
                      active
                      className="full-width-btn"
                    >
                      END TRIAGE
                    </Button> :
                    <Button
                      disabled={submiBtn && AgentCockpit_Read_Write}
                      variant="primary"
                      size="md"
                      onClick={submitInsightsHandler}
                      active
                      className="full-width-btn"
                    >
                      Start Triage
                    </Button>
                  }
                </Col>
              </div>
            </div>
            {show && topresolutions ? (
              topresolutions && <FilterDetails
                config={config}
                selectedQuestions={selectedQuestions}
              />
            ) : null}
            
          </Col>
          {show ? (
            <Col xs={10}>
              <div className="vh-100">
                <React.Fragment>
                  {show && topresolutions ? (
                    <div className="question-section agent-cockpit-area mb-1 pb-2 pl-2 pr-2">
                      <Row>
                        <Col xs={2} className="pr-0">
                          <h5 className="d-flex justify-content-start questionary-sub-heading pb-2 mt-2">
                            Next Triage Question
                            <img className="cust-image ml-1" src="images/Arrow-red.svg" />
                          </h5>
                         
                        </Col>
                        <Col xs={10} style={{paddingLeft:'0'}}>

                          <div className=" d-flex justify-content-start custom-questions">
                          {get(
                              get(questions, selectedQuestionIndex, 0),
                              "leadingquestion",
                              ""
                            )} &nbsp;&nbsp;
                            {isEqual(counter,questions.length) ? <pan style={{color:'#A0A0A0'}}>No more questions to be asked</pan> : 
                            (<> <SecondaryButtonWithLoader
                              size="sm"
                              className="w-56 h-32 second-tri"
                              onClick={() => updateSelectedQuestionIndex("no")}
                              disabled={counter === questions.length}
                              text="no"
                            />
                            <PrimaryButtonWithLoader
                              size="sm"
                              className="ml-2 w-56 h-32 ml-1 primary-tri"
                              onClick={() => updateSelectedQuestionIndex("yes")}
                              disabled={counter === questions.length}
                              text="yes"
                            />
                            <Button
                              variant="light"
                              size="sm"
                              className="skip-button w-56 h-32 ml-1"
                              disabled={counter === questions.length}
                              onClick={() => updateSelectedQuestionIndex("skip")}
                            >
                              SKIP
                            </Button> </>)}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ) : null}
                  <InsightsResults
                    config={config}
                    results={get(agentData, "responsedata", [])}
                  />
                </React.Fragment>
              </div>
            </Col>
          ) : <img src="./images/Agent-Cockpit-Bg-Img.png" alt="agen-cockpit"  style={{left:'49%',top:"17%",height:'80vh',overflow:"hidden", position:"absolute"}}/>}
        </Row>
      {showDrawer ? (
        <CompleteTriage
          showDrawer={showDrawer}
          toggleDrawer={toggleDrawer}
          selectedQuestions={selectedQuestions}
          selectedFilterData={selectedFilterData}
          appliedCheckboxes={appliedCheckboxes}
          timeStart={timeStart}
          timeEnd={timeEnd}
          uniqueIDnums={uniqueIDnums}
          config={config}
          toastFun={(msg, type) => {
            toast(msg, {
              type: type,
              pauseOnHover: false,
            });
          }}
          closeDrawer={() => {
            setShowDrawer(false);
          }}
          startNewTriageHandler={startNewTriageHandler}
        />
      ) : null}
      <ToastContainer />
    </Container>
  );
};

AgentCockpit.propTypes = {};

AgentCockpit.defaultProps = {};

const mapStateToProps = state => {
  return {
    projectList: state.user.projects,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onchangeProjectAction: (projectname) => dispatch(actions.changeProjectAction(projectname)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AgentCockpit);
