// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.myownclass .MuiTableCell-head{
    line-height: 0.6rem;
}
/* .myownclass .MuiTableCell-root:active{
border-bottom: 1px solid #5A85F2 !important;
} */
.flowListsDropdown {
    position: relative;
}
.flowListsDropdown1{
    position: relative;
    right: 28px;
}
.flowListsDropdown1 > button {
    background: transparent !important;
    border:0 !important;
    box-shadow:none !important;
}
 
.flowListsDropdown1 > button::after {
    display: none;
    border:0;
}

.flowListsDropdown > button {
    background: transparent !important;
    border:0 !important;
    box-shadow:none !important;
}
 
.flowListsDropdown > button::after {
    display: none;
    border:0;
}
.dropMenu {
    min-width: 6rem !important;
    position: absolute !important;
}
.sideDropdown{
    padding:.25rem .75rem;
    z-index: 99999;
    position: relative;
}
.sideDropdown img{
    margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/containers/ProjectViewPage/Table/Table.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;AACA;;GAEG;AACH;IACI,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,WAAW;AACf;AACA;IACI,kCAAkC;IAClC,mBAAmB;IACnB,0BAA0B;AAC9B;;AAEA;IACI,aAAa;IACb,QAAQ;AACZ;;AAEA;IACI,kCAAkC;IAClC,mBAAmB;IACnB,0BAA0B;AAC9B;;AAEA;IACI,aAAa;IACb,QAAQ;AACZ;AACA;IACI,0BAA0B;IAC1B,6BAA6B;AACjC;AACA;IACI,qBAAqB;IACrB,cAAc;IACd,kBAAkB;AACtB;AACA;IACI,kBAAkB;AACtB","sourcesContent":[".myownclass .MuiTableCell-head{\n    line-height: 0.6rem;\n}\n/* .myownclass .MuiTableCell-root:active{\nborder-bottom: 1px solid #5A85F2 !important;\n} */\n.flowListsDropdown {\n    position: relative;\n}\n.flowListsDropdown1{\n    position: relative;\n    right: 28px;\n}\n.flowListsDropdown1 > button {\n    background: transparent !important;\n    border:0 !important;\n    box-shadow:none !important;\n}\n \n.flowListsDropdown1 > button::after {\n    display: none;\n    border:0;\n}\n\n.flowListsDropdown > button {\n    background: transparent !important;\n    border:0 !important;\n    box-shadow:none !important;\n}\n \n.flowListsDropdown > button::after {\n    display: none;\n    border:0;\n}\n.dropMenu {\n    min-width: 6rem !important;\n    position: absolute !important;\n}\n.sideDropdown{\n    padding:.25rem .75rem;\n    z-index: 99999;\n    position: relative;\n}\n.sideDropdown img{\n    margin-right: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
