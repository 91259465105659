/*

* Copyright © 2020, HCL Technologies Ltd. ALL RIGHTS RESERVED.

* <p>

* This software is the confidential information of HCL Technologies Ltd., and is licensed

* as restricted rights software. The use,reproduction, or disclosure of this software

* is subject to restrictions set forth in your license agreement with HCL.

*/

 

/**

* Setup web crawler.

*

* @author Abhash Vats

*/
import React,{Component} from "react";
import { connect } from 'react-redux';
import withErrorHandler from "../../../../hoc/withErrorHandler/withErrorHandler";
import axios from "../../../../instance";
import { GetCalltoServer } from '../../../../store/utility.js';
import { Container, Col, Row } from 'react-bootstrap';
import './SetupCrawler.css';
import RefreshIcon from '@mui/icons-material/Refresh';
import Heading3 from '../components/Typography/Heading3/Heading3';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import NavbarCustom from '../../../../components/UI/Navbar/Navbar';
import * as actions from '../../../../store/actions/index';
import SpinnerPage from "../../../../components/UI/Loader/Loader";
import Tooltip from '@material-ui/core/Tooltip';
import {withStyles } from '@material-ui/core';
import ButtonComponent from "../../../../components/UIComponents/Button/ButtonComponent";
import { encryptAndStore } from "../../../../utility/utility";

const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 12,
      margin:-8 -2,
    },
  }))(Tooltip);
class DownloadData extends Component {
        state = {
                data:[],
                rows: [],
                day:15,
                rowsPerPage:5,
                page:0,
                order:'asc',
                //orderBy:'URL',
                sortBy: 'URL',
                sortOrder: 'asc',
                searchT:"",
                popperShow:false,
                projectname:"Project List",
                dataForDownload:this.props.minedUrl,
                selectedCommanFlag:'yes',
                urlLoading:false,
                downloadCrawlData:[],
        }
        setSearchTerm = (item) => {
            this.setState({searchT:item})
        }
        popperShowFn=()=>{
            this.setState({
              popperShow:true,
            })
        }
        projectclick =(event,projectname)=>{
            this.setState({searchT:"",popperShow:false})
            let savedProjectName=localStorage.getItem('projectname');
            if(savedProjectName!==projectname){
            // localStorage.setItem('projectname', projectname );
            encryptAndStore('projectname', projectname );
            this.setState({projectname:projectname});
            }
            this.props.onchangeProjectAction(projectname);        
              //window.updateHCLChatBotURL(projectname);
              let url = this.props.config.BOTBUILDER_URL + `/webcrawldata/` + localStorage.getItem('projectname')
                this.props.onDownloadDataStart(true)
                GetCalltoServer(url).then(response => {
                    console.log("response == ",response)
                    if (response.data.downloadData != "") {
                        this.setState({data:response.data.downloadData})
                        const sortedData = [...this.state.data].sort((a, b) =>  new Date(a.date) < new Date(b.date) ? 1 : -1)
                            this.setState({ data: sortedData });
                        this.props.onDownloadDataStart(false)
                    }
                    else {
                        this.props.onDownloadDataStart(false)
                    }
                }).catch(() => {
                    
                });
        }
        componentDidMount(){
            // if (typeof(this.props.downloadCrawlData) != "undefined"){
            //     this.setState({data:this.props.downloadCrawlData})
            // }
            // else{
                let url = this.props.config.BOTBUILDER_URL + `/webcrawldata/` + localStorage.getItem('projectname')
                this.props.onDownloadDataStart(true)
                GetCalltoServer(url).then(response => {
                    console.log("response == ",response)
                    if (response.data.downloadData != "") {
                        this.setState({data:response.data.downloadData})
                        const sortedData = [...this.state.data].sort((a, b) =>  new Date(a.date) < new Date(b.date) ? 1 : -1)
                            this.setState({ data: sortedData });
                        this.props.onDownloadDataStart(false)
                    }
                    else {
                        this.props.onDownloadDataStart(false)
                    }
                }).catch(() => {
                    
                });
            // }   
        }
    componentDidUpdate(prevprops){
        if(this.props.minedUrl && this.props.minedUrl !== prevprops.minedUrl){
            this.setState({
                minedUrl:this.props.minedUrl,
                dataForDownload:this.props.minedUrl,
                urlLoading:false
            })
        }
        if(this.props.downloadCrawlData && this.props.downloadCrawlData !== prevprops.downloadCrawlData && this.props.downloadCrawlData != ""){
            this.setState({
                data:this.props.downloadCrawlData,
                urlLoading:false,
                page:0

            })
        }
        if(this.props.downloadCrawlData !== prevprops.downloadCrawlData && this.props.downloadCrawlData == ""){
            let url = this.props.config.BOTBUILDER_URL + `/webcrawldata/` + localStorage.getItem('projectname')
            this.props.onDownloadDataStart(true)
            GetCalltoServer(url).then(response => {
                console.log("response == ",response)
                if (response.data.downloadData != "") {
                    this.setState({data:response.data.downloadData})
                    const sortedData = [...this.state.data].sort((a, b) =>  new Date(a.date) < new Date(b.date) ? 1 : -1)
                            this.setState({ data: sortedData });
                    this.props.onDownloadDataStart(false)
                }
                else {
                    this.props.onDownloadDataStart(false)
                }
            }).catch(() => {
                
            });  
        }
        if(this.props.loading !== prevprops.loading){
            this.setState({
                urlLoading:this.props.loading
            })
        }
    }
    increment = () =>{
        if(this.state.day<60){
                this.setState({
                        day:++this.state.day
                })
        } 
    }
    decrement = () =>{
        if(this.state.day>1){
                this.setState({
                        day:--this.state.day
                })
        }
    }

    handleChangePage = (event, newPage) => {
            console.log("event",event)
        this.setState({page:newPage})
      }
    
      handleChangeRowsPerPage = (event) => {
        this.setState({rowsPerPage:+event.target.value,page:0})
      }
      downloadData = (e,name,rowURL) => {
        let url = this.props.config.BOTBUILDER_URL;
        let urls = []
        if(name == 'all'){
        this.state.data.forEach((response)=>{
            if(response.status == 'Failed'){
                urls.push(response.url);
                console.log(urls)
            }
            return ''
        })
    }
        else if(rowURL){
            urls.push(rowURL)
        }
        else{
            this.state.dataForDownload.forEach((item)=>{
               return urls.push(item.url)
            })}
            var data = {
                        "urls" : urls,
                        "crawldocs": this.state.selectedCommanFlag,
                        "projectName":localStorage.getItem("projectname")
                    }
            this.props.onDownloadCrawledData(url,data)
        this.setState({urlLoading:false})
    }
     sortDocument = (tableData) =>{
        this.setState ({
            data: tableData.slice().sort((a, b) => a.url.localeCompare(b.url))
        });
          };
      
      sortStatus = (tableData) =>{
        this.setState ({
                    data: tableData.slice().sort((a, b) => a.status.localeCompare(b.status))
                });
    };
    sortDate = (tableData) => {
        this.setState({
            data : tableData.sort((a,b) => new Date(a.date) < new Date(b.date) ? 1 : -1)
        })
            
    }
    onRefresh = () =>{
        let url = this.props.config.BOTBUILDER_URL + `/webcrawldata/` + localStorage.getItem('projectname')
                this.props.onDownloadDataStart(true)
                GetCalltoServer(url).then(response => {
                    console.log("response == ",response)
                    if (response.data.downloadData != "") {
                        this.setState({data:response.data.downloadData})
                        const sortedData = [...this.state.data].sort((a, b) =>  new Date(a.date) < new Date(b.date) ? 1 : -1)
                            this.setState({ data: sortedData });
                        this.props.onDownloadDataStart(false)
                    }
                    else {
                        this.props.onDownloadDataStart(false)
                    }
                }).catch(() => {
                    
                });
    }
    render(){
        return(<>
            <Container className="CrawlerTabStyle">
                <Row className="headerRow" xs='12' sm='12' md="12" lg="12" xl="12" xxl="12">
                    <Col xs='6' sm='6' md="6" lg="6" xl="6" xxl="6">
                        <Heading3 text='Downloaded Data' className="pageTitleText"/>
                    </Col>
                    <Col xs='3' sm='3' md="3" lg="3" xl="3" xxl="3" className="d-flex text-align-end justify-content-end">
                        {/* <div style={{ position: "relative", float: "left", marginLeft: "120px", marginTop: "10px" }}> */}
                        <span style={{color:"rgb(42, 147, 223)", cursor:"pointer",float:"left",marginTop:"10px"}}>
                        <RefreshIcon 
                        onClick={()=>this.onRefresh()}
                        ></RefreshIcon>
                    </span>
                            {/* <ButtonComponent
                                className="buttonWidth add-lang-button"
                                variant='contained'
                                label="RETRAIN All"
                                clicked={(e) => this.downloadData(e, 'all')}
                                disabled={!this.props.CSAdminRW}
                            /> */}
                            {/* <button style={{color:"white",fontSize:"12px",fontWeight:"bold"}} className="downloadBtn" 
                    onClick={(e)=>this.downloadData(e,'all')} disabled={!this.props.CSR}>
                        <span >RETRAIN All</span>
                    </button> */}
                        {/* </div> */}
                        </Col>
                        <Col xs='3' sm='3' md="3" lg="3" xl="3" xxl="3">
                    <div style={{position:"relative",float:"right",marginRight:"60px"}}>
                    
                            <NavbarCustom 
                                className="crawlingProjectName"
                                dataList={this.props.projectlist}
                                addImportProjectIcon={false}
                                setSearchTerm={(item)=>this.setSearchTerm(item)}
                                popperShow={this.state.popperShow}
                                popperShowFn={()=>this.popperShowFn()}
                                searchTerm={this.state.searchT}
                                projectclick={(event,item)=>this.projectclick(event,item)}
                                projectname={this.state.projectname} 
                                rightPopUp={false} >
                            </NavbarCustom>
                        </div>
                            {/* <div className="increDecre">
                            <div >
                                <a onClick={() => this.increment()}> <ArrowDropUpIcon className="incre" ></ArrowDropUpIcon> </a>
                            </div>
                            <div >
                                <a onClick={() => this.decrement()}><ArrowDropDownIcon className="decre"></ArrowDropDownIcon></a>
                            </div>
                            </div>
                            <div className="increDecre increDecretextFont">
                            <span> last {this.state.day} days</span>
                            </div> */}
                    </Col>
                </Row>
                {this.state.urlLoading == true ? <SpinnerPage/> :null}
                {this.state.data && this.state.data.length>0 &&
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer component={Paper} style={{ maxHeight: "380px" }}>
                        <Table stickyHeader aria-label="sticky table">
                        
                            <TableHead>
                            <TableRow>
                                <TableCell><span className="tableHeadrText" onClick={(e)=>this.sortDocument(this.state.data)}>Document Name/URL</span></TableCell>
                                <TableCell><span className="tableHeadrText" onClick={(e)=>this.sortDate(this.state.data)}>DATE</span></TableCell>
                                <TableCell><span className="tableHeadrText" onClick={(e)=>this.sortStatus(this.state.data)}>STATUS</span></TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {this.state.data && this.state.data.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row) => (
                                <TableRow>
                                    <TableCell> {(row.url).length> 90 ? <a href={row.url} target="_blank">{(row.url).slice(0,90) + "..."}</a> : <a href={row.url} target="_blank">{row.url}</a>}</TableCell> 
                                    {/* <TableCell><a href={row.url} target="_blank">{row.url}</a></TableCell>  */}
                                    <LightTooltip arrow title={row.time} placement="top-start">
                                        <TableCell className='tableCell'>
                                        {row.date}
                                        </TableCell>
                                    </LightTooltip>
                                    
                                    <TableCell>
                                        {/* {row.status == 'Failed' ?  <span className="tableStatus">{row.status} <button className="retrainBtn" onClick={(e)=>this.downloadData(e,'row',row.url)}>
                                    <span style={{color:"white",fontSize:"12px",fontWeight:"bold"}}>RETRAIN</span>
                                    </button></span> : */}
                                    <span>{row.status}</span></TableCell>
                                    
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                     </TableContainer>
                     <TablePagination
                                rowsPerPageOptions={[5,10,15]}
                                component="div"
                                count={this.state.data.length}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.page}
                                onPageChange={(e,page)=>this.handleChangePage(e,page)}
                                onRowsPerPageChange={(e)=>this.handleChangeRowsPerPage(e)}
                        />
                     </Paper>
                     }
            </Container>
        
        </>)
    }
}

const mapStateToProps = state => {
    return {
        downloadCrawlData: state.cognitive.downloadCrawlData,
        CSAdminRW: state.user.moduleAccess.cognitiveSearchAdmin.RW,
        projectlist: state.user.projects,
        minedUrl:state.cognitive.minedUrl,
        loading:state.cognitive.loading,
        Downloadload:state.cognitive.Downloadload,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onchangeProjectAction: (projectname) => dispatch(actions.changeProjectAction(projectname)),
        onDownloadCrawledData: (url,data) => dispatch(actions.downloadCrawledData(url,data)),
       onDownloadDataStart:(data) => dispatch(actions.DownloadDataStart(data)),

    }
  
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withErrorHandler(DownloadData, axios));