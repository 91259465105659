export const SIMILAR_ISSUES_CHANGE_HANDLER = 'SIMILAR_ISSUES_CHANGE_HANDLER';
export const SIMILAR_ISSUES_OPTIONAL_FIELD_CHANGE_HANDLER = 'SIMILAR_ISSUES_OPTIONAL_FIELD1_CHANGE_HANDLER';
export const ISSUE_CLARIFICATION_OPTIONAL_FIELD_CHANGE_HANDLER = 'ISSUE_CLARIFICATION_OPTIONAL_FIELD_CHANGE_HANDLER';
export const KB_DOCS_CHANGE_HANDLER = 'KB_DOCS_CHANGE_HANDLER';
export const ISSUE_CLASSIFICATION_CHANGE_HANDLER = 'ISSUE_CLASSIFICATION_CHANGE_HANDLER';
export const TOP_RESOLUTIONS_CHANGE_HANDLER = 'TOP_RESOLUTIONS_CHANGE_HANDLER';
export const PREDICTED_ISSUES_CHANGE_HANDLER = 'PREDICTED_ISSUES_CHANGE_HANDLER';
export const QUESTIONARIES_CHANGE_HANDLER = 'QUESTIONARIES_CHANGE_HANDLER';
export const OPTIONAL_FIELD_RESET_HANDLER = 'OPTIONAL_FIELD_RESET_HANDLER';
export const KNOWLEDGE_ARTICLES_CHANGE_HANDLER = 'KNOWLEDGE_ARTICLES_CHANGE_HANDLER';

export const SAVE_INSIGHTS_UNIFIED_LOGIN_ES_DATA_FAILURE = 'SAVE_INSIGHTS_UNIFIED_LOGIN_ES_DATA_FAILURE';
export const SAVE_INSIGHTS_UNIFIED_LOGIN_ES_DATA_SUCCESS = 'SAVE_INSIGHTS_UNIFIED_LOGIN_ES_DATA_SUCCESS';
export const SAVE_INSIGHTS_UNIFIED_LOGIN_ES_DATA_START = 'SAVE_INSIGHTS_UNIFIED_LOGIN_ES_DATA_START';

export const FETCH_INSIGHTS_DATA_START = 'FETCH_INSIGHTS_DATA_START';
export const FETCH_INSIGHTS_DATA_SUCCESS = 'FETCH_INSIGHTS_DATA_SUCCESS';
export const FETCH_INSIGHTS_DATA_FAILURE = 'FETCH_INSIGHTS_DATA_FAILURE';
