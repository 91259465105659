// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.associate-multi-select{
    display: flex;
    flex-wrap: wrap;
    min-width: 55px;
}

.selected-option {
    /* min-width: 110px; */
    height: 29px;
    box-sizing: border-box;
    border-radius: 9px;
    background-color: rgba(73, 71, 71, 0.62);
}

.remove-icon {
    cursor: pointer;
    font-family: 'Roboto-Regular';
    font-size: 18px;
    line-height: 21px;
    color: #000;
}

.selected-close-icon {
    color: #FFFFFF;
}

.selected-option-label {
    font-family: 'Roboto-Regular';
    font-weight: 300;
    font-size: 11px;
    line-height: 13px;
    /* identical to box height */
    color: #FFFFFF;
}`, "",{"version":3,"sources":["webpack://./src/containers/SettingPage/SettingInnerPages/UserManagement/components/userManagementComponent.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,sBAAsB;IACtB,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;IAClB,wCAAwC;AAC5C;;AAEA;IACI,eAAe;IACf,6BAA6B;IAC7B,eAAe;IACf,iBAAiB;IACjB,WAAW;AACf;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,6BAA6B;IAC7B,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,4BAA4B;IAC5B,cAAc;AAClB","sourcesContent":[".associate-multi-select{\n    display: flex;\n    flex-wrap: wrap;\n    min-width: 55px;\n}\n\n.selected-option {\n    /* min-width: 110px; */\n    height: 29px;\n    box-sizing: border-box;\n    border-radius: 9px;\n    background-color: rgba(73, 71, 71, 0.62);\n}\n\n.remove-icon {\n    cursor: pointer;\n    font-family: 'Roboto-Regular';\n    font-size: 18px;\n    line-height: 21px;\n    color: #000;\n}\n\n.selected-close-icon {\n    color: #FFFFFF;\n}\n\n.selected-option-label {\n    font-family: 'Roboto-Regular';\n    font-weight: 300;\n    font-size: 11px;\n    line-height: 13px;\n    /* identical to box height */\n    color: #FFFFFF;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
