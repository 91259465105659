// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-dropdown{
    padding: 0;
    background: #FFFFFF;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #646363;
}
.custom-dropdown button{
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    color: #696868 !important;
}

.custom-dropdown .dropdown-menu{
    width: 100%;    
}

.custom-dropdown .dropdown-toggle::after {
    position: absolute;
    right: 15px;
    top: 16px;
    font-size: 25px !important;
  }`, "",{"version":3,"sources":["webpack://./src/components/UI/FormComponents/DropdownInput.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,mBAAmB;IACnB,yBAAyB;IACzB,sBAAsB;IACtB,kBAAkB;IAClB,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;AACA;IACI,gBAAgB;IAChB,eAAe;IACf,gBAAgB;IAChB,yBAAyB;AAC7B;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,SAAS;IACT,0BAA0B;EAC5B","sourcesContent":[".custom-dropdown{\n    padding: 0;\n    background: #FFFFFF;\n    border: 1px solid #000000;\n    box-sizing: border-box;\n    border-radius: 4px;\n    font-family: Roboto;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 14px;\n    line-height: 16px;\n    color: #646363;\n}\n.custom-dropdown button{\n    text-align: left;\n    font-size: 16px;\n    font-weight: 400;\n    color: #696868 !important;\n}\n\n.custom-dropdown .dropdown-menu{\n    width: 100%;    \n}\n\n.custom-dropdown .dropdown-toggle::after {\n    position: absolute;\n    right: 15px;\n    top: 16px;\n    font-size: 25px !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
