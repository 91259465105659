import React from 'react';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';
import Accordion from 'react-bootstrap/Accordion';
import { Row,  Button } from 'react-bootstrap';
import Slider from '../../../components/UI/Slider/Slider';
import Input from '../../UI/Input/InputCustom';
import LabelValueComponent from '../components/LabelValueComponent/LabelValueComponent';
import ReactSelectComponent from '../components/ReactSelectComponent/ReactSelectComponent';
import './SimilarIssues.css';
import { useDispatch, useSelector } from "react-redux";
import { SetWarningMessage } from "../../../store/actions/message";


const SimilarIssuesPage = (
    {
        enabled,
        size,
        extensionFields,
        handleSwitchChange,
        inputChangedHandler,
        multiChangeHandler,
        handleSlideChange,
        similarity,
        optionFieldsChangedHandler,
        saveButtonClickHandler,
        similarIssuesSelect,
        optionField1,
        optionField2,
        onRemoveItemHandler,
        optionalField1DdOptions,
        optionalField2DdOptions,
        ticketFieldsOptions,
        similarIssuesDefaultField,
        ownProps
    }) => { 
        const dispatch = useDispatch();
        const issueCStatus = useSelector(
        (state) => state.insights.issueclassification.enabled
        );
        const topresStatus = useSelector(
        (state) => state.insights.topresolutions.enabled
        ); 
    const check = (e)=>{
        if(ownProps.permissions==false) return true

        if(issueCStatus!=false || topresStatus!=false ) {
            handleSwitchChange(e)
        }else{
            dispatch(SetWarningMessage("One Tab Should be Enable"));
        }
    }
    
      
    return (
        <div className="SimilarIssuesPage" >
            <Accordion defaultActiveKey="0">
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="1">
					<span id="SimilarIsuues-icon" ><img src="./images/SimilarIsuues.svg" className="InsightsTabsIcons" />Similar Issues </span>                        
                          <div className="SimilarIssuesActions">
                            <Form>
                                <Form.Check
                                    type="switch"
                                    id="SimilarIssuesCustomSwitch"
                                    checked={ownProps.permissions && enabled?true:false}
                                    onChange={e => check(e)}
                                />
                            </Form>
                            <span class="Settings-icon" ><img src="./images/SettingsGrey.svg" /></span>
                        </div>

                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body>
                            <div className="col-md-4 SearchSize">
                                <Form.Label>Size of the Search List</Form.Label>
                                <Input
                                    key="SelctDropDown"
                                    elementType="select"
                                    elementConfig={similarIssuesSelect.elementConfig}
                                    value={size}
                                    changed={(event) => inputChangedHandler(event, 'size')}
                                />
                            </div>
                            <div className="col-md-12 SimilarityLevel">
                                <p>List and order of the ticket fields from the collection</p>
                                <ReactSelectComponent
                                    value={extensionFields}
                                    options={ticketFieldsOptions}
                                    onChange={(newValue) => multiChangeHandler(newValue, 'extensionFields')}
                                    onRemoveItem={onRemoveItemHandler}
                                    placeholder={'Select Ticket field'}
                                />
                            </div>
                            <div className="SimilarityLevel">
                                <p>Similarity confidence level </p>
                                <Slider
                                    minValue={50}
                                    maxValue={100}
                                    value={similarity}
                                    onChange={handleSlideChange}
                                />
                            </div>
                            <div className="SimilarityFields">
                                <p>Similarity based on fields  </p>
                                <div className="col-md-12">
                                    <Row>
                                        <div className='col-md-3'>
                                            <LabelValueComponent
                                                key='defaultField'
                                                value='title'
                                                readOnly={true}
                                                labelName='Default Field' />
                                        </div>
                                        <div className='col-md-3'>
                                            <LabelValueComponent
                                                key='defaultField'
                                                value={similarIssuesDefaultField}
                                                readOnly={true}
                                                labelName='Default Field2' />
                                        </div>
                                        <div className="col-md-3 OptionField">
                                            <Form.Group>
                                                <Form.Label>Optional Field1</Form.Label>
                                                <Input
                                                    key="sizeOfTheSearchList1"
                                                    elementType="select"
                                                    elementConfig={optionalField1DdOptions}
                                                    value={optionField1}
                                                    changed={(event) => optionFieldsChangedHandler(event, 'similarIssuesOptionalField1')}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-3 OptionField">
                                            <Form.Group>
                                                <Form.Label>Optional Field2</Form.Label>
                                                <Input
                                                    key="sizeOfTheSearchList2"
                                                    elementType="select"
                                                    elementConfig={optionalField2DdOptions}
                                                    value={optionField2}
                                                    changed={(event) => optionFieldsChangedHandler(event, 'similarIssuesOptionalField2')}
                                                />
                                            </Form.Group>
                                        </div>
                                    </Row>

                                </div>
                            </div>
                            <Button className='saveBtn' disabled={ownProps.permissions==true?false:true} variant="primary" onClick={saveButtonClickHandler}>Save</Button>{' '}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>

            </Accordion>
        </div>
    );
};

SimilarIssuesPage.propTypes = {
    similarIssuesSelect: PropTypes.object,
    sizeSearchListConfig: PropTypes.object
};

SimilarIssuesPage.defaultProps = {
    similarIssuesSelect: {
        elementType: 'select',
        elementConfig: {
            options: [
                { value: 3, displayValue: '3' },
                { value: 5, displayValue: '5' },
                { value: 8, displayValue: '8' },
                { value: 10, displayValue: '10' }
            ]
        },
        value: '',
        validation: {
            required: true
        },
        valid: false,
        touched: false,
        className: 'selectLang'
    },
    sizeSearchListConfig: {
        elementType: 'select',
        elementConfig: {
            options: [
                { value: '', displayValue: '--Select--' },
                { value: 'assignee', displayValue: 'assignee' },
                { value: 'severity', displayValue: 'severity' },
                { value: 'workGroup', displayValue: 'workGroup' },
                { value: 'customerName', displayValue: 'customerName' },
                { value: 'problemArea', displayValue: 'problemArea' },
                { value: 'incidentCategory', displayValue: 'incidentCategory' }
            ]
        },
        value: '',
        validation: {
            required: true
        },
        valid: false,
        touched: false,
        className: 'selectLang'
    },
    // extensionFieldsOptions: [
    //     { value: 'title', label: 'title' },
    //     { value: 'assignee', label: 'assignee' },
    //     { value: 'severity', label: 'severity' },
    //     { value: 'workGroup', label: 'workGroup' },
    //     { value: 'customerName', label: 'customerName' },
    //     { value: 'problemArea', label: 'problemArea' },
    //     { value: 'incidentCategory', label: 'incidentCategory' },
    //     { value: 'problemDescription', label: 'problemDescription' }
    // ]
    extensionFieldsOptions:  [
        { value: 'title', label: 'title' },
        { value: 'description', label: 'description' },
        { value: 'SR_NUM', label: 'SR_NUM' },
        { value: 'PRODUCT', label: 'PRODUCT' },
        { value: 'RESOLUTION_CD', label: 'RESOLUTION_CD' },
        { value: 'SYMPTON_CODE', label: 'SYMPTON_CODE' },
        { value: 'productfamilyname', label: 'productfamilyname' },
        { value: 'SERIAL_NUM', label: 'SERIAL_NUM' }
    ]
}

export default SimilarIssuesPage;