import get from 'lodash/get';
import getInsightsData from './getInsightsData';

const getOptionalFieldOptions = (props) => {
    const insightsData = getInsightsData(props);
    const defaultFields = get(insightsData, 'similarTicketsOptions.defaultFields', []);
    const fields = get(insightsData, 'similarTicketsOptions.fields', []);
    const filteredFields = fields.filter(field => !defaultFields.includes(field));
    const options = filteredFields.map(item => ({
        value: item,
        displayValue: item
    }));
    options.unshift({value: "", displayValue: "--Select--"});
    return options;
};

export default getOptionalFieldOptions;