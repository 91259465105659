import React from 'react';
import 'bs-stepper/dist/css/bs-stepper.min.css';
import Stepper from 'bs-stepper'
import ManageMasterCard from './ManageMasterCard'
import './StepperConfigureTrain.css';
import TriageDataStatus from "./helpers/TriageDataStatus";
import getRunModalBodyData from "./helpers/getRunModalBodyData";
import {
  GetCalltoServer,
  PostCalltoServer,
} from "../../../../../../../store/utility";
import get from "lodash/get";
import { connect } from 'react-redux';
import getProjectDetails from "../../../../helpers/getProjectDetails";

class StepperConfigureTrain extends React.Component {
  constructor() {
        super();
        this.state = {
          progress: 0,
          speed: 1,
          triageData:false,
          showData:false,
          CallCount:10,
          triageMsg:'',
          loop:0,
        };
        this.frame = this.frame.bind(this);
        
      }
    
    
      componentDidMount() {
        let sourceVal = this.props.sourceVal;
        this.stepper = new Stepper(document.querySelector('#stepper1'), {
          linear: false,animation: true
        })       
      }
      componentWillReceiveProps = (nextProps) => {
        if (nextProps.IsProjectChange !== this.props.IsProjectChange) {
          this.stepper.to(1);
          this.setState({
            triageData:false,progress: 0, CallCount:10 ,triageMsg:'',
          showData:false,loop:0
          })
        }
      }
    
      frame() {
        let DataArr=[];
        const projectName = getProjectDetails();
        const url = this.props.config.INSIGHTS_API_URL + "/product-intelligent-triage/settings/monitoringlogs/project/"+projectName;
        GetCalltoServer(url)
         .then((response) => {
          if(response.data.data){
            DataArr = JSON.parse(response.data.data)
          }
         }).catch(() => {});
        let TriageData = TriageDataStatus();
        if (this.state.progress < 100){
          this.setState((prevState, props) => ({
            progress: prevState.progress + this.state.speed
          }));
          if(this.state.CallCount==this.state.progress){
            console.log(TriageData[0])
            this.setState({CallCount:this.state.CallCount+8,loop:this.state.loop+1,triageMsg:TriageData[this.state.loop]["message"]});
            console.log(this.state.loop)
          }
        }else if(this.state.progress == 100){
          this.setState({triageData:true})
          clearInterval(this.interval);
        }
      }
  
      componentWillUnmount() {
        clearInterval(this.interval);
        
      }
     ViewTrained= ()=>{
       
       this.setState({triageData:false, showData:false});
       this.props.OnSelectTrainedData("viewTrained");
     }
     results=()=>{
       this.runModalHandler();

       this.setState({showData:true})
       this.interval = setInterval(() => this.frame(), 700);
     }
     
      onSubmit(e) {
        e.preventDefault()
      }
      runModalHandler = () => {
        let selectedSource = this.props.selectedSource.split(" - ");
        let sourceCole = selectedSource[1];
        const updatedselectedSource = localStorage.getItem("source").slice(13).trim() || sourceCole;

        const projectName = getProjectDetails();
        const url = this.props.config.INSIGHTS_API_URL + `/product-intelligent-triage/settings/reindex`;
        const reIndexObj = {
          sourceIndex: updatedselectedSource, desitnationIndex: "producttriage", project: projectName
        }
        PostCalltoServer(url, reIndexObj)
            .then((response) => {
              if (get(response, "data.status.responseType", "") === "success") {
        const url =
        this.props.config.INSIGHTS_API_URL + "/product-intelligent-triage/bulkapi";
        const runModalBodyData = getRunModalBodyData(this.props.config);
       setTimeout(() => {
        PostCalltoServer(url, runModalBodyData)
          .then(() => {
          
          })
          .catch(() => {
         
          });
      }, 3000);
        
        }
      })
      .catch(err => {
        console.log(err);
      })
      };

      render() {
        return (
          <div>
            <div id="stepper1" className="bs-stepper">
              <div className="bs-stepper-header col-md-12">
                <div className="step" data-target="#test-l-1">
                  <button className="step-trigger">
                    <span className="bs-stepper-circle">1</span>
                    <span className="bs-stepper-label">Configure Triage <br/>Attributes</span>
                  </button>
                </div>
                <div className="line"></div>
                <div className="step" data-target="#test-l-2">
                  <button className="step-trigger">
                    <span className="bs-stepper-circle">2</span>
                    <span className="bs-stepper-label">Train Triage  <br/>Model</span>
                  </button>
                </div>
              </div>
              <div className="bs-stepper-content">
                <form >
                  <div id="test-l-1" className="content">
                    <ManageMasterCard config={this.props.config} changeNextEvent={() => this.stepper.next()} sourceVal={this.props.sourceVal} selectedSource={this.props.selectedSource}/>
                  </div>
                  <div id="test-l-2" className="content">
                    <div className='TriageBg'>
                        <img src="./images/TriageModel.png" />
                        {!this.state.showData && !this.state.triageData?<button className="btn btn-primary" onClick={this.results}>START TRAINING THE TRIAGE MODEL</button>:''}
                        {this.state.showData && !this.state.triageData ?<div id="myProgress"> 
                          <div id="myBar" style={{width: this.state.progress + "%"}}>
                          </div> 
                          <div className="barLabel">{this.state.progress<100?'Please wait':''} ({this.state.progress}%) Completed<br/>
                          <span>{this.state.triageMsg}</span>
                          </div> 
                         </div> :''
                        }
                        {this.state.triageData ?<button className="btn btn-primary view" onClick={this.ViewTrained} >VIEW TRIAGE DATA </button>:''}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        );
      }
    }
const mapStateToProps = state => {
      return {
        IsProjectChange:state.projectview.IsProjectChange,
        IsTabChanged:state.projectview.IsTabChanged
      };
  };
  const mapDispatchToProps = () => {
    return {
    };
};
export default connect( mapStateToProps, mapDispatchToProps )(StepperConfigureTrain);
