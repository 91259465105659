import React,{useRef} from 'react';
import { Row, Button } from 'react-bootstrap';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import * as actions from '../../../store/actions/index';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { connect } from 'react-redux';
import moment from 'moment';
import SelectCustom from "../../../components/UIComponents/SelectDropdown/SelectComponent";
import DatetimeRangePicker from '../../../components/UI/DatetimeRangePicker';
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import './UserDescription.css';
import ChartDataLabels from "chartjs-plugin-datalabels";
import HorizontalBarChart from '../../../components/UI/Charts/HorizontalBarChart';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Line } from 'react-chartjs-2';
import MetricCard from './MetricCard';
import { number } from 'prop-types';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

ChartJS.defaults.borderColor = "#b8b6b6";
//const chartRef = useRef<'bar'>(null);
const allProj = 'All Projects';
class AgentHandover extends React.Component {


  constructor(props) {
    super(props);
    this.chartReference = React.createRef(null);
    this.chartObject = null;
    let projectName = "All Projects"
    let reportDetail = this.props.reportDetail
    let startDate = this.props.startDate?this.props.startDate:moment().subtract(6, 'days');
    let endDate = this.props.endDate?this.props.endDate:moment();
    if (reportDetail != null) {
      startDate = moment(reportDetail.startDate)
      endDate = moment(reportDetail.endDate)
      projectName = reportDetail.project
    }
    this.state = {
      selectedCal: '',
      projectname: projectName,
      searchT: "",
      popperShow: false,
      botname: '',
      startDate: startDate,
      endDate: endDate,
      metricsOptions: [
        { value: "User Metrics - Total Users", key: "totaluser" },
        { value: "User Metrics - New Users", key: "newuser" },
        { value: "User Metrics - Repeat Users", key: "repeatuser" },
        { value: "Conversation Metrics - Chat Sessions", key: "chat" },
        { value: "Conversation Metrics - Flow Initiated", key: "flows" },
        { value: "Conversation Metrics - Feedback", key: "feedback" },
        { value: "Conversation Metrics - Agent Handovers", key: "agent" },
        { value: "Conversation Metrics - Unanswered", key: "unanswered" },
        { value: "Conversation Metrics - Meetings Scheduled", key: "meetings" }
      ],
     // selectedMetrics: (location && location[1]) ? location[1] : '',
      SelectedFlowList: [],
      SelectedCumulative: 'cumulative',
      //chartRefff: currChart
      agentCatHeading:'',
      topUnansHeading:''
    }
  }
  calAPI(val) {
    let apiURL = this.props.config.BOTBUILDER_URL;
    let details = this.props.reportDetail;
    let projName = this.state.projectname
    if(details==null){
     details={
      "startDate": moment(this.state.startDate).format('YYYY-MM-DD'),
      "endDate": moment(this.state.endDate).format('YYYY-MM-DD'),
      "project": projName=='common'?allProj:projName,
      "orgName": localStorage.getItem('orgName'),     }
    }

    this.props.totalAgentTransforms(apiURL, details)
    this.props.totalAgentReport(apiURL, details)
    this.props.TotalAgentTransferCat(apiURL, details)
    this.props.TopAgentTransferData(apiURL, details)
    //this.props.AgentTransUtterances(apiURL, details)
    
    setTimeout(() => {
      let AgentTransferCat =this.props.AgentTransferCat
      if(AgentTransferCat.length>0){
        if(AgentTransferCat[0].responseData && AgentTransferCat[0].responseData.length>0){
          let agentTransCat = AgentTransferCat[0].responseData[0]
        let sortable = []
        for (let cat in agentTransCat) {
          sortable.push([cat, agentTransCat[cat]]);
        }  
        sortable = sortable.sort((a, b)=> b[1] - a[1]);
        console.log(sortable,"sortable sortable sortable")
        let agentHeading = sortable.length!=0? sortable[0][0]:[]
        this.setState({ agentCatHeading:agentHeading })
        let msgDetails = details;
        msgDetails.message = agentHeading.length!=0?agentHeading.trim():''
        this.props.AgentTransUtterances(apiURL, msgDetails)
      }
    }
    }, 1000);
  }

  componentDidMount() {
   // let location = this.props.location.search;
   let details = this.props.reportDetail;
    this.calAPI()
    
   
  }

  componentDidUpdate(prevProps) {
    if (prevProps.reportDetail !== this.props.reportDetail) {

     // let location = this.props.location.search;
      this.calAPI()
     

    }
  }

  setSearchTerm = (item) => {
    this.setState({ searchT: item })
  }

  popperShowFn = () => {
    this.setState({
      popperShow: true,
    })
  }


  backToReportPage = () => {
    this.props.history.push('/ConversationMetrics?back')
  }

  handleCalenderChange = (e, val) => {
    this.setState({ selectedCal: val })
  }
  onProjectDropDown = (event) => {
    this.setState({
      selectedFlow: event.target.value,
    })
    let projName = this.state.projectname
    let data = {
      "startDate": moment(this.props.reportDetail.startDate).utcOffset("+05:30").format('YYYY-MM-DD'),
      "endDate": moment(this.props.reportDetail.endDate).utcOffset("+05:30").format('YYYY-MM-DD'),
      "project": projName=='common'?allProj:projName,
      "orgName": localStorage.getItem('orgName'),
    }
    this.props.ReportPageData(data)

  }


  topMsgCatEvent=(evt,element,msg)=>{
    if(element.length > 0)
      {
       let msgDetails = this.props.reportDetail;
       msgDetails.message = msg.trim()
       this.props.AgentTransUtterances(this.props.config.BOTBUILDER_URL, msgDetails)
       this.setState({
        agentCatHeading:msg
      })
      }
    }



  render() {
  let agentTranforms = this.props.agentTranforms
  let verMapLabels = [], agentSuccess  = [], agentUnsuccess =[], totanAnsUnans=[];
  const uti = (val,datatype) =>{    
      let sorted_data = Object.keys(val).sort().reduce((obj, key) => { 
              obj[key] = val[key];
              return obj;
          }, {});
        verMapLabels=Object.keys(sorted_data);
        if(datatype=='DAILY'){
          verMapLabels = verMapLabels.map(data=>moment(data).format('D-MMM'))
        }
      Object.keys(sorted_data).forEach((valll)=>{
        console.log(sorted_data[valll])
        let answered = sorted_data[valll][0].split(":") ,unanswerd = sorted_data[valll][1].split(":");
        agentSuccess.push(answered[1])
        agentUnsuccess.push(unanswerd[1])
      //  totanAnsUnans.push(parseInt(answered)+parseInt(unanswerd))
      })
  }
  let AgentTransferReport = this.props.AgentTransferReport;
  let lineChartData = {}
  let parseConv = (AgentTransferReport)?JSON.parse(AgentTransferReport):[];

  
  let weekly =false
  parseConv && parseConv.map((val, k) => {
    if (Object.keys(val).length != 0) {
      
      Object.keys(val).forEach((innerval) => {
        let checkInnerVal = Object.keys(val[innerval]).length
        if(innerval=='MONTHLY'&&checkInnerVal!=0&&weekly==false){
          uti(val[innerval],innerval)
          lineChartData = val[innerval]
          
        }else if(innerval=='WEEKLY'&&checkInnerVal!=0&&checkInnerVal<=8){
          uti(val[innerval],innerval)
          lineChartData = val[innerval]
          weekly =true
        }
        else if(innerval=='DAILY'&&checkInnerVal!=0) {
          uti(val[innerval],innerval)
          lineChartData = val[innerval]
        }
      })
    }
  });
    let options = {
      plugins: {
        legend: {
          display: false
        },
        datalabels: {
          display: true,
          color: "#fff",
          align: 'center',
          formatter: function(value, context) {
            return value==0?'':value;
          },
          font: {
            size: 12,
            family: 'Roboto-Bold',
          }
        }
      },
      responsive: true,
       scales: {
          x: {
            grid: {
              display: false,
              drawOnChartArea: false,
            },
            ticks: {
              precision: 0
          },
            stacked:true
          },
          y: {
            grid: {
              display: true,
              drawOnChartArea: true,
              drawBorder: false
            },
            ticks: {
              precision: 0
          },
            stacked:true
          },
      },
    };

    let data = {
      labels:verMapLabels,
      datasets: [
        {
          label: 'Agent Handover Successfull',
          data: agentSuccess,
          backgroundColor: '#0e770b',
        },
        {
          label: 'Agent Handover unSuccessfull',
          data: agentUnsuccess,
          backgroundColor: '#e73b3b',
        }
      ],
    };

    let dataSec = {
      labels:verMapLabels,
      datasets: [
        {
          label: 'Agent Handover Successfull',
          data: [10,20,5,30,25],
          backgroundColor: '#aeac28',
        }
      ],
    };
    
    

    


  
   
    let AgentTransferCat = this.props.AgentTransferCat;
    let agentTransCatKeys = [], agentTransCatVals = [];
    if(AgentTransferCat && AgentTransferCat.length>0){
      if(AgentTransferCat[0].responseData && AgentTransferCat[0].responseData.length>0){
        let agentTransCat = AgentTransferCat[0].responseData[0]
        let sortable = [];
          for (let cat in agentTransCat) {
              sortable.push([cat, agentTransCat[cat]]);
          }  
          sortable.sort((a, b)=> b[1] - a[1]);
          sortable = (sortable.length>=7)?sortable.slice(0, 7):sortable.slice(0, sortable.length)
          sortable.forEach(element => {
            agentTransCatVals.push(element[1]);
            agentTransCatKeys.push(element[0]);
          }); 
      } 

    }
    let agentUtterances = this.props.AgentTransferUtterances;
      let agentTransUtterTitle =[] ,agentTransUtterVals = [], agentTransCount = 0;
     if(agentUtterances) agentUtterances =JSON.parse(agentUtterances)
     agentUtterances && agentUtterances.map((ele)=>{
      agentTransUtterTitle = Object.keys(ele)
      agentTransUtterVals = Object.values(ele)
        agentTransCount = Object.values(ele).reduce((a, b) => parseInt(a) + parseInt(b), 0)
      })

      let bgColorMsg = [];
      if(this.state.agentCatHeading){
        agentTransCatKeys.forEach((element,i) => {
          if(agentTransCatKeys.indexOf(this.state.agentCatHeading) ==i) bgColorMsg.push('#aeac28')
          else bgColorMsg.push('rgb(232,229,229)')
        });
      }

      /****************TOP AGENT TRANSFER ******************** */
      let TopAgentTransfer = this.props.TopAgentTransfer;
      let TopAgentTransKeys = [], TopAgentTransVals = [];
      if(TopAgentTransfer && TopAgentTransfer.length>0){
        if(TopAgentTransfer[0].responseData && TopAgentTransfer[0].responseData.length>0){
          let agentTransCat = TopAgentTransfer[0].responseData[0]
          let sortable = [];
            for (let cat in agentTransCat) {
                sortable.push([cat, agentTransCat[cat]]);
            }  
            sortable.sort((a, b)=> b[1] - a[1]);
            sortable = (sortable.length>=7)?sortable.slice(0, 7):sortable.slice(0, sortable.length)
            sortable.forEach(element => {
              TopAgentTransVals.push(element[1]);
              TopAgentTransKeys.push(element[0]);
            }); 
        } 
  
      }

    return (
      
      <>
      <div className='d-flex mb-3'>
          <div className='detail-card'>
              <MetricCard
                  heading={"Agent Handover"}
                  count={agentTranforms}
                  icon={"./Icons/agenthandovericon1.svg"}
                  backgroundColor={"#aeac28"}
                  isLeftBorder={false}
              />
          </div>
          <div className='detail-card agenthandoverSec-card'>
              <MetricCard
                  heading={"Agent Handover Average Waiting Time"}
                  count="1 sec"
                  icon={"./Icons/agenthandovericon.svg"}
                  backgroundColor={"#aeac28"}
                  isLeftBorder={false}
              />
          </div>

      </div>
      <div className='col-md-6'></div>
      <div className='row'>
      <div className='col-md-6 conversation_handled'>
         
         <div className="handledGraphContainer">
           <h4 className='chartTitle'>Agent Handovers Performance</h4>
           <div className='agentHandoverConver'>
             <span className='agentSuccesslabel'>Agent Handover Successful</span>
             <span className='agentUnsuccesslabel'>Agent Handover unSuccessful</span>
           </div>
           <Bar options={options} data={data} plugins={[ChartDataLabels]}/>
         </div>
     </div>
     <div className='col-md-6 conversation_handled'>
       
         <div className="handledGraphContainer">
         <h4 className='chartTitle pb-4'>Conversation- Time Based</h4>
         <Bar options={options} data={dataSec} plugins={[ChartDataLabels]}/>
         </div>
     </div>
     <div className='col-md-6 conversation_handled'>
     
         <div className="handledGraphContainer">
         <h4 className='chartTitle '>Top Agent Handover Categories</h4>
      <HorizontalBarChart labels={agentTransCatKeys} dataHorBar={agentTransCatVals} bgColor={bgColorMsg} clickEvent={this.topMsgCatEvent} plugins={[ChartDataLabels]} selected={this.state.agentCatHeading} seltextColor="#fff"/>
         </div>
     </div>
     <div className='col-md-6 conversation_handled'>
     <div className='payments-div'>
         <div className='payments-header agenthandover-header1'>{this.state.agentCatHeading} ({agentTransCount})</div>
         <div className='payments-data-wrapper scroll_payment_div'>
           {agentTransUtterTitle && agentTransUtterTitle.map((element,key)=>
             <div className="d-flex justify-content-between">
                 <div>{element}</div>
                 <div>{agentTransUtterVals[key]}</div>
             </div>
           )

           }
         </div>
         </div>
     </div>
     <div className='col-md-6 conversation_handled'>
     
         <div className="handledGraphContainer">
         <h4 className='chartTitle'>Top Agents</h4>

         </div>
     </div>
     <div className='col-md-6 conversation_handled'>
         <div className='payments-div'>
         <div className='payments-header agenthandover-header2'></div>
         <div className='payments-data-wrapper'>
           { /*unUnsTitle && unUnsTitle.map((element,key)=>
             <div className="d-flex justify-content-between">
                 <div>{element}</div>
                 <div>{unUnsVals[key]}</div>
             </div>
           )*/

           }
         </div>
         </div>
     </div>
      </div>
      
  </>
     
    )
  }
}

const mapStateToProps = state => {
  return {
    projectList: state.user.projects,
    totalUsersReportData: state.reportDashboard.totalUsersReport,
    RepeatUserReport: state.reportDashboard.RepeatUserReport,
    agentTranforms: state.reportDashboard.totalAgentTransforms,
    reportDetail: state.reportDashboard.reportDetail,
    AgentTransferReport: state.reportDashboard.totalAgentTransferReport,
    AgentTransferCat: state.reportDashboard.AgentTransferCat,
    AgentTransferUtterances: state.reportDashboard.AgentTransferUtterances,
    TopAgentTransfer: state.reportDashboard.TopAgentTransfer
  }
}
    
const mapDispatchToProps = dispatch => {
  return {
    totalAgentTransforms: (apiURL, data) => dispatch(actions.totalAgentTransforms(apiURL, data)),
    totalAgentReport: (apiUrl, data) => dispatch(actions.totalAgentTransferReport(apiUrl, data)),
    onchangeProjectAction: (projectname) => dispatch(actions.changeProjectAction(projectname)),
    ReportPageData: (data) => dispatch(actions.ReportPageData(data)),
    AgentTransUtterances: (apiUrl, data) => dispatch(actions.AgentTransUtterances(apiUrl, data)),
    TotalAgentTransferCat: (apiUrl, data) => dispatch(actions.TotalAgentTransferCat(apiUrl, data)),
    TopAgentTransferData: (apiUrl, data) => dispatch(actions.TopAgentTransferData(apiUrl, data))    
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AgentHandover);

