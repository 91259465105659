import React, { useState } from 'react';
import { Col, Form } from "react-bootstrap";
import './Drawer.css';
import ButtonCustom from "../../../../components/UIComponents/Button/ButtonComponent";
import ReactSelectComponent from "../../../SettingPage/SettingInnerPages/components/ReactSelectComponent/ReactSelectComponent";
import InputWithLabelComponent from "../../../SettingPage/SettingInnerPages/components/InputWithLabelComponent/InputWithLabelComponent";


const MediaNodeDetails = ({node, invalidVarMsg, variable, handleExternalSubmit}) => {

    const mediaList = [{value:'Image',label:'Image'},{value:'Media',label:'Video'}];
    const [mediatype, setMediatype] = useState(node.data?.mediatype?node.data.mediatype:'Select');
    const [url, setUrl] = useState(node.data.url);
    const [message, setMessage] = useState(node.data.message);
    const alignOptions = [{value:'Top Align',label:'Top Align'},{value:'Bottom Align',label:'Bottom Align'}];
    const [alignment, setAlignment] = useState(node.data?.alignment?node.data.alignment:'Top Align');
    const [addToLog, setAddToLog] = useState(node.data.addToLog!==undefined? node.data.addToLog:true);

    const isFormValid = () => {
        return variable && !invalidVarMsg && url && mediatype !== 'Select';
    }

    const saveData = () => {
        let mediaData = {
            mediatype: mediatype,
            url: url,
            message: message,
            alignment: alignment
        };
        handleExternalSubmit('media', {mediaData,addToLog});
    }

    const getAlignment = (value) => {
        return alignOptions.find((item)=>item.value===value);
    }

    const getMediaType = (value) => {
        return mediaList.find((item)=>item.value===value);
    }

    return (
        <>
            <div className="mt-1">
            <Col md={12}>
                <div className="common-input">
                <Form.Label className="mb-1 text-box-title adding-other-input">Media type*</Form.Label>
                </div>
                <ReactSelectComponent
                    customClassName="SelectView"
                    value={getMediaType(mediatype)}
                    options={mediaList}
                    name="media"
                    placeholder={'Select'}
                    onChange={(event) => setMediatype(event.value)}
                />
            </Col>
            </div>
            <div className="mt-1">
            <Col md={12}>
                <InputWithLabelComponent
                    type="text"
                    name="url"
                    label="URL*"
                    value={url}
                    readOnly={false}
                    onChange={(e) => setUrl(e.target.value)}
                    required={true}
                    placeHolder='Enter URL'
                />
            </Col>
            </div>
            <div className="mt-1">
            <Col md={12}>
                <InputWithLabelComponent
                    type="text"
                    name="url"
                    label="Text Message"
                    value={message}
                    readOnly={false}
                    onChange={(e) => setMessage(e.target.value)}
                    required={true}
                />
            </Col>
            </div>
            <div className="mt-1">
                <Col md={12}>
                  <div className="common-input">
                    <Form.Label className="mb-1 text-box-title adding-other-input">Text Message Alignment</Form.Label>
                  </div>
                  <ReactSelectComponent
                        customClassName="SelectView"
                        value={getAlignment(alignment)}
                        options={alignOptions}
                        name="flow"
                        placeholder={'Select'}
                        onChange={(event) => setAlignment(event.value)}
                  />
                </Col>
            </div>

            <div className="mt-1"  id="checkInCdNode">
                <Col md={12}>
                <Form>
                    <Form.Check 
                    type="checkbox"
                    id={`addToLog`+variable}
                    label='Capture as conversation log'
                    checked = {addToLog}
                    onChange={e=>setAddToLog(e.target.checked)}
                    />
                </Form>
                </Col>
            </div>

            <br/>
            <div className="mt-1">
            <Col md={12}>
                <div className="mt-1">
                    <ButtonCustom variant='contained' label='Save'className='buttonWidth' disabled={!isFormValid()} clicked={saveData}/>
                </div>
            </Col>
            </div>
              
        </>
    )
}

export default MediaNodeDetails;