import { isEqual } from "lodash";

const SearchSymptomAndRootcause = (data, type, event, method) => { 

      if(type==="symptoms"){
        let arrayOfMatchedObjects = data.filter(object => {
          return JSON.stringify(object.symptoms)
            .toString()
            .toLowerCase()
            .includes(event.target.value.trim().toLowerCase());
        });
        return arrayOfMatchedObjects;
      }
      if(type==="rootcause"){
        let arrayOfMatchedObjects = data.filter(object => {

          return JSON.stringify(object.rootcause)
            .toString()
            .toLowerCase()
            .includes(event.target.value.trim().toLowerCase());
        });
        return arrayOfMatchedObjects;
      }

}



export default SearchSymptomAndRootcause;