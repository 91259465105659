// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.manage-master-card {
    background: #FFFFFF;
    box-shadow: 0px 1px 5px 3px #E0E4E7;
    cursor: pointer;
    margin: 15px;
    min-height: 95px;
}
.product-content h5{
    font-family: "Roboto-Bold";
    font-size: 14px;
    line-height: 16px;
    color: #3D3A3A;
}
.source-content h6, .attribute-content h6 {
    font-family: "Roboto-Bold";
    font-size: 12px;
    line-height: 13px;    
    color:#3D3A3A;
}
.product-content-details,
.source-content-details,
 .attribute-content-details {
   font-family: "Roboto-Regular";
   font-weight: 300;
   font-size: 12px;
   line-height: 14px;
   color: #000
}
.save-button, .modal-button, .data-button {
    width: 147px;
    height: 37px;
    background: #B8BEC3;
    border-radius: 4px;
    transform: rotate(0.14deg);
    font-family: "Roboto-Bold";
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */
    font-variant: small-caps;
    color: #FFFFFF;
    border: 0;
}
.trigae-configuration-page .error.error-msg {
    text-align: right;
    margin-right: 20px;
    margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/containers/SettingPage/SettingInnerPages/Insight/AdminConfiguration/TraiageConfigurationTabs/ManageMasterList/manage-master-card.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,mCAAmC;IACnC,eAAe;IACf,YAAY;IACZ,gBAAgB;AACpB;AACA;IACI,0BAA0B;IAC1B,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;AACA;IACI,0BAA0B;IAC1B,eAAe;IACf,iBAAiB;IACjB,aAAa;AACjB;AACA;;;GAGG,6BAA6B;GAC7B,gBAAgB;GAChB,eAAe;GACf,iBAAiB;GACjB;AACH;AACA;IACI,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;IAClB,0BAA0B;IAC1B,0BAA0B;IAC1B,eAAe;IACf,iBAAiB;IACjB,4BAA4B;IAC5B,wBAAwB;IACxB,cAAc;IACd,SAAS;AACb;AACA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;AACpB","sourcesContent":[".manage-master-card {\n    background: #FFFFFF;\n    box-shadow: 0px 1px 5px 3px #E0E4E7;\n    cursor: pointer;\n    margin: 15px;\n    min-height: 95px;\n}\n.product-content h5{\n    font-family: \"Roboto-Bold\";\n    font-size: 14px;\n    line-height: 16px;\n    color: #3D3A3A;\n}\n.source-content h6, .attribute-content h6 {\n    font-family: \"Roboto-Bold\";\n    font-size: 12px;\n    line-height: 13px;    \n    color:#3D3A3A;\n}\n.product-content-details,\n.source-content-details,\n .attribute-content-details {\n   font-family: \"Roboto-Regular\";\n   font-weight: 300;\n   font-size: 12px;\n   line-height: 14px;\n   color: #000\n}\n.save-button, .modal-button, .data-button {\n    width: 147px;\n    height: 37px;\n    background: #B8BEC3;\n    border-radius: 4px;\n    transform: rotate(0.14deg);\n    font-family: \"Roboto-Bold\";\n    font-size: 16px;\n    line-height: 19px;\n    /* identical to box height */\n    font-variant: small-caps;\n    color: #FFFFFF;\n    border: 0;\n}\n.trigae-configuration-page .error.error-msg {\n    text-align: right;\n    margin-right: 20px;\n    margin-top: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
