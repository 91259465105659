import React, { useState, useEffect } from "react";
import Drawer from "@material-ui/core/Drawer";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import "./UserRoles.css";
import { Col, Row } from "react-bootstrap";
import DrawerHeaderContent from "../../components/DrawerHeaderContent/DrawerHeaderContent";
import { useDispatch, useSelector } from "react-redux";
import PrimaryButtonWithLoader from "../../components/PrimaryButtonWithLoader/PrimaryButtonWithLoader";
import Heading5 from "../../components/Typography/Heading5/Heading5";
import {PostCallToOUServer} from "../helpers/utility";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import TextAreaWithLabelComponent from "../../components/TextAreaWithLabelComponent/TextAreaWithLabelComponent";
import { isEqual } from "lodash";
import {SetErrorMessage,SetSuccessMessage} from "../../../../../store/actions";

const useStyles = makeStyles({
  list: {
    width: 600,
  },
});

const DuplicateRoleForm = ({
  config,
  showDrawer,
  toggleDrawer,
  updateRoles,
  roleheading,
  buttonText,
  data,
  closeDrawer,
  systemPermissions,
  
}) => {
  const userPermissions = useSelector((state) => {
    return state.user.permissionList;
  });

  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const orgname = localStorage.getItem("orgName");
  const [formData, setFormData] = useState({
    orgname: orgname,
    rolename: !isEqual(data.rolename, undefined) ? data.rolename + "_copy" : "",
    permissions: data.permissions,
    description: !isEqual(data.description, undefined) ? data.description : "",
  });
  const [permissionsObject, setPermissionsObject] = useState();

  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    getSelectedPermissions();
    createDataObject(systemPermissions);
  }, []);

  const createDataObject = (permissionList) => {
    let dataObject = [];
    let catList = [];
    permissionList.map((data) => {
      if (catList.includes(data.category)) {
        dataObject.forEach((obj) => {
          if (obj.category === data.category) {
            obj.permissions.push({
              code: data.permission_code,
              name: data.permission,
              checked: false,
            });
            obj.count += 1;
          }
          return null;
        });
      } else {
        let object = {
          category: data.category,
          permissions: [
            {
              code: data.permission_code,
              name: data.permission,
              checked: false,
            },
          ],
          count: 1,
          selectedCount: 0,
        };
        catList.push(data.category);
        dataObject.push(object);
      }
    });

    setPermissionsObject(dataObject);
    updatePermissionObject(dataObject);
  };

  const updatePermissionObject = (dataObj) => {
    selectedPermissions.map((code) => {
      dataObj.map((item) => {
        item.permissions.map((data) => {
          if (data.code === code) {
            data.checked = true;
            item.selectedCount += 1;
          }
        });
      });
    });
  };

  const getSelectedPermissions = () => {
    data.permissions.map((item) => {
      setSelectedPermissions((prevItems) => [
        ...prevItems,
        item.permission_code,
      ]);
    });
  };

  function ChangePermission(e, category) {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedPermissions((prevItems) => [...prevItems, value]);
    } else {
      setSelectedPermissions(
        selectedPermissions.filter((item) => item !== value)
      );
    }
    ChangePermissionObject(category, value);
  }

  function ChangePermissionObject(category, code) {
    let dataObj = permissionsObject;
    dataObj.map((obj) => {
      if (obj.category === category) {
        obj.permissions.map((item) => {
          if (item.code === code) {
            item.checked = !item.checked;
            if (item.checked) obj.selectedCount += 1;
            else obj.selectedCount -= 1;
          }
        });
      }
    });
    setPermissionsObject(dataObj);
  }

  useEffect(() => {
    setFormData((formData) => ({
      ...formData,
      permissions: selectedPermissions,
    }));
  }, [selectedPermissions]);

  useEffect(() => {
    updatePermissionObject(permissionsObject);
  }, [permissionsObject]);

  const onChangeHandler = (event, type) => {
    setFormData((formData) => ({
      ...formData,
      [type]: event.target.value,
    }));
  };

  const [activeId, setActiveId] = useState("0");

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  const hasPermission = (code) => {
    if (userPermissions.find((data) => data.permission_code.includes(code)))
      return true;
    else return false;
  };

  function getAccessIcon(data, type) {
    if (data.count === data.selectedCount)
      return type === "img" ? "./Icons/FullAccess.svg" : "Full Access";
    else if (data.selectedCount === 0)
      return type === "img" ? "./Icons/NoAccess.svg" : "No Access";
    else return type === "img" ? "" : "Partial Access";
  }

  const updateRole = (e) => {
    e.preventDefault();
    const url = config.COMMON_API_URL + `/organization/role`;
    PostCallToOUServer(url, formData).then((response) => {
      if (response.status == 201) {
        setTimeout(() => {
          dispatch(SetSuccessMessage("Duplicate Role is created successfully"));
        }, 750);
        closeDrawer();
        toggleDrawer(true);
        updateRoles();
      } else if (response?.response?.status === 400) {
        setErrorMsg("Please choose Roles and Projects.");
      } else if (response.response.status == 409) {
        setErrorMsg("Role name already exists.");
        dispatch(SetErrorMessage("Role name already exists!"));
      } else {
        dispatch(SetErrorMessage("Something went wrong!"));
      }
    });
  };

  return (
    <React.Fragment>
      {showDrawer ? (
        <Drawer
          anchor={"right"}
          open={showDrawer}
          onClose={toggleDrawer(false)}
        >
          <div
            className={clsx(classes.list, classes.fullList)}
            role="presentation"
          >
            <DrawerHeaderContent
              heading={roleheading}
              toggleDrawer={toggleDrawer}
            />{" "}
            <br />
            <div className="d-flex flex-column Symtom-Item highlights manage-role-form-details">
                <Row className="">
                  <Col md={12} className="d-flex justify-content-between">
                    <div className="d-flex flex-column">
                      <label className="label-title">Role Name</label>
                      <label className="label-text">{formData.rolename}</label>
                    </div>
                    <TextAreaWithLabelComponent
                      label="Description"
                      name="description"
                      required={true}
                      value={formData.description}
                      placeholder={"Enter description"}
                      onChange={(e) => onChangeHandler(e, "description")}
                    />
                  </Col>
                </Row>
            </div>
            <div className="d-flex flex-column Symtom-Item ">
              <div className="mt-4">
                <div className="d-flex flex-column ">
                  <Heading5
                    className="text-capitalize"
                    text="Manage Permissions"
                  />
                </div>
                <Row>
                  <Col md={12}>
                    {systemPermissions && systemPermissions.length > 0 && (
                      <Accordion defaultActiveKey="0">
                        {permissionsObject &&
                          permissionsObject.map((data) => {
                            return (
                              <div
                                key={data.category}
                                className={
                                  activeId === data.category
                                    ? "panel-wrap active-panel"
                                    : "panel-wrap"
                                }
                              >
                                <div className="panel-header">
                                  <Accordion.Toggle
                                    as={Button}
                                    variant="text"
                                    eventKey={data.category}
                                    onClick={() => toggleActive(data.category)}
                                    className="panel-toggle"
                                  >
                                    <div>
                                      <Row className="category-header">
                                        <Col ms={6}>
                                          <div className="panel-title-roledetails">
                                            <img
                                              src={
                                                activeId === data.category
                                                  ? "./Icons/Minus.svg"
                                                  : "./Icons/Plus.svg"
                                              }
                                            ></img>
                                            <span className="category-title">
                                              {data.category}
                                            </span>
                                          </div>
                                        </Col>
                                        <Col ms={6}>
                                          <div className="category-status">
                                            <img
                                              src={getAccessIcon(data, "img")}
                                              className=""
                                            ></img>
                                            {getAccessIcon(data, "text")}
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Accordion.Toggle>
                                </div>
                                <Accordion.Collapse eventKey={data.category}>
                                  <div className="panel-body-role">
                                    <ul>
                                      {data.permissions.map((item) => {
                                        return (
                                          <li
                                            key={item.code}
                                            className="accordian-list"
                                          >
                                            <input
                                              type="checkbox"
                                              checked={item.checked}
                                              name={item.code}
                                              value={item.code}
                                              onChange={(e) =>
                                                ChangePermission(
                                                  e,
                                                  data.category
                                                )
                                              }
                                            />
                                            <span className="accordian-listname">
                                              {item.name}
                                            </span>
                                          </li>
                                        );

                                        //   }
                                      })}
                                    </ul>
                                  </div>
                                </Accordion.Collapse>
                              </div>
                            );
                          })}
                      </Accordion>
                    )}
                  </Col>
                </Row>
              </div>
            </div>
            {hasPermission && (
              <div className="d-flex flex-column Symtom-Item">
                <div className="">
                  <PrimaryButtonWithLoader
                    size="lg"
                    type="submit"
                    text={buttonText}
                    loading={false}
                    onClick={updateRole}
                  />
                </div>
                <span className="error-msg">{errorMsg}</span>
              </div>
            )}
          </div>
        </Drawer>
      ) : null}
    </React.Fragment>
  );
};

export default DuplicateRoleForm;
