import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { Col, Spinner, Row, Form } from "react-bootstrap";
import ".././triage-configuration-tabs.css";
import ReactSelectComponent from "../../../../components/ReactSelectComponent/ReactSelectComponent";
import { useDispatch, useSelector } from "react-redux";
import symptomRootCauseDataChangeHandler from "../../../../../../../store/actions/adminSettings/symptomRootCauseDataChangeHandler";
import SymptomRootCauseTabs from "./SymptomRootCauseTabs";
import RootCauseSymptomTabs from "./RootCauseSymptomTabs";
import { GetCalltoServer, PostCalltoServer } from "../../../../../../../store/utility";
import { isEmpty, isEqual } from "lodash";
import getProjectDetails from "../../../../helpers/getProjectDetails";
import Filters from "../../Filters/Filters";
import advanceFilterChangeHandlerAction from "../../../../../../../store/actions/agentInsights/advanceFilterChangeHandlerAction";
import AddSymptomsDetails from "../../SymtomsAndRootCauseTab/AddSymptomsDetails";
import { getSymptomsDataAction,getRootCausesDataAction } from "../../../../../../../store/actions/adminSettings/adminSettingsActions";
import AddRootCausesDetails from "../../SymtomsAndRootCauseTab/AddRootcauseDetails";
import { SetSuccessMessage } from "../../../../../../../store/actions/message";
import SpinnerPage from "../../../../../../../components/UI/Loader/Loader";
import { Tooltip, withStyles } from '@material-ui/core';
import { event } from "jquery";


const SymptomRootCauseMapping = ({ viewingOptions, config, showDetailsTab, active,permissions }) => {
  const symptomsData = useSelector((state) => {
    return state.adminSettings.symptomsTabData.symptomData;
  });
  const rootCausesData = useSelector((state) => {
    return state.adminSettings.rootCausesTabData.rootCausesData;
  });
  const [isInvalid, setInValid] = useState(false);
  const [checked, setChecked] = useState(false);
  const [formData, setFormData] = useState({
    symptoms: '',
    leadingQuestion: '',
    relevancy: '',
    status: '',
    rootcause: ''
  });
  const changeHandler = (event) => {
    setChecked(event.target.checked);
    console.log("setChecked",checked)
  };
 

  const appliedFilters = useSelector(state => state.agentInsights.appliedFilters);
  const dispatch = useDispatch();
  const [showFilterDrawer, setShowFilterDrawer] = useState(false);
  const [viewOption, setViewOption] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [advanceFilters, setAdvanceFilters] =useState({});
  const [filterSymptomData, setFilterSymtomData] = useState([]);
  const [filterRootCauseData, setFilterRootCauseData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [selectedRootCause, SetSelectedRootCause] = useState('');
  const [selectedSymptom, SetSelectedSymptom] = useState('');
  const [lastSelectedRootCause, setLastSelectedRootCause] = useState('')
  const [lastSelectedSymptom, setLastSelectedSymptom] = useState('')
  const [value, setValue] = useState(["product-p1","yom-y1","model-x12","more"]);
  const ProjectView = useSelector(state => state.projectview.IsProjectChange);
  
  let toolTipMsg = {
    "1" : "Adiing New Root Cause",
    "2" : "Adding New Symptom"
  }
  let event = {
    value : "1"
  }

  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 12,
      fontFamily: "Roboto-Regular",
    },
    arrow: {
      color: theme.palette.common.white,
      "&:before": {
        border: "1px solid #E6E8ED",
        boxShadow: theme.shadows[1],
      },
    },
  }))(Tooltip);
  
  const toggleDrawer = (value, index) => (event) => {
    if (
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
    ) {
        return;
    }
    setShowDrawer(value);
};
const toggleShowFilterDrawer = (value) => (event) => {
  if (
    event.type === "keydown" &&
    (event.key === "Tab" || event.key === "Shift")
  ) {
    return;
  }
  setShowFilterDrawer(value);
};
  const symptomRootCauseData = useSelector(
    (state) => state.adminSettings.symptomRootCauseData
  );
  const viewChangeHandler = (event) => {
    
    setViewOption(event);
    if (event.value === "1") {
      setLastSelectedRootCause('')
      const projectName = getProjectDetails();
      const url = `${config.INSIGHTS_API_URL}/product-intelligent-triage/settings/rootcausesymptomsmappinglist/project/${projectName}`;
      setLoading(true);
      PostCalltoServer(url, advanceFilters)
        .then((response) => {
          if (get(response, "data.status.responseType", "") === "success") {
            const data = JSON.parse(get(response, "data.data", []));
            dispatch(symptomRootCauseDataChangeHandler(data));
            setLoading(false);
          } else {
            dispatch(symptomRootCauseDataChangeHandler([]));
            setLoading(false);
          }
        })
        .catch((err) => {
          dispatch(symptomRootCauseDataChangeHandler([]));
          setLoading(false);
        });
    }
    if (event.value === "2") {
      setLastSelectedSymptom('')
      const projectName = getProjectDetails();
      const url = `${config.INSIGHTS_API_URL}/product-intelligent-triage/settings/symptomsrootcausemappinglist/project/${projectName}`;
      setLoading(true);
      PostCalltoServer(url, advanceFilters)
        .then((response) => {
          if (get(response, "data.status.responseType", "") === "success") {
            const data = JSON.parse(get(response, "data.data", []));
            setLoading(false);
            dispatch(symptomRootCauseDataChangeHandler(data));
          } else {
            dispatch(symptomRootCauseDataChangeHandler([]));
            setLoading(false);
          }
        })
        .catch((err) => {
          dispatch(symptomRootCauseDataChangeHandler([]));
          setLoading(false);
        });
    }
  };

  const clearFilters = () => {
    appliedFilters.map((value, i) => {
      dispatch(advanceFilterChangeHandlerAction(value));
  })
  }

  // useEffect(() => {
  //   setAdvanceFilters({ })
  //   clearFilters();
  // },[viewOption])

  useEffect(() => {
    if (isEmpty(viewOption)) {
      let event = {
        value: "1",
        label: "Root Causes -> Symptoms",
      };
      viewChangeHandler(event);
    }
  }, [ProjectView]);

  const filterHandler = () => {
    setShowFilterDrawer(true);
   }

  const onRemoveItem = (item) => {
    const removedFilter = item.split('-');
    resultsUpdate(removedFilter[0], removedFilter[1]);
    dispatch(advanceFilterChangeHandlerAction(item));
  }

  const resultsUpdate = (attribute, data) => {
   
    if(attribute in advanceFilters){
      if(isEqual((advanceFilters[attribute][advanceFilters[attribute].length - 1]),data)){
        delete advanceFilters[attribute]
        if(isEmpty(advanceFilters))
          setAdvanceFilters({ })
        else
          setAdvanceFilters({ ...advanceFilters })
      } else{
        setAdvanceFilters({
          ...advanceFilters,
           [attribute] : (advanceFilters[attribute]).includes(data) ? [...(advanceFilters[attribute]).filter((item, i) => item !== data)] :[...(advanceFilters[attribute]),data]
           })
      }
    }else
      setAdvanceFilters({ ...advanceFilters, [attribute] : [data] })
  }

  useEffect(() => {
    if(!isEmpty(advanceFilters))
      viewChangeHandler(viewOption);
    if(isEmpty(viewOption)) {
        let event = {
          value: "1",
          label: "Root Causes -> Symptoms",
        };
        viewChangeHandler(event);
    }else
      viewChangeHandler(viewOption);
      
      getRootCauses();
  },[advanceFilters,ProjectView])

  const getSymptoms = () => {
    const projectName = getProjectDetails();
    const url =
      config.INSIGHTS_API_URL +
      `/product-intelligent-triage/settings/getSymptomsdetailsSet/project/${projectName}`;
    setDataLoading(true);
    PostCalltoServer(url, advanceFilters)
      .then((response) => {
        if (get(response, "data.status.responseType", "") === "success") {
          setDataLoading(false);
          const data = JSON.parse(get(response, "data.data", []));
          setFilterSymtomData([...data].reverse());
          dispatch(getSymptomsDataAction([...data].reverse()));
        } else {
          setDataLoading(false);
        }
      })
      .catch((err) => {
        setDataLoading(false);
      });
  }
  const getRootCauses = () => {
    const projectName = getProjectDetails();
    const url =
    config.INSIGHTS_API_URL +
    `/product-intelligent-triage/settings/getRootCausedetailsSet/project/${projectName}`;
  setDataLoading(true);
  PostCalltoServer(url, advanceFilters)
    .then((response) => {
      if (get(response, "data.status.responseType", "") === "success") {
        setDataLoading(false);
        const data = JSON.parse(get(response, "data.data", []));
        setFilterRootCauseData([...data].reverse());
        dispatch(getRootCausesDataAction([...data].reverse()));
      } else {
        setDataLoading(false);
      }
    })
    .catch((err) => {
      setDataLoading(false);
    });
  }
  const ReciveDataToSymRootCause = (val) => { // the callback. Use a better name
    SetSelectedRootCause(val)
  };
  const ReciveDataToRootCauseSym = (val) => { // the callback. Use a better name
    SetSelectedSymptom(val)
  };

  const addSymptom = (symptomsData) => {
    setFilterSymtomData((filterSymptomData) =>
      filterSymptomData.concat(symptomsData)
    );
  };

  const addRootcause = (newRootcause) => {
    setFilterRootCauseData((filterRootCauseData) =>
    filterRootCauseData.concat(newRootcause)
    );
  };

  return (
    <React.Fragment>
      <div>
        <div className="root-cause-rigjht-side-container pt-3">
          <div className="root-cause-rigjht-side-content">
            <Col xs={3}>
              {/* commented to match like figma */}
              {/* <p className="root-cause-right-side-head">Viewing</p>  */}
              <ReactSelectComponent
                className="root-cause-left-side-data"
                value={viewOption}
                options={viewingOptions}
                onChange={(event) => viewChangeHandler(event)}
              />
            </Col>
            <Col xs={7}>
              <Col xs={8} className="ml-3 d-flex justify-content-start">
                {appliedFilters.length ? (
                  <p className="root-cause-right-side-head">Filters Applied</p>
                ) : null}
              </Col>
              <Col
                xs={8}
                className="ml-3 d-flex justify-content-start symptoms-imgs"
              >
                <div className="d-flex flex-start align-items-center">
                  {appliedFilters.length
                    ? appliedFilters.slice(0, 4).map((item, index) => (
                        <div className="mr-2 mt-1 d-flex flex-start align-items-center selected-filter-tag ">
                          {index > 2 ? null : (
                            <div
                              onClick={() => onRemoveItem(item)}
                              className="selected-fiter-tag-remove-icon mr-2 ml-2"
                            >
                              <div className="selected-fiter-tag-close-icon">
                                x
                              </div>
                            </div>
                          )}
                          {index > 2 ? (
                            <div
                              className="ml-3 selected-filter-tag-label"
                              onClick={filterHandler}
                            >
                              {"show more.."}
                            </div>
                          ) : (
                            <div className="selected-filter-tag-label">
                              {item}
                            </div>
                          )}
                        </div>
                      ))
                    : ""}
                </div>
              </Col>
            </Col>

            <Col xs={2} align="right" className="mt-2">
              {/* <Form.Label className="mb-1 toggle-btn-align">
              {"Is this reviewed"}
            </Form.Label> */}
              {/* <Form.Check
                      inline
                      type="checkbox"
                      id="custom-box"
                      label="Reviewed"
                      checked={checked}
                      onChange={(event) => changeHandler(event)}
            /> */}
              <LightTooltip
              
                arrow
                title={viewOption.value === "1" ? "Adding New Symptom" : "Adding New RootCause" }
                placement="top-start"
              >
                <img
                  className={permissions == true ? "mr-3" : "mr-3 peventNone"}
                  src="./images/MapAdd.svg"
                  onClick={toggleDrawer(true)}
                />
              </LightTooltip>
              <LightTooltip arrow title={"Filter"} placement="top-start">
                <img
                  src="./images/filter_icon.png"
                  alt="filter"
                  onClick={filterHandler}
                />
              </LightTooltip>
              \{" "}
            </Col>
          </div>
        </div>
        {loading ? (
          <Row className="justify-content-center align-items-center">
            {/* <Spinner animation="border" variant="primary" /> */}
            <SpinnerPage />
          </Row>
        ) : (
          <React.Fragment>
            {symptomRootCauseData.length ? (
              <div className="d-flex pt-5 col-md-12">
                {get(viewOption, "value", "") === "1" && (
                  <RootCauseSymptomTabs
                    config={config}
                    checked={checked}
                    lastSelectedRootCause={lastSelectedRootCause}
                    sendDataToSymRootCause={ReciveDataToSymRootCause}
                    permissions={permissions}
                  />
                )}
                {get(viewOption, "value", "") === "2" && (
                  <SymptomRootCauseTabs
                    config={config}
                    checked={checked}
                    lastSelectedSymptom={lastSelectedSymptom}
                    sendDataToRootCauseSym={ReciveDataToRootCauseSym}
                    permissions={permissions}
                  />
                )}
              </div>
            ) : null}
          </React.Fragment>
        )}
        {showFilterDrawer ? (
          <Filters
            showDrawer={showDrawer}
            toggleDrawer={toggleShowFilterDrawer}
            closeDrawer={() => {
              showFilterDrawer(false);
            }}
            config={config}
            resultsUpdate={resultsUpdate}
          />
        ) : null}

        {showDrawer && get(viewOption, "value", "") === "1" ? (
          <AddSymptomsDetails
            showDrawer={showDrawer}
            toggleDrawer={toggleDrawer}
            config={config}
            symptomsData={symptomsData}
            getSymptoms={getSymptoms}
            addSymptom={(symptomData) => addSymptom(symptomData)}
            advanceFilters={advanceFilters}
            selectedRootCause={selectedRootCause}
            closeDrawer={() => {
              setShowDrawer(false);
              let event = {
                value: "1",
                label: "Root Causes -> Symptoms",
              };
              viewChangeHandler(event);
              dispatch(SetSuccessMessage("Symptom Added Successfully"));
              setLastSelectedRootCause(selectedRootCause);
              setLoading(false);
            }}
          />
        ) : null}

        {showDrawer && get(viewOption, "value", "") === "2" ? (
          <AddRootCausesDetails
            showDrawer={showDrawer}
            toggleDrawer={toggleDrawer}
            config={config}
            advanceFilters={advanceFilters}
            rootCause={rootCausesData}
            addRootcause={addRootcause}
            getRootCauses={getRootCauses}
            selectedSymptom={selectedSymptom}
            closeDrawer={() => {
              setShowDrawer(false);
              let event = {
                value: "2",
                label: "Symptoms -> Root Causes",
              };
              viewChangeHandler(event);
              dispatch(SetSuccessMessage("Root Cause Added Successfully"));
              setLastSelectedSymptom(selectedSymptom);
              setLoading(false);
            }}
          />
        ) : null}
      </div>
    </React.Fragment>
  );
};

SymptomRootCauseMapping.propTypes = {
  viewingOptions: PropTypes.array,
};

SymptomRootCauseMapping.defaultProps = {
  viewingOptions: [
    { value: "1", label: "Root Causes -> Symptoms" },
    { value: "2", label: "Symptoms -> Root Causes" },
  ],
};

export default SymptomRootCauseMapping;
