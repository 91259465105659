import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import * as PropTypes from "prop-types";
import Heading3 from "../Typography/Heading3/Heading3";

const DrawerHeaderContent = ({ heading, toggleDrawer, helpIcon }) => {
  return (
    <div className="d-flex pt-4 pl-2">
      <div className="col-md-1">
        <CloseIcon
        data-testid={`toggle`}
          className="d-flex product-img"
          onClick={toggleDrawer(false)}
          style={{ fontSize: 25 }}
        />
      </div>
      <div data-testid={`text`}className="d-flex product-data pl-3" md={6}>
        <Heading3 className="text-capitalize" text={heading} />
        {helpIcon ? (
          <HelpOutlineIcon data-testid={`helpicon`} className="product-img" style={{ fontSize: 18 }} />
        ) : null}
      </div>
    </div>
  );
};
DrawerHeaderContent.propTypes = {
  heading: PropTypes.string,
  helpIcon: PropTypes.bool,
};
DrawerHeaderContent.defaultProps = {
  heading: "Drawer Header",
  helpIcon: false,
};

export default DrawerHeaderContent;
