import React, { useState, useEffect } from "react";
import Drawer from "@material-ui/core/Drawer";
import get from 'lodash/get';
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import isEqual from 'lodash/isEqual';
import PropTypes from "prop-types";
import "./edit-symptoms.css";
import { Col, Form } from 'react-bootstrap';
import { useDispatch } from "react-redux";
import editSymptomsToRootCauseHandler from "../../../../../../../../store/actions/adminSettings/editSymptomsToRootCauseHandler";
import PrimaryButtonWithLoader from "../../../../../components/PrimaryButtonWithLoader/PrimaryButtonWithLoader";
import SecondaryButtonWithLoader from "../../../../../components/SecondaryButtonWithLoader/SecondaryButtonWithLoader";
import DrawerHeaderContent from "../../../../../components/DrawerHeaderContent/DrawerHeaderContent";
import InputWithLabelComponent from "../../../../../components/InputWithLabelComponent/InputWithLabelComponent";
import { deleteCalltoServer, UpdateCalltoServer } from "../../../../../../../../store/utility";
import deleteSymptomsToRootCauseHandler from "../../../../../../../../store/actions/adminSettings/deleteSymptomsToRootCauseHandler";
import getProjectDetails from "../../../../../helpers/getProjectDetails";
import { SetSuccessMessage } from "../../../../../../../../store/actions";


const useStyles = makeStyles({
  list: {
    width: 500,
  }
});

const EditSymptoms = ({
  showDrawer,
  toggleDrawer,
  rootcausesheading,
  closeDrawer,
  toastFun,
  rootcausestitle,
  rootCauseData,
  selectedItem,
  id,
  config
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const projectName = getProjectDetails();
  const [saveLoading, setSaveLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [isInvalid, setInValid] = useState(false);
  const [formData, setFormData] = useState({
    rootcause: '',
    rootcausetitle: '',
    relevancy: '',
    status: ''
  });
  
  useEffect(() => {
    setFormData(
      {
        rootcause: get(rootCauseData, 'rootcause', ''),
        rootcausetitle: get(rootCauseData, 'rootcausetitle', ''),
        relevancy: get(rootCauseData, 'relevancy', ''),
        status: get(rootCauseData, 'status', ''),
        symptoms: get(selectedItem, 'symptoms', '')
      }
    )
  }, [rootCauseData]);

  const changeHandler = (name) => (event) => {
    if (name === 'status') {
      setFormData(prevData => ({
        ...prevData,
        [name]: event.target.checked ? 'reviewed' : 'yet to review'
      }));
    } else {
      if(!isEqual(event.target.value,"")) {
        setInValid(false);
        setFormData(prevData => ({
          ...prevData,
          [name]: event.target.value
        }));
      }
      setFormData(prevData => ({
        ...prevData,
        [name]: event.target.value
      }));
    }
  };

  const saveHandler = (e) => {
    if( formData.relevancy == 0){
      setInValid(true)
      setTimeout(()=>{
        setInValid(false)
      },1000)
      return 
    }

    if(!isEqual(formData.relevancy,"") && formData.relevancy <= 90){
    const newForm = {
      "symptoms": formData.symptoms,
      "symptom_title": '',
      "relevancy": formData.relevancy,
      "rootcause": formData.rootcause,
      "rootcause_title": "",
      "leadingquestion": '',
      "project": projectName,
      "status": formData.status,
      "group" : "s2r"
    }
    const url = `${config.INSIGHTS_API_URL}/product-intelligent-triage/settings/statusAndScore`;
    setSaveLoading(true);
    UpdateCalltoServer(url, newForm)
      .then((response) => {
        setSaveLoading(false);
        if (get(response, "data.status.responseType", "") === "success") {
          dispatch(editSymptomsToRootCauseHandler({
            formData,
            id,
            selectedItem
          }));
          setSaveLoading(false);
          closeDrawer();
          dispatch(SetSuccessMessage("Symptom To Root cause Mapping Successfully Saved"));
        } else {
          setSaveLoading(false);
        }
      })
      .catch((err) => { 
        setSaveLoading(false);
        toastFun(err, "error");
       });
    } else{
      setInValid(true);
      setTimeout(()=>{
        setInValid(false)
      },1000)
    }
  };

  const deleteHandler = () => {
    setDeleteLoading(true);
    const url =`${config.INSIGHTS_API_URL}/product-intelligent-triage/settings/rootcauseUnmap`;
    const deletedSymptom ={
      "symptoms": get(selectedItem, 'symptoms', ''),
      "rootcause": formData.rootcause,
      "project": projectName
    }
    deleteCalltoServer(url,deletedSymptom)
    .then((response) => {
      if (get(response, "data.status.responseType", "") === "success") {
        dispatch(deleteSymptomsToRootCauseHandler({
          formData,
          id,
          selectedItem
        }));
        setDeleteLoading(false);
        closeDrawer();
        dispatch(SetSuccessMessage("Symptom To Root Cause Unmapped Successfully"));
      } else {
        setDeleteLoading(false);
      }
    })
    .catch((error) => {
      setDeleteLoading(false);
      toastFun(error, "error");
    })
  };


  return (
    <React.Fragment>
      {showDrawer ? (
        <Drawer
          anchor={"right"}
          open={showDrawer}
          onClose={toggleDrawer(false)}
        >
          <div
            className={clsx(classes.list, classes.fullList)}
            role="presentation"
          >
            <DrawerHeaderContent
              heading={"Root Causes Details"}
              toggleDrawer={toggleDrawer}
            />
              <div className="mt-3 d-flex flex-column rootCauses-Item rootCauses-Item-bg custom-bg ">
                <div className="mt-1">
                <Col md={12}>
                  <InputWithLabelComponent
                    type="text"
                    className=""
                    name="rootCause"
                    label={"Root Cause"}
                    value={get(formData, "rootcause", "")}
                    onChange={changeHandler("rootcause")}
                    readOnly
                    helpIcon={false}
                  />
                  </Col>
                </div>
                <div className="mt-3">
                <Col md={12}>
                  <InputWithLabelComponent
                    type="text"
                    className=""
                    name="leadingquestion"
                    label={"Recommended Resolution"}
                    value={get(formData, "rootcausetitle", "")}
                    onChange={changeHandler("rootcausetitle")}
                    readOnly
                    helpIcon={false}
                  />
                  </Col>
                </div>
                <div className="mt-3">
                <Col md={12}>
                  <InputWithLabelComponent
                    type="number"
                    max={'90'}
                    min={'1'}
                    className=""
                    max={'90'}
                    placeHolder={"Enter Relevancy"}
                    name="relevancy"
                    label={"Relevancy"}
                    value={get(formData, "relevancy", "")}
                    onChange={changeHandler("relevancy")}                   
                    required={true}
                    isInvalid={isInvalid}
                    error ={ isInvalid ? "Please Enter Relevancy(1-90)" : null}
                    helpIcon={false}
                  />
                  </Col>
                </div>
              </div>
              <div className="d-flex flex-column rootCauses-Item">
                  <Col md={12}>
                    <div className="d-flex align-items-baseline">
                      <Form.Label className="mb-1 toggle-btn-align">
                        {"Is this reviewed"}
                      </Form.Label>
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        label=""
                        checked={get(formData, "status", "") === "reviewed"}
                        onChange={changeHandler("status")}
                      />
                    </div>
                    </Col>
                </div>
            <div className="d-flex flex-row button-content">
              <SecondaryButtonWithLoader
                className="ml-5"
                size="lg"
                text="Unmap"
                onClick={deleteHandler}
                loading={deleteLoading}
              />
              <PrimaryButtonWithLoader
                className="ml-4"
                size="lg"
                onClick={saveHandler}
                text="save"
                loading={saveLoading}
              />
            </div>
          </div>
        </Drawer>
      ) : null}
      {/* <ToastContainer/> */}
    </React.Fragment>
  );
};

EditSymptoms.propTypes = {
  rootcausesheading: PropTypes.string,
  rootcausestitle: PropTypes.string,
  systemgeneratedrootCauses: PropTypes.string,
  deletebutton: PropTypes.string,
  savebutton: PropTypes.string,
};

EditSymptoms.defaultProps = {
  rootcausesheading: "Root Cause details",
  rootcausestitle: "Root Cause",
  systemgeneratedrootCauses: "System Generated Root Causes",
  deletebutton: "DELETE",
  savebutton: "SAVE"
};

export default EditSymptoms;
