// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drawerClass .MuiDrawer-paperAnchorRight{
    max-width:43.92vw;
    min-width:43.92vw;
}

.drawerClass .Symtom-Item {
    padding: 0.25rem 3rem;
}

.categgory-container{
    position: relative;
}

.categgory-container .add-icon{
    position: absolute;
    right: -30px;
    top: 35px;
}

.question-div div{
    margin-top: 5px;
    width: 45%;
}
.error-css {
    height: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/UIComponents/Drawer/DrawerComponent.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,SAAS;AACb;;AAEA;IACI,eAAe;IACf,UAAU;AACd;AACA;IACI,YAAY;AAChB","sourcesContent":[".drawerClass .MuiDrawer-paperAnchorRight{\n    max-width:43.92vw;\n    min-width:43.92vw;\n}\n\n.drawerClass .Symtom-Item {\n    padding: 0.25rem 3rem;\n}\n\n.categgory-container{\n    position: relative;\n}\n\n.categgory-container .add-icon{\n    position: absolute;\n    right: -30px;\n    top: 35px;\n}\n\n.question-div div{\n    margin-top: 5px;\n    width: 45%;\n}\n.error-css {\n    height: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
