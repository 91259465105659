export const TRIAGE_CONFIGURATION_CHANGE_HANDLER_ACTION = 'TRIAGE_CONFIGURATION_CHANGE_HANDLER_ACTION';
export const PRODUCT_ATTRIBUTES_REMOVE_ITEM_HANDLER = 'PRODUCT_ATTRIBUTES_REMOVE_ITEM_HANDLER';
export const SYMPTOM_DETAILS_UPDATE_HANDLER = 'SYMPTOM_DETAILS_UPDATE_HANDLER';
export const SYMPTOM_DETAILS_DELETE_HANDLER = 'SYMPTOM_DETAILS_DELETE_HANDLER';
export const ROOTCAUSE_DETAILS_UPDATE_HANDLER = 'ROOTCAUSE_DETAILS_UPDATE_HANDLER';
export const ROOTCAUSE_DETAILS_DELETE_HANDLER = 'ROOTCAUSE_DETAILS_DELETE_HANDLER';
export const TRIAGE_CONFIGURATION_UPDATE_HANDLER_ACTION = 'TRIAGE_CONFIGURATION_UPDATE_HANDLER_ACTION';
export const GET_SYMPTOMS_DATA = 'GET_SYMPTOMS_DATA';
export const GET_ROOTCAUSES_DATA = 'GET_ROOTCAUSES_DATA';
export const SYMPTOM_ROOT_CAUSE_DATA_CHANGE = 'SYMPTOM_ROOT_CAUSE_DATA_CHANGE';
export const EDIT_SYMPTOMS_TO_ROOT_CAUSE = 'EDIT_SYMPTOMS_TO_ROOT_CAUSE';
export const EDIT_ROOT_CAUSE_TO_SYMPTOMS = 'EDIT_ROOT_CAUSE_TO_SYMPTOMS';
export const DELETE_ROOT_CAUSE_TO_SYMPTOMS = 'DELETE_ROOT_CAUSE_TO_SYMPTOMS';
export const DELETE_SYMPTOMS_TO_ROOT_CAUSE = 'DELETE_SYMPTOMS_TO_ROOT_CAUSE';
