import * as actionTypes from './actionTypes';
import axios from '../../instance';
import { PostCalltoServer, GetCalltoServer, deleteCalltoServer} from '../utility';
import { SetErrorMessage, SetSuccessMessage, SetWarningMessage } from './message'

const orgName = localStorage.getItem("orgName");
const username = localStorage.getItem("userName");

export const GetUseCaseDetailsStart = () => {
    return {
        type: actionTypes.GET_USECASE_DETAILS_START
    };
};
export const GetUseCaseDetailsSuccess = (status) => {
    return {
        type: actionTypes.GET_USECASE_DETAILS_SUCCESS,
        Data: status
    };
};
export const GetUseCaseDetailsFail = (error) => {
    return {
        type: actionTypes.GET_USECASE_DETAILS_FAIL,
        error: error
    };
};
export const GetUseCaseDetailsByNameStart = () => {
    return {
        type: actionTypes.GET_USECASE_DETAILS_BYNAME_START
    };
};
export const GetUseCaseDetailsByNameSuccess = (status) => {
    return {
        type: actionTypes.GET_USECASE_DETAILS_BYNAME_SUCCESS,
        Data: status
    };
};
export const GetUseCaseDetailsByNameFail = (error) => {
    return {
        type: actionTypes.GET_USECASE_DETAILS_BYNAME_FAIL,
        error: error
    };
};
export const GetSourceAttributesStart = () => {
    return {
        type: actionTypes.GET_SOURCE_ATTRIBUTES_START
    };
};
export const GetSourceAttributesSuccess = (status) => {
    return {
        type: actionTypes.GET_SOURCE_ATTRIBUTES_SUCCESS,
        Data: status
    };
};
export const GetSourceAttributesFail = (error) => {
    return {
        type: actionTypes.GET_SOURCE_ATTRIBUTES_FAIL,
        error: error
    };
};

export const FetchSourceAttributesStart = () => {
    return {
        type: actionTypes.FETCH_SOURCE_ATTRIBUTES_START
    };
};
export const FetchSourceAttributesSuccess = (status) => {
    return {
        type: actionTypes.FETCH_SOURCE_ATTRIBUTES_SUCCESS,
        Data: status
    };
};
export const FetchSourceAttributesFail = (error) => {
    return {
        type: actionTypes.FETCH_SOURCE_ATTRIBUTES_FAIL,
        error: error
    };
};
export const createUseCaseStart = () => {
    return {
        type: actionTypes.CREATE_USECASE_START
    };
};
export const createUseCaseSuccess = (status) => {
    return {
        type: actionTypes.CREATE_USECASE_SUCCESS,
        Data: status
    };
};
export const createUseCaseFail = (error) => {
    return {
        type: actionTypes.CREATE_USECASE_FAIL,
        error: error
    };
};

export const CollectionValueStart = () => {
    return {
        type: actionTypes.CREATE_COLLECTION_VALUE_START
    };
};
export const CollectionValueSuccess = (status) => {
    return {
        type: actionTypes.CREATE_COLLECTION_VALUE_SUCCESS,
        Data: status
    };
};
export const CollectionValueFail = (error) => {
    return {
        type: actionTypes.CREATE_COLLECTION_VALUE_FAIL,
        error: error
    };
};
export const updateClusterDataStart = () => {
    return {
        type: actionTypes.UPDATE_CLUSTER_DATA_START
    };
};
export const updateClusterDataSuccess = (status) => {
    return {
        type: actionTypes.UPDATE_CLUSTER_DATA_SUCCESS,
        Data: status
    };
};
export const updateClusterDataFail = (error) => {
    return {
        type: actionTypes.UPDATE_CLUSTER_DATA_FAIL,
        error: error
    };
};
export const GetUseCaseDetails = (COMMON_API_URL) => {
    return dispatch => {
        var project = localStorage.getItem('projectname');
        let url = COMMON_API_URL + `/mlsupport/usecase/`+project;
        
        GetCalltoServer(url).then(response => {
            if (response.data.status.responseType == "success") {
                dispatch(GetUseCaseDetailsSuccess(response.data.data));
            }
            else {
                dispatch(SetErrorMessage('No more records to show'));
                dispatch(GetUseCaseDetailsFail(response.errors));
            }
        })
            .catch(err => {
                dispatch(SetErrorMessage('No more records to show'));
                dispatch(GetUseCaseDetailsFail(err.message));
                dispatch(GetUseCaseDetailsSuccess(''));
            });
    };
};
export const createUseCase = (COMMON_API_URL, data) => {
    return dispatch => {
        var project = localStorage.getItem('projectname');
        let url = COMMON_API_URL + `/mlsupport/usecase/create`;
        PostCalltoServer(url, data)
            .then(response => {
                if (response.data.status.responseType == "success") {
                    dispatch(SetSuccessMessage('Use Case created Successfully.'));
                    dispatch(createUseCaseSuccess(response.data.data));
                }
                else {
                    dispatch(SetErrorMessage('No more records to show'));
                    dispatch(createUseCaseFail(response.errors));
                }
            })
            .catch(err => {
                dispatch(SetErrorMessage('No more records to show'));
                dispatch(createUseCaseFail(err.message));
                dispatch(createUseCaseSuccess(''));
            });
    };
};
export const CollectionValue = (COMMON_API_URL, data) => {
    return dispatch => {
        var project = localStorage.getItem('projectname');
        let url = COMMON_API_URL + `/utilityservice/ticketdump/get-info-by-project/project`;
        PostCalltoServer(url, data)
            .then(response => {
                if (response.data.status.responseType == "success") {
                    // dispatch(SetSuccessMessage('Collection fetched Successfully.'));
                    dispatch(CollectionValueSuccess(response.data.data));
                }
                else {
                    dispatch(SetErrorMessage('Collection not available'));
                    dispatch(CollectionValueFail(response.errors));
                }
            })
            .catch(err => {
                dispatch(SetErrorMessage('Collection not available'));
                dispatch(CollectionValueFail(err.message));
                dispatch(CollectionValueSuccess(''));
            });
    };
};
export const GetSourceAttributes = (url) => {
    return dispatch => {
        // let url = COMMON_API_URL + `/utilityservice/settings/fields/`+data;
        GetCalltoServer(url).then(response => {
            if (response.data.status.responseType == "success") {
                dispatch(GetSourceAttributesSuccess(response.data.data));
            }
            else {
                dispatch(SetErrorMessage('No more records to show'));
                dispatch(GetSourceAttributesFail(response.errors));
            }
        })
            .catch(err => {
                dispatch(SetErrorMessage('No more records to show'));
                dispatch(GetSourceAttributesFail(err.message));
                //dispatch(GetSourceAttributesSuccess(''));
            });
    };
};

export const FetchSourceAttributes = (COMMON_API_URL,data) => {
    return dispatch => {
        let url = COMMON_API_URL + `/utilityservice/settings/fields/`+data;
        GetCalltoServer(url).then(response => {
            if (response.data.status.responseType == "success") {
                dispatch(FetchSourceAttributesSuccess(response.data.data));
            }
            else {
                dispatch(SetErrorMessage('No more attributes to show'));
                dispatch(FetchSourceAttributesFail(response.errors));
            }
        })
            .catch(err => {
                dispatch(SetErrorMessage('No more attributes to show'));
                dispatch(FetchSourceAttributesFail(err.message));
                //dispatch(GetSourceAttributesSuccess(''));
            });
    };
};


export const getModelOutPutDataStart = (loading) => {
    return {
        type: actionTypes.GET_MODELOUTPUT_DATA_START,
        modelOutputDataLoading:loading
    };
};
export const getModelOutPutDataSuccess = (data) => {
    return {
        type: actionTypes.GET_MODELOUTPUT_DATA_SUCCESS,
        modelOutputData: data
    };
};
export const getModelOutPutDataFail = (error) => {
    return {
        type: actionTypes.GET_MODELOUTPUT_DATA_FAIL,
        error: error
    };
};

export const getModelOutPutData = (url) => {
    return dispatch => {
        getModelOutPutDataStart(true)
        GetCalltoServer(url).then(response => {
            if (response.data.status.responseType == "success") {
                dispatch(getModelOutPutDataSuccess(response.data.data));
                getModelOutPutDataStart(false)
            }
            else {
                dispatch(SetErrorMessage('No Model Output Data'));
                dispatch(getModelOutPutDataFail(response.errors));
                getModelOutPutDataStart(false)
            }
        })
            .catch(err => {
                dispatch(SetErrorMessage('Not Fetched Model Output Data'));
                dispatch(getModelOutPutDataFail(err.message));
                getModelOutPutDataStart(false)
            })
    };
};
export const saveModelOutputDataStart = () => {
    return {
        type: actionTypes.SAVE_MODELOUTPUT_DATA_START,
        
    };
};
export const saveModelOutputDataSuccess = (status) => {
    return {
        type: actionTypes.SAVE_MODELOUTPUT_DATA_SUCCESS,
       Data:status
    }
};
export const saveModelOutputDataFail = (error) => {
    return {
        type: actionTypes.SAVE_MODELOUTPUT_DATA_FAIL,
        error:error
    }
}
export const saveModelOutputData = (url,payload) => {
    return dispatch => {
        saveModelOutputDataStart(true)
        PostCalltoServer(url,payload).then((response) => {
            if (response.data.status.responseType == "success") {
                dispatch(SetSuccessMessage('Records updated successfully.'));
                dispatch(saveModelOutputDataSuccess(response.data.data));
            }
            else {
              dispatch(saveModelOutputDataFail(response.data.status.responseType));
            }
          })
          .catch((err) => {
            dispatch(saveModelOutputDataFail(err.message));
          })
          .finally(() => {
            dispatch(saveModelOutputDataStart(false));
          });
      };
}


export const GetUseCaseDetailsByName = (COMMON_API_URL,data) => {
    return dispatch => {
        var project = localStorage.getItem('projectname');
        let url = COMMON_API_URL + `/mlsupport/usecase/`+project+`/`+ data;
        
        GetCalltoServer(url).then(response => {
            if (response.data.status.responseType == "success") {
                dispatch(GetUseCaseDetailsByNameSuccess(response.data.data));
            }
            else {
                dispatch(SetErrorMessage('No more records to show'));
                dispatch(GetUseCaseDetailsByNameFail(response.errors));
            }
        })
            .catch(err => {
                dispatch(SetErrorMessage('No more records to show'));
                dispatch(GetUseCaseDetailsByNameFail(err.message));
                dispatch(GetUseCaseDetailsByNameSuccess(''));
            });
    };
};
export const handleUpdate = (COMMON_API_URL, data) => {
    return dispatch => {
        var project = localStorage.getItem('projectname');
        let url = COMMON_API_URL + `/mlsupport/usecase/update`;
        PostCalltoServer(url, data)
            .then(response => {
                if (response.data.status.responseType == "success") {
                    dispatch(SetSuccessMessage('Use Case Updated Successfully.'));
                    dispatch(updateClusterDataSuccess(response.data.data));
                }
                else {
                    dispatch(SetErrorMessage('Use Case Updation failed'));
                    dispatch(updateClusterDataFail(response.errors));
                }
            })
            .catch(err => {
                dispatch(SetErrorMessage('Use Case Updation failed'));
                dispatch(updateClusterDataFail(err.message));
                dispatch(updateClusterDataSuccess(''));
            });
    };
};

export const downloadModelOutputData = (url,payload) => {
    return dispatch => {
        let token = localStorage.getItem("accessToken");
        axios({
            url: url,
            headers: { 'content-type': 'application/json', 'Authorization': `Bearer ${token}`,"orgName":orgName,"userName":username },
            method: 'POST',
            data: payload,
            responseType: 'blob', // important
        }).then(response => {
            if(response.data){
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download',orgName+'-modeloutput-'+payload.clusters +".zip");
            document.body.appendChild(link);
            link.click();         
                dispatch(SetSuccessMessage("Download Model Output Data Successfully."));
           }
           else
           {
            dispatch(SetErrorMessage('Failed to Download Model Output Data.'));
           }
        })
        .catch(() => {
            dispatch(SetErrorMessage('Failed to Download Model Output Data.'));
        });
    };
};

export const getDataReviewDataStart = (loading) => {
    return {
        type: actionTypes.GET_DATA_REVIEW_DATA_START,
        dataReviewDataLoading:loading
    };
};
export const getDataReviewDataSuccess = (data) => {
    return {
        type: actionTypes.GET_DATA_REVIEW_DATA_SUCCESS,
        dataReviewData: data
    };
};
export const getDataReviewDataFail = (error) => {
    return {
        type: actionTypes.GET_DATA_REVIEW_DATA_FAIL,
        error: error
    };
};

export const getDataReviewData = (url) => {
    return dispatch => {
        dispatch(getDataReviewDataStart(true))
        GetCalltoServer(url).then(response => {
            if (response.data.status.responseType == "success") {
                dispatch(getDataReviewDataSuccess(response.data.data));
                dispatch(getDataReviewDataStart(false))
            }
            else {
                //dispatch(SetErrorMessage('No Model Output Data'));
                dispatch(getDataReviewDataFail(response.errors));
                dispatch(getDataReviewDataStart(false))
            }
        })
            .catch(err => {
                //dispatch(SetErrorMessage('Not Fetched Model Output Data'));
                dispatch(getDataReviewDataFail(err.message));
                dispatch(getDataReviewDataStart(false))
            })
    };
};

export const getSentimentAndCategoryAndStatusSuccess = (data) => {
    return {
        type: actionTypes.GET_SENTIMENT_CATEGORY_STATUS_LIST_SUCCESS,
        allEditableList: data
    };
};

export const getSentimentAndCategoryAndStatus = (url) => {
    return dispatch => {
        GetCalltoServer(url).then(response => {
            if (response.data.status.responseType == "success") {
                dispatch(getSentimentAndCategoryAndStatusSuccess(response.data.data));
            }
        })
            .catch(err => {
                console.log(err)
                dispatch(SetErrorMessage(err));
            })
    };
};

// export const getSentimentAndCategoryAndStatusSuccess = (data) => {
//     return {
//         type: actionTypes.GET_SENTIMENT_CATEGORY_STATUS_LIST_SUCCESS,
//         allEditableList: data
//     };
// };

export const updateDataReview = (url,payload) => {
    return dispatch => {
        PostCalltoServer(url,payload).then(response => {
            if (response.data.status.responseType == "success") {
                dispatch(SetSuccessMessage("Data Updated Successfully."));
            }
            else{
                dispatch(SetErrorMessage("Data Not Updated Successfully."));
            }
        })
            .catch(err => {
                console.log(err)
                dispatch(SetErrorMessage(err));
            })
    };
};

export const deleteDataReview = (url,payload) => {
    return dispatch => {
        deleteCalltoServer(url,payload).then(response => {
            if (response.data.status.responseType == "success") {
                dispatch(SetSuccessMessage("Data(s) Deleted Successfully."));
            }
            else{
                dispatch(SetErrorMessage("Data(s) Not Deleted Successfully."));
            }
        })
            .catch(err => {
                console.log(err)
                dispatch(SetErrorMessage(err));
            })
    };
};

