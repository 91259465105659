import React, { useEffect, useState, useCallback } from "react";
import { Col, Row, Table, Spinner } from "react-bootstrap";
import Heading3 from "../components/Typography/Heading3/Heading3";
import { useDispatch, useSelector } from "react-redux";
import ButtonCustom from "../../../../components/UIComponents/Button/ButtonComponent";
import { useHistory } from "react-router-dom";
import TextAreaWithLabelComponent from "../components/TextAreaWithLabelComponent/TextAreaWithLabelComponent";
import ProductAttributesComponent from "../Insight/AdminConfiguration/ProductAttributesComponent/ProductAttributesComponent";
import { isEmpty, isEqual, get } from "lodash";
import { useDropzone } from "react-dropzone";
import "./Tickets.css";
import { SetErrorMessage, SetSuccessMessage, SetWarningMessage } from "../../../../store/actions";
import { GetCalltoServer, PostCalltoServer } from "../../../../store/utility";
import axios from "../../../../instance";
import AddCollection from "./AddCollection";
import SpinnerPage from "../../../../components/UI/Loader/Loader";
import { decrypt, encryptAndStore } from "../../../../utility/utility";
import { Pagination, PaginationItem, Typography } from "@mui/material";
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(() => ({

  root: {
    "& .MuiPaginationItem-root": {
      fontFamily: 'Roboto-Bold !importatnt',
      fontWeight: "900",
      fontSize: "10px",
      color: "#696868",
      margin: "0px",
      borderRadius: "0px",
      borderColor: "#696868"
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'NEXT'",
      marginRight: "8px",
      variant: "outlined",
      margin: "normal",
    },
    "& .MuiPagination-ul li:first-child button::after": {
      content: "'PREVIOUS'",
      marginLeft: "8px",
    },
    "& .MuiPagination-ul li:last-child button": {
      borderTopRightRadius: "5px",
      borderBottomRightRadius: "5px",
    },
    "& .MuiPagination-ul li:first-child button": {
      borderTopLeftRadius: "5px",
      borderBottomLeftRadius: "5px",
    },
    "& .MuiPagination-ul li:first-child button:enabled": {
      color: "#2A93DF",
    },
    "& .MuiPagination-ul li:last-child button:enabled": {
      color: "#2A93DF",
    },

  },
  ul: {
    '& .MuiPaginationItem-root': {
      '&.Mui-selected': {
        background: '#2A93DF',
        color: 'white',
      },
    }
  },
})
)




const Tickets = (config) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const projects = useSelector((state) => state.user.projects);
  const [projectValues, setProjectValues] = useState([]);
  const [selectedCollectionValues, setSelectedCollectionValues] = useState([]);
  const [btnUpload, setBtnUpload] = useState(true);
  const [records, setRecords] = useState([]);
  const [fileName, setFileName] = useState("");
  const [description, setDescription] = useState("");
  const [projectList, setProjectList] = useState(projects);
  const [collectionNames, setCollectionNames] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState("");
  const [dataLoading, setDataLoading] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [dropedItem, setDropedItem] = useState({});
  const [pagenationRecord, setPagenationRecord] = useState(1);
  const start = pagenationRecord * 10;
  const [sort, setSort] = useState(true);
  const getProjectName = localStorage.getItem("projectname");
  const getCollection = localStorage.getItem("sourceSelect");
  const [ingestion, setIngestion] = useState([]);
  const[selectedIngestion,setSelectedIngestion] = useState([]);
  
  const SportsFootballRounded = (str) => {
    if (str === "createdAt") {
      if (sort) {
        const sorted = records.sort((a, b) => new Date(a[str]) > new Date(b[str]) ? -1 : 1)
        setRecords(sorted)
      } else {
        const sorted = records.sort((a, b) => new Date(a[str]) < new Date(b[str]) ? -1 : 1)
        setRecords(sorted)
      }
      setSort(!sort)
      return
    }

    if (sort) {
      const sorted = records.sort((a, b) => a[str].toString().toLowerCase() > b[str].toString().toLowerCase() ? -1 : 1)
      setRecords(sorted)
    } else {
      const sorted = records.sort((a, b) => a[str].toString().toLowerCase() > b[str].toString().toLowerCase() ? 1 : -1)
      setRecords(sorted)
    }

    setSort(!sort)

  }

  var fd = new FormData();
  let token = localStorage.getItem("accessToken");
  

  useEffect(() => {
    getHistory();
  }, []);

  useEffect(() => {
    getHistory();
  }, []);

  const addedRecord = (newRecord) => {
    let updatedRecords = [newRecord, ...records]
    setRecords(updatedRecords);
  }

  const toggleDrawer = (value) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setShowDrawer(value);
  };

  const getHistory = () => {
    GetCalltoServer(
      config.config.INSIGHTS_API_URL +
      "/utilityservice/ticketdump/get-source-info/history"
    )
      .then((response) => {
        if (response.data.status.responseType == "success") {
          setRecords(response.data.data.reverse());
        }
      })
      .catch(() => { });
    setProjectList(projects.bots);
  };

  const onDropdownChangeHandler = (selectedItems, name) => {
    if (isEqual(name, "collectionNames")) {
      setSelectedCollection(selectedItems.map((item) => item.value));
      setSelectedCollectionValues(selectedItems.map((item) => item.value));
    } 
    else if(isEqual(name, "ingestion")){
      setSelectedIngestion(selectedItems.map((item) => item.value))
    }
    else {
      setSelectedCollectionValues([]);
      setCollectionNames([]);
      setSelectedIngestion([])
      setProjectValues(selectedItems.map((item) => item.value));
      // let selectedPrj = selectedItems.map((item) => item.value);
      // getCollectionByProject(selectedPrj.length > 0 ? selectedPrj[selectedPrj.length-1]: null);
      getCollectionByProject(selectedItems.map((item) => item.value));
    }
  };

  const onRemoveItemHandler = (name) => (selectedItem) => {
    if (isEqual(name, "collectionNames")) {
      const filteredCollections = selectedCollectionValues.filter(
        (item) => item !== selectedItem
      );
      setSelectedCollectionValues(filteredCollections);
    } 
    else if(isEqual(name, "ingestion")){
      const filteredCollections = selectedIngestion.filter(
        (item) => item !== selectedItem
      );
      setSelectedIngestion(filteredCollections);
    }
    else {
      const filteredProducts = projectValues.filter(
        (item) => item !== selectedItem
      );
      if (isEqual(filteredProducts.length, 0)) {
        setCollectionNames([]);
        setSelectedCollection("");
      }

      setProjectValues(filteredProducts);
    }
  };

  const getCollectionByProject = (project) => {
    if (!isEqual(project.length, 0)) {
      PostCalltoServer(
        config.config.INSIGHTS_API_URL +
        `/utilityservice/ticketdump/get-info-by-project/project`,
        { project, "modelType": "Triage" }
      )
        .then((response) => {
          if (response.data.status.responseType == "success") {
            setCollectionNames(get(response, "data.data[0].collection", []));
          }
        })
        .catch(() => { });
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    if (!isEmpty(acceptedFiles)) {
      setBtnUpload(false);
      let droppedFiles = [];
      acceptedFiles.forEach((item) => {
        setFileName(item.name);
        droppedFiles.push(item.name);
        setDropedItem(item);
        fd.append("file", item);

      });
      setFileNames(droppedFiles);
    } else {
      dispatch(SetErrorMessage("File Format Not Supported."));
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: ".csv,.xlsx",
    onDrop,
    multiple: false,
  });


  const uploadBtn = () => {
    const configHeader = {
      headers: {
        Authorization: `Bearer ${token}`,
        orgName: localStorage.getItem("orgName"),
        userName: localStorage.getItem("userName")

      }
    };
    fd.append("file", dropedItem);
    if (selectedCollection && projectValues.length > 0 && selectedIngestion.length> 0) {
      setDataLoading(true);
      // localStorage.setItem("projectname", projectValues);
      // localStorage.setItem("sourceSelect", selectedCollection);
      encryptAndStore("projectname", projectValues);
      encryptAndStore("sourceSelect", selectedCollection);
      setTimeout(() => {
        axios
          .post(config.config.INSIGHTS_API_URL + `/utilityservice/ticketdump/upload-ticket/${selectedCollection}`, fd, configHeader)
          .then((res) => {
            if (get(res, "data.status.responseType", "") === "success") {

              let responseJson = { "project": projectValues, "source": "test_ticket",  "modelType":selectedIngestion[0], "description": description, "files": fileNames }
              updateIngestion();
              PostCalltoServer(config.config.INSIGHTS_API_URL + `/utilityservice/ticketdump/save-source-info/${selectedCollection}`, responseJson)
                .then((response) => {
                  if (get(response, "data.status.responseType", "") === "success") {
                    let today = new Date().toUTCString();
                    let addedObj = {
                      collection: selectedCollection,
                      files: fileNames,
                      description: description,
                      project: projectValues.toString(),
                      createdAt: today
                    }
                    addedRecord(addedObj);
                    dispatch(SetSuccessMessage("Ticket Uploaded successfully"));
                    setSelectedCollection("");
                    setProjectValues([]);
                    setCollectionNames([]);
                    setSelectedCollectionValues([]);
                    setFileName("");
                    setDescription("");
                    setDataLoading(false);
                    setBtnUpload(true);
                    setSelectedIngestion([])
                  }
                }).catch((err) => {
                  console.log(err, err);
                  dispatch(SetErrorMessage("Ticket Uploaded failed"));
                  setDataLoading(false);
                })
            }
          })
          .catch((error) => {
            console.log(error);
            dispatch(SetErrorMessage("Ticket Uploaded failed"));
            setDataLoading(false);

          });
      }, 30000)
    } else {
      if(projectValues.length == 0 ){
        dispatch(SetWarningMessage("please select the Project"))
      }
      else if(selectedCollection == "" ){
        dispatch(SetWarningMessage("please select the Collection"));
      }
      else if(selectedIngestion.length == 0 ){
        dispatch(SetWarningMessage("please select the Ingestion"))
      }
      // dispatch(SetWarningMessage(projectValues.length == 0 && selectedIngestion.length == 0 && selectedCollection === "" ? "please select ": "raje"));
    }
  };


  const onChangeHandler = (e) => {
    setDescription(e.target.value)

  };

  const getOptions = (list) => {
    let options = [];
    list.forEach((option) =>
      options.push({
        label: option,
        value: option,
      })
    );
    return options;
  };



  const updateCollectionName = (val) => {
    let createdCollection = [];
    createdCollection.push(val);
    setCollectionNames(createdCollection);
    setSelectedCollectionValues(createdCollection);
    setSelectedCollection(val);
    setShowDrawer(false);
    dispatch(SetSuccessMessage(val + "  - Collection Added successfully"));
  }
  const updateIngestion = () =>{
    let createIngestion = [];
    // createIngestion.push("Triage", "Unsupervised ML", "Supervised ML");
    createIngestion.push("Triage");
    setIngestion(createIngestion);
  }
  useEffect(() =>{
    updateIngestion();
  },[]);
  
  const totalCount = Math.ceil(records.length / 10);

  const handleChange = (event, value) => {
    setPagenationRecord(value);
  };

  return (
    <>
      <Row
        className="tickets-dump"
        style={{
          marginLeft: "0px",
          marginRight: "0px",
          backgroundColor: "white",
        }}
      >
        <Col xs="12" className="row-no-gutters">
          <div className="d-flex mt-3 main-heading">
            <h3>
              <img
                className="upload-ticket-back-icon"
                src="./Icons/arrow-lft.png"
                onClick={() => history.goBack()}
              />
            </h3>
            <Heading3 text="Tickets" className="pageTitleText" />
          </div>

          <div className="mb-2">
            <label>
              Upload the files which has Tickets Dump Details i.e .csv, .xls
            </label>
          </div>
        </Col>
        {dataLoading ? (
          <Row
            className="vh-100 justify-content-center align-items-center"
            style={{ marginLeft: "50%" }}
          >
            <SpinnerPage animation="border" variant="primary" />
          </Row>
        ) : (
          <Col xs="12" className="d-flex">
            <Col xs="4">
              <div
                style={{
                  minHeight: "300px",
                  backgroundColor: "#F2F1F1",
                  padding: "2rem",
                }}
              >
                <div
                  {...getRootProps()}
                  className="mb-3"
                  style={{ minHeight: "25vh", border: "dashed 2px #484545" }}
                >
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <p>Drop the files here ...</p>
                  ) : (
                    <div>
                      <p style={{ padding: "4rem", color: "#0000008f" }}>
                        {isEqual(fileName, "")
                          ? "Drag 'n' drop some files here, or click to select files"
                          : fileName}
                      </p>
                    </div>
                  )}
                </div>
                <TextAreaWithLabelComponent
                  label="Description"
                  name="Description"
                  required={false}
                  value={description}
                  className="text-area"
                  placeholder="Enter Text Area Content."
                  onChange={(e) => onChangeHandler(e)}
                />
                <Col xs="12" className="d-flex mt-3 p-0">
                  <Col xs="6" className="p-0 pr-2">
                    <ProductAttributesComponent
                      options={getOptions(
                        projects.bots.length > 0 ? projects.bots : []
                      )}
                      //options={ProjectsOptions}
                      name={"Projects_Associated"}
                      label={"Projects Associated*"}
                      boldTitle={true}
                      placeholder={"Select"}
                      className={"mt-1 mb-2"}
                      targetSourceDescription={"source_name"}
                      sourceHelpIcon={false}
                      attributeHelpIcon={false}
                      value={projectValues}
                      search={true}
                      onChange={(event) =>
                        onDropdownChangeHandler(event, "projectValues")
                      }
                      onRemoveItem={onRemoveItemHandler("projectValues")}
                    />
                  </Col>
                  <Col xs="5" className="p-0 pl-3">
                    <ProductAttributesComponent
                      options={getOptions(
                        collectionNames.length > 0 ? collectionNames : []
                      )}
                      name={"collection_Nmae"}
                      label={"collection Name*"}
                      boldTitle={true}
                      search={false}
                      placeholder={"Select"}
                      className={"mt-1 mb-2"}
                      targetSourceDescription={"source_name"}
                      sourceHelpIcon={false}
                      attributeHelpIcon={false}
                      //showValues={true}
                      value={selectedCollectionValues}
                      onChange={(event) =>
                        onDropdownChangeHandler(event, "collectionNames")
                      }
                      onRemoveItem={onRemoveItemHandler("collectionNames")}
                    />
                  </Col>
                  <Col xs="1 mt-5">
                    <div className="d-flex">
                      <img
                        className="mr-2 add-collection-icon"
                        src="./images/MapAdd.svg"
                        onClick={toggleDrawer(true)}
                      />
                      <img
                        className="remove-collection-icon"
                        src="./images/DeleteBlack.svg"
                      />
                    </div>
                  </Col>
                </Col>
                <Col xs="6" className="p-0 pr-2">
                    <ProductAttributesComponent
                      options={getOptions(
                        ingestion.length > 0 ? ingestion : []
                      )}
                      name={"ingestion"}
                      label={"Ingestion For*"}
                      boldTitle={true}
                      placeholder={"Select"}
                      className={"mt-1 mb-2"}
                      targetSourceDescription={"source_name"}
                      sourceHelpIcon={false}
                      attributeHelpIcon={false}
                      value={selectedIngestion}
                      search={true}
                      onChange={(event) =>
                        onDropdownChangeHandler(event, "ingestion")
                      }
                      onRemoveItem={onRemoveItemHandler("ingestion")}
                    />
                  </Col>
                <Col style={{marginBottom: "3.5rem"}} xs="12 mt-4">
                  <div align="center">
                    <ButtonCustom
                      variant="contained"
                      label="Upload"
                      className="buttonWidth"
                      disabled={btnUpload}
                      clicked={uploadBtn}
                    />
                  </div>
                </Col>
              </div>
            </Col>
            <Col xs="8" className="row-no-gutters">
              <div style={{ backgroundColor: "#fff" }}>
                <Col xs="12 pb-2">
                  <div style={{ color:"#221A1A" }} className="ticket-dump-table-main-header pb-2">Existing Ticket Dump</div>
                </Col>
                <div className="ticket-dump-table-scroll">
                  <Table responsive className="mt-2 pr-2 ticket-dump-table">
                    <thead className="ticket-dump-table-header" xs={12}>
                      <tr style={{ backgroundColor: "#F2F1F1" }} className='sorting'>
                        <th xs={2}  onClick={() => SportsFootballRounded('collection')}  >COLLECTION NAME </th>
                        <th xs={3} onClick={() => SportsFootballRounded('files')}  >FILE NAME</th>
                        <th xs={3} onClick={() => SportsFootballRounded('description')} >DESCRIPTION</th>
                        <th xs={2} onClick={() => SportsFootballRounded('project')}>PROJECTS ASSOCIATED</th>
                        <th xs={1} onClick={() => SportsFootballRounded('createdAt')}>UPDATED DATE</th>
                        {/* <th key={5}></th> */}
                        {/* <th xs={1}></th> */}
                      </tr>
                    </thead>
                    <tbody className="ticket-dump-table-body" xs={12}>
                      {records.slice(start - 10, start).reverse().reverse().map((item, index) => (
                        <tr key={item.uuid}>
                          <td xs={2}>{item.collection}</td>
                          <td xs={2}>{item.files[0]}</td>
                          <td xs={3}>{item.description}</td>
                          <td xs={2}>{item.project.toString()}</td>
                          <td xs={2}>{item.createdAt.slice(5, 16)}</td>
                          {/* <td xs={1}></td> */}
                          {/* <td key={5}>...</td> */}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
            <div className="d-flex justify-content-center pt-4 pb-4">
            <Pagination xs={8} classes={{ ul: classes.ul }} className={classes.root} count={totalCount}
              shape="rounded" variant="outlined" color="primary" size="large"
              page={pagenationRecord} onChange={handleChange}
            />
            </div>
              </div>
            </Col>

          </Col>
        )}
      </Row>
      {showDrawer ? (
        <AddCollection
          showDrawer={showDrawer}
          toggleDrawer={toggleDrawer}
          updateCollectionName={updateCollectionName}
          config={config}
          selectedCollection={selectedCollection}
          collectionNames={collectionNames}
          records={records}
          closeDrawer={() => {
            setShowDrawer(false);
          }}
        />
      ) : null}
    </>
  );
};

export default Tickets;
