import { Row, Col, Container } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SearchComponent from "../../../components/UIComponents/SearchComponent/Search";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { projectHeaderShow,UpdateCuxBotName,CreateCuxFlowTemplate } from "../../../store/actions/index";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import orderBy from 'lodash/orderBy';
import './conversationalUI.css';

const CDFlowList = ({config, showBotModal, selectFlowFromList, deleteCuxBot, exportCuxBot}) => {

    const defaultCols = [{name:'FLOW NAME ',sortAscend:false},{name:'CREATED DATE ',sortAscend:false},{name:'',sortAscend:''}];
    const flowList = useSelector((state)=> { return state.projectview.CuxBotList });
    const dispatch = useDispatch();

    const [tableCols, setTableCols] = useState(defaultCols);
    const [sortedList,setSortedList] = useState(flowList);
    const [showOptions, setShowOptions] = useState(null);
    const [selectedFlowData, setSelectedFlowData] = useState();
    const [searchValue, setSearchValue] = useState('');
    const [showSearchIcon, setShowSearchIcon] = useState(true);

    let projectname = localStorage.getItem("projectname");
    if (!projectname) projectname = "Common";
    let language = localStorage.getItem("language");

    useEffect(()=>{
        setSortedList(flowList);
    },[flowList]);

    const onSelectFlow = (id, name) => {
        dispatch(projectHeaderShow(false));
        selectFlowFromList(id,name);
    }

    const handleClickOptions = (data,event) => {
        setSelectedFlowData(data);
        setShowOptions(event.currentTarget)
    }

    const openFlowDesigner = () => {
        onSelectFlow(selectedFlowData.flowid, selectedFlowData.flowname);
    }

    const exportFlow = () => {
        exportCuxBot({botName: selectedFlowData.flowname,
            language: language ? language : "en",
            project: projectname
        });
        setShowOptions(null);
    }

    const saveAsTemplate = () => {
        dispatch(UpdateCuxBotName(config.COMMON_API_URL, selectedFlowData.flowid, 'Template_'+selectedFlowData.flowname, localStorage.getItem("projectname")));
        dispatch(CreateCuxFlowTemplate(config.COMMON_API_URL, selectedFlowData.flowid, 'Template_'+selectedFlowData.flowname));
        setShowOptions(null);
    }

    const deleteFlow = () => {
        deleteCuxBot(selectedFlowData.flowid);
        setShowOptions(null);
    }

    const ClearSearchValue = () => {
        setSearchValue('');
        setShowSearchIcon(true);
    }

    const handleSearchFilter = (value) => {
        setSearchValue(value);
        setShowSearchIcon(value !== '' ? false : true);
    }

    const handleSort = (tableCol, index) => {
        let type = tableCol.name === defaultCols[0].name ? 'flowname' : 'created_at';
        let list = [...flowList];
        list = orderBy(list,type,tableCol.sortAscend?'desc':'asc');
        setSortedList(list);
        let tempCols = [...tableCols];
        tempCols[index]= {...tempCols[index],sortAscend:!tableCol.sortAscend}
        setTableCols(tempCols);
    }

    return (
    <>
        <Row style={{overflow:"hidden"}}>
            <Col md={12} sm={12} lg={12} xlg={12}>
                <div className='header flowList-header'>Flows</div>
            </Col>
        </Row>
        <Row className='commonPadding' style={{width:"100%",paddingRight:"0px"}}>
            <Col md={8} sm={8} lg={8} xlg={8}>
                <div><span className='headerText'><span style={{color:"#016BB5"}}>{flowList?.length?flowList.length:0} Flows </span> are available! </span> </div>
            </Col>
            <Col md={4} sm={4} lg={4} xlg={4} className='recent'>
                <div>
                    <SearchComponent className="searchIcon" class={"input-group searchClass"} 
                    Clear={()=>ClearSearchValue()} show={showSearchIcon} 
                    value={searchValue} onSearch={(e) => handleSearchFilter(e.target.value)}/>
                </div>
            </Col>
        </Row>
        <Row className='ml-2 mr-3'>
        <TableContainer className="table1Cont">
        <Table aria-label="simple table" stickyHeader >
            <TableHead className='multi-header'>
                <TableRow>
                    {tableCols.map((tableHeader, index) => {
                    return (
                        <TableCell key={index} className="table1H flow-list-header" style={{padding:"10px"}} 
                            onClick={() => handleSort(tableHeader, index)}>
                            {tableHeader.name} 
                            {tableHeader.name!=='' ? tableHeader.sortAscend ? 
                            <ArrowUpwardIcon className="iconStyle"/> : <ArrowDownwardIcon className="iconStyle"/>:''}
                        </TableCell>
                    )
                    })}
                </TableRow>
            </TableHead>
            <TableBody >
                {sortedList != undefined && sortedList !== null && 
                sortedList.filter((item)=> item.flowname.toLowerCase().includes(searchValue.toLowerCase()))
                .map((item) => {
                return (
                <>
                    <TableRow key={item.flowid} style={{backgroundColor:"inherit", cursor:"default"}} >
                        <TableCell className='table1C' onClick={()=>onSelectFlow(item.flowid,item.flowname)}>
                            {item.flowname}
                        </TableCell>
                        <TableCell className='table1C pl-20'>
                            {item.created_at.substring(0,10)}
                        </TableCell>
                        <TableCell className='table1C'>
                            <img src='./Icons/OptionsIcon.svg' style={{cursor:"pointer"}}
                                aria-controls="simple-menu" aria-haspopup="true" 
                                onClick={(e)=> handleClickOptions(item,e) }/> 
                        </TableCell>
                    </TableRow>
                    
                </>
                )
                }) 
                }
            </TableBody>
        </Table>
        </TableContainer>
        <Menu anchorEl={showOptions} keepMounted open={showOptions} onClose={()=>setShowOptions(null)} >
            <MenuItem className="IntentMenuItems" onClick={() => openFlowDesigner()}>VIEW/EDIT</MenuItem>
            <MenuItem  className="IntentMenuItems" onClick={() => exportFlow()}>EXPORT</MenuItem>
            <MenuItem  className="IntentMenuItems" onClick={() => saveAsTemplate()}>SAVE AS TEMPLATE</MenuItem>
            <MenuItem  className="IntentMenuItems" onClick={() => deleteFlow()}>DELETE</MenuItem>
        </Menu>
        {(sortedList === undefined || sortedList === null || sortedList.length === 0) && (
            <div className='emptyFlowList'>
                <h1><span className='no-trigger-text'>Flows are not added</span></h1>
                <h2><span className='noDataList' onClick={showBotModal}>Add New Flow</span></h2>
            </div>
        )}
        
        </Row>
    </>
    )
}

export default CDFlowList;