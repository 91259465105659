import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import withErrorHandler from "../../hoc/withErrorHandler/withErrorHandler";
import axios from "../../instance";
import * as actions from "../../store/actions/index";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import SpinnerPage from "../../components/UI/Loader/Loader";
import permissions from "../../permissions_Json";
import { Image } from "react-bootstrap";
import AddProjectToVA from "../../components/UI/Drawer/AddProjectToVA";
import ButtonComponent from "../../components/UIComponents/Button/ButtonComponent";
import { PostCalltoServer } from '../../store/utility';
import SearchComponent from "../../components/UIComponents/SearchComponent/Search";
import { encryptAndStore } from "../../utility/utility";

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: "100vh",
        backgroundColor: "#F2F1F1 !important",
        width: "96.5vw",
        position: "relative",
        left: "20px",
        [theme.breakpoints.up("sm")]: {
            backgroundColor: "#F2F1F1",
        },
        [theme.breakpoints.down("md")]: {
            backgroundColor: "#F2F1F1",
        },
        [theme.breakpoints.up("1700")]: {
            position: "relative",
            left: "18px",
            width: "97.5vw",
        }
    },
    mainProjectContainer: {
        backgroundColor: "#F2F1F1",
    },
    headergrid: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "15px 0px",
        margin: '0px 20px'
    },
    gridLeft: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        marginLeft: '-11px'
    },
    paraGraphGrid: {
        display: "flex",
        padding: "0px 0px",
        maxHeight: "38px",
        marginLeft: '10px',
    },
    toolBar: {
        display: "flex",
        padding: "15px 0px",
        justifyContent: "space-between",
        alignItems: "center",
    },
    SortByBar: {
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
        position: "relative",
        right: '30px',
        "& button": {
            backgroundColor: "none",
            "&:hover": {
                backgroundColor: "none",
                background: "none",
            },
        },
        "& ul": {
            paddingLeft: 2,
        },
        "& li": {
            fontSize: 12,
            fontWeight: "700",
            fontFamily: "Roboto-Regular",
            color: "black",
            textTransform: "uppercase",
            "&.active, &:hover, &.active:hover": {
                background: "none",
                color: "#2A93DF",
            },
        },
    },
    mainCardContainer: {
        padding: "0px 0px",
        margin: "0px 20px",
    },
    card: {
        maxWidth: "100%",
        margin: "10px 10px",
        cursor: "pointer",
    },
    authorContent: {
        display: "flex",
        justifyContent: "space-between",
        padding: "none !important",
    },
    btnGroup: {
        "&:hover": {
            backgroundColor: "none !important",
            boxShadow: "none",
        },
        "&:active": {
            boxShadow: "none",
            backgroundColor: "none !important",
        },
        marginLeft: '15px'
    },
    listbox: {
        width: 320,
        margin: "0px 15px",
        padding: 10,
        zIndex: 1,
        position: "absolute",
        listStyle: "none",
        background: "rgba(245, 245, 245, 1)",
        border: "0.25px solid #D0D0D0",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.25)",
        overflow: "auto",
        maxHeight: 200,
        fontFamily: "Roboto-Regular",
        fontWeight: "lighter",
        '& li[data-focus="true"]': {
            color: "#2977f5",
            cursor: "pointer",
            marginBottom: 2,
        },
        "& li:active": {
            color: "#2977f5",
        },
        [theme.breakpoints.up("1612.530", "781.250")]: {
            width: 400,
        },
    },
    allProjectsDropDown: {
        "& button": {
            backgroundColor: "none",
            "&:hover": {
                backgroundColor: "none",
                background: "none",
            },
        },
        "& ul": {
            paddingLeft: 2,
        },
        "& li": {
            fontSize: 14,
            fontWeight: "bold",
            fontFamily: "Roboto-Regular",
            color: "black",
            "&.active, &:hover, &.active:hover": {
                background: "none",
                color: "#2A93DF",
            },
        },
    },
    paperCustom: {
        boxShadow:
            "0px 0px 1px -1px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
        borderRadius: '10px !important',
    },
    paraBox: {
        height: "80px",
        color: '#605F5F !important'
    },
    DocBox: {
        paddingTop: '8px',
        paddingBottom: '4px'
    },
    addflowIcon:{
        cursor: 'pointer'
    }
}));

function Projects(props) {
    let access = false;
    let obj = props.permissions.find(data => {
        if (data.permission_code.includes(permissions.permissionCode.CONVERSATIONAL_AI_STANDARD_R
            || permissions.permissionCode.CONVERSATIONAL_AI_STANDARD_RW
            || permissions.permissionCode.COGNITIVE_SEARCH_KU_RW))
            return true
    })
    access = obj ? true : false;


    const classes = useStyles();
    let ProjectNameLoop;

    const [arraySize, setArraySize] = useState([{ startIndex: 0, endindex: 12 }]);
    const [projectList, setprojectlist] = useState([]);
    const [Searchvalue, setSearchValue] = useState("");
    const [addProjectShow, setAddProjectShow] = useState(false);
    const [GridView, setGridView] = useState(false);
    const [show, setShow] = useState(true)

    const handleOpenProjectDrawer = () => {
        setAddProjectShow(true);
    };

    function SortArray(x, y) {
        var nameA = x.projectName.toUpperCase();
        var nameB = y.projectName.toUpperCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        const botBuilder_URL = props.config.BOTBUILDER_URL;
        props.OnGetAllProjectsData(props.config.BOTBUILDER_PROXY);
        props.onchangeTabAction(true);
        setGridView(true);
    }, []);

    useEffect(() => {
        if (props.projectList !== null && props.projectList !== undefined && show == true) {
            let newProjectList = [];
            props.projectList && props.projectList.map((item) => newProjectList.push(item));
            setprojectlist(newProjectList);
        }
        if (Searchvalue.length > 0 && Searchvalue !== "") {
            setShow(false);
            let vaProjectList = props.projectList.filter(project => props.projects.includes(project.projectName))
            let output = vaProjectList && vaProjectList.filter((item) => {
                return (
                    item.projectName.toLowerCase().includes(Searchvalue.toLowerCase())
                )
            })
            setprojectlist(output);
        } else {
            setShow(true)
        }
    }, [props.projectList, Searchvalue, show]);

    useEffect(() => {
        handleClose("addProject");
    }, [props.createProjectFlag]);

    const ProjectCardClick = (projectname) => {
        if (access || props.cognitiveSearch.WR) {
            const queryParams = [];
            queryParams.push("projname=" + projectname);
            let savedProjectName = localStorage.getItem("projectname");
            if (savedProjectName !== projectname) {
                // localStorage.setItem("projectname", projectname);
                encryptAndStore("projectname", projectname);
            }
            //getting project related user permissions
            const commonapi_URL = props.config.COMMON_API_URL;
            props.OnGetUserPermissions(commonapi_URL, projectname);
            props.OnGetProjectLang(props.config.BOTBUILDER_PROXY, projectname);
            const queryString = queryParams.join("&");
            if (
                props.history.location.pathname != "/insights" &&
                props.history.location.pathname != "/settings" &&
                props.history.location.search != "?ticketsInsights" &&
                props.history.location.search != "/Cognitive"
            ) {
                // localStorage.setItem("fromVA",true);
                encryptAndStore("fromVA",true);
                props.history.push({
                    pathname: "/projectviewpage",
                    search: "?" + queryString,
                });
            }

            let orgname = localStorage.getItem("orgName");
            if (!props.config.BOT_ORGS.includes(orgname)) {
                props.onGetCuxBotList(props.config.COMMON_API_URL, projectname);
            }

            props.onchangeProjectAction(projectname);
            window.updateHCLChatBotURL(projectname);
            let projectname1 = localStorage.getItem("projectname");
            let language = localStorage.getItem("language");
            let docvalue = {
                projectname: projectname1,
                language: language,
            };
            props.OngetProjectDescription(docvalue, props.config.BOTBUILDER_URL);
        }
    };


    const handleClose = (projectType) => {
        if (projectType == "addProject") {
            setAddProjectShow(false);
        }

    };
    const onQuickFilterProjects = (event) => {
        setSearchValue(event.target.value);
      }
      const clearSearchValue = () => {
        setSearchValue('');
        setShow(true);
      }

    const removeProjectHandler = (projectName) => {
        let list = props.projects.filter(project => project !== projectName);
        var name = localStorage.getItem("userName").substring(0, localStorage.getItem("userName").lastIndexOf("@"));
        let obj = {
            virtualassistantName: localStorage.getItem("vaName"),
            virutalassistantDescription: props.description,
            createdUser: name,
            project: [...list]
        }
        props.onSaveAssistant(props.config.BOTBUILDER_URL, obj,"Project removed successfully");
        let checkFolder = {  "FolderName": 'Intelli' + localStorage.getItem('vaName') + 'Bot',
        "orgName": localStorage.getItem('orgName')}
        PostCalltoServer(props.config.VIRTUAL_ASSISTANCE_URL + '/virtualAssistant/checkFolderExists', checkFolder).then(response => {
                if (response.data.status == true) {
                    console.log("project Updated")
                    let token = { "project": list.join(), "FolderName": 'Intelli' + localStorage.getItem('vaName') + 'Bot',"orgName": localStorage.getItem('orgName') }
                    PostCalltoServer(props.config.VIRTUAL_ASSISTANCE_URL + '/virtualAssistant/updateProjectName', token)
                        .then(response => {
                            console.log("project Updated")
                            /*if (response.data.status == 'Success') {
                                console.log("project Updated")
                            }*/
                        });
                }
            });
       
}



    let loadingImg = null;
    if (props.projectsloading || props.assistantsloading) {
        loadingImg = <SpinnerPage />;
    } else {
        loadingImg = null;
    }
   let alphabeticalOrder = projectList && projectList.sort(SortArray);
    if (projectList !== null && projectList !== undefined) {
        let vaProjectList = alphabeticalOrder.filter(project => props.projects.includes(project.projectName))
        ProjectNameLoop =
            vaProjectList && vaProjectList.slice(arraySize[0].startIndex, arraySize[0].endindex).map((data, i) => {
                return (
                    <>
                        <Grid item sm={6} md={4} key={i}>
                            <Card
                                p={1}
                                className="customCard"
                                key={i}
                            >
                                <CardContent>
                                    <div className="d-flex justify-content-between mb-3" onClick={() => ProjectCardClick(data.projectName)}>
                                        <span className="ProjectTitle">{data.projectName}</span>
                                        <span className=""><img src="./Icons/black-up-arrow.png" /></span>
                                    </div>
                                    <Box pb={1} className={classes.paraBox}>
                                        <span className="cardParagraph">
                                            {data.description}
                                        </span>
                                    </Box>
                                    <Box className={classes.DocBox}>
                                        <div className="d-flex justify-content-between">
                                            <span className="CardFooter">
                                                {data.projectName == 'common' ? data.flowCount-1 : data.flowCount - 2}
                                                &nbsp;Intents | &nbsp;{data.faqCount}{" "}{data.faqCount === 1 ? "FAQ" :
                                                    "FAQs"} &nbsp;|&nbsp; {data.documentCount}{" "}
                                                {data.documentCount === 1 ? "Document" : "Documents"}
                                            </span>
                                            {<ButtonComponent variant='outlined'
                                                label='Remove' className='buttonClass remove-btn'
                                                clicked={() => { removeProjectHandler(data.projectName) }} />}
                                        </div>

                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </>
                );
            });
    } else {
        ProjectNameLoop = projectList && projectList.map((data, i) => {
            return (
                <>
                    <Grid item sm={6} md={4} key={i}>
                        <Card
                            p={1}
                            className="customCard"
                            key={i}
                            onClick={() => ProjectCardClick(data.projectName)}
                        >
                            <CardContent>
                                <div className="card-header mb-3">
                                    <span className="ProjectTitle">{data.projectName}</span>
                                    <span className=""><img src="./Icons/up-arrow.svg" /></span>
                                </div>

                                <Box pb={1} className={classes.paraBox}>
                                    <span className="cardParagraph">
                                        {data.description}
                                    </span>
                                </Box>
                                <Box className={classes.DocBox}>
                                    <span className="CardFooter">
                                        {data.projectName == 'common' ? data.flowCount-1 : data.flowCount - 2} Flows
                                        &nbsp;|&nbsp;{data.faqCount}{" "}{data.faqCount === 1 ? "FAQ" : "FAQs"} &nbsp;|
                                        &nbsp; {data.documentCount}{" "}
                                        {data.documentCount === 1 ? "Document" : "Documents"}
                                    </span>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                </>
            );
        });

    }
    return (
        <>
            <Grid>
                {loadingImg}
                <Grid container md={12} className={classes.toolBar}>
                    <Grid item className={classes.allProjectsDropDown}>
                        <div className={classes.phrasesDiv}>
                            <h3 className={`${classes.phrasesName} d-inline mr-2 ml-3`}>Projects</h3>
                            <Image src="./Icons/plus-icon.svg"
                                onClick={() => { handleOpenProjectDrawer() }}
                                className={classes.addflowIcon} />
                        </div>
                    </Grid>
                    <SearchComponent
                        class={"input-group homeSearch VASearchbox"}
                        id="example-search-input"
                        value={Searchvalue}
                        show={show}
                        onSearch={(event) => onQuickFilterProjects(event)}
                        Clear={clearSearchValue}
                    />
                </Grid>
                <Grid container lg={12} className="pl-4 pr-2">
                    {GridView === true && ProjectNameLoop.length > 0
                        ? ProjectNameLoop
                        : ""}
                </Grid>
            </Grid>
            <AddProjectToVA
                show={addProjectShow}
                onHide={handleClose}
                config={props.config}
                projectList={props.projectList && props.projectList.filter(project => !props.projects.includes(project.projectName))}
                projects={props.projects}
                description={props.description}
                createdUser={props.createdUser}
            />
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        cognitiveSearch: state.user.moduleAccess.cognitiveOtherDocsUpload,
        permissions: state.user.permissionList,
        projectList: state.projectview.AllProjectsList,
        projectsloading: state.projectview.projectsloading,
        assistantsloading: state.projectview.Assistantsloading,
        createProjectFlag: state.user.createProjectFlag,
        uploadProjectFlag: state.user.uploadProjectFlag,
        englishProject: state.user.englishProject,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSaveAssistant: (APIURL, vaData,msg) => dispatch(actions.CreateAssistant(APIURL, vaData,msg)),
        OnGetAllProjectsData: (APIURL) =>
            dispatch(actions.GetAllProjectsData(APIURL)),
        OnGetBuildProps: (apiUrl) => dispatch(actions.GetBuildProps(apiUrl)),
        OnGetUserPermissions: (apiUrl, projectname) =>
            dispatch(actions.GetUserPermissions(apiUrl, projectname)),
        onchangeTabAction: (tab) => dispatch(actions.changeTabAction(tab)),
        OnGetLanguage: (apiUrl) => dispatch(actions.GetLanguage(apiUrl)),
        OngetProjectDescription: (apiUrl, data) =>
            dispatch(actions.getProjectDescription(apiUrl, data)),
        onchangeProjectAction: (projectname) =>
            dispatch(actions.changeProjectAction(projectname)),
        OnProjectfileupload: (apiUrl, formdata, dataUrl, projectName) =>
            dispatch(
                actions.Projectfileupload(apiUrl, formdata, dataUrl, projectName)
            ),
        onGetCuxBotList: (apiUrl, projectName) =>
            dispatch(actions.GetCuxBotList(apiUrl, projectName)),
        OnGetProjectLang: (apiUrl, projectName) =>
            dispatch(actions.GetProjectLang(apiUrl, projectName)),
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withErrorHandler(Projects, axios));
