import React from 'react';
import { connect } from 'react-redux';
import { Row } from 'react-bootstrap';
import * as actions from '../../store/actions/index';
import moment from 'moment';
import Grid from "@material-ui/core/Grid";
import MetricCard from './innerPages/MetricCard';
import { Line } from 'react-chartjs-2';
import SpinnerPage from '../../components/UI/Loader/Loader';
import ButtonComponent from '../../components/UIComponents/Button/ButtonComponent';
import './reportDashboard.css';
import DashboardFilters from './DashboardFilters';

const allProj = 'All VA Projects';
let VaProjectName = '';
class ConversationalDashboard extends React.Component {
    constructor(props) {
        super(props);
        let reportDetail = this.props.reportDetail
        let location = this.props.location.search;
        let startDate = moment().subtract(6, 'days');
        let endDate = moment();
        let projectName = "All VA Projects";
        let vaProject = "All";
        if (location == "?back" && reportDetail != null) {
            startDate = moment(reportDetail.startDate)
            endDate = moment(reportDetail.endDate)
            projectName = reportDetail.project
            vaProject = reportDetail.virtualAssist
        }
        this.state = {
            usersCount: {
                heading: 'Users Count',
                headerIcon: '',
                backgroundColor: '#454D66',
                count: 0,
                icon: './Icons/total-users.svg'
            },
            conversationsCount: {
                heading: 'Conversations Count',
                headerIcon: '',
                backgroundColor: '#307399',
                count: 0,
                icon: './Icons/chat-session.svg'
            },
            conversationsSentiment: {
                heading: 'Conversations Feedback',
                headerIcon: './Icons/up-arrow.svg',
                backgroundColor: '#63465B',
                count: 0,
                icon: './Icons/sentiment.svg',
                positiveCount: 0,
                negitiveCount: 0
            },
            conversationsHandled: {
                heading: 'Conversations Handled',
                headerIcon: './Icons/up-arrow.svg',
                backgroundColor: '#6B5CA5',
                count: 0,
                count2: 0,
                label1: '',
                label2: '',
                icon: './Icons/con-hand.svg',
                answeredCount: 0,
                unAnsweredCount: 0
            },
            agentHandover: {
                heading: 'Agent Handover',
                headerIcon: './Icons/up-arrow.svg',
                backgroundColor: '#AEAC28',
                count: 0,
                count2: 0,
                label1: '',
                label2: '',
                icon: './Icons/agent-handover.png'
            },
            projectname: projectName,
            startDate: startDate,
            endDate: endDate,
            vaName: vaProject,
        }
    }
    callUsersDetails = (data) => {
        let apiURL = this.props.config.BOTBUILDER_URL;
        this.props.conversationalMetricsData(apiURL,data)
    }
    handleDidmountCall = () => {
        let location = this.props.location.search;
        if (location != "?back") {
            this.props.ReportPageData(null)
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.handleDidmountCall();
        }, 2000)
        let apiData = {
            "startDate": moment(this.state.startDate).format('YYYY-MM-DD'),
            "endDate": moment(this.state.endDate).format('YYYY-MM-DD'),
            "project": this.state.projectname,
            "orgName": localStorage.getItem('orgName'),
            "virtualAssist":this.state.vaName
        }
        let apiURL = this.props.config.BOTBUILDER_URL;
        this.props.OnGetAllAssistantsData(apiURL)
        let data = apiData;
        this.callUsersDetails(data);
    }

    UsersDetails = () => {
        let projName;

        projName = this.state.projectname ? this.state.projectname : allProj

        let apiURL = this.props.config.BOTBUILDER_URL;
        let data = {
            "startDate": moment(this.state.startDate).format('YYYY-MM-DD'),
            "endDate": moment(this.state.endDate).format('YYYY-MM-DD'),
            "project": projName,
            "orgName": localStorage.getItem('orgName'),
            "virtualAssist":this.state.vaName
        }
        this.props.conversationalMetricsData(apiURL, data)
    }
    VAListClick = (data) => {
        if (data.value) {
            this.setState({ vaName: data.value })
        }
        this.setState({ projectname: allProj },()=>{
            let nrwrdata = {
                "startDate": moment(this.state.startDate).format('YYYY-MM-DD'),
                "endDate": moment(this.state.endDate).format('YYYY-MM-DD'),
                "project": this.state.projectname,
                "orgName": localStorage.getItem('orgName'),
                "virtualAssist": data.value
            }
            this.props.ReportPageData(nrwrdata)
        })

        setTimeout(() => {
            this.UsersDetails();
        }, 100);

        
    }
    projectclick = (data) => {
        if (data.value) {
            this.setState({ searchT: "" })
            this.setState({ projectname: data.value });
            this.props.onchangeProjectAction(data.value);

            setTimeout(() => {
                this.UsersDetails();
            }, 100);

            let nrwdata = {
                "startDate": moment(this.state.startDate).format('YYYY-MM-DD'),
                "endDate": moment(this.state.endDate).format('YYYY-MM-DD'),
                "project": data.value,
                "orgName": localStorage.getItem('orgName'),
                "virtualAssist":this.state.vaName
            }
            this.props.ReportPageData(nrwdata)
        }
    }
    UserDescription = (val) => {
        this.props.history.push('/reportdescription?' + val)
    }

    getDateValues = (dateValue) => {
        let projName = this.state.projectname
        this.setState({
            startDate: dateValue.startDate,
            endDate: dateValue.endDate
        })
        setTimeout(() => {
            this.UsersDetails()
        }, 100);

        let data = {
            "startDate": moment(dateValue.startDate).format('YYYY-MM-DD'),
            "endDate": moment(dateValue.endDate).format('YYYY-MM-DD'),
            "project": projName == 'common' ? allProj : projName,
            "orgName": localStorage.getItem('orgName'),
            "virtualAssist":this.state.vaName
        }
        this.props.ReportPageData(data)

    };

    exportData = () => {
        let projName = this.state.projectname
        let data = {
            "startDate": moment(this.state.startDate).utcOffset("+05:30").format('YYYY-MM-DD'),
            "endDate": moment(this.state.endDate).utcOffset("+05:30").format('YYYY-MM-DD'),
            "project": projName == 'common' ? allProj : projName,
            "orgName": localStorage.getItem('orgName'),
            "virtualAssist":this.state.vaName
        }

        this.props.onExportReportData(this.props.config.BOTBUILDER_URL, data, 'download-conversational-metrics')

    }
    getGraphData = () => {
        
        let data = {}
        let showdata = '';
        let sorted_data;
        let details = []
        let totaluser = this.props.conMetricsData?.dateWiseUsers
        if(totaluser) details = totaluser  ? totaluser : []

        let chart_data = [];
        let chart_labels = [];
        let weekly = false;

        if(details){
            for (let [key, value] of Object.entries(details)) {
                if(key.toLowerCase() === "day" && Object.keys(value[0]).length>=1 && value.length<8){                 
                    sorted_data = value;
                    showdata = "day" 
                    break 
                    
                }else if (key.toLowerCase() === "week" && Object.keys(value[0]).length>=1 && value.length <= 9){
                    sorted_data = value;
                    weekly = true
                    showdata = 'week'

                } else if(key.toLowerCase() === 'month' && Object.keys(value[0]).length>=1 && !weekly){
                    sorted_data = value;
                    showdata = 'month'

                }else if(key.toLowerCase() === 'year' && Object.keys(value[0]).length>=1 ){
                    sorted_data = value;
                    showdata = 'year'
                }
            }
        }

        if(sorted_data){
            sorted_data?.forEach((v) => {
                chart_labels.push(Object.keys(v)[0])
                chart_data.push(Object.values(v)[0])
            });
        }
        chart_labels = chart_labels.map((v) => {
            return (showdata == 'year') ? `${moment(v).format("YYYY")}` : (showdata == 'month') ? `${moment(v).format("MMM")}` : `${moment(v).format("DD")}-${moment(v).format("MMM")}`
        })
        data = {
            labels: chart_labels,
            datasets: [
                {
                    label: "Users",
                    data: chart_data,
                    fill: false,
                    backgroundColor: "#2A93DF",
                    borderColor: "#2A93DF",
                    pointBorderWidth: 4,
                    pointBorderColor: "#FFFFFF",
                    pointBackgroundColor: '#2A93DF',
                    pointRadius: 6,
                    borderDash: [2, 2],
                }
            ],
        };
        const options = {
            maintainAspectRatio: false, // Don't maintain w/h ratio
            scales: {
                x: {
                    grid: {
                        display: false,
                        drawOnChartArea: false,
                    },
                },
                y: {
                    grid: {
                        color: '#B8B6B6',
                        display: true,
                        drawOnChartArea: true,
                        drawBorder: false
                    },
                    ticks: {
                        precision: 0
                    }
                },

            },
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    mode: 'index',
                    intersect: false,
                    displayColors: false,
                    callbacks: {
                        title: function () { }
                    }
                }
            }
        }

        return {
            data,
            options
        }
    }


    render() {
        let { data, options } = this.getGraphData()
        let VAList = '';
        let project = [];
        let alphabeticalOrder = this.props.projectList.bots.sort();
        let projectList = '';
        let virtualassistantName = this.props.AssistantsList.map((item)=>{
            return(
              item.virtualassistantName
            )
        })
        if (this.props.AssistantsList) {
            let newVA = ["All",...new Set(virtualassistantName)]
            VAList = newVA.map(ele => {
                return { value: ele, label: ele }
            })
            let obj = this.props.AssistantsList.find(ele => ele.virtualassistantName == this.state.vaName)
            if (obj) {
                project = obj.project.filter(ele => ele !== '');
            } else {
                project = [];
            }
            let vaProjectList = alphabeticalOrder.filter(projects => project.includes(projects))
            if (vaProjectList) {
                let newArray = ["All VA Projects", ...new Set(vaProjectList)]
                projectList = newArray.map(ele => {
                    return { value: ele, label: ele }
                })
            }
        }
        let allProjectsList = ''
        if (this.props.projectList) {
            let nwra = ["All Projects", ...new Set(this.props.projectList.bots)]
            allProjectsList = nwra.map((item) => {
                return { value: item, label: item }
            })
        }
        let vaProjects = this.props.AssistantsList.map((item)=>{
            return item.project
        })
        let combineList = vaProjects.flat(3);
        let VAProjectsList = '';
        if(vaProjects){
            let nwrwArr = ["All VA Projects",...new Set(combineList)]
            VAProjectsList = nwrwArr.map((ele)=>{
                return {value:ele, label:ele}
            })
        }
        
        let totalUsersData = this.props.conMetricsData?.users;
        let repeatUsersData = this.props.repeatUsersData;
        let newUsersData = this.props.newUsersData;
        let totalagenttransfer = this.props.conMetricsData?.totalAgentTransfers;
        let positiveFeedback = 0;
        let negitiveFeedback = 0;
        if (this.props.conMetricsData !== null && this.props.conMetricsData !== undefined) {
            positiveFeedback = this.props.conMetricsData?.positive;
            negitiveFeedback = this.props.conMetricsData?.negative;
        }
        let ansCount = this.props.conMetricsData?.answered
        let unansCount = this.props.conMetricsData?.unAnswered
        let TotalAnsUnansCount = this.props.conMetricsData?.conversations

        totalUsersData = (totalUsersData && totalUsersData != '') ? totalUsersData : 0
        repeatUsersData = (repeatUsersData && repeatUsersData != '') ? repeatUsersData : 0
        newUsersData = (newUsersData && newUsersData != '') ? newUsersData : 0
        const { usersCount,
            conversationsCount,
            conversationsSentiment,
            agentHandover,
            conversationsHandled } = this.state;
        return (<>
            { this.props.conMetricsLoading === true ? <SpinnerPage /> : null}
            <div className="botReportsTiles">
                <Row>
                    <div className="col-md-6">
                        <h2 className="reportsTag">Conversation Metrics Insights</h2>
                        <div className='reportsDesc'>
                            <span className="reportsPara">
                                Provision to view users & conversations insights
                            </span>
                            <ButtonComponent variant='outlined'
                                label='Download Insights' className='buttonClass dld-btn'
                                clicked={() => { this.exportData() }} />
                        </div>
                    </div>
                    
                        <DashboardFilters
                            AssistantsList={this.props.AssistantsList}
                            vaName={this.state.vaName}
                            VAList={VAList}
                            VAListClick={(event) => { this.VAListClick(event) }}
                            projectname={this.state.projectname}
                            projectList={projectList}
                            allProjectsList={allProjectsList}
                            projectclick={(event) => { this.projectclick(event)}}
                            getDateValues={this.getDateValues}
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            location={this.props.location}
                            VAProjectsList={VAProjectsList} />
                </Row>

                <Grid container lg={12} className="con-cards">
                    <Grid item md={4} >
                        <MetricCard
                            heading={usersCount.heading}
                            count={totalUsersData}
                            icon={usersCount.icon}
                            backgroundColor={usersCount.backgroundColor}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <MetricCard
                            heading={conversationsCount.heading}
                            count={TotalAnsUnansCount}
                            icon={conversationsCount.icon}
                            backgroundColor={conversationsCount.backgroundColor}
                        />
                    </Grid>
                    <Grid item md={4} onClick={() => this.UserDescription('sentiment')}>
                        <MetricCard
                            heading={conversationsSentiment.heading}
                            headerIcon={conversationsHandled.headerIcon}
                            count={positiveFeedback ? positiveFeedback : 0}
                            count2={negitiveFeedback ? negitiveFeedback : 0}
                            label1="Positive"
                            label2="Negative"
                            icon={conversationsSentiment.icon}
                            backgroundColor={conversationsSentiment.backgroundColor}
                        />
                    </Grid>
                    <Grid item md={8} >
                        <div className='report-graph-wrapper'>
                            <div className=" mb-2 graphOuterContainer pb-2">
                                <div className='d-flex justify-content-between title-row pt-3'>
                                    <span className='chart-title'>Users</span>
                                </div>
                                <div className="GraphContainer px-4" >
                                    <Line data={data} options={options} />
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item md={4} className="right-2">
                        <span onClick={() => this.UserDescription('handled')}>
                            <MetricCard
                                heading={conversationsHandled.heading}
                                headerIcon={conversationsHandled.headerIcon}
                                count={ansCount}
                                icon={conversationsHandled.icon}
                                backgroundColor={conversationsHandled.backgroundColor}
                                count2={unansCount}
                                label1="Answered"
                                label2="Unanswered"
                            />
                        </span>
                        <span onClick={() => this.UserDescription('agenthandover')}>
                            <MetricCard
                                heading={agentHandover.heading}
                                headerIcon={conversationsHandled.headerIcon}
                                count={totalagenttransfer}
                                icon={agentHandover.icon}
                                backgroundColor={agentHandover.backgroundColor}
                            />
                        </span>
                    </Grid>
                </Grid>
            </div>
        </>
        )
    }
}

const mapStateToProps = state => {
    return {
        reportDetail: state.reportDashboard.reportDetail,
        totalUsersData: state.reportDashboard.totalUsers,
        newUsersData: state.reportDashboard.newUsers,
        repeatUsersData: state.reportDashboard.repeatUsers,
        IsProjectChange: state.projectview.IsProjectChange,
        totalfeedbackData: state.reportDashboard.totalFeedback,
        agentTranforms: state.reportDashboard.totalAgentTransforms,
        totalAnsUnansCount: state.reportDashboard.totalAnsUnansCount,
        conLoading: state.reportDashboard.conLoading,
        conUsers: state.reportDashboard.conUsers,
        conFeedbackLoading: state.reportDashboard.conFeedbackLoading,
        conAgentLoading: state.reportDashboard.conAgentLoading,
        conUserReportLoading: state.reportDashboard.conUserReportLoading,
        projectList: state.user.projects,
        totalUsersReportData: state.reportDashboard.totalUsersReport,
        AssistantsList: state.projectview.AllAssistantsList,
        conMetricsData: state.reportDashboard.conMetricsData,
        conMetricsLoading: state.reportDashboard.conMetricsLoading
    };
};
const mapDispatchToProps = dispatch => {
    return {
        totalUsersReport: (apiUrl, data) => dispatch(actions.totalUsersReport(apiUrl, data)),
        totalUsers: (apiUrl, data) => dispatch(actions.totalUsers(apiUrl, data)),
        onchangeProjectAction: (projectname) => dispatch(actions.changeProjectAction(projectname)),
        totalfeedback: (apiUrl, data) => dispatch(actions.totalfeedback(apiUrl, data)),
        totalAgentTransforms: (apiURL, data) => dispatch(actions.totalAgentTransforms(apiURL, data)),
        onExportReportData: (apiUrl, data, url) => dispatch(actions.exportReportData(apiUrl, data, url)),
        totalAnswerCount: (apiUrl, data) => dispatch(actions.totalAnswerCount(apiUrl, data)),
        ReportPageData: (data) => dispatch(actions.ReportPageData(data)),
        OnGetAllAssistantsData: (APIURL) => dispatch(actions.GetAllAssistantsData(APIURL)),
        conversationalMetricsData: (APIURL,data) => dispatch(actions.conversationalMetricsData(APIURL,data))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ConversationalDashboard);

