import get from 'lodash/get';
import { PostCalltoServer } from '../../../utility';
import fetchRecommendedSolutionFailureAction from './fetchRecommendedSolutionFailureAction';
import fetchRecommendedSolutionStartAction from './fetchRecommendedSolutionStartAction';
import fetchRecommendedSolutionSuccessAction from './fetchRecommendedSolutionSuccessAction';
import getProjectDetails from "../../../../containers/SettingPage/SettingInnerPages/helpers/getProjectDetails";

const fetchRecommendedSolutionDataAction = (payload) => (dispatch, getState) => {
    const state = getState();
    const projectName = getProjectDetails();
    const score = (state?.agentInsights?.yesQuestions)?.length > 0 ? state.agentInsights.yesQuestions.map(item => item.score) : [];
    const yesQuestions = (state?.agentInsights?.yesQuestions)?.length > 0 ? state.agentInsights.yesQuestions.map(item => item.symptoms) : [];
   
    const temp = [];
    for(let i=0; i<score.length ; i++){
        temp.push({score: score[i], symptoms:yesQuestions[i]})
    }
    
    const url = payload + `/product-intelligent-triage/settings/RecommendedSolutions/${projectName}`;   
    console.log("yesQuestions: ", temp);
    dispatch(fetchRecommendedSolutionStartAction());
    PostCalltoServer(url, temp)
        .then(response => {
            if (get(response, 'data.status.responseType', '') === "success") {
                dispatch(fetchRecommendedSolutionSuccessAction(response.data.data));
            }
            else {
                dispatch(fetchRecommendedSolutionFailureAction(response.data.status.responseType))
            }
        }).catch(err => {
            dispatch(fetchRecommendedSolutionFailureAction(err.message))
        });
};

export default fetchRecommendedSolutionDataAction;
