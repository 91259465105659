// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.va-container .header h3 {
    margin-bottom: 0 !important;
    line-height: 0 !important;
}

.va-card{
    height: 200px !important;
}

 .va-container .para-graph {
    font-size: 12px !important;
}

.author-text {
    font-family: 'Roboto-bold';
    font-style: normal;
    font-weight: 900;
    font-size: 10px;
    color: #656060;
}
.card-paragraph{
    font-family: 'Roboto-bold';
font-style: normal;
font-weight: 400;
font-size: 12px;
color: #000000;
}
.va-cards-wrapper{
    padding-left: 30px;
    height: 86vh;
    overflow-y: auto;
}
.homeSearch.VASearchbox{
    position: static !important;
    margin-left: auto ;
    margin-right: 1rem ;
}`, "",{"version":3,"sources":["webpack://./src/containers/SettingPage/SettingInnerPages/VirtualAssistant/va-container.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,yBAAyB;AAC7B;;AAEA;IACI,wBAAwB;AAC5B;;CAEC;IACG,0BAA0B;AAC9B;;AAEA;IACI,0BAA0B;IAC1B,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,cAAc;AAClB;AACA;IACI,0BAA0B;AAC9B,kBAAkB;AAClB,gBAAgB;AAChB,eAAe;AACf,cAAc;AACd;AACA;IACI,kBAAkB;IAClB,YAAY;IACZ,gBAAgB;AACpB;AACA;IACI,2BAA2B;IAC3B,kBAAkB;IAClB,mBAAmB;AACvB","sourcesContent":[".va-container .header h3 {\n    margin-bottom: 0 !important;\n    line-height: 0 !important;\n}\n\n.va-card{\n    height: 200px !important;\n}\n\n .va-container .para-graph {\n    font-size: 12px !important;\n}\n\n.author-text {\n    font-family: 'Roboto-bold';\n    font-style: normal;\n    font-weight: 900;\n    font-size: 10px;\n    color: #656060;\n}\n.card-paragraph{\n    font-family: 'Roboto-bold';\nfont-style: normal;\nfont-weight: 400;\nfont-size: 12px;\ncolor: #000000;\n}\n.va-cards-wrapper{\n    padding-left: 30px;\n    height: 86vh;\n    overflow-y: auto;\n}\n.homeSearch.VASearchbox{\n    position: static !important;\n    margin-left: auto ;\n    margin-right: 1rem ;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
