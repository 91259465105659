import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../utility';

const initialState = {
    error: null,
    loading: false,
    saveFields: null,
    FieldsData1: null,
};
const saveFieldsStart = (state) => {
    return updateObject(state, { error: null, loading: true });
};
const saveFieldsSuccess = (state, action) => {
    return updateObject(state, {
        saveFields: action.saveFields,
        error: null,
        loading: false,
    });
};
const saveFieldsFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};
const GetFieldsDataStart = (state) => {
    return updateObject(state, { error: null, loading: true });
};
const GetFieldsDataSuccess = (state, action) => {
    console.log(action.FieldsData, 'reducer');
    return updateObject(state, {
        FieldsData1: action.FieldsData,
        error: null,
        loading: false,
    });
};
// const GetFieldsDataFail = (state, action) => {
//     return updateObject(state, {
//         error: action.error,
//         loading: false
//     });
// };
const CrmLayout = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SAVEFIELDS_START: return saveFieldsStart(state);
        case actionTypes.SAVEFIELDS_SUCCESS: return saveFieldsSuccess(state, action);
        case actionTypes.SAVEFIELDS_FAIL: return saveFieldsFail(state, action);
        case actionTypes.GET_FIELDS_DATA_START: return GetFieldsDataStart(state);
        case actionTypes.GET_FIELDS_DATA_SUCCESS: return GetFieldsDataSuccess(state, action);
        case actionTypes.GET_FIELDS_DATA_FAIL: return saveFieldsFail(state, action);
        default:
            return state;
    }
};
export default CrmLayout;