import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

const editRootCauseToSymptomsReducer = (state, payload) => {
    const symptomRootCauseData = state.symptomRootCauseData;
    const updatedData = symptomRootCauseData.map(item => {
        if (item.rootcause === payload.selectedItem.rootcause) {
            const symptomsdata = get(item, 'rootcausedata', []);
            if (isEmpty(symptomsdata)) {
                return item;
            }
            const updatedSymptomsdata = symptomsdata.map((symptoms, index) => {
                if (index === payload.id) {
                    return {
                        ...symptoms,
                        ...payload.formData
                    };
                }
                return symptoms;
            });
            return {
                ...item,
                rootcausedata: updatedSymptomsdata
            };
        }
        return item;
    });
    return {
        ...state,
        symptomRootCauseData: updatedData
    }
}

export default editRootCauseToSymptomsReducer;