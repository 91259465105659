import React, { useState, useEffect } from "react";
import Drawer from "@material-ui/core/Drawer";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import "./SymptomAndRootCause.css";
import { Col, Form } from "react-bootstrap";
import {
 
  PostCalltoServer,
} from "../../../../../../store/utility";
import get from "lodash/get";
import DrawerHeaderContent from "../../../components/DrawerHeaderContent/DrawerHeaderContent";
import { useDispatch, useSelector } from "react-redux";
import InputWithLabelComponent from "../../../components/InputWithLabelComponent/InputWithLabelComponent";
import isEmpty from "lodash/isEmpty";
import ProductAttributesComponent from "../ProductAttributesComponent/ProductAttributesComponent";
import fetchProductTriageDataAction from "../../../../../../store/actions/agentInsights/fetchProductTriageData/fetchProductTriageDataAction";
import PrimaryButtonWithLoader from "../../../components/PrimaryButtonWithLoader/PrimaryButtonWithLoader";
import getProjectDetails from "../../../helpers/getProjectDetails";

import isEqual from "lodash.isequal";
import AutoCompleteComponent from "../autoComplete/AutoComplete";
import { getSymptomsDataAction } from "../../../../../../store/actions/adminSettings/adminSettingsActions";
import { SetSuccessMessage } from "../../../../../../store/actions";
import { event } from "jquery";
import { Tooltip, withStyles } from '@material-ui/core';


const useStyles = makeStyles({
  list: {
    width: "29rem",
  },
});

const AddSymptomsDetails = ({
  config,
  showDrawer,
  toggleDrawer,
  symtomsheading,
  savebutton,
  symtomtitle,
  associatedIssues,
  allOption,
  onRemoveItem,
  disabled,
  placeholder,
  label,
  data,
  triageQuestion,
  addSymptom,
  closeDrawer,
  advanceFilters,
  symptomsData,
  getSymptoms,
  selectedRootCause,
  ...rest
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const projectName = getProjectDetails();
  const [productAttributesData, setProductAttributesData] = useState([]);
  const filterData = useSelector((state) => state.agentInsights.filterData);
  const productTriageDataLoading = useSelector(state => state.agentInsights.productTriageDataLoading);
  const [rootCauseOptions, setRootCauseOptions] = useState([]);
  const [seletctedRootcauseData, setSeletctedRootcauseData] = useState([]);
  const [formData, setFormData] = useState({
    symptoms: "",
    leadingquestion: "",
    description: [],
    rootcauses: [],
    resolution: []
  });
  const [nonTrainData, setNonTrainData] = useState("");
  const [saveLoading, setSaveLoading] = useState(false);
  const [rootCauseDataLoading, setRootCauseDataLoading] = useState(false);  
  const [symptomError, setSymptomError] = useState(false);
  const [mandatory, setMandatory] = useState(false);
  const [rootCauseVal,setRootCauseVal] = useState([]);
  const [isTrained ,setIsTrained]=useState(null)  
  const associateIssueElements = [
    {
      type: "text",
      id: 1,
      value: "",
    },
  ];
  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 12,
      fontFamily: "Roboto-Regular",
    },
    arrow: {
      color: theme.palette.common.white,
      "&:before": {
        border: "1px solid #E6E8ED",
        boxShadow: theme.shadows[1],
      },
    },
  }))(Tooltip);

  const [associateIssueArr, setAssociateIssueArr] = useState(
    associateIssueElements
  );

  const addAssociateIssue = () => {
    setAssociateIssueArr((s) => {
      return [
        ...s,
        {
          type: "text",
          value: "",
        },
      ];
    });
  };

  const handleChange = (e) => {
    e.preventDefault();
    const index = e.target.id;
    setAssociateIssueArr((s) => {
      const newArr = s.slice();
      newArr[index].value = e.target.value;
      return newArr;
    });
  };

  const getOptions = (data) => {
    if (isEmpty(data)) {
      return [];
    }
    if(isEqual(data.attribute_data,null)){
      return [];
    }
    const attrData = get(data, "attribute_data", []);
    return attrData.map((option) => ({
      label: option,
      value: option,
    }));
  };

  const symptomValidation = (updatedFormData) => {
      if(updatedFormData.symptoms!= "" &&  updatedFormData.leadingquestion!= "" && nonTrainData!= "" && updatedFormData.description!= ""){
        if (!isEqual(updatedFormData.rootcauses), ((nonTrainData == ""))) return true;
      }
      else if(updatedFormData.symptoms!= "" &&  updatedFormData.leadingquestion!= "" && updatedFormData.description!= "" && seletctedRootcauseData.length!= 0){
        if (!isEqual(updatedFormData.rootcauses), ((seletctedRootcauseData.length == 0))) return true;
      }else{
        return true;
      }
     // if (isEqual(updatedFormData.symptoms, "")) return true;
      // if (isEqual(updatedFormData.leadingquestion, "")) return true;
      // if(nonTrainData == "" || seletctedRootcauseData.length == 0){
      //   if (!isEqual(updatedFormData.rootcauses), ((nonTrainData == ""))) return true;
      //   if (!isEqual(updatedFormData.rootcauses), ((seletctedRootcauseData.length == 0))) return true;
      // }
   
      // if (isEqual(updatedFormData.description.length, 0)) return true;
      // else {
      //   if (
      //     isEqual(
      //       updatedFormData.productattributes.map((item) => item.attributename)
      //         .length,
      //       filterData.map((item) => item.attribute_name).length
      //     )
      //   )
      //     return false;
      // }
    // if (isEqual(updatedFormData.rootcauses.length, 0)) return true;
    // if (
    //   !isEqual(
    //     updatedFormData.productattributes.map((item) => item.attributename).length,
    //     filterData.map((item) => item.attribute_name).length
    //   )
    // )
    //   return true;
    // if (isEqual(updatedFormData.description.length, 0)) return true;
    // else {
    //     if (
    //       isEqual(
    //         updatedFormData.productattributes.map((item) => item.attributename).length,
    //         filterData.map((item) => item.attribute_name).length
    //       )
    //     )
    //       return false;
    //   }
  };

  const saveHandler = () => {
    const descriptionArr = associateIssueArr.map((item) => item.value);
    const productattributes = getProductAttributes(productAttributesData);
    const rootCauseOptions = get(formData, "rootcauses", []);
    let rootcauses = seletctedRootcauseData
    if(selectedRootCause){
      rootcauses = rootCauseVal
    }
    if(seletctedRootcauseData.length === 0){
      rootcauses = [{rootcause:nonTrainData,rootcauseTitle:nonTrainData}]
    }
    const newFormData = {
      symptoms: get(formData, "symptoms", ""),
      leadingquestion: get(formData, "leadingquestion", ""),
      resolution: get(formData, "resolution", []),
      description: descriptionArr,
      rootcauses: rootcauses,
      project: projectName,
    };
    const updatedFormData = {
      ...newFormData,
      productattributes,
    };
    if (!symptomValidation(updatedFormData)) {
      const url =
        config.INSIGHTS_API_URL +
        `/product-intelligent-triage/settings/addSymptoms`;
      setSaveLoading(true);
      PostCalltoServer(url, updatedFormData)
        .then((response) => {
          if (get(response, "data.status.responseType", "") === "success") {
            setSaveLoading(false);
            closeDrawer();
            const newSymptom = {
              ...productAttributesData,
              symptoms: get(formData, "symptoms", ""),
              leadingquestion: get(formData, "leadingquestion", "")
            };
            dispatch(getSymptomsDataAction(symptomsData.concat(newSymptom)));
            addSymptom(newSymptom);
            dispatch(SetSuccessMessage('New Symptom Added Successfully'));
          } else {
            setSaveLoading(false);
          }
        })
        .catch(() => {
          setSaveLoading(false);
        });
    } else setMandatory(symptomValidation(updatedFormData));
  };

  const getRootCauseOptions = (data) => {
    if(selectedRootCause){
      let ert = data.find(item => item.rootcause === selectedRootCause)
      setRootCauseVal([{
        rootcauseTitle:ert.rootcauseTitle, rootcause:selectedRootCause
      }])
    }
    
    return data.map((item) => ({
      label: get(item, "rootcauseTitle", ""),
      value: get(item, "rootcause", ""),
    }));
  };

  useEffect(() => {
    dispatch(fetchProductTriageDataAction(config));
    setProductAttributesData([]);
    const url =
      config.INSIGHTS_API_URL +
      `/product-intelligent-triage/settings/getRootCausedetailsSet/project/${projectName}`;
    setRootCauseDataLoading(true);
    PostCalltoServer(url, advanceFilters)
      .then((response) => {
        if (get(response, "data.status.responseType", "") === "success") {
          setRootCauseDataLoading(false);
          const data = JSON.parse(get(response, "data.data", []));
          setIsTrained(data);
          setRootCauseOptions(getRootCauseOptions(data));
        } else {
          setRootCauseDataLoading(false);
          setRootCauseOptions([]);
        }
      })
      .catch(() => {
        setRootCauseDataLoading(false);
        setRootCauseOptions([]);
      });
      
  }, []);

  const onChangeHandler = (event, type) => {
    setFormData((formData) => ({
      ...formData,
      [type]: event.target.value,
    }));
    setMandatory(false);
  };

  const onRemoveItemHandler = (name) => (selectedItem) => {
    if (name === "rootcauses") {
      const selectedRootCauses = get(formData, "rootcauses", []);
      const filteredRootCauses = seletctedRootcauseData.filter(
        (item) => item.rootcauseTitle !== selectedItem
      );
      setSeletctedRootcauseData(filteredRootCauses);
      setFormData((formData) => ({
        ...formData,
        [name]: selectedRootCauses.filter((item) => item !== selectedItem),
      }));
    } else {
      const selectedProduct = get(productAttributesData, name, []);
      const filteredProducts = selectedProduct.filter(
        (item) => item !== selectedItem
      );
      setProductAttributesData((productAttributesData) => ({
        ...productAttributesData,
        [name]: filteredProducts,
      }));
    }
  };
  const removeAssociatedIssues = (index) => {
    const list = [...associateIssueArr];
    list.splice(index, 1);
    setAssociateIssueArr(list);
  };

  useEffect(() => {}, [seletctedRootcauseData]);

  const onDropdownChangeHandler = (selectedItems, name) => {
    if (name === "rootcauses") {
       const rootcauseValues= selectedItems.map(obj=> ({rootcause:obj.label,rootcauseTitle:obj.label}))
      setSeletctedRootcauseData(rootcauseValues);
      setFormData((formData) => ({
        ...formData,
        [name]: selectedItems.map((item) => item.value),
      }));
    } else {
      setProductAttributesData((productAttributesData) => ({
        ...productAttributesData,
        [name]: selectedItems.map((item) => item.value),
      }));
    }
    setMandatory(false);
  };

  const getProductAttributes = (productData) =>
    Object.entries(productData).map(([key, value]) => {
      return {
        attribute_name: key,
        attribute_data: value,
      };
    });

  const symptomUpdate = (value) => {
    setFormData((formData) => ({
      ...formData,
      ["symptoms"]: value,
    }));
    if((symptomsData.map((item) => item.symptoms)).includes(value)) 
      setSymptomError(true);
    else
    setSymptomError(false);
    setMandatory(false);
  };

  return (
    <React.Fragment>
      {showDrawer ? (
        <Drawer
          anchor={"right"}
          open={showDrawer}
          onClose={toggleDrawer(false)}
        >
          <div
            className={clsx(classes.list, classes.fullList)}
            role="presentation"
          >
            <DrawerHeaderContent
              heading={symtomsheading}
              toggleDrawer={toggleDrawer}
            />
            <div className="d-flex flex-column Symtom-Item">
              <div className="mt-1 mb-2">
                <Col md={12}>
                  <Form.Label className="mb-1 text-box-title  adding-other-input">
                    {symtomtitle}
                  </Form.Label>
                  <AutoCompleteComponent
                    suggestions={symptomsData.map((item) => item.symptoms)}
                    symptomUpdate={symptomUpdate}
                    symptomError={symptomError}
                    placeholder={"Add Symptom"}
                    type="Symptom"
                  />
                </Col>
              </div>
              <div className="mt-1">
                <Col md={12}>
                  <span className="adding-other-input">{associatedIssues}</span>
                  <LightTooltip
                    arrow
                    title={" Click to add more Associated Issues"}
                    placement="top-start"
                    >
                       <img
                    className="addingInputBox pl-1"
                    onClick={addAssociateIssue}
                    src="./Icons/AddBlack.svg"
                  />
                   {/* <img src="./images/info_icon.png" style={{marginLeft:'5px', filter:`${activeId == scope ? "invert(1)":"none"}`}}/> */}
                  </LightTooltip>
                 
                  {associateIssueArr.map((item, i) => {
                    return (
                      <div className="d-flex" key={i}>
                        <input
                          className="mb-2 addAssociatebox"
                          placeholder={"Enter Text Here"}
                          onChange={handleChange}
                          value={item.value}
                          id={i}
                          type={item.type}
                          size="57"
                        />
                        {isEqual(i, 0) ? null : (
                          <img
                            className="pl-1 addingInputBox"
                            name={item.value}
                            onClick={() => removeAssociatedIssues(i)}
                            src="./images/minus.svg"
                          />
                        )}
                      </div>
                    );
                  })}
                </Col>
              </div>
              <div className="mt-1 mb-2">
                <Col md={12}>
                  <span className="adding-other-input ">{triageQuestion}</span>
                  {/* <img
                    className="pl-1 addingInputBox "
                    src="./Icons/AddBlack.svg"
                  /> */}
                  <InputWithLabelComponent
                    type="text"
                    className="mt-2"
                    name="symptomTitle"
                    showLabel={false}
                    value={formData.leadingquestion}
                    readOnly={false}
                    onChange={(e) => onChangeHandler(e, "leadingquestion")}
                  />
                </Col>
              </div>
              <div className="mt-1">
              {rootCauseOptions.length ? (
                <ProductAttributesComponent
                  options={rootCauseOptions}
                  name={"Associated Root Causes"}
                  label={"Associated Root Causes*"}
                  boldTitle={true}
                  search={false}
                  placeholder={"Select"}
                  className={"mt-1 mb-2"}
                  targetSourceDescription={"source_name"}
                  sourceHelpIcon={false}
                  attributeHelpIcon={false}
                  value={get(formData, "rootcauses", [])}
                  onChange={(event) =>
                    onDropdownChangeHandler(event, "rootcauses")
                  }
                  onRemoveItem={onRemoveItemHandler("rootcauses")}
                />
              ) :  <div className="mt-1 mb-2">
              <Col md={12}>
                <span className="adding-other-input ">{"ROOT CAUSES"}</span>
                <InputWithLabelComponent
                  type="text"
                  className="mt-2"
                  name="symptomTitle"
                  showLabel={false}
                  value={nonTrainData}
                  readOnly={false}
                  onChange={(e) => setNonTrainData(e.target.value)}
                />
              </Col>
            </div>}
              </div>
              {((filterData.length && filterData[0].attribute_data != null && !productTriageDataLoading))
                ? filterData.map((item) => {
                    return (
                      <ProductAttributesComponent
                        options={getOptions(item)}
                        name={item.attribute_name}
                        label={item.attribute_name}
                        boldTitle={true}
                        search={true}
                        placeholder={"Select"}
                        className={""}
                        targetSourceDescription={"source_name"}
                        sourceHelpIcon={false}
                        attributeHelpIcon={false}
                        value={get(
                          productAttributesData,
                          item.attribute_name,
                          ""
                        )}
                        onChange={(event) =>
                          onDropdownChangeHandler(event, item.attribute_name)
                        }
                        onRemoveItem={onRemoveItemHandler(item.attribute_name)}
                      />
                    );
                  })
                : null}
                <div className="ml-4">
                {mandatory ? (
                  <Form.Label className="text-box-error">
                    {"All fields are mandatory"}
                  </Form.Label>
                ) : null}
              </div>
            </div>
            <div className="d-flex flex-row">
              <PrimaryButtonWithLoader
                className="ml-5 mb-2"
                size="lg"
                onClick={saveHandler}
                text={savebutton}
                loading={saveLoading}
              />
            </div>
          </div>
        </Drawer>
      ) : null}
    </React.Fragment>
  );
};

AddSymptomsDetails.propTypes = {
  symtomsheading: PropTypes.string,
  symtomtitle: PropTypes.string,
  associatedIssues: PropTypes.string,
  triageQuestion: PropTypes.string,
  savebutton: PropTypes.string,
  disabled: PropTypes.bool,
};

AddSymptomsDetails.defaultProps = {
  symtomsheading: "Add Symptom",
  symtomtitle: "Symptom*",
  associatedIssues: "Associated Issues*",
  triageQuestion: "Triage Question*",
  savebutton: "SAVE",
  disabled: false,
};

export default AddSymptomsDetails;
