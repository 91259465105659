import React from 'react';
import 'bs-stepper/dist/css/bs-stepper.min.css';
import Stepper from 'bs-stepper'
import './CreateUseCase.css';
import ManageMasterCard from '../Insight/AdminConfiguration/TraiageConfigurationTabs/ManageMasterList/ManageMasterCard';
import TriageDataStatus from '../Insight/AdminConfiguration/TraiageConfigurationTabs/ManageMasterList/helpers/TriageDataStatus';
import getRunModalBodyData from '../Insight/AdminConfiguration/TraiageConfigurationTabs/ManageMasterList/helpers/getRunModalBodyData';
import { GetCalltoServer, PostCalltoServer } from '../../../../store/utility';
import get from "lodash/get";
import { connect } from 'react-redux';
import getProjectDetails from '../helpers/getProjectDetails';
import Grid from "@material-ui/core/Grid";
import { Table } from 'react-bootstrap';
import ButtonComponent from '../../../../components/UIComponents/Button/ButtonComponent';
import CreateUseCareDrawer from './CreateUseCareDrawer';
import DrawerComponent from '../../../../components/UIComponents/Drawer/DrawerComponent';
import UnsupervisedLearning from './UnsupervisedLearning';
import PrimaryButtonWithLoader from '../components/PrimaryButtonWithLoader/PrimaryButtonWithLoader';
import { Col, Row } from 'react-bootstrap';
import UseCase from './UseCase';
import Heading3 from "../components/Typography/Heading3/Heading3";
import "./UnsupervisedLearning.css"
import { Card } from "react-bootstrap";
import ClusterDrawer from "./ClusterDrawer";
import { withRouter } from "react-router-dom";
import *as actions from '../../../../store/actions/index';
import { GetSourceAttributes } from '../../../../store/actions/index';
import config from '../../../../config';
import { data } from 'jquery';
import { GetUseCaseDetailsByName } from '../../../../store/actions/index';
import SpinnerPage from '../../../../components/UI/Loader/Loader';
import ModelOutput from './modelOutput';
import DataReview from './dataReview';
import IngestPredictionData from './IngestPredictionData';
class CreateUseCase extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            progress: 0,
            speed: 1,
            triageData: false,
            showData: false,
            CallCount: 10,
            triageMsg: '',
            loop: 0,
            openDrawer: false,
            saveData: false,
            backButton: false,
            openDrawer1: false,
            backButton1: false,
            datatocollect: " ",
            UseCaseNameData: [],
            loading: true,
            childDataa: "true",
            modaloutput: false,
        };
        this.frame = this.frame.bind(this);
    }
    componentDidMount() {
        // if(document.getElementsByClassName('#stepper1') != undefined && document.getElementsByClassName('#stepper1') != null){
        this.stepper = new Stepper(document.querySelector('#stepper1'), {
            linear: false, animation: true
        })
        if (this.props.viewModelOutput) {
            this.stepper.to(3)
        }
        if (this.props.ingestData) {
            this.stepper.to(5)
        }
        let url = 'http://10.14.108.21:32470'
        setTimeout(() => {
            if(this.props.sourcedata !=null && this.props.sourcedata !=undefined){
            this.props.onFetchSourceAttributes(url, this.props.sourcedata);}
        }, 8000);
        this.props.onGetUseCaseDetailsByName(url, this.props.usecasevalue)
        setTimeout(() => {
            this.setState({ loading: false })
        }, 2000);
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.state.backButton == true) {
            this.props.handleCallBack(true)
        }
        if (this.props.UseCaseData != prevProps.UseCaseData) {
            this.setState({
                UseCaseNameData: this.props.UseCaseData

            })
        }

    }
    componentWillReceiveProps = (nextProps) => {
        if (nextProps.IsProjectChange !== this.props.IsProjectChange) {
            this.stepper.to(1);
            this.setState({
                triageData: false, progress: 0, CallCount: 10, triageMsg: '',
                showData: false, loop: 0
            })
        }
    }

    frame() {
        let DataArr = [];
        const projectName = getProjectDetails();
        const url = this.props.config.INSIGHTS_API_URL + "/product-intelligent-triage/settings/monitoringlogs/project/" + projectName;
        GetCalltoServer(url)
            .then((response) => {
                if (response.data.data) {
                    DataArr = JSON.parse(response.data.data)
                }
            }).catch(() => { });
        let TriageData = TriageDataStatus();
        if (this.state.progress < 100) {
            this.setState((prevState, props) => ({
                progress: prevState.progress + this.state.speed
            }));
            if (this.state.CallCount == this.state.progress) {
                console.log(TriageData[0])
                this.setState({ CallCount: this.state.CallCount + 8, loop: this.state.loop + 1, triageMsg: TriageData[this.state.loop]["message"] });
                console.log(this.state.loop)
            }
        } else if (this.state.progress == 100) {
            this.setState({ triageData: true })
            clearInterval(this.interval);
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);

    }
    handleDataFromChild = (data) => {
        this.setState({ childDataa: data, openDrawer1: false });
        setTimeout(() => {
            this.props.onGetUseCaseDetailsByName('http://10.14.108.21:32470', this.props.usecasevalue)
            this.setState({ loading: true })
        }, 1000);
        setTimeout(() => {
            this.setState({ loading: false })
        }, 2000);
    }
    ViewTrained = () => {

        this.setState({ triageData: false, showData: false });
        this.props.OnSelectTrainedData("viewTrained");
    }
    results = () => {
        this.runModalHandler();

        this.setState({ showData: true })
        this.interval = setInterval(() => this.frame(), 700);
    }

    onSubmit(e) {
        e.preventDefault()
    }
    runModalHandler = () => {
        let selectedSource = this.props.selectedSource.split(" - ");
        let sourceCole = selectedSource[1]
        const projectName = getProjectDetails();
        const url = this.props.config.INSIGHTS_API_URL + `/product-intelligent-triage/settings/reindex`;
        const reIndexObj = {
            sourceIndex: sourceCole, desitnationIndex: "producttriage", project: projectName
        }
        PostCalltoServer(url, reIndexObj)
            .then((response) => {
                if (get(response, "data.status.responseType", "") === "success") {
                    const url =
                        this.props.config.INSIGHTS_API_URL + "/product-intelligent-triage/bulkapi";
                    const runModalBodyData = getRunModalBodyData(this.props.config);
                    setTimeout(() => {
                        PostCalltoServer(url, runModalBodyData)
                            .then(() => {

                            })
                            .catch(() => {

                            });
                    }, 3000);

                }
            })
            .catch(err => {
                console.log(err);
            })
    };
    // onHide = () => {
    //     this.setState({ openDrawer: false })
    // }
    // toggleDrawer = (open) => (event) => {
    //     if (!open) {
    //         this.onHide();
    //     }
    //     if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    //         this.onHide();
    //     }
    // };
    exportData = () => {
        this.stepper.next()
        this.setState({
            saveData: true
        })
    }
    backdata = () => {
        this.stepper.previous()
    }
    nextdata = () => {
        this.stepper.next()
    }
    // showDrawer = () => {
    //     this.setState({ openDrawer: true })
    // }
    handlecallback = (childData) => {
        this.setState({ backButton: childData })
    }
    showDrawerr = () => {
        this.setState({
            openDrawer1: true,
        })
    }
    onHide = () => {
        this.setState({ openDrawer1: false })
    }

    toggleDrawer1 = (open) => (event) => {
        if (!open) {
            this.onHide();
            setTimeout(() => {
                this.props.onGetUseCaseDetailsByName('http://10.14.108.21:32470', this.props.usecasevalue)
                this.setState({ loading: true })
            }, 1000);
            setTimeout(() => {
                this.setState({ loading: false })

            }, 2000);
        }
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            this.onHide();
        }

    };
    handleBack = () => {
        // console.log('s')
        // this.setState({backButton1:true})

        this.props.handleCallBack(true)
    }
    handlecallback2 = () => {
        console.log('s')
    }
    //   handleModalOtput = (childdata) =>{
    //     if(childdata){
    //         this.stepper.next()
    //     }
    //   }
    handleModalOtput = (childData) => {
        // if(childData){
            this.stepper.next()
        // }   
        // this.setState({ modaloutput: childData })


    }

    render() {
        return (
            <div>
                {this.state.loading === true ? <SpinnerPage /> : null}
                <div id="stepper1" className="bs-stepper">
                    <div className="bs-stepper-header col-md-12">
                        <div className="step" data-target="#test-l-1">
                            <button className="step-trigger">
                                <span className="bs-stepper-circle">1</span>
                                <span className="bs-stepper-label">Configure Model</span>
                            </button>
                        </div>
                        <div className="line"></div>
                        <div className="step" data-target="#test-l-2">
                            <button className="step-trigger">
                                <span className="bs-stepper-circle">2</span>
                                <span className="bs-stepper-label">Train Model</span>
                            </button>
                        </div>
                        <div className="line"></div>
                        <div className="step" data-target="#test-l-3">
                            <button className="step-trigger">
                                <span className="bs-stepper-circle">3</span>
                                <span className="bs-stepper-label">Model Output</span>
                            </button>
                        </div>
                        <div className="line"></div>
                        <div className="step" data-target="#test-l-4">
                            <button className="step-trigger">
                                <span className="bs-stepper-circle">4</span>
                                <span className="bs-stepper-label">Data Review</span>
                            </button>
                        </div>
                        <div className="line"></div>
                        <div className="step" data-target="#test-l-5">
                            <button className="step-trigger">
                                <span className="bs-stepper-circle">5</span>
                                <span className="bs-stepper-label">Ingest Prediction Data</span>
                            </button>
                        </div>
                    </div>
                    <div className="bs-stepper-content" >
                        <form >
                            <div id="test-l-1" className="content"
                            // style={{ border: "1px solid grey", backgroundColor: "white" }}
                            >
                                {/* <UnsupervisedLearning config={this.props.config} changeNextEvent={() => this.stepper.next()} sourceVal={this.props.sourceVal} selectedSource={this.props.selectedSource} handlecallback={this.handlecallback} className='mb-5' /> */}
                                <Row>
                                    <Col xs="12">
                                        <Card className="mb-1 card">
                                            <Card.Body onClick={this.showDrawerr}>
                                                <div className="d-flex align-items-center">
                                                    <div className="col-md-3 source-container">
                                                        <div className="source-content">
                                                            <h5>Use Case</h5>
                                                        </div>
                                                        <div className="source-content-details">
                                                            <span>{this.state.UseCaseNameData.useCaseName}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3 source-container">
                                                        <div className="source-content">
                                                            <h5>Source</h5>
                                                        </div>
                                                        <div className="source-content-details">{(this.state.UseCaseNameData.source) != null ? <span>{this.state.UseCaseNameData.source}</span> : <span>--</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3 source-container">
                                                        <div className="source-content">
                                                            <h5>Attributes</h5>
                                                        </div>
                                                        <div className="source-content-details">
                                                            {(this.state.UseCaseNameData.attributes) != null && (this.state.UseCaseNameData.attributes).length > 0 ?
                                                                this.state.UseCaseNameData.attributes && this.state.UseCaseNameData.attributes.map((item, index) => (
                                                                    <span>{item} </span>

                                                                ))
                                                                : <span>--</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2 source-container">
                                                        <div className="source-content">
                                                            <h5>No. of cluster</h5>
                                                        </div>
                                                        <div className="source-content-details">
                                                            {this.state.UseCaseNameData.noOfCluster}
                                                        </div>
                                                    </div>
                                                    <span className="col-md-1 image justify-content-end">
                                                        <img src="./images/Vector.svg" />
                                                    </span>
                                                </div>
                                            </Card.Body>
                                            <DrawerComponent
                                                heading="Cluster Input"
                                                showDrawer={this.toggleDrawer1(false)}
                                                openDrawer={this.state.openDrawer1}
                                            >
                                                {this.state.openDrawer1 === true ?
                                                    <div>
                                                        <ClusterDrawer toggleDrawer={this.toggleDrawer1()} handlecallback2={this.handlecallback2()} useCaseDrawerData={this.state.UseCaseNameData} onDataPassed={this.handleDataFromChild} />
                                                    </div> : null}
                                            </DrawerComponent>
                                        </Card>

                                    </Col>
                                </Row>
                                <div className="d-flex mt-6 justify-content-end button-content mt-5 btnalign">
                                    <div><ButtonComponent
                                        variant="outlined"
                                        label="back"
                                        size="lg"
                                        // onClick={() => { this.previouspg() }}
                                        clicked={this.handleBack}
                                        className="mr-4 back"
                                    />
                                    </div>
                                    <div>
                                        <PrimaryButtonWithLoader
                                            className="mr-3 save-proceed-btn"
                                            size="lg"
                                            text="SAVE & PROCEED"
                                            onClick={() => { this.exportData() }}
                                        /></div>
                                </div>
                            </div>
                            <div id="test-l-2" className="content">
                                <div className='TriageBg'>
                                    <img src="./images/TriageModel.png" />
                                    {!this.state.showData && !this.state.triageData ? <button className="btn btn-primary" onClick={this.results}>START TRAINING THE TRIAGE MODEL</button> : ''}
                                    {this.state.showData && !this.state.triageData ? <div id="myProgress">
                                        <div id="myBar" style={{ width: this.state.progress + "%" }}>
                                        </div>
                                        <div className="barLabel">{this.state.progress < 100 ? 'Please wait' : ''} ({this.state.progress}%) Completed<br />
                                            <span>{this.state.triageMsg}</span>
                                        </div>
                                    </div> : ''
                                    }
                                    {this.state.triageData ? <button className="btn btn-primary view" onClick={this.ViewTrained} >VIEW TRIAGE DATA </button> : ''}
                                </div>
                                <div className='btnnxt pt-3'>
                                    <ButtonComponent
                                        label='CANCEL'
                                        variant='outlined'
                                        // clicked={() => { this.backdata() }}
                                        className='buttonWidth add-lang-button mr-2 mb-4'
                                        disabled={false}
                                    />
                                    <ButtonComponent
                                        label='BACK'
                                        variant='outlined'
                                        clicked={() => { this.backdata() }}
                                        className='buttonWidth add-lang-button mr-2 mb-4'
                                        disabled={false}
                                    />
                                    <ButtonComponent
                                        label='NEXT'
                                        variant='contained'
                                        clicked={() => { this.nextdata() }}
                                        className='buttonWidth add-lang-button mr-2 mb-4'
                                        disabled={false}
                                    />
                                </div>
                            </div>
                            <div id="test-l-3" className="content">
                                <ModelOutput config={this.props.config} handleCallBack={this.handleModalOtput} UseCaseName={this.props.usecasevalue} Sourcsvalue={this.props.sourcedata} />
                                <div className='btnnxt pt-5 mt-5'>
                                <ButtonComponent
                                        label='CANCEL'
                                        variant='outlined'
                                        // clicked={() => { this.backdata() }}
                                        className='buttonWidth add-lang-button mr-2 mb-4 mt-2'
                                        disabled={false}
                                    />
                                    <ButtonComponent
                                        label='BACK'
                                        variant='outlined'
                                        clicked={() => { this.backdata() }}
                                        className='buttonWidth add-lang-button mr-2 mb-4 mt-2'
                                        disabled={false}
                                    />
                                    <ButtonComponent
                                        label='NEXT'
                                        variant='contained'
                                        clicked={() => { this.nextdata() }}
                                        className='buttonWidth add-lang-button mr-2 mb-4 mt-2'
                                        disabled={false}
                                    />
                                </div>
                            </div>
                            <div id="test-l-4" className="content">
                                <DataReview config={this.props.config} UseCaseName={this.props.usecasevalue} Sourcsvalue={this.props.sourcedata} />
                                <div className='btnnxt pt-5 mt-5'>
                                <ButtonComponent
                                        label='CANCEL'
                                        variant='outlined'
                                        // clicked={() => { this.backdata() }}
                                        className='buttonWidth add-lang-button mr-2 mb-4 mt-2'
                                        disabled={false}
                                    />
                                    <ButtonComponent
                                        label='BACK'
                                        variant='outlined'
                                        clicked={() => { this.backdata() }}
                                        className='buttonWidth add-lang-button mr-2 mb-4 mt-2'
                                        disabled={false}
                                    />
                                     <ButtonComponent
                                        label='NEXT'
                                        variant='contained'
                                        clicked={() => { this.nextdata() }}
                                        className='buttonWidth add-lang-button mr-2 mb-4 mt-2'
                                        disabled={false}
                                    />
                                </div>
                            </div>
                            <div id="test-l-5" className="content">
                            <IngestPredictionData config={this.props.config} UseCaseName={this.props.usecasevalue} Sourcsvalue={this.props.sourcedata} />
                                <div className='btnnxt pt-5 mt-5'>
                                <ButtonComponent
                                        label='CANCEL'
                                        variant='outlined'
                                        // clicked={() => { this.backdata() }}
                                        className='buttonWidth add-lang-button mr-2 mb-4 mt-2'
                                        disabled={false}
                                    />
                                    <ButtonComponent
                                        label='BACK'
                                        variant='outlined'
                                        clicked={() => { this.backdata() }}
                                        className='buttonWidth add-lang-button mr-2 mb-4 mt-2'
                                        disabled={false}
                                    />
                                     <ButtonComponent
                                        label='NEXT'
                                        variant='contained'
                                        clicked={() => { this.nextdata() }}
                                        className='buttonWidth add-lang-button mr-2 mb-4 mt-2'
                                        disabled={true}
                                    />
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
                {/* {this.state.saveData != true ?
                    <div className='nextButton pt-3'>
                        <ButtonComponent
                            label='NEXT'
                            variant='contained'
                            clicked={() => { this.exportData() }}
                            className='buttonWidth add-lang-button mr-2'
                            disabled={false}
                        />
                    </div> : null} */}
                {/* <DrawerComponent
                    heading="Create Use Case"
                    showDrawer={this.toggleDrawer(false)}
                    openDrawer={this.state.openDrawer}
                >
                    {this.state.openDrawer === true ?
                        <div>
                            <CreateUseCareDrawer toggleDrawer={this.toggleDrawer()} />

                        </div> : null}

                </DrawerComponent> */}
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        IsProjectChange: state.projectview.IsProjectChange,
        IsTabChanged: state.projectview.IsTabChanged,
        SourceAttributes_Data: state.UnsupervisedML.DataAttributes,
        UseCaseData: state.UnsupervisedML.UsecaseDetails,
        Loading: state.UnsupervisedML.loading,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onFetchSourceAttributes: (COMMON_API_URL, data) => dispatch(actions.FetchSourceAttributes(COMMON_API_URL, data)),
        onGetUseCaseDetailsByName: (COMMON_API_URL, data) => dispatch(actions.GetUseCaseDetailsByName(COMMON_API_URL, data)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateUseCase);
