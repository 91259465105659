import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import withErrorHandler from "../../hoc/withErrorHandler/withErrorHandler";
import axios from "../../instance";
import * as actions from "../../store/actions/index";
import DropdownCustom from "../../components/UIComponents/Dropdowns/DropdownComponent";
import SearchComponent from "../../components/UIComponents/SearchComponent/Search";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import AddProjectDrawer from "../../components/UI/Drawer/AddProjectDrawer";
import ImportProjectDrawer from "../../components/UI/Drawer/ImportProjectDrawer";
import "./Projectpage.css";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InfiniteScroll from "react-infinite-scroll-component";
import SpinnerPage from "../../components/UI/Loader/Loader";
import permissions from "../../permissions_Json";
import { PostCalltoServer,GetCalltoServer } from "../../store/utility";
import {useHistory} from 'react-router-dom'
import SubcriptionCheck from "../../components/validity/SubcriptionCheck";

import OneTimeLogin from './OneTimeLogin'
import { encryptAndStore } from "../../utility/utility";
const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    backgroundColor: "#F2F1F1 !important",
    width: "96.5vw",
    position: "relative",
    left: "20px",
    [theme.breakpoints.up("sm")]: {
      backgroundColor: "#F2F1F1",
    },
    [theme.breakpoints.down("md")]: {
      backgroundColor: "#F2F1F1",
    },
    [theme.breakpoints.up("1700")]: {
      position: "relative",
      left: "18px",
      width: "97.5vw",
    }
  },
  mainProjectContainer: {
    backgroundColor: "#F2F1F1",
  },
  headergrid: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "15px 0px",
  },
  gridLeft: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  paraGraphGrid: {
    display: "flex",
    paddingBottom:"3rem",
    maxHeight: "38px",
    marginLeft: '10px',
  },
  toolBar: {
    display: "flex",
    padding: "15px 0px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  SortByBar: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    position: "relative",
    right: '14px',
    "& button": {
      backgroundColor: "none",
      "&:hover": {
        backgroundColor: "none",
        background: "none",
      },
    },
    "& ul": {
      paddingLeft: 2,
    },
    "& li": {
      fontSize: 12,
      fontWeight: "700",
      fontFamily: "Roboto-Regular",
      color: "black",
      textTransform: "uppercase",
      "&.active, &:hover, &.active:hover": {
        background: "none",
        color: "#2A93DF",
      },
    },
  },
  mainCardContainer: {
    padding: "0px 0px",
    margin: "0px 20px",
  },
  card: {
    maxWidth: "100%",
    margin: "10px 10px",
    cursor: "pointer",
  },
  authorContent: {
    display: "flex",
    justifyContent: "space-between",
    padding: "none !important",
  },
  btnGroup: {
    "&:hover": {
      backgroundColor: "none !important",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "none !important",
    },
  },
  listbox: {
    width: 320,
    margin: "0px 15px",
    padding: 10,
    zIndex: 1,
    position: "absolute",
    listStyle: "none",
    background: "rgba(245, 245, 245, 1)",
    border: "0.25px solid #D0D0D0",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.25)",
    overflow: "auto",
    maxHeight: 200,
    fontFamily: "Roboto-Regular",
    fontWeight: "lighter",
    '& li[data-focus="true"]': {
      color: "#2977f5",
      cursor: "pointer",
      marginBottom: 2,
    },
    "& li:active": {
      color: "#2977f5",
    },
    [theme.breakpoints.up("1612.530", "781.250")]: {
      width: 400,
    },
  },
  allProjectsDropDown: {
    "& button": {
      backgroundColor: "none",
      "&:hover": {
        backgroundColor: "none",
        background: "none",
      },
    },
    "& ul": {
      paddingLeft: 2,
    },
    "& li": {
      fontSize: 14,
      fontWeight: "bold",
      fontFamily: "Roboto-Regular",
      color: "black",
      "&.active, &:hover, &.active:hover": {
        background: "none",
        color: "#2A93DF",
      },
    },
  },
  paperCustom: {
    boxShadow:
      "0px 0px 1px -1px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    borderRadius: '10px !important',
  },
  paraBox: {
    height: "80px",
    width: '19vw',
    overflow: "hidden"
  },
  DocBox: {
    paddingTop: '8px',
    paddingBottom: '4px'
  }
}));


function ProjectsList(props) { 

  let access = false;
  let projectManagementRW = false;

  let obj = props.permissions.find(data => {
    if (data.permission_code.includes(permissions.permissionCode.CONVERSATIONAL_AI_STANDARD_R
      || permissions.permissionCode.CONVERSATIONAL_AI_STANDARD_RW
      || permissions.permissionCode.COGNITIVE_SEARCH_KU_RW))
      return true
  })
  access = obj ? true : false;

  let projectRWStatus = props.permissions.find(data => {
    if (data.permission_code.includes(permissions.permissionCode.PROJECTM_RW) && data.sku_code.includes(permissions.skuCode.STANDARD))
      return true
  })
  projectManagementRW = projectRWStatus ? true : false;

  const classes = useStyles();
  let ProjectNameLoop;

  const [arraySize, setArraySize] = useState([{ startIndex: 0, endindex: 12 }]);
  const [hasMore, setHasMore] = useState(true);
  const [projectList, setprojectlist] = useState([]);
  const [Searchvalue, setSearchValue] = useState("");
  const [addProjectShow, setAddProjectShow] = useState(false);
  const [importProjectShow, setimportProjectShow] = useState(false);
  const [filevalidation, setfilevalidation] = useState(null);
  const [projectFile, setProjectFile] = useState(null);
  const [importFile, setimportFile] = useState(null);
  const [listView, setListView] = useState(false);
  const [GridView, setGridView] = useState(false);

   //****************************  SUBCRIPTION CHECK  *********************************\\

  const [validity ,setValidity]=useState({tenant_admin:false, days_left:'', showmsg:localStorage.getItem('popup')});
  const {tenant_admin, days_left ,  showmsg }=validity;
  useEffect(()=>{
  const url = props.config.COMMON_API_URL + `/organization/subscription`;
  GetCalltoServer(url).then(res=>{
    const {tenant_admin, days_left}=res.data  
    setValidity({...validity,  tenant_admin, days_left})
    // setValidity({...validity,  tenant_admin:true,  days_left:0})    
  })
  },[])

  //****************************  SUBCRIPTION CHECK END *********************************\\

  const [show, setShow] = useState(true)
  let history=useHistory()

  function SortArray(x, y) {
    var nameA = x.projectName.toUpperCase();
    var nameB = y.projectName.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    const botBuilder_URL = props.config.BOTBUILDER_URL;
    props.OnGetAllProjectsData(props.config.BOTBUILDER_PROXY);
    props.onchangeTabAction(true);
    setGridView(true);
    props.onKnowledgeGridExpandCollapse("collapse")
  }, []);

  useEffect(() => {   
    if (props.projectList !== null && props.projectList !== undefined && show == true) {
      let newProjectList = [];
      props.projectList && props.projectList.map((item) => newProjectList.push(item));
      setprojectlist(newProjectList);
    }
    if (Searchvalue.length > 0 && Searchvalue !== "") {
      setShow(false);
      let output = props.projectList && props.projectList.filter((item) => {
        return (
          item.projectName.toLowerCase().includes(Searchvalue.toLowerCase())
        )
      })
      setprojectlist(output);
    } else {
      setShow(true)
    }
  }, [props.projectList, Searchvalue, show]);



  useEffect(() => {
    handleClose("addProject");
  }, [props.createProjectFlag]);



  const ProjectCardClick = (projectname) => {
    if (access || props.cognitiveSearch.WR) {
      const queryParams = [];
      queryParams.push("projname=" + projectname);
      let savedProjectName = localStorage.getItem("projectname");
      if (savedProjectName !== projectname) {
        // localStorage.setItem("projectname", projectname);
        encryptAndStore("projectname", projectname);
      }
      //getting project related user permissions
      const commonapi_URL = props.config.COMMON_API_URL;
      props.OnGetUserPermissions(commonapi_URL, projectname);
      // props.OnGetProjectLang(props.config.BOTBUILDER_PROXY, projectname);
      props.OnGetProjectLang(props.config.BOTBUILDER_URL, projectname);
      const queryString = queryParams.join("&");
      if (
        props.history.location.pathname != "/insights" &&
        props.history.location.pathname != "/settings" &&
        props.history.location.search != "?ticketsInsights" &&
        props.history.location.search != "/Cognitive"
      ) {
        props.history.push({
          pathname: "/projectviewpage",
          search: "?" + queryString,
        });
      }

      let orgname = localStorage.getItem("orgName");
      if (!props.config.BOT_ORGS.includes(orgname)) {
        props.onGetCuxBotList(props.config.COMMON_API_URL, projectname);
      }

      props.onchangeProjectAction(projectname);
      window.updateHCLChatBotURL(projectname);
      let projectname1 = localStorage.getItem("projectname");
      let language = localStorage.getItem("language");
      let docvalue = {
        projectname: projectname1,
        language: language,
      };
      props.OngetProjectDescription(docvalue, props.config.BOTBUILDER_URL);
    }
  };

  const onQuickFilterProjects = (event) => {
    setSearchValue(event.target.value);
  }
  const clearSearchValue = () => {
    setSearchValue('');
    setShow(true);
  }

  const selectedNode = (nodeName) => {
    if (nodeName == "NEW PROJECT") {
      setAddProjectShow(true);
    } else if (nodeName == "IMPORT PROJECT") {
      setimportProjectShow(true);
    }
  };

  const IsprojectExists = (projectName) => {
   // let projects =
    //  props.englishProject && props.englishProject.length != 0
    //    ? props.englishProject
    //    : [];
    var projecttmpList= []
    projectList && projectList.map((data, i) => {        
      projecttmpList.push(data.projectName);      
    })
        
    let projects = projecttmpList; 
    if (projects !== undefined && projects.length > 0) {
      var projExists = projects.findIndex((element) => {
        return element.toLowerCase() === projectName.toLowerCase();
      });
      if (projExists > -1) {
        return true;
      } else return false;
    } else return false;
  };

  const handleClose = (projectType) => {
    if (projectType == "addProject") {
      setAddProjectShow(false);
    }
    if (projectType == "importProject") {
      setimportProjectShow(false);
      setfilevalidation(null);
    }
  };

  const validateFile = (file) => {
    const validTypes = ["application/x-zip-compressed","application/zip"];
    if (validTypes.indexOf(file) === -1) {
      return false;
    }
    return true;
  };

  const handleFile = (e) => {
    if (e.target.files[0] && !/^[A-Z0-9./_]+$/i.test(e.target.files[0].name) && !(validateFile(e.target.files[0].type))) {
      setfilevalidation("File Type is not permitted");
    }
    else if (e.target.files[0] && !/^[A-Z0-9./_]+$/i.test(e.target.files[0].name)) {
      setfilevalidation("Project name should be alphanumeric");
    } else if (e.target.files[0] && validateFile(e.target.files[0].type)) {
      let tempPathFile = null;
      let fileAdded = null;
      let file = e.target.files[0];
      if (file) {
        fileAdded = file.name.split(".");
        tempPathFile = fileAdded[0];
      }
      if (fileAdded[0].includes("(")) {
        tempPathFile = fileAdded[0].split("(");
        tempPathFile = tempPathFile[0].trim();
      }
      if (!IsprojectExists(tempPathFile)) {
        let DeleteProjName = localStorage.getItem("DeleteProjName");
        if (tempPathFile == DeleteProjName)
          // localStorage.setItem("DeleteProjName", null);
          encryptAndStore("DeleteProjName", null);
        setfilevalidation(null);
        setProjectFile(e.target.value);
        setimportFile(e.target.files[0]);
      } else {
        setfilevalidation("Project already exists.");
      }
    } else {
      setProjectFile(null);
      setimportFile(null);
      if (e.target.files[0]) setfilevalidation("File Type is not permitted");
    }
  };

  const clearUploadProjectStateValue = () => {
    setProjectFile(null);
    setimportFile(null);
  };

  const submitImport = (e) => {
    e.preventDefault();
    if (importFile) {
      setfilevalidation(null);
      let formData = new FormData();
      formData.append("file", importFile);
      formData.append("language",localStorage.getItem("language"));
      var filename = projectFile.replace(/^.*[\\\/]/, "");
      let file = filename.split(".");
      props.OnProjectfileupload(
        props.config.BOTBUILDER_PROXY,
        formData,
        props.config.BOTBUILDER_PROXY,
        file[0],
        filename
      );
      setTimeout(() => {
          props.OnGetAllProjectsData(props.config.BOTBUILDER_PROXY);
        }, 2000);
      clearUploadProjectStateValue();
      setimportProjectShow(false);
    } else {
      if (!filevalidation) setfilevalidation("Project name is required");
    }
  };

  const fetchMoreData = () => {
    if (arraySize[0].endindex >= projectList.length) {
      setHasMore(false);
      return;
    }

    setTimeout(() => {
      let newArray = [...arraySize];
      newArray[0]["endindex"] += 8;
      setArraySize(newArray);
    }, 200);
  };

  let loadingImg = null;
  if (props.projectsloading) {
    loadingImg = <SpinnerPage />;
  } else {
    loadingImg = null;
  }

  let alphabeticalOrder = projectList && projectList.sort(SortArray)
  if (projectList !== null && projectList !== undefined && show == true) {
    ProjectNameLoop =
      alphabeticalOrder && alphabeticalOrder.slice(arraySize[0].startIndex, arraySize[0].endindex).map((data, i) => {
        return (
          <>       
            {tenant_admin && showmsg &&  (days_left >= 1 && days_left <= 60 )   && (
              <SubcriptionCheck
                validity={validity}
                setValidity={setValidity}
                text={`Subcription is ending in ${validity.days_left} days ,  Renew Now! `}
              />
            )}
          
            <Grid item sm={6} md={3} key={i}>
              <Card
                p={1}
                className="customCard"
                key={i}
                onClick={() => ProjectCardClick(data.projectName)}
              >
                <CardContent>
                  <span className="ProjectTitle">{data.projectName}</span>
                  <Box pb={1} className={classes.paraBox}>
                    <span className="cardParagraph">{data.description}</span>
                  </Box>
                  <Box className={classes.DocBox}>
                    <span className="CardFooter">
                      {data.projectName == "common"
                        ? data.flowCount - 1
                        : data.flowCount - 2}{" "}
                      Intents &nbsp;|&nbsp;{data.faqCount}{" "}
                      {data.faqCount === 1 ? "FAQ" : "FAQs"} &nbsp;|&nbsp;{" "}
                      {data.documentCount}{" "}
                      {data.documentCount === 1 ? "Document" : "Documents"}
                    </span>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </>
        );
      });
  } else {
    ProjectNameLoop = alphabeticalOrder && alphabeticalOrder.map((data, i) => {
      return (
        <>
          <Grid item sm={6} md={3} key={i}>
            <Card
              p={1}
              className="customCard"
              key={i}
              onClick={() => ProjectCardClick(data.projectName)}
            >
              <CardContent>
                <span className="ProjectTitle">{data.projectName}</span>
                <Box pb={1} className={classes.paraBox}>
                  <span className="cardParagraph">
                    {data.description}
                  </span>
                </Box>
                <Box className={classes.DocBox}>
                  <span className="CardFooter">
                    {data.projectName == 'common' ? data.flowCount - 1 : data.flowCount - 2} Intents
                     &nbsp;|&nbsp;{data.faqCount}{" "}{data.faqCount === 1 ? "FAQ" : "FAQs"} &nbsp;|&nbsp; {data.documentCount}{" "}
                    {data.documentCount === 1 ? "Document" : "Documents"}
                  </span>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </>
      );
    });

  }
  return (
    <>
      <Container maxWidth="xl" className={classes.root}>
        {loadingImg}
        <InfiniteScroll
          dataLength={arraySize[0].endindex}
          next={fetchMoreData}
          style={{ overflow: "hidden" }}
          hasMore={hasMore}
          loader={
            <div class="text-center">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading Projects...</span>
              </div>
            </div>
          }
        >
          <Grid container md={12} className={classes.headergrid  }  style={{marginTop:` ${validity.showmsg ? "50px" :""}   `    }} >
            <Grid item md={6} margin={3} className={classes.gridLeft}>
              <span className="ProjectsDropDown ml-10">Projects</span>
              {projectManagementRW && <DropdownCustom
                label="Create"
                customClass="dropdownClass"
                variant="primary"
                dropdownItem={[
                  { name: "NEW PROJECT", "disabled":  false },
                  { name: "IMPORT PROJECT", "disabled": false },
                ]}
                selectedItem={selectedNode}
              />}
            </Grid>
            <Grid item md={6}>
              <SearchComponent
                class={"input-group homeSearch listview"}
                id="example-search-input"
                value={Searchvalue}
                show={show}
                onSearch={(event) => onQuickFilterProjects(event)}
                Clear={clearSearchValue}
              />
            </Grid>
          </Grid>
          <Grid item md={12} className={classes.paraGraphGrid}>
            <p className="mainParagraph">
              Project consists of knowledge documents and workflows for
              consumption by the different modules of IntelliService and for
              ensuring controlled data access for users.
            </p>
            
          </Grid>

          <Container maxWidth="xl" className={classes.mainCardContainer}>
            <Grid container md={12} className={classes.toolBar}>
              <Grid item className={classes.allProjectsDropDown}>
                <h3 style={{ position: "relative", top: "6px", left: '-9px', fontSize: '16px' }}>
                  <span style={{ color: "#016BB5" }}>
                    {projectList !== null && projectList !== undefined
                      ? projectList.length
                      : ""}{" "}
                    {projectList.length == 1 ? 'Project is' : 'Projects are'}
                  </span>{" "}
                  available!
                </h3>
              </Grid>
            </Grid>

            <Grid container lg={12}>
              {/*  */}
              {listView === true && (
                <Grid item lg={12}>
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                      <TableHead className="tableContentRows">
                        <TableRow>
                          <TableCell>
                            <span className="tableHeaderTitles">
                              NAME <ExpandMoreIcon className="downicon" />
                            </span>
                          </TableCell>
                          <TableCell>
                            <span className="tableHeaderTitles">
                              DESCRIPTION <ExpandMoreIcon />
                            </span>
                          </TableCell>
                          <TableCell>
                            <span className="tableHeaderTitles">
                              CREATED BY <ExpandMoreIcon />
                            </span>
                          </TableCell>
                          <TableCell>
                            <span className="tableHeaderTitles">
                              FLOWS <ExpandMoreIcon />
                            </span>
                          </TableCell>
                          <TableCell>
                            <span className="tableHeaderTitles">
                              DOCUMENTS <ExpandMoreIcon />
                            </span>
                          </TableCell>
                          <TableCell>
                            <span className="tableHeaderTitles">
                              LAST MODIFIED <ExpandMoreIcon />
                            </span>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {projectList &&
                          projectList
                            .slice(
                              arraySize[0].startIndex,
                              arraySize[0].endindex
                            )
                            .map((row, id) => (
                              <TableRow key={id} className="tableContentRows">
                                <TableCell>{row}</TableCell>
                                <TableCell>{row}</TableCell>
                                <TableCell>{row}</TableCell>
                                <TableCell>{row}</TableCell>
                                <TableCell>{row}</TableCell>
                                <TableCell>{row}</TableCell>
                              </TableRow>
                            ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              )}
              {GridView === true && ProjectNameLoop.length > 0
                ? ProjectNameLoop
                : ""}
            </Grid>
          </Container>
        </InfiniteScroll>
      </Container>
      <AddProjectDrawer
        show={addProjectShow}
        onHide={handleClose}
        config={props.config}
        isThisExists={IsprojectExists}
      />
      <ImportProjectDrawer
        show={importProjectShow}
        error={filevalidation}
        onHide={() => handleClose("importProject")}
        value={projectFile}
        onChange={handleFile}
        submit={(e) => submitImport(e)}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    cognitiveSearch: state.user.moduleAccess.cognitiveOtherDocsUpload,
    permissions: state.user.permissionList,
    projectList: state.projectview.AllProjectsList,
    projectsloading: state.projectview.projectsloading,
    createProjectFlag: state.user.createProjectFlag,
    uploadProjectFlag: state.user.uploadProjectFlag,
    englishProject: state.user.englishProject,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logOut: () => dispatch(actions.logout()),
    onGetUserById: (apiUrl, botBuilder_URL) =>
      dispatch(actions.GetUserbyID(apiUrl, botBuilder_URL)),
    OnGetAllProjectsData: (APIURL) =>
      dispatch(actions.GetAllProjectsData(APIURL)),
    OnGetBuildProps: (apiUrl) => dispatch(actions.GetBuildProps(apiUrl)),
    OnGetUserPermissions: (apiUrl, projectname) =>
      dispatch(actions.GetUserPermissions(apiUrl, projectname)),
    onchangeTabAction: (tab) => dispatch(actions.changeTabAction(tab)),
    OnGetLanguage: (apiUrl) => dispatch(actions.GetLanguage(apiUrl)),
    OngetProjectDescription: (apiUrl, data) =>
      dispatch(actions.getProjectDescription(apiUrl, data)),
    onchangeProjectAction: (projectname) =>
      dispatch(actions.changeProjectAction(projectname)),
    OnProjectfileupload: (apiUrl, formdata, dataUrl, projectName,filename) =>
      dispatch(
        actions.Projectfileupload(apiUrl, formdata, dataUrl, projectName,filename)
      ),
    onGetCuxBotList: (apiUrl, projectName) =>
      dispatch(actions.GetCuxBotList(apiUrl, projectName)),
    OnGetProjectLang: (apiUrl, projectName) =>
      dispatch(actions.GetProjectLang(apiUrl, projectName)),
      onKnowledgeGridExpandCollapse: (expand)  => dispatch(actions.KnowledgeGridExpandCollapse(expand)) ,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorHandler(ProjectsList, axios));
