import React, { useState, useEffect } from "react";
import Drawer from "@material-ui/core/Drawer";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import "./Users.css";
import { Row, Col,Form } from "react-bootstrap";
import DrawerHeaderContent from "../../components/DrawerHeaderContent/DrawerHeaderContent";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import PrimaryButtonWithLoader from "../../components/PrimaryButtonWithLoader/PrimaryButtonWithLoader";
import ReactSelectComponent from "../../components/ReactSelectComponent/ReactSelectComponent";
import {
  GetCalltoOUServer,
  UpdateCalltoOUServer,
  DeleteCalltoOUServer,
} from "../helpers/utility";
import {
  SetSuccessMessage,
  SetErrorMessage,
} from "../../../../../store/actions";
import { isEqual } from "lodash";


const useStyles = makeStyles({
  list: {
    width: 600,
  },
  formData: {
    overflow: "hidden",
  },
});

const UserDetailsForm = ({
  config,
  showDrawer,
  toggleDrawer,
  updateUserCallBack,
  usergroup,
  
 
  
  data,
  
  closeDrawer,
  updateFilterData,
  
}) => {
  const userPermissions = useSelector((state) => {
    return state.user.permissionList;
  });

  const dispatch = useDispatch();

  const orgname = localStorage.getItem("orgName");
  const username = localStorage.getItem("userName");
  const [userGroups, setUserGroups] = useState([]);
  const [mandatory, setMandatory] = useState(false);
  const [selectedUserGroups, setSelectedUserGroups] = useState(data.usergroup);
  const [errorMsg, setErrorMsg] = useState();
  const [disabled, setDisabled] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [formData, setFormData] = useState({
    username: data.username,
    first_name: data.first_name,
    last_name: data.last_name,
    orgname: orgname,
    usergroup: selectedUserGroups,
  });

  const classes = useStyles();
 

  useEffect(() => {
    if (usergroup !== null && usergroup !== undefined) {
     
      setSelectedUserGroups([usergroup]);
      setDisabled(true);
    } else getUserGroups();
  }, []);

  const parseData = (data) => {
    let usergroup = [];
    usergroup = data.map((group) => group.usergroup_name);
    return usergroup;
  };

  const getUserGroups = () => {
    
    const url =
      config.COMMON_API_URL + `/organization/usergroup?orgname=${orgname}`;
    
    GetCalltoOUServer(url)
      .then((response) => {
        if (response.data != null) {
         

          setUserGroups(parseData(response.data));
        } 
      })
      .catch((err) => {
        console.log(err)
      });
  };

  const getOptions = (data) => {
    if (isEmpty(data)) {
      return [];
    }
    
    const attrData = data;
    return attrData.map((option) => ({
      label: option,
      value: option,
    }));
  };

  const updateUserGroup = (e) => {
    if (!selectedUserGroups.includes(e.value)){
      setSelectedUserGroups((prevData) => [...prevData, e.value]);
      setIsDisabled(false);
      setMandatory(false)
    }
  };

  const removeUserGroup = (e, item) => {
    if (usergroup === undefined)
      setSelectedUserGroups(selectedUserGroups.filter((list) => list !== item));
      setIsDisabled(false);
      setMandatory(false);
  };

  useEffect(() => {
    setFormData((formData) => ({
      ...formData,
      usergroup: selectedUserGroups,
    }));
    
    // if((selectedUserGroups && selectedUserGroups.length != 0))
    // setIsDisabled(false);
  }, [selectedUserGroups]);

  const displayToastMsg = (msg, method) => {
    setTimeout(() => {
      dispatch(method(msg))
    },450)
  }
  const RoleValidation = (formData) => {
    if(selectedUserGroups.length === 0){
    if (isEqual( formData.usergroup, [])) return true;
    }
    else return
  }
    

  const updateUser = () => {
    if (!RoleValidation(formData)) {
    let username = formData.username.replaceAll(".", "~~");
    const url =
      config.COMMON_API_URL + `/organization/${orgname}/user/${username}`;
    UpdateCalltoOUServer(url, formData).then((response) => {
      if (response.status == 204) {
        closeDrawer();        
        toggleDrawer(true);
        setIsDisabled(true);
        updateFilterData(formData);
        displayToastMsg('User updated successfully', SetSuccessMessage);        
      } else {
        displayToastMsg('Something went wrong!', SetErrorMessage);
      }
    });
    updateUserCallBack();
  }
  else setMandatory(RoleValidation(formData));
  };

  const removeUser = () => {
    let username = formData.username.replaceAll(".", "~~");
    const url =
      config.COMMON_API_URL + `/organization/${orgname}/user/${username}`;
  
    DeleteCalltoOUServer(url).then((response) => {
      if (response.status == 204) {
        toggleDrawer(true);
        updateUserCallBack();
        closeDrawer();
        displayToastMsg('User deleted successfully', SetSuccessMessage); 
      } else {
        displayToastMsg('Something went wrong!', SetErrorMessage);
      }
    });
  };

  async function getUpdatedUsergroups(username) {
    const url =
      config.COMMON_API_URL +
      `/organization/user?username=${username}&orgname=${orgname}`;

    try {
      const response = await GetCalltoOUServer(url);

      if (response.data != null) {
        let usergroupList = response.data[0]?.usergroup;
        let updatedList = usergroupList.filter((list) => list !== usergroup);
        return updatedList;
      }
    } catch (err) {}
  }

  const removeUserFromUsergroup = async () => {
    let username = formData.username.replaceAll(".", "~~");
    const url =
      config.COMMON_API_URL + `/organization/${orgname}/user/${username}`;
    const list = await getUpdatedUsergroups(formData.username);

    const requestBody = { usergroup: list };
    if (list.length > 0) {
      UpdateCalltoOUServer(url, requestBody).then((response) => {
        if (response.status == 204) {
          closeDrawer();
          updateUserCallBack();
          toggleDrawer(true);
        } else {
          displayToastMsg('Something went wrong!', SetErrorMessage);
          
        }
      });
    } else {
      setErrorMsg("User needs to be part of atleast one group.");
    }
  };

  const removeAction = () => {
    if (usergroup === undefined) {
      removeUser();
    } else {
      removeUserFromUsergroup();
    }
  };



  const hasPermission = (code) => {
 
    if (userPermissions.find((data) => data.permission_code.includes(code)))
      return true;
    else return false;
  };

  const isCurrentUser = () => {
    if (username === formData.username) {
      return true;
    }
    return false;
  };

  return (
    <React.Fragment>
      {showDrawer ? (
        <Drawer
          anchor={"right"}
          open={showDrawer}
          onClose={toggleDrawer(false)}
        >
          <div
            className={clsx(classes.list, classes.fullList, classes.formData)}
            role="presentation"
            style={{height:"100%"}}
          >
            <DrawerHeaderContent
              heading="User Details" 
              toggleDrawer={toggleDrawer}
            />
            <div className="d-flex pl-4">
              <div className="mt-1">
                <div className="d-flex">
                  <Col md={4}>
                    <div>
                      <label className="label-title">Name</label>
                    </div>
                    <div>
                      <label className="label-text">
                        {formData.first_name + " " + formData.last_name}
                      </label>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div>
                      <label className="label-title">Email Id</label>
                    </div>
                    <div>
                      <label className="label-email">{formData.username}</label>
                    </div>
                  </Col>
                </div>
                <Col md={10}>
                  <div>
                    <label className="label-title">Status</label>
                  </div>
                  <div>
                    <label className="label-text">
                      {data.last_active < 0 ? "Invite Sent" : "Active"}
                    </label>
                  </div>
                </Col>
              </div>
            </div>

            <div className="d-flex flex-column pl-4">
              {/* <Col md={12}>
                
                <Heading5 className="text-capitalize" text="User Groups" />
                </Col> */}
              <Col md={10}>
                <ReactSelectComponent
                  options={getOptions(userGroups)}
                  name="userGroups"
                  label="User Groups"
                  placeholder={"Select"}
                  value=""
                  onChange={updateUserGroup}
                  disabled={disabled}
                />{" "}
              </Col>
              <div className="mt-2 pl-2">
                <Row>
                  <Col md={10}>
                    {/* {data.usergroup ? data.usergroup.map((item) => { */}
                    {selectedUserGroups !== null &&
                    selectedUserGroups.length > 0 ? (
                      selectedUserGroups.map((item) => {
                        return (
                          // <li>
                          // <a className="dyna-list"  >{item}</a>
                          <a
                            className="dyna-list"
                            key={item}
                            onClick={(e) => removeUserGroup(e, item)}
                          >
                            <span className="li-close">X</span>
                            {item}
                          </a>

                          // </li>
                        );
                      })
                    ) : (
                      <div className="error-msg"></div>
                    )}
                  </Col>
                </Row>
              </div>
            </div>
            <div className="ml-4">
                {mandatory ? (
                  <Form.Label className="text-box-error">
                    {"All fields are mandatory"}
                  </Form.Label>
                ) : null}
              </div>
              <div className="mt-4 ml-4 d-flex justify-content-between">
                {hasPermission("usermanagement_RW") && (
                  <div className="d-flex flex-row">
                    {!isCurrentUser() && (
                      <PrimaryButtonWithLoader
                        // className="ml-5 mb-2"
                        size="lg"
                        onClick={removeAction}
                        text="Remove"
                        loading={false}
                      />
                    )}
                    {usergroup === undefined && (
                      <PrimaryButtonWithLoader
                        className="save-btn"
                        size="lg"
                        onClick={updateUser}
                        text="Save"
                        loading={false}
                        disabled={isDisabled}
                      />
                    )}
                  </div>
                )}
                <div className="mt-1 error-msg">{errorMsg}</div>
              </div>
            </div>
        </Drawer>
      ) : null}
    </React.Fragment>
  );
};

export default UserDetailsForm;
