import { useState } from "react";
import { Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import DrawerTemplate from "./DrawerTemplate";
import ButtonCustom from "../../UIComponents/Button/ButtonComponent";
import ReactSelectComponent from "../../../containers/SettingPage/SettingInnerPages/components/ReactSelectComponent/ReactSelectComponent";
import { copyChannelFlow } from "../../../store/actions";

const CopyChannelDrawer = ({config, show, showDrawer, selectedChannel, channelList, flowid}) => {

    const dispatch = useDispatch();
    const [copySource, setCopySource] = useState();

    const handleSubmit = () => {
        let data = {
            lang:localStorage.getItem('language'),
            source_channel: copySource.toLowerCase(),
            target_channel: selectedChannel.toLowerCase()
        };
        
        dispatch(copyChannelFlow(config.COMMON_API_URL, data, flowid));
        showDrawer();
        setCopySource();
    }

    return (
        <DrawerTemplate title='Copy Channel Details' show={show} 
            closeDrawer={showDrawer} >
            <div className="mt-1">
            <Col md={12}>
                <ReactSelectComponent
                    customClassName="SelectView"
                    value={channelList.filter((item)=>item.value === copySource)}
                    options={channelList.filter((item)=>item.value !== selectedChannel)}
                    name="source_channel"
                    label="Source Channel"
                    placeholder={'Select'}
                    onChange={(event) => setCopySource(event.value)}
                />
            </Col>
            </div>
            <br/><br/>
            <div className="mt-1">
               <Col md={12}>
                   <ButtonCustom variant='contained' label='Copy'className='buttonWidth' 
                        disabled={!copySource} clicked={handleSubmit}/>
               </Col>
            </div>
      </DrawerTemplate>
    )
}

export default CopyChannelDrawer;