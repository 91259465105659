import React from "react";

import * as PropTypes from "prop-types";
import "./Search-component.css";

const SearchComponent = ({
  name,
  placeHolder,
  className,
  value,
  onChange,
  defaultClass,
  
}) => {
  const inputRef=React.useRef()

  React.useEffect(()=>{
     inputRef.current.onblur=function(){
      inputRef.current.value=""
     }

  },[])

  return (
    <div className="search-component">
      <form className="search">
        <div className="search__wrapper">
          <input
            type="text"
            name={name}
            placeholder={placeHolder}
            className={defaultClass + " " + className}
            value={value}
            onChange={onChange}
            ref={inputRef}

          />
          <button type="submit" className="search__icon">
            <img src="./images/Search.svg" />
          </button>
        </div>
      </form>
    </div>
  );
};
SearchComponent.propTypes = {
  placeHolder: PropTypes.string,
  name: PropTypes.string,
  defaultClass: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
};

SearchComponent.defaultProps = {
  placeHolder: "Search",
  name: "",
  defaultClass: "search__field1",
  value: "",
  className: "",
};
export default SearchComponent;
