const changeFilterDataReducer = (state, payload) => {
    return {
        ...state,
            selectedFilterData: {
                ...state.selectedFilterData,
                ...payload
            }
    };
};

export default changeFilterDataReducer;