export const LOGIN = 'LOGIN';

export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

export const GET_USER_BY_ID = 'GET_USER_BY_ID';
export const GET_USER_BY_ID_FAILURE='GET_USER_BY_ID_FAILURE';
export const GET_USER_BY_ID_SUCCESS='GET_USER_BY_ID_SUCCESS';
export const GET_USER_PROJECTS_SUCCESS='GET_USER_PROJECTS_SUCCESS';

export const PROJECT_REDIRECT_TO="PROJECT_REDIRECT_TO";

export const GET_USER_SKILL_SUCCESS='GET_USER_SKILL_SUCCESS';
export const GET_USER_SKILL_FAILURE='GET_USER_SKILL_FAILURE';

export const CREATE_USER_SKILL_START='CREATE_USER_SKILL_START'
export const CREATE_USER_SKILL_SUCCESS='CREATE_USER_SKILL_SUCCESS';
export const CREATE_USER_SKILL_FAILURE='CREATE_USER_SKILL_FAILURE';

export const DELETE_USER_SKILL_START = 'DELETE_USER_SKILL_START';
export const DELETE_USER_SKILL_SUCCESS='DELETE_USER_SKILL_SUCCESS';
export const DELETE_USER_SKILL_FAILURE='DELETE_USER_SKILL_FAILURE';

export const GET_USERS_SUCCESS='GET_USERS_SUCCESS';
export const GET_USERS_FAILURE='GET_USERS_FAILURE';

export const DELETE_USER_SUCCESS='DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE='DELETE_USER_FAILURE';

export const GET_USER_ROLE_SUCCESS='GET_USER_ROLE_SUCCESS';
export const GET_USER_ROLE_FAILURE='GET_USER_ROLE_FAILURE';

export const GET_SETTINGS_SUCCESS='GET_SETTINGS_SUCCESS';
export const GET_SETTINGS_FAILURE='GET_SETTINGS_FAILURE';

export const UPDATE_USER_SUCCESS='UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE='UPDATE_USER_FAILURE';
export const UPDATE_USER_START='UPDATE_USER_START';


export const CREATE_USER_SUCCESS='CREATE_USER_SUCCESS';
export const CREATE_USER_FAIL='CREATE_USER_FAIL';

export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
export const SET_WARNING_MESSAGE = 'SET_WARNING_MESSAGE';
export const SET_SUCCESS_MESSAGE = 'SET_SUCCESS_MESSAGE';

export const USER_START = 'USER_START';

export const  CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS'; 
export const  CREATE_PROJECT_FAILURE= 'CREATE_PROJECT_FAILURE';
export const  CREATE_PROJECT_START= 'CREATE_PROJECT_START';

export const  CREATE_ASSISTANT_SUCCESS = 'CREATE_ASSISTANT_SUCCESS'; 
export const  CREATE_ASSISTANT_FAILURE= 'CREATE_ASSISTANT_FAILURE';
export const  CREATE_ASSISTANT_START= 'CREATE_ASSISTANT_START';

export const  UPLOAD_PROJECT_SUCCESS = 'UPLOAD_PROJECT_SUCCESS'; 
export const  UPLOAD_PROJECT_FAILURE= 'UPLOAD_PROJECT_FAILURE';
export const  UPLOAD_PROJECT_START= 'UPLOAD_PROJECT_START';

export const CHECK_ITS_EX_ENGN_SUCCESS='CHECK_ITS_EX_ENGN_SUCCESS';
export const CHECK_ITS_EX_ENGN_FAILURE='CHECK_ITS_EX_ENGN_FAILURE';

export const FLOWLIST_START = 'FLOWLIST_START';
export const FLOWLIST_SUCCESS = 'FLOWLIST_SUCCESS';
export const FLOWLIST_FAIL = 'FLOWLIST_FAIL';

export const CHECK_UPLOAD_ENGN_SUCCESS='CHECK_UPLOAD_ENGN_SUCCESS';
export const CHECK_UPLOAD_ENGN_FAILURE='CHECK_UPLOAD_ENGN_FAILURE';

export const GET_STATUS_SUCCESS='GET_STATUS_SUCCESS';
export const GET_STATUS_FAILURE='GET_STATUS_FAILURE';

export const GET_DASHBOARD_STATUS_SUCCESS='GET_DASHBOARD_STATUS_SUCCESS';
export const GET_DASHBOARD_STATUS_FAILURE='GET_DASHBOARD_STATUS_FAILURE';

export const CREATE_ROLE_START = 'CREATE_ROLE_START';
export const CREATE_ROLE_SUCCESS='CREATE_ROLE_SUCCESS';
export const CREATE_ROLE_FAILURE='CREATE_ROLE_FAILURE';

export const CATEGORYLIST_START = 'CATEGORYLIST_START';
export const CATEGORYLIST_SUCCESS = 'CATEGORYLIST_SUCCESS';
export const CATEGORYLIST_FAIL = 'CATEGORYLIST_FAIL';

export const CAT_FLOWLIST_START = 'CAT_FLOWLIST_START';
export const CAT_FLOWLIST_SUCCESS = 'CAT_FLOWLIST_SUCCESS';
export const CAT_FLOWLIST_FAIL = 'CAT_FLOWLIST_FAIL';

export const CREATE_CATEGORY_START = 'CREATE_CATEGORY_START';
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_FAIL = 'CREATE_CATEGORY_FAIL';

export const CREATE_CATEGORY_BOT_START = 'CREATE_CATEGORY_BOT_START';
export const CREATE_CATEGORY_BOT_SUCCESS = 'CREATE_CATEGORY_BOT_SUCCESS';
export const CREATE_CATEGORY_BOT_FAIL = 'CREATE_CATEGORY_BOT_FAIL';

export const DELETE_CATEGORY_BOT_START = 'DELETE_CATEGORY_BOT_START';
export const DELETE_CATEGORY_BOT_SUCCESS = 'DELETE_CATEGORY_BOT_SUCCESS';
export const DELETE_CATEGORY_BOT_FAIL = 'DELETE_CATEGORY_BOT_FAIL';

export const TRIGGER_START='TRIGGER_START';
export const TRIGGER_SUCCESS='TRIGGER_SUCCESS';
export const TRIGGER_FAILURE='TRIGGER_FAILURE';

export const TRIGGER_START_DOC='TRIGGER_START_DOC';
export const TRIGGER_SUCCESS_DOC='TRIGGER_SUCCESS_DOC';
export const TRIGGER_FAILURE_DOC='TRIGGER_FAILURE_DOC';

export const INTENT_START='INTENT_START';
export const INTENT_SUCCESS='INTENT_SUCCESS';
export const INTENT_FAILURE='INTENT_FAILURE';

export const KNOWLEDGE_INTENT_START='KNOWLEDGE_INTENT_START';
export const KNOWLEDGE_INTENT_SUCCESS='KNOWLEDGE_INTENT_SUCCESS';
export const KNOWLEDGE_INTENT_FAILURE='KNOWLEDGE_INTENT_FAILURE';

export const KNOWLEDGE_WITH_FILE_INTENT_START='KNOWLEDGE_WITH_FILE_INTENT_START';
export const KNOWLEDGE_WITH_FILE_INTENT_SUCCESS='KNOWLEDGE_WITH_FILE_INTENT_SUCCESS';
export const KNOWLEDGE_WITH_FILE_INTENT_CANCEL='KNOWLEDGE_WITH_FILE_INTENT_CANCEL';
export const KNOWLEDGE_WITH_FILE_INTENT_FAILURE='KNOWLEDGE_WITH_FILE_INTENT_FAILURE';

export const GET_KNOWLEDGE_INTENT_START='GET_KNOWLEDGE_INTENT_START';
export const GET_KNOWLEDGE_INTENT_SUCCESS='GET_KNOWLEDGE_INTENT_SUCCESS';
export const GET_KNOWLEDGE_INTENT_FAILURE='GET_KNOWLEDGE_INTENT_FAILURE';

export const GET_SYNONYMS_START='GET_SYNONYMS_START';
export const GET_SYNONYMS_SUCCESS='GET_SYNONYMS_SUCCESS';
export const GET_SYNONYMS_FAILURE='GET_SYNONYMS_FAILURE';

export const KNOWLEDGE_INTENT_IMPORT_START='KNOWLEDGE_INTENT_IMPORT_START';
export const KNOWLEDGE_INTENT_IMPORT_SUCCESS='KNOWLEDGE_INTENT_IMPORT_SUCCESS';
export const KNOWLEDGE_INTENT_IMPORT_FAILURE='KNOWLEDGE_INTENT_IMPORT_FAILURE';

export const SAVE_PROFILE_START='SAVE_PROFILE_START';
export const SAVE_PROFILE_SUCCESS='SAVE_PROFILE_SUCCESS';
export const SAVE_PROFILE_FAILURE='SAVE_PROFILE_FAILURE';

export const GET_STRUCTURE_SUCCESS='GET_STRUCTURE_SUCCESS';
export const GET_STRUCTURE_FAILURE='GET_STRUCTURE_FAILURE';

export const GET_BOTFILE_SUCCESS='GET_BOTFILE_SUCCESS';
export const GET_BOTFILE_FAILURE='GET_BOTFILE_FAILURE';

export const GET_ENTITY_START='GET_ENTITY_START';
export const GET_ENTITY_SUCCESS='GET_ENTITY_SUCCESS';
export const GET_ENTITY_FAILURE='GET_ENTITY_FAILURE';

export const GET_COGNITIVE_START='GET_COGNITIVE_START';
export const GET_COGNITIVE_SUCCESS='GET_COGNITIVE_SUCCESS';
export const GET_COGNITIVE_FAILURE='GET_COGNITIVE_FAILURE';

export const READ_FILE_SUCCESS='READ_FILE_SUCCESS';
export const READ_FILE_FAILURE='READ_FILE_FAILURE';

export const  CREATE_BOT_SUCCESS= 'CREATE_BOT_SUCCESS';
export const  CREATE_BOT_FAILURE= 'CREATE_BOT_FAILURE';
export const  CREATE_BOT_START= 'CREATE_BOT_START';

export const  GET_BOT_LIST_SUCCESS= 'GET_BOT_LIST_SUCCESS';
export const GET_PROJECT_LIST_FAILURE= 'GET_PROJECT_LIST_FAILURE';

export const  GET_PROJECT_LIST_SUCCESS= 'GET_PROJECT_LIST_SUCCESS';
export const  GET_BOT_LIST_FAILURE= 'GET_BOT_LIST_FAILURE';
export const  GET_PROJECTLIST_START = 'GET_PROJECTLIST_START';

export const  GET_ASSISTANTS_LIST_SUCCESS= 'GET_ASSISTANTS_LIST_SUCCESS';
export const  GET_ASSISTANTS_FAILURE= 'GET_ASSISTANTS_FAILURE';
export const  GET_ASSISTANTS_START = 'GET_ASSISTANTS_START';

export const CREATE_ENTITY_START='CREATE_ENTITY_START';
export const CREATE_ENTITY_SUCCESS='CREATE_ENTITY_SUCCESS';
export const CREATE_ENTITY_FAILURE='CREATE_ENTITY_FAILURE';

export const  GET_CHATBOT_SUCCESS= 'GET_CHATBOT_SUCCESS';
export const  GET_CHATBOT_FAILURE= 'GET_CHATBOT_FAILURE';

export const  GET_DOC_SUCCESS= 'GET_DOC_SUCCESS';
export const  GET_DOC_FAILURE= 'GET_DOC_FAILURE';

export const  GET_PROJECT_DESCRIPTION_SUCCESS= 'GET_PROJECT_DESCRIPTION_SUCCESS';
export const  GET_PROJECT_DESCRIPTION_FAILURE= 'GET_PROJECT_DESCRIPTION_FAILURE';

export const AGENTLIST_START = 'AGENTLIST_START';
export const AGENTLIST_SUCCESS = 'AGENTLIST_SUCCESS';
export const AGENTLIST_FAIL = 'AGENTLIST_FAIL';
export const ACTIVEAGENT_SUCCESS = 'ACTIVEAGENT_SUCCESS';
export const ACTIVEAGENT_FAIL = 'ACTIVEAGENT_FAIL';
export const SAVE_AGENTLIST_START = 'SAVE_AGENTLIST_START';
export const SAVE_AGENTLIST_SUCCESS = 'SAVE_AGENTLIST_SUCCESS';
export const SAVE_AGENTLIST_FAIL = 'SAVE_AGENTLIST_FAIL';

export const  SCT_SAVE_ES_SCHEDULE_DATA_SUCCESS = 'SCT_SAVE_ES_SCHEDULE_DATA_SUCCESS';
export const  SCT_SAVE_ES_SCHEDULE_DATA_FAILURE = 'SCT_SAVE_ES_SCHEDULE_DATA_FAILURE';

export const REFRESH_LAYOUT ='REFRESH_LAYOUT';
export const DELETE_FLOW_BOT_START='DELETE_FLOW_BOT_START';
export const DELETE_FLOW_BOT_SUCCESS='DELETE_FLOW_BOT_SUCCESS';
export const DELETE_FLOW_BOT_FAIL='DELETE_FLOW_BOT_FAIL';

export const CREATE_ORG_START = 'CREATE_ORG_START';
export const CREATE_ORG_SUCCESS='CREATE_ORG_SUCCESS';
export const CREATE_ORG_FAILURE='CREATE_ORG_FAILURE';

export const GET_ORG_SUCCESS='GET_ORG_SUCCESS';
export const GET_ORG_FAILURE='GET_ORG_FAILURE';

export const DELETE_KNOWLEDGE_INTENT_START='DELETE_KNOWLEDGE_INTENT_START';
export const DELETE_KNOWLEDGE_INTENT_END='DELETE_KNOWLEDGE_INTENT_END';

export const EXPORT_FLOW_BOT_START='EXPORT_FLOW_BOT_START';
export const EXPORT_FLOW_BOT_SUCCESS='EXPORT_FLOW_BOT_SUCCESS';
export const EXPORT_FLOW_BOT_FAIL='EXPORT_FLOW_BOT_FAIL';

export const IMPORT_FLOW_START='IMPORT_FLOW_START';
export const IMPORT_FLOW_SUCCESS='IMPORT_FLOW_SUCCESS';
export const IMPORT_FLOW_FAIL='IMPORT_FLOW_FAIL';

export const EXPORT_PROJECT_START='IMPORT_PROJECT_START';
export const EXPORT_PROJECT_SUCCESS='IMPORT_PROJECT_SUCCESS';
export const EXPORT_PROJECT_FAIL='IMPORT_PROJECT_FAIL';

export const CREATE_GROUP_START = 'CREATE_GROUP_START';
export const CREATE_GROUP_SUCCESS='CREATE_GROUP_SUCCESS';

export const CREATE_GROUP_FAILURE ='CREATE_GROUP_FAILURE'



export const CONNECT_DATA_IMPORT_SUCCESS='CONNECT_DATA_IMPORT_SUCCESS';
export const CONNECT_DATA_IMPORT_FAILURE='CONNECT_DATA_IMPORT_FAILURE';
export const CONNECT_DATA_IMPORT_START='CONNECT_DATA_IMPORT_START';

export const GET_GROUP_SUCCESS='GET_GROUP_SUCCESS';
export const GET_GROUP_FAILURE='GET_GROUP_FAILURE';

export const GET_MESSAGE_SETTING_START='GET_MESSAGE_SETTING_START';


export const GET_CLUSTER_DATA_SUCCESS='GET_CLUSTER_DATA_SUCCESS';
export const GET_CLUSTER_DATA_FAILURE='GET_CLUSTER_DATA_FAILURE';
export const GET_CLUSTER_DATA_START='GET_CLUSTER_DATA_START';
export const EDIT_GROUP_START = 'EDIT_GROUP_START';
export const GET_MESSAGE_SETTING_SUCCESS='GET_MESSAGE_SETTING_SUCCESS';
export const EDIT_GROUP_SUCCESS='EDIT_GROUP_SUCCESS';
export const EDIT_GROUP_FAILURE='EDIT_GROUP_FAILURE';

export const GET_SYNONMYS_START='GET_SYNONMYS_START';
export const GET_SYNONMYS_SUCCESS='GET_SYNONMYS_SUCCESS';

export const GET_PROJECT_BY_LANG_START='GET_PROJECT_BY_LANG_START';
export const GET_PROJECT_BY_LANG_SUCCESS='GET_PROJECT_BY_LANG_SUCCESS';

export const SET_DEFAULT_LANGUAGE='SET_DEFAULT_LANGUAGE';

export const LANG_DELETE_START='LANG_DELETE_START';
export const LANG_DELETE_SUCCESS='LANG_DELETE_SUCCESS';
export const LANG_DELETE_FAILURE='LANG_DELETE_FAILURE';

export const IMPORT_LANG_START='IMPORT_LANG_START';
export const IMPORT_LANG_SUCCESS='IMPORT_LANG_SUCCESS';
export const IMPORT_LANG_FAILURE='IMPORT_LANG_FAILURE';


export const  INIT_CHAT_BOT_SUCCESS= 'INIT_CHAT_BOT_SUCCESS';
export const  INIT_CHAT_BOT_FAILURE= 'INIT_CHAT_BOT_FAILURE';

export const SAVE_CATEGORY_PROFILE_START='SAVE_CATEGORY_PROFILE_START';
export const SAVE_CATEGORY_PROFILE_SUCCESS='SAVE_CATEGORY_PROFILE_SUCCESS';
export const SAVE_CATEGORY_PROFILE_FAILURE='SAVE_CATEGORY_PROFILE_FAILURE';

export const GET_CATEGORYSTRUCTURE_FAILURE='GET_CATEGORYSTRUCTURE_FAILURE';
export const GET_CATEGORYSTRUCTURE_SUCCESS='GET_CATEGORYSTRUCTURE_SUCCESS';

export const GET_CATEGORYFILE_FAILURE='GET_CATEGORYFILE_FAILURE';
export const GET_CATEGORYFILE_SUCCESS='GET_CATEGORYFILE_SUCCESS';

export const  INTENT_LIST_DATA_SCORE_SUCCESS= 'INTENT_LIST_DATA_SCORE_SUCCESS';
export const  INTENT_LIST_DATA_SCORE_FAILURE= 'INTENT_LIST_DATA_SCORE_FAILURE';

export const  CREATE_PROJECT_ACCURACY_SUCCESS= 'CREATE_PROJECT_ACCURACY_SUCCESS';
export const  CREATE_PROJECT_ACCURACY_FAILURE= 'CREATE_PROJECT_ACCURACY_FAILURE';


export const TRANSLATION_PROJECT_START='TRANSLATION_PROJECT_START';
export const TRANSLATION_PROJECT_END='TRANSLATION_PROJECT_END';
export const GET_PROJECT_DATA='GET_PROJECT_DATA';
export const HANDLE_TRANSLATION_BTN='HANDLE_TRANSLATION_BTN';

export const GET_MACRO_DATA='GET_MACRO_DATA';
export const GET_MACRO_LIST='GET_MACRO_LIST';

export const KNOWLEDGEGRIDEXPANDCOLLAPSE='KNOWLEDGEGRIDEXPANDCOLLAPSE';


export const UPDATE_KNOWLEDGE_INTENT_START='UPDATE_KNOWLEDGE_INTENT_START';
export const UPDATE_KNOWLEDGE_INTENT_SUCCESS='UPDATE_KNOWLEDGE_INTENT_SUCCESS';
export const UPDATE_KNOWLEDGE_INTENT_FAILURE='UPDATE_KNOWLEDGE_INTENT_FAILURE';


export const SAVE_MACRO_FILE_START='SAVE_MACRO_FILE_START';
export const SAVE_MACRO_FILE_SUCCESS='SAVE_MACRO_FILE_SUCCESS';
export const SAVE_MACRO_FILE_FAILURE='SAVE_MACRO_FILE_FAILURE';


export const CREATE_FLOWF_START='CREATE_FLOWF_START';
export const CREATE_FLOWF_SUCCESS='CREATE_FLOWF_SUCCESS';
export const CREATE_FLOWF_FAILURE='CREATE_FLOWF_FAILURE';

export const TRIGGER_TYPE_START='TRIGGER_TYPE_START';
export const TRIGGER_TYPE_SUCCESS='TRIGGER_TYPE_SUCCESS';
export const TRIGGER_TYPE_FAILURE='TRIGGER_TYPE_FAILURE';

export const  GET_FLOW_LIST_SUCCESS= 'GET_FLOW_LIST_SUCCESS';
export const  GET_FLOW_LIST_FAILURE= 'GET_FLOW_LIST_FAILURE';


export const CREATE_UPDATE_ENTITY_START='CREATE_UPDATE_ENTITY_START';
export const CREATE_UPDATE_ENTITY_SUCCESS='CREATE_UPDATE_ENTITY_SUCCESS';
export const CREATE_UPDATE_ENTITY_FAILURE='CREATE_UPDATE_ENTITY_FAILURE';


export const GET_LIST_CATEGORY_START='GET_LIST_CATEGORY_START';
export const GET_LIST_CATEGORY_SUCCESS='GET_LIST_CATEGORY_SUCCESS';


export const GET_ENTITY_CAT_START='GET_ENTITY_CAT_START';
export const GET_ENTITY_CAT_SUCCESS='GET_ENTITY_CAT_SUCCESS';
export const GET_ENTITY_CAT_FAILURE='GET_ENTITY_CAT_FAILURE';

export const SAVE_BOT_PROFILE_START='SAVE_BOT_PROFILE_START';
export const SAVE_BOT_PROFILE_FAILURE='SAVE_BOT_PROFILE_FAILURE';
export const SAVE_BOT_PROFILE_SUCCESS='SAVE_BOT_PROFILE_SUCCESS';

export const SCHEDULER_SUCCESS='SCHEDULER_SUCCESS';
export const CHAT_SUCCESS='CHAT_SUCCESS';
export const FLOWREPLY_SUCCESS='FLOWREPLY_SUCCESS';
export const BUTTON_SUCCESS='BUTTON_SUCCESS';
export const BUTTON_LABEL_SUCCESS='BUTTON_LABEL_SUCCESS';

export const USER_SCHEDULER_SUCCESS='USER_SCHEDULER_SUCCESS';
export const INPUT_SCHEDULER_SUCCESS='INPUT_SCHEDULER_SUCCESS';
export const TIME_SCHEDULER_SUCCESS='TIME_SCHEDULER_SUCCESS';

export const GET_SITE_LIST_START='GET_SITE_LIST_START';
export const GET_SITE_LIST_SUCCESS='GET_SITE_LIST_SUCCESS';
export const GET_SITE_LIST_FAILURE='GET_SITE_LIST_FAILURE';

export const GET_CONNECTIONS_DATA_START='GET_CONNECTIONS_DATA_START';
export const GET_CONNECTIONS_DATA_SUCCESS='GET_CONNECTIONS_DATA_SUCCESS';
export const GET_CONNECTIONS_DATA_FAILURE='GET_CONNECTIONS_DATA_FAILURE';

export const UPDATE_TICKET_SYSTEMS_DATA = 'UPDATE_TICKET_SYSTEMS_DATA';
export const UPDATE_KNOWLEDGE_BASE_DATA = 'UPDATE_KNOWLEDGE_BASE_DATA';

export const FETCH_DATA_SOURCE_INDEX_NAMES_START='FETCH_DATA_SOURCE_INDEX_NAMES_START';
export const FETCH_DATA_SOURCE_INDEX_NAMES_SUCCESS='FETCH_DATA_SOURCE_INDEX_NAMES_SUCCESS';
export const FETCH_DATA_SOURCE_INDEX_NAMES_FAILURE='FETCH_DATA_SOURCE_INDEX_NAMES_FAILURE';
export const ADD_DATA_SOURCE_INDEX_NAMES='ADD_DATA_SOURCE_INDEX_NAMES';

export const INDEX_SUCCESS = 'INDEX_SUCCESS';
export const SUBTITLE_SUCCESS = 'SUBTITLE_SUCCESS';
export const IMAGE_SUCCESS = 'IMAGE_SUCCESS';

export const IMG_TITLE_SUCCESS = 'IMG_TITLE_SUCCESS';
export const IMG_LINK_SUCCESS = 'IMG_LINK_SUCCESS';
export const IMG_URL_SUCCESS = 'IMG_URL_SUCCESS';

export const UPDATE_FILE_SUCCESS='UPDATE_FILE_SUCCESS';
export const UPDATE_FILE_FAILURE='UPDATE_FILE_FAILURE';

export const SAVE_CATEGORY = 'SAVE_CATEGORY';
export const SAVE_CATEGORY_START = 'SAVE_CATEGORY_START';
export const SAVE_CATEGORY_SUCCESS = 'SAVE_CATEGORY_SUCCESS';
export const SAVE_CATEGORY_FAIL = 'SAVE_CATEGORY_FAIL';

export const GET_ALL_CATEGORY = 'GET_ALL_CATEGORY';
export const GET_ALL_CATEGORY_START = 'GET_ALL_CATEGORY_START';
export const GET_ALL_CATEGORY_SUCCESS = 'GET_ALL_CATEGORY_SUCCESS';
export const GET_ALL_CATEGORY_FAIL = 'GET_ALL_CATEGORY_FAIL';

export const EMAIL_SUCCESS = 'EMAIL_SUCCESS';
export const LIST_SUCCESS = 'LIST_SUCCESS';
export const NODE_SUCCESS = 'NODE_SUCCESS';

export const GET_SHARE_POINT_DATA_START = 'GET_SHARE_POINT_DATA_START';
export const GET_SHARE_POINT_DATA_SUCCESS = 'GET_SHARE_POINT_DATA_SUCCESS';
export const GET_SHARE_POINT_DATA_FAILURE = 'GET_SHARE_POINT_DATA_FAILURE';
export const UPDATE_SHARE_POINT_BASE_URL = 'UPDATE_SHARE_POINT_BASE_URL';
export const SET_REERESH_OTHERDOC = 'SET_REERESH_OTHERDOC';
export const SELECT_SHARE_POINT_FILE = 'SELECT_SHARE_POINT_FILE';
export const RESET_SHARE_POINT_DATA = 'RESET_SHARE_POINT_DATA';

export const FLOWS_SUCCESS = 'FLOWS_SUCCESS';

export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const UPDATE_CATEGORY_START = 'UPDATE_CATEGORY_START';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_FAIL = 'UPDATE_CATEGORY_FAIL';
// BACKUP&RESTORE ACTION TYPES
 
export const SET_ES_BACKUP_DETAILS = 'SET_ES_BACKUP_DETAILS';
export const GET_ES_BACKUP_DETAILS = 'GET_ES_BACKUP_DETAILS';
 
export const SET_ES_RESTORE_LIST_DETAILS = 'SET_ES_RESTORE_LIST_DETAILS';
export const GET_ES_RESTORE_LIST_DETAILS = 'GET_ES_RESTORE_LIST_DETAILS';

export const GET_CATEGORY_TREEDATA = 'GET_CATEGORY_TREEDATA';
export const GET_CATEGORY_TREEDATA_START = 'GET_CATEGORY_TREEDATA_START';
export const GET_CATEGORY_TREEDATA_SUCCESS = 'GET_CATEGORY_TREEDATA_SUCCESS';
export const GET_CATEGORY_TREEDATA_FAIL = 'GET_CATEGORY_TREEDATA_FAIL';

export const DELETE_CATEGORY_START = 'DELETE_CATEGORY_START';
export const DELETE_CATEGORY_FAIL = 'DELETE_CATEGORY_FAIL';

export const CONDITION_SUCCESS = 'CONDITION_SUCCESS';
export const RULE_SUCCESS='RULE_SUCCESS'

export const CREATE_NEWUSER_START = 'CREATE_NEWUSER_START';
export const CREATE_NEWUSER_SUCCESS = 'CREATE_NEWUSER_SUCCESS';
export const CREATE_NEWUSER_FAILURE = 'CREATE_NEWUSER_FAILURE';

export const GET_PROPERTY_SUCCESS = 'GET_PROPERTY_SUCCESS';
export const GET_PROPERTY_FAILURE = 'GET_PROPERTY_FAILURE';

export const UPDATE_PROPERTY_START = 'UPDATE_PROPERTY_START';
export const UPDATE_PROPERTY_SUCCESS = 'UPDATE_PROPERTY_SUCCESS';
export const UPDATE_PROPERTY_FAILURE = 'UPDATE_PROPERTY_FAILURE';

export const MACROS_READ_FILE_SUCCESS='MACROS_READ_FILE_SUCCESS';
export const MACROS_READ_FILE_FAILURE='MACROS_READ_FILE_FAILURE';


export const SAVE_MACROS_DATA_SUCCESS='SAVE_MACROS_DATA_SUCCESS';
export const SAVE_MACROS_DATA_FAILURE='SAVE_MACROS_DATA_FAILURE';

export const DELETE_ALT_QUE_FOR_FAQ='DELETE_ALT_QUE_FOR_FAQ';

export const CONFIGURATION_INPUT_SUCCESS='CONFIGURATION_INPUT_SUCCESS';
export const CONFIGURATION_INPUT_FAILURE='CONFIGURATION_INPUT_FAILURE';

export const DELETE_PROFILE_START = 'DELETE_PROFILE_START';
export const DELETE_PROFILE_SUCCESS = 'DELETE_PROFILE_SUCCESS';
export const DELETE_PROFILE_FAIL = 'DELETE_PROFILE_FAIL';

export const SAVECHAT_SUCCESS='SAVECHAT_SUCCESS';

export const GET_BUILD_SUCCESS='GET_BUILD_SUCCESS';
export const GET_BUILD_FAILURE='GET_BUILD_FAILURE';

export const B_CONFIGURATION_SUCCESS='B_CONFIGURATION_SUCCESS';
export const B_CONFIGURATION_FAILURE='B_CONFIGURATION_FAILURE';

export const REMOVE_NODE_SUCCESS='REMOVE_NODE_SUCCESS';
export const VIDEO_SUCCESS='VIDEO_SUCCESS';
export const changeProjectActionType='changeProjectActionType';

export const GET_USER_PERMISSION_SUCCESS='GET_USER_PERMISSION_SUCCESS';
export const GET_USER_PERMISSION_FAILURE='GET_USER_PERMISSION_FAILURE';

export const INEDITFLOWTAB='INEDITFLOWTAB';
export const CAROUSEL_SUCCESS='CAROUSEL_SUCCESS'

export const UPDATE_USER_LOGIN_SUCCESS='UPDATE_USER_LOGIN_SUCCESS';
export const UPDATE_USER_LOGIN_FAILURE='UPDATE_USER_LOGIN_FAILURE';

export const GET_LANGUAGE_SUCCESS='GET_LANGUAGE_SUCCESS';
export const GET_LANGUAGE_FAILURE='GET_LANGUAGE_FAILURE';

export const CUXBOT_START='CUXBOT_START';
export const CUX_LOADER='CUX_LOADER';

export const GET_CUXBOT_DATA_SUCCESS = 'GET_CUXBOT_DATA_SUCCESS';
export const GET_CUXBOT_DATA_FAILURE = 'GET_CUXBOT_DATA_FAILURE';

export const UPDATE_CUXBOT_DATA_SUCCESS = 'UPDATE_CUXBOT_DATA_SUCCESS';
export const UPDATE_CUXBOT_DATA_FAILURE = 'UPDATE_CUXBOT_DATA_FAILURE';

export const GET_CUXBOT_LIST_SUCCESS = 'GET_CUXBOT_LIST_SUCCESS';
export const GET_CUXBOT_LIST_FAILURE = 'GET_CUXBOT_LIST_FAILURE';

export const DELETE_CUXBOT_SUCCESS = 'DELETE_CUXBOT_SUCCESS';
export const DELETE_CUXBOT_FAILURE = 'DELETE_CUXBOT_FAILURE';

export const CREATE_CUXBOT_SUCCESS = 'CREATE_CUXBOT_SUCCESS';
export const CREATE_CUXBOT_FAILURE = 'CREATE_CUXBOT_FAILURE';

export const GET_CUXTRIGGER_LIST_SUCCESS = 'GET_CUXTRIGGER_LIST_SUCCESS';
export const GET_CUXTRIGGER_LIST_FAILURE = 'GET_CUXTRIGGER_LIST_FAILURE'; 

export const CREATE_CUXTRIGGER_FAILURE = 'CREATE_CUXTRIGGER_FAILURE';
export const UPDATE_CUXTRIGGER_FAILURE = 'UPDATE_CUXTRIGGER_FAILURE';

export const GET_CUXBOT_TEMPLATES_SUCCESS = 'GET_CUXBOT_TEMPLATES_SUCCESS';
export const DELETE_CUXBOT_TEMPLATES_SUCCESS = 'DELETE_CUXBOT_TEMPLATES_SUCCESS';

export const CREATE_CUXFLOW_TEMPLATE_SUCCESS = 'CREATE_CUXFLOW_TEMPLATE_SUCCESS';
export const CREATE_CUXTEMPLATE_SUCCESS = 'CREATE_CUXTEMPLATE_SUCCESS';
export const CUXTEMPLATE_FAILURE = 'CUXTEMPLATE_FAILURE';
export const CLEAR_CUXTEMPLATES_SUCCESS = 'CLEAR_CUXTEMPLATES_SUCCESS';

export const GET_ENGLISH_PROJECTS_SUCCESS = 'GET_ENGLISH_PROJECTS_SUCCESS';
export const GET_ENGLISH_PROJECTS_FAILURE = 'GET_ENGLISH_PROJECTS_FAILURE';


export const GET_URLMINING_START = 'GET_URLMINING_START';
export const GET_URLMINING_SUCCESS = 'GET_URLMINING_SUCCESS';
export const GET_URLMINING_FAILURE = 'GET_URLMINING_FAILURE';

export const GET_DOWNLOAD_CRAWL_DATA_START = 'GET_DOWNLOAD_CRAWL_DATA_START';
export const GET_DOWNLOAD_CRAWL_DATA_SUCCESS = 'GET_DOWNLOAD_CRAWL_DATA_SUCCESS';
export const GET_DOWNLOAD_CRAWL_DATA_FAILURE = 'GET_DOWNLOAD_CRAWL_DATA_FAILURE';

export const GET_PROJECT_LANGS_SUCCESS = 'GET_PROJECT_LANGS_SUCCESS';
export const GET_PROJECT_LANGS_FAILURE = 'GET_PROJECT_LANGS_FAILURE';
 
export const ADD_LANGUAGE_START = 'ADD_LANGUAGE_START';
export const ADD_LANGUAGE_SUCCESS = 'ADD_LANGUAGE_SUCCESS';
export const ADD_LANGUAGE_FAILURE = 'ADD_LANGUAGE_FAILURE';

export const TOTALUSER_START = 'TOTALUSER_START';
export const TOTALUSER_SUCCESS = 'TOTALUSER_SUCCESS';
export const TOTALUSER_FAIL = 'TOTALUSER_FAIL';

export const TOTAL_COGNITIVE_USER_START = 'TOTAL_COGNITVE_USER_START';
export const TOTAL_COGNITIVE_USER_SUCCESS = 'TOTAL_COGNITVE_USER_SUCCESS';
export const TOTAL_COGNITIVE_USER_FAIL = 'TOTAL_COGNITVE_USER_FAIL';

export const NEWUSER_START = 'NEWUSER_START';
export const NEWUSER_SUCCESS = 'NEWUSER_SUCCESS';
export const NEWUSER_FAIL= 'NEWUSER_FAIL';

export const REPEATUSER_START = 'REPEATUSER_START';
export const REPEATUSER_SUCCESS = 'REPEATUSER_SUCCESS';
export const REPEATUSER_FAIL = 'REPEATUSER_FAIL';

export const GET_TIME_ZONE_START = 'GET_TIME_ZONE_START';
export const GET_TIME_ZONE_SUCCESS = 'GET_TIME_ZONE_SUCCESS';
export const GET_TIME_ZONE_FAIL = 'GET_TIME_ZONE_FAIL';

export const GET_CONTRY_START = 'GET_CONTRY_START';
export const GET_CONTRY_SUCCESS = 'GET_CONTRY_SUCCESS';
export const GET_CONTRY_FAIL = 'GET_CONTRY_FAIL';

export const TOTALUSERREPORT_START = 'TOTALUSERREPORT_START';
export const TOTALUSERREPORT_SUCCESS = 'TOTALUSERREPORT_SUCCESS';
export const TOTALUSERREPORT_FAIL = 'TOTALUSERREPORT_FAIL';

export const TOTALINITIATFLOW_START = 'TOTALINITIATFLOW_START';
export const TOTALINITIATFLOW_SUCCESS = 'TOTALINITIATFLOW_SUCCESS';
export const TOTALINITIATFLOW_FAIL = 'TOTALINITIATFLOW_FAIL';

export const TOTALUNANSWERS_START = 'TOTALUNANSWERS_START';
export const TOTALUNANSWERS_SUCCESS = 'TOTALUNANSWERS_SUCCESS';
export const TOTALUNANSWERS_FAIL = 'TOTALUNANSWERS_FAIL';

export const TOTAL_COGNITIVE_SEARCHQUERY_START = 'TOTAL_COGNITIVE_SEARCHQUERY_START';
export const TOTAL_COGNITIVE_SEARCHQUERY_SUCCESS = 'TOTAL_COGNITIVE_SEARCHQUERY_SUCCESS';
export const TOTAL_COGNITIVE_SEARCHQUERY_FAIL = 'TOTAL_COGNITIVE_SEARCHQUERY_FAIL';

export const TOTAL_COGNITIVE_UNANSWERS_START = 'TOTAL_COGNITIVE_UNANSWERS_START';
export const TOTAL_COGNITIVE_UNANSWERS_SUCCESS = 'TOTAL_COGNITIVE_UNANSWERS_SUCCESS';
export const TOTAL_COGNITIVE_UNANSWERS_FAIL = 'TOTAL_COGNITIVE_UNANSWERS_FAIL';

export const TOTALFEEDBACK_START = 'TOTALFEEDBACK_START';
export const TOTALFEEDBACK_SUCCESS = 'TOTALFEEDBACK_SUCCESS';
export const TOTALFEEDBACK_FAIL = 'TOTALFEEDBACK_FAIL';

export const TOTAL_COGNITIVE_FEEDBACK_START = 'TOTAL_COGNITIVE_FEEDBACK_START';
export const TOTAL_COGNITIVE_FEEDBACK_SUCCESS = 'TOTAL_COGNITIVE_FEEDBACK_SUCCESS';
export const TOTAL_COGNITIVE_FEEDBACK_FAIL = 'TOTAL_COGNITIVE_FEEDBACK_FAIL';

export const TOTALFEEDBACK_REPORT_START = 'TOTALFEEDBACK_REPORT_START';
export const TOTALFEEDBACK_REPORT_SUCCESS = 'TOTALFEEDBACK_REPORT_SUCCESS';
export const TOTALFEEDBACK_REPORT_FAIL = 'TOTALFEEDBACK_REPORT_FAIL';

export const TOTALFEEDBACK_UTTARANCES_START = 'TOTALFEEDBACK_UTTARANCES_START';
export const TOTALFEEDBACK_UTTARANCES_SUCCESS = 'TOTALFEEDBACK_UTTARANCES_SUCCESS';
export const TOTALFEEDBACK_UTTARANCES_FAIL = 'TOTALFEEDBACK_UTTARANCES_FAIL';

export const TOTALFEEDBACK_CATEGORIES_START = 'TOTALFEEDBACK_CATEGORIES_START';
export const TOTALFEEDBACK_CATEGORIES_SUCCESS = 'TOTALFEEDBACK_CATEGORIES_SUCCESS';
export const TOTALFEEDBACK_CATEGORIES_FAIL = 'TOTALFEEDBACK_CATEGORIES_FAIL';

export const SELECTED_CATEGORY = 'SELECTED_CATEGORY';

export const TOTALCHATSESSION_START = 'TOTALCHATSESSION_START';
export const TOTALCHATSESSION_SUCCESS = 'TOTALCHATSESSION_SUCCESS';
export const TOTALCHATSESSION_FAIL = 'TOTALCHATSESSION_FAIL';

export const TOTALCHATSESSIONREPORT_START = 'TOTALCHATSESSIONREPORT_START';
export const TOTALCHATSESSIONREPORT_SUCCESS = 'TOTALCHATSESSIONREPORT_SUCCESS';
export const TOTALCHATSESSIONREPORT_FAIL = 'TOTALCHATSESSIONREPORT_FAIL';

export const TOTAL_AGENT_TRANSFORMS_START = 'TOTAL_AGENT_TRANSFORMS_START';
export const TOTAL_AGENT_TRANSFORMS_SUCCESS = 'TOTAL_AGENT_TRANSFORMS_SUCCESS';
export const TOTAL_AGENT_TRANSFORMS_FAIL = 'TOTAL_AGENT_TRANSFORMS_FAIL';

export const TOTAL_AGENT_TRANSFER_REPORT_START = 'TOTAL_AGENT_TRANSFER_REPORT_START';
export const TOTAL_AGENT_TRANSFER_REPORT_SUCCESS = 'TOTAL_AGENT_TRANSFER_REPORT_SUCCESS';
export const TOTAL_AGENT_TRANSFER_REPORT_FAIL = 'TOTAL_AGENT_TRANSFER_REPORT_FAIL';

export const TOTAL_FLOW_REPORT_START = 'TOTAL_FLOW_REPORT_START';
export const TOTAL_FLOW_REPORT_SUCCESS = 'TOTAL_FLOW_REPORT_SUCCESS';
export const TOTAL_FLOW_REPORT_FAIL = 'TOTAL_FLOW_REPORT_FAIL';

export const TOTALNEWUSERREPORT_START = 'TOTALNEWUSERREPORT_START';
export const TOTALNEWUSERREPORT_SUCCESS = 'TOTALNEWUSERREPORT_SUCCESS';
export const TOTALNEWUSERREPORT_FAIL = 'TOTALNEWUSERREPORT_FAIL';

export const TOTALREPEATUSERREPORT_START = 'TOTALREPEATUSERREPORT_START';
export const TOTALREPEATUSERREPORT_SUCCESS = 'TOTALREPEATUSERREPORT_SUCCESS';
export const TOTALREPEATUSERREPORT_FAIL = 'TOTALREPEATUSERREPORT_FAIL';

export const GET_ALL_SCHEDULE_MEETING_DETAIL_START = 'GET_ALL_SCHEDULE_MEETING_DETAIL_START';
export const GET_ALL_SCHEDULE_MEETING_DETAIL_SUCCESS = 'GET_ALL_SCHEDULE_MEETING_DETAIL_SUCCESS';
export const GET_ALL_SCHEDULE_MEETING_DETAIL_FAIL = 'GET_ALL_SCHEDULE_MEETING_DETAIL_FAIL';

export const GET_AGENT_TRANSFER_DETAIL_START = 'GET_AGENT_TRANSFER_DETAIL_START';
export const GET_AGENT_TRANSFER_DETAIL_SUCCESS = 'GET_AGENT_TRANSFER_DETAIL_SUCCESS';
export const GET_AGENT_TRANSFER_DETAIL_FAIL = 'GET_AGENT_TRANSFER_DETAIL_FAIL';

export const GET_AGENT_DETAIL_START = 'GET_AGENT_DETAIL_START';
export const GET_AGENT_DETAIL_SUCCESS = 'GET_AGENT_DETAIL_SUCCESS';
export const GET_AGENT_DETAIL_FAIL = 'GET_AGENT_DETAIL_FAIL';

export const TOTALUNANSWEREDREPORT_START = 'TOTALUNANSWEREDREPORT_START';
export const TOTALUNANSWEREDREPORT_SUCCESS = 'TOTALUNANSWEREDREPORT_SUCCESS';
export const TOTALUNANSWEREDREPORT_FAIL = 'TOTALUNANSWEREDREPORT_FAIL';

export const REPORT_PAGE_ACTION = 'REPORT_PAGE_ACTION';

export const GET_COUNTRY_DETAILS_SUCCESS = 'GET_COUNTRY_DETAILS_SUCCESS';
export const GET_COUNTRY_DETAILS_FAILURE = 'GET_COUNTRY_DETAILS_FAILURE';

export const UPDATE_CUX_FORM_ACTIVENODE = 'UPDATE_CUX_FORM_ACTIVENODE';

export const AVGUSERSREPORT_START = 'AVGUSERSREPORT_START';
export const AVGUSERSREPORT_SUCCESS = 'AVGUSERSREPORT_SUCCESS';
export const AVGUSERSREPORT_FAIL = 'AVGUSERSREPORT_FAIL';

export const PROJECTDESCRIPTION_SUCCESS='PROJECTDESCRIPTION_SUCCESS'
export const PROJECTDESCRIPTION_FAILURE='PROJECTDESCRIPTION_FAILURE'

export const TOTALMEETINGSCOUNT_START = 'TOTALMEETINGSCOUNT_START';
export const TOTALMEETINGSCOUNT_SUCCESS = 'TOTALMEETINGSCOUNT_SUCCESS';
export const TOTALMEETINGSCOUNT_FAIL = 'TOTALMEETINGSCOUNT_FAIL';

export const TOTALMEETINGSREPORT_START = 'TOTALMEETINGSREPORT_START';
export const TOTALMEETINGSREPORT_SUCCESS = 'TOTALMEETINGSREPORT_SUCCESS';
export const TOTALMEETINGSREPORT_FAIL = 'TOTALMEETINGSREPORT_FAIL'; 

export const TOTALINITIATEDBOTREPORT_START = 'TOTALINITIATEDBOTREPORT_START';
export const TOTALINITIATEDBOTREPORT_SUCCESS = 'TOTALINITIATEDBOTREPORT_SUCCESS';
export const TOTALINITIATEDBOTREPORT_FAIL = 'TOTALINITIATEDBOTREPORT_FAIL'
export const DOWNLOAD_DATA_START = 'DOWNLOAD_DATA_START';

export const GET_CMM_LOGIN_SUCCESS = 'GET_CMM_LOGIN_SUCCESS';
export const GET_CMM_LOGIN_FAILURE = 'GET_CMM_LOGIN_FAILURE';

export const DELETE_MULTIMEDIA_ROW_START = 'DELETE_MULTIMEDIA_ROW_START';
export const DELETE_MULTIMEDIA_ROW_SUCCESS = 'DELETE_MULTIMEDIA_ROW_SUCCESS';
export const DELETE_MULTIMEDIA_ROW_FAIL = 'DELETE_MULTIMEDIA_ROW_FAIL'; 

export const EDIT_MULTIMEDIA_ROW_START = 'EDIT_MULTIMEDIA_ROW_START';
export const EDIT_MULTIMEDIA_ROW_SUCCESS = 'EDIT_MULTIMEDIA_ROW_SUCCESS';
export const EDIT_MULTIMEDIA_ROW_FAILURE = 'EDIT_MULTIMEDIA_ROW_FAILURE'; 

export const GET_COGNITIVE_FLOW_START = 'GET_COGNITIVE_FLOW_START';
export const GET_COGNITIVE_FLOW_FAILURE = 'GET_COGNITIVE_FLOW_FAILURE';
export const GET_COGNITIVE_FLOW_SUCCESS = 'GET_COGNITIVE_FLOW_SUCCESS'; 

export const SAVEFIELDS_START = 'SAVEFIELDS_START';
export const SAVEFIELDS_SUCCESS = 'SAVEFIELDS_SUCCESS';
export const SAVEFIELDS_FAIL = 'SAVEFIELDS_FAIL';

export const GET_FIELDS_DATA_START = 'GET_FIELDS_DATA_START';
export const GET_FIELDS_DATA_SUCCESS = 'GET_FIELDS_DATA_SUCCESS';
export const GET_FIELDS_DATA_FAIL = 'GET_FIELDS_DATA_FAIL';

export const TOTALANSCOUNT_START = 'TOTALANSCOUNT_START';
export const TOTALANSCOUNT_SUCCESS = 'TOTALANSCOUNT_SUCCESS';
export const TOTALANSCOUNT_FAIL = 'TOTALANSCOUNT_FAIL';

export const MSGCATEGORY_START = 'MSGCATEGORY_START';
export const MSGCATEGORY_SUCCESS = 'MSGCATEGORY_SUCCESS';
export const MSGCATEGORY_FAIL = 'MSGCATEGORY_FAIL';

export const MSGCATEGORYANS_START = 'MSGCATEGORYANS_START';
export const MSGCATEGORYANS_SUCCESS = 'MSGCATEGORYANS_SUCCESS';
export const MSGCATEGORYANS_FAIL = 'MSGCATEGORYANS_FAIL';

export const CONANSUNSCOUNT_START = 'CONANSUNSCOUNT_START';
export const CONANSUNSCOUNT_SUCCESS = 'CONANSUNSCOUNT_SUCCESS';
export const CONANSUNSCOUNT_FAIL = 'CONANSUNSCOUNT_FAIL';

export const CONANSUNSCOUNTREPORT_START = 'CONANSUNSCOUNTREPORT_START';
export const CONANSUNSCOUNTREPORT_SUCCESS = 'CONANSUNSCOUNTREPORT_SUCCESS';
export const CONANSUNSCOUNTREPORT_FAIL = 'CONANSUNSCOUNTREPORT_FAIL';

export const UNANSWERED_CAT_START = 'UNANSWERED_CAT_START';
export const UNANSWERED_CAT_SUCCESS = 'UNANSWERED_CAT_SUCCESS';
export const UNANSWERED_CAT_FAIL = 'UNANSWERED_CAT_FAIL';

export const UNANSWERED_UTTERENCE_START = 'UNANSWERED_UTTERENCE_START';
export const UNANSWERED_UTTERENCE_SUCCESS = 'UNANSWERED_UTTERENCE_SUCCESS';
export const UNANSWERED_UTTERENCE_FAIL = 'UNANSWERED_UTTERENCE_FAIL';

export const PROJECT_HEADER_SHOW='PROJECT_HEADER_SHOW';

export const AGENTTRANSFER_CAT_START = 'AGENTTRANSFER_CAT_START';
export const AGENTTRANSFER_CAT_SUCCESS = 'AGENTTRANSFER_CAT_SUCCESS';
export const AGENTTRANSFER_CAT_FAIL = 'AGENTTRANSFER_CAT_FAIL';

export const AGENTTRANSFER_UTTERENCE_START = 'AGENTTRANSFER_UTTERENCE_START';
export const AGENTTRANSFER_UTTERENCE_SUCCESS = 'AGENTTRANSFER_UTTERENCE_SUCCESS';
export const AGENTTRANSFER_UTTERENCE_FAIL = 'AGENTTRANSFER_UTTERENCE_FAIL';

export const TOP_AGENTTRANSFER_START = 'TOP_AGENTTRANSFER_START';
export const TOP_AGENTTRANSFER_SUCCESS = 'TOP_AGENTTRANSFER_SUCCESS';
export const TOP_AGENTTRANSFER_FAIL = 'TOP_AGENTTRANSFER_FAIL';

export const TOTAL_COGUSER_REPORT_START = 'TOTAL_COGUSER_REPORT_START';
export const TOTAL_COGUSER_REPORT_SUCCESS = 'TOTAL_COGUSER_REPORT_SUCCESS';
export const TOTAL_COGUSER_REPORT_FAIL = 'TOTAL_COGUSER_REPORT_FAIL';

export const GET_INTENT_DESCRIPTION_SUCCESS = 'GET_INTENT_DESCRIPTION_SUCCESS';
export const GET_INTENT_DESCRIPTION_FAIL = 'GET_INTENT_DESCRIPTION_FAIL';

export const GET_FAQ_FROM_URL_START = "GET_FAQ_FROM_URL_START";
export const GET_FAQ_FROM_URL_SUCCESS = 'GET_FAQ_FROM_URL_SUCCESS';
export const GET_FAQ_FROM_URL_FAIL = 'GET_FAQ_FROM_URL_FAIL';

export const GET_CONTINUOUS_DATA_START = "GET_CONTINUOUS_DATA_START";
export const GET_CONTINUOUS_DATA_SUCCESS = 'GET_CONTINUOUS_DATA_SUCCESS';
export const GET_CONTINUOUS_DATA_FAIL = 'GET_CONTINUOUS_DATA_FAIL';

export const SAVE_CONTINUOUS_DATA_SUCCESS = 'SAVE_CONTINUOUS_DATA_SUCCESS';

export const GET_WAITING_FOR_APPROVAL_INTENT_START = "GET_WAITING_FOR_APPROVAL_INTENT_START";
export const GET_WAITING_FOR_APPROVAL_INTENT_SUCCESS = 'GET_WAITING_FOR_APPROVAL_INTENT_SUCCESS';
export const GET_WAITING_FOR_APPROVAL_INTENT_FAIL = 'GET_WAITING_FOR_APPROVAL_INTENT_FAIL';

export const ACTION_START = "ACTION_START";
export const ACTION_SUCCESS = "ACTION_SUCCESS";
export const ACTION_FAILURE = "ACTION_FAILURE";

export const GET_WHATSAPP_DATA_SUCCESS = "GET_WHATSAPP_DATA_SUCCESS";

export const TOTAL_TRIAGE_REPORT_START = "TOTAL_TRIAGE_REPORT_START";
export const TOTAL_TRIAGE_REPORT_SUCCESS = "TOTAL_TRIAGE_REPORT_SUCCESS";
export const TOTAL_TRAIGE_REPORT_FAIL = "TOTAL_TRAIGE_REPORT_FAIL";

export const CONVERSATIONAL_METRICS_START = "CONVERSATIONAL_METRICS_START";
export const CONVERSATIONAL_METRICS_SUCCESS = "CONVERSATIONAL_METRICS_SUCCESS";
export const CONVERSATIONAL_METRICS_FAIL = "CONVERSATIONAL_METRICS_FAIL";

export const COGNITIVE_METRICS_START = "COGNITIVE_METRICS_START";
export const COGNITIVE_METRICS_SUCCESS = "COGNITIVE_METRICS_SUCCESS";
export const COGNITIVE_METRICS_FAIL = "COGNITIVE_METRICS_FAIL";

export const CONVERSATIONAL_FEEDBACK_START = "CONVERSATIONAL_FEEDBACK_START";
export const CONVERSATIONAL_FEEDBACK_SUCCESS = "CONVERSATIONAL_FEEDBACK_SUCCESS";
export const CONVERSATIONAL_FEEDBACK_FAIL= "CONVERSATIONAL_FEEDBACK_FAIL";

export const CONVERSATIONAL_NEGATIVE_UTTERANCE_START = "CONVERSATIONAL_NEGATIVE_UTTERANCE_START";
export const CONVERSATIONAL_NEGATIVE_UTTERANCE_SUCCESS = "CONVERSATIONAL_NEGATIVE_UTTERANCE_SUCCESS";
export const CONVERSATIONAL_NEGATIVE_UTTERANCE_FAIL= "CONVERSATIONAL_NEGATIVE_UTTERANCE_FAIL";

export const CONVERSATIONAL_HANDLED_START = "CONVERSATIONAL_HANDLED_START";
export const CONVERSATIONAL_HANDLED_SUCCESS = "CONVERSATIONAL_HANDLED_SUCCESS";
export const CONVERSATIONAL_HANDLED_FAIL= "CONVERSATIONAL_HANDLED_FAIL";

export const CONVERSATIONAL_MSG_UTTERANCE_START = "CONVERSATIONAL_MSG_UTTERANCE_START";
export const CONVERSATIONAL_MSG_UTTERANCE_SUCCESS = "CONVERSATIONAL_MSG_UTTERANCE_SUCCESS";
export const CONVERSATIONAL_MSG_UTTERANCE_FAIL= "CONVERSATIONAL_MSG_UTTERANCE_FAIL";

export const CONVERSATIONAL_UNANS_UTTERANCE_START = "CONVERSATIONAL_UNANS_UTTERANCE_START";
export const CONVERSATIONAL_UNANS_UTTERANCE_SUCCESS = "CONVERSATIONAL_UNANS_UTTERANCE_SUCCESS";
export const CONVERSATIONAL_UNANS_UTTERANCE_FAIL= "CONVERSATIONAL_UNANS_UTTERANCE_FAIL";
export const COPY_CHANNEL_SUCCESS = "COPY_CHANNEL_SUCCESS";

export const GET_USECASE_DETAILS_START = "GET_USECASE_DETAILS_START";
export const GET_USECASE_DETAILS_SUCCESS = "GET_USECASE_DETAILS_SUCCESS";
export const GET_USECASE_DETAILS_FAIL= "GET_USECASE_DETAILS_FAIL";

export const CREATE_USECASE_START = "CREATE_USECASE_START";
export const CREATE_USECASE_SUCCESS = "CREATE_USECASE_SUCCESS";
export const CREATE_USECASE_FAIL= "CREATE_USECASE_FAIL";

export const GET_SOURCE_ATTRIBUTES_START = "GET_SOURCE_ATTRIBUTES_START";
export const GET_SOURCE_ATTRIBUTES_SUCCESS = "GET_SOURCE_ATTRIBUTES_SUCCESS";
export const GET_SOURCE_ATTRIBUTES_FAIL= "GET_SOURCE_ATTRIBUTES_FAIL";

export const FETCH_SOURCE_ATTRIBUTES_START = "FETCH_SOURCE_ATTRIBUTES_START";
export const FETCH_SOURCE_ATTRIBUTES_SUCCESS = "FETCH_SOURCE_ATTRIBUTES_SUCCESS";
export const FETCH_SOURCE_ATTRIBUTES_FAIL= "FETCH_SOURCE_ATTRIBUTES_FAIL";

export const CREATE_COLLECTION_VALUE_START = "CREATE_COLLECTION_VALUE_START";
export const CREATE_COLLECTION_VALUE_SUCCESS = "CREATE_COLLECTION_VALUE_SUCCESS";
export const CREATE_COLLECTION_VALUE_FAIL= "CREATE_COLLECTION_VALUE_FAIL";

export const GET_MODELOUTPUT_DATA_START = "GET_MODELOUTPUT_DATA_START";
export const GET_MODELOUTPUT_DATA_SUCCESS = "GET_MODELOUTPUT_DATA_SUCCESS";
export const GET_MODELOUTPUT_DATA_FAIL= "GET_MODELOUTPUT_DATA_FAIL";

export const  SAVE_MODELOUTPUT_DATA_START = "SAVE_MODELOUTPUT_DATA_START";
export const SAVE_MODELOUTPUT_DATA_SUCCESS = "SAVE_MODELOUTPUT_DATA_SUCCESS";
export const SAVE_MODELOUTPUT_DATA_FAIL = "SAVE_MODELOUTPUT_DATA_FAIL";




export const GET_USECASE_DETAILS_BYNAME_START = "GET_USECASE_DETAILS_BYNAME_START";
export const GET_USECASE_DETAILS_BYNAME_SUCCESS = "GET_USECASE_DETAILS_BYNAME_SUCCESS";
export const GET_USECASE_DETAILS_BYNAME_FAIL= "GET_USECASE_DETAILS_BYNAME_FAIL";

export const UPDATE_CLUSTER_DATA_START = "UPDATE_CLUSTER_DATA_START"
export const UPDATE_CLUSTER_DATA_SUCCESS = "UPDATE_CLUSTER_DATA_SUCCESS";
export const UPDATE_CLUSTER_DATA_FAIL= "UPDATE_CLUSTER_DATA_FAIL";

export const GET_DATA_REVIEW_DATA_START = "GET_DATA_REVIEW_DATA_START"
export const GET_DATA_REVIEW_DATA_SUCCESS = "GET_DATA_REVIEW_DATA_SUCCESS";
export const GET_DATA_REVIEW_DATA_FAIL= "GET_DATA_REVIEW_DATA_FAIL";

export const GET_SENTIMENT_CATEGORY_STATUS_LIST_SUCCESS = "GET_SENTIMENT_CATEGORY_STATUS_LIST_SUCCESS";

export const UPDATE_DATA_REVIEW_SUCCESS = "UPDATE_DATA_REVIEW_SUCCESS";