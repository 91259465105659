import React, { useState, useEffect } from "react";
import Drawer from "@material-ui/core/Drawer";
import * as material from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import "./UserRoles.css";
import { Col, Row } from "react-bootstrap";
import DrawerHeaderContent from "../../components/DrawerHeaderContent/DrawerHeaderContent";
import { useDispatch } from "react-redux";
import InputWithLabelComponent from "../../components/InputWithLabelComponent/InputWithLabelComponent";
import PrimaryButtonWithLoader from "../../components/PrimaryButtonWithLoader/PrimaryButtonWithLoader";
import Heading5 from "../../components/Typography/Heading5/Heading5";
import { UpdateCalltoOUServer } from "../helpers/utility";
import Accordion from "react-bootstrap/Accordion";
import TextAreaWithLabelComponent from "../../components/TextAreaWithLabelComponent/TextAreaWithLabelComponent";
import permissionsJson from "../../../../../permissions_Json";
import {
    SetErrorMessage,
    SetSuccessMessage,
} from "../../../../../store/actions";
import ButtonComponent from "../../../../../components/UIComponents/Button/ButtonComponent";

const useStyles = makeStyles({
    list: {
        width: "29rem",
    },
});

let categoryPermissions = {
    "Conversational AI": {
        "Conversation Builder": permissionsJson.permissionCode.CONVERSATIONAL_AI_STANDARD_RW,
        "Dashboard": permissionsJson.permissionCode.CONVERSATIONAL_AI_DASHBOARD_RW,
        "Admin Settings": permissionsJson.permissionCode.CONVERSATIONAL_AI_ADMIN_RW
    },
    "Cognitive Search": {
        "Interface": permissionsJson.permissionCode.COGNITIVE_SEARCH_STANDARD_RW,
        "Knowledge Upload": permissionsJson.permissionCode.COGNITIVE_SEARCH_KU_RW,
        "Dashboard": permissionsJson.permissionCode.COGNITIVE_SEARCH_DASHBOARD_RW,
        "Admin Settings": permissionsJson.permissionCode.COGNITIVE_SEARCH_ADMIN_RW
    },
    "Product Triage": {
        "Interface": permissionsJson.permissionCode.PRODUCT_TRIAGE_STANDARD_RW,
        "Model Training": permissionsJson.permissionCode.PRODUCT_TRIAGE_TRAIN_RW,
        "Dashboard": permissionsJson.permissionCode.PRODUCT_TRIAGE_DASHBOARD_RW,
        "Admin Settings": permissionsJson.permissionCode.PRODUCT_TRIAGE_ADMIN_RW
    },
    "Common": {
        "Project Management": permissionsJson.permissionCode.PROJECTM_RW,
        "User Management": permissionsJson.permissionCode.USERMANAGEMENT_RW,
        "Integration Management": permissionsJson.permissionCode.INTEGRATIONM_RW,
    },

}

const EditRole = ({
    role,
    config,
    showDrawer,
    toggleDrawer,
    updateRoles,
    roleheading,
    savebutton,
    data,
    closeDrawer,
    removeRole,
    systemPermissions,
}) => {
    let defaultPerm = [];


    const orgname = localStorage.getItem("orgName");

    const confirmMsg = (msg, method) => {
        setTimeout(() => {
            dispatch(method(msg));
        }, 450);
    };

    useEffect(() => {
        getSelectedPermissions();
    }, []);

    const getSelectedPermissions = () => {
        setFormData((formData) => ({
            ...formData,
            rolename: data.rolename,
            description: role.description,
            permissions: selectedPermissions,
        }));
        role.permissions.map((item) => {
            setSelectedPermissions((prevItems) => [
                ...prevItems,
                item.permission_code,
            ]);
        });
    };


    let [selectedPermissions, setSelectedPermissions] = useState(defaultPerm);

    const [errorMsg, setErrorMsg] = useState();
    const [formData, setFormData] = useState({
        orgname: orgname,
        rolename: "",
        description: "",
        permissions: defaultPerm,
    });

    const dispatch = useDispatch();
    const classes = useStyles();

    useEffect(() => {
        setFormData((formData) => ({
            ...formData,
            permissions: selectedPermissions,
        }));
    }, [selectedPermissions]);


    const onChangePermissions = (e) => {
        let perm = e.target.value;
        let newPerm = [];
        if (selectedPermissions.includes(perm)) {
            selectedPermissions = selectedPermissions.filter(ele => ele !== perm);
        } else {
            selectedPermissions.push(perm)
        }
        newPerm = selectedPermissions ? selectedPermissions : [];
        setSelectedPermissions([...newPerm])
    }


    const updateRole = (e) => {
        e.preventDefault();
        const url = config.COMMON_API_URL + `/organization/${orgname}/role/${role.rolename}`;
        UpdateCalltoOUServer(url, {
            rolename: data.rolename,
            permissions: selectedPermissions,
            description: formData.description,
        })
            .then((response) => {
                if (response.status == 204) {
                    toggleDrawer(true);
                    updateRoles();
                    closeDrawer();
                    confirmMsg("Role updated successfully", SetSuccessMessage);
                } else if (response.response.status == 400) {
                    setErrorMsg("Please select permissions.");
                    dispatch(SetErrorMessage("Please select permission."));
                } else if (response.response.status == 409) {
                    setErrorMsg("Role name already exists.");
                    dispatch(SetErrorMessage("Role name already exists."));
                } else {
                    dispatch(SetErrorMessage("Role creation failed!"));
                }
            })
            .catch((err) => {
                console.log("add role error: ", err);
            });
    };

    const onChangeHandler = (event, type) => {
        setFormData((formData) => ({
            ...formData,
            [type]: event.target.value,
        }));
    };

    return (
        <React.Fragment>
            {showDrawer ? (
                <Drawer
                    anchor={"right"}
                    open={showDrawer}
                    onClose={toggleDrawer(false)}
                >
                    <form onSubmit={updateRole} className="role-form">
                        <div
                            className={clsx(classes.list, classes.fullList)}
                            role="presentation"
                        >
                            <DrawerHeaderContent
                                heading={roleheading}
                                toggleDrawer={toggleDrawer}
                            />
                            <div className="d-flex flex-column Symtom-Item highlights">
                                <div className="mt-1">
                                    <Row>
                                        <Col md={12}>
                                            <InputWithLabelComponent
                                                type="text"
                                                className="input-label"
                                                label="Role Name"
                                                value={formData.rolename}
                                                readOnly={true}
                                                required={true}
                                            />
                                        </Col>
                                        <br />
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col md={12}>
                                            <TextAreaWithLabelComponent
                                                label="Description"
                                                name="description"
                                                required={true}
                                                value={formData.description}
                                                placeholder={"Enter description"}
                                                onChange={(e) => onChangeHandler(e, "description")}
                                            />
                                        </Col>
                                    </Row>{" "}
                                    <br />
                                </div>
                            </div>
                            <div className="d-flex flex-column Symtom-Item ">
                                <div className="mt-1">
                                    <div className="d-flex flex-column ">
                                        <Heading5
                                            className="role-permission-header"
                                            text="Role Permissions"
                                        />
                                    </div>
                                    <Row>
                                        <Col md={12} className="role-categories">
                                            {systemPermissions && systemPermissions.length > 0 && (<>
                                                {Object.keys(categoryPermissions).map((section, i) => {
                                                    return (<material.Accordion kay={i} className="my-2 cat-tab active">
                                                        <AccordionSummary
                                                            className="m-0"
                                                            expandIcon={<img src="./Icons/ddown.png"/>}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                        >
                                                            <Typography className="role-headers-content">{section}</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails className="accordion-details">
                                                            <Typography>
                                                                <Accordion defaultActiveKey="0" className="row">
                                                                    {section &&
                                                                        Object.keys(categoryPermissions[section]).map((scope, i) => {
                                                                            return (<div className="col-md-6" key={i}>
                                                                                <input className="role-check-box"
                                                                                    type="checkbox" id={scope}
                                                                                    name={scope}
                                                                                    value={categoryPermissions[section][scope]}
                                                                                    onChange={onChangePermissions}
                                                                                    checked={selectedPermissions.includes(categoryPermissions[section][scope])} />
                                                                                <label style={{ paddingLeft: "inherit" }} for={scope} className="checkbox-label"> {scope}</label>
                                                                            </div>)
                                                                        })}
                                                                </Accordion>
                                                            </Typography>
                                                        </AccordionDetails>
                                                    </material.Accordion>)
                                                })}
                                            </>
                                            )}
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            <div className="d-flex flex-column Symtom-Item">
                                <div className="mt-1 d-flex justify-content-center">
                                    <ButtonComponent variant='outlined'
                                        label='Delete' className='buttonClass delete-btn mr-4'
                                        clicked={()=>{removeRole(role.rolename)}} />
                                    <PrimaryButtonWithLoader
                                        size="lg"
                                        text={savebutton}
                                        loading={false}
                                        type="submit"
                                    />
                                </div>
                                <span className="error-msg">{errorMsg}</span>
                            </div>
                        </div>
                    </form>
                </Drawer>
            ) : null}
        </React.Fragment>
    );
};

export default EditRole;
