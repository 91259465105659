import React, { useState, useEffect } from 'react';
import get from 'lodash/get';

import { useSelector } from 'react-redux';
import { Col } from 'react-bootstrap';

import IssueResultsCardComponent from "../IssueResultsCardComponent/IssueResultsCardComponent";

import { Row, Spinner } from 'react-bootstrap';
import './insights-results.css';

import IssueResultsDetails from '../IssueResultsDetailsComponent/IssueResultsDetails';


import { isEqual, uniqueId } from "lodash";
import { GetCalltoServer } from '../../../../../../store/utility';
import getProjectDetails from '../../../helpers/getProjectDetails';
import objectKeysToLowerCase from '../../helpers/objectKeysToLowerCase';

const SimilarIssuesTab = ({
    config, updateCounts
}) => {
    const projectName = getProjectDetails();
    const [showDetailsComponent, setShowDetailsComponent] = useState(false);
    const agentInsightsLoading = useSelector(state => state.agentInsights.agentInsightsLoading);
    const agentInsightsData = useSelector(state => state.agentInsights.agentInsightsData);
    const productAtt = useSelector((state) => state.agentInsights.filterData);   
    const results = get(agentInsightsData, 'responsedata', []);
    const [data, setData] = useState([]);
    const [productAtributes, setProductAtributes] = useState([]);  
    const [selectedIssueId, setSelectedIssueId] = useState(null);   
    const [searchText, setSearchText] = useState('');
    const [showDrawer, setShowDrawer] = useState(false);
    const [searchEnable, setSearchEnable] = useState(false);
    const [configureKeys, setConfigureKeys] = useState([]);
 

    const toggleDrawer = (value) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }
        setShowDrawer(value);
    };
    const onShowDetailsTab = (index) => {
        setShowDetailsComponent(true);
        setShowDrawer(true);
        setSelectedIssueId(index);
    };

    useEffect(() => {
        setData(get(agentInsightsData, 'responsedata', []));
        setConfigureKeys( ["id","project", ...productAtributes?.map(obj=> obj.attribute_name)||[]])
        setProductAtributes(productAtt)
        setSearchEnable(get(agentInsightsData, 'responsedata', []) !== 0)
    }, [agentInsightsData,productAtributes]);

   

    const [keys, setKeys]=useState([])
    console.log('keys', keys)


    useEffect(() => {
        updateCounts("similarIssues", results);
        GetCalltoServer(config.INSIGHTS_API_URL + `/product-intelligent-triage/settings/insightsconfiguration/project/${projectName}`)
             .then((response) => {                
                const JsonObj = JSON.parse(response.data.data).similartickets;
                console.log('JsonObj', JsonObj);  
                if(JsonObj){
                    setKeys(JsonObj.extensionFields)  
                }
              
             }).catch((err) => {
                 console.log("err",err)
             })
            
    },[])


    useEffect(() => {       
        if(keys && keys.length>0){
            setConfigureKeys( ["id","project", ...keys])  
        }else{
            setConfigureKeys( ["id","project", ...productAtributes?.map(obj=> obj.attribute_name)||[]]) 
        }
          
    }, [productAtributes, keys]);



    // useEffect(() => {
       
    //     updateCounts("similarIssues", results);
    //     GetCalltoServer(config.INSIGHTS_API_URL + `/product-intelligent-triage/settings/insightsconfiguration/project/${projectName}`)
    //          .then((response) => {                
    //             const JsonObj = JSON.parse(response.data.data).similartickets;
    //             console.log('JsonObj', JsonObj);      
    //             setConfigureKeys( ["id","project", ...productAtributes?.map(obj=> obj.attribute_name)])
    //             // if(JsonObj.enabled && JsonObj.hasOwnProperty('extensionFields'))
    //             //    setConfigureKeys(!isEqual(JsonObj.extensionFields.length , 0 ) ? JsonObj.extensionFields : ["id","project", ...productAtributes.map(obj=> obj.attribute_name)]);
    //             //    alert('if')
    //             // }else {
    //             //     alert('else')
    //             //     setConfigureKeys(["id","project", ...productAtributes.map(obj=> obj.attribute_name)  ])
    //             //     // setConfigureKeys(["id","project" , ...productAtributes.map(obj=> obj.attribute_name)])
    //             // }
    //          }).catch((err) => {
    //              console.log("err",err)
    //          })
            
    // },[results])
   
    // const searchData = (event) => {
    //     setSearchText(event.target.value);
    //     const filteredRows = results.filter((row) => {
    //         if (event.target.value.trim() === '') {
    //             setData(results);
    //             updateCounts("similarIssues", results)
    //         }
    //         return row.issueid.toLowerCase().includes(event.target.value.toLowerCase()) ||
    //             row.description.toLowerCase().includes(event.target.value.toLowerCase());
    //     });
    //     setData(filteredRows);
    //     updateCounts("similarIssues", filteredRows)
    // }

    return (
        <React.Fragment>
             {data.length>0?
             <>
             <Row className='mt-3'>
                <Col xs={{ span: 3, offset: 9 }}  className='d-flex'>
                    <div className="search-input">
                    {/* <SearchComponent
                                name={'recomended-solution'}
                                searchValue={searchText}
                                onSearch={searchData}
                                placeHolder={'Search'}
                            /> */}
                    </div>
                </Col>
            </Row>
            <Row className="d-flex pl-2 mt-4">
             {(configureKeys.slice(0,4)).map((item, index) => {
            return (<>
                <Col  xs={ isEqual(index, 1) ? 2 : 3 } className={ isEqual(index, 0) ? '' : 'pl-1'}>
                    <p className="col-md-2 issue-descp-title issue-sub-title" style={{ textTransform:'uppercase'}}>{item}</p>
                </Col>
            </>)
              })}
                
            </Row>
            <div className="insights-tab-scrool">
                {agentInsightsLoading  ?
                    <Row className="justify-content-center align-items-center">
                        <Spinner animation="border" variant="primary" />
                    </Row>
                    : <React.Fragment>                    
                        {data.length ?
                            data.map((item, index) => {
                                return (
                                    <IssueResultsCardComponent
                                        active={selectedIssueId === index}
                                        issueid={item.issueid}
                                        key={uniqueId()}
                                        description={item.description}
                                        showDetailsTab={() => onShowDetailsTab(index,item.issueid)}
                                        isRecommendedTab={false}
                                        configureKeys={configureKeys.map(name => name.toLowerCase())}
                                        item={objectKeysToLowerCase(item)}
                                    />
                                    //<AccordionComponent  config={config} isSimilarIssuesTab={true} item={item} />

                                )
                            })
                            : null
                        }
                        {showDrawer && results.length ?
                            <IssueResultsDetails
                                data={results}
                                onDetailsCloseHandler={() => setShowDrawer(false)}
                                selectedIssueId={selectedIssueId}
                                key={uniqueId()}
                                toggleDrawer={toggleDrawer}
                                item={objectKeysToLowerCase(get(data, `${selectedIssueId}`, ''))}
                                config={config}
                                configureKeys={configureKeys.map(name => name.toLowerCase())}
                            />
                            : null
                        }
                    </React.Fragment>
                }
            </div>
            </>
            :
            <div className="row nodataAgentcockpit justify-content-center align-items-center">
                No Data Available 
          </div>
            }

        </React.Fragment>
    )
};

export default SimilarIssuesTab;
