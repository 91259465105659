import React from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { uniqueId } from "lodash";

const checkbox = (props) => {

  return (
    <div>
      <FormGroup>
        {props.checkboxes.map((item) => (
          <FormControlLabel
            style={{ margin: "0px" }}  key={uniqueId()}
            control={
              <Checkbox
                checked={true}
                name={item.name}
                color="primary"
                disabled={true}
              />
            }
            label={item.name}
          />
        ))}
      </FormGroup>
    </div>
  );
};

export default checkbox;
