import { Card, Col, Row, Form } from "react-bootstrap";

import "./issue-results-card.css";

import { isEqual } from "lodash";

const IssueResultsCardComponent = ({
  issueid,
 
  showDetailsTab,
  active,
  isRecommendedTab,
  relevancy,
  appliedCheckboxes,
  configureKeys,
  item
}) => {

  

  // const checkHandler = (e, issueid) => {
  //   dispatch(recomendedSolutionsCheckboxAction(`${issueid}`));
  // };

  return (
    <Card className={`issue-results-box mb-2 ${active ? "selected" : ""}`} onClick={showDetailsTab} >
       { isRecommendedTab  ? (<Card.Body className="d-flexalign-items-center">
          <Row>
            <Col xs={4}>
              <div className="issue-results-description">
                <div key={issueid}>
                  <Form.Check
                    inline
                    type={"checkbox"}
                    id={`default-${issueid}`}
                    label={issueid}
                    name={issueid}
                    // onChange={(e) => checkHandler(e, issueid)}
                    checked={appliedCheckboxes.includes(issueid)}
                  />
                </div>
              </div>
            </Col>
            <Col xs={7}>
              <div className="issue-results-description">{relevancy}</div>
            </Col>
            <Col xs={1} className="d-flex " >
            </Col>
          </Row>
        </Card.Body>) :
        (<Card.Body className="d-flexalign-items-center">
          <Row>
            {(configureKeys.slice(0, 4)).map((value, index) => {
              return (<>
                <Col xs={isEqual(index, 1) ? 2 : 3}>
                  <div className="issue-results-description small-caps">
                    {item.hasOwnProperty(value) ? item[value] : "- -"}
                  </div>
                </Col>
              </>)
            })}
            <Col xs={1} className="d-flex " >
              <div> <img src="./images/Vector.svg" alt="Arrow-img" /> </div>
            </Col>
          </Row>
        </Card.Body>)}
    </Card>
  );
};

export default IssueResultsCardComponent;
