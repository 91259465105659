import React, { Component } from "react";
import { connect } from "react-redux";
import ButtonCustom from "../../../../components/UI/Button/Button";
import classes from "../../SettingPage.module.css";
import { Table } from "react-bootstrap";
import TableHeader from "../../../../components/UI/Table/TableHeader";
import Spinner from "../BackupRestore/Spinner/Spinner";
import axios from "../../../../instance";
import { isEqual } from "lodash";
import * as actions from "../../../../store/actions/index";
import Modal from "react-bootstrap/Modal";
import { botPostCalltoServer, botPostCalltoServerWithFormData, GetCalltoServer } from "../../../../store/utility";

function mapStateToProps(state) {
  return {
    recentBackupDetails: state.backupRestore.recentBackupDetails,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getBackupDetails: () => dispatch(actions.getBackupDetails()),
    setBackupDetails: (data) => dispatch(actions.setBackupDetails(data)),
    SetSuccessMessage: (msg) => dispatch(actions.SetSuccessMessage(msg)),
    SetErrorMessage: (msg) => dispatch(actions.SetErrorMessage(msg))
  };
}

let frequency = {
  day: "Daily",
  week: "Weekly"
}
class BackupTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      retentionValue: 30,
      show: false,
      isWeekly:false,
      scheduleType:"day",
      selectedDay:0,
      days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
      showSpinner: false,
      intialDate: new Date().toISOString().slice(0, 10),
      initialTime:"00:00",
      recentBackup: this.props.recentBackupDetails,
      scheduleBackupDetails:[],
      activeScheduleDetail:null,
      apiUrl: props.apiURL,
      columnHeader: ["Date Time", "Service Name", "Snapshot Name"],
      scheduleColumnHeader: ["Frequency", "Day", "Time", "Retention(Days)", "User"],
      errorMsg: "",
    };
    this.backupNow = this.backupNow.bind(this);
    this.statusEsDump = this.statusEsDump.bind(this);
  }

  componentDidMount() {
    
    this.getBackupDetails();
    this.getScheduleBackupDetails();
    this.getActiveScheduleDetail();
  }

  handleDay = (e) => {
    this.setState({
      selectedDay: e.target.value
    })
  }

  getActiveScheduleDetail = () => {
    GetCalltoServer(this.state.apiUrl + "/backup/last/schedule/detail")
      .then((response) => {
        if (isEqual(response.status, 200)) {
          let info=response.data;
          if(info){
            let day=new Date();
            let min=info.minutes-day.getTimezoneOffset();
            day.setHours(info.hours);
            day.setMinutes(min);
            let hours=('0'+day.getHours()).slice(-2);
            let mins=('0'+day.getMinutes()).slice(-2);
            this.setState({
              activeScheduleDetail: info,
              retentionValue: info.retension,
              isWeekly:"day"==info.frequency?false:true,
              scheduleType:info.frequency,
              selectedDay:info.day,
              initialTime:`${hours}:${mins}`,
            });
          }
        } else if (isEqual(response.status, 400) && isEqual(response.data.status.responseType, "Failure")) {
          this.props.SetErrorMessage("esbkdump Service Failure here");
        } else {
          this.props.SetErrorMessage("unhandled error block ekbkupdump !");
        }
      })
      .catch((error) => {
        this.setState({ showSpinner: false });
        this.props.SetErrorMessage(error);
      });
  }

  getScheduleBackupDetails = () => {
    GetCalltoServer(this.state.apiUrl + "/backup/schedule/details")
      .then((response) => {
        if (isEqual(response.status, 200)) {
          this.setState({
            scheduleBackupDetails: response.data
          });
        } else if (isEqual(response.status, 400) && isEqual(response.data.status.responseType, "Failure")) {
          this.props.SetErrorMessage("esbkdump Service Failure here");
        } else {
          this.props.SetErrorMessage("unhandled error block ekbkupdump !");
        }
      })
      .catch((error) => {
        this.setState({ showSpinner: false });
        this.props.SetErrorMessage(error);
      });
  }

  getBackupDetails = () => {
    GetCalltoServer(this.state.apiUrl + `/backup/details?day=${this.state.retentionValue}`)
      .then((response) => {
        if (isEqual(response.status, 200)) {
          this.setState({
            recentBackup: response.data
          });
        } else if (isEqual(response.status, 400) && isEqual(response.data.status.responseType, "Failure")) {
          this.props.SetErrorMessage("esbkdump Service Failure here");
        } else {
          this.props.SetErrorMessage("unhandled error block ekbkupdump !");
        }
      })
      .catch((error) => {
        this.setState({ showSpinner: false });
        this.props.SetErrorMessage(error);
      });
  }

  handleScheduleBackup = () => {
    let date= new Date();
    let time = this.state.initialTime.split(":")
    date.setHours(parseInt(time[0]),parseInt(time[1]))
    
    let data = {
      "frequency": this.state.scheduleType,
      "day": parseInt(this.state.selectedDay),
      "hours": date.getUTCHours(),
      "minutes": date.getUTCMinutes(),
      "retension": this.state.retentionValue,
      "services": [],
      "user": localStorage.getItem("userName")
    }

    botPostCalltoServer(this.state.apiUrl + "/backup/schedule", data).then((response) => {
      if (isEqual(response.status, 200)) {
        this.props.toastFn("Backup scheduled","success");
        this.getScheduleBackupDetails();
      }
      else {
        this.setState({
          show: true,
          showSpinner: false
        });
      }
    })
      .catch((error) => {
        this.setState({
          show: true,
          errorMsg: error,
        });
      });

  }

  scheduleTypeHandler = (e) => {
    this.setState({
      isWeekly: e.target.value !== 'day',
      scheduleType: e.target.value
    })
  }

  statusEsDump = (url, data) => {
    axios
      .post(url + "/statusesdump", data)
      .then((response) => {
        if (isEqual(response.data.data, "completed")) {
          this.state.recentBackup.push({
            date: new Date().toUTCString().slice(0,17),    //new Date().toISOString().slice(0, 10), for local Date
            time: new Date().toTimeString(),               //new Date().toLocaleTimeString() for local system Time
            status: response.data.data,
          });
          this.setState({
            recentBackup: this.state.recentBackup,
            showSpinner: false,
            intialDate : new Date().toISOString().slice(0, 10)
          });
          this.props.setBackupDetails(this.state.recentBackup);
          this.props.SetSuccessMessage("Es Backup completed");
          localStorage.setItem( 'recentBackupDetails', JSON.stringify(this.state.recentBackup));
        } else if (isEqual(response.data.data, "started")) {
          setTimeout(() => {
            this.statusEsDump(url, data);
          }, 4000);
        } else {
          this.props.SetErrorMessage("Es Backup statusesdump Failed");
          this.setState({ 
            showSpinner : false,
            intialDate : new Date().toISOString().slice(0, 10)
          });
        }
      })
      .catch((error) => {
        this.props.SetErrorMessage("Es Backup statusesdump Failed", error);
        this.setState({ 
          showSpinner : false,
          intialDate : new Date().toISOString().slice(0, 10)
        });
      });
  };

  backupNow = () => {
    this.setState({ showSpinner: true });
    let bodyFormData = new FormData();
    bodyFormData.append('user', localStorage.getItem("userName"));
    
    botPostCalltoServerWithFormData(this.state.apiUrl + "/backup/create", 
    bodyFormData).then((response) => {
        if (isEqual(response.status, 200)) {
          this.props.toastFn("Backup initiated","success");
            this.getBackupDetails();
            this.setState({
              showSpinner: false
            });
        }
        else {
          this.setState({
            show: true,
            errorMsg: "unable to find ElasticDump!",
            showSpinner: false
          });
        }
      })
      .catch((error) => {
        this.setState({
          show: true,
          errorMsg: error,
        });
      });
  };

  render() {
    const {
      days, scheduleBackupDetails, scheduleColumnHeader, columnHeader,
      recentBackup, showSpinner, intialDate, scheduleType,
      isWeekly, initialTime, retentionValue, show
    } = this.state
    // const checkboxes = [
    //   { name: "Projects", key: "1", label: "Projects" },
    //   { name: "Intents", key: "2", label: "Intents" },
    //   { name: "SmartFAQ", key: "3", label: "SmartFAQ" },
    //   { name: "Connectors", key: "4", label: "Connectors" },
    //   { name: "Tenenats Settings", key: "5", label: "Tenenats Settings" },
    //   {
    //     name: "Agent converstion history",
    //     key: "6",
    //     label: "Agent converstion history",
    //   },
    // ];
    // const checkboxesOne = [
    //   { name: "Ticket Data", key: "1", label: "Ticket Data" },
    //   { name: "Knowledge Base Data", key: "2", label: "Knowledge Base Data" },
    // ];

    const daysContet=days.map((day,i)=><option value={i} key={i}>{day}</option>)

    let scheduleTabContent = (
      <Table bordered className={classes.processTable} size="md">
        <TableHeader columnHeader={scheduleColumnHeader} />
        <tbody>
          {scheduleBackupDetails.map((item, index) => {
            let day = new Date();
            let min = item.minutes - day.getTimezoneOffset();
            day.setHours(item.hours);
            day.setMinutes(min);
            let hours = ('0' + day.getHours()).slice(-2);
            let part=hours > 12 ? 'PM' : 'AM';
            hours = hours > 12 ? hours - 12 : hours;
            let mins = ('0' + day.getMinutes()).slice(-2);
            hours = hours == '00' ? '12' : hours
            return (
              <tr key={index}>
                <td>{frequency[item.frequency]}</td>
                <td>{days[item.day]}</td>
                <td>{hours}:{mins} {part}</td>
                <td>{item.retension}</td>
                <td>{item.user}</td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    );

    let tabContent = (
      <Table bordered className={classes.processTable} size="md">
        <TableHeader columnHeader={columnHeader} />
        <tbody>
            {recentBackup.map((item, index) => (
              <tr key={index}>
                <td>{item.created_at}</td>
                <td>{item.service_type}</td>
                <td>{item.snapshot_name}</td>
              </tr>
            ))}
        </tbody>
      </Table>
    );
    return (
      <div className="global-settings margin-setup">
        {showSpinner ? <Spinner /> : null}
        <h6>Global Settings</h6>
        <div className="row margin-setup">
          <div className="col-md-3">
            <input
              type="date"
              className="form-control"
              defaultValue={intialDate}
              min={intialDate}
              max={intialDate}
              onChange={(event) => {
                this.setState({
                  intialDate: event.target.value,
                });
              }}
            />
          </div>
          <div className="col-md-9">
            <div className="form-group">
              <ButtonCustom btnInput="button" clicked={this.backupNow}>
                Backup now
              </ButtonCustom>
            </div>
          </div>
        </div>
        <div className="row margin-setup">
          <div className="col-md-3">
            <div className="form-group">
              <label>Frequency</label>
              <select className="form-control"
                value={scheduleType}
                onChange={(e) => this.scheduleTypeHandler(e)}>
                <option value="day">Daily</option>
                <option value="week">Weekly</option>
              </select>
            </div>
          </div>
          <div className="col-md-3">
            {isWeekly ? <div className="form-group">
              <label>Day</label>
              <select value={this.state.selectedDay} onChange={this.handleDay} className="form-control">
                {daysContet}
              </select>
            </div> : <div className="form-group">
              <label>Time</label>
              <input
                type="time"
                value={initialTime}
                className="form-control"
                onChange={(event) => {
                  this.setState({ initialTime: event.target.value })
                }}
              />
            </div>}
          </div>
          <div className="col-md-3">
            {isWeekly && <div className="form-group">
              <label>Time</label>
              <input
                type="time"
                value={initialTime}
                className="form-control"
                onChange={(event) => {
                  this.setState({ initialTime: event.target.value })
                }}
              />
            </div>}
          </div>
          <div className="col-md-3 backup-now margin-setup">
            <ButtonCustom
              btnInput="button"
              clicked={()=>this.handleScheduleBackup()}
            >
              Schedule Backup
            </ButtonCustom>
          </div>
        </div>
        <div className="margin-setup recent-backup">
          <h6>Schedule History</h6>
          <div style={{ height: "200px", overflow: "auto" }}>
            {scheduleTabContent}
          </div>
        </div>
        <div className="margin-setup recent-backup">
          <h6>Last Backup</h6>
          <div style={{ height: "200px", overflow: "auto" }}>
            {tabContent}
          </div>
        </div>
        <hr className="margin-setup"></hr>
        <div className="retension-Policy margin-setup">
          <h6>Retension Policy</h6>
          <div className="row margin-setup" style={{ marginTop: "2%" }}>
            <div className="col-md-2">
              <input
                type="number"
                min="1" 
                max="90"
                defaultValue={retentionValue}
                className="form-control"
                onChange={(event) => {
                  this.setState({
                    retentionValue: event.target.value,
                  });
                }}
              />
            </div>
            <div className="col-md-1">
              <h6>Days</h6>
            </div>
          </div>
        </div>
        <hr className="margin-setup"></hr>
        {/* <div className="margin-setup row">
          <div className="col-md-6">
            <CheckboxComponent checkboxes={checkboxes} />
          </div>
          <div className="col-md-6">
            <CheckboxComponent checkboxes={checkboxesOne} />
          </div>
        </div> */}
        {show ? (
          <>
            <Modal
              show={show}
              onHide={() => {
                this.setState({
                  show: false,
                });
              }}
              animation={true}
            >
              <Modal.Header closeButton>
                <h4>Access</h4>
              </Modal.Header>
              <Modal.Body>
                <p>{this.state.errorMsg}</p>
              </Modal.Body>
            </Modal>
          </>
        ) : null}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BackupTab);
