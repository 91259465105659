import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";
import axios from "../../../instance";
import "./Drawer.css";
import InputWithLabelComponent from "../../../containers/SettingPage/SettingInnerPages/components/InputWithLabelComponent/InputWithLabelComponent";
import TextAreaWithLabelComponent from "../../../containers/SettingPage/SettingInnerPages/components/TextAreaWithLabelComponent/TextAreaWithLabelComponent";
import DrawerComponent from "../../UIComponents/Drawer/DrawerComponent";
import ButtonComponent from "../../UIComponents/Button/ButtonComponent";

let initState = {
  right: false,
  projectName: "",
  description: "",
  projectValidationMsg: "",
  validation: {
    projectName: true,
    description: true,
    isProjectExists: true,
  },
  projectnameCount: 15,
  projDescriptionCount: 220,
};
let ProjNametouched = false;
let ProjDesptouched = false;
class AddVirtualAssistantDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initState,
      right: true,
    };
  }

  handleInputs = (key) => (event) => {
    let value = event.target.value;
    if (key == "projectName") {
      if (
        value.length !== 0 &&
        value !== "" &&
        value !== undefined &&
        value !== null
      ) {
        ProjNametouched = true;
      } else {
        ProjNametouched = false;
      }
      let msg = "";
      if (!value) {
        msg = "Please add Virtual Assistant name.";
      } else if (!/^[A-Z0-9]+$/i.test(value)) {
        msg = "Virtual Assistant name should be alphanumeric.";
      } else if (value.length > 15) {
        msg = "Virtual Assistant name should not be more than 15 chars";
      } else if (this.props.isThisExists(value)) {
        msg = "Virtual Assistant already exists";
      }
      this.setState({
        ...this.state,
        projectName: event.target.value,
        projectValidationMsg: msg,
        validation: {
          ...this.state.validation,
          projectName: !msg ? true : false,
        },
        projectnameCount: 15 - value.length,
      });
    } else {
      if (
        value.length !== 0 &&
        value !== "" &&
        value !== undefined &&
        value !== null
      ) {
        ProjDesptouched = true;
      } else {
        ProjDesptouched = false;
      }
      this.setState({
        ...this.state,
        [key]: value,
        validation: {
          ...this.state.validation,
          [key]: [key] ? true : false,
        },
        projDescriptionCount: 220 - value.length,
      });
    }
  };

  validationOnSubmition = () => {
    let msg = "";
    const { projectName, description, projectValidationMsg } = this.state;
    if (!projectName) {
      msg = "Please add Virtual Assistant name.";
    } else {
      msg = projectValidationMsg;
    }

    this.setState({
      ...this.state,
      projectValidationMsg: msg,
      validation: {
        projectName: !msg ? true : false,
        description: description ? true : false,
        isProjectExists: this.props.isThisExists(projectName),
      },
    });
    return !msg && description ? true : false;
  };
  gotoProjectsPage = ( virtualassistantName) => {
    const queryParams = [];
    queryParams.push("va-name="+ virtualassistantName);
    const queryString = queryParams.join("&");
    this.props.history.push({
        pathname: "/virtual-assistant",
        search: "?" + queryString,
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    if (this.validationOnSubmition()) {
      let msg="Virtual Assistant created successfully."
      this.props.OnCreateAssistant(
        this.props.config.BOTBUILDER_URL,
        {
          virtualassistantName: this.state.projectName,
          virutalassistantDescription: this.state.description,
          createdUser:localStorage.getItem("userName").split('@')[0],
          project:[]
        },
        msg
      );
      this.props.onHide("addProject");
      this.setState({...initState})
    }
      this.gotoProjectsPage(this.state.projectName)
  };

  toggleDrawer = (open) => (event) => {
    if (!open) {
      this.props.onHide("addProject");
      this.setState({
        ...initState
      })
    }
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      this.setState({
        ...initState
      })
      this.props.onHide("addProject");
      return;
    }
    this.setState({ right: open });
  };

  componentWillUnmount() {
    ProjNametouched = false;
    ProjDesptouched = false;
  }

  render() {
    let ProjNameCharString = ProjNametouched == true ? "Left" : "";
    let ProjDespCharString = ProjDesptouched == true ? "Left" : "";

    return (
      <React.Fragment>
        <DrawerComponent
          heading="Virtual Assistant Details"
          showDrawer={this.toggleDrawer(false)}
          openDrawer={this.props.show}
        >
          <div className="drawer">
            <div className="drawer-body mt-2">
              <form>
                <InputWithLabelComponent
                  placeHolder=""
                  className={"drawer-input"}
                  type="text"
                  name="name"
                  label="Virtual Assistant Name*"
                  value={this.state.projectName}
                  onChange={this.handleInputs("projectName")}
                  isInvalid={this.state.projectValidationMsg ? true : false}
                  error={this.state.projectValidationMsg}
                  maxLength="15"
                />
                <span
                  style={{
                    float: "right",
                      color: "#A49D9D",
                      fontStyle: "Roboto-bold",
                      fontSize: "12px",
                      fontWeight: "700",
                      position:'relative',
                      top:'4px',
                  }}
                >
                  {this.state.projectnameCount} Characters{" "}
                  <span>{ProjNameCharString}</span>
                </span>
                <div className="mt-3">
                  <TextAreaWithLabelComponent
                    label="Description*"
                    name="description"
                    required={false}
                    value={this.state.description}
                    className="text-area"
                    placeholder=""
                    isInvalid={
                      !this.state.validation.description ? true : false
                    }
                    onChange={this.handleInputs("description")}
                    maxLength="220"
                  />
                  <span
                    style={{
                      float: "right",
                      color: "#A49D9D",
                      fontStyle: "Roboto-bold",
                      fontSize: "12px",
                      fontWeight: "700",
                      position:'relative',
                      top:'4px',
                    }}
                  >
                    {this.state.projDescriptionCount} Characters{" "}
                    {ProjDespCharString}
                  </span>
                </div>
                <div className="form-group submit-btn-parent mt-4">
                  <ButtonComponent
                    variant="contained"
                    label="SAVE"
                    clicked={this.handleSubmit}
                    className="buttonWidth"
                    disabled={
                      !(
                        this.state.projectName &&
                        this.state.description &&
                        !this.state.projectValidationMsg
                      )
                    }
                  />
                </div>
              </form>
            </div>
          </div>
        </DrawerComponent>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
   ...state
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    OnCreateAssistant: (apiUrl, data, msg) =>
      dispatch(actions.CreateAssistant(apiUrl,data,msg)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AddVirtualAssistantDrawer, axios));
