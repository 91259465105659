import React, { Component } from 'react';
import { connect } from 'react-redux';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import axios from '../../instance';
import * as actions from '../../store/actions/index'
import { Col, Row } from 'react-bootstrap';
import classes from './ProjectViewPage.module.css';
import DeleteModal from "../../components/UIComponents/DeleteModalComponent/DeleteModal";
import TextField from '@material-ui/core/TextField';
import SearchComponent from "../../components/UIComponents/SearchComponent/Search";
import SpinnerPage from '../../components/UI/Loader/Loader';
import TableMI from '../ProjectViewPage/Table/TableMI';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import EditorCustom from '../../components/UI/Editor/Editor';
import ButtonComponent from "../../components/UIComponents/Button/ButtonComponent";
import './knowledgeTable.css'
import DrawerComponent from '../../components/UIComponents/Drawer/DrawerComponent';
import InputWithLabelComponent from '../../containers/SettingPage/SettingInnerPages/components/InputWithLabelComponent/InputWithLabelComponent';
import TextAreaWithLabelComponent from '../SettingPage/SettingInnerPages/components/TextAreaWithLabelComponent/TextAreaWithLabelComponent'
import SynonymsTable from './Table/SynonymsTable';

class SynonymsDataContainer extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }
    altQues
    altque = 0
    gridAltQue = null
    gridCol4
    state = {
        synonymsDrawer:false,
        rightOpenpopper: false,
        anchorEl: null,
        gridModel1: false,
        gridAltQue: [],
        gridAltQueFlag: false,
        gridAltQueCount: 0,
        IsDeleteAll: false,
        SeletedKnowledgeIntents: [],
        selectedCategory: null,
        optionListValues: [],
        gridCol1: null,
        gridCol2: null,
        gridCol3: null,
        updateKnowledgeData: null,
        rowdata: null,
        KnowledgeTabSelected: "FAQ",
        catSelected: null,
        UpdatedKnowledgeGridData: null,
        deleteknowledgeIntentShow: false,
        ShowDeleteIndividualfromTable: false,
        IsKnowledgeRowSelected: false,
        catListdata: [],
        fileExistFlag: null,
        existingcatSelected: null,
        existingFilesNames: null,
        existingFilesErrorMsg: null,
        KnowledgeFilevalidation: null,
        knowledgeFile: null,
        knowledgeImportFile: null,
        knoledgeRows: [{}],
        knoledgeReLearnRows: [{}],
        knowledgeIntentSearch: "",
        show: true,
        columnDefs: [
            {
                headerName: "Base word",
                field: "description",
                editable: true,
                tooltipField: "description",
                cellEditor: 'agLargeTextCellEditor',
                cellEditorParams: {
                    rows: 4,
                    cols: 35
                }
            },
            {
                headerName: "Synonyms/Acronyms",
                field: "response",
                editable: true,
                tooltipField: "response",
                cellEditor: 'agLargeTextCellEditor',
                cellEditorParams: {
                    rows: 5,
                    cols: 36
                }
            },
        ],
        currentBotSelected: "",
        scriptSelected: false,
        ShowRightPanel: false,
        currentFileSelected: null,
        currentAceValue: "",
        scripttouched: false,
        incomingData: null,
        validation: {
            gridCol1: true,
            gridCol2: true,
        },
        saveSetting: true
    }
    /* FOR DRAWER FUNCTIONNALUTY ONLY*/
    GetKnowledgeIntentData = () => {
        let projectName = localStorage.getItem("projectname");
        let language = localStorage.getItem("language");
        if (!language) {
            language = "en";
        }
        var requestObject = { "projectName": projectName, "language": language };
        this.props.onGetKnowledgeIntent(requestObject, this.props.config.BOTBUILDER_URL);
        this.props.OnGetBotList(this.props.config.BOTBUILDER_URL);
        // this.props.onGetCategoryList(this.props.config.BOTBUILDER_URL);
    }

    handleClick = (data) => (event) => {
        this.setState({
            anchorEl: event.currentTarget,
            incomingData: data
        })
    }
    handleMenuClose = () => {
        this.setState({
            anchorEl: null
        })
    }

    handleClose = (modaltype) => {
        this.state.Cflag = null;
        if (modaltype == "deleteKnowledgeIntent") {
            this.setState({ deleteknowledgeIntentShow: false })
        } if (modaltype == "deleteKnowledgeIntentSingle") {
            this.setState({ ShowDeleteIndividualfromTable: false })
        }
        else if (modaltype == "custommacro") {
            this.setState({ custommacroShow: false })
        }
        else if (modaltype == "displayInfo") {
            this.setState({ flowValue: false })
            this.setState({ displayInfoShow: false })
        }
        else if (modaltype == "displayInfo1") {
            this.setState({ displayInfoShow1: false })
        }
        else if (modaltype == "useContent") {
            this.setState({ displayUseContent: false })
        }
        else if (modaltype == "gridModelClose") {
            this.altque = 0
            this.setState({ gridModel1: false, gridAltQue: [], gridAltQueFlag: false })
        }

        else if (modaltype == "addCategory") {
            this.setState({
                openModal: false,
                dialogValue: {
                    title: '',
                },
                ShowCatDeleteIcons: false
            })
        }
        else if (modaltype == "deleteCategory") {
            this.setState({ deleteCatModal: false, })
        }
        else if (modaltype == "fileUploadAlreadyExit") {
            this.setState({ fileUploadCatModal: false, })
            this.state.catSelected = null
            this.state.existingcatSelected = null
            this.state.files = null
            this.state.fileExistFlag = null
        }
    }

    getCurrentDate = () => {
        var currentdate = new Date();
        var dd = currentdate.getDate()
        var mm = (currentdate.getMonth() + 1)
        var yyyy = currentdate.getFullYear();
        var hh = currentdate.getHours()
        var ms = currentdate.getMinutes()
        var sc = currentdate.getSeconds();

        if (dd < 10)
            dd = '0' + dd;

        if (mm < 10)
            mm = '0' + mm;

        if (hh < 10)
            hh = '0' + hh;

        if (ms < 10)
            ms = '0' + ms;

        if (sc < 10)
            sc = '0' + sc;

        var currentdate = yyyy + '/' + mm + '/' + dd + ' ' + hh + ':' + ms + ':' + sc;
        return currentdate;
    }




    DeleteIntent = (event) => {
        event.preventDefault();
        let projectname = localStorage.getItem("projectname");
        let orgName = localStorage.getItem("orgName");

        let datasourcename = "optimus";
        let optimusCollection = '';
        if (datasourcename == "optimus" && orgName != "default") {
            optimusCollection = datasourcename + "_" + orgName;
        }
        else {
            optimusCollection = datasourcename;
        }

        // Delete all records 
        if (this.state.IsDeleteAll) {
            let data = {
                "project": projectname,
                "language": localStorage.getItem("language"),
                "intentFile": this.state.SeletedKnowledgeIntents,
                "type": "faq",
                "action": "multidelete",
                "collection": optimusCollection,
                "category": this.state.selectedCategory,
                "orgName": localStorage.getItem("orgName")
            }
            this.setState({ SeletedKnowledgeIntents: [] })
            this.setState({ IsDeleteAll: false })
            this.props.deleteSynonyms(this.props.config.BOTBUILDER_URL)
            this.setState({ selectedCategory: this.state.optionListValues[0] })
            setTimeout(() => {
                this.setState({ gridModel1: false })
                this.GetKnowledgeIntentData();
            }, 2000);
        }
        else if (this.state.SeletedKnowledgeIntents.length > 0) {
            let synonym = this.state.incomingData.synonym
            let word = this.state.incomingData.word
            let data = {
                "language": localStorage.getItem("language"),
                "orgName": localStorage.getItem("orgName"),
                "project": projectname,
                "synonym": synonym,
                "word": word
            }
            this.setState({ SeletedKnowledgeIntents: [], ShowDeleteIndividualfromTable: false, })
            this.props.deleteSynonyms(this.props.config.BOTBUILDER_URL,data)
            this.setState({ selectedCategory: this.state.selectedCategory })
            setTimeout(() => {
                this.setState({ gridModel1: false })
                this.GetKnowledgeIntentData();
            }, 2000);
        }
    }

    updateKnowledgeIntent = (event) => {
        event.preventDefault();
        this.setState({
            ...this.state,
            validation: {
                gridCol1: this.state.gridCol1 ? true : false,
                gridCol2: this.state.gridCol2 ? true : false,
            }
        })
        let language = localStorage.getItem("language");
        if (!language) {
            language = "en";
        }
        if (this.state.gridCol1 && this.state.gridCol2) {
            let learn = this.state.gridCol1 + "\n";
            for (let x in this.state.gridAltQue) {
                learn += this.state.gridAltQue[x] + "\n";
            }
            this.altque = 0
            this.setState({ gridAltQueFlag: false })
            this.setState({ gridAltQue: [] })
            this.state.updateKnowledgeData = [{
                "projectName": localStorage.getItem("projectname"), "language": language,
                "data": JSON.stringify({
                    "id": this.state.rowdata.id, "description": this.state.gridCol1, "response": this.state.gridCol2, "training": { "learn": learn, "relearn": this.state.rowdata.relearn },
                    "isfaq": "faq", "intent": this.state.rowdata.intent, "createdate": this.getCurrentDate()
                }),
                "collection": "optimus",
                "project": localStorage.getItem("projectname"),
                "documentId": this.state.rowdata.recordId,
                "orgName": localStorage.getItem("orgName")
            }]
            this.setState({ gridModel1: false })
            this.props.onUpdateAllKnowledgeIntent(this.state.updateKnowledgeData, this.props.config.BOTBUILDER_URL, false);

            setTimeout(() => {
                this.setState({ selectedCategory: this.state.selectedCategory })
                this.GetKnowledgeIntentData();
            }, 2000);
        }
    }

    deletegridAltQues = (index) => {
        const rows = [...this.state.gridAltQue]
        if (this.state.gridAltQueCount <= index) {
            console.log("if")
            rows.splice(index, 1)
            this.altque = 0
            this.state.gridAltQueFlag = false
            this.setState({ gridAltQue: rows })
        }
        else {
            let data = {
                "project": localStorage.getItem("projectname"),
                "id": this.state.rowdata.recordId,
                "altQuestion": rows[index],
                "collectionName": "optimus",
                "language": localStorage.getItem("language")
            }
            this.props.ondeleteAltQuesForFAQ(this.props.config.BOTBUILDER_URL, data);

            rows.splice(index, 1)
            this.altque = 0
            this.state.gridAltQueFlag = false
            this.state.gridAltQue = rows
            this.state.gridAltQueCount = this.state.gridAltQueCount - 1
            console.log("gridAltQue ", this.state.gridAltQue)
            console.log("rows", rows)
            setTimeout(() => {
                this.GetKnowledgeIntentData()
            }, 1000);

        }
        this.setState({ saveSetting: false })
    }

    onChangeCol1 = (e) => {
        this.altque = 0
        this.setState({
            ...this.state,
            gridCol1: e.target.value,
            validation: {
                ...this.state.validation,
                gridCol1: this.state.gridCol1 ? true : false
            },
            saveSetting: false
        })
    }
    onChangeCol2 = (e) => {
        this.altque = 0
        this.setState({
            ...this.state,
            gridCol2: e.target.value,
            validation: {
                ...this.state.validation,
                gridCol2: this.state.gridCol2 ? true : false
            },
            saveSetting: false
        })
    }
    handlegridAltQue = (e) => {
        e.preventDefault()
        this.state.gridAltQue.push("")
        this.altque = 0
        this.setState({ gridAltQue: this.state.gridAltQue })
    }
    onChangeCol3 = (e) => {
        this.altque = 0
        this.gridAltQue = e.target.name
        this.setState({ gridAltQueFlag: true })
        this.gridCol4 = e.target.value
        this.setState({ saveSetting: false })
    }


    ClearKnowledgeIntentState = () => {
        this.setState({ KnowledgeFilevalidation: null });
        this.setState({
            knowledgeFile: null,
            knowledgeImportFile: null,
            knoledgeRows: [{}],
            knoledgeReLearnRows: [{}],
        });
    }

    componentDidMount() {
        this.setState({ ShowDeleteIcons: false, SeletedKnowledgeIntents: null, selectedCategory: 'All Categories' })
        this.GetKnowledgeIntentData();
        this.ClearKnowledgeIntentState();

    }

    componentDidUpdate(prevprops) {

        if (this.state.gridModel1 == false) {
            this.state.gridCol1 = null
            this.state.gridCol2 = null
            this.state.gridCol3 = null
            this.gridCol4 = null
            this.state.gridAltQue = []
            this.state.gridAltQueCount = 0
        }
        if (this.state.gridCol3) {
            this.altque = 0
        }
        if (this.state.KnowledgeTabSelected == "FAQ") {
            this.state.catSelected = null;
        }
        if (this.state.prvProj != localStorage.getItem("projectname")) {
            this.state.selectedCategory = 'All Categories';
            this.state.catSelected = null;
            this.state.prvProj = localStorage.getItem("projectname");
        }
        if (this.props.updateKnowledgeIntentLoading && this.props.updateKnowledgeIntentLoading !== prevprops.updateKnowledgeIntentLoading) {
            this.ClearKnowledgeIntentState();
            this.setState({ knoledgeRows: [{}] });
            this.setState({
                knoledgeReLearnRows: [{}],
                UpdatedKnowledgeGridData: null
            });
        }

        if (this.props.pageload !== prevprops.prevprops && this.props.pageload === true) {
            this.ClearKnowledgeIntentState();
            this.setState({ knoledgeRows: [{}] });
            this.setState({ knoledgeReLearnRows: [{}] });
            this.setState({ KnowledgeTabSelected: "FAQ" });
            this.setState({ SeletedKnowledgeIntents: [] })
            this.GetKnowledgeIntentData();

        }

        if (this.props.importKnowledgeIntent && this.props.importKnowledgeIntent !== prevprops.importKnowledgeIntent) {
            this.ClearKnowledgeIntentState();
            this.setState({ SeletedKnowledgeIntents: [] })
            this.GetKnowledgeIntentData();

        }
        if (this.props.knowledgePattern && this.props.knowledgePattern !== prevprops.knowledgePattern) {
            this.ClearKnowledgeIntentState();
            setTimeout(() => {
                this.setState({ SeletedKnowledgeIntents: [] })
                this.GetKnowledgeIntentData();
            }, 2000);


        }

        if (this.state.selectedCategory != null && this.props.delknowledgeIntentInprogress !== prevprops.delknowledgeIntentInprogress && prevprops.delknowledgeIntentInprogress == true) {
            this.handleClose('deleteKnowledgeIntent');
            this.ClearKnowledgeIntentState();
            setTimeout(() => {
                this.setState({ IsKnowledgeRowSelected: false });
                this.GetKnowledgeIntentData();
            }, 1000);

        }

        if (this.props.uploadloading !== prevprops.uploadloading && !this.props.uploadloading) {
            if (this.props.knowledgeUploadfailure == '') {
                this.setState({ showFiles: true, catSelected: null });
            }
            else if (this.props.knowledgeUpload == '') {
                this.setState({ showFiles: false, failure: true, catSelected: this.state.catSelected });
            }
        }
        if (this.props.catList && this.props.catList !== prevprops.catList) {
            let categorySearchResultArray = []
            let options = []
            options.push("All Categories")
            for (var { category: c } of this.props.catList) {
                var elem = new Object();
                elem['title'] = c;
                categorySearchResultArray.push(elem);
                options.push(c)
            }
            this.setState({
                catListdata: categorySearchResultArray,
                optionListValues: options
            });

        }
        if (this.state.fileExistFlag && this.props.knowledgeUpload && typeof this.props.knowledgeUpload.data !== 'undefined' && this.props.knowledgeUpload.data.includes('Do you wish to override ?')) {
            if (this.state.catSelected) {
                this.state.existingcatSelected = this.state.catSelected
                this.state.existingFilesNames = this.props.knowledgeUpload.existingFiles
                this.state.existingFilesErrorMsg = this.props.knowledgeUpload.data
                this.state.fileUploadCatModal = true
            }
        }
    }

    /* FOR DRAWER FUNCTIONNALUTY ONLY*/
    inputCategoryHandler = (event, catName) => {
        this.setState({
            selectedCategory: catName
        })
        this.setState({ knowledgeIntentSearch: '' });
        if (catName == "All Categories") {
            let projectName = localStorage.getItem("projectname");
            let language = localStorage.getItem("language");
            if (!language) {
                language = "en";
            }
            var requestObject = { "projectName": projectName, "language": language };
            this.props.onGetKnowledgeIntent(requestObject, this.props.config.BOTBUILDER_URL);
        }
        else if (catName != null && catName != "") {
            let projectName = localStorage.getItem("projectname");
            let orgName = localStorage.getItem('orgName');
            let language = localStorage.getItem("language");
            if (!language) {
                language = "en";
            }
            var requestObject = { "projectName": projectName, "language": language };
            this.props.onGetKnowledgeIntent(requestObject, this.props.config.BOTBUILDER_URL);
        } else {
            let projectName = localStorage.getItem("projectname");
            let language = localStorage.getItem("language");
            if (!language) {
                language = "en";
            }
            var requestObject = { "projectName": projectName, "language": language };
            this.props.onGetKnowledgeIntent(requestObject, this.props.config.BOTBUILDER_URL);
        }
    }

    onQuickFilterChanged = (event) => {
        this.setState({ knowledgeIntentSearch: event.target.value });

        if (event.target.value != "") {
            this.setState({ show: false })

        }
        else {
            this.setState({ show: true })
        }
    };

    ExportIntent = () => {
        let projectname = localStorage.getItem("projectname");
        let orgName = localStorage.getItem('orgName');
        this.props.onExportKnowledgeIntent(this.props.config.BOTBUILDER_URL, projectname, 'en', orgName)
        this.setState({ anchorEl: null, rightOpenpopper: false, })
    }

    ShowDeleteIntentModal = () => {
        this.setState({ gridCol1: null, gridCol2: null, gridCol3: null, gridAltQue: [] })
        this.gridCol4 = null
        this.setState({ IsDeleteAll: true })
        let data = this.props.knowledgeIntents;
        this.setState({ SeletedKnowledgeIntents: data.map(i => i._source && i._source.intent + '.txt'), deleteknowledgeIntentShow: true })
        this.setState({ anchorEl: null, rightOpenpopper: false, })
    }

    ShowDeleteIndividualItem = (data11) => {
        this.state.rowdata = data11;
        this.setState({
            ShowDeleteIndividualfromTable: true,
            SeletedKnowledgeIntents: [this.state.rowdata.intent + '.txt'],
            IsDeleteAll: false,
        });
        this.setState({ anchorEl: null })
    }
    onGridRowClicked = (data) => {
        this.setState({ saveSetting: true });
        this.state.rowdata = data;
        if (this.state.rowdata && this.state.rowdata != undefined) {
            this.altque = 0
            this.setState({
                gridModel1: true,
                gridCol1: this.state.rowdata.word,
                gridCol2: this.state.rowdata.synonym,
                // gridCol3: this.state.rowdata.learn,
                SeletedKnowledgeIntents: [this.state.rowdata.intent + '.txt']
            })

            this.setState({ IsKnowledgeRowSelected: true });

            this.setState({ gridAltQue: [] })
            // this.state.gridAltQue = this.state.rowdata.learn.split(/\r?\n/)
            this.state.gridAltQue.pop()
            // this.state.gridAltQueCount = this.state.gridAltQue.length
            this.setState({ gridAltQue: this.state.gridAltQue })
            this.setState({ anchorEl: null })
        }
    }

    setSelected = (tab) => {
        this.setState({ KnowledgeTabSelected: tab });
        if (tab === "FAQ") {
            this.setState({ ShowDeleteIcons: false, SeletedKnowledgeIntents: null, selectedCategory: this.state.optionListValues[0] })
            this.state.selectedCategory = this.state.optionListValues[0]
            this.GetKnowledgeIntentData();
        } else if (tab == "Script") {
            this.setState({ currentBotSelected: "faq" });
            this.setState({ "scriptSelected": true, "ShowRightPanel": false, "currentFileSelected": null })
            this.props.openJSFile("bot.js", true)
            this.setState({ currentAceValue: "", scripttouched: false })
        }
    }

    onChangeAce(newValue) {
        this.setState({ currentAceValue: newValue, scripttouched: true })
    }


    saveBotProfile = () => {
        let projectname = localStorage.getItem("projectname");
        if (!projectname)
            projectname = "common";
        let data = {
            "path": this.state.currentBotSelected + '//' + 'bot.js',
            "data": this.botEditor.editor.getValue(),
            "project": projectname,
            "language": "en",
            "botName": this.state.currentBotSelected
        }
        this.props.onsaveBotProfile(data, this.props.config.BOTBUILDER_URL)
    }

    handleClickSetting = () => {
        this.setState({
            rightOpenpopper: true,
        })
    }
    handleSettingsMenuClose = () => {
        this.setState({
            rightOpenpopper: false,
        })
    }
    ClearKnowledgeIntentSearch = () => {
        this.setState({ knowledgeIntentSearch: "", show: true });
    }

    render() {
        let loadingImportKnowledgeIntent = null;
        if (this.props.knowledgeImportloading) {
            loadingImportKnowledgeIntent = (<SpinnerPage />);
        }
        let loadingGetKnowledgeIntent = null;
        if (this.props.GetKnowledgeloading) {
            loadingGetKnowledgeIntent = (<SpinnerPage />);
        } else {
            loadingGetKnowledgeIntent = null;
        }

        let loadingUpdateIntentImg = null;
        if (this.props.updateKnowledgeIntentLoading) {
            loadingUpdateIntentImg = (<SpinnerPage />)
        } else {
            loadingUpdateIntentImg = null;
        }



        let smartFaqSearchResultArray = this.props.synonyms ? this.props.synonyms : [];



        let filteredSearchResult = smartFaqSearchResultArray && smartFaqSearchResultArray.filter((item) => {
            return item.word.toLowerCase().includes(this.state.knowledgeIntentSearch.toLowerCase()) ||
                item.synonym.toLowerCase().includes(this.state.knowledgeIntentSearch.toLowerCase())
        })

        let knowledgeTabContent = null;
        knowledgeTabContent = <>
            {this.state.KnowledgeTabSelected === 'FAQ' &&
                <Row className="no-gutters ">
                    <Col md={12} className={classes.KnowledgeDropdownDiv1}>
                        <Col md={3}>
                            <h3 className='otherDocMedia' style={{ paddingLeft: "0px" }}>Synonyms & Acronyms</h3>
                        </Col>
                        <Col md={3}>
                            <div className=''>
                                <SearchComponent
                                    class={"input-group searchClass"}
                                    Clear={this.ClearKnowledgeIntentSearch}
                                    show={this.state.show}
                                    value={this.state.knowledgeIntentSearch}
                                    onSearch={(event) => { this.onQuickFilterChanged(event) }} />

                            </div>
                        </Col>
                    </Col>
                    {smartFaqSearchResultArray.length > 0 && <>
                        <Col md={12} className={classes.categoryTitleFaq}>
                            <h4><span style={{ color: '#2A93DF' }}>{filteredSearchResult.length} {filteredSearchResult.length == 1 ? "Synonym & Acronym is" : "Synonyms & Acronyms are"} </span> available.
                            </h4>
                        </Col>
                    </>}
                    <Col className={classes.knowledgeContentLeftSection} md={12}>
                        <Row className="no-gutters">
                            {smartFaqSearchResultArray && smartFaqSearchResultArray.length > 0 ? '' : <>{!this.props.GetKnowledgeloading && <div className='w-100 no-data-container'>
                                <div className="d-flex justify-content-center">
                                    <div className={'no-data-text'}>Synonyms are not added </div>
                                </div>
                                {(this.props.AIStandard.RW || this.props.csUpload) && <div className="d-flex justify-content-center">
                                    <div className={'no-data-add-faq'} onClick={() => this.props.openSynonymsDrawer()}>Add Synonym/Acronym </div>
                                </div>}

                            </div>}</>}
                            <div >
                                {loadingImportKnowledgeIntent}
                            </div>
                            <div className="faq-wrapper">
                                <div id="myGrid" className="ag-theme-alpine pt-1" >
                                    <div className={classes.KnowledgeGridLoader} >
                                        {loadingGetKnowledgeIntent}
                                        {loadingUpdateIntentImg}
                                    </div>
                                    <SynonymsTable
                                        columnDefs={this.state.columnDefs}
                                        onGridReady={this.onGridReady}
                                        smartFaqSearchResultArray={this.state.knowledgeIntentSearch.length > 0 ? smartFaqSearchResultArray.filter((item) => {
                                            return item.word.toLowerCase().includes(this.state.knowledgeIntentSearch.toLowerCase()) ||
                                                item.synonym.toLowerCase().includes(this.state.knowledgeIntentSearch.toLowerCase())
                                        }) : smartFaqSearchResultArray}
                                        onGridRowClicked={(data) => this.onGridRowClicked(data)}
                                        deleteData={(data) => this.ShowDeleteIndividualItem(data)}
                                        handleClick={(event) => this.handleClick(event)}
                                        incomingData={this.state.incomingData}
                                        handleMenuClose={this.handleMenuClose}
                                        editData={(data) => this.onGridRowClicked(data)}
                                        anchorElement={this.state.anchorEl}
                                        menuID="faqEditDropdown"
                                    />
                                </div>
                            </div>
                        </Row>
                    </Col>
                </Row>}
        </>
        return (
            <>
                <Col>
                    <div className={[classes.topSpace, classes.ProjectTabContentKnowledge].join(' ')}>
                        <div className={[classes.topSpace, classes.ProjectTabContentKnowledge].join(' ')}>
                            {knowledgeTabContent}
                        </div>
                    </div>
                </Col>

                <div>
                    {['right'].map((anchor) => (
                        <React.Fragment key={anchor}>
                            {this.state.synonymsDrawer == true ? 
                            <DrawerComponent heading="SYNONYMS Details"
                                showDrawer={() => this.handleClose('gridModelClose')}
                                openDrawer={this.state.gridModel1}>
                                <div className='drawer'>
                                    <div className='drawer-body mt-2'>
                                        <form>
                                            <InputWithLabelComponent
                                                placeHolder=''
                                                className={'drawer-input'}
                                                type="text"
                                                name="name"
                                                label="Question*"
                                                value={this.state.gridCol1}
                                                onChange={this.onChangeCol1}
                                                required={true}
                                                isInvalid={this.state.gridCol1 == '' ? true : false}
                                                error={"Question is required"}
                                            />
                                            <div className='mt-3'>
                                                <TextAreaWithLabelComponent
                                                    placeHolder=''
                                                    className="textArea"
                                                    name="name"
                                                    label="Answer*"
                                                    value={this.state.gridCol2}
                                                    onChange={this.onChangeCol2}
                                                    required={true}
                                                    isInvalid={this.state.gridCol2 == '' ? true : false}
                                                    error={"Answer is required"}
                                                />
                                            </div>
                                            <div className='mt-3'>
                                                <label className='alterQuesHeading' style={{ color: " #000000 " }}>Alternate Questions</label>
                                                <img className='alterQuesFAQ' src="./Icons/plus-icon.svg" onClick={(event) => this.handlegridAltQue(event)} />
                                            </div>
                                            <div className='mt-3'>
                                                {(this.state.gridAltQue.length > 0) ?
                                                    this.altQues = this.state.gridAltQue.map((que, id) => {
                                                        if (this.state.gridAltQue.length >= this.altque) {
                                                            this.altque++
                                                            if (!this.state.gridAltQueFlag) {
                                                                this.gridCol4 = que
                                                            }
                                                            else {
                                                                if (this.gridAltQue == this.altque) {
                                                                    this.altque--
                                                                    this.state.gridAltQue[this.altque] = this.gridCol4
                                                                    this.altque++
                                                                }
                                                            }
                                                            return (
                                                                <div className='AlterQuestions mt-2'>
                                                                    <label>Question {this.altque}</label><br></br>
                                                                    <div>
                                                                        <TextField type="text" name={this.altque} value={(this.state.gridAltQueFlag == 0 || this.gridAltQue == this.altque) ? this.gridCol4 : que} fullWidth="true" variant="outlined" maxRows={4}
                                                                            onChange={this.onChangeCol3} multiline="true" size="small" color="primary" >
                                                                        </TextField>
                                                                        <img src='./Icons/DeleteBlack.svg' onClick={() => this.deletegridAltQues(id)} ></img>

                                                                    </div>
                                                                </div>
                                                            )

                                                        }

                                                    }) : <p>There are no Alternate Questions.</p>
                                                }
                                            </div>
                                            <div className="form-group submit-btn-parent mt-3">
                                                <ButtonComponent
                                                    clicked={(e) => this.updateKnowledgeIntent(e)}
                                                    disabled={this.state.gridCol1 == '' || this.state.gridCol2 == '' || this.state.saveSetting == true}
                                                    label={"SAVE"}
                                                    variant="contained"
                                                    className="buttonWidth"
                                                />

                                            </div>
                                        </form>
                                    </div>
                                </div>      </DrawerComponent> : null}

                        </React.Fragment>
                    ))}
                </div>

                <div>

                    <Menu
                        id="categoriesMenu"
                        anchorEl={this.myRef.current}
                        keepMounted
                        open={this.state.rightOpenpopper}
                        placement='right'
                        onClose={this.handleSettingsMenuClose}
                        getContentAnchorEl={null}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                    >

                        <MenuItem className={this.props.AIStandard.RW ? 'menu-item' : `menu-item click-access`} onClick={() => this.ShowDeleteIntentModal()}>DELETE</MenuItem>
                        <MenuItem className={this.props.AIStandard.RW ? 'menu-item' : `menu-item click-access`}
                            onClick={() => this.ExportIntent()}>EXPORT</MenuItem>
                    </Menu>




                </div>
                <DeleteModal
                    show={this.state.deleteknowledgeIntentShow}
                    deleteModal={() => this.handleClose("deleteKnowledgeIntent")}
                    title="Delete Confirmation"
                    bodyTitle="Are you sure you want to delete the Synonyms and Acronyms?"
                    buttonClick={(e) => this.DeleteIntent(e)}
                />

                <DeleteModal
                    show={this.state.ShowDeleteIndividualfromTable}
                    deleteModal={() => this.handleClose("deleteKnowledgeIntentSingle")}
                    title="Delete Confirmation"
                    bodyTitle="Are you sure you want to delete the Synonym and Acronym?"
                    buttonClick={(e) => this.DeleteIntent(e)}
                />
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        synonyms: state.projectview.synonyms,
        AIStandard: state.user.moduleAccess.conversationalStandard,
        csUpload: state.user.moduleAccess.cognitiveOtherDocsUpload.WR,
        updateKnowledgeIntentLoading: state.projectview.updateKnowledgeIntentLoading,
        importKnowledgeIntent: state.projectview.importKnowledgeIntent,
        knowledgePattern: state.projectview.knowledgePattern,
        delknowledgeIntentInprogress: state.projectview.delknowledgeIntentInprogress,
        uploadloading: state.projectview.uploadloading,
        knowledgeUploadfailure: state.projectview.knowledgeUploadfailure,
        knowledgeUpload: state.projectview.knowledgeUpload,
        catList: state.projectview.catList,
        knowledgeIntents: state.projectview.knowledgeIntents,
        GetKnowledgeloading: state.projectview.GetKnowledgeloading,
        permissions: state.user.permissionList,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        deleteSynonyms: (APIURL,data) => dispatch(actions.deleteSynonyms(APIURL,data)),
        onGetKnowledgeIntent: (reqData, APIURL) => dispatch(actions.getSynonymsData(reqData, APIURL)),
        OnGetBotList: (APIURL) => dispatch(actions.GetBotList(APIURL)),
        onGetCategoryList: (APIURL) => dispatch(actions.getAllCategory(APIURL)),
        onUpdateAllKnowledgeIntent: (bulkUpdateArr, APIURL) => dispatch(actions.UpdateAllKnowledgeIntent(bulkUpdateArr, APIURL)),
        ondeleteAltQuesForFAQ: (APIURL, data) => dispatch(actions.deleteAltQuesForFAQ(APIURL, data)),
        onExportKnowledgeIntent: (APIURL, projectName, language, orgName) => dispatch(actions.ExportKnowledgeIntent(APIURL, projectName, language, orgName)),
        onsaveBotProfile: (profile, APIURL) => dispatch(actions.saveBotProfile(profile, APIURL)),
    }

};

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(SynonymsDataContainer, axios));
