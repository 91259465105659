const getInsightsTabItems = (similarIssuesData, clusterData, recommendedSolutionData,tabsStatus) => {
    let data = []
    if(tabsStatus["topresolutions"])
    data.push({
        label: `RECOMMENDED SOLUTIONS (${recommendedSolutionData.length})`,
        value: 'recommendedSolutions'
    })
    if(tabsStatus["similartickets"])
    data.push({
        label: `SIMILAR ISSUES (${similarIssuesData.length})`,
        value: 'similarIssues'
    })
    if(tabsStatus["issueclassification"])
    data.push({
        label: `ISSUE CLUSTERS (${clusterData.length})`,
        value: 'issueClusters'
    })
    if(tabsStatus["knowledgeArticles"])
    data.push({
        label: 'KNOWLEDGE ARTICLES',
        value: 'knowledgeArticles'
    })
    return data;
};

export default getInsightsTabItems;