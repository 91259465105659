// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input[type="color"] {
	opacity: 0;
	display: block;
	width: 32px;
	height: 32px;
	border: none;
    cursor: pointer;
}
.color-wrapper{
    margin-top: 2px;
    margin-top: 0.25rem;
    font-family: 'Roboto-Regular';
    font-size: 14px;
    color: #696868;
    resize: none;
    height: 40.56px;
    width: 40px;
    border-radius: 0.25rem;
    border: 1px solid #484545;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.labelContentData{
    position: relative;
    top: -24px;
    left: 8px;
    cursor: pointer;
    color: #FFFFFF;
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
}
.color-text-input{
    border-left: 0;
}
.VirtualAssistantContainer .form-control.color-text-input:disabled, .VirtualAssistantContainer .form-control.color-text-input[readonly] {
    background-color: #fff!important;
    cursor: default;
}
.VirtualAssistantContainer .form-control.color-text-input:focus {
    border: 1px;
    border: 1px solid #000;
    border-left: 0;
}`, "",{"version":3,"sources":["webpack://./src/containers/SettingPage/SettingInnerPages/components/ColorPicker/color-picker.css"],"names":[],"mappings":"AAAA;CACC,UAAU;CACV,cAAc;CACd,WAAW;CACX,YAAY;CACZ,YAAY;IACT,eAAe;AACnB;AACA;IACI,eAAe;IACf,mBAAmB;IACnB,6BAA6B;IAC7B,eAAe;IACf,cAAc;IACd,YAAY;IACZ,eAAe;IACf,WAAW;IACX,sBAAsB;IACtB,yBAAyB;IACzB,0BAA0B;IAC1B,6BAA6B;AACjC;AACA;IACI,kBAAkB;IAClB,UAAU;IACV,SAAS;IACT,eAAe;IACf,cAAc;IACd,qBAAqB;IACrB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;AACnB;AACA;IACI,cAAc;AAClB;AACA;IACI,gCAAgC;IAChC,eAAe;AACnB;AACA;IACI,WAAW;IACX,sBAAsB;IACtB,cAAc;AAClB","sourcesContent":["input[type=\"color\"] {\n\topacity: 0;\n\tdisplay: block;\n\twidth: 32px;\n\theight: 32px;\n\tborder: none;\n    cursor: pointer;\n}\n.color-wrapper{\n    margin-top: 2px;\n    margin-top: 0.25rem;\n    font-family: 'Roboto-Regular';\n    font-size: 14px;\n    color: #696868;\n    resize: none;\n    height: 40.56px;\n    width: 40px;\n    border-radius: 0.25rem;\n    border: 1px solid #484545;\n    border-top-right-radius: 0;\n    border-bottom-right-radius: 0;\n}\n.labelContentData{\n    position: relative;\n    top: -24px;\n    left: 8px;\n    cursor: pointer;\n    color: #FFFFFF;\n    font-family: 'Gotham';\n    font-style: normal;\n    font-weight: 400;\n    font-size: 14px;\n}\n.color-text-input{\n    border-left: 0;\n}\n.VirtualAssistantContainer .form-control.color-text-input:disabled, .VirtualAssistantContainer .form-control.color-text-input[readonly] {\n    background-color: #fff!important;\n    cursor: default;\n}\n.VirtualAssistantContainer .form-control.color-text-input:focus {\n    border: 1px;\n    border: 1px solid #000;\n    border-left: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
