import fetchClustersDataAction from "./fetchClustersData/fetchClustersDataAction";
import fetchProductTriageInsightsAction from "./fetchProductTriageInsights/fetchProductTriageInsightsAction";
import fetchRecommendedSolutionDataAction from "./fetchRecommendedSolutionData/fetchRecommendedSolutionDataAction";

const customerResponseHandler = (payload) => (dispatch) => {
   

    // if(selectedTab === 'similarIssues') {
    //     dispatch(fetchProductTriageInsightsAction(payload))
    // }
    // if(selectedTab === 'issueClusters') {
    //     dispatch(fetchClustersDataAction(payload.url))
    // }
    // if(selectedTab === 'recommendedSolutions') {
    //     dispatch(fetchRecommendedSolutionDataAction(payload.url))
    // }
    dispatch(fetchRecommendedSolutionDataAction(payload.url))
    dispatch(fetchProductTriageInsightsAction(payload))
    dispatch(fetchClustersDataAction(payload.url))
};

export default customerResponseHandler;