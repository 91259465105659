import * as React from 'react';
import './Search.css';
import ClearIcon from '@material-ui/icons/Clear';


 const SearchComponent = (props) => {
    return(
      <div className={props.class}>  
                 <input className="form-control border-right-0" type="text" placeholder="Search" 
                 id="example-search-input" onChange={props.onSearch} value={props.value} onKeyPress={props.onKeyPress}/>
                <span className="input-group-append">
                <div className="input-group-text bg-white">
                    {props.show ?   <img src="./Icons/Search.svg"/>:
                         <ClearIcon onClick={props.Clear} style={{cursor:'pointer'}}/>}
                  </div>
                 </span>
            
        </div>
   )
}

export default SearchComponent;
