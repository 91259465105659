import permissions from '../../permissions_Json';
import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../utility';

let moduleAccess = {
    conversationalAdmin: {
        R: false,
        RW: false
    },
    conversationalDashboard: false,
    cognitiveDashboard: false,
    productTriageDashboard: false,
    ptModelTraining:false,
    conversationalStandard: {
        R: false,
        RW: false
    },
    cognitiveOtherDocsUpload: {
        R: false,
        WR: false
    },
    cognitiveSearchAdmin: {
        R: false,
        RW: false
    },
    projectManagement: {
        RW: false
    }
}



const initialState = {
    defaultlanginfo: {
        langname: "English",
        langkey: 'en'
    },
    moduleAccess,
    projects: { bots: [] },
    skillList: [],
    createskillstart: false,
    roleList: [],
    addrolestart: false,
    createProjectFlag: null,
    createProjectFail: false,
    uploadProjectFlag: null,
    orgList: [],
    createUserStart: false,
    permissionList: [],
    permissionError: null,
    langLoading: false,
};

const userStart = (state) => {
    return updateObject(state, { error: null, loading: true });
};

const getUserByID = (state) => {
    return updateObject(state, { error: null });
};

const getUserByIDFailure = (state, action) => {
    return updateObject(state, {
        error: action.error
    });
};

const getUserByIDSuccess = (state, action) => {
    return updateObject(state, {
        user: action.user
    });
};

const getUserProjectsSuccess = (state, action) => {
    return updateObject(state, {
        projects: action.projects
    });
};

const getUserSkillFailure = (state, action) => {
    return updateObject(state, {
        error: action.error
    });
};


const getUserSkillSuccess = (state, action) => {
    return updateObject(state, {
        skillList: action.skillList
    });
};

const deleteUserSkillStart = (state) => {
    return updateObject(state, { error: null, delSkillstart: true });
};

const deleteUserSkillSuccess = (state, action) => {
    var filtered = state.skillList.filter(function (value) {
        return value.skillsetname !== action.deleteSkill;
    });
    return updateObject(state, {
        skillList: filtered,
        error: null,
        delSkillstart: false,
    });
};

const deleteUserSkillFailure = (state, action) => {

    return updateObject(state, {
        error: action.error,
        delSkillstart: false
    });
};

const createUserSkillStart = (state) => {
    return updateObject(state, { error: null, createskillstart: true });
};

const createUserSkillFailure = (state, action) => {
    return updateObject(state, {
        error: action.error,
        createskillstart: false
    });
};

const createUserSkillSuccess = (state, action) => {
    return updateObject(state, {
        skillList: state.skillList.concat(action.newskill),
        error: null,
        createskillstart: false,
    });
};



const getUsersFailure = (state, action) => {
    return updateObject(state, {
        error: action.error
    });
};

const getUsersSuccess = (state, action) => {
    return updateObject(state, {
        users: action.users
    });
};

const updateUserStart = (state) => {
    return updateObject(state, {
        error: null,
        updateuserstart: true,
    });
};

const updateUserFailure = (state, action) => {
    return updateObject(state, {
        error: action.error,
        updateuserstart: false,
    });
};

const updateUserSuccess = (state, action) => {
    return updateObject(state, {
        updateUser: action.updateUser,
        updateuserstart: false,
    });
};

const deleteUserFailure = (state, action) => {
    return updateObject(state, {
        error: action.error
    });
};

const deleteUserSuccess = (state, action) => {
    return updateObject(state, {
        deleteUser: action.deleteUser
    });
};

const getUserRoleFailure = (state, action) => {
    return updateObject(state, {
        error: action.error
    });
};

const getUserRoleSuccess = (state, action) => {
    return updateObject(state, {
        roleList: action.roleList
    });
};

//user management - added by Aiswarya
const getUserPermissionSuccess = (state, action) => {
    action.permissionList.forEach(data => {
        if (data.permission_code.includes(permissions.permissionCode.CONVERSATIONAL_AI_STANDARD_R)) {
            moduleAccess.conversationalStandard.R = true
        }
        if (data.permission_code.includes(permissions.permissionCode.CONVERSATIONAL_AI_STANDARD_RW)) {
            moduleAccess.conversationalStandard.RW = true
        }
        if (data.permission_code.includes(permissions.permissionCode.CONVERSATIONAL_AI_ADMIN_R)) {
            moduleAccess.conversationalAdmin.R = true
        }
        if (data.permission_code.includes(permissions.permissionCode.CONVERSATIONAL_AI_ADMIN_RW)) {
            moduleAccess.conversationalAdmin.RW = true
        }
        if (data.permission_code.includes(permissions.permissionCode.COGNITIVE_SEARCH_KU_R)) {
            moduleAccess.cognitiveOtherDocsUpload.R = true
        }
        if (data.permission_code.includes(permissions.permissionCode.COGNITIVE_SEARCH_KU_RW)) {
            moduleAccess.cognitiveOtherDocsUpload.WR = true
        }
        if (data.permission_code.includes(permissions.permissionCode.COGNITIVE_SEARCH_ADMIN_RW)) {
            moduleAccess.cognitiveSearchAdmin.RW = true
        }
        if (data.permission_code.includes(permissions.permissionCode.PROJECTM_RW)) {
            moduleAccess.projectManagement.RW = true
        }
        if (data.permission_code.includes(permissions.permissionCode.CONVERSATIONAL_AI_DASHBOARD_RW)) {
            moduleAccess.conversationalDashboard = true
        }
        if (data.permission_code.includes(permissions.permissionCode.COGNITIVE_SEARCH_DASHBOARD_RW)) {
            moduleAccess.cognitiveDashboard = true
        }
        if (data.permission_code.includes(permissions.permissionCode.PRODUCT_TRIAGE_DASHBOARD_RW)) {
            moduleAccess.productTriageDashboard = true
        }
        if (data.permission_code.includes(permissions.permissionCode.PRODUCT_TRIAGE_TRAIN_RW)) {
            moduleAccess.ptModelTraining = true
        }
        
    })

    return updateObject(state, {
        permissionList: action.permissionList,
        moduleAccess
    })
}

const getUserPermissionFailure = (state, action) => {
    return updateObject(state, {
        permissionError: action.error
    });
};


const getSettingsFailure = (state, action) => {
    return updateObject(state, {
        error: action.error
    });
};

const getSettingsSuccess = (state, action) => {
    return updateObject(state, {
        settings: action.settings,
        orgList: action.orglist
    });
};


const createProjectStart = (state) => {
    return updateObject(state,
        {
            error: null,
            loading: true,
            createProjectFlag: "S",
            createProjectFail: false
        });
};

const createProjectSuccess = (state) => {
    return updateObject(state, {
        loading: false,
        createProjectFlag: 'E',
        createProjectFail: false
    });
};

const createProjectFailure = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
        createProjectFlag: 'E',
        createProjectFail: true
    });
};

const createAssistantStart = (state) => {
    return updateObject(state,
        {
            error: null,
            loading: true,
        });
};

const createAssistantSuccess = (state) => {
    return updateObject(state, {
        loading: false,
    });
};

const createAssistantFailure = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
    });
};

const uploadProjectStart = (state) => {
    return updateObject(state, {
        loading: true,
        uploadProjectFlag: 'S',
        createProjectFail: false

    });
};

const uploadProjectSuccess = (state) => {
    return updateObject(state, {
        loading: false,
        uploadProjectFlag: 'E',
        createProjectFail: false
    });
};

const uploadProjectFailure = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
        uploadProjectFlag: 'E',
        createProjectFail: true
    });
};

const createRoleFailure = (state, action) => {
    return updateObject(state, {
        error: action.error,
        addrolestart: false
    });
};

const createRoleStart = (state) => {
    return updateObject(state, { error: null, addrolestart: true });
};


const createRoleSuccess = (state, action) => {
    return updateObject(state, {
        roleList: state.roleList.concat(action.newrole),
        addrolestart: false,
        error: null,
    });
};

const createOrgStart = (state) => {
    return updateObject(state, { error: null, createorgstart: true });
};

const createOrgFailure = (state, action) => {
    return updateObject(state, {
        error: action.error,
        createorgstart: false
    });
};

const createOrgSuccess = (state, action) => {
    return updateObject(state, {
        orgList: state.orgList.concat(action.neworg),
        error: null,
        createorgstart: false,
    });
};

const getUserOrgFailure = (state, action) => {
    return updateObject(state, {
        error: action.error
    });
};


const getUserOrgSuccess = (state, action) => {
    return updateObject(state, {
        mappedData: action.mappedData
    });
};

const createGroupStart = (state) => {
    return updateObject(state, { error: null, createGrpstart: true });
};
const getMessageSettingStart = (state) => {
    return updateObject(state, { error: null, messagesettingstart: true });
};

const createGroupFailure = (state, action) => {
    return updateObject(state, {
        error: action.error,
        createGrpstart: false
    });
};
const getMessageSettingSuccess = (state, action) => {
    return updateObject(state, {
        messageSetting: action.messageSetting
    });
};

const createGroupSuccess = (state) => {
    return updateObject(state, {
        error: null,
        createGrpstart: false,
    });
};

const getGroupFailure = (state, action) => {
    return updateObject(state, {
        error: action.error
    });
};


const getGroupSuccess = (state, action) => {
    return updateObject(state, {
        groupData: action.groupedData
    });
};

const getSynonmysStart = (state) => {
    return updateObject(state, { error: null, synonmysstart: true });
};

const getSynonmysSuccess = (state, action) => {
    return updateObject(state, {
        synonmyslist: action.synonmyslist
    });
};


const getProjectByLangStart = (state) => {
    return updateObject(state, { error: null, geyprojectbylangstart: true });
};

const getProjectByLangSuccess = (state, action) => {
    return updateObject(state, {
        projectlistbylang: action.projectlistbylang
    });
};

const setDefaultLanguage = (state, action) => {
    return updateObject(state, {
        defaultlanginfo: action.defaultlanginfo
    });
};

const langDeleteStart = (state) => {
    return updateObject(state, { error: null, langdeletestart: true });
};

const langDeleteSuccess = (state) => {
    return updateObject(state, { error: null, langdeletestart: false });
};

const langDeleteFailure = (state) => {
    return updateObject(state, { error: null, langdeletestart: false });
};

const importLangStart = (state) => {
    return updateObject(state, { error: null, importlangstart: true });
};

const importLangSuccess = (state) => {
    return updateObject(state, { error: null, importlangstart: false });
};

const importLangFailure = (state) => {
    return updateObject(state, { error: null, importlangstart: false });
};

const editGrpStart = (state) => {
    return updateObject(state, { error: null, editGrpstart: true });
};

const editGrpFailure = (state, action) => {
    return updateObject(state, {
        error: action.error,
        editGrpstart: false
    });
};

const editGrpSuccess = (state) => {
    return updateObject(state, {
        error: null,
        editGrpstart: false,
    });
};

const createUserStart = (state) => {
    return updateObject(state, { error: null, createUserStart: true });
};

const createUserFailure = (state, action) => {
    return updateObject(state, {
        error: action.error,
        createUserStart: false
    });
};

const createUserSuccess = (state) => {
    return updateObject(state, {
        error: null,
        createUserStart: false,
    });
};

const getPropertyFailure = (state, action) => {
    return updateObject(state, {
        error: action.error,
    });
};

const getPropertySuccess = (state, action) => {
    return updateObject(state, {
        error: null,
        property: action.property
    });
};

const updatePropertyStart = (state) => {
    return updateObject(state, { error: null, updatePropertyStart: true });
};

const updatePropertyFailure = (state, action) => {
    return updateObject(state, {
        error: action.error,
        updatePropertyStart: false
    });
};

const updatePropertySuccess = (state) => {
    return updateObject(state, {
        error: null,
        updatePropertyStart: false,
    });
};

const getBuildPropsFailure = (state, action) => {
    return updateObject(state, {
        error: action.error
    });
};

const getBuildPropsSuccess = (state, action) => {
    return updateObject(state, {
        buildProps: action.buildProps,
    });
};


const getLangaugeFailure = (state, action) => {
    return updateObject(state, {
        error: action.error,
        langLoading: false

    });
};

const getLangaugeSuccess = (state, action) => {
    return updateObject(state, {
        languageList: action.languageList,
        langLoading: false
    });
};

const addLanguageStart = (state) => {
    return updateObject(state, { langLoading: true });
};

const addLanguageSuccess = (state) => {
    return updateObject(state, { langLoading: false });
};

const addLanguageFailureAction = (state) => {
    return updateObject(state, { langLoading: false });
};

const getEnglishProjectsFailure = (state, action) => {
    return updateObject(state, {
        error: action.error
    });
};


const getEnglishProjectsSuccess = (state, action) => {
    return updateObject(state, {
        englishProject: action.englishProject
    });
};

const getProjectLangFailure = (state, action) => {
    return updateObject(state, {
        error: action.error
    });
};


const getProjectLangSuccess = (state, action) => {
    return updateObject(state, {
        projectLang: action.projectLang
    });
};

const UserReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.USER_START: return userStart(state);
        case actionTypes.GET_USER_BY_ID: return getUserByID(state);
        case actionTypes.GET_USER_BY_ID_SUCCESS: return getUserByIDSuccess(state, action);
        case actionTypes.GET_USER_PROJECTS_SUCCESS: return getUserProjectsSuccess(state, action);
        case actionTypes.GET_USER_BY_ID_FAILURE: return getUserByIDFailure(state, action);
        case actionTypes.GET_USER_SKILL_SUCCESS: return getUserSkillSuccess(state, action);
        case actionTypes.GET_USER_SKILL_FAILURE: return getUserSkillFailure(state, action);
        case actionTypes.DELETE_USER_SKILL_START: return deleteUserSkillStart(state);
        case actionTypes.DELETE_USER_SKILL_SUCCESS: return deleteUserSkillSuccess(state, action);
        case actionTypes.DELETE_USER_SKILL_FAILURE: return deleteUserSkillFailure(state, action);
        case actionTypes.CREATE_USER_SKILL_START: return createUserSkillStart(state);
        case actionTypes.CREATE_USER_SKILL_SUCCESS: return createUserSkillSuccess(state, action);
        case actionTypes.CREATE_USER_SKILL_FAILURE: return createUserSkillFailure(state, action);
        case actionTypes.GET_USERS_SUCCESS: return getUsersSuccess(state, action);
        case actionTypes.GET_USERS_FAILURE: return getUsersFailure(state, action);
        case actionTypes.UPDATE_USER_START: return updateUserStart(state);
        case actionTypes.UPDATE_USER_SUCCESS: return updateUserSuccess(state, action);
        case actionTypes.UPDATE_USER_FAILURE: return updateUserFailure(state, action);
        case actionTypes.DELETE_USER_SUCCESS: return deleteUserSuccess(state, action);
        case actionTypes.DELETE_USER_FAILURE: return deleteUserFailure(state, action);
        case actionTypes.GET_USER_ROLE_SUCCESS: return getUserRoleSuccess(state, action);
        case actionTypes.GET_USER_ROLE_FAILURE: return getUserRoleFailure(state, action);
        case actionTypes.GET_SETTINGS_SUCCESS: return getSettingsSuccess(state, action);
        case actionTypes.GET_SETTINGS_FAILURE: return getSettingsFailure(state, action);
        case actionTypes.CREATE_PROJECT_START: return createProjectStart(state);
        case actionTypes.CREATE_PROJECT_SUCCESS: return createProjectSuccess(state);
        case actionTypes.CREATE_PROJECT_FAILURE: return createProjectFailure(state, action);
        case actionTypes.CREATE_ASSISTANT_START: return createAssistantStart(state);
        case actionTypes.CREATE_ASSISTANT_SUCCESS: return createAssistantSuccess(state);
        case actionTypes.CREATE_ASSISTANT_FAILURE: return createAssistantFailure(state, action);
        case actionTypes.UPLOAD_PROJECT_START: return uploadProjectStart(state);
        case actionTypes.UPLOAD_PROJECT_SUCCESS: return uploadProjectSuccess(state);
        case actionTypes.UPLOAD_PROJECT_FAILURE: return uploadProjectFailure(state, action);
        case actionTypes.CREATE_ROLE_START: return createRoleStart(state);
        case actionTypes.CREATE_ROLE_SUCCESS: return createRoleSuccess(state, action);
        case actionTypes.CREATE_ROLE_FAILURE: return createRoleFailure(state, action);
        case actionTypes.CREATE_ORG_START: return createOrgStart(state);
        case actionTypes.CREATE_ORG_SUCCESS: return createOrgSuccess(state, action);
        case actionTypes.CREATE_ORG_FAILURE: return createOrgFailure(state, action);
        case actionTypes.GET_ORG_SUCCESS: return getUserOrgSuccess(state, action);
        case actionTypes.GET_ORG_FAILURE: return getUserOrgFailure(state, action);
        case actionTypes.CREATE_GROUP_START: return createGroupStart(state);
        case actionTypes.CREATE_GROUP_SUCCESS: return createGroupSuccess(state);
        case actionTypes.CREATE_GROUP_FAILURE: return createGroupFailure(state, action);
        case actionTypes.GET_GROUP_SUCCESS: return getGroupSuccess(state, action);
        case actionTypes.GET_GROUP_FAILURE: return getGroupFailure(state, action);
        case actionTypes.GET_MESSAGE_SETTING_START: return getMessageSettingStart(state);
        case actionTypes.GET_MESSAGE_SETTING_SUCCESS: return getMessageSettingSuccess(state, action);
        case actionTypes.GET_SYNONMYS_START: return getSynonmysStart(state);
        case actionTypes.GET_SYNONMYS_SUCCESS: return getSynonmysSuccess(state, action);
        case actionTypes.GET_PROJECT_BY_LANG_START: return getProjectByLangStart(state);
        case actionTypes.GET_PROJECT_BY_LANG_SUCCESS: return getProjectByLangSuccess(state, action);
        case actionTypes.SET_DEFAULT_LANGUAGE: return setDefaultLanguage(state, action);
        case actionTypes.LANG_DELETE_START: return langDeleteStart(state);
        case actionTypes.LANG_DELETE_SUCCESS: return langDeleteSuccess(state);
        case actionTypes.LANG_DELETE_FAILURE: return langDeleteFailure(state);
        case actionTypes.IMPORT_LANG_START: return importLangStart(state);
        case actionTypes.IMPORT_LANG_SUCCESS: return importLangSuccess(state);
        case actionTypes.IMPORT_LANG_FAILURE: return importLangFailure(state);
        case actionTypes.EDIT_GROUP_START: return editGrpStart(state);
        case actionTypes.EDIT_GROUP_SUCCESS: return editGrpSuccess(state);
        case actionTypes.EDIT_GROUP_FAILURE: return editGrpFailure(state, action);
        case actionTypes.CREATE_NEWUSER_START: return createUserStart(state);
        case actionTypes.CREATE_NEWUSER_SUCCESS: return createUserSuccess(state);
        case actionTypes.CREATE_NEWUSER_FAILURE: return createUserFailure(state, action);
        case actionTypes.GET_PROPERTY_SUCCESS: return getPropertySuccess(state, action);
        case actionTypes.GET_PROPERTY_FAILURE: return getPropertyFailure(state, action);
        case actionTypes.UPDATE_PROPERTY_START: return updatePropertyStart(state);
        case actionTypes.UPDATE_PROPERTY_SUCCESS: return updatePropertySuccess(state);
        case actionTypes.UPDATE_PROPERTY_FAILURE: return updatePropertyFailure(state, action);
        case actionTypes.GET_BUILD_SUCCESS: return getBuildPropsSuccess(state, action);
        case actionTypes.GET_BUILD_FAILURE: return getBuildPropsFailure(state, action);
        case actionTypes.GET_USER_PERMISSION_SUCCESS: return getUserPermissionSuccess(state, action);
        case actionTypes.GET_USER_PERMISSION_FAILURE: return getUserPermissionFailure(state, action);
        case actionTypes.GET_LANGUAGE_SUCCESS: return getLangaugeSuccess(state, action);

        case actionTypes.ADD_LANGUAGE_START: return addLanguageStart(state);
        case actionTypes.ADD_LANGUAGE_SUCCESS: return addLanguageSuccess(state);
        case actionTypes.ADD_LANGUAGE_FAILURE: return addLanguageFailureAction(state);

        case actionTypes.GET_LANGUAGE_FAILURE: return getLangaugeFailure(state, action);
        case actionTypes.GET_ENGLISH_PROJECTS_SUCCESS: return getEnglishProjectsSuccess(state, action);
        case actionTypes.GET_ENGLISH_PROJECTS_FAILURE: return getEnglishProjectsFailure(state, action);
        case actionTypes.GET_PROJECT_LANGS_SUCCESS: return getProjectLangSuccess(state, action);
        case actionTypes.GET_PROJECT_LANGS_FAILURE: return getProjectLangFailure(state, action);
        default:
            return state;
    }
};

export default UserReducer;