import React from 'react';
import classes from './Entities.module.css';
import  './Entities.module.css';
import {Col,Row} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'

const entity = (props) => (
    <Row>
        <Col md={12}>
        <Form.Label>Entities</Form.Label>  
        </Col>
        <Col md={12}>
            {props.entityList}
            {props.addEntity}
        </Col>
        <Col md={12} className={classes.topSpace}>
        <div className="inputContainer">
            <Form.Label >Select Validation Type</Form.Label>
            <Form.Control as="select" name="validation" onChange={props.changed} required={true} value={props.validationText === 'custom'?props.validationText:props.validation}>  
                
                <option value="">Select your Option</option>   
               {props.optionValues}
               <option value="custom">Custom</option>
               
            </Form.Control>
        </div>
        </Col>
        {props.validationText === 'custom'? 
        <>
        <Col md={12}>
        <div className="inputContainer">
            <Form.Label>Validation Name*</Form.Label>     
            <Form.Control type="text" placeholder="Enter Reg expression type.." required={true} autoComplete="off"  name="validationText" onChange={props.changed}  value={props.validationName}/>
        </div>
        {props.emptyValidationTypeMsg}
        </Col>
        <Col md={12}>
        <div className="inputContainer">
            <Form.Label>Validation*</Form.Label>     
            <Form.Control type="text" placeholder="Enter Reg expression.." required={true} autoComplete="off"   onChange={props.changed}  name="ValidationValue" value={props.validation}/>
        </div>
        {props.emptyValidationValueMsg}
        </Col></>:<Col md={12}>
        <div className="inputContainer">
            <Form.Label>Validation</Form.Label>     
            <Form.Control type="text" placeholder="Enter Reg expression type.."  name="validationName" onChange={props.changed} readOnly={true} value={props.validation} />
        </div>
        </Col>}
        <Col md={12}>
        <div className="inputContainer">
            <Form.Label>Validation  Message</Form.Label>     
            <Form.Control type="text" name="invalidEntity"  autoComplete="off" required={true} value={props.invalidEntity} onChange={props.changed} placeholder="Enter message to be displayed when entity is invalid.."/>
        </div>
        </Col>
        <Col md={12}>
        <div className="inputContainer">
            <Form.Label>Question*</Form.Label>     
            <Form.Control type="text" name="question" autoComplete="off" required={true} value={props.question} onChange={props.changed} placeholder="Enter Question to display when entity is missing.."/>
        </div>
        {props.emptyQuestionMsg}
        </Col>
        <Col md={12}>
        <div className="inputContainer">
            <Form.Label>Default Value*</Form.Label>     
            <Form.Control type="text"  name="defaultValue"  autoComplete="off" required={true}  value={props.defaultValue} onChange={props.changed}/>
        </div>
        {props.emptyDefaultMsg}
        </Col>
        <Col md={12}>
        <div className="inputContainer">
            <Form.Label>Option Type</Form.Label>
            <Form.Control as="select" name="optionType"  value={props.optionType} onChange={props.changed}>     
                <option value="-1">Select your Option</option>
                <option value="addoption">Add Option</option>
                <option value="confirmation">Confirmation</option>
                <option value="password">Password</option>
                <option value="form">HTML Form</option>
            </Form.Control>
        </div>
        </Col>
        <Col md={12}>
		{props.addOption === true ? 
        	<><div classNameclass="input-group" >
               
             <input type="text" className="form-control" name="addOptionValue" autoComplete="off" onKeyDown={(e)=>{
                 if(e.key==='Enter'){
                     e.preventDefault();
                     props.buttonClicked(e);
                 }
             }} onChange={props.changed} value={props.addOptionValue} placeholder="type here...." ></input>
			 <span className="input-group-btn">
                   <button className="btn blue btn-primary" type="button" onClick={props.buttonClicked}>Add</button>
               </span>
			</div>
           <div className={classes.topSpace}>{props.validateOptionMsg}</div>
           <ul className={classes.optionListing}>
           {props.addOptionList}
            </ul></>:null}
            
		
        </Col>
        <Col md={12}>
        {(props.optionType =="password" || props.optionType =="form")?null:
        <div className="inputContainer">
            <Form.Label>Display Option</Form.Label>     
            <Form.Control as="select" name="display"  value={props.display} onChange={props.changed}>
                <option value="-1">Select Your Options</option>
                <option value="checkbox">Checkbox</option>
                <option value="dropdown">Dropdown</option>
                <option value="list">ListBox</option>
                <option value="radio">Radio Button</option>
                <option value="button">Button</option>
            </Form.Control>
        </div>}
        </Col>
        <Col md={12}>
        <Form.Group>
            <Form.Label>Type of Entity</Form.Label>  
            <Row className={classes.ToggleAlign}>
            <ToggleButtonGroup type="radio" name="entityType" defaultValue={props.entityType}>
                <ToggleButton onChange={props.Entitychanged} className={classes.ToggleBtn} value="false">Required</ToggleButton>
                <ToggleButton onChange={props.Entitychanged} className={classes.ToggleBtn} value="true">Optional</ToggleButton>
            </ToggleButtonGroup>
            </Row>
        </Form.Group>
        </Col>
        <Col md={12}>
            <Form.Label>Remember this Entity</Form.Label> 
            <Row className={classes.ToggleAlign}>    
            <ToggleButtonGroup type="radio" name="remember" defaultValue={props.remember}>
                <ToggleButton onChange={props.Rememberchanged} className={classes.ToggleBtn} value="true">Yes</ToggleButton>
                <ToggleButton onChange={props.Rememberchanged} className={classes.ToggleBtn} value="false">No</ToggleButton>
            </ToggleButtonGroup>
            </Row>
        </Col>
    </Row>
);

export default entity;