import * as React from 'react';
import './metric-card.css'

export default function MetricCard(props) {


  return (
    <div className={`card my-3 ml-3 metric-card ${props.headerIcon&&'clickable-item'}`} style={{ backgroundColor: props.backgroundColor }}>
      <div className="card-header">
        {props.heading}     <img src={props.headerIcon} />
      </div>
      <div className="card-body d-flex justify-content-between align-items-center">
        {props.label2 ? <><div className='left-border count'>
          {props.count}
          <span className='count-label'>{props.label1}</span>
        </div>
          <div className='left2-border count'>
            {props.count2}
            <span className='count-label'>{props.label2}</span>
          </div></> : props.content ? <div className='triage-content'>{props.content}</div> : <div className={`${props.isLeftBorder?'left-border count':'count'}`}>
          {props.count}
        </div>
        }
        <div>
          <img src={props.icon} />
        </div>
      </div>
    </div>
  );
}