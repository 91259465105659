// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Editor_ace-width__gn9H6{
    width:100% !important;
    height: 340px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/Editor/Editor.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,wBAAwB;AAC5B","sourcesContent":[".ace-width{\n    width:100% !important;\n    height: 340px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ace-width": `Editor_ace-width__gn9H6`
};
export default ___CSS_LOADER_EXPORT___;
