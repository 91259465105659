import React, {useEffect, useState}  from 'react';
import './chat-window.css';
import { Row } from 'react-bootstrap';
import TreeCustom from "../../../../../components/UIComponents/TreeComponent/TreeComponent";
import DOMPurify from "dompurify";

const ChatWindow = (props) => {

    let selectedLabel= '';

    const [expanded, setExpanded] = useState([]);
   
    const onMouseEnter = (e) => {
        let { botResponse } = props.canvas;
        e.target.style.background = botResponse.buttonHoverBgColor;
        e.target.style.borderColor = botResponse.buttonHoverBorderColor;
        e.target.style.color = botResponse.buttonHoverTextColor;
    }
    const onMouseLeave = (e) => {
        let { botResponse } = props.canvas;
        e.target.style.background = botResponse.buttonBgColor;
        e.target.style.borderColor = botResponse.buttonBorderColor;
        e.target.style.color = botResponse.buttonTextColor;
    }

    const selectedMenuItem = (item) => {
        this.setState({
            selectedLabel:item
          })
    }

    const onNodeChange = (e, nodes) => {
        // const expandingNodes = nodes.filter(x => !expanded.includes(x));
        setExpanded(nodes);
    //    console.log("expandingNodes",expandingNodes)
    };

    const renderMenuOptions = (menuItem) => {
        let subMenuList = menuItem.subMenu.map((item)=>item.text);
        // const classes = this.useStyles();
        return (
            <div>
                <TreeCustom ariaLabel={menuItem.text} label={menuItem.text}
                    nodeList={subMenuList}
                    onNodeChange={onNodeChange}  expanded={expanded}
                    selectedItem={selectedMenuItem} selectedLabel={selectedLabel}
                    // classExpanded={classes.styleExpanded}
                    // styleItemLabel={{color:menuItem.subMenu[0].textColor}}
                    />
            </div>
        )
    }

    useEffect(()=> {
        return () => {
            document.getElementById('chatImageTag').src = "IntelliCuxBot/windowfiles/chatOpen.png";
            document.getElementById('addChatWindowholder').className ="add-chat-Window-btn";
        }
    },[]);

    function alignMsgBox(e) {
        let bottom = 15;
        if(e.target.height !== 0)
            bottom = e.target.height/2 - 22;
        else 
            document.getElementById('chatImage').setAttribute('style','width:75px !important');

        document.getElementById('engagementMsg').setAttribute('style','bottom:'+ bottom +'px');
        
    }

    const { canvas } = props;
    let imageFile;
    
    if(props.headerBotImg) {
        imageFile = props.headerBotImg;
    } else if (props.header.botImg !== "" && props.header.botImg !== null) {
        imageFile = props.header.botImg;
    }
    let screenWidth = window.screen.availWidth;
    let btmContainerHt = '';
    let back2HomeImg = null;
    let menuImg = null;
    if(props.footer.backToHome && !props.footer.backToHome.isVisible) {
        btmContainerHt = '40px !important'
    } else {
        back2HomeImg = <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.8125 6.5C0.8125 8.00842 1.41172 9.45506 2.47833 10.5217C3.54494 11.5883 4.99158 12.1875 6.5 12.1875C8.00842 12.1875 9.45506 11.5883 10.5217 10.5217C11.5883 9.45506 12.1875 8.00842 12.1875 6.5C12.1875 4.99158 11.5883 3.54494 10.5217 2.47833C9.45506 1.41172 8.00842 0.8125 6.5 0.8125C4.99158 0.8125 3.54494 1.41172 2.47833 2.47833C1.41172 3.54494 0.8125 4.99158 0.8125 6.5ZM13 6.5C13 8.22391 12.3152 9.87721 11.0962 11.0962C9.87721 12.3152 8.22391 13 6.5 13C4.77609 13 3.12279 12.3152 1.90381 11.0962C0.68482 9.87721 0 8.22391 0 6.5C0 4.77609 0.68482 3.12279 1.90381 1.90381C3.12279 0.68482 4.77609 0 6.5 0C8.22391 0 9.87721 0.68482 11.0962 1.90381C12.3152 3.12279 13 4.77609 13 6.5ZM9.34375 6.09375C9.45149 6.09375 9.55483 6.13655 9.63101 6.21274C9.7072 6.28892 9.75 6.39226 9.75 6.5C9.75 6.60774 9.7072 6.71108 9.63101 6.78726C9.55483 6.86345 9.45149 6.90625 9.34375 6.90625H4.63694L6.38138 8.64987C6.41915 8.68765 6.44911 8.73249 6.46955 8.78184C6.48999 8.83119 6.50051 8.88408 6.50051 8.9375C6.50051 8.99092 6.48999 9.04381 6.46955 9.09316C6.44911 9.14251 6.41915 9.18735 6.38138 9.22513C6.3436 9.2629 6.29876 9.29286 6.24941 9.3133C6.20006 9.33374 6.14717 9.34426 6.09375 9.34426C6.04033 9.34426 5.98744 9.33374 5.93809 9.3133C5.88874 9.29286 5.8439 9.2629 5.80612 9.22513L3.36862 6.78763C3.33079 6.74989 3.30078 6.70506 3.2803 6.6557C3.25982 6.60635 3.24927 6.55344 3.24927 6.5C3.24927 6.44656 3.25982 6.39365 3.2803 6.3443C3.30078 6.29494 3.33079 6.25011 3.36862 6.21237L5.80612 3.77487C5.88241 3.69859 5.98587 3.65574 6.09375 3.65574C6.20163 3.65574 6.30509 3.69859 6.38138 3.77487C6.45766 3.85116 6.50051 3.95462 6.50051 4.0625C6.50051 4.17038 6.45766 4.27384 6.38138 4.35013L4.63694 6.09375H9.34375Z" fill={props.footer.backToHome.textColor}/>
    </svg>;
        menuImg = <svg width="23" height="15" viewBox="0 0 23 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.13333 14.1667C6.13333 13.9457 6.21411 13.7337 6.35788 13.5774C6.50166 13.4211 6.69667 13.3333 6.9 13.3333H22.2333C22.4367 13.3333 22.6317 13.4211 22.7754 13.5774C22.9192 13.7337 23 13.9457 23 14.1667C23 14.3877 22.9192 14.5996 22.7754 14.7559C22.6317 14.9122 22.4367 15 22.2333 15H6.9C6.69667 15 6.50166 14.9122 6.35788 14.7559C6.21411 14.5996 6.13333 14.3877 6.13333 14.1667ZM3.06667 7.5C3.06667 7.27899 3.14744 7.06702 3.29122 6.91074C3.435 6.75446 3.63 6.66667 3.83333 6.66667H19.1667C19.37 6.66667 19.565 6.75446 19.7088 6.91074C19.8526 7.06702 19.9333 7.27899 19.9333 7.5C19.9333 7.72101 19.8526 7.93297 19.7088 8.08926C19.565 8.24554 19.37 8.33333 19.1667 8.33333H3.83333C3.63 8.33333 3.435 8.24554 3.29122 8.08926C3.14744 7.93297 3.06667 7.72101 3.06667 7.5ZM0 0.833333C0 0.61232 0.0807735 0.400358 0.224551 0.244078C0.368329 0.0877975 0.563334 0 0.766667 0H16.1C16.3033 0 16.4983 0.0877975 16.6421 0.244078C16.7859 0.400358 16.8667 0.61232 16.8667 0.833333C16.8667 1.05435 16.7859 1.26631 16.6421 1.42259C16.4983 1.57887 16.3033 1.66667 16.1 1.66667H0.766667C0.563334 1.66667 0.368329 1.57887 0.224551 1.42259C0.0807735 1.26631 0 1.05435 0 0.833333Z" fill={props.footer.backToHome.textColor}/>
    </svg>;
    }
    let chatCloseSvg = <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="8.5" cy="8.5" r="8.5" fill={props.header.closeIconBgColor}/>
			<path d="M5.14686 5.14686C5.1933 5.10031 5.24846 5.06337 5.30919 5.03817C5.36992 5.01297 5.43503 5 5.50078 5C5.56654 5 5.63164 5.01297 5.69238 5.03817C5.75311 5.06337 5.80827 5.10031 5.85471 5.14686L8.50013 7.79329L11.1456 5.14686C11.192 5.10038 11.2472 5.06352 11.3079 5.03836C11.3687 5.01321 11.4337 5.00026 11.4995 5.00026C11.5652 5.00026 11.6303 5.01321 11.691 5.03836C11.7517 5.06352 11.8069 5.10038 11.8534 5.14686C11.8999 5.19334 11.9367 5.24852 11.9619 5.30924C11.9871 5.36997 12 5.43505 12 5.50078C12 5.56651 11.9871 5.6316 11.9619 5.69233C11.9367 5.75305 11.8999 5.80823 11.8534 5.85471L9.20698 8.50013L11.8534 11.1456C11.8999 11.192 11.9367 11.2472 11.9619 11.3079C11.9871 11.3687 12 11.4337 12 11.4995C12 11.5652 11.9871 11.6303 11.9619 11.691C11.9367 11.7517 11.8999 11.8069 11.8534 11.8534C11.8069 11.8999 11.7517 11.9367 11.691 11.9619C11.6303 11.9871 11.5652 12 11.4995 12C11.4337 12 11.3687 11.9871 11.3079 11.9619C11.2472 11.9367 11.192 11.8999 11.1456 11.8534L8.50013 9.20698L5.85471 11.8534C5.80823 11.8999 5.75305 11.9367 5.69233 11.9619C5.6316 11.9871 5.56651 12 5.50078 12C5.43505 12 5.36997 11.9871 5.30924 11.9619C5.24852 11.9367 5.19334 11.8999 5.14686 11.8534C5.10038 11.8069 5.06352 11.7517 5.03836 11.691C5.01321 11.6303 5.00026 11.5652 5.00026 11.4995C5.00026 11.4337 5.01321 11.3687 5.03836 11.3079C5.06352 11.2472 5.10038 11.192 5.14686 11.1456L7.79329 8.50013L5.14686 5.85471C5.10031 5.80827 5.06337 5.75311 5.03817 5.69238C5.01297 5.63164 5 5.56654 5 5.50078C5 5.43503 5.01297 5.36992 5.03817 5.30919C5.06337 5.24846 5.10031 5.1933 5.14686 5.14686Z" fill={props.header.closeIconColor}/>
			</svg>;
	let chatMinimizeSvg = <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="8.5" cy="8.5" r="8.5" fill={props.header.minimizeIconBgColor}/>
			<path d="M5 9C5 8.73478 5.05268 8.48043 5.14645 8.29289C5.24021 8.10536 5.36739 8 5.5 8H12.5C12.6326 8 12.7598 8.10536 12.8536 8.29289C12.9473 8.48043 13 8.73478 13 9C13 9.26522 12.9473 9.51957 12.8536 9.70711C12.7598 9.89464 12.6326 10 12.5 10H5.5C5.36739 10 5.24021 9.89464 5.14645 9.70711C5.05268 9.51957 5 9.26522 5 9Z" fill={props.header.minimizeIconColor}/>
			</svg>;
	let chatMaximizeSvg = <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="8.5" cy="8.5" r="8.5" fill={props.header.maximizeIconBgColor}/>
			<path d="M3.81505 4.35953L10.004 4.96139L4.76351 10.5068L3.81505 4.35953Z" fill={props.header.maximizeIconColor}/>
			<path d="M12.827 13.0619L6.66287 12.5057L11.8851 6.94306L12.827 13.0619Z" fill={props.header.maximizeIconColor}/>
			</svg>;
    let sendIconSvg = <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.962 0.72866C17.0006 0.632118 17.0101 0.526358 16.9892 0.424491C16.9683 0.322623 16.9179 0.229129 
        16.8444 0.155598C16.7709 0.0820668 16.6774 0.0317331 16.5755 0.0108367C16.4737 -0.0100597 16.3679 -0.000599873 
        16.2714 0.0380435L0.815706 6.22065H0.814643L0.33441 6.4119C0.243453 6.44818 0.164294 6.5089 0.105678 6.58735C0.0470624 
        6.6658 0.0112704 6.75892 0.00225584 6.85643C-0.00675867 6.95395 0.0113552 7.05205 0.0545965 7.13992C0.0978379 7.22778 0.164524 
        7.30198 0.247288 7.35433L0.682898 7.63057L0.68396 7.6327L5.99096 11.0093L9.36747 16.3164L9.3696 16.3185L9.64584 16.7541C9.69835 
        16.8366 9.77259 16.903 9.86038 16.9459C9.94818 16.9889 10.0461 17.0068 10.1435 16.9977C10.2408 16.9886 10.3337 16.9528 10.412 
        16.8942C10.4903 16.8357 10.5509 16.7567 10.5872 16.666L16.962 0.72866ZM15.0145 2.73676L7.05236 10.699L6.82393 10.3399C6.78208 
        10.274 6.72622 10.2181 6.66032 10.1763L6.3012 9.94786L14.2633 1.98558L15.5149 1.48515L15.0155 2.73676H15.0145Z" fill={props.footer.userInput.sendIconDisabledColor}/>
        </svg>;
    let engagementCloseIcon = <svg width="21" height="21" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8.5" cy="8.5" r="8.5" fill={props.personality.engagement.closeBgColor}/>
        <path d="M5.14686 5.14686C5.1933 5.10031 5.24846 5.06337 5.30919 5.03817C5.36992 5.01297 
        5.43503 5 5.50078 5C5.56654 5 5.63164 5.01297 5.69238 5.03817C5.75311 5.06337 5.80827 5.10031 5.85471 
        5.14686L8.50013 7.79329L11.1456 5.14686C11.192 5.10038 11.2472 5.06352 11.3079 5.03836C11.3687 5.01321 
        11.4337 5.00026 11.4995 5.00026C11.5652 5.00026 11.6303 5.01321 11.691 5.03836C11.7517 5.06352 11.8069 
        5.10038 11.8534 5.14686C11.8999 5.19334 11.9367 5.24852 11.9619 5.30924C11.9871 5.36997 12 5.43505 12 
        5.50078C12 5.56651 11.9871 5.6316 11.9619 5.69233C11.9367 5.75305 11.8999 5.80823 11.8534 5.85471L9.20698 
        8.50013L11.8534 11.1456C11.8999 11.192 11.9367 11.2472 11.9619 11.3079C11.9871 11.3687 12 11.4337 12 
        11.4995C12 11.5652 11.9871 11.6303 11.9619 11.691C11.9367 11.7517 11.8999 11.8069 11.8534 11.8534C11.8069 
        11.8999 11.7517 11.9367 11.691 11.9619C11.6303 11.9871 11.5652 12 11.4995 12C11.4337 12 11.3687 11.9871 
        11.3079 11.9619C11.2472 11.9367 11.192 11.8999 11.1456 11.8534L8.50013 9.20698L5.85471 11.8534C5.80823 
        11.8999 5.75305 11.9367 5.69233 11.9619C5.6316 11.9871 5.56651 12 5.50078 12C5.43505 12 5.36997 11.9871 
        5.30924 11.9619C5.24852 11.9367 5.19334 11.8999 5.14686 11.8534C5.10038 11.8069 5.06352 11.7517 5.03836 
        11.691C5.01321 11.6303 5.00026 11.5652 5.00026 11.4995C5.00026 11.4337 5.01321 11.3687 5.03836 
        11.3079C5.06352 11.2472 5.10038 11.192 5.14686 11.1456L7.79329 8.50013L5.14686 5.85471C5.10031 5.80827 
        5.06337 5.75311 5.03817 5.69238C5.01297 5.63164 5 5.56654 5 5.50078C5 5.43503 5.01297 5.36992 5.03817 
        5.30919C5.06337 5.24846 5.10031 5.1933 5.14686 5.14686Z" fill={props.personality.engagement.closeIconColor}/>
        </svg>;

    if(props.selectedSection==='Personality' && props.selectedSubSection==='Engagement' && props.personality.engagement.isEnable) {
        
        document.getElementById('addChatWindowholder').className ="hide-window";
        
        return (
            <div style={{position: 'fixed', bottom: '20px', right: '20px', display: 'flex'}}>
                <div id="engagementMsg">
                    <div class="engagementCloseBtn">{engagementCloseIcon}</div>
                    <div id="engagemsgcontainer" style={{backgroundColor:props.personality.engagement.bgColor, 
                        color:props.personality.engagement.textColor,
                        border: '1px solid '+ props.personality.engagement.borderColor,
                        '--borderLeft':'19px solid ' + props.personality.engagement.bgColor
                        }}>
                        <span>{props.personality.engagement.message}</span>
                    </div>
                </div>
                <div className='chatIcon'>
                    <img id="chatImage" onLoad={e=>alignMsgBox(e)}
                    src = {props.personality.agent.widgetIcon ? "./VATheme/Templatefiles/"+props.personality.agent.widgetIcon:"IntelliCuxBot/windowfiles/chatIcon.png"}/>
                </div>
            </div>
        )
        
    }

    DOMPurify.addHook('afterSanitizeAttributes', function (node) {
        if ('target' in node) { node.setAttribute('target', '_blank'); }
    });

    return (
        <div id="HCLBotwindow"
            className="dhtmlwindow chatBotWindow"
            style={{ zIndex: 106,
            backgroundColor: props.canvas.botResponse.canvasBgColor,
            display:props.selectedSection==='Personality' && props.selectedSubSection==='Engagement'?'none':'block'
            }}>
            <div className="drag-handle"
                style={{ backgroundColor: props.header.headerBgColor, opacity: 1, paddingLeft:"10px", height:"47px", paddingBottom: "42px" }}
            >
                <div className="chatHeader">
                    <div>
                    <span>
                    {props.header.botImgEnabled == true ? <img style={{ top: '8px', }}
                            src={props.headerBotImg ? imageFile : props.header.botImg? "./VATheme/Templatefiles/"+imageFile:"IntelliCuxBot/windowfiles/chatUser.svg"} /> : ''
                    }
                           </span>
                    </div>
                    <div id="chatTitles">
                        <div className='headerStyle' style={{ color: props.header.headerTextColor }}>{props.header.headerText}</div>
                        <div id="chat_subtitle" style={{ color: props.header.subheaderTextColor }}>{props.header.subheaderText}</div> 
                    </div>
                </div>
                <div className="drag-controls" style={{top:'14px'}}>
                    <div class="header-btn">{chatCloseSvg}</div>
                    <div class="header-btn" >{chatMinimizeSvg}</div>
                    <div id="resize-btn" class="header-btn" style={{
                        display:props.header.maximizeIconEnable?'block':'none'
                    }}>{chatMaximizeSvg}</div>
                </div>  
            </div>
            <div className="drag-contentarea" style={{ display: 'block' }}>
                <div className="HCLBotConsole"
                    id="HCLBot_console" style={{ height:'53vh', background:canvas.botResponse.canvasBgColor }}>
                    <div class="HCLBot_user">
                        <div style={{
                            background: canvas.userText.responseBgColor,
                            color: canvas.userText.responseTextColor,
                            borderColor: canvas.userText.responseBorderColor
                        }}
                            class={`HCLBot_red ${canvas.userText.responseBgColor == "#006BB6" && 'bg-clr-class'}`}
                        >Hi</div>
                        <div class="userTime">
                            <span style={{
                                color: canvas.botResponse.timeStampTextColor,
                            }}>12:24 PM</span>
                        </div>
                    </div>
                    <div className="HCLBot_blue">
                        <div className="CDuserIcon">
                            {props.header.selectedImageFile !== undefined ? <img src={(imageFile)} /> :
                                <img src={props.headerBotImg ? imageFile : props.header.botImg? "./VATheme/Templatefiles/"+imageFile:"IntelliCuxBot/windowfiles/chatUser.svg"} />}
                        </div>
                        <div className="HCLCuxBot_reply" style={{
                            background: canvas.botResponse.responseBgColor,
                            color: canvas.botResponse.responseTextColor,
                            border: '1px solid '+  canvas.botResponse.responseBorderColor
                        }}>
                            Hi! I'm DigiBot, your smart virtual assistant.
                            Feel free to ask me anything! Please select any of the options to help me guide you better.
                            <br /><br />
                            <div id="4ac64e6f-d420-5aff-311d-4a2204db2f09">
                                <button type="button"
                                    className="ac-pushButton"
                                    id="btn0"
                                    name="button"
                                    style={{
                                        background: canvas.botResponse.buttonBgColor,
                                        color: canvas.botResponse.buttonTextColor,
                                        border: '1px solid '+ canvas.botResponse.buttonBorderColor
                                    }}
                                    onMouseEnter={e=>onMouseEnter(e)}
                                    onMouseLeave={e=>onMouseLeave(e)}
                                    onclick="htmlClickresponse(this,event)">
                                    I want to know about HCL
                                </button><br />
                                <button type="button"
                                    style={{
                                        background: canvas.botResponse.buttonBgColor,
                                        color: canvas.botResponse.buttonTextColor,
                                        border: '1px solid '+ canvas.botResponse.buttonBorderColor
                                    }}
                                    onMouseEnter={e=>onMouseEnter(e)}
                                    onMouseLeave={e=>onMouseLeave(e)}
                                    className="ac-pushButton" id="btn1" name="button"
                                    onclick="htmlClickresponse(this,event)">
                                    I want to know about specific industries
                                </button><br />
                                <button type="button"
                                    style={{
                                        background: canvas.botResponse.buttonBgColor,
                                        color: canvas.botResponse.buttonTextColor,
                                        border: '1px solid '+ canvas.botResponse.buttonBorderColor
                                    }}
                                    onMouseEnter={e=>onMouseEnter(e)}
                                    onMouseLeave={e=>onMouseLeave(e)}
                                    className="ac-pushButton" id="btn2" name="button"
                                    onclick="htmlClickresponse(this,event)">
                                    I am a job seeker
                                </button><br />
                                <button type="button"
                                    style={{
                                        background: canvas.botResponse.buttonBgColor,
                                        color: canvas.botResponse.buttonTextColor,
                                        border: '1px solid '+ canvas.botResponse.buttonBorderColor
                                    }}
                                    onMouseEnter={e=>onMouseEnter(e)}
                                    onMouseLeave={e=>onMouseLeave(e)}
                                    className="ac-pushButton" id="btn3" name="button"
                                    onclick="htmlClickresponse(this,event)"> I am a partner</button><br /><br />
                            </div>
                        </div>
                        <div className="responseTime">
                            <span style={{
                                color: canvas.botResponse.timeStampTextColor,
                            }}>9:24 AM</span></div>
                    </div>
                </div><br />
                <div id="preview" style={{ display: 'none' }} /><br />
                <div className="bottom-ctrl-conatiner" style={{
                    height:btmContainerHt,
                    backgroundColor:props.canvas.botResponse.canvasBgColor?props.canvas.botResponse.canvasBgColor:"#FFFFFF"
                }}>
                    {props.footer.backToHome && props.footer.backToHome.isVisible && (
                        <div class="back2Home">
                        <a href="javascript:;" class="" id="HCLBot_home">
                        <span id="backArrow">{back2HomeImg}</span>
                        <span id="backHomeText" 
                        style={{color:props.footer.backToHome.textColor}}>
                            {props.footer.backToHome.text}</span></a>
                    </div>
                    )}
                
                    <div className="sendChatConatiner">
                        <div class="chatHome" style={{display: (props.footer.menuOptions && props.footer.menuOptions.isMenuVisible)?'block':'none'}}>
                           {menuImg}
                        </div>
                        <div className="sendChatInput" style={{
                            background: props.footer.userInput.inputBgColor,
                            color: props.footer.userInput.inputTextColor,
                            borderColor: props.footer.userInput.inputBorderColor,
                            borderRadius: "10px"
                        }}>
                            <input type="text" placeholder=""
                                className="chatBotText"
                                style={{
                                    background: props.footer.userInput.inputBgColor,
                                    color: props.footer.userInput.inputTextColor,
                                    width:"80% !important"
                                }}
                                value={props.footer.userInput.text}
                                onkeyup="HCLBot_onkeyup(event)"
                                onkeypress="HCLBot_OnKeyPress(event)"
                                autoComplete="off" id="HCLBot_text" />
                            <span id="chatLang" style={{
                                marginBottom: "4px",
                                background: props.footer.userInput.langBgColor,
                                color: props.footer.userInput.iconColor,
                                display: props.footer.userInput.isLangVisible?'block':'none'                               
                                }}>EN</span>
                            <span id="HCLBot_send"><a href="javascript:;" id="sendImageTag" class="" onclick="HCLBot_OnSend(event)" >
                            {sendIconSvg}</a></span>
                        </div>
                    </div>
                </div>
            </div>
            

            <div className="chatbotFooter" style={{
                    backgroundColor:props.canvas.botResponse.canvasBgColor?props.canvas.botResponse.canvasBgColor:"#FFFFFF"
                }}>
                <div id="footerContent">
                    <span id="footerSpanText" style={{ color: props.footer.poweredByText.color }}  
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.footer.poweredByText.text) }}>
                    </span>
                </div>
            </div>
            <div className="drag-statusarea" style={{ display: 'block' }}>
                <div id="statusAreaInfo" style={{ display: 'none' }} />
                <div className="drag-resizearea"
                    style={{ background: 'transparent url(https://leads.intelliservice.ai/MarketingClientBot/windowfiles/resize.gif) top right no-repeat', display: 'none' }}>
                    &nbsp;
                </div>
            </div>
            {props.selectedSection==='Footer' && props.selectedSubSection==='Menu' 
            && props.footer.menuOptions && (
            <div id='menuContainer' className="dhtmlwindow" style={{
                display: props.footer.menuOptions.isMenuVisible?'block':'none',
                backgroundColor:props.footer.menuOptions.bgColor
                }}>
                <div id='menuDisplay'>
                {props.footer.menuOptions.menuItems.map((item)=>renderMenuOptions(item))}
                </div>                    
                <Row className='menuCloseRow'>
                <img src='./images/chatMenuClose.svg' style={{fill:'yellow'}}/>
                </Row>
            </div>
            )}
        </div>
        
    );

    
}

export default ChatWindow;

