import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Container, Form } from 'react-bootstrap';
import SelectComponent from '../../../../components/UIComponents/SelectDropdown/SelectComponent';
import { Switch } from '@material-ui/core';
import InputWithLabelComponent from '../components/InputWithLabelComponent/InputWithLabelComponent';
import { defaultJSON } from './DefaultPTValues';
import DeleteModal from '../../../../components/UIComponents/DeleteModalComponent/DeleteModal';
import ButtonComponent from '../../../../components/UIComponents/Button/ButtonComponent';
import * as actions from "../../../../store/actions/index";
import { PostCalltoServer,botPostCalltoServerWithFormData } from '../../../../store/utility';
import './personalityPart.css'
import AgentImage from './AgentImage';
import { decrypt } from '../../../../utility/utility';
import Engagement from './PersonalitySections/Engagement';


const PersonalityPart = ({config, data, onChangePersonality, projectDetails, updateSubSelection}) => {

    const dispatch = useDispatch();

    const [personalityData, setPersonalityData] = useState(defaultJSON.personality);
    const [saveSetting, setSaveSetting] = useState(false);
    const [resetSetting, setResetSetting] = useState(false);
    const [resetPopUp, setResetPopUp] = useState(false);
    const [savePopUp, setSavePopUp] = useState(false);
    const [dataReset, setDataReset] = useState(false);

    const sectionList = [{ value: 'Auto Pop-up', displayValue: 'Auto Pop-up' },
    { value: 'Virtual Agent Image', displayValue: 'Virtual Agent Image' },
    { value: 'Greeting', displayValue: 'Greeting' },
    { value: 'Engagement', displayValue: 'Engagement' }
    // { value: 'Salutation', displayValue: 'Salutation' }
];

    const [selectedSection, setSelectedSection] = useState('Auto Pop-up');

    const [langList, setLangList] = useState();
    const languageList = useSelector( state => state.user.languageList);
    const [widgetImg, setWidgetImg] = useState();

    useEffect(()=> {

        let language_array = languageList  && languageList.split(',');
        let templanglist = [];
        for(let langItem of language_array){
            let lngvalue=langItem.split('_')[0];
            templanglist.push({
                "value":langItem.split('_')[1],
                "displayValue":lngvalue.charAt(0).toUpperCase() +lngvalue.slice(1)
            });
        }
        setLangList(templanglist);
    },[]);

    const permissions = useSelector(state => {
        return state.user.moduleAccess.conversationalAdmin;
      });

    const hasRWPermission = () => {
        return permissions.RW?false:true;
    }

    const getUpdatedData = (type, value) => {
        switch(selectedSection) {
            case 'Auto Pop-up': return {...data,
                autoPopup: {
                ...data.autoPopup,
                [type]: value
            }};
            case 'Greeting': 
                return {...data,
                    greeting: value
                }
            case 'Virtual Agent Image': 
                if(value.widgetIconData) { setWidgetImg(value.widgetIconData); }
                if(type === 'deleteWidgetIcon') {
                    setWidgetImg();
                    return {...data,
                        agent: { ...data.agent, widgetIcon:''}
                    }
                }
                if(type === 'isDraggable') {
                    return {...data,
                        agent: { ...data.agent, [type]:value}
                    }
                }
                return {...data}   
            case 'Engagement':
                return {...data,
                    engagement: {
                    ...data.engagement,
                    [type]: value
                }};      
            
        }
        
    }

    const isAutoEngagement = (value) => {
        if(selectedSection === 'Engagement') {
            if(data.autoPopup.isEnable === value) return true;
        } else if(data.engagement.isEnable === value) return true;
        return false;
    }

    const onChangeHandler = (type, value) => {
        if(type === 'isEnable' && value && isAutoEngagement(value)) { //isEnable present in auto-popup and engagement section
            dispatch(actions.SetErrorMessage(`Both "Auto Pop-up chatbot" and "Display message on page load" 
                toggle buttons cannot be enabled simultaneously`));
            return;
        }
        let updatedData = getUpdatedData(type, value);
        setPersonalityData(updatedData);
        onChangePersonality(updatedData);
        setSaveSetting(true);
        setResetSetting(true);
    }

    const updateLangItem = (key, value, index) =>{
        let tempArray = [...data.greeting];
        tempArray[index] = {...tempArray[index], [key]:value};
        onChangeHandler('greeting',tempArray)
    }

    const onSave = () => {
        let project = { "vaName":localStorage.getItem('vaName'),"project": (projectDetails).join(),'virtualLink':config.VIRTUAL_ASSISTANCE_URL,'deployLink':config.DEPLOYMENT_URL,'websocketurl': config.WEBSOCKET_URL,'botOrgsLink': config.BOT_ORGS,'orgName':localStorage.getItem('orgName') }
        let otherdata = {"token":localStorage.getItem('accessToken'),"project":(projectDetails).join(),"language":localStorage.getItem('language'),"orgName":localStorage.getItem('orgName'),"vaName":localStorage.getItem('vaName')}

        const formData = new FormData();
        formData.append("data",JSON.stringify(data));
        if(widgetImg && widgetImg !== '') {
            formData.append('widgetIcon', widgetImg);
        }
       
        PostCalltoServer(config.VIRTUAL_ASSISTANCE_URL+'/virtualAssistant/create-template', project)
        .then(response => {
            let responsee = response.data;
            
            formData.append("FolderName",responsee.FolderName)
            formData.append("other",JSON.stringify(otherdata))
            
            botPostCalltoServerWithFormData(config.VIRTUAL_ASSISTANCE_URL+'/virtualAssistant/personality', formData)
                .then(result => {
                    if(result.data.status=='Success'){
                        dispatch(actions.SetSuccessMessage("Changes to the personality section have been updated."))
                        }
                    setSaveSetting(false);
                    setSavePopUp(false);
                })
                .catch((e)=>console.error('Error:',e));
        });
    }

    const onReset = () => {
        setPersonalityData(defaultJSON.personality);
        onChangePersonality(defaultJSON.personality);
        setSaveSetting(false);
        setResetSetting(true);
        setResetPopUp(false);
        setDataReset(true);
        dispatch(actions.SetSuccessMessage("Personality section changes have been reset to default."));
    }

    const addRow = () => {
        let tempData = [...data.greeting];
        tempData.push({langCode:'en',flowQuery:''})
        onChangeHandler('greeting',tempData);
    }

    const deleteRow = (index) => {
        let tempData = [...data.greeting];
        tempData = tempData.filter((_, i)=>i !== index)
        onChangeHandler('delete',tempData);
    }

    function renderList(index,item) {
        return (
            <Row key={'langRow'+index}>
            <Col md='3'>
                <SelectComponent
                    name={"greetingLang"+index}
                    label="Language"
                    changed={(e) => updateLangItem('langCode',e.target.value, index)}
                    required={true}
                    value={item.langCode}
                    options={langList}
                    className='mt-p45'
                />
            </Col>
            <Col md='3'>
            <div className="common-input">
                <Form.Label className="mb-1 text-box-title adding-other-input">Routing link</Form.Label>
                <div className="input-group routing-div">
                    <label className="dropdown-wrapper dropless-wrapper"><span>Flow</span>
                    </label>
                    <Form.Control
                        type={"text"}
                        name={"flowQuery"+index}
                        onChange={(e) => updateLangItem('flowQuery',e.target.value, index)}
                        required={false}
                        value={item.flowQuery}
                        className={"default-css color-text-input"}
                        placeholder={'Query to trigger'}
                        readOnly={false}
                    />
                </div>
            </div>
            </Col>
            <Col md='1' className='plusImgCol'>
                <img onClick={()=>deleteRow(index)} className='deleteBtn-img' src="./Icons/removeCircleBlack.svg"/>
                
            </Col>
            </Row>
        )
    }

    useEffect(()=>{
        updateSubSelection(selectedSection);
    },[selectedSection])

    return (
        <Container className="VirtualAssitantPersonality">
         <Row>
            <Col md='3'>
            <SelectComponent
                        name="selectName"
                        label="Select the component"
                        changed={(e) => setSelectedSection(e.target.value)}
                        required={true}
                        value={selectedSection}
                        options={sectionList}
            />
            </Col>
        </Row>
        {selectedSection === 'Auto Pop-up' && (
            <>
            <Row className='mt-2'>
            <Col md='2' className='al-ctr'>
                <Form.Label className="mb-1 text-box-title switch-label">Auto Pop-up chatbot</Form.Label>
            </Col>
            <Col md='1'>
                <Switch
                    className='switchMain'
                    checked={data.autoPopup.isEnable}
                    onChange={(e) => onChangeHandler('isEnable',e.target.checked)}
                    color="primary"
                    name="displayCheck"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    disableRipple
                />
            </Col>
            </Row>
            {data.autoPopup.isEnable && (
                <Row className='mt-2'>
                <Col md='3'>
                    <InputWithLabelComponent
                        type="timeout"
                        placeHolder=""
                        name="autoPopup"
                        label="Auto Pop-up Chatbot after (seconds)"
                        value={data.autoPopup.timeout}
                        readOnly={false}
                        onChange={(e) => onChangeHandler('timeout',e.target.value)}
                        required={false}
                    />
                </Col>
                </Row>
            )}
            </>
        )}

        {selectedSection === 'Greeting' && (
        <>
        <Row className='mt-3'>
            <Col md='4'>
                <label className='text-box-title'>Routing method for greeting message</label>
                <img onClick={addRow} className='addBtn-img' src="./Icons/plus-icon.svg"/>
            </Col>
        </Row>
        {data.greeting.map((item, index) => renderList(index, item))}
        </>
        )}

        {selectedSection === 'Virtual Agent Image' && (
        <AgentImage data={data} onPersonalityImgChange={onChangeHandler} resetFlag={dataReset} updateReset={setDataReset}/>
        )}

        {selectedSection === 'Engagement' && (
            <Engagement data={data} onChangeHandler={onChangeHandler}/>
        )}

        <DeleteModal
            show={resetPopUp}
            deleteModal={() => setResetPopUp(false)}
            title="Reset Confirmation"
            bodyTitle="Do you want to reset the personality section changes?"
            buttonClick={onReset}
        />
            <DeleteModal
            show={savePopUp}
            deleteModal={() => setSavePopUp(false)}
            title="Save Confirmation"
            bodyTitle="Do you want to save the changes to the personality section?"
            buttonClick={onSave}
        />
        <div className='row mt-4 pl-3 mb-4'>
            <ButtonComponent
                variant='outlined'
                label='Reset'
                className='buttonClass mr-3 reset-btn'
                clicked={() => setResetPopUp(true)}
            />
            <ButtonComponent
                variant='contained'
                label='Save'
                className='buttonWidth'
                clicked={()=> setSavePopUp(true)}
                disabled={!resetSetting?true:hasRWPermission()}
            />
        </div>

        </Container>
    )
}

export default PersonalityPart;