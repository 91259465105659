// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.fileClass input{
    font-family: 'Roboto-Regular';
    font-size: 14px;
    /* border: none; */
    background: transparent;
    padding: 0.375rem 0.75rem;
    border:1px solid #484545; 
    border-radius: 4px;
    width: -webkit-fill-available;
}
.fileClass input::-webkit-file-upload-button {

    padding: 0.4em 0.6em;
    background-color: #828B92;
    color:#fff;
    border: #828B92;
    margin-right:10px;
    text-transform: uppercase;
    font-family: 'Roboto-Bold';
    font-size: 14px;

  }
  .fileClass input[type='file']:focus,.fileClass input[type='file']:active,.fileClass input[type='file']:hover{
    border: 1px solid #2A93DF;
  }`, "",{"version":3,"sources":["webpack://./src/components/UIComponents/FileInput/FileInput.css"],"names":[],"mappings":";AACA;IACI,6BAA6B;IAC7B,eAAe;IACf,kBAAkB;IAClB,uBAAuB;IACvB,yBAAyB;IACzB,wBAAwB;IACxB,kBAAkB;IAClB,6BAA6B;AACjC;AACA;;IAEI,oBAAoB;IACpB,yBAAyB;IACzB,UAAU;IACV,eAAe;IACf,iBAAiB;IACjB,yBAAyB;IACzB,0BAA0B;IAC1B,eAAe;;EAEjB;EACA;IACE,yBAAyB;EAC3B","sourcesContent":["\n.fileClass input{\n    font-family: 'Roboto-Regular';\n    font-size: 14px;\n    /* border: none; */\n    background: transparent;\n    padding: 0.375rem 0.75rem;\n    border:1px solid #484545; \n    border-radius: 4px;\n    width: -webkit-fill-available;\n}\n.fileClass input::-webkit-file-upload-button {\n\n    padding: 0.4em 0.6em;\n    background-color: #828B92;\n    color:#fff;\n    border: #828B92;\n    margin-right:10px;\n    text-transform: uppercase;\n    font-family: 'Roboto-Bold';\n    font-size: 14px;\n\n  }\n  .fileClass input[type='file']:focus,.fileClass input[type='file']:active,.fileClass input[type='file']:hover{\n    border: 1px solid #2A93DF;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
