let permissions = {
    permissionCode:{
        CONVERSATIONAL_AI_STANDARD_R:"conversational_AI_standard_R",
        CONVERSATIONAL_AI_STANDARD_RW:"conversational_AI_standard_RW",
        CONVERSATIONAL_AI_ADMIN_R:"conversational_AI_admin_R",
        CONVERSATIONAL_AI_ADMIN_RW:"conversational_AI_admin_RW",
        CONVERSATIONAL_AI_DASHBOARD_RW :"conversational_AI_dashboard_RW",
        COGNITIVE_SEARCH_STANDARD_R:"cognitive_search_standard_R",
        COGNITIVE_SEARCH_STANDARD_RW:"cognitive_search_standard_RW",
        COGNITIVE_SEARCH_KU_R:"cognitive_search_KU_R",
        COGNITIVE_SEARCH_KU_RW:"cognitive_search_KU_RW",
        COGNITIVE_SEARCH_ADMIN_R :"cognitive_search_admin_R",
        COGNITIVE_SEARCH_ADMIN_RW:"cognitive_search_admin_RW",
        COGNITIVE_SEARCH_DASHBOARD_RW:"cognitive_search_dashboard_RW",
        PRODUCT_TRIAGE_STANDARD_R:"product_triage_standard_R",
        PRODUCT_TRIAGE_STANDARD_RW:"product_triage_standard_RW",
        PRODUCT_TRIAGE_TRAIN_R:"product_triage_train_R",
        PRODUCT_TRIAGE_TRAIN_RW:"product_triage_train_RW",
        PRODUCT_TRIAGE_ADMIN_R:"product_triage_admin_R",
        PRODUCT_TRIAGE_ADMIN_RW:"product_triage_admin_RW",
        PRODUCT_TRIAGE_DASHBOARD_RW:"product_triage_dashboard_RW",
        PROJECTM_R:"projectM_R",
        PROJECTM_RW:"projectM_RW",
        USERMANAGEMENT_R:"usermanagement_R",
        USERMANAGEMENT_RW:"usermanagement_RW",
        INTEGRATIONM_R:"integrationM_R",
        INTEGRATIONM_RW:"integrationM_RW",
        TICKETS_INSIGHTS_R:"ticket_insights_R",
        TICKETS_INSIGHTS_RW:"ticket_insights_RW",
        BACKUP_RESTORE_R:"backup&restore_R",
        BACKUP_RESTORE_RW:"backup&restore_RW",
    },
    skuCode:{
        AI:"AI",
        CS:"CS",
        PT:"PT",
        STANDARD:"STANDARD",
    }
}

export default permissions;