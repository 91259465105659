import React, { useState, useEffect, useRef } from 'react';
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  removeElements,
  isEdge,
  isNode,
  Controls
} from 'react-flow-renderer';
import { Row, Col, Form } from "react-bootstrap";
import Sidebar from './Sidebar';
import './conversationalUI.css';
import BoxSelector from './Components/BoxSelector';
import StartNode from './Components/StartNode';
import ExpressionNode from './Components/ExpressionNode';
import HttpNode from './Components/HttpNode';
import TextNode from './Components/TextNode';
import TextPromptNode from './Components/TextPromptNode';
import ConfirmationNode from './Components/ConfirmationNode';
import CarouselNode from './Components/CarouselNode';
import AddNodeDetailsDrawer from '../../../components/UI/Drawer/AddNodeDetailsDrawer';
import { useDispatch, useSelector } from "react-redux";
import { SetErrorMessage, SetSuccessMessage, UpdateCuxFormActiveField, CuxLoader, GetCuxBotData } from '../../../store/actions';
import { SmartEdge } from '@tisoap/react-flow-smart-edge';
import FlowNode from './Components/FlowNode';
import MediaNode from './Components/MediaNode';
import FormsNode from './Components/FormsNode';
import ButtonCustom from "../../../components/UIComponents/Button/ButtonComponent";
import AgentNode from './Components/AgentNode';
import ScriptNode from './Components/ScriptNode';
import EmailNode from './Components/EmailNode';
import TriggerIntentNode from './Components/TriggerIntentNode';
import ShowDataNode from './Components/ShowDataNode';
import FeedbackNode from './Components/FeedbackNode';
import {
  GetCalltoServer,
  UpdateCalltoServer,
} from "../../../store/utility";
import ReactSelectComponent from "../../SettingPage/SettingInnerPages/components/ReactSelectComponent/ReactSelectComponent";
import CopyChannelDrawer from '../../../components/UI/Drawer/CopyChannelDrawer';


const USER_PROMPT = 'USER_PROMPT';
const NO_PROMPT = 'NO_PROMPT';

const style = {  background: '#FFFFFF',borderRadius: '3px',
            boxShadow: '0px 1px 11px rgba(0, 0, 0, 0.25)', paddingLeft:'1%',
            paddingTop:'1%', paddingRight:'1%', paddingBottom:'1%' }

const onDragOver = (event) => {
  event.preventDefault();
  event.dataTransfer.dropEffect = 'move';
};

let id = 0;
const getId = () => `dndnode_${id++}`;

const nodeTypes = {
    boxSelector: BoxSelector,
    start: StartNode,
    http: HttpNode,
    text: TextNode,
    textPrompt: TextPromptNode,
    confirmation: ConfirmationNode,
    carousel: CarouselNode,
    expression: ExpressionNode,
    flow:FlowNode,
    media:MediaNode,
    forms:FormsNode,
    agent: AgentNode,
    script: ScriptNode,
    email: EmailNode,
    triggerIntent: TriggerIntentNode,
    showData: ShowDataNode,
    feedback: FeedbackNode
  };

const initialElements = [{ id: 'startnode', type: 'start', data: { label: 'START', type: 'start', category: NO_PROMPT }, position: { x: 250, y: 5 },
                           style: { padding: 10, 
                           background: '#FFFFFF',
                           border: '3px solid #000000',
                           boxShadow: '0px 1px 11px rgba(0, 0, 0, 0.25)',
                           borderRadius: '5px',
                           fontWeight: '900',
                           fontSize: '16px' }, 
                          }];



const DnDFlow = ({config, botid, botname}) => {
  const [reactFlowInstance, setReactFlowInstance] = useState();
  const [elements, setElements] = useState();
  const [modal, setModal] = useState(false);
  const [selectedNode, setSelectedNode] = useState();
  const [deleteFlag, setDeleteFlag] = useState(false);
  const [variableList, setVariableList] = useState();
  const [flowTemplateFlag, setFlowTemplateFlag] = useState(false);
  const [nodePosition, setNodePosition] = useState();
  const [selectedChannel, setSelectedChannel] = useState('WEB');
  const [showCopyDrawer, setShowCopyDrawer] = useState(false);

  const channelList = [{value:'WEB', label:'Web'},/*{value:'TEAMS', label:'MS Teams'},
{value:'WHATSAPP', label:'WhatsApp'}*/] //to be uncommented when watsapp and teams enabled

  const reactFlowWrapper = useRef(null);

  const dispatch = useDispatch();

  const refreshFlow = useSelector(state => {
    return state.projectview.copyChannelRefresh;
  });

  useEffect (()=>{
    getBotDetails();
    setSelectedChannel('WEB');
  },[botid]);

  useEffect (()=>{
    getBotDetails();
  },[selectedChannel,refreshFlow]);

  useEffect (() => {
    getVariables();
  },[elements]);

  const addNodeFn = (nodes) => {
    return nodes.map((node)=> {
      node.data = {
        ...node.data,
        nodeOptions:onNodeOptions
      }
      return node;
    })
  }

  const getBotDetails = () => {
    dispatch(CuxLoader(true));
    let url = config.COMMON_API_URL + '/flowframework/flow/' + botid +'?channel='+selectedChannel.toLowerCase()+'&lang='+localStorage.getItem('language');
    GetCalltoServer(url)
    .then((response)=>{
      if(response.status === 200) {
        if(response.data.flowcontent?.length > 0) {
          let elementList = response.data.flowcontent;
          addNodeFn(elementList);
          setElements(elementList);
          getLatestId(elementList);
          dispatch(CuxLoader(false));
          if(elementList.length === 1) {
            dispatch(SetSuccessMessage('Flow details have not been added yet'));
          }
        }        
        else {
          setElements(initialElements);
          dispatch(CuxLoader(false));
          dispatch(SetSuccessMessage('Flow details have not been added yet'));
        }          
      } else {
        dispatch(SetErrorMessage('Failed to fetch flow details'));
        dispatch(CuxLoader(false));
      }
    })
    .catch(()=>{
      dispatch(SetErrorMessage('Failed to fetch flow details'));
      dispatch(CuxLoader(false));
    })
  }

  const getLatestId = (botArray) => {
    let maxId = 0; 
    let nodeId = 0;
    botArray.forEach(element => {
      if(isNode(element)) {
        if(element.id.includes('dndnode_')) {
          nodeId = parseInt(element.id.split('dndnode_')[1]);
          if(maxId < nodeId) {
            maxId = nodeId;
          }
        }          
      }
    });
    id = maxId+1;    
  }
  
  const modalOpen = () => {
    setModal(true);
  }

  const showDrawer=(status)=>{
    setModal(status);
    if(!status) { //to reset Form's active field
      dispatch(UpdateCuxFormActiveField(0));
    }
  }

  const clearElements = () => {
    setElements(initialElements);
    setVariableList([]);
  }

  const copyElements = () => {
    setShowCopyDrawer(true);
  }

  const handleSave = () => {
    let duplicates = variableList.filter((item, index)=> variableList.indexOf(item) !== index);
    if(duplicates.length === 0) {
      saveElements();
    } else {
      dispatch(SetErrorMessage('Flow cannot be saved due to duplication of node names'));
    }
  }

  const saveElements = () => {
    dispatch(CuxLoader(true));
    let url = config.COMMON_API_URL + '/flowframework/flow/' + botid +'?channel='+selectedChannel.toLowerCase()+'&lang='+localStorage.getItem('language');
    let body = {
      flowname: botname,
      projectname: localStorage.getItem('projectname'),
      flowcontent: elements
    }
    UpdateCalltoServer(url, body)
    .then((response) => {
      if(response.status === 200) {
        dispatch(SetSuccessMessage('Flow details updated successfully'));
        dispatch(CuxLoader(false));
      }
      else {
        dispatch(SetErrorMessage('Failed to update flow details'));
        dispatch(CuxLoader(false));
      }
    })
    .catch(()=>{
        dispatch(SetErrorMessage('Failed to update flow details'));
        dispatch(CuxLoader(false));
    })
  }

  const getChangeFlag = (type, data, element) => {
    switch(type) {
      case 'boxSelector':
      case 'expression' : return data.branched !== element.data.branched ? true : false; 
      case 'carousel' : if(data?.buttonDataList?.length !== element.data?.buttonDataList?.length || data?.showMoreBtn !== element.data?.showMoreBtn) {
                          return true;
                        } break;
      default: return false;
    }
  }

  const updateNode = (data, element) => {
    let changeFlag = false;
    setElements((els) =>
      els.map((el) => {
        if(el.id !== element.id) { return el; }
        changeFlag = getChangeFlag(element.type, data, el);
        el.data = {
          ...el.data,
          label: data.label,
          variable: data.variable,
          options: data.options,
          count: data.count,
          referenceVariable: data.referenceVariable,
          branched: data.branched,
          url: data.url,
          httpAction: data.httpAction,
          httpBodyJson: data.httpBodyJson,
          httpBodyEncoded: data.httpBodyEncoded,
          httpFormData: data.httpFormData,
          isMultipleFiles: data.isMultipleFiles,
          params: data.params,
          headers: data.headers,
          branchOptions: data.branchOptions,
          carousel: data.carousel,
          selectBtn: data.selectBtn,
          showMoreBtn: data.showMoreBtn,
          urlBtn: data.urlBtn,
          maxBodyLength: data.maxBodyLength,
          carouselReferenceCheck: data.carouselReferenceCheck,
          flowid: data.flowid,
          buttonList:data.buttonList,
          mediatype:data.mediatype,
          formInputs:data.formInputs,
          formSubmitBtn: data.formSubmitBtn,
          formSubmitMsg: data.formSubmitMsg,
          formSkipBtnText: data.formSkipBtnText,
          formSkipBtnMsg: data.formSkipBtnMsg,
          buttonDataList: data.buttonDataList,
          scriptData: data.scriptData,
          displayHTML: data.displayHTML,
          emailData: data.emailData,
          intentQuery: data.intentQuery,
          agentTransferData: data.agentTransferData,
          showDataDetails: data.showDataDetails,
          disableTextbox:data.disableTextbox,
          isCardView:data.isCardView,
          IsReferance: data.IsReferance,
          message: data.message,
          alignment: data.alignment,
          optionValues: data.optionValues,
          refVariableDetails:data.refVariableDetails,
          textPromptData: data.textPromptData,
          addToLog: data.addToLog,
        };

        return el;
      })
    );
    getVariables();
    if(changeFlag) {
      removeEdge(element.id);
    }
  }

  const removeEdge = (ele_id) => {
    elements.map((item)=> {
      if(isEdge(item)) {
        if(item.source === ele_id) {
          onElementsRemove([item]);
        }
      }
    })
  }

  const onConnect = (params) =>{
    params.type = 'smart';
    params.labelBgStyle= { fontWeight: 400};
    params.style = {stroke: "black"};
    if(params.sourceHandle !== null)
      params.label = params.sourceHandle;
    setElements((els) => addEdge(params, els))
  };

  const onElementsRemove = (elementsToRemove) => {
    setElements((els) => removeElements(elementsToRemove, els));
  }
  const onLoad = (_reactFlowInstance) => setReactFlowInstance(_reactFlowInstance);
  const onNodeDoubleClick = (event, node) => {
    setSelectedNode(node); 
    modalOpen();
  }

  const onClickElement = (event, element) => {
    setSelectedNode(element); 
  }

  useEffect(()=> {
    if(selectedNode !== undefined && deleteFlag) {
      onElementsRemove([selectedNode]);
      setDeleteFlag(false);
      updateVariableList(selectedNode.data.variable);
      dispatch(SetSuccessMessage('Node deleted successfully'));
    }    
  },[selectedNode && deleteFlag === true])

  const deleteNode = () => {
    setDeleteFlag(true);
  }

  const onNodeOptions = (option) => {
    if(option === 'delete') {
      deleteNode();
    } else {
      modalOpen();
    }
  }

  function updateVariableList (variable) {
    setVariableList(variableList.filter(item => item !== variable))
  }

  function getVariables () {
    let list = [];
    if(elements !== undefined) {
      elements.forEach((node)=> {
        if(node.data?.variable !== undefined) {
          list.push(node.data.variable);
        }        
      });
    }    
    setVariableList(list);
  }

  function updateNodePosition (node) {
    setElements((els) =>
      els.map((e) => {
        if (isEdge(e)) {
            return e;
        }
        
        if (e.id === node.id) {
          e =   {
            ...e,
            position:node.position,
          };        
        }
        return e;        
      })
    );
  }

  const onNodeDragStop = (event, node) => {
    updateNodePosition(node);
  };

  const applyNewPositions = (nodes) => {
    let xDiff = Math.abs(nodes[0].position.x - nodePosition.x);
    let yDiff = Math.abs(nodes[0].position.y - nodePosition.y);
    return nodes.map((item)=>{
      if(isNode(item)) {
        return {...item, 
          position:{ 
            x:item.position.x+xDiff,
            y:item.position.y+yDiff
          }}
      } else return item;
    });
    

  }

  const updateIds = (nodes) => {
    let tempId = new Date().getTime();
    return nodes.map((item)=> {
      if(isNode(item)) {
        return { ...item, id: item.id+tempId}
      } else {
        return {
          ...item,
          source: item.source+tempId,
          target: item.target+tempId,
          id: item.id+tempId
        }
      }
    });
  }

  const processTemplateNodes = () => {
    let list = flowNodes;
    list.shift(); //first node will always be startnode
    list = list.filter((item)=> { //to remove edge connecting startnode
      if(isEdge(item)) { 
        if(item.source !== 'startnode') {
          return item; 
        } 
      } 
      else {
        return item
      }
    });
    addNodeFn(list);
    list = applyNewPositions(list);
    list = updateIds(list);
    return list;
  }

  const flowNodes = useSelector(state => {
    return state.projectview.CuxBotData;
  });

  useEffect(()=> {
    if(flowTemplateFlag) {
      let nodeList = processTemplateNodes();
      setElements((es) => es.concat(nodeList));
      setFlowTemplateFlag(false);
    }
    
  },[flowNodes]);

  const onDrop = (event) => {
    event.preventDefault();
    let onDropData = JSON.parse(event.dataTransfer.getData('application/reactflow'));
    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();    
    const position = reactFlowInstance.project({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top
    });
    const type = onDropData.nodeType;

    if(type ==='flow' && onDropData.data) {
      setNodePosition(position);
      dispatch(GetCuxBotData(config.COMMON_API_URL, onDropData.data.flowid));
      setFlowTemplateFlag(true);
    } else if (reactFlowInstance) {
      let newNode = null;
      let newId = getId();
      switch(type) {
        case 'boxSelector' :
          newNode = {
            id: newId,
            type: 'boxSelector',      
            position,  
            data: { type:'boxSelector', category: USER_PROMPT, nodeOptions: onNodeOptions },
            style: {  ...style, border: '1px solid #3D348B' },
          }; 
          break;
        case 'start' :
          newNode = {
            id: newId,
            type,
            position,
            data: { label: 'START', type, category: NO_PROMPT },
            style: { border: '1px solid #777', padding: 10, background: '#2A93DF', color: '#FFFFFF' },
          };
          break;
        case 'expression' :
          newNode = {
            id: newId,
            type,
            position,
            data: { type, category: NO_PROMPT, nodeOptions: onNodeOptions },
            style: {  ...style, border: '1px solid #F35B04' },
          };
          break;
        case 'http' :
          newNode = {
            id: newId,
            type,
            position,
            data: onDropData.data?{...onDropData.data, type, nodeOptions: onNodeOptions} : { type, category: NO_PROMPT, nodeOptions: onNodeOptions },
            style: {  ...style, border: '1px solid #F18701' },
          };
          break;
        case 'text' :
          newNode = {
            id: newId,
            type,
            position,
            data: { type, category: NO_PROMPT, nodeOptions: onNodeOptions },
            style: {  ...style, border: '1px solid #7678ED' }
          };
          break
        case 'textPrompt' :
          newNode = {
            id: newId,
            type,
            position,
            data: { type, category: USER_PROMPT, nodeOptions: onNodeOptions },
            style: {  ...style, border: '1px solid #CE9C0B' },
          };
          break
        case 'confirmation' :
          newNode = {
            id: newId,
            type,      
            position,  
            data: { type, category: USER_PROMPT, nodeOptions: onNodeOptions },
            style: {  ...style, border: '1px solid #90BE6D' },
          }; 
          break;
        case 'carousel' :
          newNode = {
            id: newId,
            type,      
            position,  
            data: { type, category: USER_PROMPT, nodeOptions: onNodeOptions },
            style: {  ...style, border: '1px solid #577590' },
          }; 
          break;
        case 'flow' :
          newNode = {
            id: newId,
            type,
            position,
            data: { type, category: NO_PROMPT, nodeOptions: onNodeOptions },
            style: {  ...style, border: '1px solid #4D908E' },
          };
          break;
        case 'media' :
          newNode = {
            id: newId,
            type,
            position,
            data: { type, category: NO_PROMPT, nodeOptions: onNodeOptions },
            style: {  ...style, border: '1px solid #9AA0A5' },
          };
          break;
        case 'forms' :
          newNode = {
            id: newId,
            type,
            position,
            data: onDropData.data?{...onDropData.data, type, nodeOptions: onNodeOptions} : { type, category: USER_PROMPT, nodeOptions: onNodeOptions },
            style: {  ...style, border: '1px solid #BB9C76' },
          };
          break;
        case 'agent' :
          newNode = {
            id: newId,
            type,
            position,
            data: { type, category: NO_PROMPT, nodeOptions: onNodeOptions },
            style: {  ...style, border: '1px solid #0D4846' },
          };
          break;
        case 'script' :
          newNode = {
            id: newId,
            type,
            position,
            data: onDropData.data?{...onDropData.data, type, nodeOptions: onNodeOptions} :{ type, category: NO_PROMPT, nodeOptions: onNodeOptions },
            style: {  ...style, border: '1px solid #2A2B3A' },
          };
          break;
        case 'email' :
          newNode = {
            id: newId,
            type,
            position,
            data: { type, category: NO_PROMPT, nodeOptions: onNodeOptions },
            style: {  ...style, border: '1px solid #5E6607' },
          };
          break;
        case 'triggerIntent' :
          newNode = {
            id: newId,
            type,
            position,
            data: { type, category: NO_PROMPT, nodeOptions: onNodeOptions },
            style: {  ...style, border: '1px solid #4C69FF' },
          };
          break;
        case 'showData' :
          newNode = {
            id: newId,
            type,
            position,
            data: { type, category: NO_PROMPT, nodeOptions: onNodeOptions },
            style: {  ...style, border: '1px solid #69726D' },
          };
          break;
        case 'feedback' :
          newNode = {
            id: newId,
            type,
            position,
            data: { type, category: NO_PROMPT, nodeOptions: onNodeOptions },
            style: {  ...style, border: '1px solid #49B5D8' }
          };
          break
      }
      setElements((es) => es.concat(newNode));
    }
  };

  const graphStyles = { height: "80vh" };

  return (
     <div className="dndflow"> 
      <ReactFlowProvider>
        <Row className='dnd-view'>
        <Col md={9} className='dnd-area'>
          <Row className='btn-options-row'>
            <Col md={3}>
              <ReactSelectComponent
                    customClassName="SelectView dnd-channel"
                    value={channelList.filter((item)=>item.value === selectedChannel)}
                    options={channelList}
                    name="channel"
                    label="Channels"
                    placeholder={'Select'}
                    onChange={(event) => setSelectedChannel(event.value)}
              />
            </Col>
            <Col md={4}></Col>
            <Col md={2} className='flowButtons'>
              <ButtonCustom variant='outlined' label='Clear' className={['buttonClass','clearDndBtn'].join(' ')}
               clicked={clearElements}/></Col>
            <Col md={1} className='flowButtons'>
              <ButtonCustom variant='outlined' label='Copy' className={['buttonClass','clearDndBtn'].join(' ')} 
              clicked={copyElements}/></Col>
            <Col md={2} className='flowButtons sidePadding'><ButtonCustom variant='contained' label='Save'className={['buttonWidth','saveDndBtn'].join(' ')} clicked={handleSave}/></Col>  
          </Row>
        <Row>
        <div className="reactflow-wrapper" ref={reactFlowWrapper}>
        {/* <SmartEdgeProvider options={{ debounceTime: 0, nodePadding: 15 }}> */}
          <ReactFlow
            elements={elements}
            onConnect={onConnect}
            onElementsRemove={onElementsRemove}
            onElementClick={onClickElement}
            onLoad={onLoad}
            onDrop={onDrop}
            onNodeDragStop={onNodeDragStop}
            onDragOver={onDragOver}
            style={graphStyles}
            nodeTypes={nodeTypes}
            edgeTypes={{
              smart: SmartEdge,
            }}
            onNodeDoubleClick={onNodeDoubleClick}
          >
            <Controls />
          </ReactFlow>
          {/* </SmartEdgeProvider> */}
        </div>
        </Row>
        </Col>
        <Col md={3}>
        <Sidebar config={config}/>
        </Col>
        </Row>
      </ReactFlowProvider>
    {modal ? <AddNodeDetailsDrawer config={config} show={modal} showDrawer={showDrawer} 
      node={selectedNode} updateNode={updateNode} variableList={variableList} flowid={botid}></AddNodeDetailsDrawer>
        : null}
        <CopyChannelDrawer config={config} show={showCopyDrawer} showDrawer={()=>setShowCopyDrawer(false)} 
          selectedChannel={selectedChannel} channelList={channelList} flowid={botid} />
     </div> 
  );
};

export default DnDFlow;